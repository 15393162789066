import { Estadistica } from 'components/Estadistica/Estadistica';
import { HeaderEstadistica } from 'components/Estadistica/HeaderEstadistica'
import SpinnerComponent from 'components/Spinner/SpinnerComponent';
import environment from 'constants/apiConst';
import React, { useState } from 'react'
import OperationsStatisticsGraph from './OperationsStatisticsGraph';

const OperationsStatistics = () => {

  const [currentPage, setCurrentPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const varForMap = ["form_revised", "form_approved", "form_returned"];
  const [dataOp, setDataOp] = useState(null);
  return (
    <>
      <HeaderEstadistica
        title="Operaciones"
        enableQualifiedUser
        enableRendering
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_OP}
        showOpUser={false}
        getOpData={true}
        getOpDataPath={environment.OBTENER_STATISTICS_OP_DATA}
        setDataOp={setDataOp}
      />
      {loading && <SpinnerComponent />}
      {
        !loading && (
          <div className="row mt-2">
            <div className="col-12">
              <OperationsStatisticsGraph
                title={"Formularios"}
                data={currentPage}
                dataOp={dataOp}
                loading={loading}
                varForMap={varForMap}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
    </>
  )
}

export default OperationsStatistics
