/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Switch,
  useDisclosure
} from '@chakra-ui/react'
import { AuthContext } from 'context/AuthContext'
import { H2 } from 'pages/Pass-Form/stylePases'
import { IconGeneric } from 'styled/Icons.styled'
import { BiImageAdd } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import GlobalContext from 'context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import ReactCardWithIcon from './ReactCardWithIcon'
import { MdClear, MdOutlineImage } from 'react-icons/md'
import { ButtonStyled } from 'styled/Buttons.styled'
import { Modalcustomizable } from 'components/modal/ModalPass'
import ZoomImage from './ZoomedImage'

export const AddPhotography = ({
  id,
  AttachingImages = true,
  Title = '',
  photographies,
  setPhotographies = null,
  disableUpload = false,
  activeDelete = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { requestData } = useContext(GlobalContext)
  const { authUser } = useContext(AuthContext)
  const [LoadImage, setLoadImage] = useState(false)
  const [openAlert] = AlertErrorAlert()
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)
  const [imagesTer, setImagesTer] = useState([])
  const [previewImage, setPreviewImage] = useState(null)
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { mutate } = useMutation({
    mutationKey: ['Photongraph'],
    mutationFn: requestData
  })

  const handleResponse = res => {
    setIsLoadingPhoto(false)

    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const photography = res?.data?.status?.data?.photography

      if (setPhotographies && photography) {
        setPhotographies(prev => [...prev, photography])
      }

      setImagesTer([])
      return openAlert('Se ha adjuntado la fotografía', '')
    }
  }

  const HandleAddImagen = id => {
    const Photos = new FormData()
    Photos.append('id', id)
    imagesTer.forEach(file => {
      Photos.append('photography[]', file)
    })
    setIsLoadingPhoto(true)

    mutate(
      {
        data: Photos,
        Endpoint: environment.ADD_ATTACHMENT_OR_PHOTOGRAPHY_DISPATCH_CORRECTED,
        PropertyBody: 'sendFormDataContentPOST'
      },
      {
        onSuccess: data => handleResponse(data),
        onError: data => {
          setIsLoadingPhoto(false)
        }
      }
    )
  }

  useEffect(() => {
    if (imagesTer?.length > 0) HandleAddImagen(id)
  }, [imagesTer])

  const UploadImg = () => {
    document.getElementById('display-img').click()
  }

  const DeleteImg = data => {
    const formData = new FormData()
    formData.append('photography_corrected', data?.photography_corrected)

    Swal.fire({
      title: `¿Estas seguro de eliminar la fotografía  "${data.photography_corrected}"?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1F7AC3',
      confirmButtonText: 'Eliminar',
      reverseButtons: true,
      customClass: {
        title: 'class-text'
      }
    }).then(res => {
      if (res.isConfirmed) {
        mutate(
          {
            pageParam: null,
            resourceIdentifier: data?.id,
            data: formData,
            Endpoint: environment.DELETE_PHOTOGRAPHY_DISPATCH_CORRECTED,
            PropertyBody: 'sendFormDataContentPOST'
          },
          {
            onSuccess: data => {
              if (data?.data?.status?.code === 400) {
                return openAlert(data?.data?.status?.message, 'error')
              }
              if (data?.data?.status?.code === 200) {
                const ident = data?.data?.status?.data?.photography_corrected
                const newAttachment = photographies.filter(
                  item => item.photography_corrected !== ident
                )
                if (setPhotographies) {
                  setPhotographies(newAttachment)
                }

                return openAlert('Fotografía eliminada', 'success')
              }
            }
          }
        )
      }
    })
  }

  const MAX_IMAGE_SIZE_BYTES = 3145728
  const MAX_ALLOWED_IMAGES = 5

  const handleImageUpload = event => {
    const selectedFiles = event.target.files
    const totalImages = selectedFiles?.length + imagesTer?.length

    if (
      imagesTer?.length > MAX_ALLOWED_IMAGES ||
      totalImages > MAX_ALLOWED_IMAGES
    ) {
      openAlert(`Se ha excedido el límite de imágenes permitidas (5)`, 'error')
    } else {
      for (let i = 0; i < selectedFiles?.length; i++) {
        const file = selectedFiles[i]

        if (file.size > MAX_IMAGE_SIZE_BYTES) {
          openAlert(
            `La fotografía ${selectedFiles[i].name} excede el limite permitido de Megabytes`,
            'error'
          )
        } else {
          uploadAndProcessImage(file)
        }
      }
    }
  }

  const uploadAndProcessImage = file => {
    const updatedImages = [...imagesTer, file]
    setImagesTer(updatedImages)
  }

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  }, []);

  const openImageView = useCallback(
    (url) => {
      setPreviewImage(url);
      setIsFullscreen(false);
      onOpen();
    },
    [onOpen]
  );

  const shouldShowSwitch = useMemo(() => {
    return (authUser.admin || authUser.is_commerce) && !disableUpload;
  }, [authUser.admin, authUser.is_commerce, disableUpload]);

  return (
    <>
      <Modalcustomizable
        title={'Previsualización '}
        isOpen={isOpen}
        onClose={onClose}
        rightButtonText={'Confirmar'}
        iconComponent={MdOutlineImage}
        hiddenButtons={true}
        size={isFullscreen ? "full" : "6xl"}
      >
        <ZoomImage
          src={previewImage}
          toggleFullscreen={toggleFullscreen}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
        />
      </Modalcustomizable>

      <div id='marginIcon' className='mt-3'>
        {AttachingImages && (
          <Box display='flex' className='d-flex align-items-center'>
            <H2 id='titleSection'>{Title}</H2>
            {shouldShowSwitch ? (
              <Switch
                name="generic_subheading_declaration"
                id="generic_subheading_declaration"
                size="md"
                isChecked={LoadImage}
                onChange={() => setLoadImage(prev => !prev)}
                className='ms-3'
              />
            ) : null}

            <input
              onChange={handleImageUpload}
              multiple
              accept='image/*'
              type='file'
              id='display-img'
            />
          </Box>
        )}

        {LoadImage && (
          <Box mt={1}>
            <ButtonStyled
              className='d-flex justify-content-center align-items-center'
              bgColor='orange'
              onClick={UploadImg}
              isLoading={isLoadingPhoto}
              loadingText='Adjuntando...'
            >
              Adjuntar Imagenes
              <IconGeneric className='text-white ms-1' as={BiImageAdd} />
            </ButtonStyled>
          </Box>
        )}

        <Divider className='my-3' />
        <Grid templateColumns='repeat(auto-fill, minmax(198px, 1fr))' gap={1}>
          {photographies &&
            photographies.map((items, index) => (
              <GridItem>
                <ReactCardWithIcon
                  w={'200px'}
                  photografyUrl={items?.photografy_url}
                  photografyAlt={items?.name_photography_corrected}
                  Icon={MdClear}
                  ColorIcon='rgb(175, 10, 10)'
                  ActionIcon={() => DeleteImg(items)}
                  onClickImageButton={() =>
                    openImageView(items?.photografy_url)
                  }
                  key={index}
                  activeDelete={authUser.admin || authUser.is_commerce}
                />
              </GridItem>
            ))}
        </Grid>
      </div>
    </>
  )
}
