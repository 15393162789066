import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext } from "react";

export const PdfNationalTerritorySalida = ({ values }) => {
  const {
    certificate_inspection_pre_boarding,
    date,
    commercial_invoice,
    value_duta
  } = values;
  const { opcional } = useContext(FormCrudCRUDContext);

  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Cert. de inspecc. preembarque: </strong>{" "}
          {certificate_inspection_pre_boarding}
        </p>

        <p>
          <strong>Fecha: </strong> {date}
        </p>
      </div>

      <div className="col">
        <p>
          <strong>Factura Comercial: </strong> {commercial_invoice}
        </p>
        {opcional && (
          <p>
            <strong>{opcional}: </strong> {value_duta}
          </p>
        )}
      </div>
    </div>
  );
};
