import axios from 'axios';

import env from '../constants/apiConst';

export const __Login = async (body) => {
  // body debe venir en formato FormData

  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    },
    data: body
  };

  const res = await axios(env.LOGIN, options);
  return res;
};

export const __generateSigningToken = async (nickname) => {
  console.log("nickname", nickname);

  const formData = new FormData();
  formData.append("nickname", nickname);

  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=utf-8"
    },
    data: formData
  };

  const res = await axios(env.GENERATE_SIGNING_TOKEN, options);
  return res;
};

export const __getImagenLogin = async () => {
  const options = {
    method: "GET"
  };

  const res = await axios(env.GET_IMAGE_LOGIN, options);
  return res;
};
