import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  List,
  ListItem,
  Link,
  Flex,
  Icon,
  IconButton,
  Button,
  VStack,
  Collapse,
  useColorModeValue,
  keyframes,
} from "@chakra-ui/react";
import { BiLink, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import {
  GrDocumentPdf,
  GrDocumentText,
  GrDocumentExcel,
  GrDocumentWord,
} from "react-icons/gr";

export const getFileIcon = (fileName) => {
  const lowerCaseName = fileName.toLowerCase();
  if (lowerCaseName.includes(".doc") || lowerCaseName.includes(".docx"))
    return GrDocumentWord;
  if (lowerCaseName.includes(".pdf")) return GrDocumentPdf;
  if (lowerCaseName.includes(".xls") || lowerCaseName.includes(".xlsx"))
    return GrDocumentExcel;
  return GrDocumentText;
};

const MAX_VISIBLE_FILES = 5;

// Definir una animación más elegante para el botón
const notificationAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

const ExistingFiles = ({
  existingFiles = [],
  handleDeleteFile = () => {},
  showDeleteButton = true,
  isLoading,
}) => {
  const [showAllFiles, setShowAllFiles] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const visibleFiles = showAllFiles
    ? existingFiles
    : existingFiles.slice(0, MAX_VISIBLE_FILES);
  const hasMoreFiles = existingFiles.length > MAX_VISIBLE_FILES;

  useEffect(() => {
    if (existingFiles.length > 0) {
      setIsAnimating(true);
      const timer = setTimeout(() => setIsAnimating(false), 5000); // Aumentamos la duración de la animación
      return () => clearTimeout(timer);
    }
  }, [existingFiles]);

  return (
    <Box>
      {existingFiles.length > 0 && (
        <VStack
          align="stretch"
          spacing={4}
          bg={bgColor}
          p={4}
          borderRadius="md"
          borderWidth={1}
          borderColor={borderColor}
        >
          <Flex justify="space-between" align="center">
            <Text fontWeight="medium">Archivos existentes:</Text>
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={showAllFiles ? <BiChevronUp /> : <BiChevronDown />}
              onClick={() => setShowAllFiles(!showAllFiles)}
              animation={
                isAnimating ? `${notificationAnimation} 2s infinite` : "none"
              }
              _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
              transition="all 0.2s"
            >
              {showAllFiles
                ? "Mostrar menos"
                : `Ver ${existingFiles.length} ${
                    existingFiles.length === 1 ? "archivo" : "archivos"
                  }`}
            </Button>
          </Flex>
          <Collapse in={showAllFiles} animateOpacity>
            <List spacing={2}>
              {visibleFiles.map((file) => (
                <ListItem key={file?.id}>
                  <Flex
                    align="center"
                    justify="space-between"
                    p={2}
                    _hover={{ bg: "gray.100" }}
                    borderRadius="md"
                  >
                    <Flex align="center" maxW="70%">
                      <Icon
                        as={getFileIcon(
                          file?.name_attachment ?? file?.other_attachment_name
                        )}
                        boxSize={5}
                        mr={3}
                      />
                      <Link
                        href={file?.attachment_url}
                        isExternal
                        color="blue.500"
                        fontWeight="medium"
                        _hover={{ textDecoration: "underline" }}
                        isTruncated
                      >
                        {file?.name_attachment ?? file?.other_attachment_name}
                        <Icon as={BiLink} ml={1} boxSize={4} />
                      </Link>
                    </Flex>

                    {showDeleteButton ? (
                      <IconButton
                        icon={<FaTrash />}
                        aria-label="Eliminar archivo"
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => handleDeleteFile(file)}
                        isLoading={isLoading}
                      />
                    ) : null}
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </VStack>
      )}
    </Box>
  );
};

export default ExistingFiles;
