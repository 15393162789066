import { Td, Tr } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import MenuBox from "components/Menu/MenuIntegracionBox/MainMenu";
import environment from "constants/apiConst";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { __Post } from "request/Petitions/__Post";

const TbodyBoxCorrected = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);

  const checkIfItIsBlocked = async () => {
    const body = new FormData();
    body.append("form_id", data?.integration_certificate_id);
    body.append("type_of", "CIC");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false)
      setTimeout(() => setOpenMenu(true), 500)
    }
  }

  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          to={`/ShowIntegrationBox?idCorrected=${data?.integration_certificate_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.integration_certificate_code}</span>
        </NavLink>
      </Td>


      {data?.integration_certificate_code_old
        ? (
          <Td className="text-center">
            <NavLink
              to={`/ShowIntegrationBox?id=${data?.integration_certificate_id_old}`}
              className="ms-2"
            >
              <span className="formNumber">{data?.integration_certificate_code_old}</span>
            </NavLink>
          </Td>
        )
        : (
          <Td className="text-center">
            <span>N/A</span>
          </Td>
        )}

      <Td className="text-center">{data?.qualified_users_nit}</Td>
      <Td className="text-center">{data?.qualified_users_name}</Td>
      <Td className="text-center">{data?.destiny}</Td>
      <Td className="text-center">{data?.status_corrected}</Td>
      {data?.status_corrected === "PRESENTADO"
        ? (
          <Td className="text-center" isNumeric onClick={() => checkIfItIsBlocked()}>
            {openMenu && <MenuBox data={data} isCorrected={true} />}
          </Td>
        )
        : (
          pathname === "/TabletIntegrationCorrected" &&
            data.status_corrected === "AUTORIZADO"
            ? (
              <Td className="text-center">N/A</Td>
            )
            : (
              <Td className="text-center" isNumeric>
                <MenuBox data={data} isCorrected={true} />
              </Td>
            ))}
    </Tr>
  );
};

export default TbodyBoxCorrected;
