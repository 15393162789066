/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import { AuthContext } from "context/AuthContext";
import { Box, Switch } from "@chakra-ui/react";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { __PostForm } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import GlobalContext from "context/GlobalContext";

export const OptionsForm = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { FormEntry, Number } = queryString.parse(location.search);
  const FormId = FormEntry ?? Number ?? "";
  const [openAlert] = AlertErrorAlert();
  const [free_pass, setFree_pass] = useState(false);
  const [inspection, setInspection] = useState(false);
  const { IsTheFormLocked, setReviewsData } = useContext(GlobalContext);

  useEffect(() => {
    setFree_pass(!!data?.free_pass);
    setInspection(!!data?.inspection);
    setReviewsData((prev) => ({ ...prev, free_pass: data?.free_pass, inspection:  data?.inspection}));
  }, [data]);

  const changeInspectionOrFreePass = async (data) => {
    try {
      const resp = await __PostForm(
        environment.INSPECTION_OR_FREEPASS,
        token,
        data
      );
      if (resp?.data?.status?.code === 200) {
        setReviewsData((prev) => ({
          ...prev,
          free_pass: resp?.data?.status?.data?.free_pass,
          inspection: resp?.data?.status?.data?.inspection,
        }));
        openAlert(resp?.data?.status?.message, "success");
      } else {
        openAlert(resp?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeCheck = (e) => {
    const { name, checked } = e?.target;

    if (name === "free_pass") {
      setFree_pass(checked);
      const formData = new FormData();
      formData.append("entry_form_id", FormId);
      formData.append("free_pass", checked ? 1 : 0);
      formData.append("flag", 2);

      changeInspectionOrFreePass(formData);
    } else if (name === "inspection") {
      setInspection(checked);
      const formData = new FormData();
      formData.append("entry_form_id", FormId);
      formData.append("inspection", checked ? 1 : 0);
      formData.append("flag", 1);

      changeInspectionOrFreePass(formData);
    }
  };

  return (
    (data?.status_entryForm === "AUTORIZADO" ||
      data?.status_entryForm === "APROBADO" ||
      data?.status_entryForm === "PRESENTADO") && (
      <div className="col">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className="mt-2"
        >
          {(authUser.admin === 1 || authUser.is_commerce === 1) &&
            !IsTheFormLocked && (
              <>
                <div className="ms-4 mb-3">
                  <LabeFormlStyled
                    htmlFor=" inspection"
                    style={{
                      marginRight: "15px",
                      width: "100%",
                    }}
                  >
                    Inspección
                  </LabeFormlStyled>
                  <div>
                    <Switch
                      name="inspection"
                      id=" inspection"
                      size="lg"
                      value={inspection}
                      onChange={changeCheck}
                      isChecked={inspection}
                      disabled={data?.status_entryForm === "AUTORIZADO"}
                    />
                  </div>
                </div>
              </>
            )}

          {authUser.is_commerce === 1 && !IsTheFormLocked && (
            <div className="ms-4 mb-3">
              <LabeFormlStyled
                htmlFor=" free_pass"
                style={{
                  marginRight: "15px",
                  width: "100%",
                }}
              >
                Activar pase libre
              </LabeFormlStyled>
              <div>
                <Switch
                  name="free_pass"
                  id=" free_pass"
                  size="lg"
                  value={free_pass}
                  onChange={changeCheck}
                  isChecked={free_pass}
                  disabled={data?.status_entryForm === "AUTORIZADO"}
                />
              </div>
            </div>
          )}
        </Box>
      </div>
    )
  );
};
