export const headerTabs = [
  "Producto elaborado",
  "Materias primas e insumos",
  "Otros costos y gastos",
  "Desperdicios"
];

export const cabeceraOrder = [
  "Editables",
  "Presentados",
  "Autorizados",
  "Correcciones"
];
