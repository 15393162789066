export const formatValues = (valor) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  }).format(valor);
};

export const FormatValueNet = (valor) => {
  return new Intl.NumberFormat("es-ES", {
    style: "unit",
    unit: "kilogram",
    minimumFractionDigits: 4
  }).format(valor);
};
