/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Flex, Spacer, Text } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Cancelar, Continuar } from "pages/RefProductInter/Stilos";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import PasswordField from "components/PasswordField/PasswordField";
import { useForm } from "hooks/useForm";
import { useContext } from "react";
import GestionCrudContext from "context/GestionCrudContext";
import { HrDivider } from "styled/Line.styled";
import { useEffect } from "react";
import { __SHOW_GESTION } from "request/GestionComercial/__GestionCo";
import { AuthContext } from "context/AuthContext";
import { useState } from "react";
import {
  InputLicenciaUnit,
  InputLicenseG
} from "components/InputSelect/InputSelect";
import { __LicensesAllUc } from "request/configuration/__AddLicenses";
import { useSelector } from "react-redux";

const ConfirnmPasswordSchema = Yup.object().shape({
  password: Yup.string().required("¡Inserte la nueva contraseña!"),
  password_confirm: Yup.string()
    .required("¡Inserte nuevamente la contraseña!")
    .oneOf([Yup.ref("password"), null], "¡Las contraseñas deben coindicir!")
});

export const AddGestion = () => {
  const [rol, setRol] = useState([]);
  const [valor, setValor] = useState([]);
  const [userCa, setUserCa] = useState([]);
  const { authUser } = useContext(AuthContext);
  const [status, setStatus] = useState(1);
  const { dataToEdit, editData, createData, loadingCreate, onCloseModal } =
    useContext(GestionCrudContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [values, handleInputChange, reset, setValues] = useForm({
    nickname: "",
    name_person: "",
    lastname_person: "",
    email: ""
  });
  const { nickname, name_person, lastname_person, email } = values;

  const initialValues = {
    password: "",
    password_confirm: ""
  };

  useEffect(() => {
    if (dataToEdit) {
      const initialpos = async () => {
        try {
          const res = await __SHOW_GESTION(token, dataToEdit.id);
          setValues(res.data.status.data);
          console.log(values, "values")
          console.log(res.data.status.data);
          const idLicenses = res.data.status.data.license.license_id;
          console.log(res.data.status.data);
          setStatus(res.data?.status?.data.status);

          const LicenciasSelect = async () => {
            try {
              const res = await __LicensesAllUc(
                token,
                authUser.userQualified
              );
              const restLi = res.data.status.data;
              const labelSelect = restLi.find(
                (element) => element.license_id === idLicenses
              );
              setValor({
                value: idLicenses,
                label: labelSelect.name_license
              });
            } catch (error) {}
          };
          LicenciasSelect();
        } catch (error) {}
      };
      initialpos();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  useEffect(() => {
    const LicenciasSelect = async () => {
      try {
        const res = await __LicensesAllUc(
          token,
          authUser.userQualified
        );
        setUserCa(res?.data?.status?.data);
      } catch (error) {}
    };
    LicenciasSelect();
  }, []);

  const handleSubmit = async (values) => {
    const { password, password_confirm } = values;
    if (dataToEdit) {
      console.log(valor);
      const formData = new FormData();
      formData.append("nickname", nickname);
      formData.append("name_person", name_person);
      formData.append("lastname_person", lastname_person);
      formData.append("email_person", email);
      formData.append("id", dataToEdit.id);
      formData.append("license_id", valor.value);
      formData.append("status", status);
      editData(formData);
    } else {
      const formData = new FormData();
      formData.append("nickname", nickname);
      formData.append("name_person", name_person);
      formData.append("lastname_person", lastname_person);
      formData.append("email_person", email);
      formData.append("license_id", valor.value ? valor.value : "");
      formData.append("password", password);
      formData.append("password_confirm", password_confirm);
      createData(formData);
    }
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar usuarios" : "Agregar usuario"}
      </Text>
      <HrDivider mb={6} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          required
          name={"nickname"}
          value={nickname}
          tag={"Nombre de Usuario"}
          onChange={handleInputChange}
          size={100}
          desicion={false}
          maxLength={50}
          placeholder={'Minimo tres caracteres'}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          required
          name={"name_person"}
          value={name_person.replace(/[^a-zA-Z0-9ñÑ\s]/g, "")}
          onChange={handleInputChange}
          tag={"Nombre"}
          size={45}
          desicion={false}
          maxLength={50}
        />

        <LabelGroup
          required
          name={"lastname_person"}
          value={lastname_person.replace(/[^a-zA-Z0-9ñÑ\s]/g, "")}
          onChange={handleInputChange}
          tag={"Apellido"}
          size={45}
          desicion={false}
          maxLength={50}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          required
          tag={"Correo electrónico"}
          value={email}
          onChange={handleInputChange}
          name="email"
          size={45}
          desicion={false}
        />

        <InputLicenciaUnit
          required
          partidas={userCa}
          tag={"Licencia"}
          size={45}
          setValor={setValor}
          mendigo={valor}
        />
      </div>
      <Formik
        validationSchema={!dataToEdit ? ConfirnmPasswordSchema : ""}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            {!dataToEdit
              ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2rem"
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <PasswordField
                      className="mx-2"
                      size="md"
                      name="password"
                      placeholder="Contraseña"
                    />
                  </div>

                  <div style={{ width: "45%" }}>
                    <PasswordField
                      size="md"
                      name="password_confirm"
                      placeholder="Repita su contraseña"
                    />
                  </div>
                </div>
              )
              : (
                ""
              )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem"
              }}
            ></div>
            <Flex className="my-3">
              <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
              <Spacer />
              <Continuar type="submit">
                {" "}
                {dataToEdit ? "Editar usuarios" : "Agregar usuarios"}
              </Continuar>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
