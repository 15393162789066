/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { __CountUser } from "request/configuration/__CountUser";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { TbodyCountUser } from "../tables/TbodyCountUser";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { FormAccesUser } from "../forms/FormAccesUser";
import { FormAcessShow } from "../FormShow/FormAcessShow";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";

const formEdit = () => {
  return <FormAccesUser />;
};

const formShow = () => {
  return <FormAcessShow />;
};

export const CountUser = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const {
    setRequestEdit,
    setRequestCreate,
    setFormEdit,
    setFormShow,
    update,
    setRequestCreateLicense
  } = useContext(ConfigurationCRUDContext);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);

  const tHead = [
    { name: "Usuario", order: "user" },
    { name: "Correo", order: "email" },
    { name: "Nombre", order: "name_person" },
    { name: "Apellido", order: "lastname_person" },
    { name: "Acceso admin.", order: "staff_status" },
    { name: "Estado", order: "status" },
    { name: "Acciones", order: "actions" }
  ];

  useEffect(() => {
    setFormShow(formShow);
    setFormEdit(formEdit);
  }, [
    setFormShow,
    setFormEdit,
    setRequestEdit,
    setRequestCreate
  ]);

  useEffect(() => {
    setRequestEdit(environment.USER_ACCES_UPDATE);
    setRequestCreate(environment.USER_ACCES_CREATE);
    setRequestCreateLicense(environment.LICENSES_CREATE);
  }, []);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["userAccess", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.GET_USER_ACCES,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST"
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  useEffect(() => {
    setPage(1);
  }, [freeZone_id]);

  /*  const initialpos = useCallback(async () => {
     try {
       const res = await __CountUser(token, page);
       if (res.data.status.code === 200) {
         setCurrentPage(res?.data?.status?.data?.data);
         setMaxPage(res?.data?.status?.data?.last_page);
       } else {
         setCurrentPage([]);
         setMaxPage(1);
       }
     } catch (error) {
     } finally {
       setLoading(false);
     }
   }, [page, token]);

   const changePage = useCallback(async () => {
     setLoading(true);
     try {
       const res = await __CountUser(token, page);
       setCurrentPage(res?.data?.status?.data?.data);
       setMaxPage(res?.data?.status?.data?.last_page);
     } catch (error) {
     } finally {
       setLoading(false);
     }
   }, [page, token]);

   const search = useCallback(async () => {
     let formData = new FormData();
     formData.append("search", q);
     setLoading(true);
     try {
       const res = await __CountUser(token, page, formData);
       setCurrentPage(res?.data?.status?.data?.data);
       setMaxPage(res?.data?.status?.data?.last_page);
     } catch (error) {
     } finally {
       setLoading(false);
     }
   }, [token, q, page]); */

  /*   useEffect(() => {
      initialpos();
    }, [update, initialpos]);

    useEffect(() => {
      changePage();
    }, [page, changePage]);

    useEffect(() => {
      search();
    }, [q, search]);
   */
  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader
          title={"Cuentas de usuarios"}
        ></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={tHead}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodyCountUser}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
