/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormViewPort = () => {
  const initialForm = {
    port_name: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToShow) {
      setForm(dataToShow);
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80"
        }}
      >
        Puerta
      </Text>

      <div className="row">
        <div className="col">
          <FormInputGroup>
            <LabeFormlStyled>Nombre del puerto</LabeFormlStyled>
            <InputFake>{form.port_name}</InputFake>
          </FormInputGroup>
        </div>
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
          Aceptar
        </ButtonStyled>
      </div>
    </>
  );
};

export default FormViewPort;
