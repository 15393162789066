import { Td, Tr } from "@chakra-ui/react";
import MenuForm from "components/Menu/MenuForm";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const TBodyFormEntryAccumulated = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          target="_blank"
          to={`/FormShow?FormEntry=${data?.entryForm_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_entryForm}</span>
        </NavLink>
      </Td>

      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.status_entryForm}</Td>
      <Td className="text-center">{data?.gross_weight}</Td>
      <Td className="text-center">{data?.net_weight}</Td>
      <Td className="text-center">{data?.tare_weight}</Td>
      <Td className="text-center">
        <MenuForm data={data} />
      </Td>
    </Tr>
  );
};
export default TBodyFormEntryAccumulated
