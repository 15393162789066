import axios from "axios";
import env from "../../constants/apiConst";

/**
 * Esta funcion sera la que ejecuté todas las funciones relacionadas con editar en el modulo de configuraciones.
 * Será llamada en el ConfigCRUDContext
 * recibe el endpoint en el que editará, el token y el body
 */
export const __EntryMovementGet = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_PASES_INT}?page=${page || 1}`, options);
  return res;
};

export const __EditCreatePass = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __EditPhoto = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}`, options);
  return res;
}

export const __TypeTransportNp = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPETRANSP_NOT_PAGINATE}`, options);
  return res;
};

export const __ShowPassInt = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_PASES_INT}/${id}`, options);
  return res;
};

export const __ChangeStatus = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __SavePhotogra = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __DeleteAttachment = async (endpoint, token, body, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __DeleteAttachmentPost = async (endpoint, token, body, id) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __DeletePhoto = async (endpoint, token, body, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __DeletePhotoPOST = async (endpoint, token, body, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};


export const __SearchSimpleEntry = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    `${env.GET_ENTRY_PASSES_FILTER}?page=${page || 1}`,
    options
  );
  return res;
};

export const __SearchSimpleEntryPass = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_PASES_INT_CO}?page=${page || 1}`, options);
  return res;
};

export const __SearchSimpleDispatch = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.GET_SEARCH_DESPACHO_INT}?page=${page || 1}`,
    options
  );
  return res;
};

export const __SearchSimpleDispatchPass = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_DISPATCH_CO}?page=${page || 1}`, options);
  return res;
};

export const __BulkLoad = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __ObtenerQr = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __CreateCommentary = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __ImportItemsMassively = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __ObtenerEntradas = async (token, endpoint, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },

    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __AddItemPass = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },

    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __ObtenerItems = async (token, endpoint, idPass, pageItem, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}/${idPass}?page=${pageItem || 1}`, options);
  return res;
};

export const __DeleteItems = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __AddItemPassDispacth = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },

    data: body || ""
  };

  const res = await axios(`${env.ADD_ITEM_DISPACTH_EXIT}`, options);
  return res;
};

export const __EditItemPassDispacth = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },

    data: body || ""
  };

  const res = await axios(`${env.UPDATE_ITEM_DISPACTH_EXIT}`, options);
  return res;
};

export const __CopyItemDispacth = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.COPY_ITEM_DISPACTH_EXIT}/${id}`, options);
  return res;
};

export const __getTypeProducts = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_TYPE_PRODUCTS}`, options);
  return res;
};

export const __GetDataReturnReason = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPIFICATIONSNOTPAGINATE}`, options);
  return res;
};
export const GET_TYPIFICATIONS = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_TYPIFICATIONS}?page=${page || 1}`, options);
  return res;
};

export const __ShowDataReturnReason = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_TYPIFICATIONS}/${id}`, options);
  return res;
};
