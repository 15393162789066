/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useState, useEffect } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { RiSearch2Line } from "react-icons/ri";

import { IconGeneric } from "styled/Icons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrderHover from "components/Tables/TableOrderHover";
import Pagination from "components/Pagination/Pagination";
import { FormSearchReference } from "./FormSearchReference";
import { __GetReferenciaOnly } from "request/Inventory/__InventoryCRUD";
import { useSelector } from "react-redux";
import { TBodySearchRef } from "../TbodyTable/TBodySearchRef";
import { theadSearchRef } from "../Array/ArrayInventoryControl";
import InventoryControlContext from "context/InventoryControlContext";

import { MaxLength } from "utils/MaxLength";
import { useSearchUrl } from "hooks/useSearchUrl";

export const FormReferenceAssistant = ({ onClose, handleSearchSubmit }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["reference", "import_declaration", "operation", "operation_initial", "subheading", "trade_agreement", "date_final", "date_initial", "product_code", "certificate", "description"], nestedProp: "searchAdvance" });
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const {
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    showTableData,
    setShowTableData,
    dataReference
  } = useContext(InventoryControlContext);
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [pageItem, setPageItem] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { values, handleInputChange, reset, setValues, sanitizedValues } = useForm({
    initialState: {
      reference: state?.reference,
      import_declaration: state?.import_declaration,
      operation: state?.operation,
      operation_initial: state?.operation_initial,
      subheading: state?.subheading,
      trade_agreement: state?.trade_agreement,
      date_final: state?.date_final,
      date_initial: state?.date_initial,
      product_code: state?.product_code,
      certificate: state?.certificate,
      description: state?.description,
    },
    returnType: RETURN_TYPES.OBJECT,

  });

  const {
    reference,
    import_declaration,
    date_final,
    date_initial,
    operation,
    operation_initial,
    subheading,
    trade_agreement
  } = values;

  const handleSubmit = () => {
    console.log({ values, sanitizedValues })
    if (reference === "") {
      return openAlert("El campo de la referencia es requerido", "error");
    } else {
      const data = {
        ...sanitizedValues
      };

      setParamsSearch({ params: data });

      handleSearchSubmit(data);
    }
  };

  useEffect(() => {
    if (dataReference) {
      setValues({
        reference: dataReference.inventory_reference_description,
        import_declaration: dataReference.inventory_import_declaration,
        operation: dataReference.inventory_operation_code,
        operation_initial: dataReference.inventory_initial_operation,
        subheading: dataReference.subheading_code,
        trade_agreement: dataReference.inventory_trade_agreement
      });
    }
  }, [dataReference, setValues]);

  const handleResData = async (values) => {
    // console.log(values);
    try {
      const res = await __GetReferenciaOnly(token, page, values);
      const resData = res?.data?.status;
      if (resData.code === 200) {
        setData(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        setShowTableData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchItem}
        onOpen={onOpenSearchItem}
        onClose={onCloseSearchItem}
        title="Buscar referencia"
        size="4xl"
      >
        {showTableData
          ? (
            <div>
              <ButtonStyled onClick={() => setShowTableData(false)}>
                Buscar nuevamente
              </ButtonStyled>
              <TableOrderHover
                thead={theadSearchRef}
                data={data}
                setData={setData}
                loading={loading}
                onCloseModal={onCloseSearchItem}
                tbodyData={TBodySearchRef}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )
          : (
            <FormSearchReference
              onClose={onCloseSearchItem}
              handleSearch={handleResData}
              values={values}
              handleInputChange={handleInputChange}
            />
          )}
      </ModalGeneric>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"reference"}
          value={reference || ""}
          onChange={handleInputChange}
          tag={"Referencia *"}
          size={30}
          tipo={"text"}
        />
        <div style={{ marginTop: 20 }} onClick={onOpenSearchItem}>
          <IconGeneric color="#313131" as={RiSearch2Line} />
        </div>

        <LabelGroup
          name={"import_declaration"}
          value={import_declaration || ""}
          onChange={handleInputChange}
          tag={"Dcl. Importación"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"operation"}
          value={operation || ""}
          onChange={handleInputChange}
          tag={"Operación"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />

        <LabelGroup
          name={"operation_initial"}
          value={operation_initial || ""}
          onChange={handleInputChange}
          tag={"Operación inicial"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"trade_agreement"}
          value={trade_agreement || ""}
          onChange={handleInputChange}
          tag={"Acuerdo"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"subheading"}
          value={subheading || ""}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={45}
          maxLength={MaxLength?.global?.code_subheading}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          value={date_initial || ""}
          onChange={handleInputChange}
          tag={"Fecha inicial"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final || ""}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        />
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
