import axios from "axios";
import environment from "constants/apiConst";

export const __GetInventory = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_INVENTORY}?page=${page || 1}`,
    options
  );
  // console.log(`?page=${page || 1}`, options);
  return res;
};

export const __GetInventoryAudit = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_INVENTORY_AUDIT}?page=${page || 1}`,
    options
  );
  // console.log(`?page=${page || 1}`, options);
  return res;
};

export const __GetExtractTreasury = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.TREASURY_EXPORT_GET}?page=${page || 1}`,
    options
  );
  // console.log(`?page=${page || 1}`, options);
  return res;
};

export const __GetReferencia = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_REFERENCE_AUX}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetReferenciaBalance = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_BALANCE_AUX}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetReferenciaOut = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_REFERENCE_OUT}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetReferenciaOnly = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_REFERENCE_ONLY}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetAuxTemporal = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_TEMP_AUX}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetAuxProduct = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_PRODUCT_AUX}?page=${page || 1}`,
    options
  );
  return res;
};

export const __ExportExcel = async (token, body, endpoint) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};
