import { FIRST_LOGIN } from 'redux/types/firstLogonTypes';

export const firstLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case FIRST_LOGIN.CHANGE_DATA:
      return {
        ...action.payload,
        changeData: true
      };

    case FIRST_LOGIN.CONFIRM_DATA:
      return {
        ...state,
        changeData: false
      };

    default:
      return state;
  }
};
