import axios from "axios";
import env from "../../constants/apiConst";

export const __typesOfDocuments = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.TYPE_OF_DOCUMENTS_GET}?page=${page || 1}`,
    options
  );
  return res;
};

export const __ActiveAndDesactive = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.ACTIVE_AND_DESACTIVE_DOCUMETS}`, options);
  return res;
};

export const __ActiveAndDesactiveIdentifications = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.ACTIVE_AND_DESACTIVE_IDENTIFICATIONS}`, options);
  return res;
};
