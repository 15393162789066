import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  Button,
  Radio,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill, BsEyeFill } from "react-icons/bs";
import ModalDocumentOperation from "components/modal/ModalDocumentOperation";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowOperation } from "request/configuration/__Operations";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import TableOrder from "components/Tables/TableOrder";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import Pagination from "components/Pagination/Pagination";
import { ConfigIconStatus } from "./components/LoadingConfig";
import { GrContactInfo } from "react-icons/gr";
import { AiFillInfoCircle } from "react-icons/ai";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyOperation = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenActivies,
    onOpen: onOpenActivies,
    onClose: onCloseActivies
  } = useDisclosure();
  const [isOpenTr, onOpenTr, onCloseTr] = useModal();
  const [info, setInfo] = useState();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [loadingIcons, setLoadingIcons] = useState(false);

  const OpenHandleModel = (item) => {
    setInfo(item);
    onOpen();
  };

  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(
            token,
            environment.OPERATIONS_DESACTIVAR,
            data.id
          )
          : await __ACTIVAR(token, environment.OPERATIONS_ACTIVAR, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __ShowOperation(token, data.id);
      const result = res.data.status.data.status_operation;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpenTr();
        setLoad(false);
      }
    },
    [page]
  );

  return (
    <>
      <ModalGeneric
        isOpen={isOpenActivies}
        onOpen={onOpenActivies}
        onClose={onCloseActivies}
        title={""}
      >
        {data?.activities.map(
          ({ description_activity, name_activity, dane_activity }) => {
            return (
              <Box
                key={data?.id}
                borderWidth="1px"
                borderRadius="md"
                p={4}
                mb={4}
                boxShadow="md" // Añade una sombra al elemento
                bg="white" // Fondo blanco
              >
                <Text fontSize="xl" fontWeight="bold" mb={2}>
                  {name_activity}
                </Text>
                <Text mb={2}>{description_activity}</Text>
                <Text fontWeight="bold">
                  Actividad dane: {dane_activity ?? "N/A"}
                </Text>
              </Box>
            );
          }
        )}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenTr}
        onOpen={onOpenTr}
        onClose={onCloseTr}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onCloseTr}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <ModalDocumentOperation info={info} isOpen={isOpen} onClose={onClose} />
      <Tr>
        <Td className="text-center">{data?.code_operation}</Td>
        <Td className="text-center">{data?.short_name_operation}</Td>
        <Td className="text-center">{data?.name_typeOperation}</Td>

        <Td className="text-center">
          {data?.reentry_operation === 1 ? "S" : "N"}
        </Td>
        <Td className="text-center">
          {data?.transformation === 1 ? "S" : "N"}
        </Td>
        <Td className="text-center">
          {" "}
          <IconGeneric
            color={data?.activities?.length > 0 ? "#1F7AC3" : "gray"}
            as={AiFillInfoCircle}
            onClick={data?.activities?.length > 0 ? onOpenActivies : ""}
          />
        </Td>
        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.tracking?.some((tracking) => tracking?.status === 1)
              ? "En seguimiento"
              : data?.tracking?.every((tracking) => tracking?.status === 2)
                ? "Estuvo en seguimiento"
                : ""
          }
        >
          <Td>
            {data?.tracking && (
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Radio
                  defaultChecked
                  onClick={() => showTrackingItem(data?.tracking)}
                  colorScheme={
                    data?.tracking?.some((tracking) => tracking?.status === 1)
                      ? "red"
                      : "green"
                  }
                />
              </span>
            )}
          </Td>
        </Tooltip>

        <Td className="text-center">
          <IconGeneric
            color={"#1F7AC3"}
            as={BsEyeFill}
            onClick={() => OpenHandleModel(data)}
          />
        </Td>

        <Tooltip
          placement="top"
          hasArrow
          label={data?.status_operation === 1 ? "Desactivar" : "Activar"}
        >
          <TdState
            onClick={handleState}
            className="text-center"
            isNumeric
            text={data.status_operation}
          >
            <ConfigIconStatus
              loading={loadingIcons}
              status={data?.status_operation}
            />
          </TdState>
        </Tooltip>

        <Td isNumeric className="text-center">
          <MenuRUD data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TbodyOperation;
