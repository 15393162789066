import { Box, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { MinCardMximu } from 'styled/CardTotalesDash'


const TableSystemAuthorizedForms = ({data}) => {
  useEffect(() => {
    console.log(data, 'pendientes');
  }, [data]);
  return (
    <MinCardMximu>
      <TableContainer>
        <Box m={2} justifyContent={'center'} display={'flex'} p={2} >
          <Text as="caption">
            <span id="tituloEstadistica">Formularios autorizados por Sistema</span>
          </Text>
        </Box>
        <Table variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Usuario Calificado</Th>
              <Th isNumeric>Usuario que autorizo</Th>
              <Th isNumeric>Numero de Formulario</Th>
              <Th isNumeric>Fecha de autorización</Th>
              <Th isNumeric>Dias que lleva</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.map((item) => (
              <Tr>
                <Td>{item?.name_qualified}</Td>
                <Td isNumeric>{item?.nickname}</Td>
                <Td isNumeric>
                  <NavLink
                    to={`/FormShow?FormEntry=${item?.entry_form_id}&isSystem=${true}`}>
                    <span className="formNumber">{item?.code_entryForm}</span>
                  </NavLink>
                </Td>
                <Td isNumeric>{item?.date_statusEntryForm}</Td>
                <Td isNumeric>{item?.days}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </MinCardMximu>
  )
}

export default TableSystemAuthorizedForms
