import React from "react";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

import { breadCrumbRoute } from "routes/breadCrumbRoute";

/**
 *  Breadcrumbs - Componente que muestra la ruta de los modulos en los cuales se vaya navegando
 *
 * @return {Component} Retorna componente que muestra los nombres de la ruta en la cual se está navegando.
 */

export const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(breadCrumbRoute());
  return (
    <div style={{ width: "100%", paddingTop: 10 }}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <li
              className="breadcrumb-item"
              aria-current="page"
              key={match.pathname}
            >
              {match?.route?.breadcrumbName}
              {/* {breadcrumb} */}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
