import { Box, SimpleGrid, Tooltip } from '@chakra-ui/react'
import InputSelect from 'components/InputSelect/InputSelect'
import { ListCapacidades } from 'components/Profile/Form/DocumentList'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { IconGeneric } from 'styled/Icons.styled'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { Title } from 'styled/Title'
import { MaxLength } from 'utils/MaxLength'

const ContainerFormRow = ({
  onChange,
  Container,
  isTareEditable,
  isEditable
}) => {
  const [containerData, setContainerData] = useState({
    label: Container?.capacity
  })

  useEffect(() => {
    if (containerData?.value) {
      onChange('capacity_container_corrected', containerData?.value)
    }
  }, [containerData])

  const handleContainerChange = value => {
    onChange('container_corrected', value)
  }

  const handleTareChange = value => {
    onChange('tare_container_corrected', Number(value))
  }

  return (
    <>
      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <LabelGroup
          name={'container_corrected'}
          tag={`Contenedor`}
          size={100}
          value={Container?.name}
          onChange={e => handleContainerChange(e.target.value)}
          maxLength={MaxLength.entry_movement.form_int.container}
          disabled={isEditable}
        />

        <InputSelect
          tag={'Capacidad'}
          data={ListCapacidades}
          set={setContainerData}
          value={containerData}
          isDisabled={isEditable}
        />

        <LabelGroup
          name={'tare_container_corrected'}
          tag={'Peso tara'}
          size={100}
          value={Container?.tare}
          onChange={e => handleTareChange(e.target.value)}
          desicion={isEditable || isTareEditable}
        />
      </SimpleGrid>
    </>
  )
}

const defaultState = {
  container_corrected: '',
  capacity_container_corrected: '',
  tare_container_corrected: ''
}

const ContainerManager = ({
  BlockingInput,
  IsEditable = 'Show',
  containers = [],
  setContainers = null,
  isTareEditable = false
}) => {
  const handleOnChange = (index, name, value) => {
    const copyContainer = [...containers]
    copyContainer[index] = {
      ...copyContainer[index],
      [name]: value
    }
    setContainers && setContainers(copyContainer)
  }

  const handleOnAdd = e => {
    e.preventDefault()
    setContainers && setContainers(containers.concat(defaultState))
  }

  const handleOnRemove = index => {
    const copyContainers = [...containers]
    copyContainers.splice(index, 1)
    setContainers && setContainers(copyContainers)
  }

  return (
    <HeaderSearch>
      <Title size={'18px'} weight={600}>
        Contenedores
        {containers && IsEditable
          ? (
            containers[1]
              ? (
                ''
              )
              : (
                <Tooltip placement='top' hasArrow label='Nuevo contenedor'>
                  <span>
                    <IconGeneric
                      onClick={handleOnAdd}
                      className='ms-2'
                      as={MdAdd}
                    />
                  </span>
                </Tooltip>
              )
          )
          : (
            ''
          )}
        {containers[0] && IsEditable
          ? (
            <Tooltip placement='top' hasArrow label='Limpiar'>
              <span>
                <IconGeneric
                  onClick={() => handleOnRemove(containers[1] ? 1 : 0)}
                  className='text-danger ms-2'
                  as={MdDelete}
                />
              </span>
            </Tooltip>
          )
          : (
            ''
          )}
      </Title>

      <Box>
        {containers.map((x, index) => (
          <ContainerFormRow
            Container={{
              capacity: x?.capacity_container_corrected,
              name: x?.container_corrected,
              tare: x?.tare_container_corrected
            }}
            BlockingInput={BlockingInput}
            onChange={(name, value) => handleOnChange(index, name, value)}
            key={index}
            isTareEditable={isTareEditable}
            isEditable={!IsEditable}
          />
        ))}
      </Box>
    </HeaderSearch>
  )
}

export default ContainerManager
