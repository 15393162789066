export const colorToRgba = (colorValue, opacity = 0.08) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.fillStyle = "#000";
  context.fillStyle = colorValue;
  context.globalAlpha = opacity;
  context.fillRect(0, 0, 1, 1);
  const rgbaValue = context.getImageData(0, 0, 1, 1).data;
  return `rgba(${rgbaValue[0]}, ${rgbaValue[1]}, ${rgbaValue[2]}, ${opacity})`;
};
