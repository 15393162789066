import React from "react";
import App from "./App";
import "./Tab.css";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
