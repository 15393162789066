import React, { useState, useCallback, useMemo } from "react";
import { VStack, Text, Progress, Box, useToast, Heading } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

export const defaultAllowedTypes = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};

const MultiFileUpload = ({
  onUploadComplete,
  onFileSelect,
  uploadToVapor,
  title = "Subir archivos",
  isUploading,
  allowedFileTypes = defaultAllowedTypes,
}) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const toast = useToast();

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast({
          title: "Archivo(s) rechazado(s)",
          description: "Algunos archivos no cumplen con los tipos permitidos.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }

      if (acceptedFiles.length > 0) {
        const uploadPromises = acceptedFiles.map(async (file) => {
          try {
            const vaporResponse = await uploadToVapor(file, (progress) => {
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: Math.round(progress * 100),
              }));
            });
            return {
              name: file.name,
              url: vaporResponse.url,
              vaporId: vaporResponse.uuid,
              bucket: vaporResponse.bucket,
              key: vaporResponse.key,
              headers: vaporResponse.headers,
              extension: file.name.split(".").pop().toLowerCase(),
            };
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            return null;
          }
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        const successfulUploads = uploadedFiles.filter((file) => file !== null);

        if (successfulUploads.length > 0) {
          onFileSelect(successfulUploads);
        }

        if (successfulUploads.length === uploadedFiles.length) {
          onUploadComplete(successfulUploads);
        } else {
          console.warn(
            `${
              uploadedFiles.length - successfulUploads.length
            } files failed to upload`
          );
        }
      }
    },
    [uploadToVapor, onFileSelect, onUploadComplete, toast]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes,
    multiple: true,
  });

  const fileTypesText = useMemo(() => {
    const extensions = Object.values(allowedFileTypes).flat();
    return extensions.join(', ');
  }, [allowedFileTypes]);

  return (
    <VStack spacing={4} align="stretch">
          <Heading size="md" mb={4}>
        {title}
      </Heading>
      <Box
        {...getRootProps()}
        p={4}
        border="2px dashed"
        borderColor="gray.300"
        borderRadius="md"
        bg="gray.50"
        _hover={{ bg: "gray.100", cursor: "pointer" }}
        transition="background-color 0.2s"
      >
        <input {...getInputProps()} disabled={isUploading} />
        <Text color={isDragActive ? "blue.500" : "gray.500"} fontSize="sm" textAlign="center">
          {isDragActive
            ? "Suelta los archivos aquí"
            : `Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos`}
        </Text>
        <Text color="gray.500" fontSize="xs" textAlign="center" mt={2}>
          Tipos de archivo permitidos: {fileTypesText}
        </Text>
      </Box>
     {/*  {Object.entries(uploadProgress).map(([fileName, progress]) => (
        <VStack key={fileName} align="stretch">
          <Text fontSize="sm">{fileName}</Text>
          <Progress value={progress} size="sm" colorScheme="blue" />
        </VStack>
      ))} */}
    </VStack>
  );
};

export default MultiFileUpload;