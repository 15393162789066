/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import FormDestiny from "../forms/Destiny";
import FormDestinyShow from "../FormShow/FormDestinyShow";
import { __DESTINY } from "request/configuration/__Destiny";
import TbodyDestiny from "../tables/TbodyDetiny";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formshowDestiny = () => {
  return <FormDestinyShow />;
};

const FormDestinyCrud = () => {
  return <FormDestiny />;
};

export const Destiny = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Código", order: "code" },
    { name: "Destino", order: "destiny" },
    { name: "Estado", order: "destiny" },
    { name: "Acciones", order: "" }
  ];

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(FormDestinyCrud);
    setFormShow(formshowDestiny);
    setRequestEdit(environment.DESTINY_UPDATE);
    setRequestCreate(environment.DESTINY_CREATE);
    setRequestDelete(environment.DESTINY_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configDestiny"]});
  }, [update, q]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configDestiny", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.DESTINY_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Destino"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyDestiny}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
