import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useCallback, useEffect, useState } from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { ButtonQrPdf } from "./view/ButtonQrPdf";
import { InformacionPesaje } from "./view/InformacionPesaje";
import "./Style/Style.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExportPdfPesaje } from "./ExportPdfPesaje";
import { __PostForm } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import PopoverEdit from "./view/Components/PopoverEdit";
import { Icon, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import {
  __DeleteAttachmentPost,
  __EditCreatePass
} from "request/Pases/__CrudPass";
import { compareArrays } from "./compareArrays ";
import CustomizableButton from "./view/CustomizableButton";
import { RxCircleBackslash } from "react-icons/rx";
import { ChangeStatusWeight } from "./ReportWeighing/Tbody/Menu/ChangeStatusWeight";
import { HiOutlineXCircle } from "react-icons/hi";
import { RiEditBoxLine } from "react-icons/ri";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormEditTareWeight } from "./FormWeighing/FormEditTareWeight";
import { FormEditTara } from "./FormWeighing/FormEditTara";
import AttachedAbove from "pages/Pass-Form/View/Adjunto/AttachedAbove";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import TableOrder from "components/Tables/TableOrder";
import { Title } from "styled/Title";

const tHeadTable = [
  { name: 'Consecutivo', order: 'null' },
  { name: 'Tipo dePeso', order: 'null' },
  { name: 'Peso', order: 'null' },
  { name: 'Placa', order: 'null' },
  { name: 'Fecha', order: 'null' },
  { name: 'Hora', order: 'null' },
  { name: 'Usuario', order: 'null' },

];

const TBody = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          to={`/ShowWeighing?weighing=${data.reweight_id}&Operation=${data.operation}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.consecutive}</span>
        </NavLink>
      </Td>
      <Td className="text-center">{data?.type_weight}</Td>
      <Td className="text-center">{data?.weight}</Td>
      <Td className="text-center">{data?.vehicle_plate}</Td>
      <Td className="text-center">{data?.date}</Td>
      <Td className="text-center">{data?.time}</Td>
      <Td className="text-center">{data?.user_name}</Td>
    </Tr>
  );
};

export const ShowWeighing = () => {
  const location = useLocation(); // navigate
  const { weighing = null, Operation = null } = queryString.parse(
    location.search
  );
  const [loading, setLoading] = useState(false);
  const {
    token,
    admin,
    user_rol: { modules },
    is_qualified_user
  } = useSelector((state) => state.auth.dataUser);
  const [openAlert] = AlertErrorAlert();
  const [form, setForm] = useState(null);
  const [Document, setDocument] = useState([]);
  const [ShowButton, setShowButton] = useState(false);
  const [Reload, setReload] = useState(false);
  const [ReloadEdit, setReloadEdit] = useState(false);
  const [showCardsBruto, setShowCardsBruto] = useState(false);
  const [showCardsTara, setShowCardsTara] = useState(false);
  const [dataToEditTare, setDataToEditTare] = useState([]);
  const [update, setUpdate] = useState(true);
  const [UpdateDocument, setUpdateDocument] = useState(false);
  const [isTare, setIsTare] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authUser } = useContext(AuthContext)

  const style = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  };

  const isAuthorizeToChangeStatus =
    modules?.some((item) =>
      item?.permission?.some(
        (name) => name?.name_permission === "changeStatusWeighing"
      )
    ) || admin === 1;

  const isAuthorizeToEditTare =
    modules?.some((item) =>
      item?.permission?.some(
        (name) => name?.name_permission === "updateWeighing"
      )
    ) || admin === 1;

  const notQualified = is_qualified_user !== 1;

  const notCancelled = form?.status !== "CANCELADO";

  const notDisqualified = form?.status !== "INHABILITADO";

  const initialGet = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const res = await __PostForm(environment.WEIGHING_SHOW, token, data);
        const info = res?.data?.status;
        if (info.code === 200) {
          setForm(info?.data);
          setDocument(info?.data?.attachments);
          setLoading(false);
        } else {
          setLoading(false);
          openAlert(info.message, "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [weighing, setForm, token, Reload, ReloadEdit]
  );

  useEffect(() => {
    if (weighing) {
      const formData = new FormData();
      formData.append("id", weighing);
      formData.append("operation", Operation);
      initialGet(formData);
    }
  }, [weighing, UpdateDocument, Operation, update]);

  const SaveDocument = async (data) => {
    try {
      const res = await __EditCreatePass(
        environment.ATTACH_WEIGHING_PASS,
        token,
        data
      );

      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, "success");
        setShowButton(false);
        setReload(!Reload);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {}
  };

  const hanldeSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", weighing);

    Document.forEach((element) => {
      formData.append("attachment[]", element);
    });

    SaveDocument(formData);
  };

  const HandleDeleteAttachment = async (name, id) => {
    const formData = new FormData();
    const resultado = Document.filter(
      (e) => e.name || e.name_attachment !== name
    );
    setDocument(resultado);
    formData.append("attachment", name);

    try {
      const res = await __DeleteAttachmentPost(
        environment.ATTACH_DELETE_WEIGHING,
        token,
        formData,
        id
      );
      if (res.data.status.code === 403) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let Compare = false;

    if (form && Document) {
      Compare = compareArrays(form?.attachments, Document);
    }

    if (Document.length > 0 && !Compare) {
      setShowButton(true);
    }
  }, [Document, form]);

  const getInfoToEdit = async (typeForm) => {
    setIsTare(typeForm);
    try {
      const formData = new FormData();
      formData.append("id", weighing);
      formData.append("operation", Operation);
      const res = await __PostForm(environment.WEIGHING_SHOW, token, formData);
      const data = res?.data?.status?.data;
      setDataToEditTare(data);
    } catch {
    } finally {
      onOpen();
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Editar tara"
      >
        {isTare
          ? (
            <FormEditTara
              data={dataToEditTare}
              onClose={onClose}
              updateComponent={setUpdate}
            />
          )
          : (
            <FormEditTareWeight
              data={dataToEditTare}
              onClose={onClose}
              updateComponent={setUpdate}
            />
          )}
      </ModalGeneric>

      <div className="container mt-5">
        {loading && <SpinnerComponent />}
        {!loading && (
          <>
            {form && <ExportPdfPesaje id="IdPdfPesaje" values={form} />}
            <HeaderForm
              status={""}
              numberForm={form?.consecutive}
              UserQualified={form?.name_qualified}
              Modulo={"Registro de pesaje en báscula"}
            />
            <ButtonQrPdf numero={form?.consecutive}>
              {notQualified && isAuthorizeToEditTare && (
                <Tooltip placement="top" hasArrow label={'Editar'}>
                  <span>
                    <PopoverEdit
                      data={form}
                      Reload={ReloadEdit}
                      setReload={setReloadEdit}
                    />
                  </span>
                </Tooltip>
              )}
              {notDisqualified && notQualified && isAuthorizeToChangeStatus && authUser.is_qualified_user !== 1 &&(
                <CustomizableButton
                  bgColor="#A6A6A6"
                  LabelText="Inhabilitar"
                  icon={RxCircleBackslash}
                  onClick={() => {
                    ChangeStatusWeight(
                      {
                        pass: Operation === "Entrada" ? 1 : 2,
                        status: "INHABILITADO",
                        weight_id: form?.id
                      },
                      token,
                      openAlert,
                      ReloadEdit,
                      setReloadEdit,
                      setForm,
                      true
                    );
                  }}
                />
              )}
              {notCancelled && notQualified && isAuthorizeToChangeStatus && authUser.is_qualified_user !== 1 && (
                <CustomizableButton
                  bgColor="#F94A25"
                  LabelText="Cancelar"
                  icon={HiOutlineXCircle}
                  onClick={() => {
                    ChangeStatusWeight(
                      {
                        pass: Operation === "Entrada" ? 1 : 2,
                        status: "CANCELADO",
                        weight_id: form?.id
                      },
                      token,
                      openAlert,
                      ReloadEdit,
                      setReloadEdit,
                      setForm,
                      true
                    );
                  }}
                />
              )}
            </ButtonQrPdf>

            <HeaderSearch>
              <InformacionPesaje values={form} />
            </HeaderSearch>

            <HeaderSearch>
              <AttachedAbove
                ServicetoSave={environment.ATTACH_WEIGHING_PASS}
                DeleteToService={environment.ATTACH_DELETE_WEIGHING}
                setUpdate={setUpdateDocument}
                data={form?.attachments}
                formId={weighing}
              />
            </HeaderSearch>

            <HeaderSearch>
              <Title>Información de peso</Title>
              <br />
              <div className="row">
                {form?.gross_weight && (
                  <div className="col">
                    <div className="card">
                      <div
                        id="headeCardPeso"
                        className="card-header text-center"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="text-white">
                            Información de peso bruto
                          </span>

                          <Tooltip placement="top" hasArrow label="Editar tara">
                            <span>
                              {form?.gross_container_1 !== null ||
                              form?.gross_container_2 !== null
                                ? (
                                  <button onClick={() => getInfoToEdit(false)}>
                                    <Icon
                                      className="text-white ms-1"
                                      as={RiEditBoxLine}
                                    />
                                  </button>
                                )
                                : (
                                  ""
                                )}
                            </span>
                          </Tooltip>
                        </div>
                      </div>

                      {/* fijo 1 */}
                      <div className="card-body p-0">
                        <div
                          style={{
                            boxSizing: "border-box",
                            padding: "10px 50px 10px 50px",
                            backgroundColor: "rgba(116, 185, 243, 0.3)"
                          }}
                        >
                          <div style={style}>
                            <span>Peso:</span>
                            <span>{form?.gross_weight} Kg</span>
                          </div>
                          {form?.gross_container_1 && (
                            <>
                              <div style={style}>
                                <span>Serie:</span>
                                <span>{form?.gross_container_1}</span>
                              </div>
                              <div style={style}>
                                <span>Tara:</span>
                                <span>{form?.gross_tare_container_1} Kg</span>
                              </div>
                              <div style={style}>
                                <span>De:</span>
                                <span>
                                  {form?.gross_capacity_container_1} Kg
                                </span>
                              </div>
                            </>
                          )}
                          {form?.gross_container_2 && (
                            <>
                              <div style={style}>
                                <span>Serie:</span>
                                <span>{form?.gross_container_2}</span>
                              </div>
                              <div style={style}>
                                <span>Tara:</span>
                                <span>{form?.gross_tare_container_2} Kg</span>
                              </div>
                              <div style={style}>
                                <span>De:</span>
                                <span>
                                  {form?.gross_capacity_container_2} Kg
                                </span>
                              </div>
                            </>
                          )}
                          <div style={style}>
                            <span>Operario:</span>
                            <span>{form?.gross_user_name}</span>
                          </div>
                          <div style={style}>
                            <span>Fecha:</span>
                            <span>{form?.gross_date_weight}</span>
                          </div>
                          <div style={style}>
                            <span>Báscula:</span>
                            <span>{form?.gross_scale_name}</span>
                          </div>
                          <div style={style}>
                            <span>Hora:</span>
                            <span>{form?.gross_time_weight}</span>
                          </div>
                        </div>
                        {/* fijo */}

                        {form?.gross?.map((elem, index) => (
                          <>
                            <div
                              style={{
                                boxSizing: "border-box",
                                padding: "10px 50px 10px 50px",
                                display: !showCardsBruto ? "none" : "block"
                              }}
                            >
                              <div style={style}>
                                <span>Repeso:</span>
                                <span>{elem?.weight} Kg</span>
                              </div>
                              <div style={style}>
                                <span>Operario:</span>
                                <span>{elem?.user_name}</span>
                              </div>
                              <div style={style}>
                                <span>Fecha:</span>
                                <span>{elem?.date}</span>
                              </div>
                              <div style={style}>
                                <span>Báscula:</span>
                                <span>{elem?.scale_name}</span>
                              </div>
                              <div style={style}>
                                <span>Hora:</span>
                                <span>{elem?.time}</span>
                              </div>
                            </div>
                            {form?.gross?.length > 1 &&
                              index !== form?.gross?.length - 1 && (
                              <span
                                style={{
                                  display: "block",
                                  borderBottom: "solid 1px #E4E7E4"
                                }}
                              ></span>
                            )}
                          </>
                        ))}
                        {form?.gross?.length
                          ? (
                            <span
                              style={{
                                color: "#1F7AC3",
                                cursor: "pointer",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                borderTop: "solid 1px #E2E8F0"
                              }}
                              onClick={() => {
                                !showCardsBruto
                                  ? setShowCardsBruto(true)
                                  : setShowCardsBruto(false);
                              }}
                            >
                              {!showCardsBruto ? "Ver más" : "Ver menos"}
                            </span>
                          )
                          : (
                            ""
                          )}
                      </div>
                    </div>
                  </div>
                )}

                {form?.tare_weight && (
                  <div className="col">
                    <div className="card">
                      <div
                        id="headeCardPeso"
                        className="card-header text-white text-center"
                      >
                        Información de peso tara
                        <Tooltip placement="top" hasArrow label="Editar tara">
                          <span>
                            {form?.tare_container_1 !== null ||
                            form?.tare_container_2 !== null
                              ? (
                                <button onClick={() => getInfoToEdit(true)}>
                                  <Icon
                                    className="text-white ms-1"
                                    as={RiEditBoxLine}
                                  />
                                </button>
                              )
                              : (
                                ""
                              )}
                          </span>
                        </Tooltip>
                      </div>
                      <div className="card-body p-0">
                        <div
                          style={{
                            boxSizing: "border-box",
                            padding: "10px 50px 10px 50px",
                            backgroundColor: "rgba(116, 185, 243, 0.3)"
                          }}
                        >
                          <div style={style}>
                            <span>Peso:</span>
                            <span>{form?.tare_weight} Kg</span>
                          </div>
                          {form?.tare_container_1 && (
                            <>
                              <div style={style}>
                                <span>Serie:</span>
                                <span>{form?.tare_container_1}</span>
                              </div>
                              <div style={style}>
                                <span>Tara:</span>
                                <span>{form?.tare_tare_container_1} Kg</span>
                              </div>
                              <div style={style}>
                                <span>De:</span>
                                <span>
                                  {form?.tare_capacity_container_1} Kg
                                </span>
                              </div>
                            </>
                          )}
                          {form?.tare_container_2 && (
                            <>
                              <div style={style}>
                                <span>Serie:</span>
                                <span>{form?.tare_container_2}</span>
                              </div>
                              <div style={style}>
                                <span>Tara:</span>
                                <span>{form?.tare_container_2} Kg</span>
                              </div>
                              <div style={style}>
                                <span>De:</span>
                                <span>
                                  {form?.tare_capacity_container_2} Kg
                                </span>
                              </div>
                            </>
                          )}
                          <div style={style}>
                            <span>Operario:</span>
                            <span>{form?.tare_user_name}</span>
                          </div>
                          <div style={style}>
                            <span>Fecha:</span>
                            <span>{form?.tare_date_weight}</span>
                          </div>
                          <div style={style}>
                            <span>Báscula:</span>
                            <span>{form?.tare_scale_name}</span>
                          </div>
                          <div style={style}>
                            <span>Hora:</span>
                            <span>{form?.tare_time_weight}</span>
                          </div>
                        </div>
                        {/* fijo */}

                        {form?.tare?.map((elem, index) => (
                          <>
                            <div
                              style={{
                                boxSizing: "border-box",
                                padding: "10px 50px 10px 50px",
                                display: !showCardsTara ? "none" : "block"
                              }}
                            >
                              <div style={style}>
                                <span>Repeso:</span>
                                <span>{elem?.weight} Kg</span>
                              </div>
                              <div style={style}>
                                <span>Operario:</span>
                                <span>{elem?.user_name}</span>
                              </div>
                              <div style={style}>
                                <span>Fecha:</span>
                                <span>{elem?.date}</span>
                              </div>
                              <div style={style}>
                                <span>Báscula:</span>
                                <span>{elem?.scale_name}</span>
                              </div>
                              <div style={style}>
                                <span>Hora:</span>
                                <span>{elem?.time}</span>
                              </div>
                            </div>
                            {form?.gross?.length > 1 &&
                              index !== form?.gross?.length - 1 && (
                              <span
                                style={{
                                  display: "block",
                                  borderBottom: "solid 1px #E4E7E4"
                                }}
                              ></span>
                            )}
                          </>
                        ))}
                        {form?.tare?.length
                          ? (
                            <span
                              style={{
                                color: "#1F7AC3",
                                cursor: "pointer",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                borderTop: "solid 1px #E2E8F0"
                              }}
                              onClick={() => {
                                !showCardsTara
                                  ? setShowCardsTara(true)
                                  : setShowCardsTara(false);
                              }}
                            >
                              {!showCardsTara ? "Ver más" : "Ver menos"}
                            </span>
                          )
                          : (
                            ""
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </HeaderSearch>
           {form?.reweight && (
             <HeaderSearch>
             <Title>Repeso</Title>
             <br />
             <TableOrder
               thead={tHeadTable}
               data={form?.reweight ?? []}
               setData={() => { }}
               loading={loading}
               tbodyData={TBody}
             />
             </HeaderSearch>
           )}
          </>
        )}
      </div>
    </>
  );
};
