import axios from "axios";

import env from "../../constants/apiConst";

export const __ProductoInterno = async (token, body, posicion) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.GET_PRODUCT_INT}?page=${posicion || 1}`,
    options
  );
  return res;
};

export const __EditCreateProduct = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __EditProduct = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __DeleteProduct = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __Arancel = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_UNIT_ARANCEL}/${id}`, options);
  return res;
};

export const __Arancel__ALL = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_ARANCEL_GENERAL}`, options);
  return res;
};

export const __EditCreateGestion = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __EditGestion = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __SHOW_PRDUCTOS = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_PRODUCT_INT}/${id}`, options);
  return res;
};

export const __CHANGE_STATUS_PRODUCTS = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body
  };

  const res = await axios(`${env.CHANGE_STATUS_PRODUCT}`, options);
  return res;
};

export const __CHANGE_STATUS = async (url, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body
  };

  const res = await axios(`${url}`, options);
  return res;
};
