import React, { useState, useContext } from "react";
import { InputGroup, Input, InputRightElement, FormControl, FormLabel, FormErrorMessage, FormHelperText } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { __ChangePassword } from "request/__Me";
import { useSelector } from "react-redux";
import { AuthContext } from "context/AuthContext";

import { Content, ContainerButton, ButtonStyled, ContainerFieldsPassword, TitleFormPassword, ContentNewPassword } from "./Forms.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AiFillEyeInvisible } from "react-icons/ai";
import { BiShow } from "react-icons/bi";
import { EyeIcon } from "components/PasswordField/PasswordField.styled";

const FormChangePassword = ({ setSearchAdvanced, onClose }) => {
  const { authUser } = useContext(AuthContext);
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [password1, setPassword1] = useState(false);
  const [password2, setPassword2] = useState(false);
  const [password3, setPassword3] = useState(false);
  const [messagePassword, setMessagePassword] = useState('');

  const [values, handleInputChange, reset, setValues] = useForm({
    currentPassword: "",
    newPassword: "",
    confirmedNewPassword: ""
  });

  const {
    currentPassword,
    newPassword,
    confirmedNewPassword
  } = values;

  const isError = newPassword !== confirmedNewPassword || messagePassword[0]?.includes('La contraseña debe contener al menos una letra mayúscula');
  const errorOldPassword = messagePassword === 'La antigua contraseña no coincide.'

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('password_old', currentPassword);
    formData.append('password', newPassword);
    formData.append('password_confirm', confirmedNewPassword);

    await sendData(formData);
  };

  const sendData = async (data) => {
    try {
      const res = await __ChangePassword(token, data);
      const code = res?.data?.status?.code;
      const message = res?.data?.status?.message;
      setMessagePassword(res?.data?.status?.message);
      if (code === 200) {
        if (message) {
          openAlert(res.data.status.message, "success");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "success"))
          );
        }
      } else {
        if (message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }

      console.log(res, 'datos formulario')
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContainerFieldsPassword>
      <Content>
        <TitleFormPassword>Recuerda cambiar tu contraseña periódicamente por cuestiones de seguridad informática</TitleFormPassword>
        <FormControl isInvalid={errorOldPassword}>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              type={password1 ? 'text' : 'password'}
              placeholder='Contraseña actual'
              name="currentPassword"
              value={currentPassword}
              onChange={handleInputChange}
            />
            <InputRightElement width='4.5rem'>
              <EyeIcon
                as={password1 ? AiFillEyeInvisible : BiShow}
                onClick={() => setPassword1(!password1)}
                color="#9097BE"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <ContentNewPassword>
          <FormControl style={{ display: 'flex' }} isInvalid={isError}>
            <InputGroup size='md' mr='20px' >
              <Input
                pr='4.5rem'
                type={password2 ? 'text' : 'password'}
                placeholder='Nueva contraseña'
                name={"newPassword"}
                value={newPassword}
                onChange={handleInputChange}
              />
              <InputRightElement width='4.5rem'>
                <EyeIcon
                  as={password2 ? AiFillEyeInvisible : BiShow}
                  onClick={() => setPassword2(!password2)}
                  color="#9097BE"
                />
              </InputRightElement>
            </InputGroup>
            <InputGroup size='md' style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                pr='4.5rem'
                type={password3 ? 'text' : 'password'}
                placeholder='Confirmar contraseña'
                name={"confirmedNewPassword"}
                value={confirmedNewPassword}
                onChange={handleInputChange}
              />
              <InputRightElement width='4.5rem'>
                <EyeIcon
                  as={password3 ? AiFillEyeInvisible : BiShow}
                  onClick={() => setPassword3(!password3)}
                  color="#9097BE"
                />
              </InputRightElement>
              <FormErrorMessage style={{ display: 'block', color: 'red' }}>
                {newPassword !== confirmedNewPassword ? "Las contraseñas no coinciden" : ""}
              </FormErrorMessage>
            </InputGroup>
          </FormControl>
        </ContentNewPassword>
      </Content>
      <ContainerButton>
        <ButtonStyled onClick={handleSubmit}>Editar</ButtonStyled>
      </ContainerButton>
    </ContainerFieldsPassword>
  )
};

export default FormChangePassword;
