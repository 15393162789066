/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { MaxLength } from "utils/MaxLength";
import { Regex } from "utils/Regex";

const validateForm = (form, openAlert) => {
  if (!form?.name_typeOperation.trim()) {
    openAlert("Ingrese el nombre del tipo de operación", "error");
    return false;
  }

  return true;
};

const TypeOperationForm = () => {
  const initialForm = {
    name_typeOperation: "",
    description_typeOperation: "",
    status_typeOperation: 1
  };
  const [openAlert] = AlertErrorAlert();

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [form, handleInputChange, resetForm, setForm] = useForm();
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (dataToEdit) {
      setForm({ ...dataToEdit });
      setCheck(dataToEdit.status_typeOperation === 1);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm(form, openAlert)) return;

    if (dataToEdit) {
      const data = {
        ...form,
        id: dataToEdit.id,
        status_custom: form?.status_typeOperation ? 1 : 0
      };
      // console.log(data);
      editData(data);
    } else {
      createData(form);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Tipo de operación
      </Text>
      <Divider mb={1} />
      <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Tipo de operación" : "Crear Tipo de operación"}
      </Text>
      <Divider mb={3} />

      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="name_typeOperation">
              Nombre Tipo de operación
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_typeOperation"
              id="name_typeOperation"
              value={form?.name_typeOperation || ""}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.typeOperation.name_typeOperation}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="description_typeOperation">
              Descripción
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="description_typeOperation"
              id="description_typeOperation"
              value={form?.description_typeOperation || ""}
              onChange={handleInputChange}
              maxLength={MaxLength.config.typeOperation.description_typeOperation}
            />
          </div>
        </SimpleGrid>

        {dataToEdit && (
          <div>
            <LabeFormlStyled htmlFor="status_typeOperation">
              Activado
            </LabeFormlStyled>
            <div>
              <Switch
                name="status_typeOperation"
                id="status_typeOperation"
                size="lg"
                value={form?.status_typeOperation}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default TypeOperationForm;
