import axios from "axios";

export const __ShowForm = async (token, id, endpoint) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __CommentaryForm = async (token, id, endpoint) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __GetFormatSrt = async (body, endpoint, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      'Content-type': 'application/json; charset=utf-8'
    },
    data: body
  };

  const res = await axios(endpoint, options);
  return res;
};

export const __CreateFormatSrt = async (body, endpoint, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      'Content-type': 'application/json; charset=utf-8'
    },
    data: body
  };

  const res = await axios(endpoint, options);
  return res;
};
