/* eslint-disable no-unused-vars */
import React from "react";
import { CardFormContainer } from "./CardForm.styled";
import { Container } from "styled/Containers.styled";
import { Logo, LogoBarranquilla } from "styled/Logo.styled";
import logo_blue from "assets/logo_efranco_blue.png";

import { PasswordImage } from "styled/PasswordImage.styled";
import { useLocation } from "react-router";

const CardForm = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  // console.log(location);

  return (
    <CardFormContainer>
      {pathname === "/auth/change-password"
        ? (
          <h1 className="titlezf ">ACCESO<br />E-FRANCO <span style={{ color: '#1f7ac3' }}>4.0</span></h1>
        )
        : (
          ""
        )}
      {/* {pathname === '/auth/change-password' ? <Logo width="11rem" /> : <Logo width="14rem" />} */}
      {pathname === "/auth/change-password"
        ? (
          <img src={logo_blue} alt="logo zfbq" style={{ width: '24rem', height: 'auto' }} />
        )
        : (
          <img src={logo_blue} alt="logo zfbq" style={{ width: '24rem', height: 'auto' }} />
        )}
      {pathname === "/auth/recover" && (
        <PasswordImage width="10rem" margin="1rem auto" />
      )}
      {pathname === "/auth/recover/password" && (
        <PasswordImage width="5.6rem" margin="1rem auto" />
      )}
      {pathname === "/auth/change-password" && (
        <PasswordImage width="3.98rem" margin="1rem auto" />
      )}

      <Container>{children}</Container>
    </CardFormContainer>
  );
};

export default CardForm;
