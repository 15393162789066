/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import queryEnv from "constants/queryConst";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import {
  __LicensesActivate,
  __LicensesDeactivate
} from "request/configuration/_Licenses";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { Regex } from "utils/Regex";

const FormLicenses = () => {
  const [openAlert] = AlertErrorAlert();
  const [dataUser, setDataUser] = useState([]);
  const [valueDataUser, setValueDataUser] = useState(null);
  const [status, setStatus] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    name_license: "",
    expireDate_license: "",
    description_license: "",
    active_accounts: 1,
    status_license: status,
  });

  const {
    name_license,
    expireDate_license,
    status_license,
    description_license,
    active_accounts,
  } = values;

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      // console.log(res);
      const dataUser = res?.data?.status?.data.map((items) => ({
        value: items?.id,
        label: items?.nit_qualified + " - " + items?.name_qualified
      }));
      setDataUser(dataUser);
    } catch (error) { }
  }, [token]);

  useEffect(() => {
    getUserQualified();
  }, []);

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      setStatus(dataToEdit?.status_license);
      setValueDataUser({
        value: dataToEdit?.qualifiedUser_id,
        label: dataToEdit?.uc
      });
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const queryClient = useQueryClient();
  const handleSubmit = () => {
    if(typeof values?.active_accounts !== "number") return openAlert("Ingrese la cantidad de usuarios por la licencia", "error");
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_license: status,
        name_qualified: valueDataUser.label,
        qualifiedUser_id: valueDataUser.value
      };

      actLicense(status_license, dataToEdit.id);
      editData(data, () => {
        queryClient.invalidateQueries({ queryKey: [queryEnv?.CONF_LICENCES]});
      });
    } else {
      if (name_license === "") {
        openAlert("Ingrese el nombre de la Licencia", "error");
      } else if (expireDate_license === "") {
        openAlert("Ingrese fecha de vencimiento", "error");
        return false;
      } else {
        const datatoSend = {
          ...values,
          qualifiedUser_id: valueDataUser !== null ? valueDataUser.value : ""
        };
        createData(datatoSend, () => {
          queryClient.invalidateQueries({ queryKey: [queryEnv?.CONF_LICENCES]});
        });
      }
    }
  };

  const actLicense = async (status, id) => {
    try {
      const res =
        status === 1
          ? await __LicensesActivate(token, id)
          : await __LicensesDeactivate(token, id);
    } catch (error) {
      console.log(error);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  const handleQuantityChange = (newValue, event) => {
    if (event?.event === undefined) return;
    setValues((values) => ({
      ...values, [event.event?.target.name]: newValue?.floatValue
    }));
  };

  return (
    <>
      <Text fontSize="md" my={2} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Licencia" : "Crear Licencia"}
      </Text>
      <Divider mb={3} />

      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px" marginBottom={"10px"}>

          <InputSelect
            tag={"Empresa"}
            size={100}
            data={dataUser}
            set={setValueDataUser}
            value={valueDataUser}
          />

          <LabelGroup
            name="name_license"
            tag={"Nombre"}
            value={name_license}
            onChange={(e) => handleRegex(e, "Letras", Regex?.letras)}
            size={100}
            desicion={false}
            tipo={"text"}
            maxLength={80}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <LabelGroup
            name="expireDate_license"
            tag={"Fecha de expiración"}
            value={expireDate_license}
            onChange={handleInputChange}
            size={100}
            desicion={false}
            tipo={"date"}
          />
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            customInput={LabelGroup}
            name={"active_accounts"}
            onValueChange={handleQuantityChange}
            value={active_accounts}
            tag={"No. de usuarios"}
            size={80}
            decimalScale={0}
            desicion={false}
            maxLength={34}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px" className="mt-3">
          <div>
            <LabeFormlStyled htmlFor="expireDate_license">
              Descripción
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="description_license"
              value={description_license}
              onChange={handleInputChange}
              maxLength={5000}
            />
          </div>
        </SimpleGrid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <div style={{ margin: 20 }}>
            {dataToEdit && (
              <SwitchesGroup
                status={status}
                setStatus={setStatus}
                tag={"¿Activo?"}
                size={45}
                mt={20}
              />
            )}
          </div>
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormLicenses;
