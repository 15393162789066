/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { LabelGroup, ALLproduts } from "../LabelGroup";
import { Continuar, Cancelar } from "../Stilos";

import { __ProductsUserAll } from "request/configuration/__ProductProcess";
import ProcessCrudContext from "context/ProcessCrudContext";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { useSelector } from "react-redux";
import { OnlyNumberInput } from "components/Funciones/OnlyNumberInput";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AuthContext } from "context/AuthContext";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";
import { useGlobalContext } from "context/GlobalContext";
import { isObjectNotEmpty, isValid } from "utils/type-check-utils";
import {
  dataNat,
  dataNatProduct,
} from "pages/InventoyControl/SearchTabInventory/ArraySelect";
import InputSelect from "components/InputSelect/InputSelect";

export const AddInsuProcess = () => {
  const [openAlert] = AlertErrorAlert();
  const [nat, setNat] = useState(null);

  const [decimalUc, setDecimalUc] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [fomateo] = useFormatQuantity();

  const location = useLocation();
  const { identificacion = null, id = null } = queryString.parse(
    location.search
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    productiveProces_id: "",
    quantity_product: "",
    description_product: "",
    code_unit_measurement: "",
  });
  const { quantity_product, description_product, code_unit_measurement } =
    values;

  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );

  const { setUser_Qualified } = useGlobalContext();
  const { dataToEdit, setDataToEdit, onCloseModal, createData, editData } =
    useContext(ProcessCrudContext);

  useEffect(() => {
    if (dataToEdit && isObjectNotEmpty(dataToEdit)) {
      setNat({
        label: dataToEdit?.nature_of_product || "",
        value: dataToEdit?.nature_of_product || "",
      });

      setValues(dataToEdit);
    }
  }, [dataToEdit]);

  useEffect(() => {
    if (identificacion) setUser_Qualified(identificacion);
  }, [identificacion]);

  const addProduc = () => {
    const ProductID = parseInt(values?.id);

    const data = {
      qualifiedUser_id: identificacion,
      productiveProcess_id: id,
      product_id: dataToEdit?.product_id || ProductID,
      quantity_product: parseNumberWithCommas(quantity_product),
      id: values?.item_id || dataToEdit?.id,
      ...(isValid(nat?.value) ? { nature_of_product: nat?.value } : {}),
    };

    if (dataToEdit && (values?.item_id || dataToEdit?.id)) {
      if (!values?.code_product) {
        openAlert("Elige un producto", "error");
      } else if (
        quantity_product === "" ||
        quantity_product === null ||
        quantity_product === undefined
      ) {
        openAlert("El campo cantidad producto es requerido", "error");
      } else {
        editData(data, reset);
      }
    } else {
      if (!values?.code_product) {
        openAlert("Elige un producto", "error");
      } else if (
        quantity_product === "" ||
        quantity_product === null ||
        quantity_product === undefined
      ) {
        openAlert("El campo cantidad producto es requerido", "error");
      } else {
        createData(data, reset);
      }
    }
  };

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimalUc
            ? decimalUc
            : Number(decimal_values_precision),
          posibleResult
        ),
      });
    }
  };
  const handleSelectChange = (items, select) => {
    if (!select) {
      Object.keys(values).forEach((key) => {
        values[key] = "";
      });
    }
    const Element = select?.value || null;
    const findElemet = items.find((element) => element.id === Element);

    setValues({
      item_id: dataToEdit?.id,
      convertion_factor_product: parseInt(Element?.convertion_factor_product),
      ...findElemet,
    });
    setDataToEdit({});
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit && isObjectNotEmpty(dataToEdit)
          ? " Editar Item"
          : "Agregar Ítem"}
      </Text>
      <Divider mb={3} />
      <form>
        <Box
          display={"flex"}
          /*    style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
          }} */
          gap={4}
        >
          <ProductSearchBar
            size={100}
            label="Código producto"
            required={true}
            placeHolder="Seleccionar"
            manualId={identificacion}
            value={{ label: values?.code_product, value: values?.id }}
            callBack={handleSelectChange}
            // disabled={dataToEdit ? true : false}
          />

          <LabelGroup
            name={"description_product"}
            value={description_product}
            tag={"Descripción"}
            size={100}
            desicion={true}
          />
        </Box>

        <Box
          display={"flex"}
          gap={4}
    
        >
                <InputSelect
            name={"date_by"}
            size={100}
            tag={"Naturaleza de la Operación"}
            data={dataNatProduct}
            value={nat}
            set={setNat}
          />
          
          <LabelGroup
            name={"code_unit_measurement"}
            value={code_unit_measurement}
            onChange={handleInputChange}
            tag={"Unidad de producto"}
            size={100}
            desicion={true}
          />

    
        </Box>

        <Box
          display={"flex"}
          gap={4}
          style={{
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          <LabelGroup
            type="text"
            tag={"Cantidad producto"}
            size={100}
            onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
            value={quantity_product || ""}
            onBlur={handleBlurCant}
            name={"quantity_product"}
          />
        </Box>
      </form>

      <Flex>
        <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
        <Spacer />
        <Continuar onClick={addProduc}>
          {dataToEdit && isObjectNotEmpty(dataToEdit)
            ? " Editar ítem"
            : "Agregar ítem"}
        </Continuar>
      </Flex>
    </>
  );
};
