import axios from "axios";
import env from "../../constants/apiConst";

export const __Sectors = async (token, posicion, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.SECTORS_GET}?page=${posicion}`, options);
  return res;
};

export const __ShowSectors = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SECTORS_SHOW}/${id}`, options);
  return res;
};
