import React, { useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";

import { ConfigIconStatus } from "./components/LoadingConfig";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useSelector } from "react-redux";
import { fetchConfig } from "request/configuration/__ActivityUser";
import environment from "constants/apiConst";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyZf = ({ data }) => {
  const [loadingIcons, setLoadingIcons] = useState(false);
  const [statusTypeFreeZone, setStatusTypeFreeZone] = useState(
    data?.status_typeFreeZone
  );

  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  let isRequestPending = false;

  const activate = async () => {
    const res = await fetchConfig(environment.ACTIVATE_TYPE_ZF, token, data.id);
    if (res.data.status.code !== 200) {
      openAlert(res.data.status.message, "error");
    } else {
      openAlert(res.data.status.message, "success");
      setStatusTypeFreeZone(res.data.status.data?.status_typeFreeZone);

      // setUpdate(!update);
    }
  };

  const deactivate = async () => {
    const res = await fetchConfig(
      environment.DEACTIVATE_TYPE_ZF,
      token,
      data.id
    );
    if (res.data.status.code !== 200) {
      openAlert(res.data.status.message, "error");
    } else {
      openAlert(res.data.status.message, "success");
      setStatusTypeFreeZone(res.data.status.data?.status_typeFreeZone);
      // setUpdate(!update);
    }
  };

  const handleStatus = async () => {
    if (isRequestPending) {
      return;
    }

    setLoadingIcons(true);
    isRequestPending = true;

    try {
      if (statusTypeFreeZone === 0) {
        await activate();
      } else {
        await deactivate();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
      isRequestPending = false;
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.name_typeFreeZone}</Td>
      <Td className="text-center">{data?.description_typeFreeZone}</Td>
      <Tooltip
        hasArrow
        placement="top"
        label={data?.status_typeFreeZone === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleStatus}
          className="text-center"
          text={data?.status_typeFreeZone}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={statusTypeFreeZone}
          />
        </TdState>
      </Tooltip>
      <Td isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyZf;
