export const Storage = {
  get: (key, defaultValue = []) => {
    try {
      const storedValue = localStorage.getItem(String(key));
      if (storedValue === null) {
        return defaultValue;
      }
      return JSON.parse(storedValue);
    } catch (err) {
      console.error(
        `Error al leer ${key} desde el almacenamiento local: ${err}`
      );
      return defaultValue;
    }
  },

  set: (key, value) => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(String(key), serializedValue);
    } catch (err) {
      console.error(
        `Error al escribir ${key} en el almacenamiento local: ${err}`
      );
    }
  },

  clean: (key) => {
    try {
      localStorage.removeItem(String(key));
    } catch (err) {
      console.error(
        `Error al eliminar ${key} del almacenamiento local: ${err}`
      );
    }
  }
};
