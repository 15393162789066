/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import FormTypeOShow from "../FormShow/FormTypeOShow";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import TypeOperationForm from "../forms/TypeOperationForm";
import TbodyTypeOperation from "../tables/TbodyTypeOperation";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { __TypeOperation } from "request/configuration/__TypeOperation";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const TypeOperation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const {
    update,
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestDelete,
    setRequestCreate
  } = useContext(ConfigurationCRUDContext);

  const thead = [
    { name: "Nombre", order: "name_typeOperation" },
    { name: "Descripción", order: "description_typeOperation" },
    { name: "Estado", order: "status_typeOperation" },
    { name: "Acciones", order: "" }
  ];

  const formEdit = () => {
    return <TypeOperationForm />;
  };
  const formShow = () => {
    return <FormTypeOShow />;
  };

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formShow);
    setRequestEdit(environment.TYPE_OPERATION_UPDATE);
    setRequestCreate(environment.TYPE_OPERATION_CREATE);
    setRequestDelete(environment.TYPE_OPERATION_DELETE);
  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configTypeOperation", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.TYPE_OPERATION_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Tipos de Operación"></ConfigurationHeader>
      {isLoading || (
        <TableOrder
          thead={thead}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyTypeOperation}
        />
      )}
      <Pagination
        thead={thead}
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default TypeOperation;
