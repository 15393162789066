import { Td, Tr } from "@chakra-ui/react";

const TBodyUC = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.nit_qualified ? data?.nit_qualified : "N/A"}</Td>
        <Td className="text-center">{data?.name_qualified ? data?.name_qualified : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyUC;
