import React, { useContext, useEffect } from "react";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { TiCancel } from "react-icons/ti";
import {
  MdContentCopy,
  MdOutlineAccountBalanceWallet,
  MdCancel,
} from "react-icons/md";
import { HiOutlineDocumentSearch } from "react-icons/hi";

import FormCrudCRUDContext from "context/FormCrudContex";
import MonitoringContext from "context/MonitoringContext";
import { BsEyeFill, BsRecordCircle } from "react-icons/bs";
import { useSelector } from "react-redux";

import { FaWpforms } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useStorageQuery } from "hooks/useStorageQuery";
import { localPermissions } from "Permissions/permissions";
import { hasCommonElements } from "utils/type-check-utils";
import { AuthContext } from "context/AuthContext";
import { isValid } from "utils/parseFloat";

const ButtonsAutorizado = ({
  data,
  handleAnular,
  setFollowOrder,
  handleCopy,
}) => {
  const { handleCorrection } = useContext(FormCrudCRUDContext);
  const { authUser } = useContext(AuthContext);

  const superadmin = useSelector((state) => state.auth.dataUser.admin);
  const { admin, is_commerce, is_qualified_user } = useSelector(
    (state) => state.auth.dataUser
  );
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext);
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();
  const Formtype = 2;

  const isOperationInRange = ({ code_operation, temporary }) => {
    const balanceAdjustmentPermission = hasCommonElements(
      permissionList,
      balanceAdjustmentEntry
    );

    const isPermanentValidRange =
      code_operation >= 101 &&
      code_operation <= 299 &&
      temporary === 1 &&
      balanceAdjustmentPermission;

    const isTemporaryValidRange =
      code_operation >= 300 &&
      code_operation <= 699 &&
      temporary !== 1 &&
      balanceAdjustmentPermission;

    const isAdjustBalanceIsAllowed =
      balanceAdjustmentPermission &&
      !isPermanentValidRange &&
      !isTemporaryValidRange;

    return (
      isPermanentValidRange || isTemporaryValidRange || isAdjustBalanceIsAllowed
    );
  };

  const { setData } = useStorageQuery("MovementSRT", null);

  const goToFormatSRT = () => {
    const operation = `${data?.code_operation} - ${data?.short_name_operation}`;
    setData("Ingreso");
    navigate(
      `/FormatSRT?ExitNumber=${
        data?.num_exit_form_id
      }&FormType=${"Ingreso"}&Operation=${operation}`
    );
  };

  const { permissionList } = useSelector((state) => state.permissions);
  const {
    changeStatus: changeStatusEntryForm,
    copy: copyEntryForm,
    balanceAdjustment: balanceAdjustmentEntry,
  } = localPermissions.Entryform;


  return (
    <>
{/*       {data?.final_balance_adjustment === 1 && (
        <Tooltip placement="top" hasArrow label="Ver ajuste asociado">
          <span>
            <ButtonHeaderModule
              onClick={ShowSettingsAssociated}
              bgColor="#1F7AC3"
            >
              <IconGeneric width="16px" className="text-white" as={BsEyeFill} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )} */}

      {(is_commerce === 1 || admin === 1) &&
        !is_qualified_user &&
        (data?.code_operation === 309 || data?.code_operation === 310) && (
          <Tooltip placement="top" hasArrow label="Formato SRT">
            <span>
              <ButtonHeaderModule onClick={goToFormatSRT} bgColor="#191830">
                <IconGeneric
                  width="16px"
                  className="text-white"
                  as={FaWpforms}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement="top" hasArrow label={"Hacer seguimiento"}>
          <span>
            <ButtonHeaderModule
              bgColor="#B8DB1A"
              onClick={onOpenCreateTrackingFmm}
              className="mx-1"
            >
              <IconGeneric
                as={BsRecordCircle}
                color="#fff"
                width="16px"
                className="text-white"
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement="top" hasArrow label={"Detener seguimiento"}>
          <span>
            <ButtonHeaderModule
              bgColor="red"
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
            >
              <IconGeneric
                as={MdCancel}
                width="16px"
                color="#fff"
                className="text-white"
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, copyEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement="top" hasArrow label="Copiar">
          <span>
            <ButtonHeaderModule
              onClick={handleCopy}
              className="mx-1"
              bgColor="#03C39A"
            >
              <IconGeneric
                width="16px"
                className="text-white"
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {isOperationInRange(data) && (
        <Tooltip placement="top" hasArrow label="Ajustar saldo">
          <span>
            <ButtonHeaderModule
              bgColor="#F9A621"
              onClick={() => handleCorrection(data, 1)}
            >
              <IconGeneric
                className="text-white"
                width="16px"
                as={MdOutlineAccountBalanceWallet}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, changeStatusEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement="top" hasArrow label="Corregir">
          <span>
            <ButtonHeaderModule
              className="mx-1"
              bgColor="#F9A621"
              onClick={() => handleCorrection(data, 0)}
            >
              <IconGeneric
                width="16px"
                className="text-white"
                as={HiOutlineDocumentSearch}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {!is_qualified_user &&
        (hasCommonElements(permissionList, changeStatusEntryForm) ||
          authUser.admin === 1) && (
          <Tooltip placement="top" hasArrow label="Anular">
            <span>
              <ButtonHeaderModule bgColor="#41495A" onClick={handleAnular}>
                <IconGeneric
                  width="16px"
                  className="text-white"
                  as={TiCancel}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}
    </>
  );
};

export default ButtonsAutorizado;
