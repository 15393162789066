import { Flex, Spacer } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import InputSelect from "components/InputSelect/InputSelect";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __TradeAgreementsCustoms } from "request/CustomsClearance.js/__CustomsClearence";
import { __getTypeProducts } from "request/Pases/__CrudPass";
import { ButtonStyled } from "styled/Buttons.styled";
import { dataNat, ordenarPor, typeQuantity } from "./ArraySelect";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormSearchInventory = ({ onClose, handleSearchSubmit }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["reference", "description", "import_declaration", "business_unit_code", "group_product", "operation", "operation_initial", "subheading", "certificate", "minimum_quantity", "with_certificate", "with_declaration", "with_trade_agreement", "with_exporter", "without_quantity", "tracking_indicator", "freely_available_material", "orderby_type", "serial", "invoice", "trade_agreement", "nature_inventory", "orderby", "type_product_id", "valueProducts"], nestedProp: "searchAdvance" })

  const token = useSelector((state) => state.auth.dataUser.token);
  const [DataTradeAgrement, setDataTradeAgrement] = useState(null);
  const [dataAcuerdo, setdataAcuerdo] = useState(null);
  const [Freely, setFreely] = useState(null);
  const [nat, setNat] = useState(dataNat.find(item => item?.value === state?.nature_inventory));
  const [ordenarPorSelect, setOrdenarPor] = useState(ordenarPor.find(item => item?.value === state?.orderby));
  const [dataTypeProduct, setDataTypeProduct] = useState(null);
  const [selectDataTypeProduct, setSelectDataTypeProduct] = useState(null);
  const userQualified = useSelector(
    (state) => state.auth.dataUser.userQualified
  );
  const [valueProducts, setValueProducts] = useState(state?.valueProducts || null);
  const [codeProduct, setCodeProduct] = useState(state?.product_code || null);

  /*  ...values,
      ...(dataAcuerdo !== null && {
        trade_agreement: dataAcuerdo?.value,
      }),
      ...(nat !== null && { nature_inventory: nat?.value }),
      ...(ordenarPorSelect !== null && {
        orderby: ordenarPorSelect?.value,
      }),
      ...(selectDataTypeProduct !== null && {
        type_product_id: selectDataTypeProduct?.value,
      }),
      ...(codeProduct && {
        product_code: codeProduct
      }), */

  const { values, handleInputChange, reset } = useForm({
    initialState: {
      reference: state?.reference || null,
      description: state?.description || null,
      import_declaration: state?.import_declaration || null,
      business_unit_code: state?.business_unit_code || null,
      group_product: state?.group_product || null,
      operation: state?.operation || null,
      operation_initial: state?.operation_initial || null,
      subheading: state?.subheading || null,
      certificate: state?.certificate || null,
      minimum_quantity: state?.minimum_quantity || null,
      with_certificate: state?.with_certificate || null,
      with_declaration: state?.with_declaration || null,
      with_trade_agreement: state?.with_trade_agreement || null,
      with_exporter: state?.with_exporter || null,
      without_quantity: state?.without_quantity || null,
      tracking_indicator: state?.tracking_indicator || null,
      freely_available_material: state?.freely_available_material || null,
      orderby_type: state?.orderby_type || null,
      serial: state?.serial || null,
      invoice: state?.invoice || null,
    },
    returnType: RETURN_TYPES?.OBJECT,
  });

  const {
    reference,
    description,
    import_declaration,
    business_unit_code,
    group_product,
    operation,
    operation_initial,
    subheading,
    certificate,
    minimum_quantity,
    serial,
    invoice,
    with_certificate,
    with_declaration,
    with_trade_agreement,
    with_exporter,
    without_quantity,
    tracking_indicator,
    freely_available_material,
    orderby_type,
  } = values;



  const getCodeProduct = (data, target, code) => {
    console.log({ data, target, code });
    setCodeProduct(code?.code_product);
  };

  useEffect(() => {
    console.log({ codeProduct })
  }, [codeProduct])

  const handleSubmit = () => {
    const data = {
      ...values,
      ...(dataAcuerdo !== null && {
        trade_agreement: dataAcuerdo?.value,
      }),
      ...(nat !== null && { nature_inventory: nat?.value }),
      ...(ordenarPorSelect !== null && {
        orderby: ordenarPorSelect?.value,
      }),
      ...(selectDataTypeProduct !== null && {
        type_product_id: selectDataTypeProduct?.value,
      }),
      ...(codeProduct && {
        product_code: codeProduct
      }),
    };


    setParamsSearch({
      params: {
        ...handleSanitizedObj(data),
        ...(valueProducts && { valueProducts }),
      }
    })
    handleSearchSubmit(data);
  };

  const GetInventory = useCallback(async () => {
    try {
      let current = null;
      const res = await __TradeAgreementsCustoms(token);
      const infoAcuerdo = res?.data?.status?.data.map((item) => {
        const data = {
          value: item?.code_tradeAgreement,
          label: item?.code_tradeAgreement + " - " + item?.name_tradeAgreement
        }

        if (item?.code_tradeAgreement === state?.trade_agreement) {
          current = data;
        }

        return data;
      });

      current && setdataAcuerdo(current);
      setDataTradeAgrement(infoAcuerdo);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const typeProduct = useCallback(async () => {
    try {
      const res = await __getTypeProducts(token);
      const data = res?.data?.status?.data;
      const typeProduct = data.map((item) => {
        const data = {
          value: item?.id,
          label: item?.name_typeProduct
        }
        if (item?.id === state?.type_product_id) {
          setSelectDataTypeProduct(data)
        }
        return data;
      });
      setDataTypeProduct(typeProduct);
      // setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    GetInventory();
    typeProduct();
  }, [GetInventory, typeProduct]);

  const [openAlert] = AlertErrorAlert();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <ProductSearchBar
          label="Código producto"
          size={45}
          set={setValueProducts}
          value={valueProducts}
          ButtonSide={true}
          callBack={getCodeProduct}
        /*  validations={(authUser, user_Qualified) => {
          if (authUser.is_qualified_user !== 1 && !user_Qualified)
            return openAlert(
              "Debes seleccionar un usuario calificado",
              "error"
            );
        }} */
        />

        <LabelGroup
          name={"group_product"}
          value={group_product}
          onChange={handleInputChange}
          tag={"Grupo producto"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"reference"}
          value={reference}
          onChange={handleInputChange}
          tag={"Referencia"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"subheading"}
          value={subheading}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={45}
          tipo={"text"}
          maxLength={MaxLength?.global?.code_subheading}
        />
      </div>

      <div className="row py-3">
        <div className="col-sm">
          <RadioBtn
            name={'with_certificate'}
            options={[{ value: "0", label: "Sin certificado" }, { value: "1", label: "Con certificado" }]}
            onChange={handleInputChange}
            state={with_certificate}
          />
        </div>
        <div className="col-sm ms-5">
          <RadioBtn
            name={'with_declaration'}
            options={[{ value: "1", label: "Con declaración" }, { value: "0", label: "Sin declaración" }]}
            onChange={handleInputChange}
            state={with_declaration}
          />
        </div>
      </div>

      <div className="row py-3">
        <div className="col-sm">
          <RadioBtn
            name={'with_trade_agreement'}
            options={[{ value: "1", label: "Con acuerdo" }, { value: "0", label: "Sin acuerdo" }]}
            onChange={handleInputChange}
            state={with_trade_agreement}
          />
        </div>
        <div className="col-sm ms-5">
          <RadioBtn
            name={'with_exporter'}
            options={[{ value: "1", label: "Con exportador" }, { value: "0", label: "Sin exportador" }]}
            onChange={handleInputChange}
            state={with_exporter}
          />
        </div>
      </div>

      <div className="row py-3">
        <div className="col-sm">
          Incluir sin saldo
          <RadioBtn
            name={'without_quantity'}
            onChange={handleInputChange}
            options={[{ value: "1", label: "Sí" }, { value: "0", label: "No" }]}
            state={without_quantity} />
          <br />
          <RadioBtn
            name={'orderby_type'}
            options={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
            onChange={handleInputChange}
            state={orderby_type}
          />
        </div>
        <div className="col-sm ms-5">
          Tiene seguimientos
          <RadioBtn
            name={'tracking_indicator'}
            onChange={handleInputChange}
            options={[{ value: "Si", label: "Sí" }, { value: "No", label: "No" }]}
            state={tracking_indicator}
          />
          <div className="mt-2 ">
            Libre disposición tercero
            <RadioBtn
              name={'freely_available_material'}
              state={freely_available_material}
              onChange={handleInputChange}
              options={[{ value: "Si", label: "Sí" }, { value: "No", label: "No" }]}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"certificate"}
          value={certificate}
          onChange={handleInputChange}
          tag={"Certificado"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />

        <LabelGroup
          name={"description"}
          value={description}
          onChange={handleInputChange}
          tag={"Descripción"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"operation"}
          value={operation}
          onChange={handleInputChange}
          tag={"Operación"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"import_declaration"}
          value={import_declaration}
          onChange={handleInputChange}
          tag={"Dcl. Importacion"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"business_unit_code"}
          value={business_unit_code}
          onChange={handleInputChange}
          tag={"Unidad comercial"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Naturaleza de la Operación"}
            data={dataNat}
            value={nat}
            set={setNat}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Acuerdo comercial"}
            data={DataTradeAgrement}
            value={dataAcuerdo}
            set={setdataAcuerdo}
          />
        </div>

        <LabelGroup
          name={"operation_initial"}
          value={operation_initial}
          onChange={handleInputChange}
          tag={"Operación inicial"}
          size={45}
          tipo={"text"}
        />
      </div>


      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Ordenar por:"}
            data={ordenarPor}
            value={ordenarPorSelect}
            set={setOrdenarPor}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelect
            tag="Tipo de producto"
            data={dataTypeProduct}
            set={setSelectDataTypeProduct}
            value={selectDataTypeProduct}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LabelGroup
          name={"serial"}
          value={serial}
          onChange={handleInputChange}
          tag={"Serial"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
        <LabelGroup
          name={"invoice"}
          value={invoice}
          onChange={handleInputChange}
          tag={"Factura"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"minimum_quantity"}
          value={minimum_quantity}
          onChange={handleInputChange}
          tag={"Cantidad minima"}
          size={45}
          tipo={"text"}
          mb={4}
        // maxiLength={"10"}

        />

        {/*         <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Tipo cantidad"}
            data={typeQuantity}
            value={selectTypeQuantity}
            set={setselectTypeQuantity}
          />
        </div> */}
      </div>


      <Flex className="mt-5">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
