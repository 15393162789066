import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import InputSelect from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import { useCallback, useState, useEffect, useContext } from "react";
import {
  __CalculateQuatity,
  __GetOrderForms,
  __Get_Orden_Production,
  __OrdenNotPaginate
} from "request/IntegrationBox/___IntegrationBox";

import environment from "constants/apiConst";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { TitleHeader } from "components/HeaderForm/Style";
import { Box } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { AuthContext } from "context/AuthContext";
import "../styleBox.css";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { MaxLength } from "utils/MaxLength";
const FormCorrected = ({}) => {
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [dataOrderP, setDataOrderP] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const [productOld, setProductOld] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderProduccion, setOrderProduccion] = useState(null);
  const location = useLocation();
  const [fomateo] = useFormatQuantity();
  const [decimal, setDecimal] = useState(null);

  const { id = "", idCorrected = null } = queryString.parse(location.search);
  const {
    dataLastCertificate,
    dataIdOrder,
    onToggle,
    createDataCorrection,
    setRequestCreateCorrection,
    userQualified
  } = useContext(IntegracionBoxCrudContex);
  const { authUser } = useContext(AuthContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    product_description_old: "",
    product_unit_measurement_code_old: "",
    product_subheading_code_old: "",
    quantity_product_old: "",
    net_weight_kilo_old: "",
    productive_process_description_old: "",
    fob_value_old: "",
    product_description: "",
    product_subheading_code: "",
    product_unit_measurement_code: "",
    productive_process_description: ""
  });

  const [form, handleInputChanges, resets, setForm] = useForm({
    quantity: "",
    net_weight_kilo: "",
    fob_value: "",
    quantity_product: ""
  });

  const { quantity, net_weight_kilo, fob_value, quantity_product } = form;
  const {
    product_description_old,
    product_unit_measurement_code_old,
    product_subheading_code_old,
    quantity_product_old,
    productive_process_description_old,
    net_weight_kilo_old,
    fob_value_old,
    product_description,
    product_subheading_code,
    product_unit_measurement_code,
    productive_process_description
  } = values;

  const getOrderProduccion = useCallback(async () => {
    try {
      const res = await __OrdenNotPaginate(token);
      const dataOrder = res?.data?.status?.data.map((items) => ({
        value: items?.production_order_id,
        label:
          items?.production_order_code +
          "-" +
          items?.production_order_description
      }));
      setDataOrderP(dataOrder);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getOrderProduccion();
  }, [getOrderProduccion]);

  // ESTE ES EL SERVICIO QUE SETEA LA DOS DATAS LA VIEJA Y LA NUEVA
  const getOrderonEditCorrected = async (id, flag) => {
    setLoading(true);
    try {
      const request = await __Get_Orden_Production(
        environment.GET_ORDER_PRODUCTION_CORRECTED_AND_OLD,
        token,
        id
      );
      if (request.data.status.code === 200) {
        const dataCorrected = request.data.status.data;
        console.log(dataCorrected);
        setValues({
          product_description_old: dataCorrected.product_description,
          product_subheading_code_old: dataCorrected.product_subheading_code,
          quantity_product_old: dataCorrected.quantity_product,
          productive_process_description_old:
            String(dataCorrected.productionOrder_id) +
            "-" +
            dataCorrected.productive_process_description,
          product_unit_measurement_code_old:
            dataCorrected.code_unit_measurement,
          net_weight_kilo_old: dataCorrected.net_weight_kilo,
          fob_value_old: dataCorrected.fob_value,

          product_subheading_code:
            dataCorrected.product_subheading_code_corrected,
          product_unit_measurement_code:
            dataCorrected.code_unit_measurement_corrected,
          product_description: dataCorrected.product_description_corrected,
          productive_process_description:
            String(dataCorrected.productionOrderCorrected_id) +
            "-" +
            dataCorrected.productive_process_description_corrected
        });
        if (!flag) {
          setForm({
            fob_value: dataCorrected.fob_value_corrected,
            net_weight_kilo: dataCorrected.net_weight_kilo_corrected,
            quantity_product: dataCorrected.quantity_product_corrected,
            quantity: dataCorrected.quantity_corrected
          });
        }

        setProductOld(dataCorrected.productionOrder_id);

        if (!flag) {
          setOrderProduccion({
            value: dataCorrected.productionOrderCorrected_id,
            label:
              String(dataCorrected.productionOrderCorrected_id) +
              "-" +
              dataCorrected.productive_process_description_corrected
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const ShowPetitionCertificate = (flag) => {
    const dataSend = new FormData();
    dataSend.append(
      "ProductOrderCorrected_id",
      dataIdOrder?.productionOrderCorrected_id
    );
    dataSend.append(
      "integrationCertificateCorrected_id",
      dataIdOrder?.integrationCertificateCorrected_id
    );
    if (dataIdOrder && dataIdOrder !== null) { getOrderonEditCorrected(dataSend, flag && flag); }
  };

  useEffect(() => {
    if (dataIdOrder && dataIdOrder !== null) ShowPetitionCertificate();
  }, [id, dataIdOrder, dataLastCertificate]);

  const getOrdeProductionData = useCallback(async () => {
    try {
      const res = await __GetOrderForms(
        token,
        orderProduccion.value,
        environment.GET_ORDER
      );

      const dataUser = res?.data?.status?.data;

      dataUser?.product.map((item) => {
        setValues({
          ...values,
          product_description: item?.product_description,
          product_subheading_code: item?.product_subheading_code,
          product_unit_measurement_code: item?.product_unit_measurement_code
        });
        setForm({
          ...form,
          net_weight_kilo: 0,
          quantity_product: 0,
          fob_value: 0
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, [token, orderProduccion]);

  useEffect(() => {
    if (orderProduccion && productOld === null) {
      ShowPetitionCertificate(1);
    } else if (
      orderProduccion &&
      productOld !== null &&
      orderProduccion?.value !== productOld &&
      !idCorrected
    ) {
      getOrdeProductionData();
    } else {
      ShowPetitionCertificate(1);
    }
  }, [getOrdeProductionData, orderProduccion, productOld]);

  const getCalculations = useCallback(
    async (dato, campo) => {
      try {
        const res = await __CalculateQuatity(
          environment.CALCULATED_ORDER,
          token,
          dato
        );
        const dataCalculo = res?.data?.status?.data;
        if (res?.data?.status?.code !== 200) {
          openAlert(res.data.status.message, "error");
        } else {
          if (campo === "number") {
            setForm({
              ...form,
              quantity_product: dataCalculo?.quantity_product,
              net_weight_kilo: dataCalculo?.netWeight,
              quantity: Number(dataCalculo?.quantity),
              fob_value: dataCalculo?.fob_value
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, orderProduccion]
  );

  const onChangeCalc = (e) => {
    handleInputChanges(e);
    const nombre = e.target.name;
    const valores = e.target.value;
    if ((valores !== "" || valores !== null) && nombre === "quantity_product") {
      const quantityProduct = new FormData();
      quantityProduct.append(
        "production_order_id",
        orderProduccion !== null ? orderProduccion.value : ""
      );
      quantityProduct.append("cuantity", valores.replaceAll(",", ""));
      quantityProduct.append("uc", true);
      getCalculations(quantityProduct, "number");
    }
  };

  useEffect(() => {
    setRequestCreateCorrection(
      environment.EDIT_ELABORATEDPRODUCT_OR_PRODUCT_CORRECTED
    );
  }, [setRequestCreateCorrection]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let netWeightKilo = net_weight_kilo;
    let quantityProduct = quantity_product;
    let quantitys = quantity;
    let fobValue = fob_value;

    const formData = new FormData();
    formData.append("id_corrected", dataIdOrder.id_corrected);
    formData.append(
      "productionOrderCorrected_id",
      orderProduccion !== null || undefined ? orderProduccion.value : ""
    );
    if (String(net_weight_kilo).includes(",")) {
      netWeightKilo = net_weight_kilo.replaceAll(",", "");
    }
    formData.append("net_weight_kilo_corrected", netWeightKilo);

    if (String(quantity_product).includes(",")) {
      quantityProduct = quantity_product.replaceAll(",", "");
    }
    formData.append("quantity_product_corrected", quantityProduct);

    if (String(quantity).includes(",")) {
      quantitys = quantity.replaceAll(",", "");
    }
    formData.append("quantity_corrected", quantitys);
    if (String(fob_value).includes(",")) {
      fobValue = fob_value.replaceAll(",", "");
    }
    formData.append("fob_value_corrected", fobValue);
    createDataCorrection(formData);
    handleClose();
  };

  const handleClose = () => {
    onToggle();
    reset();
    resets();
    setOrderProduccion(null);
  };

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimal(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userQualified) userC(userQualified.value);
  }, [userC, userQualified]);

  const handleFormat = (valueInput) => {
    valueInput = String(valueInput).replaceAll(",", "");
    return fomateo(
      (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
        ? decimal
        : Number(decimal_values_precision),
      valueInput
    );
  };

  return (
    <>
      {loading && <SpinnerComponent />}

      {!loading && (
        <>
          <TitleHeader className="mb-3">
            {idCorrected !== ""
              ? ""
              : `Corregir Item  ${dataIdOrder?.item_code}`}
          </TitleHeader>
          <div className="d-flex justify-content-between">
            <div className="col-6 borderforms">
              <div className="mb-3">
                <TitleHeader size={"22px"}>
                  Item original Nº {dataIdOrder?.item_code}
                </TitleHeader>
              </div>
              {idCorrected
                ? (
                  <div>
                    <LabelGroup
                      name={"productive_process_description_old"}
                      value={productive_process_description_old}
                      onChange={handleInputChange}
                      tag={"Orden de produccion"}
                      size={90}
                      desicion={true}
                      changeValue={
                        productive_process_description_old !==
                        productive_process_description && "BorderCorrect"
                      }
                    />
                  </div>
                )
                : (
                  <div>
                    <LabelGroup
                      name={"productive_process_description_old"}
                      value={productive_process_description_old}
                      onChange={handleInputChange}
                      tag={"Orden de produccion"}
                      size={90}
                      desicion={true}
                      changeValue={
                        productive_process_description_old !==
                        orderProduccion?.label && "BorderCorrect"
                      }
                    />
                  </div>
                )}

              <div>
                <LabelGroup
                  name={"product_description_old"}
                  value={product_description_old}
                  onChange={handleInputChange}
                  tag={"Descripción del producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_description_old !== product_description &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"product_subheading_code_old"}
                  value={product_subheading_code_old}
                  onChange={handleInputChange}
                  tag={"Subpartida"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_subheading_code_old !== product_subheading_code &&
                    "BorderCorrect"
                  }
                  maxLength={MaxLength?.global?.code_subheading}
                />
              </div>

              <div>
                <LabelGroup
                  name={"quantiquantity_product_oldty_old"}
                  value={handleFormat(quantity_product_old)}
                  onChange={handleInputChange}
                  tag={"Cantidad de producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    String(quantity_product_old) !== String(quantity_product) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"product_unit_measurement_code_old"}
                  value={product_unit_measurement_code_old}
                  onChange={handleInputChange}
                  tag={"Unidad comercial"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_unit_measurement_code_old !==
                      product_unit_measurement_code && "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"net_weight_kilo_old"}
                  value={handleFormat(net_weight_kilo_old)}
                  onChange={handleInputChange}
                  tag={"Peso neto en kilos"}
                  size={90}
                  desicion={true}
                  changeValue={
                    net_weight_kilo_old !== String(net_weight_kilo) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"fob_value_old"}
                  value={handleFormat(fob_value_old)}
                  onChange={handleInputChange}
                  tag={"Valor FOB en US$"}
                  size={90}
                  desicion={true}
                  changeValue={
                    fob_value_old !== String(fob_value) && "BorderCorrect"
                  }
                />
              </div>
            </div>

            <div className="col-6 borderforms">
              <div className="mb-3">
                <TitleHeader size={"22px"}>
                  Item corregido Nº {dataIdOrder?.item_code}
                </TitleHeader>
              </div>
              {idCorrected
                ? (
                  <div>
                    <LabelGroup
                      name={"productive_process_description"}
                      value={productive_process_description}
                      onChange={handleInputChange}
                      tag={"Orden de produccion"}
                      size={90}
                      desicion={true}
                      changeValue={
                        productive_process_description_old !==
                        productive_process_description && "BorderCorrect"
                      }
                    />
                  </div>
                )
                : (
                  <div>
                    <InputSelect
                      className={
                        productive_process_description_old !==
                        orderProduccion?.label && "BorderCorrect"
                      }
                      tag={"Orden de producción"}
                      size={90}
                      data={dataOrderP}
                      set={setOrderProduccion}
                      value={orderProduccion}
                    />
                  </div>
                )}

              <div>
                <LabelGroup
                  name={"product_description"}
                  value={product_description}
                  onChange={handleInputChange}
                  tag={"Descripción del producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_description_old !== product_description &&
                    "BorderCorrect"
                  }
                />
              </div>
              <div>
                <LabelGroup
                  name={"product_subheading_code"}
                  value={product_subheading_code}
                  onChange={handleInputChange}
                  tag={"Subpartida"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_subheading_code_old !== product_subheading_code &&
                    "BorderCorrect"
                  }
                  maxLength={MaxLength?.global?.code_subheading}
                />
              </div>

              <div>
                <LabelGroup
                  name={"quantity_product"}
                  value={quantity_product}
                  onChange={onChangeCalc}
                  tag={"Cantidad de producto"}
                  size={90}
                  desicion={!!idCorrected}
                  changeValue={
                    String(quantity_product_old) !== String(quantity_product) &&
                    "BorderCorrect"
                  }
                  // onBlur={handleBlurCant}
                />
              </div>

              <div>
                <LabelGroup
                  name={"product_unit_measurement_code"}
                  value={product_unit_measurement_code}
                  onChange={handleInputChange}
                  tag={"Unidad comercial"}
                  size={90}
                  desicion={true}
                  changeValue={
                    product_unit_measurement_code_old !==
                      product_unit_measurement_code && "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"net_weight_kilo"}
                  value={handleFormat(net_weight_kilo)}
                  onChange={handleInputChanges}
                  tag={"Peso neto en kilos"}
                  size={90}
                  desicion={true}
                  changeValue={
                    net_weight_kilo_old !== String(net_weight_kilo) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"fob_value"}
                  value={handleFormat(fob_value)}
                  onChange={handleInputChanges}
                  tag={"Valor FOB en US$"}
                  size={90}
                  desicion={true}
                  changeValue={
                    fob_value_old !== String(fob_value) && "BorderCorrect"
                  }
                />
              </div>
            </div>
          </div>

          <div className="mb-3 ms-5">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap={"wrap"}
            >
              <ButtonStyled bgColor={"#bababa"} onClick={handleClose}>
                Cerrar
              </ButtonStyled>
              {idCorrected
                ? (
                  ""
                )
                : (
                  <ButtonStyled type="submit" onClick={handleSubmit}>
                  Guardar
                  </ButtonStyled>
                )}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default FormCorrected;
