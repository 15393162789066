/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Icon,
  Input,
  List,
  ListIcon,
  ListItem,
  Tooltip,
  Text
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import GlobalContext from 'context/GlobalContext'
import useAttachmentHandler from 'pages/Pass-Form/View/Adjunto/hook/useAttachmentHandler '
import useDocumentSubmission from 'pages/Pass-Form/View/Adjunto/hook/useSubmit'
import useUploadButton from 'pages/Pass-Form/View/Adjunto/hook/useUploadButton '
import { useContext, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'

import {
  GrDocumentWord,
  GrDocumentPdf,
  GrDocumentWindows,
  GrDocumentText
} from 'react-icons/gr'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import isArrayNotEmpty, { isArray } from 'utils/type-check-utils'

const Attachment = ({
  title = 'Adjunto',
  formId,
  data = [],
  ServicetoSave,
  DeleteToService,
  callBack = () => { },
  Vaporload = false,
  attachedProperty = {
    delete: 'name_attachment_corrected',
    attachedName: 'name_attachment',
    id: 'id',
    postPropertyName: 'attachment',
    propertyName: 'attachment'
  },
  viewKeyToUpdate = '',
  setAttachment = null,
  accept = '.doc,.docx,image/png,.jpg,.jpeg,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ...props
}) => {
  const [showAlert] = AlertErrorAlert()
  const { handleUpload, inputRef } = useUploadButton()
  const { requestData } = useContext(GlobalContext)
  const { handleAttachment, attachedUpload, resetFile, typeDocument } =
    useAttachmentHandler(3000000, [
      '.doc',
      '.docx',
      '.pdf',
      '.png',
      '.jpg',
      '.jpeg',
      '.xml',
      'image/png',
      'image/jpeg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ])
  const token = useSelector(state => state.auth.dataUser.token)

  const {
    isLoading,
    data: AttachmentData,
    isSuccess,
    isError,
    isPending
  } = useDocumentSubmission({
    getDocuments: attachedUpload,
    formId,
    ServicetoSave,
    postPropertyName: attachedProperty?.postPropertyName,
    typeDocument,
    resetFile,
    token,
    Vaporload,
    viewKeyToUpdate
  })

  const { mutate } = useMutation({
    mutationKey: [attachedProperty?.postPropertyName || 'AttachmentDispatch'],
    mutationFn: requestData
  })

  const processAttachmentDeletion = (data, id, setAttachment) => {
    if (!isArray(data) || !isArrayNotEmpty(data) || !id) return

    const newArray = data.filter(item => item.id !== id)

    if (setAttachment) {
      setAttachment(newArray)
    }

    showAlert('Documento eliminado', 'success')
  }

  const handleResponse = res => {
    const id = res?.data?.status?.data?.id

    if (res?.data?.status?.code === 400) {
      return showAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200 || res?.data?.status?.code === 403) {
      processAttachmentDeletion(data, id, setAttachment)
    }

    callBack()
  }

  const handleAttachmentDelete = async data => {
    const formData = new FormData()
    formData.append(
      attachedProperty?.postPropertyName || 'attachment',
      data?.[attachedProperty?.delete]
    )

    const config = {
      resourceIdentifier: data?.[attachedProperty?.id] || formId,
      data: formData,
      Endpoint: DeleteToService,
      PropertyBody: 'sendFormDataContentPOST'
    }

    mutate(config, {
      onSuccess: data => handleResponse(data),
      onError: data => handleResponse(data)
    })
  }

  const downloadAttachment = url => {
    window.location.assign(url)
  }

  const file = document.querySelector('input[type=file]')

  useEffect(() => {
    if (isSuccess && setAttachment && AttachmentData?.code === 200) {
      const newAttachment =
        AttachmentData?.data?.[attachedProperty?.propertyName] ||
        AttachmentData?.data?.attachment

      const newAttachmentArray = isArray(newAttachment)
        ? newAttachment
        : [newAttachment]

      setAttachment(prev => [
        ...(isArray(prev) ? prev : []),
        ...newAttachmentArray
      ])
      resetFile()
    }

    if (isSuccess) {
      if (file) file.value = ''
      inputRef.current.value = ''
      callBack()
    }

    if (isError) {
      resetFile()
      if (file) file.value = ''
      inputRef.current.value = ''
      callBack()
    }
  }, [isSuccess, isError])

  return (
    <Box {...props}>
      <Text className='mb-2' fontSize={'18px'} fontWeight={600}>
        {title}
      </Text>

      <Input
        onChange={handleAttachment}
        type='file'
        ref={inputRef}
        display={'none'}
        accept={accept}
        multiple
      />

      <Button
        _hover={{
          bg: '#03C39A'
        }}
        bg={'#03C39A'}
        textColor={'white'}
        size='sm'
        w='auto'
        onClick={handleUpload}
        isLoading={isPending}
        loadingText='Adjuntando...'
      >
        {'Agregar Adjunto'}
      </Button>

      <List>
        {data?.map(items => (
          <ListItem key={items?.id || items?.[attachedProperty?.id]}>
            <ListIcon
              as={
                attachedProperty?.attachedName && items?.type
                  ? items?.type?.includes('word')
                    ? GrDocumentWord
                    : items?.type?.includes('pdf')
                      ? GrDocumentPdf
                      : items?.type?.includes('excel')
                        ? GrDocumentWindows
                        : GrDocumentText
                  : items?.[attachedProperty?.attachedName]?.includes('word')
                    ? GrDocumentWord
                    : items?.[attachedProperty?.attachedName]?.includes('pdf')
                      ? GrDocumentPdf
                      : items?.[attachedProperty?.attachedName]?.includes('excel')
                        ? GrDocumentWindows
                        : items?.[attachedProperty?.attachedName]?.includes('xls')
                          ? GrDocumentWindows
                          : GrDocumentText
              }
              color='green.500'
            />
            {items?.[attachedProperty?.attachedName] ? (


              <Tooltip placement='top' hasArrow label='Descargar'>
                <NavLink
                  target='_blank'
                  to={items?.attachment_url}
                  className="ms-2"
                >
                  <span type={Button} className="formNumber">{items?.[attachedProperty?.attachedName]}{' '}</span>
                </NavLink>
              </Tooltip>
            ) : (
              <>{items?.name}</>
            )}
            <Icon
              _hover={{
                cursor: 'pointer'
              }}
              onClick={() => {
                handleAttachmentDelete(items)
              }}
              w={6}
              h={6}
              color='red.500'
              as={AiFillDelete}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default Attachment
