import { View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

/**
 *  TitleOperationEntryPDF - Componente de accordion que despliega una serie de items en el componente del Aside
 *
 * @component
 * @param {Number} code Operacion del formulario.
 * @return {Component} Retorna cadena.
 */

const styles = StyleSheet.create({
  Title: {
    fontSize: '8px'
  }
})

export const TitleOperationEntryPDF = ({ code, mode = 'entry' }) => {
  if (mode === 'exit') {
    if (code === 50) return `${code} - Al resto del territorio nacional`
    if (code >= 201 && code <= 226) return `${code} - Al resto del mundo`
    if (code >= 401 && code <= 450) return `${code} - Al resto del territorio nacional`
    if (code >= 601 && code <= 618) return `${code} - A zona franca`
    if (code >= 801 && code <= 814) return `${code} - De Usuario a Usuario de la Misma Zona Franca`
  }
  if (mode === 'entry') {
    if (code >= 100 && code < 199) return `${code} - Desde el resto del mundo`
    if (code >= 300 && code <= 399) return `${code} - Desde el resto del territorio`
    if (code >= 500 && code <= 599) return `${code} - De Zona Franca a Zona Franca`
    if (code >= 700 && code <= 799) return `${code} - De Usuario a Usuario de la Misma Zona Franca`
  }

  return ''
}
