/* eslint-disable no-unused-vars */
import { createContext, useReducer, useEffect } from "react";
import { firstLoginReducer } from "redux/reducer/firstLoginReducer";
import { passwordReducer } from "../redux/reducer/passwordReducer";
import { useModal } from "hooks/useModal";

export const FirstLoginContext = createContext();

const init = () => {
  return { changeData: false };
};

export const FirstLoginProvider = ({ children }) => {
  const [firstLogin, firstLoginDispatch] = useReducer(
    firstLoginReducer,
    "",
    init
  );
  const [isOpen, openModal, closeModal] = useModal();
  useEffect(() => {
    if (!firstLogin) return;
  }, [firstLogin]);

  const data = {
    firstLogin,
    firstLoginDispatch,
    isOpen,
    openModal,
    closeModal
  };

  return (
    <FirstLoginContext.Provider value={data}>
      {children}
    </FirstLoginContext.Provider>
  );
};
