import React, { useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { __GetDataId } from "request/Petitions/__Get";

const FormViewWeighin = ({ dataToShow, onCloseView }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const initialForm = {
    name: "",
    baud_rate: "",
    data_bits: "",
    parity: "",
    stop_bits: "",
    flowcontrol: ""
  };
  const [form, setForm] = useState(initialForm);

  const viewWeighingMachine = async () => {
    try {
      const res = await __GetDataId(
        environment.SHOW_WEIGHING,
        token,
        dataToShow.id
      );

      setForm(res?.data?.status?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToShow) {
      viewWeighingMachine();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  return (
    <>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Nombre de la báscula</LabeFormlStyled>
              <InputFake>{form?.name}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Tasa de baudios</LabeFormlStyled>
              <InputFake>
                {form?.baud_rate === null ? "N/A" : form.baud_rate}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Bits de datos</LabeFormlStyled>
              <InputFake>
                {form?.data_bits === null ? "N/A" : form.data_bits}
              </InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Paridad</LabeFormlStyled>
              <InputFake>
                {form?.parity === null ? "N/A" : form.parity}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Bits de paradas</LabeFormlStyled>
              <InputFake>
                {form?.stop_bits === null ? "N/A" : form.stop_bits}
              </InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Control de flujo</LabeFormlStyled>
              <InputFake>
                {form?.flowcontrol === null ? "N/A" : form.flowcontrol}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseView}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormViewWeighin;
