import React from "react";
import { TitlePDF } from "styled/Title";

export const HeaderPDF = ({ status }) => {
  return (
    <>
      <div className="container mb-2">
        <TitlePDF size={"18px"} className="text-center">
          Orden de producción de las materias primas e insumos nacionales y
          extranjeros Intervinientes en la Fabricación del Bien {status && "-"}{" "}
          {status && status}
        </TitlePDF>
      </div>
    </>
  );
};
