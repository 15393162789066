/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { IconButton, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import InputSelect, {
  InputDeparture
} from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __DepartmentsAll } from "request/configuration/__Cities";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { useSelector } from "react-redux";
import { Title } from "styled/Title";
import { CheckDisable } from "hooks/useLocalStorage";
import { searchObservation } from "./Functions/searchObservation";
import { useDebounceError } from "hooks/useDebounceError";
import { MaxLength } from "utils/MaxLength";
import { useGlobalContext } from "context/GlobalContext";
import { getFormData } from "redux/actions/dataFormActions";
import { useNotations } from "context/NotationsContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Regex } from "utils/Regex";
import { CgAddR } from "react-icons/cg";

const RestTerritory = ({
  values,
  handleInputChange,
  code,
  formOperation,
  setValues,
  colorNotation = () => { },
  reviewData = {}
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataFormCurrent } = useContext(FormCrudCRUDContext);
  const dataFormRedux = useSelector(getFormData);
  const { updateNotation } = useNotations();
  const location = useLocation()
  const { FormExitNumber = null, FormEntryNumber = null } = queryString.parse(
    location.search
  )
  const {
    dataDepartament,
    setValueDepartament,
    valueDepartament,
    departament,
    setDepartament,
    getDepartament,
    customsadministration,
    setCustomsadministration,
    getAdminCustom,
    adminCustom,
    currentItemLength,
    onOpenSelectAuthorizationItems,
  } = useContext(FormCrudCRUDContext);

  const {
    num_dex_entryForm,
    dex_date_entryForm,
    num_commercial_invoice,
    customs_administration,
    num_import_declaration,
    inspection_act_or_auto,
    num_exit_form,
    integration_box_certificate,
    reviews
  } = values;

  useEffect(() => {
    getDepartament();
    getAdminCustom();
  }, []);

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  const shouldShowInputs =
    code === 309 ||
    code === 310 ||
    code === 314 ||
    code === 316 ||
    code === 318;

  const customStyles = (propertyName) => {
    const color = colorNotation(propertyName);

    if (color) {
      return {
        control: (provided) => ({
          ...provided,
          borderColor: color
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color
        })
      };
    }
  };

  const nameSelect = {
    name_qualified: "Empresa",
    business_name: "Proveedor/Exportador",
    short_name_operation: 'Tipo de Operación"'
  };

  const selectChange = (items, select) => {
    return updateNotation(
      select?.name,
      nameSelect?.[select?.name],
      items?.label,
      dataFormCurrent?.[select?.name],
      "Header"
    );
  };


  useEffect(() => {
    if (FormEntryNumber === null && FormExitNumber === null) {
      setValues((prev) => ({
        ...prev,
        num_exit_form: '',
        integration_box_certificate: ''
      }))
    }
  }, [FormEntryNumber, FormExitNumber])

  return (
    <div className="mx-1 shadow-sm p-1 mb-5" style={{ background: "#f2f2f2" }}>
      {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}

      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del territorio nacional
      </Title>

      {/* </SimpleGrid> */}
      {(code === 312 || code === 322) && (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "26%"
          }}
        >
          <input type="checkbox" />
          <span style={{ marginLeft: "5px" }}>Remisión</span>
        </div>
      )}

      <SimpleGrid minChildWidth="200px" spacing="10px">
        <Tooltip
          hasArrow
          label={reviewData?.num_dex_entryForm?.comment}
          bg={colorNotation("num_dex_entryForm")}
          color="white"
        >
          <div>
            <LabeFormlStyled htmlFor="num_dex_entryForm">
              Nº DEX
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              ColorFake={colorNotation("num_dex_entryForm")}
              name="num_dex_entryForm"
              id="num_dex_entryForm"
              value={num_dex_entryForm}
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              maxLength={MaxLength.formEntry.formEntryNumber.num_dex_entryForm}
              data-input-tag={"Nº DEX"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.num_dex_entryForm}
            />
          </div>
        </Tooltip>

        <Tooltip
          hasArrow
          label={reviewData?.dex_date_entryForm?.comment}
          bg={colorNotation("dex_date_entryForm")}
          color="white"
        >
          <div>
            <LabeFormlStyled>Fecha del DEX</LabeFormlStyled>
            <InputFormStyled
              type={"date"}
              ColorFake={colorNotation("dex_date_entryForm")}
              name="dex_date_entryForm"
              value={
                dex_date_entryForm === null || dex_date_entryForm === undefined
                  ? ""
                  : dex_date_entryForm.slice(0, 10)
              }
              onChange={handleInputChange}
              data-input-tag={"Fecha del DEX"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.dex_date_entryForm}
            />
          </div>
        </Tooltip>
      </SimpleGrid>

      <Tooltip
        hasArrow
        label={reviewData?.num_commercial_invoice?.comment}
        bg={colorNotation("num_commercial_invoice")}
        color="white"
      >
        <div>
          <LabeFormlStyled htmlFor="num_commercial_invoice">
            Nº Factura comercial{" "}
            <span style={{ color: "red" }}>
              {formOperation?.reentry_operation === 0 ? " *" : ""}
            </span>
          </LabeFormlStyled>
          <InputFormStyled
            ColorFake={colorNotation("num_commercial_invoice")}
            type="text"
            name="num_commercial_invoice"
            id="num_commercial_invoice"
            data-input-tag={"Nº Factura comercial"}
            data-item-id={"Header"}
            data-old-Value={dataFormCurrent?.num_commercial_invoice}
            value={num_commercial_invoice || ""}
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            maxLength={
              MaxLength.formEntry.formEntryNumber.num_commercial_invoice
            }
          />
        </div>
      </Tooltip>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-4">
        <Tooltip
          hasArrow
          label={searchObservation({ name: "department" }, reviews)?.comment}
          bg={`${searchObservation({ name: "department" }, reviews)?.status
            }.500`}
          color="white"
        >
          <div>
            <InputDeparture
              partidas={dataDepartament}
              tag={"Departamento"}
              Name="Departamento"
              setDepartament={setDepartament}
              departament={departament}
              setValor={setValueDepartament}
              mendigo={valueDepartament}
            />
          </div>
        </Tooltip>

        <Tooltip
          hasArrow
          label={reviewData?.num_import_declaration?.comment}
          bg={colorNotation("num_import_declaration")}
          color="white"
        >
          <div className="mt-1">
            <LabeFormlStyled htmlFor="num_import_declaration">
              Nº Declaración de Importación
            </LabeFormlStyled>
            <InputFormStyled
              ColorFake={colorNotation("num_import_declaration")}
              type="text"
              name="num_import_declaration"
              id="num_import_declaration"
              value={num_import_declaration || ""}
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              data-input-tag={"Nº Declaración de Importación"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.num_import_declaration}
            />
          </div>
        </Tooltip>

        {/*
        <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_typeProduct?.comment}
              bg={colorNotation("name_typeProduct")}
              color="white"
              placement="bottom-start"
            >
              <div>
                <InputSelect
                  tag="Tipo de producto *"
                  data={dataTypeProduct}
                  set={setSelectDataTypeProduct}
                  value={selectDataTypeProduct}
                  styles={customStyles("name_typeProduct")}
                  onChange={selectChange}
                  name="name_typeProduct"
                  error={
                    colorNotation("name_typeProduct") ||
                    (errorsState.typeProduct_id && !selectDataTypeProduct)
                  }
                />
              </div>
            </Tooltip>

        */}
        <Tooltip
          hasArrow
          label={reviewData?.customs_administration?.comment}
          bg={colorNotation("customs_administration")}
          color="white"
        >
          <div>
            <InputSelect
              tag={"Administración de Aduanas"}
              data={adminCustom}
              set={setCustomsadministration}
              value={customsadministration}
              name={`customs_administration`}
              customColorLabel={colorNotation("customs_administration")}
              Label={reviewData?.customs_administration?.comment}
              LabelBg={colorNotation("customs_administration")}
              styles={customStyles("customs_administration")}
              onChange={selectChange}
            />
          </div>
        </Tooltip>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-4">
        <>
          <Tooltip
            hasArrow
            label={reviewData?.inspection_act_or_auto?.comment}
            bg={colorNotation("inspection_act_or_auto")}
            color="white"
          >
            <div>
              <LabeFormlStyled htmlFor="inspection_act_or_auto">
                Acta o Auto de Inspección
              </LabeFormlStyled>
              <InputFormStyled
                type="text"
                name="inspection_act_or_auto"
                data-input-tag={"Acta o Auto de Inspección"}
                data-item-id={"Header"}
                data-old-Value={dataFormCurrent?.inspection_act_or_auto}
                ColorFake={() => colorNotation("inspection_act_or_auto")}
                id="term"
                value={inspection_act_or_auto}
                onChange={(e) =>
                  handleRegex(e, "all", Regex?.all)
                }
                maxLength={
                  MaxLength.formEntry.formEntryNumber.inspection_act_or_auto
                }
              />
            </div>
          </Tooltip>

          {(shouldShowInputs || formOperation?.reentry_operation === 1) ? (
            <>
              <Tooltip
                hasArrow
                label={reviewData?.num_exit_form?.comment}
                bg={colorNotation("num_exit_form")}
                color="white"
              >
                <div>
                  <LabeFormlStyled htmlFor="num_exit_form">
                    Nº Autorización de Salida
                  </LabeFormlStyled>
                  <div className='d-flex justify-content-center align-items-center'>
                    <InputFormStyled
                      type="text"
                      name="num_exit_form"
                      id="num_exit_form"
                      data-input-tag={"Nº Autorización de Salida"}
                      data-item-id={"Header"}
                      data-old-Value={
                        dataFormCurrent?.num_exit_form
                      }
                      ColorFake={() =>
                        colorNotation("num_exit_form")
                      }
                      value={values?.num_exit_form}
                      onChange={handleInputChange}
                      disabled={
                        dataFormRedux?.num_exit_form &&
                        currentItemLength !== 0
                      }
                    />
                    <Tooltip
                      hasArrow
                      label={'Agregar items'}
                      placement='top'
                    >
                      <IconButton
                        onClick={onOpenSelectAuthorizationItems}
                        h={'34px'}
                        marginBottom={'1rem'}
                        borderRadius={0}
                        borderTopRightRadius={'5px'}
                        borderBottomRightRadius={'5px'}
                        color={'#fff'}
                        _hover={{ backgroundColor: '#1f7ac3' }}
                        backgroundColor={'#1f7ac3'}
                        paddingBottom={'1px'}
                        _active={{ backgroundColor: '#1f7ac3' }}
                        outline={'none'}
                        disabled={!num_exit_form}
                        icon={<CgAddR />}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>

              <Tooltip
                hasArrow
                label={reviewData?.integration_box_certificate?.comment}
                bg={colorNotation("integration_box_certificate")}
                color="white"
              >
                <div>
                  <LabeFormlStyled htmlFor="integration_box_certificate">
                    Nº Autorización de C. Inte
                  </LabeFormlStyled>
                  <InputFormStyled
                    data-input-tag={"Nº Autorización de C. Inte"}
                    data-item-id={"Header"}
                    data-old-Value={
                      dataFormCurrent?.integration_box_certificate
                    }
                    ColorFake={() =>
                      colorNotation("integration_box_certificate")
                    }
                    type="text"
                    name="integration_box_certificate"
                    id="integration_box_certificate"
                    value={integration_box_certificate}
                    onChange={handleInputChange}
                  />
                </div>
              </Tooltip>
            </>
          )
            : (
              ""
            )}
        </>
      </SimpleGrid>
    </div>
  );
};

export default RestTerritory;
