/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text, Box, Divider } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

import { __SelectPaises } from "../../../request/configuration/_Country";
import { __Acuerdos } from "../../../request/configuration/__ASubheading";

import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const FormPort = () => {
  const { onCloseModal, createData, dataToEdit, editData, setDataToEdit } =
    useContext(ConfigurationCRUDContext);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    port_name: ""
  });

  const { port_name } = values;

  useEffect(() => {
    if (dataToEdit) {
      setValues({ ...dataToEdit });
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    if (port_name === "") return openAlert("El campo nombre del puerto es obligatorio", "error")
    // const dataResult = {
    //   country_id: mendigo?.value,

    // };

    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id
      };
      editData(data);
    } else {
      createData(values);
    }
    reset();
  };

  const handleClose = () => {
    onCloseModal();
    setDataToEdit(null);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar puerto" : "Crear puerto"}
      </Text>
      <Divider my={2} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem"
        }}
      >
        <LabelGroup
          name={"port_name"}
          tag={"Nombre del puerto"}
          size={100}
          value={port_name}
          onChange={(e) => handleRegex(e, 'Letras', Regex?.letras) }
          maxLength={MaxLength.config.port.port_name}
          desicion={false}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={handleClose}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};

export default FormPort;
