import { Flex, Spacer } from '@chakra-ui/react'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { __Post } from 'request/Petitions/__Post'
import { ButtonStyled } from 'styled/Buttons.styled'
import { SubTitle } from 'styled/Title'

const ModalRecoverForm = ({ dataRecuperar, onCloseRecuperar, handleChange, movement }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()

  const options = {
    Ingreso: "FMMI",
    Salida: "FMMS"
  }

  const handleClick = async () => {
    const body = new FormData();
    body.append("form_id", dataRecuperar.id);
    body.append("type", 1);
    body.append("type_of", options[movement]);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
    console.log(res, 'res')
    if (res?.data?.status?.code === 200) {
      return handleChange(dataRecuperar?.id)
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  useEffect(() => {
    return async () => {
      if (dataRecuperar) {
        const body = new FormData();
        body.append("form_id", dataRecuperar.id);
        body.append("type", 0);
        body.append("type_of", options[movement]);
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
        console.log(movement, 'movement')
        console.log(dataRecuperar.id, 'dataRecuperar')
      }
    }
  }, [dataRecuperar])

  return (
    <div>
      <SubTitle>
          ¿Está seguro que desea recuperar el formulario{" "}
        {dataRecuperar?.code_entryForm || dataRecuperar?.code_outputForm}?
      </SubTitle>
      <div className="d-flex my-2 ">
        <div className="bg-warning p-1">
          <SubTitle>Importante</SubTitle>
        </div>
        <div className="p-1">
          <SubTitle className="ms-2">
              El documento regresara al estado borrador.
          </SubTitle>
        </div>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseRecuperar}>
            Cancelar
        </ButtonStyled>
        <Spacer/>
        <ButtonStyled
          bgColor={"#03C39A"}
          onClick={handleClick}
        >
            Continuar
        </ButtonStyled>
      </Flex>
    </div>
  )
}

export default ModalRecoverForm
