import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import { formaterCant } from "components/Funciones/formaterCant";

const TbodyViewUpdate = ({ data }) => {
  return (
    <Tr>

      {/* Formulario de ingreso */}
      <Td className="text-center">
        ${formaterCant(data?.input)}
      </Td>
      <Td className="text-center">
        ${formaterCant(data?.income_schedule)}
      </Td>

      {/* Formulario de salida */}
      <Td className="text-center">
        ${formaterCant(data?.output)}
      </Td>
      <Td className="text-center">
        ${formaterCant(data?.output_annex)}
      </Td>

      {/* Corregido de ingreso */}
      <Td className="text-center"> ${formaterCant(data?.corrected_income)} </Td>
      <Td className="text-center"> ${formaterCant(data?.corrected_schedule_income)} </Td>


      {/* Corregido de salida */}
      <Td className="text-center"> ${formaterCant(data?.corrected_output)} </Td>
      <Td className="text-center"> ${formaterCant(data?.output_corrected_annex)} </Td>

      {/* Corregido de certificado de integración */}
      <Td className="text-center"> ${formaterCant(data?.fixed_integration_certificate)} </Td>
      <Td className="text-center"> ${formaterCant(data?.corrected_annex_integration_table)} </Td>

      {/* Pase de entrada */}
      <Td className="text-center"> ${formaterCant(data?.entrance_passes)} </Td>
      <Td className="text-center"> ${formaterCant(data?.entrance_pass_annex)} </Td>

      {/* Certificado de integración */}
      <Td className="text-center"> ${formaterCant(data?.integration_certificate)} </Td>

      {/* Matriz de insumo */}
      <Td className="text-center"> ${formaterCant(data?.production_order)} </Td>

      {/* Declaración de importación */}
      <Td className="text-center"> ${formaterCant(data?.import_declaration)} </Td>

      {/* Despacho agrupado */}
      <Td className="text-center"> ${formaterCant(data?.group_dispatch)} </Td>

      {/* Pesaje en báscula */}
      <Td className="text-center"> ${formaterCant(data?.scale_weighing)} </Td>


      {/* Certificado de operaciones de comercio exterior */}
      <Td className="text-center"> ${formaterCant(data?.certificate_foreign_trade_operations)} </Td>

      {/* Certificado de Usuario */}
      <Td className="text-center"> ${formaterCant(data?.user_certificate)} </Td>

      {/* Fecha */}
      <Td className="text-center">{data?.date_rate}</Td>

      {/* Usuario */}
      <Td className="text-center">{data?.nickname}</Td>

      {/* Estado */}
      <Td className="text-center" style={data?.status === 1 ? { color: "#58B158" } : { color: "#F9A621" }} fontWeight={"bold"}>
        {data?.status === 1 ? "Creado" : "Actualizado"}
      </Td>
    </Tr>
  );
};

export default TbodyViewUpdate;
