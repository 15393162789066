import React from "react";
import { PDFFreeZone } from "./PDFFreeZone";
import { PDFFromUserToUser } from "./PDFFromUserToUser";
import { PDFRestWorldEntry } from "./PDFRestWorldEntry";
import { PDFRrestTerritory } from "./PDFRrestTerritory";

export const HeaderOperation = ({ values }) => {
  const { code_operationCorrected } = values;

  return (
    <div>
      {code_operationCorrected >= 101 && code_operationCorrected < 120
        ? (
          <PDFRestWorldEntry data={values} />
        )
        : code_operationCorrected >= 301 && code_operationCorrected <= 330
          ? (
            <PDFRrestTerritory data={values} />
          )
          : code_operationCorrected >= 501 && code_operationCorrected <= 518
            ? (
              <PDFFreeZone data={values} />
            )
            : code_operationCorrected >= 701 && code_operationCorrected <= 714
              ? (
                <PDFFromUserToUser data={values} />
              )
              : (
                ""
              )}
    </div>
  );
};
