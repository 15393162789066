import React from "react";
import { Flex, SimpleGrid, Spacer } from "@chakra-ui/react";

import InputSelect from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

/**
 *  FormModal - Componente para realizar busquedas busqueda avanzada para el modulo de reporte de seguridad
 *
 * @component
 * @return {Component} Retorna componente JSX
 */

const typeRegister = [
  {
    label: "Entrada",
    value: "entry"
  },
  {
    label: "Salida",
    value: "output"
  }
];

const FormModal = ({
  values,
  onClose,
  vehicleDoor,
  typeTranspor,
  handleSubmit,
  setVehicleDoor,
  selectRegister,
  dataVehicheDoor,
  setselectRegister,
  handleInputChange,
  selectTypeTransport,
  setSelectTypeTransport
}) => {
  const [openAlert] = AlertErrorAlert();

  const handleClickSubmit = () => {
    if (!selectRegister) {
      return openAlert("Seleccione un tipo de registro", "error");
    }
    if (!vehicleDoor) {
      return openAlert("Seleccione una puerta", "error");
    }
    handleSubmit();
  };

  const handleChange = (e, Limit) => {
    const value = e.target.value;
    if (value.length > Limit) {
      openAlert(`Maximo ${Limit} valores permitidos`, "error");
      return;
    }
    if (/[^a-zA-Z0-9]/.test(value)) {
      openAlert("Caracteres especiales no permitidos", "error");
      return;
    }
    handleInputChange(e);
  };

  return (
    <div>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <InputSelect
            data={typeRegister}
            value={selectRegister}
            set={setselectRegister}
            tag="Seleccionar tipo de registro"
          />
        </div>
        <div className="spac">
          <InputSelect
            value={vehicleDoor}
            set={setVehicleDoor}
            data={dataVehicheDoor}
            tag="Puerta Vehicular"
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroup
            size={100}
            tag="Placa vehículo"
            name="vehicle_plate"
            value={
              values.vehicle_plate ? values.vehicle_plate.toUpperCase() : ""
            }
            onChange={(e) => handleChange(e, 7)}
          />
        </div>
        <div className="spac">
          <LabelGroup
            size={100}
            // desicion={true}
            tag="Nombre conductor"
            name="driver"
            value={values.driver}
            onChange={handleInputChange}
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroup
            size={100}
            // desicion={true}
            tag="Cédula conductor"
            name="identification"
            value={values.identification}
            onChange={handleInputChange}
          />
        </div>
        <div className="spac">
          <InputSelect
            value={selectTypeTransport}
            set={setSelectTypeTransport}
            data={typeTranspor}
            tag="Tipo de transporte"
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabeFormlStyled>Fecha de inicio</LabeFormlStyled>
          <InputFormStyled
            type="date"
            name="date_start"
            value={values?.date_start}
            onChange={handleInputChange}
          />
        </div>
        <div className="spac">
          <LabeFormlStyled>Fecha de final</LabeFormlStyled>
          <InputFormStyled
            type="date"
            name="date_end"
            value={values?.date_end}
            onChange={handleInputChange}
          />
        </div>
      </SimpleGrid>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled onClick={handleClickSubmit}>Buscar</ButtonStyled>
      </Flex>
    </div>
  );
};

export default FormModal;
