import { Box, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import InputSelect from 'components/InputSelect/InputSelect';
import { ModalGeneric } from 'components/modal/ModalGeneric';
import environment from 'constants/apiConst';
import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';
import { InputsContainer } from 'pages/Home/Auth/Auth.styled';
import { LabelGroup } from 'pages/RefProductInter/LabelGroup';
import React, { useContext, useEffect, useState } from 'react'
import { BiCog } from 'react-icons/bi';
import { CgImport } from 'react-icons/cg';
import { GrConfigure } from 'react-icons/gr';
import { useSelector } from 'react-redux';
import { __GetDataNoPage } from 'request/Petitions/__Get';
import { __Post } from 'request/Petitions/__Post';
import { ButtonHeaderModule, CardButton } from 'styled/Buttons.styled';
import { IconGeneric } from 'styled/Icons.styled';
import { ContenedorDane, HeaderSearch } from 'styled/SectionContainer.styled';
import { Title } from 'styled/Title';
import { ConfigDEI } from './ConfigDEI';
import TableOrderHover from 'components/Tables/TableOrderHover';
import { TheadDei } from './TheadDei';
import Pagination from 'components/Pagination/Pagination';
import TbodyDEI from './TBodyDEI';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryEnv from 'constants/queryConst';
import requestData from 'request/Petitions/requestData';
import { Card } from 'pages/Terceros/Components/CardUploadFile';
import { TbReportAnalytics } from 'react-icons/tb';
import { FaFilter } from 'react-icons/fa';
import { RiFilterFill } from 'react-icons/ri';
import { useGlobalContext } from 'context/GlobalContext';
import { AuthContext } from 'context/AuthContext';



const DEI = () => {
  const {token, nameFreeZoneTemp} = useSelector((state) => state.auth.dataUser);
  const [DataZf, setDataZf] = useState(null);
  const [fechasHoy, setFechasHoy] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const [stateSelectZf, setStateSelectZf] = useState(null);
  const [isOpenConfig, onOpenConfig, onCloseConfig] = useModal();
  const [isOpenReport, onOpenReport, onCloseReport] = useModal();
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [stateSelectZfSearch, setStateSelectZfSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [values, handleInputChange, reset] = useForm({
    date_start: "",
    date_end: "",
    date_start_search: "",
    date_end_search: ""
  });

  const { date_start, date_end, date_start_search, date_end_search } = values;

  const getZf = async () => {
    try {
      const res = await __GetDataNoPage(
        environment.GET_ZONAS_F_NOT_PAGE,
        token
      );
      if (res?.data?.status.code === 200) {
        const info = res?.data?.status?.data;
        const infoZf = info.map((item) => ({
          label: item.name_freeZone,
          value: item?.id
        }));
        setDataZf(infoZf);
      } else {
        openAlert(`Ocurrió un error`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getZf();
    const hoy_fecha = new Date().toISOString().substring(0, 10);
    setFechasHoy(hoy_fecha);
  }, []);

  const exportManual = async (endpoint, infoData) => {
    try {
      const res = await __Post(endpoint, token, infoData);
      const { code, message, data } = res?.data?.status;
      if (code === 200) {
        openAlert("Generación exitosa", "success");
        setStateSelectZf(null);
        reset();
        onCloseReport();
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append("date_start", date_start);
    data.append("date_end", date_end);
    data.append("freeZone_id", stateSelectZf?.value);
    data.append("report", 1)

    if (date_start === "" || date_end === "") {
      openAlert("Rango de tiempo es requerido", "error");
    } else if (stateSelectZf === null) {
      openAlert("Seleccione una zona franca", "error");
    } else {
      exportManual(environment.EXPORT_SPECIAL_DECLARATION, data);
    }
  };

  const { requestData } = useGlobalContext()

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const FilterByDate = () => {
    setIsLoading(true);

    if (!date_start_search) {
      setIsLoading(false);
      return openAlert("La fecha inicial es requerida", "error");
    }
    if (!date_end_search) {
      setIsLoading(false);
      return openAlert("La fecha final es requerida", "error");
    }


    const body = {
      date_start: date_start_search,
      date_end: date_end_search,
      freeZone_id: stateSelectZfSearch?.value
    }

    const config = {
      data: body,
      Endpoint: environment.GET_OUTPUT_FORM_DEI,
      PropertyBody: "sendJSONContentPOST"
    }

    mutate(config, {
      onSuccess: (res) => {
        if (res?.data?.status?.code !== 200) {
          setCurrentPage([]);
          setIsLoading(false);
          openAlert(res?.data?.status?.message, "error");
        } else {
          setCurrentPage(res?.data?.status?.data?.data);
          setMaxPage(res?.data?.status?.data.last_page);
          setIsLoading(false);
        }
      }
    })
  }

  return (
    <>
      <ModalGeneric
        isOpen={isOpenConfig}
        onOpen={onOpenConfig}
        onClose={onCloseConfig}
        title={`Configuración de correos ${nameFreeZoneTemp}`}
        scrollBehavior={"outside"}
        size={"4xl"}
      >
        <ConfigDEI onClose={onCloseConfig} DataZf={DataZf} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReport}
        onOpen={onOpenReport}
        onClose={onCloseReport}
        title="Informe manual"
        scrollBehavior={"outside"}
        size={"4xl"}
      >
        <Box>
          <SimpleGrid minChildWidth="200px" spacing="10px">
            <LabelGroup
              name={"date_start"}
              value={date_start}
              onChange={handleInputChange}
              tag={"Fecha inicial"}
              size={100}
              tipo="date"
            />
            <LabelGroup
              name={"date_end"}
              tipo="date"
              value={date_end}
              onChange={handleInputChange}
              tag={"Fecha final"}
              size={100}
              max={fechasHoy}
            />
          </SimpleGrid>

          <Box style={{ width: "100%" }}>
            <InputSelect
              tag={"Seleccione la zona franca"}
              data={DataZf}
              set={setStateSelectZf}
              value={stateSelectZf}
            />
          </Box>

          <InputsContainer>
            <CardButton bgColor={"#03C39A"} onClick={handleSubmit}>
              <IconGeneric color="#fff" className="me-2" as={TbReportAnalytics} />
              Generar informe
            </CardButton>
          </InputsContainer>
        </Box>
      </ModalGeneric>
      <Box display={"flex"} justifyContent={"space-between"} pt={10}>
        <Title>Declaración especial de importación</Title>

        <Box style={{ display: "flex", gap: "4px" }}>
          <Tooltip placement="top" hasArrow label="Configuración de correos">
            <span>
              <ButtonHeaderModule
                onClick={onOpenConfig}
              >
                <IconGeneric
                  color="#fff"
                  //   className="text-white"
                  as={BiCog}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Informe manual">
            <span>
              <ButtonHeaderModule
                onClick={onOpenReport}
                bgColor="#03C39A"
              >
                <IconGeneric
                  color="#fff"
                  as={TbReportAnalytics}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </Box>



      </Box>
      <Box className="d-flex justify-content-center">
        <Box style={{ width: "100%" }}>


          <HeaderSearch>
            <Title>Formularios DEI</Title>
            <SimpleGrid minChildWidth="200px" spacing="10px">
              <LabelGroup
                name={"date_start_search"}
                value={date_start_search}
                onChange={handleInputChange}
                tag={"Fecha inicial de búsqueda"}
                size={100}
                tipo="date"
              />
              <LabelGroup
                name={"date_end_search"}
                tipo="date"
                value={date_end_search}
                onChange={handleInputChange}
                tag={"Fecha final de búsqueda"}
                size={100}
                max={fechasHoy}
              />
            </SimpleGrid>

            <Box style={{ width: "100%" }}>
              <InputSelect
                tag={"Seleccione la zona franca"}
                data={DataZf}
                set={setStateSelectZfSearch}
                value={stateSelectZfSearch}
              />
            </Box>

            <InputsContainer>
              <CardButton onClick={FilterByDate} >
                <IconGeneric color="#fff" className="me-2" as={RiFilterFill} />
                Filtrar por fecha
              </CardButton>
            </InputsContainer>

            <TableOrderHover
              thead={TheadDei}
              data={currentPage}
              setData={setCurrentPage}
              loading={isLoading}
              tbodyData={TbodyDEI}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={isLoading}
            />
          </HeaderSearch>
        </Box>
      </Box>

    </>
  );
};

export default DEI
