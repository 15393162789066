import { Navigate, useLocation } from 'react-router';
import queryString from 'query-string';
import { useEffect } from 'react';

/**
 * La ruta para acceder al cambio de contraseña será
 * http://localhost:3000/auth/recover/password?email=correo@email.com&tk=EsteEsElToken
 */
const PasswordRoute = ({ children }) => {
  const location = useLocation();
  const { tk = "" } = queryString.parse(location.search);

  return tk ? children : <Navigate to="/auth" />;
};

export default PasswordRoute;
