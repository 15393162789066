import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { AuthContext } from "context/AuthContext";
import OrderProductionContext from "context/OrderProductionContext";
import { useForm } from "hooks/useForm";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { ButtonStyled } from "styled/Buttons.styled";

export const CreateCI = ({ onClose }) => {
  const [typeEntry, setTypeEntry] = useState(null);
  const dataType = ["Venta total", "Venta parcial"];
  const { dataCreateCI, createCiOp } = useContext(OrderProductionContext);
  const [values, handleInputChangeItems, reset, setValues] = useForm({});
  const { quantity_product } = values;
  const [openAlert] = AlertErrorAlert();
  const [fomateo] = useFormatQuantity();
  const { authUser } = useContext(AuthContext);
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
console.log('dataCreateCI', dataCreateCI)
  const valorTotalQuantity = dataCreateCI.product
    ? dataCreateCI.product[0].quantity_product
    : dataCreateCI.production_order_total_product;

  const [userQualified, setUserQuialified] = useState(null);

  useEffect(() => {
    if (typeEntry && typeEntry === "Venta total") {
      setValues({
        quantity_product: valorTotalQuantity
      });
    }
  }, [typeEntry]);

  const handleSubmit = () => {
    if (!quantity_product) {
      openAlert("Ingrese la cantidad de producto", "error");
    } else if (
      Number(
        quantity_product.includes(",")
          ? quantity_product.replaceAll(",", "")
          : quantity_product
      ) > Number(valorTotalQuantity)
    ) {
      openAlert(
        "La cantidad ingresada supera la cantidad de producto disponible",
        "error"
      );
    } else {
      // console.log(dataCreateCI)
      const information = {
        quantity_product: quantity_product.replaceAll(",", ""),
        product_order_id: dataCreateCI.production_order_id
      };
      createCiOp(information);
    }
  };

  useEffect(() => {
    getUserQualified();
  }, []);

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const data = res?.data?.status;
      if (data.code === 200) {
        const searchUC = data.data.find(
          (e) => e.id === dataCreateCI.qualified_users_id
        );
        setUserQuialified(searchUC);
      }
    } catch (error) {}
  };

  const hanldeBlur = (e) => {
    let posibleResult = e.target.value;
    const valorCorrectedFormat = fomateo(
      (authUser.admin === 1 || authUser.is_commerce === 1) &&
        userQualified.decimal_values_precision
        ? Number(userQualified.decimal_values_precision)
        : Number(decimal_values_precision),
      posibleResult
    );

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      if (
        Number(
          quantity_product.includes(",")
            ? quantity_product.replaceAll(",", "")
            : quantity_product
        ) > Number(valorTotalQuantity.replaceAll(",", ""))
      ) {
        openAlert(
          "La cantidad ingresada supera la cantida de producto disponible",
          "error"
        );
        setValues({
          quantity_product: ""
        });
      } else {
        setValues({
          quantity_product: valorCorrectedFormat
        });
      }
    }
  };

  return (
    <>
      <div className="mb-3" id="adventenciaReturn">
        <h1
          style={{
            fontSize: "1.2rem",
            fontFamily: "Roboto",
            color: "#171a22"
          }}
        >
          {`La orden de producción Nro. ${dataCreateCI.production_order_code} tiene, ${valorTotalQuantity} productos disponibles.`}
        </h1>
      </div>
      <RadioCheck set={setTypeEntry} value={typeEntry} item={dataType} />
      <LabelGroup
        name={"quantity_product"}
        value={quantity_product}
        onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
        onBlur={hanldeBlur}
        tag={"Cantidad producto *"}
        size={100}
        desicion={!!(typeEntry && typeEntry === "Venta total")}
        id={quantity_product}
      />

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Crear
        </ButtonStyled>
      </Flex>
    </>
  );
};
