import { Icon } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const CardWeighingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
`;

export const CardWeighing = styled.div`
  width: 100%;
  background: #f5f5f5;
  display: flex;
  max-height: 120px;
  max-width: 256px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
 // box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
`;

export const DivIconCardWeighing = styled.div`
  width: 100%;
  background: ${(props) => props.bg || "#03c39a"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  max-height: 52px;
  max-width: 52px;
  margin: 0 10px 0 0;
  border-radius: 3px;
`;

export const IconCardWeighing = styled(Icon)`
  width: 100%;
  height: 100%;
  color: ${(props) => props.color || "#1f7ac3"};
  max-height: 32px;
  max-width: 32px;
  /* cursor: pointer; */
`;

export const DivContentCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
