import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { MdRemoveRedEye } from "react-icons/md";

import FormCrudCRUDContext from "context/FormCrudContex";

const TBodyItemCorrectShow = ({ data }) => {
  const {
    setDataToEditItems,
    setshowItemsView,
    setDataToCopyItems
  } = useContext(FormCrudCRUDContext);

  const handleEdit = () => {
    setDataToCopyItems(null);
    setDataToEditItems(data);
    setshowItemsView(true);
  };

  return (
    <Tr className={data.status_item === 1 && "BorderCorrectTabla"}>
      <Td className="text-center">{data?.item_corrected}</Td>
      <Td className="text-center">
        {data?.code_subheading_corrected
          ? data?.code_subheading_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_product_corrected ? data?.code_product_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description_product_corrected
          ? data?.description_product_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit_corrected
          ? data?.code_businessUnit_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_corrected
          ? data?.quantity_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.product_quantity_corrected
          ? data?.product_quantity_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.cif_us_value_corrected ? data?.cif_us_value_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.us_fob_value_corrected ? data?.us_fob_value_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.net_weight_corrected
          ? data?.net_weight_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.gross_weight_corrected
          ? data?.gross_weight_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data.status_item === 1
          ? (
            <Tooltip
              placement="top"
              hasArrow
              label={data.status_item === 1 && "Ver correción"}
            >
              <span>
                <IconGeneric
                  color={data.status_item !== 1 && "#bababa"}
                  onClick={data.status_item === 1 && handleEdit}
                  as={MdRemoveRedEye}
                />
              </span>
            </Tooltip>
          )
          : (
            <IconGeneric color="#bababa" as={MdRemoveRedEye} />
          )}
      </Td>
    </Tr>
  );
};

export default TBodyItemCorrectShow;
