import React, { useContext, useState, useCallback, useMemo } from "react";
import {
  Box,
  Divider,
  Switch,
  Grid,
  GridItem,
  useDisclosure,
  useToast,
  Text,
  Progress,
} from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import { H2 } from "pages/Pass-Form/stylePases";
import { IconGeneric } from "styled/Icons.styled";
import { BiImageAdd } from "react-icons/bi";
import Swal from "sweetalert2";
import PassCrudContext from "context/PassCrudContext";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import isArrayNotEmpty from "utils/type-check-utils";
import { MdClear, MdOutlineImage } from "react-icons/md";
import { ButtonStyled } from "styled/Buttons.styled";
import ReactCardWithIcon from "components/DispatchCorrections/ReactCardWithIcon";
import ZoomImage from "components/DispatchCorrections/ZoomedImage";
import { Modalcustomizable } from "components/modal/ModalPass";

const MAX_FILE_SIZE_BYTES = 3145728; // 3MB
const MAX_NUMBER_OF_FILES = 5;
const ERROR_MESSAGE_SIZE = "excede el límite permitido de 3 Megabytes";
const ERROR_MESSAGE_QUANTITY = "Supera el límite de fotografías permitidas (5)";

export const AddPhotography = ({
  photography = [],
  setPhotography = () => {},
  isLoading = false,
  updateAndCreate = () => {},
  title = "Evidencia Fotográfica",
  disableUpload = false,
  activeDelete = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authUser } = useContext(AuthContext);
  const [stateImg, setStateImg] = useState(false);
  const { deletePhoto } = useContext(PassCrudContext);
  const [previewImage, setPreviewImage] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();

  const imgAd = useCallback(() => setStateImg((prev) => !prev), []);

  const UploadImg = useCallback(() => {
    document.getElementById("display-img").click();
  }, []);

  const validateFileSize = useCallback(
    (file) => file.size <= MAX_FILE_SIZE_BYTES,
    []
  );

  const validateFileCount = useCallback(
    (files) => files?.length + photography?.length <= MAX_NUMBER_OF_FILES,
    [photography]
  );

  const deleteImaServer = useCallback(
    (index) => {
      Swal.fire({
        title: `¿Estás seguro de eliminar la fotografía "${index.name_photography}"?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#1F7AC3",
        confirmButtonText: "Eliminar",
        reverseButtons: true,
        customClass: { title: "class-text" },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("photography", index.name_photography);
          try {
            await deletePhoto(formData, index.id);
            const newPhotography = photography.filter(
              (e) => e.name_photography !== index.name_photography
            );
            setPhotography(newPhotography);
            /*  toast({
              title: "Fotografía eliminada con éxito",
              status: "success",
              duration: 3000,
              isClosable: true,
            }); */
          } catch (error) {
            console.error("Error al eliminar la fotografía:", error);
            if (error.response && error.response.data.status.code === 403) {
              toast({
                title:
                  error.response.data.status.message ||
                  "Error al eliminar la fotografía",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Error al eliminar la fotografía",
                description:
                  "Ocurrió un error inesperado. Por favor, inténtelo de nuevo.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
        }
      });
    },
    [photography, setPhotography, deletePhoto, toast]
  );

  const handleImageSelection = useCallback(
    (event) => {
      const files = Array.from(event.target.files);

      if (!files?.length) return;

      if (!validateFileCount(files)) {
        toast({
          title: ERROR_MESSAGE_QUANTITY,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const validFiles = files.filter((file) => validateFileSize(file));
      const invalidFiles = files.filter((file) => !validateFileSize(file));

      invalidFiles.forEach((file) => {
        toast({
          title: `La fotografía ${file.name} ${ERROR_MESSAGE_SIZE}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

      if (validFiles?.length) {
        setUploadProgress(0);
        validFiles.forEach((file, index) => {
          updateAndCreate(event, file, (progress) => {
            setUploadProgress((prevProgress) => {
              const newProgress = prevProgress + progress / validFiles?.length;
              return Math.min(newProgress, 100);
            });
          });
        });
      }

      event.target.value = null;
    },
    [validateFileCount, validateFileSize, updateAndCreate, toast]
  );

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  }, []);

  const openImageView = useCallback(
    (url) => {
      setPreviewImage(url);
      setIsFullscreen(false);
      onOpen();
    },
    [onOpen]
  );

  const remainingPhotos = useMemo(
    () => MAX_NUMBER_OF_FILES - photography?.length,
    [photography]
  );

  const shouldShowSwitch = useMemo(() => {
    return (authUser?.admin || authUser?.is_commerce) && !disableUpload;
  }, [authUser?.admin, authUser?.is_commerce, disableUpload]);

  if (isLoading) return <SpinnerComponent />;

  return (
    <>
      <Modalcustomizable
        title="Previsualización"
        isOpen={isOpen}
        onClose={onClose}
        rightButtonText="Confirmar"
        iconComponent={MdOutlineImage}
        hiddenButtons={true}
        size={isFullscreen ? "full" : "6xl"}
      >
        <ZoomImage
          src={previewImage}
          toggleFullscreen={toggleFullscreen}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
        />
      </Modalcustomizable>

      <Box mt={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <H2>{title}</H2>
            {shouldShowSwitch ? (
              <Switch
                name="generic_subheading_declaration"
                id="generic_subheading_declaration"
                size="md"
                isChecked={stateImg}
                onChange={imgAd}
                ml={3}
              />
            ) : null}
          </Box>
          {!disableUpload && (
            <Text>Fotografías restantes: {remainingPhotos}</Text>
          )}
        </Box>
        {stateImg && shouldShowSwitch ? (
          <Box mt={1}>
            <ButtonStyled
              className="d-flex justify-content-center align-items-center"
              bgColor="orange"
              onClick={UploadImg}
              isDisabled={remainingPhotos === 0}
            >
              Adjuntar imágenes
              <IconGeneric className="text-white ms-1" as={BiImageAdd} />
            </ButtonStyled>
          </Box>
        ) : null}
        <input
          onChange={handleImageSelection}
          accept="image/*"
          type="file"
          id="display-img"
         // multiple
          style={{ display: "none" }}
          aria-label="Subir imágenes"
        />

        {uploadProgress > 0 && uploadProgress < 100 && (
          <Box mt={3}>
            <Text>Subiendo imágenes...</Text>
            <Progress value={uploadProgress} size="sm" colorScheme="blue" />
          </Box>
        )}

        <Divider my={3} />
        <Grid templateColumns="repeat(auto-fill, minmax(198px, 1fr))" gap={1}>
          {isArrayNotEmpty(photography) &&
            photography.map((item, index) => (
              <GridItem key={index}>
                <ReactCardWithIcon
                  w="200px"
                  photografyUrl={item.photografy_url}
                  photografyAlt={item.name_photography}
                  Icon={MdClear}
                  ColorIcon="rgb(175, 10, 10)"
                  ActionIcon={() => deleteImaServer(item)}
                  onClickImageButton={() => openImageView(item.photografy_url)}
                  activeDelete={authUser?.admin || authUser?.is_commerce}
                />
              </GridItem>
            ))}
        </Grid>
      </Box>
    </>
  );
};
