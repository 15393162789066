import React, { useContext, useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import { MdDelete, MdRemoveRedEye } from 'react-icons/md'
import {
  HiDocumentRemove,
  HiDocumentAdd,
  HiDotsHorizontal
} from 'react-icons/hi'
import { RiEdit2Fill, RiSettings4Fill } from 'react-icons/ri'

import { AiOutlineUserAdd } from 'react-icons/ai'
import ConfigurationCRUDContext from 'context/ConfigurationCRUDContext'
import { HrDividerNoMargin } from 'styled/Line.styled'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { ConfigNotification } from 'components/Header/Drawer/ConfigNotification'
import environment from 'constants/apiConst'
import { __Get } from 'request/Petitions/__Get'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { restConfig } from 'utils/restConfig'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

const MenuRUD = ({
  data,
  deleteField = true,
  licenses = false,
  document = false,
  remove = false
}) => {
  const {
    onOpenEdit,
    setDataToEdit,
    onOpenDelete,
    setDataToDelete,
    onOpenShow,
    setDataToShow,
    setDataToLicenses,
    onOpenLicenses,
    onOpenDocument,
    setDataToDocument,
    onOpenRemove,
    setDataToRemove
  } = useContext(ConfigurationCRUDContext)
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const navigate = useNavigate()

  const [isOpenConfig, setIsOpenConfig] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [StateReset, setStateReset] = useState(false);
  const [openAlert] = AlertErrorAlert();

  const handleEdit = e => {
    setDataToEdit(data)
    if (location.pathname === '/config/roles') {
      return navigate('/config/manageRoles?movement=Edit')
    }

    onOpenEdit()
  }

  const handleDelete = e => {
    const { id: _, ...rest } = data
    rest.id = data?.subheading_id ||  data?.id

    setDataToDelete(rest)
    onOpenDelete()
  }

  const handleShow = e => {
    setDataToShow(data)
    onOpenShow()
  }

  const addLicenses = e => {
    setDataToLicenses(data)
    onOpenLicenses()
  }

  const addDocument = e => {
    setDataToDocument(data)
    onOpenDocument()
  }

  const removeDocument = e => {
    setDataToRemove(data)
    onOpenRemove()
  }

  const handleOpenConfig = () => {
    setIsOpenConfig(true);
  };

  const handleCloseConfig = () => {
    setIsOpenConfig(false);
  };

  return (
    <>
      <Menu>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color='#00000085' />
        </MenuButton>

        <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
          <MenuItemStyled py={3} onClick={handleShow}>
            <IconMenuRUD color='green' as={MdRemoveRedEye} />
            Ver
          </MenuItemStyled>

          <HrDividerNoMargin />

          <MenuItemStyled py={3} onClick={handleEdit}>
            <IconMenuRUD as={RiEdit2Fill} />
            Editar
          </MenuItemStyled>

          {movement === 'Cuentas de usuario' && (
            <>
              <MenuItemStyled py={3} onClick={handleOpenConfig}>
                <IconMenuRUD as={RiSettings4Fill} />
                Notificaciones
              </MenuItemStyled>
            </>
          )}

          {movement !== 'Tipo de Documentos' && (
            <>
              <HrDividerNoMargin />
              {deleteField && (
                <MenuItemStyled py={3} onClick={handleDelete}>
                  <IconMenuRUD color='red' as={MdDelete} />
                  Eliminar
                </MenuItemStyled>
              )}
            </>
          )}

          {licenses && (
            <>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={addLicenses}>
                <IconMenuRUD as={AiOutlineUserAdd} />
                Asignar Licencia
              </MenuItemStyled>
            </>
          )}

          {document && (
            <>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={addDocument}>
                <IconMenuRUD as={HiDocumentAdd} />
                Asignar operación
              </MenuItemStyled>
            </>
          )}
          {remove && (
            <>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={removeDocument}>
                <IconMenuRUD as={HiDocumentRemove} />
                Desasociar documento
              </MenuItemStyled>
            </>
          )}
        </MenuList>
      </Menu>

      <ModalGeneric
        isOpen={isOpenConfig}
        onOpen={handleOpenConfig}
        onClose={handleCloseConfig}
        title="Configuración de notificaciones"
        size="5xl"
      >
        <ConfigNotification 
          onClose={handleCloseConfig}           
          restConfi={(position, userId) => restConfig(position, token, setStateReset, StateReset, userId)}
          StateReset={StateReset}
          userData={data}
          />
      </ModalGeneric>
    </>
  )
}

export default MenuRUD
