import React from "react";
import * as PropTypes from "prop-types";
import { Box, Center, Menu, MenuButton } from "@chakra-ui/react";

export const ButtonDropdownChakra = ({ overlay, icon, ...buttonProps }) => {
  return (
    <>
      <Menu>
        <MenuButton
          p={1}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          _hover={{ bg: "gray.400" }}
          /*   _expanded={{ bg: "blue.400" }} */
          _focus={{ boxShadow: "outline" }}
        >
          <Box display={"flex"}>
            <Center>
              {icon}
              {buttonProps?.children}
            </Center>
          </Box>
        </MenuButton>
        {overlay}
      </Menu>
    </>
  );
};
ButtonDropdownChakra.propTypes = {
  overlay: PropTypes.object.isRequired
};
