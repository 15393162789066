import { createContext, useReducer, useEffect } from "react";
// import { useSelector } from 'react-redux';
import { authReducer } from "redux/reducer/authReducer";

export const AuthContext = createContext();

/**
 * Estado inicial del auth al momento de ingresar a la pagina
 */
const init = () => {
  return { logged: false };
};

export const AuthProvider = ({ children }) => {
  const [authUser, dispatch] = useReducer(authReducer, "", init);

  useEffect(() => {
    if (!authUser) return;
  }, [authUser]);

  const data = { authUser, dispatch };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
