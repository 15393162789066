/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import FormCrudCRUDContext from 'context/FormCrudContex'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Radio,
  SimpleGrid,
  Spacer,
  Textarea,
  Tooltip,
  background
} from '@chakra-ui/react'
import InputSelect, {
  AcuerdoC,
  SelectMethodTransport,
  SelectPackaging
} from 'components/InputSelect/InputSelect'
import { LabeFormlStyled } from 'styled/Input.styled'
import './style.css'
import { ButtonStyled } from 'styled/Buttons.styled'
import { RETURN_TYPES, useForm } from 'hooks/useForm'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import { useSelector } from 'react-redux'
import { __Trm } from 'request/configuration/__Trm'
import { __SelectAcuerdosSub } from 'request/configuration/__ASubheading'
import { __ShowItems } from 'request/Forms/_CrudForm'
import environment from 'constants/apiConst'
import { OnlyNumberInput } from 'components/Funciones/OnlyNumberInput'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import { useModal } from 'hooks/useModal'
import { AuthContext } from 'context/AuthContext'
import { useFormatQuantity } from 'hooks/useFormatQuantity'
import { RadioBtnRedonder } from 'components/ComponentRadioButton/RadioBtnRedonder'
import { SearchEditedFields } from 'hooks/useLocalStorage'
import { __Get, __GetDataId } from 'request/Petitions/__Get'
import ProductCRUDContext from 'context/ProductCRUDContext'
import ModalTransition from 'components/modal/ModalTransition'
import { ModalAdd } from 'pages/RefProductInter/ModalAdd'
import { TbFileInvoice } from 'react-icons/tb'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { Cancelar } from 'pages/RefProductInter/Stilos'
import { MaxLength } from 'utils/MaxLength'
import { useDebounceError } from 'hooks/useDebounceError'
import ProductSearchBar from 'pages/IntegrationBox/Form/ProductSearchBar'
import GlobalContext, { useGlobalContext } from 'context/GlobalContext'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NumericFormat } from 'react-number-format'
import { useNotations } from 'context/NotationsContext'
import {
  isNumber,
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
  safelyConvertToString
} from 'utils/type-check-utils'
import { getFormData } from 'redux/actions/dataFormActions'
import { RiEdit2Fill } from 'react-icons/ri'
import { parseFloat } from 'utils/parseFloat'
import Hover from "components/HoverInfo";
import { BsInfoCircle } from 'react-icons/bs'
import { LabelStyled } from 'components/addProduct/CreateTemplate.styled'
import { Regex } from 'utils/Regex'

/**
 *  AddItemsForm  - Componente de Formulario de items  que permite al usuarios cargar items
 *
 * @componentsetTrm
 * @return {Component} Retorna componente formulario para añadir items al formulario de entrada
 */

const errorsDefaultState = {
  product_id: false,
  product_quantity: true,
  typeProduct_id: false,
  countryProcedency_id: false,
  countryOrigin_id: false,
  countryPurchase_id: false,
  transportMethod_id: false,
  flag_id: false,
  packaging_id: false,
  gross_weight: false,
  us_freight: false,
  port_id: false
}

export function parseNumberWithCommas (value) {
  return Number(value?.toString()?.replaceAll(',', '') || 0)
}

export const removeDecimalIfZero = (number, decimalPlaces = 0, name) => {
  const roundedNumber = parseFloat(number)
  
  if(!isValid(roundedNumber)){
    console.error('The parameter must be a valid number.')
  }


  return +roundedNumber?.toFixed(decimalPlaces).replace(/(\.0+|0+)$/, '');
}


export const ConvertPesosToDollars = (pesos, exchangeRate, decimals = 2) => {
  if (
    isNaN(pesos) ||
    isNaN(exchangeRate) ||
    pesos === null ||
    exchangeRate === null
  ) {
    console.error('Parameters must be valid numbers.')
  }

  if (exchangeRate <= 0) {
    console.error('The exchange rate must be greater than zero.')
  }

  let dollars = 0
  if (pesos !== 0 && exchangeRate !== 0) {
    dollars = pesos / exchangeRate
  }

  const result = dollars?.toFixed(decimals)
  return result
}

const AddItemsForm = ({ Reviews, valuesHeader }) => {
  const [openAlert] = AlertErrorAlert()
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal()
  const [errorsState, setErrorsState] = useState(errorsDefaultState)
  const {
    token,
    decimal_values_precision,
    port = null,
    freeZone_id,
    userQualified
  } = useSelector(state => state.auth.dataUser)
  const [valueInvoice, setValueInvoice] = useState(null)
  const [valueSlect, setValueSelect] = useState(null)
  const [dataSelect, setDataSelect] = useState(null)
  const [isOpenInvoice, onOpenInvoice, onCloseInvoice] = useModal()

  const [suma, setSuma] = useState(0)
  const [multiplicacion, setMultiplicacion] = useState(0)
  const { authUser } = useContext(AuthContext)
  const [fomateo] = useFormatQuantity()
  const dataCoin = ['USD', 'COP']
  const dataNaturaleza = ['Nacional', 'Extranjera']
  const { requestData, formOperation } =
    useContext(GlobalContext)
  const [valorDolarActual, setValorDolarActual] = useState(null)
  const {
    updateNotation: updateComment,
    updateNotationData,
    reviewsData
  } = useNotations()

  const [loading, setLoading] = useState(false)
  const [fieldsBackup, setFieldsBackup] = useState({})
  const location = useLocation() // navigate

  const { FormEntryNumber = null, FormExitNumber = null } = queryString.parse(
    location.search
  )
  const dataFormRedux = useSelector(getFormData)

  const formId = FormEntryNumber || FormExitNumber
  const {
    dataCountry,
    info,
    setInfo,
    dataCountryOrigin,
    setDataCountryOrigin,
    dataCountryBuy,
    setDataCountryBuy,
    setPlaceU,
    dataTransportMethod,
    valueMethod,
    setValueMethod,
    etiqueta,
    setEtiqueta,
    valueFlag,
    setValueFlag,
    dataAgreements,
    acuerdos,
    setAcuerdos,
    dataPackagings,
    packagings,
    setPackagings,
    setSelectCurrency,
    selectCurrency,
    valueSubheading,
    setValueSubheading,
    setNameSubheading,
    setNameProducts,
    valueProducts,
    setValueProducts,
    setDataSuheading,
    dataToEditItems,
    setShow,
    show,
    dataToCopyItems,
    setDataToEditItems,
    setDataToCopyItems,
    selectItemForm,
    dataTypeProduct,
    selectDataTypeProduct,
    setSelectDataTypeProduct,
    getMethodTransport,
    getCountry,
    getPackaging,
    getAcuerdosC,
    typeProduct,
    decimalUcContext,
    dataProcedencia,
    dataCountry120,
    selectNE,
    setSelectNE,
    editDataForm,
    letNumDepartureAuthorization,
  } = useContext(FormCrudCRUDContext)
  
  const [dataForm, setDataForm] = useState()

  const fieldAllowed = [
    'brand',
    'serial',
    'product_quantity',
    'name_typeProduct',
    'quantity',
    'net_weight',
    'gross_weight',
    'number_packages',
    'code_businessUnit',
    'us_freight',
    'us_insurance',
    'other_us_expenses',
    'us_fob_value',
    'cif_us_value',
    'cif_cop_value',
    'exchange_rate',
    'invoice',
    'name_country_flag',
    'name_country',
    'name_country_proc',
    'name_country_purs',
    'name_transportMethod',
    'form_item_description'
  ]


  const fieldCalculate = [
    'us_freight',
    'us_insurance',
    'other_us_expenses',
    'us_fob_value',
    'exchange_rate',
    'cif_cop_value'
  ]

/*   useEffect(() => {
   
  }, [
    suma,
    multiplicacion,
  ])
 */
  const onFieldChange = (name, newValue, target) => {
    const { oldValue, itemId, inputTag } = target?.dataset || {};
    const Allowed = fieldAllowed.includes(name)
    const Calculate = fieldCalculate.includes(name)

    const isReentryOperation = formOperation?.reentry_operation === 1
    const isItemTemporaryExit = name === 'item_temporary_exit'
    const customValue = {
      [name]: newValue
    }

    const valItemTemporaryExit =
      isItemTemporaryExit &&
      isString(newValue) &&
      newValue !== '' &&
      isReentryOperation && 
      name === 'item_temporary_exit'

    if(Calculate){
      const exchangeRate = customValue?.['exchange_rate'] || exchange_rate || valorDolarActual
        const sumaDetodo =
        parseFloat(customValue?.['us_freight'] !== undefined ? customValue?.['us_freight'] : us_freight) +
        parseFloat(customValue?.['us_insurance'] !== undefined ? customValue?.['us_insurance'] : us_insurance) +
        parseFloat(customValue?.['other_us_expenses'] !== undefined ? customValue?.['other_us_expenses'] : other_us_expenses) +
        parseFloat(customValue?.['us_fob_value'] !== undefined ? customValue?.['us_fob_value'] : us_fob_value)
  
      const formateadoSumas = fomateo(decimalPrecision || 2, sumaDetodo)
      const resultadoMultiplicacion = sumaDetodo * (exchangeRate?.replaceAll(',', ''))
      console.log({sumaDetodo, pesosToDollars: parseFloat(sumaDetodo/parseFloat(exchangeRate)), exchangeRate})
      if (selectCurrency === 'COP') {
        setPesosToDollars(parseFloat(sumaDetodo/parseFloat(exchangeRate)))
      }

      setSuma(formateadoSumas)
      setMultiplicacion(resultadoMultiplicacion)
    }

    if (valItemTemporaryExit) {
      ItemsTemporary(newValue)
    }

    if (
      name === 'product_quantity' &&
      isReentryOperation &&
      ((isString(newValue) && newValue !== '') || (isNumber(newValue) && newValue !== 0)) &&
      ((isNumber(item_temporary_exit) && item_temporary_exit > 0) ||
        (isString(item_temporary_exit) && item_temporary_exit !== ''))
    ) {
      ItemsTemporary(item_temporary_exit, newValue)
    }

    if (Allowed) {
      return updateComment(name, inputTag, newValue, oldValue, itemId)
    }
  }

  const {values, handleInputChange, reset, setValues} = useForm({
  initialState:{
    entryForm_id: '',
    product_id: '',
    businessUnit_id: '',
    transportMethod_id: '',
    packaging_id: '',
    subheading_id: '',
    countryOrigin_id: '',
    countryProcedency_id: '',
    countryPurchase_id: '',
    product_code: '',
    product_unit: '',
    factor_conv: '0',
    product_quantity: 0,
    product_description: '',
    item_temporary_exit: '',
    gross_weight_sdo: 0,
    amount_sdo: 0,
    fob_sdo_value: 0,
    cif_value_sdo: 0,
    description_subheading: '',
    flag_id: '',
    gross_weight: 0,
    net_weight: 0,
    number_packages: '',
    quantity: 0,
    coin: '',
    us_freight: 0,
    us_insurance: 0,
    other_us_expenses: 0,
    us_fob_value: 0,
    cif_us_value: 0,
    exchange_rate: '',
    // fixed_asset: "",
    tradeAgreement_id: '',
    form_item_description: '',
    cif_cop_value: 0,
    code_subheading: '',
    code_businessUnit: '',
    brand: '',
    serial: '',
    invoice: ''
  },
  onFieldChange: onFieldChange,
  returnType: RETURN_TYPES.OBJECT
  }
  )

  const {
    product_unit,
    factor_conv,
    product_quantity,
    product_description,
    item_temporary_exit,
    gross_weight_sdo,
    amount_sdo,
    fob_sdo_value,
    cif_value_sdo,
    description_subheading,
    gross_weight,
    net_weight,
    number_packages,
    quantity,
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    brand,
    serial,
    invoice,
    form_item_description,
    // fixed_asset,
    // free_pass,
    // fixed_asset,
    code_subheading,
    code_businessUnit,
    name_businessUnit,
    exchange_rate
  } = values

  const {
    onOpenEdit,
    isOpenEdit,
    onCloseModal,
    setDataToEdit,
    editData,
    setRequestCreate,
    setRequestEdit
  } = useContext(ProductCRUDContext)

  const [decimalPrecision, setDecimalPrecision] = useState(0)


  useEffect(() => {
   /*  if (firstRender.current) {
      firstRender.current = false;
    } else { */
    const isAdmin = authUser.admin === 1
    const isCommerce = authUser.is_commerce === 1
    const decimalValuesPrecision =
     isNaN(Number(decimal_values_precision)) ? null 
     : Number(decimal_values_precision) 
    

    if ((isAdmin || isCommerce) &&  isString(decimalUcContext)) {
      setDecimalPrecision(decimalUcContext || 4);
      return
    } 

    if(isNumber(dataFormRedux?.precision) || isString(dataFormRedux?.precision, true)) {
      setDecimalPrecision(dataFormRedux?.precision);
      return
    }

   if(isNumber(decimalValuesPrecision)) {
      setDecimalPrecision(decimalValuesPrecision);
      return
    }

    return 
 /*  } */
  }, [authUser, decimalUcContext, decimal_values_precision, decimalPrecision, dataFormRedux?.precision])


  const firstRender = useRef(true);
  
  const valueDolar = parseFloat(valorDolarActual)
  const { mutate, isPending } = useMutation({
    mutationFn: requestData
  })

  const getPuerto = async () => {
    try {
      const res = await __Get(environment.GET_PORT_NO_PAGE, token)
      const destruct = res.data.status
      if (destruct.code === 200) {
        const inforPort = destruct.data.map(item => ({
          value: item?.id,
          label: `${item.port_name !== null ? item?.port_name : ''}`
        }))

        setDataSelect(inforPort)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getInvoice = async e => {
    e.preventDefault()
    try {
      const res = await __GetDataId(
        environment.GET_COMERCIAL_INVOICE_ENTRY,
        token,
        FormEntryNumber
      )
      const destruct = res.data.status
      if (destruct.code === 200) {
        onOpenInvoice()
        setValueInvoice(destruct.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
//  UPDATE_ITEMS CREATE_ITEMS_FORM
const messageAlert = {
  unitProduct:  'La Unidad Comercial del producto está establecida en "KG", lo que significa que el Peso Neto debe ser igual a la cantidad especificada.',
  nature: '¡Es obligatorio seleccionar la Naturaleza!',
  quantityAndQuantityProduct: 'La cantidad de producto y cantidad deben ser calculadas usando el factor de conversión.',
  numberPackages: 'El número de bultos debe ser diferente de cero.',
  resultProduct: 'Cantidad de producto debe ser diferente de cero.',
  quantity: 'Cantidad debe ser diferente de cero.',
  typeProduct:  '¡Es obligatorio seleccionar el Tipo de producto!',
  countryOrigin: '¡Es obligatorio seleccionar el País origen!',
  countryDeparture: '¡Es obligatorio seleccionar el País procedencia!',
  countryBuy: '¡Es obligatorio seleccionar el País compra!',
  transportMode:  '¡Es obligatorio seleccionar el Modo de transporte!',
  flag: '¡Es obligatorio seleccionar una bandera!',
  packagings: '¡Es obligatorio seleccionar un Embalaje!',
  usFobValue: `¡El Valor FOB ${selectCurrency ?? ''} es obligatorio!`,
  net_weightNotGreaterThanGross_weight: 'El Peso Bruto debe ser mayor o igual al peso Neto',
  exchangeRate: 'La Tasa de Cambio es obligatoria',
  cif_cop_value: 'El Valor CIF COP es obligatorio',
}

const cleanNumber = (input) => {
  if (isString(input, true)) {
    const inputClean = input.replaceAll(',', '');
    return inputClean
  } 

  if (isNumber(input)) {
    return input;
  }

  return 0;
}
  const queryClient = useQueryClient();


  const resUpdateItem = (res, callBack) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      if (selectCurrency === "USD" && ((!dataFormRedux?.max_value_cop && parseFloat(us_fob_value) * parseFloat(exchange_rate || valorDolarActual) > 20000000) || (dataFormRedux?.max_value_cop && parseFloat(us_fob_value) * parseFloat(exchange_rate || valorDolarActual) > dataFormRedux?.max_value_cop))) openAlert("El valor FOB USD * Tasa de cambio no debe exceder los 20.000.000 USD", 'info')
      if (selectCurrency === "COP" && ((!dataFormRedux?.max_value_cop && parseFloat(us_fob_value) > 20000000) || (dataFormRedux?.max_value_cop && parseFloat(us_fob_value) > dataFormRedux?.max_value_cop))) openAlert("El valor FOB COP no debe exceder los 20.000.000 COP", 'info')
      
      handleCancel()
      callBack()
      queryClient.invalidateQueries({ queryKey: ["getItemsFormEntry"] });
      queryClient.invalidateQueries({
        queryKey: ["formEntryView"],
      });
      openAlert(`Registro actualizado Exitosamente`, "success");
      return  
    }
  }

  const updateItemPost = (payload, callBack = () => {}) => {
   const requestData = {
    data: payload,
    Endpoint: environment.UPDATE_ITEMS,
    PropertyBody: 'sendJSONContentPOST'
  }

  
  mutate(requestData, {
    onSuccess: (e) => resUpdateItem(e, callBack)
  }) 
  }

  const createItemPost = (payload, callBack = () => {}) => {
    const requestData = {
     data: payload,
     Endpoint: environment.CREATE_ITEMS_FORM,
     PropertyBody: 'sendJSONContentPOST'
   }
 
   
   mutate(requestData, {
     onSuccess: (e) => resUpdateItem(e, callBack)
   }) 
   }


  const handleSubmit = e => {
    e.preventDefault()

      //variables or constants
      const unitProduct = product_unit?.toLowerCase();
      const quantityString = quantity.toString()
      const productQuantityString = product_quantity.toString()
      const resultProduc =  cleanNumber(productQuantityString)
      const factorConversion = cleanNumber(factor_conv);
      const multiplicationResult = Number(factorConversion) * Number(resultProduc)
      const CurrentQuantity = cleanNumber(quantityString)
      const grossWeight = cleanNumber(gross_weight_sdo)
      const amountSdo = cleanNumber(amount_sdo)

      //Validation
      const QuantityAndQuantityProductValid = Number(CurrentQuantity).toFixed(decimalPrecision) !== multiplicationResult.toFixed(decimalPrecision);
      const natureValid = !selectNE && selectItemForm >= 500 && selectItemForm <= 599;
      const numberPackagesValid = Number(number_packages) <= 0   ;
      const resultProductValid = Number(resultProduc) <= 0;
      const quantityValid = Number(quantityString) <= 0;
      const typeProductValid = !isValid(selectDataTypeProduct?.value)
      const countryOriginValid = !isValid(info?.value)
      const countryDepartureValid = !isValid(dataCountryOrigin?.value)
      const countryBuyValid = !isValid(dataCountryBuy?.value)
      const transportModeValid = !isValid(valueMethod)
      const flagValid = !isValid(valueFlag?.value)
      const packagingsValid = !isValid(packagings?.value)
      const usFobValueValid = !isValid(us_fob_value) || us_fob_value === '0'
      const netWeightValid =  cleanNumber(net_weight) > cleanNumber(gross_weight)

      //Alert
      if (code_businessUnit.toLowerCase() === 'kg' && (net_weight !== quantity)) {
        return openAlert(messageAlert.unitProduct, 'error')
      }

      if(+net_weight?.replaceAll(',','') > +gross_weight?.replaceAll(',','')) return openAlert(messageAlert.net_weightNotGreaterThanGross_weight, 'error')
  
      if (QuantityAndQuantityProductValid) {
       return openAlert(messageAlert.quantityAndQuantityProduct,'error')
      }
  
      if (natureValid) {
        return openAlert(messageAlert.nature, 'error')
      }
  
      if (numberPackagesValid) {
        return openAlert(messageAlert.numberPackages,'error')
      }
  
      if (resultProductValid) {
        return openAlert(messageAlert.resultProduct, 'error')
      }
  
      if (quantityValid) {
        return openAlert(messageAlert.quantity, 'error')
      }
  
      if (typeProductValid) {
        return openAlert(messageAlert.typeProduct, 'error')
      }
     
      if (countryOriginValid) {
        return openAlert(messageAlert.countryOrigin, 'error')
      }
  
      if (countryDepartureValid) {
        return openAlert(messageAlert.countryDeparture, 'error')
      }
  
      if (countryBuyValid) {
        return openAlert(messageAlert.countryBuy, 'error')
      }
     
      if (transportModeValid) {
        return openAlert(messageAlert.transportMode, 'error')
      }
    
      if (flagValid) {
        return openAlert(messageAlert.flag, 'error')
      }
     
      if (packagingsValid) {
        return openAlert(messageAlert.packagings, 'error')
      }
  
      if (usFobValueValid) {
        return openAlert(messageAlert.usFobValue, 'error')
      }
      
      if (!exchange_rate) {
        return openAlert(messageAlert.exchangeRate, 'error')
      }

      if (!multiplicacion){
        return openAlert(messageAlert.cif_cop_value, 'error')
      }
  
    const formData = new FormData()

    const obtenerErrorItem = JSON.parse(localStorage.getItem(`erroItem-${FormEntryNumber}`) || '[]')

    const result = obtenerErrorItem?.filter(e => e !== dataToEditItems?.id)
    localStorage.setItem(`erroItem-${FormEntryNumber}`, JSON.stringify(result))

    const optionsNational = {
      Nacional: 'NAC',
      Extranjera: 'EXT'
    }[selectNE] || ''


    const createOrEditStruct = {
      'decimal_precision': decimalPrecision || 4,
      'entryForm_id': FormEntryNumber,
      'product_id': valueProducts?.value ?? '',
      'transportMethod_id': valueMethod ?? '',
      ...(selectItemForm >= 500 && selectItemForm <= 599 ? {'national_or_foreign': optionsNational} : {}),
      ...(valueMethod === 1 ? {'port': valueSlect?.label ?? '', 'port_id': valueSlect?.value ?? ''} : {}),
      'us_fob_value': cleanNumber(us_fob_value),
      'packaging_id':  packagings?.value ?? '',
      'subheading_id':  valueSubheading ?? '',
      'countryOrigin_id':  info?.value ?? '',
      'coin': selectCurrency,
      'countryProcedency_id': dataCountryOrigin?.value ?? '',
      'countryPurchase_id': dataCountryBuy?.value ?? '',
      'tradeAgreement_id': acuerdos?.value || '',
      'typeProduct_id': selectDataTypeProduct?.value || '',
      'factor_conv': factor_conv ?? 0,
      'product_quantity': resultProduc,
      'product_description': product_description || '',
      'item_temporary_exit': item_temporary_exit || '',
      'gross_weight_sdo': grossWeight,
      'amount_sdo': amountSdo,
      'fob_sdo_value': cleanNumber(fob_sdo_value),
      'cif_value_sdo': cleanNumber(cif_value_sdo),
      'description_subheading': description_subheading || '',
      'invoice': invoice || '',
      'brand': brand || '',
      'serial': serial || '',
      'flag_id': valueFlag ? valueFlag?.value : '',
      'gross_weight':cleanNumber(gross_weight),
      'net_weight': cleanNumber(net_weight),
      'number_packages': cleanNumber(number_packages),
      'quantity': cleanNumber(quantity),
      'us_freight': cleanNumber(us_freight),
      'us_insurance': cleanNumber(us_insurance),
      'other_us_expenses': cleanNumber(other_us_expenses),
      'cif_cop_value': cleanNumber(multiplicacion ?? 0),
      'entry_form_item_description': form_item_description || '',
      'exchange_rate': exchange_rate ? cleanNumber(exchange_rate) : cleanNumber(valorDolarActual),
       }

       for (const key in createOrEditStruct) {
        formData.append(key, createOrEditStruct[key]);
      }

    if (dataToEditItems) {
      localStorage.removeItem(`erroField-${dataToEditItems.id}`)
    }

    if (code_businessUnit?.toLowerCase() === 'kg') {
      if (netWeightValid) {
        openAlert('El Peso Neto debe ser menor o igual al Peso Bruto', 'error')
      } else {
        const obtenerErrorItem = JSON.parse(
          localStorage.getItem(`erroItem-${FormEntryNumber}`) || '[]'
        )

        const result = obtenerErrorItem?.filter(e => e !== dataToEditItems?.id)
        localStorage.setItem(
          `erroItem-${FormEntryNumber}`,
          JSON.stringify(result)
        )

        const formDataNumDeparture = new FormData()

        if (
          dataFormRedux.code_operation === 309 ||
          dataFormRedux.code_operation === 310
        ) {
          if (formOperation?.reentry_operation === 1 && !valuesHeader.num_exit_form && !valuesHeader.integration_box_certificate) {
            return openAlert(
              'El número de autorización de salida o el número de cuadro de integración son requeridos por favor proporcione al menos uno',
              'error'
            )
          }
          if (
            safelyConvertToString(item_temporary_exit) === ''
          ) {
            return openAlert(
              'El campo Ítem salida temporal es obligatorio',
              'error'
            )
          }

          /* Esto debería cambiarse por la propiedad nueva */
          valuesHeader?.num_exit_form && formDataNumDeparture.append(
            'num_exit_form',
            valuesHeader.num_exit_form
          )

          valuesHeader?.integration_box_certificate && formDataNumDeparture.append(
            'integration_box_certificate',
            valuesHeader.integration_box_certificate
          )


          formDataNumDeparture.append('id', FormEntryNumber || '')
          formDataNumDeparture.append(
            'operation_id',
            dataFormRedux.operation_id || ''
          )
          const resultado = valuesHeader.num_commercial_invoice
            ? valuesHeader.num_commercial_invoice.split(',')
            : []
          if (invoice && !resultado.includes(invoice)) {
            formDataNumDeparture.append(
              'num_commercial_invoice',
              valuesHeader.num_commercial_invoice && invoice
                ? valuesHeader.num_commercial_invoice + ',' + invoice
                : !valuesHeader.num_commercial_invoice && invoice
                ? invoice
                : ''
            )
          } else {
            formDataNumDeparture.append(
              'num_commercial_invoice',
              dataFormRedux.num_commercial_invoice || ''
            )
          }
        }

        if (dataToEditItems) {
          if (
            dataFormRedux.code_operation === 309 ||
            dataFormRedux.code_operation === 310 
          ) {
            formData.append('id', dataToEditItems.id)
            updateItemPost(formData, editDataForm(formDataNumDeparture)) 
            return
          }
          formData.append('id', dataToEditItems.id)
          updateItemPost(formData) 
        } else if (dataToCopyItems) {
          if (
            dataFormRedux.code_operation === 309 ||
            dataFormRedux.code_operation === 310
          ) {
            createItemPost(
              formData,
              () => {
                editDataForm(formDataNumDeparture)
              }
            )
            return
          }
          createItemPost(formData)
        } else {
          if (
            dataFormRedux.code_operation === 309 ||
            dataFormRedux.code_operation === 310
          ) {
            createItemPost(
              formData,
              () => {
              editDataForm(formDataNumDeparture)}
            )
            return
          }
          createItemPost(formData)
        }
      }
    } else {
      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormEntryNumber}`) || '[]'
      )
      const result = obtenerErrorItem?.filter(e => e !== dataToEditItems?.id)
      localStorage.setItem(
        `erroItem-${FormEntryNumber}`,
        JSON.stringify(result)
      )

      if (dataToEditItems) {
        formData.append('id', dataToEditItems.id)
        updateItemPost(formData) 
      } else if (dataToCopyItems) {
        createItemPost(formData)
      } else {
        createItemPost(formData)
      }
    }
  }

  const handleInvoice = () => {
    const formData = new FormData()
    formData.append('id', FormEntryNumber)
    formData.append(
      'num_commercial_invoice',
      valuesHeader.num_commercial_invoice && invoice
        ? valuesHeader.num_commercial_invoice + ',' + invoice
        : !valuesHeader.num_commercial_invoice && invoice
        ? invoice
        : ''
    )
    const resultado = valuesHeader.num_commercial_invoice
      ? valuesHeader.num_commercial_invoice.split(',')
      : []

    if (invoice && !resultado.includes(invoice)) {
      editDataForm(formData, false)
    }
  }


  useEffect(() => {
    const resultadoProduc =
      product_quantity && product_quantity?.replaceAll(',', '')
    const validacionFactor = factor_conv || 0
    const resultadoMultiplicacion =
      Number(validacionFactor) * Number(resultadoProduc)

    const newDato = fomateo(decimalPrecision, resultadoMultiplicacion)

    if (code_businessUnit === 'KG') {
      setValues({
        ...values,
        quantity: newDato,
        net_weight: newDato,
        gross_weight: newDato
      })
    } else if (code_businessUnit !== 'KG') {
      setValues({
        ...values,
        quantity: newDato
      })
    }
  }, [product_quantity, factor_conv, code_businessUnit])


  const [Calculated, setCalculated] = useState(null)


  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

if(isObject(fieldsBackup)){
  setValues((prev) => ({
    ...prev,
    factor_conv: fieldsBackup?.factor_conv
  }))
}
    
  }, [formOperation?.label])
  

  const handleResponseCalculated = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      return setCalculated(res?.data?.status?.data)
    }
  }

  const updateCurrencyField = e => {
    const Currency = e

    const parsedValues = {
      freight: parseNumberWithCommas(us_freight),
      insurance: parseNumberWithCommas(us_insurance),
      other_expenses: parseNumberWithCommas(other_us_expenses),
      fob_value: parseNumberWithCommas(us_fob_value)
    }

   const convertionTrm =   exchange_rate?.replaceAll(',', '') ||  valorDolarActual?.replaceAll(',', '');

    const Trm =  convertionTrm
    const trmFormatted = isNumberFormatable(Trm) ? parseFloat(Trm) : null

    const data = {
      type_calculated: Currency,
      exchange_rate: trmFormatted || valueDolar,
      decimal_precision: Number(decimalPrecision) || 1,
      ...parsedValues
    }

    const requestData = {
      data,
      Endpoint: environment.CALCULATED_ITEM,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutate(requestData, {
      onSuccess: handleResponseCalculated
    })
  }

  useEffect(() => {
    const formatValue = value => fomateo(decimalPrecision, value)

    if (Calculated) {
      const {
        fob_value_calculated,
        freight_calculated,
        insurance_calculated,
        other_expenses_calculated,
        cif_us_value_calculated,
        cif_cop_value_calculated
      } = Calculated

      setValues(prev => ({
        ...prev,
        us_fob_value: removeDecimalIfZero(fob_value_calculated, decimalPrecision, 'us_fob_value'),
        us_freight: removeDecimalIfZero(freight_calculated, decimalPrecision, 'us_freight'),
        us_insurance: removeDecimalIfZero(insurance_calculated, decimalPrecision, 'us_insurance'),
        other_us_expenses: removeDecimalIfZero(other_expenses_calculated, decimalPrecision, 'other_us_expenses'),
      }))
      setSuma(parseFloat(selectCurrency === 'COP' ? cif_cop_value_calculated : cif_us_value_calculated))
      setMultiplicacion(removeDecimalIfZero(cif_cop_value_calculated, decimalPrecision, 'cif_cop_value_calculated'))
      setPesosToDollars(removeDecimalIfZero(cif_us_value_calculated, decimalPrecision, 'cif_us_value_calculated'))
    }
  }, [Calculated, decimalPrecision])

  useEffect(() => {
    let isMounted = true

    if (dataToEditItems) {
      showItems(dataToEditItems.id)
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id)
    } else {
      setValues(values)
    }

    return () => {
      isMounted = false
    }
  }, [dataToEditItems, dataToCopyItems])

  const handleBlurReferenc = (allProducts, valueProducts) => {
    if (valueProducts) {
      const findElemet = valueProducts?.value || null
      const Element = allProducts.find(el => el.id === findElemet)

      if (Element?.typeProduct_id) {
        setSelectDataTypeProduct({
          label: Element?.name_typeProduct,
          value: Element?.typeProduct_id
        })
      }

      if (Element) {
        setFieldsBackup({
          factor_conv: Element?.convertion_factor_product,
        })
        setValueSubheading(Element?.subheading_id)
        setValues({
          ...values,
          factor_conv: Element?.convertion_factor_product,
          product_unit: Element?.code_unit_measurement,
          product_description: Element?.description_product,
          form_item_description: Element?.description_product,
          description_subheading: Element?.description,
          code_subheading: Element?.code_subheading,
          code_businessUnit: Element?.businnesUnitSubheading,
          name_businessUnit: Element?.name_businessUnit
        })
      }
    }

    if (!valueProducts) {
      setValueSubheading([])
      setValues({
        ...values,
        factor_conv: '',
        product_unit: '',
        product_description: '',
        description_subheading: '',
        code_subheading: '',
        code_businessUnit: '',
        name_businessUnit: ''
      })

      if (!Element?.typeProduct_id) setSelectDataTypeProduct(null)
    }
  }
  

  useEffect(() => {
    if (code_subheading) {
      const fourNumberSubheading = code_subheading.substr(-20, 4)
      if (
        parseInt(fourNumberSubheading) === 2709 ||
        parseInt(fourNumberSubheading) === 2710
      ) {
        if (selectItemForm >= 101 && selectItemForm < 120) {
          onOpenWarning()
        }
      }
    }
  }, [code_subheading])

  useEffect(() => {
    let isMounted = true
    // getUnity();
    if (show) {
      SubheadingControl()
      getTrm()
      typeProduct()
      getAcuerdosC()
      getCountry(selectItemForm)
      getPackaging()
      getMethodTransport()
      getPuerto()
    }

    return () => {
      isMounted = false
    }
  }, [show])

  const showItems = async id => {
    setLoading(true)
    try {
      const res = await __ShowItems(token, id, environment.VIEW_ITEMS);
      const response = res?.data?.status?.data
      setDataForm({
        ...response,
        factor_conv: response?.factor_conv,
        product_unit: response?.code_unit_measurement,
        code_businessUnit: response?.code_businessUnit
      })

      setFieldsBackup({
        factor_conv: response?.factor_conv
      })
      
      if (res?.data?.status.code === 200) {
        setValues({
          ...response,
          form_item_description: response?.entry_form_item_description,
          factor_conv: response?.factor_conv,
          product_unit: response?.code_unit_measurement,
          code_businessUnit: response?.code_businessUnit,
          exchange_rate: response?.exchange_rate
        })

        setSuma(parseFloat(response?.cif_us_value));
        setMultiplicacion(parseFloat(response?.cif_cop_value));
        if (selectItemForm >= 500 && selectItemForm <= 599) {
          const optionsNational =
            {
              NAC: 'Nacional',
              EXT: 'Extranjera'
            }[response?.national_or_foreign] || ''
          setSelectNE(optionsNational)
        }

        if (response.port_id) {
          setValueSelect({ value: response.port_id, label: response.port })
        }

        const obtenerErrorItemCurrent = JSON.parse(
          localStorage.getItem(`erroField-${id}`) || '[]'
        )

        if (obtenerErrorItemCurrent.length !== 0) {
          setErrorsState(obtenerErrorItemCurrent)
        }

        if (response.flag_id) {
          setValueFlag({
            label:
              response.code_country_flag + ' ' + response.name_country_flag,
            value: response.flag_id
          })
        }

        if (response.product_id) {
          setValueProducts({
            label: response?.code_product || '',
            value: response?.product_id || '',
            factor_conv: response?.factor_conv
          })
        }
        if (response.countryOrigin_id) {
          setInfo({
            label: `${response?.code_country}  ${response?.name_country}`,
            value: response?.countryOrigin_id
          })
        }

        if (response.countryProcedency_id) {
          setDataCountryOrigin({
            label:
              response.code_country_proc + ' ' + response.name_country_proc,
            value: response.countryProcedency_id
          })
        }

        if (response.countryPurchase_id) {
          setDataCountryBuy({
            label:
              response.code_country_purs + ' ' + response.name_country_purs,
            value: response.countryPurchase_id
          })
        }
        if (response.packaging_id) {
          setPackagings({
            label: response.code_packaging + ' ' + response.name_packaging,
            value: response.packaging_id
          })
        }
        if (response.transportMethod_id) {
          setValueMethod(response.transportMethod_id)
          setEtiqueta(
            response.code_transportMethod + ' ' + response.name_transportMethod
          )
        }
        if (response?.typeProduct_id) {
          setSelectDataTypeProduct({
            label: response?.name_typeProduct,
            value: response?.typeProduct_id
          })
        }

        if (response.trade_agreement_id) {
          setAcuerdos({
            label:
              response.code_tradeAgreement +
              ' ' +
              response?.name_tradeAgreement,
            value: response.trade_agreement_id
          })
        }

        setValueSubheading(response?.subheading_id)
        setSelectCurrency(response.coin)
      } else {
        openAlert(res?.data?.status.message, 'error')
        // set(!itemss);
        setShow(false)

        setDataToEditItems(null)
        // setDataToCopyItems(null);
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const SubheadingControl = async () => {
    try {
      const res = await __SelectAcuerdosSub(token)
      setDataSuheading(res?.data?.status?.data)
    } catch (error) {}
  }

  const getTrm = async () => {
    try {
      const res = await __Trm(token)
      if (res?.data?.status?.data) {
        setValues(prev => ({
          ...prev,
          ...((prev?.exchange_rate === '' || prev?.exchange_rate === null || prev?.exchange_rate === undefined || prev?.exchange_rate === 0) && 
            { 'exchange_rate': res?.data?.status?.data.value_trm}),
        }))
        setValorDolarActual(res?.data?.status?.data.value_trm)
      } else {
        setValues(prev => ({
          ...prev,
          exchange_rate: 0
        }))
        setValorDolarActual(0)
      }
    } catch (error) { }
  }

  useEffect(() => {
    setValues({ ...values, item_temporary_exit: '' })
  }, [letNumDepartureAuthorization])

  const handleClean = () => {
    // handleInvoice();
    setInfo(null)
    setValueProducts(null)
    setNameProducts(null)
    setNameSubheading(null)
    setValueSubheading(null)
    setDataCountryOrigin(null)
    setValueMethod(null)
    setDataCountryBuy(null)
    setValueFlag(null)
    setPackagings(null)
    setSelectDataTypeProduct(null)
    setAcuerdos('')
    reset()
    // setUnidad([]);
    setSelectNE('')
    setPlaceU(null)
    setEtiqueta(null)
    setShow(false)
  }

  const handleCancel = () => {
    setDataToEditItems(null)
    setDataToCopyItems(null)
    handleClean()
  }

  const handleChangeCoin = e => {
    setSelectCurrency(e)
    updateCurrencyField(e)
  }

  const createProduct = () => {
    onOpenEdit()
    setRequestCreate(environment.CREATE_PRODUCT_INT)
  }

  useEffect(() => {
    return () => {
      setShow(false)
    }
  }, [])

  const handleChangeRadio = e => {
    const valor = e.target.id
    setValues({ ...values, invoice: valor })
    onCloseInvoice()
  }

  const [pesosToDollars, setPesosToDollars] = useState(0)

  useEffect(() => {
    if (product_unit?.toLowerCase() === 'kg' && net_weight !== quantity) {
      setValues(prevState => ({ ...prevState, net_weight: quantity }))
    }
  }, [quantity])

  const [handleDebounceRegexError] = useDebounceError()

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all'
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    )
  }

  const { user_Qualified, multiFieldState, setMultiFieldState } =
    useGlobalContext()

  const handleSelectChange = (items, selectCurrentValue) => {
    handleBlurReferenc(items, selectCurrentValue)
  }

  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue, formattedValue, value } = newValue
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target]: {
        ...sourceEvent?.event?.target,
        value: parseFloat(floatValue || value),
      },
    }
    handleInputChange(target)
  }

  const colorNotation = propertyName => {
    return (
      updateNotationData?.[formId]?.[values?.id]?.[propertyName]?.status
        ?.color || reviewsData?.[values?.id]?.[propertyName]?.status?.color
    )
  }

  const customStyles = propertyName => {
    const color = colorNotation(propertyName)

    if (color) {
      return {
        control: provided => ({
          ...provided,
          borderColor: color
        }),
        dropdownIndicator: provided => ({
          ...provided,
          color
        })
      }
    }
  }

  const nameSelect = {
    name_typeProduct: 'Tipo de producto',
    name_country: 'País origen',
    name_country_proc: 'País procedencia',
    name_country_purs: 'País compra',
    name_transportMethod: 'Modo de transporte',
    name_country_flag: 'Bandera',
    name_packaging: 'Embalaje',
    name_tradeAgreement: 'Acuerdo de Comercio'
  }

  const selectChange = (items, select) => {
    return updateComment(
      select?.name,
      nameSelect?.[select?.name],
      items?.label,
      dataForm?.[select?.name] || '',
      dataForm?.id
    )
  }

  const handleResponseItem = res => {

    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      setValues({ ...values, item_temporary_exit: '' })
      return openAlert(res?.data?.status?.message, 'error')
    }
    if (res?.data?.status?.code === 200) {
      
      const dataItem = res?.data?.status?.data[0]
      if (dataItem?.country_origin_id) {
        setInfo({
          label: `${dataItem?.country_origin_code}  ${dataItem?.country_origin_name}`,
          value: dataItem?.country_origin_id
        })
      }

      if (dataItem.typeProduct_id) {
        setSelectDataTypeProduct({
          label: dataItem?.name_typeProduct,
          value: dataItem?.typeProduct_id
        })
      }

      if (dataItem.country_purchase_id) {
        setDataCountryBuy({
          label:
            dataItem.country_purchase_code +
            ' ' +
            dataItem.country_purchase_name,
          value: dataItem.country_purchase_id
        })
      }

      if (dataItem.transportMethod_id) {
        setValueMethod(dataItem.transportMethod_id)
        setEtiqueta(
          dataItem.code_transportMethod + ' ' + dataItem.name_transportMethod
        )
      }

      if (dataItem?.country_procedency_id) {
        setDataCountryOrigin({
          label:
            dataItem?.country_procedency_code +
            ' ' +
            dataItem?.country_procedency_name,
          value: dataItem?.country_procedency_id
        })
      }

      if (dataItem?.subheading_id) setValueSubheading(dataItem?.subheading_id)

      if (dataItem.country_flag_id) {
        setValueFlag({
          label: dataItem.country_flag_code + ' ' + dataItem.country_flag_name,
          value: dataItem.country_flag_id
        })
      }

      if (dataItem.packaging_id) {
        setPackagings({
          label: dataItem.code_packaging + ' ' + dataItem.name_packaging,
          value: dataItem.packaging_id
        })
      }

      if (dataItem.tradeAgreement_id) {
        setAcuerdos({
          label:
            dataItem.code_tradeAgreement + ' ' + dataItem?.name_tradeAgreement,
          value: dataItem.tradeAgreement_id
        })
      }

      if (dataItem.id) {
        setValueProducts({
          label: dataItem?.code_product || '',
          value: dataItem?.id || ''
        })
      }

      setValues(prev => ({
        ...prev,
        ...dataItem,
        amount_sdo: dataItem?.product_quantity,
        product_quantity: dataItem?.quantity_product,
        factor_conv: dataItem?.convertion_factor_product,
        product_unit: dataItem?.code_unit_measurement,
        product_description: dataItem?.description_product,
        form_item_description: dataItem?.description_product,
        description_subheading: dataItem?.description,
        code_subheading: dataItem?.code_subheading,
        code_businessUnit: dataItem?.businnesUnitSubheading,
        name_businessUnit: dataItem?.name_businessUnit,
        exchange_rate: dataItem?.exchange_rate,
      }))
      setSelectCurrency(dataItem?.coin);
      /* console.log({cif_us_value: dataItem?.cif_us_value})
     */
      if (dataItem?.coin !== 'COP') {
        setSuma(dataItem?.cif_us_value);
        setMultiplicacion(dataItem?.cif_cop_value);
      } 

    }
  }

  const handleResponseConvertion = res => {
    if (res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, 'error')
    }
    if (res?.data?.status?.code === 400) {
      setValues({ ...values, item_temporary_exit: '' })
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const dataItem = res?.data?.status?.data[0]
      setValues(prev => ({
        ...prev,
        /* amount_sdo: dataItem?.product_quantity, */
        /* product_quantity: dataItem?.quantity_product, */
        us_freight: dataItem?.us_freight,
        us_insurance: dataItem?.us_insurance,
        other_us_expenses: dataItem?.other_us_expenses,
        us_fob_value: dataItem?.us_fob_value,
        quantity: dataItem?.quantity,
        net_weight: dataItem?.net_weight,
        gross_weight: dataItem?.gross_weight,
        number_packages: dataItem?.number_packages,
        exchange_rate: dataItem?.exchange_rate
      }))
    }
  }

  const { isLoading, mutate: MutateItemsTemporary } = useMutation({
    mutationKey: ['itemsTemporaryExit'],
    mutationFn: requestData
  })

  const ItemsTemporary = (item_temporary_exit, quatity_product = null) => {
    const formdata = new FormData()
    if (valuesHeader?.num_exit_form) {
      formdata.append('num_exit_form', valuesHeader?.num_exit_form);
    }

    if (valuesHeader?.integration_box_certificate) {
      formdata.append('integration_box_certificate', valuesHeader?.integration_box_certificate)
    }

    if (item_temporary_exit) {
      formdata.append('item_temporary_exit', item_temporary_exit?.replaceAll(',', ''))
    }
    if (quatity_product) {
      formdata.append(
        'quantity_product',
        quatity_product?.replaceAll(',', '') || 0
      )
    }

    MutateItemsTemporary(
      {
        resourceIdentifier: user_Qualified || userQualified,
        data: formdata,
        Endpoint: environment.PRODUCTSREFERENCEUSERAALL,
        PropertyBody: 'sendFormDataContentPOST'
      },
      {
        onSuccess: data => {
          if (quatity_product) return handleResponseConvertion(data)

          handleResponseItem(data)
        }
      }
    )
  }

  useEffect(() => {
    setRequestEdit(environment.UPDATE_PRODUCT_INT)
  }, [])
  

  const hoverActive = () => {
    if (selectCurrency === "USD" && ((!dataFormRedux?.max_value_cop && parseFloat(us_fob_value) * parseFloat(exchange_rate || valorDolarActual) > 20000000) || (dataFormRedux?.max_value_cop && parseFloat(us_fob_value) * parseFloat(exchange_rate || valorDolarActual) > dataFormRedux?.max_value_cop))) {
      return (
        <div className="">
          <Hover
            title={
              "Valor máximo de ingreso"
            }
            description={
              `Recuerde: el valor FOB USD * Tasa de cambio no debe exceder los ${dataFormRedux?.max_value_cop ? `${parseFloat(dataFormRedux?.max_value_cop)} USD` : "20.000.000 USD"}`
            }
            icon={<BsInfoCircle />}
            autoFocus={false}

          />
        </div>
      )
    }
    if (selectCurrency === "COP" && ((!dataFormRedux?.max_value_cop && parseFloat(us_fob_value) > 20000000) || (dataFormRedux?.max_value_cop && parseFloat(us_fob_value) > dataFormRedux?.max_value_cop))) {  
      return (
        <div className="">
          <Hover
            title={
              "Valor máximo de ingreso"
            }
            description={
              `Recuerde: el valor FOB COP no debe exceder los ${dataFormRedux?.max_value_cop ? `${parseFloat(dataFormRedux?.max_value_cop)} COP` : "20.000.000 COP"}`
            }
            icon={<BsInfoCircle />}
            autoFocus={false}

          />
        </div>
      ) 
    }
  }

  return (
    <>
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={'xl'}
        modaltitle={''}
        handleClick={editData}
        buttonMain={'Editar'}
      >
        <ModalAdd />
      </ModalTransition>

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Advertencia`}
        titleBody={`Este ingreso contiene las partidas 2709 o 2710, las cuales deben ser reportadas a GIT DIAN según oficio 187245452'0075, informar a su jefe inmediato`}
      />

      <ModalGeneric
        isOpen={isOpenInvoice}
        onOpen={onOpenInvoice}
        onClose={onCloseInvoice}
        title='Seleccionar factura existente'
        size='sm'
      >
        {valueInvoice &&
          valueInvoice.map((e, index) => {
            if (e.invoice) {
              return (
                <div key={index}>
                  <Radio
                    id={e.invoice}
                    value={e.invoice}
                    onChange={handleChangeRadio}
                  >
                    {e.invoice}
                  </Radio>
                  <br />
                </div>
              )
            }
          })}

        <Flex mt={5}>
          <Spacer />
          <Cancelar onClick={onCloseInvoice}>Cancelar</Cancelar>
        </Flex>
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <div id="contenedores" className="my-2">
          <h1 className=' ms-1 fw-bolder'>Agregar ítem</h1>
          <SimpleGrid minChildWidth='200px' my={4}>
            <Tooltip
              hasArrow
              label={
                Reviews
                  ? SearchEditedFields(`code_product_${values?.id}`, Reviews)
                  : ''
              }
              bg='red.600'
              color='white'
              placement='bottom-start'
            >
              <div className='d-flex justify-content-center align-items-center'>
                <ProductSearchBar
                  label='Código producto'
                  size={100}
                  required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                  set={setValueProducts}
                  value={valueProducts}
                  callBack={handleSelectChange}
                  name='code_product'
                  Name={`code_product_${values?.id}`}
                  error={
                    Reviews &&
                    SearchEditedFields(`code_product_${values?.id}`, Reviews)
                      ? true
                      : errorsState.product_id && !valueProducts
                  }
                  ButtonSide={true}
                  isDisabled={formOperation?.reentry_operation === 1}
                />
                {selectItemForm >= 700 && selectItemForm <= 799 ? (
                  <Tooltip hasArrow label={'Editar producto'} placement='top'>
                    <Button
                      onClick={() => {
                        setDataToEdit(values)
                        onOpenEdit()
                      }}
                      w={"42px"}
                      h={"40px"}
                      marginTop={"21px"}
                      borderRadius={0}
                      borderTopRightRadius={'5px'}
                      borderBottomRightRadius={'5px'}
                      color={'#fff'}
                      backgroundColor={'#1f7ac3'}
                      paddingBottom={'1px'}
                      outline={'none'}
                    >
                      <RiEdit2Fill size={'45px'} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip hasArrow label={'Agregar producto'} placement='top'>
                    <Button
                      onClick={createProduct}
                      w={"42px"}
                      h={"40px"}
                      marginTop={"21px"}
                      fontSize={'25px'}
                      borderRadius={0}
                      borderTopRightRadius={'5px'}
                      borderBottomRightRadius={'5px'}
                      color={'#fff'}
                      backgroundColor={'#1f7ac3'}
                      paddingBottom={'1px'}
                      outline={'none'}
                      _hover={{ backgroundColor: '#007ac3' }}
                      isDisabled={formOperation?.reentry_operation === 1}
                    >
                      +
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Tooltip>

          
          </SimpleGrid>
          <SimpleGrid
              columns={{ base: 1, sm: 1, md: 3 }}
              minChildWidth={{ "3sm": '200px', md: "200px" }}
            gap={2}
            my={4}
          >
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.product_unit?.comment}
              bg={colorNotation('product_unit')}
              color='white'
              placement='bottom-start'
            >
              <div className='mt-1'>
                <LabelGroup
                  onChange={handleInputChange}
                  value={product_unit || ''}
                  placeholder={'Unidad de producto'}
                  tag={'Unidad de producto'}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.product_unit}
                  name={'product_unit'}
                  size={100}
                  desicion={true}
                  error={colorNotation('product_unit')}
                />
              </div>
            </Tooltip>
            <Tooltip
              hasArrow
              label={
                Reviews
                  ? SearchEditedFields(`factor_conv_${values?.id}`, Reviews)
                  : ''
              }
              bg='red.600'
              color='white'
              placement='bottom-start'
            >
              <div className='mt-1'>
                <NumericFormat
                  allowNegative={false}
                  thousandSeparator={true}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  name={'factor_conv'}
                  onChange={handleInputChange}
                  value={
                    isString(factor_conv)
                      ? parseFloat(factor_conv)
                      : factor_conv
                  }
                  tag={'Factor Conv.'}
                  size={100}
                  desicion={formOperation?.reentry_operation === 0 ? false : true}
                  error={
                    !!(
                      Reviews &&
                      SearchEditedFields(`factor_conv_${values?.id}`, Reviews)
                    )
                  }
                />
              </div>
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.product_quantity?.comment}
              bg={colorNotation('product_quantity')}
              color='white'
              placement='bottom-start'
            >
              <div className='mt-1'>
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    if (formOperation?.reentry_operation !== 1){
                      return true
                    }
                    return parseFloat(floatValue) <= parseFloat(amount_sdo);
                  }}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.product_quantity || 0}
                  name={'product_quantity'}
                  error={colorNotation('product_quantity')}
                  value={product_quantity}
                  tag={'Cantidad de producto'}
                  maxLength={MaxLength.formEntry.addItemForm.product_quantity}
                  size={100}
                  required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
              </div>
            </Tooltip>
          </SimpleGrid>

          
          <SimpleGrid
            columns={{ base: 1, sm: 1, "3sm": 3, md: 6, lg: 6 }}
            minChildWidth={{ "3sm": '200px', md: "200px", lg: "150px" }}
            my={4}
            gap={2}
          >
            <NumericFormat
              allowNegative={false}
              onValueChange={updateItemValue}
              thousandSeparator={true}
              decimalScale={0}
              decimalSeparator='.'
              customInput={LabelGroup}
              name={'item_temporary_exit'}
              // onChange={handleInputChange}
              value={item_temporary_exit}
              placeholder={0}
              tag={'Ítem salida temporal'}
              size={100}
              desicion={(!valuesHeader?.num_exit_form && !valuesHeader?.integration_box_certificate)}
            />
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_typeProduct?.comment}
              bg={colorNotation('name_typeProduct')}
              color='white'
              placement='bottom-start'
            >
              <InputSelect
                tag='Tipo de producto *'
                data={dataTypeProduct}
                set={setSelectDataTypeProduct}
                value={selectDataTypeProduct}
                styles={customStyles('name_typeProduct')}
                onChange={selectChange}
                name='name_typeProduct'
                error={
                  colorNotation('name_typeProduct') ||
                  (errorsState.typeProduct_id && !selectDataTypeProduct)
                }
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
            <LabelGroup
              name={'gross_weight_sdo'}
              onChange={e => OnlyNumberInput(e, setValues, values)}
              value={gross_weight_sdo}
              tag={'Peso bruto Sdo.'}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={'amount_sdo'}
              onChange={e => OnlyNumberInput(e, setValues, values)}
              value={amount_sdo}
              tag={'Cantidad Sdo.'}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={'fob_sdo_value'}
              onChange={e => OnlyNumberInput(e, setValues, values)}
              value={fob_sdo_value}
              tag={'Valor FOB Sdo.'}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={'cif_value_sdo'}
              onChange={e => OnlyNumberInput(e, setValues, values)}
              value={cif_value_sdo}
              tag={'Valor CIF Sdo.'}
              size={100}
              desicion={true}
            />
          </SimpleGrid>

          <SimpleGrid
            columns={{ sm: 1, md: 3, lg: 3 }}
            minChildWidth={{ "3sm": '200px', md: "200px", lg: "250px", xl: "320px" }}
            my={4}
            gap={2}
          >
            <Tooltip
              hasArrow
              label={
                Reviews
                  ? SearchEditedFields(`code_subheading_${values?.id}`, Reviews)
                  : ''
              }
              bg='red.600'
              color='white'
              placement='bottom-start'
            >
              <LabelGroup
                name={'code_subheading'}
                onChange={handleInputChange}
                value={code_subheading || ''}
                tag={'Subpartida arancelaria'}
                size={100}
                error={
                  colorNotation('code_subheading') ||
                  errorsState.code_subheading
                }
                desicion={true}
              />
            </Tooltip>
            <LabelGroup
              name={'description_subheading'}
              onChange={handleInputChange}
              value={description_subheading || ''}
              tag={'Descripción de la subpartida'}
              size={100}
              error={
                colorNotation('description_subheading') ||
                errorsState.description_subheading
              }
              desicion={true}
            />
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country?.comment}
              bg={colorNotation('name_country')}
              color='white'
              placement='bottom-start'
            >
              <InputSelect
                tag={'País origen *'}
                data={dataCountry120 || dataCountry}
                set={setInfo}
                onChange={selectChange}
                name='name_country'
                styles={customStyles('name_country')}
                value={isValid(info) ? info : {}}
                error={
                  colorNotation('name_country') ||
                  (errorsState.countryOrigin_id && !info)
                }
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_proc?.comment}
              bg={colorNotation('name_country_proc')}
              color='white'
              placement='bottom-start'
            >
                <InputSelect
                  tag={'País procedencia *'}
                  data={dataProcedencia || dataCountry120 || dataCountry}
                  set={setDataCountryOrigin}
                  name='name_country_proc'
                  onChange={selectChange}
                  styles={customStyles('name_country_proc')}
                  value={ dataCountryOrigin || {}}
                  error={
                    colorNotation('name_country_proc') ||
                    (errorsState.countryProcedency_id && !dataCountryOrigin)
                  }
                  isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_purs?.comment}
              bg={colorNotation('name_country_purs')}
              color='white'
              placement='bottom-start'
            >
              <InputSelect
                tag={'País compra *'}
                data={dataCountry120 || dataCountry}
                set={setDataCountryBuy}
                value={dataCountryBuy || {}}
                name='name_country_purs'
                onChange={selectChange}
                styles={customStyles('name_country_purs')}
                error={
                  colorNotation('name_country_purs') ||
                  (errorsState.countryPurchase_id && !dataCountryBuy)
                }
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.gross_weight?.comment}
              bg={colorNotation('gross_weight')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.gross_weight}
                  name={'gross_weight'}
                  value={gross_weight || 0}
                  tag={'Peso bruto'}
                  maxLength={MaxLength.formEntry.addItemForm.gross_weight}
                  size={100}
                  error={
                    colorNotation('gross_weight') ||
                    (errorsState.gross_weight &&
                      (!gross_weight ||
                        Number(gross_weight) === 0 ||
                        Number(gross_weight) < Number(net_weight)))
                  }
                  required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                >
                  {errorsState.gross_weight && (
                    <span className='text-danger'>
                      {Number(gross_weight) < Number(net_weight)
                        ? 'El Peso Bruto debe ser mayor o igual al peso Neto'
                        : Number(gross_weight) === 0
                        ? 'El Peso Bruto no puede ser igual a cero'
                        : ''}
                    </span>
                  )}
                </NumericFormat>
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.net_weight?.comment}
              bg={colorNotation('net_weight')}
              color='white'
              placement='bottom-start'
            >
              <NumericFormat
                allowNegative={false}
                onValueChange={updateItemValue}
                thousandSeparator={true}
                decimalScale={decimalPrecision}
                decimalSeparator='.'
                customInput={LabelGroup}
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.net_weight}
                name={'net_weight'}
                value={net_weight}
                tag={'Peso neto'}
                maxLength={MaxLength.formEntry.addItemForm.net_weight}
                size={100}
                error={
                  colorNotation('net_weight') ||
                  (errorsState.net_weight &&
                    (!net_weight ||
                      Number(net_weight) === 0 ||
                      Number(net_weight) > Number(gross_weight)))
                }
                required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                desicion={selectItemForm >= 700 && selectItemForm <= 799}
              >
                {errorsState.net_weight && (
                  <span className='text-danger'>
                    {Number(net_weight) > Number(gross_weight)
                      ? 'El Peso Neto debe ser menor o igual al Peso Bruto'
                      : Number(net_weight) === 0
                      ? 'El Peso Neto no puede ser igual a cero'
                      : ''}
                  </span>
                )}
              </NumericFormat>
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.number_packages?.comment}
              bg={colorNotation('number_packages')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.number_packages}
                  name={'number_packages'}
                  value={number_packages || 0}
                  tag={'Números de bultos'}
                  maxLength={MaxLength.formEntry.addItemForm.number_packages}
                  size={100}
                  error={colorNotation('number_packages')}
                  required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.quantity?.comment}
              bg={colorNotation('quantity')}
              color='white'
              placement='bottom-start'
            >
              <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.quantity}
                  error={colorNotation('quantity')}
                  name={'quantity'}
                  value={quantity || 0}
                  tag={'Cantidad '}
                  maxLength={MaxLength.formEntry.addItemForm.quantity}
                  size={100}
                  required={!(selectItemForm >= 700 && selectItemForm <= 799)}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.code_businessUnit?.comment}
              bg={colorNotation('code_businessUnit')}
              color='white'
              placement='bottom-start'
            >
              <LabelGroup
                name={'code_businessUnit'}
                error={colorNotation('code_businessUnit')}
                value={
                  !code_businessUnit
                    ? ''
                    : `${code_businessUnit} ${name_businessUnit}`
                }
                onChange={handleInputChange}
                tag={'Unidad comercial'}
                size={100}
                desicion={true}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_transportMethod?.comment}
              bg={colorNotation('name_transportMethod')}
              color='white'
              placement='bottom-start'
            >
              <SelectMethodTransport
                data={dataTransportMethod}
                tag={'Modo de transporte *'}
                size={100}
                setValor={setValueMethod}
                valor={valueMethod}
                etiqueta={etiqueta}
                setEtiqueta={setEtiqueta}
                name='name_transportMethod'
                onChange={selectChange}
                styles={customStyles('name_transportMethod')}
                error={
                  colorNotation('name_transportMethod') ||
                  (errorsState.transportMethod_id && !valueMethod)
                }
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_flag?.comment}
              bg={colorNotation('name_country_flag')}
              color='white'
              placement='bottom-start'
            >
              <InputSelect
                tag={'Bandera *'}
                data={dataCountry}
                set={setValueFlag}
                value={valueFlag || {}}
                name='name_country_flag'
                onChange={selectChange}
                styles={customStyles('name_country_flag')}
                error={
                  colorNotation('name_country_flag') ||
                  (errorsState.flag_id && !valueFlag)
                }
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_packaging?.comment}
              bg={colorNotation('name_packaging')}
              color='white'
              placement='bottom-start'
            >
              <SelectPackaging
                data={dataPackagings}
                tag={'Embalaje *'}
                size={100}
                name='name_packaging'
                onChange={selectChange}
                styles={customStyles('name_packaging')}
                error={
                  colorNotation('name_packaging') ||
                  (errorsState.packaging_id && !packagings)
                }
                packagings={packagings?.value ? packagings : ''}
                setPackagings={setPackagings}
                isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>

            <Box className='d-flex'>
              <div className='mx-2'>
                <LabelStyled>
                  Monedas
                </LabelStyled>

                <RadioBtnRedonder
                  options={dataCoin}
                  setOnly={handleChangeCoin}
                  state={selectCurrency || ''}
                  isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
                />
              </div>

              <Tooltip
                hasArrow
                label={reviewsData?.[values?.id]?.us_freight?.comment}
                bg={colorNotation('us_freight')}
                color='white'
                placement='bottom-start'
              >
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemValue}
                    thousandSeparator={true}
                   decimalScale={decimalPrecision}
                    decimalSeparator='.'
                    customInput={LabelGroup}
                    data-item-id={dataForm?.id}
                    data-old-Value={dataForm?.us_freight}
                    name={'us_freight'}
                    value={us_freight || 0}
                    tag={`Fletes ${selectCurrency} `}
                    maxLength={MaxLength.formEntry.addItemForm.us_freight}
                    size={100}
                    error={
                      colorNotation('us_freight') ||
                      (errorsState.us_freight &&
                        Number(us_freight) === 0 &&
                        Number(selectItemForm) !== 120)
                    }
                    desicion={selectItemForm >= 700 && selectItemForm <= 799}
                  >
                    {' '}
                    {errorsState.us_freight &&
                      Number(us_freight) === 0 &&
                      Number(selectItemForm) !== 120 && (
                        <span className='text-danger'>
                          {Number(us_freight) === 0 &&
                          Number(selectItemForm) >= 101 &&
                          Number(selectItemForm) <= 199 &&
                          Number(selectItemForm) !== 120
                            ? 'el valor de los fletes no puede ser cero'
                            : ''}
                        </span>
                      )}
                  </NumericFormat>
              </Tooltip>
            </Box>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.us_insurance?.comment}
              bg={colorNotation('us_insurance')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.us_insurance}
                  name={'us_insurance'}
                  value={us_insurance || 0}
                  tag={` Seguros ${selectCurrency} `}
                  maxLength={MaxLength.formEntry.addItemForm.us_insurance}
                  size={100}
                  error={colorNotation('us_insurance')}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>
            
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.other_us_expenses?.comment}
              bg={colorNotation('other_us_expenses')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.other_us_expenses}
                  name={'other_us_expenses'}
                  value={other_us_expenses || 0}
                  tag={` Otros gastos ${selectCurrency} `}
                  maxLength={MaxLength.formEntry.addItemForm.other_us_expenses}
                  size={100}
                  error={colorNotation('other_us_expenses')}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.us_fob_value?.comment}
              bg={colorNotation('us_fob_value')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.us_fob_value}
                  name={'us_fob_value'}
                  value={us_fob_value || 0}
                  tag={` Valor FOB ${selectCurrency || ''} `}
                  maxLength={MaxLength.formEntry.addItemForm.us_fob_value}
                  size={100}
                  error={colorNotation('us_fob_value')}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                  TagChildren={
                    hoverActive()
                  }
                />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.cif_us_value?.comment}
              bg={colorNotation('cif_us_value')}
              color='white'
              placement='bottom-start'
            >
                <NumericFormat
                  allowNegative={false}
                  // onValueChange={updateItemValue}
                  thousandSeparator={true}
                 decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.cif_us_value}
                  name={'cif_us_value'}
                  value={suma || 0}
                  tag={`Valor CIF ${selectCurrency || ''} `}
                  maxLength={MaxLength.formEntry.addItemForm.cif_us_value}
                  size={100}
                  readOnly={true}
                  error={colorNotation('cif_us_value')}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>

            {selectCurrency !== 'COP' ? (
              <Tooltip
                hasArrow
                label={
                  Reviews
                    ? SearchEditedFields(`cif_cop_value_${values?.id}`, Reviews)
                    : ''
                }
                bg='red.600'
                color='white'
                placement='bottom-start'
              >
                  <NumericFormat
                    allowNegative={false}
                    // onValueChange={updateItemValue}
                    thousandSeparator={true}
                    decimalScale={decimalPrecision}
                    decimalSeparator='.'
                    customInput={LabelGroup}
                    data-item-id={dataForm?.id}
                    data-old-Value={dataForm?.cif_cop_value}
                    name={'cif_cop_value'}
                    value={removeDecimalIfZero(multiplicacion, decimalPrecision, 'Valor CIF COP')}
                    tag={`Valor CIF COP`}
                    maxLength={MaxLength.formEntry.addItemForm.cif_cop_value}
                    size={100}
                    readOnly={true}
                    error={colorNotation('cif_cop_value')}
                    desicion={selectItemForm >= 700 && selectItemForm <= 799}
                  />
              </Tooltip>
            ) : (
              <Tooltip
                hasArrow
                label={reviewsData?.[values?.id]?.cif_cop_value?.comment}
                bg={colorNotation('cif_cop_value')}
                color='white'
                placement='bottom-start'
              >
            
                  <NumericFormat
                    allowNegative={false}
                    // onValueChange={updateItemValue}
                    thousandSeparator={true}
                   decimalScale={decimalPrecision}
                    decimalSeparator='.'
                    customInput={LabelGroup}
                    data-item-id={dataForm?.id}
                    data-old-Value={dataForm?.cif_cop_value}
                    name={'cif_cop_value'}
                    value={removeDecimalIfZero(pesosToDollars, decimalPrecision, 'Valor CIF USD')}
                    tag={`Valor CIF USD`}
                    maxLength={MaxLength.formEntry.addItemForm.cif_cop_value}
                    size={100}
                    readOnly={true}
                    error={colorNotation('cif_cop_value')}
                    desicion={selectItemForm >= 700 && selectItemForm <= 799}
                  />
              </Tooltip>
            )}

             <Tooltip
                hasArrow
                label={reviewsData?.[values?.id]?.exchange_rate?.comment}
                bg={colorNotation('exchange_rate')}
                color='white'
                placement='bottom-start'
              >
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalPrecision}
                  decimalSeparator='.'
                  customInput={LabelGroup}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.exchange_rate}
                  name={'exchange_rate'}
                  value={exchange_rate}
                  onBlur={() => {
                    if(!exchange_rate || exchange_rate === '' || exchange_rate === 0 || exchange_rate === '0'){
                      setValues((prev) => ({ ...prev, exchange_rate: valorDolarActual }));
                      onFieldChange('exchange_rate', valorDolarActual);
                    }
                  }}
                  defaultValue={valorDolarActual}
                  tag={`Tasa de Cambio`}
                  maxLength={MaxLength.formEntry.addItemForm.exchange_rate}
                  size={100}
                  error={colorNotation('exchange_rate')}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
            </Tooltip>

            {(Number(selectItemForm) < 300 || Number(selectItemForm) > 399) && (
                <Tooltip
                  hasArrow
                  label={
                    reviewsData?.[values?.id]?.name_tradeAgreement?.comment
                  }
                  bg={colorNotation('name_tradeAgreement')}
                  color='white'
                  placement='bottom-start'
                >
                  <AcuerdoC
                    data={dataAgreements}
                    tag={'Acuerdo Comercial'}
                    size='100'
                    setAcuerdos={setAcuerdos}
                    onChange={selectChange}
                    styles={customStyles('name_tradeAgreement')}
                    name={`name_tradeAgreement`}
                    Acuerdos={acuerdos || []}
                    error={colorNotation('name_tradeAgreement')}
                    isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
                  />
                </Tooltip>
            )}

            {valueMethod &&
              valueMethod === 1 &&
              port === 1 &&
              dataFormRedux?.code_operation >= 100 &&
              dataFormRedux?.code_operation <=
                199 /* Solo debe aparecer en las OP 100 <= x <= 200 */ &&
              dataFormRedux?.reentry_operation !==
                1 /* Para las de reingreso no debe aparecer el select */ && (
                  <InputSelect
                    value={valueSlect}
                    set={setValueSelect}
                    data={dataSelect}
                    error={errorsState.port_id && !valueSlect}
                    tag='Puerto'
                    isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
                  />
              )}

            {selectItemForm >= 500 && selectItemForm <= 599 && (
               <div className='d-flex'>
                 <div className='mx-2'>
                  <LabelStyled>
                    Naturaleza
                  </LabelStyled>
                  <RadioBtnRedonder
                    options={dataNaturaleza}
                    setOnly={setSelectNE}
                    state={selectNE === null ? '' : selectNE}
                  />
                 </div>
               </div>
              
            )}

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.invoice?.comment}
              bg={colorNotation('invoice')}
              color='white'
              placement='bottom-start'
            >
              <div className='d-flex justify-content-center align-items-center'>
                <LabelGroup
                  name={'invoice'}
                  value={invoice || ''}
                  onChange={e =>
                    handleRegex(e, 'all', Regex?.all)
                  }
                  error={colorNotation('invoice')}
                  tag={`Factura`}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.invoice}
                  size={100}
                  ButtonSide={valuesHeader.num_commercial_invoice && true}
                  maxLength={MaxLength.formEntry.addItemForm.invoice}
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
                {valuesHeader.num_commercial_invoice && (
                  <Tooltip
                    hasArrow
                    label={'Seleccionar factura existente'}
                    placement='top'
                  >
                    <IconButton
                      onClick={getInvoice}
                      w={'42px'}
                      h={'38px'}
                      marginTop={'22px'}
                      borderRadius={0}
                      borderTopRightRadius={'5px'}
                      borderBottomRightRadius={'5px'}
                      color={'#fff'}
                      backgroundColor={'#1f7ac3'}
                      paddingBottom={'1px'}
                      outline={'none'}
                      icon={<TbFileInvoice />}
                      disabled={selectItemForm >= 700 && selectItemForm <= 799}
                    />
                  </Tooltip>
                )}
              </div>
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.brand?.comment}
              bg={colorNotation('brand')}
              color='white'
              placement='bottom-start'
            >
              <LabelGroup
                name={'brand'}
                value={brand || ''}
                onChange={e =>
                  handleRegex(e, 'all', Regex?.all)
                }
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.brand}
                error={colorNotation('brand')}
                tag={`Marca`}
                size={100}
                maxLength={MaxLength.formEntry.addItemForm.brand}
                desicion={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.serial?.comment}
              bg={colorNotation('serial')}
              color='white'
              placement='bottom-start'
            >
              <LabelGroup
                name={'serial'}
                value={serial || ''}
                onChange={e =>
                  handleRegex(e, 'all', Regex?.all)
                }
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.serial}
                error={colorNotation('serial')}
                tag={`Serial`}
                size={100}
                maxLength={MaxLength.formEntry.addItemForm.serial}
                desicion={selectItemForm >= 700 && selectItemForm <= 799}
              />
            </Tooltip>
          </SimpleGrid>

    



          <SimpleGrid>
            {' '}
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.form_item_description?.comment}
              bg={colorNotation('form_item_description')}
              color='white'
              placement='bottom-start'
            >
              <div className='mt-1'>
                <LabeFormlStyled>Descripción larga</LabeFormlStyled>
                <Textarea
                  name={'form_item_description'}
                  onChange={handleInputChange}
                  value={form_item_description}
                  className='description'
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.form_item_description}
                  borderColor={colorNotation('form_item_description')}
                  maxLength={
                    MaxLength.formEntry.addItemForm.entry_form_item_description
                  }
                />
              </div>
            </Tooltip>
          </SimpleGrid>

          <Box
            display='flex'
            alignItems='center'
            marginTop={'20px'}
            marginBottom={'20px'}
            justifyContent='flex-end'
            flexWrap={'wrap'}
          >
            <ButtonStyled bgColor={'#bababa'} onClick={handleCancel}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled
              isLoading={isPending}
              onClick={handleSubmit}
            >
              {dataToEditItems ? 'Actualizar' : 'Agregar'}
            </ButtonStyled>
          </Box>
        </div>
      )}
    </>
  )
}

export default AddItemsForm
