import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdClear } from "react-icons/md";
import React, { useContext } from "react";
import { RiEdit2Fill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import { RxCircleBackslash } from "react-icons/rx";

import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FaWeight } from "react-icons/fa";
import WeighingContext from "context/WeighingContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const TbodyMenu = ({ data, onOpen }) => {
  const { onOpenReweigh, setIdReweigh, setCurrentDataReweigh } =
    useContext(WeighingContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const ChangeStatus = async (status) => {
    try {
      const resp = await __Post(
        environment.CHANGE_STATUS_WEIGHING,
        token,
        status
      );
      const resultado = resp.data.status;
      if (resultado.code === 200) {
        openAlert(`Ha sido ${status?.status.toLowerCase()}`, "sucess");
      }
    } catch (error) {
      openAlert(`Hubo un error`, "error");
    }
  };

  const Status = data && data?.operation === "Despacho" ? 2 : 1;

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {" "}
        {data?.consecutive !== "0" &&
        data?.consecutive !== 0 &&
        data?.consecutive
          ? (
            <>
              {" "}
              <MenuItemStyled
                py={3}
                onClick={() => {
                  const WeighData = {
                    weight_id: data?.weighing_id,
                    pass: Status
                  };
                  setIdReweigh(WeighData);
                  setCurrentDataReweigh(data);
                  onOpenReweigh();
                }}
              >
                <IconMenuRUD color="#0856dd" as={FaWeight} />
            Repesar
              </MenuItemStyled>{" "}
              <HrDividerNoMargin />
              <MenuItemStyled
                py={3}
                onClick={() =>
                  ChangeStatus({
                    status: "INHABILITADO",
                    weight_id: data?.weighing_id
                  })
                }
              >
                <IconMenuRUD color="gray" as={RxCircleBackslash} />
              inhabilitar
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled
                py={3}
                onClick={() =>
                  ChangeStatus({
                    status: "CANCELADO",
                    weight_id: data?.weighing_id
                  })
                }
              >
                <IconMenuRUD color="red" as={MdClear} />
              Cancelar
              </MenuItemStyled>
              <HrDividerNoMargin />
            </>
          )
          : (
            ""
          )}
        <MenuItemStyled py={3} onClick={onOpen}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default TbodyMenu;
