/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuProductsAndTemplate from "components/Menu/MenuProductsAndTemplate";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import ProductCRUDContext from "context/ProductCRUDContext";
import { __SHOW_PLANTILLAS } from "request/productoInterno/__Plantillas";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { __CHANGE_STATUS } from "request/productoInterno/__ProductoInterno";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ConfigIconStatus } from "pages/Configuration/tables/components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyTemplate = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const { editData, setUpdate, update } = useContext(ProductCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  /* const handleState = async (info) => {
    try {
      const res = await __SHOW_PLANTILLAS(token, data.id);
      const informacion = {
        ...data,
        status_productive_process:
          res.data.status.data.status_productive_process === 1 ? 0 : 1,
      };
      Swal.fire({
        text: `Estás seguro que deseas ${
          data.status_productive_process === 1 ? "desactivar" : "activar"
        } la plantilla ${data.code_productive_process}?`,
        showCancelButton: true,
        confirmButtonColor: "#1f7ac3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          editData(informacion);
          setUpdate(!update);
        }
      });
    } catch (error) {}
  }; */

  const [statusProduct, setStatusproduct] = useState(
    data?.status_productive_process ?? 0
  );
  const [loadingIcons, setLoadingIcons] = useState(false);
  const handleState = async () => {
    const formdata = new FormData();
    formdata.append("id", data?.id);

    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __CHANGE_STATUS(
        environment.CHANGE_STATUS_PRODUCTIVE,
        token,
        formdata
      );

      if (res.data.status.code === 200) {
        console.log(res.data.status.data.status_productive_process);
        setStatusproduct(res?.data?.status?.data?.status_productive_process);
        openAlert(res?.data?.status?.message, "success");
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
    } finally {
      setLoadingIcons(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_productive_process}</Td>
      <Td className="text-center">{data?.description_productive_process}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={statusProduct === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          text={statusProduct}
        >
          <ConfigIconStatus loading={loadingIcons} status={statusProduct} />
        </TdState>
      </Tooltip>

      <Td className="text-center">
        <MenuProductsAndTemplate
          data={data}
          deleteField={false}
          state={false}
        />
      </Td>
    </Tr>
  );
};

export default TbodyTemplate;
