import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Badge
} from "@chakra-ui/react";

export const PopoverGeneric = ({
  title,
  children,
  closeOnBlur,
  closeHidden = false,
  customButton = null,
  ...props
}) => {
  return (
    <>
      <Popover closeOnBlur={closeOnBlur}>
        <PopoverTrigger>
          {customButton || (
            <Button
              leftIcon={props.leftIcon}
              colorScheme="blue"
              variant="solid"
            >
              {title}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent>
          {closeHidden
            ? null
            : (
              <>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader> {title}</PopoverHeader>
              </>
            )}

          <PopoverBody {...props}>{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
