import {
  formaterCant,
  __formaterCant
} from "components/Funciones/formaterCant";
import React, { useEffect, useState } from "react";
import { isObject } from "utils/type-check-utils";

const TableItems = ({
  items,
  AddValores,
  refundAdd,
  precision = 0,
  currentData
}) => {
  const queryString = window.location.pathname;
  const { data_total } = currentData;

  return (
    <div className="mt-4 table-responsive">
      <table className="table table-bordered ">
        <tbody>
          <tr>
            {isObject(data_total?.total_quantity) &&
              data_total?.total_quantity?.map((data, index) => {
                return (
                  <td key={index}>
                    {`Total cantidad ${data?.code_businessUnit ?? ""}`}
                    : <br />
                    {__formaterCant(data?.total ?? 0)}
                  </td>
                );
              })}
          </tr>
        </tbody>
      </table>

      <table className="table table-bordered ">
        <tbody>
          <tr>
            <td>
              Total Peso Bruto: <br />
              {__formaterCant(data_total?.total_gross_weight ?? 0)}
            </td>
            <td>
              Total Peso Neto: <br />
              {__formaterCant(data_total?.total_net_weight ?? 0)}
            </td>
            <td>
              Total FOB: <br />
              {__formaterCant(data_total?.total_us_fob_value ?? 0)}
            </td>
            <td>
              Total Bultos: <br />{" "}
              {__formaterCant(data_total?.total_number_packages ?? 0)}
            </td>
            <td>
              {queryString === "/CheckFormEntry" || queryString === "/FormShow"
                ? "Ingreso otros servicios:"
                : queryString === "/FormShowExit" ||
                  queryString === "/CheckForm"
                  ? "Salidas por servicios:"
                  : "Egresos por servicios:"}
              <br /> {__formaterCant(data_total?.total_service ?? 0)}
            </td>
          </tr>
          <tr>
            <td>
              Total Fletes: <br />{" "}
              {__formaterCant(data_total?.total_us_freight ?? 0)}
            </td>
            <td>
              Total Seguros: <br />
              {__formaterCant(data_total?.total_us_insurance ?? 0)}
            </td>
            <td>
              Total Otros Costos:
              <br /> {__formaterCant(data_total?.total_other_us_expenses ?? 0)}
            </td>
            <td>
              Total CIF:
              <br /> {__formaterCant(data_total?.total_cif_us_value ?? 0)}
            </td>
            <td>
              Total CIF COP:
              <br /> {__formaterCant(data_total?.total_cif_cop_value ?? 0)}
            </td>
          </tr>

          {(AddValores === 0 ||
            AddValores > 0 ||
            refundAdd === 0 ||
            refundAdd > 0) && (
            <tr>
              <td>
                Total valor agregado US$: <br />{" "}
                {formaterCant(data_total?.total_added_value_us ?? 0)}
              </td>
              <td>
                Total valor a reintegrar: <br />
                {formaterCant(data_total?.total_refund_value_us ?? 0)}
              </td>
              <td colSpan="3"></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableItems;
