import styled from 'styled-components/macro';

export const SideImgContainer = styled.div`
  position: relative;
  min-width: 200px;
  min-height: 690px;
  background: linear-gradient(to bottom, #1f7ac3, #105185);
  margin-right: 3rem;
`;

export const TermsAndConditionTitleContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TermsAndConditionContainer = styled.div`
  text-align: justify;
  justify-content: justify;
  padding: 1rem;
  height:40vh;
  overflow-y: auto;
`;

export const TermAndConditionImgHead = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
