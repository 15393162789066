import { Td, Tr } from '@chakra-ui/react'

import React from 'react'
import { NavLink } from 'react-router-dom'

const TbodyUserRisk = ({ data }) => {
  return (
    <Tr>
      <Td className='text-center'>{data?.nit_qualified}</Td>
      <Td className='text-center'>{data?.shortName_freeZone}</Td>
      <Td className='text-center'>
        {' '}
        <NavLink
          to={`/ShowRiskLevel?QualifiedId=${data?.qualifiedUser_id}`}
          className='ms-2'
        >
          <span className='formNumber'>{data?.name_qualified}</span>
        </NavLink>
      </Td>
      <Td className='text-center'>{data?.value_criteria_risk_definition}</Td>
      <Td className='text-center'>{data?.value_criteria_risk}</Td>
    </Tr>
  )
}

export default TbodyUserRisk
