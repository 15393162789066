import axios from 'axios';
import environment from 'constants/apiConst';

export const __Password_First_Time = (body, token) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      Authorization: token ? `Bearer ${token}` : ''
    },
    data: body
  };

  const res = axios(environment.PASSWORD_FIRST, options);

  return res;
};
