export const theadEstadisticas = [
  { name: "Usuario", order: "person_name" },
  { name: "Cantidad Aprobados", order: "form_revised" },
  { name: "Cantidad Devueltos", order: "form_approved" },
  { name: "Cantidad Revisados", order: "form_returned" }
];

export const theadEstadisticasItem = [
  { name: "Usuario", order: "person_name" },
  { name: "Items Aprobados", order: "amount_approved_item" },
  { name: "Items Devueltos", order: "amount_returned_items" },
  { name: "Items Revisados", order: "amount_revised_items" }
];

export const operaciones = [
  { name: "", order: "" },
  { name: "Tipo de Operación", order: "" },
  { name: "Codigo de Operación", order: "" },
  { name: "Cantidad de documentos", order: "" },
  { name: "Total FOB", order: "" },
  { name: "Total Peso Bruto", order: "" },
  { name: "Total Peso Neto", order: "" }
];

export const stadisticsOpeIO = [
  { name: "Fecha", order: null },
  { name: "Hora", order: null },
  { name: "Nit", order: null },
  { name: "Empresa", order: null },
  { name: "Tipo Documento", order: null },
  { name: "Número Documento", order: null },
  { name: "Número de Items", order: null },
  { name: "Número de Operaciones", order: null },
  { name: "Número de Bultos", order: null },
  { name: "Paso libre", order: null },
  { name: "Libre Disposicion", order: null },
  { name: "Estado", order: null },
  { name: "Operario", order: null },
];

export const theadEstadisticasVeto = [
  { name: "Vetado", order: "veto" },
  { name: "Cantidad", order: "amount" }
];

export const theadOpStatsGraph = [
  { name: "Tipo de Operación", order: "operation" },
  { name: "Cantidad de Operaciones", order: "total" },
  { name: "Cantidad de Items", order: "percentage" }
];

export const mes = [
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" }
];

export const year = [
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" }
];
