/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import TbodyActivityUser from "../tables/TbodyActivityUser";

import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { FormShowActivityUser } from "../FormShow/FormActivityUser";
import FormActivityUser from "../forms/FormActivityUser";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";

const formShowType = () => {
  return <FormShowActivityUser />;
};

const formType = () => {
  return <FormActivityUser />;
};

export const ActivityUses = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Actividad", order: "name_activity" },
    { name: "Descripción", order: "description_activity" },
    { name: "¿Activo?", order: "status_activity" },
    { name: "Acciones", order: null }
  ];

  useEffect(() => {
    setFormEdit(formType);
    setFormShow(formShowType);
    setRequestEdit(environment.ACTIVITY_USER_UPDATE);
    setRequestCreate(environment.ACTIVITY_USER_CREATE);
    setRequestDelete(environment.ACTIVITY_USER_DELETE);
  }, [
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete
  ]);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["configActivities"]})
  }, [update])

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configActivities", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.ACTIVITY_USER_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST"
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Actividad" />
      <TableOrder
        thead={Title}
        data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
        setData={setCurrentPage}
        loading={isLoading}
        tbodyData={TbodyActivityUser}
      />
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};
