import { IconButton, SimpleGrid, Tooltip } from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext, useEffect } from "react";
import { Title } from "styled/Title";

import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { searchObservation } from "./Functions/searchObservation";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";
import { CgAddR } from "react-icons/cg";
import { useGlobalContext } from "context/GlobalContext";

const FreeZoneToFreeZone = ({
  handleInputChange,
  values,
  setValues,
  formOperation,
  colorNotation = () => { },
  reviewData = {}
}) => {
  const { dataType, typeEntryCurrent, setTypeEntryCurrent, dataFormCurrent, currentItemLength, onOpenSelectAuthorizationItems } =
    useContext(FormCrudCRUDContext);

  const {
    num_commercial_invoice,
    num_dex_entryForm,
    dcl_customs_transit,
    transit_expiration_date,
    num_exit_form,
    integration_box_certificate,
    cabotage,
    cabotage_expiration_date,
    shipping_form_expiration_date
  } = values;

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };


  return (
    <div
      className="mx-1 shadow-none p-1 mb-5 rounded"
      style={{ background: "#f2f2f2" }}
    >
      {/* <div className="content-word-text"> */}
      <Title size={"18px"} weight={700} className="mb-2 ms-1">
        De Zona Franca a Zona Franca
      </Title>
      {/* </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem"
        }}
      >
        <RadioCheck
          set={setTypeEntryCurrent}
          value={typeEntryCurrent}
          item={dataType}
        />
      </div>

      <Tooltip
        hasArrow
        label={reviewData?.num_commercial_invoice?.comment}
        bg={colorNotation("num_commercial_invoice")}
        color="white"
      >
        <div>
          <LabeFormlStyled htmlFor="num_commercial_invoice">
            Nº Factura comercial{" "}
            <span style={{ color: "red" }}>
              {formOperation?.reentry_operation === 0 ? " *" : ""}
            </span>
          </LabeFormlStyled>
          <InputFormStyled
            type="text"
            name="num_commercial_invoice"
            ColorFake={colorNotation("num_commercial_invoice")}
            data-input-tag={"  Nº Factura comercial"}
            data-item-id={"Header"}
            data-old-Value={dataFormCurrent?.num_commercial_invoice}
            id="term"
            value={
              num_commercial_invoice === null ? "" : num_commercial_invoice
            }
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            maxLength={MaxLength.formEntry.formEntryNumber}
          />
        </div>
      </Tooltip>
      <SimpleGrid minChildWidth="200px" spacing="10px">
        <Tooltip
          hasArrow
          label={reviewData?.exit_auth_entryForm?.comment}
          bg={colorNotation("exit_auth_entryForm")}
          color="white"
        >
          <div>
            <LabeFormlStyled htmlFor="num_exit_form">
              Nº Autorización de Salida
            </LabeFormlStyled>
            <div className='d-flex justify-content-center align-items-center'>
              <InputFormStyled
                type="text"
                name="num_exit_form"
                id="num_exit_form"
                data-input-tag={"Nº Autorización de Salida"}
                data-item-id={"Header"}
                data-old-Value={
                  dataFormCurrent?.num_exit_form
                }
                ColorFake={() =>
                  colorNotation("num_exit_form")
                }
                value={num_exit_form}
                disabled={
                  dataFormCurrent?.num_exit_form &&
                  currentItemLength !== 0
                }
                onChange={handleInputChange}
              />
              <Tooltip
                hasArrow
                label={'Agregar items'}
                placement='top'
              >
                <IconButton
                  onClick={onOpenSelectAuthorizationItems}
                  h={'34px'}
                  marginBottom={'1rem'}
                  borderRadius={0}
                  borderTopRightRadius={'5px'}
                  borderBottomRightRadius={'5px'}
                  color={'#fff'}
                  _hover={{ backgroundColor: '#1f7ac3' }}
                  backgroundColor={'#1f7ac3'}
                  paddingBottom={'1px'}
                  _active={{ backgroundColor: '#1f7ac3' }}
                  outline={'none'}
                  disabled={!values?.num_exit_form}
                  icon={<CgAddR />}
                />
              </Tooltip>
            </div>
            {/* <InputFormStyled
              type="text"
              ColorFake={colorNotation("exit_auth_entryForm")}
              data-input-tag={"Aut. de salida"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.exit_auth_entryForm}
              name={"exit_auth_entryForm"}
              value={exit_auth_entryForm === null ? "" : exit_auth_entryForm}
              id="term"
              onChange={(e) => handleRegex(e, "Números", Regex?.numeros)}
              maxLength={
                MaxLength.formEntry.formEntryNumber.exit_auth_entryForm
              }
              
            /> */}
          </div>
        </Tooltip>

        <Tooltip
          hasArrow
          label={reviewData?.integration_box_certificate?.comment}
          bg={colorNotation("integration_box_certificate")}
          color="white"
        >
          <div>
            <LabeFormlStyled htmlFor="integration_box_certificate">
              Nº Autorización de C. Inte
            </LabeFormlStyled>
            <InputFormStyled
              data-input-tag={"Nº Autorización de C. Inte"}
              data-item-id={"Header"}
              data-old-Value={
                dataFormCurrent?.integration_box_certificate
              }
              ColorFake={() =>
                colorNotation("integration_box_certificate")
              }
              type="text"
              name="integration_box_certificate"
              id="integration_box_certificate"
              value={integration_box_certificate}
              onChange={handleInputChange}
            />
          </div>
        </Tooltip>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {typeEntryCurrent !== "Planilla de envío" && (
          <>
            <Tooltip
              hasArrow
              label={reviewData?.dcl_customs_transit?.comment}
              bg={colorNotation("dcl_customs_transit")}
              color="white"
            >
              <div>
                <LabeFormlStyled htmlFor="num_commercial_invoice">
                  {typeEntryCurrent === "DTA"
                    ? "Dcl. Tránsito aduanero"
                    : typeEntryCurrent === "Cabotaje"
                      ? typeEntryCurrent
                      : ""}
                </LabeFormlStyled>
                <InputFormStyled
                  type="text"
                  ColorFake={colorNotation("dcl_customs_transit")}
                  data-input-tag={
                    typeEntryCurrent === "DTA"
                      ? "Dcl. Tránsito aduanero"
                      : typeEntryCurrent === "Cabotaje"
                        ? typeEntryCurrent
                        : ""
                  }
                  data-item-id={"Header"}
                  data-old-Value={dataFormCurrent?.dcl_customs_transit}
                  name={
                    typeEntryCurrent === "DTA"
                      ? "dcl_customs_transit"
                      : typeEntryCurrent === "Cabotaje"
                        ? "cabotage"
                        : ""
                  }
                  value={
                    typeEntryCurrent === "DTA"
                      ? dcl_customs_transit
                      : typeEntryCurrent === "Cabotaje"
                        ? cabotage
                        : ""
                  }
                  // id="num_commercial_invoice"
                  id="term"
                  onChange={(e) =>
                    handleRegex(e, "all", Regex?.all)
                  }
                  maxLength={
                    MaxLength.formEntry.formEntryNumber.dcl_customs_transit
                  }
                />
              </div>
            </Tooltip>
            <Tooltip
              hasArrow
              label={reviewData?.transit_expiration_date?.comment}
              bg={colorNotation("transit_expiration_date")}
              color="white"
            >
              <div>
                <LabeFormlStyled htmlFor="num_commercial_invoice">
                  {typeEntryCurrent === "DTA"
                    ? "Fecha de vencimiento Tránsito"
                    : typeEntryCurrent === "Cabotaje"
                      ? `Fecha de vencimiento ${typeEntryCurrent}`
                      : ""}
                </LabeFormlStyled>
                <InputFormStyled
                  type="date"
                  ColorFake={colorNotation("transit_expiration_date")}
                  data-input-tag={
                    typeEntryCurrent === "DTA"
                      ? "Fecha de vencimiento Tránsito"
                      : typeEntryCurrent === "Cabotaje"
                        ? `Fecha de vencimiento ${typeEntryCurrent}`
                        : ""
                  }
                  data-item-id={"Header"}
                  data-old-Value={dataFormCurrent?.transit_expiration_date}
                  name={
                    typeEntryCurrent === "DTA"
                      ? "transit_expiration_date"
                      : typeEntryCurrent === "Cabotaje"
                        ? "cabotage_expiration_date"
                        : ""
                  }
                  value={
                    typeEntryCurrent === "DTA"
                      ? transit_expiration_date
                      : typeEntryCurrent === "Cabotaje"
                        ? cabotage_expiration_date
                        : ""
                  }
                  // id="num_commercial_invoice"
                  id="term"
                  onChange={handleInputChange}
                />
              </div>
            </Tooltip>
          </>
        )}

        {typeEntryCurrent === "Planilla de envío" && (
          <div>
            <LabeFormlStyled htmlFor="transit_expiration_date">
              Dcl. Tránsito aduanero
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              ColorFake={colorNotation("transit_expiration_date")}
              data-input-tag={"Dcl. Tránsito aduanero"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.transit_expiration_date}
              name={"transit_expiration_date"}
              value={transit_expiration_date}
              id="term"
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              maxLength={
                MaxLength.formEntry.formEntryNumber.dcl_customs_transit
              }
            />
          </div>
        )}

        {typeEntryCurrent === "Planilla de envío" && (
          <div>
            <LabeFormlStyled htmlFor="shipping_form_expiration_date">
              Fecha de vencimiento Tránsito
            </LabeFormlStyled>
            <InputFormStyled
              type="date"
              ColorFake={colorNotation("shipping_form_expiration_date")}
              data-input-tag={"Fecha de vencimiento Tránsito"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.shipping_form_expiration_date}
              name={"shipping_form_expiration_date"}
              value={shipping_form_expiration_date}
              // id="num_commercial_invoice"
              id="term"
              onChange={handleInputChange}
            />
          </div>
        )}

        <Tooltip
          hasArrow
          label={reviewData?.num_dex_entryForm?.comment}
          bg={colorNotation("num_dex_entryForm")}
          color="white"
        >
          <div>
            <LabeFormlStyled htmlFor="num_dex_entryForm">
              Nº DEX
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              ColorFake={colorNotation("num_dex_entryForm")}
              data-input-tag={"Nº DEX"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.num_dex_entryForm}
              name={"num_dex_entryForm"}
              value={num_dex_entryForm || ""}
              // id="num_commercial_invoice"
              id="term"
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              maxLength={MaxLength.formEntry.formEntryNumber.num_dex_entryForm}
            />
          </div>
        </Tooltip>
      </SimpleGrid>
    </div>
  );
};

export default FreeZoneToFreeZone;
