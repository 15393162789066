import React from 'react'
import * as PropTypes from 'prop-types'
import { MemberDropdownChakra } from './MemberDropdown'
import RemoveButtonGroup from './RemoveButtonGroup'
import { MenuItem, MenuList } from '@chakra-ui/react'
import { ButtonDropdownChakra } from './ButtonDropdown'
import { AiOutlinePlus } from 'react-icons/ai'

const DateRanges = [
  {
    title: 'Todo el Tiempo',
    value: undefined
  },
  {
    title: 'Hoy',
    value: 'Today'
  },
  {
    title: 'Ayer',
    value: 'Yesterday'
  },
  {
    title: 'Esta semana',
    value: 'This week'
  },
  {
    title: 'Este mes',
    value: 'This month'
  },
  {
    title: 'Este cuarto',
    value: 'This quarter'
  },
  {
    title: 'Este año',
    value: 'This year'
  },
  {
    title: 'Los últimos 7 días',
    value: 'Last 7 days'
  },
  {
    title: 'Los últimos 30 días',
    value: 'Last 30 days'
  },
  {
    title: 'La semana pasada',
    value: 'Last week'
  },
  {
    title: 'El mes pasado',
    value: 'Last month'
  },
  {
    title: 'Último cuarto',
    value: 'Last quarter'
  },
  {
    title: 'El año pasado',
    value: 'Last year'
  }
]
const TimeGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods
}) => {
  const granularityMenuChakra = (member, onClick) => (
    <MenuList>
      {member.granularities.length
        ? (
          member.granularities.map(m => (
            <MenuItem key={m.title} onClick={() => onClick(m)}>
              {m.title}
            </MenuItem>
          ))
        )
        : (
          <MenuList _disabled={true}>No se encontraron miembros</MenuList>
        )}
    </MenuList>
  )

  const dateRangeMenuChakra = onClick => (
    <MenuList>
      {DateRanges.map(m => (
        <MenuItem key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title}
        </MenuItem>
      ))}
    </MenuList>
  )

  return (
    <span>
      {members.map(m => [
        <RemoveButtonGroup
          onRemoveClick={() => updateMethods.remove(m)}
          key={`${m.dimension.name}-member`}
        >
          <MemberDropdownChakra
            onClick={updateWith =>
              updateMethods.update(m, {
                ...m,
                dimension: updateWith
              })
            }
            availableMembers={availableMembers}
          >
            {m.dimension.title}
          </MemberDropdownChakra>
        </RemoveButtonGroup>,
        <b
          key={`${m.dimension.name}-for`}
          style={{ paddingLeft: '5px', paddingRight: '5px' }}
        >
          {/*  POR */}
        </b>,

        <ButtonDropdownChakra
          overlay={dateRangeMenuChakra(dateRange =>
            updateMethods.update(m, {
              ...m,
              dateRange: dateRange.value
            })
          )}
          style={{
            marginLeft: 8,
            marginRight: 8
          }}
          key={`${m.dimension.name}-date-range`}
        >
          {m.dateRange || 'Todo el tiempo'}
        </ButtonDropdownChakra>,

        <b
          key={`${m.dimension.name}-by`}
          style={{ paddingLeft: '5px', paddingRight: '5px' }}
        >
          {' '}
          POR{' '}
        </b>,

        <ButtonDropdownChakra
          overlay={granularityMenuChakra(m.dimension, granularity =>
            updateMethods.update(m, {
              ...m,
              granularity: granularity.name
            })
          )}
          style={{
            marginLeft: 8
          }}
          key={`${m.dimension.name}-granularity`}
        >
          {m.dimension.granularities.find(g => g.name === m.granularity) &&
            m.dimension.granularities.find(g => g.name === m.granularity).title}
        </ButtonDropdownChakra>
      ])}
      {!members.length && (
        <MemberDropdownChakra
          onClick={member =>
            updateMethods.add({
              dimension: member,
              granularity: 'day'
            })
          }
          availableMembers={availableMembers}
          icon={<AiOutlinePlus />}
        >
          {addMemberName}
        </MemberDropdownChakra>
      )}
    </span>
  )
}
TimeGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired
}
export default TimeGroup
