import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { GiWeightScale } from "react-icons/gi";
import { MdReportOff } from "react-icons/md";
import { TiMediaFastForward } from "react-icons/ti";
import { BiCog, BiTimeFive } from "react-icons/bi";
// import { ModalGeneric } from "components/modal/ModalGeneric";
// import { FormAddWeighinhMachine } from "./FormAddWeighinhMachine";

export const HeaderWeighinhMachine = ({ onOpenAdd, onOpenConfig }) => {
  return (
    <>
      <Tooltip placement="top" hasArrow label="Conf. Báscula">
        <span>
          <ButtonHeaderModule
            onClick={() => {
              onOpenConfig();
            }}
            bgColor="#1F7AC3"
            className="me-1"
          >
            <IconGeneric color="#fff" as={BiCog} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      {/* <Tooltip placement="top" hasArrow label="Reportar inactividad">
        <span>
          <ButtonHeaderModule
            //   onClick={onOpen}
            bgColor="#F9A621"
            className="me-1"
          >
            <IconGeneric color="#fff" as={MdReportOff} />
          </ButtonHeaderModule>
        </span>
      </Tooltip> */}

      <Tooltip placement="top" hasArrow label="Agregar báscula">
        <span>
          <ButtonHeaderModule onClick={onOpenAdd} bgColor="#03C39A">
            <IconGeneric color="#fff" as={GiWeightScale} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  );
};
