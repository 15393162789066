import React from "react";
import * as PropTypes from "prop-types";
import { ButtonDropdownChakra } from "./ButtonDropdown";
import { MenuItem, MenuList } from "@chakra-ui/react";

// Can't be a Pure Component due to Dropdown lookups overlay component type to set appropriate styles
const memberMenuChakra = (onClick, availableMembers) => {
  return (
    <MenuList maxH={"400px"} overflow="auto">
      {availableMembers.length
        ? (
          availableMembers.map((m) => (
            <MenuItem key={m?.name} onClick={() => onClick(m)}>
              {m?.shortTitle}
            </MenuItem>
          ))
        )
        : (
          <MenuItem>No se encontraron miembros</MenuItem>
        )}
    </MenuList>
  );
};

export const MemberDropdownChakra = ({
  onClick,
  availableMembers,
  ...buttonProps
}) => (
  <ButtonDropdownChakra
    overlay={memberMenuChakra(onClick, availableMembers)}
    {...buttonProps}
  />
);

MemberDropdownChakra.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired
};

/* MemberDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired,
};
 */
