import { Box } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiSearch2Line } from "react-icons/ri";
import { useForm } from "hooks/useForm";
import PassCrudContext from "context/PassCrudContext";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { __ObtenerEntradas } from "request/Pases/__CrudPass";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { parseFloat } from "utils/parseFloat";
import { getFormData } from "redux/actions/dataFormActions";
import { Regex } from "utils/Regex";

export const AddOut = ({ pressSearch }) => {
  const { token } = useSelector((state) => state.auth.dataUser);
  const { decimal_values_precision } = useSelector(getFormData);
  const [openAlert] = AlertErrorAlert();
  const { idQualifiedUser } = useContext(PassCrudContext);
  const {
    dataItemDispachSelect,
    itemDIspacth,
    addItemDispacth,
    editItemDispatch,
    EditItemDispacth,
    requestObtenerItem
  } = useContext(PassCrudContext);
  const location = useLocation(); // navigate
  const { id = "" } = queryString.parse(location.search);

  const [values, handleInputChange, reset, setValues] = useForm({
    outputFormItem_id: "",
    quantity_go_out_product: "",
    quantity_go_out: "",
    item: "",
    code_outputForm: "",
    reference: "",
    code_product: "",
    code_unit_measurement: "",
    code_businessUnit: "",
    description: "",
    convertion_factor_product: "",
    output_form_item_description: "",
    subheading_description: ""
  });

  const {
    quantity_go_out_product,
    quantity_go_out,
    item,
    code_outputForm,
    reference,
    code_product,
    code_unit_measurement,
    code_businessUnit,
    description,
    convertion_factor_product,
    subheading_description,
    output_form_item_description
  } = values;

  useEffect(() => {
    setValues({
      ...dataItemDispachSelect,
      subheading_description:
        dataItemDispachSelect?.code_subheading ??
        dataItemDispachSelect?.subheading_description,

      output_form_item_description:
        dataItemDispachSelect?.output_form_item_description,
      description: dataItemDispachSelect?.description_product,
      quantity_go_out_product:
        dataItemDispachSelect?.quantity_dispatch_product ||
        dataItemDispachSelect?.quantity_go_out_product,
      quantity_go_out:
        dataItemDispachSelect?.quantity_dispatch ||
        dataItemDispachSelect?.quantity_go_out
    });
  }, [dataItemDispachSelect, setValues]);

  const hanldeSubmit = (e) => {
    e.preventDefault();
  };

  const sendData = () => {
    if (!code_outputForm) { return openAlert("El campo salida es requerido", "error"); }
    if (!item) return openAlert("El campo item es requerido", "error");
    if (!quantity_go_out_product) {
      return openAlert(
        "El campo cantidad de producto despachada es requerido",
        "error"
      );
    }
    if (!quantity_go_out) { return openAlert("El campo cantidad despachada es requerido", "error"); }

    if (editItemDispatch) {
      console.log("editItemDispatch", dataItemDispachSelect);
      const data = {
        id: dataItemDispachSelect.id,
        quantity_go_out_product: quantity_go_out_product?.toString()?.replaceAll(',', ''),
        quantity_go_out: quantity_go_out?.toString()?.replaceAll(',', '')
      };
      return EditItemDispacth(data);
    }
    const data = {
      ...values,
      dispatchPass_id: id
    };
    addItemDispacth(data);
  };

  const handleQuantityChange = (newValue, event) => {
    if (event?.event === undefined) return;

    const handleOperation = {
      quantity_go_out_product: {
        quantity_go_out: newValue?.floatValue * parseFloat(convertion_factor_product)
      },
      quantity_go_out: {
        quantity_go_out_product: newValue?.floatValue / parseFloat(convertion_factor_product)
      }
    }[event?.event?.target?.name || "quantity_go_out_product"]

    setValues((values) => ({
      ...values, [event.event?.target.name]: newValue?.floatValue, ...handleOperation
    }));
  };

  const habldeBlur = (e) => {
    const info = {
      qualifiedUser_id: idQualifiedUser,
      dispatchPass_id: id
    };

    console.log("e.target.name", e.target.name);
    if (e.target.name === "code_outputForm") {
      const sendDate = {
        ...info,
        code: code_outputForm
      };
      handleResData(sendDate, "salida");
    } else if (e.target.name === "item") {
      if (code_outputForm !== "") {
        const sendDate = {
          ...info,
          item,
          code: code_outputForm
        };
        handleResData(sendDate, "item");
      } else {
        openAlert("La salida no fue encontrada", "error");
      }
    }
  };

  const handleResData = async (data, flag) => {
    try {
      const res = await __ObtenerEntradas(token, requestObtenerItem, data);
      const resData = res?.data?.status;
      if (resData.data.data.length < 1 && flag === "salida") {
        openAlert("La salida no fue encontrada", "error");
        reset();
      } else if (resData.data.data.length < 1 && flag === "item") {
        openAlert("La salida no fue encontrada", "error");
        setValues({
          code_outputForm: data.code,
          quantity_go_out_product: "",
          quantity_go_out: "",
          item: "",
          code_product: "",
          code_unit_measurement: "",
          code_businessUnit: "",
          description: "",
          convertion_factor_product: ""
        });
      } else if (resData.data.data.length === 1 && flag === "item") {
        setValues(resData.data.data[0]);
      }
      // openAlert("La salida tiene que estar en estado APROBADO o AUTORIZADO","error")
    } catch (error) {
      console.log(error);
    }
  };

  // const handleBlurCant = (e) => {
  //   const nameVariable = e.target.name;
  //   const posibleResult = e.target.value;

  //   if (posibleResult) {
  //     setValues({
  //       ...values,
  //       [nameVariable]: formato.format(parseFloat(posibleResult)),
  //     });
  //   }
  // };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <form onSubmit={hanldeSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"code_outputForm"}
            onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
            onBlur={habldeBlur}
            value={code_outputForm || ""}
            tag={"Salida*"}
            size={22}
            desicion={false}
            maxLength={10}
          />
          <div style={{ marginTop: 20 }} onClick={pressSearch}>
            <IconGeneric color="#313131" as={RiSearch2Line} />
          </div>

          <LabelGroup
            name={"item"}
            onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
            onBlur={habldeBlur}
            value={item || ""}
            tag={"Item de salida*"}
            size={30}
            desicion={false}
            maxLength={10}
          />

          <LabelGroup
            name={"reference"}
            onChange={handleInputChange}
            value={reference || ""}
            tag={"Referencia comercial"}
            size={30}
            desicion={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"code_product"}
            onChange={handleInputChange}
            value={code_product}
            tag={"Código producto"}
            size={30}
            desicion={true}
          />
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"quantity_go_out_product"}
            onValueChange={handleQuantityChange}
            value={quantity_go_out_product}
            tag={"Cantidad producto despachada*"}
            size={30}
            desicion={false}
            maxLength={34}
          />

          <LabelGroup
            name={"code_unit_measurement"}
            onChange={handleInputChange}
            value={code_unit_measurement}
            tag={"Unidad producto"}
            size={30}
            desicion={true}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"description"}
            onChange={handleInputChange}
            value={description}
            tag={"Descripción del Item"}
            size={30}
            desicion={true}
          />
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"quantity_go_out"}
            onValueChange={handleQuantityChange}
            value={quantity_go_out}
            tag={"Cantidad despachada*"}
            size={30}
            desicion={false}
            maxLength={34}
          />
          <LabelGroup
            name={"code_businessUnit"}
            onChange={handleInputChange}
            value={code_businessUnit}
            tag={"Unidad comercial"}
            size={30}
            desicion={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 6
          }}
        >
          <LabelGroup
            name={"subheading_description"}
            onChange={handleInputChange}
            value={subheading_description}
            tag={"Descripción de subpartida"}
            size={100}
            desicion={true}
          />

          <LabelGroup
            name={"output_form_item_description"}
            onChange={handleInputChange}
            value={output_form_item_description}
            tag={"Descripción"}
            size={100}
            desicion={true}
          />
        </div>
        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={itemDIspacth}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={sendData}>
            {editItemDispatch ? "Editar" : "Agregar"}
          </ButtonStyled>
        </Box>
      </form>
    </>
  );
};
