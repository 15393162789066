import { Box, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";

export const TimeBox = ({ data, mb }) => {
  // console.log(data.date_load_disposition);

  return (
    <Box display={"flex"} gap="1rem" mb={mb}>
      <FormControl>
        <FormLabel>Fecha disposición de carga</FormLabel>
        <Input
          name="LoadingArrangementDate"
          type="date"
          value={data.date_load_disposition}
          // onChange={handleInputChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Hora de disposición carga</FormLabel>
        <Input
          name="LoadDisposalTime"
          type="time"
          value={data.time_load_disposition}
          // onChange={handleInputChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Modo de transporte</FormLabel>
        <Select placeholder={data.transport_mode}>
          {/* <option>{data.transport_mode}</option> */}
        </Select>
      </FormControl>
    </Box>
  );
};
