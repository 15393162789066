import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const CorrectFreeZone = ({
  data,
  dataCurrent,
  handleInputChangeCorrect,
  StateDisble
}) => {
  const {
    num_dex_entryFormCorrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    integration_box_certificate_corrected,
    dcl_customs_transit_corrected,
    transit_expiration_date_corrected
  } = data;
  const {
    num_dex,
    integration_box_certificate,
    dcl_customs_transit,
    transit_expiration_date,
    num_commercial_invoice,
    national_or_foreign,
    shipping_form,
    shipping_form_expiration_date,
    shipping_form_corrected,
    shipping_form_expiration_date_corrected,
    cabotage,
    cabotage_corrected,
    cabotage_expiration_date,
    cabotage_expiration_date_corrected
  } = dataCurrent;

  const {
    selectNE,
    setSelectNE,
    dataNational,
    typeEntry,
    setTypeEntry,
    dataType,
    typeEntryCurrent
  } =
    useContext(FormCrudCRUDContext);

  const handleColorDtaShippingCabotage = () => {
    if (
      (typeEntry === "DTA" && safelyConvertToString(dcl_customs_transit) !== safelyConvertToString(dcl_customs_transit_corrected)) ||
      (typeEntry === "Planilla de envío" && safelyConvertToString(shipping_form) !== safelyConvertToString(shipping_form_corrected)) ||
      (typeEntry === "Cabotaje" && safelyConvertToString(cabotage) !== safelyConvertToString(cabotage_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaDtaShippingCabotage = {
    DTA: {
      name: "dcl_customs_transit_corrected",
      value: dcl_customs_transit_corrected || "",
      tag: "Dcl. Tránsito aduanero"
    },
    "Planilla de envío": {
      name: "shipping_form_corrected",
      value: shipping_form_corrected || "",
      tag: "Planilla de envío"
    },
    Cabotaje: {
      name: "cabotage_corrected",
      value: cabotage_corrected || "",
      tag: "Cabotaje"
    }
  }[typeEntry || ""]

  /* Metadata for 'Fecha de vencimiento' depending on typeEntry */

  const handleColorExpirationDate = () => {
    if (
      (typeEntry === "DTA" && safelyConvertToString(transit_expiration_date) !== safelyConvertToString(transit_expiration_date_corrected)) ||
      (typeEntry === "Planilla de envío" && safelyConvertToString(shipping_form_expiration_date) !== safelyConvertToString(shipping_form_expiration_date_corrected)) ||
      (typeEntry === "Cabotaje" && safelyConvertToString(cabotage_expiration_date) !== safelyConvertToString(cabotage_expiration_date_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaExpirationDate = {
    DTA: {
      name: "transit_expiration_date_corrected",
      value: transit_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    },
    "Planilla de envío": {
      name: "shipping_form_expiration_date_corrected",
      value: shipping_form_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    },
    Cabotaje: {
      name: "cabotage_expiration_date_corrected",
      value: cabotage_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    }
  }[typeEntry || ""]


  useEffect(() => {
   console.log('typeEntry', typeEntry)
  }, [typeEntry])
  

  return (
    <>
      <RadioCheck
        set={setTypeEntry}
        value={typeEntry}
        item={dataType}
        diff={typeEntryCurrent !== typeEntry}
        disables={!!StateDisble}
      />
      <LabelGroup
        name={"num_commercial_invoice_corrected"}
        value={
          num_commercial_invoice_corrected || ""
        }
        tag={"Nº Factura comercial"}
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_commercial_invoice) !== safelyConvertToString(num_commercial_invoice_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"num_exit_form_corrected"}
        value={num_exit_form_corrected || ""}
        onChange={handleInputChangeCorrect}
        tag={"Aut. de salida"}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(integration_box_certificate) !== safelyConvertToString(integration_box_certificate_corrected) && "BorderCorrect"
        }
      />
      <LabelGroup
        name={"integration_box_certificate_corrected"}
        value={integration_box_certificate_corrected || ""}
        tag={"C. de integración"}
        size={100}
        desicion={!!StateDisble}
        onChange={handleInputChangeCorrect}
        changeValue={
          safelyConvertToString(integration_box_certificate) !== safelyConvertToString(integration_box_certificate_corrected) && "BorderCorrect"
        }
      />
      <LabelGroup
        name={
          metaDtaShippingCabotage?.name
        }
        value={
          metaDtaShippingCabotage?.value || ''
        }
        tag={
          metaDtaShippingCabotage?.tag
        }
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          handleColorDtaShippingCabotage()
        }
      />
      <LabelGroup
        name={
          metaExpirationDate?.name
        }
        value={
          metaExpirationDate?.value|| ''
        }
        tag={
          metaExpirationDate?.tag
        }
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        tipo="date"
        changeValue={
          handleColorExpirationDate()
        }
      />
      <LabelGroup
        name={"num_dex_entryFormCorrected"}
        onChange={handleInputChangeCorrect}
        value={num_dex_entryFormCorrected || ""}
        tag={"Nº DEX"}
        size={100}
        desicion={!!StateDisble}
        changeValue={safelyConvertToString(num_dex) !== safelyConvertToString(num_dex_entryFormCorrected) && "BorderCorrect"}
      />
      {/* <div className="mt-1">
        <RadioCheck
          set={setSelectNE}
          value={selectNE}
          item={dataNational}
          disables={StateDisble ? true : false}
          diff={national_or_foreign !== selectNE}
        />
      </div> */}
    </>
  );
};

