import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { AUTH_TYPES } from "redux/types/authTypes";

const PrivateRoutes = ({ children }) => {
  const location = useLocation();
  const { dispatch, authUser } = useContext(AuthContext);
  const { dataUser, isLogged } = useSelector((state) => state.auth);
  const url = new URL(window.location.href);

  useEffect(() => {
    if (!authUser.token) setDataContext();
  }, [authUser]);

  const setDataContext = () => {
    const action = {
      type: AUTH_TYPES.LOGIN,
      payload: dataUser,
    };
    dispatch(action);
  };

  return isLogged && dataUser.token ? (
    children
  ) : (
    <Navigate
      replace
      state={{
        origin: location,
        redirectUrl: `${url?.pathname}${url?.search}`,
      }}
      to="/auth"
    />
  );
  // return children;
};

export default PrivateRoutes;
