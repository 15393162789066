/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  arrayLeftfmmI,
  arrayLeftfmmO,
  arrayRigthUserfmmI,
  arrayRigthUserfmmO,
  cabeceraFormularios,
  cabeceraFormulariosCorregidos,
  TitleFormsCorrect,
  TitleFormsCorrectSalida,
  TitleFormsIntItems,
  TitleFormsOutputtItems,
} from "../ArrayHeaders/ArrayHeader";

import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import FormCrudCRUDContext from "context/FormCrudContex";
import environment from "../../../constants/apiConst";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useDispatch, useSelector } from "react-redux";
// import FormAdvancedSearch from "./AdvancedSearch/AdvancedSearch";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { SubTitle, Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { __PostFormData, __PostJsonData } from "request/Petitions/__Post";
import TBodyCorrectForm from "../Table/TBodyCorrectForm";
import TbodyFmmOCorrected from "../Table/TbodyFmmOCorrected";
import { handleLogout } from "components/Logout/FuncionLogout";
import { AuthContext } from "context/AuthContext";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import FormSearchAdvanceCorrect from "./busqueda avanzada/FormSearchAdvanceCorrect";
import { Flex, Spacer, useQuery } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import TBodyFormEntryItem from "../Table/TBodyFormEntryItem";
import TbodyFormOutputItems from "../Table/TbodyFormOutputItems";
import { __ExportExcel } from "request/Inventory/__InventoryCRUD";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { statePassOrDispacth } from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { useMutation, useQueries } from "@tanstack/react-query";
import GlobalContext from "context/GlobalContext";
import { useSearchUrl } from "hooks/useSearchUrl";
import { setSearch } from "redux/actions/searchActions";
import { ComponentAccordionIndividual } from "components/AccordionComponent/ComponentAccordionIndividual";
import isArrayNotEmpty, {
  isArray,
  isValid,
  safelyConvertToString,
} from "utils/type-check-utils";
import { FormAdvanced } from "pages/Pass-Form/View/BusquedaAvanzada/FormAdvanced";
import { SearchCorrection } from "../View/AdvancedSearch/SearchCorrection";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { parseFloat } from "utils/parseFloat";
import { cleanDeepObject } from "utils/cleanDeepObject";

export const ViewCorrectForm = () => {
  const { state: currentSearchSimple, clearParamsSearch } = useSearchUrl({
    getParams: ["search", "valueQualified"],
    nestedProp: "searchSimple",
  });
  const { currentSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const { search, valueQualified } = currentSearchSimple;

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [searcheAdvanced, setSearchAdvanced] = useState([]);
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [pageSearch, setPageSearch] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewDocumenOrItem, setviewDocumenOrItem] = useState(null);
  const [generalOrDetail, setGeneralOrDetail] = useState("Detallado");
  const dataGeneralOrDetail = ["Detallado", "General"];

  // Resultado Select Third
  const [operationT, seOperationT] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  // Radio Button unico O Fraccionado
  const [selectUF, setSelectUF] = useState(null);
  // Radio Button ascedente O descendente
  const [selectAD, setSelectAD] = useState("Ascendente");
  const [refund, setRefund] = useState("");
  const [dispacth, setDispacth] = useState("");
  const [balance, setBalance] = useState("");
  const [reposition, setReposition] = useState("");
  const [openAlert] = AlertErrorAlert();
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const dispacthRedux = useDispatch();

  const {
    update,
    setRequestCopy,
    setRequestChangeStatusForm,
    dataToDesechar,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenRadicar,
    onOpenRadicar,
    onCloseRadicar,
    dataRadicar,
    changeStatusCorregido,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
  } = useContext(FormCrudCRUDContext);

  const urlApi =
    {
      "Corregido de ingreso": environment.CORRECT_FMMI_GET,
      "Corregido de salida": environment.CORRECT_FMMO_GET,
      "Ajuste saldo de ingreso": environment.CORRECT_FMMI_GET_BALANCE,
      "Ajuste saldo de Salida": environment.CORRECT_FMMO_GET_BALANCE,
    }[movement] || "";

  const searchApi =
    {
      "Corregido de ingreso": environment.SEARCH_SIMPLE_FORM_ENTRY_CORRECT,
      "Corregido de salida": environment.SEARCH_SIMPLE_FORM_EXIT_CORRECT,
      "Ajuste saldo de ingreso":
        environment.SEARCH_SIMPLE_FORM_ENTRY_CORRECT_BALANCE,
      "Ajuste saldo de Salida":
        environment.SEARCH_SIMPLE_FORM_EXIT_CORRECT_BALANCE,
    }[movement] || "";

  const searchApiAdvanced = {
    "Corregido de ingreso": environment.SEARCH_ADVANCED_FORM_ENTRY_CORRECT,
    "Corregido de salida": environment.SEARCH_ADVANCED_FORM_EXIT_CORRECT,
    "Ajuste saldo de ingreso":
      environment.SEARCH_ADVANCED_FORM_ENTRY_CORRECT_BALANCE,
    "Ajuste saldo de Salida":
      environment.SEARCH_ADVANCED_FORM_EXIT_CORRECT_BALANCE,
  };

  const [pageEditables, setPageEditables] = useState(1);
  const [pagePresentados, setPagePresentados] = useState(1);
  const [pageAutorizados, setPageAutorizados] = useState(1);

  const memoizedDataToSearch = useMemo(() => {
    const {
      typeSearch: _,
      countryBuy,
      countryOrigin,
      countryProcedency,
      datosBy,
      flag,
      operationT,
      sortBy,
      stateDispacthOrPass,
      ...rest
    } = currentSearch;

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && {
        qualifiedUser_id:
          movement === "Corregido de salida"
            ? valueQualified?.map(({ value }) => value)
            : [valueQualified?.value],
      }),
      ...rest,
    };
    dispacthRedux(setSearch(searchInfo));
    return searchInfo;
  }, [valueQualified, search, currentSearch]);

  const thead = {
    "Corregido de ingreso": TitleFormsCorrect,
    "Ajuste saldo de ingreso": TitleFormsCorrect,
    "Corregido de salida": TitleFormsCorrectSalida,
    "Ajuste saldo de Salida": TitleFormsCorrectSalida,
  }[movement];

  const tbody = {
    "Corregido de ingreso": TBodyCorrectForm,
    "Ajuste saldo de ingreso": TBodyCorrectForm,
    "Corregido de salida": TbodyFmmOCorrected,
    "Ajuste saldo de Salida": TbodyFmmOCorrected,
  }[movement];

  const requestMapping = [
    {
      key: "Editables",
      queryKey: [
        "pases",
        pageEditables,
        update,
        freeZone_id,
        memoizedDataToSearch,
        movement,
      ],
      page: pageEditables,
      setPage: setPageEditables,
      thead,
      tbody,
    },
    {
      key: "Presentados",
      queryKey: [
        "pases",
        pagePresentados,
        update,
        freeZone_id,
        memoizedDataToSearch,
        movement,
      ],
      page: pagePresentados,
      setPage: setPagePresentados,
      thead,
      tbody,
    },
    {
      key: "Autorizados",
      queryKey: [
        "pases",
        pageAutorizados,
        update,
        freeZone_id,
        memoizedDataToSearch,
        movement,
      ],
      page: pageAutorizados,
      setPage: setPageAutorizados,
      thead,
      tbody,
    },
  ];

  const { data: dataForms, pending } = useQueries({
    /* Acá se valida si no hay una búsqueda se hace la petición con requestMapping para tener todo separado, de lo contrario se hace la petición get normal*/
    queries:
      Object.entries(memoizedDataToSearch).length === 0
        ? requestMapping.map((item) => ({
            queryKey: item.queryKey,
            queryFn: () =>
              requestData({
                Endpoint: urlApi,
                page: item.page,
                method: "POST",
              }),
          }))
        : [
            {
              queryKey: [
                "dataCorrectionsSearch",
                pageSearch,
                update,
                freeZone_id,
                memoizedDataToSearch,
                movement,
              ],
              queryFn: () =>
                requestData({
                  Endpoint: searchApi,
                  page: pageSearch,
                  method: "POST",
                  data: memoizedDataToSearch,
                }),
            },
          ],
    /* Hay dos casos, el primero es cuando hay búsqueda, que se apunta a un endpoint diferente y además, este trae solo un resultado data=[{...Resultado de la Búsqueda}]. Por el contrario, cuando no hay búsqueda, este trae todos los resultados en un array de objetos [{...Editables}, {...Prestendados}, {...Autorizados}] los cuales se combinan en un solo objeto  {...Editables, ...Prestendados, ...Autorizados}*/
    combine: (results) => {
      if (Object.entries(memoizedDataToSearch).length !== 0) {
        const [data] = results.map((result) => result?.data?.data?.status);
        return {
          data,
          pending: results.some((result) => result.isPending),
        };
      }

      return {
        data: results.reduce((acc, result, index) => {
          acc[requestMapping[index].key] = {
            ...requestMapping[index],
            data: result.data?.data?.status?.data?.[index]?.data || [],
            maxPage: result.data?.data?.status?.data?.[index]?.last_page || 1,
            isLoading: result.isFetching,
          };
          return acc;
        }, {}),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  useEffect(() => {
    if (
      movement === "Corregido de ingreso" ||
      movement === "Ajuste saldo de ingreso"
    ) {
      setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMI_CORRECT);
    } else {
      setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMO_CORRECT);
    }
  }, [
    movement,
    dataToDesechar,
    setRequestChangeStatusForm,
    setRequestCopy,
    freeZone_id,
  ]);

  const { requestData } = useContext(GlobalContext);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const handleChange = (id) => {
    const infoEntryCorrect = {
      id,
      status_entryFormCorrected: "BORRADOR",
    };

    const infoOutputCorrect = {
      id,
      status_outputFormCorrected: "BORRADOR",
    };

    if (
      movement === "Corregido de ingreso" ||
      movement === "Ajuste saldo de ingreso"
    ) {
      changeStatusCorregido(infoEntryCorrect);
    } else {
      changeStatusCorregido(infoOutputCorrect);
    }
  };

  const handleChangeRecuperar = (id) => {
    const infoEntryCorrect = {
      id,
      status_entryFormCorrected: "BORRADOR",
      retrieved: "RECUPERADO",
    };

    const infoOutputCorrect = {
      id,
      status_outputFormCorrected: "BORRADOR",
      retrieved: "RECUPERADO",
    };

    if (
      movement === "Corregido de ingreso" ||
      movement === "Ajuste saldo de ingreso"
    ) {
      changeStatusCorregido(infoEntryCorrect);
    } else {
      changeStatusCorregido(infoOutputCorrect);
    }
  };

  const searchItems = async (valores) => {
    setLoading(true);

    try {
      const res = await __PostJsonData(
        movement === "Corregido de ingreso" ||
          movement === "Ajuste saldo de ingreso"
          ? environment.SEARCH_iTEMS_CORRECT_ENTRY
          : environment.SEARCH_iTEMS_CORRECT_OUTPUT,

        token,
        valores,
        pageSearch
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        openAlert(res.data.status.message, "error");
      } else {
        //  setCurrentPageTwo(res?.data?.status?.data?.data);
        //  setMaxPageSearch(res?.data?.status?.data?.last_page);
        setviewDocumenOrItem("items");
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const handleSubmit = (data, formulario) => {
    const statusEntry =
      movement === "Ingreso" &&
      isArray(data?.status) &&
      isArrayNotEmpty(data?.status);

    const statusOutput =
      movement === "Salida" &&
      isArray(data?.status) &&
      isArrayNotEmpty(data?.status);

    const fieldsCreate = {
      ...formulario,
      ...(statusEntry && { status_entryForm: "status_entryForm" }),
      ...(statusOutput && {
        status_outputForm: "status_outputForm",
      }),
    };

    const propertiesToExclude = ["status"];
    const fields = cleanDeepObject(fieldsCreate, {
      keepEmptyArrays: false, // No mantener arrays vacíos
      keepEmptyObjects: false, // No mantener objetos vacíos
      replaceWithEmptyString: false, // No reemplazar con strings vacíos
      excludeKeys: propertiesToExclude, // Mantener estas propiedades aunque estén vacías
    });

    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value,
      }),
      qualifiedUser_id: isArray(data?.qualifiedUser_id)
        ? data?.qualifiedUser_id?.map((item) => item?.value || item)
        : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
        ? data?.qualifiedUser_id?.value
        : data?.qualifiedUser_id || [],
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || [],
      }),
      ...(parseFloat(data?.type_export) !== 1 ? { fields } : {}),
    };

    const infoClean = cleanDeepObject(info, {
      keepEmptyArrays: false,
      keepEmptyObjects: false,
      replaceWithEmptyString: false,
    });

    exportColumnBy(infoClean);
  };

  const exportColumnBy = async (data) => {
    // FALTA CONDICIONAL PARA SALIDA
    const URL =
      {
        "Corregido de ingreso": environment.CORRECT_EXPORT_EXCEL_ENTRY,
        "Corregido de salida": environment.CORRECT_EXPORT_EXCEL_OUTPUT,
        /* "Ajuste saldo de ingreso": "",
      "Ajuste saldo de Salida": "", */
      }[movement] || "";

    try {
      const res = await __ExportExcel(token, data, URL);
      console.log({ code: res?.data?.status?.code });
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
        /* let url = res?.data;
        window.location.assign(url); */
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const exportDetail = {
    type_export: 1,
  };

  const calculateArrayExportNodes = () => {
    if (movement === "Corregido de ingreso") {
      return arrayLeftfmmI.concat(arrayRigthUserfmmI);
    }
    if (movement === "Corregido de salida") {
      return arrayLeftfmmO.concat(arrayRigthUserfmmO);
    }
    return [];
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
      >
        <RadioCheck
          set={setGeneralOrDetail}
          value={generalOrDetail}
          item={dataGeneralOrDetail}
        />
        {generalOrDetail && generalOrDetail === "Detallado" && (
          <ModalExportAdvance
            isOpen={isOpenExport}
            onOpen={onOpenExport}
            onClose={onCloseExport}
            title="Exportar documento"
            size="6xl"
            enableAdvance={true}
            nodeList={calculateArrayExportNodes()}
            //selectNodeList={fmmFormNodeInputFields}
            handleSubmit={handleSubmit}
          />
        )}
        <RadioCheck />

        {generalOrDetail && generalOrDetail !== "Detallado" && (
          <Flex className="my-3">
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseExport}>
              Cerrar
            </ButtonStyled>
            <Spacer />
            <ButtonStyled
              bgColor={"#03C39A"}
              type="submit"
              onClick={() => exportColumnBy(exportDetail)}
            >
              Exportar
            </ButtonStyled>
          </Flex>
        )}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRadicar}
        onOpen={onOpenRadicar}
        onClose={onCloseRadicar}
        title={`Radicar documento`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea radicar el formulario{" "}
          {dataRadicar?.code_entryFormCorrected ||
            dataRadicar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              Se realizarán cambios en el inventario
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRadicar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => handleChangeRecuperar(dataRadicar?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea recuperar el formulario{" "}
          {dataRecuperar?.code_entryFormCorrected ||
            dataRecuperar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              El documento regresara al estado borrador.
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRecuperar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => handleChange(dataRecuperar?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
      >
        <SearchCorrection onClose={onCloseSearch} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>
          {movement === "Corregido de ingreso"
            ? "Formulario corregidos de ingreso"
            : movement === "Corregido de salida"
            ? "Formulario corregidos de salida"
            : movement === "Ajuste saldo de Salida"
            ? "Ajuste saldo de salida"
            : movement === "Ajuste saldo de ingreso"
            ? "Ajuste saldo de ingreso"
            : ""}
        </Title>

        <SearchAdvancedModule
          onOpenSearch={onOpenSearch}
          changeViewSearch={Object.entries(memoizedDataToSearch)?.length !== 0}
          MultipleQualifiedUsers={
            movement === "Corregido de salida" ? "multi" : "first"
          }
          backSearch={backSearch}
          permisoSearch={
            movement === "Corregido de ingreso"
              ? "getEntryFormFilter"
              : "getOutputFormFilter"
          }
          allAccess={allAccess}
          myPermission={myPermission}
          movement={movement}
        />

        {movement === "Corregido de ingreso" ||
        movement === "Corregido de salida" ? (
          <ExportDetail onOpen={onOpenExport} />
        ) : (
          <div className="my-3">
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="d-flex float-end "></div>
              </div>
            </div>
          </div>
        )}

        {Object.entries(memoizedDataToSearch)?.length !== 0 ? (
          <div>
            <TableOrder
              thead={
                movement === "Corregido de ingreso" ||
                movement === "Ajuste saldo de ingreso"
                  ? TitleFormsCorrect
                  : (movement === "Corregido de salida" ||
                      movement === "Ajuste saldo de Salida") &&
                    !viewDocumenOrItem
                  ? TitleFormsCorrectSalida
                  : (movement === "Corregido de ingreso" ||
                      movement === "Ajuste saldo de ingreso") &&
                    viewDocumenOrItem === "items"
                  ? TitleFormsIntItems
                  : (movement === "Corregido de salida" ||
                      movement === "Ajuste saldo de Salida") &&
                    viewDocumenOrItem === "items"
                  ? TitleFormsOutputtItems
                  : ""
              }
              data={isValid(dataForms?.data?.data) ? dataForms?.data?.data : []}
              setData={() => {}}
              loading={pending}
              tbodyData={
                movement === "Corregido de ingreso" ||
                movement === "Ajuste saldo de ingreso"
                  ? TBodyCorrectForm
                  : (movement === "Corregido de salida" ||
                      movement === "Ajuste saldo de Salida") &&
                    !viewDocumenOrItem
                  ? TbodyFmmOCorrected
                  : (movement === "Corregido de ingreso" ||
                      movement === "Ajuste saldo de ingreso") &&
                    viewDocumenOrItem === "items"
                  ? TBodyFormEntryItem
                  : (movement === "Corregido de salida" ||
                      movement === "Ajuste saldo de Salida") &&
                    viewDocumenOrItem === "items"
                  ? TbodyFormOutputItems
                  : ""
              }
            />

            <Pagination
              page={pageSearch}
              setPage={setPageSearch}
              maxPage={dataForms?.data?.last_page}
              loading={pending}
            />
          </div>
        ) : (
          <>
            <ComponentAccordionIndividual
              data={dataForms}
              isLoading={pending}
            />
          </>
        )}
      </HeaderSearch>
    </>
  );
};
