import React, { useContext } from 'react'
import { Tooltip } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import {
  MdContentCopy,
  MdOutlineAccountBalanceWallet,
  MdCancel
} from 'react-icons/md'
import { HiOutlineDocumentSearch } from 'react-icons/hi'
import { BiTask } from 'react-icons/bi'
import { TiCancel } from 'react-icons/ti'
import FormCrudCRUDContext from 'context/FormCrudContex'

import MonitoringContext from 'context/MonitoringContext'
import { BsEyeFill, BsRecordCircle } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { FaWpforms } from 'react-icons/fa'
import { LuArrowRightToLine } from "react-icons/lu";
import { useStorageQuery } from 'hooks/useStorageQuery'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements, isValid } from 'utils/type-check-utils'
import { AuthContext } from 'context/AuthContext'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'

export const ShowBtnSutorizado = ({
  values,
  handlePresent,
  handleCopy,
  creardespacho,
  handleAnular,
  setFollowOrder
}) => {
  const { handleCorrection } = useContext(FormCrudCRUDContext)
  const navigate = useNavigate()
  const { admin, is_commerce, is_qualified_user } = useSelector(
    state => state.auth.dataUser
  )
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const { authUser } = useContext(AuthContext)

  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const Formtype = 1
  const data = values

  const isOperationInRange = ({ code_operation, temporary }) => {
    const balanceAdjustmentPermission = hasCommonElements(
      permissionList,
      balanceAdjustmentOutput
    )

    const isPermanentValidRange =
      code_operation >= 101 &&
      code_operation <= 299 &&
      temporary === 1 &&
      balanceAdjustmentPermission

    const isTemporaryValidRange =
      code_operation >= 300 &&
      code_operation <= 699 &&
      temporary !== 1 &&
      balanceAdjustmentPermission

    const isAdjustBalanceIsAllowed =
      balanceAdjustmentPermission &&
      !isPermanentValidRange &&
      !isTemporaryValidRange

    return (
      isPermanentValidRange || isTemporaryValidRange || isAdjustBalanceIsAllowed
    )
  }

  const { setData } = useStorageQuery('MovementExitSRT', null)

  const goToFormatSRT = () => {
    setData('Salida')
    navigate(`/FormatSRT?ExitNumber=${data?.id}&FormType=${'Salida'}`)
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { Outputform } = localPermissions
  const {
    changeStatus: changeStatusOutputForm,
    copy: copyOutputForm,
    balanceAdjustment: balanceAdjustmentOutput
  } = Outputform

  const createEntryTemporaryForm = async () => {
    const body = {
      output_form_id: data?.id,
      operation_id: data?.operation_id,
      third_partie_id: data?.thirdPartie_id,
    }
    const res = await __Post(environment.CREATE_ENTRY_TEMPORARY_FORM, token, body)

    if (res?.data?.status?.code !== 200) {
      return openAlert(res?.data?.status?.message, 'error')
    }
    if (res?.data?.status?.data?.entry_form?.id) {
      return navigate(`/FormEntry?FormEntryNumber=${res?.data?.status?.data?.entry_form?.id}`)
    }
  }

  const ShowSettingsAssociated = () => {
    const { entryFormCorrected_id, outputFormCorrected_id } = data || {};

    if (isValid(entryFormCorrected_id)) {
      navigate(`/FormCorrection?ShowEntry=${entryFormCorrected_id}`);
      return;
    }

    if (isValid(outputFormCorrected_id)) {
      navigate(`/FormCorrectionOutput?ShowOutput=${outputFormCorrected_id}`);
      return;
    }
  };

  return (
    <>
      {data?.final_balance_adjustment === 1 && (
        <Tooltip placement="top" hasArrow label="Ver ajuste asociado">
          <span>
            <ButtonHeaderModule
              onClick={ShowSettingsAssociated}
              bgColor="#1F7AC3"
            >
              <IconGeneric width="16px" className="text-white" as={BsEyeFill} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(is_commerce === 1 || admin === 1) &&
        !is_qualified_user &&
        (data?.code_operation === 408 ||
          data?.code_operation === 409 ||
          data?.code_operation === 309 ||
          data?.code_operation === 310) && (
          <Tooltip placement='top' hasArrow label='Formato SRT'>
            <span>
              <ButtonHeaderModule
                onClick={goToFormatSRT}
                bgColor='#191830'
                style={{ margin: '4px' }}
              >
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={FaWpforms}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(is_commerce === 1 || admin === 1) && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='#B8DB1A'
              onClick={onOpenCreateTrackingFmm}
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || admin === 1) && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='red'
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
              className='mx-1'
            >
              <IconGeneric
                as={MdCancel}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {isOperationInRange(data) && (
        <Tooltip placement='top' hasArrow label='Ajustar saldo'>
          <span>
            <ButtonHeaderModule
              bgColor='#F9A621'
              onClick={() => handleCorrection(values, 1)}
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdOutlineAccountBalanceWallet}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(hasCommonElements(permissionList, changeStatusOutputForm) ||
        authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Corregir'>
            <span>
              <ButtonHeaderModule
                onClick={() => handleCorrection(values)}
                className='mx-1'
                bgColor='#F9A621'
              >
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={HiOutlineDocumentSearch}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(hasCommonElements(permissionList, copyOutputForm) ||
        authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Copiar'>
            <span>
              <ButtonHeaderModule onClick={handleCopy} bgColor='#03C39A'>
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={MdContentCopy}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(hasCommonElements(permissionList, changeStatusOutputForm) ||
        authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Despachar'>
            <span>
              <ButtonHeaderModule
                onClick={creardespacho}
                className='mx-1'
                bgColor='#BABABA'
              >
                <IconGeneric width='16px' className='text-white' as={BiTask} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {!is_qualified_user &&
        (hasCommonElements(permissionList, changeStatusOutputForm) ||
          authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Anular'>
            <span>
              <ButtonHeaderModule bgColor='black' onClick={handleAnular}>
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={TiCancel}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {data?.reentry_operation === 1 && data?.validate_item_temporary === 1 &&
        (<Tooltip placement='top' hasArrow label='Crear Ingreso'>
          <span>
            <ButtonHeaderModule
              onClick={createEntryTemporaryForm}
              bgColor='#1F7AC3'
              className='mx-1'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={LuArrowRightToLine}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>)
      }
    </>
  )
}
