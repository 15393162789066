import { useCallback, useContext, useEffect, useState } from "react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import InputSelect from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import { SimpleGrid } from "@chakra-ui/react";
import { __DetinyNotPaginate } from "request/configuration/__Destiny";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { MaxLength } from "utils/MaxLength";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import queryEnv from "constants/queryConst";
import environment from "constants/apiConst";
import { useQuery } from "@tanstack/react-query";

const FormIntegration = ({ values, handleInputChange, setValues }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const [datauserQualified, setDataUserQuialified] = useState([]);
  const [dataDestiny, setDataDestiny] = useState([]);
  const { setUser_Qualified, setCode_destination } = useContext(GlobalContext);
  const { ciModuleDynamic } = useSelector((state) => state?.form) || {};

  const { setUserQualified, setDestiny, userQualified, destiny } = useContext(
    IntegracionBoxCrudContex
  );

  const { requestData } = useGlobalContext();
  const { data: dataUC, isLoading: loadingUC } = useQuery({
    queryKey: [queryEnv?.QUALIFIED_USERS],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_QUALIFIED_USERS_ALL,
      }),
  });

  useEffect(() => {
    if (dataUC?.data?.status?.code !== 200 && !dataUC?.data?.status?.data)
      return;
    const dataUser = dataUC?.data?.status?.data.map((items) => ({
      value: items?.id,
      label: items?.nit_qualified
        ? items?.nit_qualified + " - " + items?.name_qualified
        : "",
      decimal: items.decimal_values_precision,
    }));
    setDataUserQuialified(dataUser);
  }, [setDataUserQuialified, dataUC]);

  const getDestiny = useCallback(async () => {
    try {
      const res = await __DetinyNotPaginate(token);
      const dataDestiny = res?.data?.status?.data.map((items) => ({
        value: items?.id,
        label: items?.code + " " + items?.destiny,
        code: items?.code,
      }));
      setDataDestiny(dataDestiny);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getDestiny();
  }, [getDestiny]);

  useEffect(() => {
    setUser_Qualified(userQualified?.value);
    setCode_destination(destiny?.code);
  }, [userQualified, destiny]);

  return (
    <>
      <>
        <SimpleGrid minChildWidth="200px" spacing="10px">
          {authUser.is_qualified_user !== 1 && (
            <div>
              <InputSelect
                tag={"Empresa"}
                size={100}
                data={datauserQualified}
                set={setUserQualified}
                value={!userQualified?.value ? "" : userQualified}
                isLoading={loadingUC}
                isDisabled={true}
              />
            </div>
          )}

          <div>
            <InputSelect
              tag={
                ciModuleDynamic?.nameModul ===
                "Asociación de Materias primas e Insumos"
                  ? "Destino del formulario de asociación"
                  : "Tipo de cuadro"
              }
              size={100}
              data={dataDestiny}
              set={setDestiny}
              value={destiny}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <LabelGroup
              name={"observations"}
              value={values?.observations}
              onChange={handleInputChange}
              tag={"Observaciones"}
              size={100}
              desicion={false}
              maxLength={MaxLength.ci.ci.observations}
            />
          </div>
        </SimpleGrid>
      </>
    </>
  );
};

export default FormIntegration;
