import React, { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineFileSync } from "react-icons/ai";
import CustomsCrudContext from "context/CustomsCrudContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;
export const OptionsAutorizadoCustoms = ({ data, handlePresent }) => {
  const { setDataToEdit, onOpenCopy } = useContext(CustomsCrudContext);

  const handleCopy = () => {
    setDataToEdit(data);
    onOpenCopy();
  };

  return (
    <>
      <MenuItemStyled py={3} onClick={() => handlePresent("DEVUELTO")}>
        <IconMenuRUD color={"red"} as={AiOutlineFileSync} />
        Devolver
      </MenuItemStyled>

      <HrDividerNoMargin />
      <MenuItemStyled onClick={handleCopy} py={3}>
        <IconMenuRUD color="#F9A621" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      <HrDividerNoMargin />
    </>
  );
};
