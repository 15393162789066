import { useModal } from "hooks/useModal";
import {
  __DeleteProduct,
  __EditCreateProduct,
  __EditProduct,
} from "request/productoInterno/__ProductoInterno";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { isArray, isString } from "utils/type-check-utils";

const { createContext, useState } = require("react");

const ProcessCrudContext = createContext();

export const ProcessCrudProvider = ({ children }) => {
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null); //
  const [formEdit, setFormEdit] = useState(null);
  const [update, setUpdate] = useState(true);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const editData = async (data, reset) => {
    try {
      const res = await __EditProduct(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseModal();
        if (reset) reset();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async () => {
    try {
      const res = await __DeleteProduct(requestDelete, token, dataToDelete.id);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showData = () => {
    console.log(dataToShow);
  };

  const createData = async (data, reset) => {
    try {
      const res = await __EditCreateProduct(requestCreate, token, data);
      if (res.data.status.code === 200 || res.data.status.code === 403) {
        openAlert(`Se agrego el Item`, "success");
        onCloseModal();
        if (reset) reset();
        setUpdate(!update);
      } else {
        console.log(res.data.status);
        const messages = res.data.status.message;
        if (isString(messages)) {
          openAlert(messages, "error");
        }

        isArray(messages) &&
          messages.map((m) => {
            openAlert(m, "error");
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
    }
  };

  const data = {
    editData,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    setDataToShow,
    showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
  };
  return (
    <ProcessCrudContext.Provider value={data}>
      {children}
    </ProcessCrudContext.Provider>
  );
};

export default ProcessCrudContext;
