import React, { useState } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import MenuFormCorrect from "components/Menu/MenuFormCorrect";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";

const TbodyFmmOCorrected = ({ data }) => {
  // const { setDataToShow } = useContext(FormCrudCRUDContext);
  // const navigate = useNavigate();

  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const checkIfItIsBlocked = async () => {
    if (is_qualified_user) return;

    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", "FMMSC");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false)
      setTimeout(() => setOpenMenu(true), 500)
    }
  }

  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          to={`/FormCorrectionOutput?ShowOutput=${data.id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_outputFormCorrected}</span>
        </NavLink>
      </Td>
      <Td className="text-center">
        <NavLink
          to={`/FormShowExit?ExitNumber=${data.outputForm_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_outputForm}</span>
        </NavLink>
      </Td>

      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.type_operation}</Td>
      <Td className="text-center">{data?.commercial_invoice_corrected}</Td>
      <Td className="text-center">{data?.user}</Td>
      <Td className="text-center">{data?.status_outputFormCorrected}</Td>

      {data?.status_outputFormCorrected === "PRESENTADO"
        ? (
          <Td className="text-center" onClick={() => checkIfItIsBlocked()}>
            {openMenu && <MenuFormCorrect data={data} />}
          </Td>
        )
        : (
          data?.status_outputFormCorrected !== "AUTORIZADO" ||
        data?.status_entryFormCorrected !== "AUTORIZADO"
            ? (
              <Td className="text-center">
                <MenuFormCorrect data={data} />
              </Td>
            )
            : (
              <Td className="text-center">N/A</Td>
            ))}
    </Tr>
  );
};

export default TbodyFmmOCorrected;
