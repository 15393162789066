import React, { useState } from 'react'
import { Td, Tr } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import MenuDispatchCorrecton from 'components/Menu/MenuDispatchCorrecton'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'

const TbodyDispatchCorrections = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);

  return (
    <Tr>
      <Td className='text-center'>{data?.nit_qualified_corrected}</Td>
      <Td className='text-center'>{data?.name_qualified_corrected}</Td>
      <Td className='text-center'>
        <NavLink
          to={`/DispathcCorrectionShow?Dispatch=${data.id}&Action=Show`}
          className='ms-2'
        >
          <span className='formNumber'>{data?.passForm_number}</span>
        </NavLink>
      </Td>

      <Td className='text-center'>{data?.status_dispatchPassCorrected}</Td>
      <Td className='text-center'>{data?.name_typeTransport_corrected}</Td>
      <Td className='text-center'>{data?.vehicle_plate_corrected}</Td>
      <Td className='text-center'>{data?.number_packages_corrected}</Td>
      <Td className='text-center'>{data?.countItem_corrected || 'N/A'}</Td>

      {data?.status_dispatchPassCorrected === "PRESENTADO"
        ? (
          <Td className="text-center">
            <MenuDispatchCorrecton data={data} StatusPropety={'status_dispatchPassCorrected'} />
          </Td>
        )
        : (
          data?.status_dispatchPassCorrected
            ? (
              <Td className='text-center'>
                <MenuDispatchCorrecton
                  data={data}
                  StatusPropety={'status_dispatchPassCorrected'}
                />
              </Td>
            )
            : (
              <Td className='text-center'>N/A</Td>
            ))}
    </Tr>
  )
}

export default TbodyDispatchCorrections
