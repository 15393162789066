import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __TradeAgreementAll = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };
  console.log("page", page);

  const res = await axios(
    `${env.TRADE_AGREEMENT_GET}?page=${page || 1}`,
    options
  );
  return res;
};

export const __TradeAgreementFil = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.TRADE_AGREEMENT_IMPORT}`, options);
  return res;
};
export const __BusineeUnitsFil = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.BUSINESS_UNITS_IMPORT}`, options);
  return res;
};

export const __ShowTradeAgreement = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TRADE_AGREEMENT_SHOW}/${id}`, options);
  return res;
};
