import { Box, Grid, Text } from '@chakra-ui/react'
import bro from '../../assets/bro.png'

import React from 'react'
import BoxRiskLevel from './BoxRiskLevel'
import { SubTitle } from 'styled/Title'

function RiskLevels ({ Detail = [] }) {
  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      m={6}
      p={6}
      borderRadius={'10px'}
      shadow={
        'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      }
    >
      {Detail?.criteria_risk_definition?.length > 0 ? (
        <>
          {' '}
          <Grid templateColumns='repeat(auto-fill, minmax(80px, 1fr))'>
            {Detail?.criteria_risk_definition &&
              Detail?.criteria_risk_definition.map(
                ({ criteria_name, value_criteria_risk_definition, NIVEL }) => {
                  return (
                    <BoxRiskLevel
                      key={criteria_name}
                      Level={NIVEL}
                      name={criteria_name}
                      Num={value_criteria_risk_definition}
                    />
                  )
                }
              )}
          </Grid>
          {/*   <Text color={'blue.400'} textDecorationLine={'underline'}>
            Definición de criterio
          </Text> */}
        </>
      ) : (
        <div>
          <div className='d-flex justify-content-center '>
            <img
              style={{ width: `${15}%` }}
              src={window.assetURL + bro}
              alt='no hay formulario'
            />
          </div>
          <SubTitle size={'15px'} className='text-center '>
            No encontrado
          </SubTitle>
        </div>
      )}
    </Box>
  )
}

export default RiskLevels
