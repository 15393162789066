import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import CustomsCrudContext from "context/CustomsCrudContext";
import MenuCustomsClearence from "components/Menu/MenuCustomsClearence";
import { setIdShowCustoms } from "redux/actions/customsClearanceActions";

const TbodyTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setDataToShow, setDataToEdit } = useContext(CustomsCrudContext);

  const handleShow = (data) => {
    dispatch(setIdShowCustoms(data?.id));
    setDataToShow(data);
    setDataToEdit(data);
    navigate(`/ShowCustomsClearance?Customs=${data?.id}`);
  };

  return (
    <Tr>
      <Td onClick={() => handleShow(data)} className="text-center">
        <NavLink
          to={`/ShowCustomsClearance?Customs=${data?.id}`}
          className="ms-2"
        >
          <span className="formNumber">
            {data?.customsClearanceForm_number}
          </span>
        </NavLink>
      </Td>
      <Td className="text-center">{data?.nickname ? data?.nickname : "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {data?.name_qualified ? data?.name_qualified : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.acceptance_number ?? "N/A"}
      </Td>
      <Td className="text-center">
        {data?.acceptance_date ? data?.acceptance_date : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.status_customsClearance ? data?.status_customsClearance : "N/A"}
      </Td>
      {
        data?.status_customsClearance === "AUTORIZADO" ? (
          <Td className="text-center">
            {data?.date_statusCustomClearance ? data?.date_statusCustomClearance : "N/A"}
          </Td>
        ) : (
          <Td className="text-center">
            {"N/A"}
          </Td>
        )}
      <Td className="text-center">
        <MenuCustomsClearence data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTable;
