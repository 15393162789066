import { Checkbox, Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import MonitoringContext from "context/MonitoringContext";
import { TheadTrackingItems } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { serviceInfo } from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __Get_Options_FollowOrder } from "request/Monitoring/__Get_Operations";
import { __PostForm, __PostJsonData } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import TBodyTableItems from "../Table/TBodyTableItems";
import TableModalMonitoring from "../Table/TableModalMonitoring";
import Pagination from "components/Pagination/Pagination";
import { useQueryClient } from "@tanstack/react-query";

export const FormCreateFmmTracking = ({ codeForm, formType }) => {
  const [service, setService] = useState("");
  const [followOrder, setFollowOrder] = useState("");
  const [arrayItems, setArrayItems] = useState([]);
  const [allItems, setAllItems] = useState(false);
  const [initialDescription, setInitialDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsFollowOrder, setOptionsFollowOrder] = useState([]);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [viewTable, setViewTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const { onCloseCreateTrackingFmm } = useContext(MonitoringContext);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const OnSubmitItemSearch = async () => {
    if (formType && followOrder?.value) {
      setLoading(true);
      try {
        const currentData = {
          code_form: codeForm || "",
          follow_order: followOrder?.value,
          tracking: true,
          status: ["PRESENTADO", "APROBADO", "AUTORIZADO", "DEVUELTO"]
        };
        console.log(formType);
        const res = await __PostJsonData(
          formType === 1
            ? environment.GET_EXIT_ITEMS
            : environment.GET_ENTRY_ITEMS,
          token,
          currentData,
          page
        );
        const data = res?.data?.status?.data;
        const code = res?.data?.status?.code;
        const message = res?.data?.status?.message;
        const dataTableItems = data?.data;

        if (code === 200) {
          setMaxPage(data?.last_page);
          setDataTable(dataTableItems);
          return openAlert(message, "success");
        }
        openAlert(message, "error");
      } catch (error) {
      } finally {
        setLoading(false);
        setViewTable(true);
      }
    }
  };

  useEffect(() => {
    if (followOrder) {
      OnSubmitItemSearch();
    }
  }, [followOrder, page]);

  useEffect(() => {
    if (!followOrder?.value) {
      setViewTable(false);
      setDataTable([]);
      setPage(1);
      setMaxPage(1);
    }
  }, [followOrder]);

  const setItems = (id) => setArrayItems([...arrayItems, id]);
  const deleteItems = (id) => {
    const idIndex = arrayItems.findIndex((item) => item === id);
    const copyArrayItems = [...arrayItems];
    copyArrayItems.splice(idIndex, 1);
    setArrayItems(copyArrayItems);
  };

  const handleSubmit = () => {
    if (!followOrder?.value) {
      openAlert("El gestor de seguimiento es requerido", "error");
    } else if (!arrayItems?.length && !allItems) {
      openAlert("Por favor seleccione uno o más items", "error");
    } else {
      const formData = new FormData();
      formData.append("form_type", formType || "");
      formData.append("service", service?.value ? service?.value : "");
      formData.append(
        "follow_order",
        followOrder?.value ? followOrder?.value : ""
      );

      formData.append("freeZone_id", freeZone_id);
      formData.append(
        "initial_description",
        initialDescription || ""
      );

      arrayItems?.length &&
        arrayItems?.map((item, index) => {
          formData.append(`item_id[${index}]`, item);
        });

      allItems && formData.append("totalData", true);
      formData.append("form_code", codeForm);
      sendData(formData);
    }
  };
  const queryClient = useQueryClient();

  const sendData = async (data) => {
    try {
      const res = await __PostForm(
        environment.MONITORING_SEND_FORM,
        token,
        data
      );
      const message = res?.data?.status?.message;
      const code = res?.data?.status?.code;
      if (code === 200) {
        onCloseCreateTrackingFmm();
         queryClient.invalidateQueries({ queryKey: ["fetchServerPage"]});
        openAlert(message, "success");
        return
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionsFollowOrder = async () => {
    try {
      const res = await __Get_Options_FollowOrder(token);
      const optionsFollowOrder = res?.data?.status?.data?.map((item) => ({
        value: item?.follow_order,
        label: item?.follow_order
      }));
      setOptionsFollowOrder(optionsFollowOrder);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOptionsFollowOrder();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "2rem"
        }}
      >
        <InputSelect
          size={45}
          name={"followOrder"}
          tag={"Gestora de seguimiento*"}
          data={optionsFollowOrder}
          set={setFollowOrder}
          value={followOrder}
        />
        <InputSelect
          size={45}
          name={"service"}
          tag={"Medio"}
          data={serviceInfo}
          set={setService}
          value={service}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          margin: "0 auto 2rem auto"
        }}
      >
        {viewTable && dataTable.length > 0 && (
          <>
            <TableModalMonitoring
              thead={TheadTrackingItems}
              data={dataTable}
              setData={setDataTable}
              loading={loading}
              tbodyData={TBodyTableItems}
              setItems={setItems}
              deleteItems={deleteItems}
              allItems={allItems}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
            <Checkbox
              size="sm"
              colorScheme="blue"
              value={allItems}
              onChange={() => setAllItems(!allItems)}
              isDisabled={
                arrayItems?.length > 0 ||
                dataTable?.some((dataTable) => dataTable?.currentStatus === 1)
              }
            >
              Todos los items
            </Checkbox>
          </>
        )}
        <label
          style={{
            fontSize: "0.75rem",
            color: "#454546"
          }}
        >
          Comentario
        </label>
        <textarea
          onChange={(e) => setInitialDescription(e.target.value)}
          value={initialDescription}
          name="description"
          cols="50"
          rows="3"
          style={{
            fontSize: "0.8rem",
            resize: "none",
            border: "1px solid #bababa",
            borderRadius: "5px",
            outline: "none",
            padding: "0 10px"
          }}
        ></textarea>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseCreateTrackingFmm}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => {
            handleSubmit();
            // onClose();
          }}
        >
          Crear
        </ButtonStyled>
      </Flex>
    </>
  );
};
