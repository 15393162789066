import { useModal } from "hooks/useModal";

const { createContext, useState } = require("react");

const WeighingContext = createContext();

export const WeighingProvider = ({ children }) => {
  const [isOpenOtherData, onOpenOtherData, onCloseOtherData] = useModal();
  const [isOpenViewContainer, onOpenViewContainer, onCloseViewContainer] =
    useModal();
  const [isOpenViewForm, onOpenViewForm, onCloseViewForm] = useModal();
  const [isOpenReweigh, onOpenReweigh, onCloseReweigh] = useModal();
  const [WeightCurrent, setWeightCurrent] = useState(null);
  const [IdReweigh, setIdReweigh] = useState([]);
  const [CurrentDataReweigh, setCurrentDataReweigh] = useState([]);
  const [dataDrivers, setDataDrivers] = useState(null);
  const [INCOMPLETE, setINCOMPLETEWITHOUTSELECT] = useState(null);
  const [dataContainer, setDataContainer] = useState(null);
  const [dataForm, setDataForm] = useState(null);

  const data = {
    isOpenOtherData,
    onOpenOtherData,
    onCloseOtherData,
    dataDrivers,
    setDataDrivers,
    INCOMPLETE,
    setINCOMPLETEWITHOUTSELECT,
    isOpenViewContainer,
    onOpenViewContainer,
    onCloseViewContainer,
    dataContainer,
    setDataContainer,
    isOpenViewForm,
    onOpenViewForm,
    onCloseViewForm,
    dataForm,
    setDataForm,
    isOpenReweigh,
    onOpenReweigh,
    onCloseReweigh,
    IdReweigh,
    setIdReweigh,
    CurrentDataReweigh,
    setCurrentDataReweigh,
    WeightCurrent,
    setWeightCurrent
  };

  return (
    <WeighingContext.Provider value={data}>{children}</WeighingContext.Provider>
  );
};

export default WeighingContext;
