import React, { useState } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import MenuFormCorrect from "components/Menu/MenuFormCorrect";
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const TBodyCorrectForm = ({ data }) => {
  // const { setDataToShow } = useContext(FormCrudCRUDContext);
  // const navigate = useNavigate();

  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const checkIfItIsBlocked = async () => {
    if (is_qualified_user) return;

    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", "FMMIC");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false)
      setTimeout(() => setOpenMenu(true), 500)
    }
  }

  return (
    <Tr>
      <Td className="text-center">
        <NavLink to={`/FormCorrection?ShowEntry=${data.id}`} className="ms-2">
          <span className="formNumber">{data?.code_entryFormCorrected}</span>
        </NavLink>
      </Td>
      <Td className="text-center">
        <NavLink
          to={`/FormShow?FormEntry=${data.entryForm_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_entryForm}</span>
        </NavLink>
      </Td>

      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.type_operation}</Td>
      <Td className="text-center">{data?.identification_num_thirdPartieCorrected}</Td>
      <Td className="text-center">{data?.business_nameCorrected}</Td>
      <Td className="text-center">{data?.num_commercial_invoice_corrected}</Td>
      <Td className="text-center">{data?.num_transport_document_corrected}</Td>
      <Td className="text-center">{data?.user}</Td>
      <Td className="text-center">{data?.status_entryFormCorrected}</Td>

      {data?.status_entryFormCorrected === "PRESENTADO"
        ? (
          <Td className="text-center" onClick={() => checkIfItIsBlocked()}>
            {openMenu && <MenuFormCorrect data={data} />}
          </Td>
        )
        : (
          data?.status_entryFormCorrected !== "AUTORIZADO"
            ? (
              <Td className="text-center">
                <MenuFormCorrect data={data}/>
              </Td>
            )
            : (
              <Td className="text-center">N/A</Td>
            ))}
    </Tr>
  );
};

export default TBodyCorrectForm;
