export const validateField = (
  field,
  fieldName,
  regex,
  minLength,
  maxLength,
  errorMessage,
  errors,
  form,
  IsErrorRegex
) => {
  const value = form[field].trim();
  if (!value) {
    errors[field] = `El campo "${fieldName}" no debe estar vacío.`;
    return true;
  }
  if (value.length < minLength) {
    errors[
      field
    ] = `El campo "${fieldName}" debe tener al menos ${minLength} caracteres.`;
    return true;
  }
  if (maxLength && value.length > maxLength) {
    errors[
      field
    ] = `El campo "${fieldName}" no puede tener más de ${maxLength} caracteres.`;
    return true;
  }
  if (regex && !regex.test(value)) {
    errors[field] = errorMessage;
    IsErrorRegex = true;
    return true;
  }
  return false;
};
