import { Flex, Spacer } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import ControlledSelect from 'components/InputSelect/ControlledSelect'
import environment from 'constants/apiConst'
import GlobalContext from 'context/GlobalContext'
import { useForm } from 'hooks/useForm'
import useTransformOptions from 'hooks/useTransformOptions '
import { LabelGroupTextArea } from 'pages/RefProductInter/LabelGroup'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { __GetDataReturnReason } from 'request/Pases/__CrudPass'
import { __Post } from 'request/Petitions/__Post'
import { ButtonStyled } from 'styled/Buttons.styled'

export const ReasonForReturnComponent = ({
  onClose,
  id,
  serviceReturn,
  PropertyStatus,
  PropertyReasonForReturn,
  callBack = null,
  propsBlockForm
}) => {
  const token = useSelector(state => state?.auth?.dataUser?.token)
  const { performStatusChange } = useContext(GlobalContext)
  const [openAlert] = AlertErrorAlert()

  const [values, handleInputChange] = useForm({
    id,
    [PropertyStatus]: 'DEVUELTO',
    [PropertyReasonForReturn]: '',
    typification_id: []
  })

  const {
    isLoading: isLoadingReturnReason,
    data: ReturnReasonData,
    isError: isErrorReturnReason,
    isFetching: isFetchingReturnReason,
    isSuccess: isSuccessReturnReason
  } = useQuery({
    queryKey: ['ReturnReason'],
    queryFn: () => __GetDataReturnReason(token),
    refetchOnWindowFocus: false
  })

  const optionsReturnReason = useTransformOptions(
    ReturnReasonData?.data?.status?.data || [],
    'name',
    'id',
    "descriptions"
  )

  const errorMessage = isErrorReturnReason
    ? 'Ha ocurrido un error al procesar'
    : ''
  const selectMessage = isSuccessReturnReason
    ? 'Por favor, selecciona la razón de devolución'
    : ''

  const messageToShow = errorMessage || selectMessage

  const unlockForm = () => {
    const body = new FormData()
    body.append('form_id', propsBlockForm?.form_id)
    body.append('type_of', propsBlockForm?.type_of)
    body.append('type', propsBlockForm?.type)
    const res = __Post(propsBlockForm?.endpoint, token, body)
  }

  const handleResponse = res => {
    if (res?.data?.status?.code === 400) {
      onClose()
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      openAlert(res?.data?.status?.message, 'success')
    }

    if (callBack) callBack(res)
  }

  const handleError = error => {
    onClose()
    openAlert('Ha ocurrido un error, por favor intente nuevamente', 'error')
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: performStatusChange,
    retry: 3,
    onSuccess: data => handleResponse(data),
    onError: () => handleError
  })

  const handleReturn = () => {
    unlockForm()
    mutate({
      data: values,
      Endpoint: serviceReturn,
      PropertyBody: 'sendJSONContentPOST'
    })
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2rem'
        }}
        className='mb-2 me-2'
      >
        <div style={{ width: '100%' }}>
          <ControlledSelect
            label={'Motivo'}
            placeholder={messageToShow}
            idStorageProperty='typification_id'
            handleChange={handleInputChange}
            options={optionsReturnReason}
            isDisabled={isErrorReturnReason}
            isLoading={isLoadingReturnReason || isFetchingReturnReason}
            isMulti={true}
          />
        </div>
      </div>

      {values?.typification_id?.length !== 0 && (
        <LabelGroupTextArea
          name={PropertyReasonForReturn}
          value={values?.[PropertyReasonForReturn] ?? ''}
          tag={'Descripción'}
          onChange={handleInputChange}
          width={100}
        />
      )}

      <Flex className='my-3'>
        <ButtonStyled bgColor={'#bababa'} onClick={onClose}>
          Cancelar
        </ButtonStyled>
        <Spacer />
        {values?.[PropertyReasonForReturn] !== '' && (
          <>
            <ButtonStyled
              bgColor={isLoading ? '#7dd081' : '#03C39A'}
              isLoading={isLoading}
              loadingText='Guardando'
              onClick={handleReturn}
            >
              Guardar
            </ButtonStyled>
          </>
        )}
      </Flex>
    </>
  )
}
