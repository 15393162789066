import axios from "axios";
import env from "../../constants/apiConst";

export const __GestionCo = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_GESTION}?page=${page || 1}`, options);
  return res;
};

export const __SHOW_GESTION = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_GESTION}/${id}`, options);
  return res;
};

export const __ChangePassword = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __Change_Status = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(env.CHANGESTATUS_GESTION, options);
  return res;
};
