import { useModal } from "hooks/useModal";
import { useSelector } from "react-redux";
import {
  __CreateBlocking,
  __ExportDocumentBocking
} from "request/Blocking/___BlockingCrud";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const { createContext, useState } = require("react");

const BlockingContext = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */

export const BlockingCRUDProvider = ({ children }) => {
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [isOpenHistory, onOpenHistory, onCloseHistory] = useModal();
  const [isOpenBlocking, onOpenBlocking, onCloseBlocking] = useModal();
  const [isOpenInfo, onOpenInfo, onCloseInfo] = useModal();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShowBlocking, setDataToShowBlocking] = useState(null);
  const [show, setShow] = useState(null);
  const [history, setHistory] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestCreate, setRequestCreate] = useState(null); //
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [update, setUpdate] = useState(true);
  const [openAlert] = AlertErrorAlert();
  const [actionsRes, setActionsRes] = useState(false);

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const editData = async (data) => {
    console.log(requestEdit);
    try {
      const res = await __CreateBlocking(requestEdit, token, data);
      console.log(res);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        onCloseBlocking();
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportColumnBy = async (data, onCloseExport) => {
    try {
      const res = await __ExportDocumentBocking(token, data);
      console.log(res);
      if (res.status === 200) {
        const url = res?.data;
        window.location.assign(url);
        onCloseExport();
      } else {
        // console.log(res);
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createData = async (data) => {
    try {
      const res = await __CreateBlocking(requestCreate, token, data);
      console.log(res.data.status);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseBlocking();
        openAlert(`Registro creado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");

          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }

    if (isOpenBlocking) {
      onCloseBlocking();
      setDataToEdit(null);

      return;
    }

    if (isOpenInfo) {
      onCloseInfo();
      setDataToEdit(null);
    }
  };

  const data = {
    history,
    setHistory,
    onCloseShow,
    editData,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    // deleteData,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShowBlocking,
    setDataToShowBlocking,
    // showData,
    setRequestEdit,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    isOpenBlocking,
    onOpenBlocking,
    onCloseBlocking,
    show,
    setShow,
    isOpenHistory,
    onOpenHistory,
    onCloseHistory,
    actionsRes,
    setActionsRes,
    isOpenInfo,
    onOpenInfo,
    onCloseInfo,
    exportColumnBy
  };

  return (
    <BlockingContext.Provider value={data}>{children}</BlockingContext.Provider>
  );
};

export default BlockingContext;
