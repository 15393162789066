/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Checkbox, Text, useDisclosure } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import React, { useContext, useEffect, useState } from "react";
import { __SHOW_COUNT_USER } from "request/configuration/__CountUser";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { MdRemoveRedEye } from "react-icons/md";
import { ListPermisosUser } from "components/modal/ListPermisosUser";
import { useSelector } from "react-redux";

export const FormAcessShow = () => {
  const initialForm = {
    nickname: "",
    email: "",
    username_efranco: "",
    lastname_person: "",
    name_person: "",
    qualified_user: "",
    session_limit: "",
    permission: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const { authUser } = useContext(AuthContext);
  const [sesionesLimi, setSesionesLimi] = useState(null);
  const [comerciante, setComercio] = useState(null);
  const [statusUser, setStatusUser] = useState(null);
  const [staff, setStaff] = useState(null);
  const [supera, setSupera] = useState(null);
  const [notiff, setNotiff] = useState(null);
  const [empresasRev, setEmpresasRev] = useState(null);
  const [zonasf, setZonasf] = useState(null);
  const [permisosADD, setPermisosADD] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToShow) {
      const initialpos = async () => {
        try {
          const res = await __SHOW_COUNT_USER(token, dataToShow.id);
          const respopnse = res.data.status.data;
          setForm(respopnse);
          setSesionesLimi(respopnse.session_limit);
          setComercio(respopnse.is_commerce);
          setStatusUser(respopnse.status);
          setStaff(respopnse.staff_status);
          setSupera(respopnse.rol.id);
          setNotiff(respopnse.all_notify);
          setZonasf(respopnse.user_free_zones);
          setEmpresasRev(respopnse.view_qualified_users);
          setPermisosADD(respopnse.permission);
          console.log(respopnse);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <ListPermisosUser
        isOpen={isOpen}
        onClose={onClose}
        informacion={permisosADD}
      />
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.nickname : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nickname</LabeFormlStyled>
                <InputFake>{form.nickname}</InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Nombre de usuario</LabeFormlStyled>
                <InputFake>
                  {form?.username_efranco === null
                    ? "---"
                    : form?.username_efranco}
                </InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>
                  {form?.name_person ?? "---"}
                </InputFake>
              </FormInputGroup>
              <Checkbox
                isDisabled
                color="#000"
                isChecked={sesionesLimi === 1}
              >
                ¿Limitar sesiones?
              </Checkbox>
              <br />
              <Checkbox
                isDisabled
                isChecked={statusUser === 1}
                color="#000"
              >
                Activo
              </Checkbox>
              <br />
              <Checkbox
                isDisabled
                isChecked={supera === 1}
                color="#000"
              >
                Superadmin
              </Checkbox>
              <br />

              {supera != 1
                ? (
                  <div>
                    <FormInputGroup>
                      <LabeFormlStyled>Licencia</LabeFormlStyled>
                      <InputFake>{form?.license_user?.name_license}</InputFake>
                    </FormInputGroup>
                    <FormInputGroup>
                      <LabeFormlStyled>Rol</LabeFormlStyled>
                      <InputFake>{form?.rol?.name_rol}</InputFake>
                    </FormInputGroup>
                  </div>
                )
                : (
                  ""
                )}
              <FormInputGroup>
                <LabeFormlStyled>Empresas a revisar</LabeFormlStyled>
                {empresasRev
                  ? empresasRev.map((items, index) => (
                    <InputFake key={index}>
                      {items?.nickname_qualified}
                    </InputFake>
                  ))
                  : ""}
              </FormInputGroup>
            </div>

            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Email</LabeFormlStyled>
                <InputFake>{form?.email ?? "---"}</InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Apellido</LabeFormlStyled>
                <InputFake>{form?.lastname_person ?? "---"}</InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Usuario calificado</LabeFormlStyled>
                <InputFake>
                  {form?.qualified_user?.name_qualified ?? "---"}
                </InputFake>
              </FormInputGroup>
              <Checkbox
                isDisabled
                isChecked={comerciante === 1}
                color="#000"
              >
                ¿Es comercio?
              </Checkbox>
              <br />
              <Checkbox
                isDisabled
                isChecked={staff === 1}
                color="#000"
              >
                StaffStatus
              </Checkbox>
              <br />
              <Checkbox
                isDisabled
                isChecked={notiff === 1}
                color="#000"
              >
                Todas las notificaciones
              </Checkbox>
              <br />

              <FormInputGroup>
                <LabeFormlStyled>Permisos asignados</LabeFormlStyled>
                <InputFake>
                  {permisosADD
                    ? (
                      permisosADD.length > 3
                        ? (
                          <div>
                            {permisosADD[0]?.description_permission}
                            <br />
                            {permisosADD[1]?.description_permission}
                            <br />
                            {permisosADD[2]?.description_permission}
                            <br />
                            <span
                              type="button"
                              id="changepasword"
                              className="float-end"
                              onClick={onOpen}
                            >
                          ver más <IconGeneric as={MdRemoveRedEye} />
                            </span>
                          </div>
                        )
                        : permisosADD
                          ? (
                            permisosADD.map((items, index) => items?.description_permission)
                          )
                          : (
                            ""
                          )
                    )
                    : (
                      ""
                    )}
                </InputFake>
              </FormInputGroup>

              <FormInputGroup>
                <LabeFormlStyled>Zona franca</LabeFormlStyled>
                {zonasf
                  ? zonasf.map((items, index) => (
                    <InputFake key={index}>{items?.name_freeZone}</InputFake>
                  ))
                  : ""}
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};
