import styled from "styled-components";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

export const TitleHeader = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => (props.size ? props.size : "24px")};
  font-weight: 500;
  color: #313131;
`;

export const SubTitleHeader = styled.h1`
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  color: #616161;
`;
