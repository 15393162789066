/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";


export const TableInfo = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryState([queryEnv?.INTEGRATION_BOX]);
  
  return (
    <TableContainer border={"1px solid #E0E0E0"} borderRadius={10}>
      <Table >
        <Thead>
          <Tr>
            <Th borderRight={"1px solid #E0E0E0"}></Th>
            <Th isNumeric>Peso en kilos</Th>
            <Th isNumeric>Total en US$</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}>Materia e Insumos</Td>
            <Td isNumeric>{data?.data?.total_supply_net_weight}</Td>
            <Td isNumeric>{data?.data?.total_supply_fob}</Td>
          </Tr>
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}>Otros costos</Td>
            <Td isNumeric>{data?.data?.total_other_net_weight}</Td>
            <Td isNumeric>{data?.data?.total_other_fob}</Td>
          </Tr>
          {/* Need a TR empty */}
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}></Td>
            <Td isNumeric></Td>
            <Td isNumeric></Td>
          </Tr>
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}>Total calculado</Td>
            <Td isNumeric>{data?.data?.total_net_weight}</Td>
            <Td isNumeric>{data?.data?.total_fob_value}</Td>
          </Tr>
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}>Total producto</Td>
            <Td isNumeric>{data?.data?.total_product_net_weight}</Td>
            <Td isNumeric>{data?.data?.total_product_fob}</Td>
          </Tr>
          <Tr>
            <Td borderRight={"1px solid #E0E0E0"}>Diferencia</Td>
            <Td isNumeric>{data?.data?.total_net_weight_difference}</Td>
            <Td isNumeric>{data?.data?.total_fob_value_difference}</Td>
          </Tr>
        </Tbody>

      </Table>
    </TableContainer>
  );
};
