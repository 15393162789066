/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormUserShow = () => {
  const initialForm = {
    name_qualified: "",
    name_activity: "",
    name_qualificationQualifiedUser: "",
    shortName_freeZone: "",
    name_city: "",
    name_freeZone: "",
    nit_qualified: "",
    code_ciiu: "",
    name_sector: "",
    nickname_qualified: "",
    email_qualified: "",
    phone_1_qualified: "",
    phone_2_qualified: "",
    address_qualified: "",
    fax_qualified: "",
    representative: "",
    manager: "",
    phone_manager: "",
    email_manager: "",
    collect: "",
    license_id: "",
    unlinked: "",
    generic_subheading_declaration: "",
    qualification_date: "",
    partial_processing_revision_amount: "",
    total_amount_incomeGenerating_activity: "",
    third_party_documents: "",
    third_party_items: "",
    terms_and_conditions: "",
    status_qualified: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToShow) {
      setForm(dataToShow);
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.name_qualified : ""}
      </Text>
      <form action="">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_qualified}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nit</LabeFormlStyled>
                <InputFake>{form.nit_qualified}</InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Codigo CIIU</LabeFormlStyled>
                <InputFake>{form.code_ciiu}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Actividad</LabeFormlStyled>
                <InputFake>{form.name_activity}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Clasificación</LabeFormlStyled>
                <InputFake>{form.name_qualificationQualifiedUser}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Zona Franca </LabeFormlStyled>
                <InputFake>{form.name_freeZone}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Ciudad</LabeFormlStyled>
                <InputFake>{form.name_city}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Correo</LabeFormlStyled>
                <InputFake>{form?.email_qualified}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Telefono 1</LabeFormlStyled>
                <InputFake>{form.phone_1_qualified}</InputFake>
              </FormInputGroup>
            </div>
          </div>
          {form.phone_2_qualified
            ? (
              <div className="row">
                <div className="col-md-6">
                  <FormInputGroup>
                    <LabeFormlStyled>Telefono 2</LabeFormlStyled>
                    <InputFake>{form?.phone_2_qualified}</InputFake>
                  </FormInputGroup>
                </div>
              </div>
            )
            : (
              ""
            )}

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Alias</LabeFormlStyled>
                <InputFake>{form?.nickname_qualified}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Dirección</LabeFormlStyled>
                <InputFake>{form.address_qualified}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Licencia</LabeFormlStyled>
                <InputFake>
                  {form?.license_id[0]?.name_license === undefined
                    ? "Sin Licencias activas"
                    : form?.license_id[0]?.name_license}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Exp. Licencia</LabeFormlStyled>
                <InputFake>
                  {form?.license_id[0]?.expireDate_license === undefined
                    ? "Sin Licencias activas"
                    : form?.license_id[0]?.expireDate_license}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Representane</LabeFormlStyled>
                <InputFake>
                  {form.representative === null ? "---" : form.representative}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Gerente</LabeFormlStyled>
                <InputFake>
                  {form?.manager === null ? "---" : form.manager}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Telefono Gerente</LabeFormlStyled>
                <InputFake>
                  {form.phone_manager === null ? "---" : form.phone_manager}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Correo Gerente</LabeFormlStyled>
                <InputFake>
                  {form?.email_manager === null ? "---" : form.email_manager}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Cobro</LabeFormlStyled>
                <InputFake>
                  {form.collect === null ? "---" : form.collect}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Desvinculado</LabeFormlStyled>
                <InputFake>
                  {form?.unlinked === null
                    ? "---"
                    : form.unlinked === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>
                  Declaración de subpartida genérica
                </LabeFormlStyled>
                <InputFake>
                  {form.generic_subheading_declaration === null
                    ? "---"
                    : form.generic_subheading_declaration === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Fecha de calificación</LabeFormlStyled>
                <InputFake>
                  {form?.qualification_date === null
                    ? "---"
                    : form.qualification_date}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>
                  Monto de revisión de procesamiento parcial
                </LabeFormlStyled>
                <InputFake>
                  {form.partial_processing_revision_amount === null
                    ? "---"
                    : form.partial_processing_revision_amount === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>
                  Monto total de ingresos actividad generadora
                </LabeFormlStyled>
                <InputFake>
                  {form?.total_amount_incomeGenerating_activity === null
                    ? "---"
                    : form.total_amount_incomeGenerating_activity === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Documentos de terceros</LabeFormlStyled>
                <InputFake>
                  {form.third_party_documents === null
                    ? "---"
                    : form.third_party_documents === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Artículos de terceros</LabeFormlStyled>
                <InputFake>
                  {form?.third_party_items === null
                    ? "---"
                    : form.third_party_items === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Estado calificado</LabeFormlStyled>
                <InputFake>
                  {form.status_qualified === null
                    ? "---"
                    : form.status_qualified === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Sector</LabeFormlStyled>
                <InputFake>{form.name_sector}</InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormUserShow;
