import { Td, Tr } from "@chakra-ui/react";
import React from "react";

export const TbodyForm = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.person_name}</Td>
      <Td className="text-center" isNumeric>{data?.form_approved}</Td>
      <Td className="text-center" isNumeric>{data?.form_returned}</Td>
      <Td className="text-center" isNumeric>{data?.form_revised}</Td>
    </Tr>
  );
};
