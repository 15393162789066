/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { AddOperation } from "components/InputSelect/InputSelect";
import { __AddDocument } from "request/configuration/__DocumentRequire";
import { __OperationList } from "request/configuration/__Operations";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const FormAddDocument = () => {
  const initialForm = {
    name_document: "",
    description_document: ""
  };

  const [form, setForm] = useState(initialForm);

  const { onCloseModal, dataToDocument, setUpdate, update } = useContext(
    ConfigurationCRUDContext
  );

  const [operation, setOperation] = useState([]);
  const [mendigo, setMendigo] = useState("");
  const location = useLocation();
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);

  const { q = "" } = queryString.parse(location.search);

  useEffect(() => {
    if (dataToDocument) {
      setForm(dataToDocument);
    } else {
      setForm(initialForm);
    }
  }, [dataToDocument]);

  useEffect(() => {
    const userC = async () => {
      const formData = new FormData();
      formData.append("search", q.toLowerCase());
      try {
        const res = await __OperationList(token);
        setOperation(res?.data?.status?.data);
      } catch (error) {}
    };
    userC();
  }, []);

  const handleAdd = async () => {
    const data = {
      operation_id: mendigo.value,
      document_id: dataToDocument.id
    };
    try {
      const res = await __AddDocument(token, data);
      if (res.data.status.code === 200) {
        openAlert("Documento asignado Exitosamente", "success");

        onCloseModal();
        setUpdate(!update);
      } else {
        openAlert(`Hubo un error`, "error");
      }
    } catch (error) {}
  };

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToDocument ? form.name_document : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_document}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Descripción</LabeFormlStyled>
                <InputFake>{form.description_document}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row ">
            <div>
              <AddOperation
                data={operation}
                tag={"Tipo de operación"}
                setMendigo={setMendigo}
                mendigo={mendigo}
              />
            </div>
          </div>
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          marginTop={"40px"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleAdd}>Asignar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormAddDocument;
