import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  arrayLeftTreasuryExtract,
  arrayLeftTreasuryExtractUser,
  arrayRigthTreasuryExtract,
  arrayRigthTreasuryExtractUser,
  TitleExtract
} from "../ArrayList/ArrayList";
import { Title } from "styled/Title";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { useModal } from "hooks/useModal";
import { useForm } from "hooks/useForm";
import TBodyExtract from "../Tables/TbodyExtract";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import FormSearchExtract from "../Forms/FormSearchExtract";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import TreasuryCrudContex from "context/TreasuryContex";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import TablesItems from "./view/TablesItems";
import { __PostJsonData } from "request/Petitions/__Post";
import { __GetExtractTreasury } from "request/Inventory/__InventoryCRUD";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useSearchUrl } from "hooks/useSearchUrl";

const Extracts = () => {
  const { cleanState } = useSearchUrl({ getParams: ["date1", "date2", "code", "document", "valueDocument"], nestedProp: "searchAdvance" });
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });

  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { exportColumnBy, setRequestExport, onCloseModal, isOpenObservacion } =
    useContext(TreasuryCrudContex);
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search || "",
    valueQualified: state?.valueQualified || [],
  });

  const { searchInformacion, valueQualified } = values;

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchAdvance", "searchSimple"] });
    reset();
    setSearchAdvanced([]);
    setChangeViewSearch(false);
  };

  const handleSubmit = (data, formulario) => {
    const info = {
      fields: formulario,
      data
    };
    exportColumnBy(info, onCloseExport);
  };

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.TREASURY_EXPORT_GET,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPageSearch(res?.data?.status?.data?.last_page);
        // console.log(res?.data?.status?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const searchAVanzada = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.TREASURY_EXPORT_GET,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPageSearch(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const getExtractData = useCallback(async () => {
    try {
      const res = await __GetExtractTreasury(token, page);
      const requestExtract = res?.data?.status?.data?.data;

      if (requestExtract !== undefined) {
        setCurrentPage(requestExtract);
        setMaxPage(res?.data?.status?.data?.last_page);
      } else {
        setCurrentPage([]);
        setMaxPage(1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token, page]);

  useEffect(() => {
    getExtractData();
  }, [getExtractData]);


  const dispatchRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified && valueQualified.length !== 0 && {
        qualifiedUser_id: valueQualified.value
      }),
      ...searcheAdvanced,
    }

    if (Object.values(data).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(data);
      dispatchRedux(setSearch(data));
      return;
    };

    dispatchRedux(clearSearch());
    setChangeViewSearch(false);
    getExtractData();

  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZone_id]);

  useEffect(() => {
    setRequestExport(environment.TREASURY_EXPORT_MODULE_EXPORT);
  }, [setRequestExport]);

  useEffect(() => {
    return async () => dispatchRedux(clearSearch());
  }, []);

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateNodeList = {
    0: arrayLeftTreasuryExtract.concat(arrayRigthTreasuryExtract),
    1: arrayLeftTreasuryExtractUser.concat(arrayRigthTreasuryExtractUser),
  }[authUser.is_qualified_user];

  return (
    <>
      <Title style={{ marginTop: 30 }}>Extractos</Title>
      <ModalGeneric
        isOpen={isOpenObservacion}
        onClose={onCloseModal}
        title={"Detalle"}
        size={"5xl"}
      >
        <TablesItems />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
        scrollBehavior="outside"
      >
        <FormSearchExtract
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={calculateNodeList}
        dafaulActive={["document_type_id"]}
        enableAdvance={true}
      />
      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
        size={"4xl"}
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={
            authUser.is_qualified_user === 0
              ? arrayLeftTreasuryExtract
              : arrayLeftTreasuryExtractUser
          }
          listRight={
            authUser.is_qualified_user === 0
              ? arrayRigthTreasuryExtract
              : arrayRigthTreasuryExtractUser
          }
          handleSubmit={handleSubmit}
          dafaulActive={["document_type_id"]}
        />
      </ModalGeneric> */}

      <div className="container p-4">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getExtracts"}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />
        <ExportDetail onOpen={onOpenExport} />
      </div>

      <HeaderSearch>
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div className="px-5">
                <div>
                  <TableOrder
                    thead={TitleExtract}
                    data={currentPageTwo}
                    setData={setCurrentPageTwo}
                    loading={loading}
                    tbodyData={TBodyExtract}
                  />

                  <Pagination
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    loading={loading}
                  />
                </div>
              </div>
            )
          )
          : (
            <div>
              <TableOrder
                thead={TitleExtract}
                data={currentPage}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TBodyExtract}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default Extracts;
