import React from "react";
import { Box } from "@chakra-ui/react";
import { H2 } from "pages/Pass-Form/stylePases";
import { Title } from "styled/Title";
import { safelyConvertToString } from "utils/type-check-utils";

export const CreateAndAutorizate = ({ values }) => {
  const { status } = values;

  const statusLabel = {
    BORRADOR: "Creado:",
    PRESENTADO: "Presentado:",
    AUTORIZADO: "Autorizado:",
    DEVUELTO: "Devuelto:",

  };

  return (
    <>
      <div
        className="row mt-4 ms-2"
        style={{
          background: "#f2f2f2",
          padding: "10px"
        }}
      >
        {status
          ? status.map((items, index) => (
            <div className="col col-sm-6 sol-md-3" key={index}>
              <H2 className="m-1 d-flex">
                <Title size="18px">
                  {statusLabel[safelyConvertToString(items.status_customsClearance)]}
                </Title>

                <span className="ms-1 text-mute">
                  {items.date_customsClearance + " " + items.nickname}
                </span>
              </H2>
            </div>
          ))
          : ""}
      </div>
    </>
  );
};
