import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import Select from "react-select";

const InputSelect2 = ({
  tag,
  name,
  data,
  set,
  value,
  size,
  className,
  error,
  isDisabled,
  labelDisable = false,
  ButtonSide = false,
  required = false,
  onChange = () => {},
  ...attrs
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : provided?.borderColor,
      borderTopRightRadius: ButtonSide && "0",
      borderBottomRightRadius: ButtonSide && "0",
      borderRight: ButtonSide && "0"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: error ? "red" : provided.color
    })
  };

  return (
    <>
      <div style={{ width: `${size}%` }}>
        {labelDisable
          ? (
            <></>
          )
          : (
            <LabelStyled error={error} htmlFor={name}>
              {tag}
              {required && (
                <span style={{ color: "red", fontSize: "12px" }}>*</span>
              )}
            </LabelStyled>
          )}
        <Select
          classNamePrefix="select"
          id={name}
          name={name}
          value={value?.value ? value : null}
          isClearable={true}
          options={data}
          size={size}
          className={className}
          placeholder="Seleccionar"
          error={error}
          styles={customStyles}
          isDisabled={isDisabled}
          required={required}
          onChange={set}
          {...attrs}
        />
      </div>
    </>
  );
};
export default InputSelect2;
