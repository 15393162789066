import { Box, Button, Checkbox, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import Pagination from 'components/Pagination/Pagination'
import environment from 'constants/apiConst'
import { useGlobalContext } from 'context/GlobalContext'
import React, { useContext, useEffect, useState } from 'react'
import { isArray } from 'utils/type-check-utils'
import bro from "../../../../assets/bro.png";
import { RxCross2 } from 'react-icons/rx'
import { IconGeneric } from 'styled/Icons.styled'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { Title } from 'styled/Title'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { ButtonStyled } from 'styled/Buttons.styled'
import { useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import queryEnv from 'constants/queryConst'


const theadReentry = [
  { name: "N° Item", order: null }, { name: "Referencia", order: null }, { name: "descripción", order: null }, { name: "Cantidad", order: null }, { name: "Unidad comercial", order: null }, { name: "Añadir", order: null },]

const theadReentrySelected = [
  { name: "N° Item", order: null }, { name: "Referencia", order: null }, { name: "descripción", order: null }, { name: "Cantidad", order: null }, { name: "Eliminar", order: null },
]

const AddItemsReentryOperation = ({ dataFetch, isOpen, onOpen, onClose }) => {
  const [params] = useSearchParams();
  const {
    update, setUpdate,
    /* Estos estados controlan los items de autorización de salida: */
    isSelectedAllItems, setIsSelectedAllItems, itemsSelected, setItemsSelected, onCloseSelectAuthorizationItems,
  } = useContext(FormCrudCRUDContext);
  const [page, setPage] = useState(1);
  const [pageItemsSelected, setPageItemsSelected] = useState(1);
  const [localSelectedItems, setLocalSelectedItems] = useState([]);
  const { requestData } = useGlobalContext();


  const { data: dataItemsReentry, isLoading } = useQuery({
    queryKey: ['itemsReentry', dataFetch, page],
    queryFn: () => requestData({
      Endpoint: environment.GET_ITEMS_TEMPORARY_ENTRY_FORM,
      method: 'POST',
      page,
      data: {
        ...dataFetch,
        length: 5
      },
    }),
  })

  useEffect(() => {
    if (dataItemsReentry?.data?.status?.data?.data) {
      const selectedItems = dataItemsReentry?.data?.status?.data?.data?.filter((item) => item?.aggregated);
      setLocalSelectedItems(selectedItems)
    }
  }, [dataItemsReentry?.data?.status?.data?.data])

  const setItems = ({ data: newItem }) => {
    setLocalSelectedItems((prev) => {
      if (prev.some((item) => item?.item === newItem?.item)) {
        return prev?.filter((item) => item?.item !== newItem?.item)
      }
      return [...prev, newItem].sort((a, b) => a?.item - b?.item)
    })
  };

  useEffect(() => {
    setPageItemsSelected(pageItemsSelected !== 1 && localSelectedItems.slice((pageItemsSelected - 1) * 5, pageItemsSelected * 5).length === 0 ? pageItemsSelected - 1 : pageItemsSelected)
  }, [localSelectedItems, pageItemsSelected])



  const queryClient = useQueryClient();
  const [openAlert] = AlertErrorAlert();


  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: data => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, 'error');
      }

      if (data?.data?.status?.code === 200) {
        setLocalSelectedItems([]);
        setIsSelectedAllItems(false);
        setUpdate(prev => !prev);
        onClose();
        queryClient.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] })
        openAlert(data?.data?.status?.message, 'success')
      }
    }
  })

  /* itemsSelected.forEach(i => formData.append('array_item[]', i.item) */
  const handleSubmit = () => {
    if (params?.get('FormEntryNumber')) {
      return Swal.fire({
        title: '¿Deseas agregar los items de la salida seleccionados?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar'
      }).then((result) => {
        if (result.isConfirmed) {
          mutate({
            data: {
              ...dataFetch,
              ...(isArray(localSelectedItems) && localSelectedItems?.length !== 0 && { array_item: localSelectedItems.filter(i => i?.aggregated !== 1).map(i => i.item) }),
              ...(isSelectedAllItems && { all_item: isSelectedAllItems }),
            },
            Endpoint: environment.ADD_TEMPORAL_ITEM_ONFORM,
            PropertyBody: 'POST',
          })
        }
      })
    }
    setItemsSelected(localSelectedItems);
    onClose();
  }

  return (

    <ModalGeneric
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      title={`Seleccionar Items`}
      size={'5xl'}
    >
      <HeaderSearch
        margen={`0 0 ${isArray(localSelectedItems) && localSelectedItems?.length === 0 ? '15px' : '0'} 0`}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          mb={'10px'}
        >
          <Title size={"18px"}>Items de la Salida</Title>
          <Box
            display={'flex'}
            gap={'0.1rem'}
            mr={'20px'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <h1 style={{
              fontSize: '14px',
              marginRight: '10px'
            }}>
              {"Seleccionar todos los items de la salida"}
            </h1>
            <Checkbox
              bgColor={'#fff'}
              borderRadius={'10px'}
              colorScheme={"white"}
              iconColor='blue.400'
              size='md'
              onChange={(e) => {
                e?.target?.checked && setLocalSelectedItems([]);
                setIsSelectedAllItems(e.target.checked);
              }}
            />
          </Box>
        </Box>
        <TableReentryOperation
          thead={theadReentry}
          data={dataItemsReentry?.data?.status?.data?.data || []}
          loading={isLoading}
          setSelectedItems={setItems}
          selectedItems={localSelectedItems}
          tbodyData={TBodyReentryOperationItems}
        >

        </TableReentryOperation>
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataItemsReentry?.data?.status?.data?.last_page || 1}
          loading={isLoading}
        />
      </HeaderSearch>
      {isArray(localSelectedItems) && localSelectedItems?.length !== 0 &&
        <HeaderSearch>
          <Title>Items seleccionados</Title>
          <TableReentryOperation
            thead={theadReentrySelected}
            data={localSelectedItems.slice((pageItemsSelected - 1) * 5, pageItemsSelected * 5) || []}
            setData={() => { }}
            loading={isLoading}
            setSelectedItems={setItems}
            tbodyData={TBodyReentryOperationItemsSelected}
            messageNoData="No hay items seleccionados"
          />
          <Pagination
            page={pageItemsSelected}
            setPage={setPageItemsSelected}
            maxPage={Math.ceil(localSelectedItems.length / 5) || 1}
          />
        </HeaderSearch>
      }

      <Box display={'flex'} justifyContent={'end'} mt={'20px'} gap={'10px'}>
        <ButtonStyled
          bgColor={'#bababa'}
          onClick={() => {
            onCloseSelectAuthorizationItems();
            setIsSelectedAllItems(false);
            setLocalSelectedItems([]);
            onClose();
          }}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
        >
          Aceptar
        </ButtonStyled>
      </Box>


    </ModalGeneric>
  )
}

export default AddItemsReentryOperation

const TBodyReentryOperationItems = ({ data, setSelectedItems, selectedItems }) => {
  const {
    /* Estos estados controlan los items de autorización de salida: */
    isSelectedAllItems
  } = useContext(FormCrudCRUDContext)

  return <Tr>
    <Td className="text-center"> {data?.item ? data?.item : ""}</Td>
    <Td className="text-center"> {data?.reference ? data?.reference : ""}</Td>
    <Td className="text-center"> {data?.description_product ? data?.description_product : ""}</Td>
    <Td className="text-center"> {data?.quantity ? data?.quantity : ""}</Td>
    <Td className="text-center"> {data?.code_businessUnit ? data?.code_businessUnit : ""}</Td>
    <Td className="text-center">
      <Checkbox
        bgColor={'#fff'}
        borderRadius={'10px'}
        colorScheme={"white"}
        isChecked={selectedItems.some((item) => item?.item === data?.item) || data?.aggregated}
        iconColor='blue.400'
        onChange={(event) => {
          setSelectedItems({ data, checked: event.target.checked })
        }}
        isDisabled={isSelectedAllItems || data?.aggregated}
      />
    </Td>
  </Tr>
}

const TBodyReentryOperationItemsSelected = ({ data, setSelectedItems }) => {
  return <Tr>
    <Td className="text-center"> {data?.item ? data?.item : ""}</Td>
    <Td className="text-center"> {data?.reference ? data?.reference : ""}</Td>
    <Td className="text-center"> {data?.description_product ? data?.description_product : ""}</Td>
    <Td className="text-center"> {data?.quantity ? data?.quantity : ""}</Td>
    <Td className="text-center" >
      <IconGeneric
        onClick={() => data?.aggregated !== 1 && setSelectedItems({ data })}
        color={data?.aggregated !== 1 ? 'red' : '#bababa'}
        cursor={data?.aggregated !== 1 ? 'pointer' : 'auto'}
        width="16px"
        as={RxCross2}
      />
    </Td>
  </Tr>
}

const TableReentryOperation = ({ thead, data, isLoading, selectedItems, setSelectedItems, tbodyData: TBodyData, messageNoData }) => {
  return (
    <Table
      size="md"
      variant="simple"
      className="table table-sm "
      overflowX={true}
      __css={{
        minH: "300px",
      }}>
      <Thead
        borderBottom={"2px solid #1f7ac3"}
      >
        {thead.map((th, index) => (
          <Th
            p={1}
            key={th?.name + "_" + index}
            className="text-center"
          >
            {th.name}
          </Th>
        ))}
      </Thead>
      <Tbody className={"table-hover"}>
        {
          (isLoading || !isArray(data) || (isArray(data) && data?.length === 0)) ? (
            <Tr>
              <Td colSpan={thead.length + 1}>
                {!messageNoData && <div className="d-flex justify-content-center ml-6">
                  <img
                    style={{ width: `${20}%` }}
                    src={window.assetURL + bro}
                    alt="no hay formulario"
                  />
                </div>}
                <h2 className="text-center my-2">{messageNoData || "Aún no hay registros."}</h2>
              </Td>
            </Tr>
          ) : (
            data?.map((item, index) => (
              <TBodyData
                data={item}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
              />
            ))
          )
        }
      </Tbody>
    </Table>
  )
}