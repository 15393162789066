/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import BodyFormExit from './BodyFormExit'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { HeaderForm } from 'components/HeaderForm/HeaderForm'
import { useSelector, useDispatch } from 'react-redux'
import { __ShowForm } from 'request/Forms/__ShowForm'
import environment from 'constants/apiConst'

import { setFormData, clearFormData } from 'redux/actions/dataFormActions'
import { useFormBlocker } from 'hooks/useFormBlocker'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import useStatusListener from 'hooks/useStatusListener'

const FormExit = () => {
  const location = useLocation()
  const token = useSelector(state => state.auth.dataUser.token)
  const [InfoUser, setInfoUser] = useState([])
  const { FormExitNumber = '' } = queryString.parse(location.search)
  const dispatchRedux = useDispatch()
  const [openAlert] = AlertErrorAlert()

  const EditOrCreate = useCallback(
    async id => {
      try {
        const res = await __ShowForm(token, id, environment.SHOW_FORM_EXIT)
        const response = res.data.status.data
        setInfoUser(response)
        dispatchRedux(
          setFormData({ ...response, precision: Number(response?.precision) })
        )
      } catch (error) {}
    },
    [token]
  )

  useEffect(() => {
    if (FormExitNumber !== '') EditOrCreate(FormExitNumber)
  }, [FormExitNumber, EditOrCreate])

  useEffect(() => {
    return async () => dispatchRedux(clearFormData())
  }, [])

  const navigate = useNavigate()

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: FormExitNumber || '',
    type_of: 'FMMS',
    userProvidedEndpoint: environment.FORMS_ON_EDIT
  })

  useEffect(() => {
    blockForm(1)
  }, [])

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, 'error')
      navigate(-1)
    }
  }, [formLockQueryResult])

  const navigateIfEligibleForUpdate = (
    flag,
    form_id,
    new_status,
    dataCurrent
  ) => {
    const shouldRefetch =
      flag === 'output_form' &&
      (new_status !== 'DEVUELTO' || new_status !== 'BORRADOR') &&
      form_id === InfoUser.id

    if (shouldRefetch) {
      navigate(`/FormShowExit?ExitNumber=${form_id || dataCurrent?.id}`)
      return
    }
  }
  useStatusListener({
    id: InfoUser.id,
    callBack: navigateIfEligibleForUpdate,
    dataCurrent: InfoUser
  })

  return (
    <>
      <HeaderForm
        status={''}
        numberForm={FormExitNumber && InfoUser.code_outputForm}
        UserQualified={FormExitNumber && InfoUser.name_qualified}
        Modulo={
          InfoUser.length !== 0 && FormExitNumber
            ? `Edición de formulario de Salida En Zonas Francas`
            : `Creación de formulario de Salida En Zonas Francas`
        }
      />

      <div>
        <BodyFormExit />
      </div>
    </>
  )
}

export default FormExit
