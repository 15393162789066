import { Flex, Spacer } from "@chakra-ui/react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import GlobalContext from "context/GlobalContext";
import { useForm } from "hooks/useForm";
import { ButtonItemsForm } from "pages/Pass-Form/stylePases";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useContext } from "react";

import { useSelector } from "react-redux";

export const SearchFmmO = ({ onClose, loading, handleSearch }) => {
  const [values, handleInputChange] = useForm({});
  const { valueQualified, selectItemForm, valueQualifiedForm } =
    useContext(FormCrudCRUDContext);
  const { authUser } = useContext(AuthContext);
  const { user_Qualified } = useContext(GlobalContext);
  const userQualifiedInf = useSelector(
    (state) => state.auth.dataUser.userQualified
  );
  const {
    code_form,
    code_operation,
    commercial_invoice,
    num_import_declaration,
    integration_certificate,
    date_final,
    date_initial
  } = values;

  /*
    if (user_Qualified && authUser.is_qualified_user !== 1) {
      formData.append("qualified_user_2_id", user_Qualified);
    }
    if (authUser.is_qualified_user) {
      formData.append("qualified_user_2_id", userQualifiedInf);
    } */
  const handleSeacrh = () => {
    const formData = new FormData();
    /*
    selectItemForm >= 700 && selectItemForm <= 799
      ? formData.append("qualified_user_2_id", valueQualifiedForm?.value)
      : formData.append("qualified_user_id", valueQualified); */
    const validateOperation = selectItemForm >= 700 && selectItemForm <= 799;
    if (
      user_Qualified &&
      authUser.is_qualified_user !== 1 &&
      validateOperation
    ) {
      formData.append("qualified_user_2_id", user_Qualified);
    }
    if (authUser.is_qualified_user && validateOperation) {
      formData.append("qualified_user_2_id", userQualifiedInf);
    }

    Object.entries(values).map(([key, valor]) => {
      if (valor) {
        formData.append(`${key}`, valor);
      }
    });
    handleSearch(formData);
  };

  return (
    <>
      {loading && <SpinnerComponent />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"code_form"}
          onChange={handleInputChange}
          value={code_form || ""}
          tag={"Nº Formulario"}
          size={30}
          desicion={false}
        />

        <LabelGroup
          name={"code_operation"}
          onChange={handleInputChange}
          value={code_operation || ""}
          tag={"Código operación"}
          size={30}
          maxLength={"3"}
        />

        <LabelGroup
          name={"commercial_invoice"}
          onChange={handleInputChange}
          value={commercial_invoice || ""}
          tag={"Factura comercial"}
          size={30}
          desicion={false}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          onChange={handleInputChange}
          value={date_initial || ""}
          tag={"Fecha inicio"}
          size={30}
          tipo={"date"}
          desicion={false}
        />

        <LabelGroup
          name={"date_final"}
          onChange={handleInputChange}
          value={date_final || ""}
          tag={"Fecha final"}
          size={30}
          tipo={"date"}
          desicion={false}
        />

        <LabelGroup
          name={"integration_certificate"}
          onChange={handleInputChange}
          value={integration_certificate || ""}
          tag={"Cert. Integración"}
          size={30}
          desicion={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"num_import_declaration"}
          onChange={handleInputChange}
          value={num_import_declaration || ""}
          tag={"Declaración de importación"}
          size={100}
          desicion={false}
        />
      </div>
      <Flex mt={6} className="mb-3">
        <ButtonItemsForm color="grisaso" onClick={onClose}>
          Cancelar
        </ButtonItemsForm>
        <Spacer />
        <ButtonItemsForm color="aqua" onClick={handleSeacrh}>
          Buscar
        </ButtonItemsForm>
      </Flex>
    </>
  );
};
