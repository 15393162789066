import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useModal } from "hooks/useModal";
import { useSelector } from "react-redux";
import { __EditCreateConfig } from "request/configuration/__CRUDConfig";
import { __Post } from "request/Petitions/__Post";

const { createContext, useState } = require("react");

const InventoryControlContext = createContext();

export const InventoryControlProvider = ({ children }) => {
  const [isOpenSearchItem, onOpenSearchItem, onCloseSearchItem] = useModal();
  const [isOpenChange, onOpenChange, onCloseChange] = useModal();
  const [isOpenChangeKardex, onOpenChangeKardex, onCloseChangeKardex] =
    useModal();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const [dataReference, setdataReference] = useState(null);
  const [dataSubheanding, setdataSubheanding] = useState(null);
  const [update, setupdate] = useState(true);
  const [showTableData, setShowTableData] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState([]);
  const [valueOperation, setValueOperation] = useState([]);
  const [valueTypeP, setValueTypeP] = useState([]);
  const [valueTypeO, setValueTypeO] = useState([]);
  const [valueDescription, setValueDescription] = useState([]);
  const [valueUserQ, setvalueUserQ] = useState(null);
  const [isOpenNewFilter, onOpenNewFilter, onCloseNewFilter] = useModal();
  const [requestCreate, setRequestCreate] = useState(null); //

  const updateSubpartida = async (endpoint, info, onClose) => {
    try {
      const res = await __Post(endpoint, token, info);
      const resultado = res?.data?.status;
      if (resultado?.code === 200) {
        setupdate(!update);
        onClose();
      } else if (resultado?.code !== 200) {
        openAlert(resultado?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createData = async (data) => {
    try {
      const res = await __EditCreateConfig(requestCreate, token, data);
      if (res.data.status.code === 200) {
        onCloseNewFilter();
        openAlert(`Registro creado Exitosamente`, "success");
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    dataReference,
    setdataReference,
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    showTableData,
    setShowTableData,
    searcheAdvanced,
    setSearchAdvanced,
    isOpenChange,
    onOpenChange,
    onCloseChange,
    dataSubheanding,
    setdataSubheanding,
    updateSubpartida,
    update,
    setupdate,
    isOpenChangeKardex,
    onOpenChangeKardex,
    onCloseChangeKardex,
    valueOperation,
    setValueOperation,
    valueTypeP,
    setValueTypeP,
    valueTypeO,
    setValueTypeO,
    valueDescription,
    setValueDescription,
    valueUserQ,
    setvalueUserQ,
    isOpenNewFilter,
    onOpenNewFilter,
    onCloseNewFilter,
    requestCreate,
    setRequestCreate,
    createData
  };

  return (
    <InventoryControlContext.Provider value={data}>
      {children}
    </InventoryControlContext.Provider>
  );
};

export default InventoryControlContext;
