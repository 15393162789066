import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Flex, FormControl, GridItem, SimpleGrid, Tooltip } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { BiFilter } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { AuthContext } from "context/AuthContext";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import {
  __AllQualifiedUSers,
  __GetAll
} from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import queryEnv from "constants/queryConst";
import environment from "constants/apiConst";
import { useSearchUrl } from "hooks/useSearchUrl";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import { isValid } from "utils/parseFloat";

/**
 * SearchAdvancedModule - Componente de input y boton para el filtro y el exportar
 * @param {Function} handleInputChange Funcion que escucha los cambios del input.
 * @param {Array} values Estado inicial del formulario.
 * @param {Function} onOpenSearch Funcion para abrir los modales.
 * @param {Boolean} changeViewSearch Boolean para evaluar si esta editando.
 * @param {Function} backSearch Funcion limpiar el filtro.
 * @param {Function} setValueQuialified Funcion para actualizar el estado del usuario calificado.
 * @param {Array} valueQualified Conocer el usuario y su id.
 * @return {Component} Retorna componente de boton para filtrar.
 */

export const SearchAdvancedModule = ({
  handleInputChange,
  values = {},
  onOpenSearch,
  changeViewSearch,
  backSearch = () => { },
  setValueQuialified = () => { },
  valueQualified,
  children,
  changeViewSearchSimple,
  permisoSearch = "",
  allAccess,
  myPermission,
  viewButtonFilter,
  viewFilterQualified,
  MultipleQualifiedUsers = "first",
  findHiddenInput = false,
  filterActive = false,
  setValues = () => { },
  disableSearch = false,
  movement = null,
  ...props
}) => {
  const { searchInformacion } = values;
  const { authUser } = useContext(AuthContext);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  const location = useLocation();

  const { state, handleSearchParams } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: true ? "searchSimple" : null })
  const { values: localValues, setValues: setLocalValues, handleInputChange: localValuesHandleInputChange } = useForm({
    initialState: {
      search: state?.search || searchInformacion || "",
    },
    returnType: RETURN_TYPES.OBJECT
  });

  const localValuesReset = () => {
    setLocalValues({ search: "" });
  }

  const { search } = localValues || {};

  const { requestData } = useGlobalContext();


  const { isLoading: isLoadingUC, data: dataUC } = useQuery({
    queryKey: [queryEnv.QUALIFIED_USERS, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
    })
  })

  useEffect(() => {
    if (!isLoadingUC && dataUC?.data?.status?.data);
    setValueQuialified && setValueQuialified([]);
  }, [dataUC, isLoadingUC])

  const consultin = () => {
    if (myPermission) return myPermission.includes(permisoSearch);
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    handleSearchParams({ name: "search", value: search.length > 0 ? search : null })
    setValues(prev => ({ ...prev, searchInformacion: search }));
  }

  const isShowFilter = (!viewButtonFilter || filterActive) && ((allAccess || filterActive) || (permisoSearch !== "" && consultin()) || (location.pathname === "/third-parties"))

  const isOnSearch = (changeViewSearch || changeViewSearchSimple);

  const handleChangeUCOnSearch = (e) => {
    const dataUser = e?.map((user) => ({ value: user?.value, label: user?.label }));
    handleSearchParams({ name: "valueQualified", value: dataUser?.length > 0 ? JSON.stringify(dataUser) : null })
    const setUC = (prev) => ({ ...prev, valueQualified: dataUser })
    /* setLocalValues(setUC); */
    setValues(setUC);
  }

  const handleChangeUCOnSearchSingle = (e) => {
    handleSearchParams({ name: "valueQualified", value: e?.value ? JSON.stringify({ value: e?.value, label: e?.label }) : null })
    const setUC = (prev) => ({ ...prev, valueQualified: e })
    /* setLocalValues(setUC); */
    setValues(setUC);
  }

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setValues(prev => ({ ...prev, searchInformacion: search, valueQualified: state?.valueQualified }));
    }
    setFirstRender(false);
  }, [search])

  useEffect(() => {
    if (disableSearch) {
      setLocalValues(prev => ({ ...prev, search: "" }));
      handleSearchParams({ name: "search", value: null })
    }
  }, [disableSearch])

  useEffect(() => {
    if (!state.search && !!movement) {
      localValuesReset();
    }
  }, [movement])

  const TypeSelector = (type) => {
    const componentMap = {
      first: (
        <InputSelect
          tag={"Usuario calificado"}
          name={"valueQualified"}
          data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
            value: item?.id,
            label: item?.nit_qualified + " - " + item?.name_qualified,
            ...item,
          })) : []}
          set={handleChangeUCOnSearchSingle}
          value={state?.valueQualified}
          isLoading={isLoadingUC}
          isDisabled={isLoadingUC}
        />
      ),
      multi: (
        <InputSelectMulti
          className=""
          tag={"Usuario calificado"}
          name={"valueQualified"}
          data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
            value: item?.id,
            label: item?.nit_qualified + " - " + item?.name_qualified,
            ...item,
          })) : []}
          set={handleChangeUCOnSearch}
          value={state?.valueQualified}
          isLoading={isLoadingUC}
          isDisabled={isLoadingUC}
        />
      )
    };

    return (
      componentMap[type] || (
        <InputSelect
          tag={"Usuario calificado"}
          name={"valueQualified"}
          data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
            value: item?.id,
            label: item?.nit_qualified + " - " + item?.name_qualified,
            ...item,
          })) : []}
          set={handleChangeUCOnSearchSingle}
          value={state?.valueQualified}
          isLoading={isLoadingUC}
          isDisabled={isLoadingUC}
        />
      )
    );
  };
  return (
    <>
      <Flex
        alignItems={"flex-end"}
        gap={1}
      >
        {(!authUser.is_qualified_user || authUser.is_commerce === 1) &&
          !viewFilterQualified && (
            <Box
              w={"100%"}
              minW={"150px"}
            >
              {TypeSelector(MultipleQualifiedUsers)}
            </Box>
          )}

        {
          !findHiddenInput && (
            <Box
              w={"100%"}
              minW={"150px"}
            >

              <form
                onSubmit={onSubmitSearch}
              >
                <LabelGroup
                  name={"search"}
                  onChange={(e) => {
                    localValuesHandleInputChange(e);
                  }}
                  value={search}
                  tag={"Buscar"}
                  size={100}
                  desicion={disableSearch}
                  placeholder={"Ingrese un registro"}
                />
              </form>
            </Box>
          )
        }

        {
          children && (
            <Box
              w={"100%"}
              minW={"150px"}
            >
              {children}
            </Box>
          )
        }

        {isShowFilter && (
          <Tooltip placement="top" hasArrow label="Filtro">
            <span>
              <ButtonHeaderModule
                onClick={onOpenSearch}
                bgColor="#1F7AC3"
              >
                <IconGeneric width="16px" color="#fff" as={BiFilter} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

        {isOnSearch && (
          <Tooltip placement="top" hasArrow label="Limpiar filtro">
            <span>
              <ButtonHeaderModule
                onClick={() => {
                  localValuesReset();
                  backSearch();
                }}
                bgColor="#F9A621"
              >
                <IconGeneric width="16px" color="#fff" as={AiOutlineClear} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      </Flex>
    </>
  );
};

export const SearchModule = ({
  setValueQuialified,
  valueQualified,
  tag,
  service,
  type = "Business"
}) => {
  const [userQualified, setUserQuialified] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);

  const getUserData = useCallback(
    async (env) => {
      try {
        const res = await __GetAll(env, token);
        let usuariosConValores;

        if (res?.data?.status?.data) {
          usuariosConValores = res.data.status.data.map(
            ({
              id,
              nit_qualified,
              name_qualified,
              name_freeZone,
              nickname
            }) => {
              switch (type) {
                case "Business":
                  return {
                    value: id,
                    label: `${nit_qualified} - ${name_qualified}`
                  };
                case "FreeTradeZone":
                  return { value: id, label: name_freeZone };
                default:
                  return { value: id, label: nickname };
              }
            }
          );

          setUserQuialified(usuariosConValores);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [token]
  );

  useEffect(() => {
    getUserData(service);
  }, [getUserData]);

  return (
    <FormControl>
      <InputSelect
        tag={tag}
        data={userQualified}
        set={setValueQuialified}
        value={valueQualified}
      />
    </FormControl>
  );
};
