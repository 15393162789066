import axios from 'axios';

export const __DeleteThirdParty = async (endpoint, token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};
