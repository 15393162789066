import React, { useContext } from "react";
import { IconMenuRUD } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { HrDividerNoMargin } from "styled/Line.styled";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import CustomsCrudContext from "context/CustomsCrudContext";
import { MdContentCopy, MdFactCheck } from "react-icons/md";
import { GiCardDiscard } from "react-icons/gi";
import { AuthContext } from "context/AuthContext";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;
export const OptionsBorradorCustoms = ({ data, handlePresent }) => {
  const { authUser } = useContext(AuthContext);

  const { setDataToEdit, onOpenCopy } = useContext(CustomsCrudContext);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()

  const handleEdit = async (e) => {
    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", 'DESADUANAMIENTO');
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(data);
      navigate(`/formCustomsClearance?Customs=${data.id}`);
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };

  const handleCopy = () => {
    setDataToEdit(data);
    onOpenCopy();
  };

  const handleClickPresent = async (status) => {
    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", 'DESADUANAMIENTO');
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      if (status === "AUTORIZADO") {
        handlePresent(status);
      }
      if (status === "DESECHADO") {
        handlePresent(status);
      }
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };

  return (
    <>
      <MenuItemStyled py={3} onClick={handleEdit}>
        <IconMenuRUD as={RiEdit2Fill} />
        Editar
      </MenuItemStyled>
      <HrDividerNoMargin />

      <MenuItemStyled onClick={handleCopy} py={3}>
        <IconMenuRUD color="#F9A621" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      <HrDividerNoMargin />

      <MenuItemStyled onClick={handleClickPresent} py={3}>
        <IconMenuRUD as={MdFactCheck} />
        Radicar
      </MenuItemStyled>
      <HrDividerNoMargin />
      {authUser.is_qualified_user === 0 && (
        <MenuItemStyled onClick={handleClickPresent} py={3}>
          <IconMenuRUD color="#d92525" as={GiCardDiscard} />
          Desechar
        </MenuItemStyled>
      )}
    </>
  );
};
