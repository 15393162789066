import React from "react";
import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";

/**
 * RadioBtnRedonder - Radio Boton de seleccion unica complementando el formulario.
 * @param {Function} setOnly Estado donde capturamos la seleccion del usuario.
 * @param {Object} options Objeto de las opciones a retornar para que el usuario pueda elegir.
 * @return {Component} Retorna seleccion de una opcion por el usuario.
 */

export const RadioBtnRedonder = ({
  setOnly,
  options,
  state,
  diff,
  ...rest
}) => {
  function RadioCard (props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          //   borderWidth="1px"
          borderRadius="xl"
          boxShadow="md"
          background="transparent"
          _checked={{
            bg: "blue.600",
            color: "white",
            borderColor: "blue.600"
          }}
          _focus={{
            boxShadow: "outline"
          }}
          px={1}
          py={1}
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: `${state}`,
    value: `${state}`,
    onChange: setOnly,
    ...rest
  });

  const groupOnly = getRootProps();

  return (
    <HStack {...groupOnly}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard isDisabled={rest?.isDisabled} key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
