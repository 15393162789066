import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { SimpleGrid } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import Pagination from "components/Pagination/Pagination";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import TableOrderHover from "components/Tables/TableOrderHover";
import { TheadFormSearchRefTable } from "./theadCustomsClearance";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TbodyFormSearchRefTable from "./Tbody/TbodyFormSearchRefTable";
import { __getItemsFormCustomsClearances } from "request/CustomsClearance.js/__CustomsClearence";
import CustomsCrudContext from "context/CustomsCrudContext";
import { useForm } from "hooks/useForm";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

/**
 *  FormSearchRef - Componente de formulario para buscar referencias en Desaduanamiento
 *
 * @component
 * @param {Function} onCloseModal Funcion para cerrar el modal.
 * @return {Component} Retorna componente de formulario para buscar referencias.
 */

const FormSearchRef = ({ onCloseModal, idUser }) => {
   const [openAlert] = AlertErrorAlert();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setItemId, codeSubheading } = useContext(CustomsCrudContext);

  const [values, handleInputChange, reset] = useForm({
    reference: "",
    operation: "",
    description: "",
    certificate: "",
    product_code: "",
    import_declaration: ""
  });

  const {
    reference,
    operation,
    description,
    certificate,
    product_code,
    import_declaration
  } = values;

  const handleSearch = async () => {
    setLoading(true);
  
    try {
      const res = await __getItemsFormCustomsClearances(
        token,
        codeSubheading,
        idUser,
        values
      );
  
      const code = res?.data?.status.code;
      
      if (code === 400) {
        setData([]);
        setMaxPage(1);
        return openAlert(res?.data?.status.message, 'error')
      }
  
      if (code === 200) {
        const resData = res?.data?.status?.data?.data;
        const lastPage = res?.data?.status?.data?.last_page;
        setData(resData);
        setItemId(resData[0]?.product_id);
        setMaxPage(lastPage);
        setShowTable(true);
        reset();
      }
    } catch (error) {
      console.error("Error during search:", error);
      return openAlert("No ha sido posible realizar la búsqueda. Inténtelo nuevamente.", 'error');
    } finally {
      setLoading(false);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <div>
      {loading && <SpinnerComponent />}

      {showTable ? (
        <div className="my-4">
          <ButtonStyled onClick={() => setShowTable(false)}>
            Buscar nuevamente
          </ButtonStyled>
          <TableOrderHover
            thead={TheadFormSearchRefTable}
            data={data}
            setData={setData}
            loading={loading}
            onCloseModal={onCloseModal}
            tbodyData={TbodyFormSearchRefTable}
            // imageNone
          />

          <Pagination
            page={page}
            setPage={setPage}
            maxPage={maxPage}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
            <div className="spac">
              <LabelGroup
                size={100}
                // desicion={true}
                name="reference"
                tag="Referencia"
                value={reference}
                onChange={(e) => handleRegex(e, 'all', Regex?.all)}
                maxLength={MaxLength.customClearance.formCustomsClearance.addItem.reference}
              />
            </div>
            <div className="spac">
              <LabelGroup
                size={100}
                desicion={true}
                tag="Código de producto"
                name="product_code "
                value={product_code}
                onChange={handleInputChange}
              />
            </div>
          </SimpleGrid>
          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
            <div className="spac">
              <LabelGroup
                size={100}
                // desicion={true}
                tag="Descripción"
                name="description"
                value={description}
                onChange={handleInputChange}
                maxLength={MaxLength.customClearance.formCustomsClearance.addItem.description_product}
              />
            </div>
            <div className="spac">
              <LabelGroup
                size={100}
                // desicion={true}
                tag="Certificado"
                name="certificate"
                value={certificate}
                onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
                maxLength={MaxLength.customClearance.formCustomsClearance.addItem.certificate}
              />
            </div>
          </SimpleGrid>
          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
            <div className="spac">
              <LabelGroup
                size={100}
                // desicion={true}
                name="import_declaration"
                tag="Dcl. Importación"
                value={import_declaration}
                onChange={(e) => handleRegex(e, 'all', Regex?.all)}
                maxLength={MaxLength.customClearance.formCustomsClearance.addItem.import_declaration}
              />
            </div>
            <div className="spac">
              <LabelGroup
                size={100}
                // desicion={true}
                tag="Operación"
                name="operation"
                value={operation}
                onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
                maxLength={MaxLength.customClearance.formCustomsClearance.addItem.operation}
              />
            </div>
          </SimpleGrid>
          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
            <div className="spac">
              <LabelGroup
                size={100}
                desicion={true}
                name="codeSubheading"
                tag="Subpartida"
                value={codeSubheading}
                onChange={handleInputChange}
                maxLength={MaxLength?.global?.code_subheading}
              />
            </div>
            <div className="spac" />
          </SimpleGrid>

          <div className="d-flex justify-content-end">
            <ButtonStyled onClick={handleSearch}>Buscar</ButtonStyled>
          </div>
        </>
      )}
    </div>
  );
};

FormSearchRef.propTypes = {
  onCloseModal: PropTypes.func
};

export default FormSearchRef;
