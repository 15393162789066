import isArrayNotEmpty, {
  isArray,
  isObject,
  isValid,
  isObjectNotEmpty,
} from "./type-check-utils";

/**
 * Options for cleaning deep objects
 * @typedef {Object} CleanOptions
 * @property {boolean} replaceWithEmptyString - Replace null/undefined with empty string
 * @property {boolean} keepEmptyArrays - Keep empty arrays in the result
 * @property {boolean} keepEmptyObjects - Keep empty objects in the result
 * @property {string[]} excludeKeys - Array of keys to exclude from cleaning
 * @property {any} replaceWith - Custom value to replace null/undefined with
 */

/**
 * Deeply cleans an object or array by removing null, undefined, and empty values
 *
 * @param {Object|Array} obj - Object or array to clean
 * @param {boolean|CleanOptions} options - Cleaning options or boolean for replaceWithEmptyString
 * @returns {Object|Array} Cleaned object or array
 * @throws {Error} If input is not a valid object or array
 */
export const cleanDeepObject = (obj, options = {}) => {
  // Handle legacy boolean parameter
  if (typeof options === "boolean") {
    options = { replaceWithEmptyString: options };
  }

  const {
    replaceWithEmptyString = false,
    keepEmptyArrays = false,
    keepEmptyObjects = false,
    excludeKeys = [],
    replaceWith = replaceWithEmptyString ? "" : undefined,
  } = options;

  // Validate input
  if (!isObject(obj)) {
    throw new Error("La entrada debe ser un objeto o array válido.");
  }

  // Initialize result based on input type
  const cleanObj = isArray(obj) ? [] : {};

  // Process each key in the object/array
  Object.keys(obj).forEach((key) => {
    // Skip excluded keys
    if (excludeKeys.includes(key)) {
      cleanObj[key] = obj[key];
      return;
    }

    const value = obj[key];

    // Handle nested objects and arrays
    if (isObject(value)) {
      const cleanedValue = cleanDeepObject(value, options);

      // Handle arrays
      if (isArray(cleanedValue)) {
        if (isArrayNotEmpty(cleanedValue) || keepEmptyArrays) {
          cleanObj[key] = cleanedValue;
        }
        return;
      }

      // Handle objects
      if (isObjectNotEmpty(cleanedValue) || keepEmptyObjects) {
        cleanObj[key] = cleanedValue;
      }
      return;
    }

    // Handle non-object values
    if (isValid(value) && value !== "") {
      cleanObj[key] = value;
    } else if (replaceWith !== undefined) {
      cleanObj[key] = replaceWith;
    }
  });

  return cleanObj;
};

/**
 * Creates a preconfigured cleaner function with default options
 *
 * @param {CleanOptions} defaultOptions - Default options for the cleaner
 * @returns {Function} Configured cleaner function
 */
export const createCleaner = (defaultOptions = {}) => {
  return (obj, options = {}) =>
    cleanDeepObject(obj, { ...defaultOptions, ...options });
};

// Preset cleaners for common use cases
export const strictCleaner = createCleaner({
  keepEmptyArrays: false,
  keepEmptyObjects: false,
  replaceWithEmptyString: false,
});

export const lenientCleaner = createCleaner({
  keepEmptyArrays: true,
  keepEmptyObjects: true,
  replaceWithEmptyString: true,
});

/**
 * Example usage:
 *
 * const data = {
 *   name: 'John',
 *   age: null,
 *   contacts: [],
 *   address: {
 *     street: undefined,
 *     city: ''
 *   },
 *   tags: ['', null, 'active']
 * };
 *
 * // Basic usage
 * cleanDeepObject(data);
 *
 * // With options
 * cleanDeepObject(data, {
 *   replaceWithEmptyString: true,
 *   keepEmptyArrays: true,
 *   excludeKeys: ['contacts']
 * });
 *
 * // Using preset cleaners
 * strictCleaner(data);
 * lenientCleaner(data);
 */
