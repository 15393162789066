import { Text, Box, Switch, Divider, Textarea, Center } from "@chakra-ui/react"; import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import GlobalContext from "context/GlobalContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { __CreateFormatSrt, __GetFormatSrt } from "request/Forms/__ShowForm";
import { ButtonStyled } from "styled/Buttons.styled";

const data = [
  {
    value: "NUEVO",
    label: "NUEVO"
  },
  {
    value: "USADO",
    label: "USADO"
  },
  {
    value: "MIXTO",
    label: "MIXTO"
  }
];

const ModalFormatSRT = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { idItemSRT, setiIdItemSRT, isOpen, setIsOpen, numItemSRT, setNumdItemSRT } = useContext(GlobalContext);
  const [openAlert] = AlertErrorAlert();
  const [status_srt, setStatus_srt] = useState('');
  const [values, handleInputChange, reset, setValues] = useForm({
    srt_serial: "",
    srt_color: "",
    srt_special_description: ""
  });
  const { srt_serial, srt_color, srt_special_description } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!srt_color) {
      return openAlert("El campo Color es obligatorio", "error");
    }
    if (!status_srt?.value) {
      return openAlert("El campo Estado es obligatorio", "error");
    }
    if (!srt_special_description) {
      return openAlert("El campo Descripción es obligatorio", "error");
    }
    const formData = new FormData();
    formData.append("item_id", idItemSRT || '');
    formData.append("srt_serial", srt_serial || '');
    formData.append("srt_color", srt_color);
    formData.append("status_srt", status_srt?.value ? status_srt?.value : '');
    formData.append("srt_special_description", srt_special_description);
    try {
      const res = await __CreateFormatSrt(
        formData,
        environment.CREATE_FORMAT_SRT,
        token
      );
      if (res?.data?.status?.code === 200) {
        const result = res?.data?.status?.data;
        openAlert(res?.data?.status?.message, "success");
        setValues(result);
        setIsOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("item_id", idItemSRT);
    formData.append("srt_serial", '');
    formData.append("srt_color", '');
    formData.append("status_srt", '');
    formData.append("srt_special_description", '');
    try {
      const res = await __CreateFormatSrt(
        formData,
        environment.CREATE_FORMAT_SRT,
        token
      );
      if (res?.data?.status?.code === 200) {
        const result = res?.data?.status?.data;
        openAlert(res?.data?.status?.message, "success");
        setValues(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showFormatSRT = async () => {
    const formData = new FormData();
    formData.append("item_id", idItemSRT);
    try {
      const res = await __GetFormatSrt(
        formData,
        environment.GET_FORMAT_SRT,
        token
      );
      if (res?.data?.status?.code === 200) {
        const result = res?.data?.status?.data;
        setValues(result);
        setStatus_srt({ value: result?.status_srt, label: result?.status_srt });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    showFormatSRT();
  }, [idItemSRT])

  return (
    <div style={{ marginBottom: '20px' }}>
      <Text fontSize="md" mb={1} style={{ fontWeight: 500 }}>
        {`Informacion Adicional del Item ${numItemSRT}`}
      </Text>
      <Divider />
      <form action="">
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <LabelGroup
            name={"srt_serial"}
            onChange={handleInputChange}
            value={srt_serial ?? ""}
            tag={"Serial"}
            size={30}
            desicion={false}
            maxLength={200}
          />

          <LabelGroup
            name={"srt_color"}
            onChange={handleInputChange}
            value={srt_color || ""}
            tag={"Color"}
            size={30}
            desicion={false}
            maxLength={5000}
          />
          <InputSelect
            tag={"Estado"}
            data={data}
            set={setStatus_srt}
            value={status_srt || ""}
            size={30}
          />
        </div>
        <div style={{ width: "100%", paddingTop: '20px' }}>
          <Text style={{ fontSize: '14px', color: '#454546' }}>Caracteristicas especiales</Text>
          <Textarea style={{ border: '1px solid #cccccc', width: '100%', borderRadius: '4px' }}
            rows="4"
            name={"srt_special_description"}
            value={srt_special_description || ""}
            placeholder="Escriba aqui su descripción..."
            maxlength="5000"
            onChange={handleInputChange}>

          </Textarea>
        </div>
        <Center>
          <ButtonStyled onClick={handleSubmit}>Guardar</ButtonStyled>
          <ButtonStyled onClick={() => setIsOpen(false)} bgColor={'#797979'}>Cancelar</ButtonStyled>
          <ButtonStyled onClick={handleReset} bgColor={"#ea2e2e"}>Limpiar</ButtonStyled>
        </Center>
      </form>
    </div>
  )
}

export default ModalFormatSRT
