import {
  Box,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonStyled } from "styled/Buttons.styled";
import environment from "constants/apiConst";
import { useDebounceError } from "hooks/useDebounceError";
import ProductSearchBar from "./ProductSearchBar";
import { NumericFormat } from "react-number-format";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import isArrayNotEmpty, { isArray, isValid } from "utils/type-check-utils";
import GlobalContext from "context/GlobalContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const FormAddProductOP = ({ onCloseModal, id = null }) => {
  const [valueProduct, setValueProduct] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    quantity_product: "",
  });
  const { quantity_product, quantity } = values || {};
  const queryClient = useQueryClient();
  const { user_Qualified, requestData } = useContext(GlobalContext);
  const [nature, setNature] = useState("EXT");

  const { isLoading, mutate } = useMutation({
    mutationFn: requestData,
  });

  const respCreateProductOP = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      if (
        isArray(res?.data?.status?.message) &&
        isArrayNotEmpty(res?.data?.status?.message)
      ) {
        res?.data?.status?.message.map((item) => {
          return openAlert(item, "error");
        });

        return;
      }

      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["getSupplyItemOP"] });
      onCloseModal();
    }
  };

  const handleSendData = (e) => {
    e.preventDefault();

    if (!isValid(id)) {
      console.error("id not found");
      return;
    }

    const dataToSend = new FormData();
    dataToSend.append("production_order_id", id);
    dataToSend.append("quantity_product", quantity_product);
    dataToSend.append("product_id", valueProduct?.value || "");
    dataToSend.append("quantity", quantity);
    dataToSend.append("nature_of_inventory", nature);

    const config = {
      data: dataToSend,
      Endpoint:
        /* isValid(dataToEditOP)
        ? environment.UPDATE_SUPPLY_ITEM_OP
        :  */ environment.CREATE_PRODUCT_OP,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => respCreateProductOP(data),
    });
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    );
  };

  /*   const suppliesFilter = currentPage2?.unitedProducts?.filter(
    (nature) => nature?.nature_of_inventory !== "EXT"
  );

  const suppliesFilterExt = currentPage2?.unitedProducts?.filter(
    (nature) => nature?.nature_of_inventory !== "NAC"
  );
 */


  useEffect(() => {
    console.log("nature", nature);
  }, [nature]);

  return (
    <>
      <form>
        <Box display={"flex"} gap={4}>
          <ProductSearchBar
            label="Producto"
            required={true}
            set={setValueProduct}
            value={valueProduct}
            size={100}
            manualId={user_Qualified}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"quantity_product"}
            value={quantity_product}
            onChange={(e) => handleRegex(e, "Numeros", /^[0-9-.,\s]*$/g)}
            tag={"Cantidad de producto"}
            size={100}
            desicion={false}
            maxLength={34}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"quantity"}
            value={quantity}
            onChange={(e) => handleRegex(e, "Numeros", /^[0-9-.,\s]*$/g)}
            tag={"Cantidad"}
            size={100}
            desicion={false}
            maxLength={34}
          />

          {/* nature_of_inventory */}

          <Box px="2" fontWeight={"bold"} alignContent={"center"}>
            <RadioGroup defaultValue="EXT" value={nature} onChange={setNature}>
              <Stack direction="row">
                <Radio type="checkbox" value="EXT" />

                <label style={{ marginRight: "16px" }}>EXT</label>
                <Radio type="checkbox" value="NAC" />
                <label>NAC</label>
              </Stack>
            </RadioGroup>
          </Box>
        </Box>

        <div className="mb-3 ms-5">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>

            <ButtonStyled
              type="submit"
              onClick={handleSendData}
              isLoading={isLoading}
            >
              Agregar
            </ButtonStyled>
          </Box>
        </div>
      </form>
    </>
  );
};

export default FormAddProductOP;
