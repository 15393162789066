/* eslint-disable no-unused-vars */
import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuSecutiry from "components/Menu/MenuSecutiry";

/**
 *  TbodyTable - Componente que muestra los items al momento de realizar la busqueda en el registro de seguridad entrada y salida
 * @component
 * @param {Object} data data que se muestra en la tabla de items en entrada y salida.
 * @return {Component} Retorna componente tabla jsx
 */

const TbodyTable = ({ data }) => {
  console.log(data);
  const { description, quantity_dispatch, code_businessUnit } = data;
  return (
    <Tr>
      <Td className="text-center"> {description || "N/A"}</Td>
      {quantity_dispatch
        ? (
          <Td className="text-center">
            {quantity_dispatch || "N/A"}
          </Td>
        )
        : (
          <Td className="text-center"></Td>
        )}
      <Td className="text-center">
        {code_businessUnit || "N/A"}
      </Td>
    </Tr>
  );
};

export default TbodyTable;
