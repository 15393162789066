import React from 'react'
import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { IconGeneric } from 'styled/Icons.styled'
import { HiDotsHorizontal } from 'react-icons/hi'
import { OptionsBorradorForm } from './OptionsForm/OptionsBorradorForm'
import { OptionsPresentadoForm } from './OptionsForm/OptionsPresentadoForm'
import { OptionsAprobadosForm } from './OptionsForm/OptionsAprobadosForm'
import { OptionsAutorizadosForm } from './OptionsForm/OptionsAuthorizadosForm'
import { OptionsCaducar } from './OptionsForm/OptionsCaducar'
// import { OptionsCorrecionesForm } from "./OptionsForm/OptionsCorreccionesForm";

const MenuForm = ({ data, deleteFiled = true }) => {
  const optionMenuForm = {
    'BORRADOR': <OptionsBorradorForm data={data} />,
    'DEVUELTO': <OptionsBorradorForm data={data} />,
    'PRESENTADO': <OptionsPresentadoForm data={data} />,
    'APROBADO': <OptionsAprobadosForm data={data} />,
    'AUTORIZADO': <OptionsAutorizadosForm data={data} />,
    'CADUCADO': <OptionsCaducar data={data} />,
  }[data.status_entryForm || data.status_outputForm || '']; 


  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>
      {/* {movement === "Ingreso" ? ( */}
      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        {optionMenuForm}
      </MenuList>
    </Menu>
  )
}

export default MenuForm
