import React from "react";
import { IconGeneric } from "styled/Icons.styled";
import { SubTitle, Title } from "styled/Title";
import "./Style.css";
import { MdWarning } from "react-icons/md";

export const AlertReturnReason = ({ title, item, description, nickname }) => {
  return (
    <div id="adventenciaReturn" className="my-2">
      <div className="d-flex">
        <IconGeneric width="40px" className="text-warning" as={MdWarning} />
        <div className="d-flex">
          <Title size={"18px"} weight={600}>
            {title && title}
            {item &&
              item.map((elemento, index) => (
                <span key={index}> {elemento.name}.</span>
              ))}
          </Title>
          &nbsp;
          <Title size={"18px"} weight={600}>
            Motivo:&nbsp;
          </Title>
          <SubTitle size={"18px"}>{description && description}.</SubTitle>
          &nbsp;
          {nickname && (
            <>
              <Title size={"18px"} weight={600}>
                Usuario:&nbsp;
              </Title>
              <SubTitle size={"18px"}>{nickname && nickname}.</SubTitle>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
