import html2pdf from 'html2pdf.js'

/**
 *  ExportPdf - Funcion para exportar documento PDF
 * @param {String } id Id del div que se va a exportar.
 * @param {String} name Nombre que llevara el documento al exportar.
 * @param {String} orien Orientacion del archivo
 * @return {Function} Retorna Documento PDF.
 */

export const ExportPdf = (id, name, orient) => {
  // landscape horizontal
  const element = window.document.getElementById(id).innerHTML
  const opt = {
    margin: 0.1,
    filename: `${name}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 3, letterRendering: true },
    jsPDF: {
      unit: 'in',
      format: 'A4',
      orientation: orient || 'portrait'
    }
  }
  html2pdf()
    .from(element)
    .set(opt)
    .toPdf()
    .get('pdf')
    .then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages()
      for (let i = 1; i <= totalPages; i++) {
        const now = new Date()
        const dateText = `${now.toLocaleDateString()}: ${now.getHours()}: ${now.getMinutes()}: ${now.getSeconds()}`

        pdf.setPage(i)
        pdf.setFontSize(10)

        pdf.text(
          `Page ${i} of ${totalPages}`,
          pdf.internal.pageSize.getWidth() - 1, // Positioned on the right side
          pdf.internal.pageSize.getHeight() - 0.8
        )

        pdf.text(
          dateText,
          0.3, // Positioned on the left side
          pdf.internal.pageSize.getHeight() - 0.8
        )
      }
    })
    .save()
  // html2pdf().set(opt).from(element).save()
}
