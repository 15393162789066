import { useModal } from "hooks/useModal";

const { createContext, useState } = require("react");

const StatisContext = createContext();

export const StatisProvider = ({ children }) => {
  const [isOpenOtherData, onOpenOtherData, onCloseOtherData] = useModal();

  const [dataForCal, setdataForCal] = useState([]);

  const data = {
    isOpenOtherData,
    onOpenOtherData,
    onCloseOtherData,
    dataForCal,
    setdataForCal
  };

  return (
    <StatisContext.Provider value={data}>{children}</StatisContext.Provider>
  );
};

export default StatisContext;
