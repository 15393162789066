import React, { useContext, useEffect, useCallback, useState } from "react";
import { Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "../LabelGroup";
import { Continuar, Cancelar } from "../Stilos";

import ProcessCrudContext from "context/ProcessCrudContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";

import { AuthContext } from "context/AuthContext";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { isValid } from "utils/type-check-utils";

export const AddOtherProcess = () => {
  const [decimalUc, setDecimalUc] = useState(null);
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const { authUser } = useContext(AuthContext);

  const location = useLocation();
  const { id = "", identificacion = "" } = queryString.parse(location.search);
  const [values, handleInputChange, reset, setValues] = useForm({
    name_cost: "",
    quantity_cost: null,
    value_cost: "",
    net_weight_cost: "",
  });
  const { name_cost, quantity_cost, value_cost, net_weight_cost } = values;
  const { dataToEdit, onCloseModal, createData, editData } =
    useContext(ProcessCrudContext);
  const [fomateo] = useFormatQuantity();

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
    } else {
      setValues(values);
    }
  }, [dataToEdit, setValues]);
  useEffect(() => {
    if (identificacion) {
      userC();
    }
  }, []);

  const userC = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const serachUc = res?.data?.status?.data.find(
        ({ id }) => id === Number(identificacion)
      );
      setDecimalUc(serachUc.decimal_values_precision);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const addProduc = () => {
    const { quantity_cost: _, ...rest } = values || {};

    const data = {
      ...rest,
      ...(isValid(quantity_cost) && {
        quantity_cost: quantity_cost.replaceAll(",", ""),
      }),
      value_cost: value_cost.replaceAll(",", ""),
      net_weight_cost: net_weight_cost.replaceAll(",", ""),
      productiveProcess_id: id,
    };

    if (dataToEdit) {
      const dataEdit = {
        ...rest,
        ...(isValid(quantity_cost) && {
          quantity_cost: quantity_cost.replaceAll(",", ""),
        }),
        value_cost: value_cost.replaceAll(",", ""),
        net_weight_cost: net_weight_cost.replaceAll(",", ""),
        id: dataToEdit.id,
      };
      editData(dataEdit, reset);
    } else {
      createData(data, reset);
    }
  };

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimalUc
            ? decimalUc
            : Number(decimal_values_precision),
          posibleResult
        ),
      });
    }
  };

  return (
    <>
      {" "}
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar ítem" : "Agregar ítem"}
      </Text>
      <Divider mb={3} />
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
          }}
        >
          <LabelGroup
            tag={"Nombre"}
            name={"name_cost"}
            size={45}
            value={name_cost || ""}
            onChange={handleInputChange}
            desicion={false}
          />
          {/*   <LabelGroup
            tag={"Cantidad"}
            name={"quantity_cost"}
            size={45}
            value={quantity_cost || ""}
            onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
            onBlur={handleBlurCant}
            desicion={false}
          /> */}
          <LabelGroup
            name={"value_cost"}
            tag={"Valor"}
            size={45}
            value={value_cost || ""}
            onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
            onBlur={handleBlurCant}
            desicion={false}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
          }}
        >
          <LabelGroup
            name={"net_weight_cost"}
            tag={"Peso neto"}
            size={45}
            value={net_weight_cost || ""}
            onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
            onBlur={handleBlurCant}
            desicion={false}
          />
        </div>
      </form>
      <Flex>
        <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
        <Spacer />
        <Continuar onClick={addProduc}>
          {" "}
          {dataToEdit ? " Editar ítem" : "Agregar ítem"}
        </Continuar>
      </Flex>
    </>
  );
};
