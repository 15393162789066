import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuTesoreria from "components/Menu/MenuTreasury/MainMenuTreasury";
import React, { useContext } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import TreasuryCrudContex from "context/TreasuryContex";

const TBodyUpdateRate = ({ data }) => {
  const { setDataIdShow, onOpenViewUpdate, dataToRecord } =
    useContext(TreasuryCrudContex);

  const DataIdShow = () => {
    setDataIdShow(data);
    onOpenViewUpdate();
  };

  return (
    <>
      <Tr>
        <Td className="text-center">{data?.date_rate}</Td>
        <Td className="text-center">{data?.name_type_rate}</Td>
        <Td className="text-center">{data?.nickname}</Td>

        <Tooltip placement="top" hasArrow label={"Ver más"}>
          <Td className="text-center">
            <IconGeneric
              color={"#1F7AC3"}
              as={BsEyeFill}
              onClick={DataIdShow}
            />
          </Td>
        </Tooltip>
        <Td className="text-center" isNumeric>
          <MenuTesoreria data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TBodyUpdateRate;
