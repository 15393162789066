/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Tooltip } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetInventory
} from "request/Inventory/__InventoryCRUD";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  arrayLeftRefProduct,
  arrayRigthRefProduct,
  arrayRigthUserRefProduct
} from "./Array/ArrayExport";
import { theadInventoryInProcess } from "./Array/ArrayInventoryControl";
import { FormProduct } from "./SearchTabInventory/FormProduct";
import { TbodyInventoryInProcess } from "./TbodyTable/TbodyInventoryInProcess";
import { Title } from "styled/Title";
import GlobalContext from "context/GlobalContext";
import { useDebounce } from "hooks/useDebouce";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray, safelyConvertToString } from "utils/type-check-utils";
import { productsNodeInputFields } from "pages/Treasury/ArrayList/ArrayList";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useSearchUrl } from "hooks/useSearchUrl";

export const InventoryInProcess = () => {
  const { cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["description", "product_code", "subheading", "group_product", "status_product", "without_quantity", "date_start", "date_end", "valueProducts"], nestedProp: "searchAdvance" })

  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [openAlert] = AlertErrorAlert();
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const { setUser_Qualified } = useContext(GlobalContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: null,
  });

  const { searchInformacion, valueQualified } = values;
  const Searchdebounced = useDebounce(searchInformacion, 700);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    dispacthRedux(clearSearch());
    setChangeViewSearch(false);
    setCurrentPage([]);
  };

  const dispacthRedux = useDispatch();

  useEffect(() => {
    if (searchInformacion?.length === 0 || searchInformacion !== 0) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }

    if (!valueQualified && !searchInformacion && !searcheAdvanced) {
      setCurrentPage([]);
      setPage(1);
    }
  }, [Searchdebounced, valueQualified]);

  useEffect(() => {
    const data = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified && { qualifiedUser_id: isArray(valueQualified) ? valueQualified[0]?.value : valueQualified?.value || valueQualified || valueQualified }),
      ...(searcheAdvanced && searcheAdvanced),
    };

    setUser_Qualified(valueQualified?.value ?? "");

    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      GetInventoryInProcess(data);
      dispacthRedux(setSearch(data));
      return
    }
    dispacthRedux(clearSearch());
  }, [Searchdebounced, searcheAdvanced, page, valueQualified, freeZoneValue]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, [])

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: data?.qualifiedUser_id[0]?.value || data?.qualifiedUser_id?.value || data?.qualifiedUser_id
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    exportColumnBy(info, onCloseExport);
  };

  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_INVENTORY
      );
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const GetInventoryInProcess = async (body) => {
    setLoading(true);
    try {
      const res = await __GetInventory(token, page, body);
      if (
        res?.data?.status.code === 200 &&
        res?.data?.status?.data?.data.length !== 0
      ) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      } else if (res?.data?.status?.data?.data.length === 0) {
        openAlert(
          "No se han encontrado resultados o el producto no tiene cantidad disponible",
          "error"
        );
        setCurrentPage([]);
        setMaxPage(1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateArrayExportNodes = () => {
    if (authUser.is_qualified_user === 1) { return arrayLeftRefProduct.concat(arrayRigthUserRefProduct); }

    if (authUser.is_qualified_user !== 1) { return arrayLeftRefProduct.concat(arrayRigthRefProduct); }

    return [];
  };

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
        size={"4xl"}
        enableAdvance={true}
        nodeList={calculateArrayExportNodes()}
        selectNodeList={productsNodeInputFields}
        handleSubmit={handleSubmit}
      />

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormProduct
          onClose={onCloseSearch}
          handleSearchSubmit={setSearchAdvanced}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Inventario en trámite</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getInventory"}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />

        <ExportDetail onOpen={onOpenExport} />
        {/* {allAccess ? (
          <ExportDetail onOpen={onOpenExport}>
            {changeViewSearch ? (
              <Tooltip placement="top" hasArrow label="Limpiar filtro">
                <ButtonHeaderModule
                  onClick={backSearch}
                  bgColor="#F9A621"
                  className="  d-flex float-end"
                >
                  <IconGeneric color="#fff" as={AiOutlineClear} />
                </ButtonHeaderModule>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" hasArrow label="Filtro">
              <ButtonHeaderModule
                onClick={onOpenSearch}
                bgColor="#1F7AC3"
                className="mx-1   d-flex float-end"
              >
                <IconGeneric color="#fff" as={BiFilter} />
              </ButtonHeaderModule>
            </Tooltip>
          </ExportDetail>
        ) : myPermission?.includes("exportExcelInventory") ? (
          <ExportDetail onOpen={onOpenExport}>
            {changeViewSearch ? (
              <Tooltip placement="top" hasArrow label="Limpiar filtro">
                <ButtonHeaderModule
                  onClick={backSearch}
                  bgColor="#F9A621"
                  className="mx-1   d-flex float-end"
                >
                  <IconGeneric color="#fff" as={AiOutlineClear} />
                </ButtonHeaderModule>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" hasArrow label="Filtro">
              <ButtonHeaderModule
                onClick={onOpenSearch}
                bgColor="#1F7AC3"
                className="ms-1   d-flex float-end"
              >
                <IconGeneric color="#fff" as={BiFilter} />
              </ButtonHeaderModule>
            </Tooltip>
          </ExportDetail>
        ) : null} */}
        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadInventoryInProcess}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyInventoryInProcess}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
