// FREE TRADE ZONES TITLE
export const TitleZone = [
  { name: "Nombre", order: "name_freeZone" },
  { name: "Abreviatura", order: "shortName_freeZone" },
  { name: "Tipo", order: "name_typeFreeZone" },
  { name: "Máximo valor COP", order: "max_value_cop" },
  { name: "Mostrar usuarios pdf", order: "show_pdf_user" },
  { name: "Copiar movimientos", order: "copy_movements" },
  { name: "Cambiar subpartidas", order: "change_subheading" },
  { name: "Sello Dian", order: "dian_stamp" },
  { name: "Revisión detallada", order: "detailed_review" },
  { name: "Can add adjuntos", order: "can_add_attachments" },
  { name: "Maneja puerta vehicular", order: "drive_vehicle_door" },
  { name: "Subpartida especial", order: "special_subheading" },
  { name: "Bloqueo aprobacion", order: "approval_lock" },
  {
    name: "Validar procesamiento parcial",
    order: "validate_partial_processing"
  },
  { name: "Advertir subpartidas", order: "warn_subheadings" },
  { name: "Decl temporal", order: "temporary_decl" },
  { name: "Validar salidas reposicion", order: "validate_reposition_outputs" },
  { name: "Exportar detallado documentos", order: "export_detailed_documents" },
  { name: "Usar perfiles de dian", order: "use_dian_profiling" },
  { name: "Estados zonas F", order: "status_freeZone" },
  { name: "No requiere pase de entrada", order: "not_require_entry_pass" },
  { name: "No requiere pase de salida", order: "not_require_exit_pass" },
  { name: "Puerto", order: "port" },
  { name: "Acciones", order: "status_freeZone" }
];

// SUBHEADING CONTROL TITLE

export const SubheadingTitle = [
  { name: "Nombre", order: "name_freeZone" },
  { name: "Codigo", order: "code_subheading" },
  { name: "Bloqueo aprobado", order: "block_approval" },
  { name: "Bloqueo radicación", order: "block_filing" },
  { name: "Ingreso", order: "income" },
  { name: "Salida", order: "departures" },
  { name: "Resto del mundo", order: "rest_of_the_world" },
  { name: "Colombia", order: "colombia" },
  { name: "Usuario", order: "username" },
  { name: "Zona Franca", order: "free_zone" },
  { name: "Acciones", order: "actions" }
];

export const ZonesControlTitle = [
  { name: "Nombre", order: null },
  { name: "Tipo", order: null },
  { name: "Mostrar usuarios PDF", order: null },
  { name: "Mostrar usuarios PRS con Repr", order: null },
  { name: "Copiar movimientos", order: null },
  { name: "Cambiar subpartida", order: null },
  { name: "Sello dian", order: null },
  { name: "Revision detallada", order: null }
  // { name: "Acciones", order: "actions" },
];
