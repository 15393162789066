import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const FormCorrectFromUserToUser = ({ data, dataCorrect }) => {
  const { StateNit } = useContext(FormCrudCRUDContext);
  const { qualifiedUser2_id, nit_qualified2, name_qualified2, num_exit_form, balance_correction } = data;

  const nameQualified = nit_qualified2 ? nit_qualified2 + (name_qualified2 ? " - " + name_qualified2 : "") : "";

  const { num_exit_form_corrected } = dataCorrect;
  return (
    <>
      <LabelGroup
        name={"searchInformacion"}
        value={nameQualified}
        tag={"Nit"}
        size={100}
        desicion={true}
        changeValue={
          qualifiedUser2_id !== StateNit?.value &&
          balance_correction !== 1 &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={num_exit_form}
        tag={"Nº Formulario de salida"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_exit_form) !== safelyConvertToString(num_exit_form_corrected) &&
          balance_correction !== 1 &&
          "BorderCorrect"
        }
      />
    </>
  );
};
