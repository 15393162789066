/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text, Box, Divider } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { Country, AcuerdoC } from "../../../components/InputSelect/InputSelect";
import { __SelectPaises } from "../../../request/configuration/_Country";
import { __Acuerdos } from "../../../request/configuration/__ASubheading";

import { HrDivider } from "styled/Line.styled";
import { useSelector } from "react-redux";

const FormCountry = () => {
  const [data, setData] = useState([]);
  const [mendigo, setMendigo] = useState([]);
  const [dataAc, setDataAc] = useState([]);
  const [Acuerdos, setAcuerdos] = useState([]);
  const [code, setCode] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);

  const { onCloseModal, createData, dataToEdit, editData } = useContext(
    ConfigurationCRUDContext
  );

  useEffect(() => {
    if (dataToEdit) {
      setMendigo({
        label: dataToEdit.name_country,
        value: dataToEdit.country_id
      });

      setCode(dataToEdit.code_country);

      setAcuerdos({
        label: dataToEdit.code_tradeAgreement,
        value: dataToEdit.tradeAgreement_id
      });
    }
  }, [dataToEdit]);

  const userC = async () => {
    try {
      const res = await __SelectPaises(token);
      setData(res?.data?.status?.data);
    } catch (error) {}
  };

  const acuerdosC = async () => {
    try {
      const res = await __Acuerdos(token);
      setDataAc(res?.data?.status?.data);
    } catch (error) {}
  };

  useEffect(() => {
    userC();
    acuerdosC();
  }, []);

  const handleSubmit = (e) => {
    const dataResult = {
      country_id: mendigo?.value,
      code_country: code,
      tradeAgreement_id: Acuerdos?.value,
      code_tradeAgreement: Acuerdos?.label
    };

    if (dataToEdit) {
      const data = {
        ...dataResult,
        id: dataToEdit.id
      };
      editData(data);
    } else {
      createData(dataResult);
    }
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar país acuerdo comercial" : "Crear país con acuerdo comercial"}
      </Text>
      <Divider my={2} />
      <form action="">
        <div
          style={{
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Country
            className="p-2 "
            paises={data}
            tag={"Países"}
            size={45}
            setMendigo={setMendigo}
            mendigo={mendigo}
            code={code}
            setCode={setCode}
          />

          <AcuerdoC
            data={dataAc}
            tag={"Acuerdo Comercial"}
            size={45}
            setAcuerdos={setAcuerdos}
            Acuerdos={Acuerdos}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          className="mt-5"
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormCountry;
