import React from 'react'
import { Td, Tr } from '@chakra-ui/react'

const TbodyEntryFormAsociatedPass = ({ data }) => {
  console.log('TbodyEntryFormAsociatedPass', data)
  return (
    <Tr>
      <Td className='text-center'>{data?.item}</Td>
      <Td className='text-center'>{data?.code_product}</Td>
      <Td className='text-center'>{data?.code_businessUnit} </Td>
      <Td className='text-center'>{data?.code_subheading}</Td>
      <Td className='text-center'>{data?.code_unit_measurement}</Td>
      <Td className='text-center'>{data?.description_product}</Td>
      <Td className='text-center'>{data?.entry_form_item_description}</Td>
    </Tr>
  )
}

export default TbodyEntryFormAsociatedPass
