import axios from "axios";
import env from "../../constants/apiConst";

export const __Subheading = async (token, body, posicion) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.SUBHEADING_GET}?page=${posicion}`, options);
  return res;
};

export const __CAPITULO = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.CAPITULO_ARANCEL_GET}`, options);
  return res;
};

export const __ShowSubheading = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SUBHEADING_SHOW}/${id}`, options);
  return res;
};
