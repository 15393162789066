import { Table, Thead, Tr, Td, Tbody, Box, Button } from "@chakra-ui/react";
import { useContext } from "react";
import "../../SelectInput/stilos.css";
import { ThStyledBlue } from "./Table.styled";
import { BsArrowDownCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { __ExportExcel } from "request/Inventory/__InventoryCRUD";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ControlAnalysisContext from "context/ControlAnalysis";

const ControAnalysisTable = ({ columns, dataSource = [], loading }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { filterCurrent } = useContext(ControlAnalysisContext);
  const [openAlert] = AlertErrorAlert();

  const BgSelect = (name) => {
    switch (name) {
      case "BAJO":
        return "green";
      case "MEDIO":
        return "yellow";
      case "ALTO":
        return "red";
      default:
        return "white";
    }
  };

  const exportExcelMatrix = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        {
          start_date: filterCurrent?.start_date,
          end_date: filterCurrent?.end_date,
          qualifiedUser_id: filterCurrent?.qualification_id,
          sector_id: filterCurrent?.sector_id,
          free_zone_key: filterCurrent?.free_zone_id
        },
        environment.EXPORT_EXCEL_MATRIX
      );
      if (res?.status === 200) {
        const url = res?.data;
        window.location.href = url;
      } else {
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      openAlert(error, "error");
    }
  };

  return (
    <>
      {loading
        ? (
          <></>
        )
        : (
          <div className="table-responsive">
            <Box display={"flex"} justifyContent="space-between" w="full">
              <Button
                leftIcon={<BsArrowDownCircle color="white" />}
                colorScheme="blue"
                variant="solid"
                onClick={exportExcelMatrix}
              >
              Exportar
              </Button>
            </Box>
            <Table
              size="md"
              variant="simple"
              className="table table-striped table-sm "
              overflowX={true}
            >
              <Thead>
                <Tr>
                  {columns?.map((column) => {
                    return (
                      <ThStyledBlue
                        key={column.key}
                        style={{
                          backgroundColor: BgSelect(column?.risk_level),
                          color:
                          BgSelect(column?.risk_level) === "white"
                            ? "gray"
                            : "white"
                        }}
                        className="text-center"
                      >
                        <Box>{column.title}</Box>
                      </ThStyledBlue>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody>
                {dataSource.map((row) => (
                  <Tr key={row.key}>
                    {columns?.map((column) => {
                      return (
                        <Td key={column.key} className="text-center">
                          {row[column.dataIndex ?? column.key]}
                        </Td>
                      );
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}{" "}
    </>
  );
};

export default ControAnalysisTable;
