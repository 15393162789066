/**
 * Checks if a given value is a string.
 *
 * @param {any} value - The value to be checked.
 * @return {boolean} Returns true if the value is a string, false otherwise.
 */

import { parseFloat } from "./parseFloat";

export function isString(value, checkNumber = false) {
  if (!isValid(value) || typeof value !== "string") {
    return false;
  }

  if (checkNumber) {
    const trimmedValue = value.trim();

    if (trimmedValue === "") {
      return false;
    }

    const numberLike = value.replace(/,/g, "");
    const numberTransformed = parseFloat(numberLike);

    if (isNaN(numberTransformed) || !isValid(value)) {
      return false;
    }
  }

  return true;
}

/**
 * Comprueba si un valor es ni undefined ni null.
 *
 * @param {any} value - El valor a verificar.
 * @return {boolean} Devuelve true si el valor no es undefined ni null, de lo contrario false.
 */
export function isValid(value) {
  return value !== undefined && value !== null;
}

/**
 * Checks if a given value is a boolean.
 *
 * @param {any} value - The value to be checked.
 * @return {boolean} Returns true if the value is a boolean, false otherwise.
 */
export function isBoolean(value) {
  return typeof value === "boolean";
}

/**
 * Checks if an email is valid.
 *
 * @param {string} email - The email to be validated.
 * @return {boolean} Returns true if the email is valid, false otherwise.
 */
export function isEmailValid(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

/**
 * Checks if a given value is an object.
 *
 * @param {any} value - The value to be checked.
 * @return {boolean} Returns true if the value is an object, false otherwise.
 */

export function isObject(value) {
  return typeof value === "object" && value !== null;
}

/**
 * Checks if a given URL is valid.
 *
 * @param {string} url - The URL to be validated.
 * @return {boolean} Returns true if the URL is valid, false otherwise.
 */
export function isUrlValid(url) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

/**
 * Removes a specified item from an array.
 *
 * @param {Array} array - The array from which to remove the item.
 * @param {any} itemToRemove - The item to be removed from the array.
 * @return {Array} The new array with the item removed.
 */

export function removeItemFromArray(array, itemToRemove) {
  return array.filter((item) => item !== itemToRemove);
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isEmptyObject(obj) {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  return Object.keys(obj).length === 0;
}

export function isObjectNotEmpty(obj) {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }
  return Object.keys(obj).length > 0;
}

/**
 * Sanitizes an object by removing null and undefined values, and optionally custom values.
 * @param {Object} obj - The object to be sanitized.
 * @param {Array} [custom=[]] - An optional array of custom values to be excluded from the sanitized object.
 * @returns {Object} - The sanitized object.
 */
export const handleSanitizedObj = (obj, custom = []) => {
  const sanitizedObject = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && value !== undefined && !custom.includes(value)) {
      sanitizedObject[key] = value;
    }
  }
  return sanitizedObject;
};

export function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}

export function safelyConvertToString(value) {
  if (
    value === null ||
    value === undefined ||
    (isNumber(value) && isNaN(value))
  ) {
    return "";
  }

  if (typeof value === "string") return value;

  return String(value);
}

export const removeObjectProperty = (obj, propertyToDelete) => {
  // eslint-disable-next-line no-unused-vars
  const { [propertyToDelete]: deletedProperty, ...updatedObject } = obj;
  return updatedObject;
};

export const isFunction = (e) => {
  return typeof e === "function";
};

const isArrayNotEmpty = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export default isArrayNotEmpty;

export const addIfNotExists = (newElement, Setter) => {
  Setter((prevArray) => {
    if (isArray(newElement)) {
      const uniqueElements = newElement.filter(
        (element) => !prevArray.includes(element)
      );
      return [...prevArray, ...uniqueElements];
    }

    if (!prevArray.includes(newElement)) {
      return [...prevArray, newElement];
    }

    return prevArray;
  });
};

export const mergeUniqueElements = (array1, array2) => {
  if (!isArray(array1)) {
    console.error("El primer argumento debe ser un array.");
  }

  if (!isArray(array2)) {
    console.error("El segundo argumento debe ser un array.");
  }

  if (!isArray(array1) && !isArray(array2)) {
    console.error("Los argumentos deben ser arrays.");
    return;
  }

  const filteredArray1 = isArray(array1)
    ? array1.filter((item) => item !== "" && item != null)
    : [];

  const filteredArray2 = isArray(array2)
    ? array2.filter((item) => item !== "" && item != null)
    : [];

  const combinedArray = [...new Set([...filteredArray1, ...filteredArray2])];
  return combinedArray;
};

export const arrayToString = (array, separator = ", ") => {
  if (!isArray(array)) {
    console.error("El argumento deben ser un array.");
    return;
  }

  if (array.length > 1) {
    let initialPart = array.slice(0, -1).join(separator);
    let lastPart = array[array.length - 1];
    return `${initialPart}${separator}${lastPart}`;
  } else {
    return array.join("");
  }
};

export const cleanObject = (obj, replaceWithEmptyString = false) => {
  if (!isObject(obj) || obj === null) {
    throw new Error("La entrada debe ser un objeto.");
  }

  const cleanedObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value !== null && value !== undefined &&  value !== '') {
      cleanedObj[key] = value;
    } else if (replaceWithEmptyString) {
      cleanedObj[key] = "";
    }
  });

  return cleanedObj;
};

export const cleanDeepObject = (obj, replaceWithEmptyString = false) => {
  if (!isObject(obj) || obj === null) {
    throw new Error("La entrada debe ser un objeto.");
  }

  const cleanObj = isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (isObject(value) && value !== null) {
      return (cleanObj[key] = cleanDeepObject(value, replaceWithEmptyString));
    }

    if (value !== null && value !== undefined) {
      return (cleanObj[key] = value);
    }

    if (replaceWithEmptyString) {
      return (cleanObj[key] = "");
    }

    return obj;
  });

  return cleanObj;
};

export const isNonEmptyString = (value, checkNumber = false) => {
  if (typeof value === "string" || (checkNumber && isString(value, true))) {
    const stringValue = value.toString();
    return stringValue.trim().length > 0;
  }

  return false;
};

export const removeElementFromArray = (
  array,
  position,
  returnOriginal = true
) => {
  if (!isArray(array) || !isArrayNotEmpty(array)) {
    console.error("The first argument must be a non-empty array.");
    return returnOriginal ? array : null;
  }

  if (
    isNumber(position) ||
    isNaN(position) ||
    position < 0 ||
    position >= array.length
  ) {
    console.error("The provided position is not valid.");
    return returnOriginal ? array : null;
  }

  const newArray = [...array];
  newArray.splice(position, 1);

  return newArray;
};

export const hasCommonElements = (sourceArray = [], targetElements = []) => {
  if (!Array.isArray(sourceArray) || !Array.isArray(targetElements)) {
    console.error("Ambos argumentos deben ser arrays");
    return false;
  }
  const targetSet = new Set(targetElements);
  return sourceArray.some((element) => targetSet.has(element));
};

export const isNumberFormatable = (value) => {
  if (value === null || value === undefined) {
    return false;
  }

  const valueStr = String(value).trim().toLowerCase();

  const phoneNumberPattern = /^\+?[0-9]{10,15}$/;
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (phoneNumberPattern.test(valueStr) || datePattern.test(valueStr)) {
    return false;
  }

  const num = Number(valueStr);

  if (isNaN(num)) {
    return false;
  }

  const normalStr = String(num);
  const scientificStr = num.toExponential().toLowerCase();

  if (
    valueStr === normalStr ||
    valueStr === scientificStr ||
    valueStr === num.toFixed()
  ) {
    return true;
  }

  return parseFloat(valueStr) === num && !/[^\d.e-]/i.test(valueStr);
};

export const excludeProperties = (source, excludedProps) => {
  return Object.keys(source).reduce((acc, key) => {
    if (!excludedProps.includes(key)) {
      acc[key] = source[key];
    }
    return acc;
  }, {});
};

export const checkProperty = ({
  obj = {},
  prop,
  value = undefined,
  checkPropertyAndValue = false,
  checkType = false,
}) => {
  if (isArray(obj)) {
    if (checkPropertyAndValue && !isValid(value)) {
      console.error("value must be valid if checkPropertyAndValue is true.");
    }

    const hasTheProperty = isObjectNotEmpty(obj)
      ? obj.some((element) => element.hasOwnProperty(prop))
      : false;

    const isValidValue =
      isObjectNotEmpty(obj) && isValid(value)
        ? obj.some((element) => element?.[prop] === value)
        : false;

    if (hasTheProperty && isValid(value) && checkPropertyAndValue) {
      return isValidValue;
    }

    if (hasTheProperty) {
      return hasTheProperty;
    }
  }

  if (isObject(obj)) {
    const hasTheProperty = obj.hasOwnProperty(prop);

    if (hasTheProperty) {
      return true;
    }

    if (!hasTheProperty) {
      return false;
    }

    if (hasTheProperty && checkPropertyAndValue && isValid(value)) {
      return obj[prop] === value;
    }
  }
};

export const removeDecimalIfZero = (number, decimalPlaces = 0, name) => {
  const roundedNumber = parseFloat(number)

  if (!isValid(roundedNumber)) {
    console.error('The parameter must be a valid number.')
  }


  return +roundedNumber?.toFixed(decimalPlaces).replace(/(\.0+|0+)$/, '');
}

export   const validateValue = ({ value }, maxIntegerDigits = 10) => {
  // Si no hay valor, permitir
  if (!value) return true;

  // Separar parte entera y decimal
  const [integerPart, decimalPart] = value.toString().split('.');
  
  // Remover separadores de miles si existen
  const cleanIntegerPart = integerPart.replace(/,/g, '');
  
  // Verificar si la parte entera excede el límite
  return cleanIntegerPart.length <= maxIntegerDigits;
};
