import {
  __ChangeStatus,
  __CreateCommentary,
  __EditCreatePass
} from "../request/Pases/__CrudPass";
import { useNavigate } from "react-router-dom";
import {
  __CopyDesaduanamiento,
  __DeleteAttachmentCustoms,
  __getDeleteItemCustomsClearance,
  __getShowItemCustomsClearance,
  __postItemCustomsClearance
} from "request/CustomsClearance.js/__CustomsClearence";
import { useSelector } from "react-redux";
import { useModal } from "hooks/useModal";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post, __PostJsonData } from "request/Petitions/__Post";
import environment from "constants/apiConst";
const { createContext, useState } = require("react");

const CustomsCrudContext = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */
export const CustomsCrudProvider = ({ children }) => {
  const [isOpenCopy, onOpenCopy, onCloseCopy] = useModal();
  const [isOpenReturnReason, onOpenReturnReason, onCloseReturnReason] =
    useModal();
  const [openAlert] = AlertErrorAlert();
  const [newCommentary, setNewCommentary] = useState(true);
  const [ReturnReason, setReturnReason] = useState(null);
  const [decimalUcContext, setDecimalUcContext] = useState(null);

  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestCreate, setRequestCreate] = useState(null); //
  const [requestChangeStatus, setRequestChangeStatus] = useState(null);
  const [requestBulkLoad, setRequestBulkLoad] = useState(null);
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [formLicensias, setFormLicensias] = useState(null);
  const [update, setUpdate] = useState(true);
  const navigate = useNavigate();
  const [requestCopy, setRequestCopy] = useState(null); //
  const token = useSelector((state) => state.auth.dataUser.token);
  const [itemId, setItemId] = useState(null);
  const [subTotals, setSubTotals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [codeSubheading, setCodeSubheading] = useState(null);
  const [dataTableAddItems, setDataTableAddItems] = useState([]);
  const [pageCustomsClearances, setPageCustomsClearances] = useState(1);
  const [maxPageCustomsClearances, setMaxPageCustomsClearances] = useState(1);
  const [editItemsCustomsClearance, seteditItemsCustomsClearance] =
    useState(false);
  const [getItemsFormCustomsClearances, setgetItemsFormCustomsClearances] =
    useState({});

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const editData = async (data) => {
    try {
      const res = await __EditCreatePass(requestEdit, token, data);
      const infoUpdate = res?.data;
      console.log(infoUpdate);
      if (infoUpdate.status.code === 403) {
        openAlert(infoUpdate.status.message, "error");
      } else if (infoUpdate.status.code === 400) {
        openAlert(infoUpdate.status.message, "error");
      } else {
        openAlert(`Registro actualizado Exitosamente`, "success");
        navigate(`/ShowCustomsClearance?Customs=${infoUpdate.status.data.id}`);
        setDataToEdit(null);
        setUpdate(!update);
        setDataToShow(infoUpdate.status.data);
      }
    } catch (error) { }
  };

  const createData = async (data) => {
    try {
      const res = await __EditCreatePass(requestCreate, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro creado Exitosamente`, "success");
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttachment = async (endpoint, id) => {
    try {
      const res = await __DeleteAttachmentCustoms(endpoint, token, id);
      if (res.data.status.code === 200) {
        setUpdate(!update);
        openAlert(res.data.status.message, "success");
      } else if (res.data.status.code === 403) {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatus = async (data) => {
    try {
      const res = await __ChangeStatus(requestChangeStatus, token, data);
      // console.log(res.data.status);
      let estadoNuevo;
      const numeroFormulario =
        res?.data?.status?.data?.customsClearanceForm_number;
      if (data.status_customsClearance === "BORRADOR") {
        estadoNuevo = "recuperado";
      } else if (data.status_customsClearance === "AUTORIZADO") {
        estadoNuevo = "autorizado";
      } else if (
        data.status_entryPass === "DEVUELTO" ||
        data.status_dispatchPass === "DEVUELTO"
      ) {
        estadoNuevo = "devuelto";
      } else {
        estadoNuevo = "devuelto";
      }

      if (res.data.status.code === 200) {
        const titleMessage = `El formulario ${numeroFormulario} fue ${estadoNuevo} Exitosamente`;
        openAlert(titleMessage, "success");
        setUpdate(!update);
        onCloseReturnReason();
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportColumnBy = async (data, sucessCallback = () => { }) => {
    try {
      const res = await __Post(environment.EXPORT_DOCUMENT_CUSTOMS_CLEARANCE, token, data);
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        sucessCallback();
      }
    } catch (error) {
      openAlert(`Se ha presentado un problema, por favor intente más tarde`, "error")
    }
  };

  const onCloseModal = () => {
    if (isOpenCopy) {
      onCloseCopy();
    }
  };

  const copyData = async () => {
    try {
      const res = await __CopyDesaduanamiento(
        requestCopy,
        token,
        dataToEdit?.id
      );
      if (res?.data?.status?.code === 200) {
        setDataToEdit(res?.data?.status?.data);
        onCloseCopy();
        openAlert(`Registro copiado Exitosamente`, "success");
        setUpdate(!update);
        navigate(
          `/formCustomsClearance?Customs=${res?.data?.status?.data?.id}`
        );
      } else {
        if (res?.data?.status?.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getItemsCustomsClearances = async (id, orderBy = { value: 'ASC' }) => {
    // const idBody = id ? id : dataToEdit?.id;
    try {
      const payload = {
        customsClearence_id: id,
        orderBy: orderBy.value
      }
      const res = await __PostJsonData(environment.ITEMS_CUSTOMS_CLEARANCE,
        token,
        payload,
        pageCustomsClearances,
      );
      const data = res.data?.status?.data?.data?.data;
      setDataTableAddItems(data || []);
      setMaxPageCustomsClearances(res?.data?.status?.data?.data?.last_page);
      setSubTotals(res?.data?.status?.data?.subtotal);
    } catch (error) {
      console.log(error);
    }
  };

  const editCustomClearanceFormItems = async ({ customsClearanceItem_id }) => {
    console.log(customsClearanceItem_id);
    setLoading(true);
    try {
      const res = await __getShowItemCustomsClearance(
        token,
        customsClearanceItem_id
      );
      const data = res.data?.status?.data;
      console.log('FormCustoms', data)
      setgetItemsFormCustomsClearances(data);
      // seteditItemsCustomsClearance(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteCustomClearanceFormItems = async ({
    customsClearanceItem_id
  }) => {
    setLoading(true);
    try {
      await __getDeleteItemCustomsClearance(token, customsClearanceItem_id);
      getItemsCustomsClearances();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const createCommentary = async (endpoint, data) => {
    try {
      const res = await __CreateCommentary(endpoint, token, data);
      if (res.data.status.code !== 200) {
        openAlert(res.data.status.message, "error");
      } else {
        setNewCommentary(!newCommentary);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    itemId,
    update,
    loading,
    editData,
    copyData,
    formEdit,
    formShow,
    subTotals,
    setUpdate,
    setItemId,
    setLoading,
    dataToEdit,
    dataToShow,
    isOpenCopy,
    onOpenCopy,
    createData,
    setFormEdit,
    setFormShow,
    showAddItem,
    dataToDelete,
    onCloseModal,
    changeStatus,
    formLicensias,
    setDataToShow,
    setDataToEdit,
    codeSubheading,
    exportColumnBy,
    setRequestEdit,
    setShowAddItem,
    setRequestCopy,
    setDataToDelete,
    requestBulkLoad,
    setFormLicensias,
    setRequestCreate,
    deleteAttachment,
    setCodeSubheading,
    dataTableAddItems,
    setRequestBulkLoad,
    setDataTableAddItems,
    pageCustomsClearances,
    setRequestChangeStatus,
    setPageCustomsClearances,
    maxPageCustomsClearances,
    getItemsCustomsClearances,
    editItemsCustomsClearance,
    setMaxPageCustomsClearances,
    editCustomClearanceFormItems,
    seteditItemsCustomsClearance,
    getItemsFormCustomsClearances,
    deleteCustomClearanceFormItems,
    setgetItemsFormCustomsClearances,
    setNewCommentary,
    newCommentary,
    createCommentary,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    ReturnReason,
    setReturnReason,
    decimalUcContext,
    setDecimalUcContext
  };

  return (
    <CustomsCrudContext.Provider value={data}>
      {children}
    </CustomsCrudContext.Provider>
  );
};

export default CustomsCrudContext;
