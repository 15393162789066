import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const bold = { fontWeight: "bold" };

const TbodyUnd = ({ data, iope }) => {
  return (
    <Tr>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td isNumeric style={bold}>
        {data?.total_unit}
      </Td>
      <Td isNumeric style={bold}>
        {data?.unit}
      </Td>
    </Tr>
  );
};

export default TbodyUnd;
