import { Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonItemsForm } from "../stylePases";

export const AddItemsForm = () => {
  return (
    <>
      {" "}
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        {/* {dataToEdit ? " Editar Rol" : "Crear Rol"} */}
        Buscar items del formulario
      </Text>
      <Divider my={5} />
      <form
      //   onSubmit={hanldeSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"name_rol"}
            // onChange={handleInputChange}
            // value={name_rol}
            tag={"Ingreso"}
            size={45}
            desicion={false}
          />
          <LabelGroup
            name={"description_rol"}
            // onChange={handleInputChange}
            // value={description_rol}
            tag={"Autorización de Ingreso"}
            size={45}
            desicion={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"name_rol"}
            // onChange={handleInputChange}
            // value={name_rol}
            tag={"Fecha Inicio"}
            size={45}
            desicion={false}
            tipo={"date"}
          />
          <LabelGroup
            name={"description_rol"}
            // onChange={handleInputChange}
            // value={description_rol}
            tag={"Fecha Final"}
            size={45}
            desicion={false}
            tipo={"date"}
          />
        </div>
        <Flex mt={6}>
          <ButtonItemsForm
            color="grisaso"
            // onClick={onCloseModal}
          >
            Cancelar
          </ButtonItemsForm>
          <Spacer />
          <ButtonItemsForm
            color="aqua"
            //    onClick={onOpenEdit}
          >
            Buscar
          </ButtonItemsForm>
        </Flex>
      </form>
    </>
  );
};
