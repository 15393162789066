/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import OrderProductionContext from "context/OrderProductionContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Cancelar, Continuar } from "pages/RefProductInter/Stilos";
import { useSelector } from "react-redux";
import { __PlantillasUsers } from "request/productoInterno/__Plantillas";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { __ShowFormOrder } from "request/OrderProduction/OrderProduction";
import { LabelStyledLink } from "components/addProduct/CreateTemplate.styled";
import { IconInModal } from "styled/Icons.styled";
import { HiOutlinePlus } from "react-icons/hi";
import { AuthContext } from "context/AuthContext";
// import useDebounceText from "hooks/useSearchDebounce";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { MaxLength } from "utils/MaxLength";
import environment from "constants/apiConst";
import { NumericFormat } from "react-number-format";
import queryString from "query-string";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { parseFloat } from "utils/parseFloat";
import InputSelect from "components/InputSelect/InputSelect";
import { dataNat } from "pages/InventoyControl/SearchTabInventory/ArraySelect";
import { isValid } from "utils/type-check-utils";

export const FormTemplate = ({
  Orderid,
  UserId,
  location,
  apiManual = null,
}) => {
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const locationCI = useLocation();

  const [fomateo] = useFormatQuantity();
  const { pathname } = locationCI;

  const { id: idCI = null, idCorrected = null } = queryString.parse(
    locationCI.search
  );
  const idForm = idCI || idCorrected || Orderid;
  // const [text, setText] = useDebounceText();
  const [loading, setLoading] = useState(false);
  const { authUser } = useContext(AuthContext);
  const {
    onCloseModal,
    associateTemplate,
    dataToEdit,
    dataToGeneral,
    decimalUc,
    loading: loadingAssociate,
    editTemplate,
  } = useContext(OrderProductionContext);
  const [openAlert] = AlertErrorAlert();

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          authUser.admin === 1 && decimalUc
            ? decimalUc
            : Number(decimal_values_precision),
          posibleResult
        ),
      });
    }
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    code_productive_process: "",
    total_product: "",
    description_productive_process: "",
    id: "",
  });

  const {
    code_productive_process,
    total_product,
    description_productive_process,
    id,
  } = values;

  const consultaPlantilla = useCallback(
    async (formData) => {
      const dato =
        authUser.is_qualified_user === 1 ? authUser.userQualified : UserId;
      try {
        const res = await __PlantillasUsers(token, dato, formData);
        const data = res?.data?.status?.data;
        console.log(res?.data?.status?.data?.id);
        if (res?.data?.status.code === 200) {
          setValues((values) => ({
            ...values,
            description_productive_process: data.description_productive_process,
            id: data.id,
          }));
          console.log(values.id);
        } else {
          setValues((values) => ({
            ...values,
            description_productive_process: "",
            id: "",
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      setValues,
      token,
      UserId,
      authUser.userQualified,
      authUser.is_qualified_user,
    ]
  );

  const { requestData } = useGlobalContext();

  const { mutate } = useMutation({
    mutationKey: ["references"],
    mutationFn: requestData,
    onSuccess: (data) => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, "error");
      }
      reset();
    },
  });

  const queryClient = useQueryClient();

  const createValidate = () => {
    const informacion = {
      id: parseInt(idForm),
      productive_process_form_id: id,
      qualifiedUser_id: UserId,
     // nature_of_product: nat?.value,
      total_product: parseFloat(total_product),
      total_calculated: total_product.includes(",")
        ? total_product.replaceAll(",", "")
        : total_product,
    };
    /*     const infoCI = {
      id: parseInt(idForm),
      productive_process_form_id: id,
      total_product: total_product.includes(",")
        ? total_product.replaceAll(",", "")
        : total_product,
      qualifiedUser_id: UserId,
    }; */
    if (id === "") {
      return openAlert("Plantilla no existe", "error");
    }

    if (!isValid(id)) {
      return openAlert("Plantilla no existe", "error");
    }

    if (total_product === "") {
      return openAlert("Cantidad de producto es requerida", "error");
    }

    if (!isValid(total_product)) {
      return openAlert("Cantidad de producto es requerida", "error");
    }


    if (location === "Orden de produccion") {
      if (dataToEdit) {
        editTemplate(environment.UPDATE_PRODUCTION_ORDER, informacion, () => {
          dataToEdit(null);
          reset();
        });
        return onCloseModal();
      }
      associateTemplate(informacion, reset);
    } else if (location === "Certificado de integracion") {
      mutate(
        {
          Endpoint: idCI
            ? environment.CREATE_INTEGRATION_CERTIFICATE_TEMPLATE
            : environment.CREATE_INTEGRATION_CERTIFICATE_TEMPLATE_CIC,
          PropertyBody: "POST",
          data: informacion,
        },
        {
          //getOtherCostItemOP, getGarbageItemOP, getSupplyItemOP, getProductItemOP
          onSuccess: (data) => {
            if (data?.data?.status?.code === 400) {
              return openAlert(data?.data?.status?.message, "error");
            }

            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.PRODUCTS_OP],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.SUPPLIES_OP],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.OTHER_COST_OP],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.GARBAGE_OP],
            });

            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.PRODUCTS_CI],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.SUPPLIES_CI],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.OTHER_COST_CI],
            });
            queryClient?.invalidateQueries({
              queryKey: [queryEnv?.GARBAGE_CI],
            });
            reset();
            onCloseModal();
          },
        }
      );
      /*   addTemplateCI(infoCI, reset)
      onCloseModal() */
    }
  };

  const initialShow = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __ShowFormOrder(
        token,
        dataToGeneral.production_order_id
      );
      const info = res?.data?.status?.data;
      console.log(res?.data?.status);
      if (res?.data?.status.code) {
        setValues((values) => ({
          ...values,
          code_productive_process: info.productive_process_code,
          description_productive_process: info.productive_process_description,
          total_product: info.production_order_total_product,
          id: info.productive_process_id,
        }));
        console.log(values.id);
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [dataToGeneral?.production_order_id, token, setValues]);

  // useEffect(() => {
  //   let formData = new FormData();
  //   formData.append("search", code_productive_process);
  //   if (code_productive_process) consultaPlantilla(formData);
  // }, [code_productive_process, consultaPlantilla]);

  useEffect(() => {
    if (dataToEdit) {
      initialShow();
    } else {
      setValues((values) => ({ ...values }));
    }
  }, [dataToEdit, setValues, initialShow]);

  const habldeBlur = (e) => {
    const formData = new FormData();
    formData.append("search", code_productive_process);
    if (code_productive_process) consultaPlantilla(formData);
  };

/*   const [nat, setNat] = useState(null);
 */
  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <Box
            gap={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <LabelGroup
              name={"code_productive_process"}
              value={code_productive_process}
              onChange={handleInputChange}
              onBlur={habldeBlur}
              tag={"Código plantilla"}
              size={100}
              tipo={"text"}
              maxLength={MaxLength.productionOrder.code_productive_process}
            >
              <LabelStyledLink
                id="Nuevorol"
                to={`/product-int?movement=Proceso productivo`}
                as={NavLink}
              >
                Nueva plantilla
                <IconInModal as={HiOutlinePlus} className="ms-1" />
              </LabelStyledLink>
            </LabelGroup>
   {/*          <InputSelect
              name={"date_by"}
              size={100}
              tag={"Naturaleza de la Operación"}
              data={dataNat}
              value={nat}
              set={setNat}
            /> */}      <LabelGroup
              name={"description_productive_process"}
              value={description_productive_process || ""}
              onChange={handleInputChange}
              desicion={true}
              tag={"Descripción"}
              size={100}
              tipo={"text"}
            />
          </Box>

          <Box
            gap={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
      

            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"total_product"}
              value={total_product || ""}
              onBlur={handleBlurCant}
              //onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
              onValueChange={handleInputChange}
              tag={"Cantidad de producto"}
              size={100}
              tipo={"text"}
              maxLength={MaxLength.productionOrder.total_product}
            />
          </Box>

          <Flex className="m-3">
            <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
            <Spacer />
            <Continuar onClick={createValidate} disabled={loadingAssociate}>
              Aceptar
            </Continuar>
          </Flex>
        </>
      )}
    </>
  );
};
