import {
  Box,
  Text,
  CheckboxGroup,
  Stack,
  Checkbox,
  Skeleton
} from "@chakra-ui/react";
import "./CheckBox.css";

export const BoxCheck = ({
  title,
  Data,
  StatusCurrent,
  setStatusCurrent,
  size,
  maxW = "215px",
  ...props
}) => {
  const HandleUpdateCheckBox = (id) => {
    const NewStatusCheck = StatusCurrent.map((Checkbox) => {
      if (Checkbox.id === id) {
        return {
          ...Checkbox,
          status: !Checkbox.status
        };
      }
      return Checkbox;
    });
    setStatusCurrent(NewStatusCheck);
  };

  return (
    <Box
      className="ScrollMinimal"
      maxW={maxW}
      w={size}
      borderRadius={"5"}
      border="0.01rem solid rgba(60, 64, 67, 0.3)"
      m="3"
      p="3"
      {...props}
    >
      <Text color="gray.600">{title || <Skeleton height="20px" />}</Text>
      <CheckboxGroup colorScheme="blue">
        <Stack spacing={[1, 1]} direction={["row", "column"]} pt="2">
          {Data
            ? (
              Data.map(({ label, id }) => {
                return (
                  <Checkbox
                    onChange={(x) => HandleUpdateCheckBox(id)}
                    isDisabled={
                      !!(StatusCurrent[0]?.status === true &&
                    StatusCurrent[id]?.id !== 0)
                    }
                    isChecked={
                      StatusCurrent.length !== 0
                        ? StatusCurrent[id]?.status
                        : false
                    }
                    key={id}
                    size="md"
                  >
                    {label}
                  </Checkbox>
                );
              })
            )
            : (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}{" "}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
};
