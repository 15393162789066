import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";
import {
  __ACT_COUNT_USER,
  __DESAC_COUNT_USER,
  __SHOW_COUNT_USER,
} from "request/configuration/__CountUser";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useQueryClient } from "@tanstack/react-query";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

export const TbodyCountUser = ({ data }) => {
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const desactivarAndActivarUser = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESAC_COUNT_USER(token, data.id)
          : await __ACT_COUNT_USER(token, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const queryClient = useQueryClient();

  const handleState = async () => {
    try {
      const res = await __SHOW_COUNT_USER(token, data.id);
      const result = res.data.status.data.status;
      desactivarAndActivarUser(result);
      queryClient.invalidateQueries({ queryKey: ["userAccess"] });
    } catch (error) {}
  };

  return (
    <>
      {" "}
      <Tr>
        <Td className="text-center">{data.nickname}</Td>
        <Td className="text-center">{data.email}</Td>
        <Td className="text-center">{data.name_person}</Td>
        <Td className="text-center">{data.lastname_person}</Td>
        <TdState className="text-center" isNumeric text={data.staff_status}>
          <IconGeneric
            color={data.staff_status === 1 ? "#BA1F33" : "#bababa"}
            as={AiFillMinusCircle}
          />
        </TdState>
        <Tooltip
          placement="top"
          hasArrow
          label={data.status === 1 ? "Desactivar" : "Activar"}
        >
          <TdState
            onClick={handleState}
            className="text-center"
            isNumeric
            text={data.status}
          >
            <IconGeneric
              color={data.status === 1 ? "#03C39A" : "#bababa"}
              as={BsCheckCircleFill}
            />
          </TdState>
        </Tooltip>
        <Td isNumeric className="text-center">
          <MenuRUD data={data} deleteField={false} />
        </Td>
      </Tr>
    </>
  );
};
