import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
  Container,
  Image,
  Text,
  Icon,
  Box,
  Center,
  ModalHeader
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { useEffect, useRef, useState } from "react";
import { BiSearchAlt2, BiX } from "react-icons/bi";
import { useSelector } from "react-redux";
import { __PostJsonData } from "request/Petitions/__Post";
import imagenLogocity from "../../../../assets/logo2.png";
import { theadAdvance } from "./TableConfig/ArrayTableHead";
import TbodySearchAdvance from "./TableConfig/TbodySearchAdvance";
import { useDebounce } from "hooks/useDebouce";

export const ModalAdvancedSearch = ({ isOpen, onClose }) => {
  const Notfound = () => {
    return (
      <Container
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        centerContent
      >
        <Icon
          as={BiX}
          fontSize={"90"}
          color={"#006EB8"}
          borderRadius={"100%"}
          background={"rgba(0, 110, 184, 0.4)"}
        />
        <Text pt={"20px"} fontSize={"19px"}>
          No se encuentran resultados para tu busqueda
        </Text>
      </Container>
    );
  };
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const searchDelay = useDebounce(searchTerm, 500);

  const [openAlert] = AlertErrorAlert();

  const searchSimple = async (informacion) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("search", informacion);
    formData.append("qualifiedUser_id", "");

    try {
      const res = await __PostJsonData(
        environment.SEARCH_ADVANCED,
        token,
        formData,
        page
      );
      if (res?.data?.status?.code !== 200) {
        openAlert(res.data.status.message, "error");
      } else {
        setSearchResults(res?.data?.status?.data?.data);
        // console.log(res?.data?.status?.data?.data)
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const isFirstSearchRender = useRef(true);

  useEffect(() => {
    if (isFirstSearchRender.current) {
      isFirstSearchRender.current = false;
      return;
    }

    searchSimple(searchDelay);
  }, [searchDelay, page]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ border: "0px" }} />
        <ModalContent
          w="80%"
          h="80vh"
          maxW="none"
          maxH="none"
          borderRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
        >
          <ModalHeader></ModalHeader>

          <ModalBody overflowY="auto" maxH="100%">
            <ModalCloseButton
              _hover={{ background: "blue" }}
              _focus={{ background: "blue" }}
              border={"0"}
              color={"white"}
              borderRadius={"20px"}
              background={"#006EB8"}
              opacity={"0.5"}
            />

            <Container
              maxW={"full"}
              position={
                (searchResults.length > 0 && searchDelay.length > 0) ||
                searchResults.length < 1
                  ? ""
                  : "absolute"
              }
              top={
                (searchResults.length > 0 && searchDelay.length > 0) ||
                searchResults.length < 1
                  ? ""
                  : "50%"
              }
              left={
                (searchResults.length > 0 && searchDelay.length > 0) ||
                searchResults.length < 1
                  ? ""
                  : "50%"
              }
              transform={
                (searchResults.length > 0 && searchDelay.length > 0) ||
                searchResults.length < 1
                  ? ""
                  : "translate(-50%, -50%)"
              }
              centerContent
            >
              <Container centerContent>
                <Image
                  w={"320px"}
                  pb={6}
                  src={window.assetURL + imagenLogocity}
                  alt="Logo zona franca"
                />
              </Container>
              <InputGroup maxW={"2xl"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearchAlt2 color="#006EB8" size={"26px"} />}
                />
                <Input
                  type="text"
                  borderRadius={"20px"}
                  border={"2px"}
                  borderColor={"#9097BE"}
                  background={" #F6F9FF"}
                  _placeholder={{ color: "black" }}
                  placeholder="Buscar en EFRANCO"
                  onChange={handleChange}
                  color={"black"}
                />
              </InputGroup>
              {searchDelay.length > 0
                ? (
                  <Text color={"#006EB8"} fontSize={"18px"} padding={"40px"}>
                  Resultado de la busqueda
                  </Text>
                )
                : (
                  <></>
                )}
            </Container>
            {searchResults.length > 0 && searchDelay.length > 0
              ? (
                <Center>
                  <Box w="100%">
                    <TableOrder
                      thead={theadAdvance}
                      data={searchResults}
                      setData={setSearchResults}
                      loading={loading}
                      tbodyData={TbodySearchAdvance}
                      onClose={onClose}
                    />

                    <Pagination
                      page={page}
                      setPage={setPage}
                      maxPage={maxPage}
                      loading={loading}
                    />
                  </Box>{" "}
                </Center>
              )
              : (
                <></>
              )}
            {searchResults.length < 1 ? <Notfound /> : <></>}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
