export const updateFormField = (propertyName, name, value) => ({
  type: "UPDATE_FORM_FIELD",
  payload: { propertyName, name, value },
});

export const clearFormField = (propertyName) => {
  return {
    type: "CLEAR_FORM_FIELD",
    payload: { propertyName },
  };
};

export const updateFormSingle = (propertyName, name, value) => ({
  type: "UPDATE_FORM_SINGLE",
  payload: { propertyName, name, value },
});