import React, { useContext, useEffect, useState } from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuItems from "components/Menu/MenuItems";

import FormCrudCRUDContext from "context/FormCrudContex";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const TBodyItemCorrect = ({ data }) => {
  const { balanceCorrecedState } = useContext(FormCrudCRUDContext);

  const location = useLocation();
  const { FormEntry = "", FormOutput = "" } = queryString.parse(
    location.search
  );
  const [erroresLocal, setErroresLocal] = useState([]);

  useEffect(() => {
    if (FormEntry || FormOutput) {
      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormEntry || FormOutput}`) || "[]"
      );
      if (obtenerErrorItem) {
        setErroresLocal(obtenerErrorItem);
      }
    }
  }, [FormEntry, FormOutput]);

  return (
    <Tr
      className={
        data.status_item === 1 &&
        erroresLocal &&
        erroresLocal?.includes(data.id)
          ? "BorderCorrectTabla BorderError"
          : data.status_item === 1
            ? "BorderCorrectTabla"
            : erroresLocal && erroresLocal?.includes(data.id)
              ? "BorderError"
              : ""
      }
    >
      <Td className="text-center">{data?.item_corrected}</Td>
      <Td className="text-center">
        {data?.code_subheading_corrected
          ? data?.code_subheading_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_product_corrected ? data?.code_product_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description_product_corrected
          ? data?.description_product_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit_corrected
          ? data?.code_businessUnit_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_corrected
          ? data?.quantity_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.product_quantity_corrected
          ? data?.product_quantity_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.cif_us_value_corrected ? data?.cif_us_value_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.us_fob_value_corrected ? data?.us_fob_value_corrected : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.net_weight_corrected
          ? data?.net_weight_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.gross_weight_corrected
          ? data?.gross_weight_corrected
          : "N/A"}
      </Td>
      <Td className="text-center">
        <MenuItems
          data={data}
          deleteField={data?.status_item === 1}
          copyField={balanceCorrecedState !== 1}
        />
      </Td>
    </Tr>
  );
};

export default TBodyItemCorrect;
