/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import queryString from "query-string";
import environment from "constants/apiConst";
import FormLicenses from "../forms/FormLicenses";
import TableOrder from "components/Tables/TableOrder";
import TbodyLicencia from "../tables/TbodyLicencia";
import { TTableLicencia } from "../arrayRoles/Array_Rol";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import { __Licencia } from "request/configuration/__Roles";
import FormLicensesShow from "../FormShow/FormLicensesShow";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { __DeleteItems as __GetItemPass } from "request/Pases/__CrudPass";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { Divider, Text } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import { IconGeneric } from "styled/Icons.styled";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import queryEnv from "constants/queryConst";

export const LicenceRule = () => {
  const {
    setRequestEdit,
    setRequestCreate,
    setFormEdit,
    setFormShow,
    update,
    isOpenView,
    onOpenView,
    onCloseView,
    idViewLicencia
  } = useContext(ConfigurationCRUDContext);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);

  const formShowLicencia = () => {
    return <FormLicensesShow />;
  };

  const formLicencias = () => {
    return <FormLicenses />;
  };


  useEffect(() => {
    setFormEdit(formLicencias);
    setFormShow(formShowLicencia);
    setRequestEdit(environment.LICENCIA_UPDATE);
    setRequestCreate(environment.LICENCIA_CREATE);
  }, [])

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryEnv?.CONF_LICENCES, page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.LICENCIA_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })


  return (
    <>
      {" "}
      <ModalGeneric
        isOpen={isOpenView}
        onOpen={onOpenView}
        onClose={onCloseView}
        title=""
        size={"sm"}
      >
        <form action="">
          <div>
            <FormInputGroup>
              <LabeFormlStyled>Usuario Calificado</LabeFormlStyled>
              <InputFake>{idViewLicencia?.uc ?? "N/A"}</InputFake>
            </FormInputGroup>
          </div>
          <div>
            <FormInputGroup>
              <LabeFormlStyled>Usuarios activos</LabeFormlStyled>
              <InputFake>{idViewLicencia?.active_accounts ?? "0"}</InputFake>
            </FormInputGroup>
          </div>
          {idViewLicencia?.users?.length > 0 && (
            <div className="row">
              <div className="row">
                <Text fontSize="md" mb={3} my={1} style={{ fontWeight: 600 }}>
                  <IconGeneric width={"20px"} color={"#1F7AC3"} as={FaUsers} />{" "}Usuarios:
                </Text>

                {idViewLicencia?.users.map((x) => {
                  return (
                    <div className="col-md-6">
                      <FormInputGroup>
                        <InputFake>{x}</InputFake>
                      </FormInputGroup>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
      </ModalGeneric>
      <SectionContainerGene>
        <ConfigurationHeader title="Licencia" />

        <TableOrder
          thead={TTableLicencia}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyLicencia}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
