import { Tooltip } from '@chakra-ui/react'
import { AuthContext } from 'context/AuthContext'
import React, { useContext, useEffect } from 'react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import {
  MdContentCopy,
  MdFactCheck,
  MdOutlineEditNotifications,
  MdCancel
} from 'react-icons/md'
import { FaRegCalendarTimes, FaWpforms } from 'react-icons/fa'
import { BiTask } from 'react-icons/bi'

import { AiOutlineFileSync } from 'react-icons/ai'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import MonitoringContext from 'context/MonitoringContext'
import { BsRecordCircle } from 'react-icons/bs'

import { useNavigate } from 'react-router-dom'
import FormCrudCRUDContext from 'context/FormCrudContex'
import GlobalContext from 'context/GlobalContext'
import { useStorageQuery } from 'hooks/useStorageQuery'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

export const ShowBtnAprobado = ({
  values,
  handleEdit,
  handlePresent,
  handleDesechar,
  handleCopy,
  creardespacho,
  handleCaducar,
  setFollowOrder
}) => {
  const navigate = useNavigate()
  const { authUser } = useContext(AuthContext)
  const [openAlert] = AlertErrorAlert()
  const token = useSelector(state => state.auth.dataUser.token)
  const {    isLoadingChangeStatus } = useContext(FormCrudCRUDContext)
  const { admin, is_commerce, is_qualified_user } = useSelector(
    state => state.auth.dataUser
  )
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const { stateSRT, dispatchSRT } = useContext(GlobalContext)
  const Formtype = 1
  const data = values

  const handleNotify = async user_id => {
    const User = {
      id: user_id
    }

    try {
      const resp = await __Post(
        environment.POST_APROVED_OUTPUT_AUTHORIZE,
        token,
        User
      )
      if (resp.data.status.code === 200) {
        openAlert('El formulario ha sido notificado', 'success')
      } else {
        openAlert(resp?.data?.status?.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const tiempoTranscurrido = desde => {
    const fechaDesde = new Date(desde)
    const fechaActual = new Date()
    const diferenciaEnMilisegundos = fechaActual - fechaDesde
    const horasTranscurridas = Math.floor(
      diferenciaEnMilisegundos / (1000 * 60 * 60)
    )

    return horasTranscurridas
  }

  const VerifiedRadicar = () => {
    console.log({ use_dian_profiling: data?.use_dian_profiling })
    if (
      data?.status_outputForm === 'APROBADO' &&
      data?.use_dian_profiling === 1
    ) {
      const lastElement = data?.users[data?.users?.length - 1]

      if (lastElement) {
        const fechaInicial = `${lastElement.date_outputForm} ${lastElement.time_outputForm}`
        const TimeTranscurrido = tiempoTranscurrido(fechaInicial)
        const { profiling_deal_type_form, profiling_status } = data

        const ProfileBool =
          profiling_deal_type_form === null || profiling_deal_type_form === ''

        if (
          (TimeTranscurrido <= 0 && ProfileBool) ||
          (TimeTranscurrido <= 0 &&
            profiling_deal_type_form === 'Si' &&
            profiling_status !== 'Finalizado' &&
            TimeTranscurrido > 0)
        ) {
          openAlert(
            'El documento se encuentra en espera de revisión por DIAN',
            'error'
          )
        }

        if (
          profiling_deal_type_form === 'Si' &&
          profiling_status !== 'Finalizado'
        ) {
          openAlert('Documento se encuentra perfilado por DIAN', 'error')
        }

        if (
          profiling_deal_type_form === 'No' ||
          profiling_status === 'Finalizado' ||
          TimeTranscurrido > 0
        ) {
          return handlePresent('AUTORIZADO')
        }

        if (
          (TimeTranscurrido > 0 && ProfileBool) ||
          profiling_status === 'Finalizado'
        ) {
          return handlePresent('AUTORIZADO')
        }
      }
    } else {
      handlePresent('AUTORIZADO')
    }
  }

  const goToFormatSRT = () => {
    navigate(`/FormatSRT?ExitNumber=${data?.id}&FormType=${'Salida'}`)
  }

  const { permissionList } = useSelector(state => state.permissions)
  const {
    changeStatus: changeStatusOutputForm,
    copy: copyOutputForm,
    createPass: createDispatchPass
  } = localPermissions.Outputform
  return (
    <>
      {(is_commerce === 1 || admin === 1) &&
        !is_qualified_user &&
        (data?.code_operation === 408 ||
          data?.code_operation === 409 ||
          data?.code_operation === 310 ||
          data?.code_operation === 309) && (
          <Tooltip placement='top' hasArrow label='Formato SRT'>
            <span>
              <ButtonHeaderModule onClick={goToFormatSRT} bgColor='#191830'>
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={FaWpforms}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(is_commerce === 1 || admin === 1) && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='#B8DB1A'
              onClick={onOpenCreateTrackingFmm}
              className='mx-1'
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || admin === 1) && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='red'
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
            >
              <IconGeneric
                width='16px'
                as={MdCancel}
                color='#fff'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, copyOutputForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule
              onClick={handleCopy}
              className='mx-1'
              bgColor='#03C39A'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, createDispatchPass) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Despachar'>
          <span>
            <ButtonHeaderModule onClick={creardespacho} bgColor='#BABABA'>
              <IconGeneric width='16px' className='text-white' as={BiTask} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, changeStatusOutputForm) ||
          authUser.admin === 1) && (
          <>
            <Tooltip placement='top' hasArrow label='Radicar'>
              <span>
                <ButtonHeaderModule
                  onClick={() => VerifiedRadicar()}
                  className='mx-1'
                  disabled={isLoadingChangeStatus}
                  // bgColor="#d92525"
                >
                  <IconGeneric
                    width='16px'
                    className='text-white'
                    as={MdFactCheck}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            {(hasCommonElements(permissionList, changeStatusOutputForm) ||
              authUser.admin === 1) && (
              <Tooltip placement='top' hasArrow label='Devolver'>
                <span>
                  <ButtonHeaderModule
                    onClick={() => handlePresent('DEVUELTO')}
                    bgColor='#d92525'
                  >
                    <IconGeneric
                      width='16px'
                      className='text-white'
                      as={AiOutlineFileSync}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

            {(hasCommonElements(permissionList, changeStatusOutputForm) ||
              authUser.admin === 1) && (
              <Tooltip placement='top' hasArrow label='Caducar'>
                <span>
                  <ButtonHeaderModule
                    onClick={handleCaducar}
                    className='mx-1'
                    bgColor='#F9A621'
                  >
                    <IconGeneric
                      width='16px'
                      className='text-white'
                      as={FaRegCalendarTimes}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}
          </>
        )}
      {/* <Tooltip placement="top" hasArrow label=" Notificar para Autorizar">
        <span>
          <ButtonHeaderModule
            onClick={() => handleNotify(values.id)}
            bgColor="#F1C40F"
          >
            <IconGeneric
              width="16px"
              className="text-white"
              as={MdOutlineEditNotifications}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip> */}
    </>
  )
}
