/* eslint-disable no-unused-vars */
import {
  Button,
  Radio,
  Td,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import { useCallback, useContext, useState } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill, BsCheckAll } from "react-icons/bs";
import { AiFillClockCircle } from "react-icons/ai";
import { FaRegTimesCircle } from "react-icons/fa";
import styled from "styled-components/macro";
import { AuthContext } from "context/AuthContext";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import {
  __ACTI_USER_QUALIFIED,
  __ACT_USER_QUALIFIED,
  __DESACTI_USER_QUALIFIED,
  __DESACT_USER_QUALIFIED,
  __ShowQualifiedUser
} from "request/configuration/__QualifiedUsers";
import Swal from "sweetalert2";
import "../Form/styleForUsuario.css";
import { useSelector } from "react-redux";
import { formaterCant } from "components/Funciones/formaterCant";
import TableOrder from "./TableOrder";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import Pagination from "components/Pagination/Pagination";
import { useGlobalContext } from "context/GlobalContext";
// import { CgLayoutGrid } from "react-icons/cg";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#FECE00")};
`;

const TbodyUserConfig = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const { setUpdate, update } = useContext(ConfigurationCRUDContext);
  const { collectionRatesUserConfig } = useGlobalContext();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [info, setInfo] = useState();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);

  const handleState = async () => {
    try {
      const res = await __ShowQualifiedUser(token, data.id);
      const status = res.data.status.data.status_qualified;
      console.log("Resutado tabla", status);

      if (authUser.admin === 1 && (status === 2 || status === 1)) {
        Swal.fire({
          title: `¿Estas seguro de desactivar el usuario calificado ${data?.name_qualified}?`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#1F7AC3",
          confirmButtonText: "Desactivar",
          customClass: {
            title: "class-text"
          }
        }).then((resultado) => {
          if (resultado.isConfirmed) {
            const desactivarUserQualified = async () => {
              try {
                const resultado = await __DESACT_USER_QUALIFIED(token, data.id);
                setUpdate(!update);
                console.log(resultado);
              } catch (error) {
                console.log(error);
              }
            };
            desactivarUserQualified();
          }
        });
      } else if (authUser.admin === 1 && status === 0) {
        Swal.fire({
          title: `¿Estas seguro de activar el usuario calificado ${data?.name_qualified}?`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#1F7AC3",
          confirmButtonText: "Activar",
          customClass: {
            title: "class-text"
          }
        }).then((resultado) => {
          if (resultado.isConfirmed) {
            const activarUserQualified = async () => {
              try {
                const resultado = await __ACT_USER_QUALIFIED(token, data.id);
                console.log(resultado);
                setUpdate(!update);
              } catch (error) {
                console.log(error);
              }
            };
            activarUserQualified();
          }
        });
      } else if (authUser.is_commerce === 1 && status === 1) {
        Swal.fire({
          title: `¿Estas seguro de desactivar el usuario calificado ${data?.name_qualified}?`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#1F7AC3",
          confirmButtonText: "Desactivar",
          customClass: {
            title: "class-text"
          }
        }).then((resultado) => {
          if (resultado.isConfirmed) {
            const activarUserQualifi = async () => {
              try {
                const resultado = await __DESACTI_USER_QUALIFIED(
                  token,
                  data.id
                );
                console.log(resultado);
                setUpdate(!update);
              } catch (error) {
                console.log(error);
              }
            };
            activarUserQualifi();
          }
        });
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          return formData.append(
            `tracking_id[${index}]`,
            trackings?.tracking_id
          );
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr>
        <Td className="text-center">{data?.nit_qualified}</Td>
        <Td className="text-center">{data?.name_qualified}</Td>
        <Td className="text-center">{data?.nickname_qualified}</Td>
        <Td className="text-center">
          {data?.qualificationQualifiedUser_id === 1
            ? "USUARIO"
            : data?.qualificationQualifiedUser_id === 2
              ? "OPERADOR"
              : "ENTIDAD APOYO"}
        </Td>
        <Td className="text-center">{data?.license_id[0]?.name_license}</Td>
        <Td className="text-center">
          {data?.license_id[0]?.expireDate_license}
        </Td>
        <Td className="text-center">
          {collectionRatesUserConfig?.[data?.collect]}
        </Td>
        <Td className="text-center">{formaterCant(data?.document_balance)}</Td>
        <Td className="text-center">{formaterCant(data?.scale_balance)}</Td>

        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.block_status === null || data?.block_status === 0
              ? "Activo"
              : "Bloqueado"
          }
        >
          <TdState
            // onClick={handleState}
            className="text-center"
            isNumeric
            text={data?.block_status}
          >
            {data?.block_status === null || data?.block_status === 0
              ? (
                <IconGeneric color={"#03C39A"} as={BsCheckAll} />
              )
              : (
                <IconGeneric color={"#d92525"} as={FaRegTimesCircle} />
              )}
          </TdState>
        </Tooltip>

        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.tracking?.some((tracking) => tracking.status === 1)
              ? "En seguimiento"
              : data?.tracking?.every((tracking) => tracking.status === 2)
                ? "Estuvo en seguimiento"
                : ""
          }
        >
          <Td>
            {data?.tracking?.length && (
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Radio
                  defaultChecked
                  onClick={() => showTrackingItem(data?.tracking)}
                  colorScheme={
                    data?.tracking?.some((tracking) => tracking?.status === 1)
                      ? "red"
                      : "green"
                  }
                />
              </span>
            )}
          </Td>
        </Tooltip>

        <Tooltip
          hasArrow
          placement="top"
          label={
            data.status_qualified === 1 || data.status_qualified === 2
              ? "Desactivar"
              : "Activar"
          }
        >
          <TdState
            onClick={handleState}
            className="text-center"
            isNumeric
            text={data?.status_qualified}
          >
            {data.status_qualified === 2
              ? (
                <IconGeneric color={"#FECE00"} as={AiFillClockCircle} />
              )
              : data.status_qualified === 1
                ? (
                  <IconGeneric color={"#03C39A"} as={BsCheckCircleFill} />
                )
                : data.status_qualified === 0
                  ? (
                    <IconGeneric color={"#bababa"} as={BsCheckCircleFill} />
                  )
                  : (
                    <IconGeneric color={"#FF8000"} as={BsCheckCircleFill} />
                  )}
          </TdState>
        </Tooltip>
        <Td isNumeric className="text-center">
          <MenuRUD data={data} deleteField={false} licenses={true} />
        </Td>
      </Tr>
    </>
  );
};

export default TbodyUserConfig;
