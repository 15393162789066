import React from "react";

export const PDFFromUserToUser = ({ data }) => {
  const { num_exit_form_corrected, name_qualified4 } = data;
  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Nit: </strong>
          {name_qualified4}
        </p>
      </div>

      <div className="col">
        <p>
          <strong>Nº Formulario de salida: </strong>
          {num_exit_form_corrected}
        </p>
      </div>
    </div>
  );
};
