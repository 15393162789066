import { useEffect, useState } from "react";
import { QueryObserver, useQueryClient } from "@tanstack/react-query";

export const useGetUsersObserver = ({ key }) => {
  const queryClient = useQueryClient();

  const [data, setData] = useState(() => {
    const data = queryClient.getQueryData([key]);
    return data ?? [];
  });

  useEffect(() => {
    const observer = new QueryObserver(queryClient, { queryKey: [key] });

    const unsubscribe = observer.subscribe((result) => {
      if (result.data) setData(result.data);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    data,
  };
};
