/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { __Show_Packaging } from "../../../request/configuration/__Packaging";
import { Box, Divider, Switch, Text } from "@chakra-ui/react";
import { LabelGroup } from "../../../pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabeFormlStyled } from "styled/Input.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const PackagingForm = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    name_packaging: "",
    code_packaging: "",
    status_packaging: 1
  });

  const [check, setCheck] = useState(true);
  const [openAlert] = AlertErrorAlert();

  const { name_packaging, code_packaging, status_packaging } = values;

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const edit = async () => {
    try {
      const res = await __Show_Packaging(token, dataToEdit.id);
     // console.log(res?.data?.status?.data);
      setValues(res.data?.status?.data);
      setCheck(res.data.status.data?.status_packaging);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_packaging: status_packaging ? 1 : 0
      };
      console.log(data);
      editData(data);
    } else {
      if (name_packaging === "" || code_packaging === "") {
        openAlert("No puede enviar campos vacios", "error");
      } else {
        createData(values);
      }
    }
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Ciudad " : "Crear Ciudad"}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            name={"name_packaging"}
            tag={"Nombre Embalaje"}
            size={45}
            value={name_packaging}
            onChange={handleInputChange}
            desicion={false}
            maxLength={50}
          />
          <LabelGroup
            name={"code_packaging"}
            tag={"Código Embalaje"}
            size={45}
            value={code_packaging}
            onChange={handleInputChange}
            desicion={false}
            maxLength={3}
          />
        </div>

        <div>
          <LabeFormlStyled htmlFor="status_packaging">Activado</LabeFormlStyled>
          <div>
            <Switch
              name="status_packaging"
              id="status_packaging"
              size="lg"
              value={status_packaging}
              onChange={changeCheck}
              isChecked={check}
            />
          </div>
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default PackagingForm;
