import styled from "styled-components";

export const InputSelect = styled.input.attrs((props) => ({
  type: "select",
  size: props.size || "1em"
}))`
  width: 100%;
  height: auto;
  background-color: #ffffff87;
  border: none;
  outline: none;
  border-bottom: 1px solid #1f7ac3;
  font-size: 1rem;
  display: inline-block;
`;
