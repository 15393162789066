import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Td, Tr } from '@chakra-ui/react'
import GlobalContext from 'context/GlobalContext'

const TbodyDispatchCorrected = ({ data, onClose = null }) => {
  const { setTargetData } = useContext(GlobalContext)

  const newItemData = {
    outputFormItemCorrected_id: data?.outputFormItem_id,
    quantity_go_out_corrected: 0,
    quantity_go_out_product_corrected: 0,
    item_output_corrected: data?.item,

    referenc: data?.reference,
    reference_corrected: data?.reference,

    code_product: data?.code_product,
    code_product_corrected: data?.code_product,

    quantity_dispatch_product: 0,
    quantity_dispatch_product_corrected: 0,

    quantity_dispatch: 0,
    quantity_dispatch_corrected: 0,

    code_businessUnit: data?.code_unit_measurement,
    code_businessUnit_corrected: data?.code_unit_measurement,

    output_form_item_description: data?.output_form_item_description,
    output_form_item_description_corrected: data?.output_form_item_description,

    /*     code_unit_measurement: data?.description_product,
    code_unit_measurement_corrected: data?.description_product, */

    description_product: data?.description_product,
    description_product_corrected: data?.description_product,

    code_outputForm_corrected: data?.code_outputForm,

    item_corrected: data?.item,
    item: data?.item
  }

  const handleSearchItem = () => {
    setTargetData(newItemData)
    if (onClose) onClose()
  }

  return (
    <Tr onClick={handleSearchItem}>
      <Td className='text-center'>{data?.code_outputForm ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.item ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.reference ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.custom_clearance ?? ''}</Td>
      <Td className='text-center'>{data?.code_product ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.description ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.code_businessUnit ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.quantity_go_out ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.code_unit_measurement ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.quantity_go_out_product ?? 'N/A'}</Td>
    </Tr>
  )
}

TbodyDispatchCorrected.propTypes = {
  data: PropTypes.object
}

export default TbodyDispatchCorrected
