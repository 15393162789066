import { Button } from "@chakra-ui/react";
import TableOrder from "components/Tables/TableOrder";
import { ModalGeneric } from "components/modal/ModalGeneric";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { theadLock } from "pages/IntegrationBox/Arrays/ArrayTitles";
import FormModalStatusDelete from "pages/IntegrationBox/Form/FormModalStatusDelete";
import TbodyLock from "pages/IntegrationBox/Table/TbodyLock";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Modals = ({ lock, setLock, loading, urlCopy = null }) => {
  const location = useLocation();
  const { id = "" } = queryString.parse(location.search);

  const handleAnular = (status) => {
    const dataAnular = new FormData();
    dataAnular.append("id", id);
    dataAnular.append("status", status);
    changeStatus(dataAnular);
    onCloseModal();
  };

  const {
    isOpenDeleteBox,
    onCloseModal,
    changeStatus,
    copyData,
    isOpenCopyBox,
    isOpenLog,
    isOpenAnular,
    form
  } = useContext(IntegracionBoxCrudContex);

  const statusDataCopy = new FormData();
  statusDataCopy.append("id", id);

  return (
    <>
      <FormModalStatusDelete
        status={"DESECHAR"}
        redirection={true}
        routeToDrive={`/TabletIntegration`}
        nameDocument={"Certificado de integración"}
        numberOfDocument={form?.integration_certificate_code}
        id={id}
        isOpen={isOpenDeleteBox}
        onClose={onCloseModal}
        functionChangeStatus={changeStatus}

      />

      <FormModalStatusDelete
        status={"COPIAR"}
        nameDocument={"Certificado de integración"}
        numberOfDocument={form?.integration_certificate_code}
        isOpen={isOpenCopyBox}
        data={statusDataCopy}
        onClose={onCloseModal}
        copy={true}
        functionChangeStatus={copyData}
        endPoint={urlCopy}
      />

      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular el certificado de integración Nº ${form?.integration_certificate_code}? 
        <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario`}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: "#41495A", color: "white" }}
            onClick={() => handleAnular("ANULADO")}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric isOpen={isOpenLog} onClose={onCloseModal} size={"6xl"}>
        <TableOrder
          thead={theadLock}
          data={lock}
          setData={setLock}
          loading={loading}
          tbodyData={TbodyLock}
        />
      </ModalGeneric>
    </>
  );
};

export default Modals;
