/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, SimpleGrid, Spacer } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import queryEnv from "constants/queryConst";
import { useGlobalContext } from "context/GlobalContext";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __Dependency_Not_Paginate } from "request/configuration/__Dependency";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";

import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

/**
 * FormsearchAdvance- Componente donde se encuentra formulario para realizar bloqueos de dependencias
 *
 * @component
 * @param {Array} setSearchAdvanced Array donde se envia la informacion para la busqueda avazada por parametros .
 * @param {Function}  onClose  funcion para cerrar el modal de busqueda avanzada.
 * @return {Component} Retorna formulario para crear y editar bloqueos de dependencias.
 */

const FormsearchAdvance = ({ setSearchAdvanced, onClose }) => {
  const { state, cleanState, setParamsSearch } = useSearchUrl({
    getParams: ["dependency", "status", "date1", "date2", "lock_type", "statusInfo",
      "dependencyInfo", "lock", "userQualified"], nestedProp: "searchAdvance"
  });
  const { state: stateSearchSimple } = useSearchUrl({
    getParams: ["valueQualified"], nestedProp: "searchSimple"
  });
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dependencyInfo, setDependencyinfo] = useState(state?.dependencyInfo);
  const [statusInfo, setStatusinfo] = useState(state?.statusInfo);
  const [lock, setLock] = useState(state?.lock);
  const [userQualified, setUserQualified] = useState(state?.userQualified || stateSearchSimple?.valueQualified);

  const [values, handleInputChange, reset, setValues] = useForm({
    dependency: state?.dependency,
    status: state?.status,
    date1: state?.date1,
    date2: state?.date2,
    lock_type: state?.lock_type,
  });

  const { date1, date2 } = values;



  const estado = [
    { value: 1, label: "Bloqueado" },
    { value: 0, label: "Desbloqueado" }
  ];

  const lockType = [
    { value: "Ingreso", label: "Ingreso" },
    { value: "Salida", label: "Salida" }
  ];

  const { requestData } = useGlobalContext();


  const { isLoading: isLoadingUC, data: dataUC } = useQuery({
    queryKey: [queryEnv.QUALIFIED_USERS],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
    })
  })


  const { isLoading: isLodingDependency, data: dataDep } = useQuery({
    queryKey: [queryEnv.DEPENDENCIES_NO_PAGINATE],
    queryFn: () => requestData({
      Endpoint: environment.DEPENDENCY_NOT_PAGINATE,
    })
  })

  const handleSubmit = () => {
    const data = {
      ...values,
      status: statusInfo !== null ? statusInfo.value : "",
      dependency: dependencyInfo !== null ? dependencyInfo.value : "",
      lock_type: lock !== null ? lock.value : "",
      qualifiedUser_id: userQualified !== null ? userQualified.value : ""
    };

    setParamsSearch({
      params: handleSanitizedObj({
        ...data,
        statusInfo,
        dependencyInfo,
        lock,
        userQualified,
      })
    });
    onClose();
  };

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-2">
        <div>
          <InputSelect
            tag={"Usuario Calificado"}
            size={100}
            data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
              value: item?.id,
              label: item?.nit_qualified + " - " + item?.name_qualified,
              ...item,
            })) : []}
            set={setUserQualified}
            value={userQualified === null ? "" : userQualified}
            isLoading={isLoadingUC}
          />
        </div>
        <div>
          <InputSelect
            name={"dependency"}
            tag={"Dependencia"}
            data={!isLoadingUC ? dataDep?.data?.status?.data?.map((item) => ({
              value: item?.dependency,
              label: item?.dependency
            })) : []}
            set={setDependencyinfo}
            value={dependencyInfo}
            isLoading={isLodingDependency}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <div>
          <InputSelect
            tag={"Tipo de Bloqueo"}
            size={100}
            data={lockType}
            set={setLock}
            value={lock}
          />
        </div>

        <div>
          <InputSelect
            name={"dependency"}
            tag={"Estado"}
            data={estado}
            set={setStatusinfo}
            value={statusInfo}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-2">
        <LabelGroup
          tipo={"date"}
          name={"date1"}
          onChange={handleInputChange}
          value={date1}
          tag={"Fecha inicial"}
          size={100}
          desicion={false}
        />

        <LabelGroup
          tipo={"date"}
          name={"date2"}
          onChange={handleInputChange}
          value={date2}
          tag={"Fecha final"}
          size={100}
          desicion={false}
        />
      </SimpleGrid>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormsearchAdvance;
