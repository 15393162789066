/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Box, Flex, Spacer, Stack, Tooltip } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { MdOutlineSearch, MdUploadFile } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import SearchForm from "components/SearchForm/SearchForm";
import ProductCRUDContext from "context/ProductCRUDContext";
import { AuthContext } from "context/AuthContext";
import { Title } from "styled/Title";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { useModal } from "hooks/useModal";
import { ButtonHeaderModule } from "styled/Buttons.styled";

const ConfigurationTitle = styled.h4`
  color: #1f7ac3;
  text-transform: uppercase;
  font-weight: 600;
  display: inline;
`;

const RigthSide = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChildrenLeft = styled(Box)`
  margin-left: 3rem;
`;

const HeaderUnit = ({
  title,
  activeSeach = true,
  onOpenLoadExcel,
  exportOn,
  children
}) => {
  const [buttonChange, setButtonChange] = useState(false);
  const { onOpenEdit } = useContext(ProductCRUDContext);
  const { authUser } = useContext(AuthContext);

  const handleButtonChange = (e) => {
    setButtonChange(!buttonChange);
  };

  const [isOpenExport, onOpenExport, onCloseExport] = useModal();

  return (
    <Flex>
      {" "}
      <LeftSide>
        <Title>{title}</Title>
        <ChildrenLeft>{children}</ChildrenLeft>
      </LeftSide>
      <Spacer />
      <Stack spacing={1} direction="row" align="center">
        {activeSeach && (
          <>
            {!buttonChange
              ? (
                <>
                  <IconGeneric
                    as={MdOutlineSearch}
                    color="#bababa"
                    margin="0px 1rem 0px 0px"
                    onClick={handleButtonChange}
                  />
                </>
              )
              : (
                <SearchForm handleButtonChange={handleButtonChange} />
              )}
          </>
        )}

        {!authUser.admin
          ? (
            <>
              <Tooltip placement="top" hasArrow label="Agregar">
                <span>
                  <ButtonHeaderModule
                    onClick={onOpenEdit}
                    bgColor="#1F7AC3"
                    className="me-4 align-bottom d-flex float-end"
                  >
                    <IconGeneric as={HiOutlinePlus} color="#fff" />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            </>
          )
          : (
            ""
          )}
        <Tooltip placement="top" hasArrow label="Cargar Excel">
          <ButtonHeaderModule
            onClick={onOpenLoadExcel}
            bgColor="#1F7AC3"
            className="align-bottom d-flex float-end"
          >
            <IconGeneric color="#fff" as={MdUploadFile} />
          </ButtonHeaderModule>
        </Tooltip>
        <ExportDetail onOpen={exportOn} />
      </Stack>
    </Flex>
  );
};

export default HeaderUnit;
