/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable multiline-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Pagination from 'components/Pagination/Pagination'
import TableOrder from 'components/Tables/TableOrder'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { useSelector } from 'react-redux'
import { TitleProfile } from '../../ArrayTitleDian/ArrayTitle'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { useModal } from 'hooks/useModal'
import { SearchAdvancedModule } from 'components/SearchSimpleAdvanced/SearchAdvancedModule'
import { ExportDetail } from 'pages/CustomsClearance/Adjunto/ExportDetail'
import { useForm } from 'hooks/useForm'
import {
  __PostForm,
  __PostFormData,
  __PostJsonData
} from '../../../../request/Petitions/__Post'
import environment from 'constants/apiConst'
import TbodyProfile from 'pages/Dian/Table/TbodyProfile'
import FormSearchProfile from 'pages/Dian/form/FormSearchProfile'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import DianCRUDContext from 'context/DianCrudContext'
import './StileItems.css'
import { useSearchUrl } from 'hooks/useSearchUrl'

const ProfileDocumentView = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["form_code", "operation", "document_type", "valueTypeForm"], nestedProp: "searchAdvance" });


  const [currentPage, setCurrentPage] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [maxPage, setMaxPage] = useState(1)
  const token = useSelector(state => state.auth.dataUser.token)
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal()
  const [isOpenExport, onOpenExport, onCloseExport] = useModal()
  const [changeViewSearch, setChangeViewSearch] = useState(false)
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState)
  const [pageSearch, setPageSearch] = useState(1)
  const [maxPageSearch, setMaxPageSearch] = useState(1)
  const { user_rol, admin } = useSelector(state => state.auth.dataUser)
  const [allAccess, setAllAccess] = useState(false)
  const [myPermission, setmyPermission] = useState(null)
  const [openAlert] = AlertErrorAlert()
  const { freeZoneValue } = useSelector(state => state.chagenFreeZone)

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search,
    valueQualified: state?.valueQualified,
  })

  const { update } = useContext(DianCRUDContext)

  const { searchInformacion, valueQualified } = values

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] })
    reset()
    setSearchAdvanced({})
    setChangeViewSearch(false)
  }

  const GetIntegration = useCallback(async () => {
    setLoading(true)
    try {
      const res = await __PostForm(environment.DOCUMENTS_PROFILE_GET, token)
      const requestData = res?.data?.status?.data.data
      if (requestData.length !== 0) {
        setCurrentPage(requestData)
      } else {
        setCurrentPage([])
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [token])

  useEffect(() => {
    GetIntegration()
  }, [GetIntegration, update, freeZoneValue])

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true)
    } else if (!allAccess) {
      let newArray = []
      user_rol?.modules?.map(item => {
        return item.permission.map(e => newArray.push(e.name_permission))
      })
      setmyPermission(newArray)
    }
  }, [user_rol, allAccess, admin, freeZoneValue])

  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified && { qualified_user_id: valueQualified?.value }),
    }

    if (Object.keys(data).length !== 0) {
      setLoading(true)
      setChangeViewSearch(true)
      searchSimple(data)
      return;
    }

    setChangeViewSearch(false)
    GetIntegration()

  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZoneValue])

  const searchSimple = async q => {
    setLoading(true)
    try {
      const res = await __PostJsonData(
        environment.DOCUMENTS_PROFILE_GET,
        token,
        q,
        page
      )
      if (res?.data?.status?.message === 'Acceso no autorizado.') {
        reset()
        setSearchAdvanced({})
        setChangeViewSearch(false)
        openAlert(res.data.status.message, 'error')
      } else {
        setCurrentPage(res?.data?.status?.data?.data)
        setMaxPage(res?.data?.status?.data?.last_page)
        console.log(res?.data?.status)
      }
    } catch (error) {
    } finally {
      setLoading(false)
      onCloseSearch()
    }
  }

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearch}
        onClose={onCloseSearch}
        title={'Búsqueda avanzada'}
      >
        <FormSearchProfile
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <HeaderSearch>
        <div>
          <SearchAdvancedModule
            handleInputChange={handleInputChange}
            values={values}
            onOpenSearch={onOpenSearch}
            changeViewSearch={changeViewSearch}
            backSearch={backSearch}
            allAccess={allAccess}
            myPermission={myPermission}
            setValues={setValues}
          />
        </div>

        {changeViewSearch ? (
          currentPage !== undefined && (
            <div className='px-5'>
              <div>
                <TableOrder
                  thead={TitleProfile}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyProfile}
                />
                <Pagination
                  page={pageSearch}
                  setPage={setPageSearch}
                  maxPage={maxPageSearch}
                  loading={loading}
                />
              </div>
            </div>
          )
        ) : (
          <div>
            <div>
              <TableOrder
                thead={TitleProfile}
                data={currentPage}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodyProfile}
                className={'verticalNone'}
              />

              <Pagination
                page={pageSearch}
                setPage={setPageSearch}
                maxPage={maxPageSearch}
                loading={loading}
              />
            </div>
          </div>
        )}
      </HeaderSearch>
    </>
  )
}

export default ProfileDocumentView
