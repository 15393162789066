/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Box, Text } from '@chakra-ui/react'
import { ButtonStyled } from 'styled/Buttons.styled'
import '../StyleFormInt.css'
import { DataCompanyExit } from './DataCompanyExit/DataCompanyExit'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { useSelector } from 'react-redux'
import { __OperationListExit } from 'request/configuration/__Operations'
import { ContainerFormExit } from './ContainerFormExit'
import { useForm } from 'hooks/useForm'

import FormCrudCRUDContext from 'context/FormCrudContex'
import DocumentUploader from '../FormEntry/DocumentUploader/DocumentUploader'
import Swal from 'sweetalert2'
import environment from 'constants/apiConst'
import { __ShowForm } from 'request/Forms/__ShowForm'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { ItemsFMM } from '../View/ItemsFMM'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Vapor from 'laravel-vapor'
import { AuthContext } from 'context/AuthContext'
import { AttchmentTypiFied } from 'components/attchmentTypiFied/AttchmentTypiFied'
import {
  RadioCheck,
  RadioCheckDuta
} from 'components/ComponentRadioButton/RadioCheck'
import { ContainerTitleOutput } from './ContainerTitleOutput'
import { __AllQualifiedUSers } from 'request/configuration/__QualifiedUsers'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __SearchUser } from 'request/Reviews/__GetTReviews'
import { __Post } from 'request/Petitions/__Post'
import GlobalContext, { useGlobalContext } from 'context/GlobalContext'
import { useNotations } from 'context/NotationsContext'
import isArrayNotEmpty, {
  arrayToString,
  isArray,
  isNonEmptyString,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
  mergeUniqueElements
} from 'utils/type-check-utils'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { GroupInputUpload } from '../GroupInputUpload'
import { useConfirmationModal } from 'hooks/useConfirmationModal'
import { Storage } from 'hooks/useStorage'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { setDataStatusBalance } from 'redux/actions/statusBalanceaction'
import { changeForms } from 'redux/actions/FormsActions'
import EnhancedFileUploadManager from 'pages/CustomsClearance/MultipleFileUploader'
import ExistingFiles from 'pages/CustomsClearance/Adjunto/ExistingFiles'
import { convertArrayToGroupedObject } from '../FormEntry/BodyForm'
import { handleError } from 'utils/handleError'
import { handleValidationError } from 'utils/handleValidationError'

/**
 * BodyFormExit - el usuario registra su formulario de salida y dependiendo de la operacion cambian algunos campos del formulario secundario.
 *
 * @component
 * @return {Component} Retorna creacion formularios de salida.
 */

const errorsDefaultState = {
  opertationId: false
}

export function createKey (documentReqId, doc_corrected_req = null) {
  if (isNumber(doc_corrected_req) && doc_corrected_req === 1) {
    return `document-${documentReqId}_doc_corrected_req`
  }

  return 'document-' + documentReqId
}

export function createObjectFromItem (item, index) {
  return {
    name: item.attachment_name,
    document_id: item.documentReq_id,
    downloadFile: item.attachment_url,
    from_server: true,
    id: item.id,
    originalIndex: index,
    attachment_number: item.attachment_number,
    attachment_description: item.attachment_description,
    attachment_comment: item.attachment_comment,
    attachment_date: item.attachment_date,
    ...(item?.doc_corrected_req && { doc_corrected_req: item?.documentReq_id })
  }
}

const BodyFormExit = () => {
  const { authUser } = useContext(AuthContext)

  const [errorsState, setErrorsState] = useState(errorsDefaultState)

  const token = useSelector(state => state.auth.dataUser.token)
  const userQualifiedId = useSelector(
    state => state.auth.dataUser.userQualified
  )

  const {
    DocumentOperation,
    setRequestCreate,
    dataToEdit,
    setRequestEdit,
    setDocumentOperation,
    setRequestCreateItem,
    deleteAttachment,
    setDataToEdit,
    setOpcional,
    opcional,
    dataDUTA,
    setDecimalUcContext,
    update,
    addresDestine,
    customsadministration,
    setCustomsadministration,
    setDataFormCurrent,
    currentItemLength,
    setCurrentItemLength,
    setDataToShow,
    setUpdate
  } = useContext(FormCrudCRUDContext)

  const [loading, setLoading] = useState(false)
  const location = useLocation() // navigate

  const [FormStatus, setFormStatus] = useState(null)

  const { FormExitNumber = '' } = queryString.parse(location.search)
  // Data select operacion
  const [selectItemForm, setSelectItemForm] = useState('')
  const [selectIdOp, setSelectIdOp] = useState('')
  const [dataOperation, setDataOperation] = useState([])
  const [nameOperation, setNameOperation] = useState('')

  //  Data Selet User Qualified from user to user
  const [userQualified, setUserQuialified] = useState([])
  const [valueQualified, setValueQuialified] = useState('')
  const [nameQualified, setNameQualified] = useState('')

  // Data del select de los Terceros

  // Resultado Select Third
  const [valueThird, setValueThird] = useState([])

  // Radio Button
  const [only, setOnly] = useState('Único')
  const [refund, setRefund] = useState('Sin reintegro')
  const [radioRequired, setRadioRequired] = useState('')

  // agregar item
  const [itemss, setItemss] = useState(false)
  const [nameFiles, setNameFiles] = useState([])
  const [files, setFiles] = useState({})
  const [attachment, setAttachment] = useState([])
  const [attachmentType, setAttachmentType] = useState([])
  const [Reviews, setReviews] = useState({})

  // plazo
  const [plazo, setPlazo] = useState(null)
  const navigate = useNavigate()
  const [openAlert] = AlertErrorAlert()
  const { formOperation } = useContext(GlobalContext)
  const [valueQualifiedToUser, setValueQuialifiedToUser] = useState([])
  const [attachmentId, setAttachmentId] = useState([])
  const [attachmentDescription, setAttachmentDescription] = useState([])
  const [attachmentNumber, setAttachmentNumber] = useState([])
  const [otherDocuments, setOtherDocuments] = useState([])
  const [isUploadActive, setIsUploadActive] = useState(false)
  const { requestData } = useGlobalContext()

  const fieldAllowed = [
    'purchase_order',
    'commercial_invoice',
    'value_duta',
    'date',
    'term',
    'destination_address',
    'operation_id',
    'thirdPartie_id',
    'shipment',
    'qualifiedUser_id',
    'pass_required',
    'nitQualifiedUser_id',
    'modality',
    'status_outputForm',
    'customs_administration',
    'commercial_invoice',
    'certificate_inspection_pre_boarding',
    'dcl_customs_transit'
  ]

  const onFieldChange = (name, newValue, target) => {
    const { oldValue, itemId, inputTag } = target?.dataset
    const Allowed = fieldAllowed.includes(name)
    if (Allowed) {
      return updateNotation(name, inputTag, newValue, oldValue, itemId)
    }
  }

  const [values, handleInputChange, reset, setValues] = useForm(
    {
      commercial_invoice: '',
      date: '',
      certificate_inspection_pre_boarding: '',
      term: '',
      destination_address: '',
      operation_id: '',
      thirdPartie_id: '',
      shipment: '',
      qualifiedUser_id: '',
      pass_required: '',
      nitQualifiedUser_id: '',
      modality: '',
      status_outputForm: ''
    },
    null,
    onFieldChange
  )

  const resetCampos = () => {
    setValueQuialified('')
    setNameQualified()
    reset()
    setDataToEdit(null)
    setValueThird([])
    setPlazo(null)
    setNameOperation('')
    setSelectIdOp('')
    setSelectItemForm('')
    setFiles({})
    setAttachment([])
    setAttachmentType([])
    setCustomsadministration(null)
    setAttachmentDescription([])
    setAttachmentNumber([])
    setAttachmentId([])
    setDataOperation([])
  }

  useEffect(() => {
    return () => {
      resetNotations()
    }
  }, [])

  const {
    updateNotation,
    updateNotationData,
    setFormId,
    setReviewsData,
    resetNotations,
    setDataCurrent
  } = useNotations()

  const idForm = FormExitNumber

  useEffect(() => {
    if (idForm) setFormId(idForm)
    if (idForm && Reviews?.[idForm]) setReviewsData(Reviews?.[idForm])
  }, [idForm, Reviews?.[idForm]])

  useEffect(() => {
    setRequestCreate(environment.CREATE_FORM_EXIT)
    setRequestEdit(environment.UPDATE_FORM_EXIT)
    setRequestCreateItem(environment.CREATE_ITEM_FORM_EXIT)
    if (dataToEdit !== null || FormExitNumber) {
      EditOrCreate(FormExitNumber)
    } else {
      resetCampos()
    }
  }, [dataToEdit, FormExitNumber, update])

  useEffect(() => {
    if (valueQualified !== '' && authUser.is_qualified_user !== 1) {
      getOperationNotPaginate(valueQualified)
    } else if (authUser.is_qualified_user === 1) {
      getOperationNotPaginate(userQualifiedId)
    }
  }, [valueQualified, authUser])

  const EditOrCreate = async id => {
    // console.log(id)
    setLoading(true)
    try {
      const res = await __ShowForm(token, id, environment.SHOW_FORM_EXIT)
      const response = res.data.status.data

      if (res?.data?.status.code === 200) {
        if (
          response.status_outputForm === 'BORRADOR' ||
          response.status_outputForm === 'DEVUELTO'
        ) {
          const fromServer = response.files.reduce(
            (accumulator, item, index) => {
              const key = createKey(item.documentReq_id)
              accumulator[key] = createObjectFromItem(item, index)
              return accumulator
            },
            {}
          )

          setFiles(Object.assign({}, files, fromServer))

          if (response.term !== 0) setPlazo(response.term)

            const other_files = convertArrayToGroupedObject(
              response?.other_files || [],
              "other_files"
            );
  
            setOtherDocuments(other_files)
          setValueQuialifiedToUser({
            value: response?.qualifiedUser2_id
              ? response?.qualifiedUser2_id
              : '',
            label: response?.nit_qualified2
              ? response?.nit_qualified2
              : '' + ' ' + response?.name_qualified2
              ? response?.name_qualified2
              : ''
          })

          setFormStatus(response.status_outputForm)

          setDataCurrent(prev => ({
            ...prev,
            formCurrent: response
          }))

          setOnly(response.shipment)
          setRefund(response?.refund || 'Sin reintegro')
          setReviews(response?.reviews)
          setDataFormCurrent(response)
          setRadioRequired(response.pass_required)
          setValues(response)
          setValueQuialified(response.qualifiedUser_id)
          setNameQualified(response.name_qualified)
          if (response?.thirdPartie_id) {
            setValueThird({
              label: response?.business_name,
              value: response?.thirdPartie_id
            })
          }
          if (response.code_operation) {
            setSelectItemForm(response.code_operation)
            setSelectIdOp(response.operation_id)
            setNameOperation(
              response.code_operation + ' ' + response.short_name_operation
            )
          }
          setDocumentOperation(response?.documents)
          setOpcional(response?.check_duta)

          userC(response?.qualifiedUser_id)

          if (response.customAdministration_id) {
            setCustomsadministration({
              label: response.custom_code + ' ' + response.custom_name,
              value: response.customAdministration_id
            })
          }
        } else {
          openAlert(
            `El formulario ${response.code_outputForm} esta en estado ${response.status_outputForm} y no puede ser editado`,
            'error'
          )
          navigate(`/FormShowExit?ExitNumber=${response.id}`)
        }
      } else {
        openAlert(res?.data?.status.message, 'error')
        navigate(-1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const userC = useCallback(
    async idUcCurrent => {
      try {
        const res = await __AllQualifiedUSers(token)
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        )
        setDecimalUcContext(serachUc.decimal_values_precision)
      } catch (error) {
        console.log(error)
      }
    },
    [token]
  )

  const getOperationNotPaginate = async id => {
    try {
      const res = await __OperationListExit(
        token,
        id && id
        // authUser.is_qualified_user !== 1 && valueQualified
      )
      const data = res?.data?.status?.data
      setDataOperation(data)
    } catch (error) {
      throw error
    }
  }

  const hanldeSubmit = e => {
    e.preventDefault()
  }

  const saveCorrectionReview = async () => {
    setLoading(true)
    const templateData = {
      type_doc: 'Salida',
      id: FormExitNumber,
      review: updateNotationData
    }

    if (isObject(updateNotationData) && isObjectNotEmpty(updateNotationData)) {
      try {
        const res = await __SearchUser(
          environment.GUARDAR_CORRECION_REVISION,
          token,
          templateData
        )
        const status = res?.data?.status
        if (status.code !== 200) {
          openAlert(status?.message, 'error')
        } else {
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  /*   const Enviar = () => {
    if (objetos) {
      SearchUserCard()
    }
  } */
  useEffect(() => {
    return async () => {
      setCurrentItemLength(0)
      const body = new FormData()
      body.append('form_id', FormExitNumber)
      body.append('type', 0)
      body.append('type_of', 'FMMS')
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    }
  }, [])

  const dispatchRedux = useDispatch()
  const { balance } = useSelector(state => state.status)
  const { pathname } = location
  const { mutate: mutateCreate, isPending } = useMutation({
    mutationFn: requestData
  })

  //funciones migradas del context
  const resCreateForm = res => {
    const dataCreateForm = res?.data?.status

    if (res?.data?.status?.code === 200) {
      setUpdate(!update)
      const dataBalanceFmm = dataCreateForm?.data.balance
      localStorage.setItem('ValueBalance', JSON.stringify(dataBalanceFmm))
      dispatchRedux(setDataStatusBalance(!balance))
      openAlert(`Registro actualizado Exitosamente`, 'success')

      if (pathname === '/FormEntry') {
        const dataFmm = dataCreateForm?.data.entry_form
        setDataToShow(dataFmm)
        dispatchRedux(changeForms(dataFmm?.id))
        setDataToEdit(dataFmm)
        navigate(`/FormEntry?FormEntryNumber=${dataFmm?.id}`)
      } else if (pathname === '/FormExit') {
        const dataFmm = dataCreateForm?.data.output_form
        setDataToShow(dataFmm)
        dispatchRedux(changeForms(dataFmm?.id))
        setDataToEdit(dataFmm)
        navigate(`/FormExit?FormExitNumber=${dataFmm?.id}`)
      }
    }

    if (res?.data?.status?.code !== 200) {
      if (dataCreateForm?.message) {
        openAlert(dataCreateForm?.message, 'error')
      } else {
        Object.values(dataCreateForm?.message).forEach(message =>
          message.forEach(item => openAlert(item, 'error'))
        )
      }
    }
  }

  const resUpdateForm = (
    res,
    rediret = true,
    successCallback = () => {},
    cancelUpdate = false
  ) => {
    console.log('resUpdateForm', res.data.status)

    if (res.data.status.code === 200) {
      const { id } = res.data.status.data
      setDataToEdit(null)
      openAlert(`Registro actualizado Exitosamente  `, 'success')
      if (!cancelUpdate) setUpdate(!update)
      successCallback()
      if (!rediret) return
      if (pathname === '/FormExit' || pathname === '/FormShowExit') {
        return navigate(`/FormShowExit?ExitNumber=${id}`)
      }
      return navigate(`/FormShow?FormEntry=${id}`)
    }

    if (res.data.status.code !== 200) {
      setDataToEdit(!dataToEdit)
      openAlert(res?.data?.status?.message, 'error')
    }
  }

  const createForm = async data => {
    const config = {
      data,
      Endpoint: environment.CREATE_FORM_EXIT,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutateCreate(config, {
      onSuccess: res => resCreateForm(res),
      onError: (error) => {
        handleError(error)
        handleValidationError(error, setErrorsState, errorsState);
      },
    })
  }

  const editForm = async info => {
    const config = {
      data: info,
      Endpoint: environment.UPDATE_FORM_EXIT,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutateCreate(config, {
      onSuccess: res => resUpdateForm(res)
    })
  }

  const handleCreate = () => {
    // console.log(authUser);

    const isReentryOperation = formOperation?.reentry_operation === 0

    const isReentry =
      isReentryOperation &&
      !isNonEmptyString(values?.commercial_invoice) &&
      currentItemLength > 0

    const validInvoice =
      !isNonEmptyString(values?.commercial_invoice) && currentItemLength > 0

    if (isReentry || validInvoice) {
      return openAlert('El campo Nº Factura comercial es obligatorio.', 'error')
    }

    if (!valueThird && currentItemLength > 0) {
      return openAlert('El campo Proveedor/Exportador es obligatorio.', 'error')
    }

    if (FormStatus === 'DEVUELTO') {
      saveCorrectionReview()
    }

    const formData = new FormData()
    formData.append('shipment', only)
    formData.append('refund', refund)
    formData.append('pass_required', radioRequired === 'Requiere pase' ? 1 : 0)
    formData.append('operation_id', selectIdOp || '')
    formData.append(
      'thirdPartie_id',
      !valueThird ? '' : valueThird?.length !== 0 ? valueThird?.value : ''
    )
    if(isValid(plazo)) formData.append('number_days_extension', parseFloat(plazo))

    formData.append(
      'customAdministration_id',
      customsadministration ? customsadministration.value : ''
    )
    formData.append('shipment', only)

    formData.append(
      'qualifiedUser2_id',
      !valueQualifiedToUser ? '' : valueQualifiedToUser?.length !== 0 ? valueQualifiedToUser?.value : ''
    )
    formData.append(
      'qualifiedUser_id',
      authUser.is_qualified_user === 1
        ? authUser.userQualified
        : valueQualified === undefined
        ? ''
        : valueQualified
    )

    formData.append(
      'commercial_invoice',
      values.commercial_invoice ? values.commercial_invoice : ''
    )
    formData.append('date', values.date ? values.date : '')
    formData.append(
      'certificate_inspection_pre_boarding',
      values.certificate_inspection_pre_boarding
        ? values.certificate_inspection_pre_boarding
        : ''
    )
    formData.append('term', values.term ? values.term : '')
    formData.append(
      'destination_address',
      values.destination_address ? values.destination_address : ''
    )

    if (opcional) {
      formData.append('value_duta', values.value_duta ? values.value_duta : '')
      formData.append('check_duta', opcional)
    }

    if (dataToEdit !== null || FormExitNumber) {
      formData.append('id', FormExitNumber)

      if (!selectIdOp) {
        openAlert(
          `El formulario presenta errores. Verifique los campos marcados.`,
          'error'
        )
        setErrorsState({ opertationId: true })
      } else {
        setErrorsState({ opertationId: false })
        editForm(formData)
        resetCampos()
      }
    } else {
      if (!selectIdOp) {
        openAlert(
          `El formulario presenta errores. Verifique los campos marcados.`,
          'error'
        )
        setErrorsState({ opertationId: true, thirdPartie_id: true })
      } else {
        setErrorsState({ opertationId: false })
        createForm(formData)
      }
    }

    if (
      addresDestine.includes(selectItemForm) &&
      valueThird.addres !== values.destination_address
    ) {
      const information = {
        id: valueThird.value,
        address: values.destination_address
      }
      updateAddresThird(information)
    }
  }

  const {
    isLoading: isLoadingFiles,
    data: data_Files,
    refetch,
    isFetching,
    isSuccess
  } = useQuery({
    queryKey: ['data_Files'],
    queryFn: () =>
      requestData({
        Endpoint: environment.SHOW_FORM_EXIT,
        PropertyBody: 'get',
        resourceIdentifier: FormExitNumber
      }),
    refetchOnWindowFocus: false,
    enabled: false
  })

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleResponseUpdateForm = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      refetch()
      //  return setCalculated(res?.data?.status?.data);
    }
  }

  const {
    data: dataView,
    refetch: refetchView,
    isFetching: isFetchingView,
    isSuccess: isSuccessView
  } = useQuery({
    queryKey: ['formExitView'],
    queryFn: () =>
      requestData({
        Endpoint: environment.SHOW_FORM_EXIT,
        PropertyBody: 'get',
        resourceIdentifier: FormExitNumber
      }),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      if (isSuccessView && !isFetchingView) {
        const data = dataView?.data?.status?.data

        const commercialInvoiceUseForm = isString(values?.commercial_invoice)
          ? values?.commercial_invoice.split(', ')
          : ''

        const commercialInvoiceGet = isString(data?.commercial_invoice)
          ? data?.commercial_invoice.split(', ')
          : ''

        const ElementMerge = mergeUniqueElements(
          commercialInvoiceUseForm,
          commercialInvoiceGet
        )

        const arrayToStringLocal = arrayToString(ElementMerge, ', ')

        setValues(prev => ({
          ...prev,
          commercial_invoice: arrayToStringLocal
        }))
      }
    }
  }, [isFetchingView])


  const updateAndCreate = async (data = undefined, payloadManual = false) => {
    const formData = new FormData()
    formData.append('id', idForm)
    const dataAttachments = attachment[0]
    const vaporRes = (await data) || null

    dataAttachments?.id &&
      formData.append('other_attachment_id[]', dataAttachments?.id)
    const file = {
      file: vaporRes
    }

    if (dataAttachments?.id || dataAttachments?.other_attachment_description) {
      formData.append(
        'other_attachment[]',
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      )
    }

    dataAttachments?.other_attachment_description &&
      formData.append(
        'other_attachment_description[]',
        dataAttachments?.other_attachment_description
      )
    dataAttachments?.other_attachment_comment &&
      formData.append(
        'other_attachment_comment[]',
        dataAttachments?.other_attachment_comment
      )

    dataAttachments?.other_attachment_number &&
      formData.append(
        'other_attachment_number[]',
        dataAttachments?.other_attachment_number
      )

    const date =
      dataAttachments?.other_attachment_date || dataAttachments?.attachment_date
    date && formData.append('other_attachment_date[]', date)

    dataAttachments?.attachment_date &&
      formData.append('attachment_date[]', dataAttachments?.attachment_date)
    dataAttachments?.extension &&
      formData.append('other_attachment_type[]', dataAttachments?.extension)

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      if (data) {
        Object.keys(data).forEach(documentId => {
          if (!data[documentId].from_server) {
            formData.append(
              'attachment[' + documentId + ']',
              JSON.stringify(data[documentId])
            )
          }
        })
      }
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.UPDATE_FORM_EXIT,
      PropertyBody: 'sendFormDataContentPOST'
    }

    setOtherDocuments([])
    setAttachment([])
    setFiles({})

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm
    })
  }

  const editUploadFiles = data => {
    const formData = new FormData()
    formData.append('id', FormExitNumber)

    if (data?.id) formData.append('attachment_id[]', data.id)

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append('attachment_comment[]', data.attachment_comment)
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append('attachment_number[]', data.attachment_number)
    }

    if (isNonEmptyString(data?.attachment_date)) {
      formData.append('attachment_date[]', data.attachment_date)
    }

    updateAndCreate(formData, true)
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      if (isSuccess) {
        const data = data_Files?.data?.status?.data
        console.log('data_Files', data)
        //setValues(prev => ({...prev, commercial_invoice: 'commercial_invoice'}))
        const fileServer = data.files.reduce((old, it, index) => {
          return {
            ...old,
            ['document-' + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
              originalIndex: index,
              attachment_number: it.attachment_number,
              attachment_description: it.attachment_description,
              attachment_comment: it.attachment_comment,
              attachment_date: it.attachment_date
            }
          }
        }, {})

        setFiles({
          ...fileServer
        })

        const other_files = convertArrayToGroupedObject(
          data?.other_files || [],
          "other_files"
        );

        setOtherDocuments(other_files)
      }
    }
  }, [isFetching])

  useEffect(() => {
    if (isUploadRender.current) {
      isUploadRender.current = false
      return
    }

    if (isUploadActive) {
    }
  }, [isUploadActive])

  const firstRender = useRef(true)
  const isUploadRender = useRef(true)

  const updateAddresThird = async data => {
    try {
      const res = await __Post(environment.UPDATE_THIRD_PARTY, token, data)
      if (res.data.status.code === 200) {
        openAlert(`Dirección de tercero actualizada exitoxamente`, 'success')
      } else {
        openAlert(res.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFiles = (e, idFiles) => {
    e.preventDefault()
    document.getElementById(`${idFiles}`).click()
  }

  const extensionOptions = [
    '.doc',
    '.docx',
    '.pdf',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.bmp',
    '.xls',
    '.xlsx',
    '.xlsm',
    'image/png',
    'image/*',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-excel'
  ]

  const handleFilesInput = async e => {
    try {
      e.preventDefault()
      const resModal = await onOpenUpload()
      const { name, files: uploadedFiles, id } = e.target
      const uploadData = getStorage('uploadData')?.uploadData || {}

      if (!uploadedFiles.length) return
      if (uploadData?.cancel) return localStorage.removeItem('uploadData')

      const isValidExtension = validateFileExtension(uploadedFiles[0])
      if (!isValidExtension) return

      const idDocument = extractDocumentId(id)
      const uploadResponse = await uploadFile(uploadedFiles[0])

      updateFileState(
        uploadResponse,
        uploadedFiles[0]?.name,
        idDocument,
        e.target.id
      )
      updateNameFilesState(name)
      clearLocalStorageErrors(idDocument)
    } catch (error) {
      console.error('Error handling file input:', error)
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  }

  function validateFileExtension (file) {
    const getFileExtension = file.name.split('.').pop()
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || ''

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`)
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        'error'
      )
    }
    return isValidExtension
  }

  const extractDocumentId = id => {
    return Number(id.replace('document-', ''))
  }

  async function uploadFile (file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
  const { get: getStorage } = Storage

  const updateFileState = (uploadResponse, name, idDocument, targetId) => {
    const uploadData = getStorage('uploadData')?.uploadData || {}
    const file = {
      [targetId]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData
      }
    }

    if (isObjectNotEmpty(file)) updateAndCreate(file)

    return localStorage.removeItem('uploadData')
  }

  const updateNameFilesState = name => {
    setNameFiles(prevNameFiles => [...prevNameFiles, { name }])
  }

  const clearLocalStorageErrors = idDocument => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormExitNumber}`) || '[]'
    )
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter(e => e !== idDocument)
      localStorage.setItem(
        `errorAttadchment-${FormExitNumber}`,
        JSON.stringify(filteredErrors)
      )
    }
  }

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${files?.[archivo]?.name}"?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1F7AC3',
      confirmButtonText: 'Eliminar',
      customClass: {
        title: 'class-text'
      }
    }).then(resultado => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          const obj = Object.assign({}, { ...files })
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo]
            if (files[archivo].from_server) {
              // removeFileAttachment()
              deleteAttachment(
                environment.DELETE_ATTACHMENT_EXIT,
                files[archivo].id,
                '',
                setFiles,
                obj,
                () => refetch()
              )
            } else {
              const fileInput = document.getElementById('OtherAttachment')
              if (fileInput) fileInput.value = ''
              setFiles(obj)
            }
          }
        }
      }
    })
  }

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload
  } = useConfirmationModal()

  const { nameDocument } = useGlobalContext()


  const getAuthToken = async () => {
    return token;
  };


  const cleanFile = (file) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ?? file?.name_attachment ?? file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint:environment.DELETE_OTHER_ATTACHMENT_EXIT,
          resourceIdentifier: file?.id,
        //  PropertyBody: "sendFormDataContentPOST",
        };

        mutate(requestData, {
          onSuccess: () => {
           // setAttached([]);
            setOtherDocuments([]);
            refetch()
          },
        });
      }
    });
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={'lg'}
      >
        <GroupInputUpload
          onClose={onCloseUpload}
          setIsUploadActive={setIsUploadActive}
          // callBack={closeModal}
        />
      </ModalGeneric>

      {loading && <SpinnerComponent />}

      {!loading && (
        <form onSubmit={hanldeSubmit} className='line'>
          <HeaderSearch>
            <DataCompanyExit
              setValues={setValues}
              values={values}
              handleInputChange={handleInputChange}
              dataOperation={dataOperation}
              setSelectItemForm={setSelectItemForm}
              selectItemForm={selectItemForm}
              setSelectIdOp={setSelectIdOp}
              setUserQuialified={setUserQuialified}
              userQualified={userQualified}
              setValueQuialified={setValueQuialified}
              valueQualified={valueQualified}
              setNameQualified={setNameQualified}
              nameQualified={nameQualified}
              setOnly={setOnly}
              only={only}
              setRefund={setRefund}
              refund={refund}
              setRadioRequired={setRadioRequired}
              radioRequired={radioRequired}
              setValueThird={setValueThird}
              valueThird={valueThird}
              setNameOperation={setNameOperation}
              nameOperation={nameOperation}
              setPlazo={setPlazo}
              plazo={plazo}
              errorsState={errorsState}
              resetCampos={resetCampos}
            />
          </HeaderSearch>

          {selectItemForm && (
            <HeaderSearch>
              <div id='contenedores'>
                <ContainerTitleOutput code={selectItemForm} />
                {((selectItemForm >= 201 && selectItemForm <= 299) ||
                  (selectItemForm >= 601 && selectItemForm <= 699)) && (
                  <>
                    <span>
                      <Text as='sub'>* Opcional</Text>
                      <br />
                    </span>

                    <RadioCheckDuta
                      set={setOpcional}
                      value={opcional}
                      item={dataDUTA}
                    />
                  </>
                )}
                <ContainerFormExit
                  formOperation={formOperation}
                  code={selectItemForm}
                  values={values}
                  handleInputChange={handleInputChange}
                  valueQualifiedToUser={valueQualifiedToUser}
                  setValueQuialifiedToUser={setValueQuialifiedToUser}
                />
              </div>
            </HeaderSearch>
          )}

          {(dataToEdit !== null || FormExitNumber) && (
            <ItemsFMM
              dataToEdit={dataToEdit}
              setItemss={setItemss}
              itemss={itemss}
              valueQualified={valueQualified}
              selectItemForm={selectItemForm}
              codeForm={values.code_outputForm}
              idQualified={values.qualifiedUser_id}
              valuesHeader={values}
              refund={refund}
            />
          )}

          {DocumentOperation && selectItemForm && FormExitNumber && (
            <>
              <HeaderSearch>
                {DocumentOperation.map((document, index) => (
                  <div key={index}>
                    <DocumentUploader
                      attachmentId={attachmentId}
                      setAttachmentId={setAttachmentId}
                      attachmentDescription={attachmentDescription}
                      setAttachmentDescription={setAttachmentDescription}
                      attachmentNumber={attachmentNumber}
                      setAttachmentNumber={setAttachmentNumber}
                      document={document}
                      handleFiles={handleFiles}
                      handleFilesInput={handleFilesInput}
                      handleDelete={removeItemFromArr}
                      files={files}
                      setNameFiles={setNameFiles}
                      nameFiles={nameFiles}
                      index={index}
                      idFmm={FormExitNumber}
                      documentPreview={false}
                      updateAndCreate={updateAndCreate}
                      callBack={editUploadFiles}
                    />
                  </div>
                ))}
              </HeaderSearch>

              <HeaderSearch>
                <Box display={"grid"} gap={4} pt={12}>
                  <EnhancedFileUploadManager
                    title="Otros documentos"
                    onUploadComplete={() => {}}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    endpointAttachments={{
                      create: environment.UPDATE_FORM_EXIT,
                    }}
                    postPropertyName={"other_attachment"}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={getAuthToken}
                    formID={idForm}
                  />

                  <ExistingFiles
                    existingFiles={otherDocuments?.other_files}
                    // isLoading={isPendingViewPass}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
              </HeaderSearch>
            </>
          )}

          <HeaderSearch>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              flexWrap={'wrap'}
            >
              <ButtonStyled
                onClick={handleCreate}
                isLoading={isPending}
                type='submit'
              >
                Guardar
              </ButtonStyled>
            </Box>
          </HeaderSearch>
        </form>
      )}
    </>
  )
}

export default BodyFormExit
