import { AuthContext } from 'context/AuthContext'
import React, { useContext, useState, useEffect } from 'react'

import { TitlePDF } from 'styled/Title'

export const CabeceraEntry = ({ values }) => {
  const {
    users,
    nit_qualified,
    business_name,
    name_qualified,
    status_entryForm,
    code_entryForm,
    status_outputForm,
    code_outputForm
  } = values

  const [dateToday, setDateToday] = useState(null)
  const [dateCreate, setdateCreate] = useState(null)
  const { authUser } = useContext(AuthContext)
  const [fechaAutorizado, setFechaAutorizado] = useState(null)
  //   const [fechaCreate, setFechaCreate] = useState(null);

  useEffect(() => {
    const fechaHoy = new Date()
    setDateToday(
      fechaHoy.toLocaleDateString() +
        ' ' +
        fechaHoy.getHours() +
        ':' +
        fechaHoy.getMinutes() +
        ':' +
        fechaHoy.getSeconds()
    )
    if (users) {
      const respuesta = users.find(
        ({ status_entryForm, status_outputForm }) =>
          status_entryForm === 'BORRADOR' || status_outputForm === 'BORRADOR'
      )
      const respuestaAut = users.find(
        ({ status_entryForm, status_outputForm }) =>
          status_entryForm === 'BORRADOR' || status_outputForm === 'AUTORIZADO'
      )
      if (respuesta) {
        const { date_entryForm, date_outputForm } = respuesta
        setdateCreate(date_entryForm || date_outputForm)
      }
      if (respuestaAut) {
        const { date_entryForm, date_outputForm } = respuesta
        setFechaAutorizado(date_entryForm || date_outputForm)
      }
    }
  }, [values, users])

  return (
    <>
      <div className='row'>
        <div className='col-3'>
          <p>
            {/*  <strong>Impreso: </strong>
            {dateToday} */}
          </p>
          <p>
            <strong>Usuario: </strong>
            {authUser.username}
          </p>
          {/* <p>Pagina 1 </p> */}
        </div>

        <div className='col-6'>
          <div className='container'>
            <TitlePDF size={'18px'} weight={'700'} className='text-center'>
              {authUser.name_freeZone} &nbsp;
              {status_entryForm && 'Formulario de ingresos En Zonas Francas'}
              {status_outputForm &&
                'Formulario de salida en Zonas Francas'}-{' '}
              {status_entryForm || status_outputForm}
              {/* <br />
              Tiquete de báscula - Normal */}
            </TitlePDF>
          </div>
        </div>

        <div className='col-3'>
          <p>
            <strong>Formulario: </strong>
            {code_entryForm || code_outputForm}
          </p>
          <p>
            <strong>Fecha: </strong>
            {dateCreate && dateCreate}{' '}
          </p>
        </div>

        <hr className='mt-4 ms-0' />

        <div className='col-6'>
          <p>
            <strong>Usuario: </strong>
            {name_qualified}
          </p>
          <p>
            <strong>Nit: </strong>
            {nit_qualified}
          </p>
        </div>
        <div className='col-6'>
          <p>
            <strong>Exportador/Proveedor: </strong>
            {business_name}
          </p>
          <p>
            <strong>Fecha Autorización: </strong>
            {fechaAutorizado && fechaAutorizado}
          </p>
        </div>
      </div>
    </>
  )
}
