/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SimpleGrid, Tooltip } from "@chakra-ui/react";
import { InputSelectMulti } from "components/InputSelect/InputSelect";
import { InputsContainer } from "pages/Home/Auth/Auth.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useState } from "react";
import { ButtonHeaderModule, CardButton } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { ContenedorDane } from "styled/SectionContainer.styled";
import { BiCog } from "react-icons/bi";
import { CgImport } from "react-icons/cg";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { ConfigDane } from "./ConfigDane";
import environment from "constants/apiConst";
import { __GetDataNoPage } from "request/Petitions/__Get";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useForm } from "hooks/useForm";
import { __Post } from "request/Petitions/__Post";

export const Dane = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [DataZf, setDataZf] = useState(null);
  const [fechasHoy, setfechasHoy] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const [stateSelectZf, setstateSelectZf] = useState(null);
  const [isOpenConfig, onOpenConfig, onCloseConfig] = useModal();
  const [values, handleInputChange, reset] = useForm({
    date_start: "",
    date_end: ""
  });

  const { date_start, date_end } = values;

  const getZf = async () => {
    try {
      const res = await __GetDataNoPage(
        environment.GET_ZONAS_F_NOT_PAGE,
        token
      );
      if (res?.data?.status.code === 200) {
        const info = res?.data?.status?.data;
        const infoZf = info.map((item) => ({
          label: item.name_freeZone,
          value: item?.id
        }));
        setDataZf(infoZf);
      } else {
        openAlert(`Ocurrio un error`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getZf();
    const hoy_fecha = new Date().toISOString().substring(0, 10);
    setfechasHoy(hoy_fecha);
  }, []);

  const exportManual = async (endpoint, infoData) => {
    try {
      const res = await __Post(endpoint, token, infoData);
      const { code, message, data } = res?.data?.status;
      if (code === 200) {
        openAlert(data || message, "success");
        setstateSelectZf(null);
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const newZf = [];
    if (stateSelectZf) {
      stateSelectZf.forEach(({ value }) => newZf.push(value));
    }
    const data = {
      ...values,
      report: 1,
      free_zones: newZf
    };
    if (date_start === "" || date_end === "") {
      openAlert("Rango de tiempo es requerido", "error");
    } else if (stateSelectZf === null || stateSelectZf.length === 0) {
      openAlert("Seleccione al menos una zona franca", "error");
    } else {
      exportManual(environment.EXPORT_DANE_MANUEL, data);
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenConfig}
        onOpen={onOpenConfig}
        onClose={onCloseConfig}
        title="Configuración DANE"
        scrollBehavior={"outside"}
        size={"4xl"}
      >
        <ConfigDane onClose={onCloseConfig} DataZf={DataZf} />
      </ModalGeneric>

      <div className="">
        <div className="d-flex justify-content-center">
          <ContenedorDane>
            <div className="float-end">
              <Tooltip placement="top" hasArrow label="Configuración">
                <span>
                  <ButtonHeaderModule
                    onClick={onOpenConfig}
                    // bgColor="#03C39A"
                  >
                    <IconGeneric
                      color="#fff"
                      //   className="text-white"
                      as={BiCog}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            </div>
            <SimpleGrid minChildWidth="200px" spacing="10px" mt={10}>
              <LabelGroup
                name={"date_start"}
                value={date_start}
                onChange={handleInputChange}
                tag={"Fecha inicial"}
                size={100}
                tipo="date"
              />
              <LabelGroup
                name={"date_end"}
                tipo="date"
                value={date_end}
                onChange={handleInputChange}
                tag={"Fecha final"}
                size={100}
                max={fechasHoy}
              />
            </SimpleGrid>

            <div style={{ width: "100%" }}>
              <InputSelectMulti
                tag={"Seleccione las zonas francas"}
                data={DataZf}
                set={setstateSelectZf}
                value={stateSelectZf}
              />
            </div>

            <InputsContainer>
              <CardButton onClick={handleSubmit}>
                <IconGeneric color="#fff" className="me-2" as={CgImport} />
                Descargar
              </CardButton>
            </InputsContainer>
          </ContenedorDane>
        </div>
      </div>
    </>
  );
};
