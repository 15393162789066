/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __ShowQualification } from "request/configuration/__QualificationU";
import { ButtonStyled } from "styled/Buttons.styled";

const FormQualificationU = () => {
  const [status, setStatus] = useState(1);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const token = useSelector((state) => state.auth.dataUser.token);
  const [values, handleInputChange, reset, setValues] = useForm({
    name_qualificationQualifiedUser: "",
    description_qualificationQualifiedUser: "",
    status_qualificationQualifiedUser: status
  });
  const {
    name_qualificationQualifiedUser,
    description_qualificationQualifiedUser
  } = values;

  const [openAlert] = AlertErrorAlert();

  useEffect(() => {
    if (dataToEdit) {
      const edit = async () => {
        try {
          const res = await __ShowQualification(token, dataToEdit.id);
          setValues(res.data?.status?.data);
          setStatus(res.data?.status?.data.status_qualificationQualifiedUser);
        } catch (error) {}
      };
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_qualificationQualifiedUser: status
      };

      editData(data);
    } else {
      if (name_qualificationQualifiedUser === "") {
        openAlert("Ingrese el nombre de la Calificación", "error");
      } else if (description_qualificationQualifiedUser === "") {
        openAlert("Ingrese la descripción", "error");
      } else {
        createData(values);
      }
    }
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Clasificación de usuarios calificado
      </Text>
      <Divider mb={1} />
      <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar Clasificación de usuarios calificado"
          : "Crear Clasificación de usuarios calificado"}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            name={"name_qualificationQualifiedUser"}
            tag={"Nombre"}
            size={45}
            value={name_qualificationQualifiedUser}
            onChange={handleInputChange}
            desicion={false}
          />
          <LabelGroup
            name={"description_qualificationQualifiedUser"}
            tag={"Descripción"}
            size={45}
            value={description_qualificationQualifiedUser}
            onChange={handleInputChange}
            desicion={false}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          {dataToEdit && (
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag={"¿Activo?"}
              size={45}
            />
          )}
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormQualificationU;
