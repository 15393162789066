import React, { useState, forwardRef } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { IoIosArrowForward } from "react-icons/io";
import TableOrder from "components/Tables/TableOrder";
import { theadEstadisticasVeto } from "../General/ArrayEstadisticas";
import { TbodySecurity } from "../General/Tbody/TbodySecurity";
import { Bar } from "react-chartjs-2";

import { Title } from "styled/Title";

export const EstadisticaItemSecurity = forwardRef(
  (
    {
      data,
      loading,
      setCurrentPage
    },
    ref
  ) => {
    const [details, setDetails] = useState(false);

    const [userData, setUserData] = useState({
      labels: data && data.map((data) => data.veto),
      datasets: [
        {
          label: ["Vetados"],
          data: data && data.map((data) => data.amount),
          backgroundColor: ["#006EB8", "#47B3E7", "#9CD8F7"]
        }
      ]
    });

    return (
      <React.Fragment>
        <div id="contenedorEstadistica">
          <div className="container">
            <div className="row">
              <div className="col">
                <Title size={"18px"} weight={600} className="mt-3">
                  {'Vehículos vetados'}
                </Title>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div id="contenedorResultado" className="px-2">
              {!loading && <Bar data={userData} ref={ref} />}
            </div>
          </div>
          <span
            className="mt-3 ms-3 text-primary cursorDetail"
            onClick={() => setDetails(!details)}
          >
            Ver más
            <IconGeneric width={"20px"} as={IoIosArrowForward} className="me-3" />
          </span>

          {details && (
            <div className="mt-2">
              <TableOrder
                thead={theadEstadisticasVeto}
                data={data || []}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodySecurity}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
);
