import React from "react";
import { Routes, Route } from "react-router-dom";

import { Roles } from "pages/Configuration/views/Roles";
import Customs from "pages/Configuration/views/Customs";
import { Group } from "pages/Configuration/views/Group";
import { TypeProducts } from "pages/Configuration/views/TypeProducts";
import { Cities } from "pages/Configuration/views/Cities";
import { Sectors } from "pages/Configuration/views/Sectors";
import { TasaCambio } from "pages/Configuration/ExchangeRate/TasaCambio";
import { TypeZfb } from "pages/Configuration/views/TypeZfb";
import Packaging from "pages/Configuration/views/Packaging";
import UserConfig from "pages/Configuration/views/UserConfig";
import Operations from "pages/Configuration/views/Operations";
import { Countries } from "pages/Configuration/views/Countries";
import { CountUser } from "pages/Configuration/views/CountUser";
import { Subheading } from "pages/Configuration/views/Subheading";
import { LicenceRule } from "pages/Configuration/views/LicenceRule";
import { Departments } from "pages/Configuration/views/Departments";
import VehicularDoor from "pages/Configuration/views/VehicularDoor";
import TypeOperation from "pages/Configuration/views/TypeOperation";
import BusinessUnits from "pages/Configuration/views/BusinessUnits";
import AgreementCSub from "pages/Configuration/views/AgreementCSub";
import { ActivityUses } from "pages/Configuration/views/ActivityUses";
import { ZonasFrancas } from "pages/Configuration/views/ZonasFrancas";
import TradeAgreement from "pages/Configuration/views/TradeAgreement";
import TransportMethod from "pages/Configuration/views/TransportMethod";
import { TypesTrasport } from "pages/Configuration/views/TypesTrasport";
import DocumentRequire from "pages/Configuration/views/DocumentRequired";
import { QualificationU } from "pages/Configuration/views/QualificationU";
import CountryAgreementC from "pages/Configuration/views/CountryAgreementC";
import { PermissionsRule } from "pages/Configuration/views/PermissionsRule";
import { SubheadingControl } from "pages/Configuration/views/SubheadingControl";
import { Destiny } from "pages/Configuration/views/Detiny";
import { Dependency } from "pages/Configuration/views/Dependency";
import { ZonesControl } from "pages/Configuration/views/ZonesControl";
import { TipificacionMotivoDevolucion } from "pages/Configuration/views/TipificacionMotivoDevolucion";
import { TypeDocument } from "pages/Configuration/views/TypeDocument";
import { TypeTarif } from "pages/Configuration/views/TypeTarif";
import Port from "pages/Configuration/views/Port";
import TypeIndentifications from "pages/Configuration/views/TypeIndentifications";
import ManageRoles from "pages/Configuration/views/ManageRoles";

export const routeConf = [
  {
    path: "/roles",
    breadcrumbName: "Roles",
    element: <Roles />
  },
  {
    path: "/manageRoles",
    breadcrumbName: "Administrar roles",
    element: <ManageRoles />
  },
  {
    path: "/group",
    breadcrumbName: "Grupo",
    element: <Group />
  },
  {
    path: "/cities",
    breadcrumbName: "Ciudades",
    element: <Cities />
  },
  {
    path: "/sectors",
    breadcrumbName: "Sectores",
    element: <Sectors />
  },
  {
    path: "/customs",
    breadcrumbName: "Aduanas",
    element: <Customs />
  },
  {
    path: "/tasa",
    breadcrumbName: "Tasa de cambio",
    element: <TasaCambio />
  },
  {
    path: "/users",
    breadcrumbName: "Configuración de usuario",
    element: <UserConfig />
  },
  {
    path: "/type-zfb",
    breadcrumbName: "Tipos de zona franca",
    element: <TypeZfb />
  },
  {
    path: "/countries",
    breadcrumbName: "Países",
    element: <Countries />
  },
  {
    path: "/countUser",
    breadcrumbName: "Cuentas de usuarios",
    element: <CountUser />
  },
  {
    path: "/zonesF",
    breadcrumbName: "Zonas francas",
    element: <ZonasFrancas />
  },
  {
    path: "/packaging",
    breadcrumbName: "Embalaje",
    element: <Packaging />
  },
  {
    path: "/subheading",
    breadcrumbName: "Subpartidas",
    element: <Subheading />
  },
  {
    path: "/operations",
    breadcrumbName: "Operaciones",
    element: <Operations />
  },
  {
    path: "/type-user",
    breadcrumbName: "Actividad",
    element: <ActivityUses />
  },

  {
    path: "/licenceRule",
    breadcrumbName: "Licencia",
    element: <LicenceRule />
  },
  {
    path: "/departments",
    breadcrumbName: "Departamentos",
    element: <Departments />
  },
  {
    path: "/type-trans",
    breadcrumbName: "Tipos de trasporte",
    element: <TypesTrasport />
  },
  {
    path: "/vehicular-door",
    breadcrumbName: "Puerta vehícular",
    element: <VehicularDoor />
  },
  {
    path: "/type-operation",
    breadcrumbName: "Tipos de operación",
    element: <TypeOperation />
  },
  {
    path: "/business-units",
    breadcrumbName: "Unidades comerciales",
    element: <BusinessUnits />
  },
  {
    path: "/trade-agreement",
    breadcrumbName: "Acuerdos comerciales",
    element: <TradeAgreement />
  },
  {
    path: "/permissionsRule",
    breadcrumbName: "Permisos",
    element: <PermissionsRule />
  },
  {
    path: "/transport-method",
    breadcrumbName: "Métodos de transporte",
    element: <TransportMethod />
  },
  {
    path: "/DocumentRequired",
    breadcrumbName: "Documentos requeridos",
    element: <DocumentRequire />
  },
  {
    path: "/qualification-user",
    breadcrumbName: "Usuario calificados",
    element: <QualificationU />
  },
  {
    path: "/subheading-agreement",
    breadcrumbName: "Subpartidas - acuerdo comerciales",
    element: <AgreementCSub />
  },
  {
    path: "/country-agreement",
    breadcrumbName: "Países - Acuerdo Comerciales",
    element: <CountryAgreementC />
  },
  {
    path: "/port",
    breadcrumbName: "Puerto",
    element: <Port />
  },
  {
    path: "/subheadingControl",
    breadcrumbName: "Control de subpartidas",
    element: <SubheadingControl />
  },
  {
    path: "/destiny",
    breadcrumbName: "Destino",
    element: <Destiny />
  },
  {
    path: "/dependency",
    breadcrumbName: "Dependencias",
    element: <Dependency />
  },
  {
    path: "/zoneControl",
    breadcrumbName: "Control de zonas francas",
    element: <ZonasFrancas />
  },
  {
    path: "/tipyficationReasonReturn",
    breadcrumbName: "Tipificación motivo devolución",
    element: <TipificacionMotivoDevolucion />
  },
  {
    path: "/typesDocuments",
    breadcrumbName: "Tipo de documentos",
    element: <TypeDocument />
  },
  {
    path: "/typesIdentifications",
    breadcrumbName: "Tipo de identificaciones",
    element: <TypeIndentifications />
  },
  {
    path: "/typeproducts",
    breadcrumbName: "Tipos de Productos",
    element: <TypeProducts />
  },
  {
    path: "/typetarif",
    breadcrumbName: "Tipos de tarifas",
    element: <TypeTarif />
  }
];

export const ConfigurationRoutes = () => {
  return (
    <Routes>
      {routeConf.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
};
