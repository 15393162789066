import { AuthContext } from "context/AuthContext";
import React, { useContext, useState, useEffect } from "react";

import { TitlePDF } from "styled/Title";

export const Cabecera = ({ values }) => {
  const {
    users,
    // name_qualified,
    code_entryFormCorrected,
    status_entryFormCorrected,
    code_entryForm,
    status_outputFormCorrected,
    code_outputFormCorrected,
    code_outputForm
  } = values;

  const [dateToday, setDateToday] = useState(null);
  const [dateCreate, setdateCreate] = useState(null);
  const { authUser } = useContext(AuthContext);
  //   const [fechaAutorizado, setFechaAutorizado] = useState(null);
  //   const [fechaCreate, setFechaCreate] = useState(null);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
    if (users) {
      const respuesta = users.find(
        ({ status_entryFormCorrected, status_outputFormCorrected }) =>
          status_entryFormCorrected === "BORRADOR" ||
          status_outputFormCorrected === "BORRADOR"
      );
      if (respuesta) {
        const { date_entryFormCorrected, date_outputFormCorrected } = respuesta;
        setdateCreate(date_entryFormCorrected || date_outputFormCorrected);
      }
    }
  }, [values, users]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <p>
            <strong>Impreso: </strong>
            {dateToday}
          </p>
          <p>
            <strong>Usuario: </strong>
            {authUser.username}
          </p>
          <p>Pagina 1 </p>
        </div>
        <div className="col-6">
          <div className="container">
            <TitlePDF size={"18px"} weight={"700"} className="text-center">
              {authUser.name_freeZone} &nbsp;
              {status_entryFormCorrected &&
                "Formulario de corrección de ingresos de mercancías en Zonas Francas"}
              {status_outputFormCorrected &&
                "Formulario de corrección de salidas de mercancías en Zonas Francas"}
              - {status_entryFormCorrected || status_outputFormCorrected}
              {/* <br />
              Tiquete de báscula - Normal */}
            </TitlePDF>
          </div>
        </div>
        <div className="col-3">
          <p>
            <strong>Fmm Corregido: </strong>
            {code_entryFormCorrected || code_outputFormCorrected}
          </p>
          <p>
            <strong>Fmm Original: </strong>
            {code_entryForm || code_outputForm}
          </p>
          <p>
            <strong>Fecha: </strong>
            {dateCreate && dateCreate}{" "}
          </p>
        </div>

        <hr className="mt-4 ms-0" />
      </div>
    </>
  );
};
