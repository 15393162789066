/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import TableOrder from "components/Tables/TableOrder";
import React, { useContext, useEffect, useState } from "react";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __Country } from "request/configuration/_Country";
import TbodyCountryAc from "../tables/TbodyCountryAC";
import Pagination from "components/Pagination/Pagination";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import FormCountry from "../FormShow/FormCountry";
import FormCountryView from "../FormShow/FormCountryView";

import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const CountryAgreementC = () => {
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const navigate = useNavigate();

  const formEditSub = () => {
    return <FormCountry />;
  };

  const countryView = () => {
    return <FormCountryView />;
  };

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(countryView);
    setRequestEdit(environment.COUNTRY_UPDATE);
    setRequestCreate(environment.COUNTRY_CREATE);
    setRequestDelete(environment.COUNTRY_DELETE);
  }, [q, update]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configCountryAgreement", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.COUNTRY_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  const Title = [
    { name: "Acuerdo comercial", order: "tradeAgreement_id" },
    { name: "pais", order: "code_country" },
    { name: "Estado", order: "status_countrytradeAgreement" },
    { name: "Acciones", order: "" }
  ];

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Países - Acuerdo Comerciales"></ConfigurationHeader>

        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyCountryAc}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};

export default CountryAgreementC;
