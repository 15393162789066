/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Logo } from "styled/Logo.styled";
// import { ImageWelcome } from "./Auth/HomeScreen/HomeScreen.styled";
import "./styled.css";

import queryString from "query-string";

export const WelcomeLan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code = "", id = "" } = queryString.parse(location.search);

  const login = () => {
    navigate("/auth", {
      replace: true
    });
  };
  return (
    <>
      {/* <ImageWelcome> */}
      <Logo />
      <Stack className=" text-center mt-5  " spacing={6}>
        <div id="hijo">
          <Heading as="h1" size="4xl" isTruncated className="colour  ">
            Bienvenido a EFRANCO
          </Heading>
          <Heading as="h3" size="4xl" isTruncated className="colour  ">
            Gracias por confirmar tus datos
          </Heading>
        </div>
      </Stack>
      <div className="d-flex justify-content-center">
        <button type="button" className="btn btn-primary" onClick={login}>
          ir al login
        </button>
      </div>
      {/* </ImageWelcome> */}
    </>
  );
};
