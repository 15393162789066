import React, { useContext } from 'react'
import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { IconGeneric } from 'styled/Icons.styled'
import { HiDotsHorizontal } from 'react-icons/hi'
import { OptionsBorrador } from './OptionsPass/OptionsBorrador'
import { OptionsPresentado } from './OptionsPass/OptionsPresentado'
import { OptionsAutorizados } from './OptionsPass/OptionsAutorizados'
import { useNavigate } from 'react-router-dom'
import PassCrudContext from 'context/PassCrudContext'
import { OptionsRevisado } from './OptionsPass/OptionsRevisado'
import environment from 'constants/apiConst'

const MenuPass = ({ data }) => {
  const { changeStatus, onOpenReturnReason, setReturnReason, setDataToShow } =
    useContext(PassCrudContext)
  const navigate = useNavigate()

  const handlePresent = status => {
    if (data?.status_dispatchPass) {
      const dataCahnge = {
        id: data.id,
        status_dispatchPass: status
      }

      if (status === 'DEVUELTO') {
        setReturnReason(dataCahnge)
        onOpenReturnReason()
      } else {
        changeStatus(dataCahnge, () => {}, environment.CHANGESTATUSDISPACTH)
        if (status === 'REVISADO') {
          setDataToShow(data)
          navigate(
            `/CheckPassOutput?qualifiedUser=${data.name_qualified}&numberForm=${data.passForm_number}&id=${data.id}&status=${data.status_dispatchPass}&typeO=Dispatch`
          )
        }
      }
    }

    if (data?.status_entryPass) {
      const dataCahnge = {
        id: data.id,
        status_entryPass: status
      }
      if (status === 'DEVUELTO') {
        onOpenReturnReason()
        setReturnReason(dataCahnge)
      } else {
        changeStatus(dataCahnge, () => {}, environment.CHANGESTATUSPASS)
        if (status === 'REVISADO') {
          setDataToShow(data)
          navigate(
            `/CheckPassEntry?qualifiedUser=${data.name_qualified}&numberForm=${data.passForm_number}&id=${data.id}&status=${data.status_entryPass}&typeO=`
          )
        }
      }
    }
  }

  /*  if (data.status_entryPass === "AUTORIZADO") return "";
   */
  return (
    <Menu>
      <>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color='#00000085' />
        </MenuButton>
        {data.status_entryPass ? (
          <MenuList
            borderRadius='none'
            py={0}
            style={{ boxSizing: 'border-box' }}
          >
            {data.status_entryPass === 'BORRADOR' ||
              data.status_entryPass === 'DEVUELTO' ? (
              <OptionsBorrador data={data} handlePresent={handlePresent} />
            ) : data.status_entryPass === 'PRESENTADO'? (
              <OptionsPresentado data={data} handlePresent={handlePresent} />
            ) : data.status_entryPass === 'AUTORIZADO' ? (
              <OptionsAutorizados data={data} handlePresent={handlePresent} />
            ) : data.status_entryPass === 'REVISADO' ? (
              <OptionsRevisado data={data} handlePresent={handlePresent} />
            ) : ''
            }
          </MenuList>
        ) : (
          <MenuList
            borderRadius='none'
            py={0}
            style={{ boxSizing: 'border-box' }}
          >
            {data.status_dispatchPass === 'BORRADOR' ||
              data.status_dispatchPass === 'DEVUELTO' ? (
              <OptionsBorrador data={data} handlePresent={handlePresent} />
            ) : data.status_dispatchPass === 'PRESENTADO' ? (
              <OptionsPresentado data={data} handlePresent={handlePresent} />
            ) : data.status_dispatchPass === 'AUTORIZADO' ? (
              <OptionsAutorizados data={data} handlePresent={handlePresent} />
            ) : data.status_dispatchPass === 'REVISADO' ? (
              <OptionsRevisado data={data} handlePresent={handlePresent} />
            ) : ''}
          </MenuList>
        )}
      </>
    </Menu>
  )
}

export default MenuPass
