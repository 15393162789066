import { Button, Spinner, Td, Tr } from "@chakra-ui/react";
import MenuItemPass from "components/Menu/MenuItemPass";
import React, { useState } from "react";
import { trackingStatus } from "../Templates";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { getFormData } from "redux/actions/dataFormActions";
import { NumericFormat } from "react-number-format";

export const TbodyItemDispacthGet = ({ data }) => {
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const { decimal_values_precision } = useSelector(getFormData);
  const token = useSelector((state) => state.auth.dataUser.token);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles the reference of the data and navigates to the corresponding page.
   * @async
   * @function handleReference
   * @returns {Promise<void>}
   */
  const handleReference = async () => {
    const body = data?.reference?.startsWith("C")
      ? { cintegration: data?.reference }
      : { formentry: data?.reference };
    setIsLoading(true);
    try {
      const res = await __Post(environment.SHOW_REFERENCE, token, body);
      data?.reference?.startsWith("C")
        ? window.open(
          `/ShowIntegrationBox?id=${res?.data?.status?.data?.integration_certificate_id}`,
          "_blank"
        )
        : window.open(
          `/FormShow?FormEntry=${res?.data?.status?.data?.id}`,
          "_blank"
        );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tr
        id={
          data?.status_outputForm === "APROBADO"
            ? "warningInsp"
            : data?.status_outputForm === "AUTORIZADO"
              ? ""
              : ""
        }
      >
        <Td className="text-center">{data?.item}</Td>
        <Td className="text-center">
          <NavLink
            to={`/FormShowExit?ExitNumber=${data?.outputForm_id}`}
            className={"text-center"}
          >
            <span className="formNumber">
              {data?.code_outputForm ? data?.code_outputForm : "N/A"}
            </span>
          </NavLink>
        </Td>
        <Td className="text-center">
          {data?.code_operation ? data?.code_operation : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.item_output ? data?.item_output : "N/A"}
        </Td>
        <Td className="text-center" onClick={handleReference}>
          {isLoading
            ? (
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xs"
              />
            )
            : (
              <span style={{ color: "#3f8dce", cursor: "pointer" }}>
                {data?.reference ?? "N/A"}
              </span>
            )}
        </Td>
        <Td className="text-center">{data?.code_product ?? "N/A"}</Td>

        <Td className="text-center">{data?.description_product ?? "N/A"}</Td>
        <Td className="text-center">{data?.subheading_description ?? "N/A"}</Td>
        <Td className="text-center">
          {data?.output_form_item_description ?? "N/A"}
        </Td>
        <Td className="text-center">{data?.quantity_go_out ?? "N/A"}</Td>
        {/* <Td className="text-center">{data?.quantity_dispatch ?? "N/A"}</Td> */}
        <Td className="text-center">
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            displayType={"text"}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            value={data?.quantity_dispatch || 0}
          />
        </Td>
        <Td className="text-center">{data?.code_businessUnit ?? "N/A"}</Td>
        <Td className="text-center">
          {data?.quantity_go_out_product ?? "N/A"}
        </Td>
        <Td className="text-center">
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            displayType={"text"}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            value={data?.quantity_dispatch_product || 0}
          />
        </Td>

        {/* <Td className="text-center">
          {data?.quantity_dispatch_product ?? "N/A"}
        </Td> */}
        <Td className="text-center">{data?.code_unit_measurement ?? "N/A"}</Td>
        {is_qualified_user === 0 && (
          <Td className="text-center">
            {trackingStatus[data?.tacking] || trackingStatus.default}
          </Td>
        )}
        <Td className="text-center">
          <MenuItemPass data={data} />
        </Td>
      </Tr>
    </>
  );
};
