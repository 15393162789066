import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { Title } from "styled/Title";
import TbodyTable from "./TbodyTable";
import TbodyTablePerson from "./TbodyTablePerson";
import { TitleHeader, TitleHeaderPerson } from "./TheadAccordion";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import {
  __GetBlockTrucks,
  __GetBlockPerson,
  __GetBlockDriver
} from "request/Blocking/___BlockingCrud";
import {
  setDataVetaDriver,
  setDataVetaPerson,
  setDataVetaTruck
} from "redux/actions/securityActions";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useForm } from "hooks/useForm";

/**
 *  ListBlockages - Componente donde muestra lista de bloqueos tanto por Conductores, Vehículos y Personas
 *
 * @component
 * @return {Component} Retorna componente JSX, muestra la data mediante acordions con tablas
 */

const ListBlockages = () => {
  const [openAlert] = AlertErrorAlert();

  const dispatch = useDispatch();
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { dataVetaTruck, dataVetaPerson, dataVetaDriver } = useSelector(
    (state) => state.security
  );

  const [loadingDrive, setLoadingDrive] = useState(false);
  const [pageBlockDriver, setPageBlockDriver] = useState(1);
  const [maxPageBlockDriver, setMaxPageBlockDriver] = useState(1);

  const [loadingPerson, setLoadingPerson] = useState(false);
  const [pageBlockPerson, setPageBlockPerson] = useState(1);
  const [maxPageBlockPerson, setMaxPageBlockPerson] = useState(1);

  const [loadingTruck, setLoadingTruck] = useState(false);
  const [pageBlockTruck, setPageBlockTruck] = useState(1);
  const [maxPageBlockTruck, setMaxPageBlockTruck] = useState(1);

  const changePageBlockDriver = (index) => {
    setPageBlockDriver(1);
    setMaxPageBlockDriver(
      dataVetaDriver ? dataVetaDriver[index]?.last_page : 1
    );
  };

  const changePageBlockPerson = (index) => {
    setPageBlockPerson(1);
    setMaxPageBlockPerson(
      dataVetaPerson ? dataVetaPerson[index]?.last_page : 1
    );
  };

  const changePagePlate = (index) => {
    setPageBlockTruck(1);
    setMaxPageBlockTruck(dataVetaTruck ? dataVetaTruck[index]?.last_page : 1);
  };

  useEffect(() => {
    getBlockDriver();
  }, [pageBlockDriver, freeZone_id]);

  useEffect(() => {
    getBlockPerson();
  }, [pageBlockPerson, freeZone_id]);

  useEffect(() => {
    getBlockTrucks();
  }, [pageBlockTruck, freeZone_id]);

  const [values, handleInputChange, reset] = useForm({
    searchDriver: "",
    searchVehicle: "",

    searchPerson: ""
  });
  const { searchDriver, searchVehicle, searchPerson } = values;

  useEffect(() => {
    const formData = new FormData();
    formData.append("search", searchPerson);
    getBlockPerson(formData);
  }, [searchPerson, freeZone_id]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("search", searchVehicle);
    getBlockTrucks(formData);
  }, [searchVehicle, freeZone_id]);
  useEffect(() => {
    const formData = new FormData();
    formData.append("search", searchDriver);
    getBlockDriver(formData);
  }, [searchDriver, freeZone_id]);

  const getBlockDriver = async (body) => {
    setLoadingDrive(true);
    try {
      const res = await __GetBlockDriver(token, body, pageBlockDriver);
      const data = res?.data?.status;

      if (data?.code === 200) {
        dispatch(setDataVetaDriver(data?.data?.data));
        setMaxPageBlockDriver(data?.data?.last_page);
        setLoadingDrive(false);
      } else {
        setLoadingDrive(false);
        openAlert(data?.message, "error");
      }
    } catch (error) {
      setLoadingDrive(false);
      console.log(error);
    }
  };

  const getBlockTrucks = async (body) => {
    setLoadingTruck(true);
    try {
      const res = await __GetBlockTrucks(token, body, pageBlockTruck);
      const data = res?.data?.status;

      if (data?.code === 200) {
        dispatch(setDataVetaTruck(data?.data?.data));
        setMaxPageBlockTruck(data?.data?.last_page);
        setLoadingTruck(false);
      } else {
        setLoadingTruck(false);
        openAlert(data?.message, "error");
      }
    } catch (error) {
      setLoadingTruck(false);
      console.log(error);
    }
  };

  const getBlockPerson = async (body) => {
    setLoadingPerson(true);
    try {
      const res = await __GetBlockPerson(token, body, pageBlockPerson);
      const data = res?.data?.status;

      if (data?.code === 200) {
        dispatch(setDataVetaPerson(data?.data?.data));
        setMaxPageBlockPerson(data?.data?.last_page);
        setLoadingPerson(false);
      } else {
        setLoadingPerson(false);
        openAlert(data?.message, "error");
      }
    } catch (error) {
      setLoadingPerson(false);
      console.log(error);
    }
  };

  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      <Title style={{ marginTop: 30, marginBottom: 30 }}>
        Lista de bloqueos
      </Title>
      <HeaderSearch>
        <LabelGroup
          name={"searchDriver"}
          onChange={handleInputChange}
          value={searchDriver}
          tag={"Buscar"}
          size={45}
          desicion={false}
          changeValue="mb-3"
        />
        <ComponentAccordion
          stateNull
          loading={loadingDrive}
          page={pageBlockDriver}
          cabecera={["Conductores"]}
          setPage={setPageBlockDriver}
          maxPage={maxPageBlockDriver}
          currentPage={dataVetaDriver}
          TitleHeader={TitleHeaderPerson}
          changePage={changePageBlockDriver}
          setCurrentPage={setDataVetaDriver}
          TBodyEntryMovement={TbodyTablePerson}
        />
      </HeaderSearch>
      <HeaderSearch style={{ marginTop: 30 }}>
        <LabelGroup
          name={"searchVehicle"}
          onChange={handleInputChange}
          value={searchVehicle}
          tag={"Buscar"}
          size={45}
          desicion={false}
          changeValue="mb-3"
        />
        <ComponentAccordion
          stateNull
          page={pageBlockTruck}
          loading={loadingTruck}
          cabecera={["Vehículos"]}
          TitleHeader={TitleHeader}
          maxPage={maxPageBlockTruck}
          currentPage={dataVetaTruck}
          setPage={setPageBlockTruck}
          changePage={changePagePlate}
          TBodyEntryMovement={TbodyTable}
          setCurrentPage={setDataVetaTruck}
        />
      </HeaderSearch>
      <HeaderSearch style={{ marginTop: 30 }}>
        <LabelGroup
          name={"searchPerson"}
          onChange={handleInputChange}
          value={searchPerson}
          tag={"Buscar"}
          size={45}
          desicion={false}
          changeValue="mb-3"
        />
        <ComponentAccordion
          stateNull
          page={pageBlockPerson}
          loading={loadingPerson}
          cabecera={["Personas"]}
          setPage={setPageBlockPerson}
          maxPage={maxPageBlockPerson}
          currentPage={dataVetaPerson}
          TitleHeader={TitleHeaderPerson}
          changePage={changePageBlockPerson}
          setCurrentPage={setDataVetaPerson}
          TBodyEntryMovement={TbodyTablePerson}
        />
      </HeaderSearch>
    </HeaderSearch>
  );
};

export default ListBlockages;
