/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Checkbox, Td, Tr } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import DianCRUDContext from "context/DianCrudContext";
import { __PostForm } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import "../view/ProfileView/StileItems.css";

const TbodyItemProfile = ({ data }) => {
  const [openAlert] = AlertErrorAlert();
  const { update, setUpdate, setLoading } = useContext(DianCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation(); // navigate
  const { DocumentType = "" } = queryString.parse(location.search);
  // const [first, setfirst] = useState([])
  const handleItemsFinish = async (e) => {
    console.log(e.target.value);

    const datafinishProfile = new FormData();
    datafinishProfile.append("document_type", DocumentType);
    datafinishProfile.append("form_item_id", data.id);

    setLoading(true);
    try {
      const res = await __PostForm(
        environment.FINISH_ITEMS_PROFILES,
        token,
        datafinishProfile
      );
      if (res?.data?.status?.code === 200) {
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Tr className={"verticalNone"}>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Td className="text-center">{data?.description_product}</Td>
      <Td className="text-center">{data?.product_quantity}</Td>
      <Td className="text-center">{data?.code_businessUnit}</Td>
      <Td className="text-center">{data?.gross_weight}</Td>
      <Td
        style={{
          width: "17%",
          paddingLeft: "13px"
        }}
        className="text-center"
      >
        {
          <div
            style={{
              width: "100%",
              marginBottom: "5px"
            }}
          >
            <Checkbox
              className="birde"
              // value={data.form_code}
              onChange={handleItemsFinish}
              // colorScheme={"items"}
            />
          </div>
        }
      </Td>
    </Tr>
  );
};

export default TbodyItemProfile;
