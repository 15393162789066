import { Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useContext } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";

const FormShowTarif = () => {
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? dataToShow.name_type_rate : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre de la tarifa </LabeFormlStyled>
                <InputFake>{dataToShow.name_type_rate}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Descripción de la tarifa</LabeFormlStyled>
                <InputFake>
                  {dataToShow.description_type_rate === null
                    ? "---"
                    : dataToShow.description_type_rate}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Estado</LabeFormlStyled>
              <InputFake>
                {dataToShow.status_type_rate === 1 ? "Activo" : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormShowTarif;
