import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { useGlobalContext } from "context/GlobalContext";
import { useEffect } from "react";
import Vapor from "laravel-vapor";
import isArrayNotEmpty, {
  isArray,
  isObject,
  isObjectNotEmpty,
} from "utils/type-check-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useDocumentSubmission = ({
  getDocuments,
  formId,
  ServicetoSave,
  postPropertyName = "attachment[]",
  resetFile = () => {},
  Vaporload = false,
  viewKeyToUpdate = "",
  token,
}) => {
  const [openAlert] = AlertErrorAlert();
  const queryClient = useQueryClient();

  const { requestData } = useGlobalContext();
  const { mutate, isLoading, isPending, isSuccess, isError, data } =
    useMutation({
      mutationFn: requestData,
      retry: 3,
    });

  const handleResponse = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }
    if (res?.data?.status?.code === 200) {
      /* openAlert(res?.data?.status?.message, 'success') */
    }
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const submitAlternative = async (data, id) => {
    const file = isArray(data) && isArrayNotEmpty(data) ? data[0] : {};
    const uploadResponse = await uploadFile(file);
    const formData = new FormData();
    formData.append("id", id);

    const fileSctruct = {
      [id]: {
        file: uploadResponse,
        name: file?.name,
      },
    };

    if (isObject(fileSctruct) && isObjectNotEmpty(fileSctruct)) {
      if (fileSctruct) {
        Object.keys(fileSctruct).forEach((documentId) => {
          if (!fileSctruct[documentId].from_server) {
            formData.append(
              postPropertyName,
              JSON.stringify(fileSctruct[documentId])
            );
          }
        });
      }
    }

    const requestData = {
      data: formData,
      Endpoint: ServicetoSave,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [viewKeyToUpdate] });
      },
    });
  };

  const handleSubmit = (documents, id) => {
    if (Vaporload) {
      submitAlternative(documents, id);
      return;
    }

    const formData = new FormData();

    formData.append("id", id);
    documents.forEach((element) => {
      formData.append(postPropertyName, element);
    });

    const Config = {
      data: formData,
      Endpoint: ServicetoSave,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(Config, {
      onSuccess: (data) => {
        handleResponse(data);
        resetFile();
      },
    });
  };

  useEffect(() => {
    if (getDocuments.length > 0) {
      handleSubmit(getDocuments, formId);
    }
  }, [getDocuments]);

  return { isSuccess, isError, isLoading, isPending, data: data?.data?.status };
};

export default useDocumentSubmission;
