import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyAgreementSub = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();
  const [loadingIcons, setLoadingIcons] = useState(false);

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(
            token,
            environment.ASUBHEADINS_DESACTIVE,
            data.id
          )
          : await __ACTIVAR(token, environment.ASUBHEADINS_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    setLoadingIcons(true);
    try {
      desactivarAndActivar(data.status_subheadingTradeAgreement);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(true);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{`${data?.code_tradeAgreement} - ${data?.name_tradeAgreement}`}</Td>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={
          data.status_subheadingTradeAgreement === 1 ? "Desactivar" : "Activar"
        }
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data.status_subheadingTradeAgreement}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_subheadingTradeAgreement}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyAgreementSub;
