import { BaseOfExport } from "components/Export/BaseOfExport";
import TableOrder from "components/Tables/TableOrder";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";

import { Title } from "styled/Title";

export const ExportPdfGrafica = ({
  rutaImage,
  thead,
  data,
  tbody,
  subtotal,
  title,
  id,
  dateToday,
  children
}) => {
  const { authUser } = useContext(AuthContext);

  return (
    <div id={id}>
      <BaseOfExport>
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <p>
                <strong>Impreso: </strong>
                {dateToday}
              </p>
              <p>
                <strong>Usuario: </strong>
                {authUser.username}
              </p>
              <p>Pagina 1 </p>
            </div>
          </div>

          <hr className="mt-4 ms-0" />
        </div>

        <Title size={"18px"} weight={600} className="mt-3 ms-3">
          {title}
        </Title>
        <div className="p-3">
          {rutaImage && (
            <image alt="grafica" src={rutaImage} style={{ width: "100%" }} />
          )}
          {children && children}

          {data && !children && (
            <div className="mt-2">
              <TableOrder
                thead={thead}
                data={data || []}
                setData={null}
                loading={null}
                tbodyData={tbody}
                subTotals={subtotal || []}
                titleTotal={subtotal && "Total"}
              />
            </div>
          )}
        </div>
      </BaseOfExport>
    </div>
  );
};
