export const typeWeihing = [
  {
    value: "Tara",
    label: "Tara"
  },
  {
    value: "Bruto",
    label: "Bruto"
  }
];

export const typeScheduleList = [
  {
    value: "NORMAL",
    label: "NORMAL"
  },
  {
    value: "CORRIDO",
    label: "CORRIDO"
  }
];

export const typeStatusList = [
  {
    value: "NORMAL",
    label: "NORMAL"
  },
  {
    value: "CADUCADO",
    label: "CADUCADO"
  }
];
