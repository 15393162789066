import React, { useContext } from "react";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import {
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import ProductCRUDContext from "context/ProductCRUDContext";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuProductsAndTemplate = ({ data, deleteField = true }) => {
  const { setDataToEdit, onOpenDelete, setDataToDelete } =
    useContext(ProductCRUDContext);
  const navigate = useNavigate();

  const handleEdit = () => {
    setDataToEdit(data);
    return navigate(
      `/edit-product-template?code_productive_process=${
        data.code_productive_process
      }&description_productive_process=${
        data.description_productive_process
      }&status_productive_process=${data.status_productive_process}&id=${
        data?.id
      }&identificacion=${
        data?.qualifiedUser_id
      }&title=${"Editar proceso productivo"}`
    );
  };
  const handleDelete = (e) => {
    setDataToDelete(data);
    onOpenDelete();
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />{" "}
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {/* <MenuItemStyled py={3}>
          <IconMenuRUD />
          Ver
        </MenuItemStyled>

        <Divider /> */}

        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>

        {deleteField && (
          <>
            <Divider />
            <MenuItemStyled py={3} onClick={handleDelete}>
              <IconMenuRUD as={MdDelete} />
              Eliminar
            </MenuItemStyled>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuProductsAndTemplate;
