/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */ import { useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import FormShowTypeProducts from "../FormShow/FormShowTypeProducts";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationHeader from "../ConfigurationHeader";
import TBodyProducts from "components/Tables/TBodyProducts";
import Pagination from "components/Pagination/Pagination";
import EditFormTypeProducts from "components/Form/EditFormTypeProducts";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowProducts } from "request/configuration/__Products";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

export const TypeProducts = () => {
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    update,
    setFormShow,
    setFormEdit,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete
  } = useContext(ConfigurationCRUDContext);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  // const [isOpenModalNP, openModalNP, closeModalNP] = useModal();

  const TitleProducts = [
    { name: "Nombre", order: "name_typeProduct" },
    { name: "Descripción", order: "description_typeProduct" },
    { name: "Acciones", order: "actions_products" }
  ];

  const formEditProd = () => {
    return <EditFormTypeProducts />;
  };

  const formShowP = () => {
    return <FormShowTypeProducts />;
  }; 

  useEffect(() => {
    setFormShow(formShowP);
    setFormEdit(formEditProd);
    setRequestEdit(environment.PRODUCTS_UPDATE);
    setRequestCreate(environment.PRODUCTS_CREATE);
    setRequestDelete(environment.PRODUCTS_DELETE);
  }, [update, q, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configTypeProducts", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.PRODUCTS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <ConfigurationHeader title="Tipos de Productos" />
      <SectionContainerGene>
        <TableOrder
          thead={TitleProducts}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          isNum={false}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TBodyProducts}
        />
        {!!maxPage && (
          <Pagination
            page={page}
            setPage={setPage}
            maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
            loading={isLoading}
          />
        )}
      </SectionContainerGene>
    </>
  );
};
