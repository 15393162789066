import React, { useCallback, useState } from "react";
import { Button, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import OptionsCustomsClearence from "components/Menu/OptionsCustomsClearence/OptionsCustomsClearence";
import { __formaterCant } from "components/Funciones/formaterCant";
import { IconGeneric, IconTracking } from "styled/Icons.styled";
import { RiRadioButtonFill } from "react-icons/ri";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const td = [1, 2, 3, 4, 5, 6, 7, 8];
const bold = { fontWeight: "bold" };

const TbodyFormCustomClearance = ({ data, actionsNone }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr>
        <Td className="text-center">
          {data?.numItem_customsClearance
            ? data?.numItem_customsClearance
            : "N/A"}
        </Td>
        <Td className="text-center">
          <NavLink
            target="_blank"
            to={`/FormShow?FormEntry=${data?.entryForm_id}`}
            className="ms-2"
          >
            <span className="formNumber">{data?.reference ? data?.reference : "N/A"}</span>
          </NavLink>
        </Td>
        {/*     <Td className="text-center">
          {data?.reference ? data?.reference : "N/A"}
        </Td> */}
        <Td className="text-center">
          {data?.code_product ? data?.code_product : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.description_product ? data?.description_product : "N/A"}
        </Td>
        <Td className="text-center">{data?.equivalent_amount ?? "N/A"}</Td>
        <Td className="text-center">
          {data?.code_subheading ? data?.code_subheading : "N/A"}
        </Td>
        <Td className="text-center">{data?.business_unit_code ?? "N/A"}</Td>
        <Td className="text-center">{data?.quantity_subtract ?? "N/A"}</Td>
        <Td className="text-center">
          {data?.product_business_unit_code ?? "N/A"}
        </Td>
        <Td isNumeric>
          {data?.equivalent_amount ? data?.equivalent_amount : "N/A"}
        </Td>
        <Td isNumeric>
          {data?.weight_kilograms ? data?.weight_kilograms : "N/A"}
        </Td>
        <Td isNumeric>
          {data?.US_value ? __formaterCant(data?.US_value) : "N/A"}
        </Td>
        {is_qualified_user === 0 && (
          <Td>
            {data?.tracking?.length
              ? (
                <Tooltip
                  hasArrow
                  placement="top"
                  label={
                    data?.tracking?.some((tracking) => tracking?.status === 1)
                      ? "En seguimiento"
                      : data?.tracking?.every((tracking) => tracking?.status === 2)
                        ? "Estuvo en seguimiento"
                        : ""
                  }
                >
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <IconTracking
                      onClick={() => showTrackingItem(data?.tracking)}
                      color={
                        data?.tracking?.some((tracking) => tracking?.status === 1)
                          ? "#BA1F33"
                          : "#03C39A"
                      }
                      as={RiRadioButtonFill}
                    ></IconTracking>
                  </span>
                </Tooltip>
              )
              : (
                <Tooltip placement="top" hasArrow label={"Sin seguimiento"}>
                  <spam
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <IconTracking color={"#BABABA"} as={RiRadioButtonFill} />
                  </spam>
                </Tooltip>
              )}
          </Td>
        )}
        {!actionsNone && (
          <Td className="text-center">
            <OptionsCustomsClearence data={data} />
          </Td>
        )}
      </Tr>
    </>
  );
};

export const TbodyFormCustomClearanceSubTotal = ({ data, actionsNone }) => {
  return (
    <Tr>
      <Td className="text-center" style={bold}>
        Subtotales
      </Td>
      {td.map((i) => (
        <Td key={i} />
      ))}
      <Td className="text-center" style={bold}>
        {"N/A"}
      </Td>
      <Td className="text-center" style={bold}>
        {"N/A"}
      </Td>
      <Td className="text-center" style={bold}>
        {"N/A"}
      </Td>
    </Tr>
  );
};

export default TbodyFormCustomClearance;
