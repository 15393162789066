import React from "react";

export const CaberaOutput = ({ values }) => {
  const { name_qualified, nit_qualified, business_nameCorrected } = values;
  return (
    <>
      <div className="row">
        <div className="col-3">
          <p>
            <strong>Usuario: </strong>
            {name_qualified}
          </p>
        </div>
        <div className="col-6">
          <p className="text-center">
            <strong>Nit: </strong>
            {nit_qualified}
          </p>
        </div>
        <div className="col-3">
          <p>
            <strong>Importador/Comprador: </strong>
            {business_nameCorrected}
          </p>
        </div>
      </div>
    </>
  );
};
