/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Tooltip } from '@chakra-ui/react'
import TablePass from 'components/Tables/TablesPass'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import '../../pages/Pass-Form/stilo.css'
import { Title } from 'styled/Title'

import { ButtonHeaderModule } from 'styled/Buttons.styled'
import Pagination from 'components/Pagination/Pagination'
import { IconGeneric } from 'styled/Icons.styled'
import GlobalContext from 'context/GlobalContext'
import { MdOutlineAdd } from 'react-icons/md'
import { AddItemsDispatch } from 'pages/Pass-Form/Form/AddItemsDispatch'
import InputSelect from 'components/InputSelect/InputSelect'

export const EditOrViewItems = ({
  currentItemPass,
  isLoading,
  setCurrentItem,
  maxPageItem,
  pageItem,
  setItemPage,
  theadData,
  TbodyData,
  Actions,
  orderBy,
  setOrderBy,
  ...props
}) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [editType, setEditType] = useState(null)
  const { actionToBeCarriedOut, setActionToBeCarriedOut } =
    useContext(GlobalContext)

  useEffect(() => {
    const queryKeys = Object.keys(queryParams)

    if (queryKeys.includes('Dispatch')) {
      return setEditType('Dispatch')
    }

    if (queryKeys.includes('Income')) {
      return setEditType('Income')
    }
  }, [queryParams])

  const ShowCreateItem = action => {
    setActionToBeCarriedOut(action)
  }

  return (
    <>
      {(actionToBeCarriedOut === 'Edit' ||
        actionToBeCarriedOut === 'Create') && (
          <div id='marginIcon' className='mb-3'>
            <AddItemsDispatch editType={editType} qualifiedUserId={props.qualifiedUserId} />
          </div>
        )}

      <div id=''>
        <Box display='flex' justifyContent='space-between'>
          <Box display={"flex"} justifyContent="space-between" alignItems={"center"} w={'450px'}>
            <Title size={'18px'} weight={600}>
              Ítems del Formulario
            </Title>
            <InputSelect
              data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
              value={orderBy}
              name="order_by"
              set={(e) => {
                setOrderBy(e);
              }}
              placeholder="Ordenar por"
              inputAttrs={{
                color: '#5f5f',
              }}
              size={40}
              isClearable={false}
            />
          </Box>
          {Actions === 'Edit' && (
            <div className='float-end'>
              <Tooltip placement='top' hasArrow label={`Agregar Ítem`}>
                <ButtonHeaderModule
                  onClick={() => ShowCreateItem('Create')}
                  bgColor='#2189f9'
                  className=' d-flex float-end me-3'
                >
                  <IconGeneric color='#ffffff' as={MdOutlineAdd} />
                </ButtonHeaderModule>
              </Tooltip>
            </div>
          )}
        </Box>

        <TablePass
          thead={theadData}
          data={currentItemPass}
          setData={setCurrentItem}
          loading={isLoading}
          tbodyData={TbodyData}
        />
        <Pagination
          page={pageItem}
          setPage={setItemPage}
          maxPage={maxPageItem}
          loading={isLoading}
        />
      </div>
    </>
  )
}
