/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import BlockingContext from "context/BlockingContex";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { TitleDependency } from "../Title/ArrayTitle";
import TableDependency from "../Table/TableDependency";
import { __ShowRestriccion } from "request/Blocking/___BlockingCrud";
import { useSelector } from "react-redux";

/**
 *  FormView - Componente de tabla para dependecias bloqueadas con la empresa escojida
 *
 * @component
 * @return {Component} Retorna componente de tabla de dependencia bloqueadas.
 */

const FormView = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataRestriccion, setDataRestriccion] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const { show } = useContext(BlockingContext);
  const token = useSelector((state) => state.auth.dataUser.token);

  const getRestriccion = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("qualifiedUser_id", show.qualifiedUser_id);
    try {
      const res = await __ShowRestriccion(token, formData);
      console.log(res?.data?.status?.data?.restrictions);
      setDataRestriccion(res?.data?.status?.data?.restrictions);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token, show.qualifiedUser_id]);

  useEffect(() => {
    if (show.qualifiedUser_id !== null && show.qualifiedUser_id !== undefined) {
      getRestriccion();
    }
  }, [getRestriccion, show.qualifiedUser_id]);

  return (
    <>
      <HeaderSearch>
        <TableOrder
          thead={TitleDependency}
          data={dataRestriccion}
          setData={setDataRestriccion}
          loading={loading}
          tbodyData={TableDependency}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default FormView;
