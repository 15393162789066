import styled from "styled-components/macro";

export const InputFormStyled = styled.input`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "1rem"};
  padding: ${(props) => props.padding || "0 0.5rem"};
  padding-bottom: 0.5rem;
  border: none;
  outline: none;
  border-bottom: 1px solid ${(props) => props.ColorFake || "#bababa80"};
  font-size: 1rem;
  display: inline-block;

  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const SelectFormStyled = styled.select`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border: none;
  outline: none;
  border-bottom: 1px solid #bababa;
  font-size: 1rem;
  display: inline-block;
`;

export const LabeFormlStyled = styled.label`
  color: ${(props) => (props.error ? "red" : "#454546")};
  font-size: 0.75rem;
`;

export const LabeFormlStyledColor = styled.label`
  color: ${(props) => (props.Color ? props.Color : "#454546")};
  font-size: 0.75rem;
`;

export const FormInputGroup = styled.div`
  width: ${(props) => props.size || "100%"};
`;

export const InputFake = styled.p`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  margin-bottom: ${(props) => props.mb || "1rem"};
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${(props) => props.ColorFake || "#bababa80"};
  font-size: 1rem;
  display: inline-block;
`;

export const InputCustom = styled.input`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  margin-bottom: ${(props) => props.mb || "1rem"};
  padding: 0.5rem;
  border: 2px solid ${(props) => props.inputColor || "gray"};
  //box-shadow: ${(props) => props.inputColor || "gray"} 0px 0px 0px 2px,
  // rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
  //rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 10px;
  font-size: 1rem;
  display: inline-block;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

export const InputCustomTextArea = styled.textarea`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  margin-bottom: ${(props) => props.mb || "1rem"};
  padding: 0.5rem;
  border: 2px solid ${(props) => props.inputColor || "gray"};
  //box-shadow: ${(props) => props.inputColor || "gray"} 0px 0px 0px 2px,
  // rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
  //rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 10px;
  font-size: 1rem;
  display: inline-block;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

export const TextAreaFormStyled = styled.textarea`
  width: 100%;
  height: 60px;
  /* background-color: #ffffff87; */
  outline: none;
  border: 1px solid ${(props) => (props.error ? "red" : "#bababa")};
  border-radius: 5px;
  padding-left: 5px;
  font-size: 1rem;
  display: inline-block;
`;
