export const parseFloat = (value) => {
  try {
    if (typeof value === 'number') return value;
    const number = Number.parseFloat(value.replaceAll(",", ""));
    if (value === "") return 0;
    if (isNaN(number)) return value;
    return number;
  } catch (e) {
    return 0;
  }
};


/**
 * Comprueba si un valor es ni undefined ni null.
 *
 * @param {any} value - El valor a verificar.
 * @return {boolean} Devuelve true si el valor no es undefined ni null, de lo contrario false.
 */
export function isValid(value) {
  return value !== undefined && value !== null;
}


/**
 * Remueve el decimal si el valor es 0
 *  
 * @param {any} value - El valor a verificar.
 * @param {number} decimalPlaces - La precisión del decimal.
 * @return {number} Devuelve el valor con el o los decimales removidos.
*/

export const removeDecimals = ({ number, decimalPlaces = 0 }) => {
  const roundedNumber = parseFloat(number)
  return parseFloat(roundedNumber?.toFixed(decimalPlaces).replace(/(\.0+|0+)$/, ''));
}

