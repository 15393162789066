import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import { GiPaperClip } from 'react-icons/gi'
import { IoAlertOutline } from 'react-icons/io5'

import {
  FormInputGroup,
  InputFake,
  LabeFormlStyledColor
} from 'styled/Input.styled'
import { colorToRgba } from './colorToRgba'
import { NavLink } from 'react-router-dom'
import { isString } from 'utils/type-check-utils'
import React, { useEffect, useRef } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

class TooltipConfig {
  constructor (icon, text = '', color = '', error = false) {
    this.icon = icon
    this.text = text
    this.color = color
    this.error = error
  }
}

export const InputFakeLabelTextArea = ({
  value,
  name,
  Label,
  ColorInput,
  ColorTextLabel
}) => {
  return (
    <FormInputGroup>
      <LabeFormlStyledColor
        Color={ColorInput === '#bababa80' ? 'black' : ColorInput}
      >
        {name}
      </LabeFormlStyledColor>
      <Tooltip
        hasArrow
        label={Label}
        bg={ColorInput}
        color={ColorTextLabel}
        placement='bottom-start'
      >
        <Textarea
          style={{ borderBottom: ` 1px solid ${ColorInput || '#bababa80'} ` }}
          readOnly={true}
          value={value}
        />
      </Tooltip>
    </FormInputGroup>
  )
}

export const InputFakeLabel = ({
  value,
  name,
  Label,
  ColorInput,
  ColorTextLabel
}) => {
  return (
    <FormInputGroup>
      <LabeFormlStyledColor
        Color={ColorInput === '#bababa80' ? 'black' : ColorInput}
      >
        {name}
      </LabeFormlStyledColor>
      <Tooltip
        hasArrow
        label={Label}
        bg={ColorInput}
        color={ColorTextLabel}
        placement='bottom-start'
      >
        <InputFake ColorFake={ColorInput}>{value}</InputFake>
      </Tooltip>
    </FormInputGroup>
  )
}

const InputRightElementCustom = ({
  tooltipText = null,
  inputBgColor,
  name = '',
  onClick = () => {},
  customConfig = {
    icon: null,
    text: null,
    color: null,
    onclick: () => {}
  }
}) => {
  class TooltipManager {
    static templateConfig = {
      red: new TooltipConfig(
        <IoAlertOutline color='red' />,
        '¡Error! Se ha enviado un campo no solicitado.',
        inputBgColor,
        true
      ),
      orange: new TooltipConfig(
        <FiAlertTriangle color='#FFA500' />,
        '¡Error! Se ha devuelto el campo sin ser corregido.',
        inputBgColor,
        true
      ),
      purple: new TooltipConfig(null, null, null),
      default: new TooltipConfig(
        isString(tooltipText) && tooltipText !== '' ? <GiPaperClip /> : null,
        tooltipText,
        '#1F7AC3'
      )
    }

    static getConfig (inputBgColor) {
      const config = this.templateConfig[inputBgColor || 'default']
      if (inputBgColor === 'purple') {
        const defaultConfig = this.templateConfig.default
        config.icon = config.icon || defaultConfig.icon
        config.text = config.text || defaultConfig.text
        config.color = config.color || defaultConfig.color
      }
      return config
    }
  }

  const config = TooltipManager.getConfig(inputBgColor)
  const IconShow = customConfig?.icon || config?.icon || <></>
  const TextShow = customConfig?.text || config?.text || ''

  const childrenWithProps = React.cloneElement(IconShow, {
    target: document.getElementsByName(name)?.[0],
    data: { ...config }
  })

  const handleClick = () => {
    onClick()
    if (document.getElementsByName(name)?.[0]) {
      document.getElementsByName(name)?.[0].click()
    }
  }

  return (
    <Tooltip
      hasArrow
      label={TextShow}
      bg={customConfig?.color || config?.color}
      placement='top-start'
    >
      <InputRightElement
        color={customConfig?.color}
        onClick={handleClick}
        _hover={{
          cursor: 'pointer'
        }}
        children={childrenWithProps}
      />
    </Tooltip>
  )
}

/**
 *
Componente para crear un input con una etiqueta
@param {string} labelText - Texto de la etiqueta
@param {string} tooltipText - Texto de la ayuda para el input
@param {string} inputBgColor - Color de fondo del input
@param {string} tooltipColor - Color del texto de la ayuda
@param {Object} props - Propiedades adicionales para el input
*/

export const InputWithLabel = ({
  labelText = '',
  tooltipText = '',
  inputBgColor,
  cursor = 'auto',
  tooltipColor,
  customConfig = {
    icon: null,
    text: null,
    color: null,
    onClick: () => {}
  },
  onClick = () => {},
  children,
  ...props
}) => {
  const GrayEvaluation = inputBgColor !== 'gray' && inputBgColor !== '#bababa80'

  const onClickManager = (...attrs) => {
    onClick(...attrs)
    // customConfig?.onClick(...attrs);
  }

  const inputRef = useRef(null)
  const isLongInput = ['Descripción de Producto', 'Descripción larga']

  useEffect(() => {
    if (!inputRef?.current) return

    const isLongDescription = isLongInput.includes(
      inputRef?.current?.dataset?.nameId
    )

    if (isLongDescription && inputRef?.current?.scrollHeight) {
      inputRef.current.style.height = 'auto'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [props?.value, inputRef?.current?.scrollHeight])

  return (
    <FormInputGroup>
      <LabeFormlStyledColor
        Color={inputBgColor === '#bababa80' ? 'black' : inputBgColor}
      >
        {labelText}
      </LabeFormlStyledColor>
      <Tooltip
        hasArrow
        label={tooltipText}
        bg={colorToRgba(inputBgColor, 0.8)}
        color={tooltipColor}
        placement='bottom-start'
      >
        <InputGroup>
          <Input
            ref={inputRef}
            borderTopLeftRadius={4}
            borderTopRightRadius={4}
            border={'none'}
            _focus={{
              outline: 'none'
            }}
            onClick={onClickManager}
            fontWeight={GrayEvaluation ? 600 : 0}
            style={{
              cursor: cursor,
              color: GrayEvaluation ? inputBgColor : 'rgb(49,49,49)',
              borderBottom: inputBgColor
                ? ` 2px solid ${inputBgColor}`
                : ' 2px solid #bababa80'
            }}
            readOnly
            {...props}
          />
          {children}
          <InputRightElementCustom
            tooltipText={props?.tooltipTextComment}
            inputBgColor={inputBgColor}
            customConfig={customConfig}
            name={props?.name}
          />
        </InputGroup>
      </Tooltip>
    </FormInputGroup>
  )
}

export const BoxWithLabel = ({
  labelText,
  tooltipText,
  inputBgColor,
  tooltipColor,
  customConfig = {
    icon: null,
    text: null,
    color: null,
    onClick: () => {}
  },
  onClick = () => {},
  children,
  ...props
}) => {
  const GrayEvaluation = inputBgColor !== 'gray' && inputBgColor !== '#bababa80'

  const onClickManager = (...attrs) => {
    onClick(...attrs)
    // customConfig?.onClick(...attrs);
  }

  return (
    <>
      <FormInputGroup>
        <LabeFormlStyledColor
          Color={inputBgColor === '#bababa80' ? 'black' : inputBgColor}
        >
          {labelText}
        </LabeFormlStyledColor>
        <Tooltip
          hasArrow
          label={tooltipText}
          bg={colorToRgba(inputBgColor, 0.8)}
          color={tooltipColor}
          placement='bottom-start'
        >
          <InputGroup>
            <Textarea
              borderTopLeftRadius={4}
              borderTopRightRadius={4}
              border={'none'}
              w={'full'}
              _focus={{
                outline: 'none'
              }}
              fontWeight={GrayEvaluation ? 600 : 0}
              onClick={onClickManager}
              style={{
                color: GrayEvaluation ? inputBgColor : 'rgb(49,49,49)',
                borderBottom: inputBgColor
                  ? ` 2px solid ${inputBgColor}`
                  : ' 2px solid #bababa80'
              }}
              readOnly
              {...props}
            >
              {props.value}{' '}
            </Textarea>
            {children}
            <InputRightElementCustom
              tooltipText={props.tooltipTextComment}
              inputBgColor={inputBgColor}
              customConfig={customConfig}
              name={props?.name}
            />
          </InputGroup>
        </Tooltip>
      </FormInputGroup>
    </>
  )
}

export const InputFakeLink = ({
  url,
  value,
  labelText,
  disableRedirect = false
}) => {
  const content = (
    <Text
      as='h2'
      p='2.5'
      pl={0}
      borderBottom='2px solid rgba(186, 186, 186, 0.5)'
      _hover={
        !disableRedirect && {
          cursor: 'pointer',
          color: '#1F7AC3',
          borderBottom: '2px solid #1F7AC3'
        }
      }
    >
      {value || 'N/A'}
    </Text>
  )

  return (
    <Box>
      <LabeFormlStyledColor>{labelText}</LabeFormlStyledColor>
      {disableRedirect ? content : <NavLink to={url}>{content}</NavLink>}
    </Box>
  )
}
