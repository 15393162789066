import React from 'react'

import { Box, Grid, GridItem, Icon, Text } from '@chakra-ui/react'

import { BsCalendar4Event } from 'react-icons/bs'
import RiskLevels from 'components/ControlAnalysis/RiskLevels'
import ViewControlAnalysis from 'components/ControlAnalysis/ViewControlAnalysis'
import Information from 'components/ControlAnalysis/Information'
import { BaseOfExport } from 'components/Export/BaseOfExport'

export const Level = level => {
  switch (level) {
    case 'ALTO':
      return 0
    case 'MODERADO':
      return 1
    case 'LEVE':
      return 2
    default:
      return null
  }
}

function ExportShowRiskLevel ({ Detail, ...props }) {
  return (
    <div
      style={{
        display: 'none'
      }}
      {...props}
    >
      <BaseOfExport>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          m={6}
          p={4}
          mb={0}
        >
          <Text fontSize={14} fontWeight={500} color={'gray'}>
            <Icon as={BsCalendar4Event} boxSize={6} color={'gray'} pr={1} />
            {Detail?.detailed_analysis?.date}
          </Text>

          <Text fontSize={14} fontWeight={500} color={'gray'}>
            {Detail?.shortName_freeZone}
          </Text>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          m={6}
          p={4}
          mt={0}
          bg={'gray.100'}
          borderTop={'1px solid gray'}
        >
          <Text color={'gray'}>
            Usuario <br />
            {Detail?.name_qualified}
          </Text>
          <Text color={'gray'}>
            Calificación <br />
            {Detail?.name_activity ?? 'N/A'}
          </Text>
        </Box>
        {/* Filtro y exportar PDF */}
        <Text fontSize={26} fontWeight={600}>
          Resumen de criterios
        </Text>
        <RiskLevels Detail={Detail} />
        <Grid
          gap={4}
          p={4}
          mt={0}
          templateColumns='repeat(auto-fit, minmax(500px, 1fr))'
        >
          <GridItem>
            <ViewControlAnalysis Detail={Detail} />
          </GridItem>
          <GridItem>
            {' '}
            {/*   <Information /> */}
          </GridItem>
        </Grid>
      </BaseOfExport>
    </div>
  )
}

export default ExportShowRiskLevel
