import { useState } from "react";
import { useForm } from "./useForm";

export const useRegexState = (initialState = {}, regex = /^[a-zA-Z0-9]*$/) => {
  const [values, handleInputChange, resetForm, setFormValues] =
    useForm(initialState);

  const handleRegexValidation = ({ target }) => {
    if (!regex.test(target.value)) {
      return;
    }

    handleInputChange({ target });
  };

  return {
    values,
    handleInputChange: handleRegexValidation,
    resetForm,
    setFormValues
  };
};

export const useRegexLabelState = (initialState = {}, regex) => {
  const [values, setValues] = useState(initialState);

  const resetForm = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    const field = values[name];

    setValues({
      ...values,
      [target.name]: {
        ...field,
        value
      }
    });
  };

  const handleRegexValidation = ({ target }) => {
    const { name, value } = target;
    const regexValidation = initialState[name].regex || regex;
    if (!regexValidation.test(value)) {
      return;
    }
    handleInputChange({ target });
  };

  return {
    values,
    handleInputChange: handleRegexValidation,
    resetForm,
    setFormValues: setValues
  };
};
