import { Box, Text } from '@chakra-ui/react'
import React from 'react'

function BoxRiskLevel ({ Num = 0, name, Level }) {
  const Color = level => {
    switch (level) {
      case 'ALTO':
        return 'red'
      case 'MODERADO':
        return 'yellow'
      case 'LEVE':
        return 'green'
      default:
        return ''
    }
  }

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      alignItems={'center'}
      textAlign='center'
    >
      <Text fontSize={'2xl'} color={'blackAlpha.600'} fontWeight={800}>
        {Num}
      </Text>

      <Box
        display={'flex'}
        flexDir={'column-reverse'}
        w={20}
        h={20}
        borderStyle={'dotted'}
        borderWidth={3}
        borderTop={'none'}
        borderBottom={'none'}
        borderRight={'none'}
      >
        <Box h={10} borderRadius={6} bg={`${Color(Level)}.400`}></Box>
      </Box>

      <Text fontSize={10} color={'blackAlpha.600'} fontWeight={500}>
        {name}
      </Text>
    </Box>
  )
}

export default BoxRiskLevel
