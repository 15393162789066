import React, { useEffect, useState } from "react";
import { Td, Tr } from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import MenuItems from "components/Menu/MenuItems";

const TbodyItemTransfer = ({ data, iope }) => {
  const location = useLocation();
  const { FormEntryNumber = "" } = queryString.parse(location.search);
  const [erroresLocal, setErroresLocal] = useState([]);

  useEffect(() => {
    if (FormEntryNumber) {
      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormEntryNumber}`) || "[]"
      );
      if (obtenerErrorItem) {
        setErroresLocal(obtenerErrorItem);
      }
    }
  }, [FormEntryNumber]);

  return (
    <Tr
      className={
        erroresLocal && erroresLocal?.includes(data.id) && "BorderError"
      }
    >
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">
        {data?.code_subheading ? data?.code_subheading : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description_product ? data?.description_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit ? data?.code_businessUnit : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity ? data?.quantity.replaceAll(".", ",") : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.product_quantity
          ? data?.product_quantity.replaceAll(".", ",")
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.cif_us_value ? data?.cif_us_value : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.us_fob_value ? data?.us_fob_value : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.net_weight ? data?.net_weight.replaceAll(".", ",") : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.gross_weight ? data?.gross_weight.replaceAll(".", ",") : "N/A"}
      </Td>
      <Td isNumeric className="text-center">
        <MenuItems data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyItemTransfer;
