import React, { useState } from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import { ConfigIconStatus } from "./components/LoadingConfig";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";

const TbodyTariff = ({ data }) => {
  const TdState = styled(Td)`
    color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
  `;
  const { requestData } = useGlobalContext()

  const queryClient = useQueryClient();

  const { mutate: activeAndDesactive, isLoading } = useMutation({
    mutationFn: requestData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["configTypeTariff"]});
    }
  })


  return (
    <>
      <Tr>
        <Td className="text-center">{data?.name_type_rate}</Td>
        <Td className="text-center">{data?.description_type_rate}</Td>
        <TdState
          className="text-center"
          text={data?.status_type_rate}
          onClick={() => {
            activeAndDesactive({
              Endpoint: environment.CHANGUE_STATUS_RATE_TYPE,
              data: {
                rate_type_id: data?.type_rate_id,
                status: data?.status_type_rate
              },
              PropertyBody: "sendJSONContentPOST"
            })
          }}>
          <ConfigIconStatus
            loading={isLoading}
            status={data?.status_type_rate}
          />
        </TdState>
        <Td isNumeric>
          <MenuRUD data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TbodyTariff;
