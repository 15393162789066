import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect } from 'react'

// Custom components

import Menu from '../../Components/menu/Mainmenu'
import Card from '../../Components/cards/Card'

export const ColumnsChart = ({
  title,
  onOpenWarning,
  itemId,
  component,
  Button
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  return (
    <>
      <Card w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'
          >
            {title}
          </Text>
          {Button
            ? (
              <Box>{Button}</Box>
            )
            : (
              <Menu onOpenWarning={onOpenWarning} itemId={itemId} />
            )}
        </Flex>
        {component}
      </Card>{' '}
    </>
  )
}

export default ColumnsChart
