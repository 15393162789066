import React, { createContext, useContext, useState, useCallback } from 'react'
const FormContext = createContext()

export const useFormProvider = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('useFormProvider debe ser usado dentro de un FormProvider')
  }
  return context
}

// Proveedor del contexto de formularios
export const FormProvider = ({ children }) => {
  const [forms, setForms] = useState({})

  const registerForm = useCallback((formKey, initialState) => {
    setForms(prevForms => ({
      ...prevForms,
      [formKey]: { values: initialState, errors: {}, isSubmitting: false }
    }))
  }, [])

  const updateForm = useCallback((formKey, newValues) => {
    setForms(prevForms => ({
      ...prevForms,
      [formKey]: {
        ...prevForms[formKey],
        values: { ...prevForms[formKey].values, ...newValues }
      }
    }))
  }, [])

  const resetForm = useCallback(formKey => {
    setForms(prevForms => ({
      ...prevForms,
      [formKey]: {
        ...prevForms[formKey],
        values: prevForms[formKey].initialValues
      }
    }))
  }, [])

  const invalidateForm = useCallback(formKey => {
    setForms(prevForms => ({
      ...prevForms,
      [formKey]: { ...prevForms[formKey], invalidated: true }
    }))
  }, [])

  return (
    <FormContext.Provider
      value={{ forms, registerForm, updateForm, resetForm, invalidateForm }}
    >
      {children}
    </FormContext.Provider>
  )
}
