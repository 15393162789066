const { useEffect, useState } = require("react");
const { useSelector } = require("react-redux");
const { __ObtenerItems } = require("request/Pases/__CrudPass");

const useGetItemPass = ({ idPass, pageItem, endpoint, orderBy = { value: "ASC" } }) => {
  const [currentItem, setCurrentItem] = useState([]);
  const [maxPageItem, setMaxPageItem] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [formActive, setFormActive] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);

  const fetch = async () => {
    setIsLoading(true);

    try {
      const res = await __ObtenerItems(token, endpoint, idPass, pageItem, { orderBy: orderBy.value });
      const resData = res?.data?.status;
      if (resData.code === 200) {
        setCurrentItem(res?.data?.status?.data?.data);
        setMaxPageItem(res?.data?.status?.data?.last_page);
        setFormActive(
          resData?.data?.data.find((item) => {
            if (item?.check_rm === "Planilla de envío") {
              return true;
            } else return false;
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (idPass) fetch();
  }, [idPass, pageItem, endpoint, orderBy]);

  return {
    isLoading,
    maxPageItem,
    setMaxPageItem,
    currentItem,
    setCurrentItem,
    fetch,
    formActive
  };
};

export default useGetItemPass;
