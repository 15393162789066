/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { __Trm } from "request/configuration/__Trm";
import { LabelGroup, LabelGroups } from "pages/RefProductInter/LabelGroup";
import { Box } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useDispatch, useSelector } from "react-redux";
import "./ExchangeStyle.css";
import { useForm } from "hooks/useForm";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __PostForm } from "request/Petitions/__Post";
import { setDataStatusTrm } from "redux/actions/statusTrmaction";
import { __GetData } from "request/Petitions/__Get";

const ConfigurationTitle = styled.h4`
  color: #1f7ac3;
  text-transform: uppercase;
  font-weight: 600;
  display: inline;
  font-size: 1.2rem;
`;

export const TasaCambio = () => {
  const [trm, setTrm] = useState(0);
  const [trmSuper, setTrmSuper] = useState(0);
  const [day, setDay] = useState(null);
  const [dayNumber, setDayNumber] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [update, setUpdate] = useState(true);
  const [value, setValue] = useState("");
  const [openAlert] = AlertErrorAlert();
  const { trmValue } = useSelector((state) => state.statusTrm);
  const dispacthRedux = useDispatch();

  const token = useSelector((state) => state.auth.dataUser.token);

  const editDataTrm = async (data) => {
    try {
      const res = await __PostForm(environment.TRM_UPDATE, token, data);
      if (res.data.status.code === 200) {
        const dataValueTrm = res?.data?.status?.data.value_trm;
        localStorage.setItem("ValueTrm", JSON.stringify(dataValueTrm));
        dispacthRedux(setDataStatusTrm(!trmValue));
        setUpdate(!update);
        openAlert(`Registro actualizado Exitosamente`, "success");
      } else {
        openAlert(res.data.status.message[0], "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExchangeRate = useCallback(async () => {
    try {
      const res = await __Trm(token);
     // console.log(res?.data?.status?.data);
      setTrm(res?.data?.status?.data);
      const dataValueTrmGet = res?.data?.status?.data.value_trm;
      localStorage.setItem("ValueTrm", JSON.stringify(dataValueTrmGet));
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const getExchangeRateSuperFinancial = useCallback(async () => {
    try {
      const res = await __GetData(environment.TRM_GET_SUPERFINANCIAL, token);
      setTrmSuper(res?.data?.status.message.trm);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getExchangeRateSuperFinancial();
  }, [getExchangeRateSuperFinancial]);

  useEffect(() => {
    getExchangeRate();
  }, [getExchangeRate, update]);

  const handleUpdate = () => {
    const valueData = new FormData();
    valueData.append("value", value.replace(/,/g, ''));
    editDataTrm(valueData);
    setValue('');
  };

  const nameDay = useCallback(
    (fecha) =>
      [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
      ][new Date(fecha).getDay()],
    []
  );

  const nameMonth = useCallback(
    (fecha) =>
      [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ][new Date(fecha).getMonth()],
    []
  );

  useEffect(() => {
    const date = new Date();
    const diaNumber = new Date().getDate();
    const diaYear = new Date().getFullYear();
    setYear(diaYear);
    setDayNumber(diaNumber);
    setMonth(nameMonth(date));
    setDay(nameDay(date));
  }, [nameDay, nameMonth, dayNumber]);

  const quantityFormatter = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0 && numericValue.length > 3) {
        formattedValue += numericValue[i];
        formattedValue += ",";
      } else if (i === 4) {
        formattedValue += ".";
        formattedValue += numericValue[i];
      } else {
        formattedValue += numericValue[i];
      }
    }
    setValue(formattedValue);
  };

  return (
    <>
      <ConfigurationTitle>Valor de TRM Zona Franca</ConfigurationTitle>
      <div className="contenedor-trm">
        <div className="prev-info">
          <div className="mt-2 info-day">
            {day} {dayNumber} {month}
            <span className="ms-1">del</span> {year}
          </div>
          <div className="valueFormat">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(trm?.value_trm ?? 0)}
          </div>
          <div className="title-info-trm">
            <h1>Ultima actualización</h1>
          </div>
          <div className="info-previus">
            <h1>Fecha:</h1>
            <span className="ms-1">{trm?.date_start} </span>
          </div>

          <div className="info-previus">
            <h1>Hora:</h1>
            <span className="ms-1">{trm?.time}</span>
          </div>
        </div>

        <div className="prev-info">
          <ConfigurationTitle>Valor de TRM Superfinanciera</ConfigurationTitle>

          <div className="valueFormat">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(trmSuper ?? 0)}
          </div>
        </div>
      </div>
      <div className="prev-info mt-5">
        <LabelGroups
          name={"value"}
          onChange={quantityFormatter}
          value={value}
          maxLength={8}
          placeholder={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(trm?.value_trm ?? 0)}
          size={50}
          desicion={false}
        />
        <Box>
          <ButtonStyled wd={"264px"} hg={"40px"} onClick={handleUpdate}>
            Actualizar
          </ButtonStyled>
        </Box>
      </div>
    </>
  );
};
