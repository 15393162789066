import axios from "axios";

import env from "../../constants/apiConst";

export const __Plantillas = async (token, posicion, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    `${env.GET_PROCESO_ALL}?page=${posicion || 1}`,
    options
  );
  return res;
};

export const __SHOW_PLANTILLAS = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_PROCESO}/${id}`, options);
  return res;
};

export const __PlantillasUsers = async (token, id, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${env.GET_PROCESO_USER}/${id}`, options);
  return res;
};
