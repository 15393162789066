export const dateBy = [
  { value: 0, label: "Creación" },
  { value: 1, label: "Peso bruto" },
  { value: 2, label: "Peso tara" }
];

export const statePesaje = [
  { value: "Normal", label: "Normal" },
  { value: "Caducado", label: "Caducado" }
];

export const Operation = [
  { value: "Entrada", label: "Entrada" },
  { value: "Despacho", label: "Despacho" }
];

export const times = [
  { value: "NORMAL", label: "Normal" },
  { value: "CORRIDO", label: "Corrido" }
];

export const complentitud = [
  { value: "COMPLETO", label: "Completo" },
  { value: "INCOMPLETO", label: "Incompleto" }
];

export const withReweight = [
  { value: 1, label: "Si" },
  { value: 0, label: "No" }
];

export const OrderBy = [
  { value: "Fecha de creación", label: "Fecha de creación" },
  { value: "Fecha de peso bruto", label: "Fecha de peso bruto" },
  { value: "Fecha de peso tara", label: "Fecha de peso tara" },
  { value: "Fecha de peso transacción", label: "Fecha de peso transacción" },
  { value: "Consecutivo pesaje", label: "Consecutivo pesaje" },
  { value: "Pesaje", label: "Pesaje" }
];
