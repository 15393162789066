import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";

export const NationalTerritoryCorrect = ({
  data,
  handleChange,
  defaultDisabless
}) => {
  const { opcional } = useContext(FormCrudCRUDContext);
  const {
    commercial_invoice,
    certificate_inspection_pre_boarding,
    date,
    value_duta,
    date_corrected,
    certificate_inspection_pre_boarding_corrected,
    commercial_invoice_corrected,
    value_duta_corrected
  } = data;

  return (
    <>
      <LabelGroup
        name={"certificate_inspection_pre_boarding_corrected"}
        value={certificate_inspection_pre_boarding_corrected}
        tag={"Cert. de inspecc. preembarque"}
        onChange={handleChange}
        size={100}
        desicion={defaultDisabless}
        changeValue={
          certificate_inspection_pre_boarding !==
            certificate_inspection_pre_boarding_corrected && "BorderCorrect"
        }
      />
      <LabelGroup
        name={"date_corrected"}
        value={date_corrected}
        tag={"Fecha"}
        onChange={handleChange}
        size={100}
        changeValue={date !== date_corrected && "BorderCorrect"}
        tipo={"date"}
        desicion={defaultDisabless}
      />
      <LabelGroup
        name={"commercial_invoice_corrected"}
        onChange={handleChange}
        value={commercial_invoice_corrected}
        tag={"Factura Comercial"}
        changeValue={
          commercial_invoice !== commercial_invoice_corrected && "BorderCorrect"
        }
        size={100}
        desicion={defaultDisabless}
      />
      {opcional && (
        <LabelGroup
          name={"value_duta_corrected"}
          value={value_duta_corrected}
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          onChange={handleChange}
          tag={opcional}
          size={100}
          desicion={defaultDisabless}
        />
      )}
    </>
  );
};

/* import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage,
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus,
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment,
} from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { useState } from "react";

export const NationalTerritoryCorrect = ({
  data,
  handleChange,
  defaultDisabless,
}) => {
  const { opcional } = useContext(FormCrudCRUDContext);
  const {
    commercial_invoice,
    certificate_inspection_pre_boarding,
    date,
    value_duta,
    date_corrected,
    certificate_inspection_pre_boarding_corrected,
    commercial_invoice_corrected,
    value_duta_corrected,
    reviews,
  } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Status = data.status_outputFormCorrected;

  const FormId = data?.id;
  const [CurrentPos, setCurrentPos] = useState(0);
  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );
  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        //console.log("No encontrado");
      }
    } else {
      return "";
    }
  };
  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;

    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      console.log(name, FormId, FindElement);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  };

  return (
    <>
      {" "}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comentario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalComment
              obj={GetLocalStorage(FormId)}
              Pos={CurrentPos}
              FormId={FormId}
              onClose={onClose}
              setVerifiedStatus={setVerifiedStatus}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"certificate_inspection_pre_boarding_corrected"}
            value={certificate_inspection_pre_boarding_corrected}
            tag={"Cert. de inspecc. preembarque"}
            placeholder={"Cert. de inspecc. preembarque"}
            onChange={handleChange}
            size={100}
            desicion={false}
            ColorCustom={(x) =>
              certificate_inspection_pre_boarding !==
                certificate_inspection_pre_boarding_corrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            onClick={(x) => CorrectionComment(x)}
            readOnly={true}
          />
        ) : (
          <LabelGroup
            name={"certificate_inspection_pre_boarding_corrected"}
            value={certificate_inspection_pre_boarding_corrected}
            label={TooltipComentary(
              "certificate_inspection_pre_boarding_corrected"
            )}
            bg={"#900800"}
            tag={"Cert. de inspecc. preembarque"}
            onChange={handleChange}
            size={100}
            desicion={defaultDisabless}
            changeValue={
              certificate_inspection_pre_boarding !==
                certificate_inspection_pre_boarding_corrected && "BorderCorrect"
            }
          />
        )}

        {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"date_corrected"}
            value={date_corrected}
            tag={"Fecha"}
            placeholder={"Fecha"}
            onChange={handleChange}
            size={100}
            tipo={"date"}
            ColorCustom={(x) =>
              date !== date_corrected && Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            onClick={(x) => CorrectionComment(x)}
            desicion={false}
            readOnly={true}
          />
        ) : (
          <LabelGroup
            name={"date_corrected"}
            value={date_corrected}
            tag={"Fecha"}
            onChange={handleChange}
            label={TooltipComentary("date_corrected")}
            size={100}
            bg={"#900800"}
            changeValue={date !== date_corrected && "BorderCorrect"}
            tipo={"date"}
            desicion={defaultDisabless}
          />
        )}

        {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"commercial_invoice_corrected"}
            onChange={handleChange}
            value={commercial_invoice_corrected}
            tag={"Factura Comercial"}
            placeholder={"Factura Comercial"}
            ColorCustom={(x) =>
              commercial_invoice !== commercial_invoice_corrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            size={100}
            onClick={(x) => CorrectionComment(x)}
            desicion={false}
            readOnly={true}
          />
        ) : (
          <LabelGroup
            name={"commercial_invoice_corrected"}
            onChange={handleChange}
            label={TooltipComentary("commercial_invoice_corrected")}
            value={commercial_invoice_corrected}
            tag={"Factura Comercial"}
            bg={"#900800"}
            changeValue={
              commercial_invoice !== commercial_invoice_corrected &&
              "BorderCorrect"
            }
            size={100}
            desicion={defaultDisabless}
          />
        )}

        {opcional && (
          <LabelGroup
            name={"value_duta_corrected"}
            value={value_duta_corrected}
            changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
            onChange={handleChange}
            tag={opcional}
            size={100}
            desicion={defaultDisabless}
          />
        )}
      </>
    );
  };
   */
