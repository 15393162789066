/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { FormInput, Continuar, Cancelar } from './Stilos'
import { LabelStyled } from 'components/addProduct/CreateTemplate.styled'
import { useForm } from 'hooks/useForm'
import { LabelGroup, InputGroup } from './LabelGroup'
import ProductCRUDContext from 'context/ProductCRUDContext'
import { AuthContext } from 'context/AuthContext'
import { __Arancel } from 'request/productoInterno/__ProductoInterno'
import './selecr.css'
import { __AllQualifiedUSers } from 'request/configuration/__QualifiedUsers'
import {
  __SelectUnidad,
  __SelectUnidades
} from 'request/configuration/_AUnidad'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import useDebounceText from 'hooks/useSearchDebounce'
import { __ObtenerSubHeadingCode } from 'request/CustomsClearance.js/__CustomsClearence'
import { useCallback } from 'react'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import InputSelect from 'components/InputSelect/InputSelect'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { IconGeneric } from 'styled/Icons.styled'
import { HiOutlinePlus } from 'react-icons/hi'
import FormUnitMeasurement from 'pages/Configuration/forms/UnitMeasurement'
import environment from 'constants/apiConst'
import { MaxLength } from 'utils/MaxLength'
import { useDebounceError } from 'hooks/useDebounceError'
import { __GetDataNoPage } from 'request/Petitions/__Get'
import { NumericFormat } from 'react-number-format'
import isArrayNotEmpty, {
  isArray,
  isNumberFormatable,
  isString
} from 'utils/type-check-utils'
import { Regex } from 'utils/Regex'

export const ModalAdd = () => {
  const {
    dataToEdit,
    onCloseModal,
    editData,
    createData,
    isOpenCreate,
    onOpenCreate,
    onCloseCreate,
  } = useContext(ProductCRUDContext)

  const { onFmm, changeQualified, selectItemForm, update, setUpdate } =
    useContext(FormCrudCRUDContext)
  const { authUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [unidad, setUnidad] = useState('')
  const [arancel, setArancel] = useState('')
  const [bussines, setBussines] = useState('')
  const [userCa, setUserCa] = useState([])
  const [unidadUC, setUnidadUC] = useState([])
  const [status, setStatus] = useState(1)
  const { userQualified, setUserQuialified } = useContext(FormCrudCRUDContext)
  const { token } = useSelector(state => state.auth.dataUser)
  const [openAlert] = AlertErrorAlert()
  const [subheadingSearch, setSubheadingSearch] = useDebounceText()
  const [allTypeProducts, setAllTypeProducts] = useState([])
  const [typeProducts, setTypeProducts] = useState(null)
  const [loadingTypeProduct, setLoadingTypeProduct] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [execute, setExecute] = useState(false)

  const [values, handleInputChange, reset, setValues] = useForm({
    qualifiedUser_id: userQualified || 0,
    code_product: '',
    mendigo: '',
    unidad: '',
    description_product: '',
    group_product: '',
    convertion_factor_product: '',
    code_subheading: ''
  })
  const {
    code_product,
    description_product,
    group_product,
    convertion_factor_product,
    code_subheading
  } = values

  const Unidad = async () => {
    const idUnidad = onFmm
      ? changeQualified
      : isArray(userQualified) && isArrayNotEmpty(userQualified)
      ? userQualified[0]?.value
      : userQualified?.value || ''

    setLoading(true)
    try {
      const res =
        authUser.admin === 1 || authUser.is_commerce === 1
          ? userQualified?.length !== 0 &&
            idUnidad?.length !== 0 &&
            (await __SelectUnidad(token, idUnidad))
          : await __SelectUnidades(token)

      setUnidadUC(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (firstRenderUnit.current) {
      firstRenderUnit.current = false
      return
    }

    Unidad()
  }, [userQualified])

  const firstRenderUnit = useRef(true)

  const userC = async () => {
    setLoading(true)
    try {
      const res = await __AllQualifiedUSers(token)
      const infoUsuarios = res?.data?.status?.data.map(item => ({
        value: item?.id,
        label: item?.nit_qualified + ' - ' + item?.name_qualified,
        decimal: item?.decimal_values_precision
      }))

      setUserCa(infoUsuarios)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getTypeProducts = async () => {
    setLoadingTypeProduct(true)
    try {
      const res = await __GetDataNoPage(environment.PRODUCTS_ALL, token)
      const status = res?.data?.status
      if (status?.code === 200) {
        setAllTypeProducts(
          status?.data?.map(item => ({
            value: item?.id,
            label: item?.name_typeProduct
          }))
        )
      }
      setLoadingTypeProduct(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!execute) {
      userC()
      Unidad()
      getTypeProducts()
      setExecute(true)
    }
  }, [execute])

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      return
    }

    if (!userCa) return setUnidadUC([])

  

    if (dataToEdit) {
      const convertionFactorProduct = isNumberFormatable(
        dataToEdit?.convertion_factor_product || values?.factor_conv
      )
        ? parseFloat(
            dataToEdit?.convertion_factor_product || values?.factor_conv
          )
        : 0

      const { convertion_factor_product: _, ...rest } = dataToEdit
      rest.convertion_factor_product =
        convertionFactorProduct || values?.factor_conv

      setValues(rest)

      setSubheadingSearch(rest?.code_subheading)
      setUnidad({
        label: `${rest?.code_unit_measurement || ''} ${
          rest?.unit_measurement || ''
        }`,
        value: rest?.unitMeasurement_id
      })
      setTypeProducts({
        label: rest?.name_typeProduct,
        value: rest?.typeProduct_id
      })
      setStatus(rest?.status_product)

      if (userCa) {
        const searchUc = userCa.find(
          ({ value }) => value === rest?.qualifiedUser_id
        )
        if (searchUc) {
          setUserQuialified([searchUc])
        }
      }
    } else {
      const convertionFactorProduct = isNumberFormatable(
        values?.convertion_factor_product || values?.factor_conv
      )
        ? parseFloat(values?.convertion_factor_product || values?.factor_conv)
        : 0

      const { convertion_factor_product: _, ...rest } = values
      rest.convertion_factor_product =
        convertionFactorProduct || values?.factor_conv

      setValues(rest)
    }
  }, [dataToEdit, userCa])

  const addProduc = () => {
    const useQualified = isArray(userQualified)
      ? userQualified[0]?.value
      : userQualified?.value

    const informacion = {
      qualifiedUser_id:
        (authUser.admin === 1 || authUser.is_commerce === 1) && onFmm
          ? changeQualified
          : authUser.admin === 1 || authUser.is_commerce === 1
          ? useQualified
          : authUser.userQualified,
      code_product,
      subheading_id: bussines,
      businessUnit_id: unidad?.value || dataToEdit?.businessUnit_id,
      code_subheading,
      convertion_factor_product:
        isString(convertion_factor_product || values?.factor_conv) &&
        (convertion_factor_product?.includes(',') ||
          values?.factor_conv?.includes(','))
          ? convertion_factor_product.toString()?.replaceAll(',', '') ||
            values?.factor_conv?.toString()?.replaceAll(',', '') ||
            dataToEdit?.factor_conv
          : convertion_factor_product ||
            values?.factor_conv ||
            dataToEdit?.factor_conv,
      description_product,
      group_product,
      typeProduct_id: typeProducts?.value,
      status_product: status
    }

    const informacionC = {
      code_product,
      subheading_id: bussines,
      businessUnit_id: unidad?.value || dataToEdit?.businessUnit_id,
      code_subheading,
      convertion_factor_product:
        isString(convertion_factor_product || values?.factor_conv) &&
        (convertion_factor_product?.includes(',') ||
          values?.factor_conv?.includes(','))
          ? convertion_factor_product.toString()?.replaceAll(',', '') ||
            dataToEdit?.factor_conv
          : convertion_factor_product ||
            values?.factor_conv ||
            dataToEdit?.factor_conv,
      description_product,
      group_product,
      typeProduct_id: typeProducts?.value,
      status_product: status
    }

    if (Number(convertion_factor_product  ||  values?.factor_conv) <= 0) {
      return openAlert('El Factor de conversión debe ser mayor a 0', 'error')
    }
    if (!code_product) return openAlert('El codigo es necesario', 'error')
    if (!bussines) return openAlert('La Subpartida es necesaria', 'error')
    // if(!code_subheading) return openAlert('La Subpartida es necesaria', 'error')
    if (!dataToEdit) {
      if (!unidad) {
        return openAlert('La Unidad de Producto es necesaria', 'error')
      }

      if (!convertion_factor_product  &&  !values?.factor_conv) {
        return openAlert('El Factor de conversión es necesario', 'error')
      }
    }
    if (!description_product) {
      return openAlert('La Descripcion es necesaria', 'error')
    }
    if (!typeProducts?.value)
      return openAlert('El tipo de producto es necesario', 'error')
    let data = {}
    if (dataToEdit) {
      if (authUser.admin === 1 || authUser.is_commerce === 1) {
        if (selectItemForm >= 700 && selectItemForm <= 799) {
          data = {
            ...informacion,
            id: dataToEdit.product_id,
            transfer: 1
          }
          editData(data, onCloseModal)
          setUpdate(!update)
        } else {
          data = {
            ...informacion,
            id: dataToEdit.id
          }
          editData(data, onCloseModal)
        }
      } else {
        if (selectItemForm >= 700 && selectItemForm <= 799) {
          data = {
            ...informacionC,
            id: dataToEdit.product_id,
            transfer: 1
          }
          editData(data, onCloseModal)
          setUpdate(!update)
        } else {
          data = {
            ...informacionC,
            id: dataToEdit.id
          }
          editData(data, onCloseModal)
        }
      }
    } else {
      if (authUser.admin === 1 || authUser.is_commerce === 1) {
        createData(informacion, environment.CREATE_PRODUCT_INT)
        onCloseModal()
        reset()
      } else {
        createData(informacionC, environment.CREATE_PRODUCT_INT)
        onCloseModal()
        reset()
      }
    }
  }

  const fetchArancelData = useCallback(
    async subpartida => {
      try {
        const res = await __Arancel(token, subpartida)
        setArancel(res?.data?.status?.data.code_businessUnit)
        setBussines(res?.data?.status?.data.id)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    },
    [token]
  )

  const obtenerSubpartida = useCallback(
    async info => {
      try {
        const res = await __ObtenerSubHeadingCode(token, info)
        const resultado = res?.data?.status
        if (resultado.code === 403) {
          openAlert(resultado.message, 'error')
          setArancel('')
          setBussines('')
        }

        if (resultado.code === 200) {
          openAlert('La subpartida ha sido verificada y es correcta', 'success')
          fetchArancelData(resultado.data.code_subheading)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [openAlert, token, fetchArancelData]
  )

  useEffect(() => {
    if (!code_subheading) return
    console.log(3)

    if (code_subheading && code_subheading.length >= 10) {
      const formData = new FormData()
      formData.append('code_subheading', code_subheading)
      obtenerSubpartida(formData)
    } else {
      setArancel('')
      setBussines('')
    }
  }, [code_subheading])

  /*   const handleBlurCant = e => {
    const nameVariable = e.target.name
    let posibleResult = e.target.value

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(',', '')
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && userQualified
            ? userQualified.decimal
            : Number(decimal_values_precision),
          posibleResult
        )
      })
    }
  } */

  const [handleDebounceRegexError] = useDebounceError()

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all'
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    )
  }

  return (
    <>
      <Text fontSize='xl' mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? ' Editar Producto' : 'Agregar Producto'}
      </Text>
      <Divider mb={3} />

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              {(authUser.admin === 1 || authUser.is_commerce === 1) &&
              !onFmm ? (
                <div style={{ width: '45%' }}>
                  <InputSelect
                    tag={'Usuario calificado'}
                    data={userCa}
                    set={setUserQuialified}
                    value={
                      isArray(userQualified) ? userQualified[0] : userQualified
                    }
                    saveArray={true}
                  />
                </div>
              ) : null}

              {/* <InputGroupUser
                  partidas={userCa}
                  tag={"Usuario calificado"}
                  size={45}
                  setMendigo={setUserQuialified}
                  setNameQ={setNameQ}
                  nameQ={nameQ}
                  userQualified={userQualified}
                /> */}
              <LabelGroup
                name={'code_product'}
                onChange={e => handleRegex(e, 'all', Regex?.all)}
                value={code_product}
                tag={'Código'}
                size={45}
                desicion={false}
                maxLength={MaxLength.productReference.products.code_product}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              <LabelGroup
                name={'code_subheading'}
                onChange={handleInputChange}
                value={code_subheading}
                tag={'Subpartida'}
                size={45}
                desicion={selectItemForm >= 700 && selectItemForm <= 799}
                tipo={'search'}
                maxLength={MaxLength?.global?.code_subheading}
              />

              <LabelGroup
                name={'arancel'}
                value={arancel}
                tag={'Unidad de Arancel'}
                size={45}
                desicion={true}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              <div className='container-unidad-medida'>
                <div className='input-unidad'>
                  {/*              {authUser.admin === 1 || authUser.is_commerce === 1 ? ( */}
                  {/* <InputGroup
                      partidas={unidadCo}
                      tag={'Unidad de producto'}
                      size={100}
                      setMendigo={setUnidad}
                      unidad={unidad}
                    /> */}

                  <InputGroup
                    partidas={unidadUC}
                    tag={'Unidad de producto'}
                    size={100}
                    setMendigo={setUnidad}
                    unidad={unidad || dataToEdit?.product_unit}
                  />
                </div>

                <div className='btn-unidad'>
                  <Tooltip placement='top' hasArrow label='Agregar'>
                    <span>
                      <button onClick={onOpenCreate}>
                        <IconGeneric as={HiOutlinePlus} color='#fff' />
                      </button>
                    </span>
                  </Tooltip>
                </div>
              </div>

              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalSeparator='.'
                customInput={LabelGroup}
                name={'convertion_factor_product'}
                onChange={e => handleRegex(e, 'Números', Regex?.numeros)}
                value={convertion_factor_product || values?.factor_conv}
                tag={'Factor de conversión'}
                size={45}
                // onBlur={handleBlurCant}
                /*   maxLength={convertion_factor_product.includes('.') ? MaxLength.productReference.products.conversion_factor_product + 1 : MaxLength.productReference.products.conversion_factor_product} */
                desicion={
                  selectItemForm >= 700 && selectItemForm <= 799
                    ? true
                    : !!(
                        (authUser.admin === 1 || authUser.is_commerce === 1) &&
                        !userQualified &&
                        !onFmm
                      )
                }
              />
            </div>
            {/* && userQualified
        ? userQualified.decimal */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              <div style={{ width: '45%' }}>
                <InputSelect
                  tag={'Tipo de producto'}
                  data={allTypeProducts}
                  set={setTypeProducts}
                  value={typeProducts}
                  isLoading={loadingTypeProduct}
                />
              </div>
              <div style={{ width: '45%' }}>
                <LabelStyled>Descripción</LabelStyled>
                <FormInput
                  name='description_product'
                  value={description_product}
                  onChange={handleInputChange}
                  type='text'
                  maxLength={
                    MaxLength.productReference.products.description_product
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              <LabelGroup
                name={'group_product'}
                tag={'Grupo'}
                size={45}
                value={group_product}
                onChange={e => handleRegex(e, 'all', Regex?.all)}
                desicion={false}
                maxLength={MaxLength.productReference.products.group_product}
              />
              {dataToEdit ? (
                <>
                  <div
                    style={{
                      width: '45%'
                    }}
                  >
                    <LabelStyled>{'¿Activo?'}</LabelStyled>
                    <div className='form-check form-switch'>
                      <input
                        style={{ width: '45px', height: '25px' }}
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckChecked'
                        onChange={e => {
                          const status = {
                            true: 1,
                            false: 0
                          }
                          setStatus(status[e.target.checked])
                        }}
                        checked={status === 1}
                      />
                    </div>
                  </div>
                  {/*  <SwitchesGroup
                    status={status}
                    setStatus={setStatus}
                    tag={'¿Activo?'}
                    size={45}
                  /> */}
                </>
              ) : (
                ''
              )}
            </div>
            <Modal isOpen={isOpenCreate} onClose={onCloseCreate}>
              <ModalOverlay />
              <ModalContent>
                <Box p={4}>
                  <FormUnitMeasurement />
                </Box>
              </ModalContent>
            </Modal>
          </>
          <Flex>
            <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
            <Spacer />
            <Continuar
              disabled={!unidadUC?.length && onFmm}
              onClick={addProduc}
            >
              Aplicar
            </Continuar>
          </Flex>
        </>
      )}
    </>
  )
}
