import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IconAside, IconGeneric } from 'styled/Icons.styled'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Box,
  Tooltip
} from '@chakra-ui/react'
import { Storage } from 'hooks/useStorage'
import './style.css'
import { useSelector } from 'react-redux'
import { hasCommonElements, isArray } from 'utils/type-check-utils'

/**
 *  AccordionAside - Componente de accordion que despliega una serie de items en el componente del Aside
 *
 * @param {string} title Titulo del accordion.
 * @param {Array} items Lista de subItems a desplegar en el accordion
 * @param {Function} nameIcon Nombre del icono brindado por la libreria @chakra-ui/react.
 * @param {Function} onClick Funcion para realizar la navegacion a los distintos submodulos desplegados en el accordion.
 * @return {Component} Retorna componente de accordion.
 */

export const AccordionAside = React.forwardRef(
  (
    {
      nameIcon,
      title,
      items,
      onClick,
      allAccess = false,
      permissions = [],
      toggle = true,
      set,
      pathName,
      setPathName,
      movementName,
      setMovementName
    },
    ref
  ) => {
    //  const [myPermission, setmyPermission] = useState(null)
    const { get: getStorage, set: setStorage } = Storage
    const { permissionList } = useSelector(state => state.permissions)

    /*   useEffect(() => {
      if (!allAccess) {
        const myArrayCurrent = permissions?.filter(
          e => e.modul_permissionGroup === title
        )
        const newArray = []
        myArrayCurrent.map(item => {
          return item.permission.map(e => newArray.push(e.name_permission))
        })
        setmyPermission(newArray)
      }
    }, [permissions, title, allAccess]) */

    const obtenerPermisos = useCallback(
      e => {
        if (allAccess && isArray(permissionList) && isArray(e?.nameAccess))
          return true
        // return e.nameAccess?.some((a) => myPermission?.includes(a));
        return hasCommonElements(e.nameAccess, permissionList)
      },
      [allAccess, permissionList]
    )

    const handleSet = item => {
      setStorage('route', item.path)
      setStorage('search', item.name)
      setPathName(getStorage('route'))
      setMovementName(getStorage('search'))
      !toggle && set(true)
    }

    const filteredItems = useMemo(() => {
      return !allAccess ? items.filter(obtenerPermisos) : items
    }, [items, allAccess, obtenerPermisos])

    return (
      <Accordion allowToggle>
        <AccordionItem border='0px'>
          <AccordionButton activeClassName='active'>
            {toggle ? (
              <Tooltip placement='left' hasArrow label={title}>
                <Box
                  flex='1'
                  textAlign='left'
                  className='d-flex align-items-center'
                >
                  {nameIcon ? (
                    <IconGeneric color='#fff' as={nameIcon} />
                  ) : (
                    <img
                      src={filteredItems[0].image}
                      alt={filteredItems[0].image}
                    />
                  )}

                  {!toggle && (
                    <span className='ms-2 text-white fontAside'>{title}</span>
                  )}
                </Box>
              </Tooltip>
            ) : (
              <Box
                flex='1'
                textAlign='left'
                className='d-flex align-items-center'
              >
                {nameIcon ? (
                  <IconGeneric color='#fff' as={nameIcon} />
                ) : (
                  <img
                    src={filteredItems[0].image}
                    alt={filteredItems[0].image}
                  />
                )}

                {!toggle && (
                  <span className='ms-2 text-white fontAside'>{title}</span>
                )}
              </Box>
            )}

            {!toggle && (
              <AccordionIcon color='#fff' size={20} className='float-end' />
            )}
          </AccordionButton>

          {allAccess
            ? filteredItems.map((items, index) => (
                <AccordionPanel
                  pb={1}
                  key={index}
                  type='button'
                  className='text-white ms-2 fontForm'
                >
                  <NavLink
                    to={`${items.path}?movement=${items.name}`}
                    onClick={() => handleSet(items)}
                  >
                    <div className='d-flex '>
                      {toggle ? (
                        <Tooltip placement='left' hasArrow label={items.name}>
                          <span>
                            {items.src ? (
                              <IconAside
                                as={items.src}
                                className={
                                  pathName === items.path &&
                                  movementName === items.name
                                    ? 'focus'
                                    : 'notFocus'
                                }
                              />
                            ) : (
                              <img
                                className={
                                  pathName === items.path &&
                                  movementName === items.name
                                    ? 'imgFocus'
                                    : 'imgNotFocus'
                                }
                                src={items.image}
                                alt={items.image}
                              />
                            )}
                          </span>
                        </Tooltip>
                      ) : items.src ? (
                        <IconAside
                          className={
                            pathName === items.path &&
                            movementName === items.name
                              ? 'focus'
                              : 'notFocus'
                          }
                          as={items.src}
                        />
                      ) : (
                        <img
                          className={
                            pathName === items.path &&
                            movementName === items.name
                              ? 'imgFocus'
                              : 'imgNotFocus'
                          }
                          src={items.image}
                          alt={items.image}
                        />
                      )}

                      {!toggle && (
                        <span className='text-white fontAside ms-1'>
                          {items.name}
                        </span>
                      )}
                    </div>
                  </NavLink>
                </AccordionPanel>
              ))
            : !allAccess
            ? filteredItems.filter(obtenerPermisos).map((items, index) => (
                <AccordionPanel
                  pb={1}
                  key={index}
                  type='button'
                  onClick={() => onClick(items)}
                  className='text-white ms-2 fontForm'
                >
                  <NavLink
                    to={`${items.path}?movement=${items.name}`}
                    onClick={() => handleSet(items)}
                  >
                    <div className='d-flex '>
                      {toggle ? (
                        <Tooltip placement='left' hasArrow label={items.name}>
                          <span>
                            {items.src ? (
                              <IconAside
                                className={
                                  pathName === items.path &&
                                  movementName === items.name
                                    ? 'focus'
                                    : 'notFocus'
                                }
                                as={items.src}
                              />
                            ) : (
                              <img
                                className={
                                  pathName === items.path &&
                                  movementName === items.name
                                    ? 'imgFocus'
                                    : 'imgNotFocus'
                                }
                                src={items.image}
                                alt={items.image}
                              />
                            )}
                          </span>
                        </Tooltip>
                      ) : items.src ? (
                        <IconAside
                          className={
                            pathName === items.path &&
                            movementName === items.name
                              ? 'focus'
                              : 'notFocus'
                          }
                          as={items.src}
                        />
                      ) : (
                        <img
                          className={
                            pathName === items.path &&
                            movementName === items.name
                              ? 'imgFocus'
                              : 'imgNotFocus'
                          }
                          src={items.image}
                          alt={items.image}
                        />
                      )}
                      {!toggle && (
                        <span className='text-white fontAside ms-1'>
                          {items.name}
                        </span>
                      )}
                    </div>
                  </NavLink>
                </AccordionPanel>
              ))
            : ''}
        </AccordionItem>
      </Accordion>
    )
  }
)

AccordionAside.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  onClick: PropTypes.func,
  nameIcon: PropTypes.func
}
