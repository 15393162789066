/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import environment from "constants/apiConst";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { __PostForm } from "request/Petitions/__Post";
import queryString from "query-string";
import { Title } from "styled/Title";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { TitleItemsProfile } from "pages/Dian/ArrayTitleDian/ArrayTitle";
import TbodyItemProfile from "pages/Dian/Table/TbodyItemProfile";
import { Box } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";

import DianCRUDContext from "context/DianCrudContext";

const ItemProfileFinish = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [currentPage, setCurrentPage] = useState([]);
  const location = useLocation(); // navigate
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { IdForm = "", DocumentType = "" } = queryString.parse(location.search);
  const navigate = useNavigate();
  const { update } = useContext(DianCRUDContext);

  const handleReturn = () => {
    navigate(-1);
  };
  const getItemsProfile = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const res = await __PostForm(
          environment.VIEW_DOCUMENT_PROFILES,
          token,
          data
        );
        setCurrentPage(res?.data?.status?.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const dataItems = new FormData();
    dataItems.append("document_type", DocumentType);
    dataItems.append("form_id", IdForm);
    if (IdForm !== "" && DocumentType !== "") {
      getItemsProfile(dataItems);
    }
  }, [getItemsProfile, DocumentType, IdForm, update]);

  return (
    <>
      <Title style={{ marginTop: 30, marginBottom: 40 }}>Ítem perfilado</Title>
      <HeaderSearch>
        <div className="px-5">
          <div>
            <TableOrder
              thead={TitleItemsProfile}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyItemProfile}
              className={"verticalNone"}
            />

            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        </div>
      </HeaderSearch>

      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} type="submit" onClick={handleReturn}>
          volver
        </ButtonStyled>
      </Box>
    </>
  );
};

export default ItemProfileFinish;
