export const DocumentsList = [
  {
    value: "Cédula",
    label: "Cédula"
  },
  {
    value: "Pasaporte",
    label: "Pasaporte"
  },
  {
    value: "Cédula de extranjería",
    label: "Cédula de extranjería"
  }
];

export const ListCapacidades = [
  {
    value: 20,
    label: "20"
  },
  {
    value: 40,
    label: "40"
  },
  {
    value: 45,
    label: "45"
  }
];

export const ListCapacidadesLimitadas = [
  {
    value: 20,
    label: "20"
  }
]
