import React, { useContext } from "react";
import { IconMenuRUD } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { GiCardDiscard } from "react-icons/gi";
import { MdContentCopy, MdFactCheck } from "react-icons/md";
import { HrDividerNoMargin } from "styled/Line.styled";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { MenuItem } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import OrderProductionContext from "context/OrderProductionContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;
export const OptionsBorrador = ({ data }) => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const { changeStatus, setDataToCopy, onOpenCopy } = useContext(
    OrderProductionContext
  );

  const handleEdit = (e) => {
    navigate(`/CrearOrdenDeProduccion?Orden=${data?.production_order_id}`);
  };

  const handleChangeStatus = (status) => {
    const info = {
      id: data?.production_order_id,
      status_ProductionOrderForm: status
    };
    changeStatus(info);
  };

  const handleCopy = () => {
    setDataToCopy(data);
    onOpenCopy();
  };

  return (
    <>
      <MenuItemStyled py={3} onClick={handleEdit}>
        <IconMenuRUD as={RiEdit2Fill} />
        Editar
      </MenuItemStyled>
      <HrDividerNoMargin />
      <MenuItemStyled onClick={handleCopy} py={3}>
        <IconMenuRUD color="#F9A621" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      <HrDividerNoMargin />

      <MenuItemStyled onClick={() => handleChangeStatus("PRESENTADO")} py={3}>
        <IconMenuRUD as={MdFactCheck} />
        Presentar
      </MenuItemStyled>
      <HrDividerNoMargin />
     {/*  {authUser.is_qualified_user !== 1 && (
        <MenuItemStyled
          py={3}
          // onClick={handleDesechar}
        >
          <IconMenuRUD color="#d92525" as={GiCardDiscard} />
          Desechar
        </MenuItemStyled>
      )} */}
    </>
  );
};
