import React from "react";
import styled from "styled-components"; // Importa styled-components
import "./Style.css";
import { MinCard } from "styled/CardTotalesDash";

// Define los estilos para la disposición personalizada
const CustomCard = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  margin: 2px;
  height: 100%;
`;

const CustomSubtitle = styled.div`
  width: 40%;
  background-color: #006eb8;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomTitle = styled.div`
  width: 60%;
`;

export const CardTotal = ({ size, title, subtitle, identifier }) => {
  return (
    <MinCard backgroundColor={'#fff'}>
      <CustomCard>
        <CustomSubtitle>
          <span id={identifier}>{subtitle}</span>
        </CustomSubtitle>
        <CustomTitle>
          <span id="tituloTotal">{title}</span>
        </CustomTitle>
      </CustomCard>
    </MinCard>

  );
};
