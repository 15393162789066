import React from "react";

export const TbodyPDFOther = ({ data }) => {
  return (
    <tr>
      <td className="text-center">
        <span>{data?.name_cost}</span>
      </td>
      <td className="text-center">{""}</td>
      <td className="text-center">{data?.quantity_cost}</td>
    </tr>
  );
};
