/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import InputSelect from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
// import { LabelGroup } from "pages/RefProductInter/LabelGroup";q
import {
  __CalculateQuatity,
  __GetOrderForms,
  __OrdenNotPaginateCI
} from "request/IntegrationBox/___IntegrationBox";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { Box, SimpleGrid, Switch } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import environment from "constants/apiConst";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabeFormlStyled } from "styled/Input.styled";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __PostForm } from "request/Petitions/__Post";
import { findColorByStatus } from "hooks/useLocalStorage";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useGetInput } from "hooks/useGetInput";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { InputWithTooltip } from "components/Input/InputWithTooltip";
import { useDebounceAlert } from "hooks/useDebounceError";
import { useDebounce } from "hooks/useDebouce";
import { NumericFormat } from "react-number-format";
import queryEnv from "constants/queryConst";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { isArray } from "utils/type-check-utils";

const UpdateObjectList = (
  objectId,
  propertyToUpdate,
  newValue,
  objectList,
  setObjectList
) => {
  const newObject = {
    form_field: objectId,
    form_field_info_before: newValue ?? "N/A",
    form_field_info_now: "N/A"
  };

  if (objectList && typeof objectList === "object") {
    const objectIndex = objectList.findIndex(
      (item) => item.form_field === objectId
    );

    if (objectIndex === -1) {
      setObjectList((prevList) => [...prevList, newObject]);
    } else {
      const updatedObjectList = [...objectList];
      updatedObjectList[objectIndex][propertyToUpdate] = newValue;
      setObjectList(updatedObjectList);
    }
  }
};

const FormOrdenProduccion = ({ ...props }) => {
  const [dataOrder, setDataOrder] = useState([]);
  const [dataOrderProduction, setDataOrderProduction] = useState([]);
  const [productQuantity, setProductQuantity] = useState("");
  const [netweigth, setNetWeigth] = useState("");
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [updateValues, setUpdateValues] = useState(false);
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const { pathname } = location;
  const [openAlert] = AlertErrorAlert();
  const [decimal, setDecimal] = useState(null);
  const [objectList, setObjectList] = useState([]);
  const decimalScale = decimal || decimal_values_precision || 4;
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const [values, handleInputChange, reset, setValues] = useForm({
    product_business_unit_code: "",
    convertion_factor_product: "",
    description: "",
    product_description: "",
    product_subheading_code: "",
    product_unit_measurement: "",
    venta: false,
    qualifiedUser_id: "",
    destiny_id: "",
    production_order_id: "",
    net_weight_kilo: "",
    fob_value: "",
    quantity: "",
    quantity_product: ""
  });

  // eslint-disable-next-line no-unused-vars
  /*   const [form, handleInputOnChange, resets, setForm] = useForm({
      net_weight_kilo: "",
      fob_value: "",
      quantity: "",
      quantity_product: "",
    });
   */
  /*   const { net_weight_kilo, fob_value, quantity, quantity_product } = form; */

  const {
    product_business_unit_code,
    convertion_factor_product,
    description,
    product_description,
    product_subheading_code,
    product_unit_measurement,
    net_weight_kilo,
    fob_value,
    quantity,
    quantity_product,
    venta
  } = values;

  const {
    createData,
    setRequestCreate,
    setOrderProduccion,
    orderProduccion,
    userQualified,
    destiny,
    onCloseModal,
    dataToEditOrder,
    idOrder,
    userQualifiedCorrect,
    createDataCorrection,
    setRequestCreateCorrection,
    requestReviews,
    setProductionOrderReviews,
    setIdOrder
  } = useContext(IntegracionBoxCrudContex);
  const idItems = idOrder?.item_code ?? 0;

  useEffect(() => {
    if (net_weight_kilo && props?.Status === "DEVUELTO") {
      UpdateObjectList(
        `net_weight_kilo_${idItems}`,
        ["form_field_info_now"],
        net_weight_kilo,
        objectList,
        setObjectList
      );
    }
  }, [net_weight_kilo]);

  useEffect(() => {
    if (fob_value && props?.Status === "DEVUELTO") {
      UpdateObjectList(
        `fob_value_${idItems}`,
        ["form_field_info_now"],
        fob_value,
        objectList,
        setObjectList
      );
    }
  }, [fob_value]);

  useEffect(() => {
    if (quantity && props?.Status === "DEVUELTO") {
      UpdateObjectList(
        `quantity_${idItems}`,
        ["form_field_info_now"],
        quantity,
        objectList,
        setObjectList
      );
    }
  }, [quantity]);

  useEffect(() => {
    if (quantity_product && props?.Status === "DEVUELTO") {
      UpdateObjectList(
        `quantity_product_${idItems}`,
        ["form_field_info_now"],
        quantity_product,
        objectList,
        setObjectList
      );
    }
  }, [quantity_product]);

  const getOrderProduccion = useCallback(
    async (data) => {
      try {
        const res = await __OrdenNotPaginateCI(token, data);
        const dataOrder = res?.data?.status?.data.map((items) => ({
          value: items?.production_order_id,
          label:
            items?.production_order_code +
            "-" +
            items?.production_order_description
        }));
        setDataOrderProduction(res?.data?.status?.data);
        setDataOrder(dataOrder);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  const getCalculations = async (dato, campo) => {
    try {
      const res = await __CalculateQuatity(
        environment.CALCULATED_ORDER,
        token,
        dato
      );
      const dataCalculo = res?.data?.status?.data;
      if (res?.data?.status?.code !== 200) { return openAlert(res?.data?.status?.message, "error"); }

      if (campo === "text") {
        setValues((prev) => ({
          ...prev,
          quantity: dataCalculo?.quantity,
          net_weight_kilo: dataCalculo?.netWeight,
          quantity_product: dataCalculo?.quantity_product,
          fob_value: dataCalculo?.fob_value
        }));
      }

      if (campo === "number") {
        return setValues((prev) => ({
          ...prev,
          quantity_product: dataCalculo?.quantity_product,
          net_weight_kilo: dataCalculo?.netWeight,
          quantity: dataCalculo?.quantity,
          fob_value: dataCalculo?.fob_value
        }));
      }

      if (campo === "check") {
        console.log("dataCalculo", dataCalculo?.quantityUC);
        return setValues((prev) => ({
          ...prev,
          net_weight_kilo: dataCalculo?.netWeight,
          quantity: dataCalculo.quantity,
          quantity_product: dataCalculo?.quantity_product,
          fob_value: dataCalculo?.fob_value
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [searchProduct, setSearchProduct] = useState({
    type: "",
    value: ""
  });

  const onChangeCalc = (e) => {
    handleInputChange(e);
    if (!orderProduccion.value) {
      return openAlert("Debe seleccionar una orden de producción", "error");
    }
    const nombre = e.target.name;
    const valores = e.target.value;
    if ((valores !== "" || valores !== null) && nombre === "quantity_product") {
      const quantityProducts = new FormData();
      quantityProducts.append(
        "production_order_id",
        orderProduccion.value ?? ""
      );
      quantityProducts.append("cuantity", valores?.replaceAll(",", ""));
      quantityProducts.append("uc", true);
      setSearchProduct({ value: quantityProducts, type: "text" });
    } else if ((valores !== "" || valores !== null) && nombre === "quantity") {
      const quantityProduct = new FormData();
      quantityProduct.append(
        "production_order_id",
        orderProduccion?.value ?? ""
      );
      quantityProduct.append("cuantity", valores?.replaceAll(",", ""));
      quantityProduct.append("subheading", true);
      setSearchProduct({ value: quantityProduct, type: "number" });
    }
  };

  const searchDelay = useDebounce(searchProduct, 500);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getCalculations(searchDelay?.value, searchDelay?.type);
    }
  }, [searchDelay]);

  const firstRender = useRef(true);

  useEffect(() => {
    /* When it create the orderProduction */
    console.log({ orderProduccion, dataOrderProduction })
    if (!orderProduccion?.value) return reset();
    const currentDataOP = dataOrderProduction.find((item) => {
      return item?.production_order_id === orderProduccion?.value;
    });
    if (!currentDataOP) return;
    setOrderProduccion((prev) => ({
      ...prev,
      label:  currentDataOP?.production_order_description ? currentDataOP?.production_order_description : prev?.label
    }))
    setProductQuantity(currentDataOP?.quantity_product);
    setNetWeigth(currentDataOP?.net_weight_kilo);
    setValues({
      ...values,
      product_business_unit_code: currentDataOP?.product_business_unit_code,
      convertion_factor_product: currentDataOP?.convertion_factor_product,
      product_description: currentDataOP?.product_description,
      description: currentDataOP?.product_description,
      product_subheading_code: currentDataOP?.product_subheading_code,
      product_unit_measurement: currentDataOP?.product_unit_measurement_code,
      venta: false
    });
  }, [orderProduccion?.value, dataOrderProduction, idOrder]);

  useEffect(() => {
    if (!userQualified && !userQualifiedCorrect) return;
    const formData = new FormData();
    formData.append(
      "qualifiedUser_id",
      userQualified?.value || userQualifiedCorrect?.value
    );
    getOrderProduccion(formData);
  }, [getOrderProduccion]);

  useEffect(() => {
    // Cargo los datos que vienen en la tabla
    setOrderProduccion({
      value: idOrder?.production_order_id,
      label: idOrder?.description_product
    });

    setValues((prev) => ({
      ...prev,
      product_business_unit_code: idOrder?.unit_measurement,
      convertion_factor_product: idOrder?.convertion_factor_product,
      quantity_product: idOrder?.quantity_product,
      product_description: idOrder?.description_product,
      description: idOrder?.description_product,
      product_subheading_code: idOrder?.code_subheading,
      venta: !!idOrder?.type_purchase,
      quantity: idOrder?.quantity,
      product_unit_measurement: idOrder?.business_unit,
      net_weight_kilo: idOrder?.net_weight_kilo,
      fob_value: idOrder?.fob_value
    }));
  }, [idOrder]);

  useEffect(() => {
    if (idOrder && orderProduccion?.value !== idOrder.production_order_id) {
      setUpdateValues(true);
    } else {
      setUpdateValues(false);
    }
  }, [orderProduccion?.value]);

  useEffect(() => {
    setRequestCreate(environment.CREATE_INTEGRATION_BOX_EDIT);
  }, [setRequestCreate]);

  useEffect(() => {
    setRequestCreateCorrection(
      environment.CREATE_NEW_ORDER_OR_PRODUCT_CORRECTED
    );
  }, [setRequestCreateCorrection]);

  useEffect(() => {
    const quantityProduct = new FormData();
    console.log("venta: ", venta);
    console.log("orderProduccion: ", orderProduccion);
    quantityProduct.append("production_order_id", orderProduccion?.value ?? "");
    quantityProduct.append("cuantity", productQuantity?.replaceAll(",", ""));
    if (venta && orderProduccion && orderProduccion?.value) {
      getCalculations(quantityProduct, "check");
      return;
    }

    setValues((prev) => ({ ...prev }));
  }, [venta]);

  function parseNumberWithCommas(value) {
    return Number(value?.toString().replaceAll(",", "") || 0);
  }
  const queryClient = useQueryClient();

  const invalidateQueryFn = () => {
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.PRODUCTS_CI] })
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] })
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const quantityProduct =
      (typeof quantity_product === "string" &&
        (!quantity_product.trim() || quantity_product === "")) ||
      (typeof quantity_product === "number" && quantity_product <= 0);

    if (quantityProduct) {
      openAlert("Ingrese una cantidad válida para el producto", "error");
      return;
    }

    if (!orderProduccion?.value) {
      return openAlert("Debes seleccionar una orden de producción", "error");
    }

    const formData = new FormData();
    formData.append("id", id ?? idCorrected);
    formData.append(
      "qualifiedUser_id",
      authUser.is_qualified_user === 1
        ? authUser.userQualified
        : userQualified !== null
          ? userQualified.value
          : userQualifiedCorrect === null
            ? userQualifiedCorrect.value
            : ""
    );
    formData.append(
      "production_order_id",
      orderProduccion !== null || undefined ? orderProduccion?.value : ""
    );
    formData.append("destiny_id", destiny?.value ?? "");
    formData.append("observations", props.observations);
    formData.append("net_weight_kilo", parseNumberWithCommas(net_weight_kilo));
    formData.append("quantity_product", quantity_product ?? 0);
    formData.append("quantity", quantity ?? 0);
    formData.append("fob_value", parseNumberWithCommas(fob_value));

    if (dataToEditOrder) {
      if (updateValues) {
        formData.append("edit", true);
        formData.append("old_production_order_id", idOrder.production_order_id);
      } else {
        formData.append("edit", true);
      }
      createData(formData, invalidateQueryFn);
      handleReset();
    } else if (props?.Status && props?.Status === "CORREGIDO") {
      formData.append("cert_corrected", true);
      createData(formData, invalidateQueryFn);
      handleReset();
    } else {
      createData(formData, invalidateQueryFn);
      handleReset();
    }
  };

  const handleSubmitCorrected = (e) => {
    e.preventDefault();
    let netWeightKilo = net_weight_kilo;
    let quantityProduct = quantity_product;
    let quantitys = quantity;
    let fobValue = fob_value;

    const quantityProductValidation =
      (typeof quantity_product === "string" &&
        (!quantity_product.trim() || quantity_product === "")) ||
      (typeof quantity_product === "number" && quantity_product <= 0);

    if (quantityProductValidation) {
      openAlert("Ingrese una cantidad válida para el producto", "error");
      return;
    }

    const formData = new FormData();
    formData.append("integrationCertificateCorrected_id", idCorrected);
    formData.append(
      "productionOrderCorrected_id",
      orderProduccion !== null || undefined ? orderProduccion?.value : ""
    );
    if (String(net_weight_kilo).includes(",")) {
      netWeightKilo = net_weight_kilo.replaceAll(",", "");
    }
    formData.append("net_weight_kilo_corrected", netWeightKilo);

    if (String(quantity_product).includes(",")) {
      quantityProduct = quantity_product.replaceAll(",", "");
    }
    formData.append("quantity_product_corrected", quantityProduct);

    if (String(quantity).includes(",")) {
      quantitys = quantity.replaceAll(",", "");
    }
    formData.append("quantity_corrected", quantitys);
    if (String(fob_value).includes(",")) {
      fobValue = fob_value.replaceAll(",", "");
    }
    formData.append("fob_value_corrected", fobValue);
    createDataCorrection(formData, invalidateQueryFn);
    handleReset();
  };

  const handleReset = () => {
    setOrderProduccion([]);
    onCloseModal();
    reset();
  };

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimal(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userQualified) userC(userQualified.value);
  }, [userC, userQualified]);

  const {
    handleSwitchStatus,
    selectedObservationIndex,
    setObservations,
    observations
  } = useGetInput({
    storageKey: id,
    onOpen: props.onOpenComment
  });

  const GetTooltipReview = (name, requestReviews) => {
    if (!requestReviews && !isArray(requestReviews)) {
      return;
    }

    console.log({requestReviews})
    /* const index = requestReviews?.findIndex(
      (element) => element?.form_field === name
    );

    return index !== -1 ? requestReviews[index]?.comment : null; */
  };
  useEffect(() => {
    if (objectList) setProductionOrderReviews(objectList);
  }, [objectList]);

  const [handleDebounceAlert] = useDebounceAlert();

  const handleRegex = ({ target }) => {
    const { value, name } = target;
    let intPart;
    // I want the int part of th value }
    // Remove non-digit characters except dots
    const numericInput = value.replace(/[^\d.]/g, "");
    const decimalPart = numericInput.slice(
      numericInput?.indexOf("."),
      numericInput?.length
    );
    if (decimalPart?.length > 5) {
      return handleDebounceAlert(
        "El número de decimales no puede exceder los 4 digitos."
      );
    }

    // Validate if the dot is in the first character of the number and if it is, add 0 to the number
    if (numericInput.startsWith(".")) {
      return handleInputChange({ target: { value: "0.", name } });
    }

    // Validate only one dot in the number input
    if (numericInput.indexOf(".") !== -1) {
      if (numericInput.indexOf(".") !== numericInput.lastIndexOf(".")) {
        return handleDebounceAlert(
          "El número solo puede tener un punto decimal"
        );
      }
      intPart = parseInt(
        numericInput?.slice(0, numericInput?.indexOf("."))
      ).toLocaleString("en-US");
      const number = intPart + decimalPart || "";
      return handleInputChange({ target: { value: number, name } });
    }

    const number =
      numericInput.length !== 0
        ? parseInt(numericInput).toLocaleString("en-US")
        : "";
    return handleInputChange({ target: { value: number, name } });
  };

  return (
    <>
      {orderProduccion?.value &&
        productQuantity !== null &&
        productQuantity !== "" && (
          <div className="mb-4" id="adventenciaReturn">
            <h1
              style={{
                fontSize: "1.2rem",
                fontFamily: "Roboto",
                color: "#171a22"
              }}
            >
              Esta orden de producción tiene, {productQuantity} productos
              disponibles.
            </h1>
          </div>
        )}

      <form>
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            <InputSelect
              tag={"Orden de producción"}
              size={100}
              data={dataOrder}
              set={setOrderProduccion}
              value={orderProduccion ?? ""}
            />
          </div>

          <div>
            {props?.Status !== "PRESENTADO" ? (
              <InputWithTooltip
                disabled={true}
                name={"product_business_unit_name"}
                value={product_business_unit_code}
                onChange={handleInputChange}
                tag={"Unidad de producto"}
                error={findColorByStatus(
                  `product_business_unit_name_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `product_business_unit_name_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `product_business_unit_name_${idItems}`,
                  requestReviews
                )}
                size={90}
                readOnly={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`product_business_unit_name_${idItems}`}
                labelText={"Unidad de producto"}
                value={product_business_unit_code ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `product_business_unit_name_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                // disabled={isDisabled}

                data-name-id={"Unidad de producto"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {props?.Status !== "PRESENTADO" ? (
              <InputWithTooltip
                disabled={true}
                name={`convertion_factor_product`}
                value={convertion_factor_product}
                onChange={handleInputChange}
                tag={"Factor conversión"}
                error={findColorByStatus(
                  `product_convertion_factor_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `product_convertion_factor_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `product_convertion_factor_${idItems}`,
                  requestReviews
                )}
                size={90}
                readOnly={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`product_convertion_factor_${idItems}`}
                labelText={"Factor conversión"}
                value={convertion_factor_product ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `product_convertion_factor_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                    `business_name`,
                    reviewUpdates
                  )} */
                data-name-id={"Factor conversión"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          {/*   <div>
            <LabelGroupColor
              name={"quantity_product"}
              tag={"Cantidad producto"}
              size={90}
              value={quantity_product}
              onChange={onChangeCalc}
              desicion={
                seller === 1
                  ? true
                  : orderProduccion?.length === 0
                  ? true
                  : false
              }
              readOnly={props?.Status === "PRESENTADO" ? true : false}
              required
            />
          </div> */}

          <div>
            {props?.Status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                name={`quantity_product`}
                value={quantity_product}
                onChange={onChangeCalc}
                tag={"Cantidad producto"}
                error={findColorByStatus(
                  `quantity_product_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `quantity_product_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `quantity_product_${idItems}`,
                  requestReviews
                )}
                disabled={venta || orderProduccion?.length === 0}
                size={90}
                readOnly={props?.Status === "PRESENTADO"}
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`quantity_product_${idItems}`}
                labelText={"Cantidad producto"}
                value={quantity_product ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `quantity_product_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                    `business_name`,
                    reviewUpdates
                  )} */
                data-name-id={"Cantidad producto"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {props?.Status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`product_description`}
                value={product_description}
                onChange={handleInputChange}
                tag={"Descripción del producto"}
                error={findColorByStatus(
                  `product_description_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `product_description_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `product_description_${idItems}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`product_description_${idItems}`}
                labelText={"Descripción del producto"}
                value={product_description ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `product_description_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                `business_name`,
                reviewUpdates
              )} */
                data-name-id={"Descripción del producto"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {props?.Status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`description`}
                value={description}
                onChange={handleInputChange}
                tag={"Descripción"}
                error={findColorByStatus(
                  `description_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `description_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `description_${idItems}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`description_${idItems}`}
                labelText={"Descripción"}
                value={description ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `description_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                `business_name`,
                reviewUpdates
              )} */
                data-name-id={"Descripción"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {props?.Status !== "PRESENTADO" ? (
              /*  <LabelGroup
                  name={"product_subheading_code"}
                  tag={"Subpartida"}
                  size={100}
                  value={product_subheading_code}
                  onChange={handleInputChange}
                  desicion={true}
                /> */

              <InputWithTooltip
                name={`subheading_code`}
                value={product_subheading_code ?? "N/A"}
                onChange={handleInputChange}
                tag={"Subpartida"}
                error={findColorByStatus(
                  `subheading_code_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `subheading_code_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `subheading_code_${idItems}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`subheading_code_${idItems}`}
                labelText={"Subpartida"}
                value={product_subheading_code ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `subheading_code_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                `business_name`,
                reviewUpdates
              )} */
                data-name-id={"Subpartida"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div
            style={{
              width: "50%",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "2%"
            }}
          >
            <LabeFormlStyled htmlFor=" venta">
              {venta ? "Venta total" : "Venta parcial"}
            </LabeFormlStyled>
            <div>
              <Switch
                name="venta"
                id="venta"
                size="lg"
                onChange={handleInputChange}
                isChecked={venta}
                isDisabled={orderProduccion?.length === 0}
              />
            </div>
          </div>
          <div>
            {props?.Status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                name={`quantity`}
                value={quantity ?? 0}
                onChange={onChangeCalc}
                tag={"Cantidad"}
                error={findColorByStatus(`quantity_${idItems}`, requestReviews)}
                msgTooltip={GetTooltipReview(
                  `quantity_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `quantity_${idItems}`,
                  requestReviews
                )}
                disabled={venta || orderProduccion?.length === 0}
                readOnly={props?.Status === "PRESENTADO"}
                required
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`quantity_${idItems}`}
                labelText={"Cantidad"}
                value={quantity ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `quantity_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                `business_name`,
                reviewUpdates
              )} */
                data-name-id={"Cantidad"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {props?.Status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`business_unit_code`}
                value={product_unit_measurement}
                onChange={handleInputChange}
                tag={"Unidad comercial"}
                error={findColorByStatus(
                  `business_unit_code_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `business_unit_code_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `business_unit_code_${idItems}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`business_unit_code_${idItems}`}
                labelText={"Unidad comercial"}
                value={product_unit_measurement ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `business_unit_code_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Unidad comercial"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {props?.Status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                name={"net_weight_kilo"}
                value={net_weight_kilo === 0 ? netweigth : net_weight_kilo}
                onChange={(e) => handleRegex(e)}
                tag={"Peso neto en kilos"}
                error={findColorByStatus(
                  `net_weight_kilo_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `net_weight_kilo_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `net_weight_kilo_${idItems}`,
                  requestReviews
                )}
                readOnly={false}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`net_weight_kilo_${idItems}`}
                labelText={"Peso neto en kilos"}
                value={net_weight_kilo ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `net_weight_kilo_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                readOnly={true}
                disabled={true}
                data-name-id={"Peso neto en kilos"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {props?.Status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                name={`fob_value`}
                value={fob_value}
                onChange={(e) => handleRegex(e)}
                tag={"Valor FOB en US$"}
                placeholder="0"
                error={findColorByStatus(
                  `fob_value_${idItems}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `fob_value_${idItems}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `fob_value_${idItems}`,
                  requestReviews
                )}
                required
                readOnly={false}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                onChange={(e) => handleRegex(e)}
                borderRadius={"none"}
                name={`fob_value_${idItems}`}
                labelText={"Valor FOB en US$"}
                value={
                  fob_value
                    ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2
                    }).format(fob_value)
                    : fob_value
                }
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `fob_value_${idItems}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                `business_name`,
                reviewUpdates
              )} */
                data-name-id={"Valor FOB en US$"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <div className="mb-3 ms-5">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>

            {pathname === "/IntegracionBox" &&
              props?.Status !== "PRESENTADO"
              ? (
                <ButtonStyled type="submit" onClick={handleSubmit}>
                  Guardar
                </ButtonStyled>
              )
              : pathname === "/CorrectedCertificate" &&
                props?.Status !== "PRESENTADO"
                ? (
                  <ButtonStyled type="submit" onClick={handleSubmitCorrected}>
                    Guardar
                  </ButtonStyled>
                )
                : (
                  ""
                )}
          </Box>
        </div>
      </form>
    </>
  );
};

export default FormOrdenProduccion;
