import React, { useState, useEffect } from 'react'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'

import InputSelect from 'components/InputSelect/InputSelect'

const MenuReviewsUpdate = ({ data }) => {
  const token = useSelector(state => state.auth.dataUser.token)
  const [UsersForSelect, setUsersForSelect] = useState()
  const [Input_Select, setInputSelect] = useState()

  const Id = data?.id;
  const TypeDoc = data?.type_doc;
  const DefaultId = data?.user_id_review

  useEffect(() => {
    if (data) {
      UsersSelect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (UsersForSelect) {
      const DefaultUser = UsersForSelect.find(
        ({ value }) => value === DefaultId
      )

      if (DefaultUser) {
        setInputSelect({
          value: DefaultUser.value,
          label: DefaultUser.label,
          id: DefaultUser.value
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UsersForSelect])


  const UsersSelect = () => {
    let SelectList
    if (data.users) {
      SelectList = data.users.map(({ nickname, user_id }) => ({
        label: nickname,
        value: user_id
      }))
    }
    setUsersForSelect(SelectList)
  }


  /* Esto es para permitir deseleccionar el usuario */
  const handleChange = async (id) => {
    const obj = {
      id: Id,
      ...(id && { user_id_review: id }),
      type_doc: TypeDoc
    }

    try {
      const res = await __Post(environment.SET_REVIEW_USER, token, obj)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <InputSelect
        tag={'UsersForSelect'}
        data={UsersForSelect}
        set={(e) => {
          setInputSelect(e);
          /* Permite null para limpiar el campo */
          handleChange(e?.value)
        }}
        value={Input_Select}
        labelDisable={true}
      />
    </>
  )
}

export default MenuReviewsUpdate
