import React from "react";
import { Title } from "styled/Title";

export const TableInformation = ({ values }) => {
  const { difference, other_costs, supply, total_calculated, total_product } =
    values?.observation_totals || {};

  return (
    <>
      <div className="p-1 mx-1 mt-2">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td></td>
              <td>
                <Title size={"16px"} weight={600}>
                  Peso en kilos
                </Title>
              </td>
              <td>
                <Title size={"16px"} weight={600}>
                  Total en US$
                </Title>
              </td>
            </tr>

            <tr>
              <td>
                <Title size={"16px"} weight={600}>
                  Materia e insumos
                </Title>
              </td>
              {supply &&
                supply.map((item, index) => <td key={index}>{item}</td>)}
            </tr>

            <tr>
              <td>
                <Title size={"16px"} weight={600}>
                  Otros costos
                </Title>
              </td>
              {other_costs &&
                other_costs.map((item, index) => <td key={index}>{item}</td>)}
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td>
                <Title size={"16px"} weight={600}>
                  Total calculado{" "}
                </Title>
              </td>
              {total_calculated &&
                total_calculated.map((item, index) => (
                  <td key={index}>{item}</td>
                ))}
            </tr>

            <tr>
              <td>
                <Title size={"16px"} weight={600}>
                  Total producto
                </Title>
              </td>
              {total_product &&
                total_product.map((item, index) => <td key={index}>{item}</td>)}
            </tr>

            <tr>
              <td>
                <Title size={"16px"} weight={600}>
                  Diferencia
                </Title>
              </td>
              {difference &&
                difference.map((item, index) => <td key={index}>{item}</td>)}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
