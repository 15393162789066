import { Box } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { H1 } from "pages/Pass-Form/stylePases";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import HeadboardList from "../Headboards/HeadboardList";

const ListDian = () => {
  const typeForm = [
    { value: "Ingreso", label: "Ingreso" },
    { value: "Salida", label: "Salida" }
  ];

  return (
    <>
      <HeaderSearch>
        <HeadboardList>
          <H1>Reporte listado Dian</H1>
        </HeadboardList>
      </HeaderSearch>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: " 24%" }}>
            <InputSelect
              tag={"Documento listado"}
              data={typeForm}
              size={355}
              // set={setInfo}
              // value={info === null ? "" : info}
            />
          </div>
          <div></div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5%",
            paddingRight: "24%"
          }}
        >
          <div>
            <LabelGroup
              tipo={"date"}
              name={"code_subheading"}
              // onChange={handleInputChange}
              // onChange={handleInputChange}
              // value={
              //   qualification_date === null
              //     ? ""
              //     : qualification_date.slice(0, 10)
              // }
              tag={"Fecha revisión inicio"}
              size={160}
              desicion={false}
            />
          </div>
          <div>
            <LabelGroup
              tipo={"date"}
              name={"description_subheading"}
              // onChange={handleInputChange}
              // value={
              //   qualification_date === null
              //     ? ""
              //     : qualification_date.slice(0, 10)
              // }
              tag={"Fecha revisión final"}
              size={160}
              desicion={false}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5%",
            paddingRight: "20%"
          }}
        >
          <div>
            <LabelGroup
              tipo={"time"}
              name={"code_subheading"}
              // onChange={handleInputChange}
              // onChange={handleInputChange}
              // value={
              //   qualification_date === null
              //     ? ""
              //     : qualification_date.slice(0, 10)
              // }
              tag={"Hora de inicio"}
              size={208}
              desicion={false}
            />
          </div>
          <div style={{ marginRight: "13%" }}>
            <LabelGroup
              tipo={"time"}
              name={"description_subheading"}
              // onChange={handleInputChange}
              // value={
              //   qualification_date === null
              //     ? ""
              //     : qualification_date.slice(0, 10)
              // }
              tag={"Hora final"}
              size={228}
              desicion={false}
            />
          </div>
        </div>

        <Box
          display="flex"
          alignItems="center"
          marginTop={"40px"}
          marginRight={"74px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled type="submit">Generar</ButtonStyled>
        </Box>
      </div>
    </>
  );
};

export default ListDian;
