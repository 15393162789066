import { searchTypes } from 'redux/types/searchTypes';

export const searchReducer = (state = {}, action) => {
  switch (action.type) {
    case searchTypes.SEARCH:
      return {
        ...action.payload
      }
    case searchTypes.CLEAR:
      return {}
    default:
      return state
  }
};
