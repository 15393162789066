/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { __ShowSectors } from "request/configuration/__Sectors";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

export const FormSectors = () => {
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    name_sector: ""
  });
  const { name_sector } = values;

  useEffect(() => {
    if (dataToEdit) {
      const initialpos = async () => {
        try {
          const res = await __ShowSectors(token, dataToEdit.id);
          setValues(res?.data?.status?.data);
        } catch (error) { }
      };
      initialpos();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (name_sector === "") {
      openAlert("El nombre es requerido", "error");
    } else {
      if (dataToEdit) {
        editData(values);
      } else {
        createData(values);
      }
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Sector" : "Crear Sector"}
      </Text>
      <Divider mb={3} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name="name_sector"
          tag={"Nombre del sector"}
          value={name_sector}
          onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.sectors.name_sector}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};
