/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, TabPanel } from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import Pagination from "components/Pagination/Pagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TableOrder from "components/Tables/TableOrder";
import { useSelector } from "react-redux";
import { headerTabs } from "./ArrayOrder/ArrayHeadOrder";
import {
  elaborateProductDos,
  HeadGarbages,
  HeadOtherCosts,
  HeadOtherCostsNoEdit,
  Matter,
  MatterNoEdit,
  MatterNoUC,
  MatterNoUCNoEdit,
} from "./ArrayOrder/ArrayTableOrder";
import TBodyMatter from "./CreateNewProductoOrder/Table/TBodyMatter";
import TbodyOtherCosts from "./CreateNewProductoOrder/Table/TbodyOtherCosts";
import TBodyGarbages from "./CreateNewProductoOrder/Table/TBodyGarbages";
import { TabsCreateOrder } from "./TabsCreate/TabsCreateOrder";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __ShowFormOrder } from "request/OrderProduction/OrderProduction";
import OrderProductionContext from "context/OrderProductionContext";
import { BtnShowOrder } from "./BotonesShow/BtnShowOrder";
import { SatausOfOrder } from "./BotonesShow/SatausOfOrder";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TableInformation } from "./TableInormativa/TableInformation";
import TBodyElaboradoNoAccion from "./CreateNewProductoOrder/Table/TBodyElaboradoNoAccion";
import { tdGarbages, tdMatter, tdOther, tdProduct } from "./Tdfalse/TdFlase";
import ModalCopy from "components/modal/ModalCopy";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { ExportPdf } from "./ExportPdf";
import TbodyUnd from "./CreateNewProductoOrder/Table/TbodyUnd";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import TbodyGarbagesUnit from "./CreateNewProductoOrder/Table/TbodyGarbagesUnit";
import { CreateCI } from "./CreateCI";
import {
  useQuery,
  keepPreviousData,
  useQueryClient,
} from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import { isArray, isValid } from "utils/type-check-utils";
import TBodyMatterNoEdit from "./CreateNewProductoOrder/Table/TBodyMatterNoEdit";
import TbodyOtherCostsNoEdit from "./CreateNewProductoOrder/Table/TbodyOtherCostsNoEdit";
import InputSelect from "components/InputSelect/InputSelect";

export const ShowProductionOrder = () => {
  const initialForm = {
    production_order_status: "",
    production_order_code: "",
    qualified_users_name: "",
    qualified_users_id: "",
    difference: [],
    other_costs: [],
    supply: [],
    total_calculated: [],
    total_product: [],
    observation_totals: [],
  };
  const token = useSelector((state) => state.auth.dataUser.token);
  const dataUser = useSelector((state) => state.auth.dataUser);
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [positionView, setPositionView] = useState(0);
  const [currentPage, setCurrentPage] = useState([]);
  const location = useLocation(); // navigate
  const { Orden = null } = queryString.parse(location.search);
  const { requestData } = useGlobalContext();
  const positionProperties = ["product", "supply", "other_costs", "garbage"];


  const [page, setPage] = useState({
    product: 1,
    supply: 1,
    other_costs: 1,
    garbage: 1,
  });

  const {
    update,
    setDataToGeneral,
    isOpenCopy,
    onCloseCopy,
    dataToCopy,
    copyData,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenCreateCIntegration,
    onOpenCreateCIntegration,
    onCloseCreateCIntegration,
  } = useContext(OrderProductionContext);

  const handleCambio = (index) => {
    setPositionView(index);
  };

  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })


  const QueryConfig = {
    GET_PRODUCT_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_PRODUCT_ITEM_OP,
          method: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.product,
          data: { orderBy: orderBy.value },
        }),
    },
    GET_SUPPLY_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_SUPPLY_ITEM_OP,
          method: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.supply,
          data: { orderBy: orderBy.value },
        }),
    },
    GET_OTHERCOST_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_OTHERCOST_ITEM_OP,
          method: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.other_costs,
          data: { orderBy: orderBy.value },
        }),
    },
    GET_GARBAGE_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_GARBAGE_ITEM_OP,
          method: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.garbage,
          data: { orderBy: orderBy.value },
        }),
    },
    SHOW_PRODUCTION_ORDER: {
      queryFn: () =>
        requestData({
          Endpoint: environment.SHOW_PRODUCTION_ORDER,
          method: "GET",
          resourceIdentifier: Orden,
          data: { orderBy: orderBy.value },
        }),
    },
  };

  //BEGIN - Datos de la orden de producción
  const { data: productionOrderData, isLoading: isLoadingProductionOrderData } =
    useQuery({
      queryKey: ["getProductionOrderData", Orden, update, token, orderBy],
      queryFn: QueryConfig.SHOW_PRODUCTION_ORDER.queryFn,
      enabled: isValid(Orden),
    });

  useEffect(() => {
    if (isValid(productionOrderData) && !isLoadingProductionOrderData) {
      const productionData = productionOrderData?.data?.status?.data;
      setForm(productionData);
      setDataToGeneral(productionData);
    }
  }, [productionOrderData]);

  // END - Datos de la orden de producción

  //BEGIN - Producto elaborado
  const { data: getProductItemOP, isLoading: isLoadingGetProductItemOP } =
    useQuery({
      queryKey: ["getProductItemOP", Orden, update, page?.product, orderBy],
      queryFn: QueryConfig.GET_PRODUCT_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const ProductItemOP = getProductItemOP?.data?.status?.data?.data;
  const totalSumProductItemOP =
    isValid(ProductItemOP) && isArray(ProductItemOP)
      ? ProductItemOP.reduce(
        (sum, item) => sum + parseInt(item?.quantity || 0),
        0
      )
      : 0;

  //END - Producto elaborado

  //BEGIN - Materias primas e insumos
  const { data: getSupplyItemOP, isLoading: isLoadingGetSupplyItemOP } =
    useQuery({
      queryKey: ["getSupplyItemOP", Orden, update, page?.supply, orderBy],
      queryFn: QueryConfig.GET_SUPPLY_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const SupplyItemOP = getSupplyItemOP?.data?.status?.data?.data;
  const totalSumSupplyItemOP =
    isValid(SupplyItemOP) && isArray(SupplyItemOP)
      ? SupplyItemOP.reduce(
        (sum, item) => sum + parseInt(item?.quantity || 0),
        0
      )
      : 0;

  //END - Materias primas e insumos

  //BEGIN - Otros costos y gastos
  const { data: getOtherCostItemOP, isLoading: isLoadingGetOtherCostItemOP } =
    useQuery({
      queryKey: ["getOtherCostItemOP", Orden, update, page?.other_costs, orderBy],
      queryFn: QueryConfig.GET_OTHERCOST_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const OtherCostItemOP = getOtherCostItemOP?.data?.status?.data?.data;
  const totalOtherCostItemOP =
    isValid(OtherCostItemOP) && isArray(OtherCostItemOP)
      ? OtherCostItemOP.reduce(
        (sum, item) => sum + parseInt(item?.net_weight_cost || 0),
        0
      )
      : 0;

  //END - Otros costos y gastos

  //BEGIN - Desperdicios
  const { data: getGarbageItemOP, isLoading: isLoadingGetGarbageItemOP } =
    useQuery({
      queryKey: ["getGarbageItemOP", Orden, update, page?.garbage, orderBy],
      queryFn: QueryConfig.GET_GARBAGE_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const GarbageItemOP = getGarbageItemOP?.data?.status?.data?.data;
  //END - Desperdicios
  return (
    <>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={OrderProductionContext}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCreateCIntegration}
        onOpen={onOpenCreateCIntegration}
        onClose={onCloseCreateCIntegration}
        size="md"
        title="Crear certificados de integración"
      >
        <CreateCI onClose={onCloseCreateCIntegration} />
      </ModalGeneric>

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseCopy}
        title={`Desea Copiar el formulario Nº ${dataToCopy?.production_order_code}`}
        onCopy={copyData}
      />
      <ExportPdf id="pdfId" values={form} />

      <div className="mt-5">
        {loading && <SpinnerComponent />}
        {!loading && (
          <>
            <HeaderForm
              status={form?.production_order_status}
              numberForm={form?.production_order_code}
              UserQualified={form?.qualified_users_name}
              Modulo={"Formulario de Orden de producción En Zonas Francas"}
            />

            <BtnShowOrder values={form}>
              {form?.reason_for_return &&
                (form?.production_order_status === "BORRADOR" ||
                  form?.production_order_status === "DEVUELTO") && (
                  <AlertReturnReason
                    title={"Tipo de devolución:"}
                    item={form.typifications}
                    description={
                      form?.reason_for_return && form?.reason_for_return
                    }
                    nickname={form.nickname}
                  />
                )}
              <SatausOfOrder values={form} />
            </BtnShowOrder>

            <TableInformation values={form} />

            <Box
              display={"flex"}
              justifyContent={'flex-end'}
              alignContent={'flex-end'}

            >
              <InputSelect
                data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                value={orderBy}
                name="order_by"
                set={(e) => {
                  setOrderBy(e);
                  setPage({
                    product: 1,
                    supply: 1,
                    other_costs: 1,
                    garbage: 1,
                  })
                }}
                placeholder="Ordenar por"
                inputAttrs={{
                  color: '#5f5f',
                }}
                size={30}
                isClearable={false}
              />
            </Box>

            <TabsCreateOrder head={headerTabs} change={handleCambio}>
              <TabPanel>
                <TableOrder
                  thead={elaborateProductDos}
                  data={ProductItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyElaboradoNoAccion}
                  subTotals={totalSumProductItemOP}
                  titleTotal="Total"
                  td={tdProduct}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={
                    dataUser?.is_qualified_user === 0
                      ? MatterNoEdit
                      : MatterNoUCNoEdit
                  }
                  data={SupplyItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyMatterNoEdit}
                  subTotals={totalSumSupplyItemOP}
                  titleTotal="Total"
                  td={tdMatter}
                  // tUnid={currentPage?.unit_array_value}
                  tbodyUnd={TbodyUnd}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={HeadOtherCostsNoEdit}
                  data={OtherCostItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyOtherCostsNoEdit}
                  subTotals={totalOtherCostItemOP}
                  titleTotal="Total"
                  td={tdOther}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={HeadGarbages}
                  data={GarbageItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyGarbages}
                  //   subTotals={currentPage?.totals_garbages}
                  titleTotal="Total"
                  td={tdGarbages}
                  // tUnid={currentPage?.unit_array_value_garbage}
                  tbodyUnd={TbodyGarbagesUnit}
                />
              </TabPanel>

              <Pagination
                page={page[positionProperties[positionView]]}
                setPage={setPage}
                StoreProperty={positionProperties[positionView]}
                maxPage={{
                  garbage: getGarbageItemOP?.data?.status?.data?.last_page,
                  other_costs: getOtherCostItemOP?.data?.status?.data?.last_page,
                  supply: getSupplyItemOP?.data?.status?.data?.last_page,
                  product: getProductItemOP?.data?.status?.data?.last_page
                }[positionProperties[positionView]] || 1}
                loading={loading}
              />
            </TabsCreateOrder>
          </>
        )}
      </div>
    </>
  );
};
