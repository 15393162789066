import axios from 'axios';

import env from '../constants/apiConst';

/**
 * Peticion para validar el email del usuario para cambiar contraseña
 * Recibe como parametro un formdata con el email del usuario
 */

export const __Forgot_Password = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    },
    data: body
  };

  const res = await axios(env.FORGOT_PASSWORD, options);
  return res;
};
