import { H2 } from "pages/Pass-Form/stylePases";
import { Title } from "styled/Title";

export const StausBox = ({ log, logCorrected, values }) => {
  return (
    <>
      <div
        className="row mt-4 ms-2"
        style={{
          background: "#f2f2f2",
          padding: "10px"
        }}
      >
        {log
          ? log?.log?.map((items, index) => (
            <div className="col col-sm-6 sol-md-3" key={index}>
              <H2 className="m-1 d-flex">
                {items?.status === "BORRADOR"
                  ? (
                    <Title size="18px">Creado: </Title>
                  )
                  : items?.status === "PRESENTADO"
                    ? (
                      <Title size="18px">Presentado: </Title>
                    )
                    : items?.status === "AUTORIZADO"
                      ? (
                        <Title size="18px">Autorizado: </Title>
                      )
                      : items?.status === "APROBADO"
                        ? (
                          <Title size="18px">Aprobado: </Title>
                        )
                        : items?.status === "CORREGIDO"
                          ? (
                            <Title size="18px">Corregido: </Title>
                          )
                          : items?.status === "DEVUELTO"
                            ? (
                              <Title size="18px">Devuelto: </Title>
                            )
                            : (
                              ""
                            )}

                <span className="ms-2 text-mute">
                  {items?.date + " " + items?.time + " " + items?.person_name}
                </span>
              </H2>
            </div>
          ))
          : logCorrected
            ? logCorrected?.map((items, index) => (
              <div className="col col-sm-6 sol-md-3" key={index}>
                <H2 className="m-1 d-flex">
                  {items?.status === "BORRADOR"
                    ? (
                      <Title size="18px">Creado: </Title>
                    )
                    : items?.status === "PRESENTADO"
                      ? (
                        <Title size="18px">Presentado: </Title>
                      )
                      : items?.status === "AUTORIZADO"
                        ? (
                          <Title size="18px">Autorizado: </Title>
                        )
                        : items?.status === "APROBADO"
                          ? (
                            <Title size="18px">Aprobado: </Title>
                          )
                          : items?.status === "CORREGIDO"
                            ? (
                              <Title size="18px">Corregido: </Title>
                            )
                            : items?.status === "DEVUELTO"
                              ? (
                                <Title size="18px">Devuelto: </Title>
                              )
                              : (
                                ""
                              )}

                  <span className="ms-2 text-mute">
                    {items?.date + " " + items?.time + " " + items?.nickname}
                  </span>
                </H2>
              </div>
            ))
            : values?.log?.map((items, index) => (
              <div className="col col-sm-6 sol-md-3" key={index}>
                <H2 className="m-1 d-flex">
                  {items?.status === "BORRADOR"
                    ? (
                      <Title size="18px">Creado: </Title>
                    )
                    : items?.status === "PRESENTADO"
                      ? (
                        <Title size="18px">Presentado: </Title>
                      )
                      : items?.status === "AUTORIZADO"
                        ? (
                          <Title size="18px">Autorizado: </Title>
                        )
                        : items?.status === "APROBADO"
                          ? (
                            <Title size="18px">Aprobado: </Title>
                          )
                          : items?.status === "CORREGIDO"
                            ? (
                              <Title size="18px">Corregido: </Title>
                            )
                            : items?.status === "DEVUELTO"
                              ? (
                                <Title size="18px">Devuelto: </Title>
                              )
                              : (
                                ""
                              )}

                  <span className="ms-2 text-mute">
                    {items?.date + " " + items?.time + " " + items?.person_name}
                  </span>
                </H2>
              </div>
            ))}
      </div>
    </>
  );
};
