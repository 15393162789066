/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { useForm } from "hooks/useForm";
import { Box, Divider, Switch, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { LabeFormlStyled } from "styled/Input.styled";
import { __ShowDependency } from "request/configuration/__Dependency";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const FormDependency = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [statusDependency, setStatusDependency] = useState(1);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();
  const [values, handleInputChange, reset, setValues] = useForm({
    dependency: "",
    status: ""
  });

  const { dependency, status } = values;

  const editDependency = async () => {
    try {
      const res = await __ShowDependency(token, dataToEdit.id);
      console.log(res.data?.status?.data);
      setValues(res?.data?.status?.data);
      setStatusDependency(res?.data?.status?.data?.status);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      editDependency();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status
      };
      editData(data);
    } else {
      const data = {
        ...values,
        status: 1
      };
      createData(data);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    if (e.target.name === "status") {
      if (e.target.checked === true) {
        setStatusDependency(1);
      } else {
        setStatusDependency(0);
      }
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar dependencia " : "Crear dependencia "}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            type={"text"}
            name={"dependency"}
            tag={"Nombre de dependencia"}
            size={35}
            value={dependency}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            desicion={false}
            maxLength={70}
          />

          {dataToEdit && (
            <div
              style={{
                marginRight: "10%"
              }}
            >
              <LabeFormlStyled>Activo</LabeFormlStyled>
              <div>
                <Switch
                  name="status"
                  id="status"
                  size="lg"
                  value={status}
                  onChange={changeCheck}
                  isChecked={statusDependency}
                />
              </div>
            </div>
          )}
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormDependency;
