/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */import React, { useContext, useEffect, useState } from "react";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import ConfigurationHeader from "../ConfigurationHeader";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { __ZonasFrancaObt } from "request/configuration/__ZonasFranca";
import TbodyZoneF from "../tables/TbodyZoneF";
import { TitleZone } from "../ArrayTitles";
import { FormZonaFranca } from "../forms/FormZonaFranca";
import FormZonasFShow from "../FormShow/FormZonasFrancasShow";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formEditSub = () => {
  return <FormZonaFranca />;
};

const formShow = () => {
  return <FormZonasFShow />;
};

export const ZonasFrancas = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { q = "" } = queryString.parse(location.search);
  const { setRequestEdit, setRequestCreate, setRequestDelete, setFormEdit, setFormShow, update } =
    useContext(ConfigurationCRUDContext);

    const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formShow);
    setRequestEdit(environment.ZONAS_F_UPDATE);
    setRequestCreate(environment.ZONAS_F_CREATE);
    setRequestDelete(environment.ZONAS_F_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configZoneControl"]});
  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configZoneControl", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.ZONAS_F_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])


  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Zonas Franca"></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={TitleZone}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodyZoneF}
          />
        )}
        <Pagination page={page} setPage={setPage} maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1} loading={isLoading} />
      </SectionContainerGene>
    </>
  );
};
