import { Flex, Spacer } from "@chakra-ui/react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { ButtonItemsForm } from "../stylePases";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import PassCrudContext from "context/PassCrudContext";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";

const TemplateInitializer = {
  advanced: {
    // qualifiedUser_id: idQualifiedUser,
    dispatchPass_id: "",
    item: "",
    reference: "",
    subheading: "",
    description: "",
    code: "",
    date_start: "",
    date_end: ""
  },
  simple: {
    code: "",
    date_start: "",
    date_end: ""
  }
};

export const SearchItemDispacth = ({
  onClose,
  loading,
  handleSearch,
  mode = "advanced"
}) => {
  const location = useLocation(); // navigate
  const { id = "" } = queryString.parse(location.search);
  // const { idQualifiedUser } = useContext(PassCrudContext);

  const [values, handleInputChange, reset, setValues] = useForm(
    TemplateInitializer[mode]
  );

  useEffect(() => {
    if (id) setValues((prev) => ({ ...prev, dispatchPass_id: id }));
  }, [id]);

  if (mode === "simple") {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"code"}
            onChange={handleInputChange}
            value={values?.code ?? ""}
            tag={"Salida"}
            size={30}
            desicion={false}
          />

          <LabelGroup
            name={"date_start"}
            onChange={handleInputChange}
            value={values?.date_start ?? ""}
            tag={"Fecha inicio"}
            size={30}
            tipo={"date"}
            desicion={false}
          />

          <LabelGroup
            name={"date_end"}
            onChange={handleInputChange}
            value={values?.date_end ?? ""}
            tag={"Fecha final"}
            size={30}
            tipo={"date"}
            desicion={false}
          />
        </div>

        <Flex mt={6} className="mb-3">
          <ButtonStyled onClick={onClose}>Cancelar</ButtonStyled>
          <Spacer />
          <ButtonStyled
            isLoading={loading}
            loadingText="Buscando.."
            onClick={() => handleSearch(values)}
          >
            Buscar
          </ButtonStyled>
        </Flex>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"code"}
          onChange={handleInputChange}
          value={values?.code ?? ""}
          tag={"Salida"}
          size={30}
          desicion={false}
        />

        <LabelGroup
          name={"item"}
          onChange={handleInputChange}
          value={values?.item ?? ""}
          tag={"Item de salida"}
          size={30}
          desicion={values?.code === ""}
        />

        <LabelGroup
          name={"reference"}
          onChange={handleInputChange}
          value={values?.reference ?? ""}
          tag={"Referencia"}
          size={30}
          desicion={false}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"date_start"}
          onChange={handleInputChange}
          value={values?.date_start ?? ""}
          tag={"Fecha inicio"}
          size={30}
          tipo={"date"}
          desicion={false}
        />

        <LabelGroup
          name={"date_end"}
          onChange={handleInputChange}
          value={values?.date_end ?? ""}
          tag={"Fecha final"}
          size={30}
          tipo={"date"}
          desicion={false}
        />

        <LabelGroup
          name={"subheading"}
          onChange={handleInputChange}
          value={values?.subheading ?? ""}
          tag={"Subpartida"}
          size={30}
          desicion={false}
          maxLength={MaxLength?.global?.code_subheading}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"description"}
          onChange={handleInputChange}
          value={values?.description ?? ""}
          tag={"Descripción"}
          size={100}
          desicion={false}
        />
      </div>
      <Flex mt={6} className="mb-3">
        <ButtonStyled onClick={onClose}>Cancelar</ButtonStyled>
        <Spacer />
        <ButtonStyled
          isLoading={loading}
          loadingText="Buscando.."
          onClick={() => handleSearch(values)}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
