/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import TablePass from "components/Tables/TablesPass";
import { AuthContext } from "context/AuthContext";
import {
  TitleTablePassDispacthShow,
  TitleTablePassShow
} from "../View/ArrayHeadTable/TheadMovementInt";
import { SubTitle, Title } from "styled/Title";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Box } from "@chakra-ui/react";
import PassCrudContext from "context/PassCrudContext";
import { TbodyDispacthShow } from "../tbody/TbodyDispacthShow";
import { TbodyItemShow } from "../tbody/TbodyItemShow";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import bro from "../../../assets/bro.png";

export const ExportPassPdf = ({ values }) => {
  const [dateToday, setDateToday] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [fechaAutorizado, setFechaAutorizado] = useState("");
  const [fechaCreate, setFechaCreate] = useState("");
  const location = useLocation();

  const { currentItem, setCurrentItem } = useContext(PassCrudContext);
  const { typeO = "" } = queryString.parse(location.search);

  const {
    status_entryPass,
    status_dispatchPass,
    passForm_number,
    name_qualified,
    nit_qualified,
    observations,
    number_packages,
    name_typeTransport,
    identification,
    driver,
    phone,
    vehicle_plate,
    containers,
    status
  } = values || {};

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
    if (status) {
      const dateAutorizado = status?.find(
        (element) => element?.status_pass === "AUTORIZADO"
      );
      setFechaAutorizado(dateAutorizado?.date_pass);
      // console.log(dateAutorizado);
      const dateCreate = status?.find(
        (element) => element?.status_pass === "BORRADOR"
      );
      setFechaCreate(dateCreate?.date_pass);
    }
  }, [values]);

  return (
    <div id="display-vacio" className="container">
      <div className="row">
        <div className="col-3">
          <h6>Impreso: {dateToday}</h6>
          <h6>Usuario: {authUser.username}</h6>
          <h6>Pagina 1 de 1</h6>
        </div>
        <div className="col-6">
          <h4 className="text-center">
            <strong>Zona franca Barranquilla</strong>
          </h4>
          <h4 className="text-center">
            <strong>
              Formulario Movimiento de Mercancías en Zonas Francas
            </strong>
          </h4>
          <h4 className="text-center">
            <strong>
              Pase de {status_entryPass ? "Entrada" : "Salida"} -{" "}
              {status_entryPass || status_dispatchPass}{" "}
            </strong>
          </h4>
        </div>
        <div className="col-3">
          <h4>Formulario: {passForm_number}</h4>
          <h4>Fecha: {fechaCreate} </h4>
        </div>
        <hr className="mt-3" />
      </div>

      <div className="row mt-3">
        <div className="col-3">
          <h5>Usuario: {name_qualified}</h5>
          <h6>NIT: {nit_qualified}</h6>
        </div>
        <div className="col-6">
          <h4 className="text-center">Nº Pase de Entrada: {passForm_number}</h4>
        </div>
        <div className="col-3">
          <h4>
            Fecha de autorización: {fechaAutorizado || ""}
          </h4>
        </div>
      </div>

      <div className="row mt-5">
        <h1 id="titleSection">Información General</h1>
        <div className="col-3">
          <h6>Placa: {vehicle_plate !== "null" ? vehicle_plate : ""}</h6>
          <h6>Conductor: {driver !== "null" ? driver : ""}</h6>
          <h6>Cédula : {identification !== "null" ? identification : ""}</h6>
          <h6>Teléfono: {phone !== "null" ? phone : ""}</h6>
          <h6>
            Nº Bultos: {number_packages !== "null" ? number_packages : ""}
          </h6>
          <h6>Observaciones: {observations !== "null" ? observations : ""}</h6>
        </div>
        <div className="col-6">
          <h6>
            Tipo de Transporte:{" "}
            {name_typeTransport !== "null" ? name_typeTransport : ""}
          </h6>
          {containers
            ? containers.map((items, index) => (
              <h6 key={index}>No de Serie: {items?.container}</h6>
            ))
            : ""}
        </div>
        <div className="col-3">
          <br />
          {containers
            ? containers.map((items, index) => (
              <h6 key={index}>De: {items?.capacity_container}</h6>
            ))
            : ""}
        </div>
      </div>

      <div className="row my-5">
        <h1 id="titleSection">Mercancía Transportada</h1>
        <HeaderSearch>
          <div>
            <Box display="flex" justifyContent="space-between"></Box>
            {currentItem.length === 0
              ? (
                <div>
                  <div className="d-flex justify-content-center ">
                    <img
                      style={{ width: `${15}%` }}
                      src={bro}
                      alt="no hay formulario"
                    />
                  </div>
                  <SubTitle size={"15px"} className="text-center ">
                  Aún no hay Formulario asociados a esta lista
                  </SubTitle>
                </div>
              )
              : (
                <>
                  <TablePass
                    thead={
                      typeO === "Dispatch"
                        ? TitleTablePassDispacthShow
                        : TitleTablePassShow
                    }
                    data={currentItem}
                    setData={setCurrentItem}
                    loading={loading}
                    tbodyData={
                      typeO === "Dispatch" ? TbodyDispacthShow : TbodyItemShow
                    }
                  />
                </>
              )}
          </div>
        </HeaderSearch>
        <br />
        <br />
      </div>
    </div>
  );
};
