import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from "@chakra-ui/react";
import "./hover.css";

const Hover = ({ title, description, icon, ...props }) => {
  return (
    <Popover
      placement="top-start"
      defaultIsOpen={true}
      closeOnBlur={true}
      closeOnEsc={true}
      flip={true}
      {...props}
    >
      <PopoverTrigger>
        <Box tabIndex="0" role="button" aria-label="Some box" children={icon} />
      </PopoverTrigger>
      <PopoverContent bg={"#FCF8E3"} color="black" className="PopoverContent ">
        <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{description}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Hover;
