import { Box } from "@chakra-ui/react";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import Select from "react-select";

export const SelectCustom = ({ data, ...props }) => {
  const GetValue = (x) => {
    if (props?.get && props?.set) {
      const { name, set, get } = props;
      set({ ...get, [name]: x.value });
      console.log(get)
    }
  };

  console.log(props.defaultValues);

  return (
    <Box w={`${props.size}%`}>
      <LabelStyled>{props.tag}</LabelStyled>
      <Select
        {...props}
        options={data}
        defaultValue={{
          label: props.defaultValues,
          value: props.defaultValues
        }}
        onChange={GetValue}
      />
    </Box>
  );
};
