import axios from 'axios';
import environment from 'constants/apiConst';

export const __Get_MonitoringSearch = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${environment.GET_MONITORING}?page=${page || 1}`, options);
  return res;
};

export const __Get_Monitoring = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.GET_MONITORING}?page=${page || 1}`, options);
  return res;
};

export const __GetSubheadings = async (token, data) => {
  const formData = new FormData();
  formData.append("follow_order", data);
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: formData || ""
  };

  const res = await axios(environment.GET_SUBHEADING, options);
  return res;
};

export const __Get_traceability = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${environment.GET_TRACEABILITY}?page=${page || 1}`, options);
  return res;
};

export const __GetTrackingLog = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${environment.GET_LOG}?page=${page || 1}`, options);
  return res;
};

