import React, { useCallback, useEffect, useContext } from "react";
import { Box } from "@chakra-ui/react";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import environment from "constants/apiConst";
import { __showBox } from "request/IntegrationBox/___IntegrationBox";

import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { updateFormSingle } from "redux/actions/useFormAction";
import { useDispatch } from "react-redux";

const HeadboardIntegration = ({ data }) => {
  const location = useLocation();
  const {
    id = null,
    qualifiedUser = null,
    typeCertificateL = null,
  } = queryString.parse(location.search);
  const { InfoUser, setInfoUser } = useContext(IntegracionBoxCrudContex);
  const dispacthRedux = useDispatch();

  useEffect(() => {
    setInfoUser(data);
  }, [data]);

  useEffect(() => {
    dispacthRedux(
      updateFormSingle(
        "ciModuleDynamic",
        ["nameModul"],
        InfoUser?.integration_certificate_name || "Certificado de Integración"
      )
    );
  }, [InfoUser]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mt={5}
      flexDirection="column"
    >
      <HeaderForm
        status={""}
        numberForm={InfoUser?.integration_certificate_code}
        UserQualified={InfoUser?.name_qualified || qualifiedUser || ""}
        Modulo={`Edición de formulario de ${
          InfoUser?.integration_certificate_name ||
          typeCertificateL ||
          "certificados de integración"
        }`}
      />
    </Box>
  );
};

export default HeadboardIntegration;
