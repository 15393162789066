import DateRangePicker from "components/DatePicker/DatePicker";

const {
  Box,
  InputGroup,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton
} = require("@chakra-ui/react");

export const Variables = ({ StatusIrrigation, StatusCriterion }) => {
  let Irrigation = 0;
  let Criterion = 0;

  return (
    <Box display={"flex"} gap="0.5rem">
      <Box w={"15rem"}>
        <InputGroup flexDir="column">
          <FormLabel fontSize={"0.9rem"}>Periodo de tiempo</FormLabel>
          <DateRangePicker
            h={"2rem"}
            fontSize={"0.9rem"}
            border={"2px solid #97C0E6"}
            bg={"transparent"}
            borderRadius={"full"}
            color={"gray.600"}
          />
        </InputGroup>
      </Box>

      <Box w="full" display={"flex"} alignItems={"flex-end"} gap="0.2rem">
        {StatusIrrigation.map(({ status }) => {
          if (status && Irrigation === 0) {
            Irrigation++;
            return (
              <Tag
                size={"lg"}
                h="1rem"
                borderRadius="full"
                variant="outline"
                colorScheme="blue"
              >
                <TagLabel color={"gray.600"}>Niveles de riesgo</TagLabel>
                {/* <TagCloseButton /> */}
              </Tag>
            );
          }
        })}

        {StatusCriterion.map(({ status }) => {
          if (status && Criterion === 0) {
            Criterion++;
            return (
              <Tag
                h="1rem"
                size={"lg"}
                borderRadius="full"
                variant="outline"
                colorScheme="blue"
              >
                <TagLabel color={"gray.600"}>Criterios</TagLabel>
                {/*      <TagCloseButton /> */}
              </Tag>
            );
          }
        })}
      </Box>
    </Box>
  );
};
