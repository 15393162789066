import React from "react";

export const TablaExportPdf = ({
  thead = [],
  tbodyData: TbodyData,
  data = []
}) => {
  return (
    <>
      <div className="px-4 py-2">
        <table className="table table-bordered">
          <tbody>
            <tr>
              {thead &&
                thead.map((item, index) => (
                  <td className="text-center" key={index}>
                    {item}
                  </td>
                ))}
            </tr>
            {data.map((item, index) => (
              <TbodyData
                key={`${index}-${Date.now()}-${index}`}
                data={item}
                iope={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
