/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import {
  InputGroupZones,
  InputTypeZones
} from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __TypeZfAll } from "request/configuration/TypeZf";
import { __GroupZonesAll } from "request/configuration/__GroupZonesF";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { __ShowZonasFranca } from "../../../request/configuration/__ZonasFranca";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { Regex } from "utils/Regex";
export const FormZonaFranca = () => {
  const { authUser } = useContext(AuthContext);
  // Inputs
  const [typezone, setTypeZone] = useState([]);
  const [groupzones, setGroupZones] = useState([]);
  const [groupzone, setGroupZone] = useState("");
  const [groupzonesv, setGroupZonesV] = useState("");
  const [zones, setTypeZones] = useState("");
  const [typezonesv, setTypeZonesV] = useState("");
  // Checkeds
  const [checkshowpdfuser, setCheckShowPdfUser] = useState(true);
  const [checkshowuserprscomrepr, setCheckShowuserprsComRepr] = useState(true);
  const [checkcopymovements, setCheckCopyMovements] = useState(true);
  const [checkchangesubheading, setCheckChangeSubheading] = useState(true);
  const [checkdianstamp, setCheckDianStamp] = useState(true);
  const [checkDetailedReview, setCheckDetailedReview] = useState(true);
  const [checkcanaddattachments, setCheckCanaddattachments] = useState(true);
  const [checkdrivevehicledoor, setCheckDriveVehicleDoor] = useState(true);
  const [checkspecialsubheading, setCheckSpecialSubheading] = useState(true);
  const [checkapprovallock, setCheckApprovalLock] = useState(true);
  const [checkvalidatepartialprocessing, setCheckValidatePartialProcessing] =
    useState(true);
  const [checkwarnsubheadings, setCheckWarnSubheadings] = useState(true);
  const [checktemporarydecl, setCheckTemporaryDecl] = useState(true);
  const [checkvalidaterepositionoutputs, setCheckValidateRepositionOutputs] =
    useState(true);
  const [checkexportdetaileddocuments, setCheckExportDetailedDocuments] =
    useState(true);
  const [checkusedianprofiling, setCheckUseDianProfiling] = useState(true);
  const [checkstatusfreeZone, setCheckStatusFreeZone] = useState(true);
  const [checkrequireentrypass, setCheckRequireEntryPass] = useState(true);
  const [checkrequireexitpass, setCheckRequireExitPass] = useState(true);
  const [checkport, setCheckPort] = useState(true);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [values, handleInputChange, reset, setValues] = useForm({
    name_freeZone: "",
    shortName_freeZone: "",
    site: "",
    color: "",
    show_pdf_user: 1,
    copy_movements: 1,
    change_subheading: 1,
    dian_stamp: 1,
    detailed_review: 1,
    can_add_attachments: 1,
    drive_vehicle_door: 1,
    special_subheading: 1,
    approval_lock: 1,
    validate_partial_processing: 1,
    qualification_date_valid_proc_parc: "",
    warn_subheadings: 1,
    temporary_decl: 1,
    validate_reposition_outputs: 1,
    percentage_value_outputs_reset: "",
    export_detailed_documents: 1,
    use_dian_profiling: 1,
    status_freeZone: 1,
    not_require_entry_pass: 1,
    not_require_exit_pass: 1,
    port: 1,
    max_value_cop: null,
    notifications_day: null,
  });


  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue, formattedValue, value } = newValue
    const parsedValue = parseFloat(floatValue || value);
    setValues({ ...values, [sourceEvent?.event?.target?.name]: parsedValue });
  }

  const {
    name_freeZone,
    shortName_freeZone,
    site,
    color,
    show_pdf_user,
    copy_movements,
    change_subheading,
    dian_stamp,
    detailed_review,
    can_add_attachments,
    drive_vehicle_door,
    special_subheading,
    approval_lock,
    validate_partial_processing,
    qualification_date_valid_proc_parc,
    warn_subheadings,
    temporary_decl,
    validate_reposition_outputs,
    percentage_value_outputs_reset,
    export_detailed_documents,
    use_dian_profiling,
    status_freeZone,
    not_require_entry_pass,
    not_require_exit_pass,
    port,
    max_value_cop,
    notifications_day
  } = values;

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  useEffect(() => {
    const getData = async () => {
      if (dataToEdit) {
        const initialpos = async () => {
          try {
            const res = await __ShowZonasFranca(token, dataToEdit.id);

            // Valores anteriores
            setValues({
              ...res?.data?.status.data,
              max_value_cop: res?.data?.status.data?.max_value_cop || 20000000,
              notifications_day: res?.data?.status.data?.notifications_day || ''
            });

            // ID
            setGroupZonesV(res?.data?.status?.data?.groupFreeZone_id);
            setTypeZonesV(res?.data?.status?.data?.typeFreeZone_id);

            // Names

            setGroupZone(res?.data?.status?.data?.name_groupFreeZone);
            setTypeZones(res?.data?.status?.data?.name_typeFreeZone);

            // Checks
            setCheckShowPdfUser(
              res?.data?.status?.data?.show_pdf_user === 1
            );
            setCheckCopyMovements(
              res?.data?.status?.data?.copy_movements === 1
            );
            setCheckChangeSubheading(
              res?.data?.status?.data?.change_subheading === 1
            );
            setCheckDianStamp(
              res?.data?.status?.data?.dian_stamp === 1
            );
            setCheckDetailedReview(
              res?.data?.status?.data?.detailed_review === 1
            );
            setCheckCanaddattachments(
              res?.data?.status?.data?.can_add_attachments === 1
            );
            setCheckDriveVehicleDoor(
              res?.data?.status?.data?.drive_vehicle_door === 1
            );
            setCheckSpecialSubheading(
              res?.data?.status?.data?.special_subheading === 1
            );
            setCheckApprovalLock(
              res?.data?.status?.data?.approval_lock === 1
            );
            setCheckValidatePartialProcessing(
              res?.data?.status?.data?.validate_partial_processing === 1
            );
            setCheckWarnSubheadings(
              res?.data?.status?.data?.warn_subheadings === 1
            );
            setCheckTemporaryDecl(
              res?.data?.status?.data?.temporary_decl === 1
            );
            setCheckValidateRepositionOutputs(
              res?.data?.status?.data?.validate_reposition_outputs === 1
            );

            setCheckExportDetailedDocuments(
              res?.data?.status?.data?.export_detailed_documents === 1
            );
            setCheckUseDianProfiling(
              res?.data?.status?.data?.use_dian_profiling === 1
            );
            setCheckStatusFreeZone(
              res?.data?.status?.data?.status_freeZone === 1
            );
            setCheckRequireEntryPass(
              res?.data?.status?.data?.not_require_entry_pass === 1
            )
            setCheckRequireExitPass(
              res?.data?.status?.data?.not_require_exit_pass === 1
            )
            setCheckPort(
              res?.data?.status?.data?.port === 1
            )
          } catch (error) { }
        };
        initialpos();
      } else {
        setValues(values);
      }
    };
    getData();
  }, [dataToEdit]);

  const handleAceptar = () => {
    if (dataToEdit) {
      console.log(dataToEdit);
      const data = {
        ...values,
        id: dataToEdit.id,
        groupFreeZone_id: groupzonesv,
        typeFreeZone_id: typezonesv,
        show_pdf_user: show_pdf_user ? 1 : 0,
        copy_movements: copy_movements ? 1 : 0,
        change_subheading: change_subheading ? 1 : 0,
        dian_stamp: dian_stamp ? 1 : 0,
        detailed_review: detailed_review ? 1 : 0,
        can_add_attachments: can_add_attachments ? 1 : 0,
        drive_vehicle_door: drive_vehicle_door ? 1 : 0,
        special_subheading: special_subheading ? 1 : 0,
        approval_lock: approval_lock ? 1 : 0,
        validate_partial_processing: validate_partial_processing ? 1 : 0,
        warn_subheadings: warn_subheadings ? 1 : 0,
        temporary_decl: temporary_decl ? 1 : 0,
        validate_reposition_outputs: validate_reposition_outputs ? 1 : 0,
        export_detailed_documents: export_detailed_documents ? 1 : 0,
        use_dian_profiling: use_dian_profiling ? 1 : 0,
        status_freeZone: status_freeZone ? 1 : 0,
        not_require_entry_pass: not_require_entry_pass ? 1 : 0,
        not_require_exit_pass: not_require_exit_pass ? 1 : 0,
        port: port ? 1 : 0
      };
      console.log(data);
      editData(data);
    } else {
      const data = {
        ...values,
        groupFreeZone_id: groupzonesv,
        typeFreeZone_id: typezonesv,
        show_pdf_user: show_pdf_user ? 1 : 0,
        copy_movements: copy_movements ? 1 : 0,
        change_subheading: change_subheading ? 1 : 0,
        dian_stamp: dian_stamp ? 1 : 0,
        detailed_review: detailed_review ? 1 : 0,
        can_add_attachments: can_add_attachments ? 1 : 0,
        drive_vehicle_door: drive_vehicle_door ? 1 : 0,
        special_subheading: special_subheading ? 1 : 0,
        approval_lock: approval_lock ? 1 : 0,
        validate_partial_processing: validate_partial_processing ? 1 : 0,
        warn_subheadings: warn_subheadings ? 1 : 0,
        temporary_decl: temporary_decl ? 1 : 0,
        validate_reposition_outputs: validate_reposition_outputs ? 1 : 0,
        export_detailed_documents: export_detailed_documents ? 1 : 0,
        use_dian_profiling: use_dian_profiling ? 1 : 0,
        status_freeZone: status_freeZone ? 1 : 0,
        not_require_entry_pass: not_require_entry_pass ? 1 : 0,
        not_require_exit_pass: not_require_exit_pass ? 1 : 0,
        port: port ? 1 : 0
      };
      console.log(data);
      createData(data);
    }
  };

  // CONSULTA DE SERVICIOS
  useEffect(() => {
    const TypeZonasF = async () => {
      try {
        const res = await __TypeZfAll(token);
        setTypeZone(res?.data?.status?.data);
      } catch (error) { }
    };
    TypeZonasF();

    const GroupZonasF = async () => {
      try {
        const res = await __GroupZonesAll(token);
        setGroupZones(res?.data?.status?.data);
      } catch (error) { }
    };
    GroupZonasF();
  }, []);

  // PREVENIR EVENTO
  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  // VALIDACIONES CHECKED
  const changeCheck = (e) => {
    handleInputChange(e);
    if (e.target.name === "show_pdf_user") {
      setCheckShowPdfUser(e.target.checked);
      return;
    }
    if (e.target.name === "copy_movements") {
      setCheckCopyMovements(e.target.checked);
      return;
    }
    if (e.target.name === "change_subheading") {
      setCheckChangeSubheading(e.target.checked);
      return;
    }
    if (e.target.name === "dian_stamp") {
      setCheckDianStamp(e.target.checked);
      return;
    }
    if (e.target.name === "detailed_review") {
      setCheckDetailedReview(e.target.checked);
      return;
    }
    if (e.target.name === "can_add_attachments") {
      setCheckCanaddattachments(e.target.checked);
      return;
    }
    if (e.target.name === "drive_vehicle_door") {
      setCheckDriveVehicleDoor(e.target.checked);
      return;
    }
    if (e.target.name === "special_subheading") {
      setCheckSpecialSubheading(e.target.checked);
      return;
    }
    if (e.target.name === "approval_lock") {
      setCheckApprovalLock(e.target.checked);
      return;
    }
    if (e.target.name === "validate_partial_processing") {
      setCheckValidatePartialProcessing(e.target.checked);
      return;
    }
    if (e.target.name === "warn_subheadings") {
      setCheckWarnSubheadings(e.target.checked);
      return;
    }
    if (e.target.name === "temporary_decl") {
      setCheckTemporaryDecl(e.target.checked);
      return;
    }
    if (e.target.name === "validate_reposition_outputs") {
      setCheckValidateRepositionOutputs(e.target.checked);
      return;
    }
    if (e.target.name === "export_detailed_documents") {
      setCheckExportDetailedDocuments(e.target.checked);
      return;
    }
    if (e.target.name === "use_dian_profiling") {
      setCheckUseDianProfiling(e.target.checked);
      return;
    }
    if (e.target.name === "status_freeZone") {
      setCheckStatusFreeZone(e.target.checked);
      return;
    }
    if (e.target.name === "not_require_entry_pass") {
      setCheckRequireEntryPass(e.target.checked);
      return;
    }
    if (e.target.name === "not_require_exit_pass") {
      setCheckRequireExitPass(e.target.checked);
      return;
    }
    if (e.target.name === "port") {
      setCheckPort(e.target.checked);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} ml={2} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Zona Franca" : "Crear Zona Franca"}
      </Text>
      <Divider />

      <form onSubmit={hanldeSubmit}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2 }}
          minChildWidth={{ md: "300px" }}
          gap={2}
          spacingY={4}
        >
          <div>
            <LabeFormlStyled>Nombre</LabeFormlStyled>
            <InputFormStyled
              marginTop="10px"
              type="text"
              name="name_freeZone"
              value={name_freeZone}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={50}
            />
          </div>

          <div>
            <LabeFormlStyled>Abreviación</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="shortName_freeZone"
              value={shortName_freeZone}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={50}
            />
          </div>

          <div>
            <LabeFormlStyled>Sitio</LabeFormlStyled>
            <InputFormStyled type="text" name="site" value={site} onChange={handleInputChange} maxLength={50} />
          </div>

          <div >
            <LabeFormlStyled>Color</LabeFormlStyled>
            <InputFormStyled mt="3px" padding="8px 6px" height="30px" type="color" name="color" value={color} onChange={handleInputChange} />
          </div>

          <div>
            <InputTypeZones
              partidas={typezone}
              tag={"Tipo de Zona Franca"}
              setTypeZones={setTypeZones}
              zones={zones}
              setValor={setTypeZonesV}
              valor={typezonesv}
            />
          </div>

          <div>
            <InputGroupZones
              partidas={groupzones === null ? "" : groupzones}
              tag={"Grupos de Zonas Francas"}
              setGroupZones={setGroupZone}
              groupzones={groupzone}
              setValor={setGroupZonesV}
              valor={groupzonesv}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <LabeFormlStyled>Fecha calificación valid proc parc</LabeFormlStyled>
            <InputFormStyled
              type={"date"}
              name="qualification_date_valid_proc_parc"
              value={
                qualification_date_valid_proc_parc === null
                  ? ""
                  : qualification_date_valid_proc_parc.slice(0, 10)
              }
              onChange={handleInputChange}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <LabeFormlStyled>Porcentaje valor salidas reposición</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="percentage_value_outputs_reset"
              value={percentage_value_outputs_reset}
              onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={3}
            />
          </div>

          <div>
            <LabeFormlStyled>Valor máximo COP</LabeFormlStyled>
            <NumericFormat
              allowNegative={false}
              onValueChange={updateItemValue}
              thousandSeparator={true}
              decimalScale={0}
              decimalSeparator='.'
              name={'max_value_cop'}
              customInput={InputFormStyled}
              value={max_value_cop}
              size={100}
              min={20000000}
            />
          </div>

          <div>
            <LabeFormlStyled>No. de días radicaciones por caducar </LabeFormlStyled>
            <NumericFormat
              allowNegative={false}
              onValueChange={updateItemValue}
              thousandSeparator={true}
              decimalScale={0}
              decimalSeparator='.'
              name={'notifications_day'}
              customInput={InputFormStyled}
              value={notifications_day}
              size={100}
              maxLength={3}
            />
          </div>

        </SimpleGrid>



        <SimpleGrid
          columns={{ base: 1, sm: 3, md: 5 }}
          minChildWidth={{ sm: "100px", md: "200px" }}
          gap={2}
          my={8}
        >

          <div>
            <LabeFormlStyled>Mostrar Usuarios PDF</LabeFormlStyled>
            <div>
              <Switch
                name="show_pdf_user"
                id="show_pdf_user"
                size="lg"
                value={show_pdf_user}
                onChange={changeCheck}
                isChecked={checkshowpdfuser}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Copiar movimientos</LabeFormlStyled>
            <div>
              <Switch
                name="copy_movements"
                id="copy_movements"
                size="lg"
                value={copy_movements}
                onChange={changeCheck}
                isChecked={checkcopymovements}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Cambiar subpartidas</LabeFormlStyled>
            <div>
              <Switch
                name="change_subheading"
                id="change_subheading"
                size="lg"
                value={change_subheading}
                onChange={changeCheck}
                isChecked={checkchangesubheading}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Estado zonas F</LabeFormlStyled>
            <div>
              <Switch
                name="status_freeZone"
                id="status_freeZone"
                size="lg"
                value={status_freeZone}
                onChange={changeCheck}
                isChecked={checkstatusfreeZone}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Sello Dian</LabeFormlStyled>
            <div>
              <Switch
                name="dian_stamp"
                id="dian_stamp"
                size="lg"
                value={dian_stamp}
                onChange={changeCheck}
                isChecked={checkdianstamp}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Revisión detallada</LabeFormlStyled>
            <div>
              <Switch
                name="detailed_review"
                id="detailed_review"
                size="lg"
                value={detailed_review}
                onChange={changeCheck}
                isChecked={checkDetailedReview}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Can add adjuntos</LabeFormlStyled>
            <div>
              <Switch
                name="can_add_attachments"
                id="can_add_attachments"
                size="lg"
                value={can_add_attachments}
                onChange={changeCheck}
                isChecked={checkcanaddattachments}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Maneja puerta vehicular</LabeFormlStyled>
            <div>
              <Switch
                name="drive_vehicle_door"
                id="drive_vehicle_door"
                size="lg"
                value={drive_vehicle_door}
                onChange={changeCheck}
                isChecked={checkdrivevehicledoor}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Subpartida especial</LabeFormlStyled>
            <div>
              <Switch
                name="special_subheading"
                id="special_subheading"
                size="lg"
                value={special_subheading}
                onChange={changeCheck}
                isChecked={checkspecialsubheading}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Bloqueo aprobación</LabeFormlStyled>
            <div>
              <Switch
                name="approval_lock"
                id="approval_lock"
                size="lg"
                value={approval_lock}
                onChange={changeCheck}
                isChecked={checkapprovallock}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Validar procesamiento parcial</LabeFormlStyled>
            <div>
              <Switch
                name="validate_partial_processing"
                id="validate_partial_processing"
                size="lg"
                value={validate_partial_processing}
                onChange={changeCheck}
                isChecked={checkvalidatepartialprocessing}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Exportar documentos detallados </LabeFormlStyled>
            <div>
              <Switch
                name="export_detailed_documents"
                id="export_detailed_documents"
                size="lg"
                value={export_detailed_documents}
                onChange={changeCheck}
                isChecked={checkexportdetaileddocuments}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Advertir subpartidas</LabeFormlStyled>
            <div>
              <Switch
                name="warn_subheadings"
                id="warn_subheadings"
                size="lg"
                value={warn_subheadings}
                onChange={changeCheck}
                isChecked={checkwarnsubheadings}
              />
            </div>
          </div>
          <div>
            <LabeFormlStyled>Decl temporal</LabeFormlStyled>
            <div>
              <Switch
                name="temporary_decl"
                id="temporary_decl"
                size="lg"
                value={temporary_decl}
                onChange={changeCheck}
                isChecked={checktemporarydecl}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Validar salidas reposicion</LabeFormlStyled>
            <div>
              <Switch
                name="validate_reposition_outputs"
                id="validate_reposition_outputs"
                size="lg"
                value={validate_reposition_outputs}
                onChange={changeCheck}
                isChecked={checkvalidaterepositionoutputs}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Usar perfiles de dian</LabeFormlStyled>
            <div>
              <Switch
                name="use_dian_profiling"
                id="use_dian_profiling"
                size="lg"
                value={use_dian_profiling}
                onChange={changeCheck}
                isChecked={checkusedianprofiling}
              />
            </div>
          </div>

          {/* <div>
            <LabeFormlStyled>Porcentaje valor Salidas</LabeFormlStyled>
            <div>
              <Switch
                name="percentage_value_outputs_reset"
                id="percentage_value_outputs_reset"
                size="lg"
                value={percentage_value_outputs_reset}
                onChange={changeCheck}
                isChecked={checkpercentagevalueoutputsreset}
              />
            </div>
          </div> */}

          <div>
            <LabeFormlStyled>No requiere pase de entrada</LabeFormlStyled>
            <div>
              <Switch
                name="not_require_entry_pass"
                id="not_require_entry_pass"
                size="lg"
                value={not_require_entry_pass}
                onChange={changeCheck}
                isChecked={checkrequireentrypass}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>No requiere pase de salida</LabeFormlStyled>
            <div>
              <Switch
                name="not_require_exit_pass"
                id="not_require_exit_pass"
                size="lg"
                value={not_require_exit_pass}
                onChange={changeCheck}
                isChecked={checkrequireexitpass}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Puerto</LabeFormlStyled>
            <div>
              <Switch
                name="port"
                id="port"
                size="lg"
                value={port}
                onChange={changeCheck}
                isChecked={checkport}
              />
            </div>
          </div>
        </SimpleGrid>



        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap={"wrap"}>
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleAceptar}>
            Aceptar
          </ButtonStyled>
        </Box>
      </form>
    </>
  );
};
