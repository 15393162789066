import Select from "react-select";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import React from "react";

const SelectedMultipleUC = ({ userCa, tag, setValues }) => {
  const selectValor = (optionesVa) => {
    const values = optionesVa.map((item) => ({
      value: item?.id,
      label: item?.nit_qualified + " - " + item?.name_qualified
    }));
    return values;
  };

  const handleSelectChange = (items) => {
    const ids = items.map((data) => data?.value);
    setValues(ids)
  };

  return (
    <>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={selectValor(userCa)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </>
  );
};

export default SelectedMultipleUC;
