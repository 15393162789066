/* eslint-disable no-useless-concat */

import { SimpleGrid, Switch } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import { safelyConvertToString } from "utils/type-check-utils";

export const FormEntryHeader = ({ form, valuesCorrect }) => {
  const { authUser } = useContext(AuthContext);
  const {
    StateCurrentExport,
    selectExport,
    selectOperation,
    selectEmbarcacion,
    setPortM,
    setInspeccion,
    portM,
    inspeccion,
    portMCorrect,
    inspeccionCorrect
  } = useContext(FormCrudCRUDContext);
  const {
    code_operation,
    short_name_operation,
    shipment_entryForm,
    purchase_order,
    portmagdalena,
    inspection,
    thirdPartie_id
  } = form || {};
  
  const {
    shipment_entryFormCorrected,
    identification_numCorrected,
    business_nameCorrected,
    code_operationCorrected,
    short_name_operationCorrected,
    purchase_order_corrected
  } = valuesCorrect || {};


  useEffect(() => {
    if (portmagdalena === 0) {
      setPortM(false);
    } else {
      setPortM(true);
    }

    if (inspection === 0) {
      setInspeccion(false);
    } else {
      setInspeccion(true);
    }
  }, [portmagdalena, inspection, setInspeccion, setPortM]);

  return (
    <>
      <LabelGroup
        changeValue={
          safelyConvertToString(thirdPartie_id) !== safelyConvertToString(selectExport?.value) &&
            "BorderCorrect"
        }
        name={"searchInformacion"}
        value={StateCurrentExport || ""}
        tag={"Exportador/Proveedor*"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          (code_operation + " " + short_name_operation !==
            selectOperation?.label ||
            (code_operationCorrected &&
              code_operation + " " + short_name_operation !==
                code_operationCorrected +
                  " " +
                  short_name_operationCorrected)) &&
          "BorderCorrect"
        }
        name={"searchInformacion"}
        value={code_operation + " " + short_name_operation}
        tag={"Tipo de Operación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          (shipment_entryForm !== selectEmbarcacion?.label ||
            (shipment_entryFormCorrected &&
              shipment_entryForm !== shipment_entryFormCorrected)) &&
          "BorderCorrect"
        }
        name={"searchInformacion"}
        value={shipment_entryForm}
        tag={"Tipo de embarcación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(purchase_order) !== safelyConvertToString(purchase_order_corrected) && "BorderCorrect"
        }
        name={"purchase_order"}
        value={purchase_order}
        tag={"Orden de compra"}
        maxLength={80}
        size={100}
        desicion={true}
      />

      <SimpleGrid minChildWidth="100px" spacing="10px" className="ms-4 mt-3">
        {authUser.is_commerce === 1 ||
          (authUser.admin === 1 && (
            <div className={portM !== portMCorrect && "BorderCorrect"}>
              <LabeFormlStyled htmlFor="portmagdalena">
                Portmagdalena
              </LabeFormlStyled>
              <div>
                <Switch
                  name="portmagdalena"
                  id="portmagdalena"
                  size="lg"
                  value={portmagdalena}
                  isChecked={portM}
                  isDisabled
                />
              </div>
            </div>
          ))}
        {authUser.is_commerce === 1 || authUser.admin === 1
          ? (
            <div
              className={
                inspeccion !== inspeccionCorrect && "BorderCorrect"
              }
            >
              <LabeFormlStyled htmlFor="inspection">Inspección</LabeFormlStyled>
              <div>
                <Switch
                  name="inspection"
                  id="inspection"
                  size="lg"
                  value={inspection}
                  isChecked={inspeccion}
                  isDisabled
                />
              </div>
            </div>
          )
          : (
            ""
          )}
      </SimpleGrid>
    </>
  );
};
