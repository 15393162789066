import { Td, Tr } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import MenuReviewsUpdate from '../TableReviews/Menu/MenuReviewsUpdate'

const TbodyUpdateReview = ({ data, ...props }) => {
  const SelectRouter = status => {
    if (status === 'Formulario de ingreso') {
      return `/FormShow?FormEntry=${data?.form_id}`
    }

    if (status === 'Formulario de salida') {
      return `/FormShowExit?ExitNumber=${data?.form_id}`
    }

    if (status === 'Formulario de certificado de integración') {
      return `/ShowIntegrationBox?id=${data?.form_id}`
    }
  }

  return (
    <Tr>
      <Td className='text-center'>
        {' '}
        <NavLink to={SelectRouter(data.type_doc)}>
          <span className='formNumber'>{data?.num_form}</span>
        </NavLink>
      </Td>

      <Td className='text-center'>
        {data?.code_operation ? data?.code_operation : 'N/A'}
      </Td>
      <Td className='text-center'>{data?.name_qualified}</Td>
      <Td className='text-center'>{data?.type_doc}</Td>
      <Td className='text-center'>
        {data?.date_submitted} {data?.time_submitted}
      </Td>
      <Td className='text-center'>
        <MenuReviewsUpdate data={data} />
      </Td>
    </Tr>
  )
}

export default TbodyUpdateReview
