import environment from "constants/apiConst";

export const typeRegister = [
  {
    label: "Vehículo",
    value: "truck"
  },
  {
    label: "Conductor",
    value: "driver"
  },
  {
    label: "Persona",
    value: "person"
  },
  {
    label: "Todos",
    value: "all"
  }
];


export const historyNodeInputFields = [
  {
    type: "date",
    name: "date_start",
    tag: "Fecha inicial",
    search: "date_start",
  },
  {
    type: "date",
    name: "date_end",
    tag: "Fecha final",
    search: "date_end",
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_search",
    tag: "Seleccionar filtro",
    search: "type_search",
    data: typeRegister,
  },
/*   {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "PRESENTADO", label: "PRESENTADO" },
      { value: "APROBADO", label: "APROBADO" },
      { value: "AUTORIZADO", label: "AUTORIZADO" },
      { value: "DEVUELTO", label: "DEVUELTO" },
      { value: "ELIMINADO", label: "ELIMINADO" },
      { value: "CADUCADO", label: "CADUCADO" },
      { value: "ANULADO", label: "ANULADO" }
    ],
    value: null
  }, */
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]

export const histyoryExportNodeList = [

];
