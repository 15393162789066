export const typeSearchLogTracking = [
  { value: "reference", label: "REFERENCIA" },
  { value: "form", label: "FORMULARIO" }
];

export const typeOperations = [
  { value: "INGRESO", label: "INGRESO" },
  { value: "SALIDA", label: "SALIDA" },
  { value: "PRODUCCIÓN", label: "PRODUCCIÓN" }
];

export const formTypeInfo = [
  { value: 1, label: "Form. de salida" },
  { value: 2, label: "Form. de ingreso" },
  { value: 3, label: "Número de documento de transporte" },
  { value: 4, label: "Usuario calificado" },
  { value: 5, label: "Tipos de operación" },
  { value: 6, label: "Rango de subpartidas" }
];
export const followOrderInfo = [
  { value: "DIAN GIT", label: "DIAN GIT" },
  { value: "FISCALIZACIÓN", label: "FISCALIZACIÓN" },
  { value: "POLFA", label: "POLFA" },
  { value: "INTERNO", label: "INTERNO" },
  { value: "SARBAQ", label: "SARBAQ" },
  { value: "GAPER", label: "GAPER" }
];

export const serviceInfo = [
  { value: "CARTA", label: "CARTA" },
  { value: "CORREO", label: "CORREO" },
  { value: "N/A", label: "N/A" }
];

export const TypeFollowOrder = [
  { value: "1", label: "ENTRADA" },
  { value: "2", label: "SALIDA" },
  { value: "3", label: "INGRESO PENDIENTE" },
  { value: "4", label: "OTRO" }
];

export const statusTracking = [
  { value: 1, label: "Activo" },
  { value: 2, label: "Detenido" }
];

export const dateBy = [
  { value: 0, label: "Creación" },
  { value: 1, label: "Presentación" },
  { value: 2, label: "Revisión" },
  { value: 3, label: "Autorización" }
  // { value: 4, label: "Terminación" },
];

export const statePassOrDispacth = [
  { value: "BORRADOR", label: "BORRADOR" },
  { value: "PRESENTADO", label: "PRESENTADO" },
  { value: "APROBADO", label: "APROBADO" },
  { value: "AUTORIZADO", label: "AUTORIZADO" },
  { value: "DEVUELTO", label: "DEVUELTO" },
  { value: "ELIMINADO", label: "ELIMINADO" },
  { value: "CADUCADO", label: "CADUCADO" },
  { value: "ANULADO", label: "ANULADO" }
];

export const stateFormExitExpired = [
  { value: "BORRADOR", label: "BORRADOR" },
  { value: "APROBADO", label: "APROBADO" },
  { value: "DEVUELTO", label: "DEVUELTO" },
  { value: "ELIMINADO", label: "ELIMINADO" },
  { value: "ANULADO", label: "ANULADO" },
  { value: "CADUCADO", label: "CADUCADO" }
];

export const weighing = [
  { value: "Con pesaje", label: "Con pesaje" },
  { value: "Sin pesaje", label: "Sin pesaje" }
];

export const OperationType = [
  { value: "Ingreso Resto del mundo", label: "Ingreso Resto del mundo" },
  { value: "Ingreso Zona franca", label: "Ingreso Zona franca" },
  {
    value: "Ingreso Territorio nacional",
    label: "Ingreso Territorio nacional"
  },
  {
    value: "Ingreso Usuario de la misma Zona Franca",
    label: "Ingreso Usuario de la misma Zona Franca"
  },
  { value: "Salida Resto del mundo", label: "Salida Resto del mundo" },
  { value: "Salida Zona franca", label: "Salida Zona franca" },
  { value: "Salida Territorio nacional", label: "Salida Territorio nacional" },
  {
    value: "Salida Usuario de la misma Zona Franca",
    label: "Salida Usuario de la misma Zona Franca"
  }
];

export const OperationTypeInput = [
  { value: "Ingreso Resto del mundo", label: "Ingreso Resto del mundo" },
  { value: "Ingreso Zona franca", label: "Ingreso Zona franca" },
  {
    value: "Ingreso Territorio nacional",
    label: "Ingreso Territorio nacional"
  },
  {
    value: "Ingreso Usuario de la misma Zona Franca",
    label: "Ingreso Usuario de la misma Zona Franca"
  }
];

export const OperationTypeOutput = [
  { value: "Salida Resto del mundo", label: "Salida Resto del mundo" },
  { value: "Salida Zona franca", label: "Salida Zona franca" },
  { value: "Salida Territorio nacional", label: "Salida Territorio nacional" },
  {
    value: "Salida Usuario de la misma Zona Franca",
    label: "Salida Usuario de la misma Zona Franca"
  }
];

export const associatedSecurity = [
  { value: 0, label: "Asociado" },
  { value: 1, label: "No asociado" }
];

export const tidy = [
  { value: 0, label: "Num. Formulario" },
  { value: 1, label: "Fecha creación" },
  { value: 2, label: "Fecha presentación " },
  { value: 3, label: "Fecha revisión " },
  { value: 4, label: "Fecha autorización" }
];

export const formExpiredSortBy = [
  { value: "code_entryForm", label: "Num. Formulario" },
  { value: "operation_code", label: "Codigo de Operacion" },
  { value: "BORRADOR", label: "BORRADOR" },
  { value: "PRESENTADO", label: "PRESENTADO" },
  { value: "REVISADO", label: "REVISADO" },
  { value: "AUTORIZADO", label: "AUTORIZADO" }
];

export const stateCustomsClearence = [
  { value: "BORRADOR", label: "BORRADOR" },
  { value: "AUTORIZADO", label: "AUTORIZADO" }
];

export const dataOrderBy = [
  { value: 1, label: "Numero formulario" },
  { value: 2, label: "Fecha creación" },
  { value: 3, label: "Fecha autorización" }
];

export const Embarque = [
  { value: "Único", label: "Único" },
  { value: "Fraccionado", label: "Fraccionado" }
];

export const EntradaAsociada = [
  { value: "Con entrada", label: "Con entrada" },
  { value: "Sin entrada", label: "Sin entrada" }
];

export const AsociadoASeguridad = [
  { value: "Asociado", label: "Asociado" },
  { value: "No asociado", label: "No asociado" }
];

export const tidyFMM = [
  { value: "code", label: "Num. Formulario" },
  { value: 0, label: "Fecha creación" },
  { value: 1, label: "Fecha presentación " },
  { value: 2, label: "Fecha revisión " },
  { value: 3, label: "Fecha autorización" },
  { value: "type_operations", label: "Tipo de operación" }
];

export const ordenarPorIems = [
  { value: "code_entryForm", label: "Num. Formulario" },
  { value: "item", label: "Fecha creación" },
  { value: "reference", label: "Fecha presentación " },
  { value: "code_subheading", label: "Fecha revisión " },
  { value: "num_dex_entryForm", label: "Fecha autorización" }
  // { value: "type_operations", label: "Tipo de operación" },
];
export const ordenarPorIemsOutput = [
  { value: "code_outputForm", label: "Num. Formulario" },
  { value: "item", label: "Fecha creación" },
  { value: "reference", label: "Fecha presentación " },
  { value: "code_subheading", label: "Fecha revisión " },
  { value: "import_declaration", label: "Dcl. de importación" }
  // { value: "type_operations", label: "Tipo de operación" },
];

export const ordenarPorTemporales = [
  { value: "code_operation", label: "Codigo de Operacion" },
  { value: "item", label: "Item" },
  { value: "reference", label: "Referencia" },
  { value: "subheading", label: "Subpartida" }
];
