/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */ import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment
} from "pages/RefProductInter/LabelGroup";
import React, { useContext, useState } from "react";

export const FmmOheadercorrectedShow = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    code_operationCorrected,
    short_name_operationCorrected,
    shipment_corrected,
    refund_corrected,
    destination_address_corrected,
    refund,
    shipment,
    code_operation,
    short_name_operation,
    destination_address,
    reviews
  } = data;

  const {
    addresDestine,
    selectOperation,
    selectExport,
    refunds,
    selectEmbarcacion,
    StateCurrentExport
  } = useContext(FormCrudCRUDContext);

  const FormId = data?.id;
  const [CurrentPos, setCurrentPos] = useState(0);
  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );

  const operationOriginal = code_operation + " " + short_name_operation;
  const operationCorrected = `${short_name_operationCorrected ? (code_operationCorrected && code_operationCorrected + " ") + short_name_operationCorrected : ""}`;

  const Status = data.status_outputFormCorrected;
  /*
  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        //console.log("No encontrado");
      }
    } else {
      return "";
    }
  };
 */
  /*  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;

    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  }; */

  const handleTypeOperation = () => {
    /* It's a correctedOutPutForm */
    if (data?.balance_correction !== 1) {
      return operationCorrected;
    }

    /* It's a balance */
    if (data?.operation_id !== data?.operationCorrected_id) {
      return operationCorrected;
    }
    return ""
  }

  return (
    <>
      {/*    <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Comentario</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ModalComment
                obj={GetLocalStorage(FormId)}
                Pos={CurrentPos}
                FormId={FormId}
                onClose={onClose}
                setVerifiedStatus={setVerifiedStatus}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </> */}

      {/* inicio Importador/Comprador */}
      {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            value={selectExport?.label ? selectExport?.label : "N/A"}
            name={"searchInformacion"}
            tag={"Importador/Comprador*"}
            placeholder={"Importador/Comprador*"}
            size={100}
            desicion={false}
            ColorCustom={(x) =>
              StateCurrentExport !== selectExport?.label &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            changeValue={
              StateCurrentExport !== selectExport?.label && "BorderCorrect"
            }
            // onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            changeValue={
              StateCurrentExport !== selectExport?.label && "BorderCorrect"
            }
            // label={TooltipComentary("searchInformacion")}
            bg={"#900800"}
            value={selectExport?.label ? selectExport?.label : "N/A"}
            name={"searchInformacion"}
            tag={"Importador/Comprador*"}
            size={100}
            desicion={false}
            readOnly={true}
          />
        )}
      {/* Fin Importador/Comprador */}

      {/* inicio Tipo de Operación */}
      {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            value={operationCorrected ?? "N/A"}
            tag={"Tipo de Operación"}
            placeholder={"Tipo de Operación"}
            name={"TypeOfOperation"}
            size={100}
            desicion={false}
            ColorCustom={(x) =>
              operationOriginal !== selectOperation?.label &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            // onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            changeValue={
              data?.balance_correction !== 1 &&
            operationOriginal !== selectOperation?.label && "BorderCorrect"
            }
            value={handleTypeOperation()}
            /* label={TooltipComentary("TypeOfOperation")} */
            bg={"#900800"}
            name={"TypeOfOperation"}
            tag={"Tipo de Operación"}
            size={100}
            desicion={false}
            readOnly={true}
          />
        )}
      {/* Fin Tipo de Operación */}

      {/* inicio Tipo de embarcación */}
      {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            value={shipment_corrected || "N/A"}
            name={"TypeOfBoat"}
            tag={"Tipo de embarcación"}
            placeholder={"Tipo de embarcación"}
            size={100}
            desicion={false}
            ColorCustom={(x) =>
              shipment !== selectEmbarcacion?.label &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            // onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            changeValue={shipment !== selectEmbarcacion?.label && "BorderCorrect"}
            value={shipment_corrected || "N/A"}
            name={"TypeOfBoat"}
            // label={TooltipComentary("TypeOfBoat")}
            bg={"#900800"}
            tag={"Tipo de embarcación"}
            size={100}
            desicion={false}
            readOnly={true}
          />
        )}

      {/* Fin Tipo de embarcación */}

      {/* inicio Modalidad */}
      {data.status_outputFormCorrected &&
        data.status_outputFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            value={refund_corrected || "N/A"}
            name={"Modality"}
            tag={"Modalidad"}
            placeholder={"Modalidad"}
            size={100}
            desicion={false}
            ColorCustom={(x) =>
              refund !== refunds?.label && Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            // onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            changeValue={refund !== refunds?.label && "BorderCorrect"}
            value={refund_corrected || "N/A"}
            name={"Modality"}
            tag={"Modalidad"}
            size={100}
            // label={TooltipComentary("Modality")}
            bg={"#900800"}
            desicion={false}
            readOnly={true}
          />
        )}

      {/* Fin Modalidad */}

      {selectOperation &&
        addresDestine.map((items, index) => {
          if (items === selectOperation.value) {
            return (
              <LabelGroup
                changeValue={
                  destination_address !== destination_address_corrected &&
                  "BorderCorrect"
                }
                name={"destination_address"}
                value={destination_address_corrected}
                tag={"Dirección de destino"}
                size={100}
                desicion={true}
                key={index}
              />
            );
          }
        })}
    </>
  );
};
