/*
import { MaxLength } from "utils/MaxLength";
 */

/*
const [openAlert] = AlertErrorAlert();
*/

/*
  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}`: `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

*/

/*
(e) => handleRegex(e, 'Letras', /^[a-zA-ZÀ-ÿ\s-.,]*$/g)
*/
/*
(e) => handleRegex(e, 'all', /^[0-9a-zA-ZÀ-ÿ\s-._/-]*$/g)
*/
/*
(e) => handleRegex(e, 'Números', /^[0-9-.\s]*$/g)
*/

export const Regex = {
  letras : /^[a-zA-ZÀ-ÿ\s-._/-]*$/g,
  all: /^[0-9a-zA-ZÀ-ÿ\s-._/,-]*$/g,
  numeros: /^[0-9-._/+*\s]*$/g,
}

export const RegexR = {
  letras: /^[a-zA-ZÀ-ÿ\s\-._/]+$/,
  all: /^[0-9a-zA-ZÀ-ÿ\s\-._/,]+$/,
  numeros: /^[-+]?(?:\d*\.\d+|\d+)(?:[eE][-+]?\d+)?(?:\s*[-+*/]\s*[-+]?(?:\d*\.\d+|\d+)(?:[eE][-+]?\d+)?)*$/,
};