import axios from "axios";
import env from "../../constants/apiConst";

export const __ZonasFranca = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_ZONAS_F_NOT_PAGE}`, options);
  return res;
};

export const __ZonasFrancaObt = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.ZONAS_F_GET}?page=${page}`, options);
  return res;
};

export const __ShowZonasFranca = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ZONAS_F_SHOW}/${id}`, options);
  return res;
};
