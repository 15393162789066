import React, { useContext } from "react";
import {
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import ProductCRUDContext from "context/ProductCRUDContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuProduct = ({ data, viewShow = false, deleteFiled = true }) => {
  const {
    onOpenEdit,
    setDataToEdit,
    onOpenDelete,
    setDataToDelete,
    setDataToShow,
    onOpenShow
  } = useContext(ProductCRUDContext);

  const handleEdit = (e) => {
    // console.log(data);
    setDataToEdit(data);
    onOpenEdit();
  };

  const handleDelete = (e) => {
    setDataToDelete(data);
    onOpenDelete();
  };

  const handleShow = (e) => {
    setDataToShow(data);
    onOpenShow();
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {viewShow && (
          <MenuItemStyled py={3} onClick={handleShow}>
            <IconMenuRUD as={MdRemoveRedEye} />
            Ver
          </MenuItemStyled>
        )}
        <Divider />
        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>
        <Divider />
        {deleteFiled && (
          <MenuItemStyled py={3} onClick={handleDelete}>
            <IconMenuRUD color="#d92525" as={MdDelete} />
            Eliminar
          </MenuItemStyled>
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuProduct;
