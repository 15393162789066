import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { TiDelete } from "react-icons/ti";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiEdit2Fill } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import PassCrudContext from "context/PassCrudContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuItemPass = ({ data }) => {
  const location = useLocation();
  const { typeO = "" } = queryString.parse(location.search);
  const {
    onOpenWarning,
    setDataToDelete,
    setDataItemDispachSelect,
    seteditItemDispatch,
    setShowFormDispacthItem,
    setDataToCopy,
    onOpenCopy
  } = useContext(PassCrudContext);

  const handleDelete = (e) => {
    setDataToDelete(data);
    onOpenWarning();
  };

  const handleEditItem = () => {
    console.log(data);
    setDataItemDispachSelect(data);
    seteditItemDispatch(true);
    setShowFormDispacthItem(true);
  };

  const copyItems = () => {
    setDataToCopy(data);
    onOpenCopy();
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={handleDelete}>
          <IconMenuRUD as={TiDelete} color="#d92525" />
          Eliminar
        </MenuItemStyled>
        {typeO === "Dispatch" && (
          <>
            <MenuItemStyled py={3} onClick={handleEditItem}>
              <IconMenuRUD as={RiEdit2Fill} />
              Editar
            </MenuItemStyled>
            <MenuItemStyled py={3} onClick={copyItems}>
              <IconMenuRUD as={MdContentCopy} color="#03C39A" />
              Copiar
            </MenuItemStyled>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuItemPass;
