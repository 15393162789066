/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import InputSelect, {
  InputSelectMulti,
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useMemo, useState } from "react";
import { __TypeTransportNp } from "request/Pases/__CrudPass";
import { ButtonStyled } from "styled/Buttons.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { isArray, isValid } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";
import {
  dateBy,
  statePassOrDispacth,
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import environment from "constants/apiConst";

export const SearchCorrection = ({ onClose, setSearchAdvanced }) => {
  const location = useLocation();
  const { movement = null } = queryString.parse(location.search);
  const { state, currentSearch, setParamsSearch } = useSearchUrl({
    getParams: [
      "freelyAvailable",
      "associated_security",
      "loaded",
      "date_from",
      "stateDispacthOrPass",
      "datosBy",
      "typeTransporSelec",
      "user_Qualified",
      "weighin",
      "plate",
      "identification",
      "observations",
      "formNumber",
      "container",
      "date_end",
      "time_end",
      "date_start",
      "time_start",
      "num_commercial_invoice",
      "ntd",
      "shipment",
      "modality",
      "code_operation",
      "code_form_original",
      "code",
      "dcl",
    ],
    nestedProp: "searchAdvance",
  });
  const [datosBy, setDatosBy] = useState(state?.datosBy);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(
    state?.stateDispacthOrPass
  );

  const [operationT, setOperationT] = useState(currentSearch?.operationT);
  const [values, handleInputChange, reset, setValues] = useForm({
    plate: state?.plate,
    identification: state?.identification,
    observations: state?.observations,
    container: state?.container,
    date_end: state?.date_end,
    time_end: state?.time_end,
    date_start: state?.date_start,
    time_start: state?.time_start,
    num_commercial_invoice: state?.num_commercial_invoice,
    dcl: state?.dcl,
    ntd: state?.ntd,
    shipment: state?.shipment,
    modality: state?.modality,
    code_operation: state?.code_operation,
    code_form_original: state?.code_form_original,
    code: state?.code,
    type_operation: operationT?.value,
  });

  useEffect(() => {
    console.log("movement", movement);
  }, [movement]);

  const {
    date_start,
    date_end,
    time_start,
    time_end,
    dcl,
    num_commercial_invoice,
    ntd,
    shipment,
    modality,
    code_operation,
    code_form_original,
    code,
    identification_num,
  } = values;

  const handleSubmit = () => {
    console.log("operationT", operationT);
    const datosSend = {
      ...values,
      code,
      type_operation: operationT?.value,
      ...(stateDispacthOrPass && {
        status: isArray(stateDispacthOrPass)
          ? stateDispacthOrPass?.map((item) => item?.value)
          : stateDispacthOrPass?.value ?? "",
      }),
      date_from: datosBy?.value ?? "",
    };

    const sanitizePayload = Object.keys(datosSend).reduce((acc, curr) => {
      if (isValid(datosSend[curr]) && datosSend[curr] !== "") {
        acc[curr] = datosSend[curr];
      }
      return acc;
    }, {});

    const selectsPayload = Object.entries({
      datosBy,
      stateDispacthOrPass,
    }).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    setParamsSearch({
      params: {
        ...sanitizePayload,
        ...selectsPayload,
      },
    });
    onClose();
  };

  const { requestData } = useGlobalContext();

  const { data: dataTypeOperations } = useQuery({
    queryKey: ["dataTypeOperations"],
    queryFn: () =>
      requestData({
        Endpoint: environment.TYPE_OPERATION_LIST,
        PropertyBody: "GET",
      }),
  });

  const memoizedTypeOperations = useMemo(() => {
    return dataTypeOperations?.data?.status?.data
      ?.filter(
        (item) =>
          !item?.name_typeOperation?.startsWith(
            movement === "Salida" ? "Ingreso" : "Salida"
          )
      )
      .map((item) => ({
        value: item?.id,
        label: item?.name_typeOperation,
      }));
  }, [dataTypeOperations, movement]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"code"}
          value={code}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
          // maxiLength={"10"}
        />
        <LabelGroup
          name={"code_form_original"}
          value={code_form_original}
          onChange={handleInputChange}
          tag={"Nº Formulario Original"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"date_start"}
          value={date_start}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_end"}
          value={date_end}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"time_start"}
          value={time_start}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"time_end"}
          value={time_end}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Fecha de:"}
            data={dateBy}
            value={datosBy}
            set={setDatosBy}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            name={"status"}
            tag={"Estado"}
            data={statePassOrDispacth}
            value={stateDispacthOrPass}
            set={setStateDispacthOrPass}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Tipo Operación"}
            data={memoizedTypeOperations}
            value={operationT}
            set={setOperationT}
          />
        </div>
        <LabelGroup
          name={"identification_num"}
          value={identification_num}
          onChange={handleInputChange}
          tag={"Nit Proveedor/Agente"}
          size={45}
          tipo={"text"}
          maxLength={"11"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"num_commercial_invoice"}
          value={num_commercial_invoice}
          onChange={handleInputChange}
          tag={"Nº Factura comercial"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"ntd"}
          value={ntd}
          onChange={handleInputChange}
          tag={"Doc. Transporte"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"dcl"}
          value={dcl}
          onChange={handleInputChange}
          tag={"DTA"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"code_operation"}
          value={code_operation}
          onChange={handleInputChange}
          tag={"Cod. Operación"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        {movement !== "Corregido de salida" ? (
          <LabelGroup
            name={"shipment"}
            value={shipment}
            onChange={handleInputChange}
            tag={"Embarque"}
            size={45}
            tipo={"text"}
          />
        ) : (
          <LabelGroup
            name={"modality"}
            value={modality}
            onChange={handleInputChange}
            tag={"Modalidad"}
            size={45}
            tipo={"text"}
          />
        )}
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
