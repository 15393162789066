import React, { useState } from "react";
import { useModal } from "hooks/useModal";
import FormField from "components/FormField/FormField";
import AlertError from "components/Alerts/AlertError";
import { CardButton } from "styled/Buttons.styled";
import { InputsContainer, StyledLink } from "../../Auth.styled";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import { PasswordContext } from "context/PasswordContext";
// import { PASSWORD_TYPES } from "redux/types/passwordTypes";
import { __Forgot_Password } from "request/__Forgot_Password";
import { ScaleFade } from "@chakra-ui/react";
import AlertSuccess from "components/Alerts/AlertSuccess";
import SpinnerComponent from "components/Spinner/SpinnerComponent";

const RecoverPasswordSchema = Yup.object().shape({
  nickname: Yup.string()
    .min(4, "El usuario debe tener por lo menos 4 caracteres.")
    .max(80, "El usuario debe tener menos de 80 caracteres.")
    .required("El usuario debe tener por lo menos 4 caracteres.")
});

const initialValues = { nickname: "" };

const RecoverPassword = () => {
  const [isOpen, openModal, closeModal] = useModal();
  const [isOpenSuccess, openModalSuccess, closeModalSuccess] = useModal();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const recover = async (values) => {
    const { nickname } = values;
    // Validar correo (pendiente)

    const formData = new FormData();
    formData.append("nickname", nickname);
    try {
      setLoading(true);
      const forgotPass = await __Forgot_Password(formData);
      // console.log(forgotPass);

      if (forgotPass.data.status.code !== 200) {
        setLoading(false);
        setAlertMessage(forgotPass.data.status.message);
        openModal();
        return;
      }

      setLoading(false);
      setAlertMessage(
        `Correo valido: Se ha enviado un mensaje a su bandeja de entrada`
      );
      openModalSuccess();
    } catch (error) {
      setAlertMessage(`${error}`);
      openModal();
    }
  };

  const handleCloseAlert = () => {
    if (isOpen) {
      closeModal();
      setAlertMessage("");
      return;
    }
    if (isOpenSuccess) {
      closeModalSuccess();
      setAlertMessage("");
    }
  };

  return (
    <>
      {loading && <SpinnerComponent />}
      {alertMessage && (
        <>
          <ScaleFade in={isOpen}>
            <AlertError
              alertMessage={alertMessage}
              closeAlert={handleCloseAlert}
            />
          </ScaleFade>
          <ScaleFade in={isOpenSuccess}>
            <AlertSuccess
              alertMessage={alertMessage}
              closeAlert={handleCloseAlert}
            />
          </ScaleFade>
        </>
      )}
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem",
          wordWrap: "break-word",
          padding: "0 2rem"
        }}
      >
        Digita tú usuario de acceso o nickname para reiniciar la contraseña. Te enviaremos
        un mensaje con el link de restablecimiento.{" "}
      </p>
      <Formik
        validationSchema={RecoverPasswordSchema}
        initialValues={initialValues}
        onSubmit={recover}
      >
        {() => (
          <Form>
            <InputsContainer margin="1rem 2rem 2rem 0">
              <FormField
                size="lg"
                name="nickname"
                placeholder="Usuario"
                autocomplete="nickname"
              />
              <StyledLink to="/auth">Volver</StyledLink>
              <CardButton type="submit">ENVIAR CORREO ELECTRÓNICO</CardButton>
            </InputsContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RecoverPassword;
