import { Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

const TBodyTraceability = ({ data }) => {
  const formType = {
    entries: "Ingreso",
    output: "Salida",
    entry_pass: "Pase de entrada",
    dispatch: "Pase de salida",
    ci: "Certificado de integración",
    production_order: "Orden de producción",
    custom_clearance: "Desaduanamiento"
  };

  const formNavigation = (data) => {
    const formTypeNavigation = {
      entries: `/FormShow?FormEntry=${data?.id}`,
      output: `/FormShowExit?ExitNumber=${data?.id}`,
      entry_pass: `/pases?numberForm=${data?.code}&id=${data?.id}&status=${data?.status}&typeO=`,
      dispatch: `/pases?numberForm=${data?.code}&id=${data?.id}&status=${data?.status}&typeO=Dispatch`,
      ci: `/ShowIntegrationBox?id=${data?.id}`,
      production_order: `/VerOrdenDeProduccion?Orden=${data?.id}`,
      custom_clearance: `/ShowCustomsClearance?Customs=${data?.id}`

    };
    return formTypeNavigation[data?.form_type] || formTypeNavigation[data?.type];
  };

  return (
    <>
      {data !== null && (
        <Tr>
          <Td className="text-center"> {formType[data?.form_type] || formType[data?.type] || "N/A"}</Td>
          <Td className="text-center">
            {data?.code
              ? (
                <NavLink className="formNumber" to={formNavigation(data)}>
                  {data?.code || "N/A"}
                </NavLink>
              )
              : (
                <Text>{"N/A"}</Text>
              )}
          </Td>
          <Td className="text-center"> {data?.status || "N/A"}</Td>
        </Tr>
      )}
    </>
  );
};

export default TBodyTraceability;
