/* eslint-disable no-unused-vars */
import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import { SwitchPropiosMedios } from "pages/RefProductInter/LabelGroup";
import { useEffect } from "react";
import { useState } from "react";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const TBodyTypeAlert = ({ data }) => {
  const [emailCheck, setEmailCheck] = useState(0);

  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    // const { email_notify } = data?.config;

    setEmailCheck(data?.config?.email_notify);
  }, []);

  //     updateConfig(data);

  const queryClient = useQueryClient();

  const updateConfig = async (data) => {
    try {
      const res = await __Post(
        environment.UPDATE_NOTIFICATION_CONFI,
        token,
        data
      );
      const restData = res?.data?.status;
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e, state) => {
    updateConfig({ ...(data?.config?.id ? { id: data?.config?.id } : { notify_id: data?.id }), [e]: state });
  };

  return (
    <Tr>
      <Td>{data?.name}</Td>

      <Td className="d-flex justify-content-center">
        <SwitchPropiosMedios
          status={emailCheck}
          setStatus={setEmailCheck}
          name="email_notify"
          tag={""}
          handleUpdate={handleChange}
          margin
        />
      </Td>
    </Tr>
  );
};

export default TBodyTypeAlert;
