import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import React from 'react'

function Information () {
  return (
    <Box
      w={'full'}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      p={6}
      borderRadius={'10px'}
      shadow={
        'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      }
    >
      <TableContainer textAlign={'center'}>
        <Text fontWeight={500} color={'blue.400'} pb={3}>
          CLASIFICACIÓN DE USUARIOS POR NIVEL DE RIESGO
        </Text>
        <Table variant='striped' colorScheme='gray'>
          <Tbody>
            <Tr>
              <Td fontWeight={600}>5</Td>
              <Td>4 o mas criterios con calificación 3</Td>
              <Td fontWeight={600}>Muy Alto</Td>
              <td
                className='text-center'
                style={{
                  padding: '3px',
                  backgroundColor: 'rgb(160, 6, 6)'
                }}
              ></td>
            </Tr>
            <Tr>
              <Td fontWeight={600}>4</Td>
              <Td>Hasta 3 criterios con calficación 3</Td>
              <Td fontWeight={600}>Alto</Td>
              <td
                className='text-center'
                style={{
                  padding: '3px',
                  backgroundColor: 'rgb(255, 46, 46)'
                }}
              ></td>
            </Tr>
            <Tr>
              <Td fontWeight={600}>3</Td>
              <Td>3 o mas criterios con calificación 2</Td>
              <Td fontWeight={600}>Moderado Alto</Td>
              <td
                className='text-center'
                style={{
                  padding: '3px',
                  backgroundColor: 'rgb(225, 187, 16)'
                }}
              ></td>
            </Tr>
            <Tr>
              <Td fontWeight={600}>2</Td>
              <Td>Hasta 2 criterios con calficación 2</Td>
              <Td fontWeight={600}>Moderado Bajo</Td>
              <td
                className='text-center'
                style={{
                  padding: '3px',
                  backgroundColor: 'rgb(255, 230, 0)'
                }}
              ></td>
            </Tr>
            <Tr>
              <Td fontWeight={600}>1</Td>
              <Td>Todos los criterios con calificación 1</Td>
              <Td fontWeight={600}>Leve</Td>
              <td
                className='text-center'
                style={{
                  padding: '3px',
                  backgroundColor: 'rgb(0, 182, 55)'
                }}
              ></td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Information
