import { authTypes } from "redux/types";

export const updateFreeZone = (payload) => {
  return {
    type: authTypes.updateFreeZone,
    payload: {
      freeZone_id: payload?.id,
      port: payload?.port,
      name_freeZone: payload?.name_freeZone,
      nameFreeZoneTemp: payload?.shortName_freeZone
    }
  };
}

export default updateFreeZone;
