import React from "react";
import { FormInputGroup } from "styled/Input.styled";

export const ExportPdfRefund = ({ data }) => {
  const stylesFail = {
    border: "1px solid #bababa",
    borderRadius: "5px",
    padding: "10px",
    marginTop: "10px",
    fontSize: "13px"
  };
  return (
    <>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Justificación del FOB</p>
            {data.adjustment_justification_fob
              ? data.adjustment_justification_fob
              : "N/A"}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Valor agregado US$</p>
            {data.added_value_us ? data.added_value_us : "N/A"}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Valor a Reintegrar US$</p>
            {data.refund_value_us ? data.refund_value_us : "N/A"}
          </FormInputGroup>
        </FormInputGroup>
      </div>
    </>
  );
};
