import {
  Button,
  Radio,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import MenuProduct from "components/Menu/MenuProduct";
import { AuthContext } from "context/AuthContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import ProductCRUDContext from "context/ProductCRUDContext";
import {
  __CHANGE_STATUS_PRODUCTS,
  __SHOW_PRDUCTOS,
} from "request/productoInterno/__ProductoInterno";
import { useSelector } from "react-redux";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "./TableOrder";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import Pagination from "components/Pagination/Pagination";
import { ConfigIconStatus } from "pages/Configuration/tables/components/LoadingConfig";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { NumericFormat } from "react-number-format";
import { isArray, isNumberFormatable } from "utils/type-check-utils";
import { MdUploadFile } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import { useMutation } from "@tanstack/react-query";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;
const ContainerLogs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 720px;
  overflow-y: auto;
`;

const CardLog = styled.div`
  padding: 10px;
  border: #1f7ac3 1px solid;
  border-radius: 10px;
  width: 49%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
`;

const ElementLog = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const ElementChangeLog = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`;

const NumberElementLog = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  border-radius: 50%;
  background-color: #1f7ac3;
  color: #fff;
  position: absolute;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const TbodyProduct = ({ data }) => {
  // const [fomateo] = useFormatQuantity();
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [statusProduct, setStatusproduct] = useState(data?.status_product ?? 0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenLog,
    onOpen: onOpenLog,
    onClose: onCloseLog,
  } = useDisclosure();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [loadingIcons, setLoadingIcons] = useState(false);
  const [dataLog, setDataLog] = useState();
  const { user_permissions } = useSelector((state) => state.auth.dataUser);
  const [editProductUser, setEditProductUser] = useState();

  useEffect(() => {
    const editProdUser =
      isArray(user_permissions) &&
      user_permissions.find(
        (item) => item.name_permission === "editProductUser"
      );
    setEditProductUser(editProdUser?.name_permission);
  }, [user_permissions]);

  const handleState = async () => {
    const formdata = new FormData();
    formdata.append("id", data?.id);
    formdata.append("status_product", statusProduct);

    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __CHANGE_STATUS_PRODUCTS(token, formdata);

      if (res?.data?.status?.code === 200) {
        setStatusproduct(res?.data?.status?.data?.status_product);
        openAlert(res?.data?.status?.message, "success");
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
    } finally {
      setLoadingIcons(false);
    }
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );

  const { requestData } = useGlobalContext();

  const { mutate: showLog, isLoading } = useMutation({
    mutationFn: requestData,
    onSuccess: (res) => {
      if (res?.data?.status?.code !== 200) {
        return openAlert(res?.data?.status?.message, "error");
      }
      setDataLog(res?.data?.status?.data?.data);
      onOpenLog();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenLog}
        onOpen={onOpenLog}
        onClose={onCloseLog}
        title={"🕒 Historial de cambios"}
      >
        <ContainerLogs>
          {dataLog?.map((item, index, coll) => {
            return (
              <CardLog key={index}>
                <NumberElementLog>{coll.length - index}</NumberElementLog>
                <ElementLog>Fecha: {item?.created_at}</ElementLog>
                <ElementLog>Usuario: {item?.nickname}</ElementLog>
                <ElementLog>Cambios: </ElementLog>
                {JSON.parse(item?.new_value)?.code_product && (
                  <ElementChangeLog>
                    {" "}
                    Código: {JSON.parse(item?.new_value)?.code_product}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.name_typeProduct && (
                  <ElementChangeLog>
                    {" "}
                    Tipo de producto:{" "}
                    {JSON.parse(item?.new_value)?.name_typeProduct}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.description_product && (
                  <ElementChangeLog>
                    {" "}
                    Descripción:{" "}
                    {JSON.parse(item?.new_value)?.description_product}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.code_subheading && (
                  <ElementChangeLog>
                    {" "}
                    Subpartida: {JSON.parse(item?.new_value)?.code_subheading}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.businessUnit_id && (
                  <ElementChangeLog>
                    {" "}
                    Unidad de medida:{" "}
                    {JSON.parse(item?.new_value)?.businessUnit_id}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.convertion_factor_product && (
                  <ElementChangeLog>
                    {" "}
                    Factor de conversion:{" "}
                    {JSON.parse(item?.new_value)?.convertion_factor_product}
                  </ElementChangeLog>
                )}
                {JSON.parse(item?.new_value)?.group_product && (
                  <ElementChangeLog>
                    {" "}
                    Grupo: {JSON.parse(item?.new_value)?.group_product}
                  </ElementChangeLog>
                )}
              </CardLog>
            );
          })}
        </ContainerLogs>
      </ModalGeneric>

      <Tr>
        {authUser.admin === 1 || authUser.is_commerce === 1 ? (
          <Td className="text-center">{data?.nit_qualified}</Td>
        ) : (
          ""
        )}
        {authUser.admin === 1 || authUser.is_commerce === 1 ? (
          <Td>{data?.name_qualified}</Td>
        ) : (
          ""
        )}
        <Td className="text-center">{data?.code_product}</Td>
        <Td className="text-center">{data?.name_typeProduct}</Td>
        <Td className="text-center">{data?.description_product} </Td>
        <Td className="text-center">{data?.code_subheading}</Td>
        <Td className="text-center">{data?.code_unit_measurement}</Td>
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          customInput={Td}
          className="text-center"
        >
          {isNumberFormatable(data?.convertion_factor_product)
            ? parseFloat(data?.convertion_factor_product)
            : data?.convertion_factor_product || "N/A"}
        </NumericFormat>
        {(authUser.admin === 1 || authUser.is_commerce === 1) && (
          <Tooltip
            hasArrow
            placement="top"
            label={
              data?.tracking?.some((tracking) => tracking.status === 1)
                ? "En seguimiento"
                : data?.tracking?.every((tracking) => tracking.status === 2)
                ? "Estuvo en seguimiento"
                : ""
            }
          >
            <Td>
              {data?.tracking && (
                <span
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Radio
                    defaultChecked
                    onClick={() => showTrackingItem(data?.tracking)}
                    colorScheme={
                      data?.tracking?.some((tracking) => tracking?.status === 1)
                        ? "red"
                        : "green"
                    }
                  />
                </span>
              )}
            </Td>
          </Tooltip>
        )}

        {!authUser.admin && !authUser.is_commerce && editProductUser && (
          <Tooltip hasArrow placement="top" label={"Ver historial"}>
            <Td
              onClick={() => {
                !data?.has_log
                  ? openAlert("No hay historial", "error")
                  : showLog({
                      Endpoint: environment.GET_LOG_PRODUCTS,
                      data: {
                        product_id: data?.id,
                      },
                      PropertyBody: "sendJSONContentPOST",
                    });
              }}
              className="text-center"
            >
              <IconGeneric
                color={data?.has_log === 1 ? "#1f7ac3" : "#9a9a9a"}
                as={IoEyeSharp}
              />
            </Td>
          </Tooltip>
        )}

        <Tooltip
          hasArrow
          placement="top"
          label={data.status_product === 1 ? "Desactivar" : "Activar"}
        >
          <TdState
            onClick={handleState}
            className="text-center"
            text={data?.status_product}
          >
            <ConfigIconStatus loading={loadingIcons} status={statusProduct} />
          </TdState>
        </Tooltip>
        <Td className="text-center">
          <MenuProduct data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TbodyProduct;
