import axios from 'axios';
import environment from 'constants/apiConst';

export const __Activate_Or_Inactivate = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.ACTIVATE_OR_INACTIVATE_MONITORING, options);
  return res;
};

export const __Get_FollowOrderStopTracking = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.GET_FOLLOW_ORDER, options);
  return res;
};
