import React, { useContext, useState } from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { CgPlayListAdd } from "react-icons/cg";
// import { MdOutlineAddShoppingCart } from "react-icons/md";
// import { RiFileAddLine } from "react-icons/ri";
import { MdUploadFile } from "react-icons/md";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { AddItemsMassively } from "components/AddItemsMassively/AddItemsMassively";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useBulkItemAggregation } from "hooks/useBulkItemAggregation";
import { RiAddFill, RiPlayListAddFill } from "react-icons/ri";
import { BiArrowToRight } from "react-icons/bi";
import { useSelector } from "react-redux";

export const UploaderItems = ({
  set,
  itemss,
  reset,
  codeForm,
  idQualified,
  setIsOpenAddProductForm,
  isOpenAddProductForm,
  setIsOpenAddIncomesForm,
  isOpenAddIncomesForm,
  children,
}) => {
  const [isOpenImport, onOpenImport, onCloseImport] = useModal();
  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const { dataToEdit, BulkLoad, update, setUpdate } = useContext(FormCrudCRUDContext);
  const [openAlert] = AlertErrorAlert();
  const { user_rol } = useSelector((state) => state.auth.dataUser);

  const openFmmItems = () => {
    reset();
    set(!itemss);
  };

  const Upload = () => {
    document.getElementById("display").click();
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);
        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);
      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
  };

  const handleClean = () => {
    removeItemFromArr();
    onCloseImport();
  };

  const AddItemsMassiively = () => {
    if (Attachment.length !== 0) {
      const formData = new FormData();
      formData.append("file", Attachment);
      formData.append("qualifiedUser_id", idQualified);
      formData.append("code_outputForm", codeForm);
      BulkLoad(environment.IMPORT_ITEMS_OUTPUT, formData, handleClean);
    } else {
      openAlert(`Olvidaste seleccionar un archivo`, "error");
    }
  };

  const formData = new FormData();
  formData.append("file", Attachment);
  formData.append("qualifiedUser_id", idQualified);
  formData.append("code_outputForm", codeForm);
  const { bulkAddItems, ErrorTable, isLoadingJobStatus: isLoadingBulkAggregation } = useBulkItemAggregation({
    userProvidedEndpoint: environment.IMPORT_ITEMS_OUTPUT,
    userProvidedBody: formData,
    isOpen: isOpenImport,
    onClose: onCloseImport,
    file: Attachment,
    nameFile: nameAttachment,
    update,
    setUpdate,
    key: codeForm
  })

  return (
    <>
      <ModalGeneric
        isOpen={isOpenImport}
        onOpen={onOpenImport}
        onClose={onCloseImport}
        title="Agregar ítem masivamente"
        buttonClose={false}
      >
        <AddItemsMassively
          nameAttachment={nameAttachment}
          handleAttachment={handleAttachment}
          Upload={Upload}
          removeItemFromArr={removeItemFromArr}
          onCloseImport={onCloseImport}
          handleSUbmit={bulkAddItems}
          ErrorTable={ErrorTable}
          isLoading={isLoadingBulkAggregation}
        />
      </ModalGeneric>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-evenly'}
          w={'100%'}
          mt={'1rem'}
          gap={3}
          mb={5}
        >
          <Box display={'flex'} alignItems={'center'} w={'100%'} gap={3}>
            <Text fontSize="md" style={{ fontWeight: 600 }}>
              Ítem del Formulario
            </Text>

            {children}
          </Box>
          <Box display={'flex'} alignItems={'flex-end'}>
            <Tooltip placement="top" hasArrow label="Agregar ítem masivamente">
              <ButtonHeaderModule
                className="me-1 d-flex float-end"
                bgColor="#1F7AC3"
                onClick={onOpenImport}
              >
                <IconGeneric width="16px" color="#fff" as={MdUploadFile} />
              </ButtonHeaderModule>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Agregar ítem">
              <ButtonHeaderModule
                onClick={() => openFmmItems()}
                bgColor="#03C39A"
                className="d-flex float-end me-1"
              >
                <IconGeneric width="16px" color="#fff" as={CgPlayListAdd} />
              </ButtonHeaderModule>
            </Tooltip>

            {user_rol?.modules?.some(item => item?.permission?.some(name => name?.name_permission === "saveProductToOutputForm")) &&
              <Tooltip placement="top" hasArrow label="Agregar producto">
                <ButtonHeaderModule
                  onClick={() => setIsOpenAddProductForm(!isOpenAddProductForm)}
                  bgColor="#58B158"
                  className="d-flex float-end me-1"
                >
                  <IconGeneric width="16px" color="#fff" as={RiAddFill} />
                </ButtonHeaderModule>
              </Tooltip>}

            {user_rol?.modules?.some(item => item?.permission?.some(name => name?.name_permission === "saveEntryFormToOutputForm")) &&
              <Tooltip placement="top" hasArrow label="Agregar ingreso">
                <ButtonHeaderModule
                  onClick={() => setIsOpenAddIncomesForm(!isOpenAddIncomesForm)}
                  bgColor="#B8DB1A"
                  className="d-flex float-end me-1"
                >
                  <IconGeneric width="16px" color="#fff" as={BiArrowToRight} />
                </ButtonHeaderModule>
              </Tooltip>}
          </Box>
        </Box>
    </>
  );
};
