/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Button, SimpleGrid, Switch, Tooltip } from "@chakra-ui/react";
import {
  SelectFormTypeOperation,
  SelectThirdParties
} from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { InputGroupUser } from "pages/RefProductInter/LabelGroup";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { AuthContext } from "context/AuthContext";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import ThirdPartiesForm from "pages/Terceros/form/ThirdPartiesForm";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import ModalTransition from "components/modal/ModalTransition";
import environment from "constants/apiConst";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNotations } from "context/NotationsContext";
import { isNumber } from "utils/type-check-utils";
import { Regex } from "utils/Regex";

/**
 *  DataCompany -  Componente el cual maneja toda la informacion de operacione, terceros, usario calificado y orden de compra..
 *
 * @component
 * @param {Object} values Lista de valores para los diferentes inpus .
 * @param {Function} handleInputChange Funcion para escuchar cambios de inputs.
 * @return {Component} Retorna componente con diferentes select, check e inputs para crear un formulario de entrada.
 */

const 
DataCompany = ({ values, handleInputChange, errorsState }) => {
  const { cormagdalena, inspection, purchase_order } = values;
  const { authUser } = useContext(AuthContext);
  const options = ["Único", "Fraccionado"];
  const location = useLocation();
  const { FormExitNumber = null, FormEntryNumber = null } = queryString.parse(
    location.search
  );
  const formId = FormEntryNumber || FormExitNumber;
  const { createItemDecimal, setCreateItemDecimal } = useGlobalContext()

  const {
    dataOperation,
    userQualified,
    setValueQuialified,
    setNameQualified,
    nameQualified,
    valueQualified,
    setSelectUF,
    setSelectItemForm,
    setSelectIdOp,
    selectItemForm,
    dataThird,
    setDataThird,
    setValueThird,
    setNameThird,
    nameThird,
    valueThird,
    setPortM,
    setInspeccion,
    portM,
    inspeccion,
    selectUF,
    nameOperation,
    setNameOperation,
    getThirdParties,
    getUserQualified,
    setDecimalUcContext,
    setChangeQualified,
    setOnFmm,
    updateThirdParties,
    setupdateThirdParties,
    dataFormCurrent,
    isOpenSelectAuthorizationItems,
  } = useContext(FormCrudCRUDContext);

  const { isOpenEdit, onOpenEdit, onCloseModal, setRequestCreate } =
    useContext(ThirdPartiesContext);
  const { setUser_Qualified, setFormOperation } = useContext(GlobalContext);
  const { reviewsData, updateNotationData, updateNotation } = useNotations();

  /**
   * If the checkbox is checked, set the state to true. If the checkbox is unchecked, set the state to
   * false.
   * @param e - SyntheticEvent&lt;HTMLInputElement&gt;
   * @returns The return value of the function is undefined.
   */

  const changeCheck = (e) => {
    handleInputChange(e);
    if (e.target.name === "cormagdalena") {
      setPortM(e.target.checked);
      return;
    }
    if (e.target.name === "inspection") {
      setInspeccion(e.target.checked);
    }
  };

  useEffect(() => {
    setChangeQualified(valueQualified);
    if (
      valueQualified &&
      valueQualified.length !== 0 &&
      authUser.is_qualified_user !== 1
    ) {
      getThirdParties(valueQualified);
    }
    getUserQualified();
  }, [valueQualified, updateThirdParties]);

  useEffect(() => {
    if (authUser.is_qualified_user === 1) {
      getThirdParties();
    }
  }, [authUser, updateThirdParties]);

  useEffect(() => {
    setRequestCreate(environment.CREATE_THIRD_PARTY);
    setOnFmm(true);
  }, []);

  useEffect(() => {
    return () => {
      setValueQuialified(null);
      setDataThird([]);
    }
  }, []);

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  const reviewData = reviewsData?.Header;

  useEffect(() => {
    if(isNumber(valueQualified)) setUser_Qualified(valueQualified);
  }, [valueQualified]);

  const colorNotation = (propertyName) => {
    return (
      updateNotationData?.[formId]?.Header?.[propertyName]?.status?.color ||
      reviewData?.[propertyName]?.status?.color
    );
  };

  const customStyles = (propertyName) => {
    const color = colorNotation(propertyName);

    if (color) {
      return {
        control: (provided) => ({
          ...provided,
          borderColor: color
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color
        })
      };
    }
  };

  const nameSelect = {
    name_qualified: "Empresa",
    business_name: "Proveedor/Exportador",
    short_name_operation: 'Tipo de Operación"'
  };

  const selectChange = (items, select) => {
    setCreateItemDecimal(items?.decimal)
    return updateNotation(
      select?.name,
      nameSelect?.[select?.name],
      items?.label,
      dataFormCurrent?.[select?.name],
      "Header"
    );
  };

  return (
    <>
      <ModalTransition isOpen={isOpenEdit} onClose={onCloseModal} size="xl">
        <ThirdPartiesForm setupdateThirdParties={setupdateThirdParties} />
      </ModalTransition>
      <SimpleGrid
        minChildWidth="100px"
        spacing="10px"
        className="mx-1  p-1 mt-3"
      >
        {authUser.is_commerce === 1 ||
          (authUser.admin === 1 && (
            <div>
              <LabeFormlStyled htmlFor="cormagdalena">
                Portmagdalena
              </LabeFormlStyled>
              <div>
                <Switch
                  name="cormagdalena"
                  id="cormagdalena"
                  size="lg"
                  value={cormagdalena}
                  onChange={changeCheck}
                  isChecked={portM}
                />
              </div>
            </div>
          ))}
        {authUser.is_commerce === 1 || authUser.admin === 1
          ? (
            <div className="ms-4">
              <LabeFormlStyled htmlFor="inspection">Inspección</LabeFormlStyled>
              <div>
                <Switch
                  name="inspection"
                  id="inspection"
                  size="lg"
                  value={inspection}
                  onChange={changeCheck}
                  isChecked={inspeccion}
                />
              </div>
            </div>
          )
          : (
            ""
          )}

        {authUser.is_qualified_user === 1
          ? (
            ""
          )
          : (
            <div className="mt-3 ms-3">
              <RadioBtn
                options={options}
                setOnly={setSelectUF}
                state={selectUF}
              />
            </div>
          )}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="20px" className="mt-4">
        {authUser.is_qualified_user !== 1 && (
          <div>
            <InputGroupUser
              customColorLabel={colorNotation("name_qualified")}
              Label={reviewData?.name_qualified?.comment}
              LabelBg={colorNotation("name_qualified")}
              styles={customStyles("name_qualified")}
              name={"name_qualified"}
              onChange={selectChange}
              partidas={userQualified}
              tag={"Empresa"}
              setMendigo={setValueQuialified}
              setNameQ={setNameQualified}
              nameQ={nameQualified}
              valor={valueQualified}
              setDecimalUcContext={setDecimalUcContext}
            />
          </div>
        )}
        <Tooltip
          hasArrow
          label={reviewData?.business_name?.comment}
          bg={colorNotation("business_name")}
          color="white"
          placement="bottom-start"
        >
          <div className="d-flex align-items-center justify-content-center">
            <SelectThirdParties
              size={100}
              customColorLabel={colorNotation("business_name")}
              styles={customStyles("business_name")}
              onChange={selectChange}
              name={"business_name"}
              data={dataThird}
              tag={"Proveedor/Exportador"}
              setValor={setValueThird}
              setName={setNameThird}
              Name={nameThird}
              valor={valueThird}
              ButtonSide={true}
            />

            <Tooltip
              hasArrow
              label={"Agregar tercero"}
              color="white"
              placement="top"
            >
              <Button
                onClick={onOpenEdit}
                w={"42px"}
                h={"38px"}
                fontSize={"25px"}
                marginTop={"24px"}
                borderRadius={0}
                borderTopRightRadius={"5px"}
                borderBottomRightRadius={"5px"}
                color={"#fff"}
                backgroundColor={"#1f7ac3"}
                paddingBottom={"1px"}
                outline={"none"}
              >
                +
              </Button>
            </Tooltip>
          </div>
        </Tooltip>

        {authUser.is_qualified_user === 1
          ? (
            <div className="margin-data ms-3">
              <RadioBtn
                options={options}
                setOnly={setSelectUF}
                state={selectUF}
              />
            </div>
          )
          : (
            ""
          )}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="20px" className="mt-4">
        <div>
          <SelectFormTypeOperation
            data={values?.validate_item_temporary ? dataOperation?.filter(operation => operation.reentry_operation === 1) : dataOperation}
            setPrimaryValue={setFormOperation}
            error={
              !!(errorsState.opertationId &&
              (selectItemForm.length === 0 || selectItemForm === null))
            }
            customColorLabel={colorNotation("short_name_operation")}
            Label={reviewData?.short_name_operation?.comment}
            LabelBg={colorNotation("short_name_operation")}
            styles={customStyles("short_name_operation")}
            name={"short_name_operation"}
            onChange={selectChange}
            tag={"Tipo de Operación"}
            valor={selectItemForm}
            setValor={setSelectItemForm}
            setSelectIdOp={setSelectIdOp}
            setNameOperation={setNameOperation}
            nameOperation={nameOperation}
            size={100}
          />
          {
            values?.validate_item_temporary && !selectItemForm ? (
            <span style={{ color: "red", fontSize: "12px", fontWeight: "bold"}}>Por favor seleccione un tipo de operación*</span>
            ) : (
              ""
            )
          
          }
        </div>

        <Tooltip
          hasArrow
          label={reviewData?.purchase_order?.comment}
          bg={colorNotation("purchase_order")}
          color="white"
          placement="bottom-start"
        >
          <div className=" margin-input">
            <LabeFormlStyled
              style={
                colorNotation("purchase_order") && {
                  color: colorNotation("purchase_order")
                }
              }
              htmlFor="purchase_order"
            >
              Orden de Compra (OC)
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              data-input-tag={"Orden de Compra"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.purchase_order}
              ColorFake={colorNotation("purchase_order")}
              name="purchase_order"
              id="purchase_order"
              width={"95%"}
              value={purchase_order}
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              maxLength={MaxLength.formEntry.formEntryNumber.purchase_order}
              required
            />
          </div>
        </Tooltip>
      </SimpleGrid>
    </>
  );
};

export default DataCompany;
