import { useContext, useEffect, useState } from "react";
import HeadboardIntegration from "./Headboard/HeadboardIntegration";
import IntegrationBody from "./IntegrationBody";
import GlobalContext from "context/GlobalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import environment from "constants/apiConst";
import { useFormBlocker } from "hooks/useFormBlocker";
import useStatusListener from "hooks/useStatusListener";
import queryEnv from "constants/queryConst";

const IntegracionBox = () => {
  const { requestData } = useContext(GlobalContext);
  const [openAlert] = AlertErrorAlert();
  const location = useLocation();
  const { id = null } = queryString.parse(location.search);
  const [showEdit, setShowEdit] = useState(null);
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const queryClient = useQueryClient();

  const handleResponse = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.setQueryData([queryEnv?.INTEGRATION_BOX], res?.data?.status?.data);
      return setShowEdit(res?.data?.status?.data);
    }
  };

  useEffect(() => {
    if (!id) return;
    const showID = new FormData();
    showID.append("id", id);

    const config = {
      data: showID,
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => handleResponse(data),
    });
  }, [id]);

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: id || "",
    type_of: "CI",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      (flag === "ci" || flag === "cic") &&
      (new_status !== "DEVUELTO" || new_status !== "BORRADOR") &&
      form_id === dataCurrent.integration_certificate_id;

    if (shouldRefetch) {
      navigate(`/ShowIntegrationBox?id=${form_id}`);
      return;
    }
  };

  useStatusListener({
    id: id,
    callBack: refetchForm,
    dataCurrent: showEdit,
  });

  return (
    <>
      <div className="d-flex justify-content-center aling-item-center">
        <HeadboardIntegration data={showEdit} />
      </div>

      <div className="col-lg-12 col-md-12 col-xs-6 ps-1 ">
        <IntegrationBody data={showEdit} />
      </div>
    </>
  );
};

export default IntegracionBox;
