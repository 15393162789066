/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import styled from "styled-components";
import MenuGestionUser from "components/Menu/MenuGestionUser";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { useContext } from "react";
import GestionCrudContext from "context/GestionCrudContext";
import { AuthContext } from "context/AuthContext";
import { __Change_Status, __SHOW_GESTION } from "request/GestionComercial/__GestionCo";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { FaSpinner } from "react-icons/fa";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyGestionComercial = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loadingIcons, setLoadingIcons] = useState(false);
  const { editData, setUpdate, update } = useContext(GestionCrudContext);
  const { authUser } = useContext(AuthContext);
  const [openAlert] = AlertErrorAlert();
  const handleState = async () => {
    setLoadingIcons(true)
    const body = new FormData()
    body.append('id', data.id ? data.id : "")
    body.append('status', data.status)
    try {
      const res = await __Change_Status(token, body);
      setUpdate(!update)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingIcons(false)
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.nickname}</Td>
      <Td className="text-center">
        {data?.name_person + " " + data?.lastname_person}
      </Td>
      <Td className="text-center">{data?.licenses?.[0]?.name_license}</Td>
      <Tooltip
        hasArrow
        placement="top"
        label={data.status === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          className="text-center"
          onClick={handleState}
          isNumeric
          text={data.status}
        >
          {loadingIcons
            ? (<IconGeneric
              color={"#207AC3"}
              as={FaSpinner}
              className="loading-icon"
            />)
            : (<IconGeneric
              color={data.status === 1 ? "#03C39A" : "#bababa"}
              as={BsCheckCircleFill}
            />)}
        </TdState>
      </Tooltip>

      <Td isNumeric className="text-center">
        <MenuGestionUser data={data} deleteField={false} />
      </Td>
    </Tr>
  );
};

export default TbodyGestionComercial;
