/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { ButtonStyled } from "styled/Buttons.styled";
import { __ShowTypeZf } from "request/configuration/TypeZf";
import { useSelector } from "react-redux";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

export const FormTypeZF = () => {
  const [status, setStatus] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    name_typeFreeZone: "",
    description_typeFreeZone: "",
    status_typeFreeZone: ""
  });
  const { description_typeFreeZone, name_typeFreeZone } = values;

  useEffect(() => {
    if (dataToEdit) {
      const initialpos = async () => {
        try {
          const res = await __ShowTypeZf(token, dataToEdit.id);
          setValues(res?.data?.status?.data);
          setStatus(res?.data?.status?.data.status_typeFreeZone);
        } catch (error) {}
      };
      initialpos();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (name_typeFreeZone.trim() === '') return openAlert('El campo del nombre es requerido', 'error')
    // console.log(values);
    const data = {
      ...values,
      status_typeFreeZone: status
    };
    if (dataToEdit) {
      return editData(data);
    }
    createData(data);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar tipo de zona franca"
          : "Crear tipo de zona franca"}
      </Text>
      <Divider mb={3} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name="name_typeFreeZone"
          tag={"Nombre Tipo de Zona Franca"}
          value={name_typeFreeZone}
          onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeZfb.name_typeFreeZone}
        />
        <LabelGroup
          name="description_typeFreeZone"
          tag={"Descripción Tipo de Zona Franca"}
          value={description_typeFreeZone}
          onChange={handleInputChange}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeZfb.description_typeFreeZone}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <SwitchesGroup
          status={status}
          setStatus={setStatus}
          tag={"¿Activo?"}
          size={45}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};
