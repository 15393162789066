const thead = [
  { name: "Nº Formulario", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Tipo de Usuario", order: "null" },
  { name: "Nit Usuario", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "Acciones", order: "null" }
];
const theadCI = [
  { name: "Nº Formulario", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Tipo de Usuario", order: "null" },
  { name: "Nit Usuario", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "Acciones", order: "null" }
];

const theadAproved = [
  { name: "Nº Formulario", order: "null" },
  { name: "Operación", order: "null" },
  // { name: "Usuario", order: "null" },
  { name: "Tipo de Usuario", order: "null" },
  // { name: "Nit Usuario", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "Acciones", order: "null" }
];

const theadUpdateReview = [
  { name: "Nº Formulario", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Empresa", order: "null" },
  { name: "Tipo de Documento", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "revisor", order: "null" }
];

const theadUpdateSuperADMIN = [
  { name: "Nº Formulario", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Tipo de Usuario", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "Acciones", order: "null" }
];

const theadSuperADMIN = [
  { name: "Nº Formulario", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Tipo de Usuario", order: "null" },
  { name: "Nit Usuario", order: "null" },
  { name: "Fecha de inicio", order: "null" },
  { name: "Acciones", order: "null" }
];

export {
  thead,
  theadCI,
  theadSuperADMIN,
  theadUpdateSuperADMIN,
  theadUpdateReview,
  theadAproved
};
