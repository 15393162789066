/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { InputGroupLicense } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import {
  __AddLicenses,
  __AddLicensesAll
} from "request/configuration/__AddLicenses";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const FormAddLicenses = () => {
  const initialForm = {
    nickname_qualified: "",
    name_qualified: "",
    nit_qualified: ""
  };

  const [form, setForm] = useState(initialForm);

  const { onCloseModal, dataToLicenses, setUpdate, update } = useContext(
    ConfigurationCRUDContext
  );
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const [userCa, setUserCa] = useState([]);
  const [valor, setValor] = useState([]);
  const location = useLocation();

  const { q = "" } = queryString.parse(location.search);

  useEffect(() => {
    if (dataToLicenses) {
      setForm(dataToLicenses);
    } else {
      setForm(initialForm);
    }
  }, [dataToLicenses]);

  useEffect(() => {
    const userC = async () => {
      const formData = new FormData();
      formData.append("search", q.toLowerCase());
      try {
        const res = await __AddLicensesAll(token);
        setUserCa(res?.data?.status?.data);
      } catch (error) {}
    };
    userC();
  }, []);

  const handleAdd = async () => {
    const data = {
      qualifiedUser_id: dataToLicenses.id,
      licenses_id: valor
    };
    try {
      const res = await __AddLicenses(token, data);
      if (res.data.status.code === 200) {
        openAlert("Licencia asignada Exitosamente", "success");

        onCloseModal();
        setUpdate(!update);
      } else {
        openAlert("Hubo un error", "error");
      }
    } catch (error) {}
  };

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToLicenses ? form.name_qualified : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_qualified}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nit</LabeFormlStyled>
                <InputFake>{form.nit_qualified}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row ">
            <InputGroupLicense
              partidas={userCa}
              tag={"Licncias"}
              size={50}
              setValor={setValor}
            />
          </div>
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleAdd}>Asignar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormAddLicenses;
