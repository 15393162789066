import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import "./Treasury.css";
import { formatValues } from "components/Funciones/FunctionFormaterNew";

const TbodyIncidents = ({ data }) => {
  const date = new Date(data?.created_at).toLocaleDateString();
  // console.log(data);

  return (
    <Tr className="sizeLeter">
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">
        {data?.type_recharge === 1 ? "Documentos" : "Pesaje de bascula"}
      </Td>
      <Td className="text-center">{formatValues(data?.current_balance)}</Td>
      <Td className="text-center">
        {formatValues(data?.consumable_value)}
      </Td>
      <Td className="text-center">
        {formatValues(data?.new_balance)}
      </Td>
      <Td className="text-center ">{data?.observations}</Td>
      <Td className="text-center">{date || ""}</Td>
    </Tr>
  );
};

export default TbodyIncidents;
