import AnexedView from "../view/AnexedView/AnexedView";
import { Title } from "styled/Title";

const Annexed = () => {
  return (
    <>
      <Title style={{ marginTop: 30, marginBottom: 40 }}>
     Interoperabilidad - Anexo 5
      </Title>

      <AnexedView />
    </>
  );
};

export default Annexed;
