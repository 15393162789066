/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import InventoryControlContext from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";

export const FormChangeSubheading = ({ onClose }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loading, setLoading] = useState(false);
  const [openAlert] = AlertErrorAlert();

  const { dataSubheanding, updateSubpartida } = useContext(
    InventoryControlContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    code_subheading: "",
    business_unit_code: "",
    quantity_available: "",
    quantity: "",
    description: "",
    subheadingSearch: "",
    code_businessUnit: "",
    descriptionNew: "",
    subheading_id: ""
  });

  const {
    code_subheading,
    business_unit_code,
    quantity_available,
    quantity,
    description,
    subheadingSearch,
    code_businessUnit,
    descriptionNew,
    subheading_idNew
  } = values;

  const obtenerSubheandingCurrent = useCallback(
    async (body) => {
      setLoading(true);
      try {
        const res = await __Post(
          environment.GET_CHANGE_SUBHEANDING_INVENTORY,
          token,
          body
        );
        const desResultado = res?.data?.status;
        if (desResultado?.code === 200) {
          setValues(desResultado?.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    if (dataSubheanding !== null) {
      const formData = new FormData();
      formData.append("inventory_id", dataSubheanding);
      obtenerSubheandingCurrent(formData);
    }
  }, [dataSubheanding, obtenerSubheandingCurrent]);

  const obtenerSubpartida = async (info) => {
    try {
      const res = await __Post(
        environment.GET_SUBHEADING_CUSTOMS_CLEARANCE,
        token,
        info
      );

      const resultado = res?.data?.status;
      if (resultado?.code === 200) {
        setValues({
          ...values,
          subheadingSearch: resultado?.data?.code_subheading,
          descriptionNew: resultado?.data?.description,
          code_businessUnit: resultado?.data?.code_businessUnit,
          subheading_idNew: resultado?.data?.id
        });
      } else if (resultado?.code === 403) {
        openAlert(resultado?.message, "error");
        setValues({
          ...values,
          subheadingSearch: "",
          descriptionNew: "",
          code_businessUnit: "",
          subheading_idNew: ""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const habldeBlur = (e) => {
    const formData = new FormData();
    formData.append("code_subheading", subheadingSearch);
    if (e.target.name === "subheadingSearch") {
      obtenerSubpartida(formData);
    }
  };

  useEffect(() => {
    if (code_businessUnit === business_unit_code) {
      setValues({
        ...values,
        quantity: ""
      });
    }
  }, [code_businessUnit]);

  const handleSubmit = () => {
    if (
      subheading_idNew === "" ||
      subheading_idNew === null ||
      subheading_idNew === undefined
    ) {
      openAlert("Ingrese una subpartida", "error");
    } else if (
      (code_businessUnit !== business_unit_code && quantity === "") ||
      quantity === undefined ||
      quantity === null
    ) {
      openAlert("Ingrese una cantidad validad", "error");
    } else if (
      description === "" ||
      description === null ||
      description === undefined
    ) {
      openAlert(
        "Ingrese la justificación del cambio de la subpartida",
        "error"
      );
    } else {
      const formData = new FormData();
      formData.append("inventory_id", dataSubheanding);
      formData.append("subheading_id", subheading_idNew);
      formData.append(
        "quantity",
        code_businessUnit === business_unit_code ? quantity_available : quantity
      );
      formData.append("description", description);
      updateSubpartida(
        environment.UPDATE_CHANGE_SUBHEANDING_INVENTORY,
        formData,
        onClose
      );
    }
  };

  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"code_subheading"}
              value={code_subheading || ""}
              onChange={handleInputChange}
              tag={"Subpartida actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"subheadingSearch"}
              value={subheadingSearch || ""}
              onChange={handleInputChange}
              tag={"Subpartida nueva"}
              size={45}
              onBlur={habldeBlur}
              tipo={"search"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"descriptionNew"}
              value={descriptionNew || ""}
              onChange={handleInputChange}
              tag={"Descripción subpartida nueva"}
              size={100}
              tipo={"text"}
              desicion={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"business_unit_code"}
              value={business_unit_code || ""}
              onChange={handleInputChange}
              tag={"Unidad actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"code_businessUnit"}
              value={code_businessUnit || ""}
              onChange={handleInputChange}
              tag={"Unidad nueva"}
              size={45}
              desicion={true}
              tipo={"text"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"quantity_available"}
              value={quantity_available || ""}
              onChange={handleInputChange}
              tag={"Cantidad actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"quantity"}
              value={quantity || ""}
              onChange={handleInputChange}
              tag={"Cantidad nueva"}
              size={45}
              tipo={"text"}
              desicion={code_businessUnit === business_unit_code}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"description"}
              value={description || ""}
              onChange={handleInputChange}
              tag={"Justificación del cambio"}
              size={100}
              tipo={"text"}
            />
          </div>
          <Flex className="my-3">
            <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
              Cancelar
            </ButtonStyled>
            <Spacer />
            <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
              Guardar
            </ButtonStyled>
          </Flex>
        </>
      )}
    </>
  );
};
