import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import { IconGeneric } from "styled/Icons.styled";
import styled from "styled-components/macro";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { _handleStateConfig } from "./func/_handleStateConfig";
import { ConfigIconStatus } from "./components/LoadingConfig";
const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyCustoms = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );

  const [openAlert] = AlertErrorAlert();
  const [loadingIcons, setLoadingIcons] = useState(false);

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.CUSTOMS_DESACTIVE, data.id)
          : await __ACTIVAR(token, environment.CUSTOMS_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /*   const handleState = async () => {
    setLoadingIcons(true);
    try {
      const res = await fetchConfig(environment.CUSTOMS_SHOW, token, data?.id);
      let result = res.data.status.data.status_custom;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  }; */

  /* const _handleStateConfig = async (
    url,
    setDisableAndActivate,
    setLoadingIcons,
    token,
    id
  ) => {
    setLoadingIcons(true);

    try {
      const config = await fetchConfig(url, token, id);
      const customStatus = config.data.status.data.status_custom;
      const Message = config?.data?.status?.customStatus;
      console.log("customStatus");
      setDisableAndActivate(customStatus);
    } catch (error) {
      console.error("Error while handling state:", error);
    } finally {
      setLoadingIcons(false);
    }
  }; */

  return (
    <Tr>
      <Td className="text-center">{data?.code_custom}</Td>
      <Td className="text-center">{data?.name_custom}</Td>
      <Td className="text-center">{data?.email_custom}</Td>
      <Td className="text-center">{data?.description_custom}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data?.status_custom === 1 ? "Desactivar" : "Activar"}
      >
        <TdState className="text-center" isNumeric text={data?.status_custom}>
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_custom}
            onClick={() =>
              _handleStateConfig(
                environment.CUSTOMS_SHOW,
                desactivarAndActivar,
                setLoadingIcons,
                token,
                data?.id
              )
            }
          />
        </TdState>
      </Tooltip>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyCustoms;
