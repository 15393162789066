import { SimpleGrid, useToast } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import Items from "components/Security/CardInfoSearchPlateVehicle/Items";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { __postcreateVetoTruckOrPerson } from "request/__Security";
import { ButtonStyled } from "styled/Buttons.styled";

/**
 *  Card - Componente que muestra la data buscada en bloqueo, se envia la data a mostrar a travez de el parametro item, tambien recibe
 * la function  onFilter que al momento de hacer el registro se realiza el filtro eliminando la card que se bloquea.
 * @component
 * @param {Object} item data que se le envia al componente para mostrarla en la card.
 * @param {Function} onFilter funcion que realiza el filtrado por card registrada
 * @return {Component} Retorna componente JSX
 */

const typeBlock = [
  {
    label: "Identificación",
    value: "identification"
  },
  {
    label: "Placa",
    value: "plate"
  },
  {
    label: "Ambos",
    value: "ambos"
  }
];

const typeOfSanctions = [
  {
    label: "Permanente",
    value: "Permanente"
  },
  {
    label: "Temporal",
    value: "temporal"
  }
];

const DRIVER = "driver";

const Card = ({ item, onFilter }) => {
  const toast = useToast();
  const [loadingVeta, setloadingVeta] = useState(false);
  const [selectTypeBlock, setSelectTypeBlock] = useState("");
  const [typeOfSanction, setSelectTypeOfSanction] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);

  const [values, handleInputChange, reset] = useForm({
    plateOrIdent: "",
    description: ""
  });

  const handleSubmitBlock = async () => {
    if (!values.description) {
      alertError("Debe agregar una descripción");
    } else if (!typeOfSanction) {
      alertError("Debe selecionar el tipo de sanción");
    } else if (item?.origin === DRIVER && !selectTypeBlock) {
      alertError("Debe seleccionar el tipo por el cual deseas bloquear");
    } else {
      setloadingVeta(true);
      try {
        const body = {
          name: item?.name,
          origin: item?.origin,
          description: values.description,
          type_block: selectTypeBlock?.value,
          type_of_sanction: typeOfSanction?.value,
          vehicle_plate: item?.vehicle_plate,
          identification: item?.identification
        };
        const res = await __postcreateVetoTruckOrPerson(token, body);
        const status = res?.data?.status;
        if (status?.code === 400) {
          alertError(status.message);
        }
        if (status?.code === 403) {
          alertError(status.message);
        }
        if (status?.code !== 200) {
          alertError("Ha ocurrido un error, por favor intente nuevamente");
        } else {
          reset();
          onFilter(item?.passForm_number);
          alertSuccess(status.message);
        }
        setloadingVeta(false);
      } catch (error) {
        setloadingVeta(false);
      }
    }
  };

  const alertSuccess = (title) => {
    toast({
      title: `${title}`,
      status: "success",
      isClosable: true,
      position: "top"
    });
  };

  const alertError = (title) => {
    toast({
      title: `${title}`,
      status: "error",
      isClosable: true,
      position: "top"
    });
  };

  return (
    <div className="col-sm-6">
      <div className="card ">
        {/* {item.origin === DRIVER ? (
          <div className="card-header name"> Núm. Formulario: {item?.passForm_number}</div>
        ) : (
          )} */}
        <div className="card-header name">{item?.name}</div>

        <div style={{ padding: 20 }}>
          <SimpleGrid my={4}>
            <div className="">
              <InputSelect
                data={typeOfSanctions}
                value={typeOfSanction}
                set={setSelectTypeOfSanction}
                tag="Tipo de sanción"
              />
            </div>
          </SimpleGrid>

          {item?.origin === DRIVER && (
            <SimpleGrid my={4}>
              <div className="">
                <InputSelect
                  data={typeBlock}
                  value={selectTypeBlock}
                  set={setSelectTypeBlock}
                  tag="Por cual deseas bloquear?"
                />
              </div>
            </SimpleGrid>
          )}
          {item?.origin === DRIVER && (
            <Items title="Nombre:" desc={item?.name} />
          )}

          <Items title="Identificación:" desc={item?.identification} />
          <Items title="Placa del vehículo:" desc={item?.vehicle_plate} />
          {/* <Items title="Número de formulario:" desc={item?.passForm_number} /> */}

          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
            <div className="">
              <LabelGroup
                size={100}
                required={true}
                // desicion={true}
                tag="Descripción"
                name="description"
                maxLength={300}
                value={values?.description}
                onChange={handleInputChange}
              />
            </div>
          </SimpleGrid>

          {loadingVeta
            ? (
              <SpinnerComponent />
            )
            : (
              <ButtonStyled type="submit" onClick={handleSubmitBlock}>
              Bloquear
              </ButtonStyled>
            )}
        </div>
      </div>
    </div>
  );
};

export default Card;
