import { Box, Button, Flex, Spacer, Stack } from "@chakra-ui/react";
import { useContext } from "react";
import GestionCrudContext from "context/GestionCrudContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

export const SubHeadGestion = ({ values, handleInputChange }) => {
  const { onOpenEdit } = useContext(GestionCrudContext);

  return (
    <>
      <Box m={4}>
        <Box m={4}>
          <Flex>
            <LabelGroup
              tag={"Buscar"}
              name={'search'}
              value={values?.search}
              onChange={handleInputChange}
            />
            <Spacer />
            <Stack spacing={4} direction="row" align="center">
              <Button
                onClick={onOpenEdit}
                size="md"
                height="40px"
                bg="#03C39A"
                color="#fff"
                _hover={{ bg: "#07876b" }}
                width="170px"
              >
                Agregar usuarios
              </Button>
            </Stack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
