import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Spacer
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import MonitoringContext from "context/MonitoringContext";
import { useForm } from "hooks/useForm";
import { useRegexLabelState, useRegexState } from "hooks/useRegexState";
import { useSearchUrl } from "hooks/useSearchUrl";
import {
  formTypeInfo,
  serviceInfo,
  followOrderInfo,
  statePassOrDispacth,
  statusTracking
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormSearchMonitoring = ({ setSearchAdvanced }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["form_type", "service", "follow_order", "form_code", "code", "tdn", "status", "start_date", "end_date"], nestedProp: "searchAdvance" })
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [formType, setFormType] = useState(formTypeInfo.find(item => item.value === state?.form_type) || null);
  const [service, setService] = useState(serviceInfo.find(item => item.value === state?.service) || null);
  const [followOrder, setFollowOrder] = useState(followOrderInfo.find(item => item.value === state?.follow_order) || null);
  const [status, setStatus] = useState(statusTracking.find(item => item.value === state?.status) || null);
  const { onCloseSearchTracking } = useContext(MonitoringContext);

  const { values: regexValues, handleInputChange: regexHandleInputChange } =
    useRegexLabelState({
      code: {
        value: state?.form_code || "",
        regex: /^[0-9]*$/
      },
      numTransportDocument: {
        value: state?.tdn || "",
        regex: /^[A-Za-z0-9]*$/
      },
      codeTracking: {
        value: state?.code || "",
        regex: /^[0-9]*$/
      }
    });

  const { code, numTransportDocument, codeTracking } = regexValues;

  const [values, handleInputChange, setValues] = useForm({
    date_start: state?.start_date || "",
    date_end: state?.end_date || ""
  });

  const { date_start, date_end } = values;


  const handleSubmit = () => {
    const formData = new FormData();
    const data = {
      form_type: formType?.value,
      service: service?.value,
      follow_order: followOrder?.value,
      form_code: code?.value,
      code: codeTracking?.value,
      tdn: numTransportDocument?.value,
      status: status?.value,
      start_date: date_start,
      end_date: date_end
    };

    setParamsSearch({ params: handleSanitizedObj(data) });

    formData.append("form_type", formType?.value ? formType?.value : "");
    formData.append("service", service?.value ? service?.value : "");
    formData.append(
      "follow_order",
      followOrder?.value ? followOrder?.value : ""
    );
    formData.append("form_code", code?.value);
    formData.append("code", codeTracking?.value);
    formData.append("tdn", numTransportDocument?.value);
    formData.append("status", status?.value ? status?.value : "");
    formData.append("start_date", date_start);
    formData.append("end_date", date_end);
    setSearchAdvanced(formData);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "0 auto 2rem auto"
        }}
      >
        <InputSelect
          size={45}
          name={"formType"}
          tag={"Tipo de seguimiento"}
          data={formTypeInfo}
          set={setFormType}
          value={formType}
        />
        <InputSelect
          size={45}
          name={"service"}
          tag={"Oficio"}
          data={serviceInfo}
          set={setService}
          value={service}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "0 auto 2rem auto"
        }}
      >
        <InputSelect
          size={45}
          name={"followOrder"}
          tag={"Gestora de seguimiento"}
          data={followOrderInfo}
          set={setFollowOrder}
          value={followOrder}
        />
        <LabelGroup
          fontSize="12.5px"
          name={"code"}
          value={code.value}
          onChange={regexHandleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo="text"
        />
      </div>

      <div
        style={{
          width: "95%",
          margin: "0 auto 2rem auto"
        }}
      >
        <FormControl>
          <FormLabel
            style={{ color: "#454546", fontSize: "0.75rem", fontWeight: "400" }}
          >
            Nº documento de transporte
          </FormLabel>
          <InputGroup>
            <Input
              style={{ border: "solid 1px #bababa", outline: "none" }}
              htmlSize={100}
              width="auto"
              type="text"
              name="numTransportDocument"
              value={numTransportDocument.value}
              onChange={regexHandleInputChange}
              maxLength={15}
            />
          </InputGroup>
        </FormControl>
      </div>

      <div
        style={{
          width: "95%",
          margin: "0 auto 2rem auto"
        }}
      >
        <FormControl>
          <FormLabel
            style={{ color: "#454546", fontSize: "0.75rem", fontWeight: "400" }}
          >
            Código de seguimiento
          </FormLabel>
          <InputGroup>
            <Input
              style={{ border: "solid 1px #bababa", outline: "none" }}
              htmlSize={100}
              width="auto"
              type="text"
              name="codeTracking"
              value={codeTracking.value}
              onChange={regexHandleInputChange}
              maxLength={15}
            />
          </InputGroup>
        </FormControl>
      </div>
      <div
        style={{
          width: "95%",
          margin: "0 auto 2rem auto"
        }}
      >
        <InputSelect
          name={"status"}
          tag={"Estado"}
          data={statusTracking}
          value={status}
          set={setStatus}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          fontSize="12.5px"
          name={"date_start"}
          value={date_start}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          desicion={status?.value === 1}
          fontSize="12.5px"
          name={"date_end"}
          value={date_end}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>

      <Flex className="my-3">
        <ButtonStyled onClick={onCloseSearchTracking} bgColor={"#bababa"}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
