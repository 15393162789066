import { Td, Tr } from "@chakra-ui/react";
import React from "react";

export const TbodyInventoryInProcess = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.qualified_user_nit}</Td>
      <Td className="text-center">{data?.qualified_user_name}</Td>
      <Td className="text-center">{data?.product_code}</Td>
      <Td className="text-center">
        {data?.inventory_quantity_reserved_product_input}
      </Td>
      <Td className="text-center">{data?.inventory_reference_description}</Td>
      <Td className="text-center">{data?.subheading_code}</Td>
      <Td className="text-center">{data?.inventory_operation_code}</Td>
      <Td className="text-center">{data?.inventory_initial_operation}</Td>
      <Td className="text-center">{data?.product_business_unit_code}</Td>
      <Td className="text-center">{data?.inventory_nature_of_inventory}</Td>
      <Td className="text-center">{data?.inventory_quantity_reserved_input}</Td>
      <Td className="text-center">
        {data?.inventory_quantity_reserved_product_input}
      </Td>
      <Td className="text-center">
        {data?.inventory_date_approved ? data?.inventory_date_approved : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.inventory_date_entry ? data?.inventory_date_entry : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.inventory_count_entry ? data?.inventory_count_entry : 0}
      </Td>
    </Tr>
  );
};
