import axios from "axios";

export const __GetData = async (endpoint, token, page) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    }
  };

  const res = await axios(`${endpoint}?page=${page || 1}`, options);

  return res;
};

export const __GetDataId = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);

  return res;
};

export const __GetDataNoPage = async (endpoint, token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}`, options);

  return res;
};

export const __Get = async (endpoind, token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoind}`, options);
  return res;
};
