export const theadTemplate = [
  { name: "NIT", order: "nit_qualified" },
  { name: "Empresa", order: "name_qualified" },
  { name: "Código", order: "code_productive_process" },
  { name: "Descripción", order: "description_productive_process" },
  { name: "¿Activo?", order: "status_productive_process" },
  { name: "Acciones", order: "actions" }
];

export const options = [
  "Productos",
  "Proceso productivo",
  "Unidades de medida"
];

export const items = ["Productos", "Insumos", "Otros costos y gastos"];

export const thead = {
  Productos: [
    { name: "Item", order: "item_product" },
    { name: "Código de producto", order: "code_product" },
    { name: "Producto", order: "description_product" },
    { name: "Tipo de producto", order: "name_typeProduct" },
    { name: "Cant. de producto", order: "quantity_product" },
    { name: "Und. de producto", order: "businessUnit_id" },
    { name: "Cant. de Arancel", order: "quantity_for_tariff" },
    { name: "Und. de Arancel", order: "unit_for_tariff" },
    { name: "Acciones", order: null }
  ],
  Insumos: [
    { name: "Item", order: "item" },
    { name: "Código de producto", order: "code_product" },
    { name: "Producto", order: "product" },
    { name: "Tipo de producto", order: "name_typeProduct" },
    { name: "Cantidad de producto", order: "quantity_product" },
    { name: "Unidad de producto", order: "unit_product" },
    { name: "Acciones", order: null }
  ],
  "Otros costos y gastos": [
    { name: "Item", order: "item" },
    { name: "Nombre", order: "name" },
  //  { name: "Cantidad", order: "quantify" },
    { name: "Peso neto", order: "net_weight" },
    { name: "Valor", order: "value" },
    { name: "Acciones", order: null }
  ]
};

export const TheadProduct = [
  { name: "NIT", order: "nit_qualified" },
  { name: "Empresa", order: "name_qualified" },
  { name: "Código", order: "code_product" },
  { name: "Tipo de producto", order: "name_typeProduct" },
  { name: "Descripción", order: "description_product" },
  { name: "Subpartida", order: "subheadin_productg" },
  { name: "Unidad de medida", order: "qualitify_measure" },
  { name: "Factor de conversión", order: "conversion_factor" },
  { name: "Seguimiento", order: "null" },
  { name: "¿Activo?", order: "status_product" },
  { name: "Acciones", order: "actions" }
];

export const TheadConv = [
  { name: "Código", order: "code_product" },
  { name: "Tipo de producto", order: "name_typeProduct" },
  { name: "Descripción", order: "description_product" },
  { name: "Subpartida", order: "subheadin" },
  { name: "Unidad de medida", order: "qualitify_measure" },
  { name: "Factor de conversión", order: "conversion_facto" },
  { name: "¿Activo?", order: "active" },
  { name: "Acciones", order: "actions" }
];

export const TheadConvLog = [
  { name: "Código", order: "code_product" },
  { name: "Tipo de producto", order: "name_typeProduct" },
  { name: "Descripción", order: "description_product" },
  { name: "Subpartida", order: "subheadin" },
  { name: "Unidad de medida", order: "qualitify_measure" },
  { name: "Factor de conversión", order: "conversion_facto" },
  { name: "Historial", order: "null" },
  { name: "¿Activo?", order: "active" },
  { name: "Acciones", order: "actions" }
];

export const TitleUnidades = [
  { name: "NIT", order: "nit_qualified" },
  { name: "Empresa", order: "name_qualified" },
  { name: "Codigo", order: "code_unit_measurement" },
  { name: "Unidad De Medida", order: "unit_measurement" },
  { name: "Estado", order: "status_unit_measurement" }
];

export const TitleUnidadesUC = [
  { name: "Codigo", order: "code_unit_measurement" },
  { name: "Unidad De Medida", order: "unit_measurement" },
  { name: "Estado", order: "status_unit_measurement" },
  { name: "Acciones", order: "actions" }
];
