import React, { useContext } from 'react'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { RiDeviceRecoverFill } from 'react-icons/ri'

import { BiShow } from 'react-icons/bi'
// import PassCrudContext from "context/PassCrudContext";
import { AuthContext } from 'context/AuthContext'
// import { useNavigate } from "react-router-dom";
import { AiOutlineFileSync } from 'react-icons/ai'
import { localPermissions } from 'Permissions/permissions'
import { useSelector } from 'react-redux'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsPresentado = ({ data, handlePresent }) => {
  const { authUser } = useContext(AuthContext)
  const { permissionList } = useSelector(state => state.permissions)
  const { EntryPass, OutputPass } = localPermissions

  const { changeStatus: changeStatusEntryPass } = EntryPass
  const { changeStatus: changeStatusOutputPass } = OutputPass
  return (
    <>
      {(authUser.admin === 1 || authUser.is_commerce === 1) &&
        (hasCommonElements(permissionList, [
          ...changeStatusOutputPass,
          ...changeStatusEntryPass
        ]) ||
          authUser.admin === 1) && (
          <>
            {/* <MenuItemStyled py={3}>
            <IconMenuRUD color="#F9A621" as={GiSecurityGate} />
            Entrada de seguridad
          </MenuItemStyled>
          <HrDividerNoMargin /> */}

            <MenuItemStyled onClick={() => handlePresent('REVISADO')} py={3}>
              <IconMenuRUD color={'green'} as={BiShow} />
              Revisar
            </MenuItemStyled>
          </>
        )}
      {(hasCommonElements(permissionList, [
          ...changeStatusOutputPass,
          ...changeStatusEntryPass
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={() => handlePresent('BORRADOR')} py={3}>
              <IconMenuRUD as={RiDeviceRecoverFill} />
              Recuperar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}
    </>
  )
}
