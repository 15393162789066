import React from "react";
import * as PropTypes from "prop-types";

import { background, Button, ButtonGroup } from "@chakra-ui/react";
import { RiCloseFill } from "react-icons/ri";

const RemoveButtonGroup = ({ onRemoveClick, children, ...props }) => (
  <ButtonGroup
    style={{
      margin: 0,
      padding: 0
    }}
    {...props}
  >
    {children}
    <Button
      style={{
        margin: 0,
        padding: 0,
        fontSize: '24px',
        background: 'transparent'
      }}
      onClick={onRemoveClick}
      leftIcon={<RiCloseFill />}
    />
  </ButtonGroup>
);

RemoveButtonGroup.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
};
export default RemoveButtonGroup;
