const { default: environment } = require("constants/apiConst");
const { __Post } = require("request/Petitions/__Post");

export const ChangeStatusWeight = async (
  status,
  token,
  openAlert,
  Reload,
  setReload,
  setFormStatus,
  ReloadContent = false
) => {
  try {
    const resp = await __Post(
      environment.CHANGE_STATUS_WEIGHING,
      token,
      status
    );
    const resultado = resp.data.status;
    if (resultado.code === 200) {
      openAlert(`Ha sido ${status?.status.toLowerCase()}`, "success");
      setFormStatus((prev) => ({
        ...prev,
        status: resultado?.data?.status
      }))
      ReloadContent && setReload(!Reload);
    }
    if (resultado.code !== 200) {
      openAlert("Hubo un error", "error");
    }
  } catch (error) {
    
  }
};
