import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import Pagination from "components/Pagination/Pagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TableOrder from "components/Tables/TableOrder";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __getHistoryVeto } from "request/__Security";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import TbodyTable from "./TbodyTable";
import { thead } from "./thead";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { CgImport } from "react-icons/cg";
import { useModal } from "hooks/useModal";
import { ModalGeneric } from "components/modal/ModalGeneric";
import ExportStatistics from "components/Estadistica/ExportStatistics";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import ExportAdvance, { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useDispatch } from "react-redux";
import { historyNodeInputFields, histyoryExportNodeList, typeRegister } from "./nodeList";
import { isArray } from "utils/type-check-utils";

/**
 *  History - Componente donde muestra el historial de los registros y procesos en reguridad
 *
 * @component
 * @return {Component} Retorna componente JSX, muestra la data mediante tablas
 */


const History = () => {
  const [openAlert] = AlertErrorAlert();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [history, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectTypeSearch, setSelectTypeSearch] = useState(null);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const dispatchRedux = useDispatch();


  const [values, handleInputChange, reset] = useForm({
    search: ""
  });
  const { search, date_start, date_end } = values;

  useEffect(() => {
    getHistoryVeto();
  }, [selectTypeSearch, search, freeZone_id]);
  useEffect(() => {
    if (date_start && date_end) {
      getHistoryVeto();
    }
  }, [date_start, date_end, freeZone_id]);

  const getHistoryVeto = async () => {
    setLoading(true);
    const body = {
      ...values,
      type_search: selectTypeSearch ? selectTypeSearch?.value : ""
    };
    const res = await __getHistoryVeto(token, body);
    const data = res?.data?.status;
    if (data?.code === 200) {
      dispatchRedux(setSearch(body));
      setDataHistory(data?.data?.data);
      console.log("getHistoryVeto", data?.data?.data);
      setMaxPage(data?.data?.last_page);
    } else {
      openAlert(data?.message, "error");
      dispatchRedux(clearSearch());
    }
    try {
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: data?.type_export_file?.value || "xlsx",
      ...(data?.qualifieUser_id && {
        qualifieUser_id: isArray(data?.qualifieUser_id)
          ? data?.qualifieUser_id?.map((item) => item?.value)
          : data?.qualifieUser_id?.value || ""
      }),
      ...(data?.type_search && {
        type_search: isArray(data?.type_search) ? data?.type_search[0]?.value : data?.type_search?.value || ""
      }),
      fields: formulario
    };
    exportColumnBy(info, onCloseExport);
  };

  const exportColumnBy = async (data, onCloseExport) => {
    try {
      const res = await __Post(environment.POST_EXPORT_EXCEL_VETO, token, data);
      if (res?.status === 200) {
        onCloseExport();
      } else {
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      dispatchRedux(clearSearch());
    }
  }, [])

  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      {loading && <SpinnerComponent />}
      <Title>Historial</Title>
      {/* <div className=" d-flex justify-content-between align-items-center mt-4 mb-4 "> */}
      {/* Exportar pendiente de cambios */}
      <ModalExportAdvance
        onClose={onCloseExport}
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        nodeList={histyoryExportNodeList}
        title={"Exportar documento"}
        handleSubmit={handleSubmit}
        selectNodeList={historyNodeInputFields}
      />

      <div className="row mt-3 mb-4">
        <div className="col-lg-4">
          <LabelGroup
            name={"search"}
            onChange={handleInputChange}
            value={search}
            tag={"Buscar"}
            size={100}
            desicion={false}
          />
        </div>
        <div className="col-lg-2">
          <LabelGroup
            name={"date_start"}
            onChange={handleInputChange}
            value={date_start}
            tag={"Fecha inicial"}
            size={100}
            desicion={false}
            tipo="date"
          />
        </div>
        <div className="col-lg-2">
          <LabelGroup
            name={"date_end"}
            onChange={handleInputChange}
            value={date_end}
            tag={"Fecha final"}
            size={100}
            desicion={false}
            tipo="date"
          />
        </div>
        <div className="col-lg-3">
          <InputSelect
            data={typeRegister}
            value={selectTypeSearch}
            set={setSelectTypeSearch}
            tag="Seleccionar filtro"
          />
        </div>
        <div className="col-lg-1"><Tooltip placement="top" hasArrow label="Exportar">
          <span>
            <ButtonHeaderModule
              onClick={onOpenExport}
              bgColor="#03C39A"
              className="mt-4 align-bottom d-flex float-end"
            >
              <IconGeneric color="#fff" as={CgImport} />
            </ButtonHeaderModule>
          </span>
        </Tooltip></div>
      </div>
      {/* </div> */}
      <TableOrder
        thead={thead}
        loading={false}
        data={history}
        setData={setDataHistory}
        tbodyData={TbodyTable}
      />
      <Pagination
        thead={thead}
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        loading={false}
      />
    </HeaderSearch>
  );
};

export default History;
