import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { __typesOfIdentifications } from "request/configuration/__TypeIdentifications";
import TbodyTypesIdentifications from "../tables/TbodyTypesIdentifications";
import FormTypeOfIdentifications from "../forms/FormTypeOfIdentifications";
import FormTypeIdentifications from "../FormShow/FormTypeIdentifications";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const formShowType = () => {
  return <FormTypeIdentifications />;
};

const formTypeDocument = () => {
  return <FormTypeOfIdentifications />;
};

const TypeIndentifications = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token); const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Codigo", order: "" },
    { name: "Nombre", order: "" },
    { name: "Estado", order: "" },
    { name: "Acciones", order: "" },
  ];
  useEffect(() => {
    setFormEdit(formTypeDocument);
    setFormShow(formShowType);
    setRequestEdit(environment.TYPE_OF_IDENTIFICATIONS_UPDATE);
    setRequestCreate(environment.TYPE_OF_IDENTIFICATIONS_CREATE);
    setRequestDelete(environment.TYPE_OF_IDENTIFICATIONS_DELETE);
  }, [update, page, q]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configTypeIdentifications", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.TYPE_OF_IDENTIFICATIONS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Tipo de Identificacion" />
      <TableOrder
        thead={Title}
        data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
        setData={setCurrentPage}
        loading={isLoading}
        tbodyData={TbodyTypesIdentifications}
      />
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default TypeIndentifications;
