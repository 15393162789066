import styled from "styled-components";

export const InputTRM = styled.input`
  width: 264px;
  height: 40px;
  border-radius: 2px;
  border: 1.5px solid #9097be;
  padding: 0px 18px;
  &:focus {
    outline: 1px #006eb8 solid;
  }
`;
