import React from "react";
import { Td, Tr } from "@chakra-ui/react";
// import MenuSecutiry from "components/Menu/MenuSecutiry";

/**
 *  TbodyTable - Componente que muestra los items mediante la tabla en el componente de historial
 * @component
 * @param {Object} data data que se muestra en la tabla del componete de Historial.
 * @return {Component} Retorna componente tabla jsx
 */

const TbodyTable = ({ data }) => {

  const comparativeOrigin = {
    truck : "Vehículo",
    driver : "Conductor",
    person: "Persona"
  }

  const {
    vehicle_plate,
    name_driver,
    date,
    status,
    identification,
    type_of_sanction,
    description,
    origin
  } = data;
  return (
    <Tr>
      <Td className="text-center"> {name_driver || "N/A"}</Td>
      <Td>{comparativeOrigin[origin]}</Td>
      <Td className="text-center">
        {" "}
        {identification || "N/A"}
      </Td>
      <Td className="text-center"> {vehicle_plate || "N/A"}</Td>
      <Td className="text-center"> {date || "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {type_of_sanction || "N/A"}
      </Td>
      <Td className="text-center"> {description || "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {status === 0 ? "Bloqueado" : "Desbloqueado"}
      </Td>
    </Tr>
  );
};

export default TbodyTable;
