/* eslint-disable no-unused-vars */
import { useModal } from "hooks/useModal";
import {
  __DeleteConfig,
  __EditCreateConfig,
  __EditCreateConfigFormData
} from "request/configuration/__CRUDConfig";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __ActiveAndDesactive } from "request/configuration/__TypeDocuments";

const { createContext, useState } = require("react");

const DianCRUDContext = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */

export const DianCRUDProvider = ({ children }) => {
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [isOpenLicencia, onOpenLicencia, onCloseLicencia] = useModal();
  const [isOpenRol, onOpenRol, onCloseRol] = useModal();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenLicenses, onOpenLicenses, onCloseLicenses] = useModal();
  const [isOpenDocument, onOpenDocument, onCloseDocument] = useModal();
  const [isOpenRemove, onOpenRemove, onCloseRemove] = useModal();
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [dataToLicenses, setDataToLicenses] = useState(null);
  const [dataToDocument, setDataToDocument] = useState(null);
  const [dataToRemove, setDataToRemove] = useState(null);
  const [idUserQualified, setidUserQualified] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestCreate, setRequestCreate] = useState(null); //
  const [requestEditLicense, setRequestEditLicense] = useState(null); //
  const [dataFormItems, setDataFormItems] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [formLicensias, setFormLicensias] = useState(null);
  const [formDocument, setDocument] = useState(null);
  const [formRemove, setRemove] = useState(null);
  const [update, setUpdate] = useState(true);
  const [dataProfile, setDataProfile] = useState(null);
  const [NewLicense, setNewLicense] = useState(true);
  const [act, setAct] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const [openAlert] = AlertErrorAlert();

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const editData = async (data) => {
    try {
      const res =
        movement === "Tipo de Documentos" || movement === "Tasas de Cambio"
          ? await __EditCreateConfigFormData(requestEdit, token, data)
          : await __EditCreateConfig(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        onCloseEdit();
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editDatLicense = async (data) => {
    try {
      const res = await __EditCreateConfig(requestEditLicense, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        onCloseEdit();
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async () => {
    try {
      const res = await __DeleteConfig(requestDelete, token, dataToDelete.id);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showData = () => {
    console.log(dataToShow);
  };

  const createData = async (data) => {
    try {
      const res =
        movement === "Tipo de Documentos"
          ? await __EditCreateConfigFormData(requestCreate, token, data)
          : await __EditCreateConfig(requestCreate, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
      return;
    }
    if (isOpenLicenses) {
      onCloseLicenses();
      return;
    }
    if (isOpenDocument) {
      onCloseDocument();
      return;
    }
    if (isOpenLicencia) {
      onCloseLicencia();
      setDataToEdit(null);
      return;
    }
    if (isOpenRemove) {
      onCloseRemove();
    }
  };

  const data = {
    editData,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    isOpenRemove,
    onOpenRemove,
    onCloseRemove,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    isOpenLicenses,
    onOpenLicenses,
    onCloseLicenses,
    isOpenDocument,
    onCloseDocument,
    onOpenDocument,
    setDataToLicenses,
    dataToLicenses,
    dataToDocument,
    setDataToDocument,
    dataToRemove,
    setDataToRemove,
    setDataToShow,
    showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    setRequestEditLicense,
    dataFormItems,
    setDataFormItems,
    formEdit,
    setFormShow,
    formShow,
    formLicensias,
    setFormLicensias,
    formDocument,
    setDocument,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    act,
    setAct,
    formRemove,
    setRemove,
    editDatLicense,
    isOpenLicencia,
    onOpenLicencia,
    onCloseLicencia,
    idUserQualified,
    setidUserQualified,
    NewLicense,
    isOpenRol,
    onOpenRol,
    onCloseRol,
    loading,
    setLoading,
    dataProfile,
    setDataProfile
  };

  return (
    <DianCRUDContext.Provider value={data}>{children}</DianCRUDContext.Provider>
  );
};

export default DianCRUDContext;
