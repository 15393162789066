import React from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";

const TBodyContainer = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.container}</Td>
      <Td className="text-center">{data?.capacity_container}</Td>

      <Td className="text-center">{data?.tare_container_1}</Td>
    </Tr>
  );
};

TBodyContainer.propTypes = {
  data: PropTypes.object
};

export default TBodyContainer;
