/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import environment from "constants/apiConst";
import { useSelector, useDispatch } from "react-redux";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __GetItems, __ShowItems } from "request/Forms/_CrudForm";
import { Commentary } from "components/Commentary/Commentary";
import { useForm } from "hooks/useForm";
import { SubTitleHeader } from "components/HeaderForm/Style";
import { AuthContext } from "context/AuthContext";
import { FormCorrectionRestWorld } from "./containerCorrect/FormCorrectionRestWorld";
import { FormCorrectionRestTerritory } from "./containerCorrect/FormCorrectionRestTerritory";
import { FormCorrectFreeZone } from "./containerCorrect/FormCorrectFreeZone";
import { FormCorrectFromUserToUser } from "./containerCorrect/FormCorrectFromUserToUser";
import { CorrectionRestWorld } from "./containerCorrect/CorrectionRestWorld";
import { CorrectionRestTerritory } from "./containerCorrect/CorrectionRestTerritory";
import { CorrectFreeZone } from "./containerCorrect/CorrectFreeZone";
import { CorrectFromUserToUser } from "./containerCorrect/CorrectFromUserToUser";
import Pagination from "components/Pagination/Pagination";
import { TitleItemFmm, TitleItemFmmShow } from "../arrayHeadItems";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { CgPlayListAdd } from "react-icons/cg";
import TableOrder from "components/Tables/TableOrder";
import { ForItemsCorrect } from "./ForItemsCorrect";
import { FormCorrectItems } from "./FormCorrectItems";
import "./Style.css";
import {
  restWorld,
  varFrezone,
  varRestTerritory,
  varUserToUser,
} from "./ArrayCorrect";
import { FormEntryHeader } from "./headerCorrectForm/FormEntryHeader";
import { FormCorrectEntry } from "./headerCorrectForm/FormCorrectEntry";
import { FormShowCorrect } from "./headerCorrectForm/FormShowCorrect";
import { __GetDataId } from "request/Petitions/__Get";
import LogsState from "./Logs/LogsState";
import ButtonView from "./Logs/BtnLog";
import { __Trm } from "request/configuration/__Trm";
import { useModal } from "hooks/useModal";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import TBodyItemCorrect from "../Table/TBodyItemCorrect";
import TBodyItemCorrectShow from "../Table/TBodyItemCorrectShow";
import ModalDelete from "components/modal/ModalDelete";
import { HeaderSearch } from "styled/SectionContainer.styled";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import Swal from "sweetalert2";
import { TitleOperations } from "../TitleOperation/TitleOperations";
import { BtnWithToltip } from "components/BtnWithToltip/BtnWithToltip";
import { ExportPdfEntryCorrect } from "../Export/pdfCorrect/ExportPdfEntryCorrect";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { SubTitle } from "styled/Title";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { CapsulaContainer } from "./CapsulaContainer";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { parseFloat } from "utils/parseFloat";
import { setFormData, clearFormData } from "redux/actions/dataFormActions";
import { __Post } from "request/Petitions/__Post";
import useStatusListener from "hooks/useStatusListener";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";
import { useGlobalContext } from "context/GlobalContext";
import {
  isArray,
  isNonEmptyString,
  isNumber,
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
  isString,
} from "utils/type-check-utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createKey, createObjectFromItem } from "../FormExit/BodyFormExit";
import { Storage } from "hooks/useStorage";
import { extensionOptions } from "./CorrectionForm";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { GroupInputUpload } from "../GroupInputUpload";
import InputSelect from "components/InputSelect/InputSelect";
import CreateAt from "../View/CreateAt";

const ReviewCorrectEntry = () => {
  const navigate = useNavigate();
  const [filesFront, setFilesFront] = useState({});
  const [nameFiles, setNameFiles] = useState([]);
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const location = useLocation(); // navigate
  const { FormEntry = "", ShowEntry = "" } = queryString.parse(location.search);

  const dispacthRedux = useDispatch();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setDataCorrected } = useGlobalContext();
  const { authUser } = useContext(AuthContext);
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal();
  const [suma, setSuma] = useState(0);
  const [multiplicacion, setMultiplicacion] = useState(0);
  const [valorDolarActual, setValorDolarActual] = useState(null);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [commentarios, setComentarios] = useState([]);
  const [code, setCode] = useState(null);
  // state collapse
  const [stateCollapse, setstateCollapse] = useState(true);
  // state collapse fin
  const [attachment, setAttachment] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);

  const initialForm = {
    qualifiedUser_id: "",
    num_dex: "",
    dex_date: "",
    exit_auth: "",
    inspection: "",
    operation_id: "",
    cormagdalena: "",
    department_id: "",
    num_exit_form: "",
    thirdPartie_id: "",
    code_entryForm: "",
    purchase_order: "",
    transp_doc_date: "",
    integration_box_certificate: " ",
    qualifiedUser2_id: "",
    shipment_entryForm: "",
    dcl_customs_transit: "",
    num_transport_document: "",
    num_commercial_invoice: "",
    num_import_declaration: "",
    customs_administration: "",
    inspection_act_or_auto: "",
    transit_expiration_date: "",
    cargo_manifest_entryForm: "",
    integration_box_certificate: "",
    date_entryForm: "",
    status_entryForm: "",
    name_qualified: "",
    comments: "",
  };
  const [form, setForm] = useState(initialForm);
  const { pathname } = location;
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({});
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })

  const [valuesItems, handleInputChangeItems, resetItems, setValuesItems] =
    useForm({
      product_unit: "",
      factor_conv: "",
      product_description: "",
      item_temporary_exit: "",
      gross_weight_sdo: "",
      amount_sdo: "",
      fob_sdo_value: "",
      cif_value_sdo: "",
      description_subheading: "",
      coin: "",
      number_packages: "",
      exchange_rate: "",
      entry_form_item_description: "",
      code_subheading: "",
      code_businessUnit: "",
      product_quantity: 0,
      gross_weight: 0,
      net_weight: 0,
      quantity: 0,
      us_freight: 0,
      us_insurance: 0,
      other_us_expenses: 0,
      us_fob_value: 0,
      cif_us_value: 0,
      cif_cop_value: 0,
      product_quantity_corrected: 0,
      gross_weight_corrected: 0,
      net_weight_corrected: 0,
      quantity_corrected: 0,
      us_freight_corrected: 0,
      us_insurance_corrected: 0,
      other_us_expenses_corrected: 0,
      us_fob_value_corrected: 0,
      cif_us_value_corrected: 0,
      cif_cop_value_corrected: 0,
      code_product: "",
      code_country_proc: "",
      name_country_proc: "",
      code_country: "",
      name_country: "",
      code_country_purs: "",
      name_country_purs: "",
      code_transportMethod: "",
      name_transportMethod: "",
      code_contry_flag: "",
      name_country_flag: "",
      name_packaging: "",
      code_packaging: "",
      code_tradeAgreement: "",
      name_tradeAgreement: "",
      factor_conv_corrected: "",
      code_businessUnit_correct: "",
      fob_sdo_value_corrected: "",
      gross_weight_sdo_corrected: "",
      amount_sdo_corrected: "",
      item_temporary_exit_corrected: "",
      code_subheading_corrected: "",
      description_subheading_corrected: "",
      number_packages_corrected: "",
      exchange_rate_corrected: "",
      product_description_corrected: "",
      entry_form_item_description_corrected: "",
    });

  const [
    valuesCorrect,
    handleInputChangeCorrect,
    resetCorrect,
    setValuesCorrect,
  ] = useForm({});

  const {
    DocumentOperation,
    setDocumentOperation,
    deleteAttachment,
    createCommentary,
    update,
    newCommentary,
    getThirdParties,
    dataThird,
    dataOperation,
    getOperationNotPaginate,
    setRequestDelete,
    dataPackagings,
    dataTransportMethod,
    dataAgreements,
    getAcuerdosC,
    getCountry,
    getPackaging,
    getMethodTransport,
    getCodeProduct,
    setDataPackagingsNew,
    setDataTransportMethodNew,
    setDataAgreementsNew,
    setValueProducts,
    setInfo,
    setDataCountryOrigin,
    setDataCountryBuy,
    setValueFlag,
    setPackagings,
    setValueMethod,
    setAcuerdos,
    dataToEditItems,
    dataToCopyItems,
    dataDepartament,
    setDataDepartamentNew,
    getDepartament,
    getUserQualified,
    userQualified,
    StateCountry,
    setUserQuialifiedNew,
    StateNit,
    selectExport,
    setselectExport,
    setStateCurrentExport,
    selectOperation,
    setselectOperation,
    selectEmbarcacion,
    setselectEmbarcacion,
    setDataSelectOperation,
    setDataSelectExport,
    updateCorrectDocument,
    valueProducts,
    setValueSubheading,
    allProducts,
    setDataToEditItems,
    typeProduct,
    setSelectDataTypeProduct,
    selectDataTypeProduct,
    info,
    dataCountryOrigin,
    dataCountryBuy,
    valueMethod,
    valueFlag,
    packagings,
    acuerdos,
    valueSubheading,
    CorrectItem,
    isOpenDelete,
    onCloseModal,
    deleteItemsCorrect,
    setDataToCopyItems,
    dataToDelete,
    setStateCountry,
    setStateNit,
    setPortMCorrect,
    setInspeccionCorrect,
    portMCorrect,
    inspeccionCorrect,
    setTypeEntry,
    setTypeEntryCurrent,
    typeEntry,
    selectNE,
    setSelectNE,
    setSelectCurrency,
    isOpenRadicar,
    onOpenRadicar,
    onCloseRadicar,
    dataRadicar,
    changeStatusCorregido,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    setRequestChangeStatusForm,
  } = useContext(FormCrudCRUDContext);

  const workDirty = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const fromServer = data.files?.reduce((accumulator, item, index) => {
        const key = createKey(item.documentReq_id, item?.doc_corrected_req);
        accumulator[key] = createObjectFromItem(item, index);
        return accumulator;
      }, {});

      setFiles(Object.assign({}, files, fromServer));
      setDataCorrected((prev) => ({
        ...prev,
        files: Object.assign({}, files, fromServer),
      }));
      setDeleteFile(false);
      return;
    }
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMI_CORRECT);

    handleCancelCabecera();
    const {
      code_operation,
      qualifiedUser_id,
      dcl_customs_transit_corrected,
      transit_expiration_date_corrected,
      num_transport_document_corrected,
      transp_doc_date_corrected,
      cargo_manifest_entryFormCorrected,
      num_commercial_invoice_corrected,
      num_dex_entryFormCorrected,
      dex_date_entryFormCorrected,
      num_import_declaration_corrected,
      customs_administration_corrected,
      exit_auth_entryFormCorrected,
      integration_box_certificate_corrected,
      num_exit_form_corrected,
      shipment_entryFormCorrected,
      code_operationCorrected,
      name_departmentCorrected,
      departmentCorrected_id,
      nit_qualified4,
      name_qualified4,
      qualifiedUser4_id,
      documents,
      purchase_order_corrected,
      portmagdalena_corrected,
      inspection_corrected,
      check_rm_corrected,
      check_rm,
      shipping_form_corrected,
      cabotage_corrected,
      shipping_form_expiration_date_corrected,
      cabotage_expiration_date_corrected,
      national_or_foreign_corrected,
      code_departmentCorrected,
    } = data;

    setDocumentOperation(documents);

    const fromServer = data.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));
    setDataCorrected((prev) => ({
      ...prev,
      files: Object.assign({}, files, fromServer),
    }));
    setOtherDocuments(data?.other_files);

    if (check_rm_corrected) {
      setTypeEntry(check_rm_corrected);
    } else {
      setTypeEntry("DTA");
    }
    if (check_rm) {
      setTypeEntryCurrent(check_rm_corrected);
    } else {
      setTypeEntryCurrent("DTA");
    }

    setForm(data);
    setValues(data);
    setCode(parseInt(code_operation));
    getThirdParties(qualifiedUser_id);
    getDepartament();
    getUserQualified();

    setselectEmbarcacion({
      label: shipment_entryFormCorrected,
      value: shipment_entryFormCorrected,
    });

    if (qualifiedUser_id) {
      getCodeProduct(qualifiedUser_id);
    }

    if (qualifiedUser_id && authUser.is_qualified_user !== 1) {
      getOperationNotPaginate(qualifiedUser_id);
    } else if (authUser.is_qualified_user === 1) {
      getOperationNotPaginate();
    }

    if (portmagdalena_corrected === 0) {
      setPortMCorrect(false);
    } else {
      setPortMCorrect(true);
    }

    if (inspection_corrected === 0) {
      setInspeccionCorrect(false);
    } else {
      setInspeccionCorrect(true);
    }

    if (code_operationCorrected >= 101 && code_operationCorrected < 120) {
      setValuesCorrect({
        ...valuesCorrect,
        inspection_corrected: inspection_corrected || "",
        purchase_order_corrected: purchase_order_corrected || "",
        portmagdalena_corrected: portmagdalena_corrected || "",
        shipping_form_corrected,
        cabotage_corrected,
        shipping_form_expiration_date_corrected,
        cabotage_expiration_date_corrected,
        shipment_entryFormCorrected: shipment_entryFormCorrected || "",
        dcl_customs_transit_corrected: dcl_customs_transit_corrected || "",
        transit_expiration_date_corrected:
          transit_expiration_date_corrected || "",
        num_transport_document_corrected:
          num_transport_document_corrected || "",
        transp_doc_date_corrected: transp_doc_date_corrected || "",
        cargo_manifest_entryFormCorrected:
          cargo_manifest_entryFormCorrected || "",
        num_commercial_invoice_corrected:
          num_commercial_invoice_corrected || "",
        num_exit_form_corrected:
          num_exit_form_corrected || "",
        integration_box_certificate_corrected:
          integration_box_certificate_corrected || "",
      });
    } else if (
      code_operationCorrected >= 301 &&
      code_operationCorrected < 330
    ) {
      setStateCountry({
        value: departmentCorrected_id,
        label: code_departmentCorrected + " " + name_departmentCorrected,
        code: code_departmentCorrected,
      });
      setValuesCorrect({
        ...valuesCorrect,
        purchase_order_corrected,
        inspection_corrected,
        portmagdalena_corrected,
        shipment_entryFormCorrected,
        name_departmentCorrected,
        num_dex_entryFormCorrected,
        dex_date_entryFormCorrected,
        num_import_declaration_corrected,
        integration_box_certificate_corrected,
        num_commercial_invoice_corrected,
        num_exit_form_corrected,
        customs_administration_corrected,
      });
    } else if (
      code_operationCorrected >= 501 &&
      code_operationCorrected < 518
    ) {
      setSelectNE(national_or_foreign_corrected);

      setValuesCorrect({
        ...valuesCorrect,
        purchase_order_corrected: purchase_order_corrected || "",
        inspection_corrected: inspection_corrected || "",
        portmagdalena_corrected: portmagdalena_corrected || "",

        shipment_entryFormCorrected: shipment_entryFormCorrected || "",
        num_dex_entryFormCorrected: num_dex_entryFormCorrected || "",
        num_commercial_invoice_corrected:
          num_commercial_invoice_corrected || "",
        exit_auth_entryFormCorrected: exit_auth_entryFormCorrected || "",
        integration_box_certificate_corrected: integration_box_certificate_corrected || "",
        dcl_customs_transit_corrected: dcl_customs_transit_corrected || "",
        transit_expiration_date_corrected:
          transit_expiration_date_corrected || "",
      });
    } else if (
      code_operationCorrected >= 701 &&
      code_operationCorrected < 714
    ) {
      setStateNit({
        label: `${nit_qualified4} ${name_qualified4}`,
        value: qualifiedUser4_id,
      });
      setValuesCorrect({
        ...valuesCorrect,
        qualifiedUser4_id,
        purchase_order_corrected,
        inspection_corrected,
        portmagdalena_corrected,

        shipment_entryFormCorrected,
        num_exit_form_corrected,
      });
    }
  };

  const {
    data: dataViewFormCorrect,
    isFetching: isFetchingViewFormCorrect,
    isSuccess: isSuccessViewFormCorrect,
    isError: isErrorViewFormCorrect,
    status: statusViewFormCorrect,
  } = useQuery({
    queryKey: ["viewReviewFormCorrect", FormEntry, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.CORRECT_FMMI_SHOW,
        resourceIdentifier: ShowEntry || FormEntry,
      }),
    refetchOnWindowFocus: false,
  });

  const getItems = async (idEntry) => {
    const formData = new FormData();
    formData.append("entryFormCorrected_id", idEntry);
    formData.append("orderBy", orderBy.value);
    setPage(1);
    setLoadingItems(true);
    setRequestDelete(environment.DELECT_CORRECT_ITEM);
    try {
      const res = await __GetItems(
        token,
        formData,
        page,
        environment.GET_CORRECT_ITEM
      );
      setCurrentPage(res?.data?.status?.data?.data);
      setMaxPage(res?.data?.status?.data?.last_page);
    } catch (error) {
    } finally {
      setLoadingItems(false);
    }
  };

  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async () => {
    try {
      const res = await __CommentaryForm(
        token,
        FormEntry ? Number(FormEntry) : ShowEntry ? Number(ShowEntry) : "",
        environment.CORRECT_GET_COMMENT_FMM_ENTRY
      );
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) { }
  };

  const showItems = async (id) => {
    setLoadingItems(true);
    try {
      const res = await __ShowItems(token, id, environment.SHOW_CORRECT_ITEM);
      const response = res?.data?.status;
      const { code, data } = response;

      if (code === 200) {
        const {
          code_product_corrected,
          productCorrected_id,
          code_country_ori_corrected,
          name_country_ori_corrected,
          countryOriginCorrected_id,
          code_country_proc_corrected,
          name_country_proc_corrected,
          countryProcedencyCorrected_id,
          code_country_purs_corrected,
          name_country_purs_corrected,
          countryPurchaseCorrected_id,
          code_contry_flag_corrected,
          name_country_flag_corrected,
          flagCorrected_id,
          code_packaging_corrected,
          name_packaging_corrected,
          packagingCorrected_id,
          code_transportMethod_corrected,
          name_transportMethod_corrected,
          transportMethodCorrected_id,
          code_tradeAgreement_corrected,
          name_tradeAgreement_corrected,
          tradeAgreementCorrected_id,
          name_typeProduct_corrected,
          typeProductCorrected_id,
          subheadingCorrected_id,
          coin_corrected,
        } = data;
        // quedamos en el show de items y eliminar
        setstateCollapse(false);
        setValuesItems(data);
        setSuma(parseFloat(data?.cif_us_value_corrected));
        setMultiplicacion(parseFloat(data?.cif_cop_value_corrected));

        typeProductCorrected_id &&
          setSelectDataTypeProduct({
            label: `${name_typeProduct_corrected || ""}`,
            value: typeProductCorrected_id,
          });

        productCorrected_id &&
          setValueProducts({
            label: `${code_product_corrected || ""}`,
            value: productCorrected_id,
          });

        countryOriginCorrected_id &&
          setInfo({
            label: `${code_country_ori_corrected
                ? code_country_ori_corrected +
                (name_country_ori_corrected
                  ? " " + name_country_ori_corrected
                  : "")
                : ""
              }`,
            value: countryOriginCorrected_id,
          });

        countryProcedencyCorrected_id &&
          setDataCountryOrigin({
            label: `${code_country_proc_corrected
                ? code_country_proc_corrected +
                (name_country_proc_corrected
                  ? " " + name_country_proc_corrected
                  : "")
                : ""
              }`,
            value: countryProcedencyCorrected_id,
          });

        countryPurchaseCorrected_id &&
          setDataCountryBuy({
            label: `${code_country_purs_corrected
                ? code_country_purs_corrected +
                (name_country_purs_corrected
                  ? " " + name_country_purs_corrected
                  : "")
                : ""
              }`,
            value: countryPurchaseCorrected_id,
          });

        flagCorrected_id &&
          setValueFlag({
            label: `${code_contry_flag_corrected
                ? code_contry_flag_corrected +
                (name_country_flag_corrected
                  ? " " + name_country_flag_corrected
                  : "")
                : ""
              }`,
            value: flagCorrected_id,
          });

        packagingCorrected_id &&
          setPackagings({
            label: `${code_packaging_corrected
                ? code_packaging_corrected +
                (name_packaging_corrected
                  ? " " + name_packaging_corrected
                  : "")
                : ""
              }`,
            value: packagingCorrected_id,
          });

        transportMethodCorrected_id &&
          setValueMethod({
            label: `${code_transportMethod_corrected
                ? code_transportMethod_corrected +
                (name_transportMethod_corrected
                  ? " " + name_transportMethod_corrected
                  : "")
                : ""
              }`,
            value: transportMethodCorrected_id,
          });

        tradeAgreementCorrected_id &&
          setAcuerdos({
            label: `${code_tradeAgreement_corrected
                ? code_tradeAgreement_corrected +
                (name_tradeAgreement_corrected
                  ? " " + name_tradeAgreement_corrected
                  : "")
                : ""
              }`,
            value: tradeAgreementCorrected_id,
          });
        setValueSubheading(subheadingCorrected_id);
        setSelectCurrency(coin_corrected);
        setLoadingItems(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingItems(false);
    }
  };

  const handleSubmit = () => {
    const infoCurrent = {};
    const newCorrect = {};
    delete values.code_operationCorrected;
    delete values.operationCorrected_id;
    delete values.thirdPartieCorrected_id;
    delete values.files;
    delete values.users;
    delete infoCurrent.shipment_entryFormCorrected;
    delete values.documents;
    delete values.portmagdalena_corrected;
    delete values.inspection_corrected;
    delete values.purchase_order_corrected;
    delete values.check_rm_corrected;
    delete values.national_or_foreign_corrected;
    delete valuesCorrect.check_rm_corrected;

    Object.entries(values).map(([key, valor]) => {
      if (!valor) {
        return;
      }
      return (infoCurrent[key] = valor);
    });

    // dependiendo de la operacion todos los campos de esa operacion son requeridos
    // si funciona no lo toque, cuando hice este codigo estaba Dios conmigo solo el y yo sbaemos que hace
    Object.entries(valuesCorrect).map(([key, valor]) => {
      if (selectOperation.value >= 101 && selectOperation.value < 120) {
        restWorld.map((e) => {
          if (key === e) {
            return (newCorrect[key] = valor);
          }
        });
        if (typeEntry === "DTA") {
          newCorrect.dcl_customs_transit_corrected =
            valuesCorrect.dcl_customs_transit_corrected;
          newCorrect.transit_expiration_date_corrected =
            valuesCorrect.transit_expiration_date_corrected;
          newCorrect.shipping_form_corrected = "";
          newCorrect.cabotage_corrected = "";
          newCorrect.shipping_form_expiration_date_corrected = "";
          newCorrect.cabotage_expiration_date_corrected = "";
        } else if (typeEntry === "Planilla de envío") {
          newCorrect.shipping_form_corrected =
            valuesCorrect.shipping_form_corrected;
          newCorrect.shipping_form_expiration_date_corrected =
            valuesCorrect.shipping_form_expiration_date_corrected;
          newCorrect.cabotage_corrected = "";
          newCorrect.dcl_customs_transit_corrected = "";
          newCorrect.transit_expiration_date_corrected = "";
          newCorrect.cabotage_expiration_date_corrected = "";
        } else {
          newCorrect.cabotage_corrected = valuesCorrect.cabotage_corrected;
          newCorrect.cabotage_expiration_date_corrected =
            valuesCorrect.cabotage_expiration_date_corrected;
          newCorrect.shipping_form_corrected = "";
          newCorrect.shipping_form_expiration_date_corrected = "";
          newCorrect.dcl_customs_transit_corrected = "";
          newCorrect.transit_expiration_date_corrected = "";
        }
      } else if (selectOperation.value >= 301 && selectOperation.value <= 330) {
        newCorrect.departmentCorrected_id = StateCountry.value;
        varRestTerritory.map((e) => {
          if (key === e) {
            return (newCorrect[key] = valor);
          }
        });
      } else if (
        selectOperation?.value >= 501 &&
        selectOperation?.value <= 518
      ) {
        delete values.national_or_foreign_corrected;
        newCorrect.national_or_foreign_corrected = selectNE;
        varFrezone.map((e) => {
          if (key === e) {
            return (newCorrect[key] = valor);
          }
        });
      } else if (
        selectOperation?.value >= 701 &&
        selectOperation?.value <= 714
      ) {
        newCorrect.qualifiedUser2Corrected_id = StateNit?.value;

        varUserToUser.map((e) => {
          if (key === e) {
            return (newCorrect[key] = valor);
          }
        });
      }
    });

    const newObjetData = {
      id: ShowEntry,
      operationCorrected_id: selectOperation ? selectOperation.id : "",
      thirdPartieCorrected_id: selectExport ? selectExport.value : "",
      shipment_entryFormCorrected: selectEmbarcacion.value,
      portmagdalena_corrected: portMCorrect === true ? 1 : 0,
      inspection_corrected: inspeccionCorrect === true ? 1 : 0,
      purchase_order_corrected: valuesCorrect.purchase_order_corrected,
      check_rm_corrected: typeEntry,
      ...infoCurrent,
      ...newCorrect,
    };

    const formData = new FormData();

    Object.entries(newObjetData).map(([key, valor]) => {
      formData.append(`${key}`, valor);
    });

    updateCorrectDocument(
      environment.CORRECT_FMMI_UPDATE,
      formData,
      handleCancelCabecera,
      "/FormCorrection?ShowEntry"
    );
  };

  const { requestData, nameDocument } = useGlobalContext();
  const [deleteFile, setDeleteFile] = useState(false);
  const firstRender = useRef(true);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setValuesItems((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const {
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_correct,
    code_subheading_corrected,
  } = valuesItems;

  const getTrm = async () => {
    try {
      const res = await __Trm(token);
      setValuesItems({
        ...valuesItems,
        exchange_rate_corrected: res?.data?.status?.data.value_trm,
      });
      setValorDolarActual(res?.data?.status?.data.value_trm);
    } catch (error) { }
  };

  const handleClean = () => {
    setInfo([]);
    setValueProducts(null);
    setValueSubheading([]);
    setDataCountryOrigin([]);
    setDataCountryOrigin([]);
    setValueMethod([]);
    setDataCountryBuy([]);
    setValueFlag([]);
    setPackagings([]);
    setAcuerdos([]);
    resetItems();
    selectDataTypeProduct(null);
    // setUnidad([]);
  };

  const handleCancel = () => {
    setstateCollapse(!stateCollapse);
    setDataToEditItems(null);
    setDataToCopyItems(null);
    handleClean();
  };

  const handleCancelCabecera = () => {
    setAttachment([]);
    setAttachmentDescription([]);
    setAttachmentType([]);
    setFilesFront({});
    setNameFiles([]);
  };

  const hadleSaveItems = () => {
    delete valuesItems?.status_item;
    const dataSave = {
      ...valuesItems,
      entryFormCorrected_id: FormEntry || ShowEntry || "",
      productCorrected_id: valueProducts ? valueProducts.value : "",
      typeProductCorrected_id: selectDataTypeProduct
        ? selectDataTypeProduct.value
        : "",
      countryProcedencyCorrected_id: dataCountryOrigin
        ? dataCountryOrigin.value
        : "",
      countryOriginCorrected_id: info ? info.value : "",
      countryPurchaseCorrected_id: dataCountryBuy ? dataCountryBuy.value : "",
      transportMethodCorrected_id: valueMethod ? valueMethod.value : "",
      flagCorrected_id: valueFlag ? valueFlag.value : "",
      packagingCorrected_id: packagings ? packagings.value : ":",
      subheadingCorrected_id: valueSubheading,
      countryDestinyCorrected_id: 1,
      tradeAgreementCorrected_id: acuerdos ? acuerdos.value : "",
      status_item: 1,
      quantity_corrected: parseFloat(valuesItems?.quantity_corrected),

      net_weight_corrected: parseFloat(valuesItems?.net_weight_corrected),
      gross_weight_corrected: parseFloat(valuesItems?.gross_weight_corrected),
    };

    let message;
    if (dataToEditItems) {
      message = "Item actualizado con exito";
      return CorrectItem(
        environment.UPDATE_CORRECT_ITEM,
        dataSave,
        handleCancel,
        message
      );
    }
    if (dataToCopyItems) {
      message = "Item copiado con exito";
      return CorrectItem(
        environment.CREATE_CORRECT_ITEM,
        dataSave,
        handleCancel,
        message
      );
    }
    message = "Item creado con exito";
    CorrectItem(
      environment.CREATE_CORRECT_ITEM,
      dataSave,
      handleCancel,
      message
    );
  };

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();

  const handleFilesInput = async (e) => {
    try {
      e.preventDefault();
      const resModal = await onOpenUpload();
      const { name, files: uploadedFiles, id } = e.target;
      const idFile = e?.target?.id;
      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);

      if (!isValidExtension) return;

      const idDocument = extractDocumentId(adjustDocumentName(idFile));
      const uploadResponse = await uploadFile(uploadedFiles[0]);

      updateFileState(uploadResponse, uploadedFiles[0]?.name, idDocument, e);
      updateNameFilesState(name);
      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${nombre}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (filesFront[archivo] !== undefined && filesFront[archivo] !== null) {
          const obj = Object.assign({}, { ...filesFront });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo];
            if (filesFront[archivo].from_server) {
              deleteAttachment(
                environment.DELETE_ATTACHMENT_ENTRY_CORRECT,
                filesFront[archivo].id,
                "",
                setFilesFront,
                obj
              );
            } else {
              setFilesFront(obj);
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (dataAgreements) {
      const infoPackagin = dataAgreements.map((item) => ({
        label: item?.code_tradeAgreement + " " + item?.name_tradeAgreement,
        value: item?.id,
      }));

      setDataAgreementsNew(infoPackagin);
    }
  }, [dataAgreements]);

  useEffect(() => {
    if (dataPackagings) {
      const infoPackagin = dataPackagings.map((item) => ({
        label: item?.code_packaging + " " + item?.name_packaging,
        value: item?.id,
      }));
      setDataPackagingsNew(infoPackagin);
    }
  }, [dataPackagings]);

  useEffect(() => {
    if (dataTransportMethod) {
      const infoPackagin = dataTransportMethod.map((item) => ({
        label: item?.code_transportMethod + " " + item?.name_transportMethod,
        value: item?.id,
      }));

      setDataTransportMethodNew(infoPackagin);
    }
  }, [dataTransportMethod]);

  useEffect(() => {
    if (userQualified) {
      const infoPackagin = userQualified.map((item) => ({
        label: item?.nit_qualified + " - " + item?.name_qualified,
        value: item?.id,
      }));

      setUserQuialifiedNew(infoPackagin);
    }
  }, [userQualified]);

  useEffect(() => {
    if (dataDepartament) {
      const infoPackagin = dataDepartament.map((item) => ({
        label: item?.name_department,
        value: item?.id,
        code: item?.code_department,
      }));

      setDataDepartamentNew(infoPackagin);
    }
  }, [dataDepartament]);

  useEffect(() => {
    if (!stateCollapse) {
      getAcuerdosC();
      getCountry();
      getPackaging();
      getMethodTransport();
      typeProduct();
    }
  }, [stateCollapse]);

  // estado actual del exportado
  useEffect(() => {
    if (dataThird && form) {
      const resultadoSearch = dataThird.find(
        (e) => e.business_name === form?.business_name
      );

      const resultadoCorrect = dataThird.find(
        (e) => e.business_name === form?.business_nameCorrected
      );

      setStateCurrentExport(
        `${resultadoSearch?.identification_num !== null
          ? resultadoSearch?.identification_num + " "
          : ""
        }${resultadoSearch?.business_name}`
      );

      if (resultadoCorrect) {
        setselectExport({
          label: `${resultadoCorrect && resultadoCorrect.identification_num !== null
              ? resultadoCorrect?.identification_num + " "
              : ""
            }${resultadoCorrect?.business_name}`,
          value: resultadoCorrect?.id,
        });
        setValuesCorrect({
          ...valuesCorrect,
          nit_business_nameCorrected: resultadoCorrect.identification_num,
          business_nameCorrected: resultadoCorrect?.business_name,
        });
      } else {
        setselectExport({
          label: `${resultadoSearch && resultadoSearch.identification_num !== null
              ? resultadoSearch?.identification_num
              : " "
            } ${resultadoSearch?.business_name}`,
          value: resultadoSearch?.id,
        });
      }
    }
  }, [dataThird, form]);
  // estado actual del exportado fin

  // estado actual del operacion
  useEffect(() => {
    if (dataOperation && form) {
      const resultadoSearch = dataOperation.find(
        (e) => e.code_operation === form?.code_operation
      );

      const resultadoCorrect = dataOperation.find(
        (e) => e.id === form?.operationCorrected_id
      );

      if (resultadoCorrect) {
        setselectOperation({
          label:
            resultadoCorrect?.code_operation +
            " " +
            resultadoCorrect?.short_name_operation,
          value: resultadoCorrect?.code_operation,
          id: resultadoCorrect?.id,
          documents: resultadoCorrect?.documents,
          plazo: resultadoCorrect?.term,
        });
        setValuesCorrect({
          ...valuesCorrect,
          code_operationCorrected: resultadoCorrect.code_operation,
          short_name_operationCorrected: resultadoCorrect?.short_name_operation,
        });
        setselectOperation({
          label:
            resultadoCorrect?.code_operation +
            " " +
            resultadoCorrect?.short_name_operation,
          value: resultadoCorrect?.code_operation,
          id: resultadoCorrect?.id,
          documents: resultadoCorrect?.documents,
          plazo: resultadoCorrect?.term,
        });
      } else {
        setselectOperation({
          label:
            resultadoSearch?.code_operation +
            " " +
            resultadoSearch?.short_name_operation,
          value: resultadoSearch?.code_operation,
          id: resultadoSearch?.id,
          documents: resultadoSearch?.documents,
          plazo: resultadoSearch?.term,
        });
      }
    }
  }, [dataOperation, form]);
  // estado actual del operacion fin

  useEffect(() => {
    if (FormEntry) {
      // viewForms();
      // ShowDataCorrect(FormEntry);
      getItems(FormEntry);
      viewCommentary(FormEntry);
    } else if (ShowEntry) {
      // ShowDataCorrect(ShowEntry);
      getItems(ShowEntry);
      viewCommentary(ShowEntry);
    } else {
      setForm(initialForm);
    }
  }, [FormEntry, ShowEntry, update, orderBy]);

  useEffect(() => {
    if (FormEntry) viewCommentary();
  }, [newCommentary]);

  /* Esto "hacía" las conversiones. (En la vista de revisión por el momento no se necesitan, es un copiar pegar que dejaron los devs anteriores) */
  /* useEffect(() => {
    let resultadoProduc = parseFloat(product_quantity_corrected);
    let resultadoMultiplicacion =
      parseFloat(factor_conv_corrected) * resultadoProduc;
    let newDato = parseFloat(resultadoMultiplicacion).toFixed(2);

    //?.replaceAll(".", ",")
    if (code_businessUnit_correct === "KG") {
      setValuesItems({
        ...valuesItems,
        quantity_corrected: newDato,
        net_weight_corrected: newDato,
        gross_weight_corrected: newDato,
      });
    } else if (code_businessUnit_correct !== "KG") {
      setValuesItems({
        ...valuesItems,
        quantity_corrected: newDato,
      });
    }
  }, [
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_correct,
  ]); */

  useEffect(() => {
    if (code_subheading_corrected) {
      const fourNumberSubheading = code_subheading_corrected.substr(-20, 4);
      if (
        parseInt(fourNumberSubheading) === 2709 ||
        parseInt(fourNumberSubheading) === 2710
      ) {
        if (selectOperation?.value >= 101 && selectOperation?.value < 120) {
          onOpenWarning();
        }
      }
    }
  }, [code_subheading_corrected]);

  useEffect(() => {
    if (dataOperation) {
      const infoExport = dataOperation.map((item) => ({
        label: item?.code_operation + " " + item?.short_name_operation,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));

      setDataSelectOperation(infoExport);
    }
  }, [dataOperation]);

  useEffect(() => {
    if (dataThird) {
      const infoExport = dataThird.map((item) => ({
        label: `${item.identification_num !== null ? item?.identification_num : ""
          } ${item?.business_name}`,
        value: item?.id,
      }));

      setDataSelectExport(infoExport);
    }
  }, [dataThird]);

  useEffect(() => {
    if (dataToEditItems) {
      showItems(dataToEditItems.id);
      getTrm();
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id);
      getTrm();
    }
  }, [dataToEditItems, dataToCopyItems]);

  /*   useEffect(() => {
      if (valueProducts) {
        let respuesta = allProducts.find(
          (element) => element.id === parseInt(valueProducts.value)
        );
        setValueSubheading(respuesta?.subheading_id);
        setValuesItems((prev) => ({
          ...prev,
          factor_conv_corrected: respuesta?.convertion_factor_product,
          product_unit_Correct: respuesta?.code_unit_measurement,
          product_description_corrected: respuesta?.description_product,
          description_subheading_corrected: respuesta?.description,
          code_subheading_corrected: respuesta?.code_subheading,
          code_businessUnit_correct: respuesta?.businnesUnitSubheading,
          code_businessUnit: respuesta?.businnesUnitSubheading,
        }));
      }
    }, [valueProducts?.value]); */

  /*   useEffect(() => {
      const valueDolar = parseFloat(valorDolarActual);
      setSuma(
        Number(us_freight_corrected) +
        Number(us_insurance_corrected) +
        Number(other_us_expenses_corrected) +
        Number(us_fob_value_corrected)
      );
      setMultiplicacion(suma * Number(valueDolar));
    }, [
      values,
      suma,
      multiplicacion,
      valorDolarActual,
      other_us_expenses_corrected,
      us_freight_corrected,
      us_insurance_corrected,
      us_fob_value_corrected,
    ]); */

  const handleChange = (id) => {
    const infoEntryCorrect = {
      id,
      status_entryFormCorrected: "AUTORIZADO",
    };
    changeStatusCorregido(infoEntryCorrect, () => {
      navigate(`/FormCorrection?ShowEntry=${id}`);
    });
  };

  useEffect(() => {
    return async () => dispacthRedux(clearFormData());
  }, []);

  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const submitReviewBasedOnStatus = async () => {
    if (is_qualified_user) return;

    if (form?.status_entryFormCorrected === "PRESENTADO") {
      const body = new FormData();
      body.append("form_id", ShowEntry);
      body.append("type_of", "FMMIC");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [form]);

  const propsBlockForm = {
    form_id: ShowEntry,
    type_of: "FMMIC",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };

  const unlockForm = async () => {
    const data = new FormData();
    data.append("form_id", ShowEntry);
    data.append("type_of", "FMMIC");
    data.append("type", 0);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, data);
  };

  const navigateIfEligibleForUpdate = (flag, form_id, new_status, formData) => {
    const shouldRefetch =
      flag === "entry_form_corrected" &&
      new_status !== formData?.status_entryFormCorrected &&
      form_id === formData?.id;

    if (
      shouldRefetch &&
      new_status !== "REVISADO" &&
      new_status !== "PRESENTADO"
    ) {
      navigate(
        `/FormCorrection?ShowEntry=${form_id || ShowEntry || FormEntry}`
      );
      return;
    }
  };

  useStatusListener({
    id: ShowEntry || FormEntry,
    callBack: navigateIfEligibleForUpdate,
    dataCurrent: form,
  });

  function adjustDocumentName(name) {
    const suffix = "_doc_corrected_req";
    if (name.includes(suffix)) {
      return name.replace(suffix, "");
    }
    return name;
  }

  const { get: getStorage } = Storage;

  const updateFileState = async (uploadResponse, name, idDocument, e) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};
    const { iscorrected } = await e.target.dataset;

    const file = {
      [e.target.id]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file, false, iscorrected);

    return localStorage.removeItem("uploadData");
  };

  const updateNameFilesState = (name) => {
    setNameFiles((prevNameFiles) => [...prevNameFiles, { name }]);
  };

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormEntry}`) || "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${FormEntry}`,
        JSON.stringify(filteredErrors)
      );
    }
  };

  function removeElementFromArray(array, posicion) {
    return [...array.slice(0, posicion), ...array.slice(posicion + 1)];
  }

  const responseDeleteFile = (res, index = null) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      if (isString(index)) {
        const { [index]: _, ...rest } = files;
        setFiles(rest);
        setDataCorrected((prev) => ({ ...prev, files: rest }));
      }

      if (isNumber(index)) {
        const newArray = removeElementFromArray(otherDocuments, index);
        setOtherDocuments(newArray);
      }

      return openAlert("El documento ha sido eliminado ", "error");
    }
  };

  const [otherDocuments, setOtherDocuments] = useState([]);
  const [files, setFiles] = useState({});

  const editUploadFiles = (data) => {
    const formData = new FormData();
    formData.append("id", FormEntry || ShowEntry);
    if (data?.id && !data?.doc_corrected_req)
      formData.append("attachment_id[]", data.id);

    data?.doc_corrected_req &&
      data?.id &&
      formData.append("document_corrected_id[]", data.id);

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append("attachment_comment[]", data.attachment_comment);
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append("attachment_number[]", data.attachment_number);
    }

    const date = data?.other_attachment_date || data?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    data?.attachment_date &&
      formData.append("attachment_date[]", data?.attachment_date);

    updateAndCreate(formData, true);
  };

  const updateAndCreate = async (
    data = undefined,
    payloadManual = false,
    isCorrected = null
  ) => {
    const formData = new FormData();
    formData.append("id", FormEntry || ShowEntry);
    const dataAttachments = attachment[0];
    const vaporRes = (await data) || null;

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };
    (dataAttachments?.id || dataAttachments?.other_attachment_description) &&
      formData.append(
        "other_attachment[]",
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      );
    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    const date =
      dataAttachments?.other_attachment_date ||
      dataAttachments?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    dataAttachments?.attachment_date &&
      formData.append("attachment_date[]", dataAttachments?.attachment_date);

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      Object.keys(data).forEach((documentId) => {
        if (!data[documentId].from_server) {
          formData.append(
            isNumberFormatable(isCorrected) && Number(isCorrected) === 1
              ? "document_corrected[" + adjustDocumentName(documentId) + "]"
              : "attachment[" + documentId + "]",
            JSON.stringify(data[documentId])
          );
        }
      });
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.CORRECT_FMMI_UPDATE,
      PropertyBody: "sendFormDataContentPOST",
    };

    setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["viewReviewFormCorrect"] });
      return;
    }
  };

  const removeFileByIdentifier = (
    id,
    fileNameOrID,
    dataDocument = {},
    typeComponent
  ) => {
    const IsTypeNewFile =
      dataDocument?.doc_corrected_req === 1 ? "/doc_corrected_req" : "";
    const Endpoint = environment.DELETE_ATTACHMENT_ENTRY_CORRECT;
    const requestData = {
      resourceIdentifier: `${dataDocument?.id}${IsTypeNewFile}`,
      Endpoint,
    };

    mutate(requestData, {
      onSuccess: (res) => responseDeleteFile(res, id),
    });

    //DELETE_OTHER_ATTACHMENT_ENTRY_CORRECT
    /*   Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${files?.[archivo]?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo];
            if (files[archivo].from_server) {
              deleteAttachment(
                environment.DELETE_ATTACHMENT_ENTRY_CORRECT,
                files[archivo].id,
                "",
                setFiles,
                obj,
                () => {
                  setDeleteFile(true);
                  refetchViewFormCorrect();
                }
              );
            } else {
              setFiles(obj);
            }
          }
        }
      }
    }); */
  };

  useEffect(() => {
    const dataFormCurrent = dataViewFormCorrect?.data?.status;

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect && deleteFile) {
      workDirty(dataFormCurrent?.data, true);
      return;
    }

    if (isErrorViewFormCorrect) {
      openAlert(dataFormCurrent?.data?.status.message, "error");
      navigate(-1);
      return;
    }

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect) {
      const { data } = dataFormCurrent;

      dispacthRedux(
        setFormData({
          ...data,
          precision: Number(data?.precision),
        })
      );

      if (data.status_entryFormCorrected === "PRESENTADO") {
        workDirty(data);
      } else {
        openAlert(
          `El formulario ${data?.code_entryFormCorrected} esta en estado ${data?.status_entryFormCorrected} y no puede ser editado`,
          "error"
        );

        if (data?.id)
          navigate(`/FormCorrection?ShowEntry=${FormEntry || ShowEntry}`);
      }
    }
  }, [isFetchingViewFormCorrect, statusViewFormCorrect, dataViewFormCorrect]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={"lg"}
      >
        <GroupInputUpload onClose={onCloseUpload} />
      </ModalGeneric>

      <Commentary
        id={FormEntry ? Number(FormEntry) : ShowEntry ? Number(ShowEntry) : ""}
        ruta={environment.CORRECT_CREATE_COMMENT_FMM_ENTRY}
        create={createCommentary}
        commentarys={commentarios}
      />

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Advertencia`}
        titleBody={`Este ingreso contiene las partidas 2709 o 2710, las cuales deben ser reportadas a GIT DIAN según oficio 187245452-0075, informar a su jefe inmediato`}
      />

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar item n. ${dataToDelete?.item_corrected}`}
        onDelete={deleteItemsCorrect}
      />

      <ModalGeneric
        isOpen={isOpenRadicar}
        onOpen={onOpenRadicar}
        onClose={onCloseRadicar}
        title={`Radicar documento`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea radicar el formulario{" "}
          {dataRadicar?.code_entryFormCorrected ||
            dataRadicar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              Se realizarán cambios en el inventario
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRadicar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => {
              handleChange(dataRadicar?.id);
              unlockForm();
            }}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          FormId={form.id}
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      {loading && <SpinnerComponent />}

      {form && (
        <ExportPdfEntryCorrect
          id="IdPdfFmmCorrectEntry"
          values={form}
          items={currentPage}
        />
      )}

      {!loading && (
        <>
          <div className="container mb-5">
            <HeaderForm
              status={form?.status_entryFormCorrected}
              numberForm={form?.code_entryFormCorrected}
              UserQualified={form?.name_qualified}
              Modulo={`${"Formulario"} de corrección de ingresos de mercancías en Zonas Francas`}
            />
          </div>
          {ShowEntry && (
            <ButtonView
              data={form}
              idFmm={FormEntry || ShowEntry}
              status={form?.status_entryFormCorrected}
            >
       <CreateAt
              entries={form.users}
              config={{
                statusKey: "status_entryFormCorrected",
                dateKey: "date_entryFormCorrected",
                timeKey: "time_entryFormCorrected",
                personNameKey: "person_name",
              }}
              expirationDate={form.expirationDate}
            />
            </ButtonView>
          )}

          <div className="row">
            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  Ingreso
                  <NavLink
                    to={`/FormShow?FormEntry=${form?.entryForm_id}`}
                    className="ms-2"
                  >
                    {form?.code_entryForm}
                  </NavLink>
                </SubTitleHeader>
                <FormEntryHeader form={form} valuesCorrect={valuesCorrect} />
              </HeaderSearch>
            </div>
            {/* Inicio de corrección */}

            {form?.status_entryFormCorrected === "PRESENTADO" && ShowEntry ? (
              <div className="col-md-6">
                <HeaderSearch>
                  <SubTitleHeader>
                    {form.balance_correction === 1 ? "Ajuste" : "Corrección"}
                  </SubTitleHeader>
                  {FormEntry && (
                    <FormCorrectEntry
                      form={form}
                      valuesCorrect={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                    />
                  )}
                  {ShowEntry && (
                    <FormShowCorrect
                      form={form}
                      valuesCorrect={valuesCorrect}
                    />
                  )}
                </HeaderSearch>
              </div>
            ) : (
              <div className="col-md-6">
                <HeaderSearch>
                  <SubTitleHeader>Corrección</SubTitleHeader>
                  {FormEntry && (
                    <FormCorrectEntry
                      form={form}
                      valuesCorrect={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                    />
                  )}
                  {ShowEntry && (
                    <FormShowCorrect
                      form={form}
                      valuesCorrect={valuesCorrect}
                    />
                  )}
                </HeaderSearch>
              </div>
            )}
            {/* Final de corrección */}

            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperations code={code} />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  <CapsulaContainer
                    code={code}
                    form={form}
                    valuesCorrect={valuesCorrect}
                  />
                </div>
              </HeaderSearch>
            </div>

            {/**/}
            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperations
                    code={
                      form.balance_correction === 1
                        ? code
                        : selectOperation?.value
                    }
                  />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  {selectOperation?.value >= 101 &&
                    selectOperation?.value < 120 ? (
                    <CorrectionRestWorld
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 301 &&
                    selectOperation?.value <= 330 ? (
                    <CorrectionRestTerritory
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 501 &&
                    selectOperation?.value <= 518 ? (
                    <CorrectFreeZone
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 701 &&
                    selectOperation?.value <= 714 ? (
                    <CorrectFromUserToUser
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : form.balance_correction === 1 ? (
                    <CapsulaContainer
                      code={code}
                      form={form}
                      valuesCorrect={valuesCorrect}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </HeaderSearch>
            </div>
            {/**/}

            <div className="col-4">
              <Text className="mt-4" fontSize="xl" style={{ fontWeight: 600 }}>
                Ítems
              </Text>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <div className="d-flex float-end my-4">
                {!ShowEntry && (
                  <BtnWithToltip
                    msgToltip="Agregar ítem"
                    hableSubmit={() => setstateCollapse(!stateCollapse)}
                    icon={CgPlayListAdd}
                  />
                )}
              </div>
            </div>

            {!stateCollapse && (
              <div className="col-6">
                {loadingItems && <SpinnerComponent />}
                {!loadingItems && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <ForItemsCorrect
                      values={valuesItems}
                      handleInputChange={handleInputChangeItems}
                      handleInput={handleInput}
                      multiplicacion={multiplicacion}
                      suma={suma}
                    />
                  </div>
                )}
              </div>
            )}

            {!stateCollapse && (
              <div className="col-6">
                {loadingItems && <SpinnerComponent />}
                {!loadingItems && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FormCorrectItems
                      values={valuesItems}
                      handleInputChange={handleInputChangeItems}
                      setValues={setValuesItems}
                      handleInput={handleInput}
                      suma={suma}
                      setSuma={setSuma}
                      setMultiplicacion={setMultiplicacion}
                      multiplicacion={multiplicacion}
                      handleCancel={handleCancel}
                      hadleSaveItems={hadleSaveItems}
                      disableCurrency={true}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="col-12 ">
              <label
                style={{
                  width: 300
                }}
              >
                <label style={{ width: '100%' }}></label>

                <InputSelect
                  data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                  value={orderBy}
                  name="order_by"
                  set={setOrderBy}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: '#5f5f',
                  }}
                  isClearable={false}
                />
              </label>
              <div className="mt-5">
                <TableOrder
                  thead={!ShowEntry ? TitleItemFmm : TitleItemFmmShow}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={
                    !ShowEntry ? TBodyItemCorrect : TBodyItemCorrectShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-12">
              <HeaderSearch>
                {isArray(DocumentOperation) &&
                  DocumentOperation.map((document, index) => (
                    <div key={index}>
                      <DocumentUploader
                        document={document}
                        handleFiles={handleFiles}
                        handleFilesInput={handleFilesInput}
                        handleDelete={removeFileByIdentifier}
                        viewBtn={true}
                        files={files}
                        setNameFiles={setNameFiles}
                        nameFiles={nameFiles}
                        index={index}
                        idFmm={FormEntry}
                        /* documentPreview={!!ShowEntry}
                        viewBtnDelete={!!ShowEntry} */
                        updateAndCreate={updateAndCreate}
                        callBack={editUploadFiles}
                      />
                    </div>
                  ))}
              </HeaderSearch>
              <HeaderSearch>
                <OtherAttchment
                  otherDocuments={otherDocuments}
                  titulo={"Otros documentos"}
                  attachment={attachment}
                  setAttachment={setAttachment}
                  attachmentType={attachmentType}
                  setAttachmentType={setAttachmentType}
                  endPointDelete={
                    environment.DELETE_OTHER_ATTACHMENT_ENTRY_CORRECT
                  }
                  responseDeleteFile={responseDeleteFile}
                  viewBtn={true}
                  update={updateAndCreate}
                  isLoadingUpdate={isLoadingUpdate}
                />
              </HeaderSearch>
            </div>
            {!ShowEntry && (
              <Box
                display="flex"
                alignItems="center"
                marginTop={"20px"}
                marginBottom={"20px"}
                justifyContent="flex-end"
                flexWrap={"wrap"}
              >
                <ButtonStyled type="submit" onClick={handleSubmit}>
                  Guardar
                </ButtonStyled>
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ReviewCorrectEntry;
