import React from "react";
import { PDFNationalTerritory } from "./PDFNationalTerritory";
import { PDFRestOfTheWorl } from "./PDFRestOfTheWorl";
import { PDFUserToUser } from "./PDFUserToUser";

export const HeaderOperationOutput = ({ values }) => {
  const { code_operationCorrected } = values;
  return (
    <>
      {code_operationCorrected === 50
        ? (
          <PDFNationalTerritory values={values} />
        )
        : code_operationCorrected >= 201 && code_operationCorrected <= 226
          ? (
            <PDFRestOfTheWorl values={values} />
          )
          : code_operationCorrected >= 401 && code_operationCorrected <= 450
            ? (
              <PDFNationalTerritory values={values} />
            )
            : code_operationCorrected >= 601 && code_operationCorrected <= 618
              ? (
                <PDFRestOfTheWorl values={values} />
              )
              : code_operationCorrected >= 801 && code_operationCorrected <= 814
                ? (
                  <PDFUserToUser values={values} />
                )
                : (
                  ""
                )}
    </>
  );
};
