import axios from "axios";
import env from "../../constants/apiConst";

export const __Cities = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.CITIES_GET}?page=${page || 1}`, options);
  return res;
};

export const __ShowCities = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.CITIES_SHOW}/${id}`, options);
  return res;
};

export const __DepartmentsAll = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DEPARTMENTS_ALL}`, options);
  return res;
};
