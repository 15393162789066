/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import FormUCShow from "../FormShow/FormUCShow";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import BusinessUnitForm from "components/Form/BusinessUnitForm";
import ImportarUndComercial from "../forms/ImportarUndComercial";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import TbodyBusinessUnits from "components/Tables/TbodyBusinessUnits";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __BusinessUnits } from "request/configuration/__BusinessUnits";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const BusinessUnits = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const {
    update,
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestDelete,
    setRequestCreate
  } = useContext(ConfigurationCRUDContext);

  const thead = [
    { name: "Código", order: "code_businessUnit" },
    { name: "Nombre", order: "name_businessUnit" },
    { name: "Estado", order: "status_businessUnit" },
    { name: "Acciones", order: "" }
  ];

  const formEdit = () => {
    return <BusinessUnitForm />;
  };

  const formSHow = () => {
    return <FormUCShow />;
  };

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formSHow);
    setRequestEdit(environment.BUSINESS_UNITS_UPDATE);
    setRequestCreate(environment.BUSINESS_UNITS_CREATE);
    setRequestDelete(environment.BUSINESS_UNITS_DELETE);
  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configBusinessUnits", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.BUSINESS_UNITS,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Unidades Comerciales">
        <ImportarUndComercial />
      </ConfigurationHeader>
      {isLoading || (
        <TableOrder
          thead={thead}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyBusinessUnits}
        />
      )}
      <Pagination
        thead={thead}
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default BusinessUnits;
