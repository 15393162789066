import React from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdOutlineBlock } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  _getDeleteVetoDriver,
  _getDeleteVetoPerson,
  _getDeleteVetoTruck
} from "request/__Security";
import {
  setDataVetaDriver,
  setDataVetaPerson,
  setDataVetaTruck
} from "redux/actions/securityActions";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuSecutiry = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataVetaTruck, dataVetaPerson, dataVetaDriver } = useSelector(
    (state) => state.security
  );

  const handleDelete = () => {
    if (data?.origin === "person") {
      handleDeleteVetoPerson();
    } else if (data?.origin === "driver") {
      handleDeleteVetoDriver();
    } else {
      handleDeleteVetoTruck();
    }
  };

  const handleDeleteVetoTruck = async (e) => {
    try {
      const res = await _getDeleteVetoTruck(token, data?.id);
      const status = res?.data?.status;
      //  console.log({status})
      if (status?.code === 200) {
        const dataFilter = dataVetaTruck.filter((item) => {
          return item.id !== data.id;
        });
        dispatch(setDataVetaTruck(dataFilter));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVetoPerson = async (e) => {
    try {
      const res = await _getDeleteVetoPerson(token, data?.id);
      const status = res?.data?.status;
      // console.log({ status });
      if (status?.code === 200) {
        const dataFilter = dataVetaPerson.filter((item) => {
          return item.id !== data.id;
        });
        dispatch(setDataVetaPerson(dataFilter));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVetoDriver = async (e) => {
    try {
      const res = await _getDeleteVetoDriver(token, data?.id);
      const status = res?.data?.status;
      //  console.log({ status });
      if (status?.code === 200) {
        const dataFilter = dataVetaDriver.filter((item) => {
          return item.id !== data.id;
        });
        console.log(dataFilter)
        dispatch(setDataVetaDriver(dataFilter));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>
      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={handleDelete}>
          <IconMenuRUD as={MdOutlineBlock} />
          Desbloquear
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default MenuSecutiry;
