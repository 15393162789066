/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import TableOrder from "components/Tables/TableOrder";
import React, { useContext, useEffect, useState } from "react";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __AUnidad } from "request/configuration/_AUnidad";
import TbodyUnidad from "../tables/TbodyUnidad";
import Pagination from "components/Pagination/Pagination";
import environment from "constants/apiConst";
import FormUnitMeasurement from "../forms/UnitMeasurement";

import FormUnitView from "../FormShow/FormUnitView";
import ProductCRUDContext from "context/ProductCRUDContext";
import ModalDelete from "components/modal/ModalDelete";
import ModalTransition from "components/modal/ModalTransition";
import HeaderUnit from "../../RefProductInter/HeaderUnit";
import { useSelector } from "react-redux";

const Unidad = () => {
  const {
    editData,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    isOpenDelete,
    isOpenEdit,
    onCloseModal,
    onCloseShow,
    formShow,
    isOpenShow,
    setFormEdit,
    formEdit,
    setFormShow,
    deleteData,
    update
  } = useContext(ProductCRUDContext);

  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);

  const navigate = useNavigate();

  const formEditSub = () => {
    return <FormUnitMeasurement />;
  };

  const formularioview = () => {
    return <FormUnitView />;
  };

  useEffect(() => {
    const initialpos = async () => {
      setFormShow(formularioview);
      setFormEdit(formEditSub);
      setRequestEdit(environment.UNIT_UPDATE);
      setRequestCreate(environment.UNIT_CREATE);
      setRequestDelete(environment.UNIT_DELETE);
      try {
        const res = await __AUnidad(token, page);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    initialpos();
  }, []);

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formularioview);
    const initialpos = async () => {
      setPage(1);
      setLoading(true);
      const formData = new FormData();
      formData.append("search", q.toLowerCase());
      setRequestEdit(environment.UNIT_UPDATE);
      setRequestCreate(environment.UNIT_CREATE);
      setRequestDelete(environment.UNIT_DELETE);
      try {
        const res = await __AUnidad(token, formData, page);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    initialpos();
  }, [q]);

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formularioview);
    navigate(`?`);

    const initialpos = async () => {
      setPage(1);
      setLoading(true);
      setRequestEdit(environment.UNIT_UPDATE);
      setRequestCreate(environment.UNIT_CREATE);
      setRequestDelete(environment.UNIT_DELETE);
      try {
        const res = await __AUnidad(token, page);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    initialpos();
  }, [update]);

  const Title = [
    { name: "Codigo", order: "code_unit_measurement" },
    { name: "Unidad De Medida", order: "unit_measurement" },
    { name: "Estado", order: "status_unit_measurement" },
    { name: "Acciones", order: null }
  ];

  return (
    <>
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento`}
        onDelete={deleteData}
      />
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"xl"}
        modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={'outside'}
      >
        {formEdit}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenShow}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Ver información"}
        handleClick={onCloseShow}
        buttonMain={"Aceptar"}
      >
        {formShow}
      </ModalTransition>

      <SectionContainerGene>
        <HeaderUnit title="Unidades De Medidas"></HeaderUnit>

        <TableOrder
          thead={Title}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyUnidad}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </SectionContainerGene>
    </>
  );
};

export default Unidad;
