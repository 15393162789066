/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetAuxProduct,
} from "request/Inventory/__InventoryCRUD";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftRefProduct,
  arrayRigthRefProduct,
  arrayRigthUserRefProduct,
} from "./Array/ArrayExport";
import { theadAuxProduct } from "./Array/ArrayInventoryControl";
import { FormProduct } from "./SearchTabInventory/FormProduct";
import { TbodyAuxPrdocut } from "./TbodyTable/TbodyAuxPrdocut";
import { useDebounce } from "hooks/useDebouce";
import GlobalContext from "context/GlobalContext";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { entryFormNodeInputFields } from "pages/Pass-Form/ListItem";
import { productsNodeInputFields } from "pages/Treasury/ArrayList/ArrayList";
import {
  excludeProperties,
  isArray,
  safelyConvertToString,
} from "utils/type-check-utils";
import { __Post } from "request/Petitions/__Post";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useSearchUrl } from "hooks/useSearchUrl";

export const InventoryProduct = () => {
  const { state, cleanState, clearParamsSearch, setParamsSearch } = useSearchUrl({ getParams: ["description", "product_code", "subheading", "group_product", "status_product", "without_quantity", "date_start", "date_end", "valueProducts"], nestedProp: "searchAdvance" })

  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [openAlert] = AlertErrorAlert();
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
  });

  const { searchInformacion, valueQualified } = values;

  const Searchdebounced = useDebounce(searchInformacion, 700);
  const { setUser_Qualified } = useContext(GlobalContext);
  const dispacthRedux = useDispatch();
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    return async () => dispacthRedux(clearSearch());
  }, []);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    dispacthRedux(clearSearch());
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    setCurrentPage([]);
  };


  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && {
        qualifiedUser_id: valueQualified.value,
      }),
    };

    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      GetAuxProduct(data);
      dispacthRedux(setSearch(data));
      return;
    }
    dispacthRedux(clearSearch());
  }, [Searchdebounced, searcheAdvanced, page, valueQualified, freeZoneValue]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, [])

  useEffect(() => {
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);

  const handleSubmit = (formulario, fields) => {
    /*    const newInfo = {};
    Object.entries(searcheAdvanced).map(([key, valor]) => {
      if (!valor) {
        return;
      }
      return (newInfo[key] = valor);
    });
 */

    const { type_export_file = "null", qualifiedUser_id = "null", status_product = "null", product_balance = "null", without_quantity = "null", ...rest } = formulario

    const info = {
      ...rest,
      ...(formulario?.without_quantity && {
        without_quantity: formulario?.without_quantity[0]?.value,
      }),
      type_export_file: "xlsx",
      ...(formulario?.type_export_file && {
        type_export_file: formulario?.type_export_file?.value,
      }),
      ...(formulario?.status_product && {
        status_product: formulario?.status_product[0]?.value,
      }),
      ...(formulario?.product_balance && {
        product_balance: formulario?.product_balance?.value,
      }),
      ...(formulario?.qualifiedUser_id && {
        qualifiedUser_id: isArray(formulario?.qualifiedUser_id)
          ? formulario?.qualifiedUser_id?.map((item) => item?.value || item)
          : safelyConvertToString(formulario?.qualifiedUser_id?.value) !== ""
            ? formulario?.qualifiedUser_id?.value
            : formulario?.qualifiedUser_id || [],
      }),
      ...(formulario?.status && {
        status: isArray(formulario?.status)
          ? formulario?.status?.map((item) => item?.value || item)
          : formulario?.status?.value || [],
      }),
      fields,
    };


    exportColumnBy(info);
  };

  const exportColumnBy = async (data) => {
    try {
      const res = await __Post(
        environment.EXPOR_EXCEL_REF_PRODUCT,
        token,
        data
      );
      if (res?.status === 200) {
        onCloseExport();
        return openAlert(`${res?.data?.status?.message}`, "success");
      } else {
        return openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAuxProduct = async (body) => {
    setLoading(true);
    try {
      const res = await __GetAuxProduct(token, page, body);

      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (res?.data?.status?.data?.data.length !== 0) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateArrayExportNodes = () => {
    if (authUser.is_qualified_user === 1) {
      return arrayLeftRefProduct.concat(arrayRigthUserRefProduct);
    }
    if (authUser.is_qualified_user !== 1) {
      return arrayLeftRefProduct.concat(arrayRigthRefProduct);
    }

    return [];
  };

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={calculateArrayExportNodes()}
        selectNodeList={productsNodeInputFields}
        enableAdvance={true}
      />

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormProduct
          onClose={onCloseSearch}
          handleSearchSubmit={setSearchAdvanced}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Producto</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getInventoryProduct"}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />
        {allAccess ? (
          <ExportDetail onOpen={onOpenExport} />
        ) : myPermission?.includes("exportExcelInventoryRefProduct") ? (
          <ExportDetail onOpen={onOpenExport} />
        ) : (
          ""
        )}
        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadAuxProduct}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyAuxPrdocut}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
