/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SimpleGrid, Switch } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import { dataEmbarque } from "../ArrayCorrect";
import {
  __OperationListCorrected,
  __OperationListId
} from "request/configuration/__Operations";
import { useSelector } from "react-redux";
import { isValid, safelyConvertToString } from "utils/type-check-utils";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import environment from "constants/apiConst";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

export const FormCorrectEntry = ({
  form,
  valuesCorrect,
  handleInputChangeCorrect,
  data = null
}) => {
  const { authUser } = useContext(AuthContext);
  const {
    StateCurrentExport,
    stateCurrent,
    selectExport,
    selectOperation,
    selectEmbarcacion,
    setselectEmbarcacion,
    setselectOperation,
    dataSelectOperation,
    setselectExport,
    dataSelectExport,
    portMCorrect,
    inspeccionCorrect,
    setPortMCorrect,
    setInspeccionCorrect,
    portM,
    inspeccion,
    setDocumentOperation
  } = useContext(FormCrudCRUDContext);
  const { token } = useSelector((state) => state.auth.dataUser);
  const location = useLocation(); // navigate
  const queryClient = useQueryClient();
  const { FormEntry = null, ShowEntry = null, FormOutput = null, ShowOutput = null  } = queryString.parse(
    location.search
  );

  useEffect(() => {
    if (selectOperation && selectOperation.documents) {
      setDocumentOperation(selectOperation?.documents);
    }
  }, [selectOperation]);

  const {
    code_operation,
    short_name_operation,
    shipment_entryForm,
    purchase_order,
    balance_correction
  } = form || {};
  const {
    purchase_order_corrected,
    portmagdalena_corrected,
    inspection_corrected
  } = valuesCorrect || {};

  const changeCheck = (e) => {
    handleInputChangeCorrect(e);
    console.log({
      target: e.target,
      name: e.target.name,
      value: e.target.checked
    });
    if (e.target.name === "portmagdalena_corrected") {
      setPortMCorrect(e.target.checked);
      return;
    }
    if (e.target.name === "inspection_corrected") {
      setInspeccionCorrect(e.target.checked);
    }
  };

  const { requestData } = useGlobalContext();
  const [openAlert] = AlertErrorAlert();

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdate = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["viewFormCorrected"] });
      return;
    }
  };
  //viewFormCorrect
//CORRECT_FMMO_SHOW
  const saveTypeOperation = (id) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput || ShowEntry || FormEntry);
    formData.append("operationCorrected_id", id);

    const requestData = {
      data: formData,
      Endpoint: isValid(FormOutput || ShowOutput) ?  environment.CORRECT_FMMO_UPDATE : environment.CORRECT_FMMI_UPDATE ,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: handleResponseUpdate,
    });
  };

  const setTypeOperation = (e) => {
    saveTypeOperation(e?.id);
    setselectOperation(e);
  };



  return (
    <>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={
            stateCurrent?.ExporterSupplier?.id !== selectExport?.value &&
            "BorderCorrect"
          }
          tag={"Exportador/Proveedor*"}
          data={dataSelectExport}
          set={setselectExport}
          value={selectExport}
          isDisabled={balance_correction === 1}
        />
      </div>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={
            code_operation + " " + short_name_operation !==
              selectOperation?.label && "BorderCorrect"
          }
          tag={"Tipo de operación"}
          data={dataSelectOperation || data}
          value={selectOperation}
          set={setTypeOperation}
        />
      </div>

      <div style={{ width: "100%" }}>
        <InputSelect
          className={
            shipment_entryForm !== selectEmbarcacion?.label && "BorderCorrect"
          }
          tag={"Tipo de embarcación"}
          data={dataEmbarque}
          value={selectEmbarcacion}
          set={setselectEmbarcacion}
          isDisabled={balance_correction === 1}
        />
      </div>

      <LabelGroup
        changeValue={
          safelyConvertToString(purchase_order) !==
            safelyConvertToString(purchase_order_corrected) && "BorderCorrect"
        }
        desicion={balance_correction === 1}
        name={"purchase_order_corrected"}
        onChange={handleInputChangeCorrect}
        value={purchase_order_corrected}
        tag={"Orden de compra"}
        maxLength={80}
        size={100}
      />
      <SimpleGrid minChildWidth="100px" spacing="10px" className="ms-4 mt-3">
        {authUser.is_commerce === 1 ||
          (authUser.admin === 1 && (
            <div className={portM !== portMCorrect && "BorderCorrect"}>
              <LabeFormlStyled htmlFor="portmagdalena_corrected">
                Portmagdalena
              </LabeFormlStyled>
              <div>
                <Switch
                  name="portmagdalena_corrected"
                  id="portmagdalena_corrected"
                  size="lg"
                  value={portmagdalena_corrected}
                  onChange={changeCheck}
                  isChecked={portMCorrect}
                />
              </div>
            </div>
          ))}
        {authUser.is_commerce === 1 || authUser.admin === 1
          ? (
            <div className={inspeccion !== inspeccionCorrect && "BorderCorrect"}>
              <LabeFormlStyled htmlFor="inspection_corrected">
              Inspección
              </LabeFormlStyled>
              <div>
                <Switch
                  name="inspection_corrected"
                  id="inspection_corrected"
                  size="lg"
                  value={inspection_corrected}
                  onChange={changeCheck}
                  isChecked={inspeccionCorrect}
                  isDisabled
                />
              </div>
            </div>
          )
          : (
            ""
          )}
      </SimpleGrid>
    </>
  );
};
