import { FORMS_TYPES } from "redux/types/FormsTypes";

const initialState = {
  idForms: null
};
export const FormsUploadIntReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORMS_TYPES.FOMR_UPLOAD:
      return {
        idForms: action.payload
      };

    default:
      return state;
  }
};
