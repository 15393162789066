export const thead = [
  { name: "Nombre", order: null },
  { name: "Tipo", order: null },
  { name: "Identificación", order: null },
  { name: "Placa", order: null },
  { name: "Fecha", order: null },
  { name: "Tipo de sanción", order: null },
  { name: "Descripción", order: null },
  { name: "Estado", order: null }
];
