import React, { useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputsContainer } from "../../Auth.styled";
import PasswordField from "components/PasswordField/PasswordField";
import { CardButton } from "styled/Buttons.styled";
import { __Reset_Password } from "request/__Reset_Password";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { Input, ScaleFade } from "@chakra-ui/react";
import { useModal } from "hooks/useModal";
import AlertError from "components/Alerts/AlertError";
import AlertSuccess from "components/Alerts/AlertSuccess";
import SpinnerComponent from "components/Spinner/SpinnerComponent";

const ConfirnmPasswordSchema = Yup.object().shape({
  password: Yup.string().required("¡Inserte la nueva contraseña!"),
  password_confirm: Yup.string()
    .min(6, "¡La contraseña debe tener al menos 6 caracteres!")
    .max(50, "¡La contraseña debe tener máximo 50 caracteres!")
    .required("¡Inserte nuevamente la contraseña!")
    .oneOf([Yup.ref("password"), null], "¡Las contraseñas deben coindicir!")
});

const ConfirmPassword = () => {
  const location = useLocation();
  const { tk = "" } = queryString.parse(location.search);
  const initialValues = { password: "", password_confirm: "" };
  const [isOpenError, openModalError, closeModalError] = useModal();
  const [isOpenSuccess, openModalSuccess, closeModalSuccess] = useModal();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const recover = async (values) => {
    const { password, password_confirm } = values;
    console.log(values, "values")
    setLoading(true);
    const formData = new FormData();

    console.log("password: ", password);
    formData.append("token", tk);
    formData.append("password", password);
    formData.append("password_confirm", password_confirm);
    try {
      const res = await __Reset_Password(formData);
      console.log(res.data.status.code);
      if (res.data.status.code === 200) {
        setLoading(false);
        setAlertMessage(`Password actualizado Exitosamente`);
        openModalSuccess();
        setTimeout(() => {
          navigate("/auth", {
            replace: true
          });
        }, 1000);
      } else {
        setLoading(false);
        setAlertMessage(res.data.status.message);
        openModalError();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    if (isOpenError) {
      closeModalError();
      setAlertMessage("");
    }
    if (isOpenSuccess) {
      closeModalSuccess();
      setAlertMessage("");
    }
  };
  return (
    <>
      {alertMessage && (
        <>
          <ScaleFade in={isOpenError}>
            <AlertError
              alertMessage={alertMessage}
              closeAlert={handleCloseAlert}
            />
          </ScaleFade>
          <ScaleFade in={isOpenSuccess}>
            <AlertSuccess
              alertMessage={alertMessage}
              closeAlert={handleCloseAlert}
            />
          </ScaleFade>
        </>
      )}
      {loading && <SpinnerComponent />}
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem",
          wordWrap: "break-word",
          padding: "0 2rem"
        }}
      >
        Digita la nueva contraseña y confirmala para guardarla en el sistema ¡y
        listo!
      </p>
      <Formik
        validationSchema={ConfirnmPasswordSchema}
        initialValues={initialValues}
        onSubmit={recover}
      >
        {() => (
          <Form>
            <InputsContainer margin="1rem 2rem 2rem 0rem">
              {/*           <Input
                placeholder={email}
                name="email"
                type="email"
                disabled={true}
                value={email}
              /> */}
              <PasswordField
                size="lg"
                name="password"
                placeholder="Contraseña"
              />
              <PasswordField
                size="lg"
                name="password_confirm"
                placeholder="Repita su contraseña"
              />
              <CardButton type="submit">Reiniciar Contraseña</CardButton>
            </InputsContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmPassword;
