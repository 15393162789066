import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import { ExportPdf } from "components/Export/ExportPdf";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import React, { useRef, useEffect, useCallback, useState } from "react";

import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { Bar, Pie } from "react-chartjs-2";

import "./operationsUsed.css";
import { estadisticaOptions } from "../General/EstadisticaOptions";
import { Box } from "@chakra-ui/react";
import bro from "../../../assets/bro.png";

export const OperationsUsed = () => {
  const [loading, setLoading] = useState(false);
  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);

  const [currentPage, setCurrentPage] = useState(null);
  const [userData, setUserData] = useState(null);
  const defaultData = (label) => {
    return {
      labels: [],
      datasets: [
        {
          label: "Operaciones de salida",
          data: [],
          backgroundColor: ["#47B3E7"]
        }
      ]
    };
  };

  useEffect(() => {
    currentPage &&
      setUserData({
        entryOp: {
          ...(currentPage?.operations_used_entry !== 0
            ? {
              labels: currentPage?.operations_used_entry.map(
                (data) => data.code_operation
              ),
              datasets: [
                {
                  label: "Operaciones de entrada",
                  data: currentPage?.operations_used_entry.map(
                    (data) => data.contador
                  ),
                  backgroundColor: ["#47B3E7"]
                }
              ]
            }
            : defaultData("Operaciones de entrada"))
        },
        outputData: {
          ...(currentPage?.operations_used_output !== 0
            ? {
              labels: currentPage?.operations_used_output.map(
                (data) => data.code_operation
              ),
              datasets: [
                {
                  label: "Operaciones de salida",
                  data: currentPage?.operations_used_output.map(
                    (data) => data.contador
                  ),
                  backgroundColor: ["#47B3E7"]
                }
              ]
            }
            : defaultData("Operaciones de salida"))
        },
        EntryOut: {
          ...(currentPage?.entry !== 0 && currentPage?.output !== 0
            ? {
              labels: [...currentPage?.output.map((data) => data.short_name_operation), ...currentPage?.entry.map((data) => data.short_name_operation)],
              datasets: [
                {
                  label: "Movimiento",
                  data: [...currentPage?.output.map((data) => data.amount), ...currentPage?.entry.map((data) => data.amount)],
                  backgroundColor: ["#47B3E7"]

                }
              ]
            }
            : defaultData("Entradas y salidas"))
        },
        total_op: {
          ...(currentPage?.total_op !== 0
            ? {
              labels: currentPage?.total_op.map(
                (data) =>
                  data.operation_entry_form || data.operation_output_form
              ),
              datasets: [
                {
                  label: "",
                  data: currentPage?.total_op.map((data) => data.amount),
                  backgroundColor: ["#006EB8", "#47B3E7"]
                }
              ]
            }
            : defaultData("Total de operaciones"))
        }
      });
  }, [currentPage]);

  const title = "Estadísticas operaciones utilizadas";
  const ref = useRef(null);

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) ExportPdf("IdPdfFmmCorrectEntry", `${title}_${dateToday}`);
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
  }, []);

  return (
    <>
      <HeaderEstadistica
        title={"Operaciones utilizadas"}
        enableQualifiedUser
        // generar64={dowmLoadPdfImgae}
        excelPath={environment.POST_EXPORT_EXCEL_OP_ENTRY_OUT}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_OPERATION_USER}
        enableRendering={true}
      />
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div id="contenedorEstadistica">
            <ExportPdfGrafica
              id="IdPdfFmmCorrectEntry"
              rutaImage={rutaImage}
              thead={""}
              data={""}
              tbody={[]}
              title={`${title}`}
              dateToday={dateToday}
            />
            {(userData?.entryOp?.datasets[0]?.data?.length === 0 &&
              userData?.outputData?.datasets[0]?.data?.length === 0 &&
              userData?.EntryOut?.datasets[0]?.data?.length === 0 &&
              userData?.total_op?.datasets[0]?.data?.[0] === 0 &&
              userData?.total_op?.datasets[0]?.data?.[0] === 0
            )
              ? (
                <>
                  <div className="d-flex justify-content-center" style={{ paddingTop: "2rem" }}>
                    <img
                      style={{ width: `${20}%` }}
                      src={bro}
                      alt="no hay formulario"
                    />
                  </div>
                  <h4 className="text-center container" style={{ paddingBottom: "2rem" }}>No se encontraron resultados</h4>
                </>
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div id="contenedorVehicle" className="px-2 card">
                        {!loading && userData && (
                          <Bar
                            data={userData?.entryOp}
                            options={estadisticaOptions(
                              "Cantidad",
                              "Operación",
                              "Total de operaciones de entrada"
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="contenedorResultado" className="px-2 card">
                        {!loading && userData && (
                          <Bar
                            data={userData?.outputData}
                            options={estadisticaOptions(
                              "Cantidad",
                              "Operación",
                              "Total de operaciones de salida"
                            )}
                          />
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                    <div id="contenedorResultado" className="px-2 card">
                      {!loading && userData && (
                        <Bar
                          data={userData?.EntryOut}
                          options={estadisticaOptions(
                            "Cantidad",
                            "Operación",
                            "Total de operaciones"
                          )}
                        />
                      )}
                    </div>
                  </div> */}

                    <div className="col-lg-6">
                      <div id="contenedorResultado" className="px-2 card">
                        <Box height="32.3vh">
                          {!loading && userData && (
                            <Pie
                              data={userData?.total_op}
                              options={{
                                responsive: true,
                                maintainAspectRatio: false
                              }}
                            />
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* {details && (
              <div className="mt-2">
                <TableOrder
                  thead={theadEstadisticas}
                  data={data ? data?.statistics : []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyForm}
                  subTotals={data?.quantity_form ? data?.quantity_form : []}
                  titleTotal="Total"
                />
              </div>
            )} */}
          </div>
        </>
      )}
    </>
  );
};
