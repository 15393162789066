import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false
  },
  colors: {
    white: "#ffffff",
    text: "#333333",
    shadow: "#bababa",
    mainAccent: "#007FFF",
    gradient: "linear-gradient(0deg, #103D62, #1F7AC3)",
    gray: {
      ba: "#BABABA"
    }
  },
  breakpoints: {
    mid: "300px",
    tabletMini: "769px",
    tabletL: "820px"
  },
  borderRadius: "0.5em"
});

export default theme;
