import { useState } from "react";
import { Navigate } from "react-router";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

/**
 * La ruta para acceder al bienvenido
 * http://localhost:3000/welcome
 */
const WelcomeRoute = ({ children }) => {
  const location = useLocation();

  const { name = "" } = queryString.parse(location.search);

  const [state, setState] = useState(true);

  return state ? children : <Navigate to="/" />;
};

export default WelcomeRoute;
