import environment from "constants/apiConst";

export const entryFormNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status_entryPass",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "PRESENTADO", label: "PRESENTADO" },
      { value: "APROBADO", label: "APROBADO" },
      { value: "AUTORIZADO", label: "AUTORIZADO" },
      { value: "DEVUELTO", label: "DEVUELTO" },
      { value: "ELIMINADO", label: "ELIMINADO" },
      { value: "CADUCADO", label: "CADUCADO" },
      { value: "ANULADO", label: "ANULADO" }
    ],
    value: null
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]

export const entryFormExportNodeList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "nit_qualified"
  },
  {
    value: "date_security",
    label: "Fecha de seguridad"
  },
  {
    value: "name_qualified",
    label: "Nombre del cliente"

  },
  {
    value: "passForm_number",
    label: "Número de pase"

  },
  {
    value: "type_transport_name",
    label: "Tipo de transporte"
  },
  {
    value: "vehicle_plate",
    label: "Placa del vehiculo"

  },
  {
    value: "identification",
    label: "Identificación"

  },
  {
    value: "driver",
    label: "Conductor"

  },
  {
    value: "phone",
    label: "Teléfono"

  },
  {
    value: "empty_pass",
    label: "Pase vacío"

  },
  {
    value: "container_1",
    label: "Contenedor 1",
    childs: [
      {
        value: "capacity_container_1",
        label: "Capacidad contenedor 1"
      },
      {
        value: "tare_container_1",
        label: "Tara contenedor 1"
      }
    ]
  },
  {
    value: "container_2",
    label: "Contenedor 2",
    childs: [
      {
        value: "capacity_container_2",
        label: "Capacidad contenedor 2"
      },
      {
        value: "tare_container_2",
        label: "Tara contenedor 2"
      }
    ]
  },
  {
    value: "number_packages",
    label: "Numero paquetes"

  },
  {
    value: "freely_available",
    label: "Libre disposición"

  },
  {
    value: "observations",
    label: "Observaciones"

  },
  {
    value: "status_entryPass",
    label: "Estado del pase"

  }
];
