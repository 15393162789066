import { Box, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { DeleteLocalstore, GetLocalStorage } from "hooks/useLocalStorage";
import React from "react";

function MessagerTrash ({ id, onClose }) {
  const [openAlert] = AlertErrorAlert();

  const TrashObservations = () => {
    const StatusLocal = GetLocalStorage(id);
    if (StatusLocal.length > 0 || Object?.keys(StatusLocal)?.length > 0) {
      DeleteLocalstore(id);
      openAlert(
        `Los datos han sido eliminados exitosamente. Ahora puede aprobar el formulario si todos los campos están completos y verificados. `,
        "success"
      );
    } else {
      openAlert(
        `No hay datos disponibles para ser eliminados. Por favor, verifique que haya ingresado observaciones en los campos antes de intentar eliminarlos.`,
        "error"
      );
    }
  };

  return (
    <SimpleGrid row={2}>
      <Box p={3}>
        <Text as="b">Confirmación!</Text>
        <Text>
          {" "}
          Al presionar "Eliminar observaciones", se borrarán permanentemente y
          no se podrán recuperar. Solo utilice esta función al aprobar
          definitivamente el formulario.
        </Text>
      </Box>

      <Button
        colorScheme={"red"}
        shadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px"}
        onClick={() => {
          TrashObservations(id);
          onClose();
        }}
      >
        Eliminar observaciones
      </Button>
    </SimpleGrid>
  );
}

export default MessagerTrash;
