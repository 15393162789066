import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const TableHistory = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.dependency}</Td>
      <Td className="text-center">
        {data?.action === 1 ? "Bloqueado" : "Desbloqueado"}
      </Td>
      <Td className="text-center">{data?.created}</Td>
      <Td className="text-center">{data?.user_name}</Td>
      <Td className="text-center">{data?.observations}</Td>
    </Tr>
  );
};

export default TableHistory;
