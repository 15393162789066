import axios from "axios";
import env from "../../constants/apiConst";

export const __AddLicenses = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };
  const res = await axios(`${env.LICENCIA_ADD_USERCA}`, options);
  return res;
};

export const __AddLicensesAll = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.LICENCIA_GET_ALL}`, options);
  return res;
};
export const __LicensesAllUc = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.LICENCIA_GET_ALL_UC}/${id}`, options);
  return res;
};
