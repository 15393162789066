/* eslint-disable camelcase */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BiCheckShield,
  BiSearchAlt2,
  BiSupport,
  BiAddToQueue,
  BiCoinStack,
  BiBox,
  BiSelectMultiple,
  BiPackage,
  BiFile,
  BiDetail
} from 'react-icons/bi'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

import { TbChecklist } from 'react-icons/tb'
import { ImNotification, ImStatsDots } from 'react-icons/im'
import {
  BsBookmarks,
  BsShieldX,
  BsRecordCircle,
  BsFillClipboard2MinusFill
} from 'react-icons/bs'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import {
  Accordion,
  AccordionItem,
  useDisclosure,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Tooltip,
  Link
} from '@chakra-ui/react'

import './StyleAside.css'
import environment from 'constants/apiConst'
import iconAside from '../../assets/logo_aside.png'
import imagenLogocity from '../../assets/image 27.png'
import { AuthContext } from 'context/AuthContext'
import '../../pages/Configuration/Style/stilos.css'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { ModalMenu } from '../Header/ModalPass/ModalMenu'
import { __EditCreatePass } from 'request/Pases/__CrudPass'
import { AccordionAside } from '../../components/AccordionAside/index'
import { AgrupForm } from 'components/Header/ModalPass/form/AgrupForm'
// import { FormSalida } from "components/Header/ModalPass/form/FormSalida";
import { FormEntrada } from 'components/Header/ModalPass/form/FormEntrada'
import { __EditCreateDispatch } from 'request/DespachoAgrupado/__DispatchAg'
import { FormCustomsClearance } from 'components/Header/ModalPass/form/FormCustomsClearance'
import { __EditCreateCustoms } from 'request/CustomsClearance.js/__CustomsClearence'
import { IconAside, IconGeneric } from 'styled/Icons.styled'
import {
  DianList,
  FormularioEntry,
  Formularios,
  inventoryControl,
  ListItems,
  MovimientosInt,
  RevisionInt,
  productList,
  Security,
  statistics,
  treasury,
  Weighing,
  certificated,
  Tracking
} from './ListItems'
// import inventoryIcon from "../../assets/dashboard-icons/inventory.png";
import { FaWeight, FaBoxOpen } from 'react-icons/fa'
import { FormCreateAdmin } from 'pages/ProductionOrder/FormCreateAdmin'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { GiBookmark, GiReturnArrow } from 'react-icons/gi'
import FormIntegrationBox from 'pages/IntegrationBox/Form/FormIntegrationBox'
import { __EditCreateIntegrationBox } from 'request/IntegrationBox/___IntegrationBox'
import { setDataStatusBalance } from 'redux/actions/statusBalanceaction'
import { ProSidebar } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
// import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdSettings,
  MdHistoryToggleOff
} from 'react-icons/md'
import { AiOutlineDashboard, AiOutlineUsergroupAdd } from 'react-icons/ai'
import ImageDane from '../../assets/dashboard-icons/Dane.png'
import { ModalAdvancedSearch } from 'pages/Entry_and_exit_forms/View/AdvancedSearch/ModalAdvancedSearch'
import { VscSourceControl } from 'react-icons/vsc'

import { RxDashboard } from 'react-icons/rx'
import { Storage } from 'hooks/useStorage'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import isArrayNotEmpty, {
  hasCommonElements,
  isArray
} from 'utils/type-check-utils'
import { setPermissionsRedux } from 'redux/actions/permissionsActions'
import { localPermissions } from 'Permissions/permissions'
import InputSelect from 'components/InputSelect/InputSelect'
import { isValid } from 'utils/parseFloat'
import { useGlobalContext } from 'context/GlobalContext'
import queryEnv from 'constants/queryConst'
import { useQuery } from '@tanstack/react-query'

const Aside = ({
  state,
  set,
  isAdvancedSearchModal,
  openAdvancedSearchModal,
  onCloseAdvancedSearchModal
}) => {
  const navigate = useNavigate()
  const [bodyMo, setBodyMo] = useState(null)
  const { authUser } = useContext(AuthContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setDataToEdit } = useContext(FormCrudCRUDContext)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const { admin, user_rol, is_commerce } = useSelector(
    state => state.auth.dataUser
  )
  const [allAccess, setAllAccess] = useState(false)
  const [namePermissions, setNamePermissions] = useState([])
  const dispacthRedux = useDispatch()
  const { balance } = useSelector(state => state.status)
  const [comerceExter, setComerceExter] = useState(false)
  const ref = useRef({ route: '', search: '' })
  const { get: getStorage, set: setStorage } = Storage
  const defaultValor = getStorage('route')
  const defaultPath =
    typeof defaultValor === 'string' && !Array.isArray(defaultValor)
  const [pathName, setPathName] = useState(defaultPath ? defaultValor : '/')
  const [movementName, setMovementName] = useState(getStorage('search'))
  const [nameModule, setNameModule] = useState('')
  const {
    isOpen: isOpenCreatePass,
    onOpen: onOpenCreatePass,
    onClose: onCloseCreatePass
  } = useDisclosure()

  const formEntradas = () => {
    return <FormEntrada onClose={onClose} />
  }

  const formAgrupado = () => {
    return <AgrupForm onClose={onClose} />
  }

  const formCustomsClearance = () => {
    return <FormCustomsClearance onClose={onClose} />
  }

  const formOrdenProduction = () => {
    return <FormCreateAdmin onClose={onClose} />
  }

  const formIntegrationBox = () => {
    return <FormIntegrationBox onClose={onClose} />
  }

  const createCustom = () => {
    const formData = new FormData()
    formData.append('qualifiedUser_id', authUser.userQualified)
    if (nameModule === 'Certificado de integración') formData.append('integration_certificate_type_id', certificateSelect?.value)

    if (nameModule === 'Desaduanamiento') {
      createCustomsClearenceUserQualified(formData)
    }
    if (nameModule === 'Pase de salida') createPass(formData)
    if (nameModule === 'Pase de entrada') createDispacth(formData)
    if (nameModule === 'Orden de producción') createOrden(formData)
    if (nameModule === 'Certificado de integración') createBox(formData)

    return onCloseCreatePass()
  }

  const OpenForm = items => {
    const formData = new FormData()
    formData.append('qualifiedUser_id', authUser.userQualified)
    if (items) setNameModule(items)
    if (items === 'Form. Ingreso') {
      setDataToEdit(null)
      navigate(`/FormEntry`)
      return !state && set(true)
    }

    if (items === 'Form. Salida') {
      setDataToEdit(null)
      navigate(`/FormExit`)
      return !state && set(true)
    }

    if (items === 'Desaduanamiento') {
      !state && set(true)
      if (authUser.is_qualified_user === 1) {
        return onOpenCreatePass()
      }

      navigate(`/formCustomsClearance`)
     // setBodyMo(formCustomsClearance)
     // return onOpen()
    }

    if (items === 'Pase de salida') {
      !state && set(true)
      if (authUser.is_qualified_user === 1) {
        return onOpenCreatePass()
      }
      navigate(`/formInt`)
      setBodyMo(formAgrupado)
      return onOpen()
    }

    if (items === 'Pase de entrada') {
      !state && set(true)
      if (authUser.is_qualified_user === 1) {
        return onOpenCreatePass()
      }
      setBodyMo(formEntradas)
      navigate(`/formInt`)
      return onOpen()
    }

    if (items === 'Orden de producción') {
      !state && set(true)
      if (authUser.is_qualified_user === 1) {
        return onOpenCreatePass()
      }

     // setBodyMo(formOrdenProduction)
      navigate(`/CrearOrdenDeProduccion`)
      return //onOpen()
    }

    if (items === 'Certificado de integración') {
      !state && set(true)
      if (authUser.is_qualified_user === 1) {
        return onOpenCreatePass()
      }
      setBodyMo(formIntegrationBox)
      navigate(
        `/IntegracionBox`
      );
      if (authUser.is_qualified_user === 0) {
        onOpen()
      }
    }
  }


  const { data: dataCertificate, isPending: isLoadingDataCertificate } =
  useQuery({
    queryKey: [queryEnv?.GET_CERTIFICATE_TYPE],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_CERTIFICATE_TYPE,
        PropertyBody: "GET",
      }),
  });

const dataCertificateForSelect =
  isValid(dataCertificate?.data?.status?.data) &&
  isArray(dataCertificate?.data?.status?.data) &&
  !isLoadingDataCertificate
    ? dataCertificate?.data?.status?.data.map((items) => ({
        value: items?.id,
        label: items?.integration_certificate_name,
        //decimal: items.decimal_values_precision,
      }))
    : [];


  const createCustomsClearenceUserQualified = async user => {
    try {
      const resultado = await __EditCreateCustoms(
        environment.CREATE_CUSTOMS_CLEARANCE,
        token,
        user
      )
      const numberFormIn = resultado.data.status.data
      if (resultado.data.status.code === 200) {
        onClose()
        navigate(`/formCustomsClearance?Customs=${numberFormIn.id}`, {
          replace: true
        })
      } else {
        openAlert(resultado.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createDispacth = async user => {
    try {
      const resultado = await __EditCreatePass(
        environment.CREATE_PASES_IN,
        token,
        user
      )
      if (resultado.data.status.code === 200) {
        const numberFormIn = resultado.data.status.data.entry_pass
        const balancePassEntry = resultado?.data?.status?.data?.balance
        // console.log(balancePassEntry);
        if (balancePassEntry) {
          localStorage.setItem('ValueBalance', JSON.stringify(balancePassEntry))
        }
        // console.log(balancePassEntry);
        // console.log(numberFormIn);

        // localStorage.setItem("ValueBalance", JSON.stringify(balancePassEntry));
        dispacthRedux(setDataStatusBalance(!balance))
        navigate(
          `/formInt?qualifiedUser=${numberFormIn.name_qualified}&numberForm=${numberFormIn.passForm_number}&id=${numberFormIn.id}&status=${numberFormIn.status_entryPass}`
        )
      } else {
        openAlert(resultado.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createPass = async user => {
    try {
      const resultado = await __EditCreateDispatch(
        environment.CREATE_DISPATCH,
        token,
        user
      )
      if (resultado.data.status.code === 200) {
        const numberFormIn = resultado.data.status.data.dispatch_pass
        const balanceDespacho = resultado.data.status.data.balance
        // localStorage.setItem("ValueBalance", JSON.stringify(balanceDespacho));
        if (balanceDespacho) {
          localStorage.setItem('ValueBalance', JSON.stringify(balanceDespacho))
        }
        dispacthRedux(setDataStatusBalance(!balance))
        navigate(
          `/formInt?qualifiedUser=${numberFormIn.name_qualified}&numberForm=${
            numberFormIn.passForm_number
          }&id=${numberFormIn.id}&status=${
            numberFormIn.status_entryPass || numberFormIn.status_dispatchPass
          }&typeO=${'Dispatch'}`
        )
        onClose()
      } else {
        openAlert(resultado.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createOrden = async user => {
    try {
      const resultado = await __EditCreatePass(
        environment.CREATE_PRODUCTION_ORDER,
        token,
        user
      )
      if (resultado.data.status.code === 200) {
        const numberFormIn = resultado.data.status.data.production_order
        const BalanceOrder = resultado.data.status.data?.balance

        localStorage.setItem('ValueBalance', JSON.stringify(BalanceOrder))
        dispacthRedux(setDataStatusBalance(!balance))
        navigate(`/CrearOrdenDeProduccion?Orden=${numberFormIn.id}`)
      } else {
        openAlert(resultado.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createBox = async user => {
    try {
      const resultado = await __EditCreateIntegrationBox(
        environment.CREATE_INTEGRATION_BOX,
        token,
        user
      )
      if (resultado.data.status.code === 200) {
        const numberFormIn = resultado.data.status.data
        const BalanceOrder = resultado.data.status.data?.balance
        // console.log(numberFormIn);
        navigate(`/IntegracionBox?id=${numberFormIn?.cetificate?.id}`)
        localStorage.setItem('ValueBalance', JSON.stringify(BalanceOrder))
        dispacthRedux(setDataStatusBalance(!balance))
        onClose()
      } else {
        openAlert(resultado.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const movement = (items, name) => {
    navigate(`${items}?movement=${name}`)
  }

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true)
    } else if (user_rol.modules) {
      if (is_commerce === 1) {
        setComerceExter(true)
      } else {
        setComerceExter(false)
      }

      const newPermissions = user_rol.modules.reduce((acc, item) => {
        if (!acc.includes(item.modul_permissionGroup)) {
          acc.push(item.modul_permissionGroup)
        }
        return acc
      }, [])

      setNamePermissions(newPermissions)
    }
  }, [user_rol?.modules, admin])

  const [myPermission, setmyPermission] = useState(null)

  const collectAndSetUserPermissionsBasedOnRole = async () => {
    if (!allAccess) {
      let set = new Set() // Crear un Set para almacenar elementos únicos
      user_rol?.modules?.forEach(item => {
        item.permission.forEach(e => set.add(e.name_permission)) // Añadir elementos al Set
      })
      let newArray = [...set] // Convertir el Set de nuevo a un Array
      setmyPermission(newArray)
      isArrayNotEmpty(newArray) && dispacthRedux(setPermissionsRedux(newArray))
    }
  }

  useEffect(() => {
    collectAndSetUserPermissionsBasedOnRole()
  }, [user_rol, allAccess])

  const obtenerPermisos = e => {
    return e.nameAccess?.some(a => myPermission?.includes(a))
  }

  const location = useLocation()

  const handleSet = route => {
    !state && set(true)
    ref.current = { route }
    setStorage('route', ref.current.route)
    setPathName(getStorage('route'))
  }
  const { requestData } = useGlobalContext();
  const [certificateSelect, setCertificateSelect] = useState(null);
  const { permissionList } = useSelector(state => state.permissions)
  const { history: historyEntryForm } = localPermissions.Entryform
  const isActiveHistory =
    isArray(historyEntryForm) && isArray(permissionList)
      ? hasCommonElements(permissionList, historyEntryForm)
      : false

 

  return (
    // <AsideNav>
    <div className='content-sidebar'>
      <ModalAdvertencia
        isOpen={isOpenCreatePass}
        onClose={onCloseCreatePass}
        iconCustom={ImNotification}
        title={`Advertencia`}
        colorAdjust={'#1F7AC3'}
        colorScheme={'blue'}
        titleBody={<>{`Estás a punto de iniciar el proceso de creación de un ${nameModule}.`} 
      {nameModule === 'Certificado de integración' ? <InputSelect
          tag={"Tipo de certificado"}
          size={100}
          data={dataCertificateForSelect}
          set={setCertificateSelect}
          value={certificateSelect}
          isLoading={isLoadingDataCertificate}
        /> : <></>}</>}
        generar={createCustom}
      />
      
      <ModalMenu
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        cuerpo={bodyMo}
        size='3xl'
        scrollBehaviorProp={'outside'}
      />

      <ModalAdvancedSearch
        isOpen={isAdvancedSearchModal}
        onClose={onCloseAdvancedSearchModal}
      />

      <ProSidebar collapsed={state}>
        <div className='logoaside'>
          <NavLink
            to='/'
            activeClassName='active'
            className='box'
            onClick={() => !state && set(true)}
          >
            {state ? (
              <>
                <img
                  className='imgLogoAsideTwo'
                  src={window.assetURL + imagenLogocity}
                  alt={imagenLogocity}
                />
              </>
            ) : (
              <>
                <img className='imgLogoAside' src={iconAside} alt={iconAside} />
              </>
            )}
          </NavLink>
        </div>

        <div className='containerFrag pb-5'>
          <div
            onClick={() => set(!state)}
            style={{
              position: 'fixed',
              left: state ? '4rem' : '16rem',
              top: '3.2rem',
              background: '#fff',
              borderRadius: '20px',
              width: '40px',
              height: '40px',
              zIndex: '9000',
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
            }}
            className='d-flex justify-content-center align-items-center'
          >
            <IconGeneric
              width={'50px'}
              margin={'5px'}
              as={state ? MdKeyboardArrowRight : MdKeyboardArrowLeft}
            />
          </div>
          {/* <Fragmento className="containerFrag"> */}
          {(allAccess ||
            namePermissions.includes('Movimientos') ||
            namePermissions.includes('Revision') ||
            namePermissions.includes('Desaduanamiento') ||
            namePermissions.includes('Certificado de integración') ||
            namePermissions.includes('Formularios') ||
            namePermissions.includes('Orden de producción')) && (
            <Accordion className='' allowToggle>
              <AccordionItem className='mb-3 ' border='0px'>
                <AccordionButton
                  className='acordionOut d-flex justify-content-center'
                  p={1}
                >
                  {state && (
                    <Tooltip placement='left' hasArrow label='Nuevo Formulario'>
                      <div className='boxform'>
                        <IconGeneric color='#1f7ac3' as={BiAddToQueue} />
                      </div>
                    </Tooltip>
                  )}

                  {!state && (
                    <div className='boxform'>
                      <IconGeneric color='#1f7ac3' as={BiAddToQueue} />
                      <span className='color-text'>Nuevo Formulario</span>
                      <AccordionIcon
                        color='#1F7AC3'
                        size={24}
                        className='ms-1'
                      />
                    </div>
                  )}
                </AccordionButton>

                {allAccess &&
                  Formularios.map((items, index) => (
                    <AccordionPanel
                      type='button'
                      onClick={() => OpenForm(items.name)}
                      key={index}
                      className=' text-white fontForm'
                      pb={1}
                    >
                      <div className='d-flex'>
                        {state && (
                          <Tooltip placement='left' hasArrow label={items.name}>
                            <span>
                              <IconAside
                                color='#fff'
                                className='ms-3 me-1'
                                as={items.src}
                              />
                            </span>
                          </Tooltip>
                        )}

                        {!state && (
                          <IconAside
                            color='#fff'
                            className='ms-3 me-1'
                            as={items.src}
                          />
                        )}
                        {!state && (
                          <span className='text-white'>{items.name}</span>
                        )}
                      </div>
                    </AccordionPanel>
                  ))}

                {!allAccess &&
                  Formularios.filter(obtenerPermisos).map((items, index) => (
                    <AccordionPanel
                      type='button'
                      onClick={() => OpenForm(items.name)}
                      key={index}
                      className=' text-white fontForm'
                      pb={1}
                    >
                      <div className='d-flex'>
                        {state && (
                          <IconAside
                            color='#fff'
                            className='ms-3 me-1'
                            as={items.src}
                          />
                        )}

                        {!state && (
                          <IconAside
                            color='#fff'
                            className='ms-3 me-1'
                            as={items.src}
                          />
                        )}
                        {!state && (
                          <span className='text-white'>{items.name}</span>
                        )}
                      </div>
                    </AccordionPanel>
                  ))}
              </AccordionItem>
            </Accordion>
          )}

          {/* Análisis de control */}
          {/*   {console.log("namePermissions", namePermissions)} */}
          {allAccess || namePermissions.includes('Matriz de control') ? (
            <NavLink
              to='/control-analysis'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/control-analysis')}
            >
              <div className='ms-3 box-name '>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Análisis de control'}
                  >
                    <span>
                      <IconGeneric
                        as={VscSourceControl}
                        className={
                          pathName === '/control-analysis'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    as={VscSourceControl}
                    className={
                      pathName === '/control-analysis'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Análisis de control
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {/* Fin Análisis de control */}

          {/* Busqueda avanzada */}
          {allAccess ? (
            <div className='ms-3 box-name' onClick={openAdvancedSearchModal}>
              {state ? (
                <Tooltip placement='left' hasArrow label={'Busqueda avanzada'}>
                  <span>
                    <IconGeneric color='#fff' as={BiSearchAlt2} />
                  </span>
                </Tooltip>
              ) : (
                <IconGeneric color='#fff' as={BiSearchAlt2} />
              )}

              {!state && (
                <span className='ms-2 text-white fontAside'>
                  Busqueda avanzada
                </span>
              )}
            </div>
          ) : allAccess === false &&
            namePermissions.includes('Busqueda avanzada') ? (
            <div className='ms-3 box-name' onClick={openAdvancedSearchModal}>
              {state ? (
                <Tooltip placement='left' hasArrow label={'Busqueda avanzada'}>
                  <span>
                    <IconGeneric color='#fff' as={BiSearchAlt2} />
                  </span>
                </Tooltip>
              ) : (
                <IconGeneric color='#fff' as={BiSearchAlt2} />
              )}
              {!state && (
                <span className='ms-2 text-white fontAside'>
                  Busqueda avanzada
                </span>
              )}
            </div>
          ) : (
            ''
          )}
          {/* Fin Busqueda avanzada */}

          {allAccess || namePermissions.includes('Resumen de actividad') ? (
            <NavLink
              to='/'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/')}
            >
              <div className='ms-3 box-name '>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Resumen de Actividad'}
                  >
                    <span>
                      <IconGeneric
                        as={AiOutlineDashboard}
                        className={
                          pathName === '/' ? 'activeItem' : 'notActiveItem'
                        }
                        borderRadius={pathName === '/' ? '999px' : ''}
                        padding={pathName === '/' ? '2px' : ''}
                        background={pathName === '/' ? '#fff' : ''}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    <IconGeneric
                      as={AiOutlineDashboard}
                      className={
                        pathName === '/' ? 'activeItem' : 'notActiveItem'
                      }
                      background={pathName === '/' ? '#fff' : ''}
                      borderRadius={pathName === '/' ? '999px' : ''}
                      padding={pathName === '/' ? '2px' : ''}
                    />
                  </span>
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Resumen de Actividad
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {allAccess || namePermissions.includes('Panel de control') ? (
            <NavLink
              to='/dashboard'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/dashboard')}
            >
              <div className='ms-3 box-name '>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Panel de Control'}>
                    <span>
                      <IconGeneric
                        className={
                          pathName === '/dashboard'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }
                        as={RxDashboard}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    color={pathName === '/dashboard' ? '#1f7ac3' : '#fff'}
                    as={RxDashboard}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Panel de Control
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {/* confi */}

          {allAccess ? (
            <AccordionAside
              items={ListItems}
              title='Configuración'
              nameIcon={MdSettings}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false &&
            comerceExter === true &&
            namePermissions.includes('Configuración') ? (
            <AccordionAside
              items={ListItems}
              title='Configuración'
              nameIcon={MdSettings}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {/* Cuadro de integración */}

          {allAccess ? (
            <AccordionAside
              items={certificated}
              title='Certificado de integración'
              nameIcon={BiSelectMultiple}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false &&
            namePermissions.includes('Certificado de integración') ? (
            <AccordionAside
              items={certificated}
              title='Certificado de integración'
              nameIcon={BiSelectMultiple}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {allAccess ? (
            <AccordionAside
              items={productList}
              title='Referencia de productos'
              nameIcon={FaBoxOpen}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false &&
            namePermissions.includes('Referencia de productos') ? (
            <AccordionAside
              items={productList}
              title='Referencia de productos'
              nameIcon={FaBoxOpen}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {/* Terceros */}

          {allAccess ? (
            <NavLink
              to='/third-parties'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/third-parties')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Terceros'}>
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/third-parties'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiDetail}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/third-parties'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiDetail}
                  />
                )}

                {!state && (
                  <span className='ms-2 text-white fontAside'> Terceros </span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false && namePermissions.includes('Terceros') ? (
            <NavLink
              to='/third-parties'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/third-parties')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Terceros'}>
                    <span>
                      <IconGeneric
                        className={`${
                          ref.current === '/third-parties'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiDetail}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      ref.current === '/third-parties'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiDetail}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'> Terceros </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {(is_commerce === 1 || admin === 1) && (
            <AccordionAside
              items={Tracking}
              title='Seguimiento'
              nameIcon={BsRecordCircle}
              onClick={items => movement(items.path, items.name)}
              allAccess={true}
              // permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          )}

          {allAccess ? (
            <NavLink
              to='/customsClearance'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/customsClearance')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Desaduanamiento'}>
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/customsClearance'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiBox}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    color={`${
                      pathName === '/customsClearance'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiBox}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Desaduanamiento
                  </span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false &&
            namePermissions.includes('Desaduanamiento') ? (
            <NavLink
              to='/customsClearance'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/customsClearance')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Desaduanamiento'}>
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/customsClearance'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiBox}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/customsClearance'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiBox}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Desaduanamiento
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {/* Movimientos */}

          {allAccess ? (
            <AccordionAside
              items={MovimientosInt}
              title='Movimientos'
              nameIcon={BiPackage}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Movimientos') ? (
            <AccordionAside
              items={MovimientosInt}
              title='Movimientos'
              nameIcon={BiPackage}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {/* Formularios */}

          {allAccess ? (
            <AccordionAside
              items={FormularioEntry}
              title='Formularios'
              nameIcon={BiFile}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Formularios') ? (
            <AccordionAside
              items={FormularioEntry}
              title='Formularios'
              nameIcon={BiFile}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {(allAccess || isActiveHistory) && (
            <NavLink
              to='/HistoryFmm'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/HistoryFmm')}
            >
              <div className='ms-3 box-name'>
                {(state || !state) && (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={!state ? '' : 'Historial de formularios'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/HistoryFmm'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={MdHistoryToggleOff}
                      />{' '}
                    </span>
                  </Tooltip>
                )}

                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Historial de formularios
                  </span>
                )}
              </div>
            </NavLink>
          )}

          {/* Revisión */}

          {(allAccess || namePermissions.includes('Cola de revisión')) && (
            <AccordionAside
              items={RevisionInt}
              title='Cola de revisión'
              nameIcon={TbChecklist}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          )}

          {/* Control inventario */}

          {allAccess ? (
            <AccordionAside
              items={inventoryControl}
              title='Inventario'
              nameIcon={BsFillClipboard2MinusFill}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Inventario') ? (
            <AccordionAside
              items={inventoryControl}
              title='Inventario'
              nameIcon={BsFillClipboard2MinusFill}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {allAccess ? (
            <NavLink
              to='/OrdenDeProduccion'
              activeClassName='active'
              className={`${
                location.pathname === '/OrdenDeProduccion' ? 'select' : 'boSx'
              }`}
              onClick={() => handleSet('/OrdenDeProduccion')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Orden de producción'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/OrdenDeProduccion'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiCoinStack}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/OrdenDeProduccion'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiCoinStack}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Orden de producción
                  </span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false &&
            namePermissions.includes('Orden de producción') ? (
            <NavLink
              to='/OrdenDeProduccion'
              activeClassName='active'
              className='boSx'
              onClick={() => handleSet('/OrdenDeProduccion')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Orden de producción'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/OrdenDeProduccion'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BiCoinStack}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/OrdenDeProduccion'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BiCoinStack}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Orden de producción
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {/* Seguridad */}
          {!authUser.is_qualified_user && allAccess ? (
            <AccordionAside
              items={Security}
              title='Seguridad'
              nameIcon={BiCheckShield}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Seguridad') ? (
            <AccordionAside
              items={Security}
              title='Seguridad'
              nameIcon={BiCheckShield}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}
          {allAccess ? (
            <AccordionAside
              items={Weighing}
              title='Báscula'
              nameIcon={FaWeight}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Básculas') ? (
            <AccordionAside
              items={Weighing}
              title='Básculas'
              nameIcon={FaWeight}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}
          {/* tesoreria */}
          {allAccess ? (
            <AccordionAside
              items={treasury}
              title='Tesorería'
              nameIcon={RiMoneyDollarCircleFill}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false && namePermissions.includes('Tesorería') ? (
            <AccordionAside
              items={treasury}
              title='Tesorería'
              nameIcon={RiMoneyDollarCircleFill}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {allAccess ? (
            <AccordionAside
              items={statistics}
              title='Estadísticas'
              nameIcon={ImStatsDots}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : allAccess === false &&
            namePermissions.includes('Estadísticas') ? (
            <AccordionAside
              items={statistics}
              title='Estadísticas'
              nameIcon={ImStatsDots}
              onClick={items => movement(items.path, items.name)}
              allAccess={allAccess}
              permissions={user_rol.modules}
              toggle={state}
              set={set}
              ref={ref}
              pathName={pathName}
              setPathName={setPathName}
              movementName={movementName}
              setMovementName={setMovementName}
            />
          ) : (
            ''
          )}

          {(allAccess || namePermissions.includes('Cola de presentados')) &&
          authUser.is_qualified_user === 1 &&
          authUser.is_commerce === 0 ? (
            <NavLink
              to='/Coladepresentados'
              activeClassName='active'
              className='boSx'
              onClick={() => handleSet('/Coladepresentados')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Cola de doc. presentados'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/Coladepresentados'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BsBookmarks}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/Coladepresentados'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={BsBookmarks}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Cola de doc. presentados
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {/*
          { ? (

          ) : (
            ""
          )} */}

          {allAccess ? (
            <NavLink
              to='/motivodevolucion'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/motivodevolucion')}
            >
              <div className='ms-3 box-name '>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Motivo de devolución'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/motivodevolucion'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={GiReturnArrow}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/motivodevolucion'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={GiReturnArrow}
                  />
                )}

                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Motivo de devolución
                  </span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false &&
            namePermissions.includes('Motivo de devolución') ? (
            <NavLink
              to='/motivodevolucion'
              activeClassName='active'
              className='box'
              onClick={() => handleSet('/motivodevolucion')}
            >
              <div className='ms-3 box-name '>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Motivo de devolución'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/motivodevolucion'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={GiReturnArrow}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/motivodevolucion'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={GiReturnArrow}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Motivo de devolución
                  </span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {allAccess ? (
            <NavLink
              to='/Blocking'
              activeClassName='active'
              className='boSx'
              onClick={() => handleSet('/Blocking')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Bloqueos'}>
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/Blocking'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BsShieldX}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/Blocking' ? 'activeItem' : 'notActiveItem'
                    }`}
                    as={BsShieldX}
                  />
                )}

                {!state && (
                  <span className='ms-2 text-white fontAside'>Bloqueos</span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false && namePermissions.includes('Bloqueos') ? (
            <NavLink
              to='/Blocking'
              activeClassName='active'
              className='boSx'
              onClick={() => handleSet('/Blocking')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Bloqueos'}>
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/Blocking'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={BsShieldX}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/Blocking' ? 'activeItem' : 'notActiveItem'
                    }`}
                    as={BsShieldX}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>Bloqueos</span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {(allAccess || namePermissions.includes('Dian')) && (
            <div className='mt-3'>
              <AccordionAside
                items={DianList}
                onClick={items => movement(items.path, items.name)}
                allAccess={allAccess}
                permissions={user_rol.modules}
                toggle={state}
                set={set}
                ref={ref}
                pathName={pathName}
                setPathName={setPathName}
                movementName={movementName}
                setMovementName={setMovementName}
              />
            </div>
          )}

          {allAccess ? (
            <NavLink
              to='/dane'
              activeClassName='selected'
              className='box'
              onClick={() => handleSet('/dane')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'DANE'}>
                    <span>
                      <img src={ImageDane} alt={ImageDane} />
                    </span>
                  </Tooltip>
                ) : (
                  <img src={ImageDane} alt={ImageDane} />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>DANE</span>
                )}
              </div>
            </NavLink>
          ) : allAccess === false && namePermissions.includes('DANE') ? (
            <NavLink
              to='/dane'
              activeClassName='selected'
              className='box'
              onClick={() => handleSet('/dane')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip placement='left' hasArrow label={'Dane'}>
                    <span>
                      <img src={ImageDane} alt={ImageDane} />
                    </span>
                  </Tooltip>
                ) : (
                  <img src={ImageDane} alt={ImageDane} />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>DANE</span>
                )}
              </div>
            </NavLink>
          ) : (
            ''
          )}

          {namePermissions.includes('Gestión de usuario') && (
            <NavLink
              to='/gestion-user'
              activeClassName='selected'
              className='box'
              onClick={() => handleSet('/gestion-user')}
            >
              <div className='ms-3 box-name'>
                {state ? (
                  <Tooltip
                    placement='left'
                    hasArrow
                    label={'Gestión de usuarios'}
                  >
                    <span>
                      <IconGeneric
                        className={`${
                          pathName === '/gestion-user'
                            ? 'activeItem'
                            : 'notActiveItem'
                        }`}
                        as={AiOutlineUsergroupAdd}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <IconGeneric
                    className={`${
                      pathName === '/gestion-user'
                        ? 'activeItem'
                        : 'notActiveItem'
                    }`}
                    as={AiOutlineUsergroupAdd}
                  />
                )}
                {!state && (
                  <span className='ms-2 text-white fontAside'>
                    Gestión de usuarios
                  </span>
                )}
              </div>
            </NavLink>
          )}
          <Link
            href='https://technisupport.atlassian.net/wiki/spaces/MZF/pages/1978597396/Gu+a+de+inicio'
            isExternal
          >
            <div className='ms-3 box-name'>
              {state ? (
                <Tooltip placement='left' hasArrow label={'Manual de usuario'}>
                  <span>
                    <IconGeneric className={'notActiveItem'} as={GiBookmark} />
                  </span>
                </Tooltip>
              ) : (
                <IconGeneric className={'notActiveItem'} as={GiBookmark} />
              )}
              {!state && (
                <span className='ms-2 text-white fontAside'>
                  Manual de usuario
                </span>
              )}
            </div>
          </Link>

          <Link
            onClick={() =>
              (window.location = 'mailto:soporte@zonafrancabarranquilla.com')
            }
          >
            <div className='ms-3 box-name'>
              {state ? (
                <Tooltip placement='left' hasArrow label={'Soporte'}>
                  <span>
                    <IconGeneric className={'notActiveItem'} as={BiSupport} />
                  </span>
                </Tooltip>
              ) : (
                <IconGeneric className={'notActiveItem'} as={BiSupport} />
              )}
              {!state && (
                <span className='ms-2 text-white fontAside'>Soporte</span>
              )}
            </div>
          </Link>
          {/* </Fragmento> */}
        </div>
      </ProSidebar>
    </div>
    // </AsideNav>
  )
}

export default Aside
