const initialState = {};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FORM_FIELD": {
      const { propertyName, name, value } = action.payload;
      return {
        ...state,
        [propertyName]: {
          ...state[propertyName],
          [name]: value,
        },
      };
    }

    case "UPDATE_FORM_SINGLE": {
      const { propertyName, name, value } = action.payload;
      return {
        ...state,
        [propertyName]: {
          ...state[propertyName],
          [name]: value,
        },
      };
    }

    case "CLEAR_FORM_FIELD": {
      const { propertyName } = action.payload;
      return {
        ...state,
        [propertyName]: {},
      };
    }

    default:
      return state;
  }
};

export default formReducer;
