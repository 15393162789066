/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import BlockingContext from "context/BlockingContex";
import React, { useContext, useState } from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TableHistory from "../Table/TableHistory";
import { TitleHistory } from "../Title/ArrayTitle";

/**
 *  FormHistory - Componente de tabla para historial de dependencia bloqueadas
 *
 * @component
 * @return {Component} Retorna componente de tabla del historial dependencia bloqueadas.
 */

const FormHistory = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const { history, setHistory } = useContext(BlockingContext);

  return (
    <>
      <HeaderSearch>
        <TableOrder
          thead={TitleHistory}
          data={history || []}
          setData={setHistory}
          loading={loading}
          tbodyData={TableHistory}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default FormHistory;
