import axios from "axios";

import env from "../../constants/apiConst";

export const __Country = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.COUNTRY_GET}?page=${page || 1}`, options);
  return res;
};

export const __SelectPaises = async (token) => {
  const options = {
    method: "GET",
    headers: { Authorization: token ? `Bearer ${token}` : "" }
  };
  const res = await axios(`${env.COUNTRY_ALL}`, options);
  return res;
};
