import { Td, Tr } from "@chakra-ui/react";

const TBodyLogBalance = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.operation_type ? data?.operation_type : "N/A"}</Td>
        <Td className="text-center"> {data?.form_code ? data?.form_code : "N/A"}</Td>
        <Td className="text-center"> {data?.input_quantity ? data?.input_quantity : "N/A"}</Td>
        <Td className="text-center"> {data?.output_quantity ? data?.output_quantity : "N/A"}</Td>
        <Td className="text-center"> {data?.input_quantity_product ? data?.input_quantity_product : "N/A"}</Td>
        <Td className="text-center"> {data?.output_quantity_product ? data?.output_quantity_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_balance ? data?.input_quantity_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_balance_product ? data?.quantity_balance_product : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyLogBalance;
