import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import './styIeViewItems.css'

const ViewItemsOriginal = ({ data }) => {
  const location = useLocation() // navigate
  const { FormEntry = '' } = queryString.parse(location.search)
  const stylesFail = {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    border: 'none',
    paddingBottom: '12px',
    borderBottom: '2px solid #bababa80'
  }

  return (
    <table className='custom-table'>
      <tbody>
        <tr>
          <td className='table-header-cell'>
            <p>Subpartida arancelaria:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.code_subheading ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>País origen:</p>
          </td>
          <td className='table-cell'>
            <p>
              {' '}
              {data?.name_country
                ? `${data?.code_country} ${data?.name_country}`
                : 'N/A'}{' '}
            </p>
          </td>
        </tr>
        {FormEntry && (
          <tr>
            <td className='table-header-cell'>
              <p>País procedencia:</p>
            </td>
            <td className='table-cell'>
              <p>
                {' '}
                {data?.name_country_proc
                  ? `${data?.code_country_proc} ${data?.name_country_proc}`
                  : 'N/A'}{' '}
              </p>
            </td>
            <td className='table-header-cell'>
              <p>País compra:</p>
            </td>
            <td className='table-cell'>
              <p>
                {' '}
                {data?.name_country_purs
                  ? `${data?.code_country_purs} ${data?.name_country_purs}`
                  : 'N/A'}{' '}
              </p>
            </td>
          </tr>
        )}
        <tr>
          <td className='table-header-cell'>
            <p>Peso bruto en Kg:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.gross_weight ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>Peso neto Kg:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.net_weight ?? 'N/A'} </p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Números de bultos:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.number_packages ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>Cantidad:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.quantity ?? 'N/A'}</p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Unidad comercial:</p>
          </td>
          <td className='table-cell'>
            <p>
              {data?.code_businessUnit
                ? `${data?.code_businessUnit} ${data?.name_businessUnit}`
                : 'N/A'}
            </p>
          </td>
          <td className='table-header-cell'>
            <p>Modo de Transporte:</p>
          </td>
          <td className='table-cell'>
            <p>
              {data?.code_transportMethod
                ? `${data?.code_transportMethod} ${data?.name_transportMethod}`
                : 'N/A'}
            </p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Bandera:</p>
          </td>
          <td className='table-cell'>
            <p>
              {data?.code_country_flag
                ? `${data?.code_country_flag} ${data?.name_country_flag}`
                : 'N/A'}
            </p>
          </td>
          <td className='table-header-cell'>
            <p>Embalaje:</p>
          </td>
          <td className='table-cell'>
            <p>
              {data?.code_packaging
                ? `${data?.code_packaging} ${data?.name_packaging}`
                : 'N/A'}
            </p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Fletes:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.us_freight ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>Seguros:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.us_insurance ?? 'N/A'} </p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Otros gastos:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.other_us_expenses ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>Valor FOB {data?.coin_corrected ?? ''}:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p> {data?.us_fob_value ?? 'N/A'}</p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Valor CIF {data?.coin_corrected ?? ''}:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.cif_us_value ?? 'N/A'} </p>
          </td>
          <td className='table-header-cell'>
            <p>Valor CIF COP:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.cif_cop_value ?? 'N/A'}</p>
          </td>
        </tr>
        <tr>
          <td className='table-header-cell'>
            <p>Tasa de Cambio:</p>
          </td>
          <td className='table-cell'>
            {' '}
            <p>{data?.exchange_rate ?? 'N/A'} </p>
          </td>
          {/*  */}
          <td className='table-header-cell'>
            <p>Acuerdo Comercial:</p>
          </td>
          <td className='table-cell'>
            <p>
              {' '}
              {data.code_tradeAgreement
                ? `${data?.code_tradeAgreement} ${data?.name_tradeAgreement}`
                : 'N/A'}{' '}
            </p>
          </td>
        </tr>
        <tr>
          <td colSpan='4' className='table-cell'>
            <p>Descripción de la mercancía</p>

            <p>
              {data?.entry_form_item_description
                ? data?.entry_form_item_description
                : data?.output_form_item_description
                  ? data?.output_form_item_description
                  : 'N/A'}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ViewItemsOriginal
