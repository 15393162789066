import React, { useState } from "react";
import { Title } from "styled/Title";
import "./styleForm.css";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { isValid } from "utils/type-check-utils";

export const FormBalanceAdjustmentLog = ({ title, item, form = {} }) => {
  const [ShowMoreState, setShowMoreState] = useState(false);
  const navigate = useNavigate();

  const ShowMore = () => {
    setShowMoreState((prev) => !prev);
  };

  const {
    entryFormCorrected_id,
    outputFormCorrected_id,
    final_balance_adjustment,
    date_statusEntryForm,
    entryForm_id,
    outputForm_id,
  } = form || {};

  const ShowSettingsAssociated = () => {
    if (isValid(entryForm_id)) {
      navigate(`/FormShow?FormEntry=${entryForm_id}`);
      return;
    }

    if (isValid(outputForm_id)) {
      navigate(`/FormShowExit?ExitNumber=${outputForm_id}`);
      return;
    }
  };

  return (
    <div id="adventenciaReturn" className="my-2 ms-2">
      <div className="d-flex">
        <div>
          {final_balance_adjustment === 1 && (
            <Title size={"18px"} weight={400} className={"pt-2"}>
              Este formulario cuenta con ajuste creado:
            </Title>
          )}

          {/*   {final_balance_adjustment === 1 && ( */}
          <div /* className="ms-2" */>
            Creada: {date_statusEntryForm}{" "}
              <span
                className="text-primary"
                style={{
                  cursor: "pointer",
                }}
                onClick={ShowSettingsAssociated}
              >
                Ver correción.
              </span>
          </div>
          {/*   )}
           */}
        </div>
      </div>
    </div>
  );
};
