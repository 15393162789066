import { Box } from "@chakra-ui/react";
import React from "react";

const ReviewsHeader = ({ title, children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={"wrap"}
      m={3}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <span className="fs-4" style={{ fontWeight: "600" }}>
          {title}
        </span>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          {children}
        </div>
      </Box>
    </Box>
  );
};

export default ReviewsHeader;
