import { Checkbox, Td, Tr } from "@chakra-ui/react";
import StatisContext from "context/StatisContext";
import React, { useEffect, useContext } from "react";

export const TBodyOperationOutput = ({ data }) => {
  const { dataForCal, setdataForCal } = useContext(StatisContext);

  const handleChange = (e) => {
    if (e.target.checked) {
      return setdataForCal(prev => [...prev, data]);
    }
    const res = dataForCal.filter((item) => item?.code_operation !== data.code_operation);
    setdataForCal(res);
  };

  return (
    <Tr>
      <Td>
        <div className="d-flex justify-content-center">
          <Checkbox
            id={data.id}
            onChange={handleChange}
            defaultChecked={dataForCal.find((element) => element.code_form === data?.code_form && element.code_operation === data?.code_operation
            )}
            isInvalid
          />
        </div>
      </Td>
      <Td className="text-center">{data?.name_typeOperation}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.count_fmm_o}</Td>
      <Td className="text-center" isNumeric>{data?.total_fob ? data?.total_fob : 0}</Td>
      <Td className="text-center" isNumeric>{data?.net_weight ? data?.net_weight : 0}</Td>
      <Td className="text-center" isNumeric>{data?.gross_weight ? data?.gross_weight : 0}</Td>
    </Tr>
  );
};
