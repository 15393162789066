import React, { useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Title } from 'styled/Title'
import { estadisticaOptions } from '../General/EstadisticaOptions'
import { IconGeneric } from 'styled/Icons.styled'
import { IoIosArrowForward } from 'react-icons/io'
import TableOrder from 'components/Tables/TableOrder'
import { theadOpStatsGraph } from '../General/ArrayEstadisticas'

import { Td, Tr } from "@chakra-ui/react";

const dataOpIfFail = [
  {
    "operation": "Autorizacion de Ingreso",
    "document": 15132,
    "items": 841662
  },
  {
    "operation": "Autorizacion de Salida",
    "document": 272,
    "items": 484371
  },
  {
    "operation": "Autorizacion de Cuadro de Integracion",
    "document": 0,
    "items": 1
  },
  {
    "operation": "Autorizacion de Declaracion",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Autorizacion Pase Entrada",
    "document": 4334,
    "items": 5202
  },
  {
    "operation": "Autorizacion Pase Salida",
    "document": 378,
    "items": 4246
  },
  {
    "operation": "Correccion de Ingreso",
    "document": 14,
    "items": 95
  },
  {
    "operation": "Correccion de Salida",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Correccion de Cuadro de Integracion",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Reversion de Declaracion",
    "document": 0,
    "items": 1
  },
  {
    "operation": "Reversion Pase Entrada",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Reversion Pase Salida",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Anulacion de Ingreso",
    "document": 5,
    "items": 1362
  },
  {
    "operation": "Anulacion de Salida",
    "document": 1,
    "items": 3
  },
  {
    "operation": "Anulacion de Cuadro de Integracion",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Correccion de Autorizacion de Ingreso",
    "document": 14,
    "items": 95
  },
  {
    "operation": "Correccion de Autorizacion de Salida",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Correccion de Autorizacion de Cuadro de Integracion",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Pesaje de Entrada",
    "document": 0,
    "items": 0
  },
  {
    "operation": "Pesaje de Salida",
    "document": 0,
    "items": 0
  }
]


export const TbodyForm = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.operation}</Td>
      <Td className="text-center" isNumeric>{data?.document}</Td>
      <Td className="text-center" isNumeric>{data?.items}</Td>
    </Tr>
  );
};

const OperationsStatisticsGraph = ({
  title,
  data,
  loading,
  setCurrentPage,
  dataOp = dataOpIfFail }) => {

  const [userData, setUserData] = useState(data || {
    labels: [
      "Autorizacion de Ingreso",
      "Autorizacion de Salida",
      "Autorizacion de Cuadro de Integracion",
      "Autorizacion de Declaracion",
      "Autorizacion Pase Entrada",
      "Autorizacion Pase Salida",
      "Correccion de Ingreso",
      "Correccion de Salida",
      "Correccion de Cuadro de Integracion",
      "Reversion de Declaracion",
      "Reversion Pase Entrada",
      "Reversion Pase Salida",
      "Anulacion de Ingreso",
      "Anulacion de Salida",
      "Anulacion de Cuadro de Integracion",
      "Correccion de Autorizacion de Ingreso",
      "Correccion de Autorizacion de Salida",
      "Correccion de Autorizacion de Cuadro de Integracion",
      "Cambio de Arancel",
      "Pesajes Realizados",
      "Pesajes Completos"
    ],
    datasets: [
      {
        label: "Cantidad de Operaciones",
        data: [124, 83, 53, 179, 66, 51, 10, 30, 25, 257, 44, 2, 1, 0, 85, 23, 0, 80, 67],
        backgroundColor: ["#006EB8"]
      },
      {
        label: "Cantidad de Items",
        data: [179, 247, 55, 124, 60, 97, 16, 0, 0, 1, 1, 2, 1, 0, 164, 41, 0, 0, 0],
        backgroundColor: ["#47B3E7"]
      },
    ]
  })

  const ref = useRef(null);
  const [details, setDetails] = useState(false);

  return (
    <>
      <div id="contenedorEstadistica">
        <div className="container">
          <div className="row">
            <div className="col">
              <Title size={"18px"} weight={600} className="mt-3">
                {title}
              </Title>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div id="contenedorResultado" className="px-2">
            {!loading && <Bar data={userData} ref={ref} options={estadisticaOptions('Tipos de Operaciones', 'Cantidades')} />}
          </div>
        </div>
        <span
          className="mt-3 ms-3 text-primary cursorDetail"
          onClick={() => setDetails(!details)}
        >
          Ver más
          <IconGeneric width={"20px"} as={IoIosArrowForward} className="me-3" />
        </span>

        {details && (
          <div className="mt-2">
            <TableOrder
              thead={theadOpStatsGraph}
              data={dataOp || []}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyForm}
              titleTotal="Total"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default OperationsStatisticsGraph

