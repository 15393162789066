/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import queryString from 'query-string'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { MenuItem } from '@chakra-ui/react'
import {
  MdContentCopy,
  MdFactCheck,
  MdOutlineEditNotifications
} from 'react-icons/md'
import { FaRegCalendarTimes } from 'react-icons/fa'
import { AuthContext } from 'context/AuthContext'
import { AiOutlineFileSync } from 'react-icons/ai'
import { FiBell } from 'react-icons/fi'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsAprobadosForm = ({ data }) => {
  const {
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason,
    setDataToCaducar,
    onOpenCaducar,
    onOpenRadicar,
    setDataRadicar,
    changeStatusCorregido
  } = useContext(FormCrudCRUDContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { authUser } = useContext(AuthContext)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const { movement = '' } = queryString.parse(location.search)

  const handlePresent = status => {
    if (movement === 'Ingreso') {
      const info = {
        id: data.id,
        status_entryForm: status
      }

      if (status === 'DEVUELTO') {
        onOpenReturnReason()
        setReturnReason(info)
      } else {
        changeStatus(info)
      }
    } else if (movement === 'Salida') {
      const info = {
        id: data.id,
        status_outputForm: status
      }
      if (status === 'DEVUELTO') {
        onOpenReturnReason()
        setReturnReason(info)
      } else {
        changeStatus(info)
      }
    }

    const infoEntryCorrect = {
      id: data.id,
      status_entryFormCorrected: status
    }

    const infoOutputCorrect = {
      id: data.id,
      status_entryFormCorrected: status
    }

    if (movement === 'Corregido de ingreso') {
      if (status === 'DEVUELTO') {
        onOpenReturnReason()
        setReturnReason(infoEntryCorrect)
      } else if (status === 'AUTORIZADO') {
        onOpenRadicar()
        setDataRadicar(data)
      } else {
        changeStatusCorregido(infoEntryCorrect)
      }
    } else if (movement === 'Corregido de salida') {
      if (status === 'DEVUELTO') {
        onOpenReturnReason()
        setReturnReason(infoOutputCorrect)
      } else if (status === 'AUTORIZADO') {
        onOpenRadicar()
        setDataRadicar(data)
      } else {
        changeStatusCorregido(infoOutputCorrect)
      }
    }
  }

  const handleCopy = () => {
    setDataToCopy(data)
    onOpenCopy()
  }

  const handleCaducar = () => {
    setDataToCaducar(data)
    onOpenCaducar()
  }

  const EnvSelect = () => {
    if (movement === 'Ingreso') {
      return environment.POST_APROVED_ENTRY_AUTHORIZE
    }

    if (movement === 'Salida') {
      return environment.POST_APROVED_OUTPUT_AUTHORIZE
    }
  }

  const handleNotifyToAutorize = async user_id => {
    const User = {
      id: user_id
    }
    try {
      const resp = await __Post(EnvSelect(), token, User)
      if (resp.data.status.code === 200) {
        openAlert('El formulario ha sido notificado', 'success')
      } else {
        openAlert(resp?.data?.status?.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { Outputform, Entryform } = localPermissions

  const { changeStatus: changeStatusEntryForm, copy: copyEntryForm } = Entryform
  const { changeStatus: changeStatusOutputForm, copy: copyOutputForm } =
    Outputform

  const handleNotifyToTransfer = async () => {
    try {
      const resp = await __Post(environment?.ENTRYFORM_TRANSFER_NOTIFICATION, token, {
        id: data.id
      })
      if (resp.data.status.code === 200) {
        openAlert('El formulario ha sido notificado', 'success')
      } else {
        openAlert(resp?.data?.status?.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {(movement === 'Ingreso' || movement === 'Salida') &&
        (hasCommonElements(permissionList, [
          ...copyEntryForm,
          ...copyOutputForm
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={handleCopy} py={3}>
              <IconMenuRUD color='#F9A621' as={MdContentCopy} />
              Copiar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}

      {authUser.is_qualified_user !== 1 &&
        (authUser.admin === 1 || authUser.is_commerce === 1) &&
        (hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={() => handlePresent('AUTORIZADO')} py={3}>
              <IconMenuRUD as={MdFactCheck} />
              Radicar
            </MenuItemStyled>
            <HrDividerNoMargin />

            <MenuItemStyled onClick={() => handlePresent('DEVUELTO')} py={3}>
              <IconMenuRUD color='#d92525' as={AiOutlineFileSync} />
              Devolver
            </MenuItemStyled>
            <HrDividerNoMargin />

            <MenuItemStyled onClick={handleCaducar} py={3}>
              <IconMenuRUD color='#F9A621' as={FaRegCalendarTimes} />
              Caducar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}

      {movement !== 'Salida' &&
        (hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) ||
          authUser.admin === 1) && (
          <MenuItemStyled onClick={() => handleNotifyToAutorize(data.id)} py={3}>
            <IconMenuRUD color='#F1C40F' as={MdOutlineEditNotifications} />
            Notificar para Autorizar
          </MenuItemStyled>
        )}
      {
        ((authUser.is_qualified_user === 1 || authUser.admin === 1) && movement !== 'Salida' && (data?.code_operation >= 700 && data?.code_operation < 800)) && (
          <>
            <MenuItemStyled onClick={handleNotifyToTransfer} py={3}>
              <IconMenuRUD color='#F1C40F' as={MdOutlineEditNotifications} />
              Notificar traslado
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )
      }
    </>
  )
}
