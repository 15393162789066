import React, { useCallback, useContext, useEffect, useState } from "react";
import { TitleViewUpdate } from "pages/Treasury/ArrayList/ArrayList";
import { useSelector } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import TreasuryCrudContex from "context/TreasuryContex";
import TbodyViewUpdate from "pages/Treasury/Tables/TbodyViewUpdate";
import { __PostForm } from "request/Petitions/__Post";
import { Box, Heading, Input, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Table, TableCaption, TableContainer, Tabs, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import { formaterCant } from "components/Funciones/formaterCant";

const UpdateView = () => {
  const { dataIdShow } = useContext(TreasuryCrudContex);
  const { freeZone_id } = useSelector(state => state.auth.dataUser)

  const { requestData } = useGlobalContext();

  const { data, isLoading: isLoadingRate } = useQuery({
    queryKey: ["getRates"],
    queryFn: () => requestData({
      Endpoint: environment.TREASURY_UPDATE_RATE_GET,
      PropertyBody: "POST",
      data: {
        freeZone_id,
        ...(dataIdShow?.rate_id && { dataId: dataIdShow?.rate_id }),
      }
    })
  });


  const dataRate = data?.data?.status?.data;
  const dataCurrent = dataRate?.[0];

  /*  <Tr>
                    <Th>Usuario</Th>
                    <Th colSpan={2} textAlign={'center'}>{dataCurrent?.nickname}</Th>
                  </Tr>
                  <Tr>
                    <Th>Fecha</Th>
                    <Th colSpan={2} textAlign={'center'}>{dataCurrent?.date_rate}</Th>
                  </Tr> */

  return (
    <Tabs isFitted variant='enclosed'>
      <TabList mb='1em'>
        <Tab>Vigente</Tab>
        <Tab>Trazabilidad</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TableContainer>
            <Table variant='simple'>
              <TableCaption>{`${dataCurrent?.nickname ? dataCurrent?.nickname : ""}${dataCurrent?.date_rate ? `${dataCurrent?.nickname ? " - " : ""} ${dataCurrent?.date_rate}` : ""}`}</TableCaption>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th isNumeric>Valor de la transacción</Th>
                  <Th isNumeric>Valor del anexo</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Ingreso</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.input)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.income_schedule)}`}</Td>
                </Tr>
                <Tr>
                  <Td>Salida</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.output)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.output_annex)}`}</Td>
                </Tr>
                <Tr>
                  <Td>Certificado de integración</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.integration_certificate)}`}</Td>
                  <Td isNumeric></Td>
                </Tr>
                <Tr>
                  <Td>Matriz de insumo</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.production_order)}`}</Td>
                  <Td isNumeric></Td>
                </Tr>
                <Tr>
                  <Td>Declaración de importación</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.import_declaration)}`}</Td>
                  <Td isNumeric></Td>
                </Tr>
                <Tr>
                  <Td>Despacho agrupado</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.group_dispatch)}`}</Td>
                  <Td isNumeric></Td>
                </Tr>
                <Tr>
                  <Td>Pesaje en báscula</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.scale_weighing)}`}</Td>
                  <Td isNumeric></Td>
                </Tr>
                <Tr>
                  <Td>Corregido de ingreso</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.corrected_income)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.corrected_schedule_income)}`}</Td>
                </Tr>
                <Tr>
                  <Td>Corregido de salida</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.corrected_output)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.output_corrected_annex)}`}</Td>
                </Tr>
                <Tr>
                  <Td>Corregido de certificado de integración</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.fixed_integration_certificate)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.corrected_annex_integration_table)}`}</Td>
                </Tr>
                <Tr>
                  <Td>Certificado de operaciones de comercio exterior</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.certificate_foreign_trade_operations)}`}</Td>
                  <Td isNumeric>{ }</Td>
                </Tr>
                <Tr>
                  <Td>Certificado de Usuario</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.user_certificate)}`}</Td>
                  <Td isNumeric>{ }</Td>
                </Tr>
                <Tr>
                  <Td>Pase de entrada</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.entrance_passes)}`}</Td>
                  <Td isNumeric>{`$${formaterCant(dataCurrent?.entrance_pass_annex)}`}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel>
          <TableOrder
            thead={TitleViewUpdate}
            data={!isLoadingRate && dataRate ? dataRate : []}
            setData={() => { }}
            loading={isLoadingRate}
            tbodyData={TbodyViewUpdate}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default UpdateView;
