import React, { useContext } from "react";
import {
  Navigate
  //  useLocation
} from "react-router";
// import queryString from "query-string";
import { FirstLoginContext } from "context/FirstLoginContext";

const PasswordFirstRoute = ({ children }) => {
  // const location = useLocation();
  // const { username, terms_and_conditions, change_password, tk } =
  //   queryString.parse(location.search);
  const { firstLogin } = useContext(FirstLoginContext);

  console.log(firstLogin);
  return firstLogin.username &&
    firstLogin.tk &&
    (!firstLogin.terms_and_conditions ||
      !firstLogin.change_password ||
      !firstLogin.is_superadmin)
    ? (
      <Navigate to="/auth" />
    )
    : (
      children
    );
};

export default PasswordFirstRoute;
