const ElementFilter = [
  {
    dataIndex: "criteria_name",
    key: "criteria_name",
    title: "CRITERIOS"
  },
  {
    dataIndex: "shortName_freeZone",
    key: "shortName_freeZone", // name_freeZone

    title: "ZONAS"
  }
];

const Elementfirst = [
  {
    dataIndex: "month",
    key: "month",
    title: "MES"
  },
  {
    dataIndex: "shortName_freeZone",
    key: "shortName_freeZone",
    title: "ZONA"
  },
  {
    dataIndex: "name_activity",
    key: "name_activity",
    title: "ACTIVIDAD"
  },
  {
    dataIndex: "name_qualified",
    key: "name_qualified",
    title: "USUARIO"
  },
  {
    dataIndex: "have_risk_level",
    key: "have_risk_level",
    title: "CORRESPONDE AL CRITERIO"
  }
];

const ElementLast = [
  {
    dataIndex: "total_inspection",
    key: "total_inspection",
    title: "INSPECCIONES TOTALES"
  },
  {
    dataIndex: "total_compliant_inspection",
    key: "total_compliant_inspection",
    title: "CANTIDAD DE INSPECCIONES CONFORMES"
  },
  {
    dataIndex: "percentage_compliant_inspection",
    key: "percentage_compliant_inspection",
    title: "% DE INSPECCIONES CONFORMES"
  },
  {
    dataIndex: "inspection_risk_score",
    key: "inspection_risk_score",
    title: "PUNTAJE DE RIESGO "
  }
];

export { ElementFilter, Elementfirst, ElementLast };
