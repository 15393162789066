import { Checkbox, Td, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import DianCRUDContext from "context/DianCrudContext";
import { __PostForm } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";

const TbodyFinishDocument = ({ data }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { update, setUpdate, setLoading } = useContext(DianCRUDContext);

  const urlForm = {
    "FORM.SALID": `/FormShowExit?ExitNumber=${data?.form_id}`,
    "default": `/FormShow?FormEntry=${data?.form_id}`,
  }[data?.document_type || "default"];

  const handleInfoForm = () => {
    if (data?.document_type && data?.document_type === "FORM.SALID") {
      navigate(`/FormShowExit?ExitNumber=${data?.form_id}`);
    } else {
      navigate(`/FormShow?FormEntry=${data?.form_id}`);
    }
  };

  const handleItems = () => {
    navigate(
      `/ProfileFinish?IdForm=${data.form_id}&DocumentType=${data.document_type}`
    );
  };

  const handleFinish = async (e) => {
    console.log(e.target.value);
    const datafinishProfile = new FormData();
    datafinishProfile.append("document_type", data?.document_type);
    datafinishProfile.append("form_code", data.form_code);

    setLoading(true);
    try {
      const res = await __PostForm(
        environment.FINISH_DOCUMENT_PROFILES,
        token,
        datafinishProfile
      );
      if (res?.data?.status?.code === 200) {
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.date}</Td>
      <Td className="text-center">{data?.document_type}</Td>
      <Td className="text-center">
        <NavLink
          to={urlForm}
          className="ms-2"
        >
           <span className="formNumber">{data?.form_code}</span>
        </NavLink>
      </Td>
      {/* <Td className="text-center" onClick={handleInfoForm}>
        <span className="formNumber">{data?.form_code}</span>
      </Td> */}
      <Td className="text-center">{data?.qualified_user_name}</Td>
      <Td className="text-center">{data?.operation_code}</Td>
      <Td className="text-center">{data?.num_transport_document}</Td>
      <Td className="text-center">{ }</Td>
      <Td className="text-center">{ }</Td>

      <Td className="text-center">{data?.quantity_pass_item}</Td>
      <Td className="text-center">
        <IconGeneric
          color={"#1F7AC3"}
          as={BsEyeFill}
          onClick={() => handleItems()}
        />
      </Td>

      <Td
        style={{
          width: "17%",
          paddingLeft: "13px"
        }}
        className="text-center"
      >
        {
          <div
            style={{
              width: "100%",
              marginBottom: "20px"
            }}
          >
            <Checkbox
              className="birde"
              // value={data.form_code}
              onChange={handleFinish}
              colorScheme={"blue"}
            />
          </div>
        }
      </Td>
    </Tr>
  );
};

export default TbodyFinishDocument;
