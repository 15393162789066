import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineFileSync } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { FaUnlock } from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useFormBlocker } from "hooks/useFormBlocker";
import environment from "constants/apiConst";

const ButtonsCheck = ({ handleStatusBox, handleStatusAutorized, isLoading = false }) => {
  const location = useLocation();
  const { Corrected = "", idCorrected = null, id = null } = queryString.parse(location.search);
  const { authUser } = useContext(AuthContext);
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate();

  const { blockForm, formLockQueryResult, isBlocked } = useFormBlocker({
    form_id: id,
    type_of: "CI",
    userProvidedEndpoint: environment.FORMS_ON_REVIEW
  });

  return (
    <>

      {authUser.is_qualified_user !== 1 && <Tooltip placement="top" hasArrow label="Desbloquear">
        <span>
          <ButtonHeaderModule
            onClick={() => {
              blockForm(3)
              openAlert("Se ha desbloqueado con exito", "success")
              navigate(-1)
            }}
            className="m-2"
            bgColor="#fe8d59"
          >
            <IconGeneric
              className="m-1 text-white"
              as={FaUnlock}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>}

      {Corrected && Corrected !== "" && (
        <Tooltip placement="top" hasArrow label="Radicar">
          <span>
            <ButtonHeaderModule
              onClick={() => handleStatusAutorized("AUTORIZADO")}
              className="m-2"
              bgColor="#1F7AC3"
            >
              <IconGeneric className="m-1 text-white" as={BsCheck2Circle} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {!Corrected && (
        <Tooltip placement="top" hasArrow label="Radicar">
          <span>
            <ButtonHeaderModule
              onClick={() => {
                blockForm(0)
                handleStatusBox("AUTORIZADO")
              }}
              className="m-2"
              bgColor="#1F7AC3"
              // isLoading={isLoading}
            >
              <IconGeneric className="m-1 text-white" as={BsCheck2Circle} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      <Tooltip placement="top" hasArrow label="Devolver">
        <span>
          <ButtonHeaderModule
            onClick={() => handleStatusBox("DEVUELTO")}
            className="m-2"
            bgColor="#d92525"
          >
            <IconGeneric className="m-1 text-white" as={AiOutlineFileSync} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  );
};

export default ButtonsCheck;
