/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import TablePass from "components/Tables/TablesPass";
import PassCrudContext from "context/PassCrudContext";
import React, { useContext, useEffect, useState } from "react";
import {
  theadinfoFmm,
  theadTablaDispacth,
  TitleTablePass,
  TitleTablePassDispacth,
  TitleTablePassDispacthIsCommerce,
  TitleTablePassIsCommerce
} from "../View/ArrayHeadTable/TheadMovementInt";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "../stilo.css";
import { Title } from "styled/Title";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormEntryItem } from "./FormEntryItem";
import { ButtonHeaderModule, ButtonStyled } from "styled/Buttons.styled";
import TableOrderHover from "components/Tables/TableOrderHover";
import Pagination from "components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { __AddItemPass, __ObtenerEntradas } from "request/Pases/__CrudPass";
import TbodyInfoFmm from "../tbody/TbodyInfoFmm";
import { TbodyItem } from "../tbody/TbodyItem";
import { IconGeneric } from "styled/Icons.styled";
import { BiArrowFromRight } from "react-icons/bi";
import { CgPlayListAdd } from "react-icons/cg";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import { AddOut } from "./AddOut";
import { SearchItemDispacth } from "./SearchItemDispacth";
import TbodyItemDispacth from "../tbody/TbodyItemDispacth";
import { TbodyItemDispacthGet } from "../tbody/TbodyItemDispacthGet";
import ModalCopy from "components/modal/ModalCopy";
import environment from "constants/apiConst";
import { ItemSearchModal } from "./ItemSearchModal";
import GlobalContext from "context/GlobalContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";

export const FormItemsForm = ({
  freeD,
  vehicle,
  currentItemPass,
  isLoading,
  setMaxPageItem,
  pageFormItem,
  setpageFormItem,
  setCurrentItem,
  maxPageItemPass,
  orderBy,
  setOrderBy,
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const location = useLocation();
  const { typeO = "", id = "" } = queryString.parse(location.search);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [showTableData, setShowTableData] = useState(false);
  const { targetData, setTargetData } = useContext(GlobalContext);
  const [openAlert] = AlertErrorAlert();


  const {
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    requestObtenerItem,
    isOpenWarning,
    onCloseWarning,
    dataToDelete,
    deleteItemPass,
    showFormDispacthItem,
    itemDIspacth,
    isOpenCopy,
    copyItem,
    onCloseCopy,
    isOpenAsoccietUnit,
    onCloseAsoccietUnit,
    messageModalAsocciet,
    dataAsocciet,
    addItemPass,
    setRequestObtenerItemPass,
    setUpdate,
    idQualifiedUser
  } = useContext(PassCrudContext);

  const {
    isOpen: isOpen_SearchItem,
    onOpen: onOpen_SearchItem,
    onClose: onClose_SearchItem
  } = useDisclosure();

  const [searchValues, setSearchValues] = useState(null);

  const handleResData = async (values) => {
    if (values) setSearchValues(values);
    try {
    
      const res = await __ObtenerEntradas(
        token,
        `${requestObtenerItem}?page=${page || 1}`,
        values ?? searchValues
      );
      const resData = res?.data?.status;
      if (resData.code === 200) {
        setData(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        setShowTableData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleResData();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [onCloseSearchItem]);

  const formItemsEntry = () => {
    return (
      <FormEntryItem
        onClose={onCloseSearchItem}
        loading={isLoading}
        handleSearch={handleResData}
      />
    );
  };

  const formItemsDispacth = () => {
    return (
      <SearchItemDispacth
        onClose={onCloseSearchItem}
        loading={isLoading}
        handleSearch={handleResData}
      />
    );
  };

  const [FormModalSearch, setFormModalSearch] = useState(formItemsEntry);

  const openModalSearchQuest = () => {
    if (typeO === "Dispatch") {
      setFormModalSearch(formItemsDispacth);
      setRequestObtenerItemPass(environment.GET_ITEM_DISPACTH_EXIT);
    } else {
      setFormModalSearch(formItemsEntry);
      setRequestObtenerItemPass(environment.OBTENER_ITEM_ENTRADA_PASES_INT);
    }
    onOpenSearchItem();
  };
  // targetData

  const handleAddItemDispatch = async () => {
    if (!targetData) return;
    if (typeO !== "Dispatch") return;
    try {
      const res = await __AddItemPass(
        environment.UPDATE_ITEM_DISPACTH_EXIT_BY_DISPATCH,
        token,
        {
          code: targetData?.code_outputForm_corrected,
          dispatchPass_id: id
        }
      );

      if (res?.data?.status?.code === 200) {
        setUpdate((prev) => !prev);
        itemDIspacth();
        setTargetData(null);
        return openAlert("Salida agregada exitosamente", "success");
      }
      return openAlert(res?.data?.status?.message, "error");
    } catch (error) {
      return openAlert(
        "Error en el servidor, por favor contáctese con un administrador",
        "error"
      );
    }
  };

  useEffect(() => {
    handleAddItemDispatch();
  }, [targetData]);

  /*   console.log(currentItemPass); */

  /**
   * Represents a THead array Element.
   * @constructor
   * @param {string} is_qualified_user - is on auth.dataUser and 1 is true and 0 is false.
   */
  const THead = {
    Dispatch: () => {
      const dispatchThead = {
        0: TitleTablePassDispacthIsCommerce,
        1: TitleTablePassDispacth
      };
      return dispatchThead[is_qualified_user];
    },
    Default: () => {
      const defaultThead = {
        0: TitleTablePassIsCommerce,
        1: TitleTablePass
      };
      return defaultThead[is_qualified_user];
    }
  };

  return (
    <>
      <ItemSearchModal
        OnClose={onClose_SearchItem}
        isOpen={isOpen_SearchItem}
        onOpen={onOpen_SearchItem}
        qualifiedUserId={idQualifiedUser}
        mode={"simple"}
      />

      <ModalGeneric
        isOpen={isOpenSearchItem}
        onOpen={onOpenSearchItem}
        onClose={onCloseSearchItem}
        title="Buscar"
        size="6xl"
      >
        {/* <ChangeItems Orderid={Orden} UserId={qualified_users_id} /> */}
        {showTableData
          ? (
            <div>
              <ButtonStyled onClick={() => setShowTableData(false)}>
                Buscar nuevamente
              </ButtonStyled>
              <TableOrderHover
                thead={typeO === "Dispatch" ? theadTablaDispacth : theadinfoFmm}
                data={data}
                setData={setData}
                loading={isLoading}
                onCloseModal={onCloseSearchItem}
                tbodyData={
                  typeO === "Dispatch" ? TbodyItemDispacth : TbodyInfoFmm
                }
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={isLoading}
              />
            </div>
          )
          : (
            FormModalSearch
          )}
      </ModalGeneric>
      {/* Modal */}
      <ModalAdvertencia
        isOpen={isOpenAsoccietUnit}
        onClose={onCloseAsoccietUnit}
        title={`Advertencia`}
        titleBody={` ${messageModalAsocciet && messageModalAsocciet}`}
        generar={() => addItemPass(dataAsocciet)}
      />

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Eliminar item`}
        titleBody={`¿Desea eliminar el item Nº ${dataToDelete?.item}?`}
        generar={deleteItemPass}
      />

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseCopy}
        title={`Desea copiar este item`}
        onCopy={copyItem}
      />

      {showFormDispacthItem && (
        <div id="marginIcon" className="mb-3">
          <AddOut pressSearch={openModalSearchQuest} />
        </div>
      )}

      <div id="">
        <Box display="flex" justifyContent="space-between">
          <Box display={"flex"} justifyContent="space-between" alignItems={"center"} w={'450px'}>
            <Title size={"18px"} weight={600}>
              Ítems del {typeO === "Dispatch" ? "Despacho" : "Formulario"}{" "}
            </Title>

            <InputSelect
              data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
              value={orderBy}
              name="order_by"
              set={(e) => {
                setOrderBy(e);
              }}
              placeholder="Ordenar por"
              inputAttrs={{
                color: '#5f5f',
              }}
              size={40}
              isClearable={false}
            />
          </Box>

          <div className="float-end">
            {typeO === "Dispatch" && freeD === 0 && vehicle === 0 && (
              <Tooltip placement="top" hasArrow label="Agregar Salida">
                <ButtonHeaderModule
                  onClick={onOpen_SearchItem}
                  bgColor="#03C39A"
                  className=" d-flex float-end"
                >
                  <IconGeneric color="#fff" as={BiArrowFromRight} />
                </ButtonHeaderModule>
              </Tooltip>
            )}

            {freeD === 0 && vehicle === 0 && (
              <Tooltip
                placement="top"
                hasArrow
                label={`Agregar ${typeO === "Dispatch" ? "Ítem" : "Ingreso"}`}
              >
                <ButtonHeaderModule
                  onClick={
                    typeO === "Dispatch" ? itemDIspacth : openModalSearchQuest
                  }
                  bgColor="#F9A621"
                  className=" d-flex float-end me-3"
                >
                  {typeO === "Dispatch"
                    ? (
                      <IconGeneric color="#fff" as={CgPlayListAdd} />
                    )
                    : (
                      <IconGeneric color="#fff" as={BiArrowFromRight} />
                    )}
                </ButtonHeaderModule>
              </Tooltip>
            )}
          </div>
        </Box>

        <TablePass
          thead={THead[typeO]?.() || THead.Default?.()}
          data={currentItemPass}
          setData={setCurrentItem}
          loading={isLoading}
          tbodyData={typeO === "Dispatch" ? TbodyItemDispacthGet : TbodyItem}
        />
        <Pagination
          page={pageFormItem}
          setPage={setpageFormItem}
          maxPage={maxPageItemPass}
          loading={isLoading}
        />
      </div>
    </>
  );
};
