import React from "react";
import { Td, Tr } from "@chakra-ui/react";

// import CustomsCrudContext from "context/CustomsCrudContext";

const TBodyElaboradoNoAccion = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">
        <span>{data?.product_description}</span>
      </Td>
      <Td className="text-center">{data?.product_code || "N/A"}</Td>
      <Td className="text-center">{data?.subheading_code || "N/A"}</Td>
      <Td className="text-center">{data?.quantity_product}</Td>
      <Td className="text-center">{data?.unit_measurement_code}</Td>
      <Td className="text-center" isNumeric>
        {data?.quantity}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.business_unit_code || ""}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.net_weight_kilo}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.total_calculated}
      </Td>
    </Tr>
  );
};

export default TBodyElaboradoNoAccion;
