import { HeaderPDF } from "components/HeaderPDF/HeaderPDF";
import { AuthContext } from "context/AuthContext";
import React, { useContext, useState, useEffect } from "react";

export const InformacionCabecera = ({ values }) => {
  const {
    production_order_status,
    production_order_code,
    users,
    qualified_users_name,
    qualified_users_nit
  } = values;
  const [dateToday, setDateToday] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [fechaAutorizado, setFechaAutorizado] = useState(null);
  const [fechaCreate, setFechaCreate] = useState(null);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
    if (users) {
      const dateAutorizado = users?.find(
        (element) => element?.status_form === "AUTORIZADO"
      );
      setFechaAutorizado(dateAutorizado?.date_form);
      // console.log(dateAutorizado);
      const dateCreate = users?.find(
        (element) => element?.status_form === "BORRADOR"
      );
      setFechaCreate(dateCreate?.date_form);
    }
  }, [values, users]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <p>Impreso: {dateToday}</p>
          <p>Usuario: {authUser.username}</p>
          <p>Pagina 1 </p>
        </div>
        <div className="col-6">
          <HeaderPDF status={production_order_status} />
        </div>
        <div className="col-3">
          <p>Formulario: {production_order_code && production_order_code}</p>
          <p>Fecha: {fechaCreate} </p>
        </div>
        <hr className="mt-3" />

        <div className="col-3">
          <p>Usuario: {qualified_users_name}</p>
          <p>NIT: {qualified_users_nit}</p>
        </div>
        <div className="col-6">
          <p className="text-center">
            Nº Orden de producción: {production_order_code}
          </p>
        </div>
        <div className="col-3">
          <p>Fecha de autorización: {fechaAutorizado || ""}</p>
        </div>
      </div>
    </>
  );
};
