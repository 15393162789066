import { Icon, Switch, Td, Tr } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { useState } from "react";

const TBodyShowItems = ({ data, setItems, deleteItems }) => {
  const [trackingItem, setTrackingItem] = useState(0);
  const itemID = data?.entry_form_item_id;

  const change = () => {
    if (trackingItem === 0) {
      setTrackingItem(1);
      setItems(itemID);
    } else {
      setTrackingItem(0);
      deleteItems(itemID);
    }
  }

  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.code_operation ? data?.code_operation : "N/A"}</Td>
        <Td className="text-center"> {data?.code_product ? data?.code_product : "N/A"}</Td>
        <Td className="text-center"> {data?.reference ? data?.reference : "N/A"}</Td>
        <Td className="text-center"> {data?.description_product ? data?.description_product : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyShowItems;
