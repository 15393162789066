import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import "../styleBox.css";

const bold = { fontWeight: "bold" };

const TbodyUndCertificated = ({ data, iope }) => {
  return (
    <Tr>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td>
      <Td isNumeric style={bold} className="total_unit-styles">
        {data?.total_unit}
      </Td>
      <Td isNumeric style={bold} className="total_unit-styles">
        {data?.unit}
      </Td>
    </Tr>
  );
};

export default TbodyUndCertificated;
