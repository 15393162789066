import { Box, Menu, MenuButton, MenuItem, MenuList, Tooltip } from "@chakra-ui/react"
import HeaderAccordion, { ucTypes } from "../HeaderAccordion"
import { LabelGroup } from "pages/RefProductInter/LabelGroup"
import { RETURN_TYPES, useForm } from "hooks/useForm";
import InputSelect, { InputSelectMulti } from "components/InputSelect/InputSelect";
import { NumericFormat } from "react-number-format";
import { ButtonStyled } from "styled/Buttons.styled";
import { useModal } from "hooks/useModal";
import { IconGeneric } from "styled/Icons.styled";
import { CgImport } from "react-icons/cg";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { stadisticsOpeIO } from "../General/ArrayEstadisticas";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import environment from "constants/apiConst";
import { handleSanitizedObj, isValid } from "utils/type-check-utils";
import { HrDividerNoMargin } from "styled/Line.styled";
import { TbFilterCancel } from "react-icons/tb";
import TBodyOpeIO from "./TBodyOpeIO";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";


export const ExportExcelButton = ({ handleExportExcel = () => { } }) => {
  return (
    <Menu>
      <Tooltip placement="top" hasArrow label="Exportar">
        <MenuButton>
          <ButtonStyled bgColor="#03C39A" mt="0" mr="0" wd="50px" hg="35px" >
            <IconGeneric color="#fff" as={CgImport} />
          </ButtonStyled>
        </MenuButton>
      </Tooltip>
      <MenuList
        borderRadius="none"
        py={0}
        style={{ boxSizing: "border-box" }}
      >
        <MenuItem _hover={{ bg: "#1f7ac3", color: "#fff" }} py={3} onClick={() => handleExportExcel("csv")} >
          CSV
        </MenuItem>
        <HrDividerNoMargin />
        <MenuItem _hover={{ bg: "#1f7ac3", color: "#fff" }} py={3} onClick={() => handleExportExcel("xlsx")}>
          XLSX
        </MenuItem>
      </MenuList>
    </Menu>
  );
};


const initialState = {
  code_form: "",
  date_start: "",
  date_end: "",
  document_type: null,
  status_form: null,
  loaded: null,
  free_pass: null,
  freely_available: null,
}


const EstadisticasOpeIO = ({ type = "single" }) => {
  const { cleanStateWithoutProp: state } = useSearchUrl({ nestedProp: "searchSimple" });
  const { cleanStateWithoutProp, setParamsSearch, clearParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });
  const { valueQualified } = state;
  const { requestData } = useGlobalContext();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [openAlert] = AlertErrorAlert();


  const { values, handleInputChange, setValues } = useForm({
    initialState: {
      code_form: cleanStateWithoutProp?.code_form ?? "",
      date_start: cleanStateWithoutProp?.date_start ?? "",
      date_end: cleanStateWithoutProp?.date_end ?? "",
      document_type: cleanStateWithoutProp?.document_type ?? null,
      status_form: cleanStateWithoutProp?.status_form ?? null,
      loaded: cleanStateWithoutProp?.loaded ?? null,
      free_pass: cleanStateWithoutProp?.free_pass ?? null,
      freely_available: cleanStateWithoutProp?.freely_available ?? null,
    },
    returnType: RETURN_TYPES.OBJECT,
  });

  const { code_form, date_start, date_end, document_type, status_form, loaded, free_pass, freely_available } = values;

  const data = useMemo(() => {
    const { document_type, status_form, free_pass, freely_available, loaded, ...rest } = cleanStateWithoutProp

    const searchInfo = {
      ...(valueQualified && { qualifiedUser_id: valueQualified?.map(({ value }) => value) }),
      ...(document_type && { document_type: document_type?.map(({ value }) => value) }),
      ...(status_form && { status_form: status_form?.map(({ value }) => value) }),
      ...(isValid(loaded?.value) && { loaded: loaded?.value }),
      ...(isValid(free_pass?.value) && { free_pass: free_pass?.value }),
      ...(isValid(freely_available?.value) && { freely_available: freely_available?.value }),
      length: 8,
      ...rest,
    }

    return searchInfo
  }, [valueQualified, cleanStateWithoutProp]);

  useEffect(() => {
    setPage(1);
  }, [data])

  const { data: dataStatistics, isLoading } = useQuery({
    queryKey: ["stadisticsOpeIO", page, data],
    queryFn: () => requestData({
      method: "POST",
      Endpoint: environment.STATISTICS_OPERATION_DOCUMENTS,
      data,
      page,
    }),
  })

  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue } = newValue;

    setValues((previousValues) => ({
      ...previousValues,
      [sourceEvent?.event?.target?.name]: floatValue
    }));
  };

  const handleSearchStatistics = (e) => {
    e.preventDefault();
    setParamsSearch({
      params: { ...handleSanitizedObj(values, [""]) },
    });
  }

  const handleResetFilter = () => {
    setValues(initialState);
    setPage(1);
    clearParamsSearch({ searchArray: ["searchAdvance"] });
  }

  const { mutate } = useMutation({
    mutationFn: requestData,
    onSuccess: (data) => {
      openAlert(data?.data?.status?.data ?? data?.data?.status?.message, data?.data?.status?.code !== 200 ? "error" : "success");
    }
  })

  const handleExportExcel = async (type) => {
    mutate({ method: "POST", Endpoint: environment.EXPORT_STATISTICS_OPERATION_DOCUMENTS, data: { ...data, type } })
  };

  return (
    <Box
      boxSizing={"border-box"}
      width={"100%"}
      minHeight={"auto"}
      boxShadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px"}
      borderRadius={"5px"}
      padding={"0.9rem"}
    >
      <HeaderAccordion type={ucTypes?.MULTI} title='Estadísticas de operaciones en documentos'>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          gap={2}
          as={"form"}
          onSubmit={handleSearchStatistics}
        >
          <LabelGroup
            name={"date_start"}
            value={date_start}
            onChange={handleInputChange}
            tag={"Fecha inicio"}
            size={49}
            tipo={"date"}
            required={true}
          />

          <LabelGroup
            name={"date_end"}
            value={date_end}
            onChange={handleInputChange}
            tag={"Fecha final"}
            size={49}
            tipo={"date"}
            max={new Date().toISOString().split("T")[0]}
            required={true}
          />
          <InputSelectMulti
            tag={"Tipo de documento"}
            data={[{ label: "Cert. Integracion", value: "CUADR.INT." }, { label: "Decl. de Importacion", value: "FORM.DECL." },
            { label: "Ingreso", value: "FORM.INGR." }, { label: "Salida", value: "FORM.SALID" },
            { label: "Corr. de Ingreso", value: "FRM.CRR.IN" }, { label: "Corr. de Salida", value: "FRM.CRR.SL" },
            { label: "Corr. de Cert. de Integracion", value: "FRM.CRR.CI" }, { label: "Despacho Agrupado", value: "DESP.AGRP." },
            { label: "Entrada", value: "ENTR.MERC." }]}
            set={(e) => {
              setValues(prev => ({ ...prev, document_type: e?.length ? e : null }));
            }}
            style={{
              width: "49%",
            }}
            value={document_type}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={false}
            decimalScale={0}
            decimalSeparator='.'
            onValueChange={updateItemValue}
            customInput={LabelGroup}
            name={'code_form'}
            size={49}
            tag='Nº Formulario'
            value={code_form}
            isAllowed={(values) => {
              const { value } = values;
              return value.toString().length <= 6;
            }}
          />

          <InputSelectMulti
            tag={"Estado"}
            data={[{ label: "BORRADOR", value: "BORRADOR" }, { label: "PRESENTADO", value: "PRESENTADO" },
            { label: "APROBADO", value: "APROBADO" }, { label: "AUTORIZADO", value: "AUTORIZADO" },
            { label: "DEVUELTO", value: "DEVUELTO" }, { label: "ELIMINADO", value: "ELIMINADO" },
            { label: "ANULADO", value: "ANULADO" }, { label: "CADUCADO", value: "CADUCADO" },
            ]}
            set={(e) => {
              setValues(prev => ({ ...prev, status_form: e?.length > 0 ? e : null }))
            }}
            value={status_form}
            style={{
              width: "49%",
            }}
          />

          <InputSelect
            tag={"Cargado"}
            data={[{ label: "Sí", value: 1 }, { label: "No", value: 0 }]}
            set={(e) => {
              setValues(prev => ({ ...prev, loaded: e }))
            }}
            value={loaded}
            size={49}
          />


          <InputSelect
            tag={"Es paso libre"}
            data={[{ label: "Sí", value: 1 }, { label: "No", value: 0 }]}
            set={(e) => {
              setValues(prev => ({ ...prev, free_pass: e }))
            }}
            value={free_pass}
            size={49}
          />

          <InputSelect
            tag={"Es de libre disposición"}
            data={[{ label: "Sí", value: 1 }, { label: "No", value: 0 }]}
            set={(e) => {
              setValues(prev => ({ ...prev, freely_available: e }))
            }}
            value={freely_available}
            size={49}
          />
          <Box
            display="flex"
            w={"100%"}
            placeContent={"flex-end"}
            flexWrap={"wrap"}
            gap={1}
            mt="2"
          >
            <ExportExcelButton handleExportExcel={handleExportExcel} />
            <Tooltip placement="top" hasArrow label="Limpiar Filtro">
              <ButtonStyled onClick={handleResetFilter} bgColor="#03C39A" mt="0" mr="0" wd="50px" hg="35px" >
                <IconGeneric color="#fff" as={TbFilterCancel} />
              </ButtonStyled>
            </Tooltip>
            <ButtonStyled type="submit" mt="0" mr="0" wd="130px" hg="35px" >
              Aceptar
            </ButtonStyled>
          </Box>
        </Box>
      </HeaderAccordion >
      <div>
        <TableOrder
          thead={stadisticsOpeIO}
          data={dataStatistics?.data?.status?.data?.data || []}
          setData={() => { }}
          loading={isLoading}
          tbodyData={TBodyOpeIO}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataStatistics?.data?.status?.data?.last_page}
          loading={isLoading}
        />
      </div>


    </Box>
  )
}

export default EstadisticasOpeIO