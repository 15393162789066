import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiDeviceRecoverFill } from "react-icons/ri";
import { BiShow } from "react-icons/bi";
import { AuthContext } from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { FaUnlock } from "react-icons/fa";
import queryString from "query-string";

const BtnLogPresentado = ({ handlePresent, data }) => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { requestData } = useGlobalContext();
  const { ShowOutput = "" } = queryString.parse(location.search);

  const { mutate } = useMutation({
    mutationFn: requestData
  });

  const handleCorrected = (url, statusProperty = null, callBack = () => { }) => {
    const postData = {
      id: data.id,
      [statusProperty || "status_outputFormCorrected"]: "REVISADO"
    };
    const config = {
      data: postData,
      Endpoint: url,
      PropertyBody: "sendJSONContentPOST"
    };

    mutate(config, {
      onSuccess: (res) => {
        if (res?.data?.status?.code === 400) {
          return openAlert(res?.data?.status?.message, "error");
        }

        if (res?.data?.status?.code === 200) {
          callBack();
          return openAlert(res?.data?.status?.message, "success");
        }
      }
    });
  };

  const handleRevisar = async () => {
    if (pathname === "/FormCorrection") {
      return handleCorrected(
        environment.CHANGE_STATUS_FORMI_CORRECT,
        "status_entryFormCorrected",
        () => {
          navigate(`/CheckCorrectEntry?ShowEntry=${data.id}`);
        }
      );
    } else {
      return handleCorrected(
        environment.CHANGE_STATUS_FORMO_CORRECT,
        null,
        () => {
          navigate(`/CheckCorrectOutput?ShowOutput=${data.id}`);
        }
      );
    }
  };

  const handleClickPresent = async () => {
    handlePresent("RECUPERADO");
  };

  const [isBlocked, setIsBlocked] = useState(false);
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    const helper = async () => {
      const body = new FormData();
      body.append("form_id", ShowOutput || data?.id);
      body.append("type_of", ShowOutput ? "FMMSC" : "FMMIC");
      body.append("type", 4);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
      if (res?.data?.status?.code === 200) {
        setIsBlocked(false);
        console.log('desbloqueado')
      }
      if (res?.data?.status?.code === 400) {
        setIsBlocked(true);
        setMessageError(res?.data?.status?.message)
        console.log('bloqueado')
      }
    }

    helper()
  }, [])

  const showAlert = (title, message, callback = () => { }) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: "Desbloquear",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1e75bb",
      position: "top"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = new FormData();
        body.append("form_id", ShowOutput || data?.id);
        body.append("type_of", ShowOutput ? "FMMSC" : "FMMIC");
        body.append("type", 2);
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
        setIsBlocked(false);
        callback();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  const checkIfItIsBlocked = async (fnParam, ...arg) => {
    const body = new FormData();
    body.append("form_id", ShowOutput || data?.id);
    body.append("type_of", ShowOutput ? "FMMSC" : "FMMIC");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      fnParam(...arg);
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
    }
  }

  return (
    <>

      {authUser.is_qualified_user !== 1 && isBlocked && <Tooltip placement="top" hasArrow label="Desbloquear">
        <span>
          <ButtonHeaderModule
            onClick={() => { showAlert('¿Deseas desbloquear el formulario?', messageError, () => { openAlert('Formulario desbloqueado', 'success') }) }}
            bgColor="#fe8d59"
          >
            <IconGeneric
              className="text-white"
              color="#fff"
              width="16px"
              as={FaUnlock}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>}

      <Tooltip placement="top" hasArrow label="Recuperar">
        <span>
          <ButtonHeaderModule
            onClick={handleClickPresent}
            className="m-2"
            bgColor="#1F7AC3"
            disabled={isBlocked}
          >
            <IconGeneric className=" text-white" as={RiDeviceRecoverFill} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
      {authUser.is_qualified_user !== 1 &&
        (authUser.admin === 1 || authUser.is_commerce === 1) && (
        <Tooltip placement="top" hasArrow label="Revisar">
          <span>
            <ButtonHeaderModule bgColor="#58B158" onClick={handleRevisar} disabled={isBlocked}>
              <IconGeneric className="text-white" as={BiShow} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default BtnLogPresentado;
