/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __CreateDps } from "request/OrderProduction/OrderProduction";
import { ButtonStyled } from "styled/Buttons.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import OrderProductionContext from "context/OrderProductionContext";

export const FormDpsForMatter = ({ dataSelect, onCloseEdit }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const location = useLocation(); // navigate
  const { Orden = "" } = queryString.parse(location.search);
  const optionsBalance = ["%", "Cnt"];
  const [first, setfirst] = useState(null);
  const [supliesCurrent, setsupliesCurrent] = useState(null);
  const [DataSelectSuplies, setDataSelectSuplies] = useState(null);
  const { update, setUpdate, createDps } = useContext(OrderProductionContext);

  const [values, handleInputChange] = useForm({
    quantity: ""
  });

  const { quantity } = values;

  useEffect(() => {
    if (dataSelect) {
      const infoSuplies = dataSelect.map((item) => ({
        value: item?.product_id,
        label: item?.reference_description + " - " + item?.description_product
      }));

      setDataSelectSuplies(infoSuplies);
    }
  }, [dataSelect]);

  const handleSubmit = () => {
    const info = {
      ...values,
      production_order_id: Orden,
      supply_id: supliesCurrent ? supliesCurrent.value : "",
      type: first === "%" ? 0 : first === "Cnt" ? 1 : ""
    };
    if (quantity === "") {
      openAlert(
        `${
          first === "%"
            ? "El porcentaje es requerido"
            : first === "Cnt"
              ? "La cantidad es requerida"
              : "Todos los campos son requeridos"
        }`,
        "error"
      );
    } else if (supliesCurrent === null) {
      openAlert("Seleccione un insumo", "error");
    } else {
      createDps(info);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: "100%" }}>
          <InputSelect
            tag={"Materia prima o insumo"}
            data={DataSelectSuplies}
            set={setsupliesCurrent}
            value={supliesCurrent}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: "15%" }} className="mt-4">
          <RadioBtn setOnly={setfirst} options={optionsBalance} state={first} />
        </div>
        <LabelGroup
          name={"quantity"}
          value={quantity}
          onChange={handleInputChange}
          tag={
            first && first === "%"
              ? "Porcentaje"
              : first === "Cnt"
                ? "Cantidad"
                : "Porcentaje/Cantidad"
          }
          size={35}
          tipo={"text"}
          desicion={first === null}
        />
        <div style={{ width: "50%" }}></div>
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseEdit}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Guardar
        </ButtonStyled>
      </Flex>
    </>
  );
};
