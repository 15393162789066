/* eslint-disable no-unused-vars */
import { SimpleGrid, Tooltip } from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import {
  InputFromUserToUser,
  LabelGroup
} from "pages/RefProductInter/LabelGroup";
import React, { useContext, useState, useEffect } from "react";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { Title } from "styled/Title";
import { CheckDisable } from "hooks/useLocalStorage";
import { ModalGeneric } from "components/modal/ModalGeneric";

import { ButtonStyled } from "styled/Buttons.styled";
import TableOrderHover from "components/Tables/TableOrderHover";
import Pagination from "components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { SearchFmmO } from "./SearchFmmO";
import InputSelect from "components/InputSelect/InputSelect";

import environment from "constants/apiConst";
import { __PostForm } from "request/Petitions/__Post";
import { IconGeneric } from "styled/Icons.styled";
import { RiSearch2Line } from "react-icons/ri";
import Tbody from "./SearchFmmo/Tbody";
import { thead } from "./SearchFmmo/ArrayHead";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import GlobalContext from "context/GlobalContext";
import { Regex } from "utils/Regex";

const FromUserToUser = ({
  handleInputChange,
  values,
  setValues,
  formOperation
}) => {
  const {
    userQualified,
    valueQualifiedForm,
    setValueQuialifiedForm,
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    dataItemDispachSelect,
    valueQualified
  } = useContext(FormCrudCRUDContext);

  const { user_Qualified } = useContext(GlobalContext);

  const [showTableData, setShowTableData] = useState(false);
  const [data, setData] = useState([]);
  const [dataUc, setDataUc] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const userQualifiedInf = useSelector(
    (state) => state.auth.dataUser.userQualified
  );

  const { num_exit_form, num_commercial_invoice, integration_certificate } =
    values;

  const handleResData = async (values, flag) => {
    try {
      const res = await __PostForm(
        environment.SEARCH_FMM0_ENTRY,
        token,
        values
      );
      const resData = res?.data?.status;
      if (resData?.code === 403) {
        return openAlert(resData?.message, "error");
      }
      if (resData.code === 200) {
        if (!flag) {
          setData(res?.data?.status?.data?.data);
        } else {
          const resp = res?.data?.status?.data?.data[0];
          if (resp) {
            setValues({
              ...values,
              num_exit_form: resp.output_form_code,
              num_commercial_invoice: resp.output_form_commercial_invoice,
              integration_certificate: resp.integration_certificate
            });
          } else {
            setValues({
              ...values,
              num_exit_form: "",
              num_commercial_invoice: "",
              integration_certificate: resp.integration_certificate
            });
            openAlert(
              "No existe un formulario con este código de salida",
              "error"
            );
          }
        }
        setMaxPage(res?.data?.status?.data?.last_page);
        setShowTableData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formItemsDispacth = () => {
    return (
      <SearchFmmO
        onClose={onCloseSearchItem}
        loading={loading}
        handleSearch={handleResData}
      />
    );
  };

  const [FormModalSearch, setFormModalSearch] = useState(formItemsDispacth);

  useEffect(() => {
    if (userQualified) {
      const infoUsuarios = userQualified.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setDataUc(infoUsuarios);
    }
  }, [userQualified]);

  useEffect(() => {
    if (dataItemDispachSelect) {
      const { output_form_code, output_form_commercial_invoice, integration_certificate } =
        dataItemDispachSelect;

      console.log({ output_form_code, output_form_commercial_invoice, integration_certificate });
      setValues({
        ...values,
        num_exit_form: output_form_code,
        num_commercial_invoice: output_form_commercial_invoice,
        integration_certificate
      });
    }
  }, [dataItemDispachSelect]);

  const handleBlur = () => {
    const formData = new FormData();
    if (authUser.is_qualified_user === 1) {
      formData.append("qualified_user_2_id", userQualifiedInf);
    }
    if (user_Qualified && authUser.is_qualified_user !== 1) {
      formData.append("qualified_user_2_id", user_Qualified);
    }

    formData.append(`code_form`, num_exit_form || null);

    handleResData(formData, 1);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchItem}
        onOpen={onOpenSearchItem}
        onClose={onCloseSearchItem}
        title="Buscar"
        size="4xl"
      >
        {/* <ChangeItems Orderid={Orden} UserId={qualified_users_id} /> */}
        {showTableData
          ? (
            <div>
              <ButtonStyled onClick={() => setShowTableData(false)}>
              Buscar nuevamente
              </ButtonStyled>
              <TableOrderHover
                thead={thead}
                data={data}
                setData={setData}
                loading={loading}
                onCloseModal={onCloseSearchItem}
                tbodyData={Tbody}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )
          : (
            FormModalSearch
          )}
      </ModalGeneric>

      <div
        className="mx-1 shadow-sm p-1 mb-4 "
        style={{ background: "#f2f2f2" }}
      >
        <Title size={"18px"} weight={700} className="mb-2">
          De Usuario a Usuario de la misma Zona Franca
        </Title>

        <SimpleGrid minChildWidth="200px" spacing="10px">
          <div>
            <InputSelect
              tag={"Nit"}
              data={dataUc}
              set={setValueQuialifiedForm}
              value={valueQualifiedForm}
            />
          </div>

          <div className="mt-1">
            <LabeFormlStyled htmlFor="num_exit_form">
              Nº Formulario de Salida
            </LabeFormlStyled>
            <div className="d-flex">
              <InputFormStyled
                type="text"
                onBlur={handleBlur}
                name={"num_exit_form"}
                value={num_exit_form || ""}
                id="term"
                onChange={(e) => handleRegex(e, "Números", Regex?.numeros)}
                disabled={!valueQualifiedForm}
                maxLength={MaxLength.formEntry.formEntryNumber.num_exit_form}
              />
              {valueQualifiedForm
                ? (
                  <div onClick={onOpenSearchItem} className="ms-1">
                    <IconGeneric color="#313131" as={RiSearch2Line} />
                  </div>
                )
                : null}
            </div>
          </div>

          {/* <div className="mt-1">
            <LabeFormlStyled htmlFor="num_commercial_invoice">
              Nº Factura comercial
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              onBlur={handleBlur}
              name={"num_commercial_invoice"}
              value={num_commercial_invoice ? num_commercial_invoice : ""}
              onChange={handleInputChange}
              disabled={true}
            />
          </div> */}
        </SimpleGrid>

        <div className="mt-1">
          <LabeFormlStyled htmlFor="num_commercial_invoice">
            Nº Factura comercial{" "}
            <span style={{ color: "red" }}>
              {formOperation?.reentry_operation === 0 ? " *" : ""}
            </span>
          </LabeFormlStyled>
          <InputFormStyled
            type="text"
            onBlur={handleBlur}
            name={"num_commercial_invoice"}
            value={num_commercial_invoice || ""}
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            maxLength={
              MaxLength.formEntry.formEntryNumber.num_commercial_invoice
            }
          />
        </div>
        <SimpleGrid minChildWidth="200px" spacing="10px">
          <div className="mt-1">
            <LabeFormlStyled htmlFor="integration_certificate">
              Cert. Integración
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              onBlur={handleBlur}
              name={"integration_certificate"}
              value={integration_certificate || ""}
              onChange={handleInputChange}
              disabled={true}
            />
          </div>
        </SimpleGrid>
      </div>
    </>
  );
};

export default FromUserToUser;
