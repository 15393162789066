import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __Operations = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.OPERATIONS_GET}?page=${page || 1}`, options);
  return res;
};

export const __OperationList = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.OPERATIONS_All}`, options);
  return res;
};

export const __OperationListId = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };
  const res = await axios(
    `${env.OPERATION_LIST_ID}${id !== "" ? "/" : ""}${id !== "" ? id : ""}`,
    options
  );
  return res;
};

export const __OperationListCorrected = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };
  const res = await axios(
    `${env.OPERATION_LIST_CORRECTED}${id !== "" ? "/" : ""}${
      id !== "" ? id : ""
    }`,
    options
  );
  return res;
};

export const __ShowOperation = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.OPERATIONS_VIEW}/${id}`, options);
  return res;
};

export const __OperationListExit = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.OPERATIONS_EXIT}${id !== undefined ? "/" : ""}${
      id !== undefined ? id : ""
    }`,
    options
  );
  return res;
};
