import styled from "styled-components/macro";

export const Title = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => props.size || "20px"};
  font-weight: 700;
  color: ${(props) => props.color || "#171a22"};
  text-align: left;
`;

export const TitleBorderBottom = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => props.size || "20px"};
  font-weight: 700;
  color: #171a22;
  border-bottom: 1px solid #171a22;
`;

export const SubTitle = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => props.size || "20px"};
  font-weight: ${(props) => props.weight || 500};
  color: #616161;
`;

export const TitlePDF = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => props.size || "24px"};
  font-weight: ${(props) => props.weight || 500};
  color: #313131;
`;

export const SubTitlePDF = styled.h1`
  font-family: "Roboto";
  font-size: ${(props) => props.size || "24px"};
  font-weight: ${(props) => props.weight || 500};
  color: #616161;
`;
