import axios from 'axios';

import env from '../constants/apiConst';

export const __Me = async (token) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(env.ME, options);
  return res;
};

export const __ChangePassword = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(env.CHANGE_PASSWORD, options);
  return res;
};

export const __UpdateDataUser = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(env.UPDATE_DATA_USER, options);
  return res;
};

export const __UpdateDataImage = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(env.UPDATE_DATA_IMAGE, options);
  return res;
};
