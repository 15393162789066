import { useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import ReviewsHeader from "./ReviewsHeader";
import Pagination from "components/Pagination/Pagination";
import TbodyReviews from "./TbodyReviews";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { __SearchReviews } from "request/Reviews/__GetTReviews";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import { CardReviews } from "../Cards/CardReviews/CardReviews";
import { DivCard } from "../Styles/Styled";
import { Divider } from "@chakra-ui/react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { thead, theadCI } from "./thead/thead";

const TableReviews = () => {
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [myPermission, setmyPermission] = useState(null);

  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [card, setCatd] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [recient, setRecient] = useState("");
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  useEffect(() => {
    GetReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movement, freeZone_id]);

  const GetReviews = async () => {
    setLoading(true);
    let SelectGet;

    if (movement === "Ingreso") {
      SelectGet = environment.GET_Reviews_Entry;
    }

    if (movement === "Salida") {
      SelectGet = environment.GET_Reviews_Exit;
    }

    if (movement === "Revisión Certificado de integración") {
      SelectGet = environment.GET_Reviews_Integration;
    }

    try {
      const res = await __SearchReviews(SelectGet, token, page);
      const status = res?.data?.status;

      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else if (movement === "Ingreso" || movement === "Salida") {
        setCatd(res?.data?.status?.data?.cards);
        setResData(res?.data?.status?.data?.rest?.data);
        setRecient(res?.data?.status?.data?.rest?.data.length);
        setMaxPage(res?.data?.status?.data?.rest?.last_page);
      }
      if (movement === "Revisión Certificado de integración") {
        setResData(res?.data?.status?.data?.data);
        setRecient(res?.data?.status?.data?.data?.length);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_rol, allAccess, admin, freeZone_id]);

  return (
    <>
      <HeaderSearch>
        {movement !== "Revisión Certificado de integración" ? (
          <>
            <DivCard>
              {card.map((x, index) => {
                return (
                  <CardReviews
                    key={index}
                    data={x}
                    color="rgba(3, 195, 154, 1)"
                    title={x?.name_qualified.substring(0, 16)}
                    items={x?.items}
                    iconSelect={x.items}
                    code={x?.code_operation}
                    nit={x?.nit_qualified}
                    // typeuser={x?.name_activity.substring(8, 40)}
                    date={x?.date_submitted}
                    form_id={x?.form_id}
                    movement={movement}
                    status={
                      movement === "Ingreso"
                        ? x?.status_entryForm
                        : x?.status_outputForm
                    }
                  />
                );
              })}
            </DivCard>
            <Divider
              color="rgba(237, 237, 237, 1)"
              marginBottom={10}
              marginTop={4}
            />
          </>
        ) : (
          <></>
        )}

        <ReviewsHeader title={"Todos los documentos"} mt="mt-5" />
        <TableOrder
          thead={theadCI}
          data={resData}
          isNum={false}
          setData={setResData}
          loading={loading}
          tbodyData={TbodyReviews}
        />
        <Pagination
          thead={thead}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default TableReviews;
