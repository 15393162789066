/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import FormQualificationU from "../forms/FormQualificationU";
import React, { useContext, useEffect, useState } from "react";
import TbodyQualificationU from "../tables/TbodyQualificationU";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { FormShowQualificationU } from "../FormShow/FormQualification";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __QualificationUser } from "request/configuration/__QualificationU";

const formshowqualification = () => {
  return <FormShowQualificationU />;
};

const FormQualification = () => {
  return <FormQualificationU />;
};

export const QualificationU = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);

  const { setRequestEdit, setRequestCreate, setRequestDelete, setFormEdit, setFormShow, update } =
    useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Clasificación", order: "name_qualificationQualifiedUser" },
    { name: "Descripción", order: "description_qualificationQualifiedUser" },
    { name: "¿Activo?", order: "status_qualificationQualifiedUser" },
    { name: "Acciones", order: "actions_qualificationQualifiedUser" }
  ];

  useEffect(() => {
    setFormEdit(FormQualification);
    setFormShow(formshowqualification);
    getQualificationUser();
  }, [update, page]);

  const getQualificationUser = async () => {
    setPage(1);
    setLoading(true);
    setRequestEdit(environment.QUALIFICATION_USER_UPDATE);
    setRequestCreate(environment.QUALIFICATION_USER_CREATE);
    setRequestDelete(environment.QUALIFICATION_USER_DELETE);
    try {
      const res = await __QualificationUser(token, page);
      setCurrentPage(res.data.status.data.data);
      setMaxPage(res.data.status.data.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormEdit(FormQualification);
    setFormShow(formshowqualification);
    searchQualificationUser();
  }, [q]);

  const searchQualificationUser = async () => {
    const formData = new FormData();
    formData.append("search", q);
    setPage(1);
    setLoading(true);
    setRequestEdit(environment.QUALIFICATION_USER_UPDATE);
    setRequestCreate(environment.QUALIFICATION_USER_CREATE);
    setRequestDelete(environment.QUALIFICATION_USER_DELETE);
    try {
      const res = await __QualificationUser(token, formData, page);
      console.log(res);
      setCurrentPage(res.data.status.data.data);
      setMaxPage(res.data.status.data.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Clasificación de usuarios calificados"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyQualificationU}
        />
        <Pagination page={page} setPage={setPage} maxPage={maxPage} loading={loading} />
      </SectionContainerGene>
    </>
  );
};
