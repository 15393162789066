/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import TableOrder from "components/Tables/TableOrder";
import {
  TitleMovementDispatch,
  TitleMovementInt
} from "./ArrayHeadTable/TheadMovementInt";
import Pagination from "components/Pagination/Pagination";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  __EntryMovementGet,
  __SearchSimpleDispatch,
  __SearchSimpleDispatchPass,
  __SearchSimpleEntry,
  __SearchSimpleEntryPass
} from "request/Pases/__CrudPass";
import { AuthContext } from "context/AuthContext";
import TBodyEntryMovement from "../tbody/TBodyEntryMovement";
import bro from "../../../assets/bro.png";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __DispatchAg } from "request/DespachoAgrupado/__DispatchAg";
import PassCrudContext from "context/PassCrudContext";
import environment from "constants/apiConst";
import { useForm } from "hooks/useForm";
import "../stilo.css";
import { ModalSearch } from "./BusquedaAvanzada/ModalSearch";
import { FormAdvanced } from "./BusquedaAvanzada/FormAdvanced";
import { useDispatch, useSelector } from "react-redux";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormReturnReason } from "../FormReturnReason";
import { handleLogout } from "components/Logout/FuncionLogout";
import { __PostJsonData } from "request/Petitions/__Post";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";

export const EntryMovementReviw = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["plate", "identification", "observations", "formNumber", "container", "endDate", "endTime", "startDate", "startTime", "commercial_invoice", "transport_document", "operation_cod", "associated_form", "stateDispacthOrPass", "datosBy", "associated", "typeTransporSelec", "user_Qualified", "weighin"], nestedProp: "searchAdvance" });

  const { search, valueQualified } = state;



  const { authUser } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const {
    setRequestChangeStatus,
    update,
    setRequestBulkLoad,
    setShowFormDispacthItem,
    setDataItemDispachSelect,
    seteditItemDispatch,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    setRequestObtenerItemPass
  } = useContext(PassCrudContext);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);




  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);

  const resetPages = () => {
    setPage(1);
    setPageSearch(1);
  };

  const data = useMemo(() => {

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && { qualifiedUser_id: valueQualified.value }),
      ...cleanState,
    }

    return searchInfo
  }, [valueQualified, search, cleanState]);

  const { requestData } = useGlobalContext();

  useEffect(() => {
    return async () => resetPages();
  }, [movement]);


  useEffect(() => {
    setRequestObtenerItemPass(
      movement !== "Rev. Pase de entrada"
        ? environment.GET_ITEM_DISPACTH_EXIT
        : environment.OBTENER_ITEM_ENTRADA_PASES_INT
    );

    setRequestChangeStatus(
      movement !== "Rev. Pase de entrada"
        ? environment.CHANGESTATUSDISPACTH
        : environment.CHANGESTATUSPASS
    );
    setRequestBulkLoad(
      movement !== "Rev. Pase de entrada"
        ? environment.IMPORT_BULKLOAD_DISPATC
        : environment.IMPORT_BULKLOAD
    );

    setShowFormDispacthItem(false);
    setDataItemDispachSelect(null);
    seteditItemDispatch(false);
  }, [update, movement, page, freeZone_id, authUser]);

  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);

  const initialEndpoint = {
    "Rev. Pase de entrada": environment.GET_PASES_INT_CO,
    "Rev. Pase de salida": environment.GET_DISPATCH_CO,
  }[movement];

  const searchEndpoint = {
    "Rev. Pase de entrada": environment.GET_PASES_INT_CO,
    "Rev. Pase de salida": environment.GET_DISPATCH_CO,
  }[movement];


  const { data: dataPases, isFetching } = useQuery({
    queryKey: Object.entries(data).length === 0 ? ["reviewPases", page, freeZone_id, movement, update] : ["reviewPasesSearch", pageSearch, freeZone_id, movement, data, update],
    queryFn: () => Object.entries(data).length === 0 ?
      requestData({
        Endpoint: initialEndpoint,
        page,
        method: "POST",
      }) :
      requestData({
        Endpoint: searchEndpoint,
        page,
        method: "POST",
        data
      })
  });

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  return (
    <>
      <ModalSearch
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Búsqueda avanzada"}
      >
        <FormAdvanced onClose={onClose} />
      </ModalSearch>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={PassCrudContext}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>{movement}</Title>
        <SearchAdvancedModule
          onOpenSearch={onOpen}
          changeViewSearch={Object.entries(data).length !== 0}
          backSearch={() => {
            clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
            resetPages();
          }}
          permisoSearch={
            movement === "Rev. Pase de entrada"
              ? "getEntryPassesSearch"
              : "getDispatchPassesSearch"
          }
          allAccess={allAccess}
          viewButtonFilter={true}
          myPermission={myPermission}
          movement={movement}
        />

        {/* || authUser.is_commerce === 1 */}
        <TableOrder
          thead={
            movement === "Rev. Pase de entrada"
              ? TitleMovementInt
              : TitleMovementDispatch
          }
          data={dataPases?.data?.status?.data?.data}
          setData={() => { }}
          loading={isFetching}
          tbodyData={TBodyEntryMovement}
        />

        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataPases?.data?.status?.data?.last_page}
          loading={isFetching}
        />

      </HeaderSearch>
    </>
  );
};
