import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import InputSelect from 'components/InputSelect/InputSelect'
import environment from 'constants/apiConst'
import { AuthContext } from 'context/AuthContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useDebounce } from 'hooks/useDebouce'
import { useForm } from 'hooks/useForm'
import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import useTransformOptions from 'hooks/useTransformOptions '
import { IconGeneric } from 'styled/Icons.styled'
import { GoSearch } from 'react-icons/go'
import { isArray } from 'utils/type-check-utils'

const ProductSearchBar = ({
  label = '',
  required = false,
  manualId = null,
  set = null,
  callBack = null,
  IsQualifiedUserEnabled = true,
  onValueChange = () => {},
  ...attrs
}) => {
  const { requestData, user_Qualified } = useGlobalContext()
  const [ProductData, setProductData] = useState({ value: null, label: null })
  const { authUser } = useContext(AuthContext)

  const [values, handleInputChange, reset, setValues] = useForm({
    search: ''
  })

  const SearchDebounce = useDebounce(values?.search, 700)
  const userQualifiedInf = useSelector(
    state => state.auth.dataUser.userQualified
  )

  const userQualified = user_Qualified || userQualifiedInf
  const [openAlert] = AlertErrorAlert()

  const handleResponseItem = res => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      return setProductData(res?.data?.status?.data)
      // return setUpdate('')
    }
  }

  const { isPending, mutate: MutateProduct } = useMutation({
    mutationFn: requestData,
    onSuccess: data => handleResponseItem(data)
  })

  const searchProduct = search => {
    const formdata = new FormData()
    formdata.append('search', search)

    MutateProduct({
      pageParam: null,
      resourceIdentifier: manualId || userQualified,
      data: formdata,
      Endpoint: environment.PRODUCTSREFERENCEUSERAALL,
      PropertyBody: 'sendFormDataContentPOST'
    })
  }
  const options = useTransformOptions(
    ProductData,
    'code_product',
    'id',
    'description_product',
    true
  )

  const handleInput = inputValue => {
    setValues({ search: inputValue })
  }

  const handleSelectChange = selectedOption => {
    const hasProductData = ProductData || []

    const index =
      isArray(hasProductData) &&
      hasProductData?.findIndex(item => item.id === selectedOption?.value)

    set && set(selectedOption)
    callBack &&
      callBack(hasProductData, selectedOption, hasProductData?.[index])
  }

  useEffect(() => {
    if (SearchDebounce?.length >= 3) {
      if (
        authUser.is_qualified_user !== 1 &&
        !user_Qualified &&
        IsQualifiedUserEnabled
      ) {
        openAlert('Debes seleccionar un usuario calificado', 'error')
        return
      }
      searchProduct(SearchDebounce)
    }
  }, [SearchDebounce])

  const onChangeManager = (...attrs) => {
    handleSelectChange(...attrs)
    onValueChange(...attrs)
  }

  return (
    <>
      <InputSelect
        components={{
          // IndicatorSeparator: () => null, // Oculta el separador de indicadores
          DropdownIndicator: () => (
            <IconGeneric
              style={{
                margin: '7px'
              }}
              color={'black'}
              width='16px'
              as={GoSearch}
            />
          )
        }}
        required={required}
        tag={label}
        onInputChange={handleInput}
        onChange={onChangeManager}
        data={options}
        isLoading={isPending}
        noOptionsMessage={() => 'Escribe el código del producto...'}
        {...attrs}
      />
    </>
  )
}

export default ProductSearchBar
