import React, { useEffect, useState } from "react";
import { Flex, Spacer, Switch } from "@chakra-ui/react";
import environment from "constants/apiConst";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Cancelar, Continuar } from "pages/RefProductInter/Stilos";
import { useSelector } from "react-redux";
import { OnlyNumberInput } from "components/Funciones/OnlyNumberInput";
import { LabeFormlStyled } from "styled/Input.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import { __GetDataId } from "request/Petitions/__Get";

export const FormAddWeighinhMachine = ({
  onCloseAdd,
  setUpdate,
  update,
  dataToEdit,
  setDataToedit,
  Status,
  setStatus
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [statusWeighingMachine, setStatusWeighingMachine] = useState(false);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    name: "",
    baud_rate: "",
    data_bits: "",
    parity: "",
    stop_bits: "",
    flowcontrol: "",
    direction: "",
    status: ""
  });

  const {
    name,
    baud_rate,
    data_bits,
    parity,
    stop_bits,
    flowcontrol,
    direction,
    status
  } = values;

  // Validaciones Checks
  const changeCheck = (e) => {
    handleInputChange(e);
    if (e.target.name === "status") {
      setStatusWeighingMachine(e.target.checked);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name || "");
    formData.append("baud_rate", baud_rate || "");
    formData.append("data_bits", data_bits || "");
    formData.append("parity", parity || "");
    formData.append("stop_bits", stop_bits || "");
    formData.append("flowcontrol", flowcontrol || "");
    formData.append("direction", direction || "");
    formData.append("status", statusWeighingMachine ? 1 : 0);
    if (dataToEdit && Status === true) {
      formData.append("id", dataToEdit.id ? dataToEdit.id : "");
      createData(environment.UPDATE_WEIGHING, formData);
    } else {
      createData(environment.CREATE_WEIGHING, formData);
    }
  };

  const createData = async (endpoint, formData) => {
    try {
      const data = await __Post(endpoint, token, formData);
      if (data?.data?.status?.code === 200) {
        openAlert(
          dataToEdit
            ? "Bascula actualizada exitoxamente"
            : "Bascula creada exitoxamente",
          "success"
        );
        setUpdate(!update);
        onCloseAdd();
        setStatus(false);
        setDataToedit();
        reset();
      } else {
        openAlert("Hubo un error", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateWeighingMachine = async () => {
    try {
      const res = await __GetDataId(
        environment.SHOW_WEIGHING,
        token,
        dataToEdit.id
      );
      setValues(res?.data?.status?.data);
      setStatusWeighingMachine(res?.data?.status?.data?.status);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      updateWeighingMachine();
    } else {
      setValues(values);
    }
  }, []);

  const Close = () => {
    onCloseAdd();
    setStatus(false);
    setDataToedit();
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit(e);
    }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"name"}
          value={name}
          onChange={handleInputChange}
          tag={"Nombre de la báscula"}
          size={100}
          tipo={"text"}
          required={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"baud_rate"}
          value={baud_rate}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          tag={"Tasa de baudios"}
          size={45}
          tipo={"text"}
          required={true}
        />

        <LabelGroup
          name={"data_bits"}
          value={data_bits}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          tag={"Bits de datos"}
          size={45}
          tipo={"text"}
          required={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"parity"}
          value={parity}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          tag={"Paridad"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"stop_bits"}
          value={stop_bits}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          tag={"Bits de parada"}
          size={45}
          tipo={"text"}
          required={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"flowcontrol"}
          value={flowcontrol}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          tag={"control de flujo"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"direction"}
          value={direction}
          onChange={handleInputChange}
          tag={"Dirección"}
          size={45}
          tipo={"text"}
          required={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div>
          <LabeFormlStyled
            htmlFor="reentry_operation"
            style={{
              width: "100px",
              marginBottom: "6px"
            }}
          >
            Activo
          </LabeFormlStyled>
          <div>
            <Switch
              name="status"
              id="status"
              size="lg"
              value={status}
              onChange={changeCheck}
              isChecked={statusWeighingMachine}
            />
          </div>
        </div>
      </div>
      <Flex className="m-3">
        <Cancelar onClick={Close}>Cancelar</Cancelar>
        <Spacer />
        <Continuar type="submit">
          {Status === true ? "Actualizar" : "Generar"}
        </Continuar>
      </Flex>
    </form>
  );
};
