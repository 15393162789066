import { Icon } from '@chakra-ui/react';
import styled from 'styled-components/macro';

export const LogoutIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => props.theme.colors.mainAccent};
  font-size: 1.3em;
  display: inline-block;
`;

export const LogoutTag = styled.span`
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => props.theme.colors.mainAccent};
  margin-left: 3rem;
`;
