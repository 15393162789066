/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __ShowType } from "request/configuration/__ActivityUser";
import { ButtonStyled } from "styled/Buttons.styled";

const FormActivityUser = () => {
  const [status, setStatus] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    name_activity: "",
    description_activity: "",
    status_activity: status
  });
  const { name_activity, description_activity } = values;

  const edit = async () => {
    try {
      const res = await __ShowType(token, dataToEdit.id);
      setValues(res.data?.status?.data);
      setStatus(res.data?.status?.data?.status_activity);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);


  const handleSubmit = () => {
    if (name_activity === "") {
      return openAlert("Ingrese el nombre de la Licencia", "error");
    }
    if (description_activity === "") {
      return openAlert("Ingrese la descripción", "error");
    }
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_activity: status
      };
      return editData(data);
    }
    const data = {
      ...values
    };
    return createData(data)
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Actividad De Usuario
      </Text>
      <Divider mb={1} />
      <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar Actividad de usuarios calificado"
          : "Crear Actividad de usuarios calificado"}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            name={"name_activity"}
            tag={"Nombre"}
            size={45}
            value={name_activity}
            onChange={handleInputChange}
            desicion={false}
            maxLength={191}
          />
          <LabelGroup
            name={"description_activity"}
            tag={"Descripción"}
            size={45}
            value={description_activity}
            onChange={handleInputChange}
            desicion={false}
            maxLength={5000}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          {dataToEdit && (
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag={"¿Activo?"}
              size={45}
            />
          )}
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormActivityUser;
