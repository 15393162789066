const localPermissions = {
  Entryform: {
    update: ['updateEntryForm'],
    create: ['createEntryForm'],
    changeStatus: ['changeStatusEntryForm'],
    history: ['getHistoryForm'],
    copy: ['copyEntryForm'],
    exportExcel: ['exportExcelEntryPasses'],
    balanceAdjustment: ['createEntryFormCorrected'],
    notifyToAuthorize: ['saveReviewAprovedEntryFormToAuthorize'],
    createPass: ['createEntryPass']
  },
  Outputform: {
    update: ['updateOutputForm'],
    create: ['createOutputForm'],
    changeStatus: ['changeStatusOutputForm'],
    copy: ['copyOutputForm'],
    balanceAdjustment: ['createOutputFormCorrected'],
    createPass: ['createDispatchPass']
  },
  EntryPass: {
    update: ['updateEntryPass'],
    create: ['createEntryPass'],
    changeStatus: ['changeStatusEntryPass']
  },
  OutputPass: {
    update: ['updateDispatchPass'],
    create: ['createDispatchPass'],
    changeStatus: ['changeStatusDispatchPass']
  },
  ProductOrder: {
    update: ['updateProductOrder'],
    create: ['createProductOrder'],
    changeStatus: ['changeStatusProductOrder']
  }
}

export { localPermissions }
