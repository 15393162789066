import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useState } from 'react'

/**
 * Hook personalizado para manejar archivos adjuntos.
 * @param {number} maxSizeBytes - El tamaño máximo permitido en bytes.
 * @param {string[]} allowedFileTypes - Los tipos de archivo permitidos.
 * @returns {[function, any[]]} - La función para manejar los archivos adjuntos y el array de archivos adjuntos seleccionados.
 */

const useAttachmentHandler = (maxSizeBytes, allowedFileTypes) => {
  const [openAlert] = AlertErrorAlert()
  const [attachedUpload, setAttachedUpload] = useState([])
  const [typeDocument, setTypeDocument] = useState('Desconocido')

  const handleAttachment = e => {
    const { files } = e.target

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const fileType = file.type
      const fileName = file.name

      if (!isFileTypeAllowed(fileType)) {
        openAlert(
          `El archivo ${fileName} no es un tipo de archivo permitido.`,
          'error'
        )
      } else if (file.size > maxSizeBytes) {
        openAlert(
          `El archivo ${fileName} excede el límite permitido de Megabytes (${
            maxSizeBytes / 1000000
          } MB).`,
          'error'
        )
      } else {
        setAttachedUpload(prevAttachedUpload => [...prevAttachedUpload, file])
      }
    }
  }

  function getFileContentType (fileType) {
    if (fileType.startsWith('image/')) {
      return 'Image'
    } else if (
      fileType.startsWith('application/') ||
      fileType.startsWith('text/')
    ) {
      return 'Documento'
    } else {
      return 'Desconocido'
    }
  }

  function isFileTypeAllowed (fileType) {
    setTypeDocument(getFileContentType(fileType))
    return allowedFileTypes.includes(fileType)
  }

  const resetFile = () => {
    setAttachedUpload([])
  }

  return { handleAttachment, attachedUpload, resetFile, typeDocument }
}

export default useAttachmentHandler
