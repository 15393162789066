import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";

export const TabsAdvanced = ({ handleCambio, arrayHead, children, defaultIndex }) => {
  return (
    <>
      <Tabs
        isFitted
        variant="enclosed"
        onChange={(index) => handleCambio(index)}
        defaultIndex={defaultIndex}
      >
        <TabList mb="1em">
          {arrayHead &&
            arrayHead.map((items, index) => <Tab key={index}>{items}</Tab>)}
        </TabList>
        <TabPanels>{children}</TabPanels>
      </Tabs>
    </>
  );
};
