import styled from "styled-components/macro";
import { Icon } from "@chakra-ui/react";

export const HeaderStyled = styled.header`
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  padding: 1rem;
  /* width: calc(100% - 220px); */
  width: ${(props) => props.wd || "100%"};

  height: 50px;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000016;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    max-width: 90%;
  }
  @media (min-width: 1024px) {
    max-width: 95%;
  }
  @media (min-width: 1440px) {
    max-width: 95%;
  }
`;

export const HeaderSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  padding: ${(props) => props.padding || "0"};
  align-items: center;
  /* border: 1px solid black; */
`;

export const HeaderIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => props.color || "#000"};
  width: ${(props) => props.wd || "24px;"};
  height: 24px;
  display: inline-block;
  margin: 0 0.5rem;
`;

export const IconNotification = styled.button`
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => props.color || "#000"};
  width: 24px;
  height: 24px;
  position: relative;
`;

export const BalanceSection = styled.div`
  /* display: grid; */
  flex-direction: column;
  justify-content: center;
  /* grid-template-areas: "pesaje pesaje pesaje pesaje documento documento documento documento";
  grid-template-rows: repeat(auto-fit, 20px); */
`;

export const TitleBalance = styled.h1`
  font-size: ${(props) => props.font || "0.8em"};
  font-weight: ${(props) => props.weight || ""};
  color: ${(props) => props.color || "black"};
  margin-right: 5px;
`;

export const ValueBalance = styled.h4`
  color: ${(props) => props.color || "black"};
`;

export const TrmSection = styled.div`
  display: grid;
  /* flex-direction: column;
  justify-content: center; */
  /* grid-template-areas: "pesaje pesaje pesaje pesaje documento documento documento documento"; */
  /* grid-template-rows: repeat(auto-fit, 20px); */
`;

export const ProfileItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  border: 1.3px solid #007fff;
  border-radius: 25px;
  padding: 2px 0;
  margin: 10px auto;
`;

export const TitleProfile = styled.h1`
  color: #007fff;
`;
