import React from "react";
import { Field } from "formik";
import { BiUserCircle } from "react-icons/bi";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

const FormField = ({ name, placeholder, size, autocomplete, type }) => {
  return (
    <div className="w-100%">
      <Field name={name}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <InputGroup size={size}>
              <Input
                id={name}
                {...field}
                size={size}
                type={type}
                variant="outline"
                className="form-control"
                placeholder={placeholder}
                autoComplete={autocomplete}
              />
              <InputRightElement width="3rem">
                <BiUserCircle color="#9097BE" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default FormField;
