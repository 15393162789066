/* eslint-disable no-unused-vars */
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import ModalCopy from "components/modal/ModalCopy";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import { AuthContext } from "context/AuthContext";
import OrderProductionContext from "context/OrderProductionContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import {
  cabeceraOrdens,
  cabeceraSelect
} from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  __GetOrderProducts,
  __GetOrderProductsSearch
} from "request/OrderProduction/OrderProduction";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftOrder,
  arrayProductionOrderNodeList,
  arrayProductionOrderNodeListNoUC,
  arrayProductionOrderNodeListUC,
  arrayRigthOrder,
  arrayRigthUserOrder,
  productionOrdenNodeInputFields
} from "./ArrayOrder/ArrayExportExcel";
import { cabeceraPrincipal } from "./ArrayOrder/ArrayTableOrder";
import { CreateCI } from "./CreateCI";
import Body from "./CreateNewProductoOrder/Table/Body";
import { useRegexState } from "hooks/useRegexState";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray, safelyConvertToString } from "utils/type-check-utils";

import { clearSearch, setSearch } from "redux/actions/searchActions";

export const ProductionOrder = () => {
  const { authUser } = useContext(AuthContext);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState([]);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const dispatchRedux = useDispatch();

  const {
    update,
    isOpenCopy,
    onCloseCopy,
    dataToCopy,
    copyData,
    exportColumnBy,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenCreateCIntegration,
    onOpenCreateCIntegration,
    onCloseCreateCIntegration
  } = useContext(OrderProductionContext);

  const changePage = (index) => {
    setPage(1);
    setMaxPage(currentPage ? currentPage[index]?.last_page : 1);
  };

  const backSearch = () => {
    reset();
    setSearchAdvanced([]);
    setChangeViewSearch(false);
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
  });

  const { searchInformacion, valueQualified } = values;

  const Obtener = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __GetOrderProducts(token, page);
      const data = res?.data?.status;
      if (data.code === 200) {
        setCurrentPage(data.data);
      }
      // console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [token, page]);

  const Search = useCallback(
    async (informacion) => {
      setLoading(true);
      try {
        const res = await __GetOrderProductsSearch(token, informacion);
        const data = res?.data?.status?.data?.data;
        setCurrentPageTwo(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const newInfo = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(isArray(valueQualified) && valueQualified?.length !== 0
        ? { qualifiedUser_id: valueQualified?.map((item) => item?.value) }
        : valueQualified?.value && { qualifiedUser_id: valueQualified?.value })
    };

    if (Object.keys(newInfo).length !== 0) {
      dispatchRedux(setSearch(newInfo));
      Search(newInfo);
      setChangeViewSearch(true);
      return;
    }
    dispatchRedux(clearSearch());
    setChangeViewSearch(false);
    Obtener();
  }, [searchInformacion, freeZone_id, Obtener, Search, valueQualified]);

  useEffect(() => {
    return (async) => dispatchRedux(clearSearch());
  }, []);

  useEffect(() => {
    Obtener();
  }, [token, Obtener, update, page, freeZone_id]);

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(isArray(data?.type_export_file) &&
        data?.type_export_file?.length !== 0 && {
        type_export_file: data?.type_export_file?.value
      }),
      qualifiedUser_id: isArray(data?.qualifiedUser_id)
        ? data?.qualifiedUser_id?.map((item) => item?.value || item)
        : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
          ? data?.qualifiedUser_id?.value
          : data?.qualifiedUser_id || [],
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    exportColumnBy(info, () => onCloseExport());
  };

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateNodeList = () => {
    const nodeList = arrayProductionOrderNodeList;
    if (authUser.is_qualified_user === 1) {
      return nodeList.concat(arrayProductionOrderNodeListUC);
    }
    return nodeList.concat(arrayProductionOrderNodeListNoUC);
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        {/* <SearchFormAdvanced
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        /> */}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCreateCIntegration}
        onOpen={onOpenCreateCIntegration}
        onClose={onCloseCreateCIntegration}
        size="md"
        title="Crear certificados de integración"
      >
        <CreateCI onClose={onCloseCreateCIntegration} />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        size="4xl"
        nodeList={calculateNodeList()}
        selectNodeList={productionOrdenNodeInputFields}
        handleSubmit={handleSubmit}
      />
      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeftOrder}
          listRight={
            authUser.is_qualified_user === 1
              ? arrayRigthUserOrder
              : arrayRigthOrder
          }
          handleSubmit={handleSubmit}
          statusDataSelect={cabeceraSelect}
          dafaulActive={[]}
        />
      </ModalGeneric> */}

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseCopy}
        title={`Desea Copiar el formulario Nº ${dataToCopy?.production_order_code}`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={OrderProductionContext}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Orden de producción</Title>
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getProductOrderSearch"}
          allAccess={allAccess}
          myPermission={myPermission}
          viewButtonFilter={true}
          setValues={setValues}
        />
        <ExportDetail onOpen={onOpenExport} />
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <>
                <TableOrder
                  thead={cabeceraPrincipal}
                  data={currentPageTwo}
                  setData={setCurrentPageTwo}
                  loading={loading}
                  tbodyData={Body}
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </>
            )
          )
          : (
            <div>
              <ComponentAccordion
                cabecera={cabeceraOrdens}
                currentPage={currentPage || []}
                TitleHeader={cabeceraPrincipal}
                setCurrentPage={setCurrentPage}
                loading={loading}
                TBodyEntryMovement={Body}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                changePage={changePage}
                nameModule={"Orden de producción"}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};
