import { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";
import { MdContentCopy } from "react-icons/md";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { TiCancel } from "react-icons/ti";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;
export const OptionsAuthorizedBox = ({ data }) => {
  const {
    setDataToAnular,
    onOpenAnular,
    onOpenCopyBox,
    setDataToCopy,
    changeStatus
  } = useContext(IntegracionBoxCrudContex);

  const handleAnular = () => {
    setDataToAnular(data);
    onOpenAnular();
  };

  const handleCopy = () => {
    onOpenCopyBox();
    setDataToCopy(data);
  };

  const handlePresent = (status) => {
    const dataSend = new FormData();
    dataSend.append("status", status);
    dataSend.append("id", data?.integration_certificate_id);
    changeStatus(dataSend);
  };

  return (
    <>
      <MenuItemStyled py={3} onClick={handleCopy}>
        <IconMenuRUD color="#03C39A" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      <HrDividerNoMargin />
      <MenuItemStyled py={3} onClick={() => handlePresent("CORREGIDO")}>
        <IconMenuRUD color="#F9A621" as={HiOutlineDocumentSearch} />
        Corregir
      </MenuItemStyled>

      {data?.status !== "AUTORIZADO" && (
        <>
          <HrDividerNoMargin />
          <MenuItemStyled py={3} onClick={handleAnular}>
            <IconMenuRUD color="#41495A" as={TiCancel} />
            Anular
          </MenuItemStyled>
        </>
      )}
    </>
  );
};
