/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Center, Td, Tr } from "@chakra-ui/react";
import MenuPass from "components/Menu/MenuPass";
// import PassCrudContext from "context/PassCrudContext";
import "./styleTbody.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IconGeneric } from "styled/Icons.styled";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useModal } from "hooks/useModal";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import queryString from "query-string";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import PassCrudContext from "context/PassCrudContext";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";

const TBodyPass = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          to={`/ShowWeighing?weighing=${data.id}&Operation=${data.operation}`}
          className="ms-2"
        >
          <span className="formNumber">{data.consecutive}</span>
        </NavLink>
      </Td>
      <Td className="text-center">{data?.gross_weight ?? "N/A"}</Td>
      <Td className="text-center">{data?.tare_weight ?? "N/A"}</Td>
      <Td className="text-center">{data?.net_weight ?? "N/A"}</Td>
    </Tr>
  );
};

const TBodyContainers = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.container ?? "N/A"}</Td>
      <Td className="text-center">{data?.capacity_container ?? "N/A"}</Td>
      <Td className="text-center">{data?.tare_container ?? "N/A"}</Td>
    </Tr>
  );
};

export const TitlePass = [
  { name: "Tiquete de bascula", order: "null" },
  { name: "Peso Bruto", order: "null" },
  { name: "Peso Tara", order: "null" },
  { name: "Peso Neto", order: "null" },
];

export const TitleContainers = [
  { name: "Contenedor", order: "null" },
  { name: "Capacidad", order: "null" },
  { name: "Peso Tara", order: "null" },
];

const TBodyEntryMovement = ({ data, iope }) => {
  // const { setDataToShow } = useContext(PassCrudContext);
  const navigate = useNavigate();
  const location = useLocation(); // navigate
  const [loading, setLoading] = useState(false);
  const { pathname } = location;
  const [isOpen, onOpen, onClose] = useModal();
  const [isOpenWeight, onOpenWeight, onCloseWeight] = useModal();
  const { movement = "", searchSimple = null, searchAdvance = null } = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState(data?.weinghings ?? []);
  const [dataContainers, setDataContainers] = useState(data?.containers ?? []);

  const redirectShowReviwEntry = `/CheckPassEntry?numberForm=${data.passForm_number}&id=${data.id}&status=${data?.status_entryPass}&typeO=`;

  const redirectShowReviwOutput = `/CheckPassOutput?numberForm=${data.passForm_number}&id=${data.id}&status=${data?.status_dispatchPass}&typeO=Dispatch`;

  const redirectShow = `/pases?numberForm=${data.passForm_number}&id=${data.id}&status=${
    data.status_entryPass || data.status_dispatchPass
  }&typeO=${data.status_dispatchPass ? "Dispatch" : ""}`;

  const handleShow = () => {
    if (data.status_entryPass && data.status_entryPass === "REVISADO") {
      navigate(redirectShowReviwEntry);
    } else if (
      data?.status_dispatchPass &&
      data?.status_dispatchPass === "REVISADO"
    ) {
      navigate(redirectShowReviwOutput);
    } else {
      navigate(redirectShow);
    }
  };

  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [openMenu, setOpenMenu] = useState(true);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const checkIfItIsBlocked = async () => {
    if (is_qualified_user) {
      setOpenMenu(true);
      return;
    }

    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", data?.status_dispatchPass ? "DESPACHO" : "ENTRADA");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true);
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false);
      setTimeout(() => setOpenMenu(true), 500);
    }
  };

  return (
    <Tr>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Tiquete de báscula"
        size="md"
        scrollBehavior="outside"
      >
        <TableOrder
          thead={TitlePass}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TBodyPass}
        />
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenWeight}
        onOpen={onOpenWeight}
        onClose={onCloseWeight}
        title="Contenedores"
        size="md"
        scrollBehavior="outside"
      >
        <TableOrder
          thead={TitleContainers}
          data={dataContainers}
          setData={setDataContainers}
          loading={loading}
          tbodyData={TBodyContainers}
        />
      </ModalGeneric>

      <Td className="text-center">{data.nit_qualified}</Td>
      <Td className="text-center"> {data.name_qualified}</Td>
      <Td className="text-center" onClick={() => handleShow(data)}>
        <NavLink
          onClick={() => handleShow(data)}
          to={
            data?.status_entryPass && data?.status_entryPass === "REVISADO"
              ? redirectShowReviwEntry
              : data?.status_dispatchPass &&
                data?.status_dispatchPass === "REVISADO"
              ? redirectShowReviwOutput
              : redirectShow
          }
          className="ms-2"
        >
          <span className="formNumber">{data?.passForm_number}</span>
        </NavLink>
      </Td>

      <Td className="text-center">
        {data?.status_entryPass || data?.status_dispatchPass}
      </Td>
      <Td className="text-center">{data?.name_typeTransport}</Td>
      <Td className="text-center">{data?.vehicle_plate}</Td>
      <Td className="text-center">{data?.number_packages}</Td>
      {movement === "Pase de entrada" &&
      data?.status_entryPass !== "BORRADOR" &&
      data?.status_entryPass !== "DEVUELTO" ? (
        <Td className="text-center">
          {data?.code_security ? (
            <IconGeneric color={"#03C39A"} as={BsCheckCircle} />
          ) : (
            <IconGeneric color={"#BA1F33"} as={BsXCircle} />
          )}
        </Td>
      ) : (
        (searchSimple || searchAdvance) && <Td className="text-center"></Td>
      )}
      {(pathname === "/FormShow" || pathname === "/FormShowExit") && (
        <>
          <Td className="text-center">{data?.net_weight}</Td>
          <Td>
            <Center>
              {" "}
              <IconGeneric
                onClick={() => {
                  if (dataContainers?.length > 0) {
                    onOpenWeight();
                  }
                }}
                as={HiOutlineInformationCircle}
                color={dataContainers?.length > 0 ? "" : "gray"}
              />
            </Center>
          </Td>

          <Td className="text-center">
            <IconGeneric
              onClick={() => {
                if (currentPage?.length > 0) {
                  onOpen();
                }
              }}
              as={HiOutlineInformationCircle}
              color={currentPage?.length > 0 ? "" : "gray"}
            />
          </Td>
        </>
      )}

      <Td className="text-center">{data?.countItems}</Td>

      {data?.status_entryPass === "PRESENTADO" ||
      data?.status_dispatchPass === "PRESENTADO" ? (
        <Td className="text-center" onClick={checkIfItIsBlocked}>
          {openMenu && <MenuPass data={data} />}
        </Td>
      ) : pathname !== "/FormShow" && pathname !== "/FormShowExit" ? (
        <Td className="text-center">
          <MenuPass data={data} />
        </Td>
      ) : (
        ""
      )}
    </Tr>
  );
};

export default TBodyEntryMovement;
