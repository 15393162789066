/* eslint-disable no-unused-vars */
import { useContext } from "react";
import { IconMenuRUD } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { GiCardDiscard } from "react-icons/gi";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { HrDividerNoMargin } from "styled/Line.styled";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { MenuItem } from "@chakra-ui/react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

export const OptionsEraserBox = ({ data, isCorrected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { pathname } = location;
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()

  const {
    setDeleteBox,
    onOpenDeleteBox,
    setDataToCopy,
    onOpenCopyBox,
    changeStatusPresent,
    changeStatusCorrected
  } = useContext(IntegracionBoxCrudContex);

  const handlePresent = () => {
    const statusData = new FormData();
    statusData.append(
      "integration_certificate_id",
      data?.integration_certificate_id
    );
    changeStatusPresent(statusData);
  };

  const handleCopy = (e) => {
    onOpenCopyBox();
    setDataToCopy(data);
  };

  const handleDesechar = async () => {
    const body = new FormData();
    body.append("form_id", data?.integration_certificate_id);
    body.append("type_of", ('CI'));
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      setDeleteBox(data);
      onOpenDeleteBox();
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };

  const handleChangeStatus = (status) => {
    const dataToSend = new FormData();
    dataToSend.append("id", data.integration_certificate_id);
    dataToSend.append("status", status);
    changeStatusCorrected(dataToSend);
  };

  const handleClickEdit = async (type) => {
    const identifiers = {
      CIC: data?.integration_certificate_id,
      CI: data?.integration_certificate_id
    }
    const body = new FormData();
    body.append("form_id", identifiers[type]);
    body.append("type_of", type);
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      if (type === 'CIC') {
        return navigate(`/CorrectedCertificate?id=${data?.integration_certificate_id}`);
      }
      if (type === 'CI') {
        return navigate(`/IntegracionBox?id=${data?.integration_certificate_id}`);
      }
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleClickPresent = async (type) => {
    const identifiers = {
      CIC: data?.integration_certificate_id,
      CI: data?.integration_certificate_id
    }
    const body = new FormData();
    body.append("form_id", identifiers[type]);
    body.append("type_of", type);
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      if (type === 'CIC') {
        return handleChangeStatus("PRESENTADO")
      }
      if (type === 'CI') {
        return handlePresent()
      }
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  return (
    <>
      {isCorrected
        ? (
          <>
            <MenuItemStyled py={3} onClick={() => handleClickEdit('CIC')}>
              <IconMenuRUD as={RiEdit2Fill} />
            Editar
            </MenuItemStyled>
            <HrDividerNoMargin />

            <MenuItemStyled
              py={3}
              onClick={() => handleClickPresent("CIC")}
            >
              <IconMenuRUD color="#03C39A" as={AiOutlineFileDone} />
            Presentar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )
        : (
          <>
            <MenuItemStyled py={3} onClick={() => handleClickEdit('CI')}>
              <IconMenuRUD as={RiEdit2Fill} />
            Editar
            </MenuItemStyled>
            <HrDividerNoMargin />

            <>
              <MenuItemStyled py={3} onClick={handleCopy}>
                <IconMenuRUD color="#F9A621" as={MdContentCopy} />
              Copiar
              </MenuItemStyled>
              <HrDividerNoMargin />
            </>

            <MenuItemStyled py={3} onClick={() => handleClickPresent('CI')}>
              <IconMenuRUD color="#03C39A" as={AiOutlineFileDone} />
            Presentar
            </MenuItemStyled>
            <HrDividerNoMargin />

            <>
              <MenuItemStyled onClick={handleDesechar}>
                <IconMenuRUD color="#d92525" as={GiCardDiscard} />
              Desechar
              </MenuItemStyled>
            </>
          </>
        )}
    </>
  );
};
