import { Icon } from '@chakra-ui/react';
import styled from 'styled-components/macro';

export const ProfileIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.mainAccent};
  }
  color: #fff;
`;

export const ProfileTitle = styled.h1`
  color: ${(props) => props.theme.colors.mainAccent};
  font-size: 10px;
  font-weight: bold;
  padding: 9px 0 5px 0;
  @media (max-width: 912px) {
    padding-top: 10px;
  }
`;

export const ProfiButton = styled.a`
  &:hover {
    color: ${(props) => props.theme.colors.mainAccent};
    cursor: pointer;
  }
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #BABABA;
  font-weight: light;
  margin-left: 10px;
  font-family: Roboto;
`;

export const ContainerItem = styled.div`
  &:hover {
    color: ${(props) => props.theme.colors.mainAccent};

  }
`;

export const ContainerProfile = styled.div`
    flex-direction: row; 
    padding: 10px; 
    display: flex; 
    justify-Content: space-between; 
    height: 85vh;

    @media (max-width: 912px) {
      flex-direction: column;
      align-items: center;
      justify-Content: space-around; 
      height: 100%;
    }

    @media (max-width: 536px) {
      height: 100%;
    }
`;

export const ContainerMenu = styled.div`
    width: 25%; 
    box-shadow:  rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    padding: 15px 10px;
    
    @media (max-width: 912px) {
      width: 100%;
      align-items: end;
      padding: 30px;
    }
    @media (max-width: 536px) {
      width: 100%;
      height: auto;
      align-items: center;
    }
`;

export const ContainerComponents = styled.div`
    width: 72%;  
    box-shadow:  rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
    box-sizing: border-box; 
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    @media (max-width: 912px) {
      width: 100%;
      height: 70%;
    }
    @media (max-width: 536px) {
      width: 100%;
      height: 100%;
    }
`;

export const LineSpacer = styled.span`
    display: block; 
    width: 90%; 
    border-bottom: 2px solid #E4E4E4;
    margin: auto; 
    margin-bottom: 25px; 
    margin-top: 25px;

    @media (max-width: 912px) {
      display: none;
    }
`;

export const InfoUserContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1199px) {
      flex-direction: column;
    }
`;

export const ContentNameUser = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1199px) {
    align-items: center;
  }
`;

export const UserName = styled.div`
  font-size: 12px;
`;

export const ContentOptions = styled.div`
  display: flex; 
  flex-direction: column;

  @media (max-width: 912px) {
    flex-direction: row;
    width: 60%; 
    justify-content: space-between;
  }

  @media (max-width: 729px) {
    flex-direction: column;
    justify-content: end;
    width: auto;
  }
`;

export const ContainerAllInfoUser = styled.div`
  @media (max-width: 912px) {
    display: flex; 
    justify-content: space-between; 
    width: 100%; 
    align-items: center;
  }
  @media (max-width: 536px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
`;
