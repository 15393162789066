const { BaseOfExport } = require('components/Export/BaseOfExport')
const { default: TableOrder } = require('components/Tables/TableOrder')
const { HeaderSearchCustom } = require('styled/SectionContainer.styled')

const ExportTable = ({
  thead,
  Tbody,
  currentPage,
  setCurrentPage,
  loading,
  page,
  setPage,
  maxPage,
  ...props
}) => {
  return (
    <div
      {...props}
      style={{
        display: 'none'
      }}
    >
      <BaseOfExport>
        <HeaderSearchCustom margen='0' padding='3' Shadow='0'>
          <TableOrder
            thead={thead}
            data={currentPage ?? []}
            tbodyData={Tbody}
            setData={setCurrentPage}
            loading={loading}
          />
        </HeaderSearchCustom>
      </BaseOfExport>
    </div>
  )
}

export default ExportTable;
