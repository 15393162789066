import React from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";

const TBodyContainers = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">
        {data?.container_1 ? data?.container_1 : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.container_2 ? data?.container_2 : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.capacity_container_1 ? data?.capacity_container_1 : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.capacity_container_2 ? data?.capacity_container_2 : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.tare_container_1 ? data?.tare_container_1 : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.tare_container_2 ? data?.tare_container_2 : "N/A"}
      </Td>

      <Td className="text-center">{data?.driver ? data?.driver : "N/A"}</Td>
    </Tr>
  );
};

TBodyContainers.propTypes = {
  data: PropTypes.object
};

export default TBodyContainers;
