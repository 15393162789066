import React, { useContext, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { RiEdit2Fill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import Swal from "sweetalert2";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuSupplies = ({ data }) => {
  const {
    onOpenAddSupplies,
    setSuppliesData,
    setShowSupplies,
    setActiveView,
    setDataToCorrectSupplies,
    setRequestDeleteSupplies,
    setDataSuppliesPCorrected,
    onOpenDeleteSupplyCorrected,
    setIsEditCiSupplies
  } = useContext(IntegracionBoxCrudContex);
  const location = useLocation();
  const { pathname } = location;

  const [openAlert] = AlertErrorAlert();

  const { requestData } = useGlobalContext()
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: data => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, 'error')
      }
      
      if (data?.data?.status?.code === 200) {
        queryClient.invalidateQueries({queryKey: [queryEnv?.SUPPLIES_CI]})
        openAlert(data?.data?.status?.message, 'success')
      }
    }
  })
  const handleDelete = () => {
    Swal.fire({
      title: `¿Desea eliminar el insumo ${data?.code_product}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text"
      },
      text: `Le recordamos limpiar la información agregada de materias primas e insumos, otros gastos y costos, si es el caso.`,
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        mutate({
          data: {
            id: data?.id
          },
          Endpoint: environment.DELETE_SUPPLIES_PRODUCTS,
          PropertyBody: 'sendJSONContentPOST',
        })
      }
    });
  };

  const handleEditProduct = () => {
    setIsEditCiSupplies(true)
    onOpenAddSupplies();//
    setSuppliesData(data);
  };

  const handleCorrectedSupplies = () => {
    setShowSupplies(true);
    setActiveView("showSupplies");;
    setDataToCorrectSupplies(data);
  };

  const handleDeleteCorrected = () => {
    Swal.fire({
      title: `¿Desea eliminar el insumo ${data?.code_product_corrected}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text"
      },
      text: `Le recordamos limpiar la información agregada de materias primas e insumos, otros gastos y costos, si es el caso.`,
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        mutate({
          data: {
            id_corrected: data?.id_corrected
          },
          Endpoint: environment.DELETE_CORRECTED_SUPPLY,
          PropertyBody: 'sendJSONContentPOST',
        })
      }
    });
  };

  useEffect(() => {
    setRequestDeleteSupplies(environment.DELETE_SUPPLIES_PRODUCTS);
  }, [setRequestDeleteSupplies]);

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <>
          <HrDividerNoMargin />
          {pathname === "/CorrectedCertificate" && !data.codeProductionOrder
            ? (
              <>
                <MenuItemStyled py={3} onClick={handleCorrectedSupplies}>
                  <IconMenuRUD as={RiEdit2Fill} />
                Editar
                </MenuItemStyled>
                <HrDividerNoMargin />
                <MenuItemStyled py={3} onClick={handleDeleteCorrected}>
                  <IconMenuRUD as={MdDelete} />
                Eliminar
                </MenuItemStyled>
              </>
            )
            : (
              <>
                <MenuItemStyled py={3} onClick={handleEditProduct}>
                  <IconMenuRUD as={RiEdit2Fill} />
                Editar
                </MenuItemStyled>
                <HrDividerNoMargin />
                <MenuItemStyled py={3} onClick={handleDelete}>
                  <IconMenuRUD as={MdDelete} />
                Eliminar
                </MenuItemStyled>
              </>
            )}
        </>
      </MenuList>
    </Menu>
  );
};

export default MenuSupplies;
