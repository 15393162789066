/* eslint-disable react-hooks/exhaustive-deps */
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { operaciones } from "../General/ArrayEstadisticas";
import { TbodyOperaciones } from "../Tbody/TbodyOperaciones";
import { __Post } from "request/Petitions/__Post";
import { Title } from "styled/Title";

import StatisContext from "context/StatisContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { TBodyOperationOutput } from "../Tbody/TBodyOperationOutput";
import InputSelect from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { ExportPdf } from "components/Export/ExportPdf";

import { Bar } from "react-chartjs-2";
import { estadisticaOptions } from "../General/EstadisticaOptions";
import { Checkbox } from "@chakra-ui/react";

export const EstadisticasIngresoSalida = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const { dataForCal, setdataForCal } = useContext(StatisContext);
  const [filterEst, setfilterEst] = useState(true);
  const [dateToday, setDateToday] = useState(null);

  const [rutaImage, setrutaImage] = useState(null);

  const [userData, setUserData] = useState({
    labels: dataForCal && dataForCal.map((data) => data?.type_transport),
    datasets: [
      {
        label: ["Vetados"],
        data: dataForCal && dataForCal.map((data) => data?.amount),
        backgroundColor: ["#006EB8"]
      }
    ]
  });

  const generateColorDependingOnCode = ({ code_operation, code_operation_o }) => {
    if (code_operation >= 101 && code_operation < 120) {
      return "#2898ee";
    }
    if (code_operation >= 301 && code_operation <= 330) {
      return "#107acc";
    }
    if (code_operation >= 501 && code_operation <= 518) {
      return "#0cbccC";
    }
    if (code_operation_o === 50) {
      return "#142157";
    }
    if (code_operation_o >= 201 && code_operation_o <= 226) {
      return "#15297c";
    }
    if (code_operation_o >= 401 && code_operation_o <= 450) {
      return "#0cbccC";
    }
    if (code_operation_o >= 601 && code_operation_o <= 618) {
      return "#107acc";
    }
    if (code_operation_o >= 801 && code_operation_o <= 814) {
      return "#2898ee";
    }
    return "#0cbcFF";
  };

  useEffect(() => {
    setUserData({
      labels:
        dataForCal &&
        dataForCal.map(
          ({ code_operation, code_operation_o }) =>
            code_operation || code_operation_o
        ),
      datasets: [
        {
          label: "Cantidad",
          data:
            dataForCal &&
            dataForCal.map(
              ({ count_fmm, count_fmm_o }) => count_fmm || count_fmm_o
            ),
          backgroundColor:
            dataForCal &&
            dataForCal.map(({ code_operation, code_operation_o }) => generateColorDependingOnCode({ code_operation, code_operation_o }))
        }
      ]
    });
  }, [dataForCal]);

  const title = "Operaciones Ingresos y Salidas";
  const ref = useRef(null);

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) ExportPdf("IdPdfFmmCorrectEntry", `${title}_${dateToday}`);
  }, [rutaImage]);

  useEffect(() => {
    console.log(dataForCal);
  }, [dataForCal]);



  return (
    <>
      <HeaderEstadistica
        title={title}
        enableQualifiedUser
        generar64={dowmLoadPdfImgae}
        excelPath={environment.POST_EXPORT_EXCEL_OP_ENTRY_OUT}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_OPE}
        enableRendering={true}
      />
      {filterEst && (
        <>
          <div className="row">
            <div className="col-6">
              <div className="mt-4">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title>Ingreso</Title>
                  <Checkbox
                    size="md"
                    colorScheme="blue"
                    isInvalid
                    onChange={(e) => e.target.checked ? currentPage?.entry_forms && setdataForCal(prevData => [...prevData, ...currentPage?.entry_forms]) : setdataForCal(prevData => prevData.filter(item => !currentPage?.entry_forms.includes(item)))}>
                    Todos
                  </Checkbox>
                </div>
                <TableOrder
                  thead={operaciones}
                  data={currentPage ? currentPage?.entry_forms : []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyOperaciones}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mt-4">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title>Salida</Title>
                  <Checkbox
                    size="md"
                    colorScheme="blue"
                    isInvalid
                    onChange={(e) => e.target.checked ? currentPage?.output_forms && setdataForCal(prevData => [...prevData, ...currentPage?.output_forms]) : setdataForCal(prevData => prevData.filter(item => !currentPage?.output_forms.includes(item)))}>
                    Todos
                  </Checkbox>
                </div>
                <TableOrder
                  thead={operaciones}
                  data={currentPage ? currentPage?.output_forms : []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyOperationOutput}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <ExportPdfGrafica
        id="IdPdfFmmCorrectEntry"
        rutaImage={rutaImage}
        thead={operaciones}
        data={[]}
        tbody={TbodyOperaciones}
        title={`${title}`}
        dateToday={dateToday}
      >
        <div className="html2pdf__page-break" />

        <div className="row">
          <div className="col-12">
            <div className="mt-4">
              <Title>Ingreso</Title>
              <TableOrder
                thead={operaciones}
                data={currentPage ? currentPage?.entry_forms : []}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodyOperaciones}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="mt-4">
              <Title>Salida</Title>
              <TableOrder
                thead={operaciones}
                data={currentPage ? currentPage?.output_forms : []}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TBodyOperationOutput}
              />
            </div>
          </div>
        </div>
      </ExportPdfGrafica>

      <div className="mt-2">
        {!loading && dataForCal.length !== 0 && (
          <Bar data={userData} ref={ref} options={estadisticaOptions('Operación', 'Cantidad', 'Operaciones utilizadas')} />
        )}
      </div>
    </>
  );
};
