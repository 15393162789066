import axios from "axios";
import env from "../../constants/apiConst";

export const __Roles = async (token, body, posicion) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.ROL_GET}?page=${posicion}`, options);
  return res;
};

export const __Permisos = async (token, body, posicion) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.PERMISOS_GET}?page=${posicion}`, options);
  return res;
};

export const __Licencia = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.LICENCIA_GET}?page=${page}`, options);
  return res;
};

export const __Group = async (token, body, posicion) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GROUP_GET}?page=${posicion}`, options);
  return res;
};

export const __Group_All = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GROUP_GET_ALL}`, options);
  return res;
};

export const __Group_And_Permisos = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GROUP_GET_ALL_AND_PERMISOS}`, options);
  return res;
};

export const __ShowRoles = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ROL_SHOW}/${id}`, options);
  return res;
};
export const __PermisosSinPag = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.PERMISOS_SIN_PAGINAR}`, options);
  return res;
};

export const __UserCalificadosSp = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.USER_ROL_SP_ADMIN}`, options);
  return res;
};

export const __RolesNotPage = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ROL_SP_USER}`, options);
  return res;
};

export const __ShowLicencia = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.LICENCIA_SHOW}/${id}`, options);
  return res;
};
