import React, { useEffect, useState } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import {
  LabelGroup
} from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import InputSelect from "components/InputSelect/InputSelect";
import { useSearchUrl } from "hooks/useSearchUrl";

export const ThirdSearchAdvanceForm = ({ setSearchAdvanced, onClose }) => {
  const types = [
    {
      value: "EXTRANJERO",
      label: "Extranjero"
    },
    {
      value: "NACIONAL",
      label: "Nacional"
    }
  ]
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["type", "business_name", "identification_num", "internal_code", "acronym", "alias", "advanced"], nestedProp: "searchAdvance" })
  const [type, setType] = useState(types.find(({ value }) => state?.type === value) || null);
  const [values, handleInputChange, reset, setValues] = useForm({
    businessName: state?.business_name || "",
    identification_num: state?.identification_num || "",
    internal_code: state?.internal_code || "",
    acronym: state?.acronym || "",
    alias: state?.alias || "",
  });

  const {
    businessName,
    identification_num,
    internal_code,
    acronym,
    alias
  } = values;

  const handleSubmit = () => {
    const info = {
      ...(type?.value && { type: type?.value }),
      ...(businessName && { business_name: businessName }),
      ...(identification_num && { identification_num }),
      ...(internal_code && { internal_code }),
      ...(acronym && { acronym }),
      ...(alias && { alias }),
      advanced: true,
    }
    setParamsSearch({ params: info });
    setSearchAdvanced(info);
    onClose();
  };



  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <InputSelect
          tag={"Tipo"}
          data={types}
          set={setType}
          value={type}
          size={45}
        />

        <LabelGroup
          name={"businessName"}
          value={businessName}
          onChange={handleInputChange}
          tag={"Razón social"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"identification_num"}
          value={identification_num}
          onChange={handleInputChange}
          tag={"Nit"}
          size={45}
          tipo={"number"}
        />
        <LabelGroup
          name={"internal_code"}
          value={internal_code}
          onChange={handleInputChange}
          tag={"Código interno"}
          size={45}
          tipo={"number"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"acronym"}
          value={acronym}
          onChange={handleInputChange}
          tag={"Siglas"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"alias"}
          value={alias}
          onChange={handleInputChange}
          tag={"Alias"}
          size={45}
          tipo={"text"}
        />
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  )
}
