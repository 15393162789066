import React from "react";
import { Td, Tr } from "@chakra-ui/react";

// import MenuCustomsClearence from "components/Menu/MenuCustomsClearence";
import MenuSecutiry from "components/Menu/MenuSecutiry";

/**
 *  TbodyTablePerson - Componente que muestra los items mediante la tabla en el componente de lista de bloqueo
 * @component
 * @param {Object} data data que se muestra en la tabla de personas del componete de lista de bloqueo.
 * @return {Component} Retorna componente tabla jsx
 */

const TbodyTablePerson = ({ data }) => {
  const { date, name_driver, description, identification, type_of_sanction } =
    data;
  return (
    <Tr>
      <Td className="text-center">{name_driver || "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {identification || "N/A"}
      </Td>
      <Td className="text-center">
        {" "}
        {type_of_sanction || "N/A"}
      </Td>
      {/* <Td className="text-center"> {status_people}</Td> */}
      <Td className="text-center"> {date || "N/A"}</Td>
      <Td className="text-center"> {description || "N/A"}</Td>
      <Td className="text-center">
        <MenuSecutiry data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTablePerson;
