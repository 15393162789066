import { Text, View, StyleSheet, Link } from '@react-pdf/renderer'
import React from 'react'

export const BodyAnexxed = ({
  code,
  Description = 'Opcional',
  link = '#',
  Document,
  DateDocument = 'N/A',
  Comment = '',
  fontSize = '6px'
}) => {
  const styles = StyleSheet.create({
    annexed: {
      border: '1px solid gray',
      borderLeft: 'none',
      borderBottom: 'none',
      padding: '2px',
      width: '100%',
      height: '17px',
      textAlign: 'center',
      borderTop: 'none',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize
    }
  })
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Text
        style={{
          ...styles.annexed,
          borderBottom: '1px solid gray',

          borderLeft: '1px solid gray'
        }}
      >
        {code}
      </Text>
      <Text
        style={{
          ...styles.annexed,
          borderBottom: '1px solid gray'
        }}
      >
        {Description}
      </Text>
      <Text
        style={{
          ...styles.annexed,

          borderBottom: '1px solid gray'
        }}
      >
        {Document}
      </Text>
      <Text
        style={{
          ...styles.annexed,

          borderBottom: '1px solid gray'
        }}
      >
        {DateDocument}
      </Text>
      <Text
        style={{
          ...styles.annexed,
          borderTop: 'none',
          borderBottom: '1px solid gray'
        }}
      >
        {Comment}
      </Text>
    </View>
  )
}
