/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, SimpleGrid, useToast } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { __Dependency_Not_Paginate } from "request/configuration/__Dependency";
import BlockingContext from "context/BlockingContex";
import { useForm } from "hooks/useForm";
import environment from "constants/apiConst";
import { __ShowBlocking } from "request/Blocking/___BlockingCrud";
import { AuthContext } from "context/AuthContext";
import { MaxLength } from "utils/MaxLength";

/**
 * FormBlocking- Componente donde se encuentra formulario para realizar bloqueos de dependencias
 *
 * @component
 * @return {Component} Retorna formulario para crear y editar bloqueos de dependencias.
 */

const FormBlocking = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [datauserQualified, setDataUserQuialified] = useState([]);
  const [userQualified, setUserQualified] = useState(null);
  const [dataCountry, setDataCountry] = useState([]);
  const [country, setCountry] = useState(null);
  const [lock, setLock] = useState(null);
  const [statusB, setStatusB] = useState(false);
  const [idRestriccion, setIdRestriccion] = useState("");
  const [lockCurrents, setLockCurrent] = useState("");
  const [prueba, setPrueba] = useState("");
  const [infoLock, setInfoLock] = useState("");
  const { authUser } = useContext(AuthContext);

  const toast = useToast();
  const {
    setRequestEdit,
    setRequestCreate,
    createData,
    onCloseBlocking,
    setActionsRes,
    actionsRes,
    onCloseInfo
  } = useContext(BlockingContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    action: "",
    observations: ""
  });

  const { observations, action } = values;

  const lockType = [
    { value: "Ingreso", label: "Ingreso" },
    { value: "Salida", label: "Salida" }
  ];

  const alertError = useCallback(
    (title) => {
      toast({
        title: `${title}`,
        status: "error",
        isClosable: true,
        position: "top"
      });
    },
    [toast]
  );

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const dataUser = res?.data?.status?.data.map((items) => ({
        value: items.id,
        label: items?.nit_qualified + " - " + items?.name_qualified
      }));
      setDataUserQuialified(dataUser);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const getCountry = useCallback(async () => {
    try {
      const res = await __Dependency_Not_Paginate(token);
      const data = res?.data?.status?.data;
      const datalast = data[data.length - 1];
      const isTreasuryAdmin = [
        { value: datalast.id, label: datalast.dependency }
      ];
      const indoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.dependency
      }));
      if (authUser.is_commerce !== 1) {
        setDataCountry(indoCountry);
      } else {
        setDataCountry(isTreasuryAdmin);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const ShowBlocking = useCallback(
    async (userid, id) => {
      try {
        const res = await __ShowBlocking(token, userid, id);
        const respuestaShow = res?.data?.status?.data;
        setIdRestriccion(respuestaShow?.restriction_id);

        if (res?.data?.status.code === 200) {
          setActionsRes(true);
          setStatusB(false);
          setValues({
            action:
              respuestaShow?.lock_type[0]?.action === 1
                ? "Bloqueado"
                : "Desbloqueado",
            observations: respuestaShow?.lock_type[0]?.observations
          });

          // estado de tipo de bloqueo
          if (respuestaShow?.lock_type[0].lock_type !== undefined) {
            setLock({
              value: respuestaShow?.lock_type[0].lock_type,
              label: respuestaShow?.lock_type[0].lock_type
            });
            setLockCurrent(respuestaShow?.lock_type[0].lock_type);
          } else {
            setLock({
              value: respuestaShow?.lock_type,
              label: respuestaShow?.lock_type
            });
            setLockCurrent(respuestaShow?.lock_type);
          }
          // console.log(respuestaShow?.lock_type);

          // estado para  el array //
          setInfoLock(respuestaShow?.lock_type);
        } else {
          setValues({ action: "Bloquear", observations: "" });
          setLock("");
          setStatusB(true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, setValues, alertError, setActionsRes]
  );

  useEffect(() => {
    getUserQualified();
    getCountry();
    // reset();
  }, [getUserQualified, getCountry]);

  useEffect(() => {
    if (country !== null && userQualified !== null) {
      ShowBlocking(userQualified.value, country.value);
    }
  }, [country, ShowBlocking, userQualified]);

  // console.log("lock", lock);
  useEffect(() => {
    if (lock !== null && lock?.value !== lockCurrents && infoLock) {
      console.log(infoLock);
      const resultado = infoLock?.find(
        (elemento) => elemento?.lock_type === lock?.value
      );

      setPrueba(resultado);

      if (resultado !== undefined) {
        setLockCurrent(resultado?.lock_type);
        setValues({
          action: resultado?.action === 0 ? "Debloqueado" : "Bloqueado",
          observations: resultado?.observations
        });
      } else {
        setLockCurrent(lockCurrents === "Ingreso" ? "Salida" : "Ingreso");
        setValues({
          action: "Bloquear",
          observations: ""
        });
      }
    }
  }, [lock, infoLock, lockCurrents]);

  useEffect(() => {
    setRequestEdit(environment.BLOCKING_UPDATE);
    setRequestCreate(environment.BLOCKING_CREATE);
  }, [setRequestCreate, setRequestEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "qualifiedUser_id",
      userQualified !== null ? userQualified.value : ""
    );
    formData.append("dependency_id", country !== null ? country.value : "");
    formData.append(
      "action",
      action === "Desbloqueado"
        ? 1
        : action === "Bloqueado"
          ? 0
          : action === "Bloquear"
            ? 1
            : ""
    );
    formData.append("lock_type", lock !== null ? lock.value : "");
    formData.append("observations", values.observations);
    if (statusB) {
      createData(formData);
    } else if (actionsRes) {
      formData.append("id", idRestriccion);
      createData(formData);
      setActionsRes(false);
      handleReset();
    }
  };

  const handleReset = () => {
    reset();
    onCloseBlocking();
    onCloseInfo();
  };

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="10px">
        <div>
          <InputSelect
            tag={"Usuario Calificado"}
            size={100}
            data={datauserQualified}
            set={setUserQualified}
            value={userQualified === null ? "" : userQualified}
          />
        </div>

        <div>
          <InputSelect
            tag={"Dependencia"}
            size={100}
            data={dataCountry}
            set={setCountry}
            value={country === null ? "" : country}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-2">
        <LabelGroup
          name={"action"}
          onChange={handleInputChange}
          value={action || ""}
          tag={"Acción"}
          size={100}
          desicion={true}
        />

        <div>
          <InputSelect
            tag={"Tipo de Bloqueo"}
            size={100}
            data={lockType}
            set={setLock}
            value={lock}
          />
        </div>
      </SimpleGrid>

      <div
        className="col-12"
        style={{
          display: "flex",
          marginTop: "3%"
        }}
      >
        <LabelGroup
          name={"observations"}
          onChange={handleInputChange}
          value={observations}
          tag={"Observaciones del bloqueo"}
          size={200}
          desicion={false}
          maxLength={MaxLength.blocking.observations}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={handleReset}>
          Cancelar
        </ButtonStyled>
        {userQualified && country !== ""
          ? (
            <ButtonStyled type="submit" onClick={handleSubmit}>
              {action === "Desbloqueado"
                ? "Bloquear"
                : action === "Bloqueado"
                  ? "Desbloquear"
                  : action === "Bloquear" ||
                prueba === undefined ||
                prueba === "" ||
                prueba?.action === 0
                    ? "Bloquear"
                    : "no entro"}
            </ButtonStyled>
          )
          : (
            ""
          )}
      </Box>
    </>
  );
};

export default FormBlocking;
