/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import TbodyPermisos from "../tables/TbodyPermisos";
import { FormPermisos } from "../forms/FormPermisos";
import { TTablePermisos } from "../arrayRoles/Array_Rol";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import { __Permisos } from "request/configuration/__Roles";
import FormPermisosShow from "../FormShow/FormPermisosShow";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

export const PermissionsRule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setRequestEdit, setRequestCreate, setRequestDelete, setFormEdit, setFormShow, update } =
    useContext(ConfigurationCRUDContext);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const { q = "" } = queryString.parse(location.search);

  const formShowPermisos = () => {
    return <FormPermisosShow />;
  };

  const formEditPermi = () => {
    return <FormPermisos />;
  };

  useEffect(() => {
    setFormEdit(formEditPermi);
    setFormShow(formShowPermisos);
    setRequestEdit(environment.PERMISOS_UPDATE);
    setRequestCreate(environment.PERMISOS_CREATE);
    setRequestDelete(environment.PERMISOS_DELETE);
  }, [q, update]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configPermissionsRule", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.PERMISOS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Permisos" />
        <TableOrder
          thead={TTablePermisos}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyPermisos}
        />
        <Pagination page={page} setPage={setPage} maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1} loading={isLoading} />
      </SectionContainerGene>
    </>
  );
};
