export const cabeceraPrincipal = [
  { name: "Nº Formulario", order: null },
  { name: "Nit", order: null },
  { name: "Empresa", order: null },
  { name: "Plantilla", order: null },
  { name: "Producto", order: null },
  { name: "Estado", order: null },
  { name: "Acciones", order: null },
];

export const elaborateProduct = [
  { name: "Item", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Und. Prod", order: null },
  { name: "Cant. Prod.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Peso neto en kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Acciones", order: null },
];

export const MatterNoEdit = [
  { name: "Item", order: null },
  { name: "Referencia", order: null },
  { name: "Producto", order: null },
  { name: "Und. Producto", order: null },
  { name: "Prod, Cant.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Naturaleza", order: null },
  { name: "Oper", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Tipo DPS", order: null },
  { name: "%/Cnt", order: null },
  { name: "Seguimiento", order: null },
  { name: "Acuerdo", order: null },
  { name: "Descripción", order: null },
];

export const Matter = [
  { name: "Item", order: null },
  { name: "Referencia", order: null },
  { name: "Producto", order: null },
  { name: "Und. Producto", order: null },
  { name: "Prod, Cant.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Naturaleza", order: null },
  { name: "Oper", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Tipo DPS", order: null },
  { name: "%/Cnt", order: null },
  { name: "Seguimiento", order: null },
  { name: "Acuerdo", order: null },
  { name: "Descripción", order: null },
  { name: "Acciones", order: null },
];

export const MatterNoUCNoEdit = [
  { name: "Item", order: null },
  { name: "Referencia", order: null },
  { name: "Producto", order: null },
  { name: "Und. Producto", order: null },
  { name: "Prod, Cant.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Naturaleza", order: null },
  { name: "Oper", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Tipo DPS", order: null },
  { name: "%/Cnt", order: null },
  { name: "Acuerdo", order: null },
  { name: "Descripción", order: null },
];

export const MatterNoUC = [
  { name: "Item", order: null },
  { name: "Referencia", order: null },
  { name: "Producto", order: null },
  { name: "Und. Producto", order: null },
  { name: "Prod, Cant.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Naturaleza", order: null },
  { name: "Oper", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Tipo DPS", order: null },
  { name: "%/Cnt", order: null },
  { name: "Acuerdo", order: null },
  { name: "Descripción", order: null },
  { name: "Acciones", order: null },
];

export const HeadOtherCosts = [
  { name: "Item", order: null },
  { name: "Descripción", order: null },
  { name: "Valor en US$", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Acciones", order: null },
];

export const HeadOtherCostsNoEdit = [
  { name: "Item", order: null },
  { name: "Descripción", order: null },
  { name: "Valor en US$", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Valor unitario", order: null },
];

export const HeadGarbages = [
  { name: "Insumo", order: null },
  { name: "Tipo de producto", order: null },
  { name: "Referencia", order: null },
  { name: "Subpartida", order: null },
  { name: "Cantidad", order: null },
  { name: "Und.", order: null },
  { name: "Peso en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Descripción", order: null },
];

export const elaborateProductDos = [
  { name: "Item", order: null },
  { name: "Referencia", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Subpartida", order: null },
  { name: "Cant. Prod.", order: null },
  { name: "Und.Prod.", order: null },
  { name: "Cantidad", order: null },
  { name: "Und.", order: null },
  { name: "Peso neto en kilos", order: null },
  { name: "Valor en US$", order: null },
];
/*    { name: "Item", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Und. Prod", order: null },
  { name: "Cant. Prod.", order: null },
  { name: "Subpartida", order: null },
  { name: "Und.", order: null },
  { name: "Cantidad", order: null },
  { name: "Peso neto en kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Acciones", order: null }, */
