/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Divider, Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowGroup } from "request/configuration/__ShowGroup";
import { useSelector } from "react-redux";

export const FormShowGroup = () => {
  const [status, setStatus] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialForm = {
    name_permissionGroup: "",
    descriptions_permissionGroup: "",
    status_permissionGroup: status
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToShow) {
      //   setForm(dataToShow);
      const initialpos = async () => {
        try {
          const res = await __ShowGroup(token, dataToShow.id);
          setForm(res?.data?.status?.data);
          setStatus(res?.data?.status?.data.status_permissionGroup);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text fontSize="md" my={2} style={{ fontWeight: 600 }}>
        {/* {dataToEdit ? "Editar Licencia" : "Crear Licencia"} */}
        Grupo
      </Text>
      <Divider mb={3} />
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Nombre</LabeFormlStyled>
              <InputFake>{form.name_permissionGroup}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Descripción</LabeFormlStyled>
              <InputFake>
                {form.descriptions_permissionGroup === null
                  ? "---"
                  : form.descriptions_permissionGroup}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>¿Activo?</LabeFormlStyled>
              <InputFake>{form.status_permissionGroup === 1 ? "Activo" : "Inactivo"}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Modulo</LabeFormlStyled>
              <InputFake>{form.modul_permissionGroup}</InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};
