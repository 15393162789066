import React, { useContext, useEffect, useState, useCallback } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import {
  InputSelectMulti,
  InputSelectMultiZindex
} from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import InventoryControlContext from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FormNewFilter = ({ onCloseNewFilter }) => {
  const { setRequestCreate, createData } = useContext(InventoryControlContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataSubpartida, setDataSubpartida] = useState([]);
  const [valueSubpartida, setValueSubpartida] = useState([]);

  const [values, handleInputChange, reset] = useForm({
    category: "",
    category_description: "",
    searchInfo: ""
  });

  const { category, category_description, searchInfo } = values;

  const handleSubmit = () => {
    const idSuheading = [];

    if (valueSubpartida) {
      valueSubpartida.forEach(({ value }) => idSuheading.push(value));
    }
    const datatoSend = {
      category,
      category_description,
      category_identifier: idSuheading
    };
    createData(datatoSend);
  };

  useEffect(() => {
    setRequestCreate(environment.CREATE_INVENTORY_AUDIT_FILTER);
  }, [setRequestCreate]);

  const GetInventory = useCallback(
    async (data) => {
      try {
        const res = await __Post(
          environment.GET_INVENTORY_SUBHEADING,
          token,
          data
        );
        if (res?.data?.status.code === 200) {
          const request = res.data.status.data.map(
            ({ code_subheading, subheading_id }) => ({
              label: code_subheading,
              value: subheading_id
            })
          );
          setDataSubpartida(request);
        } else if (res?.data?.status?.data?.data.length === 0) {
          toast.error("No se han encontrado resultados", "error");
          setDataSubpartida([]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (searchInfo.length > 3) {
      const data = {
        search: searchInfo
      };
      GetInventory(data);
    }
  }, [searchInfo, GetInventory]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"category"}
          value={category}
          onChange={handleInputChange}
          tag={"Nombre de categoria"}
          size={45}
          desicion={false}
        />

        <LabelGroup
          name={"category_description"}
          value={category_description}
          onChange={handleInputChange}
          tag={"Descripción de categoría"}
          size={45}
          desicion={false}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"searchInfo"}
          value={searchInfo}
          onChange={handleInputChange}
          tag={"Buscar Subpartidas"}
          size={45}
          desicion={false}
          placeholder={"Digita palabra clave, EJ: Alimentos"}
        />

        <div style={{ width: `45%` }}>
          <InputSelectMultiZindex
            tag={"Subpartidas"}
            data={dataSubpartida}
            value={valueSubpartida}
            set={setValueSubpartida}
            className={"z-index-4"}
          />
        </div>
      </div>

      <Flex className="mt-5">
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseNewFilter}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Agregar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormNewFilter;
