import environment from "constants/apiConst";

export const arrayList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "qualifiedUser_id"
  },
  {
    value: "integration_certificate_code",
    label: "Formulario de certificado"
  },
  {
    value: "requesting_user",
    label: "Usuario que solicita"
  },
  {
    value: "requesting_date",
    label: "Fecha de solicitud"
  },
  {
    value: "requesting_time",
    label: "Hora de solicitud"
  },
  {
    value: "presenting_user",
    label: "Usuario que presenta"
  },
  {
    value: "presenting_date",
    label: "Fecha de presentación"
  },
  {
    value: "presenting_time",
    label: "Hora de presentación"
  },

  {
    value: "reviewed_user",
    label: "Ultimo Usuario que reviso"
  },
  {
    value: "reviewed_date",
    label: "Ultima fecha de revisión"
  },
  {
    value: "reviewed_time",
    label: "Ultima hora de revisión"
  },
  {
    value: "integration_certificate",
    label: "Certificado de integración"
  },
  {
    value: "authorizing_user",
    label: "Usuario que autoriza"
  },
  {
    value: "authorizing_date",
    label: "Fecha de autorización"
  },
  {
    value: "authorizing_time",
    label: "Hora de autorización"
  },

  {
    value: "finished_code",
    label: "Autorizacion de terminación"
  },
  {
    value: "finished_user",
    label: "Usuario que termina"
  },
  {
    value: "finished_date",
    label: "Fecha de terminación"
  },
  {
    value: "finished_time",
    label: "Hora de terminación"
  },
  {
    value: "nit",
    label: "NIT"
  },
  {
    value: "name_qualified",
    label: "Nombre del cliente"
  },
  {
    value: "last_item_product",
    label: "Ultimo item de producto"
  },

  {
    value: "last_item_supply",
    label: "Ultimo item de insumo"
  },
  {
    value: "last_item_garbage",
    label: "Ultimo item de desperdicio"
  },
  {
    value: "destination_code",
    label: "Destino"
  },
  {
    value: "last_item_other_cost",
    label: "Ultimo item de otros gastos"
  },
  {
    value: "return",
    label: "Nro. devoluciones"
  },
  {
    value: "net_weight_kilo_product",
    label: "Total peso neto producto"
  },
  {
    value: "quantity_product",
    label: "Total prod. elaborado"
  },

  {
    value: "net_weight_kilo_supply",
    label: "Total peso neto insumos"
  },
  {
    value: "quantity_supply",
    label: "Total insumos"
  },
  {
    value: "net_weight_kilo_other_cost",
    label: "Total peso neto otros costos"
  },
  {
    value: "quantity_other_cost",
    label: "Total otros costos"
  },
  {
    value: "decimal_values_precision",
    label: "Precision de valores"
  },
  {
    value: "participation_percentage",
    label: "% Participacion"
  },
  {
    value: "observations",
    label: "Observaciones"
  },
  {
    value: "integrationCertificateCorrected_code",
    label: "Indicador de corregido"
  },
  {
    value: "pending_product_return_balance",
    label: "Saldo de Reingreso de Producto Pendiente"
  },
  {
    value: "temporary_automatic_authorization_number",
    label: "Numero de autorizacion automatica temporal"
  },
  {
    value: "third_party",
    label: "Tercero"
  },
  {
    value: "term_expiration_date",
    label: "Fecha Venc. del Plazo"
  },
  {
    value: "item_code",
    label: "Item de insumo"
  },
  {
    value: "code_subheading",
    label: "Subpartida arancelaria"
  },
  {
    value: "business_unit",
    label: "Unidad comercial"
  },
  {
    value: "waste_percentage",
    label: "Porcentaje de desperdicio"
  },
  {
    value: "description_product",
    label: "Descripción"
  },
  {
    value: "quantity_item",
    label: "Cantidad de insumo"
  },
  {
    value: "net_weight_kilo_item",
    label: "Peso neto de insumo"
  },
  {
    value: "fob_value",
    label: "Vr. CIF US$ del insumo"
  },
  {
    value: "reference_description",
    label: "Referencia"
  },
  {
    value: "ci_inventory_code",
    label: "Certificado de integración"
  },
  {
    value: "customs_clearance",
    label: "Declaración de importación"
  },
  {
    value: "operation_code",
    label: "Codigo de operación"
  },
  {
    value: "initial_operation",
    label: "Operación inicial"
  },
  {
    value: "trade_agreement",
    label: "Acuerdo comercial"
  },
  {
    value: "nature_of_inventory",
    label: "Naturaleza"
  },
  {
    value: "status_supply",
    label: "Estado del insumo"
  },
  {
    value: "product_id",
    label: "Id del Producto"
  },
  {
    value: "quantity_supply_item",
    label: "Cantidad Producto de insumo"
  },
  {
    value: "status",
    label: "Estado"
  }
];

export const ciNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "PRESENTADO", label: "PRESENTADO" },
      { value: "APROBADO", label: "APROBADO" },
      { value: "AUTORIZADO", label: "AUTORIZADO" },
      { value: "DEVUELTO", label: "DEVUELTO" },
      { value: "ELIMINADO", label: "ELIMINADO" },
      { value: "CADUCADO", label: "CADUCADO" },
      { value: "ANULADO", label: "ANULADO" }
    ],
    value: null
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  }
]

export const arrayFieldsExportingInputMotionDataList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    name: "nit_qualified"
  },
  {
    value: "name_qualified",
    label: "Nombre del cliente",
    name: "name_qualified"
  },
  {
    value: "passForm_number",
    label: "Número de pase",
    name: "passForm_number"
  },
  {
    value: "type_transport_name",
    label: "Tipo de transporte",
    name: "type_transport_name"
  },
  {
    value: "vehicle_plate",
    label: "Placa del vehiculo",
    name: "vehicle_plate"
  },
  {
    value: "identification",
    label: "Identificación",
    name: "identification"
  },
  {
    value: "driver",
    label: "Conductor",
    name: "driver"
  },
  {
    value: "phone",
    label: "Teléfono",
    name: "phone"
  },
  {
    value: "empty_pass",
    label: "Pase vacío",
    name: "empty_pass"
  },
  {
    value: "container_1",
    label: "Contenedor 1",
    name: "container_1"
  },
  {
    value: "container_2",
    label: "Contenedor 2",
    name: "container_2"
  },
  {
    value: "capacity_container_1",
    label: "Capacidad contenedor 1",
    name: "capacity_container_1",
    dependency: "container_1"
  },
  {
    value: "capacity_container_2",
    label: "Capacidad contenedor 2",
    name: "capacity_container_2",
    dependency: "container_2"
  },
  {
    value: "tare_container_1",
    label: "Tara contenedor 1",
    name: "tare_container_1",
    dependency: "container_1"
  },
  {
    value: "tare_container_2",
    label: "Tara contenedor 2",
    name: "tare_container_2",
    dependency: "container_2"
  },
  {
    value: "number_packages",
    label: "Numero paquetes",
    name: "number_packages"
  },
  {
    value: "freely_available",
    label: "Libre disposición",
    name: "freely_available"
  },
  {
    value: "observations",
    label: "Observaciones",
    name: "observations"
  },
  {
    value: "status_entryPass",
    label: "Estado del pase",
    name: "status_entryPass"
  }
];
