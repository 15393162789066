import React from "react";
import { NationalTerritory } from "./FormExit/NationalTerritory";
import { RestOfTheWorld } from "./FormExit/RestOfTheWorld";
import { UserToUser } from "./FormExit/UserToUser";
import { useNotations } from "context/NotationsContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const ContainerFormExit = ({
  code,
  formOperation,
  values,
  handleInputChange,
  valueQualifiedToUser,
  setValueQuialifiedToUser
}) => {
  const location = useLocation();
  const { FormExitNumber = null } = queryString.parse(location.search);
  const { reviewsData, updateNotationData } = useNotations();
  const idForm = FormExitNumber;

  const reviewData = reviewsData?.Header;
  const colorNotation = (propertyName) => {
    return (
      updateNotationData?.[idForm]?.Header?.[propertyName]?.status?.color ||
      reviewData?.[propertyName]?.status?.color
    );
  };

  if (code === 50) {
    return (
      <NationalTerritory
        values={values}
        formOperation={formOperation}
        handleInputChange={handleInputChange}
        colorNotation={colorNotation}
        reviewData={reviewData}
      />
    );
  } else if (code >= 201 && code <= 299) {
    return (
      <RestOfTheWorld
        values={values}
        formOperation={formOperation}
        handleInputChange={handleInputChange}
        code={code}
        colorNotation={colorNotation}
        reviewData={reviewData}
      />
    );
  } else if (code >= 401 && code <= 499) {
    return (
      <NationalTerritory
        values={values}
        formOperation={formOperation}
        handleInputChange={handleInputChange}
        colorNotation={colorNotation}
        reviewData={reviewData}
      />
    );
  } else if (code >= 601 && code <= 699) {
    return (
      <RestOfTheWorld
        values={values}
        formOperation={formOperation}
        handleInputChange={handleInputChange}
        code={code}
        colorNotation={colorNotation}
        reviewData={reviewData}
      />
    );
  } else if (code >= 801 && code <= 899) {
    return (
      <UserToUser
        values={values}
        formOperation={formOperation}
        handleInputChange={handleInputChange}
        valueQualifiedToUser={valueQualifiedToUser}
        setValueQuialifiedToUser={setValueQuialifiedToUser}
        colorNotation={colorNotation}
        reviewData={reviewData}
      />
    );
  } else {
    return <div></div>;
  }
};
