import { useRef } from 'react';

const useUploadButton = () => {
  const inputRef = useRef(null);

  const handleUpload = () => {
    inputRef.current.click();
  };

  return { inputRef, handleUpload };
};

export default useUploadButton;
