/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { useNavigate } from "react-router-dom";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import OrderProductionContext from "context/OrderProductionContext";
import { BtnShowBorrador } from "./BtnShowBorrador";
import { BtnShowPresentado } from "./BtnShowPresentado";
import { BtnShowAutorizado } from "./BtnShowAutorizado";

import { VscFilePdf } from "react-icons/vsc";
import { MdQrCodeScanner } from "react-icons/md";
import { ExportPdf } from "components/Export/ExportPdf";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

import { useMutation } from "@tanstack/react-query";
import environment from "constants/apiConst";
import GlobalContext from "context/GlobalContext";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import ModalQR from "components/modal/ModalQR";

export const BtnShowOrder = ({ values, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { production_order_status, production_order_code } = values;
  const { requestData } = useContext(GlobalContext);

  const {
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason,
  } = useContext(OrderProductionContext);
  
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();

  const handleResponseDelete = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      return navigate(`/OrdenDeProduccion`);
    }
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: requestData,
  });

  const name = "Orden de producción Nº" + production_order_code;

  const handleEdit = () => {
    navigate(`/CrearOrdenDeProduccion?Orden=${values?.production_order_id}`);
  };

  const handleReview = (e) => {
    navigate(`/RevisarOrdenDeProduccion?Orden=${values?.production_order_id}`);
  };

  const {
    isOpen: isOpenWarning,
    onOpen: onOpenWarning,
    onClose: onCloseWarning,
  } = useDisclosure();

  const handleChangeStatus = (status) => {
    const info = {
      id: values?.production_order_id,
      status_ProductionOrderForm: status,
    };
    if (status === "DEVUELTO") {
      onOpenReturnReason();
      setReturnReason(info);
    } else {
      changeStatus(info);
    }
  };

  const handleCopy = () => {
    setDataToCopy(values);
    onOpenCopy();
  };

  const handleDelete = (id) => {
    console.log("values", values);
    mutate(
      {
        data: { id },
        Endpoint: environment.DELETE_PRODUCTION_ORDER,
        PropertyBody: "sendJSONContentPOST",
      },
      {
        onSuccess: (data) => handleResponseDelete(data),
      }
    );
  };

  return (
    <>
      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Eliminar item`}
        titleBody={`¿Desea eliminar el Formulario Nº ${values?.production_order_code}?`}
        generar={() => handleDelete(values?.production_order_id)}
      />

      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />

      <div className="row">
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            {(production_order_status &&
              production_order_status === "BORRADOR") ||
            production_order_status === "DEVUELTO" ? (
              <BtnShowBorrador
                handleEdit={handleEdit}
                handleChangeStatus={handleChangeStatus}
                handleCopy={handleCopy}
                handleDelete={onOpenWarning}
              />
            ) : production_order_status &&
              production_order_status === "PRESENTADO" ? (
              <BtnShowPresentado
                handleReview={handleReview}
                handleChangeStatus={handleChangeStatus}
                handleCopy={handleCopy}
              />
            ) : production_order_status &&
              production_order_status === "AUTORIZADO" ? (
              <BtnShowAutorizado
                handleChangeStatus={handleChangeStatus}
                handleCopy={handleCopy}
                data={values}
              />
            ) : (
              ""
            )}
          </Box>
        </div>
        {children}
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <ButtonHeaderModule
                onClick={onOpen}
                className="me-1"
                bgColor="#1F7AC3"
              >
                <IconGeneric
                  width="16px"
                  className=" text-white"
                  as={MdQrCodeScanner}
                />
              </ButtonHeaderModule>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Exportar PDF">
              <ButtonHeaderModule
                onClick={() => ExportPdf("pdfId", name)}
                bgColor="#03C39A"
              >
                <IconGeneric
                  width="16px"
                  className=" text-white"
                  as={VscFilePdf}
                />
              </ButtonHeaderModule>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};
