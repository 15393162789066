import { BaseOfExport } from "components/Export/BaseOfExport";
import { CabeceraCertificado } from "../Cabecera/CabezeraCertificado";
import ViewTable from "../ViewTables/ViewTable";
import Signature from "../Signature";

const ExporCertificated = () => {
  return (
    <div id="IdpdfCertificated">
      <BaseOfExport>
        <div
          style={{
            padding: "5px",
            marginLeft: "10px"
          }}
        >
          <CabeceraCertificado />
        </div>

        <div className="mt-4">
          <ViewTable />
        </div>

        <div className="final">
          <Signature />
        </div>
      </BaseOfExport>
    </div>
  );
};

export default ExporCertificated;
