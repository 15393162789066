import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import InputSelect from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";
import TreasuryCrudContex from "context/TreasuryContex";
import environment from "constants/apiConst";

import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { formatedInput } from "components/Funciones/FunctionFormateInput";
import { __PostForm } from "request/Petitions/__Post";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { Regex } from "utils/Regex";
import { BsInfoCircle } from "react-icons/bs";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import Hover from "components/HoverInfo";
import { parseFloat, removeDecimals } from "utils/parseFloat";
import Swal from "sweetalert2";

const FormRecharge = ({ onClose }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [userQualified, setUserQualified] = useState([]);
  const [typeRecharge, setTypeRecharge] = useState(null);
  const [typePayment, setTypePayment] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const [userValueQualified, setUserValueQualified] = useState(null);
  const { createData, setRequestCreate, dataToEdit, setRequestEdit } =
    useContext(TreasuryCrudContex);

  const dataType = [
    { value: 1, label: "Documentos" },
    { value: 2, label: "Pesaje de báscula" }
  ];

  const paymentMethod = [
    { value: 1, label: "Manual" },
    { value: 2, label: "Transferencia" }
  ];

  const queryClient = useQueryClient();
  const { data: dataRecharge } = queryClient.getQueryData([queryEnv?.TAXES_RECHARGES]);


  const { values, handleInputChange, reset, setValues } = useForm({
    initialState: {
      invoice: "",
      sale_value: 0,
      consumable_value: 0,
      observations: null,
      type_recharge: ""
    },
    onFieldChange: (name, value, target) => {
      const taxP = parseFloat((dataRecharge?.status?.data?.data[0]?.tax / 100)) + 1
      console.log({ value, parsed: parseFloat(value) })
      const tax = parseFloat(taxP ?? 1.19);
      if (name === "consumable_value") {
        /* console.log({ name, value }) */
      }
      if (name === "sale_value") {
        setValues((prev) => ({
          ...prev,
          consumable_value: parseFloat(parseFloat(value) / tax),
        }))
      }
    },
    returnType: RETURN_TYPES?.OBJECT,

  });

  const { invoice, sale_value, consumable_value, observations } = values;

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const data = res?.data?.status?.data.map((items) => ({
        value: items?.id,
        label: items?.name_qualified
      }));
      setUserQualified(data);
    } catch (error) { }
  }, [setUserQualified, token]);

  const getRechargeEdit = useCallback(async () => {
    const formData = new FormData();
    formData.append("recharge_id", dataToEdit?.recharge_id);
    try {
      const res = await __PostForm(
        environment.TREASURY_RECHARGE_GET,
        token,
        formData
      );
      const dataEditRecharge = res?.data?.status?.data;
      console.log(dataEditRecharge);
      setValues(dataEditRecharge);

      setUserValueQualified({
        value: dataEditRecharge?.qualifiedUser_id,
        label: dataEditRecharge?.name_qualified
      });

      setTypePayment({
        value: dataEditRecharge?.payment_method,
        label:
          dataEditRecharge?.payment_method === 1 ? "Manual" : "Transferencia"
      });

      setTypeRecharge({
        label:
          dataEditRecharge?.type_recharge === 1
            ? "Documentos"
            : "Pesaje de bascula",
        value: dataEditRecharge?.type_recharge
      });
    } catch (error) { }
  }, [token]);

  useEffect(() => {
    getUserQualified();
  }, [getUserQualified]);

  useEffect(() => {
    if (dataToEdit !== null) {
      getRechargeEdit();
    }
  }, [dataToEdit, getRechargeEdit]);

  useEffect(() => {
    // setRequestEdit(environment.TREASURY_RECHARGE_UPDATE);
    setRequestCreate(environment.TREASURY_RECHARGE_CREATE);
  }, [setRequestCreate, setRequestEdit]);

  const handleSubmitRecharge = (e) => {
    e.preventDefault();
    
    if (!invoice) {
      return openAlert("El campo Número de Factura es requerido.", "error");
    }

    const formData = new FormData();
    formData.append(
      "type_recharge",
      typeRecharge !== null ? typeRecharge.value : ""
    );
    formData.append(
      "qualifiedUser_id",
      userValueQualified !== null ? userValueQualified.value : ""
    );

    formData.append(
      "invoice",
      dataToEdit !== null
        ? invoice.indexOf("C") !== -1
          ? `C-${parseInt(invoice.split("-")[1]) + 1}-${invoice.split("-")[2]}`
          : `C-1-${invoice}`
        : invoice
    );
    formData.append("sale_value", removeDecimals({ number: sale_value, decimalPlaces: 2 }));
    formData.append("consumable_value", removeDecimals({ number: consumable_value, decimalPlaces: 2 }));
    formData.append(
      "payment_method",
      typePayment !== null ? typePayment.value : ""
    );
   if(observations) formData.append("observations", observations);

    if (Number(consumable_value) > Number(sale_value)) {
      return openAlert(
        "El valor consumible no puede ser mayor que el valor de venta",
        "error"
      );
    }

    if (dataToEdit !== null) {
      formData.append("recharge_id", dataToEdit?.recharge_id);
      createData(formData, onClose);

    } else {
      createData(formData, onClose, (res) => {
        Swal.fire({
          title: `${res?.message[0]}`,
          icon: "warning",
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          allowEscapeKey: true,
          confirmButtonColor: "#1F7AC3",
          confirmButtonText: "Confirmar",
          customClass: {
            title: "class-text",
          },
          preConfirm: () => {
            formData.append("omit", true);
            Swal.isLoading();
            createData(formData, () => {
              onClose();
              Swal.close();
            });
          },
          allowOutsideClick: () => !Swal.isLoading()
        });
      });
    }

  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <form onSubmit={handleSubmitRecharge}>
      <SimpleGrid minChildWidth="200px" gap={4}>

        <InputSelect
          tag={"Tipo"}
          size={100}
          data={dataType}
          required={true}
          set={setTypeRecharge}
          value={typeRecharge}
        />

        <InputSelect
          tag={"Método de pago"}
          size={100}
          required={true}
          data={paymentMethod}
          set={setTypePayment}
          value={typePayment}
        />
        <LabelGroup
          name={"invoice"}
          tag={"Factura"}
          required={true}
          size={100}
          value={invoice}
          onChange={(e) => handleRegex(e, 'all', Regex?.all)}
          desicion={false}
          maxLength={MaxLength.VentasDeServicios.saleRefills.invoice}
        />


        <InputSelect
          tag={"Factura a"}
          size={100}
          data={userQualified}
          set={setUserValueQualified}
          value={userValueQualified}
          required={true}
        />

        {/* <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={2}
          decimalSeparator="."
          customInput={LabelGroup}
          value={dataRecharge?.status?.data?.data[0]?.tax}
          placeholder="0"
          onChange={handleInputChange}
          tag={"Valor del IVA"}
          size={100}
          desicion={true}
        /> */}

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={2}
          decimalSeparator="."
          customInput={LabelGroup}
          value={removeDecimals({ number: sale_value, decimalPlaces: 2 })}
          placeholder="0"
          name={"sale_value"}
          onChange={handleInputChange}
          tag={"Valor de la venta"}
          size={100}
          desicion={false}
          maxLength={MaxLength.VentasDeServicios.saleRefills.sale_value}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={2}
          decimalSeparator="."
          customInput={LabelGroup}
          value={removeDecimals({ number: consumable_value, decimalPlaces: 2 })}
          placeholder="0"
          name={"consumable_value"}
          onChange={handleInputChange}
          tag={"Valor consumible"}
          size={100}
          desicion={false}
          maxLength={MaxLength.VentasDeServicios.saleRefills.consumable_value}
          TagChildren={
            <div className="">
              <Hover
                title={
                  "Cálculo del valor consumible"
                }
                description={
                  `El valor consumible no puede ser mayor que el valor de venta, este se calcula a partir del descuento del IVA sobre el valor de la venta. El porcentaje de descuento es de ${dataRecharge?.status?.data?.data[0]?.tax ?? 19}%.`
                }
                icon={<BsInfoCircle />}
                autoFocus={false}
                defaultIsOpen={false}

              />
            </div>
          }
        />
      </SimpleGrid>




      <SimpleGrid minChildWidth="200px" mt={4}>
        <LabelGroup
          name={"observations"}
          tag={"Observaciones"}
          size={100}
          value={observations}
          onChange={handleInputChange}
          desicion={false}
          maxLength={MaxLength.VentasDeServicios.saleRefills.observations}
        />
      </SimpleGrid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>

        <ButtonStyled
          type="submit"
        // isDisabled={}
        >
          {dataToEdit ? "Editar recarga" : "Crear recarga"}
        </ButtonStyled>
      </Box>
    </form>
  );
};

export default FormRecharge;
