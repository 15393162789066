import { Box, Center, Divider, Grid, GridItem, Text } from "@chakra-ui/react";
import { FaWeight } from "react-icons/fa";

export const CardWeighingNew = ({
  data,
  handleDelete,
  handleEdit,
  handleView
}) => {
  const { name } = data;

  return (
    <>
      <Box
        maxW="initial"
        p="2"
        m="1"
        shadow={
          "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
        }
        bg={"#f5f5f5"}
        borderRadius="lg"
        overflow="hidden"
        display={"flex"}
        /*  onClick={onOpen} */
      >
        <Box
          m="1"
          p="2"
          fontSize={"40px"}
          maxW="100px"
          borderRadius="lg"
          shadow={
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
          }
          bg={
            data.status === 1
              ? "#03c39a"
              : data.status === 0
                ? "#BA1F33"
                : "#03c39a"
          }
          textColor={"white"}
          w="25%"
          h="100%"
          alignSelf={"center"}
          mr="10px"
        >
          <Center flexDirection={"column"}>
            <FaWeight />
            <Divider w={"8"} my={1} />
          </Center>
        </Box>

        <Grid
          templateRows="repeat(2, auto)"
          templateColumns="repeat(6, 1fr)"
          gap={2}
        >
          <GridItem colSpan={4}>
            <Text fontWeight="semibold" as="h5" fontSize={"15px"}>
              {name}
            </Text>
          </GridItem>

          <GridItem colSpan={3} w="120px">
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Dirección: {data?.direction ? data?.direction : "N/A"}
              </b>
            </Text>
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Tasa de baudios: {data?.baud_rate ? data?.baud_rate : "N/A"}
              </b>
            </Text>
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Paridad: {data?.parity ? data?.parity : "N/A"}
              </b>
            </Text>
          </GridItem>

          <GridItem colSpan={3}>
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Estado: {data?.status === 1 ? "Activo" : "Inactivo"}
              </b>
            </Text>
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Bits de datos: {data?.data_bits ? data?.data_bits : "N/A"}
              </b>
            </Text>
            <Text fontSize={"11px"}>
              <b style={{ color: "dimgray" }}>
                Bits de parada:{data?.stop_bits ? data?.stop_bits : "N/A"}
              </b>
            </Text>
          </GridItem>
          <GridItem colSpan={6} flexDirection={"row"}>
            <Box display={"flex"} gap={3} w="full">
              <Text
                _hover={{
                  borderBottom: "1px solid black"
                }}
                cursor={"pointer"}
                onClick={() => handleDelete(data?.id, data?.name)}
                borderBottom="1px solid white"
              >
                Eliminar
              </Text>
              <Text
                cursor={"pointer"}
                onClick={() => {
                  handleEdit(data);
                }}
                _hover={{
                  borderBottom: "1px solid black"
                }}
              >
                Actualizar
              </Text>
              <Text
                _hover={{
                  borderBottom: "1px solid black"
                }}
                cursor={"pointer"}
                onClick={() => handleView(data)}
              >
                Ver
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};
