import { Flex, Spacer } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";

export const FormSearchReference = ({
  onClose,
  handleSearch,
  values,
  handleInputChange
}) => {
  const {
    reference,
    product_code,
    operation,
    subheading,
    import_declaration,
    certificate,
    description
  } = values;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"reference"}
          value={reference}
          onChange={handleInputChange}
          tag={"Referencia *"}
          size={30}
          tipo={"text"}
        />

        <LabelGroup
          name={"product_code"}
          value={product_code}
          onChange={handleInputChange}
          tag={"Dcl. Importación"}
          size={30}
          tipo={"text"}
        />

        <LabelGroup
          name={"operation"}
          value={operation}
          onChange={handleInputChange}
          tag={"Operación"}
          size={30}
          tipo={"text"}
          maxiLength={"3"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"import_declaration"}
          value={import_declaration}
          onChange={handleInputChange}
          tag={"Dcl. Importación"}
          size={30}
          tipo={"text"}
        />
        <LabelGroup
          name={"subheading"}
          value={subheading}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={30}
          maxLength={MaxLength?.global?.code_subheading}
        />

        <LabelGroup
          name={"certificate"}
          value={certificate}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={30}
          maxLength={MaxLength?.global?.code_subheading}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"description"}
          value={description}
          onChange={handleInputChange}
          tag={"Descripción"}
          size={100}
          tipo={"text"}
        />
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={() => handleSearch(values)}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
