/* eslint-disable no-unused-vars */
import { useModal } from "hooks/useModal";
import { AuthContext } from "./AuthContext";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import { isArray, isObject } from "utils/type-check-utils";

const { createContext, useState, useContext } = require("react");

const TreasuryCrudContex = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */

export const TreasuryCRUDProvider = ({ children }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [isOpenRemove, onOpenRemove, onCloseRemove] = useModal();
  const [isOpenRecharge, onOpenRecharge, onCloseRecharge] = useModal();
  const [isOpenIncidents, onOpenIncidents, onCloseIncidenst] = useModal();
  const [isOpenViewUpdate, onOpenViewUpdate, onCloseViewUpdate] = useModal();
  const [isOpenObservacion, onOpenObservacion, onCloseObservacion] = useModal();
  const [isOpenContainers, onOpenContainer, onCloseContainers] = useModal();
  const [isOpenForms, onOpenForms, onCloseForms] = useModal();

  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataContainer, setDataContainer] = useState([]);
  const [dataForms, setDataFroms] = useState([]);
  const [dataToObservacion, setDataToObservacion] = useState(null);

  const [dataToIncidents, setDataToIncidents] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [dataToRemove, setDataToRemove] = useState(null);
  const [dataToRecord, setDataToRecord] = useState(null);
  const [dataIdShow, setDataIdShow] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [requestEdit, setRequestEdit] = useState(null);
  const [requestExport, setRequestExport] = useState(null); // //
  const [requestCreate, setRequestCreate] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [orderProduccion, setOrderProduccion] = useState("");
  const [openAlert] = AlertErrorAlert();
  const [dataSpecial, setDataSpecial] = useState(null);
  const [status, setStatus] = useState(null);
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [update, setUpdate] = useState(true);
  const [statusRequest, setStatusRequest] = useState(true);
  const { authUser } = useContext(AuthContext);
  const toast = useToast();

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const editData = async (data) => {
    try {
      const res = await __Post(requestEdit, token, data);
      // console.log(res);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        onCloseEdit();
      } else {
        toast({
          title: `${res.data.status.message}`,
          status: "error",
          isClosable: true,
          position: "top"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportColumnBy = async (data, onCloseExport) => {
    try {
      const res = await __Post(requestExport, token, data);
      console.log(res);
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "success");
        onCloseExport();
      } else {
        // console.log(res);
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createData = async (data, sucessCallback = () => { }, errorCallback = null) => {
    try {
      const res = await __Post(requestCreate, token, data);
      // console.log(res.data.status);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        openAlert(`Registro creado Exitosamente`, "success");
        setUpdate(!update);
        sucessCallback && sucessCallback();
        return;
      }

      const messages = res.data.status.message;

      if (isArray(messages) && !errorCallback) {
        messages.forEach((message) => {
          openAlert(message, "error");
        });
      }

      if (messages && !isArray(messages) && !errorCallback) {
        openAlert(messages, "error");
      }

      !errorCallback && isObject(messages) && Object.values(messages).forEach((message) => {
        isArray(message) && message?.forEach((item) => openAlert(item, "error"));
      });

      errorCallback && typeof errorCallback === "function" && errorCallback(res.data.status);

    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
      return;
    }
    if (isOpenRecharge) {
      onCloseRecharge();
      setDataToEdit(null);
    }
    if (isOpenIncidents) {
      onCloseIncidenst();
      setDataToIncidents(null);
    }
    if (isOpenViewUpdate) {
      onCloseViewUpdate();
      setDataIdShow(null);
    }
    if (isOpenObservacion) {
      onCloseObservacion();
      setDataToObservacion(null);
      setStatus(null);
    }
    if (isOpenContainers) {
      setDataContainer(null);
      onCloseContainers();
    }
    if (isOpenForms) {
      setDataFroms(null);
      onCloseForms();
    }
  };

  const data = {
    editData,
    setStatus,
    status,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    isOpenDelete,
    onOpenDelete,
    isOpenRemove,
    onOpenRemove,
    onCloseRemove,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    dataToRemove,
    setDataToRemove,
    setDataToShow,
    // showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    // setDocument,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    orderProduccion,
    setOrderProduccion,
    isOpen,
    onOpen,
    onClose,
    isOpenRecharge,
    onOpenRecharge,
    onCloseRecharge,
    exportColumnBy,
    dataSpecial,
    setDataSpecial,
    dataToIncidents,
    setDataToIncidents,
    isOpenIncidents,
    onOpenIncidents,
    onCloseIncidenst,
    requestExport,
    setRequestExport,
    dataIdShow,
    setDataIdShow,
    isOpenViewUpdate,
    onOpenViewUpdate,
    onCloseViewUpdate,
    isOpenObservacion,
    onOpenObservacion,
    onCloseObservacion,
    dataToObservacion,
    setDataToObservacion,
    dataToRecord,
    setDataToRecord,
    dataContainer,
    setDataContainer,
    isOpenContainers,
    onOpenContainer,
    onCloseContainers,
    dataForms,
    setDataFroms,
    isOpenForms,
    onOpenForms,
    onCloseForms,
    statusRequest,
    setStatusRequest
  };

  return (
    <TreasuryCrudContex.Provider value={data}>
      {children}
    </TreasuryCrudContex.Provider>
  );
};

export default TreasuryCrudContex;
