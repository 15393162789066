/**
 * FuncionLetraNumero - Funcion que utilizamos en la propiedad onChange del input
 * para que solo permita digitar numero entero y cadena de texto sin espacion ni
 * caracteres especiales.
 *
 * @param {Function} setValues lo utilizamos para actualizar el estado.
 * @param {Object} values Objeto del donde guardaremos los datos solo tipo numero.
 * @return  Retorna entero.
 */

export const FuncionLetraNumberOnly = (e, setValues, values) => {
  const posibleResult = e.target.value
    .replace(/[^a-zA-Z0-9,]/g, "")
    .replace(/,/g, "");
  const nameVariable = e.target.name;
  return setValues({ ...values, [nameVariable]: posibleResult });
};
