import React from 'react'

import { Box, Icon, Text } from '@chakra-ui/react'

import { BsCalendar4Event } from 'react-icons/bs'
import CriteriaSummary from 'components/ControlAnalysis/CriteriaSummary'
import { HeaderSearchCustom } from 'styled/SectionContainer.styled'
import TableOrder from 'components/Tables/TableOrder'
import { theadCriteria } from '../thead/thead'
import TbodyUserRisk from '../TbodyUserRisk'
import { BaseOfExport } from 'components/Export/BaseOfExport'

export const LevelNotArray = level => {
  switch (level) {
    case 'ALTO':
      return 3
    case 'MODERADO':
      return 2
    case 'LEVE':
      return 1
    default:
      return null
  }
}

function ExportShowRiskLevelUser (
  {
    Showbar,
    CurrentRiskLevel,
    criteriaStatistic,
    setCurrentRiskLevel,
    currentPage,
    setCurrentPage,
    loading,
    ...props
  }

) {
  return (
    <div
      style={{
        display: 'none'
      }}
      {...props}
    >
      <BaseOfExport>
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            m={6}
            p={4}
            mb={0}
          >
            <Text fontSize={14} fontWeight={500} color={'gray'}>
              <Icon as={BsCalendar4Event} boxSize={6} color={'gray'} pr={1} />
              {Showbar?.date}
            </Text>

            <Text fontSize={14} fontWeight={500} color={'gray'}>
              Zona Franca
            </Text>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            m={6}
            p={4}
            mt={0}
            bg={'gray.100'}
            borderTop={'1px solid gray'}
          >
            <Text color={'gray'}>{Showbar?.free_zone?.shortName_freeZone}</Text>
            <Text color={'gray'}></Text>
          </Box>
        </>

        <CriteriaSummary
          criteriaStatistic={criteriaStatistic}
          set={setCurrentRiskLevel}
        />

        {CurrentRiskLevel?.criteria_id.length > 0 && (
          <Box
            display={'flex'}
            flexDir={'column'}
            m={6}
            p={6}
            borderRadius={'10px'}
            shadow={
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
            }
            maxW='full'
          >
            <HeaderSearchCustom margen='0' padding='3' Shadow='0'>
              <TableOrder
                thead={theadCriteria}
                data={currentPage ?? []}
                tbodyData={TbodyUserRisk}
                setData={setCurrentPage}
                loading={loading}
              />
            </HeaderSearchCustom>
          </Box>
        )}
      </BaseOfExport>
    </div>
  )
}

export default ExportShowRiskLevelUser
