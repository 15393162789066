import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuTesoreria from "components/Menu/MenuTreasury/MainMenuTreasury";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import TreasuryCrudContex from "context/TreasuryContex";
import { formaterCant } from "components/Funciones/formaterCant";
import styled from "styled-components/macro";
// import { BsCheckCircleFill } from "react-icons/bs";
import {
  BsCheckAll,
  BsFillCreditCardFill,
  BsCheckCircleFill
} from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa";
import { GiCardPlay } from "react-icons/gi";

const TbodySalesOfService = ({ data }) => {
  const { onOpenIncidents, setDataToIncidents } =
    useContext(TreasuryCrudContex);

  const incidencia = data?.incidents;

  const handleDataIncidents = () => {
    setDataToIncidents(data);
    onOpenIncidents();
  };

  const TdState = styled(Td)`
    color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
  `;
  return (
    <Tr>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.invoice}</Td>
      <Td className="text-center">{data?.recharge_code}</Td>
      <Td className="text-center">
        {data?.type_recharge === 1 ? "Documento" : "Pesaje de bascula"}
      </Td>

      <Td className="text-center">{data?.date}</Td>
      <Td className="text-center">{data?.nickname}</Td>
      <Td className="text-right">${formaterCant(data?.sale_value)}</Td>
      <Td className="text-center">${formaterCant(data?.consumable_value)}</Td>
      <Td className="text-center">{data && !data?.balance ? "N/A" : `$${formaterCant(data?.balance)}`}</Td>
      <Td className="text-center">{data && !data?.new_balance ? "N/A" : `$${formaterCant(data?.new_balance)}`}</Td>
      {/* <Td className="text-center">${formaterCant(data?.scale_balance)}</Td> */}
      {/* <Td className="text-center">${formaterCant(data?.document_balance)}</Td> */}
      <Td className="text-center">
        <Tooltip
          hasArrow
          placement="top"
          label={data?.payment_method === 1 ? "Manual" : "Transferencia"}
        >
          <TdState
            className="text-center"
            isNumeric
            text={data?.payment_method}
          >
            {data?.payment_method === 1
              ? (
                <IconGeneric color={"#03C39A"} as={GiCardPlay} />
              )
              : (
                <IconGeneric color={"#58B158"} as={BsFillCreditCardFill} />
              )}
          </TdState>
        </Tooltip>
      </Td>
      <Td className="text-center">
        <TdState
          className="text-center"
          isNumeric
          text={data?.status_qualified}
        >
          <IconGeneric
            color={data?.status_qualified === 1 ? "#03C39A" : "#bababa"}
            as={BsCheckCircleFill}
          />
        </TdState>
      </Td>
      <Td className="text-center">
        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.block_status === null || data?.block_status === 0
              ? "Activo"
              : "Bloqueado"
          }
        >
          <TdState className="text-center" isNumeric text={data?.block_status}>
            {data?.block_status === null || data?.block_status === 0
              ? (
                <IconGeneric color={"#03C39A"} as={BsCheckAll} />
              )
              : (
                <IconGeneric color={"#d92525"} as={FaRegTimesCircle} />
              )}
          </TdState>
        </Tooltip>
      </Td>

      {incidencia.length !== 0
        ? (
          <Td className="text-center">

            <IconGeneric
              color={"#1F7AC3"}
              as={BsEyeFill}
              onClick={handleDataIncidents}
            />
          </Td>
        )
        : (
          <Td className="text-center"></Td>
        )}
      <Td isNumeric className="text-center">
        {
          incidencia.length === 0
            ? (
              <MenuTesoreria data={data} />
            )
            : ""
        }
      </Td>
    </Tr>
  );
};

export default TbodySalesOfService;
