import { Box } from "@chakra-ui/react";
import React from "react";

const HeadboardList = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mt={5}
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default HeadboardList;
