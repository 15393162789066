import { Box, Input, Text, Tooltip } from '@chakra-ui/react';
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import React, { useState } from 'react'
import { AiOutlineUpload } from 'react-icons/ai';
import { FaRegTrashCan } from 'react-icons/fa6';
import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
`;
/**Ui de las cards que se usan para mostrar y crear archivos @param {object} item este es el archivo con su respectiva descripción y id @param {function} handleFiles es la función para modificar (añadir elementos) @param {function} deleteFile es la función para modificar (eliminar elementos)  */
const CardUploadFile = ({ item, handleFiles, deleteFile }) => {
  const [openAlert] = AlertErrorAlert();
  const [inputKey, setInputKey] = useState(item?.id);// estado para forzar el rerender del input que permite subir archivos de esta manera se soluciona un problema que hace que al por ejemplo subir un archivo sin una descripción y que este archivo no se suba por falta de dicha descripción el input no permita volver a subir el archivo ahora si con la descripción

  /**función para manipular los archivos @param e el evento del input de tipo file, permite capturar el File. @param action 'UPLOAD': permite modificar el item, 'DELETE': permite eliminar el item */
  const handleFileChange = (e, action) => {

    if (action === "UPLOAD") {
      if (!item?.document_description) {//validación para evitar subir un archivo sin descripción
        setInputKey(prevKey => prevKey + 1);
        openAlert('El campo descripción es obligatorio', 'error')
        return
      }

      const uploadedFile = { ...item, id: item?.id, file: e.target.files[0] };
      handleFiles(uploadedFile);
      return
    }
    if (action === "DELETE") {
      deleteFile(item?.id);
      setInputKey(prevKey => prevKey + 1);
      return
    }
  };

  /**estilos para centrar por medio de la propiedad de chakra "sx" */
  const center = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <Card>
      <Box p={2}>
        <Text mb='8px'>Descripción: <Text as={'span'} color={'red.500'}>*</Text></Text>
        <Input
          value={item?.document_description}
          onChange={(e) => {
            const uploadedFile = { ...item, document_description: e.target.value };
            handleFiles(uploadedFile);
          }}
          placeholder='descripción'
          size='sm'
          borderRadius="md"
        />
      </Box>
      <Box
        h='80px'
        color='blackAlpha.700'
        fontWeight='bold'
        mb={3}
      >
        <Box p='2' sx={center}>
          {/* Botón de carga de archivos */}
          <label htmlFor={item?.id} style={{ cursor: 'pointer' }}>
            <Tooltip label='Cargar' hasArrow placement='bottom'>
              <Box
                w={item?.file?.name || item?.document_name ? '50px' : '200px'}
                h="50px"
                mr={item?.file?.name || item?.document_name ? '' : '2'}
                bg="blue.500"
                borderRadius="md"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition="all 0.3s"
                _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
              >
                <AiOutlineUpload size={24} color="white" />
              </Box>
            </Tooltip>
            <Input
              type="file"
              id={item?.id}
              name={`file`}
              key={inputKey}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, "UPLOAD")}
            />
          </label>

          {/* Nombre del archivo */}
          {(item?.file?.name || item?.document_name) && (
            <>
              <Box style={{ overflow: 'hidden' }}
                w="100%"
                h="50px"
                bg={"#d8d8d8"}
                display={"flex"}
                alignItems="center"
                borderRadius="md"
                boxShadow="lg"
                transition="all 0.3s"
                _hover={{ transform: "scale(1.01)", boxShadow: "xl" }}
                mx={2}
                p={2}
              >
                <Text fontWeight="bold">{item?.file?.name || item?.document_name}</Text>
              </Box>
            </>
          )}
          {/* Botón de borrar el archivo */}
          <Tooltip label='Eliminar' hasArrow placement='top'>
            <Box
              w={item?.file?.name || item?.document_name ? '50px' : '44px'}
              h="50px"
              bg="red.500"
              borderRadius="md"
              boxShadow="lg"
              display="flex"
              alignItems="center"
              justifyContent="center"
              transition="all 0.3s"
              _hover={{ transform: "translateY(5px)", boxShadow: "xl" }}
              onClick={(e) => handleFileChange(e, "DELETE")}
            >
              <FaRegTrashCan size={22} color="white" />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  )
}

export default CardUploadFile
