/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'

import { RolForm } from '../forms/RolForm'
import environment from 'constants/apiConst'
import TbodyRoles from '../tables/TbodyRoles'
import FormRolShow from '../FormShow/FormRolShow'
import TableOrder from 'components/Tables/TableOrder'
import { TTableRoles } from '../arrayRoles/Array_Rol'
import { __Roles } from 'request/configuration/__Roles'
import ConfigurationHeader from '../ConfigurationHeader'
import Pagination from 'components/Pagination/Pagination'
import { SectionContainerGene } from 'styled/SectionContainer.styled'
import ConfigurationCRUDContext from 'context/ConfigurationCRUDContext'
import { useDebounce } from 'hooks/useDebouce'
import { useGlobalContext } from 'context/GlobalContext'
import { useQuery } from '@tanstack/react-query'

export const Roles = () => {
  const {
    setRequestEdit,
    setRequestCreateLicense,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext)
  const [currentPage, setCurrentPage] = useState([])
  const [page, setPage] = useState(1)

  const token = useSelector(state => state.auth.dataUser.token)
  const location = useLocation()
  const { q = '' } = queryString.parse(location.search)

  const formEditSub = () => {
    return <RolForm />
  }

  const formShowRol = () => {
    return <FormRolShow />
  }

  useEffect(() => {
    setFormEdit(formEditSub)
    setFormShow(formShowRol)
    setRequestEdit(environment.ROL_UPDATE)
    setRequestCreateLicense(environment.ROL_CREATE)
    setRequestDelete(environment.ROL_DELETE)
  }, [])

  const { requestData } = useGlobalContext()

  const { data, isLoading, isError } = useQuery({
    queryKey: ["userRoles", page, q, update],
    queryFn: () => requestData({
      Endpoint: environment.ROL_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return
    if (data?.data?.status?.data?.last_page < page) setPage(1)
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title='Roles' />
        <TableOrder
          thead={TTableRoles}
          data={
            data?.data?.status?.code === 200
              ? data?.data?.status?.data?.data
              : []
          }
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyRoles}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={
            data?.data?.status?.code === 200
              ? data?.data?.status?.data?.last_page
              : 1
          }
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  )
}
