/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer, useModalContext } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import {
  dateBy,
  statePassOrDispacth,
  tidyFMM
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useLocation } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";
import GlobalContext from "context/GlobalContext";
import { handleSanitizedObj, isArray } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

export const FormDocumentsCorrectOutput = ({
  onClose,
  dataTypeOperation
}) => {
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });
  const options = ["Único", "Fracionado"];
  const optiones = ["Ascendente", "Descendente"];
  const optionsRefund = ["Con reintegro", "Sin reintegro"];

  const [values, handleInputChange] = useForm({
    date_start: currentSearch?.date_start,
    date_end: currentSearch?.date_end,
    code_operation: currentSearch?.code_operation,
    code: currentSearch?.code,
    identification_num_name: currentSearch?.identification_num_name,
    time_start: currentSearch?.time_start,
    time_end: currentSearch?.time_end,
    nci: currentSearch?.nci,
    code_form_original: currentSearch?.code_form_original
  });
  const location = useLocation();

  const { pathname } = location;

  const {
    date_start,
    date_end,
    code_operation,
    code,
    identification_num_name,
    time_start,
    time_end,
    nci,
    code_form_original
  } = values;
  const [selectUF, setSelectUF] = useState(currentSearch?.shipment);
  const [refund, setRefund] = useState(currentSearch?.refund);
  const [datosBy, setDatosBy] = useState(currentSearch?.datosBy);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(currentSearch?.stateDispacthOrPass);

  const [typeOperation, setTypeOperation] = useState(currentSearch?.typeOperation);

  const [selectAD, setSelectAD] = useState(currentSearch?.orderby_type === 'ASC' ? 'Ascendente' : 'Descendente');

  const handleBusqueda = () => {

    const newObjetData = {
      ...values,
      order_by: selectAD === "Ascendente" ? "ASC" : "DESC",
      ...(selectUF && { shipment: selectUF }),
      ...(refund && { refund }),
      ...(datosBy && datosBy?.value && { date_from: datosBy?.value }),
      ...(isArray(stateDispacthOrPass) && stateDispacthOrPass.length > 0 && { status: stateDispacthOrPass.map(({ value }) => value) }),
      ...(typeOperation && typeOperation?.value && { type_operation_id: typeOperation?.value }),
      typeSearch: "document",
    };

    setParamsSearch({
      params: handleSanitizedObj({
        ...newObjetData,
        datosBy,
        stateDispacthOrPass,
        typeOperation,
      }),
    })

    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code"}
          value={code}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"code_operation"}
          value={code_operation}
          onChange={handleInputChange}
          tag={"Cod. Operacion"}
          size={45}
          tipo={"text"}
          maxLength={"3"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_start"}
          value={date_start}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_end"}
          value={date_end}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"time_start"}
          value={time_start}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"time_end"}
          value={time_end}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>

      <div className="row py-3">
        <div className="col-sm">
          <RadioBtn
            setOnly={setSelectUF}
            options={options}
            state={selectUF}
          />
          <br />
          <RadioBtn
            setOnly={setRefund}
            options={optionsRefund}
            state={refund}
          />
        </div>
        <div className="col-sm ms-5">
          <RadioBtn options={optiones} setOnly={setSelectAD} state={selectAD} />
          {/* {position === 0 && (
            <>
              <br />
              <RadioBtn
                setOnly={setDispacth}
                options={optionsDispacth}
                state={dispacth}
              />
            </>
          )} */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Fecha de:"}
            data={dateBy}
            value={datosBy}
            set={setDatosBy}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            name={"state_dispacth"}
            tag={"Estado"}
            data={statePassOrDispacth}
            value={stateDispacthOrPass}
            set={setStateDispacthOrPass}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Tipo Operación"}
            data={dataTypeOperation}
            value={typeOperation}
            set={setTypeOperation}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"identification_num_name"}
          value={identification_num_name}
          onChange={handleInputChange}
          tag={`NIT Importador/Comprador`}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"nci"}
          value={nci}
          onChange={handleInputChange}
          tag={"Nº Factura Comercial"}
          size={45}
          tipo={"text"}
        />
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"plate"}
          // value={plate}
          // onChange={handleInputChange}
          tag={"Cert. de Integración"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          //   name={"cargo_manifest"}
          //   value={cargo_manifest}
          onChange={handleInputChange}
          tag={"Dcl. de Importación"}
          size={45}
          tipo={"text"}
        />
      </div> */}

      {(pathname !== "/Form-tables-entry" ||
        pathname !== " /Form-tables-output") && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            {/* <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Ordenar por:"}
            data={tidyFMM}
            value={sortBy}
            set={setSortBy}
            />
        </div> */}
            <LabelGroup
              name={"code_form_original"}
              value={code_form_original}
              onChange={handleInputChange}
              tag={"Nº Formulario Original"}
              size={45}
              tipo={"text"}
            />
          </div>
        )}

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
