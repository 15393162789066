import React from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdRemoveRedEye } from "react-icons/md";

import { HiDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuSearchAdvance = ({ data, onClose }) => {
  const navigate = useNavigate();

  const SelectRouter = (status) => {
    if (status === "Formulario de ingreso") {
      navigate(`/FormShow?FormEntry=${data?.id}`);
      onClose();
    }

    if (status === "Formulario de salida") {
      navigate(`/FormShowExit?ExitNumber=${data?.id}`);
      onClose();
    }

    if (status === "Formulario de certificado de integración") {
      navigate(`/ShowIntegrationBox?id=${data?.id}`);
      onClose();
    }
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={() => SelectRouter(data.type_doc)}>
          <IconMenuRUD as={MdRemoveRedEye} />
          Ver
        </MenuItemStyled>

        {/*  <HrDividerNoMargin /> */}
      </MenuList>
    </Menu>
  );
};

export default MenuSearchAdvance;
