/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { __CommentaryForm, __ShowForm } from 'request/Forms/__ShowForm'
import env from '../../../constants/apiConst'

import {
  FormInputGroup,
  InputCustom,
  LabeFormlStyled
} from 'styled/Input.styled'
import {
  GetColor,
  FindCommentInput,
  ColorVerified,
  ReviewUpdates
} from 'hooks/useLocalStorage'
import FormViewRestWorld from './FormView/FormViewEntry/FormViewRestWorld'
import FormViewRestTerritory from './FormView/FormViewEntry/FormViewRestTerritory'
import FormViewFreeZone from './FormView/FormViewEntry/FormViewFreeZone'
import FormViewFromUserToUser from './FormView/FormViewEntry/FormViewFromUserToUser'
import ButtonView from './ButtonViewForms/ButtonView'
import { __GetItems } from 'request/Forms/_CrudForm'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import ModalCopy from 'components/modal/ModalCopy'
import { useSelector } from 'react-redux'
import environment from '../../../constants/apiConst'
import TableItems from './ViewItems/TableItems'
import CreateAt from './CreateAt'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { HeaderForm } from 'components/HeaderForm/HeaderForm'
import { FormReturnReason } from 'pages/Pass-Form/FormReturnReason'
import { AlertReturnReason } from 'components/AlertReasonReturn/AlertReturnReason'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import DocumentUploader from '../FormEntry/DocumentUploader/DocumentUploader'
import Vapor from 'laravel-vapor'
import Swal from 'sweetalert2'
import { TitleMovementIngreso } from 'pages/Pass-Form/View/ArrayHeadTable/TheadMovementInt'
import TBodyEntryMovement from 'pages/Pass-Form/tbody/TBodyEntryMovement'
import { __GetDataId } from 'request/Petitions/__Get'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import TableOrder from 'components/Tables/TableOrder'
import Pagination from 'components/Pagination/Pagination'
import { SubTitle } from 'styled/Title'
import { ButtonHeaderModule, ButtonStyled } from 'styled/Buttons.styled'
import { formaterCant } from 'components/Funciones/formaterCant'
import { InputWithLabel } from 'components/Input/InputFakeCustoms'
import { __GetTrackingsFmm } from 'request/Monitoring/__GetTrackingsFmm'
import { TheadShowTrackingItems } from '../ArrayHeaders/ArrayHeader'
import TBodyShowTrackingFmm from 'pages/Seguimiento/Table/TBodyShowTrackingFmm'
import { useModal } from 'hooks/useModal'
import { FormCreateFmmTracking } from 'pages/Seguimiento/form/FormCreateFmmTracking'
import MonitoringContext from 'context/MonitoringContext'
import { FormStopTrackingForm } from 'pages/Seguimiento/form/FormStopTrackingForm'
import { BiShowAlt } from 'react-icons/bi'
import { useFormatQuantity } from 'hooks/useFormatQuantity'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormCorrectionLog } from '../FormEntry/FormCorrectionLog'
import { __Post } from 'request/Petitions/__Post'
import CreatePDF from './ViewItems/CreatePDF'
import { ExportButton } from './ViewItems/CreatePDF/ExportButton'
import { AuthContext } from 'context/AuthContext'
import ModalRecoverForm from './ModalRecoverForm'
import { CommentManager } from 'components/DispatchCorrections/CommentManager/CommentManager'
import { Storage } from 'hooks/useStorage'
import { Action } from 'react-tiny-fab'
import { RiListSettingsLine, RiSearch2Line } from 'react-icons/ri'
import RenderItems from './ViewItems/RenderItems'
import { useDebounce } from 'hooks/useDebouce'
import { useNotations } from 'hooks/useGetInput'
import isArrayNotEmpty, {
  cleanDeepObject,
  isArray,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString
} from 'utils/type-check-utils'
import { useGlobalContext } from 'context/GlobalContext'
import useStatusListener from 'hooks/useStatusListener'
import { IconGeneric } from 'styled/Icons.styled'
import { VscFilePdf } from 'react-icons/vsc'
import { RadioBtn } from 'components/ComponentRadioButton/RadioBtn'
import InputSelect from 'components/InputSelect/InputSelect'
import { RETURN_TYPES, useForm } from 'hooks/useForm'
import ExistingFiles from 'pages/CustomsClearance/Adjunto/ExistingFiles'
import { convertArrayToGroupedObject } from '../FormEntry/BodyForm'
import EnhancedFileUploadManager from 'pages/CustomsClearance/MultipleFileUploader'


/**
 *  FormsView -  Componente el cual Maneja Vista completa del Formulario una vez creado.
 *
 * @component
 * @return {Component} Retorna componente vista de datos de formulario de entrada.
 */

export async function uploadFile(file, token) {
  return Vapor.store(file, {
    baseURL: environment.VAPOR_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}


export function useUploadFile({ token, onSuccess = () => {}, onComplete = () => {} }) {
  const [isSuccess, setIsSuccess] = useState(null)
  const [isFetching, setIsFetching] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [response, setResponse] = useState(null)

  const upload = async (file) => {
    try {
      setIsFetching(true);
      setIsLoading(true)
      const res = await Vapor.store(file, {
        baseURL: environment.VAPOR_BASE_URL,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
     
      setIsSuccess(true)
      setResponse(res)
      onSuccess(res, file)
      onComplete(res, file)  // Call the onComplete callback
      return res

    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsFetching(false);
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isSuccess,
    isFetching,
    response,
    upload
  };
}
const commentTemplate = {
  renderProperties: {
    make: 'make_comment',
    date: 'date_comment',
    comment: 'comment',
    person: 'person_name'
  },
  functionProperties: {
    handleComment: 'comment'
  }
}

function createKey(documentReqId) {
  return 'document-' + documentReqId
}

function createObjectFromItem(item, index) {
  return {
    name: item.attachment_name,
    document_id: item.documentReq_id,
    downloadFile: item.attachment_url,
    from_server: true,
    id: item.id,
    originalIndex: index,
    attachment_number: item.attachment_number,
    attachment_description: item.attachment_description,
    attachment_comment: item.attachment_comment
  }
}

const FormsView = () => {
  const {
    token,
    is_commerce,
    is_qualified_user,
    admin,
    user_rol: { modules }
  } = useSelector(state => state.auth.dataUser)

  const location = useLocation() // navigate
  const { pathname } = location
  const { FormEntry = null } = queryString.parse(location.search)
  const [openAlert] = AlertErrorAlert()
  const [searchItem, setSearchItem] = useState('')
  const Searchdebounced = useDebounce(searchItem, 500)
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })


  async function fetchServerPage({ pageParam = 1 }) {
    const formData = new FormData()
    formData.append('entryForm_id', FormEntry)
    formData.append('length', 5)
    searchItem && formData.append('item', searchItem)
    orderBy?.value && formData.append('orderBy', orderBy?.value)

    const res = await __GetItems(
      token,
      formData,
      pageParam,
      environment.GET_iTEMS
    )

    const page =
      pageParam === res?.data?.status?.data?.last_page ? null : pageParam + 1
    return {
      items: res?.data?.status?.data?.data,
      nextCursor: page
    }
  }

  const {
    status,
    data,
    error: isErrorPage,
    isFetching,
    isLoading: isLoadingItems,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchItems
  } = useInfiniteQuery({
    queryKey: ['fetchServerPage', Searchdebounced, orderBy],
    queryFn: fetchServerPage,
    getNextPageParam: lastPage => lastPage.nextCursor,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const allItems = data ? data.pages.flatMap(d => d.items) : []

  const initialForm = {
    num_dex: '',
    dex_date: '',
    exit_auth: '',
    inspection: '',
    operation_id: '',
    cormagdalena: '',
    department_id: '',
    num_exit_form: '',
    thirdPartie_id: '',
    code_entryForm: '',
    purchase_order: '',
    transp_doc_date: '',
    qualifiedUser2_id: '',
    shipment_entryForm: '',
    dcl_customs_transit: '',
    num_transport_document: '',
    num_commercial_invoice: '',
    num_import_declaration: '',
    customs_administration: '',
    inspection_act_or_auto: '',
    transit_expiration_date: '',
    cargo_manifest_entryForm: '',
    num_exit_form: '',
    integration_box_certificate: '',
    date_entryForm: '',
    status_entryForm: '',
    name_qualified: '',
    nit_qualified: '',
    comments: '',
    block_for_subheading_filling: '',
    block_for_subheading_approval: '',
    reviews: []
  }

  const [currentPage, setCurrentPage] = useState([])
  const [form, setForm] = useState(initialForm)

  const [precision, setPrecision] = useState(0)

  const [code, setCode] = useState(null)
  const [user, setUser] = useState([])
  const navigate = useNavigate()
  const [files, setFiles] = useState({})
  const [nameFiles, setNameFiles] = useState([])
  const [attachment, setAttachment] = useState([])
  const [attachmentType, setAttachmentType] = useState([])
  const [costCorrect, setCostCorrect] = useState(null)
  const [isOpenShowItems, onOpenShowItems, onCloseShowItems] = useModal()
  const [loadTrackings, setLoadTrackings] = useState([])
  const [currentPageTrackings, setCurrentPageTrackings] = useState([])
  const [followOrder, setFollowOrder] = useState([])
  const [otherDocuments, setOtherDocuments] = useState([])
  const [otherFiles, setOtherFiles] = useState([])

  const {
    isOpenCreateTrackingFmm,
    onOpenCreateTrackingFmm,
    onCloseCreateTrackingFmm,
    isOpenStopTracking,
    onOpenStopTracking,
    onCloseStopTracking
  } = useContext(MonitoringContext)
  const [fomateo] = useFormatQuantity()

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const {
    isOpenCopy,
    onCloseModal,
    copyData,
    isOpenDesechar,
    onCloseDesechar,
    dataToDesechar,
    changeStatus,
    update,
    setRequestCreatePassNew,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    setDocumentOperation,
    DocumentOperation,
    deleteAttachment,
    isOpenCaducar,
    dataToCaducar,
    dataToAnular,
    isOpenAnular,
    isOpenCorrection,
    onOpenCorrection,
    onCloseCorrection,
    dataCorrection,
    dataCostos,
    CorrectDocument,
    setRequestChangeStatusForm,
    setRequestCopy,
    setTypeEntry,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
    showCorrectBalance,
    setshowCorrectBalance,
    editDataForm,
    setRequestEdit,
    isOpenDesCaducar,
    onOpenDesCaducar,
    onCloseDesCaducar
  } = useContext(FormCrudCRUDContext)
  const { authUser } = useContext(AuthContext)
  const [temporalBlock, setTemporalBlock] = useState(0)

  const ObtenerPases = async () => {
    setLoading(true)
    try {
      const res = await __GetDataId(
        environment.OBTENER_PASS_OF_FMMI,
        token,
        FormEntry
      )
      if (res?.data?.status?.code === 200) {
        setCurrentPage(res?.data?.status?.data)
      } else {
        openAlert(res?.data?.status.message, 'error')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const { requestData } = useGlobalContext()
  const queryClient = useQueryClient();

  const { mutate, mutateAsync} = useMutation({
    mutationFn: requestData
  })

  const cleanFile = (file) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ?? file?.name_attachment ??  file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint:  environment.DELETE_OTHER_ATTACHMENT_ENTRY,
          resourceIdentifier: file?.id,
   
        };

        mutate(requestData, {
          onSuccess: () => {
            setOtherFiles([]);
            setOtherDocuments([]);
            queryClient.invalidateQueries({ queryKey: ["viewForms"] });
          },
        });
      }
    });
  };
  

  useEffect(() => {
    setRequestCreatePassNew(environment.CREATE_PASES_IN)
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS)
    setRequestCopy(environment.COPY_FORMS)
    setRequestEdit(environment.UPDATE_FORM_ENTRY)

    if (FormEntry) {
      ObtenerPases()
    } else {
      setForm(initialForm)
    }
    clean(FormEntry)
  }, [FormEntry, update])

  useEffect(() => {
    if (dataCostos) {
      const respuestaFind = dataCostos?.values?.find(
        e => e.document === 'Anexo Corregido de Ingreso'
      )
      const { value } = respuestaFind
      setCostCorrect(value)
    }
  }, [dataCostos])

  const handlePresent = status => {
    const info = {
      id: dataToDesechar.id,
      status_entryForm: status
    }
    changeStatus(info)
    onCloseDesechar()
  }

  const handleCaducar = status => {
    const info = {
      id: dataToCaducar.id,
      status_entryForm: status
    }
    changeStatus(info)
    onCloseModal()
  }

  const handleAnular = status => {
    const info = {
      id: dataToAnular.id,
      status_entryForm: status
    }
    changeStatus(info)
    onCloseModal()
  }

  const sumPropertyValue = (items, propertyName) => {
    const sum = items?.reduce(
      (total, item) =>
        total + Number(item[propertyName]?.replaceAll(',', '') || 0),
      0
    )
    return sum
  }

  function formatNumber(number, decimalPlaces) {
    const decimalSeparator = '.'
    const thousandsSeparator = ','
    const roundedNumber = Number.parseFloat(number).toFixed(decimalPlaces)
    const [integerPart, decimalPart] = roundedNumber.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator
    )
    return `${formattedIntegerPart}${decimalPlaces > 0 ? decimalSeparator : ''
      }${decimalPart || '0'.repeat(decimalPlaces)}`
  }

  const handleFiles = (e, idFiles) => {
    e.preventDefault()
    document.getElementById(`${idFiles}`).click()
  }

  const extensionOptions = [
    '.doc',
    '.docx',
    '.pdf',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.bmp',
    '.xls',
    '.xlsx',
    '.xlsm',
    'image/png',
    'image/*',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-excel'
  ]


  const handleFilesInput = e => {
    e.preventDefault()
    const name = e.target.name
    const Files = e.target.files

    const getFileExtension = files[0].name.split('.').pop()
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`)

    if (!isValidExtension) {
      const fileName = files[0].name
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        'error'
      )
    }

    Vapor.store(Files[0], {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      setFiles(
        Object.assign(
          { ...files },
          {
            [e.target.id]: {
              file: response,
              name: Files[0].name,
              document_id: e.target.id.replace('document-', '')
            }
          }
        )
      )
      setNameFiles([...nameFiles, { name }])
    })
  }

  const {
    isLoading: isLoadingViewForm,
    data: dataViewForm,
    refetch: refetchViewForm,
    isFetching: isFetchingViewForm,
    isSuccess: isSuccessViewForm,
    isError: isErrorViewForm
  } = useQuery({
    queryKey: ['viewForms', FormEntry, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.FORMS_ENTRY_VIEW,
        resourceIdentifier: FormEntry
      }),
    refetchOnWindowFocus: false
  })



  const updateAndCreate = async (newAttachment = undefined) => {
    const formData = new FormData();
    formData.append('id', FormEntry);
  
    if (newAttachment) {
      const vaporRes = await uploadFile(newAttachment.file, token);
      
      formData.append('other_attachment[]', JSON.stringify({ 
        file: vaporRes, 
        name: newAttachment.file.name 
      }));
      formData.append('other_attachment_description[]', newAttachment.other_attachment_description);
      formData.append('other_attachment_comment[]', newAttachment.other_attachment_comment);
      formData.append('other_attachment_number[]', newAttachment.other_attachment_number);
      formData.append('other_attachment_type[]', newAttachment.extension);
      formData.append('other_attachment_date[]', newAttachment.other_attachment_date);
    }
  
    const requestData = {
      data: formData,
      Endpoint: environment.UPDATE_FORM_ENTRY,
      PropertyBody: 'sendFormDataContentPOST'
    };
  
    mutate(requestData, {
      onSuccess: (res) => {
        if (res?.data?.status?.code === 200) {
          queryClient.invalidateQueries({ queryKey: ["viewForms"] })
         // setOtherDocuments(prevDocs => [...prevDocs, res.data.status.data]);
          setAttachment([]);
          openAlert('Documento cargado exitosamente', 'success');
        } else {
          openAlert(res?.data?.status?.message || 'Error al cargar el documento', 'error');
        }
      },
      onError: (error) => {
        openAlert('Error al cargar el documento', 'error');
      }
    });
  };

  const handleResponseUpdateForm = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      refetchViewForm()
      //  return setCalculated(res?.data?.status?.data);
    }
  }

  const removeItemFromArr = (archivo, nombre, server = false) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${files?.[archivo]?.name}"?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1F7AC3',
      confirmButtonText: 'Eliminar',
      customClass: {
        title: 'class-text'
      }
    }).then(resultado => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          const obj = Object.assign({}, { ...files })
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo]
            if (files[archivo].from_server) {
              deleteAttachment(
                environment.DELETE_ATTACHMENT_ENTRY,
                files[archivo].id,
                '',
                setFiles,
                obj,
                () => refetchViewForm()
              )
            } else {
              setFiles(obj)
            }
          }
        }
      }
    })
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      const dataForm = dataViewForm?.data?.status?.data

      if (isErrorViewForm) {
        openAlert(dataForm?.data?.status.message, 'error')
        navigate(`/`)
        return
      }

      if (isSuccessViewForm) {
        setForm(dataForm)
        setPrecision(dataForm?.precision)
        setCode(parseInt(dataForm?.code_operation))
        setUser(dataForm?.user)
        setDocumentOperation(dataForm?.documents)
        setOtherDocuments(dataForm?.other_files)
        const otherFiles = convertArrayToGroupedObject(
          dataForm?.other_files || [],
          "other_files"
        );

        setOtherFiles(otherFiles);
        if (dataForm.check_rm) setTypeEntry(dataForm.check_rm)

        const fileServer = dataForm.files.reduce((old, it, index) => {
          return {
            ...old,
            ['document-' + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
              originalIndex: index,
              attachment_number: it.attachment_number,
              attachment_description: it.attachment_description,
              attachment_comment: it.attachment_comment,
              attachment_date: it.attachment_date,
            }
          }
        }, {})

        setFiles({
          ...fileServer
        })

        // setOtherDocuments(dataForm?.other_files)
      }
    }
  }, [isFetchingViewForm])

  const firstRender = useRef(true)

  const correct = async id => {
    setLoading(true)
    try {
      const res = await __ShowForm(token, id, environment.FORMS_ENTRY_VIEW)
      const resultado = res?.data?.status
      const { code, data } = resultado
      const dataCorrectionBalance = {
        ...data,
        balance_correction: 1
      }
      if (code === 200) {
        // CorrectDocument(environment.CORRECT_FMMI, data);
        onCloseCorrection()
        if (showCorrectBalance) {
          CorrectDocument(
            environment.CORRECT_FMMI,
            dataCorrectionBalance,
            hanldeCancel
          )
        } else {
          CorrectDocument(environment.CORRECT_FMMI, data, hanldeCancel)
        }
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const hanldeCancel = () => {
    onCloseCorrection()
    setshowCorrectBalance(false)
  }

  const handleChange = id => {
    const infoEntryCorrect = {
      id,
      status_entryForm: 'BORRADOR',
      retrieved: 'RECUPERADO',
      movement: 'Ingreso'
    }

    changeStatus(infoEntryCorrect)
  }

  const showTrackingItem = async (trackings, reference, itemId) => {
    setLoadTrackings(true)
    try {
      const formData = new FormData()
      trackings?.map((trackings, index) => {
        formData.append(`tracking_id[${index}]`, trackings?.tracking_id)
      })
      formData.append(`reference`, reference)
      formData.append(`item_id`, itemId)
      const res = await __GetTrackingsFmm(token, formData)
      setCurrentPageTrackings(
        res?.data?.status?.data?.data?.map(item => ({
          ...item,
          reference,
          item_id: itemId
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      onOpenShowItems()
      setLoadTrackings(false)
    }
  }

  const CommentFindTooltip = (id, data) => {
    /*     const Find = data?.find((x) => {
      return x?.form_field === id;
    });

    if (Find && Find?.comment?.length > 0) {
      return Find?.comment;
    } */
  }

  const GetTooltipText = names => {
    const ResColor = names?.map(x => {
      if (form?.review_updates) {
        return ReviewUpdates(x, form?.review_updates)?.Before
      } else {
        return FindCommentInput(x, form)
      }
    })

    const validValues = ResColor.filter(value => {
      return value != null
    })

    return validValues[0] ?? ''
  }

  const GetColorInput = names => {
    const ResColor = names?.map(x => {
      if (form?.review_updates) {
        return ReviewUpdates(x, form?.review_updates)?.Color
      } else {
        return ColorVerified(x, form)
      }
    })

    const validValues = ResColor.filter(value => {
      return value != null
    })

    return validValues[0] ?? '#bababa80'
  }

  const handleNetWeigh = () => {
    if (currentPage && currentPage.length > 0) {
      const arrayPesos = currentPage.map((element, index) => {
        if (element.weinghings[index] !== undefined) {
          return element.weinghings[index]
        }
      })
      const validValues = arrayPesos.filter(value => {
        return value !== undefined
      })
      const total = sumPropertyValue(validValues, 'net_weight')
      return total
    }
  }
  const isAuthorizationDeletedDocument =
    form?.status_entryForm !== 'BORRADOR' && is_commerce === 1

  const isAuthorizedToLockUnlock = modules?.some(item =>
    item.permission.some(
      name => name.name_permission === 'lock_and_unlock_entry_form'
    )
  )

  const isNotQualifiedUser = is_qualified_user !== 1

  const viewLockUnlockApproval =
    (form?.block_for_subheading_approval === 1 || temporalBlock !== 0) &&
    form?.status_entryForm === 'PRESENTADO'

  const viewLockUnlockFilling =
    (form?.block_for_subheading_filling === 1 || temporalBlock !== 0) &&
    form?.status_entryForm === 'APROBADO'

  const lockUnlock =
    form?.status_entryForm === 'PRESENTADO'
      ? form?.block_for_subheading_approval === 1
        ? 'Desbloquear'
        : 'Bloquear'
      : form?.status_entryForm === 'APROBADO' &&
        form?.block_for_subheading_filling === 1
        ? 'Desbloquear'
        : 'Bloquear'

  const bLockForm =
    form?.status_entryForm === 'PRESENTADO'
      ? form?.block_for_subheading_approval === 1
        ? 0
        : 1
      : form?.status_entryForm === 'APROBADO' &&
        form?.block_for_subheading_filling === 1
        ? 0
        : 1

  const UnlockFormFilling = async () => {
    try {
      Swal.fire({
        title: `¿Estas seguro de ${lockUnlock.toLowerCase()} el formulario "${form?.id
          }"?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#1F7AC3',
        confirmButtonText: 'Confirmar',
        customClass: {
          title: 'class-text'
        }
      }).then(async resultado => {
        if (resultado.isConfirmed) {
          const data = {
            entryForm_id: form?.id,
            block: bLockForm,
            type_block:
              form?.status_entryForm === 'PRESENTADO'
                ? 'block_for_subheading_approval'
                : form?.status_entryForm === 'APROBADO'
                  ? 'block_for_subheading_filling'
                  : ''
          }
          const res = await __Post(env.LOCK_AND_UNLOCK_ENTRY_FORM, token, data)
          if (res?.data?.status?.code === 200) {
            console.log(res?.data?.status?.data)
            setForm({
              ...form,
              block_for_subheading_filling:
                res?.data?.status?.data?.block_for_subheading_filling,
              block_for_subheading_approval:
                res?.data?.status?.data?.block_for_subheading_approval
            })
            setTemporalBlock(1)
            return openAlert(res?.data?.status?.message, 'success')
          }
        }
      })
    } catch (error) { }
  }

  const name = 'FMM_INGRESO_' + form?.code_entryForm

  const { clean } = Storage

  const [listMode, setlistMode] = useState(false)

  const switchLoadingItem = () => {
    setlistMode(prev => !prev)
  }

  const handleChangeSearch = e => {
    setSearchItem(e.target.value)
  }

  const notationsDestruct = useNotations({
    storageKey: FormEntry,
    notationTypes: [
      {
        color: 'gray',
        enableComment: false
      },
      {
        color: 'green',
        enableComment: false
      },
      {
        color: 'red',
        enableComment: true
      }
    ],
    reviews: form?.reviews,
    reviewsUpdates: form.review_updates,
    reviewsNew: form?.reviews_new ? cleanDeepObject(form?.reviews_new) : {}
  })

  const { getNestedPropertyValue, getColorByFieldName } = notationsDestruct

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      flag === 'entry_form' &&
      new_status !== dataCurrent.status_entryForm &&
      new_status !== 'REVISADO' &&
      form_id === dataCurrent.id

    if (shouldRefetch) {
      ObtenerPases()
      refetchViewForm()
      refetchItems()
    }
  }

  useStatusListener({
    id: form?.id,
    callBack: refetchForm,
    dataCurrent: form
  })


  const [isFetchingPDF, setIsFetchingPDF] = useState(false);

  const handleExportPdf = () => {
    setIsFetchingPDF(true);
    mutate(
      {
        data: {
          entryForm_id: FormEntry,
        },
        Endpoint: environment.EXPORT_PDF_ENTRYFORM,
        PropertyBody: "POST",
      },
      {
        onSuccess: (res) => {
          if (res?.data?.status?.code === 200) {
            openAlert(res?.data?.status?.data, "success");
          }
          setIsFetchingPDF(false);
        },
        onError: () => {
          setIsFetchingPDF(false);
        }
      }
    );
  };


  const handleUpload = async (files) => {
 const payloadManual = false
    console.log({
       files
    })
    
    const formData = new FormData()

    for (const fileM of files) {
      const vaporRes = await uploadFile(fileM);
   
      
      formData.append('id', FormEntry)
    const dataAttachments = files[0];

    dataAttachments?.id && formData.append('other_attachment_id[]', dataAttachments?.id)
    const file = {
      file: vaporRes
    }
    formData.append('other_attachment[]', JSON.stringify({ ...file, name: dataAttachments?.file?.name }));
    dataAttachments?.other_attachment_description && formData.append(
      'other_attachment_description[]',
      dataAttachments?.other_attachment_description
    )
    dataAttachments?.other_attachment_comment && formData.append(
      'other_attachment_comment[]',
      dataAttachments?.other_attachment_comment
    )

    dataAttachments?.other_attachment_number && formData.append(
      'other_attachment_number[]',
      dataAttachments?.other_attachment_number
    )

    dataAttachments?.extension && formData.append('other_attachment_type[]', dataAttachments?.extension)

    const date = dataAttachments?.other_attachment_date || dataAttachments?.attachment_date
    date && formData.append(
      'other_attachment_date[]', date
    )

    dataAttachments?.attachment_date && formData.append(
      'attachment_date[]', dataAttachments?.attachment_date
    )

    }

    
    if (isObject(files) && isObjectNotEmpty(files) && !payloadManual) {
      if (files) {
        Object.keys(files).forEach(documentId => {
          if (!files[documentId].from_server) {
            formData.append(
              'attachment[' + documentId + ']',
              JSON.stringify(files[documentId])
            )
          }
        })
      }
    }

    const request = {
      data: payloadManual ? files : formData,
      Endpoint: environment.UPDATE_FORM_ENTRY,
      PropertyBody: 'sendFormDataContentPOST'
    }

    setOtherDocuments([])
    setAttachment([])
    setFiles({})

    return  mutateAsync(request, {
      onSuccess: handleResponseUpdateForm
    })

    // Implementa tu lógica de carga aquí
    // Si hay un error, lanza una excepción
  /*   if (condición de error) {
      throw new Error('Error al cargar los documentos');
    } */
    // Retorna el resultado si es exitoso
  };

  const handlePreview = async (document) => {
    console.log({
      document
    })
    // Implementa tu lógica de previsualización aquí
    // Si hay un error, lanza una excepción
  /*   if (condición de error) {
      throw new Error('Error al previsualizar el documento');
    } */
    // Retorna la URL o los datos de previsualización
  };

  
  const getAuthToken = async () => {
    return token;
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenCreateTrackingFmm}
        onOpen={onOpenCreateTrackingFmm}
        onClose={onCloseCreateTrackingFmm}
        title={'Crear seguimiento'}
      >
        <FormCreateFmmTracking codeForm={form?.code_entryForm} formType={2} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenStopTracking}
        onOpen={onOpenStopTracking}
        onClose={onCloseStopTracking}
        title={'Detener seguimiento'}
      >
        <FormStopTrackingForm
          formType={2}
          followOrder={followOrder}
          idForm={form?.id}
          codeForm={form?.code_entryForm}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenShowItems}
        onOpen={onOpenShowItems}
        onClose={onCloseShowItems}
        title={'Seguimiento'}
      >
        <TableOrder
          thead={TheadShowTrackingItems}
          data={currentPageTrackings}
          setData={setCurrentPageTrackings}
          loading={loadTrackings}
          tbodyData={TBodyShowTrackingFmm}
          setState={setCurrentPageTrackings}
        />
        <div className='d-flex w-100 justify-content-end'>
          <Button onClick={onCloseShowItems}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <CommentManager
        identifier={FormEntry}
        commentGetService={environment.GET_COMMENT_FMM_ENTRY}
        commentCreationService={environment.COMMENT_FMM_ENTRY}
        properties={commentTemplate}
      >
        <Action
          style={{
            backgroundColor: '#3498db',
            width: '40px',
            height: '40px'
          }}
          text={listMode ? 'Scroll infinito' : 'Modo normal'}
          onClick={switchLoadingItem}
        >
          <RiListSettingsLine />
        </Action>
      </CommentManager>

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size='xl'
      >
        <ModalRecoverForm
          dataRecuperar={dataRecuperar}
          onCloseRecuperar={onCloseRecuperar}
          handleChange={handleChange}
          movement={'Ingreso'}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>
      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar este formulario`}
        onCopy={copyData}
      />
      <ModalGeneric
        isOpen={isOpenDesechar}
        onClose={onCloseDesechar}
        title={`Desea desechar el formulario Nº ${dataToDesechar?.code_entryForm || dataToDesechar?.code_outputForm
          } 
        <br>
        <span style="color: red">Importante:</span> Esta acción no se puede deshacer!  `}
        size={'xl'}
      >
        <div className='d-flex justify-content-end mt-4 mb-2'>
          <Button onClick={onCloseDesechar}>Cancel</Button>
          <Button
            colorScheme='red'
            onClick={() => handlePresent('ELIMINADO')}
            ml={3}
          >
            Desechar
          </Button>
        </div>
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenCaducar}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea caducar el formulario Nº ${dataToCaducar?.code_entryForm}?`}
        size={'2xl'}
      >
        <div className='d-flex justify-content-end mt-4 mb-2'>
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            style={{ background: '#F9A621' }}
            onClick={() => handleCaducar('CADUCADO')}
            ml={3}
          >
            Caducar
          </Button>
        </div>
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular el formulario Nº ${dataToAnular?.code_entryForm}? 
        <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario`}
        size={'2xl'}
      >
        <div className='d-flex justify-content-end mt-4 mb-2'>
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: '#41495A', color: 'white' }}
            onClick={() => handleAnular('ANULADO')}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenCorrection}
        onOpen={onOpenCorrection}
        onClose={hanldeCancel}
        title={'Corregir ingreso'}
        size='xl'
      >
        <SubTitle>
          ¿Está seguro que desea{' '}
          {`${showCorrectBalance ? 'hacer una correción de saldo en' : 'corregir'
            }`}{' '}
          el formulario{' '}
          {dataCorrection?.code_entryForm || dataCorrection?.code_outputForm}?
        </SubTitle>
        <div className='d-flex my-2 '>
          <div className='bg-warning p-1'>
            <SubTitle>Importante</SubTitle>
          </div>
          <div className='p-1'>
            <SubTitle className='ms-2'>
              Esta acción generará un costo de{' '}
              {costCorrect && `$${formaterCant(costCorrect)}`}
            </SubTitle>
          </div>
        </div>
        <Flex className='my-3'>
          <ButtonStyled bgColor={'#bababa'} onClick={hanldeCancel}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={'#03C39A'}
            onClick={() => correct(dataCorrection?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenDesCaducar}
        onOpen={onOpenDesCaducar}
        onClose={onCloseDesCaducar}
        title={`Descaducar documento`}
        size='xl'
      >
        <SubTitle>
          ¿Está seguro que desea Descaducar el formulario {form?.code_entryForm}
          ?
        </SubTitle>
        <div className='d-flex my-2 '>
          <div className='bg-warning p-1'>
            <SubTitle>Importante</SubTitle>
          </div>
          <div className='p-1'>
            <SubTitle className='ms-2'>
              El documento regresara al estado Aprobado.
            </SubTitle>
          </div>
        </div>

        <Flex className='my-3'>
          <ButtonStyled bgColor={'#bababa'} onClick={onCloseDesCaducar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={'#03C39A'}
            onClick={() =>
              changeStatus({
                id: FormEntry,
                status_entryForm: 'APROBADO'
              })
            }
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          {/* <SectionHeader /> */}
          <div className='mb-5'>
            <HeaderForm
              status={form?.status_entryForm}
              numberForm={form?.code_entryForm}
              UserQualified={form?.name_qualified ?? 'N/A'}
              Modulo={'Formulario de ingresos En Zonas Francas'}
              items={allItems}
              data={form}
            />
          </div>

          <ButtonView
            followOrder={followOrder}
            setFollowOrder={setFollowOrder}
            data={form}
            idFmm={FormEntry}
            ExportPdf={
              <Tooltip placement='top' hasArrow label='Exportar PDF'>
                <span>
                  <ButtonHeaderModule bgColor='#03C39A' className='mx-1' onClick={handleExportPdf} isLoading={isFetchingPDF}>
                    <IconGeneric width='16px' className=' text-white' as={VscFilePdf} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            }
          >
            {form?.reason_for_return &&
              (form?.status_entryForm === 'BORRADOR' ||
                form?.status_entryForm === 'DEVUELTO') && (
                <AlertReturnReason
                  title={'Tipo de devolución:'}
                  item={form?.typifications}
                  description={form && form?.reason_for_return}
                  nickname={form?.nickname}
                />
              )}

            {form.form_corrected && form.form_corrected.length > 0 && (
              <FormCorrectionLog
                title={'Este formulario cuenta con corrección'}
                item={form?.form_corrected}
                form={form}
              // description={form && form?.reason_for_return}
              // nickname={form?.nickname}
              />
            )}


            <CreateAt
              entries={form.users}
              config={{
                statusKey: "status_entryForm",
                dateKey: "date_entryForm",
                timeKey: "time_entryForm",
                personNameKey: "person_name",
              }}
              expirationDate={form.expirationDate}
            />


            {isNotQualifiedUser &&
              (viewLockUnlockFilling || viewLockUnlockApproval) && (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  className='alert alert-warning w-50 pt-2 pb-2 m-auto mt-3 pl-2'
                  style={{ minHeight: '60px' }}
                  role='alert'
                >
                  {viewLockUnlockFilling &&
                    `Radicación del documento está ${form?.block_for_subheading_filling === 1
                      ? 'bloqueada'
                      : 'desbloqueada'
                    }`}
                  {viewLockUnlockApproval &&
                    `Aprobación del documento está ${form?.block_for_subheading_approval === 1
                      ? 'bloqueada'
                      : 'desbloqueada'
                    }`}
                  {(isAuthorizedToLockUnlock || admin === 1) && (
                    <Button
                      onClick={UnlockFormFilling}
                      colorScheme='blackAlpha'
                    >
                      {lockUnlock}
                    </Button>
                  )}
                </Box>
              )}
          </ButtonView>

          <Box>
            <div className='row'>
              {form?.status_entryForm !== 'PRESENTADO' ||
                pathname === '/FormShow' ? (
                <div className='col-md-6'>
                  <InputWithLabel
                    p={0}
                    borderRadius={'none'}
                    labelText={'Tipo de embarcación'}
                    value={form?.shipment_entryForm ?? 'N/A'}
                    tooltipText={GetTooltipText(['shipment_entryForm'])}
                    tooltipTextComment={CommentFindTooltip(
                      'shipment_entryForm',
                      form?.review_updates
                    )}
                    inputBgColor={GetColorInput(['shipment_entryForm'])}
                  // ColorTextLabel={"white"}
                  />
                </div>
              ) : (
                <div className='col-md-6'>
                  <FormInputGroup>
                    <LabeFormlStyled>Tipo de embarcación</LabeFormlStyled>

                    <InputCustom
                      type='text'
                      name='shipment_entryForm'
                      inputColor={x => GetColor(x.name, form)}
                      value={
                        form?.shipment_entryForm
                          ? form?.shipment_entryForm
                          : 'N/A'
                      }
                      readOnly={true}
                    />
                  </FormInputGroup>
                </div>
              )}
              {form?.status_entryForm !== 'PRESENTADO' ||
                pathname === '/FormShow' ? (
                <div className='col-md-6'>
                  <InputWithLabel
                    p={0}
                    borderRadius={'none'}
                    labelText={'Empresa'}
                    value={
                      form?.nit_qualified && form?.name_qualified
                        ? `${form?.nit_qualified} - ${form?.name_qualified}`
                        : 'N/A'
                    }
                    tooltipText={getNestedPropertyValue(
                      'Header',
                      'name_qualified'
                    )}
                    inputBgColor={getColorByFieldName(
                      'Header',
                      'name_qualified'
                    )}
                    tooltipTextComment={getNestedPropertyValue(
                      'Header',
                      'name_qualified',
                      'form_field_info_before'
                    )}
                    data-name-id={'Empresa'}
                    data-item-id={'Header'}
                  // ColorTextLabel={"white"}
                  />
                </div>
              ) : (
                <div className='col-md-6'>
                  <FormInputGroup>
                    <LabeFormlStyled>Empresa</LabeFormlStyled>
                    <InputCustom
                      type='text'
                      name='name_qualified'
                      inputColor={getColorByFieldName(
                        'Header',
                        'name_qualified'
                      )}
                      value={
                        form?.nit_qualified && form?.name_qualified
                          ? `${form?.nit_qualified} - ${form?.name_qualified}`
                          : 'N/A'
                      }
                      readOnly={true}
                    />
                  </FormInputGroup>
                </div>
              )}

              {form?.status_entryForm !== 'PRESENTADO' ||
                pathname === '/FormShow' ? (
                <div className='col-md-6'>
                  <InputWithLabel
                    p={0}
                    borderRadius={'none'}
                    labelText={'Proveedor/Exportador'}
                    value={
                      form.identification_num
                        ? `${form?.identification_num} - ${form?.business_name}`
                        : form?.business_name
                          ? form?.business_name
                          : 'N/A'
                    }
                    tooltipText={getNestedPropertyValue(
                      'Header',
                      'business_name'
                    )}
                    inputBgColor={getColorByFieldName(
                      'Header',
                      'business_name'
                    )}
                    tooltipTextComment={getNestedPropertyValue(
                      'Header',
                      'business_name',
                      'form_field_info_before'
                    )}
                    data-name-id={'Proveedor/Exportador'}
                    data-item-id={'Header'}
                  // ColorTextLabel={"white"}
                  />
                </div>
              ) : (
                <div className='col-md-6'>
                  <FormInputGroup>
                    <LabeFormlStyled>Proveedor/Exportador</LabeFormlStyled>
                    <InputCustom
                      type='text'
                      name='business_name'
                      inputColor={getColorByFieldName(
                        'Header',
                        'business_name'
                      )}
                      value={
                        form.identification_num
                          ? form?.identification_num + ' ' + form?.business_name
                          : form?.business_name
                            ? form?.business_name
                            : 'N/A'
                      }
                      readOnly={true}
                    />
                  </FormInputGroup>
                </div>
              )}

              {form?.status_entryForm !== 'PRESENTADO' ||
                pathname === '/FormShow' ? (
                <div className='col-md-6'>
                  <InputWithLabel
                    p={0}
                    borderRadius={'none'}
                    labelText={'Tipo de operación'}
                    value={
                      form?.short_name_operation
                        ? `${form?.code_operation} ${form?.short_name_operation}`
                        : 'N/A'
                    }
                    tooltipText={getNestedPropertyValue(
                      'Header',
                      'short_name_operation'
                    )}
                    tooltipTextComment={getNestedPropertyValue(
                      'Header',
                      'short_name_operation',
                      'form_field_info_before'
                    )}
                    data-name-id={'Tipo de Operación'}
                    inputBgColor={getColorByFieldName(
                      'Header',
                      'short_name_operation'
                    )}
                    data-item-id={'Header'}
                  // ColorTextLabel={"white"}
                  />
                </div>
              ) : (
                <div className='col-md-6'>
                  <FormInputGroup>
                    <LabeFormlStyled>Tipo de operación</LabeFormlStyled>
                    <InputCustom
                      type='text'
                      name='short_name_operation'
                      inputColor={getColorByFieldName(
                        'Header',
                        'short_name_operation'
                      )}
                      value={
                        form?.short_name_operation
                          ? `${form?.code_operation} ${form?.short_name_operation}`
                          : '0'
                      }
                      readOnly={true}
                    />
                  </FormInputGroup>
                </div>
              )}

              {form?.status_entryForm !== 'PRESENTADO' ||
                pathname === '/FormShow' ? (
                <div className='col-md-6'>
                  <InputWithLabel
                    p={0}
                    borderRadius={'none'}
                    labelText={'Orden de compra'}
                    value={form?.purchase_order ?? 'N/A'}
                    tooltipText={getNestedPropertyValue(
                      'Header',
                      'purchase_order'
                    )}
                    tooltipTextComment={getNestedPropertyValue(
                      'Header',
                      'purchase_order',
                      'form_field_info_before'
                    )}
                    data-name-id={'Orden de Compra'}
                    inputBgColor={getColorByFieldName(
                      'Header',
                      'purchase_order'
                    )}
                    data-item-id={'Header'}
                  // ColorTextLabel={"white"}
                  />
                </div>
              ) : (
                <div className='col-md-6 '>
                  <FormInputGroup>
                    <LabeFormlStyled>Orden de compra</LabeFormlStyled>
                    <InputCustom
                      type='text'
                      name='purchase_order'
                      inputColor={getColorByFieldName(
                        'Header',
                        'purchase_order'
                      )}
                      value={
                        form?.purchase_order ? form?.purchase_order : 'N/A'
                      }
                      readOnly={true}
                    />
                  </FormInputGroup>
                </div>
              )}
            </div>

            <div className='mt-5'>
              {code >= 100 && code < 199 ? (
                <FormViewRestWorld
                  data={form}
                  code={code}
                  notations={notationsDestruct}
                  activeReview={false}
                />
              ) : code >= 300 && code <= 399 ? (
                <FormViewRestTerritory
                  data={form}
                  code={code}
                  notations={notationsDestruct}
                  activeReview={false}
                />
              ) : code >= 500 && code <= 599 ? (
                <FormViewFreeZone
                  data={form}
                  code={code}
                  notations={notationsDestruct}
                  activeReview={false}
                />
              ) : code >= 700 && code <= 799 ? (
                <FormViewFromUserToUser
                  data={form}
                  notations={notationsDestruct}
                  activeReview={false}
                />
              ) : (
                <></>
              )}
            </div>

            <HeaderSearch margen=' 0.5rem 0rem'>
              {DocumentOperation.map((document, index) => (
                <div key={index}>
                  <DocumentUploader
                    document={document}
                    handleFiles={handleFiles}
                    handleFilesInput={handleFilesInput}
                    handleDelete={removeItemFromArr}
                    files={files}
                    setNameFiles={setNameFiles}
                    nameFiles={nameFiles}
                    index={index}
                    viewBtnDelete={isAuthorizationDeletedDocument}
                    documentPreview={true}
                  />
                </div>
              ))}
            </HeaderSearch>

            <HeaderSearch margen=' 0.5rem 0rem'>
            <Box display={"grid"} gap={4} pt={12}>
                  <EnhancedFileUploadManager
                    title="Otros documentos"
                    onUploadComplete={() => {}}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    endpointAttachments={{
                      create: environment.UPDATE_FORM_ENTRY,
                    }}
                    postPropertyName={"other_attachment"}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={getAuthToken}
                    formID={FormEntry}
                  />

                  <ExistingFiles
                    existingFiles={otherFiles?.other_files}
                    isLoading={isFetchingViewForm}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
            </HeaderSearch>

            {currentPage && currentPage.length > 0 && handleNetWeigh() ? (
              <div
                className='alert alert-warning w-50 pt-2 pb-2 text-center m-auto my-4 pl-2'
                role='alert'
              >
                Peso total acumulado en báscula: {fomateo(2, handleNetWeigh())}{' '}
                Kg
              </div>
            ) : null}

            {isLoadingItems ? <SpinnerComponent /> : <></>}

            {!isLoadingItems && (
              <>
                {allItems?.length !== 0 && (
                  <TableItems
                    items={allItems}
                    precision={precision}
                    currentData={form}
                  />
                )}
              </>
            )}
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'100%'}
              mt={'1rem'}
              gap={3}
            >

              <Box w={'64'} mb={6} mt={6}>
                <label
                  style={{
                    width: '100%'
                  }}
                >
                  Buscar item
                  <InputGroup>
                    <Input
                      w={'100%'}
                      h={'38px'}
                      color={'#000'}
                      outline={'none'}
                      fontSize={'1rem'}
                      name='search'
                      backgroundColor={'#ffffff87'}
                      onChange={handleChangeSearch}
                      value={searchItem}
                      placeholder={'Buscar item'}
                    />
                    <InputRightElement style={{}} as='button'>
                      <RiSearch2Line color='green.500' />
                    </InputRightElement>
                  </InputGroup>
                </label>
              </Box>
              <label
                style={{
                  width: 300
                }}
              >
                <label style={{ width: '100%' }}></label>

                <InputSelect
                  data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                  value={orderBy}
                  name="order_by"
                  set={setOrderBy}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: '#5f5f',
                  }}
                  isClearable={false}
                />
              </label>
            </Box>

            <RenderItems
              notationsDestruct={notationsDestruct}
              FetchingStatus={status}
              admin={admin}
              dataItems={allItems}
              formData={form}
              isLoadingItems={isLoadingItems}
              error={isErrorPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              is_commerce={is_commerce}
              fetchNextPage={fetchNextPage}
              showTrackingItem={showTrackingItem}
              isInfiniteScroll={listMode}
            />
          </Box>

          {/* ObtenerPases */}

          {(form?.status_entryForm === 'APROBADO' ||
            form?.status_entryForm === 'AUTORIZADO') &&
            currentPage?.length !== 0 && (
              <Accordion allowMultiple className='mt-3'>
                <AccordionItem border='0px'>
                  <AccordionButton activeClassName='active'>
                    <Box
                      flex='1'
                      textAlign='left'
                      className='d-flex align-items-center'
                    >
                      <Icon as={BiShowAlt} />
                      {'   '}
                      {currentPage?.length} Entradas
                    </Box>
                    <AccordionIcon size={20} className='float-end' />
                  </AccordionButton>

                  <AccordionPanel pb={1}>
                    <TableOrder
                      thead={TitleMovementIngreso}
                      data={currentPage}
                      setData={setCurrentPage}
                      loading={loading}
                      tbodyData={TBodyEntryMovement}
                    />

                    <Pagination
                      page={page}
                      setPage={setPage}
                      maxPage={maxPage}
                      loading={loading}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
        </>
      )}
    </>
  )
}

export default FormsView
