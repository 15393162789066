import Pagination from 'components/Pagination/Pagination'
import TableOrder from 'components/Tables/TableOrder'
import React from 'react'
import { TitleTaxes } from '../ArrayList/ArrayList'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import queryEnv from 'constants/queryConst'
import { useGlobalContext } from 'context/GlobalContext'
import environment from 'constants/apiConst'
import { Box, Td, Text, Tooltip, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { IconGeneric } from 'styled/Icons.styled'
import { ButtonHeaderModule, ButtonStyled } from 'styled/Buttons.styled'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { useModal } from 'hooks/useModal'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import { RETURN_TYPES, useForm } from 'hooks/useForm'
import { NumericFormat } from 'react-number-format'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { parseFloat } from 'utils/parseFloat'
import { BsCheckCircleFill } from 'react-icons/bs'

const RechargesTaxesTable = () => {

  const [isOpenRate, onOpenRate, onCloseRate] = useModal();
  const { requestData } = useGlobalContext();
  const [page, setPage] = useState(1);
  const { data: dataTaxes, isLoading: isLoadingTax } = useQuery({
    queryKey: [queryEnv?.TAXES_RECHARGES, page],
    queryFn: () => requestData({ Endpoint: environment.TREASURY_RECHARGES_TAXES, PropertyBody: "POST", pageParam: page }),
  })


  return (
    <>
      <ModalCreateNewTax isOpen={isOpenRate} onClose={onCloseRate} onOpen={onOpenRate} />
      <Box
        boxSizing='border-box'
        width='100%'
        minHeight='auto'
        boxShadow={'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}
        margin='0.5rem 0rem'
        p='0.9rem'
        borderRadius='5px'
      >
        <Box
          w={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          my={2}
        >
          <Text fontSize='lg' fontWeight={600}>Historial de cambios</Text>
          <Tooltip placement="top" hasArrow label="Crear nueva tarifa">
            <span>
              <ButtonHeaderModule
                className=""
                bgColor="#F9A621"
                onClick={onOpenRate}
              >
                <IconGeneric color="#fff" as={AiOutlinePlus} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </Box>
        <Box>
          <TableOrder
            thead={TitleTaxes}
            data={dataTaxes?.data?.status?.data?.data}
            loading={isLoadingTax}
            tbodyData={TBodyTaxes}
          />
          <Pagination
            page={dataTaxes?.data?.status?.data?.current_page}
            setPage={setPage}
            maxPage={dataTaxes?.data?.status?.data?.last_page}
            loading={isLoadingTax}
          />
        </Box>
      </Box>
    </>
  )
}

export default RechargesTaxesTable


const TBodyTaxes = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">
        {data?.tax ? `${data?.tax}%` : "--"}
      </Td>
      <Td className="text-center">
        {data?.date_tax ? data?.date_tax : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.nickname ? data?.nickname : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.observation ? data?.observation : "N/A"}
      </Td>
      <Td className="text-center">
        <IconGeneric
          color={data.status_tax === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </Td>
    </Tr>
  );
};


const ModalCreateNewTax = ({ isOpen, onOpen, onClose }) => {
  const { values,
    handleInputChange,
    setValues
  } = useForm({
    initialState: {
      observation: "",
      tax: "",
    },
    returnType: RETURN_TYPES.OBJECT,
  })

  const [openAlert] = AlertErrorAlert();

  const queryClient = useQueryClient();
  const { requestData } = useGlobalContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values?.tax === 0) return openAlert("El porcentaje no puede ser 0", "error");
    if (values?.observation === "") return openAlert("La observación es requerida", "error");

    const res = await requestData({
      Endpoint: environment.TREASURY_CREATE_TAX,
      PropertyBody: 'POST',
      data: values
    })
    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries([queryEnv?.TAXES_RECHARGES])
      setValues({
        observation: "",
        tax: 0
      })
      return onClose()
    };

    openAlert(res?.data?.status?.message, "error");
  }



  return (
    <ModalGeneric
      isOpen={isOpen}
      onClose={() => {
        setValues({
          observation: "",
          tax: "",
        })
        onClose();
      }}
      title={"Nueva tasa"}
      size={"xl"}
      scrollBehavior={"outside"}
    >
      <form onSubmit={handleSubmit}>
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={0}
          decimalSeparator="."
          customInput={LabelGroup}
          value={values?.tax}
          placeholder="0"
          name={"tax"}
          onChange={handleInputChange}
          tag={"Tasa del Iva"}
          size={100}
          isAllowed={(values) => {
            const { floatValue } = values;
            return (floatValue !== 0 && (parseFloat(floatValue) < 100))
          }}
          desicion={false}
        />
        <LabelGroup
          name={"observation"}
          tag={"Observación"}
          size={100}
          value={values?.observation}
          onChange={handleInputChange}
          desicion={false}
          placeholder={"Observación"}

        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled
            type="submit"
          // isDisabled={}
          >
            Crear tasa
          </ButtonStyled>
        </Box>
      </form>
    </ModalGeneric>
  )
}