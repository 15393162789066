export const TTableRoles = [
  { name: "Rol", order: "name_rol" },
  { name: "Descripción", order: "description_rol" },
  { name: "Acciones", order: "actions_rol" }
];

export const TTablePermisos = [
  { name: "Nombre", order: "name_permission" },
  { name: "Descripción", order: "description_permission" },
  { name: "Acciones", order: "actions_permission" }
];

export const TTableLicencia = [
  { name: "Usuario Calificado", order: "name_qualified" },
  { name: "Nombre", order: "name_license" },
  { name: "Exp. Licencia", order: "expireDate_license" },
  { name: "Descripción", order: "description_license" },
  { name: "Cuentas activas", order: "active_accounts" },
  { name: "Estado", order: "status_license" },
  { name: "Acciones", order: null }
];
export const TTableGroup = [
  { name: "Modulo", order: "modul_permissionGroup" },
  { name: "Grupo", order: "name_permissionGroup" },
  { name: "Descripción", order: "descriptions_permissionGroup" },
  { name: "Estado", order: "status_permissionGroup" },
  { name: "Acciones", order: "actions_permissionGroup" }
];

export const TitleRoles = ["Roles", "Permisos", "Licencia", "Grupo"];
