import { Icon } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const IconGeneric = styled(Icon)`
  width: ${(props) => props.width || "24px"};
  font-size: ${(props) => props.Size || "12px"};
  height: 24px;
  color: ${(props) => props.color || "#1f7ac3"};
  display: inline;
  cursor: ${(props) => props.cursor || "pointer"};
  background: ${(props) => props.background || "transparent"};
  border-radius: ${(props) => props.borderRadius || "0"};
  padding: ${(props) => props.padding || "0"};
  /* margin: ${(props) => props.margin || "0"}; */
`;

export const IconMenuRUD = styled(Icon)`
  width: 24px;
  height: 24px;
  color: ${(props) => props.color || "#1f7ac3"};
  margin-right: 0.5rem;
`;

export const IconImport = styled(Icon)`
  width: 80px;
  height: 80px;
  color: ${(props) => props.color || "#1f7ac3"};
  display: inline;
  cursor: pointer;
  margin: ${(props) => props.margin || "0"};
`;

export const IconTracking = styled(Icon)`
  width: 19px;
  height: 19px;
  color: ${(props) => props.color || "gray"};
  display: inline;
  cursor: pointer;
`;

export const IconInModal = styled(Icon)`
  width: 15px;
  height: 15px;
  color: ${(props) => props.color || "#1f7ac3"};
  display: inline;
  cursor: pointer;
`;

export const IconAside = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${(props) => props.color || "#1f7ac3"};
  display: inline;
  cursor: pointer;
  margin: ${(props) => props.margin || "0"};
  background: ${(props) => props.background || "transparent"};
  border-radius: ${(props) => props.borderRadius || "0"};
  padding: ${(props) => props.padding || "0"};
`;

export const Images = styled.img`
  width: ${(props) => props.width || "24px"};
  height: 24px;
  color: ${(props) => props.color || "#1f7ac3"};
  display: inline;
  cursor: pointer;
  margin: ${(props) => props.margin || "0"};
`;
