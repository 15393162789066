import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill, BsCheckAll } from "react-icons/bs";
import TreasuryCrudContex from "context/TreasuryContex";
import { formatValues } from "components/Funciones/FunctionFormaterNew";

import { FaRegTimesCircle } from "react-icons/fa";

import styled from "styled-components/macro";
import { useFormatQuantity } from "hooks/useFormatQuantity";

const TBodyExtract = ({ data }) => {
  const [fomateo] = useFormatQuantity();

  const document = data?.document_type_id;
  const { setDataToObservacion, onOpenObservacion } =
    useContext(TreasuryCrudContex);

  const handleInfo = () => {
    setDataToObservacion(data);
    onOpenObservacion();
  };

  // const vrAnexo = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  //   minimumFractionDigits: 0,
  // }).format(data?.annexe_value || 0);

  const valor = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(data?.value);

  const TdState = styled(Td)`
    color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
  `;

  const vrAnexoFormateado = fomateo(
    data.decimal_values_precision,
    data.annexe_value
  );
  return (
    <>
      <Tr>
        <Td className="text-center">{data?.nit_qualified}</Td>
        <Td className="text-center">{data?.name_qualified}</Td>
        <Td className="text-center">{data?.treasury_code}</Td>
        <Td className="text-center">{data?.document}</Td>
        <Td className="text-center">{data?.code}</Td>
        <Td className="text-center">{data?.annexes ? data?.annexes : "N/A"}</Td>
        <Td className="text-center">
          {data?.annexes_collected ? data?.annexes_collected : 0}
        </Td>
        <Td className="text-center">
          {data.annexe_value ? `$${vrAnexoFormateado}` : 0}
        </Td>
        <Td className="text-center">{data?.decimal_values_precision}</Td>
        <Td className="text-center">{data?.nickname}</Td>
        <Td className="text-center">{data?.date + " " + data?.time}</Td>
        <Td className="text-center">
          {data?.old_balance ? formatValues(data?.old_balance) : "0"}
        </Td>
        <Td className="text-center">{data?.value ? valor : "0"}</Td>
        <Td className="text-center">
          {data?.balance ? formatValues(data?.balance) : "0"}
        </Td>

        <Td className="text-center">
          <Tooltip
            hasArrow
            placement="top"
            label={data?.status === 1 ? "Exitoso" : "Reversado"}
          >
            <TdState className="text-center" isNumeric text={data?.status}>
              {data?.status === 1
                ? (
                  <IconGeneric color={"#03C39A"} as={BsCheckAll} />
                )
                : (
                  <IconGeneric color={"#d92525"} as={FaRegTimesCircle} />
                )}
            </TdState>
          </Tooltip>
        </Td>
        {
          data?.annexes !== "0" && document !== 13
            ? (
              <Td className="text-center">
                <IconGeneric
                  color={"#1F7AC3"}
                  as={BsEyeFill}
                  onClick={() => handleInfo()}
                />
              </Td>
            )
            : document === 13
              ? (
                <Td>{data?.observations}</Td>
              )
              : (
                <Td className="text-center">
                  <IconGeneric color={"#BABABA"} as={BsEyeFill} />
                </Td>
              )
        }
      </Tr>
    </>
  );
};

export default TBodyExtract;
