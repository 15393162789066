/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { Tooltip } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { RiDeviceRecoverFill } from 'react-icons/ri'
import { MdContentCopy, MdCancel } from 'react-icons/md'
import { BiShow } from 'react-icons/bi'
import { AuthContext } from 'context/AuthContext'
import { /* useLocation, */ useNavigate } from 'react-router-dom'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import MonitoringContext from 'context/MonitoringContext'
import { BsRecordCircle } from 'react-icons/bs'

import Swal from 'sweetalert2'
import { FaUnlock } from 'react-icons/fa'
import GlobalContext from 'context/GlobalContext'
import { hasCommonElements } from 'utils/type-check-utils'
import { EntryPass, Entryform, localPermissions } from 'Permissions/permissions'

const ButtonsPresent = ({
  handlePresent,
  data,
  status,
  setFollowOrder,
  handleCopy
}) => {
  const { authUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const Formtype = 2

  const { changeStatus } = useContext(FormCrudCRUDContext)

  const superadmin = useSelector(state => state.auth.dataUser.admin)
  const is_commerce = useSelector(state => state.auth.dataUser.is_commerce)
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const { setIsTheFormLocked } = useContext(GlobalContext)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()

  const handleRevisar = status => {
    const infoEntry = {
      id: data.id,
      status_entryForm: status,
      movement: 'Ingreso'
    }

    if (status === 'REVISADO') {
      changeStatus(infoEntry)
      // navigate(`/CheckFormEntry?Number=${data.id}`);
    }

    navigate(`/CheckFormEntry?Number=${data.id}`)
  }

  const [isBlocked, setIsBlocked] = useState(false)
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    const helper = async () => {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMI')
      body.append('type', 4)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if (res?.data?.status?.code === 200) {
        setIsBlocked(false)
        setIsTheFormLocked(false)
        console.log('desbloqueado')
      }
      if (res?.data?.status?.code === 400) {
        setIsBlocked(true)
        setIsTheFormLocked(true)
        setMessageError(res?.data?.status?.message)
        console.log('bloqueado')
      }
    }

    helper()
  }, [])

  const showAlert = (title, message, callback = () => {}) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Desbloquear',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1e75bb',
      position: 'top'
    }).then(async result => {
      if (result.isConfirmed) {
        const body = new FormData()
        body.append('form_id', data?.id)
        body.append('type_of', 'FMMI')
        body.append('type', 2)
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
        setIsBlocked(false)
        callback()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const checkIfItIsBlocked = async (fnParam, ...arg) => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'FMMI')
    body.append('type', 4)
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
    if (res?.data?.status?.code === 200) {
      fnParam(...arg)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, 'error')
    }
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { changeStatus: changeStatusEntryForm, copy: copyEntryForm } =
    localPermissions.Entryform

  return (
    <>
      {authUser.is_qualified_user !== 1 && isBlocked && (
        <Tooltip placement='top' hasArrow label='Desbloquear'>
          <span>
            <ButtonHeaderModule
              onClick={() => {
                showAlert(
                  '¿Deseas desbloquear el formulario?',
                  messageError,
                  () => {
                    openAlert('Formulario desbloqueado', 'success')
                  }
                )
              }}
              bgColor='#fe8d59'
              className='mx-1'
            >
              <IconGeneric
                className='text-white'
                color='#fff'
                width='16px'
                as={FaUnlock}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor={
                isBlocked ? `rgba(184, 219, 26, 0.5)` : `rgba(184, 219, 26)`
              }
              onClick={() => checkIfItIsBlocked(onOpenCreateTrackingFmm)}
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor={isBlocked ? `rgba(255, 0, 0, 0.5)` : `rgba(255, 0, 0)`}
              onClick={() =>
                checkIfItIsBlocked(
                  getFollowOrder,
                  data,
                  setFollowOrder,
                  Formtype
                )
              }
              className='mx-1'
            >
              <IconGeneric
                width='16px'
                as={MdCancel}
                color='#fff'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, changeStatusEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Recuperar'>
          <span>
            <ButtonHeaderModule
              onClick={() => checkIfItIsBlocked(handlePresent, 'RECUPERADO')}
              bgColor={
                isBlocked ? `rgba(31, 122, 195, 0.5)` : `rgba(31, 122, 195)`
              }
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={RiDeviceRecoverFill}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {authUser.is_qualified_user === 1 ? (
        ''
      ) : (
        <>
          {(hasCommonElements(permissionList, changeStatusEntryForm) ||
            authUser.admin === 1) && (
            <Tooltip placement='top' hasArrow label='Revisar'>
              <span>
                <ButtonHeaderModule
                  className='mx-1'
                  bgColor={
                    isBlocked
                      ? `rgba(88, 177, 88, 0.5)`
                      : `rgba(88, 177, 88, 1.0)`
                  }
                  onClick={() => checkIfItIsBlocked(handleRevisar, 'REVISADO')}
                >
                  <IconGeneric
                    width='16px'
                    className='text-white'
                    as={BiShow}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          )}
        </>
      )}

      {(hasCommonElements(permissionList, copyEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule
              onClick={handleCopy}
              className='mx-1'
              bgColor='#F9A621'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default ButtonsPresent
