/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Container,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel
} from "@chakra-ui/react";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import React, { useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useSelector } from "react-redux";
import { __GetDataNoPage } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import {
  LabelGroup,
  LabelGroupTextArea
} from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { __Post } from "request/Petitions/__Post";

import { IconGeneric } from "styled/Icons.styled";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
// import { InfoInput } from "./InfoInput";

/**
 *  ConfigDEI - Componente para hacer configuración.
 *
 * @component
 * @param {Function} onClose Funcion para cerrar modal.
 * @param {Array} DataZf Data de las zonas franca para listar en el select.
 * @return {Component} Retorna componente de crear y actualizar configuración DANE.
 */

export const ConfigDEI = ({ onClose, DataZf }) => {
  const {token, freeZone_id} = useSelector((state) => state.auth.dataUser);  const [openAlert] = AlertErrorAlert();
  const [createNewConfig, setcreateNewConfig] = useState(false);
  const [selectRange, setselectRange] = useState('Mensual');
  const [idConfi, setidConfi] = useState(null);
  const [dataDaysMonth, setdataDaysMonth] = useState(null);
  const [dataCurrentDay, setdataCurrentDay] = useState(null);
  const [values, handleInputChange, reset, setValues] = useForm({
    email: "",
    hour: "",
    subject: ""
  });
  const { email, hour, subject } = values;

  useEffect(() => {
    getConfig();
  }, []);


  const [EmailCurrent, setEmailCurrent] = useState([]);

  const getConfig = async () => {
    try {
      const res = await __GetDataNoPage(environment.GET_CONFIG_DEI, token);
      if (res?.data?.status.code === 200) {
        const data = res?.data?.status.data;
        const { free_zones, range_type, id } = data;
        setValues({ ...data, email: "", });
        if (data.email) {
          setEmailCurrent(data.email);
        }

        if (data.day) {
          setdataCurrentDay({ label: data.day, value: data.day });
        }

        setidConfi(id);
        const resultadoBusqueda = dataRange_Type.find(
          (e) => e.value === range_type
        );
        setselectRange('Mensual');

      } else if (
        res?.data?.status.code === 403 &&
        res?.data?.status.message === "No se encuentra la configuración"
      ) {
        setcreateNewConfig(true);
        openAlert(`No tiene configuración guardada`, "info");
      } else {
        openAlert(res?.data?.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlur = (event) => {
    const campo = event.target;
    const emailRegex =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (!emailRegex.test(campo.value)) {
      openAlert(`¡Inserte un correo electrónico valido!`, "error");
    }
  };

  const updateOrCreate = async (endpoint, infoData) => {
    try {
      const res = await __Post(endpoint, token, infoData);
      const { code, message } = res?.data?.status;
      if (code === 200) {
        openAlert("Configuración exitosa", "success");
        onClose();
        reset();
      } else {
        console.log(message);
        openAlert(`${message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const newZf = [];
    // range_type
    const data = {
      ...values,
      email: EmailCurrent,
      day: dataCurrentDay ? dataCurrentDay.value : "",
      range: selectRange.toUpperCase(),
      freeZone_id: freeZone_id
    };

    // validacion de corre

    // let emailRegex =
    //   /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    // if (!emailRegex.test(email)) {
    //   openAlert(`¡Inserte un correo electrónico valido!`, "error");
    // } else

    // fin validacion de corre

    if (subject === "" || subject === null || subject === undefined) {
      openAlert(`El campo asunto es requerido`, "error");
    } else if (!dataCurrentDay) {
      openAlert(`Seleccione el dia para el envio del correo`, "error");
    } else if (hour === "" || hour === null || hour === undefined) {
      openAlert(`El campo hora es requerido`, "error");
    } else {
      console.log(data);
      if (createNewConfig) {
        updateOrCreate(environment.CREATE_DANE, data);
      } else {
        const info = {
          ...data,
          id: idConfi
        };
        console.log(info);
        updateOrCreate(environment.SAVE_CONFIG_DEI, info);
      }
    }
  };

  const dataRange_Type = [
    {
      label: "Minuto",
      value: "minuto",
      messaje: "Se genera el reporte cada minuto."
    },
    {
      label: "Hora",
      value: "hora",
      messaje: "Se genera un reporte cada hora (Hora estipulada)."
    },
    {
      label: "Semanal",
      value: "semanal",
      messaje: "Se genera un reporte semanal el lunes (a la hora estipulada)."
    },
    {
      label: "Quincenal",
      value: "quincenal",
      messaje: "Se genera reporte los 15 de cada mes (a la hora estipulada)."
    },
    {
      label: "Mensual",
      value: "mensual",
      messaje:
        "Se genera reporte el último día del mes (a la hora estipulada)."
    }
  ];

  const handleInputChangeEmail = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value.replaceAll(",", "")
    });
  };

  const onEnter = (e, state, set) => {
    const valor = e.target.value;
    // console.log(e.key);
    if (e.key === " ") {
      // array.push(email);
      if (!state.includes(valor)) {
        const emailRegex =
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(valor)) {
          openAlert(`¡Inserte un correo electrónico valido!`, "error");
        } else {
          set(state.concat([valor]));
        }
      } else {
        openAlert(`El correo ya esta guardado`, "error");
      }
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  const handleDeleteTag = (item, state, set) => {
    const result = state.filter((element) => element !== item);
    set(result);
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const mes = today.getMonth();

    const diasMes = new Date(year, mes, 0).getDate();

    const array = [];
    for (let dia = 1; dia <= diasMes; dia++) {
      array.push({
        label: dia,
        value: dia
      });
    }
    setdataDaysMonth(array);
  }, []);

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      > */}
      <SimpleGrid minChildWidth="200px" spacing="10px">
        <div
          style={{
            width: "100%"
          }}
        >
          <div className="d-flex">
            <LabelStyled>Correo electrónico</LabelStyled>
            <Popover isLazy>
              <PopoverTrigger>
                <Box
                  className="ms-1"
                  w="20px"
                  children={
                    <IconGeneric width="20px" as={HiOutlineInformationCircle} />
                  }
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">Información</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  Haciendo uso la tecla espacio agregará el correo
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>

          <Box w="100%" h="auto" border="1px solid #bababa" borderRadius="5px">
            <Container p={0} className="p-1" maxW="100%">
              {EmailCurrent &&
                EmailCurrent?.map((elemento) => (
                  <>
                    <Tag
                      size="sm"
                      key={elemento}
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="blue"
                      wrapProps={{ direction: "column", align: "start" }}
                      className="mb-1 ms-0"
                    >
                      <TagLabel className="text-dark">{elemento}</TagLabel>
                      <TagCloseButton
                        onClick={() =>
                          handleDeleteTag(
                            elemento,
                            EmailCurrent,
                            setEmailCurrent
                          )
                        }
                      />
                    </Tag>
                  </>
                ))}
              <LabelGroup
                name={"email"}
                onKeyDown={(e) => onEnter(e, EmailCurrent, setEmailCurrent)}
                value={email}
                onChange={handleInputChangeEmail}
                tag={""}
                size={80}
                maxLength={50}
                placeholder={!EmailCurrent && "Destinatario:"}
                error="transparent"
              />
            </Container>
          </Box>
        </div>
      </SimpleGrid>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroupTextArea
          name={"subject"}
          value={subject}
          onChange={handleInputChange}
          tag={"Asunto"}
          size={100}
          maxLength={50}
        />
      </div>

      <SimpleGrid minChildWidth="200px" spacing="10px" my={2}>
        <LabelGroup
          name={"selectRange"}
          value={selectRange}
          onChange={handleInputChange}
          tag={"Rango"}
          size={100}
          maxLength={50}
          desicion={true}
        />
        <div
          className="d-flex"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <div style={{ width: "45%" }}>
            <InputSelect
              tag={"Día"}
              data={dataDaysMonth}
              set={setdataCurrentDay}
              value={dataCurrentDay}
            />
          </div>
          <LabelGroup
            name={"hour"}
            value={hour}
            onChange={handleInputChange}
            tag={"Hora"}
            size={45}
            // maxLength={50}
            tipo="time"
          />
        </div>
      </SimpleGrid>

      <Flex>
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          {createNewConfig ? "Crear" : "Guardar"}
        </ButtonStyled>
      </Flex>
    </>
  );
};
