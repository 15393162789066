import GlobalContext from "context/GlobalContext";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { GrUpdate } from "react-icons/gr";
import { GoArrowDown } from "react-icons/go";
import { VscOpenPreview } from "react-icons/vsc";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Textarea
} from "@chakra-ui/react";
import { useModal } from "hooks/useModal";

const Form = ({ target, onCancel, notations, data = {}, id, ...props }) => {
  const { name = "", value = "" } = target || {};
  const { nameId, itemId = null } = target?.dataset || {};
  const [hex, setHex] = useState("#B1B1B1");

  const [inputValue, setInputValue] = useState("");
  const valueBefore =
    notations?.targetData?.[id]?.[name]?.form_field_info_before;

  useEffect(() => {
    const comment =
      notations?.storage?.[id]?.[name]?.comment ||
      notations?.reviewsNew?.[id]?.[name]?.comment;

    if (comment) setInputValue(comment);
  }, [notations?.storage, id, name]);

  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };

  const Icon = data?.icon || null;
  const [activeColor, setActiveColor] = useState(null);

  const colorSelected = (e) => {
    const { name = "" } = e.target.dataset;
    setActiveColor(name);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (notations?.statusSaveReview === "success") {
        onCancel();
      }
    }
  }, [notations?.statusSaveReview]);

  const firstRender = useRef(true);

  const colorCompare = (color) => {
    const colorNotation =
      notations?.targetData?.[itemId]?.[name]?.status?.color || "";
    return colorNotation === color;
  };

  return (
    <Box spacing={4}>
      <Box mt={4}>
        <FormLabel>{nameId || "Campo Invalido"}</FormLabel>
        <Textarea
          onChange={onChangeHandler}
          value={inputValue}
          placeholder={"Observación"}
        />
      </Box>

      <Box p={1}>
        <HStack spacing="5px">
          <Box
            w="5"
            h="5"
            borderRadius="5"
            border={activeColor === "#fc0a0a" ? "3px solid #303030" : "none"}
            bg="#fc0a0a"
            onClick={colorSelected}
            data-name="#fc0a0a"
          />

          <Box
            w="5"
            borderRadius="5"
            h="5"
            bg="#03C39A"
            border={activeColor === "#03C39A" ? "3px solid #303030" : "none"}
            onClick={colorSelected}
            data-name="#03C39A"
          />

          {/*  <PopoverGeneric
              closeHidden={true}
              customButton={
                <Box
                  w="5"
                  borderRadius="5"
                  h="5"
                  bg={hex}
                  border={
                    activeColor === "customColor" ? "3px solid #303030" : "none"
                  }
                  onClick={colorSelected}
                  data-name="customColor"
                />
              }
            >
              <ColorSketch hex={hex} setHex={setHex} />
            </PopoverGeneric> */}
        </HStack>
      </Box>

      <Accordion p={3} allowMultiple>
        {data?.error && (
          <AccordionItem>
            <AccordionButton>
              <Flex gap={2} alignItems={"center"}>
                {Icon}
                <Text fontSize={14}>Observación</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>{data?.text}</Text>
            </AccordionPanel>
          </AccordionItem>
        )}
        {(valueBefore || colorCompare("purple")) && (
          <AccordionItem>
            <AccordionButton>
              <Flex gap={2} alignItems={"center"}>
                <GrUpdate color="#2B6CB0" />
                <Text fontSize={14}>Cambios</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Flex alignItems={"center"} flexDir={"column"}>
                <FormInputGroup>
                  <LabeFormlStyled>{nameId}</LabeFormlStyled>
                  <InputFake>{valueBefore || "N/A"}</InputFake>
                </FormInputGroup>
                <GoArrowDown size={20} color="#06870f" />
                <FormInputGroup>
                  <LabeFormlStyled>{nameId}</LabeFormlStyled>
                  <InputFake>{value || "N/A"}</InputFake>
                </FormInputGroup>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>

      <ButtonGroup d="flex" justifyContent="flex-end">
        <Button variant="outline" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          colorScheme="blue"
          type="button"
          disabled={!(notations && activeColor)}
          isLoading={notations?.isLoadingSaveReview}
          onClick={() =>
            notations.createComment(target, inputValue, activeColor, id)
          }
        >
          Guardar
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const ReviewPopover = ({ target, notations, data, id }) => {
  const [isOpen, onOpen, onClose] = useModal();
  const { popoverState, setPopoverState } = useContext(GlobalContext);

  useEffect(() => {
    if (firstRender.current) {
      if (isOpen) {
        onOpen();
        setPopoverState({ name: target?.name, active: isOpen });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (popoverState?.name !== target?.name) onClose();
  }, [popoverState]);

  const firstRender = useRef(true);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <IconButton
          fontSize="lg"
          bg="none"
          minWidth={"auto"}
          h={"6"}
          w={"6"}
          icon={<VscOpenPreview />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent p={5}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Form
            target={target}
            onCancel={onClose}
            notations={notations}
            data={data}
            id={id}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
