/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Collapse, Text, Tooltip } from "@chakra-ui/react";
import TableOrder from "components/Tables/TableOrder";
// import TBodyDesaduanamiento from "components/Tables/TBodyCustomsClearance";
import { Button } from "pages/Pass-Form/stylePases";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import {
  theadCustomsClearance,
  theadCustomsClearanceActionNoneIsCommerce,
} from "./theadCustomsClearance";
import { useForm } from "hooks/useForm";
import InputSelect from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import {
  __getShowItemFormCustomsClearance,
  __ObtenerSubHeadingCode,
  __ShowCustomsClearence,
  __TradeAgreementsCustoms,
} from "request/CustomsClearance.js/__CustomsClearence";
import environment from "constants/apiConst";
import CustomsCrudContext from "context/CustomsCrudContext";
import { AdjuntoOneAttac } from "./Adjunto/AdjuntoOneAttac";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { IconGeneric } from "styled/Icons.styled";
import { CgPlayListAdd } from "react-icons/cg";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import FormAddItems from "./FormAddItems";
import TbodyFormCustomClearance from "./Tbody/TbodyFormCustomClearance";
import Pagination from "components/Pagination/Pagination";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { AttchmentTypiFied } from "components/attchmentTypiFied/AttchmentTypiFied";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { LabelGroupTextArea } from "pages/RefProductInter/LabelGroup";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { __Post } from "request/Petitions/__Post";
import { useFormBlocker } from "hooks/useFormBlocker";
import {
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";

import { useMutation } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import Vapor from "laravel-vapor";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { GroupInputUpload } from "pages/Entry_and_exit_forms/GroupInputUpload";
import { Storage } from "hooks/useStorage";
import { useGetUserQualified } from "hooks/useUserQualified";
import { setIdShowCustoms } from "redux/actions/customsClearanceActions";
import { useDispatch } from "react-redux";
import { Regex } from "utils/Regex";
import { AuthContext } from "context/AuthContext";
import MultipleFileUploaderWithoutModal from "./MultipleFileUploaderWithoutModal";
import FileUploadManager from "./Adjunto/FileUploadManager";
import ExistingFiles from "./Adjunto/ExistingFiles";
import { convertArrayToGroupedObject } from "pages/Entry_and_exit_forms/FormEntry/BodyForm";
import Swal from "sweetalert2";

/**
 *  FormCustomsClearance - Componente de formulario del  Desaduanamiento
 *
 * @component
 * @return {Component} Retorna componente de formulario.
 */

const FormCustomsClearance = () => {
  const location = useLocation(); // navigate
  const { Customs = null } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [subheaId, setSubheaId] = useState(null);
  const [infoTradeAgreements, setInfoTradeAgreements] = useState([]);
  const [stateTradeAgreements, setStateTradeAgreements] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [uploadAttached, setUploadAttached] = useState(null);
  const [currentPage, setCurrentPage] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const { authUser } = useContext(AuthContext);
  const [values, handleInputChange, reset, setValues] = useForm({
    initialState: {
      tradeAgreement_id: "",
      acceptance_number: "",
      acceptance_date: "",
      release_number: "",
      subheading_id: "",
      code_subheading: "",
      subchapter: "",
      code_businessUnit: "",
      example: "",
    },
    formStateHandler: true,
  });

  useEffect(() => {
    reset();

    return () => {
      reset();
    };
  }, []);

  const {
    code_subheading,
    acceptance_number,
    acceptance_date,
    release_number,
    subchapter,
    code_businessUnit,
  } = values;

  const {
    itemId,
    loading,
    editData,
    setLoading,
    subTotals,
    showAddItem,
    setShowAddItem,
    setRequestEdit,
    deleteAttachment,
    dataTableAddItems,
    setCodeSubheading,
    setDataTableAddItems,
    pageCustomsClearances,
    setPageCustomsClearances,
    maxPageCustomsClearances,
    getItemsCustomsClearances,
    setMaxPageCustomsClearances,
    setgetItemsFormCustomsClearances,
    getItemsFormCustomsClearances: itemsForms,
    editItemsCustomsClearance: updateAddItems,
    decimalUcContext,
    setDecimalUcContext,
  } = useContext(CustomsCrudContext);
  const [isUploadActive, setIsUploadActive] = useState(false);

  useEffect(() => {
    reset();
    //setAttached([]);
    setExistingFiles([]);
    setAttachment([]);
    setDataTableAddItems([]);
    setInfoTradeAgreements([]);
  }, [Customs]);

  const endpointAttachments = {
    create: environment.UPDATE_CUSTOMS_CLEARANCE,
    delete: environment.DELETE_OTHER_ATTACHMENT_CUSTOMS,
  };

  const showItemFormCustomsClearance = async (id) => {
    setLoading(true);
    try {
      const res = await __getShowItemFormCustomsClearance(token, id);
      const resData = res?.data?.status?.data;
      if (res?.data?.status.code === 200) {
        setgetItemsFormCustomsClearances(resData);
        setMaxPageCustomsClearances(res?.data?.status?.data?.last_page);
        if (resData.trade_agreement_id) {
          setInfoTradeAgreements({
            label:
              resData.code_tradeAgreement + " " + resData.name_tradeAgreement,
            value: resData.trade_agreement_id,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (itemId) {
      showItemFormCustomsClearance(itemId);
    }
  }, [itemId, token]);

  useEffect(() => {
    TradeAgreements();
    setRequestEdit(environment.UPDATE_CUSTOMS_CLEARANCE);
    // Corregir
    if (Customs) showCustomClearence();
  }, []);

  const showCustomClearence = async () => {
    setLoading(true);

    try {
      const res = await __ShowCustomsClearence(token, Customs);
      const data = res.data.status.data;
      if (res.data.status?.code === 200) {
        const FilterData = Object.keys(data).reduce((acc, key) => {
          const value = data[key];
          acc[key] = value !== null && value !== undefined ? value : "";
          return acc;
        }, {});

        setValues(FilterData);

        setSelectUserQualified({
          label: `${FilterData?.nit_qualified} - ${FilterData?.name_qualified}`,
          value: FilterData?.qualifiedUsers_id,
        });

        getItemsCustomsClearances(data?.id);

        if (data.tradeAgreement_id !== null) {
          setInfoTradeAgreements({
            value: data?.tradeAgreement_id,
            label:
              data?.code_tradeAgreement + " - " + data?.name_tradeAgreement,
          });
        }
        //setAttached(data.attachments);
        setExistingFiles(data.attachments);

        const outputObject = convertArrayToGroupedObject(
          data.other_attachment || [],
          "other_attachment"
        );
        setOtherDocuments(outputObject);

        data.other_attachment?.map((items) =>
          setAttachmentDescription((prev) => [
            ...prev,
            items?.other_attachment_description,
          ])
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleBlurPartida = () => {
    if (code_subheading && code_subheading.length > 9) {
      const formData = new FormData();
      formData.append("code_subheading", code_subheading);
      obtenerSubpartida(formData);
      setCodeSubheading(code_subheading);
    }
  };

  useEffect(() => {
    setCodeSubheading(code_subheading);
  }, [code_subheading]);

  const obtenerSubpartida = async (info) => {
    try {
      const res = await __ObtenerSubHeadingCode(token, info);
      const resultado = res?.data?.status;
      if (resultado.code === 403) {
        openAlert(resultado.message, "error");
      } else {
        setValues({
          ...values,
          subchapter: resultado.data.subchapter,
          code_businessUnit: resultado.data.code_businessUnit,
        });

        setSubheaId(resultado.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TradeAgreements = async () => {
    try {
      const res = await __TradeAgreementsCustoms(token);
      const infoAcuerdo = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_tradeAgreement + " - " + item?.name_tradeAgreement,
      }));
      setStateTradeAgreements(infoAcuerdo);
    } catch (error) {}
  };

  const { requestData } = useGlobalContext();

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      fileReload();
      return;
    }
  };

  const fileReload = () => {
    const requestData = {
      resourceIdentifier: Customs,
      Endpoint: environment.SHOW_CUSTOMS_CLEARANCE,
    };

    mutate(requestData, {
      onSuccess: ({ data }) => {
        const { attachments, other_attachment } = data?.status?.data || {};

        setUploadAttached(null);
        // setAttached(attachments);
        setExistingFiles(attachments);
        const outputObject = convertArrayToGroupedObject(
          other_attachment || [],
          "other_attachment"
        );
        setOtherDocuments(outputObject);
        //  setOtherDocuments(other_attachment);
      },
    });

    /* setAttachment([]);
    SHOW_CUSTOMS_CLEARANCE */
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("id", Customs);
    formData.append(
      "tradeAgreement_id",
      infoTradeAgreements?.value !== undefined ? infoTradeAgreements?.value : ""
    );
    formData.append("subheading_id", subheaId ?? values?.subheadings_id);
    formData.append("code_subheading", code_subheading);
    formData.append("acceptance_number", acceptance_number);
    formData.append("acceptance_date", acceptance_date);
    formData.append("release_number", release_number);
    formData.append("subchapter", subchapter);
    formData.append("code_businessUnit", code_businessUnit);

    if (attachment) {
      attachment.forEach((file) => {
        if (!file.attachment_url) {
          formData.append("other_attachment[]", file);
        }
      });
    }
    if (attachmentDescription) {
      attachmentDescription.forEach((descrip) => {
        formData.append("other_attachment_description[]", descrip);
      });
    }
    if (attachmentType) {
      attachmentType.forEach((type) => {
        formData.append("other_attachment_type[]", type);
      });
    }

    if (!code_subheading) {
      openAlert("La subpartida es necesaria", "error");
    } else if (!acceptance_number) {
      openAlert("El numero de aceptación es necesaria", "error");
    } else if (!acceptance_date) {
      openAlert("La fecha de aceptación es necesaria", "error");
    } else if (!release_number) {
      openAlert("El levante es necesario", "error");
    } else {
      editData(formData);
    }
  };

  const extensionOptions = [
    ".doc",
    ".docx",
    ".pdf",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".xls",
    ".xlsx",
    ".xlsm",
    "image/png",
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
  ];

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();
  const { get: getStorage } = Storage;

  const handleAttachment = (e) => {
    const files = e.target.files;
    const MAXIMO_TAMANIO_BYTES = 3000000;
    const getFileExtension = files[0].name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      const fileName = files[0].name;
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAXIMO_TAMANIO_BYTES) {
        openAlert(
          `El archivo ${files[i].name} excede el límite permitido de Megabytes.(3 MB)`,
          "error"
        );
      } else {
        setUploadAttached([files[0]]);
      }
    }
  };

  const td = [1, 2, 3, 4, 5, 6, 7, 8];

  const validateLevante = () => {
    if (
      release_number === acceptance_number &&
      release_number !== "" &&
      release_number !== null &&
      release_number !== undefined &&
      acceptance_number !== "" &&
      acceptance_number !== null &&
      acceptance_number !== undefined
    ) {
      openAlert(
        "El levante no puede ser igual al número de aceptación",
        "error"
      );
    }
  };

  const [handleDebounceRegexError] = useDebounceError();
  const dispatch = useDispatch();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    );
  };

  /**
   * Represents a book.
   * @constructor
   * @param {string} is_qualified_user - is on auth.dataUser and 1 is true and 0 is false.
   */
  const tHead = {
    0: theadCustomsClearanceActionNoneIsCommerce,
    1: theadCustomsClearance,
  };

  useEffect(() => {
    if (Customs) {
      return async () => {
        const body = new FormData();
        body.append("form_id", Customs);
        body.append("type_of", "DESADUANAMIENTO");
        body.append("type", 0);
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      };
    }
  }, [Customs]);

  const navigate = useNavigate();

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: Customs || "",
    type_of: "DESADUANAMIENTO",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  const uploadDeclaration = async (e, name) => {
    const resModal = await onOpenUpload();
    const uploadData = getStorage("uploadData")?.uploadData || {};

    const target = await e;
    const data = { file: { ...target }, ...uploadData, name };
    const formData = new FormData();
    formData.append("id", Customs);
    formData.append("attachment[]", JSON.stringify({ ...data }));

    const requestData = {
      data: formData,
      Endpoint: environment.UPDATE_CUSTOMS_CLEARANCE,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };




  const {
    userQualified,
    isLoading: userQualifiedLoading,
    selectUserQualified,
    setSelectUserQualified,
  } = useGetUserQualified();

  const createCustomsClearance = () => {
    if (!isValid(selectUserQualified?.value)) {
      openAlert("Seleccione una empresa", "error");
    }

    const formData = new FormData();
    formData.append("qualifiedUser_id", selectUserQualified?.value);
    formData.append("tradeAgreement_id", infoTradeAgreements?.value || "");
    formData.append("subheading_id", subheaId ?? values?.subheadings_id);
    formData.append("code_subheading", code_subheading);
    formData.append("acceptance_number", acceptance_number);
    formData.append("acceptance_date", acceptance_date);
    formData.append("release_number", release_number);
    formData.append("subchapter", subchapter);
    formData.append("code_businessUnit", code_businessUnit);

    if (!code_subheading) {
      openAlert("La subpartida es necesaria", "error");
      return;
    }

    if (!acceptance_number) {
      openAlert("El numero de aceptación es necesaria", "error");
      return;
    }

    if (!acceptance_date) {
      openAlert("La fecha de aceptación es necesaria", "error");
      return;
    }

    if (!release_number) {
      openAlert("El levante es necesario", "error");
      return;
    }

    const config = {
      data: formData,
      Endpoint: environment.CREATE_CUSTOMS_CLEARANCE,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (e) => respCreateCustomsClearance(e),
    });
  };

  const respCreateCustomsClearance = (res) => {
    if (res?.data?.status?.code === 200) {
      const numberFormIn = res.data.status.data;
      console.log("numberFormIn", numberFormIn);
      navigate(`/formCustomsClearance?Customs=${numberFormIn?.id}`, {
        replace: true,
      });
      dispatch(setIdShowCustoms(numberFormIn?.id));
    }

    if (res?.data?.status?.code !== 200) {
      openAlert(res.data.status.message, "error");
    }
  };
  const handleUploadComplete = () => {
    console.log("Todos los archivos han sido cargados");
    // Aquí puedes actualizar el estado de tu formulario principal o realizar otras acciones necesarias
  };

  const getAuthToken = async () => {
    // Implementa la lógica para obtener el token de autenticación
    return token;
  };

  const cleanFile = (file) => {
    console.log("cleanFile", file);
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ??
        file?.name_attachment ??
        file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint: environment.DELETE_OTHER_ATTACHMENT_CUSTOMS,
          resourceIdentifier: file?.id,
          // PropertyBody: "sendFormDataContentPOST",
        };

        mutate(requestData, {
          onSuccess: () => {
            setOtherDocuments([]);
            showCustomClearence();
          },
        });
      }
    });
  };

  return (
    <>
      {loading && <SpinnerComponent />}

      {!loading && (
        <div className="">
          <div className="mb-3">
            <ModalGeneric
              isOpen={isOpenUpload}
              onClose={onCloseUpload}
              onOpen={onOpenUpload}
              title="Cargar Archivo"
              size={"lg"}
            >
              <GroupInputUpload
                onClose={onCloseUpload}
                setIsUploadActive={setIsUploadActive}
                // callBack={closeModal}
              />
            </ModalGeneric>
            {/* <h1 className="text-center fw-bold fs-3">
              Edición de Formulario de Desaduanamiento En Zonas Francas
            </h1> */}

            <HeaderForm
              status={values?.status_customsClearance}
              numberForm={values?.customsClearanceForm_number}
              UserQualified={values?.name_qualified}
              Modulo={
                "Edición de Formulario de Desaduanamiento En Zonas Francas"
              }
            />
          </div>
          {values.reason_for_return && (
            <AlertReturnReason
              title={"Tipo de devolución:"}
              item={values.typifications}
              description={values.reason_for_return && values.reason_for_return}
              nickname={values.nickname}
            />
          )}

          <HeaderSearch>
            <div className="row">
              {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
              {authUser.is_qualified_user !== 1 && (
                <div className="col-sm ">
                  <InputSelect
                    tag={"Usuario calificado"}
                    data={userQualified}
                    set={setSelectUserQualified}
                    value={selectUserQualified}
                    isLoading={userQualifiedLoading}
                    isDisabled={isValid(selectUserQualified?.value)}
                  />
                </div>
              )}

              <div className="col-sm ">
                <LabeFormlStyled htmlFor="acceptance_number">
                  Nº de aceptacion
                </LabeFormlStyled>
                <InputFormStyled
                  type="text"
                  name="acceptance_number"
                  id="acceptance_number"
                  value={acceptance_number}
                  onChange={(e) => handleRegex(e, "all", Regex?.all)}
                  maxLength={
                    MaxLength.customClearance.formCustomsClearance
                      .acceptance_number
                  }
                />
              </div>
              <div className="col-sm">
                <LabeFormlStyled>Fecha aceptación</LabeFormlStyled>
                <InputFormStyled
                  type="date"
                  name="acceptance_date"
                  value={acceptance_date}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm">
                <LabeFormlStyled htmlFor="release_number">
                  Levante
                </LabeFormlStyled>
                <InputFormStyled
                  type="text"
                  name="release_number"
                  id="release_number"
                  value={release_number}
                  onChange={(e) => handleRegex(e, "all", Regex?.all)}
                  onBlur={validateLevante}
                  maxLength={
                    MaxLength.customClearance.formCustomsClearance
                      .release_number
                  }
                />
              </div>
              {/* </SimpleGrid> */}
            </div>

            <div className="row">
              {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
              <div className="col-sm mt-1">
                <LabeFormlStyled htmlFor="code_subheading">
                  Subpartida arancelaria
                </LabeFormlStyled>
                <InputFormStyled
                  type="search"
                  name="code_subheading"
                  id="code_subheading"
                  value={code_subheading}
                  onChange={(e) => handleRegex(e, "Números", Regex?.numeros)}
                  onBlur={handleBlurPartida}
                  maxLength={
                    MaxLength.customClearance.formCustomsClearance
                      .code_subheading
                  }
                />
              </div>

              <div className="col-sm mt-1">
                <LabeFormlStyled htmlFor="code_businessUnit">
                  Unidad comercial
                </LabeFormlStyled>
                <InputFormStyled
                  type="text"
                  name="code_businessUnit"
                  id="code_businessUnit"
                  value={code_businessUnit}
                  // onChange={handleInputChange}
                  disabled={true}
                />
              </div>

              <div className="col-sm">
                {/*   <InputSelect
                  onChange={handleInputChange}
                  data={example}
                  tag="example"
                  name={"dsdsdsds"}
                   isMulti
                /> */}

                <InputSelect
                  tag={"Acuerdo comercial"}
                  data={stateTradeAgreements}
                  set={setInfoTradeAgreements}
                  value={infoTradeAgreements}
                />
              </div>

              {/* </SimpleGrid> */}
            </div>

            <LabelGroupTextArea
              name={"subchapter"}
              value={subchapter}
              onChange={handleInputChange}
              tag={"Descripción de la subpartida"}
              size={100}
              desicion={true}
            />
            <div className="row mt-4">
              {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
              <div className="col-sm"></div>
              <div className="col-sm" />
              <div className="col-sm" />
              {/* </SimpleGrid> */}
            </div>
          </HeaderSearch>

          {/* agregar item */}
          {isValid(Customs) && (
            <>
              {" "}
              <div className="mt-5">
                <div className="mb-5">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginTop={"20px"}
                  >
                    <div>
                      <Text
                        className="ms-2"
                        fontSize="xl"
                        style={{ fontWeight: 600 }}
                      >
                        Ítems del Formulario
                      </Text>
                    </div>

                    <div>
                      <Tooltip placement="top" hasArrow label="Agregar ítem">
                        <ButtonHeaderModule
                          className="mx-1 d-flex float-end"
                          bgColor="#03C39A"
                          disabled={!subchapter}
                          onClick={() => setShowAddItem(!showAddItem)}
                        >
                          <IconGeneric color="#fff" as={CgPlayListAdd} />
                        </ButtonHeaderModule>
                      </Tooltip>
                    </div>
                  </Box>

                  <Collapse in={showAddItem} animateOpacity>
                    {/* <AddItemsForm /> */}
                    <FormAddItems
                      setShowAddItem={setShowAddItem}
                      idUser={values?.qualified_user_id}
                    />
                  </Collapse>
                </div>
                <div className="mb-5">
                  <HeaderSearch>
                    <div className="row">
                      {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_dex">
                          Subpartida
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          id="num_dex"
                          name="num_dex"
                          disabled={true}
                          value={values?.code_subheading}
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Certificado
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          id="num_commercial_invoice"
                          name="num_commercial_invoice"
                          value={
                            itemsForms?.integration_certificate
                              ? itemsForms?.integration_certificate
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Decl de Imp
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          id="num_commercial_invoice"
                          name="num_commercial_invoice"
                          value={
                            values?.acceptance_number
                              ? values?.acceptance_number
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Operación
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          name="num_commercial_invoice"
                          id="num_commercial_invoice"
                          value={
                            itemsForms?.code_operation
                              ? itemsForms?.code_operation
                              : "N/A"
                          }
                          // onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Inicial
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          name="num_commercial_invoice"
                          id="num_commercial_invoice"
                          value={
                            itemsForms?.code_operation_inicial
                              ? itemsForms?.code_operation_inicial
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Acuerdo
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          name="num_commercial_invoice"
                          id="num_commercial_invoice"
                          value={
                            values?.code_tradeAgreement
                              ? values?.code_tradeAgreement
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Saldo Disp.
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          name="num_commercial_invoice"
                          id="num_commercial_invoice"
                          value={
                            itemsForms?.quantity_available
                              ? itemsForms.quantity_available
                              : "N/A"
                          }
                          // value={itemsForms?.code_tradeAgreement}
                        />
                      </div>
                      <div className="col-sm">
                        <LabeFormlStyled htmlFor="num_commercial_invoice">
                          Saldo Prod.
                        </LabeFormlStyled>
                        <InputFormStyled
                          type="text"
                          disabled={true}
                          name="num_commercial_invoice"
                          id="num_commercial_invoice"
                          value={
                            itemsForms?.quantity_available_product
                              ? itemsForms?.quantity_available_product
                              : "N/A"
                          }
                        />
                      </div>
                      {/* </SimpleGrid> */}
                    </div>
                  </HeaderSearch>
                </div>
              </div>
              {/* table */}
              <div>
                <TableOrder
                  thead={tHead[is_qualified_user]}
                  data={
                    dataTableAddItems?.length === 0 ? [] : dataTableAddItems
                  }
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyFormCustomClearance}
                  imageNone
                  subTotals={subTotals}
                  titleTotal={
                    dataTableAddItems?.length !== 0 ? "Subtotales" : ""
                  }
                  td={dataTableAddItems?.length !== 0 ? td : ""}
                />
                {dataTableAddItems?.length !== 0 && (
                  <Pagination
                    page={pageCustomsClearances}
                    setPage={setPageCustomsClearances}
                    maxPage={maxPageCustomsClearances}
                    loading={loading}
                  />
                )}
              </div>
              <div className="my-3">
                <HeaderSearch>
                  <MultipleFileUploaderWithoutModal
                    title="Declaración de importación"
                    existingFiles={existingFiles}
                    onUploadComplete={handleUploadComplete}
                    id={Customs}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    setExistingFiles={setExistingFiles}
                  />
                </HeaderSearch>

                <HeaderSearch>
                  <Box display={"grid"} gap={4}>
                    <FileUploadManager
                      onUploadComplete={handleUploadComplete}
                      vaporBaseUrl={environment.VAPOR_BASE_URL}
                      getAuthToken={getAuthToken}
                      title="Otros documentos"
                      formID={Customs}
                      postPropertyName="other_attachment"
                      endpointAttachments={{
                        create: endpointAttachments?.create,
                      }}
                      handleResponseUpdateForm={handleResponseUpdateForm}
                    />

                    <ExistingFiles
                      existingFiles={otherDocuments?.other_attachment}
                      handleDeleteFile={cleanFile}
                    />
                  </Box>
                </HeaderSearch>
              </div>
            </>
          )}
          <div className="mt-5 text-end">
            <div className="me-2 mt-2 mb-4">
              <Button
                onClick={
                  !isValid(Customs) ? createCustomsClearance : handleSubmit
                }
                color="aqua"
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormCustomsClearance;
