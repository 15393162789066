import { __ValidateQuantitiesInSupplies } from "request/IntegrationBox/___IntegrationBox";

class SupplyQuantitiesValidationService {
  constructor(token) {
    this.token = token;
  }

  async validateQuantities(selectedValue, integrationCertificateId) {
    try {
      const response = await __ValidateQuantitiesInSupplies(this.token, {
        code_destination: selectedValue,
        integration_certificate_id: integrationCertificateId
      });

      const { message, code } = response.data.status;
      const validate_supplies_quantities = response.data?.data?.validate_supplies_quantities;

      return {
        isValid: code !== 400,
        message,
        validate_supplies_quantities
      };
    } catch (error) {
      return {
        isValid: false,
        message: 'Error al validar el tipo de datos',
        validate_supplies_quantities: null
      };
    }
  }
}

export default SupplyQuantitiesValidationService; 