import { BsFillPersonFill } from "react-icons/bs";
import { Box, Center, Divider, Image, Text } from "@chakra-ui/react";

import { ModalAssignCompany } from "pages/Reviews/Modal/ModalAssignCompany";

// Modal
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";

export const CardUpdateReview = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Asignar empresas a los usuarios - ${data?.nickname}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalAssignCompany Data={data} onclose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        maxW="initial"
        h="6rem"
        cursor={"pointer"}
        p="2"
        m="1"
        shadow={
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;"
        }
        borderRadius="lg"
        overflow="hidden"
        display={"flex"}
        onClick={onOpen}
      >
        <Box
          m="1"
          p="2"
          fontSize={"40px"}
          maxW="100px"
          borderRadius="lg"
          shadow={
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
          }
          backgroundColor="blue.600"
          textColor={"white"}
          w="25%"
          h="4rem"
        >
          <Center flexDirection={"column"}>
            <BsFillPersonFill />
            <Divider w={"8"} my />
          </Center>
        </Box>

        <Box flexDir={"row"} m="2" w="50%">
          <Text
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
            fontSize={"18px"}
            pt="0"
          >
            {data?.nickname}
          </Text>

          <Text fontSize={"12px"} pt="1">
            <b style={{ color: "dimgray" }}>
              {" "}
              A: {data?.count_form}F - {data?.items}I
            </b>
          </Text>
        </Box>

        <Box flexDir={"row"} m="2" w="50%">
          <Text
            mt="1"
            textAlign={"right"}
            as="h4"
            lineHeight="tight"
            noOfLines={1}
            fontSize={"14px"}
            pt="1.5"
          >
            20/10/21
          </Text>

          <Text fontSize={"12px"} pt="1">
            <b style={{ color: "dimgray" }}>
              {" "}
              P: {data?.count_form}F - {data?.items}I
            </b>
          </Text>
        </Box>
      </Box>
    </>
  );
};
