import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuProcess from "components/Menu/MenuProcess";

const TbodyInsumosProcess = ({ data, iope }) => {
  return (
    <Tr>
      <Td className="text-center">{iope + 1}</Td>
      <Td className="text-center">{data?.code_product}</Td>
      <Td className="text-center">{data?.description_product} </Td>
      <Td className="text-center">{data?.name_typeProduct} </Td>
      <Td className="text-center">{data?.quantity_product}</Td>
      <Td className="text-center">{data?.code_unit_measurement}</Td>
      <Td className="text-center">
        <MenuProcess data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyInsumosProcess;
