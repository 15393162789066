/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import InventoryControlContext from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { dataNat } from "../SearchTabInventory/ArraySelect";

export const FormChangeValues = ({ onClose }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loading, setLoading] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const [nat, setNat] = useState(null);

  const { dataSubheanding, updateSubpartida } = useContext(
    InventoryControlContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    net_weight: "",
    value_fob: "",
    value_cif: "",
    net_weightNew: "",
    value_fobNew: "",
    value_cifNew: "",
    description: "",
    nature_of_inventory: ""
  });

  const {
    net_weight,
    value_fob,
    value_cif,
    net_weightNew,
    value_fobNew,
    value_cifNew,
    description,
    nature_of_inventory
  } = values;

  const obtenerSubheandingCurrent = useCallback(
    async (body) => {
      setLoading(true);
      try {
        const res = await __Post(
          environment.GET_CHANGE_KARDEX_INVENTORY,
          token,
          body
        );
        const desResultado = res?.data?.status;
        if (desResultado?.code === 200) {
          setValues({
            ...desResultado?.data,
            net_weightNew: desResultado?.data?.net_weight,
            value_fobNew: desResultado?.data?.value_fob,
            value_cifNew: desResultado?.data?.value_cif
          });
          console.log(desResultado?.data?.nature_of_inventory);
          if (
            desResultado?.data?.nature_of_inventory !== null ||
            desResultado?.data?.nature_of_inventory !== undefined ||
            desResultado?.data?.nature_of_inventory !== ""
          ) {
            setNat({
              value: desResultado?.data?.nature_of_inventory,
              label: desResultado?.data?.nature_of_inventory
            });
          } else {
            setNat(null);
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    if (dataSubheanding !== null) {
      const formData = new FormData();
      formData.append("inventory_id", dataSubheanding);
      obtenerSubheandingCurrent(formData);
    }
  }, [dataSubheanding, obtenerSubheandingCurrent]);

  //   inventory_id:1
  //    net_weight:5000
  //    value_fob:50000
  //    value_cif:25000
  //    nature_of_inventory: nature_inventory: nat !== null ? nat.value : "",
  //    description:cambiaso kardex

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("inventory_id", dataSubheanding);
    formData.append("net_weight", net_weightNew);
    formData.append("value_fob", value_fobNew);
    formData.append("value_cif", value_cifNew);
    formData.append("description", description);
    formData.append("nature_of_inventory", nat !== null ? nat.value : "");
    updateSubpartida(
      environment.UPDATE_CHANGE_KARDEX_INVENTORY,
      formData,
      onClose
    );
  };
  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"net_weight"}
              value={net_weight || ""}
              onChange={handleInputChange}
              tag={"Peso neto actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"net_weightNew"}
              value={net_weightNew || ""}
              onChange={handleInputChange}
              tag={"Nuevo peso neto"}
              size={45}
              tipo={"text"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"value_fob"}
              value={value_fob || ""}
              onChange={handleInputChange}
              tag={"Valor FOB actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"value_fobNew"}
              value={value_fobNew || ""}
              onChange={handleInputChange}
              tag={"Nuevo valor FOB"}
              size={45}
              tipo={"text"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"value_cif"}
              value={value_cif || ""}
              onChange={handleInputChange}
              tag={"Valor CIF actual"}
              size={45}
              tipo={"text"}
              desicion={true}
            />

            <LabelGroup
              name={"value_cifNew"}
              value={value_cifNew || ""}
              onChange={handleInputChange}
              tag={"Nuevo valor CIF"}
              size={45}
              tipo={"text"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"nature_of_inventory"}
              value={nature_of_inventory || ""}
              onChange={handleInputChange}
              tag={"Naturaleza"}
              size={45}
              tipo={"text"}
              desicion={true}
            />
            <div style={{ width: `45%` }}>
              <InputSelect
                name={"date_by"}
                tag={"Nueva naturaleza"}
                data={dataNat}
                value={nat}
                set={setNat}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <LabelGroup
              name={"description"}
              value={description || ""}
              onChange={handleInputChange}
              tag={"Justificación del cambio"}
              size={100}
              tipo={"text"}
            />
          </div>

          <Flex className="mt-1">
            <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
              Cancelar
            </ButtonStyled>
            <Spacer />
            <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
              Guardar
            </ButtonStyled>
          </Flex>
        </>
      )}
    </>
  );
};
