import { Td, Tr } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

export const TBodyBalanceAssistant = ({ data }) => {
  const urlTemplates = {
    DESADUANAMIENTO: `/ShowCustomsClearance?Customs=${data?.form_id}`,
    "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
    "CUADR.INT.": `/ShowIntegrationBox?id=${data?.form_id}`,
    INGRESO: `/FormShow?FormEntry=${data?.form_id}`,
    SALIDA: `/FormShowExit?ExitNumber=${data?.form_id}`,
    "INGRESO ANULADO": `/FormShow?FormEntry=${data?.form_id}`,
    "SALIDA ANULADA": `/FormShowExit?ExitNumber=${data?.form_id}`,
    "INGRESO CORREGIDO": `/FormCorrection?ShowEntry=${data?.form_id}`,
    "SALIDA CORREGIDA": `/FormCorrectionOutput?ShowOutput=${data?.form_id}`,
    "CERTIF.INT CORREGIDO": `/ShowIntegrationBox?id=${data?.form_id}`,
    "CUADR.INT. CORREGIDO": `/ShowIntegrationBox?id=${data?.form_id}`,
    "DESPACHO CORREGIDO": `/DispathcCorrectionShow?Dispatch=${data?.form_id}&Action=Show`,
    DESPACHO: `/pases?numberForm=${data.form_code}&id=${data?.form_id}&status=${data?.status}&typeO=Dispatch`
  };

  const dynamicURL = urlTemplates[data?.operation_type];
  return (
    <Tr>
      <Td className="text-center">{data?.qualified_user_nit}</Td>
      <Td className="text-center">{data?.qualified_user_name}</Td>
      <Td className="text-center">{data?.date_authorized}</Td>
      <Td className="text-center">{data?.time_authorized}</Td>
      <Td className="text-center">{data?.authorization_reference_type}</Td>
      <Td className="text-center">{data?.authorization_reference}</Td>
      <Td className="text-center">{data?.item_code}</Td>
      <Td className="text-center">{data?.operation_type}</Td>

      {data?.form_id
        ? (
          <Td className="text-center">
            <NavLink to={dynamicURL} className="ms-2">
              <span className="formNumber">{data?.authorization}</span>
            </NavLink>
          </Td>
        )
        : (
          <Td className="text-center ">{data?.authorization}</Td>
        )}
      <Td isNumeric className="text-center">
        {" "}
        {data?.input_quantity}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.output_quantity}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.inventory_quantity_available}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.input_quantity_product}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.output_quantity_product}
      </Td>
      <Td isNumeric className="text-center">
        {data?.inventory_quantity_available_product}
      </Td>
    </Tr>
  );
};
