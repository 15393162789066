import {
  Box,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrderHover from "components/Tables/TableOrderHover";
import environment from "constants/apiConst";
import WeighingContext from "context/WeighingContext";
import { useForm } from "hooks/useForm";
import { DeleteLocalstore } from "hooks/useLocalStorage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { Title } from "styled/Title";
import { headTable } from "../Array/ArrayPrueba";
import TbodyDriver from "../Tbody/TbodyDriver";
import { ContainerRow } from "./ContainerRow";
import { ForGetWeihighing } from "./ForGetWeihighing";
import { FormPass } from "./FormPass";

const defaultState = {
  container: "",
  capacity_container: "",
  tare_container: ""
};

export const FormWeighing = ({ onClose, set }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [rows, setRows] = useState([defaultState]);
  const {
    isOpenOtherData,
    onOpenOtherData,
    onCloseOtherData,
    dataDrivers,
    setDataDrivers
  } = useContext(WeighingContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [horario, setHorario] = useState(null);
  const [CloseWeighing, setCloseWeighing] = useState(0);
  const [ownMeans, setOwnMeans] = useState(0);
  const idTypeTransporteWithContainer = [8, 9, 10, 12];

  const [values, handleInputChange, reset, setValues] = useForm({
    vehicle_plate: "",
    identification: "",
    driver: "",
    name_typeTransport: "",
    name_qualified: "",
    passForm_number: "",
    freely_available: "",
    own_means: "",
    empty_pass: "",
    handling_process: "",
    type_weight: "",
    weight_id: "",
    tare: "",
    gross_weight: "",
    tare_weight: "",
    incomplete: "",
    not_close: 0,
    automatic: null,
    weighing_manual: "",
  });

  const {
    vehicle_plate,
    containers,
    typeTransport_id,
    type_weight,
    pass_id,
    weight_id,
    weight,
    pass,
    not_close,
    own_means,
    automatic,
    weighing_manual
  } = values;

  useEffect(() => {
    setCloseWeighing(not_close);
  }, [values]);

  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handleShowPlate = useCallback(
    async (dataPlateToSend) => {
      setLoading(true);
      try {
        const data = await __Post(
          environment.SHOW_PLATE_WEIGHING,
          token,
          dataPlateToSend
        );
        const resultado = data.data.status.data;
        if (resultado?.length > 1) {
          setData(resultado);
          onOpenOtherData();
        } else if (resultado?.length === 1) {
          setValues(resultado[0]);
        } else if (!resultado?.length) {
          openAlert(
            ownMeans
              ? "No hay fmm por sus propios medios"
              : "No hay formularios para pesar con esa placa",
            "error"
          );
          if (ownMeans) setOwnMeans(0);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [token, ownMeans]
  );

  useEffect(() => {
    const dataPlateToSend = {
      plate: vehicle_plate,
      own_means: ownMeans
    };
    if (vehicle_plate !== "" && vehicle_plate?.length >= 6 && !ownMeans) {
      setOwnMeans(0);
      handleShowPlate(dataPlateToSend);
    }
  }, [vehicle_plate, handleShowPlate]);

  useEffect(() => {
    if (ownMeans === 1 && vehicle_plate === "") {
      const dataPlateToSend = {
        plate: "",
        own_means: ownMeans
      };
      handleShowPlate(dataPlateToSend);
    } else if (ownMeans === 0) {
      reset();
      setDataDrivers(null);
    }
  }, [ownMeans]);

  useEffect(() => {
    if (dataDrivers) {
      setValues(dataDrivers);
    }
  }, [dataDrivers]);

  useEffect(() => {
    const tipodato = typeof setRows;
    if (containers !== null && tipodato === "function") {
      setRows(containers);
    }
  }, [containers]);

  useEffect(() => {
    const fechaHoy = new Date();
    const dia = fechaHoy.getDay();
    const hora = fechaHoy.getHours();
    if (hora < 8 && hora > 18 && (dia === 0 || dia === 6)) {
      setHorario("CORRIDO");
    } else if (hora >= 8 && hora <= 18 && (dia !== 0 || dia !== 6)) {
      setHorario("NORMAL");
    }
  }, [values]);

  const GetIncomplete = () => {
    const result = data.some(
      (element) => element.completeness === "INCOMPLETO"
    );

    if (data.length > 1 && result) {
      return 1;
    }

    if (data.length > 0 && !result) {
      return 0;
    }

    if (data.length === 0) {
      return 0;
    }
  };

  const GetWeighing = () => {
    const firstIncomplete = data.find(
      (element) => element.completeness === "INCOMPLETO"
    );
    const weightId = firstIncomplete ? firstIncomplete.weight_id : undefined;

    if (weight_id) {
      return weight_id;
    }

    if (weightId) {
      return weightId;
    }
  };

  const sendData = () => {
    const scaleId = localStorage.getItem("chanelWeighing");

    // console.log(data);
    const newInfo = {};
    Object.entries({
      type_weight,
      pass_id,
      weight_id: GetWeighing(),
      weight,
      type: automatic ? "AUTOMATICO" : "MANUAL",
      pass: parseInt(pass),
      contenedores: rows,
      vehicle_plate,
      shedule: horario,
      status: "NORMAL",
      not_close: CloseWeighing,
      own_means: ownMeans
    }).map(([key, valor]) => {
      if (!valor) {
        return 0;
      }
      return (newInfo[key] = valor);
    });

    if (values.pass_id === "" || values.pass_id === null) {
      openAlert("Seleccione un vehículo", "error");
    } else if (values.type_weight === "" || values.type_weight === null) {
      openAlert("El tipo de peso es requerido", "error");
    } else if (values.weight === "") {
      openAlert("El peso es requerido", "error");
    } else if (!scaleId) {
      openAlert("Seleccione una bascula", "error")
    } else {
      if (values.type_weight === "Tara") {
        const info = {
          ...newInfo,
          incomplete: /*  Boolean === true ? 1 : 0 */ GetIncomplete(),
          tare_scale_id: parseInt(scaleId)
        };
        saveWeihing(info);
        DeleteLocalstore("INCOMPLETE_SELECT");
      } else if (values.type_weight === "Bruto") {
        const info = {
          ...newInfo,
          incomplete: GetIncomplete(),
          gross_scale_id: parseInt(scaleId)
        };
        saveWeihing(info);
        DeleteLocalstore("INCOMPLETE_SELECT");
      }
    }
  };

  const saveWeihing = async (data) => {
    setLoading(true);
    try {
      const resp = await __Post(
        environment.ASSOCIATE_WEIGHING_PASS,
        token,
        data
      );
      const resultado = resp.data.status;
      if (resultado.code === 200) {
        set((prev) => !prev);
        closeAndClean();
        openAlert("Procesado con éxito", "success");
      } else if (resultado.code !== 200) {
        openAlert(resultado.message, "error");
      }
      setLoading(false);
    } catch (error) {
      openAlert(error, "error");
    }
  };

  const handleOnChange = (index, name, value) => {
    const copyRows = [...rows];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value
    };
    setRows(copyRows);
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rows];
    copyRows.splice(index, 1);
    setRows(copyRows);
  };

  const closeAndClean = () => {
    reset();
    onClose();
    setDataDrivers(null);
  };
  const [StatusCheck, setStatusCheck] = useState(true);
  const [Verified, setVerified] = useState();

  useEffect(() => {
    data?.map(({ completeness }) => {
      if (completeness === "INCOMPLETO") {
        setVerified(true);
      }
    });
  }, [data]);

  useEffect(() => {
    const CheckIncomplete = (data, setStatus) => {
      if (data.length === 0) {
        setStatus(true);
      }

      const first = data[0];

      for (let i = 1; i < data.length; i++) {
        if (data[i]?.pass !== first?.pass) {
          setStatus(false);
        }
      }
    };

    CheckIncomplete(data, setStatusCheck);
  }, [data, StatusCheck]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenOtherData}
        onOpen={onOpenOtherData}
        onClose={onCloseOtherData}
        title="Información"
        size="4sxl"
      >
        <TableOrderHover
          thead={headTable}
          data={data}
          setData={setData}
          loading={loading}
          StatusCheck={StatusCheck}
          IncompleteStatus={Verified}
          onCloseModal={onCloseOtherData}
          tbodyData={TbodyDriver}
        />
      </ModalGeneric>

      <Box>
        <input
          style={{ width: "100%" }}
          type="range"
          min="0"
          max="2"
          value={tabIndex}
          onChange={handleSliderChange}
        />

        <Tabs isFitted index={tabIndex} onChange={handleTabsChange}>
          <TabList mb="1em">
            <Tab
              _selected={{
                color: "white",
                bg: "blue.500",
                borderRadius: "5px"
              }}
              isDisabled={
                tabIndex === 0
                  ? false
                  : tabIndex === 1
                    ? true
                    : tabIndex === 2
                      ? true
                      : true
              }
            >
              Buscar placa
            </Tab>
            <Tab
              _selected={{
                color: "white",
                bg: "blue.500",
                borderRadius: "5px"
              }}
              isDisabled={
                tabIndex === 1
                  ? false
                  : tabIndex === 0
                    ? true
                    : tabIndex === 2
                      ? true
                      : true
              }
            >
              Obtener peso
            </Tab>
            <Tab
              _selected={{
                color: "white",
                bg: "blue.500",
                borderRadius: "5px"
              }}
              isDisabled={
                tabIndex === 2
                  ? false
                  : tabIndex === 0
                    ? true
                    : tabIndex === 1
                      ? true
                      : true
              }
            >
              Confirmar datos
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FormPass
                values={values}
                handleInputChange={handleInputChange}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                onClose={closeAndClean}
                id={pass_id}
                setValues={setValues}
                ownMeans={ownMeans}
                setOwnMeans={setOwnMeans}
                CloseWeighing={CloseWeighing}
                setCloseWeighing={setCloseWeighing}
                ButtonActive={false}
              >
                {containers &&
                  typeTransport_id !== null &&
                  idTypeTransporteWithContainer.includes(typeTransport_id) && (
                  <div id="marginIcon" className="mt-2">
                    <Title size={"18px"} weight={600}>
                        Contenedores
                    </Title>
                    <Box>
                      {rows &&
                          rows.map((row, index) => (
                            <ContainerRow
                              {...row}
                              onChange={(name, value) =>
                                handleOnChange(index, name, value)
                              }
                              onRemove={() => handleOnRemove(index)}
                              key={index}
                              tabIndex={tabIndex}
                            />
                          ))}
                    </Box>
                  </div>
                )}
              </FormPass>
            </TabPanel>

            <TabPanel>
              <ForGetWeihighing
                values={values}
                handleInputChange={handleInputChange}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                set={setValues}
                rows={rows}
                setRows={setRows}
              >
                {containers &&
                  typeTransport_id !== null &&
                  idTypeTransporteWithContainer.includes(typeTransport_id) && (
                  <div id="marginIcon" className="mt-2">
                    <Title size={"18px"} weight={600}>
                        Contenedores
                    </Title>
                    <Box>
                      {rows &&
                          rows.map((row, index) => (
                            <ContainerRow
                              {...row}
                              weight_id={weight_id}
                              onChange={(name, value) =>
                                handleOnChange(index, name, value)
                              }
                              onRemove={() => handleOnRemove(index)}
                              key={index}
                              tabIndex={tabIndex}
                            />
                          ))}
                    </Box>
                  </div>
                )}
              </ForGetWeihighing>
            </TabPanel>

            <TabPanel>
              <FormPass
                values={values}
                handleInputChange={handleInputChange}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                onClose={closeAndClean}
                CloseWeighing={CloseWeighing}
                ownMeans={ownMeans}
                setOwnMeans={setOwnMeans}
                setCloseWeighing={setCloseWeighing}
                ButtonActive={false}
                CloseWeighingStatus={true}
              />
              <ForGetWeihighing
                values={values}
                set={setValues}
                handleInputChange={handleInputChange}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
              {containers &&
                typeTransport_id !== null &&
                idTypeTransporteWithContainer.includes(typeTransport_id) && (
                <div id="marginIcon" className="mt-2">
                  <Title size={"18px"} weight={600}>
                      Contenedores
                  </Title>
                  <Box>
                    {rows &&
                        rows.map((row, index) => (
                          <ContainerRow
                            {...row}
                            weight_id={weight_id}
                            onChange={(name, value) =>
                              handleOnChange(index, name, value)
                            }
                            onRemove={() => handleOnRemove(index)}
                            key={index}
                            tabIndex={tabIndex}
                          />
                        ))}
                  </Box>
                </div>
              )}
              <Flex>
                <ButtonStyled bgColor={"#bababa"} onClick={closeAndClean}>
                  Cancelar
                </ButtonStyled>
                <ButtonStyled
                  bgColor={"#3182CE"}
                  onClick={() => setTabIndex(tabIndex - 1)}
                >
                  Volver
                </ButtonStyled>
                <Spacer />
                <ButtonStyled bgColor={"#3182CE"} onClick={!loading && sendData}>
                  Guardar
                </ButtonStyled>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
