import React from 'react'
import { IconGeneric } from 'styled/Icons.styled'
import './Style.css'
import { Text } from '@chakra-ui/react'
export const ChildrenMethod = ({
  title = '',
  icon = '',
  IconColor,
  quantity = '',
  porcentaje = '',
  time = ''
}) => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col '>
          <Text
            fontFamily={'Poppins, sans-serif'}
            fontStyle={'normal'}
            fontWeight={700}
            fontSize={'12px'}
            color={'#41495a'}
          >
            {title ?? 'N/A'}
          </Text>
        </div>
        <div className='col'>
          <div className='float-end'>
            <span id='tituloQuantity'>
              {quantity ?? 'N/A'}{' '}
              <IconGeneric cursor={'default'} width={'14px'} color={IconColor} as={icon} />
            </span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <span id='tituloPorcentaje'>{porcentaje ?? 'N/A'}</span>
        </div>
        <div className='col'>
          <div className='float-end'>
            <span id='tituloTime'>{time ?? 'N/A'} </span>
          </div>
        </div>
      </div>
      {/* <input
        style={{ width: '100%' }}
        type='range'
        min='0'
        max='100'
        value={porcentaje}
      /> */}
    </div>
  )
}
