/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useSelector } from "react-redux";
import { __GetItems } from "request/Forms/_CrudForm";
import { __ShowForm } from "request/Forms/__ShowForm";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TerritoryNationalDisable } from "./viewFmmShow/TerritoryNationalDisable";
import { RestOfTheWorldDisabled } from "./viewFmmShow/RestOfTheWorldDisabled";
import { UserToUserDisabled } from "./viewFmmShow/UserToUserDisabled";
import TableItems from "../View/ViewItems/TableItems";
import { BtnShowFmm } from "../BtnShowHeader/BtnShowFmm";
import { StatusOfUser } from "../BtnShowHeader/StatusOfUser";
import { ModalGeneric } from "components/modal/ModalGeneric";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
} from "@chakra-ui/react";
import ModalCopy from "components/modal/ModalCopy";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import Swal from "sweetalert2";
import { SubTitle } from "styled/Title";
import { formaterCant } from "components/Funciones/formaterCant";
import { ButtonHeaderModule, ButtonStyled } from "styled/Buttons.styled";
import { ContainerTitleOutput } from "./ContainerTitleOutput";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ExportPdfOutput } from "../Export/pdfOriginal/ExportPdfOutput";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import TableOrder from "components/Tables/TableOrder";
import { TitleMovementIntSinAcciones } from "pages/Pass-Form/View/ArrayHeadTable/TheadMovementInt";
import TBodyEntryMovement from "pages/Pass-Form/tbody/TBodyEntryMovement";
import Pagination from "components/Pagination/Pagination";
import { __GetDataId } from "request/Petitions/__Get";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { TheadShowTrackingItems } from "../ArrayHeaders/ArrayHeader";
import TBodyShowTrackingFmm from "pages/Seguimiento/Table/TBodyShowTrackingFmm";
import { useModal } from "hooks/useModal";

import { FormCreateFmmTracking } from "pages/Seguimiento/form/FormCreateFmmTracking";
import MonitoringContext from "context/MonitoringContext";
import { FormStopTrackingForm } from "pages/Seguimiento/form/FormStopTrackingForm";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { FormCorrectionLog } from "../FormEntry/FormCorrectionLog";
import { __Post } from "request/Petitions/__Post";
import { AuthContext } from "context/AuthContext";
import ModalRecoverForm from "../View/ModalRecoverForm";
import ModalDiscard from "../View/ModalDiscard";
import RenderItems from "../View/ViewItems/RenderItems";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { CommentManager } from "components/DispatchCorrections/CommentManager/CommentManager";
import { Action } from "react-tiny-fab";
import { RiListSettingsLine, RiSearch2Line } from "react-icons/ri";
import { useDebounce } from "hooks/useDebouce";
import { useNotations } from "hooks/useGetInput";
import { renderInputWithLabel } from "../View/ViewItems/ViewItemsEntryForm";
import isArrayNotEmpty, {
  cleanDeepObject,
  isArray,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import { useGlobalContext } from "context/GlobalContext";
import { IconGeneric } from "styled/Icons.styled";
import { VscFilePdf } from "react-icons/vsc";
import useStatusListener from "hooks/useStatusListener";
import InputSelect from "components/InputSelect/InputSelect";
import { EditableInput } from "./DataCompanyExit/CustomInput";
import EnhancedFileUploadManager from "pages/CustomsClearance/MultipleFileUploader";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";
import { convertArrayToGroupedObject } from "../FormEntry/BodyForm";
import CreateAt from "../View/CreateAt";

const commentTemplate = {
  renderProperties: {
    make: "make_comment",
    date: "date_comment",
    comment: "comment",
    person: "person_name",
  },
  functionProperties: {
    handleComment: "comment",
  },
};

export const ShowExitFmm = () => {
  const location = useLocation(); // navigate
  const { ExitNumber = "" } = queryString.parse(location.search);
  const {
    token,
    is_commerce,
    is_qualified_user,
    admin,
    user_rol: { modules },
  } = useSelector((state) => state.auth.dataUser);
  const { pathname } = location;

  const [isOpenShowItems, onOpenShowItems, onCloseShowItems] = useModal();
  const [load, setLoad] = useState([]);
  const [currentPageTrackings, setCurrentPageTrackings] = useState([]);
  const {
    isOpenCreateTrackingFmm,
    onOpenCreateTrackingFmm,
    onCloseCreateTrackingFmm,
    isOpenStopTracking,
    onOpenStopTracking,
    onCloseStopTracking,
  } = useContext(MonitoringContext);

  const [costCorrect, setCostCorrect] = useState(null);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [followOrder, setFollowOrder] = useState([]);

  const [maxPage, setMaxPage] = useState(1);
  const {
    update,
    setRequestChangeStatusForm,
    isOpenDesechar,
    onCloseDesechar,
    dataToDesechar,
    changeStatus,
    isOpenCopy,
    onCloseModal,
    copyData,
    setRequestCopy,
    setRequestCreatePassNew,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    DocumentOperation,
    setDocumentOperation,
    deleteAttachment,
    isOpenCaducar,
    dataToCaducar,
    isOpenAnular,
    dataToAnular,
    isOpenCorrection,
    onOpenCorrection,
    onCloseCorrection,
    dataCorrection,
    dataCostos,
    CorrectDocument,
    setOpcional,
    opcional,
    dataDUTA,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
    showCorrectBalance,
    setshowCorrectBalance,
    editDataForm,
    setRequestEdit,
    addresDestine,
    isOpenDesCaducar,
    onOpenDesCaducar,
    onCloseDesCaducar,
    setDataForm,
  } = useContext(FormCrudCRUDContext);
  const { authUser } = useContext(AuthContext);
  const initialForm = {
    commercial_invoice: "",
    date: "",
    certificate_inspection_pre_boarding: "",
    term: "",
    destination_address: "",
    operation_id: "",
    thirdPartie_id: "",
    shipment: "",
    qualifiedUser_id: "",
    pass_required: "",
    nitQualifiedUser_id: "",
    modality: "",
    purchase_order: "",
    user: "",
    id: "",
    block_for_subheading_filling: "",
    block_for_subheading_approval: "",
  };

  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [items, setItems] = useState([]);

  const [precision, setPrecision] = useState(0);
  const [fomateo] = useFormatQuantity();
  const [otherDocuments, setOtherDocuments] = useState([]);

  const [statusActual, setStatusActual] = useState([]);
  const [openAlert] = AlertErrorAlert();

  const [files, setFiles] = useState({});
  const [nameFiles, setNameFiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const Searchdebounced = useDebounce(searchItem, 500);
  const navigate = useNavigate();

  useEffect(() => {
    setRequestCopy(environment.COPY_FORMS_EXIT);
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS_EXIT);
    setRequestCreatePassNew(environment.CREATE_DISPATCH);
    setRequestEdit(environment.UPDATE_FORM_EXIT);

    if (ExitNumber !== "") {
      ObtenerPases();
      // ObtenerPases();
    } else {
      setLoading(true);
    }
  }, [ExitNumber, update, precision]);

  const handlePresent = (status) => {
    const info = {
      id: form.id,
      status_outputForm: status,
    };
    changeStatus(info);
  };

  const handleCaducar = (status) => {
    const info = {
      id: dataToCaducar.id,
      status_outputForm: status,
    };
    changeStatus(info);
    onCloseModal();
  };

  const handleAnular = (status) => {
    const info = {
      id: dataToAnular.id,
      status_outputForm: status,
    };
    changeStatus(info);
    onCloseModal();
  };

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  const handleFilesInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const Files = e.target.files;

    const extensionOptions = [
      ".doc",
      ".docx",
      ".pdf",
      ".png",
      ".jpg",
      ".jpeg",
      ".gif",
      ".bmp",
      ".xls",
      ".xlsx",
      ".xlsm",
      "image/png",
      "image/*",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/vnd.ms-excel",
    ];

    const getFileExtension = files[0].name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);

    if (!isValidExtension) {
      const fileName = files[0].name;
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        "error"
      );
    }

    Vapor.store(Files[0], {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("respuesta al momento de cargar", response);
      setFiles(
        Object.assign(
          { ...files },
          {
            [e.target.id]: {
              file: response,
              name: Files[0].name,
              document_id: e.target.id.replace("document-", ""),
            },
          }
        )
      );
      setNameFiles([...nameFiles, { name }]);
    });
  };

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${nombre}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          if (files[archivo].from_server) {
            deleteAttachment(
              environment.DELETE_ATTACHMENT_EXIT,
              files[archivo].id
            );
          }
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            console.log(obj[archivo]);
            delete obj[archivo];
          }
          setFiles(obj);
        }
      }
    });
  };

  useEffect(() => {
    if (dataCostos) {
      const respuestaFind = dataCostos?.values?.find(
        (e) => e.document === "Anexo Corregido de Salida"
      );
      const { value } = respuestaFind;
      setCostCorrect(value);
    }
  }, [dataCostos]);

  const correct = async (id) => {
    setLoading(true);
    try {
      const res = await __ShowForm(token, id, environment.SHOW_FORM_EXIT);
      const resultado = res?.data?.status;
      const { code, data } = resultado;
      const dataCorrectionBalance = {
        ...data,
        balance_correction: 1,
      };
      if (code === 200) {
        if (showCorrectBalance) {
          CorrectDocument(
            environment.CORRECT_FMMO,
            dataCorrectionBalance,
            hanldeCancel
          );
        } else {
          CorrectDocument(environment.CORRECT_FMMO, data, hanldeCancel);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const hanldeCancel = () => {
    onCloseCorrection();
    setshowCorrectBalance(false);
  };

  const handleChange = (id) => {
    const infoOutputCorrect = {
      id,
      status_outputForm: "BORRADOR",
      retrieved: "RECUPERADO",
      movement: "Salida",
    };

    changeStatus(infoOutputCorrect);
  };

  const [CommentReviews, setCommentReviews] = useState([]);

  const ObtenerPases = async () => {
    setLoading(true);
    try {
      const res = await __GetDataId(
        environment.OBTENER_PASS_OF_FMMO,
        token,
        ExitNumber
      );
      if (res?.data?.status?.code === 200) {
        setCurrentPage(res?.data?.status?.data);
      } else {
        openAlert(res?.data?.status.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCommentReviews(form?.review_updates);
  }, [form]);

  const showTrackingItem = async (trackings, reference, itemId) => {
    setLoad(true);
    try {
      const formData = new FormData();
      trackings?.forEach((trackings, index) => {
        formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
      });
      formData.append(`reference`, reference);
      formData.append(`item_id`, itemId);
      const res = await __GetTrackingsFmm(token, formData);
      setCurrentPageTrackings(
        res?.data?.status?.data?.data?.map((item) => ({
          ...item,
          reference,
          item_id: itemId,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      onOpenShowItems();
      setLoad(false);
    }
  };

  const sumPropertyValue = (items, propertyName) => {
    const sum = items?.reduce(
      (total, item) =>
        total + Number(item[propertyName]?.replaceAll(",", "") || 0),
      0
    );
    return sum;
  };
  const handleNetWeigh = () => {
    if (currentPage && currentPage.length > 0) {
      const arrayPesos = currentPage.map((element, index) => {
        if (element.weinghings[index] !== undefined) {
          return element.weinghings[index];
        }
      });
      const validValues = arrayPesos.filter((value) => {
        return value !== undefined;
      });
      const total = sumPropertyValue(validValues, "net_weight");
      return total;
    }
  };

  const [temporalBlock, setTemporalBlock] = useState(0);

  const isAuthorizationDeletedDocument =
    form?.status_outputForm !== "BORRADOR" && is_commerce === 1;

  const isAuthorizedToLockUnlock = modules?.some((item) =>
    item.permission.some(
      (name) => name.name_permission === "lock_and_unlock_output_form"
    )
  );

  const isNotQualifiedUser = is_qualified_user !== 1;

  const viewLockUnlockApproval =
    (form?.block_for_subheading_approval === 1 || temporalBlock !== 0) &&
    form?.status_outputForm === "PRESENTADO";

  const viewLockUnlockFilling =
    (form?.block_for_subheading_filling === 1 || temporalBlock !== 0) &&
    form?.status_outputForm === "APROBADO";

  const lockUnlock =
    form?.status_outputForm === "PRESENTADO"
      ? form?.block_for_subheading_approval === 1
        ? "Desbloquear"
        : "Bloquear"
      : form?.status_outputForm === "APROBADO" &&
        form?.block_for_subheading_filling === 1
      ? "Desbloquear"
      : "Bloquear";

  const bLockForm =
    form?.status_outputForm === "PRESENTADO"
      ? form?.block_for_subheading_approval === 1
        ? 0
        : 1
      : form?.status_outputForm === "APROBADO" &&
        form?.block_for_subheading_filling === 1
      ? 0
      : 1;

  const UnlockFormFilling = () => {
    try {
      Swal.fire({
        title: `¿Estas seguro de ${lockUnlock.toLowerCase()} el formulario "${
          form?.id
        }"?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#1F7AC3",
        confirmButtonText: "Confirmar",
        customClass: {
          title: "class-text",
        },
      }).then(async (resultado) => {
        if (resultado.isConfirmed) {
          const data = {
            outputForm_id: form?.id,
            block: bLockForm,
            type_block:
              form?.status_outputForm === "PRESENTADO"
                ? "block_for_subheading_approval"
                : form?.status_outputForm === "APROBADO"
                ? "block_for_subheading_filling"
                : "",
          };
          const res = await __Post(
            environment.LOCK_AND_UNLOCK_OUTPUT_FORM,
            token,
            data
          );
          if (res?.data?.status?.code === 200) {
            //  console.log(res?.data?.status?.data);
            setForm({
              ...form,
              block_for_subheading_filling:
                res?.data?.status?.data?.block_for_subheading_filling,
              block_for_subheading_approval:
                res?.data?.status?.data?.block_for_subheading_approval,
            });
            setTemporalBlock(1);
            return openAlert(res?.data?.status?.message, "success");
          }
        }
      });
    } catch (error) {}
  };

  async function fetchServerPage({ pageParam = 1 }) {
    const formData = new FormData();
    formData.append("outputForm_id", ExitNumber);
    formData.append("length", 5);
    orderBy?.value && formData.append("orderBy", orderBy?.value);
    if (searchItem) formData.append("item", searchItem);

    const res = await __GetItems(
      token,
      formData,
      pageParam,
      environment.GET_iTEM_EXIT
    );

    if (res?.data?.status?.code === 200) {
      const page =
        pageParam === res?.data?.status?.data?.last_page ? null : pageParam + 1;
      setItems(res?.data?.status?.data?.data);

      return {
        items: res?.data?.status?.data?.data,
        nextCursor: page,
      };
    }
  }

  const [listMode, setlistMode] = useState(false);

  const switchLoadingItem = () => {
    setlistMode((prev) => !prev);
  };

  const {
    status,
    data,
    error: isErrorPage,
    isFetching,
    isLoading: isLoadingItems,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchItems,
  } = useInfiniteQuery({
    queryKey: ["fetchServerPage", Searchdebounced, orderBy?.value],
    queryFn: fetchServerPage,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const allItems = data ? data.pages.flatMap((d) => d?.items) : [];

  const handleChangeSearch = (e) => {
    setSearchItem(e.target.value);
  };

  const notationsDestruct = useNotations({
    storageKey: ExitNumber,
    notationTypes: [
      {
        color: "gray",
        enableComment: false,
      },
      {
        color: "green",
        enableComment: false,
      },
      {
        color: "red",
        enableComment: true,
      },
    ],
    reviews: form?.reviews,
    reviewsUpdates: form.review_updates,
    reviewsNew: form?.reviews_new ? cleanDeepObject(form?.reviews_new) : {},
  });

  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations: notationsDestruct,
    id: "Header",
    data: form,
  };

  const { requestData } = useGlobalContext();

  const {
    data: dataViewForm,
    refetch: refetchViewForm,
    isFetching: isFetchingViewForm,
    isSuccess: isSuccessViewForm,
    isError: isErrorViewForm,
  } = useQuery({
    queryKey: ["viewForms", ExitNumber, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.SHOW_FORM_EXIT,
        resourceIdentifier: ExitNumber,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const dataForm = dataViewForm?.data?.status?.data;

      if (isErrorViewForm) {
        openAlert(dataForm?.data?.status.message, "error");
        navigate(`/`);
        return;
      }

      if (isSuccessViewForm) {
        dataForm?.expiration_final_date &&
          setExpirationDate(dataForm?.expiration_final_date);
        setForm(dataForm);
        console.log({ dataForm });
        setDataForm(dataForm);
        setStatusActual(dataForm?.users);
        setPrecision(dataForm?.precision);
        setCode(parseInt(dataForm?.code_operation));
        setDocumentOperation(dataForm?.documents);
        setOpcional(dataForm?.check_duta);

        const otherFiles = convertArrayToGroupedObject(
          dataForm?.other_files || [],
          "other_files"
        );

        setOtherDocuments(otherFiles);
        const fileServer = dataForm.files.reduce((old, it, index) => {
          return {
            ...old,
            ["document-" + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
              originalIndex: index,
              attachment_number: it.attachment_number,
              attachment_description: it.attachment_description,
              attachment_comment: it.attachment_comment,
              attachment_date: it.attachment_date,
            },
          };
        }, {});

        setFiles({
          ...fileServer,
        });
      }
    }

    return () => {
      setForm({});
    };
  }, [isFetchingViewForm, dataViewForm]);

  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      refetchViewForm();
      //  return setCalculated(res?.data?.status?.data);
    }
  };

  const firstRender = useRef(true);

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      flag === "output_form" &&
      new_status !== dataCurrent.status_outputForm &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.id;

    if (shouldRefetch) {
      ObtenerPases();
      refetchItems();
      refetchViewForm();
    }
  };

  useStatusListener({
    id: form?.id,
    callBack: refetchForm,
    dataCurrent: form,
  });

  const [isFetchingPDF, setIsFetchingPDF] = useState(false);

  const handleExportPdf = () => {
    setIsFetchingPDF(true);
    mutate(
      {
        data: {
          outputForm_id: ExitNumber,
        },
        Endpoint: environment.EXPORT_PDF_OUTPUTFORM,
        PropertyBody: "POST",
      },
      {
        onSuccess: (res) => {
          if (res?.data?.status?.code === 200) {
            openAlert(res?.data?.status?.data, "success");
          }
          setIsFetchingPDF(false);
        },
        onError: () => {
          setIsFetchingPDF(false);
        },
      }
    );
  };

  const handleResponseTerm = (res) => {
    if (res?.data?.status?.code === 400) {
      // return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      console.log("handleResponseTerm", res?.data?.status);
      return openAlert(res?.data?.status?.message, "success");
      // queryClient.invalidateQueries({ queryKey: ["getProductItemOP"] });
    }
  };

  const { isPending, mutate: mutateTerm } = useMutation({
    mutationFn: requestData,
  });

  const handleSave = (newValue) => {
    const config = {
      data: { id: ExitNumber, number_days_extension: parseFloat(newValue) },
      Endpoint: environment.UPDATE_FORM_EXIT,
      PropertyBody: "sendJSONContentPOST",
    };

    mutateTerm(config, {
      onSuccess: (data) => handleResponseTerm(data),
    });
  };

  const getAuthToken = async () => {
    return token;
  };

  const queryClient = useQueryClient();
  const cleanFile = (file) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ??
        file?.name_attachment ??
        file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint: environment.DELETE_OTHER_ATTACHMENT_EXIT,
          resourceIdentifier: file?.id,
        };

        mutate(requestData, {
          onSuccess: () => {
            //  setOtherFiles([]);
            setOtherDocuments([]);
            queryClient.invalidateQueries({ queryKey: ["viewForms"] });
          },
        });
      }
    });
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenStopTracking}
        onOpen={onOpenStopTracking}
        onClose={onCloseStopTracking}
        title={"Detener seguimiento"}
      >
        <FormStopTrackingForm
          formType={1}
          followOrder={followOrder}
          idForm={form?.id}
          codeForm={form?.code_outputForm}
        />
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenCreateTrackingFmm}
        onOpen={onOpenCreateTrackingFmm}
        onClose={onCloseCreateTrackingFmm}
        title={"Crear seguimiento"}
      >
        <FormCreateFmmTracking codeForm={form?.code_outputForm} formType={1} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenShowItems}
        onOpen={onOpenShowItems}
        onClose={onCloseShowItems}
        title={"Seguimiento"}
      >
        <TableOrder // tabla
          thead={TheadShowTrackingItems}
          data={currentPageTrackings}
          setData={setCurrentPageTrackings}
          loading={load}
          tbodyData={TBodyShowTrackingFmm}
          setState={setCurrentPageTrackings}
        />
        {/* <Pagination
          page={pageTrackings}
          setPage={setPageTrackings}
          maxPage={maxPageTrackings}
          loading={load}
        /> */}
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onCloseShowItems}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <CommentManager
        identifier={ExitNumber}
        commentGetService={environment.GET_COMMENT_FMM_EXIT}
        commentCreationService={environment.COMMENT_FMM_EXIT}
        properties={commentTemplate}
      >
        <Action
          style={{
            backgroundColor: "#3498db",
            width: "40px",
            height: "40px",
          }}
          text={listMode ? "Scroll infinito" : "Modo normal"}
          onClick={switchLoadingItem}
        >
          <RiListSettingsLine />
        </Action>
      </CommentManager>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar este formulario`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenDesechar}
        onClose={onCloseDesechar}
        title={`Desea desechar el formulario Nº ${dataToDesechar?.code_outputForm} 
        <br> <span style="color: red">Importante:</span> Esta acción no se puede deshacer `}
        size={"2xl"}
      >
        <ModalDiscard
          dataToDesechar={dataToDesechar}
          onCloseDesechar={onCloseDesechar}
          handlePresent={handlePresent}
          movement={"Salida"}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCaducar}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea caducar el formulario Nº ${dataToCaducar?.code_outputForm} `}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: "#F9A621" }}
            onClick={() => handleCaducar("CADUCADO")}
            ml={3}
          >
            Caducar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular  el formulario Nº ${dataToAnular?.code_outputForm} 
        <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario. `}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: "black", color: "white" }}
            onClick={() => handleAnular("ANULADO")}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size="xl"
      >
        <ModalRecoverForm
          dataRecuperar={dataRecuperar}
          onCloseRecuperar={onCloseRecuperar}
          handleChange={handleChange}
          movement={"Salida"}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCorrection}
        onOpen={onOpenCorrection}
        onClose={onCloseCorrection}
        title={`Corregir salida`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea{" "}
          {`${
            showCorrectBalance ? "hacer una correción de saldo en" : "corregir"
          }`}{" "}
          el formulario{" "}
          {dataCorrection?.code_entryForm || dataCorrection?.code_outputForm}?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              Esta acción generará un costo de{" "}
              {costCorrect && `$${formaterCant(costCorrect)}`}
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseCorrection}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => correct(dataCorrection?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenDesCaducar}
        onOpen={onOpenDesCaducar}
        onClose={onCloseDesCaducar}
        title={`Descaducar documento`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea Descaducar el formulario{" "}
          {form?.code_outputForm}?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              El documento regresara al estado Aprobado.
            </SubTitle>
          </div>
        </div>

        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseDesCaducar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() =>
              changeStatus({
                id: ExitNumber,
                status_outputForm: "APROBADO",
              })
            }
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      {form && (
        <ExportPdfOutput id="IdPdfFmmOutput" values={form} items={items} />
      )}

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div className="mb-5">
            <HeaderForm
              status={form?.status_outputForm}
              numberForm={form?.code_outputForm}
              UserQualified={form?.name_qualified}
              Modulo={"Formulario de salida en Zonas Francas"}
              items={items}
            />
          </div>

          <BtnShowFmm
            followOrder={followOrder}
            setFollowOrder={setFollowOrder}
            idFmm={ExitNumber}
            values={form}
            ExportPdf={
              <Tooltip placement="top" hasArrow label="Exportar PDF">
                <span>
                  <ButtonHeaderModule
                    bgColor="#03C39A"
                    className="mx-1"
                    onClick={handleExportPdf}
                    isLoading={isFetchingPDF}
                  >
                    <IconGeneric
                      width="16px"
                      className=" text-white"
                      as={VscFilePdf}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            }
          >
            {form.reason_for_return &&
              (form?.status_outputForm === "BORRADOR" ||
                form?.status_outputForm === "DEVUELTO") && (
                <AlertReturnReason
                  title={"Tipo de devolución:"}
                  item={form.typifications}
                  description={form.reason_for_return && form.reason_for_return}
                  nickname={form.nickname}
                />
              )}
            {form.output_form_corrected &&
              form.output_form_corrected.length > 0 && (
                <FormCorrectionLog
                  title={"Este formulario cuenta con corrección"}
                  item={form?.output_form_corrected}
                  // description={form && form?.reason_for_return}
                  // nickname={form?.nickname}
                />
              )}
            <CreateAt
              entries={statusActual}
              config={{
                statusKey: "status_outputForm",
                dateKey: "date_outputForm",
                timeKey: "time_outputForm",
                personNameKey: "person_name",
              }}
              expirationDate={expirationDate}
            />
            
            {isNotQualifiedUser &&
              (viewLockUnlockFilling || viewLockUnlockApproval) && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className="alert alert-warning w-50 pt-2 pb-2 m-auto mt-3 pl-2"
                    style={{ minHeight: "60px" }}
                    role="alert"
                  >
                    {viewLockUnlockFilling &&
                      `Radicación del documento esta ${
                        form?.block_for_subheading_filling === 0
                          ? "bloqueada"
                          : "desbloqueada"
                      }`}
                    {viewLockUnlockApproval &&
                      `Aprobación del documento esta ${
                        form?.block_for_subheading_approval === 0
                          ? "bloqueada"
                          : "desbloqueada"
                      }`}
                    {(isAuthorizedToLockUnlock || admin === 1) && (
                      <Button
                        onClick={UnlockFormFilling}
                        colorScheme="blackAlpha"
                      >
                        {lockUnlock}
                      </Button>
                    )}
                  </Box>
                </>
              )}
          </BtnShowFmm>
          <div className="d-flex justify-content-end mb-5">
            {/* <ButtonView data={form} /> */}
          </div>
          <Box>
            <HeaderSearch>
              <div className="row">
                {/* Empresa */}
                {renderInputWithLabel({
                  labelText: "Empresa",
                  name: "name_qualified",
                  manualValue: `${form?.nit_qualified} - ${form?.name_qualified}`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Importador/Comprador */}
                {renderInputWithLabel({
                  labelText: "Importador/Comprador",
                  name: "business_name",
                  manualValue: `${form?.identification_num ?? ""} - ${
                    form?.business_name ?? ""
                  }`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Tipo de operación */}
                {renderInputWithLabel({
                  labelText: "Tipo de operación",
                  name: "short_name_operation",
                  manualValue: `${form.code_operation ?? ""} ${
                    form?.short_name_operation ?? "N/A"
                  }`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Modalidad */}
                {renderInputWithLabel({
                  labelText: "Modalidad",
                  name: "refund",
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Embarque */}
                {renderInputWithLabel({
                  labelText: "Embarque",
                  name: "shipment",
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Dirección de destino */}
                {addresDestine.includes(form.code_operation) &&
                  renderInputWithLabel({
                    labelText: "Dirección de destino",
                    name: "destination_address",
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    PropsBox: {
                      className: "col-md-6",
                    },
                    annotationInfo,
                  })}

                {/* Plazo */}
                {isValid(form.term) && form?.term !== 0 && (
                  <EditableInput
                    label="Plazo (en días)"
                    initialValue={form?.term || "N/A"}
                    onSave={handleSave}
                    helperText="Introduce el plazo en días (máximo 365)"
                    placeholder="Ej: 30"
                    isRequired={true}
                    type="number"
                    min={1}
                    allowEdit={
                      form?.status_outputForm === "AUTORIZADO" ? true : false
                    }
                    max={365}
                    isLoading={isPending}
                    controlStyle={{
                      className: "col-md-6",
                      width: "50%",
                      "max-width": "100%",
                    }}
                    style={{
                      border: "none",
                      // width: "100%",
                      height: "38px",
                      "border-radius": "0",
                      "font-size": "1rem",
                      // display: "inline-block",
                      cursor: "cursor",
                      color: "rgb(49,49,49)",
                      borderBottom: " 2px solid #bababa80",
                      fontWeight: 600,
                    }}
                    queryKey={["plazo"]} // Clave de consulta para invalidar después del guardado
                  />
                )}

                {/* Requiere pase */}
                {code === 426 &&
                  renderInputWithLabel({
                    labelText: "Requiere pase",
                    name: "term",
                    manualValue:
                      form?.pass_required === 1
                        ? "Requiere pase"
                        : "No requiere pase",
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    PropsBox: {
                      className: "col-md-6",
                    },
                    annotationInfo,
                  })}
              </div>
            </HeaderSearch>

            <HeaderSearch>
              <div id="contenedores">
                <div className="row">
                  <ContainerTitleOutput code={code} />
                  <span>
                    <Text as="sub">* Opcional</Text>
                    <br />
                  </span>
                  <RadioCheck
                    set={setOpcional}
                    value={opcional}
                    item={dataDUTA}
                    disables={true}
                  />
                  {code ? (
                    code === 50 ? (
                      <TerritoryNationalDisable
                        form={form}
                        notations={notationsDestruct}
                        activeReview={false}
                      />
                    ) : code >= 201 && code <= 224 ? (
                      <RestOfTheWorldDisabled
                        form={form}
                        notations={notationsDestruct}
                        activeReview={false}
                      />
                    ) : code >= 401 && code <= 450 ? (
                      <TerritoryNationalDisable
                        form={form}
                        notations={notationsDestruct}
                        activeReview={false}
                      />
                    ) : code >= 601 && code <= 618 ? (
                      <RestOfTheWorldDisabled
                        form={form}
                        activeReview={false}
                        notations={notationsDestruct}
                      />
                    ) : code >= 801 && code <= 814 ? (
                      <UserToUserDisabled
                        form={form}
                        notations={notationsDestruct}
                        activeReview={false}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {opcional && (
                    <LabelGroup
                      name={"value_duta"}
                      value={form?.value_duta}
                      // onChange={handleInputChange}
                      tag={opcional}
                      size={100}
                      desicion={true}
                      // readOnly
                    />
                  )}
                </div>
              </div>
            </HeaderSearch>

            <HeaderSearch>
              {DocumentOperation.map((document, index) => (
                <div key={index}>
                  <DocumentUploader
                    document={document}
                    handleFiles={handleFiles}
                    handleFilesInput={handleFilesInput}
                    handleDelete={removeItemFromArr}
                    files={files}
                    setNameFiles={setNameFiles}
                    nameFiles={nameFiles}
                    index={index}
                    viewBtnDelete={isAuthorizationDeletedDocument}
                    documentPreview={true}
                  />
                </div>
              ))}
            </HeaderSearch>

            <HeaderSearch>
              {" "}
              <Box display={"grid"} gap={4} pt={12}>
                <EnhancedFileUploadManager
                  title="Otros documentos"
                  onUploadComplete={() => {}}
                  handleResponseUpdateForm={handleResponseUpdateForm}
                  endpointAttachments={{
                    create: environment.UPDATE_FORM_EXIT,
                  }}
                  postPropertyName={"other_attachment"}
                  vaporBaseUrl={environment.VAPOR_BASE_URL}
                  getAuthToken={getAuthToken}
                  formID={ExitNumber}
                />

                <ExistingFiles
                  existingFiles={otherDocuments?.other_files}
                  // isLoading={isPendingViewPass}
                  handleDeleteFile={cleanFile}
                />
              </Box>
            </HeaderSearch>

            {currentPage && currentPage.length > 0 && handleNetWeigh() ? (
              <div
                className="alert alert-warning w-50 pt-2 pb-2 text-center m-auto my-4 pl-2"
                role="alert"
              >
                Peso total acumulado en báscula: {fomateo(2, handleNetWeigh())}{" "}
                Kg
              </div>
            ) : null}

            {loadingItems && <SpinnerComponent />}
            {!loadingItems && (
              <>
                {items?.length !== 0 && (
                  <TableItems items={items} currentData={form} />
                )}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  w={"100%"}
                  mt={"1rem"}
                  gap={3}
                >
                  <Box w={"64"} mb={6} mt={6}>
                    <label
                      style={{
                        width: "100%",
                      }}
                    >
                      Buscar item
                      <InputGroup>
                        <Input
                          w={"100%"}
                          h={"38px"}
                          color={"#000"}
                          outline={"none"}
                          fontSize={"1rem"}
                          backgroundColor={"#ffffff87"}
                          onChange={handleChangeSearch}
                          value={searchItem}
                          placeholder={"Buscar item"}
                        />
                        <InputRightElement style={{}} as="button">
                          <RiSearch2Line color="green.500" />
                        </InputRightElement>
                      </InputGroup>
                    </label>
                  </Box>
                  <label
                    style={{
                      width: 300,
                    }}
                  >
                    <label style={{ width: "100%" }}></label>

                    <InputSelect
                      data={[
                        { value: "ASC", label: "Ascendente" },
                        { value: "DESC", label: "Descendente" },
                      ]}
                      value={orderBy}
                      name="order_by"
                      set={setOrderBy}
                      placeholder="Ordenar por"
                      inputAttrs={{
                        color: "#5f5f",
                      }}
                      isClearable={false}
                    />
                  </label>
                </Box>{" "}
                {items && items?.length > 0 && (
                  <RenderItems
                    notationsDestruct={notationsDestruct}
                    CommentReviews={CommentReviews}
                    FetchingStatus={status}
                    admin={admin}
                    dataItems={allItems}
                    formData={form}
                    isLoadingItems={isLoadingItems}
                    error={isErrorPage}
                    hasNextPage={hasNextPage}
                    isFetching={isFetching}
                    isFetchingNextPage={isFetchingNextPage}
                    is_commerce={is_commerce}
                    fetchNextPage={fetchNextPage}
                    showTrackingItem={showTrackingItem}
                    isInfiniteScroll={listMode}
                    templateProperty={{
                      propertyStatus: "status_outputForm",
                    }}
                  />
                )}
              </>
            )}
          </Box>

          {(form?.status_outputForm === "APROBADO" ||
            form?.status_outputForm === "AUTORIZADO") &&
            currentPage?.length !== 0 && (
              <Accordion allowMultiple>
                <AccordionItem border="0px">
                  <AccordionButton activeClassName="active">
                    <Box
                      flex="1"
                      textAlign="left"
                      className="d-flex align-items-center"
                    >
                      {currentPage?.length}{" "}
                      {currentPage?.length > 1 ? "Despachos" : "Despacho"}
                    </Box>
                    <AccordionIcon size={20} className="float-end" />
                  </AccordionButton>

                  <AccordionPanel pb={1}>
                    <TableOrder
                      thead={TitleMovementIntSinAcciones}
                      data={currentPage}
                      setData={setCurrentPage}
                      loading={loading}
                      tbodyData={TBodyEntryMovement}
                    />

                    <Pagination
                      page={page}
                      setPage={setPage}
                      maxPage={maxPage}
                      loading={loading}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
        </>
      )}
    </>
  );
};
