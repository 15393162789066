import { Text } from "@chakra-ui/react";
import React from "react";

export const ContainerTitleOutput = ({ code }) => {
  if (code === 50) {
    return (
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        Al resto del territorio nacional
      </Text>
    );
  } else if (code >= 201 && code <= 226) {
    return (
      <>
        <Text fontSize="xl" style={{ fontWeight: 600 }}>
          Al resto del mundo
        </Text>
      </>
    );
  } else if (code >= 401 && code <= 450) {
    return (
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        Al resto del territorio nacional
      </Text>
    );
  } else if (code >= 601 && code <= 618) {
    return (
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        A zona franca
      </Text>
    );
  } else if (code >= 801 && code <= 814) {
    return (
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        De Usuario a Usuario de la Misma Zona Franca
      </Text>
    );
  } else {
    return <div></div>;
  }
};
