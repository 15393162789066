import React from "react";

export const TbodyExporPdfProduct = ({ data }) => {
  return (
    <tr>
      <td className="text-center">
        <span>{data?.product_description}</span>
      </td>
      <td className="text-center">
        {data?.product_subheading_code ? data?.product_subheading_code : "N/A"}
      </td>
      <td className="text-center">
        {data?.product_business_unit_code
          ? data?.product_business_unit_code
          : ""}
      </td>
      <td className="text-center">{data?.net_weight_kilo}</td>
      <td className="text-center">{data?.total_us_product}</td>
      <td className="text-center">{data?.quantity_product}</td>
    </tr>
  );
};
