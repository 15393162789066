// utils/restConfig.js
import { __Get } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

export const restConfig = async (
  position,
  token,
  setStateReset,
  StateReset,
  userId
) => {
  const [openAlert] = AlertErrorAlert();
  try {
    const endpoint =
      position === 0
        ? userId
          ? `${environment.REST_ALERT_CONFI}/${userId}`
          : environment.REST_ALERT_CONFI
        : position === 1
        ? userId
          ? `${environment.REST_NOTIF_CONFI}/${userId}`
          : environment.REST_NOTIF_CONFI
        : userId
        ? `${environment.REST_ALERT_CONFI_EMPRESA}/${userId}`
        : environment.REST_ALERT_CONFI_EMPRESA;

    const res = await __Get(endpoint, token);

    const restData = res?.data?.status;

    if (restData?.code === 200) {
      setStateReset(!StateReset);
    } else {
      openAlert(restData.message, "error");
    }
  } catch (error) {
    console.log(error);
  }
};
