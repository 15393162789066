/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Divider, Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormPermisosShow = () => {
  const initialForm = {
    description_permission: "",
    name_permission: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    console.log('form', form)
    if (dataToShow) {
      setForm(dataToShow);
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  
  return (
    <>
      <Text fontSize="md" my={2} style={{ fontWeight: 600 }}>
        {/* {dataToEdit ? "Editar Licencia" : "Crear Licencia"} */}
        Permisos
      </Text>
      <Divider mb={3} />
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_permission}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Descripción</LabeFormlStyled>
                <InputFake>
                  {form.description_permission === null ? "---" : form.description_permission}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormPermisosShow;
