import React, { useContext, useEffect } from 'react'
import { Tooltip } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { RiEdit2Fill } from 'react-icons/ri'
import { AiOutlineFileDone } from 'react-icons/ai'
import { GiCardDiscard } from 'react-icons/gi'
import { MdContentCopy, MdCancel } from 'react-icons/md'
import { AuthContext } from 'context/AuthContext'
import MonitoringContext from 'context/MonitoringContext'
import { BsRecordCircle } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { hasCommonElements } from 'utils/type-check-utils'
import { localPermissions } from 'Permissions/permissions'

const ButtonsBorrador = ({
  handleEdit,
  handlePresent,
  handleCopy,
  handleDesechar,
  status,
  data,
  setFollowOrder
}) => {
  const { authUser } = useContext(AuthContext)
  const Formtype = 2

  const superadmin = useSelector(state => state.auth.dataUser.admin)
  const is_commerce = useSelector(state => state.auth.dataUser.is_commerce)
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()

  const handleClickDiscard = async () => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'FMMI')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    console.log(res)
    if (res?.data?.status?.code === 200) {
      handleDesechar()
      return setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000)
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleClickPresent = async () => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'FMMI')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    console.log(res)
    if (res?.data?.status?.code === 200) {
      handlePresent('PRESENTADO')
      return setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000)
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const { permissionList } = useSelector(state => state.permissions)
  const {
    update: permissionUpdate,
    changeStatus: changeStatusEntryForm,
    copy: copyEntryForm
  } = localPermissions.Entryform

  return (
    <>
      {(is_commerce === 1 || superadmin === 1) && status !== 'BORRADOR' && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='#B8DB1A'
              onClick={onOpenCreateTrackingFmm}
              className='mx-1'
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                className='text-white'
                width='16px'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || superadmin === 1) && status !== 'BORRADOR' && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='red'
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
            >
              <IconGeneric
                width='16px'
                as={MdCancel}
                color='#fff'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, permissionUpdate) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Editar'>
          <span>
            <ButtonHeaderModule
              onClick={handleEdit}
              className='mx-1'
              bgColor='#1F7AC3'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={RiEdit2Fill}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(hasCommonElements(permissionList, copyEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule onClick={handleCopy} bgColor='#03C39A'>
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, changeStatusEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Presentar'>
          <span>
            <ButtonHeaderModule
              onClick={handleClickPresent}
              className='mx-1'
              bgColor='#1F7AC3'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={AiOutlineFileDone}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, changeStatusEntryForm) ||
          authUser.admin === 1) && (
          <>
            <Tooltip placement='top' hasArrow label='Desechar'>
              <span>
                <ButtonHeaderModule
                  onClick={handleClickDiscard}
                  bgColor='#d92525'
                >
                  <IconGeneric
                    width='16px'
                    className='text-white'
                    as={GiCardDiscard}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </>
        )}
    </>
  )
}

export default ButtonsBorrador
