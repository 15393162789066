/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalTransition from "components/modal/ModalTransition";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import ProductCRUDContext from "context/ProductCRUDContext";
import { Box, Divider, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AuthContext } from "context/AuthContext";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "../LabelGroup";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import GlobalContext from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import environment from "constants/apiConst";
import { Regex } from "utils/Regex";

const initialForm = {
  code_productive_process: "",
  // qualifiedUser_id: "",
  description_productive_process: ""
};
const CreateTemplate = ({ isOpen, onClose }) => {
  const { setDataToEdit } = useContext(ProductCRUDContext);
  const [form, handleInputChange, resetForm, setForm] = useForm(initialForm);
  const { authUser } = useContext(AuthContext);
  const [selUSerQ, setSelUSerQ] = useState([]);
  const [userUSerQ, setUserUSerQ] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { requestData } = useContext(GlobalContext);

  const validateForm = (form, openAlert) => {
    if (!form?.code_productive_process) {
      openAlert("El código es requerido", "error");
      return false;
    }

    if (!form?.description_productive_process) {
      openAlert("la descripción es requerida", "error");
      return false;
    }

    if (
      !form.qualifiedUser_id &&
      (authUser.admin === 1 || authUser.is_commerce === 1)
    ) {
      openAlert("Seleccione el usuario calificado", "error");
      return false;
    }

    return true;
  };

  useEffect(() => {
    const userC = async () => {
      try {
        const res = await __AllQualifiedUSers(token);
        //  setUserCa(res?.data?.status?.data);
        // console.log(res?.data?.status?.data);
        const selUQ = res?.data?.status?.data.map((item) => ({
          value: item.id,
          label: item.name_qualified
        }));
        setSelUSerQ(selUQ);
      } catch (error) {
      } finally {

      }
    };
    userC();
  }, []);

  const handleResponseProductive = (res) => {
    const message = res?.data?.status?.message
    if (res?.data?.status?.code === 400) {
      if (Array.isArray(message)) {
        message.map(alert => {
          return openAlert(alert, 'error')
        })
      }
      return openAlert(message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const resData = res?.data?.status?.data;
      setDataToEdit(resData);
      navigate(
        `/edit-product-template?code_productive_process=${
          resData.code_productive_process
        }&description_productive_process=${
          resData.description_productive_process
        }&status_productive_process=${resData.status_productive_process}&id=${
          resData?.id
        }&identificacion=${
          resData?.qualifiedUser_id
        }&title=${"Editar proceso productivo"}`
      );
      resetForm();
      onClose();
    }
  };

  const { isLoading, mutate: MutateProductive } = useMutation({
    mutationFn: requestData,
    onSuccess: (data) => handleResponseProductive(data)
  });

  const handleSubmit = (search) => {
    const data = {
      ...form,
      qualifiedUser_id:
        authUser.admin === 1 || authUser.is_commerce === 1
          ? userUSerQ.value
          : authUser.userQualified,
      status_productive_process: 1
    };
    MutateProductive({
      pageParam: null,
      resourceIdentifier: null,
      data,
      Endpoint: environment.CREATE_PROCESO,
      PropertyBody: "sendJSONContentPOST"
    });
  };

  /*   const handleSubmit = async (values) => {
    const data = {
      ...form,
      qualifiedUser_id:
        authUser.admin === 1 || authUser.is_commerce === 1
          ? userUSerQ.value
          : authUser.userQualified,
      status_productive_process: 1,
    };

    setLoading(true);
    //status
    try {
      if (!validateForm(data, openAlert)) return;
      const res = await createData(data);
      setDataToEdit(data);
      navigate(
        `/edit-product-template?code_productive_process=${
          data.code_productive_process
        }&description_productive_process=${
          data.description_productive_process
        }&status_productive_process=${data.status_productive_process}&id=${
          data?.id
        }&identificacion=${
          data?.qualifiedUser_id
        }&title=${"Editar proceso productivo"}`
      );
      resetForm();
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }; */

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <ModalTransition
        isOpen={isOpen}
        onClose={onClose}
        modaltitle={"Nueva Plantilla"}
        size="xl"
        handleClick={handleSubmit}
        buttonMain={"Guardar"}
        buttons={false}
        scrollBehavior="outside"
      >
        <Text fontSize="md" mb={3} style={{ fontWeight: 600 }}>
          Crear Plantilla
        </Text>
        <Divider mb={1} />

        <form action="">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            {authUser.admin === 1 || authUser.is_commerce === 1
              ? (
                <div style={{ width: "45%" }}>
                  <InputSelect
                    tag={"Usuario calificado"}
                    name="qualifiedUser_id"
                    data={selUSerQ}
                    set={setUserUSerQ}
                    value={userUSerQ}
                  />
                </div>
              )
              : (
                ""
              )}
            <div style={{ width: "45%" }}>
              <LabeFormlStyled htmlFor="code_productive_process">
                Código
              </LabeFormlStyled>
              <InputFormStyled
                name="code_productive_process"
                id="code_productive_process"
                type="text"
                value={form?.code_productive_process}
                onChange={(e) =>
                  handleRegex(e, "all", Regex?.all)
                }
                maxLength={
                  MaxLength.productReference.productiveProcess
                    .code_productive_process
                }
              />
            </div>

            {authUser.admin === 1 || authUser.is_commerce === 1
              ? (
                ""
              )
              : (
                <div>
                  <LabelGroup
                    name={"description_productive_process"}
                    onChange={handleInputChange}
                    value={form.description_productive_process}
                    tag={"Descripción"}
                    desicion={false}
                    maxLength={
                      MaxLength.productReference.productiveProcess
                        .description_productive_process
                    }
                  />
                </div>
              )}
          </div>

          {authUser.admin === 1 || authUser.is_commerce === 1
            ? (
              <div>
                <LabelGroup
                  name={"description_productive_process"}
                  onChange={handleInputChange}
                  value={form.description_productive_process}
                  tag={"Descripción"}
                  desicion={false}
                />
              </div>
            )
            : (
              ""
            )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap={"wrap"}
          >
            <ButtonStyled type="button" bgColor="#bababa" onClick={onClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled onClick={handleSubmit}>Guardar</ButtonStyled>
          </Box>
        </form>
      </ModalTransition>
    </>
  );
};

export default CreateTemplate;
