import { useModal } from "hooks/useModal";

const { createContext, useState } = require("react");

const ReturnReasonContext = createContext();

export const ReturnReasonnProvider = ({ children }) => {
  const [isOpenListReason, onOpenListReason, onCloseListReason] = useModal();
  const [isOpenShowComentary, onOpenShowComentary, onCloseShowComentary] =
    useModal();

  const [ListReturnReason, setListReturnReason] = useState(null);

  const data = {
    isOpenListReason,
    onOpenListReason,
    onCloseListReason,
    ListReturnReason,
    isOpenShowComentary,
    onOpenShowComentary,
    onCloseShowComentary,
    setListReturnReason
  };

  return (
    <ReturnReasonContext.Provider value={data}>
      {children}
    </ReturnReasonContext.Provider>
  );
};

export default ReturnReasonContext;
