import React, { useContext } from "react";
import { LogoutIcon, LogoutTag } from "./LogoutIcon";
import { MdLogout } from "react-icons/md";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "./FuncionLogout";
import { AUTH_TYPES } from "redux/types/authTypes";
import { logoutAction } from "redux/actions/authentication";

const channel = new BroadcastChannel("logout-channel");

const Logout = () => {
  const navigate = useNavigate();
  const dispacthRedux = useDispatch();
  const { dispatch } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);

  channel.onmessage = (message) => {
    if (message?.data?.type !== AUTH_TYPES.LOGOUT) return;
    localStorage.setItem("chanelWeighing", "");
    localStorage.removeItem("route");
    localStorage.removeItem("search");
    dispacthRedux(logoutAction());
    window.Echo.leave(`notification-${idFuncionaio}`); // desasociar al canal
    navigate("/auth", {
      replace: true
    });
  };

  return (
    <div
      onClick={() =>
        handleLogout(token, dispatch, dispacthRedux, navigate, idFuncionaio, channel)
      }
    >
      <LogoutIcon as={MdLogout} />
      <LogoutTag>Cerrar sesión</LogoutTag>
    </div>
  );
};

export default Logout;
