import { Spinner, Td, Tr } from "@chakra-ui/react";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { trackingStatus } from "../Templates";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";

export const TbodyDispacthShow = ({ data }) => {
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const token = useSelector((state) => state.auth.dataUser.token);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles the reference of the data and navigates to the corresponding page.
   * @async
   * @function handleReference
   * @returns {Promise<void>}
   */
  const handleReference = async () => {
    const body = data?.reference?.startsWith("C") ? { cintegration: data?.reference } : { formentry: data?.reference }
    setIsLoading(true)
    try {
      const res = await __Post(environment.SHOW_REFERENCE, token, body);
      data?.reference?.startsWith("C") ? window.open(`/ShowIntegrationBox?id=${res?.data?.status?.data?.integration_certificate_id}`, '_blank') : window.open(`/FormShow?FormEntry=${res?.data?.status?.data?.id}`, '_blank')
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Tr
      id={
        data?.status_outputForm === "APROBADO"
          ? "warningInsp"
          : data?.status_outputForm === "AUTORIZADO"
            ? ""
            : ""
      }
    >
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">
        <NavLink
          to={`/FormShowExit?ExitNumber=${data?.outputForm_id}`}
          className={"text-center"}
        >
          <span className="formNumber">
            {data?.code_outputForm ? data?.code_outputForm : "N/A"}
          </span>
        </NavLink>
      </Td>
      <Td className="text-center">
        {data?.code_operation ? data?.code_operation : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.item_output ? data?.item_output : "N/A"}
      </Td>
      <Td className="text-center" onClick={handleReference}>
        {isLoading
          ? <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xs"
          />
          : <span style={{ color: '#3f8dce', cursor: 'pointer' }}>
            {data?.reference ? data?.reference : "N/A"}
          </span>}
      </Td>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>

      <Td className="text-center">
        {data?.description_product ? data?.description_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_go_out ? data?.quantity_go_out : '0'}
      </Td>
      <Td className="text-center">
        {data?.quantity_dispatch ? data?.quantity_dispatch : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.output_form_item_description ?? "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit ? data?.code_businessUnit : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_go_out_product ? data?.quantity_go_out_product : "0"}
      </Td>
      <Td className="text-center">
        {data?.quantity_dispatch_product
          ? data?.quantity_dispatch_product
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_unit_measurement ? data?.code_unit_measurement : "N/A"}
      </Td>
      {is_qualified_user === 0 && (
        <Td className="text-center">
          {trackingStatus[data?.tracking] || trackingStatus.default}
        </Td>
      )}
    </Tr>
  );
};
