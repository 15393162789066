import axios from "axios";
import environment from "constants/apiConst";

export const __ObtenerEstadisticas = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${environment.OBTENER_STATISTICS}`, options);
  return res;
};
