/**
 * OnlyNumberInput - Funcion que utilizamos en la propiedad onChange del input
 * para que solo permita digitar numero entero y no cadena de texto.
 * @param {Function} setValues lo utilizamos para actualizar el estado.
 * @param {Object} values Objeto del donde guardaremos los datos solo tipo numero.
 * @return  Retorna entero.
 */

export const OnlyNumberInput = (e, setValues, values) => {
  const posibleResult = e.target.value
    .replace(/[^0-9,]/g, "")
    .replace(/,/g, ",");
  const nameVariable = e.target.name;
  return setValues({ ...values, [nameVariable]: posibleResult });
};
