import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { useContext, useState } from 'react'
import { AiFillMinusCircle, AiOutlineComment } from 'react-icons/ai'
import { BsCheckCircleFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { __Activate_Or_Inactivate } from 'request/Monitoring/__Activate_Or_Inactivate'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import TBodyTrackComments from './TBodyTrackComments'
import { useModal } from 'hooks/useModal'
import { HiDotsHorizontal } from 'react-icons/hi'
import styled from 'styled-components'
import { MdRemoveRedEye } from 'react-icons/md'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { FaHistory } from 'react-icons/fa'
import { IoAnalyticsOutline } from 'react-icons/io5'
import MonitoringContext from 'context/MonitoringContext'
import TableOrder from 'components/Tables/TableOrder'
import {
  TheadShowItems,
  TheadShowUC,
  TheadSubheading,
  TheadTableLog,
  TheadTabletraceability,
  TheadTrackingOperations
} from 'pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader'
import TBodyShowItems from './TBodyShowItems'
import TBodyUC from './TBodyUC'
import TBodySubheadingTrackings from './TBodySubheadingTrackings'
import TbodyOperationsMonitoring from './TBodyOperationsMonitoring'
import { __GetTrackingLog } from 'request/Monitoring/__Get_Monitoring'
import TBodyTrackingLog from './TBodyTrackingLog'
import { __PostForm } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import TBodyTraceability from './TBodyTraceability'
import isArrayNotEmpty, { isArray } from 'utils/type-check-utils'
import { isValid } from 'utils/parseFloat'
import { uploadFile } from 'pages/Entry_and_exit_forms/View/FormsView'
import { useQueryClient } from '@tanstack/react-query'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

const TbodyReference = ({ data, getMonitoring }) => {
  const [finalDescription, setFinalDescription] = useState('')
  const [attachmentCancel, setAttachmentCancel] = useState([])
  const queryClient = useQueryClient();
  const [dataTraceabilityCommentary, setDataTraceabilityCommentary] = useState([
    {
      user: data?.comments?.initial_user_id || '',
      comment: data?.comments?.initial_comment || ''
    },
    {
      user: data?.comments?.final_user_id || '',
      comment: data?.comments?.final_comment || ''
    }
  ])
  const [load, setIsLoad] = useState([])
  const [loadOperations, setLoadOperations] = useState(false)
  const [loading, setLoading] = useState([])
  const [logInfo, setLogInfo] = useState([])
  const [formType, setFormType] = useState('')
  const [dataTraceability, setDataTraceability] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const [isOpenCommentary, onOpenCommentary, onCloseCommentary] = useModal()
  const [isOpenLog, onOpenLog, onCloseLog] = useModal()
  const [isOpenHistory, onOpenHistory, onCloseHistory] = useModal()
  const { isOpenModalInfo, onOpenModalInfo, onCloseModalInfo } =
    useContext(MonitoringContext)
  const trackingId = data.tracking_id

  const sendDataTableOperations = () => {
    setFormType(data.form_type_id)
    if (isArray(data?.data) && isArrayNotEmpty(data?.data)) onOpenModalInfo()

    /*
    setAttachmentShow(data?.attachment[0]?.attachment_url) */
  }


  const handleAttachment = async (e) => {
    const dataAttachments = e.target.files[0];
    const vaporRes = await uploadFile(dataAttachments, token);

    setAttachmentCancel({
      file: { ...vaporRes },
      name: dataAttachments?.name,
      attachment_description: "",
      attachment_comment: "",
      attachment_date: "",
    });
  };

  const stopTracking = async () => {
    if (!finalDescription) {
      openAlert(
        'Para desactivar el seguimiento se requiere un comentario',
        'error'
      )
    } else {
      try {
        const formData = new FormData()
        formData.append('tracking_id', trackingId)
        if (!isValid(attachmentCancel)) {
          return openAlert("Para desactivar el seguimiento del formulario se requiere un archivo", "error");
        }
        formData.append('attachment', attachmentCancel)
        formData.append('final_description', finalDescription)
        const res = await __Activate_Or_Inactivate(token, formData)
        const message = res?.data?.status?.message
        const code = res?.data?.status?.code
        if (code === 200) {
          onClose()
          openAlert(message, 'success')
          queryClient.invalidateQueries({ queryKey: ["fetchServerPage"]});
        } else if (code !== 200) {
          openAlert(message, 'error')
        }
      } catch {
        console.log('error')
      } finally {
        getMonitoring()
      }
    }
  }

  const cleanTextArea = () => {
    if (data?.status_id === 1) {
      setFinalDescription('')
      onOpen()
      console.log('click')
    }
  }

  const traceabilitySanitization = data => {
    const traceability = []
    const res = Object.entries(data) || []

    res?.forEach(([key, value]) => {
      if (value !== null && value.length > 0) {
        value !== null &&
          value.forEach(item => {
            traceability.push({
              ...item,
              type: key
            })
          })
      }
    })

    return traceability
  }

  const handleTreazability = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('id', data?.tracking_id)
    try {
      const res = await __PostForm(
        environment.GET_TRACKING_TRACEABILITY,
        token,
        formData
      )
      const response = res?.data?.status
      const data = res?.data?.status?.data

      if (response?.code === 200) {
        return setDataTraceability(traceabilitySanitization(data))
      }
      openAlert(response?.message, 'error')
    } catch (error) {
      console.error(error)
      openAlert('Error al obtener la trazabilidad', 'error')
    } finally {
      onOpenHistory()
      setLoading(false)
    }
  }

  const formatLog = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('tracking_id', data?.tracking_id)
    try {
      const res = await __GetTrackingLog(token, formData)
      const infoLog = res?.data?.status?.data
      if (infoLog) {
        setLogInfo(infoLog)
      }
    } catch {
      console.log('error')
    } finally {
      onOpenLog()
      setLoading(false)
    }
  }

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title='Parar seguimiento'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            justifyContent: 'space-between',
            margin: '0 auto 2rem auto',
            padding: '10px 0'
          }}
        >
          <input
            type='file'
            name='attachment'
            accept='.png, .jpeg, .jpg, .pdf'
            style={{
              marginBottom: '15px'
            }}
            onChange={e => handleAttachment(e)}
          />
          <textarea
            onChange={e => setFinalDescription(e.target.value)}
            value={finalDescription}
            name='finalDescription'
            cols='50'
            rows='3'
            placeholder='Comentario requerido'
            style={{
              fontSize: '0.8rem',
              resize: 'none',
              border: '1px solid #bababa',
              borderRadius: '5px',
              outline: 'none',
              padding: '0 10px'
            }}
          ></textarea>
          <Button onClick={stopTracking} style={{ margin: '15px 0' }}>
            Enviar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenLog}
        onOpen={onOpenLog}
        onClose={onCloseLog}
        title='Log'
      >
        <TableOrder
          thead={TheadTableLog}
          data={logInfo}
          setData={setLogInfo}
          loading={loading}
          tbodyData={TBodyTrackingLog}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenHistory}
        onOpen={onOpenHistory}
        onClose={onCloseHistory}
        title='Trazabilidad'
      >
        <TableOrder
          thead={TheadTabletraceability}
          data={dataTraceability}
          setData={setDataTraceability}
          loading={loading}
          tbodyData={TBodyTraceability}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCommentary}
        onOpen={onOpenCommentary}
        onClose={onCloseCommentary}
        title='Comentarios'
      >
        <TBodyTrackComments data={dataTraceabilityCommentary} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenModalInfo}
        onOpen={onOpenModalInfo}
        onClose={onCloseModalInfo}
        title={
          formType === 1 || formType === 2 || formType === 3
            ? 'Items en seguimiento'
            : formType === 4
              ? 'Usuarios calificados'
              : formType === 6
                ? 'Subpartidas'
                : 'Operaciones en seguimiento'
        }
      >
        <TableOrder
          thead={
            formType === 1 || formType === 2 || formType === 3
              ? TheadShowItems
              : formType === 4
                ? TheadShowUC
                : formType === 6
                  ? TheadSubheading
                  : TheadTrackingOperations
          }
          data={data?.data}
          loading={load}
          tbodyData={
            formType === 1 || formType === 2 || formType === 3
              ? TBodyShowItems
              : formType === 4
                ? TBodyUC
                : formType === 6
                  ? TBodySubheadingTrackings
                  : TbodyOperationsMonitoring
          }
          getMonitoring={getMonitoring}
          formType={formType}
          setLoadOperations={setLoadOperations}
        />
      </ModalGeneric>

      <Tr>
        <td className='text-center'>{data?.tracking_code}</td>
        <td className='text-center'>
          <NavLink
            to={
              data?.form_type_id === 1
                ? `/FormShowExit?ExitNumber=${data?.form_id}`
                : `/FormShow?FormEntry=${data?.form_id}`
            }
            className='ms-2'
          >
            <span className='formNumber'>{data?.form_code}</span>
          </NavLink>
        </td>
        <td className='text-center'>{data?.form_type || 'N/A'}</td>
        <td className='text-center'>{data?.tracking_parent || 'N/A'}</td>
        <td className='text-center'>{data?.name_qualified || 'N/A'}</td>
        <td className='text-center'>{data?.nickname || 'N/A'}</td>
        <td className='text-center'>{data?.start_date || 'N/A'}</td>
        <td className='text-center'>{data?.end_date || 'N/A'}</td>
        <td className='text-center'>{data?.final_user_nickname || 'N/A'}</td>
        <td className='text-center'>{data?.name_operation || 'N/A'}</td>
        <td className='text-center'>{data?.service || 'N/A'}</td>
        <td className='text-center'>{data?.follow_order || 'N/A'}</td>
        <Tooltip placement='top' hasArrow label={'Comentarios'}>
          <Td onClick={onOpenCommentary} className='text-center'>
            <IconGeneric color='#03C39A' as={AiOutlineComment} />
          </Td>
        </Tooltip>
        <Tooltip
          placement='top'
          hasArrow
          label={data?.status_id === 1 ? 'Activo' : 'Detenido'}
        >
          <Td onClick={cleanTextArea} className='text-center'>
            {data?.status_id === 1
              ? (
                <IconGeneric color='#03C39A' as={BsCheckCircleFill} />
              )
              : (
                <IconGeneric
                  color='F96464'
                  width='24px'
                  height='24px'
                  as={AiFillMinusCircle}
                />
              )}
          </Td>
        </Tooltip>
        <Td className='text-center'>
          <Menu>
            <MenuButton>
              <IconGeneric as={HiDotsHorizontal} color='#00000085' />
            </MenuButton>
            <MenuList
              borderRadius='none'
              py={0}
              style={{ boxSizing: 'border-bMenuListox' }}
            >
              <MenuItemStyled py={3} onClick={sendDataTableOperations}>
                <IconMenuRUD as={MdRemoveRedEye} />
                Ver historial
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={formatLog}>
                <IconMenuRUD as={FaHistory} />
                Ver log
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={handleTreazability}>
                <IconMenuRUD as={IoAnalyticsOutline} />
                Trazabilidad
              </MenuItemStyled>
            </MenuList>
          </Menu>
        </Td>
      </Tr>
    </>
  )
}

export default TbodyReference
