import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const TableSearch = ({ data }) => {
  const date = new Date(data?.date).toLocaleDateString();
  return (
    <Tr>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>

      <Td className="text-center">{data?.dependency}</Td>
      <Td className="text-center">{data?.lock_type}</Td>

      <Td className="text-center">
        {data?.action === 1 ? "Bloqueado" : "Desbloqueado"}
      </Td>
      <Td className="text-center">{date}</Td>
      <Td className="text-center">{data?.nickname}</Td>
      <Td className="text-center">{data?.observations}</Td>
    </Tr>
  );
};

export default TableSearch;
