/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";
import ButtonsReviewing from "./ButtonsReviewing";
import { useForm } from "hooks/useForm";
import { AuthContext } from "context/AuthContext";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import queryString from "query-string";
import { OptionsForm } from "../components/OptionsForm";
import { isEmptyObject, isObjectNotEmpty } from "utils/type-check-utils";
import { Storage } from "hooks/useStorage";
import { useSelector } from "react-redux";
import ModalQR from "components/modal/ModalQR";

const ButtonChek = ({ data, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { get, clean } = Storage;
  const location = useLocation();
  const { Number = null, ShowEntry = null } = queryString.parse(
    location.search
  );
  const { token } = useSelector((state) => state.auth.dataUser);

  const {
    changeStatus,
    onOpenReturnReason,
    setReturnReason,
    setRequestChangeStatusForm
  } = useContext(FormCrudCRUDContext);
  const [inspectiones, setinspectiones] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const [values, handleInputChange, reset, setValues] = useForm({
    free_pass: "",
    inspection: ""
  });

  const { free_pass } = values;

  useEffect(() => {
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS);
  }, []);

  const unlockForm = async () => {
    const body = new FormData();
    body.append("form_id", Number || ShowEntry);
    body.append("type_of", Number ? "FMMI" : "FMMIC");
    body.append("type", 0);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
  };

  const handlePresent = (status) => {
    if (data?.id) clean(data?.id);
    const newReviews = data?.reviews_new || {};

    const info = {
      id: data?.id,
      status_entryForm: status,
      free_pass: free_pass ? 1 : 0,
      inspection: inspectiones ? 2 : 0
    };

    if (status === "DEVUELTO" && isEmptyObject(newReviews)) {
      onOpenReturnReason();
      return setReturnReason(info);
    }

    if (status === "DEVUELTO" && isObjectNotEmpty(newReviews)) {
      return changeStatus(info, data?.id);
    }

    if (status !== "DEVUELTO" && isEmptyObject(newReviews)) {
      return changeStatus(info);
    }

    if (status === "APROBADO" && isObjectNotEmpty(newReviews)) {
      return openAlert("Los campos se encuentran con observaciones", "error");
    }

    unlockForm();
    return changeStatus(info);
  };

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />
      <div className="row">
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <ButtonsReviewing handlePresent={handlePresent} data={data} />
          </Box>
        </div>
        {children}
        <OptionsForm data={data} />
      </div>
    </>
  );
};

export default ButtonChek;
