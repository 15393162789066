import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __ShowTypeTransp } from "../../../request/configuration/__TypeTranport";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyTypeTransp = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();
  const [loadingIcons, setLoadingIcons] = useState(false);

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.TYPETRANSP_DESACTIVE, data.id)
          : await __ACTIVAR(token, environment.TYPETRANSP_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __ShowTypeTransp(token, data.id);
      const result = res.data.status.data.status_typeTransport;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.name_typeTransport}</Td>
      <Td className="text-center">{data?.description_typeTransport}</Td>

      <Tooltip
        placement="top"
        hasArrow
        label={data.status_typeTransport === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data?.status_typeTransport}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_typeTransport}
          />
        </TdState>
      </Tooltip>

      <TdState
        className="text-center"
        isNumeric
        text={data?.is_container}
      >
        <ConfigIconStatus
          loading={loadingIcons}
          status={data?.is_container}
        />
      </TdState>

      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTypeTransp;
