const thead = [
  { name: "Tipo", order: "null" },
  { name: "Razón social", order: "null" },
  { name: "Tipo. Ident", order: "null" },
  { name: "Num. Ident", order: "null" },
  { name: "Código interno", order: "null" },
  { name: "Siglas", order: "null" },
  { name: "Alias", order: "null" },
  { name: "Usuario creación", order: "null" },
  { name: "Fecha creación", order: "null" },
  { name: "Usuario ult. mod. ", order: "null" },
  { name: "Fecha ult. mod. ", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: "null" }
];

const theadSuperADMIN = [
  { name: "usuario calificado", order: "null" },
  { name: "Tipo", order: "null" },
  { name: "Razón social", order: "null" },
  { name: "Tipo. Ident", order: "null" },
  { name: "Num. Ident", order: "null" },
  { name: "Código interno", order: "null" },
  { name: "Siglas", order: "null" },
  { name: "Alias", order: "null" },
  { name: "Usuario creación", order: "null" },
  { name: "Fecha creación", order: "null" },
  { name: "Usuario ult. mod. ", order: "null" },
  { name: "Fecha ult. mod. ", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: "null" }
];

export { thead, theadSuperADMIN };
