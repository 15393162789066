import { Flex, Spacer } from "@chakra-ui/react";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import environment from "constants/apiConst";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { __PostForm } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { Title } from "styled/Title";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

export const FormEditTara = ({ data, onClose, updateComponent }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const [openAlert] = AlertErrorAlert();

  const { Operation = "" } = queryString.parse(location.search);

  const [values, handleInputChange, reset, setValues] = useForm({
    tare_container_1: "",
    tare_tare_container_1: "",
    tare_capacity_container_1: "",
    tare_container_2: "",
    tare_tare_container_2: "",
    gross_capacity_container_2: ""
  });

  const {
    tare_container_1,
    tare_tare_container_1,
    tare_capacity_container_1,
    tare_container_2,
    tare_tare_container_2,
    gross_capacity_container_2
  } = values;

  useEffect(() => {
    if (data) {
      (() => {
        setValues(data);
      })();
    }
  }, [data]);

  const sendDataToEdit = async () => {
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("pass", Operation === "Entrada" ? 1 : 2);
    formData.append("container_tare", 1);
    formData.append("tare_tare_container_1", tare_tare_container_1);
    if (tare_tare_container_2 !== null) {
      formData.append("tare_tare_container_2", tare_tare_container_2);
    }

    try {
      const res = await __PostForm(
        environment.WEIGHING_SHOW_EDIT,
        token,
        formData
      );
      const info = res?.data?.status;
      if (info.code === 200) {
        openAlert("Actualizado con exito", "success");
        updateComponent((prev) => !prev);
        onClose();
      } else {
        openAlert(info.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNoCharacter = (e) => {
    const valor = e.target.value;

    if (valor && !valor.toString().includes(".")) {
      FormatoCantidadMexico(e, setValues, values);
    } else if (valor === "") {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  return (
    <>
      <div className="pb-4">
        <Title>Contenedor N.º 1</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <LabelGroup
            name={"tare_container_1"}
            value={tare_container_1}
            onChange={handleInputChange}
            tag={"Serie"}
            size={30}
            desicion={true}
          />
          <LabelGroup
            name={"tare_capacity_container_1"}
            value={tare_capacity_container_1}
            onChange={handleInputChange}
            tag={"De"}
            size={30}
            desicion={true}
          />
          <LabelGroup
            name={"tare_tare_container_1"}
            value={tare_tare_container_1}
            onChange={handleNoCharacter}
            tag={"Tara"}
            size={30}
          />
        </div>
      </div>
      {tare_tare_container_2 !== null
        ? (<div className="pb-4">
          <Title>Contenedor N.º 2</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <LabelGroup
              name={"tare_container_2"}
              value={tare_container_2}
              onChange={handleInputChange}
              tag={"Serie"}
              size={30}
              desicion={true}
            />
            <LabelGroup
              name={"gross_capacity_container_2"}
              value={gross_capacity_container_2}
              onChange={handleInputChange}
              tag={"De"}
              size={30}
              desicion={true}
            />
            <LabelGroup
              name={"tare_tare_container_2"}
              value={tare_tare_container_2}
              onChange={handleNoCharacter}
              tag={"Tara"}
              size={30}
            />
          </div>
        </div>)
        : ("")}
      <Flex>
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
                    Cancelar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled onClick={sendDataToEdit} bgColor={"#3182CE"}>
                    Editar
        </ButtonStyled>
      </Flex>
    </>
  );
};
