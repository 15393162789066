/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useModal } from "./useModal";
import { useQuery } from "@tanstack/react-query";
import { requestData } from "request/Petitions/requestData";
import environment from "constants/apiConst";
/* : async () => {
        const res = await __AllQualifiedUSers(token);
        return res?.data?.status?.data;
      } */
export const useGetUserQualified = () => {
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [selectUserQualified, setSelectUserQualified] = useState(null);
  const { data: userQualified, isLoading } = useQuery({
    queryKey: ["getUserQualified", freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
      PropertyBody: "get",
      token
    }),
    // Opciones de cacheo
    gcTime: 1000 * 60 * 5, // 5 minutos
    staleTime: 1000 * 60 * 2, // 2 minutos
    refetchOnMount: false, // No volver a buscar los datos al montarse el componente
    refetchOnWindowFocus: false
  });

  const data = userQualified?.data?.status?.data &&
    userQualified?.data?.status?.data?.map((items) => ({
      value: items?.id,
      label: items?.name_qualified,
      decimal: items?.decimal_values_precision
    }));

  return { userQualified: data, isLoading, selectUserQualified, setSelectUserQualified };
};

/**
 *  useUserQualified - Hooks para obtener los tipos de usuarios calificados
 *
 * @function
 * @return {Object} Retorna objeto que dentro tiene un array con los tipos de usuarios calificados.
 */

const useUserQualified = () => {
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [userQualified, setUserQualified] = useState([]);
  const [isLoading, onLoading, noLoading] = useModal();
  const [selectUserQualified, setSelectUserQualified] = useState(null);

  useEffect(() => {
    getUserQualified();
  }, [freeZone_id]);

  const getUserQualified = async () => {
    try {
      onLoading();
      const res = await __AllQualifiedUSers(token);
      const user = res?.data?.status?.data;
      const data = user.map((items) => ({
        value: items?.id,
        label: items?.name_qualified,
        decimal: items?.decimal_values_precision
      }));
      noLoading();
      setUserQualified(data);
    } catch (error) {
      console.log(error);
    }
  };

  return { userQualified, isLoading, selectUserQualified, setSelectUserQualified };
};

export default useUserQualified;
