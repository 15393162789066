import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { QueryBuilder } from '@cubejs-client/react'
import ChartRenderer from '../ChartRenderer'
import MemberGroup from './MemberGroup'
import FilterGroup from './FilterGroup'
import TimeGroup from './TimeGroup'
import { SelectChartTypeSimple } from './SelectChartType'
import OrderGroup from './Order/OrderGroup'
import Pivot from './Pivot/Pivot'
import ColumnsChart from '../../pages/components/CardCharts'
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react'
import { AiOutlineBorderInner, AiOutlineSortAscending } from 'react-icons/ai'

export default function ExploreQueryBuilder ({
  vizState,
  cubejsApi,
  chartExtra,
  onVizStateChanged
}) {
  return (
    <QueryBuilder
      initialVizState={vizState}
      cubejsApi={cubejsApi}
      wrapWithQueryRenderer={false}
      onVizStateChanged={onVizStateChanged}
      render={({
        validatedQuery,
        isQueryPresent,
        chartType,
        updateChartType,
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        orderMembers,
        updateOrder,
        pivotConfig,
        updatePivotConfig
      }) => {
        return (
          <>
            <Flex align='top' justify='space-around' spacing={6}>
              <Box width='100%'>
                <Box p={6} rounded='lg' boxShadow='md' bg='white'>
                  <Flex align='top' justify='space-around' spacing={6} mb={6}>
                    <Box width='100%' display={'flex'} flexWrap={'wrap'}>
                      <MemberGroup
                        members={measures}
                        availableMembers={availableMeasures}
                        addMemberName='Medida'
                        updateMethods={updateMeasures}
                      />
                      <Center>
                        <Text
                          mr='5px'
                          ml={'5px'}
                          h={5}
                          borderLeft={'1px solid #d4d4d4'}
                        />
                      </Center>
                      <MemberGroup
                        members={dimensions}
                        availableMembers={availableDimensions}
                        addMemberName='Dimensión'
                        updateMethods={updateDimensions}
                      />
                      <Center>
                        <Text
                          mr='5px'
                          ml={'5px'}
                          h={5}
                          borderLeft={'1px solid #d4d4d4'}
                        />
                      </Center>

                      <TimeGroup
                        members={timeDimensions}
                        availableMembers={availableTimeDimensions}
                        addMemberName='Hora'
                        updateMethods={updateTimeDimensions}
                      />
                    </Box>
                  </Flex>

                  {/*   <Flex align='top' justify='space-around' spacing={6} mb={6}>
                    <Box width='100%'>
                      <FilterGroup
                        members={filters}
                        availableMembers={availableDimensions.concat(
                          availableMeasures
                        )}
                        addMemberName='Filtro'
                        updateMethods={updateFilters}
                      />
                    </Box>
                  </Flex> */}

                  <Flex align='top' justify='space-around' spacing={6}>
                    <Box width='100%' display={'flex'} flexWrap={'wrap'}>
                      <SelectChartTypeSimple
                        chartType={chartType}
                        updateChartType={updateChartType}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>

            <Grid templateColumns='repeat(12, 1fr)' gap={6}>
              <Box gridColumn={['span 12']} p='5'>
                {isQueryPresent
                  ? (
                    <>
                      {' '}
                      <ColumnsChart
                        Button={chartExtra}
                        component={
                          <ChartRenderer
                            vizState={{
                              query: validatedQuery,
                              chartType,
                              pivotConfig
                            }}
                            cubejsApi={cubejsApi}
                          />
                        }
                      />
                    </>
                  )
                  : (
                    <Heading
                      as='h2'
                      textAlign={'center'}
                      size='1xl'
                      noOfLines={1}
                    >
                    Elija una medida o dimensión para comenzar
                    </Heading>
                  )}
              </Box>
            </Grid>
          </>
        )
      }}
    />
  )
}
ExploreQueryBuilder.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
  chartExtra: PropTypes.array
}
ExploreQueryBuilder.defaultProps = {
  vizState: {},
  cubejsApi: null,
  chartExtra: null
}
