/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useForm } from "hooks/useForm";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled } from "styled/Input.styled";
import { Title } from "styled/Title";

const SectionHeader = ({ secTitle, children, ShowSearch = false }) => {
  const [form, handleInputChange, resetForm, setForm] = useForm({ search: "" });

  useEffect(() => {
    setForm({ search: "" });
  }, []);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`?search=${form.search}`);
  };

  return (
    <div className="row w-100 mx-auto ">
      <div className="col-lg-6 col-md-6 mb-sm-3 ">
        <div className="row">
          <Title style={{ marginTop: 20 }}>{secTitle}</Title>
          {children && <div className="col-md-4">{children}</div>}
        </div>
      </div>

      {ShowSearch && (
        <div className="col-lg-6 col-md-5 justify-content-end pb-5">
          <form onSubmit={handleSearch}>
            <div className="row align-items-end justify-content-end me-md-5 mx-lg-0">
              <div className="col-md-5 col-12">
                <InputFormStyled
                  placeholder="Búsqueda"
                  width="100%"
                  mb="0rem"
                  name="search"
                  value={form.search}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-5 col-12">
                <ButtonStyled
                  type="button"
                  onClick={handleSearch}
                  style={{ marginLeft: ".5rem" }}
                >
                  Búsqueda
                </ButtonStyled>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
