import axios from "axios";
import env from "../../constants/apiConst";

export const __SubheadingControl = async (token, posicion, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.SUBHEADIN_C_GET}?page=${posicion}`, options);
  return res;
};

export const __ShowSubheadingC = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SUBHEADIN_C_SHOW}/${id}`, options);
  return res;
};
