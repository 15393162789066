import { useDebounceError } from 'hooks/useDebounceError'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import React, { useEffect } from 'react'

const FormInfo = ({
  template,
  values,
  Actions,
  errors = null,
  mode = 'corrected',
  isEdit = false,
  children,
  ...props
}) => {
  // data-name-regex

  return (
    <>
      {template.map(
        ({
          label,
          Property,
          PropertyCorrected,
          readOnly = false,
          RegExpType = null,
          maxLength = null
        }) => {
          const getPropertyName = mode => {
            return mode === 'corrected' ? PropertyCorrected : Property
          }

          const propertyName = getPropertyName(mode)
          const value = values?.[propertyName] || ''
          const isCorrectedMode = mode === 'corrected'
          const isPropertyDifferent =
            values?.[Property] !== values?.[PropertyCorrected]
          const borderClassName =
            isCorrectedMode && isPropertyDifferent ? 'BorderCorrect' : ''

          return (
            <>
              <LabelGroup
                changeValue={borderClassName}
                key={label}
                placeholder={ !values?.[propertyName] ? 'Valor no disponible' : ''}
                name={propertyName}
                value={value}
                tag={label}
                size={100}
                data-Type-regex={RegExpType}
                desicion={!isEdit || readOnly}
                maxLength={maxLength}
                {...props}
              />

              {errors?.[propertyName] && (
                <div className='alert alert-danger p-1'>
                  {errors?.[propertyName]}
                </div>
              )}
            </>
          )
        }
      )}

      {children}
    </>
  )
}

export default FormInfo
