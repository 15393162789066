import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useGlobalContext } from 'context/GlobalContext'
import React from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import styled from 'styled-components'
import { RiEdit2Fill } from 'react-icons/ri'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { MdDelete } from 'react-icons/md'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import environment from 'constants/apiConst'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

export const MenuOtherCosts = ({ data }) => {
  const { requestData } = useGlobalContext()
  const { onOpenAddOtherCosts, setDataToEditOP } = useGlobalContext()
  const queryClient = useQueryClient()
  const [openAlert] = AlertErrorAlert()
  const { isLoading, mutate } = useMutation({
    mutationFn: requestData
  })
  const handleEdit = e => {
    setDataToEditOP(data)
    onOpenAddOtherCosts()
  }

  const handleResponseDelete = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ['getOtherCostItemOP'] })
    }
  }

  const handleDelete = e => {
    const config = {
      resourceIdentifier: `${data?.id}?=${data?.production_order_id}`,
      Endpoint: environment.DELETE_OTHERS_COST_OP
    }

    mutate(config, {
      onSuccess: data => handleResponseDelete(data)
    })
  }

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>
      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editars
        </MenuItemStyled>
        <HrDividerNoMargin />
        <MenuItemStyled py={3} onClick={handleDelete} isLoading={isLoading}>
          <IconMenuRUD as={MdDelete} color='#d92525' />
          Eliminar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}
