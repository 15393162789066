import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { useContext } from "react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HiDotsHorizontal, HiOutlineXCircle } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import { RxCircleBackslash } from "react-icons/rx";

import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FaWeight } from "react-icons/fa";
import WeighingContext from "context/WeighingContext";
import { ChangeStatusWeight } from "../ReportWeighing/Tbody/Menu/ChangeStatusWeight";
import { AuthContext } from "context/AuthContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const TbodyMenuWeigh = ({ data }) => {
  const { onOpenReweigh, setIdReweigh, setCurrentDataReweigh } =
    useContext(WeighingContext);

  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext)

  const Status = data && data?.operation === "Despacho" ? 2 : 1;

  return (
    <>
      <Menu>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color="#00000085" />
        </MenuButton>
        {/*   <CustomizableButton
                bgColor="#A6A6A6"
                LabelText="Inhabilitar"
                icon={RxCircleBackslash}
                onClick={() => {
                  ChangeStatusWeight(
                    {
                      pass: Operation === "Entrada" ? 1 : 2,
                      status: "INHABILITADO",
                      weight_id: form?.id,
                    },
                    token,
                    openAlert,
                    ReloadEdit,
                    setReloadEdit
                  );
                }}
              /> */}
        <MenuList
          borderRadius="none"
          py={0}
          style={{ boxSizing: "border-box" }}
        >
          <MenuItemStyled
            py={3}
            onClick={() => {
              const WeighData = {
                weight_id: data?.id,
                pass: Status
              };
              setIdReweigh(WeighData);
              setCurrentDataReweigh(data);
              onOpenReweigh();
            }}
          >
            <IconMenuRUD color="#0856dd" as={FaWeight} />
            Repesar
          </MenuItemStyled>{" "}
          {authUser.is_qualified_user !== 1 && (
            <><HrDividerNoMargin />
              <MenuItemStyled
                py={3}
                onClick={() => {
                  ChangeStatusWeight(
                    {
                      pass: Status,
                      status: "INHABILITADO",
                      weight_id: data?.id
                    },
                    token,
                    openAlert
                  );
                }}
              >
                <IconMenuRUD color="#A6A6A6" as={RxCircleBackslash} />
                Inhabilitar
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled
                py={3}
                onClick={() => {
                  ChangeStatusWeight(
                    {
                      pass: Status,
                      status: "CANCELADO",
                      weight_id: data?.id
                    },
                    token,
                    openAlert
                  );
                }}
              >
                <IconMenuRUD color="#F94A25" as={HiOutlineXCircle} />
                Cancelar
              </MenuItemStyled></>
          )}
        </MenuList>

        {/*  <HrDividerNoMargin /> */}
      </Menu>
    </>
  );
};

export default TbodyMenuWeigh;
