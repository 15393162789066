import {
  Button,
  Menu,
  MenuButton,
  Td,
  Tooltip,
  Tr,
  MenuItem,
  MenuList
  , useDisclosure
} from "@chakra-ui/react";
import { MdRemoveRedEye } from "react-icons/md";

import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useSelector } from "react-redux";
import { __Activate_Or_Inactivate } from "request/Monitoring/__Activate_Or_Inactivate";
import { useContext, useEffect, useState } from "react";
import MonitoringContext from "context/MonitoringContext";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiFillMinusCircle, AiOutlineComment } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { __GetTrackingLog } from "request/Monitoring/__Get_Monitoring";
import TableOrder from "components/Tables/TableOrder";
import {
  TheadTableLog,
  TheadTabletraceability,
  TheadTraceabilityCommentary
} from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingLog from "./TBodyTrackingLog";
import Pagination from "components/Pagination/Pagination";
import { FaHistory } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import styled from "styled-components/macro";
import { HrDividerNoMargin } from "styled/Line.styled";
import { IoAnalyticsOutline } from "react-icons/io5";
import { __PostForm } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import TBodyTraceability from "./TBodyTraceability";
import TBodyInOutMonitoring from "./TBodyInOutMonitoring";
import TBodyTransport from "./TBodyTransport";
import TBodyUcOpSubMonitoring from "./TBodyUcOpSubMonitoring";
import TBodyTrackComments from "./TBodyTrackComments";
import { uploadFile } from "pages/Entry_and_exit_forms/View/FormsView";
import { useQueryClient } from "@tanstack/react-query";

const TbodyMonitoring = ({
  data,
  setGetTrackingId,
  setState,
  getMonitoring,
  setFormType,
  setAttachmentShow,
  movement
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [finalDescription, setFinalDescription] = useState("");
  const [attachmentCancel, setAttachmentCancel] = useState([]);
  const [isOpenLog, onOpenLog, onCloseLog] = useModal();
  const [isOpenHistory, onOpenHistory, onCloseHistory] = useModal();
  const [isOpenCommentary, onOpenCommentary, onCloseCommentary] = useModal();
  const [currentPage, setCurrentPage] = useState([]);
  const [logInfo, setLogInfo] = useState([]);
  const [loading, setLoading] = useState([]);
  const trackingId = data.tracking_id;
  const [openAlert] = AlertErrorAlert();
  const [dataTraceability, setDataTraceability] = useState([]);
  const queryClient = useQueryClient();
  const [dataTraceabilityCommentary, setDataTraceabilityCommentary] = useState([
    {
      user: data?.comments?.initial_user_id || "",
      comment: data?.comments?.initial_comment || ""
    },
    {
      user: data?.comments?.final_user_id || "",
      comment: data?.comments?.final_comment || ""
    }
  ]);

  const { onOpenModalInfo } = useContext(MonitoringContext);

  const navigate = useNavigate();

  const handleShow = () => {
    if (data?.form_id) {
      if (data?.form_type_id === 1) { navigate(`/FormShowExit?ExitNumber=${data.form_id}`); }
    } else if (data?.form_type_id === 2) {
      navigate(`/FormShow?FormEntry=${data.form_id}`);
    }
    // setDataToShow(data);
  };

  const sendDataTableOperations = () => {
    console.log(data);
    setGetTrackingId(data?.data);
    setFormType(data.form_type_id);
    onOpenModalInfo();
    /*
      setAttachmentShow(data?.attachment[0]?.attachment_url) */
  };

  const handleAttachment = async (e) => {
    const dataAttachments = e.target.files[0];
    const vaporRes = await uploadFile(dataAttachments, token);
  
    setAttachmentCancel({
      file: { ...vaporRes },
      name: dataAttachments?.name,
      attachment_description: "",
      attachment_comment: "",
      attachment_date: "",
    });
  };

  const stopTracking = async () => {
    if (!finalDescription) {
      openAlert(
        "Para desactivar el seguimiento se requiere un comentario",
        "error"
      );
    } else {
      try {
        const formData = new FormData();
        formData.append("tracking_id", trackingId);
        formData.append("attachment", JSON.stringify(attachmentCancel));
        formData.append("final_description", finalDescription);
        const res = await __Activate_Or_Inactivate(token, formData);
        const message = res?.data?.status?.message;
        const code = res?.data?.status?.code;
        if (code === 200) {
          onClose();
          openAlert(message, "success");
          queryClient.invalidateQueries({ queryKey: ["fetchServerPage"] });
        } else if (code !== 200) {
          openAlert(message, "error");
        }
      } catch {
        console.log("error");
      } finally {
        getMonitoring();
      }
    }
  };

  const cleanTextArea = () => {
    if (data?.status_id === 1) {
      setFinalDescription("");
      onOpen();
    }
  };

  const handleComments = () => {
    onOpenCommentary();
  };

  const traceabilitySanitization = (data) => {
    const traceability = [];
    const res = Object.entries(data) || []

    res?.forEach(([key, value]) => {
      if (value !== null && value.length > 0) {
        value !== null && value.forEach((item) => {
          traceability.push({
            ...item,
            type: key
          });
        });
      }
    });

    return traceability;
  };

  const handleTreazability = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", data?.tracking_id);
    try {
      const res = await __PostForm(
        environment.GET_TRACKING_TRACEABILITY,
        token,
        formData
      );
      const response = res?.data?.status;
      const data = res?.data?.status?.data;

      if (response?.code === 200) {
        return setDataTraceability(traceabilitySanitization(data));
      }
      openAlert(response?.message, "error");
    } catch (error) {
      console.error(error);
      openAlert("Error al obtener la trazabilidad", "error");
    } finally {
      onOpenHistory();
      setLoading(false);
    }
  };

  const formatLog = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("tracking_id", data?.tracking_id);
    try {
      const res = await __GetTrackingLog(token, formData);
      const infoLog = res?.data?.status?.data;
      if (infoLog) {
        setLogInfo(infoLog);
      }
    } catch {
      console.log("error");
    } finally {
      onOpenLog();
      setLoading(false);
    }
  };

  const MenuItemStyled = styled(MenuItem)`
    background-color: #fff !important;
    color: #000000;

    &:hover {
      background-color: #1f7ac3 !important;
      color: #fff !important;
    }
    &:hover * {
      color: #fff !important;
    }
  `;

  const personalizedTbody = {
    "Formulario de salida": <TBodyInOutMonitoring data={data} />,
    "Formulario de ingreso": <TBodyInOutMonitoring data={data} />,
    "Documento de transporte": <TBodyTransport data={data} />,
    "Usuario calificado": <TBodyUcOpSubMonitoring data={data} />,
    Operaciones: <TBodyUcOpSubMonitoring data={data} />,
    Subpartidas: <TBodyUcOpSubMonitoring data={data} />
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Parar seguimiento"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
            justifyContent: "space-between",
            margin: "0 auto 2rem auto",
            padding: "10px 0"
          }}
        >
          <input
            type="file"
            name="attachment"
            accept=".png, .jpeg, .jpg, .pdf"
            style={{
              marginBottom: "15px"
            }}
            onChange={(e) => handleAttachment(e)}
          />
          <textarea
            onChange={(e) => setFinalDescription(e.target.value)}
            value={finalDescription}
            name="finalDescription"
            cols="50"
            rows="3"
            placeholder="Comentario requerido"
            style={{
              fontSize: "0.8rem",
              resize: "none",
              border: "1px solid #bababa",
              borderRadius: "5px",
              outline: "none",
              padding: "0 10px"
            }}
          ></textarea>
          <Button onClick={stopTracking} style={{ margin: "15px 0" }}>
            Enviar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenLog}
        onOpen={onOpenLog}
        onClose={onCloseLog}
        title="Log"
      >
        <TableOrder
          thead={TheadTableLog}
          data={logInfo}
          setData={setLogInfo}
          loading={loading}
          tbodyData={TBodyTrackingLog}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenHistory}
        onOpen={onOpenHistory}
        onClose={onCloseHistory}
        title="Trazabilidad"
      >
        <TableOrder
          thead={TheadTabletraceability}
          data={dataTraceability}
          setData={setDataTraceability}
          loading={loading}
          tbodyData={TBodyTraceability}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCommentary}
        onOpen={onOpenCommentary}
        onClose={onCloseCommentary}
        title="Comentarios"
      >
        <TBodyTrackComments data={dataTraceabilityCommentary} />
      </ModalGeneric>

      <Tr>
        <Td className="text-center">
          {" "}
          {data?.tracking_code ? data?.tracking_code : "N/A"}
        </Td>

        {/* <Td className="text-center"> {data?.form_code ? data?.form_code : "N/A"}</Td> */}
        {personalizedTbody[movement]}

        <Tooltip placement="top" hasArrow label={"Comentarios"}>
          <Td onClick={onOpenCommentary} className="text-center">
            <IconGeneric color="#03C39A" as={AiOutlineComment} />
          </Td>
        </Tooltip>

        <Tooltip
          placement="top"
          hasArrow
          label={data?.status_id === 1 ? "Activo" : "Detenido"}
        >
          <Td onClick={cleanTextArea} className="text-center">
            {data?.status_id === 1
              ? (
                <IconGeneric color="#03C39A" as={BsCheckCircleFill} />
              )
              : (
                <IconGeneric
                  color="F96464"
                  width="24px"
                  height="24px"
                  as={AiFillMinusCircle}
                />
              )}
          </Td>
        </Tooltip>
        <Td className="text-center">
          <Menu>
            <MenuButton>
              <IconGeneric as={HiDotsHorizontal} color="#00000085" />
            </MenuButton>
            <MenuList
              borderRadius="none"
              py={0}
              style={{ boxSizing: "border-bMenuListox" }}
            >
              <MenuItemStyled py={3} onClick={sendDataTableOperations}>
                <IconMenuRUD as={MdRemoveRedEye} />
                Ver historial
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={formatLog}>
                <IconMenuRUD as={FaHistory} />
                Ver log
              </MenuItemStyled>
              <HrDividerNoMargin />
              <MenuItemStyled py={3} onClick={handleTreazability}>
                <IconMenuRUD as={IoAnalyticsOutline} />
                Trazabilidad
              </MenuItemStyled>
            </MenuList>
          </Menu>
        </Td>

        {/*   <Td className="text-center">
        <div className="d-flex justify-content-center align-items-center mr-4">
        {data?.data?.length ? (
          <button id={data?.tracking_id} onClick={sendDataTableOperations} style={{padding: "0 5px"}}>
            <Icon color='#006EB8' as={MdRemoveRedEye} />
          </button>
          ) : ''}
        </div>
        <Tooltip
          placement="top"
          hasArrow
          label={"Log"}
        >
        {(data?.form_type_id === 1 || data?.form_type_id === 2) ? (

            <button onClick={formatLog} style={{paddingRight: "5px"}}>
              <Icon  color='#006EB8' as={FaHistory} />
            </button>

        ) : ''}
        </Tooltip>

      </Td>     */}
      </Tr>
    </>
  );
};

export default TbodyMonitoring;
