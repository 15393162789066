import React, { useCallback, useContext, useState, useEffect } from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { AiOutlinePlusCircle } from "react-icons/ai";
import TreasuryCrudContex from "context/TreasuryContex";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BiHistory } from "react-icons/bi";

import { __ZonasFranca } from "request/configuration/__ZonasFranca";
import { useSelector } from "react-redux";

const ButtonsUpdate = ({ onOpenUpdate }) => {
  const { setDataSpecial, setDataToRecord } = useContext(TreasuryCrudContex);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataZona, setDataZona] = useState(null);
  const navigate = useNavigate();

  const getTypeZone = useCallback(async () => {
    try {
      const res = await __ZonasFranca(token);
      const zones = res?.data?.status?.data.map((item) => ({
        label: item?.name_freeZone,
        value: item?.id
      }));
      setDataZona(zones);
    } catch (error) { }
  }, [token]);

  const handleNavigate = () => {
    navigate("/TarifasEspeciales?movement=TarifasEspeciales");
    setDataSpecial("Special");
  };

  const handleRecord = () => {
    navigate(`/Record`);
    setDataToRecord("true");
  };

  useEffect(() => {
    getTypeZone();
  }, [getTypeZone]);

  return (
    <div className="row">
      <div className="col-4 ">
        <Text className="ms-2" fontSize="xl" style={{ fontWeight: 600 }}>
          Todas las tarifas
        </Text>
      </div>
      <div className="col-4 "></div>
      <div className="col-4">
        <div className="d-flex float-end ">
          <Tooltip placement="top" hasArrow label="Crear nueva tarifa">
            <span>
              <ButtonHeaderModule
                className="mx-1 d-flex float-end"
                bgColor="#1F7AC3"
                onClick={() => {
                  onOpenUpdate();
                }}
              >
                <IconGeneric color="#fff" as={AiOutlinePlusCircle} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Ver tarifas Especiales">
            <span>
              <ButtonHeaderModule
                className="mx-1 d-flex float-end"
                bgColor="#03C39A"
                onClick={handleNavigate}
              >
                <IconGeneric color="#fff" as={BsFillEyeFill} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Ver historial">
            <span>
              <ButtonHeaderModule
                className="mx-1 d-flex float-end"
                bgColor="#F9A621"
                onClick={handleRecord}
              >
                <IconGeneric color="#fff" as={BiHistory} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ButtonsUpdate;
