import { useSelector } from "react-redux";
import { Box, Checkbox, Flex, Spacer } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { AuthContext } from "context/AuthContext";
import { ButtonStyled } from "styled/Buttons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";
// import InputSelect from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import SelectedMultipleUC from "components/SelectedMultipleUc";
import { arrayRightEntry, arrayRightExit, arrayLeft } from "./arrayCheck";
import InputSelect from "components/InputSelect/InputSelect";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

/**
 *  FormExport - Componente para realizar filtro acorde a opciones a seleccionar. funcionalidad de exportar un archivo .xsl o xlsx
 *
 * @component
 * @return {Component} Retorna archivo .xsl o xlsx
 */

const allOrOne = ["Todos los usuarios", "Usuarios"];

const typeRegister = [
  {
    label: "Entrada",
    value: "entry"
  },
  {
    label: "Salida",
    value: "output"
  }
];

const FormExport = (props) => {
  const { onClose, handleSubmit, defaultRegister } = props;

  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    authUser: { is_qualified_user }
  } = useContext(AuthContext);
  const [isOpenUser, onOpenUser, onCloseUser] = useModal();
  const [values, handleInputChange, reset, setValues] = useForm({});
  const [selectRegister, setSelectRegister] = useState(props?.defaultRegister);


  const [fields, setFields] = useState([]);
  const [qualifiedUserId, setQualifiedUserId] = useState([]);
  const [arrayUserQualified, setArrayUserQualified] = useState([]);
  const [valueQualified, setValueQuialified] = useState({
    value: "",
    label: ""
  });
  const [openAlert] = AlertErrorAlert();

  // states select
  const [userQualifiedState, setUserQualifiedState] = useState(false);

  useEffect(() => {
    getUserQualified();
    // getAllSubpartida();
  }, []);

  useEffect(() => {
    setQualifiedUserId([valueQualified?.value]);
  }, [valueQualified]);

  const selectVar = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const isState = e.target.checked;

    if (isState) {
      setValues({ ...values, [id]: value });
      setFields([...fields, id]);
    } else {
      const obj = Object.assign({}, { ...values });
      delete obj[id];
      setValues(obj);
      const newFields = fields.filter((data) => data !== id);
      setFields(newFields);
    }

    if (e.target.id === "name_qualified" && isState && !is_qualified_user) {
      onOpenUser();
    } else if (e.target.id === "name_qualified" && !isState) {
      setValueQuialified([]);
      setQualifiedUserId([]);
      setUserQualifiedState(false);
    }
  };

  const handleChange = (e) => {
    onCloseUser();
    const value = e.target.value;
    if (value === "Todos los usuarios") {
      setUserQualifiedState(false);
      // const user = arrayUserQualified.map((data) => data.id);
      setQualifiedUserId([]);
    } else {
      setUserQualifiedState(true);
    }
  };

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const data = res?.data?.status?.data;

      setArrayUserQualified(data);
    } catch (error) {}
  };

  const handleExport = () => {
    if (!selectRegister?.value) {
      openAlert("El tipo de registro es requerido", "error");
      return;
    }

    handleSubmit(fields, qualifiedUserId, selectRegister?.value);
  };

  return (
    <div>
      <ModalGeneric
        isOpen={isOpenUser}
        onOpen={onOpenUser}
        onClose={onCloseUser}
        title="Seleccione una opción"
        size={"xs"}
      >
        <div className="d-flex justify-content-center p-2 mb-4">
          {allOrOne.map((items, index) => (
            <Checkbox
              key={index}
              id={items}
              value={items}
              className="m-1"
              onChange={handleChange}
            >
              {items}
            </Checkbox>
          ))}
        </div>
      </ModalGeneric>

      <div className="p-2">
        <h1 className="mb-3">Seleccione las columnas a exportar:</h1>
        <div>
          <InputSelect
            size={30}
            data={typeRegister}
            value={selectRegister}
            set={setSelectRegister}
            tag="Seleccionar registro"
          />
        </div>
        <Box p={4} className="row">
          <div className="col">
            <ul className="noStyle">
              {arrayLeft.map((items, index) => (
                <li key={index}>
                  <Checkbox
                    id={items?.name}
                    value={items?.value}
                    className="ms-1 mb-2"
                    onChange={selectVar}
                  >
                    {items?.label}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>

          <div className="col">
            <ul className="noStyle">
              {selectRegister?.value === "output"
                ? arrayRightExit.map((items, index) => (
                  <li key={index}>
                    <Checkbox
                      id={items?.name}
                      value={items?.value}
                      className="mb-2"
                      onChange={selectVar}
                    >
                      {items?.label}
                    </Checkbox>
                  </li>
                ))
                : arrayRightEntry.map((items, index) => (
                  <li key={index}>
                    <Checkbox
                      id={items.name}
                      value={items.value}
                      className="mb-2"
                      onChange={selectVar}
                    >
                      {items?.label}
                    </Checkbox>
                  </li>
                ))}
            </ul>
          </div>
        </Box>

        {userQualifiedState && (
          <div style={{ width: "100%" }}>
            {/* <InputSelect
              tag={"Usuario calificado"}
              data={userQualified}
              set={setValueQuialified}
              value={valueQualified}
              placeholder=""
            /> */}
            <SelectedMultipleUC
              tag={"Usuario calificado"}
              userCa={arrayUserQualified}
              setValues={setQualifiedUserId}
            />
          </div>
        )}
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled onClick={handleExport}>Exportar</ButtonStyled>
      </Flex>
    </div>
  );
};

export default FormExport;
