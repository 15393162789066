import { useEffect, useContext } from "react";

import { AuthContext } from "context/AuthContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";

const ViewShowCorrected = ({ form }) => {
  const { authUser } = useContext(AuthContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    destiny_old: "",
    observations_old: null,
    name_qualified_old: "",
    observations: "",
    name_qualified: "",
    destiny: ""
  });

  const {
    destiny_old,
    observations_old,
    name_qualified_old,
    observations,
    name_qualified,
    destiny
  } = values;

  useEffect(() => {
    if (form) {
      setValues({
        destiny_old: form?.oldData?.data?.destiny_old,
        observations_old: form?.oldData?.data?.observations_old,
        name_qualified_old: form?.oldData?.data?.name_qualified_old,
        name_qualified: form?.newData?.data?.name_qualified,
        destiny: form?.newData?.data?.destiny,
        observations:
          form?.newData?.data?.observations === "null"
            ? ""
            : form?.newData?.data?.observations
      });
    }
  }, [form]);

  return (
    <div className="d-flex justify-content-between mt-4">
      <div className="col-6 borderforms">
        {authUser.is_qualified_user !== 1 && (
          <div>
            <LabelGroup
              name={"name_qualified_old"}
              value={name_qualified_old ?? 'N/A'}
              onChange={handleInputChange}
              tag={"Empresa"}
              size={90}
              desicion={true}
              changeValue={
                name_qualified_old !== name_qualified && "BorderCorrect"
              }
            />
          </div>
        )}

        <div>
          <LabelGroup
            name={"destiny_old"}
            value={destiny_old ?? 'N/A'}
            onChange={handleInputChange}
            tag={"Tipo de cuadro"}
            size={90}
            desicion={true}
            changeValue={destiny_old !== destiny && "BorderCorrect"}
          />
        </div>

        <div>
          <LabelGroup
            name={"observations_old"}
            value={observations_old ?? 'N/A'}
            onChange={handleInputChange}
            tag={"Observaciones"}
            size={90}
            desicion={true}
            changeValue={observations_old !== observations && "BorderCorrect"}
          />
        </div>
      </div>

      <div className="col-6 borderforms">
        {authUser.is_qualified_user !== 1 && (
          <div>
            <LabelGroup
              name={"name_qualified"}
              value={name_qualified ?? 'N/A'}
              onChange={handleInputChange}
              tag={"Empresa"}
              size={90}
              desicion={true}
              changeValue={
                name_qualified_old !== name_qualified && "BorderCorrect"
              }
            />
          </div>
        )}

        <div>
          <LabelGroup
            name={"destiny"}
            value={destiny ?? 'N/A'}
            onChange={handleInputChange}
            tag={"Tipo de cuadro"}
            size={90}
            desicion={true}
            changeValue={destiny_old !== destiny && "BorderCorrect"}
          />
        </div>

        <div>
          <LabelGroup
            name={"observations"}
            value={observations ?? 'N/A'}
            onChange={handleInputChange}
            tag={"Observaciones"}
            size={90}
            desicion={true}
            changeValue={observations_old !== observations && "BorderCorrect"}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewShowCorrected;
