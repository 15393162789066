import { Td, Tr } from "@chakra-ui/react";
import React from "react";

export const TbodyItems = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.person_name}</Td>
      <Td className="text-center" isNumeric>{data?.amount_approved_item}</Td>
      <Td className="text-center" isNumeric>{data?.amount_returned_items}</Td>
      <Td className="text-center" isNumeric>{data?.amount_revised_items}</Td>
    </Tr>
  );
};
