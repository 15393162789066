/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import environment from 'constants/apiConst'
import { useMutation, useQuery } from '@tanstack/react-query'
import { HeaderForm } from 'components/HeaderForm/HeaderForm'
import LogState from 'pages/Entry_and_exit_forms/CorrectionFMMO/logs/LogState'
import ActionButtonsInView from './ActionButtonsInView'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { SubTitleHeader } from 'components/HeaderForm/Style'
import FormInfo from './FormInfo'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { useForm } from 'hooks/useForm'
import { FormInfoTemplate, errorMessageMap, regexPatterns } from './Templates'
import {
  TitleTableConductorPlate,
  TitleTablePassDispacthShow
} from 'pages/Pass-Form/View/ArrayHeadTable/TheadMovementInt'
import { TbodyDispatchShowItem } from 'pages/Pass-Form/tbody/TbodyDispatchShowItem'
import GlobalContext from 'context/GlobalContext'
import { ButtonStyled } from 'styled/Buttons.styled'
import useTransformOptions from 'hooks/useTransformOptions '
import ControlledSelect from 'components/InputSelect/ControlledSelect'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { EditOrViewItems } from './EditOrViewItems'
import { AddPhotography } from './AddPhotography'
import { AuthContext } from 'context/AuthContext'

import Attachment from './Attachment'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import TableOrderHover from 'components/Tables/TableOrderHover'
import { Box, useDisclosure } from '@chakra-ui/react'

import TbodyDrive from './Tcomponent/Tbody/TbodyDrive'
import { useDebounceErrorHandling } from 'hooks/useDebounceError'
import { MaxLength } from 'utils/MaxLength'
import { CommentManager } from './CommentManager/CommentManager'
import ContainerManager from 'pages/Pass-Form/ContainerManager'
import { validateField } from './Validate/validateField'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import { useFormBlocker } from 'hooks/useFormBlocker'

const initializedValue = {
  phone: '',
  driver: '',
  vehicle_plate: '',
  identification: '',
  name_typeTransport: '',
  // Corrected
  phone_corrected: '',
  driver_corrected: '',
  vehicle_plate_corrected: '',
  identification_corrected: '',
  name_typeTransport_corrected: '',
  // Global
  status_dispatchPassCorrected: ''
}

const commentTemplate = {
  renderProperties: {
    make: 'make_comment_corrected',
    date: 'date_comment_corrected',
    comment: 'comment_corrected',
    person: 'person_name_corrected'
  },
  functionProperties: {
    handleComment: 'comment_corrected'
  }
}

const DispatchCorrectionsShow = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [openAlert] = AlertErrorAlert()
  const [handleDebounceRegexError] = useDebounceErrorHandling()

  const {
    isOpen: isOpenOtherData,
    onOpen: onOpenOtherData,
    onClose: onCloseOtherData
  } = useDisclosure()

  const {
    setAssociateData,
    setActionToBeCarriedOut,
    requestData,
    Update,
    setUpdate
  } = useContext(GlobalContext)

  const [pageItemData, setPageItemData] = useState([])
  const [pageItem, setItemPage] = useState(1)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [plateInformation, setplateInformation] = useState([])
  const [attachment, setAttachment] = useState([])
  const [uploadedImages, setUploadedImages] = useState([])
  const [containers, setContainers] = useState()
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })

  const { authUser } = useContext(AuthContext)
  const {
    Dispatch: DispatchCorrectionID = null,
    Action = '',
    Dispatch = '',
    typeOf = ''
  } = queryString.parse(location.search)
  const token = useSelector(state => state.auth.dataUser.token)
  const onValidate = form => {
    const errors = {}
    const IsErrorRegex = false

    const phoneField = 'phone_corrected'
    const plateField = 'vehicle_plate_corrected'
    const driverField = 'driver_corrected'
    const identificationField = 'identification_corrected'

    validateField(
      plateField,
      'Placa',
      regexPatterns.lettersAndNumbersOnly,
      4,
      MaxLength.entry_movement.form_int.vehicle_plate,
      'El campo "Placa" solo acepta números y letras.',
      errors,
      form,
      IsErrorRegex
    )

    validateField(
      driverField,
      'Conductor',
      regexPatterns.lettersOnly,
      4,
      MaxLength.entry_movement.form_int.driver,
      'El campo "Conductor" solo acepta letras.',
      errors,
      form,
      IsErrorRegex
    )

    validateField(
      identificationField,
      'Cédula del conductor',
      regexPatterns.numbersWithDotsAndCommas,
      7,
      MaxLength.entry_movement.form_int.identification,
      'El campo "Cédula del conductor" solo acepta números.',
      errors,
      form,
      IsErrorRegex
    )

    /*  validateField(
      phoneField,
      'Número de teléfono',
      regexPatterns['numbersWithDotsAndCommas'],
      8,
      MaxLength.entry_movement.form_int.phone,
      'El campo "Número de teléfono" solo acepta números.',
      errors,
      form,
      IsErrorRegex
    ) */

    if (Object.keys(errors).length > 0) {
      return { errors, IsErrorRegex }
    }

    return null
  }

  const [values, handleInputChange, reset, setValues, errors] = useForm(
    initializedValue,
    onValidate
  )

  const handleRegex = ({ target }) => {
    const typeRegex = target?.dataset?.typeRegex || 'anythingGoes'
    const regexConf = regexPatterns[typeRegex]

    const { value } = target
    let errorMessage = null

    if (!regexConf.test(value)) {
      if (typeRegex !== 'anythingGoes') {
        errorMessage = `Este campo solo acepta ${errorMessageMap[typeRegex]}`
      } else if (value.length > 0) {
        errorMessage = `El carácter ${value[value.length - 1]
          } no es permitido en este campo`
      }
    }

    handleDebounceRegexError(
      { target },
      handleInputChange,
      errorMessage,
      regexConf
    )
  }

  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3
  })

  const handleResponse = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
    if (res?.data?.status?.code === 200) {
      /* openAlert(res?.data?.status?.message, 'success') */
    }
  }

  const {
    isLoading: isLoadingViewMain,
    data: ViewMain,
    isError: isErrorViewMain,
    isFetching: isFetchingViewMain,
    refetch: refetchViewMain,
    isSuccess: isSuccessViewMain,
    data: dataViewMain
  } = useQuery({
    queryKey: ['ViewMain'],
    queryFn: () =>
      requestData({
        resourceIdentifier: DispatchCorrectionID,
        Endpoint: environment.GET_CORRECTED_DISPATCH_SHOW,
        PropertyBody: 'get'
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })

  useEffect(() => {
    if (isSuccessViewMain) handleResponse(dataViewMain)
  }, [dataViewMain])

  const handleResponseItem = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      return setUpdate('')
    }
  }

  const {
    isLoading: isLoadingItems,
    data: ItemsData,
    isError: isErrorItems,
    refetch: refetchItems,
    isSuccess: isSuccessItems,
    data: dataItems
  } = useQuery({
    queryKey: ['ItemsDispatch', pageItem, orderBy],
    queryFn: () =>
      requestData({
        pageParam: pageItem,
        resourceIdentifier: DispatchCorrectionID,
        Endpoint: environment.GET_CORRECTED_DISPATCH_ITEMS,
        PropertyBody: 'POST',
        data: {
          orderBy: orderBy?.value
        },
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (isSuccessItems) handleResponseItem(dataItems)
  }, [dataItems])

  const { isLoading: isLoadingTypesTransport, data: TypesTransportData } =
    useQuery({
      queryKey: ['TypesTransport'],
      queryFn: () =>
        requestData({
          Endpoint: environment.TYPETRANSP_NOT_PAGINATE,
          PropertyBody: 'get'
        }),
      refetchOnWindowFocus: false,
      staleTime: 3600000
    })

  const options = useTransformOptions(
    TypesTransportData?.data?.status?.data,
    'name_typeTransport',
    'id'
  )

  const handleFormEditResponse = res => {
    setSubmitLoading(false)
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      openAlert(res?.data?.status?.message, 'success')
      navigate(-1)
    }
  }
  const handleSubmitForm = formData => {
    setSubmitLoading(true)
    const newValue = {
      ...formData,
      containers_corrected: [...containers]
    }

    const Config = {
      data: newValue,
      Endpoint: environment.UPDATE_DISPATCH_CORRECTED,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutate(Config, {
      onSuccess: data => handleFormEditResponse(data)
    })
  }

  const handlePlateSearchResponse = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const plates = res?.data?.status?.data

      if (plates.length === 1) {
        const PlateData = res?.data?.status?.data[0]
        setValues(prevValues => ({
          ...prevValues,
          driver_corrected: PlateData?.name_driver || '',
          identification_corrected: PlateData?.identification || 0,
          phone_corrected: PlateData?.phone || 0
        }))
      }
      if (plates.length > 1) {
        setplateInformation(res?.data?.status?.data)
        onOpenOtherData()
      }
      return openAlert(res?.data?.status?.message, 'success')
    }
  }

  const searchPlate = resourceIdentifier => {
    const config = {
      resourceIdentifier,
      Endpoint: environment.GET_PERSON_VETO,
      PropertyBody: 'get'
    }

    mutate(config, {
      onSuccess: data => handlePlateSearchResponse(data)
    })
  }

  const handleChangePlate = event => {
    const targetName = event.target.name
    const inputValue = event.target.value

    if (
      targetName === 'vehicle_plate_corrected' &&
      inputValue?.length >= 3 /*  &&
      inputValue !== values.vehicle_plate */
    ) {
      searchPlate(inputValue)
    }
  }

  useEffect(() => {
    if (ViewMain?.data?.status?.data) {
      const filterAndNormalizeData = Object.keys(
        ViewMain?.data?.status?.data
      ).reduce((acc, key) => {
        const value = ViewMain?.data?.status?.data[key]
        acc[key] = value !== null && value !== undefined ? value : ''
        return acc
      }, {})
      const status_dispatchPassCorrected =
        filterAndNormalizeData?.status_dispatchPassCorrected ?? null

      if (
        Action === 'Edit' &&
        status_dispatchPassCorrected !== 'DEVUELTO' &&
        status_dispatchPassCorrected !== 'BORRADOR'
      ) {
        navigate(-1)
      }

      setContainers(filterAndNormalizeData?.containers_corrected || [])
      setUploadedImages(filterAndNormalizeData?.photographies || [])
      setAttachment(filterAndNormalizeData?.attachments || [])
      setAssociateData(filterAndNormalizeData)
      setValues(filterAndNormalizeData)
    }
  }, [ViewMain])

  useEffect(() => {
    if (Update === 'formDataToUpdate') {
      refetchViewMain()
      setUpdate('')
    }

    if (Update === 'updatedItems') {
      refetchItems()
      setUpdate('')
    }
  }, [Update])

  useEffect(() => {
    if (Action !== 'Edit') setActionToBeCarriedOut('')
  }, [Action])

  useEffect(() => {
    if (ItemsData?.data?.status?.data?.data?.length > 0) {
      setPageItemData(ItemsData?.data?.status?.data?.data)
    }
  }, [ItemsData])

  useEffect(() => {
    if (Dispatch) {
      return async () => {
        const body = new FormData()
        body.append('form_id', Dispatch)
        body.append('type_of', 'CORREGIDO_DESPACHO')
        body.append('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }
    }
  }, [Dispatch])

  const submitReviewBasedOnStatus = async () => {
    if (
      ViewMain?.data?.status?.data?.status_dispatchPassCorrected ===
      'PRESENTADO'
    ) {
      const body = new FormData()
      body.append('form_id', Dispatch)
      body.append('type_of', 'CORREGIDO_DESPACHO')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body) // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, 'error')
        navigate(-1)
      }
    }
  }

  useEffect(() => {
    submitReviewBasedOnStatus()
  }, [ViewMain])

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: Dispatch || '',
    type_of: 'CORREGIDO_DESPACHO',
    userProvidedEndpoint: environment.FORMS_ON_EDIT
  })

  useEffect(() => {
    ; (ViewMain?.data?.status?.data?.status_dispatchPassCorrected ===
      'BORRADOR' ||
      ViewMain?.data?.status?.data?.status_dispatchPassCorrected ===
      'DEVUELTO') &&
      blockForm(1)
  }, [])

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, 'error')
      navigate(-1)
    }
  }, [formLockQueryResult])

  const Form_Status =
    ViewMain?.data?.status?.data?.status_dispatchPassCorrected || ''

  if (isLoadingViewMain || isFetchingViewMain) return <SpinnerComponent />
  if (isErrorViewMain) return <>Ha ocurrido un error</>

  return (
    <>
      <CommentManager
        identifier={DispatchCorrectionID}
        commentGetService={environment.GET_COMMENTS_CORRECTED_DISPATCH}
        commentCreationService={environment.CREATE_COMMENTS_CORRECTED_DISPATCH}
        properties={commentTemplate}
      />

      <ModalGeneric
        isOpen={isOpenOtherData}
        onOpen={onOpenOtherData}
        onClose={onCloseOtherData}
        title='Información'
        size='xl'
      >
        <TableOrderHover
          thead={TitleTableConductorPlate}
          data={plateInformation}
          setData={setplateInformation}
          loading={false}
          onClose={onCloseOtherData}
          onCloseModal={onCloseOtherData}
          tbodyData={TbodyDrive}
          set={setValues}
        />
      </ModalGeneric>

      <Box className=' mb-5'>
        <HeaderForm
          status={Form_Status}
          numberForm={ViewMain?.data?.status?.data?.passForm_number}
          UserQualified={ViewMain?.data?.status?.data?.name_qualified_corrected}
          Modulo={`Formulario de corrección de Despacho de mercancías en Zonas Francas`}
        />
      </Box>

      {Action !== 'Edit' && (
        <ActionButtonsInView
          data={ViewMain?.data?.status?.data}
          propetyStatus='status_dispatchPassCorrected'
          Actions={Action}
        >
          <LogState
            propetyStatus={'status_passCorrected'}
            data={ViewMain?.data?.status?.data?.status}
          />{' '}
        </ActionButtonsInView>
      )}
      <Box className='row'>
        <Box className='col-md-6'>
          <HeaderSearch>
            <SubTitleHeader>
              Despacho{' '}
              {/*   <NavLink to={`/FormShow?FormEntry=${1}`} className='ms-2'> */}
              {ViewMain?.data?.status?.data?.passForm_number}
              {/*  </NavLink> */}
            </SubTitleHeader>
            <FormInfo
              template={FormInfoTemplate}
              values={values}
              Actions={Action}
              mode='evaluate'
            >
              {' '}
              <ControlledSelect
                name='name_typeTransport'
                options={options}
                defaultValue={{ label: values?.name_typeTransport }}
                label={'Tipo de transporte'}
                placeholder={'Ejemplo: Camión'}
                isLoading={isLoadingTypesTransport}
                isDisabled={true}
              />
            </FormInfo>
          </HeaderSearch>
        </Box>

        <Box className='col-md-6'>
          <HeaderSearch>
            <SubTitleHeader>
              Corrección{' '}
              {/* <NavLink to={`/FormShow?FormEntry=${1}`} className='ms-2'> */}
              {ViewMain?.data?.status?.data?.passForm_number_corrected}
              {/*   </NavLink> */}
            </SubTitleHeader>
            <FormInfo
              template={FormInfoTemplate}
              values={values}
              isEdit={Action === 'Edit'}
              onChange={handleRegex}
              Actions={Action}
              onBlur={handleChangePlate}
              errors={errors}
            >
              <ControlledSelect
                options={options}
                name='name_typeTransport_corrected'
                handleChange={handleInputChange}
                idStorageProperty='typeTransportCorrected_id'
                defaultValue={{ label: values?.name_typeTransport_corrected }}
                label={'Tipo de transporte'}
                placeholder={'Ejemplo: Camión'}
                isLoading={isLoadingTypesTransport}
                isDisabled={Action !== 'Edit'}
              />
            </FormInfo>
          </HeaderSearch>
        </Box>

        {/*  {containers?.length > 0 && ( */}
        <ContainerManager
          containers={containers}
          setContainers={setContainers}
          IsEditable={Action === 'Edit'}
        />
        {/*   )} */}

        <HeaderSearch>
          <EditOrViewItems
            theadData={TitleTablePassDispacthShow}
            TbodyData={TbodyDispatchShowItem}
            currentItemPass={pageItemData || []}
            setCurrentItem={setPageItemData}
            pageItem={pageItem}
            setItemPage={setItemPage}
            maxPageItem={ItemsData?.data?.status?.data?.last_page || 1}
            isLoading={isLoadingItems}
            isErrorItems={isErrorItems}
            Actions={Action}
            qualifiedUserId={ViewMain?.data?.status?.data?.qualifiedUser_id}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          {Action === 'Edit' && (
            <Box className='d-grid gap-2 d-md-flex justify-content-md-end ms-2'>
              <ButtonStyled
                onClick={() => handleSubmitForm(values)}
                wd='140px'
                isLoading={submitLoading}
                loadingText='Guardando..'
                isDisabled={errors}
              >
                Guardar
              </ButtonStyled>
            </Box>
          )}
        </HeaderSearch>

        <HeaderSearch>
          <Attachment
            ServicetoSave={
              environment.ADD_ATTACHMENT_OR_PHOTOGRAPHY_DISPATCH_CORRECTED
            }
            DeleteToService={environment.DELETE_ATTACHMENT_DISPATCH_CORRECTED}
            attachedProperty={{
              attachedName: 'name_attachment_corrected',
              delete: 'name_attachment_corrected',
              id: 'id'
            }}
            data={attachment}
            formId={DispatchCorrectionID}
            setAttachment={setAttachment}
          />
        </HeaderSearch>

        {(Form_Status === 'PRESENTADO' || Form_Status === 'AUTORIZADO') && (
          <HeaderSearch>
            <AddPhotography
              AttachingImages={
                (authUser.admin === 1 || authUser.is_commerce === 1) &&
                Form_Status !== 'AUTORIZADO'
              }
              Title={
                Form_Status === 'PRESENTADO'
                  ? 'Evidencia Fotográfica'
                  : 'Registro fotográfico'
              }
              id={DispatchCorrectionID}
              photographies={uploadedImages}
              setPhotographies={setUploadedImages}
            />
          </HeaderSearch>
        )}
      </Box>
    </>
  )
}

export default DispatchCorrectionsShow
