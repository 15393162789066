import { Box, SimpleGrid } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { useContext, useEffect, useCallback, useState } from "react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __PostForm } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { Regex } from "utils/Regex";
import { parseFloat } from "utils/parseFloat";
import { isValid } from "utils/type-check-utils";

const FormAddOtherCost = ({ onCloseModal }) => {
  const location = useLocation();
  const { pathname } = location;
  const [fomateo] = useFormatQuantity();
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [openAlert] = AlertErrorAlert();
  const [decimal, setDecimal] = useState(null);
  const { authUser } = useContext(AuthContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    quantity_product: "",
    fob_value: "",
    net_weight_kilo: "",
    name_cost: ""
  });
  const { quantity_product, fob_value, net_weight_kilo, name_cost } = values;

  const {
    createData,
    setRequestCreate,
    dataToEditOtherCost,
    editData,
    setRequestEdit,
    userQualified,
    createDataCorrection,
    setRequestCreateCorrection
  } = useContext(IntegracionBoxCrudContex);


  const queryClient = useQueryClient();

  const invalidateQueryFn = () => queryClient?.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] });

  const handleSubmit = (e) => {
    e.preventDefault();
    let nameCost = name_cost;
    let quantityProduct = quantity_product;
    let netWeightKilo = net_weight_kilo;
    let fobValue = fob_value;
    const formData = new FormData();
    formData.append("integration_certificate_id", id || idCorrected);
    if (String(name_cost).includes(",")) {
      nameCost = name_cost?.replaceAll(",", "");
    }

    formData.append("name_cost", nameCost);
  
   if(isValid(quantity_product)) formData.append("quantity_product", parseFloat(quantity_product));

    if (String(net_weight_kilo).includes(",")) {
      netWeightKilo = net_weight_kilo?.replaceAll(",", "");
    }
    formData.append("net_weight_kilo", netWeightKilo);

    if (String(fob_value).includes(",")) {
      fobValue = fob_value?.replaceAll(",", "");
    }
    formData.append("fob_value", fobValue);
    if (dataToEditOtherCost) {
      formData.append("id", dataToEditOtherCost.id);
      editData(formData, invalidateQueryFn);
      onCloseModal();
    } else {
      createData(formData, invalidateQueryFn);
      onCloseModal();
    }
  };

  useEffect(() => {
    const decimal = Number(userQualified?.decimal);
    const precisionDecimal = isNaN(decimal) ? 0 : decimal;

    if (userQualified) setDecimal(precisionDecimal);
  }, [userQualified]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
            ? decimal
            : Number(decimal_values_precision),
          posibleResult
        )
      });
    }
  };

  const ShowOtherCost = useCallback(
    async (data) => {
      try {
        const resp = await __PostForm(
          environment.SHOW_OTHERS_COST,
          token,
          data
        );
        const status = resp?.data?.status.code;
        const { name_cost, net_weight_kilo, fob_value, quantity_product } =
          resp.data.status.data;
        if (status === 403) {
          openAlert(status.message, "error");
        } else {
          setValues({
            quantity_product,
            fob_value,
            net_weight_kilo,
            name_cost
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [token]
  );

  useEffect(() => {
    if (dataToEditOtherCost && dataToEditOtherCost !== null) {
      const dataForm = new FormData();
      dataForm.append("id", dataToEditOtherCost.id);
      ShowOtherCost(dataForm);
    }
  }, [dataToEditOtherCost, ShowOtherCost]);

  useEffect(() => {
    setRequestEdit(environment.UPDATE_OTHERS_COST);
    setRequestCreate(environment.CREATE_OTHERS_COST);
  }, [setRequestCreate, setRequestEdit]);

  useEffect(() => {
    setRequestCreateCorrection(environment.CREATE_OTHERS_COST_CORRECTED);
  }, [setRequestCreateCorrection]);

  const handleSubmitCorrected = (e) => {
    e.preventDefault();
    let quantityProductCorrected = quantity_product;
    let net_weight_kilo_corrected = net_weight_kilo;
    let fob_value_corrected = fob_value;
    const formData = new FormData();
    formData.append("integrationCertificateCorrected_id", id || idCorrected);
    formData.append("name_cost_corrected", name_cost);

    if (String(quantity_product).includes(",")) {
      quantityProductCorrected = quantity_product.replaceAll(",", "");
    }
    formData.append("quantity_product_corrected", quantityProductCorrected);

    if (String(net_weight_kilo).includes(",")) {
      net_weight_kilo_corrected = net_weight_kilo.replaceAll(",", "");
    }
    formData.append("net_weight_kilo_corrected", net_weight_kilo_corrected);

    if (String(fob_value).includes(",")) {
      fob_value_corrected = fob_value.replaceAll(",", "");
    }
    formData.append("fob_value_corrected", fob_value_corrected);
    createDataCorrection(formData, invalidateQueryFn);
    onCloseModal();
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <form>
      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <div>
          <LabelGroup
            name={"name_cost"}
            value={name_cost}
            onChange={handleInputChange}
            tag={"Descripción"}
            size={100}
            desicion={false}
            maxLength={5000}
          />
        </div>

{/*         <div>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"quantity_product"}
            value={quantity_product}
            onChange={(e) => handleRegex(e, "Numeros", Regex?.numeros)}
            tag={"Cantidad "}
            size={100}
            desicion={false}
            onBlur={handleBlurCant}
            maxLength={34}
          />
        </div> */}

        <div>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"net_weight_kilo"}
            value={net_weight_kilo}
            onChange={(e) => handleRegex(e, "Numeros", Regex?.numeros)}
            tag={"Peso en Kilos"}
            size={100}
            desicion={false}
            onBlur={handleBlurCant}
            maxLength={34}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-3">
        <div>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"fob_value"}
            value={fob_value}
            onChange={(e) => handleRegex(e, "Numeros", Regex?.numeros)}
            tag={"Valor Total en US$"}
            size={100}
            desicion={false}
            onBlur={handleBlurCant}
            maxLength={34}
          />
        </div>
        <div></div>
      </SimpleGrid>

      <div className="mb-3 ms-5">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          {pathname === "/CorrectedCertificate"
            ? (
              <ButtonStyled type="submit" onClick={handleSubmitCorrected}>
                Agregar
              </ButtonStyled>
            )
            : (
              <ButtonStyled type="submit" onClick={handleSubmit}>
                Agregar
              </ButtonStyled>
            )}
        </Box>
      </div>
    </form>
  );
};

export default FormAddOtherCost;
