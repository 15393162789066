import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import PassCrudContext from "context/PassCrudContext";

const TbodyConductores = ({ data }) => {
  const { setDataConductor, onCloseOtherData } = useContext(PassCrudContext);

  const handleSearchItem = () => {
    setDataConductor(data);
    onCloseOtherData();
  };

  return (
    <Tr onClick={handleSearchItem}>
      <Td className="text-center">{data?.vehicle_plate}</Td>
      <Td className="text-center">{data?.identification}</Td>
      <Td className="text-center">{data?.name_driver}</Td>
      <Td className="text-center">{data?.phone}</Td>
      <Td className="text-center">{data?.name_typeTransport}</Td>
    </Tr>
  );
};

TbodyConductores.propTypes = {
  data: PropTypes.object
};

export default TbodyConductores;
