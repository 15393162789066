import axios from "axios";

export const __EditCreateThirdParties = async (endpoint, token, body) => {
  console.log(endpoint);
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${endpoint}`, options);

  return res;
};
