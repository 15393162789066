import { Td, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import MenuItems from "components/Menu/MenuItems";
import { __formaterCant } from "components/Funciones/formaterCant";

const TBodyServiceItem = ({ data }) => {
  const [erroresLocal, setErroresLocal] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );

  useEffect(() => {
    let obtenerErrorItem;
    if (FormEntryNumber) {
      obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormEntryNumber}`) || "[]"
      );

      if (obtenerErrorItem) {
        setErroresLocal(obtenerErrorItem);
        return;
      }
    }

    if (FormExitNumber) {
      obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormExitNumber}`) || "[]"
      );

      if (obtenerErrorItem) {
        setErroresLocal(obtenerErrorItem);
      }
    }
  }, [FormEntryNumber]);

  const descripción =
    data?.entry_form_item_description || data?.output_form_item_description;

  return (
    <Tr
      className={
        erroresLocal && erroresLocal?.includes(data.id) && "BorderError"
      }
    >
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">{__formaterCant(data?.service || 0)}</Td>
      <Td className="text-center">{data?.exchange_rate}</Td>
      <Td className="text-center">{descripción || ""}</Td>
      <Td isNumeric className="text-center">
        <MenuItems data={data} />
      </Td>
    </Tr>
  );
};

export default TBodyServiceItem;
