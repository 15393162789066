/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

import InputSelect, {
  InputSelectMulti,
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  dateBy,
  statePassOrDispacth,
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useLocation } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useRegexLabelState, useRegexState } from "hooks/useRegexState";
import GlobalContext from "context/GlobalContext";
import { handleSanitizedObj, isArray } from "utils/type-check-utils";
import { useSelector } from "react-redux";
import { useSearchUrl } from "hooks/useSearchUrl";
import { NumericFormat } from "react-number-format";

const FormDocumentsCorrect = ({
  handleSubmit = () => {},
  onClose,
  dataTypeOperation,
  viewSelectStatus,
}) => {
  const { state, setParamsSearch } = useSearchUrl({
    getParams: ["date_start", "date_end", "cargo_manifest", "identification_num", "dcl", "ntd", "nci", "time_start", "time_end", "code_form_original", "code_operation", "code", "order_by", "selectUF", "datosBy", "stateDispacthOrPass", "operationT"], nestedProp: "searchAdvance"
  });


  const [order_by, setOrderBy] = useState(state?.order_by === 'ASC' ? 'Ascendente' : 'Descendente');
  const [typeOperation, setTypeOperation] = useState(state?.typeOperation);


  const { values, handleInputChange, reset, cleaReduxState } = useForm({
    initialState: {
      date_start: state.date_start,
      date_end: state.date_end,
      cargo_manifest: state.cargo_manifest,
      identification_num: state.identification_num,
      dcl: state?.dcl,
      ntd: state?.ntd,
      nci: state?.nci,
      time_start: state.time_start,
      time_end: state.time_end,
      code_form_original: state.code_form_original,
      code_operation: state.code_operation,
      code: state.code,
    },
    /*     formKey: "filterDocument",
        useRedux: true, */
    returnType: "object",
  });



  const { form: useFormRedux } = useSelector((state) => state);

  const {
    date_start,
    date_end,
    cargo_manifest,
    identification_num,
    dcl,
    ntd,
    nci,
    time_start,
    time_end,
    code_form_original,
    code_operation,
    code,
  } = values;

  const options = ["Único", "Fracionado"];
  const optiones = ["Ascendente", "Descendente"];
  const [datosBy, setDatosBy] = useState(state?.datosBy);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(state?.stateDispacthOrPass);
  const location = useLocation();
  const { pathname } = location;
  const { setSearchAdvancedPosition, setChangeExportExcel } =
    useContext(FormCrudCRUDContext);

  const handleBusqueda = () => {
    setChangeExportExcel("Document");
    setSearchAdvancedPosition("Document");



    const newState = [];
    if (stateDispacthOrPass) {
      stateDispacthOrPass.forEach(({ value }) => newState.push(value));
    }

    console.log({ newState });

    const imputSelects = handleSanitizedObj({
      datosBy,
      stateDispacthOrPass,
      typeOperation,
    })

    const newObjetData = {
      ...handleSanitizedObj(values),
      order_by: order_by === "Ascendente" ? "ASC" : "DESC",
      ...(datosBy?.value && { date_from: datosBy?.value }),
      ...(isArray(newState) && newState.length > 0 && { status: newState }),
      ...(typeOperation?.value && { type_operation: typeOperation?.value }),
    };

    setParamsSearch({
      params: {
        ...newObjetData,
        ...imputSelects,
        typeSearch: "document",
      }
    });


    handleSubmit(newObjetData);
    onClose();
  };



  const updateItemValue = (newValue, sourceEvent) => {
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue,
    };

    handleInputChange(target);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <NumericFormat
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={0}
          decimalSeparator='.'
          onValueChange={updateItemValue}
          customInput={LabelGroup}
          name={'code'}
          size={45}
          tag='Nº Formulario'
          value={code}
          isAllowed={(values) => {
            const { value } = values;
            return value.toString().length <= 6;
          }}
        />
        <NumericFormat
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={0}
          decimalSeparator='.'
          onValueChange={updateItemValue}
          customInput={LabelGroup}
          name={'code_operation'}
          size={45}
          tag='Cod. Operacion'
          value={code_operation}
          isAllowed={(values) => {
            const { floatValue } = values;

            return floatValue.toString().length <= 3;
          }}

        />
        {/* <LabelGroup
          name={"code"}
          value={code}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"code_operation"}
          value={code_operation}
          tag={"Cod. Operacion"}
          size={45}
          tipo={"text"}
          maxLength={"3"}
        /> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"date_start"}
          value={date_start}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_end"}
          value={date_end}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"time_start"}
          value={time_start}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"time_end"}
          value={time_end}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>

      {!viewSelectStatus && (
        <>
          <div className="row py-3">
            {/* <div className="col-sm">
          <RadioBtn options={options} setOnly={setSelectUF} state={selectUF} />
        </div> */}
            <div className="col-sm">
              <RadioBtn
                options={optiones}
                setOnly={setOrderBy}
                state={order_by}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <div style={{ width: `45%` }}>
              <InputSelect
                name={"date_by"}
                tag={"Fecha de:"}
                data={dateBy}
                value={datosBy}
                set={setDatosBy}
              />
            </div>
            <div style={{ width: `45%` }}>
              <InputSelectMulti
                name={"state_dispacth"}
                tag={"Estado"}
                data={statePassOrDispacth}
                value={stateDispacthOrPass}
                set={setStateDispacthOrPass}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <div style={{ width: `45%` }}>
              <InputSelect
                name={"state_dispacth"}
                tag={"Tipo Operación"}
                data={dataTypeOperation}
                value={typeOperation}
                set={setTypeOperation}
              />
            </div>
            <LabelGroup
              name={"identification_num"}
              value={identification_num}
              onChange={handleInputChange}
              tag={`Nit Proveedor/Agente `}
              size={45}
              tipo={"text"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <LabelGroup
              name={"nci"}
              value={nci}
              onChange={handleInputChange}
              tag={"Nº Factura Comercial"}
              size={45}
              tipo={"text"}
            />
            <LabelGroup
              name={"ntd"}
              value={ntd}
              onChange={handleInputChange}
              tag={"Doc. de Transporte"}
              size={45}
              tipo={"text"}
              maxLength={"10"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            <LabelGroup
              name={"dcl"}
              value={dcl}
              onChange={handleInputChange}
              tag={"DTA"}
              size={45}
              tipo={"text"}
              maxLength={"10"}
            />
            <LabelGroup
              name={"cargo_manifest"}
              value={cargo_manifest}
              onChange={handleInputChange}
              tag={"Manifiesto de Carga"}
              size={45}
              type={"number"}
              maxLength={"10"}
            />
          </div>

          {(pathname !== "/Form-tables-entry" ||
            pathname !== " /Form-tables-output") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2rem",
                }}
              >
                <LabelGroup
                  name={"code_form_original"}
                  value={code_form_original}
                  onChange={handleInputChange}
                  tag={"Nº Formulario Original"}
                  size={45}
                  tipo={"text"}
                />
              </div>
            )}
        </>
      )}

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormDocumentsCorrect;
