import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";

const PublicRoutes = ({ children }) => {
  // const { authUser } = useContext(AuthContext);
  const [origin, setOrigin] = useState(null);
  const { sessionExpired } = useSelector((state) => state.auth);

  const location = useLocation();
  const { dataUser, isLogged } = useSelector((state) => state.auth);

  const calculateLocation = () => {
    const state = location?.state;
    const origin = state?.origin;
    if (state && origin && sessionExpired) return setOrigin(`${origin?.pathname}${origin?.search}`)
    return setOrigin("/");
  }

  useEffect(() => {
    calculateLocation();
  }, []);

  return isLogged && dataUser.token ? <Navigate to={origin} /> : children;
};

export default PublicRoutes;
