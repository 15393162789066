import React, { useCallback, useContext, useEffect, useState } from "react";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import { TitleUpdateRecord } from "pages/Treasury/ArrayList/ArrayList";
import TBodyRecord from "pages/Treasury/Tables/TbodyRecord";
import TreasuryCrudContex from "context/TreasuryContex";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import UpdateView from "./UpdateView";
import { Box } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { __PostForm } from "request/Petitions/__Post";
import AcordionComponent from "./AcordionComponent";
import queryString from "query-string";

const RecordUpdate = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPageFreeZone, setCurrentPageFreeZone] = useState([]);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const [currentPage, setCurrentPage] = useState([]);

  const {
    dataToRecord,
    isOpenViewUpdate,
    onCloseModal,
    dataIdShow,
    setDataToRecord
  } = useContext(TreasuryCrudContex);
  const [loading, setLoading] = useState(false);

  const changePage = (year) => {
    setPage(1);
    if (year) {
      getUpdateRatesHistory(year);
    }
  };

  const getUpdateRates = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __PostForm(environment.TREASURY_UPDATE_RATE_GET, token);
      const response = res?.data?.status?.data;
      setCurrentPage(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getUpdateRates();
  }, [getUpdateRates, freeZoneValue]);

  const getUpdateRatesHistory = useCallback(
    async (year, id) => {
      const newDataRecord = new FormData();
      if (!year) {
        newDataRecord.append("historic", dataToRecord);
      } else if (year) {
        newDataRecord.append("idDetail", year);
        newDataRecord.append("free_zone_id", id);
      }
      setLoading(true);
      try {
        const res = await __PostForm(
          environment.TREASURY_UPDATE_RATE_GET,
          token,
          newDataRecord
        );
        const responseFreeZone = res?.data?.status?.data;
        if (res?.data?.status.code === 200) {
          setCurrentPageFreeZone(responseFreeZone);
        } else {
          setCurrentPageFreeZone([]);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const BackButton = () => {
    navigate(-1);
    setDataToRecord("");
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenViewUpdate}
        onClose={onCloseModal}
        title={`Tarifa ${dataIdShow?.name_type_rate}`}
        size={"6xl"}
      >
        <UpdateView onClose={onCloseModal} />
      </ModalGeneric>

      <>
        <HeaderSearch>
          <HeaderForm
            status={""}
            Modulo={`Historial de actualización tarifas`}
          />
        </HeaderSearch>

        <HeaderSearch>
          <AcordionComponent
            cabecera={currentPage}
            changePage={changePage}
            TitleHeader={TitleUpdateRecord}
            TBodyEntryMovement={TBodyRecord}
            getDataTable={getUpdateRatesHistory}
            currentPage={currentPageFreeZone}
            setCurrentPage={setCurrentPage}
            loading={loading}
            page={page}
            setPage={setPage}
            maxPage={maxPage}
            stateNull={true}
            nameModule={"Tarifas"}
          ></AcordionComponent>
        </HeaderSearch>

        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={BackButton}>
            Volver
          </ButtonStyled>
        </Box>
      </>
    </>
  );
};

export default RecordUpdate;
