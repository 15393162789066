/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

// import CustomsCrudContext from "context/CustomsCrudContext";
import MenuCustomsClearence from "components/Menu/MenuCustomsClearence";
import MenuPrincipal from "components/Menu/MenuOrderProduct/MenuPrincipal";

const Body = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const { setDataToShow, setDataToEdit, seteditItemsCustomsClearance } =
  //     useContext(CustomsCrudContext);

  const handleShow = (data) => {
    navigate(`/VerOrdenDeProduccion?Orden=${data?.production_order_id}`);
  };

  return (
    <Tr>
      <Td className="text-center">
        <NavLink
          to={`/VerOrdenDeProduccion?Orden=${data?.production_order_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.production_order_code}</span>
        </NavLink>
      </Td>
      <Td className="text-center">
        {data?.qualified_users_nit ? data?.qualified_users_nit : "N/A"}
      </Td>
      <Td className="text-center">
        {" "}
        {data?.qualified_users_name ? data?.qualified_users_name : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.productive_process_code ? data?.productive_process_code : ""}
      </Td>
      <Td className="text-center">
        {data?.product_code ? data?.product_code : ""}
      </Td>

      <Td className="text-center">
        {data?.production_order_status ? data?.production_order_status : "N/A"}
      </Td>
      <Td className="text-center">
        <MenuPrincipal data={data} />
      </Td>
    </Tr>
  );
};

export default Body;
