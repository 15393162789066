import { useContext } from 'react'
import { Td, Tr } from '@chakra-ui/react'
import MenuOrdenProduccion from 'components/Menu/MenuIntegracionBox/MenuOrdenProduccion'
import { useLocation } from 'react-router-dom'
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex'
import MenuCorrected from 'components/Menu/MenuIntegracionBox/MenuCorrected'
import { IconGeneric } from 'styled/Icons.styled'
import { BsEyeFill } from 'react-icons/bs'
import queryString from 'query-string'

const TbodyElaborateProduct = ({ data }) => {
  const location = useLocation()
  const { pathname } = location
  const { idCorrected = null } = queryString.parse(location.search)
  const { setDataIdOrder, onToggle, setShowProduct, setDataIdProduct, setActiveView } =
    useContext(IntegracionBoxCrudContex)

  const handleInfo = () => {
    if (data?.code_production_order) {
      setDataIdOrder(data)
      onToggle()
      setActiveView(null)
      setShowProduct(false)
    } else {
      setShowProduct(true)
      setActiveView('showProduct')
      setDataIdProduct(data)
    }
  }

  return (
    <Tr
      className={data?.corrected === 2 && idCorrected && 'BorderCorrectTabla'}
    >
      <Td className='text-center'>
        {data?.item_code ? data?.item_code : data?.item_code_corrected}
      </Td>
      <Td className='text-center'>
        <span>
          {data?.description_product
            ? data?.description_product
            : data?.description_product_corrected}
        </span>
      </Td>
      <Td className='text-center'>
        {data?.code_product
          ? data?.code_product
          : data?.code_product_corrected
            ? data?.code_product_corrected
            : 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.code_subheading
          ? data?.code_subheading
          : data?.code_subheading_corrected}
      </Td>
      <Td className='text-center'>
        {data?.quantity ? data?.quantity : data?.quantity_corrected}
      </Td>
      <Td className='text-center'>
        {data?.business_unit
          ? data?.business_unit
          : data?.business_unit_corrected
            ? data?.business_unit_corrected
            : 'N/A'}
      </Td>

      <Td className='text-center'>
        {data?.unit_measurement
          ? data?.unit_measurement
          : data?.unit_measurement_corrected}
      </Td>
      <Td className='text-center'>
        {data?.quantity_product
          ? data?.quantity_product
          : data?.quantity_product_corrected}
      </Td>

      <Td className='text-center'>
        {data.net_weight_kilo
          ? data.net_weight_kilo
          : data.net_weight_kilo_corrected}
      </Td>

      <Td className='text-center'>
        {data.fob_value ? data.fob_value : data.fob_value_corrected}
      </Td>

      <Td className='text-center'>
        {data?.productVal
          ? 'N/A'
          : data?.code_production_order
            ? data?.code_production_order
            : data?.production_order_code_corrected}
      </Td>
      {pathname === '/ShowIntegrationBox' && (
        <Td className='text-center' isNumeric>
          <MenuOrdenProduccion data={data} pathname={pathname} />
        </Td>
      )}

      {pathname === '/IntegracionBox' || pathname === '/CheckIntegrationBox'
        ? (
          <Td className='text-center' isNumeric>
            <MenuOrdenProduccion data={data} pathname={pathname} />
          </Td>
        )
        : pathname === '/CorrectedCertificate'
          ? (
            <Td className='text-center' isNumeric>
              <MenuCorrected data={data} />
            </Td>
          )
          : pathname === '/ShowIntegrationBox' &&
        data?.corrected === 2 &&
        idCorrected
            ? (
              <Td className='text-center'>
                <IconGeneric
                  color={'#1F7AC3'}
                  as={BsEyeFill}
                  onClick={() => handleInfo()}
                />
              </Td>
            )
            : (
              ''
            )}
    </Tr>
  )
}

export default TbodyElaborateProduct
