import React from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import MenuSearchAdvance from "./MenuSearchAdvance";

const TbodySearchAdvance = ({ data, onClose }) => {
  const SelectRouter = (status) => {
    if (status === "Formulario de ingreso") {
      return `/FormShow?FormEntry=${data?.id}`;
    }

    if (status === "Formulario de salida") {
      return `/FormShowExit?ExitNumber=${data?.id}`;
    }

    if (status === "Formulario de certificado de integración") {
      return `/ShowIntegrationBox?id=${data?.id}`;
    }
  };

  return (
    <Tr>
      <Td onClick={() => onClose()} className="text-center">
        {" "}
        <NavLink to={SelectRouter(data.type_doc)}>
          <span className="formNumber">{data?.code_entryForm}</span>
        </NavLink>
      </Td>

      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.type_doc}</Td>
      <Td className="text-center">{data?.num_commercial_invoice}</Td>
      <Td className="text-center">
        <MenuSearchAdvance data={data} onClose={onClose} />
      </Td>
    </Tr>
  );
};

TbodySearchAdvance.propTypes = {
  data: PropTypes.object
};

export default TbodySearchAdvance;
