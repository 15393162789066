import { Tooltip } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiDeviceRecoverFill } from "react-icons/ri";
import { MdContentCopy, MdFactCheck } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { AuthContext } from "context/AuthContext";
import { FaRegCalendarCheck } from "react-icons/fa";
import { AiOutlineFileSync } from "react-icons/ai";

export const BtnShowPresentado = ({
  handleReview,
  handleChangeStatus,
  handleCopy,
}) => {
  const { authUser } = useContext(AuthContext);
console.log('authUser', authUser)
  return (
    <>
      <Tooltip placement="top" hasArrow label="Recuperar">
        <span>
          <ButtonHeaderModule
            onClick={() => handleChangeStatus("BORRADOR")}
            bgColor="#1F7AC3"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={RiDeviceRecoverFill}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

     {/*  {(authUser.admin === 1 || authUser.is_commerce !== 1)&& (
      <Tooltip placement="top" hasArrow label="Devolver">
        <span>
          <ButtonHeaderModule
            onClick={() => handleChangeStatus("DEVUELTO")}
            bgColor="#d92525"
            className="mx-1"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={AiOutlineFileSync}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>)} */}

      {/*     <Tooltip placement="top" hasArrow label="Reservar">
        <ButtonHeaderModule
          onClick={() => handleChangeStatus("BORRADOR")}
          bgColor="#1F7AC3"
        >
          <IconGeneric
            width="16px"
            className=" text-white"
            as={FaRegCalendarCheck}
          />
        </ButtonHeaderModule>
      </Tooltip> */}

      {(authUser.is_qualified_user !== 1 || authUser.is_commerce === 1) && (
        <Tooltip placement="top" hasArrow label="Revisar">
          <span>
            <ButtonHeaderModule
              onClick={handleReview}
              bgColor="#58B158"
              className="mx-1"
            >
              <IconGeneric width="16px" className=" text-white" as={BiShow} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      <Tooltip placement="top" hasArrow label="Copiar">
        <span>
          <ButtonHeaderModule
            onClick={handleCopy}
            bgColor="#03C39A"
            className="mx-1"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={MdContentCopy}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

{/*       {(authUser.admin === 1 || authUser.is_commerce !== 1)&& (
        <Tooltip placement="top" hasArrow label="Radicar">
          <span>
            <ButtonHeaderModule
              onClick={() => handleChangeStatus("AUTORIZADO")}
              bgColor="#03C39A"
              className="mx-1"
            >
              <IconGeneric
                width="16px"
                className=" text-white"
                as={MdFactCheck}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )} */}
    </>
  );
};
