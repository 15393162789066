import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { __ShowDependency } from "request/configuration/__Dependency";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyDependency = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.DEPENDENCY_DESACTIVE, data.id)
          : await __ACTIVAR(token, environment.DEPENDENCY_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    try {
      const res = await __ShowDependency(token, data.id);
      const result = res.data.status.data.status;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.dependency}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data.status === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data.status}
        >
          <IconGeneric
            color={data.status === 1 ? "#03C39A" : "#bababa"}
            as={BsCheckCircleFill}
          />
        </TdState>
      </Tooltip>

      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyDependency;
