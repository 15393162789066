import axios from "axios";
import env from "../../constants/apiConst";

export const __GroupZonesAll = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GROUPZF_ALL}`, options);
  return res;
};
