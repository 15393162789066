import React from "react";
import Hover from "components/HoverInfo";
import { BsInfoCircle } from "react-icons/bs";
import { isNumber } from "utils/type-check-utils";

const MaxValueHover = ({
  selectCurrency,
  max_value_cop,
  exchange_rate,
  us_fob_value,
  valorDolarActual,
}) => {
  const formattedMaxValueCop = max_value_cop
    ? parseFloat(max_value_cop).toLocaleString("es-CO")
    : "20.000.000";
  const usFobValue = isNumber(us_fob_value)
    ? parseFloat(us_fob_value)
    : parseFloat(us_fob_value.replace(/,/g, ""));

  if (
    selectCurrency === "USD" &&
    ((!max_value_cop &&
      usFobValue * parseFloat(exchange_rate || valorDolarActual) > 20000000) ||
      (max_value_cop &&
        usFobValue * parseFloat(exchange_rate || valorDolarActual) >
          max_value_cop))
  ) {
    return (
      <div className="">
        <Hover
          title={"Valor máximo de ingreso"}
          description={`Recuerde: el valor FOB USD * Tasa de cambio no debe exceder los ${
            max_value_cop ? `${formattedMaxValueCop} COP` : "20.000.000 USD"
          }`}
          icon={<BsInfoCircle />}
          autoFocus={false}
        />
      </div>
    );
  }
  if (
    selectCurrency === "COP" &&
    ((!max_value_cop && usFobValue > 20000000) ||
      (max_value_cop && usFobValue > max_value_cop))
  ) {
    return (
      <div className="">
        <Hover
          title={"Valor máximo de ingreso"}
          description={`Recuerde: el valor FOB COP no debe exceder los ${
            max_value_cop ? `${formattedMaxValueCop} COP` : "20.000.000 COP"
          }`}
          icon={<BsInfoCircle />}
          autoFocus={false}
        />
      </div>
    );
  }
};

export default MaxValueHover;
