import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __TransportMethod = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.TRANSPORT_METHOD_GET}?page=${page || 1}`,
    options
  );
  return res;
};

export const __TransportMethodNotPaginate = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TRANSPORT_METHOD_ALL}`, options);
  return res;
};

export const __ShowTransportMethod = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TRANSPORT_METHOD_SHOW}/${id}`, options);
  return res;
};
