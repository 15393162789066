import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import Vapor from "laravel-vapor";
import MultiFileUpload, { defaultAllowedTypes } from "./MultiFileUpload";
import { useGlobalContext } from "context/GlobalContext";

const FileUploadManager = ({
  vaporBaseUrl,
  getAuthToken,
  title = "Subir documentos del proyecto",
  formID,
  postPropertyName = "attachment",
  endpointAttachments,
  handleResponseUpdateForm = () => {},
  allowedFileTypes = defaultAllowedTypes,
}) => {
  const toast = useToast();
  const { requestData } = useGlobalContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: requestData,
    onSuccess: (res) => {
      handleResponseUpdateForm(res);
      toast({
        title: "Subida exitosa",
        description: `Archivos subidos correctamente.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return 0;
    },
    onError: (error) => {
      console.error("Error durante la subida:", error);
      toast({
        title: "Error en la subida",
        description:
          "Hubo un problema al subir los archivos. Por favor, intenta de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return 0;
    },
  });

  const uploadToVapor = async (file, onProgress) => {
    const token = await getAuthToken();
    return Vapor.store(file, {
      baseURL: vaporBaseUrl,
      headers: { Authorization: `Bearer ${token}` },
      progress: onProgress,
    });
  };
  const handleFileSelect = (uploadedFiles) => {
    const formattedFiles = uploadedFiles.map((file) => ({
      file: {
        uuid: file.vaporId,
        bucket: file.bucket,
        key: file.key,
        url: file.url,
        headers: file.headers,
        extension: file.extension,
      },
      name: file.name,
    }));

    const formData = new FormData();
    formData.append("id", formID);
    formData.append(postPropertyName, JSON.stringify(formattedFiles));
    const requestData = {
      data: formData,
      Endpoint: endpointAttachments?.create,
      PropertyBody: "SENDFORMDATACONTENTPOST",
    };

    mutate(requestData);
  };

  return (
    <MultiFileUpload
      onFileSelect={handleFileSelect}
      uploadToVapor={uploadToVapor}
      isUploading={isLoading}
      title={title}
      allowedFileTypes={allowedFileTypes}
    />
  );
};

export default FileUploadManager;
