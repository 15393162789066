/* eslint-disable react-hooks/exhaustive-deps */
import "./styleInput.css";
import Select from "react-select";
import AsyncSelect from "react-select";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import FormCrudCRUDContext from "context/FormCrudContex";
import {
  LabelStyled,
  LabelStyledLink,
  colourStyles,
} from "components/addProduct/CreateTemplate.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Tooltip } from "@chakra-ui/react";
import isArrayNotEmpty, {
  isArray,
  isNonEmptyString,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";

export const SelectThirdParties = ({
  data,
  tag,
  size,
  setValor,
  setName,
  valor,
  Disabled = false,
  Name,
  Error = false,
  ButtonSide = false,
  customColorLabel = null,
  onChange = () => { },
  ...attrs
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: `${element.identification_num !== null
          ? element?.identification_num
          : " "
          } ${element?.business_name} `,

        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setName(items?.label);
  };

  const handleAndNotifySelectChange = (...attrs) => {
    handleSelectChange(...attrs);
    onChange(...attrs);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled style={customColorLabel && { color: customColorLabel }} error={Error}>
        {tag}
      </LabelStyled>
      <Select
        styles={
          Error
            ? {
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "grey" : "red",
                borderTopRightRadius: ButtonSide && "0",
                borderBottomRightRadius: ButtonSide && "0",
                borderRight: ButtonSide && "0",
              }),
            }
            : ButtonSide
              ? {
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  borderRight: "0",
                }),
              }
              : ""
        }
        isDisabled={Disabled}
        value={{ label: Name, value: valor }}
        options={selectValor(data)}
        onChange={handleAndNotifySelectChange}
        {...attrs}
      />
    </div>
  );
};

export const SelectFormTypeOperation = ({
  data,
  tag,
  size,
  valor,
  setValor,
  setSelectIdOp,
  setNameOperation,
  nameOperation,
  setPlazo = null,
  Disabled,
  Name,
  onChange = () => { },
  error = false,
  Label,
  LabelBg = null,
  setPrimaryValue,
  customColorLabel = null,
  ...attrs
}) => {
  const { setDocumentOperation } = useContext(FormCrudCRUDContext);

  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      if (element.typeOperation_id !== 9) {
        valores.push({
          label: element?.code_operation + " " + element?.short_name_operation,
          value: element?.code_operation,
          id: element?.id,
          documents: element?.documents,
          plazo: element?.term,
          ...element,
        });
      }
    });
    return valores;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : provided?.borderColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: error ? "red" : provided.color,
    }),
  };

  const handleSelectChange = (items) => {
    setPrimaryValue(items);

    const idvalue = items?.value;
    setValor(idvalue);
    setSelectIdOp(items?.id);
    setNameOperation(items.label);
    setDocumentOperation(items?.documents);
    if (items.plazo !== 0) {
      if (setPlazo) setPlazo(items.plazo);
    } else {
      if (setPlazo) setPlazo(null);
    }
  };

  const handleAndNotifySelectChange = (...attrs) => {
    handleSelectChange(...attrs);
    onChange(...attrs);
  };

  return (
    <Tooltip
      hasArrow
      label={Label}
      bg={LabelBg || "white"}
      color="white"
      placement="bottom-start"
    >
      <div style={{ width: `${size}%` }}>
        <LabelStyled
          style={customColorLabel && { color: customColorLabel }}
          error={error}
        >
          {tag}
        </LabelStyled>
        <Select
          error={error}
          // className="z-index"
          isDisabled={Disabled}
          value={{ label: nameOperation, value: valor }}
          styles={customStyles}
          options={selectValor(data)}
          onChange={handleAndNotifySelectChange}
          {...attrs}
        />
      </div>
    </Tooltip>
  );
};

export const InputCitys = ({
  partidas,
  tag,
  size,
  setValor,
  mendigo,
  city,
  setCity,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_city,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setCity(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: city, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputTypeZones = ({
  partidas,
  tag,
  size,
  setValor,
  valor,
  zones,
  setTypeZones,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_typeFreeZone,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setTypeZones(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: zones, value: valor }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputGroupZones = ({
  tag,
  size,
  valor,
  setValor,
  partidas,
  groupzones,
  setGroupZones,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_groupFreeZone,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setGroupZones(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: groupzones, value: valor }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputDeparture = ({
  tag,
  size,
  mendigo,
  setValor,
  partidas,
  departament,
  setDepartament,
  Name,
  ...rest
}) => {
  const location = useLocation(); // navigate
  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );

  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: `${element?.code_department} ${element?.name_department}`,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setDepartament(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: departament, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
        placeholder="Seleccionar"
        {...rest}
      />
    </div>
  );
};

export const InputSectores = ({
  partidas,
  tag,
  size,
  setValor,
  mendigo,
  sector,
  setSector,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_sector,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setSector(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: sector, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputZonasF = ({
  partidas,
  tag,
  size,
  setValor,
  mendigo,
  zonas,
  setZonas,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_freeZone,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setZonas(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: zonas, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
        isClearable={true}
      />
    </div>
  );
};

export const InputActivity = ({
  tag,
  size,
  mendigo,
  setValor,
  partidas,
  activity,
  setActivity,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_activity,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setActivity(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: activity, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputClasificacion = ({
  tag,
  size,
  mendigo,
  partidas,
  setValor,
  clasification,
  setClasification,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_qualificationQualifiedUser,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items?.value);
    setClasification(items?.label);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: clasification, value: mendigo }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

const InputSelect = ({
  tag,
  name,
  data,
  set = null,
  value = null,
  size,
  className,
  error,
  isDisabled,
  labelDisable = false,
  onBlur,
  Name,
  ButtonSide = false,
  required = false,
  onChange = () => { },
  customColorLabel = null,
  cleanCallBack = () => { },
  saveArray = false,
  TagChildren,
  isClearable = true,
  menuPosition = "fixed",
  ...attrs
}) => {
  const [search, setSearch] = useState("");
  const handleSelectChange = useCallback((selectedOption, actionMeta) => {
    if (actionMeta.action === 'clear') {
      setSearch("");
      cleanCallBack();
    }
    console.log({ Meta: actionMeta.action });

    if (saveArray && set) {
      set(selectedOption ? [selectedOption] : null);
    } else if (set) {
      set(selectedOption);
    }

    onChange(selectedOption, actionMeta);
  }, [set, saveArray, onChange, cleanCallBack]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : provided?.borderColor,
      borderTopRightRadius: ButtonSide && "0",
      borderBottomRightRadius: ButtonSide && "0",
      borderRight: ButtonSide && "0",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: error ? "red" : provided.color,
    }),
  };

  return (
    <div style={{ width: `${size}%` }}>
      {(isString(tag) || isNumber(tag)) &&
        !labelDisable &&
        isNonEmptyString(tag) ? (
        <div className="d-flex justify-center align-items-center gap-1">
          <LabelStyled
            style={customColorLabel && { color: customColorLabel }}
            error={error}
            htmlFor={name}
          >
            {tag}
            {required && (
              <span style={{ color: "red", fontSize: "12px" }}>*</span>
            )}
          </LabelStyled>
          {TagChildren}
        </div>
      ) : null}
      <AsyncSelect
        classNamePrefix="required"
        id={name}
        name={name}
        value={value}
        isClearable={isClearable}
        options={data}
        onChange={handleSelectChange}
        size={size}
        className={className}
        placeholder="Seleccionar"
        error={error}
        styles={customStyles}
        isDisabled={isDisabled}
        onBlur={onBlur || null}
        required={required}
        menuPosition={menuPosition}
        inputValue={search}
        {...attrs}
        onInputChange={attrs?.onInputChange ? (e) => {
          setSearch(e);
          attrs?.onInputChange(e);
        } : setSearch}

      />
    </div>
  );
};

export default InputSelect;
// /////////////////////////////////////////
export const InputSelectMulti = ({
  data,
  tag,
  set,
  value,
  labelDisable = false,
  isDisabled = false,
  isLoading,
  ...props
}) => {
  const handleSelectChange = (items) => {
    set(items);
  };
  return (
    <div {...props}>
      {labelDisable ? <></> : <LabelStyled>{tag}</LabelStyled>}
      <Select
        isDisabled={isDisabled}
        closeMenuOnSelect={false}
        value={value}
        isMulti
        placeholder="Seleccionar"
        options={
          isLoading === null || isLoading === undefined || isLoading === false
            ? data
            : []
        }
        onChange={(items) => {
          handleSelectChange(items);
        }}
        isLoading={isLoading}
        styles={colourStyles}
        menuPosition="fixed"
      />
    </div>
  );
};
export const InputSelectMultiZindex = ({
  data,
  tag,
  set,
  value,
  className,
  ...props
}) => {
  const handleSelectChange = (items) => {
    set(items);
  };
  return (
    <div className={className || "z-index"} {...props}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        value={value}
        isMulti
        placeholder="Seleccionar"
        options={data}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputSelectNew = ({
  tag,
  name,
  data,
  size,
  value,
  className,
  ...props
}) => {
  return (
    <>
      <div style={{ width: `${size}%` }}>
        <LabeFormlStyled htmlFor={name}>{tag}</LabeFormlStyled>
        <AsyncSelect
          id={name}
          name={name}
          value={value}
          options={data}
          // onChange={(item) => handleSelectChange(item)}
          size={size}
          className={className}
          placeholder="Seleccionar"
          {...props}
        />
      </div>
    </>
  );
};

export const InputSelectZindex = ({
  tag,
  name,
  data,
  set,
  value,
  size,
  ...props
}) => {
  const handleSelectChange = (item) => {
    set(item);
  };

  return (
    <>
      <div style={{ width: `${size}%` }}>
        <LabeFormlStyled htmlFor={name}>{tag}</LabeFormlStyled>
        <AsyncSelect
          id={name}
          name={name}
          value={value}
          options={data}
          onChange={(item) => handleSelectChange(item)}
          className="z-index"
          placeholder="Seleccionar"
          {...props}
        />
      </div>
    </>
  );
};

export const Nacionalidad = ({ data, tag, size, setMendigo, mendigo }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.label,
        value: element?.value,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setMendigo(items?.value);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: mendigo, value: mendigo }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
        placeholder="Seleccionar"
      />
    </div>
  );
};

export const AddOperation = ({ data, tag, size, setMendigo, mendigo }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.code_operation + " " + element?.short_name_operation,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setMendigo(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        className="z-index-2"
        value={{ label: mendigo.label, value: mendigo.value }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
        placeholder="Seleccionar"
      />
    </div>
  );
};

export const Country = ({
  paises,
  tag,
  size,
  setMendigo,
  mendigo,
  code,
  setCode,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_country,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const codeCountry = paises.find(
      (element) => element?.name_country === items?.label
    );
    setCode(codeCountry?.code_country);
    setMendigo(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        // className="z-index"
        value={{ label: mendigo.label, value: mendigo.value }}
        options={selectValor(paises)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const SelectMethodTransport = ({
  tag,
  size,
  data,
  valor,
  etiqueta,
  setValor,
  setEtiqueta,
  error,
  Name,
  onChange = () => { },
  ...props
}) => {
  const location = useLocation(); // navigate
  const [search, setSearch] = useState("");
  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );

  const options = useMemo(() => data.map((item) => ({ label: item?.code_transportMethod + " " + item?.name_transportMethod, value: item?.id, ...item })), [data]);

  const handleSelectChange = (items, action) => {
    if (action.action === 'clear') {
      setSearch("")
    }
    setValor(items?.value);
    setEtiqueta(items?.label);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : provided?.borderColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: error ? "red" : provided.color,
    }),
  };

  const handleAndNotifySelectChange = (...attrs) => {
    handleSelectChange(...attrs);
    onChange(...attrs);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled error={error}>{tag}</LabelStyled>
      <AsyncSelect
        error={error}
        styles={customStyles}
        value={{ label: etiqueta, value: valor }}
        options={options}
        isClearable={true}
        onChange={handleAndNotifySelectChange}
        onInputChange={setSearch}
        inputValue={search}
        {...props}
      />
    </div>
  );
};

export const SelectPackaging = ({
  data,
  tag,
  size,
  packagings,
  setPackagings,
  error,
  onChange = () => { },
  ...attrs
}) => {
  const location = useLocation(); // navigate
  const [search, setSearch] = useState("");

  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );

  const options = useMemo(() => {
    return data.map((element) => ({
      label: element?.code_packaging + " " + element?.name_packaging,
      value: element?.id,
    }))
  }, [data]);

  const handleSelectChange = (items, action) => {
    if (action.action === 'clear') {
      setSearch("")
    }
    setPackagings(items);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : provided?.borderColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: error ? "red" : provided.color,
    }),
  };

  const handleAndNotifySelectChange = (...attrs) => {
    handleSelectChange(...attrs);
    onChange(...attrs);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled error={error}>{tag}</LabelStyled>
      <AsyncSelect
        styles={customStyles}
        error={error}
        value={{ label: packagings.label, value: packagings.value }}
        options={options}
        isClearable={true}
        onChange={handleAndNotifySelectChange}
        onInputChange={setSearch}
        inputValue={search}
        {...attrs}
      />
    </div>
  );
};

export const AcuerdoSub = ({ data, tag, size, setMendigo, mendigo }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.code_subheading,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setMendigo(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        // className="z-index"
        value={{ label: mendigo.label, value: mendigo.value }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};
// /////////////////////////////////////////
export const AcuerdoC = ({
  data,
  tag,
  size,
  setAcuerdos = () => { },
  Acuerdos = undefined,
  Name,
  error,
  onChange = () => { },
  ...attrs
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label:
          element?.code_tradeAgreement + " " + element?.name_tradeAgreement,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setAcuerdos(items);
  };

  const handleAndNotifySelectChange = (...attrs) => {
    handleSelectChange(...attrs);
    onChange(...attrs);
  };

  const validationValue =
    isNumber(Acuerdos) || isArrayNotEmpty(Acuerdos) || isObject(Acuerdos);

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled error={error}>{tag}</LabelStyled>
      <AsyncSelect
        isClearable={true}
        // className="z-index"
        value={
          validationValue && { label: Acuerdos?.label, value: Acuerdos?.value }
        }
        options={selectValor(data)}
        styles={
          error
            ? {
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "grey" : "red",
              }),
            }
            : ""
        }
        onChange={handleAndNotifySelectChange}
        {...attrs}
      />
    </div>
  );
};
// /////////////////////////////////////////

export const UserOper = ({ data, tag, size, setMendigo, mendigo }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.label,
        value: element?.value,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setMendigo(items?.value);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: mendigo, value: mendigo }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const UserCaRoles = ({
  data,
  tag,
  size,
  setValor,
  ediciones,
  valor,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.nickname,
        value: element?.nickname,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const usuariosSelect = [];
    for (let i = 0; i < items.length; i++) {
      const datos = data.find((info) => info?.nickname === items[i]?.value);
      const existence = valor.find((element) => element === datos?.id);
      if (!existence) {
        usuariosSelect.push(datos?.id);
      }
    }
    setValor(usuariosSelect);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={false}
        isMulti
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const ZonasFranca = ({ data, tag, size, setZona, zona, decision }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.label,
        value: element?.value,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setZona(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        isDisabled={!!decision}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputLicenseG = ({ partidas, tag, size, setValor, decision }) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_license,
        value: element?.name_license,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const licensesUser = [];
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find(
        (info) => info?.name_license === items[i]?.value
      );
      licensesUser.push(datos.id);
    }
    setValor(licensesUser);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        isDisabled={!!decision}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputPermisosG = ({
  partidas,
  values,
  setValue = null,
  tag,
  size,
  setValor,
  decision,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.description_permission,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    if (setValue) setValue(items);

    const licensesUser = [];
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find(
        (info) => info?.description_permission === items[i]?.value
      );
      licensesUser.push(datos.id);
    }

    setValor(licensesUser);
  };

  const propsOptionals = {
    ...(setValue && { value: values }),
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        {...propsOptionals}
        isDisabled={!!decision}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputRolesG = ({
  partidas,
  tag,
  size,
  setValor,
  decision,
  mendigo,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_rol,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        isDisabled={!!decision}
        value={{ label: mendigo.label, value: mendigo.value }}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputEmpresaRev = ({
  partidas,
  tag,
  size,
  setValor,
  ediciones,
  decision,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_qualified,
        value: element?.name_qualified,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const licensesUser = [];
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find(
        (info) => info?.name_qualified === items[i]?.value
      );
      licensesUser.push(datos.id);
    }
    if (ediciones) {
      setValor((prev) => [...prev, parseInt(licensesUser)]);
    } else {
      setValor(licensesUser);
    }
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        isDisabled={!!decision}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputLicenciaUnit = ({
  partidas,
  tag,
  size,
  setValor,
  decision,
  mendigo,
  children,
  required,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_license,
        value: element?.license_id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setValor(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>
        {tag}
        {required && <span style={{ color: "red", fontSize: "12px" }}>*</span>}
      </LabelStyled>
      <Select
        isDisabled={!!decision}
        value={mendigo}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
      <LabelStyledLink>{children}</LabelStyledLink>
    </div>
  );
};

export const InputZonasFUser = ({
  partidas,
  tag,
  size,
  setZonasF,
  ediciones,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_freeZone,
        value: element?.name_freeZone,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const licensesUser = [];
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find(
        (info) => info.name_freeZone === items[i]?.value
      );
      licensesUser.push(datos.id);
    }
    if (ediciones) {
      setZonasF((prev) => [...prev, parseInt(licensesUser)]);
    } else {
      setZonasF(licensesUser);
    }
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const RolesAcountUse = ({
  data,
  tag,
  size,
  setValor,
  valor,
  decision,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_rol,
        value: element?.name_rol,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    const rolSelect = [];
    for (let i = 0; i < items.length; i++) {
      const datos = data.find((info) => info?.name_rol === items[i]?.value);
      const existence = valor.find((element) => element === datos.id);
      if (!existence) {
        rolSelect.push(datos.id);
      }
    }
    // console.log(rolSelect);
    setValor(rolSelect);
  };

  return (
    <div style={{ width: `${size}%` }}>
      {/* {console.log(valor)} */}
      <LabelStyled>{tag}</LabelStyled>
      <Select
        isDisabled={!!decision}
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={false}
        isMulti
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const TypeTranspor = ({
  data,
  tag,
  size,
  setMendigo,
  mendigo,
  estado,
}) => {
  const selectValor = (optionesVa = []) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_typeTransport,
        value: element?.id,
      });
    });
    return valores;
  };

  const handleSelectChange = (items) => {
    setMendigo(items);
  };

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        // className="z-index"
        value={{ label: mendigo?.label, value: mendigo?.value }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
        isDisabled={!!estado}
        placeholder="Seleccionar"
      />
    </div>
  );
};
