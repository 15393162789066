import styled from "styled-components";

export const DivCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));


  /* Media Queries */
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
`;

export const DivGrid = styled.div`
  display: grid;

  box-sizing: border-box;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => (props.Size ? props.Size : "18rem")}, auto)
  );
  height: 15rem;
  overflow: auto;
  gap: 10px;
  width: 100%;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  margin: 0.5rem 0.5rem;
  padding: 0.3rem;
  border-radius: 5px;
`;

export const separator = styled.hr``;
