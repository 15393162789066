import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { renderInputWithLabel } from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsEntryForm";

import React from "react";
import { useLocation } from "react-router-dom";

export const RestOfTheWorldDisabled = ({
  form,
  notations = null,
  activeReview = true,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data: form,
  };
  return (
    <>
      <div className="col-md-12">
        {/* Factura Comercial */}
        {renderInputWithLabel({
          labelText: "Factura Comercial",
          name: "commercial_invoice",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          wrapInDiv: false,
          additionalProps: {
            background: "#F9F9F9",
          },
          disableFormatting: true,
        })}

        {/* Administración de Aduanas */}
        {form.code_operation >= 201 &&
          form.code_operation <= 299 &&
          form.check_duta &&
          renderInputWithLabel({
            labelText: "Administración de Aduanas",
            name: "commercial_invoice",
            activeComment: isCheckFormExit || isCheckFormEntry,
            manualValue: `${form?.custom_code ?? ""} ${
              form?.custom_name ?? "N/A"
            }`,
            annotationInfo,
            wrapInDiv: false,
            additionalProps: {
              background: "#F9F9F9",
            },
          })}
      </div>
    </>
  );
};
