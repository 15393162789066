import React, { useState, useContext, useEffect, useCallback } from "react";
import { Flex, Spacer } from "@chakra-ui/react";

import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import { useForm } from "hooks/useForm";

import { AuthContext } from "context/AuthContext";

import { __Arancel } from "request/productoInterno/__ProductoInterno";
import "../../RefProductInter/selecr.css";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import useDebounceText from "hooks/useSearchDebounce";
import { __ObtenerSubHeadingCode } from "request/CustomsClearance.js/__CustomsClearence";

import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import {
  __SelectUnidad,
  __SelectUnidades
} from "request/configuration/_AUnidad";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";
import { InputGroup, LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Cancelar, Continuar, FormInput } from "pages/RefProductInter/Stilos";
import InputSelect from "components/InputSelect/InputSelect";
import { __GetDataNoPage } from "request/Petitions/__Get";
import { MaxLength } from "utils/MaxLength";

const FormAddNewProduct = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [unidad, setUnidad] = useState("");
  const [arancel, setArancel] = useState("");
  const [bussines, setBussines] = useState("");
  const [unidadCo, setUnidadCo] = useState([]);
  const [unidadUC, setUnidadUC] = useState([]);
  const [status, setStatus] = useState(1);
  const [fomateo] = useFormatQuantity();
  const [allTypeProducts, setAllTypeProducts] = useState([]);
  const [typeProducts, setTypeProducts] = useState(null);
  const [loadingTypeProduct, setLoadingTypeProduct] = useState(false)

  const {
    createDataProduct,
    setRequestCreateProduct,
    onCloseAddNewProduct,
    userQualified
  } = useContext(IntegracionBoxCrudContex);

  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [openAlert] = AlertErrorAlert();
  const [subheadingSearch, setSubheadingSearch] = useDebounceText();

  const [values, handleInputChange, reset, setValues] = useForm({
    qualifiedUser_id: userQualified || 0,
    code_product: "",
    mendigo: "",
    unidad: "",
    description_product: "",
    group_product: "",
    convertion_factor_product: ""
  });
  const {
    code_product,
    description_product,
    group_product,
    convertion_factor_product
  } = values;

  useEffect(() => {
    setRequestCreateProduct(environment.CREATE_PRODUCT_INT);
  }, [setRequestCreateProduct]);

  const handleClose = () => onCloseAddNewProduct();

  const unidadID = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __SelectUnidad(token, userQualified.value);
      setUnidadCo(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token, userQualified]);

  useEffect(() => {
    if (userQualified) unidadID();
  }, [userQualified, unidadID]);

  const UnidadM = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __SelectUnidades(token);
      setUnidadUC(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    // userC();
    UnidadM();
  }, [UnidadM]);

  const addProduc = () => {
    const informacion = {
      qualifiedUser_id:
        authUser.admin === 1 || authUser.is_commerce === 1
          ? userQualified.value
          : authUser.userQualified,
      code_product,
      subheading_id: bussines,
      businessUnit_id: unidad.value,
      code_subheading: subheadingSearch,
      convertion_factor_product: convertion_factor_product.includes(",")
        ? convertion_factor_product.toString().replaceAll(",", "")
        : convertion_factor_product,
      description_product,
      group_product,
      status_product: status,
      typeProduct_id: typeProducts?.value
    };

    const informacionC = {
      code_product,
      subheading_id: bussines,
      businessUnit_id: unidad.value,
      code_subheading: subheadingSearch,
      convertion_factor_product: convertion_factor_product.includes(",")
        ? convertion_factor_product.toString().replaceAll(",", "")
        : convertion_factor_product,
      description_product,
      group_product,
      status_product: status,
      typeProduct_id: typeProducts?.value
    };

    if (!typeProducts?.value) return openAlert('El tipo de producto es necesario', 'error');

    if (
      code_product === "" ||
      subheadingSearch === "" ||
      description_product === ""
    ) {
      for (const [key, value] of Object.entries(informacion)) {
        if (value === "") {
          switch (key) {
            case "code_product":
              openAlert("El codigo es necesario", "error");
              return;
            case "subheading_id":
              openAlert("La Subpartida es necesario", "error");
              return;
            case "businessUnit_id":
              openAlert("La Unidad de Producto es necesario", "error");
              console.log("hola");
              return;
            case "description_product":
              openAlert("La Descripcion es necesario", "error");
              return;
            default:
              return;
          }
        }
      }
    } else {
      if (authUser.admin === 1 || authUser.is_commerce === 1) {
        createDataProduct(informacion);
        onCloseAddNewProduct();
        reset();
      } else {
        createDataProduct(informacionC);
        onCloseAddNewProduct();
        reset();
      }
    }
  };

  const fetch = useCallback(
    async (subpartida) => {
      try {
        const res = await __Arancel(token, subpartida);
        setArancel(res?.data?.status?.data.code_businessUnit);
        setBussines(res?.data?.status?.data.id);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const obtenerSubpartida = useCallback(
    async (info) => {
      try {
        const res = await __ObtenerSubHeadingCode(token, info);
        const resultado = res?.data?.status;
        if (resultado.code === 403) {
          openAlert(resultado.message, "error");
          setArancel("");
          setBussines("");
        } else {
          fetch(resultado.data.code_subheading);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [openAlert, token, fetch]
  );

  useEffect(() => {
    if (subheadingSearch && subheadingSearch.length > 9) {
      const formData = new FormData();
      formData.append("code_subheading", subheadingSearch);
      obtenerSubpartida(formData);
    }
  }, [subheadingSearch, obtenerSubpartida]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;

    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && userQualified
            ? userQualified.decimal
            : Number(decimal_values_precision),
          posibleResult
        )
      });
    }
  };

  const getTypeProducts = async () => {
    setLoadingTypeProduct(true)
    try {
      const res = await __GetDataNoPage(environment.PRODUCTS_ALL, token);
      const status = res?.data?.status;
      if (status?.code === 200) {
        setAllTypeProducts(status?.data?.map((item) => ({
          value: item?.id,
          label: item?.name_typeProduct
        })));
        console.log("typeProducts: ", allTypeProducts)
      }
      setLoadingTypeProduct(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTypeProducts()
  }, []);

  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <form className="mb-5">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem"
              }}
            >
              <LabelGroup
                name={"code_product"}
                onChange={handleInputChange}
                value={code_product}
                tag={"Código"}
                size={45}
                desicion={false}
              />

              <LabelGroup
                name={"group_product"}
                tag={"Grupo"}
                size={45}
                value={group_product}
                onChange={handleInputChange}
                desicion={false}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem"
              }}
            >
              <LabelGroup
                name={"subheadingSearch"}
                onChange={(e) => {
                  setSubheadingSearch(e.target.value);
                }}
                value={subheadingSearch}
                tag={"Subpartida"}
                size={45}
                desicion={false}
                tipo={"search"}
                maxLength={MaxLength?.global?.code_subheading}
              />

              <LabelGroup
                name={"arancel"}
                value={arancel}
                tag={"Unidad de arancel"}
                size={45}
                desicion={true}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem"
              }}
            >
              {authUser.admin === 1 || authUser.is_commerce === 1
                ? (
                  <InputGroup
                    partidas={unidadCo}
                    tag={"Unidad de producto"}
                    size={45}
                    setMendigo={setUnidad}
                    unidad={unidad}
                  />
                )
                : (
                  <InputGroup
                    partidas={unidadUC}
                    tag={"Unidad de producto"}
                    size={45}
                    setMendigo={setUnidad}
                    unidad={unidad}
                  />
                )}

              <LabelGroup
                name={"convertion_factor_product"}
                onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
                value={convertion_factor_product}
                tag={"Factor de conversión"}
                size={45}
                onBlur={handleBlurCant}
                desicion={
                  !!((authUser.admin === 1 || authUser.is_commerce === 1) &&
                    !userQualified)
                }
              />
            </div>

            <div style={{ width: '45%' }}>
              <InputSelect
                tag={'Tipo de producto'}
                data={allTypeProducts}
                set={setTypeProducts}
                value={typeProducts}
                isLoading={loadingTypeProduct}
                className="mb-3"
              />
            </div>

            <div>
              <LabelStyled>Descripción</LabelStyled>
              <FormInput
                name="description_product"
                value={description_product}
                onChange={handleInputChange}
                type="text"
              />
            </div>

          </form>
          <Flex>
            <Cancelar onClick={() => handleClose()}>Cancelar</Cancelar>
            <Spacer />
            <Continuar onClick={addProduc}>Agregar producto</Continuar>
          </Flex>
        </>
      )}
    </>
  );
};

export default FormAddNewProduct;
