import React, { useEffect, useState, useContext } from "react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import { AuthContext } from "context/AuthContext";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { theadInventoryAudit } from "./Array/ArrayInventoryControl";
import TbodyAudit from "./TbodyTable/TbodyAudit";
import {
  __ExportExcel,
  __GetInventoryAudit
} from "request/Inventory/__InventoryCRUD";
import { useModal } from "hooks/useModal";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";

import InventoryControlContext from "context/InventoryControlContext";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormSearchAudit from "./SearchTabInventory/FormSearchAudit";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import environment from "constants/apiConst";
import { clearSearch, getSearch, setSearch } from "redux/actions/searchActions";
import { useDispatch } from "react-redux";
import { isArray } from "utils/type-check-utils";

export const InventoryAudit = () => {
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  // const { update } = useContext(InventoryControlContext);

  // hook de los Modal
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(null);
  const [valueQualified, setValueQuialified] = useState({});
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [nameQualified, setNameQualified] = useState(null);
  const [dataInventory, setDataInventory] = useState([]);

  const [values, handleInputChange, reset] = useForm({
    searchInformacion: ""
  });

  const { searchInformacion } = values;

  const {
    setValueOperation,
    setValueTypeP,
    setValueTypeO,
    setValueDescription,
  } = useContext(InventoryControlContext);

  const backSearch = () => {
    reset();
    setSearchAdvanced([]);
    dispacthRedux(clearSearch());
    setValueQuialified({});
    setChangeViewSearch(false);
    // setPositionView(0);
    setCurrentPage([]);
    setNameQualified(null);
    setValueOperation([]);
    setValueTypeP([]);
    setValueTypeO([]);
    setValueDescription([]);
  };
  const search = useSelector(getSearch);

  const handleSubmit = (data, fields) => {
    const info = {
      ...search,
    };

    exportColumnBy(info);
  };

  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_INVENTORY_AUDIT
      );
      if (res.status === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");

    } catch (error) {
      console.log(error);
    }
  };

  const GetInventory = async (body) => {
    setLoading(true);
    try {
      const res = await __GetInventoryAudit(token, page, body);

      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (res?.data?.status?.code === 200) {
        setDataInventory(res?.data?.status?.data);
        setCategory(res?.data?.status?.data.category);
        setNameQualified(res?.data?.status?.data.qualified_name);
        setCurrentPage(res?.data?.status?.data.inventory?.data);
        setMaxPage(res?.data?.status?.data?.inventory?.last_page);
      }

    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const dispacthRedux = useDispatch();
  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && {
        qualifiedUser_id: valueQualified.value,
      }),
    };

    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      GetInventory(data);
      dispacthRedux(setSearch(data));
    }
  }, [searchInformacion, searcheAdvanced, page, valueQualified]);


  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, [])

  useEffect(() => {
    console.log({ searchInformacion, searcheAdvanced, valueQualified })
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormSearchAudit
          onClose={onCloseSearch}
          handleSearchSubmit={setSearchAdvanced}
          category={category}
          valueQualified={valueQualified}
          setValueQualified={setValueQuialified}
          nameQualified={nameQualified}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Auditoría de inventario</Title>
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          setValueQuialified={setValueQuialified}
          valueQualified={valueQualified}
          permisoSearch={"getInventoryForAudith"}
          allAccess={allAccess}
          myPermission={myPermission}
        />

        {allAccess
          ? (
            <ExportDetail onOpen={handleSubmit} />
          )
          : myPermission?.includes("exportExcelInventoryAudit")
            ? (
              <ExportDetail onOpen={handleSubmit} />
            )
            : null}

        {currentPage.length > 0 && (
          <div
            className="d-flex ms-2 mb-3 justify-content-center"
            id="datosFiltro"
          >
            <div>
              <h1
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "Roboto"
                }}
              >
                Nº Total de ítems del inventario:
                <span className="ms-1">
                  {dataInventory?.quantity_inventory}
                </span>
              </h1>
            </div>

            <div className="ms-4">
              <h1
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "Roboto"
                }}
              >
                Tamaño de la muestra a inventariar:
                <span className="ms-1">{dataInventory?.sample_size}</span>
              </h1>
            </div>

            <div className=" ms-4">
              <h1
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "Roboto"
                }}
              >
                Tamaño de inventario filtrado:
                <span className="ms-1">{dataInventory?.filter_size}</span>
              </h1>
            </div>
          </div>
        )}

        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadInventoryAudit}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyAudit}
            />

            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
