import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";

const TbodyPermisos = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.name_permission}</Td>
      <Td className="text-center">{data?.description_permission}</Td>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyPermisos;
