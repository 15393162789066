export const theadWeighing = [
  { name: "Nit", order: "nit_qualified" },
  { name: "Empresa", order: "name_qualified" },
  { name: "Consecutivo", order: "consecutive" },
  { name: "Fecha", order: "weight_date" },
  { name: "Placa", order: "vehicle_plate" },
  { name: "Horario", order: null },
  { name: "Operación", order: "operation" },
  { name: "Descripción", order: null },
  { name: "Peso neto", order: "net_weight" },
  { name: "Peso bruto", order: "gross_weight" },
  { name: "Operario. R. Bruto", order: "gross_user_name" },
  { name: "Bascula bruto", order: "gross_scale_name" },
  { name: "Tipo bruto", order: "type_gross" },
  { name: "Peso tara", order: "tare_weight" },
  { name: "Operario. R. Tara", order: "tare_user_name" },
  { name: "Tipo tara", order: "type_tare" },
  { name: "Bascula tara", order: "tare_scale_name" },
  { name: "Completitud", order: null },
  { name: "Contenedores", order: null },
  { name: "Estado", order: "status" },
  { name: "Nº Ingreso/Salida", order: "passForm_number" },
  { name: "Nº Formulario", order: "passForm_number" },
  { name: "Acciones", order: "" }
];

export const theadReports = [
  { name: "Fecha de ingreso", order: "null" },
  { name: "Fecha pesaje", order: "null" },
  { name: "Placa Vehículo", order: "null" },
  { name: "Nombre conductor", order: "null" },
  { name: "Cédula conductor", order: "null" },
  { name: "Celular conductor", order: "null" },
  { name: "Usuario Calificado que ingresó vehículo", order: "null" },
];

export const theadReport = [
  { name: "Nº. Formulario", order: "" },
  { name: "Tiquete de báscula", order: "consecutive" },
  { name: "Empresa", order: "" },
  // { name: "Turno", order: "" },
  // { name: "Báscula", order: "" },
  { name: "Placa", order: "vehicle_plate" },
  { name: "Conductor", order: "driver" },
  { name: "Identificación", order: "identification" },
  { name: "Nº Contenedores", order: null },
  { name: "Camión vacío", order: "empty_pass" },
  { name: "Libre disposición", order: "freely_available" },
  { name: "Pase libre", order: "free_pass" },
  { name: "Manipuleo", order: "handling_process" },
  { name: "Acciónes", order: "null" }
];

export const theadContainer = [
  { name: "Contenedor", order: "" },
  { name: "Capacidad", order: "" },
  { name: "tara", order: "" }
];
