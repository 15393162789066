import React from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { AiOutlinePercentage, AiOutlinePlusCircle } from "react-icons/ai";
import { MdSell } from "react-icons/md";
import { useSelector } from "react-redux";

const Buttons = ({ onOpenRecharge, onOpenPay, onOpenPercentage }) => {
  const dataUser = useSelector((state) => state.auth.dataUser);
  return (
    <div className="row">
      <div className="col-4 ">
        {/* <Text className="ms-2" fontSize="xl" style={{ fontWeight: 600 }}>
          Todos los Usuarios
        </Text> */}
      </div>
      <div className="col-4 "></div>
      <div className="col-4">
        <div className="d-flex float-end ">
          {dataUser?.user_rol?.modules?.some(item => item?.permission?.some(name => name?.name_permission === "createTaxesRecharge")) && (
            <Tooltip placement="top" hasArrow label="Gestión del IVA">
              <span>
                <ButtonHeaderModule
                  className="mx-1 d-flex float-end"
                  bgColor="#F9A621"
                  onClick={onOpenPercentage}
                >
                  <IconGeneric color="#fff" as={AiOutlinePercentage} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          )}
          <Tooltip placement="top" hasArrow label="Recarga en linea">
            <span>
              <ButtonHeaderModule
                className="mx-1 d-flex float-end"
                bgColor="#F9A621"
                onClick={onOpenPay}
              >
                <IconGeneric color="#fff" as={AiOutlinePlusCircle} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Venta de recarga">
            <span>
              <ButtonHeaderModule
                onClick={onOpenRecharge}
                bgColor="#03C39A"
                className="d-flex float-end"
              >
                <IconGeneric color="#fff" as={MdSell} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Buttons;
