/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import InputSelect, { AcuerdoC } from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { __SelectPaises } from "request/configuration/_Country";
import { __TransportMethodNotPaginate } from "request/configuration/__TransportMethod";
import { __Packaging_All } from "request/configuration/__Packaging";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Radio,
  SimpleGrid,
  Spacer,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import {
  __GetReferenceOutputForm,
  __ProductsUserAll,
} from "request/configuration/__ProductProcess";
import { __Trm } from "request/configuration/__Trm";
import FormCrudCRUDContext from "context/FormCrudContex";
import environment from "constants/apiConst";
import { __ShowItems } from "request/Forms/_CrudForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { LabeFormlStyled } from "styled/Input.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "context/AuthContext";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { SearchEditedFields } from "hooks/useLocalStorage";
import { TbFileInvoice } from "react-icons/tb";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { __GetDataId } from "request/Petitions/__Get";
import { Cancelar } from "pages/RefProductInter/Stilos";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { getFormData } from "redux/actions/dataFormActions";
import { useNotations } from "context/NotationsContext";
import SearchReference from "pages/IntegrationBox/Form/SearchReference";
import { __Acuerdos } from "request/configuration/__ASubheading";
import {
  isArray,
  isNumber,
  isNumberFormatable,
  isString,
} from "utils/type-check-utils";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ConvertPesosToDollars,
  removeDecimalIfZero,
} from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/AddItemForm";
import { RadioBtnRedonder } from "components/ComponentRadioButton/RadioBtnRedonder";
import { Regex } from "utils/Regex";
import { isValid, parseFloat } from "utils/parseFloat";
import queryEnv from "constants/queryConst";
import axios from "axios";

const errorsDefaultState = {
  product_id: false,
  product_quantity: false,
  typeProduct_id: false,
  countryDestiny_id: false,
  countryOrigin_id: false,
  countryPurchase_id: false,
  transportMethod_id: false,
  flag_id: false,
  packaging_id: false,
  gross_weight: false,
  us_freight: false,
};

export const FormUploaderItem = ({
  idUserCalificado,
  set,
  itemss,
  values,
  handleInputChange,
  reset,
  setValues,
  selectItemForm,
  valuesHeader,
  valueQualified,
  refund,
}) => {
  const { token } = useSelector((state) => state.auth.dataUser);
  const { authUser } = useContext(AuthContext);
  const [errorsState, setErrorsState] = useState(errorsDefaultState);

  // estados de los select
  const [refProduct, setRefProduct] = useState(null);
  const [countryOrigin, setCountryOrigin] = useState(null);
  const [countryPurchase, setCountryPurchase] = useState(null);
  const [countryDestination, setCountryDestination] = useState(null);
  const [methodTransport, setMethodTransport] = useState(null);
  const [flag, setflag] = useState(null);
  const [packagings, setPackagings] = useState(null);
  const [dataProducts, setDataProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [fomateo] = useFormatQuantity();
  const [trm, setTrm] = useState(0);
  const dataCoin = ["USD", "COP"];
  const location = useLocation(); // navigate

  const { FormExitNumber = null } = queryString.parse(location.search);
  const formId = FormExitNumber;
  // data de los inputs select
  const [dataCountry, setDataCountry] = useState([]);
  const [dataTransportMethod, setDataTransportMethod] = useState([]);
  const [dataPackagings, setDataPackagings] = useState([]);

  const [loading, setLoading] = useState(false);

  const [suma, setSuma] = useState();
  const [multiplicacion, setMultiplicacion] = useState(0);
  const [subpartida, setSubpartida] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const [isOpenInvoice, onOpenInvoice, onCloseInvoice] = useModal();
  const [valueInvoice, setValueInvoice] = useState(null);
  const [brandValue, setBrandValue] = useState("");
  const [serialValue, setSerialValue] = useState("");
  const [invoiceValue, setInvoiceValue] = useState("");
  const [ValueFobOriginal, setValueFobOriginal] = useState(0);
  const [Calculated, setCalculated] = useState(null);

  function handleBrandValueChange(event, onFieldChange = () => {}) {
    const { value, name } = event.target;

    const newValue = { ...values, [name]: value };

    setBrandValue(event.target.value);
    onFieldChange(name, newValue[name], event?.target);
  }

  function handleSerialValueChange(event, onFieldChange = () => {}) {
    const { value, name } = event.target;

    const newValue = { ...values, [name]: value };

    setSerialValue(event.target.value);
    onFieldChange(name, newValue[name], event?.target);
  }

  function handleInvoiceValueChangue(event, onFieldChange = () => {}) {
    const { value, name } = event.target;

    const newValue = { ...values, [name]: value };

    setInvoiceValue(event.target.value);
    onFieldChange(name, newValue[name], event?.target);
  }
  // hook formulario

  const {
    product_code,
    factor_conv,
    product_unit,
    code_subheading,
    product_description,
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    quantity,
    number_packages,
    net_weight,
    gross_weight,
    product_quantity,
    code_businessUnit,
    name_businessUnit,
    reference_description,
    output_form_item_description,
    adjustment_justification_fob,
    added_value_us,
    refund_value_us,
    brand,
    serial,
    invoice,
    customs_clearance,
    operation_code,
    initial_operation,
    code_tradeAgreement,
    name_tradeAgreement,
    value_sdo,
    value_sdo_product,
    fob_sdo_value,
    cif_value_sdo,
    description_product,
    name_typeProduct,
  } = values || {};

  const {
    dataToEditItems,
    setRequestEditItem,
    setDataToEditItems,
    dataToCopyItems,
    setDataToCopyItems,
    setDataCountry120,
    setDataProcedencia,
    dataCountry120,
    ReviewsData,
  } = useContext(FormCrudCRUDContext);
  const [dataForm, setDataForm] = useState({});

  const { exchange_rate } = dataForm || {};

  useEffect(() => {
    getCountry(selectItemForm);
    getMethodTransport();
    getPackaging();
  }, []);

  useEffect(() => {
    getCountry(selectItemForm);
  }, [selectItemForm]);

  useEffect(() => {
    if (idUserCalificado) {
      getProductUserQualified();
    }
  }, [idUserCalificado]);

  const fieldAllowed = [
    "brand",
    "serial",
    "product_quantity",
    "name_typeProduct",
    "quantity",
    // "net_weight",
    "gross_weight",
    "number_packages",
    "code_businessUnit",
    "us_freight",
    "us_insurance",
    "other_us_expenses",
    "us_fob_value",
    "cif_us_value",
    "cif_cop_value",
    "exchange_rate",
    "invoice",
    "added_value_us",
    "refund_value_us",
    "name_country_flag",
    "name_country",
    "name_country_proc",
    "name_country_purs",
    "name_transportMethod",
  ];

  useEffect(() => {
    if (brand) setBrandValue(brand);
    if (serial) setSerialValue(serial);
    if (invoice) setInvoiceValue(invoice);
  }, [invoice, brand, serial]);

  const onFieldChange = (e) => {
    /* Para actualizar el comentario */
    const { oldValue, itemId, inputTag } = e?.target?.dataset;
    /* Para extraer los valores */
    const { name, value } = e?.target || {};
    const newValue = parseFloat(value);
    const Allowed = fieldAllowed.includes(name);
    if (Allowed) {
      updateComment(name, inputTag, newValue, oldValue, itemId);
    }
  };

  const handleBlurReferenc = (hasProductData, selectedOption) => {
    if (selectedOption) {
      setSubpartida(selectedOption?.subheading_id);
      setValues({
        ...values,
        ...selectedOption,
        factor_conv: selectedOption?.convertion_factor_product,
        product_unit: selectedOption?.code_unit_measurement,
        product_code: selectedOption?.code_product,
        code_businessUnit: selectedOption?.businnesUnitSubheading,
        output_form_item_description: selectedOption?.description_product,
        code_tradeAgreement: selectedOption?.trade_agreement_id
          ? selectedOption?.code_tradeAgreement
          : "",
        name_tradeAgreement: selectedOption?.trade_agreement_id
          ? selectedOption?.name_tradeAgreement
          : "",
        value_sdo: selectedOption?.value_sdo ? selectedOption?.value_sdo : "",
        value_sdo_product: selectedOption?.value_sdo_product
          ? selectedOption?.value_sdo_product
          : "",
        fob_sdo_value: selectedOption?.fob_sdo_value
          ? selectedOption?.fob_sdo_value
          : "",
        cif_value_sdo: selectedOption?.cif_value_sdo
          ? selectedOption?.cif_value_sdo
          : "",
        customs_clearance: selectedOption?.customs_clearance
          ? selectedOption?.customs_clearance
          : "",
      });

      setflag({
        ...(isString(selectedOption?.flag_name) && {
          label: `${selectedOption?.flag_code} ${selectedOption?.flag_name}`,
        }),
        ...(isNumber(selectedOption?.flag_id) && {
          value: selectedOption?.flag_id,
        }),
      });

      setPackagings({
        ...(isString(selectedOption?.name_packaging) && {
          label: `${selectedOption?.code_packaging} ${selectedOption?.name_packaging}`,
        }),
        ...(isNumber(selectedOption?.packaging_id) && {
          value: selectedOption?.packaging_id,
        }),
      });

      setMethodTransport({
        ...(isString(selectedOption?.name_transportMethod) && {
          label: `${selectedOption?.code_transportMethod || ""} ${
            selectedOption?.name_transportMethod
          }`,
        }),
        ...(isNumber(selectedOption?.transportMethod_id) && {
          value: selectedOption?.transportMethod_id,
        }),
      });

      /*   setCountryDestination({
        ...(isString(selectedOption?.origin_name) && { label: `${selectedOption?.destiny_code || ''} ${selectedOption?.destiny_name}` }),
        ...(isNumber(selectedOption?.destiny_id) && { value: selectedOption?.destiny_id} ),
       }); */

      setCountryOrigin({
        ...(isString(selectedOption?.origin_name) && {
          label: `${selectedOption?.origin_code} ${selectedOption?.origin_name}`,
        }),
        ...(isNumber(selectedOption?.origin_id) && {
          value: selectedOption?.origin_id,
        }),
      });

      setCountryPurchase({
        ...(isString(selectedOption?.purchase_name) && {
          label: `${selectedOption?.purchase_code} ${selectedOption?.purchase_name}`,
        }),
        ...(isNumber(selectedOption?.purchase_id) && {
          value: selectedOption?.purchase_id,
        }),
      });

      /*   if (refProduct === null) {
      setSubpartida(null);
      setValues({
        ...values,
        factor_conv: "",
        product_unit: "",
        code_subheading: "",
        code_businessUnit: "",
        name_businessUnit: "",
        customs_clearance: "",
        operation_code: "",
        initial_operation: "",
        product_code: "",
        code_tradeAgreement: "",
        name_tradeAgreement: "",
        value_sdo: "",
        value_sdo_product: "",
        fob_sdo_value: "",
        cif_value_sdo: "",
      });
      setCountryOrigin(null);
      setRefProduct(null);
      setCountryPurchase(null);
      setCountryDestination(null);
      setflag(null);
    } */
    }
  };

  function parseNumberWithCommas(value) {
    return Number(value?.toString().replaceAll(",", "") || 0);
  }

  /*   useEffect(() => {
      handleBlurReferenc();
    }, [refProduct]); */

  const dataFormRedux = useSelector(getFormData);

  useEffect(() => {
    const valueFob =
      parseFloat(values?.value_fob_unit_product) * parseNumberOrZero(quantity);
    if (isNumber(valueFob)) {
      setValueFobOriginal(valueFob);
    }
  }, [refProduct, allProducts, product_quantity]);

  function parseNumberOrZero(value) {
    const number = Number(value?.toString().replaceAll(",", ""));
    return isNaN(number) ? 0 : number;
  }

  useEffect(() => {
    const { us_freight, us_insurance, other_us_expenses, us_fob_value } =
      values;

    const sumaDetodo =
      parseNumberOrZero(us_freight) +
      parseNumberOrZero(us_insurance) +
      parseNumberOrZero(other_us_expenses) +
      parseNumberOrZero(us_fob_value);

    const formateadoSumas = fomateo(dataFormRedux?.precision, sumaDetodo);

    setSuma(formateadoSumas);

    const resultadoMultiplicacion =
      sumaDetodo * Number(trm?.toString()?.replaceAll(",", "")) || 0;

    const formateado = fomateo(
      dataFormRedux?.precision,
      resultadoMultiplicacion
    );

    setMultiplicacion(formateado);
    // console.log(trm);
  }, [values, suma, multiplicacion, trm, us_fob_value]);

  useEffect(() => {
    if (dataToEditItems) {
      setRequestEditItem(environment.UPDATE_ITEMS_EXIT);
      showItems(dataToEditItems.id);
      setLoading(true);
      getProductUserQualified(idUserCalificado);
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id);
      getProductUserQualified(idUserCalificado);
    } else {
      setValues(values);
      reset();
      resetSelect();
    }
  }, [dataToEditItems, dataToCopyItems]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      // setRefProduct(null);
      getProductUserQualified(selectItemForm);
    }
  }, [selectItemForm]);
  const firstRender = useRef(true);

  // peticiones de la informacion para los input select

  const showItems = async (idItems) => {
    try {
      const res = await __ShowItems(token, idItems, environment.SHOW_ITEM_EXIT);
      if (res?.data?.status.code === 200) {
        const response = res?.data?.status?.data;
        setValues({
          ...response,
          product_unit: response?.code_unit_measurement,
          product_code: response?.code_product,
          code_businessUnit: response?.code_businessUnit,
        });
        setTrm(response?.exchange_rate);

        const obtenerErrorItemCurrent = JSON.parse(
          localStorage.getItem(`erroField-${idItems}`) || "[]"
        );
        console.log(obtenerErrorItemCurrent);

        if (obtenerErrorItemCurrent.length !== 0) {
          console.log("obtenerErrorItemCurrent", obtenerErrorItemCurrent);
          setErrorsState(obtenerErrorItemCurrent);
        }

        setDataForm({
          ...response,
          exchange_rate: response?.exchange_rate,
          factor_conv: response?.factor_conv,
          product_unit: response?.code_unit_measurement,
          code_businessUnit: response?.code_businessUnit,
        });

        if (response.product_id) {
          setRefProduct({
            label: response?.reference_description,
            value: response?.product_id,
          });
        }

        if (response.countryOrigin_id) {
          setCountryOrigin({
            label: `${response.code_country} ${response.name_country}`,
            value: response.countryOrigin_id,
          });
        }
        if (response.countryPurchase_id) {
          setCountryPurchase({
            label: `
              ${response.code_country_purs} ${response.name_country_purs}`,
            value: response.countryPurchase_id,
          });
        }

        if (response.transportMethod_id) {
          setMethodTransport({
            label: `
            ${response.code_transportMethod} ${response.name_transportMethod}`,
            value: response.transportMethod_id,
          });
        }
        if (response.countryDestiny_id) {
          setCountryDestination({
            label: `${response.code_country_des} ${response.name_country_des}`,
            value: response.countryDestiny_id,
          });
        }

        if (response.flag_id) {
          setflag({
            label: `${response.code_country_flag} ${response.name_country_flag}`,
            value: response.flag_id,
          });
        }
        if (response.packaging_id) {
          setPackagings({
            label: `${response.code_packaging} ${response.name_packaging}`,
            value: response.packaging_id,
          });
        }
      } else {
        openAlert(res?.data?.status.message, "error");
        set(!itemss);
        setDataToEditItems(null);
        setDataToCopyItems(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      // set(!itemss);
    }
  };

  const getCountry = async (codeOperations) => {
    setLoading(true);
    try {
      const res = await __SelectPaises(token);
      const infoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_country + " " + item?.name_country,
        origin_country: item?.origin_country,
        code: item?.code_country,
      }));
      setDataCountry(infoCountry);

      if (!codeOperations) {
        setDataProcedencia(null);
        setDataCountry120(null);
        return;
      }
      const searchIZF = infoCountry.filter(({ code, origin_country }) => {
        if (codeOperations >= 401 && codeOperations <= 499) {
          return code === "169";
        }
        if (codeOperations >= 601 && codeOperations <= 699) {
          return origin_country === "IZ";
        }
        if (codeOperations >= 801 && codeOperations <= 899) {
          return code === "911";
        }
        return [];
      });

      setDataCountry120(searchIZF);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getMethodTransport = async () => {
    setLoading(true);
    try {
      const res = await __TransportMethodNotPaginate(token);
      const infoMethod = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_transportMethod + " " + item?.name_transportMethod,
      }));
      setDataTransportMethod(infoMethod);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPackaging = async () => {
    setLoading(true);
    try {
      const res = await __Packaging_All(token);
      const infoPackagin = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_packaging + " " + item?.name_packaging,
      }));
      setDataPackagings(infoPackagin);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProductUserQualified = async () => {
    setLoading(true);

    try {
      const body = new FormData();
      body.append("qualifiedUser_id", valueQualified);
      body.append("outputForm_id", FormExitNumber);
      body.append("operation_code", selectItemForm);

      const res = await __GetReferenceOutputForm(token, body);

      const infoProduct =
        isArray(res?.data?.status?.data) &&
        res?.data?.status?.data.map((item) => ({
          value: item?.inventory_reference,
          label: item?.inventory_reference,
        }));

      setDataProducts(infoProduct);
      setAllProducts(res?.data?.status?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const requestManagementReference = (data) => {
    setAllProducts(data?.data);
  };

  const getInvoice = async (e) => {
    e.preventDefault();

    try {
      const res = await __GetDataId(
        environment.GET_COMERCIAL_INVOICE_OUTPUT,
        token,
        FormExitNumber
      );
      const destruct = res.data.status;
      if (destruct.code === 200) {
        onOpenInvoice();
        console.log(destruct.data);

        setValueInvoice(destruct.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const queryClient = useQueryClient();

  const resUpdateItem = (res, callBack) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      callBack();
      queryClient.invalidateQueries({ queryKey: ["getItemsFormExit"] });
      queryClient.invalidateQueries({
        queryKey: ["formExitView"],
      });
      openAlert(`Registro actualizado Exitosamente`, "success");
      return;
    }
  };

  const updateItemPost = (payload, callBack = () => {}) => {
    const requestData = {
      data: payload,
      Endpoint: environment.UPDATE_ITEMS_EXIT,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(requestData, {
      onSuccess: (e) => resUpdateItem(e, callBack),
    });
  };

  const createItemPost = (payload, callBack = () => {}) => {
    const requestData = {
      data: payload,
      Endpoint: environment.CREATE_ITEM_FORM_EXIT,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(requestData, {
      onSuccess: (e) => resUpdateItem(e, callBack),
    });
  };

  const { data: dataTRM } = useQuery({
    queryKey: [queryEnv?.TRM],
    queryFn: async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };

      const res = await axios(`${environment.TRM_GET}`, options);
      if (res?.data?.status?.code === 200) {
        setTrm((prev) => (!prev ? res?.data?.status?.data.value_trm : prev));
        return {
          ...res?.data?.status,
          data: {
            ...res?.data?.status?.data,
            value_trm: parseFloat(res?.data?.status?.data.value_trm),
          },
        };
      }
      return res;
    },
  });

  useEffect(() => {
    console.log({ dataTRM });
  }, [dataTRM]);

  const handleCrear = (e) => {
    e.preventDefault();

    if (
      code_businessUnit?.toLowerCase() === "kg" &&
      (net_weight !== quantity || quantity !== net_weight)
    ) {
      return openAlert(
        'La Unidad del producto está establecida en "KG", lo que significa que el Peso Neto debe ser igual a la cantidad especificada.',
        "error"
      );
    }
    const quantityString = quantity.toString();
    const productQuantityString = product_quantity.toString();

    const resultadoProduc = productQuantityString.replaceAll(",", "");
    const validacionFactor = factor_conv ?? 0;
    const resultadoMultiplicacion =
      parseFloat(factor_conv) * parseFloat(product_quantity);
    const CurrentQuantity = quantityString?.replaceAll(",", "");

    if (parseFloat(gross_weight) < parseFloat(net_weight))
      return openAlert(
        "El Peso Bruto debe ser mayor o igual al peso Neto",
        "error"
      );

    if (
      removeDecimalIfZero(quantity, dataFormRedux?.precision) !==
      removeDecimalIfZero(resultadoMultiplicacion, dataFormRedux?.precision)
    ) {
      return openAlert(
        "La cantidad de producto y cantidad deben ser calculadas usando el factor de conversión.",
        "error"
      );
    }

    if (Number(number_packages) <= 0) {
      return openAlert(
        "El número de bultos debe ser diferente de cero.",
        "error"
      );
    }

    if (Number(resultadoProduc) <= 0) {
      return openAlert(
        "Cantidad de producto debe ser diferente de cero.",
        "error"
      );
    }

    if (Number(quantityString) <= 0) {
      return openAlert("Cantidad debe ser diferente de cero.", "error");
    }

    if (
      initial_operation === "CDI" &&
      (!added_value_us || added_value_us <= 0)
    ) {
      return openAlert("El campo Valor agregado US$ es requerido", "error");
    }

    if (refund === "Con reintegro") {
      if (!refund_value_us || refund_value_us == 0) {
        return openAlert(
          "El campo Valor a Reintegrar US$ es requerido",
          "error"
        );
      }
    }

    /*   if (!selectDataTypeProduct?.value) {
      return openAlert(
        "¡Es obligatorio seleccionar el Tipo de producto!",
        "error"
      );
    } */

    if (!countryOrigin?.value) {
      return openAlert("¡Es obligatorio seleccionar el País origen!", "error");
    }

    /*  if (!dataCountryOrigin?.value) {
      return openAlert(
        "¡Es obligatorio seleccionar el País procedencia!",
        "error"
      );
    } */

    if (!countryPurchase?.value) {
      return openAlert("¡Es obligatorio seleccionar el País compra!", "error");
    }

    if (!methodTransport?.value) {
      return openAlert(
        "¡Es obligatorio seleccionar el Modo de transporte!",
        "error"
      );
    }

    if (!flag?.value) {
      return openAlert("¡Es obligatorio seleccionar una bandera!", "error");
    }

    if (!packagings?.value) {
      return openAlert("¡Es obligatorio seleccionar un Embalaje!", "error");
    }

    const formData = new FormData();
    formData.append("outputForm_id", FormExitNumber);
    formData.append(
      "original_fob_value_us",
      isString(ValueFobOriginal)
        ? ValueFobOriginal?.replaceAll(",", "")
        : ValueFobOriginal
    );

    /* 
       formData.append(
      "original_fob_value_us",
      isString(ValueFobOriginal)
        ? ValueFobOriginal?.replaceAll(",", "")
        : isNumber(ValueFobOriginal)
        ? ValueFobOriginal.toFixed(4)
        : ValueFobOriginal || ""
    ); */

    formData.append(
      "product_id",
      refProduct ? refProduct?.value || refProduct.id : ""
    );
    formData.append(
      "exchange_rate",
      trm
        ? trm?.replaceAll(",", "")
        : dataTRM?.data?.value_trm?.replaceAll(",", "")
    );
    formData.append("tradeAgreement_id", values?.trade_agreement_id || "");
    formData.append("cif_us_value", suma.replaceAll(",", ""));
    formData.append("cif_cop_value", multiplicacion.replaceAll(",", ""));
    formData.append("flag_id", flag?.value || "");
    formData.append("inventory_id", values?.inventory_id || "");
    formData.append(
      "us_freight",
      us_freight ? us_freight.replaceAll(",", "") : 0
    );
    formData.append(
      "us_insurance",
      us_insurance ? us_insurance.replaceAll(",", "") : 0
    );
    formData.append(
      "other_us_expenses",
      other_us_expenses ? other_us_expenses.replaceAll(",", "") : 0
    );
    formData.append(
      "us_fob_value",
      us_fob_value ? us_fob_value.replaceAll(",", "") : 0
    );
    formData.append("quantity", quantity ? quantity.replaceAll(",", "") : 0);
    formData.append(
      "number_packages",
      number_packages ? number_packages.replaceAll(",", "") : 0
    );
    formData.append(
      "net_weight",
      net_weight ? net_weight.replaceAll(",", "") : 0
    );
    formData.append("coin", typeCoin);
    formData.append(
      "gross_weight",
      gross_weight ? gross_weight.replaceAll(",", "") : 0
    );

    formData.append("reference_description", reference_description || "");
    formData.append(
      "adjustment_justification_fob",
      adjustment_justification_fob || ""
    );
    formData.append(
      "added_value_us",
      added_value_us ? added_value_us.replaceAll(",", "") : 0
    );
    formData.append(
      "refund_value_us",
      refund_value_us ? refund_value_us.replaceAll(",", "") : 0
    );
    formData.append("invoice", invoiceValue || "");
    formData.append("brand", brandValue || "");
    formData.append("serial", serialValue || "");

    formData.append(
      "output_form_item_description",
      output_form_item_description || ""
    );
    formData.append(
      "product_quantity",
      product_quantity ? product_quantity.replaceAll(",", "") : ""
    );
    formData.append("businessUnit_id", 1);
    formData.append("subheading_id", subpartida || "");
    formData.append("factor_conv", factor_conv || "");

    formData.append("countryDestiny_id", countryDestination?.value || "");
    formData.append("countryPurchase_id", countryPurchase?.value || "");
    formData.append("countryOrigin_id", countryOrigin?.value || "");

    formData.append("packaging_id", packagings?.value || "");
    formData.append("transportMethod_id", methodTransport?.value || "");
    formData.append("custom_clearance", customs_clearance ?? "");

    if (code_businessUnit === "KG") {
      if (parseFloat(quantity) !== parseFloat(net_weight)) {
        return openAlert("El peso neto debe ser igual a la cantidad", "error");
      }

      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormExitNumber}`) || "[]"
      );

      const result = obtenerErrorItem?.filter((e) => e !== dataToEditItems?.id);
      localStorage.setItem(
        `erroItem-${FormExitNumber}`,
        JSON.stringify(result)
      );
      if (dataToEditItems) {
        formData.append("id", dataToEditItems.id);
        updateItemPost(formData, cancelar);
      } else {
        setDataToCopyItems(null);
        createItemPost(formData, cancelar);
      }
    } else {
      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormExitNumber}`) || "[]"
      );

      const result = obtenerErrorItem?.filter((e) => e !== dataToEditItems?.id);
      localStorage.setItem(
        `erroItem-${FormExitNumber}`,
        JSON.stringify(result)
      );
      if (dataToEditItems) {
        formData.append("id", dataToEditItems.id);
        updateItemPost(formData, cancelar);
      } else {
        setDataToCopyItems(null);
        createItemPost(formData, cancelar);
      }
    }
    if (dataToEditItems) {
      localStorage.removeItem(`erroField-${dataToEditItems.id}`);
    }
  };

  const cancelar = () => {
    resetSelect();
    setDataToEditItems(null);
    setDataToCopyItems(null);
    set(!itemss);
  };

  const resetSelect = () => {
    reset();
    setCountryOrigin(null);
    setRefProduct(null);
    setCountryPurchase(null);
    setCountryDestination(null);
    setMethodTransport(null);
    setflag(null);
    setPackagings(null);
  };

  /* Esto debe refactorizarse utilizando el onFieldChange */
  useEffect(() => {
    const resultadoProduc =
      product_quantity && product_quantity.replaceAll(",", "");
    const validacionFactor = factor_conv || 0;
    const resultadoMultiplicacion =
      Number(validacionFactor) * Number(resultadoProduc);

    const newDato = fomateo(dataFormRedux?.precision, resultadoMultiplicacion);

    // ?.replaceAll(".", ",")
    if (code_businessUnit === "KG" && factor_conv) {
      setValues({
        ...values,
        quantity: newDato,
        net_weight: newDato,
        // gross_weight: newDato,
      });
    } else if (code_businessUnit !== "KG" && factor_conv) {
      setValues({
        ...values,
        quantity: newDato,
      });
    }
  }, [product_quantity, factor_conv]);

  const handleChangeRadio = (e) => {
    const valor = e.target.id;
    setValues({ ...values, invoice: valor });
    onCloseInvoice();
  };

  /* Esto también debe refactorizarse en el onFieldChange */
  useEffect(() => {
    if (product_unit?.toLowerCase() === "kg" && net_weight !== quantity) {
      setValues((prevState) => ({ ...prevState, net_weight: quantity }));
    }
  }, [quantity]);

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    );
  };

  const handleTrmChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 4) {
        formattedValue += ".";
        formattedValue += numericValue[i];
      } else {
        formattedValue += numericValue[i];
      }
    }

    setTrm(formattedValue);
  };

  const {
    updateNotation: updateComment,
    updateNotationData,
    reviewsData,
  } = useNotations();

  const colorNotation = (propertyName) => {
    return (
      updateNotationData?.[formId]?.[values?.id]?.[propertyName]?.status
        ?.color || reviewsData?.[values?.id]?.[propertyName]?.status?.color
    );
  };

  const customStyles = (propertyName) => {
    const color = colorNotation(propertyName);

    if (color) {
      return {
        control: (provided) => ({
          ...provided,
          borderColor: color,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color,
        }),
      };
    }
  };

  const nameSelect = {
    name_typeProduct: "Tipo de producto",
    name_country: "País origen",
    name_country_proc: "País procedencia",
    name_country_purs: "País compra",
    name_transportMethod: "Modo de transporte",
    name_country_flag: "Bandera",
    name_packaging: "Embalaje",
    name_tradeAgreement: "Acuerdo de Comercio",
  };

  const selectChange = (items, select) => {
    return updateComment(
      select?.name,
      nameSelect?.[select?.name],
      items?.label,
      dataForm?.[select?.name] || "",
      dataForm?.id
    );
  };

  const { requestData } = useGlobalContext();

  const { mutate, isPending } = useMutation({
    mutationFn: requestData,
  });

  useEffect(() => {
    const formatValue = (value) => fomateo(dataFormRedux?.precision, value);

    if (Calculated) {
      const {
        fob_value_calculated,
        freight_calculated,
        insurance_calculated,
        other_expenses_calculated,
        cif_us_value_calculated,
        cif_cop_value_calculated,
      } = Calculated;

      setValues((prev) => ({
        ...prev,
        us_fob_value: formatValue(fob_value_calculated),
        us_freight: formatValue(freight_calculated),
        us_insurance: formatValue(insurance_calculated),
        other_us_expenses: formatValue(other_expenses_calculated),
      }));

      setMultiplicacion(formatValue(cif_cop_value_calculated));
      setPesosToDollars(formatValue(cif_us_value_calculated));
    }
  }, [Calculated, dataFormRedux?.precision]);

  const updateCurrencyField = (e) => {
    const Currency = e;

    const parsedValues = {
      freight: parseNumberWithCommas(us_freight),
      insurance: parseNumberWithCommas(us_insurance),
      other_expenses: parseNumberWithCommas(other_us_expenses),
      fob_value: parseNumberWithCommas(us_fob_value),
    };

    const convertionTrm = isValid(trm) ? trm : dataTRM?.data?.value_trm;

    const Trm = convertionTrm;
    const trmFormatted = isNumberFormatable(Trm) ? parseFloat(Trm) : null;

    const data = {
      type_calculated: Currency,
      exchange_rate: trmFormatted,
      decimal_precision: Number(dataFormRedux?.precision) || 1,
      ...parsedValues,
    };

    const requestData = {
      data,
      Endpoint: environment.CALCULATED_ITEM,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(requestData, {
      onSuccess: handleResponseCalculated,
    });
  };

  const handleResponseCalculated = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      setCalculated(res?.data?.status?.data);
      return;
    }
  };

  const [typeCoin, setTypeCoin] = useState("USD");

  const handleChangeCoin = (e) => {
    const value = e || "USD";

    if (typeCoin === "USD") {
      setTypeCoin("COP");
      updateCurrencyField("COP");
    }

    if (typeCoin === "COP") {
      setTypeCoin("USD");
      updateCurrencyField("USD");
    }
  };

  const [pesosToDollars, setPesosToDollars] = useState(0);

  /* Esto también se debería hacer a través de onFieldChange */
  useEffect(() => {
    const sumaDetodo =
      parseNumberWithCommas(us_freight) +
      parseNumberWithCommas(us_insurance) +
      parseNumberWithCommas(other_us_expenses) +
      parseNumberWithCommas(us_fob_value);

    const Trm = exchange_rate?.replaceAll(",", "");
    const trmFormatted = isNumberFormatable(Trm) ? parseFloat(Trm) : null;

    const DolarCurrent = trmFormatted || Number(dataTRM?.data?.value_trm);

    const PesosToDollars =
      sumaDetodo !== 0 && DolarCurrent !== 0
        ? ConvertPesosToDollars(
            sumaDetodo,
            DolarCurrent,
            dataFormRedux?.precision
          )
        : 0;

    setPesosToDollars(PesosToDollars);
  }, [
    us_fob_value,
    exchange_rate,
    dataFormRedux?.precision,
    us_freight,
    us_insurance,
    other_us_expenses,
  ]);

  /*   useEffect(() => {
    const currentValues = values || {};
    const sum = +currentValues.us_fob_value + +currentValues.us_freight + +currentValues.us_insurance + +currentValues.other_us_expenses
    //setCifUsValue(sum)
   // setValue("cif_us_value", sum)
  }, [watchedFields])

  useEffect(() => {
    const exchangeRate = getValues("exchange_rate")
   // setCifCopValue(exchangeRate * cif_us_value)
    //setValue("cif_cop_value", exchangeRate * cif_us_value)
  }, [cif_us_value])
 */

  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue, formattedValue, value } = newValue;
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target]: {
        ...sourceEvent?.event?.target,
        value: parseFloat(floatValue || value),
      },
    };
    handleInputChange(target);
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenInvoice}
        onOpen={onOpenInvoice}
        onClose={onCloseInvoice}
        title="Seleccionar factura existente"
        size="sm"
      >
        {valueInvoice &&
          valueInvoice.map((e, index) => {
            if (e.invoice) {
              return (
                <div key={index}>
                  <Radio
                    id={e.invoice}
                    value={e.invoice}
                    onChange={handleChangeRadio}
                  >
                    {e.invoice}
                  </Radio>
                  <br />
                </div>
              );
            }
          })}
        <Flex mt={5}>
          <Spacer />
          <Cancelar onClick={onCloseInvoice}>Cancelar</Cancelar>
        </Flex>
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 5 }}
            minChildWidth={{ md: "200px" }}
            gap={2}
          >
            <SearchReference
              label="Referencia *"
              set={setRefProduct}
              value={refProduct}
              requestManagement={requestManagementReference}
              callBack={handleBlurReferenc}
              formData={{
                qualifiedUser_id: valueQualified,
                outputForm_id: FormExitNumber,
                operation_code: selectItemForm,
              }}
              error={errorsState.product_id && !refProduct}
            />
            <LabelGroup
              name={"customs_clearance"}
              value={customs_clearance}
              tag={"Declaración de importación "}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"value_sdo"}
              value={value_sdo}
              tag={"Saldo"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"operation_code"}
              value={operation_code}
              tag={"Oper"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"initial_operation"}
              value={initial_operation}
              tag={"Inicial"}
              size={100}
              desicion={true}
            />
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 5 }}
            minChildWidth={{ md: "200px" }}
            my={4}
            gap={2}
          >
            <LabelGroup
              label={
                SearchEditedFields(`code_product_${values?.id}`, ReviewsData) ??
                ""
              }
              error={
                !!(
                  ReviewsData &&
                  SearchEditedFields(`code_product_${values?.id}`, ReviewsData)
                )
              }
              bg="red.600"
              name={"product_code"}
              value={product_code || ""}
              tag={"Código producto"}
              size={100}
              desicion={true}
            />

            <LabelGroup
              name={"value_sdo_product"}
              value={value_sdo_product}
              tag={"Saldo producto"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"product_unit"}
              value={product_unit || ""}
              onChange={handleInputChange}
              tag={"Unidad de producto"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"factor_conv"}
              value={factor_conv || ""}
              tag={"Factor de conversión"}
              size={100}
              desicion={true}
            />

            {/* inicio cantidad de producto */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={
                product_quantity && product_quantity !== "NaN"
                  ? product_quantity
                  : ""
              }
              label={reviewsData?.[values?.id]?.product_quantity?.comment}
              bg={colorNotation("product_quantity")}
              placeholder="0"
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.product_quantity}
              name={"product_quantity"}
              error={
                colorNotation("product_quantity") ||
                errorsState.product_quantity ||
                !isValid(product_quantity)
              }
              onValueChange={updateItemValue}
              onChange={onFieldChange}
              tag={"Cantidad de producto"}
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.product_quantity}
            />
            {/* fin cantidad de producto */}
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 5 }}
            minChildWidth={{ md: "200px" }}
            my={4}
            gap={2}
          >
            <LabelGroup
              name={"name_typeProduct"}
              value={name_typeProduct ?? ""}
              onChange={handleInputChange}
              desicion={true}
              tag={"Tipo de producto"}
              size={100}
              label={reviewsData?.[values?.id]?.code_subheading?.comment}
              bg={colorNotation("code_subheading")}
              error={
                colorNotation("name_typeProduct") ||
                errorsState.name_typeProduct
              }
              readOnly={true}
            />
            <LabelGroup
              name={"code_subheading"}
              value={code_subheading ?? ""}
              onChange={handleInputChange}
              desicion={true}
              tag={"Subpartida arancelaria"}
              size={100}
              label={reviewsData?.[values?.id]?.code_subheading?.comment}
              bg={colorNotation("code_subheading")}
              error={
                colorNotation("code_subheading") || errorsState.code_subheading
              }
              readOnly={true}
            />

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country?.comment}
              bg={colorNotation("name_country")}
              color="white"
              placement="bottom-start"
            >
              <InputSelect
                tag={"País origen *"}
                data={dataCountry}
                set={setCountryOrigin}
                value={countryOrigin}
                onChange={selectChange}
                name="name_country"
                styles={customStyles("name_country")}
                error={
                  colorNotation("name_country") ||
                  errorsState.countryOrigin_id ||
                  !isValid(countryOrigin) ||
                  countryOrigin.length === 0
                }
                size={100}
              />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_purs?.comment}
              bg={colorNotation("name_country_purs")}
              color="white"
              placement="bottom-start"
            >
              <InputSelect
                tag={"País compra *"}
                data={dataCountry}
                set={setCountryPurchase}
                value={countryPurchase}
                name="name_country_purs"
                onChange={selectChange}
                styles={customStyles("name_country_purs")}
                error={
                  colorNotation("name_country_purs") ||
                  errorsState.countryPurchase_id ||
                  !countryPurchase ||
                  countryPurchase.length === 0
                }
              />
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_des?.comment}
              bg={colorNotation("name_country_des")}
              color="white"
              placement="bottom-start"
            >
              <InputSelect
                tag={"País destino"}
                data={dataCountry120 || dataCountry}
                set={setCountryDestination}
                value={countryDestination}
                name="name_country_des"
                onChange={selectChange}
                styles={customStyles("name_country_des")}
                error={
                  colorNotation("name_country_des") ||
                  (errorsState.countryDestiny_id ||
                    (!countryDestination || countryDestination.length === 0))
                }
              />
            </Tooltip>
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 3 }}
            minChildWidth={{ "2sm": "200px", md: "200px", lg: "300px" }}
            gap={2}
            my={4}
          >
            {/* inicio peso bruto */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={gross_weight && gross_weight !== "NaN" ? gross_weight : ""}
              placeholder="0"
              onValueChange={updateItemValue}
              onChange={onFieldChange}
              tag={"Peso bruto"}
              size={100}
              desicion={false}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.gross_weight}
              name={"gross_weight"}
              label={reviewsData?.[values?.id]?.gross_weight?.comment}
              bg={colorNotation("gross_weight")}
              error={
                colorNotation("gross_weight") ||
                errorsState.gross_weight ||
                !isValid(gross_weight) ||
                Number(gross_weight) === 0 ||
                Number(gross_weight) < Number(net_weight)
              }
              maxLength={MaxLength.formExit.addItemForm.gross_weight}
            >
            
                <span className="text-danger">
                  {Number(gross_weight) < Number(net_weight) || errorsState.gross_weight 
                    ? "El Peso Bruto debe ser mayor o igual al peso Neto"
                    : Number(gross_weight) === 0
                    ? "El Peso Bruto no puede ser igual a cero"
                    : ""}
                </span>
           
            </NumericFormat>
            {/* fin peso bruto */}

            {/* inicia peso neto */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={net_weight && net_weight !== "NaN" ? net_weight : ""}
              placeholder="0"
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              tag={"Peso neto"}
              size={100}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.net_weight}
              name={"net_weight"}
              label={reviewsData?.[values?.id]?.net_weight?.comment}
              bg={colorNotation("net_weight")}
              error={
                colorNotation("net_weight") ||
                (errorsState.net_weight &&
                  (!net_weight ||
                    Number(net_weight) === 0 ||
                    Number(net_weight) > Number(gross_weight)))
              }
              desicion={code_businessUnit === "KG"}
              maxLength={MaxLength.formExit.addItemForm.net_weight}
            />
            {/* fin peso neto */}

            {/* inicia numero de bultos */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"number_packages"}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.number_packages}
              value={
                number_packages && number_packages !== "NaN"
                  ? number_packages
                  : ""
              }
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              placeholder="0"
              tag={"Número de bultos"}
              size={100}
              label={reviewsData?.[values?.id]?.number_packages?.comment}
              bg={colorNotation("number_packages")}
              error={
                colorNotation("number_packages") || errorsState.number_packages
              }
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.number_packages}
            />
            {/* fin numero de bultos */}

            {/* inicio cantidad */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={quantity && quantity !== "NaN" ? quantity : ""}
              placeholder="0"
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              tag={"Cantidad"}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.quantity}
              error={colorNotation("quantity") || errorsState.quantity}
              name={"quantity"}
              label={reviewsData?.[values?.id]?.quantity?.comment}
              bg={colorNotation("quantity")}
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.quantity}
            />
            {/* Fin de cantidad */}

            {/* inicia Unidad comercial */}
            <LabelGroup
              name={"code_businessUnit"}
              value={
                code_businessUnit && name_businessUnit
                  ? code_businessUnit + " " + name_businessUnit
                  : ""
              }
              placeholder="0"
              tag={"Unidad comercial"}
              label={reviewsData?.[values?.id]?.code_businessUnit?.comment}
              bg={colorNotation("code_businessUnit")}
              error={
                colorNotation("code_businessUnit") ||
                errorsState.code_businessUnit
              }
              size={100}
              desicion={true}
            />
            {/* fin Unidad comercial */}

            {/* inicia modo de transporte */}
            <Tooltip
              hasArrow
              label={
                ReviewsData
                  ? SearchEditedFields(
                      `name_transportMethod_${values?.id}`,
                      ReviewsData
                    )
                  : ""
              }
              bg="red.600"
              color="white"
              placement="bottom-start"
            >
              <div>
                <InputSelect
                  tag={"Modo de transporte"}
                  data={dataTransportMethod}
                  set={setMethodTransport}
                  value={methodTransport}
                  name="name_transportMethod"
                  onChange={selectChange}
                  styles={customStyles("name_transportMethod")}
                  error={
                    colorNotation("name_transportMethod") ||
                    errorsState.transportMethod_id ||
                    !isValid(methodTransport)
                  }
                />
              </div>
            </Tooltip>
            {/* fin modo de transporte */}

            {/* inicia bandera */}
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_country_flag?.comment}
              bg={colorNotation("name_country_flag")}
              color="white"
              placement="bottom-start"
            >
              <div>
                <InputSelect
                  tag={"Bandera"}
                  data={dataCountry}
                  set={setflag}
                  value={flag}
                  name="name_country_flag"
                  onChange={selectChange}
                  styles={customStyles("name_country_flag")}
                  error={
                    colorNotation("name_country_flag") ||
                    errorsState.flag_id ||
                    !flag ||
                    flag.length === 0
                  }
                />
              </div>
            </Tooltip>
            {/* fin bandera */}

            {/* inicia embalaje */}
            <Tooltip
              hasArrow
              label={reviewsData?.[values?.id]?.name_packaging?.comment}
              bg={colorNotation("name_packaging")}
              color="white"
              placement="bottom-start"
            >
              <div>
                <InputSelect
                  tag={"Embalaje"}
                  Name={`name_packaging_${values?.id}`}
                  data={dataPackagings}
                  set={setPackagings}
                  value={packagings}
                  name="name_packaging"
                  onChange={selectChange}
                  styles={customStyles("name_packaging")}
                  error={
                    colorNotation("name_packaging") ||
                    errorsState.packaging_id ||
                    !isValid(packagings)
                  }
                />
              </div>
            </Tooltip>
            {/* fin embalaje */}

            <Box>
              <SimpleGrid columns={{ base: 1, sm: 2, md: 2 }}>
                <div className="mx-2 w-20%">
                  <LabeFormlStyled>Monedas</LabeFormlStyled>
                  <RadioBtnRedonder
                    options={dataCoin}
                    setOnly={handleChangeCoin}
                    state={typeCoin}
                    // isDisabled={selectItemForm >= 700 && selectItemForm <= 799}
                  />
                </div>
                <NumericFormat
                  width="80%"
                  allowNegative={false}
                  thousandSeparator={true}
                  decimalScale={dataFormRedux?.precision}
                  decimalSeparator="."
                  customInput={LabelGroup}
                  value={us_freight && us_freight !== "NaN" ? us_freight : ""}
                  onChange={onFieldChange}
                  onValueChange={updateItemValue}
                  style={{
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                  placeholder="0"
                  tag={`Flete ${typeCoin}`}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.us_freight}
                  size={100}
                  desicion={false}
                  name="us_freight"
                  label={reviewsData?.[values?.id]?.us_freight?.comment}
                  bg={colorNotation("us_freight")}
                  error={
                    colorNotation("us_freight") ||
                    (errorsState.us_freight &&
                      Number(us_freight) === 0 &&
                      Number(selectItemForm) !== 120)
                  }
                  maxLength={MaxLength.formExit.addItemForm.us_freight}
                >
                  {errorsState.us_freight &&
                    Number(us_freight) === 0 &&
                    Number(selectItemForm) !== 120 && (
                      <span className="text-danger">
                        {Number(us_freight) === 0 &&
                        Number(selectItemForm) >= 101 &&
                        Number(selectItemForm) <= 199 &&
                        Number(selectItemForm) !== 120
                          ? "el valor de los fletes no puede ser cero"
                          : ""}
                      </span>
                    )}
                </NumericFormat>
              </SimpleGrid>
            </Box>
            {/* inicia Seguros */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={us_insurance && us_insurance !== "NaN" ? us_insurance : ""}
              placeholder="0"
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.us_insurance}
              name={"us_insurance"}
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              tag={`Seguros ${typeCoin}`}
              label={reviewsData?.[values?.id]?.us_insurance?.comment}
              bg={colorNotation("us_insurance")}
              error={colorNotation("us_insurance") || errorsState.us_insurance}
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.us_insurance}
            />
            {/* fin Seguros */}

            {/* inicia Otros gastos */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.other_us_expenses}
              name={"other_us_expenses"}
              value={
                other_us_expenses && other_us_expenses !== "NaN"
                  ? other_us_expenses
                  : ""
              }
              error={
                colorNotation("other_us_expenses") ||
                errorsState.other_us_expenses
              }
              label={reviewsData?.[values?.id]?.other_us_expenses?.comment}
              bg={colorNotation("other_us_expenses")}
              placeholder="0"
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              tag={`Otros gastos ${typeCoin}`}
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.other_us_expenses}
            />
            {/* fin Otros gastos */}

            {/* inicia Valor FOB inicial */}
            <NumericFormat
              allowNegative={false}
              onValueChange={updateItemValue}
              decimalSeparator="."
              customInput={LabelGroup}
              decimalScale={4}
              name={"value_fob_unit_product"}
              value={
                quantity !== "NaN" &&
                quantity !== 0 &&
                quantity !== "0" &&
                quantity !== "" &&
                quantity !== undefined &&
                quantity !== null
                  ? parseFloat(values?.value_fob_unit_product) *
                    parseNumberOrZero(quantity)
                  : values?.value_fob_unit_product || "N/A"
              }
              tag={"Valor FOB Inicial USD"}
              error={
                colorNotation("value_fob_unit_product") ||
                errorsState.value_fob_unit_product
              }
              label={reviewsData?.[values?.id]?.value_fob_unit_product?.comment}
              bg={colorNotation("value_fob_unit_product")}
              id={us_fob_value}
              size={100}
              readOnly={true}
              desicion={true}
            />
            {/* fin Valor FOB inicial */}

            {/* inicia Valor FOB */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={us_fob_value && us_fob_value !== "NaN" ? us_fob_value : ""}
              placeholder="0"
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.us_fob_value}
              name={"us_fob_value"}
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              /*    style={{
                borderBottomRightRadius: "0",
                borderTopRightRadius: "0",
              }} */
              tag={` Valor FOB ${typeCoin}`}
              size={100}
              error={colorNotation("us_fob_value")}
              label={reviewsData?.[values?.id]?.us_fob_value?.comment}
              bg={colorNotation("us_fob_value")}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.us_fob_value}
            />
            {/* fin Valor FOB */}

            {/* inicia Valor CIF */}
            <NumericFormat
              allowNegative={false}
              // onValueChange={updateItemValue}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.cif_us_value}
              name={"cif_us_value"}
              value={suma || 0}
              tag={`Valor CIF ${typeCoin || ""} `}
              maxLength={MaxLength.formEntry.addItemForm.cif_us_value}
              size={100}
              readOnly={true}
              error={colorNotation("cif_us_value") || errorsState.cif_us_value}
              desicion={selectItemForm >= 700 && selectItemForm <= 799}
            />
            {/* fin Valor CIF */}

            {/* inicio Tasa de cambio */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={trm}
              onChange={handleTrmChange}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.trm}
              name={"trm"}
              error={colorNotation("trm") || errorsState.trm}
              label={reviewsData?.[values?.id]?.trm?.comment}
              bg={colorNotation("trm")}
              placeholder={"0.00"}
              tag={"Tasa de Cambio"}
              onBlur={() => {
                if (!trm || trm === "" || trm === 0 || trm === "0") {
                  setTrm(dataTRM?.data?.value_trm);
                }
              }}
              size={100}
              maxLength={10}
            />
            {/* fin Tasa de cambio */}

            {/* inicia Valor CIF COP o USD dependiendo de la moneda */}
            {typeCoin !== "COP" ? (
              <NumericFormat
                allowNegative={false}
                // onValueChange={updateItemValue}
                thousandSeparator={true}
                decimalScale={dataFormRedux?.precision}
                decimalSeparator="."
                customInput={LabelGroup}
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.cif_cop_value}
                name={"cif_cop_value"}
                value={removeDecimalIfZero(
                  multiplicacion,
                  dataFormRedux?.precision,
                  "cif_cop_value, Valor CIF COP"
                )}
                tag={`Valor CIF COP`}
                placeholder="0.00$"
                maxLength={MaxLength.formEntry.addItemForm.cif_cop_value}
                size={100}
                readOnly={true}
                error={
                  colorNotation("cif_cop_value") || errorsState.cif_cop_value
                }
                desicion={selectItemForm >= 700 && selectItemForm <= 799}
              />
            ) : (
              <Tooltip
                hasArrow
                label={reviewsData?.[values?.id]?.cif_cop_value?.comment}
                bg={colorNotation("cif_cop_value")}
                color="white"
                placement="bottom-start"
              >
                <NumericFormat
                  allowNegative={false}
                  // onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={dataFormRedux?.precision}
                  decimalSeparator="."
                  customInput={LabelGroup}
                  placeholder="0.00$"
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.cif_cop_value}
                  name={"cif_cop_value"}
                  value={removeDecimalIfZero(
                    pesosToDollars,
                    dataFormRedux?.precision,
                    "cif_cop_value, Valor CIF USD"
                  )}
                  tag={`Valor CIF USD`}
                  maxLength={MaxLength.formEntry.addItemForm.cif_cop_value}
                  size={100}
                  readOnly={true}
                  error={
                    colorNotation("cif_cop_value") || errorsState.cif_cop_value
                  }
                  desicion={selectItemForm >= 700 && selectItemForm <= 799}
                />
              </Tooltip>
            )}
            {/* fin Valor CIF COP or USD dependiendo de la moneda */}

            {/* inicio Valor agregado */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={added_value_us}
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              tag={"Valor agregado US$"}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.added_value_us}
              name={"added_value_us"}
              error={
                colorNotation("added_value_us") || errorsState.added_value_us
              }
              label={reviewsData?.[values?.id]?.added_value_us?.comment}
              bg={colorNotation("added_value_us")}
              placeholder="0"
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.added_value_us}
              required={initial_operation === "CDI"}
            />
            {/* fin Valor agregado */}

            {/* inicio Valor a Reintegrar */}
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={dataFormRedux?.precision}
              decimalSeparator="."
              customInput={LabelGroup}
              value={refund_value_us}
              onChange={onFieldChange}
              onValueChange={updateItemValue}
              placeholder="0"
              tag={"Valor a Reintegrar US$"}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.refund_value_us}
              name={"refund_value_us"}
              error={
                colorNotation("refund_value_us") || errorsState.refund_value_us
              }
              label={reviewsData?.[values?.id]?.refund_value_us?.comment}
              bg={colorNotation("refund_value_us")}
              size={100}
              desicion={false}
              maxLength={MaxLength.formExit.addItemForm.refund_value_us}
              required={refund === "Con reintegro"}
            />
            {/* fin Valor a Reintegrar */}
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 3 }}
            minChildWidth={{ md: "400px" }}
            gap={2}
            my={4}
          >
            <LabelGroup
              // name={"commercialInvoice"}
              value={
                (code_tradeAgreement || "") + " " + (name_tradeAgreement || "")
              }
              bg="red.600"
              placeholder={"Acuerdo Comercial"}
              tag={"Acuerdo Comercial"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"fob_sdo_value"}
              value={fob_sdo_value}
              placeholder="0.00$"
              tag={"Saldo FOB"}
              size={100}
              desicion={true}
            />
            <LabelGroup
              name={"cif_value_sdo"}
              value={cif_value_sdo}
              placeholder="0.00$"
              tag={"Saldo CIF"}
              size={100}
              desicion={true}
            />

            <div className="d-flex justify-content-center align-items-center">
              <LabelGroup
                value={invoiceValue}
                onChange={(e) => handleInvoiceValueChangue(e, onFieldChange)}
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.invoice}
                name={"invoice"}
                error={colorNotation("invoice") || errorsState.invoice}
                label={reviewsData?.[values?.id]?.invoice?.comment}
                bg={colorNotation("invoice")}
                tag={`Factura`}
                size={100}
                ButtonSide={valuesHeader.commercial_invoice && true}
                maxLength={MaxLength.formExit.addItemForm.invoice}
              />

              {valuesHeader.commercial_invoice && (
                <Tooltip
                  hasArrow
                  label={"Seleccionar factura existente"}
                  placement="top"
                >
                  <IconButton
                    onClick={getInvoice}
                    w={"42px"}
                    h={"37px"}
                    marginTop={"22px"}
                    borderRadius={0}
                    borderTopRightRadius={"5px"}
                    borderBottomRightRadius={"5px"}
                    color={"#fff"}
                    backgroundColor={"#1f7ac3"}
                    paddingBottom={"1px"}
                    outline={"none"}
                    icon={<TbFileInvoice />}
                  />
                </Tooltip>
              )}
            </div>
            <LabelGroup
              value={brandValue}
              onChange={(e) => handleBrandValueChange(e, onFieldChange)}
              data-item-id={dataForm?.id}
              data-old-Value={dataForm?.brand}
              name={"brand"}
              error={colorNotation("brand") || errorsState.brand}
              label={reviewsData?.[values?.id]?.brand?.comment}
              bg={colorNotation("brand")}
              tag={`Marca`}
              size={100}
              maxLength={MaxLength.formExit.addItemForm.brand}
            />

            <Tooltip
              hasArrow
              label={
                ReviewsData
                  ? SearchEditedFields(`serial_${values?.id}`, ReviewsData)
                  : ""
              }
              bg="red.600"
              color="white"
              placement="bottom-start"
            >
              <LabelGroup
                value={serialValue}
                onChange={(e) => handleSerialValueChange(e, onFieldChange)}
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.serial}
                name={"serial"}
                error={colorNotation("serial") || errorsState.serial}
                label={reviewsData?.[values?.id]?.serial?.comment}
                bg={colorNotation("serial")}
                tag={`Serial`}
                size={100}
                maxLength={MaxLength.formExit.addItemForm.serial}
              />
            </Tooltip>
          </SimpleGrid>

          <SimpleGrid area={"descriptions-justifications"}>
            <div
              style={{
                justifyContent: "space-between",
              }}
            >
              <LabeFormlStyled>Justificación ajusté FOB</LabeFormlStyled>
              <Textarea
                name={"adjustment_justification_fob"}
                value={adjustment_justification_fob}
                onChange={(e) => handleRegex(e, "all", Regex?.all)}
                data-item-id={dataForm?.id}
                data-old-Value={dataForm?.adjustment_justification_fob}
                borderColor={colorNotation("adjustment_justification_fob")}
                style={{ background: "#fff" }}
                maxLength={
                  MaxLength.formExit.addItemForm.adjustment_justification_fob
                }
              />
            </div>
            <div
              style={{
                // display: "flex",
                justifyContent: "space-between",
              }}
            >
              <LabeFormlStyled>Descripción</LabeFormlStyled>

              <Textarea
                name={"description_product"}
                value={description_product}
                isReadOnly={true}
                data-item-id={dataForm?.id}
                className="description"
                textColor="#2322"
              />
            </div>

            <div
              style={{
                // display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <LabelGroup
              name={"product_description"}
              value={product_description}
              onChange={handleInputChange}
              tag={"Descripción larga"}
              size={100}
              desicion={false}
            /> */}
              <LabeFormlStyled>Descripción larga</LabeFormlStyled>
              <Tooltip
                hasArrow
                label={
                  reviewsData?.[values?.id]?.output_form_item_description
                    ?.comment
                }
                bg={colorNotation("output_form_item_description")}
                color="white"
                placement="bottom-start"
              >
                <Textarea
                  name={"output_form_item_description"}
                  value={output_form_item_description}
                  onChange={handleInputChange}
                  isDisabled={false}
                  data-item-id={dataForm?.id}
                  data-old-Value={dataForm?.output_form_item_description}
                  borderColor={colorNotation("output_form_item_description")}
                  className="description"
                  style={{ background: "#fff" }}
                  maxLength={
                    MaxLength.formExit.addItemForm.output_form_item_description
                  }
                />
              </Tooltip>
            </div>
          </SimpleGrid>

          <Box
            display="flex"
            alignItems="center"
            marginTop={"20px"}
            marginBottom={"20px"}
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={() => cancelar()}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled isLoading={isPending} onClick={handleCrear}>
              {dataToEditItems
                ? "Actualizar"
                : dataToCopyItems
                ? "Copiar"
                : "Agregar"}
            </ButtonStyled>
          </Box>

          {/*    
          <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>


            <div className="d-flex">
             

             <Tooltip
                hasArrow
                label={"Seleccione el tipo de Moneda"}
                placement="top"
              >
                <Input
                  onClick={handleChangeCoin}
                  w={"auto"}
                  h={"38px"}
                  type="button"
                  marginTop={"24px"}
                  borderRadius={0}
                  borderTopRightRadius={"5px"}
                  borderBottomRightRadius={"5px"}
                  color={"#fff"}
                  backgroundColor={"#1f7ac3"}
                  // paddingBottom={"1px"}
                  border={"none"}
                  outline={"none"}
                  value={typeCoin}
                  // icon={<TbFileInvoice />}
                />
              </Tooltip> 
            </div>


          </SimpleGrid>
          */}
        </>
      )}
    </>
  );
};
