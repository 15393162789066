import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const SuperAdminRoutes = ({ children }) => {
  const { isLogged, dataUser } = useSelector((state) => state.auth);

  return isLogged && dataUser ? (
    //  && authUser.rol === 1
    children
  ) : (
    <Navigate to="/" />
  );
  // return children;
};

export default SuperAdminRoutes;
