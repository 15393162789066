import { Box, Flex, Spacer, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ButtonItemsForm } from "pages/Pass-Form/stylePases";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import React, { useEffect, useState } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { VscFilePdf } from "react-icons/vsc";
import { MdQrCodeScanner } from "react-icons/md";
import { useSelector } from "react-redux";
import { ExportPdf } from "components/Export/ExportPdf";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import ModalQR from "components/modal/ModalQR";

export const ButtonQrPdf = ({ numero, children }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const name = "Registro de pesaje en báscula" + numero;

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}

      />

      <div className="row">
        <div className="col"></div>

        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            {children}

            <Tooltip placement="top" hasArrow label="Qr">
              <span>
                <ButtonHeaderModule
                  onClick={onOpen}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Exportar PDF">
              <span>
                <ButtonHeaderModule
                  onClick={() => ExportPdf("IdPdfPesaje", name)}
                  className="m-1"
                  bgColor="#03C39A"
                >
                  <IconGeneric className=" text-white" as={VscFilePdf} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};
