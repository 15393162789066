import { Box, SimpleGrid } from "@chakra-ui/react";
import { TitleHeader } from "components/HeaderForm/Style";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useContext, useEffect, useState, useCallback } from "react";

import { IconGeneric } from "styled/Icons.styled";
import { RiSearch2Line } from "react-icons/ri";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormSearchsupplies from "./FormSearchsupplies";
import { ButtonStyled } from "styled/Buttons.styled";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";

import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { MaxLength } from "utils/MaxLength";
import { NumericFormat } from "react-number-format";
import {
  isNonEmptyString,
  safelyConvertToString,
} from "utils/type-check-utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import queryEnv from "constants/queryConst";
import { parseFloat } from "utils/parseFloat";
import { useDebounce } from "hooks/useDebouce";

const FormCorrectedSupplies = ({ ...props }) => {
  const location = useLocation();
  const { ActionCorrect = "" } = queryString.parse(location.search);
  const options = ["%", "Cant"];
  const [percentageAndAmount, setpercentageAndAmount] = useState("%");
  const [openAlert] = AlertErrorAlert();
  const decimal_values_precision = props?.userQualified?.decimal || 4;
  const { requestData } = useGlobalContext();

  const onFieldChange = (name, newValue, target) => {
    const parsedValue = parseFloat(newValue);

    if (!values?.convertion_factor_product_corrected) return;
    if (name === "quantity_product_corrected") {
      return setValues((prev) => ({
        ...prev,
        quantity_product_corrected: parsedValue,
        quantity_corrected:
          parsedValue * parseFloat(values?.convertion_factor_product_corrected),
        ...(values?.business_unit_corrected &&
          values?.business_unit_corrected?.toUpperCase() === "KG" && {
            net_weight_kilo_corrected:
              parsedValue *
              parseFloat(values?.convertion_factor_product_corrected),
            calculated_weight_corrected:
              parsedValue *
              parseFloat(values?.convertion_factor_product_corrected),
          }),
      }));
    }
    if (name === "quantity_corrected") {
      return setValues((prev) => ({
        ...prev,
        quantity_corrected: parsedValue,
        quantity_product_corrected:
          parsedValue / parseFloat(values?.convertion_factor_product_corrected),
        ...(values?.business_unit_corrected &&
          values?.business_unit_corrected?.toUpperCase() === "KG" && {
            net_weight_kilo_corrected:
              parsedValue /
              parseFloat(values?.convertion_factor_product_corrected),
            calculated_weight_corrected:
              parsedValue /
              parseFloat(values?.convertion_factor_product_corrected),
          }),
      }));
    }

    if (name === "convertion_factor_product_corrected") {
      return setValues((prev) => ({
        ...prev,
        quantity_corrected:
          parseFloat(newValue) * parseFloat(values?.product_quantity_corrected),
        net_weight_kilo_corrected:
          parseFloat(newValue) * parseFloat(values?.product_quantity_corrected),
        calculated_weight_corrected:
          parseFloat(newValue) * parseFloat(values?.product_quantity_corrected),
      }));
    }
  };

  const [values, handleInputChange, reset, setValues] = useForm(
    {
      reference: null,
      description_product: null,
      nature_of_inventory: null,
      code_product: null,
      quantity_available_product: null,
      business_unit_product: null,
      convertion_factor_product: null,
      quantity_product: null,
      quantity: null,
      business_unit: null,
      quantity_garbage: null,
      net_weight_kilo: null,
      fob_value: null,
      code_tradeAgreement: null,
      name_tradeAgreement: null,
      agreement: "" /* Artificial prop */,
      code_subheading: null,
      integration_certificate: null,
      import_declaration: null,
      code_operation: null,
      code_operation_inicial: null,
      quantity_available: null,
      value_cif: null,
      calculated_value: null /* Artificial prop */,
      calculated_weight: null /* Artificial prop */,

      /* _corrected props */
      reference_corrected: null,
      description_product_corrected: null,
      nature_of_inventory_corrected: null,
      code_product_corrected: null,
      quantity_available_product_corrected: null,
      business_unit_product_corrected: null,
      convertion_factor_product_corrected: null,
      quantity_product_corrected: null,
      quantity_corrected: null,
      business_unit_corrected: null,
      quantity_garbage_corrected: null,
      net_weight_kilo_corrected: null,
      fob_value_corrected: null,
      code_tradeAgreement_corrected: null,
      name_tradeAgreement_corrected: null,
      agreement_corrected: "" /* Artificial prop */,
      code_subheading_corrected: null,
      integration_certificate_corrected: null,
      import_declaration_corrected: null,
      code_operation_corrected: null,
      code_operation_inicial_corrected: null,
      quantity_available_corrected: null,
      value_cif_corrected: null,
      calculated_value_corrected: null /* Artificial prop */,
      calculated_weight_corrected: null,
    },
    null,
    onFieldChange
  );

  const {
    reference,
    description_product,
    nature_of_inventory,
    code_product,
    quantity_available_product,
    business_unit_product,
    convertion_factor_product,
    quantity_product,
    quantity,
    business_unit,
    quantity_garbage,
    net_weight_kilo,
    fob_value,
    code_tradeAgreement,
    name_tradeAgreement,
    agreement = "" /* Artificial prop */,
    code_subheading,
    integration_certificate,
    import_declaration,
    code_operation,
    code_operation_inicial,
    quantity_available,
    value_cif,
    calculated_value /* Artificial prop */,
    calculated_weight /* Artificial prop */,
  } = values;

  const {
    reference_corrected,
    description_product_corrected,
    nature_of_inventory_corrected,
    code_product_corrected,
    quantity_available_product_corrected,
    business_unit_product_corrected,
    convertion_factor_product_corrected,
    quantity_product_corrected,
    quantity_corrected,
    business_unit_corrected,
    quantity_garbage_corrected,
    net_weight_kilo_corrected,
    fob_value_corrected,
    code_tradeAgreement_corrected,
    name_tradeAgreement_corrected,
    agreement_corrected = "" /* Artificial prop */,
    code_subheading_corrected,
    integration_certificate_corrected,
    import_declaration_corrected,
    code_operation_corrected,
    code_operation_inicial_corrected,
    quantity_available_corrected,
    value_cif_corrected,
    calculated_value_corrected /* Artificial prop */,
    calculated_weight_corrected /* Artificial prop */,
  } = values;

  const reference_corrected_debounce = useDebounce(reference_corrected, 500);

  const {
    onCloseModal,
    isOpenSearchSupplies,
    onOpenSearchSupplies,
    createDataCorrection,
    setShowSupplies,
    setActiveView,
    dataToCorrectSupplies,
    setDataToCorrectSupplies,
    setRequestCreateCorrection,
    dataReference: dataReferenceCorrected,
  } = useContext(IntegracionBoxCrudContex);

  const handleResetCorrectedItem = () => {
    setValues((prev) => ({
      ...prev,
      description_product_corrected: "",
      nature_of_inventory_corrected: "",
      code_product_corrected: "",
      quantity_available_product_corrected: "",
      business_unit_product_corrected: "",
      convertion_factor_product_corrected: "",
      quantity_product_corrected: "",
      quantity_corrected: "",
      business_unit_corrected: "",
      quantity_garbage_corrected: "",
      net_weight_kilo_corrected: "",
      fob_value_corrected: "",
      code_tradeAgreement_corrected: "",
      name_tradeAgreement_corrected: "",
      agreement_corrected: "" /* Artificial prop */,
      code_subheading_corrected: "",
      integration_certificate_corrected: "",
      import_declaration_corrected: "",
      code_operation_corrected: "",
      code_operation_inicial_corrected: "",
      quantity_available_corrected: "",
      value_cif_corrected: "",
      calculated_value_corrected: "" /* Artificial prop */,
      calculated_weight_corrected: "",
    }));
  };

  const handleReset = () => {
    setShowSupplies(false);
    setActiveView(null);
    reset();
    setDataToCorrectSupplies(null);
  };

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: (data) => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, "error");
      }

      if (data?.data?.status?.code === 200) {
        invalidateQueries();
        openAlert(data?.data?.status?.message, "success");
      }
    },
  });

  const invalidateQueries = () => {
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] });
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.GARBAGE_CI] });
  };

  const handleAddSuply = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("id_corrected", values?.id_corrected);
    formData.append("reference_corrected", reference_corrected);
    formData.append(
      "net_weight_corrected",
      parseFloat(net_weight_kilo_corrected)
    );
    formData.append(
      "quantity_garbage_corrected",
      parseFloat(quantity_garbage_corrected)
    );
    formData.append(
      "quantity_product_corrected",
      parseFloat(quantity_product_corrected)
    );
    formData.append("quantity_corrected", parseFloat(quantity_corrected));
    formData.append("fob_value_corrected", parseFloat(fob_value_corrected));
    formData.append("type_corrected", percentageAndAmount === "%" ? 0 : 1);

    mutate({
      data: formData,
      Endpoint: environment.UPDATE_SUPPLY_CORRECTED,
      PropertyBody: "sendFormDataContentPOST",
    });

    /* createDataCorrection(formData, invalidateQueries); */
    handleReset();
  };

  useEffect(() => {
    setRequestCreateCorrection(environment.UPDATE_SUPPLY_CORRECTED);
  }, [setRequestCreateCorrection]);

  const { data: dataSuppliesPCorrected, isLoading: isLoadingSupplies } =
    useQuery({
      queryKey: [queryEnv?.SUPPLIES_CIC_ITEM],
      queryFn: () =>
        requestData({
          Endpoint: environment?.SHOW_SUPPLIES_CORRECTED,
          PropertyBody: "POST",
          data: {
            id_corrected: dataToCorrectSupplies?.id_corrected,
          },
        }),
    });

  const [first, setFirst] = useState(null);

  useEffect(() => {
    if (dataSuppliesPCorrected?.data?.status.code === 200) {
      const data = dataSuppliesPCorrected?.data?.status?.data;
      const {
        // New
        quantity_product_corrected,
        code_tradeAgreement_corrected,
        value_fob_unit_corrected,
        net_weight_unit_corrected,
        value_cif_unit_corrected,

        // Old
        type_quantity_garbage,
        quantity_product,
        code_tradeAgreement,
        net_weight_unit,
        value_cif_unit,
      } = data;

      setpercentageAndAmount(type_quantity_garbage === 0 ? "%" : "cant");
      setFirst(data?.reference_corrected);
      setValues((prev) => ({
        ...prev,
        /* fob_value: parseFloat(quantity_product) * parseFloat(value_cif_unit),
        fob_value_corrected: parseFloat(quantity_product_corrected) * parseFloat(value_cif_unit_corrected), */
        agreement: `${name_tradeAgreement ? name_tradeAgreement : ""} ${
          code_tradeAgreement ? " - " + code_tradeAgreement : ""
        }`,
        agreement_corrected: ` ${
          code_tradeAgreement_corrected ? code_tradeAgreement_corrected : ""
        }${
          name_tradeAgreement_corrected
            ? " - " + name_tradeAgreement_corrected
            : ""
        }`,
        calculated_value:
          parseFloat(quantity_product) * parseFloat(value_cif_unit),
        calculated_value_corrected:
          parseFloat(quantity_product_corrected) *
          parseFloat(value_cif_unit_corrected),
        calculated_weight:
          parseFloat(quantity_product) * parseFloat(net_weight_unit),
        calculated_weight_corrected:
          parseFloat(quantity_product_corrected) *
          parseFloat(net_weight_unit_corrected),
        ...data,
      }));
    }
  }, [dataSuppliesPCorrected]);

  const { data: dataReference, isLoading: isLoadingReference } = useQuery({
    queryKey: [queryEnv?.GET_REFERENCE_SEARCH, reference_corrected_debounce],
    queryFn: () =>
      requestData({
        Endpoint: environment?.GET_REFERENCES,
        PropertyBody: "POST",
        data: {
          reference: reference_corrected_debounce,
          code_destination: props?.destiny?.label,
        },
      }),
    enabled:
      !!reference_corrected_debounce && first !== reference_corrected_debounce,
  });

  useEffect(() => {
    if (!isNonEmptyString(reference_corrected_debounce))
      handleResetCorrectedItem();
  }, [reference_corrected_debounce]);

  useEffect(() => {
    setFirst(null);
    if (
      (isLoadingReference === false &&
        !!dataReference?.data?.status?.code === 200) ||
      dataReference?.data?.status?.data?.data?.length === 0
    ) {
      openAlert("No se encontró la referencia solicitada", "info");
      return handleResetCorrectedItem();
    }
    const data = dataReference?.data?.status?.data?.data[0];
    if (!data) return;
    setValues((prev) => ({
      ...prev,
      reference_corrected: data?.reference,
      description_product_corrected: data?.description_product,
      nature_of_inventory_corrected: data?.nature_of_inventory,
      code_product_corrected: data?.code_product,
      quantity_available_product_corrected: data?.quantity_available,
      business_unit_product_corrected: data?.business_unit_product,
      convertion_factor_product_corrected: data?.convertion_factor_product,
      /*  quantity_product_corrected, 
       quantity_corrected,  */
      business_unit_corrected: data?.business_unit,
      /* quantity_garbage_corrected, */
      net_weight_kilo_corrected: data?.net_weight_kilo,
      fob_value_corrected: data?.value_fob,
      code_tradeAgreement_corrected: data?.code_tradeAgreement,
      name_tradeAgreement_corrected: data?.name_tradeAgreement,
      agreement_corrected: "" /* Artificial prop */,
      code_subheading_corrected: data?.code_subheading,
      integration_certificate_corrected: data?.integration_certificate,
      import_declaration_corrected: data?.import_declaration,
      code_operation_corrected: data?.code_operation,
      code_operation_inicial_corrected: data?.code_operation_inicial,
      quantity_available_corrected: data?.quantity_available,
      value_cif_corrected: data?.value_cif_unit,
      /*  calculated_value_corrected, */
      /*  calculated_weight_corrected, */
    }));
  }, [dataReference?.data?.status?.data]);

  useEffect(() => {
    if (!dataReferenceCorrected) return;
    setValues({
      reference_corrected: dataReferenceCorrected?.reference,
      description_product_corrected:
        dataReferenceCorrected?.description_product,
      nature_of_inventory_corrected:
        dataReferenceCorrected?.nature_of_inventory,
      code_product_corrected: dataReferenceCorrected?.code_product,
      quantity_available_product_corrected:
        dataReferenceCorrected?.quantity_available_product,
      business_unit_product_corrected:
        dataReferenceCorrected?.business_unit_product,
      convertion_factor_product_corrected:
        dataReferenceCorrected?.convertion_factor_product,
      /*  quantity_product_corrected, 
       quantity_corrected,  */
      business_unit_corrected: dataReferenceCorrected?.business_unit_product,
      /* quantity_garbage_corrected, */
      net_weight_kilo_corrected: dataReferenceCorrected?.net_weight_kilo,
      fob_value_corrected: dataReferenceCorrected?.value_fob,
      code_tradeAgreement_corrected:
        dataReferenceCorrected?.code_tradeAgreement,
      name_tradeAgreement_corrected:
        dataReferenceCorrected?.name_tradeAgreement,
      agreement_corrected: "" /* Artificial prop */,
      code_subheading_corrected: dataReferenceCorrected?.code_subheading,
      integration_certificate_corrected:
        dataReferenceCorrected?.integration_certificate,
      import_declaration_corrected: dataReferenceCorrected?.import_declaration,
      code_operation_corrected: dataReferenceCorrected?.code_operation,
      code_operation_inicial_corrected:
        dataReferenceCorrected?.code_operation_inicial,
      quantity_available_corrected: dataReferenceCorrected?.quantity_available,
      value_cif_corrected: dataReferenceCorrected?.value_cif_unit,
    });
  }, [dataReferenceCorrected]);

  const updateItemQuantity = (newValue, sourceEvent) => {
    const { floatValue } = newValue;
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.value]: parseFloat(floatValue),
    };
    handleInputChange(target);
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchSupplies}
        onClose={onCloseModal}
        title={"Búsqueda por referencia"}
        size={"5xl"}
      >
        <FormSearchsupplies />
      </ModalGeneric>
      {isLoadingSupplies && <SpinnerComponent />}

      {!isLoadingSupplies && (
        <div className="d-flex justify-content-between ">
          <div className="col-6 borderforms">
            <div className="mb-3">
              <TitleHeader size={"22px"}>
                {`Item original ${
                  dataToCorrectSupplies?.item_code
                    ? "N°" + dataToCorrectSupplies?.item_code
                    : ""
                }`}
              </TitleHeader>
            </div>
            {/* Item original */}
            <form>
              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div className="d-flex">
                  <LabelGroup
                    tipo={"text"}
                    name={"reference"}
                    value={reference}
                    onChange={handleInputChange}
                    tag={"Referencia"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(reference) !==
                        safelyConvertToString(reference_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"description_product"}
                    value={description_product}
                    onChange={handleInputChange}
                    tag={"Descripción"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(description_product_corrected) !==
                        safelyConvertToString(description_product) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"nature_of_inventory"}
                    value={nature_of_inventory}
                    onChange={handleInputChange}
                    tag={"Naturaleza"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(nature_of_inventory_corrected) !==
                        safelyConvertToString(nature_of_inventory) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"code_product"}
                    value={code_product}
                    onChange={handleInputChange}
                    tag={"Código producto"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_product_corrected) !==
                        safelyConvertToString(code_product) && "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"quantity_available_product"}
                    value={quantity_available_product}
                    onChange={handleInputChange}
                    tag={"Saldo Prod disponible"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity_available_product_corrected) !==
                        parseFloat(quantity_available_product) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"business_unit_corrected"}
                    value={business_unit_product}
                    onChange={handleInputChange}
                    tag={"Unidad de producto"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(business_unit_product) !==
                        safelyConvertToString(
                          business_unit_product_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    decimalScale={4}
                    decimalSeparator="."
                    customInput={LabelGroup}
                    name={"convertion_factor_product"}
                    value={convertion_factor_product}
                    onChange={handleInputChange}
                    tag={"Factor conv."}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(convertion_factor_product) !==
                        parseFloat(convertion_factor_product_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"quantity_product"}
                    value={quantity_product}
                    onChange={handleInputChange}
                    tag={"Cantidad producto"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity_product_corrected) !==
                        parseFloat(quantity_product) && "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"quantity"}
                    value={quantity}
                    onChange={handleInputChange}
                    tag={"Cantidad"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity) !== parseFloat(quantity_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"business_unit"}
                    value={business_unit}
                    onChange={handleInputChange}
                    tag={"Unidad comercial"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(business_unit) !==
                        safelyConvertToString(business_unit_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"quantity_garbage"}
                    value={quantity_garbage}
                    onChange={handleInputChange}
                    tag={"Desperdicio"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity_garbage) !==
                        parseFloat(quantity_garbage_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"net_weight_kilo"}
                    value={net_weight_kilo}
                    onChange={handleInputChange}
                    tag={"Peso en kilos"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(net_weight_kilo) !==
                        parseFloat(net_weight_kilo_corrected) && "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"fob_value"}
                    value={fob_value}
                    onChange={handleInputChange}
                    tag={"Valor en US$"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(fob_value) !==
                        parseFloat(fob_value_corrected) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"agreement"}
                    value={agreement}
                    onChange={handleInputChange}
                    tag={"Acuerdo"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(agreement) !==
                        safelyConvertToString(agreement_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"code_subheading"}
                    value={code_subheading}
                    onChange={handleInputChange}
                    tag={"Subpartida"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_subheading) !==
                        safelyConvertToString(code_subheading_corrected) &&
                      "BorderCorrect"
                    }
                    maxLength={MaxLength?.global?.code_subheading}
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"integration_certificate"}
                    value={integration_certificate}
                    onChange={handleInputChange}
                    tag={"Certificado"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(integration_certificate) !==
                        safelyConvertToString(
                          integration_certificate_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"import_declaration"}
                    value={import_declaration}
                    onChange={handleInputChange}
                    tag={"Decl de importación"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(import_declaration) !==
                        safelyConvertToString(import_declaration_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"code_operation"}
                    value={code_operation}
                    onChange={handleInputChange}
                    tag={"Operación"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_operation) !==
                        safelyConvertToString(code_operation_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"code_operation_inicial"}
                    value={code_operation_inicial}
                    onChange={handleInputChange}
                    tag={"Inicial"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_operation_inicial) !==
                        safelyConvertToString(
                          code_operation_inicial_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"quantity_available"}
                    value={quantity_available}
                    onChange={handleInputChange}
                    tag={"Saldo disponible"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity_available) !==
                        parseFloat(quantity_available_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"value_cif"}
                    value={value_cif}
                    onChange={handleInputChange}
                    tag={"Saldo en valor."}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(value_cif) !==
                        parseFloat(value_cif_corrected) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"calculated_value"}
                    value={calculated_value}
                    onChange={handleInputChange}
                    tag={"Valor calculado"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(calculated_value) !==
                        parseFloat(calculated_value_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"calculated_weight"}
                    value={calculated_weight}
                    onChange={handleInputChange}
                    tag={"Peso calculado"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(calculated_weight) !==
                        parseFloat(calculated_weight_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>
            </form>
          </div>

          {/* Nueva data  */}
          <div className="col-6 borderforms">
            <div className="mb-3">
              <TitleHeader size={"22px"}>
                {`Item Corregido ${
                  dataToCorrectSupplies?.item_code_corrected
                    ? `N° ${dataToCorrectSupplies?.item_code_corrected}`
                    : ""
                }`}
              </TitleHeader>
            </div>
            <form onSubmit={handleAddSuply}>
              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div className="d-flex">
                  <LabelGroup
                    tipo={"text"}
                    name={"reference_corrected"}
                    value={reference_corrected || ""}
                    onChange={handleInputChange}
                    tag={"Referencia"}
                    size={100}
                    desicion={!!ActionCorrect}
                    changeValue={
                      safelyConvertToString(reference) !==
                        safelyConvertToString(reference_corrected) &&
                      "BorderCorrect"
                    }
                  />
                  {!ActionCorrect && (
                    <div
                      style={{ marginTop: 28, marginLeft: 10 }}
                      onClick={onOpenSearchSupplies}
                    >
                      <IconGeneric color="#313131" as={RiSearch2Line} />
                    </div>
                  )}
                </div>

                <div>
                  <LabelGroup
                    name={"description_product_corrected"}
                    value={description_product_corrected}
                    onChange={handleInputChange}
                    tag={"Descripción"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(description_product_corrected) !==
                        safelyConvertToString(description_product) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"nature_of_inventory_corrected"}
                    value={nature_of_inventory_corrected}
                    onChange={handleInputChange}
                    tag={"Naturaleza"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(nature_of_inventory_corrected) !==
                        safelyConvertToString(nature_of_inventory) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"code_product_corrected"}
                    value={code_product_corrected}
                    onChange={handleInputChange}
                    tag={"Código producto"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_product_corrected) !==
                        safelyConvertToString(code_product) && "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"quantity_available_product_corrected"}
                    value={quantity_available_product_corrected}
                    onChange={handleInputChange}
                    tag={"Saldo Prod disponible"}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(quantity_available_product_corrected) !==
                        parseFloat(quantity_available_product) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"business_unit_product_corrected"}
                    value={business_unit_product_corrected}
                    onChange={handleInputChange}
                    tag={"Unidad de producto"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(business_unit_product) !==
                        safelyConvertToString(
                          business_unit_product_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    decimalSeparator="."
                    customInput={LabelGroup}
                    name={"convertion_factor_product_corrected"}
                    onChange={handleInputChange}
                    tag={"Factor conv."}
                    size={100}
                    desicion={true}
                    changeValue={
                      parseFloat(convertion_factor_product) !==
                        parseFloat(convertion_factor_product_corrected) &&
                      "BorderCorrect"
                    }
                    value={convertion_factor_product_corrected}
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={!!ActionCorrect}
                    name={"quantity_product_corrected"}
                    value={quantity_product_corrected}
                    changeValue={
                      parseFloat(quantity_product) !==
                        parseFloat(quantity_product_corrected) &&
                      "BorderCorrect"
                    }
                    tag={"Cantidad producto"}
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={!!ActionCorrect}
                    name={"quantity_corrected"}
                    value={quantity_corrected}
                    tag={"Cantidad"}
                    changeValue={
                      parseFloat(quantity) !== parseFloat(quantity_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"business_unit_corrected"}
                    value={business_unit_corrected}
                    onChange={handleInputChange}
                    tag={"Unidad comercial"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(business_unit) !==
                        safelyConvertToString(business_unit_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={!!ActionCorrect}
                    name={"quantity_garbage_corrected"}
                    value={quantity_garbage_corrected}
                    tag={"Desperdicio"}
                    changeValue={
                      parseFloat(quantity_garbage) !==
                        parseFloat(quantity_garbage_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={!!ActionCorrect}
                    name={"net_weight_kilo_corrected"}
                    value={net_weight_kilo_corrected}
                    tag={"Peso en kilos"}
                    changeValue={
                      parseFloat(net_weight_kilo) !==
                        parseFloat(net_weight_kilo_corrected) && "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={!!ActionCorrect}
                    name={"fob_value_corrected"}
                    value={fob_value_corrected}
                    tag={"Valor en US$"}
                    changeValue={
                      parseFloat(fob_value) !==
                        parseFloat(fob_value_corrected) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"agreement_corrected"}
                    value={agreement_corrected}
                    onChange={handleInputChange}
                    tag={"Acuerdo"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(agreement) !==
                        safelyConvertToString(agreement_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"code_subheading_corrected"}
                    value={code_subheading_corrected}
                    onChange={handleInputChange}
                    tag={"Subpartida"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_subheading) !==
                        safelyConvertToString(code_subheading_corrected) &&
                      "BorderCorrect"
                    }
                    maxLength={MaxLength?.global?.code_subheading}
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"integration_certificate_corrected"}
                    value={integration_certificate_corrected}
                    onChange={handleInputChange}
                    tag={"Certificado"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(integration_certificate) !==
                        safelyConvertToString(
                          integration_certificate_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"import_declaration_corrected"}
                    value={import_declaration_corrected}
                    onChange={handleInputChange}
                    tag={"Decl de importación"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(import_declaration) !==
                        safelyConvertToString(import_declaration_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"code_operation_corrected"}
                    value={code_operation_corrected}
                    onChange={handleInputChange}
                    tag={"Operación"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_operation) !==
                        safelyConvertToString(code_operation_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"code_operation_inicial_corrected"}
                    value={code_operation_inicial_corrected}
                    onChange={handleInputChange}
                    tag={"Inicial"}
                    size={100}
                    desicion={true}
                    changeValue={
                      safelyConvertToString(code_operation_inicial) !==
                        safelyConvertToString(
                          code_operation_inicial_corrected
                        ) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={true}
                    name={"quantity_available_corrected"}
                    value={quantity_available_corrected}
                    tag={"Saldo disponible"}
                    changeValue={
                      parseFloat(quantity_available) !==
                        parseFloat(quantity_available_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>
              </SimpleGrid>

              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={true}
                    name={"value_cif_corrected"}
                    value={value_cif_corrected}
                    tag={"Saldo en valor."}
                    changeValue={
                      parseFloat(value_cif) !==
                        parseFloat(value_cif_corrected) && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={true}
                    name={"calculated_value_corrected"}
                    value={calculated_value_corrected}
                    tag={"Valor calculado"}
                    changeValue={
                      parseFloat(calculated_value) !==
                        parseFloat(calculated_value_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <NumericFormat
                    allowNegative={false}
                    onValueChange={updateItemQuantity}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={decimal_values_precision}
                    customInput={LabelGroup}
                    size={100}
                    desicion={true}
                    name={"calculated_weight_corrected"}
                    value={calculated_weight_corrected}
                    tag={"Peso calculado"}
                    changeValue={
                      parseFloat(calculated_weight) !==
                        parseFloat(calculated_weight_corrected) &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div className="mt-4   d-flex justify-content-center ">
                  <RadioBtn
                    options={options}
                    setOnly={setpercentageAndAmount}
                    state={percentageAndAmount}
                  />
                </div>
              </SimpleGrid>

              <div className="mb-3 ms-5 mt-3">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexWrap={"wrap"}
                >
                  <ButtonStyled bgColor={"#bababa"} onClick={handleReset}>
                    Cerrar
                  </ButtonStyled>
                  {!ActionCorrect && (
                    <ButtonStyled type="submit">Agregar</ButtonStyled>
                  )}
                </Box>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FormCorrectedSupplies;
