/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import ModalDelete from "components/modal/ModalDelete";
import Pagination from "components/Pagination/Pagination";
import TablePass from "components/Tables/TablesPass";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { __GetItems } from "request/Forms/_CrudForm";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  Title,
  TitleItemFmm,
  TitleServiceItemFmmShow,
} from "../arrayHeadItems";
import { FormUploaderItem } from "../FormExit/UploaderItem/FormUploaderItem";
import { UploaderItems } from "../FormExit/UploaderItem/UploaderItems";
import TbodyItems from "../Table/TbodyItems";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import AddServiceItem from "../FormEntry/AddItemForm/AddServiceItem";
import FormUploaderServiceItem from "../FormExit/UploaderItem/FormUploaderServiceItem";
import TBodyServiceItem from "../Table/TBodyServiceItem";
import AddProductsForm from "../FormExit/UploaderItem/AddProductsForm";
import AddIncomesForm from "../FormExit/UploaderItem/AddIncomesForm";
import { useNotations } from "context/NotationsContext";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import InputSelect from "components/InputSelect/InputSelect";

export const ItemsFMM = ({
  dataToEdit,
  setItemss,
  itemss,
  valueQualified,
  selectItemForm,
  codeForm,
  idQualified,
  valuesHeader,
  refund,
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const {
    update,
    dataToEditItems,
    isOpenDelete,
    onCloseModal,
    deleteData,
    setRequestDelete,
    dataToDelete,
    dataToCopyItems,
    setCurrentItemLength,
  } = useContext(FormCrudCRUDContext);

  // tabla
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation(); // navigate
  const [openAlert] = AlertErrorAlert();
  const [isOpenAddProductForm, setIsOpenAddProductForm] = useState(false);
  const [isOpenAddIncomesForm, setIsOpenAddIncomesForm] = useState(false);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })


  const { FormExitNumber = "" } = queryString.parse(location.search);
  const { setDataCurrent } = useNotations();

  const [values, handleInputChange, reset, setValues] = useForm({
    product_code: "",
    factor_conv: "",
    product_unit: "",
    code_subheading: "",
    product_description: "",
    us_freight: "",
    us_insurance: "",
    other_us_expenses: "",
    us_fob_value: "",
    cif_us_value: "",
    cif_cop_value: "",
    quantity: 0,
    number_packages: "",
    net_weight: 0,
    gross_weight: "",
    code_businessUnit: "",
    product_quantity: 0,
    reference_description: "",
    output_form_item_description: "",
    adjustment_justification_fob: "",
    added_value_us: "",
    refund_value_us: "",
  });

  useEffect(() => {
    let isMounted = true;
    setRequestDelete(environment.DELETE_ITEMS_EXIT);
    return () => {
      isMounted = false;
    };
  }, [FormExitNumber, update]);

  const { requestData } = useGlobalContext();

  const {
    data: res = {},
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["getItemsFormExit", FormExitNumber, update, page, orderBy?.value],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_iTEM_EXIT,
        pageParam: page,
        data: createFormData(),
        PropertyBody: "sendJSONContentPOST",
      }),
  });

  useEffect(() => {
    if (isSuccess) {
      setCurrentPage(res?.data?.status?.data?.data);
      setDataCurrent((prev) => ({
        ...prev,
        itemCurrent: res?.data?.status?.data?.data,
      }));
      setCurrentItemLength(res?.data?.status?.data?.data?.length);
      setMaxPage(res?.data?.status?.data?.last_page);
    }

    if (isError) {
      openAlert(res?.data?.status.message, "error");
    }
  }, [isFetching]);

  const createFormData = () => {
    const formData = new FormData();
    formData.append("outputForm_id", FormExitNumber);
    formData.append("length", 15);
    orderBy?.value && formData.append("orderBy", orderBy?.value);

    return formData;
  };

  useEffect(() => {
    let isMounted = true;
    if (dataToEditItems) setItemss(true);
    if (dataToCopyItems) setItemss(true);
    return () => {
      isMounted = false;
    };
  }, [dataToEditItems, dataToCopyItems]);

  const tBody = valuesHeader?.services === 1 ? TBodyServiceItem : TbodyItems;

  const tHead =
    valuesHeader?.services === 1 ? TitleServiceItemFmmShow : TitleItemFmm;

  return (
    <>
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Desear eliminar el elemento ${dataToDelete?.description_product}`}
        onDelete={deleteData}
      />

      <HeaderSearch>
        <UploaderItems
          codeForm={codeForm}
          idQualified={idQualified}
          set={setItemss}
          itemss={itemss}
          reset={reset}
          setIsOpenAddProductForm={setIsOpenAddProductForm}
          isOpenAddProductForm={isOpenAddProductForm}
          setIsOpenAddIncomesForm={setIsOpenAddIncomesForm}
          isOpenAddIncomesForm={isOpenAddIncomesForm}
          children={<InputSelect
            data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
            value={orderBy}
            name="order_by"
            set={(e) => {
              setOrderBy(e);
              setPage(1);
            }}
            placeholder="Ordenar por"
            inputAttrs={{
              color: '#5f5f',
            }}
            size={30}
            isClearable={false}
          />}
        />
        {itemss &&
          (valuesHeader?.services !== 1 ? (
            <div id="contenedores" className="my-2">
              <FormUploaderItem
                idUserCalificado={valueQualified}
                set={setItemss}
                itemss={itemss}
                values={values}
                handleInputChange={handleInputChange}
                reset={reset}
                setValues={setValues}
                selectItemForm={selectItemForm}
                valuesHeader={valuesHeader}
                valueQualified={valueQualified}
                refund={refund}
              />
            </div>
          ) : (
            <FormUploaderServiceItem
              valuesHeader={valuesHeader}
              set={setItemss}
            />
          ))}
        {isOpenAddProductForm && (
          <AddProductsForm
            setIsOpenAddProductForm={setIsOpenAddProductForm}
            isOpenAddProductForm={isOpenAddProductForm}
          />
        )}
        {isOpenAddIncomesForm && (
          <AddIncomesForm
            setIsOpenAddIncomesForm={setIsOpenAddIncomesForm}
            isOpenAddIncomesForm={isOpenAddIncomesForm}
          />
        )}

        <TablePass
          thead={tHead}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={tBody}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};
