import { H2 } from "pages/Pass-Form/stylePases";

const Descriptions = ({ values, formCorrected }) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-5 ">
        <div className="d-flex ">
          <H2 className="fw-bold ">Tipo de cuadro:</H2>
          <span className="ms-2">
            {values?.destiny
              ? values?.destiny
              : formCorrected?.newData?.data?.destiny}
          </span>
        </div>
        <div>
          <H2 className="fw-bold">Plazo:</H2>
        </div>
        <div></div>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex">
          <H2 className="fw-bold">Observación :</H2>
          <span className="ms-2">
            {values?.observations
              ? values?.observations
              : formCorrected?.newData?.data?.observations}
          </span>
        </div>
      </div>
    </>
  );
};

export default Descriptions;
