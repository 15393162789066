import { Button, Icon, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { MdInventory, MdOutlineInventory, MdOutlineLibraryAddCheck, MdRemoveRedEye } from "react-icons/md";

import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import { TheadTableLog, TheadTableLogCI, TheadTableLogInventory, TheadTableLoginventoryBalance, TheadTableLogOp } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { useEffect, useState } from "react";
import TBodyTemporaryLog from "./TBodyTemporaryLog";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useSelector } from "react-redux";
import { __GetTrackingLog } from "request/Monitoring/__Get_Monitoring";
import { useModal } from "hooks/useModal";
import TBodyLogOp from "./TBodyLogOp";
import TBodyLogCI from "./TBodyLogCI";
import TBodyLogInventory from "./TBodyLogInventory";
import Pagination from "components/Pagination/Pagination";
import { FaBalanceScale } from "react-icons/fa";
import TBodyLogBalance from "./TBodyLogBalance";

const TBodyTrackingLog = ({ data }) => {
  const [infoTemporary, setInfoTemporary] = useState(data?.dataTemporary);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [typeLog, setTypeLog] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const [dataTableLog, setDataTableLog] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [isOpenTableLog, onOpenTableLog, onCloseTableLog] = useModal();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  console.log(data, 'data que entra en tbody del log')

  /* const sendDataTableOperations = () => {
    onOpen();
    setInfoTemporary(data?.dataTemporary)
  } */

  const getLogOP = async () => {
    setTypeLog(1);
    setLoading(true)
    const formData = new FormData();
    formData.append('tracking_id', data?.tracking_id);
    formData.append('dataLog', 'dataOP')
    formData.append('reference', data?.reference);
    try {
      const res = await __GetTrackingLog(token, formData, page);
      const infoLog = res?.data?.status?.data?.dataOP?.data;
      if (infoLog) {
        setCurrentPage(infoLog);
      }
      setMaxPage(res?.data?.status?.data?.dataOP?.last_page);
    } catch {
      console.log('error')
    } finally {
      onOpenTableLog();
      setLoading(false)
    }
  }

  const getLogCI = async () => {
    setTypeLog(2);
    setLoading(true)
    const formData = new FormData();
    formData.append('tracking_id', data?.tracking_id);
    formData.append('dataLog', 'dataCISupplies')
    formData.append('reference', data?.reference);
    try {
      const res = await __GetTrackingLog(token, formData, page);
      console.log(res, 'respuesta cuadro integración');
      const infoLog = res?.data?.status?.data?.dataCISupplies?.data;
      if (infoLog) {
        setCurrentPage(infoLog);
      }
      setMaxPage(res?.data?.status?.data?.dataCISupplies?.last_page);
    } catch {
      console.log('error')
    } finally {
      onOpenTableLog();
      setLoading(false)
    }
  }

  const getLogInventory = async () => {
    setTypeLog(3);
    setLoading(true);
    const formData = new FormData();
    formData.append('tracking_id', data?.tracking_id);
    formData.append('dataLog', 'dataInventory')
    formData.append('reference', data?.reference);
    try {
      const res = await __GetTrackingLog(token, formData, page);
      const infoLog = res?.data?.status?.data?.dataInventory?.data;
      if (infoLog) {
        setCurrentPage(infoLog);
      }
      setMaxPage(res?.data?.status?.data?.dataInventory?.last_page);
    } catch {
      console.log('error')
    } finally {
      onOpenTableLog();
      setLoading(false)
    }
  }

  const getLogInventoryBalance = async () => {
    setTypeLog(4);
    setLoading(true);
    const formData = new FormData();
    formData.append('tracking_id', data?.tracking_id);
    formData.append('dataLog', 'dataInventoryBalanceRef')
    formData.append('reference', data?.reference);
    try {
      const res = await __GetTrackingLog(token, formData, page);
      const infoLog = res?.data?.status?.data?.dataInventoryBalanceRef?.data;
      if (infoLog) {
        setCurrentPage(infoLog);
      }
      setMaxPage(res?.data?.status?.data?.dataInventoryBalanceRef?.last_page);
    } catch {
      console.log('error')
    } finally {
      onOpenTableLog();
      setLoading(false)
    }
  }

  const getLogInventoryRef = async () => {
    console.log('data', data)
  }

  useEffect(() => {
    if (isOpenTableLog) {
      if (typeLog === 1) {
        getLogOP();
      } else if (typeLog === 2) {
        getLogCI();
      } else if (typeLog === 3) {
        getLogInventory();
      } else if (typeLog === 4) {
        getLogInventoryBalance();
      } else if (typeLog === 5) {
        getLogInventoryRef();
      }
    }
  }, [page, isOpenTableLog]);

  useEffect(() => {
    if (!isOpenTableLog) {
      setPage(1);
    }
  }, [isOpenTableLog]);

  return (
    <>
      {/* <ModalGeneric
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            title="Log de temporales"
            size='6xl'
        >
          <TableOrder
            thead={TheadTableLog}
            data={infoTemporary}
            setData={setInfoTemporary}
            tbodyData={TBodyTemporaryLog}
          />
          <div className="d-flex w-100 justify-content-end mt-3 mb-2">
            <Button onClick={onClose} >Cerrar</Button>
          </div>
        </ModalGeneric> */}

      <ModalGeneric
        isOpen={isOpenTableLog}
        onOpen={onOpenTableLog}
        onClose={onCloseTableLog}
        size={typeLog === 2 ? '6xl' : '4xl'}
        title={
          typeLog === 1
            ? 'Log orden de producción'
            : typeLog === 2
              ? 'Log cuadro de integración'
              : typeLog === 3
                ? 'Log inventario'
                : typeLog === 4
                  ? 'Log saldo'
                  : typeLog === 5
                    ? 'Log inventario de referencia'
                    : ''
        }
      >
        <TableOrder
          thead={
            typeLog === 1
              ? TheadTableLogOp
              : typeLog === 2
                ? TheadTableLogCI
                : typeLog === 3
                  ? TheadTableLogInventory
                  : typeLog === 4 || typeLog === 5
                    ? TheadTableLoginventoryBalance
                    : ''
          }
          data={currentPage}
          setData={setInfoTemporary}
          tbodyData={
            typeLog === 1
              ? TBodyLogOp
              : typeLog === 2
                ? TBodyLogCI
                : typeLog === 3
                  ? TBodyLogInventory
                  : typeLog === 4 || typeLog === 5
                    ? TBodyLogBalance
                    : ''
          }
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
        <div className="d-flex w-100 justify-content-end mt-3 mb-2">
          <Button onClick={onCloseTableLog} >Cerrar</Button>
        </div>
      </ModalGeneric>

      <Tr>
        <Td className="text-center"> {data?.reference !== null ? data?.reference : "N/A"}</Td>
        <Td className="text-center">
          {data?.dataOP?.length
            ? (
              <IconGeneric color={data?.reference !== null ? "#F0BF6F" : "#636060"} as={AiOutlineUnorderedList} onClick={data?.reference !== null ? getLogOP : null} />
            )
            : 'N/A'}

        </Td>
        <Td className="text-center">
          {data?.dataCISupplies?.data?.length
            ? (
              <IconGeneric color={data?.reference !== null ? "#7C6BF8" : "#636060"} as={MdOutlineLibraryAddCheck} onClick={data?.reference !== null ? getLogCI : null} />
            )
            : 'N/A'}
        </Td>
        <Td className="text-center">
          {data?.dataInventory?.length
            ? (
              <IconGeneric color={data?.reference !== null ? "#39D9BE" : "#636060"} as={MdInventory} onClick={data?.reference !== null ? getLogInventory : null} />
            )
            : 'N/A'}
        </Td>

        {/** ****Front-end******/}
        <Td className="text-center">
          {data?.dataInventoryBalanceRef?.length
            ? (
              <IconGeneric color={data?.reference !== null ? "#89256F" : "#636060"} as={FaBalanceScale} onClick={data?.reference !== null ? getLogInventoryBalance : null} />
            )
            : 'N/A'}
        </Td>
        <Td className="text-center">
          {data?.dataInventoryRef?.length
            ? (
              <IconGeneric color={data?.reference !== null ? "#556EF7" : "#636060"} as={MdOutlineInventory} onClick={data?.reference !== null ? getLogInventoryRef : null} />
            )
            : 'N/A'}
        </Td>

        {/* <Td className="text-center" >
        {data?.dataTemporary?.length ? (
          <button onClick={sendDataTableOperations} style={{padding: "0 20px"}}>
            <Icon color='#006EB8' as={MdRemoveRedEye} />
          </button>
          ) : ''}
        </Td> */}

      </Tr>
    </>
  );
};

export default TBodyTrackingLog;
