import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  List,
  ListItem,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Progress,
  Alert,
  AlertIcon,
  Icon,
  Link,
} from "@chakra-ui/react";

import { MdOutlineImage } from "react-icons/md";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FaEdit, FaEye, FaTrash, FaUpload, FaRedoAlt } from "react-icons/fa";
import { GroupInputUpload } from "pages/Entry_and_exit_forms/GroupInputUpload";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import { useDropzone } from "react-dropzone";
import { motion, AnimatePresence } from "framer-motion";
import ZoomImage from "components/DispatchCorrections/ZoomedImage";
import { Modalcustomizable } from "components/modal/ModalPass";
import { BiLink } from "react-icons/bi";
import { getFileIcon } from "pages/CustomsClearance/Adjunto/ExistingFiles";

// Componente para mostrar un documento individual
export const DocumentItem = ({ document, isExisting = true, children }) => {
  const nameFile =
    document?.name ??
    document?.other_attachment_name ??
    document?.attachment_name ??
    document?.name_attachment;

  const fileExtension = nameFile?.split(".")?.pop()?.toLowerCase();
  const allowExtension = ["png", "jpg", "jpeg", "gif"];
  const openImageView = () => {
    onOpenPreview();
  };

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  return (
    <>
      <Modalcustomizable
        title={"Previsualización "}
        isOpen={isOpenPreview}
        onClose={onClosePreview}
        rightButtonText={"Confirmar"}
        iconComponent={MdOutlineImage}
        hiddenButtons={true}
        size={"4xl"}
      >
        {allowExtension.includes(fileExtension) && (
          <ZoomImage src={document?.attachment_url} />
        )}

        {fileExtension === "pdf" && (
          <iframe
            title="preview"
            src={document?.attachment_url}
            width="100%"
            height="500px"
          />
        )}
      </Modalcustomizable>

      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
      >
        <ListItem>
          <Flex
            align="center"
            justify="space-between"
            p={2}
            // _hover={{ bg: "gray.100" }}
            borderRadius="md"
            flexDir={"row"}
            gap={3}
          >
            <Flex align="center">
              <Icon as={getFileIcon(nameFile)} boxSize={5} mr={3} />
              <Link
                href={document?.attachment_url}
                isExternal
                color="blue.500"
                fontWeight="medium"
                _hover={{ textDecoration: "underline" }}
                isTruncated
              >
                {nameFile}
                <Icon as={BiLink} ml={1} boxSize={4} />
              </Link>
            </Flex>

            {children}
          </Flex>
        </ListItem>
      </motion.div>
    </>
  );
};

// Componente OtherAttachment mejorado
export const OtherAttchment = ({
  otherDocuments = [],
  titulo,
  attachment,
  setAttachment,
  viewBtn = false,
  viewBtnDelete = true,
  endPointDelete = null,
  responseDeleteFile = () => {},
  removeFileAttachment = () => {},
  update = () => {},
  alertDelete = false,
  isLoading = false,
  documentPreview = false,
  maxFiles = 5,
  acceptedFileTypes = {
    "application/pdf": [".pdf"],
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
}) => {
  const [openAlert] = AlertErrorAlert();
  const {
    isOpen: isOpenUploadModal,
    onOpen: openUploadModal,
    onClose: closeUploadModal,
  } = useDisclosure();

  const { requestData, IsTheFormLocked } = useGlobalContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [values, handleInputChange, reset] = useForm({
    description: "",
    other_attachment_comment: "",
    attachment_number: "",
    attachment_date: "",
  });

  const [valuesEdit, handleInputEdit, resetEdit, setValuesEdit] = useForm({
    index: null,
    attachment_description: "",
    attachment_number: "",
    attachment_comment: "",
    attachment_date: "",
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
      setCurrentFileIndex(0);
      onOpen();
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        openAlert(
          `Archivo rechazado: ${file.file.name}. ${file.errors[0].message}`,
          "error"
        );
      });
    },
  });

  const handleAcceptUpload = () => {
    const currentFile = filesToUpload[currentFileIndex];
    if (currentFile) {
      const fileExtension = currentFile.name.split(".").pop().toLowerCase();
      const extensionType =
        Object.entries(acceptedFileTypes).find(([, extensions]) =>
          extensions.includes(`.${fileExtension}`)
        )?.[0] || "application/octet-stream";

      const newAttachment = {
        file: currentFile,
        other_attachment_description: values.description,
        other_attachment_number: values.attachment_number,
        other_attachment_comment: values.other_attachment_comment,
        extension: extensionType,
        other_attachment_date: values.attachment_date,
      };

      setAttachment((prev) => [...prev, newAttachment]);
      reset();

      if (currentFileIndex < filesToUpload.length - 1) {
        setCurrentFileIndex(currentFileIndex + 1);
      } else {
        update(attachment);
        setFilesToUpload([]);
        onClose();
      }
    }
  };

  const editAttachmentLocal = (index, type = "") => {
    if (type === "localFile") {
      const fileData = attachment[index];
      setValuesEdit({
        index,
        type,
        attachment_comment: fileData?.other_attachment_comment ?? "",
        name: fileData?.file?.name ?? "",
        attachment_description: fileData?.other_attachment_description ?? "",
        attachment_number: fileData?.other_attachment_number ?? "",
        extension: fileData?.extension ?? fileData?.other_attachment_type ?? "",
        attachment_date: fileData?.other_attachment_date ?? "",
      });
    } else if (type === "serverFile") {
      const fileData = otherDocuments[index];
      const documentName = fileData?.name || fileData?.other_attachment_name;
      setValuesEdit({
        index,
        type,
        id: fileData?.id,
        attachment_comment: fileData?.other_attachment_comment,
        name: documentName,
        attachment_description: fileData?.other_attachment_description || "",
        attachment_number:
          fileData?.attachment_number ||
          fileData?.other_attachment_number ||
          "",
        url: fileData?.attachment_url || fileData?.url || "",
        extension: fileData?.other_attachment_type || null,
        attachment_date: fileData?.other_attachment_date || "",
      });
    }
    openUploadModal();
  };

  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const removeFileByIdentifier = (index, id, Endpoint) => {
    const requestData = {
      resourceIdentifier: id,
      Endpoint,
    };

    mutate(requestData, {
      onSuccess: (res) => responseDeleteFile(res, index, id),
    });
  };

  const uploadFiles = async () => {
    setUploadProgress(0);
    setUploadError(null);

    try {
      for (let i = 0; i < attachment.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        update(attachment[i]);
        setUploadProgress(((i + 1) / attachment.length) * 100);
      }

      openAlert("Archivos cargados exitosamente", "success");
    } catch (error) {
      setUploadError(
        "Error al cargar los archivos. Por favor, intente nuevamente."
      );
    }
  };

  const retryUpload = () => {
    setUploadError(null);
    uploadFiles();
  };

  const isValidUpload =
    values.description && values.attachment_number && values.attachment_date;
  const IconPreview = documentPreview ? FaEye : FaEdit;
  const textTooltipPreview = documentPreview
    ? "Ver documento"
    : "Editar documento";

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="xl" fontWeight="bold">
          {titulo}
        </Text>
        {/*    {viewBtn && !   && (
          <Tooltip label="Agregar documentos">
            <IconButton
              icon={<FaUpload />}
              colorScheme="teal"
              onClick={onOpenTypified}
              isLoading={isLoading}
            />
          </Tooltip>
        )} */}
      </Flex>

      <ModalGeneric
        isOpen={isOpen}
        onClose={onClose}
        title={`Detalles del documento (${currentFileIndex + 1}/${
          filesToUpload.length
        })`}
        size="lg"
      >
        <VStack spacing={4} align="stretch">
          <Text>Archivo: {filesToUpload[currentFileIndex]?.name}</Text>
          <LabelGroup
            name="description"
            onChange={handleInputChange}
            value={values.description}
            tag="Descripción"
            size={100}
            maxiLength="50"
            required
          />
          <LabelGroup
            name="other_attachment_comment"
            onChange={handleInputChange}
            value={values.other_attachment_comment}
            tag="Comentario"
            size={100}
            maxiLength="50"
          />
          <LabelGroup
            name="attachment_number"
            onChange={handleInputChange}
            value={values.attachment_number}
            tag="Número del anexo"
            size={100}
            required
          />
          <LabelGroup
            name="attachment_date"
            onChange={handleInputChange}
            value={values.attachment_date}
            tag="Fecha"
            tipo="date"
            size={100}
            required
          />
          <Button
            colorScheme="blue"
            onClick={handleAcceptUpload}
            isDisabled={!isValidUpload}
          >
            {currentFileIndex < filesToUpload.length - 1
              ? "Siguiente"
              : "Finalizar"}
          </Button>
        </VStack>
      </ModalGeneric>

      {!IsTheFormLocked && (
        <Box
          {...getRootProps()}
          borderWidth={2}
          borderStyle="dashed"
          borderRadius="md"
          p={4}
          textAlign="center"
          bg={isDragActive ? "gray.100" : "white"}
          transition="all 0.2s"
          _hover={{ bg: "gray.50" }}
        >
          <input {...getInputProps()} id="fileInput" />
          <Text>
            {isDragActive
              ? "Suelta los archivos aquí"
              : "Arrastra y suelta archivos aquí, o haz clic para seleccionar"}
          </Text>
        </Box>
      )}

      <VStack spacing={2} mt={4} align="stretch">
        <List spacing={3}>
          <AnimatePresence>
            {attachment?.map((file, index) => (
              <DocumentItem
                key={`new-${index}`}
                document={file}
                isExisting={false}
              >
                <Tooltip label="Editar">
                  <IconButton
                    icon={<FaEdit />}
                    fontSize="12px"
                    size={"xs"}
                    onClick={() => editAttachmentLocal(index, "localFile")}
                  />
                </Tooltip>
                <Tooltip label="Eliminar">
                  <IconButton
                    icon={<FaTrash />}
                    fontSize="12px"
                    size={"xs"}
                    colorScheme="red"
                    onClick={() =>
                      removeFileAttachment(index, file.id, "localFile")
                    }
                  />
                </Tooltip>
              </DocumentItem>
            ))}
          </AnimatePresence>
        </List>
      </VStack>

      {attachment.length > 0 && (
        <Button
          mt={4}
          colorScheme="blue"
          onClick={uploadFiles}
          leftIcon={<FaUpload />}
          isLoading={uploadProgress > 0 && uploadProgress < 100}
          loadingText="Cargando..."
        >
          Cargar {attachment.length} archivo(s)
        </Button>
      )}

      {uploadProgress > 0 && (
        <Progress value={uploadProgress} size="sm" colorScheme="blue" mt={4} />
      )}

      {uploadError && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          <Text>{uploadError}</Text>
          <Button
            leftIcon={<FaRedoAlt />}
            ml="auto"
            size="sm"
            onClick={retryUpload}
          >
            Reintentar
          </Button>
        </Alert>
      )}

      {/*   <VStack spacing={2} mt={4} align="stretch">
        <List spacing={3}>
          <AnimatePresence>
            {otherDocuments.map((doc, index) => (
              <DocumentItem
                key={`existing-${index}`}
                document={doc}
                isExisting={true}
              >
                <Tooltip label={textTooltipPreview}>
                  <IconButton
                    icon={<IconPreview />}
                    fontSize="12px"
                    size={"xs"}
                    colorScheme="blue"
                    onClick={() => editAttachmentLocal(index, "serverFile")}
                  />
                </Tooltip>
                {viewBtnDelete && !IsTheFormLocked && (
                  <Tooltip label="Eliminar">
                    <IconButton
                      icon={<FaTrash />}
                      fontSize="12px"
                      size={"xs"}
                      colorScheme="red"
                      onClick={() =>
                        endPointDelete
                          ? removeFileByIdentifier(
                              index,
                              doc.id,
                              endPointDelete
                            )
                          : removeFileAttachment(index, doc.id, "serverFile")
                      }
                    />
                  </Tooltip>
                )}
              </DocumentItem>
            ))}
          </AnimatePresence>
        </List>
      </VStack>
 */}
      <ModalGeneric
        isOpen={isOpenUploadModal}
        onClose={closeUploadModal}
        title={textTooltipPreview}
        size="lg"
      >
        <GroupInputUpload
          customHandleChange={{
            values: valuesEdit,
            handleInputChange: handleInputEdit,
            reset: resetEdit,
            setValues: setValuesEdit,
          }}
          onClose={closeUploadModal}
          isOpen={isOpenUploadModal}
          mode="edit"
          preview={documentPreview}
        />
      </ModalGeneric>
    </Box>
  );
};
