import { BaseOfExport } from "components/Export/BaseOfExport";
import { TitleOperationOutput } from "pages/Entry_and_exit_forms/TitleOperation/TitleOperationOutput";
import ViewItemsPdf from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsPdf";
import React from "react";
import { Logo } from "styled/Logo.styled";
import "../../CorrectionForm/Style.css";
import { Cabecera } from "./cabeceraEntryCorrect/Cabecera";
import { CaberaOutput } from "./cabeceraOutputCorrect/CaberaOutput";
import { HeaderOperationOutput } from "./cabeceraOutputCorrect/HeaderOperationOutput";

export const ExportPdfOutputCorrect = ({ values, items }) => {
  const {
    code_operationCorrected,
    refund_corrected,
    shipment_corrected,
    term
  } = values;

  return (
    <div id="IdPdfFmmCorrectEntry">
      <BaseOfExport>
        <div
          style={{
            padding: "5px",
            marginLeft: "10px"
          }}
        >
          <Cabecera values={values} />
        </div>

        <div
          style={{
            padding: "5px",
            marginLeft: "10px"
          }}
        >
          <CaberaOutput values={values} />
        </div>

        <div className="row">
          <div className="col-12">
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #bababa",
                borderRadius: "5px",
                marginLeft: "10px",
                marginRight: "10px"
              }}
            >
              <div
                style={{
                  padding: "5px"
                }}
              >
                <TitleOperationOutput code={code_operationCorrected} />
              </div>
              <div className="p-1">
                <HeaderOperationOutput values={values} />
              </div>
              <div
                style={{
                  border: "1px solid #bababa",
                  borderRadius: "5px",
                  padding: "5px",
                  marginTop: "5px"
                }}
              >
                <div className="row">
                  <div className="col">
                    <p>
                      <strong>Modalidad: </strong> {refund_corrected}
                    </p>

                    <p>
                      <strong>Embarque: </strong> {shipment_corrected}
                    </p>
                  </div>

                  <div className="col">
                    <p>
                      <strong>Plazo: </strong> {term}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: "5px",
              marginLeft: "20px"
            }}
          >
            <p>
              <strong>Tipo de operación: </strong>
              {values?.type_operation_corrected}
            </p>
          </div>

          <div className="col-12">
            {items === undefined
              ? ""
              : items.map((info, index) => (
                <>
                  {index !== 0 && (
                    <>
                      <div className="d-flex mt-5">
                        <div id="banner" />
                        <Logo width={"180px"} className="mx-2" />
                      </div>
                      <div
                        style={{
                          padding: "5px",
                          marginLeft: "10px"
                        }}
                      >
                        <Cabecera values={values} />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      border: "1px solid #bababa",
                      marginTop: "20px",
                      borderRadius: "5px",
                      padding: "5px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                  >
                    <div className="row" key={index}>
                      <h1 className="text-word mb-1 mt-1">
                          Item de Corrección Nº {info?.item_corrected} del item{" "}
                        {info.item || 0}
                      </h1>
                      <ViewItemsPdf
                        data={info}
                        numero={index}
                        alldata={items}
                      />
                    </div>
                  </div>
                  {items && index !== items.length - 1 && (
                    <div className="html2pdf__page-break" />
                  )}
                </>
              ))}
          </div>
        </div>
      </BaseOfExport>
    </div>
  );
};
