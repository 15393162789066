import { Tooltip } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination"
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import MonitoringContext from "context/MonitoringContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { TheadComments, TheadFollowOrder } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader"
import { useContext, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { RxReload } from "react-icons/rx";
import { useSelector } from "react-redux";
import { __Get_FollowOrderTable } from "request/Monitoring/__Get_Operations";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import { FormCreateComment } from "./form/FormCreateComment";
import { FormCreateFollowOrder } from "./form/FormCreateFollowOrder";
import TBodyComment from "./Table/TBodyComments";
import TBodyFollowOrder from "./Table/TBodyFollowOrder";
import { useSearchUrl } from "hooks/useSearchUrl";

export const FollowOrder = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search"], nestedProp: "searchSimple" })

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [dataTableFollowOrder, setDataTableFollowOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [openAlert] = AlertErrorAlert();

  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const [searcheAdvanced, setSearchAdvanced] = useState([]);

  const { editComment, dataTableComment, isOpenCreateFollowOrder, onOpenCreateFollowOrder, onCloseCreateFollowOrder, setEditFollowOrder, editFollowOrder, deleteFollowOrder, setDeleteFollowOrder, getFollowO, setGetFollow, isOpenViewComment, onOpenViewComment, onCloseViewComment, isOpenCreateComment, onOpenCreateComment, onCloseCreateComment, dataCreateFollowOrder } = useContext(
    MonitoringContext
  );

  const backSearch = () => {
    reset();
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    setPage(1);
    setPageSearch(1);
    clearParamsSearch({ searchArray: ["searchSimple"] })
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: ""
  });

  const { searchInformacion } = values;

  const getFollowOrder = async () => {
    setLoading(true)
    try {
      const res = await __Get_FollowOrderTable(token, "", page);
      setDataTableFollowOrder(res?.data?.status?.data?.data);
      setMaxPage(res.data.status.data.last_page);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDeleteFollowOrder(false);
      setGetFollow(false)
    }
  };

  useEffect(() => {
    getFollowOrder();
  }, [page, freeZone_id, getFollowO, onCloseCreateFollowOrder]);

  useEffect(() => {
    if (deleteFollowOrder) {
      getFollowOrder();
    }
  }, [deleteFollowOrder, freeZone_id]);

  useEffect(() => {
    console.log(maxPageSearch, 'pageSearch')
  }, [maxPageSearch]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __Get_FollowOrderTable(token, informacion, pageSearch);
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        console.log(res?.data?.status?.data?.last_page)
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPageSearch(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    const formData = new FormData();
    if (searchInformacion) {
      formData.append("search", searchInformacion);
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(formData);
    } else {
      setChangeViewSearch(false);
      getFollowOrder();
    }
  }, [searchInformacion, pageSearch, freeZone_id, onCloseCreateFollowOrder]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenViewComment}
        onOpen={onOpenViewComment}
        onClose={onCloseViewComment}
        title="Comentarios"
      >
        <TableOrder
          thead={TheadComments}
          data={dataTableComment}
          loading={loading}
          tbodyData={TBodyComment}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCreateFollowOrder}
        onOpen={onOpenCreateFollowOrder}
        onClose={onCloseCreateFollowOrder}
        title={editFollowOrder ? "Editar gestor de seguimiento" : "Crear gestor de seguimiento"}
      >
        <FormCreateFollowOrder getFollowOrder={getFollowOrder} onClose={onCloseCreateFollowOrder} />
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenCreateComment}
        onOpen={onOpenCreateComment}
        onClose={onCloseCreateComment}
        title={editComment ? "Editar comentario" : "Crear comentario"}
      >
        <FormCreateComment onClose={onCloseCreateComment} data={dataCreateFollowOrder} />
      </ModalGeneric>
      <HeaderSearch>
        <Title style={{ margin: "25px 0", fontSize: "27px" }}>Gestores de seguimiento</Title>
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          setValues={setValues}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          allAccess={true}
          viewFilterQualified
          viewButtonFilter
        />
        <div className="align-items-end justify-content-end mb-4 d-flex w-100">
          <div className="d-flex">
            <Tooltip placement="top" hasArrow label={"Recargar"}>
              <ButtonStyled wd="60px" bgColor='#1F7AC3' onClick={getFollowOrder}>
                <IconGeneric color="#fff" as={RxReload} />
              </ButtonStyled>
            </Tooltip>
            <Tooltip placement="top" hasArrow label={"Crear"}>
              <ButtonStyled wd="60px" bgColor='#72CA70' onClick={() => {
                onOpenCreateFollowOrder();
                setEditFollowOrder(null)
              }}>
                <IconGeneric color="#fff" as={AiOutlinePlus} />
              </ButtonStyled>
            </Tooltip>
          </div>
        </div>
        {
          changeViewSearch
            ? (
              <>
                <TableOrder
                  thead={TheadFollowOrder}
                  data={currentPageTwo}
                  setData={setDataTableFollowOrder}
                  loading={loading}
                  tbodyData={TBodyFollowOrder}
                />
                <Pagination
                  page={pageSearch}
                  setPage={setPageSearch}
                  maxPage={maxPageSearch}
                  loading={loading}
                />
              </>
            )
            : (
              <>
                <TableOrder
                  thead={TheadFollowOrder}
                  data={dataTableFollowOrder}
                  setData={setDataTableFollowOrder}
                  loading={loading}
                  tbodyData={TBodyFollowOrder}
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </>
            )

        }
      </HeaderSearch>
    </>
  )
};
