import axios from "axios";
import env from "../../constants/apiConst";

export const __DESTINY = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DESTINY_GET}?page=${page || 1}`, options);
  return res;
};

export const __ShowDetiny = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DETINY_SHOW}/${id}`, options);
  return res;
};

export const __DetinyNotPaginate = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DESTINY_NOT_PAGINATE}`, options);
  return res;
};
