import styled from 'styled-components/macro';

export const SpanLikeLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #1f7ac3;
  }
`;
