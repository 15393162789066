/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  arrayLeftfmmI,
  arrayLeftFmmO,
  arrayRigthfmmI,
  arrayRigthfmmIThree,
  arrayRigthFmmO,
  arrayRigthFmmOThree,
  arrayRigthUserfmmI,
  arrayRigthUserFmmO,
  cabeceraFormularios,
  TitleFormsEntryAccumulated,
  TitleFormsEntryExpired,
  TitleFormsExit,
  TitleFormsExitExpired,
  TitleFormsExitTemporary,
  TitleFormsInt,
  TitleFormsIntItems,
  TitleFormsOutputtItems,
} from "../ArrayHeaders/ArrayHeader";
import { __Get_Forms_Entry, __Get_Forms_Exit } from "request/Forms/_CrudForm";
import TbodyFormEntry from "../Table/TbodyFormEntry";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import FormCrudCRUDContext from "context/FormCrudContex";
import TbodyFormExit from "../Table/TbodyFormExit";
import ModalCopy from "components/modal/ModalCopy";
import environment from "../../../constants/apiConst";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { Button, Flex, Spacer } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import FormAdvancedSearch from "./AdvancedSearch/AdvancedSearch";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { SubTitle, Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { ButtonStyled } from "styled/Buttons.styled";
import { formaterCant } from "components/Funciones/formaterCant";
import { __ShowForm } from "request/Forms/__ShowForm";
import { handleLogout } from "components/Logout/FuncionLogout";
import { AuthContext } from "context/AuthContext";
import TBodyFormEntryItem from "../Table/TBodyFormEntryItem";
import { __PostJsonData } from "request/Petitions/__Post";
import TbodyFormOutputItems from "../Table/TbodyFormOutputItems";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { statePassOrDispacth } from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { __ExportExcel } from "request/Inventory/__InventoryCRUD";
import TBodyFormEntryExpired from "../Table/TBodyFormEntryExpired";
import TBodyFormExitExpired from "../Table/TBodyFormExitExpired";
import TbodyFormExitTemporary from "../Table/TBodyFormExitTemporary";
import TBodyFormEntryAccumulated from "../Table/TBodyFormEntryAccumulated";
import GlobalContext from "context/GlobalContext";
import ModalRecoverForm from "./ModalRecoverForm";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import {
  fmmFormNodeInputFields,
  fmmiExportNodeList,
  fmmiExportNodeNoUc,
  fmmiExportNodeUC,
  fmmoExportNodeList,
  fmmoExportNodeListNoUc,
  fmmoExportNodeListUC,
} from "../ArrayHeaders/ListItem";
import isArrayNotEmpty, {
  isArray,
  isValid,
  safelyConvertToString,
} from "utils/type-check-utils";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useSearchUrl } from "hooks/useSearchUrl";
import { ComponentAccordionIndividual } from "components/AccordionComponent/ComponentAccordionIndividual";

export const FormsTablesView = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { currentSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const { search, valueQualified } = state;
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const { requestData } = useContext(GlobalContext);
  const dispatchRedux = useDispatch();
  const [openAlert] = AlertErrorAlert();
  const [costCorrect, setCostCorrect] = useState(null);
  const { user_rol, admin, freeZone_id } = useSelector(
    (state) => state.auth.dataUser
  );
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const {
    update,
    copyData,
    isOpenCopy,
    onCloseModal,
    setRequestCopy,
    setRequestChangeStatusForm,
    dataToCopy,
    isOpenDesechar,
    onCloseDesechar,
    dataToDesechar,
    changeStatus,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenCorrection,
    onOpenCorrection,
    onCloseCorrection,
    dataCorrection,
    dataCostos,
    CorrectDocument,
    isOpenCaducar,
    dataToCaducar,
    dataToAnular,
    isOpenAnular,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
    showCorrectBalance,
    setshowCorrectBalance,
    isOpenDesCaducar,
    onOpenDesCaducar,
    onCloseDesCaducar,
    dataDescaducar,
  } = useContext(FormCrudCRUDContext);

  const memoizedDataToSearch = useMemo(() => {
    const { typeSearch: _, countryBuy, countryOrigin, countryProcedency, datosBy, flag, operationT, sortBy, stateDispacthOrPass, ...rest } = currentSearch

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && { qualifiedUser_id: valueQualified?.map(({ value }) => value) }),
      ...rest,
    }
    dispatchRedux(setSearch(searchInfo))
    return searchInfo
  }, [valueQualified, search, currentSearch]);

  const resetPages = () => {
    setPageSearch(1);
    setPageEditables(1);
    setPagePresentados(1);
    setPageAprobados(1);
    setPageAutorizados(1);
  };

  const [pageSearch, setPageSearch] = useState(1);
  const [pageEditables, setPageEditables] = useState(1);
  const [pagePresentados, setPagePresentados] = useState(1);
  const [pageAprobados, setPageAprobados] = useState(1);
  const [pageAutorizados, setPageAutorizados] = useState(1);

  const requestMapping = [
    {
      key: "Editables",
      queryKey: ["forms", pageEditables, update, freeZone_id, movement],
      page: pageEditables,
      setPage: setPageEditables,
      thead: movement === "Ingreso" ? TitleFormsInt : TitleFormsExit,
      tbody: movement === "Ingreso" ? TbodyFormEntry : TbodyFormExit,
    },
    {
      key: "Presentados",
      queryKey: ["forms", pagePresentados, update, freeZone_id, movement],
      page: pagePresentados,
      setPage: setPagePresentados,
      thead: movement === "Ingreso" ? TitleFormsInt : TitleFormsExit,
      tbody: movement === "Ingreso" ? TbodyFormEntry : TbodyFormExit,
    },
    {
      key: "Aprobados",
      queryKey: ["forms", pageAprobados, update, freeZone_id, movement],
      page: pageAprobados,
      setPage: setPageAprobados,
      thead: movement === "Ingreso" ? TitleFormsInt : TitleFormsExit,
      tbody: movement === "Ingreso" ? TbodyFormEntry : TbodyFormExit,
    },
    {
      key: "Autorizados",
      queryKey: ["forms", pageAutorizados, update, freeZone_id, movement],
      page: pageAutorizados,
      setPage: setPageAutorizados,
      thead: movement === "Ingreso" ? TitleFormsInt : TitleFormsExit,
      tbody: movement === "Ingreso" ? TbodyFormEntry : TbodyFormExit,
    },
  ]

  /* currentSearch-> typeSearch: Esto proviene de las búsquedas avanzadas o cuando no hace búsquedas, por defecto es "DOCUMENT" */
  const currentTableMeta = currentSearch?.typeSearch?.toUpperCase() || "DOCUMENT"

  const tBody = {
    Ingreso: {
      DOCUMENT: TbodyFormEntry,
      ITEM: TBodyFormEntryItem,
      EXPIRED: TBodyFormEntryExpired,
      ACCUMULATED: TBodyFormEntryAccumulated,
    },
    Salida: {
      DOCUMENT: TbodyFormExit,
      ITEM: TbodyFormOutputItems,
      EXPIRED: TBodyFormExitExpired,
      TEMPORARY: TbodyFormExitTemporary,
    },
  }[movement || ""][currentTableMeta];


  const tHeadTable = {
    Ingreso: {
      DOCUMENT: TitleFormsInt,
      ITEM: TitleFormsIntItems,
      EXPIRED: TitleFormsEntryExpired,
      ACCUMULATED: TitleFormsEntryAccumulated,
    },
    Salida: {
      DOCUMENT: TitleFormsExit,
      ITEM: TitleFormsOutputtItems,
      EXPIRED: TitleFormsExitExpired,
      TEMPORARY: TitleFormsExitTemporary,
    },
  }[movement || ""][currentTableMeta];

  const apisToSearch = {
    Ingreso: {
      DOCUMENT: environment?.SEARCH_ADVANCED_FORM_ENTRY,
      ITEM: environment.SEARCH_ADVANCED_FORM_ENTRY_ITEM,
      EXPIRED: environment.SEARCH_ADVANCED_FORM_ENTRY_EXPIRED,
      ACCUMULATED: environment.SEARCH_ADVANCED_FORM_ACUMULADOS,
    },
    Salida: {
      DOCUMENT: environment?.SEARCH_ADVANCED_FORM_EXIT,
      ITEM: environment.SEARCH_ADVANCED_FORM_EXIT_ITEM,
      EXPIRED: environment.SEARCH_ADVANCED_FORM_EXIT_EXPIRED,
      TEMPORARY: environment.SEARCH_ADVANCED_FORM_TEMPORARY,
    },
  }[movement || ""][currentTableMeta];

  const APIS = {
    Ingreso: environment.FORMS_ENTRY_GET,
    Salida: environment.FORMS_EXIT_GET,
  };

  useEffect(() => {
    setPageSearch(1);
  }, [currentTableMeta]);

  const { data: dataForms, pending } = useQueries({
    /* Acá se valida si no hay una búsqueda se hace la petición con requestMapping para tener todo separado, de lo contrario se hace la petición get normal*/
    queries: Object.entries(memoizedDataToSearch).length === 0 ? requestMapping.map((item) => ({
      queryKey: item.queryKey,
      queryFn: () => requestData({
        Endpoint: APIS?.[movement] || APIS.Ingreso,
        page: item.page,
        method: "POST",
      })
    })) : [{
      queryKey: ["dataPasesSearch", pageSearch, update, freeZone_id, memoizedDataToSearch, movement],
      queryFn: () => requestData({
        Endpoint: apisToSearch,
        page: pageSearch,
        method: "POST",
        data: memoizedDataToSearch,
      })
    }],
    /* Hay dos casos, el primero es cuando hay búsqueda, que se apunta a un endpoint diferente y además, este trae solo un resultado data=[{...Resultado de la Búsqueda}]. Por el contrario, cuando no hay búsqueda, este trae todos los resultados en un array de objetos [{...Editables}, {...Prestendados}, {...Autorizados}] los cuales se combinan en un solo objeto  {...Editables, ...Prestendados, ...Autorizados}*/
    combine: (results) => {
      if (Object.entries(memoizedDataToSearch).length !== 0) {
        const [data] = results.map(result => result?.data?.data?.status)
        return {
          data,
          pending: results.some((result) => result.isPending),
        }
      }

      return {
        data: results.reduce((acc, result, index) => {
          acc[requestMapping[index].key] = {
            ...requestMapping[index],
            data: result.data?.data?.status?.data?.[index]?.data || [],
            maxPage: result.data?.data?.status?.data?.[index]?.last_page || 1,
            isLoading: result.isFetching,
          }
          return acc
        }, {}),
        pending: results.some((result) => result.isPending),
      }
    },
  })



  useEffect(() => {
    if (movement === "Ingreso") {
      setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS);
      setRequestCopy(environment.COPY_FORMS);
    } else {
      setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS_EXIT);
      setRequestCopy(environment.COPY_FORMS_EXIT);
    }
  }, [
    movement,
    dataToDesechar,
    setRequestChangeStatusForm,
    setRequestCopy,
    freeZoneValue,
  ]);

  const backSearch = () => {
    resetPages();
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
  };

  const handlePresent = (status) => {
    if (movement === "Ingreso") {
      const info = {
        id: dataToDesechar.id,
        status_entryForm: status,
      };
      changeStatus(info);
      onCloseDesechar();
    } else {
      const info = {
        id: dataToDesechar.id,
        status_outputForm: status,
      };
      changeStatus(info);
      onCloseDesechar();
    }
  };

  const handleCaducar = (status) => {
    if (movement === "Ingreso") {
      const info = {
        id: dataToCaducar.id,
        status_entryForm: status,
      };
      changeStatus(info);
      onCloseModal();
    } else {
      const info = {
        id: dataToCaducar.id,
        status_outputForm: status,
      };
      changeStatus(info);
      onCloseModal();
    }
  };

  const handleAnular = (status) => {
    if (movement === "Ingreso") {
      const info = {
        id: dataToAnular.id,
        status_entryForm: status,
      };
      changeStatus(info);
      onCloseModal();
    } else {
      const info = {
        id: dataToAnular.id,
        status_outputForm: status,
      };
      changeStatus(info);
      onCloseModal();
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  useEffect(() => {
    if (dataCostos) {
      if (movement === "Ingreso") {
        const respuestaFind = dataCostos?.values?.find(
          (e) => e.document === "Anexo Corregido de Ingreso"
        );
        const { value } = respuestaFind;
        setCostCorrect(value);
      } else {
        const respuestaFind = dataCostos?.values?.find(
          (e) => e.document === "Anexo Corregido de Salida"
        );
        const { value } = respuestaFind;
        setCostCorrect(value);
      }
    }
  }, [dataCostos]);

  const correct = async (id) => {
    try {
      const res = await __ShowForm(
        token,
        id,
        movement === "Ingreso"
          ? environment.FORMS_ENTRY_VIEW
          : environment.SHOW_FORM_EXIT
      );
      const resultado = res?.data?.status;
      const { code, data } = resultado;
      const dataCorrectionBalance = {
        ...data,
        balance_correction: 1,
      };

      if (code === 200) {
        if (movement === "Ingreso") {
          if (showCorrectBalance) {
            CorrectDocument(
              environment.CORRECT_FMMI,
              dataCorrectionBalance,
              hanldeCancel
            );
          } else {
            CorrectDocument(environment.CORRECT_FMMI, data, hanldeCancel);
          }
        } else {
          if (showCorrectBalance) {
            CorrectDocument(
              environment.CORRECT_FMMO,
              dataCorrectionBalance,
              hanldeCancel
            );
          } else {
            CorrectDocument(environment.CORRECT_FMMO, data, hanldeCancel);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleChange = (id) => {
    const infoEntryCorrect = {
      id,
      status_entryForm: "BORRADOR",
      retrieved: "RECUPERADO",
      movement: "Ingreso",
    };

    const infoOutputCorrect = {
      id,
      status_outputForm: "BORRADOR",
      retrieved: "RECUPERADO",
      movement: "Salida",
    };

    if (movement === "Ingreso") {
      changeStatus(infoEntryCorrect);
    } else {
      changeStatus(infoOutputCorrect);
    }
  };

  const handleSubmit = (data, formulario) => {
    const statusEntry =
      movement === "Ingreso" &&
      isArray(data?.status) &&
      isArrayNotEmpty(data?.status);

    const statusOutput =
      movement === "Salida" &&
      isArray(data?.status) &&
      isArrayNotEmpty(data?.status);

    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value,
      }),
      qualifiedUser_id: isArray(data?.qualifiedUser_id)
        ? data?.qualifiedUser_id?.map((item) => item?.value || item)
        : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
          ? data?.qualifiedUser_id?.value
          : data?.qualifiedUser_id || [],
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || [],
      }),
      fields: {
        ...formulario,
        ...(statusEntry && { status_entryForm: "status_entryForm" }),
        ...(statusOutput && {
          status_outputForm: "status_outputForm",
        }),
      },
    };

    exportColumnBy(info);
  };

  const exportColumnBy = async (data) => {
    const change =
      movement === "Salida" && currentTableMeta === "TEMPORARY"
        ? environment.EXPORT_EXCEL_OUTPUT_TEMPORARY
        : movement === "Ingreso"
          ? environment.EXPORT_EXCEL_ENTRY
          : movement === "Salida"
            ? environment.EXPORT_EXCEL_OUTPUT
            : "";

    try {
      const res = await __ExportExcel(token, data, change);
      if (res?.data?.status?.code === 200) {
        /* let url = res?.data; */
        /* window.open(url?.status?.data); */
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeCancel = () => {
    onCloseCorrection();
    setshowCorrectBalance(false);
  };

  const calculateArrayExportNodes = () => {
    if (authUser.is_qualified_user === 1) {
      if (movement === "Ingreso") {
        return fmmiExportNodeList.concat(fmmiExportNodeUC);
      }
      if (movement === "Salida") {
        return fmmoExportNodeList.concat(fmmoExportNodeListUC);
      }
    }

    if (movement === "Ingreso") {
      return fmmiExportNodeList.concat(fmmiExportNodeNoUc);
    }
    if (movement === "Salida") {
      return fmmoExportNodeList.concat(fmmoExportNodeListNoUc);
    }
    return [];
  };

  useEffect(() => {
    return async () => {
      dispatchRedux(clearSearch());
    };
  }, [movement]);


  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
      >
        <FormAdvancedSearch
          onClose={onCloseSearch}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCorrection}
        onOpen={onOpenCorrection}
        onClose={hanldeCancel}
        title={`Corregir ${movement}`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea{" "}
          {`${showCorrectBalance ? "hacer una correción de saldo en" : "corregir"
            }`}{" "}
          el formulario{" "}
          {dataCorrection?.code_entryForm || dataCorrection?.code_outputForm}?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              Esta acción generará un costo de{" "}
              {costCorrect && `$${formaterCant(costCorrect)}`}
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseCorrection}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => correct(dataCorrection?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size="xl"
      >
        <ModalRecoverForm
          dataRecuperar={dataRecuperar}
          onCloseRecuperar={onCloseRecuperar}
          handleChange={handleChange}
          movement={movement}
        />
      </ModalGeneric>

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar el formulario Nº ${dataToCopy?.code_entryForm || dataToCopy?.code_outputForm
          }`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenDesechar}
        onClose={onCloseDesechar}
        title={`Desea desechar el formulario Nº ${dataToDesechar?.code_entryForm || dataToDesechar?.code_outputForm
          } <br> <span style="color: red">Importante:</span> Esta acción no se puede deshacer! `}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseDesechar}>Cancelar</Button>
          <Button
            colorScheme="red"
            onClick={() => handlePresent("ELIMINADO")}
            ml={3}
          >
            Desechar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCaducar}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea caducar el formulario Nº ${dataToCaducar?.code_entryForm || dataToCaducar?.code_outputForm
          } ?`}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            style={{ background: "#F9A621" }}
            onClick={() => handleCaducar("CADUCADO")}
            ml={3}
          >
            Caducar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular  el formulario Nº ${dataToAnular?.code_entryForm || dataToAnular?.code_outputForm
          }? <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario.`}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            style={{ background: "#41495A", color: "white" }}
            onClick={() => handleAnular("ANULADO")}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
        size="6xl"
        enableAdvance={true}
        nodeList={calculateArrayExportNodes()}
        selectNodeList={fmmFormNodeInputFields}
        handleSubmit={handleSubmit}
      />

      <ModalGeneric
        isOpen={isOpenDesCaducar}
        onOpen={onOpenDesCaducar}
        onClose={onCloseDesCaducar}
        title={`Descaducar documento`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea Descaducar el formulario{" "}
          {dataDescaducar?.code_entryForm || dataDescaducar?.code_outputForm}?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              El documento regresara al estado Aprobado.
            </SubTitle>
          </div>
        </div>

        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseDesCaducar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() =>
              changeStatus(
                dataDescaducar?.code_entryForm
                  ? {
                    id: dataDescaducar?.id,
                    status_entryForm: "APROBADO",
                  }
                  : {
                    id: dataDescaducar?.id,
                    status_outputForm: "APROBADO",
                  }
              )
            }
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <HeaderSearch>
        <Title>Formulario</Title>
        <SearchAdvancedModule
          onOpenSearch={onOpenSearch}
          changeViewSearch={Object.entries(memoizedDataToSearch)?.length !== 0}
          backSearch={backSearch}
          permisoSearch={
            movement === "Ingreso"
              ? "getEntryFormFilter"
              : "getOutputFormFilter"
          }
          allAccess={allAccess}
          myPermission={myPermission}
          MultipleQualifiedUsers={"multi"}
          movement={movement}
        />

        <ExportDetail onOpen={onOpenExport} />
        {Object.entries(memoizedDataToSearch)?.length !== 0 ? (
          <div>
            <TableOrder
              thead={tHeadTable}
              data={isValid(dataForms?.data?.data) ? dataForms?.data?.data : []}
              setData={() => { }}
              loading={pending}
              tbodyData={tBody}
            />
            <Pagination
              page={pageSearch}
              setPage={setPageSearch}
              maxPage={dataForms?.data?.last_page}
              loading={pending}
            />
          </div>
        ) : (
          <>
            <ComponentAccordionIndividual
              data={dataForms}
              isLoading={pending}
            />
          </>
        )}
      </HeaderSearch>
    </>
  );
};
