import React from 'react'
import { Title, SubTitle } from 'styled/Title'
import { H2 } from './stylePases'
import { isArray } from 'utils/type-check-utils'

const CreateyPresented = ({ values }) => {
  const { status } = values || {}
  return (
    <>
      <div
        className='row mt-4 ms-2'
        style={{
          background: '#f2f2f2',
          padding: '10px'
        }}
      >
        {isArray(status) 
          ? status.map((items, index) => (
            <div className='col col-sm-6 sol-md-3' key={index}>
              <H2 className='m-1 d-flex'>
                <Title size='18px'>
                  {(items?.status_pass || items?.status_dispatchPass) ===
                    'BORRADOR'
                    ? 'Creado:'
                    : (items?.status_pass || items?.status_dispatchPass) ===
                        'PRESENTADO'
                      ? 'Presentado:'
                      : (items?.status_pass || items?.status_dispatchPass) ===
                        'AUTORIZADO'
                        ? 'Autorizado:'
                        : (items?.status_pass || items?.status_dispatchPass) ===
                        'DEVUELTO'
                          ? 'Devuelto:'
                          : (items?.status_pass || items?.status_dispatchPass) ===
                        'REVISADO'
                            ? 'Revisado:'
                            : (items?.status_pass || items?.status_dispatchPass) ===
                        'CADUCADO'
                              ? 'Terminado:'
                              : ''}
                </Title>
                <span className='ms-1 text-mute'>
                  {items?.date_pass +
                      ' ' +
                      items?.time_pass +
                      ' ' +
                      items?.nickname}
                </span>
              </H2>
            </div>
          ))
          : ''}
      </div>
    </>
  )
}

export default CreateyPresented
