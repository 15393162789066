import { AuthContext } from "context/AuthContext";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useContext, useState, useEffect } from "react";
import { TitlePDF } from "styled/Title";

export const CabeceraCertificado = () => {
  const { form } = useContext(IntegracionBoxCrudContex);
  const {
    nit_qualified,
    destiny,
    name_qualified,
    integration_certificate_code,
    created_at,
    status,
    authorizedDate
  } = form;

  const [dateToday, setDateToday] = useState(null);

  const { authUser } = useContext(AuthContext);
  // const [fechaAutorizado, setFechaAutorizado] = useState(null);
  const date = new Date(created_at).toLocaleDateString();

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
  }, [form]);

  return (
    <>
      <div className="row blac" >
        <div className="col-3">
          <p>
            <strong>Impreso: </strong>
            {dateToday}
          </p>
          <p>
            <strong>Usuario: </strong>
            {authUser.username}
          </p>
          <p>Pagina 1 </p>
        </div>

        <div className="col-6">
          <div className="container">
            <TitlePDF size={"13px"} weight={"700"} className="text-center">
              Zona Franca <br /> Certificado de Integracion de las Materias
              Primas e Insumos Nacionales y Extranjeros <br />
              {status &&
                `Intervinientes en la fabricacion del bien - ${status}`}
            </TitlePDF>
          </div>
        </div>

        <div className="col-3">
          <p>
            <strong>Formulario: </strong>
            {integration_certificate_code}
          </p>
          <p>
            <strong>Fecha: </strong>
            {created_at && date}
          </p>
        </div>

        <hr className="mt-4 ms-0" />

        <div className="col-6">
          <p>
            <strong>Usuario: </strong>
            {name_qualified}
          </p>
          <p>
            <strong>Nit: </strong>
            {nit_qualified}
          </p>
        </div>
        <div className="col-6">
          <p>
            <strong>Tipo de cuadro:</strong>
            {destiny}
          </p>
          <p>
            <strong>Fecha Autorización: </strong>
            {authorizedDate && authorizedDate}
          </p>
        </div>
      </div>
    </>
  );
};
