import { Button } from "@chakra-ui/react";
import { ModalGeneric } from "components/modal/ModalGeneric";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import FormOrdenProduct from "pages/IntegrationBox/Form/FomProduct";
import FormAddOtherCost from "pages/IntegrationBox/Form/FormAddOtherCost";
import FormAddProduct from "pages/IntegrationBox/Form/FormAddProduct";
import FormAddSupplies from "pages/IntegrationBox/Form/FormAddSupplies";
import FormModalStatusDelete from "pages/IntegrationBox/Form/FormModalStatusDelete";
import FormOrdenProduccion from "pages/IntegrationBox/Form/FormOrdenProduccion";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ShowFormOrdenProduccion from "pages/IntegrationBox/Form/ShowFormOrdenProduccion";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import OrderProductionContext from "context/OrderProductionContext";
import { FormTemplate } from "pages/ProductionOrder/CreateNewProductoOrder/FormTemplate";
import environment from "constants/apiConst";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { useGlobalContext } from "context/GlobalContext";

const ModalsFormId = ({ props }) => {
  const location = useLocation();
  const { pathname } = location;

  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const { token } = useSelector((state) => state.auth.dataUser);

  const {
    isOpenAddOrder,
    isOpenAddShow,
    onCloseModal,
    isOpenDelete,
    deleteData,
    dataToDelete,
    isOpenAddProduct,
    dataProductoSingleDelete,
    isOpenDeleteSigleProduct,
    isOpenComment,
    onOpenComment,
    onCloseComment,
    isOpenAddSupplies,
    isEditCiSupplies,
    isOpenDeleteSupplies,
    dataSuppliesP,
    deleteSupplies,
    isOpenAddOther,
    onCloseReference,
    isOpenDeleteOtherCost,
    dataToDeleteOtherCost,
    deleteOtherCost,
    isOpenAddSuppliesProduct,
    showCertificatedData,
    form,
    dataToEditSingleProduct,
    setDataToEditProduct
  } = useContext(IntegracionBoxCrudContex);

  const { isOpenTemplate, onOpenTemplate, onCloseTemplate, setDecimalUc } =
    useContext(OrderProductionContext);

  const queryClient = useQueryClient();
  const foundMutation = queryClient.getQueryData([queryEnv?.INTEGRATION_BOX]);

  const [values, setValues] = useState({
    production_order_status: "",
    production_order_code: "",
    qualified_users_name: "",
    qualified_users_id: "",
    status: "",
  });

  const { qualified_users_id } = values;

  const idCI = id || idCorrected;

  const handleDelete = () => {
    const dataTosendDelete = new FormData();
    dataTosendDelete.append("id", dataSuppliesP?.id);
    deleteSupplies(dataTosendDelete);
  };

  const handleDeleteOtherCost = () => {
    const dataToSend = new FormData();
    dataToSend.append("id", dataToDeleteOtherCost.id);
    deleteOtherCost(dataToSend);
  };

  // Data que se envia para eliminar una orden de produccion
  const statusData = new FormData();
  statusData.append("id", dataToDelete?.id);

  // Data que se envia para eliminar un producto
  const statusDataSingleProduct = new FormData();
  statusDataSingleProduct.append("id", dataProductoSingleDelete?.id);

  const { requestData } = useGlobalContext();

  const { data: dataCI } = useQuery({
    queryKey: [queryEnv?.INTEGRATION_BOX, idCI],
    queryFn: () =>
      requestData({
        Endpoint: id
          ? environment.SHOW_INTEGRACION_BOX_ID
          : environment.SHOW_INTEGRACION_BOX_ID_CORRECTED,
        data: {
          id: idCI,
        },
        PropertyBody: "POST",
      }),
  });

  useEffect(() => {
    if (dataCI?.data?.status?.code !== 200 && !dataCI?.data?.status?.data)
      return;
    setValues((prev) => ({
      ...prev,
      status: id
        ? dataCI?.data?.status?.data?.status
        : dataCI?.data?.status?.data?.newData?.status,
      qualified_users_id: id
        ? dataCI?.data?.status?.data?.qualifiedUser_id
        : dataCI?.data?.status?.data?.newData?.data?.qualifiedUser_id,
    }));
  }, [dataCI, setValues, id]);

  useEffect(() => {
    if (!foundMutation && idCI) return;
    setValues({
      ...values,
      qualified_users_id: idCorrected
        ? foundMutation?.newData?.qualifiedUser_id
        : foundMutation?.qualifiedUser_id,
    });
  }, [foundMutation, idCI]);

  const userC = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const serachUc = res?.data?.status?.data.find(
        ({ id }) => id === Number(qualified_users_id)
      );
      setDecimalUc(serachUc.decimal_values_precision);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (qualified_users_id) {
      userC();
    }
  }, [token]);

  useEffect(() => {
    if (!isOpenAddProduct) {
      setDataToEditProduct(null)
    }
  }, [isOpenAddProduct]);

  return (
    <>
      {/* ESTO NO SE VA A USAR EN EL FUTURO PORQUE NO ES NECESARIO :) */}
      <ModalGeneric
        isOpen={isOpenAddOrder}
        onClose={onCloseModal}
        title={
          foundMutation?.status !== "PRESENTADO"
            ? "Agregar orden de producción"
            : "Revisión"
        }
        size={"4xl"}
      >
        <FormOrdenProduccion
          Status={foundMutation?.status}
          observations={props.observations}
          isOpenComment={isOpenComment}
          onOpenComment={onOpenComment}
          onCloseComment={onCloseComment}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddShow}
        onClose={onCloseModal}
        title={"Ver item"}
        size={"6xl"}
      >
        <ShowFormOrdenProduccion data={showCertificatedData} form={form} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddProduct}
        onClose={onCloseModal}
        title={
          pathname !== `/CheckIntegrationBox`
            ? !dataToEditSingleProduct
              ? "Agregar Producto"
              : "Editar Producto"
            : "Orden de producción"
        }
        size={"6xl"}
      >
        <FormOrdenProduct
          observations={props.observations}
          isOpenComment={isOpenComment}
          onOpenComment={onOpenComment}
          onCloseComment={onCloseComment}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenTemplate}
        onOpen={onOpenTemplate}
        onClose={onCloseTemplate}
        title="Agregar Plantilla"
        size="xl"
      >
        <FormTemplate
          Orderid={idCI}
          UserId={qualified_users_id}
          location={"Certificado de integracion"}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddSupplies}
        onClose={onCloseReference}
        title={isEditCiSupplies ? "Editar Insumo" : "Agregar insumo"}
        size={"6xl"}
      >
        <FormAddSupplies onCloseReference={onCloseReference} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddSuppliesProduct}
        onClose={onCloseModal}
        title={"Agregar producto"}
        size={"6xl"}
      >
        <FormAddProduct onCloseModal={onCloseModal} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddOther}
        onClose={onCloseModal}
        title={"Agregar Otros costos"}
        size={"6xl"}
      >
        <FormAddOtherCost onCloseModal={onCloseModal} />
      </ModalGeneric>

      <FormModalStatusDelete
        status={"ELIMINAR"}
        nameDocument={"Orden de producción"}
        numberOfDocument={dataToDelete?.description_product}
        data={statusData}
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        deletes={true}
        functionChangeStatus={deleteData}
      />

      <FormModalStatusDelete
        status={"ELIMINAR"}
        nameDocument={"Producto"}
        numberOfDocument={dataProductoSingleDelete?.description_product}
        data={statusDataSingleProduct}
        isOpen={isOpenDeleteSigleProduct}
        onClose={onCloseModal}
        deletes={false}
        product={true}
        functionChangeStatus={deleteData}
      />

      <ModalGeneric
        isOpen={isOpenDeleteOtherCost}
        onClose={onCloseModal}
        title={`¿Desea eliminar el costo  ${dataToDeleteOtherCost?.name_cost}?`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            colorScheme="red"
            onClick={() => handleDeleteOtherCost()}
            ml={3}
          >
            Eliminar
          </Button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenDeleteSupplies}
        onClose={onCloseModal}
        title={`¿Desea eliminar el insumo  ${dataSuppliesP?.code_product}?`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button colorScheme="red" onClick={() => handleDelete()} ml={3}>
            Eliminar
          </Button>
        </div>
      </ModalGeneric>
    </>
  );
};

export default ModalsFormId;
