/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { NumericFormat } from "react-number-format";
import { RiSearch2Line } from "react-icons/ri";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import isArrayNotEmpty, {
  isArray,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import { AuthContext } from "context/AuthContext";
import InputSelect from "components/InputSelect/InputSelect";
import queryEnv from "constants/queryConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useGetUsersObserver } from "hooks/useGetUsersObserver";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrderHover from "components/Tables/TableOrderHover";
import { theadReferenceOP } from "pages/IntegrationBox/Arrays/ArrayTitles";
import TbodyReferenceOP from "pages/IntegrationBox/Table/TbodyReferenceOP";
import Pagination from "components/Pagination/Pagination";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import { parseFloat } from "utils/parseFloat";
import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";

export const PopoverForm = ({
  children,
  onOpenPopover,
  onClosePopover,
  isOpenPopover,
}) => {
  const { requestData, managerData, pagerReferenceOP } = useGlobalContext();
  const [userQualified, setUserQualified] = useState({});
  const { authUser } = useContext(AuthContext);
  const firstRender = useRef(true);

  const queryClient = useQueryClient();
  const [openAlert] = AlertErrorAlert();

  const { values, handleInputChange } = useForm({
    initialState: {
      reference: "",
      product_code: "",
      description: "",
      certificate: "",
      import_declaration: "",
      operation: "",
      subheading: "",
    },
    returnType: RETURN_TYPES.OBJECT,
  });

  const {
    reference,
    product_code,
    description,
    certificate,
    import_declaration,
    operation,
    subheading,
  } = values || {};

  const { mutate, isPending } = useMutation({
    mutationFn: requestData,
    retry: 3,
  });

  const getReference = () => {
    const formdata = new FormData();
    formdata.append("qualifiedUser_id", userQualified?.value);

    if (isObject(values)) {
      for (const key in values) {
        if (isValid(values[key])) formdata.append(key, values[key]);
      }
    }

    const config = {
      Endpoint: environment.GET_REFERENCE_OP,
      PropertyBody: "sendFormDataContentPOST",
      data: formdata,
      pageParam: pagerReferenceOP ?? 1,
    };

    mutate(config, {
      onSuccess: (data) => {
        if (data?.data?.status?.code === 400) {
          return openAlert(data?.data?.status?.message, "error");
        }

        if (data?.data?.status?.code === 200) {
          queryClient.setQueryData(
            ["getReferenceOP"],
            data?.data?.status?.data
          );
        }
      },
    });
  };

  const { data: dataUserQualified, isPending: isLoadingDataUserQualified } =
    useQuery({
      queryKey: [queryEnv?.QUALIFIED_USERS],
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_QUALIFIED_USERS_ALL,
          PropertyBody: "get",
        }),
    });

  const qualifiedData =
    isValid(dataUserQualified?.data?.status?.data) &&
    isArray(dataUserQualified?.data?.status?.data) &&
    !isLoadingDataUserQualified
      ? dataUserQualified?.data?.status?.data.map((items) => ({
          value: items?.id,
          label: items?.nit_qualified + " - " + items?.name_qualified,
          decimal: items.decimal_values_precision,
        }))
      : [];

  useEffect(() => {
    const validationManager =
      isValid(managerData?.UserQualified) &&
      isObject(managerData?.UserQualified);

    if (validationManager) setUserQualified(managerData?.UserQualified);
  }, [managerData]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (isValid(userQualified?.value) && isValid(pagerReferenceOP)) {
      getReference();
    }
  }, [pagerReferenceOP]);

  return (
    <>
      <Popover
        isOpen={isOpenPopover}
        onOpen={onOpenPopover}
        onClose={onClosePopover}
        closeOnBlur={false}
      >
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent
          p={5}
          width={"3xl"}
          boxShadow={
            "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
          }
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Búsqueda por referencia</PopoverHeader>

          <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
            {authUser.is_qualified_user !== 1 && (
              <div>
                <InputSelect
                  tag={"Empresa"}
                  size={100}
                  data={qualifiedData}
                  set={setUserQualified}
                  value={userQualified}
                  isLoading={isLoadingDataUserQualified}
                  isDisabled={true}
                />
              </div>
            )}

            <LabelGroup
              name={"reference"}
              value={reference}
              tag={"Referencia"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"description"}
              value={description}
              tag={"Descripción"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"product_code"}
              value={product_code}
              tag={"Código producto"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
            <LabelGroup
              name={"certificate"}
              value={certificate}
              tag={"Certificado"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"import_declaration"}
              value={import_declaration}
              tag={"Dcl. Importación"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"operation"}
              value={operation}
              tag={"Operacion"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"product_code"}
              value={product_code}
              tag={"Código producto"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
            />

            <LabelGroup
              name={"subheading"}
              value={subheading}
              tag={"Subpartida"}
              size={100}
              desicion={false}
              onChange={handleInputChange}
              /*   maxLength={MaxLength?.global?.code_subheading} */
            />

            {/*       )} */}
          </SimpleGrid>

          <Flex pt="5" pb="5">
            <ButtonStyled bgColor={"#bababa"} onClick={onClosePopover}>
              Cerrar
            </ButtonStyled>
            <Spacer />
            <ButtonStyled
              bgColor={"#3182CE"}
              type="submit"
              isLoading={isPending}
              onClick={getReference}
            >
              Buscar
            </ButtonStyled>
          </Flex>
        </PopoverContent>
      </Popover>
    </>
  );
};

const ModalSearchSupplies = ({ data, onCloseModal = () => {} }) => {
  const queryClient = useQueryClient();
  const [maxPage, setMaxPage] = useState(1);
  const [dataTablet, setdataTablet] = useState([]);
  const { pagerReferenceOP, setPageReferenceOP } = useGlobalContext();

  useEffect(() => {
    if (isNumber(data?.last_page)) {
      setMaxPage(data?.last_page);
    }

    if (isValid(data?.data)) {
      setdataTablet(data?.data);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      queryClient.setQueryData(["getReferenceOP"], []);
      setPageReferenceOP(null);
    };
  }, []);

  return (
    <>
      <ButtonStyled onClick={onCloseModal}>Buscar nuevamente</ButtonStyled>
      <TableOrderHover
        thead={theadReferenceOP}
        data={dataTablet}
        setData={setdataTablet}
        onCloseModal={onCloseModal}
        tbodyData={TbodyReferenceOP}
      />

      <Pagination
        page={pagerReferenceOP ?? 1}
        setPage={setPageReferenceOP}
        maxPage={maxPage}
      />
    </>
  );
};

export const AddSupplies = ({
  submit = () => {},
  onClose = () => {},
  precision = 4,
}) => {
  const firstRender = useRef(true);
  const firstRenderQuantity = useRef(true);
  const firstRenderSupplies = useRef(true);

  const { dataProductionOrder, setDataProductionOrder, dataToEditOP } =
    useGlobalContext();

  const { data: dataReferenceOP } = useGetUsersObserver({
    key: "getReferenceOP",
  });

  const onFieldChange = (name, value, target, currentValues) => {
    const convertionFactor =
      isString(convertion_factor, true) || isNumber(convertion_factor)
        ? parseFloat(convertion_factor)
        : 0;

    if (name === "quantity") {
      console.log({
        quantity: value,
        net_weight_unit,
        calculate:
          parseFloat(parseNumberWithCommas(value)) *
          parseFloat(parseNumberWithCommas(net_weight_unit)),
      });
      setValues((prev) => ({
        ...prev,
        net_weight_calculate:
          parseFloat(parseNumberWithCommas(value)) *
          parseFloat(parseNumberWithCommas(net_weight_unit)),
      }));
    }

    if (name === "quantity_supply") {
      const quantitySupply =
        isString(value, true) || isNumber(value) ? parseFloat(value) : 0;
      const netWeight = convertionFactor * quantitySupply;

      setValues((prev) => ({
        ...prev,
        quantity: netWeight,
        // net_weight: netWeight,
      }));
    }
  };

  const { values, handleInputChange, setValues } = useForm({
    initialState: {
      net_weight: 0,
      fob_value: 0,
      quantity: 0,
      quantity_supply: 0,
      reference: null,
      description: "",
      natural: "",
      code_Product: "",
      product_unit: "",
      convertion_factor: "",
      busisness_unit: "",
      quantity_garbage: "",
      agreement: "",
      subheading: "",
      certificate: "",
      taxes: "",
      customs_clearance: "",
      operation: "",
      initial: "",
      name_tradeAgreement: "",
      inventory_id: "",
      value_fob_unit: 0,
      value_cif_unit: 0,
      net_weight_unit: 0,
      type: "1",
    },
    returnType: RETURN_TYPES.OBJECT,
    onFieldChange,
  });

  const {
    net_weight,
    fob_value,
    quantity,
    quantity_supply,
    reference,
    description,
    natural,
    code_Product,
    product_unit,
    convertion_factor,
    busisness_unit,
    quantity_garbage,
    subheading,
    certificate,
    customs_clearance,
    operation,
    initial,
    value_cif,
    name_tradeAgreement,
    value_cif_unit,
    quantity_available_product,
    type,
    calculate_value,
    //calculated_weight,
    net_weight_calculate,
    available_balance,
    net_weight_unit,
  } = values || {};

  const updateItemValue = (newValue, sourceEvent) => {
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue,
    };

    handleInputChange(target);
  };

  const createSupplies = /* handleSubmit */ () => {
    submit(values);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (isArray(dataReferenceOP) && !isArrayNotEmpty(dataReferenceOP)) {
      return;
    }

    if (isObject(dataReferenceOP) && isObjectNotEmpty(dataReferenceOP)) {
      onOpenSearchSupplies();
      return;
    }
  }, [dataReferenceOP]);

  const {
    onOpen: onOpenSearchSupplies,
    onClose: onCloseSearchSupplies,
    isOpen: isOpenSearchSupplies,
  } = useDisclosure();

  const {
    onOpen: onOpenPopover,
    onClose: onClosePopover,
    isOpen: isOpenPopover,
  } = useDisclosure();

  const closeModals = () => {
    onCloseSearchSupplies();
    onClosePopover();
  };

  useEffect(() => {
    if (firstRenderSupplies.current) {
      firstRenderSupplies.current = false;
      return;
    }

    if (
      isObject(dataProductionOrder) &&
      isObjectNotEmpty(dataProductionOrder)
    ) {
      console.log("dataProductionOrder", dataProductionOrder);

      setValues((prev) => ({
        ...prev,
        ...dataProductionOrder,
        convertion_factor: dataProductionOrder?.convertion_factor_product,
        description: dataProductionOrder?.description_product,
        agreement: dataProductionOrder?.code_tradeAgreement,
      }));
    }
  }, [dataProductionOrder]);

  useEffect(() => {
    if (isValid(dataToEditOP)) {
      const {
        business_unit,
        code_product,
        code_subheading,
        business_unit_code_product,
        code_tradeAgreement,
        code_operation,
        operation_code,
        code_operation_initial,
        integration_certificate,
        quantity_available_product,
        quantity_available,
        nature_of_inventory,
        import_declaration,
        product_factor_conv,
        customs_clearance,
        inventory_id,
        product_description,
        reference,
        quantity,
        total_us_product,
        net_weight_kilo,
        net_weight_kilo_total,
        total_us_product_total,
        id,
        type_quantity_garbage,
        quantity_garbage,
        quantity_product,
        value_cif_unit_product,
        trade_agreement,
        value_cif_us,
        value_cif,
        net_weight_product_unit,
        net_weight_unit,
      } = dataToEditOP || {};

      const netWeightUnit =
        isValid(net_weight_product_unit) &&
        isString(net_weight_product_unit, true) &&
        parseFloat(parseNumberWithCommas(net_weight_product_unit)) !== 0
          ? parseFloat(parseNumberWithCommas(net_weight_product_unit))
          : parseFloat(parseNumberWithCommas(net_weight_unit));

      setValues((prev) => ({
        ...prev,
        net_weight_unit: netWeightUnit,
        type: String(type_quantity_garbage),
        id,
        quantity_supply: quantity_product,
        quantity_garbage: quantity_garbage,
        reference: reference || "",
        description: product_description || "",
        code_Product: code_product,
        product_unit: business_unit_code_product,
        busisness_unit: business_unit,
        agreement: code_tradeAgreement || "N/A",
        subheading: code_subheading,
        operation: code_operation || operation_code,
        initial: code_operation_initial,
        certificate: integration_certificate,
        available_balance: quantity_available,
        quantity_available_product: quantity_available_product,
        value_cif: value_cif || value_cif_us,
        natural: nature_of_inventory,
        convertion_factor:
          isNumber(product_factor_conv) || isString(product_factor_conv, true)
            ? parseFloat(product_factor_conv)
            : 0,
        taxes: import_declaration ?? 0,
        name_tradeAgreement: trade_agreement,
        customs_clearance: customs_clearance ?? 0,
        quantity,
        fob_value: total_us_product,
        net_weight: net_weight_kilo,
        calculated_weight: parseFloat(net_weight_kilo_total),
        calculate_value: total_us_product_total,
        inventory_id,
        value_cif_unit: parseFloat(value_cif_unit_product),
      }));
    }
  }, [dataToEditOP]);

  useEffect(() => {
    if (isValid(quantity)) {
      setValues((prev) => ({
        ...prev,
        net_weight_calculate:
          parseFloat(parseNumberWithCommas(quantity)) *
          parseFloat(parseNumberWithCommas(net_weight_unit)),
      }));
    }
  }, [quantity]);

  //formData.append("type", percentageAndAmount === "%" ? 0 : 1);

  useEffect(() => {
    return () => {
      setDataProductionOrder(null);
      //  reset(undefined, { keepDirtyValues: true });
    };
  }, []);

  useEffect(() => {
    if (firstRenderQuantity.current) {
      firstRenderQuantity.current = false;
      return;
    }
    if (isValid(quantity_supply)) {
      const quantitySupply =
        isString(quantity_supply, true) || isNumber(quantity_supply)
          ? parseFloat(quantity_supply)
          : 0;

      const convertionFactor =
        isString(convertion_factor, true) || isNumber(convertion_factor)
          ? parseFloat(convertion_factor)
          : 0;

      const valueCifUnit =
        isString(value_cif_unit, true) || isNumber(value_cif_unit)
          ? parseFloat(value_cif_unit)
          : 0;
      //Peso en kilos
      const netWeight = convertionFactor * quantitySupply;
      const calculated_weight = quantitySupply * netWeight;
      //const calculate_value = quantitySupply * valueCifUnit;

      setValues((prev) => ({
        ...prev,
        ...(isValid(product_unit) && product_unit?.toLowerCase() === "kg"
          ? { net_weight: netWeight }
          : {}),
        calculated_weight: calculated_weight,
      }));
    }
    console.log("quantity", { precision });
    if (isValid(quantity)) {
      setValues((prev) => ({
        ...prev,
        calculate_value:
          parseNumberWithCommas(quantity) *
          parseNumberWithCommas(value_cif_unit),
      }));
    }
  }, [quantity_supply, quantity]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchSupplies}
        onOpen={onOpenSearchSupplies}
        onClose={onCloseSearchSupplies}
        title="Agregar desde plantilla"
        size={"6xl"}
      >
        <ModalSearchSupplies
          data={dataReferenceOP}
          onCloseModal={closeModals}
        />
      </ModalGeneric>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3" al>
        <Box className="d-flex" alignItems={"flex-end"} gap={2}>
          <LabelGroup
            tipo={"text"}
            name={"reference"}
            value={reference}
            onChange={handleInputChange}
            tag={"Referencia"}
            size={90}
            desicion={false}
          />

          <PopoverForm
            onOpenPopover={onOpenPopover}
            onClosePopover={onClosePopover}
            isOpenPopover={isOpenPopover}
          >
            <IconButton size="md" icon={<RiSearch2Line />} />
          </PopoverForm>
        </Box>

        <LabelGroup
          name={"description"}
          value={description}
          tag={"Descripción"}
          size={90}
          desicion={true}
        />

        <LabelGroup
          name={"natural"}
          value={natural}
          tag={"Naturaleza"}
          size={90}
          desicion={true}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <LabelGroup
          name={"code_Product"}
          value={code_Product}
          tag={"Código producto"}
          size={100}
          desicion={true}
        />

        <LabelGroup
          name={"quantity_available_product"}
          value={quantity_available_product}
          tag={"Saldo Prod disponible"}
          size={100}
          desicion={true}
        />

        <LabelGroup
          name={"product_unit"}
          value={product_unit}
          tag={"Unidad de producto"}
          size={100}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          customInput={LabelGroup}
          name={"convertion_factor"}
          value={convertion_factor}
          tag={"Factor conv."}
          size={100}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          size={100}
          value={quantity_supply}
          tag="Cantidad producto"
          name={"quantity_supply"}
          onValueChange={updateItemValue}
          /*  error={errors?.quantity_supply}
{...register("quantity_supply", {
required: "Cantidad de producto es requerido",
min: { value: 1, message: "Este campo es obligatorio" },
})} */
        >
          {/*   {errors?.quantity_supply && (
            <FormErrorMessage>
              {errors?.quantity_supply?.message}
            </FormErrorMessage>
          )} */}
        </NumericFormat>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          value={quantity}
          name={"quantity"}
          size={100}
          tag="Cantidad"
          onValueChange={updateItemValue}
          /*   error={errors?.quantity}
{...register("quantity", {
required: "El campo cantidad es requerido",
min: { value: 1, message: "Este campo es obligatorio" },
})} */
        >
          {/*   {errors?.quantity && (
            <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
          )} */}
        </NumericFormat>

        <LabelGroup
          name={"busisness_unit"}
          value={busisness_unit}
          tag={"Unidad comercial"}
          size={100}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          name={"quantity_garbage"}
          value={quantity_garbage}
          tag={"Desperdicio"}
          size={100}
          desicion={false}
          onValueChange={updateItemValue}
          // onBlur={handleBlurCant}
          maxLength={34}
        />

        <RadioGroup
          onChange={(e) => setValues((prev) => ({ ...prev, type: e }))}
          value={type}
          defaultValue={"1"}
          display={"flex"}
          gap={2}
        >
          <Radio value={"1"}>Cant</Radio>
          <Radio value={"0"}>%</Radio>
        </RadioGroup>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          size={100}
          tag={"Peso en kilos"}
          value={net_weight}
          name={"net_weight"}
          onValueChange={updateItemValue}
          /*  error={errors?.net_weight}
{...register("net_weight", {
required: "Peso en kilo es requerido",
min: { value: 1, message: "Este campo es obligatorio" },
})} */
        >
          {/*    {errors?.net_weight && (
            <FormErrorMessage>{errors?.net_weight?.message}</FormErrorMessage>
          )} */}
        </NumericFormat>

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={precision}
          placeholder={"0"}
          customInput={LabelGroup}
          onValueChange={updateItemValue}
          value={fob_value}
          name={"fob_value"}
          /*    {...register("fob_value", {
               required: "Valor en US$ es requerido",
               min: { value: 1, message: "Este campo es obligatorio" },
             })} */
          tag={"Valor en US$"}
          size={100}
          desicion={false}
          maxLength={34}
          /*  error={errors?.fob_value} */
        >
          {/*    {errors.fob_value && (
            <FormErrorMessage>{errors.fob_value.message}</FormErrorMessage>
          )} */}
        </NumericFormat>

        <LabelGroup
          name={"name_tradeAgreement"}
          value={name_tradeAgreement}
          tag={"Acuerdo"}
          size={100}
          desicion={true}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <LabelGroup
          name={"subheading"}
          value={subheading}
          tag={"Subpartida"}
          size={100}
          desicion={true}
          //  maxLength={MaxLength?.global?.code_subheading}
        />

        <LabelGroup
          name={"certificate"}
          value={certificate}
          tag={"Certificado"}
          size={100}
          desicion={true}
        />

        <LabelGroup
          name={"customs_clearance"}
          value={customs_clearance}
          tag={"Dcl. Imp"}
          size={100}
          desicion={true}
        />

        <LabelGroup
          name={"operation"}
          value={operation}
          tag={"Operación"}
          size={100}
          desicion={true}
        />

        <LabelGroup
          name={"initial"}
          value={initial}
          tag={"Inicial"}
          size={100}
          desicion={true}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          size={100}
          value={available_balance}
          tag={"Saldo disponible"}
          name={"available_balance"}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          size={100}
          value={value_cif}
          tag={"Saldo en valor."}
          name={"value_cif"}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={precision}
          decimalSeparator="."
          customInput={LabelGroup}
          name={"calculate_value"}
          value={calculate_value}
          tag={"Valor calculado"}
          size={100}
          desicion={true}
        />

        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={4}
          decimalSeparator="."
          customInput={LabelGroup}
          name={"net_weight_calculate"}
          value={net_weight_calculate}
          //  onChange={handleOnInputChange}
          data-key="formCalculate"
          tag={"Peso calculado"}
          size={100}
          // onBlur={handleBlurCant}
          maxLength={34}
          desicion={true}
        />
      </SimpleGrid>

      <Flex pt="5" pb="5">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#3182CE"} onClick={createSupplies}>
          Guardar
        </ButtonStyled>
      </Flex>
    </>
  );
};
