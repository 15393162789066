import { searchTypes } from "redux/types/searchTypes"

export const setSearch = (payload) => {
  return {
    type: searchTypes.SEARCH,
    payload
  }
}

export const clearSearch = () => {
  return {
    type: searchTypes.CLEAR
  }
}

export const updateSearch = (payload) => {
  return {
    type: searchTypes.UPDATE,
    payload
  }
}

export const getSearch = (state) => state.search
