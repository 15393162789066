import { MaxLength } from "utils/MaxLength";

const ConfiRegex = {
  numbersOnly: "numbersOnly",
  lettersOnly: "lettersOnly",
  anythingGoes: "anythingGoes"
};

export const regexPatterns = {
  numbersWithDotsAndCommas: /^[+-]?[0-9]+([,.][0-9]+)?$/,
  numbersOnly: /^[0-9-.\s]*$/g,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  anythingGoes: /^.*$/,
  lettersAndNumbersOnly: /^[a-zA-Z0-9]*$/,
  lettersOnly: /^[a-zA-Z\s]*$/
};

export const errorMessageMap = {
  numbersOnly: "números",
  lettersOnly: "caracteres",
  numbersWithDotsAndCommas: "números, puntos y comas",
  email: "una dirección de correo válida"
};

export const FormInfoTemplate = [
  {
    label: "Placa vehículo",
    Property: "vehicle_plate",
    PropertyCorrected: "vehicle_plate_corrected",
    type: "number",
    RegExpType: ConfiRegex.lettersAndNumbersOnly,
    maxLength: MaxLength.entry_movement.form_int.vehicle_plate
  },
  {
    label: "Cédula del conductor",
    Property: "identification",
    PropertyCorrected: "identification_corrected",
    RegExpType: ConfiRegex.numbersOnly,
    maxLength: MaxLength.entry_movement.form_int.identification
  },
  {
    label: "Conductor",
    Property: "driver",
    PropertyCorrected: "driver_corrected",
    RegExpType: ConfiRegex.lettersOnly,
    maxLength: MaxLength.entry_movement.form_int.driver
  },
  {
    label: "Número de teléfono",
    Property: "phone",
    PropertyCorrected: "phone_corrected",
    type: "number",
    RegExpType: ConfiRegex.numbersOnly,
    maxLength: MaxLength.entry_movement.form_int.phone
  }
];

export const FormItemsTemplate = [
  {
    label: "Item de salida*",
    Property: "item",
    PropertyCorrected: "item_corrected",
    RegExpType: ConfiRegex.numbersOnly,
    maxLength: 10
  },
  {
    label: "Referencia comercial",
    Property: "reference",
    PropertyCorrected: "reference_corrected",
    RegExpType: ConfiRegex.anythingGoes,
    readOnly: true
  },
  {
    label: "Código producto",
    Property: "code_product",
    PropertyCorrected: "code_product_corrected",
    RegExpType: ConfiRegex.anythingGoes,
    readOnly: true
  },
  {
    label: "Cantidad producto despachada*",
    Property: "quantity_dispatch_product",
    PropertyCorrected: "quantity_dispatch_product_corrected",
    RegExpType: ConfiRegex.numbersOnly,
    maxLength: 10
  },
  {
    label: "Cantidad despachada*",
    Property: "quantity_dispatch",
    PropertyCorrected: "quantity_dispatch_corrected",
    RegExpType: ConfiRegex.numbersOnly,
    maxLength: 10
  },
  {
    label: "Unidad comercial",
    Property: "code_businessUnit",
    PropertyCorrected: "code_businessUnit_corrected",
    RegExpType: ConfiRegex.anythingGoes,
    readOnly: true
  },
  {
    label: "Descripción de subpartida",
    Property: "description",
    PropertyCorrected: "description_corrected",
    RegExpType: ConfiRegex.anythingGoes,
    readOnly: true
  },
  {
    label: "Descripción",
    Property: "description_product",
    PropertyCorrected: "description_product_corrected",
    RegExpType: ConfiRegex.anythingGoes,
    readOnly: true
  }
];
