import axios from "axios";

const createRequestOptions = (
  method = "GET",
  contentType = null,
  body = null,
  token = null
) => {
  if (!['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'HEAD', 'OPTIONS'].includes(method)) {
    throw new Error("Método HTTP no válido");
  }

  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(contentType && { "Content-Type": contentType })
  };

  const requestOptions = {
    method,
    headers,
    ...(method !== "GET" && body && { data: body })
  };

  return requestOptions;
};

export const options = {
  SENDJSONCONTENTPOST: (token, body) => {
    return createRequestOptions("POST", "application/json", body, token);
  },
  SENDFORMDATACONTENTPOST: (token, body) => {
    return createRequestOptions("POST", "multipart/form-data", body, token);
  },
  GET: (token) => {
    return createRequestOptions("GET", null, null, token);
  },
  POST: (token, body) => {
    return createRequestOptions("POST", "application/json", body, token);
  }
};

export const executeHTTPRequest = async (endpoint, options) => {
  const res = await axios(endpoint, options);
  return res;
};
