import { Td, Tr } from "@chakra-ui/react";
import MenuOtherCost from "components/Menu/MenuIntegracionBox/MenuOtherCost";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import React, { useContext } from "react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";

const TbodyOtherCosts = ({ data }) => {
  // console.log("otros cosos", data);
  const location = useLocation();
  const { pathname } = location;
  const { idCorrected = null } = queryString.parse(location.search);
  const { setDataToEditCorrectCost, setShowViewCorrectCost, setActiveView } = useContext(
    IntegracionBoxCrudContex
  );
  const handleInfo = () => {
    setDataToEditCorrectCost(data);
    setShowViewCorrectCost(true);
    setActiveView("showViewCorrectCost");
  };

  return (
    <Tr
      className={data?.corrected === 2 && idCorrected && "BorderCorrectTabla"}
    >
      <Td className="text-center">
        {data.item_code ? data.item_code : data.item_code_corrected}
      </Td>
      <Td className="text-center">
        {data?.name_cost ? data?.name_cost : data?.name_cost_corrected}
      </Td>
  {/*     <Td className="text-center">
        {data?.quantity_cost
          ? data?.quantity_cost
          : data?.quantity_cost_corrected}
      </Td> */}
      <Td className="text-center">
        {data?.net_weight_cost
          ? data?.net_weight_cost
          : data?.net_weight_cost_corrected}
      </Td>
      <Td className="text-center">
        {data.value_cost ? data.value_cost : data.value_cost_corrected}
      </Td>
      <Td className="text-center">
        {data?.value_unit ? data?.value_unit : data?.value_unit_corrected}
      </Td>
      {(pathname === "/IntegracionBox" && !data.production_order_id) ||
      (pathname === "/CorrectedCertificate" &&
        !data.productionOrderCorrected_id)
        ? (
          <Td className="text-center">
            <MenuOtherCost data={data} />
          </Td>
        )
        : pathname === "/ShowIntegrationBox" &&
        data?.corrected === 2 &&
        idCorrected
          ? (
            <Td className="text-center">
              <IconGeneric
                color={"#1F7AC3"}
                as={BsEyeFill}
                onClick={() => handleInfo()}
              />
            </Td>
          )
          : (
            <Td className="text-center"></Td>
          )}
    </Tr>
  );
};

export default TbodyOtherCosts;
