import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import {
  Image,
  Box,
  IconButton,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  VStack,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import {
  FaSearchPlus,
  FaSearchMinus,
  FaUndo,
  FaDownload,
  FaQuestionCircle,
  FaExpandArrowsAlt,
  FaCompressArrowsAlt,
  FaAdjust,
} from "react-icons/fa";
import Joyride, { STATUS } from "react-joyride";
import { isValid } from "utils/type-check-utils";

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 5;
const ZOOM_STEP = 0.1;

const ZoomImage = ({
  src,
  alt,
  toggleFullscreen,
  isFullscreen = null,
  setIsFullscreen = null,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [runTour, setRunTour] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageRef = useRef(null);

  const handleZoomIn = useCallback(() => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + ZOOM_STEP, MAX_ZOOM));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - ZOOM_STEP, MIN_ZOOM));
  }, []);

  const handleImageDrag = useCallback(
    (e) => {
      if (e.buttons === 1) {
        setPosition((prevPos) => ({
          x: prevPos.x + e.movementX / zoomLevel,
          y: prevPos.y + e.movementY / zoomLevel,
        }));
      }
    },
    [zoomLevel]
  );

  const resetImage = useCallback(() => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
    setBrightness(100);
  }, []);

  const downloadImage = useCallback(() => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = alt || "image";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading image:", error));
  }, [src, alt]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      isValid(setIsFullscreen) && setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const imageStyle = useMemo(
    () => ({
      transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
      transition: "transform 0.3s ease-out",
      cursor: zoomLevel > 1 ? "move" : "default",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      filter: `brightness(${brightness}%)`,
    }),
    [zoomLevel, position.x, position.y, brightness]
  );

  const steps = [
    {
      target: ".zoom-in",
      content: "Haz clic aquí para acercar la imagen",
      disableBeacon: true,
    },
    {
      target: ".zoom-out",
      content: "Haz clic aquí para alejar la imagen",
    },
    {
      target: ".reset",
      content: "Este botón reinicia el zoom y la posición de la imagen",
    },
    {
      target: ".download",
      content: "Descarga la imagen con este botón",
    },
    {
      target: ".fullscreen",
      content: "Activa o desactiva el modo pantalla completa",
    },
    {
      target: ".brightness",
      content: "Ajusta el brillo de la imagen",
    },
    {
      target: ".image-container",
      content: "Puedes arrastrar la imagen cuando esté ampliada para moverla",
      placement: "center",
      styles: {
        tooltip: {
          width: "auto",
          fontSize: "12px",
        },
      },
    },
    {
      target: ".zoom-slider",
      content:
        "Usa este control deslizante para ajustar el nivel de zoom con precisión",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    }
  };

  const startTour = () => {
    setRunTour(true);
    onOpen();
  };

  const handleWheel = useCallback(
    (e) => {
      if (e.deltaY < 0) {
        handleZoomIn();
      } else {
        handleZoomOut();
      }
    },
    [handleZoomIn, handleZoomOut]
  );

  return (
    <Box position="relative" width="100%" height="100%" overflow="hidden">
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltip: {
            fontSize: "14px",
          },
        }}
        locale={{
          back: "Atrás",
          close: "Cerrar",
          last: "Finalizar",
          next: "Siguiente",
          skip: "Saltar",
        }}
        floaterProps={{
          //   disableAnimation: true,
          placement: "auto",
        }}
        callback={handleJoyrideCallback}
      />

      <VStack
        spacing={2}
        position="absolute"
        top="10px"
        right="10px"
        zIndex="1"
      >
        <HStack>
          <Tooltip label="Acercar">
            <IconButton
              icon={<FaSearchPlus />}
              onClick={handleZoomIn}
              aria-label="Zoom in"
              isDisabled={zoomLevel >= MAX_ZOOM}
              className="zoom-in"
            />
          </Tooltip>
          <Tooltip label="Alejar">
            <IconButton
              icon={<FaSearchMinus />}
              onClick={handleZoomOut}
              aria-label="Zoom out"
              isDisabled={zoomLevel <= MIN_ZOOM}
              className="zoom-out"
            />
          </Tooltip>
        </HStack>
        <HStack>
          <Tooltip label="Reiniciar">
            <IconButton
              icon={<FaUndo />}
              onClick={resetImage}
              aria-label="Reset image"
              className="reset"
            />
          </Tooltip>
          <Tooltip label="Descargar">
            <IconButton
              icon={<FaDownload />}
              onClick={downloadImage}
              aria-label="Download image"
              className="download"
            />
          </Tooltip>
        </HStack>
        <HStack>
          <Tooltip
            label={
              isFullscreen ? "Salir de pantalla completa" : "Pantalla completa"
            }
          >
            <IconButton
              icon={
                isFullscreen ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />
              }
              onClick={toggleFullscreen}
              aria-label="Toggle fullscreen"
              className="fullscreen"
              isDisabled={!isValid(isFullscreen)}
            />
          </Tooltip>
          <Tooltip label="Ajustar brillo">
            <IconButton
              icon={<FaAdjust />}
              onClick={onOpen}
              aria-label="Adjust brightness"
              className="brightness"
            />
          </Tooltip>
        </HStack>
        <Tooltip label="Mostrar ayuda">
          <IconButton
            icon={<FaQuestionCircle />}
            onClick={startTour}
            aria-label="Show help"
            className="help"
          />
        </Tooltip>
      </VStack>
      <Box
        className="image-container"
        width="100%"
        height="100%"
        onWheel={handleWheel}
      >
        <Image
          ref={imageRef}
          src={src}
          alt={alt}
          style={imageStyle}
          onMouseMove={handleImageDrag}
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
        />
      </Box>
      <Box
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        width="80%"
        maxWidth="300px"
        className="zoom-slider"
      >
        <Slider
          aria-label="zoom-control"
          min={MIN_ZOOM}
          max={MAX_ZOOM}
          step={ZOOM_STEP}
          value={zoomLevel}
          onChange={(v) => setZoomLevel(v)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text textAlign="center">Zoom: {(zoomLevel * 100).toFixed(0)}%</Text>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajustar Brillo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Slider
              aria-label="brightness-control"
              min={0}
              max={200}
              value={brightness}
              onChange={(v) => setBrightness(v)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text textAlign="center">Brillo: {brightness}%</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ZoomImage;
