/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import { Switch } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const validateForm = (form, openAlert) => {
  if (!form?.name_transportMethod.trim()) {
    openAlert("Ingrese el nombre del método de transporte", "error");
    return false;
  }

  if (!form?.code_transportMethod.trim()) {
    openAlert("Ingrese el código", "error");
    return false;
  }

  return true;
};

const TransportMethodForm = () => {
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const initialForm = {
    name_transportMethod: "",
    code_transportMethod: "",
    status_transportMethod: 1
  };

  const [form, handleInputChange, resetForm, setForm] = useForm();
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (dataToEdit) {
      setForm({ ...dataToEdit });
      setCheck(dataToEdit.status_transportMethod === 1);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (dataToEdit) {
      if (!validateForm(form, openAlert)) return;
      const data = {
        ...form,
        status_transportMethod: form?.status_transportMethod ? 1 : 0,
        id: dataToEdit.id
      };
      // console.log(data);
      editData(data);
    } else {
      if (!validateForm(form, openAlert)) return;
      // console.log(form);
      createData(form);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar método de transporte"
          : "Crear método de transporte"}
      </Text>
      <Divider mb={3} />
      <form action="">
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="id">Código</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_transportMethod"
              id="code_transportMethod"
              value={form?.code_transportMethod || ""}
              onChange={(e) => handleRegex(e, 'all', Regex?.all)}
              maxLength={3}
            />
          </div>
          <div>
            <LabeFormlStyled htmlFor="name_packaging">
              Nombre método de transporte
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_transportMethod"
              id="name_transportMethod"
              value={form?.name_transportMethod || ""}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={80}
            />
          </div>
        </SimpleGrid>

        {dataToEdit && (
          <div>
            <LabeFormlStyled htmlFor="status_transportMethod">
              Activado
            </LabeFormlStyled>
            <div>
              <Switch
                name="status_transportMethod"
                id="status_transportMethod"
                size="lg"
                value={form?.status_transportMethod}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        )}

        {/* <div className="row"> */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>

        {/* </div> */}
      </form>
    </>
  );
};

export default TransportMethodForm;
