import { Tooltip } from '@chakra-ui/react'
import React from 'react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'

export const ButtonForAction = ({
  onClick,
  icon,
  colorIcon = 'blue',
  tooltip = ''
}) => {
  return (
    <Tooltip placement='top' hasArrow label={tooltip}>
      <span>
        <ButtonHeaderModule bgColor={colorIcon} onClick={onClick}>
          <IconGeneric className='text-white' width='16px' as={icon} />
        </ButtonHeaderModule>
      </span>
    </Tooltip>
  )
}
