import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TbodyTypesOfDocument = ({ data }) => {
  const { changeStatus } = useContext(ConfigurationCRUDContext);
  const TdState = styled(Td)`
    color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
  `;
  const [loadingIcons, setLoadingIcons] = useState(false);

  const handlesubmit = (status, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);
    changeStatus(formData);
  };

  return (
    <Tr>
      <Td className="text-center">{data?.document}</Td>
      <Td className="text-center">
        {data?.description === null ? "N/A" : data?.description}
      </Td>
      <Td className="text-center">
        {data?.annexe === null ? "N/A" : data?.annexe}
      </Td>
      <Td className="text-center">
        {data?.annexe_description === null ? "N/A" : data?.annexe_description}
      </Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data?.document_type_status === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={() =>
            handlesubmit(data?.document_type_status, data?.document_type_id)
          }
          className="text-center"
          isNumeric
          text={data?.document_type_status}
        >

          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.document_type_status}
          />
        </TdState>
      </Tooltip>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTypesOfDocument;
