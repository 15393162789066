import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import { MenuTabs } from "components/Menu/MenuOrderProduct/MenuTabs";

// import CustomsCrudContext from "context/CustomsCrudContext";

const TBodyElaborado = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">
        <span>{data?.product_description}</span>
      </Td>
      <Td className="text-center">{data?.product_code ?? "N/A"}</Td>

      <Td isNumeric className="text-center">
        {data?.unit_measurement_code ?? ""}
      </Td>
      <Td isNumeric className="text-center">
        {data?.quantity_product}
      </Td>

      <Td className="text-center">{data?.subheading_code ?? "N/A"}</Td>
      <Td className="text-center">{data?.business_unit_code ?? "N/A"}</Td>

      <Td className="text-center">{data?.quantity}</Td>
      <Td isNumeric className="text-center">
        {data?.net_weight_kilo}
      </Td>
      <Td isNumeric className="text-center">
        {data?.total_calculated}
      </Td>

      <Td className="text-center">
        <MenuTabs data={data} />
      </Td>
    </Tr>
  );
};

export default TBodyElaborado;
