import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";

export const UserToUserCorrect = ({ data, handleChange, StateDisble }) => {
  const { opcional, userQualifiedNew, StateNit, setStateNit } =
    useContext(FormCrudCRUDContext);
  const {
    commercial_invoice_corrected,
    value_duta_corrected,
    value_duta,
    name_qualified2
  } = data;

  return (
    <>
      {!StateDisble
        ? (
          <div style={{ width: "100%" }}>
            <InputSelect
              tag={"Nit"}
              data={userQualifiedNew && userQualifiedNew}
              set={setStateNit}
              value={StateNit}
              changeValue={name_qualified2 !== StateNit?.label && "BorderCorrect"}
            />
          </div>
        )
        : (
          <LabelGroup
            name={"num_exit_form_corrected"}
            value={StateNit.label}
            tag={"Nit"}
            size={100}
            onChange={handleChange}
            desicion={true}
            changeValue={name_qualified2 !== StateNit?.label && "BorderCorrect"}
          />
        )}

      <LabelGroup
        name={"commercial_invoice_corrected"}
        value={commercial_invoice_corrected}
        tag={"Factura Comercial"}
        size={100}
        onChange={handleChange}
        desicion={StateDisble}
      />

      {opcional && (
        <LabelGroup
          name={"value_duta_corrected"}
          value={value_duta_corrected}
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          onChange={handleChange}
          tag={opcional}
          size={100}
          desicion={StateDisble}
        />
      )}
    </>
  );
};
