/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { __ShowOperation } from "request/configuration/__Operations";
import { MdCheckCircle } from "react-icons/md";
import { useSelector } from "react-redux";

const ModalDocumentOperation = ({ info, isOpen, onClose }) => {
  const [form, setForm] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialpos = async () => {
    try {
      const res = await __ShowOperation(token, info.id);
      setForm(res?.data?.status?.data.documents);
    } catch (error) {}
  };

  useEffect(() => {
    initialpos();
  }, [info]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Soportes requeridos</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          {form.length !== 0
            ? form.map((documentos) => (
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={MdCheckCircle} color="#1F7AC3" />
                  {documentos.name_document}
                </ListItem>
              </List>
            ))
            : " No tiene Soportes Requeridos"}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDocumentOperation;
