/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Button, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import OrderProductionContext from "context/OrderProductionContext";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { NavLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "context/AuthContext";
import { useSelector } from "react-redux";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { IconTracking } from "styled/Icons.styled";
import { RiRadioButtonFill } from "react-icons/ri";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { isString, isValid } from "utils/type-check-utils";
import MenuSupply from "components/Menu/MenuOrderProduct/MenuSupply";
import { parseFloat } from "utils/parseFloat";
import { NumericFormat } from "react-number-format";
import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";

const TBodyMatter = ({ data }) => {
  const [typeQuantityGarbage, setTypeQuantityGarbage] = useState(null);
  const optionsBalance = useMemo(() => ["%", "Cnt"], []);
  const location = useLocation();
  const { Orden = "" } = queryString.parse(location.search);
  const { decimal_values_precision, token, is_qualified_user } = useSelector(
    (state) => state.auth.dataUser
  );
  const { authUser } = useContext(AuthContext);
  const [fomateo] = useFormatQuantity();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [trackingData, setTrackingData] = useState({
    currentPage: [],
    maxPage: 1,
    loading: false,
  });
  const { createDps, decimalUc } = useContext(OrderProductionContext);

  const [values, handleInputChange, , setValues] = useForm({
    percentage_garbage: "",
    quantity_garbage: "",
  });
  const { percentage_garbage, quantity_garbage } = values;

  const urlTemplates = useMemo(
    () => ({
      "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
      INGRESO: `/FormShow?FormEntry=${data?.form_id}`,
    }),
    [data?.form_id]
  );

  useEffect(() => {
    const validationQuantityGarbage =
      isValid(data?.type_quantity_garbage) &&
      isString(data?.type_quantity_garbage, true)
        ? parseFloat(data?.type_quantity_garbage)
        : data?.type_quantity_garbage;

    setTypeQuantityGarbage(
      validationQuantityGarbage === 0
        ? "%"
        : validationQuantityGarbage === 1
        ? "Cnt"
        : null
    );
  }, [data, setValues]);

  useEffect(() => {
    setValues({
      quantity_garbage: data?.quantity_garbage,
      percentage_garbage: data?.percentage_garbage,
    });
  }, [typeQuantityGarbage, setValues, data]);

  const handleBlurCant = useCallback(
    (e) => {
      const inputValue =
        isValid(e.target.value) && isString(e.target.value, true)
          ? parseNumberWithCommas(e.target.value)
          : "";

      if (inputValue) {
        const quantity = fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimalUc
            ? decimalUc
            : Number(decimal_values_precision),
          parseFloat(inputValue)
        );

        createDps({
          item: data?.item,
          production_order_id: Orden,
          supply_id: data.product_id,
          quantity: parseFloat(quantity),
          type: typeQuantityGarbage === "%" ? 0 : 1,
        });

        return 0;
      }
      
      createDps({
        item: data?.item,
        production_order_id: Orden,
        supply_id: data.product_id,
        quantity: parseFloat(
          typeQuantityGarbage !== "%" ? quantity_garbage : percentage_garbage
        ),
        type: typeQuantityGarbage === "%" ? 0 : 1,
      });
    },
    [
      Orden,
      authUser.admin,
      authUser.is_commerce,
      createDps,
      data.product_id,
      decimal_values_precision,
      decimalUc,
      fomateo,
      percentage_garbage,
      typeQuantityGarbage,
      quantity_garbage,
    ]
  );

  const showTrackingItem = useCallback(
    async (trackings) => {
      setTrackingData((prev) => ({ ...prev, loading: true }));
      try {
        const formData = new FormData();
        trackings?.forEach((tracking, index) => {
          formData.append(`tracking_id[${index}]`, tracking?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setTrackingData({
          currentPage: res.data.status.data.data,
          maxPage: res.data.status.data.last_page,
          loading: false,
        });
        onOpen();
      } catch (error) {
        console.error(error);
        setTrackingData((prev) => ({ ...prev, loading: false }));
      }
    },
    [onOpen, token]
  );

  const renderTrackingStatus = useMemo(() => {
    if (!data?.tracking?.length) return null;
    const isInTracking = data.tracking.some(
      (tracking) => tracking.status === 1
    );
    const wasInTracking = data.tracking.every(
      (tracking) => tracking.status === 2
    );

    return (
      <Tooltip
        hasArrow
        placement="top"
        label={
          isInTracking
            ? "En seguimiento"
            : wasInTracking
            ? "Estuvo en seguimiento"
            : ""
        }
      >
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <IconTracking
            color={isInTracking ? "#E64545" : "#20B95A"}
            onClick={() => showTrackingItem(data.tracking)}
            as={RiRadioButtonFill}
          />
        </span>
      </Tooltip>
    );
  }, [data?.tracking, showTrackingItem]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Seguimiento"
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={trackingData.currentPage}
          setData={(newData) =>
            setTrackingData((prev) => ({ ...prev, currentPage: newData }))
          }
          loading={trackingData.loading}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={trackingData.maxPage}
          loading={trackingData.loading}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr>
        <Td className="text-center">{data?.item}</Td>
        <Td className="text-center">
          {urlTemplates[data?.type_form] ? (
            <NavLink
              to={urlTemplates[data?.type_form]}
              className="ms-2"
              target="_blank"
            >
              <span className="formNumber">{data?.reference_description}</span>
            </NavLink>
          ) : (
            data?.reference_description
          )}
        </Td>
        <Td className="text-center">{data?.code_product}</Td>
        <Td className="text-center">{data?.business_unit_code_product}</Td>
        <Td className="text-center">{data?.quantity_product}</Td>
        <Td isNumeric className="text-center">
          {data?.code_subheading ?? ""}
        </Td>
        <Td isNumeric className="text-center">
          {data?.business_unit}
        </Td>
        <Td isNumeric className="text-center">
          {data?.quantity ?? ""}
        </Td>
        <Td className="text-center">{data?.nature_of_inventory}</Td>
        <Td className="text-center">{data?.operation_code}</Td>
        <Td isNumeric className="text-center">
          {data?.net_weight_kilo}
        </Td>
        <Td isNumeric className="text-center">
          {data?.total_us_product}
        </Td>
        <Td className="text-center">
          <div className="mt-1">
            <RadioBtn
              setOnly={setTypeQuantityGarbage}
              options={optionsBalance}
              state={typeQuantityGarbage}
            />
          </div>
        </Td>
        <Td className="text-center">
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalSeparator="."
            style={{ width: "70px" }}
            onChange={handleInputChange}
            onBlur={handleBlurCant}
            id={data.product_id}
            customInput={LabelGroup}
            name={
              typeQuantityGarbage !== "%"
                ? "quantity_garbage"
                : "percentage_garbage"
            }
            value={
              typeQuantityGarbage !== "%"
                ? quantity_garbage
                : percentage_garbage
            }
            size={100}
            tipo="text"
            tag=""
            desicion={!typeQuantityGarbage}
          />
        </Td>
        {is_qualified_user !== 1 && (
          <Td className="text-center">{renderTrackingStatus}</Td>
        )}
        <Td className="text-center">{data?.trade_agreement ?? "N/A"}</Td>
        <Td className="text-center">{data?.product_description}</Td>
        <Td className="text-center">
          <MenuSupply data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default React.memo(TBodyMatter);
