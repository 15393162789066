import React from 'react'

import './styles.css'
const Items = ({ title, desc, bgColor }) => {
  return (
    <div className="containerItems" style={{ backgroundColor: bgColor }}>
      <p className="titleItem">{title}</p>
      <p className="descriptionItem">{desc || 'N/A'}</p>
    </div>
  );
}

export default Items
