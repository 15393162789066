export const queryEnv = {
  /* Notifications */
  SHOW_ALERT_CONFIG: "SHOW_ALERT_CONFIG",
  GET_NOTIFICATIONS_CONFIG: "GET_NOTIFICATIONS_CONFIG",
  SHOW_CONFI_NOTIF_EMPRESA: "SHOW_CONFI_NOTIF_EMPRESA",
  /* Usuarios calificados */
  QUALIFIED_USERS: "QUALIFIED_USERS",


  /* Data selects */
  DESTINY_NOT_PAGINATE: "DESTINY_NOT_PAGINATE",

  /* Configuration */
  CONF_LICENCES: "Licences",
  
  
  INTEGRATION_BOX_QUERY_DATA: "INTEGRATION_BOX_QUERY_DATA",

  /* Integration Certificate */
  INTEGRATION_BOX_CI: "showIntegration",
  INTEGRATION_BOX: "INTEGRATION_BOX",
  ORDER_PRODUCTION_CIC: "ORDER_PRODUCTION_CIC",
  INTEGRATION_BOX_CORRECTED: "INTEGRATION_BOX_CORRECTED",
  GET_REFERENCE_SEARCH: "GET_REFERENCE_SEARCH",
  PRODUCTS_CI: "PRODUCTS_CI",
  SUPPLIES_CI: "SUPPLIES_CI",
  SUPPLIES_CIC_ITEM: "SUPPLIES_CIC",
  OTHER_COST_CI: "OTHER_COST_CI",
  GARBAGE_CI: "GARBAGE_CI",


   /* Orden de producción */
   PRODUCTS_OP: "getProductItemOP",
   SUPPLIES_OP: "getSupplyItemOP",
   OTHER_COST_OP: "getOtherCostItemOP",
   GARBAGE_OP: "getGarbageItemOP",
 


  /* Cola de revisión */
  APROVED_FMM: "APROVED_FMM",
  APROVED_FMM_TO_TRANSFER: "APROVED_FMM_TO_TRANSFER",

  /* Conf. Usuarios Bascula */
  USERS_WEIGHING: "USERS_WEIGHING",

  /* Venta de recargas */
  TAXES_RECHARGES: "TAXES_RECHARGES",


  /* BLOQUEOS */
  DEPENDENCIES_NO_PAGINATE: "DEPENDENCIES_NO_PAGINATE",
}

export default queryEnv; //getOtherCostItemOP, getGarbageItemOP, getSupplyItemOP, getProductItemOP