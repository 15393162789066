import axios from "axios";
import env from "../../constants/apiConst";

export const __TypeZf = async (token, posicion, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.TYPEZF_GET}?page=${posicion}`, options);
  return res;
};

export const __ShowTypeZf = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPEZF_SHOW}/${id}`, options);
  return res;
};

export const __TypeZfAll = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPEZF_ALL}`, options);
  return res;
};
