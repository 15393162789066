export const formExportSecurityQualifiedUser = [
  {
    value: "nit_qualified",
    label: "NIT",
    name: "nit_qualified"
  },
  {
    value: "name_qualified",
    label: "Razon social",
    name: "name_qualified"
  },
  {
    value: "nickname_qualified",
    label: "Nickname",
    name: "nickname_qualified"
  },
  {
    value: "address_qualified",
    label: "Dirección",
    name: "address_qualified"
  },
  {
    value: "email_qualified",
    label: "Correo Electrónico",
    name: "email_qualified"
  },

  {
    value: "phone_1_qualified",
    label: "Teléfono 1",
    name: "phone_1_qualified"
  },
  {
    value: "phone_2_qualified",
    label: "Teléfono 2",
    name: "phone_2_qualified"
  },
  {
    value: "phone_3_qualified",
    label: "Teléfono 3",
    name: "phone_3_qualified"
  },
  {
    value: "fax_qualified",
    label: "Fax",
    name: "fax_qualified"
  },
  {
    value: "representative",
    label: "Representante",
    name: "representative"
  },
  {
    value: "manager",
    label: "Gerente",
    name: "manager"
  },
  {
    value: "phone_manager",
    label: "Teléfono Gerente",
    name: "phone_manager"
  },
  {
    value: "email_manager",
    label: "Correo Gerente",
    name: "email_manager"
  },
  {
    value: "collect",
    label: "Recolecta",
    name: "collect"
  },
  {
    value: "unlinked",
    label: "Desvinculado",
    name: "unlinked"
  },
  {
    value: "generic_subheading_declaration",
    label: "Subpartida Genérica",
    name: "generic_subheading_declaration"
  },
  {
    value: "decimal_values_precision",
    label: "Precisión de Valores Decimales",
    name: "decimal_values_precision"
  },
  {
    value: "qualification_date",
    label: "Fecha de Calificación",
    name: "qualification_date"
  },
  {
    value: "partial_processing_revision_amount",
    label: "Cantidad de Revisión de Procesamiento Parcial",
    name: "partial_processing_revision_amount"
  },
  {
    value: "total_amount_incomeGenerating_activity",
    label: "Cantidad de Actividad Generadora de Ingresos",
    name: "total_amount_incomeGenerating_activity"
  },
  {
    value: "third_party_documents",
    label: "Documentos de Terceros",
    name: "third_party_documents"
  },
  {
    value: "third_party_items",
    label: "Artículos de Terceros",
    name: "third_party_items"
  },
  {
    value: "terms_and_conditions",
    label: "Términos y Condiciones",
    name: "terms_and_conditions"
  },
  {
    value: "status_qualified",
    label: "Estado",
    name: "status_qualified"
  },
  {
    value: "document_balance",
    label: "Saldo Documento",
    name: "document_balance"
  },
  {
    value: "scale_balance",
    label: "Saldo Escala",
    name: "scale_balance"
  },
  {
    value: "block_status",
    label: "Estado de Bloqueo",
    name: "block_status"
  },
  {
    value: "tracking",
    label: "Seguimiento",
    name: "tracking"
  },
  {
    value: "charge_reweight",
    label: "Recargo de Re-Peso",
    name: "charge_reweight"
  },
  {
    value: "shortName_freeZone",
    label: "Zona Franca",
    name: "shortName_freeZone"
  },
  {
    value: "name_city",
    label: "Ciudad",
    name: "name_city"
  },
  {
    value: "name_activity",
    label: "Actividad",
    name: "name_activity"
  },
  {
    value: "name_qualificationQualifiedUser",
    label: "Calificación",
    name: "name_qualificationQualifiedUser"
  },
  {
    value: "name_sector",
    label: "Sector",
    name: "name_sector"
  }

];

export const arrayStatisticsSecurityVehicle = [
  {
    value: "name_typeTransport",
    label: "Tipo de vehículo",
    name: "name_typeTransport"
  },
  {
    value: "driver",
    label: "Conductor",
    name: "driver"
  },
  {
    value: "vehicle_plate",
    label: "Placa del vehículo",
    name: "vehicle_plate"
  },
  {
    value: "identification",
    label: "Identificación",
    name: "identification"
  },
  {
    value: "phone",
    label: "Teléfono",
    name: "phone"
  },
  {
    value: "code_security",
    label: "Código de seguridad",
    name: "code_security"
  },
  {
    value: "container_1",
    label: "Contenedor 1",
    name: "container_1"
  },

  {
    value: "container_2",
    label: "Contenedor 2",
    name: "container_2"
  },
  {
    value: "capacity_container_1",
    label: "Capacidad contenedor 1",
    name: "capacity_container_1"
  },
  {
    value: "capacity_container_2",
    label: "Capacidad contenedor 2",
    name: "capacity_container_2"
  },
  {
    value: "date_security",
    label: "Fecha de seguridad",
    name: "date_security"
  },
  {
    value: "time_security",
    label: "Hora de seguridad",
    name: "time_security"
  },
  {
    value: "description_security",
    label: "Descripción de seguridad",
    name: "description_security"
  }
]

export const formExportStatisticsSecurityVehicle = [
  {
    value: "name_typeTransport",
    label: "Tipo de vehículo",
    name: "name_typeTransport"
  },
  {
    value: "driver",
    label: "Conductor",
    name: "driver"
  },
  {
    value: "vehicle_plate",
    label: "Placa del vehículo",
    name: "vehicle_plate"
  },
  {
    value: "identification",
    label: "Identificación",
    name: "identification"
  },
  {
    value: "phone",
    label: "Teléfono",
    name: "phone"
  },
  {
    value: "code_security",
    label: "Código de seguridad",
    name: "code_security"
  },
  {
    value: "container_1",
    label: "Contenedor 1",
    name: "container_1"
  },

  {
    value: "container_2",
    label: "Contenedor 2",
    name: "container_2"
  },
  {
    value: "capacity_container_1",
    label: "Capacidad contenedor 1",
    name: "capacity_container_1"
  },
  {
    value: "capacity_container_2",
    label: "Capacidad contenedor 2",
    name: "capacity_container_2"
  },
  {
    value: "date_security",
    label: "Fecha de seguridad",
    name: "date_security"
  },
  {
    value: "time_security",
    label: "Hora de seguridad",
    name: "time_security"
  },
  {
    value: "description_security",
    label: "Descripción de seguridad",
    name: "description_security"
  }
]

