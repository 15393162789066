import { useEffect, useState } from "react";

const useDebounceText = () => {
  const [text, setText] = useState(null);
  const [searchText, setSearchText] = useState(null);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setText(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  return [text, setSearchText];
};

export default useDebounceText;
