export const theadInventory = [
  { name: "Acciones", order: null },
  { name: "Nit", order: "", collapse: true },
  { name: "Empresa", order: "", collapse: true },
  { name: "Producto", order: "", collapse: true },
  { name: "Tipo producto", order: "", collapse: true },
  { name: "Grupo Producto", order: "", collapse: true },
  { name: "Referencia", order: "", collapse: true },
  { name: "Desaduanamiento", order: "", collapse: true },
  { name: "Serial", order: "", collapse: true },
  { name: "Subpartida", order: "", collapse: true },
  { name: "Descripción", order: "", collapse: true },
  { name: "Factura", order: "", collapse: true },
  { name: "Libre disposición tercero", order: "", collapse: true },
  { name: "Certificado", order: "", collapse: true },
  { name: "Ope.", order: "", collapse: true },
  { name: "Inic.", order: "", collapse: true },
  { name: "Acuerdo", order: "", collapse: true },
  { name: "Saldo.", order: "", collapse: true },
  { name: "Sdo. Aut. Salir.", order: "", collapse: true },
  { name: "Sdo. rsvd.", order: "", collapse: true },
  { name: "Sdo. en Temp.", order: "", collapse: true },
  { name: "Sdo. en Bodega", order: "", collapse: true },
  { name: "Unidad", order: "", collapse: true },
  { name: "Saldo Producto", order: "", collapse: true },
  {
    name: "Sdo. Producto Aut. Salir",
    order: "",
    collapse: true
  },
  { name: "Sdo. Producto rsvd", order: "", collapse: true },
  {
    name: "Sdo. Producto en Temp",
    order: "",
    collapse: true
  },
  {
    name: "Sdo. Producto en Bodega",
    order: "",
    collapse: true
  },
  { name: "Unidad Producto", order: "", collapse: true },
  { name: "Nat", order: "", collapse: true },
  { name: "Peso Neto Aut. Temp.", order: "", collapse: true },
  { name: "Cant. aut. Temp.", order: "", collapse: true },
  { name: "Cant.Producto aut. Temp. ", order: "", collapse: true },
  { name: "Peso Neto rsvd. Temp.", order: "", collapse: true },
  { name: "Cant Total ", order: "", collapse: true },
  { name: "Cant Producto Total ", order: "", collapse: true },
  { name: "Precision", order: "", collapse: true },
  { name: "FOB", order: "", collapse: true },
  { name: "CIF", order: "", collapse: true },
  { name: "Peso Neto", order: "", collapse: true },
  { name: "FOB Temp.", order: "", collapse: true },
  { name: "Seguimiento", order: "", collapse: true },
  { name: "FOB rsvd. Temp.", order: "", collapse: true },
  { name: "CIF aut. Temp.", order: "", collapse: true },
  { name: "Total de ingreso", order: "", collapse: true },
  { name: "Total de ingreso producto", order: "", collapse: true }
];

export const theadInventoryWithExport = [
  { name: "Acciones", order: null },
  { name: "Nit", order: "", collapse: true },
  { name: "Empresa", order: "", collapse: true },
  { name: "Producto", order: "", collapse: true },
  { name: "Tipo producto", order: "", collapse: true },
  { name: "Grupo Producto", order: "", collapse: true },
  { name: "Referencia", order: "", collapse: true },
  { name: "Desaduanamiento", order: "", collapse: true },
  { name: "Serial", order: "", collapse: true },
  { name: "Subpartida", order: "", collapse: true },
  { name: "Descripción", order: "", collapse: true },
  { name: "Factura", order: "", collapse: true },
  { name: "Libre disposición tercero", order: "", collapse: true },
  { name: "Certificado", order: "", collapse: true },
  { name: "Ope.", order: "", collapse: true },
  { name: "Inic.", order: "", collapse: true },
  { name: "Acuerdo", order: "", collapse: true },
  { name: "Saldo.", order: "", collapse: true },
  { name: "Sdo. Aut. Salir.", order: "", collapse: true },
  { name: "Sdo. rsvd.", order: "", collapse: true },
  { name: "Sdo. en Temp.", order: "", collapse: true },
  { name: "Sdo. en Bodega", order: "", collapse: true },
  { name: "Unidad", order: "", collapse: true },
  { name: "Saldo Producto", order: "", collapse: true },
  { name: "Sdo. Producto Aut. Salir", order: "", collapse: true },
  { name: "Sdo. Producto rsvd", order: "", collapse: true },
  { name: "Sdo. Producto en Temp", order: "", collapse: true },
  { name: "Sdo. Producto en Bodega", order: "", collapse: true },
  { name: "Unidad Producto", order: "", collapse: true },
  { name: "Nat", order: "", collapse: true },
  { name: "Peso Neto Aut. Temp.", order: "", collapse: true },
  { name: "Cant. aut. Temp.", order: "", collapse: true },
  { name: "Cant.Producto aut. Temp. ", order: "", collapse: true },
  { name: "Peso Neto rsvd. Temp.", order: "", collapse: true },
  { name: "Exp/Prov", order: "", collapse: true },
  { name: "NIT Exp/Prov", order: "", collapse: true },
  { name: "Cant Total ", order: "", collapse: true },
  { name: "Cant Producto Total ", order: "", collapse: true },
  { name: "Precision", order: "", collapse: true },
  { name: "FOB", order: "", collapse: true },
  { name: "CIF", order: "", collapse: true },
  { name: "Peso Neto", order: "", collapse: true },
  { name: "FOB Temp.", order: "", collapse: true },
  { name: "Seguimiento", order: "", collapse: true },
  { name: "FOB rsvd. Temp.", order: "", collapse: true },
  { name: "CIF aut. Temp.", order: "", collapse: true },
  { name: "Total de ingreso", order: "", collapse: true },
  { name: "Total de ingreso producto", order: "", collapse: true }
];

export const theadReferenceAssistant = [
  { name: "Nit", order: "" },
  { name: "Empresa", order: "" },
  { name: "Fecha", order: "" },
  { name: "Tipo de doc.", order: "" },
  { name: "Autorización", order: "" },
  { name: "Item", order: "" },
  { name: "Dcl. Imp.", order: "" },
  { name: "Valor ingresado", order: "" },
  { name: "Valor sacado", order: "" },
  { name: "Peso Ingresado", order: "" },
  { name: "Peso Sacado", order: "" },
  { name: "Cant. Ingresada", order: "" },
  { name: "Cant. Salida", order: "" },
  { name: "Unidad", order: "" },
  { name: "Saldo Cant.", order: "" },
  { name: "Cant. Prod. Ingresada", order: "" },
  { name: "Cant.Prod. Salida", order: "" },
  { name: "Unidad Producto", order: "" },
  { name: "Saldo Cant.Prod.", order: "" }
  // { name: "Acciones", order: null },
];

export const theadBalanceAssistant = [
  { name: "Nit", order: "" },
  { name: "Empresa", order: "" },
  { name: "Fecha", order: "" },
  { name: "Hora", order: "" },
  { name: "Tipo Doc Ref.", order: "" },
  { name: "Frm. Doc. Ref", order: "" },
  { name: "Item Ref.", order: "" },
  { name: "Tipo Doc", order: "" },
  { name: "Aut. Doc", order: "" },
  { name: "Cant. Ingresada", order: "" },
  { name: "Cant. Salida", order: "" },
  { name: "Saldo Cant.", order: "" },
  { name: "Cant.Prod. Ingresada", order: "" },
  { name: "Cant.Prod. Salida", order: "" },
  { name: "Saldo Prod. Cant.", order: "" }
  // { name: "Acciones", order: null },
];

export const theadSearchRef = [
  { name: "Código Producto", order: "" },
  { name: "Referencia", order: "" },
  { name: "Subpartida", order: "" },
  { name: "Descripciñón", order: "" },
  { name: "Certificado", order: "" },
  { name: "Decl. de Imp.", order: "" },
  { name: "Ope.", order: "" },
  { name: "Inic.", order: "" },
  { name: "Acuerdo", order: "" },
  { name: "Saldo", order: "" },
  { name: "Sdo. rsvd", order: "" },
  { name: "Unidad", order: "" },
  { name: "Saldo Producto", order: "" },
  { name: "Sdo.Producto rsvd", order: "" },
  { name: "Unidad Producto", order: "" }
  // { name: "Acciones", order: null },
];

export const theadAuxTemporales = [
  { name: "Nit", order: "" },
  { name: "Empresa", order: "" },
  { name: "Fecha", order: "" },
  { name: "Hora", order: "" },
  { name: "Tipo Doc Ref.", order: "" },
  { name: "Aut. Doc. Ref.", order: "" },
  { name: "Frm. Doc. Ref", order: "" },
  { name: "Item Ref.", order: "" },
  { name: "Tipo Doc", order: "" },
  { name: "Aut. Doc", order: "" },
  { name: "Formulario", order: "" },
  { name: "Item", order: "" },
  { name: "Unidad", order: "" },
  { name: "Cant. Ingresada", order: "" },
  { name: "Cant. Salida", order: "" },
  { name: "Saldo Cant.", order: "" },
  { name: "Cant. Pro. ingresada", order: "" },
  { name: "Cant. pro. salida", order: "" },
  { name: "Saldo cant Pro.", order: "" },
  { name: "Unidad producto ", order: "" }
];

export const theadAuxProduct = [
  { name: "Nit", order: "" },
  { name: "Empresa", order: "" },
  { name: "Código Producto", order: "" },
  { name: "Descripción", order: "" },
  { name: "Subpartida", order: "" },
  { name: "Grupo", order: "" },
  { name: "Unidad", order: "" },
  { name: "Saldo Producto", order: "" },
  { name: "Sdo. Producto Aut. Salir", order: "" },
  { name: "Sdo. Producto en Temp", order: null },
  { name: "Total Producto", order: "" }
];

export const theadInventoryInProcess = [
  { name: "NIT", order: "" },
  { name: "Empresa", order: "" },
  { name: "Producto", order: "" },
  { name: "Cant. Aprobada UC", order: "" },
  { name: "Referencia", order: "" },
  { name: "Subpartida", order: "" },
  { name: "Ope.", order: "" },
  { name: "Inic.", order: "" },
  { name: "Unidad Producto", order: "" },
  { name: "Nat.", order: "" },
  { name: "Cant. En Trámite", order: "" },
  { name: "Cant. Prod. En Trámite", order: "" },
  { name: "Fecha Aprobado", order: "" },
  { name: "Fecha último pase de entrada", order: "" },
  { name: "Cant. Pases de Entrada", order: "" }
];

export const theadInventoryUc = [
  // { name: "Acciones", order: "" },
  { name: "Nit", order: "", collapse: true },
  { name: "Empresa", order: "", collapse: true },
  { name: "Producto", order: "", collapse: true },
  { name: "Tipo producto", order: "", collapse: true },
  { name: "Grupo Producto", order: "", collapse: true },
  { name: "Referencia", order: "", collapse: true },
  { name: "Desaduanamiento", order: "", collapse: true },
  { name: "Serial", order: "", collapse: true },
  { name: "Subpartida", order: "", collapse: true },
  { name: "Descripción", order: "", collapse: true },
  { name: "Factura", order: "", collapse: true },
  { name: "Libre disposición tercero", order: "", collapse: true },
  { name: "Certificado", order: "", collapse: true },
  { name: "Ope.", order: "", collapse: true },
  { name: "Inic.", order: "", collapse: true },
  { name: "Acuerdo", order: "", collapse: true },
  { name: "Saldo.", order: "", collapse: true },
  { name: "Sdo. Aut. Salir.", order: "", collapse: true },
  { name: "Sdo. rsvd.", order: "", collapse: true },
  { name: "Sdo. en Temp.", order: "", collapse: true },
  { name: "Sdo. en Bodega", order: "", collapse: true },
  { name: "Unidad", order: "", collapse: true },
  { name: "Saldo Producto", order: "", collapse: true },
  {
    name: "Sdo. Producto Aut. Salir",
    order: "",
    collapse: true
  },
  { name: "Sdo. Producto rsvd", order: "", collapse: true },
  {
    name: "Sdo. Producto en Temp",
    order: "",
    collapse: true
  },
  {
    name: "Sdo. Producto en Bodega",
    order: "",
    collapse: true
  },
  { name: "Unidad Producto", order: "", collapse: true },
  { name: "Nat", order: "", collapse: true },
  { name: "Peso Neto Aut. Temp.", order: "", collapse: true },
  { name: "Cant. aut. Temp.", order: "", collapse: true },
  { name: "Cant.Producto aut. Temp. ", order: "", collapse: true },
  { name: "Peso Neto rsvd. Temp.", order: "", collapse: true },
  { name: "Cant Total ", order: "", collapse: true },
  { name: "Cant Producto Total ", order: "", collapse: true },
  { name: "Precision", order: "", collapse: true },
  { name: "FOB", order: "", collapse: true },
  { name: "CIF", order: "", collapse: true },
  { name: "Peso Neto", order: "", collapse: true },
  { name: "FOB Temp.", order: "", collapse: true },
  { name: "Seguimiento", order: "", collapse: true },
  { name: "FOB rsvd. Temp.", order: "", collapse: true },
  { name: "CIF aut. Temp.", order: "", collapse: true },
  { name: "Total de ingreso", order: "", collapse: true },
  { name: "Total de ingreso producto", order: "", collapse: true }
];

export const theadInventoryWithExportUc = [
  { name: "Nit", order: "", collapse: true },
  { name: "Empresa", order: "", collapse: true },
  { name: "Producto", order: "", collapse: true },
  { name: "Tipo producto", order: "", collapse: true },
  { name: "Grupo Producto", order: "", collapse: true },
  { name: "Referencia", order: "", collapse: true },
  { name: "Desaduanamiento", order: "", collapse: true },
  { name: "Serial", order: "", collapse: true },
  { name: "Subpartida", order: "", collapse: true },
  { name: "Descripción", order: "", collapse: true },
  { name: "Factura", order: "", collapse: true },
  { name: "Libre disposición tercero", order: "", collapse: true },
  { name: "Certificado", order: "", collapse: true },
  { name: "Ope.", order: "", collapse: true },
  { name: "Inic.", order: "", collapse: true },
  { name: "Acuerdo", order: "", collapse: true },
  { name: "Saldo.", order: "", collapse: true },
  { name: "Sdo. Aut. Salir.", order: "", collapse: true },
  { name: "Sdo. rsvd.", order: "", collapse: true },
  { name: "Sdo. en Temp.", order: "", collapse: true },
  { name: "Sdo. en Bodega", order: "", collapse: true },
  { name: "Unidad", order: "", collapse: true },
  { name: "Saldo Producto", order: "", collapse: true },
  { name: "Sdo. Producto Aut. Salir", order: "", collapse: true },
  { name: "Sdo. Producto rsvd", order: "", collapse: true },
  { name: "Sdo. Producto en Temp", order: "", collapse: true },
  { name: "Sdo. Producto en Bodega", order: "", collapse: true },
  { name: "Unidad Producto", order: "", collapse: true },
  { name: "Nat", order: "", collapse: true },
  { name: "Peso Neto Aut. Temp.", order: "", collapse: true },
  { name: "Cant. aut. Temp.", order: "", collapse: true },
  { name: "Cant.Producto aut. Temp. ", order: "", collapse: true },
  { name: "Peso Neto rsvd. Temp.", order: "", collapse: true },
  { name: "Exp/Prov", order: "", collapse: true },
  { name: "NIT Exp/Prov", order: "", collapse: true },
  { name: "Cant Total ", order: "", collapse: true },
  { name: "Cant Producto Total ", order: "", collapse: true },
  { name: "Precision", order: "", collapse: true },
  { name: "FOB", order: "", collapse: true },
  { name: "CIF", order: "", collapse: true },
  { name: "Peso Neto", order: "", collapse: true },
  { name: "FOB Temp.", order: "", collapse: true },
  { name: "Seguimiento", order: "", collapse: true },
  { name: "FOB rsvd. Temp.", order: "", collapse: true },
  { name: "CIF aut. Temp.", order: "", collapse: true },
  { name: "Total de ingreso", order: "", collapse: true },
  { name: "Total de ingreso producto", order: "", collapse: true }
];

export const theadInventoryAudit = [
  { name: "Producto", order: "" },
  { name: "Referencia", order: "" },
  { name: "Subpartida", order: "" },
  { name: "Importe de declaración", order: "" },
  { name: "Oper. Inicial", order: "" },
  { name: "Cód. Operación", order: "" },
  { name: "Cant. Sin aut. para salir", order: "" },
  { name: "Cant. aut. para salir", order: "" },
  { name: "Unidad Comercial", order: "" },
  { name: "Valor CIF", order: "" },
  { name: "Fecha Autorización", order: "" },
  { name: "EXP/PROV", order: "" }
];
