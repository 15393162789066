import { authTypes } from "redux/types";

export const updateToken = (payload) => {
  return {
    type: authTypes.renewToken,
    payload: {
      token: payload?.token,
      date_expires: payload?.date_expires,
      loginRefresh: payload?.loginRefresh
    }
  };
}

export const sessionExpired = () => {
  return {
    type: authTypes.sessionExpired
  };
}

export default updateToken;
