import { Tooltip } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { MdContentCopy, MdOutlineAssignmentReturn } from 'react-icons/md'

import { AuthContext } from 'context/AuthContext'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

export const ButtonCaducar = ({ handleCopy, handleDescaducar }) => {
  const { authUser } = useContext(AuthContext)

  const { permissionList } = useSelector(state => state.permissions)
  const { changeStatus: changeStatusEntryForm, copy: copyEntryForm } =
    localPermissions.Entryform

  return (
    <>
      {(hasCommonElements(permissionList, copyEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule
              className='me-1'
              onClick={handleCopy}
              bgColor='#03C39A'
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, changeStatusEntryForm) ||
          authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Descaducar'>
            <span>
              <ButtonHeaderModule bgColor='#F9A621' onClick={handleDescaducar}>
                <IconGeneric
                  className='text-white'
                  width='16px'
                  as={MdOutlineAssignmentReturn}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}
    </>
  )
}
