import { Box, Container } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { BoxCheck } from "../BoxCheck/BoxCheck";
import { Variables } from "../BoxVariable/Variables";
import { CharLine } from "../Chart/Chart";
import { StatWithIndicator } from "../StatWithIndicator/StatWithIndicator";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";

const IrrigationFields = [
  { label: "Nivel de Riesgo Alto", id: 0 },
  { label: "Nivel de Riesgo Medio", id: 1 },
  { label: "Nivel de Riesgo Leve", id: 2 }
];

export const Metrics = ({ setLevel, setListCriteria, Criteria = [] }) => {
  const [StatusIrrigationFields, setStatusIrrigationFields] = useState([]);
  const [StatusCriterionFields, setCriterionFields] = useState([]);
  const [CriterionFields, setCriterionFieldsList] = useState([]);

  const [arrayCriterion, setArrayCriterion] = useState([]);
  const [arrayRiskLevel, setArrayRiskLevel] = useState([]);

  const token = useSelector((state) => state.auth.dataUser.token);

  const GeneratorStatus = (Data, SetStates) => {
    const array = [];
    if (Data) {
      Data.map(({ id }) => {
        return array.push({ status: false, id });
      });

      return SetStates(array);
    }
  };

  useEffect(() => {
    const ListLevel = StatusCriterionFields.map(({ status, id }) => {
      if (status) {
        return id;
      } else {
        return null;
      }
    });

    const filteredArray = ListLevel.filter((value) => {
      return typeof value === "number" && !isNaN(value);
    });

    setListCriteria(filteredArray);
    setArrayCriterion(filteredArray);
  }, [StatusCriterionFields]);

  // Extract RiskLevel
  useEffect(() => {
    const ListLevel = StatusIrrigationFields.map(({ status, id }) => {
      if (status) {
        return id;
      } else {
        return null;
      }
    });

    const filteredArray = ListLevel.filter((value) => {
      return typeof value === "number" && !isNaN(value);
    });

    setLevel(filteredArray);
    setArrayRiskLevel(filteredArray);
  }, [StatusIrrigationFields]);

  // Read RiskLevel
  useEffect(() => {
    GeneratorStatus(IrrigationFields, setStatusIrrigationFields);
  }, []);

  // Read criteri
  useEffect(() => {
    if (CriterionFields.length > 0) { GeneratorStatus(CriterionFields, setCriterionFields); }
  }, [CriterionFields]);

  useEffect(() => {
    if (Criteria.length > 0 && CriterionFields.length <= 0) {
      Criteria.map(({ id, criteria_name }, index) => {
        setCriterionFieldsList((prevStatus) => [
          ...prevStatus,
          { id: index, label: criteria_name }
        ]);
      });
    }
  }, [Criteria]);

  // POST_GRAPHIC_MATRIX
  const [GraphicsData, setGraphicsData] = useState(null);

  const Graphics = async (inf) => {
    try {
      const res = await __Post(environment.POST_GRAPHIC_MATRIX, token, inf);
      const requestData = res;
      setGraphicsData(requestData?.data?.status?.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    Graphics({
      criteria_id: arrayCriterion.length > 0 ? arrayCriterion : null,
      risk_level: arrayRiskLevel.length > 0 ? arrayRiskLevel : null
    });
  }, [arrayCriterion, arrayRiskLevel]);
  return (
    <Container
      borderRadius={"10px"}
      mt={"20px"}
      shadow={
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
      }
      maxW="full"
      p="4"
    >
      <Variables
        StatusIrrigation={StatusIrrigationFields}
        StatusCriterion={StatusCriterionFields}
      />
      <StatWithIndicator />
      <CharLine GraphicsData={GraphicsData} />
      <Box display={"flex"}>
        <BoxCheck
          title="Niveles de Riesgo"
          Data={IrrigationFields}
          StatusCurrent={StatusIrrigationFields}
          setStatusCurrent={setStatusIrrigationFields}
        />
        {CriterionFields.length > 0 && (
          <BoxCheck
            title="Criterios"
            Data={CriterionFields}
            StatusCurrent={StatusCriterionFields}
            setStatusCurrent={setCriterionFields}
            size="full"
            h={40}
            overflow={"hidden"}
            overflowY={"scroll"}
          />
        )}
      </Box>
    </Container>
  );
};
