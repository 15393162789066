/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer
} from "@chakra-ui/react";
import PasswordField from "components/PasswordField/PasswordField";
import environment from "constants/apiConst";
import { Form, Formik } from "formik";
import { InputsContainer } from "pages/Home/Auth/Auth.styled";
import { Continuar, Cancelar } from "pages/RefProductInter/Stilos";

import { IconGeneric } from "styled/Icons.styled";
import * as Yup from "yup";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { RiLockPasswordFill } from "react-icons/ri";
import { __ChangePassword } from "request/GestionComercial/__GestionCo";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const ConfirnmPasswordChange = Yup.object().shape({
  password: Yup.string().required("¡Inserte la nueva contraseña!"),
  password_confirm: Yup.string()
    .required("¡Inserte nuevamente la contraseña!")
    .oneOf([Yup.ref("password"), null], "¡Las contraseñas deben coindicir!")
});

export const ChangePassword = ({ isOpen, onClose, informacion }) => {
  const [openAlert] = AlertErrorAlert();

  const { update, setUpdate } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialValues = {
    password: "",
    password_confirm: ""
  };

  const handleChange = async (values) => {
    const { password, password_confirm } = values;
    const dataPasssword = {
      ...values,
      id: informacion.id
    };
    console.log(dataPasssword);

    cambiandoPass(dataPasssword);
  };

  const cambiandoPass = async (data) => {
    try {
      const res = await __ChangePassword(
        environment.PASSWORD_CHANGE,
        token,
        data
      );
      if (res.data.status.code === 200) {
        openAlert("Cambio de contraña exitoso", "success");

        onClose();
        setUpdate(!update);
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Cambio de contraseña</ModalHeader>

          <Formik
            validationSchema={ConfirnmPasswordChange}
            initialValues={initialValues}
            onSubmit={handleChange}
          >
            {() => (
              <Form>
                <h1 className="d-flex justify-content-center">
                  Usuario: {informacion.nickname}
                </h1>
                <InputsContainer className="px-4">
                  <PasswordField
                    className="mx-2"
                    size="md"
                    name="password"
                    placeholder="Contraseña"
                  />
                  <PasswordField
                    className="mx-2"
                    size="md"
                    name="password_confirm"
                    placeholder="Repita su contraseña"
                  />
                </InputsContainer>
                <Flex className="m-3">
                  <Cancelar onClick={onClose}>Cancelar</Cancelar>
                  <Spacer />
                  <Continuar type="submit">
                    Aceptar &nbsp;
                    <IconGeneric color="#fff" as={RiLockPasswordFill} />
                  </Continuar>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};
