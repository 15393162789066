import TbodyDispatchCorrected from 'components/DispatchCorrections/Tcomponent/Tbody/TbodyDispatchCorrected'
import Pagination from 'components/Pagination/Pagination'
import TableOrderHover from 'components/Tables/TableOrderHover'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonStyled } from 'styled/Buttons.styled'
import { theadTablaDispacth } from '../View/ArrayHeadTable/TheadMovementInt'
import { SearchItemDispacth } from './SearchItemDispacth'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import { useMutation } from '@tanstack/react-query'
import GlobalContext from 'context/GlobalContext'

export const ItemSearchModal = ({ isOpen, onOpen, OnClose, mode, ...props }) => {
  const [page, setPage] = useState(1)
  const [itemData, setItemData] = useState([])
  const [persistentData, setPersistentData] = useState(null)
  const [showTableData, setShowTableData] = useState(false)
  const [openAlert] = AlertErrorAlert()

  const { requestData } = useContext(GlobalContext)

  const handleResponseItem = res => {
    if (res?.data?.status?.code === 400) { return openAlert(res?.data?.status?.message, 'error') }

    if (res?.data?.status?.code === 200) {
      setShowTableData(true)
      setItemData(res?.data?.status?.data?.data)
    }
  }

  const {
    isLoading: isLoadingItems,
    data: ItemsData,
    mutate: mutateItems
  } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: data => handleResponseItem(data)
  })

  const ItemSearch = data => {
    const sendDate = {
      item: data?.item,
      code: data?.code,
      date_start: data?.date_start,
      date_end: data?.date_end,
      reference: data?.reference,
      subheading: data?.subheading,
      description: data?.description,
      ...(props?.qualifiedUserId && { qualifiedUser_id: props?.qualifiedUserId })
    }
    setPersistentData(sendDate)

    mutateItems(
      {
        pageParam: page,
        resourceIdentifier: null,
        data: sendDate,
        Endpoint: environment.SEARCH_DISPACTH_FORM_EXIT,
        PropertyBody: 'sendJSONContentPOST'
      },
      {
        onSuccess: data => handleResponseItem(data)
      }
    )
  }

  useEffect(() => {
    if (page !== 1) ItemSearch(persistentData)
  }, [page])

  useEffect(() => {
    if (!showTableData) setPage(1)
  }, [showTableData])

  const ComponentSearch = ({ typeReturn = 'dispatch' }) => {
    if (typeReturn === 'dispatch') {
      return (
        <SearchItemDispacth
          onClose={OnClose}
          loading={isLoadingItems}
          handleSearch={ItemSearch}
          mode={mode}
        />
      )
    }
  }

  return (
    <ModalGeneric
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={OnClose}
      title='Buscar'
      size='6xl'
    >
      {showTableData
        ? (
          <div>
            <ButtonStyled onClick={() => setShowTableData(false)}>
            Buscar nuevamente
            </ButtonStyled>
            <TableOrderHover
              thead={theadTablaDispacth}
              data={itemData}
              setData={setItemData}
              loading={isLoadingItems}
              onCloseModal={OnClose}
              onClose={OnClose}
              tbodyData={TbodyDispatchCorrected}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={ItemsData?.data?.status?.data?.last_page || 1}
              loading={isLoadingItems}
            />
          </div>
        )
        : (
          <ComponentSearch />
        )}
    </ModalGeneric>
  )
}
