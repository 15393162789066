/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import environment from "constants/apiConst";
import { __GetDataNoPage } from "request/Petitions/__Get";

import { ExportPdf } from "components/Export/ExportPdf";
import { Bar } from "react-chartjs-2";
import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { arrayStatisticsSecurityVehicle } from "components/Estadistica/ArryList";
import { estadisticaOptions } from "../General/EstadisticaOptions";

export const CargoVehicle = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const ref = useRef(null);
  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    currentPage && setUserData(
      {
        labels:
          currentPage &&
          currentPage.map(
            ({ type_transport, particulars }) => type_transport || particulars
          ),
        datasets: [
          {
            label: ["Vehículo"],
            data:
              currentPage &&
              currentPage.map(({ amount, amount_p }) => amount || amount_p),
            backgroundColor: ["#006EB8", "#47B3E7", "#9CD8F7"]
          }
        ]
      }
    )

    /* console.log("currentPage: \n", currentPage);
    console.log("userData: \n", userData); */
  }, [currentPage])

  /*   const Obtener = useCallback(async () => {
      setLoading(true);
      try {
        const res = await __GetDataNoPage(
          environment.OBTENER_STATISTICS_VEHICLE,
          token
        );
        let data = res?.data?.status;
        console.log(data);
        if (data?.code === 200) {
          setCurrentPage(data.data);
          setLoading(false);

          setUserData({
            labels:
              data.data &&
              data.data.map(
                ({ type_transport, particulars }) => type_transport || particulars
              ),
            datasets: [
              {
                label: ["Vehículo"],
                data:
                  data.data &&
                  data.data.map(({ amount, amount_p }) => amount || amount_p),
                backgroundColor: ["#006EB8", "#47B3E7", "#9CD8F7"],
              },
            ],
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }, [token]);

    useEffect(() => {
      Obtener();
    }, [Obtener]); */

  const title = "Vehículo de carga y particulares";

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) {
      ExportPdf(
        "IdPdfFmmCorrectEntry",
        `Estadisticas_de_${title}_${dateToday}`
      );
    }
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);
  return (
    <>
      <HeaderEstadistica
        title={title}
        handlePDF={dowmLoadPdfImgae}
        enableQualifiedUser={false}
        excelAdvancePath={environment.POST_EXPORT_EXCEL_VEHICLE}
        arrayList={arrayStatisticsSecurityVehicle}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_VEHICLE}
        exportFilters={
          {
            date: true,
            qualified_users: true
          }
        }
        enableRendering={true}
      />
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div id="contenedorEstadistica">

            <div className="d-flex">
              <div id="contenedorResultado" className="px-2 py-2">
                {!loading && userData && <Bar data={userData} ref={ref} options={estadisticaOptions(
                  "Cantidad",
                  "Vehiculo",
                  "Total de vehiculos"
                )}/>}
              </div>
            </div>

            <ExportPdfGrafica
              id="IdPdfFmmCorrectEntry"
              rutaImage={rutaImage}
              thead={""}
              data={""}
              tbody={""}
              title={`Estadisticas de ${title}`}
              dateToday={dateToday}
            />

            {/* {details && (
              <div className="mt-2">
                <TableOrder
                  thead={theadEstadisticas}
                  data={data ? data?.statistics : []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyForm}
                  subTotals={data?.quantity_form ? data?.quantity_form : []}
                  titleTotal="Total"
                />
              </div>
            )} */}
          </div>
        </>
      )}
    </>
  );
};
