import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import { MenuTabs } from "components/Menu/MenuOrderProduct/MenuTabs";
import { MenuOtherCosts } from "components/Menu/MenuOrderProduct/MenuOtherCosts";
// import MenuPrincipal from "components/Menu/MenuOrderProduct/MenuPrincipal";

// import CustomsCrudContext from "context/CustomsCrudContext";

const TbodyOtherCosts = ({ data, iope }) => {
  return (
    <Tr>
       <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">{data?.name_cost}</Td>
      <Td className="text-center">{data?.total_us_other_cost}</Td>
      <Td isNumeric className="text-center">{data?.net_weight_cost}</Td>

      <Td className="text-center">
        <MenuOtherCosts data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyOtherCosts;
