import React, { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { AiFillWarning } from "react-icons/ai";

const ModalAdvertencia = ({
  isOpen,
  onClose,
  title,
  generar,
  titleBody,
  iconCustom = null,
  colorAdjust = null,
  colorScheme = null
}) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <div className="d-flex align-items-center">
              <IconGeneric
                className={`${
                  colorAdjust || "text-warning"
                }  me-2 mt-0`}
                color={colorAdjust}
                as={iconCustom || AiFillWarning}
              />

              {title}
            </div>
          </AlertDialogHeader>
          <AlertDialogBody>{titleBody}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {generar ? "Cancelar" : "Continuar"}
            </Button>
            {generar && (
              <Button
                colorScheme={colorScheme || "red"}
                onClick={generar}
                ml={3}
              >
                Confirmar
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ModalAdvertencia;
