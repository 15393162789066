import { Container, Text } from "@chakra-ui/react";
import { SvgNoFoundCat } from "./SvgNoFoundCat";
import "./404.css";
export const NoFound = () => {
  return (
    <Container color="black" p="26" centerContent>
      <SvgNoFoundCat width={480} height={480} />
      {/* <Text fontSize="2xl" fontWeight={"bold"}>
        Ruta No encontrada
      </Text> */}
    </Container>
  );
};
