import { Td, Tr } from '@chakra-ui/react'
import MenuDispatchCorrecton from 'components/Menu/MenuDispatchCorrecton'
import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

export const TbodyDispatchShowItem = ({ data }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { Action = '' } = queryString.parse(location.search)
  // reference_corrected
  const urlTemplates = {
    CERTIFICADO: `/ShowIntegrationBox?id=${data?.form_id}`,
    INGRESO: `/FormShow?FormEntry=${data?.form_id}`
  }

  const shouldRedirect = urlTemplates?.[data?.form_type]
  const redirectShow = () => {
    navigate(urlTemplates?.[data?.form_type])
  }
  return (
    <Tr>
      <Td className='text-center'>{data?.item_corrected}</Td>
      <Td className='text-center'>
        <NavLink
          to={`/FormShowExit?ExitNumber=${data?.outputFormCorrected_id}`}
          className={'text-center'}
        >
          <span className='formNumber'>
            {data?.code_outputForm_corrected ?? 'N/A'}
          </span>
        </NavLink>
      </Td>
      <Td className='text-center'>{data?.code_operation_corrected ?? 'N/A'}</Td>
      <Td className='text-center'>{data?.item_output_corrected ?? 'N/A'}</Td>
      <Td className='text-center'>
        {
          shouldRedirect ? (
            <NavLink
              target="_blank"
              to={urlTemplates?.[data?.form_type]}
              className="ms-2"
            >
              <span className="formNumber">{data?.reference_corrected ?? 'N/A'}</span>
            </NavLink>
          ) : (
            <span>{data?.reference_corrected ?? 'N/A'}</span>
          )
        }
      </Td>

      <Td className='text-center'>{data?.code_product_corrected ?? 'N/A'}</Td>

      <Td className='text-center'>
        {data?.description_product_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.quantity_go_out_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.quantity_dispatch_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.output_form_item_description_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.code_businessUnit_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.quantity_go_out_product_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.quantity_dispatch_product_corrected ?? 'N/A'}
      </Td>
      <Td className='text-center'>
        {data?.code_unit_measurement_corrected ?? 'N/A'}
      </Td>
      {Action === 'Edit' && (
        <Td className='text-center'>
          <MenuDispatchCorrecton
            data={data}
            StatusPropety={'status_dispatchPassCorrected'}
          />
        </Td>
      )}
    </Tr>
  )
}
