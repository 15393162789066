import React, { useContext } from "react";
import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { MdQrCodeScanner } from "react-icons/md";
import OrderProductionContext from "context/OrderProductionContext";
import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineFileSync } from "react-icons/ai";
import ModalQR from "components/modal/ModalQR";

export const BtnCheckFmm = ({ values, children }) => {
  // const { authUser } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { changeStatus } = useContext(OrderProductionContext);

  const handleChangeStatus = (status) => {
    const info = {
      id: values.production_order_id,
      status_ProductionOrderForm: status
    };
    changeStatus(info);
  };

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />

      <div className="row">
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Aprobar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleChangeStatus("AUTORIZADO")}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric className="m-1 text-white" as={BsCheck2Circle} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Rechazar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleChangeStatus("DEVUELTO")}
                  className="m-2"
                  bgColor="#d92525"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={AiOutlineFileSync}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
        {children}
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <span>
                <ButtonHeaderModule
                  onClick={onOpen}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};
