import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { __typesOfDocuments } from "request/configuration/__TypeDocuments";
import TbodyTypesOfDocument from "../tables/TbodyTypesOfDocument";
import FormTypeOfDocument from "../forms/FormTypeOfDocument";
import FormTypeDocument from "../FormShow/FormTypeDocument";

const formShowType = () => {
  return <FormTypeDocument />;
};

const formTypeDocument = () => {
  return <FormTypeOfDocument />;
};

export const TypeDocument = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Documento", order: "name_activity" },
    { name: "Descripción", order: "description" },
    { name: "Anexo", order: "annexe" },
    { name: "Descripción anexo", order: "annexe_description" },
    { name: "¿Activo?", order: "document_type_status" },
    { name: "Acciones", order: null }
  ];
  useEffect(() => {
    setFormEdit(formTypeDocument);
    setFormShow(formShowType);
    setRequestEdit(environment.TYPE_OF_DOCUMENTS_UPDATE);
    setRequestCreate(environment.TYPE_OF_DOCUMENTS_CREATE);
  }, [
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    update
  ]);

  const getDataDocuments = async () => {
    setLoading(true);
    try {
      const res = await __typesOfDocuments(token, page);
      setCurrentPage(res?.data?.status?.data?.data);
      setMaxPage(res?.data?.status?.data?.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataDocuments();
  }, []);

  const Search = async () => {
    const formData = new FormData();
    formData.append("search", q);
    setLoading(true);
    try {
      const res = await __typesOfDocuments(token, formData, page);
      setCurrentPage(res.data.status.data.data);
      setMaxPage(res.data.status.data.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Search();
  }, [page, q, update]);

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Tipo de Documentos" />
      <TableOrder
        thead={Title}
        data={currentPage || []}
        setData={setCurrentPage}
        loading={loading}
        tbodyData={TbodyTypesOfDocument}
      />
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        loading={loading}
      />
    </SectionContainerGene>
  );
};
