/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef
} from "react";
import { MdKeyboardArrowDown, MdRemoveRedEye } from "react-icons/md";
import {
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Button,
  Img,
  Icon,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  Accordion,
  AccordionPanel
  , useDisclosure
} from "@chakra-ui/react";
import bro from "../../../../src/assets/bro.png";
import { IconGeneric } from "styled/Icons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";

import {
  TheadShowItems,
  TheadShowUC,
  TheadSubheading,
  TheadTrackingItems,
  TheadTrackingOperations,
  cabeceraTableMonitoring
} from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TbodyOperationsMonitoring from "pages/Seguimiento/Table/TBodyOperationsMonitoring";
import TableOrder from "components/Tables/TableOrder";
import MonitoringContext from "context/MonitoringContext";
import { ThStyledBlue } from "components/Tables/Table.styled";
import TBodyTableItems from "./TBodyTableItems";
import TBodyShowItems from "./TBodyShowItems";
import TBodyUC from "./TBodyUC";
import TBodySubheadingTrackings from "./TBodySubheadingTrackings";
import { useModal } from "hooks/useModal";

import { NavLink } from "react-router-dom";
import { SubTitle, Title } from "styled/Title";
import Pagination from "components/Pagination/Pagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";

const bold = { fontWeight: "bold" };

const TableOperationsMonitoring = ({
  thead = [],
  tbodyData: TbodyData,
  data = [],
  setData,
  loading = false,
  isNum = true,
  imageNone,
  tbodyUnd: TbodyUnd,
  className,
  arreglo,
  getMonitoring,
  changePage,
  page,
  maxPage,
  setPage,
  nameModule = "Seguimiento",
  others,
  children,
  ...rest
}) => {
  const [update, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [load, setIsLoad] = useState([]);
  const [press, setPress] = useState(null);
  const [getTrackingId, setGetTrackingId] = useState({});
  const [formType, setFormType] = useState("");
  const [state, setState] = useState(false);
  const [attachmentShow, setAttachmentShow] = useState("");
  const [loadOperations, setLoadOperations] = useState(false);
  const [isOpenAttachment, onOpenAttachment, onCloseAttachment] = useModal();
  const { isOpenModalInfo, onOpenModalInfo, onCloseModalInfo } =
    useContext(MonitoringContext);

  const orderFirst = () => {
    const firstOrder = data.map((item) => item);
    setInitialData(firstOrder);
  };

  useEffect(() => {
    orderFirst();
  }, [data]);

  const orderData = (order) => {
    if (order !== press && order) {
      const newData = data.sort((a, b) =>
        a[order] > b[order] ? 1 : a[order] < b[order] ? -1 : 0
      );
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: newData }));
      } else {
        setData(newData);
      }
      setUpdate(!update);
      setPress(order);
    } else if (order) {
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: initialData }));
      } else {
        setData(initialData);
      }
      setUpdate(!update);
      setPress(null);
    }
  };

  useEffect(() => {
    onCloseModalInfo();
  }, [loadOperations]);

  const [isDataNull, setIsDataNull] = useState(false);

  useEffect(() => {
    setIsDataNull(data?.every((item) => item?.data?.length === 0));
  }, [data]);

  return (
    <div className="table-responsive">
      <ModalGeneric
        isOpen={isOpenModalInfo}
        onOpen={onOpenModalInfo}
        onClose={onCloseModalInfo}
        title={
          formType === 1 || formType === 2 || formType === 3
            ? "Items en seguimiento"
            : formType === 4
              ? "Usuarios calificados"
              : formType === 6
                ? "Subpartidas"
                : "Operaciones en seguimiento"
        }
      >
        <TableOrder
          thead={
            formType === 1 || formType === 2 || formType === 3
              ? TheadShowItems
              : formType === 4
                ? TheadShowUC
                : formType === 6
                  ? TheadSubheading
                  : TheadTrackingOperations
          }
          data={getTrackingId}
          loading={load}
          tbodyData={
            formType === 1 || formType === 2 || formType === 3
              ? TBodyShowItems
              : formType === 4
                ? TBodyUC
                : formType === 6
                  ? TBodySubheadingTrackings
                  : TbodyOperationsMonitoring
          }
          getMonitoring={getMonitoring}
          formType={formType}
          setLoadOperations={setLoadOperations}
        />
        {attachmentShow
          ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                colorScheme="blue"
                onClick={onOpenAttachment}
                style={{ marginRight: "20px" }}
              >
              Abrir adjunto
              </Button>
              <Button onClick={onCloseModalInfo}>Cerrar</Button>
            </div>
          )
          : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button onClick={onCloseModalInfo}>Cerrar</Button>
            </div>
          )}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAttachment}
        onOpen={onOpenAttachment}
        onClose={onCloseAttachment}
        title="Ver adjunto"
      >
        <div
          style={{
            display: "flex",
            padding: "10px 0",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          {attachmentShow?.includes(".pdf")
            ? (
              <embed
                src={attachmentShow}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )
            : (
              <>
                <Img src={attachmentShow} style={{ width: "80%" }} />
              </>
            )}
        </div>
        {/* <button  onClick={() => window.open(attachmentShow, '_blank')}>prueba</button> */}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={onCloseAttachment}>Cerrar</Button>
        </div>
      </ModalGeneric>
      {loading && <SpinnerComponent />}
      {!isDataNull && !loading
        ? (
          <Accordion allowToggle defaultIndex={[rest?.currentAccordionIndex]}>
            {cabeceraTableMonitoring.map((items, index) => {
              const currentData = data[index]?.data || [];

              return (
                <>
                  {currentData.length !== 0 && (
                    <AccordionItem key={index}>
                      <h2>
                        <AccordionButton onClick={() => rest?.currentAccordionIndex !== index ? changePage(index) : console.log({ page, index })}>
                          <Box flex="1" textAlign="left">
                            <Title size={"16px"}>{items}</Title>
                          </Box>
                          <AccordionIcon color="#1F7AC3" size={24} />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={1}>
                        {currentData
                          ? (
                            <>
                              {currentData?.length !== 0 && (
                                <>
                                  <div>
                                    <div className="table-responsive">
                                      <Table
                                        size="md"
                                        variant="simple"
                                        className="table table-striped table-sm "
                                        overflowX={true}
                                      >
                                        <Thead>
                                          <Tr>
                                            {thead[items].map((th, index) =>
                                              index > 1
                                                ? (
                                                  <ThStyledBlue
                                                    key={th?.name + "_" + index}
                                                    isNumeric={isNum}
                                                    onClick={(e) =>
                                                      arreglo &&
                                                orderData(th.order, th.isNumber)
                                                    }
                                                    cursor={th.order ? true : null}
                                                    id="alinear"
                                                    className="text-center"
                                                  >
                                                    <div className="d-flex justify-content-center align-items-center mr-4">
                                                      {th?.name}
                                                      <span>
                                                        {th.order && arreglo
                                                          ? (
                                                            <IconGeneric
                                                              as={MdKeyboardArrowDown}
                                                            />
                                                          )
                                                          : (
                                                            ""
                                                          )}
                                                      </span>
                                                    </div>
                                                  </ThStyledBlue>
                                                )
                                                : (
                                                  <ThStyledBlue
                                                    key={`${th?.name}_${index}`}
                                                    onClick={(e) =>
                                                      arreglo &&
                                                orderData(th.order, th.isNumber)
                                                    }
                                                    cursor={th.order ? true : null}
                                                    className="text-center"
                                                  >
                                                    <div className="d-flex justify-content-center align-items-center mr-4">
                                                      {th?.name}
                                                      <span>
                                                        {th.order && arreglo
                                                          ? (
                                                            <IconGeneric
                                                              as={MdKeyboardArrowDown}
                                                            />
                                                          )
                                                          : (
                                                            ""
                                                          )}
                                                      </span>
                                                    </div>
                                                  </ThStyledBlue>
                                                )
                                            )}
                                          </Tr>
                                        </Thead>
                                        <Tbody
                                          className={className || ""}
                                        >
                                          {currentData.length <= 0 && !loading
                                            ? (
                                              <Tr>
                                                {!imageNone && (
                                                  <Td colSpan={thead.length + 1}>
                                                    <div className="d-flex justify-content-center ml-6">
                                                      <img
                                                        style={{ width: `${20}%` }}
                                                        src={window.assetURL + bro}
                                                        alt="no hay formulario"
                                                      />
                                                    </div>
                                                    <h2 className="text-center my-2">
                                                Aún no hay registros.
                                                    </h2>
                                                  </Td>
                                                )}
                                              </Tr>
                                            )
                                            : (
                                              currentData.map((item, index) => (
                                                <TbodyData
                                                  key={`${index}--${index}`}
                                                  data={item}
                                                  setGetTrackingId={setGetTrackingId}
                                                  setState={setState}
                                                  getMonitoring={getMonitoring}
                                                  setFormType={setFormType}
                                                  setAttachmentShow={
                                                    setAttachmentShow
                                                  }
                                                  movement={items}
                                                />
                                              ))
                                            )}
                                        </Tbody>
                                      </Table>
                                    </div>
                                    <Pagination
                                      page={page}
                                      setPage={setPage}
                                      maxPage={maxPage}
                                      loading={loading}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )
                          : (
                            others && { children }
                          )}
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                </>
              );
            })}
          </Accordion>
        )
        : (
          !loading && (
            <>
              <div className="d-flex justify-content-center">
                <img
                  style={{ width: `${20}%` }}
                  src={window.assetURL + bro}
                  alt="no hay formulario"
                />
              </div>
              <SubTitle size={"15px"} className="text-center ">
                {`Aún no hay ${nameModule?.toLowerCase() || ''}(s) asociados a esta listas`}
              </SubTitle>
            </>
          )
        )}
    </div>
  );
};

export default TableOperationsMonitoring;

/*
  <>
    <div className="d-flex justify-content-center">
      <img
        style={{ width: `${20}%` }}
        src={window.assetURL + bro}
        alt="no hay formulario"
      />
    </div>
    <SubTitle size={"15px"} className="text-center ">
      {`Aún no hay ${
        nameModule && nameModule
      } ${items.toLowerCase()} asociados a esta listas`}
    </SubTitle>
  </>
*/
