// import { Button } from "../../../Pass-Form/stylePases";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { MdDelete, MdOutlineImage } from "react-icons/md";
import { RiUploadCloudFill } from "react-icons/ri";
import "../styleForm.css";
import { Tooltip, useDisclosure } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { Modalcustomizable } from "components/modal/ModalPass";
import ZoomImage from "components/DispatchCorrections/ZoomedImage";
import { isNumber, isString } from "utils/type-check-utils";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { FaEdit, FaRegEye } from "react-icons/fa";
import { useModal } from "hooks/useModal";
import { useForm } from "hooks/useForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { GroupInputUpload } from "pages/Entry_and_exit_forms/GroupInputUpload";

/**
 *  DocumentUploader  - Componente de input que permite al usuarios seleccionar el tipo de archivo que cargara.
 *
 * @param {Array} document Array de nombre de documentos .
 * @param {Function} handleFiles Funcion para capturar id del documento.
 * @param {Function} handleFilesInput Funcion para poder cargar los diferentes archivos al input.
 * @param {Function} handleDelete Funcion para poder eliminar los diferentes archivos del input.
 * @param {Function} handleDelete Funcion para poder eliminar los diferentes archivos del input.
 * @return {Component} Retorna componente Botones para carga de documentos requeridos
 */

const DocumentUploader = ({
  document: file,
  handleFiles,
  handleFilesInput,
  handleDelete,
  files,
  viewBtn = false,
  viewBtnDelete = true,
  loading,
  idFmm = 0,
  setAttachmentDescription = null,
  setAttachmentNumber = null,
  documentPreview = false,
  callBack = () => { },
  isLoading = false,
  accept = ".doc,.docx,image/png,image/*,.png,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel",
}) => {
  const idFiles =
    isNumber(file?.doc_corrected_req) && file?.doc_corrected_req === 1
      ? `document-${file?.id}_doc_corrected_req`
      : `document-${file?.id}`;

  const location = useLocation();
  const { pathname } = location;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [previewImage, setPreviewImage] = useState(null);
  const { setNameDocument } = useGlobalContext();

  const obtenerError = JSON.parse(
    localStorage.getItem(`errorAttadchment-${idFmm}`) || "[]"
  );

  const fileInput = document.getElementById(idFiles);

  const openImageView = (url, fileName = "") => {
    const getFileExtension = file?.name?.split(".").pop() || fileName?.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    if (fileExtension === "png" || fileExtension === "jpg") {
      return (
        <>
          <a
            href={url}
            id="downloadFile"
            onClick={(e) => {
              e.preventDefault();
              setPreviewImage(url);
              onOpen();
            }
            }
          >
            {files?.[idFiles]?.name}
          </a>
        </>
      )
    }

    return (<NavLink
      target="_blank"
      to={files[idFiles].downloadFile}
      className="ms-2"
    >
      <span className="formNumber">{files?.[idFiles]?.name}</span>
    </NavLink>)

  };
  const { IsTheFormLocked } = useContext(GlobalContext);

  const [isOpenUpload, onOpenUpload, onCloseUpload] = useModal();
  const firstRender = useRef(true);

  const [valuesEdit, handleInputEdit, resetEdit, setValuesEdit] = useForm({
    index: null,
    attachment_description: "",
    attachment_number: "",
  });

  const editAttachmentLocal = (index, nameDocument) => {
    setValuesEdit({
      index: files?.[index]?.originalIndex,
      // originalIndex: files[index]?.originalIndex,
      id: files?.[index]?.id,
      url: files?.[index]?.downloadFile || "",
      name: files?.[index]?.name || files?.[index]?.other_attachment_name,
      attachment_description: files?.[index]?.attachment_description || "",
      attachment_number: files?.[index]?.attachment_number || "",
      attachment_comment: files?.[index]?.attachment_comment || "",
      attachment_date: files?.[index]?.attachment_date || "",
      nameDocument,
      ...files?.[index],
    });

    onOpenUpload();
  };

  const updateArray = (index, newValue, arraySetter) => {
    arraySetter((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (valuesEdit?.index != null) {
      if (setAttachmentDescription) {
        updateArray(
          valuesEdit?.index,
          valuesEdit?.attachment_description,
          setAttachmentDescription
        );
      }

      if (setAttachmentNumber) {
        updateArray(
          valuesEdit?.index,
          valuesEdit?.attachment_number,
          setAttachmentNumber
        );
      }
    }
  }, [valuesEdit]);

  const nameDocument = valuesEdit?.nameDocument && valuesEdit?.nameDocument;
  const iconPreview = documentPreview ? FaRegEye : FaEdit;
  const textTooltipPreview = documentPreview
    ? `Ver ${nameDocument}`
    : `Editar ${nameDocument}`;

  const handleChangeFile = (e) => {
    setNameDocument(file?.name_document);
    handleFilesInput(e);
    return;
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={textTooltipPreview}
        size={"lg"}
      >
        <GroupInputUpload
          customHandleChange={{
            values: valuesEdit,
            handleInputChange: handleInputEdit,
            reset: resetEdit,
            setValues: setValuesEdit,
          }}
          onClose={onCloseUpload}
          mode="edit"
          preview={documentPreview}
          callBack={callBack}
        />
      </ModalGeneric>

      <Modalcustomizable
        title={"Previsualización "}
        isOpen={isOpen}
        onClose={onClose}
        rightButtonText={"Confirmar"}
        iconComponent={MdOutlineImage}
        hiddenButtons={true}
        size={"3xl"}
      >
        <ZoomImage src={previewImage} />
      </Modalcustomizable>

      <div className="row ">
        <div className="col-6 mt-2">
          <h2>{file?.name_document}</h2>
        </div>

        <div className="col-4 mt-2">
          {loading?.label &&
            loading?.name === idFiles &&
            (files[idFiles] === undefined || files[idFiles] === null) && (
              <SpinnerComponent />
            )}

          {(files[idFiles] === undefined || files[idFiles] === null) &&
            file?.doc_corrected_req === 1 && (
              <Tooltip placement="top" hasArrow label="Agregar adjunto">
                <span>
                  <ButtonHeaderModule
                    className={
                      obtenerError && obtenerError.includes(file?.id)
                        ? "border border-danger"
                        : ""
                    }
                    onClick={(e) =>
                      handleFiles(e, idFiles, file?.doc_corrected_req)
                    }
                    bgColor="#03C39A"
                    isLoading={isLoading}
                  >
                    <IconGeneric color="#fff" as={RiUploadCloudFill} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

          {(files[idFiles] === undefined || files[idFiles] === null) &&
            (file?.doc_corrected_req === undefined ||
              file?.doc_corrected_req === null) &&
            !loading?.label &&
            loading?.name !== idFiles &&
            (pathname === "/FormEntry" ||
              pathname === "/FormExit" ||
              viewBtn === true) && (
              <Tooltip placement="top" hasArrow label="Agregar adjunto">
                <span>
                  <ButtonHeaderModule
                    className={
                      obtenerError && obtenerError.includes(file?.id)
                        ? "border border-danger"
                        : ""
                    }
                    onClick={(e) => handleFiles(e, idFiles)}
                    bgColor="#03C39A"
                    isLoading={isLoading}
                  >
                    <IconGeneric color="#fff" as={RiUploadCloudFill} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

          {files?.[idFiles] !== undefined &&
            files?.[idFiles] !== null &&
            files?.[idFiles]?.from_server ? (
            <>
              {openImageView(files[idFiles].downloadFile, files[idFiles].name)}
              {/*  <span
                id="downloadFile"
                onClick={() =>
                  openImageView(files[idFiles].downloadFile, files[idFiles].name)
                }
              >
                {files?.[idFiles]?.name}
              </span> */}
            </>
          ) : (
            <span>{files?.[idFiles]?.name}</span>
          )}
        </div>

        {!IsTheFormLocked && (
          <div className="col-1 mt-2">
            {files[idFiles] !== undefined &&
              files[idFiles] !== null &&
              viewBtnDelete && (
                <>
                  <IconGeneric
                    onClick={() => {
                      if (fileInput) fileInput.value = "";
                      handleDelete(
                        idFiles,
                        document?.name_document,
                        {
                          ...files?.[idFiles],
                          doc_corrected_req: file?.doc_corrected_req,
                        },
                        "UploaderFile"
                      );
                    }}
                    className="text-danger"
                    as={MdDelete}
                  />
                </>
              )}

            {files?.[idFiles]?.from_server && (
              <IconGeneric
                onClick={() =>
                  editAttachmentLocal(idFiles, file?.name_document)
                }
                color="#1F7AC3"
                as={iconPreview}
              />
            )}
          </div>
        )}

        <input
          type="file"
          data-isCorrected={file?.doc_corrected_req}
          id={idFiles}
          hidden
          accept={accept}
          onChange={handleChangeFile}
          name={file?.name_document}
        // required
        />
      </div>
    </>
  );
};

export default DocumentUploader;