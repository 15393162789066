import React from "react";
import { BsTruck } from "react-icons/bs";
import { BiChevronRight } from "react-icons/bi";

import {
  Box,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton
} from "@chakra-ui/react";

import './styles.css'

const index = (props) => {
  const { children, title, descTitle } = props;
  return (
    <Accordion
      defaultIndex={[1]}
      allowMultiple
      className="containerAccordion"
      style={{ backgroundColor: "#f5f5f5", marginTop: 30 }}
    >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box>
              <div className="containerIcon">
                <BsTruck size={20} color="#fff" className="text-aling-center" />
              </div>
            </Box>
            <Box flex="1" textAlign="center" style={{ padding: 5 }}>
              <p className="title">{title}</p>
              {
                descTitle && (
                  <div className="containerTile">
                    <BiChevronRight size={45} color="#000" className="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel orci eget nulla
                      convallis eleifend ut et est. Quisque elit lacus, gravida ac quam in, vestibulum
                      consectetur massa. Vestibulum sollicitudin metus a sodales consequat. Suspendisse
                      porta lobortis leo posuere imperdiet.
                    </p>
                  </div>
                )
              }
            </Box>
            <div></div>
            <div className="containerIconDropDown">
              <AccordionIcon color="#fff" />
            </div>
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} style={{ marginLeft: 63 }}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default index
