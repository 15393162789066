/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal
} from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { RiEditBoxLine } from 'react-icons/ri'
import { InputWithLabel } from 'components/Input/InputFakeCustoms'
import { useForm } from 'hooks/useForm'
import { useState, useEffect } from 'react'
import {
  typeScheduleList,
  typeWeihing
} from 'pages/Weighing/TypeWighingList/TypeWighingList'
import InputSelect from 'components/InputSelect/InputSelect'
import { GetWeihingButton } from 'pages/Weighing/FormWeighingMachine.js/GetWeihing'
import { __PostForm } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useModal } from 'hooks/useModal'
import { MaxLength } from 'utils/MaxLength'

function PopoverEdit ({ data, Reload, setReload }) {
  const [typeSchedule, setTypeSchedule] = useState(null)
  const [type_weight, setTypeWeihingState] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isOpen, onOpen, onClose] = useModal()

  const token = useSelector(state => state.auth.dataUser.token)
  const location = useLocation()
  const { Operation = '' } = queryString.parse(location.search)
  // eslint-disable-next-line no-unused-vars
  const [openAlert] = AlertErrorAlert()
  const [values, handleInputChange, reset, setValues] = useForm({
    vehicle_plate: '',
    weight: '',
    tare_weight: '',
    shedule: '',
    gross_weight: '',
    automatic: null
  })

  const {
    id,

    weight,
    vehicle_plate,
    shedule,
    automatic,
    gross_weight,
    tare_weight
  } = values

  const Editar = async () => {
    const scaleId = localStorage.getItem('chanelWeighing')

    if (!scaleId) {
      return openAlert('Seleccione una báscula', 'error')
    }

    const formData = new FormData()
    formData.append('id', id)
    formData.append('pass', Operation === 'Entrada' ? 1 : 2)
    formData.append('weight', weight)
    formData.append('vehicle_plate', vehicle_plate)
    formData.append('shedule', typeSchedule?.value)
    formData.append('type_weight', type_weight?.value)
    formData.append('scale_id', scaleId)
    formData.append('type', automatic ? 'AUTOMATICO' : 'MANUAL')

    const FormPost = {
      id,
      scale_id: scaleId,
      pass: Operation === 'Entrada' ? 1 : 2,
      weight,
      vehicle_plate,
      shedule: typeSchedule?.value,
      type_weight: type_weight?.value
    }

    if (
      FormPost?.weight &&
      FormPost?.vehicle_plate &&
      FormPost?.shedule &&
      FormPost?.type_weight
    ) {
      try {
        const res = await __PostForm(
          environment.WEIGHING_SHOW_EDIT,
          token,
          formData
        )
        const info = res?.data?.status
        if (info.code === 200) {
          setReload(!Reload)
          openAlert("Tiquete editado correctamente", "success");
          onClose();
          // setDocument(info?.data?.attachments);
        } else {
          // openAlert("Aqui va el Error", "error");
        }
      } catch (error) {
        console.log(error)
      } finally {
        // setLoading(false);
      }
    } else {
      openAlert('Rellene todos los campos', 'error')
    }
  }

  useEffect(() => {
    if (data) {
      setValues(data)
    }
  }, [data])

  useEffect(() => {
    if (shedule) {
      setTypeSchedule({
        value: shedule,
        label: shedule
      })
    }
  }, [values])

  useEffect(() => {
    if (type_weight?.value === 'Bruto') {
      setValues({ ...values, weight: gross_weight })
    }

    if (type_weight?.value === 'Tara') {
      setValues({ ...values, weight: tare_weight })
    }
  }, [type_weight])

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} >
      <PopoverTrigger>
        <ButtonHeaderModule className='m-2' bgColor='#1F7AC3'>
          <IconGeneric className='m-1 text-white' as={RiEditBoxLine} />
        </ButtonHeaderModule>
      </PopoverTrigger>
      <Portal>
        <PopoverContent boxSize={'md'} h={'auto'}>
          <PopoverArrow />

          <PopoverHeader>Editar tiquete</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box className='row'>
              <Box className='col-md-6'>
                <InputWithLabel
                  p={1}
                  fontWeight={0}
                  _focus={{
                    outline: 'none'
                  }}
                  name={'vehicle_plate'}
                  value={vehicle_plate}
                  onChange={handleInputChange}
                  borderRadius={'none'}
                  labelText={'Placa'}
                  readOnly={false}
                />
              </Box>

              <Box className='col-md-6'>
                <InputSelect
                  name={'shedule'}
                  tag={'Horario'}
                  data={typeScheduleList}
                  set={setTypeSchedule}
                  value={typeSchedule}
                  menuPosition={'null'}
                />
              </Box>

              <Box className='col-md-6'>
                <InputSelect
                  tag={'Tipo de peso'}
                  data={typeWeihing}
                  set={setTypeWeihingState}
                  value={type_weight}
                  menuPosition={'null'}
                />
              </Box>

              <Box display={'flex'}>
                <InputWithLabel
                  p={1}
                  mr={3}
                  fontWeight={0}
                  _focus={{
                    outline: 'none'
                  }}
                  onKeyUp={() => {
                    setValues({ ...values, automatic: false })
                  }}
                  name={'weight'}
                  value={weight}
                  onChange={handleInputChange}
                  borderRadius={'none'}
                  labelText={'Peso'}
                  readOnly={automatic ?? false}
                />

                <GetWeihingButton
                  loading={loading}
                  setLoading={setLoading}
                  setValues={setValues}
                  values={values}
                  executeFunction={() => {
                    setValues({ ...values, automatic: true })
                  }}
                />
              </Box>

              <Box className='row'>
                <Button
                  p={2}
                  m={2}
                  colorScheme={'blue'}
                  w='full'
                  onClick={() => Editar()}
                >
                  Enviar
                </Button>
              </Box>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default PopoverEdit
