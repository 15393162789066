import { authorized, presented } from "utils/generalData";

/**
 *  Funcion para realizar mapeo de la data y crear  un nuevo array de obj con los keys y values solicitados
 * @function
 * @returns {Array} retorna array de objeto
 */

const filterDataEntry = (passesAuthorized, data) => {
  return passesAuthorized.map((dataMp) => ({
    driver: dataMp?.driver,
    id: dataMp?.entryPasses_id,
    vehicle_plate: dataMp?.vehicle_plate,
    identification: dataMp?.identification,
    description_security: dataMp?.observations,
    qualifiedUser_id: dataMp?.qualifiedUser_id,
    typeTransport_id: data[0]?.typeTransport_id,
    container_1: dataMp?.container_1 === null ? "" : dataMp?.container_1,
    container_2: dataMp?.container_2 === null ? "" : dataMp?.container_2,
    capacity_container_1:
      dataMp?.capacity_container_1 === null ? "" : dataMp?.capacity_container_1,
    capacity_container_2:
      dataMp?.capacity_container_2 === null ? "" : dataMp?.capacity_container_2
  }));
};

/**
 *  Funcion para realizar un filtro de determinados datos para pases autorizados en entrada
 * @function
 * @returns {Array} retorna array de datos
 */

const dataPassesAuthorized = (data) => {
  return data.filter(
    (passes) =>
      passes?.empty_pass === 1 ||
      passes?.free_pass === 1 ||
      passes?.status === "AUTORIZADO"
  );
};

/**
 *  Funcion para realizar un filtro de determinados datos para pases presentados en entrada
 * @function
 * @returns {Array} retorna array de datos
 */

const dataPassesPresented = (data) => {
  return data.filter(
    (passes) =>
      (passes?.status === "PRESENTADO" || passes?.status === "REVISADO") &&
      passes?.empty_pass === 0 &&
      passes?.free_pass === 0
  );
};

export { filterDataEntry, dataPassesPresented, dataPassesAuthorized };
