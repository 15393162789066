/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import { Box, Tooltip, SimpleGrid, Divider } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "context/AuthContext";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  LabelGroup,
  SwitchPropiosMedios,
} from "pages/RefProductInter/LabelGroup";
import { IconGeneric } from "styled/Icons.styled";
import { MdDelete, MdAdd } from "react-icons/md";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import PassCrudContext from "context/PassCrudContext";
import InputSelect, { TypeTranspor } from "components/InputSelect/InputSelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  __EditPhoto,
  __ShowPassInt,
  __TypeTransportNp,
} from "request/Pases/__CrudPass";
import environment from "constants/apiConst";
import { FormItemsForm } from "./Form/FormItemsForm";
import ModalTransition from "components/modal/ModalTransition";
import ModalDelete from "components/modal/ModalDelete";
import { AddItemsForm } from "./Form/AddItemsForm";
import { __ShowPassDispatch } from "request/DespachoAgrupado/__DispatchAg";
import { AddOut } from "./Form/AddOut";
import "./stilo.css";
import { useSelector, useDispatch } from "react-redux";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __GetBlockPersonOrPlate } from "request/Blocking/___BlockingCrud";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import TableOrderHover from "components/Tables/TableOrderHover";
import { ModalGeneric } from "components/modal/ModalGeneric";
import {
  TitleTableConductorId,
  TitleTableConductorPlate,
} from "./View/ArrayHeadTable/TheadMovementInt";
import TbodyConductores from "./tbody/TbodyConductores";
import TbodyCedula from "./tbody/TbodyCedula";
import { BsInfoCircle } from "react-icons/bs";
import Hover from "components/HoverInfo";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import {
  ListCapacidades,
  ListCapacidadesLimitadas,
} from "components/Profile/Form/DocumentList";
import useGetItemPass from "hooks/useGetItemPass";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { useDebounce, useDebounceState } from "hooks/useDebouce";
import { NumericFormat } from "react-number-format";
import Attachment from "components/DispatchCorrections/Attachment";
import { useFormBlocker } from "hooks/useFormBlocker";
import Swal from "sweetalert2";

import { clearFormData, setFormData } from "redux/actions/dataFormActions";
import { __Post } from "request/Petitions/__Post";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import useStatusListener from "hooks/useStatusListener";
import GlobalContext from "context/GlobalContext";
import { Regex } from "utils/Regex";
import { isValid } from "utils/type-check-utils";
import { ButtonSpecialMovement } from "./View/Show/VehiculoInfo";
import { GoDotFill } from "react-icons/go";
import { convertArrayToGroupedObject } from "pages/Entry_and_exit_forms/FormEntry/BodyForm";
import FileUploadManager from "pages/CustomsClearance/Adjunto/FileUploadManager";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";
import { parseFloat } from "utils/parseFloat";
const defaultState = {
  container: "",
  capacity_container: "",
  tare_container: "",
  // peso: "",
};

const ContainerFormRow = ({
  onChange,
  onRemove,
  containerName,
  capacity,
  tare,
  vacios = true,
  values,
}) => {
  const [containerData, setContainerData] = useState({
    label: capacity,
    value: capacity,
  });
  const location = useLocation();
  const { typeO = "" } = queryString.parse(location.search);
  const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );

  useEffect(() => {
    if (containerData?.value) {
      onChange("capacity_container", containerData?.value);
    }
  }, [containerData]);

  const handleContainerChange = (value) => {
    onChange("container", value);
  };

  const handleTareChange = (value) => {
    onChange("tare_container", value.toString()?.replaceAll(",", ""));
  };

  const isTareEditable = !!vacios;

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <form>
          <LabelGroup
            name={"container"}
            tag={`Contenedor`}
            size={100}
            value={containerName}
            onChange={(e) => handleContainerChange(e.target.value)}
            maxLength={MaxLength.entry_movement.form_int.container}
            required={true}
          />

          <InputSelect
            tag={"Capacidad"}
            data={ListCapacidades}
            set={setContainerData}
            value={containerData}
            required={true}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"tare_container"}
            tag={"Peso tara"}
            size={100}
            value={tare}
            onChange={(e) => handleTareChange(e.target.value)}
            desicion={false}
            required={!(typeO === "Dispatch" && isTareEditable)}
            maxLength={34}
            /* desicion={isTareEditable}
    required={!isTareEditable} */
          />
        </form>
      </SimpleGrid>
    </>
  );
};

const formEditD = () => {
  return <AddItemsForm />;
};

const formItem = () => {
  return <AddOut />;
};

export const FormuIntEdit = () => {
  const location = useLocation(); // navigate
  const {
    qualifiedUser = null,
    numberForm = "",
    id = "",
    typeO = "",
    qualifiedUserID = null,
  } = queryString.parse(location.search);
  const formID = id;
  const queryClient = useQueryClient();

  const {
    dataToEdit,
    onCloseModal,
    editData,
    setRequestEdit,
    formEdit,
    formShow,
    isOpenEdit,
    isOpenDelete,
    deleteData,
    onCloseShow,
    isOpenShow,
    setFormEdit,
    setRequestChangeStatus,
    setRequestDeleteAttachment,
    requestDeleteAttachment,
    setRequestDeletePhoto,
    setRequestAddItem,
    setRequestObtenerItem,
    setRequestObtenerItemPass,
    setRequestDelete,
    setIdQualifiedUser,
    isOpenOtherData,
    onOpenOtherData,
    onCloseOtherData,
    dataConductor,
    setDataConductor,
    currentItem,
    update,
    setUpdate,
    pageItem,
    page,
    patchData,
  } = useContext(PassCrudContext);

  const [loading, setLoading] = useState(false);
  const { authUser } = useContext(AuthContext);
  const [pageFormItem, setpageFormItem] = useState(1);
  const { balance } = useSelector((state) => state.status);

  const [statuse, setStatuse] = useState(0);
  const [manipuleo, setManipuleo] = useState(0);
  const [vacios, setVacios] = useState(0);
  const [medios, setMedios] = useState(0);
  const [specialMovement, setSpecialMovement] = useState(false); // estado para manejar la entrada o salida especial
  const [attached, setAttached] = useState([]);
  const [Generic, setGeneric] = useState([]);
  const [typeTranspor, setTypeTranspor] = useState([]);
  const [typeTransporSelec, setTypeTransporSelec] = useState(null);
  const [inicialValores, setInicialValores] = useState(0);
  const { token, is_commerce } = useSelector((state) => state.auth.dataUser);
  const [openAlert] = AlertErrorAlert();
  const [data, setData] = useState([]);
  const [TypeOfSearch, setTypeOfSearch] = useState(null);
  const [containers, setContainers] = useState([defaultState]);
  const [showContainers, setShowContainers] = useState(false);
  const [flagFiles, setFlagFiles] = useState(false);
  const [identification, setIdentification] = useState("");
  const dispatch = useDispatch();
  const { pathname } = location;

  const onFieldChange = (name, newValue) => {
    if (name === "identification") setIdentification(newValue);
  };

  const [vp, setVp] = useState("");
  const { values, handleInputChange, reset, setValues } = useForm({
    initialState: {
      vehicle_plate: "",
      identification: "",
      driver: "",
      number_packages: "",
      observations: "",
      handling_process: 0,
      phone: "",
    },
    onFieldChange,
    formKey: "myForm",
    returnType: "object",
  });

  useEffect(() => {
    return () => {
      setValues({
        vehicle_plate: "",
        identification: "",
        driver: "",
        number_packages: "",
        observations: "",
        handling_process: 0,
        phone: "",
      });
      setIdentification("");
      setFlagFiles(false);
      setShowContainers(false);
      setContainers([defaultState]);
      setData([]);
      setInicialValores(0);
      setTypeTransporSelec(null);
      setGeneric([]);
      setAttached([]);
      setSpecialMovement(false);
      setMedios(0);
      setVacios(0);
      setManipuleo(0);
      setStatuse(0);
    };
  }, [typeO, pathname]);

  useEffect(() => {
    if (typeO === "Dispatch") {
      setFormEdit(formItem);
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT_DISPACTH);
      setRequestDeletePhoto(environment.DELETE_PHOTO_DISPACTH);
      setRequestEdit(environment.UPDATE_DISPATCH);
      setRequestChangeStatus(environment.CHANGESTATUSDISPACTH);
      setRequestObtenerItem(environment.SEARCH_DISPACTH_FORM_EXIT);
      setRequestObtenerItemPass(environment.GET_ITEM_DISPACTH_EXIT);
      setRequestDelete(environment.DELETE_ITEM_DISPACTH_EXIT);
    } else {
      setFormEdit(formEditD);
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT);
      setRequestDeletePhoto(environment.DELETE_PHOTO);
      setRequestEdit(environment.UPDATE_PASES_INT);
      setRequestChangeStatus(environment.CHANGESTATUSPASS);
      setRequestAddItem(environment.ADD_ITEM_PASES_INT);
      setRequestObtenerItem(environment.OBTENER_ENTRADA_PASES_INT);
      setRequestObtenerItemPass(environment.OBTENER_ITEM_ENTRADA_PASES_INT);
      setRequestDelete(environment.DELETE_ITEM_ENTRADA_PASES_INT);
    }
    if (isValid(dataToEdit) || isValid(id)) {
      fetchShow();
    } else {
      setValues(values);
      if (isValid(id)) obtenerId();
      setContainers([]);
    }
    TypeTransporte();
  }, [typeO, id, dataToEdit, authUser]);

  const TypeTransporte = async () => {
    try {
      const res = await __TypeTransportNp(token);
      setTypeTranspor(res?.data?.status?.data);
    } catch (error) {}
  };
  const [initialSetup, setInitialSetup] = useState({});

  const { requestData } = useContext(GlobalContext);
  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponse = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      const resData = res?.data?.status?.data;
      dispatch(setFormData(resData));
      setIdQualifiedUser(resData?.qualifiedUser_id);
      const documentShippingForm = convertArrayToGroupedObject(
        resData?.shipping_form || [],
        "shipping_form"
      );

      setGeneric(documentShippingForm);

      const outputObject = convertArrayToGroupedObject(
        resData?.attachments || [],
        "attachments"
      );

      setAttached(outputObject);

      setFlagFiles(!resData?.attachments);
      setValues(resData);
      setSpecialMovement(resData?.special_for_lite); // sestear el estado de entrada o salida especial en el momento de cargar la información
      setTypeTransporSelec({
        label: resData?.name_typeTransport,
        value: resData?.typeTransport_id,
      });
      setContainers(resData?.containers);
      if (resData?.freely_available && statuse !== resData?.freely_available) {
        setStatuse(resData?.freely_available);
      }
      if (
        resData?.handling_process &&
        manipuleo !== resData?.handling_process
      ) {
        setManipuleo(resData?.handling_process);
      }
      if (resData?.empty_pass && vacios !== resData?.empty_pass) {
        setVacios(resData?.empty_pass);
      }
      if (resData?.own_means && medios !== resData?.own_means) {
        setMedios(resData?.own_means);
      }
      setInitialSetup({
        freely_available: resData?.freely_available,
        handling_process: resData?.handling_process,
        empty_pass: resData?.empty_pass,
        own_means: resData?.own_means,
      });
      setInicialValores(resData?.number_packages ?? 0);
      setValues((prev) => ({ ...prev, phone: resData?.phone }));
      setValues((prev) => ({
        ...prev,
        identification: resData?.identification || "",
      }));
    }
  };

  const [deleteFile, setDeleteFile] = useState(false);

  const {
    data: dataViewFiles,
    isFetching: isFetchingViewFiles,
    isSuccess: isSuccessViewFiles,
    isError: isErrorViewFiles,
    status: statusViewFiles,
  } = useQuery({
    queryKey: ["ViewFiles", formID, update],
    queryFn: () =>
      requestData({
        Endpoint:
          typeO === "Dispatch"
            ? environment.SHOW_DISPATCH
            : environment.SHOW_PASES_INT,
        resourceIdentifier: formID,
      }),
    enabled: isValid(formID),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const dataFormCurrent = dataViewFiles?.data?.status;

    if (
      isSuccessViewFiles &&
      !isFetchingViewFiles &&
      deleteFile &&
      isValid(formID)
    ) {
      filesRead(dataFormCurrent?.data, true);
      return;
    }

    if (isSuccessViewFiles && !isFetchingViewFiles && isValid(formID)) {
      const { data } = dataFormCurrent;
      filesRead(data);
    }
  }, [isFetchingViewFiles, statusViewFiles, dataViewFiles]);

  const filesRead = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const documentShippingForm = convertArrayToGroupedObject(
        data?.shipping_form || [],
        "shipping_form"
      );

      setGeneric(documentShippingForm);

      const outputObject = convertArrayToGroupedObject(
        data?.attachments || [],
        "attachments"
      );

      setAttached(outputObject);
      setDeleteFile(false);
      return;
    }

    const documentShippingForm = convertArrayToGroupedObject(
      data?.shipping_form || [],
      "shipping_form"
    );

    setGeneric(documentShippingForm);

    const outputObject = convertArrayToGroupedObject(
      data?.attachments || [],
      "attachments"
    );

    setAttached(outputObject);
  };

  const fetchShow = () => {
    const config = {
      resourceIdentifier: id,
      Endpoint:
        typeO === "Dispatch"
          ? environment.SHOW_DISPATCH
          : environment.SHOW_PASES_INT,
    };

    mutate(config, {
      onSuccess: (data) => handleResponse(data),
    });
  };
  const obtenerId = async () => {
    setLoading(true);
    try {
      const res =
        typeO === "Dispatch"
          ? await __ShowPassDispatch(token, id)
          : await __ShowPassInt(token, id);
      const respopnse = res.data.status.data;
      setIdQualifiedUser(respopnse.qualifiedUser_id);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (index, name, value) => {
    const copyRows = [...containers];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setContainers(copyRows);
  };

  const handleOnAdd = (e) => {
    e.preventDefault();
    setContainers(containers.concat(defaultState));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...containers];
    copyRows.splice(index, 1);
    setContainers(copyRows);
  };

  const [debouncedError, setError] = useDebounceState("", 500);

  useEffect(() => {
    if (!debouncedError) return;
    openAlert(debouncedError, "error");
    setTimeout(() => {
      setError((prev) => (prev = ""));
    }, 600);
  }, [debouncedError]);

  const searchChangePlate = (e) => {
    const resultado = e.target.value

      .replace(/[^a-zA-Z0-9,]/g, "")
      .replace(/,/g, "");

    if (e.target.name === "vehicle_plate" && resultado) {
      setValues((values) => ({
        ...values,
        vehicle_plate: resultado,
      }));
      setVp(e.target);
      getBlockPerson(resultado, "vehicle_plate");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (identification) {
        getBlockPerson(identification, "identification");
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [identification]);

  const getBlockPerson = async (info, type) => {
    try {
      const res = await __GetBlockPersonOrPlate(token, info);
      const data = res?.data?.status;
      if (data.code === 400) {
        data?.message?.veto
          ? openAlert(data?.message?.message, "error")
          : openAlert(data?.message, "error");

        if (data?.message?.veto && type === "vehicle_plate") {
          setValues((values) => ({
            ...values,
            vehicle_plate: "",
          }));
        }

        if (data?.message?.veto && type === "identification") {
          setValues((prev) => ({
            ...prev,
            identification: "",
          }));
        }
      }

      if (data.code === 200) {
        if (data?.data?.length === 1) {
          const currentIdentification =
            type !== "identification" ? values?.identification : info;

          const currentVehiclePlate =
            type !== "vehicle_plate" ? values?.vehicle_plate : info;

          if (
            values?.vehicle_plate &&
            values?.identification &&
            type === "identification"
          ) {
            setValues((prev) => ({
              ...prev,
              driver: data.data[0].name_driver,
              phone: data.data[0].phone,
            }));
          }

          if (!values?.identification) {
            setValues((prev) => ({
              ...prev,
              driver: data.data[0].name_driver,
              vehicle_plate: data.data[0].vehicle_plate,
              phone: data.data[0].phone,
              identification: data.data[0].identification,
            }));
            setTypeTransporSelec({
              label: data.data[0]?.name_typeTransport,
              value: data.data[0]?.typeTransport_id,
            });
          }

          if (!values?.vehicle_plate) {
            setValues((prev) => ({
              ...prev,
              driver: data.data[0].name_driver,
              vehicle_plate: data.data[0].vehicle_plate,
              phone: data.data[0].phone,
              identification: data.data[0].identification,
            }));
            setTypeTransporSelec({
              label: data.data[0]?.name_typeTransport,
              value: data.data[0]?.typeTransport_id,
            });
          }
        } else if (data.data.length > 1) {
          onOpenOtherData();
          setData(data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const idTypeTransporteWithContainer = [8, 9, 10, 12];

  const postFile = async (data) => {
    try {
      const res = await __EditPhoto(
        typeO === "Dispatch"
          ? environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY
          : environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
        token,
        data
      );
      if (res?.data?.status?.code === 200) {
        setUpdate((prev) => !prev);
        openAlert(res.data.status.message, "success");
        return res?.data?.status?.code;
      }

      const arrayData = data.getAll("attachment[]");
      res?.data?.status?.message?.map((item, index) => {
        return openAlert(
          `El archivo ${arrayData[index].name} debe ser un archivo de tipo: word, doc, docx, txt, pdf, png, jpg, gif, jpeg, bmp, xls, xlsm, xlsx.`,
          "error"
        );
      });
      return res?.data?.status?.code;
    } catch (e) {
    } finally {
    }
  };

  const uploadFiles = async () => {
    const Files = new FormData();
    Files.append("id", id);

    return await postFile(Files);
  };

  useEffect(() => {
    if (dataConductor) {
      setValues((values) => ({
        ...values,
        driver: dataConductor.name_driver,
        vehicle_plate: dataConductor.vehicle_plate,
      }));

      setValues((prev) => ({ ...prev, phone: dataConductor.phone }));
      setValues((prev) => ({
        ...prev,
        identification: dataConductor.identification || "",
      }));
      setTypeTransporSelec({
        label: dataConductor?.name_typeTransport,
        value: dataConductor?.typeTransport_id,
      });
    }
  }, [dataConductor, setValues]);

  useEffect(() => {
    if (medios === 1) {
      setTypeTransporSelec({
        value: 21,
        label: "OTROS",
      });
    } else if (medios === 0) {
      setTypeTransporSelec(null);
    }
  }, [medios]);

  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const {
    formActive,
    currentItem: currentItemPass,
    setCurrentItem,
    isLoading,
    maxPageItem: maxPageItemPass,
    setMaxPageItem,
    fetch: fetchItemPass,
  } = useGetItemPass({
    idPass: id,
    pageItem: pageFormItem,
    endpoint:
      typeO === "Dispatch"
        ? environment.GET_ITEM_DISPACTH_EXIT
        : environment.OBTENER_ITEM_ENTRADA_PASES_INT,
    orderBy,
  });

  const hanldeSubmit = async (e) => {
    e.preventDefault();
    if (attached?.length > 0 && flagFiles) {
      if ((await uploadFiles()) !== 200) return;
    }

    const totalCapacity = containers.reduce(
      (sum, container) => sum + container.capacity_container,
      0
    );
    if (totalCapacity > 45) {
      return openAlert("La capacidad total no puede ser mayor a 45", "warn");
    }

    if (statuse && !values?.observations) {
      return openAlert(
        "El pase en libre disposición debe tener una observación",
        "warn"
      );
    }
    if (currentItemPass?.length > 0) {
      if (vacios === 1) {
        return openAlert(
          "No puede haber items en pases de vehículo vacío",
          "warn"
        );
      }
      if (
        parseFloat(inicialValores ?? 0) === 0 ||
        inicialValores === "" ||
        !isValid(inicialValores)
      ) {
        return openAlert(
          "El número de bultos no puede ser 0 o estar vacío para pases con items asociados",
          "warn"
        );
      }
    }

    if (typeTransporSelec === null || typeTransporSelec.value === null) {
      return openAlert("Seleccione un tipo de transporte", "warn");
    }

    if (!values?.vehicle_plate && !medios) {
      return openAlert("La placa del vehiculo es requerida", "warn");
    }
    if (!values?.vehicle_plate && !medios) {
      return openAlert("La placa del vehículo es requerida", "error");
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("number_packages", parseFloat(inicialValores || 0));
    formData.append("empty_pass", vacios ?? "");
    formData.append("own_means", medios ?? "");
    formData.append("typeTransport_id", typeTransporSelec?.value ?? "");
    formData.append("handling_process", manipuleo ?? "");
    formData.append("freely_available", statuse ?? "");
    formData.append("identification", values?.identification || "");
    formData.append(
      "vehicle_plate",
      values?.vehicle_plate ? values?.vehicle_plate.toUpperCase() : ""
    );
    formData.append("driver", values?.driver ? values?.driver : "");
    formData.append(
      "observations",
      values?.observations ? values?.observations : ""
    );
    formData.append("phone", values?.phone || "");

    /*    typeO === "Dispatch"
      ? console.log("")
      : Generic.forEach((element) => {
        formData.append("shipping_form[]", element);
      }); */

    /*  formData.append("shipping_form[]", Generic);
     */

    showContainers &&
      containers.forEach((cont) => {
        formData.append("contenedores[]", JSON.stringify(cont));
      });

    formData.append("special_for_lite", specialMovement || ""); // se manda al backend el estado de la entrada o salida especial

    if (idTypeTransporteWithContainer.includes(typeTransporSelec.value)) {
      if (containers.length === 0 && showContainers) {
        return openAlert("El contenedor es requerido", "error");
      }
      if (
        (containers[0].container === "" || containers.length === 0) &&
        showContainers
      ) {
        return openAlert("El contenedor es requerido", "error");
      }
      if (
        (containers[0].capacity_container === "" || containers.length === 0) &&
        showContainers
      ) {
        return openAlert("La capacidad es requerida", "error");
      }

      if (vacios === 0 && typeO === "Dispatch") {
        if (
          (containers[0]?.tare_container === "" ||
            containers[1]?.tare_container === "" ||
            containers.length === 0) &&
          showContainers
        ) {
          return openAlert("El peso tara es requerido", "error");
        }
      }

      return editData(formData);
    }

    if (!vacios && statuse === 0 && inicialValores === "") {
      return openAlert("El numero de bultos es requerido", "error");
    }
    if (!values?.driver) {
      return openAlert("El nombre del conductor es requerido", "error");
    }
    if (!values?.identification) {
      return openAlert("La identificación del conductor es requerido", "error");
    }
    editData(formData);

    setDataConductor(null);
  };

  useEffect(() => {
    fetchItemPass();
  }, [update, pageItem, pageFormItem]);

  const isAuthorizationDeletedDocument =
    (values?.status_entryPass !== "BORRADOR" ||
      values?.status_dispatchPass !== "BORRADOR") &&
    is_commerce === 1;

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };
  const TRANSPORT_TYPES = [
    "CONTENEDOR",
    "CONTENEDOR (TRACTOMULA)",
    "CONTENEDOR (CAMION)",
    "ISOTANQUE (TRACTOMULA)",
  ];

  useEffect(() => {
    if (typeTransporSelec?.label) {
      const currentTransport = typeTransporSelec.label;
      const showContainers = TRANSPORT_TYPES.includes(currentTransport);
      setShowContainers(showContainers);
    }
  }, [typeTransporSelec]);

  const patchForUrl = {
    Dispatch: environment.UPDATE_DISPATCH,
    default: environment.UPDATE_PASES_INT,
  };

  const handleSetConfigPass = async (e, set) => {
    const {
      target: { name, value },
    } = e;
    const result = e.target.checked === true ? 1 : 0;
    set(result);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("show", 1);
    formData.append(`${name}`, result);
    await patchData(formData, patchForUrl[typeO] || patchForUrl.default);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (vacios) {
      setInicialValores(0);
      setManipuleo(0);
    }
  }, [vacios]);

  useEffect(() => {
    return async () => {
      const body = new FormData();
      body.append("form_id", values?.id || id);
      typeO === "Dispatch"
        ? body.append("type_of", "DESPACHO")
        : body.append("type_of", "ENTRADA");
      body.append("type", 0);
      const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      dispatch(clearFormData());
    };
  }, []);

  const navigate = useNavigate();

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: values?.id || id || "",
    type_of: typeO === "Dispatch" ? "DESPACHO" : "ENTRADA",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
    return () => {
      setStatuse(0);
      setManipuleo(0);
      setVacios(0);
      setMedios(0);
      setAttached([]);
      setGeneric([]);
      setTypeTranspor([]);
      setTypeTransporSelec(null);
      setInicialValores(0);
      setContainers([defaultState]);
      setData([]);
      setFlagFiles(false);
      setIdentification("");
      setValues({
        vehicle_plate: "",
        identification: "",
        driver: "",
        number_packages: "",
        observations: "",
        handling_process: 0,
        phone: "",
      });
    };
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  const navigateIfEligibleForUpdate = (
    flag,
    form_id,
    new_status,
    dataCurrent
  ) => {
    const shouldRefetch =
      (flag === "entry_pass" || flag === "dispatch_pass") &&
      (new_status !== "DEVUELTO" || new_status !== "BORRADOR") &&
      form_id === dataCurrent.id;

    if (shouldRefetch && typeO === "Dispatch") {
      navigate(
        `/CheckPassOutput?qualifiedUser=${dataCurrent?.name_qualified}&numberForm=${dataCurrent?.passForm_number}&id=${dataCurrent?.id}&status=${dataCurrent?.status_dispatchPass}&typeO=Dispatch`
      );
      return;
    }

    if (shouldRefetch && typeO !== "Dispatch") {
      navigate(
        `/CheckPassEntry?qualifiedUser=${dataCurrent.name_qualified}&numberForm=${dataCurrent.passForm_number}&id=${dataCurrent.id}&status=${dataCurrent.status_entryPass}&typeO=`
      );
      return;
    }
  };

  useStatusListener({
    id: values?.id || id,
    callBack: navigateIfEligibleForUpdate,
    dataCurrent: values,
  });

  const endpointAttachments = {
    Dispatch: {
      create: environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT_DISPACTH,
    },
    default: {
      create: environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT,
    },
  }[typeO || "default"];

  /* 
  console.log("queryString", qualifiedUser,qualifiedUserID );
   */

  const respCreatePass = (res) => {
    if (res?.data?.status?.code === 200) {
      const numberFormEntry = res?.data?.status?.data.entry_pass;
      const balancePassEntry = res?.data?.status?.data.balance;
      const numberFormDispatch = res?.data?.status?.data.dispatch_pass;
      const balanceDispatch = res?.data?.status?.data.balance;
      dispatch(setDataStatusBalance(!balance));
      if (isValid(balancePassEntry)) {
        localStorage.setItem("ValueBalance", JSON.stringify(balancePassEntry));
      }
      if (isValid(balanceDispatch)) {
        localStorage.setItem("ValueBalance", JSON.stringify(balanceDispatch));
      }

      if (numberFormDispatch?.id) {
        navigate(
          `/formInt?qualifiedUser=${
            numberFormDispatch?.name_qualified
          }&numberForm=${numberFormDispatch?.passForm_number}&id=${
            numberFormDispatch?.id
          }&status=${
            numberFormDispatch?.status_entryPass ||
            numberFormDispatch?.status_dispatchPass
          }&typeO=${"Dispatch"}`
        );
      }

      if (isValid(numberFormEntry?.id)) {
        navigate(
          `/formInt?qualifiedUser=${
            numberFormEntry?.name_qualified
          }&numberForm=${numberFormEntry?.passForm_number}&id=${
            numberFormEntry?.id
          }&status=${"BORRADOR"}&typeO=${"Entrada"}`
        );
      }
    }

    if (res?.data?.status?.code !== 200) {
      openAlert(res.data.status.message, "error");
    }
  };
  //qualifiedUserID

  const createPass = async () => {
    if (attached?.length > 0 && flagFiles) {
      if ((await uploadFiles()) !== 200) return;
    }

    const totalCapacity = containers.reduce(
      (sum, container) => sum + container.capacity_container,
      0
    );
    if (totalCapacity > 45) {
      return openAlert("La capacidad total no puede ser mayor a 45", "warn");
    }

    if (statuse && !values?.observations) {
      return openAlert(
        "El pase en libre disposición debe tener una observación",
        "warn"
      );
    }
    if (currentItemPass?.length > 0) {
      if (vacios === 1) {
        return openAlert(
          "No puede haber items en pases de vehículo vacío",
          "warn"
        );
      }
      if (
        parseFloat(inicialValores ?? 0) === 0 ||
        inicialValores === "" ||
        !isValid(inicialValores)
      ) {
        return openAlert(
          "El número de bultos no puede ser 0 o estar vacío para pases con items asociados",
          "warn"
        );
      }
    }

    if (typeTransporSelec === null || typeTransporSelec.value === null) {
      return openAlert("Seleccione un tipo de transporte", "warn");
    }

    if (!values?.vehicle_plate && !medios) {
      return openAlert("La placa del vehiculo es requerida", "warn");
    }

    if (!values?.vehicle_plate && !medios) {
      return openAlert("La placa del vehículo es requerida", "error");
    }

    const formData = new FormData();
    formData.append("qualifiedUser_id", parseFloat(qualifiedUserID));

    formData.append("empty_pass", vacios ?? "");
    formData.append("own_means", medios ?? "");
    formData.append("typeTransport_id", typeTransporSelec?.value ?? "");
    formData.append("handling_process", manipuleo ?? "");
    formData.append("freely_available", statuse ?? "");
    formData.append("identification", values?.identification || "");
    formData.append(
      "vehicle_plate",
      values?.vehicle_plate ? values?.vehicle_plate.toUpperCase() : ""
    );
    formData.append("driver", values?.driver ? values?.driver : "");
    formData.append(
      "observations",
      values?.observations ? values?.observations : ""
    );
    formData.append("phone", values?.phone || "");
    formData.append("number_packages", parseFloat(inicialValores || 0));
    showContainers &&
      containers.forEach((cont) => {
        formData.append("contenedores[]", JSON.stringify(cont));
      });

    formData.append("special_for_lite", specialMovement || ""); // se manda al backend el estado de la entrada o salida especial

    if (!vacios && statuse === 0 && inicialValores === "") {
      return openAlert("El numero de bultos es requerido", "error");
    }
    if (!values?.driver) {
      return openAlert("El nombre del conductor es requerido", "error");
    }
    if (!values?.identification) {
      return openAlert("La identificación del conductor es requerido", "error");
    }

    /*     setDataConductor(null); */

    const config = {
      data: formData,
      Endpoint:
        typeO === "Dispatch"
          ? environment.CREATE_DISPATCH
          : environment.CREATE_PASES_IN,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (e) => respCreatePass(e),
    });
  };

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      setAttached([]);
      setGeneric([]);
      queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });
      return;
    }
  };

  const cleanFile = (file, customEndpoint = null) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ?? file?.name_attachment
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint: customEndpoint
            ? customEndpoint
            : endpointAttachments?.delete,
          resourceIdentifier: file?.id,
          PropertyBody: "sendFormDataContentPOST",
        };

        mutate(requestData, {
          onSuccess: (res) => {
            openAlert(res?.data?.status?.message, "error");
            setAttached([]);
            setGeneric([]);
            queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });
          },
        });
      }
    });
  };

  const handleUploadComplete = (files) => {
    console.log("Archivos subidos:", files);
  };

  const getAuthToken = async () => {
    // Implementa la lógica para obtener el token de autenticación
    return token;
  };

  const formatPayload = (files) => {
    return {
      files: files,
      otherData: "algún otro dato",
    };
  };

  const customizePayload = async (payload) => {
    // Aquí puedes modificar o agregar información al payload
    /*  const additionalData = await fetchSomeAdditionalData();
    return {
      ...payload,
      additionalData,
      timestamp: new Date().toISOString()
    }; */
  };

  return (
    <>
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"4xl"}
        modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={"outside"}
      >
        {formEdit}
      </ModalTransition>

      {/* Modal a usar */}
      <ModalTransition
        isOpen={isOpenShow}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Ver información"}
        handleClick={onCloseShow}
        buttonMain={"Aceptar"}
      >
        {formShow}
      </ModalTransition>

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento`}
        onDelete={deleteData}
      ></ModalDelete>

      <ModalGeneric
        isOpen={isOpenOtherData}
        onOpen={onOpenOtherData}
        onClose={onCloseOtherData}
        title="Información"
        size="2xl"
      >
        <TableOrderHover
          thead={
            TypeOfSearch === "number"
              ? TitleTableConductorId
              : TitleTableConductorPlate
          }
          data={data}
          setData={setData}
          loading={loading}
          onCloseModal={onCloseOtherData}
          tbodyData={TypeOfSearch === "number" ? TbodyCedula : TbodyConductores}
        />
      </ModalGeneric>

      {/* <SectionHeader
        secTitle={
          typeO === "Dispatch"
            ? "Formulario de Despachos"
            : "Formulario de Entradas"
        }
      /> */}
      {loading && <SpinnerComponent />}

      {!loading && (
        <>
          <HeaderForm
            status={""}
            numberForm={numberForm}
            UserQualified={qualifiedUser}
            Modulo={`Edición de formulario de ${
              typeO === "Dispatch" ? " Despachos" : "Entradas"
            } de Mercancía en Zonas Francas`}
          />

          <HeaderSearch>
            {values?.reason_for_return && (
              <AlertReturnReason
                title={"Tipo de devolución:"}
                item={values?.typifications}
                description={
                  values?.reason_for_return && values?.reason_for_return
                }
                nickname={values?.nickname}
              />
            )}

            <form>
              <div className="mx-1">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {typeO === "Dispatch" ? (
                      ""
                    ) : (
                      <>
                        <SwitchPropiosMedios
                          name={"own_means"}
                          status={medios}
                          setStatus={setMedios}
                          tag={"Propios medios"}
                          size={15}
                          desicion={!!(vacios || statuse)}
                        />
                      </>
                    )}
                  </div>
                  {/**switch para entradas especiales */}
                  {typeO === "Dispatch" &&
                    (specialMovement === 1 || specialMovement === 0) && (
                      <div style={{}}>
                        <ButtonSpecialMovement
                          onClick={() =>
                            setSpecialMovement(specialMovement ? 0 : 1)
                          }
                          isClickable={true}
                        >
                          <span>
                            <GoDotFill
                              style={{
                                color: specialMovement ? "#00ff9c" : "#ff343c",
                              }}
                            />
                          </span>

                          <h3 style={{ fontWeight: "400", fontSize: "14px" }}>
                            Entrada por liviano{" "}
                            {specialMovement ? " Activa" : " Inactiva"}
                          </h3>
                        </ButtonSpecialMovement>
                      </div>
                    )}
                </div>
                <Divider
                  className="my-4"
                  style={{ width: `100%`, background: "#BABABA" }}
                />

                <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
                  <LabelGroup
                    name={"vehicle_plate"}
                    value={
                      values?.vehicle_plate
                        ? values?.vehicle_plate.toUpperCase()
                        : ""
                    }
                    tag={"Placa vehículo"}
                    onChange={(e) => handleRegex(e, Regex?.all)}
                    onBlur={searchChangePlate}
                    size={100}
                    desicion={!!medios}
                    tipo={"text"}
                    maxLength={MaxLength.entry_movement.form_int.vehicle_plate}
                  />

                  <LabelGroup
                    name={"identification"}
                    value={values?.identification}
                    onChange={(e) => handleRegex(e, Regex?.numeros)}
                    tag={"Cédula del conductor"}
                    size={100}
                    tipo={"text"}
                    maxLength={MaxLength.entry_movement.form_int.identification}
                  />
                </SimpleGrid>

                <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
                  <LabelGroup
                    name={"driver"}
                    value={values?.driver ? values?.driver : ""}
                    onChange={(e) => handleRegex(e, Regex?.letras)}
                    tag={"Conductor"}
                    size={100}
                    maxLength={MaxLength.entry_movement.form_int.driver}
                  />
                  <LabelGroup
                    name={"phone"}
                    value={values.phone}
                    onChange={(e) => handleRegex(e, Regex?.numeros)}
                    tag={"Número de teléfono"}
                    size={100}
                    maxLength={MaxLength.entry_movement.form_int.phone}
                  />
                </SimpleGrid>

                <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
                  <TypeTranspor
                    data={typeTranspor ?? []}
                    tag={"Tipo de transporte"}
                    size={100}
                    setMendigo={setTypeTransporSelec}
                    mendigo={typeTransporSelec}
                  />
                </SimpleGrid>

                {showContainers && containers && (
                  <div className="mt-5" id="marginIcon">
                    <Title size={"18px"} weight={600}>
                      Contenedores
                      {containers ? (
                        containers[1] ||
                        containers[0]?.capacity_container > 20 ? (
                          ""
                        ) : (
                          <Tooltip
                            placement="top"
                            hasArrow
                            label="Nuevo contenedor"
                          >
                            <span>
                              <IconGeneric
                                onClick={handleOnAdd}
                                className="ms-2"
                                as={MdAdd}
                              />
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        ""
                      )}
                      {containers[0] ? (
                        <Tooltip placement="top" hasArrow label="Limpiar">
                          <span>
                            <IconGeneric
                              onClick={() =>
                                handleOnRemove(containers[1] ? 1 : 0)
                              }
                              className="text-danger ms-2"
                              as={MdDelete}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Title>

                    <Box>
                      {containers.map((x, index) => (
                        <ContainerFormRow
                          capacity={x?.capacity_container}
                          containerName={x?.container}
                          tare={x?.tare_container}
                          onChange={(name, value) =>
                            handleOnChange(index, name, value)
                          }
                          onRemove={() => handleOnRemove(index)}
                          key={index}
                          vacios={vacios}
                          values={containers}
                        />
                      ))}
                    </Box>
                  </div>
                )}
                {!isValid(qualifiedUserID) && (
                  <div className="row mt-1">
                    {authUser.admin === 1 || authUser.is_commerce === 1 ? (
                      <div className="col col-sm-4 sol-md-4">
                        <SwitchPropiosMedios
                          name={"handling_process"}
                          status={manipuleo}
                          onChange={(e) => handleSetConfigPass(e, setManipuleo)}
                          tag={"Manipuleo"}
                          desicion={!!vacios}
                          // size={5}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-flex col col-sm-4 sol-md-4 justify-center align-items-center">
                      <>
                        <SwitchPropiosMedios
                          name={"freely_available"}
                          status={statuse}
                          onChange={(e) => handleSetConfigPass(e, setStatuse)}
                          tag={"Libre disposición"}
                          set={setInicialValores}
                          // size={65}
                          desicion={!!(vacios || currentItemPass?.length !== 0)}
                        />
                        {statuse === 1 && (
                          <div className="">
                            <Hover
                              title={
                                "Condiciones para Mercancía de Libre Disposición"
                              }
                              description={
                                "De conformidad con lo establecido en el articulo 398 Modificado Decr. 383 de 2007, art 1º: No constituyen exportación, la introducción a zona franca, proveniente del resto del territorio aduanero nacional de materiales de construcción, combustibles, alimentos, bebidas y elementos de aseo, para su consumo o utilización dentro de la zona, necesarios para el normal desarrollo de las actividades de los usuarios y que no constituye parte de su objeto social. Teniendo en cuenta lo anterior el usuario será responsable del pago de los impuestos a que haya lugar para garantizar que se encuentren en libre disposición."
                              }
                              icon={<BsInfoCircle />}
                            />
                          </div>
                        )}
                      </>
                    </div>

                    <div className="col col-sm-4 sol-md-4">
                      <SwitchPropiosMedios
                        name={"empty_pass"}
                        status={vacios}
                        onChange={(e) => handleSetConfigPass(e, setVacios)}
                        tag={"Vehículo vacío"}
                        set={setInicialValores}
                        desicion={
                          !!(statuse || medios || currentItemPass?.length !== 0)
                        }
                      />
                    </div>
                  </div>
                )}
                <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    customInput={LabelGroup}
                    name={"inicialValores"}
                    // defaultValue={}
                    value={inicialValores}
                    onChange={(e) => {
                      setInicialValores(e.target.value);
                    }}
                    tag={"Número de Bultos"}
                    size={100}
                    desicion={!!vacios}
                    maxLength={MaxLength.entry_movement.form_int.bultos}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    format={(value) => value.replace(/\./g, "")}
                  />
                  <LabelGroup
                    name={"observations"}
                    value={values?.observations ? values?.observations : ""}
                    onChange={handleInputChange}
                    tag={"Observaciones"}
                    size={100}
                    desicion={false}
                    maxLength={MaxLength.entry_movement.form_int.observations}
                  />
                </SimpleGrid>
              </div>
            </form>

            <div className="mt-5 mx-1">
              {!isValid(qualifiedUserID) && (
                <>
                  <FormItemsForm
                    freeD={statuse}
                    vehicle={vacios}
                    currentItemPass={currentItemPass}
                    setMaxPageItem={setMaxPageItem}
                    setCurrentItem={setCurrentItem}
                    isLoading={isLoading}
                    maxPageItemPass={maxPageItemPass}
                    pageFormItem={pageFormItem}
                    setpageFormItem={setpageFormItem}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />

                  <Box display={"grid"} gap={4}>
                    <FileUploadManager
                      onUploadComplete={handleUploadComplete}
                      vaporBaseUrl={environment.VAPOR_BASE_URL}
                      getAuthToken={getAuthToken}
                      formatPayload={formatPayload}
                      customizePayload={customizePayload}
                      title="Adjunto"
                      formID={formID}
                      postPropertyName="attachment"
                      endpointAttachments={{
                        create: endpointAttachments?.create,
                      }}
                      handleResponseUpdateForm={handleResponseUpdateForm}
                    />

                    <ExistingFiles
                      existingFiles={attached?.attachments}
                      //isLoading={isPendingViewPass}
                      handleDeleteFile={cleanFile}
                    />
                  </Box>

                  {typeO !== "Dispatch" && (
                    <Box display={"grid"} gap={4} pt={12}>
                      <FileUploadManager
                        onUploadComplete={handleUploadComplete}
                        vaporBaseUrl={environment.VAPOR_BASE_URL}
                        getAuthToken={getAuthToken}
                        formatPayload={formatPayload}
                        customizePayload={customizePayload}
                        title="Planilla de envío *"
                        formID={formID}
                        postPropertyName="shipping_form"
                        endpointAttachments={{
                          create: environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
                        }}
                        handleResponseUpdateForm={handleResponseUpdateForm}
                      />

                      <ExistingFiles
                        existingFiles={Generic?.shipping_form}
                        //  isLoading={isPendingViewPass}
                        handleDeleteFile={cleanFile}
                      />
                    </Box>
                  )}
                </>
              )}

              <div className="d-grid gap-2 d-md-flex justify-content-md-end ms-2">
                <ButtonStyled
                  onClick={
                    isValid(qualifiedUserID) &&
                    (typeO === "Dispatch" || typeO === "Entrada")
                      ? createPass
                      : hanldeSubmit
                  }
                  wd="140px"
                >
                  Guardar
                </ButtonStyled>
              </div>
            </div>
          </HeaderSearch>
        </>
      )}
    </>
  );
};
