import React from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { IconGeneric } from "styled/Icons.styled";
import { FaRegCheckCircle } from "react-icons/fa";
import { BiXCircle } from "react-icons/bi";

const TbodyHistoryFmm = ({ data }) => {
  const dataPresent = data?.users.filter(
    ({ status_entryForm }) => status_entryForm === "PRESENTADO"
  );
  const dataApproved = data?.users.filter(
    ({ status_entryForm }) => status_entryForm === "APROBADO"
  );
  const dataDecline = data?.users.filter(
    ({ status_entryForm }) => status_entryForm === "DEVUELTO"
  );
  const dataAutorizado = data?.users.filter(
    ({ status_entryForm }) => status_entryForm === "AUTORIZADO"
  );

  return (
    <Tr>
      <Td
        className="text-center"
        //   onClick={handleShow}
      >
        <NavLink to={`/FormShow?FormEntry=${data?.id}`} className="ms-2">
          <span className="formNumber">{data?.code_entryForm}</span>
        </NavLink>
      </Td>
      {/* <Td className="text-center">{data?.name_qualified}</Td> */}
      {/* <Td className="text-center">{data?.code_operation}</Td> */}
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              dataPresent && dataPresent?.length > 0
                ? `Por: ${dataPresent[0]?.person_name} Fecha: ${dataPresent[0]?.date_entryForm} ${dataPresent[0]?.time_entryForm} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={dataPresent && dataPresent?.length > 0 ? "green" : "red"}
                as={
                  dataPresent && dataPresent?.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              dataApproved && dataApproved?.length > 0
                ? `Por: ${dataApproved[0]?.person_name} Fecha: ${dataApproved[0]?.date_entryForm} ${dataApproved[0].time_entryForm} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={
                  dataApproved && dataApproved?.length > 0 ? "green" : "red"
                }
                as={
                  dataApproved && dataApproved?.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              dataDecline && dataDecline?.length > 0
                ? `Por: ${dataDecline[0].person_name} Fecha: ${dataDecline[0].date_entryForm} ${dataDecline[0].time_entryForm} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={dataDecline && dataDecline?.length > 0 ? "green" : "red"}
                as={
                  dataDecline && dataDecline?.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              data?.inspection === 1
                ? `Por: ${dataApproved[0]?.person_name} Fecha: ${dataApproved[0]?.date_entryForm} ${dataApproved[0]?.time_entryForm} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={data?.inspection === 1 ? "green" : "red"}
                as={data?.inspection === 1 ? FaRegCheckCircle : BiXCircle}
              />
            </span>
          </Tooltip>
        }
      </Td>
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              data?.passes && data?.passes?.length > 0
                ? `Estado: ${data?.passes[0]?.status_entryPass}`
                : null
            }
          >
            {/* Por: ${data?.passes[0].person_name} fecha: ${data?.passes[0].date_entryForm} ${data?.passes[0].time_entryForm} */}
            <span>
              <IconGeneric
                cursor={"default"}
                color={data?.passes && data?.passes?.length > 0 ? "green" : "red"}
                as={
                  data?.passes && data?.passes.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>
      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              data?.passes && data?.passes?.length > 0
                ? `Estado: ${data?.passes[0]?.weinghings[0]?.completeness} Fecha: ${data?.passes[0]?.weinghings[0]?.weight_date} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={
                  data?.passes &&
                  data?.passes?.length > 0 &&
                  data?.passes[0]?.weinghings &&
                  data?.passes[0]?.weinghings?.length > 0
                    ? "green"
                    : "red"
                }
                as={
                  data?.passes &&
                  data?.passes?.length > 0 &&
                  data?.passes[0]?.weinghings &&
                  data?.passes[0]?.weinghings.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>

      <Td className="text-center">
        {
          <Tooltip
            placement="top"
            hasArrow
            label={
              dataAutorizado && dataAutorizado.length > 0
                ? `Por: ${dataAutorizado[0]?.person_name} Fecha: ${dataAutorizado[0]?.date_entryForm} ${dataAutorizado[0].time_entryForm} `
                : null
            }
          >
            <span>
              <IconGeneric
                cursor={"default"}
                color={
                  dataAutorizado && dataAutorizado?.length > 0 ? "green" : "red"
                }
                as={
                  dataAutorizado && dataAutorizado?.length > 0
                    ? FaRegCheckCircle
                    : BiXCircle
                }
              />
            </span>
          </Tooltip>
        }
      </Td>
    </Tr>
  );
};

export default TbodyHistoryFmm;
