import { Box } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";

const FormPayOnLine = ({ onclose }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem"
        }}
      >
        <LabelGroup
          tipo={"Text"}
          name={""}
          tag={"Valor"}
          size={45}
          // value={name_city}
          // onChange={handleInputChange}
          desicion={false}
        />
        <LabelGroup
          tipo={"email"}
          name={""}
          tag={"Correo electrónico"}
          size={45}
          // value={abbreviation}
          // onChange={handleInputChange}
          desicion={false}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        onCli
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onclose}>Cerrar</ButtonStyled>
        <ButtonStyled>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};

export default FormPayOnLine;
