import React, { useState, useEffect, useCallback, useContext } from "react";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { __Post } from "../../../request/Petitions/__Post";
import environment from "constants/apiConst";

import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import InventoryControlContext from "context/InventoryControlContext";

import { useModal } from "hooks/useModal";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormNewFilter from "./FormNewFilter";

const FormSearchAudit = ({
  onClose,
  handleSearchSubmit,
  category,
  valueQualified = null,
  setValueQualified = () => { },
  nameQualified
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataUserQ, setdataUserQ] = useState([]);
  const [dataOperation, setDataOperation] = useState([]);
  const [dataTypeProduct, setDataTypeProduct] = useState([]);
  const [dataTypeOperation, setDataTypeOperation] = useState([]);
  const [valueTransfer, setValueTransfer] = useState(null);
  const [valueAscDesc, setValueAscDesc] = useState(null);
  const [dataDescription, setDataDescription] = useState([]);

  const {
    valueOperation,
    setValueOperation,
    valueTypeP,
    setValueTypeP,
    valueTypeO,
    setValueTypeO,
    valueDescription,
    setValueDescription,
    isOpenNewFilter,
    onOpenNewFilter,
    onCloseNewFilter
  } = useContext(InventoryControlContext);

  const dataTransfers = [
    { value: 1, label: "Si" },
    { value: 0, label: "No" }
  ];

  const dataAscOrDes = [
    { value: 1, label: "Ascendente" },
    { value: 2, label: "Descendente" }
  ];

  const [uc, setUc] = useState(valueQualified);


  const GetDataSeletUser = useCallback(
    async (body) => {
      try {
        const res = await __Post(
          environment.GET_INVENTORY_AUDIT_SELECTS,
          token,
          body
        );
        if (res?.data?.status.code === 200) {
          const dataToSelect = res?.data?.status?.data;/*  
          console.log("aqui esta es para los select", res?.data?.status?.data);
          */const operation = dataToSelect?.operation.map((item) => ({
            value: item.inventory_operation_code,
            label: item.inventory_operation_code
          }));

          const typeProduct = dataToSelect?.type_products.map((item) => ({
            value: item.type_product,
            label: item.type_product
          }));

          const typeOperation = dataToSelect?.type_operations.map((item) => ({
            value: item.type_operation_code,
            label: item.type_operation_code
          }));

          const typeDescription = dataToSelect?.article_type.map((item) => ({
            value: item.category,
            label: item.category
          }));
          setDataDescription(typeDescription);
          setDataTypeOperation(typeOperation);
          setDataOperation(operation);
          setDataTypeProduct(typeProduct);
        } else if (res?.data?.status?.data?.data.length === 0) {
          toast.error("No se han encontrado resultados");
          // setCurrentPage([]);
          // setMaxPage(1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  // const dataDelect = (data) => {
  //   if (data[1] && data[1].type_product) {
  //     data[1].type_product.forEach((item) => {
  //       setValueTypeP((prev) => {
  //         if (!Array.isArray(prev)) {
  //           prev = [];
  //         }
  //         return [...prev, { label: item.value, value: item.value }];
  //       });
  //     });
  //   }

  //   if (data[2] && data[2].type_operation) {
  //     data[2].type_operation.forEach((item) => {
  //       setValueTypeO((prev) => {
  //         if (!Array.isArray(prev)) {
  //           prev = [];
  //         }
  //         return [...prev, { label: item.value, value: item.value }];
  //       });
  //     });
  //   }
  // };

  useEffect(() => {
    if (uc) {
      const data = {
        qualified_user_id: uc?.value,
      };
      GetDataSeletUser(data);
    }
  }, [GetDataSeletUser, uc]);

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setdataUserQ(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getUserQualified();
  }, [getUserQualified]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const operations = [];
    const typeProducts = [];
    const typeOperations = [];
    const descriptions = [];

    if (valueOperation) {
      valueOperation.forEach(({ value }) => operations.push(value));
    }
    if (valueTypeP) {
      valueTypeP.forEach(({ value }) => typeProducts.push(value));
    }
    if (valueTypeO) {
      valueTypeO.forEach(({ value }) => typeOperations.push(value));
    }

    if (valueDescription) {
      valueDescription.forEach(({ value }) => descriptions.push(value));
    }
    const data = {
      qualifiedUser_id: uc !== null ? uc.value : "",

      order_by: "date",

      operation: valueOperation !== null ? operations : "",

      order_type:
        valueAscDesc !== null && valueAscDesc.value === 1 ? "ASC" : "DESC",

      type_product: valueTypeP !== null ? typeProducts : "",

      internal_transfer: valueTransfer !== null ? valueTransfer.value : "",

      category_description:
        valueDescription !== null ? valueDescription.value : "",
      type_operation: valueTypeO !== null ? typeOperations : ""
    };
    // console.log("DATA ENVIADA", data);
    handleSearchSubmit(data);
  };

  useEffect(() => {
    console.log({ valueQualified })
  }, [valueQualified])

  return (
    <>
      <ModalGeneric
        isOpen={isOpenNewFilter}
        onOpen={onOpenNewFilter}
        onClose={onCloseNewFilter}
        title="Agregar nuevo filtro"
      >
        <FormNewFilter onCloseNewFilter={onCloseNewFilter} />
      </ModalGeneric>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <div style={{ width: `45%` }}>
            <InputSelect
              tag={"Usuario calificado"}
              data={dataUserQ}
              value={uc}
              set={setUc}
              required
              isDisabled={!!valueQualified?.value}
            />
          </div>

          <div style={{ width: `45%` }}>
            <InputSelectMulti
              tag={"Operación"}
              data={dataOperation}
              value={valueOperation}
              set={setValueOperation}
              required
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <div style={{ width: `45%` }}>
            <InputSelect
              tag={"Ordenar por "}
              data={dataAscOrDes}
              value={valueAscDesc}
              set={setValueAscDesc}
            />
          </div>
          <div style={{ width: `45%` }}>
            <InputSelectMulti
              tag={"Descripción de categoría"}
              data={dataDescription}
              value={valueDescription}
              set={setValueDescription}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <div style={{ width: `45%` }}>
            <InputSelect
              tag={"Traslado"}
              data={dataTransfers}
              value={valueTransfer}
              set={setValueTransfer}
            />
          </div>

          <div style={{ width: `45%` }}>
            <InputSelectMulti
              tag={"Tipo de producto"}
              data={dataTypeProduct}
              value={valueTypeP}
              set={setValueTypeP}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <div style={{ width: `45%` }}>
            <InputSelectMulti
              tag={"Tipo de Operación"}
              data={dataTypeOperation}
              value={valueTypeO}
              set={setValueTypeO}
            />
          </div>

          <div className="spacing">
            <ButtonStyled bgColor={"#1F7AC3"} onClick={onOpenNewFilter}>
              Agregar nuevo filtro
            </ButtonStyled>
          </div>
        </div>

        <Flex className="mt-5">
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cerrar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            type="submit"
            isDisabled={
              !(uc !== null && valueOperation.length !== 0)
            }
          >
            Buscar
          </ButtonStyled>
        </Flex>
      </form>
    </>
  );
};

export default FormSearchAudit;
