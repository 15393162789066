/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { __ShowDataReturnReason } from "request/Pases/__CrudPass";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";
import { Regex } from "utils/Regex";

const FormTipificacion = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    name: "",
    descriptions: ""
  });

  const { name, descriptions } = values;
  const [openAlert] = AlertErrorAlert();

  const edit = async () => {
    try {
      const res = await __ShowDataReturnReason(token, dataToEdit.id);
      setValues(res.data?.status?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataToEdit) {
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (!name) return openAlert('Ingrese el nombre', 'error')
    if (!descriptions) return openAlert('Ingrese la descripción', 'error')
    if (dataToEdit) {
      return editData(values);
    }
    createData(values);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar tipificación " : "Crear tipificación"}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            type={"text"}
            name={"name"}
            tag={"Nombre"}
            size={45}
            value={name}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            desicion={false}
            maxLength={MaxLength.config.typificationReasonReturn.name}
          />
          <LabelGroup
            type={"text"}
            name={"descriptions"}
            tag={"Descripción"}
            size={45}
            value={descriptions}
            onChange={handleInputChange}
            desicion={false}
            maxLength={MaxLength.config.typificationReasonReturn.descriptions}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormTipificacion;
