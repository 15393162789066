import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GET_DASHBOARD_ITEMS } from 'DashBoard/graphql/queries'
import { useQuery } from '@apollo/client'
import { AiOutlinePlus } from 'react-icons/ai'
import Dashboard from 'DashBoard/Components/Dashboard'
import ChartRenderer from 'DashBoard/Components/ChartRenderer'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner
} from '@chakra-ui/react'
import DashboardItem from 'DashBoard/Components/DashboardItem'

import { ConfigMenuDashBoard } from 'DashBoard/Components/menu/ConfigMenu'
import { Storage } from 'hooks/useStorage'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { __Get } from 'request/Petitions/__Get'
import { DeleteLocalstore } from 'hooks/useLocalStorage'

const deserializeItem = i => ({
  ...i,
  layout: JSON.parse(i?.layout) || {},
  vizState: JSON.parse(i?.vizState)
})

const serializeItem = i => ({
  ...i,
  layout: JSON.stringify(i?.layout),
  vizState: JSON.stringify(i?.vizState)
})

const defaultLayout = i => ({
  x: i?.layout?.x || 0,
  y: i?.layout?.y || 0,
  w: i?.layout?.w || 4,
  h: i?.layout?.h || 8,
  minW: 4,
  minH: 8
})

const Comparar = (ArrayOne = [], ArrayTwo = [], properties = []) => {
  if (properties.length === 0) {
    /* console.log("No se especificaron propiedades para comparar"); */
    return false
  }

  // Verificar que ambos arrays tengan la misma longitud
  if (ArrayOne.length !== ArrayTwo.length) {
    /*  console.log("Los arrays tienen diferentes longitudes"); */
    return false
  }

  // Verificar que todas las propiedades especificadas sean iguales
  for (let i = 0; i < ArrayOne.length; i++) {
    for (let j = 0; j < properties.length; j++) {
      if (ArrayOne[i][properties[j]] !== ArrayTwo[i][properties[j]]) {
        /*  console.log(
          `La propiedad ${properties[j]} en el índice ${i} es diferente`
        ); */
        return false
      }
    }
  }

  // Si todas las verificaciones pasan, entonces las propiedades son iguales
  /* console.log(
    `Las propiedades ${properties.join(", ")} son iguales en ambos arrays`
  ); */
  return true
}

const DashboardPage = () => {
  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEMS)
  const [BackData, setBackData] = useState([])
  const { get, set } = Storage
  const [ActiveButton, setActiveButton] = useState(false)
  const { token } = useSelector(state => state.auth.dataUser)

  const GetConfig = async () => {
    try {
      const res = await __Get(environment.DASBHBOARD_GET_CONFIG, token)
      const data = res?.data?.status

      if (data?.code === 200) {
        setBackData(JSON.parse(data?.data?.data_json))
      }
      // setBackData(JSON.parse(data?.data?.data_json))
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  useEffect(() => {
    GetConfig()
  }, [])

  useEffect(() => {
    if (BackData && get('dashboardItems').length < 1) {
      set('dashboardItems', BackData && BackData?.map(serializeItem))
    }
  }, [BackData])

  const PostConfig = async data => {
    const Formate = data && data.map(deserializeItem)
    if (!Formate) return

    try {
      const resp = await __Post(environment.DASBHBOARD_POST_CONFIG, token, {
        data_json: Formate
      })
      if (resp.data.status.code === 200) {
        setActiveButton(!ActiveButton)
        DeleteLocalstore('dashboardItemsCopy')
      } else {
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data) {
      const { dashboardItems } = data
      const dashboardItemsCopy = get('dashboardItemsCopy') ?? []
      const ItemsCompare = Comparar(dashboardItems, dashboardItemsCopy, [
        'layout',
        'id'
      ])

      if (dashboardItemsCopy?.length < 1) {
        set('dashboardItemsCopy', dashboardItems)
      }

      setActiveButton(!ItemsCompare ?? false)
    }
  }, [data])

  if (loading) {
    return (
      <Box w='full' display={'flex'} justifyContent='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Box>
    )
  }
  if (error) {
    return (
      <>
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Ocurrió un error al cargar su consulta!</AlertTitle>
          <AlertDescription>{error.toString()}</AlertDescription>
        </Alert>
      </>
    )
  }
  const dashboardItem = item => (
    <div key={item.id} data-grid={defaultLayout(item)}>
      <DashboardItem key={item.id} itemId={item.id} title={item.name}>
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </div>
  )
  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12
      }}
    >
      <h2>No hay gráficos en este tablero</h2>
      <Link to='/explore'>
        <Button colorScheme='blue' size='md' rightIcon={<AiOutlinePlus />}>
          Agregar gráfico
        </Button>
      </Link>
    </div>
  )

  return !BackData ||
    BackData?.length ||
    !data ||
    data?.dashboardItems?.length
    ? (
      <>
        <ConfigMenuDashBoard />
        <Dashboard
          dashboardItems={
            BackData && data.dashboardItems < 1
              ? BackData.map(serializeItem)
              : data?.dashboardItems
          }
        >
          {BackData && data.dashboardItems < 1
            ? BackData.map(dashboardItem)
            : data.dashboardItems.map(deserializeItem).map(dashboardItem)}
        </Dashboard>{' '}
        {ActiveButton && (
          <Button onClick={() => PostConfig(data?.dashboardItems)}>
          Guardar
          </Button>
        )}
      </>
    )
    : (
      <Empty />
    )
}
export default DashboardPage
