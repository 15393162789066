import { useState, useEffect } from 'react'

/**
 * Hook que transforma opciones crudas en un formato con etiquetas y valores personalizados.
 *
 * @param {Array} rawOptions - Las opciones crudas que se van a transformar.
 * @param {string} labelProp - La propiedad de la cual se extraerá el valor de la etiqueta.
 * @param {string} valueProp - La propiedad de la cual se extraerá el valor.
 * @returns {Array} Las opciones transformadas con etiquetas y valores personalizados.
 */

const useTransformOptions = (
  rawOptions,
  labelProp,
  valueProp,
  descriptionProp = 'description_product',
  shortDecription = false
) => {
  const [transformedOptions, setTransformedOptions] = useState([])

  useEffect(() => {
    if (!Array.isArray(rawOptions)) {
      // Si rawOptions no es un array válido, retorna un array vacío
      setTransformedOptions([])
      return
    }
    /* ${ option?.[descriptionProp] ? ` - ${option?.[descriptionProp]?.slice(0, 10)}${ option?.[descriptionProp]?.length > 10 ? '...' : ''}` : ''} */
    const transformed = rawOptions.map(option => ({
      ...(!shortDecription && { label: `${option?.[labelProp]} ${option?.[descriptionProp] ? ` - ${option?.[descriptionProp]?.slice(0, 10)}${option?.[descriptionProp]?.length > 10 ? '...' : ''}` : ''}` }),
      ...(shortDecription && { label: `${option?.[labelProp]} ${option?.[descriptionProp] && ` - ${option?.[descriptionProp]}`}` }),

      value: option?.[valueProp],
      ...option
    }))

    setTransformedOptions(prev => transformed)
  }, [rawOptions, labelProp, valueProp])

  return transformedOptions
}

export default useTransformOptions
