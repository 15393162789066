import { BALANCE_TYPES } from "redux/types/stateBalancetypes";

const initialState = {
  balance: false
};

export const statusBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_TYPES.STATEBALANCE:
      return {
        ...state,
        balance: action.payload
      };

    default:
      return state;
  }
};
