import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext } from "react";

export const PDFUserToUser = ({ values }) => {
  const { opcional, name_qualified4 } = useContext(FormCrudCRUDContext);

  const { commercial_invoice_corrected, value_duta_corrected } = values;
  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Nit: </strong>
          {name_qualified4}
        </p>
        <p>
          <strong>Factura Comercial: </strong> {commercial_invoice_corrected}
        </p>
      </div>

      <div className="col">
        {opcional && (
          <p>
            <strong>{opcional}: </strong> {value_duta_corrected}
          </p>
        )}
      </div>
    </div>
  );
};
