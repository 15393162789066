import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import { CgArrowsExchangeAlt } from "react-icons/cg";
// import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { HrDividerNoMargin } from "styled/Line.styled";
import InventoryControlContext from "context/InventoryControlContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuInventorys = ({ data }) => {
  const { onOpenChange, setdataSubheanding, onOpenChangeKardex } = useContext(
    InventoryControlContext
  );

  const handleEdit = () => {
    setdataSubheanding(data?.inventory_id);
    onOpenChange();
  };

  const handleEditKar = () => {
    setdataSubheanding(data?.inventory_id);
    onOpenChangeKardex();
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={CgArrowsExchangeAlt} />
          Cambio arancel
        </MenuItemStyled>

        <HrDividerNoMargin />

        <MenuItemStyled py={3} onClick={handleEditKar}>
          <IconMenuRUD as={CgArrowsExchangeAlt} />
          Cambio kardex
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default MenuInventorys;
