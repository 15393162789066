import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "../LabelGroup";
import { Continuar, Cancelar } from "../Stilos";

import ProcessCrudContext from "context/ProcessCrudContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __Arancel } from "request/productoInterno/__ProductoInterno";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AuthContext } from "context/AuthContext";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";
import { useGlobalContext } from "context/GlobalContext";
import { isObjectNotEmpty } from "utils/type-check-utils";

export const AddProductProcess = () => {
  const location = useLocation();
  const { identificacion = null, id = null } = queryString.parse(
    location.search
  );
  const { setUser_Qualified } = useGlobalContext();
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [decimalUc, setDecimalUc] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const [formatQuantity] = useFormatQuantity();

  const [decimalPrecision, setDecimalPrecision] = useState(0);

  useEffect(() => {
    const isAdmin = authUser.admin === 1;
    const isCommerce = authUser.is_commerce === 1;

    if ((isAdmin || isCommerce) && decimalUc) {
      setDecimalPrecision(decimalUc);
    } else {
      setDecimalPrecision(Number(decimal_values_precision));
    }
  }, [authUser, decimalUc]);

  const [values, handleInputChange, reset, setValues] = useForm({
    quantity_product: "",
    quantity_for_tariff: "",
    description_product: "",
    convertion_factor_product: 0,
    tariff_unit_product: "",
    code_subheading: "",
    code_unit_measurement: ""
  });

  const {
    quantity_product,
    description_product,
    convertion_factor_product,
    code_unit_measurement,
    code_subheading
  } = values;

  const [unidadArancel, setUnidadArancel] = useState("");
  const [cantArnc, setCantArnc] = useState(0);

  const { dataToEdit, setDataToEdit, onCloseModal, createData, editData } =
    useContext(ProcessCrudContext);

  useEffect(() => {
    if (dataToEdit && isObjectNotEmpty(dataToEdit)) setValues(dataToEdit);
  }, [dataToEdit, setValues]);

  const arancel = useCallback(async () => {
    try {
      const res = await __Arancel(token, values?.code_subheading);
      setUnidadArancel(res?.data?.status?.data?.code_businessUnit);
    } catch (error) {}
  }, [token, values?.code_subheading]);

  useEffect(() => {
    if (identificacion) setUser_Qualified(identificacion);
  }, [identificacion]);

  useEffect(() => {
    if (values?.code_product) {
      arancel();
    }
  }, [values?.code_product, arancel]);

  const addProduc = () => {
    const ProductID = parseInt(values?.id);

    //  console.log("dataToEdit", editId, product_id, "Change Product", ProductID);
    const data = {
      qualifiedUser_id: identificacion,
      productiveProcess_id: id,
      product_id: dataToEdit?.product_id || ProductID,
      quantity_for_tariff: parseNumberWithCommas(cantArnc),
      quantity_product: parseNumberWithCommas(quantity_product),
      id: values?.item_id || dataToEdit?.id
    };

    if (dataToEdit && (values?.item_id || dataToEdit?.id)) {
      if (!values?.code_product) {
        openAlert("Elige un producto", "error");
      } else if (
        quantity_product === "" ||
        quantity_product === null ||
        quantity_product === undefined
      ) {
        openAlert("El campo cantidad producto es requerido", "error");
      } else {
        editData(data, reset);
      }
    } else {
      if (!values?.code_product) {
        openAlert("Elige un producto", "error");
      } else if (
        quantity_product === "" ||
        quantity_product === null ||
        quantity_product === undefined
      ) {
        openAlert("El campo cantidad producto es requerido", "error");
      } else {
        createData(data, reset);
      }
    }
  };
  useEffect(() => {
    if (
      typeof quantity_product !== "string" ||
      typeof convertion_factor_product !== "string"
    ) {
      return; // Evitar operaciones innecesarias si alguno de los valores no es una cadena de texto.
    }

    const cleanedQuantity = quantity_product
      .replaceAll(",", "")
      .replaceAll(".", "");
    const operationResult =
      parseFloat(cleanedQuantity) * parseFloat(convertion_factor_product);
    if (!isNaN(operationResult)) {
      const formattedResult = formatQuantity(decimalPrecision, operationResult);
      setCantArnc(formattedResult);
    }
  }, [quantity_product, convertion_factor_product]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    const posibleResult = e.target.value;

    setValues({
      ...values,
      [nameVariable]: formatQuantity(decimalPrecision, posibleResult)
    });
  };

  const handleSelectChange = (items, select) => {
    if (!select) {
      Object.keys(values).forEach((key) => {
        values[key] = "";
      });
      setCantArnc(0);
      return setUnidadArancel("");
    }
    const Element = select?.value || null;
    const findElemet = items.find((element) => element.id === Element);

    setValues({
      item_id: dataToEdit?.id,
      convertion_factor_product: parseInt(Element?.convertion_factor_product),
      ...findElemet
    });
    setDataToEdit({});
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit && isObjectNotEmpty(dataToEdit)
          ? " Editar ítem"
          : "Agregar ítem"}
      </Text>
      <Divider mb={3} />
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <ProductSearchBar
            size={45}
            label="Código producto"
            required={true}
            placeHolder="Seleccionar"
            manualId={identificacion}
            value={{ label: values?.code_product, value: values?.id }}
            callBack={handleSelectChange}
            // disabled={dataToEdit ? true : false}
          />

          <LabelGroup
            name={"description_product"}
            value={description_product || ""}
            tag={"Descripción"}
            onChange={handleInputChange}
            size={45}
            desicion={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            name={"convertion_factor_product"}
            value={convertion_factor_product || ""}
            tag={"Factor de conversión"}
            size={45}
            desicion={true}
          />
          <LabelGroup
            name={"code_unit_measurement"}
            value={code_unit_measurement || ""}
            tag={"Unidad de producto"}
            size={45}
            desicion={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            name={"code_subheading"}
            value={code_subheading || ""}
            tag={"Arancel"}
            size={45}
            desicion={true}
          />{" "}
          <LabelGroup
            name={"unidadArancel"}
            value={unidadArancel}
            tag={"Unidad de arancel"}
            size={45}
            desicion={true}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            type="text"
            tag={"Cantidad producto"}
            size={45}
            onChange={(e) => FormatoCantidadMexico(e, setValues, values)}
            value={quantity_product || ""}
            onBlur={handleBlurCant}
            name={"quantity_product"}
          />
          <LabelGroup
            tag={"Cantidad por arancel "}
            size={45}
            value={cantArnc || ""}
            desicion={true}
          />
        </div>
      </form>
      <Flex>
        <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
        <Spacer />
        <Continuar onClick={addProduc}>
          {dataToEdit && isObjectNotEmpty(dataToEdit)
            ? " Editar ítem"
            : "Agregar ítem"}
        </Continuar>
      </Flex>
    </>
  );
};
