/* eslint-disable no-unused-vars */
import { useModal } from "hooks/useModal";
import {
  __AddItemPass,
  __AddItemPassDispacth,
  __ChangeStatus,
  __CopyItemDispacth,
  __DeleteAttachment,
  __DeleteAttachmentPost,
  __DeleteItems,
  __DeletePhoto,
  __DeletePhotoPOST,
  __EditCreatePass,
  __EditItemPassDispacth,
  __EditPhoto,
  __ObtenerItems,
} from "../request/Pases/__CrudPass";
import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDisclosure } from "@chakra-ui/react";
const { createContext, useState, useCallback } = require("react");

const PassCrudContext = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */
export const PassCrudProvider = ({ children }) => {
  const [isOpenSearchItem, onOpenSearchItem, onCloseSearchItem] = useModal();
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal();
  const [isOpenCopy, onOpenCopy, onCloseCopy] = useModal();
  const {
    isOpen: IsOpenPopover,
    onToggle: onTogglePopover,
    onClose: onClosePopover,
  } = useDisclosure();
  const [isOpenReturnReason, onOpenReturnReason, onCloseReturnReason] =
    useModal();
  const [isOpenAsoccietUnit, onOpenAsoccietUnit, onCloseAsoccietUnit] =
    useModal();
  const [isOpenOtherData, onOpenOtherData, onCloseOtherData] = useModal();

  const [ReturnReason, setReturnReason] = useState(null);
  const [dataConductor, setDataConductor] = useState(null);
  const [isLoadingChange, setIsLoadingChange] = useState(false)

  const [openAlert] = AlertErrorAlert();
  const [dataToCopy, setDataToCopy] = useState(null);
  const [weightStatus, setWeightStatus] = useState(0);
  const [weightPresent, setWeightPresent] = useState(0);
  const [messageModalAsocciet, setmessageModalAsocciet] = useState(null);
  const [dataAsocciet, setdataAsocciet] = useState(null);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestPhoto, setRequestPhoto] = useState(null);
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null); //
  const [requestChangeStatus, setRequestChangeStatus] = useState(null);
  const [requestDeleteAttachment, setRequestDeleteAttachment] = useState(null);
  const [requestDeletePhoto, setRequestDeletePhoto] = useState(null);
  const [requestAddItem, setRequestAddItem] = useState(null);
  const [requestObtenerItem, setRequestObtenerItem] = useState(null);
  const [requestObtenerItemPass, setRequestObtenerItemPass] = useState(null);
  const [requestBulkLoad, setRequestBulkLoad] = useState(null);
  const [dataItemDispachSelect, setDataItemDispachSelect] = useState(null);
  const [idQualifiedUser, setIdQualifiedUser] = useState(null);
  const [editItemDispatch, seteditItemDispatch] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [maxPageItem, setMaxPageItem] = useState(1);
  const [showFormDispacthItem, setShowFormDispacthItem] = useState(false);
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [formLicensias, setFormLicensias] = useState(null);
  const [update, setUpdate] = useState(true);
  const [CountTicket, setCountTicket] = useState(null);
  const [isLoading, onLoading, noLoading] = useModal();
  const [cargando, setCargando] = useState(false);
  const [pageItem, setPageItem] = useState(1);
  const [page, setPage] = useState(1);
  const [successfulStateChange, setSuccessfulStateChange] = useState(false);
  const [itemsLinkArray, setItemsLinkArray] = useState([]);
  const [
    isOpenModalForSelectingItems,
    onOpenModalForSelectingItems,
    onCloseModalForSelectingItems,
  ] = useModal();

  const navigate = useNavigate();
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [alreadySailed, setAlreadySailed] = useState(false);

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const addPhotography = async (data, Update = 0, switchStatu = null) => {
    try {
      const res = await __EditCreatePass(requestEdit, token, data);
      const informacion = res.data.status.data;
      if (res?.data?.status?.code === 200) {
        setDataToEdit(null);
        openAlert(res.data.status.message, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) { }
  };

  const editData = async (data, Update = 0, switchStatu = null) => {
    try {
      const res = await __EditCreatePass(requestEdit, token, data);
      const informacion = res.data.status.data;
      if (res?.data?.status?.code === 200) {
        setDataToEdit(null);
        openAlert(res.data.status.message, "success");
        setUpdate(!update);
        setDataToShow(informacion);
        onClosePopover();

        if (res?.data?.status?.data?.weighings !== null) {
          setWeightPresent(2);
        }

        if (switchStatu) {
          changeStatus(switchStatu);
        }

        if (Update === 0) {
          navigate(
            `/pases?numberForm=${informacion?.passForm_number}&id=${informacion.id
            }&status=${informacion.status_entryPass || informacion.status_dispatchPass
            }&typeO=${informacion.status_dispatchPass ? "Dispatch" : ""}`
          );
          setUpdate(!update);
        }
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) { }
  };

  const patchData = async (data, endpoint) => {
    const url = endpoint || requestEdit;
    try {
      const res = await __EditCreatePass(url, token, data);
      const informacion = res?.data?.status;
      if (informacion?.code === 200) {
        console.log(informacion?.message);
        return true;
      } else {
        console.log(informacion?.message);
        return false;
      }
    } catch (error) { }
  };

  const editPhoto = async (data) => {
    const res = await __EditPhoto(requestPhoto, token, data);
    if (res?.data?.status?.code === 200) {
      openAlert(res.data.status.message, "success");
      setUpdate((prev) => !prev);
    } else {
      const arrayData = data.getAll("photography[]");
      console.log(arrayData);
      res?.data?.status?.message?.map((item, index) => {
        return openAlert(
          `El archivo ${arrayData[index]?.name} debe ser un archivo de tipo: word, doc, docx, txt, pdf, png, jpg, gif, jpeg, bmp, xls, xlsm, xlsx.`,
          "error"
        );
      });
    }
  };

  const createData = async (data) => {
    try {
      const res = await __EditCreatePass(requestCreate, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(res.data.status.message, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttachmentPass = async (data, id) => {
    try {
      const res = await __DeleteAttachmentPost(
        requestDeleteAttachment,
        token,
        data,
        id
      );
      if (res.data.status.code === 403) {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttachment = async (data, id) => {
    try {
      const res = await __DeleteAttachment(
        requestDeleteAttachment,
        token,
        data,
        id
      );
      if (res.data.status.code === 403) {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePhoto = async (data, id) => {
    try {
      const res = await __DeletePhotoPOST(requestDeletePhoto, token, data, id);
      if (res.data.status.code === 403) {
        setUpdate(!update);
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [weightMessage, setWeightMessage] = useState(null);
  const [movementSpecial, setMovementSpecial] = useState(null); // estado para manejar la variable "special" especialmente en el componente BtnHeaderShow
  const changeStatus = async (
    data,
    callBack = () => { },
    endPointManual = null
  ) => {
    try {
    setIsLoadingChange(true)
      const res = await __ChangeStatus(
        endPointManual || requestChangeStatus,
        token,
        data
      );

      const respuesta = res?.data?.status;
      const numeroFormulario = respuesta?.data?.passForm_number;


      const newState = {
        "BORRADOR": "recuperado",
        "PRESENTADO": "presentado",
        "AUTORIZADO": "autorizado",
        "DEVUELTO": "devuelto",
        "REVISADO": "movido a revisión",
      }[data?.status_entryPass || data?.status_dispatchPass] || "eliminado";


      if (respuesta?.format_srt) {
        callBack();
        const data = respuesta?.format_srt;
        setItemsLinkArray(data);
      }

      if (respuesta?.code === 200) {
        setUpdate(!update);
        const message = `El formulario ${numeroFormulario} fue ${newState} Exitosamente`;
        openAlert(message, "success");
        onCloseReturnReason();


        if (respuesta?.data?.status_entryPass === "DESECHAR") {
          return navigate(`/entry-movement?movement=${"Pase de entrada"}`);
        }

        if (respuesta?.data?.status_dispatchPass === "DESECHAR") {
          return navigate(`/output-movement?movement=${"Pase de salida"}`);
        }

        console.log({respuesta, status: respuesta?.data?.status_entryPass || respuesta?.data?.status_dispatchPass})

        /* Para cuando le doy revisar a un pase de entrada y este no tiene formato SRT */
        if (respuesta?.data?.status_entryPass && respuesta?.data?.status_entryPass === "REVISADO" && !respuesta.format_srt) {
          return navigate(
            `/CheckPassEntry?numberForm=${respuesta?.data?.passForm_number}&id=${respuesta?.data.id
            }&status=${respuesta?.data?.status_entryPass}&typeO=${""}`
          );
        }

        /* Para cuando le doy revisar a un pase de salida y este no tiene formato SRT */
        if (respuesta?.data?.status_dispatchPass && respuesta?.data?.status_dispatchPass === "REVISADO" && !respuesta.format_srt) {
          return navigate(
            `/CheckPassOutput?numberForm=${respuesta?.data?.passForm_number}&id=${respuesta?.data?.id
            }&status=${respuesta?.data?.status_dispatchPass}&typeO=${respuesta?.data?.status_dispatchPass ? "Dispatch" : ""}`
          );
        }

        /* Navegación por defecto al pase cuando hay un cambio de estado distinto a REVISADO */
        return navigate(
          `/pases?numberForm=${respuesta?.data?.passForm_number}&id=${respuesta?.data.id
          }&status=${respuesta?.data?.status_entryPass ||
          respuesta?.data?.status_dispatchPass
          }&typeO=${respuesta?.data?.status_dispatchPass ? "Dispatch" : ""}`
        );

      }


      if (
        res?.data?.status?.code === 400 &&
        res?.data?.status?.message?.weight !== null
      ) {
        const message = res?.data?.status?.message?.message || res?.data?.status?.message;
        setMovementSpecial(res?.data?.status?.message?.special);
        setWeightMessage(message);
        openAlert(message, "error");
        setWeightStatus(res?.data?.status?.message?.weight);
        (res?.data?.status?.message?.weight !== undefined) && onTogglePopover();
        return;
      } else {
        if (res?.data?.status?.message?.hasOwnProperty("message")) {
          openAlert(
            res?.data?.status?.message?.message || res?.data?.status?.message,
            "error"
          );
        }
      }

      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, "error");
      }


    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingChange(false)
      setWeightPresent(0);
    }
  };


  const addItemPass = async (data) => {
    try {
      const res = await __AddItemPass(requestAddItem, token, data);
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, "success");
        onCloseSearchItem();
        onCloseAsoccietUnit();
        setUpdate(!update);
      } else {
        const result = res.data.status.message;
        const dato = typeof result;

        if (dato === "object") {
          setmessageModalAsocciet(res.data.status.message.message);
          const info = {
            ...data,
            confirm: res.data.status.message.confirm,
          };
          setdataAsocciet(info);
          onOpenAsoccietUnit();
        } else {
          openAlert(res.data.status.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerItemPass = async (idPass, pageItem, endpoint, body) => {
    if (cargando) return;
    setCargando(true);
    try {
      const res = await __ObtenerItems(token, endpoint, idPass, pageItem, body);
      const resData = res?.data?.status;
      if (resData.code === 200) {
        setCurrentItem(res?.data?.status?.data?.data);
        setMaxPageItem(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCargando(false);
    }
  };

  const deleteItemPass = async () => {
    try {
      const res = await __DeleteItems(requestDelete, token, dataToDelete?.id);
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, "success");
        onCloseWarning();
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const itemDIspacth = () => {
    setShowFormDispacthItem(!showFormDispacthItem);
    setDataItemDispachSelect(null);
    seteditItemDispatch(false);
  };

  const addItemDispacth = async (data) => {
    try {
      const res = await __AddItemPassDispacth(token, data);
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, "success");
        itemDIspacth();
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditItemDispacth = async (data) => {
    try {
      const res = await __EditItemPassDispacth(token, data);
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, "success");
        itemDIspacth();
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyItem = async () => {
    try {
      const res = await __CopyItemDispacth(token, dataToCopy.id);
      if (res?.data?.status?.code === 200) {
        // setDataToEdit(res?.data?.status?.data);
        onCloseCopy();
        openAlert(`Item copiado exitosamente`, "success");
        setUpdate(!update);
      } else {
        if (res?.data?.status?.message) {
          openAlert(res?.data?.status?.message, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    editData,
    dataToEdit,
    editPhoto,
    setDataToEdit,
    dataToDelete,
    setDataToDelete,
    dataToShow,
    setDataToShow,
    // bulkLoades,
    setRequestEdit,
    requestPhoto,
    setRequestPhoto,
    setRequestDelete,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    formLicensias,
    setFormLicensias,
    setRequestCreate,
    setRequestChangeStatus,
    setRequestDeleteAttachment,
    setRequestDeletePhoto,
    createData,
    changeStatus,
    deleteAttachment,
    deletePhoto,
    requestBulkLoad,
    setRequestBulkLoad,
    update,
    setUpdate,
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    requestAddItem,
    setRequestAddItem,
    addItemPass,
    requestObtenerItem,
    setRequestObtenerItem,
    requestObtenerItemPass,
    setRequestObtenerItemPass,
    obtenerItemPass,
    currentItem,
    maxPageItem,
    setCurrentItem,
    isOpenWarning,
    onOpenWarning,
    onCloseWarning,
    deleteItemPass,
    requestDelete,
    dataItemDispachSelect,
    setDataItemDispachSelect,
    showFormDispacthItem,
    setShowFormDispacthItem,
    itemDIspacth,
    addItemDispacth,
    idQualifiedUser,
    setIdQualifiedUser,
    editItemDispatch,
    seteditItemDispatch,
    EditItemDispacth,
    isOpenCopy,
    onOpenCopy,
    onCloseCopy,
    copyItem,
    setDataToCopy,
    isOpenAsoccietUnit,
    onOpenAsoccietUnit,
    onCloseAsoccietUnit,
    messageModalAsocciet,
    dataAsocciet,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    ReturnReason,
    setReturnReason,
    isOpenOtherData,
    onOpenOtherData,
    deleteAttachmentPass,
    onCloseOtherData,
    dataConductor,
    setDataConductor,
    setWeightStatus,
    weightStatus,
    CountTicket,
    setCountTicket,
    weightPresent,
    setWeightPresent,
    IsOpenPopover,
    onTogglePopover,
    onClosePopover,
    addPhotography,
    isLoading,
    onLoading,
    noLoading,
    changeViewSearch,
    setChangeViewSearch,
    pageItem,
    setPageItem,
    page,
    setPage,
    patchData,
    successfulStateChange,
    setSuccessfulStateChange,
    isOpenModalForSelectingItems,
    onOpenModalForSelectingItems,
    onCloseModalForSelectingItems,
    itemsLinkArray,
    setItemsLinkArray,
    weightMessage,
    movementSpecial,
    alreadySailed, 
    setAlreadySailed,
    isLoadingChange,
    setIsLoadingChange
  };

  return (
    <PassCrudContext.Provider value={data}>{children}</PassCrudContext.Provider>
  );
};

export default PassCrudContext;
