import { Td, Tr } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import MenuReviews from "./Menu/MenuReviews";
import queryString from "query-string";

const TbodyReviews = ({ data, ...props }) => {
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  const SelectRouter = (status) => {
    if (status === "Ingreso") {
      return `/FormShow?FormEntry=${data?.form_id}`;
    }

    if (status === "Salida") {
      return `/CheckForm?Number=${data?.form_id}`;
    }

    if (status === "Certificado de integración") {
      return `/CheckIntegrationBox?id=${data?.form_id}`;
    }

    if (status === "Revisión Certificado de integración") {
      return `/CheckIntegrationBox?id=${data?.form_id}`;
    }
  };

  return (
    <Tr>
      <Td className="text-center">
        {" "}
        <NavLink to={SelectRouter(movement)}>
          <span className="formNumber">{data?.num_form}</span>
        </NavLink>
      </Td>

      {movement !== "Revisión Certificado de integración" && (
        <Td className="text-center">{data?.code_operation ?? "N/A"}</Td>
      )}

      <Td className="text-center">{data?.name_activity ?? "N/A"}</Td>
      <Td className="text-center">{data?.name_qualified ?? "N/A"}</Td>
      <Td className="text-center">{data?.nit_qualified ?? "N/A"}</Td>
      <Td className="text-center">
        {data?.date_submitted ?? "N/A"} {data?.time_submitted ?? "N/A"}
      </Td>
      <Td className="text-center">
        <MenuReviews data={data} movement={movement} />
      </Td>
    </Tr>
  );
};

export default TbodyReviews;
