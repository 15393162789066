import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { Center, Tr } from "@chakra-ui/react";
import { RiArrowUpDownFill, RiDragMove2Fill } from "react-icons/ri";

const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

const TableData = styled.td`
  background: white;
  &:first-of-type {
    min-width: 20ch;
  }
`;

const DocumentForm = styled.span`
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "x")};
`;

export const DraggableTableRow = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: row.original.id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <Tr
      ref={setNodeRef}
      style={style}
      {...row.getRowProps()}
      className="text-center"
    >
      {isDragging
        ? (
          <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
        )
        : (
          row.cells.map((cell, i) => {
            if (i === 0) {
              return (
                <TableData {...cell.getCellProps()}>
                  <DocumentForm {...attributes} {...listeners}>
                    <Center>
                      <RiArrowUpDownFill />

                      {cell.render("Cell")}
                    </Center>
                  </DocumentForm>
                </TableData>
              );
            }
            return (
              <TableData {...cell.getCellProps()}>
                {cell.render("Cell")}
              </TableData>
            );
          })
        )}
    </Tr>
  );
};
