import React, { useState } from "react";
import { Flex, Spacer, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { ModalSearch } from "../View/BusquedaAvanzada/ModalSearch";
import { FormCargaMasiva } from "./FormCargaMasiva";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { IconGeneric } from "styled/Icons.styled";
import { RiFileSettingsLine } from "react-icons/ri";
import { MdUploadFile } from "react-icons/md";
import { ModalGeneric } from "components/modal/ModalGeneric";

export const BtnHeaders = ({ allAccess, myPermission }) => {
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [titleModal, settitleModal] = useState("Carga masiva");

  const OpenModalSetTitle = (titulo) => {
    settitleModal(titulo);
    onOpen();
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={titleModal}
        buttonClose={false}
      >
        <FormCargaMasiva onClose={onClose} title={titleModal} />
      </ModalGeneric>

      <Flex>
        <Spacer />
        {allAccess === true ||
        myPermission?.includes("dispatchPassformImport") ||
        myPermission?.includes("entryPassformImport")
          ? (
            <Tooltip placement="top" hasArrow label="Cargar Masivamente">
              <span>
                <ButtonHeaderModule
                  onClick={() => OpenModalSetTitle("Carga masiva")}
                  bgColor="#1F7AC3"
                  className={`d-flex float-end ${
                    movement !== "Pase de entrada" && "me-1"
                  }`}
                >
                  <IconGeneric width="16px" color="#fff" as={MdUploadFile} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          )
          : (
            ""
          )}

        {movement === "Pase de entrada" &&
        (allAccess === true ||
          myPermission?.includes("entryPassModifyFormImport"))
          ? (
            <Tooltip placement="top" hasArrow label="Ajustar Masivamente">
              <span>
                <ButtonHeaderModule
                  onClick={() => OpenModalSetTitle("Ajuste masivo")}
                  bgColor="#F9A621"
                  className="mx-1 d-flex float-end"
                >
                  <IconGeneric
                    width="16px"
                    color="#fff"
                    as={RiFileSettingsLine}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          )
          : (
            ""
          )}
      </Flex>
    </>
  );
};
