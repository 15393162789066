/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import TableOrder from "components/Tables/TableOrder";
import { useSelector } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TitleBlocking, TitleBlockingSearch } from "./Title/ArrayTitle";
import Pagination from "components/Pagination/Pagination";
import ButtonsBlocking from "./Buttons/ButtonsBlocking";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormBlocking from "./FormBlocking";
import {
  __GetBlocking,
  __SearchBlocking,
  __SearchBlockingAdvance
} from "request/Blocking/___BlockingCrud";
import TableBlocking from "./Table/TableBlocking";
import BlockingContext from "context/BlockingContex";
import FormView from "./FormView/FormView";
import FormHistory from "./FormView/FormHistory";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { Title } from "styled/Title";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import {
  arrayLeftBlocking,
  arrayLeftBlockingUser,
  arrayRigthBlocking,
  blockingNodeInputFields
} from "./Title/ArrayExportExcel";
import { AuthContext } from "context/AuthContext";
import FormsearchAdvance from "./FormsearchAdvance";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import TableSearch from "./Table/TableSearch";
import { useDispatch } from "react-redux";
import { clearSearch, getSearch, setSearch } from "redux/actions/searchActions";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import { Button } from "@chakra-ui/react";

/**
 * Blocking - Componente donde se encuentra la busquedad, tablas y modales de bloqueos
 *
 * @component
 * @return {Component} Retorna tablas y busquedad de bloqueos.
 */

const Blocking = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({
    getParams: ["dependency", "status", "date1", "date2", "lock_type", "statusInfo",
      "dependencyInfo", "lockType", "userQualified", "qualifiedUser_id"], nestedProp: "searchAdvance"
  });
  const { authUser } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [pageSearch, setPageSearch] = useState(1);
  const [openAlert] = AlertErrorAlert();
  const dispacthRedux = useDispatch();
  const searchExport = useSelector(getSearch);

  const {
    update,
    isOpenBlocking,
    onOpenBlocking,
    isOpenShow,
    onCloseShow,
    isOpenHistory,
    onCloseHistory,
    onCloseModal,
    exportColumnBy
  } = useContext(BlockingContext);

  const { search, valueQualified } = state;

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    dispacthRedux(clearSearch());
  };



  const { requestData } = useGlobalContext();


  const data = useMemo(() => {
    const { dependencyInfo, lockType, statusInfo, userQualified, ...rest } = cleanState

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && { qualifiedUser_id: valueQualified?.value }),
      ...rest,
    }

    return searchInfo
  }, [search, valueQualified, cleanState]);


  const { data: dataBlocking, isLoading } = useQuery({
    queryKey: ["blocking", page, update, search, valueQualified, freeZone_id, cleanState, onCloseSearch],
    queryFn: () => requestData({
      Endpoint: Object.entries(data).length === 0 ? environment.BLOCKING_GET : environment.BLOCKING_ADVANCE_SEARCH_COMPLETE,
      page,
      method: "POST",
      data,
    })
  })

  const handleSubmit = async (formulario, data) => {
    const info = {
      fields: formulario,
      data: {
        ...data,
        ...searchExport,
      }
    };
    const res = await __Post(environment.BLOCKING_EXPORT, token, info);
    console.log(res);
    if (res?.data?.status?.code === 200) {
      onCloseExport();
      return openAlert(`${res?.data?.status?.message}`, "success");
    } else {
      // console.log(res);
      return openAlert(`${res?.data?.status?.message}`, "error");
    }
  };

  useEffect(() => {
    if (data) {
      dispacthRedux(setSearch({ data }));
      return;
    }
    return async () => dispacthRedux(clearSearch());
  }, [dataBlocking]);

  const calculateNodeList = {
    1: arrayLeftBlockingUser.concat(arrayRigthBlocking),
    0: arrayLeftBlocking.concat(arrayRigthBlocking),
  }[authUser.is_qualified_user];

  return (
    <>
      <Title style={{ marginTop: 30 }}>Bloqueos</Title>

      <ModalGeneric
        isOpen={isOpenBlocking}
        onClose={onCloseModal}
        title={`${"Bloquear nuevo usuario"}`}
        size={"4xl"}
      >
        <FormBlocking />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenShow}
        onClose={onCloseShow}
        title={"Dependencias"}
        size={"4xl"}
      >
        <FormView />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada bloqueos"
      >
        <FormsearchAdvance
          onClose={onCloseSearch}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenHistory}
        onClose={onCloseHistory}
        title={"Historial"}
        size={"4xl"}
      >
        <FormHistory />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={calculateNodeList}
        selectNodeList={blockingNodeInputFields}
        enableAdvance={true}
      />
      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
      // size={"4xl"}
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={
            authUser.is_qualified_user === 1
              ? arrayLeftBlockingUser
              : arrayLeftBlocking
          }
          listRight={arrayRigthBlocking}
          handleSubmit={handleSubmit}
          dafaulActive={[]}
        />
      </ModalGeneric> */}

      <div className="container p-4">
        <SearchAdvancedModule
          onOpenSearch={onOpenSearch}
          changeViewSearch={Object.entries(data).length !== 0}
          backSearch={backSearch}
          permisoSearch={"getRestrictionsAdvanceSearch"}
          myPermission={authUser?.user_rol?.modules?.filter(
            (e) => e.modul_permissionGroup === "Bloqueos"
          )?.[0]?.permission?.map((e) => e.name_permission)}
        />
        <ExportDetail onOpen={onOpenExport} />
      </div>

      <HeaderSearch>
        {
          <>
            {
              Object.entries(data).length === 0 && (
                <div className="mb-2">
                  <ButtonsBlocking onOpenBlocking={onOpenBlocking} />
                </div>
              )
            }
            <div>
              <TableOrder
                thead={Object.entries(data).length === 0 ? TitleBlocking : TitleBlockingSearch}
                data={dataBlocking?.data?.status?.data?.data || []}
                setData={() => { }}
                loading={isLoading}
                tbodyData={Object.entries(data).length === 0 ? TableBlocking : TableSearch}
              />

              <Pagination
                page={Object.entries(data).length === 0 ? page : pageSearch}
                setPage={Object.entries(data).length === 0 ? setPage : setPageSearch}
                maxPage={dataBlocking?.data?.status?.data?.last_page}
                loading={isLoading}
              />
            </div>
          </>

        }

      </HeaderSearch>
    </>
  );
};

export default Blocking;
