import { Box } from '@chakra-ui/react'
import InputSelect from 'components/InputSelect/InputSelect'
import environment from 'constants/apiConst'
import useUserQualified from 'hooks/useUserQualified'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { __PostForm } from 'request/Petitions/__Post'

export const DocuExportPro = () => {
  const { userQualified } = useUserQualified()
  const [selectUserQualified, setSelectUserQualified] = useState([])
  const token = useSelector(state => state.auth.dataUser.token)

  const Pdf = async () => {
    try {
      const res = await __PostForm(environment.POST_EXPORT_PDF, token, {
        qualifiedUser_id: [1]
      })
      const requestData = res
      console.log('Respuesta data', requestData)
    } catch (error) {
    } finally {
    }
  }

  return (
    <Box>
      {' '}
      <div className='spac'>
        <InputSelect
          tag={'Usuario calificado'}
          data={userQualified}
          set={setSelectUserQualified}
          value={selectUserQualified}
        />
      </div>
    </Box>
  )
}
