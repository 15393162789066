import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { HiDotsHorizontal } from 'react-icons/hi'
import OrderProductionContext from 'context/OrderProductionContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import environment from 'constants/apiConst'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import { useModal } from 'hooks/useModal'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

export const MenuSupply = ({ data }) => {
  const { requestData } = useGlobalContext()
  const { onOpenCreateSupplyItemOP, setDataToEditOP } = useGlobalContext()
  const queryClient = useQueryClient()
  const [openAlert] = AlertErrorAlert()
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal()

  const { isLoading, mutate } = useMutation({
    mutationFn: requestData
  })

  const handleEdit = e => {
    setDataToEditOP(data)
    onOpenCreateSupplyItemOP()
  }

  const handleResponseDelete = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ['getSupplyItemOP'] })
    }
  }

  const handleDelete = () => {
    const config = {
      resourceIdentifier: `${data?.id}?=${data?.production_order_id}`,
      Endpoint: environment.DELETE_SUPPLY_ITEM_OP
    }

    mutate(config, {
      onSuccess: data => handleResponseDelete(data)
    })
  }

  return (
    <>
      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Eliminar item`}
        titleBody={`Los insumos que fueron descontados para esta orden de producción Nro. ${data?.item} volverán a estar disponible en el inventario`}
        generar={() => handleDelete()}
      />

      <Menu>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color='#00000085' />
        </MenuButton>
        <MenuList
          borderRadius='none'
          py={0}
          style={{ boxSizing: 'border-box' }}
        >
          <MenuItemStyled py={3} onClick={handleEdit}>
            <IconMenuRUD as={RiEdit2Fill} />
            Editar
          </MenuItemStyled>
          <HrDividerNoMargin />
          <MenuItemStyled py={3} onClick={onOpenWarning} isLoading={isLoading}>
            <IconMenuRUD as={MdDelete} color='#d92525' />
            Eliminar
          </MenuItemStyled>
          <HrDividerNoMargin />
        </MenuList>
      </Menu>
    </>
  )
}

export default MenuSupply
