import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { __Post } from 'request/Petitions/__Post';
import environment from "constants/apiConst";
import { useQuery } from '@tanstack/react-query';
import { ModalGeneric } from './ModalGeneric';
import SpinnerComponent from 'components/Spinner/SpinnerComponent';
import { Flex, Spacer } from '@chakra-ui/react';
import { ButtonItemsForm } from 'pages/Pass-Form/stylePases';

const ModalQR = ({ isOpen, onOpen, onClose, title, size = "sm" }) => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const { data, isLoading } = useQuery({
    queryKey: ['qr'],
    queryFn: () => __Post(environment.GENERATE_QR, token, {
      url: window.location.href
    }),
    refetchOnMount: true
  })

  return (
    <ModalGeneric
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      title={title}
      size={size}
    >
      {isLoading
        ? <SpinnerComponent />
        : (
          <>
            <div className="d-flex justify-content-center">
              <img style={{ width: `50%` }} alt="" src={data?.data?.status?.data} />
            </div>

            <Flex mt={6}>
              <Spacer />
              <ButtonItemsForm color="" onClick={onClose}>
              Cancelar
              </ButtonItemsForm>
            </Flex>

          </>

        )
      }
    </ModalGeneric>
  )
}

export default ModalQR
