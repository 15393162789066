import { Colors } from 'chart.js';

export const estadisticaOptions = (LabelX, LabelY, Title, otherOptions) => ({
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  ticks: {
    beginAtZero: true
  },
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: !!Title,
      text: Title,
      font: {
        size: 16,
        weight: "bold",
        lineHeight: 1.0
      }
    }
  },
  scales: {
    x: {
      ticks: {
        autoSkip: false, // Esto evita que las etiquetas se salten automáticamente
        font: {
          size: 8,
          lineHeight: 1.4
        }
      },
      title: {
        display: !!LabelY,
        text: LabelX,
        color: "#000",
        font: {
          size: 13,
          weight: "bold",
          lineHeight: 2
        }
      }
    },
    y: {
      title: {
        display: !!LabelY,
        text: LabelY,
        color: "#000",
        font: {
          size: 13,
          weight: "bold",
          lineHeight: 1.4
        }
      }
    }
  },
  ...otherOptions
})

