import { BALANCE_TYPES } from "redux/types/stateBalancetypes";

const setDataStatusBalance = (payload) => {
  return {
    type: BALANCE_TYPES.STATEBALANCE,
    payload
  };
};

export { setDataStatusBalance };
