import { Tooltip } from '@chakra-ui/react'
import { LabelStyled } from 'components/addProduct/CreateTemplate.styled'
import { FormInputLineDow } from 'pages/RefProductInter/Stilos'

export const InputWithTooltip = ({
  bgTooltip,
  tag,
  tagSize,
  msgTooltip = null,
  required,
  ...props
}) => {
  if (msgTooltip) {
    return (

      <>
        <LabelStyled fontSize={tagSize} error={tagSize}>
          {tag}
        </LabelStyled>
        <Tooltip
          label={msgTooltip}
          hasArrow
          arrowSize={11}
          placement='bottom'
          bg={bgTooltip}
        >
          <FormInputLineDow {...props} required/>
        </Tooltip></>
    )
  }

  return (
    <>
      <LabelStyled fontSize={tagSize} error={tagSize}>
        {tag}
      </LabelStyled>
      <FormInputLineDow {...props} required/>
    </>
  )
}
