import React from "react";
import { IconGeneric } from "styled/Icons.styled";
import { CgImport } from "react-icons/cg";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { Tooltip } from "@chakra-ui/react";

export const ExportDetail = ({ onOpen, children }) => {
  return (
    <>
      <div className="my-3">
        <div className="row">
          {/* <div className="col-4">
             <h2 className="ms-3">Todos los documentos</h2>
          </div> */}
          <div className="col-6"></div>
          <div className="col-6">
            <div className="d-flex float-end ">
              {children}
              <Tooltip placement="top" hasArrow label="Exportar">
                <span>
                  <ButtonHeaderModule onClick={() => onOpen()} bgColor="#03C39A">
                    <IconGeneric width="16px" color="#fff" as={CgImport} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
