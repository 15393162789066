import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon {
  animation: spin 1s linear infinite;
}

/* 
  *::-webkit-scrollbar {
	width: 7px;
  }

 *::-webkit-scrollbar-thumb {
	background: #1F7AC3;
	border-radius: 5px;
}

.contenedor::-webkit-scrollbar {
	width: 7px;
}
.contenedor::-webkit-scrollbar-thumb {
background: #1F7AC3;} */

  body {
    font-family: "Roboto", sans-serif;
    color: black !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff !important;
    
  }
  
  p, h1, h2, span, a {
    color: black;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  .table-hover{
   cursor:pointer; 
   
  }

  .izq {
    transition: 0.3s;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.3s ;
  }
  
  .oculto{
  opacity: 0;
  display: none;
  }


  .pro-sidebar > .pro-sidebar-inner {
    background: var(--zf-primary);
  }

  .row{
    margin-right: 0;
  }
  .rtf{
    
    margin: 0;
    bottom: 80px !important;
    right: 5px !important;
            // right: "10px",
  }

  /* width 
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px; 
  }

  
  ::-webkit-scrollbar-thumb {
    background: var(--zf-primary);
    border-radius: 10px;
  }
  */

   .container, .container-lg, .container-md, .container-sm, .container-xl {
    @media (max-width: 1000px){
      max-width: 95%;
      margin-left: 30px !important;

    }
}

`;

export default GlobalStyle;
