import axios from "axios";
import env from "../../constants/apiConst";

export const __QualifiedCitys = async (token) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFIED_USERS_CITY}`, options);
  return res;
};

export const __QualifiedSector = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFIED_USERS_SECTOR}`, options);
  return res;
};

export const __QualifiedActivity = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFIED_USERS_ACTIVITY}`, options);
  return res;
};

export const __QualifiedClasificacion = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFIED_USERS_CLASIFICACION}`, options);
  return res;
};
