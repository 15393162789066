/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { __RequiredDocumentView } from "request/configuration/__DocumentRequire";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const FormRequiredDocument = () => {
  const [status, setStatus] = useState(1);
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    name_document: "",
    description_document: "",
    status_document: status
  });

  const { name_document, description_document } = values;

  useEffect(() => {
    if (dataToEdit) {
      const initialpos = async () => {
        try {
          const res = await __RequiredDocumentView(token, dataToEdit.id);
          setValues(res?.data?.status?.data);
          setStatus(res.data?.status?.data.status_document);
        } catch (error) {}
      };
      initialpos();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_document: status
      };
      editData(data);
    } else {
      if (name_document === "") {
        openAlert("Ingrese el nombre del documento", "error");
      } else {
        createData(values);
      }
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Documentos Requeridos
      </Text>
      <Divider mb={1} />
      <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Documento" : "Crear Documento"}
      </Text>
      <Divider mb={3} />
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            name="name_document"
            tag={"Nombre del documento"}
            value={name_document}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            size={45}
            desicion={false}
            maxLength={80}
          />

          <LabelGroup
            name="description_document"
            tag={"Descripción del documento"}
            value={description_document}
            onChange={handleInputChange}
            size={45}
            desicion={false}
            maxLength={5000}
          />
        </div>

        <div
          style={{
            marginRight: "90%",
            marginBottom: "2rem"
          }}
        >
          {dataToEdit && (
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag={"¿Activo?"}
              size={45}
            />
          )}
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormRequiredDocument;
