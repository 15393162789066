import React, { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import TreasuryCrudContex from "context/TreasuryContex";
import { useNavigate } from "react-router-dom";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuUpdateRate = ({ data }) => {
  const { setDataToEdit } = useContext(TreasuryCrudContex);
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/FormActualizacionDeTarifa?NumberRate=${data.rate_id}`);
    setDataToEdit(data);
  };

  return (
    <>
      <MenuItemStyled py={3} onClick={handleEdit}>
        <IconMenuRUD as={RiEdit2Fill} />
        Editar
      </MenuItemStyled>
    </>
  );
};

export default MenuUpdateRate;
