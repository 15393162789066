import axios from "axios";
import env from "../../constants/apiConst";

export const __GetModules = async (token) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_MODULES}`, options);
  return res;
};
