/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import OperationsForm from "../forms/OperationsForm";
import TableOrder from "components/Tables/TableOrder";
import TbodyOperation from "../tables/TbodyOperation";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import FormOperatioShow from "../FormShow/FormOperatioShow";
import { __Operations } from "request/configuration/__Operations";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const Operations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const {
    update,
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestDelete,
    setRequestCreate
  } = useContext(ConfigurationCRUDContext);

  const thead = [
    { name: "Código", order: "code_operation" },
    { name: "Nombre corto", order: "shortName_operation" },
    { name: "Tipo de operación", order: "shortName_operation" },
    { name: "Op. de reing", order: "reentry_operation" },
    { name: "Transformación", order: "transformation" },
    { name: "Actividades", order: "activities" },
    { name: "Seguimiento", order: null },
    { name: "Soporte requerido", order: null },
    { name: "Activo", order: "status_operation" },
    { name: "Acciones", order: null }
  ];

  const formEdit = () => {
    return <OperationsForm />;
  };

  const formShow = () => {
    return <FormOperatioShow />;
  };

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formShow);
    setRequestEdit(environment.OPERATIONS_UPDATE);
    setRequestCreate(environment.OPERATIONS_CREATE);
    setRequestDelete(environment.OPERATIONS_DELETE);
  }, [q, update , page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configOperations", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.OPERATIONS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Operaciones"></ConfigurationHeader>
      {isLoading || (
        <TableOrder
          thead={thead}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyOperation}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default Operations;
