import { Box } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import Pagination from 'components/Pagination/Pagination'
import { SearchAdvancedModule } from 'components/SearchSimpleAdvanced/SearchAdvancedModule'
import TableOrder from 'components/Tables/TableOrder'
import environment from 'constants/apiConst'
import { useGlobalContext } from 'context/GlobalContext'
import { useForm } from 'hooks/useForm'
import { theadReports } from 'pages/Weighing/SearchAdvanced/Array/ArrayTableHead'
import TbodyReports from 'pages/Weighing/Tbody/TbodyReports'
import React, { useEffect, useState } from 'react'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { Title } from 'styled/Title'

const Reports = () => {
  const [page, setPage] = useState(1);
  const [valueQualified, setValueQualified] = useState([]);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [firstRender, setFirstRender] = useState(true)
  const { requestData } = useGlobalContext()
  const [values, handleInputChange, reset] = useForm({
    searchInformacion: ""
  });
  const { searchInformacion } = values;

  const { data: dataReport, isLoading: isLoadingReport } = useQuery({
    queryKey: ["GET_REPORT_LITE_DISPATCH", page, valueQualified?.id, searchInformacion],
    queryFn: () => requestData({
      pageParam: page,
      data: {
        ...(valueQualified?.id && { qualifiedUser_id: valueQualified?.id }),
        ...(searchInformacion && { search: searchInformacion })
      },
      Endpoint: environment.GET_REPORT_LITE_DISPATCH,
      PropertyBody: "sendJSONContentPOST"
    }),
  })

  useEffect(() => {
    setChangeViewSearch(true)
    if (dataReport?.data?.status?.code === 200 && page > dataReport?.data?.status?.data?.last_page) {
      setPage(dataReport?.data?.status?.data?.last_page)
      return;
    }
  }, [dataReport])


  const backSearch = () => {
    reset();
    setValueQualified([]);
    setChangeViewSearch(false);
  };

  return (
    <>
    <Title>Reporte de salida por puerta liviano</Title>
     <Box style={{ marginBottom: 20, marginTop: 20 }}>
     <SearchAdvancedModule
        handleInputChange={handleInputChange}
        values={values}
        backSearch={backSearch}
        changeViewSearch={changeViewSearch}
        setValueQuialified={setValueQualified}
        valueQualified={valueQualified}
        allAccess={false}
        myPermission={null}
      />
     </Box>

      <HeaderSearch>
        <TableOrder
          thead={theadReports}
          data={dataReport?.data?.status?.code !== 400 ? dataReport?.data?.status?.data?.data : []}
          setData={() => { }}
          loading={isLoadingReport}
          tbodyData={TbodyReports}
        />

        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataReport?.data?.status?.data?.last_page}
          loading={isLoadingReport}
        />
      </HeaderSearch>
    </>
  )
}

export default Reports
