/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import TableOrder from "components/Tables/TableOrder";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  HeaderSearch,
  SectionProccessProductive
} from "styled/SectionContainer.styled";
import { items, thead } from "./EditArray";
import EditProductForm from "../form/EditProductForm";
import EditProductTablesMenu from "./EditProductTablesMenu";
import { Button, Tooltip } from "@chakra-ui/react";
import { AddProductProcess } from "../form/AddProductProcess";
import { AddInsuProcess } from "../form/AddInsuProcess";
import { AddOtherProcess } from "../form/AddOtherProcess";
import ProcessCrudContext from "context/ProcessCrudContext";
import ModalTransition from "components/modal/ModalTransition";
import {
  __InsumosProcess,
  __OtherProcess,
  __ProductProcess
} from "request/configuration/__ProductProcess";
import environment from "constants/apiConst";
import TbodyProductPorcess from "../tables/TbodyProductPorcess";
import Pagination from "components/Pagination/Pagination";
import TbodyInsumosProcess from "../tables/TbodyInsumosProcess";
import ModalDelete from "components/modal/ModalDelete";
import TbodyOther from "../tables/TbodyOther";
import { useSelector } from "react-redux";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { MdAddShoppingCart } from "react-icons/md";

const EditProductTemplate = () => {
  const [menuTable, setMenuTable] = useState("Productos");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { id = "" } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    onOpenEdit,
    setFormEdit,
    setRequestCreate,
    setRequestEdit,
    setRequestDelete,
    isOpenEdit,
    onCloseModal,
    editData,
    formEdit,
    isOpenDelete,
    dataToDelete,
    deleteData,
    update
  } = useContext(ProcessCrudContext);

  const formEditProduct = (e) => {
    return <AddProductProcess />;
  };
  const formEditInsumos = (e) => {
    return <AddInsuProcess />;
  };
  const formEditOther = (e) => {
    return <AddOtherProcess />;
  };

  const product = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        const res = await __ProductProcess(token, formData, page);
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [page, token]
  );

  const Insumos = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        const res = await __InsumosProcess(token, formData, page);
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [page, token]
  );

  const Other = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        const res = await __OtherProcess(token, formData, page);
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [page, token]
  );

  useEffect(() => {
    const formData = new FormData();
    formData.append("productiveProcess_id", id);

    if (menuTable === "Productos") {
      setFormEdit(formEditProduct);
      setRequestCreate(environment.ADD_PRODUCT_PROCESS);
      setRequestEdit(environment.UPDATE_PRODUCT_PROCESS);
      setRequestDelete(environment.DELETE_PRODUCT_PROCESS);
      product(formData);

      return;
    }

    if (menuTable === "Insumos") {
      setFormEdit(formEditInsumos);
      setRequestCreate(environment.ADD_INSUMOS_PROCESS);
      setRequestEdit(environment.UPDATE_INSUMOS_PROCESS);
      setRequestDelete(environment.DELETE_INSUMOS_PROCESS);
      Insumos(formData);
      return;
    }

    if (menuTable === "Otros costos y gastos") {
      setFormEdit(formEditOther);
      setRequestCreate(environment.ADD_OTHER_PROCESS);
      setRequestEdit(environment.UPDATE_OTHER_PROCESS);
      setRequestDelete(environment.DELETE_OTHER_PROCESS);
      Other(formData);
    }
  }, [update, menuTable, id]);

  return (
    <>
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"xl"}
        modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={'outside'}
      >
        {formEdit}
      </ModalTransition>

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento ${
          dataToDelete?.code_product || dataToDelete?.id
        }`}
        onDelete={deleteData}
      />

      {/* <SectionHeader /> */}

      <SectionProccessProductive>
        <HeaderSearch>
          <EditProductForm />
        </HeaderSearch>

        <div className="container pt-3">
          <span className="float-end">
            <Tooltip placement="top" hasArrow label={`Agregar ${menuTable} `}>
              <ButtonHeaderModule
                onClick={onOpenEdit}
                bgColor="#2F8B8A"
                className="align-bottom d-flex float-end"
              >
                <IconGeneric color="#fff" as={MdAddShoppingCart} />
              </ButtonHeaderModule>
            </Tooltip>
          </span>
          <EditProductTablesMenu
            setState={setMenuTable}
            items={items}
            state={menuTable}
          />
        </div>

        {loading || (
          <TableOrder
            thead={thead[menuTable]}
            data={currentPage ?? []}
            isNum={false}
            setData={setCurrentPage}
            loading={loading}
            tbodyData={
              menuTable === "Productos"
                ? TbodyProductPorcess
                : menuTable === "Insumos"
                  ? TbodyInsumosProcess
                  : TbodyOther
            }
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </SectionProccessProductive>
    </>
  );
};

export default EditProductTemplate;
