import { loginAction } from 'redux/actions/authentication'
import { AUTH_TYPES } from 'redux/types/authTypes'
import { FIRST_LOGIN } from 'redux/types/firstLogonTypes'
import { __Login } from 'request/__Login'

const permission = modules => {
  return modules.reduce((obj, module) => {
    module.permission.forEach(perm => {
      const { modul_permissionGroup, name_permissionGroup } = module
      const { name_permission } = perm

      obj[modul_permissionGroup] = obj[modul_permissionGroup] || {}
      obj[modul_permissionGroup][name_permissionGroup] =
        obj[modul_permissionGroup][name_permissionGroup] || {}

      obj[modul_permissionGroup][name_permissionGroup][name_permission] =
        callback => {
          callback()
        }
    })

    return obj
  }, {})
}

export const login = async (
  values,
  geoLocation,
  storeKeepSesion,
  setLoading,
  setAlertMessage,
  openModal,
  navigate,
  dispatch,
  dispacthRedux,
  firstLoginDispatch,
  location,
  passwordEncry,
  channel
) => {
  const formData = new FormData()
  formData.append('nickname', values?.username)
  formData.append('password', passwordEncry)
  formData.append('latitude', geoLocation?.latitudes ?? '')
  formData.append('longitude', geoLocation?.longitude ?? '')
  formData.append('remember_me', storeKeepSesion === true ? 1 : 0)
  setLoading(true)
  try {
    const resLogin = await __Login(formData)
    if (resLogin.data.status.code > 299) {
      const { message } = resLogin.data.status
      setLoading(false)
      setAlertMessage(`${message}`)
      if (openModal) {
        openModal()
      }
      return
    }
    /*
    const modules = resLogin.data.status?.user?.user_rol?.modules;
    */
    if (resLogin.data.status.code === 200) {
      const { token } = resLogin.data.status
      const identificacion =
        resLogin?.data?.status?.user?.qualifiedUser_id?.qualified_id
      const valorDoc = resLogin?.data?.status?.user?.qualifiedUser_id
      const viewFreeZone = resLogin?.data?.status?.user?.view_free_zone
      const scale_balance = valorDoc?.scale_balance
      const document_balance = valorDoc?.document_balance
      const {
        is_superadmin,
        terms_and_conditions,
        change_password_first_time,
        is_qualified_user,
        is_commerce,
        user_rol,
        qualifiedUser_id,
        port
      } = resLogin.data.status.user

      const name_freeZone = qualifiedUser_id?.name_freeZone
      const name_qualified = qualifiedUser_id?.name_qualified
      const decimal_values_precision =
        qualifiedUser_id?.decimal_values_precision
      const freeZone_id = qualifiedUser_id?.freeZone_id

      if (!terms_and_conditions || !change_password_first_time) {
        const action = {
          type: FIRST_LOGIN.CHANGE_DATA,
          payload: {
            username: values?.username,
            token,
            termsAndConditions: terms_and_conditions,
            passwordFirstTime: change_password_first_time,
            is_superadmin
          }
        }
        firstLoginDispatch(action)
        dispacthRedux(action)
        channel.postMessage(action)
        navigate(`/auth/change-password`)
        return
      }
      const payload = {
        id: resLogin.data.status.user.id,
        nameFreeZoneTemp: resLogin.data.status?.user?.name_free_zone_temp,
        collect: resLogin.data.status.user?.qualifiedUser_id?.collect,
        username: values?.username,
        token,
        rol: resLogin.data.status.user.user_rol.id, // Dato temporal
        userQualified: identificacion,
        admin: is_superadmin,
        is_qualified_user,
        is_commerce,
        name_freeZone,
        name_qualified,
        user_rol,
        user_permissions: resLogin.data.status?.user?.user_permissions,
        last_access: resLogin.data.status?.last_access,
        date_expires: resLogin.data.status?.date_expires,
        decimal_values_precision,
        freeZone_id,
        viewFreeZone,
        port,
        loginRefresh: 0
      }

      const payloadBalance = {
        scale_balance,
        document_balance
      }

      const action = {
        type: AUTH_TYPES.LOGIN,
        payload
      }

      setLoading(false)
      dispatch(action)
      channel.postMessage(action)
      dispacthRedux(loginAction(payload))
      localStorage.setItem('ValueBalance', JSON.stringify(payloadBalance))

      if (location.state?.origin) {
        return navigate(`${origin?.state?.pathname}${origin?.state?.search}`, {
          replace: true
        })
      } else {
        navigate('/', {
          replace: true
        })
      }
    }
  } catch (error) {
    console.log(error)
    setAlertMessage('Error al establecer conexion')
    openModal()
  }
}

export const getStores = setstoreKeepSesion => {
  const getStateStore = localStorage.getItem(`KeepSesion`)
  if (getStateStore !== null) {
    if (getStateStore === 'true') {
      console.log(getStateStore)
      setstoreKeepSesion(true)
    } else {
      console.log(getStateStore)
      setstoreKeepSesion(false)
    }
  }
}

export const handleKeepSesion = (e, setstoreKeepSesion) => {
  const stateCurrent = e.target.checked
  setstoreKeepSesion(stateCurrent)
  localStorage.setItem(`KeepSesion`, stateCurrent)
}

export const geolocation = setgeoLocation => {
  navigator.geolocation.getCurrentPosition(function (position) {
    setgeoLocation({
      latitudes: position.coords.latitude,
      longitude: position.coords.longitude
    })
  })
}
