import React, { useContext, useState } from "react";
import { Box, Td, Tr } from "@chakra-ui/react";
import MenuForm from "components/Menu/MenuForm";
import { NavLink, useNavigate } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const TbodyFormEntry = ({ data }) => {
  const { setDataToShow } = useContext(FormCrudCRUDContext);
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);

  const handleShow = () => {
    navigate(`/FormShow?FormEntry=${data.id}`);
    setDataToShow(data);
  };

  const handleToggleShow = () => {
    setShowAll(prev => !prev);
  };
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const checkIfItIsBlocked = async () => {
    if (is_qualified_user) return;

    
    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", "FMMI");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false)
      setTimeout(() => setOpenMenu(true), 500)
    }
  }

  const redirectDocument = () => {
    const {entryFormCorrected_id, final_balance_adjustment} = data || {}
    console.log('data', {data, entryFormCorrected_id, final_balance_adjustment})
    navigate(`/FormCorrection?ShowEntry=${entryFormCorrected_id}`);
  }

  return (
    <Tr>

      <Td className="text-center" onClick={handleShow}>
        <NavLink to={`/FormShow?FormEntry=${data.id}`} className="ms-2">
          <span className="formNumber">{data?.code_entryForm}</span>
        </NavLink>
      </Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.business_name}</Td>

      <Td className="text-center" >
        <Box style={{ maxHeight: "100px", maxWidth: "200px", overflowY: 'auto' }}>
          {showAll
            ? data?.num_commercial_invoice
            : data?.num_commercial_invoice?.slice(0, 40)}
        </Box>
        {data?.num_commercial_invoice?.length > 40 && (
          <span
            className="formNumber"
            onClick={handleToggleShow}
            style={{ cursor: "pointer" }}
          >
            {showAll ? " Ver menos" : " Ver más"}
          </span>
        )}
      </Td>

      <Td className="text-center">{data?.num_transport_document}</Td>
      <Td className="text-center">{data?.user}</Td>
      <Td className="text-center">{data?.countItems}</Td>
      <Td className="text-center">{data?.status_entryForm}</Td>
   
      {data?.status_entryForm === "PRESENTADO"
        ? (
          <Td className="text-center" onClick={() => checkIfItIsBlocked()}>
            {openMenu && <MenuForm data={data} />}
          </Td>
        )
        : (
          <Td className="text-center">
            <MenuForm data={data} />
          </Td>
        )}
    </Tr>
  );
};

export default TbodyFormEntry;
