import React from "react";

import './styles.css'
import AccordionComponent from "./components/AccordionComponent";
import { HeaderSearch } from "styled/SectionContainer.styled";

const index = () => {
  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      <h1 style={{ marginTop: 30, fontSize: 20, fontWeight: "bold" }}>Novedades</h1>
      <AccordionComponent title="Fundación CTIC - Luis Carlos Sarmiento Angulo" descTitle>
        <div>
          <h1 className="title">Datos de interes</h1>
          <div className="containerDataOfInterest">
            <div>
              <div className="containerItems">
                <p className="title">Placa:</p>
                <p className="descrip">DFV156</p>
              </div>
              <div className="containerItems">
                <p className="title">Pase de ingreso: </p>
                <p className="descrip">15486259</p>
              </div>
            </div>

            <div>
              <div className="containerItems">
                <p className="title">Conductor:</p>
                <p className="descrip">Mauricio herrera</p>
              </div>
              <div className="containerItems">
                <p className="title">Formato de ingreso:</p>
                <p className="descrip">458624562</p>
              </div>
            </div>

            <div>
              <div className="containerItems">
                <p className="title">HdV:</p>
                <p className="descrip">Mauricio herrera</p>
              </div>
              <div className="containerItems">
                <p className="title">Placa:</p>
                <p className="descrip">DFV156</p>
              </div>
            </div>
          </div>
        </div>
      </AccordionComponent>
      <AccordionComponent title="Usuario bloqueado">
        <div className="containerDataOfInterest">
          <div>
            <div className="containerItems">
              <p className="title">Conductor:</p>
              <p className="descrip">Mauricio herrera</p>
            </div>
          </div>

          <div>
            <div className="containerItems">
              <p className="title">HdV:</p>
              <p className="descrip">Mauricio herrera</p>
            </div>
          </div>
        </div>

        <div>
          <p className="title">Razones:</p>
          <p className="descrip">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel orci eget nulla
            convallis eleifend ut et est. Quisque elit lacus, gravida ac quam in, vestibulum
            consectetur massa. Vestibulum sollicitudin metus a sodales consequat. Suspendisse porta
            lobortis leo posuere imperdiet.
          </p>
        </div>
      </AccordionComponent>
      <AccordionComponent title="Fundación CTIC - Luis Carlos Sarmiento Angulo">
        <p className="descrip">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel orci eget nulla
          convallis eleifend ut et est. Quisque elit lacus, gravida ac quam in, vestibulum
          consectetur massa. Vestibulum sollicitudin metus a sodales consequat. Suspendisse porta
          lobortis leo posuere imperdiet.
        </p>
      </AccordionComponent>
      <AccordionComponent title="Fundación CTIC - Luis Carlos Sarmiento Angulo">
        <p className="descrip">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel orci eget nulla
          convallis eleifend ut et est. Quisque elit lacus, gravida ac quam in, vestibulum
          consectetur massa. Vestibulum sollicitudin metus a sodales consequat. Suspendisse porta
          lobortis leo posuere imperdiet.
        </p>
      </AccordionComponent>
    </HeaderSearch>
  );
};

export default index;
