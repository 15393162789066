import {
  Box,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text
} from "@chakra-ui/react";
import ControlAnalysisContext from "context/ControlAnalysis";
import { useContext } from "react";
import { AiOutlineMinus } from "react-icons/ai";

export const StatWithIndicator = () => {
  const { riskLevelCount } = useContext(ControlAnalysisContext);
  return (
    <StatGroup
      borderRadius={"5"}
      border="0.01rem solid rgba(60, 64, 67, 0.3)"
      m="3"
      p="3"
    >
      <Stat>
        <StatNumber fontSize={"3xl"} color="gray.600">
          {riskLevelCount?.level < 9
            ? `0${riskLevelCount?.level}`
            : riskLevelCount?.level}
        </StatNumber>
        <StatHelpText>
          <StatLabel>Zonas Francas </StatLabel>
          <StatArrow type="increase" />
          Leve
        </StatHelpText>
      </Stat>

      <Stat>
        <StatNumber fontSize={"3xl"} color="gray.600">
          {riskLevelCount?.half < 9
            ? `0${riskLevelCount?.half}`
            : riskLevelCount?.half}
        </StatNumber>
        <StatHelpText>
          <StatLabel>Zonas Francas </StatLabel>
          <Box display={"flex"} gap={1} flexDir={"row"}>
            <AiOutlineMinus color={"yellow"} />
            Medio
          </Box>
        </StatHelpText>
      </Stat>

      <Stat>
        <StatNumber fontSize={"3xl"} color="gray.600">
          {riskLevelCount?.high < 9
            ? `0${riskLevelCount?.high}`
            : riskLevelCount?.high}
        </StatNumber>
        <StatHelpText>
          <StatLabel>Zonas Francas </StatLabel>
          <StatArrow type="decrease" />
          Alto
        </StatHelpText>
      </Stat>
    </StatGroup>
  );
};
