import { Td, Tr } from "@chakra-ui/react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useContext } from "react";

const TbodyReference = ({ data, onCloseModal }) => {
  const { setDataReference } = useContext(IntegracionBoxCrudContex);

  const handleSearchReference = () => {
    setDataReference(data);
    onCloseModal();
  };
  return (
    <Tr onClick={handleSearchReference}>
      <Td className="text-center">{data?.code_product}</Td>
      <Td className="text-center">{data?.reference}</Td>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Td className="text-center">{data?.description_product}</Td>
      <Td className="text-center">{data.integration_certificate}</Td>
      <Td className="text-center">{data?.import_declaration}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.code_operation_inicial}</Td>
      <Td className="text-center">{data?.code_tradeAgreement}</Td>
      <Td className="text-center">{data?.quantity_available}</Td>
      <Td className="text-center">{data?.business_unit}</Td>
      <Td className="text-center">{data?.quantity_available_product}</Td>
      <Td className="text-center">{data?.business_unit_product}</Td>
    </Tr>
  );
};

export default TbodyReference;
