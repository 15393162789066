import React, { useState } from "react";
import {
  ScaleFade,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text
} from "@chakra-ui/react";
import { useModal } from "hooks/useModal";
import { __BusineeUnitsFil } from "request/configuration/__TradeAgreement";
import AlertError from "components/Alerts/AlertError";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabeFormlStyled } from "styled/Input.styled";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const ImportarUndComercial = () => {
  const [file, setFile] = useState(null);
  const [isOpen, closeModal] = useModal();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const handleCloseAlert = () => {
    closeModal();
    setAlertMessage("");
  };

  const handleAddItem = async () => {
    if (!file) {
      openAlert({
        title: "Error",
        description: "Por favor, selecciona un archivo.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await __BusineeUnitsFil(token, formData);
      if (res.data.status.code === 200) {
        openAlert({
          title: "Éxito",
          description: "Archivo cargado exitosamente.",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      } else {
        openAlert({
          title: "Error",
          description: "No se pudo cargar el archivo.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Box p={4}>
      <FormControl>
        <FormLabel htmlFor="fileSelect">Importar Unidad comercial</FormLabel>

        <Flex alignItems="center">
          <Input
            onChange={handleFileChange}
            type="file"
            accept=".xlsx, .xls, .csv"
            id="fileSelect"
            display="none"
          />
          <label htmlFor="fileSelect">
            <Button as="span" disabled={loading}>
              Seleccionar archivo
            </Button>
          </label>
          {file && (
            <Flex alignItems="center" ml={4}>
              <Text mr={2}>{file.name}</Text>
              <Button
                size="sm"
                onClick={() => setFile(null)}
                disabled={loading}
              >
                Eliminar
              </Button>
            </Flex>
          )}
          <Button
            onClick={handleAddItem}
            colorScheme="blue"
            disabled={!file || loading}
            isLoading={loading}
            ml={4}
          >
            Subir
          </Button>
        </Flex>
      </FormControl>
      <ScaleFade in={isOpen}>
        <AlertError alertMessage={alertMessage} closeAlert={handleCloseAlert} />
      </ScaleFade>
    </Box>
  );
};

export default ImportarUndComercial;
