import { Box, Button, Icon, TabPanel } from "@chakra-ui/react";
import TableOrder from "components/Tables/TableOrder";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import {
  arrayMatteriaEdit,
  arrayMatteriaNotEdit,
  arrayMatteriaNotEditCorrected,
  items,
  theadDesperdicios,
  theadElaborated,
  theadElaboratedNotEdit,
  theadMateria,
  theadMateriaIsCommerce,
  theadMateriaNotEditable,
  theadMateriaNotEditableIsCommerce,
  theadOther,
  theadOtherEdit,
} from "pages/IntegrationBox/Arrays/ArrayTitles";
import { TotalsCalculated } from "pages/IntegrationBox/BoxCalc/TotalsCalculated";
import TBodyGarbage from "pages/IntegrationBox/Table/TbodyDesperdicio";
import TbodyElaborateProduct from "pages/IntegrationBox/Table/TbodyElaborateProduct";
import TbodyOtherCosts from "pages/IntegrationBox/Table/TbodyOtherCosts";
import TbodyRawMaterial from "pages/IntegrationBox/Table/TbodyRawMaterial";
import TbodySupplyUnit from "pages/IntegrationBox/Table/TbodySupplyUnit";
import TbodyUndCertificated from "pages/IntegrationBox/Table/TbodyUnitCertificated";
import { TabsCreateOrder } from "pages/ProductionOrder/TabsCreate/TabsCreateOrder";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BoxCalc } from "pages/IntegrationBox/BoxCalc/BoxCalc";
import ButtonsTable from "../ViewTableBox/ButtonsTable";

import { ButtonStyled } from "styled/Buttons.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { __PostForm1 } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import "./index.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MaxLength } from "utils/MaxLength";
import { useGlobalContext } from "context/GlobalContext";
import queryString from "query-string";
import queryEnv from "constants/queryConst";
import Pagination from "components/Pagination/Pagination";
import InputSelect from "components/InputSelect/InputSelect";
import { FaCheck, FaTimes } from "react-icons/fa";

const CustomButton = ({ isActive, onClick, children }) => {
  return (
    <Button
      leftIcon={
        <Icon
          as={isActive ? FaCheck : FaTimes}
          color={isActive ? "green.400" : "red.400"}
        />
      }
      onClick={onClick}
      bg={isActive ? "blue.600" : "blue.400"}
      _hover={{ bg: isActive ? "blue.700" : "blue.500" }}
      color="white"
      width="180px"
      height="30px"
      marginBottom="4px"
      transition="all 0.3s ease"
    >
      {children}
    </Button>
  );
};

const OptionPanelTabs = () => {
  const location = useLocation();
  const [positionView, setPositionView] = useState(0);
  const [cambio, setCambio] = useState(0);
  const { pathname } = location;
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const { ciModuleDynamic } = useSelector((state) => state?.form) || {};

  const { requestData, isOpen, setIsOpen } = useGlobalContext();
  const [ciProductsPage, setCiProductsPage] = useState(1);
  const [ciSuppliesPage, setCiSuppliesPage] = useState(1);
  const [ciOtherCostPage, setCiOtherCostPage] = useState(1);
  const [ciGarbagePage, setCiGarbagePage] = useState(1);

  const queryClient = useQueryClient();
  const foundMutation = queryClient.getQueryData([queryEnv?.INTEGRATION_BOX]);
  const [supplyFilter, setSupplyFilter] = useState(null);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const resetPages = () => {
    setCiProductsPage(1);
    setCiSuppliesPage(1);
    setCiOtherCostPage(1);
    setCiGarbagePage(1);
  };

  useEffect(() => {
    if (!supplyFilter) return;
    setCiSuppliesPage(1);
  }, [supplyFilter]);

  const {
    currentPage2,
    setCurrentPage2,
    loading,
  } = useContext(IntegracionBoxCrudContex);

  const handleCambio = (index) => {
    setCambio(index);
    setPositionView(index);
    localStorage.setItem("indexTabs", JSON.stringify(index));
  };

  const handleOpenDatosInsumo = () => {
    setIsOpen((prev) => !prev);
  };

  const indexTabs = JSON.parse(localStorage.getItem("indexTabs"));

  useEffect(() => {
    if (indexTabs) {
      setPositionView(indexTabs);
    }
  }, [indexTabs]);

  /**
   * Represents a book.
   * @constructor
   * @param {string} is_qualified_user - is on auth.dataUser and 1 is true and 0 is false.
   */

  const THead = {
    "/ShowIntegrationBox": () => {
      const dispatchThead = {
        0: theadMateriaNotEditableIsCommerce,
        1: theadMateriaNotEditable,
      };
      return dispatchThead[is_qualified_user];
    },
    Default: () => {
      const defaultThead = {
        0: theadMateriaIsCommerce,
        1: theadMateria,
      };
      return defaultThead[is_qualified_user];
    },
  };

  const { data: ciProducts, isLoading: isLoadingCiProducts } = useQuery({
    queryKey: [queryEnv?.PRODUCTS_CI, ciProductsPage, idCorrected, id, orderBy],
    queryFn: () =>
      (idCorrected || id) &&
      requestData({
        Endpoint: idCorrected
          ? environment.GET_PRODUCTS_CI_CORRECTED
          : environment.GET_PRODUCTS_CI,
        resourceIdentifier: idCorrected ? idCorrected : id,
        pageParam: ciProductsPage,
        data: {
          orderBy: orderBy.value,
        },
        method: "POST",
      }),
  });

  const { data: ciSupplies, isLoading: isLoadingCiSupplies } = useQuery({
    queryKey: [
      queryEnv?.SUPPLIES_CI,
      ciSuppliesPage,
      idCorrected,
      id,
      orderBy,
      supplyFilter,
    ],
    queryFn: () =>
      (idCorrected || id) &&
      requestData({
        pageParam: ciSuppliesPage,
        data: {
          orderBy: orderBy.value,
        },
        Endpoint: idCorrected
          ? environment.GET_SUPPLYS_CI_CORRECTED
          : environment.GET_SUPPLYS_CI,
        resourceIdentifier: `${idCorrected ? idCorrected : id}${
          supplyFilter ? `/${supplyFilter}` : ""
        }`,
        method: "POST",
      }),
  });

  const { data: ciOtherCost, isLoading: isLoadingCiOtherCost } = useQuery({
    queryKey: [
      queryEnv?.OTHER_COST_CI,
      ciOtherCostPage,
      idCorrected,
      id,
      orderBy,
    ],
    queryFn: () =>
      (idCorrected || id) &&
      requestData({
        Endpoint: idCorrected
          ? environment.GET_OTHER_COST_CI_CORRECTED
          : environment.GET_OTHER_COST_CI,
        resourceIdentifier: idCorrected ? idCorrected : id,
        pageParam: ciOtherCostPage,
        data: {
          orderBy: orderBy.value,
        },
        method: "POST",
      }),
  });

  const { data: ciGarbage, isLoading: isLoadingCiGarbage } = useQuery({
    queryKey: [queryEnv?.GARBAGE_CI, ciGarbagePage, idCorrected, id, orderBy],
    queryFn: () =>
      (idCorrected || id) &&
      requestData({
        Endpoint: idCorrected
          ? environment.GET_GARBAGES_CI_CORRECTED
          : environment.GET_GARBAGES_CI,
        resourceIdentifier: idCorrected ? idCorrected : id,
        pageParam: ciGarbagePage,
        data: {
          orderBy: orderBy.value,
        },
        method: "POST",
      }),
  });

  const tabsCI = [...items];

  if (
    ciModuleDynamic?.nameModul === "Asociación de Materias primas e Insumos"
  ) {
    tabsCI.splice(0, 1, "Descripción de mercancía");
  }

  return (
    <>
      {pathname !== "/ShowIntegrationBox" &&
        pathname !== "/CheckIntegrationBox" &&
        pathname !== "/IntegrationCertificateReview" && (
          <ButtonsTable positionView={positionView} />
        )}

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        placeContent={"flex-end"}
      >
        <InputSelect
          data={[
            { value: "ASC", label: "Ascendente" },
            { value: "DESC", label: "Descendente" },
          ]}
          value={orderBy}
          name="order_by"
          set={(e) => {
            setOrderBy(e);
            resetPages();
          }}
          placeholder="Ordenar por"
          inputAttrs={{
            color: "#5f5f",
          }}
          size={20}
          isClearable={false}
        />
      </Box>

      {/* Producto */}
      <TabsCreateOrder head={tabsCI} change={handleCambio}>
        <TabPanel>
          <TableOrder
            thead={
              pathname === "/ShowIntegrationBox"
                ? theadElaboratedNotEdit
                : theadElaborated
            }
            data={
              ciProducts?.data?.status?.code === 200 && !isLoadingCiProducts
                ? ciProducts?.data?.status?.data?.data
                : []
            }
            setData={setCurrentPage2}
            loading={loading}
            tbodyData={TbodyElaborateProduct}
            subTotals={
              ciProducts?.data?.status?.code === 200
                ? [
                    ciProducts?.data?.status?.data?.total_product_net_weight,
                    ciProducts?.data?.status?.data?.total_product_fob,
                    "",
                    "",
                  ]
                : []
            }
            titleTotal="Total"
            td={[0, 1, 2, 3, 4, 5, 6]}
            tUnid={
              ciProducts?.data?.status?.code === 200 &&
              ciProducts?.data?.status?.data?.totalProdQuantity
            }
            tbodyUnd={TbodyUndCertificated}
          />
          <Pagination
            page={ciProductsPage}
            setPage={setCiProductsPage}
            maxPage={
              ciProducts?.data?.status?.code === 200 &&
              (ciProducts?.data?.status?.data?.last_page || 1)
            }
            loading={isLoadingCiProducts}
          />
        </TabPanel>

        <TabPanel>
          {/* Materia prima (CAMBIAR) */}
          {/* PREGUNTAR A KETY */}
          {(foundMutation?.status || foundMutation?.newData?.status) ===
            "PRESENTADO" && (
            <TotalsCalculated
              data={
                ciSupplies?.data?.status?.code === 200 && !isLoadingCiSupplies
                  ? ciSupplies?.data?.status?.data
                  : null
              }
              supplyFilter={supplyFilter}
              setSupplyFilter={setSupplyFilter}
            />
          )}

          <div>
            <TableOrder
              thead={THead[pathname]?.() || THead.Default?.()}
              data={
                ciSupplies?.data?.status?.code === 200 && !isLoadingCiSupplies
                  ? ciSupplies?.data?.status?.data?.data
                  : []
              }
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TbodyRawMaterial}
              subTotals={
                ciSupplies?.data?.status?.code === 200
                  ? [
                      ciSupplies?.data?.status?.data?.total_supply_net_weight,
                      ciSupplies?.data?.status?.data?.total_supply_fob,
                      "",
                      "",
                      "",
                      "",
                      "",
                    ]
                  : []
              }
              titleTotal="Total"
              td={
                pathname === "/ShowIntegrationBox"
                  ? arrayMatteriaNotEdit
                  : pathname === "/CorrectedCertificate"
                  ? arrayMatteriaNotEditCorrected
                  : arrayMatteriaEdit
              }
              tUnid={
                ciSupplies?.data?.status?.code === 200 &&
                ciSupplies?.data?.status?.data?.totalSupplies
              }
              tbodyUnd={TbodySupplyUnit}
            />
            <Pagination
              page={ciSuppliesPage}
              setPage={setCiSuppliesPage}
              maxPage={
                ciSupplies?.data?.status?.code === 200 &&
                (ciSupplies?.data?.status?.data?.last_page || 1)
              }
              loading={isLoadingCiProducts}
            />
            <div className="boton">
     
              <CustomButton isActive={isOpen} onClick={handleOpenDatosInsumo}>
                Datos insumo
              </CustomButton>
            </div>

          
          </div>
        </TabPanel>
        {/* Otros costos */}
        <TabPanel>
          <>
            <TableOrder
              thead={
                pathname === "/ShowIntegrationBox" ? theadOtherEdit : theadOther
              }
              data={
                ciOtherCost?.data?.status?.code === 200 && !isLoadingCiOtherCost
                  ? ciOtherCost?.data?.status?.data?.data
                  : []
              }
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TbodyOtherCosts}
              subTotals={
                ciOtherCost?.data?.status?.code === 200
                  ? [ciOtherCost?.data?.status?.data?.totalOtherNetWeight, ""]
                  : []
              }
              titleTotal="Total"
              td={[2, 5]}
            />
          </>
          <Pagination
            page={ciOtherCostPage}
            setPage={setCiOtherCostPage}
            maxPage={
              ciOtherCost?.data?.status?.code === 200 &&
              (ciOtherCost?.data?.status?.data?.last_page || 1)
            }
            loading={isLoadingCiProducts}
          />
        </TabPanel>
        {/* Desperdicios */}
        <TabPanel>
          <TableOrder
            thead={theadDesperdicios}
            data={
              ciGarbage?.data?.status?.code === 200 && !isLoadingCiGarbage
                ? ciGarbage?.data?.status?.data?.data
                : []
            }
            loading={loading}
            tbodyData={TBodyGarbage}
          />
          <Pagination
            page={ciGarbagePage}
            setPage={setCiGarbagePage}
            maxPage={
              ciGarbage?.data?.status?.code === 200 &&
              (ciGarbage?.data?.status?.data?.last_page || 1)
            }
            loading={isLoadingCiProducts}
          />
        </TabPanel>
        {/* CAMBIAR */}
        {cambio === 1 && foundMutation?.status === "PRESENTADO" && (
          <BoxCalc
            Data={currentPage2?.supplies ? currentPage2?.supplies : []}
          />
        )}
      </TabsCreateOrder>
    </>
  );
};

export default OptionPanelTabs;
