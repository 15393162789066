import { Td, Tr } from "@chakra-ui/react";
import React from "react";

const TbodyLock = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.status}</Td>
      <Td style={{ textAlign: "justify" }}>{data?.description}</Td>
    </Tr>
  );
};

export default TbodyLock;
