import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import TreasuryCrudContex from "context/TreasuryContex";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import environment from "constants/apiConst";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { __DocumentTypeNoPaginate } from "request/configuration/__DocumentRequire";
import InputSelect from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { formatedInput } from "components/Funciones/FunctionFormateInput";
import { __PostForm } from "request/Petitions/__Post";
import { NumericFormat } from "react-number-format";

const FormUpdate = () => {
  const { dataToEdit, setDataToEdit, setRequestEdit, editData } =
    useContext(TreasuryCrudContex);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataIntpus, setDataInputs] = useState([]);
  const [dataInputsValues, setDataInputsValue] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataQualified, setdataQuialified] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [type, setType] = useState("");
  const [searchParams] = useSearchParams();

  const [values, handleInputChange, reset, setValues] = useForm({
    date_rate: "",
    typeRate_id: ""
  });

  const { date_rate, typeRate_id } = values;


  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const dataUser = res?.data?.status?.data.map((items) => ({
        value: items.id,
        label: items?.nit_qualified + " - " + items?.name_qualified
      }));
      setdataQuialified(dataUser);
    } catch (error) { }
  }, [token]);

  useEffect(() => {
    getUserQualified();
  }, [getUserQualified]);

  const getUpdateRatesEdit = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("rate_id", searchParams.get("NumberRate"));
    try {
      const res = await __PostForm(
        environment.TREASURY_UPDATE_RATE_GET,
        token,
        formData
      );
      const typeRate = {
        1: "Simple",
        2: "Extra",
      }[res?.data?.status?.data?.typeRate_id] || "Especial";

      const data = res?.data?.status?.data;
      setDataInputs(data?.data_set);
      setValues({
        ...data,
        typeRate_id: typeRate
      });
      setType(data?.typeRate_id);
      setUserValue({
        value: data?.qualifiedUser_id,
        label: data?.nit_qualified + " " + data?.name_qualified
      });

    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const newArray = {};
    dataIntpus && Object.entries(dataIntpus)?.forEach(([key, value], index) => {
      value.forEach((item, itemi) => {
        if (!item?.id) return
        console.log({ item })
        const nameKey = item?.document?.includes("Anexo") ? "annexe" : "document_type_id";
        newArray[item?.id] = {
          [nameKey]: item?.id,
          value: item?.value
        }
      })
    })
    setDataInputsValue(newArray);
  }, [dataIntpus]);


  useEffect(() => {
    if (searchParams.get("NumberRate")) {
      getUpdateRatesEdit();
      return;
    }
  }, [searchParams.get("NumberRate"), getUpdateRatesEdit]);

  useEffect(() => {
    setRequestEdit(environment.TREASURY_UPDATE_RATE_UPDATE);
  }, [setRequestEdit]);

  const handleCancel = () => {
    navigate(`/ActualizacionDeTarifa?movement=Actualización de tarifas`);
    setDataToEdit(null);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const dataInputsFormted = Object.entries(dataInputsValues)?.map(([_, value]) => ({
      ...value,
    }))

    const requestData = {
      id: searchParams.get("NumberRate"),
      date_rate,
      qualifiedUser_id: userValue !== null ? userValue.value : "",
      rates: dataInputsFormted
    };

    editData(requestData);
    navigate(`/ActualizacionDeTarifa?movement=Actualización de tarifas`);
    handleReset();
  };

  const handleReset = () => {
    reset();
    setDataInputsValue({});
  };

  const handleOnchange = (event, id) => {
    setDataInputsValue((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        value: event?.value,
      }
    }))
  };

  const calculateFields = () => {
    const data = dataIntpus && Object.entries(dataIntpus)?.map(([key, value], index) => {
      return <>
        {value.map((item, itemi) => {
          if (!item?.id) return <div></div>
          return (
            <NumericFormat
              customInput={LabelGroup}
              key={item?.id}
              allowNegative={false}
              decimalScale={0}
              thousandSeparator={","}
              tipo={"text"}
              id={item?.id}
              name={item?.document}
              tag={item?.document}
              value={dataInputsValues[item?.id]?.value}
              onValueChange={(e) => {
                handleOnchange(e, item?.id, item?.document)
              }}
              desicion={false}
              required={true}
            />
          )
        })}
      </>
    })
    return data;

  }

  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <form>
          <Grid
            templateColumns="repeat(2, 2fr)"
            gap={6}
            spacing="10px"
            className="container"
          >
            <LabelGroup
              tipo={"date"}
              name={"date_rate"}
              tag={"Fecha"}
              size={100}
              value={date_rate}
              onChange={handleInputChange}
              desicion={false}
            />

            <LabelGroup
              tipo={"text"}
              name={"typeRate_id"}
              tag={"Tipo de tarifa"}
              size={100}
              value={typeRate_id}
              onChange={handleInputChange}
              desicion={true}
            />

            {type && type === 3 && (
              <>
                <InputSelect
                  tag={"Usuario"}
                  size={100}
                  data={dataQualified}
                  set={setUserValue}
                  value={userValue}
                />
                <div></div>
              </>
            )}

            {calculateFields()}
            <Box
              gridColumnStart={1}
              gridColumnEnd={3}
              gap={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap={"wrap"}
            >
              <ButtonStyled mr={"0"} bgColor={"#bababa"} onClick={handleCancel}>
                Cerrar
              </ButtonStyled>
              <ButtonStyled mr={"0"} onClick={handleEdit}>Editar</ButtonStyled>
            </Box>

          </Grid>


        </form>
      )}
    </>
  );
};

export default FormUpdate;
