// inicio array inventario

import environment from "constants/apiConst";

export const arrayLeftInventory = [
  {
    value: "quantity_temporary",
    label: "Sdo. en Temp.",
    name: "quantity_temporary",
  },
  {
    value: "name_typeProduct",
    label: "Tipo de producto",
    name: "name_typeProduct",
  },

  {
    value: "quantity_free_zone",
    label: "Sdo. en Bodega",
    name: "quantity_free_zone",
  },

  {
    value: "code_businessUnit",
    label: "Unidad",
    name: "code_businessUnit",
  },

  {
    value: "quantity_available_product",
    label: "Saldo Producto",
    name: "quantity_available_product",
  },

  {
    value: "quantity_go_out_product",
    label: "Sdo. Producto Aut. Salir",
    name: "quantity_go_out_product",
  },

  {
    value: "quantity_reserved_product",
    label: "Sdo. Producto rsvd",
    name: "quantity_reserved_product",
  },

  {
    value: "quantity_temporary_product",
    label: "Sdo. Producto en Temp",
    name: "quantity_temporary_product",
  },

  {
    value: "quantity_free_zone_product",
    label: "Sdo. Producto en Bodega",
    name: "quantity_free_zone_product",
  },

  {
    value: "code_product",
    label: "Código producto",
    name: "code_product",
  },
  {
    value: "nature_of_inventory",
    label: "Naturaleza",
    name: "nature_of_inventory",
  },
  {
    value: "quantity_total",
    label: "Cantidad total",
    name: "quantity_total",
  },
  {
    value: "quantity_total_product",
    label: "Cantidad total producto",
    name: "quantity_total_product",
  },

  {
    value: "tracking_indicator",
    label: "Indicador de seguimiento",
    name: "tracking_indicator",
  },
];

export const arrayRigthInventory = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    search: "qualifiedUser_id",
  },
  {
    value: "description_product",
    label: "Descripción",
    name: "description_product",
  },
  { value: "group_product", label: "Grupo producto", name: "group_product" },
  {
    value: "reference_description",
    label: "Referencia",
    name: "reference_description",
  },
  {
    value: "code_subheading",
    label: "Subpartida",
    name: "code_subheading",
  },
  {
    value: "integration_certificate",
    label: "Certificado",
    name: "integration_certificate",
  },
  {
    value: "customs_clearance",
    label: "Desaduanamiento",
    name: "customs_clearance",
  },
  { value: "operation_code", label: "Operación", name: "operation_code" },
  {
    value: "initial_operation",
    label: "Operación inicial",
    name: "initial_operation",
  },
  {
    value: "trade_agreement",
    label: "Acuerdo",
    name: "trade_agreement",
  },
  {
    value: "quantity_available",
    label: "Saldo",
    name: "quantity_available",
  },

  {
    value: "quantity_go_out",
    label: "Sdo. Aut. Salir.",
    name: "quantity_go_out",
  },
  {
    value: "quantity_reserved",
    label: "Sdo. rsvd.",
    name: "quantity_reserved",
  },
  {
    value: "value_fob_unit_product",
    label: "Valor fob unitario producto",
    name: "value_fob_unit_product",
  },
  {
    value: "value_cif_unit_product",
    label: "Valor cif unitario producto",
    name: "value_cif_unit_product",
  },
];

export const arrayRigthUserInventory = [
  {
    value: "description_product",
    label: "Descripción",
    name: "description_product",
  },
  { value: "group_product", label: "Grupo producto", name: "group_product" },
  {
    value: "reference_description",
    label: "Referencia",
    name: "reference_description",
  },
  {
    value: "code_subheading",
    label: "Subpartida",
    name: "code_subheading",
  },
  {
    value: "integration_certificate",
    label: "Certificado",
    name: "integration_certificate",
  },
  {
    value: "customs_clearance",
    label: "Desaduanamiento",
    name: "customs_clearance",
  },
  { value: "operation_code", label: "Operación", name: "operation_code" },
  {
    value: "initial_operation",
    label: "Operación inicial",
    name: "initial_operation",
  },
  {
    value: "trade_agreement",
    label: "Acuerdo",
    name: "trade_agreement",
  },
  {
    value: "quantity_available",
    label: "Saldo",
    name: "quantity_available",
  },

  {
    value: "quantity_go_out",
    label: "Sdo. Aut. Salir.",
    name: "quantity_go_out",
  },
  {
    value: "quantity_reserved",
    label: "Sdo. rsvd.",
    name: "quantity_reserved",
  },
  {
    value: "quantity_reserved",
    label: "Sdo. rsvd.",
    name: "quantity_reserved",
  },
  {
    value: "value_fob_unit_product",
    label: "Valor fob unitario producto",
    name: "value_fob_unit_product",
  },
  {
    value: "value_cif_unit_product",
    label: "Valor cif unitario producto",
    name: "value_cif_unit_product",
  },
];

// fin array inventario

// inicio array Auxiliar de referencia

export const auxRefNodeList = [
  {
    value: "quantity_balance_product",
    label: "Código producto disponible",
    name: "quantity_balance_product",
  },

  {
    value: "code_unit_measurement",
    label: "Código producto",
    name: "code_unit_measurement",
  },

  {
    value: "input_quantity_product",
    label: "Cantidad producto ingresado",
    name: "input_quantity_product",
  },

  {
    value: "output_quantity_product",
    label: "Cantidad producto sacada",
    name: "output_quantity_product",
  },

  {
    value: "quantity_balance",
    label: "Cantidad disponible",
    name: "quantity_balance",
  },

  {
    value: "business_unit",
    label: "Unidad",
    name: "business_unit",
  },

  {
    value: "input_quantity",
    label: "Cantidad ingresada",
    name: "input_quantity",
  },

  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },

  {
    value: "input_weight",
    label: "Peso ingresado",
    name: "input_weight",
  },
];

export const arrayLeftAuxRef = [
  {
    value: "quantity_balance_product",
    label: "Código producto disponible",
    name: "quantity_balance_product",
  },

  {
    value: "code_unit_measurement",
    label: "Código producto",
    name: "code_unit_measurement",
  },

  {
    value: "input_quantity_product",
    label: "Cantidad producto ingresado",
    name: "input_quantity_product",
  },

  {
    value: "output_quantity_product",
    label: "Cantidad producto sacada",
    name: "output_quantity_product",
  },

  {
    value: "quantity_balance",
    label: "Cantidad disponible",
    name: "quantity_balance",
  },

  {
    value: "business_unit",
    label: "Unidad",
    name: "business_unit",
  },

  {
    value: "input_quantity",
    label: "Cantidad ingresada",
    name: "input_quantity",
  },

  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },

  {
    value: "input_weight",
    label: "Peso ingresado",
    name: "input_weight",
  },
];

export const arrayRigthAuxRef = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified",
    search: "qualifiedUser_id"
  },

  {
    value: "output_weight",
    label: "Peso sacado",
    name: "output_weight",
  },

  {
    value: "input_value",
    label: "Valor ingresado",
    name: "input_value",
  },

  {
    value: "output_value",
    label: "Valor sacado",
    name: "output_value",
  },

  {
    value: "import_declaration",
    label: "Dcl. Imp.",
    name: "import_declaration",
  },

  {
    value: "form_code",
    label: "Código formulario",
    name: "form_code",
  },

  {
    value: "operation_type",
    label: "Tipo de operación",
    name: "operation_type",
  },

  {
    value: "created_at",
    label: "Creado",
    name: "created_at",
  },
];

export const arrayRigthUserAuxRef = [
  {
    value: "output_weight",
    label: "Peso sacado",
    name: "output_weight",
  },

  {
    value: "input_value",
    label: "Valor ingresado",
    name: "input_value",
  },

  {
    value: "output_value",
    label: "Valor sacado",
    name: "output_value",
  },

  {
    value: "import_declaration",
    label: "Dcl. Imp.",
    name: "import_declaration",
  },

  {
    value: "form_code",
    label: "Código formulario",
    name: "form_code",
  },

  {
    value: "operation_type",
    label: "Tipo de operación",
    name: "operation_type",
  },

  {
    value: "created_at",
    label: "Creado",
    name: "created_at",
  },
];

// fin array Auxiliar de referencia

// inicio array Auxiliar de salida

export const arrayLeftAuxExit = [
  {
    value: "created_at",
    label: "Creado",
    name: "created_at",
  },
  {
    value: "time",
    label: "Tiempo",
    name: "time",
  },
  {
    value: "type_document_ref",
    label: "Tipo. Doc. Ref",
    name: "type_document_ref",
  },
  {
    value: "authorization_reference",
    label: "Referencia",
    name: "authorization_reference",
  },
  {
    value: "item_code",
    label: "Item",
    name: "item_code",
  },
  {
    value: "type_document",
    label: "Tipo documento",
    name: "type_document",
  },
  {
    value: "authorization",
    label: "Autorización",
    name: "authorization",
  },
  {
    value: "input_quantity",
    label: "Cantidad ingresada",
    name: "input_quantity",
  },
];

export const arrayRigthAuxExit = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified",
    search: "qualifiedUser_id"
  },

  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },
  {
    value: "inventory_quantity_available",
    label: "Cantidad inventario disponible",
    name: "inventory_quantity_available",
  },
  {
    value: "input_quantity_product",
    label: "Cantidad producto ingresado",
    name: "input_quantity_product",
  },

  {
    value: "output_quantity_product",
    label: "Cantidad producto sacada",
    name: "output_quantity_product",
  },

  {
    value: "inventory_quantity_available_product",
    label: "Cantidad en inventario de producto disponible",
    name: "inventory_quantity_available_product",
  },
  {
    value: "code_unit_measurement",
    label: "Código producto",
    name: "code_unit_measurement",
  },
  {
    value: "quantity_balance_product",
    label: "Código producto disponible",
    name: "quantity_balance_product",
  },
];

export const arrayRigthUserAuxExit = [
  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },
  {
    value: "inventory_quantity_available",
    label: "Cantidad inventario disponible",
    name: "inventory_quantity_available",
  },
  {
    value: "input_quantity_product",
    label: "Cantidad producto ingresado",
    name: "input_quantity_product",
  },

  {
    value: "output_quantity_product",
    label: "Cantidad producto sacada",
    name: "output_quantity_product",
  },

  {
    value: "inventory_quantity_available_product",
    label: "Cantidad en inventario de producto disponible",
    name: "inventory_quantity_available_product",
  },
  {
    value: "code_unit_measurement",
    label: "Código producto",
    name: "code_unit_measurement",
  },
  {
    value: "quantity_balance_product",
    label: "Código producto disponible",
    name: "quantity_balance_product",
  },
];

// fin array Auxiliar de Salida

// inicio array Auxiliar de salida

export const arrayLeftAuxTemp = [
  {
    value: "created_at",
    label: "Creado",
    name: "created_at",
  },

  {
    value: "time",
    label: "Tiempo",
    name: "time",
  },

  {
    value: "type_document_ref",
    label: "Tipo. Doc. Ref",
    name: "type_document_ref",
  },

  {
    value: "authorization_reference",
    label: "Referencia",
    name: "authorization_reference",
  },

  {
    value: "item_code",
    label: "Item",
    name: "item_code",
  },

  {
    value: "type_document",
    label: "Tipo documento",
    name: "type_document",
  },
];

export const arrayRigthUserAuxTemp = [
  {
    value: "authorization",
    label: "Autorización",
    name: "authorization",
  },

  {
    value: "input_quantity",
    label: "Cantidad ingresada",
    name: "input_quantity",
  },

  {
    value: "business_unit",
    label: "Unidad",
    name: "business_unit",
  },

  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },

  {
    value: "inventory_quantity_available",
    label: "Cantidad inventario disponible",
    name: "inventory_quantity_available",
  },
];

export const arrayRigthAuxTemp = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified",
    search: "qualifiedUser_id"
  },

  {
    value: "authorization",
    label: "Autorización",
    name: "authorization",
  },

  {
    value: "input_quantity",
    label: "Cantidad ingresada",
    name: "input_quantity",
  },

  {
    value: "business_unit",
    label: "Unidad",
    name: "business_unit",
  },

  {
    value: "output_quantity",
    label: "Cantidad sacada",
    name: "output_quantity",
  },

  {
    value: "inventory_quantity_available",
    label: "Cantidad inventario disponible",
    name: "inventory_quantity_available",
  },
];

// fin array Auxiliar de Salida

// inicio array Referencia producto

export const arrayLeftRefProduct = [
  {
    value: "code_product",
    label: "Código producto",
    name: "code_product",
  },
  {
    value: "description_product",
    label: "Descripción",
    name: "description_product",
  },
  {
    value: "quantity_go_out_product",
    label: "Sdo. Producto Aut. Salir",
    name: "quantity_go_out_product",
  },
  {
    value: "quantity_temporary_product",
    label: "Sdo. Producto en Temp",
    name: "quantity_temporary_product",
  },
  {
    value: "quantity_available_product",
    label: "Saldo Producto",
    name: "quantity_available_product",
  },
  {
    value: "quantity_total_product",
    label: "Cantidad producto total",
    name: "quantity_total_product",
  },
  {
    value: "description_product",
    label: "Descripción producto",
    name: "description_product",
  },
  {
    value: "code_subheading",
    label: "Subpartida",
    name: "code_subheading",
  },
  { value: "group_product", label: "Grupo producto", name: "group_product" },
  {
    value: "business_unit",
    label: "Unidad del Producto",
    name: "business_unit",
  },
  {

    value: "status_product",
    alt: "status_product",
    label: "Estado del producto",
    search: "status_product",
    modal: {
      options: ["Activo ", "Inactivo"],
      value: [1],
    },
  },
  {
    value: "without_quantity",
    alt: "without_quantity",
    label: "Incluir sin saldo",
    search: "without_quantity",
    modal: {
      options: ["Si", "No"],
      value: "Si",
    },
  },
];

export const arrayRigthUserRefProduct = [
  {
    value: "quantity_available_product",
    label: "Saldo Producto",
    name: "quantity_available_product",
  },
  {
    value: "quantity_go_out_product",
    label: "Sdo. Producto Aut. Salir",
    name: "quantity_go_out_product",
  },

  {
    value: "quantity_temporal_product",
    label: "Cantidad producto temporal",
    name: "quantity_temporal_product",
  },

  {
    value: "quantity_total_product",
    label: "Cantidad producto total",
    name: "quantity_total_product",
  },
];

export const arrayRigthRefProduct = [
  {
    isQualifiedUser: true,
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified",
    parent: "name_qualified",
    search: "qualifiedUser_id"
  },

  {
    value: "quantity_available_product",
    label: "Saldo Producto",
    name: "quantity_available_product",
  },
  {
    value: "quantity_go_out_product",
    label: "Sdo. Producto Aut. Salir",
    name: "quantity_go_out_product",
  },

  {
    value: "quantity_temporal_product",
    label: "Cantidad producto temporal",
    name: "quantity_temporal_product",
  },

  {
    value: "quantity_total_product",
    label: "Cantidad producto total",
    name: "quantity_total_product",
  },
];

// fin array Referencia producto
export const inventoryNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "name_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]