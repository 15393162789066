/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Tooltip } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import InventoryControlContext from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetInventory,
} from "request/Inventory/__InventoryCRUD";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftInventory,
  arrayRigthInventory,
  arrayRigthUserInventory,
} from "./Array/ArrayExport";
import {
  theadInventory,
  theadInventoryUc,
  theadInventoryWithExport,
  theadInventoryWithExportUc,
} from "./Array/ArrayInventoryControl";
import { FormChangeSubheading } from "./ChangeData/FormChangeSubheading";
import { FormChangeValues } from "./ChangeData/FormChangeValues";
import { FormSearchInventory } from "./SearchTabInventory/FormSearchInventory";
// import { PrincipalTabInventory } from "./SearchTabInventory/PrincipalTabInventory";
import TbodyTable from "./TbodyTable/TbodyTable";
import TbodyTableUc from "./TbodyTable/TbodyTableUc";
import TbodyWithExport from "./TbodyTable/TbodyWithExport";
import TbodyWithExportUc from "./TbodyTable/TbodyWithExportUc";
import { useDebounce } from "hooks/useDebouce";
import GlobalContext from "context/GlobalContext";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { fmmFormNodeInputFields } from "pages/Entry_and_exit_forms/ArrayHeaders/ListItem";
import { productsNodeInputFields } from "pages/Treasury/ArrayList/ArrayList";
import isArrayNotEmpty, {
  isArray,
  isValid,
  safelyConvertToString,
} from "utils/type-check-utils";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useDispatch } from "react-redux";
import { useSearchUrl } from "hooks/useSearchUrl";

export const InventoryControl = () => {
  const { cleanState, clearParamsSearch } = useSearchUrl({
    getParams: [
      "reference",
      "description",
      "import_declaration",
      "business_unit_code",
      "group_product",
      "operation",
      "operation_initial",
      "subheading",
      "certificate",
      "minimum_quantity",
      "with_certificate",
      "with_declaration",
      "with_trade_agreement",
      "with_exporter",
      "without_quantity",
      "tracking_indicator",
      "freely_available_material",
      "orderby_type",
      "serial",
      "invoice",
      "trade_agreement",
      "nature_inventory",
      "orderby",
      "type_product_id",
      "valueProducts",
    ],
    nestedProp: true ? "searchAdvance" : null,
  });

  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const {
    isOpenChange,
    onOpenChange,
    onCloseChange,
    update,
    isOpenChangeKardex,
    onOpenChangeKardex,
    onCloseChangeKardex,
  } = useContext(InventoryControlContext);

  // hook de los Modal
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState || []);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
  });
  const { searchInformacion, valueQualified } = values;

  const { setUser_Qualified } = useContext(GlobalContext);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    setValues({ searchInformacion: "", valueQualified: [] });
    setSearchAdvanced([]);
    dispacthRedux(clearSearch());
    setChangeViewSearch(false);
    setCurrentPage([]);
  };

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value,
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id:
          data?.qualifiedUser_id[0]?.value ||
          data?.qualifiedUser_id?.value ||
          data?.qualifiedUser_id,
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || [],
      }),
      ...(data?.without_quantity && {
        without_quantity:
          data?.without_quantity?.value || data?.without_quantity,
      }),
      fields: formulario,
    };

    console.log({ data, info });

    exportColumnBy(info, onCloseExport);
  };

  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_INVENTORY
      );
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const GetInventory = async (body, page) => {
    setLoading(true);
    try {
      const res = await __GetInventory(token, page, body);
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (res?.data?.status?.data?.data?.length !== 0) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);

  const dispacthRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion, qa: 1 }),
      ...(valueQualified?.value && {
        qualifiedUser_id: valueQualified.value,
        reference: searcheAdvanced?.reference,
      }),
    };

    valueQualified?.value && setUser_Qualified(valueQualified?.value);

    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      GetInventory(data, page);
      dispacthRedux(setSearch(data));
    }
  }, [
    searcheAdvanced,
    page,
    valueQualified,
    update,
    freeZoneValue,
    searchInformacion,
  ]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    };
  }, []);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateArrayExportNodes = () => {
    if (authUser.is_qualified_user === 1) {
      return arrayLeftInventory.concat(arrayRigthUserInventory);
    }

    if (authUser.is_qualified_user !== 1) {
      return arrayLeftInventory.concat(arrayRigthInventory);
    }

    return [];
  };

  const hasSearchResults = !isArrayNotEmpty(searcheAdvanced);
  const isQualifiedUser = authUser.is_qualified_user === 1;
  const withoutExporter =
    searcheAdvanced.with_exporter === 0 ||
    !isValid(searcheAdvanced.with_exporter) ||
    searcheAdvanced.with_exporter === "";

  const withExporter = searcheAdvanced.with_exporter === 1;

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
        size={"4xl"}
        enableAdvance={true}
        nodeList={calculateArrayExportNodes()}
        selectNodeList={productsNodeInputFields}
        handleSubmit={handleSubmit}
      />

      <ModalGeneric
        isOpen={isOpenChange}
        onOpen={onOpenChange}
        onClose={onCloseChange}
        title="Cambiar subpartida"
      >
        <FormChangeSubheading onClose={onCloseChange} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenChangeKardex}
        onOpen={onOpenChangeKardex}
        onClose={onCloseChangeKardex}
        title="Cambiar valores"
      >
        <FormChangeValues onClose={onCloseChangeKardex} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormSearchInventory
          onClose={onCloseSearch}
          handleSearchSubmit={setSearchAdvanced}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Inventario</Title>
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          setValues={setValues}
          permisoSearch={"getInventory"}
          allAccess={allAccess}
          myPermission={myPermission}
        />

        {allAccess ? (
          <ExportDetail onOpen={onOpenExport} />
        ) : myPermission?.includes("exportExcelInventory") ? (
          <ExportDetail onOpen={onOpenExport} />
        ) : null}

        {/* theadInventoryUc
theadInventoryWithExportUc */}

        {isArrayNotEmpty(currentPage) && (
          <>
            <TableOrder
              thead={(() => {
                if (isQualifiedUser && hasSearchResults && withoutExporter) {
                  return theadInventoryUc;
                }
                if (!isQualifiedUser && hasSearchResults && withoutExporter) {
                  return theadInventory;
                }
                if (isQualifiedUser && hasSearchResults && withExporter) {
                  return theadInventoryWithExportUc;
                }
                if (!isQualifiedUser && hasSearchResults && withExporter) {
                  return theadInventoryWithExport;
                }
                return theadInventory;
              })()}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={(() => {
                if (isQualifiedUser && hasSearchResults && withoutExporter) {
                  return TbodyTableUc;
                }
                if (!isQualifiedUser && hasSearchResults && withoutExporter) {
                  return TbodyTable;
                }
                if (isQualifiedUser && hasSearchResults && withExporter) {
                  return TbodyWithExportUc;
                }
                if (!isQualifiedUser && hasSearchResults && withExporter) {
                  return TbodyWithExport;
                }
                return TbodyTable;
              })()}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </>
        )}
      </HeaderSearch>
    </>
  );
};
