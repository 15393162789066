import { USER_TYPES } from "redux/types/userType";

const setDataUser = (payload) => {
  return {
    type: USER_TYPES.STATEUPDATE,
    payload
  };
};

export { setDataUser };
