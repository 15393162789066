import useUpdateNotations from "hooks/useUpdateNotations";
import React, { createContext, useContext, useState } from "react";

const NotationsContext = createContext();

export const useNotations = () => useContext(NotationsContext);

export const NotationsProvider = ({ children }) => {
  const [formId, setFormId] = useState(null);
  const [reviewsData, setReviewsData] = useState({});
  const [dataCurrent, setDataCurrent] = useState({});

  const { updateNotation, updateNotationData, resetNotations } =
    useUpdateNotations({
      formId,
      reviewsData,
      dataCurrent
    });

  return (
    <NotationsContext.Provider
      value={{
        updateNotation,
        updateNotationData,
        formId,
        reviewsData,
        setFormId,
        setReviewsData,
        resetNotations,
        setDataCurrent
      }}
    >
      {children}
    </NotationsContext.Provider>
  );
};
