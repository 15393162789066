/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import OptionPanelTabs from "../View/OptionPanelCertificate";
import ModalsFormId from "../View/Modals/ModalsFormId";

const FormIdOrdeProduction = ({ ...props }) => {

  return (
    <>
      <ModalsFormId props={props} />
      {<OptionPanelTabs />}
    </>
  );
};

export default FormIdOrdeProduction;
