import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";

export const RestOfTheWorldOriginal = ({ data }) => {
  const {
    commercial_invoice_corrected,
    value_duta_corrected,
    commercial_invoice,
    value_duta,
    customAdministration_id,
    custom_code,
    custom_name
  } = data;

  const { opcionalCurrent, customsadministration } =
    useContext(FormCrudCRUDContext);

  const adminCustom = custom_code + " " + custom_name;

  return (
    <>
      <LabelGroup
        name={"commercial_invoice"}
        value={commercial_invoice}
        changeValue={
          commercial_invoice !== commercial_invoice_corrected && "BorderCorrect"
        }
        tag={"Factura Comercial"}
        size={100}
        desicion={true}
      />
      {opcionalCurrent && (
        <LabelGroup
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          name={"value_duta"}
          value={value_duta}
          tag={opcionalCurrent}
          size={100}
          desicion={true}
        />
      )}
      {data.code_operation >= 201 &&
        data.code_operation <= 299 &&
        opcionalCurrent && (
        <LabelGroup
          changeValue={
            adminCustom !== customsadministration?.label && "BorderCorrect"
          }
          value={customAdministration_id ? adminCustom : ""}
          tag={"Administración de Aduanas"}
          size={100}
          desicion={true}
        />
      )}
    </>
  );
};
