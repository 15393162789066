import { Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const InputsContainer = styled(Stack)`
  margin: ${(props) => props.margin || "1rem 0 1rem 0"};
  /* margin: 4rem 4rem 8rem; */
  /* text-align: right; */
  width: 100%;
`;

export const StyledLink = styled(Link)`
  font-size: 0.8em;
  text-decoration: none;
  color: ${(props) => props.theme.colors.mainAccent};
`;
