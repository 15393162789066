import { ModalGeneric } from "components/modal/ModalGeneric";
import {
  Checkbox,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import React, { useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useSelector } from "react-redux";
import { __Get } from "request/Petitions/__Get";
import { fieldTypes } from "./fieldTypes";
import { getSearch } from "redux/actions/searchActions";
import { isArray, isObject } from "utils/type-check-utils";
import { __Post } from "request/Petitions/__Post";


export const typeSelect = {
  single: "single",
  multi: "multi",
}

const ExportAdvance = ({
  onClose,
  enableAdvance,
  nodeList = [],
  subTitle,
  handleSubmit,
  defaultActive = [],
  selectNodeList = [],
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const search = useSelector(getSearch);
  const searchKeys = Object.keys(search);
  const [values, , resetValues, setValues] = useForm(nodeList?.reduce((acc, item) => {
    if (searchKeys?.includes(item?.value) || searchKeys?.includes(item?.name)) return {
      ...acc,
      [item?.name || item?.value]: search[item?.value] || search[item?.name]
    };
    return acc
  }, {}));

  const [form, , , setForm] = useForm({
    /* date_start: "",
    date_end: "", */
    exportType: 0,
  });

  const [enableChilds, , resetEnableChild, setEnableChilds] = useForm({
    ...nodeList?.reduce((acc, item) => {
      if (!item?.childs && !item?.childInputs) return acc;
      return {
        ...acc,
        [item?.value]: defaultActive.includes(item?.dependency),
      };
    }, {}),
    ...selectNodeList?.reduce((acc, item) => {
      /* console.log({ item, searchKeys, defaultActiveA: defaultActive.includes(item?.parent), defaultActiveB: searchKeys.includes(item?.parent), defaultActiveC: searchKeys.includes(item?.search) }) */
      if (!item?.parent && acc[item?.parent] === false) return acc;

      return {
        ...acc,
        [item?.parent]:
          defaultActive.includes(item?.parent) ||
          searchKeys.includes(item?.parent) ||
          searchKeys.includes(item?.search),
      };
    }, {}),
  });

  const calculateValueDataSelects = (child) => {
    if (child?.type === "select" && child?.typeSelect === typeSelect?.multi) {
      const filter = child?.data?.filter((element) => {
        if (search?.[child?.name])
          return search?.[child?.name]?.includes(element?.value);
        return search?.[child?.name] === element?.value;
      })
      if (filter?.length > 0) return filter
    }
    if (child?.type === "select" && child?.typeSelect === typeSelect?.single) {
      const filter = child?.data?.find((element) => {
        if (isObject(search?.[child?.name]) && search?.[child?.name])
          return search?.[child?.name]?.includes(element?.value);
        return search?.[child?.name] === element?.value;
      })

      if (filter !== null && filter !== undefined) return filter
    }
    if (search?.[child?.name] !== null && search?.[child?.name] !== undefined) return search?.[child?.name]
    return child?.value
  }

  const inputTypes = ["date", "number", "text"];

  const [dataSelects, setDataSelects] = useState(
    selectNodeList.reduce((acc2, child) => {
      if (!child?.request && !child?.data && !inputTypes.includes(child?.type)) {
        return acc2;
      }

      const value = calculateValueDataSelects(child);
      if (child?.data) {
        return [
          ...acc2,
          {
            ...child,
            value,
            isLoading: false,
            data: child?.data,
          },
        ];
      }
      return [
        ...acc2,
        {
          ...child,
          value:
            search?.[child?.name] ||
            search?.[child?.search] ||
            child?.value ||
            null,
          isLoading: child?.request ? true : false,
          data: null,
        },
      ];
    }, [])
  );

  const handlePreview = () => {
    const data = {
      type_export: form?.exportType || 0,
      ...(!(form?.exportType === undefined || form?.exportType === null)
        ? {
          ...search,
          ...dataSelects?.reduce((acc, item) => {
            if (!item?.value) return acc;
            if (item?.parent && !enableChilds?.[item?.parent]) return acc;
            return { ...acc, [item?.name]: item?.value };
          }, {}),
        }
        : { ...search }),
    };

    /* let data2 = {
      type_export: form?.exportType || 0,
      ...search,
      ...(dataSelects?.reduce((acc, item) => {
        if (!item?.value) return acc;
        if (item?.parent && !enableChilds?.[item?.parent]) return acc;
        return { ...acc, [item?.name]: item?.value }
      }, {})),
    }; */
    handleSubmit(data, values);
  };

  const selectVar = (e) => {
    enableChilds?.hasOwnProperty(e.target.id) &&
      setEnableChilds({ ...enableChilds, [e.target.id]: e.target.checked });
    if (e.target.checked)
      return setValues((values) => ({ ...values, [e.target.id]: e.target.value }));
    setValues((values) => {
      const { [e.target.id]: _, ...newValues } = values;
      return newValues;
    });
  };

  const method = {
    "GET": (...params) => __Get(...params),
    "POST": (...params) => __Post(...params),
  }

  useEffect(() => {
    const fetchData = async () => {
      selectNodeList?.forEach(async (item) => {
        if (!item?.request) return;
        const response = await method[item?.request?.method?.toUpperCase() || "GET"]?.(item?.request?.endpoint, token);
        if (response?.data?.status?.code !== 200) return;
        setDataSelects((dataSelects) => {
          const index = dataSelects.findIndex(
            (element) => element?.name === item?.name
          );
          dataSelects[index] = {
            ...dataSelects[index],
            data: item?.request?.dataSanitizer(response?.data?.status?.data),
            isLoading: false,
            value: item?.request
              ?.dataSanitizer(response?.data?.status?.data)
              ?.filter((element) => {
                if (isArray(search?.[item?.name]))
                  return search?.[item?.name]?.includes(element?.value);
                return (
                  search?.[item?.name] === element?.value ||
                  search?.[item.name]?.value === element?.value
                );
              }),
          };
          return [...dataSelects];
        });
      });
    };
    fetchData();
  }, [selectNodeList, token, search]);

  const calculateIsDisabled = () => {
    if (dataSelects.some((element) => element?.isLoading)) return true;
    if (form?.exportType || nodeList.length === 0) return false;
    return (
      Object.keys(values).length +
      Object.values(enableChilds).filter((item) => item === true)?.length <
      3 || nodeList.length < 3
    );
  };

  const nodeListMapping = () => {
    return (
      Array.isArray(nodeList) &&
      nodeList?.map((item, index) => {
        if (item?.isQualifiedUser && is_qualified_user === 1) return <></>;

        if (item?.childs) {
          return (
            <GridItem
              key={index}
              rowSpan={
                enableChilds?.[item?.value] ? item?.childs.length + 1 : 1
              }
            >
              <Checkbox
                id={item?.name || item?.value}
                value={item?.value}
                className="mb-2 check-box"
                onChange={selectVar}
                defaultChecked={
                  (defaultActive &&
                    defaultActive.find((element) => element === item?.value)) ||
                  enableChilds?.[item?.value]
                }
              >
                <Text>{item?.label}</Text>
              </Checkbox>
              {enableChilds?.[item?.value] &&
                item?.childs?.map((child) => (
                  <Checkbox
                    id={child?.name || child?.value}
                    value={child?.value}
                    className="mb-2 check-box"
                    onChange={selectVar}
                    defaultChecked={
                      defaultActive &&
                      defaultActive.find((element) => element === child?.value)
                    }
                    px={4}
                  >
                    <Text>{child?.label}</Text>
                  </Checkbox>
                ))}
            </GridItem>
          );
        }

        return (
          <li key={index}>
            <Checkbox
              id={item?.name || item?.value}
              value={item?.value}
              className="mb-2 check-box"
              onChange={selectVar}
              defaultChecked={
                (defaultActive &&
                  defaultActive.find((element) => element === item?.value)) ||
                enableChilds?.[item?.value] || searchKeys.includes(item?.value) || searchKeys.includes(item?.name)
              }
            >
              <Text>{item?.label}</Text>
            </Checkbox>
          </li>
        );
      })
    );
  };

  const nodeSelectListMapping = () => {
    return (
      Array.isArray(dataSelects) &&
      dataSelects?.map((item, index) => {
        if (item?.isQualifiedUser && is_qualified_user === 1) return null;

        if (item?.alwaysVisible) {
          return (
            <GridItem key={index}>{fieldTypes(item, setDataSelects)}</GridItem>
          );
        }
        if (form?.exportType) return null;

        if (!item?.parent) {
          return (
            <GridItem key={index}>{fieldTypes(item, setDataSelects)}</GridItem>
          );
        }
        if (enableChilds?.[item?.parent]) {
          return (
            <GridItem key={index}>{fieldTypes(item, setDataSelects)}</GridItem>
          );
        }
        return null;
      })
    );
  };

  return (
    <>
      <Grid
        templateAreas={`"header"
            "checkbox"
            "selects"
            "typeExcel"
            "footer"`}
        gap={0}
        color={"blackAlpha.800"}
      >
        {/* Type export (general or detail) */}
        <GridItem px="1vh" area={"header"} fontWeight={"bold"}>
          {enableAdvance && (
            <RadioGroup defaultValue="General">
              <Stack direction="row">
                <Radio
                  type="checkbox"
                  value="General"
                  checked={!form?.exportType}
                  onChange={() => {
                    setForm({ ...form, exportType: 0 });
                    resetEnableChild();
                    resetValues();
                  }}
                />
                <label style={{ marginRight: "16px" }}>Detallado</label>

                <Radio
                  type="checkbox"
                  value="Detallado"
                  checked={form?.exportType}
                  onChange={(e) => {
                    setForm({ ...form, exportType: 1 });
                    resetEnableChild();
                    resetValues();
                  }}
                />
                <label>General</label>
              </Stack>
            </RadioGroup>
          )}
        </GridItem>

        {/* Checkbox nodex */}
        <GridItem px="1vh" area={"checkbox"}>
          {subTitle && (
            <Text fontWeight={"bold"} pb={4}>
              {subTitle}:
            </Text>
          )}

          {!form?.exportType && (
            <Grid
              gridTemplateColumns={"repeat(auto-fit, minmax(32vh, 1fr))"}
              columnGap={6}
              className={enableAdvance ? "noStyle pt-2" : "noStyle"}
            >
              {nodeListMapping()}
            </Grid>
          )}
        </GridItem>

        {/* Selects */}
        <GridItem px="1vh" area={"selects"}>
          <Grid
            gridTemplateColumns={"repeat(auto-fit, minmax(40vh, 1fr))"}
            columnGap={6}
            className={enableAdvance ? "noStyle pt-2" : "noStyle"}
          >
            {nodeSelectListMapping()}
          </Grid>
        </GridItem>

        <GridItem px="1vh" area={"footer"}>
          <div className="d-flex">
            <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
              Cerrar
            </ButtonStyled>
            <Spacer />
            <ButtonStyled
              bgColor={"#03C39A"}
              type="submit"
              disabled={calculateIsDisabled()}
              onClick={() => handlePreview()}
            >
              Exportar
            </ButtonStyled>
          </div>
        </GridItem>
      </Grid>
    </>
  );
};

export const ModalExportAdvance = ({
  /* Modal params */
  isOpen,
  onOpen,
  onClose,
  title = "Exportar documento",
  subTitle = "Seleccione las columnas a exportar",
  size = "4xl",
  scrollBehavior = "outside",
  /* Export params */
  enableAdvance,
  nodeList = [],
  selectNodeList = [],
  handleSubmit,
  defaultActive = [],
}) => {
  return (
    <ModalGeneric
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      title={title}
      size={size}
      scrollBehavior={scrollBehavior}
    >
      <ExportAdvance
        onClose={onClose}
        nodeList={nodeList}
        handleSubmit={handleSubmit}
        defaultActive={defaultActive}
        enableAdvance={enableAdvance}
        selectNodeList={selectNodeList}
        subTitle={subTitle}
      />
    </ModalGeneric>
  );
};

export default ExportAdvance;
