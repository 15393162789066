/* eslint-disable no-useless-concat */
import { SimpleGrid, Switch } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import { safelyConvertToString } from "utils/type-check-utils";

export const FormShowCorrect = ({ valuesCorrect, form }) => {
  const { authUser } = useContext(AuthContext);

  const {
    code_operation,
    short_name_operation,
    shipment_entryForm,
    purchase_order
  } = form || {};

  const {
    num_commercial_invoice_corrected,
    business_nameCorrected,
    short_name_operationCorrected,
    code_operationCorrected,
    shipment_entryFormCorrected,
    portmagdalena_corrected,
    inspection_corrected,
    purchase_order_corrected,
    nit_business_nameCorrected
  } = valuesCorrect || {};
  const {
    StateCurrentExport,
    portMCorrect,
    inspeccionCorrect,
    portM,
    inspeccion
  } = useContext(FormCrudCRUDContext);

  /* Estos valores no están documentados adecuadamente y no se sabe su origen. He intentado corregir el problema de los valores nulos que se muestran, pero no entiendo cómo los desarrolladores anteriores lograron que este módulo funcionara parcialmente.
  Por favor, tómese un momento para revisar esto antes de asumir que el mal funcionamiento del código es responsabilidad mía. */

  const labelExpor = nit_business_nameCorrected
    ? `${nit_business_nameCorrected} ${business_nameCorrected}`
    : `${business_nameCorrected || "N/A"}`;

  return (
    <>
      <LabelGroup
        changeValue={
          StateCurrentExport &&
          StateCurrentExport !== labelExpor &&
          "BorderCorrect"
        }
        name={"searchInformacion"}
        value={labelExpor}
        tag={"Exportador/Proveedor*"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          code_operation + " " + short_name_operation !==
          code_operationCorrected + " " + short_name_operationCorrected &&
          "BorderCorrect"
        }
        name={"searchInformacion"}
        value={`${short_name_operationCorrected ? (code_operationCorrected && code_operationCorrected + " ") + short_name_operationCorrected : " "}`}
        tag={"Tipo de Operación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          shipment_entryForm !== shipment_entryFormCorrected && "BorderCorrect"
        }
        name={"searchInformacion"}
        value={shipment_entryFormCorrected}
        tag={"Tipo de embarcación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(purchase_order) !== safelyConvertToString(purchase_order_corrected) && "BorderCorrect"
        }
        name={"purchase_order_corrected"}
        value={purchase_order_corrected}
        tag={"Orden de compra"}
        maxLength={80}
        size={100}
        desicion={true}
      />

      <SimpleGrid minChildWidth="100px" spacing="10px" className="ms-4 mt-3">
        {authUser.is_commerce === 1 ||
          (authUser.admin === 1 && (
            <div className={portM !== portMCorrect && "BorderCorrect"}>
              <LabeFormlStyled htmlFor="portmagdalena_corrected">
                Portmagdalena
              </LabeFormlStyled>
              <div>
                <Switch
                  name="portmagdalena_corrected"
                  id="portmagdalena_corrected"
                  size="lg"
                  value={portmagdalena_corrected}
                  isChecked={portMCorrect}
                  isDisabled
                />
              </div>
            </div>
          ))}
        {authUser.is_commerce === 1 || authUser.admin === 1
          ? (
            <div
              className={
                inspeccion !== inspeccionCorrect && "BorderCorrect" + " " + "ms-4"
              }
            >
              <LabeFormlStyled htmlFor="inspection_corrected">
              Inspección
              </LabeFormlStyled>
              <div>
                <Switch
                  name="inspection_corrected"
                  id="inspection_corrected"
                  size="lg"
                  value={inspection_corrected}
                  isChecked={inspeccionCorrect}
                  isDisabled
                />
              </div>
            </div>
          )
          : (
            ""
          )}
      </SimpleGrid>
    </>
  );
};
