import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearFormField, updateFormField } from "redux/actions/useFormAction";
import isArrayNotEmpty, {
  isArray,
  isNumber,
  isObject,
  isObjectNotEmpty,
  safelyConvertToString,
} from "utils/type-check-utils";

export const RETURN_TYPES = {
  ARRAY: "array",
  OBJECT: "object",
};

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

/**
 * @typedef {Object} FormStateHandlerFunction
 * @property {function(string, any): void} updateField - Una función para actualizar un campo del formulario en el estado de Redux
 */

/**
 * @typedef {Object} FormValidationResult
 * @property {boolean} IsErrorRegex - Indica si ocurrió un error de validación de expresión regular
 * @property {Object|null} errors - Un objeto que contiene mensajes de error de validación para campos específicos
 */

/**
 * @typedef {Object} ReturnTypeConfig
 * @property {string} name - El nombre del campo
 * @property {any} value - El valor que se devolverá para el campo
 * @property {string} action - (Opcional) Una acción para manejar opciones selectas, por ejemplo, "select-option"
 */

/**
 * @typedef {Object} UseFormOptions
 * @property {Object} initialState - El estado inicial de los datos del formulario
 * @property {function(Object): FormValidationResult|null} onValidate - Una función de devolución de llamada para la validación personalizada del formulario
 * @property {function(string, any, EventTarget): void} onFieldChange - Una función de devolución de llamada para manejar cambios en los campos
 * @property {FormStateHandlerFunction|boolean} formStateHandler -
 *   - Una función para actualizar el estado del formulario en Redux (si es `true`)
 *   - Una `FormStateHandlerFunction` personalizada para mayor control
 * @property {string} formKey - (Opcional) Una clave para identificar el formulario (para uso en Redux)
 */

/**
 * Un hook personalizado de React para administrar el estado del formulario, la validación y las actualizaciones en Redux.
 *
 * @param {UseFormOptions|...any} args - Opciones de configuración para el hook del formulario:
 *   - Si se proporciona un único objeto, debe contener todas las opciones.
 *   - De lo contrario, se pueden pasar argumentos individuales:
 *     - initialState (Object): El estado inicial de los datos del formulario.
 *     - onValidate (función): Una función de devolución de llamada para la validación personalizada del formulario.
 *     - onFieldChange (función): Una función de devolución de llamada para manejar cambios en los campos.
 *     - useRedux (función|boolean):
 *       - Una función para actualizar el estado del formulario en Redux (si es `true`)
 *       - Una `useRedux` personalizada para mayor control.
 *     - formKey (string): (Opcional) Una clave para identificar el formulario (para uso en Redux).
 *
 * @returns {Array} Un array que contiene los siguientes elementos:
 *   - values (Object): El estado actual de los datos del formulario.
 *   - handleInputChange (función): Una función para manejar cambios de entrada en los campos del formulario.
 *   - reset (función): Una función para restablecer los datos del formulario a su estado inicial.
 *   - setValues (función): Una función para actualizar directamente el estado de los datos del formulario (usar con precaución).
 *   - errors (Object|null): Un objeto que contiene mensajes de error de validación para campos específicos (si la validación falla).
 */

export const useForm = (...args) => {
  let initialState = {},
    onValidate = null,
    onFieldChange = () => { },
    useRedux = false,
    formKey = "",
    returnType = RETURN_TYPES.ARRAY;

  const iState = args[0] || {};
  const hasRelevantKey = [
    "initialState",
    "onFieldChange",
    "useRedux",
    "formKey",
    "returnType",
    "onValidate",
  ].some((key) => key in iState);

  if (isArrayNotEmpty(args) && isObject(args[0]) && hasRelevantKey) {
    ({
      initialState = {},
      onValidate = null,
      onFieldChange = () => { },
      useRedux,
      formKey,
      returnType,
    } = args[0]);
  } else {
    [
      initialState,
      onValidate,
      onFieldChange = () => { },
      useRedux,
      formKey,
      returnType,
    ] = args;
  }

  const [values, setValues] = useState(initialState);
  const [regexError, setRegexError] = useState(false);
  const [errors, setErrors] = useState(null);
  const [currentField, setCurrentField] = useState("");
  const dispacthRedux = useDispatch();
  const { form: useFormRedux } = useSelector((state) => state);

  const reset = () => {
    setValues(initialState);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const valueStorage = useFormRedux?.[formKey];
      if (isObject(valueStorage) && isObjectNotEmpty(valueStorage)) {
        setValues(valueStorage);
      }
    }
  }, [formKey]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (onValidate) {
      const validationResults = onValidate(values);
      const { IsErrorRegex, errors } = validationResults || {};
      setRegexError(IsErrorRegex);
      setErrors(errors || null);
    }
  }, [values, onValidate]);

  const clearReduxState = () => {
    useRedux && dispacthRedux(clearFormField(formKey));
  };

  const handleInputChange = (e, returnType = "", callback = () => { }) => {
    if (!e?.target && returnType?.action !== "select-option") {
      return;
    }

    const typeValueSelect =
      isArray(e) && isArrayNotEmpty(e)
        ? e.map((item) => item.value)
        : e?.value || null;

    const selectConfig = {
      ...(returnType?.name && { name: returnType?.name }),
      ...(typeValueSelect && { value: typeValueSelect }),
    };

    const { checked, name, type, value } = e?.target || selectConfig;

    const types = {
      checkbox: returnType === "number" ? (checked ? 1 : 0) : checked,
      radio: safelyConvertToString(value),
      default: value,
    };
    setCurrentField(name);
    const updatedValue = types?.[type] || types.default;
    const newValue = { ...values, [name]: updatedValue };

    setValues((prevValues) => ({
      ...prevValues,
      [name]: updatedValue,
    }));
    /*  formKey */
    useRedux &&
      dispacthRedux(updateFormField(formKey || "", name, updatedValue));

    callback();
    onFieldChange(name, newValue[name], e?.target, newValue);
  };

  const sanitizedValues =
    isObject(values) &&
    Object?.keys(values)?.reduce((acc, key) => {
      const value = values[key];
      if (value !== null && value !== undefined && value !== "")
        acc[key] = value;
      return acc;
    }, {});

  if (returnType === RETURN_TYPES.OBJECT) {
    return {
      values,
      handleInputChange,
      reset,
      setValues,
      errors,
      clearReduxState,
      currentField,
      sanitizedValues,
    };
  }

  return [
    values,
    handleInputChange,
    reset,
    setValues,
    errors,
    clearReduxState,
    currentField,
    sanitizedValues,
  ];
};
