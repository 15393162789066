import React from "react";

export const PDFFreeZone = ({ data }) => {
  const {
    num_dex_entryFormCorrected,
    num_commercial_invoice_corrected,
    exit_auth_entryFormCorrected,
    integration_box_certificate_corrected,
    dcl_customs_transit_corrected,
    transit_expiration_date_corrected,
    national_or_foreign_corrected
  } = data;
  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Nº Factura comercial: </strong>{" "}
          {num_commercial_invoice_corrected}
        </p>

        <p>
          <strong>Aut. de salida: </strong> {exit_auth_entryFormCorrected}
        </p>

        <p>
          <strong>C. de integración: </strong> {integration_box_certificate_corrected}
        </p>
        <p>
          <strong>Dcl. de tránsito aduanero: </strong>{" "}
          {dcl_customs_transit_corrected}
        </p>
      </div>

      <div className="col">
        <p>
          <strong>Fecha vencimiento transito: </strong>{" "}
          {transit_expiration_date_corrected}
        </p>
        <p>
          <strong>Nº DEX: </strong> {num_dex_entryFormCorrected}
        </p>
        <p>
          <strong>Nacional/Entranjero: </strong> {national_or_foreign_corrected}
        </p>
      </div>
    </div>
  );
};
