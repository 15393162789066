export const TitleProfile = [
  { name: "Fecha y hora", order: null },
  { name: "Tipo de documento", order: null },
  { name: "Nº documento", order: null },
  { name: "Usuario calificado", order: null },
  { name: "Operación", order: null },
  { name: "Doc. transporte", order: null },
  { name: "DTA", order: null },
  { name: "DIM", order: null },
  { name: "Pases", order: null },
  { name: "Items", order: null },
  { name: "Perfilamiento", order: null }
  // { name: "Acciones", order: null },
];

export const TitleFinisProfile = [
  { name: "Fecha y hora", order: null },
  { name: "Tipo de documento", order: null },
  { name: "Nº documento", order: null },
  { name: "Usuario calificado", order: null },
  { name: "Operación", order: null },
  { name: "Doc. transporte", order: null },
  { name: "DTA", order: null },
  { name: "DIM", order: null },
  { name: "Pases", order: null },
  { name: "Items", order: null },
  { name: "Finalizar Perfilamiento", order: null }
];

export const TitleAnexed = [
  { name: "Fecha y hora", order: null },
  { name: "Nº formulario", order: null },
  { name: "Num. Form. Corregido", order: null },
  { name: "Usuario calificado", order: null },
  { name: "Operación", order: null },
  { name: "Código resp.", order: null },
  { name: "Mensaje", order: null },
  { name: "Descripción del error", order: null }
];

export const TitleItems = [
  { name: "Subpartida", order: null },
  { name: "Descripción", order: null },
  { name: "Cantidad", order: null },
  { name: "Unidad", order: null },
  { name: "Peso Bruto", order: null },
  { name: "Perfilar", order: null }
];

export const TitleItemsProfile = [
  { name: "Subpartida", order: null },
  { name: "Descripción", order: null },
  { name: "Cantidad", order: null },
  { name: "Unidad", order: null },
  { name: "Peso Bruto", order: null },
  { name: "Perfilar", order: null }
];

export const HeadProfile = ["Perfilar documentos", "Finalizar perfilamiento"];

export const TitleShippingForm = [
  { name: "Prioridad", order: null },
  { name: "Vehiculo", order: null },
  { name: "Usuario Calificado", order: null },
  { name: "Numero planilla de envio", order: null },
  { name: "Fecha y hora", order: null },
  { name: "Modo de transporte", order: null },
  { name: "Acciones", order: null }
];
