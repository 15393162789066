/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import {
  __RemoveDocument,
  __GetOperationDocument
} from "request/configuration/__DocumentRequire";
import { AddOperation } from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const FormRemoveDocument = () => {
  const initialForm = {
    name_document: "",
    description_document: ""
  };

  const [form, setForm] = useState(initialForm);
  const [input, setInput] = useState();
  const [operation, setOperation] = useState([]);
  const [mendigo, setMendigo] = useState("");

  const { onCloseModal, dataToRemove, setUpdate, update } = useContext(
    ConfigurationCRUDContext
  );

  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToRemove) {
      setForm(dataToRemove);
    } else {
      setForm(initialForm);
    }
  }, [dataToRemove]);

  useEffect(() => {
    const getOperationDocumentList = async () => {
      try {
        const res = await __GetOperationDocument(token, dataToRemove.id);
        setOperation(res?.data?.status?.data);
      } catch (error) {}
    };
    getOperationDocumentList();
  }, []);

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setInput(1);
    } else {
      setInput(0);
    }
  };

  const handleRemove = async () => {
    const data = {
      operation_id: mendigo.value,
      confirm: input,
      document_id: dataToRemove.id
    };
    try {
      const res = await __RemoveDocument(token, data);
      if (res.data.status.code === 200) {
        openAlert("Documento desasociado Exitosamente", "success");

        onCloseModal();
        setUpdate(!update);
      } else if (res.data.status.code === 400) {
        openAlert(
          "Este documento está asociado a una operación como soporte requerido. ¿Desea realmente desasociar el documento? Marque la casilla para confirmar.",
          "error"
        );
      } else {
        openAlert("Hubo un error", "error");
      }
    } catch (error) {}
  };

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToRemove ? form.name_document : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_document}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6 input-alinear">
              <label>
                <input
                  type="checkbox"
                  id="cbox1"
                  value="1"
                  onChange={handleInput}
                  className="me-2"
                />
                Confirmar
              </label>
            </div>
          </div>
          <div className="row ">
            <div>
              <AddOperation
                data={operation}
                tag={"Tipo de operación"}
                setMendigo={setMendigo}
                mendigo={mendigo}
              />
            </div>
          </div>
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          marginTop={"40px"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleRemove}>Desasociar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormRemoveDocument;
