import React, { useCallback, useContext, useEffect } from "react";
import InputSelect, {
  SelectFormTypeOperation
} from "components/InputSelect/InputSelect";
import { InputGroupUser, LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { RadioBtn } from "../../../../components/ComponentRadioButton/RadioBtn";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Button, SimpleGrid, Tooltip } from "@chakra-ui/react";
import { SearchEditedFields } from "hooks/useLocalStorage";

import ThirdPartiesForm from "pages/Terceros/form/ThirdPartiesForm";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import ModalTransition from "components/modal/ModalTransition";
import environment from "constants/apiConst";
import GlobalContext from "context/GlobalContext";
import { useNotations } from "context/NotationsContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const DataCompanyExit = ({
  values,
  handleInputChange,
  dataOperation,
  setSelectItemForm,
  selectItemForm,
  setSelectIdOp,
  setUserQuialified,
  userQualified,
  setValueQuialified,
  valueQualified,
  setNameQualified,
  nameQualified,
  setOnly,
  only,
  setRefund,
  refund,
  setRadioRequired,
  radioRequired,
  setValueThird,
  valueThird,
  setNameOperation,
  nameOperation,
  setPlazo,
  plazo,
  errorsState,
  setValues,
  resetCampos = () => { }
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const { setUser_Qualified } = useContext(GlobalContext);
  const location = useLocation();
  const { FormExitNumber = null } = queryString.parse(location.search);

  const {
    getThirdParties,
    dataThird,
    setDataThird,
    ReviewsData,
    setDecimalUcContext,
    addresDestine,
    dataFormCurrent
  } = useContext(FormCrudCRUDContext);

  const { isOpenEdit, onOpenEdit, onCloseModal, setRequestCreate } =
    useContext(ThirdPartiesContext);

  // hook form
  const { destination_address } = values;

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const data = res?.data?.status?.data;
      setUserQuialified(data);
    } catch (error) { }
  }, [setUserQuialified, token]);

  useEffect(() => {
    getUserQualified();
  }, [token, getUserQualified]);

  useEffect(() => {
    if (valueQualified.length !== 0 && authUser.is_qualified_user !== 1) {
      getThirdParties(valueQualified);
    }
    setDataThird([]);
    getUserQualified();
  }, [valueQualified, authUser]);

  useEffect(() => {
    if (authUser.is_qualified_user === 1) {
      getThirdParties();
    }
  }, [authUser]);

  useEffect(() => {
    setRequestCreate(environment.CREATE_THIRD_PARTY);
  }, []);

  const handleThird = (e) => {
    setValueThird(e);

    setValues({ ...values, destination_address: e?.address});
  };

  useEffect(() => {
    setUser_Qualified(valueQualified);
  }, [valueQualified]);

  const optionsOnly = ["Único", "Fraccionado"];
  const optionsRefund = ["Con reintegro", "Sin reintegro"];
  const optionsRequired = ["Requiere pase", "No requiere pase"];
  const { setFormOperation } = useContext(GlobalContext);
  const { reviewsData, updateNotationData, updateNotation } = useNotations();

  const formId = FormExitNumber;

  const reviewData = reviewsData?.Header;

  const colorNotation = (propertyName) => {
    return (
      updateNotationData?.[formId]?.Header?.[propertyName]?.status?.color ||
      reviewData?.[propertyName]?.status?.color
    );
  };

  const customStyles = (propertyName) => {
    const color = colorNotation(propertyName);

    if (color) {
      return {
        control: (provided) => ({
          ...provided,
          borderColor: color
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color
        })
      };
    }
  };

  const nameSelect = {
    name_qualified: "Empresa",
    business_name: "Importador/Comprador",
    short_name_operation: 'Tipo de Operación"'
  };

  const selectChange = (items, select) => {
    return updateNotation(
      select?.name,
      nameSelect?.[select?.name],
      items?.label,
      dataFormCurrent?.[select?.name],
      "Header"
    );
  };

  useEffect(() => {
    return () => {
      setDataThird([]);
      setValueQuialified("");
      resetCampos()
    }
  }, []);

  return (
    <>
      <ModalTransition isOpen={isOpenEdit} onClose={onCloseModal} size="xl">
        <ThirdPartiesForm />
      </ModalTransition>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        {authUser.is_qualified_user === 0 && (
          <InputGroupUser
            partidas={userQualified}
            tag={"Empresa"}
            name={"name_qualified"}
            customColorLabel={colorNotation("name_qualified")}
            Label={reviewData?.name_qualified?.comment}
            LabelBg={colorNotation("name_qualified")}
            styles={customStyles("name_qualified")}
            size={100}
            onChange={selectChange}
            setMendigo={setValueQuialified}
            setNameQ={setNameQualified}
            nameQ={nameQualified}
            valor={valueQualified}
            setDecimalUcContext={setDecimalUcContext}
          />
        )}

        <Tooltip
          hasArrow
          label={SearchEditedFields(`business_name`, ReviewsData) ?? ""}
          bg="red.600"
          color="white"
          placement="bottom-start"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InputSelect
              customColorLabel={colorNotation("business_name")}
              Label={reviewData?.business_name?.comment}
              LabelBg={colorNotation("business_name")}
              styles={customStyles("business_name")}
              name={"business_name"}
              onChange={selectChange}
              tag={"Importador/Comprador"}
              Name={"business_name"}
              data={dataThird}
              set={handleThird}
              value={valueThird}
              size={100}
              ButtonSide={true}
              error={errorsState.thirdPartie_id &&
                (valueThird.length === 0 || valueThird === null)}
              isRequired={true}
            />

            <Tooltip
              hasArrow
              label={"Agregar tercero"}
              color="white"
              placement="top"
            >
              <Button
                onClick={onOpenEdit}
                w={"42px"}
                h={"38px"}
                fontSize={"25px"}
                marginTop={"24px"}
                borderRadius={0}
                borderTopRightRadius={"5px"}
                borderBottomRightRadius={"5px"}
                color={"#fff"}
                backgroundColor={"#1f7ac3"}
                paddingBottom={"1px"}
                outline={"none"}
              >
                +
              </Button>
            </Tooltip>
          </div>
        </Tooltip>

        <SelectFormTypeOperation
          data={dataOperation}
          tag={"Tipo de Operación"}
          valor={selectItemForm}
          setValor={setSelectItemForm}
          setPrimaryValue={setFormOperation}
          size={100}
          setSelectIdOp={setSelectIdOp}
          setNameOperation={setNameOperation}
          nameOperation={nameOperation}
          Name={"short_name_operation"}
          setPlazo={setPlazo}
          customColorLabel={colorNotation("short_name_operation")}
          Label={reviewData?.short_name_operation?.comment}
          LabelBg={colorNotation("short_name_operation")}
          styles={customStyles("short_name_operation")}
          name={"short_name_operation"}
          onChange={selectChange}
          error={errorsState.opertationId && !selectItemForm ? true : ""}
        />
      </SimpleGrid>

      {addresDestine.map((items, index) => {
        if (items === selectItemForm) {
          return (
            <LabelGroup
              name={"destination_address"}
              value={destination_address}
              onChange={handleInputChange}
              tag={"Dirección de destino"}
              size={100}
              desicion={false}
              key={index}
            />
          );
        }
      })}

      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div style={{ width: `30%` }}>
          <RadioBtn setOnly={setOnly} options={optionsOnly} state={only} />
        </div>
        <div>
          <RadioBtn
            setOnly={setRefund}
            options={optionsRefund}
            state={refund}
          />
        </div>
        {selectItemForm === 426 && (
          <div>
            <RadioBtn
              setOnly={setRadioRequired}
              options={optionsRequired}
              state={radioRequired}
            />
          </div>
        )}
      </SimpleGrid>

      {plazo !== null && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            name={"plazo"}
            value={plazo || ""}
            onChange={handleInputChange}
            tag={"Plazo"}
            size={30}
            desicion={true}
          />
        </div>
      )}
    </>
  );
};
