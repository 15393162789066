import { BaseOfExport } from "components/Export/BaseOfExport";
import ViewItemsOriginal from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsOriginal";
import React from "react";
import { Logo } from "styled/Logo.styled";
import { CabeceraEntry } from "./cabeceraEntry/CabeceraEntry";
import { HeaderOperationSalida } from "./cabeceraOutput/HeaderOperationSalida";
import { ExportPdfRefund } from "./ExportPdfRefund";
import { TitleOperationSalida } from "./operationTitle/TitleOperationSalida";

export const ExportPdfOutput = ({ values, items }) => {
  const { code_operation, refund, shipment, term } = values;
  return (
    <div id="IdPdfFmmOutput">
      <BaseOfExport>
        <div
          style={{
            padding: "5px",
            marginLeft: "10px"
          }}
        >
          <CabeceraEntry values={values} />
        </div>

        <div className="row">
          <div className="col-12">
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #bababa",
                borderRadius: "5px",
                marginLeft: "10px",
                marginRight: "10px"
              }}
            >
              <div
                style={{
                  padding: "5px"
                }}
              >
                <TitleOperationSalida code={code_operation} />
              </div>

              <div className="p-1">
                <HeaderOperationSalida values={values} />
              </div>
              <div
                style={{
                  border: "1px solid #bababa",
                  borderRadius: "5px",
                  padding: "5px",
                  marginTop: "5px"
                }}
              >
                <div className="row">
                  <div className="col">
                    <p>
                      <strong>Modalidad: </strong> {refund}
                    </p>

                    <p>
                      <strong>Embarque: </strong> {shipment}
                    </p>
                  </div>

                  <div className="col">
                    <p>
                      <strong>Plazo: </strong> {term}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: "5px",
              marginLeft: "20px"
            }}
          >
            <p>
              <strong>Tipo de operación: </strong>
              {values?.short_name_operation}
            </p>
          </div>

          <div className="col-12">
            {items === undefined
              ? ""
              : items.map((info, index) => (
                <>
                  {index !== 0 && (
                    <>
                      <div className="d-flex mt-5">
                        <div id="banner" />
                        <Logo width={"180px"} className="mx-2" />
                      </div>
                      <div
                        style={{
                          padding: "5px",
                          marginLeft: "10px"
                        }}
                      >
                        <CabeceraEntry values={values} />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      border: "1px solid #bababa",
                      marginTop: "20px",
                      borderRadius: "5px",
                      padding: "5px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                  >
                    <div className="row" key={index}>
                      <h1 className="text-word mb-1 mt-1">
                          Item Nº {info?.item}
                      </h1>
                      <ViewItemsOriginal
                        data={info}
                        numero={index}
                        alldata={items}
                      />
                      <ExportPdfRefund data={info} />
                    </div>
                  </div>
                  {items && index !== items.length - 1 && (
                    <div className="html2pdf__page-break" />
                  )}
                </>
              ))}
          </div>
        </div>
      </BaseOfExport>
    </div>
  );
};
