/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  __LicensesActivate,
  __LicensesDeactivate
} from "request/configuration/_Licenses";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";

const validateForm = (form, openAlert) => {
  if (form?.name_license === "" || !form?.name_license?.trim()) {
    openAlert("Ingrese el nombre de la aduana", "error");
    return false;
  }

  if (!form?.active_accounts) {
    openAlert("Ingrese los N° de usuarios", "error");
    return false;
  }

  if (!form?.expireDate_license || !form?.expireDate_license?.trim()) {
    openAlert("Ingrese la fecha de expiración", "error");
    return false;
  }

  return true;
};

const LicensesForm = ({ onClose = () => {}, userUSerQ }) => {
  const [openAlert] = AlertErrorAlert();
  const location = useLocation();

  const { pathname } = location;

  const { dataToEdit, editDatLicense, createDataLicense, idUserQualified } =
    useContext(ConfigurationCRUDContext);

  const initialForm = {
    name_license: "",
    expireDate_license: "",
    description_license: "",
    status_license: 1,
    qualifiedUser_id: idUserQualified,
    active_accounts: ''
  };

  const [form, handleInputChange, resetForm, setForm] = useForm(initialForm);
  const [check, setCheck] = useState(true);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToEdit) {
      setForm({ ...dataToEdit });
      setCheck(dataToEdit.status_license === 1);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const actLicense = async (status, id) => {
    const valueStatus = status ? 1 : 0;

    try {
      const res = status
        ? await __LicensesActivate(token, id)
        : await __LicensesDeactivate(token, id);
      console.log(res);
      if (res.data.status.code === 200) {
        openAlert(
          status ? "Licencia activada" : "Licencia desactivada",
          "success"
        );
      } else {
        dataToEdit.status_license !== valueStatus &&
          openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm(form, openAlert)) return;
    console.log(dataToEdit);
    const dataCreate = {
      ...form,
      qualifiedUser_id: userUSerQ?.value
    };

    if (dataToEdit && dataToEdit?.name_license) {
      const data = {
        ...form,
        id: dataToEdit.id,
        status_license: form.status_license ? 1 : 0
      };
      // console.log(data);
      // actLicense(form.status_license, dataToEdit.id);
      // editDatLicense(data);
    } else {
      createDataLicense(dataCreate, onClose);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  return (
    <>
      <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
        {dataToEdit?.name_license ? "Editar Licencia" : "Crear Licencia"}
      </Text>
      <Divider mb={3} />

      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="name_license">Nombre</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_license"
              id="name_license"
              value={form.name_license}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="active_accounts">No. de usuarios</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="active_accounts"
              id="active_accounts"
              value={form.active_accounts}
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="expireDate_license">
              Fecha Expiración
            </LabeFormlStyled>
            <InputFormStyled
              type="date"
              name="expireDate_license"
              id="expireDate_license"
              value={form.expireDate_license}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="description_license">
              Descripción
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="description_license"
              id="description_license"
              value={form.description_license}
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>

        {dataToEdit?.name_license && (
          <div>
            <LabeFormlStyled htmlFor="status_license">Activar</LabeFormlStyled>
            <div>
              <Switch
                name="status_license"
                id="status_license"
                size="lg"
                value={form.status_license}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default LicensesForm;
