import { useForm } from "./useForm";

const { useEffect, useState } = require("react");

/**
 * Custom hook that returns a debounced value.
 *
 * @param {*} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds for the debounce.
 * @returns {*} - The debounced value.
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    /**
     * Timer for debouncing the value.
     *
     * @type {number}
     */
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

/**
 * Custom hook that returns a debounced value.
 *
 * @param {*} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds for the debounce.
 * @returns {*} - The debounced state and the setter of the debounce state.
 */
export const useDebounceState = (initialState, delay) => {
  const [state, setState] = useState(initialState);
  const debouncedState = useDebounce(state, delay);
  return [debouncedState, setState, state];
};

/**
 * Custom hook that returns a debounced value.
 *
 * @param {*} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds for the debounce.
 * @returns [debounceState, setState, state] - The debounced state and the setter of the debounce state and the state in real tyme.
 */
export const useDebounceForm = (initialState, delay) => {
  const [state, handleInputChange, reset] = useForm(initialState);
  const debouncedState = useDebounce(state, delay);
  return [debouncedState, state, handleInputChange, reset];
};
