import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import {
  CREATE_DASHBOARD_ITEM,
  UPDATE_DASHBOARD_ITEM
} from 'DashBoard/graphql/mutations'
import { GET_DASHBOARD_ITEMS } from 'DashBoard/graphql/queries'
import { useDebounce } from 'hooks/useDebouce'

const TitleModal = ({
  itemId,
  isOpen,
  onClose,
  setAddingToDashboard,
  finalVizState,
  setTitle,
  finalTitle
}) => {
  const Navigater = useNavigate()

  const [addDashboardItem] = useMutation(CREATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS
      }
    ]
  })
  const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS
      }
    ]
  })

  const [Name, setName] = useState('')
  const debouncedName = useDebounce(Name, 700)

  useEffect(() => {
    setTitle(debouncedName)
  }, [debouncedName])

  return (
    <>
      <Modal key='modal' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Guardar gráfico</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {' '}
            <Input
              placeholder='Nombre del elemento del panel'
              onChange={e => setName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button
              colorScheme='blue'
              onClick={async () => {
                onClose()
                setAddingToDashboard(true)
                try {
                  await (itemId ? updateDashboardItem : addDashboardItem)({
                    variables: {
                      id: itemId,
                      input: {
                        vizState: JSON.stringify(finalVizState),
                        name: finalTitle
                      }
                    }
                  })
                  Navigater('/dashboard')
                } finally {
                  setAddingToDashboard(false)
                }
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default TitleModal
