import { Box, Grid, GridItem } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Pagination from "components/Pagination/Pagination";
import environment from "constants/apiConst";
import { useEffect, useState, useContext } from "react";

import { useSelector } from "react-redux";
import { __Get } from "request/Petitions/__Get";
import { __PostJsonData } from "request/Petitions/__Post";
import { Metrics } from "./Metrics/Metrics";
import { SearchControlAnalysis } from "./SearchControlAnalysis";
import ControAnalysisTable from "./Tables/controAnalysisTable";
import { ElementFilter, Elementfirst } from "./Tables/tableElement";
import { UsersAccordingToCriteria } from "./UsersAccordingToCriteria/UsersAccordingToCriteria";
import ControlAnalysisContext from "context/ControlAnalysis";

export const ControlAnalysis = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { startDate, endDate, setFilterCurrent } = useContext(
    ControlAnalysisContext
  );
  const [loading, setLoading] = useState();
  const [show, setShow] = useState(true);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  const [loadingFilter, setLoadingFilter] = useState();
  const [DataFilter, setDataFilter] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [Criteria_id, setCriteria_id] = useState([]);
  const [FreeZoneId, setFreeZoneId] = useState([]);
  const [QualificationId, setQualificationId] = useState([]);
  const [sectorId, setSector] = useState([]);
  const [riskLevel, setriskLevel] = useState([]);
  // Tabla grande
  const [MatrixCurrentPage, setMatrixCurrentPage] = useState(1);
  const [maxPageMatrix, setmaxPageMatrix] = useState(null);

  const [date, setdate] = useState({
    start_date: "",
    end_date: ""
  });

  const [RiskLevelTable, setRiskLevelTable] = useState([]);

  // CONTROL_MATRIX_CRITERIS_RISK
  const [currentPage, setCurrentPage] = useState([]);
  const [ListCriteria, setListCriteria] = useState([]);
  const [pageRisk, setPageRisk] = useState(1);
  const [loadingRisk, setLoadingRisk] = useState(false);
  const [maxPageRisk, setMaxPageRisk] = useState(1);

  const FilterObj = {
    criteria_id: Criteria_id.includes(0) ? [] : Criteria_id,
    free_zone_key: FreeZoneId?.value ?? [],
    qualification_id: QualificationId.includes(0) ? [] : QualificationId,
    risk_level: riskLevel,
    sector_id: sectorId.includes(0) ? [] : sectorId,
    start_date: date.start_date ?? "",
    end_date: date.end_date ?? ""
  };

  useEffect(() => {
    setFilterCurrent({
      criteria_id: Criteria_id.includes(0) ? [] : Criteria_id,
      free_zone_key: FreeZoneId?.value ?? '',
      qualification_id: QualificationId.includes(0) ? [] : QualificationId,
      risk_level: riskLevel,
      sector_id: sectorId.includes(0) ? [] : sectorId,
      start_date: date.start_date ?? "",
      end_date: date.end_date ?? ""
    });
  }, [Criteria_id, FreeZoneId, QualificationId, riskLevel, sectorId, date]);

  useEffect(() => {
    const RiskLevelData = {
      criteria_id: ListCriteria.includes(0) ? [] : ListCriteria,
      risk_level: RiskLevelTable ?? [],
      start_date: startDate ?? "",
      end_date: endDate ?? "",
      length: 9
    };

    if (RiskLevelTable.length > 0) CriterisRisk(RiskLevelData);
    else if (pageRisk !== 1 || ListCriteria.length > 0) { CriterisRisk(RiskLevelData); }
  }, [
    RiskLevelTable,
    pageRisk,
    ListCriteria,
    startDate,
    endDate,
    freeZoneValue
  ]);

  const [openAlert] = AlertErrorAlert();

  const FilterElement = async () => {
    setLoadingFilter(true);
    try {
      const res = await __Get(environment.CONTROL_FILTER_ELEMNT, token);
      const data = res?.data?.status;
      setDataFilter(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const FindBack = () => {
    const RiskLevelData = {
      criteria_id: ListCriteria.length < 0 ? Criteria_id : ListCriteria,
      sector_id: sectorId.includes(0) ? [] : sectorId,
      length: 7
    };
    CriterisRisk(RiskLevelData);
    searchSimple(FilterObj);
  };

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.CONTROL_MATRIX,
        token,
        informacion,
        MatrixCurrentPage
      );
      const status = res?.data?.status;

      if (res?.data?.status?.message === "Acceso no autorizado.") {
        openAlert(res?.data?.status?.message, "error");
      } else {
        const Columns = status?.data?.column;
        const ColumnsList = [...Elementfirst, ...Columns];
        setTableColumns(ColumnsList);
        setDataSource(status?.data?.data?.data);
        setLoading(false);
        setmaxPageMatrix(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const CriterisRisk = async (informacion) => {
    setLoadingRisk(true);
    try {
      const res = await __PostJsonData(
        environment.CONTROL_MATRIX_CRITERIS_RISK,
        token,
        informacion,
        pageRisk
      );
      const status = res?.data?.status;
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPage(status?.data?.data);
        setMaxPageRisk(status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoadingRisk(false);
      setShow(false);
    }
  };

  useEffect(() => {
    FilterElement();
  }, [freeZoneValue]);

  useEffect(() => {
    if (MatrixCurrentPage !== 1) searchSimple(FilterObj);
  }, [MatrixCurrentPage, freeZoneValue]);

  return (
    <Box
      style={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "auto",
        margin: "0.5rem 0.5rem",
        padding: "0.9rem"
      }}
    >
      <SearchControlAnalysis
        ElementFilter={ElementFilter}
        dataFilter={DataFilter}
        CriteriaId={Criteria_id}
        setCriteria={setCriteria_id}
        FreeZoneId={FreeZoneId}
        setFreeZoneId={setFreeZoneId}
        QualificationId={QualificationId}
        setQualificationId={setQualificationId}
        riskLevel={riskLevel}
        setriskLevel={setriskLevel}
        setdate={setdate}
        date={date}
        FindBack={FindBack}
        setSector={setSector}
        sectorId={sectorId}
      />

      <Grid
        templateColumns="repeat(auto-fit, minmax(20rem, 1fr))"
        gap={2}
        hidden={show}
      >
        <GridItem w="100%">
          {" "}
          <UsersAccordingToCriteria
            page={pageRisk}
            maxPage={maxPageRisk}
            setPage={setPageRisk}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loadingRisk}
          />
        </GridItem>
        <GridItem w="100%">
          {" "}
          <Metrics
            setListCriteria={setListCriteria}
            Criteria={DataFilter?.criteria ?? []}
            setLevel={setRiskLevelTable}
          />
        </GridItem>
      </Grid>

      {tableColumns.length > 0 && (
        <Box
          display={"flex"}
          flexDir={"column"}
          m={6}
          p={6}
          borderRadius={"10px"}
          shadow={
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }
          maxW="full"
        >
          <ControAnalysisTable
            pagination={false}
            columns={tableColumns}
            dataSource={dataSource}
            loading={loading}
          />
          <Pagination
            page={MatrixCurrentPage}
            setPage={setMatrixCurrentPage}
            maxPage={maxPageMatrix}
            loading={loading}
          />
        </Box>
      )}
    </Box>
  );
};
