import { Box } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const SectionContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  min-height: 80vh;
  box-shadow: 0 3px 6px #00000016;
  /* margin: 0.5rem auto; */
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: auto;
`;

export const SectionContainerGene = styled(Box)`
  width: 100%;
  min-height: 80vh;
  /* margin: 0.5rem auto; */
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
`;

export const HeaderSearch = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  min-height: auto;
  //Sombra antigua
  //box-shadow: 0 3px 6px #00000016;
  //Sombra nueva
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  //margin: 0.5rem 0.5rem;
  margin: ${(props) => props.margen || " 0.5rem 0.0rem"};

  //padding antiguo
  //padding: 0.5rem;
  //padding nuevo
  padding: 0.9rem;
  border-radius: 5px;
`;

export const HeaderSearchCustom = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  min-height: auto;
  box-shadow: ${(props) =>
    props.Shadow ||
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"};
  margin: ${(props) => props.margen || " 0.5rem 0.5rem"};
  padding: ${(props) => props.padding || "0.9rem"};
  border-radius: ${(props) => props.br || "5px"};
`;

export const SectionProccessProductive = styled(Box)`
  width: 100%;

  margin: 0.5rem auto;
  padding: 1rem;
  border-radius: 5px;
  /* overflow-x: auto; */
`;

export const ContenedorDane = styled.div`
  width: 80%;
  /* min-height: 80vh; */
  margin: 0.5rem auto;
  padding: 1rem;
`;
