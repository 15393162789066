import React, { useContext, useEffect } from "react";
import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import CustomsCrudContext from "context/CustomsCrudContext";
import { useNavigate } from "react-router-dom";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import { QrCustomsClearence } from "./QrCustoms/QrCustomsClearence";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { MdQrCodeScanner, MdContentCopy } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { AiOutlineFileSync } from "react-icons/ai";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ModalQR from "components/modal/ModalQR";

export const BtnShow = ({ values, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { status_customsClearance } = values;
  const {
    setDataToEdit,
    changeStatus,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason
  } = useContext(CustomsCrudContext);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()

  const handleEdit = async () => {
    const body = new FormData();
    body.append("form_id", values?.id);
    body.append("type_of", 'DESADUANAMIENTO');
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(values);
      // seteditItemsCustomsClearance(true);
      navigate(`/formCustomsClearance?Customs=${values.id}`);
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };

  const handlePresent = async (status) => {
    if (status === "AUTORIZADO" && values?.attachments?.length === 0) return openAlert('No se puede autorizar sin declaración de importación', 'error')
    const body = new FormData();
    body.append("form_id", values?.id);
    body.append("type_of", 'DESADUANAMIENTO');
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      const info = {
        id: values.id,
        status_customsClearance: status
      };
      if (status === "DEVUELTO") {
        onOpenReturnReason();
        setReturnReason(info);
      } else {
        changeStatus(info);
      }
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };
  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />

      <div className="row">
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            {(status_customsClearance === "BORRADOR" ||
              status_customsClearance === "DEVUELTO") && (
                <>
                  <Tooltip placement="top" hasArrow label="Editar">
                    <span>
                      <ButtonHeaderModule onClick={handleEdit} bgColor="#1F7AC3">
                        <IconGeneric
                          width="16px"
                          className="text-white"
                          as={RiEdit2Fill}
                        />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>

                  <Tooltip placement="top" hasArrow label="Copiar">
                    <span>
                      <ButtonHeaderModule
                        onClick={onOpenCopy}
                        className="mx-1"
                        bgColor="#03C39A"
                      >
                        <IconGeneric
                          width="16px"
                          className="text-white"
                          as={MdContentCopy}
                        />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>

                  <Tooltip placement="top" hasArrow label="Radicar">
                    <span>
                      <ButtonHeaderModule
                        onClick={() => handlePresent("AUTORIZADO")}
                        className="mx-1"
                        bgColor="#1F7AC3"
                      >
                        <IconGeneric
                          width="16px"
                          className="text-white"
                          as={GoChecklist}
                        />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>
                </>
              )}

            {status_customsClearance === "AUTORIZADO" && (
              <>
                <Tooltip placement="top" hasArrow label="Devolver">
                  <span>
                    <ButtonHeaderModule
                      onClick={() => handlePresent("DEVUELTO")}
                      bgColor="#d92525"
                    >
                      <IconGeneric
                        width="16px"
                        className="text-white"
                        as={AiOutlineFileSync}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>

                <Tooltip placement="top" hasArrow label="Copiar">
                  <span>
                    <ButtonHeaderModule
                      onClick={onOpenCopy}
                      className="mx-1"
                      bgColor="#03C39A"
                    >
                      <IconGeneric
                        width="16px"
                        className="text-white"
                        as={MdContentCopy}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            )}
          </Box>
        </div>
        {children}
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <span>
                <ButtonHeaderModule onClick={onOpen} bgColor="#1F7AC3">
                  <IconGeneric
                    width="16px"
                    className="text-white"
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};
