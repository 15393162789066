import styled from "styled-components/macro";

export const CardFormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  align-self: center;
  height: 100%;
  width: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    margin: 0 1rem;
  }
`;
