import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormDeparturesAssistant = ({ onClose, handleSearchSubmit }) => {
  const [openAlert] = AlertErrorAlert();
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["authorization_code", "item_id", "operation", "operation_initial", "date_final", "date_initial"], nestedProp: "searchAdvance" });


  const [values, handleInputChange] = useForm({
    authorization_code: state?.authorization_code || "",
    item_id: state?.item_id || "",
    operation: state?.operation || "",
    operation_initial: state?.operation_initial || "",
    date_final: state?.date_final || "",
    date_initial: state?.date_initial || "",
  });

  const {
    authorization_code,
    item_id,
    date_initial,
    date_final,
    operation,
    operation_initial
  } = values;

  const handleSubmit = () => {
    if (authorization_code === "") {
      openAlert("El número de autorización es requerido", "error");
    } else if (item_id === "") {
      openAlert("El número de item es requerido", "error");
    } else {
      // const data = {
      //   values,
      // };
      setParamsSearch({
        params: {
          ...handleSanitizedObj(values),
        }
      })

      handleSearchSubmit(values);
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"authorization_code"}
          value={authorization_code}
          onChange={handleInputChange}
          tag={"Nº de autorización *"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"item_id"}
          value={item_id}
          onChange={handleInputChange}
          tag={"Nº de item *"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          value={date_initial}
          name={"date_initial"}
          onChange={handleInputChange}
          tag={"Fecha inicial"}
          size={45}
          tipo={"date"}
        // maxiLength={"10"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        // maxiLength={"10"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"operation"}
          value={operation}
          onChange={handleInputChange}
          tag={"Operación"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />
        <LabelGroup
          name={"operation_initial"}
          value={operation_initial}
          onChange={handleInputChange}
          tag={"Operación inicial"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
