import axios from "axios";
import env from "../../constants/apiConst";

/**
 * Esta funcion sera la que ejecuté todas las funciones relacionadas con editar en el modulo de configuraciones.
 * Será llamada en el ConfigCRUDContext
 * recibe el endpoint en el que editará, el token y el body
 */
export const __DispatchAg = async (token, page) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_DISPATCH}?page=${page || 1}`, options);
  return res;
};

export const __EditCreateDispatch = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __ShowPassDispatch = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_DISPATCH}/${id}`, options);
  return res;
};
