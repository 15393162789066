export const arrayLeftBlocking = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified"
  },
  {
    value: "dependency",
    label: "Dependencia",
    name: "dependency"
  },
  {
    value: "action",
    label: "Acción",
    name: "action"
  },
  {
    value: "lock_type",
    label: "Tipo de bloqueo",
    name: "lock_type"
  }
];

export const arrayRigthBlocking = [
  {
    value: "observations",
    label: "Observación",
    name: "observations"
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date"
  },
  {
    value: "nickname",
    label: "Usuario operador",
    name: "nickname"
  }
];

export const blockingNodeInputFields = [
  {
    type: "date",
    name: "date1",
    search: "date1",
    tag: "Fecha inicial",
    parent: "date",
  },
  {
    type: "date",
    name: "date2",
    search: "date2",
    tag: "Fecha final",
    parent: "date",
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]

export const arrayLeftBlockingUser = [
  {
    value: "dependency",
    label: "Dependencia",
    name: "dependency"
  },
  {
    value: "action",
    label: "Acción",
    name: "action"
  },
  {
    value: "lock_type",
    label: "Tipo de bloqueo",
    name: "lock_type"
  }
];

/* export const arrayBlockingUser = [
  {
    value: "observations",
    label: "Observación",
    name: "observations",
  },
  {
    value: "nickname",
    label: "Usuario operador",
    name: "nickname",
  },
  {
    value: "dependency",
    label: "Dependencia",
    name: "dependency",
  },
  {
    value: "action",
    label: "Acción",
    name: "action",
  },
  {
    value: "lock_type",
    label: "Tipo de bloqueo",
    name: "lock_type",
  },
] */
