import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CardForm from "components/CardForm/CardForm";
import Login from "./Login/Login";
import ConfirmPassword from "./ConfirmPassword/ConfirmPassword";
import RecoverPassword from "./RecoverPassword/RecoverPassword";
import { Container } from "styled/Containers.styled";
// import { Logo } from "styled/Logo.styled";
import { ImageContainer, MainContainer } from "./HomeScreen.styled";
import PasswordRoute from "routes/PasswordRoute";
import PasswordFirstChange from "./PasswordFirstChange/PasswordFirstChange";
import PasswordFirstRoute from "routes/PasswordFirstRoute";
import { __getImagenLogin } from "request/__Login";
import { isValid } from "utils/type-check-utils";
import queryString from "query-string";

const HomeScreen = () => {
  // let match = useRouteMatch();
  const location = useLocation();
  const [defaultImage, setDefaultImage] = useState(
    "https://res.cloudinary.com/axiever/image/upload/v1691532280/ZONA_FRANCA_BARRANQUILLA_HERO_pyfblr.png"
  );

  const getImageLogin = async () => {
    const res = await __getImagenLogin();
   // console.log(res?.data?.status?.data);
    if (
      res?.data?.status?.data !== null &&
      res?.data?.status?.data?.image !== null &&
      res?.data?.status?.data?.image?.length !== 0
    ) {
      setDefaultImage(res?.data?.status?.data?.image);
    }
  };

  useEffect(() => {
    getImageLogin();
  }, []);

  useEffect(() => {
    const { pathname, state } = location || {};
    const { typeRedirect } = queryString.parse(location?.state?.redirectUrl) || {};

    if (pathname === "/auth" && isValid(state?.redirectUrl) && typeRedirect === "email") {
      localStorage.setItem("redirectUrl", state?.redirectUrl);
    }
  }, [location]);

  return (
    <ImageContainer>
      <Container>
        <div className="row px-2">
          <div className="col col-lg-7">
            <MainContainer>
              {/* <LeftSideContainer>
            <Logo />
          </LeftSideContainer> */}

              <CardForm>
                {/* Router v5 */}
                {/* <Switch>
              <Route exact path={`${match.path}`}>
                <Login />
              </Route>
              <Route path={`${match.path}/recover`}>
                <RecoverPassword />
              </Route>
            </Switch> */}

                <Routes>
                  <Route path="/" element={<Login />} />

                  <Route path="/recover" element={<RecoverPassword />} />
                  <Route
                    path="/recover/password"
                    element={
                      <PasswordRoute>
                        <ConfirmPassword />
                      </PasswordRoute>
                    }
                  />
                  <Route
                    path="/change-password"
                    element={
                      <PasswordFirstRoute>
                        <PasswordFirstChange />
                      </PasswordFirstRoute>
                    }
                  />
                </Routes>
              </CardForm>
            </MainContainer>
          </div>
          <div className="col">
            <img
              src={defaultImage}
              alt="imagen"
              style={{
                flexDirection: "column",
                width: "100%",
                height: "100vh",
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></img>
            {/* <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{ width: `${50}%` }}
                  src={window.assetURL + imagenLogocity}
                  alt="no hay formulario"
                />
              </div>
              {pathname === "/auth" ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ButtonUc
                    className="d-flex justify-content-center mt-3"
                    onClick={onOpen}
                  >
                    Iniciar mi calificación
                  </ButtonUc>
                  <ModalStartRating isOpen={isOpen} onClose={onClose} />
                </div>
              ) : (
                ""
              )} */}
          </div>
        </div>

        {/* <OurServices /> */}
      </Container>
    </ImageContainer>
  );
};

export default HomeScreen;
