/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowSubheadingC } from "request/configuration/__SubheadingControl";
import { useSelector } from "react-redux";

const FormSubheadingShow = () => {
  const initialForm = {
    block_approval: "",
    block_filing: "",
    income: "",
    departures: "",
    rest_of_the_world: "",
    colombia: "",
    username: "",
    name_freeZone: "",
    code_subheading: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToShow) {
      const initialpos = async () => {
        try {
          const res = await __ShowSubheadingC(token, dataToShow.id);
          setForm(res?.data?.status?.data);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.name_freeZone : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Zona franca</LabeFormlStyled>
                <InputFake>
                  {form.name_freeZone === null ? "N/A" : form.name_freeZone}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Codigo de Subpartida</LabeFormlStyled>
                <InputFake>
                  {console.log("form", form)}
                  {form?.subheading ?? 'N/A'}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Bloqueo aprovado</LabeFormlStyled>
              <InputFake>
                {form?.block_approval === null
                  ? "N/A"
                  : form?.block_approval === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Bloqueo de archivos</LabeFormlStyled>
              <InputFake>
                {form?.block_filing === null
                  ? "N/A"
                  : form?.block_filing === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Ingreso</LabeFormlStyled>
              <InputFake>
                {form?.income === null
                  ? "N/A"
                  : form?.income === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Departamentos</LabeFormlStyled>
              <InputFake>
                {form?.departures === null
                  ? "N/A"
                  : form?.departures === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Resto del mundo</LabeFormlStyled>
              <InputFake>
                {form?.rest_of_the_world === null
                  ? " N/A"
                  : form?.rest_of_the_world === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Colombia</LabeFormlStyled>
              <InputFake>
                {form.colombia === null
                  ? " N/A"
                  : form.colombia === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Nombre de usuario</LabeFormlStyled>
              <InputFake>
                {form?.username === null
                  ? " N/A"
                  : form?.username === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Zona Franca</LabeFormlStyled>
              <InputFake>
                {form?.free_zone === null
                  ? " N/A"
                  : form?.free_zone === 1
                    ? "Activo"
                    : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormSubheadingShow;
