import { Box, Grid, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import BoxSummary from './BoxSummary'

function CriteriaSummary ({ criteriaStatistic, set }) {
  const CalcLimit = x => {
    if (x >= 100) return 100
    if (x <= 0) return 1
    if (x > 0 && x < 100) return x
  }
  const [currentLevel, setCurrentLevel] = useState({
    criteria_id: [],
    risk_level: []
  })

  useEffect(() => {
    set(currentLevel)
  }, [currentLevel])

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      m={6}
      p={6}
      borderRadius={'10px'}
      shadow={
        'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      }
    >
      {criteriaStatistic?.length > 0 && (
        <Grid templateColumns='repeat(auto-fill, minmax(95px, 1fr))'>
          {criteriaStatistic.map(({ criteria_id, high, mild, moderate }) => {
            return (
              <BoxSummary
                status={{
                  currentLevel,
                  setCurrentLevel
                }}
                name={criteria_id}
                High={`${CalcLimit(high)}%`}
                moderate={`${CalcLimit(moderate)}%`}
                mild={`${CalcLimit(mild)}%`}
              />
            )
          })}
        </Grid>
      )}

      {/* <Text color={'blue.400'} textDecorationLine={'underline'}>
        Definición de criterio
      </Text> */}
    </Box>
  )
}

export default CriteriaSummary
