import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  InputFakeLink,
  InputWithLabel,
} from "components/Input/InputFakeCustoms";
import { __formaterCant } from "components/Funciones/formaterCant";
import FormCrudCRUDContext from "context/FormCrudContex";
import { ReviewPopover } from "../ReviewPopover";
import {
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
} from "utils/type-check-utils";
import { NumericFormat } from "react-number-format";
import { Grid, GridItem } from "@chakra-ui/react";

export const renderInputWithLabel = ({
  labelText,
  optionalName = null,
  name,
  manualValue = null,
  additionalProps = {},
  PropsBox = {},
  activeComment = false,
  wrapInDiv = true,
  disableFormatting = false,
  annotationInfo = {
    notations: null,
    id: null,
    data: null,
  },
}) => {
  const value =
    manualValue ||
    `${annotationInfo?.data?.[name] ?? "N/A"} ${annotationInfo?.data?.[optionalName] ?? ""
    }`;

  const commonProps = {
    ...(annotationInfo?.notations && {
      inputBgColor: annotationInfo?.notations?.getColorByFieldName(
        annotationInfo?.id,
        name
      ),
    }),
    ...(annotationInfo?.notations && {
      tooltipText: annotationInfo?.notations.getNestedPropertyValue(
        annotationInfo?.id,
        name
      ),
    }),

    value,
    labelText,
    name,
    p: 0,
    "data-name-id": labelText,
    borderRadius: "none",
    ...(activeComment && {
      customConfig: {
        icon: (
          <ReviewPopover
            notations={annotationInfo?.notations || {}}
            id={annotationInfo?.id}
          />
        ),
        text: "Revisión",
        color: "#009bfc",
      },
    }),
    ...additionalProps,
  };
  const isFormartable =
    isNumberFormatable(manualValue) || isNumberFormatable(commonProps?.value);

  const isScientifciNotation = isNumberFormatable(manualValue)
    ? parseFloat(manualValue)
    : parseFloat(commonProps?.value);

  const { value: _, ...rest } = commonProps;
  rest.value = isScientifciNotation;

  if (!wrapInDiv) {
    if (isFormartable && !disableFormatting) {
      return (
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          customInput={InputWithLabel}
          {...rest}
          data-item-id={annotationInfo?.id}
        />
      );
    }

    return (
      <InputWithLabel {...commonProps} data-item-id={annotationInfo?.id} />
    );
  }

  if (isFormartable && !disableFormatting) {
    return (
      <div
        {...(isObject(PropsBox) && isObjectNotEmpty(PropsBox)
          ? PropsBox
          : { className: "col-md-3" })}
      >
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          decimalSeparator="."
          customInput={InputWithLabel}
          {...rest}
          data-item-id={annotationInfo?.id}
        />
      </div>
    );
  }

  return (
    <div
      {...(isObject(PropsBox) && isObjectNotEmpty(PropsBox)
        ? PropsBox
        : { className: "col-md-3" })}
    >
      <InputWithLabel {...commonProps} data-item-id={annotationInfo?.id} />
    </div>
  );
};

const ViewItemsOutputForm = ({ notationsDestruct = null, data, alldata }) => {
  const location = useLocation();
  const { ExitNumber = "" } = queryString.parse(location.search);
  const [ItemsData, setItemsData] = useState([]);
  const [Status, setStatus] = useState([]);
  const { pathname } = location;
  const { dataForm } = useContext(FormCrudCRUDContext);

  useEffect(() => {
    const statusForm =
      alldata?.status_outputForm ||
      alldata?.status_entryForm ||
      data?.status_entryForm ||
      data?.status_outputForm;

    if (statusForm) {
      setStatus(statusForm);
    }

    if (data) setItemsData(data || alldata);
  }, [data, alldata]);

  const urlTemplates = {
    "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
    INGRESO: `/FormShow?FormEntry=${data?.form_id}`,
  };

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";
  const isFormExit = isShowExitForm || isCheckFormExit;
  const isFormEntry = isShowForm || isCheckFormEntry;

  const annotationInfo = {
    notations: notationsDestruct,
    id: ItemsData?.id,
    data: ItemsData,
  };

  return (
    <Grid
      templateAreas={`"item-metadata-info"
            "item-metadata-product"
            "countries"
            "body"
            "refund"
            "descriptions-justifications"`}
      gap={4}
    >
      <GridItem area={"item-metadata-info"}>
        <Grid
          gridTemplateColumns={"repeat(auto-fit, minmax(150px, 1fr))"}
          gap={2}
        >
          <InputFakeLink
            labelText="Referencia"
            url={urlTemplates?.[data?.type_form]}
            value={data?.reference || "N/A"}
            disableRedirect={!urlTemplates[data?.type_form]}
          />
          {/* Declaración de importación */}
          {(isShowExitForm || isCheckFormExit) && (
            <InputFakeLink
              labelText="Declaración de importación"
              url={`/ShowCustomsClearance?Customs=${data?.custom_clearance_id}`}
              value={data?.customs_clearance || "N/A"}
              disableRedirect={!data?.custom_clearance_id}
            />
          )}

          {/* Saldo */}
          {dataForm?.reentry_operation === 1
            ? ""
            : (isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Saldo`,
              name: "value_sdo",
              manualValue: data?.value_sdo ?? "N/A",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}



          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: "Código de operación",
              name: "operation_code",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: "Operación inicial",
              name: "initial_operation",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}
        </Grid>
      </GridItem>
      <GridItem area={"item-metadata-product"}>
        <Grid
          gridTemplateColumns={"repeat(auto-fit, minmax(170px, 1fr))"}
          gap={2}
        >
          {/* Código producto */}
          {
            /* (Status !== "PRESENTADO" || isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Código de producto",
              name: "code_product",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              },
              disableFormatting: true,
            })
          }


          {/* Saldo producto */}
          {dataForm?.reentry_operation === 1
            ? ""
            : (isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Saldo producto`,
              name: "value_sdo_product",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}


          {/* Unidad de producto */}
          {
            /* (Status !== "PRESENTADO" || isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Unidad de producto",
              name: "product_unit",
              manualValue: `${data?.code_unit_measurement || ""} ${data?.unit_measurement || "0"
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Factor de conversión */}
          {
            /* (Status !== "PRESENTADO" || isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Factor de conversión",
              name: "factor_conv",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }



          {/* Cantidad de producto */}
          {
            /* (Status !== "PRESENTADO" || isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Cantidad de producto",
              name: "product_quantity",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }
        </Grid>
      </GridItem>

      <GridItem area={"countries"}>
        <Grid
          gridTemplateColumns={"repeat(auto-fit, minmax(150px, 1fr))"}
          gap={2}
        >
          {/* Ítem salida temporal (abajo cuando no es de salida temporal, arriba cuando es de salida temporal) */}
          {((isShowForm || !isShowExitForm)) &&
            renderInputWithLabel({
              labelText: "Ítem salida temporal",
              name: "item_temporary_exit",
              activeComment: false,
              // annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}


          {/* Tipo de Producto */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Tipo de Producto",
              name: "name_typeProduct",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Subpartida arancelaria */}
          {
            /* (Status !== "PRESENTADO" || isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Subpartida arancelaria",
              name: "code_subheading",
              manualValue:
                data?.code_subheading || data?.code_subheading_corrected || "N/A",
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* País origen */}
          {(Status !== "PRESENTADO" || isShowForm || isShowExitForm) &&
            renderInputWithLabel({
              labelText: "País origen",
              name: "name_country",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* País procedencia */}
          {!isCheckFormExit &&
            !ExitNumber &&
            renderInputWithLabel({
              labelText: "País procedencia",
              name: "name_country_proc",
              manualValue: `${data?.code_country_proc} ${data?.name_country_proc}`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* País de compra */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "País de compra",
              name: "name_country_purs",
              manualValue: `${data?.code_country_purs ?? "N/A"} ${data?.name_country_purs ?? ""
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }


          {/* País destino */}
          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: "País destino",
              name: "name_country_des",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}
        </Grid>
      </GridItem>

      <GridItem area={"body"}>
        <Grid
          templateColumns='repeat(3, 1fr)'
          gap={2}
        >
          {/* Peso bruto en Kg */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Peso bruto en Kg",
              name: "gross_weight",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Peso neto Kg */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Peso neto en Kg",
              name: "net_weight",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Números de bultos */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Números de bultos",
              name: "number_packages",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }


          {/* Cantidad */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Cantidad",
              name: "quantity",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }


          {/* Unidad comercial */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Unidad comercial",
              name: "code_businessUnit",
              manualValue: `${data?.code_businessUnit ?? "N/A"} ${data?.name_businessUnit ?? ""
                }`,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }




          {/* Modo de transporte */}
          {
            /* (isShowForm || isShowExitForm) && */
            renderInputWithLabel({
              labelText: "Modo de transporte",
              name: "name_transportMethod",
              manualValue: `${data?.code_transportMethod ?? "N/A"} ${data?.name_transportMethod ?? ""
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Bandera */}
          {
            /* isShowExitForm && */
            renderInputWithLabel({
              labelText: "Bandera",
              name: "name_country_flag",
              manualValue: `${data?.code_country_flag ?? "N/A"} ${data?.name_country_flag ?? ""
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Embalaje */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: "Embalaje",
              name: "name_packaging",
              manualValue: `${data?.code_packaging ?? ""}  ${data?.name_packaging ?? "N/A"
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Fletes */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: "Fletes",
              name: "us_freight",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Seguros */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: "Seguros",
              name: "us_insurance",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Otros gastos */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: "Otros costos y gastos",
              name: "other_us_expenses",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }


          {/* Valor FOB Inicial US$ */}
          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Valor FOB Inicial US$`,
              name: "value_fob_unit_product",
              manualValue: data?.original_fob_value_us ?? "N/A",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}


          {/* Valor FOB */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: `Valor FOB ${data?.coin ?? ""}`,
              name: "us_fob_value",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Valor CIF US$ */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: `Valor CIF US`,
              name: "cif_us_value",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Tasa de Cambio */}
          {
            /* (isShowExitForm || isCheckFormExit) && */
            renderInputWithLabel({
              labelText: `Tasa de Cambio`,
              name: "exchange_rate",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Valor CIF US$ */}
          {
            /* !isShowExitForm && */
            renderInputWithLabel({
              labelText: `Valor CIF COP`,
              name: "cif_cop_value",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Valor agregado US$ */}
          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Valor agregado US$`,
              name: "added_value_us",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* Valor a reintegrar */}
          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Valor a reintegrar`,
              name: "refund_value_us",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* Acuerdo Comercial */}
          {
            /* (isShowExitForm || isCheckFormExit) && */
            renderInputWithLabel({
              labelText: `Acuerdo Comercial`,
              name: "name_tradeAgreement",
              manualValue: `${data?.code_tradeAgreement ?? ""} ${data?.name_tradeAgreement ?? "N/A"
                }`,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Saldo FOB */}
          {dataForm?.reentry_operation === 1
            ? ""
            : (isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Saldo FOB`,
              name: "fob_sdo_value",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}


        </Grid>
      </GridItem>


      <GridItem area={"refund"}>

        <Grid
          templateColumns='repeat(3, 1fr)'
          gap={2}
        >

          {/* Saldo CIF */}
          {dataForm?.reentry_operation === 1
            ? ""
            : (isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Saldo CIF`,
              name: "cif_value_sdo",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}




          {/* Peso bruto Sdo */}
          {/*  {(isShowForm || !isShowExitForm) &&
        renderInputWithLabel({
          labelText: "Peso bruto Sdo",
          name: "gross_weight_sdo",
          activeComment: false,
          // annotationInfo,
          PropsBox: {
            width: 100,
            size: 100,
          }
        })} */}

          {/* Cantidad Sdo */}
          {/* {(isShowForm || !isShowExitForm) &&
        renderInputWithLabel({
          labelText: "Cantidad Sdo.",
          name: "amount_sdo",
          // activeComment: isCheckFormExit || isCheckFormEntry,
          // annotationInfo,
          PropsBox: {
            width: 100,
            size: 100,
          }
        })}
 */}

          {/* Puerto */}
          {!isShowExitForm &&
            renderInputWithLabel({
              labelText: "Puerto",
              name: "port",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}




          {/* Factura */}
          {
            /* (isShowExitForm || isCheckFormExit) && */
            renderInputWithLabel({
              labelText: `Factura`,
              name: "invoice",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              },
              disableFormatting: true,
            })
          }

          {/* Marca */}
          {
            /* (isShowExitForm || isCheckFormExit) && */
            renderInputWithLabel({
              labelText: `Marca`,
              name: "brand",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Serial */}
          {
            /* (isShowExitForm || isCheckFormExit) && */
            renderInputWithLabel({
              labelText: `Serial`,
              name: "serial",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })
          }

          {/* Cantidad por reingresar */}
          {(isShowExitForm || isCheckFormExit) &&
            renderInputWithLabel({
              labelText: `Cantidad por reingresar`,
              name: "amount_refunded",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* Cantidad por reingresar */}
          {alldata?.services === 1 &&
            renderInputWithLabel({
              labelText: isFormExit
                ? `Salida de otros servicios:`
                : isFormEntry
                  ? `Ingresos otros servicios:`
                  : "",
              name: "service",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              }
            })}

          {/* Cantidad por reingresar */}
          {/*   {(isShowExitForm || isCheckFormExit) &&
        renderInputWithLabel({
          labelText: `Servicio`,
          name: "service",
          manualValue: data?.service ? __formaterCant(data?.service) : "N/A",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          PropsBox: {
            width: 100,
            size: 100,
          }
        })} */}
        </Grid>
      </GridItem>

      <GridItem area={"descriptions-justifications"}>
        {(isShowExitForm || isCheckFormExit) &&
          renderInputWithLabel({
            labelText: `Justificación ajusté FOB`,
            name: "adjustment_justification_fob",
            activeComment: isCheckFormExit || isCheckFormEntry,
            annotationInfo,
            PropsBox: {
              width: 100,
              size: 100,
              as: "textarea",
            },
          })}

        {/* Descripción de Producto */}
        {
          /* (isShowForm || isShowExitForm) && */
          renderInputWithLabel(
            {
              labelText: "Descripción de Producto",
              name: "product_description",
              manualValue: data?.description_product ?? data?.product_description ?? "N/A",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              PropsBox: {
                width: 100,
                size: 100,
              },
              additionalProps: {
                as: "textarea",
              },
            } /* ,
          {
            className: 'col-md-12"',
          } */
          )
        }

        {/*  <Input overflow={'hidden'} /> */}
        {
          /* (isShowExitForm || isCheckFormExit) && */
          renderInputWithLabel({
            labelText: `Descripción larga`,
            name: "form_item_description",
            manualValue:
              data?.entry_form_item_description ||
              data?.output_form_item_description ||
              data?.form_item_description ||
              "N/A",
            activeComment: isCheckFormExit || isCheckFormEntry,
            annotationInfo,
            PropsBox: {
              width: 100,
              size: 100,
            },
            additionalProps: {
              as: "textarea",
              overflow: "hidden",
              h: "auto",
            },
          })
        }
      </GridItem>
    </Grid>
  );
};

export default ViewItemsOutputForm;
