import { useState } from 'react';

/**
 * Custom hook for managing modal state.
 * @returns {Object} An object with properties and methods for managing the modal.
 * @property {boolean} isOpen - A boolean indicating whether the modal is open.
 * @property {function} openModal - A function to open the modal.
 * @property {function} closeModal - A function to close the modal.
 */

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return [isOpen, openModal, closeModal];
};
