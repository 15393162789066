import React, { useContext, useEffect } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";

import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";

import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const FormTarif = () => {
  const { dataToEdit, onCloseModal, createDataFormData, editDataFormdata } =
    useContext(ConfigurationCRUDContext);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    name_type_rate: "",
    description_type_rate: ""
  });
  const { name_type_rate, description_type_rate } = values;

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (!name_type_rate) return openAlert('Ingrese el nombre de la tarifa', 'error');
    const formData = new FormData();
    formData.append("name_type_rate", name_type_rate);
    formData.append("description_type_rate", description_type_rate);
    if (dataToEdit) {
      formData.append("rate_type_id", dataToEdit.type_rate_id);
      editDataFormdata(formData);
    } else {
      createDataFormData(formData);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar tipo de tarifa"
          : "Crear tipo de tarifa"}
      </Text>
      <Divider mb={3} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name="name_type_rate"
          tag={"Nombre tarifa"}
          value={name_type_rate}
          onChange={(e) => handleRegex(e, 'Letras', Regex?.letras) }
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeRate.name_type_rate}
        />
        <LabelGroup
          name="description_type_rate"
          tag={"Descripción tarifa"}
          value={description_type_rate}
          onChange={handleInputChange}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeRate.description_type_rate}
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};

export default FormTarif;
