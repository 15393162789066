/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowDependency } from "request/configuration/__Dependency";

export const FormDependencyShow = () => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialForm = {
    dependency: "",
    status: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  const ShowDependencys = async () => {
    try {
      const res = await __ShowDependency(token, dataToShow.id);
      setForm(res?.data?.status?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToShow) {
      ShowDependencys();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  return (
    <>
      <Text
        fontSize="xl"
        my={2}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        Dependencia
      </Text>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Dependencia</LabeFormlStyled>
              <InputFake>{form.dependency}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Estado</LabeFormlStyled>
              <InputFake>{form.status === 1 ? "Activo" : "Inactivo"}</InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};
