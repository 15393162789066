import React from "react";
import styled from "styled-components/macro";

const SpanItem = styled.span`
  color: black;
  cursor: pointer;

  &.active {
    color: #1f7ac3;
    font-weight: 700;
    background: none;
    width: 17% !important;
    height: auto !important;
    text-decoration: underline #1f7ac3;
    /* border-bottom: 3px solid #1f7ac3; */
  }
`;

const EditProductTablesMenu = ({ items, setState, state }) => {
  const handleItem = (selection) => {
    setState(selection);
  };
  return (
    <div className="d-flex justify-content-center align-items-center mb-5">
      {items.map((item) => (
        <SpanItem
          key={item}
          className={
            state === item
              ? "mx-md-5 mx-5 text-center active"
              : "mx-md-5 mx-5 text-center"
          }
          onClick={(e) => handleItem(item)}
        >
          {item}
        </SpanItem>
      ))}
    </div>
  );
};

export default EditProductTablesMenu;
