/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { H2 } from "./stylePases";
import "./stilo.css";
import { Box, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TablePass from "components/Tables/TablesPass";
import Pagination from "components/Pagination/Pagination";
import CreateyPresented from "./CreateyPresented";
import { MdDelete } from "react-icons/md";
import {
  TitleTablePassDispacthShow,
  TitleTablePassDispacthShowIsCommerce,
  TitleTablePassShow,
  TitleTablePassShowIsCommerce,
} from "./View/ArrayHeadTable/TheadMovementInt";
import { VehiculoInfo } from "./View/Show/VehiculoInfo";
import { useForm } from "hooks/useForm";
import { __EditPhoto, __ShowPassInt } from "request/Pases/__CrudPass";
import PassCrudContext from "context/PassCrudContext";
import { AuthContext } from "context/AuthContext";
import bro from "../../assets/bro.png";
import { BtnHeaderShow } from "./BtnHeaderShow";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __ShowPassDispatch } from "request/DespachoAgrupado/__DispatchAg";
import environment from "constants/apiConst";
import {
  GrDocumentWord,
  GrDocumentPdf,
  GrDocumentWindows,
  GrDocumentText,
} from "react-icons/gr";
import { IconGeneric } from "styled/Icons.styled";
import { ExportPassPdf } from "./ExportPass/ExportPassPdf";
import { AddAttachment } from "./View/Adjunto/AddAttachment";
import { AddPhotography } from "./View/Adjunto/AddPhotography";
import { ButtonStyled } from "styled/Buttons.styled";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { SubTitle, Title } from "styled/Title";
import { TbodyItemShow } from "./tbody/TbodyItemShow";
import { TbodyDispacthShow } from "./tbody/TbodyDispacthShow";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormReturnReason } from "./FormReturnReason";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { AlertSecurityAssociate } from "components/AlertSecurityAssociate/AlertSecurityAssociate";
import AddDocumentsGeneric from "./View/Adjunto/Add_Generic";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import { Commentary } from "components/Commentary/Commentary";
import { __Post } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import useStatusListener from "hooks/useStatusListener";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import {
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import Vapor from "laravel-vapor";
import { Storage } from "hooks/useStorage";
import Attachment from "components/DispatchCorrections/Attachment";
import InputSelect from "components/InputSelect/InputSelect";
import { convertArrayToGroupedObject } from "pages/Entry_and_exit_forms/FormEntry/BodyForm";
import MultiFileUpload from "pages/CustomsClearance/Adjunto/MultiFileUpload";
import FileUploadManager from "pages/CustomsClearance/Adjunto/FileUploadManager";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";

const extensionOptions = [
  ".doc",
  ".docx",
  ".pdf",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".bmp",
  ".xls",
  ".xlsx",
  ".xlsm",
  "image/png",
  "image/*",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-excel",
];
const Pases = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phtotoGra, setPhotoGra] = useState(null);
  const [attached, setAttached] = useState([]);
  const [attachedes, setAttachedes] = useState([]);
  const [Generic, setGeneric] = useState([]);

  const { token, is_commerce } = useSelector((state) => state.auth.dataUser);
  const [confirmationDriver, setConfirmationDriver] = useState(0);
  const [confirmationCompany, setconfirmationCompany] = useState(0);
  const location = useLocation();
  const [manipuleo, setManipuleo] = useState(0);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const [openAlert] = AlertErrorAlert();
  const [flagFiles, setFlagFiles] = useState(false);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const [values, handleInputChange, reset, setValues] = useForm({
    vehicle_plate: "",
    identification: "",
    driver: "",
    number_packages: "",
    observations: "",
    handling_process: "",
    freely_available: "",
    name_typeTransport: "",
    created_at: "",
    phone: "",
    name_qualified: "",
    confirmation_driver: "",
    confirmation_company: "",
    reason_for_return: "",
    consecutive: "",
    tare_weight: "",
    net_weight: "",
    gross_weight: "",
    weighing_id: "",
    special: "",
  });

  const {
    dataToShow,
    setRequestEdit,
    setRequestPhoto,
    setRequestChangeStatus,
    update,
    editPhoto,
    setRequestDeleteAttachment,
    deletePhoto,
    setRequestDeletePhoto,
    currentItem,
    setCurrentItem,
    maxPageItem,
    obtenerItemPass,
    setRequestObtenerItemPass,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    deleteAttachmentPass,
    alreadySailed,
    setAlreadySailed,
  } = useContext(PassCrudContext);

  const {
    createCommentary,
    update: updateCommentary,
    newCommentary,
  } = useContext(FormCrudCRUDContext);

  const [comentarios, setComentarios] = useState([]);

  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const { authUser } = useContext(AuthContext);
  const {
    typeO = null,
    status = "",
    id = "",
    numberForm = "",
    qualifiedUser: qualifiedUserUrl = "",
  } = queryString.parse(location.search);
  const formID = id;
  const navigate = useNavigate();

  useEffect(() => {
    if (typeO === "Dispatch") {
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT_DISPACTH);
      setRequestEdit(environment.UPDATE_DISPATCH);
      setRequestPhoto(environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY);
      setRequestChangeStatus(environment.CHANGESTATUSDISPACTH);
      setRequestDeletePhoto(environment.DELETE_PHOTO_DISPACTH);
      setRequestObtenerItemPass(environment.GET_ITEM_DISPACTH_EXIT);
    } else {
      setRequestDeletePhoto(environment.DELETE_PHOTO);
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT);
      setRequestEdit(environment.UPDATE_PASES_INT);
      setRequestPhoto(environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY);
      setRequestChangeStatus(environment.CHANGESTATUSPASS);
      setRequestObtenerItemPass(environment.OBTENER_ITEM_ENTRADA_PASES_INT);
    }
    if (dataToShow || id) {
    } else {
      setValues(values);
    }
  }, [dataToShow, update, id]);

  const { requestData } = useGlobalContext();
  const firstRender = useRef(true);
  const endPoint =
    typeO === "Dispatch"
      ? environment.SHOW_DISPATCH
      : environment.SHOW_PASES_INT;

  const {
    data: dataViewPass,
    refetch: refetchViewPass,
    isFetching: isFetchingViewPass,
    isPending: isPendingViewPass,
    isSuccess: isSuccessViewPass,
    isError: isErrorViewPass,
  } = useQuery({
    queryKey: ["showPass", id, dataToShow, update],
    queryFn: () =>
      requestData({
        Endpoint: endPoint,
        resourceIdentifier: id,
      }),
    refetchOnWindowFocus: false,
  });

  const isStatusPass =
    dataViewPass?.data?.status?.data?.status_entryPass ||
    dataViewPass?.data?.status?.data?.status_dispatchPass;

  useEffect(() => {
    const calculateRoute =
      isStatusPass === "REVISADO"
        ? `/${
            typeO === "Dispatch" ? "CheckPassOutput" : "CheckPassEntry"
          }?numberForm=${numberForm}&id=${id}&status=${isStatusPass}&typeO=${typeO}`
        : `/pases?numberForm=${numberForm}&id=${id}&status=${isStatusPass}&typeO=${typeO}`;

    if (
      location?.pathname + location?.search !== calculateRoute &&
      isSuccessViewPass &&
      !alreadySailed
    ) {

      navigate(calculateRoute);
      setAlreadySailed(true);
    }
  }, [isStatusPass]);

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (
        (update && status === "AUTORIZADO" && authUser.admin) ||
        authUser.is_commerce
      ) {
        const element = document.getElementById("element-id");
        if (element) {
          element.parentNode.removeChild(element);
        }
      }

      const dataPass = dataViewPass?.data?.status?.data;

      if (isErrorViewPass) {
        openAlert(dataPass?.data?.status.message, "error");
        navigate(-1);
        return;
      }

      if (isSuccessViewPass) {
        setPhotoGra(dataPass?.photographies);
        setValues(dataPass);
        const documentShippingForm = convertArrayToGroupedObject(
          dataPass?.shipping_form || [],
          "shipping_form"
        );

        setGeneric(documentShippingForm);
        const outputObject = convertArrayToGroupedObject(
          dataPass?.attachments || [],
          "attachments"
        );

        setAttached(outputObject);
        setManipuleo(dataPass?.handling_process);
        setConfirmationDriver(parseInt(dataPass?.confirmation_driver));
        setconfirmationCompany(parseInt(dataPass?.confirmation_company));
      }
    }
  }, [isPendingViewPass, dataViewPass?.data?.status?.data]);

  const removeItemFromArrEdit = (arr, item, index, archivo, id) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${item}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (arr[index].name) {
          const resultado = arr.filter((e) => e.name !== item);
          setAttachedes(resultado);
        } else {
          const resultado = arr.filter((e) => e.name_attachment !== item);
          setAttachedes(resultado);
          const formData = new FormData();
          formData.append("attachment", archivo);
          deleteAttachmentPass(formData, id);
        }
      }
    });
  };

  const deleteImaServer = (arr, index) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar la fotografía "${index.name_photography}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const resultado = arr.filter(
          (e) => e.name_photography !== index.name_photography
        );
        setPhotoGra(resultado);
        const formData = new FormData();
        formData.append("photography", index.name_photography);
        deletePhoto(formData, index.id);
      }
    });
  };

  useEffect(() => {
    if (typeO === "Dispatch") {
      obtenerItemPass(id, page, environment.GET_ITEM_DISPACTH_EXIT, {
        orderBy: orderBy?.value,
      });
    } else {
      obtenerItemPass(id, page, environment.OBTENER_ITEM_ENTRADA_PASES_INT, {
        orderBy: orderBy?.value,
      });
    }
  }, [id, typeO, page, orderBy?.value]);

  const redirectShowReviwOutput = `/CheckPassOutput?numberForm=${values?.dispatch_pass?.passForm_number}&id=${values?.dispatch_pass?.id}&status=${values?.dispatch_pass?.status_dispatchPass}&typeO=Dispatch`;
  const redirectShowReviwEntry = `/CheckPassEntry?numberForm=${values?.entry_pass?.passForm_number}&id=${values?.entry_pass?.id}&status=${values?.entry_pass?.status_entryPass}&typeO=`;

  const viewCommentary = async (path) => {
    try {
      const res = await __CommentaryForm(token, id, path);
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!numberForm) return;
    if (typeO === "Dispatch") {
      viewCommentary(environment.GET_COMMENTS_PASES_DISPATCH);
    } else {
      viewCommentary(environment.GET_COMMENTS_PASES_INT);
    }
  }, [id, typeO, numberForm, updateCommentary, newCommentary]);
  const queryClient = useQueryClient();

  const updateAndCreate = (data = undefined, payloadManual = false) => {
    const formData = new FormData();
    formData.append("id", id || numberForm);

    if (isObject(data) && isObjectNotEmpty(data)) {
      if (data) {
        Object.keys(data).forEach((documentId) => {
          if (!data[documentId].from_server) {
            formData.append("photography[]", JSON.stringify(data[documentId]));
          }
        });
      }
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint:
        typeO === "Dispatch"
          ? environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY
          : environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: () => {
        setPhotoGra([]);
        setAttached([]);
        setGeneric([]);
        queryClient.invalidateQueries({ queryKey: ["showPass"] });
      },
    });
  };

  
  const { get: getStorage } = Storage;
  
  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };



  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${id || numberForm}`) || "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${id || numberForm}`,
        JSON.stringify(filteredErrors)
      );
    }
  };

  const updateFileState = (uploadResponse, name, targetId) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};

    const file = {
      [targetId]: {
        file: uploadResponse,
        name,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file);

    return localStorage.removeItem("uploadData");
  };

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const handleFilesInput = async (e, file) => {
    e.preventDefault();

    try {
      const { files: uploadedFiles, id } = e.target;

      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;

      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);
      if (!isValidExtension) return;

      const idDocument = extractDocumentId(id);
      const uploadResponse = await uploadFile(uploadedFiles[0]);
      updateFileState(uploadResponse, file?.name, e.target.id);

      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
    }
  };

  const isAuthorizationDeletedDocument =
    ((values?.status_entryPass !== undefined &&
      values?.status_entryPass !== "BORRADOR") ||
      (values?.status_dispatchPass !== undefined &&
        values?.status_dispatchPass !== "BORRADOR")) &&
    is_commerce === 1;

  const StatusPass = values?.status_entryPass || values?.status_dispatchPass;

  /**
   * Represents a book.
   * @constructor
   * @param {string} is_qualified_user - is on auth.dataUser and 1 is true and 0 is false.
   */
  const THead = {
    Dispatch: () => {
      const dispatchThead = {
        0: TitleTablePassDispacthShowIsCommerce,
        1: TitleTablePassDispacthShow,
      };
      return dispatchThead[is_qualified_user];
    },
    Default: () => {
      const defaultThead = {
        0: TitleTablePassShowIsCommerce,
        1: TitleTablePassShow,
      };
      return defaultThead[is_qualified_user];
    },
  };

  const refetchForm = async (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch = flag === "entry_pass" || flag === "dispatch_pass";
    if (new_status === "forms_on_review") return;

    if (shouldRefetch) {
      const data = await refetchViewPass();
      const res = data?.data?.data?.status;
      const code = res?.code;

      console.log({ data, res, code, newStatus: new_status });
      if (code !== 200) return;

      if (new_status === "REVISADO" && flag === "dispatch_pass") {
        return navigate(
          `/CheckPassOutput?numberForm=${res?.data?.passForm_number}&id=${form_id}&status=${new_status}&typeO=Dispatch`
        );
      }

      if (new_status === "REVISADO" && flag === "entry_pass") {
        return navigate(
          `/CheckPassEntry?numberForm=${res?.data?.passForm_number}&id=${form_id}&status=${new_status}&typeO=`
        );
      }

      return navigate(
        `/pases?numberForm=${
          res?.data?.passForm_number
        }&id=${form_id}&status=${new_status}&typeO=${
          flag === "dispatch_pass" ? "Dispatch" : ""
        }`
      );
    }
  };

  useStatusListener({
    id,
    callBack: refetchForm,
    dataCurrent: values,
  });

  const endpointAttachments = {
    Dispatch: {
      create: environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT_DISPACTH,
    },
    default: {
      create: environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT,
    },
  }[typeO || "default"];

  const handleResponseItems = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      const resData = res?.data?.status?.data;
      const documentShippingForm = convertArrayToGroupedObject(
        resData?.shipping_form || [],
        "shipping_form"
      );

      setGeneric(documentShippingForm);
      const outputObject = convertArrayToGroupedObject(
        resData?.attachments || [],
        "attachments"
      );
      setAttached(outputObject);
      setPhotoGra(resData?.photographies);
    }
  };

  const fetchItems = () => {
    const config = {
      resourceIdentifier: id,
      Endpoint:
        typeO === "Dispatch"
          ? environment.SHOW_DISPATCH
          : environment.SHOW_PASES_INT,
    };

    mutate(config, {
      onSuccess: (data) => handleResponseItems(data),
    });
  };


  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      setAttached([]);
      setGeneric([])
      queryClient.invalidateQueries({ queryKey: ["showPass"] });
      //  return setCalculated(res?.data?.status?.data);
    }
  };

  const cleanFile = (file, customEndpoint = null) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ?? file?.name_attachment
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint: customEndpoint
            ? customEndpoint
            : endpointAttachments?.delete,
          resourceIdentifier: file?.id,
          PropertyBody: "sendFormDataContentPOST",
        };

        mutate(requestData, {
          onSuccess: () => {
            setAttached([]);
            setGeneric([]);
            queryClient.invalidateQueries({ queryKey: ["showPass"] });
          },
        });
      }
    });
  };

  const handleUploadComplete = (files) => {
    console.log("Archivos subidos:", files);
  };

  const getAuthToken = async () => {
    // Implementa la lógica para obtener el token de autenticación
    return token;
  };

  const formatPayload = (files) => {
    return {
      files: files,
      otherData: "algún otro dato",
    };
  };

  const customizePayload = async (payload) => {
    // Aquí puedes modificar o agregar información al payload
    /*  const additionalData = await fetchSomeAdditionalData();
    return {
      ...payload,
      additionalData,
      timestamp: new Date().toISOString()
    }; */
  };

  const endpointConfig = {
    Endpoint: endpointAttachments?.create,
    PropertyBody: "POST",
  };

  return (
    <>
      <ExportPassPdf id="display-vacio" values={values} />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={PassCrudContext}
        />
      </ModalGeneric>

      <Commentary
        id={id}
        ruta={
          typeO === "Dispatch"
            ? environment.COMMENTS_PASES_DISPATCH
            : environment.COMMENTS_PASES_INT
        }
        create={createCommentary}
        commentarys={comentarios}
      />

      <div>
        <HeaderForm
          status={StatusPass}
          numberForm={values?.passForm_number}
          UserQualified={values?.name_qualified}
          Modulo={`Formulario de ${
            typeO === "Dispatch" ? " Despachos" : "Entradas"
          } de Mercancía en Zonas Francas`}
        />
        <BtnHeaderShow values={values} Status={StatusPass} id={id}>
          {values?.reason_for_return &&
            (values?.status_entryPass === "BORRADOR" ||
              values?.status_entryPass === "DEVUELTO" ||
              values?.status_dispatchPass === "BORRADOR" ||
              values?.status_dispatchPass === "DEVUELTO") && (
              <AlertReturnReason
                title={"Tipo de devolución:"}
                item={values.typifications}
                description={
                  values.reason_for_return && values.reason_for_return
                }
                nickname={values.nickname}
              />
            )}
          <CreateyPresented values={values} />
        </BtnHeaderShow>

        {values?.security && (
          <AlertSecurityAssociate
            title={"Asociado a Seguridad"}
            item={values.security}
            idDoc={values.id}
            numberDoc={values?.passForm_number}
            nickname={""}
            documento={typeO}
            initialpos={refetchViewPass}
          />
        )}

        {/* <Security_association /> */}
        <HeaderSearch>
          <VehiculoInfo
            values={values}
            setValues={setValues}
            setManipuleo={setManipuleo}
            manipuleo={manipuleo}
            setConfirmationDriver={setConfirmationDriver}
            confirmationDriver={confirmationDriver}
            setconfirmationCompany={setconfirmationCompany}
            confirmationCompany={confirmationCompany}
            typeOperation={typeO === "Dispatch" ? " Despacho" : "Entrada"}
          />
        </HeaderSearch>

        {((typeof values?.dispatch_pass === "object" &&
          values?.dispatch_pass) ||
          (typeof values?.entry_pass === "object" && values?.entry_pass)) && (
          <HeaderSearch style={{ background: "rgba(50%, 50%, 50%, 0.1)" }}>
            <Title size={"18px"} weight={600}>
              {values?.dispatch_pass ? "Despacho asociado" : "Entrada asociada"}
            </Title>
            <SimpleGrid
              borderRadius={2}
              minChildWidth="120px"
              spacing="20px"
              p={4}
            >
              <Box>
                <NavLink
                  to={`${
                    values?.dispatch_pass?.passForm_number
                      ? redirectShowReviwOutput
                      : redirectShowReviwEntry
                  }`}
                >
                  <InputWithLabel
                    p={0}
                    m={0}
                    fontWeight={0}
                    borderRadius={"none"}
                    labelText={"Nº Formulario:"}
                    inputBgColor={"black"}
                    _hover={{ cursor: "pointer", color: "blue" }}
                    value={
                      values?.dispatch_pass?.passForm_number ??
                      values?.entry_pass?.passForm_number ??
                      "N/A"
                    }
                    style={{
                      borderBottom: " 1px solid #bababa80",
                      color: "#3f8dd7",
                    }}
                  />
                </NavLink>
                {/* <InputWithLabel
                    p={0}
                    m={0}
                    fontWeight={0}
                    borderRadius={'none'}
                    name=''
                    labelText={'Nº Formulario:'}
                    inputBgColor={'black'}
                    _hover={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() =>
                      navigate(
                        values?.dispatch_pass?.passForm_number
                          ? redirectShowReviwOutput
                          : redirectShowReviwEntry
                      )
                    }
                    value={
                      values?.dispatch_pass?.passForm_number ??
                      values?.entry_pass?.passForm_number ??
                      'N/A'
                    }
                    style={{
                      borderBottom: ' 1px solid #bababa80'
                    }}
                  /> */}
              </Box>{" "}
              <Box>
                <InputWithLabel
                  p={0}
                  m={0}
                  borderRadius={"none"}
                  fontWeight={0}
                  name=""
                  labelText={"Tipo de transporte:"}
                  inputBgColor={"black"}
                  value={
                    values?.dispatch_pass?.name_typeTransport ??
                    values?.entry_pass?.name_typeTransport ??
                    "N/A"
                  }
                  style={{
                    borderBottom: " 1px solid #bababa80",
                  }}
                />
              </Box>
              <Box>
                <InputWithLabel
                  p={0}
                  m={0}
                  fontWeight={0}
                  borderRadius={"none"}
                  name=""
                  labelText={"Estado:"}
                  inputBgColor={"black"}
                  value={
                    values?.dispatch_pass?.status_dispatchPass ??
                    values?.entry_pass?.status_entryPass ??
                    "N/A"
                  }
                  style={{
                    borderBottom: " 1px solid #bababa80",
                  }}
                />
              </Box>
              <Box>
                <InputWithLabel
                  p={0}
                  m={0}
                  fontWeight={0}
                  borderRadius={"none"}
                  name=""
                  labelText={"Codigo de seguridad:"}
                  inputBgColor={"black"}
                  value={
                    values?.dispatch_pass?.code_security ??
                    values?.entry_pass?.code_security ??
                    "N/A"
                  }
                  style={{
                    borderBottom: " 1px solid #bababa80",
                  }}
                />
              </Box>
            </SimpleGrid>
          </HeaderSearch>
        )}
        <HeaderSearch>
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Title size={"18px"} weight={600}>
                Ítem del Formulario
              </Title>
              <Box w={"200px"}>
                <InputSelect
                  data={[
                    { value: "ASC", label: "Ascendente" },
                    { value: "DESC", label: "Descendente" },
                  ]}
                  value={orderBy}
                  name="order_by"
                  set={setOrderBy}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: "#5f5f",
                  }}
                  isClearable={false}
                />
              </Box>
            </Box>
            {currentItem.length === 0 ? (
              <div>
                <div className="d-flex justify-content-center ">
                  <img
                    style={{ width: `${15}%` }}
                    src={window.assetURL + bro}
                    alt="no hay formulario"
                  />
                </div>
                <SubTitle size={"15px"} className="text-center ">
                  Aún no hay Formulario asociados a esta lista
                </SubTitle>
              </div>
            ) : (
              <>
                <TablePass
                  thead={THead[typeO]?.() || THead.Default?.()}
                  data={currentItem}
                  setData={setCurrentItem}
                  loading={loading}
                  tbodyData={
                    typeO === "Dispatch" ? TbodyDispacthShow : TbodyItemShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPageItem}
                  loading={loading}
                />
              </>
            )}
          </div>
        </HeaderSearch>

        <HeaderSearch>
          <Box>
            <>
              <Box display={"grid"} gap={4}>
                <FileUploadManager
                  onUploadComplete={handleUploadComplete}
                  vaporBaseUrl={environment.VAPOR_BASE_URL}
                  getAuthToken={getAuthToken}
                  formatPayload={formatPayload}
                  customizePayload={customizePayload}
                  title="Adjunto"
                  endpointConfig={endpointConfig}
                  formID={formID}
                  postPropertyName="attachment"
                  endpointAttachments={{
                    create: endpointAttachments?.create,
                  }}
                  handleResponseUpdateForm={handleResponseUpdateForm}
                />

                <ExistingFiles
                  existingFiles={attached?.attachments}
                  isLoading={isPendingViewPass}
                  handleDeleteFile={cleanFile}
                />
              </Box>

              {typeO !== "Dispatch" && (
                <Box display={"grid"} gap={4} pt={12}>
                  <FileUploadManager
                    onUploadComplete={handleUploadComplete}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={getAuthToken}
                    formatPayload={formatPayload}
                    customizePayload={customizePayload}
                    title="Planilla de envío *"
                    endpointConfig={endpointConfig}
                    formID={formID}
                    postPropertyName="shipping_form"
                    endpointAttachments={{
                      create: environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
                    }}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                  />

                  <ExistingFiles
                    existingFiles={Generic?.shipping_form}
                    isLoading={isPendingViewPass}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
              )}
            </>
          </Box>
        </HeaderSearch>

        <HeaderSearch>
          <div>
            <AddPhotography
              photography={phtotoGra}
              setPhotography={setPhotoGra}
              updateAndCreate={handleFilesInput}
              isLoading={isFetchingViewPass}
              title={
                values?.status_entryPass === "AUTORIZADO" ||
                values?.status_dispatchPass === "AUTORIZADO"
                  ? "Registro fotográfico"
                  : "Evidencia Fotográfica"
              }
              disableUpload={
                !(
                  values?.status_entryPass === "PRESENTADO" ||
                  values?.status_dispatchPass === "PRESENTADO"
                ) &&
                (authUser.admin === 1 || authUser.is_commerce === 1)
              }
            />
          </div>
        </HeaderSearch>
      </div>
    </>
  );
};

export default Pases;
