/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Heading } from '@chakra-ui/react'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { CardTotal } from './CardTotal'
import { CardDown } from './CardDown'
import { __Get } from 'request/Petitions/__Get'
import environment from 'constants/apiConst'
import FormsWith from './FormsWith'
import Tickets from './Tickets'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { MinCardMximu } from 'styled/CardTotalesDash'
import MiniCards from './MiniCards'
import TablePresentationTime from './TablePresentationTime'
import { Title } from 'styled/Title'
import { FaSadTear } from 'react-icons/fa'
import TablePendingFormsDue from './TablePendingFormsDue'
import TableSystemAuthorizedForms from './TableSystemAuthorizedForms'

const colorArray = [
  '#006EB8',
  '#47B3E7',
  '#9CD8F7',
  '#7c8ce8',
  '#5c70c8',
  '#3a55a8'
]

export const DashboardViewP = () => {
  const { token } = useSelector(state => state.auth.dataUser)
  const [isLoading, setIsLoading] = useState(false)
  const [openTicket, setOpenTicket] = useState([])
  const [openTicketDay, setOpenTicketDay] = useState([])
  const [closeTicket, setCloseTicket] = useState([])
  const [Reweighing, setReweighing] = useState([])
  const [ucWhatMoreWeight, setUcWhatMoreWeight] = useState([])
  const [incomeBalance, setIncomeBalance] = useState(null)
  const [exitBalance, setExitBalance] = useState(null)
  const [entryPasses, setEntryPasses] = useState(null)
  const [dispatchPasses, setDispatchPasses] = useState(null)
  const [trackingOnFMMI, setTrackingOnFMMI] = useState(null)
  const [trackingOnFMMS, setTrackingOnFMMS] = useState(null)
  const [trackingOffFMMI, setTrackingOffFMMI] = useState(null)
  const [trackingOffFMMS, setTrackingOffFMMS] = useState(null)
  const [trackingOnDocFMMI, setTrackingOnDocFMMI] = useState(null)
  const [trackingOnDocFMMS, setTrackingOnDocFMMS] = useState(null)
  const [totalCurrentWeighings, setTotalCurrentWeighings] = useState(null)
  const [presentForDayData, setPresentForDayData] = useState(null)
  const [presentForUserData, setPresentForUserData] = useState(null)
  const [documentReturned, setDocumentReturned] = useState(null)
  const [documentSubmitted, setDocumentSubmitted] = useState(null)
  const [documentApproved, setDocumentApproved] = useState(null)
  const [documentSubmitteData, setDocumentSubmitteData] = useState(null)
  const [documentReturnedData, setDocumentReturnedata] = useState(null)
  const [documentsApproved, setDocumentsApproved] = useState(null)
  const [counterFMM, setCounterFMM] = useState(null)
  const [fmmi, setFmmi] = useState(0)
  const [fmms, setFmms] = useState(0)
  const [fci, setFci] = useState(0)
  const [pendingFormsDue, setPendingFormsDue] = useState(null)
  const [systemAuthorizedForms, setSystemAuthorizedForms] = useState(null)
  const { user_rol } = useSelector((state) => state.auth.dataUser);
  const [namePermissions, setNamePermissions] = useState([]);

  useEffect(() => {
    let obj = {}
    user_rol?.modules?.find((item) => item?.modul_permissionGroup === "Resumen de actividad")
      ?.permission?.forEach((item) => {
        obj = {
          ...obj,
          [item?.name_permission]: true
        }
      })
    setNamePermissions({ ...obj, default: false })
  }, [user_rol]);

  const fetchData = async (
    url,
    set,
    propertyLabels = '',
    propertyData = '',
    mode = 'advancing'
  ) => {
    setIsLoading(true)
    try {
      const response = await __Get(url, token)
      const { status } = response.data

      if (status.code === 200) {
        if (mode === 'advancing') {
          processData(status.data, set, propertyLabels, propertyData)
        }

        if (mode === 'simple') {
          set(status.data)
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const processData = (data, set, propertyLabels, propertyData) => {
    if (data) {
      const formattedData = {
        labels: data?.map(item => item[propertyLabels]) || '',
        datasets: [
          {
            label: '',
            data: data?.map(item => item[propertyData]),
            backgroundColor: colorArray
          }
        ]
      }
      set(formattedData)
    }
  }

  const DocumentGraph = (data, set) => {
    if (data) {
      const formattedData = {
        labels: [
          'Pases despacho',
          'Pases de entrada',
          'Formularios de ingreso',
          'Formularios de salida',
          'Certificados de integración'
        ] || '',
        datasets: [
          {
            label: '',
            data,
            backgroundColor: colorArray
          }
        ]
      }

      set(formattedData)
    }
  }

  useEffect(() => {
    const fetchDataWrapper = async (
      url,
      set,
      propertyLabels,
      propertyData,
      mode = 'advancing'
    ) => {
      await fetchData(url, set, propertyLabels, propertyData, mode)
    }

    const fetchDataList = [
      {
        url: environment.NEWS_SECTION_PRESENT_FOR_DAY,
        set: setPresentForDayData,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_PRESENT_FOR_USER,
        set: setPresentForUserData,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_RETURNED,
        set: setDocumentReturned,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_SUBMITTED,
        set: setDocumentSubmitted,
        mode: 'simple'
      },
      {
        url: environment.NEW_SECTION_DOCUMENT_APPROVED,
        set: setDocumentApproved,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_OPEN_TICKETS,
        set: setOpenTicket,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_OPEN_TICKETS_DAY,
        set: setOpenTicketDay,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_CLOSED_TICKETS,
        set: setCloseTicket,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_REWEIGHING,
        set: setReweighing,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_UC_WHAT_MORE_WEIGHT,
        set: setUcWhatMoreWeight,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_INCOME_BALANCE,
        set: setIncomeBalance,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DOCUMENT_EXIT_BALANCE,
        set: setExitBalance,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_ON_FMMI,
        set: setTrackingOnFMMI,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_ON_FMMS,
        set: setTrackingOnFMMS,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_OFF_FMMI,
        set: setTrackingOffFMMI,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_OFF_FMMS,
        set: setTrackingOffFMMS,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_ON_DOC_FMMI,
        set: setTrackingOnDocFMMI,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TRACKING_ON_DOC_FMMS,
        set: setTrackingOnDocFMMS,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_TOTAL_CURRENT_WEIGHINGS,
        set: setTotalCurrentWeighings,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_ENTRY_PASSES,
        set: setEntryPasses,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_DISPATCH_PASSES,
        set: setDispatchPasses,
        mode: 'simple'
      },
      {
        url: environment.NEWS_SECTION_COUNTERS_FMM,
        set: setCounterFMM,
        mode: 'simple'
      },
      {
        url: environment.GET_FORMS_TO_EXPIRE,
        set: setPendingFormsDue,
        mode: 'simple'
      },
      {
        url: environment.GET_FORMS_APPROVED_BY_SYSTEM,
        set: setSystemAuthorizedForms,
        mode: 'simple'
      }

    ]

    fetchDataList.forEach(item => {
      (namePermissions[item.url.split('/')[item.url.split('/').length - 1]] || namePermissions.default) && fetchDataWrapper(item.url, item.set, '', '', item.mode)
    })
  }, [namePermissions])

  useEffect(() => {
    setFmmi(counterFMM ? counterFMM[0].FMMI : [])
    setFmms(counterFMM ? counterFMM[1].FMMS : [])
    setFci(counterFMM ? counterFMM[2].FCI : [])
  }, [counterFMM])

  useEffect(() => {
    const processDocumentData = (data, set) => {
      const {
        dispatch_passes,
        entry_forms,
        entry_passes,
        integration_certificates,
        output_forms
      } = data

      DocumentGraph(
        [
          dispatch_passes,
          entry_passes,
          entry_forms,
          output_forms,
          integration_certificates
        ],
        set
      )
    }

    if (documentSubmitted) {
      processDocumentData(documentSubmitted, setDocumentSubmitteData)
    }

    if (documentReturned) {
      processDocumentData(documentReturned, setDocumentReturnedata)
    }

    if (documentApproved) {
      processDocumentData(documentApproved, setDocumentsApproved)
      setDocumentApproved(false)
    }
  }, [documentSubmitted, documentReturned, documentApproved])

  if (isLoading) return <SpinnerComponent />

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: totalCurrentWeighings?.map(item => `${item.name} - ${item.total_weighing}${item.total_weighing === 1 ? ' Pesaje' : ' Pesajes'}`) || '',
    datasets: [
      {
        label: '# of Votes',
        data: totalCurrentWeighings?.map(item => item.total_weighing),
        backgroundColor: [
          '#006eb8',
          '#47b3e7',
          '#9cd8f7',
          '#9097be',
          '#dddedf',
          '#d8dfe7'
        ],
        borderWidth: 1
      }
    ]
  };

  const hasData = (
    (openTicket?.length !== 0 || openTicketDay?.length !== 0 || closeTicket?.length !== 0 || Reweighing?.length !== 0) ||
    (ucWhatMoreWeight?.length !== 0 || incomeBalance !== null || exitBalance !== null || entryPasses !== null || dispatchPasses !== null) ||
    (trackingOnFMMI !== null || trackingOnFMMS !== null || trackingOffFMMI !== null || trackingOffFMMS !== null) ||
    (trackingOnDocFMMI !== null || trackingOnDocFMMS !== null) ||
    (totalCurrentWeighings !== null && totalCurrentWeighings?.length !== 0) ||
    (presentForDayData !== null && presentForDayData?.length !== 0) ||
    (presentForUserData !== null && presentForUserData?.length !== 0) ||
    (documentReturned !== null && documentReturned?.length !== 0) ||
    (documentSubmitted !== null && documentSubmitted?.length !== 0) ||
    (documentApproved !== null && documentApproved?.length !== 0) ||
    (documentSubmitteData !== null && documentSubmitteData?.length !== 0) ||
    (documentReturnedData !== null && documentReturnedData?.length !== 0) ||
    (documentsApproved !== null && documentsApproved?.length !== 0) ||
    (counterFMM !== null && counterFMM?.length !== 0) ||
    (pendingFormsDue !== null || systemAuthorizedForms !== null)
  );

  if (!hasData) {
    return (
      <Box className='mt-2'>
        <div className='row' style={{ maxWidth: '1280px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', margin: 'auto' }}>
            <FaSadTear style={{ fontSize: '3rem', marginBottom: '10px', color: '#006eb8' }} />
            <Title>¡Oh no! Parece que el patio está un poco vacío...</Title>
            <p>No hay datos disponibles en este momento. ¡Esperemos que se llenen pronto!</p>
          </div>
        </div>
      </Box>
    );
  }

  return (
    <Box className='mt-2'>
      <div className='row' style={{ maxWidth: '1280px' }}>

        <MiniCards
          documentReturned={documentReturned}
          ucWhatMoreWeight={ucWhatMoreWeight}
          incomeBalance={incomeBalance}
          exitBalance={exitBalance}
          trackingOnFMMI={trackingOnFMMI}
          trackingOnFMMS={trackingOnFMMS}
          trackingOffFMMI={trackingOffFMMI}
          trackingOffFMMS={trackingOffFMMS}
          trackingOnDocFMMI={trackingOnDocFMMI}
          trackingOnDocFMMS={trackingOnDocFMMS}
        />

        { pendingFormsDue &&
        <div style={{ margin: '10px', flex: '1',}}>
          <TablePendingFormsDue data={pendingFormsDue} />
        </div>
        }

        { systemAuthorizedForms &&
        <div style={{ margin: '10px', flex: '1', }}>
          <TableSystemAuthorizedForms data={systemAuthorizedForms} />
        </div>
        }

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {(documentSubmitteData && documentSubmitteData.length !== 0) &&
            <div style={{ margin: '10px', flex: '1', minWidth: '500px' }}>
              <CardDown
                title={'Documentos presentados'}
                userData={documentSubmitteData}
              />
            </div>}

          {(documentReturnedData && documentReturnedData.length !== 0) &&
            <div style={{ margin: '10px', flex: '1', minWidth: '500px' }}>
              <CardDown
                title={'Documentos devueltos'}
                userData={documentReturnedData}
              />
            </div>}

          {(documentsApproved && documentsApproved.length !== 0) &&
            <div style={{ margin: '10px', flex: '1', minWidth: '500px' }}>
              <CardDown
                title={'Documentos aprobados'}
                userData={documentsApproved}
              />
            </div>}
        </div>

        {(Number.isInteger(openTicket) || Number.isInteger(openTicketDay) || Number.isInteger(closeTicket) || Number.isInteger(Reweighing)) && <Tickets
          openTicket={openTicket}
          openTicketDay={openTicketDay}
          closeTicket={closeTicket}
          Reweighing={Reweighing}
        />}

        {entryPasses && <FormsWith title={'Formularios de ingreso aprobados con pases'} data={entryPasses} />}

        {dispatchPasses && <FormsWith title={'Formularios de salidas aprobados con despachos'} data={dispatchPasses} />}

        {(fmmi.length !== 0 || fmms.length !== 0 || fci.length !== 0) && <TablePresentationTime dataFmmi={fmmi} dataFmms={fmms} dataFci={fci} />}

        {totalCurrentWeighings && <div style={{ margin: '10px', height: '360px', width: '340px' }}>
          <MinCardMximu>
            <div id="tituloEstadistica" style={{ textAlign: 'center' }}>
              Total pesaje: {totalCurrentWeighings?.reduce((acc, item) => acc + item.total_weighing, 0)}
            </div>
            <Doughnut data={data} />
          </MinCardMximu>
        </div>}

        {/* <div style={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
          <Title>Cuanto vació hay en el patio</Title>
        </div> */}
      </div>
    </Box>
  )
}
