import axios from 'axios';

import env from '../../constants/apiConst';

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __Licenses = async (token, body, page) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    },
    data: body || ''
  };

  const res = await axios(`${env.LICENSES_GET}?page=${page || 1}`, options);
  return res;
};

export const __LicensesActivate = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };

  const res = await axios(`${env.LICENSES_ACTIVATE}/${id}`, options);
  return res;
};

export const __LicensesDeactivate = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };

  const res = await axios(`${env.LICENSES_DEACTIVATE}/${id}`, options);
  return res;
};
