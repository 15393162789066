export const TypeAttachmentOther = [
  {
    label: "Word",
    value: ".doc,.docx,.xml,application/msword"
  },
  {
    label: "Excel",
    value: "application/vnd.ms-excel,.xlsx,.xls,XLSX,XLS,XLSM,XLSB"
  },
  {
    label: "Pdf",
    value: "application/pdf"
  },
  {
    label: "Imagen",
    value: ".png, .jpg, .jpeg"
  }
];
