import { Box, Center, Checkbox, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import { UserCaRoles } from 'components/InputSelect/InputSelect';
import { LabelStyled } from 'components/addProduct/CreateTemplate.styled';
import environment from 'constants/apiConst';
import ConfigurationCRUDContext from 'context/ConfigurationCRUDContext';
import { useForm } from 'hooks/useForm';
import { AcordionGroup, LabelGroup } from 'pages/RefProductInter/LabelGroup';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { __Post } from 'request/Petitions/__Post';
import { __Group_And_Permisos, __PermisosSinPag, __ShowRoles, __UserCalificadosSp } from 'request/configuration/__Roles';
import { ButtonStyled } from 'styled/Buttons.styled';
import { TitleBorderBottom } from 'styled/Title'
import { MaxLength } from 'utils/MaxLength';

const ManageRoles = () => {
  const [loading, setLoading] = useState(false);
  const [subModulos, setSubmodulos] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [allper, setAllPer] = useState(false);
  const [permisosAct, setPermisosAct] = useState([]);
  const [cantPerm, setCantPerm] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [valor, setValor] = useState([]);
  const [usuariosRol, setUsuariosRol] = useState(null);
  const [message, setMessage] = useState(false);
  const [defectoV, setDefectoV] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [counterPermises, setcounterPermises] = useState(null);
  const navigate = useNavigate();
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)

  const [values, handleInputChange, reset, setValues] = useForm({
    name_rol: "",
    description_rol: ""
  });
  const { name_rol, description_rol } = values;

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const { dataToEdit, setDataToEdit, onCloseModal, createDataLicense, editData, setRequestCreateLicense } = useContext(
    ConfigurationCRUDContext
  );

  useEffect(() => {
    userCalificados();
    modulos();
    permisosSinPag();
    setRequestCreateLicense(environment.ROL_CREATE);
  }, []);

  const modulos = async () => {
    setLoading(true);
    try {
      const res = await __Group_And_Permisos(token);
      setSubmodulos(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const permisosSinPag = async () => {
    try {
      const res = await __PermisosSinPag(token);
      if (res?.data?.status?.code === 200) {
        setCantPerm(res?.data?.status?.data.length);
      }
      console.log(cantPerm);
    } catch (error) {
      console.log(error);
    }
  };

  const userCalificados = async () => {
    try {
      const res = await __UserCalificadosSp(token);
      // console.log(res.data.status.message);
      if (res.data.status.message === "No hay resultado.") {
        setMessage(true);
      } else {
        setUsuarios(res?.data?.status?.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (parseInt(counterPermises) === parseInt(cantPerm)) {
      setAllPer(true);
    } else {
      setAllPer(false);
    }
  }, [counterPermises, cantPerm]);

  const initialpos = async () => {
    try {
      const res = await __ShowRoles(token, dataToEdit.id);
      const infoDate = res?.data?.status?.data?.permissions;
      infoDate.map((items) => setPermisos((prev) => [...prev, items.id]));
      const infoUser = res?.data?.status?.data.users;
      infoUser.map((items) => setValor((prev) => [...prev, items.id]));
      setDefectoV(res?.data?.status?.data.users);
      setPermisosAct(infoDate);

      const countPerm = res?.data?.status?.data?.permissions.length;
      setcounterPermises(countPerm);
    } catch (error) { }
  };

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      initialpos();
      permisosSinPag();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  useEffect(() => {
    if (dataToEdit) {
      return localStorage.setItem("edit", JSON.stringify(dataToEdit))
    }
    if (!dataToEdit) {
      return setDataToEdit(JSON.parse(localStorage.getItem("edit")))
    }
  }, [dataToEdit])

  useEffect(() => {
    return () => {
      setDataToEdit(null);
      localStorage.removeItem("edit");
    }
  }, [])

  const handleAceptar = () => {
    const notice = {
      name_rol,
      description_rol,
      users: usuariosRol ? valor.concat(usuariosRol) : valor
    };
    if (name_rol === "") {
      openAlert("Ingrese el nombre del rol", "error");
    } else {
      if (dataToEdit) {
        if (allper) {
          delete notice.guard_name;
          delete notice.permissions;
          const data = {
            ...notice,
            permissions_all: 1,
            id: dataToEdit.id
          };
          editData(data, navigate(-1));
        } else {
          const data = {
            ...notice,
            permissions: permisos,
            id: dataToEdit.id
          };
          editData(data, navigate(-1));
        }
      } else {
        if (allper) {
          const data = {
            ...notice,
            permissions_all: 1
          };
          createDataLicense(data, navigate(-1));
        } else {
          const data = {
            ...notice,
            permissions: permisos
          };
          createDataLicense(data, navigate(-1));
        }
      }
    }
  };

  const allPerson = (e) => {
    setAllPer(e.target.checked);
  };

  const actState = async (e) => {
    const body = new FormData();
    body.append("user_id", (e?.target?.value));
    body.append("rol_id", dataToEdit?.id);
    body.append("flag", e.target.checked ? 1 : 0);
    const res = await __Post(environment.DISASSOCIATE_ROLE, token, body)
    console.log(res)
    if (res?.data?.status?.code !== 200) {
      console.log(res)
      openAlert(res?.data?.status?.message, 'error')
    }
    if (res?.data?.status?.code === 200) {
      console.log(res?.data?.status?.message)
      openAlert(res?.data?.status?.message, 'success')
    }
    if (!e.target.checked) {
      const remover = parseInt(e.target.value);
      removeItemFromArr(valor, remover);
    } else {
      setValor((prev) => [...prev, parseInt(e.target.value)]);
    }
  };

  const removeItemFromArr = (arr, item) => {
    const i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  };

  return (
    <div>

      <TitleBorderBottom style={{
        color: "#171A22",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderBottom: "none"
      }}>
        {movement === "Edit" ? " Editar Rol" : "Crear Rol"}

        <div>
          <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
            &nbsp; Permisos asignados: {allper ? cantPerm : permisos.length}
            /{cantPerm}
          </Text>
        </div>
      </TitleBorderBottom>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px"
          }}
        >
          <LabelGroup
            name={"name_rol"}
            onChange={handleInputChange}
            value={name_rol}
            tag={"Nombre del Rol"}
            size={49}
            desicion={false}
            maxLength={MaxLength.config.roles.name_rol}
          />
          <LabelGroup
            name={"description_rol"}
            onChange={handleInputChange}
            value={description_rol}
            tag={"Descripción"}
            size={49}
            desicion={false}
            maxLength={MaxLength.config.roles.description_rol}
          />
        </div>

        <LabelStyled style={{ padding: "20px" }}>
          <h3 style={{ color: "#171A22", fontWeight: 600, fontSize: "18px", marginBottom: "10px" }}>
            Módulos:
          </h3>
          <span className="d-flex ">
            <Checkbox isChecked={allper} onChange={(e) => setAllPer(e.target.checked)}>
              Todos los permisos
            </Checkbox>
          </span>{" "}
        </LabelStyled>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', padding: '20px', maxHeight: '500px', overflow: 'auto' }}>
          {subModulos?.sort((a, b) => a?.name_permissionGroup?.localeCompare(b?.name_permissionGroup))?.map((item, index) => (
            <AcordionGroup
              subTitle={item}
              setPermisos={setPermisos}
              allper={allper}
              permisosAct={permisosAct}
              permisos={permisos}
              setPermisosAct={setPermisosAct}
              key={index}
            />
          ))}
        </div>

        {/* {usuarios ? ( */}
        <div className="d-flex justify-content-center">
          <ButtonStyled
            className="d-flex justify-content-center "
            onClick={() => setAddUser(!addUser)}
          >
            Agregar Usuario
          </ButtonStyled>
        </div>
        {/* ) : (
                ""
              )} */}

        {addUser
          ? (
            !message
              ? (
                <UserCaRoles
                  data={usuarios}
                  tag={"Usuarios"}
                  size={100}
                  setValor={setUsuariosRol}
                  ediciones={dataToEdit}
                  valor={valor}
                />
              )
              : (
                <div className="text-center mt-5">
                  <span className="h5 colorFont">
                    No hay usuarios disponibles
                  </span>
                  <Center>
                    <hr className="sobreNo mt-1" />
                  </Center>
                </div>
              )
          )
          : (
            ""
          )}

        {dataToEdit?.users
          ? (
            <div
              className={
                dataToEdit?.users ? (defectoV.length === 0 ? "" : "user") : ""
              }
            >
              {defectoV.map((items, index) => (
                <div style={{ display: "inline", width: "32%", backgroundColor: "#f9f9f9", padding: "5px", borderRadius: "5px", margin: "5px" }}>
                  <Checkbox
                    className="mx-3"
                    value={items.id}
                    onChange={actState}
                    defaultIsChecked={true}
                  >
                    {items.nickname}
                  </Checkbox>
                </div>
              ))}
            </div>
          )
          : (
            ""
          )}

        <Flex className="mt-5">
          <Spacer />
          <Box>
            <ButtonStyled
              bgColor={"#bababa"}
              onClick={() => navigate(-1)}
            >
              Cerrar
            </ButtonStyled>
            <ButtonStyled type="submit" onClick={() => {
              handleAceptar()
              openAlert("Procesando información se le notificará una vez confirmada", "info")
            }}>
              {movement === "Edit" ? " Editar Rol" : "Crear Rol"}
            </ButtonStyled>
          </Box>
        </Flex>

      </div >

    </div >
  )
}

export default ManageRoles
