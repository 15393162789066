import { Flex, Grid, GridItem, Spacer } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import { useContext, useEffect, useState } from "react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import InputSelect from "components/InputSelect/InputSelect";
import { FuncionLetraNumberOnly } from "components/Funciones/FuncionLetraNumberOnly";
import WeighingContext from "context/WeighingContext";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { typeWeihing } from "../TypeWighingList/TypeWighingList";
import { GetWeihingButton } from "../FormWeighingMachine.js/GetWeihing";
import { ButtonStyled } from "styled/Buttons.styled";

const defaultState = {
  container: "",
  capacity_container: "",
  tare_container: ""
};

export const Reweigh = ({ onCloseReweigh }) => {
  const [typeWeihingState, setTypeWeihingState] = useState(null);
  const [loading, setLoading] = useState(false);
  const { IdReweigh } = useContext(WeighingContext);
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [horario, setHorario] = useState(null);
  const [rows, setRows] = useState([defaultState]);

  const [values, handleInputChange, reset, setValues] = useForm({
    vehicle_plate: "",
    identification: "",
    driver: "",
    name_typeTransport: "",
    name_qualified: "",
    passForm_number: "",
    own_means: "",
    type_weight: "",
    weight_id: "",
    tare: "",
    gross_weight: "",
    tare_weight: "",
    tare_scale_id: "",
    pass_id: "",
    containers: "",
    pass: "",
    weight: ""
  });

  const {
    vehicle_plate,
    identification,
    driver,
    name_qualified,
    passForm_number,
    name_typeTransport,
    weight,
    gross_weight,
    tare_weight,
    type_weight,
    pass_id,
    weight_id,
    containers,
    pass
  } = values;

  useEffect(() => {
    const tipodato = typeof setRows;
    if (containers !== null && tipodato === "function") {
      setRows(containers);
    }
  }, [containers]);

  useEffect(() => {
    const fechaHoy = new Date();
    const dia = fechaHoy.getDay();
    const hora = fechaHoy.getHours();
    if (hora < 8 && hora > 18 && (dia === 0 || dia === 6)) {
      setHorario("CORRIDO");
    } else if (hora >= 8 && hora <= 18 && (dia !== 0 || dia !== 6)) {
      setHorario("NORMAL");
    }
  }, [values]);

  useEffect(() => {
    const ShowWeigh = async (data) => {
      try {
        const resp = await __Post(
          environment.SHOW_WEIGHING_PESAJE,
          token,
          data
        );
        if (resp.data.status.code === 200) {
          console.log("CurrentData", resp?.data?.status?.data);
          setValues({
            ...values,
            driver: resp?.data?.status?.data?.driver,
            name_qualified: resp?.data?.status?.data?.name_qualified,
            name_typeTransport: resp?.data?.status?.data?.name_typeTransport,
            identification: resp?.data?.status?.data?.identification,
            vehicle_plate: resp?.data?.status?.data?.vehicle_plate,
            passForm_number: resp?.data?.status?.data?.passForm_number,
            own_means: resp?.data?.status?.data?.own_means,
            tare_scale_id: resp?.data?.status?.data?.tare_scale_id,
            weight_id: resp?.data?.status?.data?.weight_id,
            containers: resp?.data?.status?.data?.containers,
            pass: resp?.data?.status?.data?.pass,
            pass_id: resp?.data?.status?.data?.pass_id
          });

          // setCurrentData(resp?.data?.status?.data);
          // openAlert(resp?.data?.status?.message, "success");
        } else {
          openAlert(resp?.data?.status?.message, "error");
        }
      } catch (error) {
        console.log(error);
      }
    };

    ShowWeigh(IdReweigh);
  }, [IdReweigh]);

  useEffect(() => {
    const tipodato = typeof setValues;
    if (typeWeihingState && typeWeihingState.value && tipodato === "function") {
      setValues({ ...values, type_weight: typeWeihingState.value });
    }
  }, [typeWeihingState, setValues]);

  const sendData = () => {
    const scaleId = localStorage.getItem("chanelWeighing");
    const data = {
      type_weight,
      pass_id,
      weight_id,
      weight,
      pass: parseInt(pass),
      contenedores: rows,
      vehicle_plate,
      shedule: horario,
      status: "NORMAL",
      reweight: true
    };
    // console.log(data);
    const newInfo = {};
    Object.entries(data).map(([key, valor]) => {
      if (!valor) {
        return 0;
      }
      return (newInfo[key] = valor);
    });

    if (values.pass_id === "" || values.pass_id === null) {
      openAlert("Seleccione un vehículo", "error");
    } else if (values.type_weight === "" || values.type_weight === null) {
      openAlert("El tipo de peso es requerido", "error");
    } else if (values.weight === "") {
      openAlert("El peso es requerido", "error");
    } else {
      if (values.type_weight === "Tara") {
        const info = {
          ...newInfo,
          tare_scale_id: parseInt(scaleId)
        };
        saveWeihing(info);
      } else if (values.type_weight === "Bruto") {
        const info = {
          ...newInfo,
          gross_scale_id: parseInt(scaleId)
        };
        saveWeihing(info);
      }
    }
  };

  const saveWeihing = async (data) => {
    setLoading(true);
    console.log(data)
    try {
      const resp = await __Post(
        environment.CREATE_REWEIGH,
        token,
        data
      );
      const resultado = resp.data.status;
      if (resultado.code === 200) {
        openAlert('Procesado con exito', 'success')
        setValues((prev) => !prev);
        onCloseReweigh();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid templateColumns=" repeat(auto-fill, minmax(290px, 1fr))" gap={6}>
        <GridItem w="100%">
          {" "}
          <LabelGroup
            name={"vehicle_plate"}
            value={vehicle_plate ? vehicle_plate.toUpperCase() : vehicle_plate}
            tag={"Placa vehículo"}
            maxLength={7}
            onChange={(e) => FuncionLetraNumberOnly(e, setValues, values)}
            desicion={true}
          />
        </GridItem>
        <GridItem w="100%">
          {" "}
          <LabelGroup
            name={"identification"}
            value={identification || ""}
            onChange={handleInputChange}
            tag={"Cédula conductor"}
            desicion={true}
          />
        </GridItem>
        <GridItem w="100%">
          {" "}
          <LabelGroup
            name={"driver"}
            value={driver}
            onChange={handleInputChange}
            tag={"Conductor"}
            desicion={true}
          />
        </GridItem>

        <GridItem w="100%">
          <LabelGroup
            name={"name_typeTransport"}
            value={name_typeTransport}
            onChange={handleInputChange}
            tag={"Tipo de transporte"}
            desicion={true}
          />{" "}
        </GridItem>

        <GridItem w="100%">
          <LabelGroup
            name={"name_qualified"}
            value={name_qualified}
            onChange={handleInputChange}
            tag={"Usuario calificado"}
            desicion={true}
          />
        </GridItem>

        <GridItem w="100%">
          <LabelGroup
            name={"passForm_number"}
            value={passForm_number || ""}
            onChange={handleInputChange}
            tag={"Número de pase"}
            desicion={true}
          />
        </GridItem>

        <GridItem w="100%" gap={3} display={"flex"} flexDir="row">
          {" "}
          <LabelGroup
            name={"weight"}
            value={weight}
            onChange={handleInputChange}
            tag={"Peso"}
            size={100}
            desicion={false}
          />
          <GetWeihingButton
            loading={loading}
            setLoading={setLoading}
            setValues={setValues}
            values={values}
          />
          {/*  <>
            {loading && <SpinnerComponent size="auto" />}
            {!loading && (
              <Tooltip placement="top" hasArrow label="Obtener peso">
                <ButtonHeaderModule
                  onClick={GetWeihing}
                  //  bgColor="#03C39A"
                  className="mt-4"
                >
                  <IconGeneric color="#ffff" as={GiWeightScale} />
                </ButtonHeaderModule>
              </Tooltip>
            )}
          </> */}
        </GridItem>
        <GridItem w="100%">
          <InputSelect
            tag={"Tipo de peso"}
            data={typeWeihing}
            set={setTypeWeihingState}
            value={typeWeihingState}
          />
        </GridItem>
      </Grid>
      <Flex pt="10" pb="5">
        <ButtonStyled bgColor={"#bababa"} onClick={() => onCloseReweigh()}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#3182CE"} onClick={sendData}>
          Guardar
        </ButtonStyled>
      </Flex>
    </>
  );
};
