import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;
  border-bottom: 1px solid #bababa;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuReviews = ({ data, movement }) => {
  const { changeStatus } = useContext(FormCrudCRUDContext);

  const handleStatus = (newStatus, id) => {
    const statusForm =
      data?.movement === "Ingreso" ? "status_entryForm" : "status_outputForm";

    const info = {
      id,
      [statusForm]: newStatus
    };

    changeStatus(info);
  };

  const navigate = useNavigate();

  const handleShow = (status) => {
    if (status === "Ingreso") {
      navigate(`/CheckFormEntry?Number=${data?.form_id}`);
    }

    if (status === "Salida") {
      navigate(`/CheckForm?Number=${data?.form_id}`);
    }

    if (status === "Certificado de integración") {
      navigate(`/CheckIntegrationBox?id=${data?.form_id}`);
    }

    if (status === "Revisión Certificado de integración") {
      navigate(`/CheckIntegrationBox?id=${data?.form_id}`);
    }

    handleStatus("REVISADO", data?.form_id);
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={() => handleShow(movement)}>
          <IconMenuRUD as={MdRemoveRedEye} />
          Revisar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default MenuReviews;
