import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router'
import { ContaianeFalse, MainStyled } from 'components/Main/Main.styled'
import Aside from 'components/Aside/Aside'
import Pases from 'pages/Pass-Form/Pases'
import Unidad from 'pages/Configuration/views/Unidad'
import ThirdParties from 'pages/Terceros/ThirdParties'
import SuperAdminRoutes from 'routes/SuperAdminRoutes'
import { PassCrudProvider } from 'context/PassCrudContext'
import { FormuIntEdit } from 'pages/Pass-Form/FormuIntEdit'
import Packaging from 'pages/Configuration/views/Packaging'
import { FormCrudCRUDProvider } from 'context/FormCrudContex'
import Configuration from 'pages/Configuration/Configuration'
import { GestionCrudProvider } from 'context/GestionCrudContext'
import { ProductCRUDProvider } from 'context/ProductCRUDContext'
import { CustomsCrudProvider } from 'context/CustomsCrudContext'
import { ProcessCrudProvider } from 'context/ProcessCrudContext'
import FormsView from 'pages/Entry_and_exit_forms/View/FormsView'
import { EntryMovement } from 'pages/Pass-Form/View/EntryMovement'
import { ThirdPartiesProvider } from 'context/ThirdPartiesContext'
import FormExit from 'pages/Entry_and_exit_forms/FormExit/FormExit'
import { GestionUsuarios } from 'pages/Gestion_User/GestionUsuarios'
import CustomsClearance from 'pages/CustomsClearance/CustomsClearance'
import { DashboardViewP } from '../pages/Dashboard/view/DashboardViewP'
import { PreductoInterno } from 'pages/RefProductInter/PreductoInterno'
import FormEntry from '../pages/Entry_and_exit_forms/FormEntry/FormEntry'
import { ConfigurationCRUDProvider } from 'context/ConfigurationCRUDContext'
import FormCustomsClearance from 'pages/CustomsClearance/FormCustomsClearance'
import { FormsTablesView } from 'pages/Entry_and_exit_forms/View/FormsTablesView'
import EditProductTemplate from 'pages/RefProductInter/views/EditProductTemplate'
import { ShowCustomsClearance } from 'pages/CustomsClearance/ShowCustomsClearance'
import { ShowExitFmm } from 'pages/Entry_and_exit_forms/FormExit/ShowExitFmm'
import CorrectionForm from 'pages/Entry_and_exit_forms/CorrectionForm/CorrectionForm'
import { CheckFmm } from 'pages/Entry_and_exit_forms/FormExit/CheckFmm'
import FormReviewing from 'pages/Entry_and_exit_forms/View/FormReviewing'
import IntegracionBox from 'pages/IntegrationBox/IntegrationBox'
import StatusTableView from 'pages/IntegrationBox/View/StatusTableView/StatusTableView'
import { InventoryControl } from 'pages/InventoyControl/InventoryControl'
import { Weighing } from 'pages/Weighing/Weighing'
import { WeighingMachine } from 'pages/Weighing/WeighingMachine'
import { ProductionOrder } from 'pages/ProductionOrder/ProductionOrder'
import { FormCreateOrder } from 'pages/ProductionOrder/FormCreateOrder'
import { SecurityRoutes } from './SecurityRoute'
import Header from 'components/Header/Header'
import SubmittedQueue from 'pages/SubmittedQueue/SubmittedQueue'
import { OrderProductionProvider } from 'context/OrderProductionContext'
import ListDian from 'pages/Dian/ListDian/ListDian'
import ProfileDocuments from 'pages/Dian/ProfileDocuments/ProfileDocuments'
import { ShowProductionOrder } from 'pages/ProductionOrder/ShowProductionOrder'
import { CheckFmmOrder } from 'pages/ProductionOrder/CheckFmmOrder'
import Annexed from 'pages/Dian/Annexed5/Annexed'
import ShippingForm from 'pages/Dian/Spreadsheet/ShippingForm'
import Blocking from 'pages/Blocking/Blocking'
import { BlockingCRUDProvider } from 'context/BlockingContex'
import { IntegracionBoxCRUDProvider } from 'context/IntegracionBoxCrudContex'
import { TreasuryCRUDProvider } from 'context/TreasuryContex'
import Extracts from 'pages/Treasury/Extracts/Extracts'
import SalesOfService from 'pages/Treasury/Sales_Of_Services/SalesOfService'
import Weigh from 'pages/Treasury/Weigh_Ins/Weigh'
import Update from 'pages/Treasury/RateUpdate/Update'
import FormUpdate from 'pages/Treasury/Forms/FormUpdate'
import { EstadisticasGeneral } from 'pages/Statistics/General/EstadisticasGeneral'
import SpecialRates from 'pages/Treasury/RateUpdate/SpecialRates'
import { ReturnReason } from 'pages/ReturnReason/ReturnReason'
import { ReturnReasonnProvider } from 'context/ReturnReasonContext'
import RecordUpdate from 'pages/Treasury/RateUpdate/View/RecordUpdate'
import { AuxReference } from 'pages/InventoyControl/AuxReference'
import { InventoryInProcess } from 'pages/InventoyControl/InventoryInProcess'
import { AuxOutputs } from 'pages/InventoyControl/AuxOutputs'
import { TemporaryAux } from 'pages/InventoyControl/TemporaryAux'
import { InventoryProduct } from 'pages/InventoyControl/InventoryProduct'
import ShowIntegrationBox from 'pages/IntegrationBox/View/ShowIntegrationBox/ShowIntegrationBox'
import { ShowWeighing } from 'pages/Weighing/ShowWeighing'
import { WeighingProvider } from 'context/WeighingContext'
import { AuxBalance } from 'pages/InventoyControl/AuxBalance'
import { ReportWeighing } from 'pages/Weighing/ReportWeighing/ReportWeighing'
import { InventoryControlProvider } from 'context/InventoryControlContext'
import { EstadisticasSecurity } from 'pages/Statistics/security/EstadisticasSecurity'
import ItemProfile from 'pages/Dian/view/ProfileView/ItemProfile'
import { DianCRUDProvider } from 'context/DianCrudContext'
import { Dane } from 'pages/Dane/Dane'
/* import { Monitoring } from "pages/Seguimiento/Monitoring"; */
import FinishProfiling from 'pages/Dian/view/ProfileView/FinishProfiling'
import { ViewCorrectForm } from 'pages/Entry_and_exit_forms/CorrectionForm/ViewCorrectForm'
import ItemProfileFinish from 'pages/Dian/view/ProfileView/ItemProfileFinish'
import CheckIntegrationBox from 'pages/IntegrationBox/View/ShowIntegrationBox/CheckIntegrationBox'
import { CorrectionFmmO } from 'pages/Entry_and_exit_forms/CorrectionFMMO/CorrectionFmmO'
import { MainCorrection } from '../pages/Reviews/MainCorrection'
import ViewCorrection from 'pages/IntegrationBox/CorrectionCertificate/ViewCorrection'
import { CargoVehicle } from 'pages/Statistics/cargoVehicle/CargoVehicle'
import { EstadisticasIngresoSalida } from 'pages/Statistics/estadisticaOpeIngresoSalida/EstadisticasIngresoSalida'
import { StatisProvider } from 'context/StatisContext'
import ReviewCorrectEntry from 'pages/Entry_and_exit_forms/CorrectionForm/ReviewCorrectEntry'
import { OperationsUsed } from 'pages/Statistics/operationsUsed/OperationsUsed'
import { ReviewCorrectionFmmO } from 'pages/Entry_and_exit_forms/CorrectionFMMO/ReviewCorrectionFmmO'
import FormsCorrected from 'pages/IntegrationBox/CorrectionCertificate/FormsCorrected'
import { UpdateReview } from 'pages/Reviews/UpdateReview/UpdateReview'
import { EstadisticaQualifiedUser } from 'pages/Statistics/qualifiedUser/EstadisticaQualifiedUser'
import { EstadisticaMovimiento } from 'pages/Statistics/movimiento/EstadisticaMovimiento'
import { Th, useDisclosure } from '@chakra-ui/react'
import { useModal } from 'hooks/useModal'
import { ControlAnalysis } from 'components/ControlAnalysis/main'
import Profile from 'components/Profile/Profile'
import { ReviewAproved } from 'pages/Reviews/ReviewAprovedEntry'
import { ReviewCorrections } from 'pages/Reviews/ReviewCorrections'
import { NoFound } from 'components/NoFound/NoFound'
import { InventoryAudit } from 'pages/InventoyControl/InventoryAudit'
import { Monitoring } from 'pages/Seguimiento/Monitoring'
import { MonitoringContextProvider } from 'context/MonitoringContext'
import DashboardPage from 'DashBoard/pages/DashboardPage'
import ExplorePage from 'DashBoard/pages/ExplorePage'
import { ModeRobot } from 'components/ModeRobot/ModeRobot'

import { useLocation } from 'react-router-dom'
import { ADDLocalStorage, GetLocalStorage } from 'hooks/useLocalStorage'
import CheckPass from 'pages/Pass-Form/CheckPass'
import { EntryMovementReviw } from 'pages/Pass-Form/View/EntryMovementReviw'
import { FollowOrder } from 'pages/Seguimiento/FollowOrder'
import { FirstLoginProvider } from 'context/FirstLoginContext'
import { HistoryFmm } from 'pages/HistoryFmm/Index'
import ShowRiskLevel from 'components/ControlAnalysis/ShowRiskLevel'
import ShowRiskLevelUser from 'components/ControlAnalysis/ShowRiskLevelUser'
import { DocuExportPro } from 'components/DocuExportPro/DocuExportPro'
import { ControlAnalysisProvider } from 'context/ControlAnalysis'
import { IntegrationCertificateReview } from 'pages/IntegrationBox/View/ShowIntegrationBox/IntegrationCertificateReview'
import EstadisticaCese from 'pages/Statistics/Cese/EstadisticaCese'
import { useSelector, useDispatch } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { TokenExpirer } from 'components/Token/TokenExpirer'
import FormatSRT from 'pages/FormatSTR/FormatSRT'
import { GlobalContextProvider } from 'context/GlobalContext'
import DEI from 'pages/DEI/DEI'
import { DispatchCorrectionsMain } from 'components/DispatchCorrections/DispatchCorrectionsMain'
import DispatchCorrectionsShow from 'components/DispatchCorrections/DispatchCorrectionsShow'
import { getDateNow } from 'components/Token/getDateNow'

import { handleLogout } from 'components/Logout/FuncionLogout'

import updateToken from 'redux/actions/renewTokenActions'
import UsersWeighing from 'pages/Weighing/view/UsersWeighing'
import OperationsStatistics from 'pages/Statistics/Operations/OperationsStatistics'
import { isValid } from 'utils/parseFloat'
import Reports from 'pages/Weighing/view/Components/Reports'
import EstadisticasOpeIO from 'pages/Statistics/operaciones/EstadisticasOpeIO'

// const { movement = "" } = queryString.parse(location.search);

export const privateRouteZF = [
  {
    path: '/monitoring',
    breadcrumbName: 'Seguimiento',
    element: (
      <MonitoringContextProvider>
        <FormCrudCRUDProvider>
          <ThirdPartiesProvider>
            <Monitoring />
          </ThirdPartiesProvider>
        </FormCrudCRUDProvider>
      </MonitoringContextProvider>
    )
  },
  {
    path: '/followOrder',
    breadcrumbName: 'Gestor de seguimiento',
    element: (
      <MonitoringContextProvider>
        <ThirdPartiesProvider>
          <FollowOrder />
        </ThirdPartiesProvider>
      </MonitoringContextProvider>
    )
  },
  {
    path: '/profilee',
    breadcrumbName: 'Perfil',
    element: (
      <FormCrudCRUDProvider>
        <Profile />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/control-analysis',
    breadcrumbName: 'Análisis de control',
    element: (
      <ControlAnalysisProvider>
        <ControlAnalysis />
      </ControlAnalysisProvider>
    )
  },
  {
    path: '/ShowRiskLevel',
    breadcrumbName: 'Análisis de control',
    element: <ShowRiskLevel />
  },
  {
    path: '/ShowRiskLevelUser',
    breadcrumbName: 'Análisis de control',
    element: <ShowRiskLevelUser />
  },
  {
    path: '/config/*',
    breadcrumbName: 'Configuración',
    element: (
      <SuperAdminRoutes>
        <ConfigurationCRUDProvider>
          <Configuration />
        </ConfigurationCRUDProvider>
      </SuperAdminRoutes>
    )
  },
  {
    path: '/unit',
    breadcrumbName: 'Unidad',
    element: (
      <ProductCRUDProvider>
        <Unidad />
      </ProductCRUDProvider>
    )
  },
  // {
  //   path: "/pesaje",
  //   breadcrumbName: "Pesaje",
  //   element: <Pesaje />,
  // },
  {
    path: '/product-int',
    breadcrumbName: 'Referencia de producto',
    element: (
      <FormCrudCRUDProvider>
        <ProductCRUDProvider>
          <ThirdPartiesProvider>
            <PreductoInterno />
          </ThirdPartiesProvider>
        </ProductCRUDProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/edit-product-template',
    breadcrumbName: 'Proceso productivo',
    element: (
      <FormCrudCRUDProvider>
        <ProductCRUDProvider>
          <ProcessCrudProvider>
            <EditProductTemplate />
          </ProcessCrudProvider>
        </ProductCRUDProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/third-parties',
    breadcrumbName: 'Terceros',
    element: (
      <FormCrudCRUDProvider>
        <ProductCRUDProvider>
          <ThirdPartiesProvider>
            <ThirdParties />
          </ThirdPartiesProvider>
        </ProductCRUDProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/customsClearance',
    breadcrumbName: 'Desaduanamiento',
    element: (
      <CustomsCrudProvider>
        <ThirdPartiesProvider>
          <CustomsClearance />
        </ThirdPartiesProvider>
      </CustomsCrudProvider>
    )
  },

  {
    path: '/HistoryFmm',
    breadcrumbName: 'Historial de formulario de movimiento de mercancia',
    element: <HistoryFmm />
  },
  {
    path: '/formCustomsClearance',
    breadcrumbName: 'Formulario desaduanamiento',
    element: (
      <CustomsCrudProvider>
        <FormCustomsClearance />
      </CustomsCrudProvider>
    )
  },
  {
    path: '/ShowCustomsClearance',
    breadcrumbName: 'Ver desaduanamiento',
    element: (
      <CustomsCrudProvider>
        <ShowCustomsClearance />
      </CustomsCrudProvider>
    )
  },
  {
    path: '/packaging',
    breadcrumbName: 'Embalaje',
    element: <Packaging />
  },
  {
    path: '/pases',
    breadcrumbName: 'Pases',
    element: (
      <FormCrudCRUDProvider>
        <PassCrudProvider>
          <Pases />
        </PassCrudProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/CheckPassEntry',
    breadcrumbName: 'Revisar pases entrada',
    element: (
      <FormCrudCRUDProvider>
        <PassCrudProvider>
          <CheckPass />
        </PassCrudProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/CheckPassOutput',
    breadcrumbName: 'Revisar pases salida',
    element: (
      <FormCrudCRUDProvider>
        <PassCrudProvider>
          <CheckPass />
        </PassCrudProvider>
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/gestion-user',
    breadcrumbName: 'Gestión de usuario',
    element: (
      <GestionCrudProvider>
        <GestionUsuarios />
      </GestionCrudProvider>
    )
  },
  {
    path: '/',
    element: <DashboardViewP />,
    breadcrumbName: 'Resumen de Actividad'
  },

  {
    path: '/entry-movement',
    breadcrumbName: 'Movimiento de entrada',
    element: (
      <PassCrudProvider>
        <ThirdPartiesProvider>
          <EntryMovement />
        </ThirdPartiesProvider>
      </PassCrudProvider>
    )
  },

  {
    path: '/corrected-pass-exit',
    breadcrumbName: 'Corregidos de despacho',
    element: (
      <ThirdPartiesProvider>
        <DispatchCorrectionsMain />
      </ThirdPartiesProvider>
    )
  },

  {
    path: '/entry-movement-review',
    breadcrumbName: 'Revisión de entrada',
    element: (
      <PassCrudProvider>
        <ThirdPartiesProvider>
          <EntryMovementReviw />
        </ThirdPartiesProvider>
      </PassCrudProvider>
    )
  },
  {
    path: '/output-movement',
    breadcrumbName: 'Movimiento de salida',
    element: (
      <PassCrudProvider>
        <ThirdPartiesProvider>
          <EntryMovement />
        </ThirdPartiesProvider>
      </PassCrudProvider>
    )
  },
  {
    path: '/output-movement-review',
    breadcrumbName: 'Revisión de salida',
    element: (
      <PassCrudProvider>
        <ThirdPartiesProvider>
          <EntryMovementReviw />
        </ThirdPartiesProvider>
      </PassCrudProvider>
    )
  },
  {
    path: '/Form-tables-entry',
    breadcrumbName: 'Formulario ingreso',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <FormsTablesView />
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/Form-tables-output',
    breadcrumbName: 'Formulario salida',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <FormsTablesView />
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/Correct-Form',
    breadcrumbName: 'Formulario corregidos',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <ViewCorrectForm />
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/Correct-Form-output',
    breadcrumbName: 'Formulario corregidos',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <ViewCorrectForm />
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/formInt',
    breadcrumbName: 'Editar formulario',
    element: (
      <PassCrudProvider>
        <FormuIntEdit />
      </PassCrudProvider>
    )
  },
  {
    path: '/FormEntry',
    breadcrumbName: 'Formulario de ingreso',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <ProductCRUDProvider>
            <FormEntry />
          </ProductCRUDProvider>
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/FormExit',
    breadcrumbName: 'Formulario de salida',
    element: (
      <ThirdPartiesProvider>
        <FormCrudCRUDProvider>
          <IntegracionBoxCRUDProvider>
            <FormExit />
          </IntegracionBoxCRUDProvider>
        </FormCrudCRUDProvider>
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/DispathcCorrectionShow',
    breadcrumbName: 'Ver correcciones de despacho',
    element: <DispatchCorrectionsShow />
  },
  {
    path: '/FormShow',
    breadcrumbName: 'Ver formulario',
    element: (
      <MonitoringContextProvider>
        <PassCrudProvider>
          <FormCrudCRUDProvider>
            <FormsView />
          </FormCrudCRUDProvider>
        </PassCrudProvider>
      </MonitoringContextProvider>
    )
  },
  {
    path: '/FormCorrection',
    breadcrumbName: 'Corrección de Formulario de ingreso',
    element: (
      <FormCrudCRUDProvider>
        <CorrectionForm />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/FormCorrectionOutput',
    breadcrumbName: 'Corrección de Formulario de salida',
    element: (
      <FormCrudCRUDProvider>
        <CorrectionFmmO />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/FormShowExit',
    breadcrumbName: 'Ver formulario',
    element: (
      <MonitoringContextProvider>
        <PassCrudProvider>
          <FormCrudCRUDProvider>
            <ShowExitFmm />
          </FormCrudCRUDProvider>
        </PassCrudProvider>
      </MonitoringContextProvider>
    )
  },
  {
    path: '/CheckForm',
    breadcrumbName: 'Revisar salida',
    element: (
      <FormCrudCRUDProvider>
        <CheckFmm />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/CheckFormEntry',
    breadcrumbName: 'Revisar ingreso',
    element: (
      <FormCrudCRUDProvider>
        <FormReviewing />
      </FormCrudCRUDProvider>
    )
  },
  /* Inicio de Revisión */
  {
    path: '/review-entry',
    breadcrumbName: 'Revisión de ingreso',
    element: (
      <FormCrudCRUDProvider>
        <MainCorrection />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/review-exit',
    breadcrumbName: 'Revisión de Salida',
    element: (
      <FormCrudCRUDProvider>
        <MainCorrection />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/review-FormEntry-Authorize',
    breadcrumbName: 'Revisión Aprobados de Ingreso',
    element: (
      <FormCrudCRUDProvider>
        <ReviewAproved />
      </FormCrudCRUDProvider>
    )
  },
  /*   {
    path: "/review-FormOutput-Authorize",
    breadcrumbName: "Revisión Salida de Aprobados",
    element: (
      <FormCrudCRUDProvider>
        <ReviewAproved />
      </FormCrudCRUDProvider>
    ),
  }, */
  {
    path: '/review-Integration',
    breadcrumbName: 'Revisión Certificado de integración',
    element: (
      <FormCrudCRUDProvider>
        <MainCorrection />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/review-FormEntry-Correcteds',
    breadcrumbName: 'Corrección de ingreso',
    element: (
      <FormCrudCRUDProvider>
        <ReviewCorrections />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/review-FormOutput-Correcteds',
    breadcrumbName: 'Corrección de Salida',
    element: (
      <FormCrudCRUDProvider>
        <ReviewCorrections />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/CheckCorrectEntry',
    breadcrumbName: 'Corrección de Ingreso',
    element: (
      <FormCrudCRUDProvider>
        <ReviewCorrectEntry />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/CheckCorrectOutput',
    breadcrumbName: 'Revisar ingreso',
    element: (
      <FormCrudCRUDProvider>
        <ReviewCorrectionFmmO />
      </FormCrudCRUDProvider>
    )
  },
  {
    path: '/Update-review',
    breadcrumbName: 'Actualizar Revisión',
    element: (
      <FormCrudCRUDProvider>
        <UpdateReview />
      </FormCrudCRUDProvider>
    )
  },
  /* Fin de Revisión */

  {
   /*  path: '/IntegracionBox',
    breadcrumbName: 'Certificado de integración',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <IntegracionBox />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    ) */
  },

  {
    path: '/CheckIntegrationBox',
    breadcrumbName: 'Revisar certificado de integración',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <CheckIntegrationBox />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },

  {
    path: '/TabletIntegration',
    breadcrumbName: 'Estado de certificados de integración',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <ThirdPartiesProvider>
            <StatusTableView />
          </ThirdPartiesProvider>
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },

  {
    path: '/TabletIntegrationCorrected',
    breadcrumbName: 'Certificados de integración corregido',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <ThirdPartiesProvider>
            <FormsCorrected />
          </ThirdPartiesProvider>
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },

  {
    /* path: '/ShowIntegrationBox',
    breadcrumbName: 'Ver certificado de integración',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <ShowIntegrationBox />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    ) */
  },
  {
    path: '/IntegrationCertificateReview',
    breadcrumbName: 'Revision',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <IntegrationCertificateReview />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },

  {
    path: '/CorrectedCertificate',
    breadcrumbName: 'Corrección certificados de integración',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <ViewCorrection />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },

  {
    path: '/inventario',
    breadcrumbName: 'Inventario',
    element: (
      <InventoryControlProvider>
        <ThirdPartiesProvider>
          <InventoryControl />
        </ThirdPartiesProvider>
      </InventoryControlProvider>
    )
  },

  {
    path: '/InventoryInProcess',
    breadcrumbName: 'Inventario en trámite',
    element: (
      <ThirdPartiesProvider>
        <InventoryInProcess />
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/auxReference',
    breadcrumbName: 'Auxiliar referencia',
    element: (
      <ThirdPartiesProvider>
        <AuxReference />
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/auxBalance',
    breadcrumbName: 'Auxiliar saldo',
    element: (
      <ThirdPartiesProvider>
        <AuxBalance />
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/auxOutputs',
    breadcrumbName: 'Auxiliar salidas',
    element: (
      <ThirdPartiesProvider>
        <AuxOutputs />
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/temporaryAux',
    breadcrumbName: 'Auxiliar temporales',
    element: (
      <ThirdPartiesProvider>
        <TemporaryAux />
      </ThirdPartiesProvider>
    )
  },
  {
    path: '/inventarioProduc',
    breadcrumbName: 'Producto',
    element: (
      <ThirdPartiesProvider>
        <InventoryProduct />
      </ThirdPartiesProvider>
    )
  },

  {
    path: '/AuditoriaDeInventario',
    breadcrumbName: 'Auditoria de inventario',
    element: (
      <InventoryControlProvider>
        <ThirdPartiesProvider>
          <InventoryAudit />
        </ThirdPartiesProvider>
      </InventoryControlProvider>
    )
  },

  {
    path: '/pesaje',
    breadcrumbName: 'Pesaje',
    element: (
      <WeighingProvider>
        <ThirdPartiesProvider>
          <Weighing />
        </ThirdPartiesProvider>
      </WeighingProvider>
    )
  },
  {
    path: '/ReporteBascula',
    breadcrumbName: 'Reporte báscula',
    element: (
      <WeighingProvider>
        <ThirdPartiesProvider>
          <ReportWeighing />
        </ThirdPartiesProvider>
      </WeighingProvider>
    )
  },
  {
    path: '/Reports',
    breadcrumbName: 'Reporte de salida por puerta liviano',
    element: (
      <WeighingProvider>
        <ThirdPartiesProvider>
          <Reports />
        </ThirdPartiesProvider>
      </WeighingProvider>
    )
  },
  {
    path: '/Basculas',
    breadcrumbName: 'Básculas',
    element: <WeighingMachine />
  },
  {
    path: '/ShowWeighing',
    breadcrumbName: 'Ver tiquete',
    element: <ShowWeighing />
  },

  {
    path: '/Blocking',
    breadcrumbName: 'Bloqueos',
    element: (
      <BlockingCRUDProvider>
        <ThirdPartiesProvider>
          <Blocking />
        </ThirdPartiesProvider>
      </BlockingCRUDProvider>
    )
  },

  {
    path: '/CrearOrdenDeProduccion',
    breadcrumbName: 'Editar orden de producción',
    element: (
      <IntegracionBoxCRUDProvider>
        <OrderProductionProvider>
          <FormCreateOrder />
        </OrderProductionProvider>
      </IntegracionBoxCRUDProvider>
    )
  },
  {
    path: '/VerOrdenDeProduccion',
    breadcrumbName: 'Ver orden de producción',
    element: (
      <OrderProductionProvider>
        <ShowProductionOrder />
      </OrderProductionProvider>
    )
  },
  {
    path: '/RevisarOrdenDeProduccion',
    breadcrumbName: 'Revisar orden de producción',
    element: (
      <OrderProductionProvider>
        <CheckFmmOrder />
      </OrderProductionProvider>
    )
  },
  {
    path: '/OrdenDeProduccion',
    breadcrumbName: 'Orden de producción',
    element: (
      <OrderProductionProvider>
        <ThirdPartiesProvider>
          <ProductionOrder />
        </ThirdPartiesProvider>
      </OrderProductionProvider>
    )
  },
  {
    path: '/security/*',
    breadcrumbName: 'Seguridad',
    element: <SecurityRoutes />
  },

  {
    path: '/Coladepresentados',
    breadcrumbName: 'Cola de documentos presentados',
    element: <SubmittedQueue />
  },

  {
    path: '/Perfilardocumento',
    breadcrumbName: 'Perfilado de documento',
    element: (
      <DianCRUDProvider>
        <ThirdPartiesProvider>
          <ProfileDocuments />
        </ThirdPartiesProvider>
      </DianCRUDProvider>
    )
  },

  {
    path: '/FinalizarPerfilamiento',
    breadcrumbName: 'Finalizar Perfilamiento',
    element: (
      <DianCRUDProvider>
        <FinishProfiling />,
      </DianCRUDProvider>
    )
  },

  {
    path: '/PerfilarItemsDocument',
    breadcrumbName: 'Ítem  en perfilamiento',
    element: (
      <DianCRUDProvider>
        <ItemProfile />,
      </DianCRUDProvider>
    )
  },
  {
    path: '/Anexo',
    breadcrumbName: 'Anexo 5',
    element: (
      <DianCRUDProvider>
        <ThirdPartiesProvider>
          <Annexed />
        </ThirdPartiesProvider>
      </DianCRUDProvider>
    )
  },

  {
    path: '/Listadodian',
    breadcrumbName: 'Reporte listado DIAN',
    element: (
      <DianCRUDProvider>
        <ListDian />,
      </DianCRUDProvider>
    )
  },
  {
    path: '/Planilladeenvio',
    breadcrumbName: 'Planilla de envio',
    element: (
      <DianCRUDProvider>
        <ShippingForm />,
      </DianCRUDProvider>
    )
  },

  {
    path: '/ProfileFinish',
    breadcrumbName: 'Items perfilados',
    element: (
      <DianCRUDProvider>
        <ItemProfileFinish />
      </DianCRUDProvider>
    )
  },

  {
    path: '/Extractos',
    breadcrumbName: 'Extractos',
    element: (
      <TreasuryCRUDProvider>
        <ThirdPartiesProvider>
          <Extracts />
        </ThirdPartiesProvider>
      </TreasuryCRUDProvider>
    )
  },

  {
    path: '/VentasDeServicios',
    breadcrumbName: 'Ventas de Servicios',
    element: (
      <TreasuryCRUDProvider>
        <ThirdPartiesProvider>
          <SalesOfService />
        </ThirdPartiesProvider>
      </TreasuryCRUDProvider>
    )
  },

  {
    path: '/ActualizacionDeTarifa',
    breadcrumbName: 'Actualización de tarifas',
    element: (
      <TreasuryCRUDProvider>
        <Update />
      </TreasuryCRUDProvider>
    )
  },

  {
    path: '/FormActualizacionDeTarifa',
    breadcrumbName: 'Actualización de tarifas',
    element: (
      <TreasuryCRUDProvider>
        <FormUpdate />
      </TreasuryCRUDProvider>
    )
  },

  {
    path: '/TarifasEspeciales',
    breadcrumbName: 'Tarifas especiales',
    element: (
      <TreasuryCRUDProvider>
        <SpecialRates />
      </TreasuryCRUDProvider>
    )
  },
  {
    path: '/Pesajes',
    breadcrumbName: 'Pesajes',
    element: (
      <TreasuryCRUDProvider>
        <ThirdPartiesProvider>
          <Weigh />
        </ThirdPartiesProvider>
      </TreasuryCRUDProvider>
    )
  },
  {
    path: '/Record',
    breadcrumbName: 'Historial de actualización tarifas',
    element: (
      <TreasuryCRUDProvider>
        <RecordUpdate />
      </TreasuryCRUDProvider>
    )
  },

  {
    path: '/EstadisticasGeneral',
    breadcrumbName: 'Estadísticas general',
    element: <EstadisticasGeneral />
  },
  {
    path: '/EstadisticasOpeIngSal',
    breadcrumbName: 'Estadísticas operaciones utilizadas',
    element: <OperationsUsed />
  },
  {
    //breadcrumbName: "Estadísticas documentos por Ope. Ingresos y salidas",
    path: '/EstadisticasAdmin',
    breadcrumbName: 'Estadísticas de Ope. Ingresos y Salidas',
    element: (
      <StatisProvider>
        <EstadisticasIngresoSalida />
      </StatisProvider>
    )
  },
  {
    path: '/EstadisticasOperacionesUsadas',
    breadcrumbName: 'Estadísticas de operaciones en documentos',
    element: <EstadisticasOpeIO />
  },
  {
    path: '/EstadisticasCargoVehicle',
    breadcrumbName: 'Estadísticas vehículo',
    element: <CargoVehicle />
  },
  {
    path: '/EstadisticasSeguridad',
    breadcrumbName: 'Estadísticas seguridad',
    element: <EstadisticasSecurity />
  },
  {
    path: '/EstadisticasQualifiedUser',
    breadcrumbName: 'Estadísticas Usuarios calificados',
    element: <EstadisticaQualifiedUser />
  },
  {
    path: '/EstadisticasMovimiento',
    breadcrumbName: 'Estadísticas movimientos',
    element: <EstadisticaMovimiento />
  },
  {
    path: '/EstadisticasCese',
    breadcrumbName: 'Estadística de cese de actividades',
    element: <EstadisticaCese />
  },
  {
    path: "/EstadisticasOperaciones",
    breadcrumbName: "Estadísticas de Operaciones",
    element: <OperationsStatistics />
  },
  {
    path: '/DocuExportPro',
    breadcrumbName: 'Exportar Documentos',
    element: <DocuExportPro />
  },

  {
    path: '/motivodevolucion',
    breadcrumbName: 'Motivo de devolución',
    element: (
      <ReturnReasonnProvider>
        <ThirdPartiesProvider>
          <ReturnReason />
        </ThirdPartiesProvider>
      </ReturnReasonnProvider>
    )
  },
  {
    path: '/dane',
    breadcrumbName: 'Estadisticas DANE',
    element: <Dane />
  },
  {
    path: '/dashboard',
    breadcrumbName: 'Panel de Control',
    element: <DashboardPage />
  },
  {
    path: '/Robot-mode',
    breadcrumbName: 'Modo en Robot',
    element: <ModeRobot />
  },
  {
    path: '/explore',
    breadcrumbName: 'Edición de gráficos',
    element: <ExplorePage />
  },
  {
    path: '/FormatSRT',
    breadcrumbName: 'Formato SRT',
    element: (
      <GlobalContextProvider>
        <FormCrudCRUDProvider>
          <FormatSRT />
        </FormCrudCRUDProvider>
      </GlobalContextProvider>
    )
  },
  {
    path: '/DEI',
    breadcrumbName: 'Declaración especial de importación',
    element: <DEI />
  },
  // Ruta No encontrada
  {
    path: '*',
    breadcrumbName: 'Ruta No encontrada',
    element: <NoFound />
  },

  {
    path: '/UsuarioBascula',
    breadcrumbName: 'Conf. Usuario Báscula',
    element: (
      <GlobalContextProvider>
        <ConfigurationCRUDProvider>
          <UsersWeighing />
        </ConfigurationCRUDProvider>
      </GlobalContextProvider>
    )
  }
]

const renewTokenChannel = new BroadcastChannel('renew-token-channel')
export const privateRouterZFEdit = [...privateRouteZF]

const RoutePrivateZF = props => {
  const altoht = document.documentElement.scrollHeight
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [collaps, setCollaps] = useState(true)
  const location = useLocation()
  const [openAlert] = AlertErrorAlert()
  const [modalOpen, setModalOpen] = useState(false)

  const [isOpenModalExpired, onOpenModalExpired, onCloseModalExpired] =
    useModal()
  const token = useSelector(state => state.auth.dataUser.token)
  const dispacthRedux = useDispatch()
  const navigate = useNavigate()
  const idFuncionaio = useSelector(state => state.auth.dataUser.id)
  const { ciModuleDynamic } = useSelector((state) => state?.form) || {};

  renewTokenChannel.onmessage = event => {
    dispacthRedux(updateToken(event?.data))
  }

  useEffect(() => {
    const ModeRobot = GetLocalStorage('Mode_Robot')
    let router_current = ''
    const allowedRoutes = [
      '/CheckFormEntry',
      '/CheckForm',
      '/FormShow',
      '/FormShowExit',
      '/FormCorrection',
      '/FormCorrectionOutput',
      '/ShowIntegrationBox',
      '/Robot-mode',
      '/CheckCorrectEntry',
      '/CheckCorrectOutput'
    ]
    router_current = allowedRoutes.includes(location.pathname)

    if (ModeRobot && router_current) {
    } else {
      ADDLocalStorage(false, 'Mode_Robot')
    }
  }, [location])

  const { date_expires } = useSelector(state => state.auth.dataUser)
  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationDate = new Date(date_expires)
      const currentTime = new Date()
      const timeDiffInMilliseconds = expirationDate - currentTime
      const timeDiffInMinutes = Math.floor(timeDiffInMilliseconds / (1000 * 60))

      /* console.log({ expirationDate, currentTime, timeDiffInMinutes, date_expires }); */

      if (timeDiffInMinutes === 0) {
        handleLogout(token, () => {}, dispacthRedux, navigate, idFuncionaio)
        openAlert(
          'Tu token ha expirado. Por favor, inicia sesión nuevamente para continuar'
        )
      }

      // Evitar alertas repetidas dentro de un corto período
      if (timeDiffInMinutes <= 5 && timeDiffInMinutes >= 0 && !modalOpen) {
        openAlert(
          `¡Atención! Tu token está a punto de expirar en ${timeDiffInMinutes} minutos. Por favor, asegúrate de renovarlo a tiempo`
        )
        setModalOpen(true)
        onOpenModalExpired()
      }
    }

    // Verificar inmediatamente al cargar el componente
    checkTokenExpiration()

    // Intervalo de verificación ajustado (cada minuto)
    const intervalId = setInterval(checkTokenExpiration, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [date_expires])

  const redirectRouter = () => {
    const redirectUrl = localStorage.getItem("redirectUrl") || null;
    localStorage.removeItem("redirectUrl");
    if (isValid(redirectUrl)) navigate(redirectUrl);
  };

  useEffect(() => {
    redirectRouter()
  }, []);

  const ciTitle = {
    showName: "Ver certificado de integración",
    editName: "Certificado de integración",
  };
  const breadcrumbNameShow = ciModuleDynamic?.nameModul
    ? `Ver ${ciModuleDynamic.nameModul}`
    : ciTitle.showName;

  const breadcrumbNameEdit = ciModuleDynamic?.nameModul
    ? `Editar ${ciModuleDynamic.nameModul}`
    : ciTitle.editName;

  const showIntegrationBoxElement = (
    <IntegracionBoxCRUDProvider>
      <OrderProductionProvider>
        <ShowIntegrationBox />
      </OrderProductionProvider>
    </IntegracionBoxCRUDProvider>
  );

  const integrationBoxElement = (
    <IntegracionBoxCRUDProvider>
      <OrderProductionProvider>
        <IntegracionBox />
      </OrderProductionProvider>
    </IntegracionBoxCRUDProvider>
  );

  privateRouterZFEdit.splice(53, 1, {
    path: "/ShowIntegrationBox",
    breadcrumbName: breadcrumbNameShow,
    element: showIntegrationBoxElement,
  });

  privateRouterZFEdit.splice(49, 1, {
    path: "/IntegracionBox",
    breadcrumbName: breadcrumbNameEdit,
    element: integrationBoxElement,
  });

  return (
    <div className='row'>
      <div className='col-1'>
        <TokenExpirer
          isOpen={isOpenModalExpired}
          open={onOpenModalExpired}
          onClose={onCloseModalExpired}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />

        <FormCrudCRUDProvider>
          <Aside
            state={collaps}
            set={setCollaps}
            isAdvancedSearchModal={isOpen}
            openAdvancedSearchModal={onOpen}
            onCloseAdvancedSearchModal={onClose}
          />
        </FormCrudCRUDProvider>
      </div>

      <div className='col-11'>
        <MainStyled>
          <div className='mb-4 container'>
            <ContaianeFalse
              het={`${altoht}px`}
              className={'izq ' + (!collaps ? '' : 'oculto')}
              onClick={() => setCollaps(!collaps)}
            />
            <FormCrudCRUDProvider>
              <FirstLoginProvider>
                <Header set={setCollaps} state={collaps} />
              </FirstLoginProvider>
            </FormCrudCRUDProvider>
            <Routes>
              {privateRouterZFEdit.map(({ path, element }, i) => (
                <Route key={i} path={path} element={element} />
              ))}
            </Routes>
          </div>
        </MainStyled>
      </div>
    </div>
    // pa que suba luego lo borro
  )
}
export default RoutePrivateZF
