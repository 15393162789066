import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormtemporaryAssistant = ({ onClose, handleSearchSubmit }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["authorization_code", "item_id", "operation", "operation_initial", "date_final", "date_initial", "certificate"], nestedProp: "searchAdvance" });

  const [openAlert] = AlertErrorAlert();
  const [stateTypeSearch, setStateTypeSearch] = useState(null);

  const [values, handleInputChange] = useForm({
    authorization_code: state?.authorization_code || "",
    item_id: state?.item_id || "",
    operation: state?.operation || "",
    operation_initial: state?.operation_initial || "",
    date_final: state?.date_final || "",
    date_initial: state?.date_initial || "",
    certificate: state?.certificate || ""
  });

  const {
    authorization_code,
    item_id,
    date_initial,
    date_final,
    operation,
    operation_initial,
    certificate
  } = values;

  const handleSubmit = () => {
    if (stateTypeSearch && stateTypeSearch.value === "Certificados") {
      if (certificate === "") return openAlert("El número de certificado es requerido", "error");
    }

    if (authorization_code === "" && stateTypeSearch.value !== "Certificados") return openAlert("El número de autorización es requerido", "error");
    if (item_id === "" && stateTypeSearch.value !== "Certificados") return openAlert("El número de item es requerido", "error");
    
    setParamsSearch({
      params: {
        ...handleSanitizedObj(values),
      }
    })

    handleSearchSubmit(values);

  };

  const TypeSearch = [
    {
      value: "Salidas",
      label: "Salidas"
    },
    {
      value: "Certificados",
      label: "Certificados"
    }
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Tipo de busqueda"}
            data={TypeSearch}
            value={stateTypeSearch}
            set={setStateTypeSearch}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={
            stateTypeSearch && stateTypeSearch.value === "Certificados"
              ? "certificate"
              : "authorization_code"
          }
          value={
            stateTypeSearch && stateTypeSearch.value === "Certificados"
              ? certificate
              : authorization_code
          }
          onChange={handleInputChange}
          tag={
            stateTypeSearch && stateTypeSearch.value === "Certificados"
              ? "Nro. Certificado*"
              : "Nº de autorización *"
          }
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"item_id"}
          value={item_id}
          onChange={handleInputChange}
          tag={"Nº de item *"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          value={date_initial}
          name={"date_initial"}
          onChange={handleInputChange}
          tag={"Fecha inicial"}
          size={45}
          tipo={"date"}
        // maxiLength={"10"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        // maxiLength={"10"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"operation"}
          value={operation}
          onChange={handleInputChange}
          tag={"Operación"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />
        <LabelGroup
          name={"operation_initial"}
          value={operation_initial}
          onChange={handleInputChange}
          tag={"Operación inicial"}
          size={45}
          tipo={"text"}
          maxiLength={"3"}
        />
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
