/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __Cities } from "request/configuration/__Cities";
import { useSelector } from "react-redux";
import TbodyDependency from "../tables/TbodyDependency";
import { __GetDependency } from "request/configuration/__Dependency";
import FormDependency from "../forms/FormDependency";
import { FormDependencyShow } from "../FormShow/FormDependencyShow";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formshowdependency = () => {
  return <FormDependencyShow />;
};

const FormDepemdencys = () => {
  return <FormDependency />;
};

export const Dependency = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Dependencia", order: "dependency" },
    { name: "Estado", order: "status" },
    { name: "Acciones", order: "" }
  ];

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(FormDepemdencys);
    setFormShow(formshowdependency);
    setRequestEdit(environment.DEPENDENCY_UPDATE);
    setRequestCreate(environment.DEPENDENCY_CREATE);
    setRequestDelete(environment.DEPENDENCY_DELETE); 
    queryClient.invalidateQueries({ queryKey: ["configDependency"]});
   }, [update, q]);

 


  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configDependency", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.DEPENDENCY_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])


  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Dependencias"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyDependency}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
