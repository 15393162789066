export const TitleBlocking = [
  { name: "Nit", order: null },
  { name: "Usuario calificado", order: null },
  { name: "", order: null }
];

export const TitleDependency = [
  { name: "Dependencia", order: null },

  { name: "Estado", order: null },
  { name: "Tipo de Bloqueo", order: null }
];

export const TitleHistory = [
  { name: "Dependencia", order: null },
  { name: "Estado", order: null },
  { name: "Fecha", order: null },
  { name: "Operador", order: null },
  { name: "Observaciones", order: null }
];

export const TitleBlockingSearch = [
  { name: "Nit", order: null },
  { name: "Usuario calificado", order: null },
  { name: "Dependencia", order: null },
  { name: "Tipo de Bloqueo", order: null },
  { name: "Estado", order: null },
  { name: "Fecha", order: null },
  { name: "Operador", order: null },
  { name: "Observaciones", order: null }
];
