import { Td, Tr } from "@chakra-ui/react";
import OptionsWaste from "components/Menu/MenuIntegracionBox/OptionsWaste";
import { useLocation } from "react-router-dom";

const TBodyGarbage = ({ data }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Tr>
      <Td className="text-center">
        {data?.item_code ?? data?.item_code_corrected}
      </Td>
      <Td className="text-center">
        {data?.reference ?? data?.reference_corrected}
      </Td>
      <Td className="text-center">
        {data?.quantity_garbage ?? data?.quantity_garbage_corrected}
      </Td>
      <Td className="text-center">
        {data?.business_unit ?? data?.business_unit_corrected}
      </Td>
      <Td className="text-center">
        {data?.net_weight_kilo ?? data?.net_weight_kilo_corrected}
      </Td>
      <Td className="text-center">
        {data?.fob_value ?? data?.fob_value_corrected}
      </Td>
      <Td className="text-center">
        {data?.description_product ?? data?.description_product_corrected}
      </Td>
      {
        (pathname === "/IntegracionBox" && !data.production_order_id)
          ? <Td className="text-center" isNumeric> <OptionsWaste data={data} /></Td>
          : <Td> </Td>
      }
    </Tr>
  );
};

export default TBodyGarbage;
