import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { isString, isValid } from 'utils/type-check-utils'

const TabOptions = ({
  renderComponent = {},
  title = null,
  tabProperty = null,
  children = <></>
}) => {
  if (!isValid(renderComponent[tabProperty]) && !isValid(renderComponent)) {
    console.log('No se ha renderizado el componente')
    return
  }

  const renderTab = isValid(renderComponent[tabProperty])
    ? renderComponent[tabProperty] || {}
    : renderComponent

  return (
    <Flex gap={4} padding={4} w={'100%'} justifyContent={'space-between'}>
      {isString(title) && (
        <Text className='text-title-item' fontSize='xl'>
          {title}
        </Text>
      )}
      <Spacer />
      <Box display={'flex'} gap={1} alignItems={'center'}>
        {Object.keys(renderTab).map(key => (
          <>{renderTab[key].component}</>
        ))}

        {children}
      </Box>
    </Flex>
  )
}

export default TabOptions
