/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import { useForm } from "../../hooks/useForm";
import { ButtonStyled } from "../../styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import ConfigurationCRUDContext from "../../context/ConfigurationCRUDContext";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const EditFormTypeProducts = () => {
  const initialValues = {
    name_typeProduct: "",
    description_typeProduct: ""
  };

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, edit, setValues] =
    useForm(initialValues);
  const { name_typeProduct, description_typeProduct } = values;

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
    } else {
      setValues(initialValues);
    }
  }, [dataToEdit]);

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const handleAceptar = () => {
    if (dataToEdit) {
      editData(values);
      // reset();
    } else {
      createData(values);
      // reset();
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? " Editar Producto" : "Crear Producto"}
      </Text>
      <Divider mb={1} />

      <form onSubmit={hanldeSubmit}>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Nombre</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_typeProduct"
              value={name_typeProduct}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.typeProducts.name_typeProduct}
            />
          </div>
          <div>
            <LabeFormlStyled>Descripción</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="description_typeProduct"
              value={description_typeProduct}
              onChange={handleInputChange}
              maxLength={MaxLength.config.typeProducts.description_typeProduct}
            />
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleAceptar}>
            Aceptar
          </ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default EditFormTypeProducts;
