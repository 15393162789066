import { TablaExportPdf } from "components/Tables/TablaExportPdf";
import React, { useEffect, useState } from "react";
import { Logo, LogoWhite } from "styled/Logo.styled";
import {
  cabeceraExportPdf,
  cabeceraExportPdfOtros,
  cabeceraExportPdfSuplies
} from "./ArrayOrder/ArraPdf";
import { TbodyExporPdfProduct } from "./CreateNewProductoOrder/Table/TbodyExporPdfProduct";
import { TbodyPDFOther } from "./CreateNewProductoOrder/Table/TbodyPDFOther";
import { TbodyPdfSuplies } from "./CreateNewProductoOrder/Table/TbodyPdfSuplies";
import { InformacionCabecera } from "./ExportPdfComponent.js/InformacionCabecera";
import "./Style/Style.css";

export const ExportPdf = ({ values }) => {
  const { users } = values;
  const [autorizado, setAutorizado] = useState(null);
  const [presentado, setPresentado] = useState(null);

  useEffect(() => {
    if (users) {
      const dataPresentado = users?.find(
        (element) => element?.status_form === "PRESENTADO"
      );
      if (dataPresentado !== undefined) setPresentado(dataPresentado);

      const dateAutorizado = users?.find(
        (element) => element?.status_form === "AUTORIZADO"
      );
      if (dateAutorizado !== undefined) setAutorizado(dateAutorizado);
    }
  }, [values, users]);

  return (
    <div id="pdfId">
      <div className="d-flex mt-5">
        <div id="banner" />
        <Logo width={"180px"} className="mx-2" />
      </div>

      <div className="p-3">
        <InformacionCabecera values={values} />
      </div>
      {values.product?.length !== 0 && (
        <TablaExportPdf
          thead={cabeceraExportPdf}
          tbodyData={TbodyExporPdfProduct}
          data={values.product ? values.product : []}
        />
      )}

      {values.suplies?.length !== 0 && (
        <TablaExportPdf
          thead={cabeceraExportPdfSuplies}
          tbodyData={TbodyPdfSuplies}
          data={values?.suplies ? values?.suplies : []}
        />
      )}

      {values.other_costs?.length !== 0 && (
        <TablaExportPdf
          thead={cabeceraExportPdfOtros}
          tbodyData={TbodyPDFOther}
          data={values?.other_costs ? values?.other_costs : []}
        />
      )}

      <div className="py-5">
        {presentado && (
          <div id="firma" className="float-end mx-5">
            Presentado por: {presentado && presentado.person_name}
            <br />
            {presentado && `${presentado.date_form} ${presentado.time_form}`}
          </div>
        )}

        {autorizado && (
          <div id="firma" className="float-end mx-5">
            Autorizado por: {autorizado && autorizado.person_name}
            <br />
            {autorizado && `${autorizado.date_form} ${autorizado.time_form}`}
          </div>
        )}
      </div>
      <div className="py-5"></div>
      {/* <footer id="footer" className="mt-5">
        <LogoWhite className="float-end m-2" width={"150px"} />
      </footer> */}
    </div>
  );
};
