/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { __PostForm } from "request/Petitions/__Post";
import { HeaderSearch } from "styled/SectionContainer.styled";
import queryString from "query-string";
import { TitleItems } from "pages/Dian/ArrayTitleDian/ArrayTitle";
import TbodyItemsProfile from "pages/Dian/Table/TbodyItems";
import { Title } from "styled/Title";
import DianCRUDContext from "context/DianCrudContext";
import "./StileItems.css";
import { ButtonStyled } from "styled/Buttons.styled";
import { Box } from "@chakra-ui/react";

const ItemProfile = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const { IdForm = "" } = queryString.parse(location.search);
  const navigate = useNavigate();

  const { dataFormItems, update } = useContext(DianCRUDContext);

  const { qualified_user_name, form_code, document_type } = dataFormItems;

  const getItems = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const res = await __PostForm(
          environment.DOCUMENT_PROFILE_GET_ITEMS,
          token,
          data
        );
        setCurrentPage(res?.data?.status?.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const handleReturn = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (IdForm !== "" && document_type !== "") {
      const DataIdForms = new FormData();
      DataIdForms.append("document_type", document_type);
      DataIdForms.append("form_id", IdForm);

      getItems(DataIdForms);
    }
  }, [getItems, IdForm, update]);

  return (
    <>
      <Title style={{ marginTop: 30, marginBottom: 40 }}>
        Ítem en perfilamiento
      </Title>

      <div className="d-flex justify-content-around mb-3">
        <div>
          <span className="bold">Usuarios:</span> {qualified_user_name}
        </div>
        <div>
          <span className="bold">
            {document_type === "FORM.INGR." ? "Ingreso" : "Salida"}
          </span>{" "}
          : {form_code}
        </div>
      </div>
      <HeaderSearch>
        <div className="px-5">
          <div>
            <TableOrder
              thead={TitleItems}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyItemsProfile}
              className={"verticalNone"}
            />

            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        </div>
      </HeaderSearch>
      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} type="submit" onClick={handleReturn}>
          volver
        </ButtonStyled>
      </Box>
    </>
  );
};

export default ItemProfile;
