import React from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import { OptionsBorrador } from "./OptionsBorrador";
import { OptionsPresents } from "./OptionsPresents";
import { OptionsAutorizado } from "./OptionsAutorizado";

const MenuPrincipal = ({ data, deleteFiled = true }) => {
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>
      {/* {movement === "Ingreso" ? ( */}
      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {data.production_order_status === "BORRADOR" ? (
          <OptionsBorrador data={data} />
        ) : data.production_order_status === "PRESENTADO" ? (
          <OptionsPresents data={data} />
        ) : data.production_order_status === "AUTORIZADO" ? (
          <OptionsAutorizado data={data} />
        ) : (
          ""
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuPrincipal;
