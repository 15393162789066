import React from "react";
import HomeScreen from "./Auth/HomeScreen/HomeScreen";
import { Container } from "styled/Containers.styled";
// import Landing from "./Landing/Landing";
// import Footer from "./Footer/Footer";
import { FirstLoginProvider } from "context/FirstLoginContext";

const Home = () => {
  return (
    <Container>
      <FirstLoginProvider>
        <HomeScreen />
      </FirstLoginProvider>
      {/* <Landing /> */}
      {/* <Footer /> */}
    </Container>
  );
};

export default Home;
