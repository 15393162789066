import React, { useContext } from "react";
import { IconMenuRUD } from "styled/Icons.styled";
import { MdContentCopy } from "react-icons/md";
import { HrDividerNoMargin } from "styled/Line.styled";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { MenuItem } from "@chakra-ui/react";
// import { AuthContext } from "context/AuthContext";
import OrderProductionContext from "context/OrderProductionContext";
import { RiDeviceRecoverFill } from "react-icons/ri";
import { BiShow } from "react-icons/bi";
import { AiOutlineFileSync } from "react-icons/ai";
import { AuthContext } from "context/AuthContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;
export const OptionsPresents = ({ data }) => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);

  // const { authUser } = useContext(AuthContext);

  const {
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason,
  } = useContext(OrderProductionContext);

  const handleReview = (e) => {
    navigate(`/RevisarOrdenDeProduccion?Orden=${data.production_order_id}`);
  };

  const handleChangeStatus = (status) => {
    const info = {
      id: data.production_order_id,
      status_ProductionOrderForm: status,
    };
    if (status === "DEVUELTO") {
      onOpenReturnReason();
      setReturnReason(info);
    } else {
      changeStatus(info);
    }
  };

  const handleCopy = () => {
    setDataToCopy(data);
    onOpenCopy();
  };

  //   const handleDesechar = () => {
  //     setDataToDesechar(data);
  //     onOpenDesechar();
  //   };

  return (
    <>
      <MenuItemStyled py={3} onClick={() => handleChangeStatus("BORRADOR")}>
        <IconMenuRUD as={RiDeviceRecoverFill} />
        Recuperar
      </MenuItemStyled>
      <HrDividerNoMargin />
      {/*     <MenuItemStyled onClick={() => handleChangeStatus("DEVUELTO")} py={3}>
        <IconMenuRUD color="#d92525" as={AiOutlineFileSync} />
        Devolver
      </MenuItemStyled>
      <HrDividerNoMargin /> */}

      {(authUser.is_qualified_user !== 1 || authUser.is_commerce === 1) && (
        <>
          <MenuItemStyled onClick={handleReview} py={3}>
            <IconMenuRUD color="#58B158" as={BiShow} />
            Revisar
          </MenuItemStyled>
          <HrDividerNoMargin />
        </>
      )}

      <MenuItemStyled onClick={handleCopy} py={3}>
        <IconMenuRUD color="#F9A621" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      {/*   <HrDividerNoMargin /> */}
    </>
  );
};
