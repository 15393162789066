import { Box } from "@chakra-ui/react";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import React from "react";

export const InformacionPesaje = ({ values }) => {
  return (
    <>
      <Box className="row">
        <Box className="col-md-6">
          <InputWithLabel
            p={1}
            name={"vehicle_plate"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Placa"}
            value={values?.vehicle_plate ?? "N/A"}
          />
        </Box>

        <Box className="col-md-6">
          <InputWithLabel
            p={1}
            name={"operation"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Tipo de operación"}
            value={values?.operation ?? "N/A"}
          />
        </Box>

        <Box className="col-md-6">
          <InputWithLabel
            p={1}
            name={"schedule"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Horario"}
            value={values?.shedule ?? "N/A"}
          />
        </Box>

        <Box className="col-md-6">
          <InputWithLabel
            p={1}
            name={"net_weight"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Peso neto"}
            value={values?.net_weight ?? "N/A"}
          />
        </Box>
      </Box>

      <Box className="row">
        <Box className="col-md-4">
          <InputWithLabel
            p={1}
            name={"status"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Estado"}
            value={values?.status ?? "N/A"}
          />
        </Box>

        <Box className="col-md-4">
          <InputWithLabel
            p={1}
            name={"completeness"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Completitud"}
            value={values?.completeness ?? "N/A"}
          />
        </Box>

        <Box className="col-md-4">
          <InputWithLabel
            p={1}
            name={"passForm_number"}
            fontWeight={0}
            style={{
              borderRadius: 5,
              border: "1px solid gray"
            }}
            borderRadius={"none"}
            labelText={"Número formulario"}
            value={values?.passForm_number ?? "N/A"}
          />
        </Box>
      </Box>

      <Box className="row">
        <InputWithLabel
          p={1}
          name={"description"}
          fontWeight={0}
          style={{
            borderRadius: 5,
            border: "1px solid gray"
          }}
          borderRadius={"none"}
          labelText={"Descripción de la mercancía"}
          value={values?.description ?? "N/A"}
        />
      </Box>
    </>
  );
};
