export const ValidatePresenFmmCorrect = (data, status) => {
  /* if (
    status === "PRESENTADO" &&
    (data.code_operation === 321 ||
      data.code_operation === 324 ||
      data.code_operation === 327)
    //   &&
    // data.national_or_foreign_corrected === "Nacional" &&
    // (data.num_commercial_invoice_corrected === "" ||
    //   data.num_commercial_invoice_corrected === null ||
    //   data.num_commercial_invoice_corrected === undefined)
  ) {
    return "El ingreso debe soportarse con una Factura";
  } else  if (
    status === "PRESENTADO" &&
    (data.code_operation === 310 || data.code_operation === 315)
    //  &&
    // data.national_or_foreign_corrected === "Nacional" &&
    // (data.num_commercial_invoice_corrected === "" ||
    //   data.num_commercial_invoice_corrected === null ||
    //   data.num_commercial_invoice_corrected === undefined ||
    //   data.num_dex_entryFormCorrected === "" ||
    //   data.num_dex_entryFormCorrected === null ||
    //   data.num_dex_entryFormCorrected === undefined)
  ) {
    return "El ingreso debe soportarse con un DEX o Factura";
  } else */
  if (
    status === "PRESENTADO" &&
    data.code_operation >= 300 &&
    data.code_operation <= 400 &&
    data.code_operation !== 321 &&
    data.code_operation !== 324 &&
    data.code_operation !== 327 &&
    (data.num_commercial_invoice_corrected === "" ||
      data.num_commercial_invoice_corrected === null ||
      data.num_commercial_invoice_corrected === undefined) &&
    (data.num_import_declaration_corrected === "" ||
      data.num_import_declaration_corrected === null ||
      data.num_import_declaration_corrected === undefined)
  ) {
    return "Debe digitar la declaración o la Factura comercial";
  } else if (
    status === "PRESENTADO" &&
    data.code_operation >= 300 &&
    data.code_operation <= 400 &&
    (data.num_dex_entryFormCorrected !== "" ||
      data.num_dex_entryFormCorrected !== null ||
      data.num_dex_entryFormCorrected !== 0 ||
      data.num_dex_entryFormCorrected !== undefined) &&
    (data.name_department === "" ||
      data.name_department === null ||
      data.name_department === 0 ||
      data.name_department === undefined) &&
    data.national_or_foreign_corrected === "Nacional"
  ) {
    return "Debe digitar el departamento si digita DEX";
  }
};
