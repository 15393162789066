import { Box, Divider, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import {
  SwitchPropiosMedios,
  SwitchUpdateHot
} from "pages/RefProductInter/LabelGroup";
import { AuthContext } from "context/AuthContext";
import { Title } from "styled/Title";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import PassCrudContext from "context/PassCrudContext";
import { useSelector } from "react-redux";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { AccordionItemSimple, AccordionMain } from "./AccordionVehicle";
import styled from "styled-components";
import { MdOutlineEdit } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useGlobalContext } from "context/GlobalContext";
import { FaWeight } from "react-icons/fa";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { GoDotFill } from "react-icons/go";
import Swal from "sweetalert2";

const ButtonEditWeighing = styled.button`// estilos del botón de editar cantidad de tiquetes
  color: #ffff;
  background-color: #1e75bb;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSpecialMovement = styled.div`
  background: #1e75bb;
  color: #fff;
  border-radius: ${({ isClickable }) => (isClickable ? '5px' : '0px')};
  padding: 5px 10px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  box-shadow: ${({ isClickable }) => (isClickable ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none')};
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-3px);
    
  }
  &:hover {
    transform: ${({ isClickable }) => (isClickable ? 'translateY(-3px) scale(1.03)' : 'none')};
  }
`

export const VehiculoInfo = ({
  values,
  setValues,
  setManipuleo,
  manipuleo,
  setConfirmationDriver,
  confirmationDriver,
  setconfirmationCompany,
  confirmationCompany,
  typeOperation
}) => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { user_rol, admin, token } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [openAlert] = AlertErrorAlert();

  // dispatch_pass
  const {
    name_typeTransport,
    identification,
    freely_available,
    vehicle_plate,
    driver,
    number_packages,
    observations,
    containers,
    empty_pass,
    own_means,
    phone,
    consecutive,
    tare_weight,
    net_weight,
    gross_weight,
    weighing_id,
    tickets,
    weighings,
    special_for_lite,
    special
  } = values || {};

  const queryClient = useQueryClient()

  const location = useLocation();
  const { id = "", status = "", typeO = "" } = queryString.parse(location.search);
  const { editData } = useContext(PassCrudContext);
  const [specialMovement, setSpecialMovement] = useState(false);

  useEffect(() => {
    setSpecialMovement(special_for_lite === 1 ? true : false);
  }, [special_for_lite])


  const handleUpdateSwitch = (e, set) => {
    const nameVariable = e.target.name;
    const result = e.target.checked === true ? 1 : 0;
    if (e.target.checked === true) {
      set(1);
    } else {
      set(0);
    }
    hanldeSubmit(nameVariable, result);
  };

  const hanldeSubmit = (name, value) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("show", 1);
    formData.append(`${name}`, value);

    editData(formData, 1);
  };

  const handleShowPesaje = () => {
    navigate(
      `/ShowWeighing?weighing=${weighing_id}&Operation=${typeOperation}`
    );
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e?.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const [isEditing, setIsEditing] = useState(false); //estado para manejar el modo de edición cuando este en false no dejara editar el campo de cantidad de tiquetes
  const { requestData } = useGlobalContext();

  const editWeighing = useMutation({
    mutationFn: requestData
  })

  const editSpecialForLite = useMutation({
    mutationFn: requestData,
    onSuccess: (data) => {
      if (data?.data?.status?.code === 400) {
        setSpecialMovement(!specialMovement);
        return openAlert(data?.data?.status?.message, "error")
      }
      if (data?.data?.status?.code === 200) {
        openAlert(data?.data?.status?.message, "success")
        queryClient.invalidateQueries({ queryKey: ["showPass"] })
      }
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const handleEditWeighing = () => {
    if (isEditing) { // si el estado es true, se edita el campo de cantidad de tiquetes
      const formData = new FormData();
      formData.append("id", id);
      formData.append("show", 1);
      formData.append("weighings", weighings);
      if (!weighings || +weighings === 0) {
        return openAlert("Por favor ingrese la cantidad de tiquetes", "warn")
      }
      editWeighing.mutate({
        data: formData,
        Endpoint:  typeO === "Dispatch" ? environment.UPDATE_PASES_DISPATCH : environment.UPDATE_PASES_INT,
        PropertyBody: "sendJSONContentPOST"
      })
    }
    setIsEditing(!isEditing) //la primera vez que se le de click al botón de editar se activara el modo edición, la segunda vez que se le de click se desactivara el modo edición
  }

  const handleEditSpecialForLite = () => {


    const formData = new FormData();
    formData.append("id", id);
    formData.append("show", 1);

    if (!specialMovement) {
      setSpecialMovement(!specialMovement);
      formData.append("special_for_lite", 1);
      editSpecialForLite.mutate({
        data: formData,
        Endpoint: environment.UPDATE_PASES_INT,
        PropertyBody: "sendJSONContentPOST"
      })
    }

    if (specialMovement) {
      formData.append("special_for_lite", 0);
      showAlert("¿Esta seguro de desactivar la Salida por liviano?", "Esto eliminara el despacho especial que se haya creado anteriormente.", formData, () => {
        openAlert('Se desactivo la Salida por liviano', 'success')
        setSpecialMovement(!specialMovement);
      })
    }
  }

  const showAlert = (title, message, formData, callback = () => { }) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Desactivar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1e75bb',
      position: 'top'
    }).then(async result => {
      if (result.isConfirmed) {

        editSpecialForLite.mutate({
          data: formData,
          Endpoint: environment.UPDATE_PASES_INT,
          PropertyBody: "sendJSONContentPOST"
        })
        callback()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //setSpecialMovement(!specialMovement);
      }
    })
  }


  return (
    <>
      <div>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div style={{}}>
            <SwitchPropiosMedios
              status={own_means}
              // setStatus={setManipuleo}
              tag={"Propios medios "}
              desicion={true}
            />
          </div>

          {/**switch para el tipo de entrada solo visual */}
          {
            special === 1 ?
              <div style={{ background: "#ffc039", padding: "5px 10px" }}>
                <h3 style={{ fontWeight: "600", color: "#fff", fontSize: "16px" }}>Especial por vehículo compartido</h3>
              </div>
              :
              special_for_lite === 2
                ? <div style={{ background: "#ffc039", padding: "5px 10px" }}>
                  <h3 style={{ fontWeight: "600", color: "#fff", fontSize: "16px" }}>Especial por liviano</h3>
                </div>
                : special_for_lite === 3 ? null
                  : typeO === "Dispatch" ?
                    <div style={{}}>
                      <ButtonSpecialMovement>
                        <span><GoDotFill style={{ color: special_for_lite ? "#00ff9c" : "#ff343c" }} /></span>

                        <h3 style={{ fontWeight: "400", fontSize: "14px" }}>
                          Entrada por liviano {special_for_lite ? " Activa" : " Inactiva"}
                        </h3>
                      </ButtonSpecialMovement>
                    </div>
                    : status === "AUTORIZADO" && (special_for_lite === 1 || special_for_lite === 0) &&
                    <ButtonSpecialMovement onClick={handleEditSpecialForLite} isClickable={true}>
                      <span><GoDotFill style={{ color: specialMovement ? "#00ff9c" : "#ff343c" }} /></span>

                      <h3 style={{ fontWeight: "400", fontSize: "14px" }}>
                        {specialMovement ? "Desactivar " : "Activar "} Salida por liviano
                      </h3>
                    </ButtonSpecialMovement>
          }

        </div>
        <Divider
          className="my-2"
          style={{ width: `100%`, background: "#BABABA" }}
        />

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <FormInputGroup>
            <LabeFormlStyled>Placa vehículo</LabeFormlStyled>
            <InputFake>{vehicle_plate ?? "N/A"}</InputFake>
          </FormInputGroup>

          <FormInputGroup>
            <LabeFormlStyled>Cédula del conductor</LabeFormlStyled>
            <InputFake>{identification ?? "N/A"}</InputFake>
          </FormInputGroup>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <FormInputGroup>
            <LabeFormlStyled>Conductor</LabeFormlStyled>
            <InputFake>{driver ?? "N/A"}</InputFake>
          </FormInputGroup>

          <FormInputGroup>
            <LabeFormlStyled>Número de teléfono</LabeFormlStyled>
            <InputFake>{phone ?? "N/A"}</InputFake>
          </FormInputGroup>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <FormInputGroup>
            <InputWithLabel
              p={0}
              m={0}
              borderRadius={"none"}
              name="name_typeTransport"
              labelText={"Tipo de transporte"}
              inputBgColor={"gray"}
              value={name_typeTransport ?? "N/A"}
              style={{
                borderBottom: " 1px solid #bababa80"
              }}
            />
          </FormInputGroup>


          {
            status !== "BORRADOR" && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                {weighings !== null && (
                  <FormInputGroup>
                    <InputWithLabel
                      p={0}
                      m={0}
                      borderRadius={"none"}
                      name=""
                      labelText={"Cantidad de tiquete"}
                      inputBgColor={"gray"}
                      value={weighings ?? ""}
                      style={{
                        borderBottom: " 1px solid #bababa80"
                      }}
                      onChange={!isEditing ? null : (e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { // Solo permite números
                          setValues({ ...values, weighings: value });
                        } else {
                          openAlert("Solo se permiten números", "error");
                        }
                      }}
                      readonly={!isEditing}// solo puede ser editado cuando el estado de edición sea true(este activado)
                    />
                  </FormInputGroup>
                )}

                {weighings !== null
                  ? (<Tooltip placement="top" hasArrow label={isEditing ? "Guardar" : "Editar"}>
                    <ButtonEditWeighing onClick={handleEditWeighing}> {isEditing ? <IoMdSave /> : <MdOutlineEdit />}</ButtonEditWeighing>
                  </Tooltip>) /* los iconos cambian en base al modo de edición cuando este inactivo se mostrara el icono de un lápiz y cuando este activo se mostrara el icono de guardar */
                  : (<Tooltip placement="top" hasArrow label="Pesar">
                    <ButtonEditWeighing onClick={(e) => setValues({ ...values, weighings: 0 })}> <FaWeight /> </ButtonEditWeighing>
                  </Tooltip>)
                }
              </div>
            )
          }

        </SimpleGrid>

        {consecutive && (
          <div id="marginIcon" className="my-2">
            <Title size={"18px"} weight={600}>
              Tiquete de básculas
            </Title>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
              className="mt-1"
            >
              <FormInputGroup size="20%">
                <LabeFormlStyled>Tiquete</LabeFormlStyled>
                <InputFake>
                  {allAccess === true ||
                    myPermission?.includes("showWeighing")
                    ? (
                      <span onClick={handleShowPesaje} className="formNumber">
                        {consecutive ?? "N/A"}
                      </span>
                    )
                    : (
                      <span>{consecutive ?? "N/A"}</span>
                    )}
                </InputFake>
              </FormInputGroup>

              <FormInputGroup size="20%">
                <LabeFormlStyled>Peso neto</LabeFormlStyled>
                <InputFake>{net_weight ?? "N/A"}</InputFake>
              </FormInputGroup>
              <FormInputGroup size="20%">
                <LabeFormlStyled>Peso bruto</LabeFormlStyled>
                <InputFake>{gross_weight ?? "N/A"}</InputFake>
              </FormInputGroup>
              <FormInputGroup size="20%">
                <LabeFormlStyled>Peso tara</LabeFormlStyled>
                <InputFake>{tare_weight ?? "N/A"}</InputFake>
              </FormInputGroup>
            </div>
          </div>
        )}

        {containers
          ? (
            <div id="marginIcon">
              <Title size={"18px"} weight={600}>
                Contenedores
              </Title>
              {containers.map((items, index) => (
                <SimpleGrid
                  key={index}
                  minChildWidth="200px"
                  spacing="10px"
                  my={4}
                >
                  <FormInputGroup>
                    <LabeFormlStyled>Contenedor</LabeFormlStyled>
                    <InputFake>{items?.container ?? "N/A"}</InputFake>
                  </FormInputGroup>

                  <FormInputGroup>
                    <LabeFormlStyled>Capacidad</LabeFormlStyled>
                    <InputFake>{items?.capacity_container ?? "N/A"}</InputFake>
                  </FormInputGroup>

                  <FormInputGroup>
                    <LabeFormlStyled>Tara</LabeFormlStyled>
                    <InputFake>{items?.tare_container ?? "0"}</InputFake>
                  </FormInputGroup>
                </SimpleGrid>
              ))}
            </div>
          )
          : (
            ""
          )}

        <SimpleGrid
          // bg={"rgba(50%, 50%, 50%, 0.1)"}
          borderRadius={2}
          minChildWidth="120px"
          spacing="20px"
          p={4}
        >
          {(authUser.admin === 1 || authUser.is_commerce === 1) && (
            <Box>
              <SwitchUpdateHot
                status={manipuleo}
                tag={"Manipuleo"}
                desicion={false}
                handleswitche={(e) => handleUpdateSwitch(e, setManipuleo)}
                tagName={"handling_process"}
              />
            </Box>
          )}

          {status !== "REVISADO" && freely_available === 1 && (
            <Box>
              <SwitchPropiosMedios
                status={freely_available}
                // setStatus={setStatus}
                tag={"Libre disposición"}
                desicion={true}
              />
            </Box>
          )}

          {status !== "REVISADO" && empty_pass === 1 && (
            <Box>
              <SwitchPropiosMedios
                status={empty_pass}
                // setStatus={setManipuleo}
                tag={"Vehículo vacío"}
                desicion={true}
              />
            </Box>
          )}

          {(authUser.admin === 1 || authUser.is_commerce === 1) &&
            status !== "BORRADOR" && (
              <>
                {confirmationDriver !== 0 && (
                  <SwitchUpdateHot
                    status={confirmationDriver}
                    tag={"Confirmar conductor"}
                    desicion={false}
                    handleswitche={(e) =>
                      handleUpdateSwitch(e, setConfirmationDriver)
                    }
                    tagName={"confirmation_driver"}
                  />
                )}

                {confirmationCompany !== 0 && (
                  <SwitchUpdateHot
                    status={confirmationCompany}
                    tag={"Confirmar empresa"}
                    desicion={false}
                    handleswitche={(e) =>
                      handleUpdateSwitch(e, setconfirmationCompany)
                    }
                    tagName={"confirmation_company"}
                  />
                )}
              </>
            )}
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <FormInputGroup>
            <LabeFormlStyled>Numero de Bultos</LabeFormlStyled>
            <InputFake>{number_packages ?? "N/A"}</InputFake>
          </FormInputGroup>

          <FormInputGroup>
            <LabeFormlStyled>Observaciones</LabeFormlStyled>
            <InputFake>{observations ?? "N/A"}</InputFake>
          </FormInputGroup>
        </SimpleGrid>
        {typeof tickets === "object" && tickets?.length > 0 && (
          <SimpleGrid
            bg={"rgba(50%, 50%, 50%, 0.2)"}
            borderRadius={2}
            minChildWidth="120px"
            spacing="20px"
            p={4}
          >
            <Box>
              <Text as={"h1"}>Tiquete de Báscula:</Text>

              <NavLink
                to={`/ShowWeighing?weighing=${tickets[0]?.id}&Operation=${typeOperation}`}
              >
                <Text className="formNumber">
                  {tickets[0]?.consecutive ?? "N/A"}
                </Text>
              </NavLink>
            </Box>
            <Box>
              <Text>Peso Neto</Text>
              <Text>{tickets[0]?.net_weight ?? "N/A"}</Text>
            </Box>{" "}
            <Box>
              <Text>Peso Bruto: </Text>
              <Text>{tickets[0]?.gross_weight ?? "N/A"}</Text>
            </Box>
            <Box>
              <Text>Peso Tara: </Text>
              <Text>{tickets[0]?.tare_weight ?? "N/A"}</Text>
            </Box>
          </SimpleGrid>
        )}

        {typeof tickets === "object" && tickets?.length > 1 && (
          <AccordionMain>
            <AccordionItemSimple title={"Ver más"}>
              {tickets
                ?.slice(1)
                .map(
                  ({ consecutive, tare_weight, net_weight, gross_weight }) => {
                    return (
                      <SimpleGrid
                        bg={"rgba(50%, 50%, 50%, 0.2)"}
                        borderRadius={2}
                        minChildWidth="120px"
                        spacing="20px"
                        p={4}
                      >
                        <Box>
                          <Text as={"h1"}>Tiquete de Báscula:</Text>
                          <Text>{consecutive ?? "N/A"}</Text>
                        </Box>
                        <Box>
                          <Text>Peso Neto</Text>
                          <Text>{net_weight ?? "N/A"}</Text>
                        </Box>{" "}
                        <Box>
                          <Text>Peso Bruto: </Text>
                          <Text>{gross_weight ?? "N/A"}</Text>
                        </Box>
                        <Box>
                          <Text>Peso Tara: </Text>
                          <Text>{tare_weight ?? "N/A"}</Text>
                        </Box>
                      </SimpleGrid>
                    );
                  }
                )}
            </AccordionItemSimple>
          </AccordionMain>
        )}
      </div>
    </>
  );
};
