import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuItemPass from "components/Menu/MenuItemPass";
import React from "react";
import { trackingStatus } from "../Templates";
import { useSelector } from "react-redux";

export const TbodyItem = ({ data }) => {
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const isDataInspection = {
    1: (data) => {
      return (
        <Tooltip placement="top" hasArrow label="Requiere inspección">
          <Tr id="BluewarningInsp">
            <TableData data={data} />
          </Tr>
        </Tooltip>
      );
    },
    default: (data) => {
      return (
        <Tr>
          <TableData data={data} />
        </Tr>
      );
    }
  };

  const TableData = ({ data }) => {
    return (
      <React.Fragment>
        <Td className="text-center">{data?.item}</Td>
        <Td className="text-center">
          {data?.code_entryForm ? data?.code_entryForm : "N/A"}
        </Td>

        <Td className="text-center">
          {data?.code_operation ? data?.code_operation : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.num_commercial_invoice ? data?.num_commercial_invoice : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.status_entryForm ? data?.status_entryForm : "N/A"}
        </Td>
        {is_qualified_user === 0 && (
          <Td className="text-center">
            {trackingStatus[data?.tracking] || trackingStatus.default}
          </Td>
        )}
        <Td className="text-center">
          <MenuItemPass data={data} />
        </Td>
      </React.Fragment>
    );
  };

  return (
    <>
      {isDataInspection[data?.inspection]?.(data) ||
        isDataInspection.default(data)}
    </>
  );
};
