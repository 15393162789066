import { __Logout } from "request/__Logout";
import { AUTH_TYPES } from "redux/types/authTypes";
import { logoutAction } from "redux/actions/authentication";

export const handleLogout = async (
  token,
  dispatch,
  dispacthRedux,
  navigate,
  idFuncionaio,
  channel = new BroadcastChannel("logout-channel"),
  type = AUTH_TYPES.LOGOUT,
  dispatchAction = logoutAction

) => {
  const resLogout = await __Logout(token);

  localStorage.setItem("chanelWeighing", "");

  localStorage.removeItem("route");
  localStorage.removeItem("search");
  localStorage.clear()
  // Limpiar la caché del navegador
  window.caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      window.caches.delete(cacheName);
    });
  });

  channel.postMessage({ type });
  dispacthRedux(dispatchAction());
  window.Echo.leave(`notification-${idFuncionaio}`); // desasociar al canal

  /* navigate("/auth", {
    replace: true,
  }); */
};
