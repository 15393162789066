import React, { useContext } from 'react'
import { Tooltip } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { MdFactCheck } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex'
import { AiOutlineFileSync } from 'react-icons/ai'
import environment from 'constants/apiConst'
import { AuthContext } from 'context/AuthContext'
import { FaUnlock } from 'react-icons/fa'
import { useFormBlocker } from 'hooks/useFormBlocker'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'

export const ActionsReview = ({ handleChangeStatusCorrected }) => {
  const {
    changeStatusCorrected,
    onOpenReturnReason,
    setReturnReason,
    setRequestChangeStatusBoxCorrected
  } = useContext(IntegracionBoxCrudContex)
  const { authUser } = useContext(AuthContext);
  const [openAlert] = AlertErrorAlert()
  const location = useLocation()
  const { idCorrected = null } = queryString.parse(location.search)
  const navigate = useNavigate()

  const ChangeStatus = status => {
    const formdata = new FormData()
    formdata.append('id', idCorrected)
    formdata.append('status', status)
    changeStatusCorrected(formdata)
  }

  const returnReason = () => {
    setRequestChangeStatusBoxCorrected(
      environment.CHANGE_STATUS_INTEGRATION_CORRECTED
    )

    setReturnReason({
      id: idCorrected,
      status: 'DEVUELTO'
    })
    onOpenReturnReason()
  }

  const { blockForm, formLockQueryResult, isBlocked } = useFormBlocker({
    form_id: idCorrected,
    type_of: "CIC",
    userProvidedEndpoint: environment.FORMS_ON_REVIEW
  });

  return (
    <>

      {authUser.is_qualified_user !== 1 && <Tooltip placement="top" hasArrow label="Desbloquear">
        <span>
          <ButtonHeaderModule
            onClick={() => {
              blockForm(3)
              openAlert("Se ha desbloqueado con exito", "success")
              navigate(-1)
            }}
            className="m-2"
            bgColor="#fe8d59"
          >
            <IconGeneric
              className="m-1 text-white"
              as={FaUnlock}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>}

      <Tooltip placement='top' hasArrow label='Radicar'>
        <span>
          <ButtonHeaderModule
            onClick={() => {
              blockForm(0)
              ChangeStatus('AUTORIZADO')
            }}
            className='m-2'
          >
            <IconGeneric className='m-1 text-white' as={MdFactCheck} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      <Tooltip placement='top' hasArrow label='Devolver'>
        <span>
          <ButtonHeaderModule
            onClick={returnReason}
            className='m-2'
            bgColor='#d92525'
          >
            <IconGeneric className='m-1 text-white' as={AiOutlineFileSync} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  )
}
