import { Td, Tr } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

export const TbodyAuxTemporal = ({ data }) => {
  function generateDynamicUrl (template, data) {
    const urlTemplates = {
      DESADUANAMIENTO: `/ShowCustomsClearance?Customs=${data?.form_id}`,
      "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
      "CUADR.INT.": `/ShowIntegrationBox?id=${data?.form_id}`,
      INGRESO: `/FormShow?FormEntry=${data?.form_id}`,
      SALIDA: `/FormShowExit?ExitNumber=${data?.form_id}`,
      "INGRESO ANULADO": `/FormShow?FormEntry=${data?.form_id}`,
      "SALIDA ANULADA": `/FormShowExit?ExitNumber=${data?.form_id}`,
      "INGRESO CORREGIDO": `/FormCorrection?ShowEntry=${data?.form_id}`,
      "SALIDA CORREGIDA": `/FormCorrectionOutput?ShowOutput=${data?.form_id}`,
      "CERTIF.INT CORREGIDO": `/ShowIntegrationBox?id=${data?.form_id}`,
      "CUADR.INT. CORREGIDO": `/ShowIntegrationBox?id=${data?.form_id}`,
      "DESPACHO CORREGIDO": `/DispathcCorrectionShow?Dispatch=${data?.form_id}&Action=Show`,
      DESPACHO: `/pases?numberForm=${data?.form_code}&id=${data?.form_id}&status=${data?.status}&typeO=Dispatch`
    };

    const dynamicUrl = urlTemplates[template];

    if (dynamicUrl) {
      return dynamicUrl;
    } else {
      return "";
    }
  }

  // Ejemplo de uso:3465
  const templateReference = {
    form_id: data?.form_ref_id,
    form_code: data?.authorization_reference,
    qualified_user_name: data?.qualified_user_name,
    status: data?.status || ""
  };

  const templateOperation = {
    form_id: data?.form_id,
    form_code: data?.form_code,
    qualified_user_name: data?.qualified_user_name,
    status: data?.status || ""
  };

  const typeReference = data?.operation_type_reference;
  const typeOperation = data?.operation_type;

  const urlReference = generateDynamicUrl(typeReference, templateReference);
  const urlOperation = generateDynamicUrl(typeOperation, templateOperation);

  return (
    <Tr>
      <Td className="text-center">{data?.qualified_user_nit}</Td>
      <Td className="text-center">{data?.qualified_user_name}</Td>
      <Td className="text-center">{data?.inventory_created_at}</Td>
      <Td className="text-center">{data?.inventory_created_at}</Td>
      <Td className="text-center">{data?.operation_type_reference}</Td>
      {data?.authorization_reference
        ? (
          <Td className="text-center">
            <NavLink to={urlReference} className="ms-2">
              <span className="formNumber">{data?.authorization_reference}</span>
            </NavLink>
          </Td>
        )
        : (
          <Td className="text-center ">{data?.authorization_reference}</Td>
        )}
      <Td className="text-center">{data?.form_reference}</Td>
      <Td className="text-center">{data?.item_code}</Td>
      <Td className="text-center">{data?.operation_type}</Td>
      <Td className="text-center"> {data?.authorization}</Td>
      {data?.form_id
        ? (
          <Td className="text-center">
            <NavLink to={urlOperation} className="ms-2">
              <span className="formNumber">{data?.form_code}</span>
            </NavLink>
          </Td>
        )
        : (
          <Td className="text-center ">{data?.form_code}</Td>
        )}
      <Td className="text-center">{data?.item_code}</Td>
      <Td className="text-center"> {data?.business_unit_code}</Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.input_quantity}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.output_quantity}
      </Td>
      <Td isNumeric className="text-center">
        {" "}
        {data?.inventory_quantity_available}
      </Td>
      <Td className="text-center">{data?.input_quantity_product}</Td>
      <Td className="text-center">{data?.output_quantity_product}</Td>
      <Td className="text-center">{data?.inventory_quantity_available_product}</Td>
      <Td className="text-center">{data?.product_business_unit_code}</Td>
    </Tr>
  );
};
