/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Divider,
  Grid,
  GridItem,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { InputDeparture } from "components/InputSelect/InputSelect";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { useRegexLabelState } from "hooks/useRegexState";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __DepartmentsAll, __ShowCities } from "request/configuration/__Cities";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { Regex } from "utils/Regex";

const FormCities = () => {
  const [selDeparture, setSelDeparture] = useState([]);
  const [mendigo, setMendigo] = useState("");
  const [departament, setDepartament] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    name_city: "",
    code_city: "",
    abbreviation: ""
  });

  const { name_city, code_city, abbreviation } = values;
  const [openAlert] = AlertErrorAlert();

  useEffect(() => {
    if (dataToEdit) {
      const edit = async () => {
        try {
          const res = await __ShowCities(token, dataToEdit.id);
          setValues(res.data?.status?.data);
          setDepartament(res.data?.status?.data.name_department);
          setMendigo(res.data?.status?.data.department_id);
        } catch (error) {}
      };
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const City = async () => {
    try {
      const res = await __DepartmentsAll(token);
      setSelDeparture(res?.data?.status?.data);
    } catch (error) {}
  };
  useEffect(() => {
    City();
  }, []);

  const handleSubmit = () => {
    if (!mendigo) return openAlert("Seleccione un departamento", "error");
    if (name_city === "") { return openAlert("Ingrese el nombre de la ciudad", "error"); }
    if (code_city === "") return openAlert("Ingrese el codigo", "error");

    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        department_id: mendigo
      };
      return editData(data);
    } else {
      const data = {
        ...values,
        department_id: mendigo
      };
      return createData(data);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Ciudad " : "Crear Ciudad"}
      </Text>
      <Divider mb={3} />

      <form>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <Box gridColumn={["span 6"]}>
            <InputDeparture
              partidas={selDeparture === null ? "" : selDeparture}
              tag={"Departamento"}
              setDepartament={setDepartament}
              departament={departament}
              setValor={setMendigo}
              mendigo={mendigo}
            />
          </Box>
          <Box gridColumn={["span 6"]}>
            <LabelGroup
              name={"name_city"}
              tag={"Nombre"}
              value={name_city}
              onChange={(e) => handleRegex(e, "Letras", Regex?.letras)}
              desicion={false}
              maxLength={150}
            />
          </Box>
          <Box gridColumn={["span 6"]}>
            <LabeFormlStyled>Codigo de ciudad</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_city"
              value={code_city}
              onChange={(e) => handleRegex(e, "Números", Regex?.numeros)}
              maxLength={30}
            />
          </Box>
          <Box gridColumn={["span 6"]}>
            <LabelGroup
              name={"abbreviation"}
              tag={"Abreviación"}
              value={abbreviation}
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              desicion={false}
              maxLength={80}
            />
          </Box>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormCities;
