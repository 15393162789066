import {
  Box,
  Flex,
  Input,
  InputGroup,
  Spacer,
  TagLabel
} from "@chakra-ui/react";
import { SubTitle } from "chart.js";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useEffect, useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";

const dataRange_Type = [
  {
    label: "Día(s)",
    value: "dia",
    messaje: ""
  },
  {
    label: "Semana(s)",
    value: "semana",
    messaje: ""
  },
  {
    label: "Mes(es)",
    value: "mes",
    messaje: ""
  }
];

const dataType = [
  {
    label: "Sin pesaje",
    value: "Sin pesaje",
    messaje: ""
  },
  {
    label: "INCOMPLETO",
    value: "Incompleto",
    messaje: ""
  }
];

export const ConfigBascula = ({
  onCloseConfig,
  selectpesaje,
  setselectpesaje,
  onOpenList
}) => {
  const [values, handleInputChange, reset, setValues] = useForm({
    range_num: "",
    hour: ""
  });
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);

  const { range_num, hour } = values;
  const [selectRange, setselectRange] = useState([]);

  useEffect(() => {
    if (selectpesaje?.label?.length > 0) {
      onOpenList();
    }
  }, [selectpesaje]);

  const CreateDate = async () => {
    const Obj = {
      range_type: selectRange.value,
      range_num,
      hour,
      type_weighing: selectpesaje.value
    };

    try {
      const res = await __Post(environment.CREATE_CONFIG_PASES, token, Obj);
      if (res.data.status.code === 200) {
        openAlert(`Registro Exitoso`, "success");
        onCloseConfig();
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }

    console.log(Obj);
  };

  return (
    <>
      {selectpesaje && (
        <Title size={"16px"} weight={600} className="mb-3">
          {"Nueva configuración"}
        </Title>
      )}
      <InputGroup w="100%">
        <InputSelect
          size={100}
          tag={"Tipo de pesaje"}
          data={dataType}
          set={setselectpesaje}
          value={selectpesaje}
        />
      </InputGroup>
      {selectpesaje?.label?.length > 0
        ? (
          <Box
            gap={2}
            className="d-flex justify-content-center align-items-center"
          >
            <InputGroup w="50%">
              <InputSelect
                size={100}
                tag={"Tipo de rango"}
                data={dataRange_Type}
                set={setselectRange}
                value={selectRange}
              />
            </InputGroup>
            <InputGroup w="50%">
              <LabelGroup
                name={"range_num"}
                value={range_num}
                onChange={handleInputChange}
                tag={"Rango"}
                size={100}
                maxLength={50}
                tipo="number"
              />
            </InputGroup>

            <InputGroup w="50%" className="mt-4">
              <Input
                type={"time"}
                name="hour"
                value={hour}
                onChange={handleInputChange}
                placeholder="Sin datos"
              />
            </InputGroup>
          </Box>
        )
        : (
          <></>
        )}
      <Flex
        style={{
          marginTop: "45px"
        }}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseConfig}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          onClick={
            range_num?.length > 0 && selectRange?.label?.length > 0 && hour?.length > 0
              ? () => CreateDate()
              : () => openAlert(`Rellene todos los datos`, "error")
          }
          type="submit" /* onClick={handleSubmit} */
        >
          Guardar
        </ButtonStyled>
      </Flex>
    </>
  );
};
