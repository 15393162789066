import { AuthContext } from "context/AuthContext";
import React, { useContext, useState, useEffect } from "react";

import { TitlePDF } from "styled/Title";

export const Cabecera = ({ values }) => {
  const {
    gross_date_weight,
    passForm_number,
    consecutive,
    users,
    name_qualified,
    nit_qualified,
    completeness,
    vehicle_plate,
    operation,
    net_weight,
    shedule
  } = values;

  const [dateToday, setDateToday] = useState(null);
  const { authUser } = useContext(AuthContext);
  //   const [fechaAutorizado, setFechaAutorizado] = useState(null);
  //   const [fechaCreate, setFechaCreate] = useState(null);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
        " " +
        fechaHoy.getHours() +
        ":" +
        fechaHoy.getMinutes() +
        ":" +
        fechaHoy.getSeconds()
    );
  }, [values, users]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <p>Impreso: {dateToday}</p>
          <p>Usuario: {authUser.username}</p>
          <p>Pagina 1 </p>
        </div>
        <div className="col-6">
          <div className="container">
            <TitlePDF size={"18px"} className="text-center">
              Registro de pesaje en báscula
              <br />
              Tiquete de báscula - Normal
            </TitlePDF>
          </div>
        </div>
        <div className="col-3">
          <p>Numero tiquete: {consecutive && consecutive}</p>
          <p>Fecha: {gross_date_weight && gross_date_weight} </p>
        </div>

        <hr className="mt-4" />
        <div className="col-12">
          <p>Usuario: {name_qualified}</p>
        </div>
        <div className="col-3">
          <p>NIT: {nit_qualified}</p>
          <p>Estado: </p>
          <p>Descripción de la mercancía: </p>
          <p>Número formulario: {passForm_number}</p>
        </div>
        <div className="col-6">
          <div className="container ms-5">
            <p>Placa: {vehicle_plate}</p>
            <p>Horario: {shedule}</p>
            <p>Completitud: {completeness}</p>
          </div>
        </div>
        <div className="col-3">
          <p>Tipo de operación: {operation}</p>
          <p>Peso neto: {net_weight} Kg</p>
        </div>
      </div>
    </>
  );
};
