import {
  Box,
  Button,
  Icon,
  Input,
  List,
  ListIcon,
  ListItem,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import PassCrudContext from "context/PassCrudContext";
import React, { useContext } from "react";

import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import {
  GrDocumentWord,
  GrDocumentPdf,
  GrDocumentWindows,
  GrDocumentText,
} from "react-icons/gr";
import { useLocation } from "react-router-dom";

function AddDocumentsGeneric({
  title,
  set,
  get,
  titulo = "Adjunto",
  id = "Generic",
  setFlagFiles = null,
  deleted = null,
  viewBtnDelete = true,
  ...props
}) {
  const [openAlert] = AlertErrorAlert();

  const attachedUpload = [];
  const { deleteAttachmentPass } = useContext(PassCrudContext);

  const Upload = () => {
    document.getElementById(id).click();
  };

  const location = useLocation();
  const { pathname } = location;

  const removeItemFromArr = (arr, item, index, archivo, id) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${item}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (arr[index].name) {
          const resultado = arr.filter((e) => e.name !== item);
          set(resultado);
        } else {
          const resultado = arr.filter((e) => e.name_attachment !== item);
          set(resultado);
          const formData = new FormData();
          formData.append("shipping_form", archivo);
          deleteAttachmentPass(formData, id);
        }
      }
    });
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 3000000;
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAXIMO_TAMANIO_BYTES) {
        openAlert(
          `El archivo ${files[i].name} excede el límite permitido de Megabytes.(3 MB)`,
          "error"
        );
      } else {
        const file = files[i];
        attachedUpload.push(file);
        if (get === null) {
          set(attachedUpload);
        } else {
          set(attachedUpload.concat(get));
        }
        setFlagFiles ? setFlagFiles(true) : console.log("Upload");
      }
    }
  };

  const downloadAttachment = (url) => {
    window.location.assign(url);
  };

  return (
    <Box {...props}>
      <Text className="mb-2" fontSize={"18px"} fontWeight={600}>
        {titulo}
      </Text>

      <Input
        onChange={handleAttachment}
        type="file"
        id={id}
        display={"none"}
        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel"
        multiple
      />

      <Button
        _hover={{
          bg: "#03C39A",
        }}
        bg={"#03C39A"}
        textColor={"white"}
        size="sm"
        w="auto"
        onClick={Upload}
      >
        {"Agregar Adjunto"}
      </Button>

      <List>
        {get?.map((items, index) => (
          <ListItem>
            <ListIcon
              as={
                items?.type
                  ? items?.type.includes("word")
                    ? GrDocumentWord
                    : items?.type.includes("pdf")
                    ? GrDocumentPdf
                    : items?.type.includes("excel")
                    ? GrDocumentWindows
                    : GrDocumentText
                  : items?.name_attachment.includes("word")
                  ? GrDocumentWord
                  : items?.name_attachment.includes("pdf")
                  ? GrDocumentPdf
                  : items?.name_attachment.includes("excel")
                  ? GrDocumentWindows
                  : items?.name_attachment.includes("xls")
                  ? GrDocumentWindows
                  : GrDocumentText
              }
              color="green.500"
            />
            {items?.name_attachment ? (
              <Tooltip placement="top" hasArrow label="Descargar">
                <span
                  type={Button}
                  onClick={() => downloadAttachment(items?.attachment_url)}
                >
                  {items?.name_attachment}{" "}
                </span>
              </Tooltip>
            ) : (
              <>{items?.name}</>
            )}
            {viewBtnDelete && (
              <Icon
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  deleted
                    ? deleted(
                        get,
                        items?.name || items?.name_attachment,
                        index,
                        items?.name_attachment,
                        items?.id
                      )
                    : removeItemFromArr(
                        get,
                        items?.name || items?.name_attachment,
                        index,
                        items?.name_attachment,
                        items?.id
                      );
                }}
                w={6}
                h={6}
                color="red.500"
                as={AiFillDelete}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default AddDocumentsGeneric;
