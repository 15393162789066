import { Image, Icon } from "@chakra-ui/react";
import {
  ContainerProfile,
  ContainerMenu,
  ContainerComponents,
  ContainerItem,
  ContentNameUser,
  ContentOptions,
  ContainerAllInfoUser,
  UserName,
  InfoUserContent,
  ProfileTitle,
  ProfiButton,
  LineSpacer
} from "./ProfileStyles";
import { MdLogout, MdAccountCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import Logout from "components/Logout/Logout";
import FormChangeUserData from "./Form/FormChangeUserData";
import FormChangePassword from "./Form/FormChangePassword";
import { useState } from "react";
// import { __Me } from "request/__Me";
// import { useEffect } from "react";

const Profile = () => {
  const [selectedView, setSelectedView] = useState("data_user");
  const [userName, setUserName] = useState("");
  const GlobalUserImage = useSelector((state) => state?.userInfo?.update);

  const { name_qualified } = useSelector((state) => state.auth.dataUser);
  const imageLoading =
    "https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif";

  return (
    <ContainerProfile>
      <ContainerMenu>
        <ContainerAllInfoUser>
          <InfoUserContent>
            <Image
              borderRadius="full"
              boxSize={GlobalUserImage ? "70px" : "40px"}
              src={GlobalUserImage || imageLoading}
            />
            <ContentNameUser>
              <ProfileTitle>
                {name_qualified || "Empresa"}
              </ProfileTitle>
              <UserName>{UserName ? userName : "Nombre usuario"}</UserName>
            </ContentNameUser>
          </InfoUserContent>
          <LineSpacer />
          <ContentOptions>
            <ContainerItem>
              <Icon
                color={selectedView === "data_user" ? "#007FFF" : "#BABABA"}
                as={MdAccountCircle}
              />
              <ProfiButton
                onClick={() => setSelectedView("data_user")}
                style={
                  selectedView === "data_user"
                    ? { color: "#007FFF" }
                    : { color: "#BABABA" }
                }
              >
                Datos Personales
              </ProfiButton>
            </ContainerItem>
            <ContainerItem>
              <Icon
                color={
                  selectedView === "change_password" ? "#007FFF" : "#BABABA"
                }
                as={MdLogout}
              />
              <ProfiButton
                onClick={() => setSelectedView("change_password")}
                style={
                  selectedView === "change_password"
                    ? { color: "#007FFF" }
                    : { color: "#BABABA" }
                }
              >
                Restablecer contraseña
              </ProfiButton>
            </ContainerItem>
          </ContentOptions>
        </ContainerAllInfoUser>
        <Logout />
      </ContainerMenu>
      <ContainerComponents>
        {selectedView === "data_user"
          ? (
            <FormChangeUserData
              setUserName={setUserName}
              userName={userName}
              pic={GlobalUserImage}
            />
          )
          : selectedView === "change_password"
            ? (
              <FormChangePassword/>
            )
            : (
              "Ha ocurrido un error"
            )}
      </ContainerComponents>
    </ContainerProfile>
  );
};

export default Profile;
