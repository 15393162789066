import { Icon, Td, Tr } from "@chakra-ui/react";
import MenuCustomsClearence from "components/Menu/MenuCustomsClearence";
import GlobalContext from "context/GlobalContext";
import { useContext } from "react";
import { FaRegEdit } from "react-icons/fa";

const TBodyAuthFormatSRT = ({ data }) => {
  const { idItemSRT, setiIdItemSRT, isOpen, setIsOpen, numItemSRT, setNumdItemSRT } = useContext(GlobalContext);

  const handleClick = (e) => {
    e.preventDefault();
    setiIdItemSRT(data?.id);
    setNumdItemSRT(data?.item);
    setIsOpen(true);
  }

  return (
    <Tr>
      <Td className="text-center">{data?.item ? data?.item : "N/A"}</Td>
      <Td className="text-center">{data?.description_product ? data?.description_product : "N/A"}</Td>
      <Td className="text-center">{data?.quantity ? data?.quantity : "N/A"}</Td>
      <Td className="text-center">{data?.unit_measurement ? data?.unit_measurement : "N/A"}</Td>
      <Td className="text-center">{data?.srt_serial ? data?.srt_serial : "N/A"}</Td>
      <Td className="text-center">{data?.srt_color ? data?.srt_color : "N/A"}</Td>
      <Td className="text-center">{data?.status_srt ? data?.status_srt : "N/A"}</Td>
      <Td className="text-center">{data?.srt_special_description ? data?.srt_special_description : "N/A"}</Td>
      <Td className="text-center" onClick={handleClick}><button><Icon as={FaRegEdit} boxSize={6} color={'#212529'} pr={1} /></button></Td>
    </Tr>
  );
};

export default TBodyAuthFormatSRT;
