import { BaseOfExport } from "components/Export/BaseOfExport";
import { TitleOperations } from "pages/Entry_and_exit_forms/TitleOperation/TitleOperations";
import ViewItemsPdf from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsPdf";
import React from "react";
import { Logo } from "styled/Logo.styled";
import "../../CorrectionForm/Style.css";
import { Cabecera } from "./cabeceraEntryCorrect/Cabecera";
import { HeaderOperation } from "./cabeceraEntryCorrect/HeaderOperation";

export const ExportPdfEntryCorrect = ({ values, items }) => {
  const { code_operationCorrected } = values;

  return (
    <div id="IdPdfFmmCorrectEntry">
      <BaseOfExport>
        <div
          style={{
            padding: "5px",
            marginLeft: "10px"
          }}
        >
          <Cabecera values={values} />
        </div>

        <div className="row">
          <div className="col-12">
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #bababa",
                borderRadius: "5px",
                padding: "5px",
                marginLeft: "10px",
                marginRight: "10px"
              }}
            >
              <TitleOperations code={code_operationCorrected} />
              <div className="p-1">
                <HeaderOperation values={values} />
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: "5px",
              marginLeft: "20px"
            }}
          >
            <p>
              <strong>Tipo de operación: </strong>
              {values?.type_operation_corrected}
            </p>
          </div>

          <div className="col-12">
            {items === undefined
              ? ""
              : items.map((info, index) => (
                <>
                  {index !== 0 && (
                    <>
                      <div className="d-flex mt-5">
                        <div id="banner" />
                        <Logo width={"180px"} className="mx-2" />
                      </div>
                      <div
                        style={{
                          padding: "5px",
                          marginLeft: "10px"
                        }}
                      >
                        <Cabecera values={values} />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      border: "1px solid #bababa",
                      marginTop: "20px",
                      borderRadius: "5px",
                      padding: "5px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                  >
                    <div className="row" key={index}>
                      <h1 className="text-word mb-1 mt-1">
                          Item de Corrección Nº {info?.item_corrected} del item{" "}
                        {info.item || 0}
                      </h1>
                      <ViewItemsPdf
                        data={info}
                        numero={index}
                        alldata={items}
                      />
                    </div>
                  </div>
                  {items && index !== items.length - 1 && (
                    <div className="html2pdf__page-break" />
                  )}
                </>
              ))}
          </div>
        </div>
      </BaseOfExport>
    </div>
  );
};
