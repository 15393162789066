import { H2 } from 'pages/Pass-Form/stylePases'
import React, { useEffect } from 'react'
import { Title } from 'styled/Title'

const LogState = ({ data, propetyStatus }) => {
  const handleUser = item => {
    const {
      date_passCorrected,
      time_passCorrected,
      nickname_corrected,
      person_name
    } = item

    if (
      date_passCorrected &&
      time_passCorrected &&
      (person_name || nickname_corrected)
    ) {
      return `${date_passCorrected} ${time_passCorrected} ${
        person_name || nickname_corrected
      }`
    }

    return ''
  }

  const statusMapping = {
    BORRADOR: 'Creado:',
    PRESENTADO: 'Presentado:',
    AUTORIZADO: 'Autorizado:',
    DEVUELTO: 'Devuelto:'
  }

  const renderUserStatus = propertyStatus => {
    const statusText = statusMapping[propertyStatus] || ''
    return statusText
  }

  const renderUser = (user, index) => (
    <div className='col col-sm-6 sol-md-3' key={index}>
      <H2 className='m-1 d-flex'>
        <Title size='18px'>{renderUserStatus(user?.[propetyStatus])}</Title>
        <span className='ms-2 text-muted'>{handleUser(user)}</span>
      </H2>
    </div>
  )

  return (
    <>
      <div
        className='row mt-4 ms-2'
        style={{
          background: '#f2f2f2',
          padding: '20px'
        }}
      >
        {data && data.map((user, index) => renderUser(user, index))}
      </div>
    </>
  )
}

export default LogState
