/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from "react";
import { MdKeyboardArrowDown, MdRemoveRedEye } from "react-icons/md";
import {
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Button,
  Img,
  Icon
  , useDisclosure
} from "@chakra-ui/react";
import bro from "../../../../src/assets/bro.png";
import { IconGeneric } from "styled/Icons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";

import {
  TheadShowItems,
  TheadShowUC,
  TheadSubheading,
  TheadTrackingItems,
  TheadTrackingOperations
} from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TbodyOperationsMonitoring from "pages/Seguimiento/Table/TBodyOperationsMonitoring";
import TableOrder from "components/Tables/TableOrder";
import MonitoringContext from "context/MonitoringContext";
import { ThStyledBlue } from "components/Tables/Table.styled";
import TBodyTableItems from "./TBodyTableItems";
import TBodyShowItems from "./TBodyShowItems";
import TBodyUC from "./TBodyUC";
import TBodySubheadingTrackings from "./TBodySubheadingTrackings";
import { useModal } from "hooks/useModal";

import { NavLink } from "react-router-dom";

const bold = { fontWeight: "bold" };

const TableModalMonitoring = ({
  thead = [],
  tbodyData: TbodyData,
  data = [],
  setData,
  loading = false,
  isNum = true,
  imageNone,
  actionsNone,
  subTotals,
  titleTotal,
  td,
  tUnid,
  tbodyUnd: TbodyUnd,
  className,
  arreglo,
  otherClass,
  getMonitoring,
  setItems,
  deleteItems,
  allItems
}) => {
  const [update, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [load, setIsLoad] = useState([]);
  const [press, setPress] = useState(null);
  const [getTrackingId, setGetTrackingId] = useState({});
  const [formType, setFormType] = useState("");
  const [state, setState] = useState(false);
  const [attachmentShow, setAttachmentShow] = useState("");
  const [loadOperations, setLoadOperations] = useState(false);
  const [isOpenAttachment, onOpenAttachment, onCloseAttachment] = useModal();
  const { isOpenModalInfo, onOpenModalInfo, onCloseModalInfo } =
    useContext(MonitoringContext);

  const orderFirst = () => {
    const firstOrder = data.map((item) => item);
    setInitialData(firstOrder);
  };

  useEffect(() => {
    orderFirst();
  }, [data]);

  const orderData = (order) => {
    if (order !== press && order) {
      const newData = data.sort((a, b) =>
        a[order] > b[order] ? 1 : a[order] < b[order] ? -1 : 0
      );
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: newData }));
      } else {
        setData(newData);
      }
      setUpdate(!update);
      setPress(order);
    } else if (order) {
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: initialData }));
      } else {
        setData(initialData);
      }
      setUpdate(!update);
      setPress(null);
    }
  };

  useEffect(() => {
    onCloseModalInfo();
  }, [loadOperations]);

  return (
    <div className="table-responsive">
      <ModalGeneric
        isOpen={isOpenModalInfo}
        onOpen={onOpenModalInfo}
        onClose={onCloseModalInfo}
        title={
          formType === 1 || formType === 2 || formType === 3
            ? "Items en seguimiento"
            : formType === 4
              ? "Usuarios calificados"
              : formType === 6
                ? "Subpartidas"
                : "Operaciones en seguimiento"
        }
      >
        <TableOrder
          thead={
            formType === 1 || formType === 2 || formType === 3
              ? TheadShowItems
              : formType === 4
                ? TheadShowUC
                : formType === 6
                  ? TheadSubheading
                  : TheadTrackingOperations
          }
          data={getTrackingId}
          loading={load}
          tbodyData={
            formType === 1 || formType === 2 || formType === 3
              ? TBodyShowItems
              : formType === 4
                ? TBodyUC
                : formType === 6
                  ? TBodySubheadingTrackings
                  : TbodyOperationsMonitoring
          }
          getMonitoring={getMonitoring}
          formType={formType}
          setLoadOperations={setLoadOperations}
        />
        {attachmentShow
          ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                colorScheme="blue"
                onClick={onOpenAttachment}
                style={{ marginRight: "20px" }}
              >
              Abrir adjunto
              </Button>
              <Button onClick={onCloseModalInfo}>Cerrar</Button>
            </div>
          )
          : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button onClick={onCloseModalInfo}>Cerrar</Button>
            </div>
          )}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAttachment}
        onOpen={onOpenAttachment}
        onClose={onCloseAttachment}
        title="Ver adjunto"
      >
        <div
          style={{
            display: "flex",
            padding: "10px 0",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          {attachmentShow?.includes(".pdf")
            ? (
              <embed
                src={attachmentShow}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )
            : (
              <>
                <Img src={attachmentShow} style={{ width: "80%" }} />
              </>
            )}
        </div>
        {/* <button  onClick={() => window.open(attachmentShow, '_blank')}>prueba</button> */}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={onCloseAttachment}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <Table
        size="md"
        variant="simple"
        className="table table-striped table-sm "
        overflowX={true}
      >
        <Thead>
          <Tr>
            {thead.map((th, index) =>
              index > 1
                ? (
                  <ThStyledBlue
                    key={th?.name + "_" + index}
                    isNumeric={isNum}
                    onClick={(e) => arreglo && orderData(th.order, th.isNumber)}
                    cursor={th.order ? true : null}
                    id="alinear"
                    className="text-center"
                  >
                    <div className="d-flex justify-content-center align-items-center mr-4">
                      {th?.name}
                      <span>
                        {th.order && arreglo
                          ? (
                            <IconGeneric as={MdKeyboardArrowDown} />
                          )
                          : (
                            ""
                          )}
                      </span>
                    </div>
                  </ThStyledBlue>
                )
                : (
                  <ThStyledBlue
                    key={`${th?.name}_${index}`}
                    onClick={(e) => arreglo && orderData(th.order, th.isNumber)}
                    cursor={th.order ? true : null}
                    className="text-center"
                  >
                    <div className="d-flex justify-content-center align-items-center mr-4">
                      {th?.name}
                      <span>
                        {th.order && arreglo
                          ? (
                            <IconGeneric as={MdKeyboardArrowDown} />
                          )
                          : (
                            ""
                          )}
                      </span>
                    </div>
                  </ThStyledBlue>
                )
            )}
          </Tr>
        </Thead>
        <Tbody className={className || ""}>
          {update
            ? (
              data.length <= 0 && !loading
                ? (
                  <Tr>
                    {!imageNone && (
                      <Td colSpan={thead.length + 1}>
                        <div className="d-flex justify-content-center ml-6">
                          <img
                            style={{ width: `${20}%` }}
                            src={window.assetURL + bro}
                            alt="no hay formulario"
                          />
                        </div>
                        <h2 className="text-center my-2">Aún no hay registros.</h2>
                      </Td>
                    )}
                  </Tr>
                )
                : (
                  data.map((item, index) => (
                    <TbodyData
                      key={`${index}--${index}`}
                      data={item}
                      setItems={setItems}
                      setState={setState}
                      deleteItems={deleteItems}
                      getMonitoring={getMonitoring}
                      allItems={allItems}
                      setAttachmentShow={setAttachmentShow}
                    />
                  ))
                )
            )
            : data.length <= 0 && !loading
              ? (
                <Tr>
                  {!imageNone && (
                    <Td colSpan={thead.length + 1}>
                      <div className="d-flex justify-content-center">
                        <img
                          style={{ width: `${20}%` }}
                          src={window.assetURL + bro}
                          alt="no hay formulario"
                        />
                      </div>
                      <h2 className="text-center my-2">Aún no hay registros.</h2>
                    </Td>
                  )}
                </Tr>
              )
              : (
                data.map((item, index) => (
                  <TbodyData
                    key={`${index}--${index}`}
                    data={item}
                    setGetTrackingId={setGetTrackingId}
                    setFormType={setFormType}
                    getMonitoring={getMonitoring}
                    setState={setState}
                    iope={index}
                    actionsNone={actionsNone}
                    setItems={setItems}
                    allItems={allItems}
                    deleteItems={deleteItems}
                    setAttachmentShow={setAttachmentShow}
                  />
                ))
              )}
          <>
            {subTotals && (
              <Tr>
                <Td className="text-center" style={bold}>
                  {titleTotal}
                </Td>
                {td && td.map((i) => <Td key={i} />)}

                {subTotals.map((item, index) => (
                  <Td
                    className={
                      otherClass
                        ? "text-center text-size-export"
                        : "text-center"
                    }
                    key={index}
                    isNumeric
                    style={bold}
                  >
                    {item}
                  </Td>
                ))}
              </Tr>
            )}

            {tUnid &&
              tUnid.map((item, index) => <TbodyUnd key={index} data={item} />)}
          </>
        </Tbody>
      </Table>
    </div>
  );
};

export default TableModalMonitoring;
