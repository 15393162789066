import { Flex, Spacer } from '@chakra-ui/react'
import InputSelectMultiple from 'components/InputSelect/InputSelectMultiple'
import environment from 'constants/apiConst'
import { useForm } from 'hooks/useForm'
import { useFormBlocker } from 'hooks/useFormBlocker'
import { LabelGroupTextArea } from 'pages/RefProductInter/LabelGroup'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { __GetDataReturnReason } from 'request/Pases/__CrudPass'
import { ButtonStyled } from 'styled/Buttons.styled'

export const ReturnReasonForm = ({ onClose, contextCurrent, propsBlockForm }) => {
  const token = useSelector(state => state.auth.dataUser.token)
  const [typification, setTypification] = useState(null)
  const [datatypification, setDataTypification] = useState(null)
  const {
    ReturnReason,
    setRequestChangeStatusBoxCorrected,
    changeStatusCorrected
  } = useContext(contextCurrent)

  const [values, handleInputChange] = useForm({
    reason_for_return: ''
  })

  const { reason_for_return } = values

  const fetchDataReturnReasons = useCallback(async () => {
    try {
      const res = await __GetDataReturnReason(token)
      const data = res?.data?.status
      const infoUsuarios = data?.data?.map(item => ({
        value: item?.id,
        label: item?.name
      }))
      setDataTypification(infoUsuarios)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  useEffect(() => {
    fetchDataReturnReasons()
  }, [])

  const { blockForm } = useFormBlocker({
    form_id: propsBlockForm?.form_id,
    type_of: propsBlockForm?.type_of,
    userProvidedEndpoint: propsBlockForm?.endpoint
  });

  const generateReturn = () => {
    blockForm(propsBlockForm?.type);

    setRequestChangeStatusBoxCorrected(
      environment.CHANGE_STATUS_INTEGRATION_CORRECTED
    )

    const typificationtid = []
    typification.forEach(({ value }) => typificationtid.push(value))
    const { id = '', status = '' } = ReturnReason
    const { reason_for_return = '' } = values

    const formdata = new FormData()
    formdata.append('id', id)
    formdata.append('reason_for_return', reason_for_return)
    formdata.append('status', status)
    formdata.append('typification_id[]', typificationtid)

    changeStatusCorrected(formdata)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2rem'
        }}
        className='mb-2 me-2'
      >
        <div style={{ width: '100%' }}>
          <InputSelectMultiple
            tag={'Motivo'}
            name={'reason_for_return'}
            data={datatypification}
            set={setTypification}
            value={typification}
          />
        </div>
      </div>
      {typification && typification[0] && typification[0]?.value && (
        <LabelGroupTextArea
          name={'reason_for_return'}
          value={reason_for_return ?? ''}
          tag={'Descripción'}
          onChange={handleInputChange}
          width={100}
        />
      )}
      <Flex className='my-3'>
        <ButtonStyled bgColor={'#bababa'} onClick={onClose}>
          Cancelar
        </ButtonStyled>
        <Spacer />
        {reason_for_return !== '' && (
          <ButtonStyled bgColor={'#03C39A'} onClick={generateReturn}>
            Guardar
          </ButtonStyled>
        )}
      </Flex>
    </>
  )
}
