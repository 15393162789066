/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { __ShowDetiny } from "request/configuration/__Destiny";
import { ButtonStyled } from "styled/Buttons.styled";
import { Regex } from "utils/Regex";

const FormDestiny = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    code: "",
    destiny: ""
  });

  const { code, destiny } = values;
  const [openAlert] = AlertErrorAlert();

  const edit = async () => {
    try {
      const res = await __ShowDetiny(token, dataToEdit.id);
      if (res?.data?.status?.code === 200) {
        return setValues(res.data?.status?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      edit();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (code === "") {
      return openAlert("Ingrese el código del destino", "error");
    }
    if (destiny === "") {
      return openAlert("Ingrese el nombre del destino", "error");
    }
    if (dataToEdit) {
      return editData(values);
    }
    createData(values);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Destino " : "Crear Destino"}
      </Text>
      <Divider mb={3} />

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            type={"text"}
            name={"code"}
            tag={"Código"}
            size={45}
            value={code}
            onChange={(e) => handleRegex(e, "all", Regex?.letras)}
            desicion={false}
            maxLength={2}
          />
          <LabelGroup
            type={"text"}
            name={"destiny"}
            tag={"Destino"}
            size={45}
            value={destiny}
            onChange={handleInputChange}
            desicion={false}
            maxLength={70}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormDestiny;
