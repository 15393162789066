import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __Active_Required_Document,
  __Desactive_Required_Document
} from "request/configuration/__DocumentRequire";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyDocumentRequire = ({ data }) => {
  const { setUpdate, update } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const handleStatus = async () => {
    console.log(data);
    if (data.status_document === 0) {
      // funcion de activar
      try {
        const resultado = await __Active_Required_Document(token, data.id);
        setUpdate(!update);
        console.log(resultado);
      } catch (error) {
        console.log(error);
      }
    } else {
      // funcion de desactivar
      try {
        const resultado = await __Desactive_Required_Document(token, data.id);
        if (resultado.data.status.code === 400) {
          openAlert(
            "No se puede desactivar el documento, esta siendo utilizado en una operación.",
            "error"
          );
        } else {
          setUpdate(!update);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.name_document}</Td>
      <Td className="text-center">{data?.description_document}</Td>
      <Tooltip
        hasArrow
        placement="top"
        label={data.status_document === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={() => handleStatus()}
          className="text-center"
          text={data?.status_document}
        >
          <IconGeneric
            color={data.status_document === 1 ? "#03C39A" : "#bababa"}
            as={BsCheckCircleFill}
          />
        </TdState>
      </Tooltip>

      <Td className="text-center">
        <MenuRUD data={data} document={true} remove={true} />
      </Td>
    </Tr>
  );
};

export default TbodyDocumentRequire;
