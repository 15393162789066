import React from 'react'
import { MinCardStadistica } from 'styled/CardTotalesDash'
import './Style.css'
import { Box } from '@chakra-ui/react'

export const CardMethod = ({ title, children, ...props }) => {
  return (
    <MinCardStadistica>
      <div className='d-flex'>
        <span id='tituloEstadistica'>{title}</span>
      </div>
      <Box p={3} overflow={'auto'} {...props}>
        {children}
      </Box>
    </MinCardStadistica>
  )
}
