import { Button } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const CardButton = styled(Button)`
  color: #fff;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ bgColor }) => bgColor || '#1f7ac3'} !important;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  padding: 0.9em 0;
`;
export const ButtonUc = styled(Button)`
  padding: 0.9em 0;
  color: #fff;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid #fff;
  background: transparent !important;
  font-size: 16px;
`;

export const ButtonTracking = styled.button`
  border-radius: 19px;
  background-color: ${(props) => (props.bg ? props.bg : "gray")};
  color: #fff;
  padding: 2px 8px;
  font-size: 15px;
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
`;

export const ButtonStyled = styled(Button)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "#1F7AC3"} !important;
  color: ${(props) => (props.color ? props.color : "#fff")};
  width: ${(props) => (props.wd ? props.wd : "200px")} !important;
  height: ${(props) => (props.hg ? props.hg : "40px")} !important;
  margin-top: ${(props) => (props.mt ? props.mt : "1rem")} !important;
  margin-right: ${(props) => (props.mr ? props.mr : "5px")} !important;

  &:hover {
    color: #fff;
    background-color: ${(props) =>
    props.bgColor ? `${props.bgColor}90` : "#1F7AC380"} !important;
  }
`;

export const ButtonHeader = styled(Button)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "#03C39A"} !important;
  color: #fff;
  width: ${(props) => (props.wd ? props.wd : "190px")} !important;
  height: ${(props) => (props.hg ? props.hg : "40px")} !important;
  margin-top: 1rem;
  margin-right: 5px !important;
  /* font-size: 12px; */
  &:hover {
    color: #fff;
    background-color: ${(props) =>
    props.bgColor ? `${props.bgColor}90` : "#03C39A80"} !important;
  }
`;

export const ButtonContainer50 = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 450px) {
    width: 50%;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
  }
`;

export const ButtonsContainerDouble = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: ${(props) => props.position || "relative"};
  bottom: 1rem;
  width: 90%;
  margin: 0 auto;
`;

export const ButtonsContainerSingle = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc || "center"};
  align-items: center;
  position: ${(props) => props.position || "relative"};
  bottom: ${(props) => props.bottomQ || "auto"};
  flex-wrap: wrap;
  width: 50%;
  margin: 0 auto;

  @media screen and (min-width: 500px) {
    bottom: ${(props) => props.bottom || "auto"};
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
  }
`;

export const ButtonHeaderModule = styled(Button)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "#1F7AC3"} !important;
  color: #fff;
  /* width: 0px !important; */
  padding: 2%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    color: #fff;
    background-color: ${(props) =>
    props.bgColor ? `${props.bgColor}90` : "#1F7AC380"} !important;
  }
`;

export const ButtonBurger = styled(Button)`
  background: ${(props) =>
    props.bgColor ? props.bgColor : "#1F7AC3"} !important;
  color: #fff;
  border: 1px solid #bababa;
  border-radius: 50% !important;
  /* height: 24px !important; */
  width: 15px !important;
  padding: 0;

  &:hover {
    color: #fff;
    background: "#1F7AC380" !important;
    color: #fff;
  }
  &:focus {
    outline: none !important;
    box-shadow: 0px 0px 0px !important;
  }
`;

export const ButtonHeaderTransparent = styled(Button)`
  color: ${(props) => (props.color ? props.color : "#1F7AC3")} !important;
  padding: 2%;
  background-color: transparent !important;
  border: ${(props) =>
    props.bor ? props.bor : "1px solid #1F7AC3"} !important;
  &:hover {
    border: ${(props) =>
    props.bor ? props.bor : "1px solid #1F7AC380"} !important;
    color: ${(props) =>
    props.color ? `${props.color}90` : "#1F7AC380"} !important;
  }
`;

export const InputSearch = styled.input`
  border-radius: 4px;
  width: 252px;
  height: 38px;
  border: 1px solid #9097be;
  font-size: 1rem;
  display: inline-block;
  padding: 0 12px;
`;
