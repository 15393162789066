import React, { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";
import { MdContentCopy } from "react-icons/md";
import OrderProductionContext from "context/OrderProductionContext";
import { BiSelectMultiple } from "react-icons/bi";
import { AuthContext } from "context/AuthContext";
import { TiCancel } from "react-icons/ti";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

export const OptionsAutorizado = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const {
    changeStatus,
    onOpenCopy,
    setDataToCopy,
    onOpenCreateCIntegration,
    setDataCreateCI
  } = useContext(OrderProductionContext);

  // const handleChangeStatus = (status) => {
  //   const info = {
  //     id: data.production_order_id,
  //     status_ProductionOrderForm: status,
  //   };
  //   changeStatus(info);
  // };

  const handleCopy = () => {
    setDataToCopy(data);
    onOpenCopy();
  };
  const handleCreateCI = () => {
    setDataCreateCI(data);
    onOpenCreateCIntegration();
  };

  return (
    <>
      <MenuItemStyled onClick={handleCopy} py={3}>
        <IconMenuRUD color="#03C39A" as={MdContentCopy} />
        Copiar
      </MenuItemStyled>
      <HrDividerNoMargin />
      <MenuItemStyled onClick={handleCreateCI} py={3}>
        <IconMenuRUD color="#F9A621" as={BiSelectMultiple} />
        Crear certificados de integración
      </MenuItemStyled>

      {(authUser.admin === 1 || authUser.is_commerce === 1) && (
        <>
          <HrDividerNoMargin />
          <MenuItemStyled py={3}>
            <IconMenuRUD color="black" as={TiCancel} />
            Anular
          </MenuItemStyled>
        </>
      )}
    </>
  );
};
