/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormAduanaShow = () => {
  const initialForm = {
    code_custom: "",
    name_custom: "",
    email_custom: "",
    description_custom: "",
    status_custom: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToShow) {
      setForm(dataToShow);
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.name_custom : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Codigo</LabeFormlStyled>
                <InputFake>{form?.code_custom}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form?.name_custom}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Correo electrónico</LabeFormlStyled>
                <InputFake>{form?.email_custom}</InputFake>
              </FormInputGroup>
            </div>

            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Descripción</LabeFormlStyled>
                <InputFake>
                  {form?.description_custom === null
                    ? "---"
                    : form?.description_custom}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Estado</LabeFormlStyled>
                <InputFake>
                  {form?.status_custom === 1 ? "Activo" : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormAduanaShow;
