import {
  Box,
  Flex,
  Input,
  Radio,
  Spacer,
  Textarea,
  Tooltip
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useRef, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { __GetReferenceOutputForm } from "request/configuration/__ProductProcess";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { LabeFormlStyled } from "styled/Input.styled";
import { TbFileInvoice } from "react-icons/tb";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";

import { Cancelar } from "pages/RefProductInter/Stilos";
import { NumericFormat } from "react-number-format";
import { parseFloat } from "utils/parseFloat";
import { safelyConvertToString } from "utils/type-check-utils";
import { getFormData } from "redux/actions/dataFormActions";
export const FmmItemCorregido = ({
  data,
  suma,
  multiplicacion,
  handleInputChange,
  valuesItem,
  setValuesItem,
  sumaOriginal,
  multiplicacionOriginal,
  hadleSaveItems,
  handleCancel,
  idUsuarioCalificados,
  codeOriginalOpe,
  setquantityError,
  quantityError,
  flagBalance,
  handleBlurReference,
  handleMultiplicacion,
  valuesHeader
}) => {
  const [openAlert] = AlertErrorAlert();

  const message980 = `La cantidad ingresada no puede ser mayor a su cantidad inicial `;
  const message981 = `La cantidad ingresada no puede ser menor a su cantidad inicial `;

  const {
    dataCountryOrigin,
    setDataCountryOrigin,
    dataCountryBuy,
    setDataCountryBuy,
    info,
    setInfo,
    valueProducts,
    setValueProducts,
    packagings,
    setPackagings,
    valueFlag,
    setValueFlag,
    valueMethod,
    setValueMethod,
    dataTransportMethodNew,
    dataCountry,
    dataPackagingsNew,
    dataProducts,
    dataToEditItems,
    showItemsView,
    setDataProducts,
    setAllProducts,
    selectOperation
  } = useContext(FormCrudCRUDContext);
  // const [allProducts, setAllProducts] = useState([]);

  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const dataForm = useSelector(getFormData);

  const location = useLocation(); // navigate

  const { FormOutput = "" } = queryString.parse(location.search);
  const [isOpenInvoice, onOpenInvoice, onCloseInvoice] = useModal();
  const [valueInvoice, setValueInvoice] = useState(null);

  const {
    product_code,
    factor_conv_corrected,
    product_quantity_corrected,
    code_subheading_corrected,
    quantity_corrected,
    gross_weight_corrected,
    net_weight_corrected,
    number_packages_corrected,
    us_freight_corrected,
    code_unit_measurement_corrected,
    us_insurance_corrected,
    other_us_expenses,
    us_fob_value_corrected,
    exchange_rate_corrected,
    product_description_corrected,
    output_form_item_description,
    output_form_item_description_corrected,
    factor_conv,
    product_quantity,
    code_subheading,
    quantity,
    code_country,
    name_country,
    code_country_purs,
    name_country_purs,
    code_country_des,
    code_country_des_corrected,
    name_country_des,
    code_transportMethod_corrected,
    name_transportMethod_corrected,
    code_contry_flag_corrected,
    name_country_flag_corrected,
    code_packaging_corrected,
    name_packaging_corrected,
    gross_weight,
    us_freight,
    us_insurance,
    other_us_expenses_corrected,
    us_fob_value,
    exchange_rate,
    product_description,
    original_fob_value_us_corrected,
    original_fob_value_us,
    adjustment_justification_fob,
    added_value_us,
    refund_value_us,
    adjustment_justification_fob_corrected,
    added_value_us_corrected,
    refund_value_us_corrected,
    net_weight,
    number_packages,
    invoice,
    invoice_corrected,
    brand,
    brand_corrected,
    serial,
    serial_corrected,
    customs_clearance_corrected,
    value_sdo_corrected,
    value_sdo,
    operation_code_corrected,
    initial_operation_corrected,
    value_sdo_product_corrected,
    value_sdo_product,
    fob_sdo_value_corrected,
    cif_value_sdo_corrected,
    code_product_corrected,
    code_businessUnit,
    code_tradeAgreement_corrected,
    name_tradeAgreement_corrected,
    product_id,
    countryOrigin_id
  } = data;

  const paisOrigen = code_country + " " + name_country;
  const paisCompra = code_country_purs + " " + name_country_purs;
  const paisDestiny = code_country_des + " " + name_country_des;
  const modo =
    code_transportMethod_corrected + " " + name_transportMethod_corrected;
  const flagBandera =
    code_contry_flag_corrected + " " + name_country_flag_corrected;
  const embalaje = code_packaging_corrected + " " + name_packaging_corrected;

  const [fomateo] = useFormatQuantity();

  console.log({ info });

  const handleBlurCant = (e) => {
    const quantityWithError = [];
    const valorOriginal = e.target.id;

    const nameVariable = e.target.name;
    const posibleResult = e.target.value;
    const resultadoFormateado = fomateo(
      Number(decimal_values_precision),
      posibleResult
    );
    const valueOriginal = Number(valorOriginal?.toString().replaceAll(",", ""));

    if (
      posibleResult &&
      (selectOperation?.value === 980 ||
        selectOperation?.value === 980 ||
        selectOperation?.value === 982 ||
        selectOperation?.value === 984) &&
      Number(posibleResult) > Number(valueOriginal)
    ) {
      if (!quantityError?.includes(nameVariable)) { quantityWithError.push(nameVariable); }
      openAlert(message980, "error");
      setquantityError(quantityWithError.concat(quantityError));
      setValuesItem({
        ...valuesItem,
        [nameVariable]: resultadoFormateado
      });
    } else if (
      posibleResult &&
      (selectOperation?.value === 981 ||
        selectOperation?.value === 981 ||
        selectOperation?.value === 983 ||
        selectOperation?.value === 985) &&
      Number(posibleResult) < Number(valueOriginal)
    ) {
      if (!quantityError?.includes(nameVariable)) { quantityWithError.push(nameVariable); }
      openAlert(message981, "error");
      setquantityError(quantityWithError.concat(quantityError));
      setValuesItem({
        ...valuesItem,
        [nameVariable]: resultadoFormateado
      });
    } else if (posibleResult) {
      const clean = quantityError.filter((e) => e !== nameVariable);
      setquantityError(clean);
      setValuesItem({
        ...valuesItem,
        [nameVariable]: resultadoFormateado
      });
    }
    if (nameVariable === "product_quantity_corrected") {
      handleMultiplicacion();
    }
  };
  useEffect(() => {
    if (idUsuarioCalificados) {
      getProductUserQualified(idUsuarioCalificados);
    }
  }, [idUsuarioCalificados]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getProductUserQualified();
    }
  }, [selectOperation?.value]);
  const firstRender = useRef(true);

  const getProductUserQualified = async (id) => {
    try {
      const body = new FormData();
      body.append("qualifiedUser_id", idUsuarioCalificados);
      body.append("outputFormCorrected_id", FormOutput);
      body.append("operation_code", selectOperation?.value);
      const res = await __GetReferenceOutputForm(token, body);

      const infoProduct = res?.data?.status?.data.map((item) => ({
        ...item,
        value: item?.inventory_id,
        label: item?.inventory_reference
      }));
      // reference_description
      setDataProducts(infoProduct);
      console.log(infoProduct, "infoProduct");
      setAllProducts(res?.data?.status?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeRadio = (e) => {
    const valor = e.target.id;
    setValuesItem({ ...valuesItem, invoice_corrected: valor });
    onCloseInvoice();
  };

  const getInvoice = async (e) => {
    e.preventDefault();

    try {
      const res = await __GetDataId(
        environment.GET_COMERCIAL_INVOICE_OUTPUT_CORRECT,
        token,
        FormOutput
      );
      const destruct = res.data.status;
      if (destruct.code === 200) {
        onOpenInvoice();
        setValueInvoice(destruct.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue } = newValue;

    setValuesItem((previousValues) => ({
      ...previousValues,
      [sourceEvent?.event?.target?.name]: floatValue
    }));
  };

  const updateItemQuantity = (newValue, sourceEvent) => {
    const { floatValue } = newValue;
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.value]: parseFloat(floatValue)
    }
    handleInputChange(target);
  };

  const decimalValuesPrecision = parseFloat(dataForm?.precision) || decimal_values_precision || 4;

  console.log({ code_country_des, data_code_country_des: dataCountryOrigin?.code });
  return (
    <>
      <ModalGeneric
        isOpen={isOpenInvoice}
        onOpen={onOpenInvoice}
        onClose={onCloseInvoice}
        title="Seleccionar factura existente"
        size="sm"
      >
        {valueInvoice &&
          valueInvoice.map((e, index) => {
            if (e.invoice) {
              return (
                <div key={index}>
                  <Radio
                    id={e.invoice}
                    value={e.invoice}
                    onChange={handleChangeRadio}
                  >
                    {e.invoice}
                  </Radio>
                  <br />
                </div>
              );
            }
          })}
        <Flex mt={5}>
          <Spacer />
          <Cancelar onClick={onCloseInvoice}>Cancelar</Cancelar>
        </Flex>
      </ModalGeneric>

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"Referencia"}
        data={dataProducts}
        set={setValueProducts}
        value={valueProducts}
        onChange={handleBlurReference}
        className={
          /* dataToEditItems &&
          dataToCopyItems && */
          product_id !== valueProducts?.value &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"customs_clearance_corrected"}
        value={customs_clearance_corrected}
        tag={"Declaración de importación "}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"value_sdo_corrected"}
        value={value_sdo_corrected}
        changeValue={
          parseFloat(value_sdo_corrected) !== parseFloat(value_sdo) && "BorderCorrect"
        }
        tag={"Saldo producto"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"value_sdo_corrected"}
        value={value_sdo_corrected}
        tag={"Saldo"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"operation_code_corrected"}
        value={operation_code_corrected}
        tag={"Oper"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"initial_operation_corrected"}
        value={initial_operation_corrected}
        tag={"Inicial"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"product_code"}
        value={code_product_corrected}
        tag={"Código producto"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemQuantity}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"value_sdo_product_corrected"}
        value={value_sdo_product_corrected}
        changeValue={
          parseFloat(value_sdo_product_corrected) !== parseFloat(value_sdo_product) && "BorderCorrect"
        }
        tag={"Cantidad de producto"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"value_sdo_product_corrected"}
        value={value_sdo_product_corrected}
        tag={"Saldo producto"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"code_unit_measurement_corrected"}
        value={
          code_unit_measurement_corrected || ""
        }
        tag={"Unidad de producto"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"factor_conv_corrected"}
        value={factor_conv_corrected || ""}
        changeValue={parseFloat(factor_conv) !== parseFloat(factor_conv_corrected) && "BorderCorrect"}
        tag={"Factor de conversión"}
        size={100}
        desicion={true}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemQuantity}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"product_quantity_corrected"}
        value={product_quantity_corrected}
        changeValue={
          parseFloat(product_quantity) !== parseFloat(product_quantity_corrected) && "BorderCorrect"
        }
        tag={"Cantidad de producto"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={product_quantity}
      >
        <span className="text-danger">
          {quantityError?.includes("product_quantity_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("product_quantity_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <LabelGroup
        name={"code_subheading_corrected"}
        changeValue={
          safelyConvertToString(code_subheading) !== safelyConvertToString(code_subheading_corrected) && "BorderCorrect"
        }
        value={code_subheading_corrected || ""}
        tag={"Subpartida arancelaria"}
        size={100}
        desicion={true}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"País origen"}
        data={dataCountry}
        set={setInfo}
        value={info}
        className={safelyConvertToString(countryOrigin_id) !== safelyConvertToString(info?.value) && "BorderCorrect"}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"País compra"}
        data={dataCountry}
        set={setDataCountryBuy}
        value={dataCountryBuy}
        className={safelyConvertToString(paisCompra) !== safelyConvertToString(dataCountryBuy?.label) && "BorderCorrect"}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"País destino"}
        data={dataCountry}
        set={setDataCountryOrigin}
        value={dataCountryOrigin}
        className={safelyConvertToString(dataCountryOrigin?.value) !== safelyConvertToString(countryOrigin_id) && "BorderCorrect"}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"quantity_corrected"}
        value={
          quantity_corrected && quantity_corrected !== "NaN"
            ? quantity_corrected
            : ""
        }
        changeValue={parseFloat(quantity) !== parseFloat(quantity_corrected) && "BorderCorrect"}
        tag={"Cantidad"}
        size={100}
        desicion={!quantityError?.includes("quantity_corrected")}
        id={quantity}
      >
        <span className="text-danger">
          {quantityError?.includes("quantity_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("quantity_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        value={gross_weight_corrected}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"gross_weight_corrected"}
        tag={"Peso bruto"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={gross_weight}
        changeValue={parseFloat(gross_weight) !== parseFloat(gross_weight_corrected) && "BorderCorrect"}
      >
        <span className="text-danger">
          {quantityError?.includes("gross_weight_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("gross_weight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        value={net_weight_corrected || 0}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        size={100}
        name={"net_weight_corrected"}
        tag={"Peso neto (Calc:0)"}
        desicion={showItemsView || selectOperation === null}
        // desicion={code_businessUnit === "KG" ? true : showItemsView}
        changeValue={parseFloat(net_weight) !== parseFloat(net_weight_corrected) && "BorderCorrect"}
        id={net_weight}
      >
        <span className="text-danger">
          {quantityError?.includes("net_weight_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("net_weight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        value={number_packages_corrected}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalValuesPrecision}
        customInput={LabelGroup}
        name={"number_packages_corrected"}
        changeValue={
          parseFloat(number_packages) !== parseFloat(number_packages_corrected) && "BorderCorrect"
        }
        tag={"Número de bultos"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={number_packages}
      >
        <span className="text-danger">
          {quantityError?.includes("number_packages_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("number_packages_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <LabelGroup
        name={"code_businessUnit"}
        value={code_businessUnit}
        // onChange={handleInputChange}
        tag={"Unidad comercial"}
        size={100}
        desicion={true}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"Modo de transporte"}
        className={modo !== valueMethod?.label && "BorderCorrect"}
        data={dataTransportMethodNew}
        set={setValueMethod}
        value={valueMethod}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        tag={"Bandera"}
        data={dataCountry}
        className={flagBandera !== valueFlag?.label && "BorderCorrect"}
        set={setValueFlag}
        value={valueFlag}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={embalaje !== packagings?.label && "BorderCorrect"}
        tag={"Embalaje"}
        data={dataPackagingsNew}
        set={setPackagings}
        value={packagings}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_freight_corrected"}
        value={us_freight_corrected || 0}
        changeValue={parseFloat(us_freight) !== parseFloat(us_freight_corrected) && "BorderCorrect"}
        tag={"Flete"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={us_freight}
      >
        <span className="text-danger">
          {quantityError?.includes("us_freight_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_freight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_insurance_corrected"}
        value={us_insurance_corrected || 0}
        changeValue={parseFloat(us_insurance) !== parseFloat(us_insurance_corrected) && "BorderCorrect"}
        tag={"Seguros"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={us_insurance}
      >
        <span className="text-danger">
          {quantityError?.includes("us_insurance_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_insurance_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"other_us_expenses_corrected"}
        value={other_us_expenses_corrected || 0}
        changeValue={
          parseFloat(other_us_expenses) !== parseFloat(other_us_expenses_corrected) && "BorderCorrect"
        }
        tag={"Otros gastos"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={other_us_expenses}
      >
        <span className="text-danger">
          {quantityError?.includes("other_us_expenses_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("other_us_expenses_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        thousandsGroupStyle="thousand"
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"original_fob_value_us_corrected"}
        value={original_fob_value_us_corrected}
        changeValue={
          parseFloat(original_fob_value_us) !== parseFloat(original_fob_value_us_corrected) &&
          "BorderCorrect"
        }
        tag={"Valor FOB original US"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_fob_value_corrected"}
        value={us_fob_value_corrected || ""}
        changeValue={parseFloat(us_fob_value) !== parseFloat(us_fob_value_corrected) && "BorderCorrect"}
        tag={"Valor FOB US$"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={us_fob_value}
      >
        <span className="text-danger">
          {quantityError?.includes("us_fob_value_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_fob_value_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        /* onValueChange={updateItemValue} */
        allowNegative={false}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"cif_us_value"}
        value={suma && suma !== "NaN" ? suma : ""}
        changeValue={parseFloat(suma) !== parseFloat(sumaOriginal) && "BorderCorrect"}
        tag={"Valor CIF US$"}
        size={100}
        desicion={true}
      />

      {/* <LabelGroup
        name={"cif_us_value"}
        // value={suma ? suma : 0}
        value={suma && suma !== "NaN" ? suma : ""}
        changeValue={suma !== sumaOriginal && "BorderCorrect"}
        // onChange={formaterCant(suma?.toFixed(2))}
        tag={"Valor CIF US$"}
        size={100}
        desicion={true}
      /> */}

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(exchange_rate) !== parseFloat(exchange_rate_corrected) && "BorderCorrect"
        }
        name={"exchange_rate_corrected"}
        value={exchange_rate_corrected}
        tag={"Tasa de Cambio"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      <NumericFormat
        allowNegative={false}
        /* onValueChange={updateItemValue} */
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"cif_cop_value"}
        value={multiplicacion || 0}
        changeValue={
          parseFloat(multiplicacion) !== parseFloat(multiplicacionOriginal) && "BorderCorrect"
        }
        tag={"Valor CIF COL$"}
        size={100}
        desicion={true}
      /* desicion={showItemsView || flagBalance === 1} */
      />
      {/* <LabelGroup
        name={"cif_cop_value"}
        changeValue={
          multiplicacion !== multiplicacionOriginal && "BorderCorrect"
        }
        value={multiplicacion ? multiplicacion : 0}
        // onChange={handleInputChange}
        tag={"Valor CIF COL$"}
        size={100}
        desicion={true}
      /> */}

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"added_value_us_corrected"}
        value={added_value_us_corrected}
        changeValue={
          parseFloat(added_value_us) !== parseFloat(added_value_us_corrected) && "BorderCorrect"
        }
        tag={"Valor agregado US$"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={added_value_us}
      >
        <span className="text-danger">
          {quantityError?.includes("added_value_us_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("added_value_us_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalValuesPrecision}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"refund_value_us_corrected"}
        value={refund_value_us_corrected}
        changeValue={
          parseFloat(refund_value_us) !== parseFloat(refund_value_us_corrected) && "BorderCorrect"
        }
        tag={"Valor a Reintegrar US$"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        id={refund_value_us}
      >
        <span className="text-danger">
          {quantityError?.includes("refund_value_us_corrected") &&
            (selectOperation?.value === 980 ||
              selectOperation?.value === 982 ||
              selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("refund_value_us_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
              ? message981
              : ""}
        </span>
      </NumericFormat>

      <LabelGroup
        name={"commercialInvoice"}
        value={`${code_tradeAgreement_corrected ? code_tradeAgreement_corrected + (name_tradeAgreement_corrected && (" " + name_tradeAgreement_corrected)) : ""}`}
        // onChange={handleInputChange}
        tag={"Acuerdo Comercial"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"fob_sdo_value_corrected"}
        value={fob_sdo_value_corrected}
        tag={"Saldo FOB"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"cif_value_sdo_corrected"}
        value={cif_value_sdo_corrected}
        tag={"Saldo CIF"}
        size={100}
        desicion={true}
      />

      <LabelGroup
        name={"adjustment_justification_fob_corrected"}
        value={adjustment_justification_fob_corrected}
        onChange={handleInputChange}
        changeValue={
          safelyConvertToString(adjustment_justification_fob) !==
          safelyConvertToString(adjustment_justification_fob_corrected) && "BorderCorrect"
        }
        tag={"Justificación ajusté FOB"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <LabelGroup
          changeValue={safelyConvertToString(invoice) !== safelyConvertToString(invoice_corrected) && "BorderCorrect"}
          name={"invoice_corrected"}
          value={invoice_corrected}
          onChange={handleInputChange}
          tag={"Factura"}
          size={100}
          desicion={showItemsView || flagBalance === 1}
          ButtonSide={valuesHeader?.commercial_invoice_corrected && true}
        />
        {valuesHeader?.commercial_invoice_corrected && (
          <Tooltip
            hasArrow
            label={"Seleccionar factura existente"}
            placement="top"
          >
            <button
              onClick={getInvoice}
              style={{
                width: "42px",
                marginTop: "24px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                height: "38px",
                backgroundColor: "#1f7ac3",
                outline: "none"
              }}
            >
              <IconGeneric color="#fff" as={TbFileInvoice} />
            </button>
          </Tooltip>
        )}
      </div>

      <LabelGroup
        changeValue={safelyConvertToString(brand) !== safelyConvertToString(brand_corrected) && "BorderCorrect"}
        name={"brand_corrected"}
        value={brand_corrected}
        onChange={handleInputChange}
        tag={"Marca"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      <LabelGroup
        changeValue={safelyConvertToString(serial) !== safelyConvertToString(serial_corrected) && "BorderCorrect"}
        name={"serial_corrected"}
        value={serial_corrected}
        onChange={handleInputChange}
        tag={"Serial"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />

      <LabeFormlStyled>Descripción larga</LabeFormlStyled>
      <Textarea
        name={"output_form_item_description_corrected"}
        changeValue={
          safelyConvertToString(output_form_item_description) !==
          safelyConvertToString(output_form_item_description_corrected) && "BorderCorrect"
        }
        value={output_form_item_description_corrected}
        onChange={handleInputChange}
        desicion={showItemsView || flagBalance === 1}
        className="description"
      />

      <div
        style={{
          height: "62px"
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <ButtonStyled bgColor={"#bababa"} onClick={handleCancel}>
            Cancelar
          </ButtonStyled>
          {!showItemsView && (
            <ButtonStyled type="submit" onClick={hadleSaveItems}>
              {dataToEditItems ? "Actualizar" : "Agregar"}
            </ButtonStyled>
          )}
        </Box>
      </div>
    </>
  );
};
