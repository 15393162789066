import { Td, Tr } from '@chakra-ui/react'

import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const TbodyCriteria = ({ data }) => {
  const Color = level => {
    switch (level) {
      case 'ALTO':
        return 'red'
      case 'MODERADO':
        return 'yellow'
      case 'LEVE':
        return 'green'
      default:
        return ''
    }
  }

  return (
    <Tr>
      <Td className='text-center'>{data?.nit_qualified}</Td>

      <Td className='text-center'>
        {' '}
        <NavLink
          to={`/ShowRiskLevelUser?FreeZoneId=${data?.freeZone_id}`}
          className='ms-2'
        >
          <span className='formNumber'>{data?.shortName_freeZone}</span>
        </NavLink>
      </Td>
      <Td className='text-center'>
        {' '}
        <NavLink
          to={`/ShowRiskLevel?QualifiedId=${data?.qualifiedUser_id}`}
          className='ms-2'
        >
          <span className='formNumber'>{data?.name_qualified}</span>
        </NavLink>
      </Td>
      <Td className='text-center'>{data?.criteria}</Td>
      <Td className='text-center'>{data?.NIVEL}</Td>
      <td
        className='text-center'
        style={{
          backgroundColor: Color(data?.NIVEL)
        }}
      ></td>
    </Tr>
  )
}

export default TbodyCriteria
