import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { IconGeneric } from "styled/Icons.styled";
import { HiOutlineInformationCircle } from "react-icons/hi";
import WeighingContext from "context/WeighingContext";
import TbodyMenuWeigh from "./TbodyMenuWeigh";

const TbodyWeighing = ({ data }) => {
  const navigate = useNavigate();
  const { onOpenViewContainer, setDataContainer, onOpenViewForm, setDataForm } =
    useContext(WeighingContext);


  const hanldeShow = () => {
    setDataContainer(data?.containers);
    onOpenViewContainer();
  };

  const hanldeShowForm = () => {
    setDataForm(data?.formularios);
    onOpenViewForm();
  };

  return (
    <Tr>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">
        <NavLink to={`/ShowWeighing?weighing=${data?.id}&Operation=${data?.operation}`} className="formNumber">{data?.consecutive}</NavLink>
      </Td>
      <Td className="text-center">{data?.weight_date}</Td>
      <Td className="text-center">{data?.vehicle_plate}</Td>
      <Td className="text-center">{data?.shedule}</Td>
      <Td className="text-center">{data?.operation}</Td>
      <Td className="text-center">{data?.description}</Td>
      <Td className="text-center">{data?.net_weight}</Td>
      <Td className="text-center">{data?.gross_weight}</Td>
      <Td className="text-center">{data?.gross_user_name}</Td>
      <Td className="text-center">{data?.gross_scale_name}</Td>
      <Td className="text-center">{data?.gross_type ?? "N/A"}</Td>
      <Td className="text-center">{data?.tare_weight}</Td>
      <Td className="text-center">{data?.tare_user_name}</Td>
      <Td className="text-center">{data?.tare_type ?? "N/A"}</Td>
      <Td className="text-center">{data?.tare_scale_name}</Td>
      <Td className="text-center">{data?.completeness}</Td>
      <Td className="text-center">
        {data?.containers && data?.containers.length > 0
          ? (
            <IconGeneric onClick={hanldeShow} as={HiOutlineInformationCircle} />
          )
          : (
            <IconGeneric color="#bababa" as={HiOutlineInformationCircle} />
          )}
      </Td>
      <Td className="text-center">{data?.status}</Td>
      <Td className="text-center">{data?.passForm_number}</Td>
      <Td className="text-center">
        {data?.formularios && data?.formularios?.length > 1
          ? (
            <IconGeneric
              onClick={hanldeShowForm}
              as={HiOutlineInformationCircle}
            />
          )
          : data?.formularios
            ? (
              data?.formularios[0]?.code_entryForm
            )
            : (
              <></>
            )}
      </Td>
      <Td className="text-center">
        <TbodyMenuWeigh data={data} />
      </Td>
    </Tr>
  );
};

TbodyWeighing.propTypes = {
  data: PropTypes.object
};

export default TbodyWeighing;
