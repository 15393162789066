/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __getVehicularDoorsEntry,
  __getVehicularDoorsExit
} from "request/__Security";

/**
 *  useTypeTransport - Hooks para obtener los tipos de puertas vehiculares
 *
 * @function
 * @return {Object} Retorna objeto que dentro tiene un array con los tipos de puertas vehiculares.
 */

const useVehicularDoors = (destiny) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataVehicheDoor, setDataVehicheDoor] = useState([]);

  useEffect(() => {
    getVehicularDoors();
  }, []);

  const getVehicularDoors = async () => {
    try {
      const res =
        destiny === "entry"
          ? await __getVehicularDoorsEntry(token)
          : await __getVehicularDoorsExit(token);
      const data = res?.data?.status?.data;
      const dataVehicleDoor = data.map((item) => ({
        value: item?.id,
        label: item?.name_vehicularDoor
      }));
      setDataVehicheDoor(dataVehicleDoor);
    } catch (error) {
      console.log(error);
    }
  };

  return { dataVehicheDoor };
};

export default useVehicularDoors;
