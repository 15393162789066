import React, { useContext, useEffect } from 'react'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { MdContentCopy, MdOutlineAccountBalanceWallet } from 'react-icons/md'
import { AuthContext } from 'context/AuthContext'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { HiOutlineDocumentSearch } from 'react-icons/hi'
import { TiCancel } from 'react-icons/ti'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements, isValid } from 'utils/type-check-utils'
import { useNavigate } from 'react-router-dom'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
const { Outputform, Entryform } = localPermissions

const {
  changeStatus: changeStatusEntryForm,
  copy: copyEntryForm,
  balanceAdjustment: balanceAdjustmentEntry
} = Entryform
const {
  changeStatus: changeStatusOutputForm,
  copy: copyOutputForm,
  balanceAdjustment: balanceAdjustmentOutput
} = Outputform

export const OptionsAutorizadosForm = ({ data }) => {
const navigate = useNavigate();

  const {
    setDataToCopy,
    onOpenCopy,
    setDataToAnular,
    onOpeAnular,
    handleCorrection
  } = useContext(FormCrudCRUDContext)
  // const navigate = useNavigate();

  const { authUser } = useContext(AuthContext)

  const handleCopy = () => {
    setDataToCopy(data)
    onOpenCopy()
  }

  const handleAnular = () => {
    onOpeAnular()
    setDataToAnular(data)
  }

  const isOperationInRange = ({ code_operation, temporary }) => {
    const balanceAdjustmentPermission = hasCommonElements(permissionList, [
      ...balanceAdjustmentEntry,
      ...balanceAdjustmentOutput
    ])

    const isPermanentValidRange =
      code_operation >= 101 &&
      code_operation <= 299 &&
      temporary === 1 &&
      balanceAdjustmentPermission

    const isTemporaryValidRange =
      code_operation >= 300 &&
      code_operation <= 699 &&
      temporary !== 1 &&
      balanceAdjustmentPermission

    const isAdjustBalanceIsAllowed =
      balanceAdjustmentPermission &&
      !isPermanentValidRange &&
      !isTemporaryValidRange

    return (
      isPermanentValidRange || isTemporaryValidRange || isAdjustBalanceIsAllowed
    )
  }

  const { permissionList = [] } = useSelector(state => state.permissions)

  
  const redirectDocument = () => {
    const {entryFormCorrected_id, outputFormCorrected_id} = data || {}

    if(isValid(entryFormCorrected_id)){
      navigate(`/FormCorrection?ShowEntry=${entryFormCorrected_id}`);
    return 
    }

    if(isValid(outputFormCorrected_id)){
      navigate(`/FormCorrectionOutput?ShowOutput=${outputFormCorrected_id}`);
    return 
    }
  }

  return (
    <>
      {(hasCommonElements(permissionList, [
        ...copyEntryForm,
        ...copyOutputForm
      ]) ||
        authUser.admin === 1) && (
          <MenuItemStyled onClick={handleCopy} py={3}>
            <IconMenuRUD color='#03C39A' as={MdContentCopy} />
            Copiar
          </MenuItemStyled>
        )}
      <HrDividerNoMargin />
      {isOperationInRange(data) && (
        <>
          <MenuItemStyled onClick={() => handleCorrection(data, 1)} py={3}>
            <IconMenuRUD color='#F9A621' as={MdOutlineAccountBalanceWallet} />
            Ajustar saldo
          </MenuItemStyled>
          <HrDividerNoMargin />
        </>
      )}


        <>
          <MenuItemStyled onClick={data?.final_balance_adjustment === 1 ? redirectDocument : () => {}} py={3}>
            <IconMenuRUD color={data?.final_balance_adjustment === 1 ?'#1F7AC3' : "#BABABA"} as={data?.final_balance_adjustment === 1 ? BsEyeFill : BsEyeSlashFill} />
            Ajuste asociado
          </MenuItemStyled>
          <HrDividerNoMargin />
        </>
 


      {(hasCommonElements(permissionList, [
        ...changeStatusEntryForm,
        ...changeStatusOutputForm
      ]) ||
        authUser.admin === 1) && (
          <MenuItemStyled onClick={() => handleCorrection(data, 0)} py={3}>
            <IconMenuRUD color='#F9A621' as={HiOutlineDocumentSearch} />
            Corregir
          </MenuItemStyled>
        )}

      {(authUser.admin === 1 || authUser.is_commerce === 1) &&
        (hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) ||
          authUser.admin === 1) && (
          <>
            <HrDividerNoMargin />
            <MenuItemStyled py={3} onClick={handleAnular}>
              <IconMenuRUD color='#41495A' as={TiCancel} />
              Anular
            </MenuItemStyled>
          </>
        )}
    </>
  )
}
