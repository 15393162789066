import React, { useCallback, useContext, useEffect, useState } from "react";
import TableOrder from "components/Tables/TableOrder";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { useSelector } from "react-redux";
import TreasuryCrudContex from "context/TreasuryContex";
import { TitleIncidents } from "../ArrayList/ArrayList";
import environment from "constants/apiConst";
import TbodyIncidents from "../Tables/TbodyIncidents";
import { Box } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { __PostForm } from "request/Petitions/__Post";

const IncidentsView = ({ onClose }) => {
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { update, dataToIncidents } = useContext(TreasuryCrudContex);

  const getRecharge = useCallback(async () => {
    const formData = new FormData();
    formData.append("recharge_id", dataToIncidents?.recharge_id);
    setLoading(true);
    try {
      const res = await __PostForm(
        environment.TREASURY_RECHARGE_GET,
        token,
        formData
      );
      // console.log(res?.data?.status?.data?.incidents);
      setCurrentPage(res?.data?.status?.data?.incidents);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token, dataToIncidents?.recharge_id]);

  useEffect(() => {
    if (dataToIncidents !== null) {
      getRecharge();
    }
  }, [getRecharge, update, dataToIncidents]);

  return (
    <>
      <HeaderSearch>
        <TableOrder
          thead={TitleIncidents}
          data={currentPage || []}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyIncidents}
        />
      </HeaderSearch>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        {/* <ButtonStyled onClick={handleSubmitRecharge}>
          {dataToEdit ? "Editar recarga" : "Crear recarga"}
        </ButtonStyled> */}
      </Box>
    </>
  );
};

export default IncidentsView;
