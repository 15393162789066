import { Box, Tooltip } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import ButtonsCheck from "./ButtonsCheck";
import { VscFilePdf } from "react-icons/vsc";
import { MdQrCodeScanner } from "react-icons/md";
import { IconGeneric } from "styled/Icons.styled";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";

const ButtonsBoxCheck = ({ data, statusCI, children }) => {
  const {
    changeStatus,
    onOpenReturnReason,
    setReturnReason,
    changeStatusAutorized,
    setRequestChangeStatusAutorized,
    productionOrderReviews,
    setRequestChangeStatusBox,
    returnReviews
  } = useContext(IntegracionBoxCrudContex);

  const location = useLocation();
  const { id = "" } = queryString.parse(location.search);

  useEffect(() => {
    setRequestChangeStatusBox(environment.CHANGE_STATUS_BOX);
  }, []);

  const handleStatusBox = (status) => {
    const statusData = new FormData();
    statusData.append("id", id);
    statusData.append("status", status);
    const info = { id: data?.user_id, status_entryForm: status };

    const Return = {
      ...info
    };
    

    if (
      status === "DEVUELTO" &&
      (productionOrderReviews?.length > 0 || returnReviews?.length > 0)
    ) {
      changeStatus(Return);
    }

    if (status === "DEVUELTO" && statusCI === "PRESENTADO") {
      onOpenReturnReason();
      setReturnReason(info);
    }

    if (status === "AUTORIZADO") {
      changeStatus(statusData);
    }
  };

  const handleStatusAutorized = (status) => {
    const statusData = new FormData();
    statusData.append("id", id);
    statusData.append("status", status);

    if (status === "DEVUELTO" && statusCI === "PRESENTADO") {
      const info = {
        id: data.user_id,
        status_entryForm: status
      };
      onOpenReturnReason();
      setReturnReason(info);
    } else {
      changeStatusAutorized(statusData);
    }
  };

  useEffect(() => {
    setRequestChangeStatusAutorized(environment.CHANGE_STATUS_BOX);
  }, [setRequestChangeStatusAutorized]);

  return (
    <div className="row">
      <div className="col">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className="mt-4"
        >
          <ButtonsCheck
            handleStatusBox={handleStatusBox}
            handleStatusAutorized={handleStatusAutorized}
          />
        </Box>
      </div>

      {children}
      <div className="col">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className="mt-4"
        >
          <Tooltip placement="top" hasArrow label="Qr">
            <span>
              <ButtonHeaderModule
                //   onClick={onOpen}
                className="m-2"
                bgColor="#1F7AC3"
              >
                <IconGeneric className="m-1 text-white" as={MdQrCodeScanner} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Exportar PDF">
            <span>
              <ButtonHeaderModule
                //   onClick={() => ExportPdf("pdfId", name)}
                className="m-1"
                bgColor="#03C39A"
              >
                <IconGeneric className=" text-white" as={VscFilePdf} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
};

export default ButtonsBoxCheck;
