import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import React, { useContext, useState } from "react";
import MenuThirdParties from "../menu/MenuThirdParties";
import { ConfigIconStatus } from "pages/Configuration/tables/components/LoadingConfig";
import { useSelector } from "react-redux";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import styled from "styled-components";
import ThirdPartiesContext from "context/ThirdPartiesContext";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyThirdParties = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const [loadingIcons, setLoadingIcons] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    setUpdate,
    update
  } = useContext(ThirdPartiesContext);
  const handleState = async () => {

    if (loadingIcons) return;
    setLoadingIcons(true);
    try {
      const res = await __GetDataId(environment.CHANGE_STATUS_THIRD_PARTY, token, data.id);
      if (res.data.status.code === 200) {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  }

  return (
    <Tr>
      {authUser.admin === 1 || authUser.is_commerce === 1
        ? (
          <Td className="text-center">{data?.name_qualified}</Td>
        )
        : (
          ""
        )}
      <Td className="text-center">{data?.type}</Td>
      <Td className="text-center">{data?.business_name}</Td>
      <Td className="text-center">{data?.name_identification_type ?? 'N/A'}</Td>
      <Td className="text-center">{data?.identification_num ?? 'N/A'}</Td>
      <Td className="text-center">{data?.internal_code}</Td>
      <Td className="text-center">{data?.acronym}</Td>
      <Td className="text-center">{data?.alias}</Td>
      <Td className="text-center">{data?.user_nickname_create}</Td>
      <Td className="text-center" style={{ whiteSpace: "nowrap" }}>{data?.date_create}</Td> {/*el  whiteSpace: "nowrap" permite que no se creen saltos de linea */}
      <Td className="text-center">{data?.user_nickname_update}</Td>
      <Td className="text-center" style={{ whiteSpace: "nowrap" }}>{data?.date_update}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data.status === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data?.status}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center">
        <MenuThirdParties data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyThirdParties;
