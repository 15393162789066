import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";

export const useFormatQuantity = () => {
  const formatQuantity = (decimalPlaces, value, includeDecimals = true) => {
    const maxFractionDigits = includeDecimals ? decimalPlaces || 0 : 0;

    const numberFormatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: maxFractionDigits
    });

    const parsedValue = parseFloat(value);
    const formattedValue = numberFormatter.format(parsedValue);
    const valueWithoutFormart = parseNumberWithCommas(formattedValue);

    if (isNaN(valueWithoutFormart)) {
      // console.error(`El valor "${value}" no es un número válido.`);
      return 0;
    }

    return formattedValue;
  };

  return [formatQuantity];
};
