import React, { useContext, useEffect, useState } from "react";
import { Flex, Spacer, Image } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { useSelector, useDispatch } from "react-redux";
import {
  ContainerFields,
  ContainerButton,
  ButtonStyled,
  EditPicture
} from "./Forms.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Me, __UpdateDataImage, __UpdateDataUser } from "request/__Me";
import { SelectCustom } from "./SelectCustom";
import { DocumentsList } from "./DocumentList";
import FormCrudCRUDContext from "context/FormCrudContex";

import { setDataUser } from "redux/actions/useAction";

const FormChangeUserData = ({
  setSearchAdvanced,
  onClose,
  pic,
  setPic,
  userName,
  setUserName
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert] = AlertErrorAlert();
  const { userImage } = useContext(FormCrudCRUDContext);
  const imageLoading =
    "https://media.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif";

  const [values, handleInputChange, reset, setValues] = useForm({
    name: "",
    lastName: "",
    typeDocumentId: "",
    numberIdDocument: "",
    expeditionCountry: "",
    residenceCountry: "",
    directionResidence: "",
    email: "",
    phoneNumber: ""
  });

  const {
    name,
    lastName,
    typeDocumentId,
    numberIdDocument,
    expeditionCountry,
    residenceCountry,
    directionResidence,
    email,
    phoneNumber
  } = values;
  const dispacthRedux = useDispatch();
  const GlobalUserImage = useSelector((state) => state?.userInfo?.update);

  const handleSubmit = () => {
    const data = {
      identification: numberIdDocument,
      lastname_person: lastName,
      name_person: name,
      phone_1: phoneNumber,
      type_identification: typeDocumentId,
      email
    };
    sendData(data);
  };

  const sendData = async (data) => {
    try {
      const res = await __UpdateDataUser(token, data);
      console.log(res, "actualizar");
      const code = res?.data?.status?.code;
      const message = res?.data?.status?.message;
      if (code === 200) {
        openAlert(message, "success");
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      getUserInfo();
    }
  };

  const sendImage = async (data) => {
    try {
      const res = await __UpdateDataImage(token, data);
      console.log(res, "actualizar");
      const code = res?.data?.status?.code;
      const message = res?.data?.status?.message;
      if (code === 200) {
        openAlert(message, "success");
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeImage = (e) => {
    const image = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      dispacthRedux(setDataUser(reader.result))

      const img = {
        image_person: reader.result
      };
      sendImage(img);
    };
  };

  const getUserInfo = async () => {
    try {
      const info = await __Me(token);
      const res = info?.data?.status?.data;
      setValues({
        name: res?.data_person.name_person,
        lastName: res?.data_person?.lastname_person,
        typeDocumentId: res?.data_person?.type_identification,
        numberIdDocument: res?.data_person?.identification,
        expeditionCountry: "",
        residenceCountry: "",
        directionResidence: "",
        email: res?.email,
        phoneNumber: res?.data_person?.phone_1,
        image_person: res?.data_person?.image_person
      });
      setUserName(res?.nickname);
      dispacthRedux(setDataUser(res?.data_person?.image_person));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <Image boxSize="50px" src={imageLoading} alt="loading" />
          <h1 style={{ marginTop: "20px" }}>Loading</h1>
        </div>
      ) : (
        <>
          <ContainerFields>
            <label
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#fff",
                backgroundColor: "transparent",
                display: "inline-block",
                transition: "all .5s",
                cursor: "pointer",
                padding: "15px 40px !important",
                width: "fit-content",
                textAlign: "center",
                position: "relative"
              }}
            >
              <Image
                borderRadius="full"
                boxSize="110px"
                src={GlobalUserImage}
              />
              <EditPicture>Editar</EditPicture>
              <input
                type="file"
                id="avatar"
                name="avatar"
                accept=".jpg"
                onChange={(e) => changeImage(e)}
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: "0",
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: "-1"
                }}
              />
            </label>
            <LabelGroup
              fontSize={"12px"}
              name={"name"}
              value={name}
              onChange={handleInputChange}
              tag={"Nombre"}
              size={100}
              tipo={"text"}
            />
            <LabelGroup
              fontSize={"12px"}
              name={"lastName"}
              value={lastName}
              onChange={handleInputChange}
              tag={"Apellido"}
              size={100}
              tipo={"text"}
            />
            {/*    <LabelGroup
                    fontSize={'12px'}
                    name={"typeDocumentId"}
                    value={typeDocumentId}
                    onChange={handleInputChange}
                    tag={"Tipo de documento"}
                    size={100}
                    tipo={"text"}
                /> */}

            <SelectCustom
              tag={"Tipo de documento"}
              defaultValues={typeDocumentId}
              set={setValues}
              get={values}
              name={"typeDocumentId"}
              data={DocumentsList}
            />

            <LabelGroup
              fontSize={"12px"}
              name={"numberIdDocument"}
              value={numberIdDocument}
              onChange={handleInputChange}
              tag={"Número de documento"}
              size={100}
              tipo={"number"}
            />

            {/* <LabelGroup
                    fontSize={'12px'}
                    name={"expeditionCountry"}
                    value={expeditionCountry}
                    onChange={handleInputChange}
                    tag={"Lugar de expedición"}
                    size={100}
                    tipo={"text"}
                />
                <LabelGroup
                    fontSize={'12px'}
                    name={"residenceCountry"}
                    value={residenceCountry}
                    onChange={handleInputChange}
                    tag={"País de residencia"}
                    size={100}
                    tipo={"text"}
                />
                <LabelGroup
                    fontSize={'12px'}
                    name={"directionResidence"}
                    value={directionResidence}
                    onChange={handleInputChange}
                    tag={"Dirección de residencia"}
                    size={100}
                    tipo={"text"}
                /> */}
            <LabelGroup
              fontSize={"12px"}
              name={"phoneNumber"}
              value={phoneNumber}
              onChange={handleInputChange}
              tag={"Número de celular"}
              size={100}
              tipo={"number"}
            />
            <LabelGroup
              fontSize={"12px"}
              name={"email"}
              value={email}
              onChange={handleInputChange}
              tag={"Correo electrónico"}
              size={100}
              tipo={"email"}
            />
          </ContainerFields>
          <ContainerButton>
            <ButtonStyled onClick={handleSubmit}>Guardar</ButtonStyled>
          </ContainerButton>
        </>
      )}
    </>
  );
};

export default FormChangeUserData;
