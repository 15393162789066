import React, { useEffect, useState } from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuItems from "components/Menu/MenuItems";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const TbodyItems = ({ data, iope }) => {
  const location = useLocation();
  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );
  const [erroresLocal, setErroresLocal] = useState([]);

  useEffect(() => {
    if (FormEntryNumber || FormExitNumber) {
      const obtenerErrorItem = JSON.parse(
        localStorage.getItem(`erroItem-${FormEntryNumber || FormExitNumber}`) ||
          "[]"
      );
      if (obtenerErrorItem) {
        setErroresLocal(obtenerErrorItem);
      }
    }
  }, [FormEntryNumber, FormExitNumber]);

  return (
    <Tr
      className={
        erroresLocal && erroresLocal?.includes(data.id) && "BorderError"
      }
    >
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">
        {data?.code_subheading ? data?.code_subheading : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description_product ? data?.description_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit ? data?.code_businessUnit : "N/A"}
      </Td>
      <Td className="text-center">{data?.quantity ? data?.quantity : "N/A"}</Td>
      <Td className="text-center">
        {data?.product_quantity ? data?.product_quantity : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.cif_us_value ? data?.cif_us_value : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.us_fob_value ? data?.us_fob_value : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.net_weight ? data?.net_weight : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.gross_weight ? data?.gross_weight : "N/A"}
      </Td>
      <Td isNumeric className="text-center">
        <MenuItems data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyItems;
