/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthContext";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import ConfigurationHeader from "../ConfigurationHeader";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { SubheadingTitle } from "../ArrayTitles";
import { __SubheadingControl } from "request/configuration/__SubheadingControl";
import TbodySubheadingControl from "../tables/TbodySubheadingC";
import FormSubheadingC from "../forms/FormSubheadingC";
import FormSubheadingShow from "../FormShow/FormSubheadingShow";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formEditSub = () => {
  return <FormSubheadingC />;
};

const formShow = () => {
  return <FormSubheadingShow />;
};

export const SubheadingControl = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const { q = "" } = queryString.parse(location.search);
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formShow);
    setRequestEdit(environment.SUBHEADIN_C_UPDATE);
    setRequestCreate(environment.SUBHEADIN_C_CREATE);
    setRequestDelete(environment.SUBHEADIN_C_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configSubheadingControl"]});
  }, [q, update, page]);


  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configSubheadingControl", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.SUBHEADIN_C_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])


  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Control de subpartidas"></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={SubheadingTitle}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodySubheadingControl}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
