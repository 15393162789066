import React, { useEffect, useState } from 'react';
import { ButtonStyled } from 'styled/Buttons.styled';
import {
  SideImgContainer,
  TermAndConditionImgHead,
  TermsAndConditionContainer,
  TermsAndConditionTitleContainer
} from './TermsAndConditions.style';
import { TermsAndConditionsImage } from 'assets/images';
import SpinnerComponent from 'components/Spinner/SpinnerComponent';
import { useSelector } from 'react-redux';
import { __Get } from 'request/Petitions/__Get';
import environment from 'constants/apiConst';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { BodyTermConditions } from 'assets/dashboard-icons/BodyTermConditions';

const TermsAndCondition = ({ onCloseModal, confirmTerms, loading, token }) => {
  const [defaultText, setDefaultText] = useState('')
  const location = useLocation();

  const getTermsCondition = async () => {
    try {
      const res = await __Get(environment.GET_TERMS_CONDITIONS, token)
      setDefaultText(res?.data?.status?.data?.[0]?.terms_and_conditions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTermsCondition()
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <SideImgContainer>
        <TermAndConditionImgHead>
          <img src={TermsAndConditionsImage.head.img} alt={TermsAndConditionsImage.head.alt} />
        </TermAndConditionImgHead>
        <img src={BodyTermConditions.img} alt={TermsAndConditionsImage.body.alt} />
      </SideImgContainer>

      <div>
        <TermsAndConditionTitleContainer>
          <span className="fs-5 fw-bold ms-3">Términos y condiciones</span>
          <img
            className="me-5"
            src={TermsAndConditionsImage.title.img}
            alt={TermsAndConditionsImage.title.alt}
          />
        </TermsAndConditionTitleContainer>
        <hr className="my-4" style={{ backgroundColor: '#03C39A', height: '3px' }} />
        <TermsAndConditionContainer>
          {`${defaultText}.`}
        </TermsAndConditionContainer>

        <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
          {loading ? (
            <SpinnerComponent />
          ) : (
            <>
              {/* <ButtonStyled type="button" bgColor="#bababa" wd="140px" onClick={onCloseModal}>
                Cancelar
              </ButtonStyled> */}
              <ButtonStyled wd="140px" onClick={confirmTerms}>
                Aceptar
              </ButtonStyled>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
