import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";

export const UserToUserOriginal = ({ data }) => {
  const {
    commercial_invoice,
    value_duta,
    value_duta_corrected,
    name_qualified2
  } = data;
  const { opcionalCurrent, StateNit } = useContext(FormCrudCRUDContext);
  return (
    <>
      <LabelGroup
        value={name_qualified2}
        tag={"Nit"}
        size={100}
        desicion={true}
        changeValue={name_qualified2 !== StateNit?.label && "BorderCorrect"}
      />

      <LabelGroup
        name={"commercial_invoice"}
        value={commercial_invoice}
        tag={"Factura Comercial"}
        size={100}
        desicion={true}
      />
      {opcionalCurrent && (
        <LabelGroup
          name={"value_duta"}
          value={value_duta}
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          tag={opcionalCurrent}
          size={100}
          desicion={true}
        />
      )}
    </>
  );
};
