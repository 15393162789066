import { RiRadioButtonFill } from "react-icons/ri";

const { Tooltip } = require("@chakra-ui/react");
const { IconGeneric } = require("styled/Icons.styled");

export const trackingStatus = {
  1: (
    <Tooltip placement="top" hasArrow label="En seguimiento">
      <spam>
        <IconGeneric color={"#BA1F33"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  ),

  2: (
    <Tooltip placement="top" hasArrow label={"Estuvo en seguimiento"}>
      <spam>
        <IconGeneric color={"#03C39A"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  ),

  default: (
    <Tooltip placement="top" hasArrow label={"Sin seguimiento"}>
      <spam>
        <IconGeneric color={"#BABABA"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  )
};
