import React from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";

const index = () => {
  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      <div>Libre disposición</div>
    </HeaderSearch>
  );
};

export default index;
