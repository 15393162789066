import React, { useState } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";

import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import {
  complentitud,
  Operation,
  times
} from "pages/Weighing/SearchAdvanced/Array/ArraySearchAdvanced";
import { ButtonStyled } from "styled/Buttons.styled";
import InputSelect from "components/InputSelect/InputSelect";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

const FormsSearchWeigh = ({ setSearchAdvanced, onClose }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["order", "consecutive", "vehiclePlate", "completeness", "startDate", "endDate", "shedule", "formType", "formNumber", "completen", "operation", "shedules"], nestedProp: "searchAdvance" });

  const optionesAsc = ["Ascendente", "Descendente"];
  const [dateOf, setDateOf] = useState(null);
  const [completen, setCompleten] = useState(state?.completen);
  const [operation, setOperation] = useState(state?.operation);
  const [shedules, setsShedules] = useState(state?.shedules);
  const [ordenado, setOrdenado] = useState(state?.order !== "desc" ? "Ascendente" : "Descendente");

  const [values, handleInputChange, reset, setValues] = useForm({
    consecutive: state?.consecutive,
    vehiclePlate: state?.vehiclePlate,
    startDate: state?.startDate,
    endDate: state?.endDate,
    formNumber: state?.formNumber,
  });

  const { consecutive, vehiclePlate, startDate, endDate, formNumber } = values;

  const handleSubmit = (e) => {
    const data = {
      ...values,
      scale: true,
      order: ordenado === "Descendente" ? "desc" : "asc",
      ...(consecutive && { consecutive }),
      ...(vehiclePlate && { vehiclePlate }),
      ...(completen && { completeness: completen.value }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(shedules && { shedule: shedules.value }),
      ...(operation && { formType: operation.value }),
      ...(formNumber && { formNumber })
    };

    setParamsSearch({
      params: {
        ...handleSanitizedObj(data),
        ...(completen && { completen }),
        ...(operation && { operation }),
        ...(shedules && { shedules }),
      }
    });
    setSearchAdvanced(data);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"consecutive"}
          value={consecutive}
          onChange={handleInputChange}
          tag={"Consecutivo pesaje"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"vehiclePlate"}
          value={vehiclePlate}
          onChange={handleInputChange}
          tag={"Placa del vehiculo"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"startDate"}
          value={startDate}
          onChange={handleInputChange}
          tag={"Fecha inicio"}
          size={45}
          tipo={"date"}
        />

        <LabelGroup
          name={"endDate"}
          value={endDate}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        />
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"startTime"}
          value={startTime}
          onChange={handleInputChange}
          tag={"Fecha inicio"}
          size={45}
          tipo={"time"}
        />

        <LabelGroup
          name={"endTime"}
          value={endTime}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"time"}
        />
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Fecha de"}
            data={dateBy}
            value={dateOf}
            set={setDateOf}
          />
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Operación"}
            data={Operation}
            value={operation}
            set={setOperation}
          />
        </div>

        <LabelGroup
          name={"formNumber"}
          value={formNumber}
          onChange={handleInputChange}
          tag={"Num. Formulario"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <LabelGroup
          value={container}
          tag={"Contenedor"}
          onChange={handleInputChange}
          size={45}
          tipo={"text"}
        /> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          //   name={"code"}
          //   value={code}
          //   onChange={handleInputChange}
          tag={"Operario"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />

        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Horario"}
            data={times}
            value={shedules}
            set={setsShedules}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Ordenar por"}
            data={OrderBy}
            // value={}
            // set={}
          />
        </div> */}

        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Completitud"}
            data={complentitud}
            value={completen}
            set={setCompleten}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div className="mt-3">
          <RadioBtn
            options={optionesAsc}
            setOnly={setOrdenado}
            state={ordenado}
          />
        </div>
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormsSearchWeigh;
