import React, { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";
import styled from "styled-components";
import environment from "constants/apiConst";
import { __Get } from "request/Petitions/__Get";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormShippingWithComparison from "pages/Dian/Spreadsheet/FormShippingWithComparison";
import { useModal } from "hooks/useModal";
import { GetLocalStorage } from "hooks/useLocalStorage";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;
  border-bottom: 1px solid #bababa;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuShipping = ({ data }) => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { movement = "" } = queryString.parse(location.search);
  const [openAlert] = AlertErrorAlert();
  const [isOpenSpreadsheet, onOpenSpreadsheet, onCloseSpreadsheet] = useModal();

  const [CurrentData, setCurrentData] = useState([]);
  const GetComparison = async (id) => {
    try {
      const res = await __Get(
        `${environment.GET_COMPARISON_FORMS}/${id}`,
        token
      );
      const status = res?.data?.status;
      if (status.code !== 200) {
        openAlert(status?.message, "error");
      } else {
        // console.log(res?.data?.status?.data);
        setCurrentData(res?.data?.status?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShow = (id) => {
    GetComparison(id);
    onOpenSpreadsheet();
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSpreadsheet}
        onClose={onCloseSpreadsheet}
        title={`Planilla de envio: ${GetLocalStorage('DianNumber')}`}
        size={"6xl"}
      >
        <FormShippingWithComparison
          data={CurrentData}
          onCloseSpreadsheet={onCloseSpreadsheet}
        />
      </ModalGeneric>
      <Menu>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color="#00000085" />
        </MenuButton>

        <MenuList
          borderRadius="none"
          py={0}
          style={{ boxSizing: "border-box" }}
        >
          <MenuItemStyled py={3} onClick={() => handleShow(data.planilla_id)}>
            <IconMenuRUD as={MdRemoveRedEye} />
            Ver
          </MenuItemStyled>
        </MenuList>
      </Menu>{" "}
    </>
  );
};

export default MenuShipping;
