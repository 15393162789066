import React, { useCallback, useContext, useEffect, useState } from "react";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftTreasury,
  arrayRigthTreasury,
  arrayRigthUserTreasury,
  salesOfServiceNodeInputFields,
  TitleSales
} from "../ArrayList/ArrayList";
import FormSearch from "../Forms/FormSearch";
import { useForm } from "hooks/useForm";
import TbodySalesOfService from "../Tables/TbodySalesOfService";
import Buttons from "../Buttons/Buttons";
import FormRecharge from "../Forms/FormRecharge";
import FormPayOnLine from "../Forms/FormPayOnLine";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import TreasuryCrudContex from "context/TreasuryContex";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { AuthContext } from "context/AuthContext";
import IncidentsView from "./IncidentsView";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post, __PostForm, __PostJsonData } from "request/Petitions/__Post";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import RechargesTaxesTable from "./RechargesTaxesTable";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

const SalesOfService = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["invoice", "date1", "date2", "type", "payment_method", "valueMethod", "valueDocument"], nestedProp: "searchAdvance" });

  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const [isOpenPercentage, onOpenPercentage, onClosePercentage] = useModal();
  const [isOpenPay, onOpenPay, onClosePay] = useModal();
  const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const [allAccess, setAllAccess] = useState(false);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [myPermission, setmyPermission] = useState(null);

  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    update,
    isOpenRecharge,
    onOpenRecharge,
    onCloseModal,
    exportColumnBy,
    isOpenIncidents,
    dataToIncidents,
    setRequestExport
  } = useContext(TreasuryCrudContex);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search || "",
    valueQualified: state?.valueQualified || [],
  });

  const { searchInformacion, valueQualified } = values;

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchAdvance", "searchSimple"] });
    reset();
    setSearchAdvanced({});
    setChangeViewSearch(false);
  };

  const getRecharge = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __PostForm(environment.TREASURY_RECHARGE_GET, token);
      setCurrentPage(res?.data?.status?.data?.data);
      setMaxPage(res?.data?.status?.data?.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getRecharge();
  }, [getRecharge, update]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.TREASURY_RECHARGE_GET,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced({});
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const searchAVanzada = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.TREASURY_RECHARGE_GET,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced({});
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const dispacthRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...(valueQualified && valueQualified.length !== 0 && {
        qualifiedUser_id: valueQualified.value
      }),
      ...(searchInformacion && { search: searchInformacion }),
      ...searcheAdvanced
    }

    if (Object.keys(data).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(data);
      dispacthRedux(setSearch(data));
      return;
    }

    dispacthRedux(clearSearch());
    setChangeViewSearch(false);
    getRecharge();
  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZone_id]);

  useEffect(() => {
    return async () => dispacthRedux(clearSearch());
  }, []);

  const handleSubmit = async (data, formulario) => {
    try {
      const info = {
        fields: formulario,
        data: {
          ...data,
          ...(isArray(data?.type_export_file) &&
            data?.type_export_file?.length !== 0 && {
            type_export_file: data?.type_export_file?.value
          }),
        },
      };
      const res = await __Post(environment.TREASURY_RECHARGE_EXPORT, token, info);
      console.log(res);
      if (res?.data?.status?.code === 200) {
        onCloseExport();
        return openAlert(`${res?.data?.status?.message}`, "success");
      } else {
        // console.log(res);
        return openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const { requestData } = useGlobalContext();
  const { data: dataTaxes, isLoading: isLoadingTax } = useQuery({
    queryKey: [queryEnv?.TAXES_RECHARGES],
    queryFn: () => requestData({ Endpoint: environment.TREASURY_RECHARGES_TAXES, PropertyBody: "POST" }),
  })

  const calculateNodeList = {
    1: arrayLeftTreasury.concat(arrayRigthUserTreasury),
    0: arrayLeftTreasury.concat(arrayRigthTreasury),
  }[authUser.is_qualified_user]

  return (
    <>
      <ModalGeneric
        isOpen={isOpenIncidents}
        onClose={onCloseModal}
        title={`Factura Nº ${dataToIncidents?.invoice}`}
        size={"6xl"}
      >
        <IncidentsView onClose={onCloseModal} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRecharge}
        onClose={onCloseModal}
        title={"Venta de recargas"}
        size={"xl"}
        scrollBehavior={"outside"}
      >
        <FormRecharge onClose={onCloseModal} onOpenRecharge={onOpenRecharge} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenPercentage}
        onClose={onClosePercentage}
        title={"Manejo del IVA"}
        size={"3xl"}
      >
        <RechargesTaxesTable />
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenPay}
        onClose={onClosePay}
        title={"Pago en Linea"}
        size={"xl"}
      >
        <FormPayOnLine onclose={onClosePay} />
      </ModalGeneric>

      <Title style={{ marginTop: 30 }}>Ventas de servicios</Title>
      <ModalGeneric
        isOpen={isOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
      >
        <FormSearch
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={calculateNodeList}
        selectNodeList={salesOfServiceNodeInputFields}
        enableAdvance={true}
      />

      {/*    <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
      // size={"4xl"}
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeftTreasury}
          listRight={
            authUser.is_qualified_user === 1
              ? arrayRigthUserTreasury
              : arrayRigthTreasury
          }
          dafaulActive={[]}
          handleSubmit={handleSubmit}
        />
      </ModalGeneric> */}
      <div className="container p-4">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getRecharges"}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />
        <ExportDetail onOpen={onOpenExport} />
      </div>

      <HeaderSearch>
        <Buttons
          onOpenRecharge={onOpenRecharge}
          onOpenPercentage={onOpenPercentage}
          onOpenPay={onOpenPay}
        />

        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div className="px-5">
                <div>
                  <TableOrder
                    thead={TitleSales}
                    data={currentPageTwo}
                    setData={setCurrentPageTwo}
                    loading={loading}
                    tbodyData={TbodySalesOfService}
                  />

                  <Pagination
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    loading={loading}
                  />
                </div>
              </div>
            )
          )
          : (
            <div>
              <TableOrder
                thead={TitleSales}
                data={currentPage}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodySalesOfService}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default SalesOfService;
