import React, { useCallback, useContext, useEffect, useState } from 'react'
// import Pagination from "components/Pagination/Pagination";
// import TableOrder from "components/Tables/TableOrder";
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { TitleUpdate } from '../ArrayList/ArrayList'
import ButtonsUpdate from '../Buttons/ButtonsUpdate'
import { useModal } from 'hooks/useModal'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import FormUpdateRate from './UpdateRate'
import { useSelector } from 'react-redux'
// import { __GetTreasury } from "request/Treasury/__Treasury";
import TBodyUpdateRate from '../Tables/TBodyUpdateRate'
import { HeaderForm } from 'components/HeaderForm/HeaderForm'
import TreasuryCrudContex from 'context/TreasuryContex'
import environment from 'constants/apiConst'
import UpdateView from './View/UpdateView'
import { __PostForm } from 'request/Petitions/__Post'
import { AuthContext } from 'context/AuthContext'
// import TarifUser from "./View/TarifUser";
import AcordionComponent from './View/AcordionComponent'
// import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";

const Update = () => {
  const [currentPage, setCurrentPage] = useState([])
  const [currentPageFreeZone, setCurrentPageFreeZone] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [isOpenUpdate, onOpenUpdate, onCloseUpdate] = useModal()
  const { token, freeZone_id } = useSelector(state => state.auth.dataUser)
  const {
    update,
    onCloseModal,
    isOpenViewUpdate,
    dataIdShow,
    statusRequest,
    createData
  } = useContext(TreasuryCrudContex)
  const [currentRateHistory, setCurrentRateHistory] = useState({})

  const getUpdateRates = useCallback(async () => {
    setLoading(true)
    try {
      const res = await __PostForm(environment.TREASURY_UPDATE_RATE_GET, token)
      const response = res?.data?.status?.data
      setCurrentPage(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [token, freeZone_id])

  useEffect(() => {
    getUpdateRates()
  }, [getUpdateRates, update, freeZone_id])

  useEffect(() => {
    const { year, id } = currentRateHistory
    if (year || id) getUpdateRatesHistory(year, id)
  }, [createData, freeZone_id])

  const changePage = year => {
    setPage(1)
    if (year) {
      getUpdateRatesHistory(year)
    }
  }

  const getUpdateRatesHistory = useCallback(
    async (year, id) => {
      const newDataRecord = new FormData()
      newDataRecord.append('idDetail', year)
      newDataRecord.append('free_zone_id', id)
      setLoading(true)
      try {
        const res = await __PostForm(
          environment.TREASURY_UPDATE_RATE_GET,
          token,
          newDataRecord
        )
        const responseFreeZone = res?.data?.status?.data
        if (res?.data?.status.code === 200) {
          setCurrentRateHistory({ year, id })
          setCurrentPageFreeZone(responseFreeZone)
        } else {
          setCurrentPageFreeZone([])
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    },
    [token, freeZone_id]
  )

  return (
    <>
      <HeaderSearch>
        <HeaderForm status={''} Modulo={`Actualización de tarifas`} />
      </HeaderSearch>

      <ModalGeneric
        isOpen={isOpenUpdate}
        onClose={onCloseUpdate}
        title={'Crear tarifas'}
        size={'3xl'}
      >
        <FormUpdateRate onClose={onCloseUpdate} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenViewUpdate}
        onClose={onCloseModal}
        title={`Tarifa ${dataIdShow?.name_type_rate}`}
        size={'6xl'}
      >
        <UpdateView onClose={onCloseModal} />
      </ModalGeneric>

      <HeaderSearch>
        <ButtonsUpdate onOpenUpdate={onOpenUpdate} />

        {/* {authUser.is_commerce === 1 ? (
          <div className="mt-3">
            <TarifUser currentPageFreeZone={currentPageFreeZone} />
          </div>
        ) : ( */}
        <>
          <div className='mt-3'>
            <AcordionComponent
              cabecera={currentPage}
              changePage={changePage}
              getDataTable={getUpdateRatesHistory}
              currentPage={currentPageFreeZone}
              TitleHeader={TitleUpdate}
              setCurrentPage={setCurrentPage}
              loading={loading}
              TBodyEntryMovement={TBodyUpdateRate}
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              stateNull={true}
              nameModule={'Tarifas'}
            ></AcordionComponent>
          </div>
        </>
        {/* )} */}
      </HeaderSearch>
    </>
  )
}

export default Update
