import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import { Cancelar } from "pages/RefProductInter/Stilos";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __EditCreateIntegrationBox } from "request/IntegrationBox/___IntegrationBox";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { isValid } from "utils/parseFloat";
import { isArray } from "utils/type-check-utils";

const FormIntegrationBox = ({ onClose }) => {
  const [datauserQualified, setDataUserQuialified] = useState([]);
  const [userQualified, setUserQualified] = useState(null);
  const [certificateSelect, setCertificateSelect] = useState(null);
  const navigate = useNavigate();

  const { requestData } = useGlobalContext();
  const { data: dataUC, isLoading: loadingUC } = useQuery({
    queryKey: [queryEnv?.QUALIFIED_USERS],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_QUALIFIED_USERS_ALL,
      }),
  });

  useEffect(() => {
    if (dataUC?.data?.status?.code !== 200 && !dataUC?.data?.status?.data)
      return;
    const dataUser = dataUC?.data?.status?.data.map((items) => ({
      value: items?.id,
      label: items?.nit_qualified
        ? items?.nit_qualified + " - " + items?.name_qualified
        : "",
    }));
    setDataUserQuialified(dataUser);
  }, [setDataUserQuialified, dataUC]);

  const createBox = () => {
    navigate(
      `/IntegracionBox?qualifiedUser=${userQualified.label}&qualifiedUserID=${userQualified.value}&typeCertificateL=${certificateSelect?.label}&typeCertificateV=${certificateSelect?.value}`
    );
    onClose();
    /*  const formData = new FormData();
    formData.append("qualifiedUser_id", userQualified.value);

    try {
      const resultado = await __EditCreateIntegrationBox(
        environment.CREATE_INTEGRATION_BOX,
        token,
        formData
      );
      if (resultado.data.status.code === 200) {
        const numberFormIn = resultado.data.status.data.cetificate;
        const BalanceOrder = resultado.data.status.data?.balance;
        navigate(`/IntegracionBox?id=${numberFormIn?.id}`);
        localStorage.setItem("ValueBalance", JSON.stringify(BalanceOrder));
        dispacthRedux(setDataStatusBalance(!balance));
        onClose();
      } else {
        openAlert(resultado.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    } */
  };

  const closeInfo = () => {
    onClose();
    navigate("/", {
      replace: true,
    });
  };

  const { data: dataCertificate, isPending: isLoadingDataCertificate } =
    useQuery({
      queryKey: [queryEnv?.GET_CERTIFICATE_TYPE],
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_CERTIFICATE_TYPE,
          PropertyBody: "get",
        }),
    });

  const dataCertificateForSelect =
    isValid(dataCertificate?.data?.status?.data) &&
    isArray(dataCertificate?.data?.status?.data) &&
    !isLoadingDataCertificate
      ? dataCertificate?.data?.status?.data.map((items) => ({
          value: items?.id,
          label: items?.integration_certificate_name,
          //decimal: items.decimal_values_precision,
        }))
      : [];

  useEffect(() => {
    console.log("dataCertificate", dataCertificate?.data?.status?.data);
  }, [dataCertificate?.data?.status?.data]);

  return (
    <>
      <Text fontSize="md" mb={4} style={{ fontWeight: 600 }}>
        Formulario de certificado de integración
      </Text>
      <Divider my={2} />
      <Box display={"flex"} flexDir={"row"} gap={3}>
        <InputSelect
          tag={"Empresa"}
          data={datauserQualified}
          set={setUserQualified}
          value={userQualified}
          isLoading={loadingUC}
          size={100}
        />

        <InputSelect
          tag={"Tipo de certificado"}
          size={100}
          data={dataCertificateForSelect}
          set={setCertificateSelect}
          value={certificateSelect}
          isLoading={isLoadingDataCertificate}
        />
      </Box>

      <Flex className="my-3">
        <Cancelar onClick={closeInfo}>Cancelar</Cancelar>
        <Spacer />

        <Button
          onClick={createBox}
          bg="#03C39A"
          color="#fff"
          _hover={{ bg: "#07876b" }}
          width="40%"
          height="40px"
        >
          Formulario
        </Button>
      </Flex>
    </>
  );
};

export default FormIntegrationBox;
