/* eslint-disable react-hooks/exhaustive-deps */
import { Estadistica } from "components/Estadistica/Estadistica";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { __ObtenerEstadisticas } from "request/Estadisticas/__Estadisticas";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { EstadisticaItem } from "./EstadisticaItem";
import { SubTitle } from "styled/Title";
import { InputSelectMulti } from "components/InputSelect/InputSelect";
import useUserQualified from "hooks/useUserQualified";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { BiFilter } from "react-icons/bi";
import { CgImport } from "react-icons/cg";
import { VscFilePdf } from "react-icons/vsc";
import environment from "constants/apiConst";
import { __PostJsonData } from "request/Petitions/__Post";
import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";

export const EstadisticasGeneral = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [currentPage, setCurrentPage] = useState(null);
  const [filterEst, setfilterEst] = useState(false);
  const { userQualified } = useUserQualified();
  const [selectUserQualified, setSelectUserQualified] = useState(null);
  const [values, handleInputChange] = useForm({
    date_1: "",
    date_2: ""
  });

  const {
    date_1,
    date_2
  } = values;

  const Obtener = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        const res = await __ObtenerEstadisticas(token, formData);
        const { data: { status: { code, data } } } = res;
        if (code === 200) {
          setCurrentPage(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const varForMap = ["form_revised", "form_approved", "form_returned"];
  return (
    <React.Fragment>
      <HeaderEstadistica
        title={'Estadísticas generales'}
        enableQualifiedUser
        // generar64={dowmLoadPdfImgae}
        enableRendering
        excelPath={environment.POST_EXPORT_EXCEL_OP_ENTRY_OUT}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS}
      />
      {loading && <SpinnerComponent />}
      {
        !loading && (
          <div className="row mt-2">
            <div className="col-6">
              <Estadistica
                title={"Formularios"}
                data={currentPage}
                loading={loading}
                varForMap={varForMap}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-6">
              <EstadisticaItem
                title={"Items"}
                data={currentPage}
                loading={loading}
                varForMap={varForMap}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )
      }
    </React.Fragment >
  );
};
