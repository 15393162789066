import { Tooltip } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useState } from "react";
import { GiWeightScale } from "react-icons/gi";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";

export const GetWeihingButton = ({
  loading,
  setLoading,
  setValues,
  values,
  executeFunction
}) => {
  const [openAlert] = AlertErrorAlert();

  const GetWeihing = (setLoading, setValues, values) => {
    const scaleId = localStorage.getItem("chanelWeighing");

    if (scaleId) {
      setLoading(true);
      window.Echo.channel("scale-" + scaleId).listen(".new-weighing", (e) => {
        setValues({ ...values, weight: e.weight });
        window.Echo.leave("scale-" + scaleId); // desasociar al canal
        setLoading(false);
      });
    } else {
      openAlert("Seleccione una báscula", "error");
    }
  };

  return (
    <>
      {loading && <SpinnerComponent size="auto" />}
      {!loading && (
        <Tooltip placement="top" hasArrow label="Obtener peso">
          <ButtonHeaderModule
            onClick={() => {
              GetWeihing(setLoading, setValues, values);
              if (executeFunction) {
                executeFunction();
              }
            }}
            //  bgColor="#03C39A"
            className="mt-4"
          >
            <IconGeneric color="#ffff" as={GiWeightScale} />
          </ButtonHeaderModule>
        </Tooltip>
      )}
    </>
  );
};
