import { Td, Tooltip, Tr } from '@chakra-ui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import Pagination from 'components/Pagination/Pagination'
import TableOrder from 'components/Tables/TableOrder'
import environment from 'constants/apiConst'
import queryEnv from 'constants/queryConst'
import { useGlobalContext } from 'context/GlobalContext'
import ConfigurationHeader from 'pages/Configuration/ConfigurationHeader'
import { ConfigIconStatus } from 'pages/Configuration/tables/components/LoadingConfig'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { __GetDataId } from 'request/Petitions/__Get'
import { __Post } from 'request/Petitions/__Post'
import styled from 'styled-components'
import { SectionContainerGene } from 'styled/SectionContainer.styled'

const tHead = [
  { name: "Usuario", order: "user" },
  { name: "Correo", order: "email" },
  { name: "Nombre", order: "name_person" },
  { name: "Apellido", order: "lastname_person" },
  { name: "Pesaje manual", order: "status" },
];

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

export const TbodyUserWeighing = ({ data, actionsNone}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(data?.weighing_manual);	

  const handleState = async () => {
    setIsLoading(true);
    setIsActive(data?.weighing_manual === 1 ? 0 : 1);
    try {
      const res = await __Post(environment.ACTIVE_AND_DESACTIVE_WEIGHING_MANUAL, token, {id: data?.id});
      
      queryClient.invalidateQueries({ queryKey: [queryEnv.USERS_WEIGHING] });
    } catch (error) {
      console.log(error);
      setIsActive(data?.weighing_manual);
      openAlert("El servidor esta presentando problemas, por favor intente mas tarde", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {" "}
      <Tr>
        <Td className="text-center">{data.nickname}</Td>
        <Td className="text-center">{data.email}</Td>
        <Td className="text-center">{data.name_person}</Td>
        <Td className="text-center">{data.lastname_person}</Td>
        <Tooltip
          placement="top"
          hasArrow
          label={data?.weighing_manual === 1 ? "Desactivar" : "Activar"}
        >
          <TdState
            onClick={isLoading ? null : handleState}
            className="text-center"
            isNumeric
            text={isActive}
          >
            <ConfigIconStatus
              status={isActive}
            />
          </TdState>
        </Tooltip>
      </Tr>
    </>
  );
};

const UsersWeighing = () => {
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const { requestData } = useGlobalContext();

  const {
    data: dataUsersWeighing,
    isLoading: isLoadingUsersWeighing,
  } = useQuery({
    queryKey: [queryEnv.USERS_WEIGHING, page, q, freeZone_id],
    queryFn: async () => {
      const res = await requestData({
        Endpoint: environment.GET_USERS_WEIGHING,
        pageParam: page,
        ...(q?.length !== 0 && { data: { search: q } }),
        PropertyBody: "sendJSONContentPOST"
      })

      if (res?.data?.status?.code !== 200) return;
      if (res?.data?.status?.data?.last_page < page) setPage(1);
      return res
    }
  })

  useEffect(() => {
    setPage(1);
  }, [freeZone_id]);

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader
          title={"Usuarios Bascula"}
          aggregator={false}
        ></ConfigurationHeader>
        {isLoadingUsersWeighing || (
          <TableOrder
            thead={tHead}
            data={dataUsersWeighing?.data?.status?.code === 200 ? dataUsersWeighing?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoadingUsersWeighing}
            tbodyData={TbodyUserWeighing}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataUsersWeighing?.data?.status?.code === 200 ? dataUsersWeighing?.data?.status?.data?.last_page : 1}
          loading={isLoadingUsersWeighing}
        />
      </SectionContainerGene>

    </>
  )
}

export default UsersWeighing
