import React from "react";
import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";
import { isObject } from "utils/type-check-utils";

/**
 * RadioBtn - Radio Boton de seleccion unica complementando el formulario.
 * @param {Function} setOnly Estado donde capturamos la seleccion del usuario.
 * @param {Object} options Objeto de las opciones a retornar para que el usuario pueda elegir.
 * @return {Component} Retorna seleccion de una opcion por el usuario.
 */
export const RadioBtn = ({ setOnly, options, state, ...props }) => {
  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            bg: "blue.600",
            color: "white",
            borderColor: "blue.600"
          }}
          _focus={{
            boxShadow: "outline"
          }}
          px={1}
          py={1}
          {...props}
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: `${state}`,
    onChange: setOnly,
    ...props
  });

  const groupOnly = getRootProps();

  return (
    <HStack {...groupOnly}>
      {options.map((option) => {
        const value = isObject(option) ? option?.value : option;
        const label = option?.label || option;
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio} {...props} isChecked={value === state}>
            {label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
