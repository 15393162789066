import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Button = styled.button`
  color: #ffff;
  padding: 0.2rem;
  width: ${(props) => (props.width ? props.width : "170px")};
  height: ${(props) =>
    props.height ? props.height : props.docForm ? "30px" : "auto"};
  border-radius: 5px;
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "#1f7ac3";

      case "orange":
        return "#F9A621";

      case "aqua":
        return "#03C39A";

      case "green":
        return "#048090";

      case "red":
        return "#BA1F33";

      default:
        return "#1f7ac3";
    }
  }};
`;

export const Input = styled.input`
  color: #ffff;
  padding: 0.2rem;
  width: 170px;
  height: ${(props) => (props.docForm ? "30px" : "auto")};
  border-radius: 5px;
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "rgb(31, 122, 195)";

      case "orange":
        return "#F9A621";

      case "aqua":
        return "#03C39A";

      case "green":
        return "#048090";

      case "red":
        return "#BA1F33";

      default:
        return "#1f7ac3";
    }
  }};
`;

export const Desasociar = styled.button`
  background-color: #1f7ac3;
  color: #ffff;
  width: 20%;
  height: 40px;
  border-radius: 5px;
  &:hover {
    background-color: #51a5eb;
  }
`;

export const H2 = styled.h1`
  font-size: 1rem;
  font-weight: 400;
`;

export const ButtonItemsForm = styled.button`
  align-items: center;
  text-align: center;
  color: #ffff;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "rgb(31, 122, 195)";

      case "orange":
        return "#F9A621";

      case "aqua":
        return "#03C39A";

      case "green":
        return "#048090";

      case "red":
        return "#BA1F33";
      case "grisaso":
        return "#BABABA";

      default:
        return "#1f7ac3";
    }
  }};
`;
