import { Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { CgPlayListAdd } from "react-icons/cg";

/**
 * ButtonsBlocking- Componente de Botones y encabezado de la tabla de bloqueos
 *
 * @component
 * @param {Function}  onOpenBlocking  funcion para cerrar el modal de creacion de bloqueos.
 * @return {Component} Retorna componente de boton de tabla de bloqueo.
 *
 */

const ButtonsBlocking = ({ onOpenBlocking }) => {
  return (
    <div className="row">
      <div className="col-4 ">
        <Text className="ms-2" fontSize="xl" style={{ fontWeight: 600 }}>
          Recientes
        </Text>
      </div>
      <div className="col-4 "></div>
      <div className="col-4">
        <div className="d-flex float-end  me-4 ">
          <Tooltip placement="top" hasArrow label="Bloquear nuevo usuario">
            <span>
              <ButtonHeaderModule
                onClick={onOpenBlocking}
                bgColor="#F9A621"
                className="d-flex float-end"
              >
                <IconGeneric color="#fff" as={CgPlayListAdd} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ButtonsBlocking;
