import React, { useEffect, useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable } from "react-table";
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";
import { Table, Thead, Tr } from "@chakra-ui/react";
import { ThStyledBlue } from "../Table.styled";
import { ButtonStyled } from "styled/Buttons.styled";

export function TableDraggable ({
  columns,
  data,
  setData,
  activeButton,
  funtionMove
}) {
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  const [equalId, setEqualId] = useState(false);

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart (event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd (event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel () {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  const handleSubmit = () => {
    const arreglo = [];
    rows.map(({ original }) => arreglo.push(original));
    const data = {
      queue: arreglo
    };
    localStorage.setItem("idDocuments", JSON.stringify(arreglo));
    funtionMove(data);
  };

  useEffect(() => {
    const documentsCurrent = JSON.parse(localStorage.getItem("idDocuments"));
    if (JSON.stringify(documentsCurrent) === JSON.stringify(items)) {
      setEqualId(false);
    } else if (JSON.stringify(documentsCurrent) !== JSON.stringify(items)) {
      setEqualId(true);
    } else {
      setEqualId(false);
    }
  }, [items]);

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <Table
        size="md"
        variant="simple"
        className="table table-striped table-sm "
        overflowX={true}
        {...getTableProps()}
      >
        <Thead>
          {headerGroups.slice(1, 2).map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ThStyledBlue
                  {...column.getHeaderProps()}
                  className="text-center"
                >
                  {column.render("Header")}
                </ThStyledBlue>
              ))}
            </Tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {rows.map((row, i) => {
              prepareRow(row);
              return <DraggableTableRow key={row?.original.id} row={row} />;
            })}
          </SortableContext>
        </tbody>
      </Table>
      <DragOverlay>
        {activeId && (
          <table style={{ width: "100%" }}>
            <tbody>
              <StaticTableRow row={selectedRow} />
            </tbody>
          </table>
        )}
      </DragOverlay>
      {activeButton && equalId
        ? (
          <ButtonStyled width={"100px"} type="submit" onClick={handleSubmit}>
          Aceptar
          </ButtonStyled>
        )
        : (
          ""
        )}
    </DndContext>
  );
}
