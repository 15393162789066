/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowDetiny } from "request/configuration/__Destiny";

const FormDestinyShow = () => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialForm = {
    code: "",
    destiny: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const initialpos = async () => {
    try {
      const res = await __ShowDetiny(token, dataToShow.id);
      setForm(res?.data?.status?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToShow) {
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  return (
    <>
      <Text
        fontSize="xl"
        my={2}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        Destino
      </Text>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Código</LabeFormlStyled>
              <InputFake>{form?.code}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Destino</LabeFormlStyled>
              <InputFake>
                {form?.destiny === null ? "N/A" : form.destiny}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormDestinyShow;
