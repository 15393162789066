import axios from "axios";
import env from "../../constants/apiConst";

export const __ShowGroup = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GROUP_SHOW}/${id}`, options);
  return res;
};
