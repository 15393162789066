export const dataNat = [
  { value: "NAC", label: "NAC" },
  { value: "EXT", label: "EXT" },
  { value: "CDI", label: "CDI" }
];

export const dataNatProduct = [
  { value: "NAC", label: "NAC" },
  { value: "EXT", label: "EXT" },
  { value: "DCL", label: "DCL" },
  { value: "DPD", label: "DPD" }
];

export const ordenarPor = [
  { value: "reference_description", label: "Referencia" },
  { value: "integration_certificate", label: "Certificado de integración" },
  { value: "customs_clearance", label: "Declaración de importación" },
  { value: "operation_code", label: "Código de operación" },
  { value: "initial_operation", label: "Operación inicial" },
  { value: "trade_agreement", label: "Acuerdo comercial" },
  { value: "subheading_code", label: "Subpartida" },
  { value: "business_unit_code", label: "Unidad comercial" },
  { value: "nature_of_inventory", label: "Naturaleza" }
];

export const typeQuantity = [
  { value: "quantity_total", label: "Cantidad total" },
  { value: "quantity_available", label: "Cantidad disponible" },
  { value: "quantity_go_out", label: "Cantidad autorizada por salir" },
  { value: "quantity_temporary", label: "Cantidad en proceso temporal" },
  { value: "quantity_free_zone", label: "Cantidad en bodega" },
  { value: "quantity_reserved", label: "Cantidad reservada temporal" }
];
