export const theadEntry = [
  { name: "Descripción", order: null },
  { name: "Unidad", order: null }
];

export const theadOutput = [
  { name: "Descripción", order: null },
  { name: "Cantidad despacho", order: null },
  { name: "Unidad", order: null }
];

