import React from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { NumericFormat } from "react-number-format";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyZoneF = ({ data }) => {
  return (
    <Tr>
      <Td>{data?.name_freeZone}</Td>
      <Td>{data?.shortName_freeZone}</Td>
      <Td>{data?.name_typeFreeZone}</Td>
      <Td className="text-center">
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          displayType="text"
          decimalSeparator="."
          value={(data?.max_value_cop === null || data?.max_value_cop === undefined) ? 20000000 : data?.max_value_cop}
        />
      </Td>
      <TdState className="text-center" text={data?.show_pdf_user}>
        <IconGeneric
          color={data.show_pdf_user === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.copy_movements}>
        <IconGeneric
          color={data.copy_movements === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.change_subheading}>
        <IconGeneric
          color={data.change_subheading === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.dian_stamp}>
        <IconGeneric
          color={data.dian_stamp === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.detailed_review}>
        <IconGeneric
          color={data.detailed_review === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.can_add_attachments}>
        <IconGeneric
          color={data.can_add_attachments === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.drive_vehicle_door}>
        <IconGeneric
          color={data.drive_vehicle_door === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.special_subheading}>
        <IconGeneric
          color={data.special_subheading === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.approval_lock}>
        <IconGeneric
          color={data.approval_lock === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.validate_partial_processing}>
        <IconGeneric
          color={data.validate_partial_processing === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.warn_subheadings}>
        <IconGeneric
          color={data.warn_subheadings === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.temporary_decl}>
        <IconGeneric
          color={data.temporary_decl === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.validate_reposition_outputs}>
        <IconGeneric
          color={data.validate_reposition_outputs === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <TdState className="text-center" text={data?.export_detailed_documents}>
        <IconGeneric
          color={data.export_detailed_documents === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.use_dian_profiling}>
        <IconGeneric
          color={data.use_dian_profiling === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <TdState className="text-center" text={data?.status_freeZone}>
        <IconGeneric
          color={data.status_freeZone === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <TdState className="text-center" text={data?.not_require_entry_pass}>
        <IconGeneric
          color={data?.not_require_entry_pass === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <TdState className="text-center" text={data?.not_require_exit_pass}>
        <IconGeneric
          color={data?.not_require_exit_pass === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <TdState className="text-center" text={data?.port}>
        <IconGeneric
          color={data?.port === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>

      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyZoneF;
