import React, { useContext, useState } from 'react'
import { Td, Tr } from '@chakra-ui/react'
import MenuForm from 'components/Menu/MenuForm'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'
import { IconGeneric } from 'styled/Icons.styled'

const TbodyFormExit = ({ data }) => {
  const { setDataToShow } = useContext(FormCrudCRUDContext)
  const navigate = useNavigate()
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const [openMenu, setOpenMenu] = useState(true);

  const handleShow = () => {
    setDataToShow(data)
    navigate(`/FormShowExit?ExitNumber=${data.id}`)
  }
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const checkIfItIsBlocked = async () => {
    if (is_qualified_user) return;

    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", "FMMS");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      setOpenMenu(true)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
      setOpenMenu(false)
      setTimeout(() => setOpenMenu(true), 500)
    }
  }

  const redirectDocument = () => {
    const {entryFormCorrected_id, final_balance_adjustment} = data || {}
    navigate(`/FormCorrection?ShowEntry=${entryFormCorrected_id}`);
  }


  return (
    <Tr>
      <Td onClick={handleShow} className='text-center'>
        <NavLink to={`/FormShowExit?ExitNumber=${data.id}`} className='ms-2'>
          <span className='formNumber'>{data?.code_outputForm}</span>
        </NavLink>
      </Td>
      <Td className='text-center'>{data?.name_qualified}</Td>
      <Td className='text-center'>{data?.code_operation}</Td>
      <Td className='text-center'>{data?.business_name}</Td>
      <Td className='text-center'>{data?.commercial_invoice}</Td>
      <Td className='text-center'>{data?.countItems}</Td>
      <Td className='text-center'>{data?.status_outputForm}</Td>
    {/*   <Td className="text-center">
        { data?.final_balance_adjustment === 1 &&  <IconGeneric
              color={"#1F7AC3"}
              as={BsEyeFill}
              onClick={redirectDocument}
            />}

        { data?.final_balance_adjustment === 0 &&  <IconGeneric
              color={"#BABABA"}
              as={BsEyeSlashFill}
              onClick={redirectDocument}
            />}
          </Td> */}
      {data?.status_outputForm === "PRESENTADO"
        ? (
          <Td className="text-center" onClick={() => checkIfItIsBlocked()}>
            {openMenu && <MenuForm data={data} />}
          </Td>
        )
        : (
          <Td className='text-center'>
            <MenuForm data={data} />
          </Td>)}
    </Tr>
  )
}

export default TbodyFormExit
