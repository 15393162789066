import { Box, Tooltip, useDisclosure } from '@chakra-ui/react'
import { QrForms } from 'pages/Entry_and_exit_forms/QrForms/QrForms'
import { OptionsForm } from 'pages/Entry_and_exit_forms/View/components/OptionsForm'
import { ModalSearch } from 'pages/Pass-Form/View/BusquedaAvanzada/ModalSearch'
import { MdQrCodeScanner } from 'react-icons/md'
import { ButtonForAction } from './ButtonForAction'

import { RiEdit2Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { AiOutlineFileDone, AiOutlineFileSync } from 'react-icons/ai'
import { FaPrescriptionBottle, FaUnlock } from 'react-icons/fa'
import { useContext, useEffect } from 'react'
import GlobalContext from 'context/GlobalContext'
import environment from 'constants/apiConst'
import { useMutation } from '@tanstack/react-query'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { ReasonForReturnComponent } from './ReasonForReturnComponent'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import { AuthContext } from 'context/AuthContext'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import ModalQR from 'components/modal/ModalQR'

const ActionButtonsInView = ({
  data,
  propetyStatus,
  Actions,
  children,
  ExportPdf
}) => {
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate()
  const { authUser } = useContext(AuthContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenReturnReason,
    onOpen: onOpenReturnReason,
    onClose: onCloseReturnReason
  } = useDisclosure()
  const token = useSelector(state => state.auth.dataUser.token)

  const Status = data?.[propetyStatus]

  const { performStatusChange } = useContext(GlobalContext)

  const { mutate } = useMutation({
    mutationFn: performStatusChange,
    retry: 3,
    onSuccess: data => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, 'error')
      }

      if (data?.data?.status?.code === 200) {
        navigate(-1)
        openAlert(data?.data?.status?.message, 'success')
      }
    }
  })

  const changeStatus = async (id, state) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('status_dispatchPassCorrected', state)

    mutate({
      data: formData,
      Endpoint: environment.CHANGE_DISPATCH_STATUS_CORRECTED,
      PropertyBody: 'sendJSONContentPOST'
    })
  }

  const actionDeleteAndReturn = [
    {
      Icon: RiEdit2Fill,
      name: 'Editar',
      colorIcon: '#1F7AC3',
      Action: async () => {
        const body = new FormData()
        body.append('form_id', data?.id)
        body.append('type_of', 'CORREGIDO_DESPACHO')
        body.append('type', 1)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        if (res?.data?.status?.code === 200) {
          return navigate(
            `/DispathcCorrectionShow?Dispatch=${data.id}&Action=Edit`
          )
        }
        if (res?.data?.status?.code === 400) {
          return openAlert(res?.data?.status?.message, 'error')
        }
      }
    },
    {
      Icon: AiOutlineFileDone,
      name: 'Presentar',
      colorIcon: '#03C39A',
      Action: async () => {
        const body = new FormData()
        body.append('form_id', data?.id)
        body.append('type_of', 'CORREGIDO_DESPACHO')
        body.append('type', 1)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        if (res?.data?.status?.code === 200) {
          setTimeout(async () => {
            body.set('type', 0)
            const res = await __Post(environment.FORMS_ON_EDIT, token, body)
          }, 5000)
          return changeStatus(data.id, 'PRESENTADO')
        }
        if (res?.data?.status?.code === 400) {
          return openAlert(res?.data?.status?.message, 'error')
        }
      }
    }
  ]

  const unlockForm = () => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'CORREGIDO_DESPACHO')
    body.append('type', 0)
    const res = __Post(environment.FORMS_ON_REVIEW, token, body)
  }

  const actionPresent = [
    {
      Icon: AiOutlineFileSync,
      name: 'Radicar',
      colorIcon: '#1F7AC3',
      Action: () => {
        unlockForm()
        changeStatus(data.id, 'AUTORIZADO')
      }
    },
    {
      Icon: AiOutlineFileSync,
      name: 'Devolver',
      colorIcon: '#BA1F33',
      Action: () => onOpenReturnReason()
    }
  ]

  const ActionsTemplate = {
    BORRADOR: [],
    DEVUELTO: [],
    AUTORIZADO: [
      {
        Icon: AiOutlineFileSync,
        name: 'Devolver',
        colorIcon: '#BA1F33',
        Action: () => onOpenReturnReason()
      }
    ],
    PRESENTADO: []
  }

  ActionsTemplate.DEVUELTO = [...actionDeleteAndReturn]
  ActionsTemplate.BORRADOR = [...actionDeleteAndReturn]

  if (!authUser?.is_qualified_user) { ActionsTemplate.PRESENTADO = [...actionPresent] }

  const ReturnCorrent = () => {
    navigate(-1)
  }

  const propsBlockForm = {
    form_id: data?.id,
    type_of: "CORREGIDO_DESPACHO",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW
  }

  return (
    <>

      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={'Código QR'}
        size={'md'}
      />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >
        <ReasonForReturnComponent
          onClose={onCloseReturnReason}
          id={data?.id}
          PropertyReasonForReturn={'reason_for_return_corrected'}
          PropertyStatus={'status_dispatchPassCorrected'}
          serviceReturn={environment.CHANGE_DISPATCH_STATUS_CORRECTED}
          callBack={ReturnCorrent}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <div className='row'>
        {Actions === 'Show' && (
          <div className='col'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              className='mt-4'
              gap={2}
            >

              {(data?.status_dispatchPassCorrected === "PRESENTADO" && authUser.is_qualified_user !== 1) &&
              <Tooltip placement="top" hasArrow label="Desbloquear">
                <span>
                  <ButtonHeaderModule
                    onClick={() => {
                      unlockForm()
                      openAlert("Se ha desbloqueado con exito", "success")
                      navigate(-1)
                    }}
                    className="mx-1"
                    bgColor="#fe8d59"
                  >
                    <IconGeneric
                      className="text-white"
                      color="#fff"
                      width="16px"
                      as={FaUnlock}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>}

              {ActionsTemplate?.[Status]?.map(
                ({ Icon, name, colorIcon, Action }) => {
                  return (
                    <ButtonForAction
                      icon={Icon}
                      tooltip={name}
                      colorIcon={colorIcon}
                      onClick={Action}
                    />
                  )
                }
              )}
            </Box>
          </div>
        )}

        {children}
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            <OptionsForm data={data} />

            <ButtonForAction
              icon={MdQrCodeScanner}
              tooltip='Qr'
              colorIcon='#1F7AC3'
              onClick={onOpen}
            />

            {ExportPdf && ExportPdf}
          </Box>
        </div>
      </div>
    </>
  )
}

export default ActionButtonsInView
