export const MaxLength = {
  global: {
    code_subheading: 10
  },
  config: {
    operations: {
      name_operation: 5000,
      short_name_operation: 80,
      objetive: 5000,
      term: 4
    },
    vehicularDoor: {
      name_vehicularDoor: 80
    },
    roles: {
      name_rol: 150,
      name: 150,
      description_rol: 5000,
      guard_name: 191
    },
    permissionsRule: {
      name_permission: 150,
      description_permission: 5000
    },
    countries: {
      code_country: 3,
      name_country: 150,
      market: 150,
      origin_country: 150,
      destiny_country: 150
    },
    port: {
      port_name: 150
    },
    subheading: {
      code_subheading: 10,
      assessment: 80,
      description: 5000,
      subchapter: 5000
    },
    sectors: {
      name_sector: 100
    },
    typificationReasonReturn: {
      name: 50,
      descriptions: 250
    },
    typeOperation: {
      name_typeOperation: 150,
      description_typeOperation: 5000
    },
    typeTrans: {
      name_typeTransport: 80,
      description_typeTransport: 5000
    },
    typeZfb: {
      name_typeFreeZone: 80,
      description_typeFreeZone: 5000
    },
    typesDocuments: {
      document: 70,
      description: 5000,
      annexe: 70,
      annexe_description: 5000
    },
    typesIdentifications: {
      code_identification_type: 2,
      name_identification_type: 70
    },
    typeProducts: {
      name_typeProduct: 100,
      description_typeProduct: 5000
    },
    typeRate: {
      name_type_rate: 80,
      description_type_rate: 5000
    },
    users: {
      nit_qualified: 20,
      name_qualified: 50,
      nickname_qualified: 50,
      address_qualified: 150,
      email_qualified: 100,
      phone_1_qualified: 15,
      phone_2_qualified: 15,
      phone_3_qualified: 15,
      fax_qualified: 15,
      representative: 100,
      manager: 100,
      phone_manager: 15,
      email_manager: 100
    },
    businessUnits: {
      code_businessUnit: 3,
      name_businessUnit: 80
    }
  },
  ci: {
    ci: {
      observations: 5000
    }
  },
  productReference: {
    products: {
      code_product: 150,
      conversion_factor_product: 2,
      description_product: 5000,
      group_product: 80
    },
    productiveProcess: {
      code_productive_process: 50,
      description_productive_process: 5000
    },
    unitMeasurement: {
      code_unit_measurement: 30,
      unit_measurement: 30
    }
  },
  thirdParties: {
    business_name: 150,
    alias: 20,
    identification_num: 15,
    internal_code: 30,
    phone: 15,
    acronym: 20,
    address: 150
  },
  monitoring: {
    monitoring: {
      initial_description: 5000,
      final_description: 5000
    },
    followOrder: {
      description: 5000
    }
  },
  customClearance: {
    formCustomsClearance: {
      acceptance_number: 30,
      release_number: 30,
      code_subheading: 10,

      addItem: {
        reference: 50,
        description_product: 255,
        certificate: 10,
        import_declaration: 25,
        operation: 5,
        quantity_subtract: 10,
        equivalent_amount: 10,
        weight_kilograms: 8,
        US_value: 8
      }
    }
  },
  entry_movement: {
    form_int: {
      vehicle_plate: 7,
      identification: 15,
      driver: 80,
      phone: 15,
      container: 20,
      bultos: 8,
      observations: 5000
    }
  },
  formEntry: {
    formEntryNumber: {
      purchase_order: 50,
      num_transport_document: 17,
      cargo_manifest_entryForm: 30,
      num_commercial_invoice: 50,
      dcl_customs_transit: 20,
      num_dex_entryForm: 30,
      num_import_declaration: 30,
      inspection_act_or_auto: 50,
      exit_auth_entryForm: 80,
      integration_box_certificate: 50,
      num_exit_form: 10
    },
    addItemForm: {
      product_quantity: 34,
      quantity: 34,
      net_weight: 34,
      gross_weight: 34,
      number_packages: 34,
      us_freight: 34,
      us_insurance: 34,
      other_us_expenses: 34,
      us_fob_value: 34,
      cif_us_value: 34,
      cif_cop_value: 34,
      invoice: 70,
      brand: 70,
      serial: 70,
      entry_form_item_description: 5000
    }
  },
  formExit: {
    formExitNumber: {
      certificate_inspection_pre_boarding: 30,
      commercial_invoice: 30,
      value_duta: 50
    },
    addItemForm: {
      product_quantity: 34,
      quantity: 34,
      net_weight: 34,
      gross_weight: 34,
      number_packages: 34,
      us_freight: 34,
      us_insurance: 34,
      other_us_expenses: 34,
      us_fob_value: 34,
      cif_us_value: 34,
      cif_cop_value: 34,
      added_value_us: 34,
      refund_value_us: 34,
      invoice: 70,
      brand: 70,
      serial: 70,
      adjustment_justification_fob: 191,
      output_form_item_description: 5000
    }
  },
  security: {
    entriesVehicles: {
      driver: 120,
      identification: 15,
      description: 5000
    }
  },
  productionOrder: {
    code_productive_process: 150,
    total_product: 34
  },
  VentasDeServicios: {
    saleRefills: {
      invoice: 70,
      sale_value: 16,
      consumable_value: 16,
      observations: 5000
    }
  },
  blocking: {
    observations: 5000
  }
}

