import React from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import MenuTreasury from "./MenuTreasury";
import MenuOther from "./MenuOther";
import MenuUpdateRate from "./MenuUpdateRate";

const MenuTesoreria = ({ data }) => {
  const location = useLocation();

  const { movement = "" } = queryString.parse(location.search);
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>
      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {movement === "Usuarios"
          ? (
            <MenuTreasury data={data} />
          )
          : movement === "Actualización de tarifas"
            ? (
              <MenuUpdateRate data={data} />
            )
            : movement === "TarifasEspeciales"
              ? (
                <MenuUpdateRate data={data} />
              )
              : (
                <MenuOther data={data} />
              )}
      </MenuList>
    </Menu>
  );
};

export default MenuTesoreria;
