/* eslint-disable no-unused-vars */
import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { useEffect } from "react";
import { useState } from "react";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";

const TbodyType = ({ data }) => {
  const [emailCheck, setEmailCheck] = useState(0);
  const [mondayCheck, setMondayCheck] = useState(0);
  const [tuesdayCheck, setTuesdayCheck] = useState(0);
  const [wednesdayCheck, setWednesdayCheck] = useState(0);
  const [ThursdayCheck, setThursdayCheck] = useState(0);
  const [FridayCheck, setFridayCheck] = useState(0);
  const [SaturdayCheck, setSaturdayCheck] = useState(0);
  const [SundayCheck, setSundayCheck] = useState(0);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (data?.config !== null) {
      const {
        days,
        email_alert,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      } = data?.config;
      setValues({ ...values, days });
      setEmailCheck(email_alert);
      setMondayCheck(monday);
      setTuesdayCheck(tuesday);
      setWednesdayCheck(wednesday);
      setThursdayCheck(thursday);
      setFridayCheck(friday);
      setSaturdayCheck(saturday);
      setSundayCheck(sunday);
    }
  }, []);

  const [values, handleInputChange, reset, setValues] = useForm({
    days: "",
    id: ""
  });
  const { days } = values;

  // 10221

  //     updateConfig(data);

  const updateConfig = async (data) => {
    try {
      const res = await __Post(environment.UPDATE_ALERT, token, data);
      const restData = res?.data?.status;
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e, state) => {
    console.log({ e, state, data});
    updateConfig({ id: data?.config?.id || data.id, [e]: state });
  };

  return (
    <Tr>
      <Td>{data?.name}</Td>

      <Td>
        <SwitchPropiosMedios
          status={emailCheck}
          setStatus={setEmailCheck}
          name="email_alert"
          tag={""}
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <LabelGroup
          name={"days"}
          onChange={handleInputChange}
          value={days}
          onBlur={() => updateConfig({ id: data?.config?.id || data.id, days })}
          tag={""}
          size={100}
          desicion={false}
          id={data?.config?.id || data.id}
          maxLength={"3"}
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={mondayCheck}
          setStatus={setMondayCheck}
          tag={""}
          name="monday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={tuesdayCheck}
          setStatus={setTuesdayCheck}
          tag={""}
          name="tuesday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={wednesdayCheck}
          setStatus={setWednesdayCheck}
          tag={""}
          name="wednesday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={ThursdayCheck}
          setStatus={setThursdayCheck}
          tag={""}
          name="thursday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={FridayCheck}
          setStatus={setFridayCheck}
          tag={""}
          name="friday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={SaturdayCheck}
          setStatus={setSaturdayCheck}
          tag={""}
          name="saturday"
          handleUpdate={handleChange}
          margin
        />
      </Td>
      <Td className="text-center">
        <SwitchPropiosMedios
          status={SundayCheck}
          setStatus={setSundayCheck}
          name="sunday"
          handleUpdate={handleChange}
          tag={""}
          margin
        />
      </Td>
    </Tr>
  );
};

export default TbodyType;
