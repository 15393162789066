/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import SectionHeader from "components/SectionHeader/SectionHeader";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import TbodyGestionComercial from "./Tbody/TbodyGestionComercial";
import { SubHeadGestion } from "./subHead/SubHeadGestion";
import { __GestionCo } from "request/GestionComercial/__GestionCo";
import ModalTransition from "components/modal/ModalTransition";
import GestionCrudContext from "context/GestionCrudContext";
import ModalDelete from "components/modal/ModalDelete";
import environment from "constants/apiConst";
import { AddGestion } from "./form/AddGestion";
import FormShow from "./form/FormShow";
import { useSelector } from "react-redux";
import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebouce";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const formEditat = () => {
  return <AddGestion />;
};

const formSHOSE = () => {
  return <FormShow />;
};

export const GestionUsuarios = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [values, handleInputChange, reset] = useForm({
    search: ''
  });
  const debounced = useDebounce(values?.search, 700);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const {
    editData,
    isOpenEdit,
    isOpenDelete,
    deleteData,
    isOpenShow,
    onCloseShow,
    formEdit,
    onCloseModal,
    setFormEdit,
    setFormShow,
    formShow,
    setRequestEdit,
    setRequestCreate,
    update
  } = useContext(GestionCrudContext);

  const theadTemplate = [
    { name: "Usuario", order: "nickname" },
    { name: "Nombre", order: "lastname_person" },
    { name: "Licencia", order: "name_license" },
    { name: "Estado", order: "status" },
    { name: "Acciones", order: "" }
  ];

  useEffect(() => {
    setFormEdit(formEditat);
    setFormShow(formSHOSE);
    setRequestEdit(environment.UPDATE_GESTION);
    setRequestCreate(environment.CREATE_GESTION);
    postGestionCo();
  }, [update, page]);

  const postGestionCo = async () => {
    setLoading(true);
    try {
      const res = await __GestionCo(token, page);
      setCurrentPage(res.data.status.data.data);
      setMaxPage(res.data.status.data.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = async () => {
    setLoading(true);
    const body = new FormData()
    if (values.search) {
      body.append("search", values.search);
    }
    console.log(body.values)
    try {
      const res = await __GestionCo(token, body, page)
      const data = res?.data?.status;
      if (data?.code !== 200) {
        openAlert(data?.message, "error");

        setCurrentPage([]);
        setMaxPage(1);
      } else {
        // reset();
        // alertSuccess(data?.message);
        setCurrentPage(data?.data?.data);
        setMaxPage(data?.data?.last_page);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    search();
  }, [debounced]);

  return (
    <>
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"4xl"}
        modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={'outside'}
      >
        {formEdit}
      </ModalTransition>
      <ModalTransition
        isOpen={isOpenShow}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Ver información"}
        handleClick={onCloseShow}
        buttonMain={"Aceptar"}
      >
        {formShow}
      </ModalTransition>

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Elimar elemento`}
        onDelete={deleteData}
      />

      <SectionHeader secTitle="Gestión de usuarios" />
      <SubHeadGestion
        values={values}
        handleInputChange={handleInputChange}
      />
      {loading || (
        <TableOrder
          thead={theadTemplate}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyGestionComercial}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        loading={loading}
      />
    </>
  );
};
