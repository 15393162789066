import React from 'react'
import { Td, Tr } from '@chakra-ui/react'

const TbodyDrive = ({ data, set = null, onClose = null }) => {
  const Handle = () => {
    if (set) {
      set(prevValues => ({
        ...prevValues,
        driver_corrected: data?.name_driver || '',
        identification_corrected: data?.identification || 0,
        phone_corrected: data?.phone || 0
      }))
    }

    onClose && onClose()
  }

  return (
    <Tr onClick={Handle}>
      <Td className='text-center'>{data?.identification}</Td>
      <Td className='text-center'>{data?.name_driver}</Td>
      <Td className='text-center'>{data?.phone}</Td>
      <Td className='text-center'>{data?.name_typeTransport}</Td>
    </Tr>
  )
}

export default TbodyDrive
