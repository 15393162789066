export const TitleItemFmm = [
  { name: "ítem", order: "id" },
  { name: "subpartida", order: "code_subheading" },
  { name: "Código producto", order: "code_product" },
  { name: "Descripción", order: "entry_form_item_description" },
  { name: "Unidad", order: "code_businessUnit" },
  { name: "Cantidad", order: "quantity " },
  { name: "Cantidad producto", order: "product_quantity" },
  { name: "Valor CIF US$", order: "cif_us_value" },
  { name: "Valor FOB US$", order: "us_fov_alue" },
  { name: "Peso Neto", order: "net_weight" },
  { name: "Peso Bruto", order: "gross_weight" },
  { name: "Acciones", order: "null" }
];
export const TitleItemFmmTransfer = [
  { name: "ítem", order: "id" },
  { name: "subpartida", order: "code_subheading" },
  { name: "Código producto", order: "code_product" },
  { name: "Descripción", order: "entry_form_item_description" },
  { name: "Unidad", order: "code_businessUnit" },
  { name: "Cantidad", order: "quantity " },
  { name: "Cantidad producto", order: "product_quantity" },
  { name: "Valor CIF US$", order: "cif_us_value" },
  { name: "Valor FOB US$", order: "us_fov_alue" },
  { name: "Peso Neto", order: "net_weight" },
  { name: "Peso Bruto", order: "gross_weight" },
  { name: "Acciones", order: "null" }
];
export const TitleServiceItemFmmShow = [
  { name: "ítem", order: "id" },
  { name: "Servicio", order: "service" },
  { name: "Tasa de cambio", order: "exchange_rate" },
  { name: "Descripción", order: "entry_form_item_description" }
];
export const TitleItemFmmShow = [
  { name: "ítem", order: "id" },
  { name: "subpartida", order: "code_subheading" },
  { name: "Código producto", order: "code_product" },
  { name: "Descripción", order: "entry_form_item_description" },
  { name: "Unidad", order: "code_businessUnit" },
  { name: "Cantidad", order: "quantity " },
  { name: "Cantidad producto", order: "product_quantity" },
  { name: "Valor CIF US$", order: "cif_us_value" },
  { name: "Valor FOB US$", order: "us_fov_alue" },
  { name: "Peso Neto", order: "net_weight" },
  { name: "Peso Bruto", order: "gross_weight" },
  { name: "Detalle", order: "" }
];

export const TitleAccordionForm = ["Entradas"];
