import { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";
import { RiDeviceRecoverFill } from "react-icons/ri";
import { BiShow } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useLocation, useNavigate } from "react-router-dom";
import { __Post } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
// import FormCrudCRUDContext from "context/FormCrudContex";
import { AuthContext } from "context/AuthContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

export const OptionsSubmittedBox = ({ data, isCorrected }) => {
  const { authUser } = useContext(AuthContext);

  const { changeStatus, onOpenCopyBox, setDataToCopy, changeStatusCorrected, ChangeStatusCertified } =
    useContext(IntegracionBoxCrudContex);

  const navigate = useNavigate();
  const handleStatusBox = (status) => {
    const statusData = new FormData();
    statusData.append("id", data?.integration_certificate_id);
    statusData.append("status", status);
    changeStatus(statusData);
  };

  const handleCopy = (e) => {
    onOpenCopyBox();
    setDataToCopy(data);
  };

  const handleNavigateCorrected = () => {
    navigate(
      `/IntegrationCertificateReview?id=${data?.integration_certificate_code}`
    );
  };

  const handleChangeStatus = (status) => {
    const dataToSend = new FormData();
    dataToSend.append("id", data.integration_certificate_id);
    dataToSend.append("status", status);
    changeStatusCorrected(dataToSend);
  };

  const ChangeStatus = (status) => {
    const reviewIntegrationCertificate = {
      id: data.integration_certificate_id,
      status
    };
    ChangeStatusCertified(reviewIntegrationCertificate);
  };

  return (
    <>
      {isCorrected ? (
        <>
          <MenuItemStyled py={3} onClick={() => handleChangeStatus("BORRADOR")}>
            <IconMenuRUD as={RiDeviceRecoverFill} />
            Recuperar
          </MenuItemStyled>
          <HrDividerNoMargin />

          {!authUser.is_qualified_user && (
            <MenuItemStyled py={3} onClick={handleNavigateCorrected}>
              <IconMenuRUD color="#58B158" as={BiShow} />
              Revisar
            </MenuItemStyled>
          )}
        </>
      ) : (
        <>
          <MenuItemStyled py={3} onClick={() => handleStatusBox("BORRADOR")}>
            <IconMenuRUD as={RiDeviceRecoverFill} />
            Recuperar
          </MenuItemStyled>
          <HrDividerNoMargin />

          {!authUser.is_qualified_user && (
            <MenuItemStyled py={3} onClick={() => {
              ChangeStatus("REVISADO");
              navigate(`/CheckIntegrationBox?id=${data.integration_certificate_id}`)
            }}>
              <IconMenuRUD color="#58B158" as={BiShow} />
              Revisar
            </MenuItemStyled>
          )}

          {/* {!authUser.is_qualified_user && (
            <MenuItemStyled py={3} onClick={handleShow}>
              <IconMenuRUD color="#58B158" as={BiShow} />
              Revisar
            </MenuItemStyled>
          )} */}

          <HrDividerNoMargin />

          <MenuItemStyled py={3} onClick={handleCopy}>
            <IconMenuRUD color="#eeca06" as={MdContentCopy} />
            Copiar
          </MenuItemStyled>
          <HrDividerNoMargin />
        </>
      )}
    </>
  );
};
