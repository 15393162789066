/* eslint-disable no-unused-vars */
import { Table, Thead, Tr, Td, Tbody } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ThStyledBlue } from "./Table.styled";
import { IconGeneric } from "styled/Icons.styled";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsFilter } from "react-icons/bs";

import "../SelectInput/stilos.css";

const TablePass = ({
  thead = [],
  tbodyData: TbodyData,
  data = [],
  setData,
  loading = false,
  isNum = true
}) => {
  const [update, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [press, setPress] = useState(null);

  useEffect(() => {
    const orderFirst = async () => {
      const firstOrder = data?.map((item) => item);
      setInitialData(firstOrder);
    };
    orderFirst();
  }, [data]);

  const orderData = (order) => {
    if (order !== press && order) {
      const newData = data.sort((a, b) =>
        a[order] > b[order] ? 1 : a[order] < b[order] ? -1 : 0
      );
      setData(newData);
      setUpdate(!update);
      setPress(order);
    } else if (order) {
      setData(initialData);
      setUpdate(!update);
      setPress(null);
    }
  };

  return (
    <div className="table-responsive">
      <Table size="md" className="table table-sm ">
        <Thead>
          <Tr>
            {thead.map((th, index) =>
              index > 1 ? (
                <ThStyledBlue
                  key={th?.name + "_" + index}
                  isNumeric={isNum}
                  onClick={(e) => orderData(th.order, th.isNumber)}
                  // cursor={th.order ? true : null}
                  id="alinear"
                  className="text-center"
                >
                  <div className="d-flex justify-content-center align-items-center mr-4">
                    {th?.name}
                    {/* <span>{th.order ? <IconGeneric as={BsFilter} /> : ""}</span> */}
                  </div>
                </ThStyledBlue>
              ) : (
                <ThStyledBlue
                  key={`${th?.name}_${index}`}
                  onClick={(e) => orderData(th.order, th.isNumber)}
                  // cursor={th.order ? true : null}
                  className="text-center"
                >
                  <div className="d-flex justify-content-center align-items-center mr-4">
                    {th?.name}
                    {/* <span>{th.order ? <IconGeneric as={BsFilter} /> : ""}</span> */}
                  </div>
                </ThStyledBlue>
              )
            )}
          </Tr>
        </Thead>
        <Tbody>
          {update
            ? (
              data?.length <= 0 && !loading
                ? (
                  <Tr>
                    <Td colSpan={thead.length + 1}>No se encontraron datos</Td>
                  </Tr>
                )
                : (
                  data.map((item, index) => (
                    <TbodyData
                      key={`${index}-${Date.now()}-${index}`}
                      data={item}
                    ></TbodyData>
                  ))
                )
            )
            : data?.length <= 0 && !loading
              ? (
                <Tr>
                  <Td colSpan={thead.length + 1}>No se encontraron datos</Td>
                </Tr>
              )
              : (
                data?.map((item, index) => (
                  <TbodyData
                    key={`${index}-${Date.now()}-${index}`}
                    data={item}
                    iope={index}
                  ></TbodyData>
                ))
              )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TablePass;
