import { Tooltip } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { MdContentCopy, MdFactCheck } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { AuthContext } from "context/AuthContext";
import { GiCardDiscard } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const BtnShowBorrador = ({
  handleEdit,
  handleChangeStatus,
  handleCopy,
  handleDelete = () => {},
}) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation(); // navigate
  const { pathname } = location;
  // CrearOrdenDeProduccion
  return (
    <>
      {pathname !== "/CrearOrdenDeProduccion" && (
        <Tooltip placement="top" hasArrow label="Editar">
          <span>
            <ButtonHeaderModule onClick={handleEdit} bgColor="#1F7AC3">
              <IconGeneric
                width="16px"
                className=" text-white"
                as={RiEdit2Fill}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      <Tooltip placement="top" hasArrow label="Copiar">
        <span>
          <ButtonHeaderModule
            onClick={handleCopy}
            bgColor="#03C39A"
            className="mx-1"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={MdContentCopy}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      <Tooltip placement="top" hasArrow label="Presentar">
        <span>
          <ButtonHeaderModule
            onClick={() => handleChangeStatus("PRESENTADO")}
            bgColor="#1F7AC3"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={AiOutlineFileDone}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      {/*       {authUser.is_qualified_user !== 1 && (
        <Tooltip placement="top" hasArrow label="Radicar">
          <span>
            <ButtonHeaderModule
              onClick={() => handleChangeStatus("AUTORIZADO")}
              bgColor="#03C39A"
            >
              <IconGeneric
                width="16px"
                className=" text-white"
                as={MdFactCheck}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )} */}

      <Tooltip placement="top" hasArrow label="Desechar">
        <span>
          <ButtonHeaderModule
            className="mx-1"
            bgColor="#d92525"
            onClick={() => handleDelete()}
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={GiCardDiscard}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  );
};
