import axios from "axios";

export const __PostFormData = async (endpoint, token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

export const __PostJsonData = async (endpoint, token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

export const __Post = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __PostForm = async (endpoint, token, body, signal) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || "",
    signal
  };
  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __PostForm1 = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __PostFormNoToken = async (endpoint, body) => {
  const options = {
    method: "POST",
    headers: {
      // Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };
  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __PostJsonDataPage = async (endpoint, token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    }
  };
  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};
