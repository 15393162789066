import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __PostForm } from "request/Petitions/__Post";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TBodyPort = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("port_status", result === "Activo" ? "Inactivo" : "Activo");
    try {
      const resultado = await __PostForm(
        environment.UPDATE_STATE_PORT,
        token,
        formData
      );

      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.port_name}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data.port_status === "Activo" ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={() => desactivarAndActivar(data.port_status)}
          className="text-center"
          isNumeric
          text={data.port_status}
        >
          <IconGeneric
            color={data.port_status === "Activo" ? "#03C39A" : "#bababa"}
            as={BsCheckCircleFill}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TBodyPort;
