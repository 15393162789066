import { HeaderForm } from "components/HeaderForm/HeaderForm";
import environment from "constants/apiConst";
import { useEffect, useCallback, useState, useContext } from "react";

import { useSelector } from "react-redux";
import { __showBox } from "request/IntegrationBox/___IntegrationBox";
import { HeaderSearch } from "styled/SectionContainer.styled";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Collapse } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

import { AuthContext } from "context/AuthContext";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __DetinyNotPaginate } from "request/configuration/__Destiny";
import { useForm } from "hooks/useForm";
import { TableInfo } from "../View/TableInfo/TableInfo";
import FormIdOrdeProduction from "../Form/FormIdOrdeProduction";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import FormCorrected from "../Form/FormCorrected";
import FormCorrectedProduct from "../Form/FormCorrectedProduct";
import { ButtonStyled } from "styled/Buttons.styled";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import FormModalStatusDelete from "../Form/FormModalStatusDelete";
import FormCorrectedSupplies from "../Form/FormCorrectedSupplies";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormCorrectedOther from "../Form/FormCorrectedOther";
import { __Post } from "request/Petitions/__Post";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { useFormBlocker } from "hooks/useFormBlocker";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const ViewCorrection = () => {
  const initialForm = {
    status: "",
    integration_certificate_code: "",
    name_qualified: "",
  };
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loading, setLoading] = useState(false);
  const [dataHead, setDataHead] = useState(initialForm);
  const location = useLocation();
  const { idCorrected = null } = queryString.parse(location.search);
  const { authUser } = useContext(AuthContext);
  const [datauserQualified, setDataUserQuialified] = useState([]);
  const [dataDestiny, setDataDestiny] = useState([]);
  const [destiny, setDestiny] = useState(null);
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();

  const {
    isOpen,
    // setDataLastCertificate,
    setUserQualifiedCorrect,
    userQualifiedCorrect,
    SaveDataHead,
    isOpenDeleteCorrected,
    onCloseModal,
    isOpenDeleteProductCorrected,
    deleteData,
    dataDProduct,
    dataDeleteCorrect,
    setRequestSaveHead,
    setRequestDelete,
    dataTodDeleteOtherCostCorrected,
    dataSuppliesPCorrected,
    isOpenDeleteSupplyCorrected,
    setShowProduct,
    setActiveView,
    activeView,
  } = useContext(IntegracionBoxCrudContex);
  const { setCode_destination } = useContext(GlobalContext);


  const template = {
    destiny_old: "",
    observations_old: null,
    name_qualified_old: "",
    observations: "",
  };

  const [values, handleInputChange, reset, setValues] = useForm(template);

  const { observations, observations_old, name_qualified_old, destiny_old } =
    values;

  useEffect(() => {
    return () => {
      setDestiny(null);
      setValues(template);
      setActiveView(null);
      setShowProduct(false);
    };
  }, []);

  const queryClient = useQueryClient();
  const { requestData } = useGlobalContext();

  const { data, isLoading } = useQuery({
    queryKey: [queryEnv?.INTEGRATION_BOX],
    queryFn: () => requestData({
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID_CORRECTED,
      data: {
        id: idCorrected
      },
      PropertyBody: "POST"
    }),
  });

  useEffect(() => {
    if (isLoading && !data) return;
    
    const dataOld = data?.oldData?.data;
    const dataNew = data?.newData?.data;
    queryClient.setQueriesData([queryEnv?.INTEGRATION_BOX], data?.data?.status.data);
    setValues({
      destiny_old:
        dataOld?.destiny_old === null ? "" : dataOld?.destiny_old,
      observations_old: dataOld?.observations_old,
      observations: dataNew?.observations,
      name_qualified_old: dataOld?.name_qualified_old,
    });
    setDataHead(data?.data?.data?.status?.data);
    setDestiny({
      value: dataNew?.destiny_id,
      label: dataNew?.destiny === null ? "" : dataNew?.destiny,
    });
    setUserQualifiedCorrect({
      value: dataNew?.qualifiedUser_id,
      label: dataNew?.name_qualified,
    });
  }, [data, isLoading])

  useEffect(() => {
    if (!destiny?.value) return;
    setCode_destination(destiny?.value);
  }, [destiny])



  const { data: dataUC, isLoading: loadingUC } = useQuery({
    queryKey: [queryEnv?.QUALIFIED_USERS],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
    }),
  })

  useEffect(() => {
    if (dataUC?.data?.status?.code !== 200 && !dataUC?.data?.status?.data) return;
    const dataUser = dataUC?.data?.status?.data.map((items) => ({
      value: items?.id,
      label: items?.nit_qualified ? (items?.nit_qualified + " - " + items?.name_qualified) : "",
      decimal: items.decimal_values_precision
    }));
    setDataUserQuialified(dataUser);
  }, [setDataUserQuialified, dataUC])


  const { data: dataDestinyQ, isLoading: isLoadingDestinyData } = useQuery({
    queryKey: [queryEnv?.DESTINY_NOT_PAGINATE],
    queryFn: () => requestData({
      Endpoint: environment.DESTINY_NOT_PAGINATE,
    }),
  })

  useEffect(() => {
    if (dataDestinyQ?.data?.status?.code !== 200 && !dataDestinyQ?.data?.status?.data) return;
    const dataDestiny = dataDestinyQ?.data?.status?.data.map((items) => {
      const { destiny, ...rest } = items;
      return {
        ...rest,
        value: items?.id,
        label: destiny
      }
    })
    setDataDestiny(dataDestiny);
  }, [dataDestinyQ, setDataDestiny])

  // FUNCION PARA ENVIAR SOLO DATOS DE LA CABEZERA
  const handleSaveBox = () => {
    const dataHeadSend = new FormData();
    dataHeadSend.append("id", idCorrected);
    dataHeadSend.append("destinyCorrected_id", destiny?.value || "");
    dataHeadSend.append("observation_corrected", observations);
    dataHeadSend.append(
      "qualifiedUserCorrected_id",
      userQualifiedCorrect?.value || ""
    );
    SaveDataHead(dataHeadSend);
    navigate(`/ShowIntegrationBox?idCorrected=${idCorrected}`);
    handleReset();
  };

  const handleReset = () => {
    reset();
    setDestiny(null);
    setUserQualifiedCorrect(null);
  };

  const { setUser_Qualified } = useContext(GlobalContext);

  useEffect(() => {
    setUser_Qualified(userQualifiedCorrect?.value);
  }, [userQualifiedCorrect?.value]);

  useEffect(() => {
    setRequestSaveHead(environment.SAVE_HEAD_CORRECTED);
  }, [setRequestSaveHead]);

  useEffect(() => {
    setRequestDelete(environment.DELETE_CORRECTED_OTHER_COST);
  }, [dataTodDeleteOtherCostCorrected?.id_corrected]);

  useEffect(() => {
    setRequestDelete(environment.DELETE_CORRECTED_PRODUCT_AND_ORDER);
  }, [dataDProduct?.id_corrected]);


  // Funcion para eliminar un producto de orden de produccion
  const handleDeleteProductOrder = () => {
    const statusData = new FormData();
    statusData.append("id_corrected", dataDeleteCorrect?.id_corrected);
    deleteData(statusDataSingleProduct);
  };

  // funcion para eliminar insumo

  const handleDeleteSupplysCorrected = () => {
    const statusData = new FormData();
    statusData.append("id_corrected", dataSuppliesPCorrected.id_corrected);
    deleteData(statusData);
  };

  // Data que se envia para eliminar un producto
  const statusDataSingleProduct = new FormData();
  statusDataSingleProduct.append(
    "id_corrected",
    dataDeleteCorrect?.id_corrected || dataDProduct?.id_corrected
  );

  useEffect(() => {
    if (idCorrected) {
      return async () => {
        const body = new FormData();
        body.append("form_id", idCorrected);
        body.append("type_of", "CIC");
        body.append("type", 0);
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      };
    }
  }, [idCorrected]);

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: idCorrected || "",
    type_of: "CIC",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenDeleteCorrected}
        onClose={onCloseModal}
        title={`¿ El producto elaborado ${dataTodDeleteOtherCostCorrected?.description_product_corrected}  pertenece a una orden de producción, al eliminar la se descartaría la plantilla de la orden de producción?`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            colorScheme="red"
            onClick={() => handleDeleteProductOrder()}
            ml={3}
          >
            Eliminar
          </Button>
        </div>
      </ModalGeneric>

      <FormModalStatusDelete
        status={"ELIMINAR"}
        nameDocument={"Producto"}
        numberOfDocument={dataDProduct?.description_product_corrected}
        data={statusDataSingleProduct}
        isOpen={isOpenDeleteProductCorrected}
        onClose={onCloseModal}
        deletes={false}
        product={true}
        functionChangeStatus={deleteData}
      />

      {/*       <ModalGeneric
        isOpen={isOpenDeleteOtherCostCorrected}
        onClose={onCloseModal}
        title={`¿Desea eliminar el gasto ${dataTodDeleteOtherCostCorrected?.name_cost_corrected}?`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            colorScheme="red"
            onClick={() => handleDeleteOtherCost()}
            ml={3}
          >
            Eliminar
          </Button>
        </div>
      </ModalGeneric>
 */}
      <ModalGeneric
        isOpen={isOpenDeleteSupplyCorrected}
        onClose={onCloseModal}
        title={`¿Desea eliminar el insumoaaaaa ${dataSuppliesPCorrected?.description_product_corrected}?`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancelar</Button>
          <Button
            colorScheme="red"
            onClick={() => handleDeleteSupplysCorrected()}
            ml={3}
          >
            Eliminar
          </Button>
        </div>
      </ModalGeneric>

      {loading && <SpinnerComponent />}

      {!loading && (
        <>
          <HeaderSearch>
            <HeaderForm
              numberForm={dataHead?.newData?.data?.integration_certificate_code}
              UserQualified={dataHead?.newData?.data.name_qualified}
              Modulo={
                "Edición de Formulario de Corrección de Cert. de Integración En Zonas Francas"
              }
            />
          </HeaderSearch>

          <div className="d-flex justify-content-between mt-4">
            <div className="col-6 borderforms">
              {authUser.is_qualified_user !== 1 && (
                <div>
                  <LabelGroup
                    name={"name_qualified_old"}
                    value={name_qualified_old}
                    onChange={handleInputChange}
                    tag={"Empresa"}
                    size={90}
                    desicion={true}
                    changeValue={
                      name_qualified_old !== userQualifiedCorrect?.label &&
                      "BorderCorrect"
                    }
                  />
                </div>
              )}

              <div>
                <LabelGroup
                  name={"destiny_old"}
                  value={destiny_old}
                  onChange={handleInputChange}
                  tag={"Tipo de cuadro"}
                  size={90}
                  desicion={true}
                  changeValue={
                    destiny_old !== destiny?.label && "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"observations_old"}
                  value={observations_old}
                  onChange={handleInputChange}
                  tag={"Observaciones"}
                  size={90}
                  desicion={true}
                  changeValue={
                    observations_old !== observations && "BorderCorrect"
                  }
                />
              </div>
            </div>

            <div className="col-6 borderforms">
              {authUser.is_qualified_user !== 1 && (
                <div>
                  <InputSelect
                    className={
                      name_qualified_old !== userQualifiedCorrect?.label &&
                      "BorderCorrect"
                    }
                    tag={"Empresa"}
                    size={90}
                    data={datauserQualified}
                    set={setUserQualifiedCorrect}
                    value={userQualifiedCorrect}
                    isLoading={loadingUC}
                  />
                </div>
              )}

              <div>
                <InputSelect
                  className={destiny_old !== destiny?.label && "BorderCorrect"}
                  tag={"Tipo de cuadro"}
                  size={90}
                  data={dataDestiny}
                  set={setDestiny}
                  value={destiny}
                  isLoading={isLoadingDestinyData}
                />
              </div>

              <div>
                <LabelGroup
                  name={"observations"}
                  value={observations}
                  onChange={handleInputChange}
                  tag={"Observaciones"}
                  size={90}
                  desicion={false}
                  changeValue={
                    observations_old !== observations && "BorderCorrect"
                  }
                />
              </div>
            </div>
          </div>

          <Collapse in={isOpen}>
            <FormCorrected
              destiny={destiny}
              valuesObservation={values}
              userQualifiedCorrect={userQualifiedCorrect}
            />
          </Collapse>

          {activeView === "showProduct" && (
            <FormCorrectedProduct
              destiny={destiny}
              valuesObservation={values}
              userQualifiedCorrect={userQualifiedCorrect}
            />
          )}

          {activeView === "showSupplies" && <FormCorrectedSupplies destiny={destiny} userQualified={userQualifiedCorrect} />}
          {activeView === "showViewCorrectCost" && <FormCorrectedOther />}

          <div className="mt-5">
            {/* Aqui estan los modales */}
            <FormIdOrdeProduction />
          </div>

          <div className="mb-3 ms-5">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap={"wrap"}
            >
              <ButtonStyled type="submit" onClick={handleSaveBox}>
                Aceptar
              </ButtonStyled>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default ViewCorrection;
