import { Box, Td, Tooltip, Tr } from '@chakra-ui/react'
import React from 'react'
import { RiRadioButtonFill } from 'react-icons/ri';
import { IconGeneric } from 'styled/Icons.styled';

export const iconStatus = {
  0: (
    <Tooltip placement="top" hasArrow label="No">
      <spam>
        <IconGeneric color={"#BA1F33"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  ),

  1: (
    <Tooltip placement="top" hasArrow label={"Sí"}>
      <spam>
        <IconGeneric color={"#03C39A"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  ),

  DEFAULT: (
    <Tooltip placement="top" hasArrow label={"No"}>
      <spam>
        <IconGeneric color={"#BABABA"} as={RiRadioButtonFill} />
      </spam>
    </Tooltip>
  )
};

/* 
[Ayer 16:38] Maicol Martinez De La Hoz
"id": 1,
                    "date_form": "2024-07-29",
                    "time_form": "10:34:51",
                    "nit_qualified": "8020193394",
                    "name_qualified": "ADAMA ANDINA B.V SUCURSAL COLOMBIA.",
                    "document_type": "FORM.INGRESO",
                    "code_form": "2792",
                    "item_form": 0,
                    "number_packages": 4,
                    "num_operations": 0,
                    "free_pass": 1,
                    "freely_available": 1,
                    "status_form": "BORRADOR",
                    "nickname": "kruiz"
 like 1

*/

const TBodyOpeIO = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center" whiteSpace={'nowrap'} minWidth={'fit-content'}>{data?.date_form}</Td>
      <Td className="text-center">{data?.time_form}</Td>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.document_type}</Td>
      <Td className="text-center">{data?.code_form}</Td>
      <Td className="text-center">{data?.item_form}</Td>
      <Td className="text-center">{data?.num_operations}</Td>
      <Td className="text-center">{data?.number_packages}</Td>
      <Td className="text-center">{iconStatus[data?.free_pass] ?? iconStatus.DEFAULT}</Td>
      <Td className="text-center">{iconStatus[data?.freely_available] ?? iconStatus.DEFAULT}</Td>
      <Td className="text-center">{data?.status_form}</Td>
      <Td className="text-center">{data?.nickname}</Td>
    </Tr>
  )
}

export default TBodyOpeIO