import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { useContext, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import {
  __SearchPOST,
  __SearchReviews,
  __SearchUser
} from "request/Reviews/__GetTReviews";
import { HeaderSearch } from "styled/SectionContainer.styled";
import ReviewsHeader from "../TableReviews/ReviewsHeader";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TbodyUpdateReview from "./TbodyUpdateReview";
import { theadUpdateReview } from "../TableReviews/thead/thead";
import { CardUpdateReview } from "../Cards/CardUpdateReview/CardUpdateReview.jsx";
import { DivGrid } from "../Styles/Styled";
import { SearchModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { Storage } from "hooks/useStorage";
import FormCrudCRUDContext from "context/FormCrudContex";

import { Box, FormControl } from "@chakra-ui/react";
import { InputSelectMulti } from "components/InputSelect/InputSelect";
import { __GetAll } from "request/configuration/__QualifiedUsers";

export const UpdateReview = () => {
  const { flagUpdateReviews, setflagUpdateReviews } =
    useContext(FormCrudCRUDContext);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  // eslint-disable-next-line no-unused-vars
  const [allAccess, setAllAccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const token = useSelector((state) => state.auth.dataUser.token);

  const { get, set } = Storage;

  const [openAlert] = AlertErrorAlert();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [recient, setRecient] = useState("");
  const [card, setCatd] = useState([]);

  const [freeTradeZone, setFreeTradeZone] = useState([]);
  const [usersReviewer, setUsersReviewer] = useState([]);

  useEffect(() => {
    SearchUserCard(SearchReviewer);
    searchUser({ ...SearchZone, ...SearchReviewer });
  }, [freeTradeZone, usersReviewer, flagUpdateReviews, freeZone_id]);

  const searchUser = async (data) => {
    setLoading(true);
    try {
      const res = await __SearchUser(
        environment.GET_REVIEW_UPDATE,
        token,
        data
      );
      const status = res?.data?.status;
      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else {
        setResData(res?.data?.status?.data?.data);
      }
    } catch (error) {
      // manejo del error
    } finally {
      setLoading(false);
    }
  };

  const SearchUserCard = async (data) => {
    setLoading(true);

    try {
      const res = await __SearchUser(environment.GET_USERS_REVIEW, token, data);
      const status = res?.data?.status;
      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else {
        setCatd(res?.data?.status?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetUserCard();
    GetReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_rol, allAccess, admin, freeZone_id]);

  const newReviewer = [];
  if (usersReviewer) {
    usersReviewer.forEach(({ value }) => newReviewer.push(value));
  }

  const SearchReviewer = {
    reviewer: newReviewer
  };

  const SearchZone = {
    freeZone: freeTradeZone?.value
  };

  const GetReviews = async () => {
    try {
      const res = await __SearchReviews(
        environment.GET_REVIEW_UPDATE,
        token,
        page
      );
      const status = res?.data?.status;
      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else {
        // openAlert(status?.message, "success");
        setResData(res?.data?.status?.data?.data);
        setRecient(res?.data?.status?.data?.data?.length);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const GetUserCard = async () => {
    setLoading(true);
    try {
      const res = await __SearchPOST(environment.GET_USERS_REVIEW, token, page);
      const status = res?.data?.status;
      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else {
        setCatd(res?.data?.status?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const [revisor, setRevisores] = useState([]);

  const getUserData = async () => {
    try {
      const res = await __GetAll(
        environment.GET_USERS_REVIEWER_NOT_PAGE,
        token
      );

      if (res?.data?.status?.data) {
        const infoZf = res.data.status.data.map((item) => ({
          label: item.nickname,
          value: item?.id
        }));
        setRevisores(infoZf);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // get("zfReviwer")
    const dataZf = get("ZfReviwer");

    if (dataZf) {
      setFreeTradeZone(dataZf);
    }
    const dataReviewr = get("Reviwer");
    if (dataReviewr.length > 0) {
      setUsersReviewer(dataReviewr);
    }
    getUserData();
  }, []);

  const handleChangeZf = (item) => {
    set("ZfReviwer", item);
    setFreeTradeZone(item);
  };
  const handleChangeRevi = (item) => {
    set("Reviwer", item);
    setUsersReviewer(item);
  };

  return (
    <>
      <Box
        display={"flex"}
        gridGap={6}
        shadow={
          "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;margin: 0.5rem 0.5rem"
        }
        m={"0.5rem 0.5rem"}
        p={"1rem"}
        borderRadius=" 5px"
        w="full"
      >
        <FormControl>
          <SearchModule
            tag={"Zonas Francas"}
            type="FreeTradeZone"
            service={environment.GET_ZONAS_F_NOT_PAGE}
            setValueQuialified={handleChangeZf}
            valueQualified={freeTradeZone}
          />
        </FormControl>

        <FormControl>
          <div style={{ width: "100%" }}>
            <InputSelectMulti
              tag={"Revisores"}
              data={revisor}
              set={handleChangeRevi}
              value={usersReviewer}
            />
          </div>
        </FormControl>
      </Box>

      <DivGrid Size="260px">
        {card.map((x, index) => {
          return <CardUpdateReview key={index} data={x} />;
        })}
      </DivGrid>

      <HeaderSearch>
        <ReviewsHeader title={"Todos los documentos"} mt="mt-5" />

        <TableOrder
          thead={theadUpdateReview}
          data={resData}
          isNum={false}
          setData={setResData}
          loading={loading}
          tbodyData={TbodyUpdateReview}
        />
        <Pagination
          thead={theadUpdateReview}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};
