import { Td, Tr } from "@chakra-ui/react";

import React from "react";

const TbodyItemsDelete = ({ data }) => {
  console.log(data);
  const date = new Date(data.created_at).toLocaleDateString();

  return (
    <Tr>
      <Td className="text-center">{data?.entry_form_item_description}</Td>
      <Td className="text-center">{data?.product_quantity}</Td>
      <Td className="text-center">{date}</Td>
      <Td className="text-center">{data.nickname}</Td>
    </Tr>
  );
};

export default TbodyItemsDelete;
