export const theadReference = [
  { name: "Código", order: "null" },
  { name: "Código de formulario", order: "null" },
  { name: "Tipo", order: "null" },
  { name: "Código del seguimiento padre", order: "null" },
  { name: "Usuario calificado", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Fecha inicio", order: "null" },
  { name: "Fecha fin", order: "null" },
  { name: "Usuario fin", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Oficio", order: "null" },
  { name: "Gestor de seguimiento", order: "null" },
  { name: "Comentario", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: "null" }
]
