import React, { useContext, useRef, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from "@chakra-ui/react";
import { MdSend, MdKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineComment } from "react-icons/ai";

import "./style.css";
import { InputCommentary } from "pages/RefProductInter/Stilos";
import { IconGeneric } from "styled/Icons.styled";
import { Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { useForm } from "hooks/useForm";
import { AuthContext } from "context/AuthContext";

/**
 * Commentary - Componente para hacer comentarios.
 * @param {String} id Id del formulario donde haremos el comentario.
 * @param {String} ruta Ruta a donde haremos la consulta.
 * @param {Function} create Funcion para realizar el comentario.
 * @param {Array} commentarys Lista de comentarios realizados.
 * @return {Component} Retorna componente de crear y leer los comentarios.
 */

export const Commentary = ({ id, ruta, create, commentarys }) => {
  // document.getElementById("contenedorComm").scrollIntoView(true);
  const [first, setfirst] = useState(false);
  const btnRef = useRef();
  const divCommentaryRef = useRef();
  const { authUser } = useContext(AuthContext);

  const [values, handleInputChange, reset] = useForm({
    comment: ""
  });
  const { comment } = values;

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...values,
      form_id: id
    };
    if (comment !== "") {
      reset();
      create(ruta, data);
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      {!first && (
        <Fab
          mainButtonStyles={{
            backgroundColor: "#3498db",
            width: "40px",
            height: "40px"
            // borderRadius: 48,
            // bottom: "40px",
          }}
          icon={<MdKeyboardArrowLeft />}
          alwaysShowTitle={true}
          onClick={() => setfirst(!first)}
          text={commentarys ? `${commentarys.length}` : ""}
        />
      )}

      <Drawer
        isOpen={first}
        placement="right"
        onClose={() => setfirst(!first)}
        finalFocusRef={btnRef}
        id="scroolhiden"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Comentarios</DrawerHeader>

          <DrawerBody>
            <div id="contenedorComm" ref={divCommentaryRef}>
              {commentarys &&
                commentarys.map((item, index) => (
                  <div
                    key={index}
                    id={
                      item?.user_id !== authUser.id
                        ? "messageOtherUser"
                        : "messageMyUser"
                    }
                  >
                    <span id="UserMessage">{item?.person_name}</span>{" "}
                    <h4>{item?.comment} </h4>
                    <span id="timeMessage">
                      {item?.date_comment?.slice(0, 10) +
                        " " +
                        item?.make_comment}{" "}
                    </span>{" "}
                  </div>
                ))}
            </div>
          </DrawerBody>

          <DrawerFooter>
            <InputCommentary
              name={"comment"}
              onChange={handleInputChange}
              value={comment}
              placeholder="Escribe un comentario"
              onKeyDown={(e) => onEnter(e)}
            />

            <IconGeneric
              onClick={(e) => handleSubmit(e)}
              type="submit"
              className="m-1 d-flex justify-content-center"
              as={MdSend}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
