/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps */
import { Box, Menu, MenuButton, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import styled from "styled-components/macro";
import React, { useCallback, useEffect, useState } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";
import { CgImport } from "react-icons/cg";
import { BiFilter } from "react-icons/bi";
import { VscFilePdf } from "react-icons/vsc";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import useUserQualified from "hooks/useUserQualified";
import { InputSelectMulti } from "components/InputSelect/InputSelect";
import { __Post, __PostJsonData } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { __ObtenerEstadisticas } from "request/Estadisticas/__Estadisticas";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ExportStatistics from "./ExportStatistics";
import { arrayStatisticsSecurityVehicle, formExportStatisticsSecurityVehicle } from "./ArryList";
import { useRegexState } from "hooks/useRegexState";
import { HrDividerNoMargin } from "styled/Line.styled";
import { __Get } from "request/Petitions/__Get";
import environment from "constants/apiConst";

export const HeaderEstadistica = ({
  title,
  filter = true,
  filterDates = true,
  enableQualifiedUser,
  handlePDF,
  excelPath,
  obtenerPath,
  setCurrentPage,
  setLoading,
  enableRendering,
  excelAdvancePath,
  arrayList,
  exportFilters,
  filterDays,
  showOpUser = true,
  getOpData,
  getOpDataPath,
  setDataOp
}) => {
  const [fechasHoy, setfechasHoy] = useState(null);
  const [filterEst, setFilterEst] = useState(!showOpUser);
  const { userQualified, isLoading } = useUserQualified();
  const [selectUserQualified, setSelectUserQualified] = useState(null);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [openAlert] = AlertErrorAlert();
  const [dataUserOperator, setDataUserOperator] = useState(null);
  const [selectUserOperator, setSelectUserOperator] = useState(null);

  useEffect(() => {
    setSelectUserQualified([]);
  }, [freeZone_id])

  const [values, handleInputChange] = useForm({
    date_start: "",
    date_end: ""
  });

  const { values: regexValues, handleInputChange: handleRegexChange, resetForm: reset } = useRegexState({
    days: ""
  }, /^[0-9]*$/);

  const { date_start, date_end } = values;
  const { days } = regexValues;

  const Obtener = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        const res = await __PostJsonData(obtenerPath, token, formData);
        const { data: { status: { code, data } } } = res;
        if (code === 200) {
          setCurrentPage(data);
        }
        if (code !== 200) {
          openAlert(data, "error");
        }
        if (getOpData) {
          const resDataOp = await __PostJsonData(getOpDataPath, token, formData);
          !date_start && !date_end && openAlert('La fecha de inicio y fin son requeridas', "info");
          if (resDataOp?.data?.status?.code === 200) {
            openAlert(resDataOp?.data?.status?.message, "success");
            setDataOp(resDataOp?.data?.status?.data);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    console.log(selectUserOperator)
    const formData = {
      ...filterDates && {
        date_start,
        date_end
      },
      ...(selectUserQualified && selectUserQualified?.length !== 0 && {
        qualifiedUser_id:
          selectUserQualified.map(user => user.value)
      }),
      ...(selectUserOperator && selectUserOperator?.length !== 0 && {
        id_commerce:
          selectUserOperator.map(user => user.value)
      }),
      ...(filterDays && {
        days
      })
    }
    if (enableRendering && obtenerPath && setLoading) {
      Obtener(formData);
    }
  }, [date_start, date_end, selectUserQualified, selectUserOperator, freeZone_id, days])

  useEffect(() => {
    const hoy_fecha = new Date().toISOString().substring(0, 10);
    setfechasHoy(hoy_fecha);
  }, []);

  const getOperatorUsersAbi = async () => {
    const res = await __Get(environment.GET_OPERATOR_USERS, token);
    const data = res?.data?.status?.data?.map((items) => ({
      value: items?.id,
      label: items?.person_name
    }))
    setDataUserOperator(data)
  }

  useEffect(() => {
    getOperatorUsersAbi()
  }, [])

  const handleExportExcel = async (type = "xlsx") => {
    try {
      const res = await __PostJsonData(excelPath, token, {
        filter: selectUserQualified ? 1 : 2,
        date_start,
        date_end,
        ...(selectUserQualified && {
          qualifiedUser_id:
            selectUserQualified.map(user => user.value)
        }),
        ...(selectUserOperator && {
          id_commerce: selectUserOperator.map(user => user.value)
        }),
        type
      })
      const { data: { status: { code, data } } } = res;
      if (code === 200 && data) {
        console.log(data)
        return openAlert(`${data}`, "info");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      fields: Object.values(formulario) !== '' ? Object.values(formulario) : []
    };
    exportColumnBy(info, onCloseExport);
  };

  const exportColumnBy = async (data, onCloseExport) => {
    try {
      const res = await __Post(excelAdvancePath, token, data);
      if (res?.status === 200) {
        onCloseExport();
        return window.location.href = res?.data?.status?.data;
      } else {
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

  return (
    <>

      {excelAdvancePath && arrayList &&
        (<ModalGeneric
          isOpen={isOpenExport}
          onOpen={onOpenExport}
          onClose={onCloseExport}
          title={"Exportar documento"}
          size={"4xl"}
        >
          <ExportStatistics
            title={"Exportar estadísticas"}
            onClose={onCloseExport}
            NodeList={arrayList}
            handleSubmit={handleSubmit}
            exportFilters={exportFilters}
            dafaulActive={[]}

          />
        </ModalGeneric>)}
      <div className="container mb-3">
        <div className="row">
          <div className="col">
            <Title size={"18px"} weight={600} className="mt-3">
              {title}
            </Title>
          </div>
          <div className="col">
            <div className="d-flex gap-2 justify-content-end container mt-4">
              {filter && (
                <Tooltip placement="top" hasArrow label="Seleccione periodo">
                  <ButtonHeaderModule
                    onClick={() => setFilterEst(!filterEst)} >
                    <IconGeneric color="#fff" as={BiFilter} />
                  </ButtonHeaderModule>
                </Tooltip>
              )}
              {excelPath && (
                <Menu>
                  <MenuButton>
                    <ButtonHeaderModule bgColor="#03C39A">
                      <IconGeneric as={CgImport} color="#fff" />
                    </ButtonHeaderModule>
                  </MenuButton>
                  <MenuList
                    borderRadius="none"
                    py={0}
                    style={{ boxSizing: "border-box" }}
                  >
                    <MenuItemStyled py={3} onClick={() => handleExportExcel("csv")}>
                      CSV
                    </MenuItemStyled>
                    <HrDividerNoMargin />
                    <MenuItemStyled py={3} onClick={() => handleExportExcel("xlsx")}>
                      XLSX
                    </MenuItemStyled>
                  </MenuList>
                </Menu>
              )}
              {excelAdvancePath && (
                <Tooltip placement="top" hasArrow label="Exportar">
                  <ButtonHeaderModule onClick={onOpenExport} bgColor="#03C39A">
                    <IconGeneric color="#fff" as={CgImport} />
                  </ButtonHeaderModule>
                </Tooltip>
              )}
              {handlePDF && (
                <Tooltip placement="top" hasArrow label="Exportar">
                  <ButtonHeaderModule onClick={handlePDF} bgColor="#03C39A">
                    <IconGeneric color="#fff" as={VscFilePdf} />
                  </ButtonHeaderModule>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          {enableQualifiedUser &&
            <div style={{ display: "flex", gap: "10px", width: "100%" }}>

              <InputSelectMulti
                tag={"Usuario calificado"}
                data={userQualified}
                set={setSelectUserQualified}
                value={selectUserQualified}
                isLoading={isLoading}
                isDisabled={isLoading}
                size={100}
                className="col-6"
              />

              {
                showOpUser && (
                  <InputSelectMulti
                    tag={"Usuario Operador"}
                    data={dataUserOperator}
                    set={setSelectUserOperator}
                    value={selectUserOperator}
                    isLoading={isLoading}
                    size={100}
                    className="col-6"
                  />
                )
              }
            </div>
          }
          <div className="col-2">
            {
              filterEst && filterDays && (
                <div className="d-flex gap-4 align-items-center container">
                  <LabelGroup
                    tipo={"text"}
                    name={"days"}
                    onChange={handleRegexChange}
                    value={days}
                    tag={"Días"}
                    size={45}
                    desicion={false}
                  />
                </div>
              )
            }
          </div>
          <div className="col-4">
            {
              filterEst && filterDates && (
                <React.Fragment>
                  <div className="d-flex gap-4 align-items-center container">
                    <LabelGroup
                      name={"date_start"}
                      value={date_start}
                      onChange={handleInputChange}
                      tag={"Fecha inicial*"}
                      size={45}
                      tipo="date"
                      max={fechasHoy}
                    />
                    <LabelGroup
                      name={"date_end"}
                      tipo="date"
                      value={date_end}
                      onChange={handleInputChange}
                      tag={"Fecha final*"}
                      size={45}
                      max={fechasHoy}
                    />
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>

      </div>
    </>
  );
};
