import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { MdContentCopy } from "react-icons/md";

import { HiOutlineDocumentSearch } from "react-icons/hi";

export const ButtonsAuthorization = ({
  handleAnular,
  handleCopy,
  handleStatusBox
}) => {
  return (
    <>
      <Tooltip placement="top" hasArrow label="Copiar">
        <span>
          <ButtonHeaderModule
            onClick={handleCopy}
            className="m-2"
            bgColor="#03C39A"
          >
            <IconGeneric className="m-1 text-white" as={MdContentCopy} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      <Tooltip placement="top" hasArrow label="Corregir">
        <span>
          <ButtonHeaderModule
            onClick={() => handleStatusBox("CORREGIDO")}
            className="m-2"
            bgColor="#F9A621"
          >
            <IconGeneric
              className="m-1 text-white"
              as={HiOutlineDocumentSearch}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  );
};
