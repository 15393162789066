import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GetDate, Hours } from "./HoursAndDate";
import { Storage } from "./useStorage";
import { isNumber, isObjectNotEmpty, isString } from "utils/type-check-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import requestData from "request/Petitions/requestData";
import { useSelector } from "react-redux";
class InitialComment {
  constructor ({ formField, formFieldInfo, item, nameId }) {
    this.form_field = formField || "";
    this.form_field_info = formFieldInfo || "N/A";
    this.comment = "";
    this.item = parseInt(item);
    this.name = nameId;
    this.date = GetDate();
    this.time = Hours();
    this.status = 1;
  }
}

export const useGetInput = ({ storageKey, onOpen }) => {
  const { get, set, clean } = Storage;
  const [observations, setObservations] = useState([]);
  const [selectedObservationIndex, setSelectedObservationIndex] = useState(0);

  useEffect(() => {
    clean(storageKey);
    const storedObservations = get(storageKey);

    if (observations.length !== 0) return set(storageKey, observations);
    if (storedObservations.length !== 0) { return setObservations(storedObservations); }
  }, [observations, storageKey]);

  const handleSwitchStatus = (x) => {
    const { name, value } = x?.target;
    const { nameId, nameChoice, itemId = null } = x?.target?.dataset;

    const initialComment = new InitialComment({
      formField: name || nameChoice,
      formFieldInfo: value,
      item: parseInt(itemId),
      nameId
    });

    const index = observations.findIndex(
      (element) => element?.form_field === name
    );

    if (index === -1) return setObservations([...observations, initialComment]);
    if (observations[index].status > 2) return;

    const updatedObservations = [...observations];
    const { status } = updatedObservations[index];

    updatedObservations[index] = {
      ...updatedObservations[index],
      status: status + 1 > 2 ? 0 : status + 1
    };

    if (status + 1 === 2) {
      setSelectedObservationIndex(index);
      onOpen();
    }

    setObservations(updatedObservations);
  };

  return {
    handleSwitchStatus,
    selectedObservationIndex,
    setObservations,
    observations
  };
};

const defaultTypes = [
  {
    color: "gray",
    enableComment: false
  },
  {
    color: "green",
    enableComment: false
  },
  {
    color: "red",
    enableComment: true
  },
  {
    color: "purple",
    enableComment: true
  },
  {
    color: "orange",
    enableComment: false
  }
];

export const useNotations = ({
  storageKey,
  onOpen = () => {},
  notationTypes = defaultTypes,
  reviews: reviewsCurrent = null,
  reviewsUpdates = null,
  reviewsNew = null,
  initialState = 1
}) => {
  const { get, set, clean } = Storage;
  const [observations, setObservations] = useState(null);
  const [currentCommentary, setCurrentCommentary] = useState(null);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    clean(storageKey);
    /*  const storedObservations = get(storageKey);

    console.log('reviewsCurrent', reviewsCurrent) */
    /*
    if (observations && isObjectNotEmpty(observations)) {
      set(storageKey, observations);
      return;
    }

    if (
      (storedObservations && isObjectNotEmpty(storedObservations)) ||
      (reviewsCurrent && isObjectNotEmpty(reviewsCurrent))
    ) {
      setObservations(reviewsCurrent || storedObservations);
      return;
    } */
  }, [observations, reviewsCurrent, storageKey]);

  const UpdateComment = (itemId, nameId, comment) => {
    if (itemId && nameId && comment) {
      setObservations((prev) => ({
        ...prev,
        [storageKey]: {
          ...prev?.[storageKey],
          [itemId]: {
            ...prev?.[storageKey][itemId],
            [nameId]: {
              ...prev?.[storageKey]?.[itemId]?.[nameId],
              comment
            }
          }
        }
      }));
    }
  };

  const getNestedPropertyValue = useMemo(() => {
    return (id, field, propertyName = "comment") => {
      const getValueFromSource = (source) =>
        source?.[storageKey]?.[id]?.[field]?.[propertyName];
      const reviewsNewValue = getValueFromSource(reviewsNew);
      const observationsValue = getValueFromSource(observations);
      const reviewsCurrentValue = getValueFromSource(reviewsCurrent);
      const reviewsUpdatesValue = getValueFromSource(reviewsUpdates);

      return (
        reviewsNewValue ||
        observationsValue ||
        reviewsCurrentValue ||
        reviewsUpdatesValue ||
        ""
      );
    };
  }, [observations, reviewsNew, reviewsCurrent, reviewsUpdates]);

  const getColorByFieldName = useMemo(() => {
    return (id, field) => {
      const sources = [
        observations,
        reviewsNew,
        reviewsCurrent,
        reviewsUpdates
      ];
      for (const source of sources) {
        if (source?.[storageKey]?.[id]?.[field]?.status?.color) {
          return source[storageKey][id][field].status.color;
        }
      }
      return "";
    };
  }, [observations, reviewsNew, reviewsCurrent, reviewsUpdates]);

  const handleSwitchStatus = (event) => {
    const { name, value } = event?.target;
    const { nameId, nameChoice, itemId = null } = event?.target?.dataset;
    const newItemId = isString(itemId, true) ? parseInt(itemId) : itemId || "";
    const currentObservation =
      observations?.[storageKey]?.[itemId]?.[name]?.index || 0;

    setCurrentCommentary({
      name,
      value,
      nameId,
      storageKey,
      nameChoice,
      itemId
    });

    const currentPosition =
      (currentObservation + 1) % (notationTypes?.length || 1);

    if (
      !observations?.[storageKey]?.[itemId] ||
      !observations?.[storageKey]?.[itemId]?.[name]
    ) {
      const createComment = new createInitialComment({
        formField: name || nameChoice || "",
        formFieldInfo: value || "N/A",
        item: newItemId,
        nameId,
        status: notationTypes[currentPosition],
        index: currentPosition
      });

      return setObservations((prev) => ({
        ...prev,
        [storageKey]: {
          ...prev?.[storageKey],
          [itemId]: {
            ...prev?.[storageKey]?.[itemId],
            [name]: createComment
          }
        }
      }));
    }

    if (notationTypes[currentPosition]?.enableComment) {
      onOpen();
    }

    setObservations((prev) => ({
      ...prev,
      [storageKey]: {
        ...prev?.[storageKey],

        [itemId]: {
          ...prev?.[storageKey][itemId],
          [name]: {
            ...prev?.[storageKey]?.[itemId]?.[name],
            status: notationTypes[currentPosition],
            item: newItemId,
            index: currentPosition
          }
        }
      }
    }));
  };
  const token = useSelector((state) => state.auth.dataUser.token);

  const apiUrls = {
    "/CheckFormEntry": environment.REVIEW_FOR_ENTRY,
    "/CheckCorrectEntry": environment.POST_ENTRY_FORM_CORRECTED,
    "/CheckCorrectOutput": environment.POST_OUTPUT_FORM_CORRECTED,
    "/CheckForm": environment.REVIEW_FOR_EXIT
  };

  const [openAlert] = AlertErrorAlert();
  const { isLoading, status, mutate } = useMutation({
    mutationFn: requestData
  });

  const controllerResponse = async (res) => {
    if (res.data.status.code === 200) {
      openAlert(res?.data?.status?.message, "success");
    } else {
      openAlert(res?.data?.status?.message, "error");
    }
  };

  const reviewsSave = (data) => {
    const config = {
      data,
      Endpoint: apiUrls[pathname],
      PropertyBody: "sendJSONContentPOST",
      token
    };

    mutate(config, {
      onSuccess: (data) => controllerResponse(data)
    });
  };

  const createComment = (target, comment = "", color = "", id = null) => {
    const { name, value } = target || {};
    const { nameId /* , itemId = null */ } = target?.dataset || {};

    // const newItemId = isString(itemId, true) ? parseInt(itemId) : itemId || "";
    const createCommentData = new createInitialComment({
      formField: name,
      formFieldInfo: value,
      ...(id && { item: id }),
      comment,
      nameId,
      status: color
    });

    const currentObservation = {
      [storageKey]: {
        [id]: {
          [name]: {
            ...createCommentData
          }
        }
      }
    };

    const correctionData = {
      id: storageKey,
      review: currentObservation
    };

    reviewsSave(correctionData);

    setObservations((prev) => ({
      ...prev,
      [storageKey]: {
        ...prev?.[storageKey],
        [id]: {
          ...prev?.[storageKey][id],
          [name]: {
            ...prev?.[storageKey]?.[id]?.[name],
            ...createCommentData
          }
        }
      }
    }));
  };

  const handleResetCurrentNotations = () => {
    setCurrentCommentary(null);
    setObservations(null);
  };

  return {
    handleSwitchStatus,
    setObservations,
    observations,
    UpdateComment,
    createComment,
    reviewsCurrent,
    getNestedPropertyValue,
    getColorByFieldName,
    currentCommentary,
    handleResetCurrentNotations,
    targetData: reviewsUpdates?.[storageKey],
    storage: observations?.[storageKey],
    reviewsNew: reviewsNew?.[storageKey],
    isLoadingSaveReview: isLoading,
    statusSaveReview: status
  };
};

class createInitialComment {
  constructor ({
    formField,
    formFieldInfo,
    item = null,
    nameId,
    status = null,
    comment = "",
    index = null
  }) {
    this.form_field = formField || "";
    this.form_field_info = formFieldInfo || "N/A";
    this.comment = comment;
    if (item) this.item = item;
    this.name = nameId;
    this.date = GetDate();
    this.time = Hours();
    if (status) this.status = { color: status };
    if (index) this.index = index;
  }
}
