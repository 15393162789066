/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { IconGeneric, IconImport } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { RiUploadCloudFill } from "react-icons/ri";
import { Cancelar, Continuar } from "pages/RefProductInter/Stilos";
import OrderProductionContext from "context/OrderProductionContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

export const ChangeItems = () => {
  const [openAlert] = AlertErrorAlert();

  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const { onCloseModal } = useContext(OrderProductionContext);
  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);

        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);
      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const Upload = () => {
    document.getElementById("display").click();
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
  };

  return (
    <>
      <input
        onChange={handleAttachment}
        type="file"
        id="display"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div onClick={Upload} className="d-flex justify-content-center my-4">
        <div id="redonder" className="d-flex justify-content-center">
          <IconImport color="#fff" as={RiUploadCloudFill} />
        </div>
      </div>
      <br />
      <br />
      <span className="text-mute">
        {nameAttachment && nameAttachment}
        {nameAttachment && (
          <IconGeneric
            onClick={() => removeItemFromArr()}
            className="m-1 text-danger"
            as={MdDelete}
          />
        )}
      </span>

      <Flex className="m-3">
        <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
        <Spacer />
        <Continuar
        // onClick={createValidate}
        >
          Aceptar
        </Continuar>
      </Flex>
    </>
  );
};
