/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  SimpleGrid,
  Switch,
  Text
} from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const validateForm = (form, openAlert) => {
  if (!form.name_tradeAgreement.trim()) {
    openAlert("Ingrese el nombre del acuerdo", "error");
    return false;
  }

  if (!form.code_tradeAgreement.trim()) {
    openAlert("Ingrese el código del acuerdo", "error");
    return false;
  }

  if (!form.observation.trim()) {
    openAlert("Ingrese observaciones", "error");
    return false;
  }

  return true;
};

const TradeAgreementForm = () => {
  const [openAlert] = AlertErrorAlert();

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const initialForm = {
    name_tradeAgreement: "",
    code_tradeAgreement: "",
    observation: "",
    status_tradeAgreement: 1
  };

  const [form, handleInputChange, resetForm, setForm] = useForm(initialForm);
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (dataToEdit) {
      setForm(dataToEdit);
      setCheck(dataToEdit.status_tradeAgreement === 1);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm(form, openAlert)) return;

    if (dataToEdit) {
      const data = {
        ...form,
        status_tradeAgreement: form.status_tradeAgreement ? 1 : 0,
        id: dataToEdit.id
      };
      console.log(data);
      editData(data);
    } else {
      if (!validateForm(form, openAlert)) return;
      createData(form);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Acuerdo Comercial" : "Crear Acuerdo Comercial"}
      </Text>
      <Divider mb={3} />

      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="code_tradeAgreement">
              Código
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_tradeAgreement"
              id="code_tradeAgreement"
              value={form.code_tradeAgreement}
              onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={3}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="name_packaging">
              Nombre Acuerdo Comercial
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_tradeAgreement"
              id="name_tradeAgreement"
              value={form.name_tradeAgreement}
              onChange={handleInputChange}
              className="h-auto"
              maxLength={80}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="code_packaging">
              Observaciones
            </LabeFormlStyled>

            <InputFormStyled
              type="text"
              name="observation"
              id="observation"
              value={form.observation}
              onChange={handleInputChange}
              maxLength={5000}
            />
          </div>
        </SimpleGrid>

        {dataToEdit && (
          <div>
            <LabeFormlStyled htmlFor="status_tradeAgreement">
              Activado
            </LabeFormlStyled>
            <FormControl>
              <Switch
                name="status_tradeAgreement"
                id="status_tradeAgreement"
                size="lg"
                value={form.status_tradeAgreement}
                onChange={changeCheck}
                isChecked={check}
              />
            </FormControl>
          </div>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default TradeAgreementForm;
