import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import React, { useContext } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import styled from "styled-components";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;
  border-bottom: 1px solid #bababa;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuThirdParties = ({ data }) => {
  const {
    onOpenEdit,
    setDataToEdit,
    onOpenDelete,
    setDataToDelete,
    onOpenShow,
    setDataToShow
  } = useContext(ThirdPartiesContext);

  const handleEdit = (e) => {
    // console.log(data);
    setDataToEdit(data);
    onOpenEdit();
  };

  const handleDelete = (e) => {
    // console.log('eliminar', data);
    setDataToDelete(data);
    onOpenDelete();
  };

  const handleShow = (e) => {
    // console.log('ver', data);
    setDataToShow(data);
    onOpenShow();
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={handleShow}>
          <IconMenuRUD as={MdRemoveRedEye} />
          Ver
        </MenuItemStyled>

        {/* <Divider /> */}

        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>

        {/* <Divider /> */}

        <MenuItemStyled py={3} onClick={handleDelete}>
          <IconMenuRUD as={MdDelete} />
          Eliminar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default MenuThirdParties;
