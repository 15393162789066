/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Tooltip } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetReferenciaOut
} from "request/Inventory/__InventoryCRUD";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftAuxExit,
  arrayRigthAuxExit,
  arrayRigthUserAuxExit,
  inventoryNodeInputFields
} from "./Array/ArrayExport";
import { theadBalanceAssistant } from "./Array/ArrayInventoryControl";
import { FormDeparturesAssistant } from "./SearchTabInventory/FormDeparturesAssistant";
import { TBodyBalanceAssistant } from "./TbodyTable/TBodyBalanceAssistant";
import { BiFilter } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

export const AuxOutputs = () => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["authorization_code", "item_id", "operation", "operation_initial", "date_final", "date_initial"], nestedProp: "searchAdvance" });

  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [totalReference, setTotalReference] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const tdTotalOut = [1, 2, 3, 4, 5, 6, 7, 8];
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: null,
  });

  const { searchInformacion, valueQualified } = values;

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    dispacthRedux(clearSearch());
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    setTotalReference(null);
    setCurrentPage([]);
  };


  const handleSubmit = (data, fields) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: data?.qualifiedUser_id[0]?.value || data?.qualifiedUser_id?.value || data?.qualifiedUser_id
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields,
    };

    exportColumnBy(info);
  };


  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_AUXILIAR_EXIT
      );
      if (res.status === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const GetReferenceOut = async (body) => {
    setLoading(true);
    try {
      const res = await __GetReferenciaOut(token, page, body);
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (res?.data?.status?.data?.data.length !== 0) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        const totales = res?.data?.status?.data?.data.length;
        setTotalReference(res?.data?.status?.data?.data[totales - 1]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const dispacthRedux = useDispatch();
  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && {
        qualifiedUser_id: valueQualified.value,
      }),
    };

    if (Object.values(data).length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      dispacthRedux(setSearch(data));
      GetReferenceOut(data);
      return;
    }
    dispacthRedux(clearSearch());
  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZoneValue]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, []);

  useEffect(() => {
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);


  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const nodeList = {
    1: arrayLeftAuxExit?.concat(arrayRigthUserAuxExit),
    0: arrayLeftAuxExit?.concat(arrayRigthAuxExit),
  }[authUser?.is_qualified_user]

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={nodeList}
        selectNodeList={inventoryNodeInputFields}
        enableAdvance={true}
      />

      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeftAuxExit}
          listRight={
            authUser.is_qualified_user === 1
              ? arrayRigthUserAuxExit
              : arrayRigthAuxExit
          }
          handleSubmit={handleSubmit}
          dafaulActive={[]}
        />
      </ModalGeneric> */}

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormDeparturesAssistant
          onClose={onCloseSearch}
          handleSearchSubmit={setSearchAdvanced}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Auxiliar salidas</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getInventoryRefGoOut"}
          allAccess={allAccess}
          myPermission={myPermission}
          findHiddenInput={true}
          setValues={setValues}
        />

        <ExportDetail onOpen={onOpenExport} />

        {/* {allAccess ? (
          <ExportDetail onOpen={onOpenExport}>
            {changeViewSearch ? (
              <Tooltip placement="top" hasArrow label="Limpiar filtro">
                <ButtonHeaderModule
                  onClick={backSearch}
                  bgColor="#F9A621"
                  className="  d-flex float-end"
                >
                  <IconGeneric color="#fff" as={AiOutlineClear} />
                </ButtonHeaderModule>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" hasArrow label="Filtro">
              <ButtonHeaderModule
                onClick={onOpenSearch}
                bgColor="#1F7AC3"
                className="mx-1   d-flex float-end"
              >
                <IconGeneric color="#fff" as={BiFilter} />
              </ButtonHeaderModule>
            </Tooltip>
          </ExportDetail>
        ) : myPermission?.includes("exportExcelInventory") ? (
          <ExportDetail onOpen={onOpenExport}>
            {changeViewSearch ? (
              <Tooltip placement="top" hasArrow label="Limpiar filtro">
                <ButtonHeaderModule
                  onClick={backSearch}
                  bgColor="#F9A621"
                  className="mx-1   d-flex float-end"
                >
                  <IconGeneric color="#fff" as={AiOutlineClear} />
                </ButtonHeaderModule>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" hasArrow label="Filtro">
              <ButtonHeaderModule
                onClick={onOpenSearch}
                bgColor="#1F7AC3"
                className="ms-1   d-flex float-end"
              >
                <IconGeneric color="#fff" as={BiFilter} />
              </ButtonHeaderModule>
            </Tooltip>
          </ExportDetail>
        ) : null} */}

        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadBalanceAssistant}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TBodyBalanceAssistant}
              subTotals={totalReference}
              titleTotal="Total"
              td={tdTotalOut}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
