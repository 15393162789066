/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { MaxLength } from "utils/MaxLength";

const validateForm = (form, openAlert, typeDoor) => {
  if (!form?.name_vehicularDoor.trim()) {
    openAlert("Ingrese el nombre de la puerta vehicular", "error");
    return false;
  }

  if (typeDoor === null) {
    openAlert("Ingrese el tipo de puerta vehicular", "error");
    return false;
  }

  return true;
};

const VehicularDoorForm = () => {
  const initialForm = {
    name_vehicularDoor: "",
    status_vehicularDoor: 1
  };

  const [typeDoor, setTypeDoor] = useState(null);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [form, handleInputChange, resetForm, setForm] = useForm();
  const [check, setCheck] = useState(true);
  const [openAlert] = AlertErrorAlert();

  useEffect(() => {
    if (dataToEdit) {
      setForm({ ...dataToEdit });
      setCheck(dataToEdit.status_vehicularDoor === 1);
      if (
        dataToEdit.type_vehicular_door_entry === 1 &&
        dataToEdit.type_vehicular_door_exit === 1
      ) {
        setTypeDoor({
          value: 2,
          label: "Entrada/Salida"
        });
      } else if (
        dataToEdit.type_vehicular_door_entry === 1 &&
        dataToEdit.type_vehicular_door_exit === 0
      ) {
        setTypeDoor({
          value: 0,
          label: "Entrada"
        });
      } else if (
        dataToEdit.type_vehicular_door_exit === 1 &&
        dataToEdit.type_vehicular_door_entry === 0
      ) {
        setTypeDoor({
          value: 1,
          label: "Salida"
        });
      }
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm(form, openAlert, typeDoor)) return;

    let type_vehicular_door_entry;
    let type_vehicular_door_exit;

    if (typeDoor.value === 2) {
      type_vehicular_door_entry = 1;
      type_vehicular_door_exit = 1;
    } else if (typeDoor.value === 1) {
      type_vehicular_door_entry = 0;
      type_vehicular_door_exit = 1;
    } else if (typeDoor.value === 0) {
      type_vehicular_door_entry = 1;
      type_vehicular_door_exit = 0;
    }

    if (dataToEdit) {
      const data = {
        ...form,
        id: dataToEdit.id,
        status_vehicularDoor: form?.status_vehicularDoor ? 1 : 0,
        type_vehicular_door_entry,
        type_vehicular_door_exit
      };
      editData(data);
    } else {
      const dataCreate = {
        ...form,
        status_vehicularDoor: form?.status_vehicularDoor ? 1 : 0,
        type_vehicular_door_entry,
        type_vehicular_door_exit
      };

      createData(dataCreate);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const dataTypeDoor = [
    {
      value: 0,
      label: "Entrada"
    },
    {
      value: 1,
      label: "Salida"
    },
    {
      value: 2,
      label: "Entrada/Salida"
    }
  ];

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Puerta vehicular" : "Crear Puerta vehicular"}
      </Text>
      <Divider mb={3} />

      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px" className="mb-3">
          <div>
            <LabeFormlStyled htmlFor="name_vehicularDoor">
              Nombre Puerta vehicular
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_vehicularDoor"
              id="name_vehicularDoor"
              value={form?.name_vehicularDoor || ""}
              onChange={handleInputChange}
              maxLength={MaxLength.config.vehicularDoor.name_vehicularDoor}
            />
          </div>

          {/* <div style={{ width: "40%" }}> */}
          <InputSelect
            tag={"Tipo Puerta vehicular"}
            data={dataTypeDoor}
            value={typeDoor}
            set={setTypeDoor}
          />
          {/* </div> */}
        </SimpleGrid>

        {dataToEdit && (
          <div>
            <LabeFormlStyled htmlFor="status_vehicularDoor">
              Activado
            </LabeFormlStyled>
            <div>
              <Switch
                name="status_vehicularDoor"
                id="status_vehicularDoor"
                size="lg"
                value={form?.status_vehicularDoor || ""}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          className="mt-5"
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default VehicularDoorForm;
