/* eslint-disable no-unused-vars */

import React, { useCallback, useRef, useState, useEffect } from "react";

import { IconGeneric } from "styled/Icons.styled";
import { HeaderEstadistica } from "./HeaderEstadistica";
import "./Style.css";
import TableOrder from "components/Tables/TableOrder";
import { TbodyForm } from "../../pages/Statistics/General/Tbody/TbodyForm";
import { theadEstadisticas } from "pages/Statistics/General/ArrayEstadisticas";
import { IoIosArrowForward } from "react-icons/io";
import { Bar } from "react-chartjs-2";
import { ExportPdfGrafica } from "pages/Statistics/General/ExportPdfGrafica";
import { ExportPdf } from "components/Export/ExportPdf";

import { __PostForm } from "request/Petitions/__Post";
import { Title } from "styled/Title";
import { estadisticaOptions } from "pages/Statistics/General/EstadisticaOptions";

export const Estadistica = ({
  title,
  data,
  loading,
  filterEst,
  setfilterEst,
  setCurrentPage
}) => {
  const [details, setDetails] = useState(false);
  const ref = useRef(null);
  const [rutaImage, setrutaImage] = useState(null);

  const [dateToday, setDateToday] = useState(null);
  const [userData, setUserData] = useState({
    labels: data && data?.map((data) => data?.person_name),
    datasets: [
      {
        label: "Revisados",
        data: data && data?.map((data) => data.form_revised),
        backgroundColor: ["#006EB8"]
      },
      {
        label: "Aprobados",
        data: data && data?.map((data) => data.form_approved),
        backgroundColor: ["#47B3E7"]
      },
      {
        label: "Devueltos",
        data: data && data?.map((data) => data.form_returned),
        backgroundColor: ["#9CD8F7"]
      }
    ]
  });

  const [total, setTotal] = useState(
    data &&
    [...data?.reduce((acc, { form_approved, form_returned, form_revised }) => {
      acc[0] += form_approved;
      acc[1] += form_returned;
      acc[2] += form_revised;
      return acc;
    }, [0, 0, 0])]
  );

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) {
      ExportPdf(
        "IdPdfFmmCorrectEntry",
        `Estadisticas_de_${title}_${dateToday}`
      );
    }
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  return (
    <>
      <div id="contenedorEstadistica">
        <div className="container">
          <div className="row">
            <div className="col">
              <Title size={"18px"} weight={600} className="mt-3">
                {title}
              </Title>
            </div>
          </div>
        </div>
        <ExportPdfGrafica
          id="IdPdfFmmCorrectEntry"
          rutaImage={rutaImage}
          thead={theadEstadisticas}
          data={data ? data?.statistics : []}
          tbody={TbodyForm}
          subtotal={total || []}
          title={`Estadisticas de ${title}`}
          dateToday={dateToday}
        />

        <div className="d-flex">
          <div id="contenedorResultado" className="px-2">
            {!loading && <Bar data={userData} ref={ref} options={estadisticaOptions('Usuario Calificado', 'Cantidad')}/>}
          </div>
        </div>
        <span
          className="mt-3 ms-3 text-primary cursorDetail"
          onClick={() => setDetails(!details)}
        >
          Ver más
          <IconGeneric width={"20px"} as={IoIosArrowForward} className="me-3" />
        </span>

        {details && (
          <div className="mt-2">
            <TableOrder
              thead={theadEstadisticas}
              data={data || []}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyForm}
              subTotals={total || []}
              titleTotal="Total"
            />
          </div>
        )}
      </div>
    </>
  );
};
