/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowCities } from "request/configuration/__Cities";
import { useSelector } from "react-redux";

export const FormShowCities = () => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const initialForm = {
    name_city: "",
    code_city: "",
    abbreviation: "",
    name_department: "",
    code_department: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToShow) {
      //   setForm(dataToShow);
      const initialpos = async () => {
        try {
          const res = await __ShowCities(token, dataToShow.id);
          setForm(res?.data?.status?.data);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        my={2}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        Ciudad
        {/* {dataToShow ? form.name_rol : ""} */}
      </Text>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Ciudad</LabeFormlStyled>
              <InputFake>{form.name_city}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Abreviación</LabeFormlStyled>
              <InputFake>
                {form.abbreviation === null ? "N/A" : form.abbreviation}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Codigo de Ciudad</LabeFormlStyled>
              <InputFake>
                {form.code_city === null ? "N/A" : form.code_city}
              </InputFake>
            </FormInputGroup>
          </div>

          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Departamento</LabeFormlStyled>
              <InputFake>
                {form.name_department === null ? "N/A" : form.name_department}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};
