/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __Sectors } from "request/configuration/__Sectors";
import TbodySectors from "../tables/TbodySectors";
import { FormSectors } from "../forms/FormSectors";
import FormSectorsShow from "../FormShow/FormSectorsShow";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const formEditSub = () => {
  return <FormSectors />;
};

const formShow = () => {
  return <FormSectorsShow />;
};

export const Sectors = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);  const { q = "" } = queryString.parse(location.search);
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Sector", order: "name_sector" },
    { name: "Estado", order: "status_sector" },
    { name: "Acciones", order: "" }
  ];

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formShow);
      setRequestEdit(environment.SECTORS_UPDATE);
      setRequestCreate(environment.SECTORS_CREATE);
      setRequestDelete(environment.SECTORS_DELETE);
  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configSectors", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.SECTORS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Sectores"></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={Title}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodySectors}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
