import { Box, Tooltip } from "@chakra-ui/react";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";

export const ConfigMenuDashBoard = () => {
  const Navigater = useNavigate();

  return (
    <Box display={"flex"} justifyContent="right" w="100%" m="0.5rem 0.5rem">
      <Box
        p={2}
        borderRadius={5}
        shadow={
          " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
        }
      >
        <Tooltip placement="top" hasArrow label="Crear Gráfico ">
          <span>
            <ButtonHeaderModule
              onClick={() => {
                Navigater("/explore");
              }}
              bgColor="#1F7AC3"
              className="me-1"
            >
              <IconGeneric color="#fff" as={MdOutlineCreateNewFolder} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>

        <Tooltip placement="top" hasArrow label="Gráficos">
          <span>
            <ButtonHeaderModule
              onClick={() => {
                Navigater("/dashboard");
              }}
              bgColor="#cf84159d"
              className="me-1"
            >
              <IconGeneric color="#fff" as={AiOutlineHome} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
