import React, { useContext } from 'react'
import { IconMenuRUD } from 'styled/Icons.styled'
import { RiEdit2Fill } from 'react-icons/ri'
import { GiCardDiscard } from 'react-icons/gi'
import { AiOutlineFileDone } from 'react-icons/ai'
import { HrDividerNoMargin } from 'styled/Line.styled'
import PassCrudContext from 'context/PassCrudContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import queryString from 'query-string'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'
import { AuthContext } from 'context/AuthContext'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsBorrador = ({ data, handlePresent }) => {
  const { setDataToEdit } = useContext(PassCrudContext)
  const navigate = useNavigate()
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const { authUser } = useContext(AuthContext)

  const options = {
    'Pase de entrada': 'ENTRADA',
    'Pase de salida': 'DESPACHO'
  }

  const handleEdit = async e => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', options[movement])
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(data)
      return navigate(
        `/formInt?qualifiedUser=${data.name_qualified}&numberForm=${
          data.passForm_number
        }&id=${data.id}&status=${
          data.status_entryPass || data.status_dispatchPass
        }&typeO=${data.status_dispatchPass ? 'Dispatch' : ''}`
      )
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const changueStatus = async status => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', options[movement])
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000)
      if (status === 'PRESENTADO') {
        return handlePresent(status)
      }
      if (status === 'DESECHADO') {
        return handlePresent(status)
      }
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { EntryPass, OutputPass } = localPermissions

  const { changeStatus: changeStatusEntryPass, update: updateEntryPass } =
    EntryPass
  const { update: updateOutputPass, changeStatus: changeStatusOutputPass } =
    OutputPass


  return (
    <>
      {(hasCommonElements(permissionList, [
        ...updateOutputPass,
        ...updateEntryPass
      ]) ||
        authUser.admin === 1) && (
        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>
      )}
      <HrDividerNoMargin />

      {(hasCommonElements(permissionList, [
        ...changeStatusOutputPass,
        ...changeStatusEntryPass
      ]) ||
        authUser.admin === 1) && (
        <MenuItemStyled
          onClick={() =>
            navigate(
              `/pases?numberForm=${
                data.passForm_number
              }&id=${data.id}&status=${
                data.status_entryPass || data.status_dispatchPass
              }&typeO=${data.status_dispatchPass ? 'Dispatch' : ''}`
            )
          }
          py={3}
        >
          <IconMenuRUD color='#03C39A' as={AiOutlineFileDone} />
          Presentar
        </MenuItemStyled>
      )}

      <HrDividerNoMargin />
      {(hasCommonElements(permissionList, [
        ...changeStatusOutputPass,
        ...changeStatusEntryPass
      ]) ||
        authUser.admin === 1) && (
        <MenuItemStyled onClick={() => changueStatus('DESECHADO')} py={3}>
          <IconMenuRUD color='#d92525' as={GiCardDiscard} />
          Desechar
        </MenuItemStyled>
      )}
    </>
  )
}
