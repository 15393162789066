import { Box, SimpleGrid } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

const FormSearch = ({ onClose, setSearchAdvanced }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["invoice", "date1", "date2", "type", "payment_method", "valueMethod", "valueDocument"], nestedProp: "searchAdvance" });
  const [valueDocument, setValueDocument] = useState(state?.valueDocument);
  const [valueMethod, setValueMethod] = useState(state?.valueMethod);

  const [values, handleInputChange, reset] = useForm({
    invoice: state?.invoice,
    date1: state?.date1,
    date2: state?.date2,
  });

  const { invoice, date1, date2 } = values;

  const dataDocument = [
    { value: 1, label: "Documento" },
    { value: 2, label: "Pesaje de basculas" }
  ];

  const dataMethodPay = [
    { value: 1, label: "Manual" },
    { value: 2, label: "Transferencia" }
  ];
  const handleSubmit = () => {
    const data = {
      ...values,
      ...(valueDocument && { type: valueDocument?.value }),
      ...(valueMethod && { payment_method: valueMethod?.value }),
    };
    setParamsSearch({
      params: {
        ...handleSanitizedObj(data),
        ...(valueDocument && { valueDocument }),
        ...(valueMethod && { valueMethod })
      }
    });
    setSearchAdvanced(data);
    // console.log("info desde form", data);
  };
  return (
    <>
      <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
        <InputSelect
          tag={"Tipo de documento"}
          data={dataDocument}
          set={setValueDocument}
          value={valueDocument}
          size={100}
        />

        <LabelGroup
          tipo={"tex"}
          name={"invoice"}
          tag={"Factura"}
          size={100}
          value={invoice}
          onChange={handleInputChange}
          desicion={false}
        // maxLength={2}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
        <LabelGroup
          tipo={"date"}
          name={"date1"}
          tag={"Fecha inicial"}
          size={100}
          value={date1}
          onChange={handleInputChange}
          desicion={false}
        // maxLength={2}
        />

        <LabelGroup
          tipo={"date"}
          name={"date2"}
          tag={"Fecha final"}
          size={100}
          value={date2}
          onChange={handleInputChange}
          desicion={false}
        // maxLength={2}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
        <InputSelect
          tag={"Metodo de pago"}
          data={dataMethodPay}
          set={setValueMethod}
          value={valueMethod}
          size={100}
        />
      </SimpleGrid>

      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Box>
    </>
  );
};

export default FormSearch;
