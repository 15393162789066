import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import { FormInput } from "pages/RefProductInter/Stilos";
import Select from "react-select";

export const LabelExp = ({ name, tag, value, onChange, desicion, tipo }) => {
  return (
    <>
      <LabelStyled>{tag}</LabelStyled>
      <FormInput
        onChange={onChange}
        value={value}
        name={name}
        disabled={desicion}
        id={desicion === true ? "noptions" : ""}
        type={tipo}
        isClearable={true}
      />
    </>
  );
};

export const InputCobro = ({
  tag,
  setCobranza,
  setPlaceCo,
  placeCo,
  cobranza,
  data
}) => {
  const handleSelectChange = (items) => {
    setCobranza(items.value);
    setPlaceCo(items.label);
  };
  return (
    <>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        // defaultValue={{ label: cobranza, value: cobranza }}
        value={{ label: placeCo, value: cobranza }}
        options={data}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </>
  );
};

export const InputGrouLicense = ({
  partidas,
  tag,
  setValor,
  setExpDate,
  error,
  ...props
}) => {
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.name_license,
        value: element.id
      });
    });
    return valores;
  };

  /* const handleSelectChange = (items) => {

    const licensesUser = [];
    const fVenvicimient = [];
    const labelLicenses = [];

    for (let i = 0; i < items.length; i++) {

      let datos = partidas.find(
        (info) => info.name_license === items[i]?.value
      );

      console.log("valor", datos);

      licensesUser.push(datos.id);
      fVenvicimient.push(datos.expireDate_license);
      labelLicenses.push(datos.name_license);
    }

    setValor(licensesUser);
    setExpDate(fVenvicimient);
  }; */

  const handleSelectChange = (items) => {
    // console.log("valor", items);
    setValor(items.value);
    /* setPlaceCo(items.label); */
  };

  return (
    <>
      <LabelStyled error={error}>{tag}</LabelStyled>
      <Select
        {...props}
        closeMenuOnSelect={false}
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </>
  );
};

export const InputDocumentReque = ({ partidas, tag, setValor, valor }) => {
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.name_document,
        value: element.id
      });
    });
    return valores;
  };
  const handleSelectChange = (items) => {
    const documentlabel = [];
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find((info) => info.id === items[i]?.value);
      documentlabel.push({ label: datos.name_document, value: datos.id });
    }
    setValor(documentlabel);
  };
  return (
    <>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        value={valor}
        isMulti
        options={selectValor(partidas)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </>
  );
};

export const SelectMultiple = ({
  data,
  tag,
  set,
  get,
  nameProperty,
  required = false,
  ...props
}) => {
  const selectValor = (optionesVa) => {
    return optionesVa.map((element) => ({
      label: element?.[nameProperty],
      value: element?.id
    }));
  };

  const handleSelectChange = (items) => {
    const documentLabels = items.map((item) => {
      const selectedData = data.find((info) => info.id === item?.value);
      return { label: selectedData?.[nameProperty], value: selectedData.id };
    });
    set(documentLabels);
  };

  return (
    <>
      <LabelStyled>
        {tag}
        {required && (
          <span style={{ color: "red", fontSize: "12px" }}>*</span>
        )}
      </LabelStyled>
      <Select
        closeMenuOnSelect={false}
        value={get}
        isMulti
        options={selectValor(data)}
        onChange={(items) => handleSelectChange(items)}
        required={required}
        {...props}
      />
    </>
  );
};
