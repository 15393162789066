import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import { InputUnit } from "../InputSelectsConfig/InputSelectSubhe";
import { __BusinessUnitsAll } from "request/configuration/__BusinessUnits";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";
import TariffChapter from "searchBars/Tariffchapter";
import { isNumber, isString, isValid } from "utils/type-check-utils";
import { Regex } from "utils/Regex";

const SubheadingForm = () => {
  const [loading, setLoading] = useState(false);
  const [unidadCo, setUnidadCo] = useState([]);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const [unidad, setUnidad] = useState("");
  const [status, setStatus] = useState(1);
  const [statusVariable, setStatusVariable] = useState(1);
  const [placeU, setPlaceU] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [Tariff, setTariff] = useState({});

  const { dataToEdit, setDataToEdit, onCloseModal, editData, createData } =
    useContext(ConfigurationCRUDContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      code_subheading: "",
      assessment: "",
      description: "",
      subchapter: "",
      variable: "",
    },
    mode: "onChange",
  });

  const watchFields = watch();

  useEffect(() => {
    const fetchUnidadC = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("search", q);
      try {
        const res = await __BusinessUnitsAll(token, formData);
        setUnidadCo(res?.data?.status?.data);
      } catch (error) {
        console.error("Error fetching business units:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUnidadC();
  }, [token, q]);

  useEffect(() => {
    if (dataToEdit) {
      reset(dataToEdit);
      setUnidad(dataToEdit?.businessUnit_id);
      setTariff({
        value: dataToEdit?.tariffChapter_id,
        label: dataToEdit?.name_chapter,
      });
      setStatus(dataToEdit?.status_subheading);
      setStatusVariable(dataToEdit?.variable);
    }
  }, [dataToEdit, reset]);

  useEffect(() => {
    setValue("description", Tariff?.name_chapter || dataToEdit?.description);
    setValue("subchapter", Tariff?.category_chapter || dataToEdit?.subchapter);
  }, [Tariff, dataToEdit, setValue]);

  const cleanTariff = () => {
    setValue("subchapter", "");
    setDataToEdit((prev) =>
      isValid(prev)
        ? {
            ...prev,
            subchapter: "",
            category_chapter: "",
            name_chapter: null,
            tariffChapter_id: null,
          }
        : null
    );
  };

  const onSubmit = (formData) => {
    const information = {
      ...formData,
      businessUnit_id: unidad,
      tariffChapter_id: Tariff?.value,
      variable: statusVariable,
      status_subheading: status,
    };

    if (dataToEdit) {
      editData({ ...information, id: dataToEdit?.subheading_id });
    } else {
      createData(information);
    }
    onCloseModal();
    reset();
  };

  const handleRegex = (value, regex) => {
    if (!regex.test(value)) {
      openAlert(
        `El carácter ${value[value.length - 1]} no es permitido en este campo`,
        "error"
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar subpartida" : "Crear subpartida"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Controller
            name="code_subheading"
            control={control}
            rules={{
              required: "El codigo es necesario",
              validate: (value) =>
                handleRegex(value, /^[0-9]+$/) || "Solo se permiten números",
            }}
            render={({ field }) => (
              <LabelGroup
                {...field}
                tag="Codigo"
                size={45}
                desicion={false}
                maxLength={MaxLength.config.subheading.code_subheading}
                error={!!errors.code_subheading}
              >
                {errors.code_subheading && (
                  <span>{errors.code_subheading.message}</span>
                )}
              </LabelGroup>
            )}
          />

          <InputUnit
            data={unidadCo}
            tag="Unidad de comercial"
            size={45}
            setUnidad={setUnidad}
            unidad={unidad}
            setPlaceU={setPlaceU}
            placeU={placeU}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Controller
            name="assessment"
            control={control}
            rules={{
              validate: (value) =>
                !value ||
                handleRegex(value, /^[0-9]+$/) ||
                "Solo se permiten números",
            }}
            render={({ field }) => (
              <LabelGroup
                {...field}
                tag="Gravamen"
                size={45}
                desicion={false}
                maxLength={MaxLength.config.subheading.assessment}
                error={!!errors.assessment}
              >
                {errors.assessment && <span>{errors.assessment.message}</span>}
              </LabelGroup>
            )}
          />

          <TariffChapter
            label="Capítulo arancelario"
            cleanCallBack={cleanTariff}
            set={setTariff}
            size={45}
            value={Tariff}
            placeholder="Ingrese nombre del arancel o código del arancel"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Controller
            name="description"
            control={control}
            rules={{
              required: "La descripción es necesaria",
              validate: (value) =>
                isValid(value) || "La descripción es requerida",
            }}
            render={({ field }) => (
              <LabelGroup
                {...field}
                tag="Descripción"
                size={45}
                desicion={isString(Tariff?.label) && Tariff?.label.length > 0}
                maxLength={MaxLength.config.subheading.description}
                error={!!errors.description}
              >
                {errors.description && (
                  <span>{errors.description.message}</span>
                )}
              </LabelGroup>
            )}
          />

          <Controller
            name="subchapter"
            control={control}
            render={({ field }) => (
              <LabelGroup
                {...field}
                tag="Subcapitulo"
                size={45}
                desicion={true}
                maxLength={MaxLength.config.subheading.subchapter}
                error={!!errors.subchapter}
              >
                {errors.subchapter && <span>{errors.subchapter.message}</span>}
              </LabelGroup>
            )}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dataToEdit && (
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag="¿Activo?"
              size={45}
            />
          )}

          <SwitchesGroup
            status={statusVariable}
            setStatus={setStatusVariable}
            tag="Variable"
            size={45}
          />
        </div>

        <Flex className="mt-5">
          <Spacer />
          <Box>
            <ButtonStyled bgColor="#bababa" onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>
            <ButtonStyled type="submit">
              {dataToEdit ? "Editar Subpartida" : "Crear Subpartida"}
            </ButtonStyled>
          </Box>
        </Flex>
      </form>
    </>
  );
};

export default SubheadingForm;
