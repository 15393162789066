import { Box, Icon } from '@chakra-ui/react'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import { useEffect, useState } from 'react'
import { IoMdCloseCircle } from 'react-icons/io'

export const ContainerRow = ({
  onChange,
  container,
  onRemove,
  capacity_container,
  weight_id = null,
  tare_container,
  tabIndex
}) => {
  const [Edit, setEdit] = useState(true)

  useEffect(() => {
    if (weight_id) {
      setEdit(false)
    }
  }, [weight_id])

  return (
    <Box display='flex' alignItems='center' gap={1} mt={5}>
      <Box
        alignItems='center'
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <LabelGroup
          name={'container'}
          tag={`Contenedor`}
          size={30}
          value={container}
          onChange={e => onChange('container', e.target.value)}
          desicion={tabIndex === 1 ? Edit : true}
        />

        <LabelGroup
          name={'capacity_container'}
          tag={'Capacidad'}
          size={30}
          value={capacity_container}
          onChange={e => onChange('capacity_container', e.target.value)}
          desicion={tabIndex === 1 ? Edit : true}
        />

        <LabelGroup
          name={'tare_container'}
          tag={'Peso tara'}
          size={30}
          value={tare_container}
          onChange={e => onChange('tare_container', e.target.value)}
          desicion={tabIndex !== 1}
        />
      </Box>
      {Edit === false && (
        <Icon
          as={IoMdCloseCircle}
          _hover={{
            cursor: 'pointer'
          }}
          color={'red'}
          onClick={onRemove}
        />
      )}
    </Box>
  )
}
