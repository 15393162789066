const { Chart } = require('react-chartjs-2')

export const CharLine = ({ GraphicsData }) => {
  const options = {
    title: {
      display: false
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            color: '#000000',
            zeroLineColor: '#000000',
            zeroLineWidth: 1,
            drawTicks: true
          }
        }
      ],
      xAxes: {
        0: {
          gridLines: {
            drawOnChartArea: false,
            offsetGridLines: false,
            zeroLineColor: '#000000',
            display: true,
            lineWidth: 2,
            drawTicks: true,
            zeroLineWidth: 2,
            color: '#000000'
          },
          ticks: {
            display: true,
            beginAtZero: true
          }
        }
      }
    },
    elements: {
      line: {
        borderColor: '#000000',
        lineTension: 0
      }
    }
  }

  if (!GraphicsData) return <>Loading</>

  return <Chart type='line' data={GraphicsData} />
}
