/* eslint-disable no-unused-vars */
import { Center, Td, Tr, Wrap, WrapItem } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import React from "react";

const TBodyProducts = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">
        {data.name_typeProduct}
      </Td>
      <Td className="text-center">{data.description_typeProduct} </Td>
      <Td isNumeric className="text-center">
        <MenuRUD data={data}/>
      </Td>
    </Tr>
  );
};

export default TBodyProducts;
