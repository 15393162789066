/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer
} from "@chakra-ui/react";
import { Continuar } from "pages/RefProductInter/Stilos";

export const ListPermisosUser = ({ isOpen, onClose, informacion }) => {
  const [scrollBehavior, setScrollBehavior] = useState("inside");
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        scrollBehavior={scrollBehavior}
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Permisos asignados</ModalHeader>
          <ModalBody>
            {informacion
              ? informacion.map((items, index) => (
                <div className="mx-4">
                  <Checkbox key={index} isDisabled isChecked>
                    {items.description_permission}
                  </Checkbox>
                </div>
              ))
              : ""}
          </ModalBody>
          <Flex className="m-3">
            <Spacer />
            <Continuar onClick={onClose}>Aceptar</Continuar>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};
