import React, { useContext } from "react";
import { Divider, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import CustomsCrudContext from "context/CustomsCrudContext";
// import ProcessCrudContext from "context/ProcessCrudContext";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const OptionsCustomsClearence = ({ data }) => {
  const {
    editCustomClearanceFormItems,
    seteditItemsCustomsClearance,
    deleteCustomClearanceFormItems,
    showAddItem,
    setShowAddItem
  } = useContext(CustomsCrudContext);

  const handleDelete = (e) => {
    deleteCustomClearanceFormItems(data);
  };

  const handleEdit = (e) => {
    editCustomClearanceFormItems(data);
    seteditItemsCustomsClearance(data);
    setShowAddItem(!showAddItem)
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {/* <MenuItemStyled py={3} onClick={handleShow}>
          <IconMenuRUD as={MdRemoveRedEye} />
          Ver
        </MenuItemStyled> */}

        <Divider />

        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>

        <Divider />

        <MenuItemStyled py={3} onClick={handleDelete}>
          <IconMenuRUD as={MdDelete} />
          Eliminar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default OptionsCustomsClearence;
