import styled from 'styled-components/macro';

export const ContainerFields = styled.div`
    display: grid;  
    width: 100%; 
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 50px 10px; 
    justify-items: strech;

    @media (max-width: 536px) {
        grid-template-columns: 1fr; 
        justify-items: center;
      }
`;

export const ContainerButton = styled.div`
    display: flex; 
    width: 100%; 
    justify-content: flex-end;

    @media (max-width: 915px) {
        margin-top: 20px;
    }

    @media (max-width: 529px) {
        justify-content: center;
      }
`;

export const ButtonStyled = styled.button`
    color: #fff; 
    padding: 1px 25px; 
    background-color: #1F7AC3; 
    border-radius: 5px; 
`;

export const ContainerFieldsPassword = styled.div`
    display: flex; 
    width: 100%; 
    height: 100%; 
    flex-direction: column; 
    justify-content: space-evenly;
    align-items: center;
`;

export const TitleFormPassword = styled.h1`
    font-size: 13px;
    padding-bottom: 30px;
`;

export const ContentNewPassword = styled.div`
    display: flex; 
    justify-content: space-between; 
    margin-top: 40px;
    align-items: center;

    @media (max-width: 538px) {
        flex-direction: column; 
    }
`;

export const Content = styled.div`
    @media (max-width: 538px) {
        display: flex; 
        justify-content: center; 
        flex-direction: column; 
        align-items: center;
    }
`;

export const EditPicture = styled.h1`
    &:hover {
        color: black;
        transition: all 1.1s;
    }
    position: absolute; 
    background-color: rgb(227 227 227 / 0);
    top: 0; 
    width: 110px; 
    height: 110px;
    color: transparent;
    font-size: 11px;
    border-radius: 50%;
    padding-top: 51px;

`;

