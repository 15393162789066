/* eslint-disable no-unused-vars */
import { Tooltip } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { RiDeviceRecoverFill } from 'react-icons/ri'
import { MdContentCopy, MdCancel } from 'react-icons/md'
import { BiShow } from 'react-icons/bi'
import { AuthContext } from 'context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'

import queryString from 'query-string'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { FiBell } from 'react-icons/fi'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import MonitoringContext from 'context/MonitoringContext'
import { BsRecordCircle } from 'react-icons/bs'

import Swal from 'sweetalert2'
import { FaUnlock } from 'react-icons/fa'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

export const ShowBtnPresentado = ({
  values,
  handlePresent,
  handleCopy,
  setFollowOrder,
  
}) => {
  const { authUser } = useContext(AuthContext)
  const [openAlert] = AlertErrorAlert()
  const { setDataToShow } = useContext(FormCrudCRUDContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { ExitNumber = '' } = queryString.parse(location.search)

  /*  const handleShow = () => {
    setDataToShow(values);
    if (ExitNumber !== "") {
      navigate(`/CheckForm?Number=${values.id}`);
    }
  }; */

  const { changeStatus} = useContext(FormCrudCRUDContext)

  const superadmin = useSelector(state => state.auth.dataUser.admin)
  const is_commerce = useSelector(state => state.auth.dataUser.is_commerce)
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const Formtype = 1
  const data = values
  const token = useSelector(state => state.auth.dataUser.token)

  const handleShow = async status => {
    setDataToShow(values)
    const infoEntry = {
      id: values.id,
      status_outputForm: status,
      movement: 'Salida'
    }

    if (status === 'REVISADO') {
      changeStatus(infoEntry)
    }

    navigate(`/CheckForm?Number=${values.id}`)
    return
  }

  const [isBlocked, setIsBlocked] = useState(false)
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    const helper = async () => {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMS')
      body.append('type', 4)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if (res?.data?.status?.code === 200) {
        setIsBlocked(false)
        console.log('desbloqueado')
      }
      if (res?.data?.status?.code === 400) {
        setIsBlocked(true)
        setMessageError(res?.data?.status?.message)
        console.log('bloqueado')
      }
    }

    helper()
  }, [])

  const showAlert = (title, message, callback = () => {}) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Desbloquear',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1e75bb',
      position: 'top'
    }).then(async result => {
      if (result.isConfirmed) {
        const body = new FormData()
        body.append('form_id', data?.id)
        body.append('type_of', 'FMMS')
        body.append('type', 2)
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
        setIsBlocked(false)
        callback()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { changeStatus: changeStatusOutputForm, copy: copyOutputForm } =
    localPermissions.Outputform

  return (
    <>
      {authUser.is_qualified_user !== 1 && isBlocked && (
        <Tooltip placement='top' hasArrow label='Desbloquear'>
          <span>
            <ButtonHeaderModule
              onClick={() => {
                showAlert(
                  '¿Deseas desbloquear el formulario?',
                  messageError,
                  () => {
                    openAlert('Formulario desbloqueado', 'success')
                  }
                )
              }}
              bgColor='#fe8d59'
              className='mx-1'
            >
              <IconGeneric
                className='text-white'
                color='#fff'
                width='16px'
                as={FaUnlock}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='#B8DB1A'
              onClick={onOpenCreateTrackingFmm}
              disabled={isBlocked}
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='red'
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
              className='mx-1'
              disabled={isBlocked}
            >
              <IconGeneric
                as={MdCancel}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(hasCommonElements(permissionList, changeStatusOutputForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Recuperar'>
          <span>
            <ButtonHeaderModule
              onClick={() => handlePresent('RECUPERADO')}
              bgColor='#1F7AC3'
              disabled={isBlocked}
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={RiDeviceRecoverFill}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, changeStatusOutputForm) ||
          authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Revisar'>
            <span>
              <ButtonHeaderModule
                onClick={() => handleShow('REVISADO')}
                className='mx-1'
                bgColor='#58B158'
                disabled={isBlocked}
              >
                <IconGeneric width='16px' className='text-white' as={BiShow} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(hasCommonElements(permissionList, copyOutputForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule onClick={handleCopy} bgColor='#03C39A'>
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
    </>
  )
}
