import { Flex, Spacer } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import InputSelectMultiple from 'components/InputSelect/InputSelectMultiple'
import environment from 'constants/apiConst'
import queryEnv from 'constants/queryConst'
import { useGlobalContext } from 'context/GlobalContext'
import { useForm } from 'hooks/useForm'
import { useFormBlocker } from 'hooks/useFormBlocker'
import { Storage } from 'hooks/useStorage'
import { LabelGroupTextArea } from 'pages/RefProductInter/LabelGroup'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { __GetDataReturnReason } from 'request/Pases/__CrudPass'
import { ButtonStyled } from 'styled/Buttons.styled'

export const FormReturnReason = ({ FormId, onClose, contextCurrent, propsBlockForm }) => {
  const token = useSelector(state => state.auth.dataUser.token)
  const [typification, setTypification] = useState(null)
  const [datatypification, setDataTypification] = useState(null)
  const { get } = Storage

  const {
    changeStatus,
    changeStatusCorregido,
    ReasonReturn,
    ReturnComentary,
    ReturnReason
  } = useContext(contextCurrent)
  const location = useLocation()
  const { pathname } = location

  const [values, handleInputChange] = useForm({
    reason_for_return: ''
  })

  const { requestData } = useGlobalContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: requestData,
  })

  const { reason_for_return } = values

  const GetDataReturnReason = useCallback(async () => {
    try {
      const res = await __GetDataReturnReason(token)
      const respopnse = res.data.status
      const infoUsuarios = respopnse?.data.map(item => ({
        value: item?.id,
        label: item?.name
      }))
      setDataTypification(infoUsuarios)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  useEffect(() => {
    GetDataReturnReason()
  }, [GetDataReturnReason])

  const { blockForm } = useFormBlocker({
    form_id: propsBlockForm?.form_id,
    type_of: propsBlockForm?.type_of,
    userProvidedEndpoint: propsBlockForm?.endpoint
  });

  const generarReturn = () => {
    blockForm(propsBlockForm?.type);
    const typificationtid = []
    const review = get(FormId)
    typification.forEach(({ value }) => typificationtid.push(value))
    const data = {
      ...ReturnReason,
      ...values,
      typification_id: typificationtid
    }

    const ValueReturn = {
      id: FormId,
      Corrected: true,
      reason_for_return: values.reason_for_return,
      status: ReturnReason.status_entryForm,
      typification_id: typificationtid
    }

    const reviewIntegrationCertificate = {
      id: FormId,
      status: ReturnReason.status_entryForm,
      review
    }

    if (
      pathname === '/CheckCorrectEntry' ||
      pathname === '/CheckCorrectOutput'
    ) {
      return changeStatusCorregido(data, FormId)
    }

    if (pathname === '/CheckIntegrationBox') {
      mutate({
        Endpoint: environment?.POST_STATUS_INTEGRATION,
        data: ValueReturn,
        PropertyBody: 'POST',
      }, {
        onSuccess: (data) => {
          if(data?.data?.status?.code !== 200) return;
          queryClient?.invalidateQueries({ queryKey: [queryEnv?.INTEGRATION_BOX] })
          navigate(`/ShowIntegrationBox?id=${FormId}`)
          onClose()
        }
      })
      return;
    }

    if (pathname !== '/CheckIntegrationBox') {
      return changeStatus(data, FormId)
    } else {
      changeStatus(data, FormId)
      ReasonReturn(ValueReturn, FormId)
      if (review && review?.length > 0) { ReturnComentary(reviewIntegrationCertificate, FormId) }
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2rem'
        }}
        className='mb-2 me-2'
      >
        <div style={{ width: '100%' }}>
          <InputSelectMultiple
            tag={'Motivo'}
            name={'reason_for_return'}
            data={datatypification}
            set={setTypification}
            value={typification}
          />
        </div>
      </div>
      {typification && typification[0] && typification[0]?.value && (
        <LabelGroupTextArea
          name={'reason_for_return'}
          value={reason_for_return || ''}
          tag={'Descripción'}
          onChange={handleInputChange}
          width={100}
        />
      )}
      <Flex className='my-3'>
        <ButtonStyled bgColor={'#bababa'} onClick={onClose}>
          Cancelar
        </ButtonStyled>
        <Spacer />
        {reason_for_return !== '' && (
          <ButtonStyled bgColor={'#03C39A'} onClick={generarReturn}>
            Guardar
          </ButtonStyled>
        )}
      </Flex>
    </>
  )
}
