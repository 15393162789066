import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuForm from "components/Menu/MenuForm";
import { NavLink, useNavigate } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";

const TBodyFormExitExpired = ({ data }) => {
  return (
    <Tr>
      <NavLink
        to={`/FormShowExit?ExitNumber=${data?.outputForm_id}`}
        className="ms-2"
      >
        <span className="formNumber">{data?.code_outputForm}</span>
      </NavLink>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.commercial_invoice}</Td>
      <Td className="text-center">{data?.status_outputForm}</Td>
      <Td className="text-center">
        <MenuForm data={data} />
      </Td>
    </Tr>
  );
};
export default TBodyFormExitExpired
