import React, { useEffect, useState } from "react";
import environment from "constants/apiConst";
import { useMutation } from "@tanstack/react-query";
import { ModalGeneric } from "./ModalGeneric";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { useGlobalContext } from "context/GlobalContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const ModalExtendDeadline = ({
  isOpen,
  onOpen,
  onClose,
  title,
  size = "sm",
  dataForm = {},
}) => {
  const location = useLocation();
  const { ExitNumber = "" } = queryString.parse(location.search);

  const [values, handleInputChange, reset, setValues] = useForm({
    term: dataForm?.term || "",
    ExitNumber: dataForm?.ExitNumber || "",
  });

  const { requestData } = useGlobalContext();
  const [openAlert] = AlertErrorAlert();

  const onChange = (valueAsString, valueAsNumber) => {
    const newTerm = Math.max(1, Math.min(365, valueAsNumber));
    if (isNaN(newTerm)) {
      setValues({ ...values, term: 0 });
    } else {
      setValues({ ...values, term: newTerm });
    }
  };

  const { isPending, mutate: mutateTerm } = useMutation({
    mutationFn: requestData,
  });

  const handleSubmit = () => {
    console.log("values", values);
    const config = {
      data: { id: ExitNumber, number_days_extension: parseFloat(values.term) },
      Endpoint: environment.UPDATE_FORM_EXIT,
      PropertyBody: "sendJSONContentPOST",
    };

    mutateTerm(config, {
      onSuccess: (data) => handleResponseTerm(data),
    });
  };

  const handleResponseTerm = (res) => {
    if (res?.data?.status?.code === 400) {
      // return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      console.log("handleResponseTerm", res?.data?.status);
      isOpen = false;
      const data = res?.data?.status?.data;
      onClose({ term: data.number_days_extension, expiration_final_date: data.expiration_final_date });
      return openAlert(res?.data?.status?.message, "success");
      // queryClient.invalidateQueries({ queryKey: ["getProductItemOP"] });
    }
  };

  useEffect(() => {
    if (dataForm?.term) {
      setValues({ term: dataForm?.term || "" });
    }
  }, [dataForm?.term]);

  return (
    <ModalGeneric
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      title={title}
      size={size}
    >
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <FormControl>
            <FormLabel htmlFor="term">Plazo (en días)</FormLabel>
            <NumberInput
              max={365}
              min={1}
              name="term"
              value={values?.term}
              defaultValue={values?.term}
              onChange={onChange}
            >
              <NumberInputField id="term" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Introduce el plazo en días (máximo 365)
            </FormHelperText>
          </FormControl>
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled onClick={handleSubmit}>Guardar</ButtonStyled>
        </Box>
      </form>
    </ModalGeneric>
  );
};

export default ModalExtendDeadline;
