/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { Tooltip, useDisclosure } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import {
  MdContentCopy,
  MdFactCheck,
  MdOutlineEditNotifications,
  MdCancel
} from 'react-icons/md'
import { AuthContext } from 'context/AuthContext'
import { FaRegCalendarTimes, FaWpforms } from 'react-icons/fa'
import { BiMoney } from 'react-icons/bi'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { AiOutlineFileSync } from 'react-icons/ai'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import { FiBell } from 'react-icons/fi'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import MonitoringContext from 'context/MonitoringContext'
import { BsRecordCircle } from 'react-icons/bs'

import { useStorageQuery } from 'hooks/useStorageQuery'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const ButtonsAproved = ({
  data,
  handlePresent,
  handleCopy,
  handleCaducar,
  setFollowOrder,
  isLoading = false
}) => {
  const { authUser } = useContext(AuthContext)

  const {
    newDispacthwithItem,
    isOpenAsoccietUnit,
    onCloseAsoccietUnit,
    onOpenAsoccietUnit,
    messageModalAsocciet,
    dataAsocciet,
    addItemPass
  } = useContext(FormCrudCRUDContext)

  const superadmin = useSelector(state => state.auth.dataUser.admin)
  const { admin, is_commerce, is_qualified_user } = useSelector(
    state => state.auth.dataUser
  )
  const { onOpenCreateTrackingFmm, getFollowOrder } =
    useContext(MonitoringContext)
  const Formtype = 2
  const location = useLocation() // navigate
  const { FormEntry = '' } = queryString.parse(location.search)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate()

  const handleSearchItem = () => {
    const sendData = {
      entryForm_id: FormEntry,
      qualifiedUser_id: data.qualifiedUser_id
    }

    if (FormEntry) {
      addItemPass(sendData)
    }
  }


  const tiempoTranscurrido = desde => {
    const fechaDesde = new Date(desde)
    const fechaActual = new Date()
    const diferenciaEnMilisegundos = fechaActual - fechaDesde
    const horasTranscurridas = Math.floor(
      diferenciaEnMilisegundos / (1000 * 60 * 60)
    )

    return horasTranscurridas
  }

  const VerifiedRadicar = () => {
  
    if (
      data?.status_entryForm === 'APROBADO' &&
      data?.use_dian_profiling === 1
    ) {
      const lastElement = data?.users[data?.users?.length - 1]

      if (lastElement) {
        const fechaInicial = `${lastElement.date_entryForm} ${lastElement.time_entryForm}`
        const TimeTranscurrido = tiempoTranscurrido(fechaInicial)
        const { profiling_deal_type_form, profiling_status } = data

        const ProfileBool =
          profiling_deal_type_form === null || profiling_deal_type_form === ''

        if (
          (TimeTranscurrido <= 0 && ProfileBool) ||
          (TimeTranscurrido <= 0 &&
            profiling_deal_type_form === 'Si' &&
            profiling_status !== 'Finalizado' &&
            TimeTranscurrido > 0)
        ) {
          openAlert(
            'El documento se encuentra en espera de revisión por DIAN',
            'error'
          )
        }

        if (
          profiling_deal_type_form === 'Si' &&
          profiling_status !== 'Finalizado'
        ) {
          openAlert('Documento se encuentra perfilado por DIAN', 'error')
        }

        if (
          profiling_deal_type_form === 'No' ||
          profiling_status === 'Finalizado' ||
          TimeTranscurrido > 0
        ) {
          handlePresent('AUTORIZADO')
        } else if (
          (TimeTranscurrido > 0 && ProfileBool) ||
          profiling_status === 'Finalizado'
        ) {
          handlePresent('AUTORIZADO')
        }
      }
    } else {
      handlePresent('AUTORIZADO')
    }
  }

  const handleNotify = async user_id => {
    const User = {
      id: user_id
    }
    try {
      const resp = await __Post(
        environment.POST_APROVED_ENTRY_AUTHORIZE,
        token,
        User
      )
      if (resp.data.status.code === 200) {
        openAlert('El formulario ha sido notificado', 'success')
      } else {
        openAlert(resp?.data?.status?.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { setData } = useStorageQuery('MovementSRT', null)

  const goToFormatSRT = () => {
    const operation = `${data?.code_operation} - ${data?.short_name_operation}`
    setData('Ingreso')
    navigate(
      `/FormatSRT?ExitNumber=${
        data?.num_exit_form_id
      }&FormType=${'Ingreso'}&Operation=${operation}`
    )
  }

  const { permissionList } = useSelector(state => state.permissions)
  const {
    changeStatus: changeStatusEntryForm,
    copy: copyEntryForm,
    notifyToAuthorize: notifyToAuthorizeEntryForm,
    createPass: createPassEntryForm
  } = localPermissions.Entryform

  const handleNotifyToTransfer = async () => {
    try {
      const resp = await __Post(environment?.ENTRYFORM_TRANSFER_NOTIFICATION, token, {
        id: data.id
      })
      if (resp.data.status.code === 200) {
        openAlert('El formulario ha sido notificado', 'success')
      } else {
        openAlert(resp?.data?.status?.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/*  <ModalAdvertencia
        isOpen={isOpen}
        onClose={onClose}
        title={`Advertencia`}
        titleBody={"Advertencia"}
        generar={() => creardespacho()}
      /> */}
      <ModalAdvertencia
        isOpen={isOpenAsoccietUnit}
        onClose={onCloseAsoccietUnit}
        title={`Advertencia`}
        titleBody={` ${messageModalAsocciet && messageModalAsocciet}`}
        generar={() => addItemPass(dataAsocciet)}
      />

      {(is_commerce === 1 || admin === 1) &&
        !is_qualified_user &&
        (data?.code_operation === 309 || data?.code_operation === 310) && (
          <Tooltip placement='top' hasArrow label='Formato SRT'>
            <span>
              <ButtonHeaderModule onClick={goToFormatSRT} bgColor='#191830'   disabled={isLoading}>
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={FaWpforms}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Hacer seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='#B8DB1A'
              onClick={onOpenCreateTrackingFmm}
              className='mx-1'
              disabled={isLoading}
            >
              <IconGeneric
                as={BsRecordCircle}
                color='#fff'
                width='16px'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {(is_commerce === 1 || superadmin === 1) && (
        <Tooltip placement='top' hasArrow label={'Detener seguimiento'}>
          <span>
            <ButtonHeaderModule
              bgColor='red'
              onClick={() => getFollowOrder(data, setFollowOrder, Formtype)}
              disabled={isLoading}
            >
              <IconGeneric
                width='16px'
                as={MdCancel}
                color='#fff'
                className='text-white'
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, copyEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Copiar'>
          <span>
            <ButtonHeaderModule
              onClick={handleCopy}
              className='mx-1'
              bgColor='#03C39A'
              disabled={isLoading}
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdContentCopy}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, createPassEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Ingresar'>
          <span>
            <ButtonHeaderModule
              onClick={() => handleSearchItem()}
              bgColor='#BABABA'
              disabled={isLoading}
            >
              <IconGeneric width='16px' className='text-white' as={BiMoney} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {authUser.is_qualified_user !== 1 &&
        (authUser.admin === 1 || authUser.is_commerce === 1) &&
        (hasCommonElements(permissionList, changeStatusEntryForm) ||
          authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Radicar'>
            <span>
            
              <ButtonHeaderModule
                onClick={() => VerifiedRadicar()}
                className='mx-1'
                bgColor='#03C39A'
                disabled={isLoading}
              >
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={MdFactCheck}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, changeStatusEntryForm) ||
          authUser.admin === 1) && (
          <Tooltip placement='top' hasArrow label='Devolver'>
            <span>
              <ButtonHeaderModule
                onClick={() => handlePresent('DEVUELTO')}
                bgColor='#d92525'
                className={`${authUser.is_qualified_user === 1 && 'mx-1'}`}
                disabled={isLoading}
              >
                <IconGeneric
                  width='16px'
                  className='text-white'
                  as={AiOutlineFileSync}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}

      {authUser.is_qualified_user === 1 ? (
        ''
      ) : (
        <Tooltip placement='top' hasArrow label='Caducar'>
          <span>
            <ButtonHeaderModule
              onClick={handleCaducar}
              className='mx-1'
              bgColor='#F9A621'
              disabled={isLoading}
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={FaRegCalendarTimes}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}

      {(hasCommonElements(permissionList, notifyToAuthorizeEntryForm) ||
        authUser.admin === 1) && (
        <Tooltip placement='top' hasArrow label='Notificar para autorizar'>
          <span>
            <ButtonHeaderModule
              onClick={() => handleNotify(data.id)}
              bgColor='#F1C40F'
              className={`${authUser.is_qualified_user !== 1 && 'ms-1'}`}
              disabled={isLoading}
            >
              <IconGeneric
                width='16px'
                className='text-white'
                as={MdOutlineEditNotifications}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}
      {
        ((authUser.is_qualified_user === 1 || authUser.admin === 1)&& (data?.code_operation >= 700 && data?.code_operation < 800)) && (
          <>
            <Tooltip placement='top' hasArrow label='Notificar para traslado'>
              <span>
                <ButtonHeaderModule
                  onClick={handleNotifyToTransfer}
                  bgColor='#F1C40F'
                  className={`${authUser.is_qualified_user !== 1 && 'ms-1'}`}
               //   disabled={isLoading}
                >
                  <IconGeneric
                    width='16px'
                    className='text-white'
                    as={MdOutlineEditNotifications}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </>
        )
      }
    </>
  )
}

export default ButtonsAproved
