import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const CorrectionRestTerritory = ({
  data,
  handleInputChangeCorrect,
  code,
  dataCurrent,
  StateDisble
}) => {
  const {
    dataDepartamentNew,
    StateCountry,
    setStateCountry,
    setCustomsadministration,
    customsadministration,
    getAdminCustom,
    adminCustom
  } = useContext(FormCrudCRUDContext);
  const {
    num_dex_entryFormCorrected,
    dex_date_entryFormCorrected,
    num_import_declaration_corrected,
    integration_box_certificate_corrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    customs_administration_corrected,
    name_departmentCorrected
  } = data;

  const {
    num_dex_entryForm,
    dex_date_entryForm,
    name_department,
    num_import_declaration,
    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    code_custom,
    name_custom,
    code_department,
    department_id
  } = dataCurrent;

  const adminCuston = code_custom + " " + name_custom;
  const departConcat = code_department + " " + name_department;

  useEffect(() => {
    getAdminCustom();
  }, []);
  return (
    <>
      <LabelGroup
        name={"num_dex_entryFormCorrected"}
        value={num_dex_entryFormCorrected || ""}
        onChange={handleInputChangeCorrect}
        tag={"Nº DEX"}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_dex_entryForm) !== safelyConvertToString(num_dex_entryFormCorrected) && "BorderCorrect"
        }
      />
      <LabelGroup
        onChange={handleInputChangeCorrect}
        name={"dex_date_entryFormCorrected"}
        value={dex_date_entryFormCorrected || ""}
        tag={"Fecha del DEX"}
        size={100}
        tipo="date"
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(dex_date_entryForm) !== safelyConvertToString(dex_date_entryFormCorrected) && "BorderCorrect"
        }
      />
      <LabelGroup
        name={"num_commercial_invoice_corrected"}
        value={
          num_commercial_invoice_corrected || ""
        }
        tag={"Nº Factura Comercial"}
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_commercial_invoice) !== safelyConvertToString(num_commercial_invoice_corrected) &&
          "BorderCorrect"
        }
      />
      {!StateDisble
        ? (
          <div style={{ width: "100%" }}>
            <InputSelect
              className={department_id !== StateCountry?.value && "BorderCorrect"}
              data={dataDepartamentNew && dataDepartamentNew}
              tag={"Departamento"}
              set={setStateCountry}
              value={StateCountry}
            />
          </div>
        )
        : (
          <LabelGroup
            name={"name_departmentCorrected"}
            value={name_departmentCorrected ? `${StateCountry?.label}` : ""}
            onChange={handleInputChangeCorrect}
            tag={"Departamento"}
            size={100}
            desicion={true}
            changeValue={department_id !== StateCountry?.value && "BorderCorrect"}
          />
        )}

      <LabelGroup
        name={"num_import_declaration_corrected"}
        value={
          num_import_declaration_corrected || ""
        }
        tag={"Nº Declaración de Importación"}
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_import_declaration) !== safelyConvertToString(num_import_declaration_corrected) &&
          "BorderCorrect"
        }
      />

      {!StateDisble
        ? (
          <div style={{ width: "100%" }}>
            <InputSelect
              className={
                adminCuston !== customsadministration?.label && "BorderCorrect"
              }
              tag={"Administración de Aduanas"}
              data={adminCustom && adminCustom}
              set={setCustomsadministration}
              value={customsadministration || []}
            />
          </div>
        )
        : (
          <LabelGroup
            name={"customs_administration_corrected"}
            onChange={handleInputChangeCorrect}
            tag={"Administración de Aduanas"}
            size={100}
            desicion={!!StateDisble}
            value={customsadministration ? customsadministration?.label : ""}
            changeValue={
              adminCuston !== customsadministration?.label && "BorderCorrect"
            }
          />
        )}

      {(code === 309 ||
        code === 310 ||
        code === 314 ||
        code === 316 ||
        code === 318) && (
        <>
          <LabelGroup
            name={"num_exit_form_corrected"}
            value={
              num_exit_form_corrected || ""
            }
            onChange={handleInputChangeCorrect}
            tag={"Nº Autorización de salida"}
            size={100}
            desicion={!!StateDisble}
            changeValue={
              num_exit_form !==
                num_exit_form_corrected && "BorderCorrect"
            }
          />
          <LabelGroup
            name={"integration_box_certificate_corrected"}
            value={
              integration_box_certificate_corrected || ""
            }
            tag={"Nº Autorización de C. Inte"}
            onChange={handleInputChangeCorrect}
            size={100}
            desicion={!!StateDisble}
            changeValue={
              safelyConvertToString(integration_box_certificate) !==
                safelyConvertToString(integration_box_certificate_corrected) && "BorderCorrect"
            }
          />
        </>
      )}
    </>
  );
};

/*
import InputSelect from "components/InputSelect/InputSelect";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage,
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus,
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment,
} from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { useState } from "react";

export const CorrectionRestTerritory = ({
  data,
  handleInputChangeCorrect,
  code,
  dataCurrent,
  StateDisble,
}) => {
  const { dataDepartamentNew, StateCountry, setStateCountry } =
    useContext(FormCrudCRUDContext);
  const {
    num_dex_entryFormCorrected,
    dex_date_entryFormCorrected,
    num_import_declaration_corrected,
    integration_box_certificate_corrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,

    code_customCorrected,
    name_customCorrected,
    customAdministrationCorrected_id,
    code_departmentCorrected,
    name_departmentCorrected,
  } = data;

  const {
    num_dex_entryForm,
    dex_date_entryForm,
    name_department,
    num_import_declaration,

    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    reviews,
    code_custom,
    name_custom,
    code_department,
  } = dataCurrent;

  const FormId = dataCurrent?.id;
  const Status = dataCurrent.status_entryFormCorrected;
  let departConcat = code_department + " " + name_department;

  let adminCuston = code_custom + " " + name_custom;

  // let adminCustonCorrect = code_customCorrected + " " + name_customCorrected;

  const [CurrentPos, setCurrentPos] = useState(0);
  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );
  const {
    isOpenComment,
    onOpenComment,
    onCloseComment,
    setCustomsadministration,
    customsadministration,
    getAdminCustom,
    adminCustom,
  } = useContext(FormCrudCRUDContext);
  useEffect(() => {
    getAdminCustom();
  }, []);

  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        //console.log("No encontrado");
      }
    } else {
      return "";
    }
  };

  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;

    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      console.log(name, FormId, FindElement);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpenComment,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpenComment,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpenComment,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenComment}
        onOpen={onOpenComment}
        onClose={onCloseComment}
        title="Comentario"
        size="xl"
      >
        {" "}
        <ModalComment
          obj={GetLocalStorage(FormId)}
          Pos={CurrentPos}
          FormId={FormId}
          onClose={onCloseComment}
          setVerifiedStatus={setVerifiedStatus}
        />
      </ModalGeneric>
      {dataCurrent.status_entryFormCorrected &&
      dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"num_dex_entryFormCorrected"}
          value={
            num_dex_entryFormCorrected ? num_dex_entryFormCorrected : "N/A"
          }
          onChange={handleInputChangeCorrect}
          tag={"Nº DEX"}
          size={100}
          ColorCustom={(x) =>
            num_dex_entryForm !== num_dex_entryFormCorrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          desicion={false}
          placeholder={"Nº DEX"}
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"num_dex_entryFormCorrected"}
          value={
            num_dex_entryFormCorrected ? num_dex_entryFormCorrected : "N/A"
          }
          onChange={handleInputChangeCorrect}
          tag={"Nº DEX"}
          size={100}
          label={TooltipComentary("num_dex_entryFormCorrected")}
          desicion={StateDisble ? true : false}
          changeValue={
            num_dex_entryForm !== num_dex_entryFormCorrected && "BorderCorrect"
          }
        />
      )}

      {dataCurrent.status_entryFormCorrected &&
        dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            onChange={handleInputChangeCorrect}
            name={"dex_date_entryFormCorrected"}
            value={
              dex_date_entryFormCorrected ? dex_date_entryFormCorrected : "N/A"
            }
            tag={"Fecha del DEX"}
            size={100}
            tipo="date"
            ColorCustom={(x) =>
              dex_date_entryForm !== dex_date_entryFormCorrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            desicion={false}
            readOnly={true}
            placeholder={"Fecha del DEX"}
            onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            onChange={handleInputChangeCorrect}
            name={"dex_date_entryFormCorrected"}
            value={
              dex_date_entryFormCorrected ? dex_date_entryFormCorrected : "N/A"
            }
            label={TooltipComentary("dex_date_entryFormCorrected")}
            tag={"Fecha del DEX"}
            size={100}
            tipo="date"
            desicion={StateDisble ? true : false}
            changeValue={
              dex_date_entryForm !== dex_date_entryFormCorrected &&
              "BorderCorrect"
            }
          />
        )}

        {dataCurrent.status_entryFormCorrected &&
        dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"num_commercial_invoice_corrected"}
            value={
              num_commercial_invoice_corrected
                ? num_commercial_invoice_corrected
                : "N/A"
            }
            tag={"Nº Factura Comercial"}
            onChange={handleInputChangeCorrect}
            size={100}
            ColorCustom={(x) =>
              num_commercial_invoice !== num_commercial_invoice_corrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            desicion={false}
            readOnly={true}
            placeholder={"Nº Factura Comercial"}
            onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            name={"num_commercial_invoice_corrected"}
            value={
              num_commercial_invoice_corrected
                ? num_commercial_invoice_corrected
                : "N/A"
            }
            label={TooltipComentary("num_commercial_invoice_corrected")}
            tag={"Nº Factura Comercial"}
            onChange={handleInputChangeCorrect}
            size={100}
            desicion={StateDisble ? true : false}
            changeValue={
              num_commercial_invoice !== num_commercial_invoice_corrected &&
              "BorderCorrect"
            }
          />
        )}

        {!StateDisble ? (
          <div style={{ width: "100%" }}>
            <InputSelect
              className={departConcat !== StateCountry?.label && "BorderCorrect"}
              data={dataDepartamentNew && dataDepartamentNew}
              tag={"Departamento"}
              set={setStateCountry}
              value={StateCountry ? StateCountry : "N/A"}
            />
          </div>
        ) : dataCurrent.status_entryFormCorrected &&
          dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"name_departmentCorrected"}
            value={StateCountry ? StateCountry?.label : "N/A"}
            onChange={handleInputChangeCorrect}
            tag={"Departamento"}
            size={100}
            ColorCustom={(x) =>
              departConcat !== StateCountry?.label &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            desicion={false}
            readOnly={true}
            placeholder={"Departamento"}
            onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            name={"name_departmentCorrected"}
            value={StateCountry ? StateCountry?.label : "N/A"}
            onChange={handleInputChangeCorrect}
            tag={"Departamento"}
            size={100}
            label={TooltipComentary("name_departmentCorrected")}
            desicion={StateDisble ? true : false}
            changeValue={departConcat !== StateCountry?.label && "BorderCorrect"}
          />
        )}

        {dataCurrent.status_entryFormCorrected &&
        dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"num_import_declaration_corrected"}
            value={
              num_import_declaration_corrected
                ? num_import_declaration_corrected
                : "N/A"
            }
            tag={"Nº Declaración de Importación"}
            onChange={handleInputChangeCorrect}
            size={100}
            ColorCustom={(x) =>
              num_import_declaration !== num_import_declaration_corrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            desicion={false}
            readOnly={true}
            placeholder={"Nº Declaración de Importación"}
            onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            name={"num_import_declaration_corrected"}
            value={
              num_import_declaration_corrected
                ? num_import_declaration_corrected
                : "N/A"
            }
            label={TooltipComentary("num_import_declaration_corrected")}
            tag={"Nº Declaración de Importación"}
            onChange={handleInputChangeCorrect}
            size={100}
            desicion={StateDisble ? true : false}
            changeValue={
              num_import_declaration !== num_import_declaration_corrected &&
              "BorderCorrect"
            }
          />
        )}

        {!StateDisble ? (
          <div style={{ width: "100%" }}>
            <InputSelect
              className={
                adminCuston !== customsadministration.label && "BorderCorrect"
              }
              tag={"Administración de Aduanas"}
              data={adminCustom && adminCustom}
              set={setCustomsadministration}
              value={customsadministration ? customsadministration : []}
            />
          </div>
        ) : dataCurrent.status_entryFormCorrected &&
          dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
          <LabelGroupComment
            name={"customs_administration_corrected"}
            value={customsadministration ? customsadministration.label : ""}
            onChange={handleInputChangeCorrect}
            tag={"Administración de Aduanas"}
            size={100}
            ColorCustom={(x) =>
              num_import_declaration !== num_import_declaration_corrected &&
              Colorcurrent(x, FormId) === "gray"
                ? "purple"
                : Colorcurrent(x, FormId)
            }
            desicion={false}
            readOnly={true}
            placeholder={"Administración de Aduanas"}
            onClick={(x) => CorrectionComment(x)}
          />
        ) : (
          <LabelGroup
            name={"customs_administration_corrected"}
            value={customsadministration ? customsadministration.label : ""}
            label={TooltipComentary("customs_administration_corrected")}
            onChange={handleInputChangeCorrect}
            tag={"Administración de Aduanas"}
            size={100}
            desicion={StateDisble ? true : false}
            changeValue={
              adminCuston !== customsadministration.label && "BorderCorrect"
            }
          />
        )}

        {(code === 309 ||
          code === 310 ||
          code === 314 ||
          code === 316 ||
          code === 318) && (
          <>
            <LabelGroup
              name={"num_exit_form_corrected"}
              value={
                num_exit_form_corrected
                  ? num_exit_form_corrected
                  : "N/A"
              }
              onChange={handleInputChangeCorrect}
              tag={"Nº Autorización de salida"}
              size={100}
              desicion={StateDisble ? true : false}
              changeValue={
                num_exit_form !==
                  num_exit_form_corrected && "BorderCorrect"
              }
            />
            <LabelGroup
              name={"integration_box_certificate_corrected"}
              value={
                integration_box_certificate_corrected
                  ? integration_box_certificate_corrected
                  : "N/A"
              }
              tag={"Nº Autorización de C. Inte"}
              onChange={handleInputChangeCorrect}
              size={100}
              desicion={StateDisble ? true : false}
              changeValue={
                integration_box_certificate !==
                  integration_box_certificate_corrected && "BorderCorrect"
              }
            />
          </>
        )}
      </>
    );
  };
   */
