import axios from "axios";
import environment from "constants/apiConst";

export const __getVehicularDoorsEntry = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.GET_VEHICLES_DOORS_ENTRY}`, options);
  return res;
};

export const __getVehicularDoorsExit = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.GET_VEHICLES_DOORS_EXIT}`, options);
  return res;
};

export const __getsearchVehiclePlateEntry = async (token, plate) => {
  const formData = new FormData();
  formData.append("vehicle_plate", plate);

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(`${environment.SEARCH_VEHICLE_PLATE_ENTRY}`, options);
  return res;
};

export const __getsearchVehiclePlateExit = async (token, plateVehicle) => {
  const formData = new FormData();
  formData.append("vehicle_plate", plateVehicle);

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(`${environment.SEARCH_VEHICLE_PLATE_EXIT}`, options);
  return res;
};

export const _getSearchPlateIdentification = async (token, plateOrIdent) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.SEARCH_PLATE_IDENTIFICATION}/${plateOrIdent}`,
    options
  );
  return res;
};

export const __postcreateVetoTruckOrPerson = async (token, body) => {
  const {
    name,
    type_block,
    description,
    vehicle_plate,
    identification,
    type_of_sanction,
    origin
  } = body;
  const formData = new FormData();

  formData.append("name_driver", name);
  formData.append("type_block", type_block);
  formData.append("description", description);
  formData.append("vehicle_plate", vehicle_plate);
  formData.append("identification", identification);
  formData.append("type_of_sanction", type_of_sanction);

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };
  const res = await axios(
    origin === "driver"
      ? `${environment.CREATE_VETO_TRUCK}`
      : `${environment.CREATE_VETO_PERSON}`,
    options
  );
  return res;
};

export const __postBlockageParticular = async (token, body) => {
  const {
    name,
    type_block,
    description,
    vehicle_plate,
    identification,
    type_of_sanction
  } = body;
  const formData = new FormData();

  formData.append("type_block", type_block); // ambos
  formData.append("description", description); // ambos
  formData.append("type_of_sanction", type_of_sanction); // ambos

  type_block === "plate" && formData.append("vehicle_plate", vehicle_plate); // plate

  type_block === "identification" && formData.append("name_driver", name); // ident
  type_block === "identification" &&
    formData.append("identification", identification); // ident

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(
    type_block === "plate"
      ? `${environment.CREATE_VETO_TRUCK}`
      : `${environment.CREATE_VETO_PERSON}`,
    options
  );
  return res;
};

// export const __postcreateSecurityEntryAndExit = async (token, body, destiny) => {
//   const { entryPasses_id, dispatchPasses_id, description_security, vehicularDoor_id } = body;

//   const formData = new FormData();
//   entryPasses_id && formData.append("entryPasses_id", entryPasses_id);
//   dispatchPasses_id && formData.append("dispatchPasses_id", dispatchPasses_id);
//   formData.append("description_security", description_security);
//   formData.append("vehicularDoor_id", vehicularDoor_id);

//   let options = {
//     method: "POST",
//     headers: {
//       Authorization: token ? `Bearer ${token}` : "",
//       "Content-Type": "application/json",
//     },
//     data: formData ? formData : "",
//   };

//   let res = await axios(
//     destiny === "entry"
//       ? `${environment.CREATE_SECURITY_ENTRY}`
//       : `${environment.CREATE_SECURITY_EXIT}`,
//     options
//   );
//   return res;
// };

export const __postcreateSecurityEntryAndExit = async (
  token,
  params,
  destiny
) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: params
  };

  const res = await axios(
    destiny === "entry"
      ? `${environment.CREATE_SECURITY_ENTRY}`
      : `${environment.CREATE_SECURITY_EXIT}`,
    options
  );
  return res;
};

export const _getDeleteVetoTruck = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.DELETE_VETO_TRUCK}/${id}`, options);
  return res;
};

export const _getDeleteVetoPerson = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.DELETE_VETO_PERSON}/${id}`, options);
  return res;
};

export const _getDeleteVetoDriver = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.DELETE_VETO_DRIVER}/${id}`, options);
  return res;
};

export const __getHistoryVeto = async (token, body) => {
  const formData = new FormData();
  Object.entries(body).map(([key, valor]) => {
    if (valor) {
      formData.append(`${key}`, valor);
    }
  });

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(`${environment.GET_HISTORY_VETO}`, options);
  return res;
};

export const _getSearchCodeFormEntry = async (token, code) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.SEARCH_CODE_FORM_ENTRY}/${code}`,
    options
  );
  return res;
};

export const _getSearchCodeFormOutput = async (token, code) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.SEARCH_CODE_FORM_OUTPUT}/${code}`,
    options
  );
  return res;
};

export const __getSecurityEntryFiltro = async (token) => {
  const formData = new FormData();
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(`${environment.GET_SECURITY_ENTRY_FILTRO}`, options);
  return res;
};

export const __getSecurityOutputFiltro = async (
  endpoint,
  token,
  body,
  page
) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

export const __postFilterReportSecurity = async (token, body) => {
  const {
    destiny,
  } = body;

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    destiny === "entry"
      ? `${environment.GET_SECURITY_ENTRY_FILTRO}`
      : `${environment.GET_SECURITY_OUTPUT_FILTRO}`,
    options
  );
  return res;
};

export const __postExportReportSecurity = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${environment.POST_EXPORT_REPORT_SECURITY}`, options);
  return res;
};
