import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { useForm } from "hooks/useForm";
import environment from "constants/apiConst";
import { ButtonStyled } from "styled/Buttons.styled";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { LabelStyledLink } from "components/addProduct/CreateTemplate.styled";
import { IconInModal } from "styled/Icons.styled";
import { HiOutlinePlus } from "react-icons/hi";
import FormAddNewProduct from "./FormAddNewProduct";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useGetInput, useNotations } from "hooks/useGetInput";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { findColorByStatus } from "hooks/useLocalStorage";
import { InputWithTooltip } from "components/Input/InputWithTooltip";
import { useNumericForm } from "hooks/useNumericForm ";
import ProductSearchBar from "./ProductSearchBar";
import { useQueryClient } from "@tanstack/react-query";
import { NumericFormat } from "react-number-format";
import queryEnv from "constants/queryConst";
import { isArray } from "utils/type-check-utils";

const UpdateObjectList = (
  objectId,
  propertyToUpdate,
  newValue,
  objectList,
  setObjectList
) => {
  const newObject = {
    form_field: objectId,
    form_field_info_before: newValue ?? "N/A",
    form_field_info_now: "N/A"
  };

  if (objectList && typeof objectList === "object") {
    const objectIndex = objectList.findIndex(
      (item) => item.form_field === objectId
    );

    if (objectIndex === -1) {
      setObjectList((prevList) => [...prevList, newObject]);
    } else {
      const updatedObjectList = [...objectList];
      updatedObjectList[objectIndex][propertyToUpdate] = newValue;
      setObjectList(updatedObjectList);
    }
  }
};

const FormOrdenProduct = ({ ...props }) => {
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );

  const queryClient = useQueryClient();
  const foundMutation = queryClient.getQueryData([queryEnv?.INTEGRATION_BOX]);

  const [unitCode, setUnitCode] = useState(null);
  const [decimal, setDecimal] = useState(null);
  const [fomateo] = useFormatQuantity();
  const [updateValues, setUpdateValues] = useState(false);
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const [valueProduct, setValueProduct] = useState({});
  const { pathname } = location;
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const [objectList, setObjectList] = useState([]);
  const { admin, is_commerce } = authUser;
  const [products, setProducts] = useState([]);
  const decimalScale = decimal || decimal_values_precision || 4;

  function parseNumberWithCommas(value) {
    return Number(value?.toString().replaceAll(",", "") || 0);
  }

  const {
    handleSwitchStatus,
    observations,
    getNestedPropertyValue,
    getColorByFieldName,
    currentCommentary,
    UpdateComment
  } = useNotations({
    storageKey: id || idCorrected,
    onOpen: props.onOpenComment,
    notationTypes: [
      {
        color: "gray",
        enableComment: false
      },
      {
        color: "green",
        enableComment: false
      },
      {
        color: "#fc0a0a",
        enableComment: true
      }
    ]
    // reviews: form?.reviews,
    // reviewsUpdates: form.review_updates,
  });

  const [values, handleInputChange, reset, setValues] = useForm({
    product_business_unit_name: "",
    convertion_factor_product: 0,
    description: "",
    product_description: "",
    product_subheading_code: "",
    product_unit_measurement: "",
    venta: "",
    qualifiedUser_id: "",
    destiny_id: "",
    production_order_id: ""
  });

  // eslint-disable-next-line no-unused-vars
  const { valuesNumeric, handleNumericInputChange, setFormValues } =
    useNumericForm({
      quantity: 0,
      fob_value: 0,
      net_weight_kilo: 0,
      quantity_product: 0
    });

  const { quantity_product, fob_value, net_weight_kilo, quantity } =
    valuesNumeric;

  useEffect(() => {
    if (net_weight_kilo && foundMutation?.status === "DEVUELTO") {
      UpdateObjectList(
        `net_weight_kilo_${idItemsView}`,
        ["form_field_info_now"],
        net_weight_kilo,
        objectList,
        setObjectList
      );
    }
  }, [net_weight_kilo]);

  useEffect(() => {
    if (fob_value && foundMutation?.status === "DEVUELTO") {
      UpdateObjectList(
        `fob_value_${idItemsView}`,
        ["form_field_info_now"],
        fob_value,
        objectList,
        setObjectList
      );
    }
  }, [fob_value]);

  useEffect(() => {
    if (quantity && foundMutation?.status === "DEVUELTO") {
      UpdateObjectList(
        `quantity_${idItemsView}`,
        ["form_field_info_now"],
        quantity,
        objectList,
        setObjectList
      );
    }
  }, [quantity]);

  useEffect(() => {
    if (quantity_product && foundMutation?.status === "DEVUELTO") {
      UpdateObjectList(
        `quantity_product_${idItemsView}`,
        ["form_field_info_now"],
        quantity_product,
        objectList,
        setObjectList
      );
    }
  }, [quantity_product]);

  const {
    product_business_unit_name,
    convertion_factor_product,
    description,
    product_description,
    subheading_code,
    business_unit_code
  } = values;
  const {
    createData,
    userQualified,
    destiny,
    onCloseModal,
    dataToEditSingleProduct,
    setRequestCreate,
    dataToEditProduct,
    setDataToEditProduct,
    userQualifiedCorrect,
    isOpenAddNewProduct,
    onOpenAddNewProduct,
    onCloseAddNewProduct,
    createDataCorrection,
    setRequestCreateCorrection,
    requestReviews,
    setReturnReviews,
    idOrder
  } = useContext(IntegracionBoxCrudContex);
  const idItemsView = idOrder?.item_code ?? 0;
  // Obtener productos por id

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimal(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userQualified) userC(userQualified.value);
  }, [userC, userQualified]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      const isAdminOrCommerce = admin === 1 || is_commerce === 1;
      const valueToSet =
        isAdminOrCommerce && decimal
          ? decimal
          : Number(decimal_values_precision);

      setFormValues((prevState) => ({
        ...prevState,
        [nameVariable]: fomateo(valueToSet, posibleResult)
      }));
    }
  };

  const handleFormat = (valueInput) => {
    valueInput = String(valueInput).replaceAll(",", "");
    return fomateo(
      (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
        ? decimal
        : Number(decimal_values_precision),
      valueInput
    );
  };

  useEffect(() => {
    if (
      unitCode &&
      unitCode?.product_business_unit_code === "KG" &&
      quantity_product
    ) {
      const multiplicationQuantity = (
        parseNumberWithCommas(quantity_product) *
        Number(unitCode?.convertion_factor_product || 0)
      ).toFixed(decimal);

      const multiplicationNetweight = (
        parseNumberWithCommas(quantity_product) *
        Number(unitCode?.convertion_factor_product || 0)
      ).toFixed(decimal);

      setFormValues((prevStates) => ({
        ...prevStates,
        net_weight_kilo: multiplicationNetweight
          ? handleFormat(multiplicationQuantity)
          : 0
      }));

      setFormValues((prevStates) => ({
        ...prevStates,
        quantity: multiplicationQuantity
          ? handleFormat(multiplicationQuantity)
          : 0
      }));
    }
  }, [quantity_product, unitCode]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setValues((prev) => ({
        ...prev,
        product_business_unit_code: idOrder?.unit_measurement,
        convertion_factor_product: idOrder?.convertion_factor_product || 0,
        quantity_product: idOrder?.quantity_product || 0,
        product_description: idOrder?.description_product,
        description: idOrder?.description_product,
        product_subheading_code: idOrder?.code_subheading,
        venta: !!idOrder?.type_purchase,
        quantity: idOrder?.quantity,
        product_unit_measurement: idOrder?.business_unit,
        net_weight_kilo: idOrder?.net_weight_kilo,
        fob_value: idOrder?.fob_value
      }));
      /*     setValueProduct({
        value: idOrder?.product_id,
        label: idOrder?.description_product,
      }); */
      setFormValues((prevState) => ({
        ...prevState,
        net_weight_kilo: idOrder?.net_weight_kilo,
        fob_value: idOrder?.fob_value,
        quantity_product: idOrder?.quantity_product || 0,
        quantity: idOrder?.quantity
      }));
    }
  }, [idOrder]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setValues((prev) => ({
        ...prev,
        product_business_unit_name: dataToEditSingleProduct?.business_unit,
        convertion_factor_product:
          dataToEditSingleProduct?.convertion_factor_product || 0,
        quantity_product: dataToEditSingleProduct?.quantity_product || 0,
        product_description: dataToEditSingleProduct?.description_product,
        description: dataToEditSingleProduct?.description_product,
        product_subheading_code: dataToEditSingleProduct?.code_subheading,
        venta: !!dataToEditSingleProduct?.type_purchase,
        quantity: dataToEditSingleProduct?.quantity,
        product_unit_measurement: dataToEditSingleProduct?.business_unit,
        net_weight_kilo: dataToEditSingleProduct?.net_weight_kilo,
        fob_value: dataToEditSingleProduct?.fob_value
      }));

      setValueProduct({
        value: dataToEditSingleProduct?.product_id,
        label: dataToEditSingleProduct?.description_product
      });

      setFormValues((prevState) => ({
        ...prevState,
        net_weight_kilo: dataToEditSingleProduct?.net_weight_kilo,
        fob_value: dataToEditSingleProduct?.fob_value,
        quantity_product: dataToEditSingleProduct?.quantity_product || 0,
        quantity: dataToEditSingleProduct?.quantity
      }));
    }
  }, [dataToEditSingleProduct]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (
      dataToEditSingleProduct &&
      valueProduct &&
      valueProduct.value !== dataToEditSingleProduct.product_id
    ) {
      setUpdateValues(true);
    } else {
      setUpdateValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProduct]);

  useEffect(() => {
    setRequestCreate(environment.CREATE_INTEGRATION_BOX_EDIT);
    setRequestCreateCorrection(
      environment.CREATE_NEW_ORDER_OR_PRODUCT_CORRECTED
    );
  }, []);

  const invalidateQuerys = () => {
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.PRODUCTS_CI] })
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] })
  }

  const handleSubmit = (e) => {
    // e.preventDefault();
    const caculatedSubheading =
      parseNumberWithCommas(quantity_product) *
      parseNumberWithCommas(convertion_factor_product);
    const formData = new FormData();
    formData.append("id", id);
    formData.append(
      "qualifiedUser_id",
      authUser.is_qualified_user === 1
        ? authUser.userQualified
        : userQualified !== null
          ? userQualified.value
          : userQualifiedCorrect === null
            ? userQualifiedCorrect.value
            : ""
    );
    formData.append("product_id", valueProduct?.value || "");
    formData.append("destiny_id", destiny?.value || "");
    formData.append("observations", props?.observations || "");

    formData.append(
      "quantity_product",
      parseNumberWithCommas(quantity_product)
    );
    formData.append("fob_value", parseNumberWithCommas(fob_value));

    formData.append(
      "net_weight_kilo",
      business_unit_code === "KG"
        ? caculatedSubheading
        : parseNumberWithCommas(net_weight_kilo)
    );

    formData.append("quantity", caculatedSubheading);

    if (dataToEditProduct) {
      if (updateValues) {
        formData.append("edit", true);
        formData.append("old_product_id", dataToEditSingleProduct.product_id);
      } else {
        formData.append("edit", true);
      }
      createData(formData, invalidateQuerys);
      handleReset();
    } else if (foundMutation?.status && foundMutation?.status === "CORREGIDO") {
      formData.append("cert_corrected", true);
      createData(formData, invalidateQuerys);
      handleReset();
    } else {
      createData(formData, invalidateQuerys);
      handleReset();
    }
  };

  const handleReset = () => {
    setValueProduct([]);
    onCloseModal();
    setDataToEditProduct(null);
    reset();
  };

  const handleSubmitCorrected = (e) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("integrationCertificateCorrected_id", id || idCorrected);
    formData.append("productCorrected_id", valueProduct?.value || "");

    formData.append(
      "net_weight_kilo_corrected",
      business_unit_code === "KG"
        ? quantity_product * convertion_factor_product
        : parseNumberWithCommas(net_weight_kilo)
    );

    formData.append(
      "quantity_product_corrected",
      parseNumberWithCommas(quantity_product)
    );

    formData.append(
      "quantity_corrected",
      quantity_product * convertion_factor_product
    );

    formData.append("fob_value_corrected", parseNumberWithCommas(fob_value));
    createDataCorrection(formData, invalidateQuerys);
    handleReset();
  };

  const GetTooltipReview = (name, requestReviews) => {
    if (!requestReviews) {
      return;
    }

   /*  const index = requestReviews.findIndex(
      (element) => element?.form_field === name
    );

    return index !== -1 ? requestReviews[index]?.comment : null; */
  };

  const getProductsOnSearch = (hasProductData) => {
    setProducts(hasProductData);
  };

  useEffect(() => {
    if (!valueProduct) {
      setFormValues((prevState) => ({
        ...prevState,
        net_weight_kilo: 0,
        fob_value: 0,
        quantity_product: 0,
        quantity: 0
      }));
      return reset();
    }
    const product = isArray(products)
    ? products.find((item) => item.id === valueProduct?.value)
    : null;

    if (product) {
      setValues((prev) => ({
        ...prev,
        product_business_unit_name: product?.unit_measurement,
        convertion_factor_product: product?.convertion_factor_product || 0,
        quantity_product: product?.quantity_product || 0,
        product_description: product?.description_product,
        description: product?.description_product,
        subheading_code: product?.code_subheading,
        venta: !!product?.type_purchase,
        quantity: product?.quantity,
        business_unit_code: product?.businnesUnitSubheading,
        net_weight_kilo: product?.net_weight_kilo,
        fob_value: product?.fob_value
      }));

      setValueProduct({
        value: product?.product_id || valueProduct?.value,
        label: product?.description_product || valueProduct?.label
      });

      setFormValues((prevState) => ({
        ...prevState,
        net_weight_kilo: product?.net_weight_kilo,
        fob_value: product?.fob_value,
        quantity_product: product?.quantity_product || 0,
        quantity: product?.quantity
      }));
    }
  }, [products, valueProduct?.value]);

  return (
    <>

      <ModalGeneric
        isOpen={isOpenAddNewProduct}
        onOpen={onOpenAddNewProduct}
        onClose={onCloseAddNewProduct}
        title="Agregar Nuevo Producto"
        size="4xl"
        scrollBehavior={"outside"}
      >
        <FormAddNewProduct />
      </ModalGeneric>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (pathname === "/IntegracionBox") {
            handleSubmit();
          } else if (pathname === "/CorrectedCertificate") {
            handleSubmitCorrected();
          }
        }}
      >
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          {foundMutation?.status !== "PRESENTADO"
            ? (
              <div>
                <ProductSearchBar
                  label="Productos"
                  required={true}
                  set={setValueProduct}
                  size={100}
                  value={valueProduct}
                  callBack={getProductsOnSearch}
                />
                <LabelStyledLink
                  id="Nuevorol"
                  onClick={() => onOpenAddNewProduct()}
                  className="mt-2"
                >
                  Nuevo Producto
                  <IconInModal as={HiOutlinePlus} className="ms-1" />
                </LabelStyledLink>
              </div>
            )
            : (
              <>
                <ProductSearchBar
                  label="Producto"
                  required={true}
                  set={setValueProduct}
                  isDisabled={pathname === `/CheckIntegrationBox`}
                  size={100}
                />
              </>
            )}

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={"product_business_unit_name"}
                value={product_business_unit_name}
                onChange={handleInputChange}
                tag={"Unidad de producto"}
                error={findColorByStatus(
                  `product_business_unit_name_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `product_business_unit_name_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `product_business_unit_name_${idItemsView}`,
                  requestReviews
                )}
                size={90}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`product_business_unit_name_${idItemsView}`}
                labelText={"Unidad de producto"}
                value={product_business_unit_name ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `product_business_unit_name_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                    `business_name`,
                    reviewUpdates
                  )} */
                // disabled={isDisabled}

                data-name-id={"Unidad de producto"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <>
                <InputWithTooltip
                  name={`convertion_factor_product`}
                  value={convertion_factor_product}
                  onChange={handleInputChange}
                  tag={"Factor conversión"}
                  error={findColorByStatus(
                    `convertion_factor_product_${idItemsView}`,
                    requestReviews
                  )}
                  msgTooltip={GetTooltipReview(
                    `convertion_factor_product_${idItemsView}`,
                    requestReviews
                  )}
                  bgTooltip={findColorByStatus(
                    `convertion_factor_product_${idItemsView}`,
                    requestReviews
                  )}
                  size={90}
                  readOnly={true}
                  disabled={true}
                />
              </>
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`convertion_factor_product_${idItemsView}`}
                labelText={"Factor conversión"}
                value={convertion_factor_product ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `convertion_factor_product_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                    `business_name`,
                    reviewUpdates
                  )} */
                data-name-id={"Factor conversión"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                required={true}
                name={`quantity_product`}
                value={quantity_product}
                onChange={handleNumericInputChange}
                tag={"Cantidad producto"}
                error={findColorByStatus(
                  `quantity_product_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `quantity_product_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `quantity_product_${idItemsView}`,
                  requestReviews
                )}
                onBlur={handleBlurCant}
                size={90}
                readOnly={foundMutation?.status === "PRESENTADO"}
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                required={true}
                name={`quantity_product_${idItemsView}`}
                labelText={"Cantidad producto"}
                value={quantity_product ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `quantity_product_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                    `business_name`,
                    reviewUpdates
                  )} */
                data-name-id={"Cantidad producto"}
                onClick={handleSwitchStatus}
                maxLength={10}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`product_description`}
                value={product_description}
                onChange={handleInputChange}
                tag={"Descripción del producto"}
                error={findColorByStatus(
                  `product_description_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `product_description_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `product_description_${idItemsView}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`product_description_${idItemsView}`}
                labelText={"Descripción del producto"}
                value={product_description ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `product_description_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Descripción del producto"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`description`}
                value={description}
                onChange={handleInputChange}
                tag={"Descripción"}
                error={findColorByStatus(
                  `description_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `description_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `description_${idItemsView}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`description_${idItemsView}`}
                labelText={"Descripción"}
                value={description ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `description_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Descripción"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`subheading_code`}
                value={subheading_code || dataToEditSingleProduct?.code_subheading || 0}
                onChange={handleInputChange}
                tag={"Subpartida"}
                error={findColorByStatus(
                  `subheading_code_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `subheading_code_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `subheading_code_${idItemsView}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`subheading_code_${idItemsView}`}
                labelText={"Subpartida"}
                value={subheading_code || "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `subheading_code_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Subpartida"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                required={true}
                name={`quantity`}
                value={
                  String(quantity_product).replaceAll(",", "") *
                  convertion_factor_product || 0
                }
                onChange={handleNumericInputChange}
                tag={"Cantidad"}
                error={findColorByStatus(
                  `quantity_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `quantity_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `quantity_${idItemsView}`,
                  requestReviews
                )}
                readOnly={foundMutation?.status === "PRESENTADO"}
                onBlur={handleBlurCant}
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`quantity_${idItemsView}`}
                labelText={"Cantidad"}
                required={true}
                value={quantity_product * convertion_factor_product}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `quantity_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Cantidad"}
                onClick={handleSwitchStatus}
                maxLength={10}
              />
            )}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <InputWithTooltip
                name={`business_unit_code`}
                value={business_unit_code || dataToEditSingleProduct?.business_unit}
                onChange={handleInputChange}
                tag={"Unidad comercial"}
                error={findColorByStatus(
                  `business_unit_code_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `business_unit_code_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `business_unit_code_${idItemsView}`,
                  requestReviews
                )}
                readOnly={true}
                disabled={true}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                name={`business_unit_code_${idItemsView}`}
                labelText={"Unidad comercial"}
                value={business_unit_code ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `business_unit_code_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Unidad comercial"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                required={true}
                name={"net_weight_kilo"}
                value={
                  business_unit_code === "KG"
                    ? parseNumberWithCommas(quantity_product) *
                    parseNumberWithCommas(convertion_factor_product)
                    : net_weight_kilo
                }
                onChange={handleNumericInputChange}
                onBlur={handleBlurCant}
                tag={"Peso neto en kilos"}
                error={findColorByStatus(
                  `net_weight_kilo_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `net_weight_kilo_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `net_weight_kilo_${idItemsView}`,
                  requestReviews
                )}
                readOnly={false}
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                required={true}
                name={`net_weight_kilo_${idItemsView}`}
                labelText={"Peso neto en kilos"}
                value={
                  business_unit_code === "KG"
                    ? String(quantity_product).replaceAll(",", "") *
                    convertion_factor_product
                    : net_weight_kilo
                }
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `net_weight_kilo_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Peso neto en kilos"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>

          <div>
            {foundMutation?.status !== "PRESENTADO" ? (
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={decimalScale}
                decimalSeparator="."
                customInput={InputWithTooltip}
                required={true}
                name={`fob_value`}
                value={fob_value}
                onChange={handleNumericInputChange}
                onBlur={handleBlurCant}
                tag={"Valor FOB en US$"}
                placeholder="0"
                error={findColorByStatus(
                  `fob_value_${idItemsView}`,
                  requestReviews
                )}
                msgTooltip={GetTooltipReview(
                  `fob_value_${idItemsView}`,
                  requestReviews
                )}
                bgTooltip={findColorByStatus(
                  `fob_value_${idItemsView}`,
                  requestReviews
                )}
                readOnly={false}
                maxLength={34}
              />
            ) : (
              <InputWithLabel
                p={0}
                _hover={{
                  cursor: "pointer"
                }}
                borderRadius={"none"}
                required={true}
                name={`fob_value_${idItemsView}`}
                labelText={"Valor FOB en US$"}
                value={fob_value ?? "N/A"}
                /* tooltipText={GetTooltipReview(["business_name"])} */
                inputBgColor={findColorByStatus(
                  `fob_value_${idItemsView}`,
                  observations
                )}
                /* tooltipTextComment={CommentFindTooltip(
                  `business_name`,
                  reviewUpdates
                )} */
                data-name-id={"Valor FOB en US$"}
                onClick={handleSwitchStatus}
              />
            )}
          </div>
        </SimpleGrid>

        <div className="mb-3 ms-5">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>
            {pathname === "/IntegracionBox" ? (
              <>
                {/*  <ButtonStyled onClick={SaveReviewsUpdate}>
                  GuardarComment
                </ButtonStyled> */}
                <ButtonStyled type="submit">Guardar</ButtonStyled>
              </>
            ) : pathname === "/CorrectedCertificate"
              ? (
                <ButtonStyled type="submit">Guardar</ButtonStyled>
              )
              : (
                ""
              )}
          </Box>
        </div>
      </form>
    </>
  );
};

export default FormOrdenProduct;
