import axios from 'axios';
import environment from 'constants/apiConst';

export const __Get_Users_Qualified = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.GET_USERS_QUALIFIED, options);
  return res;
};
