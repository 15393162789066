import environment from "constants/apiConst";

export const fmmiExportNodeList = [
  {
    isQualifiedUser: true,
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "qualifiedUser_id",
  },
  {
    value: "code_entry_form",
    label: "Código del Formulario",
  },
  {
    value: "code_product",
    label: "Código producto",
  },
  {
    value: "code_operation",
    label: "Código de la Operación",
  },
  {
    value: "exchange_rate",
    label: "Tasa de Cambio - TRM",
  },
  {
    value: "entry_form_item_description",
    label: "Descripción del Ítem",
  },
  {
    value: "code_unit_measurement",
    label: "Código de Unidad de Medida",
  },
  {
    value: "num_dex_entryForm",
    label: "Número DEX",
  },
  {
    value: "dex_date_entryForm",
    label: "Fecha DEX",
  },
  {
    value: "dcl_customs_transit",
    label: "Número DTA",
  },
  {
    value: "num_transport_document",
    label: "Número del Documento de Transporte",
  },
  {
    value: "transp_doc_date",
    label: "Fecha del Documento de Transporte",
  },
  {
    value: "cargo_manifest_entryForm",
    label: "Manifiesto de Carga",
  },
  {
    value: "code_department",
    label: "Código del Departamento",
  },
  {
    value: "gross_weight",
    label: "Peso Bruto",
  },
  {
    value: "net_weight",
    label: "Peso Neto",
  },
  {
    value: "us_fob_value",
    label: "Valor FOB",
  },
  {
    value: "us_freight",
    label: "Valor del Flete",
  },
  {
    value: "us_insurance",
    label: "Valor del Seguro",
  },
  {
    value: "other_us_expenses",
    label: "Otros Gastos",
  },
  {
    value: "cif_us_value",
    label: "Valor CIF",
  },
  {
    value: "cif_unit_value",
    label: "Valor CIF unitario",
  },
  {
    value: "authorizing_date",
    label: "Fecha de Autorización",
  },
  {
    value: "authorizing_time",
    label: "Hora de Autorización",
  },
  {
    value: "presenting_user",
    label: "Usuario que Presentó",
  },
  {
    value: "presenting_date",
    label: "Fecha de Presentación",
  },
  {
    value: "presenting_time",
    label: "Hora de Presentación",
  },
  {
    value: "deleting_user",
    label: "Usuario que Solicitó el Borrador",
  },
  {
    value: "deleting_date",
    label: "Fecha de Solicitud del Borrador",
  },
  {
    value: "deleting_time",
    label: "Hora de Solicitud del Borrador",
  },
  {
    value: "finished_user",
    label: "Usuario que Finalizó",
  },
  {
    value: "finished_date",
    label: "Fecha de Finalización",
  },
  {
    value: "finished_time",
    label: "Hora de Finalización",
  },
  {
    value: "reviewed_user",
    label: "Usuario Revisor",
  },
  {
    value: "reviewed_date",
    label: "Fecha de Revisión",
  },
  {
    value: "reviewed_time",
    label: "Hora de Revisión",
  },
];

export const fmmoExportNodeList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "qualifiedUser_id",
  },

  {
    value: "third_party_name",
    label: "Nombre del Tercero",
  },
  {
    value: "exchange_rate",
    label: "Tasa de Cambio - TRM",
  },
  {
    value: "output_form_item_description",
    label: "Descripción del Ítem",
  },
  {
    value: "third_party_identification_num",
    label: "NIT del Tercero",
  },
  {
    value: "code_output_form",
    label: "Código del Formulario",
  },
  {
    value: "code_product",
    label: "Código producto",
  },
  {
    value: "associated_entry_form_code",
    label: "Código de Formulario Asociado",
  },
  {
    value: "code_operation",
    label: "Código de la Operación",
  },
  {
    value: "description_type_operation",
    label: "Descripción del Tipo de Operación",
  },
  {
    value: "certificate_inspection_pre_boarding",
    label: "Certificado de Inspección Preembarque",
  },
  {
    value: "destination_address",
    label: "Dirección de Destino",
  },
  {
    value: "pass_required",
    label: "Pase Requerido",
  },
  {
    value: "status",
    label: "Estado",
  },
  {
    value: "gross_weight",
    label: "Peso Bruto",
  },
  {
    value: "deleting_time",
    label: "Hora de Eliminación",
  },
  {
    value: "presenting_user",
    label: "Usuario que Presentó",
  },
  {
    value: "presenting_date",
    label: "Fecha de Presentación",
  },
  {
    value: "presenting_time",
    label: "Hora de Presentación",
  },
  {
    value: "reviewed_user",
    label: "Usuario que Revisó",
  },
  {
    value: "reviewed_date",
    label: "Fecha de Revisión",
  },
  {
    value: "reviewed_time",
    label: "Hora de Revisión",
  },
  {
    value: "authorizing_user",
    label: "Usuario que Autorizó",
  },
  {
    value: "authorizing_date",
    label: "Fecha de Autorización",
  },
  {
    value: "authorizing_time",
    label: "Hora de Autorización",
  },
  {
    value: "finished_user",
    label: "Usuario que Finalizó",
  },
  {
    value: "finished_date",
    label: "Fecha de Finalización",
  },
  {
    value: "finished_time",
    label: "Hora de Finalización",
  },
  {
    value: "deleting_user",
    label: "Usuario que Solicitó el Borrador",
  },
  {
    value: "deleting_date",
    label: "Fecha de Solicitud del Borrador",
  },
  {
    value: "deleting_time",
    label: "Hora de Solicitud del Borrador",
  },
];

export const fmmiExportNodeUC = [
  {
    value: "num_commercial_invoice",
    label: "Factura Comercial",
  },
  {
    value: "status",
    label: "Estado",
  },
  {
    value: "cif_cop_value",
    label: "Valor CIF COP",
  },
  {
    value: "item",
    label: "Item",
  },
  {
    value: "product_quantity",
    label: "Cantidad de producto",
  },
  {
    value: "quantity",
    label: "Cantidad",
  },
  {
    value: "associated_temporary_output",
    label: "Certificado de integración",
  },
  {
    value: "num_exit_form",
    label: "Número formulario de salida",
  },
  {
    value: "customs_administration",
    label: "Administración de aduanas",
  },
  {
    value: "third_party_name",
    label: "Nombre del tercero",
  },
  {
    value: "third_party_identification_num",
    label: "Nit del tercero",
  },

  {
    value: "transit_expiration_date",
    label: "Fecha de vencimiento del documento de transporte",
  },
  {
    value: "number_packages",
    label: "Número de bultos",
  },
  {
    value: "shipment_entry_form",
    label: "Embarque",
  },
  {
    value: "description_type_operation",
    label: "Descripción del tipo de operación",
  },
  {
    value: "authorizing_user",
    label: "Usuario que autorizo",
  },
  {
    value: "service",
    label: "Servicio",
  },
];

export const fmmiExportNodeNoUc = [
  {
    value: "num_commercial_invoice",
    label: "Factura Comercial",
  },
  {
    value: "cif_cop_value",
    label: "Valor CIF COP",
  },
  {
    value: "name_qualified",
    label: "Usuario calificado",
  },
  {
    value: "status",
    label: "Estado",
  },
  {
    value: "item",
    label: "Item",
  },
  {
    value: "product_quantity",
    label: "Cantidad de producto",
  },
  {
    value: "quantity",
    label: "Cantidad",
  },
  {
    value: "associated_temporary_output",
    label: "Certificado de integración",
  },
  {
    value: "num_exit_form",
    label: "Número formulario de salida",
  },
  {
    value: "customs_administration",
    label: "Administración de aduanas",
  },
  {
    value: "third_party_name",
    label: "Nombre del tercero",
  },
  {
    value: "third_party_identification_num",
    label: "Nit del tercero",
  },

  {
    value: "transit_expiration_date",
    label: "Fecha de vencimiento del documento de transporte",
  },
  {
    value: "number_packages",
    label: "Número de bultos",
  },
  {
    value: "shipment_entry_form",
    label: "Embarque",
  },
  {
    value: "description_type_operation",
    label: "Descripción del tipo de operación",
  },
  {
    value: "authorizing_user",
    label: "Usuario que autorizo",
  },
  {
    value: "service",
    label: "Servicio",
  },
];

export const fmmoExportNodeListUC = [
  {
    value: "net_weight",
    label: "Peso neto",
    name: "net_weight",
  },
  {
    value: "cif_cop_value",
    label: "Valor CIF COP",
  },
  {
    value: "num_commercial_invoice",
    label: "Factura Comercial",
  },
  {
    value: "us_fob_value",
    label: "Valor FOB",
    name: "us_fob_value",
  },
  {
    value: "us_freight",
    label: "Valor del flete",
    name: "us_freight",
  },
  {
    value: "us_insurance",
    label: "Valor del seguro",
    name: "us_insurance",
  },
  {
    value: "other_us_expenses",
    label: "Otros costos",
    name: "other_us_expenses",
  },
  {
    value: "number_packages",
    label: "Número de bultos",
    name: "number_packages",
  },
  {
    value: "decimal_values_precision",
    label: "Decimales",
    name: "decimal_values_precision",
  },
  {
    value: "shipment",
    label: "Embarque",
    name: "shipment",
  },
  {
    value: "refund",
    label: "Modalidad",
    name: "refund",
  },
  {
    value: "item",
    label: "Item",
    name: "item",
  },

  {
    value: "deleting_user",
    label: "Usuario que elimino",
    name: "deleting_user",
  },
  {
    value: "deleting_date",
    label: "Fecha que fue eliminado",
    name: "deleting_date",
  },
];

export const fmmoExportNodeListNoUc = [
  {
    value: "net_weight",
    label: "Peso neto",
    name: "net_weight",
  },
  {
    value: "cif_cop_value",
    label: "Valor CIF COP",
  },
  {
    value: "num_commercial_invoice",
    label: "Factura Comercial",
  },
  {
    value: "us_fob_value",
    label: "Valor FOB",
    name: "us_fob_value",
  },
  {
    value: "us_freight",
    label: "Valor del flete",
    name: "us_freight",
  },
  {
    value: "us_insurance",
    label: "Valor del seguro",
    name: "us_insurance",
  },
  {
    value: "other_us_expenses",
    label: "Otros costos",
    name: "other_us_expenses",
  },
  {
    value: "number_packages",
    label: "Número de bultos",
    name: "number_packages",
  },
  {
    value: "decimal_values_precision",
    label: "Decimales",
    name: "decimal_values_precision",
  },
  {
    value: "shipment",
    label: "Embarque",
    name: "shipment",
  },
  {
    value: "refund",
    label: "Modalidad",
    name: "refund",
  },
  {
    value: "item",
    label: "Item",
    name: "item",
  },
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified",
  },
];

export const fmmFormNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "PRESENTADO", label: "PRESENTADO" },
      { value: "APROBADO", label: "APROBADO" },
      { value: "AUTORIZADO", label: "AUTORIZADO" },
      { value: "DEVUELTO", label: "DEVUELTO" },
      { value: "ELIMINADO", label: "ELIMINADO" },
      { value: "CADUCADO", label: "CADUCADO" },
      { value: "ANULADO", label: "ANULADO" },
    ],
    value: null,
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) =>
        data?.map((item) => ({
          value: item?.id,
          label: item?.name_qualified,
          decimal: item?.decimal_values_precision,
        })),
    },
  },
];
