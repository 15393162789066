import { Switch, Td, Tr } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useState } from "react";

const TBodyTableItems = ({ data, setItems, deleteItems, allItems }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [trackingItem, setTrackingItem] = useState(0);
  const itemIDEntry = data?.entry_form_item_id;
  const itemIDExit = data?.output_form_item_id;

  const change = () => {
    if (trackingItem === 0) {
      setTrackingItem(1);
      setItems(itemIDEntry || itemIDExit);
    } else {
      setTrackingItem(0);
      deleteItems(itemIDEntry || itemIDExit);
    }
  }

  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.item ? data?.item : "" }</Td>
        <Td className="text-center"> {data?.code_entry_form ? data?.code_entry_form : data?.code_output_form ? data?.code_output_form : "N/A" }</Td>
        <Td className="text-center"> {data?.status_entry_form ? data?.status_entry_form : data?.status_output_form ? data?.status_output_form : "N/A"}</Td>
        <Td className="text-center">{data?.description_product ? data?.description_product : "N/A"}</Td>
        <Td className="text-center">{data?.code_product ? data?.code_product : "N/A"}</Td>
        <Td className="text-center">{data?.code_subheading ? data?.code_subheading : "N/A"}</Td>
        <Td className="text-center">{data?.code_operation ? data?.code_operation : "N/A"}</Td>
        <Td className="text-center">{data?.cargo_manifest_entry_form ? data?.cargo_manifest_entry_form : "N/A"}</Td>
        <Td className="text-center">{data?.product_quantity ? data?.product_quantity : "N/A"}</Td>
        <Td className="text-center">{data?.flag_name ? data?.flag_name : "N/A"}</Td>
        <Td className="text-center">
          <Switch onChange={change} isChecked={data?.currentStatus === 1 || trackingItem === 1} isDisabled={data?.currentStatus === 1 || allItems} colorScheme='green' />
        </Td>
      </Tr>
    </>
  );
};

export default TBodyTableItems;
