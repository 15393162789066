import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

const AlertSuccess = ({ alertMessage, closeAlert }) => {
  return (
    <Alert status="success">
      <AlertIcon />
      <AlertTitle mr={2}></AlertTitle>
      <AlertDescription>{alertMessage}</AlertDescription>
      <span
        onClick={closeAlert}
        style={{ cursor: 'pointer', position: 'absolute', right: '1rem', top: '8px' }}
      >
        ❌
      </span>
    </Alert>
  );
};

export default AlertSuccess;
