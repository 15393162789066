import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
  Tooltip,
  useColorModeValue,
  FormErrorMessage,
  Spinner,
  Box,
} from "@chakra-ui/react";
import {
  FaEdit,
  FaSave,
  FaTimes,
  FaCheck,
  FaCalendarAlt,
  FaLock,
} from "react-icons/fa";

export const EditableInput = ({
  label,
  initialValue,
  onSave,
  helperText,
  placeholder,
  isRequired = false,
  isDisabled = false,
  min = 0,
  max = 365,
  type = "text",
  isLoading = false,
  queryKey,
  controlStyle,
  allowEdit = true, // Nueva prop para controlar si se permite la edición
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState("");

  // Colores dinámicos basados en el modo de color
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const iconColor = useColorModeValue("gray.500", "gray.400");
  const activeIconColor = useColorModeValue("blue.500", "blue.300");
  const hoverBg = useColorModeValue("gray.100", "gray.700");

  useEffect(() => {
    setHasChanged(value !== initialValue);
  }, [value, initialValue]);

  const validateValue = useCallback(
    (val) => {
      if (type === "number") {
        const numVal = Number(val);
        if (isNaN(numVal)) {
          return "Por favor, introduce un número válido.";
        }
        if (numVal < min) {
          return `El valor mínimo es ${min}.`;
        }
        if (numVal > max) {
          return `El valor máximo es ${max}.`;
        }
      }
      return "";
    },
    [type, min, max]
  );

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      setError(validateValue(newValue));
    },
    [validateValue]
  );

  const handleEdit = useCallback(() => {
    if (allowEdit) {
      setIsEditing(true);
    }
  }, [allowEdit]);

  const handleSave = useCallback(async () => {
    const validationError = validateValue(value);
    if (validationError) {
      setError(validationError);
    } else if (hasChanged) {
      try {
        await onSave(value);
        setIsEditing(false);
        setError("");
      } catch (err) {
        setError("Error al guardar. Por favor, intenta de nuevo.");
      }
    }
  }, [hasChanged, onSave, value, validateValue]);

  const handleCancel = useCallback(() => {
    setValue(initialValue);
    setIsEditing(false);
    setError("");
  }, [initialValue]);

  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled || isLoading}
      isInvalid={!!error}
      {...controlStyle}
    >
      <FormLabel margin={"0"} fontSize={"0.88rem"} fontWeight={"normal"}>
        {label}
      </FormLabel>
      <InputGroup size="md">
        <InputLeftElement>
          {type === "number" ? (
            <FaCalendarAlt color={isEditing ? activeIconColor : iconColor} />
          ) : (
            <FaEdit color={isEditing ? activeIconColor : iconColor} />
          )}
        </InputLeftElement>
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isReadOnly={!isEditing || isLoading || !allowEdit}
          bg={bgColor}
          borderColor={borderColor}
          _hover={{ borderColor: "gray.300" }}
          _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
          pl="2.5rem"
          pr="4.5rem"
          type={type}
          min={type === "number" ? min : undefined}
          max={type === "number" ? max : undefined}
          {...props}
        />
        <InputRightElement width="4.5rem">
          {isLoading ? (
            <Spinner size="sm" color={activeIconColor} />
          ) : isEditing ? (
            <>
              <Tooltip label="Guardar" placement="top">
                <IconButton
                  icon={<FaSave />}
                  size="sm"
                  colorScheme="green"
                  variant="ghost"
                  onClick={handleSave}
                  mr={1}
                  _hover={{ bg: hoverBg }}
                  isDisabled={!hasChanged || !!error}
                  aria-label="Guardar cambios"
                />
              </Tooltip>
              <Tooltip label="Cancelar" placement="top">
                <IconButton
                  icon={<FaTimes />}
                  size="sm"
                  colorScheme="red"
                  variant="ghost"
                  onClick={handleCancel}
                  _hover={{ bg: hoverBg }}
                  aria-label="Cancelar edición"
                />
              </Tooltip>
            </>
          ) : allowEdit ? (
            <Tooltip label="Editar" placement="top">
              <IconButton
                icon={<FaEdit />}
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={handleEdit}
                _hover={{ bg: hoverBg }}
                aria-label="Editar campo"
              />
            </Tooltip>
          ) : (
            <Tooltip label="No editable" placement="top">
              <IconButton
                icon={<FaLock />}
                size="sm"
                colorScheme="gray"
                variant="ghost"
                isDisabled={true}
                aria-label="Campo no editable"
              />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      <Box display={"flex"} flexDirection={"row"}>
        {helperText && !error && (
          <FormHelperText fontSize="sm" color="gray.500">
            {helperText}
          </FormHelperText>
        )}
        {hasChanged && !isEditing && !error && (
          <FormHelperText
            fontSize="sm"
            display={"flex"}
            color="green.500"
            flexDir={"row"}
            alignItems={"center"}
            gap={1}
            pl={2}
          >
            <FaCheck /> Cambios guardados
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};