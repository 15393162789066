/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { InputSelectNew } from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import DianCRUDContext from "context/DianCrudContext";
import { useSelector } from "react-redux";
import { __PostForm } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";

const TbodyItemsProfile = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const info = [
    { value: 1, label: "Si" },
    { value: 2, label: "No" }
  ];

  const { dataFormItems, setUpdate, setLoading, update } =
    useContext(DianCRUDContext);

  const handleChange = async (item) => {
    const dataUpdateDocumentItem = new FormData();
    dataUpdateDocumentItem.append(
      "document_type",
      dataFormItems?.document_type
    );
    dataUpdateDocumentItem.append("form_id", dataFormItems?.form_id);
    dataUpdateDocumentItem.append("dial_type", item.label);
    dataUpdateDocumentItem.append("form_item_id", data?.id);
    try {
      const res = await __PostForm(
        environment.DOCUMETS_PROFILE_ITEMS,
        token,
        dataUpdateDocumentItem
      );
      if (res?.data?.status?.code === 200) {
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <Tr className={"verticalNone"}>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Td className="text-center">{data?.description_product}</Td>
      <Td className="text-center">{data?.product_quantity}</Td>
      <Td className="text-center">{data?.code_businessUnit}</Td>
      <Td className="text-center">{data?.gross_weight}</Td>

      <Td
        className="text-center"
        style={{
          width: "17%",
          paddingLeft: "13px"
        }}
      >
        {
          <div
            style={{
              width: "100%",
              marginBottom: "20px"
            }}
          >
            <InputSelectNew
              size={90}
              data={info}
              onChange={(item) => handleChange(item)}
            />
          </div>
        }
      </Td>
    </Tr>
  );
};

export default TbodyItemsProfile;
