import { Box, Flex, Spacer, Tooltip } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useState } from "react";
import { ButtonHeaderModule, ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { GiWeightScale } from "react-icons/gi";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
// import Echo from "laravel-echo";
// import { broadcaster } from "laravel-echo-api-gateway";
import { GetWeihingButton } from "../FormWeighingMachine.js/GetWeihing";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { useSelector } from "react-redux";

// window.Echo = new Echo({
//   broadcaster,
//   host: window.wshost,
// });

export const ForGetWeihighing = ({
  values,
  handleInputChange,
  tabIndex,
  setTabIndex,
  set,
  rows,
  setRows,
  children
}) => {
  const { name_typeTransport, weight, automatic, type_weight, weighing_manual } = values;

  const [openAlert] = AlertErrorAlert();
  const [typeWeihingState, setTypeWeihingState] = useState(null);
  const [loading, setLoading] = useState(false);

  const typeWeihing = [
    {
      value: "Tara",
      label: "Tara"
    },
    {
      value: "Bruto",
      label: "Bruto"
    }
  ];

  useEffect(() => {
    const tipodato = typeof set;
    if (typeWeihingState && typeWeihingState.value && tipodato === "function") {
      set({ ...values, type_weight: typeWeihingState.value });
    }
  }, [typeWeihingState, set]);

  const validatPesoyType = () => {
    if (
      type_weight === "" ||
      type_weight === null ||
      type_weight === undefined
    ) {
      openAlert("El tipo de peso es requerido", "error");
    } else if (weight === "" || weight === null || weight === undefined) {
      openAlert("El peso es requerido", "error");
    } else {
      setTabIndex(tabIndex + 1);
    }
  };

  return (
    <>
      {tabIndex !== 2 && (
        <div style={{ width: "100%" }}>
          <InputSelect
            tag={"Tipo de peso"}
            data={typeWeihing}
            set={setTypeWeihingState}
            value={{value:values?.type_weight, label:values?.type_weight} || typeWeihingState} //muestra de manera condicional el valor que llega de back o el valor que el usuario seleccione
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {tabIndex !== 2 && (
          <LabelGroup
            name={"name_typeTransport"}
            value={name_typeTransport}
            onChange={handleInputChange}
            tag={"Tipo de transporte"}
            size={40}
            desicion={true}
          />
        )}

        {tabIndex === 2 && (
          <LabelGroup
            name={"type_weight"}
            value={type_weight}
            onChange={handleInputChange}
            tag={"Tipo de peso"}
            size={45}
            desicion={true}
          />
        )}
        {/*     <LabelGroup
          name={"weight"}
          value={weight}
          onChange={handleInputChange}
          tag={"Peso"}
          size={tabIndex !== 2 ? 30 : 45}
        />
 */}
        <Box display={"flex"} w={`${tabIndex !== 2 ? 50 : 45}%`}>
          <InputWithLabel
            p={1}
            mr={3}
            fontWeight={0}
            _focus={{
              outline: "none"
            }}
            onKeyUp={() => {
              set({ ...values, automatic: false });
            }}
            borderBottom={"none"}
            border={"2px solid #bababa"}
            name={"weight"}
            value={weight}
            onChange={handleInputChange}
            labelText={"Peso"}
            bg={"rgba(242, 242, 242, 0.1)"}
            readOnly={weighing_manual === 0 ? true : automatic ?? false}
            cursor={weighing_manual === 0 ? "no-drop" : "auto"}
          />
          {name_typeTransport !== "" && tabIndex !== 2 && (
            <GetWeihingButton
              loading={loading}
              setLoading={setLoading}
              setValues={set}
              values={values}
              executeFunction={() => {
                set({ ...values, automatic: true });
              }}
            />
          )}
        </Box>
      </div>

      {children}
      {tabIndex !== 2 && (
        <Flex>
          <ButtonStyled
            bgColor={"#bababa"}
            onClick={() => setTabIndex(tabIndex - 1)}
          >
            Volver
          </ButtonStyled>
          <Spacer />
          <ButtonStyled bgColor={"#3182CE"} onClick={validatPesoyType}>
            Siguiente
          </ButtonStyled>
        </Flex>
      )}
    </>
  );
};
