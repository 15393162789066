import React from "react";
import { PdfNationalTerritorySalida } from "./PdfNationalTerritorySalida";
import { PDFRestOfTheWorldSalida } from "./PDFRestOfTheWorldSalida";
import { PDFUserToUserSalida } from "./PDFUserToUserSalida";

export const HeaderOperationSalida = ({ values }) => {
  const { code_operation } = values;
  return (
    <>
      {code_operation === 50
        ? (
          <PdfNationalTerritorySalida values={values} />
        )
        : code_operation >= 201 && code_operation <= 226
          ? (
            <PDFRestOfTheWorldSalida values={values} />
          )
          : code_operation >= 401 && code_operation <= 450
            ? (
              <PdfNationalTerritorySalida values={values} />
            )
            : code_operation >= 601 && code_operation <= 618
              ? (
                <PDFRestOfTheWorldSalida values={values} />
              )
              : code_operation >= 801 && code_operation <= 814
                ? (
                  <PDFUserToUserSalida values={values} />
                )
                : (
                  ""
                )}
    </>
  );
};
