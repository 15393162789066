const { Text, View, StyleSheet } = require('@react-pdf/renderer')
const { BodyAnexxed } = require('./BodyAnexxed')

const styles = StyleSheet.create({
  annexo: {
    border: '1px solid gray',
    borderLeft: 'none',
    borderBottom: '1px solid gray',
    padding: '2px',
    width: '100%',
    height: '17px',
    textAlign: 'center'
  }
})

const CreateAnexxed = ({ data }) => {
  const { DocumentOperation, files, otherDoc } = data
  const dataArray = Object.values(files)
  dataArray.sort((a, b) => a.document_id - b.document_id)

  return (
    <>
      <Text
        style={{
          fontSize: '7'
        }}
      >
        DETALLE DE LOS ANEXOS
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: '7'
        }}
      >
        <Text
          style={{
            ...styles.annexo,
            borderLeft: '1px solid gray'
          }}
        >
          Código
        </Text>
        <Text style={{ ...styles.annexo }}>Descripción</Text>
        <Text style={{ ...styles.annexo }}>Documento</Text>
        <Text style={{ ...styles.annexo }}>Fecha</Text>
        <Text style={{ ...styles.annexo }}>Comentario</Text>
      </View>
      {DocumentOperation.map((e, index) => (
        <BodyAnexxed
          key={e?.id}
          code={dataArray[index]?.document_id}
          Description={e?.name_document}
          Document={dataArray[index]?.attachment_number}
          DateDocument={dataArray[index]?.attachment_date}
          link={dataArray[index]?.downloadFile}
        />
      ))}

      {otherDoc?.map(
        (
          {
            other_attachment_date,
            attachment_url,
            id,
            other_attachment_description,
            other_attachment_name,
            other_attachment_comment,
            attachment_number
          },
          index
        ) => (
          <BodyAnexxed
            key={index}
            code={id || 'N/A'}
            Description={other_attachment_description}
            Comment={other_attachment_comment}
            Document={attachment_number}
            DateDocument={other_attachment_date}
            link={attachment_url}
          />
        )
      )}
    </>
  )
}

export default CreateAnexxed
