import React, { useCallback, useContext, useEffect, useState } from "react";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import { useModal } from "hooks/useModal";
import { useForm } from "hooks/useForm";
import {
  arrayLeftTreasuryWeigth,
  arrayRigthTreasuryWeigth,
  theadContainerTreasury,
  TitleWehithing,
  trasuryWeigthNodeSelectList,
  trasuryWeigthSelectNodeList
} from "../ArrayList/ArrayList";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { __PostForm, __PostJsonData } from "request/Petitions/__Post";
import TbodyWeigh from "../Tables/TbodyWeigh";
import TBodyContainers from "../Tables/TbodyContainers";
import TreasuryCrudContex from "context/TreasuryContex";
import { IconGeneric } from "styled/Icons.styled";
import { GrDocumentText } from "react-icons/gr";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import FormsSearchWeigh from "../Forms/FormsSearchWeigh";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { useDebounce } from "hooks/useDebouce";
import { useDispatch } from "react-redux";
import { clearSearch, getSearch, setSearch } from "redux/actions/searchActions";
import ExportAdvance, { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useSearchUrl } from "hooks/useSearchUrl";

const Weigh = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["order", "consecutive", "vehiclePlate", "completeness", "startDate", "endDate", "shedule", "formType", "formNumber", "completen", "operation", "shedules"], nestedProp: "searchAdvance" });

  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [valueQualified, setValueQuialified] = useState(null);
  const [valueTotal, setValueTotal] = useState(null);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search,
    valueQualified: state?.valueQualified,
  });
  const { searchInformacion } = values;
  const {
    isOpenContainers,
    onOpenContainer,
    onCloseModal,
    dataContainer,
    isOpenForms,
    onOpenForms,
    dataForms,
    exportColumnBy,
    setRequestExport
  } = useContext(TreasuryCrudContex);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    setSearchAdvanced({});
    setValueQuialified(null);
    setChangeViewSearch(false);
  };

  const search = useSelector(getSearch);

  const handleSubmit = (data, formulario) => {
    const info = {
      fields: formulario,
      data: {
        ...data,
        ...(data?.formType && { formType: data?.formType?.value }),
        ...(data?.order && { order: data?.order?.value }),
        ...(data?.userManagment && { userManagment: data?.userManagment?.value }),
        ...search,
      },
    };
    // console.log(info);


    exportColumnBy(info, onCloseExport);
  };

  const GetWeighIns = useCallback(async () => {
    setLoading(true);
    const dataWeighing = new FormData();
    dataWeighing.append("order", "desc");
    dataWeighing.append("scale", true);
    try {
      const res = await __PostForm(
        environment.TREASURY_WEIGHING,
        token,
        dataWeighing
      );
      const requestData = res?.data?.status?.data.data;
      console.log("Respuesta data", requestData);
      if (requestData.length !== 0) {
        setCurrentPage(requestData);
      } else {
        setCurrentPage([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  const searchSimple = async (informacion) => {


    /* const data = new FormData();
    if (informacion === debouncedSearchInfo) {
      data.append("search", informacion)
    }
    if (informacion === valueQualified.value) {
      data.append('qualifiedUser_id', informacion)
    } */

    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.TREASURY_WEIGHING,
        token,
        informacion
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced({});
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        const requestSearch = res?.data?.status?.data.data;
        const lasTArray = requestSearch[requestSearch.length - 1];
        setValueTotal(lasTArray.total);
        setCurrentPageTwo(res?.data?.status?.data.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const dispatchRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...(valueQualified && valueQualified?.value && {
        qualifiedUser_id: valueQualified?.value
      }),
      ...(searchInformacion && { search: searchInformacion }),
      ...searcheAdvanced
    }
    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(data);
      dispatchRedux(setSearch(data));
      return;
    }
    dispatchRedux(clearSearch());
    setChangeViewSearch(false);
    GetWeighIns();
  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZone_id]);

  useEffect(() => {
    setRequestExport(environment.TREASURY_EXPORT_MODULE_WEIGTH);
  }, [setRequestExport]);

  return (
    <>
      <Title style={{ marginTop: 30 }}>Pesajes</Title>
      <ModalGeneric
        isOpen={isOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
      >
        <FormsSearchWeigh
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
        nodeList={trasuryWeigthNodeSelectList}
        selectNodeList={trasuryWeigthSelectNodeList}
        handleSubmit={handleSubmit}
        dafaulActive={[
          "passForm_number",
          "weighings.id",
          "treasury_charges.value",
          "value"
        ]}
      />

      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
      // size={"4xl"}
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeftTreasuryWeigth}
          listRight={arrayRigthTreasuryWeigth}
          dafaulActive={[
            "passForm_number",
            "weighings.id",
            "treasury_charges.value",
            "value"
          ]}
          existingOrder={true}
        />
      </ModalGeneric> */}

      <div className="container p-4">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          setValueQuialified={setValueQuialified}
          valueQualified={valueQualified}
          allAccess={allAccess}
          myPermission={myPermission}
        />
        <ExportDetail onOpen={onOpenExport} />
      </div>

      <ModalGeneric
        isOpen={isOpenContainers}
        onOpen={onOpenContainer}
        onClose={onCloseModal}
        title="Ver contenedores"
      >
        <TableOrder
          thead={theadContainerTreasury}
          data={dataContainer || []}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TBodyContainers}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenForms}
        onOpen={onOpenForms}
        onClose={onCloseModal}
        title="Formulario asociados al pase"
        size={"sm"}
      >
        {dataForms &&
          dataForms.map((item, index) => (
            <div className="p-2">
              <IconGeneric as={GrDocumentText} />
              <span key={index}>Formulario número: {item?.code_entryForm}</span>
              <br />
            </div>
          ))}
      </ModalGeneric>

      <HeaderSearch>
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div className="px-5">
                {valueTotal && valueTotal !== null && (
                  <div className="d-flex">
                    <Title>Valor Total: {valueTotal}</Title>
                  </div>
                )}
                <div>
                  <TableOrder
                    thead={TitleWehithing}
                    data={currentPageTwo}
                    setData={setCurrentPageTwo}
                    loading={loading}
                    tbodyData={TbodyWeigh}
                  />

                  <Pagination
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    loading={loading}
                  />
                </div>
              </div>
            )
          )
          : (
            <div>
              <TableOrder
                thead={TitleWehithing}
                data={currentPage}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodyWeigh}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default Weigh;
