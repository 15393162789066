export const thead = [
  { name: "Tipo de registro", order: null },
  { name: "Empresa", order: null },
  { name: "N° documento", order: null },
  { name: "Registró", order: null },
  { name: "Fecha", order: null },
  { name: "Hora", order: null },
  { name: "Puerta vehícular", order: null },
  { name: "Placa", order: null },
  { name: "Cédula", order: null },
  { name: "Conductor", order: null },
  { name: "Tip.Transp", order: null },
  { name: "Cont. 1", order: null },
  { name: "Cap. Cont. 1", order: null },
  { name: "Cont. 2", order: null },
  { name: "Cap. Cont. 2", order: null },
  { name: "Descripción", order: null }
];
