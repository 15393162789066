import React from "react";
import { Td, Tr } from "@chakra-ui/react";
// import MenuSecutiry from "components/Menu/MenuSecutiry";

/**
 *  TbodyTable - Componente que muestra los items mediante la tabla en el componente de Reporte de seguridad
 * @component
 * @param {Object} data data que se muestra en la tabla del componete de Reporte de seguridad.
 * @return {Component} Retorna componente tabla jsx
 */

const TbodyTable = ({ data }) => {
  const {
    driver,
    container_1,
    container_2,
    person_name,
    date_security,
    time_security,
    vehicle_plate,
    name_qualified,
    identification,
    passForm_number,
    name_vehicularDoor,
    name_typeTransport,
    capacity_container_1,
    description_security,
    capacity_container_2,
    type
  } = data;

  return (
    <Tr>
      <Td className="text-center">{type ?? "N/A"}</Td>
      <Td className="text-center">{name_qualified ?? "N/A"}</Td>
      <Td className="text-center">
        {passForm_number ?? "N/A"}
      </Td>
      <Td className="text-center"> {person_name ?? "N/A"}</Td>
      <Td className="text-center"> {date_security ?? "N/A"}</Td>
      <Td className="text-center"> {time_security ?? "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {name_vehicularDoor ?? "N/A"}
      </Td>
      <Td className="text-center"> {vehicle_plate ?? "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {identification ?? "N/A"}
      </Td>
      <Td className="text-center"> {driver ?? "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {name_typeTransport ?? "N/A"}
      </Td>
      <Td className="text-center"> {container_1 ?? "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {capacity_container_1 ?? "N/A"}
      </Td>
      <Td className="text-center"> {container_2 ?? "N/A"}</Td>
      <Td className="text-center">
        {" "}
        {capacity_container_2 ?? "N/A"}
      </Td>
      <Td className="text-center">
        {" "}
        {description_security ?? "N/A"}
      </Td>
    </Tr>
  );
};

export default TbodyTable;
