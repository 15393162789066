/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import FormUserShow from "../FormShow/FormUserShow";
import TableOrder from "components/Tables/TableOrder";
import FormAddLicenses from "../forms/FormAddLicenses";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import EditFormUsuario from "components/Form/EditFormUsuario";
import TbodyUserConfig from "components/Tables/TbodyUserConfig";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __GeTQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import { __PostForm } from "request/Petitions/__Post";

const UserConfig = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setCollectionRatesUserConfig } = useGlobalContext();

  const {
    update,
    setFormEdit,
    setFormShow,
    setRequestEdit,
    setRequestCreate,
    setFormLicensias
  } = useContext(ConfigurationCRUDContext);

  const thead = [
    { name: "NIT", order: "nit_qualified" },
    { name: "Nombre", order: "name_qualified" },
    { name: "Alias", order: "nickname_qualified" },
    { name: "Clasificación", order: "name_qualificationQualifiedUser" },
    { name: "Licencia", order: 'licenses_id[0]["name_license"]' },
    { name: "Ven. Lic", order: 'licenses_id[0]["expireDate_license"]' },
    { name: "Cobro", order: "collect" },
    { name: "Saldo documento", order: "collect" },
    { name: "Saldo báscula", order: "collect" },
    { name: "Estado de bloqueo", order: "block_status" },
    { name: "Seguimiento", order: "block_status" },
    { name: "Estado", order: "status_qualified" },
    { name: "Acciones", order: "actions_qualified" }
  ];

  const formEdit = () => {
    return <EditFormUsuario />;
  };

  const formShow = () => {
    return <FormUserShow />;
  };

  const formAgregarLi = () => {
    return <FormAddLicenses />;
  };

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formShow);
    setFormLicensias(formAgregarLi);
    setRequestCreate(environment.QUALIFIED_USERS_CREATE);
    setRequestEdit(environment.QUALIFIED_USERS_UPDATE);
  }, [page, q, update]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configUserConfig", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  const [firsRender, setFirstRender] = useState(true)

  const getTariff = useCallback(
    async zoneId => {
      try {
        const res = await __PostForm(environment.GET_TARRIF_ZONE, token, zoneId);
        const dataInput = res.data.status.data.reduce((acc, item) => {
          if (item?.id) {
            acc[item.id] = item.name_type_rate;
          }
          return acc;
        }, {});

        console.log(dataInput);
        setCollectionRatesUserConfig(dataInput);

      } catch (e) {
        console.log(e)
      }
    },
    [token]
  )
  useEffect(() => {
   if (firsRender) getTariff()
    setFirstRender(false)
  }, [])


  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Usuarios Calificados" />
      {isLoading || (
        <TableOrder
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          thead={thead}
          tbodyData={TbodyUserConfig}
          loading={isLoading}
          isNum={false}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default UserConfig;
