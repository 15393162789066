import React, { useCallback, useState } from "react";
import { Button, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { BsCheckCircle, BsCheckCircleFill, BsXCircle } from "react-icons/bs";
import { IconGeneric, IconTracking } from "styled/Icons.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";
import environment from "constants/apiConst";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import TableOrder from "components/Tables/TableOrder";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import Pagination from "components/Pagination/Pagination";
import { RiRadioButtonFill } from "react-icons/ri";

const TbodyWithExportUc = ({ data, viewLabel }) => {
  const navigate = useNavigate();

  const handleName = (index) => {
    const valor = "index_" + index;
    if (viewLabel[valor]) {
      return true;
    }
  };

  const urlTemplates = {
    "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
    INGRESO: `/FormShow?FormEntry=${data?.form_id}`
  };

  const { requestData } = useGlobalContext()
  const { mutate, isPending } = useMutation({
    queryKey: ["dataTracking"],
    mutationFn: requestData,
  })
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);

  const showTrackingItem = useCallback(
    async (trackings) => {

      try {
        const formData = new FormData();
        trackings?.forEach((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        mutate({
          Endpoint: environment.GET_TRACKINGS_FMM,
          data: formData,
          pageParam: page,
          PropertyBody: "sendFormDataContentPOST"
        }, {
          onSuccess: (data) => {
            setCurrentPage(data?.data?.status?.data?.data);
            setMaxPage(data?.data?.status?.data?.last_page);
          }
        })
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
      }
    },
    [page]
  );

  return (
    <Tr>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={isPending}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={isPending}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Td className="text-center">
        {handleName(0) ? data?.qualified_user_nit : ""}
      </Td>
      <Td className="text-center">
        {handleName(1) ? data?.qualified_user_name : ""}
      </Td>
      <Td className="text-center">{handleName(2) ? data?.product_code : ""}</Td>
      <Td className="text-center">{handleName(3) ? data?.type_product : ""}</Td>
      <Td className="text-center">
        {handleName(4) ? data?.product_group : ""}
      </Td>
      <Td
        className="text-center"
      >
        {handleName(5)
          ? (
            urlTemplates?.[data?.type_form]
              ? (
                <NavLink
                  to={urlTemplates?.[data?.type_form]}
                  className="ms-2"
                  target="_blank"
                >
                  <span className="formNumber">
                    {data?.inventory_reference_description}
                  </span>
                </NavLink>
              )
              : (
                <> {data?.inventory_reference_description}</>
              )
          )
          : (
            ""
          )}
      </Td>
      <Td className="text-center">
        {handleName(6)
          ? (
            data?.customs_clearance_acceptance_number
              ? (
                <NavLink
                  to={`/ShowCustomsClearance?Customs=${data?.customs_clearance_id}`}
                  className="ms-2"
                >
                  <span className="formNumber">
                    {data?.customs_clearance_acceptance_number}
                  </span>
                </NavLink>
              )
              : (
                "N/A"
              )
          )
          : (
            ""
          )}
      </Td>
      <Td className="text-center">{handleName(7) ? data?.serial : ""}</Td>
      <Td className="text-center">
        {handleName(8) ? data?.subheading_code : ""}
      </Td>
      <Td className="text-center">
        {handleName(9) ? data?.product_description : ""}
      </Td>
      <Td className="text-center">
        {handleName(10) ? (data?.invoice ? data?.invoice : "N/A") : ""}
      </Td>
      <Td className="text-center">
        {handleName(11)
          ? (
            data?.freely_available_material
              ? (
                <Tooltip
                  placement="top"
                  hasArrow
                  label={data.freely_available_material}
                >
                  <span>
                    {data.freely_available_material === "Si" ? (
                      <IconGeneric color={"#03C39A"} as={BsCheckCircle} />
                    ) : (
                      <IconGeneric color={"#BA1F33"} as={BsXCircle} />
                    )}
                  </span>
                </Tooltip>
              )
              : (
                "N/A"
              )
          )
          : (
            ""
          )}
      </Td>
      <Td className="text-center">
        {handleName(12) ? data?.inventory_integration_certificate : ""}
      </Td>
      <Td className="text-center">
        {handleName(13) ? data?.inventory_operation_code : ""}
      </Td>
      <Td className="text-center">
        {handleName(14) ? data?.inventory_initial_operation : ""}
      </Td>
      <Td className="text-center">
        {handleName(15) ? data?.inventory_trade_agreement : ""}
      </Td>
      <Td isNumeric>
        {handleName(16) ? data?.inventory_quantity_available : ""}
      </Td>
      <Td isNumeric>{handleName(17) ? data?.inventory_quantity_go_out : ""}</Td>
      <Td isNumeric>
        {handleName(18) ? data?.inventory_quantity_reserved : ""}
      </Td>
      <Td isNumeric>
        {handleName(19) ? data?.inventory_quantity_temporary : ""}
      </Td>
      <Td isNumeric>
        {handleName(20) ? data?.inventory_quantity_free_zone : ""}
      </Td>
      <Td className="text-center">
        {handleName(21) ? data?.business_unit_code : ""}
      </Td>
      <Td isNumeric>
        {handleName(22) ? data?.inventory_quantity_available_product : ""}
      </Td>
      <Td isNumeric>
        {handleName(23) ? data?.inventory_quantity_go_out_product : ""}
      </Td>
      <Td isNumeric>
        {handleName(24) ? data?.inventory_quantity_reserved_product : ""}
      </Td>
      <Td isNumeric>
        {handleName(25) ? data?.inventory_quantity_temporary_product : ""}
      </Td>
      <Td isNumeric>
        {handleName(26) ? data?.inventory_quantity_free_zone_product : ""}
      </Td>
      <Td className="text-center">
        {handleName(27) ? data?.product_business_unit_code : ""}
      </Td>
      <Td className="text-center">
        {handleName(28) ? data?.inventory_nature_of_inventory : ""}
      </Td>
      <Td isNumeric>
        {handleName(29) ? data?.inventory_temporary_net_weight : ""}
      </Td>
      <Td isNumeric>
        {handleName(30) ? data?.quantity_authorize_temporal : ""}
      </Td>
      <Td isNumeric>
        {handleName(31) ? data?.quantity_authorize_temporal_product : ""}
      </Td>
      <Td isNumeric>{handleName(32) ? data?.net_weight_reserved : ""}</Td>
      <Td className="text-center">
        {handleName(33) ? data?.inventory_third_party_name : ""}
      </Td>
      <Td className="text-center">
        {handleName(34) ? data?.inventory_third_party_identification_num : ""}
      </Td>
      <Td isNumeric>{handleName(35) ? data?.inventory_quantity_total : ""}</Td>
      <Td isNumeric>
        {handleName(36) ? data?.inventory_quantity_total_product : ""}
      </Td>
      <Td isNumeric>{handleName(37) ? data?.inventory_precision : ""}</Td>
      <Td isNumeric>
        {handleName(38) ? data?.inventory_value_fob_available : ""}
      </Td>
      <Td isNumeric>
        {handleName(39) ? data?.inventory_value_cif_available : ""}
      </Td>
      <Td isNumeric>{handleName(40) ? data?.net_weight_available : ""}</Td>
      <Td isNumeric>{handleName(41) ? data?.fob_temporal : ""}</Td>

      <Td>
        {handleName(42) && data?.tracking?.length
          ? (
            <Tooltip
              hasArrow
              placement="top"
              label={
                data?.tracking?.some((tracking) => tracking.status === 1)
                  ? "En seguimiento"
                  : data?.tracking?.every((tracking) => tracking.status === 2)
                    ? "Estuvo en seguimiento"
                    : ""
              }
            >
              {data?.tracking?.length
                ? (
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <IconTracking
                      color={
                        data?.tracking?.some((tracking) => tracking?.status === 1)
                          ? "#E64545"
                          : "#20B95A"
                      }
                      onClick={() => showTrackingItem(data?.tracking)}
                      as={RiRadioButtonFill}
                    ></IconTracking>
                  </span>
                )
                : (
                  ""
                )}
            </Tooltip>
          )
          : (
            ""
          )}
      </Td>
      <Td isNumeric>{handleName(43) ? data?.fob_reserved : ""}</Td>
      <Td isNumeric>{handleName(44) ? data?.cif_reserved : ""}</Td>
      <Td isNumeric>{handleName(45) ? data?.entry_quantity : ""}</Td>
      <Td isNumeric>{handleName(46) ? data?.entry_quantity_product : ""}</Td>
    </Tr>
  );
};

export default TbodyWithExportUc;
