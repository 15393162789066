import React, { useContext, useEffect } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import BlockingContext from "context/BlockingContex";

const TableDependency = ({ data }) => {
  const { onOpenHistory, setHistory } = useContext(BlockingContext);

  const dataHistory = () => {
    setHistory(data?.history?.data);
    onOpenHistory();
  };

  return (
    <Tr>
      <Td className="text-center">{data?.dependency}</Td>
      <Td className="text-center">
        {data?.action === 1 ? "Bloqueado" : "Desbloqueado"}
      </Td>
      <Td className="text-center">{data?.lock_type}</Td>

      <Tooltip placement="top" hasArrow label={"Ver historial"}>
        <Td className="text-center">
          <IconGeneric color={"#1F7AC3"} as={BsEyeFill} onClick={dataHistory} />
        </Td>
      </Tooltip>
    </Tr>
  );
};

export default TableDependency;
