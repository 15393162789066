import React, { useEffect, useState } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import {
  LabelGroup
} from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { MaxLength } from "utils/MaxLength";
import InputSelect from "components/InputSelect/InputSelect";
import { useSearchUrl } from "hooks/useSearchUrl";

/**opciones para el input select de estado */
const dateEstatus = [
  { value: 1, label: "Activo" },
  { value: 0, label: "Inactivo" },
];

export const FormSearchProducts = ({ setSearchAdvanced, onClose }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["productCode", "description", "subheading", "businessUnit", "convertionFactor", "group", "status_product"], nestedProp: "searchAdvance" })
  const [values, handleInputChange, reset, setValues] = useForm({
    code: state?.productCode || "",
    description: state?.description || "",
    subheading: state?.subheading || "",
    unitOfMeasurement: state?.businessUnit || "",
    conversionFactor: state?.convertionFactor || "",
    group: state?.group || "",
  });

  useEffect(() => {
    console.log({ status_product: state?.status_product });
  }, [state?.status_product]);

  const [estatus, setEstatus] = useState(dateEstatus.find((item) => item?.value === state?.status_product)); //estado para el input select de "Estado"

  const {
    code,
    description,
    subheading,
    unitOfMeasurement,
    conversionFactor,
    group
  } = values;

  const handleSubmit = () => {
    const data = {
      ...(code && { productCode: code }),
      ...(description && { description: description }),
      ...(subheading && { subheading }),
      ...(unitOfMeasurement && { businessUnit: unitOfMeasurement }),
      ...(conversionFactor && { convertionFactor: conversionFactor }),
      ...(group && { group }),
      ...(estatus && { status_product: estatus?.value })
    }
    setParamsSearch({ params: data });
    setSearchAdvanced(data);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code"}
          value={code}
          onChange={handleInputChange}
          tag={"Código"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"description"}
          value={description}
          onChange={handleInputChange}
          tag={"Descripción"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"subheading"}
          value={subheading}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={45}
          tipo={"number"}
          maxLength={MaxLength?.global?.code_subheading}
        />
        <LabelGroup
          name={"unitOfMeasurement"}
          value={unitOfMeasurement}
          onChange={handleInputChange}
          tag={"Unidad de medida"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"conversionFactor"}
          value={conversionFactor}
          onChange={handleInputChange}
          tag={"Factor de conversión"}
          size={45}
          tipo={"number"}
        />
        <LabelGroup
          name={"group"}
          value={group}
          onChange={handleInputChange}
          tag={"Grupo"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* input para filtrar por el estado de producto (Activo/Inactivo) */}
        <InputSelect
          size={45}
          name={"Estatus"}
          tag={"Estado"}
          data={dateEstatus}
          value={estatus}
          set={setEstatus}
        />
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  )
}
