import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const TBodyGarbages = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">{data?.type_product}</Td>
      <Td className="text-center">{data?.reference}</Td>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Td className="text-center">{data?.quantity}</Td>
      <Td className="text-center">{data?.business_unit}</Td>
      <Td className="text-center">{data?.net_weight_kilo}</Td>
      <Td className="text-center">{data?.total_us}</Td>
      <Td className="text-center">{data?.description_product}</Td>
    </Tr>
  );
};

export default TBodyGarbages;
