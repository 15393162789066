/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "hooks/useForm";
import ButtonView from "../CorrectionForm/Logs/BtnLog";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { SubTitleHeader } from "components/HeaderForm/Style";
import { FmmOHeader } from "./Formulario de cabecera/FmmOHeader";
import { FmmOheadercorrected } from "./Formulario de cabecera/FmmOheadercorrected";
import FormCrudCRUDContext from "context/FormCrudContex";
// import { AuthContext } from "context/AuthContext";
import { TitleOperationOutput } from "../TitleOperation/TitleOperationOutput";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { NationalTerritoryCorrect } from "./container/NationalTerritoryCorrect";
import { RestOfTheWorldCorrect } from "./container/RestOfTheWorldCorrect";
import { UserToUserCorrect } from "./container/UserToUserCorrect";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { BtnWithToltip } from "components/BtnWithToltip/BtnWithToltip";
import { CgPlayListAdd } from "react-icons/cg";
import { ButtonStyled } from "styled/Buttons.styled";
import { FmmItemOriginal } from "./item/FmmItemOriginal";
import { FmmOheadercorrectedShow } from "./Formulario de cabecera/FmmOheadercorrectedShow";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { TitleItemFmm, TitleItemFmmShow } from "../arrayHeadItems";
import TBodyItemCorrect from "../Table/TBodyItemCorrect";
import TBodyItemCorrectShow from "../Table/TBodyItemCorrectShow";
import { FmmItemCorregido } from "./item/FmmItemCorregido";
import { __GetItems, __ShowItems } from "request/Forms/_CrudForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Trm } from "request/configuration/__Trm";
import ModalDelete from "components/modal/ModalDelete";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import { Commentary } from "components/Commentary/Commentary";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import { ExportPdfOutputCorrect } from "../Export/pdfCorrect/ExportPdfOutputCorrect";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { SubTitle } from "styled/Title";
import { CapsulaContainerOutput } from "./CapsulaContainerOutput";
import { __Post } from "request/Petitions/__Post";
import { parseFloat } from "utils/parseFloat";
import useStatusListener from "hooks/useStatusListener";
import isArrayNotEmpty, {
  isArray,
  isNonEmptyString,
  isNumber,
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
  isString,
} from "utils/type-check-utils";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import { createKey, createObjectFromItem } from "../FormExit/BodyFormExit";
import { Storage } from "hooks/useStorage";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { GroupInputUpload } from "../GroupInputUpload";
import InputSelect from "components/InputSelect/InputSelect";
import CreateAt from "../View/CreateAt";

export const ReviewCorrectionFmmO = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loadingItems, setLoadingItems] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // navigate
  const [currentPage, setCurrentPage] = useState([]);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [suma, setSuma] = useState();
  const [multiplicacion, setMultiplicacion] = useState();
  const [sumaCorrect, setSumaCorrect] = useState();
  const [multiplicacionCorrect, setMultiplicacionCorrect] = useState();
  const [attachment, setAttachment] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [, setFilesFront] = useState({});
  const [nameFiles, setNameFiles] = useState([]);
  const [commentarios, setComentarios] = useState([]);
  const [LoadingCocument, setLoadingCocument] = useState({
    label: false,
    name: "",
  });

  const { FormOutput = null, ShowOutput = null } = queryString.parse(
    location.search
  );

  const [valuesHeader, handleInputChangeHeader, resetHeader, setValuesHeader] =
    useForm({});

  const {
    status_outputFormCorrected,
    code_outputFormCorrected,
    name_qualified,

    // code_operation,
    operationCorrected_id,
    thirdPartieCorrected_id,
    thirdPartie_id,
  } = valuesHeader;

  const [valuesItem, handleInputChangeItem, resetItem, setValuesItem] = useForm(
    {}
  );

  const {
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    exchange_rate,
    exchange_rate_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_correct,
  } = valuesItem;

  // const { authUser } = useContext(AuthContext);

  const {
    isOpenRadicar,
    onOpenRadicar,
    onCloseRadicar,
    dataRadicar,
    changeStatusCorregido,
    info,
    packagings,
    valueFlag,
    valueMethod,
    dataCountryBuy,
    dataCountryOrigin,
    setValueSubheading,
    getThirdParties,
    update,
    dataThirdDefault,
    setStateCurrentExport,
    getOperationOutput,
    dataOperation,
    setDataSelectOperation,
    setOpcional,
    opcional,
    dataDUTA,
    opcionalCurrent,
    setOpcionalCurrent,
    setselectOperation,
    setselectExport,
    setselectEmbarcacion,
    setRefunds,
    updateCorrectDocument,
    refunds,
    selectEmbarcacion,
    selectOperation,
    selectExport,
    setRequestDelete,
    dataToEditItems,
    dataToCopyItems,
    getCountry,
    getPackaging,
    getMethodTransport,
    getCodeProduct,
    dataAgreements,
    setDataAgreementsNew,
    dataPackagings,
    setDataPackagingsNew,
    dataTransportMethod,
    setDataTransportMethodNew,
    setValueProducts,
    setInfo,
    setDataCountryOrigin,
    setValueFlag,
    setPackagings,
    setValueMethod,
    setDataCountryBuy,
    setDataToEditItems,
    setDataToCopyItems,
    valueProducts,
    allProducts,
    valueSubheading,
    CorrectItem,
    createCommentary,
    isOpenDelete,
    onCloseModal,
    dataToDelete,
    deleteItemsCorrect,
    newCommentary,
    setStateNit,
    StateNit,
    stateCollapse,
    setstateCollapse,
    setshowItemsView,
    setRequestChangeStatusForm,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
  } = useContext(FormCrudCRUDContext);
  const { setDataCorrected, nameDocument } = useGlobalContext();
  const [DocumentOperation, setDocumentOperation] = useState([]);
  const [code, setCode] = useState(null);

  const workDirty = (data) => {
    handleCancelCabecera();
    setValuesHeader(data);
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMO_CORRECT);

    const {
      qualifiedUser_id,
      code_operation,
      check_duta,
      check_duta_corrected,
      shipment_corrected,
      refund_corrected,
      documents,
      files,
      other_files,
      qualifiedUser4_id,
      nit_qualified4,
      name_qualified4,
      identification_num,
      business_name,
      business_nameCorrected,
      id,
      identification_numCorrected,
    } = data;

    setStateCurrentExport(
      `${identification_num !== null ? identification_num : ""
      } ${business_name}`
    );

    setselectExport({
      label: `${identification_numCorrected !== null ? identification_numCorrected : " "
        } ${business_nameCorrected}`,
      value: thirdPartie_id,
    });
    setDocumentOperation(documents);
    const fromServer = data.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));
    setOtherDocuments(data?.other_files);
    setDataCorrected((prev) => ({
      ...prev,
      files: Object.assign({}, files, fromServer),
    }));
    setOtherDocuments(data?.other_files);

    getCodeProduct(qualifiedUser_id);

    if (check_duta) {
      setOpcionalCurrent(check_duta);
    }

    if (check_duta_corrected) {
      setOpcional(check_duta_corrected);
    }

    if (refund_corrected) {
      setRefunds({ label: refund_corrected, value: refund_corrected });
    }

    if (shipment_corrected) {
      setselectEmbarcacion({
        label: shipment_corrected,
        value: shipment_corrected,
      });
    }
    if (qualifiedUser4_id) {
      setStateNit({
        value: qualifiedUser4_id,
        label: nit_qualified4 + " " + name_qualified4,
      });
    }

    getThirdParties(qualifiedUser_id);
    getOperationOutput(qualifiedUser_id);
    setCode(code_operation);
  };

  const ShowDataCorrect = async (id) => {
    setLoading(true);
    try {
      const res = await __GetDataId(environment.CORRECT_FMMO_SHOW, token, id);
      const destrucData = res?.data?.status;
      const { code, data } = destrucData;
      if (code === 200) {
        if (data.status_outputFormCorrected === "PRESENTADO") {
          setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMO_CORRECT);

          workDirty(data);
          setLoading(false);
        } else {
          openAlert(
            `El formulario ${data.code_outputFormCorrected} esta en estado ${data.status_outputFormCorrected} y no puede ser revisado`,
            "error"
          );
          navigate(`/FormCorrectionOutput?ShowOutput=${data.id}`);
        }
      } else {
        setLoading(false);
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTrm = async () => {
    try {
      const res = await __Trm(token);
      setValuesItem({
        ...valuesItem,
        exchange_rate_corrected: res?.data?.status?.data.value_trm,
      });
      // setValorDolarActual(res?.data?.status?.data.value_trm);
    } catch (error) { }
  };

  const handleSubmit = () => {
    delete valuesHeader.operationCorrected_id;
    delete valuesHeader.thirdPartieCorrected_id;
    delete valuesHeader.modality_corrected;
    delete valuesHeader.shipment_corrected;
    delete valuesHeader.check_duta_corrected;
    delete valuesHeader.documents;
    delete valuesHeader.users;

    const formData = new FormData();
    Object.entries(valuesHeader).map(([key, valor]) => {
      if (valor) {
        formData.append(`${key}`, valor);
      }
    });

    formData.append("operationCorrected_id", selectOperation.id);
    formData.append(
      "thirdPartieCorrected_id",
      selectExport ? selectExport.value : ""
    );
    formData.append("refund_corrected", refunds.value);
    formData.append("shipment_corrected", selectEmbarcacion.value);
    formData.append("check_duta_corrected", opcional || "");

    if (StateNit) {
      formData.append("qualifiedUser4_id", StateNit ? StateNit.value : "");
    }

    updateCorrectDocument(
      environment.CORRECT_FMMO_UPDATE,
      formData,
      handleCancelCabecera,
      "/FormCorrectionOutput?ShowOutput"
    );
  };

  const [otherDocuments, setOtherDocuments] = useState([]);
  const [files, setFiles] = useState({});
  const { requestData } = useGlobalContext();

  function adjustDocumentName(name) {
    const suffix = "_doc_corrected_req";
    if (name.includes(suffix)) {
      return name.replace(suffix, "");
    }
    return name;
  }

  const updateAndCreate = async (
    data = undefined,
    payloadManual = false,
    isCorrected = null
  ) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput);
    const dataAttachments = attachment[0];
    const vaporRes = (await data) || null;

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };
    (dataAttachments?.id || dataAttachments?.other_attachment_description) &&
      formData.append(
        "other_attachment[]",
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      );
    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    const date =
      dataAttachments?.other_attachment_date ||
      dataAttachments?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      Object.keys(data).forEach((documentId) => {
        if (!data[documentId].from_server) {
          formData.append(
            isNumberFormatable(isCorrected) && Number(isCorrected) === 1
              ? "document_corrected[" + adjustDocumentName(documentId) + "]"
              : "attachment[" + documentId + "]",
            JSON.stringify(data[documentId])
          );
        }
      });
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.CORRECT_FMMO_UPDATE,
      PropertyBody: "sendFormDataContentPOST",
    };

    setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      ShowDataCorrect(FormOutput || ShowOutput);
      return;
    }
  };

  const getItems = async (idEntry) => {
    const formData = new FormData();
    formData.append("outputFormCorrected_id", idEntry);
    formData.append("orderBy", orderBy?.value);
    setPage(1);
    setLoadingItems(true);
    setRequestDelete(environment.DELECT_CORRECT_ITEM_OUTPUT);
    try {
      const res = await __GetItems(
        token,
        formData,
        page,
        environment.GET_CORRECT_ITEM_OUTPUT
      );
      const respuesta = res?.data?.status;
      if (respuesta.code === 200) {
        setCurrentPage(respuesta?.data?.data);
        setMaxPage(respuesta?.data?.last_page);
      } else {
        openAlert(respuesta.message, "error");
      }
    } catch (error) {
    } finally {
      setLoadingItems(false);
    }
  };

  const showItems = async (id) => {
    setLoadingItems(true);
    try {
      const res = await __ShowItems(
        token,
        id,
        environment.SHOW_CORRECT_ITEM_OUTPUT
      );
      const response = res?.data?.status;
      const { code, data, message } = response;

      if (code === 200) {
        const {
          code_product_corrected,
          productCorrected_id,
          code_country_ori_corrected,
          name_country_ori_corrected,
          countryOriginCorrected_id,

          countryProcedencyCorrected_id,
          code_country_purs_corrected,
          name_country_purs_corrected,
          countryPurchaseCorrected_id,
          code_contry_flag_corrected,
          name_country_flag_corrected,
          flagCorrected_id,
          code_packaging_corrected,
          name_packaging_corrected,
          packagingCorrected_id,
          code_transportMethod_corrected,
          name_transportMethod_corrected,
          transportMethodCorrected_id,
          code_country_des,
          name_country_des,

          subheadingCorrected_id,
        } = data;

        setstateCollapse(false);
        setValuesItem(data);

        setValueProducts({
          label: code_product_corrected !== null ? code_product_corrected : "",
          value: productCorrected_id !== null ? productCorrected_id : "",
        });

        setInfo({
          label: code_country_ori_corrected + " " + name_country_ori_corrected,
          value: countryOriginCorrected_id,
        });

        setDataCountryOrigin({
          label: code_country_des + " " + name_country_des,
          value: countryProcedencyCorrected_id,
        });

        setDataCountryBuy({
          label:
            code_country_purs_corrected + " " + name_country_purs_corrected,
          value: countryPurchaseCorrected_id,
        });

        setValueFlag({
          label: code_contry_flag_corrected + " " + name_country_flag_corrected,
          value: flagCorrected_id,
        });

        setPackagings({
          label: code_packaging_corrected + " " + name_packaging_corrected,
          value: packagingCorrected_id,
        });

        setValueMethod({
          label:
            code_transportMethod_corrected +
            " " +
            name_transportMethod_corrected,
          value: transportMethodCorrected_id,
        });
        setValueSubheading(subheadingCorrected_id);
        // setSelectCurrency(response?.coin);
        setLoadingItems(false);
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingItems(false);
    }
  };

  const handleClean = () => {
    setInfo([]);
    setValueProducts(null);
    setDataCountryOrigin([]);
    setDataCountryOrigin([]);
    setValueMethod([]);
    setDataCountryBuy([]);
    setValueFlag([]);
    setPackagings([]);
    setValueSubheading([]);
    // setAcuerdos([]);
    resetItem();
    // setUnidad([]);
  };

  const handleCancel = () => {
    setstateCollapse(!stateCollapse);
    setshowItemsView(false);
    setDataToEditItems(null);
    setDataToCopyItems(null);
    handleClean();
  };

  const handleCancelCabecera = () => {
    setAttachment([]);
    setAttachmentDescription([]);
    setAttachmentType([]);
    setNameFiles([]);
  };

  const hadleSaveItems = () => {
    delete valuesItem?.status_item;
    const dataSave = {
      ...valuesItem,
      outputFormCorrected_id: FormOutput
        ? Number(FormOutput)
        : ShowOutput
          ? Number(ShowOutput)
          : "",
      productCorrected_id: valueProducts ? valueProducts.value : "",
      countryDestinyCorrected_id: dataCountryOrigin
        ? dataCountryOrigin.value
        : "",
      countryOriginCorrected_id: info ? info.value : "",
      countryPurchaseCorrected_id: dataCountryBuy ? dataCountryBuy.value : "",
      transportMethodCorrected_id: valueMethod ? valueMethod.value : "",
      flagCorrected_id: valueFlag ? valueFlag.value : "",
      packagingCorrected_id: packagings ? packagings.value : ":",
      subheadingCorrected_id: valueSubheading,
      // countryDestinyCorrected_id: 1,
      // tradeAgreementCorrected_id: acuerdos ? acuerdos.value : "",
      status_item: 1,
    };
    let message;

    if (dataToEditItems) {
      message = "Item actualizado con exito";
      CorrectItem(
        environment.UPDATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message
      );
    } else if (dataToCopyItems) {
      message = "Item copiado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message
      );
    } else {
      message = "Item creado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM_OUTPUT,
        dataSave,
        handleCancel,
        message
      );
    }
  };

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  const extensionOptions = [
    ".doc",
    ".docx",
    ".pdf",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".xls",
    ".xlsx",
    ".xlsm",
    "image/png",
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
  ];

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const handleFilesInput = async (e) => {
    try {
      e.preventDefault();
      const resModal = await onOpenUpload();
      const { name, files: uploadedFiles, id } = e.target;
      const idFile = e?.target?.id;
      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);

      if (!isValidExtension) return;

      const idDocument = extractDocumentId(adjustDocumentName(idFile));
      const uploadResponse = await uploadFile(uploadedFiles[0]);

      updateFileState(uploadResponse, uploadedFiles[0]?.name, idDocument, e);
      updateNameFilesState(name);
      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };
  const { get: getStorage } = Storage;
  const updateFileState = async (uploadResponse, name, idDocument, e) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};
    const { iscorrected } = await e.target.dataset;

    const file = {
      [e.target.id]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file, false, iscorrected);

    return localStorage.removeItem("uploadData");
  };

  const updateNameFilesState = (name) => {
    setNameFiles((prevNameFiles) => [...prevNameFiles, { name }]);
  };

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormOutput || ShowOutput}`) ||
      "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${FormOutput || ShowOutput}`,
        JSON.stringify(filteredErrors)
      );
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (FormOutput) {
      // viewForms();
      ShowDataCorrect(FormOutput);
      getItems(FormOutput);
      viewCommentary();
    } else if (ShowOutput) {
      ShowDataCorrect(ShowOutput);
      getItems(ShowOutput);
      viewCommentary();
    } else {
      setValuesHeader(valuesHeader);
    }
    return () => {
      isMounted = false;
    };
  }, [FormOutput, ShowOutput, update, orderBy]);

  // estado actual del exportado
  useEffect(() => {
    if (dataThirdDefault && valuesHeader) {
      const resultadoSearch = dataThirdDefault.find(
        (e) => e.id === thirdPartie_id
      );

      const resultadoCorrect = dataThirdDefault.find(
        (e) => e.id === thirdPartieCorrected_id
      );

      if (resultadoCorrect) {
        setselectExport({
          label: `${resultadoCorrect && resultadoCorrect.identification_num !== null
            ? resultadoCorrect?.identification_num
            : ""
            } ${resultadoCorrect?.business_name}`,
          value: resultadoCorrect?.id,
        });
        setValuesHeader({
          ...valuesHeader,
          nit_business_nameCorrected: resultadoCorrect.identification_num,
          business_nameCorrected: resultadoCorrect?.business_name,
        });
      }
      if (resultadoSearch) {
        setselectExport({
          label: `${resultadoSearch && resultadoSearch.identification_num !== null
            ? resultadoSearch?.identification_num
            : " "
            } ${resultadoSearch?.business_name}`,
          value: resultadoSearch?.id,
        });
        setStateCurrentExport(
          `${resultadoSearch?.identification_num !== null
            ? resultadoSearch?.identification_num
            : ""
          } ${resultadoSearch?.business_name}`
        );
      }
    }
  }, [dataThirdDefault]);
  // estado actual del exportado fin

  useEffect(() => {
    let isMounted = true;

    if (dataOperation) {
      const infoExport = dataOperation.map((item) => ({
        label: item?.code_operation + " " + item?.short_name_operation,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));

      setDataSelectOperation(infoExport);
    }
    return () => {
      isMounted = false;
    };
  }, [dataOperation]);

  // estado actual del operacion
  useEffect(() => {
    if (dataOperation && valuesHeader) {
      const resultadoCorrect = dataOperation.find(
        (e) => e.id === operationCorrected_id
      );

      if (resultadoCorrect) {
        setselectOperation({
          label:
            resultadoCorrect?.code_operation +
            " " +
            resultadoCorrect?.short_name_operation,
          value: resultadoCorrect?.code_operation,
          id: resultadoCorrect?.id,
          documents: resultadoCorrect?.documents,
          plazo: resultadoCorrect?.term,
        });
        setValuesHeader({
          ...valuesHeader,
          code_operationCorrected: resultadoCorrect.code_operation,
          short_name_operationCorrected: resultadoCorrect?.short_name_operation,
        });
      }
    }
  }, [dataOperation]);
  // estado actual del operacion fin

  useEffect(() => {
    if (dataToEditItems) {
      showItems(dataToEditItems.id);
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id);
      // getTrm();
    }
  }, [dataToEditItems, dataToCopyItems]);

  useEffect(() => {
    let isMounted = true;
    if (!stateCollapse) {
      getCountry();
      getPackaging();
      getMethodTransport();
      if (!dataToEditItems && !dataToCopyItems) {
        getTrm();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [stateCollapse]);

  useEffect(() => {
    if (dataAgreements) {
      const infoPackagin = dataAgreements.map((item) => ({
        label: item?.code_tradeAgreement + " " + item?.name_tradeAgreement,
        value: item?.id,
      }));

      setDataAgreementsNew(infoPackagin);
    }
  }, [dataAgreements]);

  useEffect(() => {
    if (dataPackagings) {
      const infoPackagin = dataPackagings.map((item) => ({
        label: item?.code_packaging + " " + item?.name_packaging,
        value: item?.id,
      }));
      setDataPackagingsNew(infoPackagin);
    }
  }, [dataPackagings]);

  useEffect(() => {
    if (dataTransportMethod) {
      const infoPackagin = dataTransportMethod.map((item) => ({
        label: item?.code_transportMethod + " " + item?.name_transportMethod,
        value: item?.id,
      }));

      setDataTransportMethodNew(infoPackagin);
    }
  }, [dataTransportMethod]);

  useEffect(() => {
    setSuma(
      (
        parseFloat(us_freight) +
        parseFloat(us_insurance) +
        parseFloat(other_us_expenses) +
        parseFloat(us_fob_value)
      ).toFixed(2)
    );
    setSumaCorrect(
      (
        parseFloat(us_freight_corrected) +
        parseFloat(us_insurance_corrected) +
        parseFloat(other_us_expenses_corrected) +
        parseFloat(us_fob_value_corrected)
      ).toFixed(2)
    );

    // console.log(trm);
    setMultiplicacion(suma * parseFloat(exchange_rate));
    setMultiplicacionCorrect(sumaCorrect * parseFloat(exchange_rate_corrected));
  }, [valuesItem, suma, multiplicacion, sumaCorrect, multiplicacionCorrect]);

  useEffect(() => {
    const resultadoProduc = parseFloat(product_quantity_corrected);
    const resultadoMultiplicacion =
      parseFloat(factor_conv_corrected) * resultadoProduc;
    const newDato = parseFloat(resultadoMultiplicacion).toFixed(2);

    // ?.replaceAll(".", ",")
    if (code_businessUnit_correct === "KG") {
      setValuesItem({
        ...valuesItem,
        quantity_corrected: newDato,
        net_weight_corrected: newDato,
        gross_weight_corrected: newDato,
      });
    } else if (code_businessUnit_correct !== "KG") {
      setValuesItem({
        ...valuesItem,
        quantity_corrected: newDato,
      });
    }
  }, [
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_correct,
  ]);

  useEffect(() => {
    if (valueProducts) {
      const respuesta = allProducts.find(
        (element) => element.id === parseInt(valueProducts.value)
      );
      setValueSubheading(respuesta?.subheading_id);
      setValuesItem({
        ...valuesItem,
        factor_conv_corrected: respuesta?.convertion_factor_product,
        product_unit_Correct: respuesta?.code_unit_measurement,
        product_description_corrected: respuesta?.description_product,
        description_subheading_corrected: respuesta?.description,
        code_subheading_corrected: respuesta?.code_subheading,
        code_businessUnit_correct: respuesta?.businnesUnitSubheading,
        code_businessUnit: respuesta?.businnesUnitSubheading,
      });
    }
  }, [valueProducts]);

  useEffect(() => {
    if (FormOutput || ShowOutput) viewCommentary();
  }, [newCommentary]);

  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async () => {
    try {
      const res = await __CommentaryForm(
        token,
        FormOutput ? Number(FormOutput) : ShowOutput ? Number(ShowOutput) : "",
        environment.CORRECT_GET_COMMENT_FMM_0UTPUT
      );
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) { }
  };

  const handleChange = (id) => {
    const infoEntryCorrect = {
      id,
      status_outputFormCorrected: "AUTORIZADO",
    };

    changeStatusCorregido(infoEntryCorrect);
  };

  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const submitReviewBasedOnStatus = async () => async () => {
    if (is_qualified_user) return;

    if (status_outputFormCorrected === "PRESENTADO") {
      const body = new FormData();
      body.append("form_id", ShowOutput);
      body.append("type_of", "FMMSC");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [status_outputFormCorrected]);

  const propsBlockForm = {
    form_id: ShowOutput,
    type_of: "FMMSC",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };

  const unlockForm = async () => {
    const data = new FormData();
    data.append("form_id", ShowOutput);
    data.append("type_of", "FMMSC");
    data.append("type", 0);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, data);
  };

  //Continuar aqui
  const navigateIfEligibleForUpdate = (flag, form_id, new_status, formData) => {
    const shouldRefetch =
      flag === "output_form_corrected" &&
      new_status !== formData?.status_outputFormCorrected &&
      form_id === formData?.id;

    if (
      shouldRefetch &&
      new_status !== "REVISADO" &&
      new_status !== "PRESENTADO"
    ) {
      navigate(
        `/FormCorrectionOutput?ShowOutput=${form_id || FormOutput || ShowOutput
        }`
      );
      return;
    }
  };

  useStatusListener({
    id: FormOutput || ShowOutput,
    dataCurrent: valuesHeader,
    callBack: navigateIfEligibleForUpdate,
  });

  const editUploadFiles = (data) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput);
    if (data?.id && !data?.doc_corrected_req)
      formData.append("attachment_id[]", data.id);

    data?.doc_corrected_req &&
      data?.id &&
      formData.append("document_corrected_id[]", data.id);

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append("attachment_comment[]", data.attachment_comment);
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append("attachment_number[]", data.attachment_number);
    }

    const date = data?.other_attachment_date || data?.attachment_date;

    data?.attachment_date && formData.append("attachment_date[]", date);


    updateAndCreate(formData, true);
  };

  const removeFileByIdentifier = (
    id,
    fileNameOrID,
    dataDocument = {},
    typeComponent
  ) => {
    const IsTypeNewFile =
      dataDocument?.doc_corrected_req === 1 ? "/doc_corrected_req" : "";
    const Endpoint = environment.DELETE_ATTACHMENT_OUTPUT_CORRECT;
    const requestData = {
      resourceIdentifier: `${dataDocument?.id}${IsTypeNewFile}`,
      Endpoint,
    };

    mutate(requestData, {
      onSuccess: (res) => responseDeleteFile(res, id),
    });
  };

  function removeElementFromArray(array, posicion) {
    return [...array.slice(0, posicion), ...array.slice(posicion + 1)];
  }

  const responseDeleteFile = (res, index = null) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, "error");
    }
    if (res?.data?.status?.code === 200) {
      if (isString(index)) {
        const { [index]: _, ...rest } = files;
        setFiles(rest);

        setDataCorrected((prev) => ({
          ...prev,
          files: rest,
        }));
      }

      if (isNumber(index)) {
        const newArray = removeElementFromArray(otherDocuments, index);
        setOtherDocuments(newArray);
      }

      return openAlert("El documento ha sido eliminado ", "error");
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={"lg"}
      >
        <GroupInputUpload onClose={onCloseUpload} />
      </ModalGeneric>

      <Commentary
        id={
          FormOutput ? Number(FormOutput) : ShowOutput ? Number(ShowOutput) : ""
        }
        ruta={environment.CORRECT_CREATE_COMMENT_FMM_ENTRY}
        create={createCommentary}
        commentarys={commentarios}
      />
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar item n. ${dataToDelete?.item_corrected}`}
        onDelete={deleteItemsCorrect}
      />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          FormId={valuesHeader.id}
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenRadicar}
        onOpen={onOpenRadicar}
        onClose={onCloseRadicar}
        title={`Radicar documento`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea radicar el formulario{" "}
          {dataRadicar?.code_entryFormCorrected ||
            dataRadicar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              Se realizarán cambios en el inventario
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRadicar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => {
              handleChange(dataRadicar?.id);
              unlockForm();
            }}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      {valuesHeader && (
        <ExportPdfOutputCorrect
          id="IdPdfFmmCorrectEntry"
          values={valuesHeader}
          items={currentPage}
        />
      )}

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div className="container mb-5">
            <HeaderForm
              status={status_outputFormCorrected}
              numberForm={code_outputFormCorrected}
              UserQualified={name_qualified}
              Modulo={`${"Formulario"} de corrección de salidas de mercancías en Zonas Francas`}
            />
          </div>
          {ShowOutput && (
            <ButtonView data={valuesHeader} idFmm={FormOutput || ShowOutput}>
             <CreateAt
                entries={valuesHeader?.users}
                config={{
                  statusKey: "status_outputFormCorrected",
                  dateKey: "date_outputFormCorrected",
                  timeKey: "time_outputFormCorrected",
                  personNameKey: "person_name",
                }}
                expirationDate={valuesHeader?.expirationDate}
              />
            </ButtonView>
          )}

          <div className="row">
            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  Salida
                  <NavLink
                    to={`/FormShowExit?ExitNumber=${valuesHeader?.outputForm_id}`}
                    className="ms-2"
                  >
                    {valuesHeader?.code_outputForm}
                  </NavLink>
                </SubTitleHeader>
                <FmmOHeader data={valuesHeader} />
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  {valuesHeader.balance_correction === 1
                    ? "Ajuste"
                    : "Corrección"}
                </SubTitleHeader>
                {FormOutput && (
                  <FmmOheadercorrected
                    handleChange={handleInputChangeHeader}
                    data={valuesHeader}
                  />
                )}
                {ShowOutput && <FmmOheadercorrectedShow data={valuesHeader} />}
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperationOutput code={code} />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  <CapsulaContainerOutput
                    code={code}
                    valuesHeader={valuesHeader}
                  />
                </div>
              </HeaderSearch>
            </div>

            {/**/}
            <div className="col-md-6">
              <HeaderSearch style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperationOutput
                    code={
                      valuesHeader.balance_correction === 1
                        ? code
                        : selectOperation?.value
                    }
                  />
                </div>
                <div className="p-1" style={{ background: "#f2f2f2" }}>
                  {valuesHeader.balance_correction !== 1 && (
                    <>
                      <span>
                        <Text as="sub">* Opcional</Text>
                        <br />
                      </span>
                      <RadioCheck
                        set={setOpcional}
                        value={opcional}
                        item={dataDUTA}
                        disables={ShowOutput}
                        diff={opcionalCurrent !== opcional}
                      />
                    </>
                  )}
                  {selectOperation?.value === 50 ? (
                    <NationalTerritoryCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 201 &&
                    selectOperation?.value <= 226 ? (
                    <RestOfTheWorldCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 401 &&
                    selectOperation?.value <= 450 ? (
                    <NationalTerritoryCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 601 &&
                    selectOperation?.value <= 618 ? (
                    <RestOfTheWorldCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      defaultDisabless={ShowOutput}
                    />
                  ) : selectOperation?.value >= 801 &&
                    selectOperation?.value <= 814 ? (
                    <UserToUserCorrect
                      data={valuesHeader}
                      handleChange={handleInputChangeHeader}
                      StateDisble={ShowOutput}
                    // dataCorrect={valuesCorrect}
                    />
                  ) : valuesHeader.balance_correction === 1 ? (
                    <CapsulaContainerOutput
                      code={code}
                      valuesHeader={valuesHeader}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </HeaderSearch>
            </div>
            {/**/}

            <div className="col-4">
              <Text className="mt-4" fontSize="xl" style={{ fontWeight: 600 }}>
                Ítems
              </Text>
            </div>
            <div className="col-4"></div>

            <div className="col-4">
              <div className="d-flex float-end my-4">
                {!ShowOutput && (
                  <BtnWithToltip
                    msgToltip="Agregar ítem"
                    hableSubmit={() => setstateCollapse(!stateCollapse)}
                    icon={CgPlayListAdd}
                  />
                )}
              </div>
            </div>

            {!stateCollapse && (
              <div className="col-6">
                {loadingItems && <SpinnerComponent />}
                {!loadingItems && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FmmItemOriginal
                      data={valuesItem}
                      suma={suma}
                      multiplicacion={multiplicacion}
                      sumaCorregida={sumaCorrect}
                      multiplicacionCorregida={multiplicacionCorrect}
                    />
                  </div>
                )}
              </div>
            )}
            {!stateCollapse && (
              <div className="col-6">
                {loadingItems && <SpinnerComponent />}
                {!loadingItems && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FmmItemCorregido
                      data={valuesItem}
                      suma={sumaCorrect}
                      multiplicacion={multiplicacionCorrect}
                      handleInputChange={handleInputChangeItem}
                      valuesItem={valuesItem}
                      setValuesItem={setValuesItem}
                      sumaOriginal={suma}
                      multiplicacionOriginal={multiplicacion}
                      hadleSaveItems={hadleSaveItems}
                      handleCancel={handleCancel}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="col-12 ">
              <label
                style={{
                  width: 300
                }}
              >
                <label style={{ width: '100%' }}></label>

                <InputSelect
                  data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                  value={orderBy}
                  name="order_by"
                  set={setOrderBy}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: '#5f5f',
                  }}
                  isClearable={false}
                />
              </label>
              <div className="mt-5">
                <TableOrder
                  thead={!ShowOutput ? TitleItemFmm : TitleItemFmmShow}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={
                    !ShowOutput ? TBodyItemCorrect : TBodyItemCorrectShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-12">
              <HeaderSearch>
                {DocumentOperation.map((document, index) => (
                  <div key={index}>
                    <DocumentUploader
                      document={document}
                      handleFiles={handleFiles}
                      handleFilesInput={handleFilesInput}
                      handleDelete={removeFileByIdentifier}
                      viewBtn={true}
                      files={files}
                      setNameFiles={setNameFiles}
                      nameFiles={nameFiles}
                      index={index}
                      idFmm={FormOutput || ShowOutput}
                      documentPreview={false}
                      updateAndCreate={updateAndCreate}
                      callBack={editUploadFiles}
                    />
                  </div>
                ))}
              </HeaderSearch>
              <HeaderSearch>
                <OtherAttchment
                  otherDocuments={otherDocuments}
                  titulo={"Otros documentos"}
                  attachment={attachment}
                  setAttachment={setAttachment}
                  attachmentType={attachmentType}
                  setAttachmentType={setAttachmentType}
                  ruta={environment.DELETE_OTHER_ATTACHMENT_OUTPUT_CORRECT}
                  // removeFileAttachment={removeFileAttachment}
                  viewBtn={true}
                  update={updateAndCreate}
                  isLoadingUpdate={isLoadingUpdate}
                />
              </HeaderSearch>
            </div>

            {!ShowOutput && (
              <Box
                display="flex"
                alignItems="center"
                marginTop={"20px"}
                marginBottom={"20px"}
                justifyContent="flex-end"
                flexWrap={"wrap"}
              >
                <ButtonStyled type="submit" onClick={handleSubmit}>
                  Guardar
                </ButtonStyled>
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
};
