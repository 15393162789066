import { Flex, Spacer } from "@chakra-ui/react";
import { InputSelectZindex } from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormSearchAdvanced = ({ onClose, handleSearchSubmit }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["nickname", "type_doc"], nestedProp: "searchAdvance" });

  const [values, handleInputChange] = useForm({
    nickname: state?.nickname
  });

  const { nickname } = values;
  const [selectType, setselectType] = useState(state?.type_doc);

  const dataType = [
    { value: "Formulario de entrada", label: "Formulario de entrada" },
    { value: "Formulario de despacho", label: "Formulario de despacho" },
    { value: "Formulario de salida", label: "Formulario de salida" },
    { value: "Formulario de ingreso", label: "Formulario de ingreso" },
    {
      value: "Formulario de desaduanamiento",
      label: "Formulario de desaduanamiento"
    },
    {
      value: "Formulario de orden de producción",
      label: "Formulario de orden de producción"
    }
  ];

  const handleSubmit = () => {
    const info = {
      ...values,
      ...(selectType && { type_doc: selectType }),
    };
    setParamsSearch({
      params: {
        ...handleSanitizedObj(info),
      }
    })
    handleSearchSubmit(info);
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"nickname"}
          value={nickname}
          onChange={handleInputChange}
          tag={"Usuario"}
          size={100}
          tipo={"text"}
        />
      </div>

      <div style={{ width: `45%` }}>
        <InputSelectZindex
          name={"date_by"}
          tag={"Documento"}
          data={dataType}
          value={selectType}
          set={setselectType}
        />
      </div>

      <Flex className="mt-5">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
