import { Td, Tr } from "@chakra-ui/react";
import ReturnReasonContext from "context/ReturnReasonContext";
import React, { useContext } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { MdRemoveRedEye } from "react-icons/md";
import { HiOutlineInformationCircle } from "react-icons/hi";

export const Tbody = ({ data }) => {
  const { onOpenListReason, setListReturnReason, onOpenShowComentary } =
    useContext(ReturnReasonContext);

  const handleListReason = () => {
    onOpenListReason();
    setListReturnReason(data);
  };

  const handleComentary = () => {
    onOpenShowComentary();
    setListReturnReason(data);
  };

  return (
    <Tr>
      {/* <Td className="text-center">{data?.code_uid}</Td> */}
      <Td className="text-center">{data?.name_freeZone}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.type_doc}</Td>
      <Td className="text-center">{data?.code_form}</Td>
      <Td className="text-center">{data?.nickname}</Td>
      <Td className="text-center">
        {`${data?.date_return}, ${data?.time_return} `}
      </Td>

      <Td className="text-center">
        {data?.typifications && data?.typifications.length > 3
          ? (
            <IconGeneric onClick={handleListReason} as={MdRemoveRedEye} />
          )
          : (
            data?.typifications &&
          data?.typifications.map((item, index) => (
            <span key={index}>{item.name}. </span>
          ))
          )}
      </Td>
      <Td className="text-center">
        <IconGeneric
          onClick={handleComentary}
          as={HiOutlineInformationCircle}
        />
      </Td>
    </Tr>
  );
};
