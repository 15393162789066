import { Td, Tr } from "@chakra-ui/react";

const TBodySubheadingTrackings = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.code_subheading ? data?.code_subheading : "N/A"}</Td>
        <Td className="text-center">{data?.subheading_description ? data?.subheading_description : "N/A"}</Td>
        <Td className="text-center">{data?.subchapter ? data?.subchapter : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodySubheadingTrackings;
