/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import environment from "constants/apiConst";
import { NavLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import FormIdOrdeProduction from "pages/IntegrationBox/Form/FormIdOrdeProduction";
import { ButtonsBox } from "../ButtonsBox/ButtonsBox,";
import { StausBox } from "../StatusBox/StatusBox";
import { TableInfo } from "../TableInfo/TableInfo";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { ButtonStyled } from "styled/Buttons.styled";
import { Collapse } from "@chakra-ui/react";
import FormCorrectedProduct from "pages/IntegrationBox/Form/FormCorrectedProduct";
import FormCorrected from "pages/IntegrationBox/Form/FormCorrected";
import ViewShowCorrected from "pages/IntegrationBox/CorrectionCertificate/ViewShowCorrected";
import ExporCertificated from "pages/IntegrationBox/Export/ExportCertificado/ExporCertificated";
import "../../Export/StyleIntegration.css";
import FormCorrectedSupplies from "pages/IntegrationBox/Form/FormCorrectedSupplies";
import FormCorrectedOther from "pages/IntegrationBox/Form/FormCorrectedOther";
import Modals from "../Modals";
import Descriptions from "../Descriptions/Index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import GlobalContext from "context/GlobalContext";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import useStatusListener from "hooks/useStatusListener";
import queryEnv from "constants/queryConst";
import { useDispatch } from "react-redux";
import { updateFormSingle } from "redux/actions/useFormAction";
import { Title } from "styled/Title";
import { H2 } from "pages/Pass-Form/stylePases";
import { parseFloat } from "utils/parseFloat";
import { useSelector } from "react-redux";
import { AuthContext } from "context/AuthContext";
import { isString } from "utils/type-check-utils";

const ShowIntegrationBox = () => {
  const [lock, setLock] = useState([]);
  const [forms, setForms] = useState("");
  const [dataCorrected, setDataCorrected] = useState("");
  const [log, setLog] = useState("");
  const [logCorrected, setLogCorrected] = useState([]);
  const [dataOld, setDataOld] = useState("");
  const [statusShow, setStatusShow] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [openAlert] = AlertErrorAlert();
  const { requestData, setPrecisionManual } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: requestData,
  });
  const { authUser } = useContext(AuthContext);

  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const dispacthRedux = useDispatch();
  const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const {
    onOpenLog,
    isOpen,
    showProduct,
    showSupplies,
    showViewCorrectCost,
    form,
    setForm,
    update,
  } = useContext(IntegracionBoxCrudContex);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: "OrdenProduccionResponse",
        exact: true,
      });

      setLock(null);
      setForms(null);
      setForm(null);
      setLog(null);
      setLogCorrected(null);
      setDataOld(null);
      setStatusShow(null);
    };
  }, [id, idCorrected]);
  const StatusCurrentCI = form?.status || forms?.newData?.status;

  const handleIntegrationResponse = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      const dataWithoutCorrection = res?.data?.status?.data;
      const dataOld =
        res?.data?.status?.data?.oldData?.data?.integration_certificate_id_old;
      const dataWithCorrection = res?.data?.status?.data?.newData?.data;
      const dataWithCorrectionLog = res?.data?.status?.data?.newData;
      setPrecisionManual(
        (authUser.admin === 1 || authUser.is_commerce === 1) &&
          isString(res?.data?.status?.data?.precision, true)
          ? parseFloat(res?.data?.status?.data?.precision)
          : Number(decimal_values_precision)
      );

      if (dataWithCorrection) {
        setForm(dataWithCorrection);

        dispacthRedux(
          updateFormSingle(
            "ciModuleDynamic",
            ["nameModul"],
            dataWithCorrection?.integration_certificate_name ||
            "Certificado de Integración"
          )
        );

        setLog(dataWithCorrectionLog);
        setDataOld(dataOld);
        setStatusShow(res?.data?.status?.data?.newData[0]);
      } else {
        setLog(dataWithoutCorrection);
        setForm(dataWithoutCorrection);

        dispacthRedux(
          updateFormSingle(
            "ciModuleDynamic",
            ["nameModul"],
            dataWithoutCorrection?.integration_certificate_name ||
            "Certificado de Integración"
          )
        );
        setLock(res?.data?.status?.data.log);
      }
      queryClient.setQueryData(
        [queryEnv?.INTEGRATION_BOX],
        res?.data?.status?.data
      );
    }
  };

  const fetchIntegrationData = async (showId) => {
    const formData = new FormData();
    formData.append("id", showId);
    setLoading(true);

    const Config = {
      data: formData,
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(Config, {
      onSuccess: (data) => handleIntegrationResponse(data),
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  const handleResponseCorrectedShow = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }
    if (res?.data?.status?.code === 200) {
      const dataWithoutCorrection = res?.data?.status?.data;
      const dataCurrentCorrected = res?.data?.status?.data?.newData;
      const log = res?.data?.status?.data?.newData?.log;

      setForms(dataWithoutCorrection);
      setDataCorrected(dataCurrentCorrected);
      setLogCorrected(log);
    }

    queryClient.setQueryData(
      [queryEnv?.INTEGRATION_BOX],
      res?.data?.status?.data
    );
    setLoading(false);
  };

  const fetchCorrectedShow = async (showId) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", showId);

    const Config = {
      data: formData,
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID_CORRECTED,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(Config, {
      onSuccess: (data) => handleResponseCorrectedShow(data),
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (id) {
      fetchIntegrationData(id);
      return;
    }
    if (idCorrected) {
      fetchCorrectedShow(idCorrected);
    }
  }, [idCorrected, id, update]);

  const fetchDataFormProduct = queryClient.getQueryData([
    "OrdenProduccionResponse",
  ]);

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      (flag === "ci" || flag === "cic") &&
      new_status !== dataCurrent.status &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.integration_certificate_id;

    if (shouldRefetch) {
      if (id) {
        fetchIntegrationData(id);
        return;
      }

      if (idCorrected) {
        fetchCorrectedShow(idCorrected);
        return;
      }
    }
  };

  useStatusListener({
    id: id || idCorrected,
    callBack: refetchForm,
    dataCurrent: form || forms?.newData?.data,
  });

  return (
    <>
      <Modals
        lock={lock}
        setLock={setLock}
        loading={loading}
        urlCopy={environment.COPY_BOX}
      />
      <div className="container mt-5 ">
        {loading && <SpinnerComponent />}
        {!loading && (
          <div>
            <div className="box">
              <HeaderForm
                status={StatusCurrentCI}
                numberForm={
                  forms
                    ? forms?.newData?.data?.integration_certificate_code
                    : form?.integration_certificate_code
                }
                UserQualified={
                  forms
                    ? forms?.newData?.data?.name_qualified
                    : form?.name_qualified
                }
                Modulo={`${StatusCurrentCI === "DEVUELTO" ? "Edición de" : ""
                  } ${form?.integration_certificate_name ||
                  "Certificado de Integración"
                  } En Zonas Franca`}
              />

              <ButtonsBox values={form} valuesCorrected={dataCorrected}>
                {fetchDataFormProduct?.reason_for_return &&
                  fetchDataFormProduct?.integration_certificate_status ===
                  "DEVUELTO" && (
                    <AlertReturnReason
                      title={"Tipo de devolución:"}
                      item={fetchDataFormProduct?.typifications}
                      description={
                        fetchDataFormProduct &&
                        fetchDataFormProduct?.reason_for_return
                      }
                      nickname={fetchDataFormProduct?.nickname}
                    />
                  )}
                <StausBox
                  values={form}
                  log={log}
                  statusShow={statusShow}
                  logCorrected={logCorrected}
                />

                <Descriptions values={form} formCorrected={forms} />

                {forms?.oldData?.data &&
                  <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                    <section style={{ display: 'flex', gap: '8px' }}>
                      <H2 className="fw-bold" style={{whiteSpace: 'nowrap'}}>Cert. de Integración:</H2>
                      <NavLink to={`/ShowIntegrationBox?id=${forms?.oldData?.data?.integration_certificate_id_old}`}> 
                        <span className="formNumber">{forms?.oldData?.data?.integration_certificate_code_old}</span>
                      </NavLink>
                    </section>
                  </div>}

              </ButtonsBox>
            </div>

            {idCorrected && idCorrected !== "" && (
              <ViewShowCorrected form={forms} />
            )}

            {id && id !== "" && <TableInfo />}

            <Collapse in={isOpen}>
              <FormCorrected />
            </Collapse>

            {showProduct && <FormCorrectedProduct dataOld={dataOld} />}
            {showSupplies && <FormCorrectedSupplies />}

            {showViewCorrectCost && <FormCorrectedOther />}
            <div className="mb-5 mt-5 ">
              {/* Aqui estan los modales */}
              <FormIdOrdeProduction Status={StatusCurrentCI} />
            </div>
            {/* <div>
              <ButtonStyled
                hg={"30px"}
                wd={"180px"}
                onClick={onOpenLog}
                style={{ marginBottom: "100px" }}
              >
                Ver log
              </ButtonStyled>
            </div> */}
          </div>
        )}

      </div>
    </>
  );
};

export default ShowIntegrationBox;
