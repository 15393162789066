import { Box, Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FuncionLetraNumberOnly } from "components/Funciones/FuncionLetraNumberOnly";
import { AuthContext } from "context/AuthContext";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { ButtonStyled } from "styled/Buttons.styled";

export const FormPass = ({
  values,
  handleInputChange,
  tabIndex,
  setTabIndex,
  onClose,
  children,
  id,
  setValues,
  setCloseWeighing,
  CloseWeighing,
  setOwnMeans,
  ownMeans,
  ButtonActive = true,
  CloseWeighingStatus = false
}) => {
  const [openAlert] = AlertErrorAlert();

  const { authUser } = useContext(AuthContext);
  const {
    vehicle_plate,
    identification,
    driver,
    name_typeTransport,
    name_qualified,
    passForm_number,
    freely_available,
    // own_means,
    empty_pass,
    handling_process
  } = values;

  const validatPlate = () => {
    if (id === "" || id === null || id === undefined) {
      openAlert("Ingrese  un vehículo", "error");
    } else {
      setTabIndex(tabIndex + 1);
    }
  };
  // /^[0-9a-zA-Z]+$/

  return (
    <>
      <SwitchPropiosMedios
        status={ownMeans}
        setStatus={setOwnMeans}
        tag={"Propios medios"}
        desicion={!!((vehicle_plate !== "" && ownMeans !== 1))}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"vehicle_plate"}
          value={vehicle_plate ? vehicle_plate.toUpperCase() : vehicle_plate}
          tag={"Placa"}
          size={45}
          desicion={!!((tabIndex === 2 || (tabIndex === 0 && ownMeans)))}
          maxLength={10}
          onChange={(e) => FuncionLetraNumberOnly(e, setValues, values)}
        />
        <LabelGroup
          name={"identification"}
          value={identification || ""}
          onChange={handleInputChange}
          tag={"Cédula conductor"}
          size={45}
          desicion={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"driver"}
          value={driver}
          onChange={handleInputChange}
          tag={"Conductor"}
          size={45}
          desicion={true}
        />
        <LabelGroup
          name={"name_typeTransport"}
          value={name_typeTransport || ""}
          onChange={handleInputChange}
          tag={"Tipo de transporte"}
          size={45}
          desicion={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <LabelGroup
          name={"name_qualified"}
          value={name_qualified}
          onChange={handleInputChange}
          tag={"Usuario calificado"}
          size={45}
          desicion={true}
        />
        <LabelGroup
          name={"passForm_number"}
          value={passForm_number || ""}
          onChange={handleInputChange}
          tag={"Número de pase"}
          size={45}
          desicion={true}
        />
      </div>

      <Box className="d-flex justify-content-start mt-4 p-1 align-items-center">
        {CloseWeighingStatus
          ? (
            <SwitchPropiosMedios
              status={CloseWeighing}
              setStatus={setCloseWeighing}
              tag={"No cerrar Pesaje"}
              desicion={false}
            />
          )
          : (
            <></>
          )}

        {ButtonActive ? (
          <>
            {(authUser.admin === 1 || authUser.is_commerce === 1) && (
              <SwitchPropiosMedios
                status={handling_process}
                // setStatus={setStatus}
                tag={"Manipuleo"}
                desicion={true}
              />
            )}

            <SwitchPropiosMedios
              status={freely_available}
              // setStatus={setStatus}
              tag={"Libre disposición"}
              desicion={true}
            />

            <SwitchPropiosMedios
              status={empty_pass}
              // setStatus={setManipuleo}
              tag={"Vehículo vacío"}
              desicion={true}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
      {children}
      {tabIndex !== 2 && (
        <Flex>
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled bgColor={"#3182CE"} onClick={validatPlate}>
            Siguiente
          </ButtonStyled>
        </Flex>
      )}
    </>
  );
};
