import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react";

export const AlertSimple = ({ type, message, title }) => {
  return (
    <Alert status={type}>
      <AlertIcon />
      {title ? <AlertTitle>{title}</AlertTitle> : <></>}
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};
