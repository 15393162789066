import {
  BiCoinStack,
  BiBox,
  BiSelectMultiple,
  BiTask,
  BiMoney,
  BiArrowFromRight,
  BiArrowToRight,
  BiCog,
  BiArrowToLeft,
  BiExport,
} from "react-icons/bi";
import {
  TbFileArrowLeft,
  TbFileArrowRight,
  TbRobot,
  TbReceiptTax,
} from "react-icons/tb";
import { FaWeight, FaBoxOpen, FaUserPlus } from "react-icons/fa";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { GiFiles, GiRadarSweep } from "react-icons/gi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { AiFillSecurityScan } from "react-icons/ai";
import { CgTemplate } from "react-icons/cg";
import { RiTimerFlashLine, RiMoneyDollarCircleFill } from "react-icons/ri";
import ImageDian from "../../assets/dashboard-icons/Dian.png";
import Imagetreasury from "../../assets/dashboard-icons/treasury.png";
import { ImStatsBars } from "react-icons/im";
import inventoryIcon from "../../assets/dashboard-icons/inventory.png";
import { VscLayoutPanelJustify, VscPreview, VscOutput } from "react-icons/vsc";

import { SvgComponent } from "../../assets/IconsExportSvg";
import { BsFillClipboard2MinusFill } from "react-icons/bs";

export const ListItems = [
  {
    name: "Actividad",
    path: "/config/type-user",
    src: BiCog,
    nameAccess: [
      "getActivitiesNotPaginate",
      "getActivities",
      "createActivity",
      "showActivity",
      "updateActivity",
      "deleteActivity",
      "activeActivity",
      "desactiveActivity",
    ],
  },
  {
    name: "Acuerdos Comerciales",
    path: "/config/trade-agreement",
    src: BiCog,
    nameAccess: [
      "getTradeAgreementsNotPaginate",
      "getTradeAgreements",
      "createTradeAgreement",
      "updateTradeAgreement",
      "showTradeAgreement",
      "deleteTradeAgreement",
      "importTradeAgreements",
      "activateTradeAgreements",
      "desactivateTradeAgreements",
    ],
  },
  {
    name: "Aduanas",
    path: "/config/customs",
    src: BiCog,
    nameAccess: [
      "getCustomHouses",
      "showCustomHouse",
      "createCustomHouse",
      "updateCustomHouse",
      "deleteCustomHouse",
      "importCustomHouse",
      "activateCustomHouse",
      "desactivateCustomHouse",
    ],
  },
  {
    name: "Cuentas de usuario",
    path: "/config/countUser",
    src: BiCog,
    nameAccess: [
      "getOperatorUsers",
      "changePasswordUser",
      "desactiveUser",
      "activeUser",
      "activateUserQualifiedUser",
      "validateUser",
      "updateUser",
      "createUser",
      "showUser",
      "getUser",
    ],
  },
  {
    name: "Ciudades",
    path: "/config/cities",
    src: BiCog,
    nameAccess: [
      "getCitiesNotPaginate",
      "getCities",
      "createCity",
      "updateCity",
      "showCity",
      "deleteCity",
      "activateCity",
      "desactivateCity",
    ],
  },
  {
    name: "Control de subpartidas",
    path: "/config/subheadingControl",
    src: BiCog,
    nameAccess: [
      "getControlSubheadings",
      "createControlSubheading",
      "updateControlSubheading",
      "showControlSubheading",
      "deleteControlSubheading",
    ],
  },
  {
    name: "Control de zonas francas",
    path: "/config/zoneControl",
    src: BiCog,
    nameAccess: [
      "desactiveFreeZone",
      "activeFreeZone",
      "deleteFreeZone",
      "showFreeZone",
      "updateFreeZone",
      "createFreeZone",
      "getFreeZones",
      "getFreeZonesNotPaginate",
    ],
  },

  {
    name: "Departamentos",
    path: "/config/departments",
    src: BiCog,
    nameAccess: [
      "getDepartmentsNotPaginate",
      "getDepartments",
      "createDepartment",
      "updateDepartment",
      "showDepartment",
      "deleteDepartment",
      "activateDepartment",
      "desactivateDepartment",
    ],
  },
  {
    name: "Documentos requeridos",
    path: "/config/DocumentRequired",
    src: BiCog,
    nameAccess: [
      "getDocumentsNotPaginate",
      "getDocumentOperation",
      "getDocuments",
      "createDocument",
      "updateDocument",
      "showDocument",
      "deleteDocument",
      "activeDocument",
      "desactiveDocument",
      "asignedDocumentOperation",
      "desasignedDocumentOperation",
    ],
  },
  {
    name: "Destino",
    path: "/config/destiny",
    src: BiCog,
    nameAccess: [
      "getDestination",
      "getDestinationNotPaginate",
      "createDestination",
      "updateDestination",
      "deleteDestination",
      "activeDestination",
      "desactiveDestination",
    ],
  },

  {
    name: "Dependencias",
    path: "/config/dependency",
    src: BiCog,
    nameAccess: [
      "getDependenciesNotPaginate",
      "getDependencies",
      "createDependencies",
      "showDependency",
      "deleteDependency",
      "updateDependency",
      "activeDependency",
      "desactiveDependency",
    ],
  },
  {
    name: "Embalaje",
    path: "/config/packaging",
    src: BiCog,
    nameAccess: [
      "getPackagingNotPaginate",
      "getPackaging",
      "createPackaging",
      "updatePackaging",
      "showPackaging",
      "deletePackaging",
      "activatePackaging",
      "desactivatePackaging",
    ],
  },
  {
    name: "Grupo",
    path: "/config/group",
    src: BiCog,
    nameAccess: [
      "getGroupPermissionsNotPaginate",
      "getGroupPermissions",
      "createGroupPermission",
      "updateGroupPermission",
      "showGroupPermission",
      "deleteGroupPermission",
      "activeGroupPermission",
      "desactiveGroupPermission",
    ],
  },
  {
    name: "Licencia",
    path: "/config/licenceRule",
    src: BiCog,
    nameAccess: [
      "getLicensesQualifiedUserNotPaginate",
      "getLicenseQualifiedUser",
      "getLicenseNotPaginate",
      "getLicense",
      "createLicense",
      "updateLicense",
      "showLicense",
      "activateLicense",
      "desactivateLicense",
      "queryQualifiedUser",
    ],
  },
  {
    name: "Métodos de Transporte",
    path: "/config/transport-method",
    src: BiCog,
    nameAccess: [
      "getTransportMethodNotPaginate",
      "getTransportMethod",
      "createTransportMethod",
      "updateTransportMethod",
      "showTransportMethod",
      "deleteTransportMethod",
      "deleteOperation",
      "activeTransportMethod",
      "desactiveTransportMethod",
    ],
  },
  {
    name: "Operaciones",
    path: "/config/operations",
    src: BiCog,
    nameAccess: [
      "getOperationsEntryNotPaginate",
      "getOperationsOutputNotPaginate",
      "getOperations",
      "createOperation",
      "updateOperation",
      "showOperation",
      "deleteOperation",
      "activeOperation",
      "desactiveOperation",
      "uploadExcelOperation",
    ],
  },
  {
    name: "Países con A C",
    path: "/config/country-agreement",
    src: BiCog,
    nameAccess: [
      "getCountryTradeAgreement",
      "createCountryTradeAgreement",
      "updateCountryTradeAgreement",
      "deleteCountryTradeAgreement",
      "activateCountryTradeAgreements",
      "desactivateCountryTradeAgreements",
    ],
  },
  {
    name: "Puertas Vehiculares",
    path: "/config/vehicular-door",
    src: BiCog,
    nameAccess: [
      "getVehicularDoorsNotPaginateEntry",
      "getVehicularDoorsNotPaginateExit",
      "getVehicularDoors",
      "createVehicularDoor",
      "updateVehicularDoor",
      "showVehicularDoor",
      "deleteVehicularDoor",
      "activeVehicularDoor",
      "desactiveVehicularDoor",
    ],
  },
  {
    name: "Roles",
    path: "/config/roles",
    src: BiCog,
    nameAccess: [
      "getRolesNotPaginate",
      "getRoles",
      "createRol",
      "updateRol",
      "showRol",
      "deleteRol",
    ],
  },
  {
    name: "Permisos",
    path: "/config/permissionsRule",
    src: BiCog,
    nameAccess: [
      "getPermissionsGroupNotPaginate",
      "getPermissionsNotPaginate",
      "getPermissions",
      "getPermissionsAdmin",
      "createPermission",
      "updatePermission",
      "showPermission",
      "deletePermission",
      "asignedPermissionUser",
    ],
  },
  {
    name: "Países",
    path: "/config/countries",
    src: BiCog,
    nameAccess: [
      "getCountrysDepartmentsCitiesNotPaginate",
      "getCountrysNotPaginate",
      "getCountrys",
      "createCountry",
      "updateCountry",
      "showCountry",
      "deleteCountry",
      "activateCountry",
      "desactivateCountry",
      "getCountrysFreeZoneNotPaginate ",
    ],
  },
  {
    name: "Puerto",
    path: "/config/port",
    src: BiCog,
    nameAccess: [
      "createFreeZonePort",
      "getFreeZonePort",
      "showFreeZonePort",
      "getFreeZonePortNoPaginate",
      "updateFreeZonePort",
    ],
  },
  {
    name: "Subpartidas",
    path: "/config/subheading",
    src: BiCog,
    nameAccess: [
      "getSubheadingProduct",
      "getSubheadingsNotPaginate",
      "getSubheadings",
      "createSubheading",
      "updateSubheading",
      "showSubheading",
      "deleteSubheading",
      "activeSubheading",
      "desactiveSubheading",
    ],
  },
  {
    name: "Sectores",
    path: "/config/sectors",
    src: BiCog,
    nameAccess: [
      "getSectorsNotPaginate",
      "getSectors",
      "createSector",
      "updateSector",
      "showSector",
      "deleteSector",
      "activeSector",
      "desactiveSector",
    ],
  },
  {
    name: "Subpartidas con A C",
    path: "/config/subheading-agreement",
    src: BiCog,
    nameAccess: [
      "getSubheadingsTradeAgreement",
      "createSubheadingsTradeAgreement",
      "updateSubheadingsTradeAgreement",
      "deleteSubheadingsTradeAgreement",
      "activateSubheadingsTradeAgreements",
      "desactivateSubheadingsTradeAgreements",
    ],
  },
  {
    name: "Tipificación motivo devolución",
    path: "/config/tipyficationReasonReturn",
    src: BiCog,
    nameAccess: [
      "getTypifications",
      "showTypification",
      "createTypification",
      "updateTypification",
      "deleteTypification",
    ],
  },
  {
    name: "Tipos de Operaciones",
    path: "/config/type-operation",
    src: BiCog,
    nameAccess: [
      "getTypeOperationsNotPaginate",
      "getTypeOperations",
      "createTypeOperation",
      "updateTypeOperation",
      "showTypeOperation",
      "deleteTypeOperation",
      "activeTypeOperation",
      "desactiveTypeOperation",
    ],
  },
  {
    name: "Tipos de Transporte",
    path: "/config/type-trans",
    src: BiCog,
    nameAccess: [
      "getTypeTransportsNotPaginate",
      "getTypeTransports",
      "createTypeTransport",
      "updateTypeTransport",
      "showTypeTransport",
      "deleteTypeTransport",
      "activeTypeTransport",
      "desactiveTypeTransport",
    ],
  },
  {
    name: "Tipos de Zona Franca",
    path: "/config/type-zfb",
    src: BiCog,
    nameAccess: [
      "getTypeFreeZonesNotPaginate",
      "getTypeFreeZones",
      "createTypeFreeZone",
      "updateTypeFreeZone",
      "showTypeFreeZone",
      "deleteTypeFreeZone",
      "activeTypeFreeZone",
      "desactiveTypeFreeZone",
    ],
  },

  {
    name: "Tasas de Cambio",
    path: "/config/tasa",
    src: BiCog,
    nameAccess: ["getTrm", "saveTrm"],
  },
  {
    name: "Tipo de Documentos",
    path: "/config/typesDocuments",
    src: BiCog,
    nameAccess: [
      "getDocumentTypes",
      "getDocumentTypesNotPaginated",
      "createDocumentTypes",
      "updateDocumentType",
      "statusDocumentType",
    ],
  },
  {
    name: "Tipo de Identificación",
    path: "/config/typesIdentifications",
    src: BiCog,
    nameAccess: [
      "getDocumentTypes",
      "getDocumentTypesNotPaginated",
      "createDocumentTypes",
      "updateDocumentType",
      "statusDocumentType",
    ],
  },
  {
    name: "Tipos de Productos",
    path: "/config/typeproducts",
    src: BiCog,
    nameAccess: [
      "getTypeProducts",
      "getTypeProductsNotPaginate",
      "createTypeProduct",
      "deleteTypeProduct",
      "showTypeProduct",
      "updateTypeProduct",
    ],
  },

  {
    name: "Tipos de tarifas",
    path: "/config/typetarif",
    src: BiCog,
    nameAccess: [
      " getRatesTypes",
      "updateRateType",
      "createRateTypes",
      "deleteRateType",
    ],
  },

  {
    name: "Usuarios Calificados",
    path: "/config/users",
    src: BiCog,
    nameAccess: [
      "getQualifiedUserNotPaginate",
      "getQualifiedUser",
      "createQualifiedUser",
      "updateQualifiedUser",
      "showQualifiedUser",
      "activateQualifiedUserAdmin",
      "desactivateQualifiedUserAdmin",
      "activateQualifiedUser",
      "desactivateQualifiedUser",
    ],
  },
  {
    name: "Unid. Comerciales",
    path: "/config/business-units",
    src: BiCog,
    nameAccess: [
      "getBusinessUnitNotPaginate",
      "getBusinessUnit",
      "createBusinessUnit",
      "updateBusinessUnit",
      "showBusinessUnit",
      "deleteBusinessUnit",
      "importBusinessUnit",
      "activeBusinessUnit",
      "desactiveBusinessUnit",
    ],
  },
];

export const MovimientosInt = [
  // permisos listo
  {
    name: "Pase de entrada",
    path: "/entry-movement",
    src: BiMoney,
    nameAccess: [
      "getEntryPasses",
      "createEntryPass",
      "updateEntryPass",
      "showEntryPass",
    ],
  },
  {
    name: "Rev. Pase de entrada",
    path: "/entry-movement-review",
    src: VscOutput,
    nameAccess: ["getPresenteEntryPasses"],
  },

  {
    name: "Pase de salida",
    path: "/output-movement",
    src: BiTask,
    nameAccess: [
      "createDispatchPass",
      "updateDispatchPass",
      "showDispatchPass",
    ],
  },
  {
    name: "Corregidos de despacho",
    path: "/corrected-pass-exit",
    src: HiOutlineDocumentSearch,
    nameAccess: ["getPresenteEntryPasses"],
  },
  {
    name: "Rev. Pase de salida",
    path: "/output-movement-review",
    src: VscOutput,
    nameAccess: ["getPresentedDispatchPasses"],
  },
];

export const certificated = [
  {
    name: "Certificado de integración",
    path: "/TabletIntegration",
    src: BiSelectMultiple,
    nameAccess: ["getIntegrationCertificate"],
  },
  {
    name: "Corregidos Certificado de integración",
    path: "/TabletIntegrationCorrected",
    src: HiOutlineDocumentSearch,
    nameAccess: ["getCorrectedIntegrationCertificate"],
  },
];

export const Tracking = [
  {
    name: "Seguimiento",
    path: "/monitoring",
    src: GiRadarSweep,
    // nameAccess: ["getIntegrationCertificate"],
  },
  {
    name: "Gestor de seguimiento",
    path: "/followOrder",
    src: FaUserPlus,
    // nameAccess: ["getCorrectedIntegrationCertificate"],
  },
];

export const RevisionInt = [
  {
    name: "Ingreso",
    path: "/review-entry",
    src: BiArrowToRight,
    nameAccess: ["getReviewEntryForms"],
  },
  {
    name: "Salida",
    path: "/review-exit",
    src: BiArrowToLeft,
    nameAccess: ["getReviewOutputForms"],
  },
  {
    name: "Aprobado de ingreso",
    path: "/review-FormEntry-Authorize",
    src: TbFileArrowRight,
    nameAccess: ["getReviewAprovedEntryForms"],
  },
  /*   {
    name: "Aprobado de salida",
    path: "/review-FormOutput-Authorize",
    src: TbFileArrowLeft,
  }, */
  {
    name: "Corrección de ingreso",
    path: "/review-FormEntry-Correcteds",
    src: MdOutlineAccountBalanceWallet,
    nameAccess: ["reviewReturnEntryFormCorrected"],
  },
  {
    name: "Corrección de salida",
    path: "/review-FormOutput-Correcteds",
    src: MdOutlineAccountBalanceWallet,
    nameAccess: ["reviewReturnOutputFormCorrected"],
  },
  {
    name: "Revisión Certificado de integración",
    path: "/review-Integration",
    src: BiSelectMultiple,
    nameAccess: ["getReviewIntegrationCertificate"],
  },
  {
    name: "Actualizar Revisión",
    path: "/Update-review",
    src: VscPreview,
    nameAccess: [
      "getReviewQueueReviewUpdate",
      "getReviewUsersReviewUpdate",
      "updateQualifiedUserAssociatedReviewUsers",
    ],
  },
  {
    name: "Modo Robot",
    path: "/Robot-mode",
    src: TbRobot,
    nameAccess: ["getReviewRobotMode"],
  },
];

export const FormularioEntry = [
  // permisos listo
  {
    name: "Ingreso",
    path: "/Form-tables-entry",
    src: BiArrowToRight,
    nameAccess: [
      "getEntryFormsNotPaginate",
      "getEntryFormSearch",
      "getEntryFormFilter",
      "getEntryForms",
      "createEntryForm",
      "updateEntryForm",
      "showEntryForm",
      "copyEntryForm",
      "correctedEntryForm",
      "changeStatusEntryForm",
      "createEntryFormQr",
      "createEntryFormPdf",
      "deleteAttachmentEntryForm",
      "deleteOtherAttachmentEntryForm",
      "getEntryFormItems",
      "createEntryFormItem",
      "updateEntryFormItem",
      "showEntryFormItems",
      "deleteItemEntryForm",
      "importEntryFormItem",
      "getEntryFormComments",
      "createEntryFormComment",
      "updateEntryFormComment",
    ],
  },

  {
    name: "Ajuste saldo de ingreso",
    path: "/Correct-Form",
    src: MdOutlineAccountBalanceWallet,
    nameAccess: [
      "getEntryFormCorrectedItemSearch",
      "deleteEntryFormCorrectedItem",
      "showEntryFormCorrectedItem",
      "updateEntryFormCorrectedItem",
      "createEntryFormCorrectedItem",
      "getEntryFormCorrectedItems",
      "getEntryFormCorrectedItemsNotPaginate",
      "getEntryFormCorrectedComments",
      "createEntryFormCorrectedComment",
      "deleteOtherAttachmentEntryFormCorrected",
      "deleteAttachmentEntryFormCorrected",
      "changeStatusEntryFormCorrected",
      "getPaymentEntryFormCorrected",
      "deleteEntryFormCorrected",
      "showEntryFormCorrected",
      "updateEntryFormCorrected",
      "createEntryFormCorrected",
      "getEntryFormCorrecteds",
      "getEntryFormCorrectedFiltro",
      "getEntryFormCorrectedSearch",
    ],
  },
  {
    name: "Corregido de ingreso",
    path: "/Correct-Form",
    src: HiOutlineDocumentSearch,
    nameAccess: [
      "getEntryFormCorrectedItemSearch",
      "deleteEntryFormCorrectedItem",
      "showEntryFormCorrectedItem",
      "updateEntryFormCorrectedItem",
      "createEntryFormCorrectedItem",
      "getEntryFormCorrectedItems",
      "getEntryFormCorrectedItemsNotPaginate",
      "getEntryFormCorrectedComments",
      "createEntryFormCorrectedComment",
      "deleteOtherAttachmentEntryFormCorrected",
      "deleteAttachmentEntryFormCorrected",
      "changeStatusEntryFormCorrected",
      "getPaymentEntryFormCorrected",
      "deleteEntryFormCorrected",
      "showEntryFormCorrected",
      "updateEntryFormCorrected",
      "createEntryFormCorrected",
      "getEntryFormCorrecteds",
      "getEntryFormCorrectedFiltro",
      "getEntryFormCorrectedSearch",
    ],
  },

  {
    name: "Salida",
    path: "/Form-tables-output",
    src: BiArrowFromRight,
    nameAccess: [
      "getOutputFormSearch",
      "getOutputFormFilter",
      "getOutputForms",
      "createOutputForm",
      "updateOutputForm",
      "showOutputForm",
      "copyOutputForm",
      "correctedOutputForm",
      "changeStatusOutputForm",
      "getAttachmentOutputForm",
      "deleteAttachmentOutputForm",
      "deleteOtherAttachmentOutputForm",
      "getOutputFormItems",
      "createOutputFormItem",
      "updateOutputFormItem",
      "showOutputFormItem",
      "deleteOutputFormItem",
      "importOutputFormItem",
      "getOutputFormComments",
      "createOutputFormComment",
      "updateOutputFormComment",
      "createOutputQr",
      "createOutputPdf",
    ],
  },

  {
    name: "Ajuste saldo de Salida",
    path: "/Correct-Form",
    src: MdOutlineAccountBalanceWallet,
    nameAccess: [
      "getOutputFormCorrectedItemSearch",
      "deleteOutputFormCorrectedItem",
      "showOutputFormCorrectedItem",
      "updateOutputFormCorrectedItem",
      "createOutputFormCorrectedItem",
      "getOutputFormCorrectedItems",
      "getPaymentOutputFormCorrected",
      "deleteOtherAttachmentOutputFormCorrected",
      "deleteAttachmentOutputFormCorrected",
      "changeStatusOutputFormCorrected",
      "showOutputFormCorrected",
      "updateOutputFormCorrected",
      "createOutputFormCorrected",
      "getOutputFormCorrecteds",
      "getOutputFormCorrectedFiltro",
      "getOutputFormCorrectedSearch",
    ],
  },
  {
    name: "Corregido de salida",
    path: "/Correct-Form-output",
    src: HiOutlineDocumentSearch,
    nameAccess: [
      "getOutputFormCorrectedItemSearch",
      "deleteOutputFormCorrectedItem",
      "showOutputFormCorrectedItem",
      "updateOutputFormCorrectedItem",
      "createOutputFormCorrectedItem",
      "getOutputFormCorrectedItems",
      "getPaymentOutputFormCorrected",
      "deleteOtherAttachmentOutputFormCorrected",
      "deleteAttachmentOutputFormCorrected",
      "changeStatusOutputFormCorrected",
      "showOutputFormCorrected",
      "updateOutputFormCorrected",
      "createOutputFormCorrected",
      "getOutputFormCorrecteds",
      "getOutputFormCorrectedFiltro",
      "getOutputFormCorrectedSearch",
    ],
  },
];

export const Formularios = [
  {
    name: "Form. Ingreso",
    src: BiArrowToRight,
    nameAccess: ["createEntryForm"],
  },
  { name: "Pase de entrada", src: BiMoney, nameAccess: ["createEntryPass"] },
  {
    name: "Form. Salida",
    src: BiArrowFromRight,
    nameAccess: ["createOutputForm"],
  },
  { name: "Pase de salida", src: BiTask, nameAccess: ["createDispatchPass"] },
  {
    name: "Certificado de integración",
    src: BiSelectMultiple,
    nameAccess: ["createIntegrationCertificateForm"],
  },
  {
    name: "Orden de producción",
    src: BiCoinStack,
    nameAccess: ["createProductOrder"],
  },
  {
    name: "Desaduanamiento",
    src: BiBox,
    nameAccess: ["createCustomsClearance"],
  },
];

export const SubmitQueuePermiss = [
  {
    name: "Cola de presentados",
    path: "/ReporteBascula",
    src: FaWeight,
    nameAccess: ["getQueueSubmitted", "saveQueueSubmitted"],
  },
];

export const Weighing = [
  {
    name: "Cola de pesaje",
    path: "/ReporteBascula",
    src: FaWeight,
    nameAccess: ["getWeighingQueue"],
  },
  {
    name: "Pesaje",
    path: "/pesaje",
    src: FaWeight,
    nameAccess: [
      "getWeighing",
      "createAssociateWeighing",
      "showPassPlate",
      "showWeighing",
    ],
  },
  {
    name: "Conf. Báscula",
    path: "/Basculas",
    src: FaWeight,
    nameAccess: [
      "getScales",
      "createScale",
      "showScale",
      "updateScale",
      "deleteScale",
    ],
  },
  {
    name: "Reporte de salida por puerta liviano",
    path: "/Reports",
    src: FaWeight,
    nameAccess: [
      "getReportLiteDispatch"
    ],
  },
  {
    name: "Conf. Usuarios Báscula",
    path: "/UsuarioBascula",
    src: FaWeight,
    nameAccess: ["getUsersWeighing"],
  },
];

export const Security = [
  {
    name: "Novedades",
    path: "/security/news",
    src: AiFillSecurityScan,
    nameAccess: null,
  },
  {
    name: "Entradas",
    path: "/security/entriesVehicles",
    src: AiFillSecurityScan,
    nameAccess: [
      "searchCodeFormEntry",
      "createSecurityEntry",
      "searchVehiclePlateEntry",
    ],
  },
  {
    name: "Salidas",
    path: "/security/exitVehicles",
    src: AiFillSecurityScan,
    nameAccess: [
      "createSecurityOutput",
      "searchCodeFormOutput",
      "searchVehiclePlateOutput",
    ],
  },
  {
    name: "Bloqueos",
    path: "/security/blockages",
    src: AiFillSecurityScan,
    nameAccess: [
      "searchPlateIdentification",
      "createVetoTruck",
      "createVetoPerson",
    ],
  },
  {
    name: "Lista de bloqueos",
    path: "/security/listBlockages",
    src: AiFillSecurityScan,
    nameAccess: [
      "getVetoDriver",
      "getVetoTrucks",
      "getVetoPersons",
      "deleteVetoTruck",
      "deleteVetoPerson",
      "deleteVetoDriver",
    ],
  },
  {
    name: "Reporte de seguridad",
    path: "/security/reportSecurity",
    src: AiFillSecurityScan,
    nameAccess: [
      "getSecurityEntryFiltro",
      "getSecurityEntryFiltro",
      "getSecurityOutputFiltro",
      "getSecurityOutputFiltro",
      "exportReportEntryDispatch",
    ],
  },
  {
    name: "Historial de bloqueos",
    path: "/security/history",
    src: AiFillSecurityScan,
    nameAccess: ["getHistoryVeto"],
  },

  {
    name: "Monitoreo",
    path: "/security/monitoring",
    src: AiFillSecurityScan,
    nameAccess: null,
  },

  {
    name: "Inconsistencias",
    path: "/security/inconsistencies",
    src: AiFillSecurityScan,
    nameAccess: null,
  },
  {
    name: "Inspecciones",
    path: "/security/inspections",
    src: AiFillSecurityScan,
    nameAccess: null,
  },
  {
    name: "Libre disposición",
    path: "/security/freeDisposal",
    src: AiFillSecurityScan,
    nameAccess: null,
  },
];

export const productList = [
  // listo permisos
  {
    name: "Productos",
    path: "/product-int",
    src: FaBoxOpen,
    nameAccess: [
      "getProductsProductiveProcess",
      "getProductsNotPaginate",
      "getProducts",
      "createProduct",
      "updateProduct",
      "showProduct",
      "deleteProduct",
    ],
  },
  {
    name: "Proceso productivo",
    path: "/product-int",
    src: CgTemplate,
    nameAccess: [
      "getProductiveProcess",
      "createProductiveProces",
      "updateProductiveProces",
      "showProductiveProces",
      "showProductiveProcesQualifiedUserId",
      // "getProductiveProcessProductsAdmin",
      // "getProductiveProcessProducts",
      // "createProductiveProcessProduct",
      // "updateProductiveProcessProduct",
      // "showProductiveProcessProduct",
      // "deleteProductiveProcessProduct",
    ],
  },
  {
    name: "Unidades de medida",
    path: "/product-int",
    src: RiTimerFlashLine,
    nameAccess: [
      "getUnitMeasurementQualifiedUserAdminNotPaginate",
      "getUnitMeasurementQualifiedUserNotPaginate",
      "getUnitMeasurementQualifiedUser",
      "showUnitMeasurementQualifiedUser",
      "createUnitMeasurementQualifiedUser",
      "updateUnitMeasurementQualifiedUser",
      "deleteUnitMeasurementQualifiedUser",
      "importUnitMeasurementQualifiedUser",
      "activeUnitMeasurementQualifiedUser",
      "desactiveUnitMeasurementQualifiedUser",
    ],
  },
];

export const DianList = [
  {
    name: "Perfilar Documentos",
    path: "/Perfilardocumento",
    image: ImageDian,
    src: TbReceiptTax,
    nameAccess: ["getApprovedDocument"],
  },
  {
    name: "Finalizar Perfilamiento",
    path: "/FinalizarPerfilamiento",
    image: ImageDian,
    src: TbReceiptTax,
    nameAccess: [
      "getApprovedDocumentwithDialType",
      "getApprovedItemDocumentwithDialType",
      "finalizeApprovedDocument",
      "finalizeApprovedItemDocumentDialType",
    ],
  },
  {
    name: "Anexo 5",
    path: "/Anexo",
    image: ImageDian,
    nameAccess: ["getLogInteroperability"],
    src: TbReceiptTax,
  },
  /*   {
    name: 'Listado Dian',
    path: '/Listadodian',
    image: ImageDian,
    src: TbReceiptTax
  }, */
  {
    name: "Planilla de envio",
    path: "/Planilladeenvio",
    image: ImageDian,
    src: TbReceiptTax,
    nameAccess: ["getShippingForms"],
  },
  {
    name: "Declaración especial de importación",
    path: "/DEI",
    image: ImageDian,
    src: GiFiles,
    nameAccess: ["exportSpecialDeclarationCriteria"],
  },
];

export const treasury = [
  // faltan la mayoria de permisos
  {
    name: "Extractos",
    path: "/Extractos",
    image: Imagetreasury,
    src: RiMoneyDollarCircleFill,
    nameAccess: ["getExtracts", "getExportExtracts"],
  },

  {
    name: "Ventas de servicios",
    path: "/VentasDeServicios",
    image: Imagetreasury,
    src: RiMoneyDollarCircleFill,
    nameAccess: [
      "getRecharges",
      "updateRecharge",
      "createRecharges",
      "statusRecharge",
      "exportExcelRecharge",
    ],
  },
  {
    name: "Pesajes",
    path: "/Pesajes",
    image: Imagetreasury,
    src: RiMoneyDollarCircleFill,
    nameAccess: ["getWeighingTreasury"],
  },
  {
    name: "Actualización de tarifas",
    path: "/ActualizacionDeTarifa",
    image: Imagetreasury,
    src: RiMoneyDollarCircleFill,
    nameAccess: ["createRates", "updateRates", "getRates"],
  },
];

export const statistics = [
  // no hay permisos aun
  {
    name: "General",
    path: "/EstadisticasGeneral",
    src: ImStatsBars,
    nameAccess: ["getStatistics", "exportExcelEstatistics"],
  },
  {
    name: "Operaciones de ingreso y salida",
    path: "/EstadisticasAdmin",
    src: ImStatsBars,
    nameAccess: [
      "statisticsOperationCountOperationItems",
      "exportExcelEstatisticsOperationEntryOutput",
    ],
  },
  {
    name: "Estadísticas de operaciones en documentos",
    path: "/EstadisticasOperacionesUsadas",
    src: ImStatsBars,
    nameAccess: [
      "statisticsOperationDocuments",
      "exportStatisticsOperationDocuments",
    ],
  },
  {
    name: "Operaciones ingreso y salida",
    path: "/EstadisticasOpeIngSal",
    src: ImStatsBars,
    nameAccess: [
      "statisticsOperationEntryOutput",
      "exportExcelEstatisticsOperationEntryOutput",
    ],
  },
  {
    name: "Seguridad",
    path: "/EstadisticasSeguridad",
    src: ImStatsBars,
    nameAccess: [
      "statisticsSecurityVeto",
      "exportExceltStatisticsSecurityVeto",
    ],
  },
  {
    name: "Estadísticas vehículo",
    path: "/EstadisticasCargoVehicle",
    src: ImStatsBars,
    nameAccess: [
      "statisticsSecurityCargoVehicles",
      "exportExcelStatisticsSecurityVehicle",
    ],
  },
  {
    name: "Estadísticas Usuarios calificados",
    path: "/EstadisticasQualifiedUser",
    src: ImStatsBars,
    nameAccess: [
      "statisticsSecurityQualifiedUsers",
      "exportExelStatisticsSecurityQualifiedUser",
    ],
  },
  {
    name: "Estadísticas movimientos",
    path: "/EstadisticasMovimiento",
    src: ImStatsBars,
    nameAccess: [
      "statisticsOperationEntryOutput",
      "exportExcelEstatisticsOperationEntryOutput",
    ],
  },
  {
    name: "Estadística de cese de actividades",
    path: "/EstadisticasCese",
    src: ImStatsBars,
    nameAccess: [
      "statisticsCessationActivities",
      "exportExcelStatisticsCessationDays",
    ],
  },
  {
    name: "Estadísticas de Operaciones",
    path: "/EstadisticasOperaciones",
    src: ImStatsBars,
    nameAccess: ["getStatistics", "exportExcelEstatistics"],
  },
  /*   {
    name: 'Exportar Documentos',
    path: '/DocuExportPro',
    src: BiExport
  } */

  /*
   nameAccess: [
      "getStatistics",
      "getStatisticsOperationCountOperationItems",
      "getStatisticsOperationDocuments",
      "reportStatisticsEntryOutput",
      "getReportDaneExport",
      "statisticsSecurityCargoVehicles",
      "statisticsSecurityVeto",
      "statisticsSecurityQualifiedUsers",
      "statisticsOperationEntryOutput",
      "statisticsEntryFormAll",
      "statisticsOutputFormAll",
      "statisticsEntryPassAll",
      "statisticsDispatchPassAll",
      "statisticsOperationDocument",
      "statisticsReturnEntryForm",
      "statisticsReturnOutputForm",
      "statisticsReturnIC",
      "exportExcelEstatistics",
      "exportPDFEstatistics",
      "exportExcelEstatisticsOperationEntryOutput",
      "exportExcelReviewerForm",
      "exportExcelFormItem",
      "exportExcelStatisticsEntryForm",
      "exportExcelStatisticsOutputForm",
      "exportExcelStatisticsSecurityVehicle",
      "exportExcelStatisticsOperationDocument",
      "exportExceltStatisticsSecurityVeto",
      "exportExelStatisticsSecurityQualifiedUser",
      "statisticsDocumentOperationQualifiedUser",
      "statisticsRevisionOperationTime",
      "showFormTime",
      "exportExcelStatisticsRevisionOperationTime",
      "exportExcelStatisticsEntryPassAll",
      "exportExcelStatisticsDispatchPassAll",
    ],
  */
];

export const inventoryControl = [
  {
    name: "Inventario",
    path: "/inventario",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventory", "exportExcelInventory"],
  },
  {
    name: "Inventario en trámite",
    path: "/InventoryInProcess",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["InventoryInProcess", "exportExcelInventory"],
  },
  {
    name: "Auxiliar referencia",
    path: "/auxReference",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryRefAux", "exportExcelInventoryRefAux"],
  },
  {
    name: "Auxiliar de saldo",
    path: "/auxBalance",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryRefBalance", "exportExcelInventoryRefBalance"],
  },
  {
    name: "Auxiliar salidas",
    path: "/auxOutputs",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryRefGoOut", "exportExcelInventoryRefGoOut"],
  },
  {
    name: "Auxiliar temporales",
    path: "/temporaryAux",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryRefTemporary", "exportExcelInventoryRefTemporal"],
  },
  {
    name: "Producto",
    path: "/inventarioProduc",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryProduct", "exportExcelInventoryRefProduct"],
  },

  {
    name: "Auditoría de inventario",
    path: "/AuditoriaDeInventario",
    image: inventoryIcon,
    src: BsFillClipboard2MinusFill,
    nameAccess: ["getInventoryForAudith"],
  },
];
