/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { Table, Thead, Tr, Td, Tbody, Tooltip } from "@chakra-ui/react";
import bro from "../../../src/assets/bro.png";
import "../SelectInput/stilos.css";
import { ThStyledBlue } from "./Table.styled";
import { IconGeneric } from "styled/Icons.styled";
import { __formaterCant } from "components/Funciones/formaterCant";
import { isArray, isNumber, isString } from "utils/type-check-utils";

const bold = { fontWeight: "bold" };

const TableOrder = ({
  thead = [],
  tbodyData: TbodyData,
  data = [],
  setData,
  loading = false,
  isNum = true,
  imageNone,
  actionsNone,
  subTotals,
  titleTotal,
  td,
  tUnid,
  tbodyUnd: TbodyUnd,
  className,
  arreglo,
  otherClass,
  getMonitoring,
  formType,
  setLoadOperations,
  setState,
  onClose,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [update, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [press, setPress] = useState(null);
  const [viewLabel, setviewLabel] = useState({});

  const orderFirst = () => {
    const firstOrder = Array.isArray(data) && data?.map((item) => item);
    setInitialData(firstOrder);
  };

  useEffect(() => {
    if (thead) {
      const newView = {};
      thead.map((e, index) => {
        const valor = "index_" + index;
        return (newView[valor] = true);
      });
      setviewLabel(newView);
    }
  }, [thead]);

  useEffect(() => {
    orderFirst();
  }, []);

  const orderData = (order) => {
    if (order !== press && order) {
      const newData = data?.sort((a, b) =>
        a[order] > b[order] ? 1 : a[order] < b[order] ? -1 : 0
      );
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: newData }));
      } else {
        setData(newData);
      }
      setUpdate(!update);
      setPress(order);
    } else if (order) {
      if (arreglo) {
        setData((prev) => ({ ...prev, [arreglo]: initialData }));
      } else {
        setData(initialData);
      }
      setUpdate(!update);
      setPress(null);
    }
  };

  const hableViewLabel = (e) => {
    const id = e.target.id;
    const valor = "index_" + id;
    setviewLabel({ ...viewLabel, [valor]: !viewLabel[valor] });
  };

  const handleName = (index) => {
    const valor = "index_" + index;
    if (viewLabel[valor]) {
      return true;
    }
  };

  return (
    <div className="table-responsive">
      <Table
        size="md"
        variant="simple"
        className="table table-striped table-sm "
        overflowX={true}
      >
        <Thead>
          <Tr>
            {thead.map((th, index) =>
              index > 1 ? (
                <ThStyledBlue
                  key={th?.name + "_" + index}
                  isNumeric={isNum}
                  onClick={(e) => arreglo && orderData(th.order, th.isNumber)}
                  cursor={th.order ? true : null}
                  id="alinear"
                  className="text-center"
                >
                  <div className="d-flex justify-content-center align-items-center mr-4">
                    {handleName(index) ? th?.name : ""}
                    <span>
                      {th.order && arreglo ? (
                        <IconGeneric as={MdKeyboardArrowDown} />
                      ) : (
                        ""
                      )}
                    </span>
                    <span>
                      {th.collapse ? (
                        <Tooltip placement="top" hasArrow label={th?.name}>
                          <span>
                            <IconGeneric
                              width="18px"
                              id={index}
                              onClick={hableViewLabel}
                              as={
                                handleName(index)
                                  ? BsArrowBarLeft
                                  : BsArrowBarRight
                              }
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </ThStyledBlue>
              ) : (
                <ThStyledBlue
                  key={`${th?.name}_${index}`}
                  onClick={(e) => arreglo && orderData(th.order, th.isNumber)}
                  cursor={th.order ? true : null}
                  className="text-center"
                >
                  <div className="d-flex justify-content-center align-items-center mr-4">
                    {handleName(index) ? th?.name : ""}
                    <span>
                      {th.order && arreglo ? (
                        <IconGeneric as={MdKeyboardArrowDown} />
                      ) : (
                        ""
                      )}
                    </span>
                    <span>
                      {th.collapse ? (
                        <Tooltip placement="top" hasArrow label={th?.name}>
                          <span>
                            <IconGeneric
                              width="18px"
                              id={index}
                              onClick={hableViewLabel}
                              as={
                                handleName(index)
                                  ? BsArrowBarLeft
                                  : BsArrowBarRight
                              }
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </ThStyledBlue>
              )
            )}
          </Tr>
        </Thead>
        <Tbody className={className || ""}>
          {update ? (
            data?.length <= 0 && !loading ? (
              <Tr>
                {!imageNone && (
                  <Td colSpan={thead.length + 1}>
                    <div className="d-flex justify-content-center ml-6">
                      <img
                        style={{ width: `${20}%` }}
                        src={window.assetURL + bro}
                        alt="no hay formulario"
                      />
                    </div>
                    <h2 className="text-center my-2">Aún no hay registros.</h2>
                  </Td>
                )}
              </Tr>
            ) : (
              <>
                {isArray(data) &&
                  data?.map((item, index) => (
                    <TbodyData
                      key={`${index}-${Date.now()}-${index}`}
                      data={item}
                      onClose={onClose}
                      getMonitoring={getMonitoring}
                      formType={formType}
                      setLoadOperations={setLoadOperations}
                      setState={setState}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  ))}
              </>
            )
          ) : data?.length <= 0 && !loading ? (
            <Tr>
              {!imageNone && (
                <Td colSpan={thead.length + 1}>
                  <div className="d-flex justify-content-center">
                    <img
                      style={{ width: `${20}%` }}
                      src={window.assetURL + bro}
                      alt="no hay formulario"
                    />
                  </div>
                  <h2 className="text-center my-2">Aún no hay registros.</h2>
                </Td>
              )}
            </Tr>
          ) : (
            <>
              {isArray(data) &&
                data?.map((item, index) => (
                  <TbodyData
                    key={`${index}-${Date.now()}-${index}`}
                    data={item}
                    iope={index}
                    onClose={onClose}
                    getMonitoring={getMonitoring}
                    actionsNone={actionsNone}
                    setLoadOperations={setLoadOperations}
                    setState={setState}
                    viewLabel={viewLabel}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  // <tr
                  //   key={`${index}-${Date.now()}-${index}`}
                  //   onMouseEnter={handleMouseEnter}
                  //   onMouseLeave={handleMouseLeave}
                  // >
                  //   <TbodyData
                  //     data={item}
                  //     iope={index}
                  //     onClose={onClose}
                  //     getMonitoring={getMonitoring}
                  //     actionsNone={actionsNone}
                  //     setLoadOperations={setLoadOperations}
                  //     setState={setState}
                  //     viewLabel={viewLabel}
                  //   />
                  // </tr>
                ))}
            </>
          )}
          <>
            {(isArray(subTotals) ||
              isNumber(subTotals) ||
              isString(subTotals)) && (
              <Tr>
                <Td className="text-center" style={bold}>
                  {__formaterCant(titleTotal)}
                </Td>
                {isArray(td) && td.map((i) => <Td key={i} />)}

                {(isNumber(subTotals) || isString(subTotals)) && (
                  <Td
                    className={
                      otherClass
                        ? "text-center text-size-export"
                        : "text-center"
                    }
                    isNumeric
                    style={bold}
                  >
                    {__formaterCant(subTotals)}
                  </Td>
                )}

                {isArray(subTotals) &&
                  subTotals.map((item, index) => (
                    <Td
                      className={
                        otherClass
                          ? "text-center text-size-export"
                          : "text-center"
                      }
                      key={index}
                      isNumeric
                      style={bold}
                    >
                      {__formaterCant(item)}
                    </Td>
                  ))}
              </Tr>
            )}

            {tUnid &&
              tUnid.map((item, index) => <TbodyUnd key={index} data={item} />)}
          </>
        </Tbody>
      </Table>
    </div>
  );
};

export default TableOrder;
