/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import isArrayNotEmpty, { isArray } from "utils/type-check-utils";

const useStatusListener = ({ id, dataCurrent, callBack = () => {} }) => {
  useEffect(() => {
    if (!id) {
      return;
    }

    const channelName = `change-form-status-${id}`;
    const channel = window.Echo.channel(channelName);

    const handleNewStatus = (data) => {
      const { flag, form_id, new_status } = data;
      callBack(flag, form_id, new_status, dataCurrent);
    };

    if (isArray(id) && isArrayNotEmpty(id)) {
      id.map(({ id }) => {
        const channelName = `change-form-status-${id}`;
        const channel = window.Echo.channel(channelName);

        channel.listen(".new-status-form", handleNewStatus);
        console.log("channel", channelName);
        return 0;
      });

      return;
    }

    channel.listen(".new-status-form", handleNewStatus);

    return () => {
      channel.stopListening(".new-status-form", handleNewStatus);
    };
  }, [dataCurrent, id]);
};

export default useStatusListener;
