import { Td, Tr } from '@chakra-ui/react'
import React from 'react'

const TbodyReports = ({data}) => {
  return (
    <Tr>
      <Td className='text-center'>{data?.date_security_pass}</Td>
      <Td className='text-center'>{data?.weighing_date}</Td>
      <Td className='text-center'>{data?.vehicle_plate}</Td>
      <Td className='text-center'>{data?.driver}</Td>
      <Td className='text-center'>{data?.identification}</Td>
      <Td className='text-center'>{data?.phone}</Td>
      <Td className='text-center'>{data?.name_qualified}</Td>
    </Tr>
  )
}

export default TbodyReports
