/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Divider, Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormLicensesShow = () => {
  const initialForm = {
    expireDate_license: "",
    settled_license: "",
    name_license: "",
    description_license: "",
    status_license: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  // console.log(dataToShow);

  useEffect(() => {
    if (dataToShow) {
      // console.log(dataToShow);
      setForm(dataToShow);
    } else {
      setForm(initialForm);
      // console.log(dataToShow);
    }
  }, [dataToShow]);
  return (
    <>
      <Text fontSize="md" my={2} style={{ fontWeight: 600 }}>
        {/* {dataToEdit ? "Editar Licencia" : "Crear Licencia"} */}
        Licencias
      </Text>
      <Divider mb={3} />
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form?.name_license}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>No. de usuarios</LabeFormlStyled>
                <InputFake>{form?.active_accounts}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>F. Expiración</LabeFormlStyled>
                <InputFake>{form?.expireDate_license}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Descripción</LabeFormlStyled>
                <InputFake>
                  {form?.description_license === null ? "---" : form?.description_license}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Estado</LabeFormlStyled>
              <InputFake>{form?.status_license === 1 ? "Activo" : "Inactivo"}</InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormLicensesShow;
