import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { Box, Center, Checkbox, Divider, Flex, Icon, Link, ScaleFade, SimpleGrid } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import "./styles.css";
import { CardButton } from "styled/Buttons.styled";
import AlertError from "components/Alerts/AlertError";
import FormField from "components/FormField/FormField";
import { FirstLoginContext } from "context/FirstLoginContext";
import { InputsContainer, StyledLink } from "../../Auth.styled";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import PasswordField from "components/PasswordField/PasswordField";
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { BiLogoInstagramAlt, BiSupport } from "react-icons/bi";
import {
  geolocation,
  getStores,
  handleKeepSesion,
  login
} from "./functionLogin";

import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import { Text } from "@react-pdf/renderer";
import { AUTH_TYPES } from "redux/types/authTypes";
import { FIRST_LOGIN } from "redux/types/firstLogonTypes";
import { loginAction } from "redux/actions/authentication";

const key = encHex.parse("0123456789abcdef0123456789abcdef");
const iv = encHex.parse("abcdef9876543210abcdef9876543210");

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("¡Inserte un nombre de usuario!"),
  password: Yup.string().required("¡Inserte su contraseña!")
});

const initialValues = {
  username: "",
  password: "",
  latitude: "",
  longitude: ""
};

const channel = new BroadcastChannel("login-channel");

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { firstLoginDispatch, isOpen, openModal, closeModal } =
    useContext(FirstLoginContext);
  const dispacthRedux = useDispatch();
  const [geoLocation, setgeoLocation] = useState(null);
  const [storeKeepSesion, setstoreKeepSesion] = useState(false);

  const cripto = (text) => {
    const encrypted = aes.encrypt(text, key, { iv }).toString();
    return encrypted;
  };

  useEffect(() => {
    geolocation(setgeoLocation);
    getStores(setstoreKeepSesion);
  }, []);

  const handleCloseAlert = () => {
    closeModal();
    setAlertMessage("");
  };

  const handleKeep = (e) => {
    e.preventDefault();
    handleKeepSesion(e, setstoreKeepSesion);
  };

  channel.onmessage = (message) => {
    if (message?.data?.type === FIRST_LOGIN.CHANGE_DATA) {
      firstLoginDispatch(message?.data);
      return navigate(`/auth/change-password`); /* Use case */
    }
    dispacthRedux(loginAction(message?.data?.payload));
  };

  const onSubmitLogin = (values) => {
    const passwordEncry = cripto(values.password);
    login(
      values,
      geoLocation,
      storeKeepSesion,
      setLoading,
      setAlertMessage,
      openModal,
      navigate,
      dispatch,
      dispacthRedux,
      firstLoginDispatch,
      location,
      passwordEncry,
      channel
    );
  };

  return (
    <div className="w-100">
      {loading && <SpinnerComponent />}
      {alertMessage && (
        <ScaleFade in={isOpen}>
          <AlertError
            alertMessage={alertMessage}
            closeAlert={handleCloseAlert}
          />
        </ScaleFade>
      )}
      <h1 className="titlezf ">ACCESO<br />E-FRANCO <span style={{ color: '#1f7ac3' }}>4.0</span></h1>
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={onSubmitLogin}
      >
        {() => (
          <Form>
            <InputsContainer spacing={5}>
              <FormField
                size="md"
                name="username"
                placeholder="Usuario"
                autocomplete="username"
              />
              <PasswordField
                size="md"
                name="password"
                placeholder="Contraseña"
              />
              <div className="row">
                <div className="col col-lg-7">
                  <Checkbox
                    isChecked={storeKeepSesion && storeKeepSesion}
                    onChange={handleKeep}
                  >
                    <p className="checkIniciar">Mantener la sesión iniciada</p>
                  </Checkbox>
                </div>
                <div className="col m-0 p-0">
                  <StyledLink
                    className="float-end checkIniciar"
                    to="/auth/recover"
                  >
                    ¿Olvidaste tu contraseña?
                  </StyledLink>
                </div>
              </div>
              <CardButton type="submit">Iniciar sesión</CardButton>

              <div
                style={{
                  width: "100%",
                  background: "#F2F2F2",
                  borderRadius: "10px",
                  padding: 10
                }}
              >
                <Flex className="d-flex justify-content-center">
                  <CardButton onClick={() => window.location = 'mailto:soporte@zonafrancabarranquilla.com'} width='80%'>
                    <Icon color="#FFF" as={BiSupport} className="me-1" />{" "}
                    SOPORTE
                  </CardButton>
                </Flex>
                <Center>
                  <Text className="checkIniciar colorFollow">Siguenos en</Text>
                </Center>
                <div className="container_">
                  <div className="social-media">
                    <Icon display='block' color="#1f7ac3" as={FaTwitter} fontSize='20px' mb='4px' alignSelf='center' />
                    <Link href='https://twitter.com/zfbaq' isExternal display='block'>@zfbaq</Link>
                  </div>

                  <div className="social-media">
                    <Icon display='block' color="#1f7ac3" as={FaFacebook} fontSize='20px' mb='4px' alignSelf='center' />
                    <Link href='https://www.facebook.com/zonafrancabaq' isExternal display='block'>zfbaq</Link>
                  </div>

                  <div className="social-media">
                    <Icon display='block' color="#1f7ac3" as={FaLinkedin} fontSize='20px' mb='4px' alignSelf='center' />
                    <Link href='https://www.linkedin.com/company/zona-franca-barranquilla/' isExternal display='block'>@zfbaq</Link>
                  </div>

                  <div className="social-media">
                    <Icon color="#1f7ac3" as={BiLogoInstagramAlt} fontSize='22px' mb='4px' alignSelf='center' />
                    <Link href='https://www.instagram.com/zfbaq/' isExternal display='block'>@zfbaq</Link>
                    <Link href='https://www.instagram.com/zofiazf/?hl=es' isExternal display='block'>@zofiazf</Link>
                  </div>
                </div>

                <p style={{ textAlign: 'center' }} className="checkIniciar colorFollow">Powered by Zona Franca de Barranquilla</p>

              </div>
            </InputsContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
