import './style/Card.css'

import { useNavigate } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { SelectColor, SelectIcons } from './Func/IconsAndColor'
import { Box, Center, Divider, Icon, Text } from '@chakra-ui/react'
import { BsRecordCircle } from 'react-icons/bs'
import { FaRegCheckCircle, FaWeight } from 'react-icons/fa'
import { BiXCircle } from 'react-icons/bi'
import FormCrudCRUDContext from 'context/FormCrudContex'
import environment from 'constants/apiConst'

export const CardReviews = ({ data, ...props }) => {
  const { changeStatus, setRequestChangeStatusForm } =
    useContext(FormCrudCRUDContext)
  const ColorRender =
    data?.tracking_status === 1
      ? 'green'
      : data?.tracking_status === 2
        ? 'red'
        : 'gray'

  const navigate = useNavigate()

  useEffect(() => {
    props?.movement === 'Ingreso'
      ? setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS)
      : setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS_EXIT)
  }, [])

  const handleStatus = (newStatus, id) => {
    const statusForm =
      props?.movement === 'Ingreso' ? 'status_entryForm' : 'status_outputForm'

    changeStatus({
      id,
      [statusForm]: newStatus
    })
  }

  const redirection = () => {
    handleStatus('REVISADO', data?.form_id)
    navigate(
      props.movement === 'Ingreso'
        ? `/CheckFormEntry?Number=${props?.form_id}`
        : `/CheckForm?Number=${props?.form_id}`
    )
  }

  return (
    <>
      <Box
        cursor={'pointer'}
        onClick={redirection}
        maxW='sm'
        h='140px'
        p='1'
        m='1'
        shadow={
          'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'
        }
        borderRadius='lg'
        overflow='hidden'
        display={'flex'}
      >
        <Box
          m='2'
          pt='2'
          fontSize={'38px'}
          maxW='100px'
          borderRadius='lg'
          shadow={
            'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
          }
          backgroundColor={SelectColor(
            data?.transport_method
              ? data?.transport_method?.name_transportMethod
              : ''
          )}
          textColor={'white'}
          w='50%'
        >
          <Center flexDirection={'column'}>
            {SelectIcons(
              data?.transport_method
                ? data?.transport_method?.name_transportMethod
                : ''
            )}
            <Divider w={'8'} mt='1' />
            <Box display='flex' alignItems='baseline'>
              <Box
                color='black.300'
                letterSpacing='wide'
                fontSize='12px'
                mb='1'
              >
                {/*  <b>0</b> subpatidas <br />  */}
                <b>{props?.items}</b> Items
              </Box>
            </Box>
          </Center>
        </Box>

        <Box flexDir={'row'} m='2' w='50%'>
          <Text
            mt='1'
            fontWeight='semibold'
            as='h4'
            lineHeight='tight'
            noOfLines={1}
            fontSize={'14px'}
            pt='2'
          >
            {props.title}
          </Text>
          <Text fontSize={'12px'}>Nº {data?.num_form}</Text>
          <Text fontSize={'12px'}>Nit: {props?.nit}</Text>
          <Text fontSize={'12px'}>Operación: {props?.code}</Text>
          <Text fontSize={'12px'}>
            Nivel de R: {data?.operation_risk_level ?? 'N/A'}
          </Text>
        </Box>

        <Box flexDir={'row'} m='2' w='50%'>
          <Text
            mt='1'
            textAlign={'right'}
            as='h4'
            lineHeight='tight'
            noOfLines={1}
            fontSize={'14px'}
            pb='2'
            pt='2'
          >
            {props?.date}
          </Text>
          {/*   <Text fontSize={'12px'}>Estado: {props?.status}</Text> */}

          <Text fontSize={'12px'}>
            <Icon as={BsRecordCircle} boxSize={5} color={ColorRender} pr={1} />
            Seguimiento
          </Text>

          <Text fontSize={'12px'}>
            <Icon
              as={data?.inspection === 1 ? FaRegCheckCircle : BiXCircle}
              boxSize={5}
              color={
                data?.inspection === 1
                  ? 'green'
                  : data?.inspection === 2
                    ? 'red'
                    : 'gray'
              }
              pr={1}
            />
            inspección
          </Text>
          <Text fontSize={'12px'}>
            <Icon
              as={FaWeight}
              boxSize={5}
              color={data?.weighing ? 'green.500' : 'gray.500'}
              pr={1}
            />
            Pesaje
          </Text>
        </Box>
      </Box>
    </>
  )
}
