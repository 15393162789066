import axios from "axios";

import env from "../../constants/apiConst";

export const __AUnidad = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.UNIT_GET}?page=${page || 1}`, options);
  return res;
};

export const __SelectUnidades = async (token) => {
  const options = {
    method: "GET",
    headers: { Authorization: token ? `Bearer ${token}` : "" }
  };
  const res = await axios(`${env.UNIT_ALL}`, options);
  return res;
};

export const __SelectUnidad = async (token, id) => {
  const options = {
    method: "GET",
    headers: { Authorization: token ? `Bearer ${token}` : "" }
  };
  const res = await axios(`${env.UNIT_SUP}/${id || ''}`, options);
  return res;
};

export const __SHOW_UNIT_MEDIDAS = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.UNIT_SHOW}/${id}`, options);
  return res;
};
