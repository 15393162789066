import { Td, Tr } from "@chakra-ui/react";

const TbodyRawMateriasExport = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data.item_code}</Td>
      <Td className="text-center">{data?.reference_description}</Td>
      <Td className="text-center">{data?.code_product}</Td>
      <Td className="text-center">{data?.quantity}</Td>
      <Td className="text-center">{data?.business_unit}</Td>
      <Td className="text-center">{data?.nature_of_inventory}</Td>
      <Td className="text-center">{data?.quantity_product_supply}</Td>
      <Td className="text-center">{data?.code_unit_measurement}</Td>
      <Td className="text-center">{data.net_weight_kilo_supply}</Td>
      <Td className="text-center">{data?.total_calculated_supply}</Td>
      <Td className="text-center">{data?.description_product}</Td>
    </Tr>
  );
};

export default TbodyRawMateriasExport;
