import React from "react";
import { Flex, Spacer, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric, IconImport } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { RiUploadCloudFill } from "react-icons/ri";
import './Additem.css'

export const AddItemsMassively = ({
  nameAttachment,
  handleAttachment,
  Upload,
  removeItemFromArr,
  onCloseImport,
  handleSUbmit,
  attachmentTitle = null,
  ErrorTable = null,
  isLoading = false,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <input
        onChange={handleAttachment}
        type="file"
        id="display"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div onClick={Upload} className="direction">
        <div id="redonder" className="d-flex justify-content-center">
          <IconImport color="#fff" as={RiUploadCloudFill} />
        </div>
        {attachmentTitle && <h2>Cargar la plantilla con nombre <br />{`Plantilla de carga masiva ${attachmentTitle}`}</h2>}
      </div>
      <br />
      <br />
      <span className="text-mute">
        {nameAttachment && nameAttachment}
        {nameAttachment && (
          <IconGeneric
            onClick={() => removeItemFromArr()}
            className="m-1 text-danger"
            as={MdDelete}
          />
        )}
      </span>
      <Flex className="mb-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseImport}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled isLoading={isLoading} bgColor={isLoading ? "#bababa" : "#1F7AC3"} type="submit" onClick={!isLoading ? handleSUbmit : null}>
          Guardar
        </ButtonStyled>
      </Flex>
      <ErrorTable />
    </div>
  );
};
