import environment from "constants/apiConst";

export const arrayListCustomClereance = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "qualifiedUser_id"
  },
  {
    value: "acceptance_number",
    label: "Número aceptación"
  },
  {
    value: "acceptance_date",
    label: "Fecha aceptación"
  },
  {
    value: "name_tradeAgreement",
    label: "Acuerdos comerciales"
  },
  {
    value: "customsClearanceForm_number",
    label: "Número de formulario"
  },
  {
    value: "status",
    label: "Estado"
  },
  {
    value: "name_businessUnit",
    label: "Unidad comercial"
  }
];

export const arrayListCustomClereanceNoUC = [
  {
    value: "name_qualified",
    label: "Usuario calificado"

  },
  {
    value: "release_number",
    label: "Levante"
  },
  {
    value: "nickname",
    label: "Usuario creador"
  },
  {
    value: "code_subheading",
    label: "Subpartida arancelaria"

  },
  {
    value: "subchapter",
    label: "Descripción de la subpartida"
  }
];

export const arrayListCustomClereanceUC = [
  {
    value: "release_number",
    label: "Levante"
  },
  {
    value: "nickname",
    label: "Usuario creador"
  },
  {
    value: "code_subheading",
    label: "Subpartida arancelaria"
  },
  {
    value: "subchapter",
    label: "Descripción de la subpartida"
  }
];

export const customClearanceNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "AUTORIZADO", label: "AUTORIZADO" }
    ],
    value: null
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]
