import {
  isObjectNotEmpty,
  removeObjectProperty,
  safelyConvertToString
} from "utils/type-check-utils";

const { useState, useEffect } = require("react");

const useUpdateNotations = ({ formId, reviewsData = {}, dataCurrent = {} }) => {
  const [updateNotationData, setUpdateNotations] = useState(null);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    if (dataCurrent?.itemCurrent) {
      dataCurrent?.itemCurrent.forEach((el) => {
        setUpdateData((prev) => ({ ...prev, [el.id]: el }));
      });
    }
  }, [dataCurrent]);

  const updateNotation = (
    fieldName = "",
    label = "",
    newValue = "",
    oldValue = "",
    itemId = ""
  ) => {
    const colorOld = reviewsData?.[itemId]?.[fieldName]?.status?.color;
    const valueOld =
      dataCurrent?.formCurrent?.[fieldName] ||
      updateData?.[itemId]?.[fieldName] ||
      "";

    const colorNotation =
      {
        "#fc0a0a": "purple",
        red: "purple",
        green: "red"
      }[colorOld] || "red";

    isObjectNotEmpty(reviewsData) &&
      setUpdateNotations((prevData) => ({
        ...prevData,
        [formId]: {
          ...prevData?.[formId],
          [itemId]: {
            ...prevData?.[formId]?.[itemId],
            [fieldName]: {
              ...prevData?.[formId]?.[itemId]?.[fieldName],
              form_field: fieldName,
              form_field_info_before: oldValue,
              form_field_info_now: newValue,
              name: label,
              item: safelyConvertToString(itemId),
              status: {
                color: colorNotation
              }
            }
          }
        }
      }));

    if (valueOld === newValue) {
      setUpdateNotations((prevData) => {
        if (!prevData?.[formId]?.[itemId]) {
          return prevData;
        }

        const { [fieldName]: _, ...rest } = prevData[formId][itemId];

        return {
          ...prevData,
          [formId]: {
            ...prevData[formId],
            [itemId]: {
              ...rest
            }
          }
        };
      });
    }
  };

  const resetNotations = () => {
    setUpdateNotations(null);
    setUpdateData(null);
  };

  return {
    updateNotation,
    updateNotationData,
    reviewsData,
    resetNotations
  };
};

export default useUpdateNotations;

/*
import { safelyConvertToString } from "utils/type-check-utils";

const { useState } = require("react");

const useUpdateNotations = ({ formId, reviewsData = {} }) => {
  const [updateNotationData, setUpdateNotations] = useState(null);

  const updateNotation = (
    fieldName = "",
    label = "",
    newValue = "",
    oldValue = "",
    itemId = ""
  ) => {
    const itemReviews = reviewsData?.[itemId];
    const fieldStatus = itemReviews?.[fieldName]?.status;
    const colorPrevStatus = fieldStatus?.color;

    const colorMapping = {
      "#fc0a0a": "purple",
      red: "purple",
      green: "red",
    };

    const colorMapped = colorMapping[colorPrevStatus] || null;
    const reviewsExist = Boolean(reviewsData);
    const colorForAction = reviewsExist ? "orange" : "gray";

    const finalColor = colorMapped || colorForAction;

    reviewsExist &&
      setUpdateNotations((prevData) => ({
        ...prevData,
        [formId]: {
          ...prevData?.[formId],
          [itemId]: {
            ...prevData?.[formId]?.[itemId],
            [fieldName]: {
              ...prevData?.[formId]?.[itemId]?.[fieldName],
              form_field: fieldName,
              form_field_info_before: oldValue,
              form_field_info_now: newValue,
              name: label,
              item: safelyConvertToString(itemId),
              status: {
                color: finalColor,
              },
            },
          },
        },
      }));

    return 0;
  };

  const resetNotations = () => {
    setUpdateNotations(null);
  };

  return {
    updateNotation,
    updateNotationData,
    reviewsData,
    resetNotations,
  };
};

export default useUpdateNotations;
 */
