/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { MenuItem } from '@chakra-ui/react'
import { MdContentCopy, MdOutlineAssignmentReturn } from 'react-icons/md'
import { AuthContext } from 'context/AuthContext'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsCaducar = ({ data }) => {
  const { setDataToCopy, onOpenCopy, onOpenDesCaducar, setDataDescaducar } =
    useContext(FormCrudCRUDContext)
  const location = useLocation()
  const { authUser } = useContext(AuthContext)

  const { movement = '' } = queryString.parse(location.search)

  const handleCopy = () => {
    setDataToCopy(data)
    onOpenCopy()
  }
  const handleDescaducar = () => {
    // setDataToCaducar(data);
    setDataDescaducar(data)
    onOpenDesCaducar()
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { Outputform, Entryform } = localPermissions

  const { changeStatus: changeStatusEntryForm, copy: copyEntryForm } = Entryform
  const { changeStatus: changeStatusOutputForm, copy: copyOutputForm } =
    Outputform

  return (
    <>
      {(movement === 'Ingreso' || movement === 'Salida') &&
        (hasCommonElements(permissionList, [
          ...copyEntryForm,
          ...copyOutputForm
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={handleCopy} py={3}>
              <IconMenuRUD color='#03C39A' as={MdContentCopy} />
              Copiar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}

      {authUser.is_qualified_user !== 1 &&
        (hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) ||
          authUser.admin === 1) && (
          <MenuItemStyled onClick={handleDescaducar} py={3}>
            <IconMenuRUD color='#F9A621' as={MdOutlineAssignmentReturn} />
            Descaducar
          </MenuItemStyled>
        )}
    </>
  )
}
