import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box
} from "@chakra-ui/react";
import { Title } from "styled/Title";

export const AccordionSimple = ({ title, children }) => {
  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" className="d-flex">
                <Title size={"14px"}>{title}</Title>
                <AccordionIcon color="#1F7AC3" className="ms-5" />
              </Box>
            </AccordionButton>
          </h2>
          {children}
        </AccordionItem>
      </Accordion>
    </>
  );
};
