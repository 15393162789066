/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { RiSearch2Line } from 'react-icons/ri'
import React, { useContext, useEffect, useState } from 'react'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid
} from '@chakra-ui/react'

import { useForm } from 'hooks/useForm'
import { useModal } from 'hooks/useModal'
import FormSearchRef from './FormSearchRef'
import { IconGeneric } from 'styled/Icons.styled'
import { ButtonStyled } from 'styled/Buttons.styled'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import CustomsCrudContext from 'context/CustomsCrudContext'
import {
  LabelGroup,
  LabelGroupTextArea
} from 'pages/RefProductInter/LabelGroup'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { __createItemCustomsClearance } from 'request/CustomsClearance.js/__CustomsClearence'
import { OnlyNumberInput } from 'components/Funciones/OnlyNumberInput'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { FormatoCantidadMexico } from 'components/Funciones/FormatoCantidadMexico'
import { useFormatQuantity } from 'hooks/useFormatQuantity'
import { AuthContext } from 'context/AuthContext'

import { MaxLength } from 'utils/MaxLength'
import { useDebounceError } from 'hooks/useDebounceError'
import { useMutation } from '@tanstack/react-query'
import GlobalContext from 'context/GlobalContext'
import environment from 'constants/apiConst'
import TableOrderHover from 'components/Tables/TableOrderHover'
import { TheadFormSearchRefTable } from './theadCustomsClearance'
import TbodyFormSearchRefTable from './Tbody/TbodyFormSearchRefTable'
import Pagination from 'components/Pagination/Pagination'
import { BiSearchAlt2 } from 'react-icons/bi'
import { NumericFormat } from 'react-number-format'
import { isString, removeDecimalIfZero } from 'utils/type-check-utils'
import { Regex } from 'utils/Regex'
import { parseNumberWithCommas } from 'pages/Entry_and_exit_forms/FormEntry/AddItemForm/AddItemForm'
import { parseFloat } from 'utils/parseFloat'

/**
 *  FormAddItems - Componente de formulario para agregar item en Desaduanamiento desplegable al accionar un botón
 *
 * @component
 * @param {Number} code_subheading Subpartida arancelaria.
 * @return {Component} Retorna componente de formulario.
 */

const errorsDefaultState = {
  quantity_subtract: false
}

const FormAddItems = ({ setShowAddItem, idUser }) => {
  const [openAlert] = AlertErrorAlert()
  const [fomateo] = useFormatQuantity()
  const { authUser } = useContext(AuthContext)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [data, setData] = useState([])
  const [errorsState, setErrorsState] = useState(errorsDefaultState)

  const { token, decimal_values_precision } = useSelector(
    state => state.auth.dataUser
  )
  const [isOpenSearch, onOpenSeach, closeOpneSearch] = useModal()
  const {
    itemId,
    loading,
    setLoading,
    dataTableAddItems,
    getItemsCustomsClearances,
    setgetItemsFormCustomsClearances,
    editItemsCustomsClearance: updateAddItems,
    seteditItemsCustomsClearance,
    getItemsFormCustomsClearances: itemsForms,
    decimalUcContext,
    codeSubheading,
    setItemId
  } = useContext(CustomsCrudContext)

  const location = useLocation() // navigate

  // const { idShowCustoms } = useSelector((state) => state.customsClearanceState);
  const { Customs = '' } = queryString.parse(location.search)

  const [values, handleInputChange, , setValues] = useForm({
    US_value: '',
    reference: '',
    factor_conv: 0,
    business_unit_product: 0,
    code_product: '',
    business_unit: 0,
    product_quantity: 0,
    weight_kilograms: 0,
    equivalent_amount: 0,
    quantity_subtract: 0,
    description_product: 'N/A'
  })

  useEffect(() => {
    if (itemsForms) {
      setValues({
        US_value: itemsForms?.US_value || '',
        reference: itemsForms?.reference || '',
        factor_conv:
          itemsForms?.convertion_factor_product ||
          itemsForms?.factor_conv ||
          '',
        business_unit_product: itemsForms?.business_unit_product
          ? itemsForms?.business_unit_product
          : itemsForms?.product_business_unit_code,
        code_product: itemsForms?.code_product,
        business_unit: itemsForms?.business_unit || '',
        product_quantity: itemsForms?.product_quantity || '',
        weight_kilograms: itemsForms?.weight_kilograms || '',
        equivalent_amount: itemsForms?.equivalent_amount || '',
        quantity_subtract: itemsForms?.quantity_subtract || '',
        description_product: itemsForms?.description_product || 'N/A'
      })
    }
  }, [itemsForms])

  const createItemCustomsClearance = async e => {
    e.preventDefault()
    if (errorsState.quantity_subtract === true) {
      return openAlert(
        'La referencia digitada no tiene saldo suficiente para realizar la Declaración.',
        'error'
      )
    } else if (!values.quantity_subtract) {
      openAlert('Debe ingresar una cantidad de producto a declarar', 'error')
    } else if (!values.equivalent_amount) {
      openAlert('Debe ingresar una cantidad equivalente', 'error')
    } else if (!values.weight_kilograms) {
      openAlert('Debe ingresar un peso neto en kilogramos', 'error')
    } else if (!values.US_value) {
      openAlert('Debe ingresar un valor merc', 'error')
    } else {
      try {
        const body = {
          id:
            dataTableAddItems && dataTableAddItems[0]?.customsClearanceItem_id,
          entryFormItem_id: itemId,
          customsClearance_id: Customs,
          reference: values?.reference,
          description_product: values?.description_product,
          product_quantity: values.product_quantity, // Cantidad producto,
          US_value: values.US_value.includes(',')
            ? values.US_value.replaceAll(',', '')
            : values.US_value, // Valor merc. / CIF ingreso / valor DPD
          weight_kilograms: values.weight_kilograms.includes(',')
            ? values.weight_kilograms.replaceAll(',', '')
            : values.weight_kilograms, // Peso neto en kilos
          equivalent_amount: values.equivalent_amount.includes(',')
            ? values.equivalent_amount.replaceAll(',', '')
            : values.equivalent_amount, // Cantidad equiv.
          quantity_subtract: values.quantity_subtract.includes(',')
            ? values.quantity_subtract.replaceAll(',', '')
            : values.quantity_subtract // Cantidad a descargar
        }

        const res = await __createItemCustomsClearance(
          token,
          body,
          updateAddItems
        )
        const status = res?.data?.status
        if (status.code === 200) {
          setLoading(true)
          const msgToast = updateAddItems
            ? 'Ítems actualizados exitosamente'
            : 'Ítems agregados exitosamente'
          openAlert(msgToast, 'success')
          getItemsCustomsClearances(Customs)
          setgetItemsFormCustomsClearances({})
          updateAddItems && handleCancel()
          setShowAddItem(false)
        } else if (status.code === 400) {
          openAlert(status.message, 'error')
        } else if (status.code === 403) {
          openAlert(status.message, 'error')
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  // useEffect(() => {
  //   handleCancel()
  // }, [])

  const handleCancel = () => {
    const defaultValues = {
      US_value: '',
      reference: '',
      factor_conv: '',
      code_product: '',
      business_unit_product: '',
      business_unit: '',
      product_quantity: '',
      weight_kilograms: '',
      equivalent_amount: '',
      quantity_subtract: '',
      description_product: ''
    }
    setShowAddItem(false)
    setValues(defaultValues)
    seteditItemsCustomsClearance(null)
  }

  const handleBlurCant = e => {
    const nameVariable = e.target.name
    let posibleResult = e.target.value

    if (posibleResult) {
      posibleResult =  parseNumberWithCommas(String(posibleResult))
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) &&
            decimalUcContext
            ? decimalUcContext
            : parseFloat(decimal_values_precision),
          posibleResult
        )
      })
      if (nameVariable === 'quantity_subtract') {
        const objetError = {}
        if (parseFloat(posibleResult) > values.product_quantity) {
          openAlert(
            'La referencia digitada no tiene saldo suficiente para realizar la Declaración.',
            'error'
          )
          objetError.quantity_subtract = true
          return setErrorsState(objetError)
        } else {
          objetError.quantity_subtract = false
          setErrorsState(objetError)
        }
        handleOperation()
      }
    }
  }

  const handleOperation = () => {
    const factor = parseNumberWithCommas(values.factor_conv)
    const cnt = parseNumberWithCommas(values.quantity_subtract)
    const resulOpe = parseFloat(factor) * parseFloat(cnt)

    const resultFormat = fomateo(
      (authUser.admin === 1 || authUser.is_commerce === 1) && decimalUcContext
        ? decimalUcContext
        : parseFloat(decimal_values_precision),
      resulOpe
    )
    if (values.business_unit === 'KG') {
      setValues(prev => ({
        ...prev,
        equivalent_amount: resultFormat,
        weight_kilograms: resultFormat
      }))
    } else {
      setValues(prev => ({
        ...prev,
        equivalent_amount: resultFormat
      }))
    }
  }

  const [handleDebounceRegexError] = useDebounceError()

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all'
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    )
  }
  const [isOpenSearchRef, onOpenSearchRef, onCloseSearchRef] = useModal()
  const { requestData } = useContext(GlobalContext)
  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleResponse = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const resData = res?.data?.status?.data?.data

      if (resData?.length > 1) {
        setData(resData)
        setMaxPage(res?.data?.status?.data?.last_page)
        return onOpenSearchRef(true)
      } else {
        setgetItemsFormCustomsClearances(resData[0])
      }
    }
  }

  const fetchReference = reference => {
    const formData = new FormData()
    formData.append('subheading', codeSubheading)
    formData.append('qualified_user_id', idUser)
    formData.append('reference', reference)

    const config = {
      data: formData,
      Endpoint: environment.FORM_CUSTOMS_CHANGE_STATUS,
      PropertyBody: 'sendFormDataContentPOST'
    }

    mutate(config, {
      onSuccess: data => handleResponse(data)
    })
  }

  const handleReference = e => {
    e.preventDefault()
    if (values?.reference && values?.reference !== '') {
      return fetchReference(values?.reference)
    } else {
      return onOpenSeach()
    }
  }


  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue, formattedValue, value } = newValue;
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target]: {
        ...sourceEvent?.event?.target,
        value: parseFloat(floatValue || value),
      },
    };
    handleInputChange(target);
  };

  const validateValue = ({ value }, maxIntegerDigits = 10) => {
    // Si no hay valor, permitir
    if (!value) return true;
  
    // Separar parte entera y decimal
    const [integerPart, decimalPart] = value.toString().split('.');
    
    // Remover separadores de miles si existen
    const cleanIntegerPart = integerPart.replace(/,/g, '');
    
    // Verificar si la parte entera excede el límite
    return cleanIntegerPart.length <= maxIntegerDigits;
  };

  return (
    <div
      className='shadow-sm p-4 mb-4 rounded'
      style={{ background: '#f2f2f2', marginTop: '40px' }}
    >
      {loading && <SpinnerComponent />}
      <ModalGeneric
        size='6xl'
        isOpen={isOpenSearchRef}
        onOpen={onOpenSearchRef}
        onClose={onCloseSearchRef}
        title='Seleccionar referencia'
      >
        <TableOrderHover
          thead={TheadFormSearchRefTable}
          data={data}
          setData={setData}
          loading={loading}
          onCloseModal={onCloseSearchRef}
          tbodyData={TbodyFormSearchRefTable}
        />

        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </ModalGeneric>

      <ModalGeneric
        size='6xl'
        isOpen={isOpenSearch}
        onOpen={onOpenSeach}
        onClose={closeOpneSearch}
        title='Buscar referencia'
      >
        <FormSearchRef onCloseModal={closeOpneSearch} idUser={idUser} />
      </ModalGeneric>

      <h1 className='my-4 fw-bolder'>Agregar ítem</h1>

      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <div className='spac d-flex'>
          <label
            style={{
              width: '100%'
            }}
          >
            Referencia
            <InputGroup>
              <Input
                w={'100%'}
                h={'38px'}
                color={'#000'}
                outline={'none'}
                fontSize={'1rem'}
                backgroundColor={'white'}
                name='reference'
                onChange={e => handleRegex(e, 'all', Regex?.all)}
                value={values?.reference}
                placeholder={'Buscar por referencia'}
              />
              <InputRightElement as='button' onClick={handleReference}>
                <RiSearch2Line color='green.500' />
              </InputRightElement>
            </InputGroup>
          </label>
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <div className='spac'>
          <LabelGroupTextArea
            name={'description_product'}
            value={values.description_product}
            onChange={updateItemValue}
            tag={'Descripción'}
            size={100}
            desicion={true}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <div className='spac'>
          <LabelGroup
            size={100}
            desicion={true}
            name='code_product'
            tag='Código producto'
            value={values?.code_product}
          />
        </div>
        <div className='spac'>
          <LabelGroup
            size={100}
            desicion={true}
            name='unit_product'
            tag='Unidad de producto'
            value={values?.business_unit_product}
          />
        </div>
        <div className='spac'>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision ?? 4}
            decimalSeparator='.'
            customInput={LabelGroup}
            name={'factor_conv'}
            size={100}
            desicion={true}
            tag='Factor conv.'
            value={
              isString(values?.factor_conv)
                ? parseFloat(values?.factor_conv)
                : values?.factor_conv
            }
          />
        </div>
        <div className='spac'>
          <LabelGroup
            size={100}
            desicion={true}
            tag='Saldo de producto'
            name='product_quantity'
            value={values?.product_quantity}
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <div className='spac'>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision ?? 4}
              decimalSeparator='.'
              customInput={LabelGroup}
              size={100}
              placeholder='0'
              // desicion={true}
              name='quantity_subtract'
              tag='Cnt. De producto a declarar'
              value={ removeDecimalIfZero(values.quantity_subtract, decimal_values_precision ?? 4)}
              onBlur={handleBlurCant}
              onChange={updateItemValue}
              isAllowed={(values) => validateValue(values,   MaxLength.customClearance.formCustomsClearance.addItem
                .quantity_subtract)}
            />
            {errorsState.quantity_subtract && (
              <span className='text-danger'>
                {parseNumberWithCommas(values.quantity_subtract) > parseNumberWithCommas(values.product_quantity)
                  ? 'La referencia digitada no tiene saldo suficiente para realizar la Declaración.'
                  : ''}
              </span>
            )}
          </div>
        </div>
        <div className='spac'>
          <LabelGroup
            size={100}
            desicion={true}
            name='unit_commercial'
            tag='Unidad comercial'
            value={values?.business_unit}
            // onChange={}
          />
        </div>
        <div className='spac'>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision ?? 4}
            decimalSeparator='.'
            customInput={LabelGroup}
            size={100}
            placeholder='0'
            tag='Cantidad equiv.'
            name='equivalent_amount'
            value={removeDecimalIfZero(values.equivalent_amount, decimal_values_precision ?? 4)}
            onBlur={handleBlurCant}
            onChange={updateItemValue}
            isAllowed={(values) => validateValue(values,   MaxLength.customClearance.formCustomsClearance.addItem
              .equivalent_amount)}
          />
        </div>
        <div className='spac'>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision ?? 4}
            decimalSeparator='.'
            customInput={LabelGroup}
            size={100}
            placeholder='0'
            tag='Peso neto en kilos'
            name='weight_kilograms'
            value={removeDecimalIfZero(values.weight_kilograms, decimal_values_precision ?? 4)}
            onBlur={handleBlurCant}
            onChange={updateItemValue}
            isAllowed={(values) => validateValue(values,  MaxLength.customClearance.formCustomsClearance.addItem
              .weight_kilograms)}
         
          />
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth='200px' spacing='10px' my={4}>
        <div className='spac'>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision ?? 4}
            decimalSeparator='.'
            customInput={LabelGroup}
            size={100}
            placeholder='0'
            tag='Valor merc. / CIF ingreso / valor DPD'
            name='US_value'
            value={removeDecimalIfZero(values.US_value, decimal_values_precision ?? 4)}
            onBlur={handleBlurCant}
            onChange={updateItemValue}
            isAllowed={(values) => validateValue(values, MaxLength.customClearance.formCustomsClearance.addItem.US_value)}
          />
        </div>
        <div className='spac' />
        <div className='spac' />
        <div className='spac' />
      </SimpleGrid>

      <div className='d-flex justify-content-end'>
        <ButtonStyled bgColor={'#bababa'} onClick={handleCancel}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled onClick={createItemCustomsClearance}>
          {updateAddItems ? 'Editar' : 'Aceptar'}
        </ButtonStyled>
      </div>
    </div>
  )
}

FormAddItems.propTypes = {
  code_subheading: PropTypes.number
}

export default FormAddItems
