import { Td, Tr } from "@chakra-ui/react";

const TBodyLogCI = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.integration_certificate_code ? data?.integration_certificate_code : "N/A"}</Td>
        <Td className="text-center"> {data?.net_weight_kilo ? data?.net_weight_kilo : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity ? data?.quantity : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_product ? data?.quantity_product : "N/A"}</Td>
        <Td className="text-center"> {data?.fob_value ? data?.fob_value : "N/A"}</Td>
        <Td className="text-center"> {data?.observations ? data?.observations : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyLogCI;
