import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";


export const handleError = (error) => {
  const [openAlert] = AlertErrorAlert();
  const { response } = error;
  const { status, data } = response;
  if (status === 422) {
    const { result } = data;
    Object.values(result).forEach((message) =>
      message.forEach((item) => openAlert(item, "error"))
    );
  }
};