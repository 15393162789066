import React, { useContext } from 'react'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'
import { AuthContext } from 'context/AuthContext'
import { FaUnlock } from 'react-icons/fa'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import PassCrudContext from 'context/PassCrudContext'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsRevisado = ({ data }) => {
  const { authUser } = useContext(AuthContext)
  const [openAlert] = AlertErrorAlert()
  const token = useSelector(state => state.auth.dataUser.token)
  const location = useLocation()
  const {
    movement = '',
  } = queryString.parse(location.search)
  const {
    setUpdate,
    update
  } = useContext(PassCrudContext)

    /**función para desbloquear  @param {string} status se utiliza para cambiar el estado del formulario*/
    const unlockForm = async (status) => {
      const body = new FormData()
      body.append('form_id', data?.id)
      movement === 'Pase de salida'
        ? body.append('type_of', 'DESPACHO')
        : body.append('type_of', 'ENTRADA')
      body.append('type', 3)
      status && body.append('change_status', status)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if(res?.data?.status?.code === 200){ //actualizamos la tabla en caso de que el desbloqueo sea exitoso para que se visualice el cambio de estado del pase
        setUpdate(!update)
      }
    }

  return (
    <>
      {(authUser.is_qualified_user !== 1) &&
         (
          <>
            <MenuItemStyled onClick={() => {
              unlockForm("PRESENTADO")// en este caso el botón de desbloquear también contara con la característica de poder cambiar de estado de revisado a presentado por petición del cliente
              openAlert('Se ha desbloqueado con exito', 'success')
            }} py={3}>
              <IconMenuRUD color={'orange'} as={FaUnlock} />
              Desbloquear
            </MenuItemStyled>
          </>
        )}
    </>
  )
}
