/* eslint-disable array-callback-return */
import { GetDate, Hours } from 'hooks/HoursAndDate'
import { Storage } from './useStorage'

// Manejo del localstore

// Agregar
export const ADDLocalStorage = (Objects, Name) => {
  localStorage.setItem(`${Name}`, JSON.stringify(Objects))
}

// Obtener
export const GetLocalStorage = Name => {
  const StoredList = localStorage.getItem(`${Name}`)
  if (StoredList === null) {
    return []
  } else {
    return JSON.parse(StoredList)
  }
}

// Eliminar
export const DeleteLocalstore = keyName => {
  localStorage.removeItem(String(keyName));
}

// Actualizar
export const UpdateLocalStore = (
  Objects,
  name,
  GetId,
  inputValue,
  form_field_info
) => {
  const Data = Objects?.map(x => {
    if (x.id !== GetId + 1) {
      return { ...x }
    } else if (x.id === GetId + 1) {
      return {
        ...x,
        comment: inputValue,
        date: GetDate(),
        time: Hours(),
        form_field_info,
        status: GetLocalStorage(name)[GetId].status
      }
    }
  })

  return { Data }
}

const colorSelect = ['gray', 'green', '#ed1010', 'purple']

export const findColorByStatus = (searchTerm, data) => {
  /*  if (data) {
    const matchingElementIndex = data.findIndex(element => {
      return element?.form_field === searchTerm
    })

    if (data?.length !== 0 && data[matchingElementIndex] !== undefined) {
      return colorSelect[data[matchingElementIndex].status]
    } else {
      return colorSelect[0]
    }
  } */
}

// Funciones para manejo de colores y datos en CustomInput

// Eliminar inicio
const ColorSelect = ['gray', 'green', '#ed1010', 'purple']

export const FindPos = (search, nameLocal) => {
/*   const FindElement = GetLocalStorage(nameLocal).findIndex(element => {
    return element?.form_field === search
  })

  if (
    GetLocalStorage(nameLocal)?.length !== 0 &&
    GetLocalStorage(nameLocal)[FindElement] !== undefined
  ) {
    return ColorSelect[GetLocalStorage(nameLocal)[FindElement].status]
  } else {
    return ColorSelect[0]
  } */
}

// Eliminar final

export const Colorcurrent = (target, nameLocal) => {
/*   const FindElement = GetLocalStorage(nameLocal).findIndex(element => {
    return element?.form_field === target.name
  })

  if (
    GetLocalStorage(nameLocal)?.length !== 0 &&
    GetLocalStorage(nameLocal)[FindElement] !== undefined
  ) {
    return ColorSelect[GetLocalStorage(nameLocal)[FindElement].status]
  } else {
    return ColorSelect[0]
  } */
}
export const SearchEditedFields = (id, data = []) => {
/*   const FindElement = data?.findIndex(element => {
    return element?.form_field === id
  })

  return data?.[FindElement]?.form_field_info_before
    ? data?.[FindElement]?.form_field_info_before
    : data?.[FindElement]?.comment */
}

export const FindColor = (search, data) => {
  /*  const FindElement = data.findIndex(element => {
    return element?.form_field === search
  })

  if (data[FindElement] === undefined) {
    return ColorSelect[0]
  } else if (data?.length !== 0 && data[FindElement] !== undefined) {
    return ColorSelect[data[FindElement].status]
  } */
}

export const FindComment = (search, data, status) => {
  /*  if (status === 'DEVUELTO' && data !== undefined) {
    const FindElement = data.findIndex(element => {
      return element?.form_field === search
    })
    if (data[FindElement] === undefined || data[FindElement] === null) {
      return
    } else if (data?.length !== 0 && data[FindElement] !== undefined) {
      return data[FindElement].comment
    }
  } */
}

// Actualizar LocalStore
export const UpdateStore = (Obj, Pos, Value) => {
  /*  let Data = Obj?.map(x => {
    if (x.form_field !== Obj[Pos].form_field) {
      return { ...x }
    } else if (x.form_field === Obj[Pos].form_field) {
      return {
        ...x,
        comment: Value
      }
    }
  })

  return { Data } */
}

// Obtener colores reviews
export const GetColor = (name, data) => {
  if (
    data.status_entryForm === 'DEVUELTO' &&
    data.status_entryForm !== undefined
  ) {
    return FindColor(name, data.reviews)
  } else {
    return FindPos(name, data)
  }
}

// Obtener Comentario reviews
export const GetComment = (name, data) => {
  if (
    data.status_entryForm === 'DEVUELTO' &&
    data.status_entryForm !== undefined
  ) {
    return FindColor(name, data.reviews)
  } else {
    return FindPos(name, data)
  }
}

// Verificar Estado Input para readOnly
export const CheckDisable = (name, status, id) => {
  if (status === 'DEVUELTO') {
    const Data = GetLocalStorage(`${id}D`)

    if (Data !== undefined || Data !== null || Data?.length !== 0) {
      const Check = FindColor(name, Data)
      let Bool

      switch (Check) {
        case 'red':
          Bool = false
          break
        case 'gray':
          Bool = true
          break
        case 'green':
          Bool = true
          break
        default:
          Bool = false
          break
      }
      return Bool
    } else {
      return false
    }
  } else {
    return false
  }
}

// 06/12/2022
export const FindCommentInput = (name, data) => {
/*   if (data?.status_entryForm === 'DEVUELTO') {
    return FindComment(name, data?.reviews, data?.status_entryForm)
  }

  if (data?.status_outputForm === 'DEVUELTO') {
    return FindComment(name, data?.reviews, data?.status_outputForm)
  } */
}

export const getReviewUpdates = (fieldName, formData) => {
/*   let comments = ''
  let color = null

  formData.forEach(
    ({ form_field, form_field_info_before, color: colorValue }) => {
      if (form_field === fieldName) {
        comments = form_field_info_before

        if (colorValue === '1') {
          color = 'red'
        } else if (!color) {
          color = 'purple'
        }
      }
    }
  )

  if (comments && color) {
    return { before: comments, color }
  }

  return null */
}

export const ReviewUpdates = (name, data) => {
/*   const Comment = data
    ?.map(({ form_field, form_field_info_before }) => {
      if (form_field === name) {
        return form_field_info_before
      }
    })
    .filter(value => value !== null && value !== undefined)

  const ColorVerified = data
    ?.map(({ form_field, color }) => {
      if (form_field === name) {
        return color
      }
    })
    .filter(value => value !== null && value !== undefined)

  let Color

  if (ColorVerified?.[0] === '1') {
    Color = 'red'
  }

  if (Comment?.length && ColorVerified?.[0] !== '1') {
    Color = 'purple'
  }

  if (!Comment?.length) {
    Color = ''
  }

  const CommentCurrent = {
    Before: Comment?.length ? Comment : '',
    Color: Color
  }

  if (CommentCurrent?.Before?.length > 0 && CommentCurrent?.Color?.length > 0) {
    return CommentCurrent
  }

  if (
    CommentCurrent?.Before?.length <= 0 &&
    CommentCurrent?.Color?.length <= 0
  ) {
    return null
  } */
}

export const ColorVerified = (name, data) => {
/*   const DataVerified = data?.reviews || []
  let FindElement
  let ColorNum

  if (DataVerified) {
    FindElement = DataVerified.findIndex(element => {
      return element?.form_field === name
    })

    if ((ColorNum = DataVerified[FindElement])) {
      ColorNum = DataVerified[FindElement].status
    }
  }

  switch (ColorNum) {
    case 2:
      return '#D40418'
    case 1:
      return 'green'
    case 0:
      return 'gray'
    default:
      return '#bababa80'
  } */
}
