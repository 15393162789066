import React from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyQualificationU = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.name_qualificationQualifiedUser}</Td>
      <Td className="text-center">
        {data?.description_qualificationQualifiedUser === null
          ? "N/A"
          : data?.description_qualificationQualifiedUser}
      </Td>
      <Tooltip
        placement="top"
        hasArrow
        label={
          data.status_qualificationQualifiedUser === 1
            ? "Desactivar"
            : "Activar"
        }
      >
        <TdState
          // onClick={handleState}
          className="text-center"
          isNumeric
          text={data.status}
        >
          <IconGeneric
            color={
              data.status_qualificationQualifiedUser === 1
                ? "#03C39A"
                : "#bababa"
            }
            as={BsCheckCircleFill}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyQualificationU;
