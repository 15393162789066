import { Box, useDisclosure } from '@chakra-ui/react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { ComponentAccordion } from 'components/AccordionComponent/ComponentAccordion'
import { ComponentAccordionIndividual } from 'components/AccordionComponent/ComponentAccordionIndividual'
import Pagination from 'components/Pagination/Pagination'
import { SearchAdvancedModule } from 'components/SearchSimpleAdvanced/SearchAdvancedModule'
import TableOrder from 'components/Tables/TableOrder'
import environment from 'constants/apiConst'
import GlobalContext, { useGlobalContext } from 'context/GlobalContext'
import { useDebounce } from 'hooks/useDebouce'
import { useForm } from 'hooks/useForm'
import { useSearchUrl } from 'hooks/useSearchUrl'
import {
  HeaderDispatchCorrections,
  TitleDispatchCorrections
} from 'pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader'
import TbodyDispatchCorrections from 'pages/Entry_and_exit_forms/Table/TbodyDispatchCorrections'
import queryString from 'query-string'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { __PostFormData } from 'request/Petitions/__Post'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { Title } from 'styled/Title'

export const DispatchCorrectionsMain = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { search, valueQualified } = state;
  const { setAssociateData, Update, setUpdate } = useContext(GlobalContext);
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  useEffect(() => {
    setAssociateData(null)
  }, [])

  const [allAccess, setAllAccess] = useState(false)
  const [myPermission, setmyPermission] = useState(null)



  const data = useMemo(() => {

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && { qualifiedUser_id: valueQualified.value }),
    }

    return searchInfo
  }, [valueQualified, search]);

  const { token, user_rol, admin, freeZone_id } = useSelector(state => state.auth.dataUser)

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple"] });
    resetPages()
  }

  const { requestData } = useGlobalContext();


  const [page, setPage] = useState(1);
  const [pageEditables, setPageEditables] = useState(1);
  const [pagePresentados, setPagePresentados] = useState(1);
  const [pageAutorizados, setPageAutorizados] = useState(1);

  const resetPages = () => {
    setPage(1);
    setPageEditables(1);
    setPagePresentados(1);
    setPageAutorizados(1);
  }


  const requestMapping = [
    {
      key: "Editables",
      queryKey: ["Editables", pageEditables, freeZone_id, data, movement, Update],
      page: pageEditables,
      setPage: setPageEditables,
      thead: TitleDispatchCorrections,
      tbody: TbodyDispatchCorrections,
    },
    {
      key: "Presentados",
      queryKey: ["Presentados", pagePresentados, freeZone_id, data, movement, Update],
      page: pagePresentados,
      setPage: setPagePresentados,
      thead: TitleDispatchCorrections,
      tbody: TbodyDispatchCorrections,
    },
    {
      key: "Autorizados",
      queryKey: ["Autorizados", pageAutorizados, freeZone_id, data, movement, Update],
      page: pageAutorizados,
      setPage: setPageAutorizados,
      thead: TitleDispatchCorrections,
      tbody: TbodyDispatchCorrections,
    },
  ]


  const { data: dataPases, isFetching } = useQueries({
    /* Acá se valida si no hay una búsqueda se hace la petición con requestMapping para tener todo separado, de lo contrario se hace la petición get normal*/
    queries: Object.entries(data).length === 0 ? requestMapping.map((item) => ({
      queryKey: item.queryKey,
      queryFn: () => requestData({
        Endpoint: environment.GET_CORRECTED_DISPATCH,
        page: item.page,
        method: "POST",
      })
    })) : [{
      queryKey: ["dataPasesSearch", page, freeZone_id, data, movement, Update],
      queryFn: () => requestData({
        Endpoint: environment.GET_CORRECTED_DISPATCH_SEARCH,
        page,
        method: "POST",
        data,
      })
    }],
    /* Hay dos casos, el primero es cuando hay búsqueda, que se apunta a un endpoint diferente y además, este trae solo un resultado data=[{...Resultado de la Búsqueda}]. Por el contrario, cuando no hay búsqueda, este trae todos los resultados en un array de objetos [{...Editables}, {...Prestendados}, {...Autorizados}] los cuales se combinan en un solo objeto  {...Editables, ...Prestendados, ...Autorizados}*/
    combine: (results) => {
      if (Object.entries(data).length !== 0) {
        const [data] = results.map(result => result?.data?.data?.status)
        return {
          data,
          isFetching: results.some((result) => result.isPending),
        }
      }

      return {
        data: results.reduce((acc, result, index) => {
          acc[requestMapping[index].key] = {
            ...requestMapping[index],
            data: result.data?.data?.status?.data?.[index]?.data || [],
            maxPage: result.data?.data?.status?.data?.[index]?.last_page || 1,
            isLoading: result.isFetching,
          }
          return acc
        }, {}),
        isFetching: results.some((result) => result.isPending),
      }
    },
  })



  return (
    <HeaderSearch>
      <Title>Corregidos de despacho</Title>

      <SearchAdvancedModule
        onOpenSearch={() => { }}
        changeViewSearch={Object.entries(data).length !== 0}
        backSearch={backSearch}
        permisoSearch={'getEntryPassesSearch'}
        allAccess={allAccess}
        myPermission={myPermission}
      />

      <Box pt={10}>

        {Object.entries(data).length !== 0 && (
          <>
            <TableOrder
              thead={TitleDispatchCorrections}
              data={dataPases?.data?.data}
              setData={() => { }}
              loading={isFetching}
              tbodyData={TbodyDispatchCorrections}
            />

            <Pagination
              page={page}
              setPage={setPage}
              maxPage={dataPases?.data?.status?.data?.last_page}
              loading={isFetching}
            />
          </>
        )}

        {Object.entries(data).length === 0 && (
          <>
            <ComponentAccordionIndividual
              data={dataPases}
              isLoading={isFetching}
            />
          </>
        )}
      </Box>

    </HeaderSearch>
  )
}
