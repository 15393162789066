import { LabelStyled } from 'components/addProduct/CreateTemplate.styled'
import Select from 'react-select'
import React from 'react'

/**
 * Un componente de selección controlado con opciones y comportamiento personalizables.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {Array} props.options - Las opciones para el select.
 * @param {string} props.label - La etiqueta que se muestra arriba del select.
 * @param {number} props.size - El ancho del componente en porcentaje.
 * @param {Object} props.defaultValue - El valor por defecto seleccionado.
 * @param {string} [props.idStorageProperty=null] - La propiedad utilizada para almacenar el valor de ID.
 * @param {Function} props.handleChange - Función para manejar el cambio de selección.
 * @param {Object} props.rest - Otras propiedades adicionales a pasar al componente.
 * @returns {JSX.Element} El componente de select controlado.
 */

const ControlledSelect = ({
  options,
  label,
  size,
  defaultValue,
  idStorageProperty = null,
  handleChange = null,
  isMulti = false,
  ...rest
}) => {
  const onDataChange = (value, action) => {
    const createChangeEvent = (name, value) => ({
      target: {
        name,
        type: 'text',
        value,
        checked: ''
      }
    })

    if (isMulti && idStorageProperty) {
      const valores = value.map(e => e.value)
      return handleChange(createChangeEvent(idStorageProperty, valores))
    }

    handleChange(createChangeEvent(action?.name, value?.label), '', () => {
      if (idStorageProperty) { handleChange(createChangeEvent(idStorageProperty, value?.value)) }
    })
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{label}</LabelStyled>
      <Select
        value={defaultValue}
        options={options}
        onChange={handleChange && onDataChange} // -> onChange={handleChange ? (value, action) => onDataChange(value, action) : null}
        isMulti={isMulti}
        {...rest}
      />
    </div>
  )
}

export default ControlledSelect
