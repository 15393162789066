import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import InputSelect from 'components/InputSelect/InputSelect'
import environment from 'constants/apiConst'
import { AuthContext } from 'context/AuthContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useDebounce } from 'hooks/useDebouce'
import { useForm } from 'hooks/useForm'
import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import useTransformOptions from 'hooks/useTransformOptions '
import { IconGeneric } from 'styled/Icons.styled'
import { GoSearch } from 'react-icons/go'
import { isArray } from 'utils/type-check-utils'

const TariffChapter = ({
  label = '',
  required = false,
  manualId = null,
  set = null,
  callBack = null,
  placeholder = '',
  onValueChange = () => {},
  mutateConfig = {},
  cleanCallBack = () => {},
  ...attrs
}) => {
  const { requestData } = useGlobalContext()
  const [ProductData, setProductData] = useState({ value: null, label: null })
  const [values, handleInputChange, reset, setValues] = useForm({
    search: ''
  })

  const SearchDebounce = useDebounce(values?.search, 700)
  const [openAlert] = AlertErrorAlert()

  const handleResponseItem = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      return setProductData(res?.data?.status?.data)
    }
  }

  const { isLoading, mutate: MutateProduct } = useMutation({
    mutationFn: requestData,
    onSuccess: data => handleResponseItem(data)
  })

  const searchProduct = search => {
    MutateProduct({
      resourceIdentifier: search,
      Endpoint: environment.CAPITULO_ARANCEL_GET
    })
  }
  const options = useTransformOptions(ProductData, 'name_chapter', 'id')

  const handleInput = inputValue => {
    setValues({ search: inputValue })
  }

  const handleSelectChange = selectedOption => {
    const hasProductData = ProductData || []

    const index =
      isArray(hasProductData) &&
      hasProductData?.findIndex(item => item.id === selectedOption?.value)

    set && set(selectedOption)
    callBack &&
      callBack(hasProductData, selectedOption, hasProductData?.[index])
  }

  useEffect(() => {
    if (SearchDebounce.length >= 2) searchProduct(SearchDebounce)
  }, [SearchDebounce])

  const onChangeManager = (...attrs) => {
    handleSelectChange(...attrs)
    onValueChange(...attrs)
  }

  return (
    <>
      <InputSelect
        components={{
          // IndicatorSeparator: () => null, // Oculta el separador de indicadores
          DropdownIndicator: () => (
            <IconGeneric
              style={{
                margin: '7px'
              }}
              color={'black'}
              width='16px'
              as={GoSearch}
            />
          )
        }}
        required={required}
        tag={label}
        onInputChange={handleInput}
        onChange={onChangeManager}
        data={options}
        isLoading={isLoading}
        cleanCallBack={cleanCallBack}
        noOptionsMessage={() => placeholder}
        {...attrs}
      />
    </>
  )
}

export default TariffChapter
