import React from "react";
import { Title } from "styled/Title";

/**
 *  TitleOperations - Componente de accordion que despliega una serie de items en el componente del Aside
 *
 * @component
 * @param {Number} code Operacion del formulario.
 * @return {Component} Retorna cadena.
 */

export const TitleOperations = ({ code }) => {
  if (code >= 100 && code < 199) {
    // code >= 101 && code < 120
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del mundo
      </Title>
    );
  }
  if (code >= 300 && code <= 399) {
    // code >= 301 && code <= 330
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del territorio
      </Title>
    );
  }
  if (code >= 500 && code <= 599) {
    // code >= 501 && code <= 518
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        De Zona Franca a Zona Franca
      </Title>
    );
  }
  if (code >= 700 && code <= 799) {
    // code >= 701 && code <= 714
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        De Usuario a Usuario de la misma Zona Franca
      </Title>
    );
  }
  return <div></div>;
};
