import { Tooltip } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { BiSelectMultiple } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";

import OrderProductionContext from "context/OrderProductionContext";

export const BtnShowAutorizado = ({ handleChangeStatus, handleCopy, data }) => {
  const { setDataCreateCI, onOpenCreateCIntegration } = useContext(
    OrderProductionContext
  );
  const handleCreateCI = () => {
    console.log(data);
    setDataCreateCI(data);
    onOpenCreateCIntegration();
  };
  return (
    <>
      <Tooltip placement="top" hasArrow label="Certificado de integración">
        <span>
          <ButtonHeaderModule
            onClick={handleCreateCI}
            bgColor="#F9A621"
            className="me-1"
          >
            <IconGeneric
              width="16px"
              className=" text-white"
              as={BiSelectMultiple}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      <Tooltip placement="top" hasArrow label="Copiar">
        <span>
          <ButtonHeaderModule onClick={handleCopy} bgColor="#03C39A">
            <IconGeneric
              width="16px"
              className=" text-white"
              as={MdContentCopy}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </>
  );
};
