import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const FormCorrectionRestTerritory = ({ data, code, dataCorrect }) => {
  const { StateCountry, customsadministration } = useContext(FormCrudCRUDContext);
  const {
    num_dex_entryForm,
    dex_date_entryForm,
    name_department,
    num_import_declaration,

    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    balance_correction,
    customAdministration_id,
    code_custom,
    name_custom,
    code_department,
    code_customCorrected,
    name_customCorrected,
    department_id
  } = data;

  const {
    num_dex_entryFormCorrected,
    dex_date_entryFormCorrected,
    num_import_declaration_corrected,
    integration_box_certificate_corrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected
    /*
    code_customCorrected,
    name_customCorrected,
     */
  } = dataCorrect;

  const adminCuston = code_custom + " " + name_custom;
  const departConcat = `${code_department || ""}${name_department ? (code_department ? " " : "") + name_department : ""}`

  return (
    <>
      <LabelGroup
        name={"searchInformacion"}
        value={num_dex_entryForm}
        tag={"Nº DEX"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_dex_entryForm) !== safelyConvertToString(num_dex_entryFormCorrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={dex_date_entryForm}
        tag={"Fecha del DEX"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(dex_date_entryForm) !== safelyConvertToString(dex_date_entryFormCorrected) &&
          "BorderCorrect"
        }
        tipo="date"
      />
      <LabelGroup
        name={"searchInformacion"}
        value={num_commercial_invoice}
        tag={"Nº Factura Comercial"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_commercial_invoice) !== safelyConvertToString(num_commercial_invoice_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={departConcat}
        tag={"Departamento"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(department_id) !== safelyConvertToString(StateCountry?.value) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={num_import_declaration}
        tag={"Nº Declaración de Importación"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_import_declaration) !== safelyConvertToString(num_import_declaration_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={customAdministration_id ? adminCuston : ""}
        tag={"Administración de Aduanas"}
        size={100}
        desicion={true}
        changeValue={
          adminCuston !== customsadministration?.label &&
          "BorderCorrect"
        }
      />
      {(code === 309 ||
        code === 310 ||
        code === 314 ||
        code === 316 ||
        code === 318) && (
        <>
          <LabelGroup
            name={"searchInformacion"}
            value={num_exit_form}
            tag={"Nº Autorización de salida"}
            size={100}
            desicion={true}
            changeValue={
              safelyConvertToString(num_exit_form) !==
                safelyConvertToString(num_exit_form_corrected) &&
                "BorderCorrect"
            }
          />
          <LabelGroup
            name={"searchInformacion"}
            value={integration_box_certificate}
            tag={"Nº Autorización de C. Inte"}
            size={100}
            desicion={true}
            changeValue={
              safelyConvertToString(integration_box_certificate) !==
                safelyConvertToString(integration_box_certificate_corrected) &&
                "BorderCorrect"
            }
          />
        </>
      )}
    </>
  );
};
