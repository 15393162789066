import { Text, View, StyleSheet, Document } from '@react-pdf/renderer'
import { formaterCant, __formaterCant } from 'components/Funciones/formaterCant'
import { useFormatQuantity } from 'hooks/useFormatQuantity'
import { useEffect, useState } from 'react'

const styles = StyleSheet.create({
  table: {
    fontSize: '6px',
    width: '100%',
  },
  tr: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  tCell: {
    border: '1px solid gray',
    padding: '2px', // Añade este padding según tus necesidades
    width: '100%',
  },
  trHeader: {},
  td: {},
  tdFooter: {},
  header: {},
  footer: {}
})

const Totalizer = ({
  data,
  items,
  form,
  addedValue,
  precision = 0,
  mode = 'entry'
}) => {
  const queryString = window.location.pathname

  const renderTotal = data?.AddValores >= 0 || data?.refundAdd >= 0

  return (
    <>
      <View style={styles?.table}>
        <>
          <View style={styles?.tr}>
            <View
              style={mode === 'entry' && !renderTotal ? styles?.tCell : { ...styles?.tCell, borderRight: 'none' }}
            >
              <Text>Total Valor FOB del documento (incluyendo Anexo):</Text>
              <Text>{__formaterCant(data?.fov ?? 0)}</Text>
            </View>
            {mode !== 'entry' && (
              <View
                style={!renderTotal ? styles?.tCell : { ...styles?.tCell, borderLeft: 'none' }}
              >
                <View>
                  <Text>Total Valor Agregado Nacional (incluyendo Anexo): </Text>
                  <Text>{addedValue ?? 0}</Text>
                </View>
              </View>
            )}
            {renderTotal && (
              <>
                <View style={{ ...styles.tCell, borderLeft: 'none' }}>
                  <Text>Total valor agregado US$:</Text>
                  <Text>{formaterCant(data?.AddValores ?? 0)}</Text>
                </View>
                <View style={{ ...styles.tCell, borderLeft: 'none' }}>
                  <Text>Total valor a reintegrar:</Text>
                  <Text>{formaterCant(data?.refundAdd ?? 0)}</Text>
                </View>
              </>
            )}
          </View>
        </>
      </View>
    </>
  )
}

export default Totalizer
