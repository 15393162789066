import { Td, Tr } from "@chakra-ui/react";
import InventoryControlContext from "context/InventoryControlContext";
import React, { useContext } from "react";

export const TBodySearchRef = ({ data }) => {
  const { setdataReference, onCloseSearchItem } = useContext(
    InventoryControlContext
  );

  const handleSearchItem = () => {
    setdataReference(data);
    onCloseSearchItem();
  };
  return (
    <>
      <Tr onClick={handleSearchItem}>
        <Td className="text-center">{data?.product_code}</Td>
        <Td className="text-center">{data?.inventory_reference_description}</Td>
        <Td className="text-center">{data?.subheading_code}</Td>
        <Td className="text-center">{data?.product_description}</Td>
        <Td className="text-center">
          {/* <span className="formNumber"> */}
          {data?.inventory_integration_certificate}
          {/* </span> */}
        </Td>
        <Td className="text-center">{data?.inventory_import_declaration}</Td>
        <Td className="text-center">{data?.inventory_operation_code}</Td>
        <Td className="text-center">{data?.inventory_initial_operation}</Td>
        <Td className="text-center"> {data?.inventory_trade_agreement}</Td>
        <Td className="text-center"> {data?.inventory_quantity_available}</Td>
        <Td className="text-center">{data?.inventory_quantity_reserved}</Td>
        <Td className="text-center"> {data?.business_unit_code}</Td>
        <Td className="text-center">
          {" "}
          {data?.inventory_quantity_available_product}
        </Td>
        <Td className="text-center"> {data?.inventory_reserved_product}</Td>
        <Td className="text-center"> {data?.product_business_unit_code}</Td>
      </Tr>
    </>
  );
};
