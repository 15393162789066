import { Flex, SimpleGrid, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import InputSelect from "components/InputSelect/InputSelect";
import useTypeTransport from "hooks/useTypeTransport";
import useUserQualified from "hooks/useUserQualified";
import {
  LabelGroup,
  LabelGroupTextArea,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";

/**
 *  FormRegisterPublic - Componente de formulario para registrar ingreso de vehiculo
 *
 * @component
 * @param {Object} props parametros que se reciben para construir el formulario.
 * @return {Component} Retorna componente de formulario.
 */

const FormRegisterPublic = (props) => {
  const { typeTransport } = useTypeTransport();
  const { userQualified } = useUserQualified();
  const [openAlert] = AlertErrorAlert();

  const {
    values,
    onClose,
    onChange,
    vehicleDoor,
    setVehicleDoor,
    dataVehicheDoor,
    handleRegister,
    selectTypeTransport,
    setSelectTypeTransport,
    selectUserQualified,
    setSelectUserQualified,
    setValues,
    CreateDispacth,
    setCreateDispacth,
    title,
    vehiclePlate,
    reset
  } = props;

  if (vehiclePlate && !values.plateVehicleNewRegister) {
    values.plateVehicleNewRegister = vehiclePlate;
  }

  const { identification } = values;

  let alertTimeout = null;

  const handleChange = (e, Limit) => {
    const value = e.target.value;

    clearTimeout(alertTimeout);

    if (value.length > Limit) {
      alertTimeout = setTimeout(() => {
        openAlert(`Máximo ${Limit} valores permitidos`, "error");
      }, 500);
      return;
    }

    if (/[^a-zA-Z0-9]/.test(value)) {
      alertTimeout = setTimeout(() => {
        openAlert("Caracteres especiales no permitidos", "error");
      }, 500);
      return;
    }

    onChange(e);
  };

  const handleCancel = () => {
    reset();
    setVehicleDoor(null);
    setSelectTypeTransport(null);
    setSelectUserQualified(null);
    onClose();
  }

  return (
    <div>
      {title === "Registro de salida" && (
        <SimpleGrid minChildWidth="200px" spacing="10px" my={0}>
          <div className="spac">
            <SwitchPropiosMedios
              status={CreateDispacth}
              setStatus={setCreateDispacth}
              tag={"Crear despacho"}
            />
          </div>
        </SimpleGrid>
      )}
      <SimpleGrid minChildWidth="200px" spacing="10px" my={0}>
        <div className="spac">
          <InputSelect
            value={vehicleDoor}
            set={setVehicleDoor}
            data={dataVehicheDoor}
            tag="Puerta Vehicular *"
          />
        </div>
        <div className="spac">
          <InputSelect
            value={selectTypeTransport}
            set={setSelectTypeTransport}
            data={typeTransport}
            tag="Tipo de transporte"
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <InputSelect
            tag={"Usuario calificado"}
            data={userQualified}
            set={setSelectUserQualified}
            value={selectUserQualified}
          />
        </div>
        <div className="spac">
          <LabelGroup
            size={100}
            name="plateVehicleNewRegister"
            value={values.plateVehicleNewRegister.toUpperCase()}
            onChange={(e) => handleChange(e, 7)}
            tag="Placa"
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroup
            size={100}
            name="name"
            value={values.name.replace(/[^a-zA-Z ]/g, "")}
            onChange={onChange}
            tag="Nombre y apellido"
            maxLength={MaxLength.security.entriesVehicles.driver}
          />
        </div>
        <div className="spac">
          <LabelGroup
            size={100}
            tag="Cédula"
            onChange={(e) => handleChange(e, 10)}
            name="identification"
            value={identification.replace(/[^0-9]/g, "")}
            maxLength={MaxLength.security.entriesVehicles.identification}
          />
        </div>
      </SimpleGrid>

      {selectTypeTransport &&
      (selectTypeTransport?.value === 3 ||
        selectTypeTransport?.value === 8 ||
        selectTypeTransport?.value === 9 ||
        selectTypeTransport?.value === 10 ||
        selectTypeTransport?.value === 11 ||
        selectTypeTransport?.value === 12) ? (
          <div>
            <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
              <div className="spac">
                <LabelGroup
                  size={100}
                  name="container_1"
                  value={values.container_1}
                  onChange={onChange}
                  tag="Contenedor 1"
                // desicion={}
                />
              </div>
              <div className="spac">
                <LabelGroup
                  size={100}
                  tag="Capacidad Cont.1"
                  onChange={onChange}
                  name="capacity_container_1"
                  value={values.capacity_container_1}
                // desicion={}
                />
              </div>
            </SimpleGrid>
            <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
              <div className="spac">
                <LabelGroup
                  size={100}
                  name="container_2"
                  value={values.container_2}
                  onChange={onChange}
                  tag="Contenedor 2"
                // desicion={}
                />
              </div>
              <div className="spac">
                <LabelGroup
                  size={100}
                  tag="Capacidad Cont.2"
                  onChange={onChange}
                  name="capacity_container_2"
                  value={values.capacity_container_2}
                // desicion={}
                />
              </div>
            </SimpleGrid>
          </div>
        ) : (
          ""
        )}
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroupTextArea
            size={100}
            tag="Descripción"
            onChange={onChange}
            name="description"
            value={values.description}
            maxLength={MaxLength.security.entriesVehicles.description}
          />
        </div>
      </SimpleGrid>

      {/* <Button
        onClick={() =>
          toast({
            title: "Account created.",
            description: "We've created your account for you.",
            status: "success",
            duration: 9000,
            isClosable: true,
          })
        }
      >
        Show Toast
      </Button> */}

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={handleCancel}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled onClick={handleRegister}>Registar</ButtonStyled>
      </Flex>
    </div>
  );
};

export default FormRegisterPublic;
