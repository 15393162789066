import { RadioCheck } from 'components/ComponentRadioButton/RadioCheck'
import { formaterCant } from 'components/Funciones/formaterCant'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { LabelGroup } from 'pages/RefProductInter/LabelGroup'
import React, { useContext, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { getFormData } from 'redux/actions/dataFormActions'
import { parseFloat } from 'utils/parseFloat'
import { isString, safelyConvertToString } from 'utils/type-check-utils'

export const ForItemsCorrect = ({
  values,
  handleInputChange,
  suma,
  multiplicacion,
  ...rest
}) => {
  const {
    dataCountryOrigin,
    dataCountryBuy,
    info,
    valueProducts,
    acuerdos,
    packagings,
    valueFlag,
    valueMethod,
    selectCurrency,
    dataToEditItems,
    selectDataTypeProduct,
    dataToCopyItems
  } = useContext(FormCrudCRUDContext)
  const dataForm = useSelector(getFormData)

  const {
    cif_value_sdo,
    cif_cop_value,
    cif_us_value,
    factor_conv,
    product_quantity,
    description_product,
    item_temporary_exit,
    gross_weight_sdo,
    amount_sdo,
    fob_sdo_value,
    description_subheading,
    gross_weight,
    net_weight,
    number_packages,
    quantity,
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    exchange_rate,
    entry_form_item_description,
    coin,
    code_subheading,
    code_businessUnit,
    code_product,
    code_country_proc,
    name_country_proc,
    code_country,
    name_country,
    code_country_purs,
    name_country_purs,
    code_transportMethod,
    name_transportMethod,
    code_country_flag,
    name_country_flag,
    code_packaging,
    name_packaging,
    code_tradeAgreement,
    name_tradeAgreement,
    quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_corrected,
    product_quantity_corrected,
    fob_sdo_value_corrected,
    cif_us_value_corrected,
    gross_weight_sdo_corrected,
    amount_sdo_corrected,
    item_temporary_exit_corrected,
    code_subheading_corrected,
    description_subheading_corrected,
    gross_weight_corrected,
    net_weight_corrected,
    number_packages_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    exchange_rate_corrected,
    description_product_corrected,
    entry_form_item_description_corrected,
    name_typeProduct,
    invoice,
    invoice_corrected,
    brand,
    brand_corrected,
    serial,
    serial_corrected,
    code_unit_measurement,
    code_unit_measurement_corrected,
    national_or_foreign_corrected,
    national_or_foreign,
    cif_cop_value_corrected
  } = values

  const originCountry = `${
    name_country ? (code_country ? code_country + ' ' : '') + name_country : ''
  }`
  const procedenceCountry = `${
    name_country_proc
      ? (code_country_proc ? code_country_proc + ' ' : '') + name_country_proc
      : ''
  }`
  const purchaseCountry = `${
    name_country_purs
      ? (code_country_purs ? code_country_purs + ' ' : '') + name_country_purs
      : ''
  }`
  const transportMethod = `${
    name_transportMethod
      ? (code_transportMethod ? code_transportMethod + ' ' : '') +
        name_transportMethod
      : ''
  }`
  const flag = `${
    name_country_flag
      ? (code_country_flag ? code_country_flag + ' ' : '') + name_country_flag
      : ''
  }`
  const packaging = `${
    name_packaging
      ? (code_packaging ? code_packaging + ' ' : '') + name_packaging
      : ''
  }`
  const tradeAgreement = `${
    name_tradeAgreement
      ? (code_tradeAgreement ? code_tradeAgreement + ' ' : '') +
        name_tradeAgreement
      : ''
  }`

  const resultadCif = cif_us_value && parseFloat(cif_us_value)
  /*
  useEffect(() => {

    const checkCifCopValue =
      multiplicacion &&
      parseFloat(cif_cop_value) !== parseFloat(multiplicacion);

    const checkCifCopValueCorrected =
      cif_cop_value_corrected &&
      parseFloat(cif_cop_value_corrected) !== parseFloat(cif_cop_value);

    console.log(
      "multiplicacion",
      cif_cop_value_corrected,
      cif_cop_value
    );
  }, [multiplicacion, cif_cop_value]);
 */
  return (
    <>
      {/* valueProducts */}
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_product) !==
            safelyConvertToString(valueProducts?.label) && 'BorderCorrect'
        }
        name={'code_product'}
        onChange={handleInputChange}
        value={code_product}
        tag={'Código producto'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          name_typeProduct !== selectDataTypeProduct?.label && 'BorderCorrect'
        }
        name={'name_typeProduct'}
        onChange={handleInputChange}
        value={name_typeProduct}
        tag={'Tipo de producto'}
        size={100}
        desicion={true}
      />

      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator='.'
        customInput={LabelGroup}
        name={'factor_conv'}
        changeValue={
          parseFloat(factor_conv) !== parseFloat(factor_conv_corrected) &&
          'BorderCorrect'
        }
        onChange={handleInputChange}
        tag={'Factor Conv.'}
        size={100}
        desicion={true}
        value={isString(factor_conv) ? parseFloat(factor_conv) : factor_conv}
      />

      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_unit_measurement_corrected) !==
            safelyConvertToString(code_unit_measurement) && 'BorderCorrect'
        }
        name={'code_businessUnit'}
        onChange={handleInputChange}
        value={code_unit_measurement ?? ''}
        tag={'Unidad de medida'}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(product_quantity) !==
            parseFloat(product_quantity_corrected) && 'BorderCorrect'
        }
        name={'product_quantity'}
        onChange={handleInputChange}
        value={product_quantity}
        tag={'Cantidad producto'}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(quantity) !== parseFloat(quantity_corrected) &&
          'BorderCorrect'
        }
        onChange={handleInputChange}
        name={'quantity'}
        value={quantity}
        tag={'Cantidad'}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          quantity !== quantity_corrected &&
          "BorderCorrect"
        }
        onChange={handleInputChange}
        name={"quantity"}
        value={quantity ? quantity : ""}
        tag={"Cantidad"}
        size={100}
        desicion={true}
      /> */}
      {/*    <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          fob_sdo_value !== fob_sdo_value_corrected &&
          "BorderCorrect"
        }
        name={"fob_sdo_value"}
        value={fob_sdo_value}
        tag={"Valor FOB Sdo."}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          cif_value_sdo !== cif_us_value_corrected &&
          "BorderCorrect"
        }
        name={"cif_value_sdo"}
        value={cif_value_sdo}
        tag={"Valor CIF Sdo."}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          gross_weight_sdo !== gross_weight_sdo_corrected &&
          "BorderCorrect"
        }
        name={"gross_weight_sdo"}
        value={gross_weight_sdo}
        tag={"Peso bruto Sdo."}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          amount_sdo !== amount_sdo_corrected &&
          "BorderCorrect"
        }
        name={"amount_sdo"}
        value={amount_sdo}
        tag={"Cantidad Sdo."}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(item_temporary_exit) !==
            safelyConvertToString(item_temporary_exit_corrected) &&
          'BorderCorrect'
        }
        name={'item_temporary_exit'}
        value={item_temporary_exit || ''}
        onChange={handleInputChange}
        tag={'Item de Salida Temporal'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_businessUnit) !==
            safelyConvertToString(code_businessUnit_corrected) &&
          'BorderCorrect'
        }
        name={'code_businessUnit'}
        onChange={handleInputChange}
        value={code_businessUnit ?? ''}
        tag={'Unidad comercial'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_subheading) !==
            safelyConvertToString(code_subheading_corrected) && 'BorderCorrect'
        }
        name={'code_subheading'}
        onChange={handleInputChange}
        value={code_subheading || ''}
        tag={'Subpartida arancelaria'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(description_subheading) !==
            safelyConvertToString(description_subheading_corrected) &&
          'BorderCorrect'
        }
        name={'description_subheading'}
        onChange={handleInputChange}
        value={description_subheading || ''}
        tag={'Descripción de la Subpartida'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(originCountry) !==
            safelyConvertToString(info?.label) && 'BorderCorrect'
        }
        onChange={handleInputChange}
        tag={'País origen'}
        size={100}
        desicion={true}
        name={'code_country'}
        value={originCountry}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(procedenceCountry) !==
            safelyConvertToString(dataCountryOrigin?.label) && 'BorderCorrect'
        }
        name={'code_country_proc'}
        value={procedenceCountry}
        onChange={handleInputChange}
        tag={'País procedencia'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(purchaseCountry) !==
            safelyConvertToString(dataCountryBuy?.label) && 'BorderCorrect'
        }
        name={'code_country_purs'}
        onChange={handleInputChange}
        value={purchaseCountry}
        tag={'País compra'}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(gross_weight) !== parseFloat(gross_weight_corrected) &&
          'BorderCorrect'
        }
        onChange={handleInputChange}
        name={'gross_weight'}
        value={gross_weight}
        tag={'Peso bruto'}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          gross_weight !== gross_weight_corrected &&
          "BorderCorrect"
        }
        onChange={handleInputChange}
        name={"gross_weight"}
        value={gross_weight}
        tag={"Peso bruto"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(net_weight) !== parseFloat(net_weight_corrected) &&
          'BorderCorrect'
        }
        onChange={handleInputChange}
        name={'net_weight'}
        value={net_weight}
        tag={'Peso neto'}
        size={100}
        desicion={true}
      />
      {/*       <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          net_weight !== net_weight_corrected &&
          "BorderCorrect"
        }
        name={"net_weight"}
        onChange={handleInputChange}
        value={net_weight}
        tag={"Peso neto"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(number_packages) !==
            parseFloat(number_packages_corrected) && 'BorderCorrect'
        }
        onChange={handleInputChange}
        name={'number_packages'}
        value={number_packages}
        tag={'Números de bultos'}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          number_packages !== number_packages_corrected &&
          "BorderCorrect"
        }
        onChange={handleInputChange}
        name={"number_packages"}
        value={number_packages}
        tag={"Números de bultos"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(transportMethod) !==
            safelyConvertToString(valueMethod?.label) && 'BorderCorrect'
        }
        name={'code_transportMethod'}
        onChange={handleInputChange}
        value={transportMethod}
        tag={'Método de transporte'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(flag) !==
            safelyConvertToString(valueFlag?.label) && 'BorderCorrect'
        }
        name={'searchInformacion'}
        onChange={handleInputChange}
        value={flag}
        tag={'Bandera'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(packaging) !==
            safelyConvertToString(packagings?.label) && 'BorderCorrect'
        }
        name={'code_packaging'}
        onChange={handleInputChange}
        value={packaging}
        tag={'Embalaje'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          coin !== selectCurrency && 'BorderCorrect'
        }
        name={'coin'}
        value={coin}
        tag={'USD/COP'}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_freight) !== parseFloat(us_freight_corrected) &&
          'BorderCorrect'
        }
        name={'us_freight'}
        value={us_freight}
        tag={` Fletes ${coin === null || coin === '' ? '' : coin} `}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          us_freight !== us_freight_corrected &&
          "BorderCorrect"
        }
        name={"us_freight"}
        value={us_freight}
        tag={` Fletes ${coin === null || coin === "" ? "" : coin} `}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_insurance) !== parseFloat(us_insurance_corrected) &&
          'BorderCorrect'
        }
        name={'us_insurance'}
        value={us_insurance}
        tag={`Seguros ${coin === null || coin === '' ? '' : coin} `}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          us_insurance !== us_insurance_corrected &&
          "BorderCorrect"
        }
        name={"us_insurance"}
        value={us_insurance}
        tag={` Seguros ${coin === null || coin === "" ? "" : coin} `}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems && */
          /* dataToCopyItems && */
          parseFloat(other_us_expenses) !==
            parseFloat(other_us_expenses_corrected) && 'BorderCorrect'
        }
        name={'other_us_expenses'}
        value={other_us_expenses}
        tag={`Otros gastos ${coin === null || coin === '' ? '' : coin}`}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          other_us_expenses !== other_us_expenses_corrected &&
          "BorderCorrect"
        }
        name={"other_us_expenses"}
        value={other_us_expenses}
        tag={`Otros gastos ${coin === null || coin === "" ? "" : coin}`}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_fob_value) !== parseFloat(us_fob_value_corrected) &&
          'BorderCorrect'
        }
        name={'us_fob_value'}
        value={us_fob_value}
        tag={` Valor FOB ${coin === null || coin === '' ? '' : coin} `}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          us_fob_value !== us_fob_value_corrected &&
          "BorderCorrect"
        }
        name={"us_fob_value"}
        value={us_fob_value}
        tag={` Valor FOB ${coin === null || coin === "" ? "" : coin} `}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(resultadCif) !== parseFloat(suma) && 'BorderCorrect'
        }
        name={'cif_us_value'}
        value={resultadCif}
        tag={`Valor CIF ${coin === null || coin === '' ? '' : coin}`}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          resultadCif !== suma &&
          "BorderCorrect"
        }
        name={"cif_us_value"}
        value={resultadCif}
        tag={`Valor CIF ${coin === null || coin === "" ? "" : coin}`}
        size={100}
        desicion={true}
      /> */}
      {coin && coin !== 'COP' ? (
        <NumericFormat
          allowNegative={false}
          thousandSeparator={true}
          /* decimalScale={decimalScale} */
          decimalSeparator='.'
          customInput={LabelGroup}
          changeValue={
            /* dataToEditItems &&
            dataToCopyItems && */
            ((multiplicacion &&
              parseFloat(cif_cop_value) !== parseFloat(multiplicacion)) ||
              (cif_cop_value_corrected &&
                parseFloat(cif_cop_value_corrected) !==
                  parseFloat(cif_cop_value))) &&
            'BorderCorrect'
          }
          name={'cif_cop_value'}
          value={cif_cop_value}
          tag={`Valor CIF COP `}
          size={100}
          desicion={true}
          readOnly
        />
      ) : (
        ''
      )}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        /* decimalScale={decimalScale} */
        decimalSeparator='.'
        customInput={LabelGroup}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(exchange_rate) !== parseFloat(exchange_rate_corrected) &&
          'BorderCorrect'
        }
        name={'exchange_rate'}
        onChange={handleInputChange}
        value={exchange_rate}
        tag={'Tasa de Cambio'}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          exchange_rate !== exchange_rate_corrected &&
          "BorderCorrect"
        }
        name={"exchange_rate"}
        onChange={handleInputChange}
        value={exchange_rate}
        tag={"Tasa de Cambio"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(tradeAgreement) !==
            safelyConvertToString(acuerdos?.label) && 'BorderCorrect'
        }
        name={'code_tradeAgreement'}
        onChange={handleInputChange}
        value={tradeAgreement}
        tag={'Acuerdo Comercial'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(invoice) !==
            safelyConvertToString(invoice_corrected) && 'BorderCorrect'
        }
        name={'invoice'}
        value={invoice}
        tag={'Factura'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(brand) !==
            safelyConvertToString(brand_corrected) && 'BorderCorrect'
        }
        name={'brand'}
        value={brand}
        tag={'Marca'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(serial) !==
            safelyConvertToString(serial_corrected) && 'BorderCorrect'
        }
        name={'serial'}
        value={serial}
        tag={'Serial'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(description_product) !==
            safelyConvertToString(description_product_corrected) &&
          'BorderCorrect'
        }
        name={'description_product'}
        onChange={handleInputChange}
        value={description_product}
        tag={'Descripción producto'}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(entry_form_item_description) !==
            safelyConvertToString(entry_form_item_description_corrected) &&
          'BorderCorrect'
        }
        name={'entry_form_item_description'}
        onChange={handleInputChange}
        value={entry_form_item_description}
        tag={'Descripción larga'}
        size={100}
        desicion={true}
      />
      {((dataForm?.code_operation >= 500 && dataForm?.code_operation < 600) ||
        (rest?.selectOperation?.value >= 500 &&
          rest?.selectOperation?.value < 600)) && (
        <div className='mt-1'>
          <RadioCheck
            name='national_or_foreign'
            /* onChange={handleInputChange} */
            value={national_or_foreign}
            item={['Nacional', 'Extranjera']}
            diff={national_or_foreign !== national_or_foreign_corrected}
            disables
          />
        </div>
      )}
    </>
  )
}
