export const cabecera = ['Editables', 'Presentados', 'Autorizados']
export const cabeceraCO = ['Revisado', 'Presentados']

export const TitleMovementInt = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº entrada', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Cant. Items', order: 'null' },
  // { name: "Peso", order: null },
  // { name: "Seguimiento", order: null },
  // { name: "Codigo de seguridad", order: null },
  { name: 'Acciones', order: null }
]

export const TitleMovementIntPreAut = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº entrada', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Seguridad', order: 'null' },
  { name: 'Cant. items', order: 'null' },
  // { name: "Peso", order: null },
  // { name: "Seguimiento", order: null },
  // { name: "Codigo de seguridad", order: null },
  { name: 'Acciones', order: null }
]

export const TitleMovementOutPreAut = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº Despacho', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Seguridad', order: 'null' },
  { name: 'Cant. items', order: 'null' },
  // { name: "Peso", order: null },
  // { name: "Seguimiento", order: null },
  // { name: "Codigo de seguridad", order: null },
  { name: 'Acciones', order: null }
]

export const TitleMovementIngreso = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº Entrada', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Peso Neto', order: 'null' },
  { name: 'Contenedores', order: 'null' },
  { name: 'TIQUETE DE BÁSCULA', order: 'null' }
]

export const TitleMovementIntSinAcciones = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº despacho', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Peso Neto', order: 'null' },
  { name: 'Contenedores', order: 'null' },
  { name: 'TIQUETE DE BÁSCULA', order: 'null' }
]
export const TitleMovementIntUC = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Nº entrada', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' }
  // { name: "Peso", order: null },
  // { name: "Seguimiento", order: null },
  // { name: "Codigo de seguridad", order: null },
  // { name: "Acciones", order: null },
]

export const TitleMovementDispatch = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Despacho', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de Vehículo', order: 'null' },
  { name: 'Numero de bultos', order: 'null' },
  { name: 'Cant. Items', order: 'null' },
  // { name: "Peso", order: null },
  // { name: "Seguimiento", order: null },
  // { name: "Codigo de seguridad", order: null },
  { name: 'Acciones', order: 'null' }
]
export const TitleT = [
  { name: 'Nº Ingreso', order: 'null' },
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Tipo De Operacion', order: 'null' },
  { name: 'Doc. Transporte', order: 'null' },
  { name: 'Estado', order: 'null' }
  // { name: "Acciones", order: null },
]

export const theadinfoFmm = [
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'CÓDIGO', order: 'code_operation' },
  { name: 'FACTURA COMERCIAL', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' }
]

export const theadTablaDispacth = [
  { name: 'Salida', order: 'code_entryForm' },
  { name: 'Item', order: 'code_operation' },
  { name: 'Referencia', order: 'num_commercial_invoice' },
  { name: 'Declaración de importación', order: 'custom_clearance' },
  { name: 'Cod. Producto', order: 'status_entryForm' },
  { name: 'Descripción', order: 'status_entryForm' },
  { name: 'Unidad', order: 'status_entryForm' },
  { name: 'Saldo', order: 'status_entryForm' },
  { name: 'Und. Producto', order: 'status_entryForm' },
  { name: 'Saldo producto', order: 'status_entryForm' }
]

export const TitleTablePass = [
  { name: 'Item', order: 'item_num' },
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'Cod. Operacion', order: 'code_operation' },
  { name: 'Factura Comercial', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' },
  { name: 'Acciones', order: null }
]

export const TitleTablePassIsCommerce = [
  { name: 'Item', order: 'item_num' },
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'Cod. Operacion', order: 'code_operation' },
  { name: 'Factura Comercial', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' },
  { name: 'Seguimiento', order: 'tracking' },
  { name: 'Acciones', order: null }
]

export const TitleTablePassShow = [
  { name: 'Item', order: 'item_num' },
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'Cod. Operacion', order: 'code_operation' },
  { name: 'Factura Comercial', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' }
]

export const TitleTablePassShowIsCommerce = [
  { name: 'Item', order: 'item_num' },
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'Cod. Operacion', order: 'code_operation' },
  { name: 'Factura Comercial', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' },
  { name: 'Detalles', order: 'item_detail' },
  { name: 'Seguimiento', order: 'tracking' }
]

export const TitleTablePassDispacth = [
  { name: 'Item', order: 'item' },
  { name: 'Nº Formulario', order: '' },
  { name: 'Cod. Operacion', order: '' },
  { name: 'I. S.', order: '' },
  { name: 'Referencia', order: '' },
  { name: 'Cod. Producto', order: '' },
  { name: 'Descripción', order: '' },
  { name: 'Subpartida', order: '' },
  { name: 'Descripción de ítem', order: '' },
  { name: 'Saldo actual', order: '' },
  { name: 'Cantidad despachada', order: '' },
  { name: 'Und.', order: '' },
  { name: 'Saldo prod. Actual', order: '' },
  { name: 'Cant. Prod. Despachado', order: '' },
  { name: 'Und. Producto', order: '' },
  { name: 'Acciones', order: '' }
]
export const TitleTablePassDispacthIsCommerce = [
  { name: 'Item', order: 'item' },
  { name: 'Nº Formulario', order: '' },
  { name: 'Cod. Operacion', order: '' },
  { name: 'I. S.', order: '' },
  { name: 'Referencia', order: '' },
  { name: 'Cod. Producto', order: '' },
  { name: 'Descripción', order: '' },
  { name: 'Subpartida', order: '' },
  { name: 'Descripción de ítem', order: '' },
  { name: 'Saldo actual', order: '' },
  { name: 'Cantidad despachada', order: '' },
  { name: 'Und.', order: '' },
  { name: 'Saldo prod. Actual', order: '' },
  { name: 'Cant. Prod. Despachado', order: '' },
  { name: 'Und. Producto', order: '' },
  { name: 'Seguimiento', order: 'tacking' },
  { name: 'Acciones', order: '' }
]

export const TitleTablePassDispacthShow = [
  { name: 'Item', order: '' },
  { name: 'Nº Formulario', order: '' },
  { name: 'Cod. Operacion', order: '' },
  { name: 'I. S.', order: '' },
  { name: 'Referencia', order: '' },
  { name: 'Cod. Producto', order: '' },
  { name: 'Descripción', order: '' },
  { name: 'Saldo actual', order: '' },
  { name: 'Cantidad despachada', order: '' },
  { name: 'Descripción de ítem', order: '' },
  { name: 'Und.', order: '' },
  { name: 'Saldo prod. Actual', order: '' },
  { name: 'Cant. Prod. Despachado', order: '' },
  { name: 'Und. Producto', order: '' }
]

export const TitleTablePassDispacthShowIsCommerce = [
  { name: 'Item', order: '' },
  { name: 'Nº Formulario', order: '' },
  { name: 'Cod. Operacion', order: '' },
  { name: 'I. S.', order: '' },
  { name: 'Referencia', order: '' },
  { name: 'Cod. Producto', order: '' },
  { name: 'Descripción', order: '' },
  { name: 'Saldo actual', order: '' },
  { name: 'Cantidad despachada', order: '' },
  { name: 'Descripción de ítem', order: '' },
  { name: 'Und.', order: '' },
  { name: 'Saldo prod. Actual', order: '' },
  { name: 'Cant. Prod. Despachado', order: '' },
  { name: 'Und. Producto', order: '' },
  { name: 'Seguimiento', order: 'tracking' }
]

export const TitleTablePassNoaccion = [
  { name: 'Item', order: 'item_num' },
  { name: 'Nº Formulario', order: 'code_entryForm' },
  { name: 'Cod. Operacion', order: 'code_operation' },
  { name: 'Factura Comercial', order: 'num_commercial_invoice' },
  { name: 'Estado', order: 'status_entryForm' }
]

export const TitleTableConductorId = [
  { name: 'Placa vehículo', order: 'code_entryForm' },
  { name: 'Nombre', order: 'num_commercial_invoice' },
  { name: 'teléfono', order: 'code_operation' },
  { name: 'Tipo transporte', order: 'name_typeTransport' }
]

export const TitleTableConductorPlate = [
  { name: 'Placa vehículo', order: '' },
  { name: 'Identificación', order: 'identification' },
  { name: 'Nombre', order: 'num_commercial_invoice' },
  { name: 'teléfono', order: 'code_operation' },
  { name: 'Tipo transporte', order: 'name_typeTransport' }
]
