import { Flex, SimpleGrid, Spacer } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { ButtonStyled } from "styled/Buttons.styled";

const typeOfSanctions = [
  {
    label: "Permanente",
    value: "Permanente"
  },
  {
    label: "Temporal",
    value: "Temporal"
  }
];

const typeBlock = [
  {
    label: "Identificación",
    value: "identification"
  },
  {
    label: "Placa",
    value: "plate"
  }
];

/**
 *  FormRegisterBlockages - Componente de formulario para realizar la creación de un registro de bloqueo
 * @component
 * @param {Object} props parametros que se reciben para construir el formulario.
 * @return {Component} Retorna componente de formulario
 */

export const FormRegisterBlockages = (props) => {
  const {
    values,
    onClose,
    onChange,
    typeOfSanction,
    selectTypeBlock,
    setSelectTypeBlock,
    setSelectTypeOfSanction,
    handleRegisterBlockages
  } = props;

  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      handleRegisterBlockages();
    }}>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <InputSelect
            required={!typeOfSanction}
            data={typeOfSanctions}
            value={typeOfSanction}
            set={setSelectTypeOfSanction}
            tag="Tipo de sanción"
            error={!typeOfSanction}

          />
        </div>
        <div className="spac">
          <InputSelect
            required={!selectTypeBlock}
            data={typeBlock}
            value={selectTypeBlock}
            set={setSelectTypeBlock}
            tag="Tipo de Bloqueo"
            error={!selectTypeBlock}
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroup
            size={100}
            name="name"
            value={values.name.replace(/[^a-zA-Z0-9ñÑ\s]/g, "")}
            onChange={onChange}
            tag="Nombre y apellido"
            desicion={selectTypeBlock?.value === "plate"}
          />
        </div>
        <div className="spac">
          <LabelGroup
            required={selectTypeBlock?.value === "identification"}
            size={100}
            tag="Cédula"
            onChange={onChange}
            name="identification"
            value={values.identification.replace(/[^0-9\s]/g, "")}
            desicion={selectTypeBlock?.value === "plate"}
            maxLength={12}
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <div className="spac">
          <LabelGroup
            required
            size={100}
            tag="Placa"
            name="plate"
            value={values.plate.replace(/[^a-zA-Z0-9ñÑ\s]/g, "").toUpperCase()}
            onChange={onChange}
            maxLength={7}
          />
        </div>
        <div className="spac">
          <LabelGroup
            size={100}
            required={true}
            tag="Descripción"
            name="description"
            onChange={onChange}
            value={values.description.replace(/[^a-zA-Z0-9ñÑ\s]/g, "")}
          />
        </div>
      </SimpleGrid>

      <Flex className="">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled type='submit'>Registar</ButtonStyled>
      </Flex>
    </form>
  );
};
