import axios from 'axios'
import env from '../../constants/apiConst'

export const __DocumentRequire = async (token, page, body) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    },
    data: body || ''
  }

  const res = await axios(
    `${env.DOCUMENT_REQUIRED_GET}?page=${page || 1}`,
    options
  )
  return res
}

export const __RequiredDocumentView = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.DOCUMENT_REQUIRED_SHOW}/${id}`, options)
  return res
}

export const __DocumentNoPaginate = async token => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.DOCUMENT_REQUIRED_ALL}`, options)
  return res
}

// ACTIVAR O DESACTIVAR DOCUMENTO DESDE LA TABLA

export const __Active_Required_Document = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.DOCUMENT_REQUIRED_ACTIVE}/${id}`, options)
  return res
}

export const __Desactive_Required_Document = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.DOCUMENT_REQUIRED_DISABLE}/${id}`, options)
  return res
}

// Asignar documento a una operacion

export const __AddDocument = async (token, body) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body)
  }
  const res = await axios(`${env.DOCUMENT_ADD_USERCA}`, options)
  return res
}

export const __RemoveDocument = async (token, body) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body)
  }
  const res = await axios(`${env.DOCUMENT_REMOVE_USERCA}`, options)
  return res
}

export const __GetOperationDocument = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.OPREATION_DOCUMENT}/${id}`, options)
  return res
}

export const __DocumentTypeNoPaginate = async token => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }

  const res = await axios(`${env.DOCUMENT_REQUIRED_TYPE_ALL}`, options)
  return res
}
