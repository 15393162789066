import { Box, Grid, SimpleGrid } from "@chakra-ui/react";
import { formatedInput } from "components/Funciones/FunctionFormateInput";
import InputSelect from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import TreasuryCrudContex from "context/TreasuryContex";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __DocumentTypeNoPaginate } from "request/configuration/__DocumentRequire";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __GetDataNoPage } from "request/Petitions/__Get";
import { ButtonStyled } from "styled/Buttons.styled";
import environment from "../../../constants/apiConst";
import env from "../../../constants/apiConst";
import { __ZonasFranca } from "../../../request/configuration/__ZonasFranca";
import "./StyleRates.css";
import { NumericFormat } from "react-number-format";

const FormUpdateRate = ({ onClose }) => {
  const [tarifValue, setTarifValue] = useState(null);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [dataQualified, setdataQuialified] = useState([]);
  const [dataZona, setDataZona] = useState(null);
  const [dataIntpus, setDataInputs] = useState([]);
  const [dataInputsValues, setDataInputsValues] = useState([]);
  const [dataTarif, setDataTarif] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [typeZone, setTypeZone] = useState(null);

  const [values, handleInputChange, reset, setValues] = useForm({
    date_rate: ""
  });

  const { date_rate } = values;

  const { setRequestCreate, createData } = useContext(TreasuryCrudContex);

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const dataUser = res?.data?.status?.data.map((items) => ({
        value: items.id,
        label: items?.nit_qualified + " - " + items?.name_qualified
      }));
      setdataQuialified(dataUser);
    } catch (error) { }
  }, [token]);

  const getInputDocuments = useCallback(async () => {
    try {
      const res = await __DocumentTypeNoPaginate(token);
      const dataUser = res?.data?.status?.data_set;
      // console.log(res?.data?.status?.data?.data);
      setDataInputs(dataUser);
    } catch (error) { }
  }, [token]);

  const getTypeZone = useCallback(async () => {

    try {
      const res = await __ZonasFranca(token);
      const zones = res?.data?.status?.data.map((item) => ({
        label: item?.name_freeZone,
        value: item?.id
      }));
      setDataZona(zones);
    } catch (error) { }
  }, [token]);

  const getTypesTarif = useCallback(async () => {
    try {
      const res = await __GetDataNoPage(
        environment.QUALIFIED_USERS_TYPE_TARIFF,
        token
      );
      const dataInput = res.data.status.data.map((item) => ({
        value: item.id,
        label: item.name_type_rate
      }));
      setDataTarif(dataInput);
    } catch (e) {
      console.log(e);
    }
  }, [token]);

  useEffect(() => {
    if (tarifValue !== null && tarifValue.value === 3) {
      getUserQualified();
    }
  }, [getUserQualified, tarifValue]);

  useEffect(() => {
    getTypeZone();
    getInputDocuments();
    getTypesTarif();
  }, [getInputDocuments, getTypeZone, getTypesTarif]);

  useEffect(() => {
    setRequestCreate(env.TREASURY_UPDATE_RATE_CREATE);
  }, [setRequestCreate]);

  const handleOnchange = (event, id, name) => {
    setDataInputsValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        value: event?.value,
      }
    }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataInputsFormted = Object.entries(dataInputsValues)?.map(([_, value]) => ({
      ...value,
    }))

    const requestData = {
      date_rate,
      typeRate_id: tarifValue !== null ? tarifValue.value : "",
      qualifiedUser_id: userValue !== null ? userValue.value : "",
      rates: dataInputsFormted,
      freeZone_id: authUser?.admin ? typeZone?.value : dataZona[0]?.value || ""
    };
    createData(requestData);
    handleReset();
    onClose();
  };

  const handleReset = () => {
    reset();
    setDataInputsValues([]);
    setTarifValue(null);
  };

  const constraintDates = {
    min: () => {
      const hoy_fecha = new Date();
      return hoy_fecha.toISOString().substring(0, 10);
    },
    max: () => {
      const hoy_fecha = new Date();
      if (hoy_fecha.getMonth() === 11) {
        return new Date(hoy_fecha.getFullYear() + 1, 11, 31)
          .toISOString()
          .substring(0, 10);
      }
      return new Date(hoy_fecha.getFullYear(), 11, 31)
        .toISOString()
        .substring(0, 10);
    }
  };

  useEffect(() => {
    const handleSetNoCharge = (val) => {
      const newArray = {};
      Object.entries(dataInputsValues).forEach(([key, value], index) => {
        newArray[key] = {
          ...value,
          value: val
        }
      })
      setDataInputsValues(newArray);
    };

    tarifValue?.value === 4 ? handleSetNoCharge("0") : handleSetNoCharge("");
  }, [dataIntpus, tarifValue]);

  useEffect(() => {
    const newArray = {};
    dataIntpus && Object.entries(dataIntpus)?.forEach(([key, value], index) => {
      value.forEach((item, itemi) => {
        if (!item?.id) return
        const nameKey = item?.document?.includes("Anexo") ? "annexe" : "document_type_id";
        newArray[item?.id] = {
          [nameKey]: item?.id,
          value: ""
        }
      })
    })
    setDataInputsValues(newArray);
  }, [dataIntpus]);


  const calculateFields = () => {
    const data = dataIntpus && Object.entries(dataIntpus)?.map(([key, value], index) => {
      return <>
        {value.map((item, itemi) => {
          if (!item?.id) return <div></div>
          return (
            <NumericFormat
              customInput={LabelGroup}
              key={item?.id}
              allowNegative={false}
              decimalScale={0}
              thousandSeparator={","}
              tipo={"text"}
              id={item?.id}
              name={item?.document}
              tag={item?.document}
              value={dataInputsValues[item?.id]?.value}
              onValueChange={(e) => {
                handleOnchange(e, item?.id, item?.document)
              }}
              desicion={false}
              required={true}
            />
          )
        })}
      </>
    })
    return data;

  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        templateColumns="repeat(2, 2fr)"
        gap={6}
        spacing="10px"
        className="container"
      >
        <LabelGroup
          tipo={"date"}
          name={"date_rate"}
          tag={"Fecha"}
          size={100}
          value={date_rate}
          onChange={handleInputChange}
          desicion={false}
          max={constraintDates.max()}
          min={constraintDates.min()}
          required={true}
        />
        {authUser.admin === 1 && (
          <InputSelect
            tag={"Zona Franca"}
            size={100}
            data={dataZona}
            set={setTypeZone}
            value={typeZone}
            required={true}
          />
        )}

        <InputSelect
          tag={"Tipo de tarifa"}
          size={100}
          data={dataTarif}
          set={setTarifValue}
          value={tarifValue}
          required={true}
        />

        {tarifValue !== null && tarifValue.value === 3 && (
          <>
            <InputSelect
              tag={"Usuario"}
              size={100}
              data={dataQualified}
              set={setUserValue}
              value={userValue}
            />
            <div></div>
          </>
        )}
        {/* Aquí se renderizan los inputs de los formularios + anexos */}
        {calculateFields()}
        {/* {dataIntpus &&
          dataIntpus.map((data, index) => (
            <LabelGroup
              key={index}
              tipo={"text"}
              id={data?.document}
              name={data?.id}
              tag={data?.document}
              size={95}
              value={dataInputsValues[index]?.value}
              onChange={(e) => {
                handleOnchange(e, index);
              }}
              desicion={false}
              required={true}
            />
          ))} */}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        className="container"
        mt={6}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled type="submit" mr={"18px"}>
          Aceptar
        </ButtonStyled>
      </Box>
    </form>
  );
};

export default FormUpdateRate;
