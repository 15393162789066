/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import TitleReason from "./Header/TitleReason";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { __ReasonReturn } from "request/ReturnReason/__ReturnReason";
import { Tbody } from "./TBody/Tbody";
import { ModalGeneric } from "components/modal/ModalGeneric";
import ReturnReasonContext from "context/ReturnReasonContext";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { FormSearchAdvanced } from "./FormSearchAdvanced";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";

export const ReturnReason = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["nickname", "type_doc"], nestedProp: "searchAdvance" });

  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [maxPage, setMaxPage] = useState(1);
  const [resData, setResData] = useState([]);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const thead = [
    // { name: "UID", order: "" },
    { name: "Zona franca", order: "" },
    { name: "Cliente", order: "" },
    { name: "Tipo de documento", order: "" },
    { name: "Código Doc.", order: "" },
    { name: "Usuario", order: "" },
    { name: "Timestamp", order: "" },
    { name: "Motivo", order: "" },
    { name: "Comentario", order: "" }
  ];

  const {
    isOpenListReason,
    onOpenListReason,
    onCloseListReason,
    ListReturnReason,
    isOpenShowComentary,
    onOpenShowComentary,
    onCloseShowComentary
  } = useContext(ReturnReasonContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search,
    valueQualified: state?.valueQualified,
  });

  const { searchInformacion, valueQualified } = values;

  const backSearch = () => {
    reset();
    setSearchAdvanced({});
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    setChangeViewSearch(false);
  };

  const data = useMemo(() => {
    const search = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && { qualifiedUser_id: valueQualified?.value }),
      ...searcheAdvanced,
      ...(searcheAdvanced?.type_doc && { type_doc: searcheAdvanced?.type_doc?.value })
    }

    setChangeViewSearch(Object.values(search).length !== 0);
    return search
  }, [searchInformacion, valueQualified, searcheAdvanced]);

  const { requestData } = useGlobalContext();

  const { data: dataReturnReason, isLoading } = useQuery({
    queryKey: ["returnReason", page, data, freeZone_id],
    queryFn: () => requestData({
      method: "POST",
      page: page,
      data,
      Endpoint: environment.GET_RETURN_REASON,
    }),
  })




  useEffect(() => {
    console.log({ dataReturnReason })
    if (dataReturnReason?.data?.status?.code !== 400) {
      setResData(dataReturnReason?.data?.status?.data?.data);
      setMaxPage(dataReturnReason?.data?.status?.data?.last_page);
    }
  }, [dataReturnReason]);




  return (
    <>
      <ModalGeneric
        isOpen={isOpenShowComentary}
        onOpen={onOpenShowComentary}
        onClose={onCloseShowComentary}
        title={`Comentario`}
        size={"md"}
      >
        <span className="text-muted">
          {ListReturnReason?.reason_for_return}
        </span>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseShowComentary}>
            Cerrar
          </ButtonStyled>
          <Spacer />
        </Flex>
      </ModalGeneric>

      <HeaderSearch>
        <TitleReason title="Motivo Devolución">
          <SearchAdvancedModule
            handleInputChange={handleInputChange}
            values={values}
            onOpenSearch={onOpenSearch}
            changeViewSearch={changeViewSearch}
            backSearch={backSearch}
            permisoSearch={"getReasonReturns"}
            allAccess={allAccess}
            myPermission={myPermission}
            setValues={setValues}
          />
        </TitleReason>

        <ModalGeneric
          isOpen={isOpenSearch}
          onOpen={onOpenSearch}
          onClose={onCloseSearch}
          title="Búsqueda avanzada"
        >
          <FormSearchAdvanced
            onClose={onCloseSearch}
            handleSearchSubmit={setSearchAdvanced}
          />
        </ModalGeneric>

        <ModalGeneric
          isOpen={isOpenListReason}
          onOpen={onOpenListReason}
          onClose={onCloseListReason}
          title={`Motivos del ${ListReturnReason?.type_doc} Nº${ListReturnReason?.code_form}`}
          size={"md"}
        >
          "busqueda avanzada"
        </ModalGeneric>

        <TableOrder
          thead={thead}
          data={resData || []}
          isNum={false}
          setData={setResData}
          loading={isLoading}
          tbodyData={Tbody}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={isLoading}
        />
      </HeaderSearch>
    </>
  );
};
