import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { EstadisticaItemSecurity } from "./EstadisticaItemSecurity";
import environment from "constants/apiConst";
import InputSelect from "components/InputSelect/InputSelect";
import { SubTitle } from "styled/Title";
import { mes } from "pages/Statistics/General/ArrayEstadisticas";
import { __PostForm } from "request/Petitions/__Post";
import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { ExportPdf } from "components/Export/ExportPdf";

export const EstadisticasSecurity = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [currentPage, setCurrentPage] = useState(null);
  const [selectmes, setSelectmes] = useState(null);
  const [selectyear, setSelectyear] = useState(null);
  const [filterEst, setfilterEst] = useState(false);
  const [Datayear, setDatayear] = useState(null);
  const [dateToday, setDateToday] = useState(null);
  const [rutaImage, setrutaImage] = useState(null);
  const ref = useRef(null);

  const arrayYear10th = [];
  const cantidadYear = 11;

  useEffect(() => {
    const today = new Date();
    const yearhoy = today.getFullYear();
    const mesCurrent = today.getMonth();
    const resultado = mesCurrent + 1;
    const cadenaMes = resultado.toString();
    const searchMonth = mes.find((e) => e.value === cadenaMes);
    setSelectyear({ value: yearhoy, label: yearhoy });
    setSelectmes(searchMonth);
    for (let i = 0; i < cantidadYear; i++) {
      arrayYear10th.push({ value: yearhoy - i, label: yearhoy - i });
    }
    setDatayear(arrayYear10th);
  }, []);

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) {
      ExportPdf(
        "IdPdfFmmCorrectEntry",
        `Estadisticas_de_${title}_${dateToday}`
      );
    }
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  const title = "Estadísticas de seguridad"

  return (
    <>
      <HeaderEstadistica
        title={title}
        handlePDF={dowmLoadPdfImgae}
        // excelPath={environment.POST_EXPORT_EXCEL_OP_ENTRY_OUT}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_VETO}
        enableRendering={true}
        excelPath={environment.POST_EXPORT_EXCEL_VETO}
      />
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div className="d-flex">
            <EstadisticaItemSecurity
              title={"Seguridad"}
              data={currentPage}
              loading={loading}
              filterEst={filterEst}
              setfilterEst={setfilterEst}
              setCurrentPage={setCurrentPage}
              ref={ref}
            />
          </div>
          <ExportPdfGrafica
            id="IdPdfFmmCorrectEntry"
            rutaImage={rutaImage}
            thead={""}
            data={""}
            tbody={""}
            title={`Estadisticas de ${title}`}
            dateToday={dateToday}
          />
        </>
      )}
    </>
  );
};
