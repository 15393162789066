import { Box, Flex, Input, InputGroup, Select, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { Title } from "styled/Title";

export const ListConfigBascula = ({ selectpesaje, setselectpesaje }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [CurrentData, setCurrentData] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const createData = async (data) => {
      try {
        setLoading(true);
        const res = await __Post(environment.CONFIG_PASES, token, data);
        if (res.data.status.code === 200) {
          setCurrentData(res?.data?.status?.data);
          openAlert(`Registro guardado Exitosamente`, "success");
        } else {
          openAlert(res.data.status.message, "error");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    selectpesaje && createData({ type_weighing: selectpesaje.value });
  }, [selectpesaje]);

  return (
    <>
      <Title size={"16px"} weight={600} className="mb-3">
        {"Configuración actual de pesaje"}
      </Title>
      {loading
        ? (
          <SpinnerComponent />
        )
        : (
          <>
            <InputGroup w="100%" pb={2}>
              <Select
                icon={<MdArrowDropDown />}
                placeholder={CurrentData?.type_weighing}
                isDisabled={true}
              />
            </InputGroup>
            <Box
              gap={2}
              className="d-flex justify-content-center align-items-center mb-4"
            >
              <InputGroup w="50%">
                <Select
                  icon={<MdArrowDropDown />}
                  placeholder={CurrentData?.range_type}
                  isDisabled={true}
                />
              </InputGroup>
              <InputGroup w="50%">
                <Input
                  type={"number"}
                  value={CurrentData?.range_num}
                  placeholder="Sin datos"
                  isDisabled={true}
                />
              </InputGroup>
              <InputGroup w="50%">
                <Input
                  type={"time"}
                  value={CurrentData?.hour}
                  placeholder="Sin datos"
                  isDisabled={true}
                />
              </InputGroup>
            </Box>
          </>
        )}
    </>
  );
};
