/**
 * Reducer para Login y Logout
 */

import { AUTH_TYPES } from 'redux/types/authTypes';

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN:
      return {
        ...action.payload,
        logged: true
      };

    case AUTH_TYPES.LOGOUT:
      return {
        logged: false
      };

    default:
      return state;
  }
};
