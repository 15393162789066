import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";

const TbodyRoles = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.name_rol}</Td>
      <Td className="text-center">{data?.description_rol}</Td>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyRoles;
