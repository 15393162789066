/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const FmmOHeader = ({ data }) => {
  const {
    addresDestine,
    selectOperation,
    StateCurrentExport,
    selectExport,
    selectEmbarcacion,
    refunds
  } = useContext(FormCrudCRUDContext);

  const {
    shipment,
    refund,
    code_operation,
    short_name_operation,
    destination_address,
    code_operationCorrected,
    short_name_operationCorrected,
    destination_address_corrected,
    thirdPartie_id
  } = data;

  const operationOriginal = code_operation + " " + short_name_operation;
  
  return (
    <>
      <LabelGroup
        changeValue={
          safelyConvertToString(thirdPartie_id) !== safelyConvertToString(selectExport?.value) && "BorderCorrect"
        }
        value={StateCurrentExport || ""}
        name={"searchInformacion"}
        tag={"Importador/Comprador1*"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          data?.balance_correction !== 1 &&
          code_operation !== selectOperation?.value && "BorderCorrect"
        }
        value={operationOriginal || ""}
        name={"searchInformacion"}
        tag={"Tipo de Operación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={shipment !== selectEmbarcacion?.label && "BorderCorrect"}
        value={shipment || ""}
        name={"searchInformacion"}
        tag={"Tipo de embarcación"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={refund !== refunds?.label && "BorderCorrect"}
        value={refund || ""}
        name={"searchInformacion"}
        tag={"Modalidad"}
        size={100}
        desicion={true}
      />
      {selectOperation &&
        addresDestine.map((items, index) => {
          if (items === selectOperation.value) {
            return (
              <LabelGroup
                changeValue={
                  destination_address !== destination_address_corrected &&
                  "BorderCorrect"
                }
                name={"destination_address"}
                value={destination_address}
                tag={"Dirección de destino"}
                size={100}
                desicion={true}
                key={index}
              />
            );
          }
        })}
    </>
  );
};
