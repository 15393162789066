import { Td, Tooltip } from "@chakra-ui/react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const TBodyInOutMonitoring = ({ data }) => {
  const navigate = useNavigate();

  const handleShow = () => {
    if (data?.form_id) {
      if (data?.form_type_id === 1) { navigate(`/FormShowExit?ExitNumber=${data.form_id}`); }
    } else if (data?.form_type_id === 2) {
      navigate(`/FormShow?FormEntry=${data.form_id}`);
    }
    // setDataToShow(data);
  };
  return (
    <>
      <Td className="text-center" onClick={handleShow}>
        {data?.form_id
          ? (
            <NavLink
              to={
                data?.form_type_id === 1
                  ? `/FormShowExit?ExitNumber=${data?.form_id}`
                  : `/FormShow?FormEntry=${data?.form_id}`
              }
              className="ms-2"
            >
              <span className="formNumber">{data?.form_code}</span>
            </NavLink>
          )
          : (
            <span>{data?.form_code ? data?.form_code : ""}</span>
          )}
      </Td>
      <Td className="text-center">
        {" "}
        {data?.tracking_parent ? data?.tracking_parent : ""}
      </Td>
      <Td className="text-center">
        {data?.name_qualified ? data?.name_qualified : "N/A"}
      </Td>
      <Td className="text-center">{data?.nickname ? data?.nickname : "N/A"}</Td>
      <Td className="text-center">
        {data?.start_date ? data?.start_date : "N/A"}
      </Td>
      <Td className="text-center">{data?.end_date ? data?.end_date : "N/A"}</Td>
      <Td className="text-center">
        {data?.final_user_nickname ? data?.final_user_nickname : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.name_operation ? data?.name_operation : "N/A"}
      </Td>
      <Td className="text-center">{data?.service ? data?.service : "N/A"}</Td>
      <Td className="text-center">
        {data?.follow_order ? data?.follow_order : "N/A"}
      </Td>
      {/* <Td className="text-center">
        {data?.observation ? data?.observation : "N/A"}
      </Td> */}
      <Td className="text-center">
        {data?.transport_document_number
          ? data?.transport_document_number
          : "N/A"}
      </Td>
    </>
  );
};

export default TBodyInOutMonitoring;
