const Signature = () => {
  return (
    <div className="d-flex justify-content-around aling-item-center">
      <div>
        <span>___________________________</span>
        <h2>Presentado por</h2>
      </div>
      <div>
        <span>____________________________</span>
        <h2>Autorizado por</h2>
      </div>
    </div>
  );
};

export default Signature;
