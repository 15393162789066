import { Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { ThStyledBlue } from "components/Tables/Table.styled";
import { TheadTraceabilityCommentary } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import React from "react";

const TBodyTrackComments = ({ data }) => {
  const typeComment = {
    0: "Inicial",
    1: "Final"
  };

  return (
    <>
      <Table
        size="md"
        variant="simple"
        className="table table-striped table-sm "
        overflowX={true}
      >
        <Thead>
          <ThStyledBlue className="text-center" id="alinear">
            {""}
          </ThStyledBlue>
          {TheadTraceabilityCommentary.map((item, index) => (
            <ThStyledBlue className="text-center" id="alinear">
              {item?.name}
            </ThStyledBlue>
          ))}
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr>
              <Td className="text-center bold">{`${typeComment[index]}`}</Td>
              <Td className="text-center">{item?.comment}</Td>
              <Td className="text-center">{item?.user}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default TBodyTrackComments;
