import { Box } from "@chakra-ui/react";
import { BarChart } from "components/Charts";
import InputSelect from "components/InputSelect/InputSelect";
import React from "react";
import { Bar } from "react-chartjs-2";
import { MinCardMximu } from "styled/CardTotalesDash";

export const CardDown = ({ title, userData }) => {
  return (
    <>
      {userData &&
        <MinCardMximu>
          <div className="d-flex">
            <span id="tituloEstadistica">{title}</span>
            <br />
          </div>

          <Box id="ultimate" p={2}>
            <Bar
              data={userData}
              options={{
                responsive: true, 
                plugins: {
                  legend: {
                    display: false// Aquí desactivamos la visualización de la leyenda
                  }
                }

              }}
            />
          </Box>
        </MinCardMximu>}
    </>

  );
};
