import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import WeighingContext from "context/WeighingContext";
import { useModal } from "hooks/useModal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconGeneric } from "styled/Icons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import { FormWeighing } from "../FormWeighing/FormWeighing";
import {
  theadContainer,
  theadReport
} from "../SearchAdvanced/Array/ArrayTableHead";
import TBodyContainer from "../Tbody/TBodyContainer";
import Tbody from "./Tbody/Tbody";
import { GrDocumentText } from "react-icons/gr";
import { Box, Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { MdAdd } from "react-icons/md";
import environment from "constants/apiConst";
import { __PostJsonData } from "request/Petitions/__Post";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { useForm } from "hooks/useForm";
import FormSearchWeighing from "../SearchAdvanced/FormSearchWeighing";
import { Reweigh } from "../view/Reweigh";
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext from "context/GlobalContext";
import { handleSanitizedObj } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

export const ReportWeighing = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["searchInformacion", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["order", "consecutive", "vehiclePlate", "completeness", "reweight", "startDate", "endDate", "shedule", "operation", "formNumber"], nestedProp: "searchAdvance" });

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);

  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.searchInformacion || "",
    valueQualified: state?.valueQualified || "",
  });

  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [myPermission, setmyPermission] = useState(null);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    setSearchAdvanced(null);
    setChangeViewSearch(false);
    setLoading(true);
    // getPesaje(dataOrden);
  };

  const [allAccess, setAllAccess] = useState(false);
  // const [myPermission, setmyPermission] = useState(null);
  // const { authUser } = useContext(AuthContext)
  const [isOpenNewWeighing, onOpenNewWeighing, onCloseNewWeighing] = useModal();
  const [update, setUpdate] = useState(false);

  const [openAlert] = AlertErrorAlert();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {
    isOpenViewContainer,
    onOpenViewContainer,
    onCloseViewContainer,
    dataContainer,
    isOpenViewForm,
    onOpenViewForm,
    onCloseViewForm,
    dataForm,
    isOpenReweigh,
    onOpenReweigh,
    onCloseReweigh
  } = useContext(WeighingContext);

  const dataOrden = {
    order: "ASC"
  };

  const { searchInformacion, valueQualified } = values;




  const getPesaje = async (data) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.REPORT_WEIGHING_GET,
        token,
        dataOrden,
        page
      );
      const destrucData = res?.data?.status;
      if (destrucData?.code === 200) {
        setCurrentPage(destrucData?.data?.data);
        setLoading(false);
        setMaxPage(destrucData?.data?.last_page);
      } else {
        setLoading(false);
        openAlert(`Ocurrio un error`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.REPORT_WEIGHING_GET,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced(null);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPage(res?.data?.status?.data.data);
        setLoading(false);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(valueQualified && { qualifiedUser_id: valueQualified?.value }),
      ...(searchInformacion && { search: searchInformacion }),
    };

    if (Object.entries(data).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple({
        ...data, order: "ASC",
      });
      return;
    }
    setChangeViewSearch(false);
    getPesaje();

  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZone_id]);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  useEffect(() => {
    const channel = localStorage.getItem("chanelWeighing");
    if (!channel) openAlert("Recuerde seleccionar una bascula desde el botón que tiene al lado de su nombre de usuario", "info");
  });
  const { setUser_Qualified } = useContext(GlobalContext);

  useEffect(() => {
    return setUser_Qualified(valueQualified);
  }, [valueQualified]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormSearchWeighing
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>{" "}
      <ModalGeneric
        isOpen={isOpenViewContainer}
        onOpen={onOpenViewContainer}
        onClose={onCloseViewContainer}
        title="Ver contenedores"
      >
        <TableOrder
          thead={theadContainer}
          data={dataContainer || []}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TBodyContainer}
        />

        {/* <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        /> */}
      </ModalGeneric>
      <ModalGeneric
        isOpen={isOpenViewForm}
        onOpen={onOpenViewForm}
        onClose={onCloseViewForm}
        title="Formulario asociados al pase"
        size={"sm"}
      >
        {dataForm &&
          dataForm.map((item, index) => (
            <div className="p-2">
              <IconGeneric as={GrDocumentText} />
              <span key={index}>Formulario número: {item.code_outputForm}</span>
              <br />
            </div>
          ))}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReweigh}
        onOpen={onOpenReweigh}
        onClose={onCloseReweigh}
        scrollBehavior={"outside"}
        title="Repesar"
      >
        <Reweigh onCloseReweigh={onCloseReweigh} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenNewWeighing}
        onOpen={onOpenNewWeighing}
        onClose={onCloseNewWeighing}
        title="Nuevo pesaje"
      >
        <FormWeighing onClose={onCloseNewWeighing} set={setUpdate} />
      </ModalGeneric>
      <Title style={{ marginTop: 10 }}>Cola de pesaje</Title>

      <Box
        shadow={
          "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px"
        }
        p={"1rem"}
        borderRadius=" 5px"
        w="full"
      >
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={() => onOpenSearch()}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getWeighing"}
          allAccess={allAccess}
          w="full"
          myPermission={myPermission}
          setValues={setValues}
        />
      </Box>
      <HeaderSearch>
        {" "}
        <Box display={"flex"} flexDir="column">
          <Box display={"flex"} justifyContent="right">
            <Tooltip placement="top" hasArrow label="Nuevo">
              <ButtonHeaderModule onClick={onOpenNewWeighing} className="me-1">
                <IconGeneric size={"38px"} color="#ffff" as={MdAdd} />
              </ButtonHeaderModule>
            </Tooltip>
          </Box>
          <br />
          {/* <Title>Cola de pesajes</Title> */}
          <TableOrder
            thead={theadReport}
            data={currentPage}
            setData={setCurrentPage}
            loading={loading}
            tbodyData={Tbody}
          />
          <Pagination
            page={page}
            setPage={setPage}
            maxPage={maxPage}
            loading={loading}
          />
        </Box>
      </HeaderSearch>
    </>
  );
};
