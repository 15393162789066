import { Td, Tr } from "@chakra-ui/react";

const TBodyLogInventory = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.operation_code ? data?.operation_code : "N/A"}</Td>
        <Td className="text-center"> {data?.nature_of_inventory ? data?.nature_of_inventory : "N/A"}</Td>
        <Td className="text-center"> {data?.trade_agreement ? data?.trade_agreement : "N/A"}</Td>
        <Td className="text-center"> {data?.import_declaration ? data?.import_declaration : "N/A"}</Td>
        <Td className="text-center"> {data?.customs_clearance ? data?.customs_clearance : "N/A"}</Td>
        <Td className="text-center"> {data?.invoice ? data?.invoice : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_available ? data?.quantity_available : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_go_out ? data?.quantity_go_out : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_temporary ? data?.quantity_temporary : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved ? data?.quantity_reserved : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved_input ? data?.quantity_reserved_input : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved_elaborated ? data?.quantity_reserved_elaborated : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_free_zone ? data?.quantity_free_zone : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_total ? data?.quantity_total : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_available_product ? data?.quantity_available_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_go_out_product ? data?.quantity_go_out_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_temporary_product ? data?.quantity_temporary_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved_product ? data?.quantity_reserved_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved_input_product ? data?.quantity_reserved_input_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_reserved_elaborated_product ? data?.quantity_reserved_elaborated_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_free_zone_product ? data?.quantity_free_zone_product : "N/A"}</Td>
        <Td className="text-center"> {data?.quantity_total_product ? data?.quantity_total_product : "N/A"}</Td>
        <Td className="text-center"> {data?.value_fob_unit_product ? data?.value_fob_unit_product : "N/A"}</Td>
        <Td className="text-center"> {data?.value_cif_unit_product ? data?.value_cif_unit_product : "N/A"}</Td>
        <Td className="text-center"> {data?.net_weight_product_unit ? data?.net_weight_product_unit : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyLogInventory;
