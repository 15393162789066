export const items = [
  "Producto elaborado",
  "Materias primas e insumos",
  "Otros costos y gastos",
  "Desperdicios"
];

export const cabeceraIntegrationBox = [
  "Editables",
  "Presentados",
  "Autorizados"
];

export const theadLock = [
  { name: "Estado", order: "status" },
  { name: "Descripción", order: "description" }
];

export const theadOder = [
  { name: "Item", order: "item" },
  { name: "producto elaborado", order: "product" },
  { name: "Cod. producto", order: "codProd" },
  { name: "Cant. producto", order: "cantProd" },
  { name: "Peso neto en Kilos", order: "pesokg" },
  { name: "Valor en US$", order: "valueUS" }
];

export const theadElaborated = [
  { name: "Item", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Subpartida", order: null },
  { name: "Cantidad", order: null },
  { name: "Unidad", order: "unot" },
  { name: "Unidad producto", order: null },
  { name: "Cant. producto", order: null },
  { name: "Peso neto en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Código OP", order: null },
  { name: "Acciones", order: "" }
];

export const theadElaboratedNotEdit = [
  { name: "Item", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Subpartida", order: null },
  { name: "Cantidad", order: null },
  { name: "Unidad", order: "unot" },
  { name: "Unidad producto", order: null },
  { name: "Cant. producto", order: null },
  { name: "Peso neto en Kilos", order: null },
  { name: "Valor en US$", order: null },
  { name: "Código OP", order: null }
];

export const theadElaboratedExport = [
  { name: "Item", order: null },
  { name: "Producto elaborado", order: null },
  { name: "Cod. producto", order: null },
  { name: "Subpartida", order: null },
  { name: "Cantidad", order: null },
  { name: "Unidad", order: "unot" },
  { name: "Unidad producto", order: null },
  { name: "Cant. producto", order: null },
  { name: "Peso neto en Kilos", order: null },
  { name: "Valor en US$", order: null }
];

export const theadMateria = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Nat", order: "null" },
  { name: "Decl.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Cant.", order: "null" },
  { name: "Und", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: " % Dpd", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Código de OP", order: "null" },
  { name: "Acciones", order: "null" }
];

export const theadMateriaIsCommerce = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Nat", order: "null" },
  { name: "Decl.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Cant.", order: "null" },
  { name: "Und", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: " % Dpd", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Seguimiento", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Código de OP", order: "null" },
  { name: "Acciones", order: "null" }
];

/*
export const theadMateria = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Nat", order: "null" },
  { name: "Decl.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Cant.", order: "null" },
  { name: "Und", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: "TIPO DPD", order: "null" },
  { name: " % / CANT", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Código de OP", order: "null" },
  { name: "Acciones", order: "null" },
];
*/

export const theadMateriaNotEditable = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Nat", order: "null" },
  { name: "Decl.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Cant.", order: "null" },
  { name: "Und", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: " % Dpd", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Código de OP", order: "null" }
];

export const theadMateriaNotEditableIsCommerce = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Nat", order: "null" },
  { name: "Decl.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Cant.", order: "null" },
  { name: "Und", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: " % Dpd", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Seguimiento", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Código de OP", order: "null" }
];

export const arrayMatteriaEdit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const arrayMatteriaNotEdit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const arrayMatteriaNotEditCorrected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const theadMateriaExport = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "ref" },
  { name: "Producto", order: "product" },
  { name: "Cantidad", order: "" },
  { name: "Und", order: "null" },
  { name: "Nat", order: "null" },
  { name: "Prod. Cant", order: "null" },
  { name: "Und. Prod", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Descripción", order: "null" }
];

export const theadOther = [
  { name: "Item", order: "null" },
  { name: "Descripción", order: "null" },
/*   { name: "Cantidad", order: "null" }, */
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
/*   { name: "Valor unitario", order: "null" }, */
  { name: "Acciones", order: "null" }
];

export const theadOtherEdit = [
  { name: "Item", order: "null" },
  { name: "Descripción", order: "null" },
/*   { name: "Cantidad", order: "null" }, */
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Valor unitario", order: "null" }
];

export const theadOtherExport = [
  { name: "Item", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Cantidad", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "Valor en US$", order: "null" },
  { name: "Valor unitario", order: "null" }
];

export const theadDesperdicios = [
  { name: "item", order: "null" },
  { name: "Referencia", order: "null" },
  { name: "Cantidad", order: "null" },
  { name: "Unidad", order: "null" },
  { name: "Peso en kilos", order: "null" },
  { name: "valor en US$", order: "null" },
  { name: "Descripción", order: "null" }
];

export const TitleIntegration = [
  { name: "Nº Formulario", order: "null " },
  { name: "Nit", order: "null" },
  { name: "Empresa", order: "null" },
  { name: "Tipo de cuadro", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: null }
];

export const TitleIntegrationCorrected = [
  { name: "Nº Corrección", order: "null " },
  { name: "Nº Formulario", order: "null " },
  { name: "Nit", order: "null" },
  { name: "Empresa", order: "null" },
  { name: "Destino", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: null }
];

export const dataDate = [
  { value: 0, label: "Creación" },
  { value: 1, label: "Presentación" },
  { value: 2, label: "Revision" },
  { value: 3, label: "Autorización" },
  { value: 4, label: "Terminación" }
];

export const status = [
  { value: "BORRADOR", label: "Borrador" },
  { value: "PRESENTADO", label: "Presentado" },
  { value: "AUTORIZADO", label: "Autorizado" },
  { value: "DEVUELTO", label: "Devuelto" },
  { value: "ANULADO", label: "Anulado" }
];

export const destiny = [
  { value: 1, label: "AL RESTO DEL MUNDO" },
  { value: 2, label: "AL RESTO DEL TERRITORIO NACIONAL" },
  { value: 3, label: "A OTRA ZONA FRANCA" },
  { value: 4, label: "A USUARIOS DE LA MISMA ZF" },
  { value: 5, label: "INVENTARIO DE ACTIVOS" },
  { value: 6, label: "DE TEMPORALES EN EL RESTO DEL MUNDO" },
  { value: 7, label: "DE TEMPORALES EN EL TERRITORIO NACIONAL" }
];
export const OrderBy = [
  { value: 1, label: "Num. Formulario" },
  { value: 2, label: "Fecha de Creación" },
  { value: 3, label: "Fecha de Presentación" },
  { value: 4, label: "Fecha de Revisión" },
  { value: 5, label: "Fecha de Autorización" }
];

export const TitleTabletReference = [
  { name: "Código de Producto", order: "null" },
  { name: "Referencia", order: "null " },
  { name: "Subpartida", order: "null " },
  { name: "Descripción", order: "null" },
  { name: "Certificado", order: "null" },
  { name: "Decl. de imp.", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Inicial", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Saldo disp.", order: "null" },
  { name: "Unidad", order: "null" },
  { name: "Saldo Producto", order: "null" },
  { name: "Unidad de producto", order: "null" }
];

export const theadReferenceOP = [
  { name: "Código de Producto", order: "null" },
  { name: "Referencia", order: "null " },
  { name: "Subpartida", order: "null " },
  { name: "Descripción", order: "null" },
  { name: "Certificado", order: "null" },
  { name: "Decl. de imp.", order: "null" },
  { name: "Operación", order: "null" },
  { name: "Inicial", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Saldo disp.", order: "null" },
  { name: "Unidad", order: "null" },
  { name: "Saldo Producto", order: "null" },
  { name: "Unidad de producto", order: "null" }
];