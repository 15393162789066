import axios from 'axios';
import environment from 'constants/apiConst';

export const __GetTrackingsFmm = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${environment.GET_TRACKINGS_FMM}?page=${page || 1}`, options);
  return res;
};
