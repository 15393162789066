import React from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodySubheadingControl = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.name_freeZone}</Td>
      <Td className="text-center">{data?.subheading}</Td>
      <TdState className="text-center" text={data?.block_approval}>
        <IconGeneric
          color={data.block_approval === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.block_filling}>
        <IconGeneric
          color={data.block_filling === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.income}>
        <IconGeneric
          color={data.income === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.departures}>
        <IconGeneric
          color={data.departures === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.rest_of_the_world}>
        <IconGeneric
          color={data.rest_of_the_world === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.colombia}>
        <IconGeneric
          color={data.colombia === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.username}>
        <IconGeneric
          color={data.username === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <TdState className="text-center" text={data?.username}>
        <IconGeneric
          color={data?.free_zone === 1 ? "#03C39A" : "#bababa"}
          as={BsCheckCircleFill}
        />
      </TdState>
      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodySubheadingControl;
