import axios from 'axios';

import env from '../constants/apiConst';

export const __Reset_Password = (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    },
    data: body
  };

  const res = axios(env.RESET_PASSWORD, options);

  return res;
};
