import React, { useContext, useEffect, useState } from "react";
import { SubTitleHeader, TitleHeader } from "./Style";
import { AuthContext } from "context/AuthContext";
import { isArray } from "utils/type-check-utils";

/**
 *  HeaderForm - Componente de texto que despliega el titulo de cada formulario
 *
 * @param {String} status Estado en que se encuentra el formulario
 * @param {Number} numberForm Numero del formulario
 * @param {String} UserQualified El usuario calificado al que pertenece el formulario
 * @param {String} Modulo Modulo en que se encuentra
 * @return {Component} Retorna componente de accordion.
 */

export const HeaderForm = ({
  status,
  numberForm,
  UserQualified,
  Modulo,
  items,
  data,
}) => {
  const { authUser } = useContext(AuthContext);
  const [trackingStatus, setrackingStatus] = useState(2);

  useEffect(() => {
    if (isArray(items)) {
      const hasStatusOne = items.some((item) =>
        item?.tracking?.some((trackItem) => trackItem.status === 1)
      );

      setrackingStatus(hasStatusOne ? 1 : 2);
    }
  }, [items]);

  // Función para renderizar alertas
  const renderAlert = (message, className) => (
    <div
      className={`alert ${className} w-50 pt-2 pb-2 text-center m-auto mt-2 pl-2`}
      role="alert"
    >
      {message}
    </div>
  );

  return (
    <div className="container mb-2">
      <TitleHeader className="text-center">
        {status && `${status} - `}
        {Modulo}
      </TitleHeader>

      {(numberForm || UserQualified) && (
        <SubTitleHeader className="text-center">
          {numberForm && `Nº ${numberForm} - `}
          {UserQualified}
        </SubTitleHeader>
      )}

      {items?.some(
        (item) =>
          item?.tracking?.length > 0 &&
          trackingStatus === 1 &&
          authUser.is_qualified_user !== 1
      ) &&
        status !== "BORRADOR" &&
        renderAlert("En seguimiento", "alert-danger")}

      {data?.profiling_deal_type_form === "Si" &&
        data.profiling_status !== "Finalizado" &&
        renderAlert("Perfilado DIAN", "alert-danger")}
    </div>
  );
};
