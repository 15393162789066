import React, { useContext, useEffect, useState } from 'react'
import { Box, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { MdQrCodeScanner } from 'react-icons/md'
import { IconGeneric } from 'styled/Icons.styled'
import ButtonsBorrador from './ButtonsBorrador'
import ButtonsPresent from './ButtonsPresent'
import ButtonsAproved from './ButtonsAproved'
import ButtonsAutorizado from './ButtonsAutorizado'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import { ValidatePresenFmm } from 'pages/Entry_and_exit_forms/ValidatePresenFmm'
import { ButtonCaducar } from './ButtonCaducar'
import { OptionsForm } from '../components/OptionsForm'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import ModalQR from 'components/modal/ModalQR'

const ButtonView = ({ data, children, idFmm, setFollowOrder, ExportPdf }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    setDataToEdit,
    changeStatus,
    onOpenCopy,
    setDataToCopy,
    setDataToDesechar,
    onOpenDesechar,
    onOpenReturnReason,
    setReturnReason,
    setDataToCaducar,
    onOpenCaducar,
    setDataToAnular,
    onOpeAnular,
    getItemsPrev,
    onOpenRecuperar,
    setDataRecuperar,
    setRequestCreatePassNew,
    onOpenDesCaducar,
    isLoadingChangeStatus,
    setIsLoadingChangeStatus
  } = useContext(FormCrudCRUDContext)

  /*   const {
    setIsLoadingChangeStatus
  } = useContext(FormCrudCRUDContext) */
  const token = useSelector(state => state.auth.dataUser.token)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [openAlert] = AlertErrorAlert()
  useEffect(() => {
    setRequestCreatePassNew(environment.CREATE_PASES_IN)
  }, [])

  const handleEdit = async e => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type', 1)
    body.append('type_of', 'FMMI')
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)

    if (res?.data?.status?.code === 200) {
      if (data?.status_entryForm === 'DEVUELTO') {
        setDataToEdit(data)
        navigate(`/FormEntry?FormEntryNumber=${data?.id}`)
      } else {
        setDataToEdit(data)
        navigate(`/FormEntry?FormEntryNumber=${data?.id}`)
      }
      return
    }

    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleCopy = e => {
    setDataToCopy(data)
    onOpenCopy()
  }

  const arrayPrueba = [
    'product_quantity',
    'typeProduct_id',
    'transportMethod_id',
    'packaging_id',
    'countryOrigin_id',
    'countryProcedency_id',
    'flag_id',
    'countryPurchase_id',
    'product_id',
    'gross_weight',
    'net_weight',
    'us_freight',
    'port_id'
  ]

  const handlePresent = async status => {
    const attach = data.files
    // let arrayDocumentRequerid = [];
    // let resDocumentCurrent = attach.map(({ documentReq_id }) => documentReq_id);
    // data.documents.map(({ id }) => {
    //   if (!resDocumentCurrent.includes(id)) {
    //     return arrayDocumentRequerid.push(id);
    //   }
    // });
    setIsLoadingChangeStatus(true)
    const mensageErrorItem = ValidatePresenFmm(data, status)
    if (mensageErrorItem) {
      openAlert(mensageErrorItem, 'error')
    } else {
      const respuesta = await getItemsPrev(
        environment.GET_iTEMS_NP,
        idFmm,
        arrayPrueba,
        data?.code_operation,
        data?.services,
        data
      )

      const info = {
        id: data?.id,
        status_entryForm: status
      }

      if (
        status === 'PRESENTADO' &&
        (respuesta?.length > 0 || attach?.length === 0)
      ) {
        if (
          respuesta?.length > 0 &&
          data?.code_operation >= 101 &&
          data?.code_operation <= 599
        ) {
          navigate(`/FormEntry?FormEntryNumber=${idFmm}`)
          return openAlert(
            'El formulario presenta errores. Verifique los campos marcados',
            'error'
          )
        } else if (attach?.length === 0) {
          const alertMessage =
            '¡Hola! Queremos recordarte que es necesario adjuntar al menos uno de los siguientes archivos en el formulario:\n'

          openAlert(alertMessage, 'error')

          data?.documents?.forEach(({ name_document }, index) => {
            // alertMessage += `${name_document}, \n`;
            return openAlert(name_document, 'error')
          })
          navigate(`/FormEntry?FormEntryNumber=${idFmm}`)
        } else {
          handleCHange(status, info, attach)
        }
    
      } else {
        handleCHange(status, info, attach)
      }
    }
  }

  const handleCHange = (status, info, attach) => {
    if (status === 'DEVUELTO') {
      onOpenReturnReason()
      setReturnReason(info)
    } else if (status === 'RECUPERADO') {
      onOpenRecuperar()
      setDataRecuperar(data)
    } else if (status === 'AUTORIZADO' && attach.length === 0) {
      openAlert(
        'El formulario debe tener por lo menos un documento adjunto',
        'error'
      )
    } else {
      changeStatus(info)
    }
  }

  const handleDesechar = () => {
    setDataToDesechar(data)
    onOpenDesechar()
  }

  const handleCaducar = () => {
    setDataToCaducar(data)
    onOpenCaducar()
  }

  const handleDescaducar = () => {
    // setDataToCaducar(data);
    onOpenDesCaducar()
  }

  const handleAnular = () => {
    setDataToAnular(data)
    onOpeAnular()
  }

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={'Código QR'}
        size={'md'}
      />
      <div className='row'>
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            {data.status_entryForm === 'BORRADOR' ||
            data.status_entryForm === 'DEVUELTO' ? (
              <ButtonsBorrador
                handleEdit={handleEdit}
                handleCopy={handleCopy}
                handlePresent={handlePresent}
                handleDesechar={handleDesechar}
                status={data?.status_entryForm}
                data={data}
                setFollowOrder={setFollowOrder}
              />
            ) : data.status_entryForm === 'PRESENTADO' ? (
              <ButtonsPresent
                handlePresent={handlePresent}
                data={data}
                handleCopy={handleCopy}
                status={data.status_entryForm}
                setFollowOrder={setFollowOrder}
              />
            ) : data.status_entryForm === 'APROBADO' ? (
              <ButtonsAproved
                data={data}
                handlePresent={handlePresent}
                handleCopy={handleCopy}
                handleCaducar={handleCaducar}
                setFollowOrder={setFollowOrder}
                isLoading={isLoadingChangeStatus}
              />
            ) : data.status_entryForm === 'AUTORIZADO' ? (
              <ButtonsAutorizado
                handleCopy={handleCopy}
                data={data}
                handleAnular={handleAnular}
                setFollowOrder={setFollowOrder}
              />
            ) : data.status_entryForm === 'CADUCADO' ? (
              <ButtonCaducar
                handleCopy={handleCopy}
                data={data}
                handleDescaducar={handleDescaducar}
              />
            ) : (
              ''
            )}
          </Box>
        </div>
        {children}
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            <OptionsForm data={data} />

            <Tooltip placement='top' hasArrow label='Qr'>
              <span>
                <ButtonHeaderModule onClick={onOpen} bgColor='#1F7AC3'>
                  <IconGeneric
                    width='16px'
                    className=' text-white'
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
            {ExportPdf && ExportPdf}
          </Box>
        </div>
      </div>
    </>
  )
}

export default ButtonView
