import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import WeighingContext from "context/WeighingContext";
import { ADDLocalStorage, DeleteLocalstore } from "hooks/useLocalStorage";
// import PassCrudContext from "context/PassCrudContext";

const TbodyDriver = ({ data, CurrentData, StatusCheck, IncompleteStatus }) => {
  const {
    setDataDrivers,
    onCloseOtherData,
    setINCOMPLETEWITHOUTSELECT,
    setWeightCurrent
  } = useContext(WeighingContext);

  const completeness = data?.completeness ? data?.completeness : "Sin pesaje";

  const handleSearchItem = () => {
    setDataDrivers(data);
    onCloseOtherData();
  };
  useEffect(() => {
    const incompleteTasks = CurrentData.filter(
      (task) => task.completeness === "INCOMPLETO"
    );
    if (incompleteTasks.length > 0) {
      ADDLocalStorage(true, "INCOMPLETE_SELECT");
    } else {
      DeleteLocalstore("WEIGHT_ID");
      DeleteLocalstore("INCOMPLETE_SELECT");
    }

    if (data?.weight_id === null || data?.weight_id === undefined) {
      DeleteLocalstore("WEIGHT_ID");
    }
  }, [CurrentData]);

  useEffect(() => {
    const INCOMPLETE_SELECT =
      (data?.completeness === "INCOMPLETO" && StatusCheck === false) ||
      data?.completeness !== "INCOMPLETO";

    const EvaluateWeightId = () => {
      if (data.weight_id) {
        ADDLocalStorage(data.weight_id, "WEIGHT_ID");
      }
    };

    if (INCOMPLETE_SELECT !== null) {
      EvaluateWeightId();
    }
  }, [data]);

  return (
    <Tr
      onClick={
        (data?.completeness === "INCOMPLETO" && StatusCheck === false) ||
        data?.completeness !== "INCOMPLETO"
          ? () => {
            handleSearchItem(
              completeness === "Sin pesaje" &&
                  IncompleteStatus &&
                  StatusCheck === true
            );
          }
          : () => console.log()
      }
    >
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.passForm_number}</Td>
      <Td className="text-center">
        {data?.completeness ? data?.completeness : "Sin pesaje"}
      </Td>
      <Td className="text-center">{data?.description}</Td>
      <Td className="text-center">
        {data?.pass === "1"
          ? "Formulario de entrada"
          : "Formulario de despacho"}
      </Td>
      <Td className="text-center">{data?.identification}</Td>
      <Td className="text-center">{data?.driver}</Td>
      <Td className="text-center">{data?.name_typeTransport}</Td>
    </Tr>
  );
};

TbodyDriver.propTypes = {
  data: PropTypes.object
};

export default TbodyDriver;
