/* eslint-disable no-unused-vars */
import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { ButtonsContainerSingle, ButtonStyled } from "styled/Buttons.styled";
import theme from "theme";

/**
 * onClose, isOpen: en el componente donde se llama al modal, se debe llamar al sgt hook de chackra:
 *    const { isOpen, onOpen, onClose } = useDisclosure() o el hook personalizado useModal
 *
 * size: propiedad para defirnir tamaño del modal segun chackra
 * modaltitle: titulo del modal
 * buttonMain = tag del boton principal
 * handleClick: funcion que ejecutará el boton principal

 */

const ModalTransition = ({
  onClose,
  isOpen,
  size,
  modaltitle = "",
  buttons = false,
  handleClick,
  buttonMain,
  isCentered = true,
  scrollBehavior = 'inside',
  children,
}) => {
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior={scrollBehavior}
        isCentered={isCentered}
        autoFocus={false}
        motionPreset="slideInBottom"
        // onClose={onClose}

        isOpen={isOpen}
        size={size}
      >
        <ModalOverlay />
        <ModalContent style={{ minHeight: "auto", minWidth: "45vw" }}>
          {/* <ModalHeader>{modaltitle}</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {buttons && (
              <ButtonsContainerSingle>
                <ButtonStyled
                  bgColor={theme.colors.gray.ba}
                  onClick={onClose}
                  alignSelf="flex-end"
                >
                  Cerrar
                </ButtonStyled>
                <ButtonStyled onClick={handleClick}>{buttonMain}</ButtonStyled>
              </ButtonsContainerSingle>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalTransition;
