import React from "react";
import { Button } from "@chakra-ui/react";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * FormModalStatusDelete - Componente el cual llama un modal de precaucion al momento de eliminar y copiar cualquier documento
 *
 * @param {Function} functionChangeStatus lo utilizamos para actualizar el estado de nuestro documento.
 * @param {string} status estado al cual queremos que pase nuestro documento.
 * @param {boolean} redirection indica si queremos ser redirigidos.
 * @param {string} routeToDrive ruta a la cual queremos ser redirigidos.
 * @param {string} nameDocument Nombre del documento.
 * @param {string} numberOfDocument Numero  del documento.
 * @param {Function} isOpen  lo utilizamos para la apertura del modal .
 * @param {Function} onClose lo utilizamos para el cierre del modal .
 * @param {Function} functionChangeStatus lo utilizamos para pasar funcion del cambio de estado.
 * @return  Retorna entero.
 */

const FormModalStatusDelete = ({
  status,
  redirection,
  routeToDrive,
  nameDocument,
  numberOfDocument,
  isOpen,
  onClose,
  functionChangeStatus,
  copy,
  data,
  deletes,
  id,
  product,
  endPoint = null
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleStatusBox = (status) => {
    const statusData = new FormData();
    statusData.append("id", id);
    statusData.append("status", status);
    functionChangeStatus(statusData);
    onClose();
    if (redirection) {
      navigate(routeToDrive);
    }
  };

  const handleChangeStatus = () => {
    functionChangeStatus(data, endPoint);
    onClose();
    if (redirection) {
      navigate(routeToDrive);
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onClose={onClose}
        title={`Desea ${status.toLowerCase()}  ${
          deletes ? "la" : "el"
        } ${nameDocument} ${
          deletes || product ? " " : "Nº"
        } ${numberOfDocument}`}
        size={"2xl"}
      >
        {pathname === "/IntegracionBox" && (
          <h3 style={{ color: "#2e2e2e" }}>
            Le recordamos limpiar la información agregada de materias primas e
            insumos, otros gastos y costos, si es el caso.{" "}
          </h3>
        )}

        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            colorScheme={copy ? "yellow" : "red"}
            onClick={
              copy || deletes || product
                ? () => handleChangeStatus()
                : () => handleStatusBox(status)
            }
            ml={3}
          >
            {copy
              ? "Copiar"
              : deletes
                ? "Eliminar"
                : product
                  ? "Eliminar"
                  : "Desechar"}
          </Button>
        </div>
      </ModalGeneric>
    </>
  );
};

export default FormModalStatusDelete;
