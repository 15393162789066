import React, { useEffect, useState } from "react";

import { Search } from "./Search";
import { thead } from "./thead";
import { Title } from "styled/Title";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TbodyTable from "./TbodyTable";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormModal from "./FormModal";
import {
  __postFilterReportSecurity,
  __getSecurityEntryFiltro,
  __getSecurityOutputFiltro,
  __getVehicularDoorsEntry,
  __postExportReportSecurity,
  __getVehicularDoorsExit
} from "request/__Security";
import { useSelector } from "react-redux";
import { __TypeTransportNp } from "request/Pases/__CrudPass";
import SpinnerComponent from "components/Spinner/SpinnerComponent";

import FormExport from "./FormExport";
import { HeaderSearch } from "styled/SectionContainer.styled";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounce } from "hooks/useDebouce";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { isArray } from "utils/type-check-utils";
import { useDispatch } from "react-redux";
import { clearSearch, getSearch, setSearch } from "redux/actions/searchActions";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

/**
 *  ReportSecurity - Componente para mostrar los reportes de seguridad, filtrar por usuario calificado, opcion de busqueda y
 *  busqueda avanzada mediante un filtro(formulario)
 *
 * @component
 * @return {Component} Retorna componente JSX, muestra la data mediante una tabla
 */

const ReportSecurity = () => {
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [dataVehicheDoor, setDataVehicheDoor] = useState([]);
  const [vehicleDoor, setVehicleDoor] = useState(null);
  const [typeTranspor, setTypeTranspor] = useState([]);
  const [selectTypeTransport, setSelectTypeTransport] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [dataReport, setDataReport] = useState([]);
  const [valueQualified, setValueQuialified] = useState([]);
  const [selectRegister, setselectRegister] = useState(null);
  const dispatchRedux = useDispatch();
  const search = useSelector(getSearch);

  const [values, handleInputChange, reset] = useForm({
    search: "",
    // form
    driver: "",
    date_end: "",
    date_start: "",
    vehicle_plate: "",
    identification: ""
  });
  const [openAlert] = AlertErrorAlert();
  const { requestData } = useGlobalContext();
  const queryClient = useQueryClient();


  const backSearch = () => {
    reset();
    setValueQuialified([]);
    setChangeViewSearch(false);
    console.log('si funciono')
  };

  useEffect(() => {
    if (selectRegister && selectRegister.value === "entry") {
      getVehicularDoors();
    } else if (selectRegister && selectRegister.value === "output") {
      getVehicularDoorsExit();
    } else {
      setDataVehicheDoor([]);
      setVehicleDoor(null);
    }
  }, [selectRegister]);


  const destinyRoute = {
    "entry": environment.GET_SECURITY_ENTRY_FILTRO,
    "output": environment.GET_SECURITY_OUTPUT_FILTRO,
    "all": environment.GET_SECURITY_ENTR_AND_OUTPUT_FILTRO,
  }[selectRegister?.value || "all"];

  const handleSearch = () => {
    queryClient.invalidateQueries(["getSecurityData"]);
    onCloseSearch(false);
    changeViewSearch(true);
  }


  const { data, isLoading } = useQuery({
    queryKey: ["getSecurityData", page, valueQualified, freeZone_id, values?.search],
    queryFn: () => {
      const data = {
        ...(values?.search && {
          search: values?.search,
        }),
        ...(values?.driver && {
          driver: values?.driver,
        }),
        ...(values?.date_end && {
          date_end: values?.date_end,
        }),
        ...(selectRegister && {
          destiny: selectRegister?.value
        }),
        ...(values?.date_start && {
          date_start: values?.date_start,
        }),
        ...(values?.vehicle_plate && {
          vehicle_plate: values?.vehicle_plate,
        }),
        ...(values?.identification && {
          identification: values?.identification,
        }),
        ...(valueQualified.length !== 0 && {
          qualifiedUser_id: valueQualified.map((user) => Number(user.value)),
        }),
        ...(selectTypeTransport && {
          type_transport: selectTypeTransport?.value
        }),
        ...(vehicleDoor && {
          vehicularDoor_id: vehicleDoor?.value
        }),
        ...(isArray(valueQualified) && valueQualified.length !== 0 && { qualifiedUser_id: valueQualified.map(user => Number(user.value)) }),
      };

      dispatchRedux(setSearch(data));

      return requestData({
        Endpoint: destinyRoute,
        pageParam: page,
        data,
        PropertyBody: "POST",
      })
    }
  })

  useEffect(() => {
    if (data?.data?.status?.code === 200) {
      setDataReport(data?.data?.status?.data?.data);
      setMaxPage(data?.data?.status?.data?.last_page);
      return;
    }
    data?.data?.status?.message && openAlert(data?.data?.status?.message, "error");
    setDataReport([]);
    setMaxPage(1);

  }, [data]);

  useEffect(() => {
    return () => {
      dispatchRedux(clearSearch());
    }
  }, []);


  useEffect(() => {
    TypeTransporte();
  }, [freeZone_id]);

  const getVehicularDoors = async () => {
    try {
      const res = await __getVehicularDoorsEntry(token);
      const data = res?.data?.status?.data;
      const entryVehicle = data.map((item) => ({
        value: item?.id,
        label: item?.name_vehicularDoor
      }));
      setDataVehicheDoor(entryVehicle);
    } catch (error) {
      console.log(error);
    }
  };

  const getVehicularDoorsExit = async () => {
    try {
      const res = await __getVehicularDoorsExit(token);
      const data = res?.data?.status?.data;
      const entryVehicle = data.map((item) => ({
        value: item?.id,
        label: item?.name_vehicularDoor
      }));
      setDataVehicheDoor(entryVehicle);
    } catch (error) {
      console.log(error);
    }
  };

  const TypeTransporte = async () => {
    try {
      const res = await __TypeTransportNp(token);
      const data = res?.data?.status?.data;
      const typeTransport = data.map((item) => ({
        value: item?.id,
        label: item?.name_typeTransport
      }));
      setTypeTranspor(typeTransport);
    } catch (error) { }
  };

  const handleSubmitExport = async (fields, qualifiedUserId, entry_output) => {
    try {
      const data = {
        entry_output,
        qualifiedUser_id: qualifiedUserId,
        type: "xlsx",
        fields,
        data: search,
      };
      const res = await __postExportReportSecurity(token, data);
      if (res?.data?.status?.code === 200) {
        /* let url = res?.data; */
        /* window.open(url?.status?.data); */
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      {isLoading && <SpinnerComponent />}
      <Title style={{ marginTop: 30 }}>Reportes de Seguridad</Title>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormModal
          values={values}
          onClose={onCloseSearch}
          vehicleDoor={vehicleDoor}
          typeTranspor={typeTranspor}
          handleSubmit={handleSearch}
          setVehicleDoor={setVehicleDoor}
          selectRegister={selectRegister}
          dataVehicheDoor={dataVehicheDoor}
          setselectRegister={setselectRegister}
          handleInputChange={handleInputChange}
          selectTypeTransport={selectTypeTransport}
          setSelectTypeTransport={setSelectTypeTransport}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Filtro"
      >
        <FormExport
          onClose={onCloseExport}
          handleSubmit={handleSubmitExport}
          defaultRegister={selectRegister}
        />
      </ModalGeneric>

      <Search
        values={values}
        backSearch={backSearch}
        onOpenSearch={onOpenSearch}
        onOpenModalExport={onOpenExport}
        changeViewSearch={changeViewSearch}
        handleInputChange={handleInputChange}
        setValueQuialified={setValueQuialified}
        valueQualified={valueQualified}
      />
      <TableOrder
        thead={thead}
        loading={isLoading}
        data={dataReport}
        setData={setDataReport}
        tbodyData={TbodyTable}
      />
      <Pagination
        thead={thead}
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        loading={isLoading}
      />
    </HeaderSearch>
  );
};

export default ReportSecurity;
