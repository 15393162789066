import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { BiFilter } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { AuthContext } from "context/AuthContext";
import InputSelect, { InputSelectMulti } from "components/InputSelect/InputSelect";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";
import { CgImport } from "react-icons/cg";
import { RiSearch2Line } from "react-icons/ri";

/**
 *  Search - Componente de input y boton para el filtro y el exportar
 *
 * @component
 * @param {Function} handleInputChange Funcion que escucha los cambios del input.
 * @param {Array} values Estado inicial del formulario.
 * @param {Function} onOpenSearch Funcion para abrir los modales.
 * @param {Boolean} changeViewSearch Boolean para evaluar si esta editando.
 * @param {Function} backSearch Funcion limpiar el filtro.
 * @return {Component} Retorna componente de boton para filtrar.
 */

export const Search = ({
  values,
  children,
  backSearch,
  handleSubmit,
  onOpenSearch,
  valueQualified,
  changeViewSearch,
  onOpenModalExport,
  handleInputChange,
  setValueQuialified
}) => {
  const { searchInformacion } = values;
  const [search, setSearch] = useState({});
  const { authUser } = useContext(AuthContext);
  const [userQualified, setUserQuialified] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);

  const getUserQualified = useCallback(async () => {
    try {
      setLoading(true);
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setUserQuialified(infoUsuarios);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    setValueQuialified([])
    getUserQualified();
  }, [getUserQualified, freeZone_id]);

  return (
    <div className="row mb-3 mt-3">
      {!authUser.is_qualified_user && (
        <div className="px-5 col-6">
          <div className="row">
            <div className="col-sm mb-4">
              <div style={{ width: "100%" }}>
                <InputSelectMulti
                  tag={"Usuario calificado"}
                  data={userQualified}
                  set={setValueQuialified}
                  value={valueQualified}
                  isLoading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="px-5 d-flex align-bottom col-6">
        <form onSubmit={(e) => {
          e.preventDefault();
          handleInputChange({ target: { name: "search", value: search } });
        }}>

          <LabelGroup
            name={"search"}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            tag={"Buscar"}
            size={100}
            desicion={false}
          // placeholder={"Ingrese un registro"}
          />
        </form>

        <Tooltip placement="top" hasArrow label="Filtro">
          <span>
            <ButtonHeaderModule
              onClick={onOpenSearch}
              bgColor="#1F7AC3"
              className="ms-1 mt-4 align-bottom d-flex float-end"
            >
              <IconGeneric color="#fff" as={BiFilter} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>

        <Tooltip placement="top" hasArrow label="Exportar">
          <span>
            <ButtonHeaderModule
              onClick={onOpenModalExport}
              bgColor="#03C39A"
              className="ms-1 mt-4 align-bottom d-flex float-end"
            >
              <IconGeneric color="#fff" as={CgImport} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>

        {/* <Tooltip placement="top" hasArrow label="Filtro">
          <span>
            <ButtonHeaderModule
              onClick={onOpenSearch}
              bgColor="#1F7AC3"
              className="ms-1 mt-4 align-bottom d-flex float-end"
            >
              <IconGeneric color="#fff" as={BiFilter} />
            </ButtonHeaderModule>
          </span>
        </Tooltip> */}

        {changeViewSearch && (
          <Tooltip placement="top" hasArrow label="Limpiar filtro">
            <span>
              <ButtonHeaderModule
                onClick={backSearch}
                bgColor="#F9A621"
                className="ms-1 mt-4 align-bottom"
              >
                <IconGeneric color="#fff" as={AiOutlineClear} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        )}
        {children}
      </div>
    </div>
  );
};
