import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuProcess from "components/Menu/MenuProcess";

const TbodyOther = ({ data, iope }) => {
  return (
    <Tr>
      <Td className="text-center">{iope + 1}</Td>
      <Td className="text-center">{data?.name_cost}</Td>
    {/*   <Td className="text-center">{data?.quantity_cost} </Td> */}
      <Td className="text-center">{data?.net_weight_cost}</Td>
      <Td className="text-center">{data?.value_cost}</Td>
      <Td className="text-center">
        <MenuProcess data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyOther;
