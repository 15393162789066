import React, { useContext, useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Title } from "styled/Title";
import { renderInputWithLabel } from "../../ViewItems/ViewItemsEntryForm";

const FormViewFreeZone = ({ data, notations = null, activeReview = true }) => {
  const location = useLocation();
  const { pathname } = location;
  const { dataType, typeEntry, setTypeEntry } = useContext(FormCrudCRUDContext);

  const [Status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      data?.status_entryForm ? data?.status_entryForm : data?.status_outputForm
    );
  }, [data]);

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data
  };

  return (
    <div
      className="mx-1 shadow-none p-1 mb-5 rounded"
      style={{ background: "#f2f2f2" }}
    >
      <Title size={"18px"} weight={700} className="mb-2 ms-1">
        De Zona Franca a Zona Franca
      </Title>
      <SimpleGrid minChildWidth="200px" spacing="10px">
        <RadioCheck
          set={setTypeEntry}
          value={typeEntry}
          item={dataType}
          disables={true}
        />
      </SimpleGrid>

      {/* N° Factura Comercial */}
      {renderInputWithLabel({
        labelText: `Nº Factura Comercial`,
        name: "num_commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9"
        },
        wrapInDiv: false,
        disableFormatting: true
      })}

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Aut. de Salida */}
        {renderInputWithLabel({
          labelText: `Aut. de Salida`,
          name: "num_exit_form",
          manualValue: data?.num_exit_form ?? "N/A",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false
        })}

        {/* C. de Integración */}
        {renderInputWithLabel({
          labelText: `C. de Integración`,
          name: "integration_box_certificate",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false
        })}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {typeEntry !== "Planilla de envío" && (
          <>
            {/* Dcl. de Tránsito Aduanero */}
            {renderInputWithLabel({
              labelText:
                typeEntry === "DTA"
                  ? "Dcl. Tránsito aduanero"
                  : typeEntry === "Cabotaje"
                    ? typeEntry
                    : "",
              name:
                typeEntry === "DTA"
                  ? "dcl_customs_transit"
                  : typeEntry === "Cabotaje"
                    ? "cabotage"
                    : "",
              manualValue:
                typeEntry === "DTA"
                  ? data?.dcl_customs_transit
                    ? data?.dcl_customs_transit
                    : 0
                  : typeEntry === "Cabotaje"
                    ? data?.cabotage
                      ? data?.cabotage
                      : 0
                    : 0,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9",
                thousandSeparator: false,
              },
              wrapInDiv: false
            })}

            {/* Fecha Vencimiento Transito */}
            {renderInputWithLabel({
              labelText:
                typeEntry === "DTA"
                  ? "Fecha de vencimiento Tránsito"
                  : typeEntry === "Cabotaje"
                    ? `Fecha de vencimiento ${typeEntry}`
                    : "",
              name:
                typeEntry === "DTA"
                  ? "transit_expiration_date"
                  : typeEntry === "Cabotaje"
                    ? "cabotage_expiration_date"
                    : "",
              manualValue:
                typeEntry === "DTA"
                  ? data?.transit_expiration_date
                  : typeEntry === "Cabotaje"
                    ? data?.cabotage_expiration_date
                    : 0,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9"
              },
              wrapInDiv: false
            })}
          </>
        )}

        {/* "Nº Documento Transporte */}
        {renderInputWithLabel({
          labelText: `Nº Documento Transporte`,
          name: "num_dex_entryForm",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false
        })}
      </SimpleGrid>
    </div>
  );
};

export default FormViewFreeZone;
