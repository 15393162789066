/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Text, Box, Divider } from '@chakra-ui/react'
import { ButtonStyled } from 'styled/Buttons.styled'
import { AcuerdoC } from '../../../components/InputSelect/InputSelect'
import {
  __Acuerdos,
  __Subpartida
} from '../../../request/configuration/__ASubheading'
import ConfigurationCRUDContext from 'context/ConfigurationCRUDContext'
import { useSelector } from 'react-redux'
import { ControlSub, LabelGroup } from 'pages/RefProductInter/LabelGroup'
import useDebounceText from 'hooks/useSearchDebounce'
import { __ObtenerSubHeadingCode } from 'request/CustomsClearance.js/__CustomsClearence'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { InputSelect } from '../Style/StyledPage'
import { useDebounce } from 'hooks/useDebouce'
import { MaxLength } from 'utils/MaxLength'

const FormAComercialSubShow = () => {
  const [dataSub, setDataSub] = useState([])
  const [dataAc, setDataAc] = useState([])
  const [Acuerdos, setAcuerdos] = useState()
  const token = useSelector((state) => state.auth.dataUser.token)
  const [subheadingSearch, setSubheadingSearch] = useState('')
  const subheadingSearchDelay = useDebounce(subheadingSearch, 700)
  const [openAlert] = AlertErrorAlert()
  const [subheadingId, setSubheadingId] = useState('')
  const [subheadingCode, setSubheadingCode] = useState('')

  const { onCloseModal, createData, dataToEdit, editData } = useContext(
    ConfigurationCRUDContext
  )

  useEffect(() => {
    if (dataToEdit) {
      setSubheadingId(dataToEdit.subheading_id)
      setSubheadingCode(dataToEdit.code_subheading)
      setSubheadingSearch(dataToEdit.code_subheading)
      setAcuerdos({
        label: dataToEdit.code_tradeAgreement,
        value: dataToEdit.tradeAgreement_id
      })
    }
  }, [dataToEdit])

  const acuerdosC = useCallback(async () => {
    try {
      const res = await __Acuerdos(token)
      setDataAc(res?.data?.status?.data)
    } catch (error) {}
  }, [token])

  const SubpartidaC = useCallback(async () => {
    try {
      const res = await __Subpartida(token)
      setDataSub(res?.data?.status?.data)
    } catch (error) {}
  }, [token])

  useEffect(() => {
    acuerdosC()
    SubpartidaC()
  }, [])

  const handleSubmit = (e) => {
    if (!subheadingSearch) return openAlert('Ingrese una subpartida', 'error')
    if (!Acuerdos?.value) { return openAlert('Ingrese un acuerdo comercial', 'error') }

    const result = {
      subheading_id: subheadingId,
      code_subheading: subheadingCode,
      tradeAgreement_id: Acuerdos?.value,
      code_tradeAgreement: Acuerdos?.label
    }

    if (dataToEdit) {
      const data = {
        ...result,
        id: dataToEdit.id
      }
      editData(data)
    } else {
      createData(result)
    }
  }

  const obtenerSubpartida = useCallback(
    async (info) => {
      try {
        const res = await __ObtenerSubHeadingCode(token, info)
        const resultado = res?.data?.status
        if (resultado.code === 403) {
          openAlert(resultado.message, 'error')
          setSubheadingId()
          setSubheadingCode()
        } else {
          setSubheadingId(resultado.data.id)
          setSubheadingCode(resultado.data.code_subheading)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [openAlert, token]
  )

  useEffect(() => {
    if (subheadingSearch && subheadingSearch.length > 9) {
      const formData = new FormData()
      formData.append('code_subheading', subheadingSearch)
      obtenerSubpartida(formData)
    }
  }, [subheadingSearchDelay])

  return (
    <>
      <Text fontSize="md" mb={2} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? 'Editar subpartida con acuerdo comercial'
          : 'Crear subpartida con acuerdo comercial'}
      </Text>
      <Divider my={2} />
      <form action="">
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <LabelGroup
            name={'subheadingSearch'}
            onChange={(e) => {
              setSubheadingSearch(e.target.value)
            }}
            value={subheadingSearch}
            tag={'Subpartida'}
            size={45}
            desicion={false}
            tipo={'search'}
            maxLength={MaxLength?.global?.code_subheading}
          />

          {/*   <ControlSub
            partidas={dataSub}
            tag={"Subpartida"}
            subheadingN={subheadingN}
            setSubheadingN={setSubheadingN}
            setMendigo={setMendigo}
            mendigo={mendigo}
          /> */}

          <AcuerdoC
            data={dataAc}
            tag={'Acuerdo Comercial'}
            size={45}
            setAcuerdos={setAcuerdos}
            Acuerdos={Acuerdos}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={'wrap'}
          className="mt-5"
        >
          <ButtonStyled bgColor={'#bababa'} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  )
}

export default FormAComercialSubShow
