import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";

import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { Title } from "styled/Title";
import { renderInputWithLabel } from "../../ViewItems/ViewItemsEntryForm";

const FormViewFromUserToUser = ({
  data,
  notations = null,
  activeReview = true,
}) => {
  const location = useLocation();

  const { pathname } = location;
  const [Status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      data?.status_entryForm ? data?.status_entryForm : data?.status_outputForm
    );
  }, [data]);

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data,
  };

  return (
    <div className="mx-1 shadow-sm p-1 mb-4 " style={{ background: "#f2f2f2" }}>
      <Title size={"18px"} weight={700} className="mb-2">
        De Usuario a Usuario de la misma Zona Franca
      </Title>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Nit */}
        {renderInputWithLabel({
          labelText: `Nit`,
          name: "name_qualified2",
          manualValue: `${data?.nit_qualified2 ?? "N/A"} - ${data?.name_qualified2
            }`,
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {data.num_exit_form &&
          data.code_operation >= 700 &&
          data.code_operation <= 799 &&
          (Status !== "PRESENTADO" || pathname === "/FormShow" ? (
            <NavLink to={`/FormShowExit?ExitNumber=${data.num_exit_form_id}`}>
              {/* Formulario de salida asociada */}
              {renderInputWithLabel({
                labelText: `Formulario de salida asociada`,
                name: "num_exit_form",
                activeComment: isCheckFormExit || isCheckFormEntry,
                annotationInfo,
                additionalProps: {
                  background: "#F9F9F9",
                  cursor: "pointer",
                },
                wrapInDiv: false,
                disableFormatting: true,
              })}
            </NavLink>
          ) : (
            <>
              {/* Formulario de salida asociada */}
              {renderInputWithLabel({
                labelText: `Formulario de salida asociada`,
                name: "num_exit_form",
                activeComment: isCheckFormExit || isCheckFormEntry,
                annotationInfo,
                additionalProps: {
                  background: "#F9F9F9",
                  cursor: "not-allowed",
                },
                wrapInDiv: false,
                disableFormatting: true,
              })}
            </>
          ))}
      </SimpleGrid>

      {/* Formulario de salida asociada */}
      {renderInputWithLabel({
        labelText: `Factura comercial`,
        name: "num_commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
        },
        wrapInDiv: false,
        disableFormatting: true,
      })}
      {/* Nº Autorización de C. inte */}
      {
        renderInputWithLabel({
          labelText: `Nº Autorización de C. inte`,
          name: "integration_certificate",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false,
        })}
    </div>
  );
};

export default FormViewFromUserToUser;
