import { Box } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { TitleHeader } from "components/HeaderForm/Style";
import environment from "constants/apiConst";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
import { ButtonStyled } from "styled/Buttons.styled";
import queryString from "query-string";
import "../styleBox.css";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import ProductSearchBar from "./ProductSearchBar";
import { MaxLength } from "utils/MaxLength";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { useGlobalContext } from "context/GlobalContext";
import { safelyConvertToString } from "utils/type-check-utils";
import { parseFloat } from "utils/parseFloat";
import { NumericFormat } from "react-number-format";

const FormCorrectedProduct = ({ ...props }) => {
  const {
    dataIdProduct,
    setRequestCreate,
    setShowProduct,
    setActiveView,
    userQualifiedCorrect
  } = useContext(IntegracionBoxCrudContex);


  const [openAlert] = AlertErrorAlert();
  const location = useLocation();
  const {
    idCorrected = "",
    ActionCorrect = false,
  } = queryString.parse(location.search);
  const [decimal, setDecimal] = useState(null);

  const [values, handleInputChange, reset, setValues] = useForm({
    /* Old values: */
    item_code: null,
    product_code: "",
    product_description: "",
    subheading_code: "",
    quantity_product: "",
    business_unit_code: "",
    net_weight_kilo: "",
    fob_value: "",

    /* New values: */
    id_corrected: null,
    item_code_corrected: null,
    product_corrected_id: null,
    product_code_corrected: "",
    product_description_corrected: "",
    subheading_code_corrected: "",
    quantity_product_corrected: 0,
    business_unit_code_corrected: "",
    net_weight_kilo_corrected: 0,
    fob_value_corrected: 0,
    ...dataIdProduct,
  });

  const {
    item_code,
    product_code,
    product_description,
    subheading_code,
    quantity_product,
    business_unit_code,
    net_weight_kilo,
    fob_value,
  } = values;

  const {
    id_corrected,
    item_code_corrected,
    product_corrected_id,
    product_code_corrected,
    product_description_corrected,
    subheading_code_corrected,
    quantity_product_corrected,
    business_unit_code_corrected,
    net_weight_kilo_corrected,
    fob_value_corrected,
  } = values;

  const { requestData } = useGlobalContext();
  const queryClient = useQueryClient();
  const { data: dataOrderProduction, isLoading } = useQuery({
    queryKey: [queryEnv?.ORDER_PRODUCTION],
    queryFn: () => requestData({
      Endpoint: environment.GET_ORDER_PRODUCTION_CORRECTED_AND_OLD,
      data: {
        productCorrected_id: dataIdProduct?.productCorrected_id,
        integrationCertificateCorrected_id: idCorrected,
      },
      PropertyBody: "POST"
    })
  })

  const { mutate } = useMutation({
    mutationFn: requestData,
  })



  useEffect(() => {
    if (dataOrderProduction?.data?.status?.code !== 200) return openAlert(dataOrderProduction?.data?.status?.message);
    const data = dataOrderProduction?.data?.status?.data;
    setValues(data);
  }, [dataOrderProduction])

  useEffect(() => {
    setRequestCreate(environment.EDIT_ELABORATEDPRODUCT_OR_PRODUCT_CORRECTED);
  }, [setRequestCreate]);

  const handleClose = () => {
    setShowProduct(false);
    setActiveView(null)
    reset();
  };

  useEffect(() => {
    console.log({ product_corrected_id })
  }, [product_corrected_id])

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      Endpoint: environment.EDIT_ELABORATEDPRODUCT_OR_PRODUCT_CORRECTED,
      data: {
        productCorrected_id: product_corrected_id,
        id_corrected: id_corrected,
        quantity_product_corrected: parseFloat(quantity_product_corrected),
        quantity_corrected: parseFloat(quantity_product_corrected) * parseFloat(values?.product_convertion_factor_corrected),
        net_weight_kilo_corrected: parseFloat(net_weight_kilo_corrected),
        fob_value_corrected: parseFloat(fob_value_corrected),
      },
      PropertyBody: "POST",
    }, {
      onSuccess: (data) => {
        if (data?.data?.status?.code === 400) {
          return openAlert(data?.data?.status?.message, 'error')
        }
        if (data?.data?.status?.code === 200) {
          queryClient.invalidateQueries({ queryKey: [queryEnv?.PRODUCTS_CI] })
          queryClient.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] })
          queryClient.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] })
          queryClient.invalidateQueries({ queryKey: [queryEnv?.GARBAGE_CI] })
          handleClose();
          openAlert(data?.data?.status?.message, 'success')
        }
      }
    })


  };

  const { data: dataUC } = useQuery({
    queryKey: [queryEnv?.QUALIFIED_USERS],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
    })
  })

  useEffect(() => {
    if (dataUC?.data?.status?.code !== 200 && !dataUC?.data?.status?.data) return;
    const dataUser = dataUC?.data?.status?.data.find(({ id }) => id === userQualifiedCorrect?.value);
    setDecimal(dataUser?.decimal_values_precision);
  }, [setDecimal, dataUC, userQualifiedCorrect?.value]);


  const decimalScale = decimal || 2;

  const updateItemValue = (newValue, sourceEvent) => {
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue
    }
    handleInputChange(target);
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}

      {!isLoading && (
        <>
          <div className="d-flex justify-content-between ">
            <div className="col-6 borderforms">
              <div className="mb-3">
                <TitleHeader size={"22px"}>
                  {`Item original ${item_code ? `Nº ${item_code}` : ""}`}
                </TitleHeader>
              </div>
              <div>
                <LabelGroup
                  name={"product_code"}
                  value={product_code}
                  onChange={handleInputChange}
                  tag={"Producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(product_code) !== safelyConvertToString(product_code_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>
              <div>
                <LabelGroup
                  name={"product_description"}
                  value={product_description}
                  onChange={handleInputChange}
                  tag={"Descripción del producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(product_description) !== safelyConvertToString(product_description_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"subheading_code"}
                  value={subheading_code}
                  onChange={handleInputChange}
                  tag={"Subpartida"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(subheading_code) !== safelyConvertToString(subheading_code_corrected) && "BorderCorrect"
                  }
                  maxLength={MaxLength?.global?.code_subheading}
                />
              </div>
              <div>
                <LabelGroup
                  name={"quantity_product"}
                  value={quantity_product}
                  onChange={handleInputChange}
                  tag={"Cantidad de producto"}
                  size={90}
                  desicion={true}
                  changeValue={
                    parseFloat(quantity_product) !== parseFloat(quantity_product_corrected) && "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"business_unit_code"}
                  value={business_unit_code}
                  onChange={handleInputChange}
                  tag={"Unidad comercial"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(business_unit_code) !== safelyConvertToString(business_unit_code_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"net_weight_kilo"}
                  value={
                    net_weight_kilo
                    /* net_weight_kilo_old && handleFormat(net_weight_kilo_old) */
                  }
                  onChange={handleInputChange}
                  tag={"Peso neto en kilos"}
                  size={90}
                  desicion={true}
                  changeValue={
                    parseFloat(net_weight_kilo) !== parseFloat(net_weight_kilo_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <LabelGroup
                  name={"fob_value"}
                  value={fob_value}
                  onChange={handleInputChange}
                  tag={"Valor FOB en US$"}
                  size={90}
                  desicion={true}
                  changeValue={
                    parseFloat(fob_value) !== parseFloat(fob_value_corrected) && "BorderCorrect"
                  }
                />
              </div>
            </div>

            {/* Nueva data  */}
            <div className="col-6 borderforms">
              <div className="mb-3">
                <TitleHeader size={"22px"}>
                  Item Corregido Nº {item_code_corrected}
                </TitleHeader>
              </div>
              {ActionCorrect && ActionCorrect !== ""
                ? (
                  <div>
                    <LabelGroup
                      name={"product_code_corrected"}
                      value={product_code_corrected}
                      onChange={handleInputChange}
                      tag={"Producto corregido"}
                      size={90}
                      desicion={true}
                      changeValue={
                        safelyConvertToString(product_code) !== safelyConvertToString(product_code_corrected) &&
                        "BorderCorrect"
                      }
                    />
                  </div>
                )
                : (
                  <div>
                    <ProductSearchBar
                      name={"product_code_corrected"}
                      label="Producto corregido"
                      required={true}
                      value={{
                        label: product_code_corrected,
                        id: values?.product_corrected_id,
                      }}
                      onChange={(e) => {
                        console.log({ e })
                        setValues((values) => ({
                          ...values,
                          product_code_corrected: e?.code_product || "",
                          product_corrected_id: e?.value || "",
                          product_description_corrected: e?.description_product || "",
                          subheading_code_corrected: e?.code_subheading || "",
                          quantity_product_corrected: e !== null ? 0 : "",
                          business_unit_code_corrected: e?.businnesUnitSubheading || "",
                          net_weight_kilo_corrected: e !== null ? 0 : "",
                          fob_value_corrected: e !== null ? 0 : "",
                        }));
                      }}
                      className={
                        safelyConvertToString(product_code) !== safelyConvertToString(product_code_corrected) &&
                        "BorderCorrect"
                      }
                      size={90}
                    />
                  </div>
                )}

              <div>
                <LabelGroup
                  name={"product_description_corrected"}
                  value={product_description_corrected}
                  onChange={handleInputChange}
                  tag={"Descripción del producto corregido"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(product_description) !== safelyConvertToString(product_description_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>
              <div>
                <LabelGroup
                  name={"subheading_code_corrected"}
                  value={subheading_code_corrected}
                  onChange={handleInputChange}
                  tag={"Subpartida corregido"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(subheading_code) !== safelyConvertToString(subheading_code_corrected) && "BorderCorrect"
                  }
                  maxLength={MaxLength?.global?.code_subheading}
                />
              </div>

              <div>
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalScale}
                  decimalSeparator="."
                  customInput={LabelGroup}
                  changeValue={parseFloat(quantity_product) !== parseFloat(quantity_product_corrected) && "BorderCorrect"}
                  name={"quantity_product_corrected"}
                  value={quantity_product_corrected}
                  tag={"Cantidad de producto"}
                  size={90}
                />
              </div>

              <div>
                <LabelGroup
                  name={"business_unit_code_corrected"}
                  value={business_unit_code_corrected}
                  onChange={handleInputChange}
                  tag={"Unidad comercial corregido"}
                  size={90}
                  desicion={true}
                  changeValue={
                    safelyConvertToString(business_unit_code) !== safelyConvertToString(business_unit_code_corrected) &&
                    "BorderCorrect"
                  }
                />
              </div>

              <div>
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalScale}
                  decimalSeparator="."
                  customInput={LabelGroup}
                  changeValue={parseFloat(net_weight_kilo) !== parseFloat(net_weight_kilo_corrected) && "BorderCorrect"}
                  name={"net_weight_kilo_corrected"}
                  value={net_weight_kilo_corrected}
                  tag={"Peso neto en kilos corregido"}
                  size={90}
                />
              </div>

              <div>
                <NumericFormat
                  allowNegative={false}
                  onValueChange={updateItemValue}
                  thousandSeparator={true}
                  decimalScale={decimalScale}
                  decimalSeparator="."
                  customInput={LabelGroup}
                  changeValue={parseFloat(fob_value) !== parseFloat(fob_value_corrected) && "BorderCorrect"}
                  name={"fob_value_corrected"}
                  value={fob_value_corrected}
                  tag={"Valor FOB en US$ corregido"}
                  size={90}
                />
              </div>
            </div>
          </div>

          <div className="mb-3 ms-5">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap={"wrap"}
            >
              <ButtonStyled bgColor={"#bababa"} onClick={handleClose}>
                Cerrar
              </ButtonStyled>
              {!ActionCorrect && (
                <ButtonStyled type="submit" onClick={handleSubmit}>
                  Guardar
                </ButtonStyled>
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default FormCorrectedProduct;
