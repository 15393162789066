import environment from "constants/apiConst";

export const arrayProductionOrderNodeList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "qualifiedUser_id"
  },
  {
    value: "code_production_order",
    label: "Número formulario"
  },
  {
    value: "total_product",
    label: "Total producto"
  },
  {
    value: "status",
    label: "Estado"
  },
  {
    value: "code_product",
    label: "Código producto"
  }
]

export const arrayProductionOrderNodeListNoUC = [
  {
    value: "name_qualified",
    label: "Usuario calificado"
  },
  {
    value: "description_product",
    label: "Descripción producto"
  },
  {
    value: "code_productive_process",
    label: "Código proceso productivo"
  }
]

export const arrayProductionOrderNodeListUC = [
  {
    value: "description_product",
    label: "Descripción producto"
  },
  {
    value: "code_productive_process",
    label: "Código proceso productivo"
  }
]

export const arrayLeftOrder = [
  {
    value: "code_production_order",
    label: "Número formulario",
    name: "code_production_order"
  },
  {
    value: "total_product",
    label: "Total producto",
    name: "total_product"
  },
  {
    value: "status",
    label: "Estado",
    name: "status"
  },
  {
    value: "code_product",
    label: "Código producto",
    name: "code_product"
  }
];

export const arrayRigthOrder = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified"
  },
  {
    value: "description_product",
    label: "Descripción producto",
    name: "description_product"
  },
  {
    value: "code_productive_process",
    label: "Código proceso productivo",
    name: "code_productive_process"
  }
];

export const arrayRigthUserOrder = [
  {
    value: "description_product",
    label: "Descripción producto",
    name: "description_product"
  },
  {
    value: "code_productive_process",
    label: "Código proceso productivo",
    name: "code_productive_process"
  }
];

export const productionOrdenNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "AUTORIZADO", label: "AUTORIZADO" }
    ],
    value: null
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]
