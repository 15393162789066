import { Td, Tr } from "@chakra-ui/react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const TbodyInteroperability = ({ data }) => {
  const navigate = useNavigate();

  const urlForm = {
    "S": `/FormShowExit?ExitNumber=${data?.code_form_id}`,
    "MS": `/FormShowExit?ExitNumber=${data?.code_form_id}`,
    "I": `/FormShow?FormEntry=${data?.code_form_id}`,
    "MI": `/FormShow?FormEntry=${data?.code_form_id}`,
  }[data?.numeration];

  const urlCorrectedForm = {
    "S": `/FormCorrectionOutput?ShowOutput=${data?.code_form_corrected_id}`,
    "MS": `/FormCorrectionOutput?ShowOutput=${data?.code_form_corrected_id}`,
    "I": `/FormCorrection?ShowEntry=${data?.code_form_corrected_id}`,
    "MI": `/FormCorrection?ShowEntry=${data?.code_form_corrected_id}`,
  }[data?.numeration];

  const handleInfoForm = () => {
    if (data?.numeration === "S" || data?.numeration === "MS") {
      navigate(`/FormShowExit?ExitNumber=${data?.code_form_id}`);
    } else if (data?.numeration === "I" || data?.numeration === "MI") {
      navigate(`/FormShow?FormEntry=${data?.code_form_id}`);
    }
  };
  const handleInfoCorrectedForm = () => {
    if (data?.numeration === "S" || data?.numeration === "MS") {
      navigate(`/FormCorrectionOutput?ShowOutput=${data?.code_form_corrected_id}`);
    } else if (data?.numeration === "I" || data?.numeration === "MI") {
      navigate(`/FormCorrection?ShowEntry=${data?.code_form_corrected_id}`);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.date_sending} {data?.time_sending}</Td>
      <Td className="text-center">
        <NavLink
          to={urlForm}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_form}</span>
        </NavLink>
      </Td>
      <Td className="text-center">
        <NavLink
          to={urlCorrectedForm}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_form_corrected_id}</span>
        </NavLink>
      </Td>
      {/*    <Td className="text-center" onClick={handleInfoForm}><span className="formNumber">{data?.code_form}</span></Td>
      <Td className="text-center" onClick={handleInfoCorrectedForm}><span className="formNumber">{data?.code_form_corrected_id}</span></Td> */}
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.code_operation}</Td>
      <Td className="text-center">{data?.code_resp}</Td>
      <Td className="text-center">{data?.message}</Td>
      <Td className="text-center">{data?.description}</Td>
    </Tr>
  );
};

export default TbodyInteroperability;
