import React, { useContext, useEffect, useState } from "react";
import { Box, Icon, Spacer, Stack, Tooltip } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { MdOutlineSearch, MdOutlineSearchOff } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import SearchForm from "components/SearchForm/SearchForm";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { TitleBorderBottom } from "styled/Title";
import { Storage } from "hooks/useStorage";
import { useSpring, animated } from "@react-spring/web";
import { useLocation, useNavigate } from "react-router-dom";
// const ConfigurationTitle = styled.h4`
//   display: inline;
//   font-weight: 700;
//   text-transform: uppercase;
//   border-bottom: 1px solid #171A22
// `;

const RigthSide = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChildrenLeft = styled(Box)`
  margin-left: 3rem;
`;

const ConfigurationHeader = ({ title, children, aggregator = true }) => {
  const { onOpenEdit } = useContext(ConfigurationCRUDContext);
  const { get, set } = Storage;
  const [buttonChange, setButtonChange] = useState(get("buttonChange"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleButtonChange = (e) => {
    setButtonChange(!buttonChange);
  };

  useEffect(() => {
    set("buttonChange", buttonChange);
    if (!buttonChange) navigate(`?`);
  }, [buttonChange]);

  const buttonProps = useSpring({
    transform: buttonChange ? "scale(0.9)" : "scale(1)",
    config: { tension: 200, friction: 20 }
  });

  const navigation = () => {
    if (pathname === "/config/roles") {
      return navigate("/config/manageRoles");
    }

    return onOpenEdit();
  };

  return (
    <Stack
      direction={["column", "row"]}
      style={{ margin: "1rem auto 2rem auto" }}
    >
      <LeftSide>
        <TitleBorderBottom style={{ color: "#171A22" }}>
          {title}
        </TitleBorderBottom>
        <ChildrenLeft>{children}</ChildrenLeft>
      </LeftSide>
      <Spacer />
      <RigthSide>
        {buttonChange && <SearchForm handleButtonChange={handleButtonChange} />}

        <animated.div style={buttonProps}>
          {buttonChange
            ? (
              <MdOutlineSearchOff
                style={{
                  width: "34px",
                  height: "34px",
                  background: "#e2e2e2",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "#78767c",
                  padding: 2,
                  margin: "0px 1rem 0px 0px"
                }}
                onClick={handleButtonChange}
              />
            )
            : (
              <MdOutlineSearch
                style={{
                  width: "34px",
                  height: "34px",
                  background: "#e2e2e2",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "#78767c",
                  padding: 2,
                  margin: "0px 1rem 0px 0px"
                }}
                onClick={handleButtonChange}
              />
            )}
        </animated.div>

        {aggregator && (
          <Tooltip label="Agregar">
            <span>
              {/*  <IconGeneric as={HiOutlinePlus} onClick={onOpenEdit} /> */}
              <HiOutlinePlus
                style={{
                  width: "34px",
                  height: "34px",
                  background: "#1F7AC3",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "white",
                  padding: 2,
                  margin: "0px 1rem 0px 0px"
                }}
                onClick={navigation}
              />
            </span>
          </Tooltip>
        )}
      </RigthSide>
    </Stack>
  );
};

export default ConfigurationHeader;
