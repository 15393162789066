import { useContext } from "react";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { AuthContext } from "context/AuthContext";
import { GiCardDiscard } from "react-icons/gi";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";

export const ButtonsBorrador = ({
  handleCopy,
  handleEdit,
  handlePresent,
  handleEditCorrected,
  handleChangeStatusCorrected
}) => {
  const location = useLocation();
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()
  const { onOpenDeleteBox } = useContext(IntegracionBoxCrudContex);

  const handleDesechar = async () => {
    const body = new FormData();
    body.append("form_id", id);
    body.append("type_of", ('CI'));
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      onOpenDeleteBox();
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  };

  const handleClickEdit = async (type) => {
    const identifiers = {
      CIC: idCorrected,
      CI: id
    }
    const body = new FormData();
    body.append("form_id", identifiers[type]);
    body.append("type_of", type);
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      if (type === 'CIC') {
        return handleEditCorrected()
      }
      if (type === 'CI') {
        return handleEdit()
      }
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleClickPresent = async (type) => {
    const identifiers = {
      CIC: idCorrected,
      CI: id
    }
    const body = new FormData();
    body.append("form_id", identifiers[type]);
    body.append("type_of", type);
    body.append("type", 1);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000);
      if (type === 'CIC') {
        return handleChangeStatusCorrected("PRESENTADO")
      }
      if (type === 'CI') {
        return handlePresent()
      }
    } if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  return (
    <>
      {idCorrected
        ? (
          <>
            <Tooltip placement="top" hasArrow label="Editar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleClickEdit('CIC')}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric className="m-1 text-white" as={RiEdit2Fill} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Presentar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleClickPresent("CIC")}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={AiOutlineFileDone}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </>
        )
        : (
          <>
            <Tooltip placement="top" hasArrow label="Editar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleClickEdit('CI')}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric className="m-1 text-white" as={RiEdit2Fill} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Copiar">
              <span>
                <ButtonHeaderModule
                  onClick={handleCopy}
                  className="m-2"
                  bgColor="#03C39A"
                >
                  <IconGeneric className="m-1 text-white" as={MdContentCopy} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Presentar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleClickPresent('CI')}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={AiOutlineFileDone}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Desechar">
              <span>
                <ButtonHeaderModule
                  onClick={() => handleDesechar()}
                  className="m-2"
                  bgColor="#d92525"
                >
                  <IconGeneric className="m-1 text-white" as={GiCardDiscard} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </>
        )}
    </>
  );
};
