/* eslint-disable no-unused-vars */
import React from 'react'
import { Td, Tr } from '@chakra-ui/react'
import { SwitchPropiosMedios } from 'pages/RefProductInter/LabelGroup'
import { useEffect } from 'react'
import { useState } from 'react'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'

const TbodyNotifEmpresa = ({ data, userId }) => {
  const [emailCheck, setEmailCheck] = useState(0)
  const [emailCheckNotificar, setEmailCheckNotificar] = useState(0)
  const token = useSelector(state => state.auth.dataUser.token)

  useEffect(() => {
    if (data?.config !== null) {
      const { email_company_notify, notification_company_notify } = data?.config

      setEmailCheck(Number(email_company_notify))
      setEmailCheckNotificar(Number(notification_company_notify))
    }
  }, [data])

  const updateConfig = async data => {
    try {
      console.log('data', data)
      const res = await __Post(
        environment.UPDATE_NOTIFICATION_CONFI_EMPRESA,
        token,
        data
      )
      const restData = res?.data?.status
    } catch (error) {
      console.log(error)
    }
  }

  const insertConfig = async data => {
    try {
      const res = await __Post(
        environment.INSERT_NOTIFICATION_CONFI_EMPRESA,
        token,
        data
      )
      const restData = res?.data?.status
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e, state) => {
    const id = data?.config?.id;
    if (id) {
      updateConfig({ id, [e]: state })
    } else {
      insertConfig({ 
        companyNotificationType_id: data.id,
        user_id: userId,
        [e]: state 
      })
    }
  }

  return (
    <Tr>
      <Td>{data?.name}</Td>

      <Td className='d-flex justify-content-center'>
        <SwitchPropiosMedios
          status={emailCheckNotificar}
          setStatus={setEmailCheckNotificar}
          name='notification_company_notify'
          tag={''}
          handleUpdate={handleChange}
          margin
        />
      </Td>

      <Td>
        <SwitchPropiosMedios
          status={emailCheck}
          setStatus={setEmailCheck}
          name='email_company_notify'
          tag={''}
          handleUpdate={handleChange}
          margin
        />
      </Td>
    </Tr>
  )
}

export default TbodyNotifEmpresa
