import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { __Post } from 'request/Petitions/__Post'
import RiskLevels from './RiskLevels'
import ViewControlAnalysis from './ViewControlAnalysis'
import queryString from 'query-string'
import { __Get } from 'request/Petitions/__Get'
import { useQuery } from '@tanstack/react-query'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { Box, Grid, GridItem, Icon, Text, Tooltip } from '@chakra-ui/react'
import { InputSelectMulti } from 'components/InputSelect/InputSelect'
import Information from './Information'
import { BsCalendar4Event } from 'react-icons/bs'
import { ExportPdf } from 'components/Export/ExportPdf'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { VscFilePdf } from 'react-icons/vsc'
import ExportShowRiskLevel from './UsersAccordingToCriteria/Components/ExportShowRiskLevel'

export const Level = level => {
  switch (level) {
    case 'ALTO':
      return 0
    case 'MODERADO':
      return 1
    case 'LEVE':
      return 2
    default:
      return null
  }
}

function ShowRiskLevel () {
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const location = useLocation()
  const { QualifiedId } = queryString.parse(location.search)
  const [Criteria, setCriteria] = useState([])
  const [ListCriteria, setListCriteria] = useState([])
  const [ListRiskLevel, setListRiskLevel] = useState([])
  const [RiskLevel, setRiskLevel] = useState([])

  // SHOW_CONTROL_MATRIX_QUALIFIED
  const [Detail, setDetail] = useState(null)
  const [Loading, setLoading] = useState(null)

  const {
    isLoading,
    data: dataFilter,
    isError,
    error
  } = useQuery({
    queryKey: ['Filter'],
    queryFn: () => __Get(environment.CONTROL_FILTER_ELEMNT, token),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (dataFilter?.data?.status?.data) {
      const { criteria, risk_level } = dataFilter?.data?.status?.data

      if (criteria) {
        const infoZf = criteria.map(({ criteria_name, id }) => ({
          label: criteria_name,
          value: id
        }))
        setCriteria(infoZf)
      }

      if (risk_level) {
        const infoZf = risk_level.map(x => ({
          label: x,
          value: Level(x)
        }))
        setRiskLevel(infoZf)
      }
    }
  }, [dataFilter])

  const handleListCriteria = item => {
    setListCriteria(item)
  }

  const handletListRiskLevel = item => {
    setListRiskLevel(item)
  }

  const ShowQualified = async informacion => {
    setLoading(true)
    try {
      const res = await __Post(
        environment.SHOW_CONTROL_MATRIX_QUALIFIED,
        token,
        informacion
      )
      const status = res?.data?.status
      if (res?.data?.status?.message === 'Acceso no autorizado.') {
        openAlert(res.data.status.message, 'error')
      } else {
        setDetail(status?.data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const ListCriteriaId = ListCriteria.map(x => x.value)
    const ListRiskLevelId = ListRiskLevel.map(x => x.value)

    const Config = {
      qualifiedUser_id: QualifiedId,
      criteria_id: ListCriteriaId,
      risk_level: ListRiskLevelId
    }

    ShowQualified(Config)
  }, [QualifiedId, ListCriteria, ListRiskLevel])

  if (isLoading || Loading) return <SpinnerComponent />

  return (
    <div>
      <ExportShowRiskLevel id={'exportComponents'} Detail={Detail}/>{' '}
      <Box display={'flex'} justifyContent={'space-between'} m={6} p={4} mb={0}>
        <Text fontSize={14} fontWeight={500} color={'gray'}>
          <Icon as={BsCalendar4Event} boxSize={6} color={'gray'} pr={1} />
          {Detail?.detailed_analysis?.date}
        </Text>

        <Text fontSize={14} fontWeight={500} color={'gray'}>
          {Detail?.shortName_freeZone}
        </Text>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        m={6}
        p={4}
        mt={0}
        bg={'gray.100'}
        borderTop={'1px solid gray'}
      >
        <Text color={'gray'}>
          Usuario <br />
          {Detail?.name_qualified}
        </Text>
        <Text color={'gray'}>
          Calificación <br />
          {Detail?.name_activity ?? 'N/A'}
        </Text>
      </Box>
      {/* Filtro y exportar PDF */}
      <Text fontSize={26} fontWeight={600}>
        Resumen de criterios
      </Text>
      <Box display={'flex'} justifyContent={'space-between'} m={6} p={4} mt={0}>
        <Box display={'flex'} gap={4}>
          <InputSelectMulti
            tag={'Seleccionar Criterio'}
            data={Criteria}
            set={handleListCriteria}
            value={ListCriteria}
            style={{
              width: '230px'
            }}
          />

          <InputSelectMulti
            tag={'Seleccionar nivel de riesgo'}
            data={RiskLevel}
            set={handletListRiskLevel}
            value={ListRiskLevel}
          />
        </Box>

        <Tooltip placement='top' hasArrow label='Exportar PDF'>
          <span>
            <ButtonHeaderModule
              onClick={() =>
                ExportPdf('exportComponents', 'Resumen de criterios')
              }
              className='m-1'
              bgColor='#03C39A'
            >
              <IconGeneric className=' text-white' as={VscFilePdf} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      </Box>
      <RiskLevels Detail={Detail} />
      <Grid
        gap={4}
        p={4}
        mt={0}
        templateColumns='repeat(auto-fit, minmax(500px, 1fr))'
      >
        <GridItem>
          <ViewControlAnalysis Detail={Detail} />
        </GridItem>
        <GridItem>
          {' '}
          <Information />
        </GridItem>
      </Grid>
    </div>
  )
}

export default ShowRiskLevel
