import { routeConf } from "./ConfigurationRoutes";
import { privateRouterZFEdit, privateRouteZF } from "./RoutePrivateZF";
import { routeSecurity } from "./SecurityRoute";

// const principalRoute = privateRouteZF?.map((element) => ({
//   path: element?.path,
//   breadcrumbName: element?.breadcrumbName,
// }));

// const confRoute = routeConf?.map((element) => ({
//   path: '/config' + element?.path,
//   breadcrumbName: element?.breadcrumbName,
// }));

// export const breadCrumbRoute = [
//   {
//     path: "/",
//     breadcrumbName: "Inicio",
//   },

//   ...principalRoute,
//   ...confRoute,
// ];

export const breadCrumbRoute = () => {
  const principalRoute = privateRouterZFEdit?.map((element) => ({
    path: element?.path,
    breadcrumbName: element?.breadcrumbName
  }));

  const confRoute = routeConf?.map((element) => ({
    path: "/config" + element?.path,
    breadcrumbName: element?.breadcrumbName
  }));

  const securityRoute = routeSecurity?.map((element) => ({
    path: "/security" + element?.path,
    breadcrumbName: element?.breadcrumbName
  }));

  return [
    {
      path: "/",
      breadcrumbName: "Inicio"
    },

    ...principalRoute,
    ...confRoute,
    ...securityRoute
  ];
};
