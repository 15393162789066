/* eslint-disable no-useless-concat */

import { Textarea } from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { LabeFormlStyled } from "styled/Input.styled";
import { safelyConvertToString } from "utils/type-check-utils";

export const FmmItemOriginal = ({
  data,
  suma,
  multiplicacion,
  sumaCorregida,
  multiplicacionCorregida
}) => {
  const {
    reference_description,
    product_code,
    factor_conv,
    product_quantity,
    code_subheading,
    name_country,
    code_country,
    code_country_purs,
    name_country_purs,
    quantity,
    gross_weight,
    net_weight,
    number_packages,
    code_businessUnit,
    code_transportMethod_corrected,
    name_transportMethod_corrected,
    code_contry_flag_corrected,
    name_country_flag_corrected,
    code_packaging_corrected,
    name_packaging_corrected,
    us_freight,
    us_insurance,
    code_unit_measurement,
    other_us_expenses,
    us_fob_value,
    exchange_rate,
    code_country_des,
    name_country_des,
    code_unit_measurement_corrected,
    factor_conv_corrected,
    product_quantity_corrected,
    code_subheading_corrected,
    quantity_corrected,
    gross_weight_corrected,
    net_weight_corrected,
    number_packages_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    exchange_rate_corrected,
    adjustment_justification_fob,
    added_value_us,
    refund_value_us,
    adjustment_justification_fob_corrected,
    added_value_us_corrected,
    refund_value_us_corrected,
    output_form_item_description,
    output_form_item_description_corrected,
    brand,
    brand_corrected,
    serial,
    serial_corrected,
    customs_clearance,
    value_sdo,
    initial_operation,
    operation_code,
    value_sdo_product,
    fob_sdo_value,
    cif_value_sdo,
    code_tradeAgreement,
    name_tradeAgreement,
    original_fob_value_us,
    code_product,
    invoice,
    invoice_corrected,
    name_transportMethod,
    code_transportMethod,
    name_country_flag,
    code_contry_flag,
    name_packaging,
    code_packaging,
    product_id,
    value_sdo_product_corrected,
    value_sdo_corrected
  } = data;

  const {
    dataCountryOrigin,
    dataCountryBuy,
    info,
    valueProducts,
    packagings,
    valueFlag,
    valueMethod,
    dataToEditItems,
    dataToCopyItems
  } = useContext(FormCrudCRUDContext);

  const originCountry = `${name_country ? (code_country && code_country + " ") + name_country : ""}`;
  const purchaseCountry = `${name_country_purs ? (code_country_purs && code_country_purs + " ") + name_country_purs : ""}`;
  const destinyCountry = `${name_country_des ? (code_country_des && code_country_des + " ") + name_country_des : ""}`;
  const mode = `${name_transportMethod ? (code_transportMethod && code_transportMethod + " ") + name_transportMethod : ""}`;
  const flag = `${name_country_flag ? (code_contry_flag && code_contry_flag + " ") + name_country_flag : ""}`;
  const packing = `${name_packaging ? (code_packaging && code_packaging + " ") + name_packaging : ""}`;

  return (
    <>
      <LabelGroup
        name={"reference_description"}
        value={`${reference_description + (customs_clearance ? " Dcl-" + customs_clearance : "")}`}
        tag={"Referencia"}
        size={100}
        desicion={true}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          product_id !== valueProducts?.value &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"customs_clearance"}
        value={customs_clearance}
        tag={"Declaración de importación"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"value_sdo"}
        value={value_sdo}
        changeValue={
          parseFloat(value_sdo) !== parseFloat(value_sdo_corrected) && "BorderCorrect"
        }
        tag={"Saldo"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"value_sdo"}
        value={value_sdo}
        tag={"Saldoa"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"operation_code"}
        value={operation_code}
        tag={"Oper"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"initial_operation"}
        value={initial_operation}
        tag={"Inicial"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"product_code"}
        value={product_code ?? code_product}
        tag={"Código producto"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"Saldo producto"}
        changeValue={
          parseFloat(value_sdo_product_corrected) !== parseFloat(value_sdo_product) && "BorderCorrect"
        }
        value={value_sdo_product || ""}
        tag={"Cantidad de producto"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"value_sdo_product"}
        value={value_sdo_product}
        tag={"Saldo producto"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"code_unit_measurement"}
        value={code_unit_measurement || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_unit_measurement) !== safelyConvertToString(code_unit_measurement_corrected) &&
          "BorderCorrect"
        }
        tag={"Unidad de producto"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"factor_conv"}
        value={factor_conv || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(factor_conv) !== safelyConvertToString(factor_conv_corrected) &&
          "BorderCorrect"
        }
        tag={"Factor de conversión"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"product_quantity"}
        value={product_quantity || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(product_quantity) !== parseFloat(product_quantity_corrected) && "BorderCorrect"
        }
        tag={"Cantidad de producto"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"code_subheading"}
        value={code_subheading || ""}
        tag={"Subpartida arancelaria"}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(code_subheading) !== safelyConvertToString(code_subheading_corrected) &&
          "BorderCorrect"
        }
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"paisOrigen"}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(originCountry) !== safelyConvertToString(info?.label) &&
          "BorderCorrect"
        }
        value={originCountry || ""}
        tag={"País origen"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(purchaseCountry) !== safelyConvertToString(dataCountryBuy?.label) &&
          "BorderCorrect"
        }
        name={"paisCompra"}
        value={purchaseCountry || ""}
        tag={"País compra"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(destinyCountry) !== safelyConvertToString(dataCountryOrigin?.label) &&
          "BorderCorrect"
        }
        name={"paisDestiny"}
        value={destinyCountry || ""}
        tag={"País destino"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"quantity"}
        value={quantity || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(quantity) !== parseFloat(quantity_corrected) &&
          "BorderCorrect"
        }
        tag={"Cantidad"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          quantity !== quantity_corrected &&
          "BorderCorrect"
        }
        name={"quantity"}
        value={quantity ? quantity : ""}
        tag={"Cantidad"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"gross_weight"}
        value={gross_weight || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(gross_weight) !== parseFloat(gross_weight_corrected) &&
          "BorderCorrect"
        }
        tag={"Peso bruto"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"gross_weight"}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          gross_weight !== gross_weight_corrected &&
          "BorderCorrect"
        }
        value={gross_weight ? gross_weight : ""}
        tag={"gross_weight"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"net_weight"}
        value={net_weight || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(net_weight) !== parseFloat(net_weight_corrected) &&
          "BorderCorrect"
        }
        tag={"Peso neto (Calc:0)"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"net_weight"}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          net_weight !== net_weight_corrected &&
          "BorderCorrect"
        }
        value={net_weight ? net_weight : ""}
        tag={"Peso neto (Calc:0)"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"number_packages"}
        value={number_packages || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(number_packages) !== parseFloat(number_packages_corrected) &&
          "BorderCorrect"
        }
        tag={"Número de bultos"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          number_packages !== number_packages_corrected &&
          "BorderCorrect"
        }
        name={"number_packages"}
        value={number_packages ? number_packages : ""}
        // onChange={(e) => OnlyNumberInput(e, setValues, values)}
        tag={"Número de bultos"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"code_businessUnit"}
        value={code_businessUnit}
        tag={"Unidad comercial"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"modo"}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(mode) !== safelyConvertToString(valueMethod?.label) &&
          "BorderCorrect"
        }
        value={mode}
        tag={"Modo de transporte"}
        size={100}
        desicion={true}
      />

      <LabelGroup
        name={"flagBandera"}
        value={flag}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(flag) !== safelyConvertToString(valueFlag?.label) &&
          "BorderCorrect"
        }
        tag={"Bandera"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        name={"embalaje"}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(packing) !== safelyConvertToString(packagings?.label) &&
          "BorderCorrect"
        }
        value={packing}
        tag={"Embalaje"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_freight"}
        value={us_freight || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_freight) !== parseFloat(us_freight_corrected) &&
          "BorderCorrect"
        }
        tag={"Flete"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          us_freight !== us_freight_corrected &&
          "BorderCorrect"
        }
        name={"us_freight"}
        value={us_freight ? us_freight : ""}
        tag={"Flete"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_insurance"}
        value={us_insurance || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_insurance) !== parseFloat(us_insurance_corrected) &&
          "BorderCorrect"
        }
        tag={"Seguros"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"us_insurance"}
        value={us_insurance ? us_insurance : ""}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          us_insurance !== us_insurance_corrected &&
          "BorderCorrect"
        }
        tag={"Seguros"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"other_us_expenses"}
        value={other_us_expenses || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(other_us_expenses) !== parseFloat(other_us_expenses_corrected) &&
          "BorderCorrect"
        }
        tag={"Otros gastos"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"other_us_expenses"}
        value={other_us_expenses ? other_us_expenses : ""}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          other_us_expenses !== other_us_expenses_corrected &&
          "BorderCorrect"
        }
        tag={"Otros gastos"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"original_fob_value_us"}
        value={original_fob_value_us ?? 0}
        tag={"Valor FOB original US"}
        size={100}
        desicion={true}
      />

      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"us_fob_value"}
        value={us_fob_value || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(us_fob_value) !== parseFloat(us_fob_value_corrected) &&
          "BorderCorrect"
        }
        tag={"Valor FOB US$"}
        size={100}
        desicion={true}
      />

      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"cif_us_value"}
        value={suma || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(suma) !== parseFloat(sumaCorregida) &&
          "BorderCorrect"
        }
        tag={"Valor CIF US$"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"cif_us_value"}
        value={suma ? suma : ""}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          suma !== sumaCorregida &&
          "BorderCorrect"
        }
        // onChange={formaterCant(suma?.toFixed(2))}
        tag={"Valor CIF US$"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"exchange_rate"}
        value={exchange_rate || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(exchange_rate) !== parseFloat(exchange_rate_corrected) &&
          "BorderCorrect"
        }
        tag={"Tasa de Cambio"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"exchange_rate"}
        value={exchange_rate}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          exchange_rate !== exchange_rate_corrected &&
          "BorderCorrect"
        }
        tag={"Tasa de Cambio"}
        size={100}
        desicion={true}
      /> */}

      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"cif_cop_value"}
        value={multiplicacion || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(multiplicacion) !== parseFloat(multiplicacionCorregida) &&
          "BorderCorrect"
        }
        tag={"Valor CIF COL$"}
        size={100}
        desicion={true}
      />

      {/* <LabelGroup
        name={"cif_cop_value"}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          multiplicacion !== multiplicacionCorregida &&
          "BorderCorrect"
        }
        value={
          multiplicacion
            ? new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "COP",
            }).format(multiplicacion)
            : ""
        }
        tag={"Valor CIF COL$"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"added_value_us"}
        value={added_value_us || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(added_value_us) !== parseFloat(added_value_us_corrected) &&
          "BorderCorrect"
        }
        tag={"Valor agregado US$"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"added_value_us"}
        value={added_value_us}
        // onChange={handleInputChange}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          added_value_us !== added_value_us_corrected &&
          "BorderCorrect"
        }
        tag={"Valor agregado US$"}
        size={100}
        desicion={true}
      /> */}

      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"refund_value_us"}
        value={refund_value_us || ""}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          parseFloat(refund_value_us) !== parseFloat(refund_value_us_corrected) &&
          "BorderCorrect"
        }
        tag={"Valor a Reintegrar US$"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"refund_value_us"}
        value={refund_value_us}
        changeValue={
          dataToEditItems &&
          dataToCopyItems &&
          refund_value_us !== refund_value_us_corrected &&
          "BorderCorrect"
        }
        // onChange={handleInputChange}
        tag={"Valor a Reintegrar US$"}
        size={100}
        desicion={true}
      /> */}

      <LabelGroup
        name={"name_tradeAgreement"}
        value={
          code_tradeAgreement
            ? code_tradeAgreement + " " + name_tradeAgreement
            : ""
        }
        tag={"Acuerdo Comercial"}
        size={100}
        desicion={true}
      />
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"fob_sdo_value"}
        value={fob_sdo_value}
        tag={"Saldo FOB"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"fob_sdo_value"}
        value={fob_sdo_value}
        tag={"Saldo FOB"}
        size={100}
        desicion={true}
      /> */}
      <NumericFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalSeparator="."
        customInput={LabelGroup}
        name={"cif_value_sdo"}
        value={cif_value_sdo}
        tag={"Saldo CIF"}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        name={"cif_value_sdo"}
        value={cif_value_sdo}
        tag={"Saldo CIF"}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        name={"adjustment_justification_fob"}
        value={adjustment_justification_fob}
        tag={"Justificación ajusté FOB"}
        size={100}
        desicion={true}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(adjustment_justification_fob) !==
          safelyConvertToString(adjustment_justification_fob_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        changeValue={safelyConvertToString(invoice) !== safelyConvertToString(invoice_corrected) && "BorderCorrect"}
        name={"invoice"}
        value={invoice}
        tag={"Factura"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={safelyConvertToString(brand) !== safelyConvertToString(brand_corrected) && "BorderCorrect"}
        name={"brand"}
        value={brand}
        tag={"Marca"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={safelyConvertToString(serial) !== safelyConvertToString(serial_corrected) && "BorderCorrect"}
        name={"serial"}
        value={serial}
        tag={"Serial"}
        size={100}
        desicion={true}
      />
      <LabeFormlStyled>Descripción larga</LabeFormlStyled>
      <Textarea
        name={"output_form_item_description"}
        value={output_form_item_description}
        changeValue={
          /* dataToEditItems &&
          dataToCopyItems && */
          safelyConvertToString(output_form_item_description) !==
          safelyConvertToString(output_form_item_description_corrected) &&
          "BorderCorrect"
        }
        // onChange={handleInputChange}
        isDisabled={true}
        className="description"
      />
    </>
  );
};
