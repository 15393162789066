import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __ShowForm } from "request/Forms/__ShowForm";
import env from "../../../constants/apiConst";
import FormViewRestWorld from "./FormView/FormViewEntry/FormViewRestWorld";
import FormViewRestTerritory from "./FormView/FormViewEntry/FormViewRestTerritory";
import FormViewFromUserToUser from "./FormView/FormViewEntry/FormViewFromUserToUser";
import { __GetItems } from "request/Forms/_CrudForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import ModalCopy from "components/modal/ModalCopy";
import { useSelector } from "react-redux";
import environment from "../../../constants/apiConst";
import TableItems from "./ViewItems/TableItems";
import CreateAt from "./CreateAt";
import { ModalGeneric } from "components/modal/ModalGeneric";
import {
  Button,
  Box,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import ButtonChek from "./ButtonViewForms/ButtonChek";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
// Remover dependecia react-tabs-scrollable
/* import { Tab, Tabs } from "../../../components/Tabs/components/index"; */
import { HeaderSearch } from "styled/SectionContainer.styled";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import Swal from "sweetalert2";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AttchmentTypiFied } from "components/attchmentTypiFied/AttchmentTypiFied";
// import LazyViewItems from "./ViewItems/LazyViewItems";
import { useModal } from "hooks/useModal";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TBodyShowTrackingFmm from "pages/Seguimiento/Table/TBodyShowTrackingFmm";
import { TheadShowTrackingItems } from "../ArrayHeaders/ArrayHeader";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useNotations } from "hooks/useGetInput";
import FormViewFreeZone from "./FormView/FormViewEntry/FormViewFreeZone";
import { __Post } from "request/Petitions/__Post";
import RenderItems from "./ViewItems/RenderItems";
import { CommentManager } from "components/DispatchCorrections/CommentManager/CommentManager";
import { Action } from "react-tiny-fab";
import { RiListSettingsLine, RiSearch2Line } from "react-icons/ri";
import { useDebounce } from "hooks/useDebouce";
import ModalObservation from "../FunctionsCheck/Functions";
import { cleanDeepObject, isArray, isNumber, isObject, isObjectNotEmpty, isString } from "utils/type-check-utils";
import { Storage } from "hooks/useStorage";
import { renderInputWithLabel } from "./ViewItems/ViewItemsEntryForm";
import { useGlobalContext } from "context/GlobalContext";
import useStatusListener from "hooks/useStatusListener";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";
import { useMutation } from '@tanstack/react-query'
import InputSelect from "components/InputSelect/InputSelect";

/**
 *  FormReviewing -  Componente el cual Maneja Vista completa del Formulario una vez creado.
 *
 * @component
 * @return {Component} Retorna componente vista de datos de formulario de entrada.
 */

const commentTemplate = {
  renderProperties: {
    make: "make_comment",
    date: "date_comment",
    comment: "comment",
    person: "person_name",
  },
  functionProperties: {
    handleComment: "comment",
  },
};

const FormReviewing = () => {
  const {
    token,
    is_commerce,
    admin,
    user_rol: { modules },
    is_qualified_user,
  } = useSelector((state) => state.auth.dataUser);

  const location = useLocation();
  const { Number: FormEntry = null } = queryString.parse(location.search);

  const initialForm = {
    num_dex: "",
    dex_date: "",
    exit_auth: "",
    inspection: "",
    operation_id: "",
    cormagdalena: "",
    department_id: "",
    num_exit_form: "",
    thirdPartie_id: "",
    code_entryForm: "",
    purchase_order: "",
    transp_doc_date: "",
    qualifiedUser2_id: "",
    shipment_entryForm: "",
    dcl_customs_transit: "",
    num_transport_document: "",
    num_commercial_invoice: "",
    num_import_declaration: "",
    customs_administration: "",
    inspection_act_or_auto: "",
    transit_expiration_date: "",
    cargo_manifest_entryForm: "",
    num_exit_form: "",
    integration_box_certificate: "",
    date_entryForm: "",
    status_entryForm: "",
    name_qualified: " ",
    comments: "",
    block_for_subheading_appglobalroval: "",
  };

  const [form, setForm] = useState(initialForm);
  const [user, setUser] = useState([]);
  const [reviewUpdates, setreviewUpdates] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([])

  const [files, setFiles] = useState({});
  const [nameFiles, setNameFiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const Searchdebounced = useDebounce(searchItem, 500);

  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [precision, setPrecision] = useState(0);
  const [isOpenShowItems, onOpenShowItems, onCloseShowItems] = useModal();
  const [pageTrackings, setPageTrackings] = useState(1);
  const [loadTrackings, setLoadTrackings] = useState([]);
  const [maxPageTrackings, setMaxPageTrackings] = useState(1);
  const [currentPageTrackings, setCurrentPageTrackings] = useState([]);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })

  const {
    selectItemForm,
    isOpenCopy,
    onCloseModal,
    copyData,
    isOpenDesechar,
    onCloseDesechar,
    dataToDesechar,
    changeStatus,
    update,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenComment,
    onOpenComment,
    onCloseComment,
    setSelectItemForm,
    setTypeEntry,
    setDocumentOperation,
    DocumentOperation,
    deleteAttachment,
    editDataForm,
    setRequestEdit,
  } = useContext(FormCrudCRUDContext);

  const { set, get, clean } = Storage;

  const notationsDestruct = useNotations({
    storageKey: FormEntry,
    onOpen: onOpenComment,
    notationTypes: [
      {
        color: "gray",
        enableComment: false,
      },
      {
        color: "green",
        enableComment: false,
      },
      {
        color: "#fc0a0a",
        enableComment: true,
      },
    ],
    reviews: form?.reviews ? cleanDeepObject(form?.reviews) : {},
    reviewsUpdates: form.review_updates,
    reviewsNew: form?.reviews_new ? cleanDeepObject(form?.reviews_new) : {},
  });

  const {
    observations,
    getColorByFieldName,
    currentCommentary,
    UpdateComment,
  } = notationsDestruct;

  const extensionOptions = [
    '.doc',
    '.docx',
    '.pdf',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.bmp',
    '.xls',
    '.xlsx',
    '.xlsm',
    'image/png',
    'image/*',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-excel'
  ]


  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  const handleFilesInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const Files = e.target.files;

    const getFileExtension = files[0].name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);

    if (!isValidExtension) {
      const fileName = files[0].name;
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        "error"
      );
    }

    Vapor.store(Files[0], {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setFiles(
        Object.assign(
          { ...files },
          {
            [e.target.id]: {
              file: response,
              name: Files[0].name,
              document_id: e.target.id.replace("document-", ""),
            },
          }
        )
      );
      setNameFiles([...nameFiles, { name }]);
    });
  };

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${nombre}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          if (files[archivo].from_server) {
            deleteAttachment(
              environment.DELETE_ATTACHMENT_ENTRY,
              files[archivo].id
            );
          }
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo];
          }
          setFiles(obj);
        }
      }
    });
  };
  const { requestData } = useGlobalContext();

  const {
    isLoading: isLoadingViewForm,
    data: dataViewForm,
    refetch: refetchViewForm,
    isFetching: isFetchingViewForm,
    isSuccess: isSuccessViewForm,
    isError: isErrorViewForm,
  } = useQuery({
    queryKey: ["viewForms", FormEntry, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.FORMS_ENTRY_VIEW,
        resourceIdentifier: FormEntry,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const dataForm = dataViewForm?.data?.status?.data;

      if (isErrorViewForm) {
        openAlert(dataForm?.data?.status.message, "error");
        navigate(`/`);
        return;
      }

      if (isSuccessViewForm) {
        setForm(dataForm);
        setRequestEdit(environment.UPDATE_FORM_ENTRY);
        setPrecision(dataForm?.precision);
        setSelectItemForm(parseInt(dataForm.code_operation));
        setreviewUpdates(dataForm?.review_updates);
        setUser(dataForm?.user);

        setDocumentOperation(dataForm?.documents);
        const fromServer = dataForm?.files.reduce((old, it) => {
          return {
            ...old,
            ["document-" + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
            },
          };
        }, {});
        setFiles({
          ...files,
          ...fromServer,
        });
        setOtherDocuments(dataForm?.other_files)
        if (dataForm.check_rm) {
          setTypeEntry(dataForm?.check_rm);
        }
      }
    }
  }, [isFetchingViewForm]);

  const firstRender = useRef(true);

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoadTrackings(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPageTrackings(res.data.status.data.data);
        setMaxPageTrackings(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpenShowItems();
        setLoadTrackings(false);
      }
    },
    [pageTrackings]
  );

  /*   useEffect(() => {
    if (ItemsData) {
      const data = ItemsData?.data?.status?.data?.data;
      setItems(data);
      setViewTable(data);
    }
  }, [ItemsData, update, precision]); */

  useEffect(() => {
    if (FormEntry !== "") {
      clean(FormEntry);
    } else {
      setForm(initialForm);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FormEntry, update, precision]);

  const handlePresent = (status) => {
    const info = {
      id: dataToDesechar.id,
      status_entryForm: status,
    };
    changeStatus(info);
    onCloseDesechar();
  };

  const responseDeleteFile = (res, index = null, id) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      const filteredAttachments = isNumber(index)
        ? otherDocuments.filter((_, i) => i !== index)
        : otherDocuments

      if (isNumber(index)) setOtherDocuments(filteredAttachments)
      setAttachment([])
      return openAlert('El documento ha sido eliminado ', 'error')
    }
  }

  const removeFileAttachment = (
    index,
    id,
    type,
    Endpoint = environment.DELETE_OTHER_ATTACHMENT_ENTRY
  ) => {
    if (type === 'serverFile') {
      const requestData = {
        resourceIdentifier: id,
        Endpoint,
        PropertyBody: 'get'
      }

      mutate(requestData, {
        onSuccess: res => responseDeleteFile(res, index, id)
      })
    }
  }

  const isAuthorizationDeletedDocument =
    form?.status_entryForm !== "BORRADOR" && is_commerce === 1;

  const [LockStatus, setLockStatus] = useState(null);
  const lockUnlock = LockStatus === 1 ? "desbloquear" : "bloquear";

  const isAuthorizedToLockUnlock = modules?.some((item) =>
    item.permission.some(
      (name) => name.name_permission === "lock_and_unlock_output_form"
    )
  );

  const UnlockForm = async () => {
    try {
      Swal.fire({
        title: `¿Estas seguro de ${lockUnlock} el formulario "${form?.id}"?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#1F7AC3",
        confirmButtonText: "Confirmar",
        customClass: {
          title: "class-text",
        },
      }).then(async (resultado) => {
        if (resultado.isConfirmed) {
          const res = await __Post(env.LOCK_AND_UNLOCK_ENTRY_FORM, token, {
            entryForm_id: form?.id,
            block: LockStatus === 1 ? 0 : 1,
            type_block: "block_for_subheading_approval",
          });

          if (res?.data?.status?.code === 200) {
            setLockStatus(
              res?.data?.status?.data?.block_for_subheading_approval
            );
            return openAlert(res?.data?.status?.message, "success");
          }
          return openAlert(res?.data?.status?.message, "error");
        }
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (form) {
      setLockStatus(form?.block_for_subheading_approval);
    }
  }, [form]);

  const viewLockUnlock =
    form?.block_for_subheading_approval === 1 &&
    form?.status_entryForm === "PRESENTADO" &&
    is_qualified_user !== 1;

  async function fetchServerPage({ pageParam = 1 }) {
    const formData = new FormData();
    formData.append("entryForm_id", FormEntry);
    formData.append("length", 5);
    formData.append("item", searchItem);
    formData.append("orderBy", orderBy?.value);

    const res = await __GetItems(
      token,
      formData,
      pageParam,
      environment.GET_iTEMS
    );

    const page =
      pageParam === res?.data?.status?.data?.last_page ? null : pageParam + 1;
    return {
      items: res?.data?.status?.data?.data,
      nextCursor: page,
    };
  }

  const {
    status,
    data,
    error: isErrorPage,
    isFetching,
    isLoading: isLoadingItems,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchItems,
  } = useInfiniteQuery({
    queryKey: ["fetchServerPage", Searchdebounced, orderBy],
    queryFn: fetchServerPage,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data ? data.pages.flatMap((d) => d.items) : [];
  const [listMode, setlistMode] = useState(false);

  const switchLoadingItem = () => {
    setlistMode((prev) => !prev);
  };

  const handleChangeSearch = (e) => {
    setSearchItem(e.target.value);
  };

  const fields = [
    {
      name: "name_qualified",
      labelText: "Empresa",
      manualValue: `${form?.nit_qualified || ""} - ${form?.name_qualified}`,
    },
    {
      name: "business_name",
      labelText: "Proveedor/Exportador",
      manualValue: `${form?.identification_num || ""} - ${form?.business_name}`,
    },
    {
      name: "short_name_operation",
      labelText: "Tipo de Operación",
    },
    {
      name: "purchase_order",
      labelText: "Orden de Compra",
    },
  ];

  const submitReviewBasedOnStatus = async () => {
    if (is_qualified_user) return;

    if (form?.status_entryForm === "PRESENTADO") {
      const body = new FormData();
      body.append("form_id", FormEntry);
      body.append("type_of", "FMMI");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(-1);
      }
    }
  };

  const { mutate } = useMutation({
    mutationFn: requestData
  })


  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  const handleResponseUpdateForm = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      refetchViewForm()
      //  return setCalculated(res?.data?.status?.data);
    }
  }

  const updateAndCreate = async (data = undefined, payloadManual = false) => {
    const formData = new FormData()
    formData.append('id', FormEntry)
    const dataAttachments = attachment[0];
    const vaporRes = await uploadFile(dataAttachments?.file);

    dataAttachments?.id && formData.append('other_attachment_id[]', dataAttachments?.id)
    const file = {
      file: vaporRes
    }
    formData.append('other_attachment[]', JSON.stringify({ ...file, name: dataAttachments?.file?.name }));
    dataAttachments?.other_attachment_description && formData.append(
      'other_attachment_description[]',
      dataAttachments?.other_attachment_description
    )
    dataAttachments?.other_attachment_comment && formData.append(
      'other_attachment_comment[]',
      dataAttachments?.other_attachment_comment
    )

    dataAttachments?.other_attachment_number && formData.append(
      'other_attachment_number[]',
      dataAttachments?.other_attachment_number
    )

    dataAttachments?.extension && formData.append('other_attachment_type[]', dataAttachments?.extension)

    const date = dataAttachments?.other_attachment_date || dataAttachments?.attachment_date
    date && formData.append(
      'other_attachment_date[]', date
    )

    dataAttachments?.attachment_date && formData.append(
      'attachment_date[]', dataAttachments?.attachment_date
    )

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      if (data) {
        Object.keys(data).forEach(documentId => {
          if (!data[documentId].from_server) {
            formData.append(
              'attachment[' + documentId + ']',
              JSON.stringify(data[documentId])
            )
          }
        })
      }
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.UPDATE_FORM_ENTRY,
      PropertyBody: 'sendFormDataContentPOST'
    }

    setOtherDocuments([])
    setAttachment([])
    setFiles({})

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm
    })
  }

  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [form]);

  const propsBlockForm = {
    form_id: FormEntry,
    type_of: "FMMI",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };
  const { pathname } = location;

  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations: notationsDestruct,
    id: "Header",
    data: form,
  };

  const navigateIfEligibleForUpdate = (flag, form_id, new_status, formData) => {
    const shouldRefetch =
      flag === "entry_form" &&
      new_status !== formData?.status_entryForm &&
      form_id === formData?.id;

    if (
      shouldRefetch &&
      new_status !== "REVISADO" &&
      new_status !== "PRESENTADO"
    ) {
      navigate(`/FormShow?FormEntry=${form_id || formData.form_id}`);
      return;
    }
  };

  useStatusListener({
    id: form?.id,
    dataCurrent: form,
    callBack: navigateIfEligibleForUpdate,
  });

  return (
    <>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenComment}
        onOpen={onOpenComment}
        onClose={onCloseComment}
        title="observación"
        size="xl"
      >
        <ModalObservation
          currentCommentary={currentCommentary}
          data={observations}
          set={UpdateComment}
          onClose={onCloseComment}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenShowItems}
        onOpen={onOpenShowItems}
        onClose={onCloseShowItems}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadShowTrackingItems}
          data={currentPageTrackings}
          setData={setCurrentPageTrackings}
          loading={loadTrackings}
          tbodyData={TBodyShowTrackingFmm}
        />
        <Pagination
          page={pageTrackings}
          setPage={setPageTrackings}
          maxPage={maxPageTrackings}
          loading={loadTrackings}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onCloseShowItems}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <CommentManager
        identifier={FormEntry}
        commentGetService={environment.GET_COMMENT_FMM_ENTRY}
        commentCreationService={environment.COMMENT_FMM_ENTRY}
        properties={commentTemplate}
      >
        <Action
          style={{
            backgroundColor: "#3498db",
            width: "40px",
            height: "40px",
          }}
          text={listMode ? "Scroll infinito" : "Modo normal"}
          onClick={switchLoadingItem}
        >
          <RiListSettingsLine />
        </Action>
      </CommentManager>

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar este formulario`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenDesechar}
        onClose={onCloseDesechar}
        title={`Desea desechar el formulario Nº ${dataToDesechar?.code_entryForm || dataToDesechar?.code_outputForm
          } `}
        size={"md"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseDesechar}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={() => handlePresent("ELIMINADO")}
            ml={3}
          >
            Desechar
          </Button>
        </div>
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div className=" mb-5">
            <HeaderForm
              status={form?.status_entryForm}
              numberForm={form?.code_entryForm}
              UserQualified={form?.name_qualified}
              Modulo={"Formulario de ingresos En Zonas Francas"}
              items={allItems}
            />
          </div>

          <ButtonChek data={form}>
          <CreateAt
              entries={form.users}
              config={{
                statusKey: "status_entryForm",
                dateKey: "date_entryForm",
                timeKey: "time_entryForm",
                personNameKey: "person_name",
              }}
              expirationDate={form.expirationDate}
            />
            {viewLockUnlock && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                className="alert alert-warning w-50 pt-2 pb-2 m-auto mt-3 pl-2"
                style={{ minHeight: "60px" }}
                role="alert"
              >
                {`Aprobación del documento esta ${LockStatus === 1 ? "bloqueado" : "desbloqueado"
                  }`}
                {(isAuthorizedToLockUnlock || admin === 1) && (
                  <Button onClick={UnlockForm} colorScheme="blackAlpha">
                    {LockStatus === 1 ? "Desbloquear" : "Bloquear"}
                  </Button>
                )}
              </Box>
            )}
          </ButtonChek>

          <Box>
            <div className="row">
              {isArray(fields) &&
                fields.map((field) =>
                  /* (isShowForm || isShowExitForm) && */
                  renderInputWithLabel({
                    labelText: field.labelText,
                    name: field.name,
                    manualValue: field?.manualValue,
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    annotationInfo,
                    PropsBox: {
                      className: "col-md-6",
                    },
                  })
                )}
            </div>

            <div className="mt-5">
              {selectItemForm >= 100 && selectItemForm < 199 ? (
                <>
                  <FormViewRestWorld
                    data={form}
                    notations={notationsDestruct}
                    code={selectItemForm}
                  />
                </>
              ) : selectItemForm >= 300 && selectItemForm <= 399 ? ( // selectItemForm >= 301 && selectItemForm <= 330
                <FormViewRestTerritory
                  data={form}
                  notations={notationsDestruct}
                  code={selectItemForm}
                />
              ) : selectItemForm >= 500 && selectItemForm <= 599 ? ( // selectItemForm >= 501 && selectItemForm <= 518
                <FormViewFreeZone data={form} notations={notationsDestruct} />
              ) : selectItemForm >= 700 && selectItemForm <= 799 ? ( // selectItemForm >= 701 && selectItemForm <= 518
                <FormViewFromUserToUser
                  data={form}
                  notations={notationsDestruct}
                />
              ) : (
                ""
              )}
            </div>


            <HeaderSearch margen=' 0.5rem 0rem'>
              {DocumentOperation.map((document, index) => (
                <div key={index}>
                  <DocumentUploader
                    document={document}
                    handleFiles={handleFiles}
                    handleFilesInput={handleFilesInput}
                    handleDelete={removeItemFromArr}
                    files={files}
                    setNameFiles={setNameFiles}
                    nameFiles={nameFiles}
                    index={index}
                    viewBtnDelete={isAuthorizationDeletedDocument}
                    documentPreview={true}
                  />
                </div>
              ))}
            </HeaderSearch>

            <HeaderSearch margen=' 0.5rem 0rem'>
              <OtherAttchment
                otherDocuments={otherDocuments}
                titulo={'Otros documentos'}
                attachment={attachment}
                setAttachment={setAttachment}
                attachmentType={attachmentType}
                setAttachmentType={setAttachmentType}
                ruta={environment.DELETE_OTHER_ATTACHMENT_EXIT}
                removeFileAttachment={removeFileAttachment}
                update={updateAndCreate}
                documentPreview={true}
                viewBtn={true}
              />
            </HeaderSearch>

            {isLoadingItems && <SpinnerComponent />}

            {!isLoadingItems && (
              <>
                {allItems?.length !== 0 && (
                  <TableItems items={allItems} currentData={form} />
                )}
              </>
            )}

            <Box
              display={'flex'}
              alignItems={'center'}
              w={'100%'}
              mt={'1rem'}
              gap={3}
            >
              <Box w={"64"} mb={6} mt={6}>
                <label
                  style={{
                    width: "100%",
                  }}
                >
                  Buscar item
                  <InputGroup>
                    <Input
                      w={"100%"}
                      h={"38px"}
                      color={"#000"}
                      outline={"none"}
                      fontSize={"1rem"}
                      backgroundColor={"#ffffff87"}
                      onChange={handleChangeSearch}
                      value={searchItem}
                      placeholder={"Buscar item"}
                    />
                    <InputRightElement style={{}} as="button">
                      <RiSearch2Line color="green.500" />
                    </InputRightElement>
                  </InputGroup>
                </label>
              </Box>

              <>
                <label
                  style={{
                    width: 300
                  }}
                >
                  <label style={{ width: '100%' }}></label>
                  <InputSelect
                    data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                    value={orderBy}
                    name="order_by"
                    set={setOrderBy}
                    placeholder="Ordenar por"
                    inputAttrs={{
                      color: '#5f5f',
                    }}
                    isClearable={false}
                  />
                </label>
              </>
            </Box>

            <RenderItems
              notationsDestruct={notationsDestruct}
              reviewUpdates={reviewUpdates}
              observations={observations}
              alldata={form}
              FetchingStatus={status}
              admin={admin}
              dataItems={allItems}
              formData={form}
              isLoadingItems={isLoadingItems}
              error={isErrorPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              is_commerce={is_commerce}
              fetchNextPage={fetchNextPage}
              showTrackingItem={showTrackingItem}
              isInfiniteScroll={listMode}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FormReviewing;
