import { FaQuestion } from "react-icons/fa";
import { GiPipes } from "react-icons/gi";
import { ImLoop2 } from "react-icons/im";
import {
  TbPlane,
  TbRipple,
  TbSpeedboat,
  TbTrain,
  TbTruck
} from "react-icons/tb";

export const SelectIcons = (value) => {
  switch (value) {
    case "Transporte Ferroviario":
      return <TbTrain />;
    case "Transporte Marítimo":
      return <TbSpeedboat />;
    case "Transporte por Instalaciones Fijas":
      return <GiPipes />;
    case "Transporte carretero":
      return <TbTruck />;
    case "Transporte multimodal":
      return <ImLoop2 />;
    case "Transporte en aguas interiores":
      return <TbRipple />;
    case "Transporte Aéreo":
      return <TbPlane />;
    default:
      return <FaQuestion />;
  }
};

export const SelectColor = (value) => {
  switch (value) {
    case "Transporte Ferroviario":
      return "#03C39A";
    case "Transporte Marítimo":
      return "#0382D3";
    case "Transporte por Instalaciones Fijas":
      return "#1F7AC3";
    case "Transporte carretero":
      return "#fc9220";
    case "Transporte multimodal":
      return "#0084ff";
    case "Transporte en aguas interiores":
      return "#19B3EF";
    case "Transporte Aéreo":
      return "#FF6400";
    default:
      return "#BA1F33";
  }
};
