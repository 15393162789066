import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";
import Home from "pages/Home/Home";
import PublicRoutes from "./PublicRoutes";
// import Pases from "pages/Pass-Form/Pases";
import WelcomeRoute from "./WelcomeRoute";
import PrivateRoutes from "./PrivateRoutes";
import RoutePrivateZF from "routes/RoutePrivateZF";
import { WelcomeLan } from "../pages/Home/WelcomeLan";
// import { PassCrudProvider } from "context/PassCrudContext";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Rutas prublicas */}
        <Route
          path="/auth/*"
          element={
            <PublicRoutes>
              <Home />
            </PublicRoutes>
          }
        />

        {/* Ruta privadas */}
        <Route
          path="/*"
          element={
            <PrivateRoutes>
              <RoutePrivateZF />
            </PrivateRoutes>
          }
        />
        <Route
          path="/welcome"
          element={
            <WelcomeRoute>
              <WelcomeLan />
            </WelcomeRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
