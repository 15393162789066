/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useModal } from "hooks/useModal";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import {
  cabeceraIntegrationBox,
  TitleIntegration
} from "pages/IntegrationBox/Arrays/ArrayTitles";
import TbodyBox from "pages/IntegrationBox/Table/TbodyBox";
import { useSelector, useDispatch } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { __Get_Integration_Box } from "request/IntegrationBox/___IntegrationBox";
import { Title } from "styled/Title";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";
import FormIdOrdeProduction from "pages/IntegrationBox/Form/FormIdOrdeProduction";
import { Button } from "@chakra-ui/react";
import FormModalStatusDelete from "pages/IntegrationBox/Form/FormModalStatusDelete";
import FormSearchAdvance from "pages/IntegrationBox/Form/FormSearchAdvance";
import {
  __Post,
  __PostForm,
  __PostForm1,
  __PostFormData,
  __PostJsonData
} from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __ExportExcel } from "request/Inventory/__InventoryCRUD";
import ExportIntegrationCertificate from "./ExportItegrationCertificate";
import { arrayList, ciNodeInputFields } from "./ListItem";
import { useDebounceForm } from "hooks/useDebouce";
import { isArray, safelyConvertToString } from "utils/type-check-utils";

import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";

const StatusTableView = () => {
  const { cleanState, clearParamsSearch } = useSearchUrl({ getParams: ["date_1", "date_2", "time_1", "time_2", "form_number", "product_order_code", "product_code", "date_from", "orderBy", "orderType", "status", "destiny"], nestedProp: true ? "searchAdvance" : null, })

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const [exportType, setExportType] = useState(0);
  const [values, handleInputChange, reset, setValues] = useForm(
    {
      searchInformacion: "",
      valueQualified: [],
    }
  );

  const dispatchRedux = useDispatch();

  const { searchInformacion, valueQualified } = values;
  const {
    setRequestDelete,
    isOpenBox,
    onCloseModal,
    update,
    setRequestChangeStatusBox,
    deleteBox,
    isOpenDeleteBox,
    changeStatus,
    setRequestCopyBox,
    dataToCopy,
    isOpenCopyBox,
    copyData,
    setRequestChangeStatusBoxPresent,
    isOpenAnular,
    dataToAnular
  } = useContext(IntegracionBoxCrudContex);

  const GetIntegration = async () => {
    setLoading(true);
    try {
      const res = await __Get_Integration_Box(token, page);
      setCurrentPage(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetIntegration();
  }, [page, update, freeZone_id]);

  const changePage = (index) => {
    setPage(1);
    setMaxPage(currentPage ? currentPage[index]?.last_page : 1);
  };

  useEffect(() => {
    setRequestDelete(environment.DELETE_INTEGRATION_BOX);
    setRequestChangeStatusBox(environment.CHANGE_STATUS_BOX);
    setRequestCopyBox(environment.COPY_BOX);
    setRequestChangeStatusBoxPresent(environment.CHANGE_STATUS_PRESENT);
  }, [
    setRequestDelete,
    setRequestChangeStatusBox,
    setRequestChangeStatusBoxPresent,
    setRequestCopyBox,
    freeZone_id
  ]);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchAdvance", "searchSimple"] });
    reset();
    setSearchAdvanced([]);
    setChangeViewSearch(false);
  };

  const statusDataCopy = new FormData();
  statusDataCopy.append("id", dataToCopy?.integration_certificate_id);

  const handleAnular = (status) => {
    const dataAnular = new FormData();
    dataAnular.append("id", dataToAnular?.integration_certificate_id);
    dataAnular.append("status", status);
    changeStatus(dataAnular);
    onCloseModal();
  };

  const { user_rol, admin, is_qualified_user, is_commerce } = useSelector(
    (state) => state.auth.dataUser
  );
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1 || is_qualified_user === 1 || is_commerce === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.SEARCH_INTEGRATION_CERTIFICATE,
        token,
        informacion,
        pageSearch
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPageSearch(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    const newData = {
      ...(searchInformacion && { search: searchInformacion }),// si searchInformacion tiene un valor lo agrega a la petición
      ...searcheAdvanced,
      ...(isArray(valueQualified) && valueQualified?.length !== 0
        ? { qualifiedUser_id: valueQualified?.map((item) => item?.value) }
        : valueQualified?.value && { qualifiedUser_id: valueQualified?.value })
    };

    if (Object.keys(newData).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      dispatchRedux(setSearch(newData));
      searchSimple(newData);
      return;
    }

    dispatchRedux(clearSearch());
    setChangeViewSearch(false)
  }, [
    searchInformacion,
    searcheAdvanced,
    freeZone_id,
    valueQualified,
    pageSearch
  ]);

  useEffect(() => {
    return async () => {
      dispatchRedux(setSearch());
    };
  }, []);

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: isArray(data?.qualifiedUser_id)
          ? data?.qualifiedUser_id?.map((item) => item?.value || item)
          : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
            ? data?.qualifiedUser_id?.value
            : data?.qualifiedUser_id || []
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    exportColumnBy(info);
  };
  /*
    const handleSubmit = (data, formulario) => {
      const info = {
        fields: formulario,
        data,
        ...searcheAdvanced,
      };
      exportColumnBy(info);
    }; */

  const exportColumnBy = async (data) => {
    try {
      const res = await __Post(
        environment.EXPORT_INTEGRATION_CERTICATE,
        token,
        data
      );
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        /* let url = res?.data;
        window.location.assign(url); */
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenBox}
        onClose={onCloseModal}
        title={`Orden de produccion`}
        size={"6xl"}
      >
        <FormIdOrdeProduction />
      </ModalGeneric>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onClose={onCloseExport}
        onOpen={onOpenExport}
        title="Exportar documento"
        size="6xl"
        nodeList={arrayList}
        handleSubmit={handleSubmit}
        selectNodeList={ciNodeInputFields}
      />

      {/*       <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documento"
      >
        <ExportIntegrationCertificate
          title={"Exportar estadísticas"}
          onClose={onCloseExport}
          NodeList={arrayList}
          handleSubmit={handleSubmit}
          exportFilters={{
            date: true,
            qualified_users: true,
          }}
          dafaulActive={[]}
          exportType={exportType}
          setExportType={setExportType}
        />
      </ModalGeneric> */}

      <FormModalStatusDelete
        status={"DESECHAR"}
        nameDocument={"Certificado de integración"}
        numberOfDocument={deleteBox?.integration_certificate_code}
        id={deleteBox?.integration_certificate_id}
        isOpen={isOpenDeleteBox}
        onClose={onCloseModal}
        functionChangeStatus={changeStatus}
      />

      <FormModalStatusDelete
        status={"COPIAR"}
        nameDocument={"Certificado de integración"}
        numberOfDocument={dataToCopy?.integration_certificate_code}
        isOpen={isOpenCopyBox}
        onClose={onCloseModal}
        data={statusDataCopy}
        copy={true}
        functionChangeStatus={copyData}
      />

      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular el certificado de integración Nº ${dataToAnular?.integration_certificate_code}? 
        <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario`}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: "#41495A", color: "white" }}
            onClick={() => handleAnular("ANULADO")}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>

      <Title style={{ marginTop: 30 }}>Certificado de integración</Title>

      <ModalGeneric
        isOpen={isOpenSearch}
        onClose={onCloseSearch}
        title={"Búsqueda avanzada"}
      >
        <FormSearchAdvance
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <div className="container ">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          allAccess={allAccess}
          myPermission={myPermission}
          MultipleQualifiedUsers="multi"
          setValues={setValues}
        />
        <ExportDetail onOpen={onOpenExport} />
      </div>

      <HeaderSearch>
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div className="px-5">
                <div>
                  <TableOrder
                    thead={TitleIntegration}
                    data={currentPageTwo}
                    setData={setCurrentPageTwo}
                    loading={loading}
                    tbodyData={TbodyBox}
                  />

                  <Pagination
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    loading={loading}
                  />
                </div>
              </div>
            )
          )
          : (
            <div>
              <ComponentAccordion
                cabecera={cabeceraIntegrationBox}
                currentPage={currentPage}
                TitleHeader={TitleIntegration}
                setCurrentPage={setCurrentPage}
                loading={loading}
                TBodyEntryMovement={TbodyBox}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                changePage={changePage}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default StatusTableView;
