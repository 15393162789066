import { useState } from "react";

/**
 * Hook para manejar formularios numéricos.
 *
 * @param {object} [initialState={}] - Valores iniciales del formulario.
 * @returns {Array} Array con el estado del formulario, la función para manejar cambios de entrada, la función para resetear el formulario y la función para establecer los valores del formulario.
 */
export const useNumericForm = (initialState = {}) => {
  const [valuesNumeric, setFormValues] = useState(initialState);

  /**
   * Función para resetear el estado del formulario a los valores iniciales.
   *
   * @returns {void}
   */
  const resetForm = () => {
    setFormValues(initialState);
  };

  /**
   * Función para manejar cambios de entrada numéricos en el formulario.
   *
   * @param {object} event - Evento del cambio de entrada.
   * @returns {void}
   */
  const handleNumericInputChange = ({ target }) => {
    const newValue = target?.value.replace(/[^\d.]/g, ""); // Solo permitir dígitos y puntos decimales
    setFormValues((prevState) => ({
      ...prevState,
      [target.name]: newValue
    }));
  };

  return { valuesNumeric, handleNumericInputChange, resetForm, setFormValues };
};
