/* eslint-disable no-unused-vars */
import React from "react";
import { LabeFormlStyled } from "styled/Input.styled";
import AsyncSelect from "react-select";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const InputSelectMultiple = ({ tag, name, data, set, value }) => {
  const handleSelectChange = (item) => {
    // console.log(item);
    set(item);
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      <div style={{ width: "102%" }}>
        <LabeFormlStyled htmlFor={name}>{tag}</LabeFormlStyled>
        <Select
          isMulti
          value={value}
          options={data}
          components={animatedComponents}
          onChange={(item) => handleSelectChange(item)}
          name={name}
          id={name}
          placeholder={"Seleccionar"}
        />
      </div>
    </>
  );
};

export default InputSelectMultiple;
