/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowZonasFranca } from "request/configuration/__ZonasFranca";
import { useSelector } from "react-redux";

const FormZonasFShow = () => {
  const initialForm = {
    name_freeZone: "",
    shortName_freeZone: "",
    site: "",
    color: "",
    show_pdf_user: "",
    show_user_prs_com_repr: "",
    copy_movements: "",
    change_subheading: "",
    dian_stamp: "",
    detailed_review: "",
    can_add_attachments: "",
    drive_vehicle_door: "",
    special_subheading: "",
    approval_lock: "",
    validate_partial_processing: "",
    qualification_date_valid_proc_parc: "",
    warn_subheadings: "",
    temporary_decl: "",
    validate_reposition_outputs: "",
    percentage_value_outputs_reset: "",
    export_detailed_documents: "",
    use_dian_profiling: "",
    status_freeZone: "",
    name_groupFreeZone: "",
    name_typeFreeZone: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToShow) {
      const initialpos = async () => {
        try {
          const res = await __ShowZonasFranca(token, dataToShow.id);
          setForm(res?.data?.status?.data);
        } catch (error) { }
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.name_freeZone : ""}
      </Text>
      <form action="">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_freeZone}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Abreviación</LabeFormlStyled>
                <InputFake>{form.shortName_freeZone}</InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>sitio web</LabeFormlStyled>
                <InputFake>{form.site === null ? "N/A" : form.site}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>color</LabeFormlStyled>
                <InputFake>
                  {form.color === null ? "N/A" : form.color}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Fecha calif valid proc parc</LabeFormlStyled>
                <InputFake>
                  {form?.qualification_date_valid_proc_parc === null
                    ? "N/A"
                    : form.qualification_date_valid_proc_parc}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Grupo de zonas francas</LabeFormlStyled>
                <InputFake>{form.name_groupFreeZone}</InputFake>
              </FormInputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Tipo de zona franca</LabeFormlStyled>
                <InputFake>{form.name_typeFreeZone}</InputFake>
              </FormInputGroup>
            </div>

            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Mostrar Usuarios Prs Com Repr</LabeFormlStyled>
                <InputFake>
                  {form.show_user_prs_com_repr === null
                    ? "N/A"
                    : form.show_user_prs_com_repr === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Copiar movimientos</LabeFormlStyled>
                <InputFake>
                  {form?.copy_movements === null ? "N/A" : form.copy_movements}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Cambiar subpartidas</LabeFormlStyled>
                <InputFake>
                  {form.change_subheading === null
                    ? "N/A"
                    : form.change_subheading === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Sello Dian</LabeFormlStyled>
                <InputFake>
                  {form?.dian_stamp === null
                    ? "N/A"
                    : form.dian_stamp === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Revisión detallada</LabeFormlStyled>
                <InputFake>
                  {form.detailed_review === null
                    ? "N/A"
                    : form.detailed_review === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Can add adjuntos</LabeFormlStyled>
                <InputFake>
                  {form?.can_add_attachments === null
                    ? "N/A"
                    : form.can_add_attachments === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>

            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Maneja puerta vehicular</LabeFormlStyled>
                <InputFake>
                  {form.drive_vehicle_door === null
                    ? "N/A"
                    : form.drive_vehicle_door === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Subpartida especial</LabeFormlStyled>
                <InputFake>
                  {form.special_subheading === null
                    ? "N/A"
                    : form.special_subheading === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>

            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Bloqueo aprobación</LabeFormlStyled>
                <InputFake>
                  {form.approval_lock === null
                    ? "N/A"
                    : form.approval_lock === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Validar procesamiento parcial</LabeFormlStyled>
                <InputFake>
                  {form.validate_partial_processing === null
                    ? "N/A"
                    : form.validate_partial_processing === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Advertir subpartidas</LabeFormlStyled>
                <InputFake>
                  {form.warn_subheadings === null
                    ? "N/A"
                    : form.warn_subheadings === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Declaración temporal</LabeFormlStyled>
                <InputFake>
                  {form.temporary_decl === null
                    ? "N/A"
                    : form.temporary_decl === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Validar salidas reposición</LabeFormlStyled>
                <InputFake>
                  {form.validate_reposition_outputs === null
                    ? "N/A"
                    : form.validate_reposition_outputs === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Porcentaje valor Salidas</LabeFormlStyled>
                <InputFake>
                  {form.percentage_value_outputs_reset === null
                    ? "N/A"
                    : form.percentage_value_outputs_reset === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>
                  Exportar documentos detallados{" "}
                </LabeFormlStyled>
                <InputFake>
                  {form.export_detailed_documents === null
                    ? "N/A"
                    : form.export_detailed_documents === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Usar perfiles de dian</LabeFormlStyled>
                <InputFake>
                  {form.use_dian_profiling === null
                    ? "N/A"
                    : form.use_dian_profiling === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Estado zonas franca</LabeFormlStyled>
                <InputFake>
                  {form.status_freeZone === null
                    ? "N/A"
                    : form.status_freeZone === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Mostrar Usuarios PDF</LabeFormlStyled>
                <InputFake>
                  {form?.show_pdf_user === null
                    ? "N/A"
                    : form.show_pdf_user === 1
                      ? "Activo"
                      : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>No. de días radicaciones por caducar</LabeFormlStyled>
                <InputFake>
                  {form?.notifications_day}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormZonasFShow;
