import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SimpleGrid, useToast } from "@chakra-ui/react";
import { RiSearch2Line } from "react-icons/ri";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

import "./styles.css";
import Card from "./Card";
import { Title } from "styled/Title";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { IconGeneric } from "styled/Icons.styled";
import {
  _getSearchPlateIdentification,
  __postBlockageParticular
} from "request/__Security";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormRegisterBlockages } from "./FormRegisterBlockages";
import { ButtonStyled } from "styled/Buttons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";

/**
 *  Blockages - Componente para realizar busquedas por placa de vehiculos o por cédula para realizar bloqueo ya sea permanente
 *  temporal ademas da la opción por cual tipo desea bloquear ya sea por Identificación, placa o ambos
 * @component
 * @return {Component} Retorna componente JSX, muestra la data mediante cards
 */

const Blockages = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [selectTypeBlock, setSelectTypeBlock] = useState();
  const [typeOfSanction, setSelectTypeOfSanction] = useState();
  const [values, handleInputChange, reset, setValues] = useForm({
    name: "",
    plate: "",
    description: "",
    plateOrIdent: "",
    identification: ""
  });

  console.log("Placaaaa=>", values?.plate);

  const searchPlateIdentification = async () => {
    if (values.plateOrIdent) {
      setLoading(true);
      try {
        const query = values.plateOrIdent.toUpperCase();
        const res = await _getSearchPlateIdentification(token, query);
        const status = res?.data?.status;
        if (status?.code !== 200) {
          alertError(status?.message);
        } else {
          setDataSearch(status?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  // else if (status?.code === 403) {
  //         alertError(status?.message);
  //         setTimeout(() => {
  //           onOpenSearch(true);
  //         }, 800);
  //       }

  const handleRegisterBlockages = async () => {
    setLoading(true);
    try {
      const body = {
        name: values?.name,
        type_block: selectTypeBlock?.value,
        description: values?.description,
        vehicle_plate: values?.plate,
        identification: values?.identification,
        type_of_sanction: typeOfSanction?.value
      };
      const res = await __postBlockageParticular(token, body);
      const status = res?.data?.status;
      setLoading(false);
      if (status?.code !== 200) {
        alertError(status?.message);
      } else {
        reset();
        setSelectTypeOfSanction();
        setSelectTypeBlock();
        alertSuccess(status?.message);
        onCloseSearch(true);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const alertError = (title) => {
    toast({
      title: `${title}`,
      status: "error",
      isClosable: true,
      position: "top"
    });
  };

  const alertSuccess = (title) => {
    toast({
      title: `${title}`,
      status: "success",
      isClosable: true,
      position: "top"
    });
  };

  const onFilter = (numForm) => {
    const data = dataSearch.filter((item) => {
      return item.passForm_number !== numForm;
    });
    setDataSearch(data);
  };

  return (
    <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
      <div className="d-flex justify-content-between align-items-center m-lg-1">
        <Title>Bloqueos</Title>
        <ButtonStyled
          type="submit"
          onClick={() => onOpenSearch(true)}
          // disabled={!dataPasses.length ? true : false}
        >
          Nuevo registro
        </ButtonStyled>
      </div>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Registro de bloqueo"
      >
        <FormRegisterBlockages
          values={values}
          onClose={onCloseSearch}
          onChange={handleInputChange}
          typeOfSanction={typeOfSanction}
          selectTypeBlock={selectTypeBlock}
          setSelectTypeBlock={setSelectTypeBlock}
          handleRegisterBlockages={handleRegisterBlockages}
          setSelectTypeOfSanction={setSelectTypeOfSanction}
        />
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      <SimpleGrid minChildWidth="200px" my={4}>
        <div className="d-flex">
          <form
            className="d-flex  mb-3"
            onSubmit={(event) => {
              event.preventDefault();
              searchPlateIdentification();
            }}

            // onClick={searchPlateIdentification}
          >
            <LabelGroup
              size={100}
              maxiLength={10}
              required={true}
              name="plateOrIdent"
              tag="Bloqueo por placa de vehículo o por cédula"
              value={values?.plateOrIdent}
              onChange={handleInputChange}
              // onClick={searchPlateIdentification}
            />
            <button style={{ marginTop: 20, marginLeft: 10 }} type="submit">
              <IconGeneric color="#313131" as={RiSearch2Line} />
            </button>
          </form>
        </div>
        <div className="spac" />
      </SimpleGrid>
      <div>
        {!dataSearch?.length ? (
          <>
            {/* <img src={require("../../../assets/bro.png")} alt="vehicle" /> */}
          </>
        ) : (
          <div className="row">
            {dataSearch.map((item, i) => (
              <Card onFilter={onFilter} item={item} />
            ))}
          </div>
        )}
      </div>
    </HeaderSearch>
  );
};

export default Blockages;
