import { H1 } from "pages/Pass-Form/stylePases";
import React from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import HeadboardList from "../Headboards/HeadboardList";
import ShippingFormView from "../view/ShippingFormView/ShippingFormView";

const ShippingForm = () => {
  return (
    <>
      <HeaderSearch>
        <HeadboardList>
          <H1>Planilla de envio</H1>
        </HeadboardList>
      </HeaderSearch>

      <ShippingFormView />
    </>
  );
};

export default ShippingForm;
