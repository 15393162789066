/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import InputSelect, {
  InputSelectMulti,
  InputSelectMultiZindex
} from "components/InputSelect/InputSelect";
import { __ShowOperation } from "request/configuration/__Operations";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __TypeOperationList } from "request/configuration/__TypeOperation";
import {
  InputDocumentReque,
  SelectMultiple
} from "../InputSelectsConfig/InputSelectUserCa";
import { __DocumentNoPaginate } from "request/configuration/__DocumentRequire";
import { useSelector } from "react-redux";
import { OnlyNumberInput } from "components/Funciones/OnlyNumberInput";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Desactive } from "request/configuration/__ActivityUser";
import { __Get } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { MaxLength } from "utils/MaxLength";

const OperationsForm = () => {
  const [openAlert] = AlertErrorAlert();

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [form, handleInputChange, resetForm, setForm] = useForm();

  const [checkStatusOp, setCheckStatusOp] = useState(true);

  const [dataSelect, setDataSelect] = useState([]);
  const [typeTerm, setTypeTerm] = useState({});
  const [typeOpr, setTypeOpr] = useState({});
  const [document, setDocument] = useState([]);
  const [valor, setValor] = useState([]);
  const [Activities, setActivities] = useState([]);
  const [initiaValueSelect, setinitiaValueSelect] = useState("");
  const [listActivities, setListActivities] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [checkService, setCheckService] = useState(0);
  const [checkTransformation, setCheckTransformation] = useState(0);
  const [checkReentry, setCheckReentry] = useState(0);

  useEffect(() => {
    getData();
  }, [dataToEdit]);

  useEffect(() => {
    userC();
  }, []);

  const typeTermOptions = [
    { value: "Bloqueo", label: "Bloqueo" },
    { value: "Advertencia", label: "Advertencia" }
  ];

  const initialForm = {
    code_operation: "",
    typeOperation_id: typeOpr?.value,
    type_term: typeTerm?.value,
    term: "",
    short_name_operation: "",
    name_operation: "",
    documents_id: "",
    objetive: "",
    observation: "",
    status_operation: 1,
    services: 0,
    reentry_operation: 0,
    transformation: 0
  };

  const getData = async () => {
    const res = await __TypeOperationList(token);
    const data = res?.data?.status?.data;
    const dataToSelect = data.map((item) => ({
      value: item?.id,
      label: item?.name_typeOperation
    }));
    await setDataSelect(dataToSelect);

    if (dataToEdit) {
      const tyOprSel = await dataToSelect.filter((item) =>
        item.value === dataToEdit?.typeOperation_id ? item : null
      );
      const initialpos = async () => {
        try {
          const res = await __ShowOperation(token, dataToEdit.id);
          const { status_operation, documents, activities, type_term } =
            res?.data?.status?.data;

          // Valores anteriores
          setForm(res?.data?.status?.data);
          setCheckService(res?.data?.status?.data?.services);
          setCheckTransformation(res?.data?.status?.data?.transformation);
          setCheckReentry(res?.data?.status?.data?.reentry_operation);
          // ID
          setTypeTerm({
            label: type_term,
            value: type_term
          });

          setTypeOpr(tyOprSel[0]);
          setValor(initiaValueSelect);

          documents.map(({ name_document, id }) =>
            setValor((prev) => [...prev, { label: name_document, value: id }])
          );

          activities.map(({ name_activity, id }) =>
            setActivities((prev) => [
              ...prev,
              { label: name_activity, value: id }
            ])
          );

          setCheckStatusOp(status_operation === 1);
        } catch (error) {
          console.log(error);
        }
      };

      initialpos();
    } else {
      setForm(initialForm);
      setTypeOpr(typeOpr);
      setTypeTerm(typeTerm);
    }
  };

  const userC = async () => {
    try {
      const res = await __DocumentNoPaginate(token);
      setDocument(res?.data?.status?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const validateForm = (form) => {
    console.log(form)
    if (!form.typeOperation_id) {
      console.log("first")
      openAlert("Escoja el tipo de operación", "error");
      return false;
    }
    if (!form.type_term) {
      openAlert("Seleccione el tipo de plazo", "error");
      return false;
    }
    if (form.term === '' || form.term === null || form.term === undefined) {
      openAlert("Ingrese el plazo", "error");
      return false;
    }
    if (!form.short_name_operation) {
      openAlert("Ingrese el nombre corto", "error");
      return false;
    }
    if (!form.code_operation) {
      openAlert("Ingrese código de la operación", "error");
      return false;
    }
    if (!form.name_operation) {
      openAlert("Ingrese el nombre de la operación", "error");
      return false;
    }
    if (!form.activity_id) {
      openAlert("Seleccione la(s) actividad(es)", "error");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newDocumentid = [];
    delete form.documents;
    const idActivities = [];

    Activities.forEach(({ value }) => idActivities.push(value));
    Object.values(valor).forEach(({ value }) => newDocumentid.push(value));
    if (dataToEdit) {
      const data = {
        ...form,
        id: dataToEdit.id,
        term: !isNaN(form?.term) ? Number(form?.term) : 0,
        services: checkService,
        status_operation: checkStatusOp,
        industry_goods_services: form.industry_goods_services ? 1 : 0,
        multiple_agent: form?.multiple_agent ? 1 : 0,
        typeOperation_id: typeOpr?.value,
        type_operation: typeOpr?.label,
        type_term: typeTerm?.value,
        documents_id: newDocumentid,
        activity_id: idActivities,
        reentry_operation: checkReentry,
        transformation: checkTransformation
      };
      if (!validateForm(data)) return;
      return editData(data);
    }
    const data = {
      ...form,
      status_operation: checkStatusOp,
      term: !isNaN(form?.term) ? Number(form?.term) : 0,
      typeOperation_id: typeOpr?.value,
      type_term: typeTerm?.value,
      documents_id: newDocumentid,
      activity_id: idActivities,
      services: checkService,
      reentry_operation: checkReentry,
      transformation: checkTransformation
    };
    if (!validateForm(data)) return;
    createData(data);
  };

  const fetchActivities = async () => {
    const res = await __Get(environment.OPERATION_LIST_ACTIVITIES, token);

    if (res.data.status.code !== 200) {
      openAlert(res.data.status.message, "error");
    } else {
      setListActivities(res.data.status?.data);

      // openAlert(res.data.status.message, "success");
      // setUpdate(!update);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  const changeCheck = (e) => {
    const { name, checked } = e.target;
    const value = checked ? 1 : 0;
    const casesCheck = {
      status_operation: () => setCheckStatusOp(value),
      services: () => setCheckService(value),
      reentry_operation: () => setCheckReentry(value),
      transformation: () => setCheckTransformation(value)
    };
    casesCheck[name]();
  };

  return (
    <>
      <Text fontSize="md" mb={4} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar operación" : "Crear operación"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={handleSubmit}>
        <SimpleGrid minChildWidth="200px" spacing="20px">
          <div>
            {/* <LabeFormlStyled htmlFor="code_operation">Código</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_operation"
              id="code_operation"
              value={form?.code_operation}
              onChange={handleInputChange}
              required={true}
            /> */}
            <LabelGroup
              required={false}
              tag={"Código"}
              onChange={handleInputChange}
              name={"code_operation"}
              value={form?.code_operation}
              maxLength={4}
            />
          </div>
          <div>
            <InputSelect
              required={false}
              name={"type_term"}
              tag={"Tipo de Operacion"}
              data={dataSelect === null ? "" : dataSelect}
              value={typeOpr}
              set={setTypeOpr}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="20px">
          <div>
            {/* <LabeFormlStyled htmlFor="term">Plazo</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="term"
              id="term"
              value={form?.term}
              onChange={(e) => OnlyNumberInput(e, setForm, form)}
            /> */}
            <LabelGroup
              required={false}
              tag={"Plazo"}
              onChange={(e) => OnlyNumberInput(e, setForm, form)}
              name={"term"}
              value={form?.term}
              maxLength={MaxLength.config.operations.term}
            />
          </div>
          <div>
            <InputSelect
              required={false}
              name={"type_term"}
              tag={"Tipo de plazo"}
              data={typeTermOptions}
              value={typeTerm}
              set={setTypeTerm}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="20px">
          <div>
            {/* <LabeFormlStyled htmlFor="short_name_operation">
              Nombre corto
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="short_name_operation"
              id="short_name_operation"
              value={form?.short_name_operation}
              onChange={handleInputChange}
            /> */}
            <LabelGroup
              required={false}
              tag={"Nombre corto"}
              onChange={handleInputChange}
              name={"short_name_operation"}
              value={form?.short_name_operation}
              maxLength={MaxLength.config.operations.short_name_operation}
            />
          </div>
          <div>
            {/* <LabeFormlStyled htmlFor="name_operation">Nombre</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_operation"
              id="name_operation"
              value={form?.name_operation}
              onChange={handleInputChange}
            /> */}
            <LabelGroup
              required={false}
              tag={"Nombre"}
              onChange={handleInputChange}
              name={"name_operation"}
              value={form?.name_operation}
              maxLength={MaxLength.config.operations.name_operation}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="20px">
          {/* SOPORTE REQUERIDOS */}

          <div>
            <InputDocumentReque
              partidas={document}
              tag={"Soportes Requeridos"}
              setValor={setValor}
              valor={valor}
            />

            <SelectMultiple
              required={false}
              nameProperty={"name_activity"}
              tag={"Actividades"}
              data={listActivities}
              set={setActivities}
              value={Activities}
              placeholder=""
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="objetive">Objetivo</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="objetive"
              id="objetive"
              value={form?.objetive}
              onChange={handleInputChange}
              maxLength={MaxLength.config.operations.objetive}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="observation">Observación</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="observation"
              id="observation"
              value={form?.observation}
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="100px" spacing="10px">
          <div>
            <LabeFormlStyled htmlFor="status_operation">Activo</LabeFormlStyled>
            <div>
              <Switch
                name="status_operation"
                id="status_operation"
                size="lg"
                value={form?.status_operation}
                onChange={changeCheck}
                isChecked={checkStatusOp}
              />
            </div>
          </div>
          <div>
            <LabeFormlStyled>Servicio</LabeFormlStyled>
            <div>
              <Switch
                name="services"
                id="services"
                size="lg"
                value={form?.services}
                onChange={changeCheck}
                isChecked={checkService}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Operación de reingreso</LabeFormlStyled>
            <div>
              <Switch
                name="reentry_operation"
                id="reentry_operation"
                size="lg"
                value={form?.reentry_operation}
                onChange={changeCheck}
                isChecked={checkReentry}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Transformación</LabeFormlStyled>
            <div>
              <Switch
                name="transformation"
                id="transformation"
                size="lg"
                value={form?.transformation}
                onChange={changeCheck}
                isChecked={checkTransformation}
              />
            </div>
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit">Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default OperationsForm;
