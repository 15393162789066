import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";

export const TabsCreateOrder = ({ head, change, children }) => {
  const indexTabs = JSON.parse(localStorage.getItem("indexTabs"));
  return (
    <div className="col-xs-12">
      <Tabs
        variant="unstyled"
        onChange={(index) => change(index)}
        defaultIndex={indexTabs}
        size="md"
      >
        <TabList className="tabs">
          {head &&
            head.map((items, index) => (
              <Tab
                key={index}
                _selected={{
                  color: "#FFFFFF",
                  bg: "#007FFF",
                  borderRadius: "4px"
                  // textDecoration: "underline #1f7ac3",
                }}
                className="text-items-tabs"
              >
                {items}
              </Tab>
            ))}
        </TabList>
        <TabPanels>{children}</TabPanels>
      </Tabs>
    </div>
  );
};
