import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

/**
 *  ModalGeneric - Componente de modal generico para usar en cualquiera instancia
 * @param {Function} isOpen Funcion abrir modal
 * @param {Function} onClose Funcion cerrar modal
 * @param {String} title Titulo del modal.
 * @param {String} size Tamaño del modal.
 * @return {Component} Retorna modal.
 */

export const ModalGeneric = ({
  isOpen,
  onClose,
  children,
  title,
  size,
  scrollBehavior = "inside",
  buttonClose = true,
  ...props
}) => {
  const btnRef = React.useRef(null);
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior={scrollBehavior}
        isCentered
        autoFocus={false}
        finalFocusRef={btnRef}
        motionPreset="slideInBottom"
        size={size || "4xl"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent {...props}>
          <ModalHeader
            dangerouslySetInnerHTML={{ __html: title }}
          ></ModalHeader>
          {buttonClose ? <ModalCloseButton /> : <></>}
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
