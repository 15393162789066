import { Button } from '@chakra-ui/react'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import environment from 'constants/apiConst';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { __Post } from 'request/Petitions/__Post';

const ModalDiscard = ({ dataToDesechar, onCloseDesechar, handlePresent, movement }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert()

  const options = {
    Ingreso: "FMMI",
    Salida: "FMMS"
  }

  const handleClick = async () => {
    const body = new FormData();
    body.append("form_id", dataToDesechar.id);
    body.append("type", 1);
    body.append("type_of", options[movement]);
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    console.log(res, 'res')
    if (res?.data?.status?.code === 200) {
      return handlePresent('ELIMINADO')
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  useEffect(() => {
    return async () => {
      if (dataToDesechar) {
        const body = new FormData();
        body.append("form_id", dataToDesechar.id);
        body.append("type", 0);
        body.append("type_of", options[movement]);
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        console.log(movement, 'movement')
        console.log(dataToDesechar.id, 'dataToDesechar')
      }
    }
  }, [dataToDesechar])

  return (
    <div>
      <div className='d-flex justify-content-end mt-4 mb-2'>
        <Button onClick={onCloseDesechar}>Cancel</Button>
        <Button
          colorScheme='red'
          onClick={handleClick}
          ml={3}
        >
          Desechar
        </Button>
      </div>
    </div>
  )
}

export default ModalDiscard
