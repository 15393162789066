import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";

import FormCrudCRUDContext from "context/FormCrudContex";

const Tbody = ({ data }) => {
  const { setDataItemDispachSelect, onCloseSearchItem, seteditItemDispatch } =
    useContext(FormCrudCRUDContext);

  const handleSearchItem = () => {
    setDataItemDispachSelect(data);
    onCloseSearchItem();
    seteditItemDispatch(false);
  };

  return (
    <Tr onClick={handleSearchItem}>
      <Td className="text-center">
        {data?.output_form_code ? data?.output_form_code : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.third_business_name ? data?.third_business_name : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.authorization_date ? data?.authorization_date : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.output_form_commercial_invoice
          ? data?.output_form_commercial_invoice
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.operation_code ? data?.operation_code : "N/A"}
      </Td>

      <Td className="text-center">
        {data?.inventory_import_declaration
          ? data?.inventory_import_declaration
          : "N/A"}
      </Td>
    </Tr>
  );
};

Tbody.propTypes = {
  data: PropTypes.object
};

export default Tbody;
