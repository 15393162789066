/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { SwitchesGroup, LabelGroup } from "pages/RefProductInter/LabelGroup";

import { items } from "pages/RefProductInter/views/EditArray";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __GetModules } from "request/configuration/__Modules";
import { ButtonStyled } from "styled/Buttons.styled";
import { Regex } from "utils/Regex";

export const FormGroup = () => {
  const [status, setStatus] = useState(1);
  const [dataModules, setDataModules] = useState([]);
  const [dataModulesSelected, setDataModulesSelected] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    name_permissionGroup: "",
    descriptions_permissionGroup: "",
    status_permissionGroup: status
  });
  const { name_permissionGroup, descriptions_permissionGroup } = values;

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const { dataToEdit, onCloseModal, editData, createData } = useContext(ConfigurationCRUDContext);

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      setStatus(dataToEdit?.status_permissionGroup);
      setDataModulesSelected({ value: dataToEdit?.modul_permissionGroup, label: dataToEdit?.modul_permissionGroup })
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleAceptar = () => {
    if (dataToEdit) {
      const dataUpdate = {
        ...values,
        id: dataToEdit.id,
        status_permissionGroup: status,
        modul_permissionGroup: dataModulesSelected.value
      };
      return editData(dataUpdate);
    }
    if (!dataModulesSelected.value) return openAlert('Debe seleccionar un módulo', 'error');
    const ceateData = {
      ...values,
      modul_permissionGroup: dataModulesSelected.value
    }
    createData(ceateData);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  const getDataModules = async () => {
    const res = await __GetModules(token)
    const data = res?.data?.status?.data.map((items) => ({
      value: items.modul_permissionGroup,
      label: items.modul_permissionGroup
    }))
    console.log(data)
    setDataModules(data)
  }

  useEffect(() => {
    getDataModules()
  }, [])

  return (
    <>
      {/* <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Grupos
      </Text>
    <Divider mb={1} /> */}
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? " Editar Grupo" : "Crear Grupo"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={hanldeSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: '20px'
            // alignItems: "center",
          }}
        >
          <InputSelect
            tag={'Módulos'}
            size={45}
            data={dataModules}
            set={setDataModulesSelected}
            value={dataModulesSelected}
            required={false}
            isDisabled={!!dataToEdit}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
            // alignItems: "center",
          }}
        >
          <LabelGroup
            name={"name_permissionGroup"}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            value={name_permissionGroup}
            tag={"Nombre del Grupo"}
            size={45}
            desicion={false}
            maxLength={150}
          />
          <LabelGroup
            name={"descriptions_permissionGroup"}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            value={descriptions_permissionGroup}
            tag={"Descripción"}
            size={45}
            desicion={false}
            maxLength={5000}
          />
        </div>
        <br />
        <Flex className="mt-1">
          <div
            style={{
              // display: "flex",
              backgroundColor: "yellow",
              // justifyContent: "space-between",
              // alignItems: "center",
              marginLeft: 20
            }}
          >
            {dataToEdit && (
              <SwitchesGroup status={status} setStatus={setStatus} tag={"¿Activo?"} size={45} />
            )}
          </div>
        </Flex>
        {/* <div style={{ backgroundColor: 'red' }}>
          {dataToEdit && (
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag={"¿Activo?"}
              size={45}
              mt={20}
            />
          )}
        </div> */}

        <Flex className="mt-5">
          <Spacer />
          <Box>
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>
            <ButtonStyled type="submit" onClick={handleAceptar}>
              {dataToEdit ? " Editar Grupo" : "Crear Grupo"}
            </ButtonStyled>
          </Box>
        </Flex>
      </form>
    </>
  );
};
