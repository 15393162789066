import { Flex, Spacer, Textarea } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import MonitoringContext from "context/MonitoringContext";
import { useForm } from "hooks/useForm";
import { useRegexState } from "hooks/useRegexState";
import { LabelGroup, RegexLabelGroup } from "pages/RefProductInter/LabelGroup";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __CreateFollowOrder,
  __EditFollowOrder,
  __Get_FollowOrderTable
} from "request/Monitoring/__Get_Operations";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";

export const FormCreateFollowOrder = ({ onClose, getFollowOrder }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    values,
    handleInputChange,
    setFormValues: setValues
  } = useRegexState({
    follow_order: ""
  }, /^[a-zA-Z0-9\sáéíóúÁÉÍÓÚ]*$/);

  const { follow_order } = values;

  const { editFollowOrder, setEditFollowOrder } = useContext(MonitoringContext);

  const editData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", editFollowOrder?.id);
      formData.append("edit", true);
      const res = await __Get_FollowOrderTable(token, formData);
      const valores = res?.data?.status?.data;
      const descriptionValue = res?.data?.status?.data?.description;
      setValues(valores);
      setDescription(descriptionValue || "");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editFollowOrder) {
      editData();
    }
  }, [editFollowOrder]);

  const handleEditFollowOrder = async () => {
    if (!follow_order) {
      openAlert("El campo gestor de seguimiento es requerido", "error");
    } else {
      try {
        const formData = new FormData();
        formData.append("follow_order", follow_order);
        formData.append("description", description || "");
        formData.append("id", editFollowOrder?.id);
        const res = await __EditFollowOrder(token, formData);
        const message = res?.data?.status?.message;
        const code = res?.data?.status?.code;
        if (code === 200) {
          getFollowOrder();
          openAlert(message, "success");
          onClose();
        } else if (code !== 200) {
          openAlert(message, "error");
          onClose();
        }
        onClose();
      } catch (error) {
        console.log(error);
        setEditFollowOrder(null);
      }
    }
  };

  const createFollowOrder = async () => {
    if (!follow_order) {
      openAlert("El campo gestor de seguimiento es requerido", "error");
    } else {
      try {
        const formData = new FormData();
        formData.append("follow_order", follow_order.toUpperCase());
        formData.append("description", description || "");
        const res = await __CreateFollowOrder(token, formData);
        console.log(res, "respuesta");
        const message = res?.data?.status?.message;
        const code = res?.data?.status?.code;

        if (code === 200) {
          getFollowOrder();
          openAlert(message, "success");
          onClose();
        } else if (code !== 200) {
          openAlert(message, "error");
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="d-flex flex-column pb-3">
      <LabelGroup
        name="follow_order"
        onChange={handleInputChange}
        value={follow_order}
        tag={"Gestor*"}
        size={100}
      />
      <Textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Descripción"
        size="sm"
        style={{
          borderRadius: "5px",
          marginTop: "20px"
        }}
        maxLength={MaxLength.monitoring.followOrder.description}
      />
      <Flex className="my-3">
        <ButtonStyled onClick={onClose} bgColor={"#bababa"}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={editFollowOrder ? handleEditFollowOrder : createFollowOrder}
        >
          {editFollowOrder ? "Editar" : "Crear"}
        </ButtonStyled>
      </Flex>
    </div>
  );
};
