import { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import queryEnv from "constants/queryConst";
import environment from "constants/apiConst";
// import { MdOutlinePostAdd } from "react-icons/md";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuCorrected = ({ data }) => {
  const {
    onToggle,
    setDataIdOrder,
    setShowProduct,
    setDataIdProduct,
    onOpenDeleteCorrected,
    onOpenDeleteProductCorrected,
    setDataDeleteCorrect,
    setDataDProduct,
    setActiveView,
  } = useContext(IntegracionBoxCrudContex);

  const { requestData } = useGlobalContext()
  const queryClient = useQueryClient();
  const [openAlert] = AlertErrorAlert();


  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: data => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, 'error')
      }

      if (data?.data?.status?.code === 200) {
        queryClient.invalidateQueries({ queryKey: [queryEnv?.PRODUCTS_CI] })
        queryClient.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] })
        openAlert(data?.data?.status?.message, 'success')
      }
    }
  })
  const handleDelete = () => {
    Swal.fire({
      title: `¿Desea eliminar ${data?.code_product_corrected ? "la orden de producción" : "el producto elaborado"} ${data?.code_product_corrected}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text"
      },
      text: `Le recordamos limpiar la información agregada de materias primas e insumos, otros gastos y costos, si es el caso.`,
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        mutate({
          data: {
            id_corrected: data?.id_corrected
          },
          Endpoint: environment.DELETE_CORRECTED_PRODUCT_AND_ORDER,
          PropertyBody: 'sendJSONContentPOST',
        })
      }
    });
    /*  if (data?.production_order_code_corrected) {
       onOpenDeleteCorrected();
       setDataDeleteCorrect(data);
     } else {
       setDataDProduct(data);
       onOpenDeleteProductCorrected();
     } */
  };

  const handleEditOrder = () => {
    if (data?.production_order_code_corrected) {
      setDataIdOrder(data);
      onToggle();
      setActiveView(null);
      setShowProduct(false);
    } else {
      setShowProduct(true);
      setActiveView("showProduct");
      setDataIdProduct(data);
    }
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <>
          <HrDividerNoMargin />

          <MenuItemStyled py={3} onClick={handleEditOrder}>
            <IconMenuRUD as={RiEdit2Fill} />
            Editar
          </MenuItemStyled>
        </>
        <HrDividerNoMargin />
        <MenuItemStyled py={3} onClick={handleDelete}>
          <IconMenuRUD as={MdDelete} />
          Eliminar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default MenuCorrected;
