/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TbodyZf from "../tables/TbodyZf";
import { __TypeZf } from "request/configuration/TypeZf";
import { FormTypeZF } from "../forms/FormTypeZF";
import FormTypeZFShow from "../FormShow/FormTypeZFShow";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const formEditSub = () => {
  return <FormTypeZF />;
};

const formShow = () => {
  return <FormTypeZFShow />;
};

export const TypeZfb = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Tipo Zona Franca", order: "name_typeFreeZone" },
    { name: "Descripción Zona Franca", order: "description_typeFreeZone" },
    { name: "Estado", order: "status_typeFreeZone" },
    { name: "Acciones", order: null },
  ]; 

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formShow);
      setRequestEdit(environment.TYPEZF_UPDATE);
      setRequestCreate(environment.TYPEZF_CREATE);
      setRequestDelete(environment.TYPEZF_DELETE);

  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configTypeZFB", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.TYPEZF_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Tipo de Zona franca"></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={Title}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodyZf}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
