export const restWorld = [
  "num_transport_document_corrected",
  "transp_doc_date_corrected",
  "cargo_manifest_entryFormCorrected",
  "num_commercial_invoice_corrected",
  "num_exit_form_corrected",
  "integration_box_certificate_corrected"
];

export const varRestTerritory = [
  "num_dex_entryFormCorrected",
  "dex_date_entryFormCorrected",
  "num_import_declaration_corrected",
  "integration_box_certificate_corrected",
  "num_commercial_invoice_corrected",
  "num_exit_form_corrected",
  "customs_administration_corrected"
];

export const varFrezone = [
  "num_dex_entryFormCorrected",
  "num_commercial_invoice_corrected",
  "exit_auth_entryFormCorrected",
  "integration_box_certificate_corrected",
  "dcl_customs_transit_corrected",
  "transit_expiration_date_corrected"
];
export const varUserToUser = ["num_exit_form_corrected"];

export const dataEmbarque = [
  {
    label: "Único",
    value: "Único"
  },
  {
    label: "Fraccionado",
    value: "Fraccionado"
  }
];
export const dataRefund = [
  {
    label: "Con reintegro",
    value: "Con reintegro"
  },
  {
    label: "Sin reintegro",
    value: "Sin reintegro"
  }
];
