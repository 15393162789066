import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __Active, __Desactive } from "request/configuration/__ActivityUser";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyActivityUser = ({ data }) => {
  const { setUpdate, update } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [loadingIcons, setLoadingIcons] = useState(false);

  let isRequestPending = false;

  const activate = async () => {
    const resultado = await __Active(token, data.id);
    if (resultado.data.status.code !== 200) {
      openAlert(resultado.data.status.message, "error");
    } else {
      openAlert(resultado.data.status.message, "success");
      setUpdate(!update);
    }
  };

  const deactivate = async () => {
    const resultado = await __Desactive(token, data.id);
    if (resultado.data.status.code !== 200) {
      openAlert(resultado.data.status.message, "error");
    } else {
      openAlert(resultado.data.status.message, "success");
      setUpdate(!update);
    }
  };

  const handleStatus = async () => {
    if (isRequestPending) {
      return;
    }

    setLoadingIcons(true);
    isRequestPending = true;

    try {
      if (data.status_activity === 0) {
        await activate();
      } else {
        await deactivate();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
      isRequestPending = false;
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.name_activity}</Td>
      <Td className="text-center">
        {data?.description_activity === null
          ? "N/A"
          : data?.description_activity}
      </Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data.status_activity === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          className="text-center"
          isNumeric
          text={data?.status_activity}
          onClick={() => handleStatus()}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_activity}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyActivityUser;
