import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  SimpleGrid,
  Switch,
  Text
} from '@chakra-ui/react'
import './styleForUsuario.css'
import { InputFormStyled, LabeFormlStyled } from 'styled/Input.styled'
import { useForm } from '../../hooks/useForm'
import ConfigurationCRUDContext from '../../context/ConfigurationCRUDContext'
import { ButtonStyled } from '../../styled/Buttons.styled'
import InputSelect, {
  InputActivity,
  InputCitys,
  InputClasificacion,
  InputSectores,
  InputZonasF
} from 'components/InputSelect/InputSelect'
import {
  InputGrouLicense,
  LabelExp
} from 'pages/Configuration/InputSelectsConfig/InputSelectUserCa'
import { __AddLicensesAll } from 'request/configuration/__AddLicenses'
import { __ZonasFranca } from 'request/configuration/__ZonasFranca'
import {
  __QualifiedActivity,
  __QualifiedClasificacion,
  __QualifiedSector
} from 'request/configuration/__UserQualifed'
import { useSelector } from 'react-redux'
import { AuthContext } from 'context/AuthContext'
import environment from 'constants/apiConst'
import { __PostForm } from 'request/Petitions/__Post'
import { MaxLength } from 'utils/MaxLength'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useDebounceError } from 'hooks/useDebounceError'
import { useQuery } from '@tanstack/react-query'
import { useGlobalContext } from 'context/GlobalContext'
import { Regex } from 'utils/Regex'

const EditFormUsuario = () => {
  const [checkUnlinked, setCheckUnlinked] = useState(true)
  const [checkStusQualified, setCheckStusQualified] = useState(true)
  const [
    checkGenericSubheadingDeclaration,
    setCheckGenericSubheadingDeclaration
  ] = useState(true)
  const [checkThirdPartyDocuments, setCheckThirdPartyDocuments] = useState(true)
  const [checkThirdPartyItems, setCheckThirdPartyItems] = useState(true)
  const [Checkcharge_reweight, setCharger_reweight] = useState(false)
  const [checkTermsAndConditions, setCheckTermsAndConditions] = useState(true)
  const [valueTarif, setValueTariff] = useState(null)
  const [mendigo, setMendigo] = useState('')
  const [zonasF, setZonasF] = useState('')
  const [zonas, setZonas] = useState('')
  const [city, setCity] = useState('')
  const [sector, setSector] = useState('')
  const [activity, setActivity] = useState('')
  const [clasification, setClasification] = useState('')
  const [sectoresQ, setSectoresQ] = useState('')
  const [actividadQ, setActividadQ] = useState('')
  const [clasificacionQ, setClasificacionQ] = useState('')
  const [userCa, setUserCa] = useState([])
  const [valor, setValor] = useState([])
  const [expDate, setExpDate] = useState([])
  const [placeLi, setPlaceLi] = useState([])
  const [zonaF, setZonaF] = useState([])
  const [selCityId, setSelCityId] = useState([])
  const [sectores, setSectores] = useState([])
  const [actividad, setActividad] = useState([])
  const [clasificacion, setClasificacion] = useState([])
  const [dataTypeTariff, setDataTypeTariff] = useState([])
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const { dataToEdit, onCloseModal, editData, createData, alertSave } =
    useContext(ConfigurationCRUDContext)

  const [values, handleInputChange, reset, setValues] = useForm({
    freeZone_id: '',
    city_id: '',
    activity_id: '',
    qualificationQualifiedUser_id: '',
    nit_qualified: '',
    // code_ciiu: "",
    sector_id: '',
    name_qualified: '',
    nickname_qualified: '',
    address_qualified: '',
    email_qualified: '',
    phone_1_qualified: '',
    phone_2_qualified: '',
    phone_3_qualified: '',
    representative: '',
    manager: '',
    phone_manager: '',
    email_manager: '',
    collect: '',
    unlinked: 1,
    charge_reweight: 1,
    generic_subheading_declaration: 1,
    decimal_values_precision: '',
    qualification_date: '',
    partial_processing_revision_amount: '',
    total_amount_incomeGenerating_activity: '',
    third_party_documents: 1,
    third_party_items: 1,
    terms_and_conditions: 1,
    status_qualified: 1
  })

  const {
    nit_qualified,
    // code_ciiu,
    name_qualified,
    nickname_qualified,
    address_qualified,
    email_qualified,
    phone_1_qualified,
    phone_2_qualified,
    representative,
    manager,
    phone_manager,
    email_manager,
    unlinked,
    generic_subheading_declaration,
    decimal_values_precision,
    qualification_date,
    partial_processing_revision_amount,
    total_amount_incomeGenerating_activity,
    third_party_documents,
    third_party_items,
    terms_and_conditions,
    status_qualified,
    charge_reweight
  } = values

  const hanldeSubmit = async e => {
    e.preventDefault()
  }
  const { authUser } = useContext(AuthContext)

  // useEffect que escucha si va a editar o crear

  useEffect(() => {
    const getData = async () => {
      if (dataToEdit) {
        // ID
        setValues(dataToEdit)
        setMendigo(dataToEdit?.city_id)
        setZonasF(dataToEdit?.freeZone_id)
        setSectoresQ(dataToEdit?.sector_id)
        setActividadQ(dataToEdit?.activity_id)
        setClasificacionQ(dataToEdit?.qualificationQualifiedUser_id)

        // Names
        setActivity(dataToEdit?.name_activity)
        setSector(dataToEdit?.name_sector)
        setValueTariff({
          value: dataToEdit.typeRate_id,
          label: dataToEdit.name_type_rate
        })

        setZonas(dataToEdit?.name_freeZone)
        setCity(dataToEdit?.name_city)
        setClasification(dataToEdit?.name_qualificationQualifiedUser)
        setCheckUnlinked(dataToEdit.unlinked === 1)
        setCheckStusQualified(dataToEdit.status_qualified === 1)

        // Checks
        setCheckGenericSubheadingDeclaration(
          dataToEdit.generic_subheading_declaration === 1
        )
        setCheckThirdPartyDocuments(dataToEdit.third_party_documents === 1)
        setCheckThirdPartyItems(dataToEdit.third_party_items === 1)

        setCharger_reweight(dataToEdit.charge_reweight === 1)

        setCheckTermsAndConditions(dataToEdit.terms_and_conditions === 1)

        dataToEdit.license_id.map(items =>
          setValor(prev => [...prev, items.id])
        )
      } else {
        setValues(values)
      }
    }
    getData()
  }, [dataToEdit])

  // ***** SERVICIOS *** //

  const userC = useCallback(async () => {
    try {
      const res = await __AddLicensesAll(token)
      setUserCa(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  const ZonasFranca = useCallback(async () => {
    try {
      const res = await __ZonasFranca(token)
      setZonaF(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    }
  }, [token])
  const { requestData } = useGlobalContext()

  const handleResponse = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      setSelCityId(res?.data?.status?.data)
    }
  }

  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['getCity'],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_CITY_NOT_PAGINATE
      }),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (isSuccess) handleResponse(data)
  }, [data])

  const Sectores = useCallback(async () => {
    try {
      const res = await __QualifiedSector(token)
      setSectores(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  const Actividad = useCallback(async () => {
    try {
      const res = await __QualifiedActivity(token)
      setActividad(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  const Clasificacion = useCallback(async () => {
    try {
      const res = await __QualifiedClasificacion(token)
      setClasificacion(res?.data?.status?.data)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  useEffect(() => {
    userC()
    ZonasFranca()
    Sectores()
    Actividad()
    Clasificacion()
  }, [userC, ZonasFranca, Sectores, Actividad, Clasificacion])

  const getTariff = useCallback(
    async zoneId => {
      try {
        const res = await __PostForm(environment.GET_TARRIF_ZONE, token, zoneId)
        const dataInput = res.data.status.data.map(item => ({
          value: item?.id,
          label: item?.name_type_rate
        }))

        setDataTypeTariff(dataInput)
      } catch (e) {
        console.log(e)
      }
    },
    [token]
  )
  useEffect(() => {
    getTariff()
  }, [])

  // Funcion para Editar y Crear
  const handleAceptar = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        collect: valueTarif.value,
        id: dataToEdit.id,
        unlinked: unlinked ? 1 : 0,
        generic_subheading_declaration: generic_subheading_declaration ? 1 : 0,
        third_party_documents: third_party_documents ? 1 : 0,
        charge_reweight: charge_reweight ? 1 : 0,
        third_party_items: third_party_items ? 1 : 0,
        terms_and_conditions: terms_and_conditions ? 1 : 0,
        status_qualified: status_qualified ? 1 : 0,
        freeZone_id: zonasF,
        city_id: mendigo,
        activity_id: actividadQ,
        qualificationQualifiedUser_id: clasificacionQ,
        license_id: valor
      }
      editData(data)
    } else {
      const data = {
        ...values,
        unlinked: unlinked ? 1 : 0,
        collect: valueTarif !== null ? valueTarif.value : '',
        sector_id: sectoresQ,
        generic_subheading_declaration: generic_subheading_declaration ? 1 : 0,
        third_party_documents: third_party_documents ? 1 : 0,
        charge_reweight: charge_reweight ? 1 : 0,
        third_party_items: third_party_items ? 1 : 0,
        terms_and_conditions: terms_and_conditions ? 1 : 0,
        status_qualified: status_qualified ? 1 : 0,
        freeZone_id: zonasF,
        city_id: mendigo,
        activity_id: actividadQ,
        qualificationQualifiedUser_id: clasificacionQ,
        license_id: valor
      }
      createData(data)
    }
  }

  // Validaciones Checks
  const changeCheck = e => {
    handleInputChange(e)
    if (e.target.name === 'unlinked') {
      setCheckUnlinked(e.target.checked)
      return
    }
    if (e.target.name === 'status_qualified') {
      setCheckStusQualified(e.target.checked)
      return
    }
    if (e.target.name === 'generic_subheading_declaration') {
      setCheckGenericSubheadingDeclaration(e.target.checked)
      return
    }
    if (e.target.name === 'third_party_items') {
      setCheckThirdPartyItems(e.target.checked)
      return
    }
    if (e.target.name === 'third_party_documents') {
      setCheckThirdPartyDocuments(e.target.checked)
      return
    }
    if (e.target.name === 'charge_reweight') {
      setCharger_reweight(e.target.checked)
      return
    }
    if (e.target.name === 'terms_and_conditions') {
      setCheckTermsAndConditions(e.target.checked)
    }
  }

  // Funciones para remover las diferentes Licencias cuando se edite
  const addOrRemoveLic = e => {
    if (!e.target.checked) {
      const remover = parseInt(e.target.value)
      removeItemFromArr(valor, remover)
    } else {
      setValor(prev => [...prev, parseInt(e.target.value)])
    }
  }

  const removeItemFromArr = (arr, item) => {
    const i = arr.indexOf(item)
    if (i !== -1) {
      arr.splice(i, 1)
    }
  }

  const [handleDebounceRegexError] = useDebounceError()

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all'
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    )
  }

  return (
    <>
      {/* <Text fontSize="xl" mb={3} ml={2} style={{ fontWeight: 600 }}>
        Usuario calificado
      </Text>
      <Divider /> */}
      <Text fontSize='md' my={3} ml={2} style={{ fontWeight: 600 }}>
        {dataToEdit ? 'Editar usuario calificado' : 'Crear usuario calificado'}
      </Text>
      <Divider />

      <form onSubmit={hanldeSubmit}>
        <SimpleGrid minChildWidth='250px' spacing='20px'>
          <div>
            <LabeFormlStyled>Nit</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='nit_qualified'
              value={nit_qualified}
              onChange={e => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={MaxLength.config.users.nit_qualified}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='250px' spacing='20px'>
          <div>
            <InputZonasF
              partidas={zonaF}
              tag={'Zona Franca'}
              setValor={setZonasF}
              setZonas={setZonas}
              zonas={zonas}
              mendigo={zonasF}
            />
          </div>
          <div>
            <InputCitys
              partidas={selCityId}
              isLoading={isLoading}
              tag={'Ciudad'}
              setCity={setCity}
              city={city}
              setValor={setMendigo}
              mendigo={mendigo}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px' mt={2}>
          {/* <div>
            <LabeFormlStyled>Código CIIU</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_ciiu"
              value={code_ciiu}
              onChange={handleInputChange}
            />
          </div> */}
          <div>
            <InputSectores
              partidas={sectores}
              tag={'Sector'}
              setSector={setSector}
              sector={sector}
              setValor={setSectoresQ}
              mendigo={sectoresQ}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Nombre</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='name_qualified'
              value={name_qualified}
              onChange={e => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.users.name_qualified}
            />
          </div>

          <div>
            <LabeFormlStyled>Alias</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='nickname_qualified'
              value={nickname_qualified}
              onChange={e => handleRegex(e, 'all', Regex?.all)}
              maxLength={MaxLength.config.users.nickname_qualified}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Dirección</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='address_qualified'
              value={address_qualified}
              onChange={handleInputChange}
              maxLength={MaxLength.config.users.address_qualified}
            />
          </div>
          <div>
            <LabeFormlStyled>Correo electrónico</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='email_qualified'
              value={email_qualified}
              onChange={handleInputChange}
              maxLength={MaxLength.config.users.email_qualified}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Teléfono 1</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='phone_1_qualified'
              value={phone_1_qualified}
              onChange={e => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={MaxLength.config.users.phone_1_qualified}
            />
          </div>
          <div>
            <LabeFormlStyled>Teléfono 2</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='phone_2_qualified'
              value={phone_2_qualified}
              onChange={e => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={MaxLength.config.users.phone_2_qualified}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Representante</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='representative'
              value={representative}
              onChange={e => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.users.representative}
            />
          </div>
          <div>
            <LabeFormlStyled>Gerente</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='manager'
              value={manager}
              onChange={e => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.users.manager}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Celular del gerente</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='phone_manager'
              value={phone_manager}
              onChange={e => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={MaxLength.config.users.phone_manager}
            />
          </div>
          <div>
            <LabeFormlStyled>Correo electrónico gerente</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='email_manager'
              value={email_manager}
              onChange={handleInputChange}
              maxLength={MaxLength.config.users.email_manager}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px'>
          <div>
            <LabeFormlStyled>Precisión valores decimales</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='decimal_values_precision'
              value={decimal_values_precision}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <InputActivity
              partidas={actividad}
              tag={'Actividad'}
              setActivity={setActivity}
              activity={activity}
              setValor={setActividadQ}
              mendigo={actividadQ}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px' className='my-2'>
          <div>
            <LabeFormlStyled>
              M. de revisión procesamiento parcial
            </LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='partial_processing_revision_amount'
              value={partial_processing_revision_amount}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <LabeFormlStyled>M. total Act generadora de renta</LabeFormlStyled>
            <InputFormStyled
              type='text'
              name='total_amount_incomeGenerating_activity'
              value={total_amount_incomeGenerating_activity}
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>
        <SimpleGrid minChildWidth='175px' spacing='20px' mt={2}>
          <div>
            <InputClasificacion
              partidas={clasificacion}
              tag={'Clasificación'}
              setClasification={setClasification}
              clasification={clasification}
              setValor={setClasificacionQ}
              mendigo={clasificacionQ}
            />
          </div>
          <div>
            <InputSelect
              tag={'Cobrar'}
              size={100}
              data={dataTypeTariff}
              set={setValueTariff}
              value={valueTarif}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px' className='mt-2'>
          {dataToEdit && (
            <>
              <div>
                <InputGrouLicense
                  partidas={userCa}
                  tag={'Licencias'}
                  setValor={setValor}
                  placeholder={'Licencias'}
                  setExpDate={setExpDate}
                  valor={valor}
                  placeLi={placeLi}
                  setPlaceLi={setPlaceLi}
                />
              </div>

              <div>
                <h1 className='my-3'>Licencias</h1>
                {dataToEdit?.license_id?.map((items, index) => (
                  <div key={index}>
                    <Checkbox
                      className='mx-3'
                      value={items?.id}
                      onChange={addOrRemoveLic}
                      defaultIsChecked={true}
                    >
                      {items.name_license}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </>
          )}

          {!dataToEdit && (
            <div>
              <LabelExp
                name={'arancel'}
                value={expDate}
                tag={'Fecha de vencimiento'}
                desicion={true}
              />
            </div>
          )}
        </SimpleGrid>

        <SimpleGrid minChildWidth='175px' spacing='20px' className='mt-2'>
          <div style={{ width: '25%' }}>
            <LabeFormlStyled>Fecha de Calificación</LabeFormlStyled>
            <InputFormStyled
              type={'date'}
              name='qualification_date'
              value={
                qualification_date === null
                  ? ''
                  : qualification_date.slice(0, 10)
              }
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='50px' spacing='20px' className='mt-2'>
          <div>
            <LabeFormlStyled>Desvinculado</LabeFormlStyled>
            <div>
              <Switch
                name='unlinked'
                id='unlinked'
                size='lg'
                value={unlinked}
                onChange={changeCheck}
                isChecked={checkUnlinked}
              />
            </div>
          </div>
          <div>
            <LabeFormlStyled>Activo</LabeFormlStyled>
            <div>
              <Switch
                name='status_qualified'
                id='status_qualified'
                size='lg'
                value={status_qualified}
                onChange={changeCheck}
                isChecked={checkStusQualified}
              />
            </div>
          </div>
          <div>
            <LabeFormlStyled>
              Puede utilizar subpartida genérica en declaración
            </LabeFormlStyled>
            <div>
              <Switch
                name='generic_subheading_declaration'
                id='generic_subheading_declaration'
                size='lg'
                value={generic_subheading_declaration}
                onChange={changeCheck}
                isChecked={checkGenericSubheadingDeclaration}
              />
            </div>
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth='50px' spacing='20px' className='mt-2'>
          <div>
            <LabeFormlStyled>
              Puede utilizar tercero en documentos
            </LabeFormlStyled>
            <div>
              <Switch
                name='third_party_documents'
                id='third_party_documents'
                size='lg'
                value={third_party_documents}
                onChange={changeCheck}
                isChecked={checkThirdPartyDocuments}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>
              Puede utilizar tercero en ítems de documentos
            </LabeFormlStyled>
            <div>
              <Switch
                name='third_party_items'
                id='third_party_items'
                size='lg'
                value={third_party_items}
                onChange={changeCheck}
                isChecked={checkThirdPartyItems}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Cobrar repesaje</LabeFormlStyled>
            <div>
              <Switch
                name='charge_reweight'
                id='charge_reweight'
                size='lg'
                value={charge_reweight}
                onChange={changeCheck}
                isChecked={Checkcharge_reweight}
              />
            </div>
          </div>
        </SimpleGrid>

        {/* <Espaciada ocho></> */}

        {/* <SimpleGrid>
          <div className="boxs">
            <div className="spacingin">
              <LabeFormlStyled>
                Puede utilizar tercero en ítems de documentos
              </LabeFormlStyled>
              <div>
                <Switch
                  name="third_party_items"
                  id="third_party_items"
                  size="lg"
                  value={third_party_items}
                  onChange={changeCheck}
                  isChecked={checkThirdPartyItems}
                />
              </div>
            </div>
          </div>
        </SimpleGrid> */}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexWrap={'wrap'}
        >
          <ButtonStyled bgColor={'#bababa'} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type='submit' onClick={handleAceptar}>
            Aceptar
          </ButtonStyled>
        </Box>
      </form>
    </>
  )
}

export default EditFormUsuario
