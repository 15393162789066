/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box } from "@chakra-ui/react";
import environment from "constants/apiConst";
import { useCallback, useContext, useEffect } from "react";
import { useForm } from "hooks/useForm";
import FormIntegration from "./FormIntegration/FormIntegration";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { __showBox } from "request/IntegrationBox/___IntegrationBox";
import FormIdOrdeProduction from "./Form/FormIdOrdeProduction";
import { ButtonStyled } from "styled/Buttons.styled";
import { __SearchUser } from "request/Reviews/__GetTReviews";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import { parseFloat } from "utils/parseFloat";
import { isValid } from "utils/type-check-utils";

const IntegrationBody = ({ data }) => {
  const location = useLocation();
  const {
    id = null,
    qualifiedUser = null,
    qualifiedUserID = null,
    typeCertificateV = null,
  } = queryString.parse(location.search);

  const { requestData } = useGlobalContext();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { balance } = useSelector((state) => state.status);
  const dispatch = useDispatch();

  const {
    setUserQualified,
    setDestiny,
    setdataProductQuantity,
    setDataInsumos,
    destiny,
    userQualified,
    setRequestSaveHead,
    SaveDataHead,
    returnReviews,
    productionOrderReviews,
    setRequestReviews,
  } = useContext(IntegracionBoxCrudContex);
  const { setUser_Qualified, setCode_destination } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    observations: "",
  });

  useEffect(() => {
    if (isValid(qualifiedUserID)) {
      setUserQualified({
        label: qualifiedUser,
        value: parseFloat(qualifiedUserID),
      });
    }
  }, [qualifiedUserID]);

  useEffect(() => {
    const dataNew = data?.newData?.data;

    if (data) setRequestReviews(data?.reviews);

    if (data) {
      setUserQualified({
        label:
          data?.nit_qualified &&
          data?.nit_qualified + "-" + data?.name_qualified,
        value: data?.qualifiedUser_id,
      });
    }

    setDestiny({
      label: data?.destiny,
      value: data?.destiny_id,
      code: data?.code_destiny,
    });

    setCode_destination(data?.code_destiny);

    if (dataNew) {
      setDestiny({
        label: dataNew?.destiny,
        value: dataNew?.destiny_id,
        code: dataNew?.code_destiny,
      });
      setCode_destination(data?.code_destiny);
      setValues(dataNew);
      setUserQualified({
        label:
          dataNew?.nit_qualified &&
          dataNew?.nit_qualified + "-" + dataNew?.name_qualified,
        value: dataNew?.qualifiedUser_id,
      });
    } else if (data?.destiny_id !== null && data?.observations !== null) {
      setDestiny({
        label: data?.destiny,
        value: data?.destiny_id,
        code: data?.code_destiny,
      });
      setCode_destination(data?.code_destiny);
      setValues(data);
    } else {
    }
  }, [data]);

  const SaveReviewsUpdate = async (data) => {
    const obj = {
      type_doc: "CIntegracion",
      id,
      review: data,
    };
    if (obj.review.length > 0) {
      try {
        const res = await __SearchUser(
          environment.GUARDAR_CORRECION_REVISION,
          token,
          obj
        );
        const status = res?.data?.status;
        if (status.code !== 200) {
          openAlert(status?.message, "error");
        } else {
        }
      } catch (error) {
      } finally {
      }
    } else {
    }
  };

  const handleSaveBox = () => {
    if (!userQualified.value) {
      openAlert("El campo usuario calificado es requerido", "error");
      return;
    }
    if (!destiny?.value) {
      openAlert("El campo tipo de cuadro es requerido", "error");
      return;
    }
    if (returnReviews && productionOrderReviews) {
      const newArray = [...returnReviews, ...productionOrderReviews];
      SaveReviewsUpdate(newArray);
    }
    if (returnReviews && !productionOrderReviews) {
      SaveReviewsUpdate(returnReviews);
    }
    if (productionOrderReviews && !returnReviews) {
      SaveReviewsUpdate(productionOrderReviews);
    }

    const dataHeadSend = new FormData();
    dataHeadSend.append("id", id);
    dataHeadSend.append("destiny_id", destiny.value || "");
    dataHeadSend.append("observations", values?.observations || "");
    dataHeadSend.append(
      "qualifiedUser_id",
      userQualified !== null ? userQualified.value : ""
    );
    SaveDataHead(dataHeadSend);
    navigate(`/ShowIntegrationBox?id=${id}`);
    handleReset();
  };

  const handleReset = () => {
    setdataProductQuantity("");
    setUserQualified(null);
    setDestiny(null);
    setCode_destination(null);
    setDataInsumos("");
    reset();
  };

  useEffect(() => {
    setRequestSaveHead(environment.CREATE_INTEGRATION_BOX_HEAD);
  }, [setRequestSaveHead]);

  const handleSaveBoxCorrected = () => {
    const dataHeadSend = new FormData();
    dataHeadSend.append("id", id); // idCorrected
    dataHeadSend.append("destinyCorrected_id", destiny?.value ?? "");
    dataHeadSend.append("observation_corrected", values.observations);
    dataHeadSend.append(
      "qualifiedUserCorrected_id",
      userQualified?.value ?? ""
    );
    SaveDataHead(dataHeadSend);
    navigate(`/ShowIntegrationBox?id=${id}`);
    handleReset();
  };

  useEffect(() => {
    if (id) {
      return async () => {
        setUserQualified(null);
        setDestiny(null);
        const body = new FormData();
        body.append("form_id", id);
        body.append("type_of", "CI");
        body.append("type", 0);
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      };
    }
  }, [id]);

  const respCreateCI = (res) => {
    if (res?.data?.status?.code === 200) {
      const numberFormIn = res.data.status.data.cetificate;
      const BalanceOrder = res.data.status.data?.balance;
      navigate(`/IntegracionBox?id=${numberFormIn?.id}`);
      localStorage.setItem("ValueBalance", JSON.stringify(BalanceOrder));
      dispatch(setDataStatusBalance(!balance));
      handleReset();
    }

    if (res?.data?.status?.code !== 200) {
      openAlert(res.data.status.message, "error");
    }
  };

  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const createCI = () => {
    if (!isValid(qualifiedUserID)) {
      openAlert("El campo usuario calificado es requerido", "error");
      return;
    }
    if (!destiny?.value) {
      openAlert("El campo tipo de cuadro es requerido", "error");
      return;
    }
    if (returnReviews && productionOrderReviews) {
      const newArray = [...returnReviews, ...productionOrderReviews];
      SaveReviewsUpdate(newArray);
    }
    if (returnReviews && !productionOrderReviews) {
      SaveReviewsUpdate(returnReviews);
    }
    if (productionOrderReviews && !returnReviews) {
      SaveReviewsUpdate(productionOrderReviews);
    }

    const formData = new FormData();
    formData.append("qualifiedUser_id", parseFloat(qualifiedUserID));
    formData.append("destiny_id", destiny.value || "");
    formData.append("observations", values?.observations || "");
    formData.append("integration_certificate_type_id", typeCertificateV || "");

    const config = {
      data: formData,
      Endpoint: environment.CREATE_INTEGRATION_BOX,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (e) => respCreateCI(e),
    });
  };

  return (
    <>
      <FormIntegration
        values={values}
        setValues={setValues}
        handleInputChange={handleInputChange}
      />
      {!isValid(qualifiedUserID) && (
        <div className="mt-5">
          {/* Aqui estan los modales */}
          <FormIdOrdeProduction observations={values?.observations} />
        </div>
      )}

      {/* <Button color="aqua">Adjunto</Button> */}

      <div className="mb-3 ms-5">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          {id || qualifiedUserID ? (
            <ButtonStyled
              style={{ marginBottom: "40px" }}
              type="submit"
              onClick={qualifiedUserID ? createCI : handleSaveBox}
            >
              Guardar
            </ButtonStyled>
          ) : (
            <ButtonStyled
              style={{ marginBottom: "40px" }}
              type="submit"
              onClick={handleSaveBoxCorrected}
            >
              Guardar
            </ButtonStyled>
          )}
        </Box>
      </div>
    </>
  );
};

export default IntegrationBody;
