import React, { useContext, useState } from "react";
import styled from "styled-components";
import MenuRUD from "components/Menu/MenuRUD";
import { BsCheckCircleFill } from "react-icons/bs";
import { IconGeneric } from "styled/Icons.styled";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowCountry } from "request/configuration/__Localitation";
import { ConfigIconStatus } from "pages/Configuration/tables/components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyPaises = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();
  const [loadingIcons, setLoadingIcons] = useState(false);

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(
            token,
            environment.LOCALITATION_DESACTIVAR,
            data.id
          )
          : await __ACTIVAR(token, environment.LOCALITATION_ACTIVAR, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __ShowCountry(token, data.id);
      const result = res.data.status.data.status_country;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.code_country}</Td>
      <Td className="text-center">{data?.name_country}</Td>
      <Td className="text-center" isNumeric>
        {data?.market ?? "--"}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.tax_haven}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.origin_country}
      </Td>
      <Td className="text-center" isNumeric>
        {data?.destiny_country}
      </Td>

      <Tooltip
        placement="top"
        hasArrow
        label={data?.status_country === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data?.status_country}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_country}
          />
        </TdState>
      </Tooltip>

      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyPaises;
