import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import React, { useEffect, useState, useContext } from "react";

import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { IconGeneric, IconImport } from "styled/Icons.styled";
import { RiUploadCloudFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useBulkItemAggregation } from "hooks/useBulkItemAggregation";
import environment from "constants/apiConst";
import ProductCRUDContext from "context/ProductCRUDContext";
import ThirdPartiesContext from "context/ThirdPartiesContext";

export const CargaMasiva = ({ onClose, BulkLoad, endpoint, type }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const [userCa, setUserCa] = useState([]);
  const [valor, setValor] = useState([]);
  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const fileInputRef = React.useRef();
  const { update: updateProduct, setUpdate: setUpdateProduct } = useContext(ProductCRUDContext)
  const { update: updateThirdParties, setUpdate: setUpdateThirdParties } = useContext(ThirdPartiesContext)

  const updates = {
    Terceros: {
      update: updateThirdParties,
      setUpdate: setUpdateThirdParties
    },
    Productos: {
      update: updateProduct,
      setUpdate: setUpdateProduct
    },
    Unidades: {
      update: updateProduct,
      setUpdate: setUpdateProduct
    }
  }

  useEffect(() => {
    userC();
  }, []);

  const userC = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setUserCa(infoUsuarios);
    } catch (error) { }
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);

        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);

      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const Upload = () => {
    document.getElementById("display").click();
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
    fileInputRef.current.value = "";
  };

  const formData = new FormData();

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(Attachment)
    formData.append("file", Attachment);

    if (authUser.admin || authUser.is_commerce) {
      formData.append("qualifiedUser_id", valor?.value ?? "");
    }

    if ((authUser.admin || authUser.is_commerce) && !valor?.value) {
      return openAlert(`El campo de usuario calificado es necesario`, "error");
    }

    if (Attachment.length === 0) {
      return openAlert(`Seleccione un archivo`, "error");
    }

    if (valor !== "" && Attachment.length !== 0) {
      bulkAddItems()
    }
  };

  const { bulkAddItems, ErrorTable, isLoadingJobStatus: isLoadingBulkAggregation } = useBulkItemAggregation({
    userProvidedEndpoint: endpoint,
    userProvidedBody: formData,
    onClose,
    file: Attachment,
    nameFile: nameAttachment,
    update: updates[type].update,
    setUpdate: updates[type].setUpdate,
    key: endpoint?.split('/')?.[endpoint?.split('/')?.length - 1]
  })

  return (
    <>
      {authUser.admin || authUser.is_commerce
        ? (
          <InputSelect tag={"Nit"} data={userCa} set={setValor} value={valor} />
        )
        : (
          ""
        )}
      <input
        onChange={handleAttachment}
        type="file"
        id="display"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={fileInputRef}
      />
      <div onClick={Upload} className="d-flex justify-content-center my-4">
        <div id="redonder" className="d-flex justify-content-center">
          <IconImport color="#fff" as={RiUploadCloudFill} />
        </div>
      </div>
      <br />
      <br />
      <span className="text-mute">
        {nameAttachment && nameAttachment}
        {nameAttachment && (
          <IconGeneric
            onClick={() => removeItemFromArr()}
            className="m-1 text-danger"
            as={MdDelete}
          />
        )}
      </span>
      <Flex className="mb-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled isLoading={isLoadingBulkAggregation} bgColor={isLoadingBulkAggregation ? "#bababa" : "#1F7AC3"} type="submit" onClick={!isLoadingBulkAggregation ? handleSubmit : null}>
          Guardar
        </ButtonStyled>
      </Flex>
      <ErrorTable />
    </>
  );
};
