import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import environment from 'constants/apiConst'
import React, { useContext, useEffect, useState } from 'react'
import Modals from '../Modals'
import { HeaderForm } from 'components/HeaderForm/HeaderForm'
import { ButtonsBox } from '../ButtonsBox/ButtonsBox,'
import { StausBox } from '../StatusBox/StatusBox'
import Descriptions from '../Descriptions/Index'
import ViewShowCorrected from 'pages/IntegrationBox/CorrectionCertificate/ViewShowCorrected'
import { TableInfo } from '../TableInfo/TableInfo'
import { Collapse } from '@chakra-ui/react'
import FormCorrected from 'pages/IntegrationBox/Form/FormCorrected'
import FormCorrectedProduct from 'pages/IntegrationBox/Form/FormCorrectedProduct'
import FormCorrectedSupplies from 'pages/IntegrationBox/Form/FormCorrectedSupplies'
import FormCorrectedOther from 'pages/IntegrationBox/Form/FormCorrectedOther'
import FormIdOrdeProduction from 'pages/IntegrationBox/Form/FormIdOrdeProduction'
import ExporCertificated from 'pages/IntegrationBox/Export/ExportCertificado/ExporCertificated'
import { ButtonStyled } from 'styled/Buttons.styled'
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { ReturnReasonForm } from './components/ReturnReasonForm '
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import GlobalContext from 'context/GlobalContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import queryEnv from 'constants/queryConst'

export const IntegrationCertificateReview = () => {
  const [lock, setLock] = useState([])
  const [forms, setForms] = useState('')
  const [dataCorrected, setDataCorrected] = useState('')
  const [log, setLog] = useState('')
  const [logCorrected, setLogCorrected] = useState([])
  const [dataOld, setDataOld] = useState('')
  const [statusShow, setStatusShow] = useState('')
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const { id = null, idCorrected = null } = queryString.parse(location.search)
  const queryClient = useQueryClient()

  const {
    onOpenLog,
    isOpen,
    showProduct,
    showSupplies,
    showViewCorrectCost,
    form,
    setForm,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason
  } = useContext(IntegracionBoxCrudContex)
  const [openAlert] = AlertErrorAlert()
  const StatusCurrentCI = form?.status || forms?.newData?.status
  const { requestData } = useContext(GlobalContext)
  const navigate = useNavigate()
  const token = useSelector(state => state.auth.dataUser.token)

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleIntegrationResponse = res => {
    if (res?.data?.status?.code === 400) { return openAlert(res?.data?.status?.message, 'error') }

    if (res?.data?.status?.code === 200) {
      const dataWithoutCorrection = res?.data?.status?.data
      const dataOld =
        res?.data?.status?.data?.oldData?.data?.integration_certificate_id_old
      const dataWithCorrection = res?.data?.status?.data?.newData?.data
      const dataWithCorrectionLog = res?.data?.status?.data?.newData

      if (dataWithCorrection) {
        setForm(dataWithCorrection)
        setLog(dataWithCorrectionLog)
        setDataOld(dataOld)
        setStatusShow(res?.data?.status?.data?.newData[0])
      } else {
        setLog(dataWithoutCorrection)
        setForm(dataWithoutCorrection)
        setLock(res?.data?.status?.data.log)
      }
      console.log({ idCorrected, newData: res?.data?.status?.data?.newData, data: res?.data?.status?.data });

      queryClient.setQueryData(
        [queryEnv?.INTEGRATION_BOX],
        res?.data?.status?.data
      )
    }
  }

  const fetchIntegrationData = async showId => {
    const formData = new FormData()
    formData.append('id', showId)

    const Config = {
      data: formData,
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID,
      PropertyBody: 'sendFormDataContentPOST'
    }

    mutate(Config, {
      onSuccess: data => handleIntegrationResponse(data)
    })
  }

  const handleResponseCorrectedShow = res => {
    if (res?.data?.status?.code === 400) { return openAlert(res?.data?.status?.message, 'error') }

    if (res?.data?.status?.code === 200) {
      const dataWithoutCorrection = res?.data?.status?.data
      const dataCurrentCorrected = res?.data?.status?.data?.newData
      const log = res?.data?.status?.data?.newData?.log

      setForms(dataWithoutCorrection)
      setDataCorrected(dataCurrentCorrected)
      setLogCorrected(log)
    }

    setLoading(false)
  }

  const fetchCorrectedShow = async showId => {
    setLoading(true)
    const formData = new FormData()
    formData.append('id', showId)

    const Config = {
      data: formData,
      Endpoint: environment.SHOW_INTEGRACION_BOX_ID_CORRECTED,
      PropertyBody: 'sendFormDataContentPOST'
    }

    mutate(Config, {
      onSuccess: data => handleResponseCorrectedShow(data),
      onError: () => {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (id) {
      fetchIntegrationData(id)
      return
    }
    if (idCorrected) {
      fetchCorrectedShow(idCorrected)
    }
  }, [idCorrected, id])

  const handleLog = () => {
    onOpenLog()
  }

  const title = path => {
    let returnTitle
    switch (path) {
      case 'DEVUELTO':
        return (returnTitle = 'Edición de')
      default:
        returnTitle = ''
    }

    return returnTitle
  }

  const submitReviewBasedOnStatus = async () => {
    if (StatusCurrentCI === 'PRESENTADO') {
      const body = new FormData()
      body.append('form_id', idCorrected)
      body.append('type_of', 'CIC')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body) // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, 'error')
        navigate(-1)
      }
    }
  }

  useEffect(() => {
    submitReviewBasedOnStatus()
  }, [StatusCurrentCI])

  const propsBlockForm = {
    form_id: id,
    type_of: 'CIC',
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW
  }

  return (
    <>
      <Modals lock={lock} setLock={setLock} loading={loading} />
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >
        <ReturnReasonForm
          onClose={onCloseReturnReason}
          contextCurrent={IntegracionBoxCrudContex}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>
      <div className='container mt-5 '>
        {loading && <SpinnerComponent />}
        {!loading && (
          <div>
            <div className='box'>
              <HeaderForm
                status={StatusCurrentCI}
                numberForm={
                  forms
                    ? forms?.newData?.data?.integration_certificate_code
                    : form?.integration_certificate_code
                }
                UserQualified={
                  forms
                    ? forms?.newData?.data?.name_qualified
                    : form?.name_qualified
                }
                Modulo={`${title(
                  StatusCurrentCI
                )} Certificado de Integración En Zonas Franca`}
              />

              <ButtonsBox values={form} valuesCorrected={dataCorrected}>
                <StausBox
                  values={form}
                  log={log}
                  statusShow={statusShow}
                  logCorrected={logCorrected}
                />
                <Descriptions values={form} formCorrected={forms} />
              </ButtonsBox>
            </div>

            {idCorrected && idCorrected !== ''
              ? (
                <>
                  <div className='mb-4'>
                    <ViewShowCorrected form={forms} />
                  </div>
                </>
              )
              : (
                <TableInfo />
              )}

            <Collapse in={isOpen}>
              <FormCorrected />
            </Collapse>

            {showProduct && <FormCorrectedProduct dataOld={dataOld} />}
            {showSupplies && <FormCorrectedSupplies />}

            {showViewCorrectCost && <FormCorrectedOther />}
            <div className='mb-5 mt-5 '>
              {/* Aqui estan los modales */}
              <FormIdOrdeProduction Status={form?.status} />
            </div>
            <div>
              <ButtonStyled
                hg={'30px'}
                wd={'180px'}
                onClick={handleLog}
                style={{ marginBottom: '100px' }}
              >
                Ver log
              </ButtonStyled>
            </div>
          </div>
        )}

        {form && <ExporCertificated id='IdpdfCertificated' />}
      </div>
    </>
  )
}
