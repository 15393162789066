import React, { useEffect, useMemo, useState } from "react";
import { TabPanel } from "@chakra-ui/react";
import { useSelector } from "react-redux";
// import FormDocuments from "./FormsAdvancedSearch/FormDocuments";
import FormVencimientos from "./FormsAdvancedSearch/FormVecimientos";
import { __SelectPaises } from "request/configuration/_Country";
import FormAcumulados from "./FormsAdvancedSearch/FormAcumulados";
import { TabsAdvanced } from "components/Tabs-Pestañas/TabsAdvanced";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import FormItems from "./FormsAdvancedSearch/FormItems";
import FormDocumentsCorrect from "pages/Entry_and_exit_forms/CorrectionForm/busqueda avanzada/FormDocumentsCorrect";
import environment from "constants/apiConst";
import { __Get } from "request/Petitions/__Get";
import { FormDocumentsCorrectOutput } from "pages/Entry_and_exit_forms/CorrectionForm/busqueda avanzada/FormDocumentsCorrectOutput";
import FormExitVencimientos from "./FormsAdvancedSearch/FormsExitAdvanceSearch/FormExitVencimientos";
import FormExitTemporales from "./FormsAdvancedSearch/FormsExitAdvanceSearch/FormExitTemporales";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";
import { useSearchUrl } from "hooks/useSearchUrl";

const FormAdvancedSearch = ({
  onClose,
}) => {
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  const [positionView, setPositionView] = useState(0);
  const [dataCountry, setDataCountry] = useState([]);

  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    try {
      const res = await __SelectPaises(token);
      const indoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_country + " " + item?.name_country
      }));
      setDataCountry(indoCountry);
    } catch (error) { }
  };

  const handleCambio = (index) => {
    setPositionView(index);
  };

  const arrayHeadEntry = [
    "Documentos",
    "Items",
    "Vencimientos",
    "Acumulados Báscula"
  ];

  const arrayHeadExit = ["Documentos", "Items", "Temporales", "Vencimientos"];

  const { requestData } = useGlobalContext();

  const { data: dataTypeOperations } = useQuery({
    queryKey: ["dataTypeOperations"],
    queryFn: () => requestData({
      Endpoint: environment.TYPE_OPERATION_LIST,
      PropertyBody: "GET",
    }),
  })

  const memoizedTypeOperations = useMemo(() => {
    return dataTypeOperations?.data?.status?.data?.filter(item => !item?.name_typeOperation?.startsWith(movement === 'Salida' ? 'Ingreso' : 'Salida')).map((item) => ({
      value: item?.id,
      label: item?.name_typeOperation
    }))
  }, [dataTypeOperations, movement]);

  const nDefaultIndex = {
    "DOCUMENT": 0,
    "ITEM": 1,
    "EXPIRED": 2,
    "ACCUMULATED": 3,
    "TEMPORARY": 3,
  }[currentSearch?.typeSearch?.toUpperCase()] || 0

  return (
    <>
      {movement === "Ingreso"
        ? (
          <TabsAdvanced handleCambio={handleCambio} arrayHead={arrayHeadEntry} defaultIndex={nDefaultIndex}>
            <TabPanel>
              <FormDocumentsCorrect
                onClose={onClose}
                dataTypeOperation={memoizedTypeOperations}
              />
            </TabPanel>
            <TabPanel>
              <FormItems
                dataCountry={dataCountry}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>
              <FormVencimientos
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>

              <FormAcumulados
                onClose={onClose}
              />

            </TabPanel>
          </TabsAdvanced>
        )
        : (
          <TabsAdvanced handleCambio={handleCambio} arrayHead={arrayHeadExit} defaultIndex={nDefaultIndex}>
            <TabPanel>
              <FormDocumentsCorrectOutput
                onClose={onClose}
                dataTypeOperation={memoizedTypeOperations}
              />
            </TabPanel>
            <TabPanel>
              <FormItems
                dataCountry={dataCountry}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>
              <FormExitVencimientos
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>
              <FormExitTemporales
                dataCountry={dataCountry}
                onClose={onClose}
              />
            </TabPanel>
          </TabsAdvanced>
        )}
    </>
  );
};

export default FormAdvancedSearch;
