import { Box, SimpleGrid } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert';
import InputSelect from 'components/InputSelect/InputSelect';
import environment from 'constants/apiConst';
import FormCrudCRUDContext from 'context/FormCrudContex';
import GlobalContext from 'context/GlobalContext';
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex';
import ProductSearchBar from 'pages/IntegrationBox/Form/ProductSearchBar';
import { LabelGroup } from 'pages/RefProductInter/LabelGroup';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { __Post } from 'request/Petitions/__Post';
import { __SelectPaises } from 'request/configuration/_Country';
import { __Packaging_All } from 'request/configuration/__Packaging';
import { __TransportMethodNotPaginate } from 'request/configuration/__TransportMethod';
import styled from 'styled-components';
import { ButtonStyled } from 'styled/Buttons.styled';
import { Title } from 'styled/Title';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';
import { __Trm } from 'request/configuration/__Trm';
import { getFormData } from 'redux/actions/dataFormActions';
import InputSelect2 from './InputSelect2';
import { validateValue } from 'utils/type-check-utils';

export const ErrorSpan = styled.span`
  color: red;
  font-size: 12px;
  display: flex;
  margin-top: 2px;
`;

const AddIncomesForm = ({ setIsOpenAddIncomesForm, isOpenAddIncomesForm }) => {
  const { dataToEditSingleProduct } = useContext(IntegracionBoxCrudContex)
  const { decimal_values_precision, token } = useSelector((state) => state.auth.dataUser);
  const dataForm = useSelector(getFormData)
  const [dataCountry, setDataCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryDestiny_id, setCountryDestination] = useState(null);
  const [flag, setflag] = useState(null);
  const [transportMethod_id, setMethodTransport] = useState(null);
  const [dataTransportMethod, setDataTransportMethod] = useState([]);
  const { dataCountry120, setDataCountry120, setDataProcedencia, setUpdate, update } = useContext(FormCrudCRUDContext);
  const [trm, setTrm] = useState(null);
  const location = useLocation(); // navigate
  const { FormExitNumber = "" } = queryString.parse(
    location.search
  );
  const [openAlert] = AlertErrorAlert();
  const decimalScale = dataForm?.precision ?? decimal_values_precision ?? 4;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    defaultValues: {
      outputForm_id: FormExitNumber,
      code_entryForm: "",
      countryDestiny_id: "",
      flag_id: "",
      transportMethod_id: "",
      exchange_rate: trm
    }
  });

  const getCountry = async (codeOperations) => {
    setLoading(true);
    try {
      const res = await __SelectPaises(token);
      const infoCountry = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_country + " " + item?.name_country,
        origin_country: item?.origin_country,
        code: item?.code_country
      }));

      setDataCountry(infoCountry);
      if (
        codeOperations &&
        ((codeOperations >= 401 && codeOperations <= 499) ||
          (codeOperations >= 601 && codeOperations <= 699))
      ) {
        const serachIZF = infoCountry.filter(({ code }) => code === "169");
        return setDataCountry120(serachIZF);
      } else if (
        codeOperations &&
        codeOperations >= 801 &&
        codeOperations <= 899
      ) {
        const serachIZF = infoCountry.filter(({ code }) => code === "911");
        return setDataCountry120(serachIZF);
      } else {
        setDataProcedencia(null);
        setDataCountry120(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getMethodTransport = async () => {
    setLoading(true);
    try {
      const res = await __TransportMethodNotPaginate(token);
      const infoMethod = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_transportMethod + " " + item?.name_transportMethod
      }));
      setDataTransportMethod(infoMethod);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getTrm = async () => {
    setLoading(true);
    try {
      const res = await __Trm(token);
      const trmFormat = parseFloat(res?.data?.status?.data.value_trm);
      setTrm(trmFormat);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: ([url, authToken, data]) => {
      return __Post(url, authToken, data);
    },
    onSuccess: (res) => {
      if (res?.data?.status?.code === 200) {
        openAlert(res?.data?.status?.message, 'success')
        setUpdate(!update)
        onClose()
      }

      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, 'error')
      }

      if (res?.data?.status?.code === 401) {
        openAlert(res?.data?.status?.message, 'error')
      }
    },
    onError: (err) => {
      console.log(err, "error");
    }
  });

  const onSubmit = (data) => {
    if (!isLoading) {
      mutate([
        environment.SAVE_ENTRY_FORM_TO_OUTPUT_FORM,
        token,
        data
      ])
    }
  }

  const onClose = (e) => {
    e.preventDefault();
    setIsOpenAddIncomesForm(!isOpenAddIncomesForm);
    reset();
  }

  useEffect(() => {
    getCountry();
    getMethodTransport();
    getTrm();
  }, []);

  useEffect(() => {
    setValue('countryDestiny_id', countryDestiny_id?.value || '')
    setValue('flag_id', flag?.value || '')
    setValue("transportMethod_id", transportMethod_id?.value || '')
    setValue("exchange_rate", trm || '')
  }, [countryDestiny_id, flag, transportMethod_id, trm])

  return (
    <div style={{ backgroundColor: "#cfcfcf5d", padding: "10px", marginTop: "10px" }}>
      <Title>Agregar ingreso</Title>
      <form>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>

          <div>
            <InputSelect2
              tag={"País de destino"}
              size={100}
              name={"countryDestiny_id"}
              Name={"countryDestiny_id"}
              data={dataCountry120 || dataCountry}
              error={errors?.countryDestiny_id}
              set={setCountryDestination}
              value={countryDestiny_id}
              required={true}
              {...register('countryDestiny_id', {
                required: {
                  value: true,
                  message: 'País de destino es requerido'
                }
              })}
            />
            {errors?.countryDestiny_id && (<ErrorSpan>{errors?.countryDestiny_id?.message}</ErrorSpan>)}
          </div>

          <div>
            <InputSelect2
              tag={"Bandera"}
              size={100}
              name={"flag_id"}
              Name={"flag_id"}
              error={errors?.flag_id}
              data={dataCountry}
              set={setflag}
              value={flag}
              required={true}
              {...register('flag_id', {
                required: {
                  value: true,
                  message: 'Bandera es requerido'
                }
              })}
            />
            {errors?.flag_id && (<ErrorSpan>{errors?.flag_id?.message}</ErrorSpan>)}
          </div>

          <div>
            <InputSelect2
              tag={"Modo de transporte"}
              size={100}
              name={"transportMethod_id"}
              Name={"transportMethod_id"}
              error={errors?.transportMethod_id}
              data={dataTransportMethod}
              set={setMethodTransport}
              value={transportMethod_id}
              required={true}
              {...register('transportMethod_id', {
                required: {
                  value: true,
                  message: 'Modo de transporte es requerido'
                }
              })}
            />
            {errors?.transportMethod_id && (<ErrorSpan>{errors?.transportMethod_id?.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              onValueChange={(values, sourceInfo) => {
                setValue('exchange_rate', values.value);
              }}
              decimalSeparator="."
             // decimalScale={decimalScale}
              customInput={LabelGroup}
              placeholder={trm}
              tag={"Tasa de cambio"}
              size={100}
              name={"exchange_rate"}
              error={errors.exchange_rate}
              required={true}
              isAllowed={(values) => validateValue(values, 34)}
              {...register('exchange_rate', {
                required: {
                  value: true,
                  message: 'Tasa de cambio es requerido'
                }
              })}
            />
            {errors.exchange_rate && (<ErrorSpan>{errors.exchange_rate.message}</ErrorSpan>)}
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              onValueChange={(values, sourceInfo) => {
                setValue('code_entryForm', values.value);
              }}
              customInput={LabelGroup}
              tag={"Formulario de ingreso"}
              size={100}
              name={"code_entryForm"}
              error={errors.code_entryForm}
              required={true}
              {...register('code_entryForm', {
                maxLength: {
                  value: 10,
                  message: 'Este campo solo acepta 10 dígitos'
                },
                required: {
                  value: true,
                  message: 'Formulario de ingreso es requerido'
                }
              })}
            />
            {errors.code_entryForm && (<ErrorSpan>{errors.code_entryForm.message}</ErrorSpan>)}
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            Añadir
          </ButtonStyled>
        </Box>
      </form>
    </div >
  )
}

export default AddIncomesForm
