/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import TbodyCities from "../tables/TbodyCities";
import { __Cities } from "request/configuration/__Cities";
import FormCities from "../forms/FormCities";
import { FormShowCities } from "../FormShow/FormCities";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formshowCity = () => {
  return <FormShowCities />;
};

const Formcity = () => {
  return <FormCities />;
};

export const Cities = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Ciudad", order: "name_city" },
    { name: "Codigo de ciudad", order: "code_city" },
    { name: "abreviación", order: "abbreviation" },
    { name: "Departamento", order: "name_department" },
    { name: "Estado", order: "name_department" },
    { name: "Acciones", order: null }
  ];

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(Formcity);
    setFormShow(formshowCity);
    setRequestEdit(environment.CITIES_UPDATE);
    setRequestCreate(environment.CITIES_CREATE);
    setRequestDelete(environment.CITIES_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configCities"]});
  }, [update, q]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configCities", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.CITIES_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        {/* <Breadcrumbs route={routeConf} /> */}
        <ConfigurationHeader title="Ciudades"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyCities}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
