/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";
import { Regex } from "utils/Regex";

const FormTypeOfDocument = () => {
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    document: "",
    description: "",
    annexe: "",
    annexe_description: ""
  });

  const { document, description, annexe, annexe_description } = values;

  useEffect(() => {
    if (dataToEdit) {
      setValues({ ...dataToEdit });
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!document) return openAlert('Ingrese el nombre del documento', 'error');
    if (!description) return openAlert('Ingrese la descripción del documento', 'error');
    const formData = new FormData();
    formData.append("document", document);
    formData.append("description", description);
    formData.append("annexe", annexe);
    formData.append("annexe_description", annexe_description);
    formData.append("front", 0);
    if (dataToEdit) {
      formData.append("id", dataToEdit?.document_type_id);
      return editData(formData);
    }
    reset();
    return createData(formData);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Documento " : "Crear Documento"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            type={"text"}
            name={"document"}
            tag={"Documento"}
            size={45}
            value={document}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras) }
            desicion={false}
            maxLength={MaxLength.config.typesDocuments.document}
          />
          <LabelGroup
            type={"text"}
            name={"description"}
            tag={"Descripción"}
            size={45}
            value={description}
            onChange={handleInputChange}
            desicion={false}
            maxLength={MaxLength.config.typesDocuments.description}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem"
          }}
        >
          <LabelGroup
            type={"text"}
            name={"annexe"}
            tag={"Anexo"}
            size={45}
            value={annexe}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            desicion={false}
            maxLength={MaxLength.config.typesDocuments.annexe}
          />
          <LabelGroup
            type={"text"}
            name={"annexe_description"}
            tag={"Descripción de anexo"}
            size={45}
            value={annexe_description}
            onChange={handleInputChange}
            desicion={false}
            maxLength={MaxLength.config.typesDocuments.annexe_description}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit">Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormTypeOfDocument;
