import React, { useState } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Heading,
  List,
  ListItem,
  Link,
  Flex,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import Vapor from "laravel-vapor";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BiLink } from "react-icons/bi";
import {
  GrDocumentPdf,
  GrDocumentText,
  GrDocumentWindows,
  GrDocumentWord,
} from "react-icons/gr";
import { HiOutlineX } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";
import ExistingFiles from "./Adjunto/ExistingFiles";

const getFileIcon = (fileName) => {
  if (fileName.includes("word")) return GrDocumentWord;
  if (fileName.includes("pdf")) return GrDocumentPdf;
  if (fileName.includes("excel") || fileName.includes("xls"))
    return GrDocumentWindows;
  return GrDocumentText;
};

const MultipleFileUploaderWithoutModal = ({
  onUploadComplete,
  id,
  handleResponseUpdateForm = () => {},
  title = "Subir archivos",
  existingFiles = [],
  setExistingFiles,
  quickUploadMode = true,
}) => {
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [currentFileData, setCurrentFileData] = useState({
    attachment_comment: "",
    attachment_date: "",
    attachment_description: "",
    attachment_number: "",
  });
  const token = useSelector((state) => state.auth.dataUser.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const uploadMutation = useMutation({
    mutationFn: (requestData) => {
      return axios.post(requestData.Endpoint, requestData.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("Archivos subidos con éxito:", data);
      handleResponseUpdateForm(data);
      toast({
        title: "Archivos subidos",
        description: "Los archivos se han subido correctamente.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onUploadComplete();
      setFiles([]);
      setUploadProgress({});
    },
    onError: (error) => {
      console.error("Error al subir los archivos:", error);
      toast({
        title: "Error",
        description: "Hubo un problema al subir los archivos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
    if (quickUploadMode) {
      uploadFiles(acceptedFiles);
    } else if (acceptedFiles.length > 0) {
      setCurrentFileIndex(0);
      setCurrentFileData({
        attachment_comment: "",
        attachment_date: "",
        attachment_description: "",
        attachment_number: "",
      });
      onOpen();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadFiles = async (filesToUpload = files) => {
    const uploadedFiles = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      try {
        const response = await Vapor.store(file, {
          baseURL: environment.VAPOR_BASE_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          progress: (progress) => {
            setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
          },
        });

        const fileData = {
          file: { ...response },
          name: file.name,
          ...(quickUploadMode ? {} : currentFileData),
        };
        uploadedFiles.push(fileData);

        if (!quickUploadMode && i < filesToUpload.length - 1) {
          setCurrentFileIndex(i + 1);
          setCurrentFileData({
            attachment_comment: "",
            attachment_date: "",
            attachment_description: "",
            attachment_number: "",
          });
          onOpen();
          return; // Espera a que el usuario introduzca los datos para el siguiente archivo
        }
      } catch (error) {
        console.error("Error al cargar el archivo:", error);
        toast({
          title: "Error",
          description: `Hubo un problema al procesar el archivo ${file.name}.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append("id", id);
      uploadedFiles.forEach((file, index) => {
        formData.append(`attachment[${index}]`, JSON.stringify(file));
      });

      const requestData = {
        data: formData,
        Endpoint: environment.UPDATE_CUSTOMS_CLEARANCE,
        PropertyBody: "sendFormDataContentPOST",
      };

      uploadMutation.mutate(requestData);
    }
  };

  const handleInputChange = (name, value) => {
    setCurrentFileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const startUpload = () => {
    onClose();
    if (currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      setCurrentFileData({
        attachment_comment: "",
        attachment_date: "",
        attachment_description: "",
        attachment_number: "",
      });
      onOpen();
    } else {
      uploadFiles();
    }
  };

  const deleteFileMutation = useMutation({
    mutationFn: (fileId) => {
      return axios.get(`${environment.DELETE_ATTACHMENT_CUSTOMS}/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: (_, fileId) => {
      toast({
        title: "Archivo eliminado",
        description: "El archivo se ha eliminado correctamente.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setExistingFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileId)
      );
    },
    onError: (error) => {
      console.error("Error al eliminar el archivo:", error);
      toast({
        title: "Error",
        description: "Hubo un problema al eliminar el archivo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleDeleteFile = (fileId) => {
    deleteFileMutation.mutate(fileId);
  };

  return (
    <Box>
      <Heading size="md" mb={4}>
        {title}
      </Heading>
      <Box display={"grid"} gap={4}>
        <Box
          {...getRootProps()}
          p={4}
          border="2px dashed"
          borderColor={isDragActive ? "blue.500" : "gray.300"}
          borderRadius="md"
          bg={isDragActive ? "blue.50" : "gray.50"}
          _hover={{ bg: "gray.100", cursor: "pointer" }}
          transition="all 0.2s"
        >
          <input {...getInputProps()} />
          <Text textAlign="center">
            {isDragActive
              ? "Suelta los archivos aquí..."
              : "Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos"}
          </Text>
        </Box>

        <ExistingFiles
          isLoading={deleteFileMutation.isPending}
          existingFiles={existingFiles}
          handleDeleteFile={(e) => handleDeleteFile(e.id)}
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Información del archivo: {files[currentFileIndex]?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LabelGroup
              name="attachment_comment"
              value={currentFileData.attachment_comment}
              onChange={(e) =>
                handleInputChange("attachment_comment", e.target.value)
              }
              tag="Comentario"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="attachment_description"
              value={currentFileData.attachment_description}
              onChange={(e) =>
                handleInputChange("attachment_description", e.target.value)
              }
              tag="Descripción"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="attachment_number"
              value={currentFileData.attachment_number}
              onChange={(e) =>
                handleInputChange("attachment_number", e.target.value)
              }
              tag="Número del anexo"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="attachment_date"
              value={currentFileData.attachment_date}
              onChange={(e) =>
                handleInputChange("attachment_date", e.target.value)
              }
              type="date"
              tag="Fecha del anexo"
              size={100}
              required={true}
              max={new Date().toISOString().split("T")[0]}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={startUpload}
              isLoading={uploadMutation.isPending}
            >
              {currentFileIndex < files.length - 1
                ? "Siguiente"
                : "Subir archivos"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MultipleFileUploaderWithoutModal;
