import React, { useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import BlockingContext from "context/BlockingContex";

const TableBlocking = ({ data }) => {
  const { setShow, onOpenShow } = useContext(BlockingContext);

  const show = () => {
    setShow(data);
    onOpenShow();
  };

  return (
    <Tr>
      <Td className="text-center">{data?.nit_qualified}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Tooltip placement="top" hasArrow label={"Ver más"}>
        <Td className="text-center">
          <IconGeneric color={"#1F7AC3"} as={BsEyeFill} onClick={show} />
        </Td>
      </Tooltip>
    </Tr>
  );
};

export default TableBlocking;
