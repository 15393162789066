import axios from "axios";
import env from "../../constants/apiConst";

export const __GetThirdParties = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_THIRD_PARTIES}?page=${page || 1}`, options);
  return res;
};

export const __PostThirdParties = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_THIRD_PARTIES}?page=${page || 1}`, options);
  return res;
};

export const __Get_MonitoringSearch = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${env.GET_THIRD_PARTIES}?page=${page || 1}`, options);
  return res;
};

export const __thirdPartiesNotPaginate = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.THIRD_PARTY_NOT_PAGINATE}${id !== "" ? "/" : ""}${
      id !== "" ? id : ""
    }`,
    options
  );
  return res;
};

// export const __thirdPartiesNotPaginate = async (token, id) => {
//   let options = {
//     method: "GET",
//     headers: {
//       Authorization: token ? `Bearer ${token}` : "",
//     },
//   };

//   let res = await axios(`${env.THIRD_PARTY_NOT_PAGINATE}/${id}`, options);
//   return res;
// };
