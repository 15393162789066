import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useStorageQuery = (queryKey, initialData) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return undefined;
    },
    gcTime: Infinity,
    initialData: () => {
      const cachedData = localStorage.getItem(queryKey);
      if (cachedData) {
        return JSON.parse(cachedData);
      }

      return initialData;
    }
  });

  const setData = (newData) => {
    localStorage.setItem(queryKey, JSON.stringify(newData));
    queryClient.setQueryData([queryKey], newData);
  };

  return { data, setData, isLoading, isError, error };
};
