import React from "react";
import * as PropTypes from "prop-types";
import { ButtonDropdownChakra } from "./ButtonDropdown";
import { MenuItem, MenuList } from "@chakra-ui/react";
import {
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineInfoCircle,
  AiOutlineLineChart,
  AiOutlinePieChart,
  AiOutlineQuestion,
  AiOutlineTable
} from "react-icons/ai";

const ChartTypes = [
  {
    name: "line",
    title: "Lineal",
    icon: "line-chart"
  },
  {
    name: "area",
    title: "Area",
    icon: "area-chart"
  },
  {
    name: "bar",
    title: "Bar",
    icon: "bar-chart"
  },
  {
    name: "pie",
    title: "Pie",
    icon: "pie-chart"
  },
  {
    name: "table",
    title: "Tablas",
    icon: "table"
  },
  {
    name: "number",
    title: "numérico ",
    icon: "info-circle"
  }
];

export const SelectChartTypeSimple = ({ chartType, updateChartType }) => {
  const ReturnIcon = (name) => {
    switch (name) {
      case "line-chart":
        return <AiOutlineLineChart />;
      case "area-chart":
        return <AiOutlineAreaChart />;
      case "bar-chart":
        return <AiOutlineBarChart />;
      case "pie-chart":
        return <AiOutlinePieChart />;
      case "table":
        return <AiOutlineTable />;
      case "info-circle":
        return <AiOutlineInfoCircle />;
      default:
        return <AiOutlineQuestion />;
    }
  };

  const menu = (
    <MenuList>
      {ChartTypes.map((m) => (
        <>
          <MenuItem
            icon={ReturnIcon(m.icon)}
            key={m.title}
            onClick={() => updateChartType(m.name)}
          >
            {m.title}
          </MenuItem>
        </>
      ))}
    </MenuList>
  );
  const foundChartType = ChartTypes.find((t) => t.name === chartType);

  return (
    <ButtonDropdownChakra overlay={menu} icon={ReturnIcon(foundChartType.icon)}>
      {foundChartType.title}
    </ButtonDropdownChakra>
  );
};

SelectChartTypeSimple.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired
};
