/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import FormIdOrdeProduction from "pages/IntegrationBox/Form/FormIdOrdeProduction";
import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { __showBox } from "request/IntegrationBox/___IntegrationBox";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { StausBox } from "../StatusBox/StatusBox";
import { TableInfo } from "../TableInfo/TableInfo";
import queryString from "query-string";
import ButtonsBoxCheck from "../ButtonsBox/ButtonsBoxCheck";
import { ModalGeneric } from "components/modal/ModalGeneric";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import Descriptions from "../Descriptions/Index";
import { __Post } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import useStatusListener from "hooks/useStatusListener";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const CheckIntegrationBox = () => {
  const initialForm = {
    status: "",
    integration_certificate_code: "",
    name_qualified: "",
  };

  const { isOpenReturnReason, onOpenReturnReason, onCloseReturnReason } =
    useContext(IntegracionBoxCrudContex);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { id = null } = queryString.parse(location.search);
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();
  const queryClient = useQueryClient();

  const GetIntegration = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const res = await __showBox(
          environment.SHOW_INTEGRACION_BOX_ID,
          token,
          id
        );
        setForm(res?.data?.status?.data);
        queryClient.setQueryData(
          [queryEnv?.INTEGRATION_BOX],
          res?.data?.status?.data
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const dataForm = new FormData();
    dataForm.append("id", id);
    if (id) {
      GetIntegration(dataForm);
    }
  }, [GetIntegration, id]);

  const statusDataCopy = new FormData();
  statusDataCopy.append("id", id);

  const title = (path) => {
    let returnTitle;
    switch (path) {
      case "DEVUELTO":
        return (returnTitle = "Edición de");
      default:
        returnTitle = "";
    }

    return returnTitle;
  };

  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const submitReviewBasedOnStatus = async () => {
    if (is_qualified_user) return;

    if (form?.status === "PRESENTADO") {
      const body = new FormData();
      body.append("form_id", id);
      body.append("type_of", "CI");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [form]);

  const propsBlockForm = {
    form_id: id,
    type_of: "CI",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      (flag === "ci" || flag === "cic") &&
      new_status !== dataCurrent.status &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.integration_certificate_id;

    if (shouldRefetch) {
      navigate(`/ShowIntegrationBox?id=${form_id}`);
      return;
    }
  };

  useStatusListener({
    id: id,
    callBack: refetchForm,
    dataCurrent: form,
  });

  return (
    <>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={IntegracionBoxCrudContex}
          FormId={id}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <div className="container mt-5">
        {loading && <SpinnerComponent />}
        {!loading && (
          <>
            <HeaderSearch>
              <HeaderForm
                status={form?.status}
                numberForm={form?.integration_certificate_code}
                UserQualified={form?.name_qualified}
                Modulo={`${title(
                  form?.status
                )} Certificado de Integración En Zonas Francas`}
              />

              <ButtonsBoxCheck
                values={form}
                statusCI={form?.status}
                data={form}
              >
                <StausBox values={form} />
                <Descriptions values={form} />
              </ButtonsBoxCheck>
            </HeaderSearch>

            <TableInfo values={form} />

            <div className="mb-5">
              <FormIdOrdeProduction Status={form?.status} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CheckIntegrationBox;
