import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";

export const useFormBlocker = ({ form_id, type_of, userProvidedEndpoint }) => {
  const token = useSelector((state) => state.auth.dataUser.token);

  const { mutate, data } = useMutation({
    mutationFn: ([url, authToken, data]) => {
      return __Post(url, authToken, data);
    }
  });

  const blockForm = (type = 1) => {
    const body = new FormData();
    if (!form_id) return;
    body.append("form_id", form_id);
    body.append("type_of", type_of);
    body.append("type", type);
    mutate([userProvidedEndpoint, token, body]);
  };

  useEffect(() => {
    blockForm(4);
  }, []);

  const isBlocked = data?.data?.status?.code === 400;
  return { blockForm, formLockQueryResult: data?.data?.status, isBlocked };
};
