/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { AuthContext } from "context/AuthContext";
import { __ShowSectors } from "request/configuration/__Sectors";
import { useSelector } from "react-redux";

const FormSectorsShow = () => {
  const initialForm = {
    name_sector: ""
  };
  const token = useSelector((state) => state.auth.dataUser.token);
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    if (dataToShow) {
      const initialpos = async () => {
        try {
          const res = await __ShowSectors(token, dataToShow.id);
          setForm(res?.data?.status?.data);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.name_sector : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre del sector</LabeFormlStyled>
                <InputFake>{form.name_sector}</InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormSectorsShow;
