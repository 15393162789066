import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const CorrectFromUserToUser = ({
  data,
  dataCurrent,
  handleInputChangeCorrect,
  StateDisble
}) => {
  const { userQualifiedNew, StateNit, setStateNit } =
    useContext(FormCrudCRUDContext);
  const { num_exit_form_corrected } = data;
  const {  num_exit_form, qualifiedUser2_id } = dataCurrent;

  return (
    <>
      {!StateDisble ? (
        <div style={{ width: "100%" }}>
          <InputSelect
            tag={"Nit"}
            data={userQualifiedNew && userQualifiedNew}
            set={setStateNit}
            value={StateNit}
            className={qualifiedUser2_id !== StateNit?.value && "BorderCorrect"}
          />
        </div>
      ) : (
        <LabelGroup
          name={"num_exit_form_corrected"}
          value={StateNit.label}
          tag={"Nit"}
          size={100}
          onChange={handleInputChangeCorrect}
          desicion={true}
        // changeValue={
        //   num_exit_form !== num_exit_form_corrected && "BorderCorrect"
        // }
        />
      )}

      <LabelGroup
        name={"num_exit_form_corrected"}
        value={num_exit_form_corrected || ""}
        tag={"Nº Formulario de salida"}
        size={100}
        onChange={handleInputChangeCorrect}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_exit_form) !== safelyConvertToString(num_exit_form_corrected) && "BorderCorrect"
        }
      />
    </>
  );
};

/* import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage,
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus,
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment,
} from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { useState } from "react";

export const CorrectFromUserToUser = ({
  data,
  dataCurrent,
  handleInputChangeCorrect,
  StateDisble,
}) => {
  const { userQualifiedNew, StateNit, setStateNit } =
    useContext(FormCrudCRUDContext);
  const { num_exit_form_corrected } = data;
  const { name_qualified2, num_exit_form, reviews } = dataCurrent;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Status = dataCurrent.status_entryFormCorrected;

  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        //console.log("No encontrado");
      }
    } else {
      return "";
    }
  };

  const FormId = dataCurrent?.id;
  const [CurrentPos, setCurrentPos] = useState(0);
  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );

  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;

    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      console.log(name, FormId, FindElement);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comentario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalComment
              obj={GetLocalStorage(FormId)}
              Pos={CurrentPos}
              FormId={FormId}
              onClose={onClose}
              setVerifiedStatus={setVerifiedStatus}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {!StateDisble ? (
        <div style={{ width: "100%" }}>
          <InputSelect
            tag={"Nit"}
            data={userQualifiedNew && userQualifiedNew}
            set={setStateNit}
            value={StateNit}
            changeValue={name_qualified2 !== StateNit?.label && "BorderCorrect"}
          />
        </div>
      ) : dataCurrent.status_entryFormCorrected &&
        dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"num_exit_form_corrected"}
          value={StateNit.label}
          tag={"Nit"}
          placeholder={"Nit"}
          size={100}
          ColorCustom={(x) =>
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          onChange={handleInputChangeCorrect}
          desicion={false}
          readOnly={true}
          onClick={(x) => CorrectionComment(x)}
          // changeValue={
          //   num_exit_form !== num_exit_form_corrected && "BorderCorrect"
          // }
        />
      ) : (
        <LabelGroup
          name={"num_exit_form_corrected"}
          label={TooltipComentary("num_exit_form_corrected")}
          value={StateNit.label}
          tag={"Nit"}
          size={100}
          onChange={handleInputChangeCorrect}
          desicion={true}
          // changeValue={
          //   num_exit_form !== num_exit_form_corrected && "BorderCorrect"
          // }
        />
      )}

      {dataCurrent.status_entryFormCorrected &&
      dataCurrent.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"num_exit_form_corrected"}
          value={num_exit_form_corrected ? num_exit_form_corrected : ""}
          tag={"Nº Formulario de salida"}
          placeholder={"Nº Formulario de salida"}
          size={100}
          onChange={handleInputChangeCorrect}
          desicion={false}
          ColorCustom={(x) =>
            num_exit_form !== num_exit_form_corrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          readOnly={true}
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"num_exit_form_corrected"}
          value={num_exit_form_corrected ? num_exit_form_corrected : ""}
          label={TooltipComentary("num_exit_form_corrected")}
          tag={"Nº Formulario de salida"}
          size={100}
          onChange={handleInputChangeCorrect}
          desicion={StateDisble ? true : false}
          changeValue={
            num_exit_form !== num_exit_form_corrected && "BorderCorrect"
          }
        />
      )}
    </>
  );
};
 */
