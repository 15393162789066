import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { IconGeneric } from "styled/Icons.styled";

export const ConfigIconStatus = ({ onClick = null, loading, status }) => {
  return (
    <>
      {loading
        ? (
          <IconGeneric
            color={"#207AC3"}
            as={FaSpinner}
            className="loading-icon"
          />
        )
        : (
          <IconGeneric
            color={status === 1 ? "#03C39A" : "#bababa"}
            onClick={onClick}
            as={BsCheckCircleFill}
          />
        )}
    </>
  );
};
