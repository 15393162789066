import { Td, Tr } from "@chakra-ui/react";
import React from "react";

const TbodyItemsActive = ({ data }) => {
  const date = new Date(data?.date).toLocaleDateString();

  return (
    <Tr>
      <Td className="text-center">{data?.description_product}</Td>
      <Td className="text-center">{data?.quantity}</Td>
      <Td className="text-center">{date}</Td>
      <Td className="text-center">{data?.user_id}</Td>
    </Tr>
  );
};

export default TbodyItemsActive;
