import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { Button, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { Cancelar } from "pages/RefProductInter/Stilos";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { InputSelectZindex } from "components/InputSelect/InputSelect";
import { isValid } from "utils/type-check-utils";

export const FormEntrada = ({ onClose }) => {
  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();
  const [valor, setValor] = useState(null);
  const [userCa, setUserCa] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);

  const userC = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified,
      }));

      setUserCa(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    userC();
  }, [userC]);

  const closeInfo = () => {
    onClose();
    navigate("/", {
      replace: true,
    });
  };

  const initForm = async () => {
    if (!isValid(valor?.value)) {
      openAlert("Seleccione una empresa", "error");
      return;
    }

    if (isValid(valor?.value)) {
      navigate(
        `/formInt?qualifiedUser=${valor.label}&qualifiedUserID=${
          valor?.value
        }&typeO=${"Entrada"}`
      );
      onClose();
    }
  };
  return (
    <>
      {/* <Divider mt={0} /> */}
      <Text fontSize="md" mb={4} style={{ fontWeight: 600 }}>
        Formulario de Entrada
      </Text>
      <Divider my={2} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
        className="mb-5"
      >
        <div style={{ width: "100%" }}>
          <InputSelectZindex
            tag={"Usuario calificado"}
            data={userCa}
            set={setValor}
            value={valor}
          />
        </div>
      </div>

      <Flex className="my-3">
        <Cancelar onClick={closeInfo}>Cancelar</Cancelar>
        <Spacer />

        <Button
          onClick={initForm}
          bg="#03C39A"
          color="#fff"
          _hover={{ bg: "#07876b" }}
          width="40%"
          height="40px"
        >
          Formulario
        </Button>
      </Flex>
    </>
  );
};
