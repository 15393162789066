/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __TypeTransportNp } from "request/Pases/__CrudPass";

/**
 *  useTypeTransport - Hooks para obtener los tipos de transporte
 *
 * @function
 * @return {Object} Retorna objeto que dentro tiene un array con los tipos de transporte.
 */

const useTypeTransport = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [typeTransport, setTypeTransport] = useState([]);

  useEffect(() => {
    getTypeTransport();
  }, []);

  const getTypeTransport = async () => {
    try {
      const res = await __TypeTransportNp(token);
      const data = res?.data?.status?.data;
      const transport = data.map((item) => ({
        value: item?.id,
        label: item?.name_typeTransport,
        is_container: item?.is_container ?? 0
      }));
      setTypeTransport(transport);
    } catch (error) {}
  };

  return { typeTransport };
};

export default useTypeTransport;
