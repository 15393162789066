import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GET_DASHBOARD_ITEM } from "DashBoard/graphql/queries";
import { useQuery } from "@apollo/client";
import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { AlertSimple } from "DashBoard/Components/Alert/Alert";
import TitleModal from "DashBoard/Components/TitleModal";
import ExploreQueryBuilder from "DashBoard/Components/QueryBuilder/ExploreQueryBuilder";
import { ConfigMenuDashBoard } from "DashBoard/Components/menu/ConfigMenu";

const ExplorePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const itemId = params.get("itemId");
  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEM, {
    variables: {
      id: itemId
    },
    skip: !itemId
  });
  const [vizState, setVizState] = useState(null);
  const finalVizState =
    vizState ||
    (itemId && !loading && data && JSON.parse(data.dashboardItem.vizState)) ||
    {};

  const [addingToDashboard, setAddingToDashboard] = useState(false);

  const [title, setTitle] = useState(null);

  const finalTitle =
    title != null
      ? title
      : (itemId && !loading && data && data.dashboardItem.name) ||
        "Nuevo gráfico";
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <AlertSimple type="error" message={error.toString()} />;
  }
  return (
    <div>
      <ConfigMenuDashBoard />
      <TitleModal
        itemId={itemId}
        isOpen={isOpen}
        onClose={onClose}
        setAddingToDashboard={setAddingToDashboard}
        finalVizState={finalVizState}
        setTitle={setTitle}
        finalTitle={finalTitle}
      />
      <ExploreQueryBuilder
        vizState={finalVizState}
        chartExtra={[
          <Button
            key="button"
            colorScheme="blue"
            onClick={() => onOpen()}
            size="sm"
            borderRadius={5}
          >
            {itemId ? "Actualizar" : "Agregar al panel"}
          </Button>
        ]}
        onVizStateChanged={setVizState}
      />
    </div>
  );
};
export default ExplorePage;
