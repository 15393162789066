import { Td, Tr } from "@chakra-ui/react";

const TableOtherExport = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data.item_code}</Td>
      <Td className="text-center">{data?.name_cost}</Td>
      <Td className="text-center">{data?.quantity_cost}</Td>
      <Td className="text-center">{data?.net_weight_cost}</Td>
      <Td className="text-center">{data.value_cost}</Td>
      <Td className="text-center">{data?.value_unit}</Td>
    </Tr>
  );
};

export default TableOtherExport;
