import axios from "axios";
import env from "../../constants/apiConst";

export const __CreateBlocking = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);

  return res;
};

export const __GetBlocking = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.BLOCKING_GET}?page=${page || 1}`, options);
  return res;
};

export const __GetBlockTrucks = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_VETO_TRUCKS}?page=${page || 1}`, options);
  return res;
};

export const __GetBlockPerson = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_VETO_PERSONS}?page=${page || 1}`, options);
  return res;
};

export const __GetBlockPersonOrPlate = async (token, data) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_PERSON_VETO}/${data}`, options);
  return res;
};

export const __GetBlockDriver = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_VETO_DRIVER}?page=${page || 1}`, options);
  return res;
};

export const __ShowBlocking = async (token, idUser, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.BLOCKING_SHOW}/${idUser}/${id}`, options);
  return res;
};

export const __ExportDocumentBocking = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${env.BLOCKING_EXPORT}`, options);
  return res;
};

export const __SearchBlocking = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${env.BLOCKING_GET}?page=${page || 1}`, options);
  return res;
};

export const __ShowRestriccion = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.BLOCKING_SHOW_RESTRICCION}?page=${page || 1}`,
    options
  );
  return res;
};

export const __SearchBlockingAdvance = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    `${env.BLOCKING_ADVANCE_SEARCH_COMPLETE}?page=${page || 1}`,
    options
  );
  return res;
};
