/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { BiPlusCircle } from "react-icons/bi";
import OrderProductionContext from "context/OrderProductionContext";

export const CreateNewProductOrder = ({ stateTabs, currentPage }) => {
  const { onOpenTemplate, onOpenEdit } = useContext(OrderProductionContext);

  return (
    <>
      <div className="my-5">
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <div className=" d-flex justify-content-end float-end ">
              {stateTabs === 0 && currentPage?.product?.length === 0 && (
                <ButtonHeaderModule onClick={onOpenTemplate} bgColor="#007FFF">
                  <IconGeneric
                    className="me-1"
                    color="#fff"
                    as={BiPlusCircle}
                  />
                  Agregar plantilla
                </ButtonHeaderModule>
              )}
              {/* {stateTabs === 3 && currentPage?.suplies.length !== 0 && (
                // currentPage?.product?.length === 0 &&
                <ButtonHeaderModule onClick={onOpenEdit} bgColor="#007FFF">
                  <IconGeneric
                    className="mx-1"
                    color="#fff"
                    as={BiPlusCircle}
                  />
                  Agregar desperdicios
                </ButtonHeaderModule>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
