import { Tooltip } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiEdit2Fill } from "react-icons/ri";
import { AiOutlineFileDone } from "react-icons/ai";
import { GiCardDiscard } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { isArray } from "@apollo/client/utilities";
import { checkProperty, isObject } from "utils/type-check-utils";
import { useGlobalContext } from "context/GlobalContext";

export const BtnLogBorrador = ({ data, handlePresent }) => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const { pathname } = location;
  const { setshowItemsView } = useContext(FormCrudCRUDContext);

  const options = {
    "/FormCorrectionOutput": "FMMSC",
    "/FormCorrection": "FMMIC",
  };

  // /FormEntry?FormEntryNumber=${data.id}
  const handleEdit = async () => {
    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", options[pathname]);
    body.append("type", 1);

    const res = await __Post(environment.FORMS_ON_EDIT, token, body);

    if (res?.data?.status?.code === 200) {
      setshowItemsView(false);
      if (pathname === "/FormCorrectionOutput") {
        navigate(`/FormCorrectionOutput?FormOutput=${data?.id}`);
      } else {
        navigate(`/FormCorrection?FormEntry=${data?.id}`);
      }

      return;
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }
  };

  const { dataCorrected } = useGlobalContext();
  const { files = {} } = dataCorrected || {};

  const handleClickPresent = async () => {
    if (isObject(files)) {
      const fileFormat = Object.entries(files).map(([key, valor]) => {
        if (!valor) {
          return;
        }
        return (files[key] = valor);
      });

      if (isArray(fileFormat)) {
        const isValidDocument = checkProperty({
          obj: fileFormat,
          prop: "attachment_description",
          value: "Carta usuario",
          checkPropertyAndValue: true,
        });

        if (!isValidDocument) {
          return openAlert(
            "Es necesario adjuntar la carta de usuario.",
            "error"
          );
        }
      }
    }

    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", options[pathname]);
    body.append("type", 1);

    const res = await __Post(environment.FORMS_ON_EDIT, token, body);
    if (res?.data?.status?.code === 200) {
      handlePresent("PRESENTADO");

      return setTimeout(async () => {
        body.set("type", 0);
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      }, 5000);
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }
  };

  return (
    <>
      <Tooltip placement="top" hasArrow label="Editar">
        <ButtonHeaderModule onClick={handleEdit} bgColor="#1F7AC3">
          <IconGeneric className="text-white" as={RiEdit2Fill} />
        </ButtonHeaderModule>
      </Tooltip>

      <Tooltip placement="top" hasArrow label="Presentar">
        <ButtonHeaderModule
          onClick={handleClickPresent}
          className="mx-1"
          bgColor="#1F7AC3"
        >
          <IconGeneric className="text-white" as={AiOutlineFileDone} />
        </ButtonHeaderModule>
      </Tooltip>
      {authUser.is_qualified_user === 1 ? (
        ""
      ) : (
        <Tooltip placement="top" hasArrow label="Desechar">
          <ButtonHeaderModule
            //   onClick={handleDesechar}
            bgColor="#d92525"
          >
            <IconGeneric className="text-white" as={GiCardDiscard} />
          </ButtonHeaderModule>
        </Tooltip>
      )}
    </>
  );
};
