import {
  Button,
  FormControl,
  FormLabel,
  ModalFooter,
  Textarea
} from "@chakra-ui/react";
import { useState } from "react";

const ModalObservation = ({ currentCommentary, data, onClose, set }) => {
  const [inputValue, setInputValue] = useState(
    data?.[currentCommentary?.storageKey]?.[currentCommentary?.itemId]?.[
      currentCommentary?.name
    ]?.comment || ""
  );

  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <FormControl mt={4}>
        <FormLabel>{currentCommentary?.nameId || "Campo Invalido"}</FormLabel>
        <Textarea
          onChange={onChangeHandler}
          value={inputValue}
          placeholder={"Observación"}
        />
      </FormControl>

      <ModalFooter>
        <Button
          colorScheme="blue"
          onClick={() => {
            set(currentCommentary?.itemId, currentCommentary?.name, inputValue);
            onClose();
          }}
        >
          Guardar
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalObservation;
