import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import CustomsCrudContext from "context/CustomsCrudContext";

/**
 *  TbodyFormSearchRefTable - Componente que r
 * etorna el body de la tabla cuando se realiza una busqueda de referencias en el modulo
 * de desaduanameinto
 *
 * @component
 * @param {Object} data Información que se vera representada en la tabla.
 * @return {Component} Retorna componente body de una tabla.
 */

const TbodyFormSearchRefTable = ({ data, onCloseModal }) => {
  const { setItemId } = useContext(CustomsCrudContext);

  const handleSearchItem = () => {
    setItemId(data?.reference);
    onCloseModal();
  };

  return (
    <Tr onClick={handleSearchItem}>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.reference ? data?.reference : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_subheading ? data?.code_subheading : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description_product ? data?.description_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.integration_certificate ? data?.integration_certificate : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.import_declaration ? data?.import_declaration : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_operation ? data?.code_operation : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_operation_inicial ? data?.code_operation_inicial : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.name_tradeAgreement ? data?.name_tradeAgreement : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_available ? data?.quantity_available : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_reserved ? data?.quantity_reserved : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.business_unit ? data?.business_unit : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_available_product
          ? data?.quantity_available_product
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_reserved_product
          ? data?.quantity_reserved_product
          : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.business_unit_product ? data?.business_unit_product : "N/A"}
      </Td>
    </Tr>
  );
};

TbodyFormSearchRefTable.propTypes = {
  data: PropTypes.object
};

export default TbodyFormSearchRefTable;
