import AppRouter from "./routes/AppRouter";
import React from "react";
import GlobalStyle from "./globalStyles";
import { ThemeProvider } from "styled-components/macro";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import ThemeChakra from "./DashBoard/theme/theme";
import theme from "theme";
import { AuthProvider } from "context/AuthContext";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import "../src/utils/rootColors.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import { CubeProvider } from "@cubejs-client/react";
import client from "DashBoard/graphql/client";
import cubejs from "@cubejs-client/core";
import { QueryClientProvider } from "@tanstack/react-query";
import environment from "constants/apiConst";
import { GlobalContextProvider } from "context/GlobalContext";
import { NotationsProvider } from "context/NotationsContext";
import { queryClient } from "api/queryClient";
import { FormProvider } from "context/formContext";

const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzIyNjI2MTEsImV4cCI6MTY3MjM0OTAxMX0.tbRTj7SP1oE_PSZc_PDYjk2r4BOjlYKYpptpDRGfyvw";

const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: environment.CUBEJS,
});

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <ChakraProvider theme={ThemeChakra}>
                  <ApolloProvider client={client}>
                    <CubeProvider cubejsApi={cubejsApi}>
                      <NotationsProvider>
                        <FormProvider>
                          <GlobalContextProvider>
                            <AppRouter />
                            <ToastContainer
                              position="top-right"
                              autoClose={5000}
                              hideProgressBar
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss={false}
                              draggable
                              pauseOnHover
                              theme="light"
                            />
                          </GlobalContextProvider>
                        </FormProvider>
                      </NotationsProvider>
                    </CubeProvider>
                  </ApolloProvider>
                </ChakraProvider>
              </ThemeProvider>
            </AuthProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
