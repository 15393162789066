import axios from "axios";
import env from "../../constants/apiConst";
import environment from "../../constants/apiConst";

export const __EditCreateIntegrationBox = async (endpoint, token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: data || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __Get_Integration_Box = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.GET_iNTEGRATION_BOX}?page=${page || 1}`,
    options
  );
  return res;
};

export const __Get_Orden_Production = async (endpoint, token, data, signal) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: data || "",
    signal
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __OrdenNotPaginate = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ORDEN_PRODUCCION_NOT_PAGINATE}`, options);
  return res;
};

export const __OrdenNotPaginateCI = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: data || ""
  };

  const res = await axios(`${env.ORDEN_PRODUCCION_NOT_PAGINATE_CI}`, options);
  return res;
};

export const __GetOrderForms = async (token, id, endpoint) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __DeleteBox = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __showBox = async (endpoint, token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: data || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __CalculateQuatity = async (endpoint, token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: data || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __CreateDpsCertificate = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };
  const res = await axios(`${environment.EDIT_DESPERDICIOS}`, options);
  return res;
};

export const __ValidateQuantitiesInSupplies = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${environment.VALIDATE_QUANTITIES_SUPPLIES}`, options);
  return res;
};
