import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los PAISES
 * Recibe como parametro el token
 */
export const __Localitation = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GETLOCALITATION}?page=${page}`, options);
  return res;
};
/**
 * Obtener todos los departamentos
 * Recibe como parametro el token
 */
export const __Departments = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GETDEPARTMENTS}?page=${page}`, options);
  return res;
};

export const __ShowDepartments = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DEPARTMENTS_SHOW}/${id}`, options);
  return res;
};

export const __ShowCountry = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.LOCALITATION_SHOW}/${id}`, options);
  return res;
};
