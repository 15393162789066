/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {
  GET_TYPIFICATIONS,
  __GetDataReturnReason
} from "request/Pases/__CrudPass";
import TbodyTipification from "../tables/TbodyTipification";
import FormTipificacion from "../forms/FormTipificacion";
import ShowTipification from "../FormShow/ShowTipification";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const formshowDestiny = () => {
  return <ShowTipification />;
};

const FormDestinyCrud = () => {
  return <FormTipificacion />;
};

export const TipificacionMotivoDevolucion = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Nombre", order: "" },
    { name: "Descripción", order: "" },
    { name: "Acciones", order: "" }
  ];

  useEffect(() => {
    setFormEdit(FormDestinyCrud);
    setFormShow(formshowDestiny);
    setRequestEdit(environment.UPDATE_TYPIFICATIONS);
    setRequestCreate(environment.CREATE_TYPIFICATIONS);
    setRequestDelete(environment.DELETE_TYPIFICATIONS);
  }, [q, update, page]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configTipificationReasonReturn", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.GET_TYPIFICATIONS,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Tipificación"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyTipification}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
