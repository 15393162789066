import { Td, Tr } from "@chakra-ui/react";
import React from "react";

const TbodyAudit = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.product_description}</Td>
      <Td className="text-center">{data?.inventory_reference_description}</Td>
      <Td className="text-center">{data?.subheading_code}</Td>
      <Td className="text-center">
        {data?.inventory_import_declaration
          ? data?.inventory_import_declaration
          : "N/A"}
      </Td>
      <Td className="text-center">{data?.inventory_initial_operation}</Td>
      <Td className="text-center">{data?.inventory_operation_code} </Td>
      <Td className="text-center">{data?.inventory_quantity_available}</Td>
      <Td className="text-center">{data?.inventory_quantity_go_out}</Td>
      <Td className="text-center">{data?.inventory_business_unit}</Td>
      <Td className="text-center">{data?.inventory_value_cif}</Td>
      <Td className="text-center">{data?.date_authorization}</Td>
      <Td className="text-center">
        {" "}
        {data?.qualified_user_2_name_qualified
          ? data?.qualified_user_2_name_qualified
          : "N/A"}
      </Td>
    </Tr>
  );
};

export default TbodyAudit;
