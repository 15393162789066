import axios from "axios";
import environment from "constants/apiConst";

export const __ShowFormOrder = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.SHOW_PRODUCTION_ORDER}/${id}`, options);
  return res;
};

export const __CreateOrderProducts = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(
    `${environment.CREATE_OFTEMPLATE_PRODUCTION_ORDER}`,
    options
  );
  return res;
};

export const __CreateIntegrationCertificateTemplates = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(
    `${environment.CREATE_INTEGRATION_CERTIFICATE_TEMPLATE}`,
    options
  );
  return res;
};

export const __GetOrderProducts = async (token, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    }
  };
  const res = await axios(
    `${environment.GET_PRODUCTION_ORDER}?page=${page || 1}`,
    options
  );
  return res;
};

export const __GetOrderProductsSearch = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${environment.GET_PRODUCTION_ORDER_SEARCH}`, options);
  return res;
};

export const __DeleteItemOrderProducts = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${environment.DELETE_ITEM_PRODUCTION_ORDER}`, options);
  return res;
};

export const __CopyOrderProducts = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${environment.COPY_PRODUCTION_ORDER}`, options);
  return res;
};

export const __ExportDocument = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.EXPORT_DOCUMENT_PRODUCTION_ORDER}`,
    options
  );
  return res;
};

export const __CreateDps = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${environment.CREATE_DPS_ORDER}`, options);
  return res;
};
