import { Td, Tr } from "@chakra-ui/react";
import "../StyleIntegration.css";
const TbodyElaborateProductExport = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.item_code}</Td>
      <Td className="text-center">
        <span>
          {data?.description_product ? data?.description_product : "N/A"}
        </span>
      </Td>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_subheading ? data?.code_subheading : "N/A"}
      </Td>
      <Td className="text-center">{data?.quantity ? data?.quantity : "N/A"}</Td>
      <Td className="text-center">
        {data?.business_unit ? data?.business_unit : "N/A"}
      </Td>

      <Td className="text-center">
        {data?.unit_measurement ? data?.unit_measurement : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_product ? data?.quantity_product : "N/A"}
      </Td>

      <Td className="text-center">
        {data.net_weight_kilo ? data.net_weight_kilo : "N/A"}
      </Td>

      <Td className="text-center">{data.fob_value ? data.fob_value : "N/A"}</Td>
    </Tr>
  );
};

export default TbodyElaborateProductExport;
