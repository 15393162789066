/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { HrDividerNoMargin } from "styled/Line.styled";
import TreasuryCrudContex from "context/TreasuryContex";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuOther = ({ data }) => {
  const { setDataToEdit, onOpenRecharge } = useContext(TreasuryCrudContex);
  // const location = useLocation();

  // const { movement = "" } = queryString.parse(location.search);

  const handleEditRecharge = () => {
    setDataToEdit(data);
    onOpenRecharge();
  };

  // console.log(incidencia);

  return (
    <>
      <MenuItemStyled py={3} onClick={handleEditRecharge}>
        <IconMenuRUD as={RiEdit2Fill} />
        Editar
      </MenuItemStyled>

      {/* <HrDividerNoMargin />
      {movement !== "Ventas de servicios" && (
        <MenuItemStyled py={3}>
          <IconMenuRUD as={MdDelete} />
          Eliminar
        </MenuItemStyled>
      )} */}
    </>
  );
};

export default MenuOther;
