import { Radio, Td, Tr, Tooltip, useDisclosure, Button } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Attachment from "components/DispatchCorrections/Attachment";
import { ModalGeneric } from "components/modal/ModalGeneric";
import environment from "constants/apiConst";
import { useState } from "react";
import { useSelector } from "react-redux";
import { __Post, __PostForm } from "request/Petitions/__Post";
import Swal from "sweetalert2";
import "./styles.css"
import { useQueryClient } from "@tanstack/react-query";
import { uploadFile } from "pages/Entry_and_exit_forms/View/FormsView";
import { isValid } from "utils/type-check-utils";

const TBodyShowTrackingFmm = ({ data, setState }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [finalDescription, setFinalDescription] = useState("");
  const [attachmentCancel, setAttachmentCancel] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const queryClient = useQueryClient();

  const stopTracking = async (item) => {
    const formData = new FormData();
    formData?.append("tracking_id", item?.tracking_id);
    formData?.append("reference", item?.reference);
    formData?.append("item_id", item?.item_id);
    if (!item?.last_tracking) {
      const res = await __PostForm(environment.STOP_TRACKING_ON_ITEM, token, formData);
      if (res?.data?.status?.code !== 200) {
        return openAlert(res?.data?.status?.message, "error");
      }

      setState((prev) => prev?.map(item => {
        if (item?.tracking_id === res?.data?.status.data?.id) {
          return {
            ...item,
            status_item: 2
          }
        }
        return item;
      }))
      return queryClient.invalidateQueries({ queryKey: ["fetchServerPage"]});
    }
    if (!finalDescription) {
      return openAlert(
        "Para desactivar el seguimiento del formulario se requiere un comentario",
        "error"
      );
    }
    formData?.append("final_description", finalDescription);
    if (!isValid(attachmentCancel)) {
      return openAlert("Para desactivar el seguimiento del formulario se requiere un archivo", "error");
    }
    formData.append("attachment", JSON.stringify(attachmentCancel));;

    try {
      const res = await __PostForm(environment.STOP_TRACKING_ON_ITEM, token, formData);
      const message = res?.data?.status?.message;
      const code = res?.data?.status?.code;
      if (code === 200) {
        setState((prev) => prev?.map(item => {
          if (item?.tracking_id === res?.data?.status.data?.id) {
            return {
              ...item,
              status_item: 2
            }
          }
          return item;
        }))
        onClose();
        queryClient.invalidateQueries({ queryKey: [ "fetchServerPage"]});
        setAttachmentCancel(null);
        setFinalDescription("");
        return openAlert(message, "success");
      }
      openAlert(message, "error");
    } catch {
      console.log("error");
    }
  };

  const propsSwal = {
    reverseButtons: true,
    title: "¿Está seguro que desea detener el seguimiento?",
    // text: "Se registrarán todos los pases",
    icon: "warning",
    showCancelButton: true,
    cancelButtonColor: "#ba1f33",
    confirmButtonColor: "#1F7AC3",
    cancelButtonText: "Cancelar",
    confirmButtonText: "Aceptar",
    zIndex: 999999
  };

  const handleStopTracking = (data) => {
    if (data?.last_tracking) {
      return onOpen();
    }
    return Swal.fire(propsSwal).then((result) => {
      if (result.isConfirmed) {
        stopTracking(data);
      }
    });
  }

  const handleAttachment = async (e) => {
    const dataAttachments = e.target.files[0];
    const vaporRes = await uploadFile(dataAttachments, token);
    console.log("dataAttachments", vaporRes);

    setAttachmentCancel({
      file: { ...vaporRes },
      name: dataAttachments?.name,
      attachment_description: "",
      attachment_comment: "",
      attachment_date: "",
    });
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        title="Parar seguimiento"
        onClose={onClose}
        onOpen={onOpen}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
            justifyContent: "space-between",
            margin: "0 auto 2rem auto",
            padding: "10px 0"
          }}
        >
          <input
            type="file"
            name="attachment"
            accept=".png, .jpeg, .jpg, .pdf"
            style={{
              marginBottom: "15px"
            }}
            onChange={(e) => handleAttachment(e)}
          />
          <textarea
            onChange={(e) => setFinalDescription(e.target.value)}
            value={finalDescription}
            name="finalDescription"
            cols="50"
            rows="3"
            placeholder="Comentario requerido"
            style={{
              fontSize: "0.8rem",
              resize: "none",
              border: "1px solid #bababa",
              borderRadius: "5px",
              outline: "none",
              padding: "0 10px"
            }}
          ></textarea>
          <Button onClick={() => stopTracking(data)} style={{ margin: "15px 0" }}>
            Enviar
          </Button>

        </div>

      </ModalGeneric>
      <Tr>
        <Td className="text-center">{data?.follow_order ? data?.follow_order : "N/A"}</Td>
        <Td className="text-center">{data?.form_type ? data?.form_type : "N/A"}</Td>
        <Td className="text-center"> {data?.nickname ? data?.nickname : "N/A"}</Td>
        <Td className="text-center">{data?.start_date ? data?.start_date : "N/A"}</Td>
        <Td className="text-center"> {data?.end_date ? data?.end_date : "N/A"}</Td>
        <Td className="text-center">{data?.final_user_nickname ? data?.final_user_nickname : "N/A"}</Td>
        <Td className="text-center"> {data?.service ? data?.service : "N/A"}</Td>
        <Td className="text-center"> {data?.initial_description ? data?.initial_description : "N/A"}</Td>
        <Td className="text-center"> {data?.final_description ? data?.final_description : "N/A"}</Td>
        <Tooltip
          hasArrow
          placement="top"
          label={data?.status_item === 1 ? "Activo" : "Detenido"}
        >
          <Td
            className="text-center"
          >

            <Radio
              defaultChecked
              onClick={() => data?.status_item === 1
                ? handleStopTracking(data)
                : console.log(data)}
              colorScheme={data?.status_item === 1 ? 'green' : 'red'}
            >
            </Radio>
          </Td>
        </Tooltip>

      </Tr>
    </>
  );
};

export default TBodyShowTrackingFmm;
