/* eslint-disable no-unused-vars */
import { useModal } from "hooks/useModal";
// import { AuthContext } from "./AuthContext";
import { useDisclosure } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import {
  __CreateDpsCertificate,
  __EditCreateIntegrationBox
} from "request/IntegrationBox/___IntegrationBox";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { __Post, __PostForm } from "request/Petitions/__Post";
// import queryString from "query-string";
import { DeleteLocalstore } from "hooks/useLocalStorage";
import environment from "constants/apiConst";
import { __EditCreateProduct } from "request/productoInterno/__ProductoInterno";
import { certificated } from "components/Aside/ListItems";
import { __CreateIntegrationCertificateTemplates } from "request/OrderProduction/OrderProduction";

const { createContext, useState, useContext } = require("react");

const IntegracionBoxCrudContex = createContext();
/**
 * Maneja todo el crud que se hace en el componente
 * Tiene las funciones para abrir los modales y cerrarlosve
 * isOpenEdit, onOpenEdit, onCloseEdit : Maneja el modal para editar y crear
 * isOpenDelete, onOpenDelete, onCloseDelete : Maneja el modal para borrar
 * isOpenShow, onOpenShow, onCloseShow : Maneja el modal para mostrar
 * dataToEdit, setDataToEdit : Los datos para editar y para validar si se edita o crea
 * dataToDelete, setDataToDelete : Los datos para eliminar
 * dataToShow, setDataToShow : Los datos para mostrar
 * requestEdit, setRequestEdit : El endpoint para editar
 * requestDelete, setRequestDelete : El endpoint para eliminar
 * requestCreate, setRequestCreate : El endpoint para crear
 * formEdit, setFormEdit : El formulario para editar la data. para este formulario hay que crear una funcion
 */

export const IntegracionBoxCRUDProvider = ({ children }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const location = useLocation();
  const [
    isOpenDeleteSigleProduct,
    onOpenDeleteSingleProduct,
    onCloseDeleteSingleProduct
  ] = useModal();
  const [isOpenComment, onOpenComment, onCloseComment] = useModal();
  const [isOpenReturnReason, onOpenReturnReason, onCloseReturnReason] =
    useModal();

  const [ReturnReason, setReturnReason] = useState(null);
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [showCertificatedData, setshowCertificatedData] = useState(null);

  const [isOpenDeleteBox, onOpenDeleteBox, onCloseDeleteBox] = useModal();
  const [isOpenDeleteOtherCost, onOpenDeleteOtherCost, onCloseOtherCost] =
    useModal();

  const [
    isOpenDeleteOtherCostCorrected,
    onOpenDeleteOtherCostCorrected,
    onCloseOtherCostCorrected
  ] = useModal();

  const [
    isOpenDeleteSupplyCorrected,
    onOpenDeleteSupplyCorrected,
    onCloseDeleteSupplyCorrected
  ] = useModal();

  const [isOpenCopyBox, onOpenCopyBox, onCloseCopyBox] = useModal();
  const [isOpenRemove, onOpenRemove, onCloseRemove] = useModal();
  const [isOpenAnular, onOpenAnular, onCloseAnular] = useModal();
  const [isOpenAddOrder, onOpenAddOrder, onCloseAddOrder] = useModal();
  const [isOpenAddShow, onOpenAddShow, onCloseAddShow] = useModal();

  const [isOpenAddSupplies, onOpenAddSupplies, onCloseAddSupplies] = useModal();
  const [
    isOpenAddSuppliesProduct,
    onOpenAddSuppliesProduct,
    onCloseAddSuppliesProduct
  ] = useModal();
  const [isOpenAddOther, onOpenAddOther, onCloseAddOther] = useModal();
  const [isOpenSearchSupplies, onOpenSearchSupplies, onCloseSearchSupplies] =
    useModal();
  const [isOpenDeleteSupplies, onOpenDeleteSupplies, onCloseDeleteSupplies] =
    useModal();
  const [isOpenAddNewProduct, onOpenAddNewProduct, onCloseAddNewProduct] =
    useModal();

  const [isOpenAddTemplateCI, onOpenAddTemplateCI, onCloseAddTemplateCI] =
    useModal();

  const [isOpenTemplate, onOpenTemplate, onCloseTemplate] = useModal();

  const [isOpenDeleteCorrected, onOpenDeleteCorrected, onCloseDeleteCorrected] =
    useModal();
  const [
    isOpenDeleteProductCorrected,
    onOpenDeleteProductCorrected,
    onCloseDeleteProductCorrected
  ] = useModal();

  const [isOpenAddProduct, onOpenAddProduct, onCloseAddProduct] = useModal();
  const [isOpenLog, onOpenLog, onCloseLog] = useModal();
  const [isOpenBox, onOpenBox, onCloseBox] = useModal();
  const { isOpen, onToggle } = useDisclosure();
  const [dataToCopy, setDataToCopy] = useState(null);
  const [dataToAnular, setDataToAnular] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [dataToRemove, setDataToRemove] = useState(null);
  const [requestCopyBox, setRequestCopyBox] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestReviews, setRequestReviews] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null);
  const [requestCreateProduct, setRequestCreateProduct] = useState(null);
  const [requestCreateCorrection, setRequestCreateCorrection] = useState(null);
  const [requestSaveHead, setRequestSaveHead] = useState(null);
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestDeleteSupplies, setRequestDeleteSupplies] = useState(null);
  const [requestDeleteOtherCost, setRequestDeleteOtherCost] = useState(null);
  const [requestChangeStatusBox, setRequestChangeStatusBox] = useState(null);
  const [returnReviews, setReturnReviews] = useState([]);
  const [productionOrderReviews, setProductionOrderReviews] = useState(null);
  const [requestChangeStatusBoxCorrected, setRequestChangeStatusBoxCorrected] =
    useState(null);
  const [requestChangeStatusBoxPresent, setRequestChangeStatusBoxPresent] =
    useState(null);
  const [requestChangeStatusAutorized, setRequestChangeStatusAutorized] =
    useState(null);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToEditProduct, setDataToEditProduct] = useState(null);
  const [dataToEditSingleProduct, setDataToEditSingleProduct] = useState(null);
  const [orderProduccion, setOrderProduccion] = useState([]);
  const [userQualified, setUserQualified] = useState(null);
  const [destiny, setDestiny] = useState(null);
  const [deleteBox, setDeleteBox] = useState(null);
  const [netBox, setNetBox] = useState(null);
  const [ValueFobProductoElaborate, setValueFovProductoElaborate] =
    useState(null);
  const [dataToEditOrder, setDataToEditOrder] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  const [dataProductQuantity, setdataProductQuantity] = useState("");
  const [dataInsumos, setDataInsumos] = useState("");
  const [dataToSend, setDataToSend] = useState([]);
  const [dataToCal, setDataToCal] = useState([]);
  const [dataCalculos, setDataCalculos] = useState([]);
  const [dataTotalProduct, setDataTotalProduct] = useState([]);
  const [dataDiference, setDataDiference] = useState([]);
  const [dataCalculosMateriasPrimas, setDataCalculosMateriasPrimas] = useState(
    []
  );
  const [totalOtherCost, setTotalCost] = useState([]);
  const [dataProductoSingleDelete, setDataProductSingledelete] = useState(null);
  const [formEdit, setFormEdit] = useState(null);
  const [formShow, setFormShow] = useState(null);
  const [userQualifiedCorrect, setUserQualifiedCorrect] = useState(null);
  const [update, setUpdate] = useState(true);
  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();
  const [showProduct, setShowProduct] = useState(false);
  const [showSupplies, setShowSupplies] = useState(false);
  const [dataLastCertificate, setDataLastCertificate] = useState(null);
  const [dataIdOrder, setDataIdOrder] = useState(null);
  const [dataIdProduct, setDataIdProduct] = useState(null);
  const [dataDeleteCorrect, setDataDeleteCorrect] = useState(null);
  const [dataDProduct, setDataDProduct] = useState(null);
  const [dataSupplies, setDataSupplies] = useState(null);
  const [dataSuppliesP, setDataSuppliesP] = useState(null);
  const [dataSuppliesPCorrected, setDataSuppliesPCorrected] = useState(null);
  const [suppliesData, setSuppliesData] = useState(null);
  const [dataToCorrectSupplies, setDataToCorrectSupplies] = useState(null);
  const [currentPage2, setCurrentPage2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCosts, setTotalCosts] = useState("");
  const [dataReference, setDataReference] = useState(null);
  const [dataToDeleteOtherCost, setDataToDeleteOtherCost] = useState(null);
  const [dataToEditOtherCost, setDataToEditOtherCost] = useState(null);
  const [dataTodDeleteOtherCostCorrected, setDataTodDeleteOtherCostCorrected] =
    useState(null);
  const [dataToEditCorrectCost, setDataToEditCorrectCost] = useState(null);
  const [showViewCorrectCost, setShowViewCorrectCost] = useState(false);
  const [arrayOfUnit, setArrayOfUnit] = useState([]);
  const [arrayOfUnitSupply, setArrayOfUnitSupply] = useState([]);
  const [InfoUser, setInfoUser] = useState([]);
  const [isEditCiSupplies, setIsEditCiSupplies] = useState(false);
  // Posibles estados: 'showViewCorrectCost', 'showSupplies', 'showProduct', null
  const [activeView, setActiveView] = useState(null);

  const initialForm = {
    status: null,
    integration_certificate_code: null,
    name_qualified: null
  };
  const [form, setForm] = useState(initialForm);
  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */

  const createData = async (data, callBack = () => {}) => {
    try {
      const res = await __EditCreateIntegrationBox(requestCreate, token, data);
      // console.log(res.data.status);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        setUpdate(!update);
        callBack();
        openAlert(`Registro creado Exitosamente`, "success");
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editData = async (data, callBack = () => { }) => {
    try {
      const res = await __EditCreateIntegrationBox(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        callBack();
        openAlert(`Registro actualizado Exitosamente`, "success");
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDataCorrection = async (data, callBack = () => { }) => {
    try {
      const res = await __EditCreateIntegrationBox(
        requestCreateCorrection,
        token,
        data
      );
      // console.log(res.data.status);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        setUpdate(!update);
        callBack()
        openAlert(`Registro creado Exitosamente`, "success");
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteWaste = async (request, data, callback = () => { }) => {
    try {
      const res = await __PostForm(request, token, data);
      // console.log(res.data.status);
      if (res?.data?.status?.code === 200) {
        setUpdate(!update);
        openAlert(`Registro eliminado`, "success");
        callback();
      } else {
        if (res?.data?.status?.message) {
          openAlert(res?.data?.status?.message, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SaveDataHead = async (data) => {
    try {
      const res = await __EditCreateIntegrationBox(
        requestSaveHead,
        token,
        data
      );
      // console.log(res.data.status);
      if (res.data.status.code === 200) {
        setUpdate(!update);
        openAlert(`Registro guardado Exitosamente`, "success");
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async (data) => {
    try {
      const res = await __PostForm(requestDelete, token, data);
      if (res.data.status.code === 200) {
        setDataDeleteCorrect(null);
        setDataDProduct(null);
        onCloseDeleteSupplyCorrected();
        setDataSuppliesPCorrected(null);
        setDataTodDeleteOtherCostCorrected(null);
        onCloseDelete();
        onCloseOtherCostCorrected();
        openAlert(
          `Registro eliminado Exitosamente.
        Le recordamos, limpiar la información agregada de materias primas e insumos, otros gastos y costos, si es el caso. `,
          "success"
        );
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDps = async (data) => {
    try {
      const resultado = await __CreateDpsCertificate(token, data);
      if (resultado.data.status.code !== 200) {
        return openAlert(resultado.data.status.message, "error");
      } else {
        openAlert("Actualizado con exito", "success");
        return setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSupplies = async (data) => {
    try {
      const res = await __PostForm(requestDeleteSupplies, token, data);
      if (res.data.status.code === 200) {
        onCloseDeleteSupplies();
        setDataSuppliesP(null);
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteOtherCost = async (data) => {
    try {
      const res = await __PostForm(requestDeleteOtherCost, token, data);
      if (res.data.status.code === 200) {
        onCloseOtherCost();
        setDataToDeleteOtherCost(null);
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Cambiar estado
  const ChangeStatusCertified = async (data, callback = () => {}) => {
    try {
      const resp = await __Post(
        environment.POST_STATUS_INTEGRATION,
        token,
        data
      );
      if (resp.data.status.code === 200) {
        openAlert(resp?.data?.status?.message, "success");
        callback();
      } else {
        openAlert(resp?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Devolver de certificados de integración
  const ReasonReturn = async (data) => {
    try {
      const resp = await __Post(
        environment.POST_STATUS_INTEGRATION,
        token,
        data
      );
      if (resp.data.status.code === 200) {
        openAlert(resp?.data?.status?.message, "success");

        navigate(`/TabletIntegration`);
      } else {
        openAlert(resp?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ReturnComentary = async (data, keyname) => {
    try {
      const resp = await __Post(
        environment.REVIEW_RETURN_INTEGRATION,
        token,
        data
      );
      if (resp.data.status.code === 200) {
        openAlert(resp?.data?.status?.message, "success");
        DeleteLocalstore(keyname);
      } else {
        openAlert(resp?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatus = async (data) => {
    try {
      const res = await __PostForm(requestChangeStatusBox, token, data);

      if (res.data.status.code === 200) {
        const idCorrected = res.data.status.data.data?.corrected_id;
        const statusIntegrationBox = res.data.status.data.data?.status;
        const statusIntegrationBoxData = res.data.status.data?.status;
        const idCI = res?.data?.status?.data?.data?.id;

        let estadoNuevo;
        if (
          statusIntegrationBox === "BORRADOR" ||
          statusIntegrationBoxData === "BORRADOR"
        ) {
          estadoNuevo = "recuperado";
        } else if (
          statusIntegrationBox === "PRESENTADO" ||
          statusIntegrationBoxData === "PRESENTADO"
        ) {
          estadoNuevo = "presentado";
        } else if (
          statusIntegrationBox === "AUTORIZADO" ||
          statusIntegrationBoxData === "AUTORIZADO"
        ) {
          estadoNuevo = "autorizado";
        } else if (
          statusIntegrationBox === "CORREGIDO" ||
          statusIntegrationBoxData === "CORREGIDO"
        ) {
          estadoNuevo = "corregido";
        } else if (
          statusIntegrationBox === "DEVUELTO" ||
          statusIntegrationBoxData === "DEVUELTO"
        ) {
          estadoNuevo = "devuelto";
        } else if (
          statusIntegrationBox === "ANULADO" ||
          statusIntegrationBoxData === "ANULADO"
        ) {
          estadoNuevo = "anulado";
        } else {
          estadoNuevo = "desechado";
        }
        openAlert(
          `El certificado de integración fue ${estadoNuevo} Exitosamente`,
          "success"
        );
        setUpdate(!update);
        onCloseDeleteBox();

        if (
          (statusIntegrationBox === "AUTORIZADO" ||
            statusIntegrationBoxData === "AUTORIZADO") &&
          !idCorrected
        ) {
          navigate(`/TabletIntegration`);
        } else if (statusIntegrationBox === "ANULADO") {
          navigate(`/TabletIntegration`);
        } else if (idCorrected) {
          navigate(`/CorrectedCertificate?id=${idCorrected}`);
        }
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusCorrected = async (data, endpoint) => {
    try {
      const res = await __PostForm(
        endpoint || requestChangeStatusBoxCorrected,
        token,
        data
      );
      const { code } = res?.data?.status;
      if (code === 200) {
        const statusIntegrationBox = res.data?.status?.data?.status_corrected;

        let newStatus;
        if (statusIntegrationBox === "BORRADOR") {
          newStatus = "recuperado";
        } else if (statusIntegrationBox === "PRESENTADO") {
          newStatus = "presentado";
        } else if (statusIntegrationBox === "AUTORIZADO") {
          newStatus = "autorizado";
        } else if (statusIntegrationBox === "CORREGIDO") {
          newStatus = "corregido";
        } else if (statusIntegrationBox === "DEVUELTO") {
          newStatus = "devuelto";
        } else if (statusIntegrationBox === "ANULADO") {
          newStatus = "anulado";
        } else {
          newStatus = "desechado";
        }
        openAlert(
          `El certificado de integración corregido fue ${newStatus} Exitosamente`,
          "success"
        );
        setUpdate(!update);
        onCloseDeleteBox();

        if (newStatus === "devuelto") {
          navigate(`/TabletIntegrationCorrected`);
        }

        if (newStatus === "autorizado") {
          navigate(
            `/ShowIntegrationBox?id=${res.data?.status?.data?.integrationCertificateCorrected_code}`
          );
        }
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusPresent = async (
    data,
    endPoint = null,
    callBack = () => { }
  ) => {
    try {
      const res = await __PostForm(
        endPoint || requestChangeStatusBoxPresent,
        token,
        data
      );

      if (res.data.status.code === 200) {
        openAlert(
          `El certificado de integración fue presentado Exitosamente`,
          "success"
        );
        setUpdate(!update);
        onCloseDeleteBox();
        callBack();
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const changeStatusAutorized = async (data) => {
    try {
      const res = await __PostForm(requestChangeStatusAutorized, token, data);
      if (res.data.status.code === 200) {
        const data = res.data.status.data;
        openAlert(
          `El certificado de integración fue autorizado Exitosamente`,
          "success"
        );
        setUpdate(!update);
        navigate(`/ShowIntegrationBox?id=${data.id}&ButtonNot=${1}`);
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyData = async (data, endPoint = null, callBack = () => { }) => {
    try {
      const res = await __PostForm(endPoint || requestCopyBox, token, data);
      if (res?.data?.status?.code === 200) {
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
        navigate(
          `/IntegracionBox?id=${res?.data?.status?.data?.cetificate?.id}`
        );
      } else {
        if (res?.data?.status?.message) {
          openAlert(res?.data?.status?.message, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDataProduct = async (data) => {
    try {
      const res = await __EditCreateProduct(requestCreateProduct, token, data);
      // console.log(res);
      if (res.data.status.code === 200) {
        openAlert(`Se agrego el Producto ${data.code_product}`, "success");
        onCloseAddNewProduct();
        setUpdate(!update);
      } else {
        const message = res?.data?.status?.message;
        if (Array.isArray(message)) {
          message.map((alert) => {
            return openAlert(alert, "error");
          });
        }
        return openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addTemplateCI = async (informacion, reset) => {
    try {
      const res = await __CreateIntegrationCertificateTemplates(
        token,
        informacion
      );
      const data = res?.data?.status;
      console.log(data);
      if (data.code === 200) {
        openAlert(`${data.message}`, "success");
        setUpdate(!update);
        reset();
        onCloseAddTemplateCI();
      } else {
        openAlert(`${data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseReference = () => {
    if (isOpenAddSupplies) {
      onCloseAddSupplies();
      setDataReference(null);
      setSuppliesData(null);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      setDataProductSingledelete(null);
      setDataToDelete(null);
      return;
    }

    if (isOpenDeleteSigleProduct) {
      onCloseDeleteSingleProduct();
      setDataProductSingledelete(null);
      return;
    }
    if (isOpenAddShow) {
      onCloseAddShow();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
      return;
    }
    if (isOpenAddOrder) {
      onCloseAddOrder();
      setOrderProduccion([]);
      setDataToEditOrder(null);
      setIdOrder(null);
      return;
    }
    if (isOpenBox) {
      onCloseBox();
      return;
    }

    if (isOpenDeleteBox) {
      onCloseDeleteBox();
      setDeleteBox(null);

      return;
    }

    if (isOpenCopyBox) {
      onCloseCopyBox();
      setDataToCopy(null);
      return;
    }
    if (isOpenAddProduct) {
      setDataToEditSingleProduct(null);
      onCloseAddProduct();
    }
    if (isOpenLog) {
      onCloseLog();
    }
    if (isOpenAnular) {
      onCloseAnular();
      setDataToAnular(null);
    }
    if (isOpenDeleteCorrected) {
      onCloseDeleteCorrected();
      setDataDeleteCorrect(null);
    }
    if (isOpenDeleteProductCorrected) {
      onCloseDeleteProductCorrected();
      setDataDProduct(null);
    }

    if (isOpenDeleteSupplies) {
      onCloseDeleteSupplies();
      setDataSuppliesP(null);
    }

    if (isOpenTemplate) {
      onCloseTemplate();
    }
    if (isOpenAddOther) {
      onCloseAddOther();
      setDataToEditOtherCost(null);
    }
    if (isOpenSearchSupplies) {
      onCloseSearchSupplies();
    }
    if (isOpenDeleteOtherCost) {
      onCloseOtherCost();
      setDataToDeleteOtherCost(null);
    }
    if (isOpenAddSuppliesProduct) {
      onCloseAddSuppliesProduct();
    }

    if (isOpenDeleteOtherCostCorrected) {
      onCloseOtherCostCorrected();
      setDataTodDeleteOtherCostCorrected(null);
    }
    if (isOpenDeleteSupplyCorrected) {
      onCloseDeleteSupplyCorrected();
      setDataSuppliesPCorrected(null);
    }
  };

  const BulkLoad = async (endpoint, data, onCloseImport) => {
    try {
      const res = await __PostForm(endpoint, token, data);

      const respuestaAttachment = res.data.status;
      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
      } else {
        openAlert(`${respuestaAttachment.message}`, "error");
      }
      setUpdate(!update);
      onCloseImport();
    } catch (error) {
      console.log(error);
    } finally {
      onCloseImport();
    }
  };

  const data = {
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    dataToSend,
    setDataToSend,
    setDataToEdit,
    requestChangeStatusBox,
    setRequestChangeStatusBox,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    isOpenRemove,
    onOpenRemove,
    onCloseRemove,
    onOpenAddOrder,
    isOpenAddOrder,
    onCloseAddOrder,
    isOpenBox,
    onOpenBox,
    onCloseBox,
    deleteBox,
    setDeleteBox,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    dataToRemove,
    isOpenComment,
    onOpenComment,
    onCloseComment,
    ReturnReason,
    setReturnReason,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    requestCopyBox,
    isOpenCopyBox,
    onOpenCopyBox,
    onCloseCopyBox,
    copyData,
    setRequestCopyBox,
    setDataToRemove,
    dataToCopy,
    setDataToCopy,
    setDataToShow,
    userQualified,
    setUserQualified,
    destiny,
    setDestiny,
    // showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    requestSaveHead,
    setRequestSaveHead,
    SaveDataHead,
    // setDocument,
    onCloseModal,
    setRequestCreate,
    createData,
    changeStatus,
    update,
    setUpdate,
    orderProduccion,
    setOrderProduccion,
    dataProductQuantity,
    setdataProductQuantity,
    dataInsumos,
    setDataInsumos,
    isOpenDeleteBox,
    onOpenDeleteBox,
    onCloseDeleteBox,
    setRequestChangeStatusBoxPresent,
    changeStatusPresent,
    dataToCal,
    setDataToCal,
    netBox,
    setNetBox,
    dataToEditOrder,
    setDataToEditOrder,
    idOrder,
    setIdOrder,
    ValueFobProductoElaborate,
    setValueFovProductoElaborate,
    isOpenAddProduct,
    onOpenAddProduct,
    onCloseAddProduct,
    dataProductoSingleDelete,
    setDataProductSingledelete,
    isOpenDeleteSigleProduct,
    onOpenDeleteSingleProduct,
    onCloseDeleteSingleProduct,
    dataToEditSingleProduct,
    setDataToEditSingleProduct,
    dataToEditProduct,
    setDataToEditProduct,
    dataCalculos,
    setDataCalculos,
    dataCalculosMateriasPrimas,
    setDataCalculosMateriasPrimas,
    totalOtherCost,
    setTotalCost,
    isOpenLog,
    onOpenLog,
    isOpenAnular,
    onOpenAnular,
    dataToAnular,
    setDataToAnular,
    isOpen,
    onToggle,
    dataLastCertificate,
    setDataLastCertificate,
    dataIdOrder,
    setDataIdOrder,
    userQualifiedCorrect,
    setUserQualifiedCorrect,
    showProduct,
    setShowProduct,
    dataIdProduct,
    setDataIdProduct,
    isOpenDeleteCorrected,
    onOpenDeleteCorrected,
    onCloseDeleteCorrected,
    isOpenDeleteProductCorrected,
    onOpenDeleteProductCorrected,
    onCloseDeleteProductCorrected,
    dataDeleteCorrect,
    setDataDeleteCorrect,
    dataDProduct,
    setDataDProduct,
    isOpenAddSupplies,
    onOpenAddSupplies,
    dataSupplies,
    setDataSupplies,
    isOpenDeleteSupplies,
    onOpenDeleteSupplies,
    dataSuppliesP,
    setDataSuppliesP,
    suppliesData,
    setSuppliesData,
    showSupplies,
    setShowSupplies,
    dataToCorrectSupplies,
    setDataToCorrectSupplies,
    createDataCorrection,
    setRequestCreateCorrection,
    deleteSupplies,
    setRequestDeleteSupplies,
    ReasonReturn,
    ReturnComentary,
    currentPage2,
    setCurrentPage2,
    loading,
    setLoading,
    totalCosts,
    setTotalCosts,
    createDataProduct,
    addTemplateCI,
    onOpenAddNewProduct,
    isOpenAddNewProduct,
    onCloseAddNewProduct,
    isOpenAddTemplateCI,
    onOpenAddTemplateCI,
    onCloseAddTemplateCI,
    requestCreateProduct,
    setRequestCreateProduct,
    isOpenTemplate,
    onOpenTemplate,
    isOpenAddOther,
    onOpenAddOther,
    isOpenSearchSupplies,
    onOpenSearchSupplies,
    dataReference,
    setDataReference,
    onCloseAddSupplies,
    onCloseReference,
    isOpenDeleteOtherCost,
    onOpenDeleteOtherCost,
    onCloseOtherCost,
    dataToDeleteOtherCost,
    setDataToDeleteOtherCost,
    dataToEditOtherCost,
    setDataToEditOtherCost,
    deleteOtherCost,
    setRequestDeleteOtherCost,
    editData,
    dataToEditCorrectCost,
    setDataToEditCorrectCost,
    showViewCorrectCost,
    setShowViewCorrectCost,
    changeStatusAutorized,
    requestChangeStatusAutorized,
    setRequestChangeStatusAutorized,
    onCloseSearchSupplies,
    arrayOfUnit,
    setArrayOfUnit,
    arrayOfUnitSupply,
    setArrayOfUnitSupply,
    isOpenAddSuppliesProduct,
    onOpenAddSuppliesProduct,
    onCloseAddSuppliesProduct,
    createDps,
    BulkLoad,
    InfoUser,
    setInfoUser,
    form,
    setForm,
    dataTotalProduct,
    setDataTotalProduct,
    dataDiference,
    setDataDiference,
    requestChangeStatusBoxCorrected,
    setRequestChangeStatusBoxCorrected,
    changeStatusCorrected,
    dataTodDeleteOtherCostCorrected,
    setDataTodDeleteOtherCostCorrected,
    onOpenDeleteOtherCostCorrected,
    isOpenDeleteOtherCostCorrected,
    dataSuppliesPCorrected,
    setDataSuppliesPCorrected,
    isOpenDeleteSupplyCorrected,
    onOpenDeleteSupplyCorrected,
    isOpenAddShow,
    onOpenAddShow,
    onCloseAddShow,
    showCertificatedData,
    setshowCertificatedData,
    requestReviews,
    setRequestReviews,
    ChangeStatusCertified,
    returnReviews,
    setReturnReviews,
    productionOrderReviews,
    setProductionOrderReviews,
    DeleteWaste,
    isEditCiSupplies,
    setIsEditCiSupplies,
    activeView, setActiveView
  };

  return (
    <IntegracionBoxCrudContex.Provider value={data}>
      {children}
    </IntegracionBoxCrudContex.Provider>
  );
};

export default IntegracionBoxCrudContex;
