import React from "react";
import ButtonPagination, { ButtonNextPrev } from "./ButtonPagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Tooltip } from "@chakra-ui/react";
import { isString, isValid } from "utils/type-check-utils";

const Pagination = ({
  page,
  setPage,
  maxPage,
  loading,
  StoreProperty = null,
}) => {
  const generatePaginationActionButtons = () => {
    const isFirstPage = page === 1;
    const isLastPage = page === maxPage;
    const isSinglePage = maxPage === 1;

    let indexes, focus;

    if (isSinglePage) {
      indexes = [maxPage];
      focus = [true];
    } else if (isFirstPage) {
      indexes = [1, 2];
      focus = [true, false];
    } else if (isLastPage) {
      indexes = [maxPage - 1, maxPage];
      focus = [false, true];
    } else if (maxPage === 2) {
      indexes = [page - 1, page];
      focus = [true, false];
    } else {
      indexes = [page - 1, page, page + 1];
      focus = [false, true, false];
    }

    const buttons = indexes.map((index, i) =>
      index ? (
        <ButtonPagination
          key={`pmb-S${i}`}
          isFocused={focus[i]}
          onClick={() =>
            isString(StoreProperty)
              ? setPage((prev) => ({ ...prev, [StoreProperty]: index }))
              : setPage(index)
          }
        >
          {index}
        </ButtonPagination>
      ) : null
    );

    return buttons;
  };

  const renderPaginationAction = () => {
    const [first, second, third] = generatePaginationActionButtons();

    return (
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip label="Ir a la primera página">
          <span>
            <ButtonNextPrev
              onClick={() =>
                isValid(StoreProperty)
                  ? setPage((prev) => ({ ...prev, [StoreProperty]: 2 }))
                  : setPage(1)
              }
              as={MdKeyboardArrowLeft}
            />
          </span>
        </Tooltip>
        <div>
          {first} {second} {third}
        </div>
        <Tooltip label="Ir a la última página">
          <span>
            <ButtonNextPrev
              onClick={() =>
                isValid(StoreProperty)
                  ? setPage((prev) => ({ ...prev, [StoreProperty]: maxPage }))
                  : setPage(maxPage)
              }
              as={MdKeyboardArrowRight}
            />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && <div>{renderPaginationAction()}</div>}
    </>
  );
};

export default Pagination;
