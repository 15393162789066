import { ADDLocalStorage, UpdateLocalStore } from "hooks/useLocalStorage";
import { useEffect, useState } from "react";

import { HrDivider } from "styled/Line.styled";
import { TimeBox } from "../TimeBox/TimeBox";

const {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Divider
} = require("@chakra-ui/react");
export const Comparison = ({ data }) => {
  const info_docs = data ? data.info_docs : [];

  const KgFormat = (x) => {
    const pesoNetoFormatProduct = new Intl.NumberFormat("es-ES", {
      style: "unit",
      unit: "kilogram",
      minimumFractionDigits: 4
    }).format(x);

    return pesoNetoFormatProduct;
  };

  const [TransportDocumentNumber, setTransportDocumentNumber] = useState();
  const [observation, setObservation] = useState();
  /*  const TemplateInput = {
    TransportDocumentNumber: "",
    WeightKilogram: "",
  }; */

  const ListForm = data || [];
  ADDLocalStorage(ListForm.shipping_form, "DianNumber");
  /*   const [ValueCurrentInput, setValueCurrentInput] = useState(TemplateInput);

  const handleInputChange = (data) => {
    const id_name = data.target.name;
    const valueCurrent = data.target.value;

    TemplateInput[id_name] = valueCurrent;
    console.log(TemplateInput);
  };
 */

  useEffect(() => {
    info_docs.map((x) => {
      setTransportDocumentNumber(
        x.formIng.num_transport_document ? x.formIng.num_transport_document : ""
      );
      setObservation(x.formIng.observation ? x.formIng.observation : "");
    });
  }, []);

  return (
    <>
      <TimeBox data={ListForm} mb={"30px"} />

      <HrDivider bg="black" />
      <Box display={"flex"} mt="5" gap="1rem">
        <FormControl>
          <FormLabel>Numero de documento de transporte</FormLabel>
          <Input
            readOnly={true}
            placeholder="Nº Documento"
            value={TransportDocumentNumber}
          />
        </FormControl>
      </Box>

      <Box display={"flex"} flexDir={"row"} w="full">
        {info_docs.map((x) => {
          return (
            <Box w="50%" pb="3" pl="10" pr="10" pt="3">
              <FormControl>
                <FormLabel>Peso kilogramos</FormLabel>
                <Input
                  placeholder="0,00Kg"
                  readOnly={true}
                  value={
                    x.docTransp.gross_weight
                      ? KgFormat(x.docTransp.gross_weight)
                      : ""
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cantidad de Bultos</FormLabel>
                <Input
                  type="text"
                  name={"descriptions_permissionGroup"}
                  placeholder="0"
                  readOnly={true}
                  value={
                    x.docTransp.number_packages
                      ? x.docTransp.number_packages
                      : 0
                  }
                />
              </FormControl>
            </Box>
          );
        })}

        {info_docs.map((x) => {
          return (
            <Box w="50%" pb="3" pl="10" pr="10" pt="3">
              <FormControl>
                <FormLabel>Peso kilogramos</FormLabel>
                <Input
                  placeholder="0,00Kg"
                  defaultValue={
                    x.formIng.gross_weight
                      ? KgFormat(x.formIng.gross_weight)
                      : ""
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cantidad de Bultos</FormLabel>
                <Input
                  type="text"
                  name={"descriptions_permissionGroup"}
                  placeholder="0"
                  defaultValue={
                    x.formIng.number_packages ? x.formIng.number_packages : 0
                  }
                />
              </FormControl>
            </Box>
          );
        })}
      </Box>
      <Box display={"flex"} gap="1rem">
        <FormControl>
          <FormLabel>Observaciones</FormLabel>
          <Textarea
            type="text"
            name={"Observaciones"}
            placeholder="Observaciones"
            defaultValue={observation}
          />
        </FormControl>
      </Box>
      <HrDivider bg="black" marginBottom={10} marginTop={4} />
    </>
  );
};
