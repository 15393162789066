/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import {
  formExpiredSortBy,
  stateFormExitExpired,
  statePassOrDispacth,
  tidy
} from "../../../../Pass-Form/View/BusquedaAvanzada/ArraySelect";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import GlobalContext from "context/GlobalContext";
import { handleSanitizedObj, isArray } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const FormVencimientos = ({
  onClose,
}) => {
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const [values, handleInputChange, reset] = useForm({
    code_entryForm: currentSearch?.code_entryForm,
    dta: currentSearch?.dta,
    date_initial: currentSearch?.date_initial,
    date_final: currentSearch?.date_final,
  });

  const [selectAD, setSelectAD] = useState(currentSearch?.orderby_type === 'ASC' ? 'Ascendente' : 'Descendente');
  const [sortBy, setSortBy] = useState(currentSearch?.sortBy
    || movement !== "Ingreso" ? { value: "code_outputForm", label: "Num. Formulario" } : { value: "code_entryForm", label: "Num. Formulario" }
  );
  const { code_entryForm, dta, date_initial, date_final } = values;

  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(currentSearch?.stateDispacthOrPass);
  const options = ["Ascendente", "Descendente"];
  // const [descending, setDescending] = useState(null);

  const handleBusqueda = () => {  
    const newObjetData = {
      ...values,
      orderby_type: selectAD === "Ascendente" ? "ASC" : "DESC",
      ...(sortBy && sortBy?.value && { order_by: sortBy?.value }),
      ...(isArray(stateDispacthOrPass) && stateDispacthOrPass.length > 0 && { status: stateDispacthOrPass.map(({ value }) => value) }),
    };

    setParamsSearch({
      params: handleSanitizedObj({
        ...newObjetData,
        sortBy,
        stateDispacthOrPass,
        typeSearch: "EXPIRED",
      })
    });
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code_entryForm"}
          value={code_entryForm}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"dta"}
          value={dta}
          onChange={handleInputChange}
          tag={"DTA"}
          size={45}
          tipo={"text"}
          maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          value={date_initial}
          onChange={handleInputChange}
          tag={"Fecha Inic. Venc. DTA"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final}
          onChange={handleInputChange}
          tag={"Fecha Final Venc. DTA"}
          size={45}
          tipo={"date"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            name={"stateDispacthOrPass"}
            tag={"Estado"}
            data={stateFormExitExpired}
            value={stateDispacthOrPass}
            set={setStateDispacthOrPass}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"sortBy"}
            tag={"Ordenar por:"}
            data={formExpiredSortBy}
            value={sortBy}
            set={setSortBy}
          />
        </div>
      </div>
      {/* <div className="margin-search"> */}
      <RadioBtn options={options} setOnly={setSelectAD} state={selectAD} />
      {/* </div> */}
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormVencimientos;
