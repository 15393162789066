import { Td, Tr } from "@chakra-ui/react";
import React from "react";

export const TbodyAuxPrdocut = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.qualified_user_nit}</Td>
      <Td className="text-center">{data?.qualified_user_name}</Td>
      <Td className="text-center">{data?.product_code}</Td>
      <Td className="text-center">{data?.product_description}</Td>
      <Td className="text-center">{data?.subheading_code}</Td>
      <Td className="text-center">
        {/* <span className="formNumber"> */}
        {data?.product_group}
        {/* </span> */}
      </Td>
      <Td className="text-center"> {data?.business_unit_code}</Td>
      <Td className="text-center">
        {data?.inventory_quantity_available_product}
      </Td>
      <Td className="text-center">{data?.inventory_go_out_product}</Td>
      <Td className="text-center">{}</Td>
      <Td className="text-center"> {data?.inventory_total_product}</Td>
    </Tr>
  );
};
