import { useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { executeHTTPRequest, options } from "request/Forms/queryService";
import { generateEndpoint } from "request/Petitions/requestData";
// import { useSelector } from 'react-redux';

const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalContextProvider = ({ children }) => {
  // Redux
  const token = useSelector((state) => state?.auth?.dataUser?.token);
  const queryClient = useQueryClient();
  const queryUC = queryClient.getQueryData([queryEnv?.QUALIFIED_USERS]);

  //States Management for Document
  const [nameDocument, setNameDocument] = useState("");
  const [UseImageUpdate, setUseImageUpdate] = useState(false);
  const [idItemSRT, setiIdItemSRT] = useState("");
  const [numItemSRT, setNumdItemSRT] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [precisionManual, setPrecisionManual] = useState(false);


  // Dispatch Corrected States
  const [dataCorrected, setDataCorrected] = useState({});
  const [associateData, setAssociateData] = useState("");
  const [targetData, setTargetData] = useState(null);
  const [actionToBeCarriedOut, setActionToBeCarriedOut] = useState("");
  const [mainViewMode, setMainViewMode] = useState("View");
  const [Update, setUpdate] = useState("");

  // Form Entry
  const [user_Qualified, setUser_Qualified] = useState(null);
  const [code_destination, setCode_destination] = useState(null);
  const [search, setSearch] = useState("");
  const [createItemDecimal, setCreateItemDecimal] = useState(null);

  // End - Form Entry

  // Form Creation/Edit States
  const [formOperation, setFormOperation] = useState(null);
  const [multiFieldState, setMultiFieldState] = useState({});
  const [popoverState, setPopoverState] = useState(null);

  // End - Form Creation/Edit States

  // Security
  const [pendingPass, setPendingPass] = useState(null);
  // End - Security

  // Reviews Management
  const [reviewsData, setReviewsData] = useState(null);
  const [reviewsUpdateData, setReviewsUpdateData] = useState(null);
  const [updateNotationData, setUpdateNotations] = useState(null);
  const [propsManager, setPropsManager] = useState(null);
  const [IsTheFormLocked, setIsTheFormLocked] = useState(false);
  // End - Reviews Management

  // Modal Management data
  const [managerData, setManagerData] = useState({});
  // End - Modal Management data

  // AXG25 - He created the states and doesn't know what they are for.
  const [authorizationNCI, setAuthorizationNCI] = useState(null);
  const [authorizationS, setAuthorizationS] = useState(null);
  const [itemsCorrectionForm, setItemsCorrectionForm] = useState(null);

  // Edit production order
  const [pagerReferenceOP, setPageReferenceOP] = useState(1);
  const [dataProductionOrder, setDataProductionOrder] = useState({});
  const [dataToEditOP, setDataToEditOP] = useState(null);

  const {
    isOpen: isOpenCreatePreparedProduct,
    onOpen: onOpenCreatePreparedProduct,
    onClose: onCloseCreatePreparedProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateProduct,
    onOpen: onOpenCreateProduct,
    onClose: onCloseCreateProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateSupplyItemOP,
    onOpen: onOpenCreateSupplyItemOP,
    onClose: onCloseCreateSupplyItemOP,
  } = useDisclosure();

  const {
    isOpen: isOpenAddOtherCosts,
    onOpen: onOpenAddOtherCosts,
    onClose: onCloseAddOtherCosts,
  } = useDisclosure();

  // End - Edit production order
  
  // UserConfig 
  const [collectionRatesUserConfig, setCollectionRatesUserConfig] = useState(null);

  const performStatusChange = async ({
    data,
    Endpoint = null,
    PropertyBody = null,
  }) => {
    if (!PropertyBody) {
      throw new Error("PropertyBody is missing");
    }

    const conf = options?.[PropertyBody](token, data);
    const res = await executeHTTPRequest(Endpoint, conf);
    return res;
  };

  /**
   * Función para administrar datos a través de una solicitud HTTP.
   *
   * @async
   * @param {Object} options - Opciones para la gestión de datos.
   * @param {number|null} options.pageParam - Parámetro de página para la paginación.
   * @param {string|null} options.resourceIdentifier - Identificador del recurso.
   * @param {Object} options.data - Datos para enviar en la solicitud.
   * @param {string|null} options.Endpoint - URL del punto final de la API.
   * @param {string|null} options.PropertyBody - Nombre de la propiedad para obtener la configuración de la solicitud.
   * @returns {Promise<Object>} - Respuesta de la solicitud HTTP.
   * @throws {Error} Si PropertyBody no está definido.
   */

  const requestData = async ({
    pageParam = null,
    resourceIdentifier = null,
    data,
    Endpoint = null,
    PropertyBody = "get",
    method = PropertyBody,
    page = pageParam,
  }) => {
    if (!PropertyBody) {
      throw new Error("PropertyBody is missing");
    }

    const endpointURL = generateEndpoint(
      Endpoint,
      resourceIdentifier,
      page
    );

    const conf = options?.[method.toUpperCase()](token, data);
    const res = await executeHTTPRequest(endpointURL, conf);

    return res;
  };

  const data = {
    UseImageUpdate,
    setUseImageUpdate,
    idItemSRT,
    setiIdItemSRT,
    isOpen,
    setIsOpen,
    numItemSRT,
    setNumdItemSRT,
    associateData,
    setAssociateData,
    targetData,
    setTargetData,
    actionToBeCarriedOut,
    setActionToBeCarriedOut,
    mainViewMode,
    setMainViewMode,
    performStatusChange,
    Update,
    setUpdate,
    requestData,
    user_Qualified,
    setUser_Qualified,
    code_destination,
    setCode_destination,
    pendingPass,
    setPendingPass,
    search,
    setSearch,
    formOperation,
    setFormOperation,
    reviewsData,
    setReviewsData,
    reviewsUpdateData,
    setReviewsUpdateData,
    propsManager,
    setPropsManager,
    updateNotationData,
    setUpdateNotations,
    multiFieldState,
    setMultiFieldState,
    popoverState,
    setPopoverState,
    managerData,
    setManagerData,
    IsTheFormLocked,
    setIsTheFormLocked,
    authorizationNCI,
    setAuthorizationNCI,
    authorizationS,
    setAuthorizationS,
    itemsCorrectionForm,
    setItemsCorrectionForm,
    nameDocument,
    setNameDocument,
    queryUC,
    createItemDecimal,
    setCreateItemDecimal,
    dataCorrected,
    setDataCorrected,
    collectionRatesUserConfig,
    setCollectionRatesUserConfig,
    pagerReferenceOP,
    setPageReferenceOP,
    dataProductionOrder,
    setDataProductionOrder,
    isOpenCreatePreparedProduct,
    onOpenCreatePreparedProduct,
    onCloseCreatePreparedProduct,
    isOpenCreateSupplyItemOP,
    onOpenCreateSupplyItemOP,
    onCloseCreateSupplyItemOP,
    isOpenAddOtherCosts,
    onOpenAddOtherCosts,
    onCloseAddOtherCosts,
    dataToEditOP,
    setDataToEditOP,
    isOpenCreateProduct,
    onOpenCreateProduct,
    onCloseCreateProduct,
    precisionManual, setPrecisionManual
  };
  return (
    <GlobalContext.Provider value={data}>{children}</GlobalContext.Provider>
  );
};

export default GlobalContext;
