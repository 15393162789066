import styled from "styled-components/macro";

export const MainStyled = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 60px;
  /* position: absolute; */
  /* top: 50px; */
  /* left: 282px; */
  /* align-items: center; */
  max-width: 100%;
  background-color: #fff !important;
`;
export const Contaianers = styled.div`
  height: 100vh !important;
  width: 100vw;
  max-width: 100%;
`;
export const ContaianeFalse = styled.div`
  height: ${(props) => props.het};
  /* height: 100% !important; */
  max-width: calc(100% - 270px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 270px;
  right: 0;
`;
