/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ButtonItemsForm } from "../stylePases";
import queryString from "query-string";
import PassCrudContext from "context/PassCrudContext";

export const FormEntryItem = ({ onClose, loading, handleSearch }) => {
  const location = useLocation(); // navigate
  const { id = "" } = queryString.parse(location.search);
  const { idQualifiedUser } = useContext(PassCrudContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    qualifiedUser_id: idQualifiedUser,
    entryPass_id: id,
    code: "",
    date_start: "",
    date_end: ""
  });

  const { code, date_start, date_end } = values;

  return (
    <>
      {loading && <SpinnerComponent />}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"code"}
          onChange={handleInputChange}
          value={code}
          tag={"Ingreso"}
          size={45}
          desicion={false}
          maxLength={10}
        />
        <LabelGroup
          name={"date_start"}
          onChange={handleInputChange}
          value={date_start}
          tag={"Fecha Inicio"}
          size={45}
          desicion={false}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LabelGroup
          name={"date_end"}
          onChange={handleInputChange}
          value={date_end}
          tag={"Fecha Final"}
          size={45}
          desicion={false}
          tipo={"date"}
        />
      </div>
      <Flex mt={6}>
        <ButtonItemsForm color="grisaso" onClick={onClose}>
          Cancelar
        </ButtonItemsForm>
        <Spacer />
        <ButtonItemsForm color="aqua" onClick={() => handleSearch(values)}>
          Buscar
        </ButtonItemsForm>
      </Flex>
    </>
  );
};
