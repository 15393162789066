import styled from "styled-components/macro";

export const MinCard = styled.div`
  width: 100%;
  height: 56px;
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
  }
`;

export const MinCardStadistica = styled.div`
  width: 100%;
  height: 100%;
   max-height: 287px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
  }
`;

export const MinCardMediun = styled.div`
  width: 100%;
  height: 50%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
  }
`;

export const MinCardInput = styled.div`
  width: 100%;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
  }
`;

export const MinCardMximu = styled.div`
  padding: 5px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
  }
`;
