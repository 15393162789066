import React from "react";
import { Title } from "styled/Title";

/**
 *  TitleOperationOutput - Componente de accordion que despliega una serie de items en el componente del Aside
 *
 * @component
 * @param {Number} code Operacion del formulario.
 * @return {Component} Retorna cadena.
 */

export const TitleOperationOutput = ({ code }) => {
  if (code === 50) {
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        Al resto del territorio nacional
      </Title>
    );
  } else if (code >= 201 && code <= 226) {
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        Al resto del mundo
      </Title>
    );
  } else if (code >= 401 && code <= 450) {
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        Al resto del territorio nacional
      </Title>
    );
  } else if (code >= 601 && code <= 618) {
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        A zona franca
      </Title>
    );
  } else if (code >= 801 && code <= 814) {
    return (
      <Title size={"18px"} weight={700} className="mb-2">
        De Usuario a Usuario de la Misma Zona Franca
      </Title>
    );
  } else {
    return <div></div>;
  }
};
