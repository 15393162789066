import { Icon, Td, Tr } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import MenuFollowOrder from "components/Menu/MenuFollowOrder";
import MonitoringContext from "context/MonitoringContext";
import { useContext } from "react";
import { AiOutlineComment } from "react-icons/ai";
import { useSelector } from "react-redux";
import { __GetComment } from "request/Monitoring/__Get_Operations";

const TBodyFollowOrder = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const { setGetFollowO, onOpenViewComment, setDataTableComment } = useContext(
    MonitoringContext
  );

  const getComment = async () => {
    try {
      const formData = new FormData();
      formData.append('follow_order_id', data?.id);
      const res = await __GetComment(token, formData);
      setDataTableComment(res?.data?.status?.data);
      const message = res?.data?.status?.message;
      const code = res?.data?.status?.code;
      if (code === 200) {
        setGetFollowO(true);
        openAlert(message, "success");
      } else if (code !== 200) {
        openAlert('Ha ocurrido un error', "error");
      }
    } catch (error) {
    }
  }

  const getDataTableComment = () => {
    getComment();
    onOpenViewComment();
  }

  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.follow_order ? data?.follow_order : "N/A"}</Td>
        <Td className="text-center"> {data?.description ? data?.description : "N/A"}</Td>
        <Td className="text-center">
          {data?.messages?.length
            ? (
              <button onClick={getDataTableComment} style={{ paddingRight: "5px" }}>
                <Icon boxSize={'1.5em'} color='#1f7ac3' as={AiOutlineComment} />
              </button>
            )
            : ''}
        </Td>
        <Td className="text-center">
          <MenuFollowOrder data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TBodyFollowOrder;
