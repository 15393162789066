import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearFormField, updateFormField } from "redux/actions/useFormAction";
import { parseFloat } from "utils/parseFloat";
import {
  isArray,
  isNonEmptyString,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";

export const RETURN_TYPES = {
  ARRAY: "array",
  OBJECT: "object",
};

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const validateAndConvertValue = (value, typeInput = "string") => {
  const validators = {
    number: (v) => {
      if (isNumber(v)) return { valid: true, value: v };
      if (isString(v) && v.trim() !== "") {
        const num = parseFloat(v);
        if (!isNaN(num)) return { valid: true, value: num };
      }
      if (!isNonEmptyString(v)) return { valid: true, value: "" };
      return {
        valid: false,
        devError: `[DEV] Invalid number: Expected a number or a string that can be converted to a valid number, received: ${typeof v}`,
        userError: "Por favor, ingrese un número válido.",
      };
    },
    array: (v) =>
      isArray(v)
        ? { valid: true, value: v }
        : {
            valid: false,
            devError: `[DEV] Invalid array: Expected an array, received: ${typeof v}`,
            userError: "Formato de datos inválido.",
          },
    object: (v) =>
      isObject(v) && !Array.isArray(v)
        ? { valid: true, value: v }
        : {
            valid: false,
            devError: `[DEV] Invalid object: Expected an object, received: ${typeof v}`,
            userError: "Formato de datos inválido.",
          },
    string: (v) => {
      if (isString(v)) return { valid: true, value: v };
      if (!isValid(v)) return { valid: true, value: "" };
      return {
        valid: true,
        value: String(v),
        devWarning: `[DEV] Warning: Non-string value coerced to string: ${typeof v}`,
      };
    },
  };

  const validator = validators[typeInput] || validators.string;
  const result = validator(value);

  if (!result.valid) {
    console.error(result.devError);
    return { value: null, error: result.userError, devError: result.devError };
  }

  if (result.devWarning) {
    console.warn(result.devWarning);
  }

  return { value: result.value, error: null, devWarning: result.devWarning };
};

/**
 * @typedef {Object} UseFormBetaOptions
 * @property {Object} [initialState={}] - Estado inicial del formulario.
 * @property {Object} [onValidate={}] - Funciones de validación para cada campo.
 * @property {Function} [onFieldChange=()=>{}] - Función llamada cuando cambia un campo.
 * @property {boolean} [formStateHandler=false] - Si es true, usa Redux para manejar el estado del formulario.
 * @property {string} [formKey=""] - Clave para identificar el formulario en Redux.
 * @property {('object'|'array')} [returnType="object"] - Tipo de retorno del hook.
 * @property {number} [debounceTime=100] - Tiempo de espera para la validación debounced.
 * @property {Object} [customTransformers={}] - Transformadores personalizados para los valores de los campos.
 */

/**
 * Hook personalizado para manejar formularios con validación y estado.
 * @param {UseFormBetaOptions} options - Opciones de configuración para el hook.
 * @returns {Object|Array} - Objeto o array con las propiedades y métodos del formulario.
 */

export const useFormBeta = (options) => {
  const {
    initialState = {},
    onValidate = {},
    onFieldChange = () => {},
    formStateHandler = false,
    formKey = "",
    returnType = "object",
    debounceTime = 100,
    customTransformers = {},
  } = options;

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [interacted, setInteracted] = useState({}); // Renombrado de 'touched'
  const [modified, setModified] = useState({}); // Renombrado de 'dirty'
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const dispatch = useDispatch();
  const { form: useFormRedux } = useSelector((state) => state);
  const firstRender = useRef(true);

  const validateField = useCallback(
    async (field, value) => {
      if (onValidate[field]) {
        const result = onValidate[field](value, values);
        return result instanceof Promise ? await result : result;
      }
      return null;
    },
    [onValidate, values]
  );

  const validateForm = useCallback(async () => {
    let newErrors = {};
    for (const field of Object.keys(values)) {
      const fieldError = await validateField(field, values[field]);
      if (fieldError) {
        newErrors[field] = fieldError;
      }
    }
    return newErrors;
  }, [values, validateField]);

  useEffect(() => {
    if (!firstRender.current) {
      const valueStorage = useFormRedux?.[formKey];
      if (isObject(valueStorage) && isObjectNotEmpty(valueStorage)) {
        setValues(valueStorage);
      }
    } else {
      firstRender.current = false;
    }
  }, [formKey, useFormRedux]);

  const debouncedValidate = useMemo(
    () =>
      debounce(async (name, value) => {
        const fieldError = await validateField(name, value);
        setErrors((prev) => ({ ...prev, [name]: fieldError }));
      }, debounceTime),
    [validateField, debounceTime]
  );

  /**
   * Maneja los cambios en los campos del formulario.
   * @param {Event} e - Evento del cambio.
   */
  const handleInputChange = useCallback(
    (e) => {
      const { typeInput } = e.target?.dataset || {};
      const { name, value } = e.target;

      const {
        value: convertedValue,
        error,
        devError,
        devWarning,
      } = validateAndConvertValue(value, typeInput);

      if (error) {
        setErrors((prev) => ({ ...prev, [name]: error }));
        if (devError) {
          console.error(devError);
        }
        e.target.value = values[name];
        return;
      }

      if (devWarning) {
        console.warn(devWarning);
      }

      const transformedValue = customTransformers[name]
        ? customTransformers[name](convertedValue, values)
        : convertedValue;

      setValues((prev) => ({ ...prev, [name]: transformedValue }));
      setModified((prev) => ({ ...prev, [name]: true }));
      setCurrentField(name);
      setInteracted((prev) => ({ ...prev, [name]: true }));

      if (formStateHandler) {
        dispatch(updateFormField(formKey, name, transformedValue));
      }

      onFieldChange(name, transformedValue, e.target, { typeInput });

      debouncedValidate(name, transformedValue);
    },
    [
      formStateHandler,
      formKey,
      onFieldChange,
      dispatch,
      customTransformers,
      values,
      debouncedValidate,
    ]
  );

  /**
   * Maneja el envío del formulario.
   * @param {Function} onSubmit - Función a ejecutar si la validación es exitosa.
   */
  const handleSubmit = useCallback(
    async (onSubmit) => {
      setIsSubmitting(true);
      const newErrors = await validateForm();
      setErrors(newErrors);
      setInteracted(
        Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      );
      if (Object.keys(newErrors).length === 0) {
        await onSubmit(values);
      }
      setIsSubmitting(false);
    },
    [validateForm, values]
  );

  /**
   * Reinicia el formulario a su estado inicial.
   */
  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setInteracted({});
    setModified({});
    setIsSubmitting(false);
    setCurrentField("");
  }, [initialState]);

  /**
   * Limpia el estado del formulario en Redux.
   */
  const clearReduxState = useCallback(() => {
    if (formStateHandler) {
      dispatch(clearFormField(formKey));
    }
  }, [formStateHandler, dispatch, formKey]);

  /**
   * Valores del formulario sin campos vacíos.
   */
  const sanitizedValues = useMemo(
    () =>
      Object.entries(values).reduce((acc, [key, value]) => {
        if (value != null && value !== "") acc[key] = value;
        return acc;
      }, {}),
    [values]
  );

  /**
   * Establece el valor de un campo específico.
   * @param {string} name - Nombre del campo.
   * @param {*} value - Nuevo valor del campo.
   */
  const setFieldValue = useCallback(
    (name, value) => {
      setValues((prev) => ({ ...prev, [name]: value }));
      setModified((prev) => ({ ...prev, [name]: true }));
      setCurrentField(name);
      setInteracted((prev) => ({ ...prev, [name]: true }));
      if (formStateHandler) {
        dispatch(updateFormField(formKey, name, value));
      }
      debouncedValidate(name, value);
    },
    [formStateHandler, formKey, dispatch, debouncedValidate]
  );

  /**
   * Verifica si el formulario es válido.
   * @returns {boolean} - True si el formulario es válido, false en caso contrario.
   */
  const isFormValid = useCallback(
    () => Object.keys(errors).length === 0,
    [errors]
  );

  /**
   * Verifica si todos los campos requeridos han sido completados.
   * @param {string[]} requiredFields - Array con los nombres de los campos requeridos.
   * @returns {boolean} - True si todos los campos requeridos están completos, false en caso contrario.
   */
  const areRequiredFieldsCompleted = useCallback(
    (requiredFields) => requiredFields.every((field) => !!values[field]),
    [values]
  );

  const returnValue = {
    values,
    handleInputChange,
    reset,
    setValues,
    setFieldValue,
    errors,
    setErrors,
    interacted,
    modified,
    isSubmitting,
    clearReduxState,
    currentField,
    sanitizedValues,
    handleSubmit,
    validateForm,
    isFormValid,
    areRequiredFieldsCompleted,
  };

  return returnType === "object" ? returnValue : Object.values(returnValue);
};
