export const ValidatePresenFmm = (data, status) => {
/*   if (
    status === "PRESENTADO" &&
    (data.code_operation === 321 ||
      data.code_operation === 324 ||
      data.code_operation === 327) &&
    (data.num_commercial_invoice === "" ||
      data.num_commercial_invoice === null ||
      data.num_commercial_invoice === undefined ||
      data.num_dex_entryForm === "" ||
      data.num_dex_entryForm === null ||
      data.num_dex_entryForm === undefined)
  ) {
    return "El ingreso debe soportarse con un DEX o Factura";
  } */

  if (
    status === "PRESENTADO" &&
    data.code_operation >= 300 &&
    data.code_operation <= 400 &&
    data.code_operation !== 321 &&
    data.code_operation !== 324 &&
    data.code_operation !== 327 &&
    data.code_operation !== 310 &&
    data.code_operation !== 309 &&
    (data.num_commercial_invoice === "" ||
      data.num_commercial_invoice === null ||
      data.num_commercial_invoice === undefined) &&
    (data.num_import_declaration === "" ||
      data.num_import_declaration === null ||
      data.num_import_declaration === undefined)
  ) {
    return "Debe digitar la declaración o la Factura comercial";
  }

  if (
    status === "PRESENTADO" &&
    data.code_operation >= 300 &&
    data.code_operation <= 400 &&
    (data.num_dex_entryForm !== "" ||
      data.num_dex_entryForm !== null ||
      data.num_dex_entryForm !== 0 ||
      data.num_dex_entryForm !== undefined) &&
    (data.name_department === "" ||
      data.name_department === null ||
      data.name_department === 0 ||
      data.name_department === undefined) &&
    data.national_or_foreign === "Nacional"
  ) {
    return "Debe digitar el departamento si digita DEX";
  }
};
