/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { LabelGroup, SwitchesGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { __ShowTypeTransp } from "request/configuration/__TypeTranport";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";

export const FormTypeTransport = () => {
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [status, setStatus] = useState(1);
  const [typeContainer, setTypeContainer] = useState();

  const [values, handleInputChange, reset, setValues] = useForm({
    name_typeTransport: "",
    description_typeTransport: ""
  });
  const { name_typeTransport, description_typeTransport } = values;

  useEffect(() => {
    if (dataToEdit) {
      const initialpos = async () => {
        try {
          const res = await __ShowTypeTransp(token, dataToEdit.id);
          setValues(res?.data?.status?.data);
          setStatus(res?.data?.status?.data.status_typeTransport);
          setTypeContainer(res?.data?.status?.data?.is_container);
        } catch (error) { }
      };
      initialpos();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (name_typeTransport === "") {
      openAlert("El nombre es requerido", "error");
    } else {
      if (dataToEdit) {
        const data = {
          ...values,
          status_typeTransport: status,
          is_container: typeContainer
        };
        editData(data);
      } else {
        const data = {
          ...values,
          status_typeTransport: status,
          is_container: typeContainer
        }
        createData(data);
      }
    }
  };

  const handleRegex = ({ target }, regex) => {
    const { value } = target;
    if (regex.test(value)) {
      handleInputChange({ target });
    } else {
      openAlert(`El carácter ${value[value.length - 1]} no es permitido en este campo`, 'error')
    }
  }

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar tipo de transporte" : "Crear tipo de transporte"}
      </Text>
      <Divider mb={3} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name="name_typeTransport"
          tag={"Nombre tipo transporte"}
          value={name_typeTransport}
          onChange={(e) => handleRegex(e, /^[a-zA-ZÀ-ÿ\s-.,)(]*$/g)}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeTrans.name_typeTransport}
        />

        <LabelGroup
          name="description_typeTransport"
          tag={"Descripción tipo transporte"}
          value={description_typeTransport}
          onChange={handleInputChange}
          size={45}
          desicion={false}
          maxLength={MaxLength.config.typeTrans.description_typeTransport}
        />
      </div>

      <SimpleGrid minChildWidth="50px" spacing="20px" className="mt-5">
        <div
          style={
            {
              // flexDirection: "column",
              // display: "flex",
              // marginBottom: "2rem",
              // backgroundColor: "red",
              // alignSelf: "end",
            }
          }
        ></div>
        {dataToEdit && (
          <SwitchesGroup
            status={status}
            setStatus={setStatus}
            tag={"¿Activo?"}
            size={45}
          />
        )}

        <div style={{ marginLeft: "2rem", justifyContent: "start" }}>
          <SwitchesGroup
            status={typeContainer}
            setStatus={setTypeContainer}
            tag={"Tipo contenedor"}
            size={45}
          />
        </div>

      </SimpleGrid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
      </Box>
    </>
  );
};
