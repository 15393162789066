import { Checkbox, Flex, Spacer, useModal } from '@chakra-ui/react';
import { AuthContext } from 'context/AuthContext';
import { useForm } from 'hooks/useForm';
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { __AllQualifiedUSers } from 'request/configuration/__QualifiedUsers';
import { ButtonStyled } from 'styled/Buttons.styled';
import InputSelect, { InputSelectMulti } from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

const ExportStatistics = ({
  onClose,
  NodeList,
  handleSubmit,
  dafaulActive = [],
  exportFilters
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [userQualified, setUserQuialified] = useState([]);
  const [selectUserQualified, setSelectUserQualified] = useState(null);
  const [enableQualified, setEnableQualified] = useState(false);
  const [enableDate, setEnableDate] = useState(false);
  const [typeExcel, setTypeExcel] = useState(null);

  const [fechasHoy, setfechasHoy] = useState(null);

  const [values, handleInputChange, reset, setValues] = useForm({});
  const [form, handleInputChangeForm, resetForm, setForm] = useForm({
    date_start: "",
    date_end: ""
  });

  const { date_start, date_end } = form;

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));
      setUserQuialified(infoUsuarios);
    } catch (error) { }
  };

  useEffect(() => {
    if (dafaulActive) {
      let objeto = {};
      dafaulActive.forEach((items) => (objeto = { ...objeto, [items]: items }));
      setValues((prev) => ({ ...prev, ...objeto }));
    }
  }, [dafaulActive]);

  const handlePreview = () => {
    const data = {
      type: typeExcel?.label || "xlsx",
      date_start,
      date_end,
      qualifiedUser_id: selectUserQualified?.map(item => item.value) || []
    }
    handleSubmit(data, values);
  };

  const selectVar = (e) => {
    const valor = e.target.value;
    setValues({ ...values, [e.target.id]: valor });
  }

  useEffect(() => {
    getUserQualified();
    const hoy_fecha = new Date().toISOString().substring(0, 10);
    setfechasHoy(hoy_fecha);
  }, []);

  return (
    <div className='container'>
      <ul className="row noStyle">
        {NodeList && NodeList?.map((item, index) => (
          <div className="col-lg-6">
            <li key={index}>
              <Checkbox
                id={item?.name}
                value={item?.value}
                className="ms-1 mb-2 check-box"
                onChange={selectVar}
                defaultChecked={
                  dafaulActive &&
                  dafaulActive.find((element) =>
                    element === item?.value
                  )
                }
              >
                <span className="name-label">{item?.label}</span>
              </Checkbox>
            </li>
          </div>
        ))}
        {
          exportFilters?.qualified_users && (
            <div className="col-lg-6">
              <li key="qualifiedUser">
                <Checkbox
                  value={enableQualified}
                  className="ms-1 mb-2 check-box"
                  onChange={() => setEnableQualified(!enableQualified)}
                  defaultChecked={
                    enableQualified
                  }
                >
                  <span className="name-label">
                    {"Usuario calificado"}
                  </span>
                </Checkbox>
              </li>
            </div>

          )
        }

        {
          exportFilters?.date && (
            <div className="col-lg-12">
              <li key="enableDate">
                <Checkbox
                  value={enableDate}
                  className="ms-1 mb-2 check-box"
                  onChange={() => setEnableDate(!enableDate)}
                  defaultChecked={
                    enableDate
                  }
                >
                  <span className="name-label">
                    {"Filtro de fecha"}
                  </span>
                </Checkbox>
              </li>
            </div>
          )
        }
        <li className="col-lg-6 py-2" key="documentType">
          <InputSelect
            tag={"Tipo de documento"}
            data={[{ value: "1", label: "xlsx" }, { value: "2", label: "csv" }]}
            set={setTypeExcel}
            value={typeExcel}
          />
        </li>
        {enableQualified &&
          <li className="col-lg-12 py-2" key="qualified_user">
            <InputSelectMulti
              tag={"Usuario calificado"}
              data={userQualified}
              set={setSelectUserQualified}
              value={selectUserQualified}
            />
          </li>}

        {enableDate &&
          <React.Fragment>
            <div className="col-lg-6 py-2" key="date_start">
              <React.Fragment>
                <LabelGroup
                  id={"date_start"}
                  name={"date_start"}
                  value={date_start}
                  onChange={handleInputChangeForm}
                  tag={"Fecha inicial*"}
                  tipo="date"
                  max={fechasHoy}
                />
              </React.Fragment>
            </div>
            <div className="col-lg-6 py-2" key="date_end">
              <React.Fragment>
                <LabelGroup
                  id={"date_end"}
                  name={"date_end"}
                  tipo="date"
                  value={date_end}
                  onChange={handleInputChangeForm}
                  tag={"Fecha final*"}
                  max={fechasHoy}
                />
              </React.Fragment>
            </div>
          </React.Fragment>
        }

      </ul>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handlePreview()}
        >
          Exportar
        </ButtonStyled>
      </Flex>
    </div>
  )
}

export default ExportStatistics
