import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const CorrectionRestWorld = ({
  data,
  code,
  handleInputChangeCorrect,
  dataCurrent,
  StateDisble
}) => {
  const { typeEntry, setTypeEntry, dataType, typeEntryCurrent } =
    useContext(FormCrudCRUDContext);
  const {
    dcl_customs_transit_corrected,
    transit_expiration_date_corrected,
    num_transport_document_corrected,
    transp_doc_date_corrected,
    cargo_manifest_entryFormCorrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    integration_box_certificate_corrected,
    shipping_form_corrected,
    cabotage_corrected,
    shipping_form_expiration_date_corrected,
    cabotage_expiration_date_corrected
  } = data;
  const {
    dcl_customs_transit,
    transit_expiration_date,
    num_transport_document,
    transp_doc_date,
    cargo_manifest_entryForm,
    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    shipping_form,
    cabotage,
    shipping_form_expiration_date,
    cabotage_expiration_date
  } = dataCurrent;

  /* Metadata for "'Planilla de envío', 'Dcl. Tránsito aduanero' or Cabotaje" */
  const handleColorDtaShippingCabotage = () => {
    if (
      (typeEntry === "DTA" && safelyConvertToString(dcl_customs_transit) !== safelyConvertToString(dcl_customs_transit_corrected)) ||
      (typeEntry === "Planilla de envío" && safelyConvertToString(shipping_form) !== safelyConvertToString(shipping_form_corrected)) ||
      (typeEntry === "Cabotaje" && safelyConvertToString(cabotage) !== safelyConvertToString(cabotage_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaDtaShippingCabotage = {
    DTA: {
      name: "dcl_customs_transit_corrected",
      value: dcl_customs_transit_corrected || "",
      tag: "Dcl. Tránsito aduanero"
    },
    "Planilla de envío": {
      name: "shipping_form_corrected",
      value: shipping_form_corrected || "",
      tag: "Planilla de envío"
    },
    Cabotaje: {
      name: "cabotage_corrected",
      value: cabotage_corrected || "",
      tag: "Cabotaje"
    }
  }[typeEntry || ""]

  /* Metadata for 'Fecha de vencimiento' depending on typeEntry */

  const handleColorExpirationDate = () => {
    if (
      (typeEntry === "DTA" && safelyConvertToString(transit_expiration_date) !== safelyConvertToString(transit_expiration_date_corrected)) ||
      (typeEntry === "Planilla de envío" && safelyConvertToString(shipping_form_expiration_date) !== safelyConvertToString(shipping_form_expiration_date_corrected)) ||
      (typeEntry === "Cabotaje" && safelyConvertToString(cabotage_expiration_date) !== safelyConvertToString(cabotage_expiration_date_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaExpirationDate = {
    DTA: {
      name: "transit_expiration_date_corrected",
      value: transit_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    },
    "Planilla de envío": {
      name: "shipping_form_expiration_date_corrected",
      value: shipping_form_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    },
    Cabotaje: {
      name: "cabotage_expiration_date_corrected",
      value: cabotage_expiration_date_corrected || "",
      tag: `Fecha de vencimiento ${typeEntry}`
    }
  }[typeEntry || ""]

  return (
    <>
      <RadioCheck
        set={setTypeEntry}
        value={typeEntry}
        item={dataType}
        diff={typeEntryCurrent !== typeEntry}
        disables={!!StateDisble}
      />

      <LabelGroup
        name={
          metaDtaShippingCabotage?.name
        }
        value={
          metaDtaShippingCabotage?.value
        }
        tag={
          metaDtaShippingCabotage?.tag
        }
        size={100}
        desicion={!!StateDisble}
        onChange={handleInputChangeCorrect}
        changeValue={
          handleColorDtaShippingCabotage()
        }
      />

      <LabelGroup
        name={
          metaExpirationDate?.name
        }
        value={
          metaExpirationDate?.value
        }
        tag={
          metaExpirationDate?.tag
        }
        size={100}
        desicion={!!StateDisble}
        onChange={handleInputChangeCorrect}
        tipo={"date"}
        changeValue={
          handleColorExpirationDate()
        }
      />

      <LabelGroup
        name={"num_transport_document_corrected"}
        value={
          num_transport_document_corrected || ""
        }
        tag={"Nº Documento Transporte"}
        onChange={handleInputChangeCorrect}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(num_transport_document) !== safelyConvertToString(num_transport_document_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"transp_doc_date_corrected"}
        value={transp_doc_date_corrected || ""}
        onChange={handleInputChangeCorrect}
        tag={"Fecha Documento Transporte"}
        size={100}
        tipo={"date"}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(transp_doc_date) !== safelyConvertToString(transp_doc_date_corrected) && "BorderCorrect"
        }
      />
      <LabelGroup
        name={"cargo_manifest_entryFormCorrected"}
        value={
          cargo_manifest_entryFormCorrected || ""
        }
        onChange={handleInputChangeCorrect}
        tag={"Manifiesto de carga"}
        size={100}
        desicion={!!StateDisble}
        changeValue={
          safelyConvertToString(cargo_manifest_entryForm) !== safelyConvertToString(cargo_manifest_entryFormCorrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"num_commercial_invoice_corrected"}
        value={
          num_commercial_invoice_corrected || ""
        }
        tag={"Nº Factura comercial"}
        onChange={handleInputChangeCorrect}
        desicion={!!StateDisble}
        size={100}
        changeValue={
          safelyConvertToString(num_commercial_invoice) !== safelyConvertToString(num_commercial_invoice_corrected) &&
          "BorderCorrect"
        }
      />

      {code === 108 || code === 107
        ? (
          <>
            <LabelGroup
              name={"num_exit_form_corrected"}
              value={
                num_exit_form_corrected || ""
              }
              onChange={handleInputChangeCorrect}
              desicion={!!StateDisble}
              tag={"Nº Autorización de salida"}
              size={100}
              changeValue={
                safelyConvertToString(num_exit_form) !==
              safelyConvertToString(num_exit_form_corrected) && "BorderCorrect"
              }
            />
            <LabelGroup
              name={"integration_box_certificate_corrected"}
              onChange={handleInputChangeCorrect}
              value={
                integration_box_certificate_corrected || ""
              }
              tag={"Nº Autorización de C. inte"}
              size={100}
              desicion={!!StateDisble}
              changeValue={
                safelyConvertToString(integration_box_certificate) !==
              safelyConvertToString(integration_box_certificate_corrected) && "BorderCorrect"
              }
            />
          </>
        )
        : (
          ""
        )}
    </>
  );
};

/* import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage,
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus,
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment,
} from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { useState } from "react";

export const CorrectionRestWorld = ({
  data,
  code,
  handleInputChangeCorrect,
  dataCurrent,
  StateDisble,
}) => {
  const { typeEntry, setTypeEntry, dataType, typeEntryCurrent } =
    useContext(FormCrudCRUDContext);
  const {
    dcl_customs_transit_corrected,
    transit_expiration_date_corrected,
    num_transport_document_corrected,
    transp_doc_date_corrected,
    cargo_manifest_entryFormCorrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    integration_box_certificate_corrected,
    shipping_form_corrected,
    cabotage_corrected,
    shipping_form_expiration_date_corrected,
    cabotage_expiration_date_corrected,
  } = data;
  const {
    dcl_customs_transit,
    transit_expiration_date,
    num_transport_document,
    transp_doc_date,
    cargo_manifest_entryForm,
    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    shipping_form,
    cabotage,
    shipping_form_expiration_date,
    cabotage_expiration_date,
    reviews,
  } = dataCurrent;

  const Status = dataCurrent.status_entryFormCorrected;

  const FormId = dataCurrent.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [CurrentPos, setCurrentPos] = useState(0);
  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );

  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        //console.log("No encontrado");
      }
    } else {
      return "";
    }
  };

  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;

    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      console.log(name, FormId, FindElement);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  };

  return (
    <>
      <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Comentario</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ModalComment
                obj={GetLocalStorage(FormId)}
                Pos={CurrentPos}
                FormId={FormId}
                onClose={onClose}
                setVerifiedStatus={setVerifiedStatus}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
      <RadioCheck
        set={setTypeEntry}
        value={typeEntry}
        item={dataType}
        diff={typeEntryCurrent !== typeEntry}
        disables={StateDisble ? true : false}
      />

      {typeEntry !== "Planilla de envío" && (
        <>
          {data.status_entryFormCorrected &&
          data.status_entryFormCorrected === "PRESENTADO" ? (
            <LabelGroupComment
              name={
                typeEntry === "DTA"
                  ? "dcl_customs_transit_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_corrected"
                  : "N/A"
              }
              placeholder={
                typeEntry === "DTA"
                  ? "Dcl. Tránsito aduanero"
                  : typeEntry === "Cabotaje"
                  ? typeEntry
                  : "N/A"
              }
              value={
                typeEntry === "DTA"
                  ? dcl_customs_transit_corrected
                  : typeEntry === "Cabotaje"
                  ? cabotage_corrected
                  : "N/A"
              }
              tag={
                typeEntry === "DTA"
                  ? "Dcl. Tránsito aduanero"
                  : typeEntry === "Cabotaje"
                  ? typeEntry
                  : "N/A"
              }
              size={100}
              desicion={false}
              onChange={handleInputChangeCorrect}
              ColorCustom={(x) =>
                (typeEntry === "DTA" &&
                  dcl_customs_transit !== dcl_customs_transit_corrected) ||
                (typeEntry === "Cabotaje" &&
                  cabotage !== cabotage_corrected &&
                  Colorcurrent(x, FormId) === "gray")
                  ? "purple"
                  : Colorcurrent(x, FormId)
              }
              onClick={(x) => CorrectionComment(x)}
            />
          ) : (
            <LabelGroup
              name={
                typeEntry === "DTA"
                  ? "dcl_customs_transit_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_corrected"
                  : "N/A"
              }
              value={
                typeEntry === "DTA"
                  ? dcl_customs_transit_corrected
                  : typeEntry === "Cabotaje"
                  ? cabotage_corrected
                  : "N/A"
              }
              tag={
                typeEntry === "DTA"
                  ? "Dcl. Tránsito aduanero"
                  : typeEntry === "Cabotaje"
                  ? typeEntry
                  : "N/A"
              }
              size={100}
              label={TooltipComentary(
                typeEntry === "DTA"
                  ? "dcl_customs_transit_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_corrected"
                  : "N/A"
              )}
              desicion={StateDisble ? true : false}
              onChange={handleInputChangeCorrect}
              changeValue={
                typeEntry === "DTA" &&
                dcl_customs_transit !== dcl_customs_transit_corrected
                  ? "BorderCorrect"
                  : typeEntry === "Cabotaje" && cabotage !== cabotage_corrected
                  ? "BorderCorrect"
                  : "N/A"
              }
            />
          )}

          {data.status_entryFormCorrected &&
          data.status_entryFormCorrected === "PRESENTADO" ? (
            <LabelGroupComment
              name={
                typeEntry === "DTA"
                  ? "transit_expiration_date_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_expiration_date_corrected"
                  : "N/A"
              }
              value={
                typeEntry === "DTA"
                  ? transit_expiration_date_corrected
                  : typeEntry === "Cabotaje"
                  ? cabotage_expiration_date_corrected
                  : "N/A"
              }
              tag={
                typeEntry === "DTA"
                  ? "Fecha de vencimiento Tránsito"
                  : typeEntry === "Cabotaje"
                  ? `Fecha de vencimiento ${typeEntry}`
                  : "N/A"
              }
              placeholder={
                typeEntry === "DTA"
                  ? "Fecha de vencimiento Tránsito"
                  : typeEntry === "Cabotaje"
                  ? `Fecha de vencimiento ${typeEntry}`
                  : "N/A"
              }
              size={100}
              desicion={false}
              onChange={handleInputChangeCorrect}
              tipo={"date"}
              readOnly={true}
              ColorCustom={(x) =>
                (typeEntry === "DTA" &&
                  transit_expiration_date !==
                    transit_expiration_date_corrected) ||
                (typeEntry === "Cabotaje" &&
                  cabotage_expiration_date !==
                    cabotage_expiration_date_corrected &&
                  Colorcurrent(x, FormId) === "gray")
                  ? "purple"
                  : Colorcurrent(x, FormId)
              }
              onClick={(x) => CorrectionComment(x)}
            />
          ) : (
            <LabelGroup
              label={TooltipComentary(
                typeEntry === "DTA"
                  ? "transit_expiration_date_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_expiration_date_corrected"
                  : "N/A"
              )}
              name={
                typeEntry === "DTA"
                  ? "transit_expiration_date_corrected"
                  : typeEntry === "Cabotaje"
                  ? "cabotage_expiration_date_corrected"
                  : "N/A"
              }
              value={
                typeEntry === "DTA"
                  ? transit_expiration_date_corrected
                  : typeEntry === "Cabotaje"
                  ? cabotage_expiration_date_corrected
                  : "N/A"
              }
              tag={
                typeEntry === "DTA"
                  ? "Fecha de vencimiento Tránsito"
                  : typeEntry === "Cabotaje"
                  ? `Fecha de vencimiento ${typeEntry}`
                  : "N/A"
              }
              size={100}
              desicion={StateDisble ? true : false}
              onChange={handleInputChangeCorrect}
              tipo={"date"}
              changeValue={
                typeEntry === "DTA" &&
                transit_expiration_date !== transit_expiration_date_corrected
                  ? "BorderCorrect"
                  : typeEntry === "Cabotaje" &&
                    cabotage_expiration_date !==
                      cabotage_expiration_date_corrected
                  ? "BorderCorrect"
                  : "N/A"
              }
            />
          )}
        </>
      )}

      {data.status_entryFormCorrected &&
      data.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"num_transport_document_corrected"}
          value={
            num_transport_document_corrected
              ? num_transport_document_corrected
              : "N/A"
          }
          tag={"Nº Documento Transporte"}
          placeholder={"Nº Documento Transporte"}
          onChange={handleInputChangeCorrect}
          size={100}
          desicion={false}
          ColorCustom={(x) =>
            num_transport_document !== num_transport_document_corrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"num_transport_document_corrected"}
          value={
            num_transport_document_corrected
              ? num_transport_document_corrected
              : "N/A"
          }
          label={TooltipComentary("num_transport_document_corrected")}
          tag={"Nº Documento Transporte"}
          onChange={handleInputChangeCorrect}
          size={100}
          desicion={StateDisble ? true : false}
          changeValue={
            num_transport_document !== num_transport_document_corrected &&
            "BorderCorrect"
          }
        />
      )}

      {data.status_entryFormCorrected &&
      data.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"transp_doc_date_corrected"}
          value={transp_doc_date_corrected ? transp_doc_date_corrected : "N/A"}
          onChange={handleInputChangeCorrect}
          tag={"Fecha Documento Transporte"}
          placeholder={"Fecha Documento Transporte"}
          size={100}
          tipo={"date"}
          desicion={false}
          readOnly={true}
          ColorCustom={(x) =>
            transp_doc_date !== transp_doc_date_corrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"transp_doc_date_corrected"}
          value={transp_doc_date_corrected ? transp_doc_date_corrected : "N/A"}
          onChange={handleInputChangeCorrect}
          tag={"Fecha Documento Transporte"}
          label={TooltipComentary("transp_doc_date_corrected")}
          size={100}
          tipo={"date"}
          desicion={StateDisble ? true : false}
          changeValue={
            transp_doc_date !== transp_doc_date_corrected && "BorderCorrect"
          }
        />
      )}

      {data.status_entryFormCorrected &&
      data.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"cargo_manifest_entryFormCorrected"}
          value={
            cargo_manifest_entryFormCorrected
              ? cargo_manifest_entryFormCorrected
              : "N/A"
          }
          onChange={handleInputChangeCorrect}
          tag={"Manifiesto de carga"}
          placeholder={"Manifiesto de carga"}
          size={100}
          desicion={false}
          ColorCustom={(x) =>
            cargo_manifest_entryForm !== cargo_manifest_entryFormCorrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"cargo_manifest_entryFormCorrected"}
          value={
            cargo_manifest_entryFormCorrected
              ? cargo_manifest_entryFormCorrected
              : "N/A"
          }
          label={TooltipComentary("cargo_manifest_entryFormCorrected")}
          onChange={handleInputChangeCorrect}
          tag={"Manifiesto de carga"}
          size={100}
          desicion={StateDisble ? true : false}
          changeValue={
            cargo_manifest_entryForm !== cargo_manifest_entryFormCorrected &&
            "BorderCorrect"
          }
        />
      )}

      {data.status_entryFormCorrected &&
      data.status_entryFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"num_commercial_invoice_corrected"}
          value={
            num_commercial_invoice_corrected
              ? num_commercial_invoice_corrected
              : "N/A"
          }
          tag={"Nº Factura comercial"}
          placeholder={"Nº Factura comercial"}
          onChange={handleInputChangeCorrect}
          desicion={false}
          size={100}
          ColorCustom={(x) =>
            num_commercial_invoice !== num_commercial_invoice_corrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
        />
      ) : (
        <LabelGroup
          name={"num_commercial_invoice_corrected"}
          value={
            num_commercial_invoice_corrected
              ? num_commercial_invoice_corrected
              : "N/A"
          }
          label={TooltipComentary("num_commercial_invoice_corrected")}
          tag={"Nº Factura comercial"}
          onChange={handleInputChangeCorrect}
          desicion={StateDisble ? true : false}
          size={100}
          changeValue={
            num_commercial_invoice !== num_commercial_invoice_corrected &&
            "BorderCorrect"
          }
        />
      )}

      {code === 108 || code === 107 ? (
        <>
          <LabelGroup
            name={"num_exit_form_corrected"}
            value={
              num_exit_form_corrected
                ? num_exit_form_corrected
                : "N/A"
            }
            onChange={handleInputChangeCorrect}
            desicion={StateDisble ? true : false}
            tag={"Nº Autorización de salida"}
            size={100}
            changeValue={
              num_exit_form !==
                num_exit_form_corrected && "BorderCorrect"
            }
          />
          <LabelGroup
            name={"integration_box_certificate_corrected"}
            onChange={handleInputChangeCorrect}
            value={
              integration_box_certificate_corrected
                ? integration_box_certificate_corrected
                : "N/A"
            }
            tag={"Nº Autorización de C. inte"}
            size={100}
            desicion={StateDisble ? true : false}
            changeValue={
              integration_box_certificate !==
                integration_box_certificate_corrected && "BorderCorrect"
            }
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};
 */
