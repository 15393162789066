import { useModal } from "hooks/useModal";
import { __EditCreateThirdParties } from "request/thirdparties/__EditCreateThirdParties";
import { __DeleteThirdParty } from "request/thirdparties/__DeleteThirdParty";
import { __ExportThirdParties } from "request/thirdparties/__ExportThridParties";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";

const { createContext, useState } = require("react");

const ThirdPartiesContext = createContext();

export const ThirdPartiesProvider = ({ children }) => {
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestExport, setRequestExport] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null);
  const [qualifiedUsers, setQualifiedUsers] = useState(null);
  const [update, setUpdate] = useState(true);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */
  const editData = async (data) => {
    try {
      const res = await __EditCreateThirdParties(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        setUpdate(!update);
        onCloseEdit();
        openAlert(`Registro actualizado Exitosamente`, "success");
      } else {
        openAlert(res.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createData = async (data, handleClose = true) => {
    try {
      const res = await __EditCreateThirdParties(requestCreate, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        handleClose && onCloseEdit(); // esta linea se añade para evitar cerrar el modal al agregar un tercero desde el componente ThirdPartiesForm
        openAlert(`Registro creado Exitosamente`, "success");
        handleClose && setUpdate(!update);// esta linea se añade para evitar actualizar la tabla en caso de que aun no se hayan terminado de subir los adjuntos
        return res?.data?.status?.data // esta linea se añade para poder recuperar la data que viene del back (principalmente el id) para usarla en el componente ThirdPartiesForm al momento de añadir adjuntos desde este mismo componente se cerrara el modal de editar 
      } else {
        openAlert(res.data.status.message, "error");
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async () => {
    try {
      console.log(dataToDelete);
      const res = await __DeleteThirdParty(
        requestDelete,
        token,
        dataToDelete.id
      );
      // console.log(res);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
    }
  };

  const data = {
    isOpenEdit,
    onOpenEdit,
    isOpenDelete,
    onOpenDelete,
    isOpenShow,
    onOpenShow,
    onCloseModal,
    dataToEdit,
    dataToShow,
    dataToDelete,
    setDataToEdit,
    setDataToDelete,
    setDataToShow,
    setRequestEdit,
    setRequestDelete,
    setRequestCreate,
    setRequestExport,
    editData,
    createData,
    deleteData,
    update,
    setUpdate,
    qualifiedUsers,
    setQualifiedUsers
  };

  return (
    <ThirdPartiesContext.Provider value={data}>
      {children}
    </ThirdPartiesContext.Provider>
  );
};

export default ThirdPartiesContext;
