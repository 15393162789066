import { Box, SimpleGrid } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiSearch2Line } from "react-icons/ri";
import { ModalGeneric } from "components/modal/ModalGeneric";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useCallback, useContext, useState, useEffect } from "react";
import FormSearchsupplies from "./FormSearchsupplies";
import { useForm } from "hooks/useForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __PostForm } from "request/Petitions/__Post";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { AuthContext } from "context/AuthContext";
import { useDebounceError } from "hooks/useDebounceError";
import { NumericFormat } from "react-number-format";
import { MaxLength } from "utils/MaxLength";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { isNonEmptyString, isString } from "utils/type-check-utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { useDebounce } from "hooks/useDebouce";
import { Regex } from "utils/Regex";
import { useFormBeta } from "hooks/useFormBeta";
import { parseFloat } from "utils/parseFloat";

const FormAddSupplies = ({ onCloseReference }) => {
  const [openAlert] = AlertErrorAlert();
  const location = useLocation();
  const { pathname } = location;
  const { id = null, idCorrected = null } = queryString.parse(location.search);
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [percentageAndAmount, setpercentageAndAmount] = useState("%");
  const [unitProduct, setUnitProduct] = useState(null);
  //  const [valueFob, setValueFob] = useState("");
  const [net_weight_unit, setNet_weight_unit] = useState(0);
  //  const [valueFobShow, setValueFobShow] = useState("");
  //  const [valueNetWeigthShow, setValueNetWeigthShow] = useState("");
  const [fomateo] = useFormatQuantity();
  const [decimal, setDecimal] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [
    value_cif_unitMultipliedByquantity,
    setValue_cif_unitMultipliedByquantity,
  ] = useState("");
  const { code_destination } = useContext(GlobalContext);

  const options = ["%", "Cant"];
  const {
    onCloseModal,
    isOpenSearchSupplies,
    onOpenSearchSupplies,
    dataReference,
    setDataReference,
    createData,
    setRequestCreate,
    userQualified,
    suppliesData,
    editData,
    setRequestEdit,
    createDataCorrection,
    setRequestCreateCorrection,
    isEditCiSupplies,
  } = useContext(IntegracionBoxCrudContex);

  const handleReset = () => {
    onCloseReference();
    reset();
    setDataReference(null);
  };
  const [referenceManual, setReferenceManual] = useState(null);
  const onFieldChange = (name, newValue, target) => {
    if (name === "reference") {
      setReferenceManual(newValue);
      return;
    }

    if (name === "quantity_product") {
      const newQuantity = parseFloat(parseNumberWithCommas(newValue)) * 
                         parseFloat(parseNumberWithCommas(convertion_factor));
      
      setValues((prev) => ({
        ...prev,
        quantity: newQuantity
      }));

      setForm((prev) => ({
        ...prev,
        net_weight_calculate: newQuantity * 
                             parseFloat(parseNumberWithCommas(net_weight_unit))
      }));

      return;
    }

    if (name === "quantity") {
      setForm((prev) => ({
        ...prev,
        net_weight_calculate: parseFloat(parseNumberWithCommas(newValue)) * 
                             parseFloat(parseNumberWithCommas(net_weight_unit))
      }));
      return;
    }
  };

  const [values, handleInputChange, reset, setValues] = useForm(
    {
      net_weight: 0,
      fob_value: "",
      quantity: 0,
      quantity_product: "",
      reference: null,
      description: "",
      natural: "",
      code_Product: "",
      available_prod_balance: "",
      product_unit: "",
      convertion_factor: "",
      busisness_unit: "",
      quantity_garbage: "",
      agreement: "",
      subheading: "",
      certificate: "",
      taxes: "",
      customs_clearance: "",
      operation: "",
      initial: "",
      balance_value: "",
      name_tradeAgreement: "",
      inventory_id: "",
      value_cif_unit: "",
    },
    null,
    onFieldChange
  );

  //reference

  const {
    net_weight,
    fob_value,
    quantity,
    quantity_product,
    reference,
    description,
    natural,
    code_Product,
    available_prod_balance,
    product_unit,
    convertion_factor,
    busisness_unit,
    quantity_garbage,
    subheading,
    certificate,
    inventory_id,
    customs_clearance,
    operation,
    initial,
    balance_value,
    available_balance,
    name_tradeAgreement,
    value_cif_unit,
  } = values;

  const reference_debounce = useDebounce(referenceManual, 500);
  const [first, setFirst] = useState(null);

  const {
    values: form,
    handleInputChange: handleOnInputChange,
    setValues: setForm,
  } = useFormBeta({
    initialState: {
      calculate_value: 0,
      net_weight_calculate: 0,
    },
    returnType: "object",
  });

  const { net_weight_calculate } = form;
  const { requestData } = useGlobalContext();

  function parseNumberWithCommas(value) {
    return Number(value?.toString().replaceAll(",", "") || 0);
  }

  const { data: dataReferenceSearch, isLoading: isLoadingReferenceSearch } =
    useQuery({
      queryKey: [reference_debounce],

      queryFn: () =>
        requestData({
          Endpoint: environment?.GET_REFERENCES,
          PropertyBody: "POST",
          data: {
            reference: reference_debounce,
            code_destination: code_destination,
          },
        }),
      enabled: isNonEmptyString(reference_debounce),
    });

  useEffect(() => {
    if (!isNonEmptyString(reference_debounce)) reset();
  }, [reference_debounce]);

  useEffect(() => {
    setFirst(null);

    if (
      (isLoadingReferenceSearch === false &&
        !!dataReferenceSearch?.data?.status?.code === 200) ||
      dataReferenceSearch?.data?.status?.data?.data?.length === 0
    ) {
      openAlert("No se encontró la referencia solicitada", "info");
      return reset();
    }
    if (!dataReferenceSearch?.data?.status?.data?.data?.[0]) return;
    const {
      description_product,
      business_unit,
      code_product,
      code_subheading,
      business_unit_product,
      code_tradeAgreement,
      code_operation,
      code_operation_inicial,
      integration_certificate,
      quantity_available_product,
      quantity_available,
      value_fob,
      value_fob_unit,
      net_weight_unit,
      nature_of_inventory,
      reference,
      import_declaration,
      convertion_factor_product,
      customs_clearance,
      name_tradeAgreement,
      inventory_id,
      value_cif_unit,
    } = dataReferenceSearch?.data?.status?.data?.data?.[0];
    // setValueFob(value_fob_unit);
    setUnitProduct(dataReferenceSearch.data.status.data?.data?.[0]);
    setNet_weight_unit(parseFloat(parseNumberWithCommas(net_weight_unit)));
    setValues({
      reference,
      description: description_product,
      code_Product: code_product,
      product_unit: business_unit_product,
      busisness_unit: business_unit,
      agreement: code_tradeAgreement || "N/A",
      subheading: code_subheading,
      operation: code_operation,
      initial: code_operation_inicial,
      certificate: integration_certificate,
      available_prod_balance: quantity_available_product,
      available_balance: quantity_available,
      balance_value: value_fob,
      natural: nature_of_inventory,
      convertion_factor: Number(convertion_factor_product),
      taxes: import_declaration ?? 0,
      name_tradeAgreement,
      customs_clearance: customs_clearance ?? 0,
      quantity: 0,
      net_weight,
      inventory_id,
      value_cif_unit,
    });
    return () => {
      reset();
    };
  }, [dataReferenceSearch?.data?.status?.data]);

  const getReferenceShow = useCallback(
    async (data) => {
      try {
        const res = await __PostForm(
          environment.SHOW_REFERENCES_SUPPLY,
          token,
          data
        );
        const status = res?.data?.status.code;
        if (status === 200) {
          const {
            description_product,
            business_unit,
            code_product,
            code_subheading,
            business_unit_product,
            code_tradeAgreement,
            code_operation,
            code_operation_inicial,
            integration_certificate,
            quantity_available_product,
            quantity_available,
            fob_value,
            net_weight_kilo,
            reference,
            nature_of_inventory,
            import_declaration,
            convertion_factor_product,
            quantity,
            quantity_product,
            type_quantity_garbage,
            value_fob_calculate,
            net_weight_calculate,
            value_fob_available,
            net_weight_unit,
            value_fob_unit,
            quantity_garbage,
            name_tradeAgreement,
            inventory_id,
            value_cif_unit,
          } = res?.data?.status?.data || {};

          //   setValueFobShow(net_weight_unit);
          // setValueNetWeigthShow(value_fob_unit);
          setUnitProduct(res.data.status.data);
          setpercentageAndAmount(type_quantity_garbage === 0 ? "%" : "cant");
          setFirst(reference);
          setValues({
            quantity,
            net_weight: net_weight_kilo,
            quantity_garbage,
            reference,
            description: description_product,
            code_Product: code_product,
            product_unit: business_unit_product,
            busisness_unit: business_unit,
            agreement: code_tradeAgreement || "N/A",
            subheading: code_subheading,
            operation: code_operation,
            initial: code_operation_inicial,
            certificate: integration_certificate,
            available_prod_balance: quantity_available_product,
            available_balance: quantity_available,
            balance_value: value_fob_available,
            natural: nature_of_inventory,
            convertion_factor: convertion_factor_product,
            taxes: import_declaration || 0,
            customs_clearance: customs_clearance ?? 0,
            quantity_product,
            fob_value,
            name_tradeAgreement,
            inventory_id,
            value_cif_unit,
          });

          setNet_weight_unit(
            parseFloat(parseNumberWithCommas(net_weight_unit))
          );

          setForm({
            net_weight_calculate,
            calculate_value: value_fob_calculate,
          });
        } else {
          openAlert(status?.message, "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, setForm]
  );
  /* 
  useEffect(() => {
    if (quantity_product && quantity_product !== "" && !suppliesData) {
      const multicalculate =
        parseFloat(quantity_product.replaceAll(",", "")) *
        parseFloat(valueFob?.replaceAll(".", "").replaceAll(",", ""));

      setForm({
        ...form,
        calculate_value: multicalculate || 0,
        net_weight_calculate:
          parseFloat(parseNumberWithCommas(quantity)) *
          parseFloat(parseNumberWithCommas(net_weight_unit)),
      });
    } else {
      setForm({
        ...form,
        calculate_value:
          parseFloat(parseNumberWithCommas(quantity_product)) *
          parseFloat(parseNumberWithCommas(valueFobShow)),
        net_weight_calculate:
          parseFloat(parseNumberWithCommas(quantity)) *
          parseFloat(parseNumberWithCommas(net_weight_unit)),
      });
    }
  }, [quantity_product, suppliesData, valueFob]); */

  useEffect(() => {
    if (dataReference) {
      //   setValueFob(dataReference?.value_fob_unit);
      setUnitProduct(dataReference);

      /*    setValueNetWeigthb(
        parseFloat(parseNumberWithCommas(dataReference?.net_weight_unit))
      ); */

      setForm({
        net_weight_calculate,
        calculate_value: dataReference?.value_fob_calculate,
      });

      setNet_weight_unit(
        parseFloat(parseNumberWithCommas(dataReference?.net_weight_unit))
      );

      setFirst(dataReference?.reference);
      setValues({
        reference: dataReference?.reference,
        description: dataReference?.description_product,
        code_Product: dataReference?.code_product,
        product_unit: dataReference?.business_unit_product,
        busisness_unit: dataReference?.business_unit,
        agreement: dataReference?.code_tradeAgreement || "N/A",
        subheading: dataReference?.code_subheading,
        operation: dataReference?.code_operation,
        initial: dataReference?.code_operation_inicial,
        certificate: dataReference?.integration_certificate,
        available_prod_balance: dataReference?.quantity_available_product,
        available_balance: dataReference?.quantity_available,
        balance_value: dataReference?.value_fob,
        natural: dataReference?.nature_of_inventory,
        convertion_factor: Number(dataReference?.convertion_factor_product),
        taxes: dataReference?.import_declaration || 0,
        name_tradeAgreement: dataReference?.name_tradeAgreement,
        customs_clearance: dataReference?.customs_clearance || 0,
        quantity: 0,
        net_weight: 0,
        inventory_id: dataReference?.inventory_id,
        value_cif_unit: dataReference?.value_cif_unit,
      });
    }
  }, [dataReference]);

  useEffect(() => {
    if (suppliesData && suppliesData !== null) {
      const dataToSend = new FormData();
      dataToSend.append("id", suppliesData?.id);
      getReferenceShow(dataToSend);
    }
  }, [suppliesData]);

  const items = JSON.parse(localStorage.getItem("id_qualified"));

  const queryClient = useQueryClient();

  const invalidateQueryFn = () => {
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] });
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.GARBAGE_CI] });
  };

  const handleAddSuply = (e) => {
    console.log(inventory_id, "inventory_id");
    e.preventDefault();

    const referenceValidation =
      typeof reference === "string" && (!reference.trim() || reference === "");

    if (referenceValidation) {
      openAlert("Ingrese una Referencia válida", "error");
      return;
    }
    if (!code_destination) {
      openAlert("Debe seleccionar un tipo de cuadro.", "error");
      return;
    }

    if (!quantity_product) {
      return openAlert("Por favor, ingrese la cantidad del producto.", "error");
    }

    if (!fob_value) {
      return openAlert("Por favor, ingrese el Valor en US$.", "error");
    }

    if (fob_value && quantity_product) {
      const formData = new FormData();
      formData.append("integration_certificate_id", id || idCorrected);
      formData.append("inventory_id", inventory_id);
      formData.append("qualifiedUser_id ", userQualified?.value ?? items);
      formData.append("reference", reference);
      formData.append("code_destination", code_destination);

      formData.append(
        "quantity_garbage",
        parseNumberWithCommas(quantity_garbage)
      );
      formData.append("net_weight", parseNumberWithCommas(net_weight));
      formData.append("quantity", parseNumberWithCommas(quantity));
      formData.append(
        "quantity_product",
        parseNumberWithCommas(quantity_product)
      );
      formData.append("fob_value", parseNumberWithCommas(fob_value));
      formData.append("type", percentageAndAmount === "%" ? 0 : 1);

      if (suppliesData) {
        formData.append("id", suppliesData.id);
        editData(formData, () => {
          invalidateQueryFn();
          handleReset();
        });
      } else {
        createData(formData, () => {
          invalidateQueryFn();
          handleReset();
        });
      }
    }
  };

  const handleAddSuplyCorrected = (e) => {
    e.preventDefault();
    let fobValue = fob_value;
    let quantityGarbage = quantity_garbage;
    let netweight = net_weight;
    let quantityToSend = quantity;
    let quantityProduct = quantity_product;

    const referenceValidation =
      typeof reference === "string" && (!reference.trim() || reference === "");

    if (referenceValidation) {
      openAlert("Ingrese una Referencia válida", "error");
      return;
    }
    console.log(quantity_product, "quantity");
    if (!quantity_product) {
      return openAlert("Por favor, ingrese la cantidad del producto.", "error");
    }

    if (!fob_value) {
      return openAlert("Por favor, ingrese el Valor en US$.", "error");
    }

    const formData = new FormData();
    formData.append("integrationCertificateCorrected_id", id || idCorrected);
    formData.append("inventoryCorrected_id", inventory_id);

    formData.append("reference_corrected", reference);
    if (String(quantity_garbage).includes(",")) {
      quantityGarbage = quantity_garbage?.replaceAll(",", "");
    }
    formData.append("quantity_garbage_corrected", quantityGarbage);
    if (String(net_weight).includes(",")) {
      netweight = String(net_weight).replaceAll(",", "");
    }
    formData.append("net_weight_corrected", netweight);
    if (String(quantity).includes(",")) {
      quantityToSend = String(quantity).replaceAll(",", "");
    }
    formData.append("quantity_corrected", quantityToSend);

    if (String(quantity_product).includes(",")) {
      quantityProduct = String(quantity_product).replaceAll(",", "");
    }
    formData.append("quantity_product_corrected", quantityProduct);
    if (String(fob_value).includes(",")) {
      fobValue = String(fob_value).replaceAll(",", "");
    }
    formData.append("fob_value_corrected", fobValue);
    formData.append("type_corrected", percentageAndAmount === "%" ? 0 : 1);
    createDataCorrection(formData, invalidateQueryFn);
    handleReset();
    reset();
  };

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimal(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userQualified) userC(userQualified.value);
  }, [userC, userQualified]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
            ? decimal
            : Number(decimal_values_precision),
          posibleResult
        ),
      });
    }
  };

  const handleFormat = (valueInput) => {
    valueInput = String(valueInput).replaceAll(",", "");
    return fomateo(
      (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
        ? decimal
        : Number(decimal_values_precision),
      valueInput
    );
  };

  useEffect(() => {
    setRequestEdit(environment.GET_UPDATE_EDIT_SUPPLY);
    setRequestCreate(environment.SUPPLIES_INTEGRATION);
  }, [setRequestCreate, setRequestEdit]);

  useEffect(() => {
    setRequestCreateCorrection(environment.CREATE_SUPPLY_CORRECTED);
  }, [setRequestCreateCorrection]);

  /*  useEffect(() => {
    const validation =
      unitProduct &&
      quantity_product !== "" &&
      quantity_product !== undefined;
    if (validation) {
      const multiplicationQuantity = (
        Number(quantity_product?.replaceAll(",", "")) *
        Number(unitProduct?.convertion_factor_product)
      ).toFixed(decimal);

      const multiplicationNetweight = (
        Number(quantity_product?.replaceAll(",", "")) *
        Number(unitProduct?.convertion_factor_product)
      ).toFixed(decimal);

      setValues({
        ...values,
        quantity: multiplicationQuantity
          ? handleFormat(multiplicationQuantity)
          : 0,
        net_weight: multiplicationNetweight
          ? handleFormat(multiplicationNetweight)
          : 0,
      });
    }
  }, [quantity_product, unitProduct]); */

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
            value[value.length - 1]
          } no es permitido en este campo`,
      regex
    );
  };

  useEffect(() => {
    setValue_cif_unitMultipliedByquantity(
      value_cif_unit * parseNumberWithCommas(quantity)
    );
  }, [quantity, values]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchSupplies}
        onClose={onCloseModal}
        title={"Búsqueda por referencia"}
        size={"5xl"}
      >
        <FormSearchsupplies />
      </ModalGeneric>

      <form>
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div className="d-flex">
            <LabelGroup
              tipo={"text"}
              name={"reference"}
              value={reference}
              onChange={handleInputChange}
              tag={"Referencia"}
              size={90}
              desicion={false}
            />

            <div
              style={{ marginTop: 28, marginLeft: 10 }}
              onClick={onOpenSearchSupplies}
            >
              <IconGeneric color="#313131" as={RiSearch2Line} />
            </div>
          </div>

          <div>
            <LabelGroup
              name={"description"}
              value={description}
              onChange={handleInputChange}
              tag={"Descripción"}
              size={90}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"natural"}
              value={natural}
              onChange={handleInputChange}
              tag={"Naturaleza"}
              size={90}
              desicion={true}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <LabelGroup
              name={"code_Product"}
              value={code_Product}
              onChange={handleInputChange}
              tag={"Código producto"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"available_balance"}
              value={available_prod_balance}
              onChange={handleInputChange}
              tag={"Saldo Prod disponible"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"product_unit"}
              value={product_unit}
              onChange={handleInputChange}
              tag={"Unidad de producto"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"convertion_factor"}
              onChange={handleInputChange}
              tag={"Factor conv."}
              size={100}
              desicion={true}
              value={
                isString(convertion_factor)
                  ? parseFloat(convertion_factor)
                  : convertion_factor
              }
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"quantity_product"}
              value={quantity_product}
              onChange={handleInputChange}
              tag={"Cantidad producto"}
              dataType={"number"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"quantity"}
              value={quantity}
              onChange={handleInputChange}
              dataType={"number"}
              tag={"Cantidad"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>

          <div>
            <LabelGroup
              name={"busisness_unit"}
              value={busisness_unit}
              onChange={handleInputChange}
              tag={"Unidad comercial"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"quantity_garbage"}
              value={quantity_garbage}
              onChange={handleInputChange}
              dataType={"number"}
              tag={"Desperdicio"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>

          <div className="mt-4   d-flex justify-content-center ">
            <RadioBtn
              options={options}
              setOnly={setpercentageAndAmount}
              state={percentageAndAmount}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"net_weight"}
              value={net_weight}
              onChange={handleInputChange}
              dataType={"number"}
              tag={"Peso en kilos"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"fob_value"}
              value={fob_value}
              onChange={handleInputChange}
              dataType={"number"}
              tag={"Valor en US$"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>

          <div>
            <LabelGroup
              name={"name_tradeAgreement"}
              value={name_tradeAgreement}
              onChange={handleInputChange}
              tag={"Acuerdo"}
              size={100}
              desicion={true}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <LabelGroup
              name={"subheading"}
              value={subheading}
              onChange={handleInputChange}
              tag={"Subpartida"}
              size={100}
              desicion={true}
              maxLength={MaxLength?.global?.code_subheading}
            />
          </div>

          <div>
            <LabelGroup
              name={"certificate"}
              value={certificate}
              onChange={handleInputChange}
              tag={"Certificado"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            {/*   <LabelGroup
              name={"taxes"}
              value={taxes}
              onChange={handleInputChange}
              tag={"Dcl. Imp"}
              size={100}
              desicion={true}
            /> */}
            <LabelGroup
              name={"customs_clearance"}
              value={customs_clearance ?? "N/A"}
              onChange={handleInputChange}
              tag={"Dcl. Imp"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"operation"}
              value={operation}
              onChange={handleInputChange}
              tag={"Operación"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"initial"}
              value={initial ?? "N/A"}
              onChange={handleInputChange}
              tag={"Inicial"}
              size={100}
              desicion={true}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <LabelGroup
              name={"available_balance"}
              value={available_balance}
              onChange={handleInputChange}
              tag={"Saldo disponible"}
              size={100}
              desicion={true}
            />
          </div>

          <div>
            <LabelGroup
              name={"balance_value"}
              value={balance_value && handleFormat(balance_value)}
              onChange={handleInputChange}
              tag={"Saldo en valor."}
              size={100}
              desicion={true}
              onBlur={handleBlurCant}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              // data-key="formCalculate"
              name={"calculate_value"}
              value={
                value_cif_unitMultipliedByquantity ||
                value_cif_unit * quantity ||
                value_cif_unit
              }
              onChange={handleOnInputChange}
              data-key="formCalculate"
              tag={"Valor calculado"}
              size={100}
              desicion={true}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"net_weight_calculate"}
              value={net_weight_calculate}
              onChange={handleOnInputChange}
              data-key="formCalculate"
              tag={"Peso calculado"}
              size={100}
              onBlur={handleBlurCant}
              maxLength={34}
              desicion={true}
            />
          </div>
        </SimpleGrid>

        <div className="mb-3 ms-5">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={handleReset}>
              Cerrar
            </ButtonStyled>
            {pathname === "/IntegracionBox" ? (
              <ButtonStyled type="submit" onClick={handleAddSuply}>
                {isEditCiSupplies ? "Editar Insumo" : "Agregar insumo"}
              </ButtonStyled>
            ) : pathname === "/CorrectedCertificate" ? (
              <ButtonStyled type="submit" onClick={handleAddSuplyCorrected}>
                Agregar
              </ButtonStyled>
            ) : (
              ""
            )}
          </Box>
        </div>
      </form>
    </>
  );
};

export default FormAddSupplies;
