/* eslint-disable no-unused-vars */
import { useModal } from "hooks/useModal";
import { __EditCreateThirdParties } from "request/thirdparties/__EditCreateThirdParties";
import { useSelector } from "react-redux";
import {
  __CopyOrderProducts,
  __CreateDps,
  __CreateOrderProducts,
  __DeleteItemOrderProducts,
  __ExportDocument
} from "request/OrderProduction/OrderProduction";
import { __ProductsUserAll } from "request/configuration/__ProductProcess";
import { __ChangeStatus } from "request/Forms/_CrudForm";
import environment from "constants/apiConst";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post, __PostJsonData } from "request/Petitions/__Post";

const { createContext, useState } = require("react");

const OrderProductionContext = createContext();

export const OrderProductionProvider = ({ children }) => {
  const [isOpenReturnReason, onOpenReturnReason, onCloseReturnReason] =
    useModal();
  const [
    isOpenCreateCIntegration,
    onOpenCreateCIntegration,
    onCloseCreateCIntegration
  ] = useModal();

  const [isOpenTemplate, onOpenTemplate, onCloseTemplate] = useModal();
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenProduct, onOpenProduct, onCloseProduct] = useModal();
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal();
  const [isOpenChange, onOpenChange, onCloseChange] = useModal();
  const [isOpenCopy, onOpenCopy, onCloseCopy] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [loading, setLoading] = useState(false);
  const [dataToCopy, setDataToCopy] = useState(null);
  const [qr, setQr] = useState(null);
  const [ReturnReason, setReturnReason] = useState(null);
  const location = useLocation(); // navigate
  const [decimalUc, setDecimalUc] = useState(null);

  const [dataToDps, setDataToDps] = useState([]);
  const [dataCreateCI, setDataCreateCI] = useState(null);
  const [dataToGeneral, setDataToGeneral] = useState(null);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null);
  const [update, setUpdate] = useState(true);
  const [dataProducts, setDataProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [openAlert] = AlertErrorAlert();

  const token = useSelector((state) => state.auth.dataUser.token);
  const navigate = useNavigate();

  /**
   * Edita la data que le pasamos por paramentros
   * guarda en el endpoint que le pasemos mediante el estado de requestEdit
   * Ejecuta la funcion __EditConfig. Esta funcion recibe el endpoint, el token y la data a editar
   */
  const editData = async (data) => {
    try {
      const res = await __EditCreateThirdParties(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        onCloseEdit();
        openAlert(`Registro actualizado Exitosamente`, "successs");
        setUpdate(!update);
      } else {
        openAlert(`${res.status.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async () => {
    const formData = new FormData();
    formData.append("id", dataToGeneral.production_order_id);

    try {
      const res = await __DeleteItemOrderProducts(token, formData);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseWarning();
        openAlert(`Registro eliminado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(`${res.data.status.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
      return;
    }
    if (isOpenTemplate) {
      onCloseTemplate();
      setDataToEdit(null);
      return;
    }
    if (isOpenProduct) {
      onCloseProduct();
      return;
    }
    if (isOpenChange) {
      onCloseChange();
    }
  };

  const associateTemplate = async (informacion, reset) => {
    setLoading(true);
    try {
      const res = await __CreateOrderProducts(token, informacion);
      const data = res?.data?.status;
      console.log(data);
      if (data.code === 200) {
        openAlert(`${data.message}`, "success")
        setUpdate(!update);
        reset();
        onCloseModal();
      } else {
        openAlert(`${data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editTemplate = async (endpoint, informacion, reset) => {
    setLoading(true);
    try {
      const res = await __Post(endpoint, token, informacion);
      const data = res?.data?.status;
      console.log(data);
      if (data.code === 200) {
        openAlert(`${data.message}`, "success")
        setUpdate(!update);
        reset();
        onCloseModal();
      } else {
        openAlert(`${data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProductUserQualified = async (id) => {
    setLoading(true);
    try {
      const res = await __ProductsUserAll(token, id);
      const infoProduct = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.code_product
      }));
      setDataProducts(infoProduct);
      setAllProducts(res?.data?.status?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const changeStatus = async (data) => {
    const { pathname } = location;
    try {
      const res = await __ChangeStatus(
        environment.CHANGE_STATUS_PRODUCT_PROCESS,
        token,
        data
      );

      if (res.data.status.code === 200) {
        let estadoNuevo;
        const numeroFormulario = res.data.status.data.code_production_order;

        if (data.status_ProductionOrderForm === "BORRADOR") {
          estadoNuevo = "recuperado";
        } else if (data.status_ProductionOrderForm === "PRESENTADO") {
          estadoNuevo = "presentado";
        } else if (data.status_ProductionOrderForm === "APROBADO") {
          estadoNuevo = "aprobado";
        } else if (data.status_ProductionOrderForm === "AUTORIZADO") {
          estadoNuevo = "autorizado";
        } else if (data.status_ProductionOrderForm === "CORREGIDO") {
          estadoNuevo = "corregido";
        } else if (data.status_ProductionOrderForm === "DEVUELTO") {
          estadoNuevo = "devuelto";
        } else {
          estadoNuevo = "desechado";
        }
        openAlert(
          `El formulario ${numeroFormulario} fue ${estadoNuevo} exitosamente`,
          "success"
        );
        onCloseReturnReason();
        setUpdate(!update);
        if (
          data.status_ProductionOrderForm === "AUTORIZADO" ||
          pathname === "/CrearOrdenDeProduccion"
        ) {
          navigate(`/VerOrdenDeProduccion?Orden=${data.id}`);
        } else if (data.status_ProductionOrderForm === "DEVUELTO") {
          navigate(`/OrdenDeProduccion`);
        }
      } else {
        openAlert(`${res.data.status.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyData = async (data) => {
    const formData = new FormData();
    formData.append("id", dataToCopy.production_order_id);
    try {
      const res = await __CopyOrderProducts(token, formData);
      if (res?.data?.status?.code === 200) {
        openAlert(`El registro ha sido copiado exitosamente.`, "success");
        setUpdate(!update);
        onCloseCopy();
        navigate(
          `/CrearOrdenDeProduccion?Orden=${res?.data?.status?.data?.id}`
        );
      } else {
        if (res?.data?.status?.message) {
          openAlert(`${res?.data?.status?.message}`, "error");
        } else {
          Object.values(res?.data?.status?.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportColumnBy = async (data, onCloseExport = () => {}) => {
    try {
      const res = await __ExportDocument(token, data);
      if (res.status === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        onCloseExport();
      } else {
        // console.log(res);
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      openAlert(`Ha ocurrido un error, porfavor inténtelo más tarde`, "error");
      console.log(error);
    }
  };

  const createDps = async (data) => {
    try {
      const resultado = await __CreateDps(token, data);
      if (resultado.data.status.code === 200) {
        openAlert("Actualizado con exito", "success");
        return setUpdate(!update);
      } else {
        return openAlert(resultado.data.status.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCiOp = async (data) => {
    try {
      const res = await __Post(
        environment.CREATE_CI_PRODUCTION_ORDER,
        token,
        data
      );

      if (res.data.status.code === 200) {
        const destru = res.data.status.data.cetificate;

        onCloseCreateCIntegration();
        navigate(
          `/IntegracionBox?id=${destru.id}`
        );
        openAlert(
          `Formulario de certificado de integración creado con éxito`,
          "success"
        );
      } else {
        // console.log(res);
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    isOpenEdit,
    onOpenEdit,
    isOpenDelete,
    onOpenDelete,
    isOpenShow,
    onOpenShow,
    onCloseModal,
    dataToEdit,
    dataToShow,
    dataToDelete,
    setDataToEdit,
    setDataToDelete,
    setDataToShow,
    setRequestEdit,
    setRequestDelete,
    setRequestCreate,
    editData,
    deleteData,
    update,
    isOpenTemplate,
    onOpenTemplate,
    onCloseTemplate,
    associateTemplate,
    dataToGeneral,
    setDataToGeneral,
    dataProducts,
    getProductUserQualified,
    loading,
    setLoading,
    onCloseEdit,
    allProducts,
    isOpenProduct,
    onOpenProduct,
    onCloseProduct,
    isOpenChange,
    onOpenChange,
    onCloseChange,
    changeStatus,
    isOpenWarning,
    onOpenWarning,
    onCloseWarning,
    isOpenCopy,
    onOpenCopy,
    onCloseCopy,
    dataToCopy,
    setDataToCopy,
    copyData,
    exportColumnBy,
    qr,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    ReturnReason,
    setReturnReason,
    setUpdate,
    dataToDps,
    setDataToDps,
    createDps,
    decimalUc,
    setDecimalUc,
    isOpenCreateCIntegration,
    onOpenCreateCIntegration,
    onCloseCreateCIntegration,
    dataCreateCI,
    setDataCreateCI,
    createCiOp,
    editTemplate
  };

  return (
    <OrderProductionContext.Provider value={data}>
      {children}
    </OrderProductionContext.Provider>
  );
};

export default OrderProductionContext;
