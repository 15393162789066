import { Box, SimpleGrid, Tooltip } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import environment from "constants/apiConst";
import queryEnv from "constants/queryConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useDebounceAlert, useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { SearchEditedFields } from "hooks/useLocalStorage";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonStyled } from "styled/Buttons.styled";
import { TextAreaFormStyled } from "styled/Input.styled";
import { isValid } from "utils/parseFloat";

const initialState = {
  service: "",
  exchange_rate: "",
  output_form_item_description: ""
};

const FormUploaderServiceItem = ({ valuesHeader, set }) => {
  const {
    CreateItems,
    dataToEditItems,
    EditItems,
    setRequestEditItem,
    setDataToEditItems,
    dataToCopyItems,
    setDataToCopyItems,
    ReviewData
  } = useContext(FormCrudCRUDContext);

  const [values, handleInputChange, reset, setValues] = useForm(initialState);
  const { service, exchange_rate, output_form_item_description } = values;
  const [openAlert] = AlertErrorAlert();

  const handleSubmit = () => {
    const formData = new FormData();
    if (service == null || service.length === 0) { return openAlert("El campo servicio es obligatorio", "error"); }
    if (exchange_rate == null || exchange_rate.length === 0) { return openAlert("El campo tasa de cambio es obligatorio", "error"); }
    if (
      output_form_item_description == null ||
      output_form_item_description?.length === 0
    ) {
      return openAlert("El campo descripción es obligatorio", "error");
    }
    formData.append("service", service.replace(/\,/g, ""));
    formData.append(
      "exchange_rate", isValid(exchange_rate) ? exchange_rate : dataTRM?.data?.value_trm
    );
    formData.append(
      "output_form_item_description",
      output_form_item_description
    );
    formData.append("outputForm_id", valuesHeader?.id);
    if (dataToEditItems) {
      formData.append("id", dataToEditItems.id);
      return EditItems(formData, reset);
    }
    CreateItems(formData, () => {
      reset();
      setValues(initialState);
    });
  };

  useEffect(() => {
    if (dataToCopyItems) {
      setValues((prev) => ({
        ...prev,
        exchange_rate: dataToCopyItems?.exchange_rate
          .toString()
          .replaceAll(",", "")
      }));
      return;
    }
    if (dataToEditItems) {
      setValues((prev) => ({
        ...dataToEditItems,
        exchange_rate: dataToEditItems?.exchange_rate
          .toString()
          .replaceAll(",", "")
      }));
      return;
    }
    reset();
  }, [dataToEditItems, dataToCopyItems]);

  const handleCancel = () => {
    setDataToEditItems(null);
    setDataToCopyItems(null);
    set(false);
    reset();
  };

  useEffect(() => {
    if (dataToEditItems) {
      setRequestEditItem(environment.UPDATE_ITEMS_EXIT);
      return;
    }
    setValues(values);
    reset();
  }, [dataToEditItems]);

  const [handleDebounceAlert] = useDebounceAlert();

  const handleRegex = ({ target }) => {
    const { value, name } = target;
    let formattedValue = "";
    const numericInput = value.replace(/[^\d.]/g, "");

    const decimalPart = numericInput.includes(".")
      ? numericInput.slice(numericInput.indexOf("."))
      : "";

    if (decimalPart.length > 5) {
      return handleDebounceAlert(
        "El número de decimales no puede exceder los 4 dígitos."
      );
    }

    if (numericInput.startsWith(".")) {
      return handleInputChange({ target: { value: "0.", name } });
    }

    if (numericInput.indexOf(".") !== numericInput.lastIndexOf(".")) {
      return handleDebounceAlert(
        "El número solo puede tener un punto decimal."
      );
    }

    const intPart = numericInput.includes(".")
      ? numericInput.slice(0, numericInput.indexOf("."))
      : numericInput;

    const formattedIntPart = intPart
      ? parseInt(intPart, 10).toLocaleString("en-US")
      : "0";

    formattedValue = formattedIntPart + decimalPart;

    return handleInputChange({ target: { value: formattedValue, name } });
  };

  const { token } = useSelector(
    (state) => state.auth.dataUser
  );

  const { data: dataTRM } = useQuery({
    queryKey: [queryEnv?.TRM],
    queryFn: async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      };

      const res = await axios(`${environment.TRM_GET}`, options);
      if (res?.data?.status?.code === 200) {
        setValues(prev => ({
          ...prev,
          ...(!prev?.exchange_rate ? { exchange_rate: res?.data?.status?.data.value_trm } : {}),
        }));
        return {
          ...res?.data?.status,
          data: {
            ...res?.data?.status?.data,
            value_trm: parseFloat(res?.data?.status?.data.value_trm)
          }
        }
      };
      return res;
    },
  })


  return (
    <div
      className=" shadow-sm p-1 mb-4 rounded"
      style={{ background: "#f2f2f2", marginTop: "40px" }}
    >
      <h1 className="my-4 ms-1 fw-bolder">Agregar ítem</h1>
      <>
        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <Tooltip
            hasArrow
            label={
              ReviewData
                ? SearchEditedFields(`service${values?.id}`, ReviewData)
                : ""
            }
            bg="red.600"
            color="white"
            placement="bottom-start"
          >
            <div className="mt-1">
              <LabelGroup
                name={"service"}
                onChange={(e) => handleRegex(e)}
                value={service || ""}
                placeholder={"Valor del servicio"}
                tag={"Valor del servicio"}
                size={100}
                error={
                  !!(ReviewData &&
                    SearchEditedFields(`service${values?.id}`, ReviewData))
                }
                maxLength={20}
              />
            </div>
          </Tooltip>
          <Tooltip
            hasArrow
            label={
              ReviewData
                ? SearchEditedFields(`exchange_rate${values?.id}`, ReviewData)
                : ""
            }
            bg="red.600"
            color="white"
            placement="bottom-start"
          >
            <div className="mt-1">
            <LabelGroup
                name={"exchange_rate"}
                onChange={(e) => handleRegex(e)}
                value={exchange_rate || ""}
                placeholder={"Tasa de Cambio"}
                tag={"Tasa de Cambio"}
                size={100}
                error={
                  !!(ReviewData &&
                    SearchEditedFields(`exchange_rate${values?.id}`, ReviewData))
                }
                maxLength={10}
              />
            </div>
          </Tooltip>
        </SimpleGrid>
        <Tooltip
          hasArrow
          label={
            ReviewData
              ? SearchEditedFields(
                `output_form_item_description${values?.id}`,
                ReviewData
              )
              : ""
          }
          bg="red.600"
          color="white"
          placement="bottom-start"
        >
          <div className="mt-1 pb-10">
            <LabelStyled
              error={
                !!(ReviewData &&
                  SearchEditedFields(
                    `output_form_item_description${values?.id}`,
                    ReviewData
                  ))
              }
            >
              {"Descripción"}
            </LabelStyled>
            <TextAreaFormStyled
              tipo={"textarea"}
              name={"output_form_item_description"}
              onChange={handleInputChange}
              value={output_form_item_description ?? ""}
              placeholder={""}
              tag={"Descripción"}
              error={
                !!(ReviewData &&
                  SearchEditedFields(
                    `output_form_item_description${values?.id}`,
                    ReviewData
                  ))
              }
              size={100}
            />
          </div>
        </Tooltip>
        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={handleCancel}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleSubmit}>
            {dataToEditItems
              ? "Actualizar"
              : dataToCopyItems
                ? "Copiar"
                : "Agregar"}
          </ButtonStyled>
        </Box>
      </>
    </div>
  );
};

export default FormUploaderServiceItem;
