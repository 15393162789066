import React, { useContext, useState } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { MdQrCodeScanner } from "react-icons/md";
import { IconGeneric } from "styled/Icons.styled";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { VscFilePdf } from "react-icons/vsc";
import { BtnLogBorrador } from "./BtnLogBorrador";
import BtnLogPresentado from "./BtnLogPresentado";
import { ExportPdf } from "components/Export/ExportPdf";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ValidatePresenFmmCorrect } from "pages/Entry_and_exit_forms/ValidatePresenFmmCorrect";
import ButtonsReviewing from "pages/Entry_and_exit_forms/View/ButtonViewForms/ButtonsReviewing";
import { useForm } from "hooks/useForm";
import queryString from "query-string";
import ModalQR from "components/modal/ModalQR";
import { useModal } from "hooks/useModal";
import { useGlobalContext } from "context/GlobalContext";
import { checkProperty, isArray, isObject } from "utils/type-check-utils";

const ButtonView = ({ data, children, idFmm, status, mt = 'mt-4'}) => {
  const [isOpen, onOpen, onClose] = useModal();
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();
  const location = useLocation();

  const { pathname } = location;
  const { ShowEntry = "" } = queryString.parse(location.search);

  const {
    changeStatusCorregido,
    onOpenReturnReason,
    setReturnReason,
    getItemsPrev,
    onOpenRadicar,
    setDataRadicar,
    getItemsPrevOutput,
    onOpenRecuperar,
    setDataRecuperar,
  } = useContext(FormCrudCRUDContext);

  const [getRequiredBack, setgetRequiredBack] = useState(null);

  const [values, handleInputChange] = useForm({
    free_pass: "",
  });

  const { free_pass } = values || {};

  let name = "";

  if (data?.status_entryFormCorrected) {
    name = "FMM_INGRESO_CORREGIDO_" + data?.code_entryFormCorrected;
  } else {
    name = "FMM_SALIDA_CORREGIDO_" + data?.code_outputFormCorrected;
  }

  const arrayEntryCorrected = [
    "product_quantity_corrected",
    "typeProductCorrected_id",
    "transportMethodCorrected_id",
    "packagingCorrected_id",
    "countryOriginCorrected_id",
    "countryProcedencyCorrected_id",
    "flagCorrected_id",
    "countryPurchaseCorrected_id",
    "productCorrected_id",
    "gross_weight_corrected",
    "net_weight_corrected",
    "us_freight_corrected",
  ];

  const routerAllow = ["/CheckCorrectEntry", "/CheckCorrectOutput"];

  const { dataCorrected } = useGlobalContext();
  const { files = {} } = dataCorrected || {};
  const handlePresent = async (status) => {
    const attach = data?.files;
    const mensageErrorItem = ValidatePresenFmmCorrect(data, status);
   
    if (
      isObject(files) &&
      status === "AUTORIZADO" &&
      routerAllow.includes(pathname)
    ) {
      const fileFormat = Object.entries(files).map(([key, valor]) => {
        if (!valor) {
          return;
        }
        return (files[key] = valor);
      });

      if (isArray(fileFormat)) {
        const isValidDocument = checkProperty({
          obj: fileFormat,
          prop: "attachment_description",
          value: "Carta usuario",
          checkPropertyAndValue: true,
        });

        if (!isValidDocument) {
          return openAlert(
            "Es necesario adjuntar la carta de usuario.",
            "error"
          );
        }
      }

      if (isArray(fileFormat)) {
        const isValidDocument = checkProperty({
          obj: fileFormat,
          prop: "attachment_description",
          value: "PDF formulario original",
          checkPropertyAndValue: true,
        });

        if (!isValidDocument) {
          return openAlert(
            "Es necesario adjuntar el PDF formulario original.",
            "error"
          );
        }
      }
    }

    if (mensageErrorItem) {
      openAlert(mensageErrorItem, "error");
    } else {
      if (pathname === "/FormCorrectionOutput") {
        const respuestaOutput = await getItemsPrevOutput(
          environment.GET_iTEMS_CORRECT_OUTPUT_NP,
          idFmm
        );
        setgetRequiredBack(respuestaOutput);
      } else {
        const respuesta = await getItemsPrev(
          environment.GET_iTEMS_CORRECT_ENTRY_NP,
          idFmm,
          arrayEntryCorrected,
          data?.code_operationCorrected
        );
        setgetRequiredBack(respuesta);
      }

      const infoEntryCorrect = {
        id: data.id,
        status_entryFormCorrected: status,
        free_pass: free_pass ? 1 : 0,
      };

      const infoOutputCorrect = {
        id: data.id,
        status_outputFormCorrected: status,
      };

      if (status === "PRESENTADO" && (getRequiredBack || attach.length === 0)) {
        if (ShowEntry) {
          navigate(`/FormCorrection?FormEntry=${idFmm}`);
        } else {
          navigate(`/FormCorrectionOutput?FormOutput=${idFmm}`);
        }

        if (
          getRequiredBack &&
          data.status_item === 1 &&
          data.code_operationCorrected >= 101 &&
          data.code_operationCorrected <= 599
        ) {
          if (ShowEntry) {
            navigate(`/FormCorrection?FormEntry=${idFmm}`);
          } else {
            navigate(`/FormCorrectionOutput?FormOutput=${idFmm}`);
          }
          openAlert(
            "El formulario presenta errores. Verifique los campos marcados",
            "error"
          );
        } else if (attach.length === 0) {
          if (ShowEntry) {
            navigate(`/FormCorrection?FormEntry=${idFmm}`);
          } else {
            navigate(`/FormCorrectionOutput?FormOutput=${idFmm}`);
          }
          openAlert(
            "El formulario no tiene completo los documentos requeridos",
            "error"
          );
        } else {
          changeStatus(status, infoEntryCorrect, infoOutputCorrect);
        }
      } else {
        changeStatus(status, infoEntryCorrect, infoOutputCorrect);
      }
    }
  };

  const changeStatus = (status, infoEntryCorrect, infoOutputCorrect) => {
    if (status === "DEVUELTO") {
      onOpenReturnReason();
      if (ShowEntry) {
        setReturnReason(infoEntryCorrect);
      } else {
        setReturnReason(infoOutputCorrect);
      }
    } else if (status === "AUTORIZADO") {
      onOpenRadicar();
      setDataRadicar(data);
    } else if (status === "RECUPERADO") {
      onOpenRecuperar();
      setDataRecuperar(data);
    } else {
      if (ShowEntry) {
        changeStatusCorregido(infoEntryCorrect);
      } else {
        changeStatusCorregido(infoOutputCorrect);
      }
    }
  };

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />

      <div className="row">
        {(pathname === "/FormCorrection" ||
          pathname === "/FormCorrectionOutput") && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={mt}
          >
            {data?.status_outputFormCorrected === "BORRADOR" ||
            data?.status_entryFormCorrected === "BORRADOR" ||
            data?.status_outputFormCorrected === "DEVUELTO" ||
            data?.status_entryFormCorrected === "DEVUELTO" ? (
              <BtnLogBorrador data={data} handlePresent={handlePresent} />
            ) : data?.status_outputFormCorrected === "PRESENTADO" ||
              data?.status_entryFormCorrected === "PRESENTADO" ? (
              <BtnLogPresentado data={data} handlePresent={handlePresent} />
            ) : (
              ""
            )}
          </Box>
        )}

        {(pathname === "/CheckCorrectEntry" ||
          pathname === "/CheckCorrectOutput") && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <ButtonsReviewing handlePresent={handlePresent} data={data} />
          </Box>
        )}

        {children}
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <ButtonHeaderModule
                onClick={onOpen}
                className="me-1"
                bgColor="#1F7AC3"
              >
                <IconGeneric className="text-white" as={MdQrCodeScanner} />
              </ButtonHeaderModule>
            </Tooltip>
            <Tooltip placement="top" hasArrow label="Exportar PDF">
              <ButtonHeaderModule
                onClick={() => ExportPdf("IdPdfFmmCorrectEntry", name)}
                bgColor="#03C39A"
              >
                <IconGeneric className=" text-white" as={VscFilePdf} />
              </ButtonHeaderModule>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ButtonView;
