import { IoInformationCircleOutline } from "react-icons/io5";
import { IconGeneric } from "styled/Icons.styled";

const {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} = require("@chakra-ui/react");

export const Modalcustomizable = ({
  isOpen,
  onClose,
  title,
  content,
  size,
  rightButtonText = "Actualizar",
  rightButtonColor = "blue",
  onRightButtonClick,
  colorIcon = "#2b6cb0",
  iconComponent = IoInformationCircleOutline,
  hiddenButtons = false,
  children
}) => {
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className="d-flex align-items-center">
              <IconGeneric
                className="me-2 mt-0"
                color={colorIcon}
                as={iconComponent}
              />

              {title}
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {content}
            {children}
          </ModalBody>

          <ModalFooter>
            {!hiddenButtons && (
              <>
                <Button colorScheme="gray" mr={3} onClick={onClose}>
                  Cerrar
                </Button>
                <Button
                  colorScheme={rightButtonColor}
                  onClick={onRightButtonClick}
                >
                  {rightButtonText}
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
