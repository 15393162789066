const propsSwalSecurity = {
  reverseButtons: true,
  title: "¿Desea registrar todos los pases?",
  text: "Se registrarán todos los pases",
  icon: "warning",
  showCancelButton: true,
  cancelButtonColor: "#ba1f33",
  confirmButtonColor: "#1F7AC3",
  cancelButtonText: "Cancelar",
  confirmButtonText: "Registrar"
};

const presented = "PRESENTADO";
const revise = "REVISADO";
const authorized = "AUTORIZADO";

const styleSelected = {
  backgroundColor: "#1F7AC3",
  padding: 8,
  color: "#fff",
  borderRadius: 2
};
const styleUnSelected = {
  backgroundColor: "#f4f4f4",
  padding: 8,
  color: "#9CB4CC",
  alignSelf: "center",
  borderRadius: 2
};

export {
  propsSwalSecurity,
  presented,
  authorized,
  styleSelected,
  revise,
  styleUnSelected
};
