import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import ReviewsHeader from "./TableReviews/ReviewsHeader";
import Pagination from "components/Pagination/Pagination";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { __SearchReviews } from "request/Reviews/__GetTReviews";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { theadAproved } from "./TableReviews/thead/thead";
import TbodyAproved from "./TableReviews/TbodyAproved";
import { useQuery } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { useGlobalContext } from "context/GlobalContext";
import TBodyAprovedToTransfer from "./TableReviews/TbodyAprovedToTransfer";

export const ReviewAproved = () => {
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  // eslint-disable-next-line no-unused-vars

  const [openAlert] = AlertErrorAlert();
  const [page, setPage] = useState(1);
  const [pageToTransfer, setPageToTransfer] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [resData, setResData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  const APROVED_FMM_ENDPOINT = {
    "Aprobado de salida": environment.POST_APROVED_ENTRYOUTPUT_AUTHORIZE,
    "Aprobado de ingreso": environment.POST_APROVED_ENTRYFORM_AUTHORIZE
  }[movement];

  const { requestData } = useGlobalContext();

  const { data: dataAllDocuments, isLoading: isLoadingAllDocuments } = useQuery({
    queryKey: [queryEnv?.APROVED_FMM, freeZone_id, page],
    queryFn: () => requestData({
      Endpoint: APROVED_FMM_ENDPOINT,
      PropertyBody: "POST",
      pageParam: page
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const { data: dataDocumentsToTransfer, isLoading: isLoadingDocumentsToTransfer } = useQuery({
    queryKey: [queryEnv?.APROVED_FMM_TO_TRANSFER, freeZone_id, pageToTransfer],
    queryFn: () => requestData({
      Endpoint: environment?.POST_APROVED_ENTRY_TO_TRANSFER,
      PropertyBody: "POST",
      pageParam: pageToTransfer
    }),
    enabled: movement === "Aprobado de ingreso"
  });

  useEffect(() => {
    console.log({ dataAllDocuments, isLoadingAllDocuments })
    if (!dataAllDocuments) return;
    if (dataAllDocuments?.data?.status?.code !== 200) return openAlert(dataAllDocuments?.data?.status?.message, "error");
    setResData(dataAllDocuments?.data?.status?.data?.data);
    setMaxPage(dataAllDocuments?.data?.status?.data?.last_page);
  }, [dataAllDocuments, isLoadingAllDocuments])

  return (
    <>
      {
        <>
          <HeaderSearch>
            <ReviewsHeader title={"Todos los documentos"} mt="mt-5" />
            <TableOrder
              thead={theadAproved}
              data={resData}
              isNum={false}
              setData={setResData}
              loading={isLoadingAllDocuments}
              tbodyData={TbodyAproved}
            />
            <Pagination
              thead={theadAproved}
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={isLoadingAllDocuments}
            />
          </HeaderSearch>
          {movement === "Aprobado de ingreso" &&
            <HeaderSearch>
              <ReviewsHeader title={"Documentos pendientes de traslado"} mt="mt-5" />
              <TableOrder
                thead={theadAproved}
                data={isLoadingDocumentsToTransfer ? [] : dataDocumentsToTransfer?.data?.status?.data?.data}
                isNum={false}
                setData={() => {}}
                loading={isLoadingDocumentsToTransfer}
                tbodyData={TBodyAprovedToTransfer}
              />
              <Pagination
                thead={theadAproved}
                page={pageToTransfer}
                setPage={setPageToTransfer}
                maxPage={isLoadingDocumentsToTransfer ? 1 : dataDocumentsToTransfer?.data?.status?.data?.last_page}
                loading={isLoadingDocumentsToTransfer}
              />
            </HeaderSearch>
          }
        </>
      }
    </>
  );
};
