/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import TableOrder from "components/Tables/TableOrder";
import ThirdPartiesHeader from "./ThirdPartiesHeader";
import ModalDelete from "components/modal/ModalDelete";
import ThirdPartiesForm from "./form/ThirdPartiesForm";
import ThirdPartiiesShow from "./form/ThirdPartiiesShow";
import Pagination from "components/Pagination/Pagination";
import TbodyThirdParties from "./tables/TbodyThirdParties";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import { HeaderSearch } from "styled/SectionContainer.styled";
import ModalTransition from "components/modal/ModalTransition";
import SectionHeader from "components/SectionHeader/SectionHeader";
import {
  __GetThirdParties,
  __Get_MonitoringSearch,
  __PostThirdParties
} from "request/thirdparties/__GetThirdParties";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import { useDisclosure, Text, Tooltip } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiOutlinePlus } from "react-icons/hi";
import { MdUploadFile } from "react-icons/md";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import { CargaMasiva } from "./carga masiva/CargaMasiva";
import { __BulkLoad } from "request/Pases/__CrudPass";

import { CgImport } from "react-icons/cg";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { ThirdSearchAdvanceForm } from "./form/ThirdSearchAdvanceForm";
import { useModal } from "hooks/useModal";
import { thead, theadSuperADMIN } from "./thead/thead";
import { useForm } from "hooks/useForm";
import { __Post } from "request/Petitions/__Post";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray } from "utils/type-check-utils";
import { entryThirdPartiesNodeList, thirdPartiesInputFields } from "./Export/ListItem";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useSearchUrl } from "hooks/useSearchUrl";

const ThirdParties = () => {
  const { state, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["type", "business_name", "identification_num", "internal_code", "acronym", "alias", "advanced"], nestedProp: "searchAdvance" })

  const { authUser } = useContext(AuthContext);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [recient, setRecient] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenCarga, onOpenCarga, onCloseCarga] = useModal();
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(state);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: null,
  });
  const dispatchRedux = useDispatch();

  const { searchInformacion, valueQualified } = values;

  const {
    update,
    onOpenEdit,
    isOpenShow,
    deleteData,
    isOpenEdit,
    onCloseModal,
    dataToDelete,
    isOpenDelete,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setRequestExport,
    setUpdate
  } = useContext(ThirdPartiesContext);

  const getThirdParties = async () => {
    setLoading(true);
    setRequestEdit(environment.UPDATE_THIRD_PARTY);
    setRequestCreate(environment.CREATE_THIRD_PARTY);
    setRequestDelete(environment.DELETE_THIRD_PARTY);
    try {
      const res = await __PostThirdParties(token, page);
      const status = res?.data?.status;
      if (status.code !== 200) {
        setResData([]);
        return openAlert(status?.message, "error");
      }
      setResData(res?.data?.status?.data?.rest?.data || []);
      setRecient(status?.data?.recents || []);
      setMaxPage(res?.data?.status?.data?.rest?.last_page);
      setChangeViewSearch(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const BulkLoad = async (data) => {
    try {
      const res = await __BulkLoad(environment.THIRD_PARTY_IMPORT, token, data);
      const respuestaAttachment = res.data.status;

      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
        setUpdate(!update);
        return onCloseCarga();
      }
      openAlert(`${respuestaAttachment.message}`, "info");
    } catch (error) {
      openAlert(
        `Por favor verifique que se esté ingresando la plantilla correcta`,
        "error"
      );
    } finally {
      onClose();
    }
  };

  const backSearch = () => {
    reset();
    setSearchAdvanced([]);
    clearParamsSearch({ searchArray: ["searchAdvance", "searchSimple"] });
    setChangeViewSearch(false);
    setPage(1);
    setPageSearch(1);
  };

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __Get_MonitoringSearch(token, informacion, pageSearch);
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        return openAlert(res.data.status.message, "error");
      }

      setChangeViewSearch(true);
      setCurrentPageTwo(res?.data?.status?.data?.rest?.data);
      setRecient(res?.data?.status?.data?.recents || []);
      setMaxPageSearch(res?.data?.status?.data?.rest?.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    const formData = new FormData();
    searchInformacion && formData.append("search", searchInformacion);
    ((isArray(valueQualified) && valueQualified?.length !== 0) || valueQualified?.value) && formData.append("qualifiedUser_id", valueQualified?.value);

    Object.entries(searcheAdvanced).forEach(([key, valor]) => {
      if (valor !== null && valor !== undefined && valor !== '') {
        formData.append(key, valor)
      };
    });

    const data = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && { qualifiedUser_id: valueQualified?.value }),
      ...searcheAdvanced,
    }

    if (Object.keys(data).length !== 0) {
      dispatchRedux(setSearch(data));
      searchSimple(formData);
      return
    }

    dispatchRedux(clearSearch());
    setChangeViewSearch(false);
    getThirdParties();
  }, [searchInformacion, searcheAdvanced, valueQualified, pageSearch, update]);

  useEffect(() => {
    getThirdParties();
  }, [freeZone_id, page, token]);

  useEffect(() => {
    return async () => dispatchRedux(clearSearch());
  }, []);

  useEffect(() => {
    setPageSearch(1);
  }, [searchInformacion]);

  const [isOpenExport, onOpenExport, onCloseExport] = useModal();

  const exportThirdParties = async (data) => {
    try {
      const res = await __Post(environment.EXPORT_THIRD_PARTY, token, data);
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, 'error')
    } catch (error) {
      openAlert(`Error al enviar la solicitud`, 'error')
    }
  };

  const headerButtons = () => {
    return (
      <>
        <Tooltip placement="top" hasArrow label="Cargar Masivamente">
          <ButtonHeaderModule
            onClick={onOpenCarga}
            bgColor="#1F7AC3"
            className="ms-1 mt-4 align-bottom d-flex float-end"
          >
            <IconGeneric color="#fff" as={MdUploadFile} />
          </ButtonHeaderModule>
        </Tooltip>

        {(allAccess === true ||
          myPermission?.includes("createThirdParty")) && (
            <Tooltip placement="top" hasArrow label="Agregar tercero">
              <ButtonHeaderModule
                onClick={onOpenEdit}
                bgColor="#05668e"
                className="ms-1 mt-4 align-bottom d-flex float-end"
              >
                <IconGeneric color="#fff" as={HiOutlinePlus} />
              </ButtonHeaderModule>
            </Tooltip>
          )}
        {(allAccess === true || myPermission?.includes("exportExcel")) && (
          <Tooltip placement="top" hasArrow label="Exportar">
            <ButtonHeaderModule
              onClick={onOpenExport}
              bgColor="#03C39A"
              className="ms-1 mt-4 align-bottom d-flex float-end"
            >
              <IconGeneric color="#fff" as={CgImport} />
            </ButtonHeaderModule>
          </Tooltip>
        )}

      </>
    )
  }

  const handleSubmit = async (data, formulario) => {
    const info = {
      ...data,
      type_export_file: data?.type_export_file?.value ? data?.type_export_file?.value : "xlsx",
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: isArray(data?.qualifiedUser_id) ? data?.qualifiedUser_id?.map(item => item?.value || item) : data?.qualifiedUser_id?.value || data?.qualifiedUser_id
      }),
      fields: formulario
    };
    return exportThirdParties(info);
  };

  return (
    <>
      <ModalTransition isOpen={isOpenEdit} onClose={onCloseModal} size="xl">
        <ThirdPartiesForm />
      </ModalTransition>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={entryThirdPartiesNodeList}
        selectNodeList={thirdPartiesInputFields}
        enableAdvance={true}
      />

      <ModalTransition isOpen={isOpenShow} onClose={onCloseModal} size="xl">
        <ThirdPartiiesShow />
      </ModalTransition>

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento ${dataToDelete?.business_name || ""}`}
        onDelete={deleteData}
      />

      <ModalGeneric
        isOpen={isOpenCarga}
        onOpen={onOpenCarga}
        onClose={onCloseCarga}
        title={"Carga masiva"}
        buttonClose={false}
      >
        <CargaMasiva onClose={onCloseCarga} BulkLoad={BulkLoad} endpoint={environment.THIRD_PARTY_IMPORT} type={"Terceros"} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <ThirdSearchAdvanceForm
          setSearchAdvanced={setSearchAdvanced}
          onClose={onCloseSearch}
        />
      </ModalGeneric>

      <SectionHeader secTitle={"Terceros"}></SectionHeader>
      <div className="container p-4">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          setValues={setValues}
          valueQualified={valueQualified}
          allAccess={allAccess}
          myPermission={myPermission}
        />
      </div>
      <>
        {changeViewSearch ? (
          resData !== undefined && (
            <>
              <HeaderSearch>
                <ThirdPartiesHeader title={"Recientes"} mt="mt-3" >
                  {headerButtons()}
                </ThirdPartiesHeader>
                <TableOrder
                  thead={
                    authUser.admin === 1 || authUser.is_commerce === 1
                      ? theadSuperADMIN
                      : thead
                  }
                  data={recient}
                  isNum={false}
                  setData={setResData}
                  loading={loading}
                  tbodyData={TbodyThirdParties}
                />
              </HeaderSearch>
              <HeaderSearch>
                <TableOrder
                  thead={
                    authUser.admin === 1 || authUser.is_commerce === 1
                      ? theadSuperADMIN
                      : thead
                  }
                  data={currentPageTwo}
                  setData={setResData}
                  loading={loading}
                  tbodyData={TbodyThirdParties}
                />

                <Pagination
                  page={pageSearch}
                  setPage={setPageSearch}
                  maxPage={maxPageSearch}
                  loading={loading}
                />
              </HeaderSearch>
            </>
          )
        ) : (
          <>
            <HeaderSearch>
              <ThirdPartiesHeader title={"Recientes"} mt="mt-3">
                {/* aqui */}
                {headerButtons()}
              </ThirdPartiesHeader>

              <TableOrder
                thead={
                  authUser.admin === 1 || authUser.is_commerce === 1
                    ? theadSuperADMIN
                    : thead
                }
                data={recient}
                isNum={false}
                setData={setResData}
                loading={loading}
                tbodyData={TbodyThirdParties}
              />
            </HeaderSearch>
            <HeaderSearch>
              {/* <ThirdPartiesHeader title={"Operación"} mt="mt-5" /> */}
              <TableOrder
                thead={
                  authUser.admin === 1 || authUser.is_commerce === 1
                    ? theadSuperADMIN
                    : thead
                }
                data={resData}
                isNum={false}
                setData={setResData}
                loading={loading}
                tbodyData={TbodyThirdParties}
              />
              <Pagination
                thead={thead}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </HeaderSearch>
          </>
        )}
      </>
    </>
  );
};

export default ThirdParties;
