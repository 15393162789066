import { Text } from "@chakra-ui/react";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext } from "react";

import { NationalTerritoryOriginal } from "./container/NationalTerritoryOriginal";
import { RestOfTheWorldOriginal } from "./container/RestOfTheWorldOriginal";
import { UserToUserOriginal } from "./container/UserToUserOriginal";

export const CapsulaContainerOutput = ({ code, valuesHeader }) => {
  const { opcional, dataDUTA, opcionalCurrent, setOpcionalCurrent } =
    useContext(FormCrudCRUDContext);
  return (
    <div>
      <span>
        <Text as="sub">* Opcional</Text>
        <br />
      </span>
      <RadioCheck
        set={setOpcionalCurrent}
        value={opcionalCurrent}
        item={dataDUTA}
        disables="true"
        diff={opcionalCurrent !== opcional}
      />
      {/* 200 a 600 al resto del territorio nacional */}
      {code === 50 ? (
        <NationalTerritoryOriginal data={valuesHeader} />
      ) : code >= 200 && code <= 299 ? ( // code >= 201 && code <= 226 ?
        <RestOfTheWorldOriginal data={valuesHeader} />
      ) : code >= 401 && code <= 450
        ? (
          <NationalTerritoryOriginal data={valuesHeader} />
        )
        : code >= 600 && code <= 699
          ? (
            <RestOfTheWorldOriginal data={valuesHeader} />
          )
          : code >= 801 && code <= 814
            ? (
              <UserToUserOriginal data={valuesHeader} />
            )
            : (
              ""
            )}
    </div>
  );
};
