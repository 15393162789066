import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const RestOfTheWorldCorrect = ({
  data,
  handleChange,
  defaultDisabless
}) => {
  const {
    opcional,
    getAdminCustom,
    adminCustom,
    setCustomsadministration,
    customsadministration
  } = useContext(FormCrudCRUDContext);

  const {
    commercial_invoice_corrected,
    value_duta_corrected,
    commercial_invoice,
    value_duta,
    custom_code,
    custom_name
  } = data;

  const adminCustomer = custom_code + " " + custom_name;
  const location = useLocation();

  const { ShowOutput = "" } = queryString.parse(location.search);

  useEffect(() => {
    getAdminCustom();
  }, []);
  return (
    <>
      <LabelGroup
        name={"commercial_invoice_corrected"}
        value={commercial_invoice_corrected}
        tag={"Factura Comercial"}
        changeValue={
          commercial_invoice !== commercial_invoice_corrected && "BorderCorrect"
        }
        size={100}
        onChange={handleChange}
        desicion={defaultDisabless}
      />
      {opcional && (
        <LabelGroup
          name={"value_duta_corrected"}
          value={value_duta_corrected}
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          onChange={handleChange}
          tag={opcional}
          size={100}
          desicion={defaultDisabless}
        />
      )}

      {data.code_operationCorrected >= 201 &&
      data.code_operationCorrected <= 299 &&
      opcional &&
      !ShowOutput
        ? (
          <div>
            <InputSelect
              tag={"Administración de Aduanas"}
              data={adminCustom}
              set={setCustomsadministration}
              value={customsadministration}
              className={
                adminCustomer !== customsadministration?.label && "BorderCorrect"
              }
            />
          </div>
        )
        : (
          <LabelGroup
            value={customsadministration ? customsadministration.label : "N/A"}
            changeValue={
              adminCustom !== customsadministration?.label && "BorderCorrect"
            }
            onChange={handleChange}
            tag={"Administración de Aduanas"}
            size={100}
            desicion={ShowOutput}
          />
        )}
    </>
  );
};

/* import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { GetDate, Hours } from "hooks/HoursAndDate";
import {
  ADDLocalStorage,
  Colorcurrent,
  GetLocalStorage,
} from "hooks/useLocalStorage";
import {
  ModalComment,
  UpdateStatus,
} from "pages/Entry_and_exit_forms/FunctionsCheck/Functions";
import {
  LabelGroup,
  LabelGroupComment,
} from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const RestOfTheWorldCorrect = ({
  data,
  handleChange,
  defaultDisabless,
}) => {
  const {
    opcional,
    getAdminCustom,
    adminCustom,
    setCustomsadministration,
    customsadministration,
  } = useContext(FormCrudCRUDContext);
  const {
    commercial_invoice_corrected,
    value_duta_corrected,
    commercial_invoice,
    value_duta,
    reviews,
    custom_code,
    custom_name,
  } = data;
  const location = useLocation(); //navigate

  const { ShowOutput = "" } = queryString.parse(location.search);
  const Status = data.status_outputFormCorrected;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const FormId = data?.id;
  const [CurrentPos, setCurrentPos] = useState(0);
  let adminCustomer = custom_code + " " + custom_name;

  const [VerifiedStatus, setVerifiedStatus] = useState(
    GetLocalStorage(FormId).length !== 0 ? GetLocalStorage(FormId) : []
  );

  const TooltipComentary = (identifier) => {
    if (Status === "DEVUELTO" && reviews) {
      const FindElement = reviews.findIndex((element) => {
        return element?.form_field === identifier;
      });

      if (reviews[FindElement]) {
        return reviews[FindElement].comment;
      } else {
        console.log("No encontrado");
      }
    } else {
      return "";
    }
  };

  const CorrectionComment = (x) => {
    const { name, value, placeholder } = x.target;
    const initialComment = [
      {
        form_field: name,
        form_field_info: value.length === 0 ? "N/A" : value,
        comment: "",
        name: placeholder,
        date: GetDate(),
        time: Hours(),
        status: 0,
      },
    ];

    const FindElement = VerifiedStatus.findIndex((element) => {
      return element?.form_field === name;
    });

    if (GetLocalStorage(FormId).length === 0) {
      ADDLocalStorage(initialComment, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else if (FindElement === -1) {
      const Obj1 = GetLocalStorage(FormId);
      let NewObject = [];
      NewObject.push.apply(Obj1, initialComment);
      ADDLocalStorage(Obj1, FormId);
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    } else {
      UpdateStatus(
        GetLocalStorage(FormId),
        name,
        FormId,
        FindElement,
        onOpen,
        setCurrentPos,
        setVerifiedStatus
      );
    }
  };

  useEffect(() => {
    getAdminCustom();
  }, []);

  return (
    <>
      <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Comentario</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ModalComment
                obj={GetLocalStorage(FormId)}
                Pos={CurrentPos}
                FormId={FormId}
                onClose={onClose}
                setVerifiedStatus={setVerifiedStatus}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>

      {data.status_outputFormCorrected &&
      data.status_outputFormCorrected === "PRESENTADO" ? (
        <LabelGroupComment
          name={"commercial_invoice_corrected"}
          value={
            commercial_invoice_corrected ? commercial_invoice_corrected : "N/A"
          }
          tag={"Factura Comercial"}
          placeholder={"Factura Comercial"}
          size={100}
          onChange={handleChange}
          desicion={false}
          readOnly={true}
          ColorCustom={(x) =>
            commercial_invoice !== commercial_invoice_corrected &&
            Colorcurrent(x, FormId) === "gray"
              ? "purple"
              : Colorcurrent(x, FormId)
          }
          onClick={(x) => CorrectionComment(x)}
        />
      ) : (
        <LabelGroup
          name={"commercial_invoice_corrected"}
          label={TooltipComentary("commercial_invoice_corrected")}
          bg={"#900800"}
          value={
            commercial_invoice_corrected ? commercial_invoice_corrected : "N/A"
          }
          tag={"Factura Comercial"}
          changeValue={
            commercial_invoice !== commercial_invoice_corrected &&
            "BorderCorrect"
          }
          size={100}
          onChange={handleChange}
          desicion={false}
          readOnly={defaultDisabless}
        />
      )}
      {opcional && (
        <LabelGroup
          name={"value_duta_corrected"}
          value={value_duta_corrected ? value_duta_corrected : "N/A"}
          label={TooltipComentary("value_duta_corrected")}
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          onChange={handleChange}
          tag={opcional}
          size={100}
          desicion={defaultDisabless}
        />
      )}

      {data.code_operationCorrected >= 201 &&
      data.code_operationCorrected <= 299 &&
      opcional &&
      !ShowOutput ? (
        <div>
          <InputSelect
            tag={"Administración de Aduanas"}
            data={adminCustom}
            set={setCustomsadministration}
            value={customsadministration}
            className={
              adminCustomer !== customsadministration.label && "BorderCorrect"
            }
          />
        </div>
      ) : (
        <LabelGroup
          value={customsadministration ? customsadministration.label : "N/A"}
          changeValue={
            adminCustom !== customsadministration?.label && "BorderCorrect"
          }
          onChange={handleChange}
          tag={"Administración de Aduanas"}
          size={100}
          desicion={ShowOutput}
        />
      )}
    </>
  );
};
 */
