/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import { __SHOW_GESTION } from "request/GestionComercial/__GestionCo";
import { AuthContext } from "context/AuthContext";
import GestionCrudContext from "context/GestionCrudContext";
import { useSelector } from "react-redux";

const FormShow = () => {
  const { authUser } = useContext(AuthContext);

  const initialForm = {
    lastname_person: "",
    name_person: "",
    nickname: "",
    email: "",
    status: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(GestionCrudContext);
  const [status, setStatus] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);

  // console.log(dataToShow);

  useEffect(() => {
    if (dataToShow) {
      const initialpos = async () => {
        try {
          const res = await __SHOW_GESTION(token, dataToShow.id);
          setForm(res?.data?.status?.data);
          setStatus(res?.data?.status?.data.status_permissionGroup);
        } catch (error) {}
      };
      initialpos();

      setForm(dataToShow);
    } else {
      setForm(initialForm);
      // console.log(dataToShow);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.nickname : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Usuario</LabeFormlStyled>
                <InputFake>{form.nickname}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.name_person}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Apellido</LabeFormlStyled>
                <InputFake>{form.lastname_person}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Correo</LabeFormlStyled>
                <InputFake>{form.email}</InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Estado</LabeFormlStyled>
              <InputFake>{form.status === 1 ? "Activo" : "Inactivo"}</InputFake>
            </FormInputGroup>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormShow;
