import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiOutlineInformationCircle } from "react-icons/hi";
import TreasuryCrudContex from "context/TreasuryContex";
import { NavLink, useNavigate } from "react-router-dom";

const TbodyWeigh = ({ data }) => {
  const { setDataContainer, onOpenContainer, setDataFroms, onOpenForms } =
    useContext(TreasuryCrudContex);
  const navigate = useNavigate();

  const hanldeShow = () => {
    setDataContainer(data?.containers);
    onOpenContainer();
  };

  const hanldeShowForm = () => {
    setDataFroms(data?.formularios);
    onOpenForms();
  };

  return (
    <>
      {data && (
        <Tr>
          <Td className="text-center">
            <NavLink
              to={`/ShowWeighing?weighing=${data?.id}&Operation=${data?.operation}`}
              className="ms-2"
            >
              <span className="formNumber">{data?.consecutive}</span>
            </NavLink>
          </Td>
          <Td className="text-center">{data?.weight_date}</Td>
          <Td className="text-center">{data?.vehicle_plate}</Td>
          <Td className="text-center">
            {data?.shedule ? data?.shedule : "N/A"}
          </Td>
          <Td className="text-center">{data?.operation}</Td>
          <Td className="text-center">{data?.value}</Td>
          <Td className="text-center">
            {data?.net_weight ? data?.net_weight : "N/A"}
          </Td>
          <Td className="text-center">{data?.gross_weight}</Td>
          <Td className="text-center">{data?.userManagment}</Td>
          <Td className="text-center">
            {data?.tare_weight ? data?.tare_weight : "N/A"}
          </Td>
          <Td className="text-center">
            {data?.tare_scale_name ? data?.tare_scale_name : "N/A"}
          </Td>
          <Td className="text-center">{data?.completeness}</Td>
          <Td className="text-center">
            {data?.containers && data?.containers?.length > 0
              ? (
                <IconGeneric
                  onClick={hanldeShow}
                  as={HiOutlineInformationCircle}
                />
              )
              : (
                <IconGeneric color="#bababa" as={HiOutlineInformationCircle} />
              )}
          </Td>

          {data?.formularios?.length
            ? (
              <Td className="text-center">
                {data && data?.formularios?.length > 1
                  ? (
                    <IconGeneric
                      onClick={hanldeShowForm}
                      as={HiOutlineInformationCircle}
                    />
                  )
                  : (
                    data?.formularios[0]?.code_entryForm
                  )}
              </Td>
            )
            : (
              <Td className="text-center">{"N/A"}</Td>
            )}
        </Tr>
      )}
    </>
  );
};

export default TbodyWeigh;
