/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import { useModal } from "hooks/useModal";
import CreateTemplate from "pages/RefProductInter/views/CreateTemplate";
import { ModalAdd } from "./ModalAdd";
import SectionHeader from "components/SectionHeader/SectionHeader";
import TableOrder from "components/Tables/TableOrder";
import ProductoInterSubtitle from "./ProductoInterSubtitle";
import TbodyTemplate from "./tables/TbodyTemplate";
import { __Plantillas } from "request/productoInterno/__Plantillas";
import { __ProductoInterno } from "request/productoInterno/__ProductoInterno";
import { AuthContext } from "context/AuthContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import environment from "constants/apiConst";
import ProductCRUDContext from "context/ProductCRUDContext";
import TbodyProduct from "components/Tables/TbodyProduct";
// import para filtro de busqueda en submodulos de producto interno
import { ModalGeneric } from "components/modal/ModalGeneric";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { FormSearchProducts } from "./form/FormSearchProducts";
import { FormSearchPrdProcess } from "./form/FormSearchPrdProcess";
import { FormSearchUnits } from "./form/FormSearchUnits";

import {
  TheadConv,
  TheadProduct,
  theadTemplate,
  TitleUnidades,
  TitleUnidadesUC,
} from "./views/EditArray";
import Pagination from "components/Pagination/Pagination";
import ModalDelete from "components/modal/ModalDelete";
import ModalTransition from "components/modal/ModalTransition";
import FormUnitMeasurement from "pages/Configuration/forms/UnitMeasurement";
import FormUnitView from "pages/Configuration/FormShow/FormUnitView";
import { __AUnidad } from "request/configuration/_AUnidad";
import TbodyUnidad from "pages/Configuration/tables/TbodyUnidad";
import HeaderUnit from "./HeaderUnit";
import { useSelector, useDispatch } from "react-redux";
import { __BulkLoad } from "request/Pases/__CrudPass";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

import { useForm } from "hooks/useForm";
import { __Post, __PostForm } from "request/Petitions/__Post";
import {
  __SearchAdvancedProductInt,
  __SearchAdvancedProductiveProcess,
  __SearchAdvancedUnit,
} from "request/productoInterno/__SearchAdvanced";
import FormCrudCRUDContext from "context/FormCrudContex";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import {
  arrayProductNodeList,
  LeftdataFields,
  productiveProcessNodeInputFields,
  productsNodeInputFields,
  RightdataFields,
} from "pages/Treasury/ArrayList/ArrayList";
import { QualifiedUser } from "./QualifiedUser";
import { ButtonStyled } from "styled/Buttons.styled";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import { CargaMasiva } from "pages/Terceros/carga masiva/CargaMasiva";
import isArrayNotEmpty, {
  cleanObject,
  handleSanitizedObj,
  isArray,
  isObject,
  isObjectNotEmpty,
  isValid,
  safelyConvertToString,
} from "utils/type-check-utils";
import ExportAdvance, {
  ModalExportAdvance,
} from "components/ExportByColumn/ExportAdvance";
import { clearSearch, getSearch, setSearch } from "redux/actions/searchActions";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

//

export const PreductoInterno = () => {
  const location = useLocation();
  const { q = "", movement = "" } = queryString.parse(location.search);
  const dispacthRedux = useDispatch();

  const valuesSearchAdvance = {
    Productos: [
      "description",
      "subheading",
      "unitOfMeasurement",
      "conversionFactor",
      "group",
    ],
    "Proceso productivo": ["code", "description"],
    "Unidades de medida": ["code", "unit"],
  }[movement];
  // const { cleanState, clearParamsSearch } = useSearchUrl({ getParams: valuesSearchAdvance, nestedProp: "searchAdvance" })
  const { state, clearParamsSearch } = useSearchUrl({
    getParams: ["search", "valueQualified"],
    nestedProp: "searchSimple",
  });
  const { currentSearch } = useSearchUrl({
    getParams: valuesSearchAdvance,
    nestedProp: "searchAdvance",
  });
  const { search, valueQualified } = state || {};

  const memoizedDataToSearch = useMemo(() => {
    const {
      typeSearch: _,
      countryBuy,
      countryOrigin,
      countryProcedency,
      datosBy,
      flag,
      operationT,
      sortBy,
      stateDispacthOrPass,
      ...rest
    } = currentSearch;

    const searchInfo = {
      ...(search && { search }),
      ...(isArray(valueQualified) && valueQualified?.length !== 0
        ? { qualifiedUser_id: valueQualified?.map((item) => item?.value) }
        : valueQualified?.value && { qualifiedUser_id: valueQualified?.value }),
      ...rest,
    }
    const cleanObj =  cleanObject(searchInfo)
    return cleanObj
  }, [valueQualified, search, currentSearch]);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    setSearchAdvanced([]);
    dispacthRedux(clearSearch());
  };

  const {
    editData,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    isOpenDelete,
    isOpenEdit,
    onCloseModal,
    dataToDelete,
    setFormEdit,
    formEdit,
    formShow,
    isOpenShow,
    onCloseShow,
    setFormShow,
    deleteData,
    update,
    setUpdate,
    onCloseCarga,
  } = useContext(ProductCRUDContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenModalNP, openModalNP, closeModalNP] = useModal();
  const [btHeader, setBtHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const { user_rol, admin, freeZone_id } = useSelector(
    (state) => state.auth.dataUser
  );
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const { requestData } = useGlobalContext();

  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const {
    setUserQuialified: setUserQualifiedContext,
    userQualified: userQualifiedContext,
    exportColumnBy,
  } = useContext(FormCrudCRUDContext);
  const [searcheAdvanced, setSearchAdvanced] = useState([]);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const {
    isOpen: isOpenProdProcess,
    onOpen: onOpenProdProcess,
    onClose: onCloseProdProcess,
  } = useDisclosure();

  useEffect(() => {
    if (
      isValid(valueQualified) &&
      userQualifiedContext?.value !== valueQualified?.value
    ) {
      setUserQualifiedContext(valueQualified);
      return;
    }
  }, [valueQualified]);

  const formEditart = () => {
    return <ModalAdd />;
  };

  const formEditSub = () => {
    return <FormUnitMeasurement />;
  };

  const formularioview = () => {
    return <FormUnitView />;
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const BulkLoad = async (data) => {
    try {
      const res = await __BulkLoad(environment.IMPORT_PRODUCT_INT, token, data);

      const respuestaAttachment = res.data.status;
      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
        setUpdate(!update);
        onCloseCarga();
      } else {
        openAlert(`${respuestaAttachment.message}`, "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const apiUrl =
    {
      Productos: environment.GET_PRODUCT_INT,
      "Proceso productivo": environment.GET_PROCESO_ALL,
      "Unidades de medida": environment.UNIT_GET,
    }[movement] || environment.GET_PRODUCT_INT;

  const dispatchRedux = useDispatch();

  const { data: dataInventory, isLoading } = useQuery({
    queryKey: [
      "product-int",
      page,
      update,
      movement,
      search,
      valueQualified,
      searcheAdvanced,
    ],
    queryFn: () =>
      requestData({
        Endpoint: apiUrl,
        page,
        method: "POST",
        data: memoizedDataToSearch,
      }),
    // enabled: Object.entries(memoizedDataToSearch).length !== 0
  });

  useEffect(() => {
    setBtHeader(movement === "Productos");

    if (movement === "Productos") {
      setFormEdit(formEditart);
      setRequestEdit(environment.UPDATE_PRODUCT_INT);
      setRequestCreate(environment.CREATE_PRODUCT_INT);
      setRequestDelete(environment.DELETE_PRODUCT_INT);
    }

    if (movement === "Proceso productivo") {
      setRequestEdit(environment.UPDATE_PROCESO);
      setRequestCreate(environment.CREATE_PROCESO);
      setRequestDelete(environment.DELTE_PROCESO);
    }

    if (movement === "Unidades de medida") {
      setFormShow(formularioview);
      setFormEdit(formEditSub);
      setRequestEdit(environment.UNIT_UPDATE);
      setRequestCreate(environment.UNIT_CREATE);
      setRequestDelete(environment.UNIT_DELETE);
    }

    return () => {
      dispatchRedux(clearSearch());
      setPage(1);
    };
  }, [movement]);

  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      ...(data?.status_product && {
        status_product: data?.status_product?.value,
      }),
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value,
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: isArray(data?.qualifiedUser_id)
          ? data?.qualifiedUser_id?.map((item) => item?.value || item)
          : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
          ? data?.qualifiedUser_id?.value
          : data?.qualifiedUser_id || [],
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || [],
      }),
      fields: formulario,
    };

    exportColumnBy(info, onCloseExport);
  };

  const ExportExcelProductiveProcess = async (data) => {
    try {
      const info = {
        ...data,
        type_export_file: "xlsx",
        ...(data?.type_export_file && {
          type_export_file: data?.type_export_file?.value,
        }),
        ...(data?.qualifiedUser_id && {
          qualifiedUser_id: isArray(data?.qualifiedUser_id)
            ? data?.qualifiedUser_id?.map((item) => item?.value || item)
            : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
            ? data?.qualifiedUser_id?.value
            : data?.qualifiedUser_id || [],
        }),
        ...(data?.status && {
          status: isArray(data?.status)
            ? data?.status?.map((item) => item?.value || item)
            : data?.status?.value || [],
        }),
      };
      const res = await __Post(
        environment.EXPORT_PRODUCTIVE_PROCESS,
        token,
        info
      );
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        /* let url = res?.data;
        window.location.assign(url); */
        return onCloseProdProcess();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const ExportExcelUnidadMedida = async (onCloseExport) => {
    try {
      const res = await __Post(environment.PROCESO_EXPORT, token, search);
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        /* let url = res?.data;
        window.location.assign(url); */
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const ExportService = () => {
    if (movement === "Proceso productivo") {
      if (authUser.admin || authUser.is_commerce) {
        return onOpenProdProcess();
      } else {
        return ExportExcelProductiveProcess();
      }
    }

    onOpenExport();
  };

  const {
    isOpen: isOpenProdLoad,
    onOpen: onOpenProdLoad,
    onClose: onCloseProdLoad,
  } = useDisclosure();

  const BulkLoadProd = async (data) => {
    try {
      const res = await __BulkLoad(environment.PROCESO_IMPORT, token, data);
      const respuestaAttachment = res.data.status;

      if (respuestaAttachment.code === 200) {
        openAlert(`${respuestaAttachment.data}`, "success");
        setUpdate(!update);
        return onCloseCarga();
      }
      openAlert(`${respuestaAttachment?.message}`, "error");
    } catch (error) {
      return openAlert(
        `Por favor verifique que se esté ingresando la plantilla correcta`,
        "error"
      );
    } finally {
      onClose();
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenProdLoad}
        onOpen={onOpenProdLoad}
        onClose={onCloseProdLoad}
        title={"Carga Excel"}
        buttonClose={false}
      >
        <CargaMasiva
          onClose={onCloseProdLoad}
          BulkLoad={BulkLoadProd}
          endpoint={environment.PROCESO_IMPORT}
          type={"Unidades"}
        />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenProdProcess}
        onOpen={onOpenProdProcess}
        onClose={onCloseProdProcess}
        title={"Exportar documento"}
        size={"2xl"}
        scrollBehavior={"outside"}
        selectNodeList={productiveProcessNodeInputFields}
        handleSubmit={ExportExcelProductiveProcess}
      />

      {/*  <ModalGeneric
        isOpen={isOpenProdProcess}
        onOpen={onOpenProdProcess}
        onClose={onCloseProdProcess}
        title={'Exportar documento'}
        size={'2xl'}
        scrollBehavior={'outside'}
      >
        <QualifiedUser set={setUseQualified} get={useQualified} defaultValue={isArray(userQualified) && userQualified?.length !== 0 ?
          userQualified : userQualified?.value && userQualified?.value} />

        <Flex className='my-3'>
          <ButtonStyled bgColor={'#bababa'} onClick={onCloseProdProcess}>
            Cerrar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={'#03C39A'}
            type='submit'
            onClick={() => ExportExcelProductiveProcess(onCloseProdProcess)}
          >
            Exportar
          </ButtonStyled>
        </Flex>
      </ModalGeneric> */}

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title={"Exportar documento"}
        size={"4xl"}
        nodeList={arrayProductNodeList}
        handleSubmit={handleSubmit}
        selectNodeList={productsNodeInputFields}
        enableAdvance={true}
      />

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento ${
          dataToDelete?.code_product || dataToDelete?.id
        }`}
        onDelete={deleteData}
      />

      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"xl"}
        modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={"outside"}
      >
        {formEdit}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenShow}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Ver información"}
        handleClick={onCloseShow}
        buttonMain={"Aceptar"}
      >
        {formShow}
      </ModalTransition>

      <CreateTemplate isOpen={isOpenModalNP} onClose={closeModalNP} />
      {/* Se usa el componente SearchAdvanceModule y el modal generico
         para visualizar el formulario dependiendo del submòdulo */}

      <div className="container p-4">
        <SearchAdvancedModule
          onOpenSearch={onOpen}
          changeViewSearch={Object.entries(memoizedDataToSearch)?.length !== 0}
          backSearch={backSearch}
          allAccess={allAccess}
          myPermission={myPermission}
          filterActive={user_rol?.modules?.some((item) =>
            item?.permission?.some(
              (name) => name?.name_permission === "getProducts"
            )
          )} // verifica si tiene el permiso
          MultipleQualifiedUsers={
            movement === "Corregido de salida" ? "multi" : "first"
          }
          viewButtonFilter={true}
          movement={movement}
        />
      </div>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Búsqueda avanzada"
      >
        {movement === "Productos" ? (
          <FormSearchProducts
            setSearchAdvanced={setSearchAdvanced}
            onClose={onClose}
          />
        ) : movement === "Proceso productivo" ? (
          <FormSearchPrdProcess
            setSearchAdvanced={setSearchAdvanced}
            onClose={onClose}
          />
        ) : movement === "Unidades de medida" ? (
          <FormSearchUnits
            setSearchAdvanced={setSearchAdvanced}
            onClose={onClose}
          />
        ) : (
          ""
        )}
      </ModalGeneric>
      {movement === "Productos" || movement === "Proceso productivo" ? (
        <ProductoInterSubtitle
          title={
            movement === "Productos"
              ? "Todas las referencias"
              : "Todas las plantillas"
          }
          openModalNP={openModalNP}
          state={btHeader}
          setCreate={setRequestCreate}
          allAccess={allAccess}
          myPermission={myPermission}
          BulkLoad={BulkLoad}
          onOpenExport={ExportService}
          onOpenLoadExcel={onOpenProdLoad}
        />
      ) : movement === "Unidades de medida" ||
        allAccess === true ||
        myPermission?.includes("createUnitMeasurementQualifiedUser") ? (
        <HeaderUnit
          title={"Unidades de medida"}
          activeSeach={false}
          exportOn={ExportExcelUnidadMedida}
          onOpenLoadExcel={onOpenProdLoad}
        />
      ) : (
        ""
      )}

      {movement === "Productos"
        ? loading || (
            <TableOrder
              thead={
                authUser.admin === 1 || authUser.is_commerce === 1
                  ? TheadProduct
                  : TheadConv
              }
              data={dataInventory?.data?.status?.data?.data || []}
              setData={() => {}}
              loading={isLoading}
              tbodyData={TbodyProduct}
            />
          )
        : movement === "Proceso productivo"
        ? loading || (
            <TableOrder
              thead={theadTemplate}
              data={dataInventory?.data?.status?.data?.data || []}
              isNum={false}
              setData={() => {}}
              loading={isLoading}
              tbodyData={TbodyTemplate}
            />
          )
        : movement === "Unidades de medida"
        ? loading || (
            <TableOrder
              thead={
                authUser.admin === 1 || authUser.is_commerce === 1
                  ? TitleUnidades
                  : TitleUnidadesUC
              }
              data={dataInventory?.data?.status?.data?.data || []}
              setData={() => {}}
              loading={isLoading}
              tbodyData={TbodyUnidad}
            />
          )
        : ""}
      {changeViewSearch && currentPage !== undefined ? (
        <Pagination
          page={pageSearch}
          setPage={setPageSearch}
          maxPage={maxPageSearch}
          loading={isLoading}
        />
      ) : (
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={dataInventory?.data?.status?.data?.last_page}
          loading={isLoading}
        />
      )}
    </>
  );
};
