/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import { InventoryControlProvider } from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetReferenciaBalance
} from "request/Inventory/__InventoryCRUD";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  arrayLeftAuxRef,
  arrayRigthAuxRef,
  arrayRigthUserAuxRef,
  inventoryNodeInputFields
} from "./Array/ArrayExport";
import { theadReferenceAssistant } from "./Array/ArrayInventoryControl";
import { FormAuxBalance } from "./SearchTabInventory/FormAuxBalance";
import { TbodyReferenceAssistant } from "./TbodyTable/TbodyReferenceAssistant";
import { useDebounce } from "hooks/useDebouce";
import GlobalContext from "context/GlobalContext";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useDispatch } from "react-redux";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

export const AuxBalance = () => {
  const { cleanState, clearParamsSearch } = useSearchUrl({ getParams: ["reference", "date_final", "date_initial"], nestedProp: "searchAdvance" });
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [totalReference, setTotalReference] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  const tdTotal = [1, 2, 3, 4, 5, 6];

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: null,
  });

  const { searchInformacion, valueQualified } = values;

  const Searchdebounced = useDebounce(searchInformacion, 700);

  const GetBalanceAux = async (body) => {
    setLoading(true);
    try {
      const res = await __GetReferenciaBalance(token, page, body);
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (res?.data?.status?.data?.data?.length !== 0) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        const totales = res?.data?.status?.data?.data.length;
        setTotalReference(res?.data?.status?.data?.data[totales - 1]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };
  const { setUser_Qualified } = useContext(GlobalContext);

  const dispacthRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified && searcheAdvanced?.reference && {
        qualifiedUser_id: isArray(valueQualified) ? valueQualified[0].value : valueQualified?.value,
      }),
    };
    setUser_Qualified(valueQualified?.value || "");

    if (Object.values(data)?.length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      GetBalanceAux(data, page);
      dispacthRedux(setSearch(data));
      return;
    }
    dispacthRedux(clearSearch());
  }, [Searchdebounced, searcheAdvanced, page, valueQualified, freeZoneValue]);

  useEffect(() => {
    if (!searcheAdvanced?.reference && valueQualified?.value) return openAlert("El campo de la referencia es requerido", "error");
  }, [searcheAdvanced?.reference, valueQualified?.value]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, [])

  useEffect(() => {
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);

  const backSearch = () => {
    reset();
    clearParamsSearch({ searchArray: ["searchAdvance", "searchSimple"] });
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    setTotalReference(null);
    setCurrentPage([]);
  };

  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: data?.qualifiedUser_id[0]?.value || data?.qualifiedUser_id?.value || data?.qualifiedUser_id
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    exportColumnBy(info);
  };


  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_AUXILIAR_BALANCE
      );
      if (res.status === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");

    } catch (error) {
      console.log(error);
    }
  };

  const nodeList = {
    1: arrayLeftAuxRef?.concat(arrayRigthUserAuxRef),
    0: arrayLeftAuxRef?.concat(arrayRigthAuxRef),
  }[authUser?.is_qualified_user]

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={nodeList}
        selectNodeList={inventoryNodeInputFields}
        enableAdvance={true}
      />

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <InventoryControlProvider>
          <FormAuxBalance
            onClose={onCloseSearch}
            handleSearchSubmit={setSearchAdvanced}
          />
        </InventoryControlProvider>
      </ModalGeneric>

      <HeaderSearch>
        <Title>Auxiliar saldo</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getInventoryRefAux"}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />

        {allAccess
          ? (
            <ExportDetail onOpen={onOpenExport} />
          )
          : myPermission?.includes("exportExcelInventoryRefAux")
            ? (
              <ExportDetail onOpen={onOpenExport} />
            )
            : (
              ""
            )}
        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadReferenceAssistant}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyReferenceAssistant}
              subTotals={totalReference}
              titleTotal="Total"
              td={tdTotal}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
