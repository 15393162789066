import axios from "axios";
import env from "../../constants/apiConst";

export const __CountUser = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };
  const res = await axios(`${env.GET_USER_ACCES}?page=${page || 1}`, options);
  return res;
};

export const __SHOW_COUNT_USER = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.SHOW_USER_ACCES}/${id}`, options);
  return res;
};

export const __ACT_COUNT_USER = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ACT_USER_ACCES}/${id}`, options);
  return res;
};

export const __DESAC_COUNT_USER = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.INAC_USER_ACCES}/${id}`, options);
  return res;
};
