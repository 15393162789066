/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import InputSelect, { InputSelectMulti } from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __TypeTransportNp } from "request/Pases/__CrudPass";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  associatedSecurity,
  dateBy,
  statePassOrDispacth,
  // tidy,
  weighing
} from "./ArraySelect";
import GlobalContext from "context/GlobalContext";
import { isArray, isNonEmptyString, isValid } from "utils/type-check-utils";
import { useSearchUrl } from "hooks/useSearchUrl";

export const FormAdvanced = ({ onClose, setSearchAdvanced }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["plate", "identification", "observations", "formNumber", "container", "endDate", "endTime", "startDate", "startTime", "commercial_invoice", "transport_document", "operation_cod", "associated_form", "freelyAvailable", "associated_security","loaded",'dateFrom', "stateDispacthOrPass", "datosBy", "associated", "typeTransporSelec", "user_Qualified", "weighin"], nestedProp: "searchAdvance" });
  const [datosBy, setDatosBy] = useState(state?.datosBy);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(state?.stateDispacthOrPass);
  const [weighin, setWeighin] = useState(state?.weighin);
  const [typeTransporSelec, setTypeTransporSelec] = useState(state?.typeTransporSelec);
  const [selectDataType, setSelectDataType] = useState([]);
  const [freelyAvailable, setFreelyAvailable] = useState(state?.freelyAvailable);
  const [loaded, setLoaded] = useState(state?.loaded);
  const [associated, setAssociated] = useState(state?.associated);
  // const [tidiBy, setTidiBy] = useState(null);
  // const [descending, setDescending] = useState(null);
  /*  */
  const [valueFormNumber, setValueFormNumber] = useState(state?.formNumber);
  const [valueCedula, setValueCedula] = useState(state?.identification);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    TypeTransporte();
  }, []);

  const TypeTransporte = async () => {
    try {
      const res = await __TypeTransportNp(token);
      const selUQ = res?.data?.status?.data.map((item) => ({
        value: item.id,
        label: item.name_typeTransport
      }));
      setSelectDataType(selUQ);
    } catch (error) { }
  };
  const [values, handleInputChange, reset, setValues] = useForm({
    plate: state?.plate,
    identification: state?.identification,
    observations: state?.observations,
    formNumber: state?.formNumber,
    container: state?.container,
    endDate: state?.endDate,
    endTime: state?.endTime,
    startDate: state?.startDate,
    startTime: state?.startTime,
    commercial_invoice: state?.commercial_invoice,
    transport_document: state?.transport_document,
    operation_cod: state?.operation_cod,
    associated_form: state?.associated_form
  });

  const {
    plate,
    observations,
    container,
    startDate,
    endDate,
    startTime,
    endTime,
    commercial_invoice,
    transport_document,
    operation_cod,
    associated_form
  } = values;

  const onlyNumber = (e) => {
    const posibleResult = e.target.value
      .replace(/[^0-9,.]/g, "")
      .replace(/,/g, ".");
    // console.log(e.target.name);
    if (e.target.name === "valueFormNumber") {
      setValueFormNumber(posibleResult);
    } else {
      setValueCedula(posibleResult);
    }
  };


  const handleSubmit = () => {
    const datosSend = {
      ...values,
      identification: valueCedula,
      formNumber: valueFormNumber,
      freelyAvailable: freelyAvailable,
      loaded: loaded,
      ...(stateDispacthOrPass && {
        status: isArray(stateDispacthOrPass) ? stateDispacthOrPass?.map(item => item?.value) : stateDispacthOrPass?.value ?? ''
      }),
      dateFrom: datosBy?.value ?? "",
      associated_security:  associated?.value ?? "",
      typeTransport: typeTransporSelec ? typeTransporSelec.label : "",
      weighing: weighin ? weighin.value : "",

      // typeOrder_by: descending === 1 ? "ASC" : "DESC",
      // order_by: tidiBy ? tidiBy.value : "",
    };

    const sanitizePayload = Object.keys(datosSend).reduce((acc, curr) => {
      if (isValid(datosSend[curr]) && datosSend[curr] !== "") {
        acc[curr] = datosSend[curr];
      }
      return acc;
    }, {});

    const selectsPayload = Object.entries({
      weighin,
      typeTransporSelec,
      associated,
      datosBy,
      stateDispacthOrPass
    }).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    setParamsSearch({
      params: {
        ...sanitizePayload,
        ...selectsPayload,
      },
    });
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"valueFormNumber"}
          value={valueFormNumber}
          onChange={onlyNumber}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
        <LabelGroup
          name={"associated_form"}
          value={associated_form}
          onChange={handleInputChange}
          tag={"Formulario asociado"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"startDate"}
          value={startDate}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"endDate"}
          value={endDate}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"startTime"}
          value={startTime}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"endTime"}
          value={endTime}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Fecha de:"}
            data={dateBy}
            value={datosBy}
            set={setDatosBy}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            name={"state_dispacth"}
            tag={"Estado"}
            data={statePassOrDispacth}
            value={stateDispacthOrPass}
            set={setStateDispacthOrPass}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"plate"}
          value={plate?.replace(/[^a-zA-Z0-9ñÑ\s]/g, "")}
          onChange={handleInputChange}
          tag={"Placa del vehículo"}
          size={45}
          tipo={"text"}
          maxLength={"10"}
        />
        <LabelGroup
          name={"valueCedula"}
          value={valueCedula}
          onChange={onlyNumber}
          tag={"Cédula del conductor"}
          size={45}
          tipo={"text"}
          maxLength={"11"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Con Pesaje"}
            data={weighing}
            value={weighin}
            set={setWeighin}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Tipo de transporte"}
            data={selectDataType}
            value={typeTransporSelec}
            set={setTypeTransporSelec}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"container"}
          value={container}
          onChange={handleInputChange}
          tag={"Contenedor"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"observations"}
          value={observations}
          onChange={handleInputChange}
          tag={"Observación"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"commercial_invoice"}
          value={commercial_invoice}
          onChange={handleInputChange}
          tag={"Factura comercial"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"transport_document"}
          value={transport_document}
          onChange={handleInputChange}
          tag={"Doc. Transporte"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"operation_cod"}
          value={operation_cod}
          onChange={handleInputChange}
          tag={"Cod. Operación"}
          size={45}
          tipo={"text"}
        />
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"associated_security"}
            tag={"Asociado a seguridad"}
            data={associatedSecurity}
            value={associated}
            set={setAssociated}
          />
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Ordenar por:"}
            data={tidy}
            value={tidiBy}
            set={setTidiBy}
          />
        </div>
      </div> */}
      <div className="d-flex justify-content-start align-items-center">
        <SwitchPropiosMedios
          status={freelyAvailable}
          setStatus={setFreelyAvailable}
          tag={"Libre disposición"}
         // onChange={handleInputChange}
        />
        <SwitchPropiosMedios
          status={loaded}
          setStatus={setLoaded}
          tag={"Cargado"}
         // onChange={handleInputChange}
        />
        {/* <SwitchPropiosMedios
          status={descending}
          setStatus={setDescending}
          tag={"Descendentes/Ascendentes"}
        /> */}
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
