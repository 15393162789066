import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import DianCRUDContext from "context/DianCrudContext";
import { useDebounce } from "hooks/useDebouce";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { TitleAnexed } from "pages/Dian/ArrayTitleDian/ArrayTitle";
import TbodyInteroperability from "pages/Dian/Table/TbodyInteroperability";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __PostFormData, __PostJsonData, __PostJsonDataPage } from "request/Petitions/__Post";
import { HeaderSearch } from "styled/SectionContainer.styled";

const AnexedView = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { update } = useContext(DianCRUDContext);
  const { freeZoneValue } = useSelector(state => state.chagenFreeZone)
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search,
    valueQualified: state?.valueQualified,
  });
  const { searchInformacion, valueQualified } = values;
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [changeViewSearchSimple, setChangeViewSearchSimple] = useState(false);
  const [openAlert] = AlertErrorAlert();

  const GetDocumentToProfile = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __PostJsonDataPage(environment.GET_INTEROPERABILITY, token);
      const requestData = res?.data?.status?.data.data;
      console.log(requestData);
      if (requestData.length !== 0) {
        setCurrentPage(requestData);
      } else {
        setCurrentPage([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);


  useEffect(() => {
    const data = {
      ...(valueQualified && {
        qualifiedUser_id: valueQualified?.value,
      }),
      ...(searchInformacion && {
        search: searchInformacion,
      })
    }

    if (Object.entries(data).length !== 0) {
      setLoading(true);
      setChangeViewSearchSimple(true);
      searchSimple(data);
      return; 
    }
    setChangeViewSearch(false);
    GetDocumentToProfile();

  }, [searchInformacion, page, valueQualified, freeZoneValue, update]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(environment.GET_INTEROPERABILITY, token, informacion, page);
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setChangeViewSearchSimple(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        console.log(res?.data?.status?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    setChangeViewSearch(false);
    setChangeViewSearchSimple(false);
    GetDocumentToProfile()
  };

  return (
    <HeaderSearch>

      <SearchAdvancedModule
        handleInputChange={handleInputChange}
        values={values}
        changeViewSearch={changeViewSearch}
        backSearch={backSearch}
        changeViewSearchSimple={changeViewSearchSimple}
        setValues={setValues}
      />

      <TableOrder
        thead={TitleAnexed}
        data={currentPage}
        setData={setCurrentPage}
        loading={loading}
        tbodyData={TbodyInteroperability}
      />
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        loading={loading}
      />
    </HeaderSearch>
  );
};

export default AnexedView;
