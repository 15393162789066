import React from "react";
import { FormCorrectFreeZone } from "./containerCorrect/FormCorrectFreeZone";
import { FormCorrectFromUserToUser } from "./containerCorrect/FormCorrectFromUserToUser";
import { FormCorrectionRestTerritory } from "./containerCorrect/FormCorrectionRestTerritory";
import { FormCorrectionRestWorld } from "./containerCorrect/FormCorrectionRestWorld";

export const CapsulaContainer = ({ code, form, valuesCorrect }) => {
  return (
    <>
      {" "}
      {code >= 100 && code < 199 ? ( // code >= 101 && code < 120
        <FormCorrectionRestWorld
          data={form}
          code={code}
          dataCorrect={valuesCorrect}
        />
      ) : code >= 300 && code <= 399 ? ( // code >= 301 && code <= 330
        <FormCorrectionRestTerritory
          data={form}
          code={code}
          dataCorrect={valuesCorrect}
        />
      ) : code >= 500 && code <= 599 ? ( // code >= 501 && code <= 518
        <FormCorrectFreeZone data={form} dataCorrect={valuesCorrect} />
      ) : code >= 700 && code <= 799 ? ( // code >= 701 && code <= 714
        <FormCorrectFromUserToUser data={form} dataCorrect={valuesCorrect} />
      ) : (
        ""
      )}
    </>
  );
};
