import { Td, Tr } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useSelector } from "react-redux";

const TbodyOperationsMonitoring = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.code_operation ? data?.code_operation : "N/A"}</Td>
        <Td className="text-center">{data?.short_name_operation ? data?.short_name_operation : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TbodyOperationsMonitoring;
