/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import InputSelect, {
  InputSelectZindex
} from "components/InputSelect/InputSelect";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import OrderProductionContext from "context/OrderProductionContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { Cancelar, Continuar } from "pages/RefProductInter/Stilos";
import React, { useContext, useState } from "react";

export const FormMatterAnd = () => {
  const [productSelecc, setProductSelecc] = useState([]);

  const { loading, onCloseModal, dataProducts } = useContext(
    OrderProductionContext
  );
  const [values, handleInputChange, reset, setValues] = useForm({
    product_quantity: ""
  });

  const { product_quantity } = values;

  return (
    <>
      {" "}
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
            className="mb-5"
          >
            <div style={{ width: `45%` }}>
              <InputSelectZindex
                name={"date_by"}
                tag={"Código producto"}
                data={dataProducts}
                value={productSelecc}
                set={setProductSelecc}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
            className="mb-5"
          >
            <LabelGroup
              name={"product_quantity"}
              value={product_quantity}
              onChange={handleInputChange}
              tag={"Cantidad de producto"}
              size={98}
              tipo={"text"}
            />
          </div>

          <Flex className="m-3">
            <Cancelar onClick={onCloseModal}>Cancelar</Cancelar>
            <Spacer />
            <Continuar
            // onClick={createValidate}
            >
              Aceptar
            </Continuar>
          </Flex>
        </>
      )}
    </>
  );
};
