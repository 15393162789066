/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Input, ScaleFade } from "@chakra-ui/react";
import AlertError from "components/Alerts/AlertError";
import AlertSuccess from "components/Alerts/AlertSuccess";
import PasswordField from "components/PasswordField/PasswordField";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CardButton } from "styled/Buttons.styled";
import { InputsContainer, StyledLink } from "../../Auth.styled";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useModal } from "hooks/useModal";
import ModalTransition from "components/modal/ModalTransition";
import TermsAndCondition from "../TermsAndConditions.js/TermsAndCondition";
import { SpanLikeLink } from "styled/Span.styled";
import { __Password_First_Time } from "request/loginFirstTime/passwordFirstTime";
import { __Terms_And_Conditions } from "request/loginFirstTime/__termsAndConditions";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useSelector, useDispatch } from "react-redux";
import { FIRST_LOGIN } from "redux/types/firstLogonTypes";

import { FirstLoginContext } from "context/FirstLoginContext";
import { AuthContext } from "context/AuthContext";
import { handleLogout } from "components/Logout/FuncionLogout";
// import Swal from "sweetalert2";
// import logotype from "../../../../../assets/logo.png";

const ConfirnmPasswordSchema = Yup.object().shape({
  password: Yup.string().required("¡Inserte la nueva contraseña!"),
  password_confirmation: Yup.string()
    .required("¡Inserte nuevamente la contraseña!")
    .oneOf([Yup.ref("password"), null], "¡Las contraseñas deben coindicir!")
});

const PasswordFirstChange = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();
  const { token } = useSelector((state) => state?.firstLogin);
  const dispatchRedux = useDispatch();
  const { firstLoginDispatch } = useContext(FirstLoginContext);
  const dispacthRedux = useDispatch();
  const { dispatch } = useContext(AuthContext);
  const idFuncionaio = useSelector((state) => state?.auth?.dataUser?.id);

  const {
    username = "",
    change_password = "",
    terms_and_conditions = "",
    tk = ""
  } = queryString.parse(location.search);

  const [isOpenError, openModalError, closeModalError] = useModal();
  const [isOpenSuccess, openModalSuccess, closeModalSuccess] = useModal();
  const [isOpenTerms, openModalTerms, closeModalTerms] = useModal();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(false);
  const [changePassword, setChangePassword] = useState(
    change_password === "1" ? 1 : null
  );
  const [changeTerms, setChangeTerms] = useState(
    terms_and_conditions === "1" ? 1 : null
  );

  const initialValues = {
    password: "",
    password_confirmation: "",
    username
  };

  useEffect(() => {
    if (!changeTerms) openModalTerms();
  }, []);

  const recover = async (values) => {
    const { password, password_confirmation } = values;

    const formData = new FormData();
    formData.append("password", password);
    formData.append("password_confirm", password_confirmation);

    if (password && password_confirmation) {
      if (password?.length < 6 || password_confirmation?.length < 6) {
        openAlert("La contraseña debe tener al menos 6 caracteres", 'error');
        return
      }
    }

    if (password && password_confirmation) {
      try {
        const res = await __Password_First_Time(formData, token);

        if (res.data.status.code === 400) {
          console.log("entre en la condición del error 400")
          openAlert(res.data.status.message, 'error');
          return
        }

        if (res.data.status.code === 200) {
          setAlertMessage(`Password actualizado Exitosamente`);
          setChangePassword(1);
          openModalSuccess();
          handleLogout(token, dispatch, dispacthRedux, navigate, idFuncionaio)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const confirmTermsAndCond = async () => {
    const formData = new FormData();
    formData.append("token", token);
    setLoadingTerms(true);
    try {
      const res = await __Terms_And_Conditions(formData, token);
      if (res.data.status.code === 200) {
        closeModalTerms();
        setLoadingTerms(false);
        if (setChangePassword === 1) {
          navigate("/auth", {
            replace: true
          });
        }
        return setChangeTerms(1);
      }
      openAlert("Un error ha ocurrido", "error");
    } catch (error) {
      openAlert("Un error ha ocurrido", "error");
    }
  };

  const goLogin = (e) => {
    e.preventDefault();

    if (!changeTerms) {
      openAlert("Recuerde aceptar los terminos y condiciones", "warning");
      return;
    }
    navigate("/auth", {
      replace: true
    });
  };

  const handleCloseAlert = () => {
    if (isOpenError) {
      closeModalError();
      setAlertMessage("");
    }
    if (isOpenSuccess) {
      closeModalSuccess();
      setAlertMessage("");
    }
  };

  return (
    <>
      <ModalTransition
        isOpen={isOpenTerms}
        onClose={closeModalTerms}
        size={"full"}
      >
        <TermsAndCondition
          onCloseModal={closeModalTerms}
          confirmTerms={confirmTermsAndCond}
          loading={loadingTerms}
          token={token}
        />
      </ModalTransition>

      {alertMessage && (
        <ScaleFade in={isOpenError}>
          <AlertError
            alertMessage={alertMessage}
            closeAlert={handleCloseAlert}
          />
        </ScaleFade>
      )}
      {alertMessage && (
        <ScaleFade in={isOpenSuccess}>
          <AlertSuccess
            alertMessage={alertMessage}
            closeAlert={handleCloseAlert}
          />
        </ScaleFade>
      )}
      {loading && <SpinnerComponent />}

      <p
        style={{
          textAlign: "center",
          fontSize: "1rem",
          wordWrap: "break-word",
          padding: "0 2rem"
        }}
      >
        {changePassword === 1
          ? ""
          : "Digita la nueva contraseña y confirmala para guardarla en el sistema ¡y listo!"}
        <br />
        {changeTerms === 1
          ? ""
          : "No olvides aceptar los términos y condiciones"}
      </p>

      <Formik
        validationSchema={ConfirnmPasswordSchema}
        initialValues={initialValues}
        onSubmit={recover}
      >
        {() => (
          <Form>
            <InputsContainer margin="1rem 2rem 2rem 0rem">
              <Input
                placeholder={username}
                name="email"
                type="email"
                disabled={true}
                value={username}
              />
              <PasswordField
                size="lg"
                name="password"
                placeholder="Contraseña"
                disabled={changePassword === 1}
              />
              <PasswordField
                size="lg"
                name="password_confirmation"
                placeholder="Repita su contraseña"
                disabled={changePassword === 1}
              />

              {/* {changeTerms === 1 || (
                <StyledLink
                  className="float-end checkIniciar"
                  to="/auth/recover"
                  onClick={openModalTerms}
                >
                  Aceptar términos y condiciones
                </StyledLink>
              )} */}
              {changePassword === 1
                ? (
                  <CardButton onClick={goLogin}>Ir al Login</CardButton>
                )
                : (
                  <CardButton type="submit" onClick={recover}>
                  Reiniciar Contraseña
                  </CardButton>
                )}
            </InputsContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PasswordFirstChange;
