import { Text, Tooltip } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { RiFileCopy2Line, RiFileAddLine } from "react-icons/ri";
import { MdAddShoppingCart, MdOutlineLibraryAdd, MdUploadFile } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";

import { useContext, useState } from "react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useModal } from "hooks/useModal";

import { ModalGeneric } from "components/modal/ModalGeneric";
import { AddItemsMassively } from "components/AddItemsMassively/AddItemsMassively";

import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import OrderProductionContext from "context/OrderProductionContext";
import { useBulkItemAggregation } from "hooks/useBulkItemAggregation";
import queryEnv from "constants/queryConst";

const ButtonsTable = ({ positionView }) => {
  const {
    onOpenAddOrder,
    onOpenAddProduct,
    onOpenAddSupplies,
    setIsEditCiSupplies,
    onOpenAddOther,
    onOpenAddSuppliesProduct,
    BulkLoad,
    InfoUser,
    update,
    setUpdate
  } = useContext(IntegracionBoxCrudContex);
  const openFormOrder = () => onOpenAddOrder();
  const openFormProduct = () => onOpenAddProduct();
  const openSupplies = () => {
    setIsEditCiSupplies(false)
    onOpenAddSupplies()
  };
  const onOpenOther = () => onOpenAddOther();
  const onOpenProductSuply = () => onOpenAddSuppliesProduct();
  const [isOpenImport, onOpenImport, onCloseImport] = useModal();
  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const { onOpenTemplate, onOpenEdit } = useContext(OrderProductionContext);

  const Upload = () => {
    document.getElementById("display").click();
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);
        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);
      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
  };

  const handleClean = () => {
    removeItemFromArr();
    onCloseImport();
  };

  const AddItemsMassiively = () => {
    if (Attachment.length !== 0) {
      const formData = new FormData();
      formData.append("file", Attachment);
      formData.append("qualifiedUser_id", InfoUser.qualifiedUser_id);
      formData.append(
        "integration_certificate_code",
        InfoUser?.integration_certificate_code || ""
      );
      BulkLoad(environment.BULKLOAD_iNTEGRATION_BOX, formData, handleClean);
    } else {
      openAlert(`Olvidaste seleccionar un archivo`, "error");
    }
  };

  const formData = new FormData();
  formData.append("file", Attachment);
  formData.append("qualifiedUser_id", InfoUser?.qualifiedUser_id || '');
  formData.append("integration_certificate_code", InfoUser?.integration_certificate_code || "");
  const { bulkAddItems, ErrorTable, isLoadingJobStatus: isLoadingBulkAggregation } = useBulkItemAggregation({
    userProvidedEndpoint: environment.BULKLOAD_iNTEGRATION_BOX,
    userProvidedBody: formData,
    isOpen: isOpenImport,
    onClose: onCloseImport,
    file: Attachment,
    nameFile: nameAttachment,
    update,
    setUpdate,
    key: InfoUser?.integration_certificate_code,
    invalidateKey: [queryEnv?.SUPPLIES_CI],
  })

  return (
    <>
      <ModalGeneric
        isOpen={isOpenImport}
        onOpen={onOpenImport}
        onClose={onCloseImport}
        title="Agregar ítem masivamente"
        buttonClose={false}
      >
        <AddItemsMassively
          nameAttachment={nameAttachment}
          handleAttachment={handleAttachment}
          Upload={Upload}
          removeItemFromArr={removeItemFromArr}
          onCloseImport={handleClean}
          handleSUbmit={bulkAddItems}
          attachmentTitle= "insumos a CI.xlsx"
          ErrorTable={ErrorTable} 
          isLoading={isLoadingBulkAggregation}
        />
      </ModalGeneric>

      <div className="row mb-2">
        <div className="col-5 col-md-6 col-sm-6 col-xs-6 ">
          <Text className="text-title-item" fontSize="xl">
            Ítem del Formulario
          </Text>
        </div>
        <div className="col-7 col-md-6 col-sm-6 col-xs-6 ">
          <div className="d-flex float-end ">
            {positionView === 0 && (
              <>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="Agregar orden de producción"
                >
                  <span>
                    <ButtonHeaderModule
                      className="mx-1 d-flex float-end"
                      bgColor="#F9A621"
                      onClick={openFormOrder}
                    >
                      <IconGeneric
                        color="#fff"
                        as={RiFileCopy2Line}
                        className={"icons"}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>

                <Tooltip placement="top" hasArrow label="Agregar Producto">
                  <span>
                    <ButtonHeaderModule
                      className="mx-1 d-flex float-end"
                      bgColor="#3f89c4"
                      onClick={openFormProduct}
                    >
                      <IconGeneric
                        color="#fff"
                        as={MdAddShoppingCart}
                        className={"icons"}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>

                <Tooltip placement="top" hasArrow label="Agregar Plantilla">
                  <span>
                    <ButtonHeaderModule
                      className="mx-1 d-flex float-end"
                      bgColor="#0089BA"
                      onClick={onOpenTemplate}
                    >
                      <IconGeneric
                        color="#fff"
                        as={RiFileAddLine}
                        className={"icons"}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            )}
          </div>

          <div className="d-flex float-end ">
            {positionView === 1 && (
              <>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="Agregar ítem masivamente"
                >
                  <ButtonHeaderModule
                    className="d-flex float-end"
                    bgColor="#1F7AC3"
                    onClick={onOpenImport}
                  >
                    <IconGeneric color="#fff" as={MdUploadFile} />
                  </ButtonHeaderModule>
                </Tooltip>

                <Tooltip
                  placement="top"
                  hasArrow
                  label="Agregar materias primas"
                >
                  <span>
                    <ButtonHeaderModule
                      className="mx-1 d-flex float-end"
                      bgColor="#05668e"
                      onClick={() => openSupplies()}
                    >
                      <IconGeneric color="#fff" as={IoMdAdd} />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>

                <Tooltip placement="top" hasArrow label="Agregar producto">
                  <span>
                    <ButtonHeaderModule
                      className="d-flex float-end"
                      bgColor="#F9A621"
                      onClick={() => onOpenProductSuply()}
                    >
                      <IconGeneric color="#fff" as={MdOutlineLibraryAdd} />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            )}
          </div>

          <div className="d-flex float-end ">
            {positionView === 2 && (
              <>
                <Tooltip placement="top" hasArrow label="Agregar otros costos">
                  <span>
                    <ButtonHeaderModule
                      className="mx-1 d-flex float-end"
                      bgColor="#03C39A"
                      onClick={onOpenOther}
                    >
                      <IconGeneric color="#fff" as={IoMdAdd} />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonsTable;
