import React, { useContext } from 'react'
import { IconMenuRUD } from 'styled/Icons.styled'
import { RiEdit2Fill } from 'react-icons/ri'
import { GiCardDiscard } from 'react-icons/gi'
import { MdContentCopy } from 'react-icons/md'
import { AiOutlineFileDone } from 'react-icons/ai'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import queryString from 'query-string'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { MenuItem } from '@chakra-ui/react'
import { AuthContext } from 'context/AuthContext'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { ValidatePresenFmm } from 'pages/Entry_and_exit_forms/ValidatePresenFmm'
import { ValidatePresenFmmCorrect } from 'pages/Entry_and_exit_forms/ValidatePresenFmmCorrect'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsBorradorForm = ({ data }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const { authUser } = useContext(AuthContext)
  const [openAlert] = AlertErrorAlert()

  const {
    setDataToEdit,
    setDataToDesechar,
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    onOpenDesechar,
    getItemsPrev,
    changeStatusCorregido
  } = useContext(FormCrudCRUDContext)

  const token = useSelector(state => state.auth.dataUser.token)
  const handleEdit = async e => {
    const options = {
      Ingreso: 'FMMI',
      Salida: 'FMMS',
      'Corregido de ingreso': 'FMMIC',
      'Corregido de salida': 'FMMSC',
      'Ajuste saldo de ingreso': 'FMMIC',
      'Ajuste saldo de Salida': 'FMMSC'
    }
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type', 1)
    body.append('type_of', options[movement])
    console.log(movement, options[movement])
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(data, openAlert)
      if (movement === 'Ingreso') {
        navigate(`/FormEntry?FormEntryNumber=${data.id}`)
      } else if (movement === 'Salida') {
        navigate(`/FormExit?FormExitNumber=${data.id}`)
      }
      if (
        movement === 'Corregido de ingreso' ||
        movement === 'Ajuste saldo de ingreso'
      ) {
        navigate(`/FormCorrection?FormEntry=${data.id}`)
      } else if (
        movement === 'Corregido de salida' ||
        movement === 'Ajuste saldo de Salida'
      ) {
        navigate(`/FormCorrectionOutput?FormOutput=${data?.id}`)
      }
      return
    }

    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleCopy = () => {
    setDataToCopy(data)
    onOpenCopy()
  }
  const arrayEntry = [
    'product_quantity',
    'typeProduct_id',
    'transportMethod_id',
    'packaging_id',
    'countryOrigin_id',
    'countryProcedency_id',
    'flag_id',
    'countryPurchase_id',
    'product_id',
    'gross_weight',
    'net_weight',
    'us_freight',
    'port_id'
  ]

  const arrayEntryCorrected = [
    'product_quantity_corrected',
    'typeProductCorrected_id',
    'transportMethodCorrected_id',
    'packagingCorrected_id',
    'countryOriginCorrected_id',
    'countryProcedencyCorrected_id',
    'flagCorrected_id',
    'countryPurchaseCorrected_id',
    'productCorrected_id',
    'gross_weight_corrected',
    'net_weight_corrected',
    'us_freight_corrected'
  ]

  const arrayOutput = [
    'product_quantity',
    // "typeProduct_id",
    'transportMethod_id',
    'packaging_id',
    'countryOrigin_id',
    'flag_id',
    'countryPurchase_id',
    'product_id',
    'gross_weight',
    'net_weight',
    'us_freight',
    'countryDestiny_id'
  ]
  const arrayOutputCorrected = [
    'product_quantity_corrected',
    'transportMethodCorrected_id',
    'packagingCorrected_id',
    'countryOriginCorrected_id',
    'flagCorrected_id',
    'countryPurchaseCorrected_id',
    'productCorrected_id',
    'gross_weight_corrected',
    'net_weight_corrected',
    'us_freight_corrected',
    'countryDestinyCorrected_id'
  ]

  const handlePresent = async status => {
    const attach = data.files

    if (movement === 'Ajuste saldo de Salida') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMSC')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)

        const infoOutputCorrect = {
          id: data.id,
          status_outputFormCorrected: status
        }

        return changeStatusCorregido(infoOutputCorrect)
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    }

    if (movement === 'Ajuste saldo de ingreso') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMIC')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)
        const infoEntryCorrect = {
          id: data.id,
          status_entryFormCorrected: status
        }

        return changeStatusCorregido(infoEntryCorrect)
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    }

    const validateCustom = !!(
      data.code_operation >= 201 &&
      data.code_operation <= 299 &&
      data.check_duta &&
      !data.customAdministration_id
    )

    if (movement === 'Ingreso') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMI')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)

        const mensageErrorItem = ValidatePresenFmm(data, status)
        if (mensageErrorItem) {
          openAlert(mensageErrorItem, 'error')
        } else {
          const respuesta = await getItemsPrev(
            environment.GET_iTEMS_NP,
            data.id,
            arrayEntry,
            data?.code_operation
          )
          const info = {
            id: data.id,
            status_entryForm: status
          }

          if (
            status === 'PRESENTADO' &&
            (respuesta.length > 0 || attach.length === 0)
          ) {
            if (
              respuesta.length > 0 &&
              data.code_operation >= 101 &&
              data.code_operation <= 599
            ) {
              navigate(`/FormEntry?FormEntryNumber=${data.id}`)
              openAlert(
                'El formulario presenta errores. Verifique los campos marcados',
                'error'
              )
            } else if (attach.length === 0) {
              openAlert(
                'El formulario debe tener por lo menos un documento adjunto',
                'error'
              )
              navigate(`/FormEntry?FormEntryNumber=${data.id}`)
            } else {
              changeStatus(info)
            }
          } else {
            changeStatus(info)
          }
        }
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    } else if (movement === 'Corregido de ingreso') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMIC')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)

        const mensageErrorItem = ValidatePresenFmmCorrect(data, status)
        if (mensageErrorItem) {
          openAlert(mensageErrorItem, 'error')
        } else {
          const info = {
            id: data.id,
            status_entryFormCorrected: status
          }
          const respuesta = await getItemsPrev(
            environment.GET_iTEMS_CORRECT_ENTRY_NP,
            data.id,
            arrayEntryCorrected,
            data?.code_operation
          )
          if (
            status === 'PRESENTADO' &&
            (respuesta.length > 0 || attach.length === 0)
          ) {
            if (
              respuesta.length > 0 &&
              data.code_operation >= 101 &&
              data.code_operation <= 599
            ) {
              navigate(`/FormCorrection?FormEntry=${data.id}`)
              openAlert(
                'El formulario presenta errores. Verifique los campos marcados',
                'error'
              )
            } else if (attach.length === 0) {
              openAlert(
                'El formulario debe tener por lo menos un documento adjunto',
                'error'
              )
              navigate(`/FormCorrection?FormEntry=${data.id}`)
            } else {
              changeStatus(info)
            }
          } else {
            changeStatusCorregido(info)
          }
        }
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    } else if (movement === 'Salida') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMS')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)

        const respuesta = await getItemsPrev(
          environment.GET_iTEMS_OUTPUT_NP,
          data.id,
          arrayOutput,
          data?.code_operation
        )
        const info = {
          id: data.id,
          status_outputForm: status
        }
        if (
          status === 'PRESENTADO' &&
          (validateCustom || respuesta.length > 0 || attach.length === 0)
        ) {
          if (validateCustom) {
            navigate(`/FormExit?FormExitNumber=${data.id}`)
            openAlert(
              'El campo Administración de Aduanas es obligatorio',
              'error'
            )
          } else if (
            respuesta.length > 0 &&
            data.code_operation >= 101 &&
            data.code_operation <= 599
          ) {
            navigate(`/FormExit?FormExitNumber=${data.id}`)
            openAlert(
              'El formulario presenta errores. Verifique los campos marcados',
              'error'
            )
          } else if (attach.length === 0) {
            // localStorage.setItem(
            //   `errorAttadchment-${data.id}`,
            //   JSON.stringify(arrayDocumentRequerid)
            // );

            navigate(`/FormExit?FormExitNumber=${data.id}`)
            openAlert(
              'El formulario no tiene completo los documentos requeridos',
              'error'
            )
          } else {
            changeStatus(info)
          }
        } else {
          changeStatus(info)
        }
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    } else if (movement === 'Corregido de salida') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type_of', 'FMMSC')
      body.append('type', 1)
      const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      console.log(res)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          const res = await __Post(environment.FORMS_ON_EDIT, token, body)
        }, 5000)
        const respuesta = await getItemsPrev(
          environment.GET_iTEMS_CORRECT_OUTPUT_NP,
          data.id,
          arrayOutputCorrected,
          data?.code_operation
        )
        const info = {
          id: data.id,
          status_outputFormCorrected: status
        }

        if (
          status === 'PRESENTADO' &&
          (respuesta.length > 0 || attach.length === 0)
        ) {
          if (
            respuesta.length > 0 &&
            data.code_operation >= 101 &&
            data.code_operation <= 599
          ) {
            navigate(`/FormCorrectionOutput?ShowOutput=${data.id}`)
            openAlert(
              'El formulario presenta errores. Verifique los campos marcados',
              'error'
            )
          } else if (attach.length === 0) {
            // localStorage.setItem(
            //   `errorAttadchment-${data.id}`,
            //   JSON.stringify(arrayDocumentRequerid)
            // );
            navigate(`/FormCorrectionOutput?ShowOutput=${data.id}`)
            openAlert(
              'El formulario no tiene completo los documentos requeridos',
              'error'
            )
          } else {
            changeStatusCorregido(info)
          }
        } else {
          changeStatusCorregido(info)
        }
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    }
  }

  const handleDesechar = () => {
    setDataToDesechar(data)
    onOpenDesechar()
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { Outputform, Entryform } = localPermissions

  const {
    changeStatus: changeStatusEntryForm,
    copy: copyEntryForm,
    update: updateEntryform
  } = Entryform
  const {
    update: updateOutputForm,
    changeStatus: changeStatusOutputForm,
    copy: copyOutputForm
  } = Outputform

  return (
    <>
      {(hasCommonElements(permissionList, [
        ...updateEntryform,
        ...updateOutputForm
      ]) ||
        authUser.admin === 1) && (
        <MenuItemStyled py={3} onClick={handleEdit}>
          <IconMenuRUD as={RiEdit2Fill} />
          Editar
        </MenuItemStyled>
      )}
      <HrDividerNoMargin />

      {(movement === 'Ingreso' || movement === 'Salida') &&
        (hasCommonElements(permissionList, [
          ...copyEntryForm,
          ...copyOutputForm
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={handleCopy} py={3}>
              <IconMenuRUD color='#F9A621' as={MdContentCopy} />
              Copiar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}

      {(hasCommonElements(permissionList, [
        ...changeStatusEntryForm,
        ...changeStatusOutputForm
      ]) ||
        authUser.admin === 1) && (
        <MenuItemStyled onClick={() => handlePresent('PRESENTADO')} py={3}>
          <IconMenuRUD color='#03C39A' as={AiOutlineFileDone} />
          Presentar
        </MenuItemStyled>
      )}
      <HrDividerNoMargin />

      {authUser.is_qualified_user === 1 &&
        (hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) ||
          authUser.admin === 1) && (
          <MenuItemStyled py={3} onClick={handleDesechar}>
            <IconMenuRUD color='#d92525' as={GiCardDiscard} />
            Desechar
          </MenuItemStyled>
        )}
    </>
  )
}
