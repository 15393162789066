const theadCustomsClearance = [
  // { name: "Nit", order: "nit_qualified" },
  // { name: "Empresa", order: "name_qualified" },
  { name: "Item", order: "item" },
  { name: "Referencia", order: "Referencia" },
  { name: "Producto", order: "Producto" },
  { name: "Descripción", order: "Descripción" },
  { name: "Cantidad", order: "Cantidad" },
  { name: "Subpartida", order: "Subpartida" },
  { name: "Und.", order: "Und" },
  { name: "Cant.Prod.", order: "CantProd" },
  { name: "Und.Prod.", order: "UndProd" },
  { name: "Cant. Equiv.", order: "CantEquiv" },
  { name: "Peso en kilos", order: "pesokilo" },
  { name: "Valor US$", order: "ValorUS$" },
  // { name: "SubTotales", order: "SubTotales$" },
  { name: "Acciones", order: "Acciones" }
];

const theadCustomsClearanceIsCommerce = [
  // { name: "Nit", order: "nit_qualified" },
  // { name: "Empresa", order: "name_qualified" },
  { name: "Item", order: "item" },
  { name: "Referencia", order: "Referencia" },
  { name: "Producto", order: "Producto" },
  { name: "Descripción", order: "Descripción" },
  { name: "Cantidad", order: "Cantidad" },
  { name: "Subpartida", order: "Subpartida" },
  { name: "Und.", order: "Und" },
  { name: "Cant.Prod.", order: "CantProd" },
  { name: "Und.Prod.", order: "UndProd" },
  { name: "Cant. Equiv.", order: "CantEquiv" },
  { name: "Peso en kilos", order: "pesokilo" },
  { name: "Valor US$", order: "ValorUS$" },
  // { name: "SubTotales", order: "SubTotales$" },
  { name: "Seguimiento", order: "tracking" },
  { name: "Acciones", order: "Acciones" }
];

const theadCustomsClearanceActionNone = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "Referencia" },
  { name: "Producto", order: "Producto" },
  { name: "Descripción", order: "Descripción" },
  { name: "Cantidad", order: "Cantidad" },
  { name: "Subpartida", order: "Subpartida" },
  { name: "Und.", order: "Und" },
  { name: "Cant.Prod.", order: "CantProd" },
  { name: "Und.Prod.", order: "UndProd" },
  { name: "Cant. Equiv.", order: "CantEquiv" },
  { name: "Peso en kilos", order: "pesokilo" },
  // { name: "SubTotales", order: "SubTotales$" },
  { name: "Valor US$", order: "ValorUS$" }
];

const theadCustomsClearanceActionNoneIsCommerce = [
  { name: "Item", order: "item" },
  { name: "Referencia", order: "Referencia" },
  { name: "Producto", order: "Producto" },
  { name: "Descripción", order: "Descripción" },
  { name: "Cantidad", order: "Cantidad" },
  { name: "Subpartida", order: "Subpartida" },
  { name: "Und.", order: "Und" },
  { name: "Cant.Prod.", order: "CantProd" },
  { name: "Und.Prod.", order: "UndProd" },
  { name: "Cant. Equiv.", order: "CantEquiv" },
  { name: "Peso en kilos", order: "pesokilo" },
  // { name: "SubTotales", order: "SubTotales$" },
  { name: "Valor US$", order: "ValorUS$" },
  { name: "Seguimiento", order: "tracking" }
];

const theadCustomsClearanceEdit = [
  { name: "Nº Formulario", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Empresa", order: "null" },
  { name: "Numero de aceptación", order: "null" },
  { name: "Fecha de aceptación", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Acciones", order: "null" }
];
const theadCustomsClearanceAuthorization = [
  { name: "Nº Formulario", order: "null" },
  { name: "Usuario", order: "null" },
  { name: "Empresa", order: "null" },
  { name: "Numero de aceptación", order: "null" },
  { name: "Fecha de aceptación", order: "null" },
  { name: "Estado", order: "null" },
  { name: "Fecha de autorización", order: "null" },
  { name: "Acciones", order: "null" }
];

const TheadFormSearchRefTable = [
  { name: "Código producto", order: "null" },
  { name: "Referencia", order: "null" },
  { name: "Subpartida", order: "null" },
  { name: "Descripción", order: "null" },
  { name: "Certificado", order: "null" },
  { name: "Decl. de Imp.", order: "null" },
  { name: "Ope", order: "null" },
  { name: "Inic", order: "null" },
  { name: "Acuerdo", order: "null" },
  { name: "Saldo", order: "null" },
  { name: "Sdo. rsvd", order: "null" },
  { name: "Unidad", order: "null" },
  { name: "Saldo Producto", order: "null" },
  { name: "Sdo.Producto rsvd", order: "null" },
  { name: "Unidad Producto", order: "null" }
];

export {
  theadCustomsClearance,
  TheadFormSearchRefTable,
  theadCustomsClearanceActionNone,
  theadCustomsClearanceEdit,
  theadCustomsClearanceAuthorization,
  theadCustomsClearanceActionNoneIsCommerce
};
