import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import PassCrudContext from "context/PassCrudContext";

const TbodyItemDispacth = ({ data, onCloseModal }) => {
  const { setDataItemDispachSelect, onCloseSearchItem, seteditItemDispatch } =
    useContext(PassCrudContext);

  const handleSearchItem = () => {
    setDataItemDispachSelect(data);
    onCloseSearchItem();
    seteditItemDispatch(false);
  };

  return (
    <Tr onClick={handleSearchItem}>
      <Td className="text-center">
        {data?.code_outputForm ? data?.code_outputForm : "N/A"}
      </Td>
      <Td className="text-center">{data?.item ? data?.item : "N/A"}</Td>
      <Td className="text-center">
        {data?.reference ? data?.reference : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.custom_clearance ? data?.custom_clearance : ""}
      </Td>
      <Td className="text-center">
        {data?.code_product ? data?.code_product : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.description ? data?.description : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_businessUnit ? data?.code_businessUnit : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_go_out ? data?.quantity_go_out : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_unit_measurement ? data?.code_unit_measurement : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.quantity_go_out_product ? data?.quantity_go_out_product : "N/A"}
      </Td>
    </Tr>
  );
};

TbodyItemDispacth.propTypes = {
  data: PropTypes.object
};

export default TbodyItemDispacth;
