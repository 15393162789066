import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const { fetchConfig } = require("request/configuration/__ActivityUser");
const [openAlert] = AlertErrorAlert();

export const _handleStateConfig = async (
  url,
  setDisableAndActivate,
  setLoadingIcons,
  token,
  id
) => {
  setLoadingIcons(true);

  try {
    const config = await fetchConfig(url, token, id);
    const customStatus = config.data.status.data.status_custom;
    console.log("customStatus", customStatus);
    const Message = config?.data?.status?.message;
    openAlert(Message, "success");
    setDisableAndActivate(customStatus);
  } catch (error) {
    console.error("Error while handling state:", error);
  } finally {
    setLoadingIcons(false);
  }
};
