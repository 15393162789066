import { Box, FormErrorMessage, SimpleGrid } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";

import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
//import { useForm } from "react-hook-form";
import { isObject, isString, isValid } from "utils/type-check-utils";
import { useEffect } from "react";
import { parseFloat } from "utils/parseFloat";
import { useGlobalContext } from "context/GlobalContext";
import { RETURN_TYPES, useForm } from "hooks/useForm";

const FormAddOtherCostOP = ({ onSubmit = () => {}, onCloseModal }) => {
  const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const { dataToEditOP } = useGlobalContext();
  /*   const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        net_weight_kilo_other_cost: 0,
        total_calculated_other_cost: 0,
        quantity_other_cost: 0,
        name_other_cost: "",
      },
    }); */

  const onFieldChange = () => {};

  const { values, handleInputChange, reset, setValues } = useForm({
    initialState: {
      id: "",
      net_weight_kilo_other_cost: "",
      total_calculated_other_cost: "",
      quantity_other_cost: "",
      name_other_cost: "",
    },
    returnType: RETURN_TYPES.OBJECT,
    onFieldChange,
  });

  const {
    id,
    net_weight_kilo_other_cost,
    total_calculated_other_cost,
    quantity_other_cost,
    name_other_cost,
  } = values || {};

  const createOtherCost = () => {
    onSubmit(values);
  };

  const updateItemValue = (newValue, sourceEvent) => {
    console.log({newValue, sourceEvent: sourceEvent?.event?.target?.name})
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue,
    };

    handleInputChange(target);
  };

  /*   const validationFiels = {
      nameOtherCostValidation:
        isObject(errors.name_other_cost) &&
        isString(errors.name_other_cost?.message),
      quantity_other_costValidation:
        isObject(errors.name_other_cost) &&
        isString(errors.name_other_cost?.message),
    }; */

  useEffect(() => {
    if (dataToEditOP) {
      setValues((prev) => ({
        ...prev,
        id: dataToEditOP?.id,
        net_weight_kilo_other_cost: isValid(dataToEditOP?.net_weight_cost)
          ? parseFloat(dataToEditOP?.net_weight_cost)
          : 0,
        total_calculated_other_cost: isValid(dataToEditOP?.total_us_other_cost)
          ? parseFloat(dataToEditOP?.total_us_other_cost)
          : 0,
        quantity_other_cost: isValid(dataToEditOP?.quantity_cost)
          ? parseFloat(dataToEditOP?.quantity_cost)
          : 0,
        name_other_cost: isValid(dataToEditOP?.name_cost)
          ? dataToEditOP?.name_cost
          : "",
      }));
    }
  }, [dataToEditOP]);

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
        <div>
          <LabelGroup
            name={"name_other_cost"}
            placeholder={"Descripción"}
            // register={register}
            tag={"Descripción"}
            size={100}
            desicion={false}
            maxLength={5000}
            required={true}
            //error={validationFiels.nameOtherCostValidation}
            //onValueChange={updateItemValue}
            onChange={handleInputChange}
            value={name_other_cost}
            /*   {...register("name_other_cost", {
              required: "Este campo es obligatorio",
            })} */
          >
            {/*  {errors.name_other_cost && (
              <FormErrorMessage>
                {errors.name_other_cost.message}
              </FormErrorMessage>
            )} */}
          </LabelGroup>
        </div>

        <div>
          {/*    <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            placeholder={"0"}
            customInput={LabelGroup}
            onValueChange={({ floatValue }) => {
              setValue("quantity_other_cost", floatValue);
            }}
            {...register("quantity_other_cost")}
            tag={"Cantidad"}
            size={100}
            desicion={false}
            maxLength={34}
            error={errors?.quantity_other_cost}
          >
            {errors.quantity_other_cost && (
              <FormErrorMessage>
                {errors.quantity_other_cost.message}
              </FormErrorMessage>
            )}
          </NumericFormat> */}

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            placeholder={"0"}
            customInput={LabelGroup}
            onValueChange={updateItemValue}
            name={"net_weight_kilo_other_cost"}
            value={net_weight_kilo_other_cost}
            /*  {...register("net_weight_kilo_other_cost", {
               required: "Peso en Kilo   es requerido",
               min: { value: 1, message: "Este campo es obligatorio", },
             })} */
            tag={"Peso en Kilos"}
            size={100}
            desicion={false}
            maxLength={34}
            /*   error={errors?.net_weight_kilo_other_cost} */
          >
            {/*  {errors.net_weight_kilo_other_cost && (
              <FormErrorMessage>
                {errors.net_weight_kilo_other_cost.message}
              </FormErrorMessage>
            )} */}
          </NumericFormat>

          {/* 
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator='.'
            customInput={LabelGroup}
            type='number'
            placeholder={'0'}
            name={'net_weight_kilo_other_cost'}
            register={register}
            tag={'Peso en Kilos'}
            size={100}
            desicion={false}
            maxLength={34}
          /> */}
        </div>
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-3">
        <div>
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={decimal_values_precision || 4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"total_calculated_other_cost"}
            onValueChange={updateItemValue}
            value={total_calculated_other_cost}
            /*   onValueChange={({ floatValue }) => {
                setValue("total_calculated_other_cost", floatValue);
              }}
              {...register("total_calculated_other_cost", {
                required: "Valor Total es requerido",
                min: { value: 1, message: "Este campo es obligatorio", },
              })} */
            placeholder={"0"}
            tag={"Valor Total en US$"}
            size={100}
            desicion={false}
            maxLength={34}
          />
        </div>
        <div></div>
      </SimpleGrid>

      <div className="mb-3 ms-5">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>

          <ButtonStyled onClick={createOtherCost}>Agregar</ButtonStyled>
        </Box>
      </div>
    </>
  );
};

export default FormAddOtherCostOP;
