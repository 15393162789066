import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Spinner
} from '@chakra-ui/react'
import { MdSend } from 'react-icons/md'
import './style.css'
import { InputCommentary } from 'pages/RefProductInter/Stilos'
import { IconGeneric } from 'styled/Icons.styled'
import { Action, Fab } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { useForm } from 'hooks/useForm'
import { AuthContext } from 'context/AuthContext'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import GlobalContext from 'context/GlobalContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useModal } from 'hooks/useModal'
import { BiMessageDetail } from 'react-icons/bi'
import { TbNut } from 'react-icons/tb'

const commentTemplate = {
  renderProperties: {
    make: '',
    date: '',
    comment: '',
    person: ''
  },
  functionProperties: {
    handleComment: ''
  }
}

/**
 * Comment template for rendering and function properties.
 * @typedef {Object} CommentTemplate
 * @property {string} make - The make property.
 * @property {string} date - The date property.
 * @property {string} comment - The comment property.
 * @property {string} person - The person property.
 * @property {string} handleComment - The handleComment function property.
 */

/**
 * Comment manager component.
 * @param {Object} props - Component props.
 * @param {string} props.identifier - The identifier for comments.
 * @param {function} props.commentGetService - The comment retrieval service function.
 * @param {function} props.commentCreationService - The comment creation service function.
 * @param {CommentTemplate} props.properties - Comment template properties.
 */

export const CommentManager = ({
  identifier,
  commentGetService,
  commentCreationService,
  properties = commentTemplate,
  children
}) => {
  const btnRef = useRef()
  const [comments, setComments] = useState([])
  const [containerElement, setContainerElement] = useState(null)
  const [isOpenComment, onOpenComment, onCloseComment] = useModal()

  const { authUser } = useContext(AuthContext)
  const [showAlert] = AlertErrorAlert()
  const { requestData } = useContext(GlobalContext)
  const [values, handleInputChange, reset] = useForm({
    [properties.functionProperties.handleComment]: ''
  })

  const { mutate, data, isLoading } = useMutation({
    mutationFn: requestData,
    retry: 3
  })

  /**
   * Handle the response of commentary creation.
   * @param {Object} response - The response data.
   */
  const handleCommentaryCreationResponse = response => {
    if (response?.data?.status?.code === 400) {
      return showAlert(response?.data?.status?.message, 'error')
    }

    if (response?.data?.status?.code === 200) {
      reset()
      // return showAlert(response?.data?.status?.message, 'success')
    }
  }

  /**
   * Create a commentary.
   */
  const createCommentary = () => {
    const requestData = {
      ...values,
      form_id: identifier
    }

    const config = {
      data: requestData,
      Endpoint: commentCreationService,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutate(config, {
      onSuccess: data => handleCommentaryCreationResponse(data)
    })
  }

  const onEnter = e => {
    if (e.key === 'Enter') createCommentary()
  }

  /**
   * Handle the response of comment retrieval.
   * @param {Object} res - The response data.
   */
  const handleResponseComment = res => {
    if (res?.data?.status?.code === 400) { return showAlert(res?.data?.status?.message, 'error') }

    if (res?.data?.status?.code === 200) {
      return setComments(res?.data?.status?.data)
    }
  }

  const {
    isLoading: isLoadingCommentary,
    isFetching: isFetchingCommentary,
    isError: isErrorCommentary,
    data: dataCommentary,
    isSuccess: isSuccessCommentary,
  } = useQuery({
    queryKey: ['commentaryData', data],
    queryFn: () =>
      requestData({
        resourceIdentifier: identifier,
        Endpoint: commentGetService,
        PropertyBody: 'get'
      }),
    refetchOnWindowFocus: false,
  
  })

  useEffect(() => {
    if (isSuccessCommentary) handleResponseComment(dataCommentary)
  }, [dataCommentary])

  const GetCommentIcon = () => {
    if (isLoadingCommentary || isFetchingCommentary) return <Spinner />

    if (children) return <TbNut />

    return <BiMessageDetail />
  }

  useEffect(() => {
    if (containerElement) {
      containerElement.scrollTo(0, containerElement.scrollHeight)
    }
  }, [containerElement, comments])

  return (
    <>
      {!isOpenComment && (
        <Fab
          mainButtonStyles={{
            backgroundColor: '#3498db',
            width: '40px',
            height: '40px'
          }}
          event='click'
          icon={<GetCommentIcon />}
          alwaysShowTitle={true}
          onClick={!children ? onOpenComment : null}
          text={ comments ? `${comments.length}` : ''}
        >
          {children && (
            <Action
              text={comments && comments.length !== 0 ? `Tienes comentarios disponibles` : 'No hay comentarios disponibles'}
              style={{
                backgroundColor: '#3498db',
                width: '40px',
                height: '40px'
              }}
              onClick={onOpenComment}
            >
              <BiMessageDetail />
            </Action>
          )}

          {children}
        </Fab>
      )}

      <Drawer
        isOpen={isOpenComment}
        placement='right'
        onClose={onCloseComment}
        finalFocusRef={btnRef}
        id='scroolhiden'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Comentarios</DrawerHeader>

          <DrawerBody ref={element => setContainerElement(element)}>
            <div id='contenedorComm'>
              {isErrorCommentary
                ? (
                  <div>
                  Ha ocurrido un error. Por favor, comuníquese con soporte
                  técnico.
                  </div>
                )
                : (
                  comments &&
                comments.map((item, index) => (
                  <div
                    key={index}
                    id={
                      item?.user_id !== authUser.id
                        ? 'messageOtherUser'
                        : 'messageMyUser'
                    }
                  >
                    <span id='UserMessage'>
                      {item?.[properties.renderProperties.person]}
                    </span>{' '}
                    <h4>{item?.[properties.renderProperties.comment]} </h4>
                    <span id='timeMessage'>
                      {item?.[properties.renderProperties.date]?.slice(0, 10) +
                        ' ' +
                        item?.[properties.renderProperties.make]}{' '}
                    </span>{' '}
                  </div>
                ))
                )}
            </div>
          </DrawerBody>

          <DrawerFooter>
            <InputCommentary
              name={properties.functionProperties.handleComment}
              onChange={handleInputChange}
              value={values?.[properties.functionProperties.handleComment]}
              placeholder='Escribe un comentario'
              onKeyDown={e => onEnter(e)}
            />

            <IconGeneric
              onClick={() =>
                values?.[properties.functionProperties.handleComment] !== '' &&
                !isLoading
                  ? createCommentary()
                  : () => {}
              }
              type='submit'
              className='m-1 d-flex justify-content-center'
              as={MdSend}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
