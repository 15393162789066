import React, { useContext } from 'react'
import { Tooltip, useDisclosure } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
// import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineFileSync } from 'react-icons/ai'
import { AuthContext } from 'context/AuthContext'
import { MdFactCheck } from 'react-icons/md'
import { BsCheck2Circle } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiTrash } from 'react-icons/bi'
import { CustomizablePopover } from 'components/CustomizablePopover/CustomizablePopover'

import MessagerTrash from './MessagerTrash'
import { FaUnlock } from 'react-icons/fa'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const ButtonsReviewing = ({ handlePresent, data }) => {
  const { authUser } = useContext(AuthContext)
  const location = useLocation()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [openAlert] = AlertErrorAlert()
  const { pathname } = location
  const {
    Number = null,
    ShowEntry = null,
    ShowOutput = null
  } = queryString.parse(location.search)
  const { token } = useSelector(state => state.auth.dataUser)
  const navigate = useNavigate()

  const unlockForm = async () => {
    const body = new FormData()
    body.append('form_id', ShowOutput || Number || ShowEntry)
    body.append('type_of', ShowOutput ? 'FMMSC' : Number ? 'FMMI' : 'FMMIC')
    body.append('type', 0)
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { changeStatus: changeStatusEntryForm } = localPermissions.Entryform

  return (
    <>
      {authUser.is_qualified_user !== 1 && (
        <Tooltip placement='top' hasArrow label='Desbloquear'>
          <span>
            <ButtonHeaderModule
              onClick={() => {
                unlockForm()
                openAlert('Se ha desbloqueado con exito', 'success')
                navigate(-1)
              }}
              className='mx-1'
              bgColor='#fe8d59'
            >
              <IconGeneric
                className='text-white'
                color='#fff'
                width='16px'
                as={FaUnlock}
              />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      )}{' '}
      {data?.status_outputFormCorrected === 'AUTORIZADO' ||
      data?.status_entryFormCorrected === 'AUTORIZADO' ||
      data?.status_outputFormCorrected === 'AUTORIZADO' ||
      data?.status_entryFormCorrected === 'AUTORIZADO' ? (
        <></>
      ) : (
        <>
          {data?.status_entryForm !== 'DEVUELTO' &&
            pathname === '/CheckFormEntry' && (
              <Tooltip placement='top' hasArrow label='Eliminar observaciones'>
                <span>
                  <CustomizablePopover
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    className='me-1'
                    BodyButton={
                      <IconGeneric
                        width='16px'
                        className='text-white'
                        as={BiTrash}
                      />
                    }
                    Popovertitle=' Advertencia!'
                    bgColor='#d81717'
                    shadow={'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px'}
                    _hover={{
                      color: '#fff',
                      backgroundColor: '#d8171790'
                    }}
                    ButtonCloseTitle={'Eliminar observaciones'}
                    // style={{ maxHeight: "500px", overflowY: "scroll" }}
                  >
                    <MessagerTrash onClose={onClose} id={data?.id} />
                  </CustomizablePopover>
                </span>
              </Tooltip>
            )}

          {authUser?.is_qualified_user !== 1 &&
            (authUser?.admin === 1 || authUser?.is_commerce === 1) &&
            (hasCommonElements(permissionList, changeStatusEntryForm) ||
              authUser.admin === 1) && (
              <Tooltip
                placement='top'
                hasArrow
                label={pathname === '/CheckFormEntry' ? 'Aprobar' : 'Radicar'}
              >
                <span>
                  <ButtonHeaderModule
                    onClick={() =>
                      handlePresent(
                        pathname === '/CheckFormEntry'
                          ? 'APROBADO'
                          : 'AUTORIZADO'
                      )
                    }
                    className='me-1'
                    bgColor='#03C39A'
                  >
                    <IconGeneric
                      width='16px'
                      className='text-white'
                      as={
                        pathname === '/CheckFormEntry'
                          ? BsCheck2Circle
                          : MdFactCheck
                      }
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

          {data?.status_entryForm !== 'DEVUELTO' &&
            (hasCommonElements(permissionList, changeStatusEntryForm) ||
              authUser.admin === 1) && (
              <Tooltip placement='top' hasArrow label='Devolver'>
                <span>
                  <ButtonHeaderModule
                    onClick={() => handlePresent('DEVUELTO')}
                    bgColor='#d92525'
                  >
                    <IconGeneric
                      width='16px'
                      className='text-white'
                      as={AiOutlineFileSync}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}
        </>
      )}
    </>
  )
}

export default ButtonsReviewing
