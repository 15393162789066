import { authorized, presented, revise } from "utils/generalData";

/**
 *  Funcion para realizar mapeo de la data y crear  un nuevo array de obj con los keys y values solicitados
 * @function
 * @returns {Array} retorna array de objeto
 */

const filterDataExit = (passesAuthorized, data) => {
  return passesAuthorized.map((dataMp) => ({
    driver: dataMp?.driver,
    id: dataMp?.dispatchPasses_id,
    vehicle_plate: dataMp?.vehicle_plate,
    identification: dataMp?.identification,
    description_security: dataMp?.observations,
    qualifiedUser_id: dataMp?.qualifiedUser_id,
    typeTransport_id: data[0]?.typeTransport_id,
    container_1: dataMp?.container_1 === null ? "" : dataMp?.container_1,
    container_2: dataMp?.container_2 === null ? "" : dataMp?.container_2,
    capacity_container_1:
      dataMp?.capacity_container_1 === null ? "" : dataMp?.capacity_container_1,
    capacity_container_2:
      dataMp?.capacity_container_2 === null ? "" : dataMp?.capacity_container_2,
    flag_security: 0
  }));
};

/**
 *  Funcion para realizar un filtro de determinados datos para pases autorizados en entrada
 * @function
 * @returns {Array} retorna array de datos
 */

const dataPassesAuthorized = (data) => {
  return data.filter(
    (passes) =>
      passes?.empty_pass === 1 ||
      passes?.status === authorized ||
      (passes?.freely_available === 1 && passes?.status === authorized)
  );
};

/**
 *  Funcion para realizar un filtro de determinados datos para pases presentados en entrada
 * @function
 * @returns {Array} retorna array de datos
 */

const dataPassesPresented = (data) => {
  return data.filter(
    (passes) =>
      passes?.empty_pass === 0 &&
      (passes?.status === presented || passes?.status === revise)
    //  &&
    // (passes?.freely_available === 1 || 0 && passes?.status === presented)
  );
};

export { filterDataExit, dataPassesPresented, dataPassesAuthorized };
