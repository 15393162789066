import React from "react";
import { Box, Flex, Spacer, Stack } from "@chakra-ui/react";

import { Title } from "styled/Title";

const TitleReason = ({ title = "", children }) => {
  return (
    <>
      <Box className="my-4">
        <Box>
          <Flex>
            <Title>{title}</Title>
            <Spacer />
            <Stack spacing={4} direction="row" align="center">
              {children}
            </Stack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default TitleReason;
