import TableOrder from "components/Tables/TableOrder";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import {
  theadDesperdicios,
  theadElaboratedExport,
  theadMateriaExport,
  theadOtherExport
} from "pages/IntegrationBox/Arrays/ArrayTitles";
import TBodyGarbage from "pages/IntegrationBox/Table/TbodyDesperdicio";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { TitlePDF } from "styled/Title";
import TbodyElaborateProductExport from "../TablesExports/TbodyProductExport";
import TbodyRawMateriasExport from "../TablesExports/TbodyRawMateriasExport";
import TableOtherExport from "../TablesExports/TableOtherExport";

const ViewTable = () => {
  const location = useLocation();
  const { pathname } = location;

  const { currentPage2, setCurrentPage2, loading } = useContext(
    IntegracionBoxCrudContex
  );

  const suppliesFilter = currentPage2?.unitedProducts?.filter(
    (nature) => nature?.nature_of_inventory !== "EXT"
  );

  const suppliesFilterExt = currentPage2?.unitedProducts?.filter(
    (nature) => nature?.nature_of_inventory !== "NAC"
  );

  return (
    <>
      {currentPage2.product && currentPage2.product.length !== 0 && (
        <div className="mt-4">
          <TitlePDF size={"17px"} weight={"700"} className="ms-3">
            Producto Elaborado
          </TitlePDF>
          <div className="p-2">
            <TableOrder
              thead={theadElaboratedExport}
              data={currentPage2.product ? currentPage2.product : []}
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TbodyElaborateProductExport}
              subTotals={currentPage2?.productTotal}
              titleTotal="Total"
              td={[0, 1, 2, 3, 4, 6, 7]}
            />
          </div>
        </div>
      )}

      {currentPage2.unitedProducts &&
        suppliesFilter.length !== 0 &&
        currentPage2.unitedProducts?.length !== 0 && (
        <div
          className={
            currentPage2.product.length >= 6 ? "next-page-supplies-2" : ""
          }
        >
          <TitlePDF size={"17px"} weight={"700"} className="ms-3">
              Insumos Nacional
          </TitlePDF>
          <div className="p-2">
            <TableOrder
              thead={theadMateriaExport}
              data={
                currentPage2?.unitedProducts && suppliesFilter.length !== 0
                  ? suppliesFilter
                  : []
              }
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TbodyRawMateriasExport}
              subTotals={
                pathname === "/CorrectedCertificate"
                  ? currentPage2.suppliesTotalCorected
                  : currentPage2?.suppliesTotal
              }
              titleTotal="Total"
              td={[3, 3, 2, 3, 4, 8, 9]}
            />
          </div>
        </div>
      )}

      {currentPage2.unitedProducts &&
        suppliesFilterExt.length !== 0 &&
        currentPage2.unitedProducts?.length !== 0 && (
        <div
          className={suppliesFilter.length >= 6 ? "next-page-supplies" : ""}
        >
          <TitlePDF size={"17px"} weight={"700"} className="ms-3">
              Insumos Extranjeros
          </TitlePDF>
          <div className="p-2">
            <TableOrder
              thead={theadMateriaExport}
              data={
                currentPage2?.unitedProducts && suppliesFilterExt.length !== 0
                  ? suppliesFilterExt
                  : []
              }
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TbodyRawMateriasExport}
              subTotals={
                pathname === "/CorrectedCertificate"
                  ? currentPage2.suppliesTotalCorected
                  : currentPage2?.suppliesTotal
              }
              titleTotal="Total"
              td={[3, 3, 2, 3, 4, 8, 9]}
            />
          </div>
        </div>
      )}

      {currentPage2.garbage && (
        <div
          className={
            currentPage2.unitedProducts.length > 6 ? "next-page-others" : ""
          }
        >
          <TitlePDF size={"17px"} weight={"700"} className="ms-3">
            Otros costos
          </TitlePDF>
          <div className="p-2">
            <TableOrder
              thead={theadOtherExport}
              data={currentPage2?.others ? currentPage2?.others : []}
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TableOtherExport}
              subTotals={currentPage2?.totalOtherCostos}
              titleTotal="Total"
              td={[2, 5]}
            />
          </div>
        </div>
      )}

      {currentPage2.garbage && currentPage2.garbage?.length !== 0 && (
        <div className="next-page">
          <TitlePDF size={"17px"} weight={"700"} className="ms-3">
            Desperdicios
          </TitlePDF>
          <div className="p-2">
            <TableOrder
              thead={theadDesperdicios}
              data={currentPage2?.garbage ? currentPage2?.garbage : []}
              setData={setCurrentPage2}
              loading={loading}
              tbodyData={TBodyGarbage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ViewTable;
