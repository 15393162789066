import { TRM_TYPES } from "redux/types/stateTrm";

const initialState = {
  trmValue: false
};

export const statusTrmReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRM_TYPES.STATETRM:
      return {
        ...state,
        trmValue: action.payload
      };

    default:
      return state;
  }
};
