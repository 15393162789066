/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { HrDivider } from "styled/Line.styled";
import { MaxLength } from "utils/MaxLength";
import { Regex } from "utils/Regex";

const validateForm = (form, openAlert) => {
  if (!form?.name_businessUnit.trim()) {
    openAlert("Ingrese el nombre de la unidad comercial", "error");
    return false;
  }

  if (!form?.code_businessUnit.trim()) {
    openAlert("Ingrese el código", "error");
    return false;
  }

  return true;
};

const BusinessUnitForm = () => {
  const initialForm = {
    code_businessUnit: "",
    name_businessUnit: "",
    status_businessUnit: 1
  };
  const [openAlert] = AlertErrorAlert();

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [form, handleInputChange, resetForm, setForm] = useForm();
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (dataToEdit) {
      setForm({ ...dataToEdit });
      setCheck(dataToEdit.status_businessUnit === 1);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (dataToEdit) {
      if (!validateForm(form, openAlert)) return;

      editData({
        ...form,
        id: dataToEdit.id,
        status_businessUnit: form?.status_businessUnit ? 1 : 0
      });
    } else {
      if (!form?.name_businessUnit.trim() || !form?.code_businessUnit.trim()) {
        return;
      }
      createData(form);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar unidad comercial" : "Crear unidad comercial"}
      </Text>
      <HrDivider mb={3} />
      <form>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="name_businessUnit">
              Nombre Unidad Comercial
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_businessUnit"
              id="name_businessUnit"
              value={form?.name_businessUnit || ""}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras) }
              maxLength={MaxLength?.config?.businessUnits?.name_businessUnit}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="code_businessUnit">
              Código
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_businessUnit"
              id="code_businessUnit"
              value={form?.code_businessUnit || ""}
              onChange={handleInputChange}
              maxLength={MaxLength?.config?.businessUnits?.code_businessUnit}
            />
          </div>
        </SimpleGrid>

        {dataToEdit && (
          <div>
            <LabeFormlStyled htmlFor="status_businessUnit">
              Activado
            </LabeFormlStyled>
            <div>
              <Switch
                name="status_businessUnit"
                id="status_businessUnit"
                size="lg"
                value={form?.status_businessUnit}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default BusinessUnitForm;
