import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { handleLogout } from "components/Logout/FuncionLogout";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { __PostJsonData } from "request/Petitions/__Post";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import TbodyHistoryFmm from "./Tbody/Index";

export const HistoryFmm = () => {
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);

  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openAlert] = AlertErrorAlert();
  const { dispatch } = useContext(AuthContext);
  const dispacthRedux = useDispatch();
  const navigate = useNavigate();

  const titleTble = [
    { name: "Nº Formulario", order: "null" },
    // { name: "Empresa", order: "null" },
    // { name: "Código", order: "null" },
    { name: "Por revisar", order: "null" },
    { name: "Aprobado", order: "null" },
    { name: "Devuelto", order: "null" },
    { name: "Inspección fisica", order: "null" },
    { name: "Pase de entrada", order: "null" },
    { name: "Pesaje", order: "null" },
    { name: "Autorizado", order: "null" }
  ];

  const getData = async (q) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.HISTORY_FORM,
        token,
        q,
        page
      );
      if (
        res?.data?.status?.message === "Acceso no autorizado." ||
        res?.data?.status.code !== 200 ||
        res?.data?.status === "El token expiró."
      ) {
        // reset();
        // setChangeViewSearch(false);
        setLoading(false);
        openAlert(res.data.status.message, "error");
        if (res?.data?.status === "El token expiró.") {
          handleLogout(token, dispatch, dispacthRedux, navigate, idFuncionaio);
        }
      } else {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [page, freeZone_id]);

  return (
    <>
      <HeaderSearch>
        <Title className="mb-5">Historial formulario</Title>
        <TableOrder
          thead={titleTble}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyHistoryFmm}
        />

        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};
