import environment from "constants/apiConst";

export const thirdPartiesInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "nit_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "multi",
    name: "status",
    tag: "Estado del formulario",
    parent: "status_entryPass",
    search: "status",
    data: [
      { value: "BORRADOR", label: "BORRADOR" },
      { value: "PRESENTADO", label: "PRESENTADO" },
      { value: "APROBADO", label: "APROBADO" },
      { value: "AUTORIZADO", label: "AUTORIZADO" },
      { value: "DEVUELTO", label: "DEVUELTO" },
      { value: "ELIMINADO", label: "ELIMINADO" },
      { value: "CADUCADO", label: "CADUCADO" },
      { value: "ANULADO", label: "ANULADO" }
    ],
    value: null
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]

/*
  "qualifiedUser_id": "qualifiedUser_id",
  "identification_num": "identification_num",
  "type": "type",
  "internal_code": "internal_code",
  "business_name": "business_name",
  "address": "address",
  "phone": "phone",
  "acronym": "acronym",
  "alias": "alias",
  "identificationType_id": "identificationType_id"
*/

export const entryThirdPartiesNodeList = [
  {
    value: "nit_qualified",
    label: "NIT Calificado",
    search: "nit_qualified"
  },
  {
    value: "identification_num",
    label: "Identificación"

  },
  {
    value: "type",
    label: "Tipo"
  },
  {
    value: "internal_code",
    label: "Código Interno"
  },
  {
    value: "business_name",
    label: "Razón Social"
  },
  {
    value: "address",
    label: "Dirección"
  },
  {
    value: "phone",
    label: "Teléfono"
  },
  {
    value: "acronym",
    label: "Siglas"
  },
  {
    value: "alias",
    label: "Alias"
  },
  {
    value: "identificationType_id",
    label: "Tipo de Identificación"
  }
];
