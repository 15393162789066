import axios from "axios";
import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __Permissions = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.PERMISSION_GET}?page=${page || 1}`, options);
  return res;
};
export const __PermisosNotPage = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.PERMISSION_GET_NOTPAGE}`, options);
  return res;
};
