import { MenuItem } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

export const ButtonMenu = ({
  icon,
  colorIcon = 'blue',
  name = 'example',
  onClick
}) => {
  return (
    <MenuItemStyled onClick={onClick} py={3}>
      <IconMenuRUD color={colorIcon} as={icon} />
      {name}
    </MenuItemStyled>
  )
}
