import React from "react";

export const PDFRestWorldEntry = ({ data }) => {
  const {
    check_rm_corrected,
    dcl_customs_transit_corrected,
    shipping_form_corrected,
    cabotage_corrected,
    transit_expiration_date_corrected,
    shipping_form_expiration_date_corrected,
    cabotage_expiration_date_corrected,
    num_transport_document_corrected,
    transp_doc_date_corrected,
    cargo_manifest_entryFormCorrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    integration_box_certificate_corrected,
    code_operationCorrected
  } = data;
  return (
    <div className="row">
      <div className="col">
        <p>
          {check_rm_corrected === "DTA"
            ? (
              <>
                <strong>DTA: </strong> {dcl_customs_transit_corrected}{" "}
              </>
            )
            : check_rm_corrected === "Planilla de envío"
              ? (
                <>
                  <strong>Planilla de envío: </strong> {shipping_form_corrected}{" "}
                </>
              )
              : check_rm_corrected === "Cabotaje"
                ? (
                  <>
                    <strong>Cabotaje: </strong> {cabotage_corrected}{" "}
                  </>
                )
                : (
                  ""
                )}
        </p>
        <p>
          {check_rm_corrected === "DTA"
            ? (
              <>
                <strong>Fecha de vencimiento Tránsito: </strong>{" "}
                {transit_expiration_date_corrected}{" "}
              </>
            )
            : check_rm_corrected === "Planilla de envío"
              ? (
                <>
                  <strong>Fecha de vencimiento {check_rm_corrected}: </strong>{" "}
                  {shipping_form_expiration_date_corrected}{" "}
                </>
              )
              : check_rm_corrected === "Cabotaje"
                ? (
                  <>
                    <strong>Fecha de vencimiento {check_rm_corrected}: </strong>{" "}
                    {cabotage_expiration_date_corrected}{" "}
                  </>
                )
                : (
                  ""
                )}
        </p>
        <p>
          <strong>Nº Documento Transporte: </strong>{" "}
          {num_transport_document_corrected}
        </p>
        <p>
          <strong>Fecha Documento Transporte: </strong>{" "}
          {transp_doc_date_corrected}
        </p>
      </div>
      <div className="col">
        <p>
          <strong>Manifiesto de carga: </strong>{" "}
          {cargo_manifest_entryFormCorrected}
        </p>
        <p>
          <strong>Nº Factura comercial: </strong>{" "}
          {num_commercial_invoice_corrected}
        </p>

        {code_operationCorrected === 108 ||
          (code_operationCorrected === 107 && (
            <>
              <p>
                <strong>Nº Autorización de salida: </strong>{" "}
                {num_exit_form_corrected}
              </p>
              <p>
                <strong>Nº Autorización de C. inte: </strong>{" "}
                {integration_box_certificate_corrected}
              </p>
            </>
          ))}
      </div>
    </div>
  );
};
