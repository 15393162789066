import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const ModalMenu = ({
  isOpen,
  onClose,
  cuerpo,
  scrollBehaviorProp,
  size
}) => {
  const navigate = useNavigate();

  const closeInfo = () => {
    onClose();
    navigate("/", {
      replace: true
    });
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={size || "4xl"}
        closeOnOverlayClick={false}
        scrollBehavior={scrollBehaviorProp || "inside"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Formularios</ModalHeader> */}
          <ModalCloseButton onClick={closeInfo} />
          <ModalBody>
            {cuerpo}
            {/* <Cancelar onClick={onClose}>Cancelar</Cancelar> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
