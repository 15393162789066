import TermAndConditionHead from './TermAndConditionHead.png';
import TermAndConditionBody from './TermAndConditionBody.png';
import TermsAndConditionTitle from './TermsAndConditionTitle.png';

export const TermsAndConditionsImage = {
  head: {
    img: TermAndConditionHead,
    alt: 'Zona Franca de Barranquilla'
  },
  body: {
    img: TermAndConditionBody,
    alt: 'Logo Zona Franca de Barranquilla'
  },
  title: {
    img: TermsAndConditionTitle,
    alt: 'Logo Zona Franca de Barranquilla'
  }
};
