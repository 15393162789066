import React, { useContext, useEffect, useState } from 'react'
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text
} from '@chakra-ui/react'
import { AiFillWarning } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction, logoutAction } from 'redux/actions/authentication'
import { AUTH_TYPES } from 'redux/types/authTypes'
import { __Post } from 'request/Petitions/__Post'
import { IconGeneric } from 'styled/Icons.styled'
import { useNavigate } from 'react-router'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import environment from 'constants/apiConst'
import { AuthContext } from 'context/AuthContext'
import { getDateNow } from './getDateNow'
import { handleLogout } from 'components/Logout/FuncionLogout'
import updateToken, { sessionExpired } from 'redux/actions/renewTokenActions'
import { authTypes } from 'redux/types'

const logoutChannel = new BroadcastChannel("logout-channel");
const renewTokenChannel = new BroadcastChannel("renew-token-channel");

export const TokenExpirer = ({
  open,
  isOpen,
  onClose,
  modalOpen,
  setModalOpen,
  setFirstRender
}) => {
  const [countdown, setCountdown] = useState(30)
  const navigate = useNavigate();
  const dispacthRedux = useDispatch()
  const idSesion = useSelector(state => state.auth.dataUser.id)
  const [openAlert] = AlertErrorAlert()
  const { dispatch } = useContext(AuthContext)

  const {
    token,
    loginRefresh
  } = useSelector(state => state.auth.dataUser)

  const RefreshToken = async () => {
    try {
      const res = await __Post(environment.RefreshToken, token, { token })
      if (res?.data.status?.code === 200) {
        const tokenR = loginRefresh + 1

        const sanitizedData = {
          token: res?.data?.status?.data?.token,
          date_expires: res?.data?.status?.data?.date_expires,
          loginRefresh: tokenR
        }
        renewTokenChannel.postMessage(sanitizedData)
        dispacthRedux(updateToken(sanitizedData))
        return openAlert(res?.data.status.message, 'success')
      } else {
        return openAlert(
          'Lamentablemente, ha ocurrido un error en la respuesta del servidor. Por favor, intenta nuevamente más tarde. Disculpa las molestias.',
          'error'
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modalOpen) {
        setCountdown(prevCountdown => prevCountdown - 1)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId);
      setCountdown(30);
    }
  }, [modalOpen])

  logoutChannel.onmessage = (message) => {
    if (message?.data?.type !== authTypes.sessionExpired) return;
    localStorage.setItem("chanelWeighing", "");
    localStorage.removeItem("route");
    localStorage.removeItem("search");
    dispacthRedux(sessionExpired());
    window.Echo.leave(`notification-${idSesion}`); // desasociar al canal
    navigate("/auth", {
      replace: true
    });
  };

  useEffect(() => {
    if (countdown === 0) {
      handleLogout(token, dispatch, dispacthRedux, navigate, idSesion, logoutChannel, authTypes?.sessionExpired, sessionExpired)
      onClose()
    }
  }, [countdown, onClose])

  const handleCancel = () => {
    onClose()
    setModalOpen(false)
  }

  const handleConfirm = () => {
    RefreshToken()
    setModalOpen(false)
    onClose()
  }

  return (
    <ModalGeneric
      isOpen={isOpen}
      onOpen={open}
      onClose={onClose}
      title='Motivo de devolución'
      size='xl'
      scrollBehavior='outside'
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            <div className='d-flex align-items-center'>
              <IconGeneric
                className='text-warning me-2 mt-0'
                as={AiFillWarning}
              />
              Alerta de cierre inminente de sesión
            </div>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text fontWeight={400}>
              ¡Atención! Esta es una advertencia importante. Tu sesión está a
              punto de cerrarse porque el tiempo de acceso está por agotarse.
              Para evitar la pérdida de todo el progreso que haya realizado,
              pedimos por favor que renueves tu información de acceso cuanto
              antes. ¡Gracias!
            </Text>
            <br />
            <Text fontWeight={600} w={'full'} color={'red'}>
              Si no actualizas tu sesión en los próximos {countdown} segundos,
              se cerrará por inactividad.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleCancel}>Cancelar</Button>
            <Button colorScheme='red' onClick={handleConfirm}>
              Confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ModalGeneric>
  )
}
