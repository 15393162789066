import axios from "axios";

import env from "../../constants/apiConst";

export const __BusinessUnits = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.BUSINESS_UNITS}?page=${page || 1}`, options);
  return res;
};

export const __BusinessUnitsAll = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.BUSINESS_UNITS__ALL}`, options);
  return res;
};

export const __ShowBusinessUnit = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.BUSINESS_UNITS_SHOW}/${id}`, options);
  return res;
};
