import React from "react";
import { Title } from "styled/Title";
import ProfileDocumentView from "../view/ProfileView/ProfileDocumentView";

const ProfileDocuments = () => {
  return (
    <>
      <Title style={{ marginTop: 30, marginBottom: 40 }}>
        Perfilado de documentos
      </Title>

      <div>
        <ProfileDocumentView />
      </div>
    </>
  );
};

export default ProfileDocuments;
