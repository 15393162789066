import { Box, SimpleGrid } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { AuthContext } from "context/AuthContext";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __DocumentTypeNoPaginate } from "request/configuration/__DocumentRequire";
import { ButtonStyled } from "styled/Buttons.styled";

const FormSearchExtract = ({ setSearchAdvanced, onClose }) => {
  const { state, cleanState, setParamsSearch } = useSearchUrl({ getParams: ["date1", "date2", "code", "document", "valueDocument"], nestedProp: "searchAdvance" });

  const [valueDocument, setValueDocument] = useState(state?.valueDocument);
  const [dataDocument, setDataDocument] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const [values, handleInputChange, reset] = useForm({
    date1: state?.date1 || "",
    date2: state?.date2 || "",
    code: state?.code || "",
  });

  const { code, date1, date2 } = values;

  const dataprueba = [{ label: "Factura", value: 1 }];

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataAdmin = {
      ...values,
      document: valueDocument !== null ? valueDocument.value : ""
    };

    const dataUser = {
      ...values,
      document: valueDocument !== null ? valueDocument.value : ""
    };

    setParamsSearch({ params: { ...dataUser, valueDocument } });
    if (authUser.is_qualified_user === 1) {
      setSearchAdvanced(dataUser);
    } else {
      setSearchAdvanced(dataAdmin);
    }
    // console.log("dataaaa", data);
  };

  const getTypeDocumente = useCallback(async () => {
    try {
      const res = await __DocumentTypeNoPaginate(token);
      const requestDocuments = res?.data?.status?.data
        .slice(0, 14)
        .map((items) => ({
          label: items?.document,
          value: items?.id
        }));

      console.log(requestDocuments);
      if (authUser.is_qualified_user === 1) {
        setDataDocument(requestDocuments.concat(dataprueba));
      } else {
        setDataDocument(requestDocuments);
      }
    } catch (error) {}
  }, [token]);

  useEffect(() => {
    getTypeDocumente();
  }, [getTypeDocumente]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
          <InputSelect
            tag={"Tipo de documento"}
            data={dataDocument}
            set={setValueDocument}
            value={valueDocument}
            size={100}
          />
          <LabelGroup
            tipo={"Text"}
            name={"code"}
            tag={"Código del Documento"}
            size={100}
            value={code}
            onChange={handleInputChange}
            desicion={false}
            // maxLength={2}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
          <LabelGroup
            tipo={"date"}
            name={"date1"}
            tag={"Fecha inicial"}
            size={100}
            value={date1}
            onChange={handleInputChange}
            desicion={false}
            required
          />

          <LabelGroup
            tipo={"date"}
            name={"date2"}
            tag={"Fecha final"}
            size={100}
            value={date2}
            onChange={handleInputChange}
            desicion={false}
            required
          />
        </SimpleGrid>
        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={() => onClose()}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit">Buscar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormSearchExtract;
