import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  HStack,
  Image
} from '@chakra-ui/react'
import { BsArrowUpRight, BsQuestionLg } from 'react-icons/bs'

const ReactCardWithIcon = ({
  photografyUrl = null,
  photografyAlt = null,
  Icon = BsQuestionLg,
  ColorIcon = 'gray',
  ActionIcon = () => {},
  onClickImageButton = () => {},
  activeDelete = true,
  ...attrs
}) => {
  return (
    <>
      <Box
        rounded={'sm'}
        overflow={'hidden'}
        bg='white'
        w={'200px'}
        border={'1px'}
        borderColor='black'
        boxShadow={useColorModeValue('3px 3px 0 gray', '3px 3px 0 cyan')}
        {...attrs}
      >
        <Box borderBottom={'1px'} borderColor='black'>
          <Image
            src={photografyUrl ?? ''}
            objectFit={'cover'}
            h='full'
            w='full'
            alt={photografyAlt ?? ''}
          />
        </Box>

        <HStack borderTop={'1px'} color='black'>
          <Flex
            p={4}
            alignItems='center'
            justifyContent={'space-between'}
            roundedBottom={'sm'}
            cursor={'pointer'}
            w='full'
            onClick={onClickImageButton}
          >
            <Text
              fontSize={'md'}
              fontWeight={'semibold'}
           
            >
              Ver imagen
            </Text>
            <BsArrowUpRight />
          </Flex>
         {activeDelete && <Flex
            p={4}
            alignItems='center'
            justifyContent={'space-between'}
            roundedBottom={'sm'}
            borderLeft={'1px'}
            cursor='pointer'
            onClick={ActionIcon}
          >
            {Icon && <Icon fill={ColorIcon} fontSize={'24px'} />}
          </Flex>}
        </HStack>
      </Box>
    </>
  )
}

export default ReactCardWithIcon
