const getBaseUrl = () => {
  const { NODE_ENV } = process.env;

  const urls = {
    development: `https://gorgeous-dust-0seusmuxapkp.vapor-farm-b1.com`,
    production: window.location.protocol + "//" + window.location.host,
    test: window.location.protocol + "//" + window.location.host
  };

  return urls[NODE_ENV] || urls.production;
};

const BASE_URL = getBaseUrl();
const URL_BASE = `${BASE_URL}/api/auth`;
const BASE_URL_CUBE = `https://cubedev.technisupport.co`;

const environment = {
  /** ********* AUTH ***********/
  ME: `${URL_BASE}/me`,
  RefreshToken: `${URL_BASE}/refresh`,
  UPDATE_DATA_USER: `${URL_BASE}/updatePerfil`,
  UPDATE_DATA_IMAGE: `${URL_BASE}/updateImage`,
  CHANGE_PASSWORD: `${URL_BASE}/changePassword`,
  CUBEJS: `${BASE_URL_CUBE}/cubejs-api/v1`,
  LOGIN: `${URL_BASE}/login`,
  HASH_USER: `${URL_BASE}/generateSigningToken`,
  HASH_PASSWORD: `${URL_BASE}/generateHashLogin`,
  LOGOUT: `${URL_BASE}/logout`,
  FORGOT_PASSWORD: `${URL_BASE}/i_forgot_my_password`,
  VALIDATE_DATA: `${URL_BASE}/validate_data/Sgp2nU3XMOGF3a3gTlVvN3uTP5QU0a`,
  RESET_PASSWORD: `${URL_BASE}/reset_password`,
  PASSWORD_FIRST: `${URL_BASE}/changePasswordFirst`,
  TERMS_AND_CONDITIONS: `${URL_BASE}/confirmTermsAndConditions`,
  PASSWORD_CHANGE: `${URL_BASE}/admin/changePasswordUser`,
  GENERATE_SIGNING_TOKEN: `${URL_BASE}/generateSigningToken`,
  GET_IMAGE_LOGIN: `${URL_BASE}/getImageFront`,
  GET_TERMS_CONDITIONS: `${URL_BASE}/admin/configurations/termsConditions/getTermConditions`,
  PUSHER_AUTH: `${URL_BASE}//pusher/user-auth`,
  /** *********************************** CONFIGURATION START*******************************************/
  /** *********PACKAGING CONFIGURATION ***********/
  PACKAGING: `${URL_BASE}/admin/configurations/packagings/getPackaging`,
  PACKAGING_UPDATE: `${URL_BASE}/admin/configurations/packagings/updatePackaging`,
  PACKAGIN_CREATE: `${URL_BASE}/admin/configurations/packagings/createPackaging`,
  PACKAGING_DELETE: `${URL_BASE}/admin/configurations/packagings/deletePackaging`,
  PACKAGING_SHOW: `${URL_BASE}/admin/configurations/packagings/showPackaging`,
  PACKAGING_DESACTIVE: `${URL_BASE}/admin/configurations/packagings/desactivatePackaging`,
  PACKAGING_ACTIVE: `${URL_BASE}/admin/configurations/packagings/activatePackaging`,
  PACKAGING_NOT_PAGINATE: `${URL_BASE}/admin/configurations/packagings/getPackagingNotPaginate`,

  /** *********ZONAS FRANCA CONFIGURATION ***********/
  GET_ZONAS_F_NOT_PAGE: `${URL_BASE}/admin/configurations/freeZones/getFreeZonesNotPaginate`,
  ZONAS_F_GET: `${URL_BASE}/admin/configurations/freeZones/getFreeZones`,
  ZONAS_F_CREATE: `${URL_BASE}/admin/configurations/freeZones/createFreeZone`,
  ZONAS_F_UPDATE: `${URL_BASE}/admin/configurations/freeZones/updateFreeZone`,
  ZONAS_F_DELETE: `${URL_BASE}/admin/configurations/freeZones/deleteFreeZone`,
  ZONAS_F_SHOW: `${URL_BASE}/admin/configurations/freeZones/showFreeZone`,

  /** *********SUBHEADING CONTROL CONFIGURATION ***********/

  SUBHEADIN_C_GET: `${URL_BASE}/admin/configurations/controlSubheadings/getControlSubheadings`,
  SUBHEADIN_C_CREATE: `${URL_BASE}/admin/configurations/controlSubheadings/createControlSubheading`,
  SUBHEADIN_C_UPDATE: `${URL_BASE}/admin/configurations/controlSubheadings/updateControlSubheading`,
  SUBHEADIN_C_DELETE: `${URL_BASE}/admin/configurations/controlSubheadings/deleteControlSubheading`,
  SUBHEADIN_C_SHOW: `${URL_BASE}/admin/configurations/controlSubheadings/showControlSubheading`,

  /** *********CENTRO NOTIFICATION ***********/

  GET_NOTIFICATION: `${URL_BASE}/getNotifications`,
  SHOW_CONFI_NOTIFICATION: `${URL_BASE}/showAlertConfig`,
  SHOW_CONFI_ALERT: `${URL_BASE}/showNotificationsConfig`,
  SHOW_CONFI_NOTIF_EMPRESA: `${URL_BASE}/showCompanyNotificationsConfig`,
  UPDATE_NOTIFICATION: `${URL_BASE}/isReadNotificationsAndAlerts`,
  UPDATE_ALL_NOTIFICATION: `${URL_BASE}/isReadAllNotificationsAndAlerts`,
  UPDATE_ZONE_FREE_ZONE: `${URL_BASE}/changeFreeZoneTmp`,
  UPDATE_ALERT: `${URL_BASE}/updateAlertConfig`,
  UPDATE_NOTIFICATION_CONFI: `${URL_BASE}/updateNotificationsConfig`,
  UPDATE_NOTIFICATION_CONFI_EMPRESA: `${URL_BASE}/updateCompanyNotificationsConfig`,
  INSERT_NOTIFICATION_CONFI_EMPRESA: `${URL_BASE}/insertCompanyNotificationsConfig`,
  REST_NOTIF_CONFI: `${URL_BASE}/restoreNotificationsConfig`,
  REST_ALERT_CONFI: `${URL_BASE}/restoreAlertConfig`,
  REST_ALERT_CONFI_EMPRESA: `${URL_BASE}/restoreCompanyNotificationsConfig`,
  GET_FREE_ZONES_LOGIN: `${URL_BASE}/getViewFreeZone`,

  /** *********CUENTA DE USUARIOS CONFIGURATION ***********/
  GET_USER_ACCES: `${URL_BASE}/admin/getUser`,
  USER_ACCES_UPDATE: `${URL_BASE}/admin/updateUser`,
  USER_ACCES_CREATE: `${URL_BASE}/admin/createUser`,
  USER_ACCES_ACTIV: `${URL_BASE}/admin/activeUser`,
  SHOW_USER_ACCES: `${URL_BASE}/admin/showUser`,
  ACT_USER_ACCES: `${URL_BASE}/admin/activeUser`,
  INAC_USER_ACCES: `${URL_BASE}/admin/desactiveUser`,

  /** *********SERVICIOS DE DIAN ***********/
  GET_SHIPPING_FORMS: `${URL_BASE}/dian/getShippingForms`,
  GET_COMPARISON_FORMS: `${URL_BASE}/dian/sendReceptionForms`,
  EXPORT_SPECIAL_DECLARATION: `${URL_BASE}/outputForms/sendReportDEI`,

  /** ********* LOCALIZACION ***********/
  GETLOCALITATION: `${URL_BASE}/admin/configurations/countries/getCountrys`,
  LOCALITATION_UPDATE: `${URL_BASE}/admin/configurations/countries/updateCountry`,
  LOCALITATION_CREATE: `${URL_BASE}/admin/configurations/countries/createCountry`,
  LOCALITATION_DELETE: `${URL_BASE}/admin/configurations/countries/deleteCountry`,
  LOCALITATION_SHOW: `${URL_BASE}/admin/configurations/countries/showCountry`,
  LOCALITATION_DESACTIVAR: `${URL_BASE}/admin/configurations/countries/desactivateCountry`,
  LOCALITATION_ACTIVAR: `${URL_BASE}/admin/configurations/countries/activateCountry`,

  /** ********* LOCALIZACION_DEPARTMENTS ***********/
  GETDEPARTMENTS: `${URL_BASE}/admin/configurations/departments/getDepartments`,
  DEPARTMENTS_UPDATE: `${URL_BASE}/admin/configurations/departments/updateDepartment`,
  DEPARTMENTS_CREATE: `${URL_BASE}/admin/configurations/departments/createDepartment`,
  DEPARTMENTS_DELETE: `${URL_BASE}/admin/configurations/departments/deleteDepartment`,
  DEPARTMENTS_ALL: `${URL_BASE}/admin/configurations/departments/getDepartmentsNotPaginate`,
  DEPARTMENTS_SHOW: `${URL_BASE}/admin/configurations/departments/showDepartment`,
  DEPARTMENTS_ACTIVE: `${URL_BASE}/admin/configurations/departments/activateDepartment`,
  DEPARTMENTS_INACTIVE: `${URL_BASE}/admin/configurations/departments/desactivateDepartment`,

  /** *********USERS CONFIGURATION ***********/

  GET_QUALIFIED_USERS_ALL: `${URL_BASE}/admin/administrations/qualifiedUser/getQualifiedUserNotPaginate`,
  GET_QUALIFIED_USERS: `${URL_BASE}/admin/administrations/qualifiedUser/getQualifiedUser`,
  QUALIFIED_USERS_UPDATE: `${URL_BASE}/admin/administrations/qualifiedUser/updateQualifiedUser`,
  QUALIFIED_USERS_CREATE: `${URL_BASE}/admin/administrations/qualifiedUser/createQualifiedUser`,
  QUALIFIED_USERS_SHOW: `${URL_BASE}/admin/administrations/qualifiedUser/showQualifiedUser`,
  QUALIFIED_USERS_CITY: `${URL_BASE}/admin/configurations/cities/getCities`,
  GET_CITY_NOT_PAGINATE: `${URL_BASE}/admin/configurations/cities/getCitiesNotPaginate`,
  QUALIFIED_USERS_SECTOR: `${URL_BASE}/admin/configurations/sectors/getSectorsNotPaginate`,
  QUALIFIED_USERS_CLASIFICACION: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/getQualificationQualifiedUsersNotPaginate`,
  QUALIFIED_USERS_ACTIVITY: `${URL_BASE}/admin/configurations/activities/getActivitiesNotPaginate`,
  QUALIFIED_USERS_STATUS_ACTIVATE_SUP: `${URL_BASE}/admin/administrations/qualifiedUser/activateQualifiedUserAdmin`,
  QUALIFIED_USERS_STATUS_DEACTIVACTE_SUP: `${URL_BASE}/admin/administrations/qualifiedUser/desactivateQualifiedUserAdmin`,
  QUALIFIED_USERS_STATUS_ACTIVATE: `${URL_BASE}/admin/administrations/qualifiedUser/activateQualifiedUser`,
  QUALIFIED_USERS_STATUS_DEACTIVACTE: `${URL_BASE}/admin/administrations/qualifiedUser/desactivateQualifiedUser`,
  QUALIFIED_USERS_TYPE_TARIFF: `${URL_BASE}/admin/configurations/rates_types/getRatesTypesNotPaginate`,
  CHANGUE_STATUS_RATE_TYPE: `${URL_BASE}/admin/configurations/rates_types/changeStatusRateTypes`,
  GET_TARRIF_ZONE: `${URL_BASE}/admin/administrations/qualifiedUser/ratesByFreeZonesQualifiedUserNotPaginated`,

  /** *********BUSINESS UNITS CONFIGURATION ***********/

  BUSINESS_UNITS__ALL: `${URL_BASE}/admin/configurations/business/getBusinessUnitNotPaginate`,
  BUSINESS_UNITS: `${URL_BASE}/admin/configurations/business/getBusinessUnit`,
  BUSINESS_UNITS_UPDATE: `${URL_BASE}/admin/configurations/business/updateBusinessUnit`,
  BUSINESS_UNITS_CREATE: `${URL_BASE}/admin/configurations/business/createBusinessUnit`,
  BUSINESS_UNITS_DELETE: `${URL_BASE}/admin/configurations/business/deleteBusinessUnit`,
  BUSINESS_UNITS_IMPORT: `${URL_BASE}/admin/configurations/business/importBusinessUnit`,
  BUSINESS_UNITS_SHOW: `${URL_BASE}/admin/configurations/business/showBusinessUnit`,
  BUSINESS_UNITS_DESACTIVE: `${URL_BASE}/admin/configurations/business/desactiveBusinessUnit`,
  BUSINESS_UNITS_ACTIVE: `${URL_BASE}/admin/configurations/business/activeBusinessUnit`,

  /** *********TRADE AGREEMENTS CONFIGURATION ***********/
  TRADE_AGREEMENT_GET: `${URL_BASE}/admin/configurations/tradeAgreements/getTradeAgreements`,
  TRADE_AGREEMENT_UPDATE: `${URL_BASE}/admin/configurations/tradeAgreements/updateTradeAgreement`,
  TRADE_AGREEMENT_CREATE: `${URL_BASE}/admin/configurations/tradeAgreements/createTradeAgreement`,
  TRADE_AGREEMENT_SHOW: `${URL_BASE}/admin/configurations/tradeAgreements/showTradeAgreement`,
  TRADE_AGREEMENT_DELETE: `${URL_BASE}/admin/configurations/tradeAgreements/deleteTradeAgreement`,
  TRADE_AGREEMENT_IMPORT: `${URL_BASE}/admin/configurations/tradeAgreements/importTradeAgreements`,
  TRADE_AGREEMENT_NOT_PAGINATE: `${URL_BASE}/admin/configurations/tradeAgreements/getTradeAgreementsNotPaginate`,
  TRADE_AGREEMENT_DESACTIVE: `${URL_BASE}/admin/configurations/tradeAgreements/desactivateTradeAgreements`,
  TRADE_AGREEMENT_ACTIVE: `${URL_BASE}/admin/configurations/tradeAgreements/activateTradeAgreements`,

  /** *********TRANSPORT METHOD CONFIGURATION ***********/
  TRANSPORT_METHOD_GET: `${URL_BASE}/admin/configurations/transportMethod/getTransportMethod`,
  TRANSPORT_METHOD_UPDATE: `${URL_BASE}/admin/configurations/transportMethod/updateTransportMethod`,
  TRANSPORT_METHOD_CREATE: `${URL_BASE}/admin/configurations/transportMethod/createTransportMethod`,
  TRANSPORT_METHOD_DELETE: `${URL_BASE}/admin/configurations/transportMethod/deleteTransportMethod`,
  TRANSPORT_METHOD_ALL: `${URL_BASE}/admin/configurations/transportMethod/getTransportMethodNotPaginate`,
  TRANSPORT_METHOD_SHOW: `${URL_BASE}/admin/configurations/transportMethod/showTransportMethod`,
  TRANSPORT_METHOD_DESACTIVE: `${URL_BASE}/admin/configurations/transportMethod/desactiveTransportMethod`,
  TRANSPORT_METHOD_ACTIVE: `${URL_BASE}/admin/configurations/transportMethod/activeTransportMethod`,

  /** *********VEHICULAR DOOR CONFIGURATION ***********/
  VEHICULAR_DOOR_GET: `${URL_BASE}/admin/configurations/vehicularDoors/getVehicularDoors`,
  VEHICULAR_DOOR_UPDATE: `${URL_BASE}/admin/configurations/vehicularDoors/updateVehicularDoor`,
  VEHICULAR_DOOR_CREATE: `${URL_BASE}/admin/configurations/vehicularDoors/createVehicularDoor`,
  VEHICULAR_DOOR_DELETE: `${URL_BASE}/admin/configurations/vehicularDoors/deleteVehicularDoor`,
  VEHICULAR_DOOR_SHOW: `${URL_BASE}/admin/configurations/vehicularDoors/showVehicularDoor`,
  VEHICULAR_DOOR_DESACTIVE: `${URL_BASE}/admin/configurations/vehicularDoors/desactiveVehicularDoor`,
  VEHICULAR_DOOR_ACTIVE: `${URL_BASE}/admin/configurations/vehicularDoors/activeVehicularDoor`,

  /** *********CUSTOMS CONFIGURATION ***********/
  CUSTOMS_GET: `${URL_BASE}/admin/configurations/customHouse/getCustomHouses`,
  CUSTOMS_GET_NOT_PAGE: `${URL_BASE}/admin/configurations/customHouse/getCustomHouseNotPaginate`,
  CUSTOMS_UPDATE: `${URL_BASE}/admin/configurations/customHouse/updateCustomHouse`,
  CUSTOMS_CREATE: `${URL_BASE}/admin/configurations/customHouse/createCustomHouse`,
  CUSTOMS_DELETE: `${URL_BASE}/admin/configurations/customHouse/deleteCustomHouse`,
  CUSTOMS_SHOW: `${URL_BASE}/admin/configurations/customHouse/showCustomHouse`,
  CUSTOMS_DESACTIVE: `${URL_BASE}/admin/configurations/customHouse/desactivateCustomHouse`,
  CUSTOMS_ACTIVE: `${URL_BASE}/admin/configurations/customHouse/activateCustomHouse`,

  /** *********TYPE_OPERATION CONFIGURATION ***********/
  TYPE_OPERATION_GET: `${URL_BASE}/admin/configurations/typeOperation/getTypeOperations`,
  TYPE_OPERATION_UPDATE: `${URL_BASE}/admin/configurations/typeOperation/updateTypeOperation`,
  TYPE_OPERATION_CREATE: `${URL_BASE}/admin/configurations/typeOperation/createTypeOperation`,
  TYPE_OPERATION_DELETE: `${URL_BASE}/admin/configurations/typeOperation/deleteTypeOperation`,
  TYPE_OPERATION_SHOW: `${URL_BASE}/admin/configurations/typeOperation/showTypeOperation`,
  TYPE_OPERATION_DESACTIVE: `${URL_BASE}/admin/configurations/typeOperation/desactiveTypeOperation`,
  TYPE_OPERATION_ACTIVE: `${URL_BASE}/admin/configurations/typeOperation/activeTypeOperation`,
  TYPE_OPERATION_LIST: `${URL_BASE}/admin/configurations/typeOperation/getTypeOperationsNotPaginate`,

  /** *********OPERATION CONFIGURATION ***********/
  OPERATIONS_GET: `${URL_BASE}/admin/configurations/operations/getOperations`,
  OPERATIONS_UPDATE: `${URL_BASE}/admin/configurations/operations/updateOperation`,
  OPERATIONS_CREATE: `${URL_BASE}/admin/configurations/operations/createOperation`,
  OPERATIONS_DELETE: `${URL_BASE}/admin/configurations/operations/deleteOperation`,
  OPERATIONS_All: `${URL_BASE}/admin/configurations/operations/getOperationsEntryNotPaginate`,
  OPERATIONS_VIEW: `${URL_BASE}/admin/configurations/operations/showOperation`,
  OPREATION_DOCUMENT: `${URL_BASE}/admin/configurations/documents/getDocumentOperation`,
  OPERATIONS_EXIT: `${URL_BASE}/admin/configurations/operations/getOperationsOutputNotPaginate`,
  OPERATIONS_DESACTIVAR: `${URL_BASE}/admin/configurations/operations/desactiveOperation`,
  OPERATIONS_ACTIVAR: `${URL_BASE}/admin/configurations/operations/activeOperation`,
  OPERATION_LIST_ID: `${URL_BASE}/admin/configurations/operations/getOperationsEntryNotPaginate`,
  OPERATION_LIST_CORRECTED: `${URL_BASE}/admin/configurations/operations/getOperationsEntryNotPaginateCorrected`,
  OPERATION_LIST_ACTIVITIES: `${URL_BASE}/admin/configurations/activities/getActivitiesNotPaginate`,


  /** *********COUNTRY-AGREEMEN CONFIGURATION ***********/
  COUNTRY_GET: `${URL_BASE}/admin/configurations/getCountryTradeAgreements`,
  COUNTRY_UPDATE: `${URL_BASE}/admin/configurations/updateCountryTradeAgreement`,
  COUNTRY_CREATE: `${URL_BASE}/admin/configurations/createCountryTradeAgreement`,
  COUNTRY_DELETE: `${URL_BASE}/admin/configurations/deleteCountryTradeAgreement`,
  COUNTRY_ALL: `${URL_BASE}/admin/configurations/countries/getCountrysNotPaginate`,
  COUNTRY_DESACTIVE: `${URL_BASE}/admin/configurations/desactivateCountryTradeAgreement`,
  COUNTRY_ACTIVE: `${URL_BASE}/admin/configurations/activateCountryTradeAgreement`,

  TRADEAGRAMENT_ALL: `${URL_BASE}/admin/configurations/tradeAgreements/getTradeAgreementsNotPaginate`,

  /** *********AGREEMEN-SUBHEADINGS CONFIGURATION ***********/
  ASUBHEADINS_GET: `${URL_BASE}/admin/configurations/getSubheadingsTradeAgreement`,
  ASUBHEADINS_UPDATE: `${URL_BASE}/admin/configurations/updateSubheadingsTradeAgreement`,
  ASUBHEADINS_CREATE: `${URL_BASE}/admin/configurations/createSubheadingsTradeAgreement`,
  ASUBHEADINS_DELETE: `${URL_BASE}/admin/configurations/deleteSubheadingsTradeAgreement`,
  ASUBHEADINS_ALL: `${URL_BASE}/admin/configurations/subheadings/getSubheadingsNotPaginate`,
  ASUBHEADINS_SHOW: `${URL_BASE}/admin/configurations/subheadings/getSubheadingsNotPaginate`,
  ASUBHEADINS_DESACTIVE: `${URL_BASE}/admin/configurations/desactivateSubheadingsTradeAgreements`,
  ASUBHEADINS_ACTIVE: `${URL_BASE}/admin/configurations/activateSubheadingsTradeAgreements`,

  /** *********PUERTO CONFIGURATION ***********/
  CREATE_PORT: `${URL_BASE}/admin/configurations/freeZonePort/createFreeZonePort`,
  GET_PORT_PAGE: `${URL_BASE}/admin/configurations/freeZonePort/getFreeZonePort`,
  GET_PORT_NO_PAGE: `${URL_BASE}/admin/configurations/freeZonePort/getFreeZonePortNoPaginate`,
  SHOW_PORT: `${URL_BASE}/admin/configurations/freeZonePort/showFreeZonePort`,
  DELETE_PORT: `${URL_BASE}/admin/configurations/freeZonePort/deleteFreeZonePort`,
  UPDATE_PORT: `${URL_BASE}/admin/configurations/freeZonePort/updateFreeZonePort`,
  UPDATE_STATE_PORT: `${URL_BASE}/admin/configurations/freeZonePort/statusFreeZonePort`,

  /** *********LICENSES CONFIGURATION ***********/
  LICENSES_GET: `${URL_BASE}/admin/administrations/licenses/getLicense`,
  LICENSES_UPDATE: `${URL_BASE}/admin/administrations/licenses/updateLicense`,
  LICENSES_CREATE: `${URL_BASE}/admin/administrations/licenses/createLicense`,
  LICENSES_ACTIVATE: `${URL_BASE}/admin/administrations/licenses/activateLicense`,
  LICENSES_DEACTIVATE: `${URL_BASE}/admin/administrations/licenses/desactivateLicense`,
  /** *********PERMISSIONS CONFIGURATION ***********/
  PERMISSION_GET: `${URL_BASE}/admin/configurations/rolPermission/getPermissions`,
  PERMISSION_UPDATE: `${URL_BASE}/admin/configurations/rolPermission/updatePermission`,
  PERMISSION_CREATE: `${URL_BASE}/admin/configurations/rolPermission/createPermission`,
  PERMISSION_DELETE: `${URL_BASE}/admin/configurations/rolPermission/deletePermission`,
  PERMISSION_GET_NOTPAGE: `${URL_BASE}/admin/configurations/rolPermission/getPermissionsNotPaginate`,
  DISASSOCIATE_ROLE: `${URL_BASE}/admin/configurations/rolPermission/disassociateRole`,

  /** *********ROLES CONFIGURATION ***********/
  ROL_GET: `${URL_BASE}/admin/configurations/rolPermission/getRoles`,
  ROL_UPDATE: `${URL_BASE}/admin/configurations/rolPermission/updateRol`,
  ROL_CREATE: `${URL_BASE}/admin/configurations/rolPermission/createRol`,
  ROL_DELETE: `${URL_BASE}/admin/configurations/rolPermission/deleteRol`,
  ROL_SHOW: `${URL_BASE}/admin/configurations/rolPermission/showRol`,
  USER_ROL_SP: `${URL_BASE}/usersQualified/getUsersQualifiedUserNotPaginate`,
  ROL_SP_USER: `${URL_BASE}/admin/configurations/rolPermission/getRolesNotPaginate`,
  USER_ROL_SP_ADMIN: `${URL_BASE}/usersQualified/getUsersQualifiedUserNotPaginate`,

  /** *********Subheading CONFIGURATION ***********/
  SUBHEADING_GET: `${URL_BASE}/admin/configurations/subheadings/getSubheadings`,
  SUBHEADING_UPDATE: `${URL_BASE}/admin/configurations/subheadings/updateSubheading`,
  SUBHEADING_CREATE: `${URL_BASE}/admin/configurations/subheadings/createSubheading`,
  SUBHEADING_DELETE: `${URL_BASE}/admin/configurations/subheadings/deleteSubheading`,
  SUBHEADING_SHOW: `${URL_BASE}/admin/configurations/subheadings/showSubheading`,
  SUBHEADING_DESACTIVE: `${URL_BASE}/admin/configurations/subheadings/desactiveSubheading`,
  SUBHEADING_ACTIVE: `${URL_BASE}/admin/configurations/subheadings/activeSubheading`,
  SUBHEADING_GET_NOT_PAGINATE: `${URL_BASE}/admin/configurations/subheadings/getSubheadingsNotPaginate`,

  /** *********TYPE TRANSPORT CONFIGURATION ***********/
  TYPETRANSP_GET: `${URL_BASE}/admin/configurations/typesTransport/getTypeTransports`,
  TYPETRANSP_UPDATE: `${URL_BASE}/admin/configurations/typesTransport/updateTypeTransport`,
  TYPETRANSP_CREATE: `${URL_BASE}/admin/configurations/typesTransport/createTypeTransport`,
  TYPETRANSP_DELETE: `${URL_BASE}/admin/configurations/typesTransport/deleteTypeTransport`,
  TYPETRANSP_SHOW: `${URL_BASE}/admin/configurations/typesTransport/showTypeTransport`,
  TYPETRANSP_DESACTIVE: `${URL_BASE}/admin/configurations/typesTransport/desactiveTypeTransport`,
  TYPETRANSP_ACTIVE: `${URL_BASE}/admin/configurations/typesTransport/activeTypeTransport`,
  TYPETRANSP_NOT_PAGINATE: `${URL_BASE}/admin/configurations/typesTransport/getTypeTransportsNotPaginate`,

  /** *********SECTORES CONFIGURATION ***********/
  SECTORS_GET: `${URL_BASE}/admin/configurations/sectors/getSectors`,
  SECTORS_UPDATE: `${URL_BASE}/admin/configurations/sectors/updateSector`,
  SECTORS_CREATE: `${URL_BASE}/admin/configurations/sectors/createSector`,
  SECTORS_DELETE: `${URL_BASE}/admin/configurations/sectors/deleteSector`,
  SECTORS_SHOW: `${URL_BASE}/admin/configurations/sectors/showSector`,
  SECTORS_DESACTIVE: `${URL_BASE}/admin/configurations/sectors/desactiveSector`,
  SECTORS_ACTIVE: `${URL_BASE}/admin/configurations/sectors/activeSector`,

  /** *********TYPEZF CONFIGURATION ***********/
  TYPEZF_GET: `${URL_BASE}/admin/configurations/typeFreeZone/getTypeFreeZones`,
  TYPEZF_UPDATE: `${URL_BASE}/admin/configurations/typeFreeZone/updateTypeFreeZone`,
  TYPEZF_CREATE: `${URL_BASE}/admin/configurations/typeFreeZone/createTypeFreeZone`,
  TYPEZF_DELETE: `${URL_BASE}/admin/configurations/typeFreeZone/deleteTypeFreeZone`,
  TYPEZF_SHOW: `${URL_BASE}/admin/configurations/typeFreeZone/showTypeFreeZone`,
  TYPEZF_ALL: `${URL_BASE}/admin/configurations/typeFreeZone/getTypeFreeZonesNotPaginate`,

  /** *********TYPEZF CONFIGURATION ***********/
  TYPE_TARIFF_GET: `${URL_BASE}/admin/configurations/rates_types/getRatesTypes`,
  TYPE_TARIFF_UPDATE: `${URL_BASE}/admin/configurations/rates_types/updateRateType`,
  TYPE_TARIFF_CREATE: `${URL_BASE}/admin/configurations/rates_types/createRateTypes`,
  TYPE_TARIFF_DELETE: `${URL_BASE}/admin/configurations/rates_types/deleteRateType`,
  ACTIVATE_TYPE_ZF: `${URL_BASE}/admin/configurations/typeFreeZone/activeTypeFreeZone`,
  DEACTIVATE_TYPE_ZF: `${URL_BASE}/admin/configurations/typeFreeZone/desactiveTypeFreeZone`,

  /** *********GROUP ZONESF CONFIGURATION ***********/
  GROUPZF_GET: `${URL_BASE}/admin/configurations/groupFreeZones/getGroupFreeZones`,
  GROUPZF_UPDATE: `${URL_BASE}/admin/configurations/groupFreeZones/updateGroupFreeZone`,
  GROUPZF_CREATE: `${URL_BASE}/admin/configurations/groupFreeZones/createGroupFreeZone`,
  GROUPZF_DELETE: `${URL_BASE}/admin/configurations/groupFreeZones/deleteGroupFreeZone`,
  GROUPZF_SHOW: `${URL_BASE}/admin/configurations/groupFreeZones/showGroupFreeZone`,
  GROUPZF_ALL: `${URL_BASE}/admin/configurations/groupFreeZones/getGroupFreeZonesNotPaginate`,

  /** *********PERMISOS CONFIGURATION ***********/
  PERMISOS_GET: `${URL_BASE}/admin/configurations/rolPermission/getPermissions`,
  PERMISOS_CREATE: `${URL_BASE}/admin/configurations/rolPermission/createPermission`,
  PERMISOS_UPDATE: `${URL_BASE}/admin/configurations/rolPermission/updatePermission`,
  PERMISOS_DELETE: `${URL_BASE}/admin/configurations/rolPermission/deletePermission`,
  PERMISOS_SIN_PAGINAR: `${URL_BASE}/admin/configurations/rolPermission/getPermissionsNotPaginate`,

  /** *********LICENCIA CONFIGURATION ***********/
  LICENCIA_GET: `${URL_BASE}/admin/administrations/licenses/getLicense`,
  LICENCIA_SHOW: `${URL_BASE}/admin/administrations/licenses/showLicense`,
  LICENCIA_CREATE: `${URL_BASE}/admin/administrations/licenses/createLicense`,
  LICENCIA_DESACTIVAR: `${URL_BASE}/admin/administrations/licenses/desactivateLicense`,
  LICENCIA_ACTIVAR: `${URL_BASE}/admin/administrations/licenses/activateLicense`,

  LICENCIA_UPDATE: `${URL_BASE}/admin/administrations/licenses/updateLicense`,
  LICENCIA_GET_ALL: `${URL_BASE}/admin/administrations/licenses/getLicenseNotPaginate`,
  LICENCIA_GET_ALL_UC: `${URL_BASE}/admin/administrations/licenses/getLicensesQualifiedUserNotPaginate`,
  // LICENCIA_ASSOCIATED: `${URL_BASE}/admin/administrations/licenses/showLicense`,

  /** *********ACTIVITY USER CONFIGURATION ***********/
  ACTIVITY_USER_GET: `${URL_BASE}/admin/configurations/activities/getActivities`,
  ACTIVITY_USER_CREATE: `${URL_BASE}/admin/configurations/activities/createActivity`,
  ACTIVITY_USER_UPDATE: `${URL_BASE}/admin/configurations/activities/updateActivity`,
  ACTIVITY_USER_DELETE: `${URL_BASE}/admin/configurations/activities/deleteActivity`,
  ACTIVITY_USER_SHOW: `${URL_BASE}/admin/configurations/activities/showActivity`,
  ACTIVE_USER: `${URL_BASE}/admin/configurations/activities/activeActivity`,
  DESACTIVE_USER: `${URL_BASE}/admin/configurations/activities/desactiveActivity`,

  /** *********QUALIFICATION USER CONFIGURATION ***********/
  QUALIFICATION_USER_GET: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/getQualificationQualifiedUsers`,
  QUALIFICATION_USER_CREATE: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/createQualificationQualifiedUser`,
  QUALIFICATION_USER_UPDATE: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/updateQualificationQualifiedUser`,
  QUALIFICATION_USER_DELETE: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/deleteQualificationQualifiedUser`,
  QUALIFICATION_USER_SHOW: `${URL_BASE}/admin/administrations/qualificationQualifiedUser/showQualificationQualifiedUser`,

  /** *********CITIES CONFIGURATION ***********/
  CITIES_GET: `${URL_BASE}/admin/configurations/cities/getCities`,
  CITIES_CREATE: `${URL_BASE}/admin/configurations/cities/createCity`,
  CITIES_UPDATE: `${URL_BASE}/admin/configurations/cities/updateCity`,
  CITIES_DELETE: `${URL_BASE}/admin/configurations/cities/deleteCity`,
  CITIES_SHOW: `${URL_BASE}/admin/configurations/cities/showCity`,
  CITIES_DESACTIVAR: `${URL_BASE}/admin/configurations/cities/desactivateCity`,
  CITIES_ACTIVAR: `${URL_BASE}/admin/configurations/cities/activateCity`,

  /** *********DESTINY CONFIGURATION ***********/
  DESTINY_GET: `${URL_BASE}/admin/configurations/destinations/getDestination`,
  DESTINY_CREATE: `${URL_BASE}/admin/configurations/destinations/createDestination`,
  DESTINY_UPDATE: `${URL_BASE}/admin/configurations/destinations/updateDestination`,
  DESTINY_DELETE: `${URL_BASE}/admin/configurations/destinations/deleteDestination`,
  DETINY_SHOW: `${URL_BASE}/admin/configurations/destinations/showDestiny`,
  DETINY_DESACTIVE: `${URL_BASE}/admin/configurations/destinations/desactiveDestination`,
  DETINY_ACTIVE: `${URL_BASE}/admin/configurations/destinations/activeDestination`,
  DESTINY_NOT_PAGINATE: `${URL_BASE}/admin/configurations/destinations/getDestinationNotPaginate`,

  /** *********DEPENDENCY CONFIGURATION ***********/
  DEPENDENCY_GET: `${URL_BASE}/admin/configurations/dependencies/getDependencies`,
  DEPENDENCY_CREATE: `${URL_BASE}/admin/configurations/dependencies/createDependencies`,
  DEPENDENCY_UPDATE: `${URL_BASE}/admin/configurations/dependencies/updateDependency`,
  DEPENDENCY_DELETE: `${URL_BASE}/admin/configurations/dependencies/deleteDependency`,
  DEPENDENCY_SHOW: `${URL_BASE}/admin/configurations/dependencies/showDependency`,
  DEPENDENCY_DESACTIVE: `${URL_BASE}/admin/configurations/dependencies/desactiveDependency`,
  DEPENDENCY_ACTIVE: `${URL_BASE}/admin/configurations/dependencies/activeDependency`,
  DEPENDENCY_NOT_PAGINATE: `${URL_BASE}/admin/configurations/dependencies/getDependenciesNotPaginate`,

  /** *********PRODUCTS CONFIGURATION ***********/
  PRODUCTS_GET: `${URL_BASE}/admin/configurations/typeProducts/getTypeProducts`,
  PRODUCTS_ALL: `${URL_BASE}/admin/configurations/typeProducts/getTypeProductsNotPaginate`,
  PRODUCTS_CREATE: `${URL_BASE}/admin/configurations/typeProducts/createTypeProduct`,
  PRODUCTS_DELETE: `${URL_BASE}/admin/configurations/typeProducts/deleteTypeProduct`,
  PRODUCTS_SHOW: `${URL_BASE}/admin/configurations/typeProducts/showTypeProduct`,
  PRODUCTS_UPDATE: `${URL_BASE}/admin/configurations/typeProducts/updateTypeProduct`,

  /** *********DOCUMENT REQUIRED CONFIGURATION ***********/
  DOCUMENT_REQUIRED_GET: `${URL_BASE}/admin/configurations/documents/getDocuments`,
  DOCUMENT_REQUIRED_CREATE: `${URL_BASE}/admin/configurations/documents/createDocument`,
  DOCUMENT_REQUIRED_UPDATE: `${URL_BASE}/admin/configurations/documents/updateDocument`,
  DOCUMENT_REQUIRED_DELETE: `${URL_BASE}/admin/configurations/documents/deleteDocument`,
  DOCUMENT_REQUIRED_SHOW: `${URL_BASE}/admin/configurations/documents/showDocument`,
  DOCUMENT_REQUIRED_ALL: `${URL_BASE}/admin/configurations/documents/getDocumentsNotPaginate`,
  DOCUMENT_REQUIRED_ACTIVE: `${URL_BASE}/admin/configurations/documents/activeDocument`,
  DOCUMENT_REQUIRED_DISABLE: `${URL_BASE}/admin/configurations/documents/desactiveDocument`,
  DOCUMENT_REQUIRED_TYPE_ALL: `${URL_BASE}/admin/configurations/documents_types/getDocumentTypesNotPaginated`,

  /** *********DOCUMENT ADD CONFIGURATION ***********/
  DOCUMENT_ADD_USERCA: `${URL_BASE}/admin/configurations/documents/asignedDocumentOperation`,
  DOCUMENT_REMOVE_USERCA: `${URL_BASE}/admin/configurations/documents/desasignedDocumentOperation`,

  /** *********LICENCIA ADD CONFIGURATION ***********/
  LICENCIA_ADD_USERCA: `${URL_BASE}/admin/administrations/licenseUser/asignedLicenseQualifiedUser`,

  /** *********GROUP CONFIGURATION ***********/
  GROUP_GET_ALL: `${URL_BASE}/admin/configurations/groupPermission/getGroupPermissionsNotPaginate`,
  GROUP_GET_ALL_AND_PERMISOS: `${URL_BASE}/admin/configurations/rolPermission/getPermissionsGroupNotPaginate`,
  GROUP_GET: `${URL_BASE}/admin/configurations/groupPermission/getGroupPermissions`,
  GROUP_CREATE: `${URL_BASE}/admin/configurations/groupPermission/createGroupPermission`,
  GROUP_UPDATE: `${URL_BASE}/admin/configurations/groupPermission/updateGroupPermission`,
  GROUP_DELETE: `${URL_BASE}/admin/configurations/groupPermission/deleteGroupPermission`,
  GROUP_SHOW: `${URL_BASE}/admin/configurations/groupPermission/showGroupPermission`,
  GROUP_DESACTIVE: `${URL_BASE}/admin/configurations/groupPermission/desactiveGroupPermission`,
  GROUP_ACTIVE: `${URL_BASE}/admin/configurations/groupPermission/activeGroupPermission`,
  GET_MODULES: `${URL_BASE}/admin/configurations/groupPermission/getModules`,

  /** *********CAPITULO CONFIGURATION ***********/
  CAPITULO_ARANCEL_GET: `${URL_BASE}/admin/configurations/tariffChapter/getTariffChaptersNotPaginate`,

  /** *********TRM CONFIGURATION ***********/
  TRM_GET: `${URL_BASE}/admin/configurations/getTrm`,
  TRM_UPDATE: `${URL_BASE}/admin/configurations/updateTrm`,
  TRM_GET_SUPERFINANCIAL: `${URL_BASE}/admin/configurations/getSuperfinancialTrm`,

  /** *********UNIDAD ARANCEL***********/
  GET_ARANCEL: `${URL_BASE}/admin/configurations/subheadings/showSubheading`,
  GET_ARANCEL_ALL: `${URL_BASE}/admin/configurations/subheadings/getSubheadings`,
  GET_ARANCEL_GENERAL: `${URL_BASE}/admin/configurations/subheadings/getSubheadingsNotPaginate`,
  GET_UNIT_ARANCEL: `${URL_BASE}/admin/configurations/subheadings/getSubheadingProduct`,

  /** *********Typifications***********/
  GET_TYPIFICATIONS: `${URL_BASE}/admin/configurations/typifications/getTypifications`,
  SHOW_TYPIFICATIONS: `${URL_BASE}/admin/configurations/typifications/showTypification`,
  TYPIFICATIONSNOTPAGINATE: `${URL_BASE}/admin/configurations/typifications/getTypificationNotPaginate`,
  UPDATE_TYPIFICATIONS: `${URL_BASE}/admin/configurations/typifications/updateTypification`,
  CREATE_TYPIFICATIONS: `${URL_BASE}/admin/configurations/typifications/createTypification`,
  DELETE_TYPIFICATIONS: `${URL_BASE}/admin/configurations/typifications/deleteTypification`,

  /** *********************************** CONFIGURATION END *******************************************/

  /** *********PASES***********/
  GET_PASES_INT: `${URL_BASE}/entryPasses/getEntryPasses`,
  GET_PASES_INT_CO: `${URL_BASE}/entryPasses/getPresenteEntryPasses`,
  POST_PASES_INT: `${URL_BASE}/entryPasses/getEntryPasses`,
  POST_PASS_CORRECTIONS: `${URL_BASE}/dispatchPassCorrecteds/createDispatchPassCorrected`,
  UPDATE_PASES: `${URL_BASE}/weighings/updatePlatePassWeighing`,
  SHOW_WEIGHING_PESAJE: `${URL_BASE}/weighings/showPassReweight`,
  ATTACH_WEIGHING_PASS: `${URL_BASE}/weighings/saveAttachmentWeigh`,
  ATTACH_DELETE_WEIGHING: `${URL_BASE}/weighings/deleteAttachment`,
  GET_REPORT_LITE_DISPATCH: `${URL_BASE}/weighings/getReportLiteDispatch`,
  CREATE_CONFIG_PASES: `${URL_BASE}/admin/configurations/weighing/saveConfigurationCloseWeighing`,
  CONFIG_PASES: `${URL_BASE}/admin/configurations/weighing/showConfigurationCloseWeighing`,
  UPDATE_PASES_INT: `${URL_BASE}/entryPasses/updateEntryPass`,
  UPDATE_PASES_DISPATCH: `${URL_BASE}/dispatchPasses/updateDispatchPass`,
  SHOW_PASES_INT: `${URL_BASE}/entryPasses/showEntryPass`,
  GET_PASES_INT_NOTPAGINATE: `${URL_BASE}/entryPasses/getEntryPassesNotPaginate`,
  CHANGESTATUSPASS: `${URL_BASE}/entryPasses/changeStatusEntryPass`,
  SAVE_PHOTO: `${URL_BASE}/entryPasses/savePhotographyEntryPass`,
  DELETE_ATTACHMENT: `${URL_BASE}/entryPasses/deleteAttachmentEntryPass`,
  DELETE_PHOTO: `${URL_BASE}/entryPasses/deletePhotographyEntryPass`,
  IMPORT_BULKLOAD: `${URL_BASE}/entryPasses/entryPassformImport`,
  QR_PASES: `${URL_BASE}/entryPasses/createEntryPassQr`,
  GET_SEARCH_PASES_INT: `${URL_BASE}/entryPasses/getEntryPassesSearch`,
  ADJUSTMENT_PASES_INT: `${URL_BASE}/entryPasses/entryPassModifyFormImport`,
  OBTENER_ENTRADA_PASES_INT: `${URL_BASE}/entryPassesItems/getEntryFormsEntryPass`,
  GUARDAR_CORRECION_REVISION: `${URL_BASE}/reviews/saveReviewUpdateInputs`,
  ADD_ITEM_PASES_INT: `${URL_BASE}/entryPassesItems/createEntryPassItem`,
  OBTENER_ITEM_ENTRADA_PASES_INT: `${URL_BASE}/entryPassesItems/getEntryPassItems`,
  DELETE_ITEM_ENTRADA_PASES_INT: `${URL_BASE}/entryPassesItems/deleteEntryPassItem`,
  GET_ENTRY_PASS_ITEM_DETAIL: `${URL_BASE}/entryPassesItems/item_detail`,
  GET_REFERENCE_OP: `${URL_BASE}/productOrderItem/getReferenceOP`,
  GET_PRODUCT_ITEM_OP: `${URL_BASE}/productOrderItem/getProductItemOP`,
  CREATE_PRODUCT_OP: `${URL_BASE}/productOrderItem/createProductToSupplyOP`,
  GET_SUPPLY_ITEM_OP: `${URL_BASE}/productOrderItem/getSupplyItemOP`,
  SHOW_SUPPLY_ITEM_OP: `${URL_BASE}/productOrderItem/showSupplyItemOP`,
  CREATE_SUPPLY_ITEM_OP: `${URL_BASE}/productOrderItem/createSupplyItemOP`,
  UPDATE_SUPPLY_ITEM_OP: `${URL_BASE}/productOrderItem/updateSupplyItemOP`,
  DELETE_SUPPLY_ITEM_OP: `${URL_BASE}/productOrderItem/deleteSupplyItemOP`,
  GET_OTHERCOST_ITEM_OP: `${URL_BASE}/productOrderItem/getOtherCostItemOP`,
  GET_GARBAGE_ITEM_OP: `${URL_BASE}/productOrderItem/getGarbageItemOP`,
  CREATE_PRODUCT_ITEM_OP: `${URL_BASE}/productOrderItem/createProductItemOP`,
  UPDATE_PRODUCT_ITEM_OP: `${URL_BASE}/productOrderItem/updateProductItemOP`,
  DELETE_PRODUCT_ITEM_OP: `${URL_BASE}/productOrderItem/deleteProductItemOP`,
  GET_PERSON_VETO: `${URL_BASE}/security/securityVeto/searchPlateIdentificationEntryAndDispatch`,
  GET_COMMENTS_PASES_INT: `${URL_BASE}/entryPassComments/getEntryPassComments`,
  COMMENTS_PASES_INT: `${URL_BASE}/entryPassComments/createEntryPassComment`,
  GET_CORRECTED_DISPATCH: `${URL_BASE}/dispatchPassCorrecteds/getDispatchPassCorrected`,
  GET_CORRECTED_DISPATCH_SEARCH: `${URL_BASE}/dispatchPassCorrecteds/getDispatchPassesCorrectedSearch`,
  GET_CORRECTED_DISPATCH_SHOW: `${URL_BASE}/dispatchPassCorrecteds/showDispatchPassCorrected`,
  GET_CORRECTED_DISPATCH_ITEMS: `${URL_BASE}/dispatchPassesCorrectedItems/getDispatchPassCorrectedItems`,
  DELETE_CORRECTED_DISPATCH_ITEMS: `${URL_BASE}/dispatchPassesCorrectedItems/deleteDispatchPassItemCorrected`,
  COPY_CORRECTED_DISPATCH_ITEMS: `${URL_BASE}/dispatchPassesCorrectedItems/copyDispatchPassItemCorrected`,
  UPDATE__DISPATCH_STATUS_CORRECTED_ITEM: `${URL_BASE}/dispatchPassesCorrectedItems/updateDispatchPassItemCorrected`,
  CREATE_DISPATCH_STATUS_CORRECTED_ITEM: `${URL_BASE}/dispatchPassesCorrectedItems/createDispatchPassItemCorrected`,
  CHANGE_DISPATCH_STATUS_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/changeStatusDispatchPassCorrected`,
  UPDATE_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/updateDispatchPassCorrected`,
  ADD_ATTACHMENT_OR_PHOTOGRAPHY_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/saveAttachmentPhotographyCorrected`,
  GET_ATTACHMENT_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/getAttachmentDispatchPassCorrected`,
  DELETE_ATTACHMENT_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/deleteAttachmentDispatchPassCorrected`,
  GET_PHOTOGRAPHY_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/getPhotographyDispatchPassCorrected`,
  DELETE_PHOTOGRAPHY_DISPATCH_CORRECTED: `${URL_BASE}/dispatchPassCorrecteds/deletePhotographyDispatchPassCorrected`,
  GET_COMMENTS_CORRECTED_DISPATCH: `${URL_BASE}/dispatchPasscorrectedComments/getDispatchPassCorrectedComments`,
  CREATE_COMMENTS_CORRECTED_DISPATCH: `${URL_BASE}/dispatchPasscorrectedComments/createDispatchPassCorrectedComment`,
  POST_EXPORT_EXCEL_PASS_ENTRY: `${URL_BASE}/entryPasses/exportExcelEntryPasses`,
  POST_EXPORT_EXCEL_PASS_DISPATCH: `${URL_BASE}/dispatchPasses/exportExcelDispatchPasses`,

  /** *********DESPACHO AGRUPADO***********/
  GET_DISPATCH: `${URL_BASE}/dispatchPasses/getDispatchPasses`,
  GET_DISPATCH_CO: `${URL_BASE}/dispatchPasses/getPresentedDispatchPasses`,
  CREATE_DISPATCH: `${URL_BASE}/dispatchPasses/createDispatchPass`,
  CREATE_PASES_IN: `${URL_BASE}/entryPasses/createEntryPass`,
  UPDATE_DISPATCH: `${URL_BASE}/dispatchPasses/updateDispatchPass`,
  SHOW_DISPATCH: `${URL_BASE}/dispatchPasses/showDispatchPass`,
  CHANGESTATUSDISPACTH: `${URL_BASE}/dispatchPasses/changeStatusDispatchPass`,
  DELETE_ATTACHMENT_DISPACTH: `${URL_BASE}/dispatchPasses/deleteAttachmentDispatchPass`,
  DELETE_PHOTO_DISPACTH: `${URL_BASE}/dispatchPasses/deletePhotographyDispatchPass`,
  IMPORT_BULKLOAD_DISPATC: `${URL_BASE}/dispatchPasses/dispatchPassformImport`,
  QR_DESPACHO: `${URL_BASE}/dispatchPasses/createDispatchPassQr`,
  GET_SEARCH_DESPACHO_INT: `${URL_BASE}/dispatchPasses/getDispatchPassesSearch`,
  SEARCH_DISPACTH_FORM_EXIT: `${URL_BASE}/dispatchPassesItems/getOutputFormsDispatchPass`,
  ADD_ITEM_DISPACTH_EXIT: `${URL_BASE}/dispatchPassesItems/createDispatchPassItem`,
  UPDATE_ITEM_DISPACTH_EXIT: `${URL_BASE}/dispatchPassesItems/updateDispatchPassItem`,
  UPDATE_ITEM_DISPACTH_EXIT_BY_DISPATCH: `${URL_BASE}/dispatchPassesItems/createItemDispatchPass`,
  COPY_ITEM_DISPACTH_EXIT: `${URL_BASE}/dispatchPassesItems/copyDispatchPassItem`,
  GET_ITEM_DISPACTH_EXIT: `${URL_BASE}/dispatchPassesItems/getDispatchPassItems`,
  DELETE_ITEM_DISPACTH_EXIT: `${URL_BASE}/dispatchPassesItems/deleteDispatchPassItem`,
  SHOW_REFERENCE: `${URL_BASE}/dispatchPassesItems/showReference`,
  DESASOCIETE_SECURITY_ENTRY: `${URL_BASE}/security/securityEntry/disassociateEntryPass`,
  DESASOCIETE_SECURITY_OUTPUT: `${URL_BASE}/security/securityOutput/disassociateDispatchPass`,
  SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY: `${URL_BASE}/entryPasses/saveAttachmentPhotography`,
  GET_ENTRY_PASSES_FILTER: `${URL_BASE}/entryPasses/getEntryPassesFilter`,
  SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY: `${URL_BASE}/dispatchPasses/saveAttachmentPhotography`,

  /** *********FORMULARIO DE ENTRADAS***********/
  FORMS_ENTRY_GET: `${URL_BASE}/entryForms/getEntryForms`,
  CREATE_FORM_ENTRY: `${URL_BASE}/entryForms/createEntryForm`,
  GET_FILE_URL: `${URL_BASE}/entryForms/getFile`,
  UPDATE_FORM_ENTRY: `${URL_BASE}/entryForms/updateEntryForm`,
  FORMS_ENTRY_VIEW: `${URL_BASE}/entryForms/showEntryForm`,
  COPY_FORMS: `${URL_BASE}/entryForms/copyEntryForm`,
  CHANGE_STATUS_FORMS: `${URL_BASE}/entryForms/changeStatusEntryForm`,
  DELETE_ATTACHMENT_ENTRY: `${URL_BASE}/entryForms/deleteAttachmentEntryForm`,
  DELETE_OTHER_ATTACHMENT_ENTRY: `${URL_BASE}/entryForms/deleteOtherAttachmentEntryForm`,
  COMMENT_FMM_ENTRY: `${URL_BASE}/entryFormComments/createEntryFormComment`,
  GET_COMMENT_FMM_ENTRY: `${URL_BASE}/entryFormComments/getEntryFormComments`,
  SEARCH_FORM_ENTRY: `${URL_BASE}/entryForms/getEntryFormSearch`,
  SEARCH_ADVANCED_FORM_ENTRY: `${URL_BASE}/entryForms/getEntryFormFilter`,
  SEARCH_ADVANCED_FORM_ENTRY_ITEM: `${URL_BASE}/entryFormsItems/getEntryFormItemSearch`,
  LOCK_AND_UNLOCK_OUTPUT_FORM: `${URL_BASE}/outputForms/lock_and_unlock_output_form`,
  LOCK_AND_UNLOCK_ENTRY_FORM: `${URL_BASE}/entryForms/transferNotification`,
  SEARCH_ADVANCED_FORM_ENTRY_EXPIRED: `${URL_BASE}/entryForms/getEntryFormExpired`,
  GENERATE_QR: `${URL_BASE}/admin/configurations/generateQr`,

  SEARCH_ADVANCED_FORM_ACUMULADOS: `${URL_BASE}/entryForms/getEntryFormAccumulatedScales`,
  GET_TYPE_PRODUCTS: `${URL_BASE}/admin/configurations/typeProducts/getTypeProductsNotPaginate`,
  OBTENER_PASS_OF_FMMI: `${URL_BASE}/entryForms/passesEntryForm`,
  REVIEW_FOR_ENTRY: `${URL_BASE}/entryForms/reviewReturnEntryForm`,
  REVIEW_FOR_EXIT: `${URL_BASE}/outputForms/reviewReturnOutputForm`,
  EXPORT_EXCEL_ENTRY: `${URL_BASE}/entryForms/exportExcelEntryForm`,
  SEARCH_FMM0_ENTRY: `${URL_BASE}/outputForms/getOutputFormTranfer`,
  GET_COMERCIAL_INVOICE_ENTRY: `${URL_BASE}/entryFormsItems/showInvoiceEntryFormItem`,
  INSPECTION_OR_FREEPASS: `${URL_BASE}/entryForms/createInspectionFreePass`,
  CALCULATED_ITEM: `${URL_BASE}/entryFormsItems/CalculatedEntryFormItem`,
  ADD_TEMPORAL_ITEM_ONFORM: `${URL_BASE}/entryFormsItems/createEntryFormItemTemporal`,
  CALCULATED_ITEM_EXIT: `${URL_BASE}/outputFormsItems/CalculatedOutputFormItem`,
  CALCULATED_ITEM_CORRECTED: `${URL_BASE}/entryFormsCorectedItems/calculatedEntryFormCorrectedItem`,
  EXPORT_PDF_ENTRYFORM: `${URL_BASE}/entryForms/exportPDFEntryForm`,

  /** *********NOTIFICACIONES DE FORMULARIOS DE ENTRADAS***********/
  ENTRYFORM_TRANSFER_NOTIFICATION: `${URL_BASE}/entryForms/transferNotification`,

  /** ********CORRECIONES INGRESO */
  CORRECT_FMMI: `${URL_BASE}/entryFormCorrecteds/createEntryFormCorrected`,
  CORRECT_FMMI_UPDATE: `${URL_BASE}/entryFormCorrecteds/updateEntryFormCorrected`,
  CORRECT_FMMI_UPDATE_DOCUMENT: `${URL_BASE}/entryFormCorrecteds/saveDocumentEntryFormCorrected`,
  CORRECT_FMMO_UPDATE_DOCUMENT: `${URL_BASE}/outputFormCorrecteds/saveDocumentOutputFormCorrected`,
  CORRECT_FMMI_SHOW: `${URL_BASE}/entryFormCorrecteds/showEntryFormCorrected`,
  CORRECT_FMMI_SHOW_PAYMENT: `${URL_BASE}/entryFormCorrecteds/getPaymentEntryFormCorrected`,
  CORRECT_FMMI_GET: `${URL_BASE}/entryFormCorrecteds/getEntryFormCorrecteds`,
  CORRECT_FMMI_GET_BALANCE: `${URL_BASE}/entryFormCorrectedBalances/getEntryFormCorrectedBalances`,
  CORRECT_CREATE_COMMENT_FMM_ENTRY: `${URL_BASE}/entryFormCorrectedComments/createEntryFormCorrectedComment`,
  CORRECT_GET_COMMENT_FMM_ENTRY: `${URL_BASE}/entryFormCorrectedComments/getEntryFormCorrectedComments`,
  DELETE_ATTACHMENT_ENTRY_CORRECT: `${URL_BASE}/entryFormCorrecteds/deleteAttachmentEntryFormCorrected`,
  DELETE_OTHER_ATTACHMENT_ENTRY_CORRECT: `${URL_BASE}/entryFormCorrecteds/deleteOtherAttachmentEntryFormCorrected`,
  CHANGE_STATUS_FORMI_CORRECT: `${URL_BASE}/entryFormCorrecteds/changeStatusEntryFormCorrected`,
  SEARCH_SIMPLE_FORM_ENTRY_CORRECT: `${URL_BASE}/entryFormCorrecteds/getEntryFormCorrectedFiltro`,
  SEARCH_ADVANCED_FORM_ENTRY_CORRECT: `${URL_BASE}/entryFormCorrecteds/getEntryFormCorrectedFiltro`,
  SEARCH_SIMPLE_FORM_ENTRY_CORRECT_BALANCE: `${URL_BASE}/entryFormCorrectedBalances/getEntryFormCorrectedBalanceSearch`,
  SEARCH_ADVANCED_FORM_ENTRY_CORRECT_BALANCE: `${URL_BASE}/entryFormCorrectedBalances/getEntryFormCorrectedBalanceFiltro`,
  GET_COMERCIAL_INVOICE_ENTRY_CORRECT: `${URL_BASE}/entryFormsCorectedItems/showInvoiceEntryFormCorrectedItem`,

  /** *** CORREGIR ITEMS INGRESO *******/
  UPDATE_CORRECT_ITEM: `${URL_BASE}/entryFormsCorectedItems/updateEntryFormCorrectedItem`,
  CREATE_CORRECT_ITEM: `${URL_BASE}/entryFormsCorectedItems/createEntryFormCorrectedItem`,
  GET_CORRECT_ITEM: `${URL_BASE}/entryFormsCorectedItems/getEntryFormCorrectedItems`,

  SHOW_CORRECT_ITEM: `${URL_BASE}/entryFormsCorectedItems/showEntryFormCorrectedItem`,
  DELECT_CORRECT_ITEM: `${URL_BASE}/entryFormsCorectedItems/deleteEntryFormCorrectedItem`,
  GET_iTEMS_CORRECT_ENTRY_NP: `${URL_BASE}/entryFormsCorectedItems/getEntryFormCorrectedItemsNotPaginate`,
  SEARCH_iTEMS_CORRECT_ENTRY: `${URL_BASE}/entryFormsCorectedItems/getEntryFormCorrectedItemSearch`,
  CANCEL_ENTRY_FORM_CORRECTED_ITEM: `${URL_BASE}/entryFormsCorectedItems/cancelEntryFormCorrectedItem`,

  /** *** CREATION ITEMS *******/
  GET_iTEMS: `${URL_BASE}/entryFormsItems/getEntryFormItems`,
  GET_iTEMS_NP: `${URL_BASE}/entryFormsItems/getEntryFormItemsNotPaginate`,
  CREATE_ITEMS_FORM: `${URL_BASE}/entryFormsItems/createEntryFormItem`,
  UPDATE_ITEMS: `${URL_BASE}/entryFormsItems/updateEntryFormItem`,
  DELETE_ITEMS: `${URL_BASE}/entryFormsItems/deleteItemEntryForm`,
  VIEW_ITEMS: `${URL_BASE}/entryFormsItems/showEntryFormItems`,
  IMPORT_ITEMS_ENTRY: `${URL_BASE}/entryFormsItems/formEntryBulkingLoad`,
  GET_ITEMS_TEMPORARY_ENTRY_FORM: `${URL_BASE}/entryFormsItems/getItemTemporaryEntryForm`,

  /** ******* HISTORIAL DE FORMULARIO *******/
  HISTORY_FORM: `${URL_BASE}/admin/administrations/getHistoryForm`,

  /** *******FORMULARIO DE SALIDA *******/
  CREATE_FORM_EXIT: `${URL_BASE}/outputForms/createOutputForm`,
  FORMS_EXIT_GET: `${URL_BASE}/outputForms/getOutputForms`,
  SHOW_FORM_EXIT: `${URL_BASE}/outputForms/showOutputForm`,
  UPDATE_FORM_EXIT: `${URL_BASE}/outputForms/updateOutputForm`,
  CHANGE_STATUS_FORMS_EXIT: `${URL_BASE}/outputForms/changeStatusOutputForm`,
  COPY_FORMS_EXIT: `${URL_BASE}/outputForms/copyOutputForm`,
  DELETE_ATTACHMENT_EXIT: `${URL_BASE}/outputForms/deleteAttachmentOutputForm`,
  DELETE_OTHER_ATTACHMENT_EXIT: `${URL_BASE}/outputForms/deleteOtherAttachmentOutputForm`,
  COMMENT_FMM_EXIT: `${URL_BASE}/outputFormComments/createOutputFormComment`,
  GET_COMMENT_FMM_EXIT: `${URL_BASE}/outputFormComments/getOutputFormComments`,
  SEARCH_FORM_EXIT: `${URL_BASE}/outputForms/getOutputFormSearch`,
  SEARCH_ADVANCED_FORM_EXIT: `${URL_BASE}/outputForms/getOutputFormFilter`,
  SEARCH_ADVANCED_FORM_EXIT_ITEM: `${URL_BASE}/ouputFormsItems/getOutputFormItemSearch`,
  SEARCH_ADVANCED_FORM_EXIT_EXPIRED: `${URL_BASE}/outputForms/getOutputFormExpired`,
  SEARCH_ADVANCED_FORM_TEMPORARY: `${URL_BASE}/outputForms/getOutputFormTemporal`,
  EXPORT_EXCEL_OUTPUT: `${URL_BASE}/outputForms/exportExcelOutputForm`,
  EXPORT_EXCEL_OUTPUT_TEMPORARY: `${URL_BASE}/outputForms/exportExcelOutputFormTemporal`,
  OBTENER_PASS_OF_FMMO: `${URL_BASE}/outputForms/passesOutputForm`,
  GET_COMERCIAL_INVOICE_OUTPUT: `${URL_BASE}/ouputFormsItems/showInvoiceOutputFormItem`,
  GET_FORMAT_SRT: `${URL_BASE}/ouputFormsItems/getFormatSRT`,
  CREATE_FORMAT_SRT: `${URL_BASE}/ouputFormsItems/createFormatSRT `,
  EXPORT_PDF_OUTPUTFORM: `${URL_BASE}/outputForms/exportPDFOutputForm`,
  SAVE_ENTRY_FORM_TO_OUTPUT_FORM: `${URL_BASE}/outputForms/saveEntryFormToOutputForm`,
  SAVE_PRODUCTS_TO_OUTPUT_FORM: `${URL_BASE}/outputForms/saveProductToOutputForm`,



  /** *******ITEM FORMULARIO DE SALIDA *******/
  IMPORT_ITEMS_OUTPUT: `${URL_BASE}/ouputFormsItems/formOutputBulkingLoad`,
  CREATE_ITEM_FORM_EXIT: `${URL_BASE}/ouputFormsItems/createOutputFormItem`,
  GET_iTEMS_OUTPUT_NP: `${URL_BASE}/ouputFormsItems/getOutputFormItemsNotPaginate`,
  GET_iTEM_EXIT: `${URL_BASE}/ouputFormsItems/getOutputFormItems`,
  SHOW_ITEM_EXIT: `${URL_BASE}/ouputFormsItems/showOutputFormItem`,
  UPDATE_ITEMS_EXIT: `${URL_BASE}/ouputFormsItems/updateOutputFormItem`,
  DELETE_ITEMS_EXIT: `${URL_BASE}/ouputFormsItems/deleteOutputFormItem`,
  IMPORT_ITEMS_EXIT: `${URL_BASE}/ouputFormsItems/importOutputFormItem`,
  CORRECT_CREATE_COMMENT_FMM_OUTPUT: `${URL_BASE}/outputFormCorrectedComments/createOutputFormCorrectedComment`,
  CORRECT_EXPORT_EXCEL_ENTRY: `${URL_BASE}/entryFormCorrecteds/exportExcelEntryFormCorrected`,
  CORRECT_EXPORT_EXCEL_OUTPUT: `${URL_BASE}/outputFormCorrecteds/exportExcelOutputFormCorrected`,

  /** ********CORRECIONES SALIDA */
  CORRECT_FMMO: `${URL_BASE}/outputFormCorrecteds/createOutputFormCorrected`,
  CORRECT_FMMO_UPDATE: `${URL_BASE}/outputFormCorrecteds/updateOutputFormCorrected`,
  CORRECT_FMMO_SHOW: `${URL_BASE}/outputFormCorrecteds/showOutputFormCorrected`,
  CORRECT_FMMO_SHOW_PAYMENT: `${URL_BASE}/outputFormCorrecteds/getPaymentOutputFormCorrected`,
  CORRECT_FMMO_GET: `${URL_BASE}/outputFormCorrecteds/getOutputFormCorrecteds`,
  CORRECT_FMMO_GET_BALANCE: `${URL_BASE}/outputFormCorrectedBalances/getOutputFormCorrectedBalances`,
  DELETE_ATTACHMENT_OUTPUT_CORRECT: `${URL_BASE}/outputFormCorrecteds/deleteAttachmentOutputFormCorrected`,
  DELETE_OTHER_ATTACHMENT_OUTPUT_CORRECT: `${URL_BASE}/outputFormCorrecteds/deleteOtherAttachmentOutputFormCorrected`,
  CHANGE_STATUS_FORMO_CORRECT: `${URL_BASE}/outputFormCorrecteds/changeStatusOutputFormCorrected`,
  CORRECT_GET_COMMENT_FMM_0UTPUT: `${URL_BASE}/outputFormCorrectedComments/getOutputFormCorrectedComments`,
  CORRECT_CREATE_COMMENT_FMM_OUTPUT: `${URL_BASE}/outputFormCorrectedComments/createOutputFormCorrectedComment`,
  SEARCH_SIMPLE_FORM_EXIT_CORRECT: `${URL_BASE}/outputFormCorrecteds/getOutputFormCorrectedFiltro`,
  SEARCH_ADVANCED_FORM_EXIT_CORRECT: `${URL_BASE}/outputFormCorrecteds/getOutputFormCorrectedFiltro`,
  SEARCH_SIMPLE_FORM_EXIT_CORRECT_BALANCE: `${URL_BASE}/outputFormCorrectedBalances/getOutputFormCorrectedBalanceSearch`,
  SEARCH_ADVANCED_FORM_EXIT_CORRECT_BALANCE: `${URL_BASE}/outputFormCorrectedBalances/getOutputFormCorrectedBalanceFiltro`,
  GET_COMERCIAL_INVOICE_OUTPUT_CORRECT: `${URL_BASE}/outputFormsCorectedItems/showInvoiceOutputFormCorrectedItem`,
  EXPORT_INTEGRATION_CERTICATE: `${URL_BASE}/integrationCertificate/exportIntegrationCertificate`,

  /** *** CORREGIR ITEMS SALIDA *******/
  UPDATE_CORRECT_ITEM_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/updateOutputFormCorrectedItem`,
  CREATE_CORRECT_ITEM_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/createOutputFormCorrectedItem`,
  GET_CORRECT_ITEM_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/getOutputFormCorrectedItems`,
  SHOW_CORRECT_ITEM_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/showOutputFormCorrectedItem`,
  DELECT_CORRECT_ITEM_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/deleteOutputFormCorrectedItem`,
  GET_iTEMS_CORRECT_OUTPUT_NP: `${URL_BASE}/outputFormsCorectedItems/getOutputFormCorrectedItemsNotPaginate`,
  SEARCH_iTEMS_CORRECT_OUTPUT: `${URL_BASE}/outputFormsCorectedItems/getOutputFormCorrectedItemSearch`,
  CANCEL_OUTPUT_FORM_CORRECTED_ITEM: `${URL_BASE}/outputFormsCorectedItems/cancelOutputFormCorrectedItem`,

  /** **************CUADRO DE INTEGRACION********************/
  CREATE_INTEGRATION_BOX_EDIT: `${URL_BASE}/integrationCertificate/createIntegrationCertificate`,
  EDIT_ELABORATEDPRODUCT_OR_PRODUCT_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/updateIntegrationCertificateProductOrOPCorrected`,
  CREATE_NEW_ORDER_OR_PRODUCT_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/createItemProductOrProductionOrderCICorrected`,
  UPDATE_SUPPLY_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/saveSuppliesToProductCorrected`,
  CREATE_SUPPLY_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/createItemSupplyOrProductSupplyCICorrected`,
  SAVE_HEAD_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/updateIntegrationCertificateCorrected`,
  DELETE_CORRECTED_PRODUCT_AND_ORDER: `${URL_BASE}/integrationCertificateCorrecteds/deleteProductCICorrected`,
  DELETE_CORRECTED_OTHER_COST: `${URL_BASE}/integrationCertificateCorrecteds/deleteOtherCostCICorrected`,
  DELETE_CORRECTED_SUPPLY: `${URL_BASE}/integrationCertificateCorrecteds/deleteSupplyCICorrected`,

  CREATE_INTEGRATION_BOX_HEAD: `${URL_BASE}/integrationCertificate/updateIntegrationCertificate`,
  POST_STATUS_INTEGRATION: `${URL_BASE}/integrationCertificate/statusIntegrationCertificate`,
  CREATE_OTHERS_COST: `${URL_BASE}/integrationCertificate/saveOtherCost`,
  CREATE_OTHERS_COST_OP: `${URL_BASE}/productOrderItem/createOtherCostItemOP`,
  UPDATE_OTHERS_COST_OP: `${URL_BASE}/productOrderItem/updateOtherCostItemOP`,
  DELETE_OTHERS_COST_OP: `${URL_BASE}/productOrderItem/deleteOtherCostItemOP`,
  CREATE_OTHERS_COST_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/createItemOtherCostCICorrected`,
  UPDATE_OTHERS_COST: `${URL_BASE}/integrationCertificate/updateOthercostCI`,
  UPDATE_OTHERS_COST_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/updateOthercostCICorrected`,
  SHOW_OTHERS_COST: `${URL_BASE}/integrationCertificate/showOthercostCI`,
  GET_REFERENCES: `${URL_BASE}/integrationCertificate/getReferenceSearch`,
  SHOW_REFERENCES_SUPPLY: `${URL_BASE}/integrationCertificate/showSupplyCI`,
  GET_UPDATE_EDIT_SUPPLY: `${URL_BASE}/integrationCertificate/updateSupplyCI`,
  DELETE_OTHER_COST: `${URL_BASE}/integrationCertificate/deleteOtherCostCI`,
  GET_SHOW_SUPPLIES_CORRECTED: `${URL_BASE}/integrationCertificate/showSuppliesToEditCI`,
  SHOW_SUPPLIES_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/showSuppliesToEditCiCorrected`,

  GET_SHOW_OTHER_COST_CORRECTED: `${URL_BASE}/integrationCertificate/showOtherCostsToEditCI`,
  GET_SHOW_OTHER_COST_CORRECTEDS: `${URL_BASE}/integrationCertificateCorrecteds/showOtherCostsToEditCICorrected`,

  CHANGE_STATUS_AUTORIZED_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/statusIntegrationCertificateCorrected`,
  ADD_PRODUCT_TO_SUPPLIES: `${URL_BASE}/integrationCertificate/saveProductToSupply`,
  EDIT_DESPERDICIOS: `${URL_BASE}/integrationCertificate/createWasteSupplyCI`,
  CHANGE_STATUS_INTEGRATION_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/statusIntegrationCertificateCorrected`,
  //
  CREATE_INTEGRATION_BOX: `${URL_BASE}/integrationCertificate/createIntegrationCertificateForm`,
  GET_iNTEGRATION_BOX: `${URL_BASE}/integrationCertificate/getIntegrationCertificate`,
  SEARCH_INTEGRATION_CERTIFICATE: `${URL_BASE}/integrationCertificate/searchIntegrationCertificate`, //endpoint para la busqueda simple en ci
  GET_iNTEGRATION_BOX_CORRECTED_TABLE: `${URL_BASE}/integrationCertificateCorrecteds/showIntegrationCertificatedCorrectedID`,
  BULKLOAD_iNTEGRATION_BOX: `${URL_BASE}/integrationCertificate/integrationCertBulkingLoad`,
  GET_iNTEGRATION_BOX_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/getIntegrationCertificateCorrected`,
  GET_INTEGRATION_BOX_CORRECTED_SEARCH: `${URL_BASE}/integrationCertificateCorrecteds/getIntegrationCertificateCorrectedSearch`,
  SHOW_INTEGRACION_BOX_ID: `${URL_BASE}/integrationCertificate/showIntegrationCertificate`,
  SHOW_INTEGRACION_BOX_ID_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/showIntegrationCertificateCorrected`,
  GET_LOG_PRODUCTS: `${URL_BASE}/products/getLogEditProducts`,
  DELETE_INTEGRATION_BOX: `${URL_BASE}/auth/products/integrationCertificate/deleteIntegrationCertificate`,
  // IMPORT_ITEMS_EXIT: `${URL_BASE}/ouputFormsItems/importOutputFormItem`,
  ORDEN_PRODUCCION_NOT_PAGINATE: `${URL_BASE}/productOrder/getProductOrderNoPaginate`,
  ORDEN_PRODUCCION_NOT_PAGINATE_CI: `${URL_BASE}/productOrder/getProductOrderNoPaginateAuthorized`,
  GET_ORDER: `${URL_BASE}/productOrder/showProductOrder`,
  CALCULATED_ORDER: `${URL_BASE}/integrationCertificate/calculations`,
  CHANGE_STATUS_BOX: `${URL_BASE}/integrationCertificate/statusIntegrationCertificate`,
  CHANGE_STATUS_PRESENT: `${URL_BASE}/integrationCertificate/submitIntegrationCertificate`,
  COPY_BOX: `${URL_BASE}/integrationCertificate/copyIntegrationCertificate`,
  DELETE_ORDER_PRODUCTION: `${URL_BASE}/integrationCertificate/deleteProductCI`,
  DELETE_SUPPLIES_PRODUCTS: `${URL_BASE}/integrationCertificate/deleteSupplyCI`,
  GET_UPDATE_ORDER_PRODUCTION: `${URL_BASE}/integrationCertificate/showProductOrderOnEditCI`,
  GET_ORDER_PRODUCTION_CORRECTED_AND_OLD: `${URL_BASE}/integrationCertificateCorrecteds/showProductOrderOnEditCICorrected`,
  GET_NOT_PAGINATE_PRODUCT: `${URL_BASE}/inventory/getInventoryNotPaginated`,
  REVIEW_RETURN_INTEGRATION: `${URL_BASE}/integrationCertificate/reviewReturnIntegrationCertificate`,
  SUPPLIES_INTEGRATION: `${URL_BASE}/integrationCertificate/saveSuppliesToProduct`,
  DELETE_GARBAGE_CI: `${URL_BASE}/integrationCertificate/deleteGarbageCI`,
  SHOW_SUPPLY_DATA_CI: `${URL_BASE}/integrationCertificate/showSupplyDataCI`,
  EXPORT_CI_PDF: `${URL_BASE}/integrationCertificate/exportCIPDF`,
  CREATE_INTEGRATION_CERTIFICATE_TEMPLATE: `${URL_BASE}/integrationCertificate/createIntegrationCertificateTemplate`,
  CREATE_INTEGRATION_CERTIFICATE_TEMPLATE_OP: `${URL_BASE}/products/productiveProcessFormSupplies/createProductiveProcessFormSupplies`,


  /* INTEGRATION PANELS */
  GET_PRODUCTS_CI: `${URL_BASE}/integrationCertificate/getProductsCI`,
  GET_SUPPLYS_CI: `${URL_BASE}/integrationCertificate/getSupplysCI`,
  GET_OTHER_COST_CI: `${URL_BASE}/integrationCertificate/getOtherCostCI`,
  GET_GARBAGES_CI: `${URL_BASE}/integrationCertificate/getGarbagesCI`,
  GET_CERTIFICATE_TYPE:  `${URL_BASE}/integrationCertificate/getIntegrationCertificateType`,
  /* INTEGRATION PANELS CORRECTED */
  GET_PRODUCTS_CI_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/getProductsCICorrected`,
  GET_SUPPLYS_CI_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/getSupplysCICorrected`,
  GET_OTHER_COST_CI_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/getOtherCostCICorrected`,
  GET_GARBAGES_CI_CORRECTED: `${URL_BASE}/integrationCertificateCorrecteds/getGarbagesCICorrected`,
  CREATE_INTEGRATION_CERTIFICATE_TEMPLATE_CIC: `${URL_BASE}/integrationCertificateCorrecteds/createIntegrationCertificateTemplateCorrected`,


  /** **************BASCULAS********************/
  CREATE_WEIGHING: `${URL_BASE}/scales/createScale`,
  GET_WEIGHING: `${URL_BASE}/scales/getScales`,
  DELETE_WEIGHING: `${URL_BASE}/scales/deleteScale`,
  UPDATE_WEIGHING: `${URL_BASE}/scales/updateScale`,
  SHOW_WEIGHING: `${URL_BASE}/scales/showScale`,
  SHOW_PLATE_WEIGHING: `${URL_BASE}/weighings/showPassPlate`,
  ASSOCIATE_WEIGHING_PASS: `${URL_BASE}/weighings/createAssociateWeighing`,
  CHANGE_STATUS_WEIGHING: `${URL_BASE}/weighings/changeStatusWeighing`,
  ASSOCIATE_WEIGHING_GET: `${URL_BASE}/weighings/getWeighing`,
  REPORT_WEIGHING_GET: `${URL_BASE}/weighings/getWeighingQueue`,
  WEIGHING_SHOW: `${URL_BASE}/weighings/showWeighing`,
  WEIGHING_SHOW_EDIT: `${URL_BASE}/weighings/updateWeighing`,
  WEIGHING_EXPORT_EXCEL: `${URL_BASE}/weighings/exportWeighing`,
  WEIGHING_EXPORT_EXCEL_ADVANCE: `${URL_BASE}/weighings/exportModuleWeighing`,
  GET_USERS_WEIGHING: `${URL_BASE}/weighings/getUsersWeighing`,
  ACTIVE_AND_DESACTIVE_WEIGHING_MANUAL: `${URL_BASE}/weighings/activeDesactiveWeighingManual`,
  GET_COMMENTS_PASES_DISPATCH: `${URL_BASE}/dispatchPassComments/getDispatchPassComments`,
  COMMENTS_PASES_DISPATCH: `${URL_BASE}/dispatchPassComments/createDispatchPassComment`,

  /** **************ESTADISTICAS********************/
  OBTENER_STATISTICS: `${URL_BASE}/admin/administrations/statistics/getStatistics`,
  OBTENER_STATISTICS_OP: `${URL_BASE}/admin/administrations/statistics/statisticsDocumentOperationQualifiedUserGraph`,
  OBTENER_STATISTICS_OP_DATA: `${URL_BASE}/admin/administrations/statistics/statisticsDocumentOperationQualifiedUser`,
  OBTENER_STATISTICS_OPE: `${URL_BASE}/admin/administrations/statistics/statisticsOperationCountOperationItems`,
  OBTENER_STATISTICS_VETO: `${URL_BASE}/admin/administrations/statistics/statisticsSecurityVeto`,
  OBTENER_STATISTICS_VEHICLE: `${URL_BASE}/admin/administrations/statistics/statisticsSecurityCargoVehicles`,
  OBTENER_STATISTICS_OPERATION_USER: `${URL_BASE}/admin/administrations/statistics/statisticsOperationEntryOutput`,
  OBTENER_STATISTICS_USER_QUALIFIED: `${URL_BASE}/admin/administrations/statistics/statisticsSecurityQualifiedUsers`,
  OBTENER_STATISTICS_MOVIMIENTO: `${URL_BASE}/admin/administrations/statistics/statisticsEntryDispatch`,
  POST_EXPORT_PDF: `${URL_BASE}/admin/administrations/statistics/exports/exportPDFEstatistics`,
  POST_EXPORT_EXCEL_OP_ENTRY_OUT: `${URL_BASE}/admin/administrations/statistics/exports/exportExcelEstatisticsOperationEntryOutput`,
  POST_EXPORT_EXCEL_VEHICLE: `${URL_BASE}/admin/administrations/statistics/exports/exportExcelStatisticsSecurityVehicle`,
  POST_EXPORT_EXCEL_VETO: `${URL_BASE}/security/securityVeto/exportExcelStatisticsSecurityVeto`,
  POST_EXPORT_EXCEL_QUALIFIEDUSER: `${URL_BASE}/admin/administrations/statistics/exports/exportExcelStatisticsSecurityQualifiedUser`,
  STATISTICS_OPERATION_DOCUMENTS: `${URL_BASE}/admin/administrations/statistics/statisticsOperationDocuments`,
  EXPORT_STATISTICS_OPERATION_DOCUMENTS: `${URL_BASE}/admin/administrations/statistics/exports/exportStatisticsOperationDocuments`,
  /** **************DESADUANAMIENTO ********************/
  GET_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/getCustomsClearances`,
  CREATE_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/createCustomsClearance`,
  UPDATE_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/updateCustomsClearance`,
  SHOW_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/showCustomsClearance`,
  SEARCH_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/getCustomsClearancesSearch`,
  GET_SUBHEADING_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/getSubheadingCustomsClearance`,
  GET_QR_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/createCustomsClearanceQr`,
  EXPORT_DOCUMENT_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/exportCustomsClearance`,
  DELETE_ATTACHMENT_CUSTOMS: `${URL_BASE}/customsClearance/deleteAttachmentCustomsClearance`,
  DELETE_OTHER_ATTACHMENT_CUSTOMS: `${URL_BASE}/customsClearance/deleteOtherAttachmentCustomsClearance`,
  CUSTOMS_CHANGE_STATUS: `${URL_BASE}/customsClearance/changeStatusCustomsClearance`,
  // FORM_CUSTOMS_CHANGE_STATUS: `${URL_BASE}/customsClearance/getItemsFormCustomsClearances`,
  COPY_FORM_CUSTOMS_CLEARENCE: `${URL_BASE}/customsClearance/copyCustomsClearance`,
  FORM_CUSTOMS_CHANGE_STATUS: `${URL_BASE}/customsClearanceItems/getItemsFormCustomsClearances`,
  SHOW_ITEM_FORM_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/showItemFormCustomsClearance`,
  CREATE_ITEM_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/createItemCustomsClearance`,
  ITEMS_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/getItemsCustomsClearances`,
  SEARCH_ITEMS_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/showItemCustomsClearance`,
  UPDATE_ITEMS_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/updateItemCustomsClearance`,
  // DELETE_ATTACHMENT_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearance/deleteAttachmentCustomsClearance`,
  GET_COMMENTARY_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceComments/getCustomsClearanceComments`,
  CREATE_COMMENTARY_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceComments/createCustomsClearanceComment`,
  DELETE_ITEMS_CUSTOMS_CLEARANCE: `${URL_BASE}/customsClearanceItems/deleteItemCustomsClearance`,

  /** *********INVENTARIO ***********/
  GET_INVENTORY: `${URL_BASE}/inventory/getInventory`,
  GET_INVENTORY_AUDIT: `${URL_BASE}/inventory/getInventoryForAudith`,
  GET_CHANGE_SUBHEANDING_INVENTORY: `${URL_BASE}/inventory/getTariffInventory`,
  UPDATE_CHANGE_SUBHEANDING_INVENTORY: `${URL_BASE}/inventory/updateTariffInventory`,

  GET_CHANGE_KARDEX_INVENTORY: `${URL_BASE}/inventory/getKardexInventory`,
  UPDATE_CHANGE_KARDEX_INVENTORY: `${URL_BASE}/inventory/updateKardexInventory`,

  GET_REFERENCE_AUX: `${URL_BASE}/inventory/getInventoryRefAux`,
  GET_BALANCE_AUX: `${URL_BASE}/inventory/getInventoryRefBalance`,
  GET_REFERENCE_OUT: `${URL_BASE}/inventory/getInventoryRefGoOut`,
  GET_REFERENCE_ONLY: `${URL_BASE}/inventory/getInventoryReference`,
  GET_TEMP_AUX: `${URL_BASE}/inventory/getInventoryRefTemporary`,
  GET_PRODUCT_AUX: `${URL_BASE}/inventory/getInventoryProduct`,
  EXPOR_EXCEL_INVENTORY: `${URL_BASE}/inventory/exportExcelInventory`,
  EXPOR_EXCEL_INVENTORY_AUDIT: `${URL_BASE}/inventory/exportExcelInventoryAudit`,
  GET_INVENTORY_AUDIT_SELECTS: `${URL_BASE}/inventory/getCategoryForAudith`,
  CREATE_INVENTORY_AUDIT_FILTER: `${URL_BASE}/inventory/createCategoryForAudith`,
  GET_INVENTORY_SUBHEADING: `${URL_BASE}/inventory/getArticleForInventoryAudith`,

  EXPOR_EXCEL_AUXILIAR_REFERENCE: `${URL_BASE}/inventory/exportExcelInventoryRefAux`,
  EXPOR_EXCEL_AUXILIAR_BALANCE: `${URL_BASE}/inventory/exportExcelInventoryRefBalance`,
  EXPOR_EXCEL_AUXILIAR_EXIT: `${URL_BASE}/inventory/exportExcelInventoryRefGoOut`,
  EXPOR_EXCEL_AUXILIAR_TEMPORAL: `${URL_BASE}/inventory/exportExcelInventoryRefTemporal`,
  EXPOR_EXCEL_REF_PRODUCT: `${URL_BASE}/inventory/exportExcelInventoryRefProduct`,

  /** *********FIN INVENTARIO ***********/

  /** *********ORDEN DE PRODUCCION ***********/
  CREATE_PRODUCTION_ORDER: `${URL_BASE}/productOrder/createProductOrderForm`,
  DELETE_PRODUCTION_ORDER: `${URL_BASE}/productOrder/deleteProductOrder`,
  GET_PRODUCTION_ORDER: `${URL_BASE}/productOrder/getProductOrder`,
  CREATE_CI_PRODUCTION_ORDER: `${URL_BASE}/productOrder/createIntegrationCertificateProductOrder`,
  GET_PRODUCTION_ORDER_SEARCH: `${URL_BASE}/productOrder/getProductOrderSearch`,
  SHOW_PRODUCTION_ORDER: `${URL_BASE}/productOrder/showProductOrder`,
  CREATE_OFTEMPLATE_PRODUCTION_ORDER: `${URL_BASE}/productOrder/createProductOrder`,
  UPDATE_PRODUCTION_ORDER: `${URL_BASE}/productOrder/updateProductOrder`,
  DELETE_ITEM_PRODUCTION_ORDER: `${URL_BASE}/productOrder/unlinkProductionOrderForm`,
  COPY_PRODUCTION_ORDER: `${URL_BASE}/productOrder/copyProductOrder`,
  EXPORT_DOCUMENT_PRODUCTION_ORDER: `${URL_BASE}/productOrder/exportExcelProductOrder`,
  CREATE_DPS_ORDER: `${URL_BASE}/productOrder/createWasteSupplyProductOrder`,

  /** *********FIN INVENTARIO ***********/

  /** *********MOTIVO DEVOLUCION***********/

  GET_RETURN_REASON: `${URL_BASE}/reasonReturn/getReasonReturns`,

  /** *********FIN MOTIVO DEVOLUCION ***********/

  /** *********REFERENCIA DE PRODUCTO INTERNO***********/
  /** *********PRODUCTO INTERNO***********/
  GET_PRODUCT_INT: `${URL_BASE}/products/getProducts`,
  CREATE_PRODUCT_INT: `${URL_BASE}/products/createProduct`,
  UPDATE_PRODUCT_INT: `${URL_BASE}/products/updateProduct`,
  DELETE_PRODUCT_INT: `${URL_BASE}/products/deleteProduct`,
  SHOW_PRODUCT_INT: `${URL_BASE}/products/showProduct`,
  CHANGE_STATUS_PRODUCT: `${URL_BASE}/products/changeStatusProduct`,
  IMPORT_PRODUCT_INT: `${URL_BASE}/products/importProduct`,
  EXPORT_PRODUCT: `${URL_BASE}/products/exportExcelProducts`,
  EXPORT_PRODUCTIVE_PROCESS: `${URL_BASE}/products/productiveProcess/exportProductiveProcess`,

  /** *********DANE CONFIGURATION ***********/
  OBTENER_DANE: `${URL_BASE}/admin/configurations/ConfigurationDane/getConfigurationDane`,
  UPDATE_DANE: `${URL_BASE}/admin/configurations/ConfigurationDane/updateConfigurationDane`,
  CREATE_DANE: `${URL_BASE}/admin/configurations/ConfigurationDane/updateConfigurationDane`,
  EXPORT_DANE_MANUEL: `${URL_BASE}/admin/administrations/statistics/getReportDaneExport`,

  /** *********UNIT OF MEASUREMENT CONFIGURATION ***********/
  UNIT_GET: `${URL_BASE}/products/unitMeasuremente/getUnitMeasurementQualifiedUser`,
  UNIT_UPDATE: `${URL_BASE}/products/unitMeasuremente/updateUnitMeasurementQualifiedUser`,
  UNIT_CREATE: `${URL_BASE}/products/unitMeasuremente/createUnitMeasurementQualifiedUser`,
  UNIT_DELETE: `${URL_BASE}/products/unitMeasuremente/deleteUnitMeasurementQualifiedUser`,
  UNIT_ALL: `${URL_BASE}/products/unitMeasuremente/getUnitMeasurementQualifiedUserNotPaginate`,
  UNIT_SUP: `${URL_BASE}/products/unitMeasuremente/getUnitMeasurementQualifiedUserAdminNotPaginate`,
  UNIT_SHOW: `${URL_BASE}/products/unitMeasuremente/showUnitMeasurementQualifiedUser`,
  CHANGE_STATUS_UNIT_MEASUREMENT: `${URL_BASE}/products/unitMeasuremente/changeStatusUnitMeasurement`,
  CHANGE_STATUS_PRODUCTIVE: `${URL_BASE}/products/productiveProcess/changeProductiveProcess`,

  /** *********PLANTILLAS***********/
  GET_PROCESO_ALL_ADMIN: `${URL_BASE}/products/productiveProcess/getProductsProductiveProcess`,

  GET_PROCESO_ALL: `${URL_BASE}/products/productiveProcess/getProductiveProcess`,
  CREATE_PROCESO: `${URL_BASE}/products/productiveProcess/createProductiveProces`,
  UPDATE_PROCESO: `${URL_BASE}/products/productiveProcess/updateProductiveProces`,
  DELTE_PROCESO: `${URL_BASE}/administrations/productiveProcessProduct/deleteProductiveProcessProduct`,
  SHOW_PROCESO: `${URL_BASE}/products/productiveProcess/showProductiveProces`,
  GET_PROCESO_USER: `${URL_BASE}/products/productiveProcess/showProductiveProcesQualifiedUserId`,
  PROCESO_IMPORT: `${URL_BASE}/products/unitMeasuremente/importUnitMeasurementQualifiedUser`,
  PROCESO_EXPORT: `${URL_BASE}/products/unitMeasuremente/exportUnitMeasurementQualifiedUser`,

  /** *********PRODUCTO DE PLANTILLAS***********/
  PRODUCT_PROCESS: `${URL_BASE}/products/productiveProcessProduct/getProductiveProcessProducts`,
  PRODUCT_PROCESS_SELECT: `${URL_BASE}/products/productiveProcess/getProductsWithCodeAdmin`,
  PRODUCTSUSERAALL: `${URL_BASE}/products/productiveProcess/getProductiveProcessProductsAdmin`,
  PRODUCTSREFERENCEUSERAALL: `${URL_BASE}/products/productiveProcess/getProductsWithCodeAdmin`,
  GET_REFERENCE_OUPUT_FORM: `${URL_BASE}/ouputFormsItems/getReferenceOutputForm`,
  ADD_PRODUCT_PROCESS: `${URL_BASE}/products/productiveProcessProduct/createProductiveProcessProduct`,
  UPDATE_PRODUCT_PROCESS: `${URL_BASE}/products/productiveProcessProduct/updateProductiveProcessProduct`,
  DELETE_PRODUCT_PROCESS: `${URL_BASE}/products/productiveProcessProduct/deleteProductiveProcessProduct`,
  CHANGE_STATUS_PRODUCT_PROCESS: `${URL_BASE}/productOrder/changeStatusProductionOrderForm`,

  /** *********INSUMO DE PLANTILLAS***********/
  INSUMO_PROCESS: `${URL_BASE}/products/productiveProcessFormSupplies/getProductiveProcessFormSuppliess`,
  ADD_INSUMOS_PROCESS: `${URL_BASE}/products/productiveProcessFormSupplies/createProductiveProcessFormSupplies`,
  UPDATE_INSUMOS_PROCESS: `${URL_BASE}/products/productiveProcessFormSupplies/updateProductiveProcessFormSupplies`,
  DELETE_INSUMOS_PROCESS: `${URL_BASE}/products/productiveProcessFormSupplies/deleteProductiveProcessFormSupplies`,

  /** *********OTRO DE PLANTILLAS***********/
  OTHER_PROCESS: `${URL_BASE}/products/otherCosts/getProductiveProcessFormOtherCosts`,
  ADD_OTHER_PROCESS: `${URL_BASE}/products/otherCosts/createProductiveProcessFormOtherCosts`,
  UPDATE_OTHER_PROCESS: `${URL_BASE}/products/otherCosts/updateProductiveProcessFormOtherCosts`,
  DELETE_OTHER_PROCESS: `${URL_BASE}/products/otherCosts/deleteProductiveProcessFormOtherCosts`,

  /** *********TERCEROS***********/
  GET_THIRD_PARTIES: `${URL_BASE}/thirdParty/getThirdParties`,
  CREATE_THIRD_PARTY: `${URL_BASE}/thirdParty/createThirdParty`,
  UPDATE_THIRD_PARTY: `${URL_BASE}/thirdParty/updateThirdParty`,
  EXPORT_THIRD_PARTY: `${URL_BASE}/thirdParty/exportExcelThirdParty`,
  DELETE_THIRD_PARTY: `${URL_BASE}/thirdParty/deleteThirdParty`,
  THIRD_PARTY_NOT_PAGINATE: `${URL_BASE}/thirdParty/getThirdPartiesNotPaginate`,
  THIRD_PARTY_IMPORT: `${URL_BASE}/thirdParty/importThirdParties`,
  CHANGE_STATUS_THIRD_PARTY: `${URL_BASE}/thirdParty/changeStatusThirdParty`,
  DOCUMENT_SAVE_THIRD_PARTY: `${URL_BASE}/thirdParty/DocumentSaveThirdParty`,
  DOCUMENT_DELETE_THIRD_PARTY: `${URL_BASE}/thirdParty/DocumentDeleteThirdParty`,

  /** *********GESTION COMERCIAL***********/
  GET_GESTION: `${URL_BASE}/usersQualified/getUsersQualifiedUser`,
  CREATE_GESTION: `${URL_BASE}/usersQualified/createUserQualifiedUser`,
  UPDATE_GESTION: `${URL_BASE}/usersQualified/updateUserQualifiedUser`,
  SHOW_GESTION: `${URL_BASE}/usersQualified/showUserQualifiedUser`,
  CHANGESTATUS_GESTION: `${URL_BASE}/usersQualified/changeStatusQualifiedUser`,

  /** *********Revisión de documentos***********/
  GET_Reviews_Entry: `${URL_BASE}/reviews/getReviewEntryForms`,
  GET_ROBOT_FORM: `${URL_BASE}/reviews/getReviewRobotMode`,
  GET_Reviews_Exit: `${URL_BASE}/reviews/getReviewOutputForms`,
  GET_USERS_REVIEWER_NOT_PAGE: `${URL_BASE}/reviews/getReviewerUsers`,
  GET_Reviews_Integration: `${URL_BASE}/reviews/getReviewIntegrationCertificate`,
  Post_Reviews_QualifiedUser: `${URL_BASE}/reviews/assignQualifiedUserReview`,
  POST_QUALIFIED_USER_ASSOCIATED: `${URL_BASE}/reviews/updateQualifiedUserAssociatedReviewUsers`,
  POST_APROVED_OUTPUT_AUTHORIZE: `${URL_BASE}/reviews/saveReviewAprovedOutputFormToAuthorize`,
  POST_APROVED_ENTRY_AUTHORIZE: `${URL_BASE}/reviews/saveReviewAprovedEntryFormToAuthorize`,
  POST_APROVED_ENTRYFORM_AUTHORIZE: `${URL_BASE}/reviews/getReviewAprovedEntryFormToAuthorize`,
  POST_APROVED_ENTRYOUTPUT_AUTHORIZE: `${URL_BASE}/reviews/getReviewAprovedOutputFormToAuthorize`,
  POST_APROVED_ENTRY_TO_TRANSFER: `${URL_BASE}/reviews/getReviewAprovedEntryFormToTransfer`,
  POST_CORRECTEDS_ENTRYFORM: `${URL_BASE}/reviews/getReviewEntryFormCorrecteds`,
  POST_CORRECTEDS_ENTRYOUTPUT: `${URL_BASE}/reviews/getReviewOutputFormCorrecteds`,

  /** *********Corregidos de Entrada***********/
  POST_ENTRY_FORM_CORRECTED: `${URL_BASE}/entryFormCorrecteds/reviewReturnEntryFormCorrected`,
  POST_OUTPUT_FORM_CORRECTED: `${URL_BASE}/outputFormCorrecteds/reviewReturnOutputFormCorrected`,

  /** *********Actualizar Revisión***********/
  GET_REVIEW_UPDATE: `${URL_BASE}/reviews/getReviewQueueReviewUpdate`,
  //  GET_REVIEW_USERS: `${URL_BASE}/reviews/getReviewUsersAssociatedQualifiedUser`,
  SET_REVIEW_USER: `${URL_BASE}/reviews/changeReviewUsersAssociatedForm`,
  Change_REVIEW_USER_ASSOCIATED: `${URL_BASE}/reviews/getQualifiedUserAssociatedReviewUsers`,
  GET_USERS_REVIEW: `${URL_BASE}/reviews/getReviewUsersReviewUpdate`,

  /** ****** Busqueda avanzada **********/
  SEARCH_ADVANCED: `${URL_BASE}/searchEngine/getSearchEngine`,

  /** *********Análisis de control***********/
  CONTROL_MATRIX: `${URL_BASE}/controlmatrix/getControlMatriz`,
  CONTROL_FILTER_ELEMNT: `${URL_BASE}/controlmatrix/getFilter`,
  CONTROL_MATRIX_CRITERIS_RISK: `${URL_BASE}/controlmatrix/getQualifiedUserByCriterisRisk`,
  SHOW_CONTROL_MATRIX_QUALIFIED: `${URL_BASE}/controlmatrix/showControlMatrizByQualifiedUserID`,
  SHOW_CONTROL_MATRIX_BY_FREEZONE: `${URL_BASE}/controlmatrix/showControlMatrizByFreeZoneID`,
  POST_GRAPHIC_MATRIX: `${URL_BASE}/controlmatrix/getStatisticalGraphsByCriterisRisk`,
  EXPORT_EXCEL_MATRIX: `${URL_BASE}/controlmatrix/exportExcelControlMatrix`,

  /** ********* SEGURIDAD ***********/
  REGISTER_PARTICULAR: `${URL_BASE}/security/creaeParticular`,
  GET_VETO_DRIVER: `${URL_BASE}/security/securityVeto/getVetoDriver`,
  GET_VETO_TRUCKS: `${URL_BASE}/security/securityVeto/getVetoTrucks`,
  GET_VETO_PERSONS: `${URL_BASE}/security/securityVeto/getVetoPersons`,
  DELETE_VETO_TRUCK: `${URL_BASE}/security/securityVeto/deleteVetoTruck`,
  DELETE_VETO_PERSON: `${URL_BASE}/security/securityVeto/deleteVetoPerson`,
  DELETE_VETO_DRIVER: `${URL_BASE}/security/securityVeto/deleteVetoDriver`,
  CREATE_VETO_TRUCK: `${URL_BASE}/security/securityVeto/createVetoTruck`,
  CREATE_VETO_PERSON: `${URL_BASE}/security/securityVeto/createVetoPerson`,
  CREATE_SECURITY_ENTRY: `${URL_BASE}/security/securityEntry/createSecurityEntry`,
  SEARCH_CODE_FORM_ENTRY: `${URL_BASE}/security/securityEntry/searchCodeFormEntry`,
  SEARCH_CODE_FORM_OUTPUT: `${URL_BASE}/security/securityOutput/searchCodeFormOutput`,
  CREATE_SECURITY_EXIT: `${URL_BASE}/security/securityOutput/createSecurityOutput`,
  SEARCH_VEHICLE_PLATE_ENTRY: `${URL_BASE}/security/securityEntry/searchVehiclePlateEntry`,
  SEARCH_PLATE_IDENTIFICATION: `${URL_BASE}/security/securityVeto/searchPlateIdentification`,
  SEARCH_VEHICLE_PLATE_EXIT: `${URL_BASE}/security/securityOutput/searchVehiclePlateOutput`,
  GET_VEHICLES_DOORS_EXIT: `${URL_BASE}/admin/configurations/vehicularDoors/getVehicularDoorsNotPaginateExit`,
  GET_VEHICLES_DOORS_ENTRY: `${URL_BASE}/admin/configurations/vehicularDoors/getVehicularDoorsNotPaginateEntry`,
  GET_SECURITY_ENTRY_FILTRO: `${URL_BASE}/security/securityEntry/getSecurityEntryFiltro`,
  GET_SECURITY_OUTPUT_FILTRO: `${URL_BASE}/security/securityOutput/getSecurityOutputFiltro`,
  GET_SECURITY_ENTR_AND_OUTPUT_FILTRO: `${URL_BASE}/security/getSecurityEntryAndOutputFiltro`,
  GET_HISTORY_VETO: `${URL_BASE}/security/getHistoryVeto`,
  POST_EXPORT_REPORT_SECURITY: `${URL_BASE}/security/exportReportEntryDispatch`,

  /** *********BLOQUEOS ***********/
  BLOCKING_GET: `${URL_BASE}/restrictions/getRestrictions`,
  BLOCKING_CREATE: `${URL_BASE}/restrictions/createRestriction`,
  BLOCKING_UPDATE: `${URL_BASE}/restrictions/updateRestrictions`,
  BLOCKING_SHOW: `${URL_BASE}/restrictions/showRestriction`,
  BLOCKING_EXPORT: `${URL_BASE}/restrictions/exportExcelRestrictions`,
  BLOCKING_SHOW_RESTRICCION: `${URL_BASE}/restrictions/showRestrictionByUC`,
  BLOCKING_ADVANCE_SEARCH: `${URL_BASE}/restrictions/getRestrictionsAdvanceSearch`,
  BLOCKING_ADVANCE_SEARCH_COMPLETE: `${URL_BASE}/restrictions/getRestrictionsCompleteSearch`,

  /** *********TESORERIA-TARIFAS***********/
  TREASURY_UPDATE_RATE_GET: `${URL_BASE}/treasury/rates/getRates`,
  TREASURY_UPDATE_RATE_CREATE: `${URL_BASE}/treasury/rates/createRates`,
  TREASURY_UPDATE_RATE_UPDATE: `${URL_BASE}/treasury/rates/updateRates`,
  TREASURY_WEIGHING_USER: `${URL_BASE}/weighings/getUsersWeighingsNotPaginated`,
  /** *********TESORERIA-RECARGAS***********/
  TREASURY_RECHARGE_GET: `${URL_BASE}/treasury/recharges/getRecharges`,
  TREASURY_RECHARGE_CREATE: `${URL_BASE}/treasury/recharges/createRecharges`,
  TREASURY_RECHARGE_UPDATE: `${URL_BASE}/treasury/recharges/updateRecharge`,
  TREASURY_RECHARGE_EXPORT: `${URL_BASE}/treasury/recharges/exportExcelRecharge`,
  TREASURY_RECHARGES_TAXES: `${URL_BASE}/treasury/recharges/getTaxesRecharge`,
  TREASURY_CREATE_TAX: `${URL_BASE}/treasury/recharges/createTaxesRecharge`,
  /** *********TESORERIA-EXTRACTOS***********/
  TREASURY_EXPORT_GET: `${URL_BASE}/treasury/extracts/getExtracts`,
  TREASURY_EXPORT_MODULE_EXPORT: `${URL_BASE}/treasury/extracts/getExportExtracts`,
  TREASURY_EXPORT_DETAIL: `${URL_BASE}/treasury/extracts/getDetailsExtracts`,
  TREASURY_WEIGHING: `${URL_BASE}/treasury/weighings/getWeighingTreasury`,
  WEIGHING: `${URL_BASE}/weighings/getWeighing`,
  TREASURY_EXPORT_MODULE_WEIGTH: `${URL_BASE}/weighings/exportWeighing`,

  /** *********TYPE OF DOCUMENTS CONFIGURATION ***********/
  TYPE_OF_DOCUMENTS_GET: `${URL_BASE}/admin/configurations/documents_types/getDocumentTypes`,
  TYPE_OF_IDENTIFICATIONS_GET: `${URL_BASE}/admin/configurations/identificationTypes/getIdentificationTypes`,
  TYPE_OF_IDENTIFICATIONS_GET_ALL: `${URL_BASE}/admin/configurations/identificationTypes/getIdentificationTypesNoPaginate`,
  TYPE_OF_DOCUMENTS_CREATE: `${URL_BASE}/admin/configurations/documents_types/createDocumentTypes`,
  TYPE_OF_IDENTIFICATIONS_CREATE: `${URL_BASE}/admin/configurations/identificationTypes/createIdentificationType`,
  TYPE_OF_IDENTIFICATIONS_DELETE: `${URL_BASE}/admin/configurations/identificationTypes/deleteIdentificationType`,
  TYPE_OF_DOCUMENTS_UPDATE: `${URL_BASE}/admin/configurations/documents_types/updateDocumentType`,
  TYPE_OF_IDENTIFICATIONS_UPDATE: `${URL_BASE}/admin/configurations/identificationTypes/updateIdentificationType`,
  ACTIVE_AND_DESACTIVE_DOCUMETS: `${URL_BASE}/admin/configurations/documents_types/statusDocumentType`,
  ACTIVE_AND_DESACTIVE_IDENTIFICATIONS: `${URL_BASE}/admin/configurations/identificationTypes/statusIdentificationType`,

  /** ********* DOCUMENT PROFILE ***********/
  DOCUMENTS_PROFILE_GET: `${URL_BASE}/profiling/getApprovedDocument`,
  DOCUMENTS_PROFILES_UPDATE: `${URL_BASE}/profiling/updateApprovedDocument`,
  FINISH_DOCUMENT_PROFILE: `${URL_BASE}/profiling/getApprovedDocumentwithDialType`,
  GET_INTEROPERABILITY: `${URL_BASE}/dian/getLogInteroperability`,
  GET_TRACKING_TRACEABILITY: `${URL_BASE}/trackings/getFormsWithTrackings`,
  DOCUMETS_PROFILE_ITEMS: `${URL_BASE}/profiling/updateApprovedItemDocumentDialType`,
  DOCUMENT_PROFILE_GET_ITEMS: `${URL_BASE}/profiling/getApprovedItemDocument`,
  VIEW_DOCUMENT_PROFILES: `${URL_BASE}/profiling/getApprovedItemDocumentwithDialType`,
  FINISH_DOCUMENT_PROFILES: `${URL_BASE}/profiling/finalizeApprovedDocument`,
  FINISH_ITEMS_PROFILES: `${URL_BASE}/profiling/finalizeApprovedItemDocumentDialType`,

  /** ********* DOCUMENTS SUBMITTED***********/
  DOCUMENTS_SUBMITTED: `${URL_BASE}/queueSubmitted/getQueueSubmitted`,
  DOCUMENTS_SUBMITTED_SAVE: `${URL_BASE}/queueSubmitted/saveQueueSubmitted`,

  /** ********* MONITORING ***********/
  MONITORING_SEND_FORM: `${URL_BASE}/trackings/createTracking`,
  MONITORING_OPERATIONS: `${URL_BASE}/trackings/getOperationsByQualifiedUser`,
  GET_MONITORING: `${URL_BASE}/trackings/getTrackings`,
  ACTIVATE_OR_INACTIVATE_MONITORING: `${URL_BASE}/trackings/disableTracking`,
  GET_USERS_QUALIFIED: `${URL_BASE}/trackings/getQualifiedUserTrackingNotPaginate`,
  GET_ENTRY_ITEMS: `${URL_BASE}/entryFormsItems/getEntryFormItemSearch`,
  GET_EXIT_ITEMS: `${URL_BASE}/ouputFormsItems/getOutputFormItemSearch`,
  GET_SUBHEADING: `${URL_BASE}/trackings/getSubheadingsTrackingsNotPaginate`,
  GET_TRACKINGS_FMM: `${URL_BASE}/trackings/showTracking`,
  GET_TRACEABILITY: `${URL_BASE}/trackings/getTrackingForms`,
  GET_FOLLOW_ORDER: `${URL_BASE}/trackings/getFollowOrder`,
  GET_OPTION_FOLLOW_ORDER: `${URL_BASE}/trackings/getTrackingManagerNotPaginated`,
  GET_FOLLOW_ORDER_TABLE: `${URL_BASE}/trackings/getTrackingManager`,
  GET_LOG: `${URL_BASE}/trackings/getTrackingLog`,
  CREATE_FOLLOW_ORDER: `${URL_BASE}/trackings/createTrackingManager`,
  EDIT_FOLLOW_ORDER: `${URL_BASE}/trackings/editTrackingManager`,
  DELETE_FOLLOW_ORDER: `${URL_BASE}/trackings/statusTrackingManager`,
  CREATE_COMMENT: `${URL_BASE}/trackings/messageTrackingManager`,
  DELETE_COMMENT: `${URL_BASE}/trackings/disableMessageManager`,
  GET_COMMENT: `${URL_BASE}/trackings/getMessageManager`,
  EDIT_COMMENT: `${URL_BASE}/trackings/editMessageManager`,
  STOP_TRACKING_ON_ITEM: `${URL_BASE}/trackings/disableTrackingItem`,
  GET_TRACKING_REFERENCES: `${URL_BASE}/trackings/getTrackingReferences`,

  /** *********    ***********/
  VAPOR_BASE_URL: `${BASE_URL}`,

  /** *** DashBoard *******/
  DASBHBOARD_POST_CONFIG: `${URL_BASE}/saveOrUpdateDashboarConfig `,
  DASBHBOARD_GET_CONFIG: `${URL_BASE}/getDashboardConfig`,
  DASBHBOARD_DELETE_CONFIG: `${URL_BASE}/deleteDashboarConfig`,

  /** ***News section*******/
  NEWS_SECTION_PRESENT_FOR_DAY: `${URL_BASE}/presentedForDay`,
  NEWS_SECTION_PRESENT_FOR_USER: `${URL_BASE}/presentedForUser`,
  NEWS_SECTION_DOCUMENT_RETURNED: `${URL_BASE}/returnedDocuments`,
  NEWS_SECTION_DOCUMENT_SUBMITTED: `${URL_BASE}/submittedDocuments`,
  NEW_SECTION_DOCUMENT_APPROVED: `${URL_BASE}/approvedDocuments`,
  NEWS_SECTION_DOCUMENT_OPEN_TICKETS: `${URL_BASE}/openTickets`,
  NEWS_SECTION_DOCUMENT_OPEN_TICKETS_DAY: `${URL_BASE}/openTicketsDay`,
  NEWS_SECTION_DOCUMENT_CLOSED_TICKETS: `${URL_BASE}/closedTickets`,
  NEWS_SECTION_DOCUMENT_REWEIGHING: `${URL_BASE}/reweighing`,
  NEWS_SECTION_DOCUMENT_UC_WHAT_MORE_WEIGHT: `${URL_BASE}/ucWhatMoreWeight`,
  NEWS_SECTION_OPERATION_DOCUMENT_PRESENTED: `${URL_BASE}/operationDocumentPresented`,
  NEWS_SECTION_DOCUMENT_INCOME_BALANCE: `${URL_BASE}/incomeBalance`,
  NEWS_SECTION_DOCUMENT_EXIT_BALANCE: `${URL_BASE}/exitBalance`,
  NEWS_SECTION_TRACKING_ON_FMMI: `${URL_BASE}/trackingOnFMMI`,
  NEWS_SECTION_TRACKING_ON_FMMS: `${URL_BASE}/trackingOnFMMS`,
  NEWS_SECTION_TRACKING_OFF_FMMI: `${URL_BASE}/trackingOffFMMI`,
  NEWS_SECTION_TRACKING_OFF_FMMS: `${URL_BASE}/trackingOffFMMS`,
  NEWS_SECTION_TRACKING_ON_DOC_FMMI: `${URL_BASE}/trackingOnDocFMMI`,
  NEWS_SECTION_TRACKING_ON_DOC_FMMS: `${URL_BASE}/trackingOnDocFMMS`,
  NEWS_SECTION_ENTRY_PASSES: `${URL_BASE}/formsFMMIEntryPasses`,
  NEWS_SECTION_DISPATCH_PASSES: `${URL_BASE}/formsFMMSDispatchPasses`,
  NEWS_SECTION_TOTAL_CURRENT_WEIGHINGS: `${URL_BASE}/totalCurrentWeighings`,
  NEWS_SECTION_COUNTERS_FMM: `${URL_BASE}/countersFMM`,
  FORMS_ON_EDIT: `${URL_BASE}/forms_on_edit`,
  FORMS_ON_REVIEW: `${URL_BASE}/forms_on_review`,
  GET_JOB_STATUS: `${URL_BASE}/getJobStatus`,
  GET_OPERATOR_USERS: `${URL_BASE}/admin/getOperatorUsers`,
  GET_CONFIG_DEI: `${URL_BASE}/outputForms/getConfigurationEmailDEI`,
  SAVE_CONFIG_DEI: `${URL_BASE}/outputForms/saveConfigurationEmailDEI`,
  GET_OUTPUT_FORM_DEI : `${URL_BASE}/outputForms/getOutputFormDei`,
  UPDATE_OUTPUT_FORM_DEI : `${URL_BASE}/outputForms/updateOutputFormDei`,
  CREATE_ENTRY_TEMPORARY_FORM:`${URL_BASE}/outputForms/createEntryTemporaryForm`,
  GET_FORMS_TO_EXPIRE:`${URL_BASE}/getFMMIFormsToExpire`,
  GET_FORMS_APPROVED_BY_SYSTEM:`${URL_BASE}/getFMMIFormsApprovedBySystem`,
  CHANGE_USER_SYSTEM: `${URL_BASE}/entryForms/changeUserSystem`,
  //CHANGE_USER_SYSTEM: `${URL_BASE}/entryForms/change_user_system`,
  CREATE_REWEIGH: `${URL_BASE}/weighings/createReweigh`,
};

export default environment;
