import { Center, Grid, GridItem, Text } from '@chakra-ui/react'
import TableOrder from 'components/Tables/TableOrder'
import React, { useContext, useEffect, useState } from 'react'
import { Title } from 'styled/Title'
import TBodyFormatSRT from './TBody/TBodyFormatSRT'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import environment from 'constants/apiConst'
import { __GetFormatSrt, __ShowForm } from 'request/Forms/__ShowForm'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __GetItems } from 'request/Forms/_CrudForm'
import { HeaderSearch } from 'styled/SectionContainer.styled'
import { AttchmentTypiFied } from 'components/attchmentTypiFied/AttchmentTypiFied'
import Vapor from 'laravel-vapor'
import FormCrudCRUDContext from 'context/FormCrudContex'
import Swal from 'sweetalert2'
import DocumentUploader from 'pages/Entry_and_exit_forms/FormEntry/DocumentUploader/DocumentUploader'
import ModalFormatSRT from './ModalFormatSRT'
import GlobalContext from 'context/GlobalContext'
import TBodyAuthFormatSRT from './TBody/TBodyAuthFormatSRT'
import { ComparativeArray } from 'functions/ComparativeArray'
import { useStorageQuery } from 'hooks/useStorageQuery'
import { ButtonStyled } from 'styled/Buttons.styled'
import { isString } from 'utils/type-check-utils'

const thead = [
  { name: 'Item', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Cantidad', order: 'null' },
  { name: 'Unidad Comercial', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

const theadAuth = [
  { name: 'Item', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Cantidad', order: 'null' },
  { name: 'Unidad Comercial', order: 'null' },
  { name: 'Serial', order: 'null' },
  { name: 'Color', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Característica Principal', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

const FormatSRT = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [info, setInfo] = useState([])
  const [attachment, setAttachment] = useState([])
  const [attachmentDescription, setAttachmentDescription] = useState([])
  const [attachmentType, setAttachmentType] = useState([])
  const [files, setFiles] = useState({})
  const [nameFiles, setNameFiles] = useState([])
  const [reload, setReload] = useState(false)
  const { token } = useSelector(state => state.auth.dataUser)
  const { ExitNumber = '', FormType = '', Operation = '' } = queryString.parse(location.search)
  const { deleteAttachment, editDataForm, DocumentOperation, setRequestEdit } =
    useContext(FormCrudCRUDContext)
  const { idItemSRT, setiIdItemSRT, isOpen, setIsOpen } =
    useContext(GlobalContext)
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate();
  const { admin, is_commerce, is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const getInfo = async () => {
    setLoading(true)
    try {
      const res = await __ShowForm(
        token,
        ExitNumber,
        environment.SHOW_FORM_EXIT
      )

      if (res?.data?.status.code === 200) {
        const result = res?.data?.status?.data
        setInfo(result)
        setAttachment(result?.other_files)
        const fromServer = result.files.reduce((old, it) => {
          return {
            ...old,
            ['document-' + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id
            }
          }
        }, {})
        setFiles({
          ...files,
          ...fromServer
        })
      } else {
        openAlert(res?.data?.status.message, 'error')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const comparator = ComparativeArray(
      attachmentDescription,
      attachment,
      'other_attachment_description'
    )

    if (!comparator) {
      attachment?.forEach(item => {
        const newItem = item?.other_attachment_description
        if (newItem && !attachmentDescription.includes(newItem)) {
          setAttachmentDescription(prev => [...prev, newItem])
        }
      })
    }
  }, [attachment])

  const getData = async () => {
    const formData = new FormData()
    formData.append('outputForm_id', ExitNumber)
    try {
      const res = await __GetItems(
        token,
        formData,
        1,
        environment.GET_iTEM_EXIT
      )

      const result = res?.data?.status?.data?.data
      result.sort((a, b) => a.item - b.item)
      setData(result)
    } catch (error) { }
  }

  const handleFiles = (e, idFiles) => {
    e.preventDefault()
    document.getElementById(`${idFiles}`).click()
  }

  const handleFilesInput = e => {
    e.preventDefault()
    const name = e.target.name
    const Files = e.target.files
    const extensionOptions = [
      '.doc',
      '.docx',
      '.pdf',
      '.png',
      '.jpg',
      '.jpeg',
      '.gif',
      '.bmp',
      '.xls',
      '.xlsx',
      '.xlsm',
      'image/png',
      'image/*',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-excel'
    ]
  

    const getFileExtension =  files[0].name.split('.').pop()
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`)
    if (!isValidExtension) {
      const fileName = files[0].name
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        'error'
      )
    }
    Vapor.store(Files[0], {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      setFiles(
        Object.assign(
          { ...files },
          {
            [e.target.id]: {
              file: response,
              name: Files[0].name,
              document_id: e.target.id.replace('document-', '')
            }
          }
        )
      )
      setNameFiles([...nameFiles, { name }])
    })
  }

  useEffect(() => {
    getInfo()
    getData()
    setRequestEdit(environment.UPDATE_FORM_EXIT)
  }, [isOpen])

  useEffect(() => {
    if (reload) {
      getInfo()
      setReload(false)
    }
  }, [reload])

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${nombre}"?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1F7AC3',
      confirmButtonText: 'Eliminar',
      customClass: {
        title: 'class-text'
      }
    }).then(resultado => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          if (files[archivo].from_server) {
            deleteAttachment(
              environment.DELETE_ATTACHMENT_EXIT,
              files[archivo].id
            )
          }
          const obj = Object.assign({}, { ...files })
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            console.log(obj[archivo])
            delete obj[archivo]
          }
          setFiles(obj)
        }
      }
    })
  }

  const handleChangeOther = async () => {
    const formData = new FormData()
    formData.append('id', ExitNumber)

    if (attachment) {
      attachment.forEach(file => {
        if (!file.attachment_url) {
          formData.append('other_attachment[]', file)
        }
      })
    }

    if (attachmentDescription) {
      // attachmentDescription.forEach((descrip) => {
      formData.append(
        'other_attachment_description[]',
        attachmentDescription[0]
      )
      // });
    }
    if (attachmentType) {
      // attachmentType.forEach((type) => {
      formData.append('other_attachment_type[]', attachmentType[0])
      // });
    }
    const res = await editDataForm(formData, false)
    setReload(res)
    setAttachment([])
    setAttachmentDescription([])
    setAttachmentType([])
  }

  const isAuthorizationDeletedDocument =
    info?.status_outputForm !== 'BORRADOR' && (is_commerce === 1 || admin === 1 || is_qualified_user === 1)

  return (
    <div>
      <Center
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          marginBottom: '20px'
        }}
      >
        {FormType === 'Ingreso' && (
          <Title>Consideraciones Adicionales Para Salidas Temporales Y Reingresos Formato SRT Para Reingresos de Temporales</Title>
        )}

        {FormType === 'Salida' && (
          <Title>Consideraciones Adicionales para Salidas Temporales
          </Title>
        )}

        {FormType === 'Pases' && (
          <Title>Consideraciones Adicionales para Reingresos
          </Title>
        )}

        <h2>{`No. ${info?.code_outputForm} - ${info?.name_qualified}`}</h2>
      </Center>

      {info?.status_outputForm === 'AUTORIZADO' && (
        <Grid
          w='60%'
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(2, 1fr)'
          gap={8}
          pb={10}
        >
          <GridItem colSpan={1}>
            <Text fontSize='sm' as='b'>
              Tipo de Operación:
            </Text>
            {FormType === 'Ingreso' || FormType === 'Pases'
              ? (
                <Text>{Operation}</Text>
              )
              : (
                <Text>{`${info?.code_operation} - ${info?.short_name_operation}`}</Text>
              )}

          </GridItem>
          <GridItem colSpan={1}>
            <Text fontSize='sm' as='b'>
              Plazo:
            </Text>
            <Text>{info?.term}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontSize='sm' as='b'>
              Dirección Destino:
            </Text>
            <Text>{info?.destination_address}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontSize='sm' as='b'>
              Plazo de vencimiento:
            </Text>
            <Text>{info?.expiration_final_date}</Text>
          </GridItem>
        </Grid>
      )}

      <Title>Ítems del formulario</Title>
      {info?.status_outputForm === 'APROBADO'
        ? (
          <TableOrder
            thead={thead}
            data={data}
            setData={setData}
            loading={loading}
            tbodyData={TBodyFormatSRT}
          />
        )
        : (
          <TableOrder
            thead={theadAuth}
            data={data}
            setData={setData}
            loading={loading}
            tbodyData={TBodyAuthFormatSRT}
          />
        )}

      {isOpen && <ModalFormatSRT />}

      {DocumentOperation.length > 0 && (
        <HeaderSearch>
          {DocumentOperation.map((document, index) => (
            <div key={index}>
              <DocumentUploader
                document={document}
                handleFiles={handleFiles}
                handleFilesInput={handleFilesInput}
                handleDelete={removeItemFromArr}
                files={files}
                setNameFiles={setNameFiles}
                nameFiles={nameFiles}
                index={index}
                viewBtnDelete={isAuthorizationDeletedDocument}
              />
            </div>
          ))}
        </HeaderSearch>
      )}

      <HeaderSearch>
        <AttchmentTypiFied
          titulo={'Otros documentos'}
          attachment={attachment}
          setAttachment={setAttachment}
          attachmentDescription={attachmentDescription}
          setAttachmentDescription={setAttachmentDescription}
          attachmentType={attachmentType}
          setAttachmentType={setAttachmentType}
          deleteAttachment={deleteAttachment}
          ruta={environment.DELETE_OTHER_ATTACHMENT_EXIT}
          viewBtn={true}
          viewBtnDelete={isAuthorizationDeletedDocument}
          update={handleChangeOther}
        />
      </HeaderSearch>

      {
        FormType === "Pases" && (<ButtonStyled onClick={() => navigate(`/FormShowExit?ExitNumber=${ExitNumber}`)}>Ir al Formulario de Salida</ButtonStyled>)
      }
      {
        (FormType === "Ingreso" || FormType === "Salida") && (<ButtonStyled onClick={() => navigate(-1)}>Ir atrás</ButtonStyled>)
      }

    </div>
  )
}

export default FormatSRT
