import React, { useContext } from "react";
import { MenuItem } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { HiDocumentAdd } from "react-icons/hi";
import { RiEdit2Fill } from "react-icons/ri";
import { BiHistory } from "react-icons/bi";
import { HrDividerNoMargin } from "styled/Line.styled";
import TreasuryCrudContex from "context/TreasuryContex";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuTreasury = ({ deleteField = true }) => {
  const { onOpen } = useContext(TreasuryCrudContex);

  return (
    <>
      <MenuItemStyled py={3}>
        <IconMenuRUD as={RiEdit2Fill} />
        Editar
      </MenuItemStyled>

      <HrDividerNoMargin />

      <MenuItemStyled py={3}>
        <IconMenuRUD as={HiDocumentAdd} />
        Nueva Recarga
      </MenuItemStyled>

      <HrDividerNoMargin />

      <MenuItemStyled py={3} onClick={onOpen}>
        <IconMenuRUD as={BiHistory} />
        Ver Historial
      </MenuItemStyled>

      {deleteField && (
        <MenuItemStyled py={3}>
          <IconMenuRUD as={MdDelete} />
          Anular
        </MenuItemStyled>
      )}
    </>
  );
};

export default MenuTreasury;
