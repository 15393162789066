import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";

export const NationalTerritoryOriginal = ({ data }) => {
  const {
    commercial_invoice,
    certificate_inspection_pre_boarding,
    date,
    value_duta,
    date_corrected,
    certificate_inspection_pre_boarding_corrected,
    commercial_invoice_corrected,
    value_duta_corrected
  } = data;

  const { opcionalCurrent } = useContext(FormCrudCRUDContext);
  return (
    <>
      <LabelGroup
        name={"certificate_inspection_pre_boarding"}
        value={certificate_inspection_pre_boarding}
        tag={"Cert. de inspecc. preembarque "}
        size={100}
        desicion={true}
        changeValue={
          certificate_inspection_pre_boarding !==
            certificate_inspection_pre_boarding_corrected && "BorderCorrect"
        }
      />
      <LabelGroup
        name={"date"}
        changeValue={date !== date_corrected && "BorderCorrect"}
        value={date}
        tag={"Fecha"}
        size={100}
        tipo={"date"}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          commercial_invoice !== commercial_invoice_corrected && "BorderCorrect"
        }
        name={"commercial_invoice"}
        value={commercial_invoice}
        tag={"Factura Comercial"}
        size={100}
        desicion={true}
      />

      {opcionalCurrent && (
        <LabelGroup
          changeValue={value_duta !== value_duta_corrected && "BorderCorrect"}
          name={"value_duta"}
          value={value_duta}
          tag={opcionalCurrent}
          size={100}
          desicion={true}
        />
      )}
    </>
  );
};
