/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import TbodyAgreementSub from "../tables/TbodyAgreementSub";
import { useNavigate, useLocation } from "react-router-dom";
import FormShowComercial from "../FormShow/FormShowComercial";
import React, { useContext, useEffect, useState } from "react";
import { __ASubheading } from "request/configuration/__ASubheading";
import FormAComercialSubShow from "../FormShow/FormAComercialSubShow";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

const AgreementCSub = () => {
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);

  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const navigate = useNavigate();

  const Title = [
    { name: "Acuerdo comercial", order: "tradeAgreement_id" },
    { name: "Subpartida", order: "code_subheading" },
    { name: "Estado", order: "status_subheadingtradeAgreement" },
    { name: "Acciones", order: "" }
  ];

  const formEditSub = () => {
    return <FormAComercialSubShow />;
  };

  const formularioview = () => {
    return <FormShowComercial />;
  };

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formularioview);
    setRequestEdit(environment.ASUBHEADINS_UPDATE);
    setRequestCreate(environment.ASUBHEADINS_CREATE);
    setRequestDelete(environment.ASUBHEADINS_DELETE);
  }, [q, update]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configSubheadingAgreement", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.ASUBHEADINS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Subpartidas - acuerdo comerciales"></ConfigurationHeader>
        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyAgreementSub}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};

export default AgreementCSub;
