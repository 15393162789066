import { useCallback, useEffect, useState, useContext } from "react";
import { Button, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuSupplies from "components/Menu/MenuIntegracionBox/MenuSupplies";
import queryString from "query-string";
import { IconGeneric, IconTracking } from "styled/Icons.styled";
import { BsEyeFill } from "react-icons/bs";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";

import "../styleBox.css";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { RiRadioButtonFill } from "react-icons/ri";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import Pagination from "components/Pagination/Pagination";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { useSelector } from "react-redux";
import useSupplyDataOverlay from "../View/OptionPanelCertificate/useSupplyDataOverlay ";
import environment from "constants/apiConst";
import { useGlobalContext } from "context/GlobalContext";

const TbodyRawMaterial = ({ data, onMouseEnter, onMouseLeave }) => {
  const location = useLocation();
  const [first, setfirst] = useState(null);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const optionsBalance = ["%", "Cnt"];
  const { pathname } = location;
  const {
    setShowSupplies,
    setDataToCorrectSupplies,
    createDps,
    setActiveView,
  } = useContext(IntegracionBoxCrudContex);
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const {
    idCorrected = null,
    id = null,
    StatusCi = null,
  } = queryString.parse(location.search);

  const handleInfo = () => {
    setShowSupplies(true);
    setActiveView("showSupplies");
    setDataToCorrectSupplies(data);
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    percentage_garbage: "",
  });
  const { percentage_garbage } = values;
  const { isOpen: isOpenDatosInsumo, setIsOpen } = useGlobalContext();

  const updateObjet = (dato) => {
    const dataTosent = new FormData();
    dataTosent.append("integration_certificate_id", id);
    dataTosent.append("reference", data?.reference_description);
    dataTosent.append("quantity_product", dato);
    dataTosent.append("type_quantity_garbage", first === "%" ? 0 : 1);
    createDps(dataTosent);
  };

  useEffect(() => {
    setValues({
      ...values,
      percentage_garbage:
        data.type_quantity_garbage === 0
          ? data?.percentage_garbage
          : data?.quantity_garbage,
    });
    setfirst(
      data.type_quantity_garbage === 0
        ? "%"
        : data.type_quantity_garbage === 1
        ? "Cnt"
        : null
    );
  }, []);

  const handleBlurCant = (e) => {
    const posibleResult = e.target.value;
    if (posibleResult) {
      updateObjet(posibleResult);
    }
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );

  const formId = data?.form_id || data?.formCorrected_id;
  const urlTemplates = {
    "CERTIF.INT": `/ShowIntegrationBox?id=${formId}`,
    INGRESO: `/FormShow?FormEntry=${formId}`,
  };

  const { SupplyDataOverlay, handleMouseEnter, handleClose } =
    useSupplyDataOverlay({
      token,
      apiUrl: environment.SHOW_SUPPLY_DATA_CI,
      isEnabled: isOpenDatosInsumo,
    });

  const handleRowMouseEnter = (e) => {
    handleMouseEnter(e, data?.reference, data?.integration_certificate_id);
  };

  const referenceDescription =
    data?.reference_description || data?.reference_description_corrected;
  const shouldRedirect =
    urlTemplates?.[data?.type_form || data?.type_form_corrected];

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr
        onMouseEnter={handleRowMouseEnter}
        onMouseLeave={handleClose}
        // onClick={() => onMouseEnter(data?.reference_description, id)}
        className={data?.corrected === 2 && idCorrected && "BorderCorrectTabla"}
      >
        <Td className="text-center">
          {data.item_code ? data.item_code : data.item_code_corrected}
        </Td>
        <Td className="text-center">
          <NavLink to={shouldRedirect}>
            {shouldRedirect ? (
              <span className="formNumber">{referenceDescription}</span>
            ) : (
              <span className="formNumber">{referenceDescription}</span>
            )}
          </NavLink>
        </Td>
        <Td className="text-center">
          {data?.code_product
            ? data?.code_product
            : data?.code_product_corrected}
        </Td>
        <Td className="text-center">
          {data?.nature_of_inventory
            ? data?.nature_of_inventory
            : data?.nature_of_inventory_corrected}
        </Td>
        <Td className="text-center">
          {data?.customs_clearance
            ? data?.customs_clearance
            : data?.customs_clearance_corrected}
        </Td>
        <Td className="text-center">
          {data?.operation_code
            ? data?.operation_code
            : data?.operation_code_corrected}
        </Td>
        <Td className="text-center">
          {data?.quantity ? data?.quantity : data?.quantity_corrected}
        </Td>
        <Td className="text-center">
          {data?.business_unit
            ? data?.business_unit
            : data?.business_unit_corrected}
        </Td>
        <Td className="text-center">
          {data?.quantity_product_supply
            ? data?.quantity_product_supply
            : data?.quantity_product_supply_corrected}
        </Td>
        <Td className="text-center">
          {data?.code_unit_measurement
            ? data?.code_unit_measurement
            : data?.code_unit_measurement_corrected}
        </Td>

        {StatusCi === "BORRADOR" && pathname !== "/ShowIntegrationBox" ? (
          <>
            <Td className="text-center d-flex">
              <div className="mt-1">
                <RadioBtn
                  setOnly={setfirst}
                  options={optionsBalance}
                  state={first}
                />
              </div>
              <div className="mt-1 ms-1" style={{ minWidth: "50px" }}>
                <LabelGroup
                  style={{ fontSize: "12px", height: "31px" }}
                  name={`percentage_garbage`}
                  value={percentage_garbage}
                  onChange={handleInputChange}
                  onBlur={handleBlurCant}
                  id={data.id}
                  tag={""}
                  size={100}
                  tipo={"text"}
                  desicion={!first}
                />
              </div>
            </Td>
          </>
        ) : (
          <>
            <Td className="text-center ">
              <span className="text-supply-tablet">
                {data.type_quantity_garbage === 0 ||
                data.type_quantity_garbage_corrected === 0
                  ? "% "
                  : "Cant "}
              </span>

              {data.type_quantity_garbage !== null &&
              data.type_quantity_garbage !== undefined ? (
                <span className="">
                  {data.type_quantity_garbage === 0
                    ? data?.percentage_garbage
                    : data?.quantity_garbage}
                </span>
              ) : (
                <span className="">
                  {data.quantity_garbage_corrected === 0
                    ? data?.percentage_garbage_corrected
                    : data?.quantity_garbage_corrected}
                </span>
              )}
            </Td>
          </>
        )}

        <Td className="text-center">
          {data.net_weight_kilo_supply
            ? data.net_weight_kilo_supply
            : data.net_weight_corrected}
        </Td>
        <Td className="text-center">
          {data?.total_calculated_supply
            ? data?.total_calculated_supply
            : data?.total_calculated_supply_corrected}
        </Td>

        {is_qualified_user === 0 && (
          <Td>
            {data?.tracking?.length ? (
              <Tooltip
                hasArrow
                placement="top"
                label={
                  data?.tracking?.some((tracking) => tracking.status === 1)
                    ? "En seguimiento"
                    : data?.tracking?.every((tracking) => tracking.status === 2)
                    ? "Estuvo en seguimiento"
                    : ""
                }
              >
                {data?.tracking?.length ? (
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconTracking
                      onClick={() => showTrackingItem(data?.tracking)}
                      color={
                        data?.tracking?.some(
                          (tracking) => tracking?.status === 1
                        )
                          ? "#BA1F33"
                          : "#03C39A"
                      }
                      as={RiRadioButtonFill}
                    ></IconTracking>
                  </span>
                ) : (
                  ""
                )}
              </Tooltip>
            ) : (
              <Tooltip placement="top" hasArrow label={"Sin seguimiento"}>
                <spam
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconTracking color={"#BABABA"} as={RiRadioButtonFill} />
                </spam>
              </Tooltip>
            )}
          </Td>
        )}

        <Td className="text-center">
          {data?.trade_agreement
            ? data?.trade_agreement
            : data.trade_agreement_corrected
            ? data.trade_agreement_corrected
            : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.description_product
            ? data?.description_product
            : data.description_product_corrected}
        </Td>
        <Td className="text-center">
          {data?.codeProductionOrder
            ? data?.codeProductionOrder
            : data.production_order_code_corrected
            ? data.production_order_code_corrected
            : "N/A"}
        </Td>
        {(pathname === "/IntegracionBox" && !data.codeProductionOrder) ||
        (pathname === "/CorrectedCertificate" &&
          !data.production_order_code_corrected) ? (
          <Td className="text-center" isNumeric>
            <MenuSupplies data={data} />
          </Td>
        ) : pathname === "/ShowIntegrationBox" &&
          data?.corrected === 2 &&
          idCorrected ? (
          <Td className="text-center">
            <IconGeneric
              color={"#1F7AC3"}
              as={BsEyeFill}
              onClick={() => handleInfo()}
            />
          </Td>
        ) : (
          <Td className="text-center"></Td>
        )}
      </Tr>
      {/*  <ToggleButton style={{ position: 'fixed', top: '20px', right: '20px' }} /> */}
      <SupplyDataOverlay />
    </>
  );
};

export default TbodyRawMaterial;
