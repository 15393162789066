import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";

export default function CustomizableButton ({ icon, LabelText = "", ...props }) {
  return (
    <Tooltip placement="top" hasArrow label={LabelText}>
      <span>
        <ButtonHeaderModule
          className="m-1"
          // bgColor="#03C39A"
          {...props}
        >
          <IconGeneric className=" text-white" as={icon} />
        </ButtonHeaderModule>
      </span>
    </Tooltip>
  );
}
