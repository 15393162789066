/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Spacer, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ButtonHeaderModule, ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { MdQrCodeScanner } from "react-icons/md";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useLocation, useNavigate } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineFileSync } from "react-icons/ai";
import { GetLocalStorage } from "hooks/useLocalStorage";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { CustomizablePopover } from "components/CustomizablePopover/CustomizablePopover";
import MessagerTrash from "../View/ButtonViewForms/MessagerTrash";
import { BiTrash } from "react-icons/bi";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { SubTitle, Title } from "styled/Title";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { isEmptyObject, isObjectNotEmpty } from "utils/type-check-utils";
import { Storage } from "hooks/useStorage";
import queryString from "query-string";
import { AuthContext } from "context/AuthContext";
import { FaUnlock } from "react-icons/fa";

export const BtnCheckFmm = ({ values, setStateForm, children }) => {
  const navigate = useNavigate();
  const [isOpenConfirEmail, onOpenConfirEmail, onCloseConfirEmail] = useModal();
  const [isOpenUpdateEmail, onOpenUpdateEmail, onCloseUpdateEmail] = useModal();
  const [stateConfirmEmail, setStateConfirmEmail] = useState(false);
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const { Number = null } = queryString.parse(location.search);
  const { pathname } = location;
  const [form, handleInputChange, reset, setform] = useForm({});
  const { custom_email } = form;

  const {
    setDataToShow,
    changeStatus,
    setDataToDesechar,
    onOpenDesechar,
    onOpenReturnReason,
    setReturnReason
  } = useContext(FormCrudCRUDContext);
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const { get } = Storage;

  const unlockForm = async () => {
    const body = new FormData();
    body.append("form_id", Number);
    body.append("type_of", "FMMS");
    body.append("type", 0);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
  }

  const handlePresent = (status) => {
    setDataToShow(values);
    const notations = get(values?.id);

    const info = {
      id: values.id,
      status_outputForm: status
    };

    const validateCustom =
      !!(values.code_operation >= 201 &&
        values.code_operation <= 299 &&
        values.check_duta);

    if (status === "DEVUELTO" && isEmptyObject(notations)) {
      onOpenReturnReason();
      return setReturnReason(info);
    }

    if (status === "DEVUELTO" && isObjectNotEmpty(notations)) {
      return changeStatus(info);
    }

    if (status !== "DEVUELTO" && isEmptyObject(notations)) {
      return changeStatus(info);
    }

    if (status === "APROBADO" && isObjectNotEmpty(notations)) {
      return openAlert("Los campos se encuentran con observaciones", "error");
    }

    if (status !== "DEVUELTO" && isEmptyObject(notations)) {
      if (!stateConfirmEmail && validateCustom) {
        onOpenConfirEmail();
        setform({ custom_email: values.custom_email });
        return;
      } else {
        changeStatus(info);
        reset();
        setStateConfirmEmail(false);
        return;
      }
    }

    unlockForm();
    return changeStatus(info);
  };

  const handleDesechar = () => {
    setDataToDesechar(values);
    onOpenDesechar();
  };
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleConfirmEmail = () => {
    setStateConfirmEmail(true);
  };

  useEffect(() => {
    if (stateConfirmEmail) {
      handlePresent("APROBADO");
    }
  }, [stateConfirmEmail]);

  const updateDirectionEmail = async (data) => {
    try {
      const res = await __Post(environment.CUSTOMS_UPDATE, token, data);
      if (res.data.status.code === 200) {
        onCloseUpdateEmail();
        openAlert(
          `Correo electrónico de aduana ${values.custom_name} actualizada exitoxamente`,
          "success"
        );
        // setStateUpdateEmail(true);
        setStateForm({ ...values, custom_email });
      } else {
        openAlert(res.status.message, "error");
        // setStateUpdateEmail(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveEmail = () => {
    if (values.custom_email !== custom_email) {
      const information = {
        id: values.customAdministration_id,
        email_custom: custom_email
      };
      updateDirectionEmail(information);
    } else {
      openAlert(
        `Ambos correo electrónico son iguales, por favor ingrese un correo electrónico diferente`,
        "error"
      );
    }
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpenConfirEmail}
        onOpen={onOpenConfirEmail}
        onClose={onCloseConfirEmail}
        title="Aviso"
        size="md"
      >
        <SubTitle className="p-1">
          Al aprobar el siguiente formulario{" "}
          <strong>{values.code_outputForm}</strong>, se enviará un correo a la
          aduana <strong>{values.custom_name}</strong> con la siguiente
          dirección
          <strong> {values.custom_email}</strong>.{" "}
          <strong>¿Está seguro?</strong>
        </SubTitle>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onOpenUpdateEmail}>
            No
          </ButtonStyled>
          <Spacer />

          <ButtonStyled bgColor={"#03C39A"} onClick={handleConfirmEmail}>
            Si
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenUpdateEmail}
        onOpen={onOpenUpdateEmail}
        onClose={onCloseUpdateEmail}
        title="Actualizar dirección"
        size="sm"
      >
        <LabelGroup
          name="custom_email"
          value={custom_email}
          onChange={handleInputChange}
          tag={"Correo electrónico"}
          size={100}
        />

        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseUpdateEmail}>
            Cancelar
          </ButtonStyled>
          <Spacer />

          <ButtonStyled bgColor={"#03C39A"} onClick={handleSaveEmail}>
            Guardar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>
      {/* Si (se envía) No (le da la opción de editar)  */}

      <div className="row">
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >

            {authUser.is_qualified_user !== 1 && <Tooltip placement="top" hasArrow label="Desbloquear">
              <span>
                <ButtonHeaderModule
                  onClick={() => {
                    unlockForm()
                    openAlert("Se ha desbloqueado con exito", "success")
                    navigate(-1)
                  }}
                  className="mx-1"
                  bgColor="#fe8d59"
                >
                  <IconGeneric
                    className="text-white"
                    color="#fff"
                    width="16px"
                    as={FaUnlock}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>}

            {values.status_outputForm === "PRESENTADO" && (
              <Tooltip placement="top" hasArrow label="Eliminar observaciones">
                <span>
                  <CustomizablePopover
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    className="me-1"
                    BodyButton={
                      <IconGeneric
                        width="16px"
                        className="text-white"
                        as={BiTrash}
                      />
                    }
                    Popovertitle=" Advertencia!"
                    bgColor="#d81717"
                    shadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px"}
                    _hover={{
                      color: "#fff",
                      backgroundColor: "#d8171790"
                    }}
                    ButtonCloseTitle={"Eliminar observaciones"}
                  >
                    <MessagerTrash onClose={onClose} id={values?.id} />
                  </CustomizablePopover>
                </span>
              </Tooltip>
            )}

            {values.status_outputForm === "PRESENTADO" && (
              <Tooltip placement="top" hasArrow label="Aprobar">
                <span>
                  <ButtonHeaderModule
                    onClick={() => handlePresent("APROBADO")}
                    className="me-1"
                    bgColor="#1F7AC3"
                  >
                    <IconGeneric
                      width="16px"
                      className="text-white"
                      as={BsCheck2Circle}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}
            {values.status_outputForm !== "DEVUELTO" && (
              <Tooltip placement="top" hasArrow label="Devolver">
                <span>
                  <ButtonHeaderModule
                    onClick={() => handlePresent("DEVUELTO")}
                    className="me-1"
                    bgColor="#d92525"
                  >
                    <IconGeneric
                      width="16px"
                      className="text-white"
                      as={AiOutlineFileSync}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}
          </Box>
        </div>
        {children}
        <div className="col">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <span>
                <ButtonHeaderModule
                  //   onClick={onOpen}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    width="16px"
                    className="text-white"
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  );
};
