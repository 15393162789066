import { Box, Flex, Radio, Spacer, Tooltip } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabeFormlStyled } from "styled/Input.styled";
import { useSelector } from "react-redux";
import { __Trm } from "request/configuration/__Trm";
import { FormatoCantidadMexico } from "components/Funciones/FormatoCantidadMexico";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { AuthContext } from "context/AuthContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { RadioBtnRedonder } from "components/ComponentRadioButton/RadioBtnRedonder";
import { IconGeneric } from "styled/Icons.styled";
import { TbFileInvoice } from "react-icons/tb";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { Cancelar } from "pages/RefProductInter/Stilos";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { NumericFormat } from "react-number-format";
import { parseFloat } from "utils/parseFloat";
import { useMutation } from "@tanstack/react-query";
import GlobalContext from "context/GlobalContext";
import { getFormData } from "redux/actions/dataFormActions";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { isArray, safelyConvertToString } from "utils/type-check-utils";
import { removeDecimalIfZero } from "../FormEntry/AddItemForm/AddItemForm";
import MaxValueHover from "../FormEntry/AddItemForm/MaxValueHover";

export const FormCorrectItems = ({
  idQualified,
  handleInputChange,
  values,
  setValues,
  multiplicacion,
  suma,
  dolar,
  handleCancel,
  hadleSaveItems,
  idUserQualifieder,
  flagBalance,
  setquantityError,
  quantityError,
  codeOriginalOpe,
  handleBlurReference,
  handleMultiplicacion,
  setMultiplicacion = null,
  setSuma = null,
  errorsState,
  valuesHeader,
  pesosToDollars,
  ...rest
}) => {
  const [openAlert] = AlertErrorAlert();
  const [isOpenInvoice, onOpenInvoice, onCloseInvoice] = useModal();
  const [valueInvoice, setValueInvoice] = useState(null);
  const message980 = `La cantidad ingresada no puede ser mayor a su cantidad inicial `;
  const message981 = `La cantidad ingresada no puede ser menor a su cantidad inicial `;
  const dataForm = useSelector(getFormData);

  const {
    selectOperation,
    userQualifiedNew,
    dataCountryOrigin,
    setDataCountryOrigin,
    dataCountryBuy,
    setDataCountryBuy,
    info,
    setInfo,
    valueProducts,
    setValueProducts,
    acuerdos,
    setAcuerdos,
    packagings,
    setPackagings,
    valueFlag,
    setValueFlag,
    valueMethod,
    setValueMethod,
    dataTransportMethodNew,
    dataCountry,
    dataPackagingsNew,
    dataAgreementsNew,
    dataProducts,
    setSelectCurrency,
    selectCurrency,
    dataTypeProduct,
    setSelectDataTypeProduct,
    selectDataTypeProduct,
    dataToEditItems,
    showItemsView,
    valorDolarActual,
    setValorDolarActual,
    selectNE,
    setSelectNE,
    dataNational,
  } = useContext(FormCrudCRUDContext);

  const {
    cif_value_sdo,
    cif_cop_value,
    cif_us_value,
    factor_conv,
    product_quantity,
    description_product,
    item_temporary_exit,
    description_subheading,
    gross_weight,
    net_weight,
    number_packages,
    quantity,
    us_freight,
    us_insurance,
    other_us_expenses,
    us_fob_value,
    exchange_rate,
    entry_form_item_description,
    coin,
    code_subheading,
    code_businessUnit,
    code_product,
    code_country_proc,
    name_country_proc,
    code_country,
    name_country,
    code_country_purs,
    name_country_purs,
    code_transportMethod,
    name_transportMethod,
    code_contry_flag,
    name_country_flag,
    code_packaging,
    name_packaging,
    code_tradeAgreement,
    name_tradeAgreement,
    quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_corrected,
    product_quantity_corrected,
    item_temporary_exit_corrected,
    code_subheading_corrected,
    description_subheading_corrected,
    gross_weight_corrected,
    net_weight_corrected,
    number_packages_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    exchange_rate_corrected,
    description_product_corrected,
    entry_form_item_description_corrected,
    name_typeProduct,
    invoice,
    invoice_corrected,
    brand,
    brand_corrected,
    serial,
    serial_corrected,
    adjustment_justification_fob_corrected,
    code_unit_measurement_corrected,
    code_unit_measurement,
    national_or_foreign_corrected,
    national_or_foreign,
    flag_id,
    max_value_cop,
  } = values;

  const dataCoin = ["USD", "COP"];
  const paisOrigen = code_country ? code_country + " " + name_country : null;
  const paisProcedencia = code_country_proc
    ? code_country_proc + " " + name_country_proc
    : null;
  const paisCompra = code_country_purs
    ? code_country_purs + " " + name_country_purs
    : null;
  const metodTransporte = code_transportMethod
    ? code_transportMethod + " " + name_transportMethod
    : null;
  const banderas = code_contry_flag
    ? code_contry_flag + " " + name_country_flag
    : null;
  const embalaje = code_packaging ? code_packaging + " " + name_packaging : "";
  const acuerdoCome = code_tradeAgreement
    ? code_tradeAgreement + " " + name_tradeAgreement
    : "";

  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const [fomateo] = useFormatQuantity();
  const { authUser } = useContext(AuthContext);
  const location = useLocation(); // navigate

  const { FormEntry = "" } = queryString.parse(location.search);
  const { requestData } = useContext(GlobalContext);
  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const getTrm = async () => {
    try {
      const res = await __Trm(token);
      setValorDolarActual(parseFloat(res?.data?.status?.data.value_trm));
    } catch (error) {}
  };

  const handleChangeCoin = (e) => {
    setSelectCurrency(e);
    updateCurrencyField(e);
  };

  useEffect(() => {
    getTrm();
  }, []);

  const handleChangeRadio = (e) => {
    const valor = e.target.id;
    setValues({ ...values, invoice_corrected: valor });
    onCloseInvoice();
  };

  const getInvoice = async (e) => {
    e.preventDefault();

    try {
      const res = await __GetDataId(
        environment.GET_COMERCIAL_INVOICE_ENTRY_CORRECT,
        token,
        FormEntry
      );
      const destruct = res.data.status;
      if (destruct.code === 200) {
        onOpenInvoice();
        setValueInvoice(destruct.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateItemValue = (newValue, sourceEvent) => {
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue,
    };
    handleInputChange(target);
  };

  const [Calculated, setCalculated] = useState(null);

  const handleResponseCalculated = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      return setCalculated(res?.data?.status?.data);
    }
  };

  function parseNumberWithCommas(value) {
    return parseFloat(value);
  }

  const updateCurrencyField = (e) => {
    const Currency = e;

    const parsedValues = {
      freight_corrected: parseNumberWithCommas(us_freight_corrected),
      insurance_corrected: parseNumberWithCommas(us_insurance_corrected),
      other_expenses_corrected: parseNumberWithCommas(
        other_us_expenses_corrected
      ),
      fob_value_corrected: parseNumberWithCommas(us_fob_value_corrected),
    };

    const data = {
      type_calculated: Currency,
      exchange_rate_corrected: exchange_rate_corrected,
      decimal_precision_corrected: dataForm?.precision || 4,
      ...parsedValues,
    };

    const requestData = {
      data,
      Endpoint: environment.CALCULATED_ITEM_CORRECTED,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(requestData, {
      onSuccess: handleResponseCalculated,
    });
  };

  useEffect(() => {
    if (Calculated) {
      const {
        fob_value_calculated,
        freight_calculated,
        insurance_calculated,
        other_expenses_calculated,
        cif_us_value_calculated,
        cif_cop_value_calculated,
      } = Calculated;
      setValues((prev) => ({
        ...prev,
        us_fob_value_corrected: parseFloat(fob_value_calculated),
        us_freight_corrected: parseFloat(freight_calculated),
        us_insurance_corrected: parseFloat(insurance_calculated),
        other_us_expenses_corrected: parseFloat(other_expenses_calculated),
      }));
      setMultiplicacion &&
        setMultiplicacion(parseFloat(cif_cop_value_calculated));

      setSuma && setSuma(parseFloat(cif_us_value_calculated));
    }
  }, [Calculated]);

  const decimalScale = dataForm?.precision || decimal_values_precision || 2;

  //removeDecimalIfZero(multiplicacion) ? 0 : decimalPrecision
  return (
    <>
      <ModalGeneric
        isOpen={isOpenInvoice}
        onOpen={onOpenInvoice}
        onClose={onCloseInvoice}
        title="Seleccionar factura existente"
        size="sm"
      >

        {isArray(valueInvoice) &&
          valueInvoice.map((e, index) => {
            if (e.invoice_corrected) {
              return (
                <div key={index}>
                  <Radio
                    id={e.invoice_corrected}
                    value={e.invoice_corrected}
                    onChange={handleChangeRadio}
                  >
                    {e.invoice_corrected}
                  </Radio>
                  <br />
                </div>
              );
            }
          })}
        <Flex mt={5}>
          <Spacer />
          <Cancelar onClick={onCloseInvoice}>Cancelar</Cancelar>
        </Flex>
      </ModalGeneric>
      <ProductSearchBar
        label="Código producto"
        size={100}
        required={true}
        set={setValueProducts}
        value={valueProducts}
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(code_product) !==
            safelyConvertToString(valueProducts?.label) && "BorderCorrect"
        }
        callBack={(_, __, data) =>
          rest?.handleChangeProduct({
            data,
            exchange_rate_corrected_change: valorDolarActual,
          })
        }
        ButtonSide={true}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          name_typeProduct !== selectDataTypeProduct?.label && "BorderCorrect"
        }
        tag="Tipo de producto"
        data={dataTypeProduct}
        set={setSelectDataTypeProduct}
        value={selectDataTypeProduct}
        error={errorsState?.typeProductCorrected_id && !selectDataTypeProduct}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(factor_conv) !== parseFloat(factor_conv_corrected) &&
          "BorderCorrect"
        }
        name={"factor_conv_corrected"}
        value={factor_conv_corrected}
        tag={"Factor Conv."}
        size={100}
        desicion={true}
      />
      {/* <LabelGroup
        changeValue={factor_conv !== factor_conv_corrected && "BorderCorrect"}
        name={"factor_conv_corrected"}
        onChange={handleInputChange}
        value={factor_conv_corrected}
        tag={"Factor Conv."}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        changeValue={
          safelyConvertToString(code_unit_measurement_corrected) !==
            safelyConvertToString(code_unit_measurement) && "BorderCorrect"
        }
        name={"code_unit_measurement_corrected"}
        value={code_unit_measurement_corrected ?? ""}
        tag={"Unidad de medida"}
        size={100}
        desicion={true}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(product_quantity) !==
            parseFloat(product_quantity_corrected) && "BorderCorrect"
        }
        name={"product_quantity_corrected"}
        id={product_quantity}
        value={product_quantity_corrected}
        tag={"Cantidad producto"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        error={
          errorsState?.product_quantity_corrected && !product_quantity_corrected
        }
      >
        <span className="text-danger">
          {quantityError?.includes("product_quantity_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("product_quantity_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(quantity) !== parseFloat(quantity_corrected) &&
          "BorderCorrect"
        }
        name={"quantity_corrected"}
        value={quantity_corrected}
        id={quantity}
        tag={"Cantidad"}
        desicion={showItemsView || selectOperation === null}
        size={100}
      >
        <span className="text-danger">
          {quantityError?.includes("quantity_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("quantity_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      {/*   <LabelGroup
        changeValue={
          fob_sdo_value !== fob_sdo_value_corrected && "BorderCorrect"
        }
        name={"fob_sdo_value_corrected"}
        value={fob_sdo_value_corrected}
        tag={"Valor FOB Sdo."}
        size={100}
        desicion={true}
      />

      <LabelGroup
        changeValue={
          cif_value_sdo &&
          cif_value_sdo !== cif_us_value_corrected &&
          "BorderCorrect"
        }
        name={"cif_value_sdo_corrected"}
        value={cif_value_sdo_corrected}
        tag={"Valor CIF Sdo."}
        onChange={handleInputChange}
        size={100}
        desicion={true}
      />

      <LabelGroup
        changeValue={
          gross_weight_sdo !== gross_weight_sdo_corrected && "BorderCorrect"
        }
        name={"gross_weight_sdo_corrected"}
        value={gross_weight_sdo_corrected}
        tag={"Peso bruto Sdo."}
        size={100}
        desicion={true}
      />

      <LabelGroup
        changeValue={amount_sdo !== amount_sdo_corrected && "BorderCorrect"}
        name={"amount_sdo_corrected"}
        value={amount_sdo_corrected}
        tag={"Cantidad Sdo."}
        size={100}
        desicion={true}
      /> */}
      <LabelGroup
        changeValue={
          safelyConvertToString(item_temporary_exit) !==
            safelyConvertToString(item_temporary_exit_corrected) &&
          "BorderCorrect"
        }
        name={"item_temporary_exit_corrected"}
        onChange={handleInputChange}
        value={item_temporary_exit_corrected}
        tag={"Item de Salida Temporal"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(code_businessUnit) !==
            safelyConvertToString(code_businessUnit_corrected) &&
          "BorderCorrect"
        }
        name={"code_businessUnit_corrected"}
        value={code_businessUnit_corrected ?? ""}
        tag={"Unidad comercial"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(code_subheading) !==
            safelyConvertToString(code_subheading_corrected) && "BorderCorrect"
        }
        name={"code_subheading_corrected"}
        value={code_subheading_corrected}
        tag={"Subpartida arancelaria"}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(description_subheading) !==
            safelyConvertToString(description_subheading_corrected) &&
          "BorderCorrect"
        }
        name={"description_subheading_corrected"}
        value={description_subheading_corrected}
        tag={"Descripción de la Subpartida"}
        size={100}
        desicion={true}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(paisOrigen) !==
            safelyConvertToString(info?.label) && "BorderCorrect"
        }
        tag={"País origen"}
        data={dataCountry}
        set={setInfo}
        value={info}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(paisProcedencia) !==
            safelyConvertToString(dataCountryOrigin?.label) && "BorderCorrect"
        }
        tag={"País procedencia *"}
        data={dataCountry}
        set={setDataCountryOrigin}
        value={dataCountryOrigin}
        error={errorsState?.countryProcedencyCorrected_id && !dataCountryOrigin}
      />
      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(paisCompra) !==
            safelyConvertToString(dataCountryBuy?.label) && "BorderCorrect"
        }
        tag={"País compra *"}
        data={dataCountry}
        set={setDataCountryBuy}
        value={dataCountryBuy}
        eror={errorsState?.countryPurchaseCorrected_id && !dataCountryBuy}
      />

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(gross_weight) !== parseFloat(gross_weight_corrected) &&
          "BorderCorrect"
        }
        name={"gross_weight_corrected"}
        value={gross_weight_corrected}
        id={gross_weight}
        tag={"Peso bruto"}
        size={100}
        desicion={showItemsView || selectOperation === null}
        error={
          errorsState?.gross_weight_corrected &&
          (!gross_weight_corrected ||
            Number(gross_weight_corrected) === 0 ||
            Number(gross_weight_corrected) < Number(net_weight_corrected))
        }
      >
        <span className="text-danger">
          {quantityError?.includes("gross_weight_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("gross_weight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : errorsState?.gross_weight_corrected &&
              Number(gross_weight_corrected) < Number(net_weight_corrected)
            ? "El Peso Bruto debe ser mayor o igual al peso Neto"
            : Number(gross_weight_corrected) === 0
            ? "El Peso Bruto no puede ser igual a cero"
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(net_weight) !== parseFloat(net_weight_corrected) &&
          "BorderCorrect"
        }
        name={"net_weight_corrected"}
        value={net_weight_corrected}
        tag={"Peso neto"}
        size={100}
        id={net_weight}
        desicion={showItemsView || selectOperation === null}
        error={
          errorsState?.net_weight_corrected &&
          (!net_weight_corrected ||
            Number(net_weight_corrected) === 0 ||
            Number(net_weight_corrected) > Number(gross_weight_corrected))
        }
      >
        <span className="text-danger">
          {quantityError?.includes("net_weight_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("net_weight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : errorsState?.net_weight_corrected &&
              Number(net_weight_corrected) > Number(gross_weight_corrected)
            ? "El Peso Neto debe ser menor o igual al Peso Bruto"
            : Number(net_weight_corrected) === 0
            ? "El Peso Neto no puede ser igual a cero"
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={decimalScale}
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(number_packages) !==
            parseFloat(number_packages_corrected) && "BorderCorrect"
        }
        name={"number_packages_corrected"}
        value={number_packages_corrected}
        tag={"Números de bultos"}
        id={number_packages}
        size={100}
        desicion={showItemsView || selectOperation === null}
      >
        <span className="text-danger">
          {quantityError?.includes("number_packages_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("number_packages_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(metodTransporte) !==
            safelyConvertToString(valueMethod?.label) && "BorderCorrect"
        }
        tag={"Modo de transporte *"}
        data={dataTransportMethodNew}
        set={setValueMethod}
        value={valueMethod}
        error={errorsState?.transportMethodCorrected_id && !valueMethod}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={flag_id !== valueFlag?.value && "BorderCorrect"}
        tag={"Bandera *"}
        data={dataCountry}
        set={setValueFlag}
        value={valueFlag}
        error={errorsState?.flagCorrected_id && !valueFlag}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(embalaje) !==
            safelyConvertToString(packagings?.label) && "BorderCorrect"
        }
        tag={"Embalaje *"}
        data={dataPackagingsNew}
        set={setPackagings}
        value={packagings}
        error={errorsState?.packagingCorrected_id && !packagings}
      />

      <div
        style={{
          height: "62px",
        }}
        className={coin !== selectCurrency && "BorderCorrect"}
      >
        <LabeFormlStyled
          style={{
            marginBottom: "6px",
          }}
        >
          Monedas
        </LabeFormlStyled>

        <RadioBtnRedonder
          options={dataCoin}
          isDisabled={
            rest?.disableCurrency || showItemsView || flagBalance === 1
          }
          diff={coin !== selectCurrency}
          setOnly={handleChangeCoin}
          state={selectCurrency === null ? "" : selectCurrency}
        />
      </div>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={
          decimalScale
        }
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(us_freight) !== parseFloat(us_freight_corrected) &&
          "BorderCorrect"
        }
        name={"us_freight_corrected"}
        value={removeDecimalIfZero(us_freight_corrected, decimalScale, 'us_freight_corrected, Fletes')}
        tag={`Fletes ${selectCurrency}`}
        id={us_freight}
        size={100}
        desicion={showItemsView || selectOperation === null}
        error={
          errorsState?.us_freight_corrected &&
          Number(us_freight_corrected) === 0 &&
          Number(selectOperation?.value) !== 120
        }
      >
        <span className="text-danger">
          {quantityError?.includes("us_freight_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_freight_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : Number(us_freight_corrected) === 0 &&
              Number(selectOperation?.value) >= 101 &&
              Number(selectOperation?.value) <= 199 &&
              Number(selectOperation?.value) !== 120
            ? "el valor de los fletes no puede ser cero"
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={
           decimalScale
        }
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(us_insurance) !== parseFloat(us_insurance_corrected) &&
          "BorderCorrect"
        }
        name={"us_insurance_corrected"}
        value={removeDecimalIfZero(us_insurance_corrected, decimalScale, 'us_insurance_corrected, Seguros')}
        tag={`Seguros ${selectCurrency}`}
        id={us_insurance}
        size={100}
        desicion={showItemsView || selectOperation === null}
      >
        <span className="text-danger">
          {quantityError?.includes("us_insurance_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_insurance_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={
          decimalScale
        }
        decimalSeparator="."
        customInput={LabelGroup}
        name={"other_us_expenses_corrected"}
        changeValue={
          parseFloat(other_us_expenses) !==
            parseFloat(other_us_expenses_corrected) && "BorderCorrect"
        }
        value={removeDecimalIfZero(other_us_expenses_corrected, decimalScale, 'other_us_expenses_corrected, Otros gastos')}
        tag={` Otros gastos ${selectCurrency} `}
        id={other_us_expenses}
        size={100}
        desicion={showItemsView || selectOperation === null}
      >
        <span className="text-danger">
          {quantityError?.includes("other_us_expenses_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("other_us_expenses_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalScale={
          decimalScale
        }
        decimalSeparator="."
        customInput={LabelGroup}
        changeValue={
          parseFloat(us_fob_value) !== parseFloat(us_fob_value_corrected) &&
          "BorderCorrect"
        }
        name={"us_fob_value_corrected"}
        value={removeDecimalIfZero(us_fob_value_corrected, decimalScale, 'us_fob_value_corrected, Valor FOB')}
        tag={`Valor FOB ${selectCurrency}`}
        id={us_fob_value}
        size={100}
        desicion={showItemsView || selectOperation === null}
        TagChildren={
          <MaxValueHover 
            selectCurrency={selectCurrency} 
            us_fob_value={us_fob_value_corrected} 
            exchange_rate={exchange_rate_corrected}
            valorDolarActual={valorDolarActual}
            max_value_cop={max_value_cop}
          />
        }
      >
        <span className="text-danger">
          {quantityError?.includes("us_fob_value_corrected") &&
          (selectOperation?.value === 980 ||
            selectOperation?.value === 982 ||
            selectOperation?.value === 984)
            ? message980
            : quantityError?.includes("us_fob_value_corrected") &&
              (selectOperation?.value === 981 ||
                selectOperation?.value === 983 ||
                selectOperation?.value === 985)
            ? message981
            : ""}
        </span>
      </NumericFormat>

      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={
          decimalScale
        }
        customInput={LabelGroup}
        name={"cif_us_value"}
        value={removeDecimalIfZero(suma, decimalScale, 'cif_us_value, Valor CIF')}
        changeValue={
          parseFloat(cif_us_value) !== parseFloat(suma) && "BorderCorrect"
        }
        tag={`Valor CIF ${selectCurrency} `}
        size={100}
        desicion={true}
        /*  desicion={showItemsView || selectOperation === null} */
      />

      {/*       <LabelGroup
        changeValue={cif_us_value !== suma && "BorderCorrect"}
        name={"cif_us_value"}
        value={suma ? suma : 0}
        tag={` Valor CIF ${selectCurrency} `}
        size={100}
        desicion={showItemsView || selectOperation === null}
      /> */}

      {selectCurrency === "USD" && (
        <NumericFormat
          allowNegative={false}
          onValueChange={updateItemValue}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={decimalScale}
          customInput={LabelGroup}
          name={"cif_cop_value"}
          value={multiplicacion}
          changeValue={
            parseFloat(cif_cop_value) !== parseFloat(multiplicacion) &&
            "BorderCorrect"
          }
          tag={`Valor CIF COP `}
          size={100}
          desicion={true}
          /*  desicion={showItemsView || selectOperation === null} */
          readOnly={false}
        />
      )}
      {/* {selectCurrency === "USD" && (
        <LabelGroup
          name={"cif_cop_value"}
          value={multiplicacion}
          changeValue={cif_cop_value !== multiplicacion && "BorderCorrect"}
          tag={`Valor CIF COP `}
          size={100}
          desicion={showItemsView || selectOperation === null}
          readOnly={false}
        />
      )} */}
      {selectCurrency === "COP" && (
        <NumericFormat
          allowNegative={false}
          onValueChange={updateItemValue}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={
            decimalScale
          }
          customInput={LabelGroup}
          name={"cif_us_value"}
          value={removeDecimalIfZero(pesosToDollars, decimalScale, 'cif_us_value, Valor CIF')}
          changeValue={
            parseFloat(cif_cop_value) !== parseFloat(multiplicacion) &&
            "BorderCorrect"
          }
          tag={`Valor CIF USD`}
          size={100}
          desicion={true}
          /*   desicion={showItemsView || selectOperation === null} */
          readOnly={false}
        />
      )}
      {/* {selectCurrency === "COP" && (
        <LabelGroup
          name={"cif_us_value"}
          value={pesosToDollars}
          // changeValue={cif_cop_value !== multiplicacion && "BorderCorrect"}
          tag={`Valor CIF USD`}
          size={100}
          desicion={showItemsView || selectOperation === null}
          readOnly={false}
        />
      )} */}
      <NumericFormat
        allowNegative={false}
        onValueChange={updateItemValue}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={decimalScale}
        customInput={LabelGroup}
        name={"exchange_rate_corrected"}
        onBlur={(e) => {
          if (!exchange_rate_corrected) {
            openAlert("Se agregó la tasa de cambio por defecto", "warn");
            setValues((prev) => ({
              ...prev,
              exchange_rate_corrected: valorDolarActual,
            }));
          }
        }}
        value={exchange_rate_corrected}
        changeValue={
          parseFloat(exchange_rate) !== parseFloat(exchange_rate_corrected) &&
          "BorderCorrect"
        }
        tag={"Tasa de Cambio"}
        size={100}
        desicion={showItemsView || selectOperation === null}
      />

      <InputSelect
        isDisabled={showItemsView || flagBalance === 1}
        className={
          safelyConvertToString(acuerdoCome) !==
            safelyConvertToString(acuerdos?.label) && "BorderCorrect"
        }
        tag={"Acuerdo Comercial"}
        data={dataAgreementsNew}
        set={setAcuerdos}
        value={acuerdos}
      />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <LabelGroup
          changeValue={
            safelyConvertToString(invoice) !==
              safelyConvertToString(invoice_corrected) && "BorderCorrect"
          }
          name={"invoice_corrected"}
          value={invoice_corrected}
          onChange={handleInputChange}
          tag={"Factura"}
          size={100}
          desicion={showItemsView || flagBalance === 1}
          ButtonSide={valuesHeader?.num_commercial_invoice_corrected && true}
        />
        {valuesHeader?.num_commercial_invoice_corrected && (
          <Tooltip
            hasArrow
            label={"Seleccionar factura existente"}
            placement="top"
          >
            <button
              onClick={getInvoice}
              style={{
                width: "42px",
                marginTop: "24px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                height: "38px",
                backgroundColor: "#1f7ac3",
                outline: "none",
              }}
            >
              <IconGeneric color="#fff" as={TbFileInvoice} />
            </button>
          </Tooltip>
        )}
      </div>

      <LabelGroup
        changeValue={
          safelyConvertToString(brand) !==
            safelyConvertToString(brand_corrected) && "BorderCorrect"
        }
        name={"brand_corrected"}
        value={brand_corrected}
        onChange={handleInputChange}
        tag={"Marca"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(serial) !==
            safelyConvertToString(serial_corrected) && "BorderCorrect"
        }
        name={"serial_corrected"}
        value={serial_corrected}
        onChange={handleInputChange}
        tag={"Serial"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(description_product) !==
            safelyConvertToString(description_product_corrected) &&
          "BorderCorrect"
        }
        name={"description_product_corrected"}
        value={description_product_corrected}
        tag={"Descripción producto"}
        onChange={handleInputChange}
        size={100}
        desicion={true}
      />
      <LabelGroup
        changeValue={
          safelyConvertToString(entry_form_item_description) !==
            safelyConvertToString(entry_form_item_description_corrected) &&
          "BorderCorrect"
        }
        name={"entry_form_item_description_corrected"}
        value={entry_form_item_description_corrected}
        onChange={handleInputChange}
        tag={"Descripción larga"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      <LabelGroup
        name={"adjustment_justification_fob_corrected"}
        value={adjustment_justification_fob_corrected}
        onChange={handleInputChange}
        tag={"Motivo ajuste al FOB"}
        size={100}
        desicion={showItemsView || flagBalance === 1}
      />
      {((dataForm?.code_operation >= 500 && dataForm?.code_operation < 600) ||
        (selectOperation?.value >= 500 && selectOperation?.value < 600)) && (
        <div className="mt-1">
          <RadioCheck
            name="national_or_foreign_corrected"
            onChange={handleInputChange}
            value={national_or_foreign_corrected}
            item={["Nacional", "Extranjera"]}
            diff={national_or_foreign !== national_or_foreign_corrected}
          />
        </div>
      )}

      <div
        style={{
          height: "62px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          // flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={handleCancel}>
            Cancelar
          </ButtonStyled>
          {!showItemsView && (
            <ButtonStyled type="submit" onClick={hadleSaveItems}>
              {dataToEditItems ? "Actualizar" : "Agregar"}
            </ButtonStyled>
          )}
        </Box>
      </div>
    </>
  );
};
