import { Box, SimpleGrid, Tooltip } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useForm } from "hooks/useForm";
import { SearchEditedFields } from "hooks/useLocalStorage";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
import { ButtonStyled } from "styled/Buttons.styled";
import { TextAreaFormStyled } from "styled/Input.styled";
import queryString from "query-string";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceAlert, useDebounceError } from "hooks/useDebounceError";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import axios from "axios";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { parseFloat } from "utils/parseFloat";
import { NumericFormat } from "react-number-format";
import { removeDecimalIfZero } from "utils/type-check-utils";


const initialState = {
  service: "",
  exchange_rate: "",
  entry_form_item_description: ""
};

const AddServiceItem = ({ Reviews, valuesHeader }) => {
  const { dataToEditItems, CreateItems } = useContext(FormCrudCRUDContext);
  const token = useSelector(state => state.auth.dataUser.token);

  const {
    setShow,
    EditItems,
    dataToCopyItems,
    setDataToEditItems,
    setDataToCopyItems,

    setUpdate,
    update
  } = useContext(FormCrudCRUDContext);

  const { data: dataTRM } = useQuery({
    queryKey: [queryEnv?.TRM],
    queryFn: async () => {
      const options = {
        method: "GET",
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      };

      const res = await axios(`${environment.TRM_GET}`, options);
      if (res?.data?.status?.code === 200) {
        setValues(prev => ({
          ...prev,
          ...(!prev?.exchange_rate ? { exchange_rate: res?.data?.status?.data.value_trm } : {}),
        }));
        return {
          ...res?.data?.status,
          data: {
            ...res?.data?.status?.data,
            value_trm: parseFloat(res?.data?.status?.data.value_trm)
          }
        }
      };
      return res;
    },
  })

  const [values, handleInputChange, reset, setValues] = useForm(initialState);


  useEffect(() => {
    if (dataToCopyItems) {
      setValues((prev) => ({
        ...prev,
        exchange_rate: dataToCopyItems?.exchange_rate
          .toString()
          .replaceAll(",", "")
      }));
      return;
    }
    if (dataToEditItems) {
      setValues(dataToEditItems);
      return;
    }
    reset();
  }, [dataToEditItems, dataToCopyItems]);

  const [openAlert] = AlertErrorAlert();

  const location = useLocation(); // navigate

  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );

  const { service, exchange_rate, entry_form_item_description } = values;

  const handleSubmit = () => {
    const formData = new FormData();
    if (parseFloat(exchange_rate) === 0) { return openAlert("La tasa de cambio no puede ser 0 o vacia", "error"); }
    if (service == null || service.length === 0) { return openAlert("El campo servicio es obligatorio", "error"); }
    if (exchange_rate == null || exchange_rate.length === 0) { return openAlert("El campo tasa de cambio es obligatorio", "error"); }
    if (
      entry_form_item_description == null ||
      entry_form_item_description?.length === 0
    ) {
      return openAlert("El campo descripción es obligatorio", "error");
    }
    formData.append("service", removeDecimalIfZero(service, 2));
    formData.append(
      "exchange_rate", removeDecimalIfZero(exchange_rate, 2)
    );
    formData.append("entry_form_item_description", entry_form_item_description);
    formData.append("entryForm_id", valuesHeader?.id);
    if (dataToEditItems) {
      formData.append("id", dataToEditItems.id);
      return EditItems(formData, handleCancel);
    }
    CreateItems(formData, handleCancel);
  };

  const handleCancel = () => {
    setDataToEditItems(null);
    setDataToCopyItems(null);
    setShow(false);
    reset();
  };




  const updateItemValue = (newValue, sourceEvent) => {
    const { floatValue, formattedValue, value } = newValue
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target]: {
        ...sourceEvent?.event?.target,
        value: parseFloat(floatValue || value),
      },
    }
    handleInputChange(target)
  }


  return (
    <div
      className=" shadow-sm p-1 mb-4 rounded"
      style={{ background: "#f2f2f2", marginTop: "40px" }}
    >
      <h1 className="my-4 ms-1 fw-bolder">Agregar ítem</h1>
      <>
        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <Tooltip
            hasArrow
            label={
              Reviews ? SearchEditedFields(`service${values?.id}`, Reviews) : ""
            }
            bg="red.600"
            color="white"
            placement="bottom-start"
          >
            <>
              <NumericFormat
                allowNegative={false}
                onValueChange={updateItemValue}
                thousandSeparator={true}
                decimalScale={4}
                decimalSeparator='.'
                customInput={LabelGroup}
                placeholder={"Valor del servicio"}
                name={'service'}
                value={service}
                error={
                  !!(Reviews && SearchEditedFields(`service${values?.id}`, Reviews))
                }
                tag={"Valor del Servicio"}
                maxLength={20}

                size={100}
              />
            </>
          </Tooltip>

          <Tooltip
            hasArrow
            label={Reviews
              ? SearchEditedFields(`exchange_rate${values?.id}`, Reviews)
              : ""}
            bg={"red.600"}
            color='white'
            placement='bottom-start'
          >
            <NumericFormat
              allowNegative={false}
              onValueChange={updateItemValue}
              thousandSeparator={true}
              decimalScale={4}
              decimalSeparator='.'
              customInput={LabelGroup}

              name={'exchange_rate'}
              value={exchange_rate}
              onBlur={() => {
                if (!exchange_rate || exchange_rate === '' || exchange_rate === 0 || exchange_rate === '0') {
                  setValues((prev) => ({ ...prev, exchange_rate: dataTRM?.data?.value_trm }));
                }
              }}
              error={
                !!(Reviews &&
                  SearchEditedFields(`exchange_rate${values?.id}`, Reviews))
              }
              defaultValue={dataTRM?.data?.value_trm}
              tag={`Tasa de Cambio`}
              size={100}
            />
          </Tooltip>

        </SimpleGrid>
        <Tooltip
          hasArrow
          label={
            Reviews
              ? SearchEditedFields(
                `entry_form_item_description${values?.id}`,
                Reviews
              )
              : ""
          }
          bg="red.600"
          color="white"
          placement="bottom-start"
        >
          <div className="mt-1 pb-10">
            <LabelStyled
              error={
                !!(Reviews &&
                  SearchEditedFields(
                    `entry_form_item_description${values?.id}`,
                    Reviews
                  ))
              }
            >
              {"Descripción"}
            </LabelStyled>
            <TextAreaFormStyled
              tipo={"textarea"}
              name={"entry_form_item_description"}
              onChange={handleInputChange}
              value={
                entry_form_item_description || ""
              }
              placeholder={""}
              tag={"Descripción"}
              error={
                !!(Reviews &&
                  SearchEditedFields(
                    `entry_form_item_description${values?.id}`,
                    Reviews
                  ))
              }
              size={100}
              maxLength={5000}
            />
          </div>
        </Tooltip>
        <Box
          display="flex"
          alignItems="center"
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent="flex-end"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={handleCancel}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleSubmit}>
            {dataToEditItems ? "Actualizar" : "Agregar"}
          </ButtonStyled>
        </Box>
      </>
    </div>
  );
};

export default AddServiceItem;
