import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useState, useCallback, useEffect, useRef } from "react";

import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { Bar } from "react-chartjs-2";

import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { __Get } from "request/Petitions/__Get";

import { ExportPdf } from "components/Export/ExportPdf";

import { formExportSecurityQualifiedUser } from "components/Estadistica/ArryList";
import { estadisticaOptions } from "../General/EstadisticaOptions";

export const EstadisticaQualifiedUser = () => {
  const [loading, setLoading] = useState(false);
  const [Current, setCurrent] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);
  const ref = useRef(null);

  const [userData, setUserData] = useState({
    labels: Current && Current.map((data) => data.qualified_users),
    datasets: [
      {
        label: ["Vetados"],
        data: Current && Current.map((data) => data.amount_t),
        backgroundColor: ["#006EB8", "#47B3E7", "#9CD8F7"]
      }
    ]
  });

  const Obtener = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __Get(
        environment.OBTENER_STATISTICS_USER_QUALIFIED,
        token
      );
      const data = res?.data?.status;
      if (data?.code === 200) {
        setCurrent(data?.data);
        setUserData({
          labels:
            data?.data &&
            data?.data.map(({ qualified_users }) => qualified_users),
          datasets: [
            {
              label: "Cantidad",
              data: data?.data && data?.data.map(({ amount_t }) => amount_t),
              backgroundColor: ["#2898ee", "#107acc"]
            }
          ]
        });

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [token]);

  useEffect(() => {
    Obtener();
  }, []);

  const title = "Estadisticas de usuarios calificados";
  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) ExportPdf("IdPdfFmmCorrectEntry", `${title}_${dateToday}`);
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  return (
    <div id="contenedorEstadistica">
      <HeaderEstadistica
        title={title}
        handlePDF={dowmLoadPdfImgae}
        filter={false}
        excelAdvancePath={environment.POST_EXPORT_EXCEL_QUALIFIEDUSER}
        arrayList={formExportSecurityQualifiedUser}
      // excelPath={environment.POST_EXPORT_EXCEL_QUALIFIEDUSER}
      />
      {loading && <SpinnerComponent />}
      {!loading && (
        <>

          <ExportPdfGrafica
            id="IdPdfFmmCorrectEntry"
            rutaImage={rutaImage}
            thead={""}
            data={""}
            tbody={"TbodySecurity"}
            title={`Estadisticas de ${title}`}
            dateToday={dateToday}
          />

          <div className="d-flex">
            <div id="contenedorResultado" className="px-2">
              {!loading && <Bar data={userData} ref={ref} options={estadisticaOptions(
                "Tipo de usuario",
                "Cantidad",
                "Usuarios activos"
              )}/>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
