/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text, Box, Switch, Divider } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { AuthContext } from "context/AuthContext";
import { __AUnidad } from "../../../request/configuration/_AUnidad";
import { useForm } from "hooks/useForm";
import { InputGroupUser, LabelGroup } from "pages/RefProductInter/LabelGroup";
import { LabeFormlStyled } from "styled/Input.styled";
import ProductCRUDContext from "context/ProductCRUDContext";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import InputSelect from "components/InputSelect/InputSelect";
import { useLocation } from "react-router-dom";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";

const FormUnitMeasurement = ({ valueQualified }) => {
  const location = useLocation();
  const { pathname } = location;
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [check, setCheck] = useState(true);
  const [userQ, setUserQ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valor, setValor] = useState(null);
  const [nameQ, setNameQ] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const {
    valueQualified: valueQualifiedOrigin,
    nameQualified,
    userQualified,
    setUserQuialified,
    setValueQuialified,
    setNameQualified,
    setDecimalUcContext
  } = useContext(FormCrudCRUDContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    unit_measurement: "",
    status_unit_measurement: 1,
    code_unit_measurement: ""
  });

  const { unit_measurement, code_unit_measurement } = values;

  const {
    onCloseModal,
    createData,
    dataToEdit,
    editData,
    setRequestCreate,
    onLoading,
    noLoading
  } = useContext(ProductCRUDContext);

  useEffect(() => {
    if (dataToEdit) {
      setValues({ ...dataToEdit });
      setCheck(dataToEdit.status_unit_measurement === 1);
      // setNameQ(dataToEdit?.name_qualified);
      setValor(dataToEdit?.qualifiedUser_id);

      const userQualified = async () => {
        setLoading(true);
        try {
          const res = await __AllQualifiedUSers(token);
          const objetivos = res.data.status.data.find(
            (element) => element.id === dataToEdit.qualifiedUser_id
          );
          setNameQ(
            authUser === 1 ? objetivos.name_sector : objetivos.name_qualified
          );
        } catch (error) {}
      };
      userQualified();
    }
  }, [dataToEdit]);

  useEffect(() => {
    const userQualified = async () => {
      setLoading(true);
      try {
        const res = await __AllQualifiedUSers(token);
        setUserQ(res?.data?.status?.data);
      } catch (error) {}
    };
    userQualified();

    const userC = async () => {
      try {
        const res = await __AUnidad(token);
        setData(res?.data?.status?.data);
      } catch (error) {}
    };
    userC();
  }, []);

  const selectValor = (optionesVa = []) => {
    // console.log(optionesVa);
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.nit_qualified + " - " + element?.name_qualified,
        value: element?.id,
        decimal: element?.decimal_values_precision
      });
    });
    return valores;
  };
  const { onFmm, changeQualified } = useContext(FormCrudCRUDContext);

  const handleSubmit = (e) => {
    const result = {
      ...values,
      status_unit_measurement: values.status_unit_measurement ? 1 : 0,
      qualifiedUser_id:
        authUser.admin === 1 || authUser.is_commerce === 1
          ? onFmm
            ? valueQualifiedOrigin
            : valor || userQualified?.value
          : authUser.userQualified
    };

    if (dataToEdit) {
      const data = {
        ...result,
        id: dataToEdit.id
      };

      editData(data);
      onCloseModal();
    } else {
      createData(result);
    }
  };

  useEffect(() => {
    setRequestCreate(environment.UNIT_CREATE);
  }, []);

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" mb={1} style={{ fontWeight: 500 }}>
        {dataToEdit ? "Editar Unidad De Medida" : "Crear Unidad De Medida"}
      </Text>
      <Divider mb={3} />
      <form action="">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          {(authUser.admin === 1 || authUser.is_commerce === 1) &&
            pathname === "/FormEntry" && (
            <div style={{ width: "45%" }}>
              <InputGroupUser
                Name={"name_qualified"}
                partidas={userQualified}
                tag={"Usuario calificado"}
                LabelBg={"red.600"}
                setMendigo={setValueQuialified}
                setNameQ={setNameQualified}
                nameQ={nameQualified}
                valor={valueQualified}
                setDecimalUcContext={setDecimalUcContext}
                Disabled={onFmm === true}
              />
            </div>
          )}

          {(authUser.admin === 1 || authUser.is_commerce === 1) &&
            pathname !== "/FormEntry" && (
            <InputSelect
              tag={"Usuario calificado"}
              data={selectValor(userQ)}
              set={setUserQuialified}
              value={userQualified}
              size={45}
            />
          )}

          <div
            style={{
              width: "45%"
            }}
          >
            <LabeFormlStyled htmlFor="status_unit_measurement">
              Activado
            </LabeFormlStyled>
            <div>
              <Switch
                name="status_unit_measurement"
                id="status_unit_measurement"
                size="lg"
                value={values.status_unit_measurement}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            marginTop: "1rem"
          }}
        >
          <LabelGroup
            name={"unit_measurement"}
            onChange={handleInputChange}
            value={unit_measurement}
            tag={"Nombre Unidad de Medida"}
            size={45}
            desicion={false}
            maxLength={MaxLength.productReference.unitMeasurement.unit_measurement}
          />

          <LabelGroup
            name={"code_unit_measurement"}
            onChange={handleInputChange}
            value={code_unit_measurement}
            tag={"Código"}
            size={45}
            desicion={false}
            maxLength={MaxLength.productReference.unitMeasurement.code_unit_measurement}
          />
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
          className="mt-3"
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormUnitMeasurement;
