import React, { useContext, useEffect } from 'react'
import { Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react'
import { IconGeneric } from 'styled/Icons.styled'
import { HiDotsHorizontal } from 'react-icons/hi'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { ButtonMenu } from './ButtonMenu'
import { MdContentCopy, MdRemoveRedEye } from 'react-icons/md'
import { AiOutlineEdit, AiOutlineFileDone } from 'react-icons/ai'
import { GiCardDiscard } from 'react-icons/gi'
import GlobalContext from 'context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import environment from 'constants/apiConst'
import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { ReasonForReturnComponent } from 'components/DispatchCorrections/ReasonForReturnComponent'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import ModalCopy from 'components/modal/ModalCopy'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import { FaUnlock } from 'react-icons/fa'
import { AuthContext } from 'context/AuthContext'

const MenuDispatchCorrecton = ({ data, StatusPropety }) => {
  const QueryString = window.location.pathname
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate()
  const { authUser } = useContext(AuthContext)
  const {
    isOpen: isOpenReturnReason,
    onOpen: onOpenReturnReason,
    onClose: onCloseReturnReason
  } = useDisclosure()

  const {
    isOpen: isOpenWarning,
    onOpen: onOpenWarning,
    onClose: onCloseWarning
  } = useDisclosure()

  const {
    isOpen: isOpenCopy,
    onOpen: onOpenCopy,
    onClose: onCloseCopy
  } = useDisclosure()

  const {
    associateData,
    setTargetData,
    setActionToBeCarriedOut,
    setUpdate,
    requestData
  } = useContext(GlobalContext)
  const Status = associateData?.[StatusPropety] ?? data?.[StatusPropety]
  const token = useSelector(state => state.auth.dataUser.token)
  const allowedRoutesActionsForm = ['/DispathcCorrectionShow']
  const router_current = allowedRoutesActionsForm.includes(QueryString)
  const HandlerEdit = data => {
    setActionToBeCarriedOut('Edit')
    setTargetData(data)
  }

  const { mutate } = useMutation({
    mutationKey: ['ItemDispatchDelete'],
    mutationFn: requestData
  })

  const HandleDeleteItems = id => {
    mutate(
      {
        pageParam: null,
        resourceIdentifier: id,
        data: null,
        Endpoint: environment.DELETE_CORRECTED_DISPATCH_ITEMS,
        PropertyBody: 'get'
      },
      {
        onSuccess: data => {
          if (data?.data?.status?.code === 400) { return openAlert(data?.data?.status?.message, 'error') }

          if (data?.data?.status?.code === 200) {
            setUpdate('updatedItems')
            return openAlert('Se ha eliminado el ítem.', 'error')
          }
        }
      }
    )
  }

  const HandleCopyItems = id => {
    mutate(
      {
        pageParam: null,
        resourceIdentifier: id,
        data: null,
        Endpoint: environment.COPY_CORRECTED_DISPATCH_ITEMS,
        PropertyBody: 'get'
      },
      {
        onSuccess: data => {
          if (data?.data?.status?.code === 400) { return openAlert(data?.data?.status?.message, 'error') }

          if (data?.data?.status?.code === 200) {
            setUpdate('updatedItems')
            return openAlert('Se ha copiado el ítem.', 'success')
          }
        }
      }
    )
  }

  const changeStatus = async (id, state) => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'CORREGIDO_DESPACHO')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000)
      const formData = new FormData()
      formData.append('id', id)
      formData.append('status_dispatchPassCorrected', state)
      mutate(
        {
          data: formData,
          Endpoint: environment.CHANGE_DISPATCH_STATUS_CORRECTED,
          PropertyBody: 'sendJSONContentPOST'
        },
        {
          onSuccess: data => {
            if (data?.data?.status?.code === 400) { return openAlert(data?.data?.status?.message, 'error') }

            if (data?.data?.status?.code === 200) {
              setUpdate('refetchViewMain')
              return openAlert(data?.data?.status?.message, 'success')
            }
          }
        }
      )
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const ReturnCorrent = () => {
    setUpdate('refetchViewMain')
  }

  const handleEdit = async () => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'CORREGIDO_DESPACHO')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      return navigate(`/DispathcCorrectionShow?Dispatch=${data.id}&Action=Show`)
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const unlockForm = async () => {
    const body = new FormData()
    body.append('form_id', data?.id)
    body.append('type_of', 'CORREGIDO_DESPACHO')
    body.append('type', 3)
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
  }

  const checkIfItIsBlocked = async () => {
    const body = new FormData();
    body.append("form_id", data?.id);
    body.append("type_of", "CORREGIDO_DESPACHO");
    body.append("type", 4);
    const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
    if (res?.data?.status?.code === 200) {
      onOpenReturnReason()
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, "error");
    }
  }

  return (
    <Menu>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >
        <ReasonForReturnComponent
          onClose={onCloseReturnReason}
          id={data?.id}
          PropertyReasonForReturn={'reason_for_return_corrected'}
          PropertyStatus={'status_dispatchPassCorrected'}
          serviceReturn={environment.CHANGE_DISPATCH_STATUS_CORRECTED}
          callBack={ReturnCorrent}
        />
      </ModalGeneric>

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Eliminar item`}
        titleBody={`¿Desea eliminar el item Nº ${data?.item_corrected}?`}
        generar={() => HandleDeleteItems(data?.id)}
      />

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseCopy}
        title={`Desea copiar este item`}
        onCopy={() => HandleCopyItems(data?.id)}
      />

      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>
      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        {QueryString === '/corrected-pass-exit' &&
          (Status === 'BORRADOR' || Status === 'DEVUELTO') && (
            <>
              <ButtonMenu
                icon={AiOutlineEdit}
                colorIcon='#2c91e4'
                onClick={handleEdit}
                name='Editar'
              />
              <HrDividerNoMargin />

              <ButtonMenu
                onClick={() => changeStatus(data.id, 'PRESENTADO')}
                icon={AiOutlineFileDone}
                colorIcon='#03C39A'
                name='Presentar'
              />
            </>
          )}

        {QueryString === '/corrected-pass-exit' && Status === 'AUTORIZADO' && (
          <>
            <ButtonMenu
              onClick={() => onOpenReturnReason()}
              icon={GiCardDiscard}
              colorIcon='#d92525'
              name='Devolver'
            />
          </>
        )}

        {QueryString === '/corrected-pass-exit' && Status === 'PRESENTADO' && (
          <>
            <ButtonMenu
              icon={MdRemoveRedEye}
              colorIcon='#1884b2'
              onClick={() =>
                navigate(
                  `/DispathcCorrectionShow?Dispatch=${data.id}&Action=Show`
                )
              }
              name='Revisar'
            />
            <HrDividerNoMargin />

            <ButtonMenu
              onClick={checkIfItIsBlocked}
              icon={GiCardDiscard}
              colorIcon='#d92525'
              name='Devolver'
            />

            {(authUser.is_qualified_user !== 1) &&
              <>
                <HrDividerNoMargin />

                <ButtonMenu
                  icon={FaUnlock}
                  colorIcon='orange'
                  onClick={() => {
                    unlockForm()
                    openAlert('Se ha desbloqueado con exito', 'success')
                  }}
                  name='Desbloquear'
                />

              </>
            }
          </>
        )}

        {router_current && (Status === 'BORRADOR' || Status === 'DEVUELTO') && (
          <>
            <ButtonMenu
              onClick={onOpenCopy}
              icon={MdContentCopy}
              colorIcon='#F9A621'
              name='Copiar'
            />
            <HrDividerNoMargin />
            <ButtonMenu
              onClick={onOpenWarning}
              icon={GiCardDiscard}
              colorIcon='#d92525'
              name='Eliminar'
            />
            <HrDividerNoMargin />
            <ButtonMenu
              icon={AiOutlineEdit}
              colorIcon='#2c91e4'
              onClick={() => HandlerEdit(data)}
              name='Editar'
            />
          </>
        )}
      </MenuList>
    </Menu>
  )
}

export default MenuDispatchCorrecton
