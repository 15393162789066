import styled from "styled-components/macro";

export const TitleItems = styled.h1`
  font-size: 1.2em;
  font-family: "Roboto";
  margin-bottom: 2%;
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const StatusForm = styled.h1`
  font-size: 1.1em;
  font-family: "Roboto";
  margin-bottom: 2%;
  margin-left: 10px;
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const TextItem = styled.span`
  font-size: 1.1em;
  font-family: "Roboto";
  text-align: center;
  /* margin-bottom: 2%; */

  color: ${(props) => (props.color ? props.color : "black")};
`;
