import React from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import { OptionsBorradorForm } from "./OptionsForm/OptionsBorradorForm";
import { OptionsPresentadoForm } from "./OptionsForm/OptionsPresentadoForm";

const MenuFormCorrect = ({ data }) => {
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>
      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {data.status_entryFormCorrected === "BORRADOR" ||
        data.status_outputFormCorrected === "BORRADOR" ||
        data.status_outputFormCorrected === "DEVUELTO" ||
        data.status_entryFormCorrected === "DEVUELTO"
          ? (
            <OptionsBorradorForm data={data} />
          )
          : data.status_entryFormCorrected === "PRESENTADO" ||
          data.status_outputFormCorrected === "PRESENTADO"
            ? (
              <OptionsPresentadoForm data={data} />
            )
            : (
              ""
            )}
      </MenuList>
    </Menu>
  );
};

export default MenuFormCorrect;
