import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { SimpleGrid, Tooltip } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

export const RestOfTheWorld = ({
  values,
  formOperation = 0,
  handleInputChange,
  colorNotation = () => {},
  reviewData = {},
  title,
  code
}) => {
  const { commercial_invoice, value_duta } = values;
  const { dataFormCurrent } = useContext(FormCrudCRUDContext);

  const {
    opcional,
    getAdminCustom,
    adminCustom,
    setCustomsadministration,
    customsadministration
  } = useContext(FormCrudCRUDContext);
  const location = useLocation(); // navigate

  useEffect(() => {
    getAdminCustom();
  }, []);

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Tooltip
        hasArrow
        label={reviewData?.commercial_invoice?.comment}
        bg={colorNotation("commercial_invoice")}
        color="white"
      >
        <LabelGroup
          name={"commercial_invoice"}
          required={formOperation === 1}
          value={commercial_invoice}
          onChange={(e) => handleRegex(e, "all", Regex?.all)}
          error={colorNotation("commercial_invoice")}
          data-input-tag={"Factura Comercial"}
          data-item-id={"Header"}
          data-old-Value={dataFormCurrent?.commercial_invoice}
          tag={"Factura Comercial"}
          size={100}
          maxLength={MaxLength.formExit.formExitNumber.commercial_invoice}
        />
      </Tooltip>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        {opcional && (
          <LabelGroup
            name={"value_duta"}
            value={value_duta}
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            tag={opcional}
            size={100}
            desicion={false}
            maxLength={MaxLength.formExit.formExitNumber.value_duta}
          />
        )}
      </SimpleGrid>
      {code >= 201 && code <= 299 && opcional && (
        <Tooltip
          hasArrow
          label={reviewData?.customs_administration?.comment}
          bg={colorNotation("customs_administration")}
          color="white"
        >
          <div>
            <InputSelect
              tag={"Administración de Aduanas"}
              error={colorNotation("customs_administration")}
              data-input-tag={"Administración de Aduanas"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.customs_administration}
              data={adminCustom}
              set={setCustomsadministration}
              value={customsadministration}
              name={`customs_administration`}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};
