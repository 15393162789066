import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { AiOutlineFileSearch } from "react-icons/ai";

import Tablecheck from "./Tables/Tablecheck";
import InputSelect from "components/InputSelect/InputSelect";

export const SearchControlAnalysis = ({
  ElementFilter,
  dataFilter,
  setCriteria,
  CriteriaId,
  setriskLevel,
  setSector,
  sectorId,
  riskLevel,
  setQualificationId,
  QualificationId,
  setFreeZoneId,
  FreeZoneId,
  setdate,
  date,
  FindBack
}) => {
  const HandleDate = (x) => {
    const { value, name } = x.target;
    const fechaActual = new Date(value);
    const dia = fechaActual.getDate().toString().padStart(2, "0");
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
    const anio = fechaActual.getFullYear().toString();

    const fechaFormateada = `${dia}-${mes}-${anio}`;

    setdate({ ...date, [name]: fechaFormateada });
  };

  const data = [
    {
      value: "ZFBAQ",
      label: "Zona Franca de Barranquilla"
    },
    {
      value: "ZFPE",
      label: "Zona franca Permanente especial"
    },
    {
      value: "ZOFIA",
      label: "Zofia"
    }
  ];

  return (
    <Container
      borderRadius={"5px"}
      centerContent
      maxW={"6xl"}
      p="20px"
      gap={3}
      shadow={
        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
      }
    >
      {/* <InputGroup maxW={'2xl'}>
        <InputLeftElement
          pointerEvents='none'
          children={<BiSearchAlt2 color='#006EB8' size={'26px'} />}
        />
        <Input
          type='text'
          borderRadius={'20px'}
          border={'2px'}
          borderColor={'#9097BE'}
          background={' #F6F9FF'}
          _placeholder={{ color: 'black' }}
          placeholder='Busqueda avanzada'
          color={'black'}
        />
      </InputGroup> */}
      <Flex maxW={"2xl"} gap={4} pt={"10px"}>
        <FormControl w="35%">
          <FormLabel>Fecha inicio</FormLabel>
          <Input
            placeholder="Select Date and Time"
            type="datetime-local"
            name={"start_date"}
            onChange={HandleDate}
          />
        </FormControl>
        <FormControl w="30%">
          <FormLabel>Fecha Final</FormLabel>
          <Input
            placeholder="Select Date and Time"
            type="datetime-local"
            name={"end_date"}
            onChange={HandleDate}
          />
        </FormControl>

        <FormControl w="35%">
          <FormLabel>Zona Franca</FormLabel>
          <InputSelect
            // tag={"Zonas"}
            labelDisable={true}
            data={data}
            value={FreeZoneId}
            set={setFreeZoneId}
          />
        </FormControl>
      </Flex>

      {/* inicio table */}
      <Tablecheck
        columns={ElementFilter}
        setCriteria={setCriteria}
        CriteriaId={CriteriaId}
        dataSource={dataFilter}
        setriskLevel={setriskLevel}
        riskLevel={riskLevel}
        setQualificationId={setQualificationId}
        QualificationId={QualificationId}
        setFreeZoneId={setFreeZoneId}
        FreeZoneId={FreeZoneId}
        setSector={setSector}
        sectorId={sectorId}
      />
      {/* fin table */}

      <InputGroup maxW={"2xl"} pt={"10px"}>
        <Button
          onClick={FindBack}
          w="100%"
          leftIcon={<AiOutlineFileSearch color="white" />}
          colorScheme="blue"
          variant="solid"
        >
          Buscar
        </Button>
      </InputGroup>
      {/*    <InputGroup maxW={"2xl"} pt={"10px"}>
        <Text as="u" fontSize={"16px"} color="blue.500">
          Definiciones de criterios
        </Text>
      </InputGroup> */}
    </Container>
  );
};
