import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuList,
  Text
  , MenuItem
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { HeaderIcon } from "../Header.styled";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineComment } from "react-icons/ai";
import {
  BiArrowToRight,
  BiArrowFromRight,
  BiArrowFromBottom
} from "react-icons/bi";
import { MdNotifications, MdSettings } from "react-icons/md";
import "./Styled.css";

import { NavLink } from "react-router-dom";
import { RadioBtnRedonder } from "components/ComponentRadioButton/RadioBtnRedonder";

import { IconGeneric, IconImport, IconMenuRUD } from "styled/Icons.styled";
import { Title } from "styled/Title";

import styled from "styled-components/macro";

import { HrDividerNoMargin } from "styled/Line.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import { BsCheck2All, BsDownload } from "react-icons/bs";
import environment from "constants/apiConst";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

export const Notification = ({
  isOpen,
  onClose,
  notif,
  update,
  readAll,
  onOpenConfi
}) => {
  const routeEnty = "/FormShow?FormEntry=";
  const routeEntyCorrect = "/FormCorrection?ShowEntry=";
  const routeOutput = "/FormShowExit?ExitNumber=";
  const routeOutputCorrect = "/FormCorrectionOutput?ShowOutput=";
  const optionsBalance = ["Todas", "No leídas"];
  const [All, setAll] = useState("Todas");
  const [CardNotif, setCardNotif] = useState(notif);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (All === "Todas") {
      setCardNotif(notif);
    } else if (All === "No leídas") {
      const noRead = notif.filter(({ is_read }) => is_read === 0);

      setCardNotif(noRead);
    }
  }, [All, notif]);

  const updateGet = (id, read) => {
    if (read === 0) {
      update(id);
    }
    onClose();
  };

  const getIconForNotif = (flag) => {
    if (flag === "export_file" || flag === "output_form_srt") return BsDownload;

    // ingreso
    if (
      flag === "login" ||
      flag === "entry_form" ||
      flag === "entry_form_created" ||
      flag === "entry_form_updated" ||
      flag === "entry_form_changed_status" ||
      flag === "entry_form_corrected_create" ||
      flag === "entry_form_corrected_update" ||
      flag === "entry_form_corrected_changed_status" ||
      flag === "document_approved_entry" ||
      flag === "notification_expire_form_entry" ||
      flag === "notification_expire_form_entry_30"
    ) {
      return BiArrowToRight;
      // salida
    } else if (
      flag === "output_form" ||
      flag === "output_form_update" ||
      flag === "output_form_created" ||
      flag === "output_form_changed_status" ||
      flag === "output_form_changed_status_returned" ||
      flag === "output_form_corrected" ||
      flag === "output_form_corrected_create" ||
      flag === "output_form_corrected_update" ||
      flag === "output_form_corrected_changed_status" ||
      flag === "document_approved_output" ||
      flag === "notification_expire_form_output" ||
      flag === "notification_expire_form_output_60" ||
      flag === "output_form_srt"
    ) {
      return BiArrowFromRight;
    } else if (
      flag === "entry_form_comment" ||
      flag === "output_form_comment" ||
      flag === "entry_form_comment_corrected" ||
      flag === "output_form_corrected_comment"
      // ingreso corregido
    ) {
      return AiOutlineComment;
    } else {
      return MdNotifications;
    }
  };

  const getRouteForNotif = (flag, id) => {
    // ingreso
    if (
      flag === "login" ||
      flag === "entry_form" ||
      flag === "entry_form_comment" ||
      flag === "entry_form_created" ||
      flag === "entry_form_updated" ||
      flag === "entry_form_changed_status" ||
      flag === "document_approved_entry" ||
      flag === "notification_expire_form_entry" ||
      flag === "notification_expire_form_entry_30" ||
      flag === "document_finalized_entry" || 
      flag === "transfer_notification"
    ) {
      return routeEnty + id;
      // salida
    }
    if (
      flag === "output_form" ||
      flag === "output_form_update" ||
      flag === "output_form_created" ||
      flag === "output_form_changed_status" ||
      flag === "output_form_changed_status_returned" ||
      flag === "output_form_comment" ||
      flag === "document_approved_output" ||
      flag === "notification_expire_form_output" ||
      flag === "notification_expire_form_output_60" ||
      flag === "document_finalized_output" ||
      flag === "output_form_srt"
    ) {
      return routeOutput + id;
      // ingreso corregido
    }
    if (
      flag === "entry_form_corrected_create" ||
      flag === "entry_form_corrected_update" ||
      flag === "entry_form_corrected_changed_status" ||
      flag === "entry_form_comment_corrected"
    ) {
      return routeEntyCorrect + id;
      // salida corregido
    }
    if (
      flag === "output_form_corrected" ||
      flag === "output_form_corrected_create" ||
      flag === "output_form_corrected_update" ||
      flag === "output_form_corrected_changed_status" ||
      flag === "output_form_corrected_comment" ||
      flag === "output_form_srt"
    ) {
      return routeOutputCorrect + id;
    }
    return "/";
  };

  const handleDownload = async (doc_id, id, is_read) => {
    updateGet(id, is_read);
    const res = await __Post(environment.GET_JOB_STATUS, token, {
      job_id: doc_id
    });
    const data = res?.data?.status;
    if (data?.code === 200) {
      data?.data?.url && window.open(data?.data?.url, "_blank");
    }
  };

  const handleNavigation = (flag, id, doc_id, is_read, title) => {
    if (flag === "export_file") {
      return <spam onClick={() => handleDownload(doc_id, id, is_read)}>{title}</spam>;
    }
    return (
      <NavLink
        to={getRouteForNotif(flag, doc_id)}
        className=" exampleA"
        onClick={() => updateGet(id, is_read)}
      >
        {title}
      </NavLink>
    );
  };

  return (
    <>
      <Drawer size="xs" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}

          <DrawerHeader className="align-items-center">
            <div className="row">
              <div className="col-8">
                <div className="d-flex">
                  <HeaderIcon as={MdNotifications} color="#1f7ac3" />
                  <h1 className="titleNotif mt-1">Notificaciones</h1>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex">
                  <HeaderIcon
                    className="ms-5 float-end"
                    as={IoIosArrowForward}
                    onClick={onClose}
                    wd="15px"
                    color="#000"
                  />
                </div>
              </div>
            </div>
          </DrawerHeader>

          <DrawerBody>
            <div className="mb-4">
              <RadioBtnRedonder
                options={optionsBalance}
                setOnly={setAll}
                state={All}
              />

              <div className="d-flex float-end">
                <Menu>
                  <MenuButton>
                    <IconGeneric as={HiDotsHorizontal} />
                  </MenuButton>
                  <MenuList
                    borderRadius="none"
                    py={0}
                    style={{ boxSizing: "border-box" }}
                  >
                    <MenuItemStyled onClick={() => onOpenConfi()} py={3}>
                      <IconMenuRUD as={MdSettings} />
                      Configuración de notificaciones
                    </MenuItemStyled>
                    {CardNotif && CardNotif.length !== 0 && (
                      <>
                        <HrDividerNoMargin />
                        <MenuItemStyled onClick={() => readAll()} py={3}>
                          <IconMenuRUD as={BsCheck2All} />
                          Marcar todas como leídas
                        </MenuItemStyled>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </div>
            </div>
            {CardNotif &&
              CardNotif.map(({ is_read, notification_push, id }) => {
                const { title, description, flag, doc_id, date_time } =
                  notification_push;
                return (
                  <>
                    <div
                      id={is_read === 0 && "noViewUser"}
                      className="mt-2 p-1"
                    >
                      {/* IoIosWarning */}
                      <div className="row align-items-center">
                        <div className="col-2">
                          <HeaderIcon
                            as={getIconForNotif(flag)}
                            color={is_read !== 0 ? "#BABABA" : "#1f7ac3"}
                            onClick={
                              flag === "export_file"
                                ? () => {handleDownload(doc_id, id, is_read);}
                                : null
                                
                            }
                          />
                        </div>
                        <div className="col-10">
                          <h1 className="dateNotification float-end">
                            {date_time}
                          </h1>
                          <h1 className="titleNotification mt-2">
                            {handleNavigation(flag, id, doc_id, is_read, title)}
                          </h1>
                          {/* {title} */}
                          <h1 className="descriptionNotification">
                            {description}
                          </h1>
                          {is_read !== 0 && (
                            <Divider className="my-2" color="#BABABA" />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

            {CardNotif && CardNotif.length === 0 && (
              <>
                <div
                  id="redonder"
                  className="d-flex justify-content-center centerIcon "
                >
                  <IconImport color="#fff" as={MdNotifications} />
                </div>
                <Title className="text-center">Estás al día.</Title>
                <h1 className="text-center">
                  No tienes ninguna notificación no leída aquí.
                </h1>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
