import React, { useContext, useEffect } from 'react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconGeneric, IconMenuRUD } from 'styled/Icons.styled'
import { MdDelete } from 'react-icons/md'
import { HiDotsHorizontal } from 'react-icons/hi'
import { HrDividerNoMargin } from 'styled/Line.styled'
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex'
import { RiEdit2Fill } from 'react-icons/ri'
// import { MdOutlinePostAdd } from "react-icons/md";
import environment from 'constants/apiConst'
import Swal from 'sweetalert2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGlobalContext } from 'context/GlobalContext'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { queryClient } from 'api/queryClient'
import queryEnv from 'constants/queryConst'
const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`

const MenuOrdenProduccion = ({ data, pathname }) => {
  const {
    onOpenAddOrder,
    setDataToEditOrder,
    setIdOrder,
    onOpenAddProduct,
    setDataToEditSingleProduct,
    setDataToEditProduct,
    setRequestDelete,
    onOpenAddShow,
    setshowCertificatedData
  } = useContext(IntegracionBoxCrudContex)

  const [openAlert] = AlertErrorAlert();

  const { requestData } = useGlobalContext()
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: requestData,
    retry: 3,
    onSuccess: data => {
      if (data?.data?.status?.code === 400) {
        return openAlert(data?.data?.status?.message, 'error')
      }

      if (data?.data?.status?.code === 200) {
        queryClient.invalidateQueries({ queryKey: [queryEnv?.PRODUCTS_CI] })
        queryClient.invalidateQueries({ queryKey: [queryEnv?.OTHER_COST_CI] })
        openAlert(data?.data?.status?.message, 'success')
      }
    }
  })

  const handleDelete = () => {
    Swal.fire({
      title: `¿Desea eliminar ${data?.code_production_order ? "la orden de producción" : "el producto elaborado"} ${data?.code_product}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text"
      },
      text: `Le recordamos limpiar la información agregada de materias primas e insumos, otros gastos y costos, si es el caso.`,
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        mutate({
          data: {
            id: data?.id
          },
          Endpoint: environment.DELETE_ORDER_PRODUCTION,
          PropertyBody: 'sendJSONContentPOST',
        })
      }
    });
  }

  const handleEditOrder = () => {
    /*  console.log("DATAA: ", data) */
    if (data?.code_production_order) {
      console.log("BBBBBBBBBBBBBBBBBBBBBBBBB")
      setDataToEditOrder(true)
      setIdOrder(data)
      return onOpenAddOrder()
    }
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    setDataToEditProduct(true)
    setIdOrder(data)
    setDataToEditSingleProduct(data)
    onOpenAddProduct()
  }

  const handleShow = () => {
    console.log("data: ", data)
    setshowCertificatedData(data)
    onOpenAddShow()
  }

  useEffect(() => {
    setRequestDelete(environment.DELETE_ORDER_PRODUCTION)
  }, [setRequestDelete])

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>

      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        <>
          <HrDividerNoMargin />

          <MenuItemStyled
            py={3}
            onClick={
              pathname === '/ShowIntegrationBox' ? handleShow : handleEditOrder
            }
          >
            <IconMenuRUD as={RiEdit2Fill} />
            {pathname === '/CheckIntegrationBox' ||
              pathname === '/ShowIntegrationBox'
              ? 'Ver'
              : 'Editar'}
          </MenuItemStyled>
          {pathname === '/CheckIntegrationBox' ||
            pathname === '/ShowIntegrationBox'
            ? (
              <></>
            )
            : (
              <>
                <MenuItemStyled py={3} onClick={handleDelete}>
                  <IconMenuRUD as={MdDelete} />
                  Eliminar
                </MenuItemStyled>
                <HrDividerNoMargin />
              </>
            )}
        </>
      </MenuList>
    </Menu>
  )
}

export default MenuOrdenProduccion
