import React, { useEffect, useState, useCallback } from "react";
import { Button, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { Cancelar } from "pages/RefProductInter/Stilos";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import environment from "constants/apiConst";
import { useNavigate } from "react-router-dom";
import { __EditCreateDispatch } from "request/DespachoAgrupado/__DispatchAg";
import { useDispatch, useSelector } from "react-redux";
import { InputSelectZindex } from "components/InputSelect/InputSelect";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import { isValid } from "utils/type-check-utils";

export const AgrupForm = ({ onClose }) => {
  const navigate = useNavigate();
  const [valor, setValor] = useState(null);
  const [userCa, setUserCa] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const dispacthRedux = useDispatch();
  const { balance } = useSelector((state) => state.status);

  const userC = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified,
      }));

      setUserCa(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    userC();
  }, [userC]);

  const closeInfo = () => {
    onClose();
    navigate("/", {
      replace: true,
    });
  };

  const initForm = () => {
    if (!isValid(valor?.value)) {
      openAlert("Seleccione una empresa", "error");
      return;
    }

    if (isValid(valor?.value)) {
      navigate(
        `/formInt?qualifiedUser=${valor.label}&qualifiedUserID=${
          valor?.value
        }&typeO=${"Dispatch"}`
      );
      onClose();

      /*       const formData = new FormData();
      formData.append("qualifiedUser_id", valor.value);
      const createPass = async () => {
        try {
          const resultado = await __EditCreateDispatch(
            environment.CREATE_DISPATCH,
            token,
            formData
          );
          if (resultado.data.status.code === 200) {
            const numberFormIn = resultado.data.status.data.dispatch_pass;
            const balanceDespacho = resultado.data.status.data.balance;
            localStorage.setItem(
              "ValueBalance",
              JSON.stringify(balanceDespacho)
            );
            dispacthRedux(setDataStatusBalance(!balance));

            navigate(
              `/formInt?qualifiedUser=${
                numberFormIn.name_qualified
              }&numberForm=${numberFormIn.passForm_number}&id=${
                numberFormIn.id
              }&status=${
                numberFormIn.status_entryPass ||
                numberFormIn.status_dispatchPass
              }&typeO=${"Dispatch"}`
            );
            onClose();
          }
        } catch (error) {
          console.log(error);
        }
      };
      createPass();
    } else {
      openAlert("Seleccione una empresa", "error"); */
    }
  };

  return (
    <>
      {/* <Divider mt={0} /> */}
      <Text fontSize="md" mb={4} style={{ fontWeight: 600 }}>
        Formulario de despacho agrupado
      </Text>
      <Divider my={2} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
        className="mb-5"
      >
        <div style={{ width: "100%" }}>
          <InputSelectZindex
            tag={"Usuario calificado"}
            data={userCa}
            set={setValor}
            value={valor}
          />
        </div>
      </div>

      <Flex className="my-3">
        <Cancelar onClick={closeInfo}>Cancelar</Cancelar>
        <Spacer />

        <Button
          onClick={initForm}
          size="md"
          bg="#03C39A"
          color="#fff"
          _hover={{ bg: "#07876b" }}
          width="40%"
          height="40px"
        >
          Formulario
        </Button>
      </Flex>
    </>
  );
};
