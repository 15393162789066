import { Box } from '@chakra-ui/react'
import React from 'react'
import styled from "styled-components/macro";

export const Card = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
`;

export const Title = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #41495a;
  margin-top: 15px;
  margin-left: 8px;
`;

export const Element = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NumberForm = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #41495a;
  margin-top: 15px;
  margin-left: 12px;
  width: 100%;
  text-align: start;
`;

export const Pass = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #41495a;
  margin-top: 15px;
  margin-right: 12px;
  width: 100%;
  text-align: end;
`;

const FormsWith = ({ title, data }) => {
  return (
    <Box style={{ margin: '10px', height: '340px', width: '340px' }}>
      <Card>
        <Title>{title}</Title>
        <Element>
          <NumberForm>Formulario</NumberForm>
          <Pass>Pases</Pass>
        </Element>
        {data && data.map((item, index) => (
          <Element key={index}>
            <NumberForm>{item?.code}</NumberForm>
            <Pass>{item?.total_pass}</Pass>
          </Element>
        ))}
      </Card>
    </Box>
  )
}

export default FormsWith
