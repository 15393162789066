import React, { useContext, useEffect, useState } from "react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import InputSelect from "components/InputSelect/InputSelect";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { SimpleGrid, Tooltip } from "@chakra-ui/react";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Regex } from "utils/Regex";

export const UserToUser = ({
  values,
  formOperation = 0,
  handleInputChange,
  valueQualifiedToUser,
  setValueQuialifiedToUser,
  colorNotation = () => {},
  reviewData = {}
}) => {
  const { commercial_invoice } = values;
  const token = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation(); // navigate
  const { FormEntryNumber = "", FormExitNumber = "" } = queryString.parse(
    location.search
  );
  const { dataFormCurrent } = useContext(FormCrudCRUDContext);

  //  Data Selet User Qualified from user to user
  const [userQualified, setUserQuialified] = useState([]);

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setUserQuialified(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserQualified();
  }, [token]);

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Tooltip
        hasArrow
        label={reviewData?.dcl_customs_transit?.comment}
        bg={colorNotation("dcl_customs_transit")}
        color="white"
      >
        <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
          <InputSelect
            tag={"Nit"}
            Name={"nit"}
            data={userQualified}
            set={setValueQuialifiedToUser}
            value={valueQualifiedToUser}
            name={"dcl_customs_transit"}
            error={colorNotation("dcl_customs_transit")}
            data-input-tag={"Nit"}
            data-item-id={"Header"}
            data-old-Value={dataFormCurrent?.dcl_customs_transit}
          />
        </SimpleGrid>
      </Tooltip>
      <Tooltip
        hasArrow
        label={reviewData?.dcl_customs_transit?.comment}
        bg={colorNotation("dcl_customs_transit")}
        color="white"
      >
        <LabelGroup
          name={"commercial_invoice"}
          value={commercial_invoice}
          onChange={(e) => handleRegex(e, "all", Regex?.all)}
          tag={"Factura Comercial"}
          required={formOperation === 1}
          size={"100"}
          error={colorNotation("commercial_invoice")}
          data-input-tag={"Factura Comercial"}
          data-item-id={"Header"}
          data-old-Value={dataFormCurrent?.commercial_invoice}
          maxLength={MaxLength.formExit.formExitNumber.commercial_invoice}
        />
      </Tooltip>
    </>
  );
};
