import { Table, TableContainer, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import { useEffect, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useSelector } from "react-redux";
import { __Post } from "request/Petitions/__Post";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @param {string} userProvidedEndpoint - The user-provided endpoint.
 * @param {object} userProvidedBody - The user-provided body.
 * @return {object} An object containing the `onSubmit` function and the `ErrorTable` component.
 */
export const useBulkItemAggregation = ({ userProvidedEndpoint, userProvidedBody, isOpen, onClose, file, nameFile, update, setUpdate, key, invalidateKey = [] }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [importErrors, setImportErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [firstRender, setFirstRender] = useState(true)
  const [openAlert] = AlertErrorAlert();
  const [onLoading, setOnLoading] = useState(false)

  const queryClient = useQueryClient();

  /*   useEffect(() => {
      if (!key) return;
      const storedErrors = localStorage.getItem(`importErrors-${key}`);
      const storedErrorMessage = localStorage.getItem(`errorMessage-${key}`);

      if (storedErrors) {
        setImportErrors(JSON.parse(storedErrors));
      }

      if (storedErrorMessage) {
        setErrorMessage(storedErrorMessage);
      }

    }, [key]); */

  const { mutate, isLoading } = useMutation({
    mutationFn: ([url, authToken, data]) => {
      return __Post(url, authToken, data);
    },
    onSuccess: (res) => {
      if (res?.data?.status?.code === 202) {
        openAlert(res?.data?.status?.message, 'info')
        setErrorMessage(res?.data?.status?.message);
        getJobId(res?.data?.status?.data?.job_id)
      }
    },
    onError: (err) => {
      console.log(err, "error");
    }
  });

  const getJobId = async (id) => {
    setOnLoading(true)
    let requestCounter = 0
    const interval = setInterval(async () => {
      const response = await __Post(environment.GET_JOB_STATUS, token, { job_id: id });

      if (response?.data?.status?.data?.status === 1 || response?.data?.status?.data?.status === 0) {
        requestCounter++
        if (requestCounter === 25) {
          clearInterval(interval);
          setOnLoading(false);
          setErrorMessage('')
          openAlert('El archivo aún está en proceso. Por favor, evita volver a subir el archivo y revisa el estado del proceso más tarde', 'error')
          return
        }
      }

      if (response?.data?.status?.data?.status === 4) {
        clearInterval(interval);
        setOnLoading(false);
        setErrorMessage('');
        openAlert(response?.data?.status?.data?.messages, 'error')

        const message = response?.data?.status?.data?.messages
        setErrorMessage(`${nameFile} - ${message}`);
        // localStorage.setItem(`errorMessage-${key}`, `${nameFile} - ${message}`);

        const data = [response?.data?.status?.data?.error]
        setImportErrors(data);
        // localStorage.setItem(`importErrors-${key}`, JSON.stringify(data));
      }

      if (response?.data?.status?.data?.status === 3) {
        clearInterval(interval);
        setOnLoading(false);
        setErrorMessage('');
        openAlert('El archivo contiene errores', 'error')

        const message = response?.data?.status?.data?.messages
        setErrorMessage(`${nameFile} - ${message}`);
        // localStorage.setItem(`errorMessage-${key}`, `${nameFile} - ${message}`);

        const data = response?.data?.status?.data?.error
        setImportErrors(data);
        // localStorage.setItem(`importErrors-${key}`, JSON.stringify(data));
      }

      if (response?.data?.status?.data?.status === 2) {
        clearInterval(interval);
        setOnLoading(false);
        setErrorMessage('');
        invalidateKey && queryClient.invalidateQueries({queryKey: invalidateKey});
        openAlert(response?.data?.status?.data?.messages, 'success')

        // localStorage.removeItem(`importErrors-${key}`);
        // localStorage.removeItem(`errorMessage-${key}`);
        setUpdate(!update)
        onClose()
      }
    }, 10000);
  }

  const bulkAddItems = (e) => {
    if (!isLoading && !onLoading) {
      if (file.length === 0) {
        openAlert("Debe seleccionar un archivo", "error");
        setErrorMessage("Debe seleccionar un archivo");
      } else {
        mutate([userProvidedEndpoint, token, userProvidedBody])
      }
    }
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    /* localStorage.removeItem(`importErrors-${key}`);
    localStorage.removeItem(`errorMessage-${key}`); */
    setImportErrors([]);
    setErrorMessage("");
  }, [file, isOpen]);

  const ErrorTable = () => {
    return (
      <>
        {/* <div style={{ display: 'block', paddingBottom: '45px' }}>
          <Tooltip placement="top" hasArrow label="Descargar plantilla vacía">
            <ButtonHeaderModule
              className="me-1 d-flex float-end"
              bgColor="#03C39A"
              onClick={() => console.log('click')}
            >
              <IconGeneric width="16px" color="#fff" as={AiOutlineCloudDownload} />
            </ButtonHeaderModule>
          </Tooltip>
        </div> */}
        <div>
          <Title style={{ margin: '10px' }}>{errorMessage}</Title>
          {onLoading && <SpinnerComponent />}
          {isLoading
            ? <SpinnerComponent />
            : <TableContainer style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <Table variant='striped' colorScheme='gray'>
                <Tbody>
                  {importErrors && importErrors?.map((error, index) => (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td key={index}>{error}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          }
        </div>
      </>
    )
  }

  return { bulkAddItems, ErrorTable }
}
