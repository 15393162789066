import React from "react";
import * as PropTypes from "prop-types";
import { MemberDropdownChakra } from "./MemberDropdown";
import RemoveButtonGroup from "./RemoveButtonGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { Box } from "@chakra-ui/react";

const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods
}) => {
  return (
    <>
      <Box display={"flex"}>
        {members.map((m) => (
          <RemoveButtonGroup
            key={m.index || m.name}
            onRemoveClick={() => updateMethods.remove(m)}
          >
            <MemberDropdownChakra
              availableMembers={availableMembers}
              onClick={(updateWith) => updateMethods.update(m, updateWith)}
            >
              {m.shortTitle}
            </MemberDropdownChakra>
          </RemoveButtonGroup>
        ))}

        <MemberDropdownChakra
          onClick={(m) => updateMethods.add(m)}
          availableMembers={availableMembers}
          icon={<AiOutlinePlus />}
        >
          {addMemberName}
        </MemberDropdownChakra>
      </Box>
    </>
  );
};
MemberGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired
};
export default MemberGroup;
