import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { BsEyeFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { InputSelectNew } from "components/InputSelect/InputSelect";
import { IconGeneric } from "styled/Icons.styled";
import DianCRUDContext from "context/DianCrudContext";
import { __PostForm } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const TbodyProfile = ({ data, iope }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const navigate = useNavigate();

  const info = [
    { value: "Si", label: "Si" },
    { value: "No", label: "No" }
    // { value: 3, label: "No requiere inspección" },
  ];

  const { setDataFormItems, setUpdate, setLoading, update } =
    useContext(DianCRUDContext);

  const handleInfoForm = () => {
    if (data?.document_type && data?.document_type === "FORM.SALID") {
      navigate(`/FormShowExit?ExitNumber=${data?.form_id}`);
    } else {
      navigate(`/FormShow?FormEntry=${data?.form_id}`);
    }
  };

  const handleItems = () => {
    navigate(`/PerfilarItemsDocument?IdForm=${data?.form_id}`);
    setDataFormItems(data);
  };

  const handleChange = async (item) => {
    const dataUpdateDocument = new FormData();
    dataUpdateDocument.append("document_type", data?.document_type);
    dataUpdateDocument.append("form_code", data?.form_code);
    dataUpdateDocument.append("dial_type", item.label);
    setLoading(true);
    try {
      const res = await __PostForm(
        environment.DOCUMENTS_PROFILES_UPDATE,
        token,
        dataUpdateDocument
      );
      if (res?.data?.status?.code === 200) {
        setUpdate(!update);
      } else {
        openAlert(res.data.status.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const urlForm = {
    "FORM.SALID": `/FormShowExit?ExitNumber=${data?.form_id}`,
    "default": `/FormShow?FormEntry=${data?.form_id}`,
  }[data?.document_type || "default"];

  return (
    <>
      <Tr className={"verticalNone"}>
        <Td className="text-center">{data?.date}</Td>
        <Td className="text-center">{data?.document_type}</Td>

        <Td className="text-center">
          <NavLink
            to={urlForm}
            className="ms-2"
          >
            <span className="formNumber">{data?.form_code}</span>
          </NavLink>
        </Td>
        {/* <Td className="text-center" onClick={handleInfoForm}>
          <span className="formNumber">{data?.form_code}</span>
        </Td> */}
        <Td className="text-center">{data?.qualified_user_name}</Td>
        <Td className="text-center">{data?.operation_code}</Td>
        <Td className="text-center">{data?.num_transport_document}</Td>
        <Td className="text-center">{ }</Td>
        <Td className="text-center">{ }</Td>

        <Td className="text-center">{data?.quantity_pass_item}</Td>
        <Td className="text-center">
          <IconGeneric
            color={"#1F7AC3"}
            as={BsEyeFill}
            onClick={() => handleItems()}
          />
        </Td>

        <Td
          style={{
            width: "17%",
            paddingLeft: "13px"
          }}
        >
          {
            <div
              style={{
                width: "100%",
                marginBottom: "20px"
              }}
            >
              <InputSelectNew
                size={90}
                data={info}
                onChange={(item) => handleChange(item)}
              />
            </div>
          }
        </Td>
      </Tr>
    </>
  );
};

export default TbodyProfile;
