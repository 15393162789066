import React from 'react'
import { Card, Element, NumberForm, Title } from './FormsWith'
import { Box } from '@chakra-ui/react'

const TablePresentationTime = ({ dataFmmi, dataFmms, dataFci }) => {
  return (
    <Box style={{ margin: '10px', height: '340px', width: '340px' }}>
      <Card>
        <Title>Tiempo de presentados en tabla</Title>

        <NumberForm>FMMI</NumberForm>
        {dataFmmi.length !== 0 && dataFmmi.map((element) => {
          return (
            <Element style={{ margin: '8px' }} key={element.id}>
              <div>
                <p style={{ fontWeight: '400', color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.name_qualified}</p>
                <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.form_code}</p>
              </div>
              <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{`${element.hour.length === 1 ? '0' + element.hour : element.hour}:${element.minutes.length === 1 ? '0' + element.minutes : element.minutes}:${element.seconds.length === 1 ? '0' + element.seconds : element.seconds}`}</p>
            </Element>
          )
        })}

        <NumberForm>FMMS</NumberForm>
        {dataFmms.length !== 0 && dataFmms.map((element) => {
          return (
            <Element style={{ margin: '8px' }} key={element.id}>
              <div>
                <p style={{ fontWeight: '400', color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.name_qualified}</p>
                <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.form_code}</p>
              </div>
              <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{`${element.hour.length === 1 ? '0' + element.hour : element.hour}:${element.minutes.length === 1 ? '0' + element.minutes : element.minutes}:${element.seconds.length === 1 ? '0' + element.seconds : element.seconds}`}</p>
            </Element>
          )
        })}

        <NumberForm>FCI</NumberForm>
        {dataFci.length !== 0 && dataFci.map((element) => {
          return (
            <Element style={{ margin: '8px' }} key={element.id}>
              <div>
                <p style={{ fontWeight: '400', color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.name_qualified}</p>
                <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{element.form_code}</p>
              </div>
              <p style={{ color: '#41495a', fontFamily: 'Poppins', fontSize: '10px' }}>{`${element.hour.length === 1 ? '0' + element.hour : element.hour}:${element.minutes.length === 1 ? '0' + element.minutes : element.minutes}:${element.seconds.length === 1 ? '0' + element.seconds : element.seconds}`}</p>
            </Element>
          )
        })}

      </Card>
    </Box>

  )
}

export default TablePresentationTime
