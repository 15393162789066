import { Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import { ModalGeneric } from "components/modal/ModalGeneric";
import MonitoringContext from "context/MonitoringContext";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  __Activate_Or_Inactivate,
  __Get_FollowOrderStopTracking,
} from "request/Monitoring/__Activate_Or_Inactivate";
import { uploadFile } from "pages/Entry_and_exit_forms/View/FormsView";
import { isValid } from "utils/parseFloat";
import { useQueryClient } from "@tanstack/react-query";

export const FormStopTrackingForm = ({
  followOrder,
  idForm,
  codeForm,
  formType,
}) => {
  const [openAlert] = AlertErrorAlert();
  const [follow, setFollow] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [attachmentCancel, setAttachmentCancel] = useState([]);
  const [finalDescription, setFinalDescription] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const { onCloseStopTracking } = useContext(MonitoringContext);
  const submit = async () => {
    try {
      const formData = new FormData();
      formData.append("form_code", codeForm);
      formData.append("form_id", idForm);
      formData.append("form_type", formType);
      formData.append("follow_order", follow?.value);
      const res = await __Get_FollowOrderStopTracking(token, formData);
      setTrackingId(res?.data?.status?.data?.tracking_id);
      onOpen();
    } catch {
      console.log("error");
    } finally {
    }
  };

  const stopTracking = async () => {
    if (!finalDescription) {
      openAlert(
        "Para desactivar el seguimiento se requiere un comentario",
        "error"
      );
    } else {
      try {
        const formData = new FormData();
        formData.append("tracking_id", trackingId);
        if (!isValid(attachmentCancel)) {
          return openAlert(
            "Para desactivar el seguimiento del formulario se requiere un archivo",
            "error"
          );
        }
        formData.append("attachment", JSON.stringify(attachmentCancel));
        formData.append("final_description", finalDescription);
        const res = await __Activate_Or_Inactivate(token, formData);
        const message = res?.data?.status?.message;
        const code = res?.data?.status?.code;
        if (code === 200) {
          queryClient.invalidateQueries({ queryKey: ["fetchServerPage"] });
          onClose();
          openAlert(message, "success");
          onCloseStopTracking();
        } else if (code !== 200) {
          openAlert(message, "error");
          onClose();
        }
      } catch {
        console.log("error");
      }
    }
  };

  const handleAttachment = async (e) => {
    const dataAttachments = e.target.files[0];
    const vaporRes = await uploadFile(dataAttachments, token);

    setAttachmentCancel({
      file: { ...vaporRes },
      name: dataAttachments?.name,
      attachment_description: "",
      attachment_comment: "",
      attachment_date: "",
    });
  };

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Parar seguimiento"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "95%",
            justifyContent: "space-between",
            margin: "0 auto 2rem auto",
            padding: "10px 0",
          }}
        >
          <input
            type="file"
            name="attachment"
            accept=".png, .jpeg, .jpg, .pdf"
            style={{
              marginBottom: "15px",
            }}
            onChange={(e) => handleAttachment(e)}
          />
          <textarea
            onChange={(e) => setFinalDescription(e.target.value)}
            value={finalDescription}
            name="finalDescription"
            cols="50"
            rows="3"
            placeholder="Comentario requerido"
            style={{
              fontSize: "0.8rem",
              resize: "none",
              border: "1px solid #bababa",
              borderRadius: "5px",
              outline: "none",
              padding: "0 10px",
            }}
          ></textarea>
          <Button onClick={stopTracking} style={{ margin: "15px 0" }}>
            Enviar
          </Button>
        </div>
      </ModalGeneric>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "2rem",
        }}
      >
        <InputSelect
          size={45}
          name={"followOrder"}
          tag={"Gestora de seguimiento*"}
          data={followOrder}
          set={setFollow}
          value={follow}
        />
      </div>
      <Flex className="my-3">
        <ButtonStyled onClick={onCloseStopTracking} bgColor={"#bababa"}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={submit}>
          Detener
        </ButtonStyled>
      </Flex>
    </>
  );
};
