/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import Select from "react-select";
import FormCrudCRUDContext from "context/FormCrudContex";

export const InputUnit = ({
  data,
  tag,
  size,
  setUnidad,
  unidad,
  setPlaceU,
  placeU
}) => {
  const { dataToEdit } = useContext(ConfigurationCRUDContext);

  if (dataToEdit) {
    const unidadescrazyy = data.find((element) => element.id === unidad);
    setPlaceU(unidadescrazyy?.name_businessUnit);
  }
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.code_businessUnit + " " + element.name_businessUnit,
        value: element.id
      });
    });
    return valores;
  };
  const handleSelectChange = (items) => {
    setUnidad(items.value);
    setPlaceU(items.label);
  };
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: placeU, value: unidad }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputUnity = ({
  data,
  tag,
  size,
  setUnidad,
  unidad,
  setPlaceU,
  placeU
}) => {
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.code_businessUnit + " " + element.name_businessUnit,
        value: element.id
      });
    });
    return valores;
  };
  const handleSelectChange = (items) => {
    setUnidad(items.value);
    setPlaceU(items.label);
  };
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: placeU, value: unidad }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};

export const InputCapit = ({
  data,
  tag,
  size,
  setUniCap,
  uniCap,
  setPlaceCap,
  placeCap,
  setNameChap
}) => {
  const { dataToEdit } = useContext(ConfigurationCRUDContext);

  if (dataToEdit) {
    const unidadescrazyy = data.find((element) => element.id === uniCap);
    setPlaceCap(unidadescrazyy?.code_chapter);
  }

  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element.code_chapter,
        value: element.id
      });
    });
    return valores;
  };
  const handleSelectChange = (items) => {
    setUniCap(items.value);
    setPlaceCap(items.label);
    const capitulos = data.find((element) => element.id === items.value);
    setNameChap(capitulos);
  };
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: placeCap, value: uniCap }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};
