import { Td, Tr } from "@chakra-ui/react";
import MenuCommentsFollowOrder from "components/Menu/MenuCommentsFollowOrder";

const TBodyComment = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.type ? data?.type : "N/A"}</Td>
        <Td className="text-center"> {data?.message ? data?.message : "N/A"}</Td>
        <Td className="text-center">
          <MenuCommentsFollowOrder data={data} />
        </Td>

      </Tr>
    </>
  );
};

export default TBodyComment;
