import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Grid,
  GridItem,
  IconButton,
  List,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useForm } from "hooks/useForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { GroupInputUpload } from "pages/Entry_and_exit_forms/GroupInputUpload";
import { useUploadFile } from "pages/Entry_and_exit_forms/View/FormsView";
import { RiUploadCloudFill } from "react-icons/ri";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import isArrayNotEmpty from "utils/type-check-utils";
import { isValid } from "utils/parseFloat";
import { DocumentItem } from "components/attchmentTypiFied/OtherAttchment";
import { FaTrash } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const FileUploader = ({
  onUploadFile = () => {},
  cleanFile = () => {},
  files = [],
  customKey = null,
  associatedDocumentType = null,
  typeDoc = null,
  accept = ".doc,.docx,image/png,image/*,.png,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.dataUser.token);
  const inputRefs = useRef([]);
  const [currentRef, setCurrentRef] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [uploadFile, setUploadFile] = useState({});
  const [valuesEdit, handleInputEdit, resetEdit, setValuesEdit] = useForm({
    index: null,
    attachment_description: "",
    attachment_number: "",
  });

  const location = useLocation();
  const { pathname } = location;
  const associatedDocument = useMemo(
    () => associatedDocumentType,
    [associatedDocumentType]
  );

  const { isLoading, upload } = useUploadFile({
    token,
    onSuccess: useCallback(
      (res, file) => {
        const {
          id,
          dataset: { idtypedocument, iscorrected },
        } = inputRefs.current[currentRef] || {};

        const newUploadFile = {
          ...uploadFile,
          [id]: [
            {
              file: res,
              name: file?.name,
              document_id: idtypedocument,
              ...valuesEdit,
            },
          ],
        };

        setUploadFile(newUploadFile);
        setCurrentFile(null);
        resetEdit();

        // Call onUploadFile here
        onUploadFile({
          files: newUploadFile,
          setFiles: setUploadFile,
          id,
          doc_corrected_req: iscorrected
        });
      },
      [currentRef, valuesEdit, resetEdit, uploadFile, onUploadFile]
    ),
  });

  const triggerButtonAction = useCallback((index) => {
    setCurrentRef(index);
    inputRefs.current[index].click();
  }, []);

  const handleChangeFiles = useCallback(
    ({ target }) => {
      const { files: uploadedFiles } = target || {};
      if (uploadedFiles.length > 0) {
        setCurrentFile(uploadedFiles[0]);
        setValuesEdit({ name: uploadedFiles[0].name });
        onOpen();
      }
      target.value = "";
    },
    [setValuesEdit, onOpen]
  );

  const handleModalSubmit = useCallback(() => {
    if (currentFile) {
      upload(currentFile);
      onClose(); // Close the modal after initiating the upload
    }
  }, [currentFile, upload, onClose]);

  const handleModalClose = useCallback(() => {
    setCurrentFile(null);
    resetEdit();
    onClose();
  }, [resetEdit, onClose]);

  const renderDocumentItem = useCallback(
    ({ id, name_document, doc_corrected_req }, index) => {
      const documentKey = customKey
        ? customKey
        : parseFloat(doc_corrected_req) === 1
        ? `document-${id}_doc_corrected_req`
        : `document-${id}`;

      const hasUploadedFile =
        isValid(uploadFile[documentKey]) &&
        isArrayNotEmpty(uploadFile[documentKey]);
      const hasExistingFile = isArrayNotEmpty(files[documentKey]);

      if (
        doc_corrected_req === 1 &&
        pathname === "/FormEntry" &&
        pathname === "/FormExit"
      )
        return;

      return (
        <Box display="flex" flex="row" key={id}>
          <Box className="col-6 mt-2">
            <h2>{name_document}</h2>
          </Box>

          <Grid templateColumns="repeat(6, auto)" gap={2}>
            <GridItem colSpan={2} pt="2">
              <Tooltip
                placement="top"
                hasArrow
                label={
                  hasUploadedFile || hasExistingFile
                    ? "File already uploaded"
                    : "Add file"
                }
              >
                <span>
                  <ButtonHeaderModule
                    onClick={() => triggerButtonAction(index)}
                    bgColor="#03C39A"
                    isLoading={isLoading}
                    isDisabled={hasUploadedFile || hasExistingFile}
                  >
                    <RiUploadCloudFill color="#fff" />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            </GridItem>

            {hasExistingFile && (
              <GridItem colSpan={2} pt="2">
                <Box display={"flex"} flexDir={"column"} gap={3}>
                  <VStack spacing={2} align="stretch">
                    <List spacing={3}>
                      <DocumentItem
                        key={documentKey}
                        document={files[documentKey][0]}
                        isExisting={true}
                      >
                        <Tooltip label="Delete">
                          <IconButton
                            icon={<FaTrash />}
                            fontSize="12px"
                            size={"xs"}
                            colorScheme="red"
                            onClick={() => {
                              cleanFile(
                                files[documentKey][0],
                                doc_corrected_req,
                                typeDoc
                              );
                              setUploadFile((prev) => {
                                const newUploadFile = { ...prev };
                                delete newUploadFile[documentKey];
                                return newUploadFile;
                              });
                            }}
                          />
                        </Tooltip>
                      </DocumentItem>
                    </List>
                  </VStack>
                </Box>
              </GridItem>
            )}
          </Grid>

          <input
            type="file"
            data-isCorrected={doc_corrected_req}
            data-idTypeDocument={id}
            id={documentKey}
            ref={(el) => (inputRefs.current[index] = el)}
            hidden
            accept={accept}
            onChange={handleChangeFiles}
            name={name_document}
          />
        </Box>
      );
    },
    [customKey, uploadFile, files, pathname, isLoading, accept, handleChangeFiles, triggerButtonAction, cleanFile, typeDoc]
  );

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        title="Edit/Add file"
        size="lg"
      >
        <GroupInputUpload
          customHandleChange={{
            values: valuesEdit,
            handleInputChange: handleInputEdit,
            reset: resetEdit,
            setValues: setValuesEdit,
          }}
          onClose={onClose}
          customClose={handleModalClose}
          mode="edit"
          preview={false}
          callBack={handleModalSubmit}
        />
      </ModalGeneric>

      {isArrayNotEmpty(associatedDocument) &&
        associatedDocument.map(renderDocumentItem)}
    </>
  );
};

export default React.memo(FileUploader);
