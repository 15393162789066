/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonStyled } from 'styled/Buttons.styled'
import { RiSearch2Line } from 'react-icons/ri'
import { useForm } from 'hooks/useForm'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import GlobalContext from 'context/GlobalContext'
import environment from 'constants/apiConst'
import { SearchItemDispacth } from './SearchItemDispacth'
import { useMutation } from '@tanstack/react-query'
import FormInfo from 'components/DispatchCorrections/FormInfo'
import {
  FormItemsTemplate,
  errorMessageMap,
  regexPatterns
} from 'components/DispatchCorrections/Templates'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDebounceErrorHandling } from 'hooks/useDebounceError'
import { ItemSearchModal } from './ItemSearchModal'

const EditTemplateValueItems = {
  id: '',
  item: '',
  outputForm_id: '',
  code_outputForm: '',
  quantity_go_out: '',
  outputFormItem_id: '',
  quantity_go_out_product: '',
  convertion_factor_product: '',
  code_businessUnit_corrected: '',
  output_form_item_description: '',
  // Corrected
  item_corrected: '',
  reference_corrected: '',
  item_output_corrected: '',
  description_corrected: '',
  code_product_corrected: '',
  outputFormCorrected_id: '',
  code_outputForm_corrected: '',
  quantity_go_out_corrected: '',
  outputFormItemCorrected_id: '',
  quantity_dispatch_corrected: '',
  quantity_go_out_product_corrected: '',
  quantity_dispatch_product_corrected: '',
  output_form_item_description_corrected: ''
}

export const AddItemsDispatch = ({ editType, ...props }) => {
  const [openAlert] = AlertErrorAlert()
  const [isLoading, setIsLoading] = useState(false)

  const {
    targetData,
    actionToBeCarriedOut,
    performStatusChange,
    setActionToBeCarriedOut,
    setTargetData,
    setUpdate
  } = useContext(GlobalContext)
  const {
    isOpen: isOpenSearchItem,
    onOpen: onOpenSearchItem,
    onClose: onCloseSearchItem
  } = useDisclosure()
  const location = useLocation()
  const [handleDebounceRegexError] = useDebounceErrorHandling()

  const { Action = '', Dispatch: DispatchCorrectionID = null } =
    queryString.parse(location.search)

  const [values, handleInputChange, reset, setValues] = useForm(
    EditTemplateValueItems
  )

  const handleRegex = ({ target }) => {
    const typeRegex = target?.dataset?.typeRegex || 'anythingGoes'
    const regexConf = regexPatterns[typeRegex]

    const { value } = target
    let errorMessage = null

    if (!regexConf.test(value)) {
      if (typeRegex !== 'anythingGoes') {
        errorMessage = `Este campo solo acepta ${errorMessageMap[typeRegex]}`
      } else if (value.length > 0) {
        errorMessage = `El carácter ${value[value.length - 1]
        } no es permitido en este campo`
      }
    }

    handleDebounceRegexError(
      { target },
      handleInputChange,
      errorMessage,
      regexConf
    )
  }

  const { mutate, isLoading: isLoadingPost } = useMutation({
    mutationFn: performStatusChange,
    retry: 3
  })

  useEffect(() => {
    if (targetData) {
      const filterAndNormalizeData = Object.keys(targetData).reduce(
        (acc, key) => {
          const value = targetData[key]
          acc[key] = value !== null && value !== undefined ? value : ''
          return acc
        },
        {}
      )
      setValues(filterAndNormalizeData)
    }
  }, [targetData])

  const handleSubmit = async e => {
    e.preventDefault()

    const updatedItemData = {
      id: values?.id,
      outputFormItemCorrected_id: values?.outputFormItemCorrected_id,
      quantity_go_out_corrected: values?.quantity_dispatch_corrected,
      quantity_go_out_product_corrected:
        values?.quantity_dispatch_product_corrected,
      item_output_corrected: values?.item_output_corrected,
      reference: values?.reference_corrected
    }

    const newItemData = {
      dispatchPassCorrected_id: DispatchCorrectionID,
      outputFormItemCorrected_id: values?.outputFormItemCorrected_id,
      quantity_go_out_corrected: values?.quantity_dispatch_corrected,
      quantity_go_out_product_corrected:
        values?.quantity_dispatch_product_corrected,
      item_output_corrected: values?.item_output_corrected,
      reference: values?.reference
    }

    const requestData =
      actionToBeCarriedOut !== 'Edit' ? newItemData : updatedItemData

    const serviceEndpoint =
      actionToBeCarriedOut !== 'Edit'
        ? environment.CREATE_DISPATCH_STATUS_CORRECTED_ITEM
        : environment.UPDATE__DISPATCH_STATUS_CORRECTED_ITEM

    if (!isLoadingPost) {
      mutate(
        {
          data: requestData,
          Endpoint: serviceEndpoint,
          PropertyBody: 'sendJSONContentPOST'
        },
        {
          onSuccess: data => {
            if (data?.data?.status?.code === 400) { return openAlert(data?.data?.status?.message, 'error') }

            if (data?.data?.status?.code === 200) {
              openAlert(`Registro actualizado Exitosamente`, 'success')
              setUpdate('updatedItems')
              setActionToBeCarriedOut('')
            }
          }
        }
      )
    }
  }

  const handleBlur = async data => {
    const sendDate = {
      item: data?.item,
      code: data?.code,
      date_start: data?.date_start,
      date_end: data?.date_end,
      reference: data?.reference,
      subheading: data?.subheading,
      description: data?.description,
      ...(props?.qualifiedUserId && { qualifiedUser_id: props?.qualifiedUserId })
    }

    mutate(
      {
        data: sendDate,
        Endpoint: environment.SEARCH_DISPACTH_FORM_EXIT,
        PropertyBody: 'sendJSONContentPOST'
      },
      {
        onSuccess: data => {
          if (data?.data?.status?.code === 400) { return openAlert(data?.data?.status?.message, 'error') }

          if (data?.data?.status?.code === 200) {
            openAlert(data?.data?.status?.message, 'success')
          }
        }
      }
    )
  }

  const formItemsDispacth = () => {
    return (
      <SearchItemDispacth
        onClose={onCloseSearchItem}
        loading={isLoading}
        handleSearch={handleBlur}
      />
    )
  }

  useEffect(() => {
    if (actionToBeCarriedOut === 'Create') {
      setTargetData('')
      reset()
    }
  }, [actionToBeCarriedOut])

  /*   useEffect(() => {
    if (editType === 'Dispatch') setRenderingComponent(formItemsDispacth)
  }, [editType]) */

  return (
    <>
      <ItemSearchModal
        OnClose={onCloseSearchItem}
        isOpen={isOpenSearchItem}
        onOpen={onOpenSearchItem}
        qualifiedUserId={props.qualifiedUserId}
      />

      <form onSubmit={handleSubmit}>
        <Box>
          <InputGroup>
            <Input
              w={'100%'}
              h={'38px'}
              color={'#000'}
              outline={'none'}
              fontSize={'1rem'}
              backgroundColor={'#ffffff87'}
              name={'code_outputForm_corrected'}
              onChange={handleInputChange}
              value={values?.code_outputForm_corrected}
              placeholder={'Salida*'}
            />
            <InputRightElement onClick={onOpenSearchItem}>
              <RiSearch2Line color='green.500' />
            </InputRightElement>
          </InputGroup>

          <Flex gap={4} alignContent={'space-between'}>
            <Box w={'full'}>
              <FormInfo
                template={FormItemsTemplate}
                values={values}
                Actions={Action}
                onChange={handleRegex}
                mode='evaluate'
              />
            </Box>

            <Box w={'full'}>
              <FormInfo
                template={FormItemsTemplate}
                values={values}
                Actions={Action}
                isEdit={Action === 'Edit'}
                onChange={handleRegex}
              />
            </Box>
          </Flex>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          marginTop={'20px'}
          justifyContent='flex-end'
          flexWrap={'wrap'}
        >
          <ButtonStyled
            bgColor={'#bababa'}
            onClick={() => setActionToBeCarriedOut('')}
          >
            Cancelar
          </ButtonStyled>
          <ButtonStyled type='submit'>
            {actionToBeCarriedOut === 'Edit' ? 'Editar' : 'Agregar'}
          </ButtonStyled>
        </Box>
      </form>
    </>
  )
}
