/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { theadSubmitted } from "./ArraySubmitted/ArraySubmitted";
import Pagination from "components/Pagination/Pagination";
import { Title } from "styled/Title";
import { __Get } from "request/Petitions/__Get";
import { useSelector } from "react-redux";
import environment from "constants/apiConst";
import { TableDraggable } from "components/Tables/Dragable/Table";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import {
  Box,
  Button,
  Icon,
  Img,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text
  , Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";

import { IoHelp } from "react-icons/io5";
import gif from "./DragAndDropExample/DragAndDrop.gif";

const SubmittedQueue = () => {
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [update, setUpdate] = useState(false);

  const getQueue = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __Get(environment.DOCUMENTS_SUBMITTED, token);
      const requestData = res?.data?.status?.data;
      if (requestData !== null) {
        setCurrentPage(requestData?.data);
        const DataIds = requestData.map((item) => item?.id);
        localStorage.setItem("idDocuments", JSON.stringify(DataIds));
      } else {
        setCurrentPage([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getQueue();
  }, [getQueue, update]);

  const createData = async (data) => {
    try {
      const res = await __Post(
        environment.DOCUMENTS_SUBMITTED_SAVE,
        token,
        data
      );
      if (res.data.status.code === 200) {
        openAlert(`Registro guardado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        if (res.data.status.message) {
          openAlert(res.data.status.message, "error");
        } else {
          Object.values(res.data.status.message).forEach((message) =>
            message.forEach((item) => openAlert(item, "error"))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "null",
        columns: [
          {
            Header: "Tipo de documento",
            accessor: "type_doc"
          },
          {
            Header: "Código de operación",
            accessor: "code_operation"
          },

          {
            Header: "Formularios",
            accessor: "num_form"
          },
          {
            Header: "Cantidad de Items",
            accessor: "items"
          },
          {
            Header: "Fecha de Presentación",
            accessor: "date_submitted"
          }
        ]
      }
    ],
    []
  );

  return (
    <>
      <Box display={"flex"} justifyContent="right" w="100%" m="0.5rem 0.5rem">
        <Box
          p={2}
          borderRadius={5}
          shadow={
            " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
          }
        >
          <Popover>
            <PopoverTrigger>
              <Button colorScheme={"blue"} fontSize={"20px"}>
                <Icon as={IoHelp} />
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />

                <PopoverCloseButton />
                <PopoverBody>
                  <Tabs variant="enclosed">
                    <TabList>
                      <Tab>Información</Tab>
                      <Tab>¿Como funciona?</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Text as={"h4"}>
                          La tabla es interactiva, reordena los elementos con el
                          arrastrar y soltar
                        </Text>
                      </TabPanel>
                      <TabPanel>
                        <Img src={gif} alt="Example Gif" />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      </Box>

      <Title style={{ marginTop: 30, marginBottom: 40 }}>
        Cola de documentos presentados
      </Title>

      <HeaderSearch>
        <TableDraggable
          columns={columns}
          data={currentPage}
          setData={setCurrentPage}
          activeButton={true}
          funtionMove={createData}
          setUpdate={setUpdate}
          update={update}
        />

        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default SubmittedQueue;
