import './Content.css'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { __AllQualifiedUSers } from 'request/configuration/__QualifiedUsers'
import { useSelector } from 'react-redux'
import { ModalFooter, Button } from '@chakra-ui/react'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { InputSelectMulti } from 'components/InputSelect/InputSelect'
import { __Get } from 'request/Petitions/__Get'
import FormCrudCRUDContext from 'context/FormCrudContex'

export const ModalAssignCompany = ({ Data, onclose }) => {
  const [userQualified, setUserQuialified] = useState([])
  const token = useSelector(state => state.auth.dataUser.token)
  const [Selected, setSelected] = useState([])
  const [openAlert] = AlertErrorAlert()
  const { flagUpdateReviews, setflagUpdateReviews } =
    useContext(FormCrudCRUDContext)

  const getUserQualified = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token)
      const infoUsuarios = res?.data?.status?.data.map(item => ({
        value: item?.id,
        label: item?.nit_qualified + ' - ' + item?.name_qualified
      }))

      setUserQuialified(infoUsuarios)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  useEffect(() => {
    getUserQualified()
  }, [getUserQualified])

  useEffect(() => {
    GetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GetData = async () => {
    try {
      const res = await __Get(
        `${environment.Change_REVIEW_USER_ASSOCIATED}/${Data.user_id}`,
        token
      )
      const data = res?.data?.status?.data

      data.map(({ name_qualified, qualifiedUser_id }) => {
        setSelected(prev => [
          ...prev,
          { label: name_qualified, value: qualifiedUser_id }
        ])

        return 0
      })
    } catch (error) {
      console.log(error)
    }
  }

  const QualifiedUsersData = async DataCurrent => {
    const ListQualifiedUsers = []

    Selected.map(({ value }) => {
      ListQualifiedUsers.push(value)
      return 0
    })

    DataCurrent = {
      qualifiedUsers: ListQualifiedUsers,
      user_id: Data.user_id
    }

    if (DataCurrent) {
      try {
        const res = await __Post(
          environment.POST_QUALIFIED_USER_ASSOCIATED,
          token,
          DataCurrent
        )
        if (res?.data?.status.code) {
          openAlert(res?.data?.status?.data, 'success')
          setflagUpdateReviews(!flagUpdateReviews)
          onclose()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      <InputSelectMulti
        data={userQualified}
        tag={'Empresa revisar'}
        labelDisable={true}
        size={45}
        set={setSelected}
        value={Selected}
      />

      <ModalFooter
        paddingStart={0}
        paddingEnd={0}
        justifyContent={'space-between'}
      >
        <Button colorScheme='gray' onClick={onclose}>
          Cancelar
        </Button>
        <Button colorScheme='blue' onClick={QualifiedUsersData}>
          Guardar
        </Button>
      </ModalFooter>
    </>
  )
}
