import React from "react";
import { useLocation } from "react-router-dom";
import { FormInputGroup } from "styled/Input.styled";
import queryString from "query-string";

const ViewItemsPdf = ({ data }) => {
  const location = useLocation(); // navigate
  const { FormEntry = "" } = queryString.parse(location.search);

  const stylesFail = {
    border: "1px solid #bababa",
    borderRadius: "5px",
    padding: "10px",
    marginTop: "10px",
    fontSize: "13px"
  };

  return (
    <>
      {/* <h1 className="text-word mb-3 mt-4">Item Nº {numero + 1}</h1> */}
      <div className="col-md-3">
        <FormInputGroup style={stylesFail}>
          <p>Subpartida arancelaria</p>
          {data.code_subheading_corrected ? data.code_subheading_corrected : ""}
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>País origen</p>
            {data.name_country_ori_corrected
              ? data?.code_country_ori_corrected +
                " " +
                data.name_country_ori_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      {FormEntry && (
        <div className="col-md-3">
          <FormInputGroup>
            <FormInputGroup style={stylesFail}>
              <p>País procedencia</p>
              {data.name_country_proc_corrected
                ? data?.code_country_proc_corrected +
                  " " +
                  data.name_country_proc_corrected
                : ""}
            </FormInputGroup>
          </FormInputGroup>
        </div>
      )}

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>País compra</p>
            {data.name_country_purs_corrected
              ? data?.code_country_purs_corrected +
                " " +
                data.name_country_purs_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Código producto</p>
            {data.code_product_corrected ? data.code_product_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Unidad de producto</p>
            {data.unit_measurement_corrected
              ? data.code_unit_measurement_corrected +
                " " +
                data.unit_measurement_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Cantidad de Producto</p>
            {data.product_quantity_corrected
              ? data.product_quantity_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Peso bruto en Kg</p>
            {data.gross_weight_corrected ? data.gross_weight_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-12">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Descripción de Producto</p>
            {data?.description_product_corrected
              ? data?.description_product_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Peso neto Kg</p>
            {data.net_weight_corrected ? data.net_weight_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Números de bultos</p>
            {data.number_packages_corrected
              ? data.number_packages_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Cantidad</p>
            {data.quantity_corrected ? data.quantity_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Unidad comercial</p>

            {data.code_businessUnit_corrected
              ? data.code_businessUnit_corrected +
                " " +
                data.name_businessUnit_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Metodo de transporte</p>
            {data.code_transportMethod_corrected
              ? data.code_transportMethod_corrected +
                " " +
                data.name_transportMethod_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Bandera</p>
            {data.code_country_flag_corrected
              ? data.code_country_flag_corrected +
                " " +
                data.name_country_flag_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Embalaje</p>
            {data.code_packaging_corrected
              ? data.code_packaging_corrected +
                " " +
                data.name_packaging_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Fletes</p>
            {data.us_freight_corrected ? data.us_freight_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Seguros</p>
            {data.us_insurance_corrected ? data.us_insurance_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Otros gastos</p>
            {data.other_us_expenses_corrected
              ? data.other_us_expenses_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Valor FOB {data?.coin_corrected ?? ''}</p>
            {data.us_fob_value_corrected ? data.us_fob_value_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Valor CIF {data?.coin_corrected}</p>
            {data.cif_us_value_corrected ? data.cif_us_value_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Valor CIF COP</p>
            {data.cif_cop_value_corrected ? data.cif_cop_value_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Tasa de Cambio</p>
            {data.exchange_rate_corrected ? data.exchange_rate_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Acuerdo Comercial</p>
            {data.tradeAgreementCorrected_id
              ? data.tradeAgreementCorrected_id +
                " " +
                data.name_tradeAgreement_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Factura</p>
            {data.invoice_corrected ? data.invoice_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Marca</p>
            {data.brand_corrected ? data.brand_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
      <div className="col-md-3">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Serial</p>
            {data.serial_corrected ? data.serial_corrected : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>

      <div className="col-md-12">
        <FormInputGroup>
          <FormInputGroup style={stylesFail}>
            <p>Descripción larga</p>
            {data?.entry_form_item_description_corrected
              ? data?.entry_form_item_description_corrected
              : ""}
          </FormInputGroup>
        </FormInputGroup>
      </div>
    </>
  );
};

export default ViewItemsPdf;
