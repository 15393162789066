import { Td, Tr } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import MenuReviews from "./Menu/MenuReviews";
import queryString from "query-string";

import MenuAproved from "./Menu/MenuAproved";
import MenuAprovedToTransfer from "./Menu/MenuAprovedToTransfer";

const TBodyAprovedToTransfer = ({ data, ...props }) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  const SelectRouter = (status) => {
    if (status === "Aprobado de ingreso") {
      return `/FormShow?FormEntry=${data?.form_id}`;
    }

    if (status === "Aprobado de salida") {
      return `/FormShowExit?ExitNumber=${data?.form_id}`;
    }
  };

  return (
    <Tr>
      {authUser.admin === 1 || authUser.is_commerce === 1
        ? (
          <Td className="text-center">
            {" "}
            <NavLink to={SelectRouter(movement)}>
              <span className="formNumber">{data?.num_form}</span>
            </NavLink>
          </Td>
        )
        : (
          ""
        )}
      <Td className="text-center">
        {data?.code_operation ? data?.code_operation : "N/A"}
      </Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">
        {data?.date_submitted} {data?.time_submitted}
      </Td>
      <Td className="text-center">
        <MenuAprovedToTransfer data={data} movement={movement} />
      </Td>
    </Tr>
  );
};

export default TBodyAprovedToTransfer;
