import { formatedInput } from "components/Funciones/FunctionFormateInput";
import { AuthContext } from "context/AuthContext";
import React, { useEffect, useState, useContext } from "react";

import { BalanceSection, TitleBalance, ValueBalance } from "./Header.styled";

export const BalanceInterval = ({ balances }) => {
  const { authUser } = useContext(AuthContext);
  const [positionCarousel, setpositionCarousel] = useState(0);

  const updatePosition = () => {
    let counter = positionCarousel;
    window.carouselInterval = setInterval(() => {
      if (counter === 0) {
        counter += 1;
      } else if (counter === 1) {
        counter = 0;
      }

      return setpositionCarousel(counter);
    }, 4000);
  };

  useEffect(() => {
    if (authUser.is_commerce !== 1 && authUser.admin !== 1) {
      updatePosition();
      return () => clearInterval(window.carouselInterval);
    }
  }, []);

  return (
    <>
      {authUser.is_commerce !== 1 && authUser.admin !== 1 && (
        // <div className="d-flex">
        <BalanceSection>
          {positionCarousel === 0 && (
            <div className="text-center">
              <TitleBalance>Sdo. Pesaje</TitleBalance>
              <ValueBalance color={"#F9A621"}>
                ${formatedInput(balances?.scale_balance || 0)}
              </ValueBalance>
            </div>
          )}
          {positionCarousel === 1 && (
            <div className="text-center ms-2">
              <TitleBalance>Sdo. Doc</TitleBalance>
              <ValueBalance color={"#22CFCF"}>
                ${formatedInput(balances?.document_balance || 0)}
              </ValueBalance>
            </div>
          )}
        </BalanceSection>
        // </div>
      )}
    </>
  );
};
