import { Box, Text } from '@chakra-ui/react'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'

function ViewControlAnalysis ({ Detail }) {
  return (
    <>
      <Box
        display={'flex'}
        flexDir={'column'}
        justifyContent='space-between'
        p={6}
        borderRadius={'10px'}
        shadow={
          'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        }
      >
        {Detail
          ? (
            <>
              <Text fontSize={18} color={'blue.400'}>
              Nivel de Riesgo
              </Text>
              <Text fontSize={24} fontWeight={900}>
                {Detail?.detailed_analysis?.risk_level ?? 'N/A'}
              </Text>

              <Box display={'flex'} p={3}>
                <Box>{Detail?.detailed_analysis?.message}</Box>
              </Box>

              <Box
                w={20}
                h={20}
                bg={'yellow'}
                display={'flex'}
                flexDir={'column'}
                textAlign={'center'}
                justifyContent={'space-between'}
                borderRadius={5}
                shadow={
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                }
              >
                <Text fontSize={'4xl'} color={'yellow.600'} fontWeight={500}>
                  {Detail?.detailed_analysis?.level ?? 'N/A'}
                </Text>
                <Box
                  borderBottomLeftRadius={5}
                  borderBottomRightRadius={5}
                  bg={'blue.500'}
                >
                  <Text fontSize={'1xl'} fontWeight={500} color={'white'}>
                  Calificación
                  </Text>
                </Box>
              </Box>
            </>
          )
          : (
            <SpinnerComponent size='auto' />
          )}
      </Box>
    </>
  )
}

export default ViewControlAnalysis
