import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Td, Tr } from "@chakra-ui/react";
import PassCrudContext from "context/PassCrudContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

/**
 *  TbodyInfoFmm - Componente que retorna el body de la tabla cuando se realiza una busqueda de referencias en el modulo
 * de pases
 *
 * @component
 * @param {Object} data Información que se vera representada en la tabla.
 * @return {Component} Retorna componente body de una tabla.
 */

const TbodyInfoFmm = ({ data, onCloseModal }) => {
  const { addItemPass } = useContext(PassCrudContext);
  const location = useLocation();
  const { id = "" } = queryString.parse(location.search);

  const handleSearchItem = () => {
    const sendData = {
      entryForm_id: data.id,
      entryPass_id: parseInt(id)
    };
    if (id) {
      addItemPass(sendData);
    }
  };

  return (
    <Tr onClick={handleSearchItem}>
      <Td className="text-center">
        {data?.code_entryForm ? data?.code_entryForm : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.code_operation ? data?.code_operation : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.num_commercial_invoice ? data?.num_commercial_invoice : "N/A"}
      </Td>
      <Td className="text-center">
        {data?.status_entryForm ? data?.status_entryForm : "N/A"}
      </Td>
    </Tr>
  );
};

TbodyInfoFmm.propTypes = {
  data: PropTypes.object
};

export default TbodyInfoFmm;
