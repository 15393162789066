import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TbodyTypesIdentifications = ({ data }) => {
  const { activeAndDesactive } = useContext(ConfigurationCRUDContext);
  const TdState = styled(Td)`
    color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
  `;
  const [loadingIcons, setLoadingIcons] = useState(false);

  const handlesubmit = (status, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status === 1 ? 0 : 1);
    activeAndDesactive(formData);
  };

  return (
    <Tr>
      <Td className="text-center">{data?.code_identification_type}</Td>
      <Td className="text-center">
        {data?.name_identification_type}
      </Td>
      <Tooltip
        placement="top"
        hasArrow
        label={data?.status === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={() =>
            handlesubmit(data?.status_identification_type, data?.id)
          }
          className="text-center"
          isNumeric
          text={data?.status}
        >

          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_identification_type}
          />
        </TdState>
      </Tooltip>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTypesIdentifications
