import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __PackagingAll = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.PACKAGING}?page=${page || 1}`, options);
  return res;
};

export const __Show_Packaging = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.PACKAGING_SHOW}/${id}`, options);
  return res;
};

export const __Packaging_All = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.PACKAGING_NOT_PAGINATE}`, options);
  return res;
};
