import * as React from "react";

export const SvgComponent = (props) => (
  <svg
    viewBox="-41 -16 533 533.335"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M436.383 171.297a47.748 47.748 0 0 0-32.903-16.121 51.489 51.489 0 0 0-7.8.113v-51.965a12.255 12.255 0 0 0-3.422-8.844L301.543 3.883c-.04-.04-.02-.074-.059-.106A12.35 12.35 0 0 0 292.582 0c-.355 0-.691.258-1.043.29-.355-.032-.7.183-1.055.183H99.727c-25.309 0-46.223 20.113-46.223 45.425v9.524H44.55C19.242 55.328-1.348 75.77-1.445 101.078v352.55c0 25.31 20.687 46.372 45.996 46.372h250.543c25.308 0 45.636-21.063 45.636-46.371v-9.824h9.543c24.891.183 45.22-19.84 45.407-44.73V274.702l38.511-33.734c19.625-17.305 21.61-47.203 4.45-66.953zm-59.047 86.723a12.39 12.39 0 0 0-3.512 3.097l-25 22.04-61.539 53.882-40.355 2.305 8.203-38.852 102.097-89.758 32.122 36.692zm-72.82-215.438 49.207 49.055h-49.207zm11.238 411.047c0 11.535-9.121 21.394-20.66 21.394H44.55c-11.66-.226-20.996-9.73-21.02-21.394v-352.55c.078-11.5 9.465-20.759 20.965-20.68h9.008v318.054c0 25.305 20.914 45.352 46.223 45.352h216.027zm54.95-55.176c.171 11.082-8.677 20.203-19.759 20.375H99.727c-11.532 0-21.247-8.84-21.247-20.375V74.801c3-4.281 3-9.985 0-14.266V45.898c0-11.535 9.715-20.449 21.247-20.449h179.812v78.11c.23 7.12 5.95 12.836 13.074 13.054h78.09v48.676c0 .52-1.062 1.059-1.703 1.625l-17.152 15.184c-.684.41-1.328.882-1.918 1.414-.617.55-1.18 1.164-1.672 1.824l-112.75 99.14a12.471 12.471 0 0 0-3.965 6.797l-12.438 58.891c-.777 3.68.149 7.52 2.52 10.441a12.478 12.478 0 0 0 9.703 4.625c.235 0 .473-.007.711-.023l60.945-3.48a12.465 12.465 0 0 0 7.516-3.07l63.188-55.532 7.015-6.402zm47.214-176.219-9.84 8.676-32.094-36.668 9.758-8.574c9.352-8.223 23.602-7.305 31.824 2.047l.032.039 2.375 2.71c8.16 9.356 7.242 23.544-2.055 31.77zm0 0"
      style={{
        fill: "#fff"
      }}
    />
    <path
      d="M130.445 157.824h88.375c6.89 0 12.489-5.59 12.489-12.488 0-6.899-5.598-12.488-12.489-12.488h-88.375c-6.898 0-12.488 5.59-12.488 12.488s5.59 12.488 12.488 12.488zM282.5 217.77c0-6.899-5.594-12.489-12.488-12.489H130.445c-6.898 0-12.488 5.59-12.488 12.489 0 6.894 5.59 12.488 12.488 12.488h139.567c6.894 0 12.488-5.594 12.488-12.488zm0 0"
      style={{
        fill: "#fff"
      }}
    />
  </svg>
);
