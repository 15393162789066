import { useState, useContext } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  FormControl,
  FormLabel,
  Input,
  Button
} from '@chakra-ui/react'
import ControlAnalysisContext from 'context/ControlAnalysis'

function DateRangePicker ({ ...props }) {
  const { startDate, setStartDate, endDate, setEndDate } = useContext(
    ControlAnalysisContext
  )

  const handleSubmit = event => {
    event.preventDefault()
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button {...props}>Seleccionar fechas</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>Seleccionar fechas</PopoverHeader>
        <PopoverBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Fecha inicial</FormLabel>
              <Input
                type='date'
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Fecha final</FormLabel>
              <Input
                type='date'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </FormControl>

          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default DateRangePicker
