import React, { useCallback, useContext, useEffect, useState } from "react";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { ModalGeneric } from "components/modal/ModalGeneric";

import { HeaderSearch } from "styled/SectionContainer.styled";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import {
  theadContainer,
  theadWeighing
} from "./SearchAdvanced/Array/ArrayTableHead";
import { Title } from "styled/Title";
import { IconGeneric } from "styled/Icons.styled";
import { GrDocumentText } from "react-icons/gr";
import { FormWeighing } from "./FormWeighing/FormWeighing";
import { useSelector } from "react-redux";
import TbodyWeighing from "./Tbody/TbodyWeighing";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import WeighingContext from "context/WeighingContext";
import TBodyContainer from "./Tbody/TBodyContainer";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import {
  arrayLeftColaPesaje,
  arrayRigthColaPesaje,
  arrayRigthUserColaPesaje
} from "./Array/ArrayPrueba";
import { AuthContext } from "context/AuthContext";
import { __ExportExcel } from "request/Inventory/__InventoryCRUD";
import environment from "constants/apiConst";
import { __PostForm, __PostJsonData } from "request/Petitions/__Post";
import FormSearchWeighing from "./SearchAdvanced/FormSearchWeighing";
import { Reweigh } from "./view/Reweigh";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useSearchUrl } from "hooks/useSearchUrl";

export const Weighing = () => {
  const { state, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["order", "consecutive", "vehiclePlate", "completeness", "reweight", "startDate", "endDate", "shedule", "operation", "formNumber"], nestedProp: "searchAdvance" });

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const { authUser } = useContext(AuthContext);
  const [Reload, setReload] = useState(false);

  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [isOpenNewWeighing, onOpenNewWeighing, onCloseNewWeighing] = useModal();
  const [update, setUpdate] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [openAlert] = AlertErrorAlert();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {
    isOpenViewContainer,
    onOpenViewContainer,
    onCloseViewContainer,
    dataContainer,
    isOpenViewForm,
    onOpenViewForm,
    onCloseViewForm,
    dataForm,
    isOpenReweigh,
    onOpenReweigh,
    onCloseReweigh
  } = useContext(WeighingContext);

  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    setSearchAdvanced({});
    setChangeViewSearch(false);
    setLoading(true);
    // getPesaje(dataOrden);
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: state?.search || "",
    valueQualified: state?.valueQualified || [],
  });

  const { searchInformacion, valueQualified } = values;

  const dispacthRedux = useDispatch();

  useEffect(() => {
    const data = {
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && { qualifiedUser_id: valueQualified?.value }),
      ...searcheAdvanced,
    }

    if (Object.keys(data).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(data);
      dispacthRedux(setSearch(data));
      return;
    }

    dispacthRedux(setSearch({ reweight: true }));
    setLoading(false);
    setChangeViewSearch(false);
    GetWeighIns();
  }, [searchInformacion, searcheAdvanced, page, valueQualified, freeZone_id, update]);

  const GetWeighIns = useCallback(async () => {
    setLoading(true);
    const dataWeighing = new FormData();
    dataWeighing.append("order", "desc");
    dataWeighing.append("scale", true);
    try {
      const res = await __PostJsonData(environment.WEIGHING, token, dataWeighing, page);
      const requestData = res?.data?.status?.data.data;
      if (requestData?.length !== 0) {
        setCurrentPage(requestData);
        setMaxPage(res?.data?.status?.data?.last_page);
      } else {
        setCurrentPage([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token, freeZone_id, page]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __PostJsonData(
        environment.WEIGHING,
        token,
        informacion,
        page
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced({});
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data.data);
        setMaxPage(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };


  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);


  const handleSubmit = (data, formulario) => {
    /* Esto se hace para que tome el reweight del formulario y no de la data (o del state de redux) */
    const { reweight = false, ...fields } = formulario;
    const { reweight: _, ...sanitizedData } = data;
    const info = {
      fields,
      data: {
        ...sanitizedData,
        ...(reweight && { reweight }),
      }
    };
    exportColumnBy(info);
  };

  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.WEIGHING_EXPORT_EXCEL_ADVANCE
      );
      if (res?.data?.status?.code === 200) {
        openAlert(`${res?.data?.status?.data}`, "success");
        onCloseExport();
      } else {
        openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateNodeList = {
    1: arrayLeftColaPesaje.concat(arrayRigthUserColaPesaje),
    0: arrayLeftColaPesaje.concat(arrayRigthColaPesaje)
  }[authUser?.is_qualified_user];


  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={calculateNodeList}
        selectNodeList={[{
          type: "number",
          name: "consecutive",
          tag: "Consecutivo",
          parent: "consecutive",
          search: "consecutive",
          inputParams: {
            name: "consecutive",
            tag: "Consecutivo",
            allowNegative: false,
            thousandSeparator: false,
            decimalScale: 0,
          }
        }]}
      />
      {/*  <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeftColaPesaje}
          listRight={
            authUser.is_qualified_user === 1
              ? arrayRigthUserColaPesaje
              : arrayRigthColaPesaje
          }
          handleSubmit={handleSubmit}
          dafaulActive={[]}
        />
      </ModalGeneric> */}

      <ModalGeneric
        isOpen={isOpenViewContainer}
        onOpen={onOpenViewContainer}
        onClose={onCloseViewContainer}
        title="Ver contenedores"
      >
        <TableOrder
          thead={theadContainer}
          data={dataContainer || []}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TBodyContainer}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenViewForm}
        onOpen={onOpenViewForm}
        onClose={onCloseViewForm}
        title="Formulario asociados al pase"
        size={"sm"}
      >
        {dataForm &&
          dataForm.map((item, index) => (
            <div className="p-2">
              <IconGeneric as={GrDocumentText} />
              <span key={index}>Formulario número: {item.code_outputForm}</span>
              <br />
            </div>
          ))}
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <FormSearchWeighing
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReweigh}
        onOpen={onOpenReweigh}
        onClose={onCloseReweigh}
        scrollBehavior={"outside"}
        title="Repesar"
      >
        <Reweigh onCloseReweigh={onCloseReweigh} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenNewWeighing}
        onOpen={onOpenNewWeighing}
        onClose={onCloseNewWeighing}
        title="Nuevo pesaje"
      >
        <FormWeighing onClose={onCloseNewWeighing} set={setUpdate} />
      </ModalGeneric>

      <Title style={{ marginTop: 10 }}>Pesaje</Title>

      <SearchAdvancedModule
        handleInputChange={handleInputChange}
        values={values}
        onOpenSearch={onOpenSearch}
        changeViewSearch={changeViewSearch}
        backSearch={backSearch}
        permisoSearch={"getWeighing"}
        allAccess={allAccess}
        setValues={setValues}
        myPermission={myPermission}
      />

      <ExportDetail onOpen={onOpenExport}>
        {/* <Tooltip placement="top" hasArrow label="Nuevo">
          <ButtonHeaderModule
            onClick={onOpenNewWeighing}
            //  bgColor="#03C39A"
            className="me-1"
          >
            <IconGeneric color="#ffff" as={MdAdd} />
          </ButtonHeaderModule>
        </Tooltip> */}
      </ExportDetail>

      <HeaderSearch>
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <>
                <TableOrder
                  thead={theadWeighing}
                  data={currentPageTwo}
                  setData={setCurrentPageTwo}
                  loading={loading}
                  tbodyData={TbodyWeighing}
                />

                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </>
            )
          )
          : (
            <div>
              <TableOrder
                thead={theadWeighing}
                data={currentPage}
                setData={setCurrentPage}
                loading={loading}
                tbodyData={TbodyWeighing}
              />

              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};
