import React, { useContext, useEffect, useState } from "react";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import { Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";

const ThirdPartiiesShow = () => {
  const initialForm = {
    nit: "",
    internal_code: "",
    business_name: "",
    direction: "",
    phone: "",
    type: "",
    acronym: "",
    alias: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ThirdPartiesContext);

  // console.log(dataToShow);

  useEffect(() => {
    if (dataToShow) {
      console.log(dataToShow);
      setForm(dataToShow);
    } else {
      setForm(initialForm);
      // console.log(dataToShow);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.business_name : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Tipo</LabeFormlStyled>
                <InputFake>{form.type}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Tipo de Identificacion</LabeFormlStyled>
                <InputFake>{form.name_identification_type ? form.name_identification_type : "N/A"}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Numero de Identificacion</LabeFormlStyled>
                <InputFake>{form.identification_num ? form.identification_num : "N/A"}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Siglas</LabeFormlStyled>
                <InputFake>{form.acronym}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Codigo interno</LabeFormlStyled>
                <InputFake>{form.internal_code}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Alias</LabeFormlStyled>
                <InputFake>{form.alias}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Teléfono</LabeFormlStyled>
                <InputFake>{form?.phone ?? 'N/A'}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Dirección</LabeFormlStyled>
                <InputFake>{form.address}</InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Razón Social</LabeFormlStyled>
              <InputFake>{form.business_name}</InputFake>
            </FormInputGroup>
          </div>
        </div>

        {dataToShow?.Third_party_document?.length > 0 && // Third_party_document hace referencia a los archivos adjuntos que retorna back en caso de que no se retorne ningún archivo no es necesario mostrar la sección de archivos
          <div>
            <h2 style={{ fontWeight: "bold" }}>Archivos:</h2>
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              {dataToShow?.Third_party_document?.map((item, index) => ( // se mapean los archivos adjuntos y se crea una carta por cada uno de ellos, se muestra el nombre y la descripción al hacer click sobre el elemento este se descarga
                <a href={item?.document_url} target="_blank" rel="noreferrer" download style={{ width: "48%", height: "80px", backgroundColor: "#d8d8d8", margin: "4px", borderRadius: "4px", padding: "4px" }}>
                  <p><span style={{ fontWeight: "bold" }}>Nombre: </span>{item?.document_name}</p>
                  <p><span style={{ fontWeight: "bold" }}>Descripción: </span>{item?.document_description}</p>
                </a>
              ))}
            </div>
          </div>
        }


        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default ThirdPartiiesShow;
