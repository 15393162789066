import { Td, Tr } from '@chakra-ui/react'
import { useGlobalContext } from 'context/GlobalContext'
import { parseFloat } from 'utils/parseFloat'
import { isNumber, isString } from 'utils/type-check-utils'

const TbodyReferenceOP = ({ data, onCloseModal }) => {
  const { setDataProductionOrder } = useGlobalContext()

  const handleSearchReference = () => {
    const {
      description,
      business_unit,
      code_product,
      code_subheading,
      business_unit_product,
      code_tradeAgreement,
      code_operation,
      code_operation_initial,
      integration_certificate,
      quantity_available_product,
      quantity_available,
      value_fob,
      nature_of_inventory,
      import_declaration,
      convertion_factor,
      customs_clearance,
      name_tradeAgreement,
      inventory_id,
      value_cif_unit,
    } = data || {}

    setDataProductionOrder({
      ...data,
      description,
      code_Product: code_product,
      product_unit: business_unit_product,
      busisness_unit: business_unit,
      agreement: code_tradeAgreement || 'N/A',
      subheading: code_subheading,
      operation: code_operation,
      initial: code_operation_initial,
      certificate: integration_certificate,
      available_prod_balance: quantity_available_product,
      available_balance: quantity_available,
      balance_value: value_fob,
      natural: nature_of_inventory,
      convertion_factor:
        isNumber(convertion_factor) || isString(convertion_factor, true)
          ? parseFloat(convertion_factor)
          : 0,
      taxes: import_declaration ?? 0,
      name_tradeAgreement,
      customs_clearance: customs_clearance ?? 0,
      quantity: 0,
      net_weight: 0,
      inventory_id,
      value_cif_unit
    })
    onCloseModal()
  }

  return (
    <Tr onClick={handleSearchReference}>
      <Td className='text-center'>{data?.code_product}</Td>
      <Td className='text-center'>{data?.reference}</Td>
      <Td className='text-center'>{data?.code_subheading}</Td>
      <Td className='text-center'>{data?.description_product}</Td>
      <Td className='text-center'>{data.integration_certificate}</Td>
      <Td className='text-center'>{data?.import_declaration}</Td>
      <Td className='text-center'>{data?.code_operation}</Td>
      <Td className='text-center'>{data?.code_operation_inicial}</Td>
      <Td className='text-center'>{data?.code_tradeAgreement}</Td>
      <Td className='text-center'>{data?.quantity_available}</Td>
      <Td className='text-center'>{data?.business_unit}</Td>
      <Td className='text-center'>{data?.quantity_available_product}</Td>
      <Td className='text-center'>{data?.business_unit_product}</Td>
    </Tr>
  )
}

export default TbodyReferenceOP
