import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select
} from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { Comparison } from "./Components/Comparison/Comparison";

const FormShippingWithComparison = ({ data, onCloseSpreadsheet }) => {
  const TemplateInput = {
    TransportDocumentNumber: "",
    WeightKilogram: ""
  };

  const ListForm = data || [];
  // console.log(ListForm)
  // console.log('modal', ListForm)

  /*   const [ValueCurrentInput, setValueCurrentInput] = useState(TemplateInput);

  const handleInputChange = (data) => {
    const id_name = data.target.name;
    const valueCurrent = data.target.value;

    TemplateInput[id_name] = valueCurrent;
    console.log(TemplateInput);
  };
 */

  // console.log(ListForm)
  return (
    <>
      {/* <Box display={"flex"} gap="1rem">
        <FormControl>
          <FormLabel>Fecha disposición de carga</FormLabel>
          <Input
            name="LoadingArrangementDate"
            type="date"
            // onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Hora de disposición carga</FormLabel>
          <Input
            name="LoadDisposalTime"
            type="time"
            // onChange={handleInputChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Modo de transporte</FormLabel>
          <Select placeholder="Seleccionar">
            <option>Aéreo</option>
            <option>Aéreo</option>
            <option>Aéreo</option>
          </Select>
        </FormControl>
      </Box>
 */}
      {ListForm.map((x, index) => {
        return <Comparison key={index} data={x} />;
      })}

      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={onCloseSpreadsheet}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled type="submit">Enviar</ButtonStyled>
      </Box>
    </>
  );
};

export default FormShippingWithComparison;
