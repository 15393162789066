import axios from "axios";
import env from "../../constants/apiConst";

export const __QualificationUser = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(
    `${env.QUALIFICATION_USER_GET}?page=${page || 1}`,
    options
  );
  return res;
};

export const __ShowQualification = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFICATION_USER_SHOW}/${id}`, options);
  return res;
};
