import { Box, CheckboxGroup, Stack, Checkbox, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { GoGraph } from "react-icons/go";
import { ImParagraphLeft } from "react-icons/im";
import { IoIosBusiness } from "react-icons/io";
import { MdChecklist } from "react-icons/md";

import "../../SelectInput/stilos.css";
import { PopoverGeneric } from "../Popover/Popover";
import { Level } from "../ShowRiskLevel";

const Tablecheck = ({
  setCriteria,
  CriteriaId,
  setSector,
  sectorId,
  dataSource = [],
  loading,
  setriskLevel,
  riskLevel,
  setQualificationId,
  QualificationId,
  setFreeZoneId,
  FreeZoneId
}) => {
  const { qualification, criteria, free_zone, risk_level, sector } = {
    qualification: dataSource?.qualification ?? [],
    criteria: dataSource?.criteria ?? [],
    free_zone: dataSource?.free_zone ?? [],
    risk_level: dataSource?.risk_level ?? [],
    sector: dataSource?.sector ?? []
  };

  const FindIndexElement = (array, name, propertyName, TypeArray = false) => {
    // Validación de parámetros
    if (
      !Array.isArray(array) ||
      typeof name !== "string" ||
      typeof propertyName !== "string" ||
      typeof TypeArray !== "boolean"
    ) {
      console.error("Los parámetros son incorrectos");
    }

    // Búsqueda del índice del elemento
    if (Array.isArray(array)) {
      return array.findIndex((element) => {
        if (TypeArray) {
          // Comparación del elemento completo con name
          return element === name;
        } else {
          // Comprobación de existencia de la propiedad y comparación con name
          return element?.[propertyName] === name;
        }
      });
    }
  };

  useEffect(() => {
    if (
      qualification?.length > 0 ||
      criteria?.length > 0 ||
      free_zone?.length > 0 ||
      risk_level?.length > 0
    ) {
      if (FindIndexElement(criteria, "Todos", "criteria_name") === -1) {
        criteria.unshift({ criteria_name: "Todos", id: 0 });
      }

      if (FindIndexElement(qualification, "Todos", "operation_name") === -1) {
        qualification.unshift({ id: 0, operation_name: "Todos" });
      }

      if (FindIndexElement(free_zone, "Todos", "shortName_freeZone") === -1) {
        free_zone.unshift({ id: 0, shortName_freeZone: "Todos" });
      }

      if (FindIndexElement(sector, "Todos", "name_sector") === -1) {
        sector.unshift({ id: 0, name_sector: "Todos" });
      }
    }
  }, [dataSource]);

  const AddId = (name, get, set) => {
    const newArray = [...get];
    const index = newArray.indexOf(Level(name));
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(Level(name));
    }

    set(newArray);
  };

  const CheckBoxStatus = (name, get, set) => {
    const newArray = [...get];
    const index = newArray.indexOf(name);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(name);
    }

    set(newArray);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box display={"flex"} p="6" gap={6}>
          <PopoverGeneric
            leftIcon={<MdChecklist color="white" />}
            title={"Criterios"}
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            <CheckboxGroup colorScheme="green">
              <Stack direction={["row", "column"]}>
                {dataSource?.criteria?.map((row) => (
                  <>
                    <Checkbox
                      isDisabled={CriteriaId.includes(0) && row?.id !== 0}
                      name={row.id}
                      key={row.criteria_name}
                      onChange={(x) =>
                        CheckBoxStatus(
                          parseInt(x.target.name),
                          CriteriaId,
                          setCriteria
                        )
                      }
                    >
                      <Text fontSize="xs" noOfLines={1}>
                        {row.criteria_name}
                      </Text>
                    </Checkbox>
                  </>
                ))}
              </Stack>
            </CheckboxGroup>
          </PopoverGeneric>
          {/*
          <PopoverGeneric
            title={"Zonas"}
            leftIcon={<IoIosBusiness color="white" />}
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            <CheckboxGroup colorScheme="green">
              <Stack direction={["row", "column"]}>
                {dataSource?.free_zone?.map((row) => (
                  <Checkbox
                    name={row.id}
                    isDisabled={FreeZoneId.includes(0) && row?.id !== 0}
                    key={row["shortName_freeZone"]}
                    onChange={(x) =>
                      CheckBoxStatus(
                        parseInt(x.target.name),
                        FreeZoneId,
                        setFreeZoneId
                      )
                    }
                  >
                    <Text fontSize="xs" noOfLines={[1, 2, 3]}>
                      {row["shortName_freeZone"]}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </PopoverGeneric> */}

          <PopoverGeneric
            title={"Calificación"}
            style={{ maxHeight: "500px", overflowY: "scroll" }}
            leftIcon={<ImParagraphLeft color="white" />}
          >
            <CheckboxGroup colorScheme="green">
              <Stack direction={["row", "column"]}>
                {dataSource?.qualification?.map((row) => (
                  <Checkbox
                    isDisabled={QualificationId.includes(0) && row?.id !== 0}
                    name={row.id}
                    key={row.operation_name}
                    onChange={(x) =>
                      CheckBoxStatus(
                        parseInt(x.target.name),
                        QualificationId,
                        setQualificationId
                      )
                    }
                  >
                    <Text fontSize="xs" noOfLines={1} p={0} m={0}>
                      {row.operation_name}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </PopoverGeneric>

          <PopoverGeneric
            title={"Nivel de riesgos"}
            style={{ maxHeight: "500px", overflowY: "scroll" }}
            leftIcon={<GoGraph color="white" />}
          >
            <CheckboxGroup colorScheme="green">
              <Stack direction={["row", "column"]}>
                {dataSource?.risk_level?.map((row) => (
                  <Checkbox
                    name={row}
                    key={row}
                    data-name-id={row}
                    onChange={(x) =>
                      AddId(x.target.name, riskLevel, setriskLevel)
                    }
                  >
                    <Text fontSize="xs" noOfLines={1}>
                      {row}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </PopoverGeneric>

          <PopoverGeneric
            title={"Sector"}
            leftIcon={<IoIosBusiness color="white" />}
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            <CheckboxGroup colorScheme="green">
              <Stack direction={["row", "column"]}>
                {dataSource?.sector?.map((row) => (
                  <Checkbox
                    isDisabled={sectorId.includes(0) && row?.id !== 0}
                    name={row.id}
                    key={row.id}
                    onChange={(x) =>
                      CheckBoxStatus(
                        parseInt(x.target.name),
                        sectorId,
                        setSector
                      )
                    }
                  >
                    <Text fontSize="xs" noOfLines={[1, 2, 3]}>
                      {row.name_sector}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </PopoverGeneric>
        </Box>
      )}
    </>
  );
};

export default Tablecheck;
