import { useContext, useEffect, useState } from "react";
import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { VscFilePdf } from "react-icons/vsc";
import { MdQrCodeScanner } from "react-icons/md";
import { ButtonsBorrador } from "./ButtonsBorrador";
import { Buttonssubmitted } from "./Buttonssubmitted";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { ButtonsAuthorization } from "./ButtonsAuthorization";
import { ExportPdf } from "components/Export/ExportPdf";
import environment from "constants/apiConst";
import { ActionsReview } from "./ButtonReview";
import ActionsReviewed from "./ActionsReviewed";
import ModalQR from "components/modal/ModalQR";
import { useGlobalContext } from "context/GlobalContext";
import { useMutation } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

export const ButtonsBox = ({ values, children, valuesCorrected }) => {
  const {
    onOpenCopyBox,
    changeStatusPresent,
    onOpenAnular,
    changeStatus,
    changeStatusCorrected,
    setRequestChangeStatusBoxPresent
  } = useContext(IntegracionBoxCrudContex);
  const location = useLocation();
  const { pathname, search } = location;
  const {
    id = null,
    idCorrected = null,
    ButtonNot = ""
  } = queryString.parse(search);

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formSubmissionState, setFormSubmissionState] = useState();
  const [openAlert] = AlertErrorAlert()

  useEffect(() => {
    setFormSubmissionState(values?.status || valuesCorrected?.status);
  }, [values?.status, valuesCorrected?.status]);

  const name = "CUADRO_INT" + values?.integration_certificate_code;

  const handleCopy = (e) => {
    onOpenCopyBox();
  };

  const handleAnular = (e) => {
    onOpenAnular();
  };

  const handleEdit = () => {
    navigate(`/IntegracionBox?id=${id}`);
  };

  const handleEditCorrected = () => {
    navigate(`/CorrectedCertificate?idCorrected=${idCorrected}`);
  };

  const handlePresent = async () => {
    setRequestChangeStatusBoxPresent(environment.CHANGE_STATUS_PRESENT);

    try {
      const statusData = new FormData();
      statusData.append("integration_certificate_id", id);
      await changeStatusPresent(
        statusData,
        environment.CHANGE_STATUS_PRESENT,
        () => {
          navigate(`/ShowIntegrationBox?id=${id}`);
        }
      );
    } catch (error) {
      console.error("Error al cambiar el estado de presentación:", error);
    }
  };

  useEffect(() => {

  }, [])

  const handleChangeStatusCorrected = (status) => {
    const dataToSend = new FormData();
    dataToSend.append("id", idCorrected);
    dataToSend.append("status", status);
    changeStatusCorrected(dataToSend, environment?.CHANGE_STATUS_AUTORIZED_CORRECTED);
  };

  const handleShow = () => {
    navigate(`/CheckIntegrationBox?id=${id}`);
  };

  const { requestData } = useGlobalContext();

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleStatusBox = (status) => {
    const statusData = new FormData();
    statusData.append("id", id);
    statusData.append("status", status);
    const data = {
      id,
      status,
    }
    return mutate({
      Endpoint: environment.CHANGE_STATUS_BOX,
      data,
      PropertyBody: "POST",
    }, {
      onSuccess: (data) => {
        if (status === "") {
          navigate(`/ShowIntegrationBox?id=${id}`);
        }
      }
    })


  };

  const handleNavigateCorrected = () => {
    navigate(`/CheckIntegrationBox?idCorrected=${id}`);
  };
  const redirectToCertificateReview = () => {
    const reviewUrl = id
      ? `/IntegrationCertificateReview?id=${id}`
      : `/IntegrationCertificateReview?idCorrected=${idCorrected}`;

    navigate(reviewUrl);
  };

  const isDraft = formSubmissionState === "BORRADOR";

  const isSubmitted =
    formSubmissionState === "PRESENTADO" &&
    pathname !== "/IntegrationCertificateReview";

  const isReview =
    formSubmissionState === "PRESENTADO" &&
    pathname === "/IntegrationCertificateReview";

  const isReturned = formSubmissionState === "DEVUELTO";

  const isReviewed =
    formSubmissionState === "REVISADO" && pathname === "/ShowIntegrationBox";

  const isAuthorized =
    (formSubmissionState === "AUTORIZADO" ||
      formSubmissionState === "CORREGIDO") &&
    !ButtonNot;


  const handleExportPDF = (id) => {
    mutate({
      Endpoint: environment.EXPORT_CI_PDF,
      data: {
        ci: id,
      },
      PropertyBody: "POST",
    }, {
      onSuccess: (data) => {
        if (data?.data?.status?.code === 200) {
          openAlert(data?.data?.status?.data, "success");
        }
      }
    })
  }

const result = () => {
  if (isDraft) {
    return (
      <ButtonsBorrador
        handleCopy={handleCopy}
        handleEdit={handleEdit}
        handlePresent={handlePresent}
        handleEditCorrected={handleEditCorrected}
        handleChangeStatusCorrected={handleChangeStatusCorrected}
      />
    )
  }
  if (isReview) {
    return (
      <ActionsReview handleChangeStatusCorrected={handleChangeStatusCorrected} />
    )
  }
  if (isReviewed) {
    return (
      <ActionsReviewed formSubmissionState={formSubmissionState} />
    )
  }
  if (isSubmitted) {
    return (
      <Buttonssubmitted
        handleCopy={handleCopy}
        handleShow={handleShow}
        handleStatusBox={handleStatusBox}
        handleNavigateCorrected={handleNavigateCorrected}
        handleNavigateReview={redirectToCertificateReview}
        handleChangeStatusCorrected={handleChangeStatusCorrected}
      />
    )
  }
  if (isReturned) {
    return (
      <ButtonsBorrador
        handleCopy={handleCopy}
        handleEdit={handleEdit}
        handlePresent={handlePresent}
        handleEditCorrected={handleEditCorrected}
        handleChangeStatusCorrected={handleChangeStatusCorrected}
      />
    )
  }
  if (isAuthorized) {
    return ( <ButtonsAuthorization
      handleAnular={handleAnular}
      handleCopy={handleCopy}
      handleStatusBox={handleStatusBox}
    />)
  }

/*   return (
    (
      <div className="col">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className="mt-4"
        >
          <Tooltip placement="top" hasArrow label="Qr">
            <span>
              <ButtonHeaderModule
                onClick={onOpen}
                className="m-2"
                bgColor="#1F7AC3"
              >
                <IconGeneric className="m-1 text-white" as={MdQrCodeScanner} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>

          <Tooltip placement="top" hasArrow label="Exportar PDF">
            <span>
              <ButtonHeaderModule
                onClick={() => ExportPdf("IdpdfCertificated", name)}
                className="m-1"
                bgColor="#03C39A"
              >
                <IconGeneric className=" text-white" as={VscFilePdf} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </Box>
      </div>
    )
  ) */
}



  return (
    <div className="row">
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Código QR"}
        size={"md"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "4%",
          width: "100%"
        }}
        className="buttons"
      >
        {result()}
      </div>
      {children}
      {ButtonNot === "" && (
        <div className="button-qr">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="mt-4"
          >
            <Tooltip placement="top" hasArrow label="Qr">
              <span>
                <ButtonHeaderModule
                  onClick={onOpen}
                  className="m-2"
                  bgColor="#1F7AC3"
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement="top" hasArrow label="Exportar PDF">
              <span>
                <ButtonHeaderModule
                  onClick={() =>
                    handleExportPDF(id)
                  }
                  className="m-1"
                  bgColor="#03C39A"
                >
                  <IconGeneric className=" text-white" as={VscFilePdf} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      )}
    </div>
  );
};
