import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Spacer
} from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import {
  serviceInfo,
  formTypeInfo
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useSelector } from "react-redux";
import { __PostForm, __PostJsonData } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import {
  __Get_Operations,
  __Get_Options_FollowOrder
} from "request/Monitoring/__Get_Operations";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Get_Users_Qualified } from "request/Monitoring/__Get_Users_Qualified";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormDocumentsCorrect from "pages/Entry_and_exit_forms/CorrectionForm/busqueda avanzada/FormDocumentsCorrect";
import {
  __Get_Items__EntryFmm,
  __Get_Items__ExitsFmm
} from "request/Monitoring/__Get_Items__EntryFmm";
import TableOperationsMonitoring from "../Table/TableMonitoring";
import { TheadTrackingItems } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTableItems from "../Table/TBodyTableItems";
import { useModal } from "hooks/useModal";
import { AuthContext } from "context/AuthContext";
import { __GetDataNoPage } from "request/Petitions/__Get";
import TableModalMonitoring from "../Table/TableModalMonitoring";
import { MaxLength } from "utils/MaxLength";
import Pagination from "components/Pagination/Pagination";
import { NumericFormat } from "react-number-format";


export const FormCreateMonitoring = ({ getMonitoring, onClose }) => {
  const [arrayItems, setArrayItems] = useState([]);
  const [codeEntryOrExitForm, setCodeEntryOrExitForm] = useState([]);
  const [valueQualified, setValueQuialified] = useState([]);
  const [userQualified, setUserQuialified] = useState([]);
  const [service, setService] = useState("");
  const [followOrder, setFollowOrder] = useState("");
  const [viewTable, setViewTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [formType, setFormType] = useState("");
  const [operations, setOperations] = useState([]);
  const [optionsFollowOrder, setOptionsFollowOrder] = useState([]);
  const [operation, setOperation] = useState([]);
  const [description, setDescription] = useState("");
  const [allOperations, setAllOperations] = useState(false);
  const [allItems, setAllItems] = useState(false);
  const [allQualifiers, setAllQualifiers] = useState(false);
  const [zf, setZf] = useState(null);
  const [dataZf, setDataZf] = useState([]);
  const { token, is_commerce, admin, freeZone_id } = useSelector(
    (state) => state.auth.dataUser
  );

  const [openAlert] = AlertErrorAlert();
  const [selectUF, setSelectUF] = useState(null);
  const [selectAD, setSelectAD] = useState("Descendente");
  const [loading, setLoading] = useState(false);
  const [
    isOpenCreateMonitoring,
    onOpenCreateMonitoring,
    onCloseCreateMonitoring
  ] = useModal();
  const [operationT, seOperationT] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [numTransportDocument, setNumTransportDocument] = useState("");
  const [subheading, setSubheading] = useState("");

  const validateLengthSubheading =
    subheading &&
    subheading.length !== 2 &&
    subheading.length !== 4 &&
    subheading.length !== 10;

  const getUserQualified = async () => {
    try {
      const formData = new FormData();
      formData.append("form_type", formType?.value);
      formData.append("follow_order", followOrder?.value);
      formData.append("freeZone_id", admin ? zf?.value : freeZone_id);
      const res = await __Get_Users_Qualified(token, formData);
      const infoUsuarios = res?.data?.status?.data?.map((item) => ({
        value: item?.qualifiedUser_id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));

      setUserQuialified(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  };

  const getZf = async () => {
    try {
      const res = await __GetDataNoPage(
        environment.GET_ZONAS_F_NOT_PAGE,
        token
      );
      if (res?.data?.status.code === 200) {
        const info = res?.data?.status?.data;
        const infoZf = info.map((item) => ({
          label: item.name_freeZone,
          value: item?.id
        }));
        setDataZf(infoZf);
      } else {
        openAlert(`Ocurrio un error`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (!formType?.value) { return openAlert("El tipo de formulario es requerido", "error"); }

    if (!followOrder?.value) { return openAlert("El gestor de seguimiento es requerido", "error"); }

    if (admin === 1 && !zf?.value) { return openAlert("La zona franca es requerida", "error"); }

    formData.append("form_type", formType?.value ? formType?.value : "");
    formData.append("service", service?.value ? service?.value : "");
    formData.append(
      "follow_order",
      followOrder?.value ? followOrder?.value : ""
    );
    formData.append("initial_description", description);

    formData.append(
      "freeZone_id",
      admin ? zf?.value : is_commerce && freeZone_id
    );

    if (formType?.value === 1 || formType?.value === 2) {
      if (!arrayItems?.length && !allItems) { return openAlert("Por favor seleccione uno o más items", "error"); }
      arrayItems.length &&
        arrayItems?.map((item, index) => {
          formData.append(`item_id[${index}]`, item);
        });

      allItems && formData.append("totalData", true);
      formData.append("form_code", codeEntryOrExitForm);
    }

    if (formType?.value === 3) {
      if (!numTransportDocument) {
        return openAlert(
          "El campo número de documento de transporte es requerido",
          "error"
        );
      }
      formData.append("transport_document_number", numTransportDocument);
    }

    if (formType?.value === 4) {
      console.log({ valueQualified });
      if (!valueQualified?.length && !allQualifiers) { return openAlert("Seleccione uno o mas usuarios calificados", "error"); }
      allQualifiers
        ? formData.append("totalData", true)
        : valueQualified.map((user, index) => {
          formData.append(`arrayUC[${index}]`, user.value);
        });
    }

    if (formType?.value === 5) {
      if (!valueQualified?.value && !allQualifiers) { return openAlert("Seleccione uno o mas usuarios calificados", "error"); }
      if (!operation?.length && !allOperations) { return openAlert("Seleccione una o más operaciones", "error"); }
      formData.append(
        "arrayUC",
        valueQualified?.value ? valueQualified?.value : ""
      );
      operation &&
        operation.map((op, index) => {
          formData.append(`operations[${index}]`, op.value);
        });
      allOperations && formData.append("totalData", true);
    }

    if (formType?.value === 6) {
      if (!subheading) return openAlert("Ingrese una subpartida")
      formData.append("subheading", subheading);
    }

    sendData(formData);
  };

  const sendData = async (data) => {
    try {
      const res = await __PostForm(
        environment.MONITORING_SEND_FORM,
        token,
        data
      );
      const message = res?.data?.status?.message;
      const code = res?.data?.status?.code;
      if (code === 200) {
        onClose();
        openAlert(message, "success");
        await getMonitoring();
        return;
      }
      if (code === 400) return openAlert(message[0], "error");
    } catch (error) {
      console.log(error);
    } finally {
      await getMonitoring();
    }
  };

  const setItems = (id) => setArrayItems([...arrayItems, id]);
  const deleteItems = (id) => {
    const idIndex = arrayItems.findIndex((item) => item === id);
    const copyArrayItems = [...arrayItems];
    copyArrayItems.splice(idIndex, 1);
    setArrayItems(copyArrayItems);
  };

  const getOperations = async (data) => {
    try {
      const res = await __Get_Operations(token, data);
      const operacionesInfo = res?.data?.status?.data.map((item) => ({
        value: item?.operation_id,
        label: item?.code_operation + " - " + item?.short_name_operation
      }));
      setOperations(operacionesInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionsFollowOrder = async () => {
    try {
      const res = await __Get_Options_FollowOrder(token);
      const optionsFollowOrder = res?.data?.status?.data?.map((item) => ({
        value: item?.follow_order,
        label: item?.follow_order
      }));
      setOptionsFollowOrder(optionsFollowOrder);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOptionsFollowOrder();
    getZf();
  }, []);

  useEffect(() => {
    const formData = new FormData();
    if (formType?.value === 5 && followOrder?.value && valueQualified?.value) {
      formData.append("follow_order", followOrder?.value);
      formData.append("qualifiedUser_id", valueQualified?.value);
      getOperations(formData);
    }
  }, [followOrder?.value, formType?.value, valueQualified?.value]);

  useEffect(() => {
    if (
      (formType?.value === 1 || formType?.value === 2) &&
      followOrder?.value
    ) {
      onOpenCreateMonitoring();
    }
  }, [formType?.value, followOrder]);

  useEffect(() => {
    if (followOrder?.value && formType?.value && (formType?.value === 6 || formType?.value === 5 || formType?.value === 4)) {
      setUserQuialified([])
      getUserQualified();
    }
  }, [formType?.value, followOrder?.value]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [persistValues, setPersistValues] = useState(null);

  const OnSubmitItemSearch = async (values) => {
    if (!values?.code && !persistValues?.code_form) {
      return openAlert("El campo Nº de formulario es requerido", "error");
    }
    console.log(values)

    setLoading(true);
    try {
      console.log(formType, "formType")
      console.log(formType?.value, "formType?.value")
      const form = {
        code_form: values?.code ? values?.code : "",
        date_initial: values?.date_start ? values?.date_start : "",
        date_final: values?.date_end ? values?.date_end : "",
        initial_hour: values?.time_start ? values?.time_start : "",
        final_hour: values?.time_end ? values?.time_end : "",
        orderby_type: values?.order_by ? values?.order_by : "",
        follow_order: followOrder?.value,
        tracking: true,
        status: ["PRESENTADO", "APROBADO", "AUTORIZADO", "DEVUELTO"],
        num_commercial_invoice: values?.nci ? values?.nci : ""
      }
      if (values) setPersistValues(form)
      const res = await __PostJsonData(formType?.value === 1 ? environment.GET_EXIT_ITEMS : formType?.value === 2 ? environment.GET_ENTRY_ITEMS : "", token, persistValues || form, page)
      const data = res?.data?.status?.data;
      const code = res?.data?.status?.code;
      const message = res?.data?.status?.message;
      const dataTableItems = data?.data;
      if (code === 200) {
        setCodeEntryOrExitForm(
          formType?.value === 1 ? dataTableItems[0]?.code_output_form : dataTableItems[0]?.code_entry_form
        )
        setMaxPage(data?.last_page);
        setDataTable(dataTableItems);
        openAlert(message, "success");
        return onCloseCreateMonitoring();
      }
      return openAlert(message, "error");
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
      setViewTable(true);
    }
  };

  useEffect(() => {
    if (persistValues?.code_form) {
      OnSubmitItemSearch()
    }
  }, [page])

  useEffect(() => {
    if (!formType?.value) {
      setDataTable([]);
    }
  }, [formType?.value]);

  const inputOn = (e) => {
    if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength); }
  };

  const keyUp = (e) => {
    if (/\D/g.test(e.target.value)) { e.target.value = e.target.value.replace(/\D/g, ""); }
  };

  const inputOnNumTransport = (e) => {
    if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength); }
  };

  const keyUpNumTransport = (e) => {
    if (/[^a-zA-Z0-9 ]/g.test(e.target.value)) { e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""); }
  };

  useEffect(() => {
    if (!formType?.value) {
      setDataTable([]);
      setDescription("");
      setService("");
      setFollowOrder("");
      setSubheading("");
      setNumTransportDocument("");
      setValueQuialified("");
      setOperation("");
      setAllOperations("");
      setAllQualifiers("");
      setArrayItems("");
      setAllItems("");
      setCodeEntryOrExitForm("");
      setPersistValues(null);
      setPage(1);
      setMaxPage(1);
    }
  }, [formType?.value]);

  useEffect(() => {
    if (!followOrder?.value) {
      setArrayItems("");
      setAllItems("");
      setCodeEntryOrExitForm("");
      setDataTable([]);
      setPersistValues(null);
      setPage(1);
      setMaxPage(1);
    }
  }, [followOrder]);

  return (
    <>
      {(formType?.value === 2 || formType?.value === 1) &&
        followOrder?.value && (
          <ModalGeneric
            isOpen={isOpenCreateMonitoring}
            onOpen={onOpenCreateMonitoring}
            onClose={onCloseCreateMonitoring}
            size="3xl"
            title="Buscar Formulario"
          >
            <FormDocumentsCorrect
              onClose={onClose}
              handleSubmit={OnSubmitItemSearch}
              selectUF={selectUF}
              setSelectUF={setSelectUF}
              operationT={operationT}
              seOperationT={seOperationT}
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectAD={selectAD}
              setSelectAD={setSelectAD}
              viewSelectStatus={true}
              formType={formType}
            />
          </ModalGeneric>
        )}

      <div
        style={
          admin === 1
            ? {
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2rem"
            }
            : {
              width: "95%",
              margin: "0 auto 2rem auto"
            }
        }
      >
        {admin === 1 && (
          <InputSelect
            size={45}
            name={"freeZone_id"}
            tag={"Zona Franca"}
            required={true}
            data={dataZf}
            set={setZf}
            value={zf}
          />
        )}

        <InputSelect
          size={admin === 0 ? 100 : 45}
          name={"formType"}
          tag={"Tipo de seguimiento*"}
          data={formTypeInfo}
          set={setFormType}
          value={formType}
        />
      </div>

      {true && (
        <div
          style={{
            width: "95%",
            margin: "0 auto 2rem auto"
          }}
        >
          <FormControl isInvalid={validateLengthSubheading}>
            <FormLabel
              style={{
                color: "#454546",
                fontSize: "0.75rem",
                fontWeight: "400"
              }}
            >
              Subpartida*
            </FormLabel>
            <InputGroup size="md">
              <NumericFormat
                style={{ border: "solid 1px #bababa", outline: "none" }}
                pr="3rem"
                allowNegative={false}
                decimalScale={0}
                customInput={Input}
                maxLength={10}
                name={"subheading"}
                value={subheading}
                onChange={(e) => setSubheading(e?.target?.value)}
                desicion={true}
              />
            </InputGroup>
            <FormErrorMessage>
              La longitud de la subpartida debe ser de 2, 4 o 10 caracteres
            </FormErrorMessage>
          </FormControl>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "2rem"
        }}
      >
        <InputSelect
          size={45}
          name={"followOrder"}
          tag={"Gestora de seguimiento*"}
          data={optionsFollowOrder}
          set={setFollowOrder}
          value={followOrder}
        />
        <InputSelect
          size={45}
          name={"service"}
          tag={"Medio"}
          data={serviceInfo}
          set={setService}
          value={service}
        />
      </div>

      {viewTable &&
        dataTable.length > 0 &&
        (formType?.value === 2 || formType?.value === 1) && (
          <>
            <TableModalMonitoring
              thead={TheadTrackingItems}
              data={dataTable}
              setData={setDataTable}
              loading={loading}
              tbodyData={TBodyTableItems}
              setItems={setItems}
              deleteItems={deleteItems}
              allItems={allItems}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
            <Checkbox
              size="sm"
              colorScheme="blue"
              value={allItems}
              onChange={() => setAllItems(!allItems)}
              isDisabled={
                arrayItems?.length > 0 ||
                dataTable?.some((dataTable) => dataTable?.currentStatus === 1)
              }
            >
              Todos los items
            </Checkbox>
          </>
        )}

      {formType?.value === 5 && followOrder?.value && (
        <div
          style={{
            width: "95%",
            margin: "0 auto 2rem auto"
          }}
        >
          <InputSelect
            size={100}
            name={"qualifiedUser_id"}
            tag={"Usuario calificado*"}
            data={userQualified}
            set={setValueQuialified}
            value={valueQualified}
          />
        </div>
      )}

      {formType?.value === 5 && valueQualified?.value && (
        <div
          style={{
            width: "95%",
            margin: "0 auto 2rem auto"
          }}
        >
          <InputSelectMulti
            tag={"Seleccione las operaciones"}
            data={operations}
            value={operation}
            set={setOperation}
            isDisabled={allOperations}
          />
          <Checkbox
            size="sm"
            colorScheme="blue"
            value={allOperations}
            onChange={() => setAllOperations(!allOperations)}
            isDisabled={operation?.length > 0}
          >
            Todas las operaciones
          </Checkbox>
        </div>
      )}

      {formType?.value === 3 && (
        <div
          style={{
            width: "95%",
            margin: "0 auto 2rem auto"
          }}
        >
          <FormControl isInvalid={false}>
            <FormLabel
              style={{
                color: "#454546",
                fontSize: "0.75rem",
                fontWeight: "400"
              }}
            >
              Nº documento de transporte*
            </FormLabel>
            <InputGroup size="md">
              <Input
                style={{ border: "solid 1px #bababa", outline: "none" }}
                pr="3rem"
                type="text"
                name="numTransportDocument"
                value={numTransportDocument}
                onChange={(e) => setNumTransportDocument(e?.target?.value)}
                maxLength={15}
                onInput={(e) => inputOnNumTransport(e)}
                onKeyUp={(e) => keyUpNumTransport(e)}
                onBlur={() =>
                  setNumTransportDocument(
                    numTransportDocument.replace(/[^a-zA-Z0-9 ]/g, "")
                  )
                }
              />
            </InputGroup>
          </FormControl>
        </div>
      )}

      {(zf !== null || freeZone_id) && formType?.value === 4 && followOrder?.value && (
        <div
          style={{
            width: "95%",
            margin: "0 auto 2rem auto"
          }}
        >
          <InputSelectMulti
            tag={"Seleccione los usuarios calificados"}
            data={userQualified}
            value={valueQualified}
            set={setValueQuialified}
            isDisabled={allQualifiers || !userQualified?.length}
          />
          <Checkbox
            size="sm"
            colorScheme="blue"
            value={allQualifiers}
            onChange={() => setAllQualifiers(!allQualifiers)}
            isDisabled={valueQualified?.length > 0 || !userQualified?.length}
          >
            Todos los usuarios calificados
          </Checkbox>
          <h5
            style={
              !userQualified?.length
                ? { fontSize: "11px", color: "red" }
                : { display: "none" }
            }
          >
            *Todos los usuarios calificados se encuentran en seguimiento por
            este gestor, seleccione otro gestor.*
          </h5>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          margin: "0 auto 2rem auto"
        }}
      >
        <>
          <label
            style={{
              fontSize: "0.75rem",
              color: "#454546"
            }}
          >
            Comentario
          </label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="description"
            cols="50"
            rows="3"
            maxLength={MaxLength.monitoring.monitoring.initial_description}
            style={{
              fontSize: "0.8rem",
              resize: "none",
              border: "1px solid #bababa",
              borderRadius: "5px",
              outline: "none",
              padding: "0 10px"
            }}
          ></textarea>
        </>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          disabled={formType?.value && (formType?.value === 6 || formType?.value === 5 || formType?.value === 4) && userQualified?.length === 0}
          onClick={() => {
            handleSubmit();
            // onClose();
          }}
        >
          Crear
        </ButtonStyled>
      </Flex>
    </>
  );
};
