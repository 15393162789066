import { Button } from "@chakra-ui/react";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import { ModalGeneric } from "components/modal/ModalGeneric";
import environment from "constants/apiConst";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useCallback, useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { __Post, __PostFormData, __PostJsonDataPage } from "request/Petitions/__Post";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import {
  cabeceraIntegrationBox,
  TitleIntegrationCorrected
} from "../Arrays/ArrayTitles";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import TbodyBoxCorrected from "../Table/TbodyBoxCorrected";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { isArray } from "utils/type-check-utils";

const FormsCorrected = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [searcheAdvanced, setSearchAdvanced] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const [pageSearch, setPageSearch] = useState(1);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [maxPageSearch, setMaxPageSearch] = useState(1);

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
  });
  const { searchInformacion, valueQualified } = values;
  const {
    update,
    changeStatus,
    onCloseModal,
    isOpenAnular,
    dataToAnular,
    setRequestChangeStatusBoxCorrected,
  } = useContext(IntegracionBoxCrudContex);

  useEffect(() => {
    console.log({ valueQualified });
  }, [valueQualified]);

  /* A function that is not being used. */
  const changePage = (index) => {
    setPage(1);
    setMaxPage(currentPage ? currentPage[index]?.last_page : 1);
  };

  const backSearch = () => {
    reset();
    setChangeViewSearch(false);
  };

  const GetIntegration = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __PostJsonDataPage(
        environment.GET_iNTEGRATION_BOX_CORRECTED,
        token,
        page
      );
      setCurrentPage(res?.data?.status?.data);
      setMaxPage(res?.data?.status?.data[0]?.last_page);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, token, freeZone_id]);

  useEffect(() => {
    GetIntegration();
  }, [page, GetIntegration, update, freeZone_id]);

  const handleAnular = (status) => {
    const dataAnular = new FormData();
    dataAnular.append("id", dataToAnular?.integration_certificate_id);
    dataAnular.append("status", status);
    changeStatus(dataAnular);
    onCloseModal();
  };

  useEffect(() => {
    setRequestChangeStatusBoxCorrected(
      environment.CHANGE_STATUS_INTEGRATION_CORRECTED
    );
  }, [setRequestChangeStatusBoxCorrected]);

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __Post(
        environment.GET_INTEGRATION_BOX_CORRECTED_SEARCH,
        token,
        informacion,
        pageSearch,
      );
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        console.log(res);
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPageSearch(res?.data?.status?.data?.last_page);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  useEffect(() => {
    const data = {
      ...(searchInformacion && { search: searchInformacion, advanced_search_corrected: true }),// si searchInformacion tiene un valor lo agrega a la petición
      ...searcheAdvanced,
      ...(isArray(valueQualified) && valueQualified?.length !== 0
        ? { qualifiedUser_id: valueQualified?.map((item) => item?.value) }
        : valueQualified?.value && { qualifiedUser_id: valueQualified?.value })
    }

    if (Object.keys(data).length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(data);
      return;
    }
    setChangeViewSearch(false);
    GetIntegration();
  }, [searchInformacion, searcheAdvanced, valueQualified]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenAnular}
        onClose={onCloseModal}
        title={`¿Esta seguro que desea anular el certificado de integración Nº ${dataToAnular?.integration_certificate_code}? 
        <br> <span style="color: red">Importante:</span> El Documento quedara inutilizable y se desharán todos los cambios correspondientes en el inventario`}
        size={"2xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            style={{ background: "#41495A", color: "white" }}
            onClick={() => handleAnular("ANULADO")}
            ml={3}
          >
            Anular
          </Button>
        </div>
      </ModalGeneric>

      <Title style={{ marginTop: 30, marginBottom: "5%" }}>
        Corregidos de certificado de integración
      </Title>

      <div className="container ">
        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          allAccess={allAccess}
          myPermission={myPermission}
          setValues={setValues}
        />
        {/* <ExportDetail onOpen={onOpenExport} /> */}
      </div>

      <HeaderSearch>
        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div className="px-5">
                <div>
                  <TableOrder
                    thead={TitleIntegrationCorrected}
                    data={currentPageTwo}
                    setData={setCurrentPageTwo}
                    loading={loading}
                    tbodyData={TbodyBoxCorrected}
                  />

                  <Pagination
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    loading={loading}
                  />
                </div>
              </div>
            )
          )
          : (
            <div>
              <ComponentAccordion
                cabecera={cabeceraIntegrationBox}
                currentPage={currentPage}
                TitleHeader={TitleIntegrationCorrected}
                setCurrentPage={setCurrentPage}
                loading={loading}
                TBodyEntryMovement={TbodyBoxCorrected}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                changePage={changePage}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default FormsCorrected;
