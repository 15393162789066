/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Spacer,
  Text
} from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { LabelGroup, AcordionGroup } from "pages/RefProductInter/LabelGroup";
import {
  __Group_And_Permisos,
  __PermisosSinPag,
  __ShowRoles,
  __UserCalificadosSp
} from "request/configuration/__Roles";
import { ButtonStyled } from "styled/Buttons.styled";

import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import "../Style/stilos.css";
import { UserCaRoles } from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { MaxLength } from "utils/MaxLength";

export const RolForm = ({ onCloseRol }) => {
  const [loading, setLoading] = useState(false);
  const [subModulos, setSubmodulos] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [allper, setAllPer] = useState(false);
  const [permisosAct, setPermisosAct] = useState([]);
  const [cantPerm, setCantPerm] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [valor, setValor] = useState([]);
  const [usuariosRol, setUsuariosRol] = useState(null);
  const [message, setMessage] = useState(false);
  const [defectoV, setDefectoV] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [counterPermises, setcounterPermises] = useState(null);

  const [values, handleInputChange, reset, setValues] = useForm({
    name_rol: "",
    description_rol: ""
  });
  const { name_rol, description_rol } = values;

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const { dataToEdit, onCloseModal, createDataLicense, editData } = useContext(
    ConfigurationCRUDContext
  );

  useEffect(() => {
    userCalificados();
    modulos();
    permisosSinPag();
  }, []);

  const modulos = async () => {
    setLoading(true);
    try {
      const res = await __Group_And_Permisos(token);
      setSubmodulos(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const permisosSinPag = async () => {
    try {
      const res = await __PermisosSinPag(token);
      if (res?.data?.status?.code === 200) {
        setCantPerm(res?.data?.status?.data.length);
      }
      console.log(cantPerm);
    } catch (error) {
      console.log(error);
    }
  };

  const userCalificados = async () => {
    try {
      const res = await __UserCalificadosSp(token);
      // console.log(res.data.status.message);
      if (res.data.status.message === "No hay resultado.") {
        setMessage(true);
      } else {
        setUsuarios(res?.data?.status?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (parseInt(counterPermises) === parseInt(cantPerm)) {
      setAllPer(true);
    } else {
      setAllPer(false);
    }
  }, [counterPermises, cantPerm]);

  const initialpos = async () => {
    try {
      const res = await __ShowRoles(token, dataToEdit.id);
      const infoDate = res?.data?.status?.data?.permissions;
      infoDate.map((items) => setPermisos((prev) => [...prev, items.id]));
      const infoUser = res?.data?.status?.data.users;
      infoUser.map((items) => setValor((prev) => [...prev, items.id]));
      setDefectoV(res?.data?.status?.data.users);
      setPermisosAct(infoDate);

      const countPerm = res?.data?.status?.data?.permissions.length;
      setcounterPermises(countPerm);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      initialpos();
      permisosSinPag();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleAceptar = () => {
    const notice = {
      name_rol,
      description_rol,
      users: usuariosRol ? valor.concat(usuariosRol) : valor
    };
    if (name_rol === "") {
      openAlert("Ingrese el nombre del rol", "error");
    } else {
      if (dataToEdit) {
        if (allper) {
          delete notice.guard_name;
          delete notice.permissions;
          const data = {
            ...notice,
            permissions_all: 1,
            id: dataToEdit.id
          };
          editData(data);
        } else {
          const data = {
            ...notice,
            permissions: permisos,
            id: dataToEdit.id
          };
          editData(data);
        }
      } else {
        if (allper) {
          const data = {
            ...notice,
            permissions_all: 1
          };
          createDataLicense(data);
        } else {
          const data = {
            ...notice,
            permissions: permisos
          };
          createDataLicense(data);
        }
      }
    }
  };

  const allPerson = (e) => {
    setAllPer(e.target.checked);
  };

  const actState = (e) => {
    if (!e.target.checked) {
      const remover = parseInt(e.target.value);
      removeItemFromArr(valor, remover);
    } else {
      setValor((prev) => [...prev, parseInt(e.target.value)]);
    }
  };

  const removeItemFromArr = (arr, item) => {
    const i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  };

  return (
    <>
      <Text fontSize="xl" style={{ fontWeight: 600 }}>
        {dataToEdit ? " Editar Rol" : "Crear Rol"}
      </Text>
      <Divider my={5} />

      <form onSubmit={hanldeSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LabelGroup
            name={"name_rol"}
            onChange={handleInputChange}
            value={name_rol}
            tag={"Nombre del Rol"}
            size={45}
            desicion={false}
            maxLength={MaxLength.config.roles.name_rol}
          />
          <LabelGroup
            name={"description_rol"}
            onChange={handleInputChange}
            value={description_rol}
            tag={"Descripción"}
            size={45}
            desicion={false}
            maxLength={MaxLength.config.roles.description_rol}
          />
        </div>
        <div className="container">
          <div className="row">
            <LabelStyled className="my-3">
              Modulos{" "}
              <span className="d-flex ">
                <Checkbox isChecked={allper} onChange={allPerson}>
                  Todos los permisos{" "}
                </Checkbox>
              </span>{" "}
              <br />
              <Text fontSize="md" mb={1} style={{ fontWeight: 600 }}>
                &nbsp; Permisos asignados: {allper ? cantPerm : permisos.length}
                /{cantPerm}
              </Text>
            </LabelStyled>
            <div className="col">
              {subModulos?.map((item, index) => (
                <AcordionGroup
                  subTitle={item}
                  setPermisos={setPermisos}
                  allper={allper}
                  permisosAct={permisosAct}
                  permisos={permisos}
                  setPermisosAct={setPermisosAct}
                  key={index}
                />
              ))}
            </div>

            <div className="col">
              {/* {usuarios ? ( */}
              <div className="d-flex justify-content-center">
                <ButtonStyled
                  className="d-flex justify-content-center "
                  onClick={() => setAddUser(!addUser)}
                >
                  Agregar Usuario
                </ButtonStyled>
              </div>
              {/* ) : (
                ""
              )} */}

              {addUser
                ? (
                  !message
                    ? (
                      <UserCaRoles
                        data={usuarios}
                        tag={"Usuarios"}
                        size={100}
                        setValor={setUsuariosRol}
                        ediciones={dataToEdit}
                        valor={valor}
                      />
                    )
                    : (
                      <div className="text-center mt-5">
                        <span className="h5 colorFont">
                      No hay usuarios disponibles
                        </span>
                        <Center>
                          <hr className="sobreNo mt-1" />
                        </Center>
                      </div>
                    )
                )
                : (
                  ""
                )}

              {dataToEdit
                ? (
                  <div
                    className={
                      dataToEdit ? (defectoV.length === 0 ? "" : "user") : ""
                    }
                  >
                    {defectoV.map((items, index) => (
                      <div>
                        <Checkbox
                          className="mx-3"
                          value={items.user_id}
                          onChange={actState}
                          defaultIsChecked={true}
                        >
                          {items.nickname}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                )
                : (
                  ""
                )}
            </div>
          </div>
        </div>
        <Flex className="mt-5">
          <Spacer />
          <Box>
            <ButtonStyled
              bgColor={"#bababa"}
              onClick={onCloseRol || onCloseModal}
            >
              Cerrar
            </ButtonStyled>
            <ButtonStyled type="submit" onClick={handleAceptar}>
              {dataToEdit ? " Editar Rol" : "Crear Rol"}
            </ButtonStyled>
          </Box>
        </Flex>
      </form>
    </>
  );
};
