import { Box, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaDotCircle } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { MinCardMximu } from 'styled/CardTotalesDash'


const TablePendingFormsDue = ({ data }) => {

  return (
    <MinCardMximu>
      <TableContainer>
        <Box m={2} justifyContent={'center'} display={'flex'} p={2} >
          <Text as="caption">
            <span id="tituloEstadistica">Formularios pendientes por vencer</span>
          </Text>
        </Box>
        <Table variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Estado</Th>
              <Th>Usuario Calificado</Th>
              <Th isNumeric>Numero de Formulario</Th>
              <Th isNumeric>Fecha Primer Pase</Th>
              <Th isNumeric>Dias que lleva</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.map((item) => (
              <Tr>
                <Td><FaDotCircle color={item?.days <= 5 ? 'red'
                  : item?.days <= 10 ? '#eba301' : 'green'} /></Td>
                <Td>{item?.name_qualified}</Td>
                <Td isNumeric>
                  <NavLink
                    to={`/FormShow?FormEntry=${item?.entry_form_id}`}>
                    <span className="formNumber">{item?.code_entryForm}</span>
                  </NavLink>
                </Td>
                <Td isNumeric>{item?.date_statusEntryPass}</Td>
                <Td isNumeric style={{ color: item?.days <= 5 ? 'red' : item?.days <= 10 ? '#eba301' : 'green' }}>{item?.days}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </MinCardMximu>
  )
}

export default TablePendingFormsDue
