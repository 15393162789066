import React, { useContext } from 'react'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'
import { AuthContext } from 'context/AuthContext'
import { AiOutlineFileSync } from 'react-icons/ai'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { HiOutlineDocumentSearch } from 'react-icons/hi'
import environment from 'constants/apiConst'
import { __Post } from 'request/Petitions/__Post'
import { useGlobalContext } from 'context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
export const OptionsAutorizados = ({ data, handlePresent }) => {
  const { authUser } = useContext(AuthContext)
  const { requestData } = useGlobalContext()
  const [openAlert] = AlertErrorAlert()
  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleCorrected = () => {
    const config = {
      data: {
        dispatchPass_id: data?.id
      },
      Endpoint: environment.POST_PASS_CORRECTIONS,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutate(config, {
      onSuccess: data => {
        // navigate("/corrected-pass-exit");
        const resData = data?.data?.status?.data
        if (resData?.id) {
          navigate(
            `/DispathcCorrectionShow?Dispatch=${resData?.id}&Action=Show`
          )
        }
        return openAlert(data?.data?.status?.message, 'success')
      }
    })
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { EntryPass, OutputPass } = localPermissions

  const { changeStatus: changeStatusEntryPass } = EntryPass
  const { changeStatus: changeStatusOutputPass } = OutputPass

  return (
    <>
      {!authUser.is_qualified_user &&
        (hasCommonElements(permissionList, [
          ...changeStatusOutputPass,
          ...changeStatusEntryPass
        ]) ||
          authUser.admin === 1) && (
          <>
            <MenuItemStyled onClick={() => handlePresent('DEVUELTO')} py={3}>
              <IconMenuRUD color={'red'} as={AiOutlineFileSync} />
              Devolver
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}

      {data.status_entryPass !== 'AUTORIZADO' &&
        (hasCommonElements(permissionList, [
          ...changeStatusOutputPass,
          ...changeStatusEntryPass
        ]) ||
          authUser.admin === 1) && (
          <MenuItemStyled onClick={handleCorrected} py={3}>
            <IconMenuRUD color='#F9A621' as={HiOutlineDocumentSearch} />
            Corregir
          </MenuItemStyled>
        )}
    </>
  )
}
