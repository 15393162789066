
import { DATA_FORMS_TYPES } from "redux/types/dataFormTypes";

export const setFormData = (payload) => ({
  type: DATA_FORMS_TYPES.FORM_DATA,
  payload
});

export const clearFormData = () => ({
  type: DATA_FORMS_TYPES.CLEAR_FORMDATA
});

export const updateFormData = (payload) => ({
  type: DATA_FORMS_TYPES.UPDATE_FORM_DATA,
  payload
});

export const getFormData = (state) => state?.CurrentForm?.data;
