/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabelStyled } from "../../../components/addProduct/CreateTemplate.styled";
import { FormInput } from "../../RefProductInter/Stilos";
import { GroupPerm } from "../InputSelectsConfig/InputSelectPermi";
import { __Group_All } from "request/configuration/__Roles";
import { useSelector } from "react-redux";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

export const FormPermisos = () => {
  const [info, setInfo] = useState([]);
  const [pipote, setPipote] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { editData, onCloseModal, createData, dataToEdit } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    name_permission: "",
    description_permission: ""
  });
  const { name_permission, description_permission } = values;

  useEffect(() => {
    const userC = async () => {
      try {
        const res = await __Group_All(token);
        setInfo(res?.data?.status?.data);
      } catch (error) {}
    };
    userC();
  }, []);

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      const userC = async () => {
        try {
          const res = await __Group_All(token);
          const datosRe = res?.data?.status?.data;
          const hipotesis = datosRe.find(
            (element) => element.id === dataToEdit.permissionGroup_id
          );
          setPipote({
            label: hipotesis.name_permissionGroup,
            value: hipotesis.id
          });
        } catch (error) {}
      };
      userC();
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (name_permission?.length === 0) { return openAlert("El campo nombre del permiso es obligatorio", "error"); }
    const result = {
      ...values,
      permissionGroup_id: pipote.value
    };

    if (dataToEdit) {
      const resultEdit = {
        ...result,
        id: dataToEdit.id
      };
      editData(resultEdit);
    } else {
      createData(result);
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      {/* {" "}
      <Text fontSize="xl" mb={3} style={{ fontWeight: 600 }}>
        Permisos
      </Text>
      <Divider mb={1} /> */}
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar permiso" : "Crear permiso"}
      </Text>
      <Divider mb={3} />
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <GroupPerm
            data={info}
            tag={"Módulo"}
            size={45}
            set={setPipote}
            state={pipote}
          />
          <LabelGroup
            name={"name_permission"}
            value={name_permission}
            tag={"Nombre del permiso"}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            size={45}
            desicion={false}
            maxLength={MaxLength.config.permissionsRule.name_permission}
          />
        </div>
        <div>
          <LabelStyled>Descripcion</LabelStyled>
          <FormInput
            name="description_permission"
            value={description_permission}
            onChange={handleInputChange}
            type="text"
            maxLength={MaxLength.config.permissionsRule.description_permission}
          />
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};
