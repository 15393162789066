import React, { useContext } from 'react'
import { MenuItem } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { IconMenuRUD } from 'styled/Icons.styled'
import { HrDividerNoMargin } from 'styled/Line.styled'
import { RiDeviceRecoverFill } from 'react-icons/ri'
import { BiShow } from 'react-icons/bi'
import { MdContentCopy } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

import FormCrudCRUDContext from 'context/FormCrudContex'
import { AuthContext } from 'context/AuthContext'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`
const options = {
  Ingreso: 'FMMI',
  Salida: 'FMMS',
  'Corregido de ingreso': 'FMMIC',
  'Corregido de salida': 'FMMSC',
  'Ajuste saldo de ingreso': 'FMMIC',
  'Ajuste saldo de Salida': 'FMMSC'
}

export const OptionsPresentadoForm = ({ data }) => {
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const { authUser } = useContext(AuthContext)
  const {
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    changeStatusCorregido,
    onOpenRecuperar,
    setDataRecuperar
  } = useContext(FormCrudCRUDContext)
  const navigate = useNavigate()
  const [openAlert] = AlertErrorAlert()
  const token = useSelector(state => state.auth.dataUser.token)

  const handleStatus = async status => {
    const infoEntry = {
      id: data.id,
      status_entryForm: status
    }

    const infoExit = {
      id: data.id,
      status_outputForm: status
    }
    const CorrectinfoEntry = {
      id: data.id,
      status_entryFormCorrected: status
    }
    const CorrectinfoExit = {
      id: data.id,
      status_outputFormCorrected: status
    }

    if (status === 'REVISADO') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type', 1)
      body.append('type_of', options[movement])
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if (res?.data?.status?.code === 200) {
        if (movement === 'Ingreso') {
          changeStatus(infoEntry)
          navigate(`/CheckFormEntry?Number=${data.id}`)
          // openAlert(`El formulario está en revisión`, "success");
        } else if (movement === 'Salida') {
          changeStatus(infoExit)
          navigate(`/CheckForm?Number=${data.id}`)
        }
        if (
          movement === 'Corregido de ingreso' ||
          movement === 'Ajuste saldo de ingreso'
        ) {
          changeStatusCorregido(CorrectinfoEntry)
          navigate(`/CheckCorrectEntry?ShowEntry=${data.id}`)
          openAlert(`El formulario está en revisión`, 'success')
        } else if (
          movement === 'Corregido de salida' ||
          movement === 'Ajuste saldo de Salida'
        ) {
          changeStatusCorregido(CorrectinfoExit)
          navigate(`/CheckCorrectOutput?ShowOutput=${data.id}`)
        }
        return
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    } else if (status === 'RECUPERADO') {
      const body = new FormData()
      body.append('form_id', data?.id)
      body.append('type', 1)
      body.append('type_of', options[movement])
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if (res?.data?.status?.code === 200) {
        setTimeout(async () => {
          body.set('type', 0)
          console.log('timeout')
          const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
        }, 5000)
        onOpenRecuperar()
        setDataRecuperar(data)
      }
      if (res?.data?.status?.code === 400) {
        return openAlert(res?.data?.status?.message, 'error')
      }
    } else {
      if (movement === 'Ingreso') {
        changeStatus(infoEntry)
      } else {
        changeStatus(infoExit)
      }
    }
  }

  const handleCopy = () => {
    setDataToCopy(data)
    onOpenCopy()
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { Outputform, Entryform } = localPermissions

  const { changeStatus: changeStatusEntryForm, copy: copyEntryForm } = Entryform
  const { changeStatus: changeStatusOutputForm, copy: copyOutputForm } =
    Outputform

  return (
    <>
      {(hasCommonElements(permissionList, [
        ...changeStatusEntryForm,
        ...changeStatusOutputForm
      ]) || authUser.admin === 1)&& (
        <MenuItemStyled onClick={() => handleStatus('RECUPERADO')} py={3}>
          <IconMenuRUD as={RiDeviceRecoverFill} />
          Recuperar
        </MenuItemStyled>
      )}
      <HrDividerNoMargin />

      {authUser.is_qualified_user !== 1 &&
       ( hasCommonElements(permissionList, [
          ...changeStatusEntryForm,
          ...changeStatusOutputForm
        ]) || authUser.admin === 1)&& (
          <MenuItemStyled onClick={() => handleStatus('REVISADO')} py={3}>
            <IconMenuRUD color='#58B158' as={BiShow} />
            Revisar
          </MenuItemStyled>
        )}
      <HrDividerNoMargin />

      {authUser.is_qualified_user !== 1 &&
        (movement === 'Ingreso' || movement === 'Salida') &&
       ( hasCommonElements(permissionList, [
          ...copyEntryForm,
          ...copyOutputForm
        ]) || authUser.admin === 1)&& (
          <>
            <MenuItemStyled onClick={handleCopy} py={3}>
              <IconMenuRUD color='#03C39A' as={MdContentCopy} />
              Copiar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}
    </>
  )
}
