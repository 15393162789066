import * as React from "react"

const SvgComponent = (props) => (
  <svg
    className="animacion"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    width="1em"
    height="1em"
    {...props}
  >
    <g
      className="animados"
      style={{
        transformOrigin: "427.65px 257.08px"
      }}
    >
      <path
        d="M242.91 350s-6.16 2.68 1.35 15.72 12.58 19.43 12.58 19.43 1.37 3-1.22 3.66-8.4 0-9.4 3.25 1.53 6.39 6.56 8.89 15.85 8.38 16.83 8.92 2 1.92.26 2.62-14.59 2.72-13.27 8.78 16.48 10.71 18.13 11.26 2.07 1.2 2 2.09-4.48 1.17-8.12 1.89-7.74 2.2-6.69 5.63 10.24 10.29 31.75 11.29l10-.18 5-8.71c9.57-19.29 8.05-30.65 5.56-33.24s-5.77.27-8.17 3.11-4.77 6.54-5.59 6.18-1.2-1.05-.88-2.75 3.62-17.21-1-21.31-12.68 6.19-14.11 7.33-2.18-.41-2.17-1.51.12-13.42.38-19-1.12-9.32-4.58-10.06-5.61 4.67-7.41 6.64-3.79-.71-3.79-.71-3.12-7.54-10.87-20.44-13.2-8.68-13.2-8.68"
        style={{
          fill: "#ebebeb",
          transformOrigin: "278.319px 401.435px"
        }}
        className="animados"
      />
      <path
        d="M304.29 455.6c-5.66-9.5-15.42-26.55-23.88-41.14s-16.12-27.79-21.72-37.32c-2.81-4.75-5.07-8.6-6.65-11.27l-1.81-3.07-.46-.8-.15-.28s.07.09.18.26.28.45.49.79l1.85 3c1.61 2.64 3.91 6.47 6.74 11.22 5.65 9.5 13.35 22.69 21.8 37.27s18.19 31.66 23.8 41.19"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "277.05px 408.66px"
        }}
        className="animados"
      />
      <path
        d="M254.31 393.29a3.3 3.3 0 0 1 .7.16c.45.12 1.1.31 1.89.56 1.59.49 3.76 1.23 6.15 2.08l6.1 2.21 1.85.7a3.89 3.89 0 0 1 .67.27 3 3 0 0 1-.71-.15c-.45-.12-1.09-.32-1.88-.57-1.59-.51-3.75-1.27-6.14-2.13l-6.11-2.16-1.85-.65a3 3 0 0 1-.67-.32Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "262.99px 396.28px"
        }}
        className="animados"
      />
      <path
        d="M272.32 398.56c-.08 0 1.24-4.58 2.95-10.16s3.16-10.1 3.24-10.08-1.24 4.58-2.95 10.17-3.16 10.09-3.24 10.07Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "275.415px 388.44px"
        }}
        className="animados"
      />
      <path
        d="M284.29 421.55a4.33 4.33 0 0 1 .38-.64l1.1-1.67c.93-1.42 2.2-3.38 3.52-5.6s2.46-4.27 3.27-5.75l1-1.77a3.77 3.77 0 0 1 .38-.63 3.35 3.35 0 0 1-.27.69c-.2.43-.49 1.05-.87 1.81-.76 1.51-1.87 3.58-3.2 5.81s-2.63 4.17-3.61 5.56c-.49.69-.89 1.25-1.18 1.62a3.22 3.22 0 0 1-.52.57Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "289.115px 413.52px"
        }}
        className="animados"
      />
      <path
        d="M267 420.38a2.41 2.41 0 0 1 .68 0l1.85.06c1.57 0 3.73.15 6.12.29s4.54.26 6.11.38l1.84.15a2.26 2.26 0 0 1 .68.1 3.6 3.6 0 0 1-.68 0l-1.86-.05c-1.56-.06-3.72-.16-6.11-.29s-4.55-.27-6.11-.39l-1.85-.15a3.16 3.16 0 0 1-.67-.1Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "275.64px 420.866px"
        }}
        className="animados"
      />
      <path
        d="M275 442a4.53 4.53 0 0 1 .87 0l2.38.06c2 .06 4.78.17 7.84.31s5.83.28 7.83.4l2.38.15a5.74 5.74 0 0 1 .86.1 4 4 0 0 1-.87 0l-2.38-.06c-2-.07-4.78-.17-7.84-.31s-5.83-.29-7.83-.4l-2.37-.16a5.78 5.78 0 0 1-.87-.09Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "286.08px 442.511px"
        }}
        className="animados"
      />
      <path
        d="M296.59 442.85a5.08 5.08 0 0 1 .41-.85c.34-.56.79-1.29 1.32-2.18 1.11-1.84 2.64-4.38 4.24-7.24s3-5.48 4-7.39c.47-.92.87-1.68 1.17-2.26a4.9 4.9 0 0 1 .47-.81 5.48 5.48 0 0 1-.36.86c-.24.55-.61 1.34-1.09 2.31-.94 1.93-2.3 4.58-3.91 7.44s-3.16 5.4-4.32 7.21c-.58.91-1.06 1.63-1.41 2.13a5.92 5.92 0 0 1-.52.78Z"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "302.395px 432.485px"
        }}
        className="animados"
      />
      <path
        d="M561.31 93.35a30.78 30.78 0 0 1-.35 8.26l6.06 4.7-5.35 11.5-7.5-1.62a31 31 0 0 1-6.1 5.6l1 7.6-11.93 4.35-4.14-6.44a31.29 31.29 0 0 1-8.27-.36L520 133l-11.51-5.36 1.62-7.5a30.91 30.91 0 0 1-5.59-6.09l-7.61 1-4.35-11.92L499 99a30.78 30.78 0 0 1 .35-8.26L493.31 86l5.35-11.5 7.5 1.62a30.71 30.71 0 0 1 6.1-5.6l-1-7.61 11.93-4.35 4.17 6.44a31.29 31.29 0 0 1 8.27.36l4.69-6.07 11.51 5.36-1.62 7.49a31.25 31.25 0 0 1 5.59 6.1l7.61-1 4.35 11.92-6.45 4.16ZM543.15 92a13.61 13.61 0 1 0-8.15 17.48A13.6 13.6 0 0 0 543.15 92Z"
        style={{
          fill: "#e0e0e0",
          fillRule: "evenodd",
          transformOrigin: "530.16px 96.15px"
        }}
        className="animados"
      />
      <path
        d="M625.71 133.23a24.26 24.26 0 0 1-.28 6.55l4.81 3.72-4.24 9.12-5.94-1.29a24.92 24.92 0 0 1-4.83 4.44l.77 6-9.45 3.45-3.29-5.11a24.71 24.71 0 0 1-6.55-.28l-3.72 4.8-9.11-4.24 1.28-5.94a24.57 24.57 0 0 1-4.43-4.83l-6 .77-3.48-9.39 5.11-3.3a24.61 24.61 0 0 1 .28-6.54l-4.8-3.72 4.24-9.12 5.94 1.29a24.61 24.61 0 0 1 4.83-4.44l-.77-6 9.45-3.45 3.29 5.11a24.71 24.71 0 0 1 6.55.28l3.72-4.81 9.11 4.25-1.28 5.94a24.59 24.59 0 0 1 4.43 4.83l6-.77 3.44 9.44-5.11 3.29Zm-14.38-1a10.79 10.79 0 1 0-6.44 13.77 10.78 10.78 0 0 0 6.44-13.81Z"
        style={{
          fill: "#ebebeb",
          fillRule: "evenodd",
          transformOrigin: "601.02px 135.47px"
        }}
        className="animados"
      />
      <path
        d="M560.68 172a21.13 21.13 0 0 1-.24 5.57l4.08 3.16-3.6 7.76-5-1.1a21.08 21.08 0 0 1-4.11 3.77l.65 5.13-8 2.93-2.8-4.34a20.73 20.73 0 0 1-5.57-.25l-3.16 4.09-7.75-3.61 1.09-5a21.05 21.05 0 0 1-3.77-4.1l-5.12.65-2.93-8 4.34-2.8a21.14 21.14 0 0 1 .24-5.57l-4.09-3.16 3.61-7.75 5 1.09a21.08 21.08 0 0 1 4.11-3.77l-.65-5.12 8-2.93 2.8 4.34a21.14 21.14 0 0 1 5.57.24l3.16-4.09 7.75 3.61-1.09 5.05a21.11 21.11 0 0 1 3.77 4.11l5.12-.66 2.93 8-4.34 2.75Zm-12.24-.89A9.17 9.17 0 1 0 543 182.9a9.17 9.17 0 0 0 5.44-11.76Z"
        style={{
          fill: "#f5f5f5",
          fillRule: "evenodd",
          transformOrigin: "539.735px 173.935px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "240.54px 291.985px",
          transform: "rotate(180deg)"
        }}
        className="animados"
        d="M224.51 289.79h32.06v4.39h-32.06z"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "240.54px 284.555px",
          transform: "rotate(180deg)"
        }}
        className="animados"
        d="M224.51 282.36h32.06v4.39h-32.06z"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "249.625px 299.925px",
          transform: "rotate(180deg)"
        }}
        className="animados"
        d="M242.69 297.73h13.87v4.39h-13.87z"
      />
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "375px 456.57px"
      }}
    >
      <path
        d="M711.61 456.57c0 .14-150.72.26-336.59.26s-336.63-.12-336.63-.26 150.69-.26 336.63-.26 336.59.11 336.59.26Z"
        style={{
          fill: "#263238",
          transformOrigin: "375px 456.57px"
        }}
        className="animados"
      />
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "509.42px 432.365px"
      }}
    >
      <path
        d="M514.82 408.55a9.05 9.05 0 0 0-5.69 4.22 15.84 15.84 0 0 0-2.11 6.92c-.45 4.69 1.17 9.86 3.19 14.1 4.56-2.54 6.69-8.22 7.59-11.16 1.43-4.66 1.17-13.94-3-14.08"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "512.765px 421.17px"
        }}
        className="animados"
      />
      <path
        d="M504.13 440.39a7.73 7.73 0 0 0-.92-7.7 10.26 10.26 0 0 0-6.91-3.89 4.33 4.33 0 0 0-3.57.77 3.31 3.31 0 0 0-.74 3.27 6.69 6.69 0 0 0 1.92 2.9c2.89 2.8 6.21 5 10.22 4.65"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "498.297px 434.56px"
        }}
        className="animados"
      />
      <path
        d="M506.09 455.47a5.05 5.05 0 0 0 .06-1c0-.69.05-1.57.08-2.64a27.36 27.36 0 0 0-.9-8.67 16.65 16.65 0 0 0-4.51-7.43 11.78 11.78 0 0 0-2.15-1.55 6.17 6.17 0 0 0-.65-.32c-.15-.07-.23-.1-.24-.08a16.59 16.59 0 0 1 2.87 2.13 17.08 17.08 0 0 1 4.35 7.35 29.15 29.15 0 0 1 1 8.57v2.64a5.47 5.47 0 0 0 .09 1Z"
        style={{
          fill: "#263238",
          transformOrigin: "502.027px 444.622px"
        }}
        className="animados"
      />
      <path
        d="M514.39 415.63a3.3 3.3 0 0 0-.13.4l-.32 1.17c-.27 1-.65 2.48-1.09 4.3-.9 3.64-2.07 8.68-3.23 14.28s-2.1 10.7-2.73 14.4c-.32 1.85-.56 3.34-.71 4.38-.07.49-.13.89-.17 1.19a2 2 0 0 0 0 .43 1.49 1.49 0 0 0 .11-.41c.06-.31.13-.7.23-1.19l.82-4.36c.68-3.68 1.66-8.77 2.82-14.37s2.29-10.64 3.13-14.3c.41-1.79.75-3.26 1-4.32.11-.49.19-.88.26-1.18a1.47 1.47 0 0 0 .01-.42Z"
        style={{
          fill: "#263238",
          transformOrigin: "510.199px 435.905px"
        }}
        className="animados"
      />
      <path
        d="M510.2 444.59a18.08 18.08 0 0 1 12.41-10.91c1.42-.34 3.19-.39 4 .8s.25 2.85-.55 4.07a15.45 15.45 0 0 1-15.85 6.45"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "518.603px 439.39px"
        }}
        className="animados"
      />
      <path
        d="M521.07 437.89a4.64 4.64 0 0 0-.9.18 10.36 10.36 0 0 0-1 .3 11.87 11.87 0 0 0-1.34.49 15 15 0 0 0-1.56.72c-.55.28-1.12.63-1.7 1a20.31 20.31 0 0 0-3.47 2.92 20 20 0 0 0-2.65 3.67 16.9 16.9 0 0 0-.85 1.79 13.23 13.23 0 0 0-.58 1.61 12.37 12.37 0 0 0-.36 1.38 6.38 6.38 0 0 0-.19 1.05 4.06 4.06 0 0 0-.09.91c.07 0 .21-1.29.86-3.26.18-.49.36-1 .62-1.58a17.74 17.74 0 0 1 .87-1.75 20.55 20.55 0 0 1 2.62-3.59 21.54 21.54 0 0 1 3.39-2.89c.58-.37 1.12-.73 1.67-1a16.33 16.33 0 0 1 1.52-.75c1.88-.85 3.16-1.09 3.14-1.2Z"
        style={{
          fill: "#263238",
          transformOrigin: "513.725px 445.9px"
        }}
        className="animados"
      />
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "292.64px 208.77px"
      }}
    >
      <path
        d="M294.4 213.32a18 18 0 0 1 2.74-4l-1.53-4.2 6.22-4 3.19 3.13a18 18 0 0 1 4.74-.86l1.89-4.05 7.22 1.56v4.47a17.51 17.51 0 0 1 4 2.75l4.2-1.53 4 6.21-3.13 3.2a18 18 0 0 1 .87 4.74l4 1.89-1.56 7.23h-4.47a18.27 18.27 0 0 1-2.74 4l1.52 4.2-6.21 4-3.15-3.06a18.36 18.36 0 0 1-4.74.86l-1.89 4.05-7.22-1.57-.05-4.46a18.66 18.66 0 0 1-4-2.75l-4.2 1.53-4-6.22 3.13-3.19a18.36 18.36 0 0 1-.86-4.74l-4.05-1.89 1.57-7.22h4.47Zm8.53 6.29a7.93 7.93 0 1 0 9.43-6.07 7.92 7.92 0 0 0-9.43 6.07Z"
        style={{
          fill: "#5ea3ff",
          fillRule: "evenodd",
          transformOrigin: "310.565px 221.625px"
        }}
        className="animados"
      />
      <path
        d="M282.7 180.41a9 9 0 0 1 1.33-1.93l-.74-2 3-1.95 1.54 1.52a8.89 8.89 0 0 1 2.31-.42l.91-2 3.51.76v2.17a8.88 8.88 0 0 1 1.92 1.33l2-.74 1.94 3-1.42 1.57a8.89 8.89 0 0 1 .42 2.31l2 .91-.76 3.51h-2.16a8.59 8.59 0 0 1-1.34 1.92l.75 2-3 1.94-1.55-1.52a8.9 8.9 0 0 1-2.3.42l-.92 2-3.5-.76v-2.16a8.65 8.65 0 0 1-1.93-1.34l-2 .75-1.95-3 1.52-1.55a8.9 8.9 0 0 1-.42-2.3l-2-.92.76-3.5h2.17Zm4.14 3.05a3.85 3.85 0 1 0 4.58-2.94 3.85 3.85 0 0 0-4.58 2.94Z"
        style={{
          fill: "#5ea3ff",
          fillRule: "evenodd",
          transformOrigin: "290.64px 184.42px"
        }}
        className="animados"
      />
      <path
        d="M256.33 204a11.5 11.5 0 0 1 1.71-2.46l-1-2.61 3.87-2.49 2 1.95a11.15 11.15 0 0 1 2.95-.54l1.17-2.51 4.49 1v2.78a11.15 11.15 0 0 1 2.46 1.71l2.61-1 2.49 3.87-1.95 2a11.3 11.3 0 0 1 .54 2.95l2.51 1.17-1 4.49h-2.78a11.15 11.15 0 0 1-1.71 2.46l1 2.61-3.87 2.49-2-1.95a11.3 11.3 0 0 1-2.95.54l-1.1 2.54-4.49-1v-2.77a11.5 11.5 0 0 1-2.46-1.71l-2.61 1-2.49-3.86 1.95-2a11.15 11.15 0 0 1-.54-2.95l-2.52-1.17 1-4.49h2.77Zm5.31 3.92a4.93 4.93 0 1 0 5.86-3.78 4.93 4.93 0 0 0-5.86 3.86Z"
        style={{
          fill: "none",
          transformOrigin: "266.395px 209.17px"
        }}
        className="animados"
      />
      <path
        d="M261.64 208v-.32a3.51 3.51 0 0 1 .28-.87 5 5 0 0 1 2.34-2.4 5.15 5.15 0 1 1 2.86 9.75 5.08 5.08 0 0 1-4.58-1.8 4.92 4.92 0 0 1-1.14-3.15 3.72 3.72 0 0 1 .11-.91c0-.2.08-.3.1-.3s-.05.44 0 1.2a4.84 4.84 0 0 0 1.21 2.92A4.72 4.72 0 0 0 271 210a4.73 4.73 0 0 0-6.57-5.28 4.88 4.88 0 0 0-2.31 2.15c-.35.66-.43 1.13-.48 1.13Z"
        style={{
          fill: "#263238",
          transformOrigin: "266.525px 209.051px"
        }}
        className="animados"
      />
      <path
        d="M256.33 204a3.37 3.37 0 0 1-.69.05l-2.08.06.08-.06c-.24 1.17-.55 2.68-.93 4.5l-.07-.15 2.52 1.16h.09v.1a11.12 11.12 0 0 0 .55 2.9v.1l-.07.07-1.94 2v-.23l2.5 3.85-.24-.08 2.6-1 .13-.05.09.09a11.49 11.49 0 0 0 2.42 1.67l.12.06v2.91l-.19-.22 4.5 1-.27.13 1.17-2.51.06-.14h.15a11.16 11.16 0 0 0 2.88-.52l.15-.05.1.11c.63.6 1.3 1.27 2 1.94h-.32l3.87-2.49-.11.3-.95-2.61-.05-.15.1-.11a11.09 11.09 0 0 0 1.67-2.41l.23-.14h2.78l-.25.21c.32-1.52.65-3 1-4.49l.14.29-2.51-1.18-.14-.06v-.16a10.69 10.69 0 0 0-.52-2.88l-.05-.15.11-.1 1.95-2v.3q-1.28-2-2.48-3.87l.28.1-2.61.94-.14.05-.1-.09a10.88 10.88 0 0 0-2.41-1.68l-.12-.06v-2.92l.17.21-4.48-1 .22-.11c-.42.88-.81 1.71-1.18 2.51l-.05.11h-.12a11.41 11.41 0 0 0-2.9.51h-.1l-.07-.07-2-2h.19l-3.89 2.46.05-.14c.37 1 .67 1.9.92 2.62v.05a16.06 16.06 0 0 0-1.77 2.4s0-.06.07-.17a5.07 5.07 0 0 1 .26-.5 10.07 10.07 0 0 1 1.32-1.84v.08l-1-2.6v-.08l.08-.06 3.84-2.52.1-.06.09.08 2 1.94h-.18a11.18 11.18 0 0 1 3-.56l-.16.11 1.16-2.53.06-.14h.16l4.5 1h.16V198.98l-.12-.2a11.65 11.65 0 0 1 2.52 1.73h-.24l2.61-1 .18-.07.1.17c.81 1.25 1.64 2.54 2.5 3.85l.1.17-.14.14-1.94 2 .06-.26a11.76 11.76 0 0 1 .55 3l-.15-.22 2.52 1.17.19.09-.05.2c-.31 1.47-.64 3-1 4.49v.2H276.5l.22-.14A11.85 11.85 0 0 1 275 217v-.27l1 2.61.07.2-.17.11-3.9 2.49-.17.11-.15-.15-2-1.95.26.06a11.48 11.48 0 0 1-3 .55l.21-.15c-.4.88-.81 1.74-1.17 2.52l-.09.18-.18-.05-4.49-1h-.19v-2.96l.12.2a11.77 11.77 0 0 1-2.51-1.75h.22l-2.61.94-.16.06-.09-.14c-.86-1.36-1.69-2.65-2.47-3.87l-.08-.13.11-.1 1.95-2v.18a11.82 11.82 0 0 1-.52-3l.09.13-2.51-1.19h-.1v-.11c.42-1.81.76-3.31 1-4.48V204H256.12Z"
        style={{
          fill: "#263238",
          transformOrigin: "266.495px 209.17px"
        }}
        className="animados"
      />
      <g
        style={{
          opacity: 0.8,
          transformOrigin: "310.565px 221.625px"
        }}
        className="animados"
      >
        <path
          d="M294.4 213.32a18 18 0 0 1 2.74-4l-1.53-4.2 6.22-4 3.19 3.13a18 18 0 0 1 4.74-.86l1.89-4.05 7.22 1.56v4.47a17.51 17.51 0 0 1 4 2.75l4.2-1.53 4 6.21-3.13 3.2a18 18 0 0 1 .87 4.74l4 1.89-1.56 7.23h-4.47a18.27 18.27 0 0 1-2.74 4l1.52 4.2-6.21 4-3.15-3.06a18.36 18.36 0 0 1-4.74.86l-1.89 4.05-7.22-1.57-.05-4.46a18.66 18.66 0 0 1-4-2.75l-4.2 1.53-4-6.22 3.13-3.19a18.36 18.36 0 0 1-.86-4.74l-4.05-1.89 1.57-7.22h4.47Zm8.53 6.29a7.93 7.93 0 1 0 9.43-6.07 7.92 7.92 0 0 0-9.43 6.07Z"
          style={{
            fill: "#fff",
            fillRule: "evenodd",
            transformOrigin: "310.565px 221.625px"
          }}
          className="animados"
        />
      </g>
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "387.3px 293.391px"
      }}
    >
      <path
        d="M476.44 205.78c-1.79-20.13-8.81-40.27-22.47-55.16s-34.51-23.85-54.44-20.49-37.53 20.4-38.93 40.57c-.71 10.71 2.86 21.34 2.27 32.05-1 18.52-14.29 34.19-29.15 45.27-12.81 9.56-26.62 13-35.8 26-16.47 23.44-13.73 50.45-4 66.6 3.34 5.55 8.82 10.77 15.39 15.22 18.87 12.8 42.48 16.1 64.58 10.47 34.29-8.75 65.25-30.38 83.09-58.45 20.73-32.56 22.4-69 19.46-102.08Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "381.825px 249.19px"
        }}
        className="animados"
      />
      <g
        style={{
          opacity: 0.8,
          transformOrigin: "381.825px 249.19px"
        }}
        className="animados"
      >
        <path
          d="M476.44 205.78c-1.79-20.13-8.81-40.27-22.47-55.16s-34.51-23.85-54.44-20.49-37.53 20.4-38.93 40.57c-.71 10.71 2.86 21.34 2.27 32.05-1 18.52-14.29 34.19-29.15 45.27-12.81 9.56-26.62 13-35.8 26-16.47 23.44-13.73 50.45-4 66.6 3.34 5.55 8.82 10.77 15.39 15.22 18.87 12.8 42.48 16.1 64.58 10.47 34.29-8.75 65.25-30.38 83.09-58.45 20.73-32.56 22.4-69 19.46-102.08Z"
          style={{
            fill: "#fff",
            transformOrigin: "381.825px 249.19px"
          }}
          className="animados"
        />
      </g>
      <circle
        cx={332.15}
        cy={347.95}
        r={1.42}
        style={{
          fill: "#5ea3ff",
          transformOrigin: "332.15px 347.95px"
        }}
        className="animados"
      />
      <circle
        cx={354.75}
        cy={372.35}
        r={0.75}
        style={{
          fill: "#5ea3ff",
          transformOrigin: "354.75px 372.35px"
        }}
        className="animados"
      />
      <path
        d="M404.21 376.72c-5.12 2.1-11 9.44-11.39 15.21s2.63 11.51 7.28 14.94a23.59 23.59 0 0 0 16.46 4.07c4.11-.45 8.28-1.91 11.06-5 3.08-3.37 4.05-8.25 3.6-12.79a21.46 21.46 0 0 0-9.15-15.76c-5.26-3.44-12.47-3.93-17.86-.71"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "412.055px 392.821px"
        }}
        className="animados"
      />
      <g
        style={{
          opacity: 0.8,
          transformOrigin: "412.055px 392.821px"
        }}
        className="animados"
      >
        <path
          d="M404.21 376.72c-5.12 2.1-11 9.44-11.39 15.21s2.63 11.51 7.28 14.94a23.59 23.59 0 0 0 16.46 4.07c4.11-.45 8.28-1.91 11.06-5 3.08-3.37 4.05-8.25 3.6-12.79a21.46 21.46 0 0 0-9.15-15.76c-5.26-3.44-12.47-3.93-17.86-.71"
          style={{
            fill: "#fff",
            transformOrigin: "412.055px 392.821px"
          }}
          className="animados"
        />
      </g>
      <path
        d="M444 265.92a2.56 2.56 0 1 1-2.56-2.56 2.56 2.56 0 0 1 2.56 2.56Z"
        style={{
          fill: "#5ea3ff",
          fillRule: "evenodd",
          transformOrigin: "441.44px 265.92px"
        }}
        className="animados"
      />
      <circle
        cx={402.92}
        cy={169.72}
        r={1.42}
        style={{
          fill: "#5ea3ff",
          transformOrigin: "402.92px 169.72px"
        }}
        className="animados"
      />
      <path
        d="M437.33 430.28c0 1.67-24.73 3-55.25 3s-55.25-1.35-55.25-3 24.73-3 55.25-3 55.25 1.33 55.25 3Z"
        style={{
          fill: "#fafafa",
          transformOrigin: "382.08px 430.28px"
        }}
        className="animados"
      />
      <path
        d="m298.3 456.56 12.62-26h139.21l16.59 26s-168.42 1.75-168.42.06"
        style={{
          fill: "#263238",
          transformOrigin: "382.51px 443.958px"
        }}
        className="animados"
      />
      <path
        d="M384.76 457c-.11 0-.2-5.06-.2-11.29s.09-11.28.2-11.28.21 5.05.21 11.28-.09 11.29-.21 11.29Z"
        style={{
          fill: "#455a64",
          transformOrigin: "384.765px 445.715px"
        }}
        className="animados"
      />
      <path
        d="m455.51 440.77-.53.09-1.54.17-5.77.59-19.82 1.89.16-.09-4.15 6.69-.06.1h-1.74l-26.37.49-8 .1h-2.94.76l2.17-.09 8-.23 26.37-.61h1.63l-.17.1c1.43-2.31 2.83-4.55 4.15-6.69l.06-.08h.1l19.82-1.75 5.78-.47 1.55-.1Z"
        style={{
          fill: "#455a64",
          transformOrigin: "420.13px 445.785px"
        }}
        className="animados"
      />
      <path
        d="M442.93 441.93a56.49 56.49 0 0 1-1.49-9.57 28 28 0 0 1 1 4.75 27.13 27.13 0 0 1 .49 4.82Z"
        style={{
          fill: "#455a64",
          transformOrigin: "442.185px 437.145px"
        }}
        className="animados"
      />
      <path
        d="M331.34 457.3a1.64 1.64 0 0 1-.22-.42l-.52-1.2-1.78-4.33.15.09-9.55-.71c-4.85-.38-9.24-.74-12.42-1l-3.76-.34a12.59 12.59 0 0 1-1.37-.17 9.13 9.13 0 0 1 1.39 0l3.77.24c3.19.22 7.58.54 12.44.92l9.53.77h.12v.09c.67 1.77 1.23 3.26 1.64 4.36l.43 1.22a1.64 1.64 0 0 1 .15.48Z"
        style={{
          fill: "#455a64",
          transformOrigin: "316.605px 453.247px"
        }}
        className="animados"
      />
      <path
        d="M384.76 440.77H382.25l-6.92.06-23.47.07h.07l-6.37 2.39-3.45 1.3H342l-25.41-3.14-7.58-1-2-.28-.53-.08-.17-.05h.71l2.06.21 7.59.86 25.43 3h-.1l3.45-1.3 6.38-2.39h.07l23.47.07 6.92.06h2.35Z"
        style={{
          fill: "#455a64",
          transformOrigin: "345.535px 442.315px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "472.48px 232.515px"
        }}
        className="animados"
        d="M456.45 230.32h32.06v4.39h-32.06z"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "463.385px 240.455px"
        }}
        className="animados"
        d="M456.45 238.26h13.87v4.39h-13.87z"
      />
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "582.536px 334.875px"
      }}
    >
      <path
        d="M655.77 239.77a1.14 1.14 0 0 1 .2-.19l.63-.52.48-.37.62-.38.74-.44.88-.39a16.14 16.14 0 0 1 11.4-.2 18.31 18.31 0 0 1 6.88 4.07 19 19 0 0 1 4.84 7.79c2.1 6.2 1.56 13.79-1.47 20.64s-7.86 12.34-12.4 16.89-9 8.33-12.51 11.72a91.59 91.59 0 0 0-7.9 8.38c-.86 1.06-1.5 1.9-1.93 2.48l-.49.65c-.11.14-.17.22-.18.21a1 1 0 0 1 .14-.24c.11-.18.26-.41.45-.68.39-.6 1-1.47 1.85-2.54a83.14 83.14 0 0 1 7.81-8.53c3.48-3.42 7.87-7.23 12.42-11.79s9.24-10 12.27-16.76 3.5-14.21 1.5-20.27a18.87 18.87 0 0 0-4.68-7.64 18 18 0 0 0-6.71-4 16 16 0 0 0-11.2 0 17.81 17.81 0 0 0-3.64 2.11Z"
        style={{
          fill: "#263238",
          transformOrigin: "664.631px 273.224px"
        }}
        className="animados"
      />
      <path
        d="M573.05 404.2v-31.83c0-11.28 7.55-18.93 17-19.05l97.08-1.21a11.68 11.68 0 0 1 11.82 11.67v39.84Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "636px 378.155px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#fafafa",
          transformOrigin: "667.335px 371.2px"
        }}
        className="animados"
        d="M647.67 362.01H687v18.38h-39.33z"
      />
      <path
        d="M635.34 403.43c-.14 0-.26-11.26-.26-25.15s.12-25.15.26-25.15.26 11.26.26 25.15-.12 25.15-.26 25.15Z"
        style={{
          fill: "#263238",
          transformOrigin: "635.34px 378.28px"
        }}
        className="animados"
      />
      <path
        d="M635.74 337.88a2.9 2.9 0 0 1 1-.46 10.51 10.51 0 0 1 2.83-.63 11.81 11.81 0 0 1 13 11.34 10.43 10.43 0 0 1-.23 2.89 3.31 3.31 0 0 1-.33 1 19.26 19.26 0 0 0 .19-3.87 11.77 11.77 0 0 0-12.61-11 19.42 19.42 0 0 0-3.85.73Z"
        style={{
          fill: "#263238",
          transformOrigin: "644.164px 344.375px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "628.72px 293.49px"
        }}
        className="animados"
        d="m587.56 353.55 47.78-128.58 34.54 16.4-40.53 120.64-41.79-8.46z"
      />
      <circle
        cx={598.37}
        cy={381.65}
        r={19.18}
        style={{
          opacity: 0.4,
          transformOrigin: "598.37px 381.65px",
          transform: "rotate(-38.12deg)"
        }}
        className="animados"
      />
      <circle
        cx={600.73}
        cy={374.17}
        r={19.73}
        style={{
          fill: "#263238",
          transformOrigin: "600.73px 374.17px",
          transform: "rotate(-65.41deg)"
        }}
        className="animados"
      />
      <circle
        cx={600.73}
        cy={374.17}
        r={7.5}
        style={{
          fill: "#455a64",
          transformOrigin: "600.73px 374.17px",
          transform: "rotate(-72.58deg)"
        }}
        className="animados"
      />
      <path
        d="M608 357.68a7.2 7.2 0 0 1 .24-.79c.2-.56.46-1.32.79-2.26.72-2 1.73-4.8 3-8.25 2.58-7 6.11-16.58 10-27.17l7.12-19.06.14.3-14.09-5-3.94-1.45-1-.39a1.19 1.19 0 0 1-.34-.16 1.74 1.74 0 0 1 .37.09l1 .33 4 1.31 14.15 4.8.21.07-.08.22c-2 5.56-4.47 12.11-7 19.08l-10.17 27.12-3.13 8.19-.88 2.22a3.65 3.65 0 0 1-.39.8Z"
        style={{
          fill: "#263238",
          transformOrigin: "618.825px 325.565px"
        }}
        className="animados"
      />
      <path
        d="M652.38 295.1c0 .14-8.5-3.09-18.88-7.2s-18.74-7.55-18.69-7.69 8.51 3.09 18.88 7.21 18.75 7.58 18.69 7.68Z"
        style={{
          fill: "#263238",
          transformOrigin: "633.595px 287.655px"
        }}
        className="animados"
      />
      <path
        d="M610.58 393.15s.48-.45 1.37-1.24a44.85 44.85 0 0 0 3.55-3.55 56.1 56.1 0 0 0 8.89-13.87 123.61 123.61 0 0 0 5.71-15.56l1.47-4.81c.17-.52.3-.95.42-1.31s.16-.45.18-.44a1.55 1.55 0 0 1-.08.47c-.09.36-.2.8-.32 1.33-.3 1.15-.74 2.82-1.32 4.87a107.39 107.39 0 0 1-5.59 15.66 52.75 52.75 0 0 1-9.09 13.91 34.25 34.25 0 0 1-3.69 3.45 13.3 13.3 0 0 1-1.09.83c-.26.19-.4.28-.41.26Z"
        style={{
          fill: "#263238",
          transformOrigin: "621.375px 372.761px"
        }}
        className="animados"
      />
      <path
        d="M585.47 382.42s-.12 0-.34-.07a3.69 3.69 0 0 1-.91-.43 6.1 6.1 0 0 1-2.29-2.87 11.11 11.11 0 0 1-.51-5.42 66 66 0 0 1 1.12-6.54 70.11 70.11 0 0 1 3.58-11.49c.58-1.43 1.11-2.56 1.48-3.33a6.64 6.64 0 0 1 .65-1.17 6.65 6.65 0 0 1-.46 1.25c-.32.8-.8 1.94-1.33 3.38a84.3 84.3 0 0 0-3.41 11.47c-.91 4.54-1.91 8.85-.78 11.72a6.24 6.24 0 0 0 2.06 2.83c.67.5 1.16.62 1.14.67Z"
        style={{
          fill: "#263238",
          transformOrigin: "584.77px 366.76px"
        }}
        className="animados"
      />
      <circle
        cx={578.07}
        cy={430}
        r={17.38}
        style={{
          fill: "#455a64",
          transformOrigin: "578.07px 430px"
        }}
        className="animados"
      />
      <circle
        cx={578.07}
        cy={430}
        r={5.96}
        style={{
          fill: "#263238",
          transformOrigin: "578.07px 430px"
        }}
        className="animados"
      />
      <circle
        cx={635.18}
        cy={430.46}
        r={17.38}
        style={{
          fill: "#455a64",
          transformOrigin: "635.18px 430.46px"
        }}
        className="animados"
      />
      <circle
        cx={635.18}
        cy={430.46}
        r={5.96}
        style={{
          fill: "#263238",
          transformOrigin: "635.18px 430.46px"
        }}
        className="animados"
      />
      <circle
        cx={692.73}
        cy={430.46}
        r={17.38}
        style={{
          fill: "#455a64",
          transformOrigin: "692.73px 430.46px"
        }}
        className="animados"
      />
      <circle
        cx={692.73}
        cy={430.46}
        r={5.96}
        style={{
          fill: "#263238",
          transformOrigin: "692.73px 430.46px"
        }}
        className="animados"
      />
      <path
        d="M693.35 456.38H577.68a26.38 26.38 0 0 1 0-52.76h115.67a26.38 26.38 0 1 1 0 52.76Zm-115.67-44.11a17.73 17.73 0 1 0 0 35.46h115.67a17.73 17.73 0 1 0 0-35.46Z"
        style={{
          fill: "#263238",
          transformOrigin: "635.541px 430px"
        }}
        className="animados"
      />
      <path
        d="M635.18 412.25a56 56 0 0 1 0-8.46 59.72 59.72 0 0 1 0 8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "635.175px 408.02px"
        }}
        className="animados"
      />
      <path
        d="M656.86 412.25a56 56 0 0 1 0-8.46 56 56 0 0 1 0 8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "656.86px 408.02px"
        }}
        className="animados"
      />
      <path
        d="M678.54 412.25a56 56 0 0 1 0-8.46 56 56 0 0 1 0 8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "678.54px 408.02px"
        }}
        className="animados"
      />
      <path
        d="M698.69 413.08a28.06 28.06 0 0 1 1.07-4.1 28.58 28.58 0 0 1 1.38-4 29 29 0 0 1-1.08 4.09 30.53 30.53 0 0 1-1.37 4.01Z"
        style={{
          fill: "#455a64",
          transformOrigin: "699.915px 409.03px"
        }}
        className="animados"
      />
      <path
        d="M710.11 423.94a56.42 56.42 0 0 1 7.53-3.85 29.23 29.23 0 0 1-3.69 2.06 27.54 27.54 0 0 1-3.84 1.79Z"
        style={{
          fill: "#455a64",
          transformOrigin: "713.875px 422.015px"
        }}
        className="animados"
      />
      <path
        d="M634.11 448.11a56 56 0 0 1 0 8.46 56 56 0 0 1 0-8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "634.11px 452.34px"
        }}
        className="animados"
      />
      <path
        d="M655.79 447.84a56 56 0 0 1 0 8.46 59.72 59.72 0 0 1 0-8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "655.795px 452.07px"
        }}
        className="animados"
      />
      <path
        d="M677.47 447.84a56 56 0 0 1 0 8.46 59.72 59.72 0 0 1 0-8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "677.475px 452.07px"
        }}
        className="animados"
      />
      <path
        d="M697.62 447.28a29.17 29.17 0 0 1 1.38 4 30.7 30.7 0 0 1 1.08 4.1 28.09 28.09 0 0 1-1.38-4 29 29 0 0 1-1.08-4.1Z"
        style={{
          fill: "#455a64",
          transformOrigin: "698.85px 451.33px"
        }}
        className="animados"
      />
      <path
        d="M709.64 437.3a30.66 30.66 0 0 1 3.84 1.79 29.23 29.23 0 0 1 3.69 2.06 54.85 54.85 0 0 1-7.53-3.85Z"
        style={{
          fill: "#455a64",
          transformOrigin: "713.405px 439.225px"
        }}
        className="animados"
      />
      <path
        d="M614.47 448.11a56 56 0 0 1 0 8.46 59.72 59.72 0 0 1 0-8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "614.475px 452.34px"
        }}
        className="animados"
      />
      <path
        d="M592.79 448.11a56 56 0 0 1 0 8.46 59.72 59.72 0 0 1 0-8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "592.795px 452.34px"
        }}
        className="animados"
      />
      <path
        d="M572.65 447.28a29 29 0 0 1-1.08 4.09 29.64 29.64 0 0 1-1.38 4 56.75 56.75 0 0 1 2.46-8.1Z"
        style={{
          fill: "#455a64",
          transformOrigin: "571.42px 451.32px"
        }}
        className="animados"
      />
      <path
        d="M561.22 436.42a29.23 29.23 0 0 1-3.69 2.06 30.66 30.66 0 0 1-3.84 1.79 28.25 28.25 0 0 1 3.7-2.07 29 29 0 0 1 3.83-1.78Z"
        style={{
          fill: "#455a64",
          transformOrigin: "557.455px 438.345px"
        }}
        className="animados"
      />
      <path
        d="M615.54 412.51a59.72 59.72 0 0 1 0-8.46 56 56 0 0 1 0 8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "615.545px 408.28px"
        }}
        className="animados"
      />
      <path
        d="M593.86 412.51a56 56 0 0 1 0-8.46 56 56 0 0 1 0 8.46Z"
        style={{
          fill: "#455a64",
          transformOrigin: "593.86px 408.28px"
        }}
        className="animados"
      />
      <path
        d="M573.72 413.08a28.09 28.09 0 0 1-1.38-4 29 29 0 0 1-1.08-4.09 28.58 28.58 0 0 1 1.38 4 29 29 0 0 1 1.08 4.09Z"
        style={{
          fill: "#455a64",
          transformOrigin: "572.49px 409.035px"
        }}
        className="animados"
      />
      <path
        d="M561.7 423.05a28.13 28.13 0 0 1-3.84-1.78 29.1 29.1 0 0 1-3.7-2.07A27.54 27.54 0 0 1 558 421a28.39 28.39 0 0 1 3.7 2.05Z"
        style={{
          fill: "#455a64",
          transformOrigin: "557.93px 421.125px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "563.04px 273.51px"
        }}
        className="animados"
        d="m528.76 279.15 64.86-26.1 3.7 13.96-61.96 26.96-6.6-14.82z"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "617.15px 250.685px"
        }}
        className="animados"
        d="m645.38 257.75-45.74 18.14-10.72-28.73 51.8-21.68 4.66 32.27z"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "674.09px 225.045px"
        }}
        className="animados"
        d="M661.73 236.91 690.32 225l-4.71-11.82-27.75 11.21 3.87 12.52z"
      />
      <circle
        cx={647.5}
        cy={248.46}
        r={18.02}
        style={{
          opacity: 0.4,
          transformOrigin: "647.5px 248.46px"
        }}
        className="animados"
      />
      <path
        d="M661.12 226.17a18.54 18.54 0 1 1-26.2-1 18.54 18.54 0 0 1 26.2 1Z"
        style={{
          fill: "#263238",
          transformOrigin: "647.52px 238.77px"
        }}
        className="animados"
      />
      <path
        d="M652.68 234a7 7 0 1 1-10-.41 7 7 0 0 1 10 .41Z"
        style={{
          fill: "#455a64",
          transformOrigin: "647.48px 238.686px"
        }}
        className="animados"
      />
      <path
        d="M594.59 243.33a5.58 5.58 0 0 1 .76.7c.52.55 1.2 1.24 2 2.08l6.87 7.31-.27-.06 6.31-2.51c5.34-2.11 10.17-4 13.68-5.33l4.17-1.56a11.17 11.17 0 0 1 1.55-.5 9.31 9.31 0 0 1-1.47.69l-4.11 1.72c-3.48 1.42-8.29 3.36-13.62 5.46l-6.33 2.49-.16.06-.11-.12-6.73-7.44c-.78-.88-1.41-1.61-1.91-2.18a4.17 4.17 0 0 1-.63-.81Z"
        style={{
          fill: "#263238",
          transformOrigin: "612.125px 248.605px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "510.45px 295.59px"
        }}
        className="animados"
        d="m481.03 291.29 45.8-19.44 13.04 27.01-47.47 20.47-11.37-28.04z"
      />
      <circle
        cx={491.78}
        cy={303.67}
        r={14.8}
        style={{
          opacity: 0.4,
          transformOrigin: "491.78px 303.67px",
          transform: "rotate(-76.52deg)"
        }}
        className="animados"
      />
      <circle
        cx={485.98}
        cy={305.96}
        r={15.84}
        style={{
          fill: "#263238",
          transformOrigin: "485.98px 305.96px",
          transform: "rotate(-72.32deg)"
        }}
        className="animados"
      />
      <path
        d="M483.63 300.42a6 6 0 1 1-3.2 7.89 6 6 0 0 1 3.2-7.89Z"
        style={{
          fill: "#455a64",
          transformOrigin: "485.948px 305.954px"
        }}
        className="animados"
      />
      <path
        d="M510.16 311.67a5 5 0 0 1-.24-1.13l-.51-3.18c-.41-2.8-1-6.6-1.59-11v-.19l.18-.07 5.63-2.29c5.51-2.24 10.51-4.23 14.14-5.65l4.31-1.66a9.72 9.72 0 0 1 1.6-.54 10.6 10.6 0 0 1-1.53.73l-4.24 1.81c-3.6 1.5-8.57 3.55-14.08 5.79l-5.64 2.27.15-.26c.56 4.38 1.05 8.18 1.41 11 .15 1.3.27 2.37.36 3.2a5.82 5.82 0 0 1 .05 1.17Z"
        style={{
          fill: "#263238",
          transformOrigin: "520.75px 298.815px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#263238",
          transformOrigin: "451.14px 315.485px",
          transform: "rotate(80.29deg)"
        }}
        className="animados"
        d="M450.19 309.74h1.9v11.49h-1.9z"
      />
      <path
        style={{
          fill: "#263238",
          transformOrigin: "472.48px 311.67px"
        }}
        className="animados"
        d="m478.18 303.89-6.66 1.14-.58-3.39-2.56.44.75 4.44-4.67.8.38 2.22 1.5 8.76 4.67-.8.72 4.2 2.28-.39-.46-2.68 6.95-1.19-2.32-13.55z"
      />
      <path
        style={{
          fill: "#455a64",
          transformOrigin: "460.13px 313.955px",
          transform: "rotate(-9.71deg)"
        }}
        className="animados"
        d="M454.74 310.4h10.78v7.11h-10.78z"
      />
    </g>
    <g
      className="animatedModeRobot"
      style={{
        transformOrigin: "385.44px 264.563px"
      }}
    >
      <path
        d="M386.26 244.9s-46-1.49-46 23c0 27.73 9.14 79.69 46 79.15s43.62-73.4 44-76.64c.03-.03 1.95-23.41-44-25.51Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "385.269px 295.968px"
        }}
        className="animados"
      />
      <path
        d="M429.19 264.06a.86.86 0 0 1-.19.15l-.59.39c-.51.35-1.29.82-2.3 1.4a55.43 55.43 0 0 1-9.08 4 74.17 74.17 0 0 1-14.34 3.33 127.73 127.73 0 0 1-18 1 165.89 165.89 0 0 1-18-.91 49.56 49.56 0 0 1-14.24-3.74 32.51 32.51 0 0 1-8.36-5.33c-.86-.8-1.5-1.46-1.9-1.92l-.45-.54-.15-.19s.07.05.19.16l.49.5c.42.44 1.08 1.07 2 1.83a33 33 0 0 0 8.37 5.16 50.18 50.18 0 0 0 14.1 3.65 175.53 175.53 0 0 0 17.95.87 133.37 133.37 0 0 0 17.94-1 76.22 76.22 0 0 0 14.29-3.21 59.43 59.43 0 0 0 9.09-3.87c1-.55 1.82-1 2.35-1.31l.61-.34a1.18 1.18 0 0 1 .22-.08Z"
        style={{
          fill: "#263238",
          transformOrigin: "385.39px 268.022px"
        }}
        className="animados"
      />
      <path
        d="M401.39 273.3a3.28 3.28 0 0 1 .07.68v2a34.51 34.51 0 0 1-.76 7.22 24.87 24.87 0 0 1-1.57 4.78 20.39 20.39 0 0 1-3 4.93 18.34 18.34 0 0 1-4.91 4.1 17.45 17.45 0 0 1-3.13 1.34 14.74 14.74 0 0 1-3.46.59 13 13 0 0 1-6.63-1.64 16.49 16.49 0 0 1-4.88-4.14 21.4 21.4 0 0 1-4.26-9.86 37.2 37.2 0 0 1-.46-7.25c0-.85.08-1.52.12-2a3.23 3.23 0 0 1 .09-.69 4.56 4.56 0 0 1 0 .69v2a38.85 38.85 0 0 0 .62 7.17 21.17 21.17 0 0 0 4.26 9.62 16.17 16.17 0 0 0 4.75 4 12.58 12.58 0 0 0 6.41 1.55 14.23 14.23 0 0 0 3.33-.56 16.37 16.37 0 0 0 3-1.3 17.64 17.64 0 0 0 4.79-4 19.56 19.56 0 0 0 3-4.79 24.65 24.65 0 0 0 1.61-4.69 37.42 37.42 0 0 0 .92-7.14c0-.81 0-1.46.05-2a3.22 3.22 0 0 1 .04-.61Z"
        style={{
          fill: "#263238",
          transformOrigin: "384.919px 286.122px"
        }}
        className="animados"
      />
      <path
        d="M412.33 329.78c-.06 0-.36-1-1.14-2.69a30.74 30.74 0 0 0-4.25-6.69 29.65 29.65 0 0 0-9.15-7.26 28.86 28.86 0 0 0-27.56 1.05 29.45 29.45 0 0 0-8.57 7.93 30.25 30.25 0 0 0-3.73 7c-.66 1.76-.88 2.78-.94 2.77a4.69 4.69 0 0 1 .15-.75 20.14 20.14 0 0 1 .6-2.09 28.3 28.3 0 0 1 3.62-7.13 29.3 29.3 0 0 1 8.63-8.13 29 29 0 0 1 28-1.06 29.3 29.3 0 0 1 9.21 7.45 28.56 28.56 0 0 1 4.16 6.84c.35.88.61 1.56.75 2a3.85 3.85 0 0 1 .22.76Z"
        style={{
          fill: "#263238",
          transformOrigin: "384.66px 320.762px"
        }}
        className="animados"
      />
      <path
        d="m405.18 305.46 2.12-9.46h18.58c-.66 2.82-1.58 6.11-2.64 9.61Z"
        style={{
          fill: "#fafafa",
          transformOrigin: "415.53px 300.805px"
        }}
        className="animados"
      />
      <path
        d="m363.62 305.46-2.11-9.46h-18.59c.66 2.82 1.22 6.11 2.27 9.61Z"
        style={{
          fill: "#fafafa",
          transformOrigin: "353.27px 300.805px"
        }}
        className="animados"
      />
      <path
        d="M427.87 261.74c-4.45-7.26-16.22-15.66-41.61-16.84 0 0-28.45-.92-40.6 10.59a26.32 26.32 0 0 0-3.1 3.84c-.74 1.37 9.49 13.66 42.37 12.61 33.92-1.09 42.94-10.2 42.94-10.2Z"
        style={{
          opacity: 0.4,
          transformOrigin: "385.197px 258.443px"
        }}
        className="animados"
      />
      <path
        d="M339.47 218.36c-1.63-3.65-2.42-7.83-1.31-11.67s4.41-7.19 8.4-7.54"
        style={{
          fill: "#263238",
          transformOrigin: "342.102px 208.755px"
        }}
        className="animados"
      />
      <path
        d="M345.23 199.38c0 .07-.67.61-1.62 1.7a19.94 19.94 0 0 0-3.1 4.67 45 45 0 0 0-1.94 5.31 17.07 17.07 0 0 1-.66 2.27 7.6 7.6 0 0 1 .3-2.36 30.8 30.8 0 0 1 1.82-5.44 17 17 0 0 1 3.31-4.7 6 6 0 0 1 1.89-1.45Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "341.568px 206.355px"
        }}
        className="animados"
      />
      <path
        d="M431.42 218.36c1.63-3.65 2.42-7.83 1.31-11.67s-4.41-7.19-8.39-7.54"
        style={{
          fill: "#263238",
          transformOrigin: "428.793px 208.755px"
        }}
        className="animados"
      />
      <path
        d="M425.66 199.38a5.83 5.83 0 0 1 1.89 1.45 16.82 16.82 0 0 1 3.31 4.7 30.8 30.8 0 0 1 1.82 5.44 7.6 7.6 0 0 1 .3 2.36 19.18 19.18 0 0 1-.66-2.27 44.69 44.69 0 0 0-1.93-5.31 20.26 20.26 0 0 0-3.11-4.67c-.95-1.08-1.67-1.63-1.62-1.7Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "429.321px 206.355px"
        }}
        className="animados"
      />
      <path
        d="M343.16 236.48a19.36 19.36 0 0 1-5.14-17.4c2.66-13.4 12.41-37.52 48.24-37 37.59.53 44.65 26.55 45.86 39.08a19.54 19.54 0 0 1-4.38 14.36c-5.59 6.75-17.46 15.47-41.48 15.46-23.79.02-36.58-7.92-43.1-14.5Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "384.923px 216.526px"
        }}
        className="animados"
      />
      <path
        d="M369.51 183.65a1.77 1.77 0 0 1 .21.45l.52 1.32c.45 1.14 1.08 2.8 2.06 4.75a20.81 20.81 0 0 0 4.49 6.11 12.05 12.05 0 0 0 8.37 3.37 14.08 14.08 0 0 0 8.42-3.45 22 22 0 0 0 4.74-5.93 43.55 43.55 0 0 0 2.25-4.66 16.67 16.67 0 0 1 .77-1.75 1.44 1.44 0 0 1-.12.48c-.1.31-.25.77-.46 1.34a36.29 36.29 0 0 1-2.12 4.77 21.5 21.5 0 0 1-4.75 6.11 14.33 14.33 0 0 1-8.72 3.61 11 11 0 0 1-4.86-1 14.52 14.52 0 0 1-3.84-2.55 20.48 20.48 0 0 1-4.5-6.31 41.34 41.34 0 0 1-1.92-4.84q-.3-.88-.42-1.35a2.58 2.58 0 0 1-.12-.47Z"
        style={{
          fill: "#263238",
          transformOrigin: "385.425px 191.912px"
        }}
        className="animados"
      />
      <path
        d="M410 235c-17.44 4.26-34.46 4.42-51 .18a14.1 14.1 0 0 1-10.55-13.69v-.24a14.16 14.16 0 0 1 14.16-14.16h44a14.16 14.16 0 0 1 14.16 14.16A14.15 14.15 0 0 1 410 235Z"
        style={{
          fill: "#263238",
          transformOrigin: "384.61px 222.684px"
        }}
        className="animados"
      />
      <path
        d="M371.58 223.94a3.21 3.21 0 1 1-2.67-3.68 3.21 3.21 0 0 1 2.67 3.68Z"
        style={{
          fill: "#fff",
          transformOrigin: "368.411px 223.431px"
        }}
        className="animados"
      />
      <path
        d="M408.36 222.08a3.21 3.21 0 1 1-2.66-3.68 3.21 3.21 0 0 1 2.66 3.68Z"
        style={{
          fill: "#fff",
          transformOrigin: "405.191px 221.569px"
        }}
        className="animados"
      />
      <path
        d="M394.24 222.49a4.48 4.48 0 0 1-.48 2.78 6.65 6.65 0 0 1-1.89 2.46 6.24 6.24 0 0 1-3.47 1.36 6.13 6.13 0 0 1-3.6-.83 6.4 6.4 0 0 1-2.15-2.16 4.34 4.34 0 0 1-.79-2.69 13.48 13.48 0 0 0 1.16 2.42 6.37 6.37 0 0 0 2.07 1.89 5.79 5.79 0 0 0 3.27.71 5.92 5.92 0 0 0 3.16-1.18 6.78 6.78 0 0 0 1.84-2.18 15.48 15.48 0 0 0 .88-2.58Z"
        style={{
          fill: "#fff",
          transformOrigin: "388.073px 225.8px"
        }}
        className="animados"
      />
    </g>
    <g
      className="animados"
      style={{
        transformOrigin: "208.205px 255.716px"
      }}
    >
      <path
        d="M88.1 241.8a1 1 0 0 1-.27.11l-.8.27c-.7.24-1.73.62-3 1.2a27.56 27.56 0 0 0-9.47 7 21.41 21.41 0 0 0-4.06 7 18.71 18.71 0 0 0-.6 9.23 17.55 17.55 0 0 0 4.6 9.07 14.4 14.4 0 0 0 4.46 3 16.58 16.58 0 0 0 5.42 1.32 29.11 29.11 0 0 0 10.93-1.76c3.41-1.15 6.57-2.69 9.63-4a53.59 53.59 0 0 1 8.94-3.2 21.39 21.39 0 0 1 8.25-.53 15.49 15.49 0 0 1 10.35 5.57 12.55 12.55 0 0 1 1 1.59c.21.51.42.92.53 1.28s.17.6.22.81a1 1 0 0 1 .06.29l-.11-.27-.27-.8c-.13-.34-.35-.75-.57-1.24a11 11 0 0 0-1-1.54 15.52 15.52 0 0 0-10.24-5.33 21.32 21.32 0 0 0-8.1.59 54.21 54.21 0 0 0-8.85 3.22c-3 1.33-6.2 2.88-9.66 4.06a29.52 29.52 0 0 1-11.12 1.79 17.16 17.16 0 0 1-5.61-1.31 14.58 14.58 0 0 1-4.61-3.17 17.9 17.9 0 0 1-4.71-9.33 19 19 0 0 1 .67-9.46 21.53 21.53 0 0 1 4.18-7.13 27.16 27.16 0 0 1 9.67-7 22.81 22.81 0 0 1 4.14-1.33Z"
        style={{
          fill: "#263238",
          transformOrigin: "101.709px 261.173px"
        }}
        className="animados"
      />
      <path
        d="M216.19 397.75v-37.54a19.21 19.21 0 0 0-19-19.21l-108.65-1.36a13.07 13.07 0 0 0-13.23 13.07v44.59Z"
        style={{
          fill: "#5ea3ff",
          transformOrigin: "145.75px 368.695px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#fafafa",
          transformOrigin: "110.64px 361.01px",
          transform: "rotate(180deg)"
        }}
        className="animados"
        d="M88.63 350.72h44.02v20.58H88.63z"
      />
      <path
        d="M146.46 397.09c-.15 0-.26-12.61-.26-28.15s.11-28.16.26-28.16.26 12.6.26 28.16-.12 28.15-.26 28.15Z"
        style={{
          fill: "#263238",
          transformOrigin: "146.46px 368.935px"
        }}
        className="animados"
      />
      <path
        d="M146 323.71a20.38 20.38 0 0 0-4.26-.83 13.21 13.21 0 0 0-14.15 12.34 19.56 19.56 0 0 0 .25 4.33 3.72 3.72 0 0 1-.36-1.13 11.53 11.53 0 0 1-.25-3.23 13.35 13.35 0 0 1 14.54-12.68 11.89 11.89 0 0 1 3.16.7 4.08 4.08 0 0 1 1.07.5Z"
        style={{
          fill: "#263238",
          transformOrigin: "136.603px 331.003px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "153.865px 274.025px"
        }}
        className="animados"
        d="m199.94 341.25-53.48-143.92-38.67 18.35 45.37 135.04 46.78-9.47z"
      />
      <circle
        cx={187.84}
        cy={372.71}
        r={21.47}
        style={{
          opacity: 0.4,
          transformOrigin: "187.84px 372.71px",
          transform: "rotate(-88.05deg)"
        }}
        className="animados"
      />
      <circle
        cx={185.2}
        cy={364.33}
        r={22.08}
        style={{
          fill: "#263238",
          transformOrigin: "185.2px 364.33px",
          transform: "rotate(-68.48deg)"
        }}
        className="animados"
      />
      <path
        d="M177.52 367.72a8.4 8.4 0 1 0 4.3-11.07 8.4 8.4 0 0 0-4.3 11.07Z"
        style={{
          fill: "#455a64",
          transformOrigin: "185.208px 364.336px"
        }}
        className="animados"
      />
      <path
        d="M177.07 345.88a1.22 1.22 0 0 1-.11-.21l-.26-.64-1-2.49c-.84-2.21-2-5.34-3.5-9.18-2.92-7.81-6.93-18.52-11.37-30.35L153 281.65l-.08-.22.22-.07 15.78-5.36 4.45-1.48 1.18-.37.45-.15s-.13.07-.39.18l-1.15.43-4.41 1.6-15.77 5.55.13-.29c2.33 6.22 5.06 13.53 8 21.34 4.38 11.85 8.34 22.58 11.22 30.4 1.41 3.87 2.54 7 3.35 9.24.37 1.06.67 1.9.89 2.52l.21.66a.7.7 0 0 1-.01.25Z"
        style={{
          fill: "#263238",
          transformOrigin: "165.003px 309.94px"
        }}
        className="animados"
      />
      <path
        d="M127.38 275.83c0-.14 9.32-4 20.93-8.58s21.07-8.22 21.13-8.09-9.32 4-20.94 8.58-21.06 8.26-21.12 8.09Z"
        style={{
          fill: "#263238",
          transformOrigin: "148.41px 267.496px"
        }}
        className="animados"
      />
      <path
        d="M174.17 385.58a1.9 1.9 0 0 1-.45-.29c-.28-.21-.71-.51-1.22-.94a40.23 40.23 0 0 1-4.12-3.87 59.27 59.27 0 0 1-10.17-15.56 121 121 0 0 1-6.21-17.53c-.65-2.29-1.15-4.16-1.48-5.44l-.37-1.49a2.4 2.4 0 0 1-.09-.53 2 2 0 0 1 .19.5c.13.39.28.87.47 1.46l1.63 5.39a137.41 137.41 0 0 0 6.39 17.42 62.24 62.24 0 0 0 10 15.53 48.61 48.61 0 0 0 4 4 20.1 20.1 0 0 1 1.43 1.35Z"
        style={{
          fill: "#263238",
          transformOrigin: "162.115px 362.755px"
        }}
        className="animados"
      />
      <path
        d="M202.28 373.57a12.23 12.23 0 0 0 1.28-.74 6.89 6.89 0 0 0 2.32-3.18 12.43 12.43 0 0 0 .43-5.87c-.28-2.24-.73-4.7-1.29-7.25a92.42 92.42 0 0 0-3.83-12.84c-.6-1.61-1.13-2.89-1.49-3.78a9.17 9.17 0 0 1-.53-1.4 8.38 8.38 0 0 1 .71 1.31c.42.87 1 2.14 1.66 3.73a79.93 79.93 0 0 1 4 12.86c.56 2.58 1 5 1.26 7.32a12.53 12.53 0 0 1-.57 6.06 6.78 6.78 0 0 1-2.55 3.21 5.23 5.23 0 0 1-1 .49 1.34 1.34 0 0 1-.4.08Z"
        style={{
          fill: "#263238",
          transformOrigin: "203.057px 356.04px"
        }}
        className="animados"
      />
      <circle
        cx={210.56}
        cy={426.83}
        r={19.46}
        style={{
          fill: "#455a64",
          transformOrigin: "210.56px 426.83px"
        }}
        className="animados"
      />
      <circle
        cx={210.56}
        cy={426.83}
        r={6.67}
        style={{
          fill: "#263238",
          transformOrigin: "210.56px 426.83px"
        }}
        className="animados"
      />
      <circle
        cx={146.64}
        cy={427.34}
        r={19.46}
        style={{
          fill: "#455a64",
          transformOrigin: "146.64px 427.34px"
        }}
        className="animados"
      />
      <circle
        cx={146.64}
        cy={427.34}
        r={6.67}
        style={{
          fill: "#263238",
          transformOrigin: "146.64px 427.34px"
        }}
        className="animados"
      />
      <circle
        cx={82.22}
        cy={427.34}
        r={19.46}
        style={{
          fill: "#455a64",
          transformOrigin: "82.22px 427.34px"
        }}
        className="animados"
      />
      <circle
        cx={82.22}
        cy={427.34}
        r={6.67}
        style={{
          fill: "#263238",
          transformOrigin: "82.22px 427.34px"
        }}
        className="animados"
      />
      <path
        d="M52 426.83a29.56 29.56 0 0 1 29.52-29.53H211a29.53 29.53 0 0 1 0 59.06H81.52A29.57 29.57 0 0 1 52 426.83ZM81.52 407a19.85 19.85 0 0 0 0 39.7H211a19.85 19.85 0 0 0 0-39.7Z"
        style={{
          fill: "#263238",
          transformOrigin: "146.265px 426.83px"
        }}
        className="animados"
      />
      <path
        d="M146.64 407a70.14 70.14 0 0 1 0-9.47 70.14 70.14 0 0 1 0 9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "146.64px 402.265px"
        }}
        className="animados"
      />
      <path
        d="M122.37 407a70.14 70.14 0 0 1 0-9.47 70.14 70.14 0 0 1 0 9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "122.37px 402.265px"
        }}
        className="animados"
      />
      <path
        d="M98.1 407a70.14 70.14 0 0 1 0-9.47 70.14 70.14 0 0 1 0 9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "98.1px 402.265px"
        }}
        className="animados"
      />
      <path
        d="M75.55 407.88A36.62 36.62 0 0 1 74 403.4a37.56 37.56 0 0 1-1.23-4.58 35.41 35.41 0 0 1 1.53 4.49 34.06 34.06 0 0 1 1.25 4.57Z"
        style={{
          fill: "#455a64",
          transformOrigin: "74.16px 403.35px"
        }}
        className="animados"
      />
      <path
        d="M62.76 420a35.1 35.1 0 0 1-4.28-2 35 35 0 0 1-4.15-2.3 37.87 37.87 0 0 1 4.29 2 37 37 0 0 1 4.14 2.3Z"
        style={{
          fill: "#455a64",
          transformOrigin: "58.545px 417.85px"
        }}
        className="animados"
      />
      <path
        d="M147.84 447.1a74.81 74.81 0 0 1 0 9.47 70.14 70.14 0 0 1 0-9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "147.835px 451.835px"
        }}
        className="animados"
      />
      <path
        d="M123.57 446.8a74.81 74.81 0 0 1 0 9.47 70.14 70.14 0 0 1 0-9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "123.565px 451.535px"
        }}
        className="animados"
      />
      <path
        d="M99.3 446.8a74.81 74.81 0 0 1 0 9.47 70.14 70.14 0 0 1 0-9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "99.295px 451.535px"
        }}
        className="animados"
      />
      <path
        d="M76.75 446.17a36.53 36.53 0 0 1-1.23 4.58 36.62 36.62 0 0 1-1.52 4.48 70.14 70.14 0 0 1 2.75-9.06Z"
        style={{
          fill: "#455a64",
          transformOrigin: "75.375px 450.7px"
        }}
        className="animados"
      />
      <path
        d="M63.29 435a35.35 35.35 0 0 1-4.14 2.3 34.8 34.8 0 0 1-4.29 2A37 37 0 0 1 59 437a37.87 37.87 0 0 1 4.29-2Z"
        style={{
          fill: "#455a64",
          transformOrigin: "59.075px 437.15px"
        }}
        className="animados"
      />
      <path
        d="M169.81 447.1a70.14 70.14 0 0 1 0 9.47 74.81 74.81 0 0 1 0-9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "169.815px 451.835px"
        }}
        className="animados"
      />
      <path
        d="M194.08 447.1a70.14 70.14 0 0 1 0 9.47 74.81 74.81 0 0 1 0-9.47Z"
        style={{
          fill: "#455a64",
          transformOrigin: "194.085px 451.835px"
        }}
        className="animados"
      />
      <path
        d="M216.63 446.17a70.14 70.14 0 0 1 2.75 9.06 73.19 73.19 0 0 1-2.75-9.06Z"
        style={{
          fill: "#455a64",
          transformOrigin: "218.005px 450.7px"
        }}
        className="animados"
      />
      <path
        d="M229.42 434a73.9 73.9 0 0 1 8.44 4.31 69.82 69.82 0 0 1-8.44-4.31Z"
        style={{
          fill: "#455a64",
          transformOrigin: "233.64px 436.155px"
        }}
        className="animados"
      />
      <path
        d="M168.62 407.26a70.29 70.29 0 0 1 0-9.48 70.29 70.29 0 0 1 0 9.48Z"
        style={{
          fill: "#455a64",
          transformOrigin: "168.62px 402.52px"
        }}
        className="animados"
      />
      <path
        d="M192.89 407.26a70.29 70.29 0 0 1 0-9.48 70.29 70.29 0 0 1 0 9.48Z"
        style={{
          fill: "#455a64",
          transformOrigin: "192.89px 402.52px"
        }}
        className="animados"
      />
      <path
        d="M215.44 407.88a38.13 38.13 0 0 1 1.22-4.57 35.41 35.41 0 0 1 1.53-4.49 37.56 37.56 0 0 1-1.19 4.58 36.62 36.62 0 0 1-1.56 4.48Z"
        style={{
          fill: "#455a64",
          transformOrigin: "216.815px 403.35px"
        }}
        className="animados"
      />
      <path
        d="M228.89 419.05a73.9 73.9 0 0 1 8.44-4.31 73.9 73.9 0 0 1-8.44 4.31Z"
        style={{
          fill: "#455a64",
          transformOrigin: "233.11px 416.895px"
        }}
        className="animados"
      />
      <path
        d="M170 166.25s.08-.13.26-.37l.79-1.06c.71-.92 1.75-2.28 3.06-4.06 2.62-3.56 6.36-8.74 10.57-15.42a243.15 243.15 0 0 0 13.48-24.21c2.28-4.69 4.52-9.69 6.68-14.94s4.39-10.71 7-16.17a93.27 93.27 0 0 1 8.78-15.28 58.21 58.21 0 0 1 11.26-11.84 39.77 39.77 0 0 1 13.17-6.81 30.77 30.77 0 0 1 12.88-.93 30 30 0 0 1 10.13 3.31 25.93 25.93 0 0 1 6.39 4.89 22.78 22.78 0 0 1 3 4.14l.6 1.18c.13.27.18.41.18.41l-.23-.39-.65-1.15a23.37 23.37 0 0 0-3-4 25.62 25.62 0 0 0-6.38-4.77 29.72 29.72 0 0 0-10-3.2 30.26 30.26 0 0 0-12.7 1 39.79 39.79 0 0 0-13 6.78A57.56 57.56 0 0 0 221 75a94.33 94.33 0 0 0-8.72 15.2c-2.56 5.44-4.76 10.9-7 16.14s-4.42 10.27-6.71 15a240.51 240.51 0 0 1-13.57 24.2c-4.25 6.66-8 11.82-10.72 15.34-1.33 1.76-2.4 3.11-3.13 4l-.84 1c-.24.26-.31.37-.31.37Z"
        style={{
          fill: "#263238",
          transformOrigin: "224.115px 110.556px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "213.295px 141.18px"
        }}
        className="animados"
        d="m239.64 108.85-64.39 52.81 12.46 11.85 63.63-49.29-11.7-15.37z"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "157.58px 185.885px"
        }}
        className="animados"
        d="m150.54 218.4 45.12-37.39-23.87-27.64-52.29 41.69 31.04 23.34z"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "100.18px 229.465px"
        }}
        className="animados"
        d="m119.41 223.55-28.8 23.04-9.66-11.68 27.59-22.57 10.87 11.21z"
      />
      <path
        style={{
          fill: "#e0e0e0",
          transformOrigin: "309.015px 100.68px",
          transform: "rotate(-34.12deg)"
        }}
        className="animados"
        d="M299.75 89.64h18.53v22.08h-18.53z"
      />
      <path
        style={{
          fill: "#263238",
          transformOrigin: "331.59px 132.24px"
        }}
        className="animados"
        d="m321.45 102.52 27.63.39 15.33 22.63-7.47 23.53-6.65-1.65 3.45-15.57-11.36-13.66-14 .77-9.55 6.62-6.02 12.54 10.79 15.93 12.88 2.72-.14 6.2-21.23-1.86L298.77 137l7.34-24.08 16.83-11.41"
      />
      <circle
        cx={131.34}
        cy={218.31}
        r={21.47}
        style={{
          opacity: 0.4,
          transformOrigin: "131.34px 218.31px",
          transform: "rotate(-26.06deg)"
        }}
        className="animados"
      />
      <circle
        cx={129.47}
        cy={209.73}
        r={22.08}
        style={{
          fill: "#263238",
          transformOrigin: "129.47px 209.73px",
          transform: "rotate(-63.59deg)"
        }}
        className="animados"
      />
      <path
        d="M121.51 212.41a8.4 8.4 0 1 0 5.29-10.64 8.39 8.39 0 0 0-5.29 10.64Z"
        style={{
          fill: "#455a64",
          transformOrigin: "129.471px 209.734px"
        }}
        className="animados"
      />
      <path
        d="M165.09 158.72a6.71 6.71 0 0 1 .42 1l1.05 2.89 3.51 10.15.06.16-.13.1-6.87 5.73c-5.08 4.21-9.69 8-13 10.73l-4 3.2-1.1.85a1.9 1.9 0 0 1-.4.27s.1-.14.34-.35l1-.93 3.89-3.33c3.29-2.79 7.86-6.63 12.93-10.84l6.9-5.7-.08.26c-1.34-4.1-2.49-7.62-3.33-10.21-.37-1.2-.68-2.18-.92-2.93a5.49 5.49 0 0 1-.27-1.05Z"
        style={{
          fill: "#263238",
          transformOrigin: "157.38px 176.26px"
        }}
        className="animados"
      />
      <path
        style={{
          fill: "#5ea3ff",
          transformOrigin: "267.765px 98.835px"
        }}
        className="animados"
        d="m279.63 65.94-46.51 36.71 20.74 29.08 48.55-37.86-22.78-27.93z"
      />
      <circle
        cx={286.41}
        cy={84.26}
        r={17.63}
        style={{
          opacity: 0.4,
          transformOrigin: "286.41px 84.26px",
          transform: "rotate(-86.89deg)"
        }}
        className="animados"
      />
      <path
        d="M280.42 64.72a18.86 18.86 0 1 0 26.5 3.08 18.87 18.87 0 0 0-26.5-3.08Z"
        style={{
          fill: "#263238",
          transformOrigin: "292.131px 79.5057px"
        }}
        className="animados"
      />
      <path
        d="M287.68 73.89a7.17 7.17 0 1 0 10.07 1.17 7.16 7.16 0 0 0-10.07-1.17Z"
        style={{
          fill: "#455a64",
          transformOrigin: "292.13px 79.5101px"
        }}
        className="animados"
      />
      <path
        d="M284.25 108a7.77 7.77 0 0 1-1.16-.75l-3.09-2.19-10.7-7.77h.31l-5.6 4.58c-5.49 4.48-10.47 8.51-14.1 11.41l-4.31 3.41-1.19.9a2.41 2.41 0 0 1-.43.29s.11-.14.37-.37l1.12-1 4.21-3.54c3.56-3 8.51-7 14-11.52l5.61-4.57.16-.12.15.11 10.56 7.93 3 2.33a8.69 8.69 0 0 1 1.09.87Z"
        style={{
          fill: "#263238",
          transformOrigin: "264.115px 107.32px"
        }}
        className="animados"
      />
    </g>
  </svg>
)

export default SvgComponent
