import { Box } from "@chakra-ui/react";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { ModalGeneric } from "components/modal/ModalGeneric";
// import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import TreasuryCrudContex from "context/TreasuryContex";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { __PostForm } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TitleSpecialRate } from "../ArrayList/ArrayList";
import TBodySpecialRate from "../Tables/TbodySpecialRates";
import UpdateView from "./View/UpdateView";

const SpecialRates = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const {
    dataSpecial,
    update,
    setDataSpecial,
    isOpenViewUpdate,
    onCloseModal,
    dataIdShow
  } = useContext(TreasuryCrudContex);
  const navigate = useNavigate();

  const getUpdateSpecialRates = useCallback(async () => {
    const formData = new FormData();
    formData.append("special_rates", dataSpecial);
    setLoading(true);
    try {
      const res = await __PostForm(
        environment.TREASURY_UPDATE_RATE_GET,
        token,
        formData
      );
      setCurrentPage(res?.data?.status?.data?.data);
      // console.log(res?.data?.status?.data?.data);
      // setMaxPage(res?.data?.status?.data?.last_page);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (dataSpecial !== "") {
      getUpdateSpecialRates();
    }
  }, [getUpdateSpecialRates, update, dataSpecial]);

  const BackButton = () => {
    navigate(-1);
    setDataSpecial("");
  };
  return (
    <>
      <ModalGeneric
        isOpen={isOpenViewUpdate}
        onClose={onCloseModal}
        title={`Tarifa ${dataIdShow?.name_type_rate}`}
        size={"6xl"}
      >
        <UpdateView onClose={onCloseModal} />
      </ModalGeneric>

      <HeaderSearch>
        <HeaderForm status={""} Modulo={`Tarifas especiales`} />
      </HeaderSearch>

      <HeaderSearch>
        <TableOrder
          thead={TitleSpecialRate}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TBodySpecialRate}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={BackButton}>
          Volver
        </ButtonStyled>
      </Box>
    </>
  );
};

export default SpecialRates;
