import { Box, SimpleGrid } from "@chakra-ui/react";
// import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { TitleHeader } from "components/HeaderForm/Style";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ButtonStyled } from "styled/Buttons.styled";
import { useContext, useState, useCallback, useEffect } from "react";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";

import { __PostForm } from "request/Petitions/__Post";
import environment from "constants/apiConst";

import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { AuthContext } from "context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const FormCorrectedOther = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [fomateo] = useFormatQuantity();
  const { id = "", ActionCorrect = "" } = queryString.parse(
    location.search
  );
  const { token, decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  // const [openAlert] = AlertErrorAlert();
  const { authUser } = useContext(AuthContext);
  const [decimal, setDecimal] = useState(null);

  const [values, handleInputChange, reset, setValues] = useForm({
    // Old vars
    quantity_product_old: "",
    fob_value_old: "",
    net_weight_kilo_old: "",
    name_cost_old: "",

    // New vars
    quantity_product: "",
    fob_value: "",
    net_weight_kilo: "",
    name_cost: "",
    integration_certificate_id: id
  });
  const {
    quantity_product,
    fob_value,
    net_weight_kilo,
    name_cost,
    quantity_product_old,
    fob_value_old,
    net_weight_kilo_old,
    name_cost_old
  } = values;

  const {
    dataToEditCorrectCost,
    setShowViewCorrectCost,
    setActiveView,
    setDataToEditCorrectCost,
    createDataCorrection,
    userQualified,
    setRequestCreateCorrection
  } = useContext(IntegracionBoxCrudContex);

  const getShowOtherCost = useCallback(
    async (id, signal) => {
      setLoading(true);
      try {
        const res = await __PostForm(
          environment.GET_SHOW_OTHER_COST_CORRECTEDS,
          token,
          id,
          signal
        );
        const status = res.data.status.code;
        const data = res.data.status.data;
        if (status === 200) {
          setValues({
            quantity_product: data.quantity_product_corrected,
            fob_value: data.fob_value_corrected,
            net_weight_kilo: data.net_weight_kilo_corrected,
            name_cost: data.name_cost_corrected,
            quantity_product_old: data.quantity_product,
            fob_value_old: data.fob_value,
            net_weight_kilo_old: data.net_weight_kilo,
            name_cost_old: data.name_cost
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const dataToSend = new FormData();
    dataToSend.append("id_corrected", dataToEditCorrectCost.id_corrected);
    const controller = new AbortController();
    const { signal } = controller;
    if (dataToEditCorrectCost !== null) {
      getShowOtherCost(dataToSend, signal);
    }
    return () => controller.abort();
  }, [dataToEditCorrectCost, getShowOtherCost]);

  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault();
    let quantityProductCorrected = quantity_product;
    let net_weight_kilo_corrected = net_weight_kilo;
    let fob_value_corrected = fob_value;
    const formData = new FormData();
    formData.append("id_corrected", dataToEditCorrectCost.id_corrected);
    formData.append("name_cost_corrected", name_cost);

    if (String(quantity_product).includes(",")) {
      quantityProductCorrected = quantity_product.replaceAll(",", "");
    }
    formData.append("quantity_product_corrected", quantityProductCorrected);

    if (String(net_weight_kilo).includes(",")) {
      net_weight_kilo_corrected = net_weight_kilo.replaceAll(",", "");
    }
    formData.append("net_weight_kilo_corrected", net_weight_kilo_corrected);

    if (String(fob_value).includes(",")) {
      fob_value_corrected = fob_value.replaceAll(",", "");
    }
    formData.append("fob_value_corrected", fob_value_corrected);
    createDataCorrection(formData, () => queryClient.invalidateQueries({queryKey: [queryEnv?.OTHER_COST_CI]}));
    handleReset();
  };

  const handleReset = () => {
    setShowViewCorrectCost(false);
    setActiveView(null);
    setDataToEditCorrectCost(null);
    reset();
  };

  useEffect(() => {
    setRequestCreateCorrection(environment.UPDATE_OTHERS_COST_CORRECTED);
  }, [setRequestCreateCorrection]);

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimal(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userQualified) userC(userQualified.value);
  }, [userC, userQualified]);

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimal
            ? decimal
            : Number(decimal_values_precision),
          posibleResult
        )
      });
    }
  };

  return (
    <>
      {loading && <SpinnerComponent />}
      {!loading && (
        <div className="d-flex justify-content-between ">
          <div className="col-6 borderforms">
            <div className="mb-3">
              <TitleHeader size={"22px"}>
                Item original Nº {dataToEditCorrectCost?.item_code_corrected}
              </TitleHeader>
            </div>

            <form>
              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"name_cost_old"}
                    value={name_cost_old}
                    onChange={handleInputChange}
                    tag={"Descripción"}
                    size={100}
                    desicion={true}
                    changeValue={name_cost !== name_cost_old && "BorderCorrect"}
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"quantity_product_old"}
                    value={quantity_product_old}
                    onChange={handleInputChange}
                    tag={"Cantidad "}
                    size={100}
                    desicion={true}
                    changeValue={
                      quantity_product !== quantity_product_old &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"net_weight_kilo_old"}
                    value={net_weight_kilo_old}
                    onChange={handleInputChange}
                    tag={"Peso en Kilos"}
                    size={100}
                    desicion={true}
                    changeValue={
                      net_weight_kilo !== net_weight_kilo_old && "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"fob_value_old"}
                    value={fob_value_old}
                    onChange={handleInputChange}
                    tag={"Valor Total en US$"}
                    size={100}
                    desicion={true}
                    changeValue={fob_value !== fob_value_old && "BorderCorrect"}
                  />
                </div>
              </SimpleGrid>
            </form>
          </div>

          <div className="col-6 borderforms">
            <div className="mb-3">
              <TitleHeader size={"22px"}>
                Item original Nº {dataToEditCorrectCost?.item_code_corrected}
              </TitleHeader>
            </div>
            <form onSubmit={handleSubmit}>
              <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
                <div>
                  <LabelGroup
                    name={"name_cost"}
                    value={name_cost}
                    onChange={handleInputChange}
                    tag={"Descripción"}
                    size={100}
                    desicion={!!ActionCorrect}
                    changeValue={name_cost !== name_cost_old && "BorderCorrect"}
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"quantity_product"}
                    value={quantity_product}
                    onChange={handleInputChange}
                    tag={"Cantidad "}
                    size={100}
                    desicion={!!ActionCorrect}
                    changeValue={
                      quantity_product !== quantity_product_old &&
                      "BorderCorrect"
                    }
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"net_weight_kilo"}
                    value={net_weight_kilo}
                    onChange={handleInputChange}
                    tag={"Peso en Kilos"}
                    size={100}
                    desicion={!!ActionCorrect}
                    changeValue={
                      net_weight_kilo !== net_weight_kilo_old && "BorderCorrect"
                    }
                    onBlur={handleBlurCant}
                  />
                </div>

                <div>
                  <LabelGroup
                    name={"fob_value"}
                    value={fob_value}
                    onChange={handleInputChange}
                    tag={"Valor Total en US$"}
                    size={100}
                    desicion={!!ActionCorrect}
                    changeValue={fob_value !== fob_value_old && "BorderCorrect"}
                    onBlur={handleBlurCant}
                  />
                </div>
              </SimpleGrid>

              <div className="mb-3 ms-5">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  // flexWrap={"wrap"}
                >
                  <ButtonStyled bgColor={"#bababa"} onClick={handleReset}>
                    Cerrar
                  </ButtonStyled>
                  {!ActionCorrect && (
                    <ButtonStyled type="submit">Agregar</ButtonStyled>
                  )}
                </Box>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FormCorrectedOther;
