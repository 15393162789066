import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  // eslint-disable-next-line no-unused-vars
  others
} from "@chakra-ui/react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import bro from "../../assets/bro.png";
import { SubTitle, Title } from "styled/Title";
import { TitleMovementIntPreAut } from "pages/Pass-Form/View/ArrayHeadTable/TheadMovementInt";
import SpinnerComponent from "components/Spinner/SpinnerComponent";

/**
 *  ComponentAccordion - Componente de accordion que despliega una serie de items en el componente del las tablas con diferentes estados
 * @param {Number} page Pagina es que se encuenta la tabla.
 * @param {Boolean} loading Conocer si se activa o no el Spinner.
 * @param {Function} setPage Funcion para actualizar la pagina (hacer paginacion).
 * @param {Number} maxPage Ultima pagina de la consulta.
 * @param {Array} cabecera Lista de accordion a mostrar
 * @param {Function} changePage Funcion para actualizar la pagina (hacer paginacion).
 * @param {Array} TitleHeader Lista de titulos que tendra la tabla
 * @param {Array} currentPage array de informacion apra mostrar en las tablas
 * @param {Function} setCurrentPage Order de la tabla
 * @param {Boolean} stateNull Props para realizar la validacion de la estrucura de como me llega el array de currentPage
 * @param {Component} TBodyEntryMovement Retorna el cuerpo de la tabla
 * @return {Component} Retorna componente de accordion.
 */

export const ComponentAccordion = ({
  page,
  loading,
  setPage,
  maxPage,
  cabecera,
  stateNull,
  changePage,
  nameModule = "",
  TitleHeader,
  currentPage,
  setCurrentPage,
  TBodyEntryMovement,
  noDocumentsMessage = null,
  others,
  movement,
  children
}) => {
  return (
    <>
      <Accordion allowToggle defaultIndex={[0]}>
        {cabecera.map((items, index) => {
          const data = stateNull ? currentPage : currentPage?.[index]?.data;
          return (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton onClick={() => changePage(index)}>
                  <Box flex="1" textAlign="left">
                    <Title size={"16px"}>{items}</Title>
                  </Box>
                  <AccordionIcon color="#1F7AC3" size={24} />
                </AccordionButton>
              </h2>
              <AccordionPanel p={1}>
                {currentPage
                  ? (
                    <>
                      {data?.length === 0
                        ? (
                          <>
                            <div className="d-flex justify-content-center">
                              <img
                                style={{ width: `${20}%` }}
                                src={window.assetURL + bro}
                                alt="no hay formulario"
                              />
                            </div>
                            <SubTitle size={"15px"} className="text-center ">
                              {loading && <SpinnerComponent />}

                              {!loading && (
                                <>
                                  {noDocumentsMessage && noDocumentsMessage}
                                  {!noDocumentsMessage &&
                                    `Aún no hay ${nameModule && nameModule
                                    } ${items.toLowerCase()} asociados a esta listas`}
                                </>
                              )}
                            </SubTitle>
                          </>
                        )
                        : (
                          <div>
                            <TableOrder
                              thead={
                                movement === "Pase de entrada" &&
                                  items !== "Editables"
                                  ? TitleMovementIntPreAut
                                  : TitleHeader[items] || TitleHeader
                              }
                              data={data || []}
                              setData={setCurrentPage}
                              loading={loading}
                              tbodyData={TBodyEntryMovement[items] ||TBodyEntryMovement}
                            />
                            <Pagination
                              page={page}
                              setPage={setPage}
                              maxPage={maxPage}
                              loading={loading}
                            />
                          </div>
                        )}
                    </>
                  )
                  : others
                    ? (
                      { children }
                    )
                    : (
                      <div>
                        {loading && <SpinnerComponent />}

                        {!loading && (
                          <>
                            <div className="d-flex justify-content-center">
                              <img
                                style={{ width: `${20}%` }}
                                src={window.assetURL + bro}
                                alt="no hay formulario"
                              />
                            </div>
                            <h4 className="text-center ">
                              {noDocumentsMessage && noDocumentsMessage}
                              {!noDocumentsMessage &&
                                `Aún no hay ${nameModule && nameModule
                                } ${items.toLowerCase()} asociados a esta listas`}
                            </h4>
                          </>
                        )}
                      </div>
                    )}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};
