import { Fragment } from 'react'
import ImageNotFound from 'assets/bro.png'
import { SubTitle } from 'styled/Title'
import { ButtonTracking } from 'styled/Buttons.styled'
import InfiniteScroll from 'react-infinite-scroll-component'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import {
  Center,
  Button,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion
} from '@chakra-ui/react'
import ViewItemsEntryForm from './ViewItemsEntryForm'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import ViewItemsOutputForm from './ViewItemsOutputForm'

const Propertys = {
  propertyStatus: 'status_entryForm'
}

const hasObservations = (item, form, notations) => {
  const checkObservations = notations?.reviewsCurrent?.[form?.id]?.[item?.id]
  if (checkObservations) return '- Con observaciones'
  return ''
}

const RenderItems = ({
  notationsDestruct = null,
  FetchingStatus,
  isFetchingNextPage,
  error,
  dataItems,
  hasNextPage,
  isFetching,
  formData,
  fetchNextPage = () => { },
  showTrackingItem = () => { },
  is_commerce,
  admin,
  isInfiniteScroll = false,
  templateProperty = Propertys,
  ...attrs
}) => {
  const location = useLocation();

  const { FormEntryNumber = "", FormEntry = "", FormExitNumber = "", ExitNumber = "", Number = "" } = queryString.parse(
    location.search
  );

  const renderAccordionItem = (item, index) => (
    <AccordionItem key={index}>
      <AccordionButton
        bg='#F2F2F2'
        _hover={{
          bg: 'rgb(229, 229, 229)',
          color: 'black'
        }}
        _expanded={{
          bg: '#1F7AC3',
          color: 'white'
        }}
        _focus={{
          boxShadow: 'none'
        }}
      >
        <Box flex='1' textAlign='left'>
          {`Item N.º ${item?.item}`}{' '}
          {formData?.[templateProperty?.propertyStatus] === 'DEVUELTO' &&
            hasObservations(item, formData, notationsDestruct)}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Box className='row'>
          {renderTrackingButtons(item)}
          {(FormEntryNumber || FormEntry || (location.pathname === '/CheckFormEntry' && Number)) && <ViewItemsEntryForm
            notationsDestruct={notationsDestruct}
            data={item}
            alldata={formData}
            Estado={formData}
            {...attrs}
          />}
          {(FormExitNumber || ExitNumber || (location.pathname === '/CheckForm' && Number)) && <ViewItemsOutputForm
            notationsDestruct={notationsDestruct}
            data={item}
            alldata={formData}
            Estado={formData}
            {...attrs}
          />}
        </Box>
      </AccordionPanel>
    </AccordionItem>
  )

  const renderWithoutAccordionItem = (item, index) => (
    <Fragment key={index}>
      <h1 className='text-word '>Item Nº {item?.item}</h1>
      {item?.tracking &&
        item.tracking.some(tracking => tracking?.status === 1) &&
        (is_commerce === 1 || admin === 1) &&
        formData?.[templateProperty?.propertyStatus] !== 'BORRADOR' ? (
        <div className='mb-3 mt-2'>
          <ButtonTracking
            bg='#D13030'
            onClick={() => showTrackingItem(item?.tracking)}
          >
            En seguimiento
          </ButtonTracking>
        </div>
      ) : item?.tracking &&
        item?.tracking?.some(tracking => tracking.status === 2) &&
        (is_commerce === 1 || admin === 1) &&
        formData?.[templateProperty?.propertyStatus] !== 'BORRADOR' ? (
        <div className='mb-3 mt-2'>
          <ButtonTracking
            bg='#4FC46A'
            onClick={() => showTrackingItem(item?.tracking)}
          >
            Estuvo en seguimiento
          </ButtonTracking>
        </div>
      ) : (
        ''
      )}

      {(FormEntryNumber || FormEntry) && <ViewItemsEntryForm data={item} alldata={formData} Estado={formData} {...attrs} />}
      {(FormExitNumber || ExitNumber || Number) && <ViewItemsOutputForm data={item} alldata={formData} Estado={formData} {...attrs} />}
    </Fragment>
  )

  const renderTrackingButtons = item => {
    if (
      (is_commerce === 1 || admin === 1) &&
      formData?.[templateProperty?.propertyStatus] !== 'BORRADOR'
    ) {
      if (
        item?.tracking &&
        item?.tracking.some(tracking => tracking.status === 1)
      ) {
        return renderTrackingButton(item, 'En seguimiento', '#D13030')
      } else if (
        item?.tracking &&
        item?.tracking.some(tracking => tracking.status === 2)
      ) {
        return renderTrackingButton(item, 'Estuvo en seguimiento', '#4FC46A')
      }
    }
    return null
  }

  const renderTrackingButton = (item, label, bgColor) => (
    <div className='mb-3 mt-2'>
      <ButtonTracking
        bg={bgColor}
        onClick={() =>
          showTrackingItem(item.tracking, item?.reference, item?.id)
        }
      >
        {label}
      </ButtonTracking>
    </div>
  )

  function renderInfiniteScroll() {
    return (
      <Box>
        <InfiniteScroll
          dataLength={dataItems ? dataItems.length : 0}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<SpinnerComponent />}
          className='row'
        >
          {dataItems.map((item, index) =>
            renderWithoutAccordionItem(item, index)
          )}
        </InfiniteScroll>
      </Box>
    )
  }

  function renderLoadMoreButton() {
    return (
      <div>
        <Center>
          <Button
            colorScheme='messenger'
            onClick={fetchNextPage}
            size='md'
            mt={3}
            isLoading={isFetchingNextPage || isFetching}
            isDisabled={!hasNextPage}
          >
            {isFetchingNextPage
              ? 'Cargando más...'
              : hasNextPage
                ? 'Cargar Más'
                : 'No hay más para cargar'}
          </Button>
        </Center>
      </div>
    )
  }

  function NoAvailableItems() {
    return (
      <Center display='flex' flexDir='column'>
        <img
          src={`${window.assetURL}${ImageNotFound}`}
          alt='No se encuentran items Disponibles'
        />
        <SubTitle size='15px' className='text-center'>
          En este momento, no tenemos elementos disponibles.
        </SubTitle>
      </Center>
    )
  }

  if (FetchingStatus === 'loading') return <SpinnerComponent />
  if (FetchingStatus === 'error') return <p>Error: {error?.message}</p>

  if (dataItems && dataItems.length === 0) return NoAvailableItems()
  if (isInfiniteScroll) return renderInfiniteScroll()

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        {dataItems.map((item, index) => renderAccordionItem(item, index))}
      </Accordion>
      {renderLoadMoreButton()}
    </>
  )
}

export default RenderItems
