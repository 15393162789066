import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import MonitoringContext from "context/MonitoringContext";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __DeleteComment } from "request/Monitoring/__Get_Operations";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuCommentsFollowOrder = ({ data, onOpen }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const { setGetFollow, onCloseViewComment, setEditComment, onOpenCreateComment } = useContext(
    MonitoringContext
  );

  const handleEdit = (e) => {
    setEditComment(data);
    onOpenCreateComment();
  };

  const handleDelete = async (e) => {
    try {
      const formData = new FormData();
      formData.append('follow_order_id', data?.follow_order_id)
      formData.append('type', data?.type_id)
      const res = await __DeleteComment(token, formData);
      const message = res?.data?.status?.message;
      const code = res?.data?.status?.code;
      if (code === 200) {
        onCloseViewComment()
        setGetFollow(true);
        openAlert(message, "success");
      } else if (code !== 200) {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <Menu>
        <MenuButton>
          <IconGeneric as={HiDotsHorizontal} color="#00000085" />
        </MenuButton>

        <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
          <MenuItemStyled py={3} onClick={handleEdit}>
            <IconMenuRUD as={RiEdit2Fill} />
                Editar
          </MenuItemStyled>

          <HrDividerNoMargin />

          <MenuItemStyled py={3} onClick={handleDelete}>
            <IconMenuRUD as={MdDelete} />
                    Eliminar
          </MenuItemStyled>
        </MenuList>
      </Menu>
    </>
  );
};

export default MenuCommentsFollowOrder;
