import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import TreasuryCrudContex from "context/TreasuryContex";

import {
  TitleActiveDelete,
  TitleActiveItems
} from "pages/Treasury/ArrayList/ArrayList";
import TbodyItemsDelete from "pages/Treasury/Tables/TbodyItemDelete";
import TbodyItemsActive from "pages/Treasury/Tables/TbodyItemsActive";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __PostForm } from "request/Petitions/__Post";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { StatusForm, TextItem, TitleItems } from "./StyleTableItem";

const TablesItems = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [currentPageDelete, setCurrentPageDelete] = useState([]);

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToObservacion, setStatus, status } =
    useContext(TreasuryCrudContex);

  const getExtractDataDetail = useCallback(
    async (idRecharge) => {
      setLoading(true);
      try {
        const res = await __PostForm(
          environment.TREASURY_EXPORT_DETAIL,
          token,
          idRecharge
        );
        const statusForms = res?.data?.status?.data?.status_entryForm || res?.data?.status?.data?.status_outputForm;
        const requestExtract = res?.data?.status?.data?.activeItems;
        const requestExtractDelete = res?.data?.status?.data?.deletedItems;
        // console.log(res?.data?.status?.data);
        if (requestExtract !== undefined) {
          setStatus(statusForms);
          setCurrentPage(requestExtract);
          setCurrentPageDelete(requestExtractDelete);
        } else {
          setCurrentPage([]);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const treasuryChargeId = new FormData();
    treasuryChargeId.append("id", dataToObservacion.treasury_charge_id);
    if (dataToObservacion !== undefined && dataToObservacion !== null) {
      getExtractDataDetail(treasuryChargeId);
    }
  }, []);

  return (
    <>
      {(currentPageDelete && currentPageDelete.length !== 0) ||
      (currentPage && currentPage.length !== 0)
        ? (
          <div className="d-flex">
            <TitleItems>Estado del formulario:</TitleItems>{" "}
            <StatusForm color="#03C39A">{status}</StatusForm>
          </div>
        )
        : (
          ""
        )}

      {currentPage && currentPage.length !== 0
        ? (
          <HeaderSearch>
            <TitleItems>Items activos</TitleItems>
            <TableOrder
              thead={TitleActiveItems}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyItemsActive}
            />
          </HeaderSearch>
        )
        : currentPageDelete && currentPageDelete.length !== 0
          ? (
            <div className="mt-3">
              <HeaderSearch>
                <TitleItems>Items eliminado</TitleItems>
                <TableOrder
                  thead={TitleActiveDelete}
                  data={currentPageDelete}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyItemsDelete}
                />
              </HeaderSearch>
            </div>
          )
          : (
            <TextItem>no existe ningún ítem</TextItem>
          )}
    </>
  );
};

export default TablesItems;
