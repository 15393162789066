import styled from "styled-components/macro";

export const HrDivider = styled.hr`
  //background-color: #bababa;
  /* width: 100%; */
  background-color: ${(props) => (props.bg ? props.bg : "#bababa")};
  margin: 0.75rem auto;
`;

export const HrDividerNoMargin = styled.hr`
  background-color: #bababa;
`;
