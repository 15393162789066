import { Flex, Spacer, Textarea } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import MonitoringContext from "context/MonitoringContext";
import { useForm } from "hooks/useForm";
import { TypeFollowOrder } from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __CreateComment, __EditComment, __GetComment } from "request/Monitoring/__Get_Operations";
import { ButtonStyled } from "styled/Buttons.styled";

export const FormCreateComment = ({ data, onClose }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');

  const [values, handleInputChange, reset, setValues] = useForm({
    messages: ""
  });

  const { messages } = values;

  const { editComment, setEditComment, setGetFollow, onCloseViewComment, isOpenCreateComment } = useContext(
    MonitoringContext
  );

  const createComment = async () => {
    if (!type?.value) {
      openAlert('El tipo es requerido', "error");
    } else if (!message) {
      openAlert('El campo mensaje es requerido', "error");
    } else {
      try {
        const formData = new FormData();
        formData.append('type', type?.value)
        formData.append('message', message)
        formData.append('follow_order_id', data?.id)
        const res = await __CreateComment(token, formData);
        const code = res?.data?.status?.code;
        const messageAlert = res?.data?.status?.message;
        if (code === 200) {
          setGetFollow(true);
          openAlert(messageAlert, "success");
          onClose();
        } else {
          openAlert(messageAlert, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setGetFollow(false);
      }
    }
  }

  const handleEdit = async () => {
    if (!type?.value) {
      openAlert('El tipo es requerido', "error");
    } else if (!message) {
      openAlert('El campo mensaje es requerido', "error");
    } else {
      try {
        const formData = new FormData();
        formData.append('type', type?.value);
        formData.append('message', message);
        formData.append('id', editComment?.id);
        const res = await __EditComment(token, formData);
        const code = res?.data?.status?.code;
        const messageAlert = res?.data?.status?.message;
        if (code === 200) {
          setGetFollow(true);
          openAlert(messageAlert, "success");
          onClose();
        } else {
          openAlert(messageAlert, "error");
        }
      } catch (error) {
        console.log(error)
      } finally {
        setEditComment(null);
        onCloseViewComment();
      }
    }
  }

  const editData = async () => {
    try {
      const formData = new FormData();
      formData.append('id', editComment?.id);
      const res = await __GetComment(token, formData);
      const valores = res?.data?.status?.data;
      setMessage(valores?.message);
      setType({ value: valores?.type_id, label: valores?.type });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (editComment) {
      editData()
    }
  }, [editComment]);

  useEffect(() => {
    if (!isOpenCreateComment) {
      setEditComment(null)
    }
  }, [isOpenCreateComment]);

  return (
    <div className="d-flex flex-column pb-3">
      <InputSelect
        size={100}
        name={"service"}
        tag={"Tipo*"}
        data={TypeFollowOrder}
        set={setType}
        value={type}
      />
      <Textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Mensaje*'
        size='sm'
        style={{
          borderRadius: "5px",
          marginTop: "20px"
        }}
      />
      <Flex className="my-3">
        <ButtonStyled onClick={onClose} bgColor={"#bababa"}>
            Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={editComment ? handleEdit : createComment}>
          {editComment ? 'Editar' : 'Crear'}
        </ButtonStyled>
      </Flex>
    </div>
  )
};
