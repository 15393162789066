import styled from "styled-components/macro";
import logo from "assets/logo.png";
import logoQuillo from "assets/logo_efranco_blue.png";
import logoWhite from "assets/TermAndConditionHead.png";

export const Logo = styled.img.attrs({ src: window.assetURL + logo })`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${({ margin }) => margin || "auto"};
`;

export const LogoBarranquilla = styled.img.attrs({
  src: window.assetURL + logoQuillo
})`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${({ margin }) => margin || "auto"};
`;

export const LogoWhite = styled.img.attrs({ src: window.assetURL + logoWhite })`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${({ margin }) => margin || "auto"};
`;
