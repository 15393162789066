import React from "react";

export const PDFRrestTerritory = ({ data, old_data }) => {
  const {
    num_dex_entryFormCorrected,
    dex_date_entryFormCorrected,
    num_import_declaration_corrected,
    integration_box_certificate_corrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    customs_administration_corrected,
    name_departmentCorrected,
    code_operationCorrected
  } = data;

  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Nº DEX: </strong> {num_dex_entryFormCorrected}
        </p>

        <p>
          <strong>Fecha del DEX: </strong> {dex_date_entryFormCorrected}
        </p>

        <p>
          <strong>Nº Factura Comercial: </strong>{" "}
          {num_commercial_invoice_corrected}
        </p>

        <p>
          <strong>Departamento: </strong> {name_departmentCorrected}
        </p>
      </div>

      <div className="col">
        <p>
          <strong>Nº Declaración de Importación: </strong>{" "}
          {num_import_declaration_corrected}
        </p>
        <p>
          <strong>Administración de Aduanas: </strong>{" "}
          {customs_administration_corrected}
        </p>

        {(code_operationCorrected === 309 ||
          code_operationCorrected === 310 ||
          code_operationCorrected === 314 ||
          code_operationCorrected === 316 ||
          code_operationCorrected === 318) && (
          <>
            <p>
              <strong>Nº Autorización de salida: </strong>{" "}
              {num_exit_form_corrected}
            </p>
            <p>
              <strong>Nº Autorización de C. inte: </strong>{" "}
              {integration_box_certificate_corrected}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
