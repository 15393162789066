import React, { useContext, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete, MdContentCopy, MdCancel } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import FormCrudCRUDContext from "context/FormCrudContex";

import { useLocation } from "react-router-dom";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import GlobalContext from "context/GlobalContext";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuItems = ({ data, deleteField = true, copyField = true }) => {
  const {
    onOpenDelete,
    setDataToDelete,
    setDataToEditItems,
    show,
    setShow,
    // isOpen,
    // onToggle,
    setDataToCopyItems,
    selectOperation,
    balanceCorrecedState,
    selectItemForm,
    setUpdate,
    update
  } = useContext(FormCrudCRUDContext);

  const { itemsCorrectionForm, setItemsCorrectionForm } = useContext(GlobalContext);
  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state?.auth?.dataUser?.token);
  const location = useLocation();

  

  const handleEdit = () => {
    setShow(false);
    setDataToCopyItems(null);
    setDataToEditItems(data);
    setShow(true);
  };

  const handleDelete = (e) => {
    setDataToDelete(data);
    onOpenDelete();
  };

  const handleCopyItems = (e) => {
    setShow(false);
    setDataToEditItems(null);
    setDataToCopyItems(data);
    setShow(true);
  };
  const handleMessage = (e) => {
    openAlert("Seleccione tipo de operación", "error");
  };

  const handleCancel = async () => {
    const res = await __GetDataId((location?.pathname === '/FormCorrection' ? environment?.CANCEL_ENTRY_FORM_CORRECTED_ITEM : environment?.CANCEL_OUTPUT_FORM_CORRECTED_ITEM), token, data?.id);
    if (res?.data?.status?.code !== 200) {
     return openAlert(res?.data?.status?.message, "error");
    }
    openAlert(res?.data?.status?.message, "success");
    setUpdate(!update);
  }


  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <>
          <MenuItemStyled
            py={3}
            onClick={
              selectOperation === null && balanceCorrecedState === 1
                ? handleMessage
                : handleEdit
            }
          >
            <IconMenuRUD as={RiEdit2Fill} />
            Editar
          </MenuItemStyled>
          <HrDividerNoMargin />
        </>

        {selectItemForm >= 700 && selectItemForm <= 799
          ? null
          : <div>
            {copyField && (
              <MenuItemStyled py={3} onClick={handleCopyItems}>
                <IconMenuRUD color="#03C39A" as={MdContentCopy} />
                Copiar
              </MenuItemStyled>
            )}

            <HrDividerNoMargin />
            {deleteField && (
              <MenuItemStyled py={3} onClick={handleDelete}>
                <IconMenuRUD color={"red"} as={MdDelete} />
                Eliminar
              </MenuItemStyled>
            )}
          </div>}

        {
          (itemsCorrectionForm?.data?.length > 1 && itemsCorrectionForm?.page >= 1) && (
            <MenuItemStyled
              py={3}
              onClick={handleCancel}
            >
              <IconMenuRUD color={"orange"} as={MdCancel} />
              Anular
            </MenuItemStyled>
          )
        }

      </MenuList>
    </Menu>
  );
};

export default MenuItems;
