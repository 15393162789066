import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";

/**
 * BtnWithToltip - Radio Boton de seleccion unica complementando el formulario.
 * @param {string} msgToltip Mensaje toltip para el boton.
 * @param {Function} hableSubmit Funcion a llamar
 * @param {Function} icon Icono que ira dentro del boton.
 * @return {Component} Retorna seleccion de una opcion por el usuario.
 */

export const BtnWithToltip = ({ msgToltip, hableSubmit, icon }) => {
  return (
    <Tooltip placement="top" hasArrow label={msgToltip}>
      <ButtonHeaderModule
        onClick={hableSubmit}
        bgColor="#03C39A"
        className="d-flex float-end"
      >
        <IconGeneric color="#fff" as={icon} />
      </ButtonHeaderModule>
    </Tooltip>
  );
};
