/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Icon,
  Input,
  List,
  ListIcon,
  ListItem,
  Tooltip,
  Text
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import {
  GrDocumentWord,
  GrDocumentPdf,
  GrDocumentWindows,
  GrDocumentText
} from "react-icons/gr";
import { useSelector } from "react-redux";
import { __DeleteAttachmentPost } from "request/Pases/__CrudPass";
import useDocumentSubmission from "./hook/useSubmit";
import useAttachmentHandler from "./hook/useAttachmentHandler ";
import useUploadButton from "./hook/useUploadButton ";

const AttachedAbove = ({
  title = "Adjunto",
  formId,
  data,
  ServicetoSave,
  DeleteToService,
  setUpdate = null,
  accept = ".doc,.docx,image/png,.png,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel",
  ...props
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [showDocuments, setShowDocuments] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const { handleUpload, inputRef } = useUploadButton();

  const { handleAttachment, attachedUpload } = useAttachmentHandler(3000000, [
    ".doc",
    ".docx",
    ".pdf",
    ".png",
    "image/png",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel"
  ]);

  const { update, isSuccess } = useDocumentSubmission({
    getDocuments: attachedUpload,
    formId,
    ServicetoSave,
  });

  useEffect(() => {
    setUpdate && setUpdate((prev) => !prev);
  }, [isSuccess]);

  const HandleDeleteAttachment = async (name, id) => {
    const formData = new FormData();
    formData.append("attachment", name);
    try {
      const res = await __DeleteAttachmentPost(
        DeleteToService,
        token,
        formData,
        id
      );
      if (res.data.status.code === 403) {
        setUpdate && setUpdate((prev) => !prev);
        openAlert(res?.data?.status?.message, "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAttachment = (url) => {
    window.location.assign(url);
  };

  useEffect(() => {
    setUpdate && setUpdate(update);
  }, [update]);

  useEffect(() => {
    if (data) setShowDocuments(data);
  }, [data]);

  return (
    <Box {...props}>
      <Text className="mb-2" fontSize={"18px"} fontWeight={600}>
        {title}
      </Text>

      <Input
        onChange={handleAttachment}
        type="file"
        ref={inputRef}
        display={"none"}
        accept={accept}
        multiple
      />

      <Button
        _hover={{
          bg: "#03C39A"
        }}
        bg={"#03C39A"}
        textColor={"white"}
        size="sm"
        w="auto"
        onClick={handleUpload}
      >
        {"Agregar Adjunto"}
      </Button>

      <List>
        {showDocuments?.map((items) => (
          <ListItem key={items?.name || items?.name_attachment}>
            <ListIcon
              as={
                items?.type
                  ? items?.type.includes("word")
                    ? GrDocumentWord
                    : items?.type.includes("pdf")
                      ? GrDocumentPdf
                      : items?.type.includes("excel")
                        ? GrDocumentWindows
                        : GrDocumentText
                  : items?.name_attachment.includes("word")
                    ? GrDocumentWord
                    : items?.name_attachment.includes("pdf")
                      ? GrDocumentPdf
                      : items?.name_attachment.includes("excel")
                        ? GrDocumentWindows
                        : items?.name_attachment.includes("xls")
                          ? GrDocumentWindows
                          : GrDocumentText
              }
              color="green.500"
            />
            {items?.name_attachment
              ? (
                <Tooltip placement="top" hasArrow label="Descargar">
                  <span
                    type={Button}
                    onClick={() => downloadAttachment(items?.attachment_url)}
                  >
                    {items?.name_attachment}{" "}
                  </span>
                </Tooltip>
              )
              : (
                <>{items?.name}</>
              )}
            <Icon
              _hover={{
                cursor: "pointer"
              }}
              onClick={() => {
                HandleDeleteAttachment(items?.attachment, items?.id);
              }}
              w={6}
              h={6}
              color="red.500"
              as={AiFillDelete}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AttachedAbove;
