import React, { useCallback, useContext, useEffect, useState } from "react";
import ThirdPartiesContext from "context/ThirdPartiesContext";
import { useForm } from "hooks/useForm";
import { ButtonStyled } from "styled/Buttons.styled";
import { Box, Heading, Input, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { __AllQualifiedUSers, __GetAll } from "request/configuration/__QualifiedUsers";
import { AuthContext } from "context/AuthContext";
import InputSelect, { Nacionalidad } from "components/InputSelect/InputSelect";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import CardUploadFile from "../Components/CardUploadFile";
import { FaPlus } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { __Post } from "request/Petitions/__Post";
import { __GetDataId } from "request/Petitions/__Get";
import { Regex } from "utils/Regex";

const ThirdPartiesForm = ({ setupdateThirdParties = null }) => {
  const initialForm = {
    identification_num: "",
    internal_code: "",
    business_name: "",
    address: "",
    type: "",
    acronym: "",
    alias: "",
    identificationType_id: "",
    qualifiedUser_id: ""
  };
  const [selUserQ, setSelUserQ] = useState([]);
  const [typeId, setTypeId] = useState([]);
  const [selTypeId, setSelTypeId] = useState("");
  const [selType, setSelTye] = useState([]);
  const [userUserQ, setUserUserQ] = useState("");
  const [userType, setUserType] = useState("");
  const [form, handleInputChange, resetForm, setForm] = useForm(initialForm);
  const { onCloseModal, dataToEdit, createData, editData, setUpdate, update } = useContext(ThirdPartiesContext);
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [numericos, setNumerico] = useState("");
  const [numberNit, setNumberNit] = useState("");
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [files, setFiles] = useState([]);/** estado para manejar los archivos adjuntos */

  const handleChange = (evt) => {
    const financialGoal = evt.target.validity.valid
      ? evt.target.value
      : numericos;
    setNumerico(financialGoal);
  };
  const handleChangeNit = (evt) => {
    const financialGoal = evt.target.validity.valid
      ? evt.target.value
      : numberNit;
    setNumberNit(financialGoal);
  };

  const validateForm = (form, openAlert) => {
    if (!form.type) {
      openAlert(`Seleccione el tipo`, "error");
      return false;
    }
    if (!form.qualifiedUser_id && authUser.admin === 1) {
      openAlert(`Seleccione el usuario calificado`, "error");
      return false;
    }

    if (numberNit === "" && form.type === "Nacional") {
      openAlert(`Ingrese el Nit`, "error");
      return false;
    }

    if (!form.business_name.trim()) {
      openAlert("Ingrese la razón social", "error");
      return false;
    }

    if (!form.internal_code) {
      openAlert(`Ingrese el código interno`, "error");
      return false;
    }
    return true;
  };

  const typeSel = [
    { value: "Nacional", label: "Nacional" },
    { value: "Extranjera", label: "Extranjera" }
  ];

  const getUserQualified = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const infoUsuarios = res?.data?.status?.data.map((item) => ({
        value: item?.id,
        label: item?.nit_qualified + " - " + item?.name_qualified
      }));
   
      setSelUserQ(infoUsuarios);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserQualified()
    const getData = async () => {
      try {
        setSelTye(typeSel);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getDataTypeId = async () => {
      try {
        const res = await __GetAll(environment.TYPE_OF_IDENTIFICATIONS_GET_ALL, token);
        const array = res?.data?.status?.data;
        setTypeId(
          array.map((item) => ({
            value: item.id,
            label: item.name_identification_type
          }))
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getDataTypeId();
  }, []);

  useEffect(() => {
    if (dataToEdit) {
      const getData = async () => {
        try {
          setUserUserQ({
            value: dataToEdit?.qualifiedUser_id,
            label: dataToEdit?.name_qualified
          });
          setSelTypeId({
            value: dataToEdit?.identificationType_id,
            label: dataToEdit?.name_identification_type
          })
        } catch (error) { }
      };
      getData();
      setForm(dataToEdit);
      setUserType(dataToEdit.type);
      setNumerico(dataToEdit.phone);
      setNumberNit(dataToEdit?.identification_num);
      setFiles(dataToEdit?.Third_party_document)
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  /**mutación para adjuntar archivos si todo sale bien lanza una alerta, actualiza la tabla y cierra el modal*/
  const { mutate: mutateHandleFiles } = useMutation({
    mutationFn: ([endpoint, token, body]) => __Post(endpoint, token, body),
    onSuccess: (res) => {
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, 'success')
        setUpdate(!update)
        onCloseModal()
      }
    }
  })


  /**mutación para eliminar archivos si todo sale bien lanza una alerta*/
  const { mutate: mutateDeleteFiles } = useMutation({
    mutationFn: ([endpoint, token, id]) => __GetDataId(endpoint, token, id),
    onSuccess: (res) => {
      if (res.data.status.code === 200) {
        openAlert(res.data.status.message, 'success')
      }
    }
  })


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dataToEdit) {
      const data = {
        ...form,
        id: dataToEdit.id,
        qualifiedUser_id:
          authUser.admin === 1 || authUser.is_commerce === 1
            ? userUserQ.value
            : authUser.userQualified,
        type: userType,
        identificationType_id: selTypeId?.value,
        phone: numericos,
        identification_num: numberNit
      };
      setLoading(true);
      try {
        if (!validateForm(data, openAlert)) return;//validaciones
        await editData(data);// función que se encarga de la edición de terceros
        const body = new FormData();//estructura para adjuntar archivos
        body.append('thirdParty_id', dataToEdit?.id);// id del tercero que se esta editando
        files?.forEach((item, index) => { //se recorre el array de archivos una característica especial de los archivos en la edición es que estos están compuestos por los archivos previamente adjuntados que back retorna y por los nuevos que se adjuntan
          body.append(`third_party_files[${index}][document_description]`, item?.document_description);//descripción del archivo
          item?.document_url && body.append(`third_party_files[${index}][id]`, item?.id);//document_url es la url del archivo que permite descargarlo esto es algo que es característico de los archivos que retorna back por eso se manda el id del archivo que también retorna back esto sirve como indicativo para back de que este no es un archivo nuevo
          item?.file && body.append(`third_party_files[${index}][file]`, item?.file);// el archivo en cuestión este File es característico de los archivos nuevos que se suben por eso no se envía nada en caso de que sea un archivo previamente adjuntado 
        })
        mutateHandleFiles([environment.DOCUMENT_SAVE_THIRD_PARTY, token, body])
      } catch (error) {
      } finally {
        setLoading(false);
        if (setupdateThirdParties) setupdateThirdParties((prev) => !prev);
      }
    } else {
      const data = {
        ...form,
        qualifiedUser_id:
          authUser.admin === 1 || authUser.is_commerce === 1
            ? userUserQ.value
            : authUser.userQualified,
        type: userType,
        identificationType_id: selTypeId?.value,
        phone: numericos,
        identification_num: numberNit
      };

      setLoading(true);
      try {
        if (!validateForm(data, openAlert)) return; // validaciones
        if (files?.length === 0) { return createData(data)} // si no hay archivos el flujo es normal básicamente solo se envían los datos del formulario de tercero y se crea el tercero y se cierra el modal 

        /**esta variable se encarga de almacenar los datos que llegan al crear un tercero también con el @param {bolean} false evita que se cierre el modal para asi permitir que se puedan subir los archivos ya que estos necesitan el id del tercero para asociarlos  */
        const datos = await createData(data, false)
        const body = new FormData();//estructura para adjuntar archivos
        body.append('thirdParty_id', datos?.id);//id del tercero creado
        files?.forEach((item, index) => {// se recorren los archivos
          body.append(`third_party_files[${index}][document_description]`, item?.document_description);//descripción del archivo 
          body.append(`third_party_files[${index}][file]`, item?.file);// el archivo en cuestión
        })
        mutateHandleFiles([environment.DOCUMENT_SAVE_THIRD_PARTY, token, body])//la mutación para adjuntar archivos
      } catch (error) {
      } finally {
        setLoading(false);
        if (setupdateThirdParties) setupdateThirdParties((prev) => !prev);
      }
    }
  };


  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  /** función para modificar (añadir elementos) el array de files desde el componente CardUploadFiles @param {object} uploadedFiles un objeto compuesto por id archivo y descripción */
  const handleFiles = (uploadedFiles) => {
    const newFiles = files.map(file => file?.id === uploadedFiles?.id ? uploadedFiles : file);
    setFiles(newFiles);
  }


  /** función para modificar (eliminar elementos) el array de files desde el componente CardUploadFiles @param {number} id el id del elemento a eliminar*/
  const deleteFile = (id) => {
    const newFiles = files.filter(file => file.id !== id);
    mutateDeleteFiles([environment.DOCUMENT_DELETE_THIRD_PARTY, token, id])
    setFiles(newFiles);
  }

  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa",
          paddingBottom: "1rem"
        }}
      >
        {dataToEdit ? "Editar Tercero" : "Agregar Tercero"}
      </Text>

      <form onSubmit={handleSubmit}>
        {authUser.admin === 1 || authUser.is_commerce === 1
          ? (
            <SimpleGrid minChildWidth="250px" spacing="20px">
              <div>
                <InputSelect
                  name={"qualifiedUser_id"}
                  tag={"Usuario Calificado"}
                  data={selUserQ}
                  value={userUserQ}
                  set={setUserUserQ}
                />
              </div>
            </SimpleGrid>
          )
          : (
            ""
          )}

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <Nacionalidad
              data={typeSel}
              tag={"Tipo"}
              setMendigo={setUserType}
              mendigo={userType}
            />
          </div>

          <div>
            <InputSelect
              name={"TypeIdentification"}
              tag={"Tipo de identificacion"}
              data={typeId}
              value={selTypeId ?? form?.name_identification_type}
              set={setSelTypeId}
            />
          </div>

        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">

          <div>
            <LabeFormlStyled>Numero de identificacion</LabeFormlStyled>
            <InputFormStyled
              // onChange={handleInputChange}
              type="text"
              pattern="[0-9]*"
              onInput={handleChangeNit.bind(this)}
              value={numberNit ?? form?.identification_num}
              maxLength={MaxLength.thirdParties.identification_num}
            />
          </div>

          <div>
            <LabeFormlStyled>Razón Social</LabeFormlStyled>
            <InputFormStyled
              name="business_name"
              value={form.business_name}
              onChange={(e) => handleRegex(e, 'all', Regex?.all)}
              maxLength={MaxLength.thirdParties.business_name}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Siglas</LabeFormlStyled>
            <InputFormStyled
              name="acronym"
              value={form.acronym}
              onChange={(e) => handleRegex(e, 'all', Regex?.all)}
              maxLength={MaxLength.thirdParties.acronym}
            />
          </div>

          <div>
            <LabeFormlStyled>Codigo interno</LabeFormlStyled>
            <InputFormStyled
              name="internal_code"
              value={form.internal_code}
              onChange={(e) => handleRegex(e, 'all', Regex?.all)}
              maxLength={MaxLength.thirdParties.internal_code}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Alias</LabeFormlStyled>
            <InputFormStyled
              name="alias"
              value={form.alias}
              onChange={(e) => handleRegex(e, 'all', Regex?.all)}
              maxLength={MaxLength.thirdParties.alias}
            />
          </div>

          <div>
            <LabeFormlStyled>Teléfono</LabeFormlStyled>
            <InputFormStyled
              onChange={handleInputChange}
              type="text"
              pattern="[0-9]*"
              onInput={handleChange.bind(this)}
              value={numericos ?? form?.phone}
              maxLength={MaxLength.thirdParties.phone}
            />
          </div>

        </SimpleGrid>
        <div>
          <LabeFormlStyled>Dirección</LabeFormlStyled>

          <InputFormStyled
            name="address"
            value={form.address}
            onChange={handleInputChange}
            maxLength={MaxLength.thirdParties.address}
          />
        </div>


        <Box>
          {/* Carga de archivos */}
          <Heading mb="4px" as="h2" size="sm">Archivo</Heading>

          {/* se crea una carta por cada archivo que se desee añadir o por cada uno de los archivos que se hayan cargado previamente y los retorne backend */}
          {files?.map((item, index) => (
            <CardUploadFile key={item?.id} item={item} handleFiles={handleFiles} deleteFile={deleteFile} />
          ))}

          {/* Botón para agregar archivos */}
          {/* permite crear un hueco en el array de archivos para asi poder cargar nuevos archivos */}
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Tooltip label='Agregar' hasArrow placement='right'>
              <Box
                w='30px'
                h="30px"
                bg="blue.500"
                borderRadius="md"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition="all 0.3s"
                _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
                onClick={() => {
                  setFiles(prev => [...prev, { id: Math.random(), file: '', document_description: '' }])
                }}
              >
                <FaPlus size={10} color="white" />
              </Box>
            </Tooltip>
          </Box>
          {/* Carga de archivos */}
        </Box>


        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled type="button" bgColor="#bababa" onClick={onCloseModal}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Guardar</ButtonStyled>
        </Box>
        {loading && <SpinnerComponent />}
      </form>
    </>
  );
};

export default ThirdPartiesForm;
