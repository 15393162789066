import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyCountryAc = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [loadingIcons, setLoadingIcons] = useState(false);

  const [openAlert] = AlertErrorAlert();

  const desactivarAndActivar = async (result) => {
    if (loadingIcons) return;
    setLoadingIcons(true);
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.COUNTRY_DESACTIVE, data.id)
          : await __ACTIVAR(token, environment.COUNTRY_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingIcons(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.code_tradeAgreement}</Td>
      <Td className="text-center">{data?.name_country}</Td>
      <Tooltip
        placement="top"
        hasArrow
        label={
          data?.status_countrytradeAgreement === 1 ? "Desactivar" : "Activar"
        }
      >
        <TdState
          onClick={() =>
            desactivarAndActivar(data?.status_countryTradeAgreement)
          }
          className="text-center"
          isNumeric
          text={data?.status_countrytradeAgreement}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_countryTradeAgreement}
          />
        </TdState>
      </Tooltip>
      <Td className="text-center" isNumeric>
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyCountryAc;
