import React, { useContext, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { Tooltip, useDisclosure } from "@chakra-ui/react";
import { RxReload } from "react-icons/rx";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormCreateMonitoring } from "./form/FormCreateMonitoring";
import {
  AccordionTheadTracking
} from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { useSelector } from "react-redux";
import {
  __Get_Monitoring,
  __Get_MonitoringSearch
} from "request/Monitoring/__Get_Monitoring";
import TbodyMonitoring from "pages/Seguimiento/Table/TBodyMonitoring";
import TableOperationsMonitoring from "./Table/TableMonitoring";
import { IconGeneric } from "styled/Icons.styled";
import MonitoringContext from "context/MonitoringContext";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { Title } from "styled/Title";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";

import { useForm } from "hooks/useForm";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import { FormSearchMonitoring } from "./form/FormSearchMonitoring";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useDebounce } from "hooks/useDebouce";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import { theadReference } from "./TheHeadReference";
import TbodyReference from "./Table/TheBodyReference";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { useSearchUrl } from "hooks/useSearchUrl";

export const Monitoring = ({ SetExitCreate }) => {
  const { state, handleSearchParams } = useSearchUrl({ getParams: ["reference"], nestedProp: "searchSimple" })
  const { cleanState: stateSearchAdvance, clearParamsSearch } = useSearchUrl({ getParams: ["form_type", "service", "follow_order", "form_code", "code", "tdn", "status", "start_date", "end_date"], nestedProp: "searchAdvance" })

  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [loading, setLoading] = useState([]);
  const [loadingReference, setLoadingReference] = useState(false);
  // const [attachmentShow, setAttachmentShow] = useState();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);

  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(stateSearchAdvance);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [openAlert] = AlertErrorAlert();

  const [pageSearch, setPageSearch] = useState(1);
  const [maxPageSearch, setMaxPageSearch] = useState(1);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);
  const [dataReference, setDataReference] = useState([]);

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
    reference: state?.reference || "",
  });

  const { searchInformacion, valueQualified, reference } = values;

  const { isOpenSearchTracking, onOpenSearchTracking, onCloseSearchTracking } =
    useContext(MonitoringContext);

  const getMonitoring = async () => {
    setLoading(true);
    try {
      const res = await __Get_Monitoring(token, page);
      setCurrentPage(res.data.status.data);
      setMaxPage(res.data.status.data[0].last_page);
      // setAttachmentShow();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  useEffect(() => {
    getMonitoring();
  }, [page, freeZone_id]);

  useEffect(() => {
    setPageSearch(1);
  }, [searchInformacion, freeZone_id]);

  const backSearch = () => {
    setValues({ searchInformacion: "", valueQualified: [], reference: "" });
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    setPage(1);
    setPageSearch(1);
    setChangeViewReference(false);
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] })
  };

  const searchSimple = async (informacion) => {
    setLoading(true);
    try {
      const res = await __Get_MonitoringSearch(token, informacion, pageSearch);
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        openAlert(res?.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data);
        setMaxPageSearch(res?.data?.status?.data[0]?.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearchTracking();
    }
  };

  useEffect(() => {
    const formData = new FormData();
    console.log({ searchInformacion })

    valueQualified && valueQualified?.length && formData.append("qualifiedUser_id", valueQualified?.value);
    searchInformacion && formData.append("search", searchInformacion);
    searchInformacion && formData.append("advanced_search", true);
    if (typeof searcheAdvanced === "object") {
      for (const pair of Object.entries(searcheAdvanced)) {
        pair[1] && formData.append(pair[0], pair[1]);
      }
    } else {
      for (const pair of searcheAdvanced.entries()) {
        pair[1] && formData.append(pair[0], pair[1]);
      }
    }




    if (formData.keys().length !== 0) {
      setLoading(true);
      setChangeViewSearch(true);
      searchSimple(formData);
      return
    }
    setChangeViewSearch(false);
    getMonitoring();

  }, [
    searchInformacion,
    searcheAdvanced,
    valueQualified,
    pageSearch,
    freeZone_id
  ]);

  const [accordionIndex, setAccordionIndex] = useState(0);

  const changePage = (index) => {
    console.log({ index, accordionIndex })
    setAccordionIndex(index);
    const lastPage = currentPage[index]?.last_page;
    setMaxPage(lastPage || 1);
  };

  const [changeViewReference, setChangeViewReference] = useState(false);
  const helper = async () => {
    try {
      if (reference) {
        setLoadingReference(true);
        const res = await __Post(environment.GET_TRACKING_REFERENCES, token, { reference });
        if (res?.data?.status?.code !== 200 || res?.data?.status?.data?.data?.length === 0) {
          setValues({ ...values, reference: "" });
          setDataReference([]);
          setChangeViewReference(false);
          openAlert('No hay seguimientos con esta referencia', 'error');
          setValues({ ...values, reference: "" });
          return
        }
        setLoadingReference(false);
        setChangeViewReference(true);
        setDataReference(res?.data?.status?.data?.data)
        handleSearchParams({ name: "reference", value: reference })
        return
      }
      handleSearchParams({ name: "reference", value: null })
      setChangeViewReference(false);
    } catch (e) {

    }
  }

  useEffect(() => {
    if (state?.reference) {
      helper();
    }
  }, [state?.reference])


  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchTracking}
        onOpen={onOpenSearchTracking}
        onClose={onCloseSearchTracking}
        title="Búsqueda avanzada"
      >
        <FormSearchMonitoring
          setSearchAdvanced={setSearchAdvanced}
          onClose={onCloseSearchTracking}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size="5xl"
        title="Crear seguimiento"
      >
        <FormCreateMonitoring getMonitoring={getMonitoring} onClose={onClose} />
      </ModalGeneric>
      <div className="content-cards">
        <HeaderSearch>
          <Title style={{ margin: "25px 0", fontSize: "27px" }}>
            Seguimiento
          </Title>
          <SearchAdvancedModule
            handleInputChange={handleInputChange}
            values={values}
            onOpenSearch={onOpenSearchTracking}
            changeViewSearch={changeViewSearch}
            backSearch={backSearch}
            setValues={setValues}
            allAccess={true}
            viewFilterQualified
            disableSearch={changeViewReference}
          >
            <form onSubmit={(e) => {
              e.preventDefault();
              helper();
            }} >
              <LabelGroup
                name={"reference"}
                value={reference}
                onChange={(e) => setValues({ ...values, reference: e.target.value })}
                tag={"Buscar por referencia"}
                size={100}
                desicion={false}
                placeholder={"Ingrese una referencia"}
              />
            </form>
          </SearchAdvancedModule>
          <div className="align-items-end justify-content-between mb-4 d-flex w-100">
            <div className="d-flex w-40 p-20">
              <Tooltip placement="top" hasArrow label={"Recargar"}>
                <ButtonStyled
                  wd="60px"
                  bgColor="#FFC53F"
                  onClick={getMonitoring}
                >
                  <IconGeneric color="#fff" as={RxReload} />
                </ButtonStyled>
              </Tooltip>
            </div>
            <ButtonStyled onClick={onOpen}>Crear seguimiento</ButtonStyled>
          </div>

          {changeViewReference &&
            <div>
              {loadingReference
                ? <SpinnerComponent />
                : <TableOrder
                  thead={theadReference}
                  tbodyData={TbodyReference}
                  data={dataReference}
                  getMonitoring={getMonitoring}
                />}
            </div>}

          {!changeViewReference && (
            changeViewSearch ? (
              currentPage !== undefined && (
                <div>
                  <TableOperationsMonitoring
                    thead={AccordionTheadTracking}
                    data={currentPageTwo}
                    setData={setCurrentPage}
                    loading={loading}
                    tbodyData={TbodyMonitoring}
                    getMonitoring={getMonitoring}
                    changePage={changePage}
                    page={pageSearch}
                    setPage={setPageSearch}
                    maxPage={maxPageSearch}
                    currentAccordionIndex={accordionIndex}
                  />
                </div>
              )
            ) : (
              <>
                <TableOperationsMonitoring
                  thead={AccordionTheadTracking}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyMonitoring}
                  getMonitoring={getMonitoring}
                  changePage={changePage}
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  currentAccordionIndex={accordionIndex}
                />
                {/*              <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                /> */}
              </>
            )
          )}
        </HeaderSearch>
      </div>
    </>
  );
};
