import React, { useContext, useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useLocation } from "react-router-dom";
import { Title } from "styled/Title";
import { renderInputWithLabel } from "../../ViewItems/ViewItemsEntryForm";

const FormViewRestWorld = ({
  data,
  notations = null,
  activeReview = true,
  code,
}) => {
  const location = useLocation();
  const { dataType, typeEntry, setTypeEntry } = useContext(FormCrudCRUDContext);

  const { pathname } = location;
  const [Status, setStatus] = useState([]);

  useEffect(() => {
    if (!data) return;
    const { status_entryForm, status_outputForm } = data;
    setStatus(status_entryForm || status_outputForm);
  }, [data]);

  const metaDataDTACabotage = {
    DTA: {
      name: "dcl_customs_transit",
      value: data?.dcl_customs_transit || 0,
      tag: "Dcl. Tránsito aduanero",
    },
    /*     "Planilla de envío": {
          name: "shipping_form",
          value: data?.shipping_form || "",
          tag: "Planilla de envío",
        }, */
    Cabotaje: {
      name: "cabotage",
      value: data?.cabotage || 0,
      tag: "Cabotaje",
    },
  }[typeEntry || ""];

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data,
  };


  return (
    <div
      className="mx-1 shadow-none p-1 mb-5  rounded "
      style={{ background: "#f2f2f2" }}
    >
      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del mundo
      </Title>
      <SimpleGrid minChildWidth="200px" spacing="10px">
        <div>
          <RadioCheck
            set={setTypeEntry}
            value={typeEntry}
            item={dataType}
            disables={true}
          />
        </div>
      </SimpleGrid>
      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Dcl. Tránsito */}

        {typeEntry !== "Planilla de envío" && (
          <>
            {renderInputWithLabel({
              labelText: metaDataDTACabotage?.tag,
              name: metaDataDTACabotage?.name,
              manualValue: metaDataDTACabotage?.value,
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9",
              },
              wrapInDiv: false,
            })}

            {/* Fecha de vencimiento */}
            {renderInputWithLabel({
              labelText:
                typeEntry === "DTA"
                  ? "Fecha de vencimiento Tránsito "
                  : typeEntry === "Cabotaje"
                    ? `Fecha de vencimiento ${typeEntry} `
                    : "",
              name:
                typeEntry === "DTA"
                  ? "transit_expiration_date"
                  : typeEntry === "Cabotaje"
                    ? "cabotage_expiration_date"
                    : "",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9",
              },
              wrapInDiv: false,
            })}
          </>
        )}

        {/* Nº Documento Transporte */}
        {renderInputWithLabel({
          labelText: `Nº Documento Transporte`,
          name: "num_transport_document",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Fecha Documento Transporte */}
        {renderInputWithLabel({
          labelText: `Fecha Documento Transporte`,
          name: "transp_doc_date",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}

        {/* Manifiesto de carga */}
        {renderInputWithLabel({
          labelText: `Manifiesto de carga`,
          name: "cargo_manifest_entryForm",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}

        {/* Nº Autorización de salida */}
        {(code === 108 || code === 107 || data?.reentry_operation === 1) &&
          renderInputWithLabel({
            labelText: `"Nº Autorización de salida`,
            name: "num_exit_form",
            activeComment: isCheckFormExit || isCheckFormEntry,
            annotationInfo,
            additionalProps: {
              background: "#F9F9F9",
              thousandSeparator: false,
            },
            wrapInDiv: false,
          })}

        {/* Nº Autorización de C. inte */}
        {(code === 108 || code === 107 || data?.reentry_operation === 1) &&
          renderInputWithLabel({
            labelText: `Nº Autorización de C. inte`,
            name: "integration_box_certificate",
            activeComment: isCheckFormExit || isCheckFormEntry,
            annotationInfo,
            additionalProps: {
              background: "#F9F9F9",
              thousandSeparator: false,
            },
            wrapInDiv: false,
          })}
      </SimpleGrid>

      {/* Nº Factura comercial */}
      {renderInputWithLabel({
        labelText: `Nº Factura comercial`,
        name: "num_commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
        },
        wrapInDiv: false,
        disableFormatting: true,
      })}
    </div>
  );
};

export default FormViewRestWorld;
