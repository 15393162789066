/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __ShowVehicularDoor } from "request/configuration/__VehicularDoor";
import { useSelector } from "react-redux";

export const FormShowVheicularDoor = () => {
  const initialForm = {
    name_vehicularDoor: "",
    type_vehicularDoor: "",
    status_vehicularDoor: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    if (dataToShow) {
      //   setForm(dataToShow);
      const initialpos = async () => {
        try {
          const res = await __ShowVehicularDoor(token, dataToShow.id);
          setForm(res?.data?.status?.data);
        } catch (error) {}
      };
      initialpos();
    } else {
      setForm(initialForm);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form?.name_rol : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Nombre Puerta vehicular</LabeFormlStyled>
              <InputFake>{form?.name_vehicularDoor ?? "N/A"}</InputFake>
            </FormInputGroup>
          </div>
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>Tipo Puerta vehicular</LabeFormlStyled>
              <InputFake>
                {form?.type_vehicular_door_entry === 1 &&
                form?.type_vehicular_door_exit === 1
                  ? "Entrada/Salida"
                  : form?.type_vehicular_door_entry === 1 &&
                    form?.type_vehicular_door_exit === 0
                    ? "Entrada"
                    : form?.type_vehicular_door_exit === 1 &&
                    form?.type_vehicular_door_entry === 0
                      ? "Salida"
                      : "N/A"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormInputGroup>
              <LabeFormlStyled>¿Activo?</LabeFormlStyled>
              <InputFake>
                {form?.status_vehicularDoor === 1 ? "Activo" : "Inactivo"}
              </InputFake>
            </FormInputGroup>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};
