import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useModal } from 'hooks/useModal'
import { useSelector } from 'react-redux'
import { __Get_FollowOrderStopTracking } from 'request/Monitoring/__Activate_Or_Inactivate'
const { createContext, useState } = require('react')
const MonitoringContext = createContext()

export const MonitoringContextProvider = ({ children }) => {
  const [isOpenModalInfo, onOpenModalInfo, onCloseModalInfo] = useModal()
  const [
    isOpenCreateTrackingFmm,
    onOpenCreateTrackingFmm,
    onCloseCreateTrackingFmm
  ] = useModal()
  const [isOpenSearchTracking, onOpenSearchTracking, onCloseSearchTracking] =
    useModal()
  const [isOpenStopTracking, onOpenStopTracking, onCloseStopTracking] =
    useModal()
  const [
    isOpenCreateFollowOrder,
    onOpenCreateFollowOrder,
    onCloseCreateFollowOrder
  ] = useModal()
  const [isOpenCreateComment, onOpenCreateComment, onCloseCreateComment] =
    useModal()
  const [dataTableComment, setDataTableComment] = useState([])
  const [editFollowOrder, setEditFollowOrder] = useState(null)
  const [getFollowO, setGetFollow] = useState(false)
  const [dataCreateFollowOrder, setDataCreateFollowOrder] = useState(null)
  const [editComment, setEditComment] = useState(null)
  const [deleteFollowOrder, setDeleteFollowOrder] = useState(null)
  const token = useSelector(state => state.auth.dataUser.token)
  const [openAlert] = AlertErrorAlert()
  const optionsFollow = []

  const [isOpenViewComment, onOpenViewComment, onCloseViewComment] = useModal()

  const onCloseModal = () => {
    if (isOpenViewComment) {
      onCloseViewComment()
      return
    }

    if (isOpenModalInfo) {
      onCloseModalInfo()
      return
    }
    if (isOpenCreateTrackingFmm) {
      onCloseCreateTrackingFmm()
      return
    }
    if (isOpenSearchTracking) {
      onCloseSearchTracking()
      return
    }
    if (isOpenStopTracking) {
      onCloseStopTracking()
      return
    }
    if (isOpenCreateFollowOrder) {
      onCloseCreateFollowOrder()
      return
    }
    if (isOpenCreateComment) {
      onCloseCreateComment()
    }
  }

  const getFollowOrder = async (data, setFollowOrder, Formtype) => {
    console.log(data, 'data al ejecutar')
    try {
      const formData = new FormData()
      Formtype === 2
        ? formData.append(
          'form_code', data?.code_entryForm ? data?.code_entryForm : '')
        : formData.append(
          'form_code',
          data?.code_outputForm ? data?.code_outputForm : ''
        )
      formData.append('form_id', data?.id)
      formData.append('form_type', Formtype)
      formData.append('get_follow_order', true)
      const res = await __Get_FollowOrderStopTracking(token, formData)
      const code = res?.data?.status?.code
      const requestFollow = res?.data?.status?.data
      if (code === 200) {
        if (requestFollow?.length) {
          requestFollow?.forEach(follow =>
            optionsFollow.push({ value: follow, label: follow })
          )
          setFollowOrder(optionsFollow)
          onOpenStopTracking()
        } else {
          openAlert(
            'No hay seguimientos activos asociados a este formulario',
            'error'
          )
        }
      } else if (code !== 200) {
        openAlert('Ha ocurrido un error', 'error')
      }
    } catch {
      console.log('error')
    } finally {
    }
  }

  const data = {
    isOpenViewComment,
    onOpenViewComment,
    onCloseViewComment,

    isOpenModalInfo,
    onOpenModalInfo,
    onCloseModalInfo,
    isOpenCreateTrackingFmm,
    onOpenCreateTrackingFmm,
    onCloseCreateTrackingFmm,
    isOpenSearchTracking,
    onOpenSearchTracking,
    onCloseSearchTracking,
    isOpenStopTracking,
    onOpenStopTracking,
    onCloseStopTracking,
    isOpenCreateFollowOrder,
    onOpenCreateFollowOrder,
    onCloseCreateFollowOrder,
    isOpenCreateComment,
    onOpenCreateComment,
    onCloseCreateComment,
    setEditFollowOrder,
    editFollowOrder,
    setEditComment,
    editComment,
    setGetFollow,
    getFollowO,
    setDataCreateFollowOrder,
    dataCreateFollowOrder,
    setDeleteFollowOrder,
    deleteFollowOrder,
    setDataTableComment,
    dataTableComment,
    getFollowOrder,
    onCloseModal
  }

  return (
    <MonitoringContext.Provider value={data}>
      {children}
    </MonitoringContext.Provider>
  )
}

export default MonitoringContext
