import React from "react";
import { Routes, Route } from "react-router-dom";

import News from "pages/Security/News"
import Blockages from "pages/Security/Blockages";
import Monitoring from "pages/Security/Monitoring";
import Inspections from "pages/Security/Inspections";
import ExitVehicle from "pages/Security/ExitVehicle";
import FreeDisposal from "pages/Security/FreeDisposal";
import ListBlockages from "pages/Security/ListBlockages";
import EntriesVehicle from "pages/Security/EntriesVehicle";
import Inconsistencies from "pages/Security/Inconsistencies";
import ReportSecurity from "pages/Security/ReportSecurity";
import History from "pages/Security/History";

export const routeSecurity = [
  {
    path: "/news",
    breadcrumbName: "Novedades",
    element: <News />
  },
  {
    path: "/monitoring",
    breadcrumbName: "Monitoreo",
    element: <Monitoring />
  },
  {
    path: "/blockages",
    breadcrumbName: "Bloqueos",
    element: <Blockages />
  },
  {
    path: "/listBlockages",
    breadcrumbName: "Lista de bloqueos",
    element: <ListBlockages />
  },
  {
    path: "/inconsistencies",
    breadcrumbName: "Inconsistencias",
    element: <Inconsistencies />
  },
  {
    path: "/freeDisposal",
    breadcrumbName: "Libre disposición",
    element: <FreeDisposal />
  },

  {
    path: "/inspections",
    breadcrumbName: "Inspecciones",
    element: <Inspections />
  },
  {
    path: "/entriesVehicles",
    breadcrumbName: "Registro de entrada",
    element: <EntriesVehicle />
  },
  {
    path: "/exitVehicles",
    breadcrumbName: "Registro de salida",
    element: <ExitVehicle />
  },
  {
    path: "/reportSecurity",
    breadcrumbName: "Reporte de seguridad",
    element: <ReportSecurity />
  },
  {
    path: "/history",
    breadcrumbName: "Historial",
    element: <History />
  }
];

export const SecurityRoutes = () => {
  return (
    <Routes>
      {routeSecurity.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
};
