import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useState } from "react";

/**
 * RadioCheck - Radio Boton de seleccion unica complementando el formulario.
 * @param {Array} item Array de radioChech a renderizar.
 * @param {Function} set Estado donde capturamos la seleccion del usuario.
 * @param {string} value estado inicial o estado seleccionado por el usuario.
 * @param {boolean} diff Conocer si hay diferencia entre el y otro campo.
 * @param {boolean} disables Si estara disabled o no dependiendo.
 * @return {Component} Retorna seleccion de una opcion por el usuario.
 */

export const RadioCheck = ({ item, set, value, diff, disables = false, name, onChange = null }) => {
  return (
    <RadioGroup onChange={set} value={value}>
      <Stack direction="row">
        {item?.map((items, index) => (
          <div
            className={`p-1
            ${diff && diff && value === items && "BorderCorrect"}
            `}
            key={index}
          >
            <Radio
              name={name}
              onChange={onChange}
              isDisabled={disables}
              key={items}
              value={items}
              className={`me-1 cursorBtn `}
            >
              {items}
            </Radio>
          </div>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export const RadioCheckDuta = ({
  item,
  set,
  value,
  diff,
  disables = false
}) => {
  const [CurrentStatus, setCurrentStatus] = useState(0);

  const handleSwitchStatus = (data, value) => {
    if (data) {
      set(value);
    } else {
      set(null);
    }

    setCurrentStatus(data ? 1 : 0);
  };

  return (
    <RadioGroup>
      <Stack direction="row">
        {item.map((items, index) => (
          <div
            className={`p-1
            ${diff && diff && value === items && "BorderCorrect"}
            `}
            key={index}
          >
            <Radio
              isDisabled={disables}
              key={index}
              colorScheme="green"
              onChange={() => handleSwitchStatus(!CurrentStatus, items)}
              isChecked={CurrentStatus}
              className={`me-1 cursorBtn `}
            >
              {items}
            </Radio>
          </div>
        ))}
      </Stack>
    </RadioGroup>
  );
};
