import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { MdDelete } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { HrDividerNoMargin } from "styled/Line.styled";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import environment from "constants/apiConst";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const OptionsWaste = ({ data }) => {
  const { DeleteWaste } = useContext(IntegracionBoxCrudContex);
  /*   const location = useLocation();
  const { pathname } = location; */
  const queryClient = useQueryClient();

  const invalidateQueryFn = () => {
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.GARBAGE_CI] });
    queryClient?.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] });
  }

  const handleDelete = (data) => {
    const formData = new FormData();
    formData.append("id", data?.id);
    DeleteWaste(environment.DELETE_GARBAGE_CI, formData, invalidateQueryFn);
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <HrDividerNoMargin />
        <MenuItemStyled py={3} onClick={() => handleDelete(data)}>
          <IconMenuRUD as={MdDelete} />
          Eliminar
        </MenuItemStyled>
      </MenuList>
    </Menu>
  );
};

export default OptionsWaste;
