import { Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  content: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  signatureTitle: {
    fontSize: 8,
    marginBottom: '0.5cm',
    textAlign: 'center'
  },
  signatureLine: {
    borderBottom: '1px solid #000000',
    width: '120px'
  }
})

const FooterDocument = () => {
  return (
    <View>
      <Text
        style={{
          ...styles.content,
          fontSize: 7,
          paddingTop: '20px'
        }}
      >
        Para los fines previstos en el artículo 83 de la Constitución Política
        de Colombia, declaro bajo la gravedad de juramento que los conceptos,
        cantidades y demás datos aquí consignados en el presente formulario son
        correctos y es fiel expresión de la verdad. Autorizo al Ministerio de
        Comercio, Industria y Turismo a utilizar la información contenida en el
        presente formulario para la elaboración de estadísticas o para la
        consulta de las mismas.
      </Text>
      <View style={styles.signatureContainer}>
        <View>
          <Text
            style={{
              ...styles.signatureTitle,
              paddingTop: '20px',
              paddingBottom: '25px'
            }}
          >
            Firma Usuario de Zona Franca
          </Text>

          <Text style={styles.signatureLine}>_____________</Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.signatureTitle,
              paddingTop: '20px',
              paddingBottom: '25px'
            }}
          >
            Firma Usuario Operador{' '}
          </Text>

          <Text style={styles.signatureLine}>___________</Text>
        </View>
      </View>
    </View>
  )
}

export default FooterDocument
