import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuShipping from "components/Menu/MenuShipping/MenuShipping";

const TbodyShipping = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">0</Td>
      <Td className="text-center">{data?.numidentificacionMedioTransporte}</Td>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className="text-center">{data?.numFormulario}</Td>
      <Td className="text-center">{data?.fecPresentacion}</Td>
      <Td className="text-center">{data?.medioTransporte}</Td>
      <Td isNumeric>
        <MenuShipping data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyShipping;
