import { Box, SimpleGrid } from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonStyled } from "styled/Buttons.styled";
import queryString from "query-string";
import environment from "constants/apiConst";
import { useDebounceError } from "hooks/useDebounceError";
import ProductSearchBar from "./ProductSearchBar";
import { NumericFormat } from "react-number-format";
import { useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";
import { Regex } from "utils/Regex";

const FormAddProduct = ({ onCloseModal }) => {
  const [valueProduct, setValueProduct] = useState(null);
  const { decimal_values_precision, token } = useSelector((state) => state.auth.dataUser);
  const [values, handleInputChange, reset, setValues] = useForm({
    quantity_product: ""
  });
  const { quantity_product } = values;
  const location = useLocation();
  const { pathname } = location;
  const { id = null, idCorrected = null } = queryString.parse(location.search);

  const queryClient = useQueryClient();

  const invalidateQueryProduct = () => queryClient?.invalidateQueries({ queryKey: [queryEnv?.SUPPLIES_CI] });

  const {
    userQualified,
    setRequestCreate,
    createData,
    userQualifiedCorrect,
    setRequestCreateCorrection,
    createDataCorrection
  } = useContext(IntegracionBoxCrudContex);

  const handleSendData = (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    dataToSend.append("integration_certificate_id", id);
    dataToSend.append("quantity_product", quantity_product);
    dataToSend.append("product_id", valueProduct?.value || "");
    createData(dataToSend, () => {
      invalidateQueryProduct()
      onCloseModal();
    });

  };

  const handleSendDataCorrected = (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    dataToSend.append("integrationCertificateCorrected_id", id || idCorrected);
    dataToSend.append("quantity_product_corrected", quantity_product);
    dataToSend.append("productCorrected_id", valueProduct?.value || "");
    createDataCorrection(dataToSend, invalidateQueryProduct);
    onCloseModal();
  };

  useEffect(() => {
    setRequestCreateCorrection(environment.CREATE_SUPPLY_CORRECTED);
  }, [setRequestCreateCorrection]);

  useEffect(() => {
    setRequestCreate(environment.ADD_PRODUCT_TO_SUPPLIES);
  }, [setRequestCreate]);

  const [handleDebounceRegexError] = useDebounceError();

  const handleBlurCant = (e) => {
    const nameVariable = e.target.name;
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      setValues({
        ...values,
        [nameVariable]: posibleResult
      });
    }
  };

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <form>
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
          <div>
            <ProductSearchBar
              label="Producto"
              required={true}
              set={setValueProduct}
              value={valueProduct}
              size={100}
              manualId={userQualified?.value ?? userQualifiedCorrect?.value}
              className={"z-index-4"}
            />
          </div>

          <div>
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              decimalScale={decimal_values_precision || 4}
              decimalSeparator="."
              customInput={LabelGroup}
              name={"quantity_product"}
              value={quantity_product}
              onChange={handleInputChange}
              tag={"Cantidad de producto"}
              dataType={"number"}
              size={100}
              desicion={false}
              onBlur={handleBlurCant}
              maxLength={34}
            />
          </div>
        </SimpleGrid>

        <div className="mb-3 ms-5">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
              Cerrar
            </ButtonStyled>
            {pathname === "/IntegracionBox"
              ? (
                <ButtonStyled type="submit" onClick={handleSendData}>
                  Agregar
                </ButtonStyled>
              )
              : pathname === "/CorrectedCertificate"
                ? (
                  <ButtonStyled type="submit" onClick={handleSendDataCorrected}>
                    Agregar
                  </ButtonStyled>
                )
                : (
                  ""
                )}
          </Box>
        </div>
      </form>
    </>
  );
};

export default FormAddProduct;
