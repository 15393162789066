import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from "@chakra-ui/react";

export const CustomizablePopover = ({
  BodyButton,
  Popovertitle,
  children,
  closeOnBlur,
  isOpen,
  onOpen,
  onClose,
  ...props
}) => {
  return (
    <Popover
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      closeOnBlur={closeOnBlur}
    >
      <>
        <PopoverTrigger>
          <Button {...props}>{BodyButton}</Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="semibold">{Popovertitle}</PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>{" "}
      </>
    </Popover>
  );
};
