import axios from "axios";

import env from "../constants/apiConst";

export const __Logout = async (token) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(env.LOGOUT, options);
  return res;
};
