import React, { useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { ConfigIconStatus } from "pages/Configuration/tables/components/LoadingConfig";
import styled from "styled-components";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import { useMutation } from "@tanstack/react-query";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { NavLink } from "react-router-dom";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;


const TbodyDEI = ({ data }) => {

  const [loadingIcons, setLoadingIcons] = useState(false);
  const [statusDei, setStatusDei] = useState(data?.dei === true ? 1 : 0);
  const [openAlert] = AlertErrorAlert();

  /* const handleState = async () => {
    if (loadingIcons) return;

    setLoadingIcons(true);
    try {

    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  }; */

  const { requestData } = useGlobalContext()

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleState = () => {
    if (loadingIcons) return;

    setLoadingIcons(true);

    const body = {
      id: data?.id,
      dei: statusDei === 1 ? 0 : 1
    }

    const config = {
      data: body,
      Endpoint: environment.UPDATE_OUTPUT_FORM_DEI,
      PropertyBody: "sendJSONContentPOST"
    }

    mutate(config, {
      onSuccess: (res) => {
        if (res?.data?.status?.code !== 200) {
          setLoadingIcons(false);
          openAlert(res?.data?.status?.message, "error");
          
        } else {
          setLoadingIcons(false);
          setStatusDei(res?.data?.status?.data?.dei);
        }
      }
    })
  }


  return (
    <Tr>
      <Td className="text-center">{data?.name_qualified}</Td>
      <Td className=' text-center'>
      <NavLink to={`/FormShowExit?ExitNumber=${data.form_id}`}>
          <span className='formNumber'>{data?.code_outputForm}</span>
        </NavLink>
      </Td>

      <Td className="text-center">{data?.code_operation}</Td>

      <Td className="text-center">{data?.name_typeOperation}</Td>
      <Tooltip
        placement="right"
        hasArrow
        label={data?.dei === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data?.dei}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={statusDei}
          />
        </TdState>
      </Tooltip>
    </Tr>
  );
};

export default TbodyDEI;