import { Td, Tr } from "@chakra-ui/react";
import React from "react";

export const TbodySecurity = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data?.veto}</Td>
      <Td className="text-center">{data?.amount}</Td>
    </Tr>
  );
};
