/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Pagination from "components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TitleShippingForm } from "pages/Dian/ArrayTitleDian/ArrayTitle";
import { useLocation } from "react-router-dom";
import TableOrder from "components/Tables/TableOrder";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { CgPlayListAdd } from "react-icons/cg";
import { ModalGeneric } from "components/modal/ModalGeneric";
import FormShippingWithComparison from "pages/Dian/Spreadsheet/FormShippingWithComparison";
import FormShipping from "pages/Dian/Spreadsheet/FormShipping";
import { useModal } from "hooks/useModal";
import environment from "constants/apiConst";
import { useEffect } from "react";
import { __Search, __SearchReviews } from "request/Reviews/__GetTReviews";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import TbodyShipping from "pages/Treasury/Tables/TbodyShipping";
import { BiSearchAlt } from "react-icons/bi";
import { GetLocalStorage } from "hooks/useLocalStorage";

const ShippingFormView = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSpreadsheet, onOpenSpreadsheet, onCloseSpreadsheet] = useModal();
  const [openAlert] = AlertErrorAlert();
  const [recient, setRecient] = useState("");
  const { freeZoneValue } = useSelector(state => state.chagenFreeZone)

  useEffect(() => {
    GetReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeZoneValue]);

  const GetReviews = async () => {
    setLoading(true);

    try {
      const res = await __Search(environment.GET_SHIPPING_FORMS, token, page);
      const status = res?.data?.status;
      if (status.code !== 200) {
        setCurrentPage([]);
        openAlert(status?.message, "error");
      } else {
        // console.log(res?.data?.status?.data.last_page);
        setCurrentPage(res?.data?.status?.data?.data);
        setRecient(res?.data?.status?.data?.data.length);
        setMaxPage(res?.data?.status?.data.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/*      <ModalGeneric
        isOpen={isOpenSpreadsheet}
        onClose={onCloseSpreadsheet}
        title={`Planilla de envio: ${GetLocalStorage('DianNumber')}`} //title={"Nueva Planilla"}
        size={"6xl"}
      >
        <FormShippingWithComparison onCloseSpreadsheet={onCloseSpreadsheet} />
      </ModalGeneric> */}

      <HeaderSearch>
        <Box
          display="flex"
          justifyContent="space-between"
          marginTop={"20px"}
          marginBottom={"10px"}
        >
          <div>
            <Text className="ms-2" fontSize="xl" style={{ fontWeight: 600 }}>
              Todas las planillas
            </Text>
          </div>
          <Box display={"flex"}>
            <InputGroup>
              <InputRightElement
                pointerEvents="none"
                children={<BiSearchAlt color="gray.300" />}
              />
              <Input variant="flushed" placeholder="Buscar" />
            </InputGroup>
            {/*  <Tooltip placement="top" hasArrow label="Agregar Planilla">
              <span>
                <ButtonHeaderModule
                  className="mx-1 d-flex float-end"
                  bgColor="#03C39A"
                  onClick={onOpenSpreadsheet}
                >
                  <IconGeneric color="#fff" as={CgPlayListAdd} />
                </ButtonHeaderModule>
              </span>
            </Tooltip> */}
          </Box>
        </Box>

        <TableOrder
          thead={TitleShippingForm}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyShipping}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default ShippingFormView;
