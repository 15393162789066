import axios from "axios";
import env from "../../constants/apiConst";

export const __ProductProcess = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.PRODUCT_PROCESS}?page=${page || 1}`, options);
  return res;
};

export const __InsumosProcess = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.INSUMO_PROCESS}?page=${page || 1}`, options);
  return res;
};

export const __OtherProcess = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.OTHER_PROCESS}?page=${page || 1}`, options);
  return res;
};

export const __ProductsUserAll = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.PRODUCTSUSERAALL}/${id}`, options);
  return res;
};

export const __ProductsReferenceUserAll = async (token, id, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.PRODUCTSREFERENCEUSERAALL}/${id}`, options);
  return res;
};

export const __GetReferenceOutputForm = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.GET_REFERENCE_OUPUT_FORM}`, options);
  return res;
};
