export const cabeceraFormularios = [
  'Editables',
  'Presentados',
  'Aprobados',
  'Autorizados'
]

export const cabeceraFormulariosCorregidos = [
  'Editables',
  'Presentados',
  'Autorizados'
]

export const HeaderDispatchCorrections = [
  'Editables',
  'Presentados',
  'Autorizados'
]

export const TitleDispatchCorrections = [
  { name: 'Nit', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Despacho', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Transporte', order: 'null' },
  { name: 'Placa de vehículo', order: 'null' },
  { name: 'Número de bultos', order: 'null' },
  { name: 'Cant. items', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const cabeceraTableMonitoring = [
  'Formulario de salida',
  'Formulario de ingreso',
  'Documento de transporte',
  'Usuario calificado',
  'Operaciones',
  'Subpartidas'
]

export const cabeceraOrdens = [
  'Editables',
  'Presentados',
  'Autorizados'
]

export const TheadTableLogOp = [
  { name: 'Código', order: 'null' },
  { name: 'Código proceso productivo', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Código Producto', order: 'null' },
  { name: 'Descripción producto', order: 'null' },
  { name: 'Peso neto', order: 'null' },
  { name: 'Total producto', order: 'null' },
  { name: 'Total calculado', order: 'null' }
]

export const TheadTableLogInventory = [
  { name: 'Código operación', order: 'null' },
  { name: 'Naturaleza producto', order: 'null' },
  { name: 'Acuerdo', order: 'null' },
  { name: 'Declaración de importación', order: 'null' },
  { name: 'Desaduanamiento', order: 'null' },
  { name: 'Factura', order: 'null' },
  { name: 'Cant disponible Dian', order: 'null' },
  { name: 'Cant salida Dian', order: 'null' },
  { name: 'Cant temporal Dian', order: 'null' },
  { name: 'Cant rsvd Dian', order: 'null' },
  { name: 'Cant rsvd entrada Dian', order: 'null' },
  { name: 'Cant elaborada rsvd Dian', order: 'null' },
  { name: 'Cant zf Dian', order: 'null' },
  { name: 'Cant total Dian', order: 'null' },
  { name: 'Cant disponible producto', order: 'null' },
  { name: 'Cant salida producto', order: 'null' },
  { name: 'Cant temporal producto', order: 'null' },
  { name: 'Cant rsvd producto', order: 'null' },
  { name: 'Cant rsvd entrada producto', order: 'null' },
  { name: 'Cant elaborada rsvd producto', order: 'null' },
  { name: 'Cant zf producto', order: 'null' },
  { name: 'Cant total producto', order: 'null' },
  { name: 'Valor fob producto unidad', order: 'null' },
  { name: 'Valor cif producto unidad', order: 'null' },
  { name: 'peso neto por unidad de producto', order: 'null' }
]

export const TheadTableLogCI = [
  { name: 'Código CI', order: 'null' },
  { name: 'Peso neto', order: 'null' },
  { name: 'Cant', order: 'null' },
  { name: 'Cant Producto', order: 'null' },
  { name: 'Valor fob', order: 'null' },
  { name: 'Observaciones', order: 'null' }
]

export const TheadTableLoginventoryBalance = [
  { name: 'Tipo de operación', order: 'null' },
  { name: 'Código formulario', order: 'null' },
  { name: 'Cant entrada', order: 'null' },
  { name: 'Cant salida', order: 'null' },
  { name: 'Cant entrada producto', order: 'null' },
  { name: 'Cant salida producto', order: 'null' },
  { name: 'Cant saldo', order: 'null' },
  { name: 'Cant saldo producto', order: 'null' }
]

export const TheadTableLog = [
  { name: 'Referencia', order: 'null' },
  { name: 'Orden de producción', order: 'null' },
  { name: 'Cuadro integración', order: 'null' },
  { name: 'Inventario', order: 'null' },
  { name: 'Saldo inventario', order: 'null' },
  { name: 'Inventario de referencia', order: 'null' }
]

export const TheadTabletraceability = [
  { name: 'Tipo de operación', order: 'null' },
  { name: 'Código de formulario', order: 'null' },
  { name: 'Estado', order: 'null' }
]

export const TheadTraceabilityCommentary = [
  { name: 'Comentario', order: 'null' },
  { name: 'Usuario calificado', order: 'null' }
]

export const TheadTracking = [
  { name: 'Código', order: 'null' },
  { name: 'Código de formulario', order: 'null' },
  { name: 'Tipo de Seguimiento', order: 'null' },
  { name: 'Usuario calificado', order: 'null' },
  { name: 'Usuario', order: 'null' },
  { name: 'Fecha inicio', order: 'null' },
  { name: 'Fecha fin', order: 'null' },
  { name: 'Usuario fin', order: 'null' },
  { name: 'Operación', order: 'null' },
  { name: 'Oficio', order: 'null' },
  { name: 'Gestor de seguimiento', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Comentario inicio', order: 'null' },
  { name: 'Comentario final', order: 'null' },
  { name: 'Observaciones', order: 'null' },
  { name: 'Nº doc de trans', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

const theadOutIn = [
  { name: 'Código', order: 'null' },
  { name: 'Código de formulario', order: 'null' },
  { name: 'Código del seguimiento padre', order: 'null' },
  { name: 'Usuario calificado', order: 'null' },
  { name: 'Usuario', order: 'null' },
  { name: 'Fecha inicio', order: 'null' },
  { name: 'Fecha fin', order: 'null' },
  { name: 'Usuario fin', order: 'null' },
  { name: 'Operación', order: 'null' },
  { name: 'Oficio', order: 'null' },
  { name: 'Gestor de seguimiento', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Comentarios', order: 'null' }, // Acá un modal con comentario Inicio y Final
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

const theadOthers = [
  { name: 'Código', order: 'null' },
  { name: 'Usuario', order: 'null' },
  { name: 'Fecha inicio', order: 'null' },
  { name: 'Fecha fin', order: 'null' },
  { name: 'Usuario fin', order: 'null' },
  { name: 'Oficio', order: 'null' },
  { name: 'Gestor de seguimiento', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Comentarios', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const AccordionTheadTracking = {
  'Formulario de salida': theadOutIn,
  'Formulario de ingreso': theadOutIn,
  'Documento de transporte': [
    { name: 'Código', order: 'null' },
    { name: 'Nº doc de trans', order: 'null' },
    { name: 'Código de formulario', order: 'null' },
    { name: 'Usuario calificado', order: 'null' },
    { name: 'Usuario', order: 'null' },
    { name: 'Fecha inicio', order: 'null' },
    { name: 'Fecha fin', order: 'null' },
    { name: 'Usuario fin', order: 'null' },
    { name: 'Oficio', order: 'null' },
    { name: 'Gestor de seguimiento', order: 'null' },
    { name: 'Estado', order: 'null' },
    { name: 'Comentarios', order: 'null' }, // Acá un modal con comentario Inicio y Final
    { name: 'Estado', order: 'null' },
    { name: 'Acciones', order: 'null' }
  ],
  'Usuario calificado': theadOthers,
  Operaciones: theadOthers,
  Subpartidas: theadOthers
}

export const TheadFollowOrder = [
  { name: 'Gestor', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Comentarios', order: 'null' }
]

export const TheadComments = [
  { name: 'Tipo', order: 'null' },
  { name: 'Comentario', order: 'null' }
]

export const TheadTrackingOperations = [
  { name: 'Codigo operación', order: 'null' },
  { name: 'Operación', order: 'null' }
]

export const TheadTrackingItems = [
  { name: 'Item', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Código Producto', order: 'null' },
  { name: 'Código subartida', order: 'null' },
  { name: 'Código operación', order: 'null' },
  { name: 'Manifiesto', order: 'null' },
  { name: 'Cantidad', order: 'null' },
  { name: 'Bandera', order: 'null' },
  { name: 'seguimiento', order: 'null' }
]

export const TheadShowItems = [
  { name: 'Código', order: 'null' },
  { name: 'Código producto', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Descripción producto', order: 'null' }
]

export const TheadShowUC = [
  { name: 'Nit', order: 'null' },
  { name: 'Usuario calificado', order: 'null' }
]

export const TheadShowTrackingItems = [
  { name: 'Gestor de seguimiento', order: 'null' },
  { name: 'Tipo', order: 'null' },
  { name: 'Usuario inicio', order: 'null' },
  { name: 'Fecha inicio', order: 'null' },
  { name: 'Fecha fin', order: 'null' },
  { name: 'Usuario fin', order: 'null' },
  { name: 'Oficio', order: 'null' },
  { name: 'Comentario inicial', order: 'null' },
  { name: 'Comentario final', order: 'null' },
  { name: 'Estado', order: 'null' }
]

export const TheadSubheading = [
  { name: 'Código', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Subcapitulo', order: 'null' }
]

export const TheadTrackingModuls = [
  { name: 'Gestor de seguimiento', order: 'null' },
  { name: 'Tipo', order: 'null' },
  { name: 'Oficio', order: 'null' },
  { name: 'Fecha inicio', order: 'null' },
  { name: 'Usuario inicio', order: 'null' },
  { name: 'Usuario fin', order: 'null' },
  { name: 'Fecha fin', order: 'null' },
  { name: 'Estado', order: 'null' }
]

export const TitleFormsInt = [
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Proveedor', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Doc. de Transporte', order: 'null' },
  { name: 'Usuario', order: 'null' },
  { name: 'Cant. items', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const TitleFormsEntryExpired = [
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Doc. de Transporte', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const TitleFormsEntryAccumulated = [
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Peso Bruto', order: 'null' },
  { name: 'Peso Neto', order: 'null' },
  { name: 'Peso Acumulado', order: 'null' }
]

export const TitleFormsExitExpired = [
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const TitleFormsExitTemporary = [
  { name: 'N° Form', order: 'null' },
  { name: 'Item', order: 'null' },
  { name: 'Descripcion', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Subpartida', order: 'null' },
  { name: 'Origen', order: 'null' },
  { name: 'Compra', order: 'null' },
  { name: 'Destino', order: 'null' },
  { name: 'Bandera', order: 'null' }
]

export const TitleFormsIntItems = [
  { name: 'Formulario', order: 'null' },
  { name: 'Item', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Producto', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Subpartida', order: 'null' },
  { name: 'Dcl. Importación', order: 'null' },
  { name: 'Origen', order: 'null' },
  { name: 'Compra', order: 'null' },
  { name: 'Procedencia', order: 'null' },
  { name: 'Bandera', order: 'null' }
]
export const TitleFormsOutputtItems = [
  { name: 'Formulario', order: 'null' },
  { name: 'Item', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Producto', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Subpartida', order: 'null' },
  { name: 'Dcl. Importación', order: 'null' },
  { name: 'Origen', order: 'null' },
  { name: 'Compra', order: 'null' },
  { name: 'Destino', order: 'null' },
  { name: 'Bandera', order: 'null' },
  { name: 'Cantidad', order: 'null' }
]

export const TitleFormsCorrect = [
  { name: 'Nº Corrección', order: 'null' },
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Tipo de operación', order: 'null' },
  { name: 'Nit proveedor', order: 'null' },
  { name: 'Proveedor', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Doc. de Transporte', order: 'null' },
  { name: 'Usuario', order: 'null' },

  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]
export const TitleFormsCorrectSalida = [
  { name: 'Nº Corrección', order: 'null' },
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Tipo de operación', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Usuario', order: 'null' },

  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const TitleFormsExit = [
  { name: 'Nº Formulario', order: 'null' },
  { name: 'Empresa', order: 'null' },
  { name: 'Código', order: 'null' },
  { name: 'Importador', order: 'null' },
  { name: 'Factura Comercial', order: 'null' },
  { name: 'Cant. items', order: 'null' },
  { name: 'Estado', order: 'null' },
  { name: 'Acciones', order: 'null' }
]

export const TitleFormsItems = [
  { name: 'Formulario', order: 'null' },
  { name: 'Item', order: 'null' },
  { name: 'Descripción', order: 'null' },
  { name: 'Producto', order: 'null' },
  { name: 'Referencia', order: 'null' },
  { name: 'Subpartida', order: 'null' },
  { name: 'Dcl. Importación', order: 'null' },
  { name: 'Origen', order: 'null' },
  { name: 'Compra', order: 'null' },
  { name: 'Destino', order: 'null' },
  { name: 'Bandera', order: 'null' },
  { name: 'Cantidad', order: 'null' },
  { name: 'Saldo', order: 'null' }
]

export const cabeceraSelect = [
  { value: 'BORRADOR', label: 'BORRADOR' },
  { value: 'PRESENTADO', label: 'PRESENTADO' },
  { value: 'APROBADO', label: 'APROBADO' },
  { value: 'AUTORIZADO', label: 'AUTORIZADO' },
  { value: 'CORREGIDO', label: 'CORREGIDO' }
]

// inicio array inventario

export const arrayLeftfmmI = [
  {
    "value": "code_entry_form_corrected",
    "label": "Código del formulario",
    "name": "code_entry_form_corrected"
  },
  {
    "value": "num_commercial_invoice",
    "label": "Factura Comercial"
  },
  {
    "value": "code_operation",
    "label": "Código de la operación",
    "name": "code_operation"
  },
  {
    "value": "num_dex_entryFormCorrected",
    "label": "Número DEX",
    "name": "num_dex_entryFormCorrected"
  },
  {
    "value": "dex_date_entryForm",
    "label": "Fecha DEX",
    "name": "dex_date_entryForm"
  },
  {
    "value": "dcl_customs_transit_corrected",
    "label": "Número DTA",
    "name": "dcl_customs_transit_corrected"
  },
  {
    "value": "num_transport_document_corrected",
    "label": "Número documento de transporte corregido",
    "name": "num_transport_document_corrected"
  },
  {
    "value": "transp_doc_date_corrected",
    "label": "Fecha documento de transporte corregido",
    "name": "transp_doc_date_corrected"
  },
  {
    "value": "cargo_manifest_entryFormCorrected",
    "label": "Manifiesto de carga",
    "name": "cargo_manifest_entryFormCorrected"
  },
  {
    "value": "code_department",
    "label": "Código del departamento",
    "name": "code_department"
  },
  {
    "value": "code_product",
    "label": "Código producto",
    "name": "code_product"
  },
  {
    "value": "gross_weight",
    "label": "Peso bruto",
    "name": "gross_weight"
  },
  {
    "value": "net_weight",
    "label": "Peso neto",
    "name": "net_weight"
  },
  {
    "value": "us_fob_value",
    "label": "Valor FOB",
    "name": "us_fob_value"
  },
  {
    "value": "us_freight",
    "label": "Valor del flete",
    "name": "us_freight"
  },
  {
    "value": "us_insurance",
    "label": "Valor del seguro",
    "name": "us_insurance"
  },
  {
    "value": "other_us_expenses",
    "label": "Otros costos",
    "name": "other_us_expenses"
  },
  {
    "value": "cif_us_value",
    "label": "Valor CIF",
    "name": "cif_us_value"
  },
  {
    "value": "business_name",
    "label": "Nombre de la empresa",
    "name": "business_name"
  },
  {
    "value": "nit_qualified_user_2",
    "label": "NIT del usuario calificado",
    "name": "nit_qualified_user_2"
  },
  {
    "value": "name_qualified_user_2",
    "label": "Nombre del usuario calificado",
    "name": "name_qualified_user_2"
  },
  {
    "value": "num_commercial_invoice_corrected",
    "label": "Factura Comercial Corregida",
    "name": "num_commercial_invoice_corrected"
  },
  {
    "value": "transit_expiration_date_corrected",
    "label": "Fecha de vencimiento de tránsito corregido",
    "name": "transit_expiration_date_corrected"
  },
  {
    "value": "reviewed_user",
    "label": "Usuario que revisa",
    "name": "reviewed_user"
  },
  {
    "value": "reviewed_date",
    "label": "Fecha de revisión",
    "name": "reviewed_date"
  },
  {
    "value": "reviewed_time",
    "label": "Hora de revisión",
    "name": "reviewed_time"
  },
  {
    "value": "finished_user",
    "label": "Usuario que termina",
    "name": "finished_user"
  },
  {
    "value": "finished_date",
    "label": "Fecha de finalización",
    "name": "finished_date"
  },
  {
    "value": "finished_time",
    "label": "Hora de finalización",
    "name": "finished_time"
  }
];

export const arrayLeftfmmO = [
  {
    value: 'identification_num',
    label: 'Nit Tercero',
    name: 'identification_num'
  },
  {
    value: 'third_party_name',
    label: 'Tercero',
    name: 'business_name'
  },
  {
    value: 'nit_qualified_user_2',
    label: 'Nit Traslado',
    name: 'nit_qualified_user_2'
  },
  {
    value: 'name_qualified_user_2',
    label: 'Nombre Usuario Traslado',
    name: 'name_qualified_user_2'
  },
  {
    value: 'code_outputFormCorrected',
    label: 'Codigo Formulario',
    name: 'code_outputFormCorrected'
  },
  {
    value: 'code_operation',
    label: 'Codigo de Operacion',
    name: 'code_operation'
  },
  {
    value: 'description_type_operation',
    label: 'Operacion',
    name: 'description_type_operation'
  },
  {
    value: 'commercial_invoice_corrected',
    label: 'Factura Comercial',
    name: 'commercial_invoice_corrected'
  },
  {
    value: 'certificate_inspection_pre_boarding_corrected',
    label: 'Acta de Inspección Preembarque',
    name: 'certificate_inspection_pre_boarding_corrected'
  },
  {
    value: 'destination_address_corrected',
    label: 'Direccion Destino',
    name: 'destination_address_corrected'
  },
  {
    value: 'pass_required_corrected',
    label: 'Requiere Pase',
    name: 'pass_required_corrected'
  },
  {
    value: 'status_outputFormCorrected',
    label: 'Estado del Formulario',
    name: 'status_outputFormCorrected'
  },
  {
    value: 'shipment_corrected',
    label: 'Embarque',
    name: 'shipment_corrected'
  }
]
export const arrayRigthfmmI = [
  {
    value: 'cif_us_value',
    label: 'Valor CIF',
    name: 'cif_us_value'
  },
  {
    value: 'name_qualified',
    label: 'Usuario calificado',
    name: 'nameQualified'
  },
  {
    value: 'status_entry_form',
    label: 'Estado',
    name: 'status'
  },
  {
    value: 'item',
    label: 'Item',
    name: 'item'
  },
  {
    value: 'associated_temporary_output',
    label: 'Certificado',
    name: 'associated_temporary_output'
  },
  {
    value: 'num_exit_form',
    label: 'Número formulario de salida',
    name: 'num_exit_form'
  },
  {
    value: 'num_import_declaration',
    label: 'Número de la autorización de integración',
    name: 'num_import_declaration'
  },
  {
    value: 'integration_box_certificate',
    label: 'Cuadro de integración',
    name: 'integration_box_certificate'
  },
  {
    value: 'customs_administration',
    label: 'Administración de aduanas',
    name: 'customs_administration'
  },
  {
    value: 'business_name',
    label: 'Nombre del tercero',
    name: 'business_name'
  },
  {
    value: 'identification_num',
    label: 'Nit del tercero',
    name: 'identification_num'
  },

  {
    value: 'transit_expiration_date',
    label: 'Fecha de vencimiento del documento de transporte',
    name: 'transit_expiration_date'
  },
  {
    value: 'number_packages',
    label: 'Número de bultos',
    name: 'number_packages'
  },
  {
    value: 'shipment_entry_form',
    label: 'Embarque',
    name: 'shipment_entry_form'
  },
  {
    value: 'description_type_operation',
    label: 'Descripción del tipo de operación',
    name: 'description_type_operation'
  },
  {
    value: 'authorizing_user',
    label: 'Usuario que autorizo',
    name: 'authorizing_user'
  }
]
export const arrayRigthfmmIThree = [
  {
    value: 'authorizing_date',
    label: 'Fecha que fue autorizado',
    name: 'authorizing_date'
  },
  {
    value: 'authorizing_time',
    label: 'Hora que fue autorizado',
    name: 'authorizing_time'
  },
  {
    value: 'presenting_user',
    label: 'Usuario que presento',
    name: 'presenting_user'
  },
  {
    value: 'presenting_date',
    label: 'Fecha que fue presenatdo',
    name: 'presenting_date'
  },
  {
    value: 'presenting_time',
    label: 'Hora que fue presentado',
    name: 'presenting_time'
  },

  {
    value: 'deleting_user',
    label: 'Usuario que solicito el borrador',
    name: 'deleting_user'
  },
  {
    value: 'deleting_date',
    label: 'Fecha de solicitud el borrador',
    name: 'deleting_date'
  },
  {
    value: 'deleting_time',
    label: 'Hora de solicitud el borrador',
    name: 'deleting_time'
  },

  {
    value: 'finished_user',
    label: 'Usuario que solicito terminar',
    name: 'finished_user'
  },
  {
    value: 'finished_date',
    label: 'Fecha de terminación',
    name: 'finished_date'
  },
  {
    value: 'finished_time',
    label: 'Hora de terminación',
    name: 'finished_time'
  },

  {
    value: 'reviewed_user',
    label: 'Usuario que revisa',
    name: 'reviewed_user'
  },
  {
    value: 'reviewed_date',
    label: 'Fecha de revisión',
    name: 'reviewed_date'
  },
  {
    value: 'reviewed_time',
    label: 'Hora de revisión',
    name: 'reviewed_time'
  },
  {
    value: 'nit_qualified_user_2',
    label: 'Nit del traslado',
    name: 'nit_qualified_user_2'
  },
  {
    value: 'name_qualified_user_2',
    label: 'Nombre del traslado',
    name: 'name_qualified_user_2'
  }
]

export const arrayRigthUserfmmI = [
  {
    "value": "cif_us_value",
    "label": "Valor CIF",
    "name": "cif_us_value"
  },
  {
    "value": "status_entry_form",
    "label": "Estado",
    "name": "status"
  },
  {
    "value": "item",
    "label": "Item",
    "name": "item"
  },
  {
    "value": "num_exit_form",
    "label": "Número formulario de salida",
    "name": "code"
  },
  {
    "value": "num_import_declaration",
    "label": "Número de la autorización de integración",
    "name": "num_import_declaration"
  },
  {
    "value": "integration_box_certificate_corrected",
    "label": "Cuadro de integración",
    "name": "integration_box_certificate_corrected"
  },
  {
    "value": "customAdministration_id",
    "label": "Administración de aduanas",
    "name": "customAdministration_id"
  },
  {
    "value": "third_party_name",
    "label": "Nombre del tercero",
    "name": "third_party_name"
  },
  {
    "value": "identification_num",
    "label": "Nit del tercero",
    "name": "identification_num"
  },
  {
    "value": "transit_expiration_date",
    "label": "Fecha de vencimiento del documento de transporte",
    "name": "transit_expiration_date"
  },
  {
    "value": "number_packages",
    "label": "Número de bultos",
    "name": "number_packages"
  },
  {
    "value": "shipment_entry_form",
    "label": "Embarque",
    "name": "shipment"
  },
  {
    "value": "description_type_operation",
    "label": "Descripción del tipo de operación",
    "name": "description_type_operation"
  },
  {
    "value": "authorizing_user",
    "label": "Usuario que autorizo",
    "name": "authorizing_user"
  },
  {
    "value": "draft_user",
    "label": "Usuario que solicita borrador",
    "name": "draft_user"
  },
  {
    "value": "draft_date",
    "label": "Fecha de borrador",
    "name": "draft_date"
  },
  {
    "value": "draft_time",
    "label": "Hora de borrador",
    "name": "draft_time"
  },
  {
    "value": "presenting_user",
    "label": "Usuario que presenta",
    "name": "presenting_user"
  },
  {
    "value": "presenting_date",
    "label": "Fecha de presentación",
    "name": "presenting_date"
  },
  {
    "value": "presenting_time",
    "label": "Hora de presentación",
    "name": "presenting_time"
  },
  {
    "value": "authorizing_date",
    "label": "Fecha de autorización",
    "name": "authorizing_date"
  },
  {
    "value": "authorizing_time",
    "label": "Hora de autorización",
    "name": "authorizing_time"
  }
];
 

export const arrayRigthUserfmmO = [
  {
    value: 'net_weight',
    label: 'Peso Neto',
    name: 'net_weight'
  },
  {
    value: 'us_fob_value',
    label: 'Valor FOB',
    name: 'us_fob_value'
  },
  {
    value: 'us_freight',
    label: 'Valor Fletes',
    name: 'us_freight'
  },
  {
    value: 'us_insurance',
    label: 'Valor Seguros',
    name: 'us_insurance'
  },
  {
    value: 'other_us_expenses',
    label: 'Valor Otros Costos',
    name: 'other_us_expenses'
  },
  {
    value: 'cif_us_value',
    label: 'Valor CIF',
    name: 'cif_us_value'
  },
  {
    value: 'number_packages',
    label: 'Numero de Bultos',
    name: 'number_packages'
  },
  {
    value: 'decimal_values_precision',
    label: 'Precision',
    name: 'decimal_values_precision'
  },
  {
    value: 'refund',
    label: 'Modalidad',
    name: 'refund'
  },
  {
    value: 'item',
    label: 'Item',
    name: 'item'
  },
  {
    value: 'deleting_user',
    label: 'Usuario que Solicita Borrador',
    name: 'deleting_user'
  },
  {
    value: 'deleting_date',
    label: 'Fecha de Borrador',
    name: 'deleting_date'
  },
  {
    value: 'deleting_time',
    label: 'Hora de Borrador',
    name: 'deleting_time'
  },
  {
    value: 'presenting_user',
    label: 'Usuario que Presenta',
    name: 'presenting_user'
  },
  {
    value: 'presenting_date',
    label: 'Fecha de Presentacion',
    name: 'presenting_date'
  },
  {
    value: 'presenting_time',
    label: 'Hora de Presentacion',
    name: 'presenting_time'
  },
  {
    value: 'reviewed_user',
    label: 'Usuario que Revisa',
    name: 'reviewed_user'
  },
  {
    value: 'reviewed_date',
    label: 'Fecha de Revision',
    name: 'reviewed_date'
  },
  {
    value: 'reviewed_time',
    label: 'Hora de Revision',
    name: 'reviewed_time'
  },
  {
    value: 'authorizing_user',
    label: 'Usuario que Autoriza',
    name: 'authorizing_user'
  },
  {
    value: 'authorizing_date',
    label: 'Fecha de Autorizacion',
    name: 'authorizing_date'
  },
  {
    value: 'authorizing_time',
    label: 'Hora de Autorizacion',
    name: 'authorizing_time'
  },
  {
    value: 'finished_user',
    label: 'Usuario que Termina',
    name: 'finished_user'
  },
  {
    value: 'finished_date',
    label: 'Fecha de Terminacion',
    name: 'finished_date'
  },
  {
    value: 'finished_time',
    label: 'Hora de Terminacion',
    name: 'finished_time'
  }
]

export const arrayLeftFmmO = [
  {
    value: 'third_party_name',
    label: 'Nombre del tercero',
    name: 'third_party_name'
  },
  {
    value: 'identification_num',
    label: 'Nit del tercero',
    name: 'identification_num'
  },

  {
    value: 'nit_qualified_user_2',
    label: 'Nit del traslado',
    name: 'nit_qualified_user_2'
  },
  {
    value: 'name_qualified_user_2',
    label: 'Nombre del traslado',
    name: 'name_qualified_user_2'
  },

  {
    value: 'code_output_form',
    label: 'Código del formulario',
    name: 'code_output_form'
  },
  {
    value: 'associated_entry_form_code_corrected',
    label: 'Ingreso asociado',
    name: 'associated_entry_form_code_corrected'
  },

  {
    value: 'code_operation ',
    label: 'Código de la operación',
    name: 'code_operation '
  },

  {
    value: 'description_type_operation',
    label: 'Descripción del tipo de operación',
    name: 'description_type_operation'
  },

  {
    value: 'third_party_identification_num',
    label: 'certificado de inspección',
    name: 'third_party_identification_num'
  },

  {
    value: 'pass_required',
    label: 'Pase requerido',
    name: 'pass_required'
  },

  {
    value: 'status_output_form',
    label: 'Estado',
    name: 'status'
  },

  {
    value: 'gross_weight',
    label: 'Peso bruto',
    name: 'gross_weight'
  }
]

export const arrayRigthFmmO = [
  {
    value: 'net_weight',
    label: 'Peso neto',
    name: 'net_weight'
  },
  {
    value: 'us_fob_value',
    label: 'Valor FOB',
    name: 'us_fob_value'
  },
  {
    value: 'us_freight',
    label: 'Valor del flete',
    name: 'us_freight'
  },
  {
    value: 'us_insurance',
    label: 'Valor del seguro',
    name: 'us_insurance'
  },
  {
    value: 'other_us_expenses',
    label: 'Otros costos',
    name: 'other_us_expenses'
  },
  {
    value: 'cif_us_value',
    label: 'Valor CIF',
    name: 'cif_us_value'
  },
  {
    value: 'number_packages',
    label: 'Número de bultos',
    name: 'number_packages'
  },
  {
    value: 'decimal_values_precision',
    label: 'Decimales',
    name: 'decimal_values_precision'
  },
  {
    value: 'shipment',
    label: 'Embarque',
    name: 'shipment'
  },
  {
    value: 'refund',
    label: 'Modalidad',
    name: 'refund'
  },
  {
    value: 'item',
    label: 'Item',
    name: 'item'
  },

  {
    value: 'name_qualified',
    label: 'Usuario calificado',
    name: 'nameQualified'
  },
  {
    value: 'deleting_user',
    label: 'Usuario que elimino',
    name: 'deleting_user'
  },
  {
    value: 'deleting_date',
    label: 'Fecha que fue eliminado',
    name: 'deleting_date'
  }
]
export const arrayRigthFmmOThree = [
  {
    value: 'deleting_time',
    label: 'Hora que fue eliminado',
    name: 'deleting_time'
  },
  {
    value: 'presenting_user',
    label: 'Usuario que presento',
    name: 'presenting_user'
  },
  {
    value: 'presenting_date',
    label: 'Fecha que fue presentado',
    name: 'presenting_date'
  },
  {
    value: 'presenting_time',
    label: 'Hora que fue presento',
    name: 'presenting_time'
  },
  {
    value: 'reviewed_user',
    label: 'Usuario que reviso',
    name: 'reviewed_user'
  },
  {
    value: 'reviewed_date',
    label: 'Fecha que fue revisado',
    name: 'reviewed_date'
  },
  {
    value: 'reviewed_time',
    label: 'Hora que fue revisado',
    name: 'reviewed_time'
  },
  {
    value: 'authorizing_user',
    label: 'Usuario que autorizo',
    name: 'authorizing_user'
  },
  {
    value: 'authorizing_date',
    label: 'Fecha que fue autorizado',
    name: 'authorizing_date'
  },
  {
    value: 'authorizing_time',
    label: 'Hora que fue autorizado',
    name: 'authorizing_time'
  },
  {
    value: 'finished_user',
    label: 'Usuario que finalizo',
    name: 'finished_user'
  },
  {
    value: 'finished_date',
    label: 'Fecha que fue finalizado',
    name: 'finished_date'
  },
  {
    value: 'finished_time',
    label: 'Hora que fue finalizado',
    name: 'finished_time'
  }
]

export const arrayRigthUserFmmO = [
  {
    value: 'net_weight',
    label: 'Peso neto',
    name: 'net_weight'
  },
  {
    value: 'us_fob_value',
    label: 'Valor FOB',
    name: 'us_fob_value'
  },
  {
    value: 'us_freight',
    label: 'Valor del flete',
    name: 'us_freight'
  },
  {
    value: 'us_insurance',
    label: 'Valor del seguro',
    name: 'us_insurance'
  },
  {
    value: 'other_us_expenses',
    label: 'Otros costos',
    name: 'other_us_expenses'
  },
  {
    value: 'cif_us_value',
    label: 'Valor CIF',
    name: 'cif_us_value'
  },
  {
    value: 'number_packages',
    label: 'Número de bultos',
    name: 'number_packages'
  },
  {
    value: 'decimal_values_precision',
    label: 'Decimales',
    name: 'decimal_values_precision'
  },
  {
    value: 'shipment',
    label: 'Embarque',
    name: 'shipment'
  },
  {
    value: 'refund',
    label: 'Modalidad',
    name: 'refund'
  },
  {
    value: 'item',
    label: 'Item',
    name: 'item'
  },

  {
    value: 'deleting_user',
    label: 'Usuario que elimino',
    name: 'deleting_user'
  },
  {
    value: 'deleting_date',
    label: 'Fecha que fue eliminado',
    name: 'deleting_date'
  }
]
