/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useSelector } from "react-redux";
import { __GetItems } from "request/Forms/_CrudForm";
import { __ShowForm } from "request/Forms/__ShowForm";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { ADDLocalStorage, ReviewUpdates } from "hooks/useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TerritoryNationalDisable } from "./viewFmmShow/TerritoryNationalDisable";
import { RestOfTheWorldDisabled } from "./viewFmmShow/RestOfTheWorldDisabled";
import { UserToUserDisabled } from "./viewFmmShow/UserToUserDisabled";
import ViewItems, {
  renderInputWithLabel,
} from "../View/ViewItems/ViewItemsEntryForm";
import TableItems from "../View/ViewItems/TableItems";

import { StatusOfUser } from "../BtnShowHeader/StatusOfUser";

import { ModalGeneric } from "components/modal/ModalGeneric";
import {
  Button,
  Text,
  Radio,
  Center,
  Box,
  Tooltip,
  InputRightElement,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import ModalCopy from "components/modal/ModalCopy";
import { BtnCheckFmm } from "../BtnShowHeader/BtnCheckFmm";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { Tab, Tabs } from "../../../components/Tabs/components/index";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import { AttchmentTypiFied } from "components/attchmentTypiFied/AttchmentTypiFied";
import Vapor from "laravel-vapor";
import Swal from "sweetalert2";
import { ContainerTitleOutput } from "./ContainerTitleOutput";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { SearchEditedField } from "functions/SearchEditedField";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { CommentFindTooltip } from "../View/ViewItems/Controller/ControllerInput";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { TheadShowTrackingItems } from "../ArrayHeaders/ArrayHeader";
import TBodyShowTrackingFmm from "pages/Seguimiento/Table/TBodyShowTrackingFmm";
import { useModal } from "hooks/useModal";
import { useGetInput, useNotations } from "hooks/useGetInput";
import { ColorPicker } from "functions/handleObservations";
// import { ModalObservation } from "../FunctionsCheck/Functions";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { CommentManager } from "components/DispatchCorrections/CommentManager/CommentManager";
import { Action } from "react-tiny-fab";
import { RiListSettingsLine, RiSearch2Line } from "react-icons/ri";
import RenderItems from "../View/ViewItems/RenderItems";
import { useDebounce } from "hooks/useDebouce";
import ModalObservation from "../FunctionsCheck/Functions";
import {
  cleanDeepObject,
  isObject,
  isObjectNotEmpty,
  isString,
} from "utils/type-check-utils";
import useStatusListener from "hooks/useStatusListener";
import { OtherAttchment } from "components/attchmentTypiFied/OtherAttchment";
import { useGlobalContext } from "context/GlobalContext";
import InputSelect from "components/InputSelect/InputSelect";
import CreateAt from "../View/CreateAt";

const commentTemplate = {
  renderProperties: {
    make: "make_comment",
    date: "date_comment",
    comment: "comment",
    person: "person_name",
  },
  functionProperties: {
    handleComment: "comment",
  },
};

export const CheckFmm = () => {
  const location = useLocation(); // navigate
  const { Number = "" } = queryString.parse(location.search);
  const {
    token,
    is_commerce,
    admin,
    user_rol: { modules, permission },
    is_qualified_user,
  } = useSelector((state) => state.auth.dataUser);
  const navigate = useNavigate();
  const { requestData } = useGlobalContext();

  const {
    update,

    setRequestChangeStatusForm,
    isOpenDesechar,
    onCloseDesechar,
    dataToDesechar,
    changeStatus,
    isOpenCopy,
    onCloseModal,
    copyData,
    setRequestCopy,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenComment,
    onOpenComment,
    onCloseComment,
    setOpcional,
    opcional,
    dataDUTA,
    DocumentOperation,
    setDocumentOperation,
    deleteAttachment,
    addresDestine,
  } = useContext(FormCrudCRUDContext);

  const initialForm = {
    commercial_invoice: "",
    date: "",
    certificate_inspection_pre_boarding: "",
    term: "",
    destination_address: "",
    operation_id: "",
    thirdPartie_id: "",
    shipment: "",
    qualifiedUser_id: "",
    pass_required: "",
    nitQualifiedUser_id: "",
    modality: "",
    purchase_order: "",
    user: "",
    id: "",
    block_for_subheading_approval: "",
  };

  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [items, setItems] = useState([]);
  const [reviewUpdates, setreviewUpdates] = useState([]);
  const [precision, setPrecision] = useState(0);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);

  const [statusActual, setStatusActual] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const Searchdebounced = useDebounce(searchItem, 500);

  const [files, setFiles] = useState({});
  const [nameFiles, setNameFiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const firstRender = useRef(true);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })


  ADDLocalStorage(Number, "form_id");
  useEffect(() => {
    setRequestCopy(environment.COPY_FORMS_EXIT);
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS_EXIT);
    if (Number !== "") {
      //  viewForms(Number);
    } else {
      setLoading(true);
    }
  }, [Number, update, precision]);


  const {
    data: dataViewForm,
    refetch: refetchViewForm,
    isFetching: isFetchingViewForm,
    isSuccess: isSuccessViewForm,
    isError: isErrorViewForm,
  } = useQuery({
    queryKey: ["viewForms", Number, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.SHOW_FORM_EXIT,
        resourceIdentifier: Number,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const dataForm = dataViewForm?.data?.status?.data;

      if (isErrorViewForm) {
        openAlert(dataForm?.data?.status.message, "error");
        navigate(`/`);
        return;
      }

      if (isSuccessViewForm) {
        dataForm?.expiration_final_date &&

          setOpcional(dataForm?.check_duta);
        setForm(dataForm);
        setExpirationDate(dataForm?.expiration_final_date);
        setCode(dataForm?.code_operation);
        setPrecision(dataForm?.precision);
        setreviewUpdates(dataForm?.review_updates);
        setStatusActual(dataForm?.users);
        setDocumentOperation(dataForm?.documents);
        setOtherDocuments(dataForm?.other_files);

        const fileServer = dataForm.files.reduce((old, it, index) => {
          return {
            ...old,
            ["document-" + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
              originalIndex: index,
              attachment_number: it.attachment_number,
              attachment_description: it.attachment_description,
              attachment_comment: it.attachment_comment,
              attachment_date: it.attachment_date,
            },
          };
        }, {});

        setFiles({
          ...fileServer,
        });

        setOtherDocuments(dataForm?.other_files);
      }
    }
  }, [isFetchingViewForm]);


  /*  const viewForms = async (id) => {
     setLoading(true);
     try {
       const res = await __ShowForm(token, Number, environment.SHOW_FORM_EXIT);
       const resultado = res?.data?.status?.data;
 
       setForm(resultado);
       setCode(resultado.code_operation);
       setPrecision(resultado?.precision);
       setreviewUpdates(resultado?.review_updates);
       setStatusActual(resultado?.users);
       setDocumentOperation(resultado?.documents);
       const fromServer = resultado.files.reduce((old, it) => {
         return {
           ...old,
           ["document-" + it.documentReq_id]: {
             name: it.attachment_name,
             document_id: it.documentReq_id,
             downloadFile: it.attachment_url,
             from_server: true,
             id: it.id,
           },
         };
       }, {});
       setFiles({
         ...files,
         ...fromServer,
       });
       setAttachment(resultado?.other_files);
       resultado.other_files?.map((items) =>
         setAttachmentDescription((prev) => [
           ...prev,
           items?.other_attachment_description,
         ])
       );
     } catch (error) {
     } finally {
       setLoading(false);
     }
   }; */

  const [load, setLoad] = useState([]);
  const [maxPageTrackings, setMaxPageTrackings] = useState(1);
  const [currentPageTrackings, setCurrentPageTrackings] = useState([]);
  const [isOpenShowItems, onOpenShowItems, onCloseShowItems] = useModal();
  const [pageTrackings, setPageTrackings] = useState(1);

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPageTrackings(res.data.status.data.data);
        setMaxPageTrackings(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpenShowItems();
        setLoad(false);
      }
    },
    [pageTrackings]
  );

  const handlePresent = (status) => {
    const info = {
      id: form.id,
      status_outputForm: status,
    };
    changeStatus(info);
  };
  const notationsDestruct = useNotations({
    storageKey: Number,
    onOpen: onOpenComment,
    notationTypes: [
      {
        color: "gray",
        enableComment: false,
      },
      {
        color: "green",
        enableComment: false,
      },
      {
        color: "#fc0a0a",
        enableComment: true,
      },
    ],
    reviews: form?.reviews,
    reviewsUpdates: form.review_updates,
    reviewsNew: form?.reviews_new ? cleanDeepObject(form?.reviews_new) : {},
  });

  const { observations, currentCommentary, UpdateComment } = notationsDestruct;

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };
  const [openAlert] = AlertErrorAlert();

  const handleFilesInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const Files = e.target.files;

    const extensionOptions = [
      ".doc",
      ".docx",
      ".pdf",
      ".png",
      ".jpg",
      ".jpeg",
      ".gif",
      ".bmp",
      ".xls",
      ".xlsx",
      ".xlsm",
      "image/png",
      "image/*",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/vnd.ms-excel",
    ];

    const getFileExtension = Files[0].name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);

    if (!isValidExtension) {
      const fileName = Files[0].name;
      return openAlert(
        `El archivo ${fileName} no es un tipo de archivo permitido.`,
        "error"
      );
    }

    Vapor.store(Files[0], {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("respuesta al momento de cargar", response);
      setFiles(
        Object.assign(
          { ...files },
          {
            [e.target.id]: {
              file: response,
              name: Files[0].name,
              document_id: e.target.id.replace("document-", ""),
            },
          }
        )
      );
      setNameFiles([...nameFiles, { name }]);
    });
  };

  const removeItemFromArr = (archivo, nombre) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${nombre}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          if (files[archivo].from_server) {
            deleteAttachment(
              environment.DELETE_ATTACHMENT_ENTRY,
              files[archivo].id
            );
          }
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            console.log(obj[archivo]);
            delete obj[archivo];
          }
          setFiles(obj);
        }
      }
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (items && value.length > 0) {
      const tabIndex = parseInt(value);
      const index = items.findIndex((info) => tabIndex === info?.item);
    }
  };

  const [LockStatus, setLockStatus] = useState(null);
  const isAuthorizationDeletedDocument =
    form?.status_outputForm !== "BORRADOR" && is_commerce === 1;

  const lockUnlock = LockStatus === 1 ? "desbloquear" : "bloquear";

  const isAuthorizedToLockUnlock = modules?.some((item) =>
    item.permission.some(
      (name) => name.name_permission === "lock_and_unlock_output_form"
    )
  );

  const UnlockForm = async () => {
    try {
      Swal.fire({
        title: `¿Estas seguro de ${lockUnlock} el formulario "${form?.id}"?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#1F7AC3",
        confirmButtonText: "Confirmar",
        customClass: {
          title: "class-text",
        },
      }).then(async (resultado) => {
        if (resultado.isConfirmed) {
          const res = await __Post(
            environment.LOCK_AND_UNLOCK_OUTPUT_FORM,
            token,
            {
              outputForm_id: form?.id,
              block: LockStatus === 1 ? 0 : 1,
              type_block: "block_for_subheading_approval",
            }
          );

          if (res?.data?.status?.code === 200) {
            setLockStatus(
              res?.data?.status?.data?.block_for_subheading_approval
            );
            return openAlert(res?.data?.status?.message, "success");
          }

          if (res?.data?.status?.code === 400) {
            return openAlert(res?.data?.status?.message, "error");
          }
        }
      });
    } catch (error) { }
  };

  useEffect(() => {
    setLockStatus(form?.block_for_subheading_approval);
  }, [form]);

  const viewLockUnlock =
    form?.block_for_subheading_approval === 1 &&
    form?.status_outputForm === "PRESENTADO" &&
    is_qualified_user !== 1;

  const submitReviewBasedOnStatus = async () => {
    if (is_qualified_user) return;

    if (form?.status_outputForm === "PRESENTADO") {
      const body = new FormData();
      body.append("form_id", Number);
      body.append("type_of", "FMMS");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [form]);

  async function fetchServerPage({ pageParam = 1 }) {
    const formData = new FormData();
    formData.append("outputForm_id", Number);
    formData.append("length", 5);
    formData.append("item", searchItem);
    formData.append("orderBy", orderBy?.value);

    const res = await __GetItems(
      token,
      formData,
      pageParam,
      environment.GET_iTEM_EXIT
    );

    if (res?.data?.status?.code === 200) {
      const page =
        pageParam === res?.data?.status?.data?.last_page ? null : pageParam + 1;
      setItems(res?.data?.status?.data?.data);

      return {
        items: res?.data?.status?.data?.data,
        nextCursor: page,
      };
    }
  }

  const [listMode, setlistMode] = useState(false);

  const switchLoadingItem = () => {
    setlistMode((prev) => !prev);
  };

  const {
    status,
    data,
    error: isErrorPage,
    isFetching,
    isLoading: isLoadingItems,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["fetchServerPage", Searchdebounced, orderBy],
    queryFn: fetchServerPage,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const allItems = data ? data.pages.flatMap((d) => d?.items) : [];

  const handleChangeSearch = (e) => {
    setSearchItem(e.target.value);
  };

  const propsBlockForm = {
    form_id: Number,
    type_of: "FMMS",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };
  const annotationInfo = {
    notations: notationsDestruct,
    id: "Header",
    data: form,
  };

  const { pathname } = location;
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const navigateIfEligibleForUpdate = (
    flag,
    form_id,
    new_status,
    dataCurrent
  ) => {
    const shouldRefetch =
      flag === "output_form" &&
      new_status !== dataCurrent?.status_outputForm &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.id;

    if (shouldRefetch) {
      navigate(`/FormShowExit?ExitNumber=${form_id || data.form_id}`);
      return;
    }
  };

  useStatusListener({
    id: form?.id,
    callBack: navigateIfEligibleForUpdate,
    dataCurrent: form,
  });

  const { mutate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      refetchViewForm();
      //  return setCalculated(res?.data?.status?.data);
    }
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const updateAndCreate = async (data = undefined, payloadManual = false) => {
    const formData = new FormData();
    formData.append("id", Number);
    const dataAttachments = attachment[0];
    const vaporRes = await uploadFile(dataAttachments?.file);

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };
    formData.append(
      "other_attachment[]",
      JSON.stringify({ ...file, name: dataAttachments?.file?.name })
    );
    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      if (data) {
        Object.keys(data).forEach((documentId) => {
          if (!data[documentId].from_server) {
            formData.append(
              "attachment[" + documentId + "]",
              JSON.stringify(data[documentId])
            );
          }
        });
      }
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.UPDATE_FORM_EXIT,
      PropertyBody: "sendFormDataContentPOST",
    };

    setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  /*   useEffect(() => {
    if (!form?.id) {
      return;
    }

    const channelName = `change-form-status-${form.id}`;
    const channel = window.Echo.channel(channelName);

    const handleNewStatus = (data) => {
      const { flag, form_id, new_status } = data;

      const shouldRefetch =
        flag === "output_form" &&
        new_status !== form.status_outputForm &&
        new_status !== "REVISADO" &&
        form_id === form.id;

      if (
        shouldRefetch &&
        new_status !== "REVISADO" 
      ) {
        navigate(`/FormShowExit?ExitNumber=${data.form_id}`);
        return;
      }
    };

    channel.listen(".new-status-form", handleNewStatus);

    return () => {
      channel.stopListening(".new-status-form", handleNewStatus);
    };
  }, [form]); */

  return (
    <>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <CommentManager
        identifier={Number}
        commentGetService={environment.GET_COMMENT_FMM_EXIT}
        commentCreationService={environment.COMMENT_FMM_EXIT}
        properties={commentTemplate}
      >
        <Action
          style={{
            backgroundColor: "#3498db",
            width: "40px",
            height: "40px",
          }}
          text={listMode ? "Scroll infinito" : "Modo normal"}
          onClick={switchLoadingItem}
        >
          <RiListSettingsLine />
        </Action>
      </CommentManager>

      <ModalGeneric
        isOpen={isOpenComment}
        onOpen={onOpenComment}
        onClose={onCloseComment}
        title="observación"
        size="xl"
      >
        <ModalObservation
          currentCommentary={currentCommentary}
          data={observations}
          set={UpdateComment}
          onClose={onCloseComment}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenShowItems}
        onOpen={onOpenShowItems}
        onClose={onCloseShowItems}
        title={"Seguimiento"}
      >
        <TableOrder // tabla
          thead={TheadShowTrackingItems}
          data={currentPageTrackings}
          setData={setCurrentPageTrackings}
          loading={load}
          tbodyData={TBodyShowTrackingFmm}
        />
        <Pagination
          page={pageTrackings}
          setPage={setPageTrackings}
          maxPage={maxPageTrackings}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onCloseShowItems}>Cerrar</Button>
        </div>
      </ModalGeneric>

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar este formulario`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenDesechar}
        onClose={onCloseDesechar}
        title={`Desea desechar el formulario Nº ${dataToDesechar?.code_outputForm} 
        <br> <span style="color: red">Importante:</span> Esta acción no se puede deshacer!`}
        size={"xl"}
      >
        <div className="d-flex justify-content-end mt-4 mb-2">
          <Button onClick={onCloseDesechar}>Cancel</Button>
          <Button
            colorScheme="red"
            onClick={() => handlePresent("ELIMINADO")}
            ml={3}
          >
            Desechar
          </Button>
        </div>
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          {/* <SectionHeader secTitle={`Formulario de salida`}></SectionHeader> */}
          <div className=" my-5">
            <HeaderForm
              status={form?.status_outputForm}
              numberForm={form?.code_outputForm}
              UserQualified={form?.name_qualified}
              Modulo={"Formulario de salida en Zonas Francas"}
              items={items}
            />
          </div>
          <BtnCheckFmm values={form} setStateForm={setForm}>
          <CreateAt
              entries={statusActual}
              config={{
                statusKey: "status_outputForm",
                dateKey: "date_outputForm",
                timeKey: "time_outputForm",
                personNameKey: "person_name",
              }}
              expirationDate={expirationDate}
            />
            {viewLockUnlock && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                className="alert alert-warning w-50 pt-2 pb-2 m-auto mt-3 pl-2"
                style={{ minHeight: "60px" }}
                role="alert"
              >
                {`Aprobación del documento esta ${LockStatus === 1 ? "bloqueada" : "desbloqueada"
                  }`}
                {(isAuthorizedToLockUnlock || admin === 1) && (
                  <Button onClick={UnlockForm} colorScheme="blackAlpha">
                    {LockStatus === 1 ? "Desbloquear" : "Bloquear"}
                  </Button>
                )}
              </Box>
            )}
          </BtnCheckFmm>

          <div className="d-flex justify-content-end mb-5">
            {/* <ButtonView data={form} /> */}
          </div>
          <form action="">
            <HeaderSearch>
              <div className="row">
                {/* Empresa */}
                {renderInputWithLabel({
                  labelText: "Empresa",
                  name: "name_qualified",
                  manualValue: `${form?.nit_qualified} - ${form?.name_qualified}`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Importador/Comprador */}
                {renderInputWithLabel({
                  labelText: "Importador/Comprador",
                  name: "business_name",
                  manualValue: `${form?.identification_num ?? ""} - ${form?.business_name ?? ""
                    }`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Tipo de operación */}
                {renderInputWithLabel({
                  labelText: "Tipo de operación",
                  name: "short_name_operation",
                  manualValue: `${form.code_operation ?? ""} ${form?.short_name_operation ?? "N/A"
                    }`,
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Modalidad */}
                {renderInputWithLabel({
                  labelText: "Modalidad",
                  name: "refund",
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Embarque */}
                {renderInputWithLabel({
                  labelText: "Embarque",
                  name: "shipment",
                  activeComment: isCheckFormExit || isCheckFormEntry,
                  PropsBox: {
                    className: "col-md-6",
                  },
                  annotationInfo,
                })}

                {/* Dirección de destino */}
                {addresDestine.includes(form.code_operation) &&
                  renderInputWithLabel({
                    labelText: "Dirección de destino",
                    name: "destination_address",
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    PropsBox: {
                      className: "col-md-6",
                    },
                    annotationInfo,
                  })}

                {/* Plazo */}
                {form.term !== 0 &&
                  renderInputWithLabel({
                    labelText: "Plazo",
                    name: "term",
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    PropsBox: {
                      className: "col-md-6",
                    },
                    annotationInfo,
                  })}

                {/* Requiere pase */}
                {code === 426 &&
                  renderInputWithLabel({
                    labelText: "Requiere pase",
                    name: "term",
                    manualValue:
                      form?.pass_required === 1
                        ? "Requiere pase"
                        : "No requiere pase",
                    activeComment: isCheckFormExit || isCheckFormEntry,
                    PropsBox: {
                      className: "col-md-6",
                    },
                    annotationInfo,
                  })}
              </div>
            </HeaderSearch>

            <HeaderSearch>
              <div id="contenedores">
                <div className="row">
                  <ContainerTitleOutput code={code} />
                  <span>
                    <Text as="sub">* Opcional</Text>
                    <br />
                  </span>
                  <RadioCheck
                    set={setOpcional}
                    value={opcional}
                    item={dataDUTA}
                    disables={true}
                  />
                  {code ? (
                    code === 50 ? (
                      <TerritoryNationalDisable
                        form={form}
                        notations={notationsDestruct}
                      />
                    ) : code >= 201 && code <= 224 ? (
                      <RestOfTheWorldDisabled
                        form={form}
                        notations={notationsDestruct}
                      />
                    ) : code >= 401 && code <= 450 ? (
                      <TerritoryNationalDisable
                        form={form}
                        notations={notationsDestruct}
                      />
                    ) : code >= 601 && code <= 618 ? (
                      <RestOfTheWorldDisabled
                        form={form}
                        title="A zona franca"
                        notations={notationsDestruct}
                      />
                    ) : code >= 801 && code <= 814 ? (
                      <UserToUserDisabled
                        form={form}
                        notations={notationsDestruct}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {opcional && (
                    <LabelGroup
                      name={"value_duta"}
                      value={form?.value_duta}
                      // onChange={handleInputChange}
                      tag={opcional}
                      size={45}
                      desicion={true}
                    // readOnly
                    />
                  )}
                </div>
              </div>
            </HeaderSearch>

            {items?.length !== 0 && (
              <HeaderSearch>
                <TableItems currentData={form} />
              </HeaderSearch>
            )}

            <HeaderSearch>
              {DocumentOperation.map((document, index) => (
                <div key={index}>
                  <DocumentUploader
                    document={document}
                    handleFiles={handleFiles}
                    handleFilesInput={handleFilesInput}
                    handleDelete={removeItemFromArr}
                    files={files}
                    setNameFiles={setNameFiles}
                    nameFiles={nameFiles}
                    index={index}
                    viewBtnDelete={isAuthorizationDeletedDocument}
                    documentPreview={true}
                  />
                </div>
              ))}
            </HeaderSearch>

            <HeaderSearch>
              <OtherAttchment
                otherDocuments={otherDocuments}
                titulo={"Otros documentos"}
                attachment={attachment}
                setAttachment={setAttachment}
                attachmentType={attachmentType}
                setAttachmentType={setAttachmentType}
                deleteAttachment={deleteAttachment}
                ruta={environment.DELETE_OTHER_ATTACHMENT_EXIT}
                viewBtn={true}
                viewBtnDelete={isAuthorizationDeletedDocument}
                update={updateAndCreate}
                documentPreview={true}
              />
            </HeaderSearch>
            {items?.length > 1 && (
              <Box w={"16"}>
                <Box display={"flex"} gap={4}>
                  <InputWithLabel
                    type="number"
                    labelText={"Buscar item"}
                    borderRadius={0}
                    pattern="[0-9]*"
                    placeholder={"0"}
                    onChange={handleInputChange}
                    readOnly={false}
                  />
                </Box>
              </Box>
            )}

            <Box
              display={'flex'}
              alignItems={'center'}
              w={'100%'}
              mt={'1rem'}
              gap={3}
            >
              <Box w={"64"} mb={6} mt={6}>
                <label
                  style={{
                    width: "100%",
                  }}
                >
                  Buscar item
                  <InputGroup>
                    <Input
                      w={"100%"}
                      h={"38px"}
                      color={"#000"}
                      outline={"none"}
                      fontSize={"1rem"}
                      backgroundColor={"#ffffff87"}
                      onChange={handleChangeSearch}
                      value={searchItem}
                      placeholder={"Buscar item"}
                    />
                    <InputRightElement style={{}} as="button">
                      <RiSearch2Line color="green.500" />
                    </InputRightElement>
                  </InputGroup>
                </label>
              </Box>
              <label
                style={{
                  width: 300
                }}
              >
                <label style={{ width: '100%' }}></label>

                <InputSelect
                  data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                  value={orderBy}
                  name="order_by"
                  set={setOrderBy}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: '#5f5f',
                  }}
                  isClearable={false}
                />
              </label>
            </Box>

            <RenderItems
              notationsDestruct={notationsDestruct}
              reviewUpdates={reviewUpdates}
              observations={observations}
              alldata={form}
              FetchingStatus={status}
              admin={admin}
              dataItems={allItems}
              formData={form}
              isLoadingItems={isLoadingItems}
              error={isErrorPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              is_commerce={is_commerce}
              fetchNextPage={fetchNextPage}
              showTrackingItem={showTrackingItem}
              isInfiniteScroll={listMode}
              templateProperty={{
                propertyStatus: "status_outputForm",
              }}
            />
          </form>
        </>
      )}
    </>
  );
};
