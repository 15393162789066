/* eslint-disable no-unused-vars */
import { TabPanel } from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import Pagination from "components/Pagination/Pagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TableOrder from "components/Tables/TableOrder";
import { useSelector } from "react-redux";
import { headerTabs } from "./ArrayOrder/ArrayHeadOrder";
import {
  elaborateProductDos,
  HeadGarbages,
  HeadOtherCosts,
  HeadOtherCostsNoEdit,
  Matter
} from "./ArrayOrder/ArrayTableOrder";
import TBodyMatter from "./CreateNewProductoOrder/Table/TBodyMatter";
import TbodyOtherCosts from "./CreateNewProductoOrder/Table/TbodyOtherCosts";
import TBodyGarbages from "./CreateNewProductoOrder/Table/TBodyGarbages";
import { TabsCreateOrder } from "./TabsCreate/TabsCreateOrder";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { __ShowFormOrder } from "request/OrderProduction/OrderProduction";
import OrderProductionContext from "context/OrderProductionContext";
import { SatausOfOrder } from "./BotonesShow/SatausOfOrder";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TableInformation } from "./TableInormativa/TableInformation";
import { BtnCheckFmm } from "./BotonesShow/BtnCheckFmm";
import TBodyElaboradoNoAccion from "./CreateNewProductoOrder/Table/TBodyElaboradoNoAccion";
import { tdGarbages, tdMatter, tdOther, tdProduct } from "./Tdfalse/TdFlase";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import TbodyGarbagesUnit from "./CreateNewProductoOrder/Table/TbodyGarbagesUnit";
import TbodyUnd from "./CreateNewProductoOrder/Table/TbodyUnd";
import { isValid } from "utils/parseFloat";
import {
  useQuery,
  keepPreviousData,
} from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import { isArray } from "utils/type-check-utils";
import environment from "constants/apiConst";
import TbodyOtherCostsNoEdit from "./CreateNewProductoOrder/Table/TbodyOtherCostsNoEdit";

export const CheckFmmOrder = () => {
  const initialForm = {
    production_order_status: "",
    production_order_code: "",
    qualified_users_name: "",
    qualified_users_id: "",
    difference: [],
    other_costs: [],
    supply: [],
    total_calculated: [],
    total_product: [],
    observation_totals: []
  };
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [positionView, setPositionView] = useState(0);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation(); // navigate
  const { Orden = "" } = queryString.parse(location.search);
  const { requestData } = useGlobalContext();
  const { update, setDataToGeneral } = useContext(OrderProductionContext);
  const positionProperties = ["product", "supply", "other_costs", "garbage"];


  const handleCambio = (index) => {
    setPositionView(index);
  };

  const QueryConfig = {
    GET_PRODUCT_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_PRODUCT_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.product,
        }),
    },
    GET_SUPPLY_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_SUPPLY_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.supply,
        }),
    },
    GET_OTHERCOST_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_OTHERCOST_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.other_costs,
        }),
    },
    GET_GARBAGE_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_GARBAGE_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.garbage,
        }),
    },
    SHOW_PRODUCTION_ORDER: {
      queryFn: () =>
        requestData({
          Endpoint: environment.SHOW_PRODUCTION_ORDER,
          PropertyBody: "GET",
          resourceIdentifier: Orden,
        }),
    },
  };

  //BEGIN - Datos de la orden de producción
  const { data: productionOrderData, isLoading: isLoadingProductionOrderData } =
    useQuery({
      queryKey: ["getProductionOrderData", Orden, update, token],
      queryFn: QueryConfig.SHOW_PRODUCTION_ORDER.queryFn,
      enabled: isValid(Orden),
    });

  useEffect(() => {
    if (isValid(productionOrderData) && !isLoadingProductionOrderData) {
      const productionData = productionOrderData?.data?.status?.data;
      setForm(productionData);
      setDataToGeneral(productionData);
    }
  }, [productionOrderData]);

  // END - Datos de la orden de producción

  //BEGIN - Producto elaborado
  const { data: getProductItemOP, isLoading: isLoadingGetProductItemOP } =
    useQuery({
      queryKey: ["getProductItemOP", Orden, update, page?.product],
      queryFn: QueryConfig.GET_PRODUCT_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const ProductItemOP = getProductItemOP?.data?.status?.data?.data;
  const totalSumProductItemOP =
    isValid(ProductItemOP) && isArray(ProductItemOP)
      ? ProductItemOP.reduce(
          (sum, item) => sum + parseInt(item?.quantity || 0),
          0
        )
      : 0;

  //END - Producto elaborado

  //BEGIN - Materias primas e insumos
  const { data: getSupplyItemOP, isLoading: isLoadingGetSupplyItemOP } =
    useQuery({
      queryKey: ["getSupplyItemOP", Orden, update, page?.supply],
      queryFn: QueryConfig.GET_SUPPLY_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const SupplyItemOP = getSupplyItemOP?.data?.status?.data?.data;
  const totalSumSupplyItemOP =
    isValid(SupplyItemOP) && isArray(SupplyItemOP)
      ? SupplyItemOP.reduce(
          (sum, item) => sum + parseInt(item?.quantity || 0),
          0
        )
      : 0;

  //END - Materias primas e insumos

  //BEGIN - Otros costos y gastos
  const { data: getOtherCostItemOP, isLoading: isLoadingGetOtherCostItemOP } =
    useQuery({
      queryKey: ["getOtherCostItemOP", Orden, update, page?.other_costs],
      queryFn: QueryConfig.GET_OTHERCOST_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const OtherCostItemOP = getOtherCostItemOP?.data?.status?.data?.data;
  const totalOtherCostItemOP =
    isValid(OtherCostItemOP) && isArray(OtherCostItemOP)
      ? OtherCostItemOP.reduce(
          (sum, item) => sum + parseInt(item?.net_weight_cost || 0),
          0
        )
      : 0;

  //END - Otros costos y gastos

  //BEGIN - Desperdicios
  const { data: getGarbageItemOP, isLoading: isLoadingGetGarbageItemOP } =
    useQuery({
      queryKey: ["getGarbageItemOP", Orden, update, page?.garbage],
      queryFn: QueryConfig.GET_GARBAGE_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const GarbageItemOP = getGarbageItemOP?.data?.status?.data?.data;
  //END - Desperdicios

  useEffect(() => {
    if (isValid(ProductItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        product: getProductItemOP?.data?.status?.data?.last_page,
      }));
    }

    if (isValid(SupplyItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        supply: getSupplyItemOP?.data?.status?.data?.last_page,
      }));
    }
    if (isValid(OtherCostItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        other_costs: getOtherCostItemOP?.data?.status?.data?.last_page,
      }));
    }

    if (isValid(GarbageItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        garbage: getGarbageItemOP?.data?.status?.data?.last_page,
      }));
    }
  }, [GarbageItemOP, OtherCostItemOP, ProductItemOP, SupplyItemOP]);

  return (
    <>
      <div className="container mt-5">
        <HeaderSearch>
          {loading && <SpinnerComponent />}
          {!loading && (
            <HeaderForm
              status={form?.production_order_status}
              numberForm={form?.production_order_code}
              UserQualified={form?.qualified_users_name}
              Modulo={
                "Edición de Formulario de Orden de producción En Zonas Francas"
              }
            />
          )}

          <BtnCheckFmm values={form}>
            {form.reason_for_return && (
              <AlertReturnReason
                title={"Tipo de devolución:"}
                item={form.typifications}
                description={form.reason_for_return && form.reason_for_return}
                nickname={form.nickname}
              />
            )}
            <SatausOfOrder values={form} />
          </BtnCheckFmm>
        </HeaderSearch>

        <TableInformation values={form} />

        <TabsCreateOrder head={headerTabs} change={handleCambio}>
              <TabPanel>
                <TableOrder
                  thead={elaborateProductDos}
                  data={ProductItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyElaboradoNoAccion}
                  subTotals={totalSumProductItemOP}
                  titleTotal="Total"
                  td={tdProduct}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={
                 Matter
                  }
                  data={SupplyItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyMatter}
                  subTotals={totalSumSupplyItemOP}
                  titleTotal="Total"
                  td={tdMatter}
                  // tUnid={currentPage?.unit_array_value}
                  tbodyUnd={TbodyUnd}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={HeadOtherCostsNoEdit}
                  data={OtherCostItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyOtherCostsNoEdit}
                  subTotals={totalOtherCostItemOP}
                  titleTotal="Total"
                  td={tdOther}
                />
              </TabPanel>
              <TabPanel>
                <TableOrder
                  thead={HeadGarbages}
                  data={GarbageItemOP || []}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TBodyGarbages}
                  //   subTotals={currentPage?.totals_garbages}
                  titleTotal="Total"
                  td={tdGarbages}
                  // tUnid={currentPage?.unit_array_value_garbage}
                  tbodyUnd={TbodyGarbagesUnit}
                />
              </TabPanel>

              <Pagination
                page={page[positionProperties[positionView]]}
                setPage={setPage}
                StoreProperty={positionProperties[positionView]}
                maxPage={maxPage[positionProperties[positionView]] || 1}
                loading={loading}
              />
            </TabsCreateOrder>
      </div>
    </>
  );
};
