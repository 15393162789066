import React, { useContext, useEffect } from "react";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiDeviceRecoverFill } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { AuthContext } from "context/AuthContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useFormBlocker } from "hooks/useFormBlocker";
import environment from "constants/apiConst";
import Swal from "sweetalert2";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FaUnlock } from "react-icons/fa";
import { __Post } from "request/Petitions/__Post";
import { useSelector } from "react-redux";

export const Buttonssubmitted = ({
  handleShow,
  handleCopy,
  handleStatusBox,
  handleNavigateReview,
  handleNavigateCorrected,
  handleChangeStatusCorrected
}) => {
  const { ChangeStatusCertified } = useContext(IntegracionBoxCrudContex);
  const { authUser } = useContext(AuthContext);

  const location = useLocation();
  const { idCorrected = null, id = null } = queryString.parse(location.search);
  const [openAlert] = AlertErrorAlert()
  const token = useSelector((state) => state.auth.dataUser.token);

  const { blockForm, formLockQueryResult, isBlocked } = useFormBlocker({
    form_id: id || idCorrected,
    type_of: id ? "CI" : "CIC",
    userProvidedEndpoint: environment.FORMS_ON_REVIEW
  });

  const showAlert = (title, message, callback = () => { }, status) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: "Desbloquear",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1e75bb",
      position: "top"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = new FormData();
        body.append("form_id", id || idCorrected);
        body.append("type_of", id ? "CI" : "CIC");
        body.append("type", 2);
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
        if (res?.data?.status?.code === 200) {
          openAlert('Formulario desbloqueado', 'success')
          blockForm(2);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const showAlert2 = (title, message, status) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: "Desbloquear",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1e75bb",
      position: "top"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = new FormData();
        body.append("form_id", id || idCorrected);
        body.append("type_of", id ? "CI" : "CIC");
        body.append("type", 2);
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body);
        if (res?.data?.status?.code === 200) {
          openAlert('Formulario desbloqueado', 'success')
          blockForm(2);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const ChangeStatus = async (status) => {
    const reviewIntegrationCertificate = {
      id,
      status
    };

    if (isBlocked) return showAlert("¿Deseas desbloquear el cuadro de integración?", formLockQueryResult?.message, status);

    if (!isBlocked) {
      ChangeStatusCertified(reviewIntegrationCertificate, handleShow);

    }
  };

  const ChangeStatusCorrected = () => {
    if (isBlocked) return showAlert2("¿Deseas desbloquear el cuadro de integración corregido?", formLockQueryResult?.message)

    if (!isBlocked) {
      handleNavigateReview()
    }
  }

  return (
    <>
      {authUser.is_qualified_user !== 1 && isBlocked && <Tooltip placement="top" hasArrow label="Desbloquear">
        <span>
          <ButtonHeaderModule
            onClick={() => {
              id && ChangeStatus("REVISADO")
              idCorrected && ChangeStatusCorrected()
            }}
            className="m-2"
            bgColor="#fe8d59"
          >
            <IconGeneric
              className="m-1 text-white"
              as={FaUnlock}
            />
          </ButtonHeaderModule>
        </span>
      </Tooltip>}

      {idCorrected
        ? (
          <>
            <Tooltip placement="top" hasArrow label="Recuperar">
              <span>
                <ButtonHeaderModule
                  onClick={() => isBlocked ? openAlert(formLockQueryResult?.message, 'error') : handleChangeStatusCorrected("BORRADOR")}
                  className="m-2"
                  bgColor="#1F7AC3"
                  disabled={isBlocked}
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={RiDeviceRecoverFill}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            {authUser.is_qualified_user !== 1 && idCorrected && (
              <Tooltip placement="top" hasArrow label="Revisar">
                <span>
                  <ButtonHeaderModule
                    onClick={handleNavigateReview}
                    className="m-2"
                    bgColor="#58B158"
                    disabled={isBlocked}
                  >
                    <IconGeneric className="m-1 text-white" as={BiShow} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}
          </>
        )
        : (
          <>
            <Tooltip placement="top" hasArrow label="Recuperar">
              <span>
                <ButtonHeaderModule
                  onClick={() => isBlocked ? openAlert(formLockQueryResult?.message, 'error') : handleStatusBox("BORRADOR")}
                  className="m-2"
                  bgColor="#1F7AC3"
                  disabled={isBlocked}
                >
                  <IconGeneric
                    className="m-1 text-white"
                    as={RiDeviceRecoverFill}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            {authUser.is_qualified_user !== 1 && !idCorrected && (
              <Tooltip placement="top" hasArrow label="Revisar">
                <span>
                  <ButtonHeaderModule
                    onClick={() => ChangeStatus("REVISADO")}
                    className="m-2"
                    bgColor="#58B158"
                    disabled={isBlocked}

                  >
                    <IconGeneric className="m-1 text-white" as={BiShow} />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

            <Tooltip placement="top" hasArrow label="Copiar">
              <span>
                <ButtonHeaderModule
                  onClick={handleCopy}
                  className="m-2"
                  bgColor="#eeca06"
                >
                  <IconGeneric className="m-1 text-white" as={MdContentCopy} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </>
        )}
    </>
  );
};
