/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import OrderProductionContext from "context/OrderProductionContext";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { NavLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "context/AuthContext";
import { useSelector } from "react-redux";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { IconTracking } from "styled/Icons.styled";
import { RiRadioButtonFill } from "react-icons/ri";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import { ModalGeneric } from "components/modal/ModalGeneric";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import { isValid } from "utils/type-check-utils";

// import CustomsCrudContext from "context/CustomsCrudContext";

const TBodyMatterNoEdit = ({ data }) => {
  const [first, setfirst] = useState(null);
  const optionsBalance = ["%", "Cnt"];
  const location = useLocation(); // navigate
  const { Orden = "" } = queryString.parse(location.search);
  const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
  const { authUser } = useContext(AuthContext);
  const [fomateo] = useFormatQuantity();
  const token = useSelector((state) => state.auth.dataUser.token);
  const dataUser = useSelector((state) => state.auth.dataUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);

  const urlTemplates = {
    "CERTIF.INT": `/ShowIntegrationBox?id=${data?.form_id}`,
    INGRESO: `/FormShow?FormEntry=${data?.form_id}`,
  };

  useEffect(() => {
    setValues({ ...values, percentage_garbage: data?.percentage_garbage });
    setfirst(
      data.type_quantity_garbage == 0
        ? "%"
        : data.type_quantity_garbage == 1
        ? "Cnt"
        : null
    );
  }, [data]);
  const { createDps, decimalUc } = useContext(OrderProductionContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    percentage_garbage: "",
  });
  let { percentage_garbage } = values;

  const handleBlurCant = (e) => {
    let posibleResult = e.target.value;
    if (posibleResult) {
      posibleResult = String(posibleResult).replaceAll(",", "");
      const indo = {
        production_order_id: Orden,
        supply_id: data.product_id,
        quantity: fomateo(
          (authUser.admin === 1 || authUser.is_commerce === 1) && decimalUc
            ? decimalUc
            : Number(decimal_values_precision),
          percentage_garbage
        ),

        type: first === "%" ? 0 : 1,
      };
      createDps({ ...indo, quantity: percentage_garbage.replace(",", "") });
    } else {
      updateObjet();
    }
  };

  const updateObjet = () => {
    const indo = {
      production_order_id: Orden,
      supply_id: data.product_id,
      quantity: percentage_garbage,
      type: first === "%" ? 0 : 1,
    };
    createDps(indo);
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );
  
  if(first === "%"){
    percentage_garbage = data.percentage_garbage;
  }else{
    percentage_garbage = data.quantity_garbage;
  }
  
  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr>
        <Td className="text-center">{data?.item}</Td>

        <Td className="text-center">
          {urlTemplates?.[data?.type_form] ? (
            <NavLink
              to={urlTemplates?.[data?.type_form]}
              className="ms-2"
              target="_blank"
            >
              <span className="formNumber">{data?.reference_description}</span>
            </NavLink>
          ) : (
            <> {data?.reference_description}</>
          )}
        </Td>
        <Td className="text-center">{data?.code_product}</Td>
        <Td className="text-center">{data?.business_unit_code_product}</Td>
        <Td className="text-center">{data?.quantity_product}</Td>
        <Td isNumeric className="text-center">
          {data?.code_subheading ?? ""}
        </Td>
        <Td isNumeric className="text-center">
          {data?.business_unit}
        </Td>
        <Td isNumeric className="text-center">
          {data?.quantity ?? ""}
        </Td>
        <Td className="text-center">{data?.nature_of_inventory}</Td>
        <Td className="text-center">{data?.operation_code}</Td>
        <Td isNumeric className="text-center">
          {data?.net_weight_kilo}
        </Td>
        <Td isNumeric className="text-center">
          {data?.total_us_product}
        </Td>
        {/* <Td className="text-center">{data?.percentage_garbage}</Td> */}
        <Td className="text-center">
          <div className="mt-1">
            <RadioBtn
              setOnly={setfirst}
              options={optionsBalance}
              state={first}
            />
          </div>
        </Td>
        <Td className="text-center">
          <LabelGroup
            style={{ width: "70px" }}
            name={`percentage_garbage`}
            value={
              percentage_garbage
            }
            onChange={handleInputChange}
            onBlur={handleBlurCant}
            id={data.product_id}
            tag={""}
            size={100}
            tipo={"text"}
            desicion={!first}
          />
        </Td>
        {dataUser?.is_qualified_user !== 1 && (
          <Td className="text-center">
            {data?.tracking?.length ? (
              <Tooltip
                hasArrow
                placement="top"
                label={
                  data?.tracking?.some((tracking) => tracking.status === 1)
                    ? "En seguimiento"
                    : data?.tracking?.every((tracking) => tracking.status === 2)
                    ? "Estuvo en seguimiento"
                    : ""
                }
              >
                {data?.tracking?.length ? (
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconTracking
                      color={
                        data?.tracking?.some(
                          (tracking) => tracking?.status === 1
                        )
                          ? "#E64545"
                          : "#20B95A"
                      }
                      onClick={() => showTrackingItem(data?.tracking)}
                      as={RiRadioButtonFill}
                    ></IconTracking>
                  </span>
                ) : (
                  ""
                )}
              </Tooltip>
            ) : (
              ""
            )}
          </Td>
        )}

        <Td className="text-center">
          {data?.trade_agreement ? data?.trade_agreement : "N/A"}
        </Td>
        <Td className="text-center">{data?.product_description}</Td>
      </Tr>
    </>
  );
};

export default TBodyMatterNoEdit;
