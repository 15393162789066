import axios from "axios";
export const __SearchReviews = async (endpoint, token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

export const __SearchUser = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __Search = async (endpoint, token, body, page) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

export const __SearchPOST = async (endpoint, token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}?page=${page || 1}`, options);
  return res;
};

