const TheadAccordion = ["Personas", "Conductores", "Vehículos"];

const TitleHeader = [
  { name: "Placa de vehículo", order: null },
  { name: "Nombre", order: null },
  { name: "Número de documento", order: null },
  { name: "Tipo de sanción", order: null },
  { name: "Fecha", order: null },
  { name: "Detalle del bloqueo", order: null },
  { name: "Acciones", order: null }
];

const TitleHeaderPerson = [
  { name: "Nombre", order: null },
  { name: "Número de documento", order: null },
  { name: "Tipo de sanción", order: null },
  { name: "Fecha", order: null },
  { name: "Detalle del bloqueo", order: null },
  { name: "Acciones", order: null }
];
export { TheadAccordion, TitleHeader, TitleHeaderPerson };
