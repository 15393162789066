/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";
import InputSelect from "components/InputSelect/InputSelect";
import environment from "constants/apiConst";
import FormCrudCRUDContext from "context/FormCrudContex";
import { useGlobalContext } from "context/GlobalContext";
import {
  dataEmbarque,
  dataRefund,
} from "pages/Entry_and_exit_forms/CorrectionForm/ArrayCorrect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const FmmOheadercorrected = ({
  data,
  dataCorrected = null,
  handleChange,
}) => {
  const {
    addresDestine,
    dataSelectOperation,
    setselectOperation,
    selectOperation,
    dataThird,
    setselectExport,
    selectExport,
    setselectEmbarcacion,
    selectEmbarcacion,
    refunds,
    setRefunds,
    StateCurrentExport,
    setDocumentOperation,
  } = useContext(FormCrudCRUDContext);
  const {
    code_operation,
    short_name_operation,
    code_operationCorrected,
    short_name_operationCorrected,
    shipment,
    refund,
    destination_address_corrected,
    destination_address,
    balance_correction,
  } = data || {};
  const location = useLocation(); // navigate

  const { FormOutput = null, ShowOutput = null } = queryString.parse(
    location.search
  );


  useEffect(() => {
    if (selectOperation) {
      setDocumentOperation(selectOperation.documents);
    }
  }, [selectOperation]);

  const { requestData } = useGlobalContext();

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdate = (res) => {
    if (res.data?.status?.code === 403) {
      console.log("handleResponseUpdate", res?.data?.status);
    }
  };

  const saveTypeOperation = (id) => {
    const formData = new FormData();
    formData.append("id", FormOutput || ShowOutput);
    formData.append("thirdPartieCorrected_id", id);

    const requestData = {
      data: formData,
      Endpoint: environment.CORRECT_FMMO_UPDATE,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: handleResponseUpdate,
    });
  };

  const setTypeOperation = (e) => {
    saveTypeOperation(e?.value);
    setselectOperation(e);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={
            StateCurrentExport !== selectExport?.label && "BorderCorrect"
          }
          tag={"Importador/Comprador*"}
          data={dataThird && dataThird}
          set={setselectExport}
          value={selectExport}
          isDisabled={balance_correction === 1}
        />
      </div>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={
            data?.balance_correction !== 1 &&
            code_operation !== selectOperation?.value &&
            "BorderCorrect"
          }
          tag={"Tipo de Operación"}
          data={dataSelectOperation || dataCorrected}
          //  set={setselectOperation}
          onChange={setselectOperation}
          value={selectOperation}
        />
      </div>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={shipment !== selectEmbarcacion?.label && "BorderCorrect"}
          isDisabled={balance_correction === 1}
          tag={"Tipo de embarcación"}
          data={dataEmbarque}
          set={setselectEmbarcacion}
          value={selectEmbarcacion}
        />
      </div>
      <div style={{ width: "100%" }}>
        <InputSelect
          className={refund !== refunds?.label && "BorderCorrect"}
          tag={"Modalidad"}
          isDisabled={balance_correction === 1}
          data={dataRefund}
          set={setRefunds}
          value={refunds}
        />
      </div>

      {selectOperation &&
        addresDestine.map((items, index) => {
          if (items === selectOperation.value) {
            return (
              <LabelGroup
                changeValue={
                  destination_address !== destination_address_corrected &&
                  "BorderCorrect"
                }
                name={"destination_address_corrected"}
                value={destination_address_corrected}
                onChange={handleChange}
                tag={"Dirección de destino"}
                size={100}
                desicion={balance_correction === 1}
                key={index}
              />
            );
          }
        })}
    </>
  );
};
