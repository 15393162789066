import { Box } from '@chakra-ui/react'
import React from 'react'
import { CardTotal } from './CardTotal'

const MiniCards = ({
  documentReturned,
  ucWhatMoreWeight,
  incomeBalance,
  exitBalance,
  trackingOnFMMI,
  trackingOnFMMS,
  trackingOffFMMI,
  trackingOffFMMS,
  trackingOnDocFMMI,
  trackingOnDocFMMS

}) => {
  return (
    <>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>

        {ucWhatMoreWeight?.length !== 0
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Usuario que más pesó'
              subtitle={ucWhatMoreWeight ?? 'N/A'}
              identifier={'letras'}
            />
          </Box>
          : ''}

        {(incomeBalance?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Saldo ingreso'
              subtitle={incomeBalance?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(exitBalance?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Saldo salida'
              subtitle={exitBalance?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOnFMMI?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Documentos levantados de FMMI'
              subtitle={trackingOnFMMI?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOnFMMS?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Documentos levantados de FMMS'
              subtitle={trackingOnFMMS?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOffFMMI?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Documentos no levantados de FMMI'
              subtitle={trackingOffFMMI?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOffFMMS?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Documentos no levantados de FMMS'
              subtitle={trackingOffFMMS?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOnDocFMMI?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Seguimiento Doc levantados FMMI'
              subtitle={trackingOnDocFMMI?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}

        {(trackingOnDocFMMS?.value_cif_COP)
          ? <Box style={{ margin: '10px', flex: '1', minWidth: '300px' }}>
            <CardTotal
              size='22'
              title='Seguimiento Doc levantados FMMS'
              subtitle={trackingOnDocFMMS?.value_cif_COP ?? '0'}
              identifier={'numeros'}
            />
          </Box>
          : ''}
      </div>

    </>
  )
}

export default MiniCards
