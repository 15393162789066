import { DATA_FORMS_TYPES } from "redux/types/dataFormTypes";

const initialState = {
  data: {}
};

export const currentFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_FORMS_TYPES.FORM_DATA:
      return {
        data: action.payload
      };
    case DATA_FORMS_TYPES.UPDATE_FORM_DATA:
      console.log({ payload: action.payload });
      return {
        data: {
          ...state.data,
          ...action.payload
        }
      };
    case DATA_FORMS_TYPES.CLEAR_FORMDATA:
      return {
        data: {}
      };
    default:
      return state;
  }
};
