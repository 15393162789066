import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useState, useCallback, useEffect, useRef } from "react";

import { ExportPdfGrafica } from "../General/ExportPdfGrafica";
import { Bar } from "react-chartjs-2";

import environment from "constants/apiConst";

import { ExportPdf } from "components/Export/ExportPdf";

import { estadisticaOptions } from "../General/EstadisticaOptions";

export const EstadisticaMovimiento = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);

  const [filterEst, setfilterEst] = useState(false);
  const ref = useRef(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    currentPage && setUserData({
      labels: currentPage.map(({ entry_pass, dispatch_pass }) => entry_pass || dispatch_pass),
      datasets: [
        {
          label: "cantidad",
          data: currentPage.map(({ amount_entry, amount_dispatch }) => amount_entry || amount_dispatch),
          backgroundColor: ["#2898ee", "#107acc"]
        }
      ]
    })
  }, [currentPage])

  const title = "Estadisticas de movimiento";
  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) ExportPdf("IdPdfFmmCorrectEntry", `${title}_${dateToday}`);
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  return (
    <>
      <div id="contenedorEstadistica">
        <HeaderEstadistica
          title={title}
          enableQualifiedUser
          handlePDF={dowmLoadPdfImgae}
          excelPath={environment.POST_EXPORT_EXCEL_OP_ENTRY_OUT}
          setCurrentPage={setCurrentPage}
          setLoading={setLoading}
          obtenerPath={environment.OBTENER_STATISTICS_MOVIMIENTO}
          enableRendering={true}
        />
        {loading && <SpinnerComponent />}
        {!loading && (
          <>
            <ExportPdfGrafica
              id="IdPdfFmmCorrectEntry"
              rutaImage={rutaImage}
              thead={""}
              data={""}
              tbody={"TbodySecurity"}
              title={`Estadisticas de ${title}`}
              dateToday={dateToday}
            />
            <div className="d-flex">
              <div id="contenedorResultado" className="px-2">
                {!loading && userData && <Bar data={userData} ref={ref} options={estadisticaOptions(
                  "Operación",
                  "Cantidad",
                  "Operaciones"
                )}/>}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
