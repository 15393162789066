import React, { useContext, useEffect, useState } from "react";
import { Box, SimpleGrid, Switch, Text, Divider } from "@chakra-ui/react";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { useForm } from "../../hooks/useForm";
import ConfigurationCRUDContext from "../../context/ConfigurationCRUDContext";
import { ButtonStyled } from "../../styled/Buttons.styled";
import InputSelect from "components/InputSelect/InputSelect";
import { MaxLength } from "utils/MaxLength";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const EditFormPaises = () => {
  const [values, handleInputChange, reset, setValues] = useForm({
    code_country: "",
    name_country: "",
    market: "",
    tax_haven: "",
    status_country: 1,
    origin_country: "",
    destiny_country: ""
  });
  const {
    code_country,
    name_country,
    market,
    status_country,
    origin_country,
    destiny_country
  } = values;

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [check, setCheck] = useState(true);
  const [isTaxHaven, setIsTaxHaven] = useState("");
  const [openAlert] = AlertErrorAlert();
  const taxHeavenSelect = [
    { value: "S", label: "Sí" },
    { value: "N", label: "No" }
  ];

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
      setCheck(dataToEdit.status_country === 1);
      const selTaxHeaven = taxHeavenSelect.filter((item) =>
        item.value === dataToEdit.tax_haven ? item : null
      );
      setIsTaxHaven(selTaxHeaven[0]);
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleAceptar = () => {
    if (dataToEdit) {
      const data = {
        ...values,
        id: dataToEdit.id,
        status_country: status_country ? 1 : 0,
        tax_haven: isTaxHaven?.value
      };

      editData(data);
    } else {
      const data = {
        ...values,
        status_country: status_country ? 1 : 0,
        tax_haven: isTaxHaven?.value
      };

      createData(data);
    }
  };

  const changeCheck = (e) => {
    handleInputChange(e);
    setCheck(e.target.checked);
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? " Editar País" : "Crear País"}
      </Text>
      <Divider mb={1} />

      <form onSubmit={hanldeSubmit}>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Código</LabeFormlStyled>
            <InputFormStyled
              name="code_country"
              value={code_country}
              onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={MaxLength.config.countries.code_country}
            />
          </div>
        </SimpleGrid>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Nombre del país</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_country"
              value={name_country}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.countries.name_country}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Mercado</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="market"
              value={market}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.countries.market}
            />
          </div>
          <div>
            <InputSelect
              name={"tax_haven"}
              tag={"Paraíso Fiscal"}
              data={taxHeavenSelect}
              value={isTaxHaven}
              set={setIsTaxHaven}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Como destino</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="destiny_country"
              value={destiny_country}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.countries.destiny_country}
            />
          </div>

          <div>
            <LabeFormlStyled htmlFor="code_packaging">
              Como procedencia
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="origin_country"
              value={origin_country}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={MaxLength.config.countries.origin_country}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled htmlFor="status_country">Activado</LabeFormlStyled>
            <div>
              <Switch
                name="status_country"
                id="status_country"
                size="lg"
                value={status_country}
                onChange={changeCheck}
                isChecked={check}
              />
            </div>
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleAceptar}>
            Aceptar
          </ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default EditFormPaises;
