import { useCallback, useState } from "react";

export const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);

  const openModal = useCallback(() => {
    setIsOpen(true);
    // Retorna una nueva promesa que se resolverá cuando se cierre el modal
    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  }, []);

  const closeModal = useCallback((data) => {
    setIsOpen(false);
    // Resuelve la promesa con los datos (si se proporcionan)
    if (resolvePromise) {
      resolvePromise(data);
    }
    setResolvePromise(null);
  }, [resolvePromise]);

  return { isOpen, openModal, closeModal };
};
