import React, { useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { Title } from "styled/Title";
import { renderInputWithLabel } from "../../ViewItems/ViewItemsEntryForm";

const FormViewRestTerritory = ({
  data,
  code,
  notations = null,
  activeReview = true,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const [Status, setStatus] = useState([]);

  useEffect(() => {
    setStatus(
      data?.status_entryForm ? data?.status_entryForm : data?.status_outputForm
    );
  }, [data]);

  const shouldShowInputs =
    code === 309 ||
    code === 310 ||
    code === 314 ||
    code === 316 ||
    code === 318 ||
    data?.reentry_operation;

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data,
  };
  return (
    <div className="mx-1 shadow-sm p-1 mb-5" style={{ background: "#f2f2f2" }}>
      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del territorio nacional
      </Title>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Nº DEX */}
        {renderInputWithLabel({
          labelText: "Nº DEX",
          name: "num_dex_entryForm",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false,
        })}

        {/* Fecha del DEX */}
        {renderInputWithLabel({
          labelText: "Fecha del DEX",
          name: "dex_date_entryForm",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}
      </SimpleGrid>

      {/* Nº Factura Comercial */}
      {renderInputWithLabel({
        labelText: "Factura Comercial",
        name: "num_commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
        },
        wrapInDiv: false,
        disableFormatting: true,
      })}

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {/* Departamento */}
        {renderInputWithLabel({
          labelText: "Departamento",
          name: "name_department",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          manualValue: `${data?.code_department ?? "N/A"} ${
            data?.name_department ?? ""
          }`,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}

        {/* Nº Declaración de Importación */}
        {renderInputWithLabel({
          labelText: "Nº Declaración de Importación",
          name: "num_import_declaration",
          activeComment: isCheckFormExit || isCheckFormEntry,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
            thousandSeparator: false,
          },
          wrapInDiv: false,
        })}

        {/* Administración de Aduanas */}
        {renderInputWithLabel({
          labelText: "Administración de Aduanas",
          name: "name_custom",
          activeComment: isCheckFormExit || isCheckFormEntry,
          manualValue: `${data.code_custom ?? "N/A"} ${data.name_custom ?? ""}`,
          annotationInfo,
          additionalProps: {
            background: "#F9F9F9",
          },
          wrapInDiv: false,
        })}
      </SimpleGrid>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        {shouldShowInputs ? (
          <>
            {/* Nº Autorización de Salida */}
            {renderInputWithLabel({
              labelText: "Nº Autorización de Salida",
              name: "num_exit_form",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9",
                thousandSeparator: false,
              },
              wrapInDiv: false,
              disableFormatting: true,
            })}

            {/* Nº Autorización de C. Inte */}
            {renderInputWithLabel({
              labelText: "Nº Autorización de C. Inte",
              name: "integration_box_certificate",
              activeComment: isCheckFormExit || isCheckFormEntry,
              annotationInfo,
              additionalProps: {
                background: "#F9F9F9",
                thousandSeparator: false,
              },
              wrapInDiv: false,
              disableFormatting: true,
            })}
          </>
        ) : null}
      </SimpleGrid>
    </div>
  );
};

export default FormViewRestTerritory;
