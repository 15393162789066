import { TRM_TYPES } from "redux/types/stateTrm";

const setDataStatusTrm = (payload) => {
  return {
    type: TRM_TYPES.STATETRM,
    payload
  };
};

export { setDataStatusTrm };
