/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import {
  dateBy,
  statePassOrDispacth,
  OperationType,
  ordenarPorIems,
  ordenarPorIemsOutput,
  OperationTypeOutput,
  OperationTypeInput
} from "../../../../Pass-Form/View/BusquedaAvanzada/ArraySelect";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useCallback } from "react";
import { __ObtenerSubHeadingCode } from "request/CustomsClearance.js/__CustomsClearence";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import FormCrudCRUDContext from "context/FormCrudContex";
import GlobalContext from "context/GlobalContext";
import { handleSanitizedObj, isArray } from "utils/type-check-utils";
import { MaxLength } from "utils/MaxLength";
import { useSearchUrl } from "hooks/useSearchUrl";

const FormItems = ({
  dataCountry,
  onClose,
  /* Estos son de FMMS: */
}) => {
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [operationT, setOperationT] = useState(currentSearch?.operationT);
  const [sortBy, setSortBy] = useState(currentSearch?.sortBy);
  const [selectAD, setSelectAD] = useState(currentSearch?.orderby_type === 'ASC' ? 'Ascendente' : 'Descendente');
  const [selectUF, setSelectUF] = useState(currentSearch?.shipment);
  const [refund, setRefund] = useState(currentSearch?.refund);


  const [values, handleInputChange, reset] = useForm({
    code_form: currentSearch?.code_form,
    date_initial: currentSearch?.date_initial,
    date_final: currentSearch?.date_final,
    initial_time: currentSearch?.initial_time,
    final_time: currentSearch?.final_time,
    code_operation: currentSearch?.code_operation,
    num_commercial_invoice: currentSearch?.num_commercial_invoice,
    integration_certificate: currentSearch?.integration_certificate,
    cargo_manifest: currentSearch?.cargo_manifest,
    third_party_identification_num: currentSearch?.third_party_identification_num,
    num_transport_document: currentSearch?.num_transport_document,
    dcl_customs_transit: currentSearch?.dcl_customs_transit,
    description_product: currentSearch?.description_product,
    reference: currentSearch?.reference,
    num_import_declaration: currentSearch?.num_import_declaration,
    code_product: currentSearch?.code_product,
    Subpartida: currentSearch?.Subpartida,
  });
  const {
    code_form,
    date_initial,
    date_final,
    initial_time,
    final_time,
    code_operation,
    num_commercial_invoice,
    integration_certificate,
    cargo_manifest,
    third_party_identification_num,
    num_transport_document,
    dcl_customs_transit,
    description_product,
    reference,
    num_import_declaration,
    code_product,
    Subpartida,
  } = values;

  const options = ["Único", "Fracionado"];
  const optiones = ["Ascendente", "Descendente"];
  const [datosBy, setDatosBy] = useState({ value: 0, label: "Creación" });
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(currentSearch?.stateDispacthOrPass);
  const [countryBuy, setCountryBuy] = useState(currentSearch?.countryBuy);
  const [countryProcedency, setCountryProcedency] = useState(currentSearch?.countryProcedency);
  const [flag, setFlag] = useState(currentSearch?.flag);
  const [countryOrigin, setCountryOrigin] = useState(currentSearch?.countryOrigin);
  const [idSubpartida, setidSubpartida] = useState(null);
  const [balance, setBalance] = useState(currentSearch?.with_balance);
  const [reposition, setReposition] = useState("");

  const optionsBalance = ["Si", "No"];
  const optionsReposition = ["Reposición", "No reposición"];
  const optionsRefund = ["Con reintegro", "Sin reintegro"];

  const [openAlert] = AlertErrorAlert();
  const { setChangeExportExcel } = useContext(FormCrudCRUDContext)

  const handleBusqueda = () => {
    const handleDateOf = {
      "0": "BORRADOR",
      "1": "PRESENTADO",
      "2": "REVISADO",
      "3": "AUTORIZADO",
    }[datosBy?.value] || null;

    const data = {
      ...values,
      subheading_id: idSubpartida,
      origin_id: countryOrigin?.value,
      country_flag_id: flag?.value,
      country_purchase_id: countryBuy?.value,
      country_destiny_id: countryProcedency?.value,
      refund: refund,
      shipment: selectUF,
      with_balance: balance,
      order_by: sortBy?.value,
      orderby_type: selectAD === "Ascendente" ? "ASC" : "DESC",
      order_by_type: sortBy?.value,
      type_operation: operationT?.value,
      date_of: handleDateOf,
      status: isArray(stateDispacthOrPass) ? stateDispacthOrPass.map(({ value }) => value) : null,
    }

    setParamsSearch({
      params: handleSanitizedObj({
        ...data,
        idSubpartida,
        countryOrigin,
        flag,
        countryBuy,
        countryProcedency,
        refund,
        selectUF,
        balance,
        sortBy,
        operationT,
        datosBy,
        stateDispacthOrPass,
        typeSearch: "item",
      })
    });
    onClose();
  };

  const obtenerSubpartida = useCallback(
    async (info) => {
      console.log(info);
      try {
        const res = await __ObtenerSubHeadingCode(token, info);
        const resultado = res?.data?.status;
        if (resultado.code === 403) {
          openAlert(resultado.message, "error");
          setidSubpartida(null);
        } else {
          setidSubpartida(resultado.data.id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [openAlert, token]
  );

  const onBlurSubHeading = (e) => {
    const valor = e.target.value;
    const formData = new FormData();
    formData.append("code_subheading", valor);
    if (valor) obtenerSubpartida(formData);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code_form"}
          value={code_form}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"code_operation"}
          value={code_operation}
          onChange={handleInputChange}
          tag={"Cod. Operacion"}
          size={45}
          tipo={"text"}
          maxLength={"3"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          value={date_initial}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"initial_time"}
          value={initial_time}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"final_time"}
          value={final_time}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>
      <div className="row py-3">
        {movement !== "Ingreso" && (
          <div className="col-sm">
            <>
              <RadioBtn
                options={options}
                setOnly={setSelectUF}
                state={selectUF}
              />
              <br />
              <RadioBtn
                setOnly={setRefund}
                options={optionsRefund}
                state={refund}
              />
              <br />
              Incluir sin saldo
              <RadioBtn
                setOnly={setBalance}
                options={optionsBalance}
                state={balance}
              />
            </>
          </div>
        )}
        <div className={`col-sm ms-5`}>
          <RadioBtn options={optiones} setOnly={setSelectAD} state={selectAD} />
          {movement !== "Ingreso" && (
            <>
              <br />
              <RadioBtn
                setOnly={setReposition}
                options={optionsReposition}
                state={reposition}
              />
            </>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Fecha de:"}
            data={dateBy}
            value={datosBy}
            set={setDatosBy}
          />
        </div>
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            name={"state_dispacth"}
            tag={"Estado"}
            data={statePassOrDispacth}
            value={stateDispacthOrPass}
            set={setStateDispacthOrPass}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Tipo Operación"}
            data={movement === 'Salida' ? OperationTypeOutput : OperationTypeInput}
            value={operationT}
            set={setOperationT}
          />
        </div>
        <LabelGroup
          name={"third_party_identification_num"}
          value={third_party_identification_num}
          onChange={handleInputChange}
          tag={`Nit Proveedor/Agente `}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"num_commercial_invoice"}
          value={num_commercial_invoice}
          onChange={handleInputChange}
          tag={"Nº Factura Comercial"}
          size={45}
          tipo={"text"}
        />

        {movement !== "Ingreso"
          ? (
            <LabelGroup
              name={"integration_certificate"}
              value={integration_certificate}
              onChange={handleInputChange}
              tag={"Cert. de Integración"}
              size={45}
              tipo={"text"}
              maxiLength={"10"}
            />
          )
          : (
            <LabelGroup
              name={"num_transport_document"}
              value={num_transport_document}
              onChange={handleInputChange}
              tag={"Doc. de Transporte"}
              size={45}
              tipo={"text"}
              maxiLength={"10"}
            />
          )}
      </div>
      {movement === "Ingreso" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            name={"dcl_customs_transit"}
            value={dcl_customs_transit}
            onChange={handleInputChange}
            tag={"DTA"}
            size={45}
            tipo={"text"}
            maxiLength={"10"}
          />

          <LabelGroup
            name={"cargo_manifest"}
            value={cargo_manifest}
            onChange={handleInputChange}
            tag={"Manifiesto de Carga"}
            size={45}
            tipo={"text"}
            maxiLength={"10"}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code_product"}
          value={code_product}
          onChange={handleInputChange}
          tag={"Cod. Producto"}
          size={45}
          tipo={"text"}
        />

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Ordenar por:"}
            data={
              movement !== "Ingreso" ? ordenarPorIemsOutput : ordenarPorIems
            }
            value={sortBy}
            set={setSortBy}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"description_product"}
          value={description_product}
          onChange={handleInputChange}
          tag={"Descripción"}
          size={45}
          tipo={"text"}
          maxiLength={"10"}
        />

        <LabelGroup
          name={"Subpartida"}
          value={Subpartida}
          onChange={handleInputChange}
          onBlur={onBlurSubHeading}
          tag={"Subpartida"}
          size={45}
          desicion={false}
          tipo={"search"}
          maxLength={MaxLength?.global?.code_subheading}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"reference"}
          value={reference}
          onChange={handleInputChange}
          tag={"Referencia"}
          size={45}
          tipo={"text"}
          maxiLength={"10"}
        />

        <LabelGroup
          name={"num_import_declaration"}
          value={num_import_declaration}
          onChange={handleInputChange}
          tag={"Dcl. de Importación"}
          size={45}
          tipo={"text"}
          maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"País compra"}
            data={dataCountry}
            value={countryBuy}
            set={setCountryBuy}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={`País destino`}
            data={dataCountry}
            value={countryProcedency}
            set={setCountryProcedency}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Bandera"}
            data={dataCountry}
            value={flag}
            set={setFlag}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"País origen"}
            data={dataCountry}
            value={countryOrigin}
            set={setCountryOrigin}
          />
        </div>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormItems;
