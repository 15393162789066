import { Box, SimpleGrid } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import InputSelect from "components/InputSelect/InputSelect";
import Pagination from "components/Pagination/Pagination";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TableOrderHover from "components/Tables/TableOrderHover";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import IntegracionBoxCrudContex from "context/IntegracionBoxCrudContex";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useEffect, useRef, useState, useCallback, useContext } from "react";

import { useSelector } from "react-redux";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __PostFormData, __PostJsonData } from "request/Petitions/__Post";
import { ButtonStyled } from "styled/Buttons.styled";
import { TitleTabletReference } from "../Arrays/ArrayTitles";
import TbodyReference from "../Table/TbodyReference";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { MaxLength } from "utils/MaxLength";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryEnv from "constants/queryConst";

const FormSearchsupplies = () => {
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);


  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [openAlert] = AlertErrorAlert();
  const [dataTablet, setdataTablet] = useState([]);
  const { userQualified: selectedUC, onCloseModal, onCloseSearchSupplies } = useContext(
    IntegracionBoxCrudContex
  );

  const [userQualified, setUserQualified] = useState(selectedUC ? selectedUC : null);

  const { code_destination } = useContext(GlobalContext);
  const [values, handleInputChange, reset, setValues] = useForm({
    reference: "",
    product_code: "",
    description: "",
    certificate: "",
    import_declaration: "",
    operation: "",
    subheading: ""
  });

  const {
    reference,
    product_code,
    description,
    certificate,
    import_declaration,
    operation,
    subheading
  } = values;

  const { requestData } = useGlobalContext();

  const { data: dataUserQualified, isLoading: isLoadingDataUserQualified } = useQuery({
    queryKey: [queryEnv?.QUALIFIED_USERS],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
      PropertyBody: "get",
    })
  })


  const { mutate } = useMutation(
    {
      mutationKey: ["references", userQualified?.value, code_destination, values, page],
      mutationFn: requestData,
      onSuccess: (data) => {
        if (data?.data?.status?.code === 400) {
          return openAlert(data?.data?.status?.message, 'error')
        }
        setShowTable(true);
        setdataTablet(data?.data?.status?.data?.data);
        setMaxPage(data?.data?.status?.data?.last_page);
      },
    },

  );

  const handleSearch = async (e) => {
    if (!code_destination) {
      openAlert("Debe seleccionar un tipo de cuadro.", "error");
      return;
    }
    const data = {
      ...(userQualified?.value && { qualifiedUser_id: userQualified?.value }),
      code_destination,
      ...values,
    };

    mutate({
      Endpoint: environment.GET_REFERENCES,
      PropertyBody: "POST",
      data,
      pageParam: page,
    })
  };



  return (
    <>
      {loading && <SpinnerComponent />}

      {showTable
        ? (
          <>
            <ButtonStyled onClick={() => setShowTable(false)}>
              Buscar nuevamente
            </ButtonStyled>
            <TableOrderHover
              thead={TitleTabletReference}
              data={dataTablet}
              setData={setdataTablet}
              loading={loading}
              onCloseModal={onCloseModal}
              tbodyData={TbodyReference}
            />

            <Pagination
              page={page}
              setPage={(page) => {
                setPage(page);
                mutate({
                  Endpoint: environment.GET_REFERENCES, PropertyBody: "POST",
                  pageParam: page,
                  data: {
                    ...(userQualified?.value && { qualifiedUser_id: userQualified?.value }),
                    code_destination,
                    ...values,
                  },
                })
              }}
              maxPage={maxPage}
              loading={loading}
            />
          </>
        )
        : (
          <>
            <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
              {authUser.is_qualified_user !== 1 && (
                <div>
                  <InputSelect
                    tag={"Empresa"}
                    size={100}
                    data={dataUserQualified && dataUserQualified?.data?.status?.data.map((items) => ({
                      value: items?.id,
                      label: items?.nit_qualified + " - " + items?.name_qualified,
                      decimal: items.decimal_values_precision
                    }))}
                    set={setUserQualified}
                    value={userQualified}
                    isLoading={isLoadingDataUserQualified}
                    isDisabled={true}
                  />
                </div>
              )}
              <div>
                <LabelGroup
                  name={"reference"}
                  value={reference}
                  onChange={handleInputChange}
                  tag={"Referencia"}
                  size={100}
                  desicion={false}
                />
              </div>

              <div>
                <LabelGroup
                  name={"description"}
                  value={description}
                  onChange={handleInputChange}
                  tag={"Descripción"}
                  size={100}
                  desicion={false}
                />
              </div>
              {authUser.is_qualified_user === 1 && (
                <div>
                  <LabelGroup
                    name={"product_code"}
                    value={product_code}
                    onChange={handleInputChange}
                    tag={"Código producto"}
                    size={100}
                    desicion={false}
                  />
                </div>
              )}
            </SimpleGrid>

            <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3">
              <div>
                <LabelGroup
                  name={"certificate"}
                  value={certificate}
                  onChange={handleInputChange}
                  tag={"Certificado"}
                  size={100}
                  desicion={false}
                />
              </div>

              <div>
                <LabelGroup
                  name={"import_declaration"}
                  value={import_declaration}
                  onChange={handleInputChange}
                  tag={"Dcl. Importación"}
                  size={100}
                  desicion={false}
                />
              </div>

              <div>
                <LabelGroup
                  name={"operation"}
                  value={operation}
                  onChange={handleInputChange}
                  tag={"Operacion"}
                  size={100}
                  desicion={false}
                />
              </div>

              {authUser.is_qualified_user !== 1 && (
                <div>
                  <LabelGroup
                    name={"product_code"}
                    value={product_code}
                    onChange={handleInputChange}
                    tag={"Código producto"}
                    size={100}
                    desicion={false}
                  />
                </div>
              )}
            </SimpleGrid>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>
                <LabelGroup
                  name={"subheading"}
                  value={subheading}
                  onChange={handleInputChange}
                  tag={"Subpartida"}
                  size={125}
                  desicion={false}
                  maxLength={MaxLength?.global?.code_subheading}
                />
              </div>
            </div>

            <div className="mb-3 ms-5">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexWrap={"wrap"}
              >
                <ButtonStyled bgColor={"#bababa"} onClick={onCloseSearchSupplies}>
                  Cerrar
                </ButtonStyled>
                <ButtonStyled onClick={handleSearch}>Buscar</ButtonStyled>
              </Box>
            </div>
          </>
        )}
    </>
  );
};

export default FormSearchsupplies;
