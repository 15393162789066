import React from "react";
// eslint-disable-next-line no-unused-vars
import { Logo, LogoWhite } from "styled/Logo.styled";
import { HeaderSearch } from "styled/SectionContainer.styled";

import "../ProductionOrder/Style/Style.css";
import { Cabecera } from "./ComponentExportPdf/Cabecera";

export const ExportPdfPesaje = ({ values }) => {
  return (
    <div id="IdPdfPesaje">
      <div className="d-flex mt-2">
        <div id="banner" />
        <Logo width={"180px"} className="mx-2" />
      </div>

      <div className="p-3">
        <Cabecera values={values} />
      </div>

      <HeaderSearch>
        <div className="row ">
          {values?.gross_weight && (
            <div className="col">
              <div className="card">
                <div
                  id="headeCardPeso"
                  className="card-header text-white text-center"
                >
                  Información de peso bruto
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">
                        Peso bruto:
                        <br />
                        {values?.container_1 && (
                          <>
                            Serie:
                            <br />
                            Tara: {values?.tare_container_1} Kg
                            <br />
                          </>
                        )}
                        {values?.container_2 && (
                          <>
                            Serie:
                            <br />
                            Tara: {values?.tare_container_2} Kg
                            <br />
                          </>
                        )}
                        Operario: {values?.gross_user_name}
                        <br />
                        báscula: {values?.gross_scale_name}
                      </h5>
                    </div>
                    <div className="col">
                      <h5 className="card-title">
                        {values?.gross_weight} Kg
                        <br />
                        {values?.container_1 && (
                          <>
                            {values?.container_1}
                            <br />
                            De: {values?.capacity_container_1}
                            <br />
                          </>
                        )}
                        {values?.container_2 && (
                          <>
                            {values?.container_2}
                            <br />
                            De: {values?.capacity_container_2}
                            <br />
                          </>
                        )}
                        Fecha: {values?.gross_date_weight}
                        <br />
                        Hora: {values?.gross_time_weight}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {values?.tare_weight && (
            <div className="col">
              <div className="card">
                <div
                  id="headeCardPeso"
                  className="card-header text-white text-center"
                >
                  Información de peso tara
                </div>
                <div className="card-body">
                  {" "}
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">
                        Peso bruto:
                        <br />
                        {values?.container_1 && (
                          <>
                            Serie:
                            <br />
                            Tara: {values?.tare_container_1} Kg
                            <br />
                          </>
                        )}
                        {values?.container_2 && (
                          <>
                            Serie:
                            <br />
                            Tara: {values?.tare_container_2} Kg
                            <br />
                          </>
                        )}
                        Operario: {values?.tare_user_name}
                        <br />
                        báscula: {values?.tare_scale_name}
                      </h5>
                    </div>
                    <div className="col">
                      <h5 className="card-title">
                        {values?.tare_weight} Kg
                        <br />
                        {values?.container_1 && (
                          <>
                            {values?.container_1}
                            <br />
                            De: {values?.capacity_container_1}
                            <br />
                          </>
                        )}
                        {values?.container_2 && (
                          <>
                            {values?.container_2}
                            <br />
                            De: {values?.capacity_container_2}
                            <br />
                          </>
                        )}
                        Fecha: {values?.tare_date_weight}
                        <br />
                        Hora: {values?.tare_time_weight}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </HeaderSearch>
    </div>
  );
};
