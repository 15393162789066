import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __GeTQualifiedUSers = async (token, page, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };
  const res = await axios(
    `${env.GET_QUALIFIED_USERS}?page=${page || 1}`,
    options
  );
  return res;
};

export const __AllQualifiedUSers = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_QUALIFIED_USERS_ALL}`, options);
  return res;
};

export const __AllQualifiedUSersPOST = async (token) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.GET_QUALIFIED_USERS_ALL}`, options);
  return res;
};

export const __GetAll = async (service, token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${service}`, options);
  return res;
};

export const __ShowQualifiedUser = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.QUALIFIED_USERS_SHOW}/${id}`, options);
  return res;
};

/*
 * Actualiza los datos del embalaje seleccionado
 */
export const __UpdateQualifiedUSers = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${env.PACKAGING_UPDATE}`, options);
  return res;
};

// ACTIVAR Y DESACTIVAR COMO SUPERADMIN
export const __ACT_USER_QUALIFIED = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.QUALIFIED_USERS_STATUS_ACTIVATE_SUP}/${id}`,
    options
  );
  return res;
};

export const __DESACT_USER_QUALIFIED = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.QUALIFIED_USERS_STATUS_DEACTIVACTE_SUP}/${id}`,
    options
  );
  return res;
};

// ACTIVAR Y DESACTIVAR COMO COMERCIO EXTERIOR
export const __ACTI_USER_QUALIFIED = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.QUALIFIED_USERS_STATUS_ACTIVATE}/${id}`,
    options
  );
  return res;
};

export const __DESACTI_USER_QUALIFIED = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${env.QUALIFIED_USERS_STATUS_DEACTIVACTE}/${id}`,
    options
  );
  return res;
};
