// import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebouce";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";

const InputSearch = styled.input`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  border: none;
  outline: none;
  border-bottom: 1px solid #bababa;
  font-size: 1rem;
  display: inline-block;
`;

const FormSearch = styled.form`
  box-sizing: border-box;
  display: inline;
  margin: 0;
  padding: 0 0.3rem;
`;

const SearchForm = ({ handleButtonChange }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);

  const handleSubmitSearch = ({ name, value }) => {
    const { [name]: _, ...rest } = currentParams;
    const updatedParams = { ...rest, ...(value && { [name]: value }) };
    const updatedSearchParams = new URLSearchParams(updatedParams);
    setSearchParams(updatedSearchParams);
  }

  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const [searchState, setSearchState] = useState(q);

  return (
    <FormSearch onSubmit={(e) => {
      e.preventDefault();
      handleSubmitSearch({ name: "q", value: searchState });
    }}>
      <InputSearch
        placeholder="Buscar"
        name="search"
        value={searchState}
        onChange={(e) => {
          const value = e?.target?.value;
          setSearchState(value)
        }}
      />
    </FormSearch>
  );
};

export default SearchForm;
