import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";
import FormCrudCRUDContext from "context/FormCrudContex";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { safelyConvertToString } from "utils/type-check-utils";

export const FormCorrectFreeZone = ({ data, dataCorrect }) => {
  const {
    num_dex,
    num_exit_form,
    integration_box_certificate,
    dcl_customs_transit,
    transit_expiration_date,
    num_commercial_invoice,
    national_or_foreign,
    balance_correction,
    cabotage,
    cabotage_expiration_date
  } = data;

  const {
    num_dex_entryFormCorrected,
    num_commercial_invoice_corrected,
    num_exit_form_corrected,
    integration_box_certificate_corrected,
    dcl_customs_transit_corrected,
    transit_expiration_date_corrected,
    cabotage_corrected,
    cabotage_expiration_date_corrected,
    shipping_form_corrected,
    shipping_form_expiration_date,
    shipping_form,
    shipping_form_expiration_date_corrected
  } = dataCorrect;

  const {
    selectNE,
    setSelectNE,
    dataNational,
    typeEntryCurrent,
    setTypeEntryCurrent,
    dataType,
    typeEntry
  } = useContext(FormCrudCRUDContext);

  const handleColorDtaShippingCabotage = () => {
    if (
      (typeEntry === "DTA" && safelyConvertToString(dcl_customs_transit) !== safelyConvertToString(dcl_customs_transit_corrected)) ||
      (typeEntry === "Planilla de envío" && safelyConvertToString(shipping_form) !== safelyConvertToString(shipping_form_corrected)) ||
      (typeEntry === "Cabotaje" && safelyConvertToString(cabotage) !== safelyConvertToString(cabotage_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaDtaShippingCabotage = {
    DTA: {
      name: "dcl_customs_transit",
      value: dcl_customs_transit || "",
      tag: "Dcl. Tránsito aduanero"
    },
    "Planilla de envío": {
      name: "shipping_form",
      value: shipping_form || "",
      tag: "Planilla de envío"
    },
    Cabotaje: {
      name: "cabotage",
      value: cabotage || "",
      tag: "Cabotaje"
    }
  }[typeEntryCurrent || ""]

  const handleColorExpirationDate = () => {
    /* if (balance_correction === 1) return "" */
    if (
      (typeEntryCurrent === "DTA" && safelyConvertToString(transit_expiration_date) !== safelyConvertToString(transit_expiration_date_corrected)) ||
      (typeEntryCurrent === "Cabotaje" && safelyConvertToString(cabotage_expiration_date) !== safelyConvertToString(cabotage_expiration_date_corrected)) ||
      (typeEntryCurrent === "Planilla de envío" && safelyConvertToString(shipping_form_expiration_date) !== safelyConvertToString(shipping_form_expiration_date_corrected))
    ) return "BorderCorrect"
    return ""
  }

  const metaExpirationDate = {
    DTA: {
      name: "transit_expiration_date",
      value: transit_expiration_date || "",
      tag: `Fecha de vencimiento ${typeEntryCurrent}`
    },
    "Planilla de envío": {
      name: "shipping_form_expiration_date",
      value: shipping_form_expiration_date || "",
      tag: `Fecha de vencimiento ${typeEntryCurrent}`
    },
    Cabotaje: {
      name: "cabotage_expiration_date",
      value: cabotage_expiration_date || "",
      tag: `Fecha de vencimiento ${typeEntryCurrent}`
    }
  }[typeEntryCurrent || ""]

  return (
    <>
      <RadioCheck
        set={setTypeEntryCurrent}
        value={typeEntryCurrent}
        item={dataType}
        diff={typeEntryCurrent !== typeEntry}
        disables={true}
      />
      <LabelGroup
        name={"searchInformacion"}
        value={num_commercial_invoice}
        tag={"Nº Factura comercial"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_commercial_invoice) !== safelyConvertToString(num_commercial_invoice_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={num_exit_form}
        tag={"Aut. de salida"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(num_exit_form) !== safelyConvertToString(num_exit_form_corrected) &&
          "BorderCorrect"
        }
      />
      <LabelGroup
        name={"searchInformacion"}
        value={integration_box_certificate}
        tag={"C. de integración"}
        size={100}
        desicion={true}
        changeValue={
          safelyConvertToString(integration_box_certificate) !== safelyConvertToString(integration_box_certificate_corrected) &&
          "BorderCorrect"
        }
      />

      <>
        <LabelGroup
          name={
            metaDtaShippingCabotage?.name
          }
          value={
            metaDtaShippingCabotage?.value
          }
          tag={
            metaDtaShippingCabotage?.tag
          }
          size={100}
          desicion={true}
          changeValue={
            handleColorDtaShippingCabotage()
          }
        />

        <LabelGroup
          name={
            metaExpirationDate?.name
          }
          value={
            metaExpirationDate?.value
          }
          tag={
            metaExpirationDate?.tag
          }
          size={100}
          desicion={true}
          tipo={"date"}
          changeValue={
            handleColorExpirationDate()
          }
        />
      </>

      <LabelGroup
        name={"searchInformacion"}
        value={num_dex}
        tag={"Nº DEX"}
        changeValue={
          safelyConvertToString(num_dex) !== safelyConvertToString(num_dex_entryFormCorrected) &&
          "BorderCorrect"
        }
        size={100}
        desicion={true}
      />
      {/* <div className="mt-1">
        <RadioCheck
          set={setSelectNE}
          value={national_or_foreign}
          item={dataNational}
          disables="true"
          diff={national_or_foreign !== selectNE}
        />
      </div> */}
    </>
  );
};
