import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { renderInputWithLabel } from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsEntryForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";

export const UserToUserDisabled = ({
  form,
  notations = null,
  activeReview = true
}) => {
  const location = useLocation();
  const { pathname } = location;

  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data: form
  };

  return (
    <>
      {/* Nit */}
      {renderInputWithLabel({
        labelText: "Nit",
        name: "nit_qualified2",
        manualValue: `${form?.nit_qualified2 ?? ""} - ${
          form?.name_qualified2 ?? "N/A"
        }`,
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
          className: "col-md-12"
        }
      })}

      {/* Factura Comercial */}
      {renderInputWithLabel({
        labelText: "Factura Comercial",
        name: "commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
          className: "col-md-12"
        },
        disableFormatting: true,
      })}

      {/* ---------------- */}
      {/*
      <div className="col-md-4">
        <LabelGroup
          name={"nit_qualified2"}
          value={form?.nit_qualified2}
          // onChange={handleInputChange}
          tag={"Nit"}
          size={100}
          desicion={true}
        />
      </div>

      <div className="col-md-4">
        <LabelGroup
          name={"commercial_invoice"}
          value={form?.commercial_invoice}
          // onChange={handleInputChange}
          tag={"Factura Comercial"}
          size={100}
          desicion={true}
        />
      </div>
      */}

      {form.associated_entry_form_code &&
        form.code_operation >= 801 &&
        form.code_operation <= 899 && (
        <div className="col-md-12">
          <FormInputGroup>
            <LabeFormlStyled>Formulario de ingreso asociado</LabeFormlStyled>
            <InputFake>
              <NavLink
                to={`/FormShow?FormEntry=${form.associated_entry_form_id}`}
              >
                {form.associated_entry_form_code
                  ? form.associated_entry_form_code
                  : "N/A"}
              </NavLink>
            </InputFake>
          </FormInputGroup>
        </div>
      )}
      {/* <div className="col-md-4">
        <LabelGroup
          name={"commercial_invoice"}
          value={form?.commercial_invoice}
          // onChange={handleInputChange}
          tag={"Factura Comercial"}
          size={100}
          desicion={true}
        />
      </div> */}
    </>
  );
};
