import React from "react";
import { IconGeneric } from "styled/Icons.styled";
import { RiSecurePaymentFill } from "react-icons/ri";
import "./Style.css";
import { SubTitle, Title } from "styled/Title";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { BiUnlink } from "react-icons/bi";
import { __GetDataId } from "request/Petitions/__Get";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import Swal from "sweetalert2";

export const AlertSecurityAssociate = ({
  title,
  item,
  nickname,
  documento,
  idDoc,
  initialpos,
  numberDoc
}) => {
  const {
    code_security,
    name_person,
    lastname_person,
    date_security,
    time_security
  } = item;
  const [openAlert] = AlertErrorAlert();
  // const { authUser } = useContext(AuthContext);
  const {
    is_qualified_user = 0,
    user_rol: { modules },
    token
  } = useSelector((state) => state.auth.dataUser);

  const propsSwalSecurity = {
    reverseButtons: true,
    title: `¿Está seguro que desea desasociar el registro de seguridad del documento ${numberDoc}?`,
    // text: "Se registrarán todos los pases",
    icon: "warning",
    showCancelButton: true,
    cancelButtonColor: "#ba1f33",
    confirmButtonColor: "#1F7AC3",
    cancelButtonText: "Cancelar",
    confirmButtonText: "Aceptar"
  };

  const handleDelete = () => {
    Swal.fire(propsSwalSecurity).then((result) => {
      if (result.isConfirmed) {
        desLink();
      }
    });
  };

  const desLink = async () => {
    try {
      const res = await __GetDataId(
        documento === "Dispatch"
          ? environment.DESASOCIETE_SECURITY_OUTPUT
          : environment.DESASOCIETE_SECURITY_ENTRY,
        token,
        idDoc
      );
      const destrucData = res?.data?.status;
      const { code, message } = destrucData;

      if (code === 200) {
        initialpos();
      } else {
        openAlert(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // security/securityEntry/disassociateEntryPass/1
  //
  const isAuthorizedToDisassociate = modules?.some((item) =>
    item.permission.some(
      (name) =>
        name.name_permission === "DisassociateDispatchPass" ||
        name.name_permission === "DisassociateEntryPass"
    )
  );

  return (
    <div id="security" className="my-2">
      {!is_qualified_user && isAuthorizedToDisassociate && (
        <div className="float-end">
          <Tooltip placement="top" hasArrow label="Desasociar seguridad">
            <span>
              <ButtonHeaderModule onClick={handleDelete} bgColor="#1F7AC3">
                <IconGeneric
                  width="16px"
                  className="text-white"
                  as={BiUnlink}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        </div>
      )}
      <div className="row">
        <IconGeneric width="50px" as={RiSecurePaymentFill} />
        <div className="col">
          <Title size={"16px"} weight={600}>
            {title && title}: {code_security && code_security}
          </Title>
          <div className="d-flex">
            <Title className="my-2" size={"16px"} weight={600}>
              Fecha: {date_security + " " + time_security}
            </Title>
            <Title className="my-2 ms-4" size={"16px"} weight={600}>
              Usuario: {name_person + " " + lastname_person}
            </Title>
          </div>

          {nickname && (
            <SubTitle size={"12px"}>Usuario: {nickname && nickname}</SubTitle>
          )}
        </div>
      </div>
    </div>
  );
};
