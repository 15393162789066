import React, { useState } from "react";
import { Field } from "formik";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { AiFillEyeInvisible } from "react-icons/ai";
import { EyeIcon } from "./PasswordField.styled";
import { BiShow } from "react-icons/bi";

const PasswordField = ({ name, placeholder, size, disabled = false }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <InputGroup size={size}>
            <Input
              id={name}
              {...field}
              size={size}
              variant="outline"
              disabled={disabled}
              className="form-control"
              placeholder={placeholder}
              autoComplete="current-password"
              type={show ? "text" : "password"}
            />
            <InputRightElement width="3rem">
              <EyeIcon
                as={show ? AiFillEyeInvisible : BiShow}
                onClick={handleClick}
                color="#9097BE"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default PasswordField;
