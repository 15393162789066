import { toast } from 'react-toastify';
import { MdNotifications } from 'react-icons/md';
import React from 'react';

/**
 * CustomToastContent - Componente para contenido personalizado en la notificación.
 *
 * @param {React.ReactNode} content Contenido a renderizar, puede ser un componente React o un mensaje de texto.
 * @param {React.ElementType | null} Icon Componente de ícono para mostrar junto al contenido.
 */
const CustomToastContent = ({ content, Icon }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
   
    {content}
  </div>
);

/**
 * createNotification - Función para crear una notificación con contenido personalizado.
 *
 * @param {string} type Tipo de notificación (success, error, warn, info)
 * @param {React.ReactNode} content Contenido de la notificación, puede ser un componente React o un mensaje de texto.
 * @param {React.ElementType | null} Icon Componente de ícono para incluir en la notificación.
 * @param {string} position Posición de la notificación
 * @param {string} toastId Identificador único para la notificación
 */
const createNotification = (type, content, Icon = null, position = 'top-center', toastId = 'id') => {
  toast[type](<CustomToastContent content={content} Icon={Icon} />, {
    position,
    autoClose: 7000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    toastId,
  });
};

/**
 * useNotificationHook - Hook personalizado para abrir notificaciones.
 */
export const useNotificationHook = () => {
  const openAlert = (content, state, Icon = MdNotifications, position = 'top-center', customId = 'id') => {
    createNotification(state, content, Icon, position, customId);
  };

  return [openAlert];
};
