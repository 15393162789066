import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import environment from 'constants/apiConst'
import { __PostJsonData } from 'request/Petitions/__Post'
import React, { useEffect, useState } from 'react'
import CriteriaSummary from './CriteriaSummary'
import SpinnerComponent from 'components/Spinner/SpinnerComponent'
import { Box, Icon, Text, Tooltip } from '@chakra-ui/react'
import TableOrder from 'components/Tables/TableOrder'
import { HeaderSearchCustom } from 'styled/SectionContainer.styled'
import Pagination from 'components/Pagination/Pagination'
import TbodyUserRisk from './UsersAccordingToCriteria/TbodyUserRisk'
import { theadCriteria } from './UsersAccordingToCriteria/thead/thead'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { BsCalendar4Event } from 'react-icons/bs'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { ExportPdf } from 'components/Export/ExportPdf'
import { IconGeneric } from 'styled/Icons.styled'
import { VscFilePdf } from 'react-icons/vsc'
import ExportShowRiskLevelUser from './UsersAccordingToCriteria/Components/ExportShowRiskLevelUser'

export const LevelNotArray = level => {
  switch (level) {
    case 'ALTO':
      return 3
    case 'MODERADO':
      return 2
    case 'LEVE':
      return 1
    default:
      return null
  }
}

function ShowRiskLevelUser () {
  const location = useLocation()
  const [openAlert] = AlertErrorAlert()
  const [criteriaStatistic, setCriteriaStatistic] = useState([])
  const [currentPage, setCurrentPage] = useState([])
  const [loading, setLoading] = useState(false)
  const [maxPage, setMaxPage] = useState(1)
  const [page, setPage] = useState(1)
  const [Showbar, setShowbar] = useState(null)
  const { FreeZoneId } = queryString.parse(location.search)
  const [CurrentRiskLevel, setCurrentRiskLevel] = useState({
    criteria_id: [],
    risk_level: []
  })
  const [isLoading, setIsLoading] = useState(true)
  const token = useSelector(state => state.auth.dataUser.token)

  const set = {
    freeZone_id: FreeZoneId ?? 0,
    criteria_id: CurrentRiskLevel?.criteria_id,
    risk_level: CurrentRiskLevel?.risk_level,
    sector_id: CurrentRiskLevel?.sector_id,
    length: 7
  }

  const CriterisRisk = async (informacion, RiskDefinition = false) => {
    if (RiskDefinition) setLoading(true)
    else setIsLoading(true)

    try {
      const res = await __PostJsonData(
        environment.SHOW_CONTROL_MATRIX_BY_FREEZONE,
        token,
        informacion,
        page
      )
      const status = res?.data?.status
      if (status?.message === 'Acceso no autorizado.') {
        openAlert(status?.message, 'error')
      } else {
        setCurrentPage(
          status?.data?.criteria_risk_definition_qualified_user?.data
        )
        setShowbar(status?.data)
        setMaxPage(
          status?.data?.criteria_risk_definition_qualified_user?.last_page
        )
        setCriteriaStatistic(status?.data?.criteria_statistic)
      }
    } catch (error) {
      console.log(error)
    } finally {
      if (RiskDefinition) setLoading(false)
      else setIsLoading(false)
    }
  }

  useEffect(() => {
    CriterisRisk(set, false)
  }, [])

  useEffect(() => {
    if (currentPage.length > 0 || CurrentRiskLevel) CriterisRisk(set, true)
  }, [page, CurrentRiskLevel])

  if (isLoading) return <SpinnerComponent size='auto' />

  return (
    <div>
      <>
        {' '}
        <ExportShowRiskLevelUser
          CurrentRiskLevel={CurrentRiskLevel}
          Showbar={Showbar}
          criteriaStatistic={criteriaStatistic}
          currentPage={currentPage}
          loading={loading}
          setCurrentPage={setCurrentPage}
          setCurrentRiskLevel={setCurrentRiskLevel}
          id={'exportComponents'}
        />
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          m={6}
          p={4}
          mb={0}
        >
          <Text fontSize={14} fontWeight={500} color={'gray'}>
            <Icon as={BsCalendar4Event} boxSize={6} color={'gray'} pr={1} />
            {Showbar?.date}
          </Text>

          <Text fontSize={14} fontWeight={500} color={'gray'}>
            Zona Franca
          </Text>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          m={6}
          p={4}
          mt={0}
          bg={'gray.100'}
          borderTop={'1px solid gray'}
        >
          <Text color={'gray'}>{Showbar?.free_zone?.shortName_freeZone}</Text>
          <Text color={'gray'}></Text>
        </Box>
      </>

      <Box display={'flex'} justifyContent={'right'} m={6} p={4} mt={0}>
        <Tooltip placement='top' hasArrow label='Exportar PDF'>
          <span>
            <ButtonHeaderModule
              onClick={() =>
                ExportPdf('exportComponents', 'Análisis de control')
              }
              className='m-1'
              bgColor='#03C39A'
            >
              <IconGeneric className=' text-white' as={VscFilePdf} />
            </ButtonHeaderModule>
          </span>
        </Tooltip>
      </Box>

      <CriteriaSummary
        criteriaStatistic={criteriaStatistic}
        set={setCurrentRiskLevel}
      />

      {CurrentRiskLevel?.criteria_id.length > 0 && (
        <Box
          display={'flex'}
          flexDir={'column'}
          m={6}
          p={6}
          borderRadius={'10px'}
          shadow={
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
          }
          maxW='full'
        >
          <HeaderSearchCustom margen='0' padding='3' Shadow='0'>
            <TableOrder
              thead={theadCriteria}
              data={currentPage ?? []}
              tbodyData={TbodyUserRisk}
              setData={setCurrentPage}
              loading={loading}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </HeaderSearchCustom>
        </Box>
      )}
    </div>
  )
}

export default ShowRiskLevelUser
