import React, { useEffect } from 'react'
import { Page, View, Document, StyleSheet, Text } from '@react-pdf/renderer'
import { ItemFormEntry } from './CreatePDF/ItemFormEntry'
import { HeaderEntryFormPDF } from 'pages/Entry_and_exit_forms/Export/pdfOriginal/cabeceraEntry/HeaderEntryFormPDF'
import { TitleOperationEntryPDF } from 'pages/Entry_and_exit_forms/Export/pdfOriginal/operationTitle/TitleOperationEntryPDF'
import { HeaderOperationEntry } from 'pages/Entry_and_exit_forms/Export/pdfOriginal/cabeceraEntry/HeaderOperationEntry'
import Totalizer from 'pages/Entry_and_exit_forms/Export/pdfOriginal/cabeceraEntry/Body/Totalizer'
import FooterDocument from './CreatePDF/FooterDocument'
import CreateAnexxed from './CreatePDF/CreateAnexxed'
import axios from 'axios';

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  ContentItem: {
    display: 'flex',
    flexDirection: 'row'
  },
  BoxTitle: {
    marginTop: '15px',
    marginBottom: '5px',
    border: '1px solid #bababa',
    borderRadius: '1px',
    padding: '5px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  page: {
    backgroundColor: '#ffffff',
    padding: '1.5cm'
  },
  title: {
    marginBottom: '1.5cm'
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: '1.5cm'
  },
  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  signatureTitle: {
    fontSize: 10,
    marginBottom: '0.5cm',
    textAlign: 'center'
  },
  signatureLine: {
    borderBottom: '1px solid #000000',
    width: '120px'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 8,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  Tan: {
    border: '1px solid gray',
    borderLeft: 'none',
    borderBottom: '1px solid gray',
    padding: '2px',
    marginTop: '8px',
    width: '100px',
    height: '17px',
    textAlign: 'center'
  }
})

function CreatePDF({
  form = [],
  DocumentOperation,
  items = [],
  Total,
  mode = 'entry',
  optional,
  authUser
}) {
  const { code_operation } = form
  const ITEMS_PER_PAGE = 11

  if (!Array.isArray(items)) {
    console.error("Error: 'items' debe ser un arreglo válido.")
  }

  const paginatedItems = items.reduce((pages, item, index) => {
    const pageIndex = Math.floor(index / ITEMS_PER_PAGE)
    pages[pageIndex] ??= []
    pages[pageIndex].push(item)
    return pages
  }, [])

  const belongsToSequence = x => {
    const firstTerm = 2
    const commonDifference = 2

    const remainingDifference = x - firstTerm
    return remainingDifference % commonDifference === 0
  }

  const totalPages = paginatedItems.length
  let currentPage = 0 // Variable para realizar seguimiento de la página actual

  const RenderFooter = () => {
    const renderProperty = ({ pageNumber, totalPages }) => {
      if (pageNumber && totalPages) {
        return `${pageNumber} / ${totalPages}`
      }
    }

    return (
      <View style={styles.footer} fixed>
        <Text>{new Date().toLocaleString()}</Text>
        <Text render={renderProperty} />
      </View>
    )
  }

  const RenderLastPage = ({ children }) => {
    const renderProperty = ({ pageNumber, totalPages }) => {
      if (pageNumber === totalPages) {
        return children
      }
    }

    return <View render={renderProperty} />
  }

  const Anexe = () => {
    return (
      <>
        <CreateAnexxed data={DocumentOperation} mode={mode} />
        <View
          style={{
            width: '100%',
            marginTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {' '}
          <Totalizer
            data={Total}
            items={items}
            precision={Total?.precision}
            addedValue={Total?.addedValue_us || 0}
            mode={mode}
          />
        </View>
        <FooterDocument />
        <RenderFooter />
      </>
    )
  }

  return (
    <Document>
      <Page size='A4' style={styles.page} renderAnnotationLayer={false}
        renderTextLayer={false}>
        <>
          <HeaderEntryFormPDF mode={mode} values={form} authUser={authUser} />
          <View
            style={{
              width: '100%',
              paddingBottom: '10px',
            }}
          >
            <TitleOperationEntryPDF code={code_operation} mode={mode} />
            <HeaderOperationEntry
              values={form}
              mode={mode}
              optional={optional}
            />
          </View>

        </>
        {paginatedItems.map((pageItems, pageIndex) => {
          currentPage = pageIndex
          return (
            <View style={styles.table}>
              <View style={styles.ContentItem}>
                <View style={{ width: '100%' }}>
                  {pageItems.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                      <ItemFormEntry
                        mode={mode}
                        data={item}
                      />
                    </React.Fragment>
                  ))}
                </View>


              </View>
            </View>
          )
        })}
        {((items?.length <= 1) || (items?.length > 2 && (items?.length % 2 === 0 || items?.length % 3 === 0)))
          ? <Anexe /> :
          <RenderFooter />
        }
      </Page>
      {(items.length > 2 && !(items?.length % 2 === 0 || items?.length % 3 === 0)) &&
        <>
          <Page size='A4' style={styles.page}>
            <Anexe />
          </Page>
        </>}
    </Document>
  )


  if (items.length > 0) {
    return (
      <Document>
        {paginatedItems.map((pageItems, pageIndex) => {
          currentPage = pageIndex
          return (
            <Page key={pageIndex} size='A4' style={styles.page}>
              {pageIndex === 0 && (
                <>
                  <HeaderEntryFormPDF
                    mode={mode}
                    values={form}
                    authUser={authUser}
                  />
                  <View
                    style={{
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                  >
                    <TitleOperationEntryPDF code={code_operation} mode={mode} />
                    <HeaderOperationEntry
                      values={form}
                      mode={mode}
                      optional={optional}
                    />
                  </View>
                  <Totalizer
                    data={Total}
                    items={items}
                    form={form}
                    precision={Total?.precision}
                    addedValue={Total?.addedValue_us || 0}
                    mode={mode}
                  />
                </>
              )}

              <View style={styles.table}>
                <View style={styles.ContentItem}>
                  <View style={{ width: '100%' }}>
                    {pageItems.map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        <ItemFormEntry
                          mode={mode}
                          data={item}
                          typeColumn={'Left'}
                        />
                      </React.Fragment>
                    ))}
                  </View>

                  <View style={{ width: '100%' }}>
                    {pageItems.map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        <ItemFormEntry
                          mode={mode}
                          data={item}
                          key={itemIndex}
                          typeColumn={'Right'}
                        />
                      </React.Fragment>
                    ))}
                  </View>
                </View>
              </View>

              {currentPage === totalPages - 1 && (
                <View
                  break={
                    belongsToSequence(paginatedItems?.[0]?.length) &&
                    mode !== 'entry'
                  }
                >
                  <CreateAnexxed data={DocumentOperation} mode={mode} />
                </View>
              )}

              <RenderLastPage>
                <FooterDocument />
              </RenderLastPage>

              <RenderFooter />
            </Page>
          )
        })}
      </Document>
    )
  }



  if (items.length === 0) {
    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <>
            <HeaderEntryFormPDF mode={mode} values={form} authUser={authUser} />
            <View
              style={{
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              <TitleOperationEntryPDF code={code_operation} mode={mode} />
              <HeaderOperationEntry
                values={form}
                mode={mode}
                optional={optional}
              />
            </View>
            <View
              style={{
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              {' '}
              <Totalizer
                data={Total}
                items={items}
                precision={Total?.precision}
                addedValue={Total?.addedValue_us || 0}
                mode={mode}
              />
            </View>
          </>

          <CreateAnexxed data={DocumentOperation} mode={mode} />

          <RenderLastPage>
            <FooterDocument />
          </RenderLastPage>

          <RenderFooter />
        </Page>
      </Document>
    )
  }
}

export default CreatePDF
