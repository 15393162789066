import React from "react";

export const TbodyPdfSuplies = ({ data }) => {
  return (
    <tr>
      <td className="text-center">
        <span>{data?.description_product}</span>
      </td>
      <td className="text-center">
        {data?.product_subheading_code ? data?.product_subheading_code : ""}
      </td>
      <td className="text-center">
        {data?.business_unit ? data?.business_unit : ""}
      </td>
      <td className="text-center">{""}</td>
      <td className="text-center">
        {data?.value_CIF_unit_product ? data?.value_CIF_unit_product : ""}
      </td>
      <td className="text-center">{data?.quantity_product}</td>
    </tr>
  );
};
