import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useEffect } from "react";
import useDebounceText from "./useSearchDebounce";

export const useDebounceError = () => {
  const [error, setError] = useDebounceText();
  const [openAlert] = AlertErrorAlert();

  useEffect(() => {
    if (error) {
      openAlert(error, "error");
    }
  }, [error]);

  const handleDebounceError = ({ target }, set, error, regex) => {
    regex = new RegExp(regex)
    const { value } = target;
    if (regex?.test(value)) {
      return set({ target });
    }
    setError(error);
  };
  return [handleDebounceError];
};

export const useDebounceAlert = () => {
  const [error, setError] = useDebounceText();
  const [openAlert] = AlertErrorAlert();
  useEffect(() => {
    if (error) {
      openAlert(error, "error");
    }
  }, [error]);

  const handleDebounceAlert = (error) => {
    setError(error)
    setTimeout(() => {
      setError(null);
    }, 3000);
  };
  return [handleDebounceAlert];
};

export const useDebounceErrorHandling = () => {
  const [debouncedError, setDebouncedError] = useDebounceText();
  const [openErrorAlert] = AlertErrorAlert();

  useEffect(() => {
    if (debouncedError) openErrorAlert(debouncedError, "error");
  }, [debouncedError]);

  const handleDebounceError = (
    { target },
    set,
    errorMessage = null,
    validationRegex = null
  ) => {
    const { value } = target;

    if (errorMessage && validationRegex) {
      if (validationRegex.test(value)) {
        return set({ target });
      }
      setDebouncedError(errorMessage);
    } else {
      return set({ target });
    }
  };

  return [handleDebounceError];
};

/*
export const useDebounceError2 = ({ target }, set, regex) => {
  const [error, setError] = useState(null);
  const [text, setSearchText] = useDebounceText();

  useEffect(() => {
    if (text) {
      handleRegex({ target }, set, regex);
    }
  }, [text]);

  useEffect(() => {
    if (error) {
      AlertErrorAlert(error);
    }
  }, [error]);

  return [error, setError, setSearchText];
};
 */
