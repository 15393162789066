/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import DataCompany from "./DataCompany/DataCompany";
import ContainerForms from "./ContainerForms";
import DocumentUploader from "./DocumentUploader/DocumentUploader";
import FormCrudCRUDContext from "context/FormCrudContex";
import environment from "constants/apiConst";
import Swal from "sweetalert2";
import "../StyleFormInt.css";
import { useForm } from "hooks/useForm";
import { Box } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import { ButtonStyled } from "styled/Buttons.styled";
import { ViewFormEntry } from "../View/viewFormEntry";
import { __ShowForm } from "request/Forms/__ShowForm";
import Vapor from "laravel-vapor";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import queryString from "query-string";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useCallback } from "react";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { __SearchUser } from "request/Reviews/__GetTReviews";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { __Post } from "request/Petitions/__Post";
import { useNotations } from "context/NotationsContext";
import { useNotations as useNotationInput } from "hooks/useGetInput";
import { changeForms } from "redux/actions/FormsActions";

import {
  arrayToString,
  isArray,
  isFunction,
  isNonEmptyString,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  mergeUniqueElements,
} from "utils/type-check-utils";

import { setFormData, clearFormData } from "redux/actions/dataFormActions";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { GroupInputUpload } from "../GroupInputUpload";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { Storage } from "hooks/useStorage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import AddItemsReentryOperation from "./AddItemForm/AddItemsReentryOperation";
import EnhancedFileUploadManager from "pages/CustomsClearance/MultipleFileUploader";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";
import { handleError } from "utils/handleError";
import { handleValidationError } from "utils/handleValidationError";

// import { Commentary } from "components/Commentary/Commentary";
/**
 * Convierte un array de elementos en un objeto agrupado por una clave específica.
 *
 * @param {Array} array - El array de elementos a agrupar.
 * @param {Function|string|null} customKey - Función o nombre de propiedad para generar la clave de agrupación.
 * @returns {Object} Un objeto con los elementos agrupados.
 */
export function convertArrayToGroupedObject(array, customKey = null) {
  return array.reduce((acc, item) => {
    // Determinamos la clave base
    let key;

    if (isFunction(customKey)) {
      key = customKey(item);
    } else if (isString(customKey)) {
      key = customKey;
    }

    key = key ?? `document-${item.documentReq_id}`;
    if (item.doc_corrected_req === 1) {
      key += "_doc_corrected_req";
    }

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);

    return acc;
  }, {});
}

/**
 * BodyForm - el usuario registra su formulario de entrada y dependiendo de la operacion cambian algunos campos del formulario secundario.
 *
 * @component
 * @return {Component} Retorna creacion formularios de salida.
 */

const errorsDefaultState = {
  opertationId: false,
};
const BodyForm = () => {
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { balance } = useSelector((state) => state.status);
  const userQualified = useSelector(
    (state) => state.auth.dataUser.userQualified
  );
  const [openAlert] = AlertErrorAlert();
  const [errorsState, setErrorsState] = useState(errorsDefaultState);
  const { formOperation, setFormOperation, authorizationNCI, authorizationS } =
    useContext(GlobalContext);
  const dispatchRedux = useDispatch();
  const {
    typeEntryCurrent,
    selectIdOp,
    valueThird,
    dataToEdit,
    valueQualified,
    nameQualified,
    selectUF,
    selectItemForm,
    setRequestCreate,
    valueDepartament,
    valueQualifiedForm,
    setRequestEdit,
    setDocumentOperation,
    DocumentOperation,
    portM,
    inspeccion,
    setRequestEditItem,
    editDataForm,
    setNameThird,
    setNameQualified,
    setValueQuialified,
    setNameOperation,
    setValueThird,
    setSelectIdOp,
    setSelectUF,
    setRequestCreateItem,
    dataToCopy,
    setSelectItemForm,
    deleteAttachment,
    setValueQuialifiedForm,
    setDataToEdit,
    getOperationNotPaginate,
    setTypeEntryCurrent,
    setDecimalUcContext,
    setValueDepartament,
    setDepartament,
    update,
    customsadministration,
    setCustomsadministration,
    nameThird,
    currentItemLength,
    setCurrentItemLength,
    setDataFormCurrent,
    setDataToShow,
    setUpdate,
    /* Estos estados controlan los items de autorización de salida: */
    isSelectedAllItems,
    setIsSelectedAllItems,
    itemsSelected,
    setItemsSelected,
    isOpenSelectAuthorizationItems,
    onOpenSelectAuthorizationItems,
    onCloseSelectAuthorizationItems,
    selectedQualifiedUser
  } = useContext(FormCrudCRUDContext);

  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);
  const navigate = useNavigate();
  const [FormStatus, setFormStatus] = useState(null);
  const [files, setFiles] = useState({});
  const [attachment, setAttachment] = useState([]);
  const [otherAttachmentNumber, setOtherAttachmentNumber] = useState([]);
  const [attachmentId, setAttachmentId] = useState([]);
  const [attachmentDescription, setAttachmentDescription] = useState([]);
  const [attachmentNumber, setAttachmentNumber] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();

  const [attachmentType, setAttachmentType] = useState([]);
  const location = useLocation(); // navigate
  const { FormExitNumber = null, FormEntryNumber = null } = queryString.parse(
    location.search
  );

  const [nameFiles, setNameFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const fieldAllowed = [
    "purchase_order",
    "transit_expiration_date",
    "transp_doc_date",
    "dcl_customs_transit",
    "integration_box_certificate",
    "associated_temporary_output",
    "num_transport_document",
    "cargo_manifest_entryForm",
    "num_commercial_invoice",
    "dex_date_entryForm",
    "num_dex_entryForm",
    "num_import_declaration",
    "exit_auth_entryForm",
    "shipping_form_expiration_date",
    "transit_expiration_date",
    "integration_box_certificate",
  ];

  const {
    updateNotation,
    updateNotationData,
    setFormId,
    setReviewsData,
    resetNotations,
    setDataCurrent,
  } = useNotations();

  useEffect(() => {
    return () => {
      resetNotations();
    };
  }, []);

  function createKey(documentReqId) {
    return "document-" + documentReqId;
  }

  function createObjectFromItem(item, index) {
    return {
      name: item.attachment_name,
      document_id: item.documentReq_id,
      downloadFile: item.attachment_url,
      from_server: true,
      id: item.id,
      originalIndex: index,
      attachment_number: item.attachment_number,
      attachment_description: item.attachment_description,
      attachment_comment: item.attachment_comment,
      attachment_date: item.attachment_date,
    };
  }

  const onFieldChange = (name, newValue, target) => {
    const { oldValue, itemId, inputTag } = target?.dataset;
    const Allowed = fieldAllowed.includes(name);

    if (Allowed) {
      return updateNotation(name, inputTag, newValue, oldValue, itemId);
    }

    /* El efecto de vacíar los campos num_exit_form y integration_box_certificate debe ignorarse para las OP 500 y las 120 */
    if (
      !/^(?:[0-46-9]\d{2}|[0-9]{1,2})$/.test(selectItemForm) ||
      selectItemForm === 120
    )
      return;

    if (name === "num_exit_form") {
      return setValues((prev) => ({
        ...prev,
        integration_box_certificate: "",
      }));
    }

    if (name === "integration_box_certificate") {
      return setValues((prev) => ({
        ...prev,
        num_exit_form: "",
      }));
    }
  };

  const initialForm = {
    num_dex_entryForm: "",
    dex_date_entryForm: "",
    exit_auth_entryForm: "",
    inspection: "",
    operation_id: "",
    portmagdalena: "",
    department_id: "",
    num_exit_form: "",
    thirdPartie_id: "",
    code_entryForm: "",
    transp_doc_date: "",
    integration_certificate: "",
    shipment_entryForm: "",
    dcl_customs_transit: "",
    num_transport_document: "",
    num_commercial_invoice: "",
    num_import_declaration: "",
    customs_administration: "",
    inspection_act_or_auto: "",
    transit_expiration_date: "",
    cargo_manifest_entryForm: "",
    num_exit_form: null,
    cormagdalena: "",
    purchase_order: "",
    qualifiedUser2_id: "",
    national_or_foreign: "",
    integration_box_certificate: "",
    associated_temporary_output: "", // sustituis una vez lo reciba
    shipping_form: "",
    cabotage: "",
    shipping_form_expiration_date: "",
    cabotage_expiration_date: "",
  };

  const [values, handleInputChange, reset, setValues] = useForm(
    initialForm,
    null,
    onFieldChange
  );

  const [Reviews, setReviews] = useState([]);
  const [ProviderExporter, setProviderExporter] = useState({
    label: null,
    value: null,
  });

  useEffect(() => {
    if (valueQualified && authUser.is_qualified_user !== 1) {
      getOperationNotPaginate(valueQualified);
    }
  }, [valueQualified]);

  useEffect(() => {
    if (authUser.is_qualified_user === 1) {
      getOperationNotPaginate(userQualified);
    }
  }, [authUser]);

  useEffect(() => {
    reset();
    setValueThird("");
    setSelectItemForm("");
    setNameQualified("");
    setNameThird("");
    setNameOperation("");
  }, [freeZoneValue]);

  useEffect(() => {
    if (FormEntryNumber === null) {
      handleReset();
      setValues(initialForm);
      setValueThird("");
      setSelectItemForm("");
      setNameQualified("");
      setNameThird("");
      setNameOperation("");
      setSelectIdOp("");
    }
  }, [FormEntryNumber]);

  const initialpos = async (id) => {
    setLoading(true);
    try {
      const res = await __ShowForm(token, id, environment.FORMS_ENTRY_VIEW);
      // response.status_entryForm
      const response = res?.data?.status?.data;
      if (res?.data?.status.code === 200) {
        dispatchRedux(setFormData(response));
        if (
          response.status_entryForm === "BORRADOR" ||
          response.status_entryForm === "DEVUELTO"
        ) {
          setValueQuialifiedForm({
            value: response?.qualifiedUser2_id,
            label: response?.name_qualified2,
          });
          setFormStatus(response.status_entryForm);
          // setNameQualifiedForm(response?.name_qualified2);
          setSelectUF(response?.shipment_entryForm);
          setDataFormCurrent(response);
          setDataCurrent((prev) => ({
            ...prev,
            formCurrent: response,
          }));
          if (response) setReviews(response?.reviews);
          // /setValues(response)
          setValues({
            shipping_form_expiration_date: response?.shipping_form,
            ...response,
          });

          setProviderExporter({
            label: `${response?.nit_qualified} - ${response?.name_qualified}`,
            value: response?.qualifiedUser_id,
          });
          setNameThird(response?.business_name);
          setValueThird(response?.thirdPartie_id);
          setValueQuialified(response?.qualifiedUser_id);
          setNameQualified(
            `${response?.nit_qualified} - ${response?.name_qualified}`
          );

          setFormOperation((prev) => ({
            ...prev,
            reentry_operation: response?.reentry_operation,
          }));

          if (response.operation_id) {
            setNameOperation(
              response?.code_operation + " " + response?.short_name_operation
            );
            setSelectIdOp(response?.operation_id);
          }

          if (response.department_id) {
            setDepartament(
              response.code_department + " " + response.name_department
            );
            setValueDepartament(response.department_id);
          }
          setDocumentOperation(response?.documents);
          setSelectItemForm(parseInt(response?.code_operation));

          const fromServer = response.files.reduce(
            (accumulator, item, index) => {
              const key = createKey(item.documentReq_id);
              accumulator[key] = createObjectFromItem(item, index);
              return accumulator;
            },
            {}
          );

          setFiles(Object.assign({}, files, fromServer));
          const other_files = convertArrayToGroupedObject(
            response?.other_files || [],
            "other_files"
          );

          setOtherDocuments(other_files);

          if (response.check_rm) setTypeEntryCurrent(response.check_rm);
          userC(response?.qualifiedUser_id);
          if (response.customAdministration_id) {
            setCustomsadministration({
              label: response.code_custom + " " + response.name_custom,
              value: response.customAdministration_id,
            });
          }
        } else {
          openAlert(
            `El formulario ${response.code_entryForm} esta en estado ${response.status_entryForm} y no puede ser editado`,
            "error"
          );
          navigate(`/FormShow?FormEntry=${response.id}`);
        }
      } else {
        openAlert(res?.data?.status.message, "error");
        navigate(-1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const { pathname } = location;
  const { requestData } = useGlobalContext();

  const { mutate: mutateCreate, isPending } = useMutation({
    mutationFn: requestData,
  });

  //funciones migradas del context
  const resCreateForm = (res) => {
    const dataCreateForm = res?.data?.status;

    if (res?.data?.status?.code === 200) {
      setUpdate(!update);
      const dataBalanceFmm = dataCreateForm?.data.balance;
      localStorage.setItem("ValueBalance", JSON.stringify(dataBalanceFmm));
      dispatchRedux(setDataStatusBalance(!balance));
      openAlert(`Registro actualizado Exitosamente`, "success");
      if (pathname === "/FormEntry") {
        const dataFmm = dataCreateForm?.data.entry_form;
        setDataToShow(dataFmm);
        dispatchRedux(changeForms(dataFmm?.id));
        setDataToEdit(dataFmm);
        setItemsSelected([]);
        setIsSelectedAllItems(false);
        navigate(`/FormEntry?FormEntryNumber=${dataFmm?.id}`);
      } else if (pathname === "/FormExit") {
        const dataFmm = dataCreateForm?.data.output_form;
        setDataToShow(dataFmm);
        dispatchRedux(changeForms(dataFmm?.id));
        setDataToEdit(dataFmm);
        navigate(`/FormExit?FormExitNumber=${dataFmm?.id}`);
      }
    }

    if (res?.data?.status?.code !== 200) {
      if (dataCreateForm?.message) {
        openAlert(dataCreateForm?.message, "error");
      } else {
        Object.values(dataCreateForm?.message).forEach((message) =>
          message.forEach((item) => openAlert(item, "error"))
        );
      }
    }
  };

  const resUpdateForm = (
    res,
    rediret = true,
    successCallback = () => {},
    cancelUpdate = false
  ) => {
    if (res.data.status.code === 200) {
      const { id } = res.data.status.data;
      setDataToEdit(null);
      openAlert(`Registro actualizado Exitosamente  `, "success");
      if (!cancelUpdate) setUpdate(!update);
      successCallback();
      if (!rediret) return;
      if (pathname === "/FormExit" || pathname === "/FormShowExit") {
        return navigate(`/FormShowExit?ExitNumber=${id}`);
      }
      return navigate(`/FormShow?FormEntry=${id}`);
    }

    if (res.data.status.code !== 200) {
      setDataToEdit(!dataToEdit);
      openAlert(res?.data?.status?.message, "error");
    }
  };

  const createForm = async (data, successCallback = () => {}) => {
    const config = {
      data,
      Endpoint: environment.CREATE_FORM_ENTRY,
      PropertyBody: "sendJSONContentPOST",
    };

    mutateCreate(config, {
      onSuccess: (res) => {
        successCallback();
        resCreateForm(res)
      },
      onError: (error) => {
        handleError(error)
        handleValidationError(error, setErrorsState, errorsState);
      },
    });
  };

  const editForm = async (
    info,
    rediret = true,
    successCallback = () => {},
    cancelUpdate = false
  ) => {
    const config = {
      data: info,
      Endpoint: environment.UPDATE_FORM_ENTRY,
      PropertyBody: "sendJSONContentPOST",
    };

    mutateCreate(config, {
      onSuccess: (res) =>
        resUpdateForm(res, rediret, successCallback, cancelUpdate),
    });
  };

  const {
    isLoading: isLoadingFiles,
    data: data_Files,
    refetch,
    isFetching,
    isSuccess,
  } = useQuery({
    queryKey: ["data_Files"],
    queryFn: () =>
      requestData({
        Endpoint: environment.FORMS_ENTRY_VIEW,
        PropertyBody: "get",
        resourceIdentifier: idForm,
      }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: dataView,
    refetch: refetchView,
    isFetching: isFetchingView,
    isSuccess: isSuccessView,
  } = useQuery({
    queryKey: ["formEntryView"],
    queryFn: () =>
      idForm &&
      requestData({
        Endpoint: environment.FORMS_ENTRY_VIEW,
        PropertyBody: "get",
        resourceIdentifier: idForm,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (isSuccessView && !isFetchingView) {
        const data = dataView?.data?.status?.data;

        const commercialInvoiceUseForm = isString(
          values?.num_commercial_invoice
        )
          ? values?.num_commercial_invoice.split(", ")
          : "";

        const commercialInvoiceGet = isString(data?.num_commercial_invoice)
          ? data?.num_commercial_invoice.split(", ")
          : "";

        const ElementMerge = mergeUniqueElements(
          commercialInvoiceUseForm,
          commercialInvoiceGet
        );
        const arrayToStringLocal = arrayToString(ElementMerge, ", ");

        setValues((prev) => ({
          ...prev,
          num_commercial_invoice: arrayToStringLocal,
        }));
      }
    }
  }, [isFetchingView]);

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      refetch();
      //  return setCalculated(res?.data?.status?.data);
    }
  };

  const responseDeleteFile = (res, index = null, id) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      const filteredAttachments = isNumber(index)
        ? otherDocuments.filter((_, i) => i !== index)
        : otherDocuments;

      if (isNumber(index)) setOtherDocuments(filteredAttachments);
      setAttachment([]);
      return openAlert("El documento ha sido eliminado ", "error");
    }
  };

  const updateAndCreate = async (data = undefined, payloadManual = false) => {
    const formData = new FormData();
    formData.append("id", idForm);
    const dataAttachments = attachment[0];
    const vaporRes = (await data) || null;

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };

    if (dataAttachments?.id || dataAttachments?.other_attachment_description) {
      formData.append(
        "other_attachment[]",
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      );
    }

    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    const date =
      dataAttachments?.other_attachment_date ||
      dataAttachments?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    dataAttachments?.attachment_date &&
      formData.append("attachment_date[]", dataAttachments?.attachment_date);

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      if (data) {
        Object.keys(data).forEach((documentId) => {
          if (!data[documentId].from_server) {
            formData.append(
              "attachment[" + documentId + "]",
              JSON.stringify(data[documentId])
            );
          }
        });
      }
    }

    if (!idForm && documentsBeforeCreate().length > 0 && data) {
      setFiles(data);
      return;
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint: environment.UPDATE_FORM_ENTRY,
      PropertyBody: "sendFormDataContentPOST",
    };

    setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  const editUploadFiles = (data) => {
    const formData = new FormData();
    formData.append("id", idForm);

    if (data?.id) formData.append("attachment_id[]", data.id);

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append("attachment_comment[]", data.attachment_comment);
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append("attachment_number[]", data.attachment_number);
    }
    console.log({ dataFormData: data });
    if (isNonEmptyString(data?.attachment_date)) {
      formData.append("attachment_date[]", data.attachment_date);
    }

    updateAndCreate(formData, true);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (isSuccess) {
        const data = data_Files?.data?.status?.data;
        const fileServer = data.files.reduce((old, it, index) => {
          return {
            ...old,
            ["document-" + it.documentReq_id]: {
              name: it.attachment_name,
              document_id: it.documentReq_id,
              downloadFile: it.attachment_url,
              from_server: true,
              id: it.id,
              originalIndex: index,
              attachment_number: it.attachment_number,
              attachment_description: it.attachment_description,
              attachment_comment: it.attachment_comment,
              attachment_date: it.attachment_date,
            },
          };
        }, {});

        setFiles({
          ...fileServer,
        });

        const other_files = convertArrayToGroupedObject(
          data?.other_files || [],
          "other_files"
        );

        setOtherDocuments(other_files);
      }
    }
  }, [isFetching]);

  const firstRender = useRef(true);

  const userC = useCallback(
    async (idUcCurrent) => {
      try {
        const res = await __AllQualifiedUSers(token);
        const serachUc = res?.data?.status?.data.find(
          ({ id }) => id === Number(idUcCurrent)
        );
        setDecimalUcContext(serachUc.decimal_values_precision);
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (FormEntryNumber || dataToCopy !== null) {
      initialpos(FormEntryNumber);
    } else {
      handleReset();
    }
  }, [dataToCopy, FormEntryNumber, update]);

  useEffect(() => {
    setRequestCreate(environment.CREATE_FORM_ENTRY);
    setRequestCreateItem(environment.CREATE_ITEMS_FORM);
    setRequestEditItem(environment.UPDATE_ITEMS);
    setRequestEdit(environment.UPDATE_FORM_ENTRY);
  }, [
    setRequestCreate,
    setRequestCreateItem,
    setRequestEditItem,
    setRequestEdit,
  ]);

  const saveCorrectionReview = async () => {
    setLoading(true);
    const templateData = {
      type_doc: "Ingreso",
      id: FormEntryNumber,
      review: updateNotationData,
    };

    if (isObject(updateNotationData) && isObjectNotEmpty(updateNotationData)) {
      try {
        const res = await __SearchUser(
          environment.GUARDAR_CORRECION_REVISION,
          token,
          templateData
        );
        const status = res?.data?.status;
        if (status.code !== 200) {
          openAlert(status?.message, "error");
        } else {
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    return async () => {
      setCurrentItemLength(0);
      const body = new FormData();
      body.append("form_id", FormEntryNumber);
      body.append("type", 0);
      body.append("type_of", "FMMI");
      const res = await __Post(environment.FORMS_ON_EDIT, token, body);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isReentryOperation = formOperation?.reentry_operation === 0;
    const isCommercialInvoiceEmpty =
      !values?.num_commercial_invoice ||
      values?.num_commercial_invoice?.length === 0;

    if (
      isReentryOperation &&
      isCommercialInvoiceEmpty &&
      currentItemLength > 0
    ) {
      return openAlert(
        "El campo Nº Factura comercial es obligatorio.",
        "error"
      );
    }

    if (!valueThird && currentItemLength > 0) {
      return openAlert(
        "El campo Proveedor/Exportador es obligatorio.",
        "error"
      );
    }

    if (
      selectItemForm >= 700 &&
      selectItemForm <= 799 &&
      !values?.num_exit_form
    )
      return openAlert(
        "El campo Nº Autorización de Salida es obligatorio.",
        "error"
      );

    if (
      formOperation?.reentry_operation === 1 &&
      !(
        !/^(?:[0-46-9]\d{2}|[0-9]{1,2})$/.test(selectItemForm) ||
        selectItemForm === 120 ||
        (selectItemForm >= 700 && selectItemForm <= 799)
      ) &&
      !values?.integration_box_certificate &&
      !values?.num_exit_form &&
      location.search
    ) {
      return openAlert(
        "Los campos Nº Autorización de Salida y Nº Autorización de C. Inte se encuentran vacíos es obligatorio llenar al menos uno de ellos.",
        "error"
      );
    }

    if (FormStatus === "DEVUELTO") {
      saveCorrectionReview();
    }

    // console.log(values, "values");
    const formData = new FormData();
    isSelectedAllItems && formData.append("all_item", true);
    isArray(itemsSelected) &&
      itemsSelected.length !== 0 &&
      itemsSelected.forEach((i) => formData.append("array_item[]", i.item));
    formData.append("operation_id", selectIdOp || "");
    formData.append("department_id", valueDepartament || "");
    formData.append("thirdPartie_id", valueThird ?? "");
    formData.append("shipment_entryForm", selectUF || "");
    // formData.append("national_or_foreign", selectNE ? selectNE : ""); // cambiar a item y validar antes de presentar en las 500 que seleccione una naturaleza
    formData.append("check_rm", typeEntryCurrent);

    if (typeEntryCurrent === "DTA") {
      formData.append(
        "dcl_customs_transit",
        values.dcl_customs_transit ? values.dcl_customs_transit : ""
      );
      formData.append(
        "transit_expiration_date",
        values.transit_expiration_date ? values.transit_expiration_date : ""
      );
      formData.append("shipping_form", "");
      formData.append("cabotage", "");
      formData.append(
        "shipping_form_expiration_date",
        values?.shipping_form_expiration_date || ""
      );
      formData.append("cabotage_expiration_date", "");
    } else if (typeEntryCurrent === "Cabotaje") {
      formData.append("cabotage", values.cabotage || "");
      formData.append(
        "cabotage_expiration_date",
        values?.cabotage_expiration_date || ""
      );
      formData.append("shipping_form", "");
      formData.append("shipping_form_expiration_date", "");
      formData.append("dcl_customs_transit", "");
      formData.append("transit_expiration_date", "");
    }

    values?.integration_box_certificate &&
      formData.append(
        "integration_box_certificate",
        values?.integration_box_certificate
      );
    values?.num_exit_form &&
      formData.append("num_exit_form", values?.num_exit_form);

    formData.append(
      "associated_temporary_output",
      values.associated_temporary_output
        ? values.associated_temporary_output
        : ""
    );

    formData.append(
      "num_transport_document",
      values.num_transport_document ? values.num_transport_document : ""
    );
    formData.append(
      "purchase_order",
      values.purchase_order ? values.purchase_order : ""
    );
    formData.append(
      "transp_doc_date",
      values.transp_doc_date ? values.transp_doc_date : ""
    );
    formData.append(
      "num_commercial_invoice",
      values.num_commercial_invoice ? values.num_commercial_invoice : ""
    );

    /* if (authorizationS || values?.num_exit_form) {
      formData.append(
        'num_exit_form',
        authorizationS || values?.num_exit_form || ''
      )
    } */

    formData.append("portmagdalena", portM ? 1 : 0);
    formData.append("inspection", inspeccion ? 1 : 0);
    formData.append(
      "cargo_manifest_entryForm",
      values?.cargo_manifest_entryForm || ""
    );

    /* formData.append(
      'integration_box_certificate',
      authorizationNCI === null
        ? ''
        : authorizationNCI || values.integration_box_certificate || ''
    ) */

    formData.append(
      "inspection_act_or_auto",
      values?.inspection_act_or_auto || ""
    );
    // formData.append(
    //   "customs_administration",
    //   values.customs_administration ? values.customs_administration : ""
    // );
    formData.append(
      "customAdministration_id",
      customsadministration?.value || ""
    );
    formData.append("dex_date_entryForm", values?.dex_date_entryForm || "");
    formData.append("num_dex_entryForm", values?.num_dex_entryForm || "");
    formData.append(
      "num_import_declaration",
      values?.num_import_declaration || ""
    );

    if (valueQualifiedForm && valueQualifiedForm.value !== null) {
      formData.append("qualifiedUser2_id", valueQualifiedForm?.value ?? "");
    }

    if (valueQualified) {
      formData.append("qualifiedUser_id", valueQualified ?? "");
    }

    if (files) {
      Object.keys(files).forEach((documentId) => {
        if (!files[documentId].from_server) {
          formData.append(
            "attachment[" + documentId + "]",
            JSON.stringify(files[documentId])
          );
        }
      });
    }

    if (otherAttachmentNumber) {
      otherAttachmentNumber.forEach((e) => {
        formData.append("attachment_number[]", e);
      });
    }

    if (attachmentId) {
      attachmentId.forEach((e) => {
        formData.append("attachment_id[]", e);
      });
    }

    if (attachmentDescription) {
      attachmentDescription.forEach((e) => {
        if (e !== "null" && e !== null && e !== undefined) {
          formData.append("attachment_description[]", e);
        }
      });
    }

    if (attachmentNumber) {
      attachmentNumber.forEach((e) => {
        formData.append("attachment_number[]", e);
      });
    }

    /*
    return console.log({
       data: formData.entries().reduce((a, [k, v]) => ({ ...a, [k]: v }), {}),
       integration_box_certificate: values?.integration_box_certificate,
       num_exit_form: values?.num_exit_form,
       integration_box_certificate: values?.integration_box_certificate,
       num_exit_form: values?.num_exit_form,
       codeForm: selectItemForm,
     }) */

    if (dataToEdit !== null || FormEntryNumber) {
      if (
        values.num_commercial_invoice?.length < 4 &&
        values.num_commercial_invoice !== "" &&
        values.num_commercial_invoice !== null &&
        values.num_commercial_invoice !== undefined &&
        values.num_commercial_invoice !== 0
      ) {
        openAlert(
          "La factura comercial no puede tener menos de 4 caracteres",
          "error"
        );
      } else {
        if (!selectIdOp) {
          openAlert(
            `El formulario presenta errores. Verifique los campos marcados.`,
            "error"
          );
          setErrorsState({ opertationId: true });
        } else {
          formData.append("id", FormEntryNumber);
          setErrorsState({ opertationId: false });
          editForm(formData, true, () => {
            handleReset();
            reset();
          });
          /* handleReset()
          reset() */
        }
      }
    } else {
      if (
        values.num_commercial_invoice &&
        values.num_commercial_invoice.length < 4
      ) {
        openAlert(
          "La factura comercial no puede tener menos de 4 caracteres",
          "error"
        );
      } else {
        if (!selectIdOp) {
          setErrorsState({ opertationId: true, thirdPartie_id: true });
          openAlert(
            `El formulario presenta errores. Verifique los campos marcados.`,
            "error"
          );
        } else {
          setErrorsState({ opertationId: false, thirdPartie_id: false });
          createForm(formData, () => {
            handleReset();
            reset();
          });
          // handleReset();
          // reset();
        }
      }
    }
  };

  const handleReset = () => {
    setValueQuialified(null);
    setNameQualified([]);
    setValueThird([]);
    setNameThird([]);
    setSelectItemForm([]);
    setNameOperation([]);
    setDataToEdit(null);
    setFiles({});
    setAttachment([]);
    setAttachmentType([]);
    setDataToEdit(null);
    setCustomsadministration(null);
    setSelectIdOp(null);
    setDocumentOperation([]);
    // setTypeEntryCurrent(null);
    reset();
  };

  const handleFiles = (e, idFiles) => {
    e.preventDefault();
    document.getElementById(`${idFiles}`).click();
  };

  const extensionOptions = [
    ".doc",
    ".docx",
    ".pdf",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".xls",
    ".xlsx",
    ".xlsm",
    "image/png",
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
  ];

  const handleFilesInput = async (e) => {
    try {
      e.preventDefault();
      const resModal = await onOpenUpload();
      const { name, files: uploadedFiles, id } = e.target;
      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);
      if (!isValidExtension) return;

      const idDocument = extractDocumentId(id);
      setLoadingSubmit(true);
      const uploadResponse = await uploadFile(uploadedFiles[0]);

      updateFileState(
        uploadResponse,
        uploadedFiles[0]?.name,
        idDocument,
        e.target.id
      );
      setLoadingSubmit(false);
      updateNameFilesState(name);
      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
      setLoadingSubmit(false);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  const { get: getStorage } = Storage;

  const updateFileState = (uploadResponse, name, idDocument, targetId) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};
    const file = {
      [targetId]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file);

    return localStorage.removeItem("uploadData");
  };

  const updateNameFilesState = (name) => {
    setNameFiles((prevNameFiles) => [...prevNameFiles, { name }]);
  };

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormEntryNumber}`) || "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${FormEntryNumber}`,
        JSON.stringify(filteredErrors)
      );
    }
  };

  const removeItemFromArr = (archivo, nombre, server = false) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${files?.[archivo]?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (files[archivo] !== undefined && files[archivo] !== null) {
          const obj = Object.assign({}, { ...files });
          if (obj[archivo] !== undefined && obj[archivo] !== null) {
            delete obj[archivo];
            if (files[archivo].from_server) {
              deleteAttachment(
                environment.DELETE_ATTACHMENT_ENTRY,
                files[archivo].id,
                "",
                setFiles,
                obj,
                () => refetch()
              );
            } else {
              setFiles(obj);
            }
          }
        }
      }
    });
  };

  const defaultSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (
      (ProviderExporter.label !== nameQualified &&
        ProviderExporter.value !== valueQualified) ||
      !ProviderExporter.label ||
      !ProviderExporter.value
    ) {
      setNameThird("");
      setValueThird("");
    }
  }, [valueQualified, nameQualified]);

  const idForm = FormExitNumber || FormEntryNumber;

  useEffect(() => {
    if (idForm) setFormId(idForm);
    if (idForm && Reviews?.[idForm]) setReviewsData(Reviews?.[idForm]);
  }, [idForm, Reviews?.[idForm]]);

  useEffect(() => {
    return async () => dispatchRedux(clearFormData());
  }, []);

  const notationsDestruct = useNotationInput({
    storageKey: idForm,
    notationTypes: [
      {
        color: "gray",
        enableComment: false,
      },
      {
        color: "green",
        enableComment: false,
      },
      {
        color: "#fc0a0a",
        enableComment: true,
      },
    ],
    reviews: Reviews,
    reviewsUpdates: values.review_updates,
  });

  const { nameDocument } = useGlobalContext();

  const getAuthToken = async () => {
    return token;
  };

  const cleanFile = (file) => {
    console.log("cleanFile", file);
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ??
        file?.name_attachment ??
        file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const requestData = {
          Endpoint: environment.DELETE_OTHER_ATTACHMENT_ENTRY,
          resourceIdentifier: file?.id,
          //  PropertyBody: "sendFormDataContentPOST",
        };

        mutate(requestData, {
          onSuccess: () => {
            // setAttached([]);
            setOtherDocuments([]);
            refetch();
          },
        });
      }
    });
  };

  const documentsBeforeCreate = () => {
    const { commercial_invoice_enabled, commercial_invoice_id } = Object.keys(selectedQualifiedUser).length ? selectedQualifiedUser : authUser;
    
    if (!commercial_invoice_enabled) return [];

    const documents = DocumentOperation.filter((document) => document.id === commercial_invoice_id);

    return documents;
  }

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={"lg"}
      >
        <GroupInputUpload onClose={onCloseUpload} />
      </ModalGeneric>
      {values?.num_exit_form && isOpenSelectAuthorizationItems && (
        <AddItemsReentryOperation
          isOpen={isOpenSelectAuthorizationItems}
          onOpen={onOpenSelectAuthorizationItems}
          onClose={onCloseSelectAuthorizationItems}
          dataFetch={{
            num_exit_form: values?.num_exit_form,
            ...(authUser.is_qualified_user !== 1 && {
              qualifiedUser_id: valueQualified || values.qualifiedUser_id,
            }),
            ...(FormEntryNumber && { entryForm_id: FormEntryNumber }),
          }}
        />
      )}

      {loading && <SpinnerComponent />}
      {!loading && (
        <form className="line" onSubmit={defaultSubmit}>
          {/* Datos de Empresa */}
          <DataCompany
            values={values}
            handleInputChange={handleInputChange}
            id={FormEntryNumber}
            errorsState={errorsState}
          />

          {/* Retorno de Formulario */}
          <div className="mt-5">
            <ContainerForms
              formOperation={formOperation}
              code={selectItemForm}
              values={values}
              handleInputChange={handleInputChange}
              setValues={setValues}
              reviewsNotations={notationsDestruct}
            />
          </div>

          {/* Tabla */}
          {(dataToEdit !== null || FormEntryNumber) && (
            <ViewFormEntry
              code={selectItemForm}
              valuesHeader={values}
              codeForm={values?.code_entryForm}
              idQualified={values?.qualifiedUser_id}
            />
          )}

          {/* Botón para añadir el documento o archivo de la factura comercial antes de creación del formulario  */}
          {(!FormEntryNumber && documentsBeforeCreate().length > 0) && (
            <>
              <HeaderSearch>
                {documentsBeforeCreate().map((document, index) => (
                  <div key={index}>
                    <DocumentUploader
                      attachmentId={attachmentId}
                      setAttachmentId={setAttachmentId}
                      attachmentDescription={attachmentDescription}
                      setAttachmentDescription={setAttachmentDescription}
                      attachmentNumber={attachmentNumber}
                      setAttachmentNumber={setAttachmentNumber}
                      document={document}
                      handleFiles={handleFiles}
                      handleFilesInput={handleFilesInput}
                      handleDelete={removeItemFromArr}
                      files={files}
                      setNameFiles={setNameFiles}
                      nameFiles={nameFiles}
                      index={index}
                      idFmm={FormExitNumber}
                      documentPreview={false}
                      updateAndCreate={updateAndCreate}
                      callBack={editUploadFiles}
                    />
                  </div>
                ))}
              </HeaderSearch>
            </>
          )}

          {/* Botones Añadir documentos */}
          {FormEntryNumber && (
            <>
              <HeaderSearch>
                {DocumentOperation.map((document, index) => (
                  <div key={index}>
                    <DocumentUploader
                      attachmentId={attachmentId}
                      setAttachmentId={setAttachmentId}
                      attachmentDescription={attachmentDescription}
                      setAttachmentDescription={setAttachmentDescription}
                      attachmentNumber={attachmentNumber}
                      setAttachmentNumber={setAttachmentNumber}
                      document={document}
                      handleFiles={handleFiles}
                      handleFilesInput={handleFilesInput}
                      handleDelete={removeItemFromArr}
                      files={files}
                      setNameFiles={setNameFiles}
                      nameFiles={nameFiles}
                      index={index}
                      idFmm={FormExitNumber}
                      documentPreview={false}
                      updateAndCreate={updateAndCreate}
                      callBack={editUploadFiles}
                    />
                  </div>
                ))}
              </HeaderSearch>
            </>
          )}
          {FormEntryNumber && (
            <>
              <HeaderSearch>
                <Box display={"grid"} gap={4} pt={12}>
                  <EnhancedFileUploadManager
                    title="Otros documentos"
                    onUploadComplete={() => {}}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    endpointAttachments={{
                      create: environment.UPDATE_FORM_ENTRY,
                    }}
                    postPropertyName={"other_attachment"}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={getAuthToken}
                    formID={idForm}
                  />

                  <ExistingFiles
                    existingFiles={otherDocuments?.other_files}
                    // isLoading={isPendingViewPass}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
              </HeaderSearch>
            </>
          )}

          <Box
            display="flex"
            alignItems="center"
            marginTop={"20px"}
            marginBottom={"20px"}
            justifyContent="flex-end"
            flexWrap={"wrap"}
          >
            <ButtonStyled
              isLoading={isPending ||loadingSubmit}
              type="submit"
              onClick={handleSubmit}
            >
              Guardar
            </ButtonStyled>
          </Box>
        </form>
      )}
    </>
  );
};
export default BodyForm;
