import environment from "constants/apiConst";

export const TitleUser = [
  { name: "Usuario", order: null },
  { name: "Nit", order: null },
  { name: "Factura de venta", order: null },
  { name: "Saldo Recargado", order: null },
  { name: "Método de pago", order: null },
  { name: "Saldo actual", order: null },
  { name: "Última recarga", order: null },
  { name: "Usuario", order: null },
  { name: "Estado", order: null },
  { name: "Acciones", order: null },
];

export const TitleExtract = [
  { name: "Nit", order: null },
  { name: "Empresa", order: null },
  { name: "Csc", order: null },
  { name: "Tipo", order: null },
  { name: "Documento", order: null },
  { name: "Nº Anexos", order: null },
  { name: "Anexos Cobrado", order: null },
  { name: "Vr. anexo", order: null },
  { name: "Precisión", order: null },
  { name: "Usuario", order: null },
  { name: "Fecha", order: null },
  { name: "Saldo anterior", order: null },
  { name: "Valor", order: null },
  { name: "Saldo actual", order: null },
  { name: "Estado", order: null },
  { name: "Observación", order: null },
];

export const TitleSales = [
  { name: "Nit", order: null },
  { name: "Empresa", order: null },
  { name: "Factura", order: null },
  { name: "CSC", order: null },
  { name: "Tipo", order: null },
  { name: "Fecha", order: null },
  { name: "Usuario", order: null },
  { name: "Valor de venta", order: null },
  { name: "Valor consumible", order: null },
  { name: "Saldo actual", order: null },
  { name: "Nuevo saldo", order: null },
  /* { name: "Saldo pesaje", order: null }, */
  /* { name: "Saldo documento", order: null }, */
  { name: "Método de pago", order: null },
  { name: "Estado", order: null },
  { name: "Estado dependencia", order: null },
  { name: "Ver ajustes", order: null },
  { name: "Acciones", order: null },
];

export const TitleTaxes = [
  { name: "Tasa", order: null },
  { name: "Fecha", order: null },
  { name: "Usuario", order: null },
  { name: "Observación", order: null },
  { name: "Vigencia", order: null },
];

export const TitleWehithing = [
  { name: "Consecutivo", order: null },
  { name: "Fecha", order: null },
  { name: "Placa", order: null },
  { name: "Horario", order: null },
  { name: "Operación", order: null },
  { name: "Valor", order: null },
  // { name: "Descripción", order: null },
  { name: "Peso neto", order: null },
  { name: "Peso bruto", order: null },
  { name: "Operario ", order: null },
  { name: "Peso tara", order: null },
  { name: "Bascula tara", order: null },
  { name: "Completitud", order: null },
  { name: "Contenedores", order: null },
  { name: "Nº. Formularios", order: null },
];

export const theadContainerTreasury = [
  { name: "Contenedor 1", order: "" },
  { name: "Contenedor 2", order: "" },
  { name: "Capacidad 1", order: "" },
  { name: "Capacidad 2", order: "" },
  { name: "Tara 1", order: "" },
  { name: "Tara 2", order: "" },
  { name: "Conductor", order: "" },
];
export const TitleUpdate = [
  { name: "Fecha", order: null },
  { name: "Tipo de tarifa", order: null },
  { name: "Usuario", order: null },
  { name: "", order: null },
  { name: "Acciones", order: null },
];

export const TitleUpdateRecord = [
  { name: "Fecha", order: null },
  { name: "Tipo de tarifa", order: null },
  { name: "Usuario", order: null },
  { name: "", order: null },
];

export const TitleViewUpdate = [
  { name: "Ingreso", order: null },
  { name: "Anexo de ingreso", order: null },
  { name: "Salida", order: null },
  { name: "Anexo de salida", order: null },
  { name: "Corregido de ingreso", order: null },
  { name: "Anexo corregido de ingreso", order: null },
  { name: "Corregido de salida", order: null },
  { name: "Anexo corregido de salida", order: null },
  { name: "Corregido de certificado de integración", order: null },
  { name: "Anexo corregido certificado de integración", order: null },
  { name: "Pase de entrada", order: null },
  { name: "Anexos pases de entrada", order: null },
  { name: "Certificado de integración", order: null },
  { name: "Matriz de insumo", order: null },
  { name: "Declaración de importación", order: null },
  { name: "Despacho agrupado", order: null },
  { name: "Pesaje en báscula", order: null },
  { name: "Certificado de operaciones de comercio exterior", order: null },
  { name: "Certificado de Usuario", order: null },
  { name: "Fecha", order: null },
  { name: "Usuario", order: null },
  { name: "Estado", order: null },
];

export const TitleSpecialRate = [
  { name: "Nit", order: null },
  { name: "Empresa", order: null },
  { name: "Fecha", order: null },
  { name: "Tipo de tarifa", order: null },
];

export const TitleIncidents = [
  { name: "Nit", order: null },
  { name: "U.C", order: null },
  { name: "Tipo", order: null },

  { name: "Saldo actual", order: null },
  { name: "Valor recargado", order: null },
  { name: "Nuevo saldo ", order: null },
  { name: "Observación", order: null },
  { name: "Fecha", order: null },
];

export const TitleActiveItems = [
  { name: "Descripción del producto", order: null },
  { name: "Cantidad de producto ", order: null },
  { name: "Fecha de  creación ", order: null },
  { name: "Usuario", order: null },
];

export const TitleActiveDelete = [
  { name: "Descripción del producto", order: null },
  { name: "Cantidad de producto ", order: null },
  { name: "Fecha eliminado ", order: null },
  { name: "Usuario", order: null },
];

export const arrayRigthTreasuryUser = [
  {
    value: "observations",
    label: "Observación",
    name: "observations",
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date",
  },
];

export const arrayProductNodeList = [
  {
    value: "description",
    label: "Descripción",
  },

  {
    value: "code_product",
    label: "Código producto",
  },
  {
    value: "code_subheading",
    label: "Subpartida",
  },
  {
    value: "business_unit",
    label: "Unidad",
  },
  {
    value: "convertion",
    label: "Factor de Conversión",
  },

  {
    value: "group",
    label: "Grupo",
  },
  {
    value: "name_qualified",
    alt: "nit_qualified",
    label: "Usuario calificado",
    search: "qualifiedUser_id",
    modal: {
      options: ["Todos", "Único", "Búsqueda"],
      value: ["Todos"],
    },
  },
  {
    value: "status_product",
    alt: "status_product",
    label: "Estado del producto",
    search: "status_product",
    modal: {
      options: ["Activo ", "Inactivo"],
      value: [1],
    },
  },
  {
    value: 'create_date',
    label: 'Fecha de creación',
  },
  {
    value: 'create_time',
    label: 'Hora de creación',
  },
  {
    value: 'update_date',
    label: 'Fecha modificación',
  },
  {
    value: 'update_time',
    label: 'Hora modificación',
  },
  {
    value: 'user_nickname_create',
    label: 'Usuario creación'
  },
  {
    value: 'user_nickname_update',
    label: 'Usuario modificación'
  },
  {
    value: 'person_name_create',
    label: 'Nombre persona creación'
  },
  {
    value: 'person_name_update',
    label: 'Nombre persona modificación'
  }
];


export const productsNodeInputFields = [
  {
    type: "select",
    typeSelect: "single",
    name: "without_quantity",
    tag: "Incluir sin saldo",
    parent: "without_quantity",
    search: "without_quantity",
    data: [
      { value: "1", label: "Si" },
      { value: "0", label: "No" },
    ],
    value: null,
    size: 100,
  },
  {
    isQualifiedUser: true,
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    parent: "name_qualified",
    search: "qualifiedUser_id",
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.id, label: item?.name_qualified, decimal: item?.decimal_values_precision }))
    }
  },
  {
    type: "select",
    typeSelect: "single",
    name: "status_product",
    tag: "Estado",
    parent: "status_product",
    search: "status_product",
    data: [
      { value: 1, label: "Activo" },
      { value: 0, label: "Inactivo" },
    ],
    value: { value: 1, label: "Activo" },
    size: 100,
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" },
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100,
  },
];

export const productiveProcessNodeInputFields = [
  {
    type: "select",
    typeSelect: "multi",
    name: "qualifiedUser_id",
    tag: "Usuario calificado",
    search: "qualifiedUser_id",
    alwaysVisible: true,
    request: {
      endpoint: environment.GET_QUALIFIED_USERS_ALL,
      queryKey: "getUserQualified",
      dataSanitizer: (data) =>
        data?.map((item) => ({
          value: item?.id,
          label: item?.name_qualified,
          decimal: item?.decimal_values_precision,
        })),
    },
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" },
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100,
  },
];

export const LeftdataFields = [
  {
    value: "description",
    label: "Descripción",
    name: "description",
  },

  {
    value: "code_product",
    label: "Código producto",
    name: "code_product",
  },
  {
    value: "subheading",
    label: "Subpartida",
    name: "subheading",
  },
  {
    value: "business_unit",
    label: "Unidad",
    name: "business_unit",
  },
];

export const RightdataFields = [
  {
    value: "convertion",
    label: "Factor de Conversión",
    name: "convertion",
  },

  {
    value: "group",
    label: "Grupo",
    name: "group",
  },

  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "name_qualified",
  },
];

export const arrayRigthTreasury = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified",
  },
  {
    value: "observations",
    label: "Observación",
    name: "observations",
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date",
  },

  {
    value: "payment_method",
    label: "Método de pago",
    name: "payment_method",
  },
];

export const arrayRigthUserTreasury = [
  {
    value: "observations",
    label: "Observación",
    name: "observations",
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date",
  },

  {
    value: "payment_method",
    label: "Método de pago",
    name: "payment_method",
  },
];



export const salesOfServiceNodeInputFields = [
  {
    type: "date",
    name: "date_start",
    tag: "Fecha inicial",
    parent: "date",
  },
  {
    type: "date",
    name: "date_end",
    tag: "Fecha final",
    parent: "date",
  },
  {
    type: "select",
    typeSelect: "single",
    name: "type_export_file",
    tag: "Extensión del documento",
    data: [
      { value: "xlsx", label: "xlsx" },
      { value: "csv", label: "csv" }
    ],
    value: { value: "xlsx", label: "xlsx" },
    alwaysVisible: true,
    size: 100
  }
]

export const arrayLeftTreasury = [
  {
    value: "sale_value",
    label: "Valor de venta",
    name: "sale_value",
  },
  {
    value: "invoice",
    label: "Numero de factura",
    name: "invoice",
  },
  {
    value: "type_recharge",
    label: "Tipo de recarga",
    name: "type_recharge",
  },
  {
    value: "consumable_value",
    label: "Valor Consumible",
    name: "consumable_value",
  },
];

export const arrayRigthTreasuryExtractUser = [
  {
    value: "annexe_observations",
    label: "Observación",
    name: "annexe_observations",
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date",
  },
  {
    value: "treasury_charges.document",
    label: "Nombre del documento",
    name: "treasury_charges.document",
  },
  {
    value: "value",
    label: "Valor de la operación",
    name: "value",
  },

  {
    value: "annexes_collected",
    label: "Anexos cobrados",
    name: "annexes_collected",
  },
];

export const arrayRigthTreasuryExtract = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified",
  },
  {
    value: "annexe_observations",
    label: "Observación de anexos",
    name: "annexe_observations",
  },
  {
    value: "date",
    label: "Rango de fecha",
    name: "date",
  },
  {
    value: "treasury_charges.document",
    label: "Nombre del documento",
    name: "treasury_charges.document",
  },
  {
    value: "value",
    label: "Valor de la operación",
    name: "value",
  },

  {
    value: "annexes_collected",
    label: "Anexos cobrados",
    name: "annexes_collected",
  },
];

export const arrayLeftTreasuryExtract = [
  {
    value: "typeRate_id",
    label: "Tipo de tarifa",
    name: "typeRate_id",
  },

  {
    value: "code",
    label: "Código del documento",
    name: "code",
  },
  {
    value: "annexes",
    label: "Anexos",
    name: "annexes",
  },
  {
    value: "old_balance",
    label: "Saldo del usuario calificado antes de operar",
    name: "old_balance",
  },
  {
    value: "balance",
    label: "Saldo del usuario calificado",
    name: "balance",
  },

  {
    value: "treasury_charges.status ",
    label: "Estado",
    name: "treasury_charges.status ",
  },

  {
    value: "treasury_charges.observations",
    label: "Observación",
    name: "treasury_charges.observations",
  },
];

export const arrayLeftTreasuryExtractUser = [
  {
    value: "typeRate_id",
    label: "Tipo de tarifa",
    name: "typeRate_id",
  },

  {
    value: "code",
    label: "Código del documento",
    name: "code",
  },
  {
    value: "annexes",
    label: "Anexos",
    name: "annexes",
  },

  {
    value: "status ",
    label: "Estado",
    name: "status ",
  },

  {
    value: "observations ",
    label: "Observación",
    name: "observations ",
  },
];

export const cabeceraHitory = ["08-08-2022", "09-08-2022"];

export const trasuryWeigthSelectNodeList = [
  {
    type: "number",
    name: "consecutive",
    tag: "Consecutivo",
    parent: "consecutive",
    search: "consecutive",
    inputParams: {
      name: "consecutive",
      tag: "Consecutivo",
      allowNegative: false,
      thousandSeparator: false,
      decimalScale: 0,
    }
  },
  {
    type: "number",
    name: "formNumber",
    tag: "Número de pase",
    parent: "formNumber",
    search: "formNumber",
    inputParams: {
      name: "formNumber",
      tag: "Número de pase",
      allowNegative: false,
      thousandSeparator: false,
      decimalScale: 0,
    }
  },
  {
    type: "text",
    name: "vehiclePlate",
    tag: "Placa del vehículo",
    parent: "vehiclePlate",
    search: "vehiclePlate",
    /* inputParams: {
      name: "vehiclePlate",
      tag: "Placa del vehículo",
      allowNegative: false,
      thousandSeparator: false,
      decimalScale: 0,
    }, */
  },
  {
    type: "select",
    typeSelect: "single",
    name: "formType",
    tag: "Tipo de Operación",
    parent: "formType",
    search: "formType",
    data: [
      { value: 1, label: "Despacho" },
      { value: 2, label: "Entrada" }
    ],
    value: null,
    size: 100,
  },
  {
    type: "select",
    typeSelect: "single",
    name: "order",
    tag: "Ordenar por",
    parent: "order",
    search: "order",
    data: [
      { value: "asc", label: "Ascedente" },
      { value: "desc", label: "Desendente" }
    ],
    value: null,
    size: 100,
  },
  {
    type: "select",
    typeSelect: "single",
    name: "userManagment",
    tag: "Usuario de pesaje",
    parent: "userManagment",
    search: "userManagment",
    request: {
      endpoint: environment.TREASURY_WEIGHING_USER,
      queryKey: "USER_WEIGHING",
      method: "POST",
      dataSanitizer: (data) => data?.map(item => ({ value: item?.user_id, label: item?.nickname, ...item }))
    }
  },
  {
    type: "date",
    name: "date_1",
    search: "date1",
    tag: "Fecha inicial",
    parent: "date",
  },
  {
    type: "date",
    name: "date_2",
    search: "date2",
    tag: "Fecha final",
    parent: "date",
  },
]

export const trasuryWeigthNodeSelectList = [
  {
    value: "weighings.vehicle_plate",
    label: "Placa de vehículo",
    name: "weighings.vehicle_plate",
  },
  {
    value: "net_weight",
    label: "Peso Neto",
    name: "net_weight",
  },
  {
    value: "gross_weight",
    label: "Peso Bruto",
    name: "gross_weight",
  },
  {
    value: "weighings.tare_weight",
    label: "Peso Tara",
    name: "weighings.tare_weight",
  },
  {
    value: "operation",
    label: "Operación",
    name: "operation",
  },

  {
    value: "userManagment.nickname",
    label: "Usuario",
    name: "userManagment.nickname",
  },

  {
    value: "nickname",
    label: "Usuarios gestión pesaje",
    name: "nickname",
  },

  {
    value: "containers",
    label: "Containers ",
    name: "containers",
  },
  {
    value: "weighings.weight_date",
    label: "Fecha del pesaje",
    name: "weighings.weight_date",
  },
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified",
  },

  {
    value: "consecutive",
    label: "Consecutivo",
    name: "consecutive",
  },

  {
    value: "formNumber",
    label: "Número de pase",
    name: "formNumber",
  },
  {
    value: "vehiclePlate",
    label: "Búsqueda placa del vehículo ",
    name: "vehiclePlate",
  },
  {
    value: "formType",
    label: "Tipo Operación",
    name: "formType",
  },
  {
    value: "order",
    label: "Orden",
    name: "order",
  },

  {
    value: "userManagment",
    label: "Búsqueda usuario pesaje",
    name: "userManagment",
  },

  {
    value: "date",
    label: "Búsqueda por fecha de pesaje",
    name: "date",
  },
]

export const arrayLeftTreasuryWeigth = [
  {
    value: "weighings.vehicle_plate",
    label: "Placa de vehículo",
    name: "weighings.vehicle_plate",
  },
  {
    value: "net_weight",
    label: "Peso Neto",
    name: "net_weight",
  },
  {
    value: "gross_weight",
    label: "Peso Bruto",
    name: "gross_weight",
  },
  {
    value: "weighings.tare_weight",
    label: "Peso Tara",
    name: "weighings.tare_weight",
  },
  {
    value: "operation",
    label: "Operación",
    name: "operation",
  },

  {
    value: "userManagment.nickname",
    label: "Usuario",
    name: "userManagment.nickname",
  },

  {
    value: "nickname",
    label: "Usuarios gestión pesaje",
    name: "nickname",
  },

  {
    value: "containers",
    label: "Containers ",
    name: "containers",
  },
  {
    value: "weighings.weight_date",
    label: "Fecha del pesaje",
    name: "weighings.weight_date",
  },
];

export const arrayRigthTreasuryWeigth = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified",
  },

  {
    value: "consecutive",
    label: "Consecutivo",
    name: "consecutive",
  },

  {
    value: "formNumber",
    label: "Número de pase",
    name: "formNumber",
  },
  {
    value: "vehiclePlate",
    label: "Búsqueda placa del vehículo ",
    name: "vehiclePlate",
  },
  {
    value: "formType",
    label: "Tipo Operación",
    name: "formType",
  },
  {
    value: "order",
    label: "Orden",
    name: "order",
  },

  {
    value: "userManagment",
    label: "Búsqueda usuario pesaje",
    name: "userManagment",
  },

  {
    value: "date",
    label: "Búsqueda por fecha de pesaje",
    name: "date",
  },
];
