import { useCallback, useContext, useEffect, useState } from "react";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { __PostForm1 } from "request/Petitions/__Post";
import { MaxLength } from "utils/MaxLength";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconButton } from "@chakra-ui/react";
import { VscClose } from "react-icons/vsc";
import GlobalContext from "context/GlobalContext";

const useSupplyDataOverlay = ({ token, apiUrl, isEnabled }) => {
  const { precisionManual } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [activeReference, setActiveReference] = useState(null);
  const [activeId, setActiveId] = useState(null);
/*   const { decimal_values_precision } = useSelector(
    (state) => state.auth.dataUser
  );
 */
  const fetchSupplyData = async ({ queryKey }) => {
    const [_, reference, id] = queryKey;
    if (!reference || !isEnabled) {
      throw new Error("Reference is required or fetching is disabled");
    }

    const formData = new FormData();
    formData.append("reference", reference);
    formData.append("integration_certificate_id", id);

    const response = await __PostForm1(apiUrl, token, formData);
    return response.data.status.data;
  };

  const {
    data: supplyData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["supplyData", activeReference, activeId],
    queryFn: fetchSupplyData,
    enabled: isOpen && !!activeReference && !!activeId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
  });

  const handleMouseEnter = useCallback(
    (event, reference, id) => {
      if (!isEnabled || isOpen) return;

      const element = event.currentTarget;
      const rect = element.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX,
        top: rect.bottom + window.scrollY,
      });
      setIsOpen(true);
      setActiveReference(reference);
      setActiveId(id);
    },
    [isEnabled, isOpen]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setActiveReference(null);
    setActiveId(null);
  }, []);

  const SupplyDataOverlay = () =>
    isOpen && (
      <div
        style={{
          position: "absolute",
          left: `${position.left}px`,
          top: `${position.top}px`,
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <IconButton
          icon={<VscClose />}
          onClick={handleClose}
          position="absolute"
          right="5px"
          top="5px"
          size="sm"
          border={"none"}
          variant="ghost"
          color="white"
          _hover={{ bg: "whiteAlpha.200" }}
          aria-label="Close overlay"
        />
        {isLoading ? (
          <SpinnerComponent />
        ) : error ? (
          <div style={{ color: "red" }}>{error}</div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              <LabelGroup
                name="Subpartida"
                value={supplyData?.code_subheading ?? "N/A"}
                tag="Subpartida"
                size={19}
                desicion={true}
                color="white"
                style={{ color: "white" }}
                colorLabel=""
                maxLength={MaxLength?.global?.code_subheading}
              />
              <LabelGroup
                name="Certificado"
                value={supplyData?.integration_certificate ?? "N/A"}
                tag="Certificado"
                size={19}
                color="white"
                style={{ color: "white" }}
                colorLabel=""
                desicion={true}
              />
              <LabelGroup
                name="Decl de Imp"
                value={supplyData?.import_declaration ?? "N/A"}
                tag="Decl de Imp"
                size={39}
                desicion={true}
                color="white"
                style={{ color: "white" }}
                colorLabel=""
              />
              <LabelGroup
                name="Oper."
                value={supplyData?.operation_code ?? "N/A"}
                tag="Oper."
                size={9}
                desicion={true}
                color="white"
                style={{ color: "white" }}
                colorLabel=""
              />
              <LabelGroup
                name="Inicial"
                value={supplyData?.initial_operation ?? "N/A"}
                tag="Inicial"
                size={9}
                desicion={true}
                color="white"
                style={{ color: "white" }}
                colorLabel=""
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                color: "white",
                marginTop: "10px",
              }}
            >
              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={precisionManual || 4}
                decimalSeparator="."
                customInput={LabelGroup}
                name={"quantity_available"}
                value={supplyData?.quantity_available ?? "N/A"}
                tag="Saldo Disponible"
                size={24}
                desicion={true}
                maxLength={34}
                style={{ color: "white" }}
                colorLabel=""
              />

              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={precisionManual || 4}
                decimalSeparator="."
                customInput={LabelGroup}
                name={"value_cif"}
                value={supplyData?.value_cif ?? "N/A"}
                tag="Saldo en Vlr."
                size={24}
                desicion={true}
                maxLength={34}
                style={{ color: "white" }}
                colorLabel=""
              />

              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={precisionManual || 4}
                decimalSeparator="."
                customInput={LabelGroup}
                name={"calculated_value"}
                value={supplyData?.calculated_value ?? "N/A"}
                tag="Vlr. Calculado"
                size={24}
                desicion={true}
                style={{ color: "white" }}
                colorLabel=""
              />

              <NumericFormat
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={precisionManual || 4}
                decimalSeparator="."
                customInput={LabelGroup}
                name={"calculated_weight"}
                value={supplyData?.calculated_weight ?? "N/A"}
                tag="Peso Calculado"
                size={24}
                desicion={true}
                style={{ color: "white" }}
                colorLabel=""
              />
            </div>
          </>
        )}
      </div>
    );

  return {
    SupplyDataOverlay,
    handleMouseEnter,
    handleClose,
    isOpen,
    isLoading,
    error,
    supplyData,
    activeReference,
    activeId,
  };
};

export default useSupplyDataOverlay;
