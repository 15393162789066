import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext } from "react";

export const PDFRestOfTheWorl = ({ values }) => {
  const { commercial_invoice_corrected, value_duta_corrected } = values;
  const { opcional } = useContext(FormCrudCRUDContext);

  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Factura Comercial: </strong> {commercial_invoice_corrected}
        </p>
      </div>

      <div className="col">
        {opcional && (
          <p>
            <strong>{opcional}: </strong> {value_duta_corrected}
          </p>
        )}
      </div>
    </div>
  );
};
