/**
 * Compara dos arrays de objetos y devuelve true si son iguales o false si son diferentes.
 *
 * @param {Array<Object>} array1 - El primer array de objetos a comparar.
 * @param {Array<Object>} array2 - El segundo array de objetos a comparar.
 * @returns {boolean} true si los dos arrays son iguales, de lo contrario false.
 */
export const compareArrays = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every(
    (item, index) => JSON.stringify(item) === JSON.stringify(array2[index])
  );
};
