import Swal from "sweetalert2";
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { RiSearch2Line } from "react-icons/ri";
import { Title } from "styled/Title";
import { IconGeneric } from "styled/Icons.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import CardInfoSearchPlateVehicle from "components/Security/CardInfoSearchPlateVehicle";
import {
  __getsearchVehiclePlateExit,
  __postcreateSecurityEntryAndExit,
  _getSearchCodeFormOutput,
} from "request/__Security";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import FormRegisterPublic from "../FormRegisterPublic";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  authorized,
  propsSwalSecurity,
  styleSelected,
  styleUnSelected,
} from "utils/generalData";
import useTypeTransport from "hooks/useTypeTransport";
import useVehicularDoors from "hooks/useVehicularDoors";
import {
  dataPassesAuthorized,
  dataPassesPresented,
  filterDataExit,
} from "./functions";
import ImageNotFound from "../../../assets/bro.png";
import { FuncionLetraNumberOnly } from "components/Funciones/FuncionLetraNumberOnly";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

import { AuthContext } from "context/AuthContext";

import {
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import InputSelect from "components/InputSelect/InputSelect";
import { isNumber, isObject } from "utils/type-check-utils";
import useStatusListener from "hooks/useStatusListener";

/**
 *  ExitVehicle - Componente para realizar busquedas por placa de vehiculos y por busqueda especifica
 *
 * @component
 * @return {Component} Retorna componente JSX, muestra la data mediante cards
 */

const ExitVehicle = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { authUser } = useContext(AuthContext);
  const { admin, is_commerce } = authUser;
  const { typeTransport } = useTypeTransport();
  const { dataVehicheDoor } = useVehicularDoors("exit");
  const [loading, setLoading] = useState(false);
  const [presentedData, setpresentedData] = useState([]);
  const [authorizedData, setAuthorizedData] = useState([]);
  const [dataFreePasses, setDataFreePasses] = useState([]);
  const [dataPasses, setpassesDataAuthorized] = useState([]);
  const [specificSearch, setSpecificSearch] = useState(null);
  const [selectAuthorized, setSelectAuthorized] = useState(true);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [uploaded, setUploaded] = useState({});
  const [IsActive, setIsActive] = useState(true);
  const [dataSearchVehiclePlate, setdataSearchVehiclePlate] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const [headerInfo, setHeaderInfo] = useState({
    pendingPass: null,
    pendingPassComex: null,
  });

  const [CreateDispacth, setCreateDispacth] = useState(0);
  const [vehicleDoor, setVehicleDoor] = useState(null);
  const [selectTypeTransport, setSelectTypeTransport] = useState(null);
  const [selectUserQualified, setSelectUserQualified] = useState(null);
  const [values, handleInputChange, reset, setValues] = useForm({
    plateVehicle: "",
    specificSearch: "",

    // form serv public
    name: "",
    identification: "",
    container_1: "",
    capacity_container_1: "",
    container_2: "",
    capacity_container_2: "",
    description: "",
    plateVehicleNewRegister: "",
  });

  useEffect(() => {
    if (admin || is_commerce) {
      setIsActive(false);
    }
  }, [authUser]);

  const handleSubmitRegisterAll = () => {
    Swal.fire(propsSwalSecurity).then((result) => {
      if (result.isConfirmed) {
        handleRegisterAllEntryAutorized();
      }
    });
  };
  const [createStatusdispatch, setCreatedispatch] = useState(0);
  const [idPassAssociated, setIdPassAssociated] = useState([]);
  const getsearchVehiclePlateExit = async () => {
    setdataSearchVehiclePlate(null);
    if (values.plateVehicle) {
      setLoading(true);
      try {
        const plate = values.plateVehicle.toUpperCase();
        const res = await __getsearchVehiclePlateExit(token, plate);
        const status = res?.data?.status;
        const isEmpty = res?.data?.data;
        const Message = res?.data?.status?.message || res?.data?.message;
        const Code = status?.code || status;

        setCreatedispatch(res?.data?.createdispatch);

        if (isEmpty && !isEmpty.length > 0) {
          openAlert("No hay documentos con la placa digitada", "error");
        }

        if (Code !== 200) {
          openAlert(Message, "error");
          setdataSearchVehiclePlate(null);
          setSpecificSearch(null);
          // reset();
        } else {
          Message && openAlert(Message, "warn");
          const data = res?.data?.data;

          const idPass =
            isObject(data) &&
            data.reduce((acc, passCurrent) => {
              if (isNumber(passCurrent.passForm_number)) {
                acc.push({
                  id: passCurrent.dispatchPasses_id,
                  status: passCurrent.status,
                });
              }

              return acc;
            }, []);

          setIdPassAssociated(idPass);
          setHeaderInfo({
            pendingPass: res?.data?.pending_passes ?? null,
            pendingPassComex: res?.data?.pending_passes_comex ?? null,
          });

          setSpecificSearch(null);
          const passesAuthorized = dataPassesAuthorized(data);
          const passesPresented = dataPassesPresented(data);

          if (!passesAuthorized?.length) {
            setSelectAuthorized(false);
          }
          // const cargado = passesPresented.find((data) => data.empty_pass === 0);
          // setUploaded(cargado);
          /* if (passesAuthorized[0]?.msg_alert_a) {
            alertMsg(passesAuthorized[0]?.msg_alert_a);
          } */

          // if (data[0].msg_validate) {
          //   openAlert(data[0]?.msg_validate, "");
          // }
          // if (passesPresented[0]?.msg_alert_p) {
          //   openAlert(passesPresented[0]?.msg_alert_p, "");
          // }
          // if (passesPresented[0]?.msg_alert_r) {
          //   openAlert(passesPresented[0]?.msg_alert_r, "");
          // }

          // if (passesPresented[0]?.msg_alert_kg) {
          //   openAlert(passesPresented[0]?.msg_alert_kg, "");
          // }

          const passeFree = passesPresented.filter(
            (passes) => passes.free_pass === 1
          );
          const dataPasseFree = filterDataExit(passeFree, data);
          const dataAuthorized = filterDataExit(passesAuthorized, data);

          setDataFreePasses(dataPasseFree);
          setpassesDataAuthorized(dataAuthorized);
          setpresentedData(passesPresented);
          setAuthorizedData(passesAuthorized);
          if (data[0] && data[0]?.typeTransport_id) {
            setSelectTypeTransport({
              value: data[0]?.typeTransport_id,
              label: data[0]?.type_transport,
              is_container: data[0]?.is_container,
            });
          }
          setdataSearchVehiclePlate(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      openAlert("Debe ingresar una placa.", "error");
      setHeaderInfo({
        pendingPass: null,
        pendingPassComex: null,
      });
    }
  };

  const getSearchCodeFormExit = async () => {
    if (!values.specificSearch) {
      openAlert("Debe ingresar un número de pase", "error");
    } else {
      setLoading(true);
      try {
        const res = await _getSearchCodeFormOutput(
          token,
          values?.specificSearch
        );

        const status = res?.data?.status;
        const Message = res?.data?.status?.message || res?.data?.message;
        const Code = status?.code || status;

        if (Code !== 200) {
          openAlert(Message, "error");
          setdataSearchVehiclePlate(null);
          setSpecificSearch(null);
          setHeaderInfo({
            pendingPass: null,
            pendingPassComex: null,
          });
        } else {
          setSpecificSearch(status?.data);

          if (status?.data?.msg_alert_a) {
            openAlert(status?.data?.msg_alert_a, "");
          }
          if (status?.data?.msg_alert_p) {
            openAlert(status?.data?.msg_alert_p, "");
          }
          if (status?.data?.msg_validate) {
            openAlert(status?.data?.msg_validate, "");
          }
          setSelectTypeTransport({
            value: status?.data?.typeTransport_id,
            label: status?.data?.type_transport,
            is_container: status?.data?.is_container,
          });

          const datapassesAuthorized = [
            {
              qualifiedUser_id: status?.data?.qualifiedUser_id, // new
              driver: status?.data?.driver,
              id: status?.data?.entryPasses_id,
              vehicle_plate: status?.data?.vehicle_plate,
              identification: status?.data?.identification,
              description_security: status?.data?.observations,
              typeTransport_id: status?.data?.typeTransport_id,
              container_1:
                status?.data?.container_1 === null
                  ? ""
                  : status?.data?.container_1,
              container_2:
                status?.data?.container_2 === null
                  ? ""
                  : status?.data?.container_2,
              capacity_container_1:
                status?.data?.capacity_container_1 === null
                  ? ""
                  : status?.data?.capacity_container_1,
              capacity_container_2:
                status?.data?.capacity_container_2 === null
                  ? ""
                  : status?.data?.capacity_container_2,
            },
          ];
          setpassesDataAuthorized(datapassesAuthorized);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleRegisterAllEntryAutorized = async () => {
    if (!vehicleDoor) {
      openAlert("Debe seleccionar la puerta vehícular", "error");
    } else {
      setLoading(true);
      try {
        setLoading(false);
        const res = await __postcreateSecurityEntryAndExit(
          token,
          {
            vehicularDoor_id: vehicleDoor?.value,
            dataPasses,
          },
          "exit"
        );
        const status = res?.data?.status;
        if (status?.code !== 200) {
          openAlert(status?.message, "error");
        } else {
          // reset();
          setpresentedData([]);
          setAuthorizedData([]);
          setpassesDataAuthorized([]);
          // setdataSearchVehiclePlate([]);
          setSpecificSearch(null);
          openAlert(status?.message, "success");
          /* 	getsearchVehiclePlateExit(); */
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const registerServPublic = async () => {
    // flag_securitty
    if (!vehicleDoor) {
      openAlert("Debe seleccionar la puerta vehícular", "error");
    } else if (!selectTypeTransport) {
      openAlert("Seleccione un tipo de transporte", "error");
    } else if (CreateDispacth === 1 && selectUserQualified === null) {
      openAlert("El usuario calificado es obligatorio", "error");
    } else if (!values.name) {
      openAlert("El nombre es obligatorio", "error");
    } else {
      setLoading(true);
      try {
        setLoading(false);
        const data = [
          {
            id: "",
            driver: values?.name,
            container_1: values?.container_1,
            description_security: values?.description, // data
            container_2: values?.container_2,
            identification: values?.identification,
            typeTransport_id: selectTypeTransport?.value,
            qualifiedUser_id: selectUserQualified
              ? selectUserQualified?.value
              : "", // new
            flag_security: createStatusdispatch || CreateDispacth, // new
            vehicle_plate: values?.plateVehicleNewRegister,
            capacity_container_1: values?.capacity_container_1,
            capacity_container_2: values?.capacity_container_2,
          },
        ];
        const res = await __postcreateSecurityEntryAndExit(
          token,
          {
            vehicularDoor_id: vehicleDoor?.value,
            dataPasses: data,
          },
          "exit"
        );
        const status = res?.data?.status;
        if (status?.code !== 200) {
          openAlert(status?.message, "error");
        } else {
          openAlert(status?.message, "success");

          onCloseSearch(true);
          reset();
          setSelectUserQualified(null);
          setSelectTypeTransport(null);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRegisterFreePasse = async (params) => {
    if (!vehicleDoor) {
      openAlert("Debe seleccionar la puerta vehícular", "error");
    } else {
      setLoading(true);
      try {
        // const dataSend = [{ ...params[0], flag_security: 0 }];

        setLoading(false);
        const res = await __postcreateSecurityEntryAndExit(
          token,
          {
            vehicularDoor_id: vehicleDoor?.value,
            dataPasses: params,
          },
          "exit"
        );
        const status = res?.data?.status;
        if (status?.code !== 200) {
          openAlert(status?.message, "error");
        } else {
          //  reset();
          setpresentedData([]);
          setAuthorizedData([]);
          setpassesDataAuthorized([]);
          //  setdataSearchVehiclePlate([]);
          setSpecificSearch(null);

          openAlert(status?.message, "success");

          // !selectAuthorized && getsearchVehiclePlateExit();
          /* 	getsearchVehiclePlateExit(); */
        }
      } catch (error) { }
    }
  };

  const handleInput = (data) => {
    const index = dataPasses?.findIndex(
      (dataFind) => dataFind?.id === data?.id
    );
    if (index !== -1) {
      dataPasses[index] = data; // asignarle data al obj que tenga ese index
      setpassesDataAuthorized(dataPasses);
    }
  };

  const selectTransport = (val) => {
    setSelectTypeTransport(val);
    const dataR = dataPasses.map((data) => ({
      ...data,
      typeTransport_id: val?.value || "",
    }));
    setpassesDataAuthorized(dataR);
  };

  //idPassAssociated

  function checkAvailability(arr, val) {
    return arr.some(function ({ id }) {
      return val === id;
    });
  }

  const transformArrayToObject = (arr) => {
    const result = {};
    arr.forEach((item) => {
      result[item?.id] = item;
    });
    return result;
  };

  const refetchForm = (flag, form_id, new_status) => {
    const transformed = transformArrayToObject(idPassAssociated);

    const shouldRefetch =
      (flag === "entry_pass" || flag === "dispatch_pass") &&
      new_status !== transformed?.[form_id]?.status &&
      checkAvailability(idPassAssociated, form_id);

    if (shouldRefetch) getsearchVehiclePlateExit();
  };

  useStatusListener({
    id: idPassAssociated,
    callBack: refetchForm,
    dataCurrent: dataSearchVehiclePlate,
  });

  return (
    <>
      <HeaderSearch style={{ marginTop: 30, marginBottom: 15 }}>
        <div className="d-flex justify-content-between align-items-center m-lg-1">
          <Title>Registro de seguridad salida</Title>
          <ButtonStyled
            type="submit"
            onClick={onOpenSearch}
          // disabled={!dataPasses.length ? true : false}
          >
            Nuevo registro
          </ButtonStyled>
        </div>

        <div className="row">
          <div className="col col-md-5">
            <form
              className="d-flex  mb-3"
              onSubmit={(event) => {
                event.preventDefault();
                getsearchVehiclePlateExit();
              }}
            >
              <label
                style={{
                  width: "100%",
                }}
              >
                Placa vehículo
                <InputGroup>
                  <Input
                    w={"100%"}
                    h={"38px"}
                    color={"#000"}
                    outline={"none"}
                    fontSize={"1rem"}
                    backgroundColor={"#ffffff87"}
                    name="plateVehicle"
                    onChange={(e) =>
                      FuncionLetraNumberOnly(e, setValues, values)
                    }
                    value={values?.plateVehicle}
                    placeholder={"Ejemplo: ABC123"}
                  />
                  <InputRightElement as="button">
                    <RiSearch2Line color="green.500" />
                  </InputRightElement>
                </InputGroup>
              </label>
            </form>
          </div>
          {/* {headerInfo?.pendingPass !== null ||
          headerInfo?.pendingPassComex !== null ? (
            <div className="col col-md-2">
              <Flex
                w={"100%"}
                h={"100%"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {headerInfo?.pendingPass !== null && (
                  <span
                    style={{
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    Nº P.P: {headerInfo?.pendingPass}
                  </span>
                )}
                {headerInfo?.pendingPassComex !== null && (
                  <span
                    style={{
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    N° P.P.C: {headerInfo?.pendingPassComex}
                  </span>
                )}
              </Flex>
            </div>
          ) : (
            <div className="col col-md-2" />
          )} */}
          <div className="col col-md-2" />
          <div className="col  col-md-5">
            <form
              className="d-flex  mb-3"
              onSubmit={(event) => {
                event.preventDefault();
                getSearchCodeFormExit();
              }}
            >
              <label
                style={{
                  width: "100%",
                }}
              >
                Búsqueda específica
                <InputGroup>
                  <Input
                    w={"100%"}
                    h={"38px"}
                    color={"#000"}
                    outline={"none"}
                    fontSize={"1rem"}
                    backgroundColor={"#ffffff87"}
                    name={"specificSearch"}
                    onChange={handleInputChange}
                    value={values?.specificSearch}
                    placeholder={"Búsqueda específica"}
                  />
                  <InputRightElement as="button">
                    <RiSearch2Line color="green.500" />
                  </InputRightElement>
                </InputGroup>
              </label>
            </form>
            <div className="spac" />
          </div>{" "}
        </div>
        <div className="d-flex  mb-3">
          <InputSelect
            value={vehicleDoor}
            set={setVehicleDoor}
            data={dataVehicheDoor}
            tag="Puerta Vehicular *"
            size={100}
          />
        </div>
      </HeaderSearch>

      <HeaderSearch style={{ marginTop: 30, marginBottom: 50 }}>
        <ModalGeneric
          isOpen={isOpenSearch}
          onOpen={onOpenSearch}
          onClose={onCloseSearch}
          title="Registro de salida"
          scrollBehavior={"outside"}
        >
          <FormRegisterPublic
            reset={reset}
            values={values}
            onClose={onCloseSearch}
            vehicleDoor={vehicleDoor}
            onChange={handleInputChange}
            setVehicleDoor={setVehicleDoor}
            dataVehicheDoor={dataVehicheDoor}
            vehiclePlate={values.plateVehicle}
            handleRegister={registerServPublic}
            selectTypeTransport={selectTypeTransport}
            setSelectTypeTransport={setSelectTypeTransport}
            selectUserQualified={selectUserQualified}
            setSelectUserQualified={setSelectUserQualified}
            setValues={setValues}
            CreateDispacth={CreateDispacth}
            setCreateDispacth={setCreateDispacth}
            title="Registro de salida"
          />
        </ModalGeneric>

        {loading && <SpinnerComponent />}

        {!dataSearchVehiclePlate?.length && specificSearch === null ? (
          <div className="containerInfo">
            <img src={window.assetURL + ImageNotFound} alt="vehicle" />
          </div>
        ) : (
          <>
            {specificSearch ? (
              <>
                <CardInfoSearchPlateVehicle
                  {...specificSearch}
                  destiny="exit"
                  specificSearch
                  dataPasses={dataPasses}
                  handleInput={handleInput}
                  vehicleDoor={vehicleDoor}
                  typeTransport={typeTransport}
                  setVehicleDoor={setVehicleDoor}
                  selectUserQualified={selectUserQualified}
                  dataVehicheDoor={dataVehicheDoor}
                  selectTypeTransport={selectTypeTransport}
                  setSelectTypeTransport={selectTransport}
                  setDataFreePasses={setDataFreePasses}
                  setpassesDataAuthorized={setpassesDataAuthorized}
                  handleRegisterFreePasse={handleRegisterFreePasse}
                />
                {specificSearch?.status === authorized && (
                  <div className="btn-flotante">
                    <ButtonStyled
                      type="submit"
                      onClick={handleRegisterAllEntryAutorized}
                    >
                      Registrar
                    </ButtonStyled>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="h-100">
                  <div
                    className="d-flex mb-3 "
                    style={{
                      borderBottomWidth: 1,
                    }}
                  >
                    <div className="comer" style={{ width: "100%" }}>
                      <Title
                        size={16}
                        onClick={() => setSelectAuthorized(true)}
                        style={
                          selectAuthorized ? styleSelected : styleUnSelected
                        }
                      >
                        {/* Autorizados */}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ padding: "5px", marginRight: "10px" }}>SEGURIDAD</div>
                          <div style={{ padding: "5px"}}>Nº P.P: {authorizedData.length || 0}</div> {/* en caso de que la linea de abajo falle */}
                          {/* <div style={{ padding: "5px" }}>Nº P.P: {headerInfo?.pendingPass || 0}</div> */}
                        </div>
                      </Title>
                    </div>
                    <div className="comer" style={{ width: "100%" }}>
                      <Title
                        size={16}
                        onClick={() => setSelectAuthorized(false)}
                        style={
                          !selectAuthorized ? styleSelected : styleUnSelected
                        }
                      >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ padding: "5px", marginRight: "10px" }}>COMERCIO EXTERIOR</div>
                          <div style={{padding: "5px"}}> Nº P.P.C: {presentedData.length || 0}</div> {/* en caso de que la linea de abajo falle */}
                          {/* <div style={{ padding: "5px" }}> Nº P.P.C: {headerInfo?.pendingPassComex || 0}</div> */}
                        </div>
                      </Title>
                    </div>
                  </div>

                  {selectAuthorized ? (
                    <div className="row">
                      {/* Aqui NO PASO, NO ITEM */}
                      {authorizedData.map((item, i) => (
                        <CardInfoSearchPlateVehicle
                          key={i}
                          {...item}
                          id={i}
                          destiny="exit"
                          dataPasses={dataPasses}
                          handleInput={handleInput}
                          vehicleDoor={vehicleDoor}
                          typeTransport={typeTransport}
                          setVehicleDoor={setVehicleDoor}
                          dataVehicheDoor={dataVehicheDoor}
                          selectUserQualified={selectUserQualified}
                          setSelectTypeTransport={selectTransport}
                          selectTypeTransport={selectTypeTransport}
                          handleRegisterFreePasse={handleRegisterFreePasse}
                          isDisable_TypeTransport={IsActive}
                          hide_inform={IsActive}
                          isDisable_nItems={IsActive}
                          createStatusdispatch={createStatusdispatch}
                          headerInfo={headerInfo}
                          showAmountItems={true}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {presentedData.map((item, i) => (
                        <CardInfoSearchPlateVehicle
                          key={i}
                          {...item}
                          id={i}
                          dataPasses={dataFreePasses}
                          destiny="exit"
                          handleInput={handleInput}
                          vehicleDoor={vehicleDoor}
                          uploaded={uploaded}
                          selectUserQualified={selectUserQualified}
                          typeTransport={typeTransport}
                          setVehicleDoor={setVehicleDoor}
                          dataVehicheDoor={dataVehicheDoor}
                          setDataFreePasses={setDataFreePasses}
                          setSelectTypeTransport={selectTransport}
                          selectTypeTransport={selectTypeTransport}
                          handleRegisterFreePasse={handleRegisterFreePasse}
                          setpassesDataAuthorized={setpassesDataAuthorized}
                          isDisable_TypeTransport={IsActive}
                          hide_inform={IsActive}
                          isDisable_nItems={IsActive}
                          createStatusdispatch={createStatusdispatch}
                          headerInfo={headerInfo}
                          disabledTextarea={true}
                          showAmountItems={true}
                        />
                      ))}
                    </div>
                  )}

                  {selectAuthorized && !IsActive ? (
                    <div className="btn-flotante">
                      <ButtonStyled
                        type="submit"
                        onClick={handleSubmitRegisterAll}
                        disabled={
                          headerInfo?.pendingPassComex !== null &&
                          headerInfo?.pendingPassComex !== 0
                        }
                      // disabled={!dataPasses.length ? true : false}
                      >
                        Registrar todos
                        {/* {selectAuthorized ? "Registrar autorizados" : "Registar pases libres"} */}
                      </ButtonStyled>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </HeaderSearch>
    </>
  );
};

export default ExitVehicle;
