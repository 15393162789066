import {
  Box,
  Center,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { trackingStatus } from "../Templates";
import { useSelector } from "react-redux";
import { BiShowAlt } from "react-icons/bi";
import { useMutation } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { isArray } from "utils/type-check-utils";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TableOrder from "components/Tables/TableOrder";
import TbodyEntryFormAsociatedPass from "./TbodyEntryFormAsociatedPass";
import { TheadEntryFormAsociatedPass } from "./TheadEntryFormAsociatedPass";
import { ModalGeneric } from "components/modal/ModalGeneric";

function WithPopoverDetail({
  isLoading = false,
  data = [{}],
  isOpen = false,
  onOpen = () => {},
  onClose = () => {},
}) {
  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onClose={onClose}
        title={`Detalles`}
        size={"4xl"}
      >
        <>
          {isLoading && <SpinnerComponent />}

          {!isLoading && (
            <Box display={"flex"} justifyContent={"center"}>
              <TableOrder
                thead={TheadEntryFormAsociatedPass}
                data={data}
                loading={isLoading}
                tbodyData={TbodyEntryFormAsociatedPass}
              />
            </Box>
          )}
        </>
      </ModalGeneric>

      <IconButton
        onClick={onOpen}
        variant="tansparent"
        _active={{ border: "none", outline: "none" }}
        _focus={{ border: "none", outline: "none" }}
        _hover={{ border: "none", outline: "none" }}
        fontSize="25px"
        color={"#1F7AC3"}
        icon={<BiShowAlt />}
      />
    </>
  );
}

export const TbodyItemShow = ({ data }) => {
  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);
  const [openAlert] = AlertErrorAlert();
  const { requestData } = useGlobalContext();
  const [detailItem, setDetailItem] = useState({});

  const isDataInspection = {
    1: (data) => {
      return (
        <Tooltip placement="top" hasArrow label="Requiese inspección">
          <Tr id="BluewarningInsp">
            <TableData data={data} />
          </Tr>
        </Tooltip>
      );
    },

    default: (data) => {
      return (
        <Tr>
          <TableData data={data} />
        </Tr>
      );
    },
  };

  const { mutate, isPending } = useMutation({
    mutationFn: requestData,
  });

  const handleDetailPass = () => {
    const config = {
      resourceIdentifier: data?.entryForm_id,
      Endpoint: environment.GET_ENTRY_PASS_ITEM_DETAIL,
      PropertyBody: "get",
    };

    mutate(config, {
      onSuccess: (data) => {
        if (
          data?.data?.status?.code === 400 ||
          data?.data?.status?.code === 403
        ) {
          return openAlert(data?.data?.status?.message, "error");
        }

        if (data?.data?.status?.code === 200) {
          if (isArray(data?.data?.status?.data)) {
            setDetailItem(data?.data?.status?.data);
          }
        }
      },
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpen) handleDetailPass();
  }, [isOpen]);

  const TableData = ({ data }) => {
    return (
      <React.Fragment>
        <Td className="text-center">{data?.item}</Td>
        <Td className="text-center">
          <NavLink
            to={`/FormShow?FormEntry=${data?.entryForm_id}`}
            className={"text-center"}
          >
            <span className="formNumber">
              {data?.code_entryForm ? data?.code_entryForm : "N/A"}
            </span>
          </NavLink>
        </Td>
        <Td className="text-center">
          {data?.code_operation ? data?.code_operation : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.num_commercial_invoice ? data?.num_commercial_invoice : "N/A"}
        </Td>
        <Td className="text-center">
          {data?.status_entryForm ? data?.status_entryForm : "N/A"}
        </Td>
        <Td className="text-center">
          <Center height={"20px"}>
            <WithPopoverDetail
              isLoading={isPending}
              data={detailItem}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Center>
        </Td>
        {is_qualified_user === 0 && (
          <Td className="text-center">
            {trackingStatus[data?.tracking] || trackingStatus.default}
          </Td>
        )}
      </React.Fragment>
    );
  };
  return (
    <>
      {isDataInspection[data?.inspection]?.(data) ||
        isDataInspection.default(data)}
    </>
  );
};
