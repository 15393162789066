import { Tooltip, useDisclosure } from '@chakra-ui/react'
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex'
import React, { useContext, useEffect } from 'react'
import { AiOutlineFileSync } from 'react-icons/ai'
import { BsCheck2Circle } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import queryString from 'query-string'
import environment from 'constants/apiConst'
import { ModalGeneric } from 'components/modal/ModalGeneric'
import { ReasonForReturnComponent } from 'components/DispatchCorrections/ReasonForReturnComponent'

const ActionsReviewed = ({ formSubmissionState }) => {
  const location = useLocation()
  const { id = null } = queryString.parse(location.search)
  const navigate = useNavigate()
  const { changeStatus, setRequestChangeStatusBox } = useContext(
    IntegracionBoxCrudContex
  )

  useEffect(() => {
    setRequestChangeStatusBox(environment.CHANGE_STATUS_BOX)
  }, [])

  const handleStatusBox = status => {
    const statusData = new FormData()
    statusData.append('id', id)
    statusData.append('status', status)

    if (
      status === 'DEVUELTO' &&
      (formSubmissionState === 'PRESENTADO' || formSubmissionState === 'REVISADO')
    ) {
      onOpenReturnReason()
    }

    if (status === 'AUTORIZADO') {
      changeStatus(statusData)
    }
  }

  const {
    isOpen: isOpenReturnReason,
    onOpen: onOpenReturnReason,
    onClose: onCloseReturnReason
  } = useDisclosure()

  const ReturnCorrent = () => {
    navigate(-1)
  }

  return (
    <div>
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >
        <ReasonForReturnComponent
          onClose={onCloseReturnReason}
          id={id}
          PropertyReasonForReturn={'reason_for_return'}
          PropertyStatus={'status'}
          serviceReturn={environment.CHANGE_STATUS_BOX}
          callBack={ReturnCorrent}
        />
      </ModalGeneric>

      <Tooltip placement='top' hasArrow label='Radicar'>
        <span>
          <ButtonHeaderModule
            onClick={() => handleStatusBox('AUTORIZADO')}
            className='m-2'
            bgColor='#1F7AC3'
          >
            <IconGeneric className='m-1 text-white' as={BsCheck2Circle} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>

      <Tooltip placement='top' hasArrow label='Devolver'>
        <span>
          <ButtonHeaderModule
            onClick={() => handleStatusBox('DEVUELTO')}
            className='m-2'
            bgColor='#d92525'
          >
            <IconGeneric className='m-1 text-white' as={AiOutlineFileSync} />
          </ButtonHeaderModule>
        </span>
      </Tooltip>
    </div>
  )
}

export default ActionsReviewed
