import axios from "axios";
import environment from "constants/apiConst";

export const __EditCreateCustoms = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __TradeAgreementsCustoms = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.TRADE_AGREEMENT_NOT_PAGINATE}`, options);
  return res;
};

export const __ObtenerSubHeadingCode = async (token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.GET_SUBHEADING_CUSTOMS_CLEARANCE}`,
    options
  );
  return res;
};

export const __CustomsClearence = async (token, page) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.GET_CUSTOMS_CLEARANCE}?page=${page || 1}`,
    options
  );
  return res;
};

export const __ShowCustomsClearence = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${environment.SHOW_CUSTOMS_CLEARANCE}/${id}`, options);
  return res;
};

export const __SearchCustoms = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(
    `${environment.SEARCH_CUSTOMS_CLEARANCE}?page=${page || 1}`,
    options
  );
  return res;
};

export const __ObtenerQrCustoms = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __DeleteAttachmentCustoms = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __getItemsFormCustomsClearances = async (
  token,
  data,
  idUser,
  values
) => {
  const formData = new FormData();
  formData.append("subheading", data);
  formData.append("qualified_user_id", idUser);

  Object.entries(values).map(([key, valor]) => {
    if (valor) {
      return formData.append(key, valor);
    }
  });

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(`${environment.FORM_CUSTOMS_CHANGE_STATUS}`, options);
  return res;
};

export const __getShowItemFormCustomsClearance = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.SHOW_ITEM_FORM_CUSTOMS_CLEARANCE}/${id}`,
    options
  );
  return res;
};

export const __CopyDesaduanamiento = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __createItemCustomsClearance = async (
  token,
  data,
  updateAddItems
) => {
  const {
    id,
    US_value,
    reference,
    entryFormItem_id,
    product_quantity,
    weight_kilograms,
    equivalent_amount,
    quantity_subtract,
    customsClearance_id,
    description_product
  } = data;

  const formData = new FormData();
  updateAddItems && formData.append("id", id);
  !updateAddItems && formData.append("entryFormItem_id", entryFormItem_id);
  formData.append("customsClearance_id", customsClearance_id);
  formData.append("reference", reference);
  formData.append("description_product", description_product);
  formData.append("product_quantity", product_quantity);
  formData.append("US_value", US_value);
  formData.append("weight_kilograms", weight_kilograms);
  formData.append("equivalent_amount", equivalent_amount);
  formData.append("quantity_subtract", quantity_subtract);

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  // let res = await axios(`${environment.CREATE_ITEM_CUSTOMS_CLEARANCE}`, options);
  const res = await axios(
    updateAddItems
      ? `${environment.UPDATE_ITEMS_CUSTOMS_CLEARANCE}`
      : `${environment.CREATE_ITEM_CUSTOMS_CLEARANCE}`,
    options
  );

  return res;
};

export const __postItemCustomsClearance = async (token, id, page) => {
  const formData = new FormData();
  formData.append("customsClearence_id", id);

  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: formData || ""
  };

  const res = await axios(
    `${environment.ITEMS_CUSTOMS_CLEARANCE}?page${page || 1}`,
    options
  );
  return res;
};

export const __getShowItemCustomsClearance = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.SEARCH_ITEMS_CUSTOMS_CLEARANCE}/${id}`,
    options
  );
  return res;
};

export const __getDeleteItemCustomsClearance = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(
    `${environment.DELETE_ITEMS_CUSTOMS_CLEARANCE}/${id}`,
    options
  );
  return res;
};
