/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Modal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  useModal,
  usePopoverContext
} from '@chakra-ui/react'
import PassCrudContext from 'context/PassCrudContext'
import { useNavigate, useLocation, NavLink } from 'react-router-dom'
import queryString from 'query-string'
import html2pdf from 'html2pdf.js'
import './stilo.css'
import { VscFilePdf } from 'react-icons/vsc'
import { MdQrCodeScanner, MdFactCheck } from 'react-icons/md'
import { AuthContext } from 'context/AuthContext'
import { RiDeviceRecoverFill, RiEdit2Fill } from 'react-icons/ri'
import { FaPrescriptionBottle, FaUnlock } from 'react-icons/fa'
import { AiOutlineFileDone, AiOutlineFileSync } from 'react-icons/ai'
import { IconGeneric } from 'styled/Icons.styled'
import { ModalSearch } from './View/BusquedaAvanzada/ModalSearch'
import { ButtonHeaderModule } from 'styled/Buttons.styled'

import { BiShow } from 'react-icons/bi'
import { InputWithLabel } from 'components/Input/InputFakeCustoms'
import { __Post } from 'request/Petitions/__Post'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { useGlobalContext } from 'context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { HiOutlineDocumentSearch } from 'react-icons/hi'
import Swal from 'sweetalert2'
import ModalQR from 'components/modal/ModalQR'
import { localPermissions } from 'Permissions/permissions'
import { hasCommonElements } from 'utils/type-check-utils'

const PassType = {
  DISPATCH: 'Dispatch',
  ENTRY: 'Entry'
}

const PassStatus = {
  DEVUELTO: 'DEVUELTO',
  REVISADO: 'REVISADO',
  DESECHAR: 'DESECHAR'
}
const PopoverControlle = ({
  isOpen,
  onClose,
  handle,
  id,
  children,
  values = null
}) => {
  const {
    CountTicket,
    setCountTicket,
    weightPresent,
    setWeightPresent,
    weightStatus,
    weightMessage,
    setWeightStatus,
    onClosePopover,
    editData,
    changeStatus,
    successfulStateChange,
    setUpdate,
    update,
    movementSpecial
  } = useContext(PassCrudContext)

  const location = useLocation()
  const [openAlert] = AlertErrorAlert();
  const {
    typeO = '',
    qualifiedUser = '',
    numberForm = '',
    id: idUrl = ''
  } = queryString.parse(location.search)

  const passType = typeO === PassType.DISPATCH ? 'Dispatch' : ''
  const statusKey = passType ? 'status_dispatchPass' : 'status_entryPass'
  const data = {
    id: values?.id,
    weight: 2,
    [statusKey]: 'PRESENTADO'
  }

  const handleCountTicket = x => {
    const { value } = x?.target
    setCountTicket(value)
  }

  const HandleUpdatePass = (name, value) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('show', 1)
    formData.append(`${name}`, value)

    if(movementSpecial) { // en caso de que backend haya retornado la variable 'special' en el endpoint 'changeStatusDispatchPass' al momento de presentar un pase 
      data.weighings = value // modificaremos el objeto 'data' y le enviaremos esta propiedad 'weighings' nuevamente en el endpoint 'changeStatusDispatchPass' pero al momento de darle a 'Guardar' o 'Si' dentro del popover de confirmación
    }
  
    editData(formData, 1, data)
  }

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='right'
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Confirmación</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          {weightStatus === 0 && (
            <Box>
              <Text fontWeight='semibold'>
                {`${weightMessage || "¿Quisiera pesar este formulario de entrada?"}`}
              </Text>
            </Box>
          )}
          {weightStatus === 1 && (
            <InputWithLabel
              p={0}
              m={0}
              placeholder={0}
              value={CountTicket}
              onChange={(e) => {
                  // Filtramos la entrada permitiendo solo números
                  const value = e.target.value.replace(/\D/g, ''); // Reemplaza todo lo que no sea un número con una cadena vacía
                  setCountTicket(value);
                }}
              borderRadius={'none'}
              inputBgColor={weightStatus === 1 ? 'red' : 'gray'}
              name='numberofpasses'
              labelText={
                weightStatus === 1
                  ? 'Cantidad de tiquetes *'
                  : 'Cantidad de tiquetes'
              }
              maxLength={2}
              style={{
                borderBottom: ' 1px solid #bababa80'
              }}
              readOnly={false}
            />
          )}
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          <ButtonGroup size='sm'>
            {weightStatus !== 1 ? (
              <Button
                variant='outline'
                onClick={() =>
                  changeStatus({
                    id: id,
                    [!typeO ? 'status_entryPass' : 'status_dispatchPass']:
                      'PRESENTADO',
                    weight: 2
                  })
                }
              >
                No
              </Button>
            ) : null}

            <Button
              colorScheme='red'
              onClick={() => {
                if (weightStatus === 1) {
                  HandleUpdatePass('weighings', CountTicket)
                }

                if (weightStatus === 0) {
                  setWeightStatus(1)
                }
              }}
            >
              {weightStatus === 1 ? 'Guardar' : 'Si'}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export const BtnHeaderShow = ({ values, Status, children, id }) => {
  const {
    isOpen: isOpenModalForSelectingItems,
    onOpen: onOpenModalForSelectingItems,
    onClose: onCloseModalForSelectingItems
  } = useDisclosure()

  const {
    setDataToEdit,
    changeStatus,
    onOpenReturnReason,
    setReturnReason,
    setDataToShow,
    weightPresent,
    IsOpenPopover,
    onClosePopover,
    successfulStateChange,
    setSuccessfulStateChange,
    itemsLinkArray,
    setItemsLinkArray,
    isLoadingChange,
    setIsLoadingChange
  } = useContext(PassCrudContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    status = '',
    typeO = '',
    id: idUrl = '',
    qualifiedUser: qualifiedUserUrl = '',
    numberForm: numberFormUrl = ''
  } = queryString.parse(location.search)
  const token = useSelector(state => state.auth.dataUser.token)
  const { authUser } = useContext(AuthContext)
  const { pathname } = location
  const [openAlert] = AlertErrorAlert()
  const [isLoading, setIsLoading] = useState(false)
  const actionExport = () => {
    const element = window.document.getElementById('display-vacio').innerHTML
    const opt = {
      margin: 1,
      filename: `Pase-${values?.status_dispatchPass ? 'Despacho' : 'Entrada'}-${values?.passForm_number
        }-${values?.nit_qualified}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
    }
    html2pdf().set(opt).from(element).save()
  }
  const handlePresent = async status => {
    setIsLoadingChange(true)
    const body = new FormData()
    body.append('form_id', values?.id || idUrl)
    body.append('type_of', typeO === 'Dispatch' ? 'DESPACHO' : 'ENTRADA')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setTimeout(async () => {
        body.set('type', 0)
        const res = await __Post(environment.FORMS_ON_EDIT, token, body)
      }, 5000)
    }
    if (res?.data?.status?.code === 400) {
      setIsLoadingChange(false)
      return openAlert(res?.data?.status?.message, 'error')
    }

    const passType = typeO === PassType.DISPATCH ? 'Dispatch' : ''
    const statusKey = passType ? 'status_dispatchPass' : 'status_entryPass'

    const data = {
      id: values.id ?? idUrl, 
      weight: weightPresent ?? null,
      [statusKey]: status
    }

    if (status === PassStatus.REVISADO) {
      changeStatus(data, onOpenModalForSelectingItems)
      setDataToShow(data)
      return
    }

    if (status === PassStatus.DESECHAR) {
      const body = {
        id: values?.id,
        [typeO === 'Dispatch' ? 'status_dispatchPass' : 'status_entryPass']: status
      }

      changeStatus(body, () => { }, typeO === 'Dispatch' ? environment.CHANGESTATUSDISPACTH : environment.CHANGESTATUSPASS)

      return setTimeout(async () => {
        navigate(typeO === 'Dispatch' ? '/output-movement?movement=Pase%20de%20salida' : '/entry-movement?movement=Pase%20de%20entrada')
      }, 2000)
    }

    if (status === PassStatus.DEVUELTO) {
      onOpenReturnReason()
      setReturnReason(data)
    } else {
      changeStatus(data)
    }
  }

  const handleEdit = async () => {
    const body = new FormData()
    console.log(values)
    body.append('form_id', values?.id || idUrl)
    typeO === 'Dispatch'
      ? body.append('type_of', 'DESPACHO')
      : body.append('type_of', 'ENTRADA')
    body.append('type', 1)
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(values)
      return navigate(
        `/formInt?qualifiedUser=${values?.name_qualified || qualifiedUserUrl
        }&numberForm=${values?.passForm_number || numberFormUrl}&id=${values?.id || idUrl
        }&status=${values?.status_entryPass ||
        status ||
        values?.status_dispatchPass ||
        status
        }&typeO=${values?.status_dispatchPass || typeO === 'Dispatch' ? 'Dispatch' : ''
        }`
      )
    }
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleShow = () => {
    setDataToShow(values)
    if (values.status_dispatchPass) {
      navigate(
        `/CheckPassOutput?qualifiedUser=${values.name_qualified}&numberForm=${values.passForm_number}&id=${values.id}&status=${values.status_dispatchPass}&typeO=Dispatch`
      )
    } else {
      navigate(
        `/CheckPassEntry?qualifiedUser=${values.name_qualified}&numberForm=${values.passForm_number}&id=${values.id}&status=${values.status_entryPass}&typeO=`
      )
    }
  }

  const { requestData } = useGlobalContext()

  const { mutate } = useMutation({
    mutationFn: requestData
  })

  const handleCorrected = () => {
    const config = {
      data: {
        dispatchPass_id: values?.id
      },
      Endpoint: environment.POST_PASS_CORRECTIONS,
      PropertyBody: 'sendJSONContentPOST'
    }

    mutate(config, {
      onSuccess: data => {
        const resData = data?.data?.status?.data

        if (resData?.id) {
          navigate(
            `/DispathcCorrectionShow?Dispatch=${resData?.id}&Action=Show`
          )
        }

        return openAlert(data?.data?.status?.message, 'success')
      }
    })
  }

  const handleCloseModalForSelectingItems = () => {
    onCloseModalForSelectingItems()
    const passType = typeO === PassType.DISPATCH ? 'Dispatch' : ''
    const statusKey = passType ? 'status_dispatchPass' : 'status_entryPass'
    const data = {
      id: values.id,
      weight: weightPresent ?? null,
      [statusKey]: status
    }
    const formUrl = passType ? '/CheckPassOutput' : '/CheckPassEntry'
    navigate(
      `${formUrl}?qualifiedUser=${data?.name_qualified || qualifiedUserUrl
      }&numberForm=${data?.passForm_number || numberFormUrl}&id=${data?.id || idUrl
      }&status=${data[statusKey]}&typeO=${passType}`
    )
  }

  const [isBlocked, setIsBlocked] = useState(false)
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    const helper = async type => {
      const body = new FormData()
      body.append('form_id', values?.id || idUrl)
      typeO === 'Dispatch'
        ? body.append('type_of', 'DESPACHO')
        : body.append('type_of', 'ENTRADA')
      body.append('type', type)
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
      if (res?.data?.status?.code === 200) {
        setIsBlocked(false)
        console.log('desbloqueado')
      }
      if (res?.data?.status?.code === 400) {
        setIsBlocked(true)
        setMessageError(res?.data?.status?.message)
        console.log('bloqueado')
      }
    }

    helper(4)
  }, [])

  const showAlert = (title, message, callback = () => { }) => {
    Swal.fire({
      title,
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Desbloquear',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#1e75bb',
      position: 'top'
    }).then(async result => {
      if (result.isConfirmed) {
        const body = new FormData()
        body.append('form_id', values?.id || idUrl)
        typeO === 'Dispatch'
          ? body.append('type_of', 'DESPACHO')
          : body.append('type_of', 'ENTRADA')
        body.append('type', 2)
        const res = await __Post(environment.FORMS_ON_REVIEW, token, body)
        setIsBlocked(false)
        callback()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  /**función para desbloquear  @param {string} status se utiliza para cambiar el estado del formulario*/
  const unlockForm = async (status) => {
    const data = new FormData()
    data.append('form_id', values?.id || idUrl)
    typeO === 'Dispatch'
      ? data.append('type_of', 'DESPACHO')
      : data.append('type_of', 'ENTRADA')
    data.append('type', 0)
    status && data.append('change_status', status)
    const res = await __Post(environment.FORMS_ON_REVIEW, token, data)
  }

  const { permissionList } = useSelector(state => state.permissions)
  const { update: permissionUpdateEntry, changeStatus: changeStatusEntryPass } =
    localPermissions.EntryPass
  const {
    update: permissionUpdateDispatch,
    changeStatus: changeStatusOutputPass
  } = localPermissions.OutputPass


    /**ruta para dirigir al usuario hacia el show de pases al momento de desbloquear */
    const redirectShowPases = `/pases?numberForm=${numberFormUrl}&id=${idUrl}&status=${status}&typeO=${typeO}`

  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={'Código QR'}
        size={'md'}
      />

      <ModalSearch
        isOpen={isOpenModalForSelectingItems}
        onOpen={onOpenModalForSelectingItems}
        onClose={handleCloseModalForSelectingItems}
        title={'Formatos RR07'}
        size={'md'}
      >
        <Divider />
        <div style={{ padding: '20px' }}>
          {itemsLinkArray?.length > 0 && (
            <ul>
              {itemsLinkArray?.map(item => (
                <NavLink
                  to={`/FormatSRT?ExitNumber=${item?.form_id
                    }&FormType=${'Pases'}&Operation=${item?.code_operation}`}
                  target='_blank'
                >
                  <li style={{ cursor: 'pointer', marginLeft: '20px' }}>
                    <span style={{ color: '#3f9bdc' }}>
                      {item?.code_number_form}
                    </span>
                  </li>
                </NavLink>
              ))}
            </ul>
          )}
        </div>
      </ModalSearch>

      <div className='row'>
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            {authUser.is_qualified_user !== 1 && isBlocked && (
              <Tooltip placement='top' hasArrow label='Desbloquear'>
                <span>
                  <ButtonHeaderModule
                    onClick={() => {
                      showAlert(
                        '¿Deseas desbloquear el formulario?',
                        messageError,
                        () => {
                          openAlert('Se ha desbloqueado con éxito', 'success')
                        }
                      )
                    }}
                    bgColor='#fe8d59'
                    className='mx-1'
                  >
                    <IconGeneric
                      className='text-white'
                      color='#fff'
                      width='16px'
                      as={FaUnlock}
                    />
                  </ButtonHeaderModule>
                </span>
              </Tooltip>
            )}

            {((Status || status) === 'BORRADOR' ||
              (Status || status) === 'DEVUELTO') &&
              pathname !== '/CheckPassEntry' &&
              pathname !== '/CheckPassOutput' && (
                <>
                  {(hasCommonElements(permissionList, [
                    ...permissionUpdateEntry,
                    ...permissionUpdateDispatch
                  ]) ||
                    authUser.admin === 1) && (
                      <Tooltip placement='top' hasArrow label='Editar'>
                        <span>
                          <ButtonHeaderModule
                            onClick={handleEdit}
                            bgColor='#1F7AC3'
                          >
                            <IconGeneric
                              width='16px'
                              className='text-white'
                              as={RiEdit2Fill}
                            />
                          </ButtonHeaderModule>
                        </span>
                      </Tooltip>
                    )}

                  {(hasCommonElements(permissionList, [
                    ...changeStatusEntryPass,
                    ...changeStatusOutputPass
                  ]) ||
                    authUser.admin === 1) && (
                      <Tooltip placement='top' hasArrow label='Presentar'>
                        <span>
                          <PopoverControlle
                            isOpen={IsOpenPopover}
                            onClose={onClosePopover}
                            handle={handlePresent}
                            id={id}
                            values={values}
                          >
                            <ButtonHeaderModule
                              onClick={() => handlePresent('PRESENTADO')}
                              className='mx-1'
                              bgColor='#03C39A'
                            >
                              <IconGeneric
                                width='16px'
                                className='text-white'
                                as={AiOutlineFileDone}
                              />
                            </ButtonHeaderModule>
                          </PopoverControlle>
                        </span>
                      </Tooltip>
                    )}

                  {(hasCommonElements(permissionList, [
                    ...changeStatusEntryPass,
                    ...changeStatusOutputPass
                  ]) ||
                    authUser.admin === 1) && (
                      <Tooltip placement='top' hasArrow label='Desechar'>
                        <span>
                          <ButtonHeaderModule
                            onClick={() => handlePresent('DESECHAR')}
                            bgColor='#BA1F33'
                          >
                            <IconGeneric
                              width='16px'
                              className='text-white'
                              as={FaPrescriptionBottle}
                            />
                          </ButtonHeaderModule>
                        </span>
                      </Tooltip>
                    )}
                </>
              )}

            {(Status || status) === 'PRESENTADO' &&
              pathname !== '/CheckPassEntry' &&
              pathname !== '/CheckPassOutput' &&
              (authUser?.admin === 1 || authUser?.is_commerce === 1) &&
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) && (
                <Tooltip placement='top' hasArrow label='Revisar'>
                  <span onClick={() => handlePresent('REVISADO')}>
                    <ButtonHeaderModule bgColor='#03C39A' disabled={isBlocked}>
                      <IconGeneric
                        width='16px'
                        className='text-white'
                        as={BiShow}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              )}

            {(Status || status) === 'AUTORIZADO' &&
              pathname !== '/CheckPassEntry' &&
              pathname !== '/CheckPassOutput' &&
              !authUser?.is_qualified_user &&
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) ? (
              <>
                <Tooltip placement='top' hasArrow label='Devolver'>
                  <span>
                    <ButtonHeaderModule
                      onClick={() => handlePresent('DEVUELTO')}
                      bgColor='#d92525'
                    >
                      <IconGeneric
                        width='16px'
                        className='text-white'
                        as={AiOutlineFileSync}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            ) : (Status || status) === 'PRESENTADO' &&
              pathname !== '/CheckPassEntry' &&
              pathname !== '/CheckPassOutput' &&
              
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) ? (
              <>
                <Tooltip placement='top' hasArrow label='Recuperar'>
                  <span style={{marginLeft: '5px'}}>
                    <ButtonHeaderModule
                      onClick={() => handlePresent('BORRADOR')}
                      bgColor='#1f7ac3'
                    >
                      <IconGeneric
                        width='16px'
                        className='text-white'
                        as={RiDeviceRecoverFill}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              </>
            ) : null}

            {(Status || status) === 'AUTORIZADO' &&
              typeO === 'Dispatch' &&
              pathname !== '/CheckPassEntry' &&
              pathname !== '/CheckPassOutput' &&
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) && (
                <>
                  <Tooltip placement='top' hasArrow label='Corregir'>
                    <span>
                      <ButtonHeaderModule
                        onClick={handleCorrected}
                        className='mx-1'
                        bgColor='#F9A621'
                      >
                        <IconGeneric
                          width='16px'
                          className='text-white'
                          as={HiOutlineDocumentSearch}
                        />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>
                </>
              )}

            {(pathname === '/CheckPassEntry' ||
              pathname === '/CheckPassOutput') &&
              authUser.is_qualified_user !== 1 &&
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) && (
                <Tooltip placement='top' hasArrow label='Desbloquear'>
                  <span>
                    <ButtonHeaderModule
                      onClick={() => {
                        unlockForm("PRESENTADO")// en este caso el botón de desbloquear también contara con la característica de poder cambiar de estado de revisado a presentado por petición del cliente
                        openAlert('Se ha desbloqueado con éxito', 'success')
                        navigate(redirectShowPases)
                      }}
                      className='mx-1'
                      bgColor='#fe8d59'
                    >
                      <IconGeneric
                        className='text-white'
                        color='#fff'
                        width='16px'
                        as={FaUnlock}
                      />
                    </ButtonHeaderModule>
                  </span>
                </Tooltip>
              )}

            {(pathname === '/CheckPassEntry' ||
              pathname === '/CheckPassOutput') &&
              (authUser?.admin === 1 || authUser?.is_commerce === 1) &&
              (hasCommonElements(permissionList, [
                ...changeStatusEntryPass,
                ...changeStatusOutputPass
              ]) ||
                authUser.admin === 1) && (
                <>
                  <Tooltip placement='top' hasArrow label='Radicar'>
                    <span>
                      <ButtonHeaderModule
                        onClick={() => {
                          unlockForm()
                          handlePresent('AUTORIZADO')
                        }}
                        bgColor='#1F7AC3'
                        disabled={isLoadingChange}
                      >
                        <IconGeneric
                          width='16px'
                          className='text-white'
                          as={MdFactCheck}
                        />
                      </ButtonHeaderModule>
                    </span>
                  </Tooltip>

                  {(hasCommonElements(permissionList, [
                    ...changeStatusEntryPass,
                    ...changeStatusOutputPass
                  ]) ||
                    authUser.admin === 1) && (
                      <Tooltip placement='top' hasArrow label='Devolver'>
                        <span>
                          <ButtonHeaderModule
                            onClick={() => handlePresent('DEVUELTO')}
                            className='ms-1'
                            bgColor='#d92525'
                          >
                            <IconGeneric
                              width='16px'
                              className='text-white'
                              as={AiOutlineFileSync}
                            />
                          </ButtonHeaderModule>
                        </span>
                      </Tooltip>
                    )}
                </>
              )}
          </Box>
        </div>
        {children}
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            <Tooltip placement='top' hasArrow label='Qr'>
              <span>
                <ButtonHeaderModule
                  onClick={onOpen}
                  className='me-1'
                  bgColor='#1F7AC3'
                >
                  <IconGeneric
                    width='16px'
                    className='text-white'
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>

            <Tooltip placement='top' hasArrow label='Exportar PDF'>
              <span>
                <ButtonHeaderModule onClick={actionExport} bgColor='#03C39A'>
                  <IconGeneric
                    width='16px'
                    className=' text-white'
                    as={VscFilePdf}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          </Box>
        </div>
      </div>
    </>
  )
}
