import axios from "axios";
import env from "../../constants/apiConst";

export const __ActivityUser = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.ACTIVITY_USER_GET}?page=${page || 1}`, options);
  return res;
};

export const __ShowType = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ACTIVITY_USER_SHOW}/${id}`, options);
  return res;
};

export const __Active = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.ACTIVE_USER}/${id}`, options);
  return res;
};

export const __Desactive = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.DESACTIVE_USER}/${id}`, options);
  return res;
};

export const fetchConfig = async (url, token, id) => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };

  const res = await axios(`${url}/${id}`, config);
  return res;
};
