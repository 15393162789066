import axios from "axios";

import env from "../../constants/apiConst";

/**
 * Obtener todos los embalajes
 * Recibe como parametro el token
 */
export const __TypeOperation = async (token, body, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: body || ""
  };

  const res = await axios(`${env.TYPE_OPERATION_GET}?page=${page || 1}`, options);
  return res;
};

export const __TypeOperationList = async (token) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPE_OPERATION_LIST}`, options);
  return res;
};

export const __ShowTypeOperation = async (token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${env.TYPE_OPERATION_SHOW}/${id}`, options);
  return res;
};
