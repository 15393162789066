import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'



const styles = StyleSheet.create({
  table: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
    flexWrap: 'wrap',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '6px',
    width: '50%',
    maxWidth: '50%'
  },
  rowLeft: {
    border: '1px solid #9fd9ff',
    borderBottom: 'none',
    backgroundColor: '#c0dbee',
    width: '30%',
    padding: 4,
    whiteSpace: 'pre-line',
  },
  rowRight: {
    border: '1px solid #EEE',
    borderBottom: 'none',
    width: '70%',
    maxWidth: '70%',
    whiteSpace: 'pre-line',
    padding: 4,
  },
})

export const ItemFormEntry = ({
  data,
  typeColumn = 'Left',
  mode = 'entry'
}) => {
  const ServiceTitle =
    mode !== 'entry'
      ? 'Salidas por servicios US$'
      : 'Ingresos por servicios US$'

  const origin_destination_value =
    mode === 'entry'
      ? `${data?.code_country_proc ?? 'N/A'} ${data?.name_country_proc ?? ''}`
      : `${data?.code_country_des ?? 'N/A'} ${data?.name_country_des ?? ''}`

  const origin_destination =
    mode === 'entry' ? 'País procedencia:' : 'País destino:'


  if (mode === "entry") {
    return (
      <View style={styles.table}>
        {/* PDF HEADER -> Item number */}
        <View style={{
          ...styles.itemRow,
          backgroundColor: '#0C89BB', padding: 4, fontSize: '10px', color: '#FFF', width: '100%',
          maxWidth: '100%'
        }}>
          <Text style={styles?.headerTitle}>Item No. {data?.item}</Text>
        </View>
        {/* FIELDS */}
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Subpartida arancelaria:</Text>
          <Text style={styles.rowRight}>{data?.code_subheading ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>País origen:</Text>
          <Text style={styles.rowRight}>
            {data?.name_country
              ? `${data?.code_country} ${data?.name_country}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{origin_destination}</Text>
          <Text style={styles.rowRight}>{origin_destination_value}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso bruto en Kg:</Text>
          <Text style={styles.rowRight}>{data?.gross_weight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso neto Kg:</Text>
          <Text style={styles.rowRight}>{data?.net_weight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Números de bultos:</Text>
          <Text style={styles.rowRight}>{data?.number_packages ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Cantidad:</Text>
          <Text style={styles.rowRight}>{data?.quantity ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Unidad comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_businessUnit
              ? `${data?.code_businessUnit} ${data?.name_businessUnit}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Modo de Transporte:</Text>
          <Text style={styles.rowRight}>
            {' '}
            {data?.code_transportMethod
              ? `${data?.code_transportMethod} ${data?.name_transportMethod}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{ServiceTitle}</Text>
          <Text style={styles.rowRight}>{data?.service ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={{ ...styles.rowLeft }}>Bandera:</Text>
          <Text style={{ ...styles.rowRight }}>
            {' '}
            {data?.code_country_flag
              ? `${data?.code_country_flag} ${data?.name_country_flag}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Embalaje:</Text>
          <Text style={styles.rowRight}>
            {data?.code_packaging
              ? `${data?.code_packaging} ${data?.name_packaging}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Fletes:</Text>
          <Text style={styles.rowRight}>{data?.us_freight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Seguros:</Text>
          <Text style={styles.rowRight}>{data?.us_insurance ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Otros gasto:</Text>
          <Text style={styles.rowRight}>
            {data?.other_us_expenses ?? 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor FOB {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.us_fob_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor CIF {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.cif_us_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor CIF COP:</Text>
          <Text style={styles.rowRight}>{data?.cif_cop_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Tasa de Cambio:</Text>
          <Text style={styles.rowRight}>{data?.exchange_rate ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Acuerdo Comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_tradeAgreement
              ? `${data?.code_tradeAgreement} ${data?.name_tradeAgreement}`
              : 'N/A'}
          </Text>
        </View>

        <View style={{
          ...styles.itemRow, width: '100%',
          maxWidth: '100%'
        }}>
          <Text wrap={true} style={{ ...styles.rowLeft, borderBottom: '1px solid #9fd9ff', width: '15%' }}>
            Descripción de la mercancía:
          </Text>
          <Text
            style={{
              ...styles.rowRight,
              borderBottom: '1px solid #EEE',
              width: '85%',
              maxWidth: '85%',
              height: 'auto',
            }}
          >
            {data?.entry_form_item_description && data?.entry_form_item_description}
          </Text>
        </View>
      </View>)

  }


  if (mode === "exit") {
    return (
      <View style={styles.table}>
        {/* PDF HEADER -> Item number */}
        <View style={{
          ...styles.itemRow,
          backgroundColor: '#0C89BB', padding: 4, fontSize: '10px', color: '#FFF', width: '100%',
          maxWidth: '100%'
        }}>
          <Text style={styles?.headerTitle}>Item No. {data?.item}</Text>
        </View>
        {/* FIELDS */}
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Referencia:</Text>
          <Text style={styles.rowRight}>{data?.reference ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor agregado US$:</Text>
          <Text style={styles.rowRight}>{data?.added_value_us ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>País compra</Text>
          <Text style={styles.rowRight}>
            {data?.countryPurchase_id
              ? `${data?.code_country_purs} ${data?.name_country_purs}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor a Reintegrar US$$:</Text>
          <Text style={styles.rowRight}>{data?.refund_value_us ?? 'N/A'}</Text>
        </View>
        {data?.item_temporary_exit && <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Item Salida:</Text>
          <Text style={styles.rowRight}>{data?.item_temporary_exit}</Text>
        </View>}
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{origin_destination}</Text>
          <Text style={styles.rowRight}>{origin_destination_value}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso bruto en Kg:</Text>
          <Text style={styles.rowRight}>{data?.gross_weight ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Subpartida arancelaria:</Text>
          <Text style={styles.rowRight}>{data?.code_subheading ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>País origen:</Text>
          <Text style={styles.rowRight}>
            {data?.name_country
              ? `${data?.code_country} ${data?.name_country}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso neto Kg:</Text>
          <Text style={styles.rowRight}>{data?.net_weight ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Números de bultos:</Text>
          <Text style={styles.rowRight}>{data?.number_packages ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Cantidad:</Text>
          <Text style={styles.rowRight}>{data?.quantity ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Unidad comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_businessUnit
              ? `${data?.code_businessUnit} ${data?.name_businessUnit}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Modo de Transporte:</Text>
          <Text style={styles.rowRight}>
            {' '}
            {data?.code_transportMethod
              ? `${data?.code_transportMethod} ${data?.name_transportMethod}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{ServiceTitle}</Text>
          <Text style={styles.rowRight}>{data?.service ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={{ ...styles.rowLeft }}>Bandera:</Text>
          <Text style={styles.rowRight}>
            {' '}
            {data?.code_country_flag
              ? `${data?.code_country_flag} ${data?.name_country_flag}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Embalaje:</Text>
          <Text style={styles.rowRight}>
            {data?.code_packaging
              ? `${data?.code_packaging} ${data?.name_packaging}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Fletes:</Text>
          <Text style={styles.rowRight}>{data?.us_freight ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Seguros:</Text>
          <Text style={styles.rowRight}>{data?.us_insurance ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Otros gastos:</Text>
          <Text style={styles.rowRight}>
            {data?.other_us_expenses ?? 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Ajuste valor:</Text>
          <Text style={styles.rowRight}>
            {data?.value_adjustment ?? 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor FOB Real {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.us_fob_value ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor CIF {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.cif_us_value ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor CIF COP:</Text>
          <Text style={styles.rowRight}>{data?.cif_cop_value ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Tasa de Cambio:</Text>
          <Text style={styles.rowRight}>{data?.exchange_rate ?? 'N/A'}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Acuerdo Comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_tradeAgreement
              ? `${data?.code_tradeAgreement} ${data?.name_tradeAgreement}`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor FOB Inicial US$</Text>
          <Text style={styles.rowRight}>
            {data?.original_fob_value_us ?? 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Justificación del ajuste:</Text>
          <Text style={styles.rowRight}>
            {data?.adjustment_justification_fob ?? 'N/A'}
          </Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Declaración de importación:</Text>
          <Text style={styles.rowRight}>
            {data?.custom_clearance ?? 'N/A'}
          </Text>
        </View>
        <View style={{
          ...styles.itemRow, width: '100%',
          maxWidth: '100%'
        }}>
          <Text style={{ ...styles.rowLeft, borderBottom: '1px solid #9fd9ff', width: '15%', }}>
            Descripción de la mercancía:
          </Text>
          <Text
            style={{
              ...styles.rowRight,
              borderBottom: '1px solid #EEE',
              width: '85%',
              maxWidth: '85%',
              height: 'auto',
            }}
          >
            {data?.output_form_item_description
              ? data?.output_form_item_description
              : ''}
          </Text>
        </View>
      </View>
    )

  }

  return <></>
  if (typeColumn === 'Left') {
    return (
      <View style={styles.row} wrap={false}>
        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>Referencia:</Text>
            <Text style={styles.rowRight}>{data?.reference ?? 'N/A'}</Text>
          </View>
        )}

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Subpartida arancelaria:</Text>
          <Text style={styles.rowRight}>{data?.code_subheading ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>País origen:</Text>
          <Text style={styles.rowRight}>
            {data?.name_country
              ? `${data?.code_country} ${data?.name_country}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{origin_destination}</Text>
          <Text style={styles.rowRight}>{origin_destination_value}</Text>
        </View>

        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>País compra</Text>
            <Text style={styles.rowRight}>
              {data?.countryPurchase_id
                ? `${data?.code_country_purs} ${data?.name_country_purs}`
                : 'N/A'}
            </Text>
          </View>
        )}

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso bruto en Kg:</Text>
          <Text style={styles.rowRight}>{data?.gross_weight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Peso neto Kg:</Text>
          <Text style={styles.rowRight}>{data?.net_weight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Números de bultos:</Text>
          <Text style={styles.rowRight}>{data?.number_packages ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Cantidad:</Text>
          <Text style={styles.rowRight}>{data?.quantity ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Unidad comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_businessUnit
              ? `${data?.code_businessUnit} ${data?.name_businessUnit}`
              : 'N/A'}
          </Text>
        </View>

        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>Unidad de producto</Text>
            <Text style={styles.rowRight}>
              {`${data?.code_unit_measurement || ''} ${data?.unit_measurement || '0'
                }`}
            </Text>
          </View>
        )}

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Modo de Transporte:</Text>
          <Text style={styles.rowRight}>
            {' '}
            {data?.code_transportMethod
              ? `${data?.code_transportMethod} ${data?.name_transportMethod}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>{ServiceTitle}</Text>
          <Text style={styles.rowRight}>{data?.service ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={{ ...styles.rowLeft, height: '20px' }}>Bandera:</Text>
          <Text
            style={{
              border: '1px solid #EEE',
              width: '100%',
              height: '20px',
              padding: 4
            }}
          >
            {' '}
            {data?.code_country_flag
              ? `${data?.code_country_flag} ${data?.name_country_flag}`
              : 'N/A'}
          </Text>
        </View>
      </View>
    )
  }

  if (typeColumn === 'Right') {
    return (
      <View style={styles.row} wrap={false}>
        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Embalaje:</Text>
          <Text style={styles.rowRight}>
            {data?.code_packaging
              ? `${data?.code_packaging} ${data?.name_packaging}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Fletes:</Text>
          <Text style={styles.rowRight}>{data?.us_freight ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Seguros:</Text>
          <Text style={styles.rowRight}>{data?.us_insurance ?? 'N/A'}</Text>
        </View>

        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>Valor FOB Inicial US$</Text>
            <Text style={styles.rowRight}>
              {data?.original_fob_value_us ?? 'N/A'}
            </Text>
          </View>
        )}

        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>Código producto</Text>
            <Text style={styles.rowRight}>{data?.code_product ?? 'N/A'}</Text>
          </View>
        )}

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Cantidad de producto</Text>
          <Text style={styles.rowRight}>{data?.product_quantity ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Otros gasto:</Text>
          <Text style={styles.rowRight}>
            {data?.other_us_expenses ?? 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor FOB Real {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.us_fob_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>
            Valor CIF {data?.coin_corrected ?? ''}:
          </Text>
          <Text style={styles.rowRight}>{data?.cif_us_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Valor CIF COP:</Text>
          <Text style={styles.rowRight}>{data?.cif_cop_value ?? 'N/A'}</Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Tasa de Cambio:</Text>
          <Text style={styles.rowRight}>{data?.exchange_rate ?? 'N/A'}</Text>
        </View>

        {mode !== 'entry' && (
          <View style={styles.itemRow}>
            <Text style={styles.rowLeft}>Justificación del ajuste:</Text>
            <Text style={styles.rowRight}>
              {data?.adjustment_justification_fob ?? 'N/A'}
            </Text>
          </View>
        )}

        <View style={styles.itemRow}>
          <Text style={styles.rowLeft}>Acuerdo Comercial:</Text>
          <Text style={styles.rowRight}>
            {data?.code_tradeAgreement
              ? `${data?.code_tradeAgreement} ${data?.name_tradeAgreement}`
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.itemRow}>
          <Text style={{ ...styles.rowLeft, height: '20px' }}>
            Descripción de la mercancía:
          </Text>
          <Text
            style={{
              border: '1px solid #EEE',
              width: '100%',
              height: '20px',
              padding: 4
            }}
          >
            {' '}
            {data?.entry_form_item_description
              ? data?.entry_form_item_description
              : data?.output_form_item_description
                ? data?.output_form_item_description
                : 'N/A'}
          </Text>
        </View>
      </View>
    )
  }
}
