/* eslint-disable no-unused-vars */
import { useModal } from 'hooks/useModal'

import {
  __DeleteProduct,
  __EditCreateProduct,
  __EditProduct
} from 'request/productoInterno/__ProductoInterno'
import { useSelector } from 'react-redux'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import FormCrudCRUDContext from './FormCrudContex'
import environment from 'constants/apiConst'
import isArrayNotEmpty, { isArray } from 'utils/type-check-utils'
import { useNotificationHook } from 'hooks/useNotificationHook '
import { MdError } from 'react-icons/md'
import { Box, Text } from '@chakra-ui/react'

const { createContext, useState, useContext } = require('react')

const ProductCRUDContext = createContext()

export const ProductCRUDProvider = ({ children }) => {
  const [isOpenCarga, onOpenCarga, onCloseCarga] = useModal()
  const [isOpenCreate, onOpenCreate, onCloseCreate] = useModal()
  const [isLoading, onLoading, noLoading] = useModal()
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal()
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal()
  const [isOpenShow, onOpenShow, onCloseShow] = useModal()
  const [dataToEdit, setDataToEdit] = useState(null)
  const [dataToDelete, setDataToDelete] = useState(null)
  const [dataToShow, setDataToShow] = useState(null)
  const [requestEdit, setRequestEdit] = useState(null) //
  const [requestDelete, setRequestDelete] = useState(null)
  const [requestCreate, setRequestCreate] = useState(null) //
  const [formEdit, setFormEdit] = useState(null)
  const [formShow, setFormShow] = useState(null)
  const [update, setUpdate] = useState(true)
  const [uploadProductsWithId, setUploadProductsWithId] = useState(null)
  const token = useSelector(state => state.auth.dataUser.token)
  const [loading, setLoading] = useState(false)
  const [openAlert] = AlertErrorAlert()
  const [showNotification] = useNotificationHook()

  const { getCodeProduct, changeQualified } = useContext(FormCrudCRUDContext)

  const editData = async (data, callback = () => {}) => {
    try {
      const res = await __EditProduct(requestEdit, token, data)
      const { status } = res.data

      if (status.code === 200) {
        setDataToEdit(null)
        openAlert('Registro actualizado exitosamente', 'success')
        setUpdate(prevUpdate => !prevUpdate)
        callback(status)
      } else {
        const message = res?.data?.status?.message
        const data = res?.data?.status?.data
        const messageArray = isArray(message)
          ? message
          : isArray(data)
          ? data
          : []

        if (isArrayNotEmpty(messageArray)) {
          const FormMessage = messageArray.map(alert => {
            const isFormMessage = `N.° de form ${alert?.code_entryForm}`
            return isFormMessage
          })

          const ModelNotification = () => {
            return (
              <Box display={'flex'} flexDirection={'column'}>
                <Text>
                  Este producto se encuentra asociado a los siguientes
                  formularios:
                </Text>
                <br />

                {isArray(FormMessage) &&
                  FormMessage.map((alert, index) => (
                    <Text key={index}>{alert}</Text>
                  ))}
              </Box>
            )
          }

          showNotification(<ModelNotification />, 'warn')
        } else {
          return openAlert(message, 'error')
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteData = async () => {
    try {
      const res = await __DeleteProduct(requestDelete, token, dataToDelete.id)
      // console.log(res);
      if (res.data.status.code === 200) {
        setDataToDelete(null)
        onCloseDelete()
        openAlert(`Registro eliminado Exitosamente`, 'success')
        setUpdate(!update)
      } else {
        openAlert(res.data.status.message, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showData = () => {
    console.log(dataToShow)
  }

  const createData = async (data, Url) => {
    const producto = 'el producto'
    const process = 'el proceso productivo'
    const Unit = 'la Unidad de Medida'
    try {
      const res = await __EditCreateProduct(Url ?? requestCreate, token, data)
      // console.log(res);
      if (res.data.status.code === 200) {
        const idQualifiedUser = res?.data?.status?.data?.qualifiedUser_id
        if (changeQualified === idQualifiedUser) {
          getCodeProduct(idQualifiedUser)
        }
        onLoading()
        openAlert(
          `Se agrego ${
            data.code_product
              ? producto
              : data.code_productive_process
              ? process
              : Unit
          } 
        ${
          data?.code_product
            ? data?.code_product
            : data?.code_productive_process
            ? data?.code_productive_process
            : data?.code_unit_measurement
        }`,
          'success'
        )
        noLoading()
        onCloseModal()
        setUpdate(!update)
      } else {
        const message = res?.data?.status?.message

        if (Array.isArray(message)) {
          message.map(alert => {
            return openAlert(alert, 'error')
          })
        } else {
          return openAlert(message, 'error')
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setUploadProductsWithId(null)
    }
  }

  const onCloseModal = () => {
    if (isOpenCreate) return onCloseCreate()
    if (isOpenEdit) {
      onCloseEdit()
      setDataToEdit(null)
      return
    }
    if (isOpenDelete) {
      onCloseDelete()
      return
    }
    if (isOpenShow) {
      onCloseShow()
    }
  }

  const data = {
    editData,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    setDataToShow,
    showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    formShow,
    setFormShow,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    setLoading,
    loading,
    isOpenCarga,
    onOpenCarga,
    onCloseCarga,
    uploadProductsWithId,
    setUploadProductsWithId,
    isOpenCreate,
    onOpenCreate,
    onCloseCreate,
    isLoading,
    onLoading,
    noLoading
  }
  return (
    <ProductCRUDContext.Provider value={data}>
      {children}
    </ProductCRUDContext.Provider>
  )
}

export default ProductCRUDContext
