import React, { useContext } from "react";
import styled from "styled-components/macro";

import ModalDelete from "components/modal/ModalDelete";
import ModalTransition from "components/modal/ModalTransition";
import { SectionContainer } from "styled/SectionContainer.styled";
import SectionHeader from "components/SectionHeader/SectionHeader";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { ConfigurationRoutes } from "routes/ConfigurationRoutes";

const MainConfiguration = styled.section`
  box-sizing: border-box;
  width: 100%;
  /* padding: 0.5rem; */
`;

const Configuration = () => {
  const {
    editData,
    isOpenEdit,
    isOpenDelete,
    formLicensias,
    deleteData,
    onCloseShow,
    formEdit,
    formShow,
    isOpenShow,
    onCloseModal,
    isOpenLicenses,
    isOpenDocument,
    formDocument,
    formRemove,
    isOpenRemove,
    isOpenLicencia
  } = useContext(ConfigurationCRUDContext);

  return (
    <>
    {/* Manejos de modales para configuración */}
      <ModalTransition
        isOpen={isOpenEdit}
        onClose={onCloseModal}
        size={"4xl"}
        isCentered={false}
        // modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={'outside'}
      >
        {formEdit}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenLicencia}
        onClose={onCloseModal}
        size={"4xl"}
        isCentered={false}
        // modaltitle={""}
        handleClick={editData}
        buttonMain={"Editar"}
        scrollBehavior={'outside'}
      >
        {formEdit}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenLicenses}
        onClose={onCloseModal}
        size={"lg"}
        isCentered={false}
        // modaltitle={"Asignar Licencia"}
        // handleClick={editData}
      >
        {formLicensias}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenDocument}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Asignar Documento"}
        isCentered={false}
        // handleClick={editData}
      >
        {formDocument}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenRemove}
        onClose={onCloseModal}
        size={"lg"}
        modaltitle={"Desasociar Documento"}
        isCentered={false}
        // handleClick={editData}
      >
        {formRemove}
      </ModalTransition>

      <ModalTransition
        isOpen={isOpenShow}
        onClose={onCloseModal}
        size={"lg"}
        // modaltitle={"Ver información"}
        handleClick={onCloseShow}
        isCentered={false}
        buttonMain={"Aceptar"}
      >
        {formShow}
      </ModalTransition>

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento`}
        onDelete={deleteData}
      />

      <SectionHeader secTitle="Configuración" />
      <SectionContainer>
        <MainConfiguration>
          <ConfigurationRoutes />
        </MainConfiguration>
      </SectionContainer>
    </>
  );
};

export default Configuration;
