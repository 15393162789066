import axios from "axios";

/**
 * Esta funcion sera la que ejecuté todas las funciones relacionadas con editar en el modulo de configuraciones.
 * Será llamada en el ConfigCRUDContext
 * recibe el endpoint en el que editará, el token y el body
 */
export const __EditCreateConfig = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(body)
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};

export const __DeleteConfig = async (endpoint, token, id) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  };

  const res = await axios(`${endpoint}/${id}`, options);
  return res;
};

export const __EditCreateConfigFormData = async (endpoint, token, body) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data"
    },
    data: body || ""
  };

  const res = await axios(`${endpoint}`, options);
  return res;
};
