import React, { useContext, useState } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";

import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

import { ButtonStyled } from "styled/Buttons.styled";
import InputSelect from "components/InputSelect/InputSelect";
import {
  complentitud,
  Operation,
  times,
  withReweight
} from "./Array/ArraySearchAdvanced";
import GlobalContext from "context/GlobalContext";
import { useSearchUrl } from "hooks/useSearchUrl";

const FormSearchWeighing = ({ setSearchAdvanced, onClose }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["order", "consecutive", "vehiclePlate", "completeness", "reweight", "startDate", "endDate", "shedule", "operation", "formNumber"], nestedProp: "searchAdvance" });

  const optionesAsc = ["Ascendente", "Descendente"];
  const [completen, setCompleten] = useState(complentitud?.find((ops) => ops.value === state?.completeness));
  const [operation, setOperation] = useState(Operation.find((ops) => ops.value === state?.operation));
  const [shedules, setsShedules] = useState(times?.find((ops) => ops.value === state?.shedule));
  const [reweight, setReweight] = useState(withReweight?.find((ops) => ops.value === state?.reweight));
  const [ordenado, setOrdenado] = useState(state?.order === "asc" ? "Ascendente" : "Descendente");
  const { user_Qualified } = useContext(GlobalContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    consecutive: state?.consecutive || "",
    vehiclePlate: state?.vehiclePlate || "",
    startDate: state?.startDate || "",
    endDate: state?.endDate || "",
    formNumber: state?.formNumber || "",
  });

  const { consecutive, vehiclePlate, startDate, endDate, formNumber } = values;

  const handleSubmit = (e) => {
    const data = {
      scale: true,
      ...(ordenado && { order: ordenado === "Descendente" ? "desc" : "asc" }),
      ...(user_Qualified && { qualifiedUser_id: user_Qualified }),
      ...(consecutive && { consecutive }),
      ...(vehiclePlate && { vehiclePlate }),
      ...(completen && { completeness: completen?.value }),
      ...(reweight && { reweight: reweight?.value }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(shedules && { shedule: shedules?.value }),
      ...(operation && { operation: operation?.value }),
      ...(formNumber && { formNumber }),
    }

    setParamsSearch({ params: data });
    setSearchAdvanced(data);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"consecutive"}
          value={consecutive}
          onChange={handleInputChange}
          tag={"Consecutivo pesaje"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"vehiclePlate"}
          value={vehiclePlate}
          onChange={handleInputChange}
          tag={"Placa del vehiculo"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"startDate"}
          value={startDate}
          onChange={handleInputChange}
          tag={"Fecha inicio"}
          size={45}
          tipo={"date"}
        />

        <LabelGroup
          name={"endDate"}
          value={endDate}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        />
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"startTime"}
          value={startTime}
          onChange={handleInputChange}
          tag={"Fecha inicio"}
          size={45}
          tipo={"time"}
        />

        <LabelGroup
          name={"endTime"}
          value={endTime}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"time"}
        />
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Fecha de"}
            data={dateBy}
            value={dateOf}
            set={setDateOf}
          />
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Operación"}
            data={Operation}
            value={operation}
            set={setOperation}
          />
        </div>

        <LabelGroup
          name={"formNumber"}
          value={formNumber}
          onChange={handleInputChange}
          tag={"Num. Formulario"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <LabelGroup
          value={container}
          tag={"Contenedor"}
          onChange={handleInputChange}
          size={45}
          tipo={"text"}
        /> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          //   name={"code"}
          //   value={code}
          //   onChange={handleInputChange}
          tag={"Operario"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />

        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Horario"}
            data={times}
            value={shedules}
            set={setsShedules}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        {/* <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Ordenar por"}
            data={OrderBy}
            // value={}
            // set={}
          />
        </div> */}

        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Completitud"}
            data={complentitud}
            value={completen}
            set={setCompleten}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Con Repeso"}
            data={withReweight}
            value={reweight}
            set={setReweight}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div className="mt-3">
          <RadioBtn
            options={optionesAsc}
            setOnly={setOrdenado}
            state={ordenado}
          />
        </div>
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormSearchWeighing;
