import { executeHTTPRequest, options } from "request/Forms/queryService";

export const generateEndpoint = (Endpoint, resourceIdentifier, pageParam) => {
  if (resourceIdentifier && pageParam) { return `${Endpoint}/${resourceIdentifier}?page=${pageParam}`; }
  if (resourceIdentifier) return `${Endpoint}/${resourceIdentifier}`;
  if (pageParam) return `${Endpoint}?page=${pageParam}`;

  return Endpoint;
};

export const requestData = async ({
  pageParam = null,
  resourceIdentifier = null,
  data,
  Endpoint = null,
  PropertyBody = "get",
  token = null
}) => {
  if (!PropertyBody) {
    throw new Error("PropertyBody is missing");
  }

  const endpointURL = generateEndpoint(Endpoint, resourceIdentifier, pageParam);
  const conf = options?.[PropertyBody.toUpperCase()](token, data);
  const res = await executeHTTPRequest(endpointURL, conf);

  return res;
};

export default requestData;
