import { useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import { Box, Center, Divider, Icon, Text } from '@chakra-ui/react'
import queryString from 'query-string'
import { BsFillPersonFill, BsRecordCircle } from 'react-icons/bs'
import { FaRegCheckCircle, FaWeight } from 'react-icons/fa'
import { BiXCircle } from 'react-icons/bi'

export const CardCorrections = ({ data }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { movement = '' } = queryString.parse(location.search)
  const ColorRender =
    data?.tracking_status === 1
      ? 'green'
      : data?.tracking_status === 2
        ? 'red'
        : 'gray'

  return (
    <>
      <Box
        cursor={'pointer'}
        onClick={() =>
          navigate(
            movement === 'Corrección de ingreso'
              ? `/FormCorrection?ShowEntry=${data.form_id}`
              : `/FormCorrectionOutput?ShowOutput=${data.form_id}`
          )
        }
        maxW='sm'
        p='1'
        m='1'
        shadow={
          'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'
        }
        borderRadius='lg'
        overflow='hidden'
        display={'flex'}
      >
        <Box
          m='2'
          pt='2'
          fontSize={'38px'}
          maxW='100px'
          maxH={'200px'}
          borderRadius='lg'
          shadow={
            'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
          }
          backgroundColor={'blue.600'}
          textColor={'white'}
          w='50%'
        >
          <Center flexDirection={'column'}>
            <BsFillPersonFill />
            <Divider w={'8'} mt='1' />
            <Box display='flex' alignItems='baseline'>
              <Box
                color='black.300'
                letterSpacing='wide'
                fontSize='12px'
                mb='1'
              >
                {/*    <b>10</b> subpatidas <br / > */} <b>{data?.items}</b> Items
              </Box>
            </Box>
          </Center>
        </Box>

        <Box flexDir={'row'} m='2' w='50%'>
          <Text
            mt='1'
            fontWeight='semibold'
            as='h4'
            lineHeight='tight'
            noOfLines={1}
            fontSize={'14px'}
            pt='3'
          >
            {data.name_qualified}
          </Text>
          <Text fontSize={'12px'}>Nº {data?.num_form}</Text>
          <Text fontSize={'12px'}>Nit: {data?.nit_qualified}</Text>
          <Text fontSize={'12px'}>Operación: {data?.code_operation}</Text>
        </Box>

        <Box flexDir={'row'} m='2' w='50%'>
          <Text
            mt='1'
            textAlign={'right'}
            as='h4'
            lineHeight='tight'
            noOfLines={1}
            fontSize={'14px'}
            pb='2'
            pt='2'
          >
            {data?.date_submitted}
          </Text>
          <Text fontSize={'12px'}>
            <Icon as={BsRecordCircle} boxSize={5} color={ColorRender} pr={1} />
            Seguimiento
          </Text>

          <Text fontSize={'12px'}>
            <Icon
              as={data?.inspection === 1 ? FaRegCheckCircle : BiXCircle}
              boxSize={5}
              color={
                data?.inspection === 1
                  ? 'green'
                  : data?.inspection === 2
                    ? 'red'
                    : 'gray'
              }
              pr={1}
            />
            inspección
          </Text>
          <Text fontSize={'12px'}>
            <Icon
              as={FaWeight}
              boxSize={5}
              color={data?.weighing ? 'green.500' : 'gray.500'}
              pr={1}
            />
            Pesaje
          </Text>
        </Box>
      </Box>
    </>
  )
}
