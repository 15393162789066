import { Td, Tr } from "@chakra-ui/react";

const TBodyLogOp = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center"> {data?.code_production_order ? data?.code_production_order : "N/A"}</Td>
        <Td className="text-center"> {data?.code_productive_process ? data?.code_productive_process : "N/A"}</Td>
        <Td className="text-center"> {data?.description_productive_process ? data?.description_productive_process : "N/A"}</Td>
        <Td className="text-center"> {data?.code_product ? data?.code_product : "N/A"}</Td>
        <Td className="text-center"> {data?.description_product ? data?.description_product : "N/A"}</Td>
        <Td className="text-center"> {data?.net_weight_kilo ? data?.net_weight_kilo : "N/A"}</Td>
        <Td className="text-center"> {data?.total_product ? data?.total_product : "N/A"}</Td>
        <Td className="text-center"> {data?.total_calculated ? data?.total_calculated : "N/A"}</Td>
      </Tr>
    </>
  );
};

export default TBodyLogOp;
