import React, { useContext, useState } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowLicencia } from "request/configuration/__Roles";
import { ConfigIconStatus } from "./components/LoadingConfig";
import { AiFillEye } from "react-icons/ai";
import { IconGeneric } from "styled/Icons.styled";
import { useQueryClient } from "@tanstack/react-query";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyLicencia = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading, onOpenView, setidViewLicencia } =
    useContext(ConfigurationCRUDContext);
  const [loadingIcons, setLoadingIcons] = useState(false);
  const [isHandlingState, setIsHandlingState] = useState(false);
  const [openAlert] = AlertErrorAlert();

  const queryClient = useQueryClient();

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.LICENCIA_DESACTIVAR, data.id)
          : await __ACTIVAR(token, environment.LICENCIA_ACTIVAR, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        setUpdate(!update);
        queryClient.invalidateQueries({ queryKey: ["Licences"]});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    if (isHandlingState) {
      return;
    }

    setIsHandlingState(true);
    setLoadingIcons(true);

    try {
      const res = await __ShowLicencia(token, data.id);
      const result = res.data.status.data.status_license;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
      setIsHandlingState(false);
    }
  };

  return (
    <Tr>
      <Td className="text-center">{data?.uc}</Td>
      <Td className="text-center">{data?.name_license}</Td>
      <Td className="text-center">{data?.expireDate_license}</Td>
      <Td className="text-center">{data?.description_license}</Td>

      <Tooltip placement="top" hasArrow label={data?.name_qualified}>
        <Td
          className="text-center"
          onClick={() => {
            setidViewLicencia(data);
            onOpenView();
          }}
        >
          <IconGeneric color={"#1F7AC3"} as={AiFillEye} />
        </Td>
      </Tooltip>

      <Tooltip
        placement="top"
        hasArrow
        label={data?.status_license === 1 ? "Desactivar" : "Activar"}
      >
        <TdState
          onClick={handleState}
          className="text-center"
          isNumeric
          text={data?.status_license}
        >
          <ConfigIconStatus
            loading={loadingIcons}
            status={data?.status_license}
          />
        </TdState>
      </Tooltip>
      <Td isNumeric className="text-center">
        <MenuRUD data={data} deleteField={false} />
      </Td>
    </Tr>
  );
};

export default TbodyLicencia;
