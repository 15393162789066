import React, { useContext, useEffect, useState } from "react";
import { Checkbox, IconButton, SimpleGrid, Tooltip } from "@chakra-ui/react";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import { Title } from "styled/Title";
import { CheckDisable } from "hooks/useLocalStorage";
import { RadioCheck } from "components/ComponentRadioButton/RadioCheck";

import FormCrudCRUDContext from "context/FormCrudContex";
import { searchObservation } from "./Functions/searchObservation";

import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import { useGlobalContext } from "context/GlobalContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Regex } from "utils/Regex";
import AddItemsReentryOperation from "../AddItemForm/AddItemsReentryOperation";
import { CgAddR } from "react-icons/cg";
import { useModal } from "hooks/useModal";
import { getAuthData } from "redux/actions/authentication";
import { useSelector } from "react-redux";
import { QualifiedUser } from "pages/RefProductInter/QualifiedUser";

const RestWorld = ({
  values,
  setValues,
  handleInputChange,
  code,
  formOperation,
  colorNotation = () => { },
  reviewData = {}
}) => {
  const [fechasHoy, setfechasHoy] = useState(null);
  const { dataFormCurrent } = useContext(FormCrudCRUDContext);
  const fechaDeManana = () => {
    const hoy = new Date();
    const DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    const manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    manana.toISOString().substring(0, 10);
    return setfechasHoy(manana);
  };
  useEffect(() => {
    fechaDeManana();
  }, []);

  const { dataType, typeEntryCurrent, setTypeEntryCurrent, onOpenSelectAuthorizationItems } = useContext(FormCrudCRUDContext);
  const location = useLocation()
  const { FormExitNumber = null, FormEntryNumber = null } = queryString.parse(
    location.search
  )

  const { dataUser } = useSelector(getAuthData);
  const {
    dcl_customs_transit,
    transit_expiration_date,
    num_transport_document,
    transp_doc_date,
    cargo_manifest_entryForm,
    num_commercial_invoice,
    num_exit_form,
    integration_box_certificate,
    cabotage,
    cabotage_expiration_date,
    reviews
  } = values;

  /*   useEffect(() => {
    console.log({ reviewsNotations });
    const tooltipText = reviewsNotations?.getNestedPropertyValue("Header", "transit_expiration_date");
    console.log({ tooltipText });
  }, [reviewsNotations]); */

  const validateTypeFunction = (data) => typeof data === "function";

  const metaDataDTACabotage = {
    DTA: {
      name: "dcl_customs_transit",
      value: dcl_customs_transit,
      label: "Dcl. Tránsito aduanero"
    },
    Cabotaje: {
      name: "cabotage",
      value: cabotage,
      label: "Cabotaje"
    }
  }[typeEntryCurrent || ""];

  const metaExpirationDate = {
    DTA: {
      name: "transit_expiration_date",
      value: transit_expiration_date,
      label: "Fecha de vencimiento Tránsito"
    },
    Cabotaje: {
      name: "cabotage_expiration_date",
      value: cabotage_expiration_date,
      label: `Fecha de vencimiento ${typeEntryCurrent}`
    }
  }[typeEntryCurrent || ""];

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };




  useEffect(() => {
    if (FormEntryNumber === null && FormExitNumber === null) {
      setValues((prev) => ({
        ...prev,
        num_exit_form: '',
        integration_box_certificate: ''
      }))
    }
  }, [FormEntryNumber, FormExitNumber])

  return (
    <div
      className="mx-1 shadow-none p-1 mb-5  rounded "
      style={{ background: "#f2f2f2" }}
    >

      <Title size={"18px"} weight={700} className="mb-2">
        Desde el resto del mundo
      </Title>

      <SimpleGrid minChildWidth="200px" spacing="10px">
        <div>
          <RadioCheck
            set={setTypeEntryCurrent}
            value={typeEntryCurrent}
            item={dataType}
          />
        </div>
      </SimpleGrid>

      {typeEntryCurrent !== "Planilla de envío" && (
        <SimpleGrid minChildWidth="200px" spacing="10px" className={"mt-4"}>
          <Tooltip
            hasArrow
            label={reviewData?.dcl_customs_transit?.comment}
            bg={colorNotation("dcl_customs_transit")}
            color="white"
          >
            <div>
              <LabeFormlStyled htmlFor="dcl_customs_transit">
                {metaDataDTACabotage?.label}
              </LabeFormlStyled>

              <InputFormStyled
                type="text"
                name={typeEntryCurrent === "DTA"
                  ? "dcl_customs_transit"
                  : typeEntryCurrent === "Cabotaje"
                    ? "cabotage"
                    : ""}
                ColorFake={colorNotation("dcl_customs_transit")}
                data-input-tag={metaDataDTACabotage?.label}
                data-item-id={"Header"}
                data-old-Value={typeEntryCurrent === "DTA"
                  ? dataFormCurrent?.dcl_customs_transit
                  : typeEntryCurrent === "Cabotaje"
                    ? dataFormCurrent?.cabotage
                    : ""}
                value={
                  typeEntryCurrent === "DTA"
                    ? dcl_customs_transit
                    : typeEntryCurrent === "Cabotaje"
                      ? cabotage
                      : ""
                }
                onChange={(e) =>
                  handleRegex(e, "all", Regex?.all)
                }
                maxLength={
                  MaxLength.formEntry.formEntryNumber.dcl_customs_transit
                }
              />
            </div>
          </Tooltip>
          <Tooltip
            hasArrow
            label={reviewData?.transit_expiration_date?.comment}
            bg={colorNotation("transit_expiration_date")}
            color="white"
          >
            <div>
              <div>
                <LabeFormlStyled>{metaExpirationDate?.label}</LabeFormlStyled>
                <InputFormStyled
                  type={"date"}
                  name={typeEntryCurrent === "DTA"
                    ? "transit_expiration_date"
                    : typeEntryCurrent === "Cabotaje"
                      ? "cabotage_expiration_date"
                      : ""}
                  ColorFake={colorNotation("transit_expiration_date")}
                  data-input-tag={transit_expiration_date?.label}
                  data-item-id={"Header"}
                  data-old-Value={dataFormCurrent?.transit_expiration_date}
                  value={
                    typeEntryCurrent === "DTA"
                      ? transit_expiration_date
                      : typeEntryCurrent === "Cabotaje"
                        ? cabotage_expiration_date
                        : ""
                  }
                  onChange={handleInputChange}
                  min={fechasHoy}
                />
              </div>
            </div>
          </Tooltip>

          <Tooltip
            hasArrow
            label={reviewData?.num_transport_document?.comment}
            bg={colorNotation("num_transport_document")}
            color="white"
          >
            <div>
              <LabeFormlStyled htmlFor="num_transport_document">
                Nº Documento Transporte
              </LabeFormlStyled>
              <InputFormStyled
                ColorFake={() => colorNotation("num_transport_document")}
                type="text"
                name="num_transport_document"
                id="num_transport_document"
                data-input-tag={"Nº Documento Transporte"}
                data-item-id={"Header"}
                data-old-Value={dataFormCurrent?.num_transport_document}
                value={num_transport_document}
                onChange={(e) =>
                  handleRegex(e, "all", Regex?.all)
                }
                maxLength={
                  MaxLength.formEntry.formEntryNumber.num_transport_document
                }
              />
            </div>
          </Tooltip>
        </SimpleGrid>
      )}
      <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-4">
        {typeEntryCurrent === "Planilla de envío" && (
          <Tooltip
            hasArrow
            label={reviewData?.num_transport_document?.comment}
            bg={colorNotation("num_transport_document")}
            color="white"
          >
            <div>
              <LabeFormlStyled htmlFor="num_transport_document">
                Nº Documento Transporte
              </LabeFormlStyled>
              <InputFormStyled
                type="text"
                ColorFake={colorNotation("num_transport_document")}
                name="num_transport_document"
                id="num_transport_document"
                data-input-tag={"Nº Documento Transporte"}
                data-item-id={"Header"}
                data-old-Value={dataFormCurrent?.num_transport_document}
                value={num_transport_document}
                onChange={(e) =>
                  handleRegex(e, "all", Regex?.all)
                }
                maxLength={
                  MaxLength.formEntry.formEntryNumber.num_transport_document
                }
              />
            </div>
          </Tooltip>
        )}

        <Tooltip
          hasArrow
          label={reviewData?.transp_doc_date?.comment}
          bg={colorNotation("transp_doc_date")}
          color="white"
        >
          <div>
            <div>
              <LabeFormlStyled>Fecha Documento Transporte</LabeFormlStyled>
              <InputFormStyled
                type={"date"}
                name="transp_doc_date"
                value={
                  transp_doc_date === null || transp_doc_date === undefined ? "" : transp_doc_date.slice(0, 10)
                }
                data-input-tag={"Fecha Documento Transporte"}
                data-item-id={"Header"}
                data-old-Value={dataFormCurrent?.transp_doc_date}
                ColorFake={colorNotation("transp_doc_date")}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Tooltip>

        <Tooltip
          hasArrow
          label={reviewData?.cargo_manifest_entryForm?.comment}
          bg={colorNotation("cargo_manifest_entryForm")}
          color="white"
        >
          <div>
            <LabeFormlStyled htmlFor="cargo_manifest_entryForm">
              Manifiesto de carga
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="cargo_manifest_entryForm"
              id="cargo_manifest_entryForm"
              data-input-tag={"Manifiesto de carga"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.cargo_manifest_entryForm}
              ColorFake={colorNotation("cargo_manifest_entryForm")}
              value={cargo_manifest_entryForm}
              onChange={(e) => handleRegex(e, "all", Regex?.all)}
              maxLength={
                MaxLength.formEntry.formEntryNumber.cargo_manifest_entryForm
              }
            />
          </div>
        </Tooltip>
      </SimpleGrid>
      <Tooltip
        hasArrow
        label={reviewData?.num_commercial_invoice?.comment}
        bg={colorNotation("num_commercial_invoice")}
        color="white"
      >
        <div>
          <LabeFormlStyled htmlFor="num_commercial_invoice">
            Nº Factura comercial{" "}
            <span style={{ color: "red" }}>
              {formOperation?.reentry_operation === 0 ? " *" : ""}
            </span>
          </LabeFormlStyled>
          <InputFormStyled
            type="text"
            name="num_commercial_invoice"
            id="num_commercial_invoice"
            data-input-tag={"Nº Factura comercial"}
            data-item-id={"Header"}
            data-old-Value={dataFormCurrent?.num_commercial_invoice}
            value={num_commercial_invoice}
            ColorFake={() => colorNotation("num_commercial_invoice")}
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            maxLength={
              MaxLength.formEntry.formEntryNumber.num_commercial_invoice
            }
          />
        </div>
      </Tooltip>

      {code === 108 || code === 107 || formOperation?.reentry_operation === 1
        ? (
          <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-5">
            <Tooltip
              hasArrow
              label={reviewData?.num_exit_form?.comment}
              bg={colorNotation("num_exit_form")}
              color="white"
            >
              <div>
                <LabeFormlStyled htmlFor="term">
                  Nº Autorización de salida
                </LabeFormlStyled>
                <div className='d-flex justify-content-center align-items-center'>
                  <InputFormStyled
                    type="text"
                    name="num_exit_form"
                    id="num_exit_form"
                    data-input-tag={" Nº Autorización de salida"}
                    data-item-id={"Header"}
                    data-old-Value={dataFormCurrent?.num_exit_form}
                    ColorFake={() => colorNotation("num_exit_form")}
                    value={num_exit_form}
                    onChange={handleInputChange}
                  />
                  <Tooltip
                    hasArrow
                    label={'Agregar items'}
                    placement='top'
                  >
                    <IconButton
                      onClick={onOpenSelectAuthorizationItems}
                      h={'34px'}
                      marginBottom={'1rem'}
                      borderRadius={0}
                      borderTopRightRadius={'5px'}
                      borderBottomRightRadius={'5px'}
                      color={'#fff'}
                      _hover={{ backgroundColor: '#1f7ac3' }}
                      backgroundColor={'#1f7ac3'}
                      paddingBottom={'1px'}
                      _active={{ backgroundColor: '#1f7ac3' }}
                      outline={'none'}
                      disabled={!num_exit_form}
                      icon={<CgAddR />}
                    />
                  </Tooltip>
                </div>
              </div>
            </Tooltip>

            <Tooltip
              hasArrow
              label={reviewData?.integration_box_certificate?.comment}
              bg={colorNotation("integration_box_certificate")}
              color="white"
            >
              <div>
                <LabeFormlStyled htmlFor="integration_box_certificate">
                  Nº Autorización de C. inte
                </LabeFormlStyled>
                <InputFormStyled
                  type="text"
                  name="integration_box_certificate"
                  id="integration_box_certificate"
                  data-input-tag={"Nº Autorización de C. inte"}
                  data-item-id={"Header"}
                  data-old-Value={
                    dataFormCurrent?.integration_box_certificate
                  }
                  value={integration_box_certificate}
                  onChange={handleInputChange}
                  ColorFake={() => colorNotation("integration_box_certificate")}
                />
              </div>
            </Tooltip>
          </SimpleGrid>
        )
        : (
          ""
        )}
      {/* campos pendientes por enviar a el back avisar */}
      {code === 120 && (
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-5">
          <div>
            <LabeFormlStyled htmlFor="num_exit_form">
              Salida temporal asociada
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="num_exit_form"
              id="num_exit_form"
              data-input-tag={"Salida temporal asociada"}
              data-item-id={"Header"}
              data-old-Value={dataFormCurrent?.num_exit_form}
              ColorFake={() => colorNotation("num_exit_form")}
              value={num_exit_form}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <LabeFormlStyled htmlFor="integration_box_certificate">
              Certificado de cuadro de Integracion
            </LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="integration_box_certificate"
              id="integration_box_certificate"
              value={integration_box_certificate}
              onChange={handleInputChange}
            />
          </div>
        </SimpleGrid>
      )}
    </div>
  );
};

export default RestWorld;
