import React from "react";
import { Box } from "@chakra-ui/react";
import { H2 } from "pages/Pass-Form/stylePases";
import { Title } from "styled/Title";
import isArrayNotEmpty, { isArray } from "utils/type-check-utils";

export const SatausOfOrder = ({ values }) => {
  const { users = [], status = [] } = values || {};

  const statusForm = (() => {
    if (isArray(status) && isArrayNotEmpty(status)) {
      return status;
    }
    if (isArray(users) && isArrayNotEmpty(users)) {
      return users;
    }
    return [];
  })();

  return (
    <>
      <div
        className="row mt-4 ms-2"
        style={{
          background: "#f2f2f2",
          padding: "10px",
        }}
      >
        {isArray(status) || isArray(users)
          ? statusForm.map((items, index) => (
              <div className="col col-sm-6 sol-md-3" key={index}>
                <H2 className="m-1 d-flex">
                  <Title size="18px">
                    {items.status_form === "BORRADOR"
                      ? "Creado:"
                      : items.status_form === "PRESENTADO"
                      ? "Presentado:"
                      : items.status_form === "AUTORIZADO"
                      ? "Autorizado:"
                      : items.status_form === "CORREGIDO"
                      ? "Corregido:"
                      : ""}
                  </Title>

                  <span className="ms-2 text-mute">
                    {items.date_form +
                      " " +
                      items.time_form +
                      " " +
                      items.person_name}
                  </span>
                </H2>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};
