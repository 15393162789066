import { Box, Button, Container, Text } from "@chakra-ui/react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { BsArrowDownCircle } from "react-icons/bs";
import { HeaderSearchCustom } from "styled/SectionContainer.styled";
import TbodyCriteria from "./TbodyCriteria";
import { theadCriteria } from "./thead/thead";
import { useContext, useEffect } from "react";
import ControlAnalysisContext from "context/ControlAnalysis";

import { ExportPdf } from "components/Export/ExportPdf";
import ExportTable from "./Components/ExportTable";
import { useSelector } from "react-redux";

export const UsersAccordingToCriteria = ({
  page,
  setPage,
  maxPage,
  setCurrentPage,
  currentPage,
  loading
}) => {
  const { setRiskLevelCount } = useContext(ControlAnalysisContext);
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  useEffect(() => {
    const filterLevel = currentPage?.filter(
      (element) => element?.NIVEL === "LEVE"
    );
    const filterHalf = currentPage?.filter(
      (element) => element?.NIVEL === "MODERADO"
    );

    const filterHigh = currentPage?.filter(
      (element) => element?.NIVEL === "ALTO"
    );

    setRiskLevelCount({
      level: filterLevel?.length ?? 0,
      half: filterHalf?.length ?? 0,
      high: filterHigh?.length ?? 0
    });
  }, [currentPage]);

  return (
    <Container
      borderRadius={"10px"}
      mt={"20px"}
      shadow={
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
      }
      maxW="full"
      maxH={"714px"}
      className="ScrollMinimal"
      overflow={"hidden"}
      overflowY={"scroll"}
      p="4"
    >
      <ExportTable
        Tbody={TbodyCriteria}
        thead={theadCriteria}
        currentPage={currentPage}
        setPage={setPage}
        setCurrentPage={setCurrentPage}
        page={page}
        maxPage={maxPage}
        loading={loading}
        id={"export"}
      />

      <Box display={"flex"} justifyContent="space-between" w="full">
        <Text fontSize={24} fontWeight={700}>
          Resumen
        </Text>
        <Button
          leftIcon={<BsArrowDownCircle color="white" />}
          colorScheme="blue"
          variant="solid"
          onClick={() => ExportPdf("export", "resumen")}
        >
          Exportar
        </Button>
      </Box>

      <HeaderSearchCustom margen="0" padding="3" Shadow="0">
        <TableOrder
          thead={theadCriteria}
          data={currentPage ?? []}
          tbodyData={TbodyCriteria}
          setData={setCurrentPage}
          loading={loading}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearchCustom>
    </Container>
  );
};
