import { Flex, Spacer } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import InputSelect, {
  InputSelectMulti
} from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useCallback, useEffect, useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { dataDate, destiny, OrderBy as dataOrderBy, status as statusSelect } from "../Arrays/ArrayTitles";
import { __DetinyNotPaginate } from "request/configuration/__Destiny";
import { useSelector } from "react-redux";
import { useSearchUrl } from "hooks/useSearchUrl";

const FormSearchAdvance = ({ onClose, setSearchAdvanced }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["date_1", "date_2", "time_1", "time_2", "form_number", "product_order_code", "product_code", "date_from", "orderBy", "orderType", "status", "destiny"], nestedProp: "searchAdvance" })
  const [date_from, setDate_From] = useState(dataDate.find((item) => {
    return item?.value === state?.date_from
  }) || null);
  const [status, setStatus] = useState(statusSelect.filter(({ value }) => state?.status && state?.status.includes(value)) || null);
  const [destinyBy, setDestinyBy] = useState([]); /* Este estado se carga de la búsqueda */
  const [orderBy, setOrderBy] = useState(dataOrderBy.find((item) => item?.value === state?.orderBy) || null);
  const optionsOrderBy = [{ value: 'ASC', label: 'Ascendente' }, { value: 'DESC', label: 'Descendente' }];
  const [dataDestiny, setDataDestiny] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);

  const [values, handleInputChange, reset] = useForm({
    date_1: state?.date_1 || "",
    date_2: state?.date_2 || "",
    time_1: state?.time_1 || "",
    time_2: state?.time_2 || "",
    form_number: state?.form_number || "",
    product_order_code: state?.product_order_code || "",
    product_code: state?.product_code || "",
    orderType: state?.orderType || "DESC",
  });

  const {
    date_1,
    date_2,
    time_1,
    time_2,
    form_number,
    product_order_code,
    product_code,
    orderType,
  } = values;

  const handleSubmit = () => {
    const data = {
      ...(form_number && { form_number }),
      ...(date_1 && { date_1 }),
      ...(date_2 && { date_2 }),
      ...(time_1 && time_1.length === 5 && { time_1: `${time_1}:59` }),
      ...(time_2 && time_2.length === 5 && { time_2: `${time_2}:59` }),
      ...(date_from !== null && { date_from: date_from.value }),
      ...(orderBy !== null && { orderBy: orderBy.value }),

      ...(orderType && { orderType }),
      ...(product_order_code && { product_order_code }),
      ...(product_code && { product_code }),
      ...(status &&
        status.length !== 0 && {
        status: status?.map((item) => item?.value)
      }),
      ...(destinyBy &&
        destinyBy.length !== 0 && {
        destiny: destinyBy?.map((item) => item?.value)
      })
    };

    setParamsSearch({
      params: {
        ...data,
        ...(time_1 && { time_1: time_1.slice(0, 5) }),
        ...(time_2 && { time_2: time_2.slice(0, 5) }),
      }
    });

    setSearchAdvanced(data);
  };

  const getDestiny = useCallback(async () => {
    try {
      const filter = []
      const res = await __DetinyNotPaginate(token);
      const dataDestiny = res?.data?.status?.data.map((items) => {
        if (state?.destiny && state?.destiny.includes(items?.id)) { filter.push({ value: items?.id, label: items?.code + " " + items?.destiny }) }
        return ({
          value: items?.id,
          label: items?.code + " " + items?.destiny
        })
      });
      setDestinyBy(filter);
      setDataDestiny(dataDestiny);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    getDestiny();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"form_number"}
          value={form_number}
          onChange={handleInputChange}
          tag={" Nº Formulario"}
          size={45}
          tipo={"text"}
        />
        <div style={{ width: `45%` }}>
          <InputSelectMulti
            tag={"Tipo de cuadro"}
            data={dataDestiny}
            set={setDestinyBy}
            value={destinyBy}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_1"}
          value={date_1}
          onChange={handleInputChange}
          tag={"Fecha inicio"}
          size={45}
          tipo={"date"}
        />

        <LabelGroup
          name={"date_2"}
          value={date_2}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"time_1"}
          value={time_1}
          onChange={handleInputChange}
          tag={"Hora inicio"}
          size={45}
          tipo={"time"}
        />

        <LabelGroup
          name={"time_2"}
          value={time_2}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            tag={"Fecha de:"}
            data={dataDate}
            set={setDate_From}
            value={date_from}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelectMulti
            tag={"Estado"}
            data={statusSelect}
            set={setStatus}
            value={status}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"product_order_code"}
          value={product_order_code}
          onChange={handleInputChange}
          tag={"Código orden de producción"}
          size={45}
          tipo={"text"}
        />

        <LabelGroup
          name={"product_code"}
          value={product_code}
          onChange={handleInputChange}
          tag={"Código de producto"}
          size={45}
          tipo={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Ordenar por"}
            data={dataOrderBy}
            set={setOrderBy}
            value={orderBy}
          />
        </div>
        <div className="mt-3" style={{ width: "45%" }}>
          <RadioBtn
            name={"orderType"}
            onChange={handleInputChange}
            options={optionsOrderBy}
            state={orderType}
          />
        </div>
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormSearchAdvance;
