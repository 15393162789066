import React, { useContext, useEffect } from "react";
import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";

import { useForm } from "../../hooks/useForm";
import { ButtonStyled } from "../../styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import ConfigurationCRUDContext from "../../context/ConfigurationCRUDContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useDebounceError } from "hooks/useDebounceError";
import { Regex } from "utils/Regex";

const EditFormDepartament = () => {
  const initialValues = {
    code_department: "",
    name_department: ""
  };
  const [openAlert] = AlertErrorAlert();

  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, resetValues, setValues] =
    useForm(initialValues);
  const { code_department, name_department } = values;

  useEffect(() => {
    if (dataToEdit) {
      setValues(dataToEdit);
    } else {
      setValues(initialValues);
    }
  }, [dataToEdit]);

  const hanldeSubmit = async (e) => {
    e.preventDefault();
  };

  const handleAceptar = () => {
    if (dataToEdit) {
      editData(values);
      // reset();
    } else {
      createData(values);
      // reset();
    }
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" mb={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? " Editar Departamento" : "Crear Departamento"}
      </Text>
      <Divider mb={1} />

      <form onSubmit={hanldeSubmit}>
        <SimpleGrid minChildWidth="250px" spacing="20px">
          <div>
            <LabeFormlStyled>Código</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="code_department"
              value={code_department}
              onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
              maxLength={3}
            />
          </div>
          <div>
            <LabeFormlStyled>Nombre</LabeFormlStyled>
            <InputFormStyled
              type="text"
              name="name_department"
              value={name_department}
              onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
              maxLength={30}
            />
          </div>
        </SimpleGrid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit" onClick={handleAceptar}>
            Aceptar
          </ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default EditFormDepartament;
