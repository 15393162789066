import React from 'react'
import { useMutation } from '@apollo/client'
import { GET_DASHBOARD_ITEMS } from 'DashBoard/graphql/queries'
import { DELETE_DASHBOARD_ITEM } from 'DashBoard/graphql/mutations'
import ColumnsChart from 'DashBoard/pages/components/CardCharts'
import { useModal } from 'hooks/useModal'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import environment from 'constants/apiConst'
import { useSelector } from 'react-redux'
import { __Get } from 'request/Petitions/__Get'

const DashboardItem = ({ itemId, children, title }) => {
  const [isOpenWarnig, onOpenWarning, onCloseWarning] = useModal()
  const token = useSelector(state => state.auth.dataUser.token)

  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS
      }
    ]
  })

  const EliminarBack = async () => {
    try {
      const res = await __Get(environment.DASBHBOARD_DELETE_CONFIG, token)
      const requestData = res?.data?.status?.data.data
      if (requestData.length !== 0) {
      } else {
      }
    } catch (error) {
    } finally {
    }
  }

  return (
    <>
      <ModalAdvertencia
        isOpen={isOpenWarnig}
        onClose={onCloseWarning}
        title={`Advertencia`}
        titleBody={`¿Estás seguro de que quieres eliminar este artículo?`}
        generar={() => {
          // EliminarBack()
          removeDashboardItem({
            variables: {
              id: itemId
            }
          })
        }}
      />

      <ColumnsChart
        title={title}
        itemId={itemId}
        onOpenWarning={onOpenWarning}
        component={children}
      />
    </>
  )
}
export default DashboardItem
