import {
  Flex,
  FormErrorMessage,
  Grid,
  GridItem,
  Spacer,
} from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useGlobalContext } from "context/GlobalContext";
import { RETURN_TYPES, useForm } from "hooks/useForm";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useEffect, useState } from "react";
/* import { useForm, Controller } from 'react-hook-form' */
import { NumericFormat } from "react-number-format";
import { ButtonStyled } from "styled/Buttons.styled";
import { parseFloat } from "utils/parseFloat";
import { isString, isValid } from "utils/type-check-utils";

const CreatePreparedProductOP = ({ submit = () => {}, onClose = () => {} }) => {
  const { dataToEditOP } = useGlobalContext();
  const [openAlert] = AlertErrorAlert();
  const [productData, setProductData] = useState({});
  const [valueProduct, setValueProduct] = useState({});

  const onFieldChange = (name, value) => {
    if (name === "quantity_product") {
      const quantityProduct = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      const factorConversion = isNaN(
        parseFloat(productData?.convertion_factor_product)
      )
        ? 0
        : parseFloat(productData?.convertion_factor_product);

      setValues((prev) => ({
        ...prev,
        quantity: factorConversion * quantityProduct,
      }));

      if (isValid(factorConversion)) {
        setValues((prev) => ({
          ...prev,
          net_weight_kilo: factorConversion * quantityProduct,
        }));
      }
    }
  };

  const { values, handleInputChange, reset, setValues } = useForm({
    initialState: {
      product_id: null,
      quantity: 0,
      quantity_product: 0,
      net_weight_kilo: 0,
      total_calculated: 0,
    },
    returnType: RETURN_TYPES.OBJECT,
    onFieldChange,
  });

  const {
    product_id,
    quantity,
    quantity_product,
    net_weight_kilo,
    total_calculated,
  } = values || {};

  useEffect(() => {
    if (isValid(dataToEditOP)) {
      /*    if (isObject(dataToEditOP)) {
        for (const key in dataToEditOP) {
          if (isValid(dataToEditOP[key])) {
            setValue(
              key,
              isString(dataToEditOP[key], true)
                ? parseFloat(dataToEditOP[key])
                : dataToEditOP[key]
            )
          }
        }
      } */

      setValueProduct((prev) => ({
        ...prev,
        label: `${dataToEditOP?.product_code} - ${dataToEditOP?.product_description}`,
        value: dataToEditOP?.product_id,
      }));

      setProductData((prev) => ({
        ...prev,
        convertion_factor_product: dataToEditOP?.product_factor_conv,
        description_product: dataToEditOP?.product_description,
        code_subheading: dataToEditOP?.subheading_code,
        businnesUnitSubheading: dataToEditOP?.business_unit_code,
        unit_measurement: dataToEditOP?.unit_measurement_name,
        description: dataToEditOP?.subheading_description,
      }));

      setValues((prev) => ({
        ...prev,
        id: dataToEditOP?.id,
        product_id: dataToEditOP?.product_id,
        total_calculated: isString(dataToEditOP?.total_calculated, true)
          ? parseFloat(dataToEditOP?.total_calculated)
          : 0,
        net_weight_kilo: isString(dataToEditOP?.net_weight_kilo, true)
          ? parseFloat(dataToEditOP?.net_weight_kilo)
          : 0,
        quantity_product: isString(dataToEditOP?.quantity_product, true)
          ? parseFloat(dataToEditOP?.quantity_product)
          : 0,
        quantity: isString(dataToEditOP?.quantity, true)
          ? parseFloat(dataToEditOP?.quantity)
          : 0,
      }));
    }
  }, [dataToEditOP]);

  const createProduct = (e) => {
    e.preventDefault();
    if (!isValid(product_id)) {
      openAlert("¡Es obligatorio seleccionar un Producto!", "error");
      return;
    }

    const productForm = {
      ...values,
      quantity:  parseFloat(quantity),
      net_weight_kilo: parseFloat(net_weight_kilo),
      quantity_product: parseFloat(quantity_product),
      total_calculated: parseFloat(total_calculated),
    };

    submit(productForm);
  };

  const updateItemValue = (newValue, sourceEvent) => {
    const target = {
      ...sourceEvent?.event,
      [sourceEvent?.event?.target?.name]: newValue?.floatValue,
    };

    handleInputChange(target);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid templateColumns="repeat(auto-fill, minmax(auto, 1fr))" gap={4}>
        <GridItem colSpan={"6"} display={"flex"} gap={2}>
          <ProductSearchBar
            label="Producto"
            required={true}
            value={valueProduct}
            onValueChange={(e) => {
              setValues((prev) => ({
                ...prev,
                product_id: e?.value,
              }));
              setValueProduct(e);
              setProductData(e);
            }}
            size={100}
          />

          <LabelGroup
            name={"code_unit_measurement"}
            size={100}
            value={productData?.unit_measurement}
            desicion={true}
            tag="Unidad de producto"
          />

          <LabelGroup
            value={productData?.convertion_factor_product}
            desicion={true}
            name={"convertion_factor_product"}
            size={100}
            tag="Factor conversión"
          />

          <NumericFormat
            value={quantity_product}
            allowNegative={false}
            thousandSeparator={true}
            //decimalScale={4}
            decimalSeparator="."
            customInput={LabelGroup}
            size={100}
            tag="Cantidad producto"
            name={"quantity_product"}
            onValueChange={updateItemValue}
            /*  onBlur={onBlur}
 error={errors?.quantity_product}
 {...register("quantity_product", {
   required: "Cantidad de producto es requerido",
   min: { value: 1, message: "Este campo es obligatorio", },
 })} */
          >
            {/*   {errors?.quantity_product && (
              <FormErrorMessage>
                {errors?.quantity_product?.message}
              </FormErrorMessage>
            )} */}
          </NumericFormat>
        </GridItem>

        <GridItem colSpan={6}>
          <LabelGroup
            name={"product_description"}
            size={100}
            value={productData?.description_product}
            tag="Descripción del producto"
            desicion={true}
          />
        </GridItem>

        <GridItem colSpan={6} display={"flex"} gap={2}>
          <LabelGroup
            name={"description"}
            size={100}
            tag="Descripción"
            value={productData?.description}
            desicion={true}
          />
          <LabelGroup
            name={"code_subheading"}
            size={100}
            value={productData?.code_subheading}
            tag="Subpartida"
            desicion={true}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={4}
            decimalSeparator="."
            value={quantity}
            customInput={LabelGroup}
            name={"quantity"}
            size={100}
            tag="Cantidad"
            onValueChange={updateItemValue}
            /*  onBlur={onBlur}
 error={errors?.quantity} */
            /*   {...register("quantity", {
    required: "Cantidad es requerido",
    min: { value: 1, message: "Este campo es obligatorio", },
  })} */
          >
            {/*       {errors?.quantity && (
              <FormErrorMessage>
                {errors?.quantity?.message}
              </FormErrorMessage>
            )} */}
          </NumericFormat>
        </GridItem>

        <GridItem colSpan={6} display={"flex"} gap={2}>
          <LabelGroup
            name={"businnesUnitSubheading"}
            size={100}
            value={productData?.businnesUnitSubheading}
            tag="Unidad comercial"
            desicion={true}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"net_weight_kilo"}
            size={100}
            value={net_weight_kilo}
            tag="Peso neto en kilos"
            //   onChange={updateItemValue}
            onValueChange={updateItemValue}
            //onBlur={onBlur}
          />

          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={4}
            decimalSeparator="."
            customInput={LabelGroup}
            name={"total_calculated"}
            value={total_calculated}
            size={100}
            tag="Valor FOB en US$"
            // error={errors?.total_calculated}
            onValueChange={updateItemValue}
            /*  {...register("total_calculated", {
   required: "Valor FOB en US requerido",
   min: { value: 1, message: "Este campo es obligatorio", },
 })} */
          >
            {" "}
            {/* {errors?.total_calculated && (
            <FormErrorMessage>
              {errors?.total_calculated?.message}
            </FormErrorMessage>
          )} */}
          </NumericFormat>
        </GridItem>
      </Grid>

      <Flex pt="5" pb="5">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#3182CE"} onClick={createProduct}>
          Guardar
        </ButtonStyled>
      </Flex>
    </form>
  );
};

export default CreatePreparedProductOP;
