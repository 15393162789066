import axios from 'axios';
import environment from 'constants/apiConst';

export const __Terms_And_Conditions = (body, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      Authorization: token ? `Bearer ${token}` : ''
    },
    data: body
  };

  const res = axios(environment.TERMS_AND_CONDITIONS, options);

  return res;
};
