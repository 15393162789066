import React, { useContext, useState } from "react";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import InputSelect from "components/InputSelect/InputSelect";
import { Flex, Spacer } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { ordenarPorTemporales } from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import FormCrudCRUDContext from "context/FormCrudContex";
import GlobalContext from "context/GlobalContext";
import { MaxLength } from "utils/MaxLength";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

const FormExitTemporales = ({
  dataCountry,
  onClose,
}) => {
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });
  const [values, handleInputChange, reset] = useForm({
    code_outputForm: currentSearch?.code_outputForm,
    description: currentSearch?.description,
    code_subheading: currentSearch?.code_subheading,
    reference: currentSearch?.reference,
    destiny_code_country: currentSearch?.destiny_code_country,
    flag_code_country: currentSearch?.flag_code_country,
    origin_code_country: currentSearch?.origin_code_country,
  });
  const { code_outputForm, description, code_subheading, reference } = values;

  const options = ["Ascendente", "Descendente"];
  const [countryBuy, setCountryBuy] = useState(currentSearch?.countryBuy);
  const [countryProcedency, setCountryProcedency] = useState(currentSearch?.countryProcedency);
  const [flag, setFlag] = useState(currentSearch?.flag);
  const [countryOrigin, setCountryOrigin] = useState(currentSearch?.countryOrigin);
  const [sortBy, setSortBy] = useState(currentSearch?.sortBy);
  const [selectAD, setSelectAD] = useState(currentSearch?.orderby_type === 'ASC' ? 'Ascendente' : 'Descendente');

  const handleBusqueda = () => {
    const data = {
      ...values,
      purchase_code_country: countryBuy?.value,
      destiny_code_country: countryProcedency?.value,
      flag_code_country: flag?.value,
      origin_code_country: countryOrigin?.value,
    }


    const newObjetData = {
      ...data,
      ...(sortBy && sortBy?.value && { orderby: sortBy?.value }),
      /* orderby: sortBy ? sortBy.value : "", */ /* Esto creo  */
      orderby_type: selectAD === "Ascendente" ? "ASC" : "DESC",
    };

    setParamsSearch({
      params: handleSanitizedObj({
        ...newObjetData,
        countryBuy,
        countryProcedency,
        flag,
        countryOrigin,
        sortBy,
        typeSearch: "TEMPORARY",
      })
    });
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"code_outputForm"}
          value={code_outputForm}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        />
        <LabelGroup
          name={"description"}
          value={description}
          onChange={handleInputChange}
          tag={"Descripción"}
          size={45}
          tipo={"text"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"code_subheading"}
          value={code_subheading}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={45}
          desicion={false}
          tipo={"search"}
          maxLength={MaxLength?.global?.code_subheading}
        />
        <LabelGroup
          name={"reference"}
          value={reference}
          onChange={handleInputChange}
          tag={"Referencia"}
          size={45}
          tipo={"text"}
          maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"purchase_code_country"}
            tag={"País compra"}
            data={dataCountry}
            value={countryBuy}
            set={setCountryBuy}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"destiny_code_country"}
            tag={`País destino`}
            data={dataCountry}
            value={countryProcedency}
            set={setCountryProcedency}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"flag_code_country"}
            tag={"Bandera"}
            data={dataCountry}
            value={flag}
            set={setFlag}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"origin_code_country"}
            tag={"País Origen"}
            data={dataCountry}
            value={countryOrigin}
            set={setCountryOrigin}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"sortBy"}
            tag={"Ordenar por:"}
            data={ordenarPorTemporales}
            value={sortBy}
            set={setSortBy}
          />

          <div className="margin-search" style={{ width: `45%` }}>
            <RadioBtn
              options={options}
              setOnly={setSelectAD}
              state={selectAD}
            />
          </div>
        </div>
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormExitTemporales;
