/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ExporByColumn } from "components/ExportByColumn/ExporByColumn";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import { InventoryControlProvider } from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  __ExportExcel,
  __GetReferencia,
  __GetReferenciaBalance
} from "request/Inventory/__InventoryCRUD";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  arrayLeftAuxRef,
  arrayRigthAuxRef,
  arrayRigthUserAuxRef,
  inventoryNodeInputFields
} from "./Array/ArrayExport";
import { theadReferenceAssistant } from "./Array/ArrayInventoryControl";
import { FormReferenceAssistant } from "./SearchTabInventory/FormReferenceAssistant";
import { TbodyReferenceAssistant } from "./TbodyTable/TbodyReferenceAssistant";
import { BiFilter } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";
import { useDebounce } from "hooks/useDebouce";
import { useDispatch } from "react-redux";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { isArray, safelyConvertToString } from "utils/type-check-utils";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { useSearchUrl } from "hooks/useSearchUrl";

export const AuxReference = () => {
  const { cleanState, clearParamsSearch } = useSearchUrl({ getParams: ["reference", "import_declaration", "operation", "operation_initial", "subheading", "trade_agreement", "date_final", "date_initial", "product_code", "certificate", "description"], nestedProp: "searchAdvance" });

  const token = useSelector((state) => state.auth.dataUser.token);
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [valueQualified, setValueQuialified] = useState([]);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [totalReference, setTotalReference] = useState(null);
  const [openAlert] = AlertErrorAlert();
  const { freeZoneValue } = useSelector((state) => state.chagenFreeZone);

  const tdTotal = [1, 2, 3, 4, 5, 6];

  const [values, handleInputChange, reset] = useForm({
    searchInformacion: ""
  });

  const { searchInformacion } = values;
  const Searchdebounced = useDebounce(searchInformacion, 700);
  const dispacthRedux = useDispatch();


  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    reset();
    dispacthRedux(clearSearch());
    setSearchAdvanced([]);
    setValueQuialified([]);
    setChangeViewSearch(false);
    setTotalReference(null);
    setCurrentPage([]);
  };

  useEffect(() => {
    const data = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(valueQualified?.value && searcheAdvanced?.reference && {
        qualifiedUser_id: valueQualified.value,
        reference: searcheAdvanced?.reference
      }),
    };

    if (Object.values(data).length > 0) {
      setLoading(true);
      setChangeViewSearch(true);
      dispacthRedux(setSearch(data));
      GetReferenceAux(data);
      return;
    }
    dispacthRedux(clearSearch());
  }, [Searchdebounced, searcheAdvanced, page, valueQualified, freeZoneValue]);

  useEffect(() => {
    return () => {
      dispacthRedux(clearSearch());
    }
  }, [])

  useEffect(() => {
    if (!searchInformacion && !searcheAdvanced && !valueQualified) {
      setChangeViewSearch(false);
      setCurrentPage([]);
      setPage(1);
    }
  }, [searchInformacion, valueQualified]);


  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(data?.type_export_file && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: data?.qualifiedUser_id[0]?.value || data?.qualifiedUser_id?.value || data?.qualifiedUser_id
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    exportColumnBy(info);
  };


  const exportColumnBy = async (data) => {
    try {
      const res = await __ExportExcel(
        token,
        data,
        environment.EXPOR_EXCEL_AUXILIAR_REFERENCE
      );
      if (res.status === 200) {
        openAlert(`${res?.data?.status?.data}`, "info");
        return onCloseExport();
      }
      openAlert(`${res?.data?.status?.message}`, "error");
    } catch (error) {
      console.log(error);
    }
  };

  const GetReferenceAux = async (body) => {
    setLoading(true);
    try {
      const res = await __GetReferencia(token, page, body);
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        setCurrentPage([]);
        setMaxPage(1);
        return setLoading(false);
      }

      if (
        res?.data?.status.code === 200 &&
        res?.data?.status?.data?.data?.length !== 0
      ) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        const totales = res?.data?.status?.data?.data.length;
        setTotalReference(res?.data?.status?.data?.data[totales - 1]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const nodeList = {
    1: arrayLeftAuxRef?.concat(arrayRigthUserAuxRef),
    0: arrayLeftAuxRef?.concat(arrayRigthAuxRef),
  }[authUser?.is_qualified_user]

  return (
    <>
      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={nodeList}
        selectNodeList={inventoryNodeInputFields}
        enableAdvance={true}
      />

      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada"
      >
        <InventoryControlProvider>
          <FormReferenceAssistant
            onClose={onCloseSearch}
            handleSearchSubmit={setSearchAdvanced}
          />
        </InventoryControlProvider>
      </ModalGeneric>

      <HeaderSearch>
        <Title>Auxiliar referencia</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          setValueQuialified={setValueQuialified}
          valueQualified={valueQualified}
          permisoSearch={"getInventoryRefAux"}
          allAccess={allAccess}
          myPermission={myPermission}
        />
        <ExportDetail onOpen={onOpenExport} />

        {currentPage.length > 0 && (
          <div>
            <TableOrder
              thead={theadReferenceAssistant}
              data={currentPage}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyReferenceAssistant}
              subTotals={totalReference}
              titleTotal="Total"
              td={tdTotal}
            />
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={maxPage}
              loading={loading}
            />
          </div>
        )}
      </HeaderSearch>
    </>
  );
};
