import React, { useContext } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import FormCrudCRUDContext from "context/FormCrudContex";

const TBodyFormEntryItem = ({ data }) => {
  const { setDataToShow } = useContext(FormCrudCRUDContext);
  const navigate = useNavigate();

  const handleShow = () => {
    navigate(`/FormShow?FormEntry=${data.entry_form_id}`);
    setDataToShow(data);
  };

  return (
    <Tr>
      <Td className="text-center" onClick={handleShow}>
        <NavLink
          to={`/FormShow?FormEntry=${data.entry_form_id}`}
          className="ms-2"
        >
          <span className="formNumber">{data?.code_entry_form}</span>
        </NavLink>
      </Td>
      <Td className="text-center">{data?.item}</Td>
      <Td className="text-center">{data?.description_product}</Td>
      <Td className="text-center">{data?.code_product}</Td>
      <Td className="text-center">{data?.reference}</Td>
      <Td className="text-center">{data?.code_subheading}</Td>
      <Td className="text-center">{data?.num_import_declaration}</Td>
      <Td className="text-center">
        {data?.origin_code + " " + data?.origin_name}
      </Td>
      <Td className="text-center">
        {data?.purchase_code + " " + data?.purchase_name}
      </Td>
      <Td className="text-center">
        {data?.procedency_code + " " + data?.procedency_name}
      </Td>
      <Td className="text-center">{data?.flag_code + " " + data?.flag_name}</Td>
    </Tr>
  );
};

export default TBodyFormEntryItem;
