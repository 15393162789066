/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import TableOrder from "components/Tables/TableOrder";
import {
  cabecera,
  TitleMovementDispatch,
  TitleMovementInt,
  TitleMovementIntPreAut,
  TitleMovementIntUc,
  TitleMovementOutPreAut
} from "./ArrayHeadTable/TheadMovementInt";
import Pagination from "components/Pagination/Pagination";
import { HeaderSearch } from "styled/SectionContainer.styled";
import {
  __EntryMovementGet,
  __SearchSimpleDispatch,
  __SearchSimpleEntry
} from "request/Pases/__CrudPass";
import { AuthContext } from "context/AuthContext";
import { BtnHeaders } from "../HeaderComponent/BtnHeaders";
import TBodyEntryMovement from "../tbody/TBodyEntryMovement";
import bro from "../../../assets/bro.png";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __DispatchAg } from "request/DespachoAgrupado/__DispatchAg";
import PassCrudContext from "context/PassCrudContext";
import environment from "constants/apiConst";
import { useForm } from "hooks/useForm";
import "../stilo.css";
import { ModalSearch } from "./BusquedaAvanzada/ModalSearch";
import { FormAdvanced } from "./BusquedaAvanzada/FormAdvanced";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import { useDispatch, useSelector } from "react-redux";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import { ExportDetail } from "pages/CustomsClearance/Adjunto/ExportDetail";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormReturnReason } from "../FormReturnReason";
import { handleLogout } from "components/Logout/FuncionLogout";
import { __Post, __PostJsonData } from "request/Petitions/__Post";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { entryFormExportNodeList, entryFormNodeInputFields } from "../ListItem";
import { isArray } from "utils/type-check-utils";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { clearSearch, setSearch } from "redux/actions/searchActions";
import { useSearchUrl } from "hooks/useSearchUrl";
import { useQueries } from "@tanstack/react-query";
import { ComponentAccordionIndividual } from "components/AccordionComponent/ComponentAccordionIndividual";



function transformDynamicObject(obj, options = {}) {
  const { propsToOmit = [], propsToRename = {} } = options || {};

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const result = Array.isArray(obj) ? [] : {};

  for (const [key, value] of Object.entries(obj)) {
    if (propsToOmit.includes(key)) {
      // Omitimos completamente esta propiedad
      continue;
    }

    const newKey = propsToRename[key] || key;

    if (Array.isArray(value)) {
      result[newKey] = value.map(item => {
        if (typeof item === 'object' && item !== null && 'value' in item) {
          return item.value;
        }
        return item;
      });
    } else if (typeof value === 'object' && value !== null) {
      if ('value' in value && 'label' in value) {
        result[newKey] = value.value;
      } else {
        result[newKey] = transformDynamicObject(value, options);
      }
    } else {
      result[newKey] = value;
    }
  }

  return result;
}


export const EntryMovement = () => {
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: "searchSimple" });
  const { cleanState } = useSearchUrl({ getParams: ["plate", "identification", "observations", "formNumber", "container", "endDate", "endTime", "startDate", "startTime", "commercial_invoice", "transport_document", "operation_cod", "associated_form", "stateDispacthOrPass", "freelyAvailable", 'dateFrom',"associated_security","loaded", "datosBy", "associated", "typeTransporSelec", "user_Qualified", "weighin"], nestedProp: "searchAdvance" });

  const { search, valueQualified } = state;

  const [page, setPage] = useState(1);
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const { authUser } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);
  const {
    setRequestChangeStatus,
    update,
    setRequestBulkLoad,
    setShowFormDispacthItem,
    setDataItemDispachSelect,
    seteditItemDispatch,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    setRequestObtenerItemPass,
    changeViewSearch,
  } = useContext(PassCrudContext);
  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [openAlert] = AlertErrorAlert();
  const dispacthRedux = useDispatch();
  const idFuncionaio = useSelector((state) => state.auth.dataUser.id);


  

  const data = useMemo(() => {
    const { ...rest } = cleanState

    const searchInfo = {
      ...(search && { search }),
      ...(valueQualified && { qualifiedUser_id: valueQualified?.map(({ value }) => value) }),
      ...rest,
    }
    
    dispacthRedux(setSearch(searchInfo))
    return searchInfo
  }, [valueQualified, search, cleanState]);


  const { requestData } = useGlobalContext();

  const initialEndpoint = {
    "Pase de entrada": environment.GET_PASES_INT,
    "Pase de salida": environment.GET_DISPATCH,
  }[movement] || environment.GET_PASES_INT;



  const searchEndpoint = {
    "Pase de entrada": environment.GET_ENTRY_PASSES_FILTER,
    "Pase de salida": environment.GET_SEARCH_DESPACHO_INT,
  }[movement] || environment.GET_SEARCH_DESPACHO_INT;

  const [pageEditables, setPageEditables] = useState(1);
  const [pagePresentados, setPagePresentados] = useState(1);
  const [pageAutorizados, setPageAutorizados] = useState(1);


  /* Esto es para realizar las paginaciones de forma individual utilizando react-query, con una queryKey diferente y sus diferentes propiedades (thead, tbody, page, setPage) */
  const requestMapping = [
    {
      key: "Editables",
      queryKey: ["pases", pageEditables, update, freeZone_id, data, movement],
      page: pageEditables,
      setPage: setPageEditables,
      thead: movement === "Pase de entrada" ? TitleMovementInt : TitleMovementDispatch,
      tbody: TBodyEntryMovement,
    },
    {
      key: "Presentados",
      queryKey: ["pases", pagePresentados, update, freeZone_id, data, movement],
      page: pagePresentados,
      setPage: setPagePresentados,
      thead: movement === "Pase de entrada" ? TitleMovementIntPreAut : TitleMovementDispatch,
      tbody: TBodyEntryMovement,
    },
    {
      key: "Autorizados",
      queryKey: ["pases", pageAutorizados, update, freeZone_id, data, movement],
      page: pageAutorizados,
      setPage: setPageAutorizados,
      thead: movement === "Pase de entrada" ? TitleMovementIntPreAut : TitleMovementDispatch,
      tbody: TBodyEntryMovement,
    },
  ]

  const propsToOmit = {
    propsToOmit: ['datosBy', 'associated'],
    propsToRename: {
      'stateDispacthOrPass': 'status',
      'typeTransporSelec': 'typeTransport'
    }
  };

/*   const transformedObject = transformDynamicObject(searchInfo, propsToOmit);
  console.log(transformedObject) */

  const { data: dataPases, pending, } = useQueries({
    /* Acá se valida si no hay una búsqueda se hace la petición con requestMapping para tener todo separado, de lo contrario se hace la petición get normal*/
    queries: Object.entries(data).length === 0 ? requestMapping.map((item) => ({
      queryKey: item.queryKey,
      queryFn: () => requestData({
        Endpoint: initialEndpoint,
        page: item.page,
        method: initialEndpoint === environment.GET_DISPATCH ? "GET" : "POST",
      })
    })) : [{
      queryKey: ["dataPasesSearch", page, update, freeZone_id, data, movement],
      queryFn: () => requestData({
        Endpoint: searchEndpoint,
        page,
        method: "POST",
        data: transformDynamicObject(data, propsToOmit),
      })
    }],
    /* Hay dos casos, el primero es cuando hay búsqueda, que se apunta a un endpoint diferente y además, este trae solo un resultado data=[{...Resultado de la Búsqueda}]. Por el contrario, cuando no hay búsqueda, este trae todos los resultados en un array de objetos [{...Editables}, {...Prestendados}, {...Autorizados}] los cuales se combinan en un solo objeto  {...Editables, ...Prestendados, ...Autorizados}*/
    combine: (results) => {
      if (Object.entries(data).length !== 0) {
        const [data] = results.map(result => result?.data?.data?.status)
        return {
          data,
          pending: results.some((result) => result.isPending),
        }
      }

      return {
        data: results.reduce((acc, result, index) => {
          acc[requestMapping[index].key] = {
            ...requestMapping[index],
            data: result.data?.data?.status?.data?.[index]?.data || [],
            maxPage: result.data?.data?.status?.data?.[index]?.last_page || 1,
            isLoading: result.isFetching,
          }
          return acc
        }, {}),
        pending: results.some((result) => result.isPending),
      }
    },
  })


  const backSearch = () => {
    clearParamsSearch({ searchArray: ["searchSimple", "searchAdvance"] });
    resetPages();
  };

  useEffect(() => {
    setRequestObtenerItemPass(
      movement !== "Pase de entrada"
        ? environment.GET_ITEM_DISPACTH_EXIT
        : environment.OBTENER_ITEM_ENTRADA_PASES_INT
    );

    setRequestChangeStatus(
      movement !== "Pase de entrada"
        ? environment.CHANGESTATUSDISPACTH
        : environment.CHANGESTATUSPASS
    );
    setRequestBulkLoad(
      movement !== "Pase de entrada"
        ? environment.IMPORT_BULKLOAD_DISPATC
        : environment.IMPORT_BULKLOAD
    );

    setShowFormDispacthItem(false);
    setDataItemDispachSelect(null);
    seteditItemDispatch(false);
  }, [update, movement, authUser, freeZone_id]);

  const resetPages = () => {
    setPage(1);
    setPageEditables(1);
    setPagePresentados(1);
    setPageAutorizados(1);
  };

  useEffect(() => {
    return async () => {
      resetPages();
      dispacthRedux(clearSearch())
    };
  }, [movement]);

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);


  const handleSubmit = (data, formulario) => {
    const info = {
      ...data,
      type_export_file: "xlsx",
      ...(isArray(data?.type_export_file) &&
        data?.type_export_file?.length !== 0 && {
        type_export_file: data?.type_export_file?.value
      }),
      ...(data?.qualifiedUser_id && {
        qualifiedUser_id: isArray(data?.qualifiedUser_id)
          ? data?.qualifiedUser_id?.map((item) => item?.value || item)
          : data?.qualifiedUser_id?.value || ""
      }),
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

  
    exportColumnBy(transformDynamicObject(info, propsToOmit), onCloseExport);
  };

  const exportColumnBy = async (data, onCloseExport) => {
    const endPoint = {
      "Pase de entrada": environment.POST_EXPORT_EXCEL_PASS_ENTRY,
      "Pase de salida": environment.POST_EXPORT_EXCEL_PASS_DISPATCH
    }[movement || "Pase de entrada"];

    try {
      const res = await __Post(endPoint, token, data);
      if (res?.status === 200) {
        onCloseExport();
        return openAlert(`${res?.data?.status?.message}`, "success");
      } else {
        return openAlert(`${res?.data?.status?.message}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalSearch
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Búsqueda avanzada"}
      >
        <FormAdvanced onClose={onClose} />
      </ModalSearch>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        handleSubmit={handleSubmit}
        nodeList={entryFormExportNodeList}
        selectNodeList={entryFormNodeInputFields}
        enableAdvance={true}
      />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={PassCrudContext}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>{movement}</Title>
        <SearchAdvancedModule
          onOpenSearch={onOpen}
          changeViewSearch={Object.entries(data).length !== 0}
          backSearch={backSearch}
          permisoSearch={
            movement === "Pase de entrada"
              ? "getEntryPassesSearch"
              : "getDispatchPassesSearch"
          }
          allAccess={allAccess}
          myPermission={myPermission}
          MultipleQualifiedUsers="multi"
          movement={movement}
        />

        <ExportDetail onOpen={onOpenExport}>
          <BtnHeaders allAccess={allAccess} myPermission={myPermission} />
        </ExportDetail>

        {
          Object.entries(data).length !== 0 ? (
            <>
              <TableOrder
                thead={
                  movement === "Pase de entrada"
                    ? TitleMovementIntPreAut
                    : TitleMovementOutPreAut
                }
                data={dataPases?.data?.data}
                setData={() => { }}
                tbodyData={TBodyEntryMovement}
                loading={pending}
              />

              <Pagination
                page={page}
                setPage={setPage}
                maxPage={dataPases?.data?.last_page || 1}
                loading={pending}
              />
            </>
          ) : (
            <>
              <ComponentAccordionIndividual
                data={dataPases}
                isLoading={pending}
              />
            </>
          )
        }


      </HeaderSearch>
    </>
  );
};
