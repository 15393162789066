/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import PassCrudContext from "context/PassCrudContext";
import { Flex, Spacer } from "@chakra-ui/react";
import { InputGroupUser } from "pages/RefProductInter/LabelGroup";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AuthContext } from "context/AuthContext";
import { ButtonStyled } from "styled/Buttons.styled";
import { IconGeneric, IconImport } from "styled/Icons.styled";
import { __BulkLoad } from "request/Pases/__CrudPass";
import environment from "constants/apiConst";
import { MdDelete } from "react-icons/md";
import { RiUploadCloudFill } from "react-icons/ri";
import { useModal } from "hooks/useModal";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useBulkItemAggregation } from "hooks/useBulkItemAggregation";

export const FormCargaMasiva = ({ onClose, title }) => {
  const [userCa, setUserCa] = useState([]);
  const { authUser } = useContext(AuthContext);
  const [valor, setValor] = useState("");
  const [nameQ, setNameQ] = useState("");
  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const { setUpdate, update, requestBulkLoad } = useContext(PassCrudContext);
  const [openAlert] = AlertErrorAlert();

  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const token = useSelector((state) => state.auth.dataUser.token);

  useEffect(() => {
    userC();
  }, []);

  const userC = async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      setUserCa(res?.data?.status?.data);
    } catch (error) { }
  };

  const Upload = () => {
    document.getElementById("display").click();
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);

        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);

      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("qualifiedUser_id", valor || authUser.userQualified);
    formData.append("file", Attachment);
    if (valor !== "" && Attachment.length !== 0) {
      title === "Carga masiva" ? bulkAddItems() : BulkLoad(formData)
    } else if (Attachment.length === 0) {
      openAlert(`Seleccione un archivo`, "error");
    } else if ((authUser.admin || authUser.is_commerce) && valor === "") {
      openAlert(`El campo de usuario calificado es necesario`, "error");
    } else {
      title === "Carga masiva" ? bulkAddItems() : BulkLoad(formData)
    }
  };

  const BulkLoad = async (data) => {
    try {
      const res = await __BulkLoad(
        title === "Carga masiva"
          ? requestBulkLoad
          : environment.ADJUSTMENT_PASES_INT,
        token,
        data
      );

      const respuestaAttachment = res.data.status;

      if (title === "Carga masiva") {
        if (respuestaAttachment?.code === 200) {
          openAlert(
            `Procesado con exito ${respuestaAttachment.data} pases`,
            "success"
          );
          return setUpdate(!update);
        }
      }

      if (respuestaAttachment?.code === 400) {
        infoNoExisten(respuestaAttachment.message[0]);
        return onOpenDelete();
      }

      openAlert(
        `Se cargaron ${respuestaAttachment.data.rows} pases`,
        "success"
      );
      setUpdate(!update);
      /* else {

        if (respuestaAttachment.data.notExist.length !== 0) {
          infoNoExisten(respuestaAttachment.data.notExist);
          onOpenDelete();
        } else {
          openAlert(
            `Se cargaron ${respuestaAttachment.data.rows} pases`,
            "success"
          );
          setUpdate(!update);
        }
      } */
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const infoNoExisten = (msj) => {
    Swal.fire({
      title: `Formulario no cargados `,
      icon: "warning",
      text: `${msj}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: "#1F7AC3",
      cancelButtonText: "Volver",
      customClass: {
        title: "class-text"
      }
    });
  };

  const formData = new FormData();
  formData.append("qualifiedUser_id", valor || authUser.userQualified);
  formData.append("file", Attachment);
  const { bulkAddItems, ErrorTable, isLoadingJobStatus: isLoadingBulkAggregation } = useBulkItemAggregation({
    userProvidedEndpoint: requestBulkLoad,
    userProvidedBody: formData,
    onClose,
    file: Attachment,
    nameFile: nameAttachment,
    update,
    setUpdate,
    key: requestBulkLoad?.split('/')?.[requestBulkLoad?.split('/')?.length - 1]
  })

  return (
    <>
      {authUser.admin || authUser.is_commerce
        ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2rem"
            }}
          >
            <InputGroupUser
              partidas={userCa}
              tag={"Empresa"}
              size={100}
              setMendigo={setValor}
              setNameQ={setNameQ}
              nameQ={nameQ}
              valor={valor}
            />
          </div>
        )
        : (
          ""
        )}
      <input
        onChange={handleAttachment}
        type="file"
        id="display"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div onClick={Upload} className="d-flex justify-content-center my-4">
        <div id="redonder" className="d-flex justify-content-center">
          <IconImport color="#fff" as={RiUploadCloudFill} />
        </div>
      </div>
      <br />
      <br />
      <span className="text-mute">
        {nameAttachment && nameAttachment}
        {nameAttachment && (
          <IconGeneric
            onClick={() => removeItemFromArr()}
            className="m-1 text-danger"
            as={MdDelete}
          />
        )}
      </span>
      <Flex className="mb-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled isLoading={isLoadingBulkAggregation} bgColor={isLoadingBulkAggregation ? "#bababa" : "#1F7AC3"} type="submit" onClick={!isLoadingBulkAggregation ? handleSubmit : null}>
          Guardar
        </ButtonStyled>
      </Flex>
      {title === "Carga masiva" && <ErrorTable />}
    </>
  );
};
