import { HeaderEstadistica } from 'components/Estadistica/HeaderEstadistica'
import environment from 'constants/apiConst';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { ExportPdfGrafica } from '../General/ExportPdfGrafica';
import SpinnerComponent from 'components/Spinner/SpinnerComponent';
import { ExportPdf } from 'components/Export/ExportPdf';
import { estadisticaOptions } from '../General/EstadisticaOptions';

const EstadisticaCese = () => {
  const title = "Estadísticas cese de actividades";
  const [currentPage, setCurrentPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    currentPage &&
      setData(
        {
          labels: currentPage?.map(({ name_qualified }) => name_qualified),
          datasets: [
            {
              label: "Cantidad",
              data: currentPage?.map(({ days }) => days),
              backgroundColor: ["#2898ee"]
            }
          ]
        }
      )
  }, [currentPage])

  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);

  const ref = useRef(null);

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = ref.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  useEffect(() => {
    if (rutaImage) ExportPdf("IdPdfFmmCorrectEntry", `${title}_${dateToday}`);
  }, [rutaImage]);

  return (
    <div>
      <HeaderEstadistica
        title={title}
        enableQualifiedUser
        // generar64={dowmLoadPdfImgae}
        excelPath={environment.POST_EXPORT_EXCEL_CESE}
        setCurrentPage={setCurrentPage}
        setLoading={setLoading}
        obtenerPath={environment.OBTENER_STATISTICS_CESE}
        enableRendering={true}
        filterDays={true}
        filterDates={false}
      />
      {loading && <SpinnerComponent />}
      {!loading && (
        <>
          <ExportPdfGrafica
            id="IdPdfFmmCorrectEntry"
            rutaImage={rutaImage}
            thead={""}
            data={""}
            tbody={"TbodySecurity"}
            title={`Estadisticas de ${title}`}
            dateToday={dateToday}
            filterDates={false}
          />
          <div className="d-flex">
            <div id="contenedorResultado" className="px-2">
              {!loading && data !== null && <Bar data={data} ref={ref} options={estadisticaOptions(
                "Usuarios calificados",
                "Días"
              )}/>}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EstadisticaCese
