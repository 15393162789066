import React, { useContext, useEffect } from 'react'
import styled from "styled-components/macro";
import { MenuItem, useDisclosure } from "@chakra-ui/react";
import { IconMenuRUD } from 'styled/Icons.styled';
import { BsCheck2Circle } from 'react-icons/bs';
import { HrDividerNoMargin } from 'styled/Line.styled';
import { AiOutlineFileSync } from 'react-icons/ai';
import IntegracionBoxCrudContex from 'context/IntegracionBoxCrudContex';
import { ModalGeneric } from 'components/modal/ModalGeneric';
import { ReasonForReturnComponent } from 'components/DispatchCorrections/ReasonForReturnComponent';
import environment from 'constants/apiConst';
import { useNavigate } from 'react-router-dom';

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const OptionsReviewedBox = ({ data }) => {
  const navigate = useNavigate()

  const {
    changeStatus,
    setRequestChangeStatusBox,
    update,
    setUpdate
  } = useContext(IntegracionBoxCrudContex)

  const {
    isOpen: isOpenReturnReason,
    onOpen: onOpenReturnReason,
    onClose: onCloseReturnReason
  } = useDisclosure()

  useEffect(() => {
    setRequestChangeStatusBox(environment.CHANGE_STATUS_BOX);
  }, []);

  const handleStatusBox = (status) => {
    const statusData = new FormData();
    statusData.append("id", data?.integration_certificate_id);
    statusData.append("status", status);

    if (status === "DEVUELTO" && data?.status === "REVISADO") {
      onOpenReturnReason()
    }

    if (status === "AUTORIZADO") {
      changeStatus(statusData);
    }
  };

  const Update = () => {
    setUpdate(!update)
  }

  return (
    <>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title='Motivo de devolución'
        size='xl'
        scrollBehavior='outside'
      >

        <ReasonForReturnComponent
          onClose={onCloseReturnReason}
          id={data?.integration_certificate_id}
          PropertyReasonForReturn={'reason_for_return'}
          PropertyStatus={'status'}
          serviceReturn={environment.CHANGE_STATUS_BOX}
          callBack={Update}
        />

      </ModalGeneric>

      <MenuItemStyled py={3} onClick={() => handleStatusBox("AUTORIZADO")} >
        <IconMenuRUD as={BsCheck2Circle} />
        Radicar
      </MenuItemStyled>

      <HrDividerNoMargin />

      <MenuItemStyled py={3} onClick={() => handleStatusBox("DEVUELTO")}>
        <IconMenuRUD color="#d92525" as={AiOutlineFileSync} />
        Devolver
      </MenuItemStyled>
    </>
  )
}

export default OptionsReviewedBox
