import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isArray, isValid } from 'utils/type-check-utils';

const returnTypes = {
  OBJECT: 'OBJECT',
  ARRAY: 'ARRAY',
}

/**  
  * useSearchUrl - Hook personalizado para manejar URLs de búsqueda.
  * @author Carvajalo - Dmonasterios
  * @param {array} getParams - Arreglo de parámetros de búsqueda.
  * @param {string} nestedProp - Propiedad anidada para la actualización de parámetros anidados.
  * @param {string} returnType - El tipo de retorno de la función.
  * @returns {{ searchParams, setSearchParams, state, handleSearchParams, setParamsSearch, cleanState, clearParamsSearch }} - Un arreglo o un objeto con los valores de los parámetros de búsqueda.
  *  
*/

export const useSearchUrl = ({ getParams = [], nestedProp = null, returnType = returnTypes.OBJECT }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const currentParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  /* Esto genera como un initialState [key]: value || null para initializar forms con los valores de la URL */
  const state = useMemo(() => nestedProp ? (
    getParams.reduce((acc, curr) => {
      try {
        acc[curr] = currentParams[nestedProp] && isValid(JSON.parse(currentParams[nestedProp])[curr]) ? JSON.parse(JSON.parse(currentParams[nestedProp])[curr]) : null;
      } catch (e) {
        acc[curr] = currentParams[nestedProp] && isValid(JSON.parse(currentParams[nestedProp])[curr]) ? JSON.parse(currentParams[nestedProp])[curr] || null : null;
      }
      return acc;
    }, {})
  ) : (
    getParams.reduce((acc, curr) => {
      acc[curr] = isValid(currentParams[curr])
        ? JSON.parse(currentParams[curr]) || null
        : null;
      return acc;
    }, {})
  ), [getParams, currentParams, nestedProp]);


  /* Esto regresa los valores de los parámetros de búsqueda que no son nulos */
  const cleanState = useMemo(() => {
    return Object.entries(state).reduce((acc, [key, value]) => {
      if (value === null) return acc;
      return { ...acc, [key]: value };
    }, {});
  }, [state]);


  /* To avoid listening the prop in the nested state */
  const cleanStateWithoutProp = useMemo(() => {
    try {
      if (!nestedProp) return currentParams
      if (!isValid(currentParams[nestedProp])) return {}
      const params = JSON.parse(currentParams[nestedProp]);
      return Object.entries(params).reduce((acc, [key, value]) => {
        if (isValid(JSON.parse(value))) {
          acc[key] = JSON.parse(value);
        } else {
          acc[key] = value;
        }

        return acc;
      }, {});
    } catch (e) {
      console.log({ error: e, stack: e.stack });
      return {}
    }
  }, [currentParams, nestedProp]);


  const currentSearch = useMemo(() => {
    if (!nestedProp) {
      return currentParams.reduce((acc, [key, value]) => {
        try {
          acc[key] = JSON.parse(value);
        } catch (e) {
          acc[key] = value;
        };
        return acc;
      }, {});
    }

    if (!isValid(currentParams[nestedProp])) return {};

    try {
      const nestedState = Object.entries(JSON.parse(currentParams[nestedProp])).reduce((acc, [key, value]) => {
        acc[`${key}`] = JSON.parse(value);
        return acc;
      }, {})
      return nestedState;
    } catch {
      return JSON.parse(currentParams[nestedProp]);
    }

  }, [currentParams, nestedProp]);
  /**
   * handleSearchParams - Función para manejar los cambios en los parámetros de búsqueda.
   * @param {Object: { name: string, value: any }} params - Objeto para actualizar un parámetro de búsqueda a través del name
   * @returns {void}
    */
  const handleSearchParams = ({ name, value }) => {
    if (nestedProp) {
      const { [nestedProp]: currentNested, ...nestedRest } = currentParams;
      const { [name]: __, ...restState } = isValid(currentNested) ? JSON.parse(currentNested) : {};

      const newNestedState = {
        ...restState,
        ...(isValid(value) && {
          [name]: value
        }),
      }

      /* console.log({ newNestedState, name, value, restState, currentNested, nestedRest }); */

      const isNesting = Object.keys(newNestedState).length > 0;

      const updatedParams = {
        ...nestedRest,
        ...(isNesting && { [nestedProp]: JSON.stringify(newNestedState) }),
      };

      const updatedSearchParams = new URLSearchParams(updatedParams);
      setSearchParams(updatedSearchParams);
      return;
    }


    const { [name]: _, ...rest } = currentParams;
    const updatedParams = { ...rest, ...(value && { [name]: value }) };
    const updatedSearchParams = new URLSearchParams(updatedParams);
    setSearchParams(updatedSearchParams);
  }


  /** 
   * setParamsSearch - Función para manejar los cambios en los parámetros de búsqueda.
   * @param {params: Object} params - Objeto para actualizar varios parámetros de búsqueda a través de un objeto de parámetros
   * @returns {void}
    */
  const setParamsSearch = ({ params }) => {
    const newNestedState = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = JSON.stringify(value);
      return acc;
    }, {});
    if (nestedProp) {
      const { [nestedProp]: currentNested, ...nestedRest } = currentParams;

      const updatedParams = {
        ...nestedRest,
        ...(Object.keys(newNestedState).length > 0 && { [nestedProp]: JSON.stringify(newNestedState) }),
      };
      const updatedSearchParams = new URLSearchParams(updatedParams);
      setSearchParams(updatedSearchParams);
      return;
    }
  }


  /**
   * clearParamsSearch - Función para borrar los parámetros de búsqueda.
   * @param {searchArray: Array<string>} searchArray - Arreglo de parámetros de búsqueda a borrar
   * @returns {void}
  */
  const clearParamsSearch = ({ searchArray = [] }) => {
    if (isArray(searchArray)) {
      for (const element of searchArray) {
        searchParams.delete(element);
      }
    }
    setSearchParams(searchParams);



    /*  const updatedSearchParams = new URLSearchParams([...searchParams].filter(([key, value]) => !searchArray.includes(key)));
     setSearchParams(updatedSearchParams); */
  }



  if (returnType === returnTypes.OBJECT) return { searchParams, setSearchParams, state, handleSearchParams, setParamsSearch, cleanState, cleanStateWithoutProp, currentSearch, clearParamsSearch };
  return [searchParams, setSearchParams, state, setParamsSearch, cleanState, cleanStateWithoutProp, handleSearchParams];
};