import { SimpleGrid } from "@chakra-ui/react";
import { InputWithLabel } from "components/Input/InputFakeCustoms";

const getColorForFormField = (form, id) => {
  const review = false === form?.reviews;
  const reviewUpdates = form?.review_updates?.find(
    (element) => element?.form_field === id
  );

  if (!review && !reviewUpdates) {
    return "#bababa80"; // Color por defecto si no se encuentra el campo en las reviews
  }

  if (reviewUpdates) {
    switch (reviewUpdates?.color) {
      case 1:
        return "red"; // Color para status 1
      case 0:
        return "purple"; // Color para status 0
      default:
        return "#bababa80"; // Color por defecto si el status no es reconocido
    }
  }

  if (review) {
    switch (review?.status) {
      case 2:
        return "#D40418"; // Color para status 2
      case 1:
        return "green"; // Color para status 1
      case 0:
        return "gray"; // Color para status 0
      default:
        return "#bababa80"; // Color por defecto si el status no es reconocido
    }
  }
};

const getCommentForTooltip = (form, id) => {
  const review = form?.reviews;
  const reviewUpdates = form?.review_updates;

  if (!review && !reviewUpdates) {
    return "";
  }

  if (reviewUpdates) return reviewUpdates?.comment;
  if (review) return review?.comment;
};

const ShowFormOrdenProduccion = ({ ...props }) => {
  const { data, form } = props;
  console.log({data, form});
  return (
    <>
      <form>
        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`product_business_unit_name_${data?.item_code}`}
              labelText={"Unidad de producto"}
              value={(data?.business_unit || data?.business_unit_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `product_business_unit_name_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `product_business_unit_name_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`product_convertion_factor_${data?.item_code}`}
              labelText={"Factor conversión"}
              value={(data?.convertion_factor_product || data?.convertion_factor_product_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `product_convertion_factor_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `product_convertion_factor_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`quantity_product_${data?.item_code}`}
              labelText={"Cantidad producto"}
              value={(data?.quantity_product || data?.quantity_product_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `quantity_product_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `quantity_product_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}

              )} */
            />
          </div>
        </SimpleGrid>

        <div>
          <InputWithLabel
            p={0}
            borderRadius={"none"}
            name={`product_description_${data?.item_code}`}
            labelText={"Descripción del producto"}
            value={(data?.description_product || data?.description_product_corrected) ?? "N/A"}
            inputBgColor={getColorForFormField(
              form,
              `product_description_${data?.item_code}`
            )}
            tooltipTextComment={getCommentForTooltip(
              form,
              `product_description_${data?.item_code}`
            )}
            /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
              inputBgColor={GetColorInput([`net_weight_${idItems}`])}
             */
          />
        </div>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`description`}
              labelText={"Descripción"}
              value={(data?.description_product || data?.description_product_corrected) ?? "N/A"}
              inputBgColor={getColorForFormField(
                form,
                `description_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `description_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
              inputBgColor={GetColorInput([`net_weight_${idItems}`])}
              */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`subheading_code_${data?.item_code}`}
              labelText={"Subpartida"}
              value={(data?.code_subheading || data?.code_subheading_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `subheading_code_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `subheading_code_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}

              )} */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`quantity_${data?.item_code}`}
              labelText={"Cantidad"}
              value={(data?.quantity || data?.quantity_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `quantity_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `quantity_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="200px" spacing="10px" className="mt-3 mb-4">
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`product_unit_measurement_${data?.item_code}`}
              labelText={"Unidad comercial"}
              value={(data?.unit_measurement || data?.unit_measurement_corrected) ?? "N/A"}
              inputBgColor={getColorForFormField(
                form,
                `product_unit_measurement_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `product_unit_measurement_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`net_weight_kilo_${data?.item_code}`}
              labelText={"Peso neto en kilos"}
              value={(data?.net_weight_kilo || data?.net_weight_kilo_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `net_weight_kilo_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `net_weight_kilo_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
          <div>
            <InputWithLabel
              p={0}
              borderRadius={"none"}
              name={`fob_value_${data?.item_code}`}
              labelText={"Valor FOB en US$"}
              value={(data?.fob_value || data?.fob_value_corrected) ?? "0"}
              inputBgColor={getColorForFormField(
                form,
                `fob_value_${data?.item_code}`
              )}
              tooltipTextComment={getCommentForTooltip(
                form,
                `fob_value_${data?.item_code}`
              )}
              /* tooltipText={GetTooltipText([`net_weight_${idItems}`])}
               */
            />
          </div>
        </SimpleGrid>
      </form>
    </>
  );
};

export default ShowFormOrdenProduccion;
