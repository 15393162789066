import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { renderInputWithLabel } from "pages/Entry_and_exit_forms/View/ViewItems/ViewItemsEntryForm";

import React from "react";
import { useLocation } from "react-router-dom";

export const TerritoryNationalDisable = ({
  form,
  notations = null,
  activeReview = true,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const isShowForm = pathname === "/FormShow";
  const isShowExitForm = pathname === "/FormShowExit";
  const isCheckFormExit = pathname === "/CheckForm";
  const isCheckFormEntry = pathname === "/CheckFormEntry";

  const annotationInfo = {
    notations,
    id: "Header",
    data: form,
  };

  return (
    <>
      {/* Cert. de inspecc. preembarque */}
      {renderInputWithLabel({
        labelText: "Cert. de inspecc. preembarque",
        name: "certificate_inspection_pre_boarding",
        activeComment: isCheckFormExit || isCheckFormEntry,
        manualValue: `${form?.certificate_inspection_pre_boarding}`,
        annotationInfo,
        disableFormatting: true,
        additionalProps: {
          background: "#F9F9F9",
          className: "col-md-4",
        },
      })}

      {/* Fecha */}
      {renderInputWithLabel({
        labelText: "Fecha",
        name: "date",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
          className: "col-md-4",
        },
      })}

      {/* Factura Comercial */}
      {renderInputWithLabel({
        labelText: "Factura Comercial",
        name: "commercial_invoice",
        activeComment: isCheckFormExit || isCheckFormEntry,
        annotationInfo,
        additionalProps: {
          background: "#F9F9F9",
          className: "col-md-4",
        },
        disableFormatting: true,
      })}
    </>
  );
};
