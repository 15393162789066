/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import FormDepaShow from "../FormShow/FormDepaShow";
import TableOrder from "components/Tables/TableOrder";
import ConfigurationHeader from "../ConfigurationHeader";
import TBodyDeparta from "components/Tables/TBodyDeparta";
import Pagination from "components/Pagination/Pagination";
import EditFormDepartament from "components/Form/EditFormDepartament";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { __Departments } from "request/configuration/__Localitation";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";

export const Departments = () => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const location = useLocation();
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  const { q = "" } = queryString.parse(location.search);
  const {
    update,
    setFormShow,
    setFormEdit,
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    loading,
    setLoading,
  } = useContext(ConfigurationCRUDContext);
  // const [isOpenModalNP, openModalNP, closeModalNP] = useModal();

  const TitleDepartament = [
    { name: "Código", order: "code_department" },
    { name: "Nombre", order: "name_department" },
    { name: "Estado", order: "status_department" },
    { name: "Acciones", order: null },
  ];

  const formEditDep = () => {
    return <EditFormDepartament />;
  };

  const formShowD = () => {
    return <FormDepaShow />;
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormShow(formShowD);
    setFormEdit(formEditDep);
    setRequestEdit(environment.DEPARTMENTS_UPDATE);
    setRequestCreate(environment.DEPARTMENTS_CREATE);
    setRequestDelete(environment.DEPARTMENTS_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configDepartments"] });
  }, [page, q, update]);

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configDepartments", page, q, freeZone_id],
    queryFn: () =>
      requestData({
        Endpoint: environment.GETDEPARTMENTS,
        pageParam: page,
        ...(q?.length !== 0 && { data: { search: q } }),
        PropertyBody: "sendJSONContentPOST",
      }),
  });

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page]);

  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Departamentos" />
      {isLoading || (
        <TableOrder
          thead={TitleDepartament}
          data={
            data?.data?.status?.code === 200
              ? data?.data?.status?.data?.data
              : []
          }
          isNum={false}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TBodyDeparta}
        />
      )}

      <Pagination
        page={page}
        setPage={setPage}
        maxPage={
          data?.data?.status?.code === 200
            ? data?.data?.status?.data?.last_page
            : 1
        }
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};
