import { FREEZONE_TYPES } from "redux/types/statusZones";

const initialState = {
  freeZoneValue: false
};

export const statusFreeZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case FREEZONE_TYPES.STATUSFREEZONE:
      return {
        ...state,
        freeZoneValue: action.payload
      };

    default:
      return state;
  }
};
