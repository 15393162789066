/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { H2 } from "./stylePases";
import "./stilo.css";
import { Box, Tooltip } from "@chakra-ui/react";
import { HeaderSearch } from "styled/SectionContainer.styled";
import TablePass from "components/Tables/TablesPass";
import Pagination from "components/Pagination/Pagination";
import CreateyPresented from "./CreateyPresented";
import { MdDelete } from "react-icons/md";
import {
  TitleTablePassDispacthShow,
  TitleTablePassShow,
} from "./View/ArrayHeadTable/TheadMovementInt";
import { VehiculoInfo } from "./View/Show/VehiculoInfo";
import { useForm } from "hooks/useForm";
import { __ShowPassInt } from "request/Pases/__CrudPass";
import PassCrudContext from "context/PassCrudContext";
import { AuthContext } from "context/AuthContext";
import bro from "../../assets/bro.png";
import { BtnHeaderShow } from "./BtnHeaderShow";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __ShowPassDispatch } from "request/DespachoAgrupado/__DispatchAg";
import environment from "constants/apiConst";
import {
  GrDocumentWord,
  GrDocumentPdf,
  GrDocumentWindows,
  GrDocumentText,
} from "react-icons/gr";
import { IconGeneric } from "styled/Icons.styled";
import { ExportPassPdf } from "./ExportPass/ExportPassPdf";
import { AddPhotography } from "./View/Adjunto/AddPhotography";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { SubTitle, Title } from "styled/Title";
import { TbodyItemShow } from "./tbody/TbodyItemShow";
import { TbodyDispacthShow } from "./tbody/TbodyDispacthShow";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormReturnReason } from "./FormReturnReason";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { AlertSecurityAssociate } from "components/AlertSecurityAssociate/AlertSecurityAssociate";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Commentary } from "components/Commentary/Commentary";
import { __Post } from "request/Petitions/__Post";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";
import useStatusListener from "hooks/useStatusListener";
import { Storage } from "hooks/useStorage";
import isArrayNotEmpty, {
  isArray,
  isObject,
  isObjectNotEmpty,
  isString,
} from "utils/type-check-utils";
import Vapor from "laravel-vapor";
import Attachment from "components/DispatchCorrections/Attachment";
import InputSelect from "components/InputSelect/InputSelect";

const extensionOptions = [
  ".doc",
  ".docx",
  ".pdf",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".bmp",
  ".xls",
  ".xlsx",
  ".xlsm",
  "image/png",
  "image/*",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-excel",
];

const CheckPass = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phtotoGra, setPhotoGra] = useState(null);
  const [attached, setAttached] = useState([]);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [confirmationDriver, setConfirmationDriver] = useState(0);
  const [confirmationCompany, setconfirmationCompany] = useState(0);
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" })

  const navigate = useNavigate();
  const [openAlert] = AlertErrorAlert();
  const [Generic, setGeneric] = useState(null);

  const location = useLocation();
  const [manipuleo, setManipuleo] = useState(0);

  const [values, handleInputChange, reset, setValues] = useForm({
    vehicle_plate: "",
    identification: "",
    driver: "",
    number_packages: "",
    observations: "",
    handling_process: "",
    freely_available: "",
    name_typeTransport: "",
    created_at: "",
    phone: "",
    name_qualified: "",
    confirmation_driver: "",
    confirmation_company: "",
    reason_for_return: "",
    consecutive: "",
    tare_weight: "",
    net_weight: "",
    gross_weight: "",
    weighing_id: "",
  });

  const {
    dataToShow,
    setRequestEdit,
    setRequestChangeStatus,
    update,
    deleteAttachment,
    setRequestDeleteAttachment,
    deletePhoto,
    setRequestDeletePhoto,
    currentItem,
    setCurrentItem,
    maxPageItem,
    obtenerItemPass,
    setRequestObtenerItemPass,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    setRequestPhoto,
    alreadySailed,
    setAlreadySailed,
  } = useContext(PassCrudContext);

  const {
    createCommentary,
    update: updateCommentary,
    newCommentary,
  } = useContext(FormCrudCRUDContext);

  const { authUser } = useContext(AuthContext);

  const {
    typeO = "",
    status = "",
    id = "",
    numberForm = "",
    qualifiedUser: qualifiedUserUrl = "",
  } = queryString.parse(location.search);

  useEffect(() => {
    if (typeO === "Dispatch") {
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT_DISPACTH);
      setRequestEdit(environment.UPDATE_DISPATCH);
      setRequestChangeStatus(environment.CHANGESTATUSDISPACTH);
      setRequestDeletePhoto(environment.DELETE_PHOTO_DISPACTH);
      setRequestPhoto(environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY);
      setRequestObtenerItemPass(environment.GET_ITEM_DISPACTH_EXIT);
    } else {
      setRequestDeletePhoto(environment.DELETE_PHOTO);
      setRequestDeleteAttachment(environment.DELETE_ATTACHMENT);
      setRequestEdit(environment.UPDATE_PASES_INT);
      setRequestPhoto(environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY);
      setRequestChangeStatus(environment.CHANGESTATUSPASS);
      setRequestObtenerItemPass(environment.OBTENER_ITEM_ENTRADA_PASES_INT);
    }

    if (dataToShow || id) {
    } else {
      setValues(values);
    }
  }, [dataToShow, update, id]);

  const { requestData } = useGlobalContext();
  const firstRender = useRef(true);

  const endPoint =
    typeO === "Dispatch"
      ? environment.SHOW_DISPATCH
      : environment.SHOW_PASES_INT;

  const {
    data: dataViewPass,
    refetch: refetchViewPass,
    isFetching: isFetchingViewPass,
    isSuccess: isSuccessViewPass,
    isError: isErrorViewPass,
  } = useQuery({
    queryKey: ["viewPass", id, dataToShow, update],
    queryFn: () =>
      requestData({
        Endpoint: endPoint,
        resourceIdentifier: id,
      }),
    refetchOnWindowFocus: false,
  });


  const isStatusPass = dataViewPass?.data?.status?.data?.status_entryPass || dataViewPass?.data?.status?.data?.status_dispatchPass

  useEffect(() => {
    const calculateRoute = isStatusPass === 'REVISADO' ? `/${typeO === 'Dispatch' ? 'CheckPassOutput' : 'CheckPassEntry'}?numberForm=${numberForm}&id=${id}&status=${isStatusPass}&typeO=${typeO}` : `/pases?numberForm=${numberForm}&id=${id}&status=${isStatusPass}&typeO=${typeO}`;

    if (location?.pathname + location?.search !== calculateRoute && isSuccessViewPass && !alreadySailed) {
      console.log({ location: location?.pathname + location?.search, calculateRoute })
      navigate(calculateRoute)
      setAlreadySailed(true)
    }

  }, [isStatusPass])


  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (
        (update && status === "AUTORIZADO" && authUser.admin) ||
        authUser.is_commerce
      ) {
        const element = document.getElementById("element-id");
        if (element) {
          element.parentNode.removeChild(element);
        }
      }

      const dataPass = dataViewPass?.data?.status?.data;

      if (isErrorViewPass) {
        openAlert(dataPass?.data?.status?.message, "error");
        navigate(-1);
        return;
      }

      if (isSuccessViewPass) {
        setPhotoGra(dataPass?.photographies);
        setValues(dataPass);
        setAttached(dataPass?.attachments);
        setGeneric(dataPass?.shipping_form);
        setManipuleo(dataPass?.handling_process);
        setConfirmationDriver(parseInt(dataPass?.confirmation_driver));
        setconfirmationCompany(parseInt(dataPass?.confirmation_company));
      }
    }
  }, [isFetchingViewPass]);

  const downloadAttachment = (url) => {
    window.location.assign(url);
  };

  const removeItemFromArr = (arr, item, index, archivo, id) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${item}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (arr[index].name) {
          const resultado = arr.filter((e) => e.name !== item);
          // setAttachedes(resultado);
        } else {
          const resultado = arr.filter((e) => e.name_attachment !== item);
          //setAttachedes(resultado);
          const formData = new FormData();
          formData.append("attachment", archivo);
          deleteAttachment(formData, id);
        }
      }
    });
  };

  const deleteImaServer = (arr, index) => {
    Swal.fire({
      title: `¿Estas seguro de eliminar la fotografía  "${index.name_photography}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        const resultado = arr.filter(
          (e) => e.name_photography !== index.name_photography
        );
        setPhotoGra(resultado);
        const formData = new FormData();
        formData.append("photography", index.name_photography);
        deletePhoto(formData, index.id);
      }
    });
  };

  useEffect(() => {
    if (typeO === "Dispatch") {
      obtenerItemPass(id, page, environment.GET_ITEM_DISPACTH_EXIT, { orderBy: orderBy?.value });
    } else {
      obtenerItemPass(id, page, environment.OBTENER_ITEM_ENTRADA_PASES_INT, { orderBy: orderBy?.value });
    }
  }, [id, typeO, orderBy, page]);

  const [comentarios, setComentarios] = useState([]);
  const divCommentary = document.querySelector(".chakra-modal__body");

  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async (path) => {
    try {
      const res = await __CommentaryForm(token, id, path);
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!numberForm) return;

    if (typeO === "Dispatch") {
      viewCommentary(environment.GET_COMMENTS_PASES_DISPATCH);
    } else {
      viewCommentary(environment.GET_COMMENTS_PASES_INT);
    }
  }, [id, typeO, numberForm, updateCommentary, newCommentary]);

  const { get: getStorage } = Storage;
  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${id || numberForm}`) || "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${id || numberForm}`,
        JSON.stringify(filteredErrors)
      );
    }
  };
  const queryClient = useQueryClient();

  const updateAndCreate = (data = undefined, payloadManual = false) => {
    const formData = new FormData();
    formData.append("id", id || numberForm);

    if (isObject(data) && isObjectNotEmpty(data)) {
      if (data) {
        Object.keys(data).forEach((documentId) => {
          if (!data[documentId].from_server) {
            formData.append("photography[]", JSON.stringify(data[documentId]));
          }
        });
      }
    }

    const requestData = {
      data: payloadManual ? data : formData,
      Endpoint:
        typeO === "Dispatch"
          ? environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY
          : environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
      PropertyBody: "sendFormDataContentPOST",
    };

    mutate(requestData, {
      onSuccess: () => {
        setPhotoGra([]);
        setAttached([]);
        queryClient.invalidateQueries({ queryKey: ["viewPass"] });
      },
    });
  };

  const updateFileState = (uploadResponse, name, targetId) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};

    const file = {
      [targetId]: {
        file: uploadResponse,
        name,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file);

    return localStorage.removeItem("uploadData");
  };

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const handleFilesInput = async (e, file) => {
    try {
      e.preventDefault();
      const { files: uploadedFiles, id } = e.target;

      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);
      if (!isValidExtension) return;

      const idDocument = extractDocumentId(id);
      const uploadResponse = await uploadFile(uploadedFiles[0]);
      updateFileState(uploadResponse, file?.name, e.target.id);

      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
    }
  };

  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const submitReviewBasedOnStatus = async () => {
    if (is_qualified_user) return;

    if (status === "REVISADO") {
      const body = new FormData();
      body.append("form_id", id);
      body.append("type_of", typeO === "Dispatch" ? "DESPACHO" : "ENTRADA");
      body.append("type", 1);
      const res = await __Post(environment.FORMS_ON_REVIEW, token, body); // FORMS_ON_REVIEW
      if (res?.data?.status?.code === 200) {
      }
      if (res?.data?.status?.code === 400) {
        openAlert(res?.data?.status?.message, "error");
        navigate(
          typeO !== "Dispatch"
            ? "/entry-movement?movement=Pase de entrada"
            : "/output-movement?movement=Pase de salida"
        );
      }
    }
  };
  useEffect(() => {
    submitReviewBasedOnStatus();
  }, [status]);

  const propsBlockForm = {
    form_id: id,
    type_of: typeO === "Dispatch" ? "DESPACHO" : "ENTRADA",
    type: 0,
    endpoint: environment.FORMS_ON_REVIEW,
  };

  const refetchForm = async (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch = (flag === "entry_pass" || flag === "dispatch_pass")
    if (new_status === 'forms_on_review') return;

    if (shouldRefetch) {
      const data = await refetchViewPass();
      const res = data?.data?.data?.status;
      const code = res?.code

      if (new_status === 'REVISADO' && flag === 'dispatch_pass') {
        return navigate(
          `/CheckPassOutput?numberForm=${res?.data?.passForm_number}&id=${form_id}&status=${new_status}&typeO=Dispatch`
        )
      }

      if (new_status === 'REVISADO' && flag === 'entry_pass') {
        return navigate(
          `/CheckPassEntry?numberForm=${res?.data?.passForm_number}&id=${form_id}&status=${new_status}&typeO=`
        )
      }

      return navigate(
        `/pases?numberForm=${res?.data?.passForm_number}&id=${form_id}&status=${new_status}&typeO=${flag === "dispatch_pass" ? "Dispatch" : ""}`
      );

    }
  };

  useStatusListener({
    id,
    callBack: refetchForm,
    dataCurrent: values,
  });

  const endpointAttachments = {
    Dispatch: {
      create: environment.SAVE_DISPATCH_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT_DISPACTH,
    },
    default: {
      create: environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY,
      delete: environment.DELETE_ATTACHMENT,
    },
  }[typeO || "default"];

  const handleResponseItems = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      const resData = res?.data?.status?.data;
      setGeneric(resData?.shipping_form);
      setAttached(resData?.attachments);
      setPhotoGra(resData?.photographies);
    }
  };

  const fetchItems = () => {
    const config = {
      resourceIdentifier: id,
      Endpoint:
        typeO === "Dispatch"
          ? environment.SHOW_DISPATCH
          : environment.SHOW_PASES_INT,
    };

    mutate(config, {
      onSuccess: (data) => handleResponseItems(data),
    });
  };

  return (
    <>
      <ExportPassPdf id="display-vacio" values={values} />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={PassCrudContext}
          propsBlockForm={propsBlockForm}
        />
      </ModalGeneric>

      <Commentary
        id={id}
        ruta={
          typeO === "Dispatch"
            ? environment.COMMENTS_PASES_DISPATCH
            : environment.COMMENTS_PASES_INT
        }
        create={createCommentary}
        commentarys={comentarios}
      />

      <div>
        <HeaderForm
          status={status}
          numberForm={numberForm}
          UserQualified={values?.name_qualified}
          Modulo={`Formulario de ${typeO === "Dispatch" ? " Despachos" : "Entradas"
            } de Mercancía en Zonas Francas`}
        />
        <BtnHeaderShow values={values}>
          {values?.reason_for_return &&
            (status === "BORRADOR" || status === "DEVUELTO") && (
              <AlertReturnReason
                title={"Tipo de devolución:"}
                item={values.typifications}
                description={
                  values?.reason_for_return && values?.reason_for_return
                }
                nickname={values?.nickname}
              />
            )}
          <CreateyPresented values={values} />
        </BtnHeaderShow>

        {values?.security && (
          <AlertSecurityAssociate
            title={"Asociado a Seguridad"}
            item={values.security}
            idDoc={values.id}
            nickname={""}
            numberDoc={values?.passForm_number}
            documento={typeO}
            initialpos={refetchViewPass}
          />
        )}

        {/* <Security_association /> */}
        <HeaderSearch>
          <VehiculoInfo
            values={values}
            setValues={setValues}
            setManipuleo={setManipuleo}
            manipuleo={manipuleo}
            setConfirmationDriver={setConfirmationDriver}
            confirmationDriver={confirmationDriver}
            setconfirmationCompany={setconfirmationCompany}
            confirmationCompany={confirmationCompany}
            typeOperation={typeO === "Dispatch" ? " Despacho" : "Entrada"}
          />
        </HeaderSearch>

        <HeaderSearch>
          <div>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={4}>
              <Title size={"18px"} weight={600}>
                Ítem del Formulario
              </Title>
              <InputSelect
                data={[{ value: "ASC", label: "Ascendente" }, { value: "DESC", label: "Descendente" }]}
                value={orderBy}
                name="order_by"
                set={(e) => {
                  setOrderBy(e);
                }}
                placeholder="Ordenar por"
                inputAttrs={{
                  color: '#5f5f',
                }}
                size={30}
                isClearable={false}
              />
            </Box>
            {currentItem.length === 0 ? (
              <div>
                <div className="d-flex justify-content-center ">
                  <img
                    style={{ width: `${15}%` }}
                    src={window.assetURL + bro}
                    alt="no hay formulario"
                  />
                </div>
                <SubTitle size={"15px"} className="text-center ">
                  Aún no hay Formulario asociados a esta lista
                </SubTitle>
              </div>
            ) : (
              <>
                <TablePass
                  thead={
                    typeO === "Dispatch"
                      ? TitleTablePassDispacthShow
                      : TitleTablePassShow
                  }
                  data={currentItem}
                  setData={setCurrentItem}
                  loading={loading}
                  tbodyData={
                    typeO === "Dispatch" ? TbodyDispacthShow : TbodyItemShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPageItem}
                  loading={loading}
                />
              </>
            )}
          </div>
        </HeaderSearch>

        <HeaderSearch>
          <Box>
            <Attachment
              ServicetoSave={endpointAttachments?.create}
              DeleteToService={endpointAttachments?.delete}
              attachedProperty={{
                attachedName: "name_attachment",
                delete: "name_attachment",
                id: "id",
              }}
              Vaporload={true}
              viewKeyToUpdate="viewPass"
              data={attached}
              formId={id}
              setAttachment={setAttached}
            />

            {typeO !== "Dispatch" && (
              <Attachment
                ServicetoSave={environment.SAVE_ENTRY_ATTACHMENT_PHOTOGRAPHY}
                DeleteToService={environment.DELETE_ATTACHMENT}
                attachedProperty={{
                  attachedName: "name_attachment",
                  delete: "name_attachment",
                  id: "id",
                  postPropertyName: "shipping_form[]",
                  propertyName: "shipping_form",
                }}
                Vaporload={true}
                data={Generic}
                setAttachment={setGeneric}
                formId={id}
                callBack={fetchItems}
                title="Planilla de envío *"
              />
            )}
          </Box>
        </HeaderSearch>
        <HeaderSearch>
          {(status === "PRESENTADO" || status === "REVISADO") &&
            (authUser.admin === 1 || authUser.is_commerce === 1) ? (
            <div>
              <AddPhotography
                photography={phtotoGra}
                setPhotography={setPhotoGra}
                updateAndCreate={handleFilesInput}
                isLoading={isFetchingViewPass}
              />
            </div>
          ) : (
            phtotoGra && (
              <>
                {status === "AUTORIZADO" && (
                  <div id="marginIcon">
                    <H2 id="titleSection">Registro fotográfico</H2>
                    <div className="horizontal-snap">
                      {phtotoGra?.map((items, index) => (
                        <a
                          key={index}
                          onClick={() =>
                            authUser.admin || authUser.is_commerce
                              ? deleteImaServer(phtotoGra, items)
                              : ""
                          }
                        >
                          <figure className="hover-img">
                            <img
                              src={items?.photografy_url}
                              alt={items?.photografy_url}
                              id="imgDelete"
                            />

                            <figcaption>
                              <h3 className="m-1">x</h3>
                            </figcaption>
                          </figure>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </HeaderSearch>
      </div>
    </>
  );
};

export default CheckPass;
