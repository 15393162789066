import InputSelect, { InputSelectMulti } from "components/InputSelect/InputSelect"
import { LabelGroup } from "pages/RefProductInter/LabelGroup"
import { NumericFormat } from "react-number-format"

const GetTypesSelects = (params, set) => {
  const typeSelect = {
    multi: <InputSelectMulti
      {...params}
      set={(current) => {
        set(prev => {
          const index = prev.findIndex((element) => element?.name === params?.name);
          if (index === -1) return
          prev[index].value = current;
          return [...prev];
        })
      }}

    />,
    single: <InputSelect
      onChange={(current) => {
        set(prev => {
          const index = prev.findIndex((element) => element?.name === params?.name);
          if (index === -1) return;

          prev[index].value = current;
          return [...prev];
        })
      }}
      {...params}

    />
  }[params?.typeSelect || "single"]
  /* console.log({ params }); */

  return typeSelect;
}

export const fieldTypes = (params, set) => {
  const types = {
    select: params => GetTypesSelects(params, set),
    date: params => {
      return <LabelGroup
        {...params}
        tipo="date"
        max={new Date().toISOString().substring(0, 10)}
        onChange={(current) => {
          set(prev => {
            const index = prev.findIndex((element) => element?.name === params?.name);
            if (index === -1) return
            prev[index].value = current?.target?.value;
            return [...prev];
          })
        }}
      />
    },
    text: params => <LabelGroup
      {...params}
      tipo="text"
      onChange={(current) => {
        set(prev => {
          const index = prev.findIndex((element) => element?.name === params?.name);
          if (index === -1) return
          prev[index].value = current?.target?.value;
          return [...prev];
        })
      }}
    />,
    number: params => {
      return <NumericFormat
        {...params?.inputParams}
        onValueChange={(newValue) => {
          const floatValue = newValue?.value;
          set(prev => {
            const index = prev.findIndex((element) => element?.name === params?.name);
            if (index === -1) return
            prev[index].value = floatValue;
            return [...prev];
          })
        }}
        customInput={LabelGroup}
      />
    }

  }
  return types[params?.type || "select"](params)
}
