import { toast } from "react-toastify";
import { MdNotifications } from "react-icons/md";

/**
 *  AlertErrorAlert - hook
 *
 * @param {string} title Mensaje que mostrar la alerta.
 * @param {string} state dependera si sera error, informacion o existoso
 * @param {string} positione dependera donde estara ubicada la alerta
 */

export const AlertErrorAlert = () => {
  const openAlert = (title, state, positione, customId) => {
    if (state === "success") {
      toast.success(title, {
        position: positione || "top-center",
        autoClose: 7000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } else if (state === "error") {
      toast.error(title, {
        position: positione || "top-center",
        autoClose: 7000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } else if (state === "warn") {
      toast.warn(title, {
        position: positione || "top-center",
        autoClose: 7000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } else {
      toast.info(title, {
        position: positione || "top-center",
        autoClose: 7000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({ theme, type }) => <MdNotifications className="text-primary" />,
        toastId: customId || "id"
      });
    }
  };

  return [openAlert];
};
