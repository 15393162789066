import { useModal } from "hooks/useModal";

const { createContext, useState } = require("react");

const ControlAnalysisContext = createContext();

export const ControlAnalysisProvider = ({ children }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterCurrent, setFilterCurrent] = useState("");
  const [riskLevelCount, setRiskLevelCount] = useState({
    level: 0,
    half: 0,
    high: 0
  });

  const data = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    riskLevelCount,
    setRiskLevelCount,
    filterCurrent,
    setFilterCurrent
  };

  return (
    <ControlAnalysisContext.Provider value={data}>
      {children}
    </ControlAnalysisContext.Provider>
  );
};

export default ControlAnalysisContext;
