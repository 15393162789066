import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Td, Text, Tr, useDisclosure } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { IconGeneric } from "styled/Icons.styled";
import { BiPencil } from "react-icons/bi";
import { Modalcustomizable } from "components/modal/ModalPass";
import { ContentModalUpdatePass } from "./ContentModal";
import environment from "constants/apiConst";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { __Post } from "request/Petitions/__Post";
import { useModal } from "hooks/useModal";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import TbodyMenu from "./Menu/TbodyMenu";

const Tbody = ({ data }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const [isOpenWarnig, onOpenWarning, onCloseWarning] = useModal();

  const [passUpdate, setPassUpdate] = useState({
    id: data?.pass_id,
    operation: data?.operation,
    vehicle_plate: data?.vehicle_plate,
    driver: data?.driver,
    number_packages: data?.number_packages,
    phone: data?.phone,
    identification: data?.identification,
    vehicle_plate_before: data?.vehicle_plate,
    driver_before: data?.driver,
    number_packages_before: data?.number_packages,
    phone_before: data?.phone,
    identification_before: data?.identification
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassUpdate((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const ReturnPassUpdate = async (data) => {
    const { driver, identification, number_packages, phone, vehicle_plate } =
      data;

    const Codicion =
      driver.length !== 0 &&
      identification.length !== 0 &&
      number_packages.length !== 0 &&
      phone.length !== 0 &&
      vehicle_plate.length !== 0;

    if (Codicion) {
      try {
        const resp = await __Post(environment.UPDATE_PASES, token, data);
        if (resp.data.status.code === 200) {
          openAlert(resp?.data?.status?.message, "success");
          onCloseWarning();
          onClose();
        } else {
          openAlert(resp?.data?.status?.message, "error");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      openAlert("Rellene todos los campos", "error");
    }
  };

  const UpdateData = () => {
    onOpen();
  };

  return (
    <>
      <ModalAdvertencia
        isOpen={isOpenWarnig}
        onClose={onCloseWarning}
        title={`Advertencia`}
        titleBody={`¿Seguro que desea actualizar el Formulario de ${data?.operation === "Despacho" ? "Salida" : "Entrada"
          } Nº ${data?.passForm_number}?`}
        generar={() => ReturnPassUpdate(passUpdate)}
      />

      <Modalcustomizable
        title={"Actualizar pesaje"}
        isOpen={isOpen}
        onClose={onClose}
        rightButtonText={"Confirmar"}
        size={"6xl"}
        onRightButtonClick={() => onOpenWarning()}
        content={
          <ContentModalUpdatePass
            data={data}
            handleInputChange={handleInputChange}
          />
        }
      />

      <Tr>
        <Td className="text-center">
          <NavLink
            to={`/pases?numberForm=${data.passForm_number}&id=${data?.pass_id}&status=${data?.status}&typeO=${data?.operation === "Despacho" ? "Dispatch" : ""}`}
            className="formNumber">
            {data?.passForm_number}
          </NavLink>
        </Td>
        {/*    <Td className="text-center">
        {data?.consecutive !== 0 ? (
          <span onClick={handleShowWeighing} className="formNumber">
            {data?.consecutive}
          </span>
        ) : (
          <span>{data?.consecutive}</span>
        )}
      </Td> */}
        <Td className="text-center">
          {data?.consecutive !== "0"
            ? (
              <NavLink to={`/ShowWeighing?weighing=${data?.weighing_id}&Operation=${data?.operation}`} className="formNumber">
                {data?.consecutive}
              </NavLink>
            )
            : (
              <span>N/A</span>
            )}
        </Td>
        <Td className="text-center">{data?.name_qualified}</Td>
        {/* <Td className="text-center">{}</Td>
      <Td className="text-center">{}</Td> */}
        <Td className="text-center">{data?.vehicle_plate}</Td>
        <Td className="text-center">{data?.driver}</Td>
        <Td className="text-center">{data?.identification}</Td>
        <Td className="text-center">
          {data?.container_1 !== null && data?.container_2 !== null
            ? 2
            : data?.container_1 !== null
              ? 1
              : data?.container_2 !== null
                ? 1
                : data?.container_1 === null && data?.container_2 === null
                  ? 0
                  : 0}
        </Td>
        <Td className="text-center">
          {data?.empty_pass === 1 ? "SI" : data?.empty_pass === 0 ? "NO" : "NO"}
        </Td>
        <Td className="text-center">
          {data?.freely_available === 1
            ? "SI"
            : data?.freely_available === 0
              ? "NO"
              : "NO"}
        </Td>
        <Td className="text-center">
          {data?.free_pass === 1 ? "SI" : data?.free_pass === 0 ? "NO" : "NO"}
        </Td>
        <Td className="text-center">
          {data?.handling_process === 1
            ? "SI"
            : data?.handling_process === 0
              ? "NO"
              : "NO"}
        </Td>
        <Td className="text-center">
          <TbodyMenu onOpen={onOpen} data={data} />
        </Td>
      </Tr>
    </>
  );
};

Tbody.propTypes = {
  data: PropTypes.object
};

export default Tbody;
