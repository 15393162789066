/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { IconGeneric } from "styled/Icons.styled";
import { RiUploadCloudFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { InputSelectZindex } from "components/InputSelect/InputSelect";
import { TypeAttachmentOther } from "./TypeAttachment";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { useForm } from "hooks/useForm";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation } from "react-router-dom";
import { addIfNotExists, isFunction, isString } from "utils/type-check-utils";
import { FaEdit, FaRegEye } from "react-icons/fa";
import { GroupInputUpload } from "pages/Entry_and_exit_forms/GroupInputUpload";
import GlobalContext from "context/GlobalContext";

/**
 * AttchmentTypiFied - Componente de input que permite al usuarios seleccionar el tipo de archivo que cargara.
 * @param {string} titulo Titulo del componente.
 * @param {Array} attachment Lista de archivos cargados.
 * @param {Array} attachmentDescription Lista de descripción de los archivos cargados.
 * @param {Array} attachmentType Lista de tipo de los archivos cargados.
 * @param {Function} setAttachment Funcion para realizar el guardado de los archivos en el estado.
 * @param {Function} setAttachmentDescription Funcion para realizar el guardado de la descripción de los archivos en el estado.
 * @param {Function} setAttachmentType Funcion para realizar el guardado del tipo de los archivos en el estado.
 * @param {Function} deleteAttachment Funcion para eliminar archivos tipificados del servidor.
 * @param {string} ruta Ruta a donde haremos la consulta.
 */

export const AttchmentTypiFied = ({
  ruta,
  titulo,
  attachment,
  setAttachment,
  attachmentType,
  deleteAttachment,
  setAttachmentType,
  attachmentDescription,
  attachmentNumber = null,
  setAttachmentNumber = null,
  setAttachmentDescription,
  viewBtn = false,
  viewBtnDelete = true,
  update,
  otherAttachmentId = null,
  setOtherAttachmentNumberId = null,
  attachmentComment = null,
  setAttachmentComment = null,
  documentPreview = false
}) => {
  const [openAlert] = AlertErrorAlert();
  const location = useLocation(); // navigate
  const [uploadHot, setuploadHot] = useState(false);
  const { pathname } = location;
  const [isOpenTypiFied, onOpenTypiFied, onCloseTypiFied] = useModal();
  const [extension, setExtension] = useState([]);
  const { IsTheFormLocked } = useContext(GlobalContext);
  const attachedUpload = [];
  const attachedDescripcion = [];
  const attachedNumber = [];
  const attachedComment = [];
  const attachedExtension = [];
  const otherAttachedId = [];

  useEffect(() => {
    if (extension.value) {
      document.getElementById("OtherAttachment").click();
    }
  }, [extension.value]);

  const [values, handleInputChange, reset, setValues] = useForm({
    description: "",
    other_attachment_comment: "",
    attachment_number: ""
  });
  const { description, other_attachment_comment, attachment_number } = values;

  const handleFilesInput = (e) => {
    e.preventDefault();
    const Files = e.target.files;
    const file = Files[0];
    const copyAttachment = Files[0];

    attachedUpload.push(file);
    attachedDescripcion.push(description);
    attachedNumber.push(attachment_number);
    attachedComment.push(other_attachment_comment);
    attachedExtension.push(extension.label);
  
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || '';

    if (file) {
      if (extension.value.includes(fileExtension)) {
        if (attachment) {
          setAttachmentType(attachedExtension.concat(attachmentType));
          setAttachment(attachedUpload.concat(attachment));
          setAttachmentDescription(
            attachedDescripcion.concat(attachmentDescription)
          );

          if (setAttachmentNumber) {
            setAttachmentNumber(attachedNumber.concat(attachmentNumber));
          }

          if (setAttachmentComment) {
            setAttachmentComment(attachedComment.concat(attachmentComment));
          }

          setuploadHot(true);
        } else {
          setuploadHot(true);
          setAttachment(attachedUpload);
          setAttachmentType(attachedExtension);
          setAttachmentDescription(attachedDescripcion);
          setAttachmentNumber(attachedNumber);
          setAttachmentNumber(attachedComment);
        }
      } else {
        openAlert(
          `El tipo de archivo ${file?.type} es diferente al permitido.(${extension?.label})`,
          "error"
        );
        setAttachment([]);
        setAttachmentType([]);
      }
    }
    // handleClose();
    reset();
    onCloseTypiFied();
    setExtension([]);
  };

  useEffect(() => {
    if (update && uploadHot && attachment) {
      update();
      setuploadHot(false);
    }
  }, [attachment]);

  const deleteAttachmentLocal = (index, name, id) => {
    // let respuesta = attachmentDescription[index];
    attachmentDescription.splice(index, 1);
    const restAttacment = attachment.filter((element) => element.id !== id);
    const fileInput = document.getElementById("OtherAttachment");
    if (fileInput) fileInput.value = "";

    if (id) {
      const restAttacment = attachment.filter((element) => element.id !== id);
      // setAttachment(restAttacment);
      setAttachment(restAttacment);
      deleteAttachment(ruta, id, "", setAttachment, restAttacment);
    } else {
      setAttachment(restAttacment);
      setAttachmentDescription(attachmentDescription);
    }
  };

  const [isOpenUpload, onOpenUpload, onCloseUpload] = useModal();
  const firstRender = useRef(true);

  const [valuesEdit, handleInputEdit, resetEdit, setValuesEdit] = useForm({
    index: null,
    attachment_description: "",
    attachment_number: ""
  });

  const editAttachmentLocal = (index) => {
    const documentName =
      attachment[index]?.name || attachment[index]?.other_attachment_name;

    setValuesEdit({
      index,
      id: attachment[index]?.id,
      other_attachment_comment: attachment[index]?.other_attachment_comment,
      name: documentName,
      attachment_description:
        attachmentDescription[index] ||
        attachment[index]?.other_attachment_description ||
        "",
      attachment_number:
        attachmentNumber[index] || attachment[index]?.attachment_number || "",
      url: attachment[index]?.attachment_url || "",
      extension: attachment[index]?.other_attachment_type || ""
    });

    onOpenUpload();
  };

  const updateArray = (index, newValue, arraySetter) => {
    arraySetter((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (valuesEdit?.index != null) {
      if (isFunction(setOtherAttachmentNumberId) && valuesEdit?.id) {
        addIfNotExists(valuesEdit?.id, setOtherAttachmentNumberId);
      }

      if (setAttachmentNumber && valuesEdit?.attachment_number) {
        updateArray(
          valuesEdit?.index,
          valuesEdit?.attachment_number,
          setAttachmentNumber
        );
      }
    }
  }, [valuesEdit]);

  const isValidUpload =
    (isFunction(setAttachmentNumber) && attachment_number?.length >= 1) ||
    !setAttachmentNumber;

  const iconPreview = documentPreview ? FaRegEye : FaEdit;
  const textTooltipPreview = documentPreview ? "Ver" : "Editar";

  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={textTooltipPreview}
        size={"lg"}
      >
        <GroupInputUpload
          customHandleChange={{
            values: valuesEdit,
            handleInputChange: handleInputEdit,
            reset: resetEdit,
            setValues: setValuesEdit
          }}
          onClose={onCloseUpload}
          mode="edit"
          preview={documentPreview}
        />
      </ModalGeneric>

      <ModalGeneric
        scrollBehavior="outside"
        isOpen={isOpenTypiFied}
        onOpen={onOpenTypiFied}
        onClose={onCloseTypiFied}
        title={
          description?.length < 3
            ? "Escriba una descripción"
            : description?.length >= 3
              ? "Seleccion tipo de archivo"
              : "Otros documentos"
        }
        size={"lg"}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          marginBottom={"2rem"}
        >
          <LabelGroup
            name={"description"}
            onChange={handleInputChange}
            value={description}
            tag={"Descripción "}
            size={100}
            desicion={false}
            maxiLength={"50"}
          />

          <LabelGroup
            name={"other_attachment_comment"}
            onChange={handleInputChange}
            value={other_attachment_comment}
            tag={"Comentario"}
            size={100}
            desicion={false}
            maxiLength={"50"}
          />

          {isFunction(setAttachmentNumber) && (
            <>
              {" "}
              <LabelGroup
                name={"attachment_number"}
                onChange={handleInputChange}
                value={attachment_number}
                tag={"Número del anexo "}
                size={100}
                desicion={false}
                required={true}
              />
            </>
          )}

          {isValidUpload && (
            <InputSelectZindex
              tag={"Extensión *"}
              data={TypeAttachmentOther}
              set={setExtension}
              value={extension}
              size={100}
              className=""
            />
          )}
        </Box>
      </ModalGeneric>

      <div className="row">
        <div className="col-6 mt-2">
          <h2 className="mb-3">{titulo}</h2>
        </div>
        <div className="col-6 mt-2 ">
          {(pathname === "/FormEntry" ||
            pathname === "/FormExit" ||
            viewBtn === true) &&
            !IsTheFormLocked && (
            <Tooltip placement="top" hasArrow label="Agregar otro adjunto">
              <span>
                <ButtonHeaderModule
                  onClick={() => onOpenTypiFied()}
                  bgColor="#03C39A"
                >
                  <IconGeneric color="#fff" as={RiUploadCloudFill} />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
          )}

          <input
            type="file"
            value={attachment?.name || ""}
            id="OtherAttachment"
            hidden
            className="invisible"
            accept={extension?.value}
            onChange={(e) => handleFilesInput(e)}
          />
        </div>

        <div className=" row m-4">
          <div className="col">
            {attachmentDescription?.length !== 0 && (
              <h3 className="mb-2">Comentario</h3>
            )}
            <ol>

              {attachmentDescription
                ? attachmentDescription?.map((item, index) => (
                  <li key={index} className="p-1">
                    {item}
                  </li>
                ))
                : ""}
            </ol>
          </div>

          <div className="col">
            {attachment?.length !== 0 && <h3 className="mb-2">Nombre</h3>}
            <ol>
              {attachment
                ? attachment?.map((item, index) =>
                  item?.attachment_url
                    ? (
                      <>
                        <li key={index} className="p-1">
                          <span
                            onClick={() => window.open(item?.attachment_url)}
                            id="downloadFile"
                          >
                            {item.other_attachment_name}
                          </span>
                          {viewBtnDelete && !IsTheFormLocked && (
                            <>
                              <Tooltip
                                placement="top"
                                hasArrow
                                label="Eliminar"
                              >
                                <span>
                                  <IconGeneric
                                    onClick={() =>
                                      deleteAttachmentLocal(
                                        attachment.indexOf(item),
                                        item?.other_attachment_name,
                                        item?.id
                                      )
                                    }
                                    className=" ms-2 text-danger"
                                    as={MdDelete}
                                  />
                                </span>
                              </Tooltip>
                            </>
                          )}

                          {((viewBtnDelete &&
                            isFunction(setAttachmentNumber)) ||
                            documentPreview) && (
                            <Tooltip
                              placement="top"
                              hasArrow
                              label={textTooltipPreview}
                            >
                              <span>
                                <IconGeneric
                                  onClick={() =>
                                    editAttachmentLocal(
                                      attachment.indexOf(item)
                                    )
                                  }
                                  className="ms-2"
                                  color="#1F7AC3"
                                  as={iconPreview}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </li>
                      </>
                    )
                    : (
                      <li className="p-1">
                        {item?.name}
                        {((viewBtnDelete && !IsTheFormLocked) ||
                          documentPreview) && (
                          <>
                            <IconGeneric
                              onClick={() =>
                                deleteAttachmentLocal(
                                  attachment.indexOf(item),
                                  item?.other_attachment_name
                                )
                              }
                              className="ms-2 text-danger"
                              as={MdDelete}
                            />
                            {isFunction(setAttachmentNumber) && (
                              <IconGeneric
                                onClick={() =>
                                  editAttachmentLocal(attachment.indexOf(item))
                                }
                                className="ms-2"
                                color="#1F7AC3"
                                as={iconPreview}
                              />
                            )}
                          </>
                        )}
                      </li>
                    )
                )
                : ""}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};
