import React from "react";
import { Logo } from "styled/Logo.styled";
import "./StyleBase.css";

export const BaseOfExport = ({ children }) => {
  return (
    <>
      <div className="d-flex mt-2">
        <div id="banner" />
        <Logo width={"180px"} className="mx-2" />
      </div>
      <div className="p-1">{children}</div>
      {/* <div id="espaciofail"></div> */}
      {/* <footer id="footer" className="mt-5">
        <LogoWhite className="float-end m-2" width={"150px"} />
      </footer> */}
    </>
  );
};
