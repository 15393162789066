import { Box } from '@chakra-ui/react'
import React from 'react'
import { CardMethod } from './CardMethod'
import { ChildrenMethod } from './ChildrenMethod'
import { TbTicket, TbTicketOff } from 'react-icons/tb'
import { FaWeight } from 'react-icons/fa'
import styled from 'styled-components'

export const Title = styled.h3`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #41495a;
  margin-top: 15px;
  margin-left: 8px;
`;

const Tickets = ({ openTicket, openTicketDay, closeTicket, Reweighing }) => {
  return (
    <>
      <Box style={{ margin: '10px', height: '340px', width: '340px' }}>
        <CardMethod marginTop={1} display={'flex'} flexDir={'column'} gap={1}>
          <Title>Tickets</Title> <br />
          {(Number.isInteger(openTicket) && openTicket !== null)
            ? <ChildrenMethod
              title='Tickets abiertos'
              IconColor={'#006EB8'}
              icon={TbTicket}
              quantity={openTicket}
            />
            : ''}
          {(Number.isInteger(openTicketDay) && openTicketDay !== null)
            ? <ChildrenMethod
              title='Tickets abiertos hoy'
              IconColor={'#006EB8'}
              icon={TbTicket}
              quantity={openTicketDay}
            />
            : ''}
          {(Number.isInteger(closeTicket) && closeTicket !== null)
            ? <ChildrenMethod
              title='Tickets cerrados'
              IconColor={'#006EB8'}
              icon={TbTicketOff}
              quantity={closeTicket}
            />
            : ''}
          {(Number.isInteger(Reweighing) && Reweighing !== null)
            ? <ChildrenMethod
              title='Repesos'
              IconColor={'#006EB8'}
              icon={FaWeight}
              quantity={Reweighing}
            />
            : ''}
        </CardMethod>
      </Box>
    </>
  )
}

export default Tickets
