import React, { useState, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import Vapor from "laravel-vapor";
import MultiFileUpload, { defaultAllowedTypes } from "./Adjunto/MultiFileUpload";
import { useGlobalContext } from "context/GlobalContext";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";

const EnhancedFileUploadManager = ({
  vaporBaseUrl,
  getAuthToken,
  title = "Subir documentos del proyecto",
  formID,
  postPropertyName = "attachment",
  endpointAttachments,
  handleResponseUpdateForm = () => {},
  allowedFileTypes = defaultAllowedTypes,
}) => {
  const toast = useToast();
  const { requestData } = useGlobalContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [fileData, setFileData] = useState([]);

  const { mutate, isLoading } = useMutation({
    mutationFn: requestData,
    onSuccess: (res) => {
      handleResponseUpdateForm(res);
      toast({
        title: "Subida exitosa",
        description: `Archivos subidos correctamente.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (error) => {
      console.error("Error durante la subida:", error);
      toast({
        title: "Error en la subida",
        description: "Hubo un problema al subir los archivos. Por favor, intenta de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const uploadToVapor = useCallback(async (file, onProgress) => {
    const token = await getAuthToken();
    return Vapor.store(file, {
      baseURL: vaporBaseUrl,
      headers: { Authorization: `Bearer ${token}` },
      progress: onProgress,
    });
  }, [getAuthToken, vaporBaseUrl]);

  const handleFileSelect = useCallback((uploadedFiles) => {
    if (uploadedFiles.length > 0) {
      setFiles(uploadedFiles);
      setFileData(uploadedFiles.map(() => ({
        other_attachment_description: "",
        other_attachment_date: "",
        other_attachment_comment: "",
        other_attachment_number: "",
      })));
      setCurrentFileIndex(0);
      setIsModalOpen(true);
    }
  }, []);

  const handleInputChange = (name, value) => {
    setFileData(prevData => {
      const newData = [...prevData];
      newData[currentFileIndex] = { ...newData[currentFileIndex], [name]: value };
      return newData;
    });
  };

  const handleNextFile = () => {
    if (currentFileIndex < files.length - 1) {
      setCurrentFileIndex(prev => prev + 1);
    } else {
      handleUpload();
    }
  };

  const handleUpload = () => {
    const formattedFiles = files.map((file, index) => ({
      file: {
        uuid: file.vaporId,
        bucket: file.bucket,
        key: file.key,
        url: file.url,
        headers: file.headers,
        extension: file.extension,
      },
      name: file.name,
      other_attachment_type:  file.extension,
      ...fileData[index],
    }));

    const formData = new FormData();
    formData.append("id", formID);
    formData.append(postPropertyName, JSON.stringify(formattedFiles));
    
    const requestData = {
      data: formData,
      Endpoint: endpointAttachments?.create,
      PropertyBody: "SENDFORMDATACONTENTPOST",
    };

    mutate(requestData);
    setIsModalOpen(false);
    setFiles([]);
    setFileData([]);
    setCurrentFileIndex(0);
  };

  return (
    <>
      <MultiFileUpload
        onFileSelect={handleFileSelect}
        uploadToVapor={uploadToVapor}
        isUploading={isLoading}
        title={title}
        allowedFileTypes={allowedFileTypes}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Información del archivo: {files[currentFileIndex]?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LabelGroup
              name="other_attachment_comment"
              value={fileData[currentFileIndex]?.other_attachment_comment}
              onChange={(e) =>
                handleInputChange("other_attachment_comment", e.target.value)
              }
              tag="Comentario"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="other_attachment_description"
              value={fileData[currentFileIndex]?.other_attachment_description}
              onChange={(e) =>
                handleInputChange("other_attachment_description", e.target.value)
              }
              tag="Descripción"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="other_attachment_number"
              value={fileData[currentFileIndex]?.other_attachment_number}
              onChange={(e) =>
                handleInputChange("other_attachment_number", e.target.value)
              }
              tag="Número del anexo"
              size={100}
              maxiLength="50"
            />
            <LabelGroup
              name="other_attachment_date"
              value={fileData[currentFileIndex]?.other_attachment_date}
              onChange={(e) =>
                handleInputChange("other_attachment_date", e.target.value)
              }
              type="date"
              tag="Fecha del anexo"
              size={100}
              required={true}
              max={new Date().toISOString().split("T")[0]}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNextFile} isLoading={isLoading}>
              {currentFileIndex < files.length - 1 ? "Siguiente archivo" : "Subir archivos"}
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnhancedFileUploadManager;