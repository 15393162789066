/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, Text } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useDebounceError } from "hooks/useDebounceError";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";
import { Regex } from "utils/Regex";

const FormTypeOfIdentifications = () => {
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    code: "",
    name: ""
  });

  const { code, name } = values;
  useEffect(() => {
    if (dataToEdit) {
      setValues({ ...dataToEdit });
    } else {
      setValues(values);
    }
  }, [dataToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dataToEdit) {
      if ((!code && code === '') || !dataToEdit?.code_identification_type) return openAlert('Ingrese el código de la identificación', 'error');

      if ((!name && name === '') || !dataToEdit?.name_identification_type) return openAlert('Ingrese el nombre de la identificación', 'error');

      editData({
        id: dataToEdit?.id,
        code_identification_type: code ?? dataToEdit?.code_identification_type,
        name_identification_type: name ?? dataToEdit?.name_identification_type,
        status_identification_type: 1
      });
      return reset();
    }

    if (!code) return openAlert('Ingrese el código de la identificación', 'error');

    if (!name) return openAlert('Ingrese el nombre de la identificación', 'error');

    createData({
      code_identification_type: code,
      name_identification_type: name,
      status_identification_type: 1
    });
    return reset();
  };

  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== 'all' ? `Este campo solo acepta ${type}` : `El carácter ${value[value.length - 1]} no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit ? "Editar Identificacion " : "Crear Identificación"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem"
          }}
        >
          <LabelGroup
            name={"code"}
            tag={"Codigo"}
            size={45}
            value={code ?? dataToEdit?.code_identification_type}
            onChange={(e) => handleRegex(e, 'Números', Regex?.numeros)}
            desicion={false}
            // required
            maxLength={MaxLength.config.typesIdentifications.code_identification_type}
          />
          <LabelGroup
            type={"text"}
            name={"name"}
            tag={"Nombre"}
            size={45}
            value={name ?? dataToEdit?.name_identification_type}
            onChange={(e) => handleRegex(e, 'Letras', Regex?.letras)}
            desicion={false}
            // required
            maxLength={MaxLength.config.typesIdentifications.name_identification_type}
          />
        </div>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled type="submit">Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormTypeOfIdentifications;
