import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Radio,
  Td,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";
import styled from "styled-components/macro";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useSelector } from "react-redux";
import { __ShowSubheading } from "request/configuration/__Subheading";
import { __GetTrackingsFmm } from "request/Monitoring/__GetTrackingsFmm";
import TableOrder from "components/Tables/TableOrder";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { TheadTrackingModuls } from "pages/Entry_and_exit_forms/ArrayHeaders/ArrayHeader";
import TBodyTrackingModuls from "pages/Seguimiento/Table/TBodyTrackingModuls";
import Pagination from "components/Pagination/Pagination";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodySubheading = ({ data }) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const { setUpdate, update, setLoading } = useContext(
    ConfigurationCRUDContext
  );
  const [openAlert] = AlertErrorAlert();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [loadingIcons, setLoadingIcons] = useState(false);

  const desactivarAndActivar = async (result) => {
    setLoading(true);
    try {
      const resultado =
        result === 1
          ? await __DESACTIVAR(token, environment.SUBHEADING_DESACTIVE, data.id)
          : await __ACTIVAR(token, environment.SUBHEADING_ACTIVE, data.id);
      if (resultado.data.status.code !== 200) {
        openAlert(resultado.data.status.message, "error");
      } else {
        openAlert(resultado.data.status.message, "success");
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleState = async () => {
    if (loadingIcons) return;
    setLoadingIcons(true);
    try {
      const res = await __ShowSubheading(token, data.id);
      const result = res.data.status.data.status_subheading;
      desactivarAndActivar(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIcons(false);
    }
  };

  const showTrackingItem = useCallback(
    async (trackings) => {
      setLoad(true);
      try {
        const formData = new FormData();
        trackings?.map((trackings, index) => {
          formData.append(`tracking_id[${index}]`, trackings?.tracking_id);
        });
        const res = await __GetTrackingsFmm(token, formData);
        setCurrentPage(res.data.status.data.data);
        setMaxPage(res.data.status.data.last_page);
        console.log(res, "RES SUBHEADING");
      } catch (error) {
        console.log(error);
      } finally {
        onOpen();
        setLoad(false);
      }
    },
    [page]
  );

  return (
    <>
      <ModalGeneric
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Seguimiento"}
      >
        <TableOrder
          thead={TheadTrackingModuls}
          data={currentPage}
          setData={setCurrentPage}
          loading={load}
          tbodyData={TBodyTrackingModuls}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={load}
        />
        <div className="d-flex w-100 justify-content-end">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </ModalGeneric>
      <Tr>
        <Td className="text-center">{data?.code_subheading}</Td>
        <Td className="text-center">{data?.description}</Td>

        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.tracking?.some((tracking) => tracking.status === 1)
              ? "En seguimiento"
              : data?.tracking?.every((tracking) => tracking.status === 2)
                ? "Estuvo en seguimiento"
                : ""
          }
        >
          <Td>
            {data?.tracking?.length && (
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Radio
                  defaultChecked
                  onClick={() => showTrackingItem(data?.tracking)}
                  colorScheme={
                    data?.tracking?.some((tracking) => tracking?.status === 1)
                      ? "red"
                      : "green"
                  }
                />
              </span>
            )}
          </Td>
        </Tooltip>

        <Tooltip
          placement="top"
          hasArrow
          label={data?.status_subheading === 1 ? "Desactivar" : "Activar"}
        >
          <TdState
            onClick={handleState}
            className="text-center"
            isNumeric
            text={data?.status_subheading}
          >
            <ConfigIconStatus
              loading={loadingIcons}
              status={data?.status_subheading}
            />
          </TdState>
        </Tooltip>

        <Td className="text-center" isNumeric>
          <MenuRUD data={data} />
        </Td>
      </Tr>
    </>
  );
};

export default TbodySubheading;
