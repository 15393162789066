export const arrayLeft = [
  {
    value: "name_qualified",
    label: "Empresa",
    name: "name_qualified"
  },
  {
    value: "name_person",
    label: "Usuario",
    name: "name_person"
  },
  {
    value: "date_security",
    label: "Fecha Seg.",
    name: "date_security"
  },
  {
    value: "passForm_number",
    label: "Código de pase",
    name: "passForm_number"
  },
  {
    value: "time_security",
    label: "Hora Seg.",
    name: "time_security"
  },
  {
    value: "observations",
    label: "Observación",
    name: "observations"
  }
];

export const arrayRightExit = [
  {
    value: "security_outputs.container_1",
    label: "Contenedor 1",
    name: "security_outputs.container_1"
  },
  {
    value: "security_outputs.capacity_container_1",
    label: "Cap. Contenedor 1",
    name: "security_outputs.capacity_container_1"
  },
  {
    value: "security_outputs.container_2",
    label: "Contenedor 2",
    name: "security_outputs.container_2"
  },
  {
    value: "security_outputs.capacity_container_1",
    label: "Cap. Contenedor 2",
    name: "security_outputs.capacity_container_1"
  },
  {
    value: "description_security",
    label: "Descripción",
    name: "description_security"
  },
  {
    value: "vehicle_plate",
    label: "Placa de vehículo",
    name: "vehicle_plate"
  }
];

export const arrayRightEntry = [
  {
    value: "security_entries.container_1",
    label: "Contenedor 1",
    name: "security_entries.container_1"
  },
  {
    value: "security_entries.capacity_container_1",
    label: "Cap. Contenedor 1",
    name: "security_entries.capacity_container_1"
  },
  {
    value: "security_entries.container_2",
    label: "Contenedor 2",
    name: "security_entries.container_2"
  },
  {
    value: "security_entries.capacity_container_2",
    label: "Cap. Contenedor 2",
    name: "security_entries.capacity_container_2"
  },
  {
    value: "description_security",
    label: "Descripción",
    name: "description_security"
  },
  {
    value: "vehicle_plate",
    label: "Placa de vehículo",
    name: "vehicle_plate"
  }
];
