export const ComparativeArray = (array1 = [], array2 = [], propety = null) => {
  if (array1.length !== array2.length) return false

  if (propety) {
    return array1.every(
      (element, index) => element === array2[index]?.[propety]
    )
  }

  return array1.every((element, index) => element === array2[index])
}
