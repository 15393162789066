/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFormStyled,
  LabeFormlStyled
} from "styled/Input.styled";
import { useForm } from "hooks/useForm";
import ProductCRUDContext from "context/ProductCRUDContext";
import { SwitchesGroup } from "../LabelGroup";
import { __Plantillas } from "request/productoInterno/__Plantillas";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import GlobalContext from "context/GlobalContext";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import environment from "constants/apiConst";
import { isObject, isObjectNotEmpty } from "utils/type-check-utils";

const EditProductForm = () => {
  const { dataToEdit, editData } = useContext(ProductCRUDContext);
  const [status, setStatus] = useState(1);
  const navigate = useNavigate();
  const { requestData } = useContext(GlobalContext);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    code_productive_process: "",
    description_productive_process: "",
    status_productive_process: ""
  });

  useEffect(() => {
    if (isObjectNotEmpty(values)) {
      setValues({
        code_productive_process: dataToEdit?.code_productive_process || values?.code_productive_process,
        description_productive_process:
          dataToEdit?.description_productive_process || values?.description_productive_process
      });
    }

    setStatus(dataToEdit?.status_productive_process || values?.status_productive_process);
  }, []);

  const handleSubmit = (e) => {
    e.prevent_default();
  };

  const handleResponse = (res) => {
    const message = res?.data?.status?.message;
    if (res?.data?.status?.code === 400) {
      if (Array.isArray(message)) {
        message.map((alert) => {
          return openAlert(alert, "error");
        });
      }
      return openAlert(message, "error");
    }

    if (res?.data?.status?.code === 200) {
      navigate(`/product-int?movement=Proceso productivo`);
      return openAlert(res?.data?.status?.message, "success");
    }
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: requestData,
    onSuccess: (data) => handleResponse(data)
  });

  const handleEditar = () => {
    const data = {
      ...values,
      id: dataToEdit?.id,
      status_productive_process: status
    };
    mutate({
      data,
      Endpoint: environment.UPDATE_PROCESO,
      PropertyBody: "sendJSONContentPOST"
    });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-5">
      <div className="row">
        <div className="col-md-6">
          <FormInputGroup>
            <LabeFormlStyled>Codigo</LabeFormlStyled>
            <InputFormStyled
              name="code_productive_process"
              value={values.code_productive_process}
              onChange={handleInputChange}
            />
          </FormInputGroup>
        </div>
        <div className="col-md-6">
          <FormInputGroup>
            <LabeFormlStyled>Descripción</LabeFormlStyled>
            <InputFormStyled
              name="description_productive_process"
              value={values.description_productive_process}
              onChange={handleInputChange}
            />
            <SwitchesGroup
              status={status}
              setStatus={setStatus}
              tag={"¿Activo?"}
            />
          </FormInputGroup>
        </div>
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <Button
          onClick={handleEditar}
          size="md"
          height="40px"
          width="170px"
          colorScheme="blue"
          isLoading={isLoading}
        >
          Guardar
        </Button>
      </div>
    </form>
  );
};

export default EditProductForm;
