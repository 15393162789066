import React, { useEffect, useState } from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import {
  LabelGroup
} from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";

export const FormSearchUnits = ({ setSearchAdvanced, onClose }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["code", "unit"], nestedProp: "searchAdvance" })
  const [values, handleInputChange, reset, setValues] = useForm({
    code: state?.code || "",
    unit: state?.unit || "",
  });

  const {
    code,
    unit
  } = values;

  const handleSubmit = () => {
    const datosSend = {
      ...values
    };

    setParamsSearch({ params: datosSend });
    setSearchAdvanced(datosSend);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code"}
          value={code}
          onChange={handleInputChange}
          tag={"Código"}
          size={45}
          tipo={"number"}
        />
        <LabelGroup
          name={"unit"}
          value={unit}
          onChange={handleInputChange}
          tag={"Unidad"}
          size={45}
          tipo={"text"}
        />
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} type="submit" onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  )
}
