/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { ModalGeneric } from "components/modal/ModalGeneric";
import Pagination from "components/Pagination/Pagination";
import TableOrderHover from "components/Tables/TableOrderHover";
import InventoryControlContext from "context/InventoryControlContext";
import { useForm } from "hooks/useForm";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __GetReferenciaOnly } from "request/Inventory/__InventoryCRUD";
import { ButtonStyled } from "styled/Buttons.styled";
import { theadSearchRef } from "../Array/ArrayInventoryControl";
import { TBodySearchRef } from "../TbodyTable/TBodySearchRef";
import { FormSearchReference } from "./FormSearchReference";
import { RiSearch2Line } from "react-icons/ri";
import { IconGeneric } from "styled/Icons.styled";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormAuxBalance = ({ onClose, handleSearchSubmit }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["reference", "date_final", "date_initial"], nestedProp: "searchAdvance" });
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();
  const {
    isOpenSearchItem,
    onOpenSearchItem,
    onCloseSearchItem,
    showTableData,
    setShowTableData,
    dataReference
  } = useContext(InventoryControlContext);
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [values, handleInputChange, reset, setValues] = useForm({
    reference: state?.reference,
    date_final: state?.date_final,
    date_initial: state?.date_initial,
  });

  const { reference, date_final, date_initial } = values;

  const handleSubmit = () => {
    if (reference === "") {
      openAlert("El campo de la referencia es requerido", "error");
    } else {
      const data = {
        ...values
      };
      setParamsSearch({ params: {
        ...handleSanitizedObj(data),
      }})
      handleSearchSubmit(data);
    }
  };

  const handleResData = async (values) => {
    // console.log(values);
    try {
      const res = await __GetReferenciaOnly(token, page, values);
      const resData = res?.data?.status;
      if (resData.code === 200) {
        setData(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        setShowTableData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataReference) {
      setValues({
        reference: dataReference.inventory_reference_description
      });
    }
  }, [dataReference, setValues]);

  return (
    <>
      <ModalGeneric
        isOpen={isOpenSearchItem}
        onOpen={onOpenSearchItem}
        onClose={onCloseSearchItem}
        title="Buscar referencia"
        size="4xl"
      >
        {showTableData
          ? (
            <div>
              <ButtonStyled onClick={() => setShowTableData(false)}>
              Buscar nuevamente
              </ButtonStyled>
              <TableOrderHover
                thead={theadSearchRef}
                data={data}
                setData={setData}
                loading={loading}
                onCloseModal={onCloseSearchItem}
                tbodyData={TBodySearchRef}
              />
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                loading={loading}
              />
            </div>
          )
          : (
            <FormSearchReference
              onClose={onCloseSearchItem}
              handleSearch={handleResData}
              values={values}
              handleInputChange={handleInputChange}
            />
          )}
      </ModalGeneric>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"reference"}
          value={reference || ""}
          onChange={handleInputChange}
          tag={"Referencia *"}
          size={35}
          tipo={"text"}
        />
        <div style={{ marginTop: 20 }} onClick={onOpenSearchItem}>
          <IconGeneric color="#313131" as={RiSearch2Line} />
        </div>

        <div
          style={{
            width: "45%"
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          value={date_initial || ""}
          onChange={handleInputChange}
          tag={"Fecha inicial"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final || ""}
          onChange={handleInputChange}
          tag={"Fecha final"}
          size={45}
          tipo={"date"}
        />
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
