/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled
} from "styled/Input.styled";
import { ButtonStyled } from "styled/Buttons.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";

const FormSubShow = () => {
  const initialForm = {
    code_subheading: "",
    description: "",
    status_subheading: ""
  };
  const [form, setForm] = useState(initialForm);
  const { onCloseModal, dataToShow } = useContext(ConfigurationCRUDContext);

  // console.log(dataToShow);

  useEffect(() => {
    if (dataToShow) {
      // console.log(dataToShow);
      setForm(dataToShow);
    } else {
      setForm(initialForm);
      // console.log(dataToShow);
    }
  }, [dataToShow]);
  return (
    <>
      <Text
        fontSize="xl"
        mb={3}
        style={{
          fontWeight: 600,
          borderBottom: "1px solid #bababa80",
          paddingBottom: "1rem"
        }}
      >
        {dataToShow ? form.code_subheading : ""}
      </Text>
      <form action="">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Codigo</LabeFormlStyled>
                <InputFake>{form.code_subheading}</InputFake>
              </FormInputGroup>
            </div>
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Nombre</LabeFormlStyled>
                <InputFake>{form.description}</InputFake>
              </FormInputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormInputGroup>
                <LabeFormlStyled>Estado</LabeFormlStyled>
                <InputFake>
                  {form.status_subheading === 1 ? "Activo" : "Inactivo"}
                </InputFake>
              </FormInputGroup>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <ButtonStyled type="button" wd="140px" onClick={onCloseModal}>
            Aceptar
          </ButtonStyled>
        </div>
      </form>
    </>
  );
};

export default FormSubShow;
