import { Box, Input, InputGroup, InputLeftAddon, Flex, Spacer } from "@chakra-ui/react";

export const BoxCalc = ({ ...props }) => {
  const Data = props.Data;

  const Calc_Data = (data, findElemnt) => {
    let Total = 0;

    data.map((x) => {
      if (findElemnt === "U" && x.insumoTotal !== undefined) {
        Total += x.insumoTotal;
      }

      if (findElemnt === "KG" && x.pesoNeto !== undefined) {
        Total += x.pesoNeto;
      }

      return 0;
    });
    return Total;
  };

  // Formatear Cantidad
  const formateador = new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 2
  });

  return (
    <>
      {/* <table className="table table-bordered ">
        <tbody>
          <tr>
            {parseInt(Calc_Data(Data, "KG")) === 0 ? (
              <></>
            ) : (
              <td width={"30%"}>
                KG: {formateador.format(Calc_Data(Data, "KG"))}
              </td>
            )}

            {parseInt(Calc_Data(Data, "U")) === 0 ? (
              <></>
            ) : (
              <td width={"30%"}>
                U: {Calc_Data(Data, "U")}
                <br />
              </td>
            )}

            {Calc_Data(Data, "M2") === 0 ? (
              <></>
            ) : (
              <td width={"30%"}>M2: {Calc_Data(Data, "M2")}</td>
            )}
          </tr>
        </tbody>
      </table> */}

      <Flex gap={3}>
        {parseInt(Calc_Data(Data, "KG")) === 0
          ? (
            <></>
          )
          : (
            <Box w="50%" h="10">
              <InputGroup>
                <InputLeftAddon children="Kg" />
                <Input
                  type="text"
                  placeholder="Kilogramo"
                  value={formateador.format(Calc_Data(Data, "KG"))}
                  readOnly
                />
              </InputGroup>
            </Box>
          )}

        {parseInt(Calc_Data(Data, "U")) === 0
          ? (
            <></>
          )
          : (
            <Box w="50%" h="10">
              <InputGroup>
                <InputLeftAddon children="U" />
                <Input
                  type="text"
                  value={Calc_Data(Data, "U")}
                  placeholder="Unidad"
                  readOnly
                />
              </InputGroup>
            </Box>
          )}

        {Calc_Data(Data, "M2") === 0
          ? (
            <></>
          )
          : (
            <Box w="50%" h="10">
              <InputGroup>
                <InputLeftAddon children="M2" />
                <Input
                  type="text"
                  value={Calc_Data(Data, "M2")}
                  placeholder="metros cuadrados"
                  readOnly
                />
              </InputGroup>
            </Box>
          )}
      </Flex>
    </>
  );
};
