import styled from "styled-components/macro";
import warehouse from "assets/warehouse.png";
import city from "assets/ZONA_FRANCA_BARRANQUILLA_HERO.png";

export const ImageContainer = styled.div`
  overflow: hidden;
  // background-image: url(${window.assetURL + warehouse});

  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    height: auto;
  }
`;

export const ImageWelcome = styled.div`
  overflow: hidden;
  height: 100vh;
  background-image: url(${window.assetURL + warehouse});
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    height: 100vh;
  }
`;

// export const ImageCity = styled.img.attrs({ src: window.assetURL + city })`
//   height: 100vh;
//   overflow: hidden;
//   width: 100vw;
//   margin: ${({ margin }) => margin || "auto"};
//   @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
//     height: 100vh;
//   }
// `;

export const MainContainer = styled.main`
  min-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    padding: 0;
    padding-bottom: 10rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftSideContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    margin: 3rem auto;
  }
`;

export const Gradiante = styled.div`
  height: 50vh;
  width: 50vw;
  margin: ${({ margin }) => margin || "auto"};
  background: linear-gradient(to right, #ff6e7f, #bfe9ff);
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    height: 100vh;
  }
`;

export const ImageCIty = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #bdc3c7;
  background: linear-gradient(to bottom, #fffdfd00, #000000),
    url("${() => window.assetURL + city}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.breakpoints.mid}) {
    /* height: 100vh; */
  }
`;
