/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Table, Thead, Tr, Td, Tbody } from "@chakra-ui/react";
import bro from "../../../src/assets/bro.png";
import "../SelectInput/stilos.css";
import { ThStyledBlue } from "./Table.styled";
import { IconGeneric } from "styled/Icons.styled";

const TableOrderHover = ({
  thead = [],
  tbodyData: TbodyData,
  StatusCheck = [],
  data = [],
  setData,
  loading = false,
  isNum = true,
  imageNone,
  IncompleteStatus,
  onCloseModal,
  ...attrs
}) => {
  const [update, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [press, setPress] = useState(null);

  useEffect(() => {
    orderFirst();
  }, []);

  const orderFirst = async () => {
    const firstOrder = data.map((item) => item);
    setInitialData(firstOrder);
  };

  const orderData = (order) => {
    if (order !== press && order) {
      const newData = data.sort((a, b) =>
        a[order] > b[order] ? 1 : a[order] < b[order] ? -1 : 0
      );
      setData(newData);
      setUpdate(!update);
      setPress(order);
    } else if (order) {
      setData(initialData);
      setUpdate(!update);
      setPress(null);
    }
  };

  return (
    <div className="table-responsive">
      <Table
        size="md"
        variant="simple"
        className="table table-striped table-hover"
        overflowX={true}
      >
        <Thead>
          <Tr>
            {thead.map((th, index) =>
              index > 1
                ? (
                  <ThStyledBlue
                    key={th?.name + "_" + index}
                    isNumeric={isNum}
                    onClick={(e) => orderData(th.order, th.isNumber)}
                    cursor={th.order ? true : null}
                    id="alinear"
                    className="text-center"
                  >
                    <div className="d-flex justify-content-center align-items-center mr-4">
                      {th?.name}
                      <span>
                        {th.order ? <IconGeneric as={MdKeyboardArrowDown} /> : ""}
                      </span>
                    </div>
                  </ThStyledBlue>
                )
                : (
                  <ThStyledBlue
                    key={`${th?.name}_${index}`}
                    onClick={(e) => orderData(th.order, th.isNumber)}
                    cursor={th.order ? true : null}
                    className="text-center"
                  >
                    <div className="d-flex justify-content-center align-items-center mr-4">
                      {th?.name}
                      <span>
                        {th.order ? <IconGeneric as={MdKeyboardArrowDown} /> : ""}
                      </span>
                    </div>
                  </ThStyledBlue>
                )
            )}
          </Tr>
        </Thead>
        <Tbody>
          {update
            ? (
              data.length <= 0 && !loading
                ? (
                  <Tr>
                    {!imageNone && (
                      <Td colSpan={thead.length + 1}>
                        <div className="d-flex justify-content-center ml-6">
                          <img
                            style={{ width: `${20}%` }}
                            src={window.assetURL + bro}
                            alt="no hay formulario"
                          />
                        </div>
                        <h2 className="text-center my-2">Aún no hay registros.</h2>
                      </Td>
                    )}
                  </Tr>
                )
                : (
                  data.map((item, index) => (
                    <TbodyData
                      key={`${index}-${Date.now()}-${index}`}
                      data={item}
                      {...attrs}
                    />
                  ))
                )
            )
            : data.length <= 0 && !loading
              ? (
                <Tr>
                  {!imageNone && (
                    <Td colSpan={thead.length + 1}>
                      <div className="d-flex justify-content-center">
                        <img
                          style={{ width: `${20}%` }}
                          src={window.assetURL + bro}
                          alt="no hay formulario"
                        />
                      </div>
                      <h2 className="text-center my-2">Aún no hay registros.</h2>
                    </Td>
                  )}
                </Tr>
              )
              : (
                data.map((item, index) => (
                  <TbodyData
                    key={`${index}-${Date.now()}-${index}`}
                    data={item}
                    iope={index}
                    IncompleteStatus={IncompleteStatus}
                    CurrentData={data}
                    StatusCheck={StatusCheck}
                    onCloseModal={onCloseModal}
                    {...attrs}
                  />
                ))
              )}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableOrderHover;
