/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import InputSelect from "components/InputSelect/InputSelect";
import {
  Box,
  useToast,
  Accordion,
  SimpleGrid,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Textarea,
  Text,
  Flex,
  Grid
} from "@chakra-ui/react";

import "./styles.css";
import Items from "./Items";
import { Title } from "styled/Title";
import TbodyTable from "./TbodyTable";
import { useForm } from "hooks/useForm";
import { authorized } from "utils/generalData";
import { theadEntry, theadOutput } from "./thead";
import { ButtonStyled } from "styled/Buttons.styled";
import TableOrder from "components/Tables/TableOrder";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TbodyTableEntry from "./TbodyTableEntry";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

/**
 *  CardInfoSearchPlateVehicle - Componente que muestra la data que se solucida en el modulo de entrada o salida
 * @component
 * @param {Object} props data que se le envia al componente para mostrarla en la card.
 * @param {String} destiny validar si la llamada a este componente viene de entrada o salida.
 * @param {Function} setVehicleDoor Function para realizar el cambio de las puertas vehículares
 * @param {Function} setSelectTypeTransport Function para realizar el cambio de los tipos de transporte.
 * @param {Function} handleRegisterFreePasse Function para realizar el registro de la card con la información del pase.
 * @return {Component} Retorna componente JSX
 */

const CardInfoSearchPlateVehicle = (props, type) => {
  const {
    items,
    driver,
    status,
    destiny,
    uploaded,
    free_pass,
    empty_pass,
    dataPasses,
    container_1,
    handleInput,
    container_2,
    vehicleDoor,
    amount_items,
    observations,
    typeTransport,
    vehicle_plate,
    identification,
    setVehicleDoor,
    specificSearch,
    entryPasses_id,
    name_qualified,
    passForm_number,
    qualifiedUser_id,
    dataVehicheDoor,
    number_packages,
    freely_available,
    dispatchPasses_id,
    selectTypeTransport,
    capacity_container_1,
    capacity_container_2,
    setSelectTypeTransport,
    handleRegisterFreePasse,
    hide_inform = false,
    isDisabled_vehicleDoor = false,
    isDisable_TypeTransport = false,
    isDisable_nItems = false,
    createStatusdispatch,
    id,
    disabledTextarea,
    msg_pizf,
    msg_vv,
    msg_rc,
    msg_vrp,
    msg_vsc,
    typeTransport_id,
    type_transport,
    showAmountItems
  } = props;

  console.log({ props });

  const label = selectTypeTransport?.label || "";
  const value = selectTypeTransport?.value || "";

  /* console.log({ header: props?.headerInfo }) */

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  const [values, handleInputChange, setValues] = useForm({
    // plateVehicle: "",
    capacity_container_1: "",
    capacity_container_2: "",
    description: observations
  });

  useEffect(() => {
    setValues((prev) => ({ ...prev, description: observations ?? "" }));
  }, [observations]);

  const onChange = (e) => {
    handleInputChange(e);
    // console.log("even", e.target.value)
    // setValuesDataInput({
    //   ...dataInput,
    //   [e.target.name]: e.target.value,
    // });

    const data = {
      driver: dataPasses[0]?.driver,
      container_1: dataPasses[0]?.container_1,
      container_2: dataPasses[0]?.container_2,
      vehicle_plate: dataPasses[0]?.vehicle_plate,
      identification: dataPasses[0]?.identification,
      id: destiny === "entry" ? entryPasses_id : dispatchPasses_id,
      // description: e.target.value,
      description_security: values?.description,
      capacity_container_1: values?.capacity_container_1,
      capacity_container_2: values?.capacity_container_2,
      // qualifiedUser_id: selectUserQualified?.value, //new
      qualifiedUser_id,
      // dataInput,
      typeTransport_id: selectTypeTransport?.value
      // capacity_container_1: e.target.value,
    };

    handleInput(data);
  };

  const register = () => {
    const data = [
      {
        // id: destiny === "entry" ? entryPasses_id : dispatchPasses_id,
        // description_security: values.description,
        // typeTransport_id: selectTypeTransport?.value,
        // --------------
        driver,
        container_1: container_1 === null ? "" : container_1,
        container_2: container_2 === null ? "" : container_2,
        vehicle_plate,
        identification,
        id: destiny === "entry" ? entryPasses_id : dispatchPasses_id,
        // description: e.target.value,
        description_security: values?.description || observations,
        capacity_container_1: values?.capacity_container_1 || capacity_container_1,
        capacity_container_2: values?.capacity_container_2 || capacity_container_2,
        typeTransport_id: selectTypeTransport?.value,
        // qualifiedUser_id: selectUserQualified?.value, //new
        qualifiedUser_id,
        flag_security: createStatusdispatch || 0
      }
    ];
    handleRegisterFreePasse(data);
  };

  const validateContainer = selectTypeTransport?.is_container === 1;

  return (
    <>
      {!fetchStatus && (
        <div className="mb-4">
          <div className="card">
            <Accordion
              allowToggle
              defaultIndex={id === 0 || specificSearch ? [0] : [1]}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <div
                        className="name"
                      // style={{ color: status_entryPass === presented ? "#f9a621" : "#1F7AC3" }}
                      >
                        {driver} - Identificación: {identification}{" "}
                        {isDisable_nItems || createStatusdispatch
                          ? ""
                          : `- Nº pase: ${passForm_number}`}
                        {free_pass === 1 && (
                          <span
                            style={{
                              color: "var(--zf-chathams-blue)",
                              marginLeft: 5,
                              fontSize: "1.2rem"
                            }}
                          >
                            -{" "}
                            <span style={{ color: "var(--zf-aribbean-green)" }}>
                              Paso libre
                            </span>
                          </span>
                        )}
                        {items?.length > 0 &&
                          destiny !== "entry" &&
                          " - Con Items"}
                        {free_pass === 1 && (
                          <BiCheckCircle
                            style={{ marginLeft: 3 }}
                            color="#03c39a"
                          />
                        )}
                        {name_qualified && ` - ${name_qualified}`}
                      </div>
                      <Grid
                        templateColumns="repeat(2, 1fr)"
                        gap={4}
                        mt={2}
                        mb={2}
                      >
                        {
                          msg_pizf && (
                            <Box
                              bg={"rgb(44, 178, 91)"}
                              color={"white"}
                              textOverflow={"ellipsis"}
                              className="py-2"
                              fontWeight={"semibold"}
                              padding={"10px"}
                              rounded={"md"}
                            >
                              {msg_pizf}
                            </Box>
                          )
                        }

                        {
                          msg_vv && (
                            <Box
                              bg={"rgb(247, 177, 86)"}
                              color={"white"}
                              textOverflow={"ellipsis"}
                              className="py-2"
                              fontWeight={"semibold"}
                              padding={"10px"}
                              rounded={"md"}
                            >
                              {msg_vv}
                            </Box>
                          )
                        }
                        {
                          msg_rc && (
                            <Box
                              bg={"rgb(244, 97, 97)"}
                              color={"white"}
                              textOverflow={"ellipsis"}
                              className="py-2"
                              fontWeight={"semibold"}
                              padding={"10px"}
                              rounded={"md"}

                            >
                              {msg_rc}
                            </Box>
                          )
                        }
                        {
                          msg_vrp && (
                            <Box
                              bg={"rgb(244, 177, 86)"}
                              color={"white"}
                              textOverflow={"ellipsis"}
                              className="py-2"
                              fontWeight={"semibold"}
                              padding={"10px"}
                              rounded={"md"}
                            >
                              {msg_vrp}
                            </Box>
                          )
                        }
                        {
                          msg_vsc && (
                            <Box
                              bg={"rgb(244, 97, 97)"}
                              color={"white"}
                              textOverflow={"ellipsis"}
                              className="py-2"
                              fontWeight={"semibold"}
                              padding={"10px"}
                              rounded={"md"}
                            >
                              {msg_vsc}
                            </Box>
                          )
                        }
                      </Grid>

                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={0}>
                  <Title
                    style={{
                      color: "var(--zf-chathams-blue)",
                      padding: 10,
                      // textAlign: "center",
                      fontSize: 18
                    }}
                  >
                    Placa vehículo: {vehicle_plate}
                  </Title>
                  <div style={{ padding: 10 }}>
                    <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
                      <div className="">
                        <InputSelect
                          value={vehicleDoor}
                          set={setVehicleDoor}
                          data={dataVehicheDoor}
                          tag="Puerta Vehicular *"
                          isDisabled={true}
                        />
                      </div>
                      <div className="">
                        <InputSelect
                          value={selectTypeTransport}
                          set={setSelectTypeTransport}
                          data={typeTransport}
                          tag="Tipo de transporte"
                          isDisabled={disabledTextarea}
                        />
                      </div>
                    </SimpleGrid>

                    {hide_inform || createStatusdispatch === 1
                      ? (
                        <></>
                      )
                      : (
                        <>
                          <br />
                          <div className="row">
                            <div className="col-sm">
                              {validateContainer
                                ? (
                                  <>
                                    <Items
                                      title="Contenedor 1:"
                                      desc={container_1}
                                      bgColor="#F6F9FE"
                                    />

                                    {validateContainer && container_2 !== null && (
                                      <Items
                                        title="Contenedor 2:"
                                        desc={container_2}
                                        bgColor="#F6F9FE"
                                      />
                                    )}
                                  </>
                                )
                                : (
                                  ""
                                )}
                            </div>
                            <div className="col-sm">


                              {validateContainer &&
                                <Items
                                  title="Capacidad Cont.1:"
                                  desc={capacity_container_1}
                                  bgColor="#F6F9FE"
                                />}

                              {validateContainer && capacity_container_2 !== null && (
                                <Items
                                  title="Capacidad Cont.2:"
                                  desc={capacity_container_2}
                                  bgColor="#F6F9FE"
                                />
                              )}
                            </div>

                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                              {showAmountItems && amount_items ? (
                                <div style={{ backgroundColor: '#F6F9FE' }}>
                                  <div style={{ width: '49%' }}>
                                    <Items
                                      title="Cant. de ítems:"
                                      desc={amount_items}
                                      bgColor="#F6F9FE"
                                    />
                                  </div>
                                </div>

                              ) : ""}

                              {destiny === "exit" && items?.reduce((acc, item) => acc + Number(item.quantity_dispatch), 0) ? (
                                <div style={{ backgroundColor: '#F6F9FE' }}>
                                  <div style={{ width: '49%' }}>
                                    <Items
                                      title="Cant. de despacho:"
                                      desc={items?.reduce((acc, item) => acc + Number(item.quantity_dispatch), 0)}
                                      bgColor="#F6F9FE" />

                                  </div>
                                </div>

                              ): ""}

                              <div style={{ backgroundColor: '#F6F9FE' }}>
                                <div style={{ width: '49%' }}>
                                  <Items
                                    title="Nº de bultos:"
                                    desc={number_packages}
                                    bgColor="#F6F9FE"
                                  />
                                </div>
                              </div>
                            </div>

                          </div>{" "}
                        </>
                      )}

                    <SimpleGrid minChildWidth="200px" spacing="10px" my={10}>
                      <div>
                        <Text mb="8px">Descripción *</Text>
                        <Textarea
                          value={values?.description || observations}
                          onChange={(e) => onChange(e)}
                          name="description"
                          placeholder="Introduce la descripción aquí..."
                          size="sm"
                          disabled={disabledTextarea}
                        />
                      </div>
                    </SimpleGrid>

                    {items?.length !== 0 &&
                      destiny !== "entry" &&
                      !createStatusdispatch
                      ? (
                        <TableOrder
                          thead={destiny === "entry" ? theadEntry : theadOutput}
                          loading={false}
                          data={items}
                          setData={() => { }}
                          tbodyData={
                            destiny === "entry" ? TbodyTableEntry : TbodyTable
                          }
                        />
                      )
                      : (
                        <></>
                      )}

                    {loading ? (
                      <SpinnerComponent />
                    ) : (
                      <>
                        {empty_pass === 1 ||
                          status === authorized ||
                          free_pass === 1 ||
                          (freely_available === 1 && status === authorized) ? (
                          <ButtonStyled
                            type="submit"
                            onClick={() => register()}
                          // disabled={uploaded !== undefined ? true : false}
                          >
                            Registrar
                          </ButtonStyled>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default CardInfoSearchPlateVehicle;
