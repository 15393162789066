import { Center, Text } from "@chakra-ui/react";
import environment from "constants/apiConst";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { __Get } from "request/Petitions/__Get";
import RobotSvg from "./SvgRobot";
import "./animate.css";
import { ADDLocalStorage } from "hooks/useLocalStorage";

export const ModeRobot = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [isLoading, setLoading] = useState(null);

  ADDLocalStorage(true, "Mode_Robot");

  const GetReviews = async () => {
    setLoading(true);

    try {
      const res = await __Get(environment.GET_ROBOT_FORM, token);
      const Router = res?.data?.status?.data;
      if (Router?.type_doc === "Formulario de ingreso") {
        navigate(`/FormShow?FormEntry=${Router?.form_id}`);
      }

      if (Router?.type_doc === "Formulario de salida") {
        navigate(`/FormShowExit?ExitNumber=${Router?.form_id}`);
      }
      if (Router?.type_doc === "Formulario de corrección de ingreso") {
        navigate(`/FormCorrection?ShowEntry=${Router?.form_id}`);
      }

      if (Router?.type_doc === "Formulario de corrección de salida") {
        navigate(`/FormCorrectionOutput?ShowOutput=${Router?.form_id}`);
      }

      if (Router?.type_doc === "Formulario de certificado de integración") {
        navigate(`/ShowIntegrationBox?id=${Router?.form_id}`);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetReviews();
  }, []);

  return (
    <Center display={"flex"} flexDir={"column"}>
      <RobotSvg width={480} height={480} />
      {isLoading
        ? (
          <Text as={"h3"}>Cargando Formulario...</Text>
        )
        : (
          <Text as={"h3"}>Sin Formularios Disponibles</Text>
        )}
    </Center>
  );
};
