import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useContext } from "react";
import { SimpleGrid, Tooltip } from "@chakra-ui/react";
import { MaxLength } from "utils/MaxLength";
import { useDebounceError } from "hooks/useDebounceError";
import FormCrudCRUDContext from "context/FormCrudContex";
import { Regex } from "utils/Regex";

export const NationalTerritory = ({
  formOperation = 0,
  values,
  handleInputChange,
  colorNotation = () => {},
  reviewData = {}
}) => {
  const { certificate_inspection_pre_boarding, date, commercial_invoice } =
    values;

  const { dataFormCurrent } = useContext(FormCrudCRUDContext);
  const [handleDebounceRegexError] = useDebounceError();

  const handleRegex = ({ target }, type, regex) => {
    const { value } = target;
    handleDebounceRegexError(
      { target },
      handleInputChange,
      type !== "all"
        ? `Este campo solo acepta ${type}`
        : `El carácter ${
          value[value.length - 1]
        } no es permitido en este campo`,
      regex
    );
  };

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
        <Tooltip
          hasArrow
          label={reviewData?.certificate_inspection_pre_boarding?.comment}
          bg={colorNotation("certificate_inspection_pre_boarding")}
          color="white"
        >
          <LabelGroup
            name={"certificate_inspection_pre_boarding"}
            value={certificate_inspection_pre_boarding}
            onChange={(e) => handleRegex(e, "all", Regex?.all)}
            tag={"Cert. de inspecc. preembarque "}
            error={colorNotation("certificate_inspection_pre_boarding")}
            data-input-tag={"Cert. de inspecc. preembarque "}
            data-item-id={"Header"}
            data-old-Value={
              dataFormCurrent?.certificate_inspection_pre_boarding
            }
            size={100}
            desicion={false}
            maxLength={
              MaxLength.formExit.formExitNumber
                .certificate_inspection_pre_boarding
            }
          />
        </Tooltip>
        <LabelGroup
          name={"date"}
          value={date}
          onChange={handleInputChange}
          tag={"Fecha"}
          size={100}
          tipo={"date"}
        />
      </SimpleGrid>
      <Tooltip
        hasArrow
        label={reviewData?.commercial_invoice?.comment}
        bg={colorNotation("commercial_invoice")}
        color="white"
      >
        <LabelGroup
          name={"commercial_invoice"}
          value={commercial_invoice}
          onChange={(e) => handleRegex(e, "all", Regex?.all)}
          error={colorNotation("commercial_invoice")}
          data-input-tag={"Factura Comercial"}
          data-item-id={"Header"}
          data-old-Value={dataFormCurrent?.commercial_invoice}
          tag={"Factura Comercial"}
          required={formOperation === 1}
          size={100}
          maxLength={MaxLength.formExit.formExitNumber.commercial_invoice}
        />
      </Tooltip>
    </>
  );
};
