/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import FormSubShow from "../FormShow/FormSubShow";
import SubheadingForm from "../forms/SubheadingForm";
import TableOrder from "components/Tables/TableOrder";
import TbodySubheading from "../tables/TbodySubheading";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import { __Subheading } from "request/configuration/__Subheading";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery } from "@tanstack/react-query";

export const Subheading = () => {
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const freeZone_id = useSelector((state) => state.auth.dataUser.token);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);

  const formEditSub = () => {
    return <SubheadingForm />;
  };

  const formShow = () => {
    return <FormSubShow />;
  };

  useEffect(() => {
    setFormEdit(formEditSub);
    setFormShow(formShow);
    setRequestEdit(environment.SUBHEADING_UPDATE);
    setRequestCreate(environment.SUBHEADING_CREATE);
    setRequestDelete(environment.SUBHEADING_DELETE);
  }, [q, update, page]);


  const Title = [
    { name: "Código", order: "code_subheading" },
    { name: "Descripción", order: "description" },
    { name: "Seguimiento", order: "null" },
    { name: "Estado", order: "status_subheading" },
    { name: "Acciones", order: "" }
  ];

  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configSubheading", page, q, freeZone_id, update],
    queryFn: () => requestData({
      Endpoint: environment.SUBHEADING_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Subpartidas"></ConfigurationHeader>

        <TableOrder
          thead={Title}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodySubheading}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};
