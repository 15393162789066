import { USER_TYPES } from "redux/types/userType";

const initialState = {
  update: false
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.STATEUPDATE:
      return {
        ...state,
        update: action.payload
      };

    default:
      return state;
  }
};
