/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
  LabelStyled,
  LabelStyledComment
} from 'components/addProduct/CreateTemplate.styled'
import { FormInput, FormInputColor } from './Stilos'
import Select from 'react-select'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Tooltip
} from '@chakra-ui/react'
import { IconGeneric } from 'styled/Icons.styled'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import '../../components/InputSelect/styleInput.css'
import { TextAreaFormStyled } from 'styled/Input.styled'
import { InputTRM } from 'pages/Configuration/ExchangeRate/styleInput'
import isArrayNotEmpty, {
  isArray,
  isFunction,
  isNonEmptyString,
  isNumber,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid
} from 'utils/type-check-utils'
export const LabelGroupComment = ({
  name,
  tag,
  size,
  value,
  onChange,
  desicion,
  tipo,
  placeholder,
  maxLength,
  readOnly,
  required,
  children,
  onClick,
  onkeyup,
  id,
  onBlur,
  changeValue,
  max,
  ColorCustom
}) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // onClick();
      // alert('Hola')
    }
  }

  const VerifiedPlaceholder = () => {
    if (
      placeholder !== null &&
      placeholder !== undefined &&
      placeholder !== ''
    ) {
      return placeholder
    } else {
      return 'N/A'
    }
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyledComment ColorCustom={ColorCustom}>{tag}</LabelStyledComment>
      <FormInputColor
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        name={name}
        disabled={desicion}
        id={desicion === true ? 'noptions' : id}
        type={tipo}
        onKeyDown={handleKeyDown}
        placeholder={VerifiedPlaceholder()}
        maxLength={maxLength}
        readOnly={readOnly}
        onkeyup={onkeyup}
        required={required}
        onClick={onClick}
        className={changeValue && changeValue}
        max={max && max}
        Color={ColorCustom}
      />
      {children}
    </div>
  )
}

export const LabelGroup = ({
  name,
  tag,
  size,
  value = null,
  register = () => { },
  onChange = null,
  desicion,
  tipo,
  placeholder,
  maxLength,
  readOnly,
  required,
  children,
  onClick,
  onkeyup,
  id,
  onBlur,
  changeValue,
  max,
  min,
  error,
  color,
  label,
  bg,
  fontSize,
  onKeyDown,
  ButtonSide = false,
  TagChildren,
  fontWeight = 'normal',
  propsRegister = {},
  dataType = 'string', 
  customErrorMessage = '',
  colorLabel = null,
  ...props
}) => {

/*   const { type: _, ...rest } = props || {}
 */
/*   const [validationError, setValidationError] = useState('');

  const validateDataType = (value) => {
    switch(dataType) {
      case 'number':
        return isNumber(value) ? true : 'El valor debe ser un número.';
      case 'array':
        return isArray(value) ? true : 'El valor debe ser un array.';
      case 'object':
        return isObject(value) ? true : 'El valor debe ser un objeto.';
      case 'string':
        return isString(value) ? true : 'El valor debe ser una cadena de texto.';
      default:
        return true;
    }
  };
 */
/*   const convertToType = (value) => {
    switch(dataType) {
      case 'number':
        return Number(value);
      case 'array':
        return Array.isArray(value) ? value : [value];
      case 'object':
        return typeof value === 'object' ? value : { value };
      case 'string':
      default:
        return String(value);
    }
  };
 */
  const customProps = {
    ...((isNumber(value) ||
      (isArray(value) && isArrayNotEmpty(value)) ||
      (isObjectNotEmpty(value) && isObject(value)) ||
      (isNonEmptyString(value) || isString(value))) &&
      isValid(value)
      ? {
        value
      }
      : {})
  }

  return (
    <Tooltip label={label} hasArrow arrowSize={11} placement='bottom' bg={bg}>
      <FormControl style={{ width: `${size}%` }} isRequired={required} isInvalid={error}>
        <div className='d-flex justify-center align-items-center gap-1'>
          <FormLabel
            htmlFor={name}
            fontSize={'0.88rem'}
            fontWeight={fontWeight}
            marginBottom={'0'}
            color={colorLabel ?? 'rgb(69, 69, 70)'}
            
          >
            {tag}
          </FormLabel>
          {TagChildren}
        </div>

        <FormInput
          style={{
            borderTopRightRadius: ButtonSide && '0',
            borderBottomRightRadius: ButtonSide && '0',
            borderRight: ButtonSide && '0'
          }}
          {...(isFunction(onChange) && { onChange })}
          {...customProps}
          onBlur={onBlur}
          name={name}
          onClick={onClick}
          disabled={desicion}
          id={desicion === true ? 'noptions' : id}
          type={tipo}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          data-input-tag={tag}
          data-type-input={dataType}
          tag={tag}
          readOnly={readOnly}
          onKeyUp={onkeyup}
          {...(isFunction(register) && {
            ...register(name, {
              ...propsRegister
            })
          })}
          maxLength={maxLength}
          className={changeValue && changeValue}
          max={max && max}
          min={min && min}
          error={error}
          required={required}
          {...props}
        />

        {children || ''}
      </FormControl>
    </Tooltip>
  )
}

// Creado el 10-11-2022
export const LabelGroupColor = ({
  name,
  tag,
  size,
  value,
  onChange,
  desicion,
  tipo,
  placeholder,
  maxLength,
  readOnly,
  required,
  children,
  onClick,
  onkeyup,
  id,
  onBlur,
  changeValue,
  max,
  error,
  Color
}) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // onClick();
      // alert('Hola')
    }
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled error={error}>{tag}</LabelStyled>
      <FormInputColor
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        onClick={onClick}
        name={name}
        disabled={desicion}
        Color={Color}
        id={desicion === true ? 'noptions' : id}
        type={tipo}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly}
        onkeyup={onkeyup}
        required={required}
        className={changeValue && changeValue}
        max={max && max}
      // error={error}
      />
      {children}
    </div>
  )
}

export const LabelGroups = ({
  name,
  tag,
  size,
  value,
  onChange,
  desicion,
  tipo,
  placeholder,
  maxLength,
  readOnly,
  required,
  children,
  onClick,
  onkeyup,
  id,
  onBlur,
  changeValue
}) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // onClick();
      // alert('Hola')
    }
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <InputTRM
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        name={name}
        disabled={desicion}
        id={desicion === true ? 'noptions' : id}
        type={tipo}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly}
        onkeyup={onkeyup}
        required={required}
        className={changeValue && changeValue}
      />
      {children}
    </div>
  )
}

export const LabelGroupTextArea = ({
  name,
  tag,
  size,
  value,
  onChange,
  desicion,
  tipo,
  placeholder,
  maxLength,
  readOnly,
  required,
  children,
  error = false
}) => {
  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: error ? 'red' : provided?.borderColor
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: error ? 'red' : provided.color
    })
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <TextAreaFormStyled
        onChange={onChange}
        error={error}
        styles={customStyles}
        value={value}
        name={name}
        disabled={desicion}
        id={desicion === true ? 'noptions' : ''}
        type={tipo}
        placeholder={placeholder}
        maxLength={maxLength}
        readOnly={readOnly}
        required={required}
      />
      {children}
    </div>
  )
}

export const SwitchesGroup = ({ status, setStatus, tag, size, desicion }) => {
  const handleswitche = e => {
    if (e.target.checked === true) {
      setStatus(1)
    } else {
      setStatus(0)
    }
  }

  return (
    <div
      className='position-relative end-100 my-3 pt-3'
      style={{ width: `${size}%` }}
    >
      <div className='position-absolute top-0 start-20 translate-middle'>
        <LabelStyled>{tag}</LabelStyled>
        <div className='form-check form-switch d-flex justify-content-center'>
          <input
            style={{ width: '45px', height: '25px' }}
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='flexSwitchCheckChecked'
            onChange={handleswitche}
            checked={status === 1}
            disabled={desicion}
          />
        </div>
      </div>
    </div>
  )
}

export const SwitchPropiosMedios = ({
  status,
  setStatus,
  tag,
  set,
  size,
  desicion,
  margin = false,
  handleUpdate,
  name,
  onChange = null,
  ...props
}) => {
  const handleswitche = e => {
    console.log(e.target.checked)
    if (e.target.checked === true) {
      setStatus(1)
      if (set !== undefined) {
        if (tag === 'Libre disposición') {
          set(0)
        }
        if (tag === 'Vehículo vacío') {
          set(0)
        }
      }
    } else {
      setStatus(0)
    }
    if (handleUpdate) {
      handleUpdate(name, e.target.checked === true ? 1 : 0)
    }
  }

  return (
    // <div className="position-relative my-3 pt-3" style={{ width: `${size}%` }}>
    //   <div className="position-absolute top-0 start-50 translate-middle">
    <div className={`d-flex align-items-center ${!margin && 'me-2'} `}>
      <LabelStyled className='me-3'>{tag}</LabelStyled>
      <div className='form-check form-switch d-flex justify-content-center'>
        <input
          style={{
            width: '45px',
            height: '25px',
            borderColor: desicion ? '#bababa' : '#9097BE'
          }}
          className='form-check-input'
          type='checkbox'
          role='switch'
          id='flexSwitchCheckChecked'
          onChange={onChange || handleswitche}
          checked={status === 1}
          disabled={desicion}
          name={name}
          {...props}
        />
      </div>
    </div>
    // </div>
  )
}

export const SwitchUpdateHot = ({
  status,
  tag,
  desicion,
  handleswitche,
  tagName
}) => {
  return (
    <div className='d-flex align-items-center me-4'>
      <LabelStyled className='me-3'>{tag}</LabelStyled>
      <div className='form-check form-switch d-flex justify-content-center'>
        <input
          style={{ width: '45px', height: '25px' }}
          className='form-check-input'
          type='checkbox'
          role='switch'
          id='flexSwitchCheckChecked'
          onChange={handleswitche}
          checked={status === 1}
          disabled={desicion}
          name={tagName}
        />
      </div>
    </div>
  )
}

export const Seleccionador = ({ partidas, tag, size, setMendigo, mendigo }) => {
  const selectValor = optionesVa => {
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element?.code_subheading,
          value: element?.code_subheading
        })
      })
    return valores
  }
  const handleSelectChange = items => {
    setMendigo(items?.value)
  }
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: mendigo, value: mendigo }}
        options={selectValor(partidas)}
        onChange={items => {
          handleSelectChange(items)
        }}
      />
    </div>
  )
}

export const ControlSub = ({
  partidas,
  tag,
  size,
  setMendigo,
  mendigo,
  setSubheadingN,
  subheadingN
}) => {
  const selectValor = optionesVa => {
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element?.code_subheading,
          value: element?.id
        })
      })
    return valores
  }

  const handleSelectChange = items => {
    if (items.value !== mendigo) {
      setMendigo(items.value)
    }

    if (items.label !== subheadingN) {
      setSubheadingN(items.label)
    }
  }

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        isClearable={true}
        value={{ label: subheadingN, value: mendigo }}
        options={selectValor(partidas)}
        onChange={items => {
          handleSelectChange(items)
        }}
      />
    </div>
  )
}

export const InputGroup = ({ partidas, tag, size, setMendigo, unidad }) => {
  const selectValor = optionesVa => {
    const valores = []
    optionesVa &&
      optionesVa.forEach(element => {
        valores.push({
          label: element.code_unit_measurement + ' ' + element.unit_measurement,
          value: element.id
        })
      })
    return valores
  }
  const handleSelectChange = items => {
    setMendigo(items)
  }
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={unidad}
        options={selectValor(partidas)}
        onChange={items => {
          handleSelectChange(items)
        }}
      />
    </div>
  )
}

export const InputGroupUser = ({
  error = false,
  partidas,
  tag,
  size,
  setMendigo,
  setNameQ,
  nameQ,
  valor,
  Disabled = false,
  setDecimalUcContext = () => { },
  Name,
  Label,
  LabelBg,
  customColorLabel = null,
  onChange = () => { },
  ...attrs
}) => {
  const selectValor = (optionesVa = []) => {
    // console.log(optionesVa);
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element?.nit_qualified + ' - ' + element?.name_qualified,
          value: element?.id,
          decimal: element?.decimal_values_precision,
          ...element
        })
      })
    return valores
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: error ? 'red' : provided?.borderColor
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: error ? 'red' : provided.color
    })
  }

  const handleSelectChange = items => {
    setMendigo(items?.value)
    setNameQ(items?.label)
    setDecimalUcContext(items?.decimal)
  }

  const onChangeLocal = (...attrs) => {
    handleSelectChange(...attrs)
    onChange(...attrs)
  }

  return (
    <Tooltip
      hasArrow
      label={Label}
      bg={LabelBg}
      color='white'
      placement='bottom-start'
    >
      <div style={{ width: `${size}%` }}>
        <LabelStyled
          style={customColorLabel && { color: customColorLabel }}
          error={error}
        >
          {tag}
        </LabelStyled>
        <Select
          error={error}
          styles={customStyles}
          isDisabled={Disabled}
          value={{ label: nameQ, value: valor }}
          options={selectValor(partidas)}
          onChange={onChangeLocal}
          {...attrs}
        />
      </div>
    </Tooltip>
  )
}

export const InputFromUserToUser = ({
  partidas,
  tag,
  size,
  setMendigo,
  setNameQ,
  nameQ,
  valor
}) => {
  const selectValor = optionesVa => {
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element?.nit_qualified + ' - ' + element?.name_qualified,
          value: element?.id
        })
      })
    return valores
  }
  const handleSelectChange = items => {
    setMendigo(items.value)
    setNameQ(items.label)
  }
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        // className="z-index"
        value={{ label: nameQ, value: valor }}
        options={selectValor(partidas)}
        onChange={items => {
          handleSelectChange(items)
        }}
      />
    </div>
  )
}

export const AcordionGroup = ({
  subTitle,
  setPermisos,
  allper,
  permisosAct,
  permisos,
  setPermisosAct
}) => {
  const [act, setAct] = useState(false)
  const [have, setHave] = useState(false)

  const actState = e => {
    // setPermisos((prev) => [...prev, parseInt(e.target.value)]);
    if (e.target.checked) {
      return setPermisos(prev => [...prev, parseInt(e.target.value)])
    }
    const remover = parseInt(e.target.value)
    setPermisos(prev => [...prev.filter(item => item !== remover)])
  }

  const validate = e => {
    setAct(e.target.checked)
    if (e.target.checked) {
      return subTitle.permissions.map(items => {
        setPermisosAct(prev => [...prev, items])
        setPermisos(prev => [...prev, parseInt(items.id)])
      })
    }
    setPermisosAct(prev =>
      prev.filter(
        item => !subTitle.permissions.some(element => element.id === item.id)
      )
    )
    setPermisos(prev =>
      prev.filter(
        item => !subTitle.permissions.some(element => element.id === item)
      )
    )
  }
  return (
    <div
      style={{
        marginTop: '5px',
        border: '1px solid #bababa',
        borderRadius: '10px',
        maxHeight: '300px',
        overflow: 'auto'
      }}
    >
      <Accordion allowToggle>
        <AccordionItem isDisabled={act}>
          {({ isExpanded }) => (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  backgroundColor: have > 0 ? '#c2c2c2' : ''
                }}
              >
                <Checkbox
                  className='float-end m-2'
                  value={subTitle.name_permissionGroup}
                  onChange={validate}
                ></Checkbox>
                <h2 style={{ width: '100%' }}>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      {subTitle.name_permissionGroup}
                    </Box>
                    {isExpanded ? (
                      <IconGeneric fontSize='12px' as={MdKeyboardArrowUp} />
                    ) : (
                      <IconGeneric fontSize='12px' as={MdKeyboardArrowDown} />
                    )}
                  </AccordionButton>
                </h2>
              </div>
              <AccordionPanel pb={5}>
                {subTitle?.permissions
                  ?.sort((a, b) =>
                    a?.description_permission?.localeCompare(
                      b.description_permission
                    )
                  )
                  ?.map((items, index) => (
                    <Checkbox
                      isDisabled={allper}
                      key={index}
                      className='m-3'
                      value={items?.id}
                      onChange={actState}
                      defaultChecked={permisosAct.some(
                        element => element?.id === items?.id
                      )}
                    >
                      {setHave(
                        permisosAct.some(element => element?.id === items?.id)
                      )}
                      {items?.description_permission}
                    </Checkbox>
                  ))}
              </AccordionPanel>
            </div>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export const InputGroupLicense = ({ partidas, tag, size, setValor, licenseIds }) => {
  const [licenses, setLicenses] = useState([])

  const selectValor = optionesVa => {
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element.name_license,
          value: element.name_license
        })
      })
    return valores
  }
  const handleSelectChange = (items, actionMeta) => {
    const action = actionMeta.action;
    if (action === 'remove-value' || action === 'pop-value') {
      const removedValue = actionMeta.removedValue;
      const newValue = licenses.filter((item) => item.value !== removedValue.value);
      setLicenses(newValue);
    } else if (action === 'clear') {
      setLicenses([]);
    } else {
      setLicenses(items);
    }
    const licensesUser = []
    for (let i = 0; i < items.length; i++) {
      const datos = partidas.find(info => info.name_license === items[i]?.value)
      licensesUser.push(datos.id)
    }
    setValor(licensesUser)
  }

 
  useEffect(() => {
    const defaultValue = licenseIds
      .map((item) => {
        const datos = partidas.find((info) => info.id == item.id);
        return datos ? { id:datos.id, label: datos.name_license, value: datos.name_license } : undefined;
      })
      .filter((datos) => datos !== undefined);
    setLicenses(defaultValue);
  }, [licenseIds, partidas]);

  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={selectValor(partidas)}
        onChange={handleSelectChange}
        value={licenses}
      />
    </div>
  )
}

export const ALLproduts = ({
  partidas,
  tag,
  size,
  setMendigo,
  mendigo,
  setPlacePro,
  placePro,
  setValues
}) => {
  const selectValor = optionesVa => {
    const valores = []
    isArray(optionesVa) &&
      optionesVa.forEach(element => {
        valores.push({
          label: element?.code_product,
          value: element?.id
        })
      })
    return valores
  }
  const handleSelectChange = items => {
    setMendigo(items.value)
    setPlacePro(items.label)
    const respuesta = partidas.find(element => element.id === items.value)
    setValues(values => ({
      ...values,
      convertion_factor_product: parseInt(respuesta.convertion_factor_product),
      ...respuesta
    }))
  }
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: placePro, value: mendigo }}
        options={selectValor(partidas)}
        onChange={items => {
          handleSelectChange(items)
        }}
      />
    </div>
  )
}
