/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfigurationHeader from "../ConfigurationHeader";
import queryString from "query-string";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { __TradeAgreementAll } from "request/configuration/__TradeAgreement";
import environment from "constants/apiConst";
import TbodyTradeAgreement from "../tables/TbodyTradeAgreement";
import TradeAgreementForm from "../forms/TradeAgreementForm";
import ImportTradeAgreement from "../forms/ImportTradeAgreement";
import FormAComercialShow from "../FormShow/FormAComercialShow";

import { SectionContainerGene } from "styled/SectionContainer.styled";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const TradeAgreement = () => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);
  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const location = useLocation();

  const { q = "" } = queryString.parse(location.search);

  const thead = [
    { name: "Código", order: "code_tradeAgreement" },
    { name: "Nombre", order: "name_tradeAgreement" },
    { name: "Observaciones", order: "observation" },
    { name: "Estado", order: "observation" },
    { name: "Acciones", order: "" }
  ];

  const formEdit = () => {
    return <TradeAgreementForm />;
  };

  const formShow = () => {
    return <FormAComercialShow />;
  };

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formShow);
    setRequestEdit(environment.TRADE_AGREEMENT_UPDATE);
    setRequestCreate(environment.TRADE_AGREEMENT_CREATE);
    setRequestDelete(environment.TRADE_AGREEMENT_DELETE);
  }, [page, freeZone_id, q, update]);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["tradeAgreement"]})
  }, [update])


  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["tradeAgreement", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.TRADE_AGREEMENT_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])


  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Acuerdos Comerciales">
        <ImportTradeAgreement />
      </ConfigurationHeader>
      {isLoading || (
        <TableOrder
          thead={thead}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          loading={isLoading}
          tbodyData={TbodyTradeAgreement}
          isNum={false}
        />
      )}
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default TradeAgreement;
