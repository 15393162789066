export const cabeceraExportPdf = [
  "Descripción del producto",
  "Subpartida",
  "Und.",
  "Peso neto",
  "Valor US$",
  "Cnt. Por Und."
];

export const cabeceraExportPdfSuplies = [
  "Descripción materia primas e insumos",
  "Subpartida",
  "Und.",
  "% Desperdicio",
  "Vr. CIF Unit. US$",
  "Consumo por Und."
];

export const cabeceraExportPdfOtros = [
  "Descripción otros costos y gastos",
  "Vr. CIF Unit. US$",
  "Consumo por Und."
];
