import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query';
import InputSelect, { InputSelectMulti } from 'components/InputSelect/InputSelect';
import environment from 'constants/apiConst';
import queryEnv from 'constants/queryConst';
import { useGlobalContext } from 'context/GlobalContext';
import { useSearchUrl } from 'hooks/useSearchUrl';
import React from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { IconGeneric } from 'styled/Icons.styled';
import { Title } from 'styled/Title';

export const ucTypes = {
  SINGLE: "single",
  MULTI: "multi",
}

const HeaderAccordion = ({ type = ucTypes?.SINGLE, children, title, ...props }) => {
  const { state, handleSearchParams } = useSearchUrl({ getParams: ["search", "valueQualified"], nestedProp: true ? "searchSimple" : null });
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const { requestData } = useGlobalContext();

  const { isLoading: isLoadingUC, data: dataUC } = useQuery({
    queryKey: [queryEnv.QUALIFIED_USERS, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.GET_QUALIFIED_USERS_ALL,
    })
  })


  const handleChangeUCOnSearch = (e) => {
    const dataUser = e?.map((user) => ({ value: user?.value, label: user?.label }));
    handleSearchParams({ name: "valueQualified", value: dataUser?.length > 0 ? JSON.stringify(dataUser) : null });
  }

  const handleChangeUCOnSearchSingle = (e) => {
    handleSearchParams({ name: "valueQualified", value: e?.value ? JSON.stringify({ value: e?.value, label: e?.label }) : null });
  }

  const ucSelectObj = {
    single: (
      <InputSelect
        tag={"Usuario calificado"}
        name={"valueQualified"}
        data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
          value: item?.id,
          label: item?.nit_qualified + " - " + item?.name_qualified,
          ...item,
        })) : []}
        set={handleChangeUCOnSearchSingle}
        value={state?.valueQualified}
        isLoading={isLoadingUC}
        isDisabled={isLoadingUC}
      />
    ),
    multi: (
      <InputSelectMulti
        className=""
        tag={"Usuario calificado"}
        name={"valueQualified"}
        data={!isLoadingUC ? dataUC?.data?.status?.data?.map((item) => ({
          value: item?.id,
          label: item?.nit_qualified + " - " + item?.name_qualified,
          ...item,
        })) : []}
        set={handleChangeUCOnSearch}
        value={state?.valueQualified}
        isLoading={isLoadingUC}
        isDisabled={isLoadingUC}
      />
    )
  };

  const TypeSelectUC = ucSelectObj[type] ?? ucSelectObj.single;



  return (
    <>
      {title &&
        <Title size={"18px"} weight={600} className="mt-3">
          {title}
        </Title>
      }
      <Accordion allowMultiple {...props}>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton bg={'none'} _hover={{ bg: 'none', color: 'black' }} _expanded={{ bg: 'none', color: 'white' }} _focus={{ boxShadow: 'none' }}>
                <Box display="flex" justifyContent={"space-between"} alignContent={"center"} textAlign={"left"} flex={1}>
                  <Box as='span' w={'49%'} onClick={(e) => { e.stopPropagation() }}>
                    {TypeSelectUC}
                  </Box>
                  {isExpanded ? (
                    <IconGeneric color='#3e3e3e' as={BiMinus} />
                  ) : (
                    <IconGeneric color='#3e3e3e' as={BiPlus} />
                  )}
                </Box>
              </AccordionButton>

              <AccordionPanel pb={4}>
                {children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default HeaderAccordion