/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import environment from "constants/apiConst";
import { useSelector, useDispatch } from "react-redux";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __GetItems, __ShowItems } from "request/Forms/_CrudForm";
import { Commentary } from "components/Commentary/Commentary";
import { useForm } from "hooks/useForm";
import { SubTitleHeader } from "components/HeaderForm/Style";
import { AuthContext } from "context/AuthContext";
import { CorrectionRestWorld } from "./containerCorrect/CorrectionRestWorld";
import { CorrectionRestTerritory } from "./containerCorrect/CorrectionRestTerritory";
import { CorrectFreeZone } from "./containerCorrect/CorrectFreeZone";
import { CorrectFromUserToUser } from "./containerCorrect/CorrectFromUserToUser";
import Pagination from "components/Pagination/Pagination";
import { TitleItemFmm, TitleItemFmmShow } from "../arrayHeadItems";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { ButtonStyled } from "styled/Buttons.styled";
import { CgPlayListAdd } from "react-icons/cg";
import { ForItemsCorrect } from "./ForItemsCorrect";
import { FormCorrectItems } from "./FormCorrectItems";
import "./Style.css";
import {
  restWorld,
  varFrezone,
  varRestTerritory,
  varUserToUser,
} from "./ArrayCorrect";
import { FormEntryHeader } from "./headerCorrectForm/FormEntryHeader";
import { FormCorrectEntry } from "./headerCorrectForm/FormCorrectEntry";
import { FormShowCorrect } from "./headerCorrectForm/FormShowCorrect";
import { __GetDataId } from "request/Petitions/__Get";
import LogsState from "./Logs/LogsState";
import ButtonView from "./Logs/BtnLog";
import { useModal } from "hooks/useModal";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import TBodyItemCorrect from "../Table/TBodyItemCorrect";
import TBodyItemCorrectShow from "../Table/TBodyItemCorrectShow";
import ModalDelete from "components/modal/ModalDelete";
import { HeaderSearch } from "styled/SectionContainer.styled";
import DocumentUploader from "../FormEntry/DocumentUploader/DocumentUploader";
import Vapor from "laravel-vapor";
import Swal from "sweetalert2";
import { AttchmentTypiFied } from "components/attchmentTypiFied/AttchmentTypiFied";
import { TitleOperations } from "../TitleOperation/TitleOperations";
import { BtnWithToltip } from "components/BtnWithToltip/BtnWithToltip";
import { ExportPdfEntryCorrect } from "../Export/pdfCorrect/ExportPdfEntryCorrect";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { SubTitle } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useFormatQuantity } from "hooks/useFormatQuantity";
import { CapsulaContainer } from "./CapsulaContainer";
import TablePass from "components/Tables/TablesPass";
import { __OperationListCorrected } from "request/configuration/__Operations";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import { __Post } from "request/Petitions/__Post";
import { parseFloat } from "utils/parseFloat";
import {
  setFormData,
  clearFormData,
  getFormData,
} from "redux/actions/dataFormActions";
import { useFormBlocker } from "hooks/useFormBlocker";
import useStatusListener from "hooks/useStatusListener";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  checkProperty,
  isArray,
  isNonEmptyString,
  isNumber,
  isNumberFormatable,
  isObject,
  isObjectNotEmpty,
  isString,
  isValid,
} from "utils/type-check-utils";
import { createKey, createObjectFromItem } from "../FormExit/BodyFormExit";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { Storage } from "hooks/useStorage";
import { GroupInputUpload } from "../GroupInputUpload";
import InputSelect from "components/InputSelect/InputSelect";
import { FormBalanceAdjustmentLog } from "../FormEntry/FormBalanceAdjustmentLog";
import EnhancedFileUploadManager from "pages/CustomsClearance/MultipleFileUploader";
import { convertArrayToGroupedObject } from "../FormEntry/BodyForm";
import ExistingFiles from "pages/CustomsClearance/Adjunto/ExistingFiles";
import FileUploader from "../FormEntry/DocumentUploader/FileUploader";
import CreateAt from "../View/CreateAt";

const errorsDefaultState = {
  product_quantity_corrected: false,
  typeProductCorrected_id: false,
  transportMethodCorrected_id: false,
  packagingCorrected_id: false,
  countryOriginCorrected_id: false,
  countryProcedencyCorrected_id: false,
  flagCorrected_id: false,
  countryPurchaseCorrected_id: false,
  productCorrected_id: false,
  gross_weight_corrected: false,
  net_weight_corrected: false,
  us_freight_corrected: false,
};

export const extensionOptions = [
  ".doc",
  ".docx",
  ".pdf",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".bmp",
  ".xls",
  ".xlsx",
  ".xlsm",
  "image/png",
  "image/*",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/vnd.ms-excel",
];

export function cleanFormData(formData) {
  const cleanedFormData = new FormData();

  for (const [key, value] of formData.entries()) {
    // Convertimos el valor a string para poder comparar con 'null' y 'undefined'
    const stringValue = String(value).trim();

    // Verificamos si el valor no es nulo, indefinido, vacío, o los strings 'null' o 'undefined'
    if (
      value != null &&
      stringValue !== "" &&
      stringValue.toLowerCase() !== "null" &&
      stringValue.toLowerCase() !== "undefined"
    ) {
      // Si el valor es un objeto File, lo agregamos directamente
      if (value instanceof File) {
        cleanedFormData.append(key, value);
      } else {
        // Para otros tipos de datos, agregamos el valor trimmeado sin convertir a minúsculas
        cleanedFormData.append(key, stringValue);
      }
    }
  }

  return cleanedFormData;
}
const CorrectionForm = () => {
  const navigate = useNavigate();
  const [nameFiles, setNameFiles] = useState([]);
  const [quantityError, setquantityError] = useState([]);
  const [errorsState, setErrorsState] = useState(errorsDefaultState);
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [isLoadingShow, setIsLoadingShow] = useState(false);
  const [otherFiles, setOtherFiles] = useState([]);
  const [listFiles, setListFiles] = useState([]);

  const location = useLocation(); // navigate
  const { FormEntry = null, ShowEntry = null } = queryString.parse(
    location.search
  );
  const { token, decimal_values_precision, is_commerce } = useSelector(
    (state) => state.auth.dataUser
  );

  const dataForm = useSelector(getFormData);
  const { authUser } = useContext(AuthContext);
  const [isOpenWarning, onOpenWarning, onCloseWarning] = useModal();
  const [suma, setSuma] = useState(0);
  const [multiplicacion, setMultiplicacion] = useState(0);
  const [valorDolarActual, setValorDolarActual] = useState(null);
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [commentarios, setComentarios] = useState([]);
  const [code, setCode] = useState(null);
  // state collapse
  const [stateCollapse, setstateCollapse] = useState(true);
  // state collapse fin
  const [attachment, setAttachment] = useState([]);
  const [attachmentType, setAttachmentType] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const dispacthRedux = useDispatch();
  const { setItemsCorrectionForm } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const initialForm = {
    qualifiedUser_id: "",
    num_dex: "",
    dex_date: "",
    exit_auth: "",
    inspection: "",
    operation_id: "",
    cormagdalena: "",
    department_id: "",
    num_exit_form: "",
    thirdPartie_id: "",
    code_entryForm: "",
    purchase_order: "",
    transp_doc_date: "",
    qualifiedUser2_id: "",
    shipment_entryForm: "",
    dcl_customs_transit: "",
    num_transport_document: "",
    num_commercial_invoice: "",
    num_import_declaration: "",
    customs_administration: "",
    inspection_act_or_auto: "",
    transit_expiration_date: "",
    cargo_manifest_entryForm: "",
    num_exit_form: "",
    integration_box_certificate: "",
    date_entryForm: "",
    status_entryForm: "",
    name_qualified: "",
    comments: "",
  };

  // const fieldAllowed = ["product_quantity_corrected"];

  const onFieldChange = (name, newValue, target) => {
    const parsedValue = parseFloat(newValue);
    if (ShowEntry) return;

    if (!valuesItems?.factor_conv_corrected) return;
    if (name === "product_quantity_corrected") {
      return setValuesItems((prev) => ({
        ...prev,
        quantity_corrected:
          parsedValue * parseFloat(valuesItems?.factor_conv_corrected),
        ...(valuesItems?.code_businessUnit_corrected === "KG" && {
          gross_weight_corrected:
            parsedValue * parseFloat(valuesItems?.factor_conv_corrected),
          net_weight_corrected:
            parsedValue * parseFloat(valuesItems?.factor_conv_corrected),
        }),
      }));
    }
    if (name === "factor_conv_corrected") {
      return setValuesItems((prev) => ({
        ...prev,
        quantity_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItems?.product_quantity_corrected),
        gross_weight_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItems?.product_quantity_corrected),
        net_weight_corrected:
          parseFloat(newValue) *
          parseFloat(valuesItems?.product_quantity_corrected),
      }));
    }
  };

  const [form, setForm] = useState(initialForm);
  const [fomateo] = useFormatQuantity();
  const [pesosToDollars, setPesosToDollars] = useState(0);
  const [idQualified, setIdQualified] = useState();

  const [values, handleInputChange, reset, setValues] = useForm({});
  const [valuesItems, handleInputChangeItems, resetItems, setValuesItems] =
    useForm(
      {
        product_unit: "",
        factor_conv: "",
        product_description: "",
        item_temporary_exit: "",
        gross_weight_sdo: "",
        amount_sdo: "",
        fob_sdo_value: "",
        cif_value_sdo: "",
        description_subheading: "",
        coin: "",
        number_packages: "",
        exchange_rate: "",
        entry_form_item_description: "",
        code_subheading: "",
        code_businessUnit: "",
        product_quantity: 0,
        gross_weight: 0,
        net_weight: 0,
        quantity: 0,
        us_freight: 0,
        us_insurance: 0,
        other_us_expenses: 0,
        us_fob_value: 0,
        cif_us_value: 0,
        cif_cop_value: 0,
        product_quantity_corrected: 0,
        gross_weight_corrected: 0,
        net_weight_corrected: 0,
        quantity_corrected: 0,
        us_freight_corrected: 0,
        us_insurance_corrected: 0,
        other_us_expenses_corrected: 0,
        us_fob_value_corrected: 0,
        cif_us_value_corrected: 0,
        cif_cop_value_corrected: 0,
        code_product: "",
        code_country_proc: "",
        name_country_proc: "",
        code_country: "",
        name_country: "",
        code_country_purs: "",
        name_country_purs: "",
        code_transportMethod: "",
        name_transportMethod: "",
        code_contry_flag: "",
        name_country_flag: "",
        name_packaging: "",
        code_packaging: "",
        code_tradeAgreement: "",
        name_tradeAgreement: "",
        factor_conv_corrected: "",
        code_businessUnit_corrected: "",
        fob_sdo_value_corrected: "",
        gross_weight_sdo_corrected: "",
        amount_sdo_corrected: "",
        item_temporary_exit_corrected: "",
        code_subheading_corrected: "",
        description_subheading_corrected: "",
        number_packages_corrected: "",
        exchange_rate_corrected: "",
        product_description_corrected: "",
        entry_form_item_description_corrected: "",
      },
      null,
      onFieldChange
    );

  const [
    valuesCorrect,
    handleInputChangeCorrect,
    resetCorrect,
    setValuesCorrect,
  ] = useForm({ num_exit_form_corrected: "" });

  const {
    setRequestChangeStatusForm,
    deleteAttachment,
    createCommentary,
    update,
    newCommentary,
    getThirdParties,
    dataThird,
    dataOperation,
    getOperationNotPaginate,
    setRequestDelete,
    dataPackagings,
    dataTransportMethod,
    dataAgreements,
    getAcuerdosC,
    getCountry,
    getPackaging,
    getMethodTransport,
    getCodeProduct,
    setDataPackagingsNew,
    setDataTransportMethodNew,
    setDataAgreementsNew,
    setValueProducts,
    setInfo,
    setDataCountryOrigin,
    setDataCountryBuy,
    setValueFlag,
    setPackagings,
    setValueMethod,
    setAcuerdos,
    dataToEditItems,
    dataToCopyItems,
    dataDepartament,
    setDataDepartamentNew,
    getDepartament,
    getUserQualified,
    userQualified,
    StateCountry,
    setUserQuialifiedNew,
    StateNit,
    selectExport,
    setselectExport,
    setStateCurrentExport,
    setStateCurrent,
    selectOperation,
    setselectOperation,
    selectEmbarcacion,
    setselectEmbarcacion,
    setDataSelectOperation,
    setDataSelectExport,
    updateCorrectDocument,
    valueProducts,
    setValueSubheading,
    allProducts,
    setDataToEditItems,
    typeProduct,
    setSelectDataTypeProduct,
    selectDataTypeProduct,
    info,
    dataCountryOrigin,
    dataCountryBuy,
    valueMethod,
    valueFlag,
    packagings,
    acuerdos,
    valueSubheading,
    CorrectItem,
    isOpenDelete,
    onCloseModal,
    deleteItemsCorrect,
    setDataToCopyItems,
    dataToDelete,
    setStateCountry,
    setStateNit,
    setPortMCorrect,
    setInspeccionCorrect,
    portMCorrect,
    inspeccionCorrect,
    setTypeEntry,
    setTypeEntryCurrent,
    typeEntry,
    selectNE,
    setSelectNE,
    setSelectCurrency,
    selectCurrency,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    isOpenRecuperar,
    onOpenRecuperar,
    onCloseRecuperar,
    dataRecuperar,
    changeStatusCorregido,
    userQualifiedNew,
    setbalanceCorrecedState,
    setUpdate,
    setCustomsadministration,
    customsadministration,
  } = useContext(FormCrudCRUDContext);

  const [DocumentOperation, setDocumentOperation] = useState([]);

  const {
    closeModal: onCloseUpload,
    openModal: onOpenUpload,
    isOpen: isOpenUpload,
  } = useConfirmationModal();

  const workDirty = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const outputObject = convertArrayToGroupedObject(data?.files || []);
      setListFiles(outputObject);

      const fromServer = data.files?.reduce((accumulator, item, index) => {
        const key = createKey(item.documentReq_id, item?.doc_corrected_req);
        accumulator[key] = createObjectFromItem(item, index);
        return accumulator;
      }, {});

      setFiles(Object.assign({}, files, fromServer));
      setDataCorrected((prev) => ({
        ...prev,
        files: Object.assign({}, files, fromServer),
      }));
      setDeleteFile(false);
      return;
    }

    handleCancelCabecera();
    setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMI_CORRECT);

    const {
      code_operation,
      qualifiedUser_id,
      dcl_customs_transit_corrected,
      transit_expiration_date_corrected,
      num_transport_document_corrected,
      transp_doc_date_corrected,
      cargo_manifest_entryFormCorrected,
      num_commercial_invoice_corrected,
      num_dex_entryFormCorrected,
      dex_date_entryFormCorrected,
      num_import_declaration_corrected,
      customs_administration_corrected,
      exit_auth_entryFormCorrected,
      integration_box_certificate_corrected,
      num_exit_form_corrected,
      shipment_entryFormCorrected,
      code_operationCorrected,
      name_departmentCorrected,
      departmentCorrected_id,
      nit_qualified4,
      name_qualified4,
      qualifiedUser4_id,
      documents,
      purchase_order_corrected,
      portmagdalena_corrected,
      inspection_corrected,
      check_rm_corrected,
      check_rm,
      shipping_form_corrected,
      cabotage_corrected,
      shipping_form_expiration_date_corrected,
      cabotage_expiration_date_corrected,
      national_or_foreign_corrected,
      balance_correction,
      customAdministrationCorrected_id,
      code_customCorrected,
      name_customCorrected,
      code_departmentCorrected,
      identification_numCorrected
    } = data || {};

    setDocumentOperation(documents);

    const fromServer = data.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));

    const outputObject = convertArrayToGroupedObject(data?.files || []);
    setListFiles(outputObject);

    setDataCorrected((prev) => ({
      ...prev,
      files: Object.assign({}, files, fromServer),
    }));

    const otherFiles = convertArrayToGroupedObject(
      data?.other_files || [],
      "other_files"
    );

    setOtherFiles(otherFiles);

    if (check_rm_corrected) {
      setTypeEntry(check_rm_corrected);
    } else {
      setTypeEntry("DTA");
    }
    if (check_rm) {
      setTypeEntryCurrent(check_rm_corrected);
    } else {
      setTypeEntryCurrent("DTA");
    }

    setForm(data);
    if (balance_correction === 1) {
      setbalanceCorrecedState(balance_correction);
    } else {
      setbalanceCorrecedState(null);
    }
    setValues(data);
    setCode(parseInt(code_operation));
    getThirdParties(qualifiedUser_id);
    getDepartament();
    getUserQualified();

    setselectEmbarcacion({
      label: shipment_entryFormCorrected,
      value: shipment_entryFormCorrected,
    });

    if (customAdministrationCorrected_id) {
      setCustomsadministration({
        label: code_customCorrected + " " + name_customCorrected,
        value: customAdministrationCorrected_id,
      });
    }
    if (qualifiedUser_id) {
      setIdQualified(qualifiedUser_id);
      getCodeProduct(qualifiedUser_id);
    }

    if (qualifiedUser_id && authUser.is_qualified_user !== 1) {
      getOperationNotPaginate(qualifiedUser_id);
    } else if (authUser.is_qualified_user === 1) {
      getOperationNotPaginate(qualifiedUser_id);
    }

    if (portmagdalena_corrected === 0) {
      setPortMCorrect(false);
    } else {
      setPortMCorrect(true);
    }

    if (inspection_corrected === 0) {
      setInspeccionCorrect(false);
    } else {
      setInspeccionCorrect(true);
    }

    if (code_operationCorrected >= 101 && code_operationCorrected < 120) {
      setValuesCorrect({
        ...valuesCorrect,
        inspection_corrected: inspection_corrected || "",
        purchase_order_corrected: purchase_order_corrected || "",
        portmagdalena_corrected: portmagdalena_corrected || "",
        nit_business_nameCorrected: identification_numCorrected,
        shipping_form_corrected,
        cabotage_corrected,
        shipping_form_expiration_date_corrected,
        cabotage_expiration_date_corrected,
        shipment_entryFormCorrected: shipment_entryFormCorrected || "",
        dcl_customs_transit_corrected: dcl_customs_transit_corrected || "",
        transit_expiration_date_corrected:
          transit_expiration_date_corrected || "",
        num_transport_document_corrected:
          num_transport_document_corrected || "",
        transp_doc_date_corrected: transp_doc_date_corrected || "",
        cargo_manifest_entryFormCorrected:
          cargo_manifest_entryFormCorrected || "",
        num_commercial_invoice_corrected:
          num_commercial_invoice_corrected || "",
        integration_box_certificate_corrected:
          integration_box_certificate_corrected || "",
        ...data,
      });
    } else if (
      code_operationCorrected >= 301 &&
      code_operationCorrected < 330
    ) {
      setStateCountry({
        value: departmentCorrected_id,
        label: code_departmentCorrected + " " + name_departmentCorrected,
      });
      setValuesCorrect({
        ...valuesCorrect,
        purchase_order_corrected: purchase_order_corrected || "",
        inspection_corrected: inspection_corrected || "",
        nit_business_nameCorrected: identification_numCorrected,
        portmagdalena_corrected: portmagdalena_corrected || "",
        shipment_entryFormCorrected: shipment_entryFormCorrected || "",
        name_departmentCorrected: name_departmentCorrected || "",
        num_dex_entryFormCorrected: num_dex_entryFormCorrected || "",
        dex_date_entryFormCorrected: dex_date_entryFormCorrected || "",
        num_import_declaration_corrected:
          num_import_declaration_corrected || "",
        integration_box_certificate_corrected:
          integration_box_certificate_corrected || "",
        num_commercial_invoice_corrected:
          num_commercial_invoice_corrected || "",
        customs_administration_corrected:
          customs_administration_corrected || "",
        ...data,
      });
    } else if (
      code_operationCorrected >= 501 &&
      code_operationCorrected < 518
    ) {
      setSelectNE(national_or_foreign_corrected);

      setValuesCorrect({
        ...valuesCorrect,
        purchase_order_corrected: purchase_order_corrected || "",
        inspection_corrected: inspection_corrected || "",
        nit_business_nameCorrected: identification_numCorrected,
        portmagdalena_corrected,

        shipment_entryFormCorrected,
        num_dex_entryFormCorrected: num_dex_entryFormCorrected || "",
        num_commercial_invoice_corrected:
          num_commercial_invoice_corrected || "",
        exit_auth_entryFormCorrected: exit_auth_entryFormCorrected || "",
        integration_box_certificate_corrected:
          integration_box_certificate_corrected || "",
        dcl_customs_transit_corrected: dcl_customs_transit_corrected || "",
        transit_expiration_date_corrected:
          transit_expiration_date_corrected || "",
        ...data,
      });
    } else if (
      code_operationCorrected >= 701 &&
      code_operationCorrected < 714
    ) {
      setStateNit({
        label: `${nit_qualified4} ${name_qualified4}`,
        value: qualifiedUser4_id,
      });
      setValuesCorrect({
        ...valuesCorrect,
        qualifiedUser4_id: qualifiedUser4_id || "",
        purchase_order_corrected: purchase_order_corrected || "",
        nit_business_nameCorrected: identification_numCorrected,
        inspection_corrected: inspection_corrected || "",
        portmagdalena_corrected: portmagdalena_corrected || "",
        shipment_entryFormCorrected,
        num_exit_form_corrected: num_exit_form_corrected || "",
        ...data,
      });
    } else {
      setValuesCorrect({
        ...valuesCorrect,
        purchase_order_corrected: purchase_order_corrected || "",
        inspection_corrected: inspection_corrected || "",
        nit_business_nameCorrected: identification_numCorrected,
        portmagdalena_corrected,
        shipment_entryFormCorrected,
        ...data,
      });
    }
  };
  const { requestData } = useGlobalContext();
  const [deleteFile, setDeleteFile] = useState(false);

  const {
    data: dataViewFormCorrect,
    isFetching: isFetchingViewFormCorrect,
    isSuccess: isSuccessViewFormCorrect,
    isError: isErrorViewFormCorrect,
    status: statusViewFormCorrect,
  } = useQuery({
    queryKey: ["viewFormCorrected", FormEntry, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.CORRECT_FMMI_SHOW,
        resourceIdentifier: ShowEntry || FormEntry,
      }),
    refetchOnWindowFocus: false,
  });

  const {
    data: dataViewFiles,
    isFetching: isFetchingViewFiles,
    isSuccess: isSuccessViewFiles,
    isError: isErrorViewFiles,
    status: statusViewFiles,
  } = useQuery({
    queryKey: ["ViewFiles", FormEntry, ShowEntry, update],
    queryFn: () =>
      requestData({
        Endpoint: environment.CORRECT_FMMI_SHOW,
        resourceIdentifier: ShowEntry || FormEntry,
      }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const dataFormCurrent = dataViewFormCorrect?.data?.status;

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect && deleteFile) {
      workDirty(dataFormCurrent?.data, true);
      return;
    }

    if (isErrorViewFormCorrect) {
      openAlert(dataFormCurrent?.data?.status.message, "error");
      navigate(-1);
      return;
    }

    if (isSuccessViewFormCorrect && !isFetchingViewFormCorrect) {
      const { data } = dataFormCurrent;

      dispacthRedux(
        setFormData({
          ...data,
          precision: Number(data?.precision),
        })
      );
      if (
        FormEntry &&
        (data?.status_entryFormCorrected === "BORRADOR" ||
          data?.status_entryFormCorrected === "DEVUELTO")
      ) {
        setLoading(false);
        workDirty(data);
      } else {
        if (FormEntry) {
          openAlert(
            `El formulario ${data?.code_entryFormCorrected} esta en estado ${data?.status_entryFormCorrected} y no puede ser editado`,
            "error"
          );
        }
        if (data?.id)
          navigate(`/FormCorrection?ShowEntry=${FormEntry || ShowEntry}`);
        setLoading(false);
        workDirty(data);
      }
    }
  }, [isFetchingViewFormCorrect, statusViewFormCorrect, dataViewFormCorrect]);

  useEffect(() => {
    return async () => dispacthRedux(clearFormData());
  }, []);

  const getItems = async (idEntry) => {
    const data = {
      entryFormCorrected_id: idEntry,
      ...(orderBy && { orderBy: orderBy?.value }),
      length: 10,
    };

    setLoadingItems(true);
    setRequestDelete(environment.DELECT_CORRECT_ITEM);
    try {
      const res = await __GetItems(
        token,
        data,
        page,
        environment.GET_CORRECT_ITEM
      );
      const response = res?.data?.status;
      if (response?.code === 200) {
        setCurrentPage(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        setItemsCorrectionForm({
          data: res?.data?.status?.data?.data,
          page: res?.data?.status?.data?.last_page,
        });
      } else {
        /* console.log({
          endpoint: environment.GET_CORRECT_ITEM,
          stack: `CorrectionForm:688`,
          data,
          res,
        }); */
        openAlert(`Error al cargar items`, "error");
      }
    } catch (error) {
    } finally {
      setLoadingItems(false);
    }
  };
  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async () => {
    try {
      const res = await __CommentaryForm(
        token,
        FormEntry ? Number(FormEntry) : ShowEntry ? Number(ShowEntry) : "",
        environment.CORRECT_GET_COMMENT_FMM_ENTRY
      );
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) {}
  };

  const showItems = async (id) => {
    setIsLoadingShow(true);
    try {
      const res = await __ShowItems(token, id, environment.SHOW_CORRECT_ITEM);
      const response = res?.data?.status;
      const { code, data } = response;

      if (code === 200) {
        const {
          code_product_corrected,
          productCorrected_id,
          code_country_ori_corrected,
          name_country_ori_corrected,
          countryOriginCorrected_id,
          code_country_proc_corrected,
          name_country_proc_corrected,
          countryProcedencyCorrected_id,
          code_country_purs_corrected,
          name_country_purs_corrected,
          countryPurchaseCorrected_id,
          code_contry_flag_corrected,
          name_country_flag_corrected,
          flagCorrected_id,
          code_packaging_corrected,
          name_packaging_corrected,
          packagingCorrected_id,
          code_transportMethod_corrected,
          name_transportMethod_corrected,
          transportMethodCorrected_id,
          code_tradeAgreement_corrected,
          name_tradeAgreement_corrected,
          tradeAgreementCorrected_id,
          name_typeProduct_corrected,
          typeProductCorrected_id,
          subheadingCorrected_id,
          coin_corrected,
        } = data;
        const optionsNational = {
          NAC: "Nacional",
          EXT: "Extranjera",
        };

        // quedamos en el show de items y eliminar

        setValuesItems({
          ...data,
          invoice_corrected: data?.invoice_corrected,
          factor_conv_corrected: parseFloat(data?.factor_conv_corrected),
          product_quantity_corrected: parseFloat(
            data?.product_quantity_corrected
          ),
          quantity_corrected: parseFloat(data?.quantity_corrected),
          gross_weight_corrected: parseFloat(data?.gross_weight_corrected),
          net_weight_corrected: parseFloat(data?.net_weight_corrected),
          number_packages_corrected: parseFloat(
            data?.number_packages_corrected
          ),
          us_freight_corrected: parseFloat(data?.us_freight_corrected),
          us_insurance_corrected: parseFloat(data?.us_insurance_corrected),
          other_us_expenses_corrected: parseFloat(
            data?.other_us_expenses_corrected
          ),
          us_fob_value_corrected: parseFloat(data?.us_fob_value_corrected),
          cif_us_value: parseFloat(data?.cif_us_value),
          cif_cop_value: parseFloat(data?.cif_cop_value),
          exchange_rate_corrected: parseFloat(data?.exchange_rate_corrected),
          national_or_foreign_corrected:
            optionsNational[data?.national_or_foreign_corrected || ""] || null,
          national_or_foreign:
            optionsNational[data?.national_or_foreign || ""] || null,
        });

        setstateCollapse(false);
        const obtenerErrorItemCurrent = JSON.parse(
          localStorage.getItem(`erroField-${id}`) || "[]"
        );

        if (obtenerErrorItemCurrent.length !== 0) {
          setErrorsState(obtenerErrorItemCurrent);
        }

        if (typeProductCorrected_id) {
          setSelectDataTypeProduct({
            label: name_typeProduct_corrected,
            value: typeProductCorrected_id,
          });
        }

        if (productCorrected_id) {
          setValueProducts({
            label: code_product_corrected ?? "",
            value: productCorrected_id ?? "",
          });
        }
        if (countryOriginCorrected_id) {
          setInfo({
            label:
              code_country_ori_corrected + " " + name_country_ori_corrected,
            value: countryOriginCorrected_id,
          });
        }

        if (countryProcedencyCorrected_id) {
          setDataCountryOrigin({
            label:
              code_country_proc_corrected + " " + name_country_proc_corrected,
            value: countryProcedencyCorrected_id,
          });
        }

        if (countryPurchaseCorrected_id) {
          setDataCountryBuy({
            label:
              code_country_purs_corrected + " " + name_country_purs_corrected,
            value: countryPurchaseCorrected_id,
          });
        }

        if (flagCorrected_id) {
          setValueFlag({
            label:
              code_contry_flag_corrected + " " + name_country_flag_corrected,
            value: flagCorrected_id,
          });
        }

        if (packagingCorrected_id) {
          setPackagings({
            label: code_packaging_corrected + " " + name_packaging_corrected,
            value: packagingCorrected_id,
          });
        }
        if (transportMethodCorrected_id) {
          setValueMethod({
            label:
              code_transportMethod_corrected +
              " " +
              name_transportMethod_corrected,
            value: transportMethodCorrected_id,
          });
        }

        if (code_tradeAgreement_corrected) {
          setAcuerdos({
            label:
              code_tradeAgreement_corrected +
              " " +
              name_tradeAgreement_corrected,
            value: tradeAgreementCorrected_id,
          });
        }
        setValueSubheading(subheadingCorrected_id);
        setSelectCurrency(coin_corrected);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingShow(false);
    }
  };

  const handleSubmit = (updateBlur) => {
    const infoCurrent = {};
    const newCorrect = {};
    //El delete ocasiona comportamiento inesperado, por lo que se debe cambiar a futuro.
    delete values.code_operationCorrected;
    delete values.operationCorrected_id;
    delete values.thirdPartieCorrected_id;
    delete values.files;
    delete values.users;
    delete infoCurrent.shipment_entryFormCorrected;
    delete values.documents;
    delete values.portmagdalena_corrected;
    delete values.inspection_corrected;
    delete values.purchase_order_corrected;
    delete values.check_rm_corrected;
    delete values.national_or_foreign_corrected;
    delete valuesCorrect.check_rm_corrected;
    delete valuesCorrect.customAdministrationCorrected_id;

    if (isObject(files)) {
      const fileFormat = Object.entries(files).map(([key, valor]) => {
        if (!valor) {
          return;
        }
        return (files[key] = valor);
      });

      if (isArray(fileFormat)) {
        const isValidDocument = checkProperty({
          obj: fileFormat,
          prop: "attachment_description",
          value: "Carta usuario",
          checkPropertyAndValue: true,
        });

        if (!isValidDocument) {
          return openAlert(
            "Es necesario adjuntar la carta de usuario.",
            "error"
          );
        }
      }
    }

    if (!selectExport) return openAlert("Seleccione un exportador", "error");

    Object.entries(values).map(([key, valor]) => {
      if (!valor) {
        return;
      }
      return (infoCurrent[key] = valor);
    });

    // dependiendo de la operacion todos los campos de esa operacion son requeridos
    // si funciona no lo toque, cuando hice este codigo estaba Dios conmigo solo el y yo sbaemos que hace
    if (selectOperation === null) {
      return openAlert("Seleccione una operación", "error");
    }
    Object.entries(valuesCorrect).map(([key, valor]) => {
      if (selectOperation.value >= 101 && selectOperation.value < 120) {
        restWorld.map((e) => {
          if (key === e && (valor !== "" || valor !== null)) {
            return (newCorrect[key] = valor);
          }
        });
        if (typeEntry === "DTA") {
          newCorrect.dcl_customs_transit_corrected =
            valuesCorrect.dcl_customs_transit_corrected ?? "";
          newCorrect.transit_expiration_date_corrected =
            valuesCorrect.transit_expiration_date_corrected ?? "";
          newCorrect.shipping_form_corrected = "";
          newCorrect.cabotage_corrected = "";
          newCorrect.shipping_form_expiration_date_corrected = "";
          newCorrect.cabotage_expiration_date_corrected = "";
        } else if (typeEntry === "Planilla de envío") {
          newCorrect.shipping_form_corrected =
            valuesCorrect.shipping_form_corrected ?? "";
          newCorrect.shipping_form_expiration_date_corrected =
            valuesCorrect.shipping_form_expiration_date_corrected ?? "";
          newCorrect.cabotage_corrected = "";
          newCorrect.dcl_customs_transit_corrected = "";
          newCorrect.transit_expiration_date_corrected = "";
          newCorrect.cabotage_expiration_date_corrected = "";
        } else {
          newCorrect.cabotage_corrected =
            valuesCorrect.cabotage_corrected ?? "";
          newCorrect.cabotage_expiration_date_corrected =
            valuesCorrect.cabotage_expiration_date_corrected ?? "";
          newCorrect.shipping_form_corrected = "";
          newCorrect.shipping_form_expiration_date_corrected = "";
          newCorrect.dcl_customs_transit_corrected = "";
          newCorrect.transit_expiration_date_corrected = "";
        }
      } else if (selectOperation.value >= 301 && selectOperation.value <= 330) {
        newCorrect.departmentCorrected_id = StateCountry?.value ?? "";
        newCorrect.customAdministrationCorrected_id =
          customsadministration?.value ?? "";

        varRestTerritory.map((e) => {
          if (
            (key === e && valor !== "null") ||
            valor !== null ||
            valor !== ""
          ) {
            return (newCorrect[key] = valor);
          }
        });
      } else if (
        selectOperation?.value >= 501 &&
        selectOperation?.value <= 518
      ) {
        varFrezone.map((e) => {
          if (key === e && (valor !== "" || valor !== null)) {
            return (newCorrect[key] = valor);
          }
        });
      } else if (
        selectOperation?.value >= 701 &&
        selectOperation?.value <= 714
      ) {
        newCorrect.qualifiedUser2Corrected_id = StateNit?.value ?? "";

        varUserToUser.map((e) => {
          if (key === e) {
            return (newCorrect[key] = valor);
          }
        });
      }
    });

    //  console.log("Before", valuesCorrect);
    const newObjetData = {
      ...infoCurrent,
      ...newCorrect,
      id: FormEntry || ShowEntry,
      num_dex_entryFormCorrected: valuesCorrect?.num_dex_entryFormCorrected,
      num_exit_form_corrected: valuesCorrect?.num_exit_form_corrected,
      operationCorrected_id: selectOperation?.id || "",
      thirdPartieCorrected_id: selectExport?.value || "",
      portmagdalena_corrected: portMCorrect === true ? 1 : 0,
      purchase_order_corrected: valuesCorrect.purchase_order_corrected
        ? valuesCorrect.purchase_order_corrected
        : "",
      check_rm_corrected: typeEntry,
      inspection_corrected: inspeccionCorrect === true ? 1 : 0,
      shipment_entryFormCorrected: selectEmbarcacion?.value || "",
    };

    const formData = new FormData();
    Object.entries(newObjetData).map(([key, valor]) => {
      formData.append(`${key}`, valor);
    });

    const validFormData = cleanFormData(formData);

    updateCorrectDocument(
      environment.CORRECT_FMMI_UPDATE,
      validFormData,
      handleCancelCabecera,
      updateBlur && updateBlur?.includes("/")
        ? updateBlur
        : "/FormCorrection?ShowEntry"
    );
  };

  const [otherDocuments, setOtherDocuments] = useState([]);
  const [files, setFiles] = useState({});

  const editUploadFiles = (data) => {
    const formData = new FormData();
    formData.append("id", FormEntry || ShowEntry);
    if (data?.id && !data?.doc_corrected_req)
      formData.append("attachment_id[]", data.id);

    data?.doc_corrected_req &&
      data?.id &&
      formData.append("document_corrected_id[]", data.id);

    if (isNonEmptyString(data?.attachment_comment)) {
      formData.append("attachment_comment[]", data.attachment_comment);
    }

    if (isNonEmptyString(data?.attachment_number)) {
      formData.append("attachment_number[]", data.attachment_number);
    }

    const date = data?.other_attachment_date || data?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    data?.attachment_date &&
      formData.append("attachment_date[]", data?.attachment_date);

    const validFormData = cleanFormData(formData);

    updateAndCreate(validFormData, true);
  };

  const updateAndCreate = async (
    data = undefined,
    payloadManual = false,
    isCorrected = null
  ) => {
    const formData = new FormData();
    formData.append("id", FormEntry || ShowEntry);
    const dataAttachments = attachment[0];
    const vaporRes = (await data) || null;

    dataAttachments?.id &&
      formData.append("other_attachment_id[]", dataAttachments?.id);
    const file = {
      file: vaporRes,
    };
    (dataAttachments?.id || dataAttachments?.other_attachment_description) &&
      formData.append(
        "other_attachment[]",
        JSON.stringify({ ...file, name: dataAttachments?.file?.name })
      );
    dataAttachments?.other_attachment_description &&
      formData.append(
        "other_attachment_description[]",
        dataAttachments?.other_attachment_description
      );
    dataAttachments?.other_attachment_comment &&
      formData.append(
        "other_attachment_comment[]",
        dataAttachments?.other_attachment_comment
      );

    dataAttachments?.other_attachment_number &&
      formData.append(
        "other_attachment_number[]",
        dataAttachments?.other_attachment_number
      );

    const date =
      dataAttachments?.other_attachment_date ||
      dataAttachments?.attachment_date;
    date && formData.append("other_attachment_date[]", date);

    dataAttachments?.attachment_date &&
      formData.append("attachment_date[]", dataAttachments?.attachment_date);

    dataAttachments?.extension &&
      formData.append("other_attachment_type[]", dataAttachments?.extension);

    if (isObject(data) && isObjectNotEmpty(data) && !payloadManual) {
      Object.keys(data).forEach((documentId) => {
        if (!data[documentId].from_server) {
          formData.append(
            isNumberFormatable(isCorrected) && Number(isCorrected) === 1
              ? "document_corrected[" + adjustDocumentName(documentId) + "]"
              : "attachment[" + documentId + "]",
            JSON.stringify(data[documentId])
          );
        }
      });
    }

    const validFormData = cleanFormData(formData);

    const requestData = {
      data: payloadManual ? data : validFormData,
      Endpoint: environment.CORRECT_FMMI_UPDATE_DOCUMENT,
      PropertyBody: "sendFormDataContentPOST",
    };

    //setOtherDocuments([]);
    setAttachment([]);
    setFiles({});

    mutate(requestData, {
      onSuccess: handleResponseUpdateForm,
    });
  };

  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: requestData,
  });

  const handleResponseUpdateForm = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });
      return;
    }
  };

  useEffect(() => {
    const dataFormCurrent = dataViewFiles?.data?.status;
    if (isSuccessViewFiles && !isFetchingViewFiles && deleteFile) {
      filesRead(dataFormCurrent?.data, true);
      return;
    }

    if (isErrorViewFiles) {
      openAlert(dataFormCurrent?.data?.status.message, "error");
      navigate(-1);
      return;
    }

    if (isSuccessViewFiles && !isFetchingViewFiles) {
      const { data } = dataFormCurrent;
      filesRead(data);
    }
  }, [isFetchingViewFiles, statusViewFiles, dataViewFiles]);

  const filesRead = (data, isUpdateFile) => {
    if (isUpdateFile) {
      const fromServer = data.files?.reduce((accumulator, item, index) => {
        const key = createKey(item.documentReq_id, item?.doc_corrected_req);
        accumulator[key] = createObjectFromItem(item, index);
        return accumulator;
      }, {});

      setFiles(Object.assign({}, files, fromServer));

      const outputObject = convertArrayToGroupedObject(data?.files || []);
      setListFiles(outputObject);

      setDataCorrected((prev) => ({
        ...prev,
        files: Object.assign({}, files, fromServer),
      }));
      setDeleteFile(false);
      return;
    }

    const { documents } = data || {};
    setDocumentOperation(documents);

    const fromServer = data?.files?.reduce((accumulator, item, index) => {
      const key = createKey(item.documentReq_id, item?.doc_corrected_req);
      accumulator[key] = createObjectFromItem(item, index);
      return accumulator;
    }, {});

    setFiles(Object.assign({}, files, fromServer));
    setDataCorrected((prev) => ({
      ...prev,
      files: Object.assign({}, files, fromServer),
    }));
    const otherFiles = convertArrayToGroupedObject(
      data?.other_files || [],
      "other_files"
    );

    setOtherFiles(otherFiles);

    const outputObject = convertArrayToGroupedObject(data?.files || []);
    setListFiles(outputObject);
  };

  const {
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    product_quantity_corrected,
    factor_conv_corrected,
    code_businessUnit_corrected,
    code_subheading_corrected,
  } = valuesItems;

  const handleClean = () => {
    setInfo([]);
    setValueProducts(null);
    setValueSubheading([]);
    setDataCountryOrigin([]);
    setDataCountryOrigin([]);
    setValueMethod([]);
    setDataCountryBuy([]);
    setValueFlag([]);
    setPackagings([]);
    setAcuerdos([]);
    resetItems();
    // selectDataTypeProduct(null);
    // setUnidad([]);
  };

  const handleCancel = () => {
    setstateCollapse(!stateCollapse);
    setDataToEditItems(null);
    setDataToCopyItems(null);
    handleClean();
    setquantityError([]);

    /* Reset selected data */
    setSelectDataTypeProduct(null);
    setInfo(null);
    setDataCountryOrigin(null);
    setDataCountryBuy(null);
    setValueMethod(null);
    setValueFlag(null);
    setPackagings(null);
    setSuma(0);
    setMultiplicacion(0);
    setAcuerdos(null);
    setPesosToDollars(0);
  };

  const handleInvoice = () => {
    const formData = new FormData();
    formData.append("id", FormEntry);
    formData.append(
      "num_commercial_invoice_corrected",
      form.num_commercial_invoice_corrected && valuesItems.invoice_corrected
        ? form.num_commercial_invoice_corrected +
            "," +
            valuesItems.invoice_corrected
        : !form.num_commercial_invoice_corrected &&
          valuesItems.invoice_corrected
        ? valuesItems.invoice_corrected
        : ""
    );
    const resultado = form.num_commercial_invoice_corrected
      ? form.num_commercial_invoice_corrected.split(",")
      : [];

    if (
      valuesItems.invoice_corrected &&
      !resultado.includes(valuesItems.invoice_corrected)
    ) {
      updateCorrectDocument(
        environment.CORRECT_FMMI_UPDATE_DOCUMENT,
        formData,
        handleUpdate,
        "/FormCorrection?FormEntry"
      );
    }
  };

  const handleUpdate = () => {
    setUpdate(!update);
  };

  const handleCancelCabecera = () => {
    setAttachment([]);
    setAttachmentType([]);
    setNameFiles([]);
    handleInvoice();
    setstateCollapse(true);
  };

  const optionsNational = {
    Nacional: "NAC",
    Extranjera: "EXT",
  };

  const handleValidationOnSaveItem = (valuesItems, validations = []) => {
    const isError = validations.some((validation) => {
      if (!valuesItems?.[validation?.name]) {
        openAlert(`El campo ${validation?.value} es requerido`, "error");
        return true;
      }
    });
    return isError;
  };

  const hadleSaveItems = () => {
    const dataSave = {
      ...valuesItems,
      coin_corrected: selectCurrency,
      entryFormCorrected_id: FormEntry || ShowEntry || "",
      productCorrected_id: valueProducts?.value || "",
      typeProductCorrected_id: selectDataTypeProduct?.value || "",
      countryProcedencyCorrected_id: dataCountryOrigin?.value || "",
      countryOriginCorrected_id: info?.value || "",
      countryPurchaseCorrected_id: dataCountryBuy?.value || "",
      transportMethodCorrected_id: valueMethod?.value || "",
      flagCorrected_id: valueFlag ? valueFlag.value : "",
      packagingCorrected_id: packagings?.value || "",
      subheadingCorrected_id: Array.isArray(valueSubheading)
        ? ""
        : valueSubheading,
      countryDestinyCorrected_id: 1,
      tradeAgreementCorrected_id: acuerdos?.value || "",
      status_item: 1,
      operationCorrected_id: selectOperation.value,

      net_weight_corrected: parseFloat(valuesItems.net_weight_corrected),
      gross_weight_corrected: parseFloat(valuesItems.gross_weight_corrected),
      cif_value_sdo_corrected: parseFloat(valuesItems.cif_value_sdo_corrected),
      fob_sdo_value_corrected: parseFloat(valuesItems.fob_sdo_value_corrected),
      product_quantity_corrected: parseFloat(
        valuesItems.product_quantity_corrected
      ),
      quantity_corrected: parseFloat(valuesItems.quantity_corrected),
      number_packages_corrected: parseFloat(
        valuesItems.number_packages_corrected
      ),
      number_packages: parseFloat(valuesItems.number_packages),
      us_freight_corrected: parseFloat(valuesItems.us_freight_corrected),
      us_insurance_corrected: parseFloat(valuesItems.us_insurance_corrected),
      other_us_expenses_corrected: parseFloat(
        valuesItems.other_us_expenses_corrected
      ),

      us_fob_value_corrected: parseFloat(valuesItems?.us_fob_value_corrected),

      cif_us_value_corrected: parseFloat(pesosToDollars).toFixed(
        dataForm?.precision || decimal_values_precision || 2
      ),
      cif_cop_value_corrected: multiplicacion,
      cif_cop_value: parseFloat(valuesItems.cif_cop_value),
      cif_us_value: parseFloat(valuesItems.cif_us_value).toFixed(
        dataForm?.precision || decimal_values_precision || 2
      ),
      exchange_rate: parseFloat(valuesItems.exchange_rate),
      exchange_rate_corrected: parseFloat(valuesItems.exchange_rate_corrected),
      gross_weight: parseFloat(valuesItems.gross_weight),
      other_us_expenses: parseFloat(valuesItems.other_us_expenses),
      product_quantity: parseFloat(valuesItems.product_quantity),
      quantity: parseFloat(valuesItems.quantity),
      us_insurance: parseFloat(valuesItems.us_insurance),
      us_fob_value: parseFloat(valuesItems.us_fob_value),
      net_weight: parseFloat(valuesItems.net_weight),
      us_freight: parseFloat(valuesItems.us_freight),
      national_or_foreign:
        optionsNational[valuesItems.national_or_foreign || ""] || null,
      national_or_foreign_corrected:
        optionsNational[valuesItems.national_or_foreign_corrected || ""] ||
        null,
    };

    if (
      handleValidationOnSaveItem(dataSave, [
        { name: "typeProductCorrected_id", value: "Tipo de producto" },
        { name: "product_quantity_corrected", value: "Cantidad de producto" },
        { name: "countryOriginCorrected_id", value: "País de origen" },
        { name: "countryProcedencyCorrected_id", value: "País de procedencia" },
        { name: "countryPurchaseCorrected_id", value: "País de compra" },
        { name: "transportMethodCorrected_id", value: "Medio de transporte" },
        { name: "flagCorrected_id", value: "Bandera" },
        { name: "packagingCorrected_id", value: "Embalaje" },
        { name: "number_packages_corrected", value: "Número de bultos" },
        { name: "us_fob_value_corrected", value: "Valor FOB " },
      ])
    ) {
      return;
    }

    if (
      dataForm?.code_operationCorrected >= 500 &&
      dataForm?.code_operationCorrected < 600 &&
      !dataSave?.national_or_foreign_corrected
    ) {
      return openAlert(
        "Seleccione una naturaleza (Nacional o extranjera)",
        "error"
      );
    }

    if (dataSave?.gross_weight_corrected < dataSave?.net_weight_corrected) {
      return openAlert(
        "El peso bruto no puede ser menor al peso neto",
        "error"
      );
    }

    if (
      dataForm?.code_operationCorrected >= 100 &&
      dataForm?.code_operationCorrected < 200 &&
      !dataSave?.us_freight_corrected
    ) {
      return openAlert(
        "El valor del flete es obligatorio para las operaciones 10X",
        "warn"
      );
    }

    if (
      dataForm?.code_operationCorrected >= 100 &&
      dataForm?.code_operationCorrected < 200 &&
      !dataSave.us_insurance_corrected
    ) {
      return openAlert(
        "El valor del seguro es obligatorio para las operaciones 10X",
        "warn"
      );
    }

    /* return console.log({
      us_freight_corrected, us_insurance_corrected, other_us_expenses_corrected, us_fob_value_corrected,
      suma,
      multiplicacion,
      USD: {
        "Valor CIF USD": suma,
        "Valor CIF COP": multiplicacion,
      },
      COP: {
        "Valor CIF COP": valuesItems.cif_cop_value,
        cif_cop_value_corrected: suma,

      },
    }) */
    const obtenerErrorItem = JSON.parse(
      localStorage.getItem(`erroItem-${FormEntry}`) || "[]"
    );
    const result = obtenerErrorItem?.filter((e) => e !== dataToEditItems?.id);
    localStorage.setItem(`erroItem-${FormEntry}`, JSON.stringify(result));
    let message;

    if (dataToEditItems) {
      message = "Item actualizado con exito";
      CorrectItem(
        environment.UPDATE_CORRECT_ITEM,
        dataSave,
        handleCancel,
        message,
        setquantityError
      );
    } else if (dataToCopyItems) {
      message = "Item copiado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM,
        dataSave,
        handleCancel,
        message
      );
    } else {
      message = "Item creado con exito";
      CorrectItem(
        environment.CREATE_CORRECT_ITEM,
        dataSave,
        handleCancel,
        message
      );
    }
  };

  const handleFiles = (e, idFiles, type) => {
    e.preventDefault();

    document.getElementById(`${idFiles}`).click();
  };

  const { get: getStorage } = Storage;

  function validateFileExtension(file) {
    const getFileExtension = file.name.split(".").pop();
    const fileExtension = isString(getFileExtension)
      ? getFileExtension.toLowerCase()
      : getFileExtension || "";

    const isValidExtension = extensionOptions.includes(`.${fileExtension}`);
    if (!isValidExtension) {
      openAlert(
        `El archivo ${file.name} no es un tipo de archivo permitido.`,
        "error"
      );
    }
    return isValidExtension;
  }

  const extractDocumentId = (id) => {
    return Number(id.replace("document-", ""));
  };

  async function uploadFile(file) {
    return Vapor.store(file, {
      baseURL: environment.VAPOR_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const handleFilesInput = async (e) => {
    try {
      e.preventDefault();
      const resModal = await onOpenUpload();
      const { name, files: uploadedFiles, id } = e.target;
      const idFile = e?.target?.id;
      const uploadData = getStorage("uploadData")?.uploadData || {};

      if (!uploadedFiles.length) return;
      if (uploadData?.cancel) return localStorage.removeItem("uploadData");

      const isValidExtension = validateFileExtension(uploadedFiles[0]);

      if (!isValidExtension) return;

      const idDocument = extractDocumentId(adjustDocumentName(idFile));
      const uploadResponse = await uploadFile(uploadedFiles[0]);

      updateFileState(uploadResponse, uploadedFiles[0]?.name, idDocument, e);
      updateNameFilesState(name);
      clearLocalStorageErrors(idDocument);
    } catch (error) {
      console.error("Error handling file input:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  function adjustDocumentName(name) {
    const suffix = "_doc_corrected_req";
    if (name.includes(suffix)) {
      return name.replace(suffix, "");
    }
    return name;
  }

  const updateFileState = async (uploadResponse, name, idDocument, e) => {
    const uploadData = getStorage("uploadData")?.uploadData || {};
    const { iscorrected } = await e.target.dataset;

    const file = {
      [e.target.id]: {
        file: uploadResponse,
        name,
        document_id: idDocument,
        ...uploadData,
      },
    };

    if (isObjectNotEmpty(file)) updateAndCreate(file, false, iscorrected);

    return localStorage.removeItem("uploadData");
  };

  const updateNameFilesState = (name) => {
    setNameFiles((prevNameFiles) => [...prevNameFiles, { name }]);
  };

  const clearLocalStorageErrors = (idDocument) => {
    const errors = JSON.parse(
      localStorage.getItem(`errorAttadchment-${FormEntry}`) || "[]"
    );
    if (errors.includes(idDocument)) {
      const filteredErrors = errors.filter((e) => e !== idDocument);
      localStorage.setItem(
        `errorAttadchment-${FormEntry}`,
        JSON.stringify(filteredErrors)
      );
    }
  };



  const responseDeleteFile = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      setOtherFiles([]);
      //setOtherDocuments([]);
      queryClient.invalidateQueries({ queryKey: ["ViewFiles"] });

      return openAlert("El documento ha sido eliminado ", "error");
    }
  };

  const cleanFile = (file, doc_corrected_req, typeDoc) => {

    Swal.fire({
      title: `¿Estas seguro de eliminar el archivo "${
        file?.attachment_name ??
        file?.name_attachment ??
        file?.other_attachment_name
      }"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1F7AC3",
      confirmButtonText: "Eliminar",
      customClass: {
        title: "class-text",
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
  
        const IsTypeNewFile =
        doc_corrected_req === 1 ? "/doc_corrected_req" : "";
    
      const requestData = {
        Endpoint: typeDoc === 'attachment'
        ? environment.DELETE_ATTACHMENT_ENTRY_CORRECT
        : environment.DELETE_OTHER_ATTACHMENT_ENTRY_CORRECT,
        resourceIdentifier: `${file?.id}${IsTypeNewFile}`,
      };
  
      mutate(requestData, {
        onSuccess: (res) => responseDeleteFile(res),
      });

      
      }
    });
  };

  useEffect(() => {
    if (dataAgreements) {
      const infoPackagin = dataAgreements.map((item) => ({
        label: item?.code_tradeAgreement + " " + item?.name_tradeAgreement,
        value: item?.id,
      }));

      setDataAgreementsNew(infoPackagin);
    }
  }, [dataAgreements]);

  useEffect(() => {
    if (dataPackagings) {
      const infoPackagin = dataPackagings.map((item) => ({
        label: item?.code_packaging + " " + item?.name_packaging,
        value: item?.id,
      }));
      setDataPackagingsNew(infoPackagin);
    }
  }, [dataPackagings]);

  useEffect(() => {
    if (dataTransportMethod) {
      const infoPackagin = dataTransportMethod.map((item) => ({
        label: item?.code_transportMethod + " " + item?.name_transportMethod,
        value: item?.id,
      }));

      setDataTransportMethodNew(infoPackagin);
    }
  }, [dataTransportMethod]);

  useEffect(() => {
    if (userQualified) {
      const infoPackagin = userQualified.map((item) => ({
        label: item?.nit_qualified + " - " + item?.name_qualified,
        value: item?.id,
        decimal: item?.decimal_values_precision,
      }));

      setUserQuialifiedNew(infoPackagin);
    }
  }, [userQualified]);

  useEffect(() => {
    if (dataDepartament) {
      const infoPackagin = dataDepartament.map((item) => ({
        label: item?.code_department + " " + item?.name_department,
        value: item?.id,
      }));

      setDataDepartamentNew(infoPackagin);
    }
  }, [dataDepartament]);

  useEffect(() => {
    let isMounted = true;
    if (!stateCollapse) {
      getAcuerdosC();
      getCountry();
      getPackaging();
      getMethodTransport();
      typeProduct();
      // getTrm();
    }
    return () => {
      isMounted = false;
    };
  }, [stateCollapse]);

  useEffect(() => {
    if (isValid(form)) {
      setStateCurrent({
        ExporterSupplier: { id: form?.thirdPartie_id },
      });

      setStateCurrentExport(
        `${
          isValid(form?.identification_num)
            ? `${form?.identification_num} `
            : ""
        }${form?.business_name ?? ""}`
      );

      const labelForm = `${form?.identification_numCorrected || ""} ${
        form?.business_nameCorrected
      }`;

      setselectExport({
        label: labelForm,
        value: form?.thirdPartieCorrected_id,
      });

      setValuesCorrect({
        ...valuesCorrect,
        nit_business_nameCorrected: form?.identification_numCorrected,
        business_nameCorrected: form?.business_nameCorrected,
      });
    }
  }, [form]);

  useEffect(() => {
    if (dataOperation && form) {
      const resultadoCorrect = dataOperation.find(
        (e) => e.id === form?.operationCorrected_id
      );
      const resultadoSearch = dataOperation.find(
        (e) => e.code_operation === form?.code_operation
      );
      if (resultadoCorrect) {
        if (
          form?.balance_correction !== 1 ||
          form?.operationCorrected_id !== resultadoSearch?.id
        ) {
          setValuesCorrect({
            ...valuesCorrect,
            code_operationCorrected: resultadoCorrect.code_operation,
            short_name_operationCorrected:
              resultadoCorrect?.short_name_operation,
          });
          setselectOperation({
            label:
              resultadoCorrect?.code_operation +
              " " +
              resultadoCorrect?.short_name_operation,
            value: resultadoCorrect?.code_operation,
            id: resultadoCorrect?.id,
            documents:
              form?.balance_correction !== 1
                ? resultadoCorrect?.documents
                : DocumentOperation,
            plazo: resultadoCorrect?.term,
          });
        } else {
          setselectOperation(null);
        }
      } else {
        if (form?.balance_correction !== 1) {
          setselectOperation({
            label:
              resultadoSearch?.code_operation +
              " " +
              resultadoSearch?.short_name_operation,
            value: resultadoSearch?.code_operation,
            id: resultadoSearch?.id,
            documents:
              form?.balance_correction !== 1
                ? resultadoCorrect?.documents
                : DocumentOperation,
            plazo: resultadoSearch?.term,
          });
        } else {
          setselectOperation(null);
        }
      }
    }
  }, [dataOperation, form]);
  // estado actual del operacion fin

  useEffect(() => {
    if (FormEntry || ShowEntry) {
      getItems(FormEntry || ShowEntry);
    }
  }, [page, FormEntry, ShowEntry, update, orderBy]);

  useEffect(() => {
    if (FormEntry) {
      // viewForms();
      viewCommentary(FormEntry);
    } else if (ShowEntry) {
      viewCommentary(ShowEntry);
    } else {
      setForm(initialForm);
    }

    return () =>{
      setForm({})
    }
  }, [FormEntry, ShowEntry, update]);

  useEffect(() => {
    if (FormEntry) viewCommentary();
  }, [newCommentary]);

  // const { datauserQualified } = useUserQualified();

  useEffect(() => {
    if (code_subheading_corrected) {
      const fourNumberSubheading = code_subheading_corrected.substr(-20, 4);
      if (
        parseInt(fourNumberSubheading) === 2709 ||
        parseInt(fourNumberSubheading) === 2710
      ) {
        if (selectOperation?.value >= 101 && selectOperation?.value < 120) {
          onOpenWarning();
        }
      }
    }
  }, [code_subheading_corrected]);

  useEffect(() => {
    if (!dataOperation) return;
    /* Cuando balance_correction está activo (es decir, son correcciones de saldo) */
    if (form?.balance_correction === 1) {
      const infoExport = dataOperation
        ?.filter((data) => {
          if (
            code >= 100 &&
            code < 199 &&
            (data?.code_operation === 980 || data?.code_operation === 981)
          ) {
            return data;
          }
          if (
            code >= 300 &&
            code < 399 &&
            (data?.code_operation === 982 || data?.code_operation === 983)
          ) {
            return data;
          }
          if (
            code >= 500 &&
            code < 599 &&
            (data?.code_operation === 984 || data?.code_operation === 985)
          ) {
            return data;
          }
          if (
            code >= 700 &&
            code < 715 &&
            (data?.code_operation === 986 || data?.code_operation === 987)
          ) {
            return data;
          }
        })
        .map((item) => ({
          label: `${item?.code_operation} ${item?.short_name_operation}`,
          value: item?.code_operation,
          id: item?.id,
          documents: item?.documents,
          plazo: item?.term,
        }));
      setDataSelectOperation(infoExport);
      return;
    }
    /* Cuando son correcciones normales */
    const infoExport = dataOperation
      .filter((data) => data.code_operation < 900)
      .map((item) => ({
        label: `${item?.code_operation} ${item?.short_name_operation}`,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));
    setDataSelectOperation(infoExport);
  }, [dataOperation, form?.balance_correction, code, DocumentOperation]);

  useEffect(() => {
    if (dataThird) {
      const infoExport = dataThird.map((item) => ({
        label: `${
          item.identification_num !== null ? item?.identification_num : ""
        } ${item?.business_name}`,
        value: item?.id,
      }));

      setDataSelectExport(infoExport);
    }
  }, [dataThird]);

  useEffect(() => {
    if (dataToEditItems) {
      showItems(dataToEditItems.id);
      // getTrm();
    } else if (dataToCopyItems) {
      showItems(dataToCopyItems.id);
      // getTrm();
    }
  }, [dataToEditItems, dataToCopyItems]);

  /*   const handleBlurReference = () => {
    console.log({ valueProducts });
    if (valueProducts) {
      let respuesta = allProducts.find(
        (element) => element.id === parseInt(valueProducts.value)
      );
      setValueSubheading(respuesta?.subheading_id);
      setValuesItems({
        ...valuesItems,
        factor_conv_corrected: respuesta?.convertion_factor_product,
        product_unit_Correct: respuesta?.code_unit_measurement,
        product_description_corrected: respuesta?.description_product,
        description_subheading_corrected: respuesta?.description,
        code_subheading_corrected: respuesta?.code_subheading,
        code_businessUnit_corrected: respuesta?.businnesUnitSubheading,
        code_businessUnit: respuesta?.businnesUnitSubheading,
      });
    } else if (valueProducts === null) {
      setValueSubheading([]);
      setValues({
        ...values,
        factor_conv_corrected: "",
        product_unit_Correct: "",
        product_description_corrected: "",
        description_subheading_corrected: "",
        code_subheading_corrected: "",
        code_businessUnit_corrected: "",
        code_businessUnit: "",
      });
    }
  }; */

  const handleChangeProduct = ({ data, exchange_rate_corrected_change }) => {
    if (!data) {
      setSelectDataTypeProduct(null);
      setInfo(null);
      setDataCountryOrigin(null);
      setDataCountryBuy(null);
      setValueMethod(null);
      setValueFlag(null);
      setPackagings(null);
      setSuma(0);
      setMultiplicacion(0);
      setAcuerdos(null);
      setPesosToDollars(0);
      return setValuesItems((prev) => ({
        ...prev,
        factor_conv_corrected: "",
        product_unit_Correct: "",
        product_description_corrected: "",
        description_subheading_corrected: "",
        code_subheading_corrected: "",
        code_businessUnit_corrected: "",
        description_product_corrected: "",
        entry_form_item_description_corrected: "",
        product_quantity_corrected: 0,
        quantity_corrected: 0,
        gross_weight_corrected: 0,
        net_weight_corrected: 0,
        number_packages_corrected: 0,
        us_freight_corrected: 0,
        us_insurance_corrected: 0,
        other_us_expenses_corrected: 0,
        us_fob_value_corrected: 0,
        invoice_corrected: "",
        brand_corrected: "",
        serial_corrected: "",
        adjustment_justification_fob_corrected: "",
        code_unit_measurement_corrected: null,
      }));
    }
    setSelectDataTypeProduct({
      label: data?.name_typeProduct,
      value: data?.typeProduct_id,
    });
    setValueSubheading(data?.subheading_id);
    setValuesItems((prev) => ({
      ...prev,
      factor_conv_corrected: parseFloat(data?.convertion_factor_product),
      product_unit_Correct: data?.code_unit_measurement,
      product_description_corrected: data?.product_description_corrected,
      description_subheading_corrected: data?.description,
      code_subheading_corrected: data?.code_subheading,
      code_businessUnit_corrected: data?.businnesUnitSubheading,
      exchange_rate_corrected:
        data?.exchange_rate_corrected || exchange_rate_corrected_change,
      description_product_corrected: data?.description_product,
      entry_form_item_description_corrected: data?.description_product,
      code_unit_measurement_corrected: data?.code_unit_measurement,
    }));
  };

  function ConvertPesosToDollars(pesos, exchangeRate, decimals = 2) {
    if (
      isNaN(pesos) ||
      isNaN(exchangeRate) ||
      pesos === null ||
      exchangeRate === null
    ) {
      console.error("Parameters must be valid numbers.");
    }

    if (exchangeRate <= 0) {
      console.error("The exchange rate must be greater than zero.");
    }

    let dollars = 0;
    if (pesos !== 0 && exchangeRate !== 0) {
      dollars = pesos / exchangeRate;
    }

    const result = dollars?.toFixed(decimals);
    return result;
  }

  useEffect(() => {
    const pesos =
      parseFloat(us_freight_corrected) +
      parseFloat(us_insurance_corrected) +
      parseFloat(other_us_expenses_corrected) +
      parseFloat(us_fob_value_corrected);
    const DolarCurrent = parseFloat(valorDolarActual);
    if (pesos === 0 || DolarCurrent === 0) return setPesosToDollars(0);

    const total = parseFloat(pesos) / parseFloat(DolarCurrent);
    const totalToFixed = parseFloat(
      total.toFixed(dataForm?.precision || decimal_values_precision || 2)
    );
    setPesosToDollars(total);
  }, [
    us_freight_corrected,
    us_insurance_corrected,
    other_us_expenses_corrected,
    us_fob_value_corrected,
    valorDolarActual,
  ]);

  useEffect(() => {
    const { is_commerce, admin } = authUser;
    const valueDolar = parseFloat(valorDolarActual);
    const serchDecimal = userQualifiedNew?.find(
      ({ value }) => value === Number(form.qualifiedUser_id)
    );

    const allSum =
      parseFloat(us_freight_corrected) +
      parseFloat(us_insurance_corrected) +
      parseFloat(other_us_expenses_corrected) +
      parseFloat(us_fob_value_corrected);

    const sumFormat =
      (admin === 1 || is_commerce === 1) && serchDecimal
        ? parseFloat(allSum).toFixed(serchDecimal.decimal)
        : parseFloat(allSum).toFixed(decimal_values_precision);

    const sumaDetodo = parseFloat(sumFormat);
    setSuma(sumaDetodo);
    const resultadoMultiplicacion = sumaDetodo * parseFloat(valueDolar) ?? 0;

    const formateado =
      (admin === 1 || is_commerce === 1) && serchDecimal
        ? parseFloat(resultadoMultiplicacion).toFixed(serchDecimal.decimal)
        : parseFloat(resultadoMultiplicacion).toFixed(decimal_values_precision);

    setMultiplicacion(parseFloat(formateado));
  }, [
    suma,
    multiplicacion,
    other_us_expenses_corrected,
    us_freight_corrected,
    us_insurance_corrected,
    us_fob_value_corrected,
    valorDolarActual,
  ]);

  const handleChangeRecuperar = (id) => {
    const infoEntryCorrect = {
      id,
      status_entryFormCorrected: "BORRADOR",
      retrieved: "RECUPERADO",
    };

    changeStatusCorregido(infoEntryCorrect);
  };
  const [correctedDataTypeOperation, setCorrectedTypeOperation] =
    useState(null);

  const getOperation = async (id) => {
    try {
      const res = await __OperationListCorrected(token, id);
      const data = res?.data?.status?.data;
      const infoExport = data.map((item) => ({
        label: item?.code_operation + " " + item?.short_name_operation,
        value: item?.code_operation,
        id: item?.id,
        documents: item?.documents,
        plazo: item?.term,
      }));

      setCorrectedTypeOperation(infoExport);
    } catch (error) {
      throw error;
    }
  };
  const { setUser_Qualified } = useContext(GlobalContext);

  useEffect(() => {
    setUser_Qualified(idQualified);

    if ((form?.balance_correction === 0 && idQualified) || idQualified) {
      getOperation(idQualified);
    }
  }, [form, idQualified]);

  useEffect(() => {
    if (selectOperation === null) {
      setstateCollapse(true);
      setDataToEditItems(null);
      setDataToCopyItems(null);
      handleClean();
    }
  }, [selectOperation]);

  const handleBlurOpe = () => {
    if (selectOperation !== null) {
      handleSubmit(`/FormCorrection?FormEntry`);
    }
  };

  const isAuthorizationDeletedDocument =
    form?.status_entryFormCorrected !== "BORRADOR" && is_commerce === 1;

  useEffect(() => {
    if (valuesItems?.exchange_rate_corrected) {
      setValorDolarActual(valuesItems?.exchange_rate_corrected);
    }
  }, [valuesItems?.exchange_rate_corrected]);

  /* Para cambiar las cantidades a través de la unidad comercial */

  /*   useEffect(() => {
      if (code_businessUnit_corrected) {
        const newQuantity = parseFloat(valuesItems?.quantity_corrected);
        setValuesItems({
          ...valuesItems,
          quantity_corrected: newQuantity,
          product_quantity_corrected: newQuantity,
          gross_weight_corrected: newQuantity,
          net_weight_corrected: newQuantity,
        });
      }
    }, [valuesItems?.quantity_corrected]); */

  useEffect(() => {
    if (FormEntry) {
      return async () => {
        const body = new FormData();
        body.append("form_id", FormEntry);
        body.append("type", 0);
        body.append("type_of", "FMMIC");
        const res = await __Post(environment.FORMS_ON_EDIT, token, body);
      };
    }
  }, [FormEntry]);

  useEffect(() => {
    return () => {
      setstateCollapse(true);
    };
  }, []);

  const { blockForm, formLockQueryResult } = useFormBlocker({
    form_id: FormEntry || "",
    type_of: "FMMIC",
    userProvidedEndpoint: environment.FORMS_ON_EDIT,
  });

  useEffect(() => {
    blockForm(1);
  }, []);

  useEffect(() => {
    if (formLockQueryResult?.code === 400) {
      openAlert(formLockQueryResult?.message, "error");
      navigate(-1);
    }
  }, [formLockQueryResult]);

  const refetchForm = (flag, form_id, new_status, dataCurrent) => {
    const shouldRefetch =
      flag === "entry_form_corrected" &&
      new_status !== dataCurrent.status_entryFormCorrected &&
      new_status !== "REVISADO" &&
      form_id === dataCurrent?.id;

    if (shouldRefetch) {
      queryClient.invalidateQueries({ queryKey: ["viewFormCorrected"] });
      getItems(FormEntry || ShowEntry);
      return;
    }
  };

  useStatusListener({
    id: FormEntry || ShowEntry,
    callBack: refetchForm,
    dataCurrent: form,
  });

  const { nameDocument, setDataCorrected } = useGlobalContext();

  const onUploadFile = ({ files, setFiles, id, doc_corrected_req = 0 }) => {
    const docType = parseFloat(doc_corrected_req);

    const formData = new FormData();
    formData.append("id",FormEntry ?? ShowEntry);

    if (isValid(id)) {
      formData.append(
        docType === 1 ? "document_corrected" : "attachment",
        JSON.stringify(files[id])
      );
    } else {
      const result = Object.values(files).flatMap((documents) => documents);
      formData.append("attachment", JSON.stringify(result));
    }

    const reset = () => {
      if (isValid(id)) {
        const { [id]: _, ...rest } = files || {};
        setFiles(rest);
      } else {
        setFiles({});
      }
    };

    const requestData = {
      data: formData,
      Endpoint: environment.CORRECT_FMMI_UPDATE_DOCUMENT,
      PropertyBody: "SENDJSONCONTENTPOST",
    };

    mutate(requestData, {
      onSuccess: (res) => handleResponseUpdateForm(res, reset),
    });
  };
  return (
    <>
      <ModalGeneric
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onOpen={onOpenUpload}
        title={`Cargar documento ${nameDocument && nameDocument}`}
        size={"lg"}
      >
        <GroupInputUpload onClose={onCloseUpload} />
      </ModalGeneric>

      <Commentary
        id={FormEntry ? Number(FormEntry) : ShowEntry ? Number(ShowEntry) : ""}
        ruta={environment.CORRECT_CREATE_COMMENT_FMM_ENTRY}
        create={createCommentary}
        commentarys={commentarios}
      />

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={FormCrudCRUDContext}
        />
      </ModalGeneric>

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Advertencia`}
        titleBody={`Este ingreso contiene las partidas 2709 o 2710, las cuales deben ser reportadas a GIT DIAN según oficio 187245452-0075, informar a su jefe inmediato`}
      />

      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar item n. ${dataToDelete?.item_corrected}`}
        onDelete={deleteItemsCorrect}
      />

      <ModalGeneric
        isOpen={isOpenRecuperar}
        onOpen={onOpenRecuperar}
        onClose={onCloseRecuperar}
        title={`Recuperar formulario`}
        size="xl"
      >
        <SubTitle>
          ¿Está seguro que desea recuperar el formulario{" "}
          {dataRecuperar?.code_entryFormCorrected ||
            dataRecuperar?.code_outputFormCorrected}
          ?
        </SubTitle>
        <div className="d-flex my-2 ">
          <div className="bg-warning p-1">
            <SubTitle>Importante</SubTitle>
          </div>
          <div className="p-1">
            <SubTitle className="ms-2">
              El documento regresara al estado borrador.
            </SubTitle>
          </div>
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseRecuperar}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            onClick={() => handleChangeRecuperar(dataRecuperar?.id)}
          >
            Continuar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      {isFetchingViewFormCorrect && <SpinnerComponent />}

      {form && (
        <ExportPdfEntryCorrect
          id="IdPdfFmmCorrectEntry"
          values={form}
          items={currentPage}
        />
      )}

      {!isFetchingViewFormCorrect && (
        <>
          <div className="mb-5">
            <HeaderForm
              status={form?.status_entryFormCorrected}
              numberForm={form?.code_entryFormCorrected}
              UserQualified={form?.name_qualified}
              Modulo={`${"Formulario"} de ${
                form?.balance_correction === 1 ? "ajuste" : "corrección"
              } de ${
                form?.balance_correction === 1 ? "saldos en" : ""
              }  ingresos de mercancías en Zonas Francas`}
            />
          </div>
          {form?.final_balance_adjustment === 1 &&
            form?.status_entryFormCorrected === "AUTORIZADO" && (
              <FormBalanceAdjustmentLog
                title={"Este formulario cuenta con corrección"}
                form={form}
                // description={form && form?.reason_for_return}
                // nickname={form?.nickname}
              />
            )}

          {ShowEntry && (
            <ButtonView data={form} idFmm={FormEntry || ShowEntry} mt="">
            <CreateAt
              entries={form.users}
              config={{
                statusKey: "status_entryFormCorrected",
                dateKey: "date_entryFormCorrected",
                timeKey: "time_entryFormCorrected",
                personNameKey: "person_name",
              }}
              expirationDate={form.expirationDate}
            />
            </ButtonView>
          )}

          <div className="row">
            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  Ingreso
                  <NavLink
                    to={`/FormShow?FormEntry=${form?.entryForm_id}`}
                    className="ms-2"
                  >
                    {form?.code_entryForm}
                  </NavLink>
                </SubTitleHeader>
                <FormEntryHeader form={form} valuesCorrect={valuesCorrect} />
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch>
                <SubTitleHeader>
                  {form?.balance_correction === 1 ? "Ajuste" : "Corrección"}
                </SubTitleHeader>
                {FormEntry && (
                  <FormCorrectEntry
                    form={form}
                    valuesCorrect={valuesCorrect}
                    handleInputChangeCorrect={handleInputChangeCorrect}
                    handleBlurOpe={handleBlurOpe}
                    data={correctedDataTypeOperation}
                    balanceCorrection
                  />
                )}
                {ShowEntry && (
                  <FormShowCorrect form={form} valuesCorrect={valuesCorrect} />
                )}
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch className="p-1" style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperations code={code} />
                </div>
                <div style={{ background: "#f2f2f2" }}>
                  <CapsulaContainer
                    code={code}
                    form={form}
                    valuesCorrect={valuesCorrect}
                  />
                </div>
              </HeaderSearch>
            </div>

            <div className="col-md-6">
              <HeaderSearch className="p-1" style={{ marginTop: "40px" }}>
                <div>
                  <TitleOperations
                    code={
                      form?.balance_correction === 1
                        ? code
                        : selectOperation?.value
                    }
                  />
                </div>
                <div style={{ background: "#f2f2f2" }}>
                  {selectOperation?.value >= 101 &&
                  selectOperation?.value < 199 ? (
                    <CorrectionRestWorld
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 301 &&
                    selectOperation?.value <= 399 ? (
                    <CorrectionRestTerritory
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 501 &&
                    selectOperation?.value <= 599 ? (
                    <CorrectFreeZone
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : selectOperation?.value >= 701 &&
                    selectOperation?.value <= 799 ? (
                    <CorrectFromUserToUser
                      data={valuesCorrect}
                      handleInputChangeCorrect={handleInputChangeCorrect}
                      code={selectOperation?.value}
                      dataCurrent={form}
                      StateDisble={ShowEntry}
                    />
                  ) : form?.balance_correction === 1 ? (
                    <CapsulaContainer
                      code={code}
                      form={form}
                      valuesCorrect={valuesCorrect}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </HeaderSearch>
            </div>

            <div className="col-4">
              <Text className="mt-4" fontSize="xl" style={{ fontWeight: 600 }}>
                Ítems
              </Text>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <div className="d-flex float-end my-4">
                {!ShowEntry && form?.balance_correction !== 1 && (
                  <BtnWithToltip
                    msgToltip="Agregar ítem"
                    hableSubmit={handleCancel}
                    icon={CgPlayListAdd}
                  />
                )}
              </div>
            </div>

            {!stateCollapse && (
              <div className="col-6">
                {isLoadingShow && <SpinnerComponent />}
                {!isLoadingShow && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <ForItemsCorrect
                      values={valuesItems}
                      handleInputChange={handleInputChangeItems}
                      multiplicacion={multiplicacion}
                      suma={suma}
                      selectOperation={selectOperation}
                    />
                  </div>
                )}
              </div>
            )}

            {!stateCollapse && (
              <div className="col-6">
                {isLoadingShow && <SpinnerComponent />}
                {!isLoadingShow && (
                  <div className="p-1" style={{ background: "#f2f2f2" }}>
                    <FormCorrectItems
                      pesosToDollars={pesosToDollars}
                      values={valuesItems}
                      handleInputChange={handleInputChangeItems}
                      setValues={setValuesItems}
                      suma={suma}
                      multiplicacion={multiplicacion}
                      setMultiplicacion={setMultiplicacion}
                      setSuma={setSuma}
                      handleCancel={handleCancel}
                      hadleSaveItems={hadleSaveItems}
                      idUserQualifieder={form.qualifiedUser_id}
                      flagBalance={form?.balance_correction}
                      valuesHeader={form}
                      codeOriginalOpe={code}
                      setquantityError={setquantityError}
                      quantityError={quantityError}
                      errorsState={errorsState}
                      selectOperation={selectOperation}
                      handleChangeProduct={handleChangeProduct}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="col-12 ">
              <div className="mt-2">
                <InputSelect
                  data={[
                    { value: "ASC", label: "Ascendente" },
                    { value: "DESC", label: "Descendente" },
                  ]}
                  value={orderBy}
                  name="order_by"
                  set={(e) => {
                    setOrderBy(e);
                    setPage(1);
                  }}
                  placeholder="Ordenar por"
                  inputAttrs={{
                    color: "#5f5f",
                  }}
                  size={30}
                  isClearable={false}
                />
                <TablePass
                  thead={!ShowEntry ? TitleItemFmm : TitleItemFmmShow}
                  data={currentPage}
                  setData={setCurrentPage}
                  loading={loadingItems}
                  tbodyData={
                    !ShowEntry ? TBodyItemCorrect : TBodyItemCorrectShow
                  }
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-12">
              <HeaderSearch>
                <FileUploader
                  associatedDocumentType={DocumentOperation}
                  cleanFile={cleanFile}
                  onUploadFile={onUploadFile}
                  files={listFiles}
                  typeDoc={'attachment'}
                />
              </HeaderSearch>
              <HeaderSearch>
                <Box display={"grid"} gap={4} pt={12}>
                  <EnhancedFileUploadManager
                    title="Otros documentos"
                    onUploadComplete={() => {}}
                    handleResponseUpdateForm={handleResponseUpdateForm}
                    endpointAttachments={{
                      create: environment.CORRECT_FMMI_UPDATE_DOCUMENT,
                    }}
                    postPropertyName={"other_attachment"}
                    vaporBaseUrl={environment.VAPOR_BASE_URL}
                    getAuthToken={() => {
                      return token;
                    }}
                    formID={ShowEntry || FormEntry}
                  />

                  <ExistingFiles
                    existingFiles={otherFiles?.other_files}
                    // isLoading={isFetchingViewForm}
                    handleDeleteFile={cleanFile}
                  />
                </Box>
              </HeaderSearch>
            </div>
            {!ShowEntry && (
              <Box
                display="flex"
                alignItems="center"
                marginTop={"20px"}
                marginBottom={"20px"}
                justifyContent="flex-end"
                flexWrap={"wrap"}
              >
                <ButtonStyled type="submit" onClick={() => handleSubmit(null)}>
                  Guardar
                </ButtonStyled>
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CorrectionForm;
