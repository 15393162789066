import { Icon } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const EyeIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
  color: "${(props) => props.theme.colors.mainAccentLight}";
  font-size: 1.3em;
`;
