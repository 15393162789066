/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import environment from "constants/apiConst";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import ConfigurationHeader from "../ConfigurationHeader";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import FormSectorsShow from "../FormShow/FormSectorsShow";
import TbodyDocumentRequire from "../tables/TbodyDocumentRequire";
import { __DocumentRequire } from "request/configuration/__DocumentRequire";
import FormRequiredDocument from "../forms/FormDocumentRequired";
import FormRequiredDocumentView from "../FormShow/FormRequiredDocumentView";
import FormAddDocument from "../forms/FormAddDocument";
import FormRemoveDocument from "../forms/FormRemoveDocument";
import { useSelector } from "react-redux";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formCreateData = () => {
  return <FormRequiredDocument />;
};

const formView = () => {
  return <FormRequiredDocumentView />;
};

const formAddDocument = () => {
  return <FormAddDocument />;
};

const formRemoveDocument = () => {
  return <FormRemoveDocument />;
};

const DocumentRequire = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { q = "" } = queryString.parse(location.search);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    setDocument,
    setRemove,
    update
  } = useContext(ConfigurationCRUDContext);

  const Title = [
    { name: "Nombre", order: "name_document" },
    { name: "Descripcion", order: "description_document" },
    { name: "Estado", order: "status_document" },
    { name: "Acciones", order: null }
  ];

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(formCreateData);
    setFormShow(formView);
    setDocument(formAddDocument);
    setRemove(formRemoveDocument);
    setRequestEdit(environment.DOCUMENT_REQUIRED_UPDATE);
    setRequestCreate(environment.DOCUMENT_REQUIRED_CREATE);
    setRequestDelete(environment.DOCUMENT_REQUIRED_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configDepartments"]});
  }, [page, update, q]);


  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configDepartments", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.DOCUMENT_REQUIRED_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])

  return (
    <>
      <SectionContainerGene>
        <ConfigurationHeader title="Documentos Requeridos"></ConfigurationHeader>
        {isLoading || (
          <TableOrder
            thead={Title}
            data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
            setData={setCurrentPage}
            loading={isLoading}
            tbodyData={TbodyDocumentRequire}
          />
        )}
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
          loading={isLoading}
        />
      </SectionContainerGene>
    </>
  );
};

export default DocumentRequire;
