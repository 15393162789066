export const headTable = [
  { name: "Usuario calificado", order: "name_qualified" },
  { name: "Nº Formulario", order: "passForm_number" },
  { name: "Completitud", order: "passForm_number" },
  { name: "Descripción", order: "passForm_number" },
  { name: "Tipo de Documento", order: "passForm_number" },

  { name: "Identificación", order: "identification" },
  { name: "Conductor", order: "driver" },
  { name: "Tipo transporte", order: "name_typeTransport" }
];

export const arrayLeftColaPesaje = [
  {
    value: "gross_scale_name",
    label: "Báscula bruto",
    name: "gross_scale_name"
  },
  {
    value: "gross_user_name",
    label: "Operario R. Bruto",
    name: "gross_user_name"
  },
  {
    value: "tare_user_name",
    label: "Operario R. Tara",
    name: "tare_user_name"
  },
  {
    value: "tare_scale_name",
    label: "Báscula tara",
    name: "tare_scale_name"
  },

  {
    value: "weighings.vehicle_plate",
    label: "Placa",
    name: "vehicle_plate"
  },

  {
    value: "net_weight",
    label: "Peso neto",
    name: "net_weight"
  },
  {
    value: "gross_weight",
    label: "Peso bruto",
    name: "gross_weight"
  },
  {
    value: "containers",
    label: "Contenedor",
    name: "containers"
  },
  {
    value: 1,
    label: "Repesaje",
    name: "reweight"
  }
];

export const arrayRigthColaPesaje = [
  {
    value: "name_qualified",
    label: "Usuario calificado",
    name: "nameQualified"
  },
  {
    value: "tare_weight",
    label: "Peso tara",
    name: "tare_weight"
  },
  {
    value: "shedule",
    label: "Horario",
    name: "shedule"
  },
  {
    value: "operation",
    label: "Operación",
    name: "operation"
  },
  {
    value: "consecutive",
    label: "Consecutivo",
    name: "consecutive"
  },
  {
    value: "completeness",
    label: "Completitud",
    name: "completeness"
  },
  {
    value: "passForm_number",
    label: "Número de ingreso/despacho",
    name: "passForm_number"
  }
];

export const arrayRigthUserColaPesaje = [
  {
    value: "tare_weight",
    label: "Peso tara",
    name: "tare_weight"
  },
  {
    value: "shedule",
    label: "Horario",
    name: "shedule"
  },
  {
    value: "operation",
    label: "Operación",
    name: "operation"
  },
  {
    value: "consecutive",
    label: "Consecutivo",
    name: "consecutive"
  },
  {
    value: "completeness",
    label: "Completitud",
    name: "completeness"
  },
  {
    value: "passForm_number",
    label: "Número de ingreso/despacho",
    name: "passForm_number"
  }
];
