/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import { HeaderSearch } from "styled/SectionContainer.styled";
import Pagination from "components/Pagination/Pagination";
import TablePass from "components/Tables/TablesPass";
import { Collapse, Text, Tooltip } from "@chakra-ui/react";
import FormCrudCRUDContext from "context/FormCrudContex";
import AddItemsForm from "../FormEntry/AddItemForm/AddItemForm";
import { IconGeneric } from "styled/Icons.styled";
import { CgPlayListAdd } from "react-icons/cg";
import { __GetItems } from "request/Forms/_CrudForm";
import TbodyItems from "../Table/TbodyItems";
import ModalDelete from "components/modal/ModalDelete";

import { useSelector } from "react-redux";
import { ButtonHeaderModule } from "styled/Buttons.styled";
import { MdUploadFile } from "react-icons/md";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { useModal } from "hooks/useModal";
import { AddItemsMassively } from "components/AddItemsMassively/AddItemsMassively";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  TitleItemFmm,
  TitleItemFmmTransfer,
  TitleServiceItemFmmShow,
} from "../arrayHeadItems";
import TbodyItemTransfer from "../Table/TbodyItemTransfer";
import { ModalSearch } from "pages/Pass-Form/View/BusquedaAvanzada/ModalSearch";
import { CargaMasiva } from "pages/Terceros/carga masiva/CargaMasiva";
import AddServiceItem from "../FormEntry/AddItemForm/AddServiceItem";
import TBodyServiceItem from "../Table/TBodyServiceItem";
import { __Post } from "request/Petitions/__Post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBulkItemAggregation } from "hooks/useBulkItemAggregation";
import { useNotations } from "context/NotationsContext";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "context/GlobalContext";

export const ViewFormEntry = ({
  code,
  Reviews,
  codeForm,
  idQualified,
  valuesHeader,
}) => {
  const token = useSelector((state) => state.auth.dataUser.token);
  const [currentPage, setCurrentPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isOpenImport, onOpenImport, onCloseImport] = useModal();
  const [nameAttachment, setNameAttachment] = useState("");
  const [Attachment, setAttachment] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const location = useLocation(); // navigate
  const queryClient = useQueryClient();

  const tBody =
    valuesHeader?.services === 1
      ? TBodyServiceItem
      : code >= 700 && code <= 799
      ? TbodyItemTransfer
      : TbodyItems;
  const tHead =
    valuesHeader?.services === 1
      ? TitleServiceItemFmmShow
      : code >= 700 && code <= 799
      ? TitleItemFmmTransfer
      : TitleItemFmm;

  const { FormEntryNumber = "" } = queryString.parse(location.search);
  const {
    setRequestDelete,
    update,
    setUpdate,
    isOpenDelete,
    onCloseModal,
    deleteData,
    show,
    setShow,
    ImportItems,
    BulkLoad,
    currentItemLength,
    setCurrentItemLength,
  } = useContext(FormCrudCRUDContext);

  const handleOpen = (e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  };

  const { setDataCurrent } = useNotations();

  const { requestData } = useGlobalContext();

  const createFormData = () => {
    const formData = new FormData();
    formData.append("entryForm_id", FormEntryNumber);
    formData.append("length", 15);

    return formData;
  };

  const {
    data: res = {},
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["getItemsFormEntry", FormEntryNumber, update, page],
    queryFn: () =>
      requestData({
        Endpoint: environment.GET_iTEMS,
        pageParam: page,
        data: createFormData(),
        PropertyBody: "sendJSONContentPOST",
      }),
  });

  useEffect(() => {
    if (isSuccess) {
      setCurrentPage(res?.data?.status?.data?.data);
      setDataCurrent((prev) => ({
        ...prev,
        itemCurrent: res?.data?.status?.data?.data,
      }));
      setCurrentItemLength(res?.data?.status?.data?.data?.length);
      setMaxPage(res?.data?.status?.data?.last_page);
    }

    if (isError) {
      openAlert(res?.data?.status.message, "error");
    }
  }, [isFetching]);

  const Upload = () => {
    document.getElementById("display").click();
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 10000000;
    const files = e.target.files;
    const resulName = files[0].name.split(".").pop();
    if (resulName === "xlsx" || resulName === "xls") {
      if (files[0].size <= MAXIMO_TAMANIO_BYTES) {
        setNameAttachment(files[0].name);
        setAttachment(files[0]);
      } else {
        openAlert(
          `El archivo ${files[0].name} excede el límite permitido de Megabytes.(10 MB)`,
          "error"
        );
      }
    } else {
      setAttachment([]);
      openAlert(
        `El tipo de archivo ${resulName} es diferente al permitido.(XLSX, XLS)`,
        "error"
      );
    }
  };

  const removeItemFromArr = () => {
    setNameAttachment("");
    setAttachment([]);
  };

  const handleClean = () => {
    removeItemFromArr();
    onCloseImport();
  };

  const AddItemsMassiively = () => {
    if (Attachment.length !== 0) {
      const formData = new FormData();
      formData.append("file", Attachment);
      formData.append("qualifiedUser_id", idQualified);
      formData.append("code_entryForm", codeForm);
      BulkLoad(environment.IMPORT_ITEMS_ENTRY, formData, handleClean);
    } else {
      openAlert(`Olvidaste seleccionar un archivo`, "error");
    }
  };

  const formData = new FormData();
  formData.append("file", Attachment);
  formData.append("qualifiedUser_id", idQualified);
  formData.append("code_entryForm", codeForm);

  const { bulkAddItems, ErrorTable, isLoadingJobStatus: isLoadingBulkAggregation } = useBulkItemAggregation({
    userProvidedEndpoint: environment.IMPORT_ITEMS_ENTRY,
    userProvidedBody: formData,
    isOpen: isOpenImport,
    onClose: onCloseImport,
    file: Attachment,
    nameFile: nameAttachment,
    update,
    setUpdate,
    key: codeForm,
  });

  return (
    <>
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseModal}
        title={`Eliminar elemento`}
        onDelete={() =>
          deleteData(
            environment.DELETE_ITEMS,
            () => {
              queryClient.invalidateQueries({
                queryKey: ["getItemsFormEntry"],
              });
            },
            false
          )
        }
      />

      <ModalGeneric
        isOpen={isOpenImport}
        onOpen={onOpenImport}
        onClose={onCloseImport}
        title="Agregar ítem masivamente"
        buttonClose={false}
      >
        <AddItemsMassively
          nameAttachment={nameAttachment}
          handleAttachment={handleAttachment}
          Upload={Upload}
          removeItemFromArr={removeItemFromArr}
          onCloseImport={handleClean}
          handleSUbmit={bulkAddItems}
          ErrorTable={ErrorTable}
          isLoading={isLoadingBulkAggregation}
        />
      </ModalGeneric>

      <HeaderSearch>
        <div className="row">
          <div className="col-6 ">
            <Text className="ms-2" fontSize="md" style={{ fontWeight: 600 }}>
              Ítem del Formulario
            </Text>
          </div>
          <div className="col-2 "></div>
          <div className="col-4 ">
            <div className="d-flex float-end ">
              {code >= 700 && code <= 799 ? null : (
                <>
                  <Tooltip
                    placement="top"
                    hasArrow
                    label="Agregar ítem masivamente"
                  >
                    <ButtonHeaderModule
                      className="me-1 d-flex float-end"
                      bgColor="#1F7AC3"
                      onClick={onOpenImport}
                    >
                      <IconGeneric
                        width="16px"
                        color="#fff"
                        as={MdUploadFile}
                      />
                    </ButtonHeaderModule>
                  </Tooltip>

                  <Tooltip placement="top" hasArrow label="Agregar ítem">
                    <ButtonHeaderModule
                      onClick={handleOpen}
                      bgColor="#03C39A"
                      className="d-flex float-end"
                    >
                      <IconGeneric
                        width="16px"
                        color="#fff"
                        as={CgPlayListAdd}
                      />
                    </ButtonHeaderModule>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Agregar Items */}
        <Collapse in={show} animateOpacity>
          {valuesHeader?.services === 0 ? (
            <AddItemsForm Reviews={Reviews} valuesHeader={valuesHeader} />
          ) : (
            <AddServiceItem
              Reviews={Reviews}
              valuesHeader={valuesHeader}
              setData={setCurrentPage}
            />
          )}
        </Collapse>

        <TablePass
          thead={tHead}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={tBody}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};
