import { useMutation } from '@tanstack/react-query'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import InputSelect from 'components/InputSelect/InputSelect'
import environment from 'constants/apiConst'
import { AuthContext } from 'context/AuthContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useDebounce } from 'hooks/useDebouce'
import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import useTransformOptions from 'hooks/useTransformOptions '
import { IconGeneric } from 'styled/Icons.styled'
import { GoSearch } from 'react-icons/go'
import { isArray } from 'utils/type-check-utils'

const SearchReference = ({
  label = '',
  required = false,
  manualId = null,
  set = null,
  callBack = null,
  onValueChange = () => {},
  requestManagement = () => {},
  formData = {
    outputForm_id: null,
    qualifiedUser_id: null,
    operation_code: null
  },
  ...attrs
}) => {
  const { requestData } = useGlobalContext()
  const [ProductData, setProductData] = useState({ value: null, label: null })

  const [values, setValues] = useState({
    search_reference: ''
  })

  const SearchDebounce = useDebounce(values?.search_reference, 700)
  const [openAlert] = AlertErrorAlert()

  const handleResponseItem = res => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, 'error')
    }

    if (res?.data?.status?.code === 200) {
      requestManagement(res?.data?.status)
      return setProductData(res?.data?.status?.data)
      // return setUpdate('')
    }
  }

  const { isLoading, mutate: MutateProduct } = useMutation({
    mutationFn: requestData,
    onSuccess: data => handleResponseItem(data)
  })

  const searchProduct = search => {
    const productData = new FormData()
    productData.append('search_reference', search)

    for (const [key, value] of Object.entries(formData)) {
      if (value !== null && value !== undefined && value !== '') {
        productData.append(key, value)
      }
    }

    MutateProduct({
      data: productData,
      Endpoint: environment.GET_REFERENCE_OUPUT_FORM,
      PropertyBody: 'sendFormDataContentPOST'
    })
  }

  const options = useTransformOptions(
    ProductData,
    'inventory_reference',
    'id',
    '',
    ''
  )

  const handleInput = inputValue => {
    setValues({ search_reference: inputValue })
  }

  const handleSelectChange = selectedOption => {
    const hasProductData = ProductData || []

    const index =
      isArray(hasProductData) &&
      hasProductData?.findIndex(item => item.id === selectedOption?.value)

    set && set(selectedOption)
    callBack &&
      callBack(hasProductData, selectedOption, hasProductData?.[index])
  }

  useEffect(() => {
    if (SearchDebounce?.length >= 3) {
      /*  if (authUser.is_qualified_user !== 1 && !user_Qualified) {
        openAlert('Debes seleccionar un usuario calificado', 'error')
        return
      } */
      searchProduct(SearchDebounce)
    }
  }, [SearchDebounce])

  const onChangeManager = (...attrs) => {
    handleSelectChange(...attrs)
    onValueChange(...attrs)
  }

  return (
    <>
      <InputSelect
        components={{
          // IndicatorSeparator: () => null, // Oculta el separador de indicadores
          DropdownIndicator: () => (
            <IconGeneric
              style={{
                margin: '7px'
              }}
              color={'black'}
              width='16px'
              as={GoSearch}
            />
          )
        }}
        required={required}
        tag={label}
        onInputChange={handleInput}
        onChange={onChangeManager}
        data={options}
        isLoading={isLoading}
        noOptionsMessage={() => 'Escribe el código del producto...'}
        {...attrs}
      />
    </>
  )
}

export default SearchReference
