/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import {
  LabelGroup,
  SwitchPropiosMedios
} from "pages/RefProductInter/LabelGroup";
import {
  tidy,
  dateBy
} from "../../../../Pass-Form/View/BusquedaAvanzada/ArraySelect";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import FormCrudCRUDContext from "context/FormCrudContex";
import { ButtonStyled } from "styled/Buttons.styled";
import { Flex, Spacer } from "@chakra-ui/react";
import GlobalContext from "context/GlobalContext";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

const FormAcumulados = ({ onClose }) => {
  const { currentSearch, setParamsSearch } = useSearchUrl({ nestedProp: "searchAdvance" });

  const { selectAD, setSelectAD } = useContext(FormCrudCRUDContext);
  const [sortBy, setSortBy] = useState(currentSearch?.sortBy || { value: "code_entryForm", label: "Num. Formulario" });
  const [datosBy, setDatosBy] = useState(currentSearch?.datosBy);
  const options = ["Ascendente", "Descendente"];
  // const [descending, setDescending] = useState(null);
  const [values, handleInputChange, reset, setValues] = useForm({
    code_entryForm: currentSearch?.code_entryForm,
    date_initial: currentSearch?.date_initial,
    date_final: currentSearch?.date_final,
    initial_time: currentSearch?.initial_time,
    final_time: currentSearch?.final_time
  });

  const { code_entryForm, date_initial, date_final, initial_time, final_time } =
    values;


  const handleBusqueda = () => {
    const handleDateOf = {
      "0": "BORRADOR",
      "1": "PRESENTADO",
      "2": "REVISADO",
      "3": "AUTORIZADO",
    }[datosBy?.value] || null;

    const newObjetData = {
      ...values,
      ...(sortBy && sortBy?.value && { order_by: sortBy?.value }),
      date_of: handleDateOf,
      orderby_type: selectAD === "Ascendente" ? "ASC" : "DESC"
    };
    setParamsSearch({
      params: handleSanitizedObj({
        ...newObjetData,
        datosBy,
        sortBy,
        typeSearch: "ACCUMULATED",
      }),
    })
    onClose();

  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"code_entryForm"}
          value={code_entryForm}
          onChange={handleInputChange}
          tag={"Nº Formulario"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"date_initial"}
          value={date_initial}
          onChange={handleInputChange}
          tag={"Fecha Inic"}
          size={45}
          tipo={"date"}
        />
        <LabelGroup
          name={"date_final"}
          value={date_final}
          onChange={handleInputChange}
          tag={"Fecha Final"}
          size={45}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <LabelGroup
          name={"initial_time"}
          value={initial_time}
          onChange={handleInputChange}
          tag={"Hora de inicio"}
          size={45}
          tipo={"time"}
        />
        <LabelGroup
          name={"final_time"}
          value={final_time}
          onChange={handleInputChange}
          tag={"Hora final"}
          size={45}
          tipo={"time"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div style={{ width: `45%` }}>
          <InputSelect
            name={"date_by"}
            tag={"Fecha de:"}
            data={dateBy}
            value={datosBy}
            set={setDatosBy}
          />
        </div>

        <div style={{ width: `45%` }}>
          <InputSelect
            name={"state_dispacth"}
            tag={"Ordenar por:"}
            data={tidy}
            value={sortBy}
            set={setSortBy}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem"
        }}
      >
        <div className="">
          <RadioBtn options={options} setOnly={setSelectAD} state={selectAD} />
        </div>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled
          bgColor={"#03C39A"}
          type="submit"
          onClick={() => handleBusqueda()}
        >
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};

export default FormAcumulados;
