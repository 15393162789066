import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import environment from "constants/apiConst";
import queryEnv from "constants/queryConst";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext, useEffect } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdFactCheck, MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;
  border-bottom: 1px solid #bababa;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuAprovedToTransfer = ({ data, movement }) => {
  const { authUser } = useContext(AuthContext);
  const {
    changeStatus,
    setRequestChangeStatusForm
  } = useContext(FormCrudCRUDContext);

  useEffect(() => {
    movement === "Aprobado de ingreso" && setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS)
  }, []);

  const handlePresent = (status) => {
    const info = {
      id: data?.form_id,
      status_entryForm: status
    };
    changeStatus(info, queryEnv?.APROVED_FMM_TO_TRANSFER);

  };



  return (
    <Menu>
      <MenuButton disabled={authUser?.is_qualified_user === 1}>
        <IconGeneric as={HiDotsHorizontal} color={authUser?.is_qualified_user === 1 ? "#bababa" : "#00000085"} />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {((authUser.admin === 1 || authUser.is_commerce === 1) || true) && (
          <>
            <MenuItemStyled onClick={() => handlePresent("AUTORIZADO")} py={3}>
              <IconMenuRUD as={MdFactCheck} />
              Radicar
            </MenuItemStyled>
            <HrDividerNoMargin />
          </>
        )}
      </MenuList>
    </Menu>
  );
};
export default MenuAprovedToTransfer;
