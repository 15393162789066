import axios from 'axios';
import environment from 'constants/apiConst';

export const __Get_Operations = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.MONITORING_OPERATIONS, options);
  return res;
};

export const __Get_Options_FollowOrder = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.GET_OPTION_FOLLOW_ORDER, options);
  return res;
};

export const __Get_FollowOrderTable = async (token, data, page) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(`${environment.GET_FOLLOW_ORDER_TABLE}?page=${page || 1}`, options);
  return res;
};

export const __CreateFollowOrder = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.CREATE_FOLLOW_ORDER, options);
  return res;
};

export const __EditFollowOrder = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.EDIT_FOLLOW_ORDER, options);
  return res;
};

export const __DeleteFollowOrder = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.DELETE_FOLLOW_ORDER, options);
  return res;
};

export const __CreateComment = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.CREATE_COMMENT, options);
  return res;
};

export const __DeleteComment = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.DELETE_COMMENT, options);
  return res;
};

export const __GetComment = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.GET_COMMENT, options);
  return res;
};

export const __EditComment = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    },
    data: data || ""
  };

  const res = await axios(environment.EDIT_COMMENT, options);
  return res;
};
