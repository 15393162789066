import { Field } from "formik";
import styled from "styled-components/macro";
import { isBoolean, isString } from "utils/type-check-utils";

const getColor = (props) => {
  const { color, error } = props;

  if (isString(color)) return color;
  if (isString(error)) return error;

  return error ? "red" : "#454546";
};

export const InputStyled = styled(Field)`
  width: ${(props) => props.width || "100%"};
  height: auto;
  background-color: #ffffff87;
  border: none;
  outline: none;
  border-bottom: 1px solid #9097be;
  font-size: 1rem;
  display: inline-block;
`;

export const LabelStyled = styled.label`
  color: ${(props) => getColor(props)};
  font-size: ${(props) => props.fontSize || "0.88rem"};
`;

export const LabelStyledComment = styled.label`
  color: ${(props) => (props.ColorCustom ? props.ColorCustom : "#454546")};
  font-size: 0.88rem;
`;

export const LabelStyledLink = styled.label`
  color: #1f7ac3;
  font-size: 0.75rem;
  text-decoration: underline #1f7ac3;
  cursor: pointer;
`;

export const colourStyles = {
  valueContainer: (styles) => ({
    ...styles,
    // Ajusta según tus necesidades
    maxHeight: 100,
    overflowY: "auto"
  })
}
