/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { OnlyNumberInput } from "components/Funciones/OnlyNumberInput";
import InputSelect from "components/InputSelect/InputSelect";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { ButtonStyled } from "styled/Buttons.styled";
import { useForm } from "hooks/useForm";
import { useSearchUrl } from "hooks/useSearchUrl";

const FormSearchProfile = ({ setSearchAdvanced, onClose }) => {
  const { state, cleanState, setParamsSearch, clearParamsSearch } = useSearchUrl({ getParams: ["form_code", "operation", "document_type", "valueTypeForm"], nestedProp: "searchAdvance" });

  const [valueTypeForm, setValueTypeForm] = useState(state?.valueTypeForm);
  const [openAlert] = AlertErrorAlert();

  const [values, handleInputChange, reset, setValues] = useForm({
    form_code: state?.form_code,
    operation: state?.operation,
  });

  const { form_code, operation, hola } = values;

  const typeForm = [
    { label: "Formulario de entrada", value: "FORM.INGR." },
    { label: "Formulario de salida", value: "FORM.SALID" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...(form_code && { form_code }),
      ...(operation && { operation }),
      ...(valueTypeForm && { document_type: valueTypeForm.value })
    };

    setParamsSearch({
      params: {
        ...data,
        ...(valueTypeForm && { valueTypeForm })
      }
    })

    if (form_code !== "" || operation !== "" || valueTypeForm !== null) {
      setSearchAdvanced(data);
    } else {
      openAlert("Debe colocar un campo para realizar la búsqueda", "error");
    }
  };

  return (
    <>
      <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
        <InputSelect
          tag={"Tipo de formulario"}
          data={typeForm}
          set={setValueTypeForm}
          value={valueTypeForm}
          size={100}
        />
      </SimpleGrid>

      <SimpleGrid minChildWidth="175px" spacing="20px" className="mt-2">
        <LabelGroup
          tipo={"text"}
          name={"form_code"}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          value={form_code}
          tag={"Código de Formulario "}
          size={100}
          desicion={false}
        />

        <LabelGroup
          name={"operation"}
          onChange={(e) => OnlyNumberInput(e, setValues, values)}
          value={operation}
          tag={"Código de operación"}
          size={100}
          desicion={false}
        />
      </SimpleGrid>
      <Box
        display="flex"
        alignItems="center"
        marginTop={"20px"}
        marginBottom={"20px"}
        justifyContent="flex-end"
        flexWrap={"wrap"}
      >
        <ButtonStyled bgColor={"#bababa"} onClick={() => onClose()}>
          Cerrar
        </ButtonStyled>
        <ButtonStyled onClick={handleSubmit}>Buscar</ButtonStyled>
      </Box>
    </>
  );
};

export default FormSearchProfile;
