import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { IconGeneric } from 'styled/Icons.styled'
import { HiDotsHorizontal } from 'react-icons/hi'
import { OptionsEraserBox } from './OptionEraserBox'
import { OptionsAuthorizedBox } from './OptionAuthorizedBox'
import { OptionsSubmittedBox } from './OpctionSubmittedBox'
import OptionsReviewedBox from './OptionsReviewedBox'

// import { OptionsReturnedBox } from "./OpctionReturnedBox";

const MenuBox = ({ data, isCorrected }) => {
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color='#00000085' />
      </MenuButton>
      <MenuList borderRadius='none' py={0} style={{ boxSizing: 'border-box' }}>
        {data?.status === 'BORRADOR' ||
        data?.status_corrected === 'BORRADOR' ||
        data?.status === 'DEVUELTO' ||
        data?.status_corrected === 'DEVUELTO'
          ? (
            <OptionsEraserBox data={data} isCorrected={isCorrected} />
          )
          : data?.status === 'PRESENTADO' ||
          data?.status_corrected === 'PRESENTADO'
            ? (
              <OptionsSubmittedBox data={data} isCorrected={isCorrected} />
            )
            : data?.status === 'REVISADO'
              ? (
                <OptionsReviewedBox data={data} />
              )
              : data?.status === 'AUTORIZADO'
                ? (
                  <OptionsAuthorizedBox data={data} />
                )
                : (
                  'N/A'
                )}
      </MenuList>
    </Menu>
  )
}

export default MenuBox
