import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import environment from "constants/apiConst";
import queryEnv from "constants/queryConst";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import React, { useContext, useEffect } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdFactCheck, MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconGeneric, IconMenuRUD } from "styled/Icons.styled";
import { HrDividerNoMargin } from "styled/Line.styled";

const MenuItemStyled = styled(MenuItem)`
  background-color: #fff !important;
  color: #000000;
  border-bottom: 1px solid #bababa;

  &:hover {
    background-color: #1f7ac3 !important;
    color: #fff !important;
  }
  &:hover * {
    color: #fff !important;
  }
`;

const MenuAproved = ({ data, movement }) => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const {
    changeStatus,
    onOpenReturnReason,
    setReturnReason,
    setRequestChangeStatusForm
  } = useContext(FormCrudCRUDContext);

  useEffect(() => {
    movement === "Aprobado de ingreso"
      ? setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS)
      : setRequestChangeStatusForm(environment.CHANGE_STATUS_FORMS_EXIT);
  }, []);

  const handlePresent = (status) => {
    if (movement === "Aprobado de ingreso") {
      const info = {
        id: data?.form_id,
        status_entryForm: status
      };

      if (status === "DEVUELTO") {
        onOpenReturnReason();
        setReturnReason(info);
      } else {
        changeStatus(info, queryEnv?.APROVED_FMM);
      }
    } else if (movement === "Aprobado de salida") {
      const info = {
        id: data.id,
        status_outputForm: status
      };
      if (status === "DEVUELTO") {
        onOpenReturnReason();
        setReturnReason(info);
      } else {
        changeStatus(info, queryEnv?.APROVED_FMM);
      }
    }
  };

  const handleShow = (status) => {
    if (status === "Aprobado de ingreso") {
      navigate(`/FormShow?FormEntry=${data?.form_id}`);
    }

    if (status === "Aprobado de salida") {
      navigate(`/FormShowExit?ExitNumber=${data?.form_id}`);
    }
  };

  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        <MenuItemStyled py={3} onClick={() => handleShow(movement)}>
          <IconMenuRUD as={MdRemoveRedEye} />
          Revisar
        </MenuItemStyled>

        {(authUser.admin === 1 || authUser.is_commerce === 1) && (
          <>
            <MenuItemStyled onClick={() => handlePresent("AUTORIZADO")} py={3}>
              <IconMenuRUD as={MdFactCheck} />
              Radicar
            </MenuItemStyled>
            <HrDividerNoMargin />

            {/*  <MenuItemStyled onClick={() => handlePresent("DEVUELTO")} py={3}>
              <IconMenuRUD color="#d92525" as={AiOutlineFileSync} />
              Devolver
            </MenuItemStyled> */}
          </>
        )}
      </MenuList>
    </Menu>
  );
};
export default MenuAproved;
