/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import { RiEdit2Fill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Flex, Heading, SimpleGrid, Spacer, Tooltip } from "@chakra-ui/react";

import { BtnShow } from "./BtnShow";
import { useModal } from "hooks/useModal";
import environment from "constants/apiConst";
import { IconGeneric } from "styled/Icons.styled";
import { AuthContext } from "context/AuthContext";
import ModalCopy from "components/modal/ModalCopy";
import { ButtonStyled } from "styled/Buttons.styled";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { CreateAndAutorizate } from "./CreateAndAutorizate";
import CustomsCrudContext from "context/CustomsCrudContext";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { HeaderSearch } from "styled/SectionContainer.styled";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import TbodyFormCustomClearance from "./Tbody/TbodyFormCustomClearance";
import {
  theadCustomsClearanceActionNone,
  theadCustomsClearanceActionNoneIsCommerce,
} from "./theadCustomsClearance";
import {
  FormInputGroup,
  InputFake,
  LabeFormlStyled,
} from "styled/Input.styled";
import { __ShowCustomsClearence } from "request/CustomsClearance.js/__CustomsClearence";
import { setIdShowCustoms } from "redux/actions/customsClearanceActions";
import { Commentary } from "components/Commentary/Commentary";
import { __CommentaryForm } from "request/Forms/__ShowForm";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";

import { AdjuntoOneAttac } from "./Adjunto/AdjuntoOneAttac";
import { AttchmentTypiFied } from "components/attchmentTypiFied/AttchmentTypiFied";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { __formaterCant } from "components/Funciones/formaterCant";
import InputSelect from "components/InputSelect/InputSelect";
import ExistingFiles from "./Adjunto/ExistingFiles";
import { convertArrayToGroupedObject } from "pages/Entry_and_exit_forms/FormEntry/BodyForm";

export const ShowCustomsClearance = () => {
  const location = useLocation(); // navigate
  const { Customs = "" } = queryString.parse(location.search);
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { idShowCustoms } = useSelector((state) => state.customsClearanceState);

  const [newAcceptance_date, setNewAcceptance_date] = useState({});
  const [currentPage, setCurrentPage] = useState([]);
  const [commentarios, setComentarios] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [attachment, setAttachment] = useState([]);

  const [attached, setAttached] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const [orderBy, setOrderBy] = useState({ value: "ASC", label: "Ascendente" });

  const { is_qualified_user } = useSelector((state) => state.auth.dataUser);

  const [isOpenEditInput, onOpenEditInput, onCloseEditInput] = useModal();
  const {
    update,
    loading,
    editData,
    subTotals,
    copyData,
    setLoading,
    dataToEdit,
    isOpenCopy,
    onCloseModal,
    setRequestEdit,
    setRequestCopy,
    dataTableAddItems,
    pageCustomsClearances,
    setPageCustomsClearances,
    maxPageCustomsClearances,
    getItemsCustomsClearances,
    createCommentary,
    newCommentary,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason,
    deleteAttachment,
    setRequestChangeStatus,
  } = useContext(CustomsCrudContext);

  const [values, handleInputChange, reset, setValues] = useForm({
    US_value: "",
    tradeAgreement_id: "",
    acceptance_number: "",
    acceptance_date: "",
    release_number: "",
    subheading_id: "",
    code_subheading: "",
    subchapter: "",
    code_businessUnit: "",
    status_customsClearance: "",
    nit_qualified: "",
    name_qualified: "",
    name_tradeAgreement: "",
    weight_kilograms: "",
    equivalent_amount: "",
    customsClearanceForm_number: "",
    identification: "",
    driver: "",
  });

  const {
    US_value,
    subchapter,
    name_qualified,
    release_number,
    code_subheading,
    acceptance_date,
    weight_kilograms,
    equivalent_amount,
    code_businessUnit,
    acceptance_number,
    name_tradeAgreement,
    status_customsClearance,
    customsClearanceForm_number,
    identification,
    driver,
  } = values;

  useEffect(() => {
    setRequestEdit(environment.UPDATE_CUSTOMS_CLEARANCE);
    setRequestCopy(environment.COPY_FORM_CUSTOMS_CLEARENCE);
  }, [update]);

  useEffect(() => {
    setRequestChangeStatus(environment.CUSTOMS_CHANGE_STATUS);

    showCustomClearence();
    setIdShowCustoms(Customs);
  }, [Customs, update]);

  useEffect(() => {
    getItemsCustomsClearances(Customs, orderBy);
  }, [pageCustomsClearances, orderBy]);

  const showCustomClearence = async () => {
    setLoading(true);
    try {
      const res = await __ShowCustomsClearence(token, Customs);
      const response = res?.data?.status?.data;
      console.log({ response, attached: response.attachments });
      setValues(response);
      setNewAcceptance_date(response?.acceptance_number);
      getItemsCustomsClearances(Customs);
      setAttached(response.attachments);

      const outputObject = convertArrayToGroupedObject(
        response.other_attachment || [],
        "other_attachment"
      );
      setOtherDocuments(outputObject);

      const objectAttachment = convertArrayToGroupedObject(
        response?.attachments || [],
        "attachments"
      );

      setAttachment(objectAttachment);
      /*       setAttachmentDescription(
        response?.other_attachment.map(
          ({ other_attachment_description }) => other_attachment_description
        )
      ); */
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Customs) viewCommentary();
  }, [newCommentary]);

  const divCommentary = document.querySelector(".chakra-modal__body");
  const handleScroll = () => {
    divCommentary.scrollTop = "9999";
  };

  const viewCommentary = async () => {
    try {
      const res = await __CommentaryForm(
        token,
        Customs,
        environment.GET_COMMENTARY_CUSTOMS_CLEARANCE
      );
      setComentarios(res?.data?.status?.data);
      handleScroll();
    } catch (error) {}
  };

  const EditNumberAcept = () => {
    const formData = new FormData();
    formData.append("id", Customs);
    formData.append("show", 0);
    formData.append("driver", driver);
    formData.append("identification", identification);
    formData.append("acceptance_number", newAcceptance_date);
    editData(formData);
    onCloseEditInput();
  };

  const handleAttachment = (e) => {
    const MAXIMO_TAMANIO_BYTES = 3000000;
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAXIMO_TAMANIO_BYTES) {
        openAlert(
          `El archivo ${files[i].name} excede el límite permitido de Megabytes.(3 MB)`,
          "error"
        );
      } else {
        setAttached([files[0]]);
      }
    }
  };

  const td = [1, 2, 3, 4, 5, 6, 7, 8];

  /**
   * Represents a book.
   * @constructor
   * @param {string} is_qualified_user - is on auth.dataUser and 1 is true and 0 is false.
   */
  const THead = {
    0: theadCustomsClearanceActionNoneIsCommerce,
    1: theadCustomsClearanceActionNone,
  };

  return (
    <>
      <Commentary
        id={Customs}
        ruta={environment.CREATE_COMMENTARY_CUSTOMS_CLEARANCE}
        create={createCommentary}
        commentarys={commentarios}
      />

      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar el formulario Nº ${customsClearanceForm_number}`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenEditInput}
        onOpen={onOpenEditInput}
        onClose={onCloseEditInput}
        title="Modificar número de aceptación"
        size="sm"
      >
        <div className="d-flex justify-content-center">
          <LabelGroup
            name="newAcceptance_date"
            value={newAcceptance_date}
            onChange={(e) => setNewAcceptance_date(e.target.value)}
            tag="Número de aceptación"
            size={100}
            maxiLength={"30"}
          />
        </div>
        <Flex className="my-3">
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseEditInput}>
            Cancelar
          </ButtonStyled>
          <Spacer />
          <ButtonStyled
            bgColor={"#03C39A"}
            type="submit"
            onClick={() => EditNumberAcept()}
          >
            Guardar
          </ButtonStyled>
        </Flex>
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={CustomsCrudContext}
        />
      </ModalGeneric>

      {loading && <SpinnerComponent />}
      {!loading && (
        <div>
          <div className="mb-5">
            <HeaderForm
              status={status_customsClearance}
              numberForm={customsClearanceForm_number}
              UserQualified={name_qualified}
              Modulo={"Formulario de Desaduanamiento En Zonas Francas"}
            />
          </div>
          <BtnShow values={values}>
            {values.reason_for_return &&
              (status_customsClearance === "BORRADOR" ||
                status_customsClearance === "DEVUELTO") && (
                <AlertReturnReason
                  title={"Tipo de devolución:"}
                  item={values.typifications}
                  description={
                    values.reason_for_return && values.reason_for_return
                  }
                  nickname={values.nickname_return}
                />
              )}
            <CreateAndAutorizate values={values} />
          </BtnShow>

          <HeaderSearch>
            <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
              <FormInputGroup>
                <LabeFormlStyled>Número de aceptación</LabeFormlStyled>
                <InputFake>
                  {acceptance_number || "N/A"}
                  {status_customsClearance === "AUTORIZADO" &&
                    !authUser.is_qualified_user && (
                      <Tooltip placement="top" hasArrow label="Editar">
                        <span>
                          <IconGeneric
                            onClick={onOpenEditInput}
                            className="d-flex float-end"
                            as={RiEdit2Fill}
                          />
                        </span>
                      </Tooltip>
                    )}
                </InputFake>
              </FormInputGroup>

              <FormInputGroup>
                <LabeFormlStyled>Fecha de aceptación</LabeFormlStyled>
                <InputFake>{acceptance_date || "N/A"}</InputFake>
              </FormInputGroup>

              <FormInputGroup>
                <LabeFormlStyled>Levante</LabeFormlStyled>
                <InputFake>{release_number || "N/A"}</InputFake>
              </FormInputGroup>
            </SimpleGrid>

            <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
              <FormInputGroup>
                <LabeFormlStyled>Subpartida arancelaria</LabeFormlStyled>
                <InputFake>{code_subheading || "N/A"}</InputFake>
              </FormInputGroup>

              <FormInputGroup>
                <LabeFormlStyled>Unidad comercial</LabeFormlStyled>
                <InputFake>{code_businessUnit || "N/A"}</InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Acuerdo comercial</LabeFormlStyled>
                <InputFake>{name_tradeAgreement || "N/A"}</InputFake>
              </FormInputGroup>
            </SimpleGrid>

            <SimpleGrid minChildWidth="200px" spacing="10px" my={4}>
              <FormInputGroup>
                <LabeFormlStyled>Cantidad total</LabeFormlStyled>
                <InputFake>{equivalent_amount || "N/A"}</InputFake>
              </FormInputGroup>

              <FormInputGroup>
                <LabeFormlStyled>Total en peso</LabeFormlStyled>
                <InputFake>{weight_kilograms || "N/A"}</InputFake>
              </FormInputGroup>
              <FormInputGroup>
                <LabeFormlStyled>Valor total</LabeFormlStyled>
                <InputFake>
                  {US_value ? __formaterCant(US_value) : "N/A"}
                </InputFake>
              </FormInputGroup>
            </SimpleGrid>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2rem",
              }}
            >
              <FormInputGroup size="100%">
                <LabeFormlStyled>Descripción Subpartida</LabeFormlStyled>
                <InputFake>{subchapter || "N/A"}</InputFake>
              </FormInputGroup>
            </div>
          </HeaderSearch>

          <div>
            <label
              style={{
                width: 300,
              }}
            >
              <InputSelect
                isClearable={false}
                data={[
                  { value: "ASC", label: "Ascendente" },
                  { value: "DESC", label: "Descendente" },
                ]}
                value={orderBy}
                name="order_by"
                set={setOrderBy}
                placeholder="Ordenar por"
                inputAttrs={{
                  color: "#5f5f",
                }}
              />
            </label>
            <TableOrder
              loading={loading}
              subTotals={subTotals}
              setData={setCurrentPage}
              tbodyData={TbodyFormCustomClearance}
              thead={THead[is_qualified_user]}
              data={dataTableAddItems == null ? [] : dataTableAddItems}
              actionsNone
              imageNone
              titleTotal="Subtotales"
              td={td}
            />

            {dataTableAddItems.length !== 0 && (
              <Pagination
                page={pageCustomsClearances}
                setPage={setPageCustomsClearances}
                maxPage={maxPageCustomsClearances}
                loading={loading}
              />
            )}
          </div>
          {
            <div className="my-3">
              <HeaderSearch>
                {/*   <AdjuntoOneAttac
                  set={setAttached}
                  file={attached}
                  data={attached}
                  contextRef={CustomsCrudContext}
                  titulo="Declaración de importación"
                  handleAttachment={handleAttachment}
                  btnView={false}
                /> */}
                <Heading size="md" mb={4}>
                  Declaración de importación
                </Heading>
                <ExistingFiles
                  existingFiles={attachment?.attachments}
                  //handleDeleteFile={cleanFile}
                  showDeleteButton={false}
                />
              </HeaderSearch>
              <HeaderSearch>
                {/*     <AttchmentTypiFied
                  titulo="Otros documentos"
                  attachment={attachment}
                  setAttachment={setAttachment}
                  attachmentType={attachmentType}
                  deleteAttachment={deleteAttachment}
                  setAttachmentType={setAttachmentType}
                  attachmentDescription={attachmentDescription}
                  setAttachmentDescription={setAttachmentDescription}
                  ruta={`${environment.DELETE_OTHER_ATTACHMENT_CUSTOMS}`}
                  viewBtn={false}
                /> */}
                <Heading size="md" mb={4}>
                  Otros documentos
                </Heading>
                <ExistingFiles
                  existingFiles={otherDocuments?.other_attachment}
                  //handleDeleteFile={cleanFile}
                  showDeleteButton={false}
                />
              </HeaderSearch>
            </div>
          }
        </div>
      )}
    </>
  );
};
