/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import environment from "constants/apiConst";
import CustomsForm from "../forms/CustomsForm";
import TbodyCustoms from "../tables/TbodyCustoms";
import TableOrder from "components/Tables/TableOrder";
import FormAduanaShow from "../FormShow/FormAduanaShow";
import ConfigurationHeader from "../ConfigurationHeader";
import Pagination from "components/Pagination/Pagination";
import { __Customs } from "request/configuration/__Customs";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { SectionContainerGene } from "styled/SectionContainer.styled";
import { useGlobalContext } from "context/GlobalContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const Customs = () => {

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const { freeZone_id } = useSelector((state) => state.auth.dataUser);

  const {
    setRequestEdit,
    setRequestCreate,
    setRequestDelete,
    setFormEdit,
    setFormShow,
    update
  } = useContext(ConfigurationCRUDContext);

  const location = useLocation();

  const { q = "" } = queryString.parse(location.search);

  const thead = [
    { name: "Código", order: "code_custom" },
    { name: "Nombre", order: "name_custom" },
    { name: "Correo", order: "email_custom" },
    { name: "Descripción", order: "description_custom" },
    { name: "Estado", order: "status_custom" },
    { name: "Acciones", order: "" }
  ];

  const formEdit = () => {
    return <CustomsForm />;
  };

  const formShow = () => {
    return <FormAduanaShow />;
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    setFormEdit(formEdit);
    setFormShow(formShow);
    setRequestEdit(environment.CUSTOMS_UPDATE);
    setRequestCreate(environment.CUSTOMS_CREATE);
    setRequestDelete(environment.CUSTOMS_DELETE);
    queryClient.invalidateQueries({ queryKey: ["configCustoms"]})
  }, [page, q, update]);



  const { requestData } = useGlobalContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["configCustoms", page, q, freeZone_id],
    queryFn: () => requestData({
      Endpoint: environment.CUSTOMS_GET,
      pageParam: page,
      ...(q?.length !== 0 && { data: { search: q } }),
      PropertyBody: "sendJSONContentPOST",
    })
  })

  useEffect(() => {
    if (data?.data?.status?.code !== 200) return;
    if (data?.data?.status?.data?.last_page < page) setPage(1);
  }, [data?.data?.status?.data?.last_page])


  return (
    <SectionContainerGene>
      <ConfigurationHeader title="Aduanas" />
      {isLoading || (
        <TableOrder
          isNum={false}
          thead={thead}
          loading={isLoading}
          data={data?.data?.status?.code === 200 ? data?.data?.status?.data?.data : []}
          setData={setCurrentPage}
          tbodyData={TbodyCustoms}
        />
      )}
      <Pagination
        page={page}
        thead={thead}
        setPage={setPage}
        maxPage={data?.data?.status?.code === 200 ? data?.data?.status?.data?.last_page : 1}
        loading={isLoading}
      />
    </SectionContainerGene>
  );
};

export default Customs;
