import { useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import environment from "constants/apiConst";
import TableOrder from "components/Tables/TableOrder";
import ReviewsHeader from "./TableReviews/ReviewsHeader";
import Pagination from "components/Pagination/Pagination";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { __SearchReviews } from "request/Reviews/__GetTReviews";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { AuthContext } from "context/AuthContext";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { theadAproved } from "./TableReviews/thead/thead";
import TbodyCorrections from "./TableReviews/TbodyCorrections";
import { DivCard, DivGrid } from "./Styles/Styled";
import { CardCorrections } from "./Cards/CardCorrections/CardCorrections";

export const ReviewCorrections = () => {
  const { authUser } = useContext(AuthContext);
  const { user_rol, admin, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [myPermission, setmyPermission] = useState(null);

  const [openAlert] = AlertErrorAlert();
  const token = useSelector((state) => state.auth.dataUser.token);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [card, setCatd] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [recient, setRecient] = useState("");
  const location = useLocation();
  const { movement = "" } = queryString.parse(location.search);

  useEffect(() => {
    GetReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movement, freeZone_id]);

  const consultServices = (CurrectRounter) => {
    if (CurrectRounter === "Corrección de ingreso") {
      return environment.POST_CORRECTEDS_ENTRYFORM;
    }

    if (CurrectRounter === "Corrección de salida") {
      return environment.POST_CORRECTEDS_ENTRYOUTPUT;
    }
  };

  const GetReviews = async () => {
    try {
      const res = await __SearchReviews(consultServices(movement), token, page);
      const status = res?.data?.status;

      if (status.code !== 200) {
        setResData([]);
        openAlert(status?.message, "error");
      } else {
        setCatd(res?.data?.status?.data?.cards);
        setResData(res?.data?.status?.data?.rest?.data);
        setRecient(res?.data?.status?.data?.rest?.data.length);
        setMaxPage(res?.data?.status?.data?.rest.last_page);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_rol, allAccess, admin]);

  return (
    <>
      <DivCard>
        {card.map((x, index) => {
          return <CardCorrections key={index} data={x} />;
        })}
      </DivCard>

      <HeaderSearch>
        <ReviewsHeader title={"Todos los documentos"} mt="mt-5" />
        <TableOrder
          thead={theadAproved}
          data={resData}
          isNum={false}
          setData={setResData}
          loading={loading}
          tbodyData={TbodyCorrections}
        />
        <Pagination
          thead={theadAproved}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};
