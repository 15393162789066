import { Icon, Td, Tr } from "@chakra-ui/react";
import MenuCustomsClearence from "components/Menu/MenuCustomsClearence";
import GlobalContext from "context/GlobalContext";
import { useContext } from "react";
import { FaRegEdit } from "react-icons/fa";

const TBodyFormatSRT = ({ data }) => {
  const { idItemSRT, setiIdItemSRT, isOpen, setIsOpen, numItemSRT, setNumdItemSRT } = useContext(GlobalContext);

  const handleClick = (e) => {
    e.preventDefault();
    setiIdItemSRT(data?.id);
    setNumdItemSRT(data?.item);
    setIsOpen(true);
  }

  return (
    <Tr>
      <Td className="text-center">{data?.item ? data?.item : "N/A"}</Td>
      <Td className="text-center">{data?.description_product ? data?.description_product : "N/A"}</Td>
      <Td className="text-center">{data?.reference ? data?.reference : "N/A"}</Td>
      <Td className="text-center">{data?.product_quantity ? data?.product_quantity : "N/A"}</Td>
      <Td className="text-center">{data?.code_businessUnit ? data?.code_businessUnit : "N/A"}</Td>
      <Td className="text-center" onClick={handleClick}><button><Icon as={FaRegEdit} boxSize={6} color={'#212529'} pr={1} /></button></Td>
    </Tr>
  );
};

export default TBodyFormatSRT;
