/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { useSelector } from "react-redux";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { TitleFinisProfile } from "../../ArrayTitleDian/ArrayTitle";
import { Title } from "styled/Title";
import environment from "constants/apiConst";
import { __PostForm } from "request/Petitions/__Post";
import DianCRUDContext from "context/DianCrudContext";
import TbodyFinishDocument from "pages/Dian/Table/TbodyFinishDocument";

const FinishProfiling = () => {
  const [currentPage, setCurrentPage] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const token = useSelector((state) => state.auth.dataUser.token);
  const { update } = useContext(DianCRUDContext);
  const { freeZoneValue } = useSelector(state => state.chagenFreeZone)

  const GetDocumentToProfile = useCallback(async () => {
    setLoading(true);
    try {
      const res = await __PostForm(environment.FINISH_DOCUMENT_PROFILE, token);
      const requestData = res?.data?.status?.data.data;
      console.log(requestData);
      if (requestData.length !== 0) {
        setCurrentPage(requestData);
      } else {
        setCurrentPage([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    GetDocumentToProfile();
  }, [GetDocumentToProfile, update, freeZoneValue]);

  return (
    <>
      <Title style={{ marginTop: 30, marginBottom: 40 }}>
        Finalizar Perfilamiento
      </Title>
      <HeaderSearch>
        <TableOrder
          thead={TitleFinisProfile}
          data={currentPage}
          setData={setCurrentPage}
          loading={loading}
          tbodyData={TbodyFinishDocument}
        />
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          loading={loading}
        />
      </HeaderSearch>
    </>
  );
};

export default FinishProfiling;
