import React, { useEffect, useState, useContext } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import styled from "styled-components";
import MenuProduct from "components/Menu/MenuProduct";

import { AuthContext } from "context/AuthContext";
import ProductCRUDContext from "context/ProductCRUDContext";
import { IconGeneric } from "styled/Icons.styled";
import { BsCheckCircleFill } from "react-icons/bs";
import { __SHOW_UNIT_MEDIDAS } from "request/configuration/_AUnidad";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import {
  __ACTIVAR,
  __DESACTIVAR
} from "request/configuration/__Activar_Desactivar";
import environment from "constants/apiConst";
import { __CHANGE_STATUS } from "request/productoInterno/__ProductoInterno";
import { ConfigIconStatus } from "./components/LoadingConfig";

const TdState = styled(Td)`
  color: ${(props) => (props.text === 1 ? "#58D68D" : "#F1948A")};
`;

const TbodyUnidad = ({ data }) => {
  const { authUser } = useContext(AuthContext);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const [statusProduct, setStatusproduct] = useState(
    data?.status_unit_measurement ?? 0
  );
  const [loadingIcons, setLoadingIcons] = useState(false);
  const handleState = async () => {
    const formdata = new FormData();
    formdata.append("id", data?.id);
    // formdata.append("status_unit_measurement", statusProduct);

    if (loadingIcons) return;

    setLoadingIcons(true);
    try {
      const res = await __CHANGE_STATUS(
        environment.CHANGE_STATUS_UNIT_MEASUREMENT,
        token,
        formdata
      );

      if (res.data.status.code === 200) {
        // console.log(res.data.status.data.status_unit_measurement);
        setStatusproduct(res.data.status.data.status_unit_measurement);
        openAlert(res?.data?.status?.message, "success");
      } else {
        openAlert(res?.data?.status?.message, "error");
      }
    } catch (error) {
    } finally {
      setLoadingIcons(false);
    }
  };

  return (
    <Tr>
      {authUser.admin === 1 || authUser.is_commerce === 1
        ? (
          <>
            <Td className="text-center">{data?.nit_qualified}</Td>
            <Td className="text-center">{data?.name_qualified}</Td>
            <Td className="text-center">{data?.code_unit_measurement}</Td>
            <Td className="text-center">{data?.unit_measurement}</Td>
            <Tooltip
              className="text-center"
              placement="top"
              hasArrow
              label={statusProduct === 1 ? "Desactivar" : "Activar"}
            >
              <TdState
                onClick={handleState}
                className="text-center"
                text={statusProduct}
              >
                <ConfigIconStatus loading={loadingIcons} status={statusProduct} />
              </TdState>
            </Tooltip>
          </>
        )
        : (
          <>
            <Td className="text-center">{data?.code_unit_measurement}</Td>
            <Td className="text-center">{data?.unit_measurement}</Td>
            <Tooltip
              placement="top"
              hasArrow
              label={statusProduct === 1 ? "Desactivar" : "Activar"}
            >
              <TdState
                onClick={handleState}
                className="text-center"
                text={statusProduct}
              >
                <ConfigIconStatus loading={loadingIcons} status={statusProduct} />
              </TdState>
            </Tooltip>
            <Td isNumeric className="text-center">
              <MenuProduct data={data} viewShow={true} />
            </Td>
          </>
        )}
    </Tr>
  );
};

export default TbodyUnidad;
