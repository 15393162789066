import {
  Text,
  Tbody,
  Tr,
  TableContainer,
  Table,
  Thead,
  Td
} from "@chakra-ui/react";

export const CustomTable = ({ columns, dataSource, pagination }) => {
  return (
    <TableContainer w="100%">
      <Table variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            {columns.map((column) => {
              return (
                <Td key={column.key}>
                  <Text fontWeight="bold">{column.title}</Text>
                </Td>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {dataSource.map((row) => (
            <Tr key={row.key}>
              {columns.map((column) => (
                <Td key={column.key}>
                  <Text>{row[column.dataIndex ?? column.key]}</Text>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
