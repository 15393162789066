/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Flex, Spacer } from "@chakra-ui/react";
import { RadioBtn } from "components/ComponentRadioButton/RadioBtn";
import InputSelect from "components/InputSelect/InputSelect";
import { useForm } from "hooks/useForm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { __TradeAgreementsCustoms } from "request/CustomsClearance.js/__CustomsClearence";
import { ButtonStyled } from "styled/Buttons.styled";
import { InputFormStyled, LabeFormlStyled } from "styled/Input.styled";
import {
  dataOrderBy,
  dateBy,
  stateCustomsClearence
} from "pages/Pass-Form/View/BusquedaAvanzada/ArraySelect";
import { useSearchUrl } from "hooks/useSearchUrl";

const SearchFormAdvanced = ({ onClose, setSearchAdvanced }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["subheading", "acceptanceNumber", "acceptanceDate", "startDate", "endDate", "startTime", "endTime", "CustomsClearanceForm_number", "status", "orderType", "tradeAgreement_id", "dateFrom", "type_orderBy", "orderBy"], nestedProp: "searchAdvance" })
  const token = useSelector((state) => state.auth.dataUser.token);
  const [only, setOnly] = useState(state?.type_orderBy === "DESC" ? "Descendentes" : "Ascendentes");

  const [datosBy, setDatosBy] = useState(dateBy?.find((info) => info.value === state?.dateFrom) || null);
  const [datosOrderBy, setDatosOrderBy] = useState(dataOrderBy?.find((info) => info.value === state?.orderBy) || null);
  const [stateDispacthOrPass, setStateDispacthOrPass] = useState(stateCustomsClearence?.find((info) => info.value === state?.status) || null);
  const [stateTradeAgreements, setStateTradeAgreements] = useState([]);
  const [infoTradeAgreements, setInfoTradeAgreements] = useState([]);

  const [values, handleInputChange, reset, setValues] = useForm({
    subheading: state?.subheading || "",
    acceptanceNumber: state?.acceptanceNumber || "",
    acceptanceDate: state?.acceptanceDate || "",
    startDate: state?.startDate || "",
    endDate: state?.endDate || "",
    startTime: state?.startTime || "",
    endTime: state?.endTime || "",
    CustomsClearanceForm_number: state?.CustomsClearanceForm_number || "",
  });

  const {
    subheading,
    acceptanceNumber,
    acceptanceDate,
    startDate,
    endDate,
    startTime,
    endTime,
    CustomsClearanceForm_number
  } = values;

  const optionsOnly = ["Ascendentes", "Descendentes"];

  useEffect(() => {
    TradeAgreements();
  }, []);

  const TradeAgreements = async () => {
    try {
      let current = null;
      const res = await __TradeAgreementsCustoms(token);
      const infoAcuerdo = res?.data?.status?.data.map((item) => {
        const child = {
          value: item?.id,
          label: item?.code_tradeAgreement + " - " + item?.name_tradeAgreement
        }

        if (item?.id === state?.tradeAgreement_id) {
          current = child;
        }
        return child
      });
      setStateTradeAgreements(infoAcuerdo);
      setInfoTradeAgreements(current)
    } catch (error) { }
  };

  const handleSubmit = () => {
    const data = {
      ...values,
      ...(stateDispacthOrPass && stateDispacthOrPass?.value && { status: stateDispacthOrPass?.value }),
      ...(infoTradeAgreements && infoTradeAgreements?.value && { tradeAgreement_id: infoTradeAgreements?.value }),
      ...(datosBy && { dateFrom: datosBy?.value }),
      type_orderBy: only === "Ascendentes" ? "ASC" : "DESC",
      ...(datosOrderBy && datosOrderBy?.value && { orderBy: datosOrderBy?.value })
    };
    const sanitizePayload = Object.keys(data).reduce((acc, curr) => {
      if (data[curr] !== "" && data[curr] !== null) {
        acc[curr] = data[curr];
      }
      return acc;
    }, {});

    setParamsSearch({ params: sanitizePayload });
    setSearchAdvanced(sanitizePayload);
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm mb-4">
          <LabeFormlStyled htmlFor="CustomsClearanceForm_number">
            Nº Formulario
          </LabeFormlStyled>
          <InputFormStyled
            type="text"
            name="CustomsClearanceForm_number"
            id="CustomsClearanceForm_number"
            value={CustomsClearanceForm_number}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-sm">
          <LabeFormlStyled htmlFor="acceptanceNumber">
            Nº Aceptacion
          </LabeFormlStyled>
          <InputFormStyled
            type="text"
            name="acceptanceNumber"
            id="acceptanceNumber"
            value={acceptanceNumber}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-sm">
          <LabeFormlStyled>Fecha de inicio</LabeFormlStyled>
          <InputFormStyled
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="row">
        {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
        <div className="col-sm mb-4">
          <LabeFormlStyled>Fecha de final</LabeFormlStyled>
          <InputFormStyled
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-sm">
          <LabeFormlStyled>Hora de inicio</LabeFormlStyled>
          <InputFormStyled
            type="time"
            name="startTime"
            value={startTime}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-sm">
          <LabeFormlStyled htmlFor="endTime">Hora final</LabeFormlStyled>
          <InputFormStyled
            type="time"
            name="endTime"
            id="endTime"
            value={endTime}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="row">
        {/* <SimpleGrid minChildWidth="200px" spacing="10px"> */}
        <div className="col-sm mb-4">
          <div style={{ width: `100%` }}>
            <InputSelect
              name={"date_by"}
              tag={"Fecha de:"}
              data={dateBy}
              set={setDatosBy}
              value={datosBy}
            />
          </div>
        </div>
        <div className="col-sm">
          <div style={{ width: "100%" }}>
            <InputSelect
              tag={"Acuerdo comercial"}
              data={stateTradeAgreements}
              set={setInfoTradeAgreements}
              value={infoTradeAgreements}
            />
          </div>
        </div>

        <div className="col-sm">
          <div style={{ width: `100%` }}>
            <InputSelect
              name={"state_dispacth"}
              tag={"Estado"}
              data={stateCustomsClearence}
              set={setStateDispacthOrPass}
              value={stateDispacthOrPass}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm mb-4">
          <LabeFormlStyled htmlFor="subheading">Subpartida</LabeFormlStyled>
          <InputFormStyled
            type="text"
            name="subheading"
            id="subheading"
            value={subheading}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-sm">
          <LabeFormlStyled htmlFor="acceptanceDate">
            Fecha aceptación
          </LabeFormlStyled>
          <InputFormStyled
            type="date"
            name="acceptanceDate"
            id="acceptanceDate"
            value={acceptanceDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-sm">
          <div style={{ width: `100%` }}>
            <InputSelect
              name={"date_by"}
              tag={"Ordenar por"}
              data={dataOrderBy}
              set={setDatosOrderBy}
              value={datosOrderBy}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm pt-4">
          <RadioBtn setOnly={setOnly} options={optionsOnly} state={only} />
        </div>
        <div className="col-sm" />
      </div>

      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </div>
  );
};

export default SearchFormAdvanced;
