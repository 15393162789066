import { useModal } from "hooks/useModal";
import {
  __DeleteProduct,
  __EditCreateGestion,
  __EditGestion
} from "request/productoInterno/__ProductoInterno";
import { useSelector } from "react-redux";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";

const { createContext, useState, useEffect, useCallback } = require("react");

const GestionCrudContext = createContext();

export const GestionCrudProvider = ({ children }) => {
  const [isOpenEdit, onOpenEdit, onCloseEdit] = useModal();
  const [isOpenDelete, onOpenDelete, onCloseDelete] = useModal();
  const [isOpenShow, onOpenShow, onCloseShow] = useModal();
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [requestEdit, setRequestEdit] = useState(null); //
  const [requestDelete, setRequestDelete] = useState(null);
  const [requestCreate, setRequestCreate] = useState(null); //
  const [formEdit, setFormEdit] = useState(null);
  const [update, setUpdate] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [formShow, setFormShow] = useState(null);
  const token = useSelector((state) => state.auth.dataUser.token);
  const [openAlert] = AlertErrorAlert();

  const editData = async (data) => {
    // console.log(data);
    try {
      const res = await __EditGestion(requestEdit, token, data);
      if (res.data.status.code === 200) {
        setDataToEdit(null);
        openAlert(`Registro actualizado Exitosamente`, "success");
        onCloseModal();
        setUpdate(!update);
      } else {
        if (res?.data?.status?.message.email_person) {
          for (
            let i = 0;
            i < res?.data?.status?.message.email_person.length;
            i++
          ) {
            openAlert(`${res?.data?.status?.message.email_person[i]}`, "error");
          }
        } else if (res?.data?.status?.message.lastname_person) {
          for (
            let i = 0;
            i < res?.data?.status?.message.lastname_person.length;
            i++
          ) {
            openAlert(
              `${res?.data?.status?.message.lastname_person[i]}`,
              "error"
            );
          }
        } else if (res?.data?.status?.message.name_person) {
          for (
            let i = 0;
            i < res?.data?.status?.message.name_person.length;
            i++
          ) {
            openAlert(`${res?.data?.status?.message.name_person[i]}`, "error");
          }
        } else if (res?.data?.status?.message.nickname) {
          for (let i = 0; i < res?.data?.status?.message.nickname.length; i++) {
            openAlert(`${res?.data?.status?.message.nickname[i]}`, "error");
          }
        } else if (res?.data?.status?.message.password) {
          for (let i = 0; i < res?.data?.status?.message.password.length; i++) {
            openAlert(`${res?.data?.status?.message.password[i]}`, "error");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const useDeleteData = () => {
    const [dataToDelete, setDataToDelete] = useState(null);
  
    const deleteData = useCallback(async () => {
      if (!dataToDelete || !dataToDelete.id) {
        console.error('No hay datos para eliminar o falta el ID');
        openAlert('Error al eliminar el registro: Datos incompletos', 'error');
        return;
      }
  
      try {
        const res = await __DeleteProduct(requestDelete, token, dataToDelete.id);
        
        if (res.data.status.code === 200) {
          setDataToDelete(null);
          onCloseDelete();
          openAlert('Registro eliminado exitosamente', 'success');
          setUpdate(prev => !prev);
        } else {
          openAlert(`${res.data.status.message.message[0]}`, 'error');
        }
      } catch (error) {
        console.error('Error al eliminar el registro:', error);
        openAlert('Error al eliminar el registro', 'error');
      }
    }, [requestDelete, token, dataToDelete, openAlert, onCloseDelete, setUpdate]);
  
    return { deleteData, setDataToDelete, dataToDelete };
  };
  
  

  const deleteData = async () => {
    try {
      const res = await __DeleteProduct(requestDelete, token, dataToDelete.id);
      // console.log(res);
      if (res.data.status.code === 200) {
        setDataToDelete(null);
        onCloseDelete();
        openAlert(`Registro actualizado Exitosamente`, "success");
        setUpdate(!update);
      } else {
        openAlert(`${res.data.status.message.message[0]}`, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showData = () => {
    console.log(dataToShow);
  };
  const createData = async (data) => {
    setLoadingCreate(true);
    try {
      const res = await __EditCreateGestion(requestCreate, token, data);
      if (res.data.status.code === 200) {
        openAlert(`Se agrego el usuario`, "success");
        onCloseModal();
        setUpdate(!update);
      } else {
        if (res.data.status.message === "Acceso no autorizado.") {
          openAlert(`Acceso no autorizado.`, "error");
        } else {
          for (let i = 0; i < res.data.status.message.length; i++) {
            openAlert(`${res?.data?.status?.message[i]}`, "error");
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const onCloseModal = () => {
    if (isOpenEdit) {
      onCloseEdit();
      setDataToEdit(null);
      return;
    }
    if (isOpenDelete) {
      onCloseDelete();
      return;
    }
    if (isOpenShow) {
      onCloseShow();
    }
  };

  const data = {
    editData,
    isOpenEdit,
    onOpenEdit,
    dataToEdit,
    setDataToEdit,
    dataToDelete,
    deleteData,
    isOpenDelete,
    onOpenDelete,
    setDataToDelete,
    isOpenShow,
    onOpenShow,
    dataToShow,
    setDataToShow,
    showData,
    setRequestEdit,
    setRequestDelete,
    setFormEdit,
    formEdit,
    setFormShow,
    formShow,
    onCloseModal,
    setRequestCreate,
    createData,
    update,
    setUpdate,
    loadingCreate,
    setLoadingCreate,
    useDeleteData: deleteData
  };
  return (
    <GestionCrudContext.Provider value={data}>
      {children}
    </GestionCrudContext.Provider>
  );
};

export default GestionCrudContext;
