/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Divider, SimpleGrid, Switch, Text } from "@chakra-ui/react";
import { InputWithLabel } from "components/Input/InputFakeCustoms";
import { InputZonasF } from "components/InputSelect/InputSelect";
import ConfigurationCRUDContext from "context/ConfigurationCRUDContext";
import { useForm } from "hooks/useForm";
import { ControlSub } from "pages/RefProductInter/LabelGroup";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { __SelectAcuerdosSub } from "request/configuration/__ASubheading";
import { __ShowSubheadingC } from "request/configuration/__SubheadingControl";
import { __ZonasFranca } from "request/configuration/__ZonasFranca";
import { ButtonStyled } from "styled/Buttons.styled";
import { LabeFormlStyled } from "styled/Input.styled";

const FormSubheadingC = () => {
  // INPUTS
  const [mendigo, setMendigo] = useState("");
  const [partidas, setPartidas] = useState([]);
  const [zonasF, setZonasF] = useState("");
  const [zonas, setZonas] = useState("");
  const [zonaF, setZonaF] = useState([]);

  // CHECKS
  const [checkblockapproval, setCheckBlockApproval] = useState(0);
  const [checkblockfiling, setCheckBlockFiling] = useState(0);
  const [checkincome, setCheckIncome] = useState(0);
  const [checkdepartures, setCheckDepartures] = useState(0);
  const [checkrestoftheworld, setCheckRestOfTheWorld] = useState(0);
  const [checkcolombia, setCheckColombia] = useState(0);
  const [checkusername, setCheckUsername] = useState(0);
  const [checkFreeZone, setCheckFreeZone] = useState(0);

  const token = useSelector((state) => state.auth.dataUser.token);
  const { dataToEdit, onCloseModal, editData, createData } = useContext(
    ConfigurationCRUDContext
  );

  const [values, handleInputChange, reset, setValues] = useForm({
    block_approval: 0,
    block_filling: 0,
    income: 0,
    departures: 0,
    rest_of_the_world: 0,
    colombia: 0,
    username: 0,
    free_zone: 0
  });
  const {
    block_approval,
    block_filling,
    income,
    departures,
    rest_of_the_world,
    colombia,
    username,
    free_zone
  } = values;

  useEffect(() => {
    const getData = async () => {
      if (dataToEdit) {
        const initialpos = async () => {
          try {
            const res = await __ShowSubheadingC(token, dataToEdit.id);

            // Valores anteriores
            setValues(res?.data?.status.data);
            setPartidas(res?.data?.status?.data?.subheading);

            // ID
            // setMendigo(res?.data?.status?.data?.subheading_id);
            setZonasF(res?.data?.status?.data?.freeZone_id);

            // Names

            setZonas(res?.data?.status?.data?.name_freeZone);
            // setSubheadingN(res?.data?.status?.data?.code_subheading);

            // Checks
            setCheckBlockApproval(res?.data?.status?.data?.block_approval);
            setCheckBlockFiling(res?.data?.status?.data?.block_filling);
            setCheckBlockApproval(res?.data?.status?.data?.block_approval);
            setCheckIncome(res?.data?.status?.data?.income);
            setCheckDepartures(res?.data?.status?.data?.departures);
            setCheckRestOfTheWorld(res?.data?.status?.data?.rest_of_the_world);

            setCheckFreeZone(res?.data?.status?.data?.free_zone);

            setCheckColombia(res?.data?.status?.data?.colombia);
            setCheckUsername(res?.data?.status?.data?.username);
          } catch (error) {}
        };
        initialpos();
      } else {
        setValues(values);
      }
    };
    getData();
  }, [dataToEdit]);

  const handleSubmit = () => {
    if (!rest_of_the_world && !colombia && !username && !free_zone) {
      return toast.error(
        "Selecciona el tiplo de bloqueo (Resto del mundo, Colombia, Usuario o Zona Franca)",
        {
          position: "top-center",
          autoClose: 7000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        }
      );
    }

    if (!block_approval && !block_filling) {
      return toast.error(
        "Selecciona el tipo de bloqueo (Aprobación o Radicación)",
        {
          position: "top-center",
          autoClose: 7000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        }
      );
    }

    if (dataToEdit) {
      const dataEdit = {
        ...values,
        subheading: partidas,
        freeZone_id: zonasF
      };
      editData(dataEdit);
    } else {
      const data = {
        ...values,
        subheading: partidas,
        freeZone_id: zonasF
      };
      createData(data);
    }
  };

  useEffect(() => {
    /*  const SubheadingControl = async () => {
      try {
        const res = await __SelectAcuerdosSub(token);
        setPartidas(res?.data?.status?.data);
      } catch (error) {}
    };
    SubheadingControl(); */

    const ZonasFranca = async () => {
      try {
        const res = await __ZonasFranca(token);
        setZonaF(res?.data?.status?.data);
      } catch (error) {}
    };
    ZonasFranca();
  }, []);

  const changeCheck = (e) => {
    handleInputChange(e, "number");
    const { name, checked } = e.target;
    const casesCheck = {
      block_approval: () => setCheckBlockApproval(checked ? 1 : 0),
      block_filling: () => setCheckBlockFiling(checked ? 1 : 0),
      free_zone: () => setCheckFreeZone(checked ? 1 : 0),
      income: () => setCheckIncome(checked ? 1 : 0),
      departures: () => setCheckDepartures(checked ? 1 : 0),
      rest_of_the_world: () => setCheckRestOfTheWorld(checked ? 1 : 0),
      colombia: () => setCheckColombia(checked ? 1 : 0),
      username: () => setCheckUsername(checked ? 1 : 0)
    };
    if (name === "block_approbal") {
      setCheckBlockFiling(0);
    }
    if (name === "block_filling") {
      setCheckBlockApproval(0);
    }
    casesCheck[name]();
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setPartidas(value);
  };

  return (
    <>
      <Text fontSize="md" my={3} style={{ fontWeight: 600 }}>
        {dataToEdit
          ? "Editar control de subpartida "
          : "Crear control de subpartida"}
      </Text>
      <Divider mb={3} />

      <form onSubmit={(e) => e.preventDefault()}>
        <SimpleGrid minChildWidth="100px" spacing="20px" className="mt-5">
          <div>
            {/* <ControlSub
              partidas={partidas}
              tag={"Subpartida"}
              subheadingN={subheadingN}
              setSubheadingN={setSubheadingN}
              setMendigo={setMendigo}
              mendigo={mendigo}
            /> */}
            <InputWithLabel
              p={0}
              fontWeight={0}
              readOnly={false}
              onChange={handleChange}
              borderRadius={"none"}
              name="subheading"
              value={partidas?.toString().replace(/[^0-9]/g, "")}
              labelText={"Subpartida"}
              maxLength={10}
            />
          </div>
          <div>
            <InputZonasF
              partidas={zonaF}
              tag={"Zona Franca"}
              setValor={setZonasF}
              setZonas={setZonas}
              zonas={zonas}
              mendigo={zonasF}
            />
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="100px" spacing="20px" className="mt-5">
          <div>
            <LabeFormlStyled>Bloqueo aprobado</LabeFormlStyled>
            <div>
              <Switch
                name="block_approval"
                id="block_approval"
                size="lg"
                value={block_approval}
                onChange={changeCheck}
                isChecked={checkblockapproval}
                disabled={!!checkblockfiling}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Bloqueo de radicación</LabeFormlStyled>
            <div>
              <Switch
                name="block_filling"
                id="block_filling"
                size="lg"
                value={block_filling}
                onChange={changeCheck}
                isChecked={checkblockfiling}
                disabled={!!checkblockapproval}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Ingreso</LabeFormlStyled>
            <div>
              <Switch
                name="income"
                id="income"
                size="lg"
                value={income}
                onChange={changeCheck}
                isChecked={checkincome}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Salida</LabeFormlStyled>
            <div>
              <Switch
                name="departures"
                id="departures"
                size="lg"
                value={departures}
                onChange={changeCheck}
                isChecked={checkdepartures}
              />
            </div>
          </div>
        </SimpleGrid>

        <SimpleGrid minChildWidth="100px" spacing="20px" className="mt-5">
          <div>
            <LabeFormlStyled>Resto del mundo</LabeFormlStyled>
            <div>
              <Switch
                name="rest_of_the_world"
                id="rest_of_the_world"
                size="lg"
                value={rest_of_the_world}
                onChange={changeCheck}
                isChecked={checkrestoftheworld}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Colombia</LabeFormlStyled>
            <div>
              <Switch
                name="colombia"
                id="colombia"
                size="lg"
                value={colombia}
                onChange={changeCheck}
                isChecked={checkcolombia}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Usuario</LabeFormlStyled>
            <div>
              <Switch
                name="username"
                id="username"
                size="lg"
                value={username}
                onChange={changeCheck}
                isChecked={checkusername}
              />
            </div>
          </div>

          <div>
            <LabeFormlStyled>Zona Franca</LabeFormlStyled>
            <div>
              <Switch
                name="free_zone"
                id="free_zone"
                size="lg"
                value={free_zone}
                onChange={changeCheck}
                isChecked={checkFreeZone}
              />
            </div>
          </div>

          <div></div>
        </SimpleGrid>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <ButtonStyled bgColor={"#bababa"} onClick={onCloseModal}>
            Cerrar
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit}>Aceptar</ButtonStyled>
        </Box>
      </form>
    </>
  );
};

export default FormSubheadingC;
