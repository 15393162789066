import React, { useContext } from 'react'
import { Box, Tooltip, useDisclosure } from '@chakra-ui/react'
import { ButtonHeaderModule } from 'styled/Buttons.styled'
import { IconGeneric } from 'styled/Icons.styled'
import { MdQrCodeScanner } from 'react-icons/md'
import FormCrudCRUDContext from 'context/FormCrudContex'
import { useNavigate, useLocation } from 'react-router-dom'
import { ShowBtnBorradorExit } from './ShowBtnBorradorExit'
import { ShowBtnPresentado } from './ShowBtnPresentado'
import { ShowBtnSutorizado } from './ShowBtnSutorizado'
import { ShowBtnAprobado } from './ShowBtnAprobado'

import queryString from 'query-string'
import ModalAdvertencia from 'components/modal/ModalAdvertencia'
import environment from 'constants/apiConst'
import { AlertErrorAlert } from 'components/Alerts/AlertErrorAlert'
import { ShowBtnCaducado } from './ShowBtnCaducado'
import { __Post } from 'request/Petitions/__Post'
import { useSelector } from 'react-redux'
import ModalQR from 'components/modal/ModalQR'

export const BtnShowFmm = ({ values, children, setFollowOrder, ExportPdf, setValues }) => {
  const navigate = useNavigate()
  const location = useLocation() // navigate
  const token = useSelector(state => state.auth.dataUser.token)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { ExitNumber = '' } = queryString.parse(location.search)
  const {
    setDataToEdit,
    changeStatus,
    setDataToDesechar,
    setDataToCopy,
    onOpenDesechar,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason,
    setDataToCaducar,
    onOpenCaducar,
    setDataToAnular,
    onOpeAnular,
    onOpenRecuperar,
    setDataRecuperar,
    isOpenAsoccietUnit,
    onCloseAsoccietUnit,
    messageModalAsocciet,
    dataAsocciet,
    addItemPass,
    getItemsPrev,
    isLoadingChangeStatus,
    setIsLoadingChangeStatus
  } = useContext(FormCrudCRUDContext)

  const [openAlert] = AlertErrorAlert()

 // const name = 'FMM_SALIDA_' + values?.code_outputForm
  const arrayOutput = [
    'product_quantity',
    'typeProduct_id',
    'transportMethod_id',
    'packaging_id',
    'countryOrigin_id',
    'flag_id',
    'countryPurchase_id',
    'product_id',
    'gross_weight',
    'net_weight',
    'us_freight',
    'countryDestiny_id'
  ]

  const handleEdit = async () => {
    const body = new FormData()
    body.append('form_id', values?.id)
    body.append('type', 1)
    body.append('type_of', 'FMMS')
    const res = await __Post(environment.FORMS_ON_EDIT, token, body)
    if (res?.data?.status?.code === 200) {
      setDataToEdit(values)
      navigate(`/FormExit?FormExitNumber=${values.id}`)
    }
    if (res?.data?.status?.code === 400) {
      openAlert(res?.data?.status?.message, 'error')
    }
  }

  const handleSearchItem = () => {
    const sendData = {
      outputForm_id: ExitNumber,
      qualifiedUser_id: values.qualifiedUser_id
    }

    if (ExitNumber) {
      addItemPass(sendData)
    }
  }

  const handlePresent = async status => {
  /*   if (
      (values.status_outputForm === 'BORRADOR' ||
        values.status_outputForm === 'DEVUELTO') &&
      (!values?.total_us_fob_value || values?.total_us_fob_value == 0)
    ) {
      return openAlert('Valor FOB debe ser un valor mayor a cero', 'error')
    } */
 
      setIsLoadingChangeStatus(true)
    const attach = values.files
    const arrayDocumentRequerid = []
    const resDocumentCurrent = attach.map(
      ({ documentReq_id }) => documentReq_id
    )
    values.documents.map(({ id }) => {
      if (!resDocumentCurrent.includes(id)) {
        return arrayDocumentRequerid.push(id)
      }
    })

    const respuesta =
      (await getItemsPrev(
        environment.GET_iTEMS_OUTPUT_NP,
        ExitNumber,
        arrayOutput,
        values?.code_operation,
        values?.services
      )) || []
    const info = {
      id: values.id,
      status_outputForm: status
    }

    const validateCustom = !!(
      values.code_operation >= 201 &&
      values.code_operation <= 299 &&
      values.check_duta &&
      !values.customAdministration_id
    )

    if (
      status === 'PRESENTADO' &&
      (validateCustom || respuesta.length > 0 || attach.length === 0)
    ) {
      if (validateCustom) {
        navigate(`/FormExit?FormExitNumber=${values.id}`)
        openAlert('El campo Administración de Aduanas es obligatorio', 'error')
      } else if (
        respuesta.length > 0 &&
        (values.code_operation < 700 || values.code_operation > 800)
      ) {
        navigate(`/FormExit?FormExitNumber=${values.id}`)
        openAlert(
          'El formulario presenta errores. Verifique los campos marcados',
          'error'
        )
      } else if (attach.length === 0) {
        openAlert(
          'El formulario debe tener por lo menos un documento adjunto',
          'error'
        )
        navigate(`/FormExit?FormExitNumber=${values.id}`)
      } else {
        handleCHange(status, info, attach)
      }

      // else if (arrayDocumentRequerid.length > 0) {
      //   localStorage.setItem(
      //     `errorAttadchment-${values.id}`,
      //     JSON.stringify(arrayDocumentRequerid)
      //   );

      //   openAlert(
      //     "El formulario no tiene completo los documentos requeridos",
      //     "error"
      //   );
      // } else {
      //   handleCHange(status, info, attach);
      // }
    } else {
      handleCHange(status, info, attach)
    }
  }

  const handleCHange = (status, info, attach) => {
    if (status === 'DEVUELTO') {
      onOpenReturnReason()
      setReturnReason(info)
    } else if (status === 'RECUPERADO') {
      onOpenRecuperar()
      setDataRecuperar(values)
    } else if (status === 'AUTORIZADO' && attach.length === 0) {
      openAlert(
        'El formulario debe tener por lo menos un documento adjunto',
        'error'
      )
    } else {
      changeStatus(info)
    }
  }

  const handleDesechar = () => {
    setDataToDesechar(values)
    onOpenDesechar()
  }
  const handleCopy = e => {
    setDataToCopy(values)
    onOpenCopy()
  }
  /*
  const creardespacho = () => {
    let formData = new FormData();
    formData.append("qualifiedUser_id", values.qualifiedUser_id);
    formData.append("outputForm_id", ExitNumber);
    newDispacthwithItem(formData);
  }; */

  const handleCaducar = () => {
    setDataToCaducar(values)
    onOpenCaducar()
  }

  const handleAnular = () => {
    setDataToAnular(values)
    onOpeAnular()
  }
  return (
    <>
      <ModalQR
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        title={'Código QR'}
        size={'md'}
      />

      <ModalAdvertencia
        isOpen={isOpenAsoccietUnit}
        onClose={onCloseAsoccietUnit}
        title={`Advertencia`}
        titleBody={` ${messageModalAsocciet && messageModalAsocciet}`}
        generar={() => addItemPass(dataAsocciet)}
      />

      <div className='row'>
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            {values.status_outputForm === 'BORRADOR' ||
            values.status_outputForm === 'DEVUELTO' ? (
              <ShowBtnBorradorExit
                handleEdit={handleEdit}
                handlePresent={handlePresent}
                handleDesechar={handleDesechar}
                handleCopy={handleCopy}
                status={values?.status_outputForm}
                values={values}
                setFollowOrder={setFollowOrder}
              />
            ) : values.status_outputForm === 'PRESENTADO' ? (
              <ShowBtnPresentado
                values={values}
                handlePresent={handlePresent}
                handleCopy={handleCopy}
                setFollowOrder={setFollowOrder}
              />
            ) : values.status_outputForm === 'APROBADO' ? (
              <ShowBtnAprobado
                values={values}
                handlePresent={handlePresent}
                handleCopy={handleCopy}
                creardespacho={handleSearchItem}
                handleCaducar={handleCaducar}
                setFollowOrder={setFollowOrder}
              />
            ) : values.status_outputForm === 'AUTORIZADO' ? (
              <ShowBtnSutorizado
                values={values}
                handlePresent={handlePresent}
                handleCopy={handleCopy}
                creardespacho={handleSearchItem}
                handleAnular={handleAnular}
                setFollowOrder={setFollowOrder}
                setValues={setValues}
              />
            ) : values.status_outputForm === 'CADUCADO' ? (
              <ShowBtnCaducado data={values} handleCopy={handleCopy} />
            ) : (
              ''
            )}
          </Box>
        </div>
        {children}
        <div className='col'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            className='mt-4'
          >
            <Tooltip placement='top' hasArrow label='Qr'>
              <span>
                <ButtonHeaderModule
                  //   onClick={onOpen}
                  className='me-1'
                  bgColor='#1F7AC3'
                >
                  <IconGeneric
                    onClick={() => onOpen()}
                    width='16px'
                    className='text-white'
                    as={MdQrCodeScanner}
                  />
                </ButtonHeaderModule>
              </span>
            </Tooltip>
            {ExportPdf && ExportPdf}
          </Box>
        </div>
      </div>
    </>
  )
}
