import { Box, Text } from '@chakra-ui/react'

/**
 * Removes the specified element from the array.
 *
 * @param {Array} array - The array to remove the element from.
 * @param {*} elementToRemove - The element to remove from the array.
 * @returns {Array} - The updated array with the element removed.
 */
const removeElement = (array, elementToRemove) => {
  const elementIndex = array?.findIndex(e => e === elementToRemove)
  array?.splice(elementIndex, 1)
  return array ?? []
}

const verifyElementExists = (arr, el) => {
  const isElementFound = arr?.includes(el)
  return isElementFound
}

const areElementsInArrays = (arr1, arr2, el1, el2) => {
  const isEl1Found = arr1?.includes(el1)
  const isEl2Found = arr2?.includes(el2)
  return isEl1Found && isEl2Found
}

export default function BoxSummary ({ status, name = 0, High, moderate, mild }) {
  const { currentLevel, setCurrentLevel } = status

  const getRiskLevel = x => {
    const { nameId } = x?.target?.dataset
    const criteriaIndex = currentLevel?.criteria_id.findIndex(e => e === name)
    const riskLevelIndex = currentLevel?.risk_level.findIndex(e => e === nameId)

    if (criteriaIndex === -1) {
      const NewArray = { ...currentLevel }
      if (riskLevelIndex === -1) {
        NewArray?.risk_level.push(nameId)
      }
      NewArray?.criteria_id.push(name)

      setCurrentLevel(NewArray)
    } else {
      const criteria_id = [...currentLevel?.criteria_id]
      const risk_level = [...currentLevel?.risk_level]

      const verifyCriteria = verifyElementExists(criteria_id, name)
      const verifyRiskLevel = verifyElementExists(risk_level, nameId)

      if (verifyCriteria && !verifyRiskLevel) {
        const Risk = risk_level
        Risk.push(nameId)
        setCurrentLevel({ ...currentLevel, risk_level: Risk })
      }

      if (
        verifyCriteria &&
        verifyRiskLevel &&
        criteria_id.length === 1 &&
        risk_level.length === 1
      ) {
        const criteria_id_New = removeElement(criteria_id, name)
        const risk_level_New = removeElement(risk_level, nameId)
        setCurrentLevel({
          risk_level: risk_level_New,
          criteria_id: criteria_id_New
        })
      }

      if (
        verifyCriteria &&
        verifyRiskLevel &&
        criteria_id.length === 1 &&
        risk_level.length > 1
      ) {
        const risk_level_New = removeElement(risk_level, nameId)
        setCurrentLevel({
          ...currentLevel,
          risk_level: risk_level_New
        })
      }

      if (verifyCriteria && verifyRiskLevel && criteria_id.length > 1) {
        const criteria_id_New = removeElement(criteria_id, name)

        setCurrentLevel({
          ...currentLevel,

          criteria_id: criteria_id_New
        })
      }
    }
  }

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      alignItems={'center'}
      textAlign='center'
    >
      <Box
        display='flex'
        flexDir={'row'}
        w={'24'}
        h={'56'}
        overflow={'hidden'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        borderStyle={'dotted'}
        borderWidth={2}
        borderColor={'gray.400'}
        borderTop={'none'}
        borderBottom={'none'}
        borderRight={'none'}
      >
        <Box
          w='32px'
          h='20'
          height={mild}
          borderRadius={5}
          bg='green.500'
          data-name-id={'LEVE'}
          _hover={{
            border: '3px solid blue',
            cursor: 'pointer'
          }}
          border={
            areElementsInArrays(
              currentLevel?.risk_level,
              currentLevel?.criteria_id,
              'LEVE',
              name
            )
              ? '3px solid blue'
              : ''
          }
          onClick={getRiskLevel}
        />
        <Box
          w='32px'
          h='20'
          height={moderate}
          borderRadius={5}
          bg='orange.400'
          data-name-id='MODERADO'
          border={
            areElementsInArrays(
              currentLevel?.risk_level,
              currentLevel?.criteria_id,
              'MODERADO',
              name
            )
              ? '3px solid blue'
              : ''
          }
          _hover={{
            border: '3px solid blue',
            cursor: 'pointer'
          }}
          onClick={getRiskLevel}
        />
        <Box
          w='32px'
          h='20'
          height={High}
          borderRadius={5}
          bg='red.500'
          data-name-id='ALTO'
          border={
            areElementsInArrays(
              currentLevel?.risk_level,
              currentLevel?.criteria_id,
              'ALTO',
              name
            )
              ? '3px solid blue'
              : ''
          }
          _hover={{
            border: '3px solid blue',
            cursor: 'pointer'
          }}
          onClick={getRiskLevel}
        />
      </Box>

      <Text fontSize={'2xl'} color={'blackAlpha.600'} fontWeight={800}>
        {name}
      </Text>
    </Box>
  )
}
