import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import MenuRUD from "components/Menu/MenuRUD";

const TbodyTipification = ({ data }) => {
  return (
    <Tr>
      <Td className="text-center">{data.name}</Td>
      <Td className="text-center">{data.descriptions}</Td>
      <Td className="text-center">
        <MenuRUD data={data} />
      </Td>
    </Tr>
  );
};

export default TbodyTipification;
