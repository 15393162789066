import React, { useState } from "react";
import { Title } from "styled/Title";
import "./styleForm.css";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { isValid } from "utils/type-check-utils";

export const FormCorrectionLog = ({ title, item, form = {} }) => {
  const [ShowMoreState, setShowMoreState] = useState(false);
  const navigate = useNavigate();

  const handleRouteCorrec = (elemento) => {
    if (elemento.date_entryFormCorrected) {
      return `/FormCorrection?ShowEntry=${elemento.id}`;
    } else {
      return `/FormCorrectionOutput?ShowOutput=${elemento.id}`;
    }
  };

  const ShowMore = () => {
    setShowMoreState((prev) => !prev);
  };

  const {
    entryFormCorrected_id,
    outputFormCorrected_id,
    final_balance_adjustment,
    date_statusEntryForm
  } = form || {};

  const ShowSettingsAssociated = () => {
    if (isValid(entryFormCorrected_id)) {
      navigate(`/FormCorrection?ShowEntry=${entryFormCorrected_id}`);
      return;
    }

    if (isValid(outputFormCorrected_id)) {
      navigate(`/FormCorrectionOutput?ShowOutput=${outputFormCorrected_id}`);
      return;
    }
  };


  return (
    <div id="adventenciaReturn" className="my-2 ms-2">
      <div className="d-flex">
        <div>
          <Title size={"18px"} weight={400}>
            {title && title}
          </Title>
          {item &&
            item.map((elemento, index) => (
              <>
                <span key={index}>
                  Creada:{" "}
                  {elemento.date_entryFormCorrected ||
                    elemento.date_outputFormCorrected}
                  .
                  <NavLink
                    to={handleRouteCorrec(elemento)}
                    className="ms-2 text-primary"
                  >
                    Ver correción.
                  </NavLink>
                  <br />
                </span>
              </>
            ))}
      

          {final_balance_adjustment === 1 && (
            <Title size={"18px"} weight={400} className={'pt-2'}>
              Este formulario cuenta con ajuste creado:
            </Title>
          )}

          {final_balance_adjustment === 1 && (
            <>
              <span
                className="text-primary"
                style={{
                  cursor: "pointer",
                }}
                onClick={ShowMore}
              >
                Ver más
              </span>
            </>
          )}
{console.log({date_statusEntryForm})}
          {ShowMoreState && <div /* className="ms-2" */>Creada: {date_statusEntryForm }</div>}
        </div>
      </div>
    </div>
  );
};
