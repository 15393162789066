import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from "@chakra-ui/react";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import React from "react";
import { SubTitle, Title } from "styled/Title";
import bro from "../../../../assets/bro.png";

const AcordionComponent = ({
  cabecera,
  changePage,
  TitleHeader,
  setCurrentPage,
  loading,
  TBodyEntryMovement,
  page,
  setPage,
  maxPage,
  nameModule,
  currentPage,
  stateNull,
  getDataTable
}) => {
  return (
    <Accordion allowToggle>
      {cabecera.map((item, index) => {
        const data = stateNull ? currentPage : currentPage?.[index]?.data;
        return (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton onClick={() => changePage(index, item)}>
                <Box flex="1" textAlign="left">
                  <Title size={"16px"}>{item?.name_freeZone}</Title>
                </Box>
                <AccordionIcon color="#1F7AC3" size={24} />
              </AccordionButton>
            </h2>

            <AccordionPanel pb={4}>
              <Accordion allowToggle marginTop={3}>
                {item.year.map((element, key) => (
                  <AccordionItem key={key}>
                    <h2>
                      <AccordionButton
                        onClick={() =>
                          getDataTable(element.date_rate, item.freeZone_id)
                        }
                      >
                        <Box flex="1" textAlign="left">
                          <Title size={"16px"} color="#454546">
                            {element?.date_rate}
                          </Title>
                        </Box>
                        <AccordionIcon color="#1F7AC3" size={24} />
                      </AccordionButton>
                    </h2>

                    <AccordionPanel pb={4}>
                      {currentPage
                        ? (
                          <>
                            {data?.length === 0
                              ? (
                                <>
                                  <div className="d-flex justify-content-center">
                                    <img
                                      style={{ width: `${20}%` }}
                                      src={window.assetURL + bro}
                                      alt="no hay formulario"
                                    />
                                  </div>
                                  <SubTitle size={"15px"} className="text-center ">
                                Aún no hay {nameModule} asociados a esta lista
                                  </SubTitle>
                                </>
                              )
                              : (
                                <div>
                                  <TableOrder
                                    thead={TitleHeader}
                                    data={data || []}
                                    setData={setCurrentPage}
                                    loading={loading}
                                    tbodyData={TBodyEntryMovement}
                                  />
                                  <Pagination
                                    page={page}
                                    setPage={setPage}
                                    maxPage={maxPage}
                                    loading={loading}
                                  />
                                </div>
                              )}
                          </>
                        )
                        : (
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                style={{ width: `${20}%` }}
                                src={window.assetURL + bro}
                                alt="no hay formulario"
                              />
                            </div>
                            <h4 className="text-center ">
                            Aún no hay {nameModule} asociados a esta lista
                            </h4>
                          </div>
                        )}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default AcordionComponent;
