/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import SearchFormAdvanced from "./SearchFormAdvanced";
import { ComponentAccordion } from "components/AccordionComponent/ComponentAccordion";
import { TheadAccordion } from "./TheadAccordion";
import { useSelector, useDispatch } from "react-redux";
import {
  __CustomsClearence,
  __SearchCustoms
} from "request/CustomsClearance.js/__CustomsClearence";
import TbodyTable from "./Tbody/TbodyTable";
import { HeaderSearch } from "styled/SectionContainer.styled";
import { useForm } from "hooks/useForm";
import TableOrder from "components/Tables/TableOrder";
import Pagination from "components/Pagination/Pagination";
import { ExportDetail } from "./Adjunto/ExportDetail";
import { useModal } from "hooks/useModal";
import {
  arrayListCustomClereance,
  arrayListCustomClereanceNoUC,
  arrayListCustomClereanceUC,
  customClearanceNodeInputFields
} from "./Adjunto/ArrayCheck";
import { ModalGeneric } from "components/modal/ModalGeneric";
import CustomsCrudContext from "context/CustomsCrudContext";
import { SearchAdvancedModule } from "components/SearchSimpleAdvanced/SearchAdvancedModule";
import environment from "constants/apiConst";
import { AuthContext } from "context/AuthContext";
import ModalCopy from "components/modal/ModalCopy";
import { Title } from "styled/Title";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { FormReturnReason } from "pages/Pass-Form/FormReturnReason";
import { ModalExportAdvance } from "components/ExportByColumn/ExportAdvance";
import { isArray, safelyConvertToString } from "utils/type-check-utils";

import { clearSearch, setSearch } from "redux/actions/searchActions";
import { theadCustomsClearanceAuthorization, theadCustomsClearanceEdit } from "./theadCustomsClearance";
import { useSearchUrl } from "hooks/useSearchUrl";

const CustomsClearance = () => {
  const { authUser } = useContext(AuthContext);
  const { state, clearParamsSearch } = useSearchUrl({ getParams: ["search", "searchAdvance"], nestedProp: "searchSimple" });
  const { cleanState, setParamsSearch } = useSearchUrl({ getParams: ["subheading", "acceptanceNumber", "acceptanceDate", "startDate", "endDate", "startTime", "endTime", "CustomsClearanceForm_number", "status", "orderType", "tradeAgreement_id", "dateFrom", "type_orderBy", "orderBy"], nestedProp: "searchAdvance" })

  const { token, freeZone_id } = useSelector((state) => state.auth.dataUser);
  const {
    exportColumnBy,
    setRequestChangeStatus,
    update,
    isOpenCopy,
    onCloseModal,
    copyData,
    dataToEdit,
    setRequestCopy,
    isOpenReturnReason,
    onOpenReturnReason,
    onCloseReturnReason
  } = useContext(CustomsCrudContext);
  // hook de los Modal
  const [isOpenSearch, onOpenSearch, onCloseSearch] = useModal();
  const [isOpenExport, onOpenExport, onCloseExport] = useModal();
  const [changeViewSearch, setChangeViewSearch] = useState(false);
  const [searcheAdvanced, setSearchAdvanced] = useState(cleanState);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPageTwo, setCurrentPageTwo] = useState([]);
  const [openAlert] = AlertErrorAlert();
  const dispatchRedux = useDispatch();

  const changePage = (index) => {
    setPage(1);
    setMaxPage(currentPage ? currentPage[index]?.last_page : 1);
  };

  const [values, handleInputChange, reset, setValues] = useForm({
    searchInformacion: "",
    valueQualified: [],
  });

  const { searchInformacion, valueQualified } = values;

  const backSearch = () => {
    reset();
    setSearchAdvanced([]);
    setChangeViewSearch(false);
    clearParamsSearch({ searchArray: ["search", "searchAdvance"] });
  };

  useEffect(() => {
    setRequestChangeStatus(environment.CUSTOMS_CHANGE_STATUS);
    setRequestCopy(environment.COPY_FORM_CUSTOMS_CLEARENCE);
    initialpos();
  }, [freeZone_id, page, token, update]);

  useEffect(() => {

    const newInfo = {
      ...searcheAdvanced,
      ...(searchInformacion && { search: searchInformacion }),
      ...(isArray(valueQualified) && valueQualified?.length !== 0
        ? { qualifiedUser_id: valueQualified?.map((item) => item?.value) }
        : valueQualified?.value && { qualifiedUser_id: valueQualified?.value })
    };

    if (Object.keys(newInfo).length !== 0) {
      setChangeViewSearch(true);
      dispatchRedux(setSearch(newInfo));
      searchSimple(newInfo);
      return;
    }

    dispatchRedux(clearSearch());
    setChangeViewSearch(false);
    initialpos();
    return;

  }, [searchInformacion, searcheAdvanced, page, freeZone_id, valueQualified]);

  useEffect(() => {
    return async () => dispatchRedux(clearSearch());
  }, []);

  const searchSimple = async (q) => {
    // console.log(valueQualified.value);
    setLoading(true);
    try {
      const res = await __SearchCustoms(token, q, page);
      if (res?.data?.status?.message === "Acceso no autorizado.") {
        reset();
        setSearchAdvanced([]);
        setChangeViewSearch(false);
        openAlert(res.data.status.message, "error");
      } else {
        setCurrentPageTwo(res?.data?.status?.data?.data);
        setMaxPage(res?.data?.status?.data?.last_page);
        // console.log(res?.data?.status);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      onCloseSearch();
    }
  };

  const initialpos = async () => {
    setLoading(true);
    try {
      const res = await __CustomsClearence(token, page);
      setCurrentPage(res?.data?.status?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data, formulario) => {
    const info2 = {
      ...data,
      type_export_file: "xlsx",
      ...(isArray(data?.type_export_file) &&
        data?.type_export_file?.length !== 0 && {
        type_export_file: data?.type_export_file?.value
      }),
      qualifiedUser_id: isArray(data?.qualifiedUser_id)
        ? data?.qualifiedUser_id?.map((item) => item?.value || item)
        : safelyConvertToString(data?.qualifiedUser_id?.value) !== ""
          ? data?.qualifiedUser_id?.value
          : data?.qualifiedUser_id || [],
      ...(data?.status && {
        status: isArray(data?.status)
          ? data?.status?.map((item) => item?.value || item)
          : data?.status?.value || []
      }),
      fields: formulario
    };

    const info = {
      fields: formulario,
      data
    };
    exportColumnBy(info2, () => onCloseExport());
  };

  const { user_rol, admin } = useSelector((state) => state.auth.dataUser);
  const [allAccess, setAllAccess] = useState(false);
  const [myPermission, setmyPermission] = useState(null);

  useEffect(() => {
    if (admin === 1) {
      setAllAccess(true);
    } else if (!allAccess) {
      const newArray = [];
      user_rol?.modules?.map((item) => {
        return item.permission.map((e) => newArray.push(e.name_permission));
      });
      setmyPermission(newArray);
    }
  }, [user_rol, allAccess, admin]);

  const calculateNodeList = () => {
    const arrayList = arrayListCustomClereance;
    if (authUser.is_qualified_user === 1) {
      return arrayList.concat(arrayListCustomClereanceUC);
    }
    return arrayList.concat(arrayListCustomClereanceNoUC);
  };

  const thead = {
    Autorizados: theadCustomsClearanceAuthorization,
    Editables: theadCustomsClearanceEdit,
  }

  const tbody = {
    Autorizados: TbodyTable,
    Editables: "",
  }

  return (
    <>
      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseModal}
        title={`Desea Copiar el formulario Nº ${dataToEdit?.customsClearanceForm_number}`}
        onCopy={copyData}
      />

      {/* modal del modulo desaduanamiento */}
      <ModalGeneric
        isOpen={isOpenSearch}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        title="Búsqueda avanzada desaduanamiento"
      >
        <SearchFormAdvanced
          onClose={onCloseSearch}
          setSearchAdvanced={setSearchAdvanced}
        />
      </ModalGeneric>

      <ModalExportAdvance
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
        nodeList={calculateNodeList()}
        handleSubmit={handleSubmit}
        selectNodeList={customClearanceNodeInputFields}
      />

      {/* <ModalGeneric
        isOpen={isOpenExport}
        onOpen={onOpenExport}
        onClose={onCloseExport}
        title="Exportar documentos"
      >
        <ExporByColumn
          onClose={onCloseExport}
          ListLeft={arrayLeft}
          listRight={
            authUser.is_qualified_user === 1 ? arrayRigthUser : arrayRigth
          }
          handleSubmit={handleSubmit}
          statusDataSelect={stateCustomsClearence}
          dafaulActive={[]}
        />
      </ModalGeneric>
 */}
      <ModalGeneric
        isOpen={isOpenReturnReason}
        onOpen={onOpenReturnReason}
        onClose={onCloseReturnReason}
        title="Motivo de devolución"
        size="xl"
        scrollBehavior="outside"
      >
        <FormReturnReason
          onClose={onCloseReturnReason}
          contextCurrent={CustomsCrudContext}
        />
      </ModalGeneric>

      <HeaderSearch>
        <Title>Desaduanamiento</Title>

        <SearchAdvancedModule
          handleInputChange={handleInputChange}
          values={values}
          onOpenSearch={onOpenSearch}
          changeViewSearch={changeViewSearch}
          backSearch={backSearch}
          permisoSearch={"getCustomsClearancesSearch"}
          allAccess={allAccess}
          setValues={setValues}
          myPermission={myPermission}
          MultipleQualifiedUsers={"multi"}
        />

        {allAccess === true ||
          myPermission?.includes("exportCustomsClearance")
          ? (
            <ExportDetail onOpen={onOpenExport} />
          )
          : (
            ""
          )}

        {changeViewSearch
          ? (
            currentPage !== undefined && (
              <div>
                <TableOrder
                  thead={theadCustomsClearanceAuthorization}
                  data={currentPageTwo}
                  setData={setCurrentPage}
                  loading={loading}
                  tbodyData={TbodyTable}
                />

                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  loading={loading}
                />
              </div>
            )
          )
          : (
            <div>
              <ComponentAccordion
                cabecera={TheadAccordion}
                currentPage={currentPage}
                TitleHeader={thead}
                setCurrentPage={setCurrentPage}
                loading={loading}
                TBodyEntryMovement={TbodyTable}
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                changePage={changePage}
                nameModule={"Desaduanamiento"}
              />
            </div>
          )}
      </HeaderSearch>
    </>
  );
};

export default CustomsClearance;
