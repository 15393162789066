import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Swal from "sweetalert2";
import { H2 } from "pages/Pass-Form/stylePases";
import { Title } from "styled/Title";
import { AuthContext } from "context/AuthContext";
import FormCrudCRUDContext from "context/FormCrudContex";
import { __Post } from "request/Petitions/__Post";
import environment from "constants/apiConst";
import { hasCommonElements } from "utils/type-check-utils";
import { localPermissions } from "Permissions/permissions";

// Objeto para mapear estados a etiquetas
const statusLabels = {
  BORRADOR: "Creado",
  PRESENTADO: "Presentado",
  AUTORIZADO: "Autorizado",
  APROBADO: "Aprobado",
  CORREGIDO: "Corregido",
  DEVUELTO: "Devuelto",
  ANULADO: "Terminado",
  TERMINADO: "Terminado",
  ELIMINADO: "Terminado",
  CADUCADO: "Caducado",
  REVISADO: "Revisado",
};

const CreateAt = ({
  entries = [],
  config,
  expirationDate,
  onChangeUser,
  customRender
}) => {
  const location = useLocation();
  const { FormEntry = null } = queryString.parse(location.search);
  const { token } = useSelector((state) => state.auth.dataUser);
  const { update, setUpdate } = useContext(FormCrudCRUDContext);
  const { authUser } = useContext(AuthContext);
  const { permissionList } = useSelector((state) => state.permissions);
  const { changeStatus: changeStatusEntryForm } = localPermissions.Entryform;

  // Memoizamos la función para mejorar el rendimiento
  const handleUser = useCallback(async () => {
    if (onChangeUser) {
      await onChangeUser();
      return;
    }

    const result = await Swal.fire({
      title: "¿Cambiar usuario?",
      text: "¿Deseas cambiar el usuario que autorizó?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Cambiar usuario",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#1e75bb",
      position: "top",
    });

    if (result.isConfirmed) {
      try {
        const res = await __Post(environment.CHANGE_USER_SYSTEM, token, {
          form_id: FormEntry,
        });
        if (res?.data?.status?.code === 200) {
          setUpdate((prev) => !prev);
        }
      } catch (error) {
        console.error("Error al cambiar el usuario:", error);
        Swal.fire("Error", "No se pudo cambiar el usuario", "error");
      }
    }
  }, [FormEntry, token, setUpdate, onChangeUser]);

  const isAuthorizedBySystem = useMemo(() => 
    Array.isArray(entries) && entries.some(
      (entry) =>
        entry[config?.statusKey] === "AUTORIZADO" &&
        entry[config?.personNameKey]?.trim() === "SYSTEM"
    ),
  [entries, config]);

  const canChangeUser = useMemo(() => 
    authUser?.is_qualified_user !== 1 &&
    (authUser?.admin === 1 || authUser?.is_commerce === 1) &&
    (hasCommonElements(permissionList, changeStatusEntryForm) ||
      authUser?.admin === 1) &&
    Array.isArray(entries) && entries.some(entry => entry[config?.statusKey] === "AUTORIZADO") &&
    isAuthorizedBySystem,
  [authUser, permissionList, changeStatusEntryForm, entries, config, isAuthorizedBySystem]);

  const renderEntry = (item, index) => {
    if (customRender) {
      return customRender(item, index);
    }

    return (
      <div className="col col-sm-6 sol-md-3" key={index}>
        <H2 className="m-1 d-flex">
          <Title size="18px">
            {statusLabels[item[config?.statusKey]] || ""}:{" "}
          </Title>
          <span className="ms-2 text-mute">
            {`${item[config?.dateKey] || ''} ${item[config?.timeKey] || ''} ${item[config?.personNameKey] || ''}`}
          </span>
        </H2>
      </div>
    );
  };

  if (!Array.isArray(entries) || entries.length === 0) {
    return null; // O podrías retornar un mensaje indicando que no hay entradas
  }

  return (
    <div
      className="row mt-4 ms-2"
      style={{ background: "#f2f2f2", padding: "10px" }}
    >
      {entries.map(renderEntry)}
      {expirationDate && (
        <div className="col col-sm-6 sol-md-3">
          <H2 className="m-1 d-flex">
            <Title size="18px">Vencimiento: </Title>
            <span className="ms-1 text-mute">{expirationDate}</span>
          </H2>
        </div>
      )}
      {canChangeUser && (
        <span
          style={{ color: "#1f7ac3", cursor: "pointer", textAlign: "end" }}
          onClick={handleUser}
        >
          Cambiar usuario
        </span>
      )}
    </div>
  );
};

export default React.memo(CreateAt);