import React, { useContext } from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { IconGeneric } from "styled/Icons.styled";
import { HiDotsHorizontal } from "react-icons/hi";
import { OptionsBorradorCustoms } from "./OptionsCustomsClearence/OptionsBorradorCustoms";
import { OptionsAutorizadoCustoms } from "./OptionsCustomsClearence/OptionsAutorizadoCustoms";
import CustomsCrudContext from "context/CustomsCrudContext";

const MenuCustomsClearence = ({ data }) => {
  const { changeStatus, onOpenReturnReason, setReturnReason } =
    useContext(CustomsCrudContext);
  const handlePresent = (status) => {
    const info = {
      id: data.id,
      status_customsClearance: status
    };
    if (status === "DEVUELTO") {
      onOpenReturnReason();
      setReturnReason(info);
    } else {
      changeStatus(info);
    }
  };
  return (
    <Menu>
      <MenuButton>
        <IconGeneric as={HiDotsHorizontal} color="#00000085" />
      </MenuButton>

      <MenuList borderRadius="none" py={0} style={{ boxSizing: "border-box" }}>
        {data.status_customsClearance === "BORRADOR" ||
        data.status_customsClearance === "DEVUELTO"
          ? (
            <OptionsBorradorCustoms data={data} handlePresent={handlePresent} />
          )
          : data.status_customsClearance === "AUTORIZADO"
            ? (
              <OptionsAutorizadoCustoms data={data} handlePresent={handlePresent} />
            )
            : (
              ""
            )}
      </MenuList>
    </Menu>
  );
};

export default MenuCustomsClearence;
