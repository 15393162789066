import styled from "styled-components/macro";
import passImg from "../assets/password-img.svg";

export const PasswordImage = styled.img.attrs({
  src: window.assetURL + passImg
})`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${({ margin }) => margin || "auto"};
`;
