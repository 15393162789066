import React, { useEffect } from "react";
import {
  FreeZoneToFreeZone,
  FromUserToUser,
  RestTerritory,
  RestWorld
} from "./Forms";
import { useNotations } from "context/NotationsContext";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const ContainerForms = ({
  formOperation,
  code,
  values,
  handleInputChange,
  setValues,
}) => {
  const location = useLocation();
  const { FormExitNumber = null, FormEntryNumber = null } = queryString.parse(
    location.search
  );
  const idForm = FormExitNumber || FormEntryNumber;
  const { reviewsData, updateNotationData } = useNotations();

  const reviewData = reviewsData?.Header;
  const colorNotation = (propertyName) => {
    return (
      updateNotationData?.[idForm]?.Header?.[propertyName]?.status?.color ||
      reviewData?.[propertyName]?.status?.color
    );
  };

  if (code >= 101 && code <= 199) {
    return (
      <div>
        <RestWorld
          formOperation={formOperation}
          values={values}
          handleInputChange={handleInputChange}
          code={code}
          setValues={setValues}
          colorNotation={colorNotation}
          reviewData={reviewData}
        />
      </div>
    );
  }
  if (code >= 300 && code <= 399) {
    return (
      <div>
        <RestTerritory
          formOperation={formOperation}
          values={values}
          handleInputChange={handleInputChange}
          code={code}
          setValues={setValues}
          colorNotation={colorNotation}
          reviewData={reviewData}
        />
      </div>
    );
  }
  if (code >= 500 && code <= 599) {
    return (
      <div>
        <FreeZoneToFreeZone
          formOperation={formOperation}
          values={values}
          handleInputChange={handleInputChange}
          code={code}
          setValues={setValues}
          colorNotation={colorNotation}
          reviewData={reviewData}
        />
      </div>
    );
  }
  if (code >= 700 && code <= 799) {
    return (
      <div>
        <FromUserToUser
          formOperation={formOperation}
          values={values}
          handleInputChange={handleInputChange}
          code={code}
          setValues={setValues}
          colorNotation={colorNotation}
          reviewData={reviewData}
        />
      </div>
    );
  }

  return <></>;
};

export default ContainerForms;
