/* eslint-disable react/no-children-prop */
import { AiOutlineFieldNumber } from "react-icons/ai";
import React from "react";
import {
  InputGroup,
  InputLeftElement,
  Input,
  GridItem,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { BiPhoneIncoming } from "react-icons/bi";
import { CgTemplate } from "react-icons/cg";
import { HiOutlineIdentification } from "react-icons/hi";
import { MdDriveEta } from "react-icons/md";

export const ContentModalUpdatePass = ({ handleInputChange, data }) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
      <GridItem>
        <FormLabel>Placa vehículo</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<CgTemplate color="gray.300" />}
          />
          <Input
            type="text"
            defaultValue={data?.vehicle_plate}
            name="vehicle_plate"
            onChange={handleInputChange}
            placeholder="Placa"
          />
        </InputGroup>
      </GridItem>
      <GridItem>
        <FormLabel>Cédula del conductor</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<HiOutlineIdentification color="gray.300" />}
          />
          <Input
            type="number"
            name="identification"
            defaultValue={data?.identification}
            onChange={handleInputChange}
            placeholder="Cédula"
          />
        </InputGroup>
      </GridItem>
      <GridItem>
        <FormLabel>Conductor</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<MdDriveEta color="gray.300" />}
          />
          <Input
            type="text"
            name="driver"
            defaultValue={data?.driver}
            onChange={handleInputChange}
            placeholder="Conductor"
          />
        </InputGroup>
      </GridItem>
      <GridItem>
        <FormLabel>Número de teléfono</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<BiPhoneIncoming color="gray.300" />}
          />
          <Input
            type="tel"
            name="phone"
            defaultValue={data?.phone}
            onChange={handleInputChange}
            placeholder="Número de teléfono"
          />
        </InputGroup>
      </GridItem>
      <GridItem>
        <FormLabel>Numero de Bulto</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineFieldNumber color="gray.300" />}
          />
          <Input
            type="text"
            name="number_packages"
            defaultValue={data?.number_packages}
            onChange={handleInputChange}
            placeholder="Numero de Bultos"
          />
        </InputGroup>
      </GridItem>
    </Grid>
  );
};
