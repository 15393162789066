/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, TabPanel, Tooltip, useDisclosure } from "@chakra-ui/react";
import { TabsCreateOrder } from "./TabsCreate/TabsCreateOrder";
import { CreateNewProductOrder } from "./CreateNewProductoOrder/CreateNewProductOrder";
import { headerTabs } from "./ArrayOrder/ArrayHeadOrder";
import { useForm } from "hooks/useForm";
import Pagination from "components/Pagination/Pagination";
import TableOrder from "components/Tables/TableOrder";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import {
  elaborateProduct,
  HeadGarbages,
  HeadOtherCosts,
  Matter,
  MatterNoUC,
} from "./ArrayOrder/ArrayTableOrder";
import { useSelector } from "react-redux";
import { __ShowFormOrder } from "request/OrderProduction/OrderProduction";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import SpinnerComponent from "components/Spinner/SpinnerComponent";
import { ModalGeneric } from "components/modal/ModalGeneric";
import { FormTemplate } from "./CreateNewProductoOrder/FormTemplate";
import TBodyElaborado from "./CreateNewProductoOrder/Table/TBodyElaborado";
import TBodyMatter from "./CreateNewProductoOrder/Table/TBodyMatter";
import TbodyOtherCosts from "./CreateNewProductoOrder/Table/TbodyOtherCosts";
import TBodyGarbages from "./CreateNewProductoOrder/Table/TBodyGarbages";
import OrderProductionContext from "context/OrderProductionContext";
import { FormMatterAnd } from "./CreateNewProductoOrder/FormMatterAnd";
import { ChangeItems } from "./CreateNewProductoOrder/ChangeItems";
import { tdGarbages, tdMatter, tdOther, tdProduct } from "./Tdfalse/TdFlase";
import ModalAdvertencia from "components/modal/ModalAdvertencia";
import { HeaderForm } from "components/HeaderForm/HeaderForm";
import { AlertReturnReason } from "components/AlertReasonReturn/AlertReturnReason";
import { FormDpsForMatter } from "./CreateNewProductoOrder/FormDpsForMatter";
import TbodyUnd from "./CreateNewProductoOrder/Table/TbodyUnd";
import TbodyGarbagesUnit from "./CreateNewProductoOrder/Table/TbodyGarbagesUnit";
import { BtnShowBorrador } from "./BotonesShow/BtnShowBorrador";
import ModalCopy from "components/modal/ModalCopy";
import { ButtonHeaderModule, ButtonStyled } from "styled/Buttons.styled";
import { __AllQualifiedUSers } from "request/configuration/__QualifiedUsers";
import { AlertErrorAlert } from "components/Alerts/AlertErrorAlert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import GlobalContext, { useGlobalContext } from "context/GlobalContext";
import environment from "constants/apiConst";
import isArrayNotEmpty, {
  isArray,
  isNonEmptyString,
  isObject,
  isString,
  isValid,
} from "utils/type-check-utils";
import TabOptions from "./TabOptions";
import { IconGeneric } from "styled/Icons.styled";
import CreatePreparedProductOP from "./createPreparedProductOP";
import { AddSupplies } from "./AddSupplies";
import FormAddOtherCostOP from "pages/IntegrationBox/Form/FormAddOtherCostOP";
import { RiFileAddLine } from "react-icons/ri";
import { MdAddShoppingCart, MdOutlineLibraryAdd } from "react-icons/md";
import FormAddProductOP from "pages/IntegrationBox/Form/FormAddProductOP";
import { useGetUserQualified } from "hooks/useUserQualified";
import { setDataStatusBalance } from "redux/actions/statusBalanceaction";
import InputSelect from "components/InputSelect/InputSelect";
import { useDispatch } from "react-redux";
import { AuthContext } from "context/AuthContext";
import { parseNumberWithCommas } from "pages/Entry_and_exit_forms/FormEntry/AddItemForm/parseNumberWithCommas";
import { parseFloat } from "utils/parseFloat";

export const FormCreateOrder = () => {
  const { token } = useSelector((state) => state.auth.dataUser);
  const [currentPage, setCurrentPage] = useState([]);
  const indexTabs = JSON.parse(localStorage.getItem("indexTabs"));
  const [positionView, setPositionView] = useState(indexTabs || 0);
  const queryClient = useQueryClient();

  const positionProperties = ["product", "supply", "other_costs", "garbage"];
  const {
    isOpenCreatePreparedProduct,
    onOpenCreatePreparedProduct,
    onCloseCreatePreparedProduct,
    isOpenCreateSupplyItemOP,
    onOpenCreateSupplyItemOP,
    onCloseCreateSupplyItemOP,
    isOpenAddOtherCosts,
    onOpenAddOtherCosts,
    onCloseAddOtherCosts,
    isOpenCreateProduct,
    onOpenCreateProduct,
    onCloseCreateProduct,
    setDataToEditOP,
    dataToEditOP,
  } = useGlobalContext();

  const {
    isOpenTemplate,
    onOpenTemplate,
    onCloseTemplate,
    isOpenEdit,
    onOpenEdit,
    onCloseEdit,
    update,
    setDataToGeneral,
    isOpenProduct,
    onOpenProduct,
    onCloseProduct,
    isOpenChange,
    onOpenChange,
    onCloseChange,
    isOpenWarning,
    onCloseWarning,
    deleteData,
    dataToGeneral,
    isOpenCopy,
    onCloseCopy,
    dataToCopy,
    copyData,
    changeStatus,
    setDataToCopy,
    onOpenCopy,
    onOpenReturnReason,
    setReturnReason,
    setDecimalUc,
  } = useContext(OrderProductionContext);

  const dispatch = useDispatch();
  const { balance } = useSelector((state) => state.status);

  const [maxPage, setMaxPage] = useState({
    product: 1,
    supply: 1,
    other_costs: 1,
    garbage: 1,
  });

  const [page, setPage] = useState({
    product: 1,
    supply: 1,
    other_costs: 1,
    garbage: 1,
  });

  const renderComponent = {
    product: {
      create: {
        component: (
          <Tooltip placement="top" hasArrow label="Agregar producto">
            <span>
              <ButtonHeaderModule
                bgColor="#0089BA"
                onClick={onOpenCreatePreparedProduct}
              >
                <IconGeneric
                  color="#fff"
                  as={MdAddShoppingCart}
                  className={"icons"}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        ),
      },
      createTemplate: {
        component: (
          <Tooltip placement="top" hasArrow label="Agregar Plantilla">
            <span>
              <ButtonHeaderModule bgColor="#0089BA" onClick={onOpenTemplate}>
                <IconGeneric
                  color="#fff"
                  as={RiFileAddLine}
                  className={"icons"}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        ),
      },
    },
    supply: {
      create: {
        component: (
          <Tooltip placement="top" hasArrow label="Agregar insumo">
            <span>
              <ButtonHeaderModule
                bgColor="#0089BA"
                onClick={onOpenCreateSupplyItemOP}
              >
                <IconGeneric
                  color="#fff"
                  as={MdAddShoppingCart}
                  className={"icons"}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        ),
      },
      createProduct: {
        component: (
          <Tooltip placement="top" hasArrow label="Agregar producto">
            <span>
              <ButtonHeaderModule
                className="d-flex float-end"
                bgColor="#F9A621"
                onClick={onOpenCreateProduct}
              >
                <IconGeneric color="#fff" as={MdOutlineLibraryAdd} />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        ),
      },
    },
    other_costs: {
      create: {
        component: (
          <Tooltip placement="top" hasArrow label="Agregar otros costos">
            <span>
              <ButtonHeaderModule
                bgColor="#0089BA"
                onClick={onOpenAddOtherCosts}
              >
                <IconGeneric
                  color="#fff"
                  as={MdAddShoppingCart}
                  className={"icons"}
                />
              </ButtonHeaderModule>
            </span>
          </Tooltip>
        ),
      },
    },
  };

  const location = useLocation(); // navigate
  const { Orden = null } = queryString.parse(location.search);
  const dataUser = useSelector((state) => state.auth.dataUser);
  const { authUser } = useContext(AuthContext);
  const [values, handleInputChange, reset, setValues] = useForm({
    production_order_status: "",
    production_order_code: "",
    qualified_users_name: null,
    qualified_users_id: null,
  });

  const { requestData, setUser_Qualified, setManagerData } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const {
    production_order_status,
    production_order_code,
    qualified_users_name,
    qualified_users_id,
    reason_for_return,
    typifications,
    nickname,
  } = values || {};

  const handleCambio = (index) => {
    setPositionView(index);
  };

  const handleChangeStatus = (status) => {
    const info = {
      id: values?.production_order_id,
      status_ProductionOrderForm: status,
    };
    if (status === "DEVUELTO") {
      onOpenReturnReason();
      setReturnReason(info);
    } else {
      changeStatus(info);
    }
  };

  const handleCopy = () => {
    setDataToCopy(values);
    onOpenCopy();
  };

  const userC = useCallback(async () => {
    try {
      const res = await __AllQualifiedUSers(token);
      const serachUc = res?.data?.status?.data.find(
        ({ id }) => id === Number(qualified_users_id)
      );
      setDecimalUc(serachUc.decimal_values_precision);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    if (qualified_users_id) {
      userC();
    }
  }, []);

  const [openAlert] = AlertErrorAlert();
  const firstRender = useRef(true);

  const handleResponseDelete = (res) => {
    if (res?.data?.status?.code === 400) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      return navigate(`/OrdenDeProduccion`);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: requestData,
  });

  const handleDelete = (id) => {
    const config = {
      data: { id },
      Endpoint: environment.DELETE_PRODUCTION_ORDER,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => handleResponseDelete(data),
    });
  };

  const {
    isOpen: isOpenWarningDelete,
    onOpen: onOpenWarningDelete,
    onClose: onCloseWarningDelete,
  } = useDisclosure();

  const QueryConfig = {
    GET_PRODUCT_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_PRODUCT_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.product,
        }),
    },
    GET_SUPPLY_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_SUPPLY_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.supply,
        }),
    },
    GET_OTHERCOST_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_OTHERCOST_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.other_costs,
        }),
    },
    GET_GARBAGE_ITEM_OP: {
      queryFn: () =>
        requestData({
          Endpoint: environment.GET_GARBAGE_ITEM_OP,
          PropertyBody: "POST",
          resourceIdentifier: Orden,
          pageParam: page?.garbage,
        }),
    },
    SHOW_PRODUCTION_ORDER: {
      queryFn: () =>
        requestData({
          Endpoint: environment.SHOW_PRODUCTION_ORDER,
          PropertyBody: "GET",
          resourceIdentifier: Orden,
        }),
    },
  };

  //BEGIN - Datos de la orden de producción
  const { data: productionOrderData, isLoading: isLoadingProductionOrderData } =
    useQuery({
      queryKey: ["getProductionOrderData", Orden, update, token],
      queryFn: QueryConfig.SHOW_PRODUCTION_ORDER.queryFn,
      enabled: isValid(Orden),
    });

  useEffect(() => {
    if (isValid(productionOrderData) && !isLoadingProductionOrderData) {
      const productionData = productionOrderData?.data?.status?.data;
      setManagerData((prev) => ({
        ...prev,
        UserQualified: {
          label: `${productionOrderData?.data?.status?.data?.qualified_users_nit} - ${productionOrderData?.data?.status?.data?.qualified_users_name}`,
          value: productionOrderData?.data?.status?.data?.qualified_users_id,
        },
      }));

      setSelectUserQualified({
        label: `${productionOrderData?.data?.status?.data?.qualified_users_nit} - ${productionOrderData?.data?.status?.data?.qualified_users_name}`,
        value: productionOrderData?.data?.status?.data?.qualified_users_id,
      });
      //setSelectUserQualified
      setValues(productionData);
      setDataToGeneral(productionData);
    }
  }, [productionOrderData]);

  // END - Datos de la orden de producción

  //BEGIN - Producto elaborado
  const { data: getProductItemOP, isLoading: isLoadingGetProductItemOP } =
    useQuery({
      queryKey: ["getProductItemOP", Orden, update, page?.product],
      queryFn: QueryConfig.GET_PRODUCT_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const ProductItemOP = getProductItemOP?.data?.status?.data?.data;
  const totalSumProductItemOP =
    isValid(ProductItemOP) && isArray(ProductItemOP)
      ? ProductItemOP.reduce(
          (sum, item) => sum + parseInt(item?.quantity || 0),
          0
        )
      : 0;

  //END - Producto elaborado

  //BEGIN - Materias primas e insumos
  const { data: getSupplyItemOP, isLoading: isLoadingGetSupplyItemOP } =
    useQuery({
      queryKey: ["getSupplyItemOP", Orden, update, page?.supply],
      queryFn: QueryConfig.GET_SUPPLY_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const SupplyItemOP = getSupplyItemOP?.data?.status?.data?.data;
  const totalSumSupplyItemOP =
    isValid(SupplyItemOP) && isArray(SupplyItemOP)
      ? SupplyItemOP.reduce(
          (sum, item) => sum + parseInt(item?.quantity || 0),
          0
        )
      : 0;

  //END - Materias primas e insumos

  //BEGIN - Otros costos y gastos
  const { data: getOtherCostItemOP, isLoading: isLoadingGetOtherCostItemOP } =
    useQuery({
      queryKey: ["getOtherCostItemOP", Orden, update, page?.other_costs],
      queryFn: QueryConfig.GET_OTHERCOST_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const OtherCostItemOP = getOtherCostItemOP?.data?.status?.data?.data;
  const totalOtherCostItemOP =
    isValid(OtherCostItemOP) && isArray(OtherCostItemOP)
      ? OtherCostItemOP.reduce(
          (sum, item) => sum + parseInt(item?.net_weight_cost || 0),
          0
        )
      : 0;

  //END - Otros costos y gastos

  //BEGIN - Desperdicios
  const { data: getGarbageItemOP, isLoading: isLoadingGetGarbageItemOP } =
    useQuery({
      queryKey: ["getGarbageItemOP", Orden, update, page?.garbage],
      queryFn: QueryConfig.GET_GARBAGE_ITEM_OP.queryFn,
      enabled: isValid(Orden),
      placeholderData: keepPreviousData,
    });

  const GarbageItemOP = getGarbageItemOP?.data?.status?.data?.data;
  //END - Desperdicios

  useEffect(() => {
    if (isValid(ProductItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        product: getProductItemOP?.data?.status?.data?.last_page,
      }));
    }

    if (isValid(SupplyItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        supply: getSupplyItemOP?.data?.status?.data?.last_page,
      }));
    }
    if (isValid(OtherCostItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        other_costs: getOtherCostItemOP?.data?.status?.data?.last_page,
      }));
    }

    if (isValid(GarbageItemOP)) {
      setMaxPage((prev) => ({
        ...prev,
        garbage: getGarbageItemOP?.data?.status?.data?.last_page,
      }));
    }
  }, [GarbageItemOP, OtherCostItemOP, ProductItemOP, SupplyItemOP]);

  const respCreateProductItemOP = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["getProductItemOP"] });
      onCloseCreatePreparedProduct();
      openAlert(res?.data?.status?.message, "success");
      return;
    }
  };

  const respCreateSupplyItemOP = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      if (
        isArray(res?.data?.status?.message) &&
        isArrayNotEmpty(res?.data?.status?.message)
      ) {
        res?.data?.status?.message.map((item) => {
          return openAlert(item, "error");
        });

        return;
      }

      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["getSupplyItemOP"] });
      onCloseCreateSupplyItemOP();
      openAlert(res?.data?.status?.message, "success");
      return;
    }
  };

  const respCreateOtherCostItemOP = (res) => {
    if (res?.data?.status?.code === 400 || res?.data?.status?.code === 403) {
      if (
        isArray(res?.data?.status?.message) &&
        isArrayNotEmpty(res?.data?.status?.message)
      ) {
        res?.data?.status?.message.map((item) => {
          return openAlert(item, "error");
        });

        return;
      }

      return openAlert(res?.data?.status?.message, "error");
    }

    if (res?.data?.status?.code === 200) {
      queryClient.invalidateQueries({ queryKey: ["getOtherCostItemOP"] });
      onCloseAddOtherCosts();
      openAlert(res?.data?.status?.message, "success");
      return;
    }
  };

  const createOrEditSupplies = (data) => {
    const formdata = new FormData();

    if (!isValid(dataToEditOP)) {
      formdata.append("id", values?.production_order_id || dataToGeneral?.id);
    }

    if (isValid(dataToEditOP)) {
      formdata.append(
        "production_order_id",
        values?.production_order_id || dataToGeneral?.id
      );
    }

    if (isValid(data?.type)) {
      formdata.append("type_garbage", data?.type);
    }

    const { type: _, ...rest } = data || {};
    //parseNumberWithCommas
    if (isObject(rest)) {
      for (const key in rest) {
        if (isValid(rest[key]))
          formdata.append(
            key,
            isString(rest[key], true)
              ? parseNumberWithCommas(rest[key])
              : rest[key]
          );
      }
    }

    const config = {
      data: formdata,
      Endpoint: isValid(dataToEditOP)
        ? environment.UPDATE_SUPPLY_ITEM_OP
        : environment.CREATE_SUPPLY_ITEM_OP,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => respCreateSupplyItemOP(data),
    });
  };

  const CreateProductItemOP = (data) => {
    const formdata = new FormData();

    if (!isValid(dataToEditOP)) {
      formdata.append("id", values?.production_order_id || dataToGeneral?.id);
    }

    if (isValid(dataToEditOP)) {
      formdata.append(
        "production_order_id",
        values?.production_order_id || dataToGeneral?.id
      );
    }

    formdata.append("product_id", data?.product_id);

    if (isObject(data)) {
      const propertiesToIgnore = ["product_id"];

      for (const key in data) {
        if (propertiesToIgnore.includes(key)) continue;
        if (isValid(data[key])) formdata.append(key, data[key]);
      }
    }

    const config = {
      data: formdata,
      Endpoint: isValid(dataToEditOP)
        ? environment.UPDATE_PRODUCT_ITEM_OP
        : environment.CREATE_PRODUCT_ITEM_OP,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => respCreateProductItemOP(data),
    });
  };

  const createOrEditOtherCostOP = (data) => {
    const formdata = new FormData();

    if (!isValid(dataToEditOP)) {
      formdata.append("id", values?.production_order_id || dataToGeneral?.id);
    }

    if (isValid(dataToEditOP)) {
      formdata.append(
        "production_order_id",
        values?.production_order_id || dataToGeneral?.id
      );
    }

    if (isObject(data)) {
      for (const key in data) {
        if (isValid(data[key]) && isNonEmptyString(data[key]))
          formdata.append(
            key,
            isString(data[key], true) ? parseFloat(data[key]) : data[key]
          );
      }
    }

    const config = {
      data: formdata,
      Endpoint: isValid(dataToEditOP)
        ? environment.UPDATE_OTHERS_COST_OP
        : environment.CREATE_OTHERS_COST_OP,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (data) => respCreateOtherCostItemOP(data),
    });
  };

  useEffect(() => {
    if (isValid(qualified_users_id)) {
      setUser_Qualified(qualified_users_id);
    }
  }, [qualified_users_id]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (
        !isOpenCreatePreparedProduct &&
        !isOpenCreateSupplyItemOP &&
        !isOpenAddOtherCosts
      ) {
        setDataToEditOP(null);
      }
    }
  }, [
    isOpenCreatePreparedProduct,
    isOpenCreateSupplyItemOP,
    isOpenAddOtherCosts,
  ]);

  const handleShow = (data) => {
    navigate(`/VerOrdenDeProduccion?Orden=${Orden}`);
    if (isValid(qualified_users_name) && isValid(qualified_users_id)) {
      setSelectUserQualified({
        value: qualified_users_id,
        label: qualified_users_name,
      });
    }
  };

  const {
    userQualified,
    isLoading: userQualifiedLoading,
    selectUserQualified,
    setSelectUserQualified,
  } = useGetUserQualified();

  const createOP = () => {
    const formData = new FormData();
    formData.append("qualifiedUser_id", selectUserQualified?.value);

    const config = {
      data: formData,
      Endpoint: environment.CREATE_PRODUCTION_ORDER,
      PropertyBody: "sendJSONContentPOST",
    };

    mutate(config, {
      onSuccess: (e) => respCreateOP(e),
    });
  };

  const respCreateOP = (res) => {
    if (res?.data?.status?.code === 200) {
      const numberFormIn = res.data.status.data.production_order;
      const BalanceOrder = res.data.status.data?.balance;
      localStorage.setItem("ValueBalance", JSON.stringify(BalanceOrder));

      navigate(`/CrearOrdenDeProduccion?Orden=${numberFormIn.id}`);
      dispatch(setDataStatusBalance(!balance));
      reset();
    }

    if (res?.data?.status?.code !== 200) {
      openAlert(res.data.status.message, "error");
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (
        !isOpenCreatePreparedProduct &&
        !isOpenCreateSupplyItemOP &&
        !isOpenAddOtherCosts
      ) {
        setDataToEditOP(null);
      }
    }
  }, [
    isOpenCreatePreparedProduct,
    isOpenCreateSupplyItemOP,
    isOpenAddOtherCosts,
  ]);

  useEffect(() => {
    if (isValid(qualified_users_id)) {
      setUser_Qualified(qualified_users_id);
    }
  }, [qualified_users_id]);

  return (
    <>
      <ModalCopy
        isOpen={isOpenCopy}
        onClose={onCloseCopy}
        title={`Desea Copiar el formulario Nº ${dataToCopy?.production_order_code}`}
        onCopy={copyData}
      />

      <ModalGeneric
        isOpen={isOpenCreateProduct}
        onClose={onCloseCreateProduct}
        title={"Agregar producto"}
        size={"6xl"}
      >
        <FormAddProductOP onCloseModal={onCloseCreateProduct} id={Orden} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenTemplate}
        onOpen={onOpenTemplate}
        onClose={onCloseTemplate}
        title="Agregar Plantilla"
        size="xl"
      >
        <FormTemplate
          Orderid={Orden}
          UserId={qualified_users_id}
          location={"Orden de produccion"}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCreatePreparedProduct}
        onOpen={onOpenCreatePreparedProduct}
        onClose={onCloseCreatePreparedProduct}
        title="Agregar producto"
        size={"6xl"}
      >
        <CreatePreparedProductOP
          submit={CreateProductItemOP}
          onClose={onCloseCreatePreparedProduct}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenCreateSupplyItemOP}
        onOpen={onOpenCreateSupplyItemOP}
        onClose={onCloseCreateSupplyItemOP}
        title="Agregar insumo"
        size={"6xl"}
      >
        <AddSupplies
          submit={createOrEditSupplies}
          onClose={onCloseCreateSupplyItemOP}
          precision={parseNumberWithCommas(
            productionOrderData?.data?.status?.data?.precision || 4
          )}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenAddOtherCosts}
        onOpen={onOpenAddOtherCosts}
        onClose={onCloseAddOtherCosts}
        title="Agregar otros costos"
        size={"6xl"}
      >
        <FormAddOtherCostOP
          onCloseModal={onCloseAddOtherCosts}
          onSubmit={createOrEditOtherCostOP}
          apiCreate={environment.CREATE_OTHERS_COST_OP}
        />
      </ModalGeneric>

      <ModalAdvertencia
        isOpen={isOpenWarningDelete}
        onClose={onCloseWarningDelete}
        title={`Eliminar item`}
        titleBody={`¿Desea eliminar el Formulario Nº ${values?.production_order_code}?`}
        generar={() => handleDelete(values?.production_order_id)}
      />

      <ModalGeneric
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        title="Agregar desperdicios"
        size="3xl"
      >
        {/* {formModal} */}
        <FormDpsForMatter
          dataSelect={currentPage?.suplies}
          onCloseEdit={onCloseEdit}
        />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenProduct}
        onOpen={onOpenProduct}
        onClose={onCloseProduct}
        title="Agregar item"
        size="xl"
      >
        <FormMatterAnd Orderid={Orden} UserId={qualified_users_id} />
      </ModalGeneric>

      <ModalGeneric
        isOpen={isOpenChange}
        onOpen={onOpenChange}
        onClose={onCloseChange}
        title="Agregar masivamente"
        size="xl"
      >
        <ChangeItems Orderid={Orden} UserId={qualified_users_id} />
      </ModalGeneric>

      <ModalAdvertencia
        isOpen={isOpenWarning}
        onClose={onCloseWarning}
        title={`Eliminar item`}
        titleBody={`Los insumos que fueron descontados para esta orden de producción Nro. ${dataToGeneral?.production_order_code} volverán a estar disponible en el inventario`}
        generar={deleteData}
      />

      <Box>
        {isLoadingProductionOrderData && <SpinnerComponent />}
        {!isLoadingProductionOrderData && (
          <HeaderForm
            status={production_order_status}
            numberForm={production_order_code}
            UserQualified={qualified_users_name}
            Modulo={`${
              !isValid(Orden) ? "Creación" : "Edición"
            } de Formulario de Orden de producción En Zonas Francas`}
          />
        )}

        {authUser.is_qualified_user !== 1 && (
          <InputSelect
            tag={"Usuario calificado"}
            data={userQualified}
            set={setSelectUserQualified}
            value={selectUserQualified}
            isLoading={userQualifiedLoading}
            isDisabled={
              isValid(qualified_users_name) && isValid(qualified_users_id)
            }
          />
        )}

        {currentPage?.product?.length !== 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className="my-2"
          >
            {production_order_status &&
              (production_order_status === "BORRADOR" ||
                production_order_status === "DEVUELTO") && (
                <BtnShowBorrador
                  handleChangeStatus={handleChangeStatus}
                  handleCopy={handleCopy}
                  handleDelete={onOpenWarningDelete}
                />
              )}
          </Box>
        )}

        {reason_for_return && (
          <AlertReturnReason
            title={"Tipo de devolución"}
            item={typifications}
            description={reason_for_return && reason_for_return}
            nickname={nickname}
          />
        )}

        {currentPage?.product?.length === 0 && (
          <CreateNewProductOrder
            stateTabs={positionView}
            currentPage={currentPage}
          />
        )}
        <Box pt={10}>
          {isValid(Orden) && (
            <TabOptions
              renderComponent={renderComponent}
              tabProperty={positionProperties[positionView]}
              title={"Ítem del Formulario"}
            >
              {/*  {production_order_status &&
            (production_order_status === "BORRADOR" ||
              production_order_status === "DEVUELTO") && (
              <BtnShowBorrador
                handleChangeStatus={handleChangeStatus}
                handleCopy={handleCopy}
                handleDelete={onOpenWarningDelete}
              />
            )} */}
            </TabOptions>
          )}

          <TabsCreateOrder head={headerTabs} change={handleCambio}>
            <TabPanel>
              <TableOrder
                thead={elaborateProduct}
                data={ProductItemOP || []}
                setData={setCurrentPage}
                loading={isLoading}
                tbodyData={TBodyElaborado}
                subTotals={totalSumProductItemOP}
                titleTotal="Total"
                td={tdProduct}
              />
            </TabPanel>
            <TabPanel>
              <TableOrder
                thead={dataUser?.is_qualified_user === 0 ? Matter : MatterNoUC}
                data={SupplyItemOP || []}
                setData={setCurrentPage}
                loading={isLoading}
                tbodyData={TBodyMatter}
                subTotals={totalSumSupplyItemOP}
                titleTotal="Total"
                td={tdMatter}
                tUnid={currentPage?.unit_array_value}
                tbodyUnd={TbodyUnd}
              />
            </TabPanel>
            <TabPanel>
              <TableOrder
                thead={HeadOtherCosts}
                data={OtherCostItemOP || []}
                setData={setCurrentPage}
                loading={isLoading}
                tbodyData={TbodyOtherCosts}
                subTotals={totalOtherCostItemOP}
                titleTotal="Total"
                td={tdOther}
              />
            </TabPanel>
            <TabPanel>
              <TableOrder
                thead={HeadGarbages}
                data={GarbageItemOP || []}
                setData={setCurrentPage}
                loading={isLoading}
                tbodyData={TBodyGarbages}
                subTotals={currentPage?.totals_garbages}
                titleTotal="Total"
                td={tdGarbages}
                tUnid={currentPage?.unit_array_value_garbage}
                tbodyUnd={TbodyGarbagesUnit}
              />
            </TabPanel>
            <Pagination
              page={page[positionProperties[positionView]]}
              setPage={setPage}
              StoreProperty={positionProperties[positionView]}
              maxPage={maxPage[positionProperties[positionView]] || 1}
              loading={isLoading}
            />
          </TabsCreateOrder>

          <div className="mb-3 ms-5">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap={"wrap"}
            >
              <ButtonStyled
                style={{ marginBottom: "40px" }}
                onClick={!isValid(Orden) ? createOP : handleShow}
              >
                Guardar
              </ButtonStyled>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};
