import React from "react";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import Select from "react-select";

export const GroupPerm = ({ data, tag, size, set, state }) => {
  const selectValor = (optionesVa) => {
    const valores = [];
    optionesVa.forEach((element) => {
      valores.push({
        label: element?.name_permissionGroup,
        value: element?.id
      });
    });
    return valores;
  };
  const handleSelectChange = (items) => {
    set(items);
  };
  return (
    <div style={{ width: `${size}%` }}>
      <LabelStyled>{tag}</LabelStyled>
      <Select
        value={{ label: state.label, value: state.value }}
        options={selectValor(data)}
        onChange={(items) => {
          handleSelectChange(items);
        }}
      />
    </div>
  );
};
