/* eslint-disable no-unused-vars */
import { BarChart } from "components/Charts";

import React, { useState, useCallback, useRef, useEffect } from "react";
import { IconGeneric } from "styled/Icons.styled";
import { Title } from "styled/Title";
import { IoIosArrowForward } from "react-icons/io";
import TableOrder from "components/Tables/TableOrder";
import { theadEstadisticasItem } from "./ArrayEstadisticas";
import { TbodyItems } from "./Tbody/TbodyItems";
import { HeaderEstadistica } from "components/Estadistica/HeaderEstadistica";

import { Bar } from "react-chartjs-2";

import { ExportPdf } from "components/Export/ExportPdf";
import { ExportPdfGrafica } from "./ExportPdfGrafica";
import { estadisticaOptions } from "./EstadisticaOptions";

export const EstadisticaItem = ({
  title,
  data,
  loading,
  filterEst,
  setfilterEst,
  setCurrentPage
}) => {
  const [details, setDetails] = useState(false);
  const [userData, setUserData] = useState({
    labels: data && data?.map((data) => data.person_name),
    datasets: [
      {
        label: "Revisados",
        data: data && data?.map((data) => data.amount_revised_items),
        backgroundColor: ["#006EB8"]
      },
      {
        label: "Aprobados",
        data: data && data?.map((data) => data.amount_approved_item),
        backgroundColor: ["#47B3E7"]
      },
      {
        label: "Devueltos",
        data:
          data && data?.map((data) => data.amount_returned_items),
        backgroundColor: ["#9CD8F7"]
      }
    ]
  });

  const [total, setTotal] = useState(
    data &&
    [...data?.reduce((acc, { amount_approved_item, amount_returned_items, amount_revised_items }) => {
      acc[0] += amount_approved_item;
      acc[1] += amount_returned_items;
      acc[2] += amount_revised_items;
      return acc;
    }, [0, 0, 0])]
  );

  const refItems = useRef(null);
  const [rutaImage, setrutaImage] = useState(null);
  const [dateToday, setDateToday] = useState(null);

  const dowmLoadPdfImgae = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = refItems.current.toBase64Image();
    // link.click();
    setrutaImage(link.href);
  }, []);

  useEffect(() => {
    if (rutaImage) { ExportPdf("IdPdfestadisticas", `Estadisticas_de_${title}_${dateToday}`); }
  }, [rutaImage]);

  useEffect(() => {
    const fechaHoy = new Date();
    setDateToday(
      fechaHoy.toLocaleDateString() +
      " " +
      fechaHoy.getHours() +
      ":" +
      fechaHoy.getMinutes() +
      ":" +
      fechaHoy.getSeconds()
    );
  }, []);

  return (
    <>
      <div id="contenedorEstadistica">
        <div className="container">
          <div className="row">
            <div className="col">
              <Title size={"18px"} weight={600} className="mt-3">
                {title}
              </Title>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div id="contenedorResultado" className="px-2">
            {!loading && <Bar data={userData} ref={refItems} options={estadisticaOptions('Usuario Calificado', 'Cantidad')} />}
          </div>
        </div>
        <span
          className="mt-3 ms-3 text-primary cursorDetail"
          onClick={() => setDetails(!details)}
        >
          Ver más
          <IconGeneric width={"20px"} as={IoIosArrowForward} className="me-3" />
        </span>

        <ExportPdfGrafica
          id="IdPdfestadisticas"
          rutaImage={rutaImage}
          thead={theadEstadisticasItem}
          data={data ? data?.statistics : []}
          tbody={TbodyItems}
          subtotal={total || []}
          title={`Estadisticas de ${title}`}
          dateToday={dateToday}
        />

        {details && (
          <div className="mt-2">
            <TableOrder
              thead={theadEstadisticasItem}
              data={data || []}
              setData={setCurrentPage}
              loading={loading}
              tbodyData={TbodyItems}
              subTotals={total || []}
              titleTotal="Total"
            />
          </div>
        )}
      </div>
    </>
  );
};
