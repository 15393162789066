import { Flex, Spacer } from "@chakra-ui/react";
import Select from "react-select";
import { LabelStyled } from "components/addProduct/CreateTemplate.styled";
import { useForm } from "hooks/useForm";
import ProductSearchBar from "pages/IntegrationBox/Form/ProductSearchBar";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import React, { useState } from "react";
import { ButtonStyled } from "styled/Buttons.styled";
import { MaxLength } from "utils/MaxLength";
import { useSearchUrl } from "hooks/useSearchUrl";
import { handleSanitizedObj } from "utils/type-check-utils";

export const FormProduct = ({ onClose, handleSearchSubmit }) => {
  const { state, setParamsSearch } = useSearchUrl({ getParams: ["description", "product_code", "subheading", "group_product", "status_product", "without_quantity", "date_start", "date_end", "valueProducts"], nestedProp: "searchAdvance" })

  const [valueProducts, setValueProducts] = useState(state?.valueProducts || null);
  const [codeProduct, setCodeProduct] = useState(state?.product_code || null);

  const [values, handleInputChange] = useForm({
    description: state?.description || "",
    subheading: state?.subheading || "",
    group_product: state?.group_product || "",
    status_product: state?.status_product || 1,
    without_quantity: state?.without_quantity || 0,
    date_start: state?.date_start || "",
    date_end: state?.date_end || "",
  });

  const {
    description,
    subheading,
    group_product,
    date_start,
    date_end,
  } = values;

  const getCodeProduct = (data, target, code) => {
    setCodeProduct(code?.code_product);
  };

  const handleSubmit = () => {
    // if (authoritation_id === "") {
    //   openAlert("El número de autorización es requerido", "error");
    // } else if (item_id === "") {
    //   openAlert("El número de item es requerido", "error");
    // } else {
    //   // const data = {
    //   //   values,
    //   // };
    // }

    const data = {
      ...values,
      product_code: codeProduct || "",
    };

    setParamsSearch({
      params: {
        ...handleSanitizedObj(data),
        ...(valueProducts && { valueProducts }),
      }
    })
    handleSearchSubmit(data);
  };
  const selectData = {
    dataProduct: [
      {
        value: 1,
        label: "Activo",
      },
      {
        value: 0,
        label: "Inactivo",
      },
    ],
    withoutQuantity: [
      {
        value: 1,
        label: "Si",
      },
      {
        value: 0,
        label: "No",
      },
    ],
  };

  const [statusProduct, setStatusProduct] = useState(selectData?.dataProduct.find((item) => item?.value === state?.status_product) || {
    label: "Activo",
    value: 1,
  });
  const [balance, setBalance] = useState(selectData?.withoutQuantity.find((item) => item?.value === state?.without_quantity) || {
    label: "No",
    value: 0,
  });

  const handleSelectChange = (items, ref) => {
    const target = {
      value: items?.value,
      name: ref?.name,
      type: "select",
    };

    handleInputChange({ target });

    if (ref?.name === "status_product") {
      setStatusProduct({ label: items?.label, value: items?.value });
      return;
    }

    if (ref?.name === "without_quantity") {
      setBalance({ label: items?.label, value: items?.value });
      return;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <ProductSearchBar
          label="Código producto"
          size={45}
          set={setValueProducts}
          value={valueProducts}
          ButtonSide={true}
          callBack={getCodeProduct}
        />
        <LabelGroup
          name={"group_product"}
          value={group_product}
          onChange={handleInputChange}
          tag={"Grupo producto"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          fontSize="12.5px"
          name={"date_start"}
          value={date_start}
          onChange={handleInputChange}
          tag={"Fecha de inicio"}
          size={45}
          tipo={"date"}
        />

        <LabelGroup
          fontSize="12.5px"
          name={"date_end"}
          value={date_end}
          onChange={handleInputChange}
          tag={"Fecha final "}
          size={45}
          tipo={"date"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <LabelGroup
          name={"subheading"}
          value={subheading}
          onChange={handleInputChange}
          tag={"Subpartida"}
          size={45}
          tipo={"text"}
          maxLength={MaxLength?.global?.code_subheading}
        // maxiLength={"10"}
        />
        <LabelGroup
          name={"description"}
          value={description}
          onChange={handleInputChange}
          tag={"Descripción producto"}
          size={45}
          tipo={"text"}
        // maxiLength={"10"}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            width: "45%",
          }}
        >
          <LabelStyled>Incluir sin saldo</LabelStyled>
          <Select
            name="without_quantity"
            value={balance}
            options={selectData.withoutQuantity}
            onChange={handleSelectChange}
            placeholder="Seleccionar"
          />
        </div>

        <div
          style={{
            width: "45%",
          }}
        >
          <LabelStyled>Estado del producto</LabelStyled>
          <Select
            name="status_product"
            value={statusProduct}
            options={selectData.dataProduct}
            onChange={handleSelectChange}
            placeholder="Seleccionar"
          />
        </div>
      </div>
      <Flex className="my-3">
        <ButtonStyled bgColor={"#bababa"} onClick={onClose}>
          Cerrar
        </ButtonStyled>
        <Spacer />
        <ButtonStyled bgColor={"#03C39A"} onClick={handleSubmit}>
          Buscar
        </ButtonStyled>
      </Flex>
    </>
  );
};
