import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import ZoomImage from "components/DispatchCorrections/ZoomedImage";
import { Modalcustomizable } from "components/modal/ModalPass";
import { useForm } from "hooks/useForm";
import { Storage } from "hooks/useStorage";
import { LabelGroup } from "pages/RefProductInter/LabelGroup";
import { MdOutlineImage } from "react-icons/md";
import { isFunction } from "utils/type-check-utils";

const getExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === 0) return "";
  const extension = filename.slice(lastDotIndex + 1).toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "tiff"];
  return imageExtensions.includes(extension)
    ? "Imagen"
    : extension.charAt(0).toUpperCase() + extension.slice(1);
};

export const GroupInputUpload = ({
  onClose = () => {},
  customClose = null,
  customHandleChange = null,
  mode = "normal",
  preview = false,
  setIsUploadActive = null,
  callBack = () => {},
}) => {
  const { set: setStorage, get: getStorage } = Storage;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const firstRender = useRef(true);
  const [previewImage, setPreviewImage] = useState(null);
  const [fechasHoy, setFechasHoy] = useState(null);
  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  const [values, handleInputChange, resetForm] = useForm({
    ...(customHandleChange?.values?.id && { id: customHandleChange.values.id }),
    attachment_description: "",
    attachment_number: "",
    attachment_comment: "",
    attachment_date: null,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setStorage("uploadData", { uploadData: { ...values } });
    }
  }, [values, setStorage]);

  useEffect(() => {
    setFechasHoy(new Date().toISOString().substring(0, 10));
  }, []);

  const handleReset = useCallback(() => {
    if (customHandleChange?.reset) {
      customHandleChange.reset();
      onClose();
    } else {
      const prev = getStorage("uploadData")?.uploadData || {};
      setStorage("uploadData", { uploadData: { ...prev, cancel: true } });
      onClose();
    }
  }, [customHandleChange, onClose, getStorage, setStorage]);

  const isValidUpload =
    customHandleChange?.values?.attachment_number?.length >= 1 ||
    values.attachment_number?.length >= 1;

  const extension =
    customHandleChange?.values?.extension ||
    getExtension(customHandleChange?.values?.name || "");

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  }, []);

  const openImageView = useCallback(() => {
    setIsFullscreen(false);
    setPreviewImage(customHandleChange?.values?.url);
    onOpenPreview();
  }, [customHandleChange, onOpenPreview]);

  const previewValidation =
    (extension === "Imagen" || extension === "Pdf") && preview;

  const isActiveUpload = useCallback(() => {
    if (isFunction(setIsUploadActive) && !previewValidation) {
      setIsUploadActive(true);
      onClose();
    }
    if (!isFunction(setIsUploadActive)) onClose();
    callBack(customHandleChange?.values);
  }, [
    setIsUploadActive,
    previewValidation,
    onClose,
    callBack,
    customHandleChange,
  ]);

  return (
    <>
      <Modalcustomizable
        title={"Previsualización"}
        isOpen={isOpenPreview}
        onClose={onClosePreview}
        rightButtonText={"Confirmar"}
        iconComponent={MdOutlineImage}
        hiddenButtons={true}
        size={isFullscreen ? "full" : "4xl"}
      >
        {extension === "Imagen" && (
          <ZoomImage
            src={previewImage}
            toggleFullscreen={toggleFullscreen}
            isFullscreen={isFullscreen}
            setIsFullscreen={setIsFullscreen}
          />
        )}
        {extension === "Pdf" && (
          <iframe
            title="preview"
            src={
              customHandleChange?.values?.other_attachment_url ||
              customHandleChange?.values?.url ||
              ""
            }
            width="100%"
            height="500px"
          />
        )}
      </Modalcustomizable>

      <Box
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        marginBottom={"2rem"}
      >
        {mode === "edit" && (
          <LabelGroup
            name={"name"}
            value={customHandleChange?.values?.name}
            tag={"Documento "}
            size={100}
            desicion={true}
            maxiLength={"50"}
          />
        )}
        {customHandleChange?.values?.attachment_description && (
          <LabelGroup
            name={"attachment_description"}
            onChange={
              customHandleChange?.handleInputChange || handleInputChange
            }
            value={
              customHandleChange?.values?.attachment_description ||
              values.attachment_description
            }
            tag={"Descripción"}
            size={100}
            desicion={customHandleChange?.values?.nameDocument}
            maxiLength={"50"}
          />
        )}
        <LabelGroup
          name="attachment_comment"
          value={
            customHandleChange?.values?.attachment_comment ||
            values.attachment_comment
          }
          onChange={customHandleChange?.handleInputChange || handleInputChange}
          tag={"Comentario"}
          size={100}
          desicion={!!previewValidation}
          maxiLength={"50"}
        />
        <LabelGroup
          name={"attachment_number"}
          onChange={customHandleChange?.handleInputChange || handleInputChange}
          value={
            customHandleChange?.values?.attachment_number ||
            values.attachment_number
          }
          tag={"Número del anexo "}
          size={100}
          desicion={!!previewValidation}
          required={true}
        />
        <LabelGroup
          name={"attachment_date"}
          onChange={customHandleChange?.handleInputChange || handleInputChange}
          value={
            customHandleChange?.values?.attachment_date ||
            values.attachment_date
          }
          type={"date"}
          tag={"Fecha del anexo"}
          size={100}
          desicion={false}
          required={true}
          max={fechasHoy}
        />
      </Box>
      <Flex className="my-3" justifyContent={"space-between"}>
        <Button
          colorScheme="gray"
          onClick={isFunction(customClose) ? customClose : handleReset}
        >
          Cerrar
        </Button>
        <Button
          colorScheme="blue"
          onClick={previewValidation ? openImageView : isActiveUpload}
          disabled={previewValidation ? false : !isValidUpload}
        >
          {previewValidation ? "Ver" : "Guardar"}
        </Button>
      </Flex>
    </>
  );
};
