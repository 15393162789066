import styled from 'styled-components'
import { Input } from '@chakra-ui/react'


export const FormInput = styled.input`
  width: 100%;
  height: 38px;
  color: #000;
  //background-color: #ffffff87;
  outline: none;
  border: 1px solid
    ${props =>
    props.error
      ? typeof props.error === 'string'
        ? props.error
        : 'red'
      : '#bababa'};
  border-radius: 5px;
  padding-left: 5px;
  font-size: 1rem;
  display: inline-block;
`


/* export const FormInput = styled(Input)`
  width: 100% !important;
  height: 38px !important;
  color: #000 !important;
  //background-color: #ffffff87;
  outline: none !important;
  border: 1px solid
    ${props =>
    props.error
      ? typeof props.error === 'string'
        ? props.error
        : 'red'
      : '#bababa'} !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  font-size: 1rem !important;
  display: inline-block !important;
` */

export const FormInputLineDow = styled.input`
  width: 100%;
  height: 38px;
  outline: none;
  border: none;
  border-bottom: 3px solid
    ${props =>
    props.error
      ? typeof props.error === 'string'
        ? props.error
        : 'red'
      : '#bababa'};
  padding-left: 5px;
  font-size: 1rem;
  display: inline-block;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`

export const FormInputColor = styled.input`
  width: 100%;
  height: 38px;
  /* background-color: #ffffff87; */
  border: none;
  outline: none;
  color: ${props => (props.Color ? props.Color : '#000000')};
  border-bottom: 2px solid ${props => (props.Color ? props.Color : '#bababa')};
  //box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  font-size: 1rem;
  display: inline-block;
`

export const But = styled.input`
  padding: 16px;
  border-radius: 2px;
  border: none;
  width: 25vw;
`
export const Cancelar = styled.button`
  background-color: #bababa;
  color: #ffff;
  width: 40%;
  height: 40px;
  border-radius: 5px;
  &:hover {
    background-color: #d8d8d8;
  }
`
export const Continuar = styled.button`
  background-color: #1f7ac3;
  color: #ffff;
  width: 40%;
  height: 40px;
  border-radius: 5px;
  &:hover {
    background-color: #51a5eb;
  }
  &:disabled {
    background-color: rgba(81, 165, 235, 0.3);
    cursor: not-allowed;
  }
`

export const PesajeBu = styled.button`
  background-color: #f9a621;
  color: #ffff;
  width: 304px;
  height: 56px;
  border-radius: 12px;
  &:hover {
    background-color: #d38a14;
    color: #ffff;
  }
`
export const InputCommentary = styled.textarea`
  width: 100%;
  height: 60px;
  border: none;
  outline: none;
  font-size: 1rem;
  display: inline-block;
  border-radius: 10px;
  background: #e7e9e461;
  padding: 5px;
  resize: none;
  overflow: hidden;
`

export const InputTextarea = styled.textarea`
  width: 100%;
  max-height: 76px;
  border: none;
  outline: none;
  font-size: 1rem;
  border-bottom: 1px solid #bababa;
  font-size: 1rem;
  display: inline-block;

  padding: 5px;
  resize: none;
  overflow: hidden;
`

export const InputEdit = styled.input`
  border: 0;
  outline: none;
`
