import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  Checkbox,
  Stack
  , Flex
} from "@chakra-ui/react";

export const TotalsCalculated = ({ supplyFilter, setSupplyFilter = () => { }, data }) => {
  return (
    <Flex gap={6}>
      <Box w="30%" h="10">
        <InputGroup>
          <InputLeftAddon children="Peso" />
          <Input
            type="text"
            placeholder="Peso"
            value={data?.totalNetWeight || 0}
            readOnly
          />
        </InputGroup>
      </Box>

      <Box w="20%" h="10">
        <InputGroup>
          <InputLeftAddon children="Valor" />
          <Input
            type="text"
            value={data?.totalFobValue}
            placeholder="Valor"
            readOnly
          />
        </InputGroup>
      </Box>
      <Box w="20%" h="10">
        <InputGroup>
          <InputLeftAddon children="Cant" />
          <Input
            type="text"
            value={ data?.quantity_total}
            placeholder="Cantidad"
            readOnly
          />
        </InputGroup>
      </Box>

      <Box w="30%" h="10">
        <Stack spacing={[1, 5]} direction={["column", "row"]}>
          <Checkbox
            isChecked={supplyFilter === "NAC"}
            onChange={(e) => {
              setSupplyFilter(e?.target?.checked ? "NAC" : null);
            }}
          >
            NAC
          </Checkbox>

          <Checkbox
            isChecked={supplyFilter === "EXT"}
            onChange={(e) => {
              setSupplyFilter(e?.target?.checked ? "EXT" : null);
            }}
          >
            EXT
          </Checkbox>

          <Checkbox
            isChecked={supplyFilter === "CDI"}
            onChange={(e) => {
              setSupplyFilter(e?.target?.checked ? "CDI" : null);
            }}
          >
            CDI
          </Checkbox>
        </Stack>
      </Box>
    </Flex>
  );
};
