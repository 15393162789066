import { Radio, Td, Tooltip, Tr } from "@chakra-ui/react";

const TBodyTrackingModuls = ({ data }) => {
  return (
    <>
      <Tr>
        <Td className="text-center">{data?.follow_order ? data?.follow_order : "N/A"}</Td>
        <Td className="text-center">{data?.form_type ? data?.form_type : "N/A"}</Td>
        <Td className="text-center">{data?.service ? data?.service : "N/A"}</Td>
        <Td className="text-center">{data?.start_date ? data?.start_date : "N/A"}</Td>
        <Td className="text-center"> {data?.nickname ? data?.nickname : "N/A"}</Td>
        <Td className="text-center">{data?.final_user_nickname ? data?.final_user_nickname : "N/A"}</Td>
        <Td className="text-center"> {data?.end_date ? data?.end_date : "N/A"}</Td>
        <Tooltip
          hasArrow
          placement="top"
          label={
            data?.status_id === 1 ? 'En seguimiento' : 'Estuvo en seguimiento'
          }
        >
          <Td className="text-center">
            <Radio isChecked colorScheme={data?.status_id === 1 ? 'red' : 'green'} />
          </Td>
        </Tooltip>
      </Tr>
    </>
  );
};

export default TBodyTrackingModuls;
